import { groupBy, isUndefined, maxBy, round } from 'lodash'
import { useMemo } from 'react'
import { useSelector, shallowEqual } from 'react-redux'
import { getHardPiezoDataTypes } from 'utils/PiezometryUtils'
import i18n from 'simple-react-i18n'
import { getDate, getYear } from 'utils/DateUtil'
import { findApproximateValue } from 'station/components/suivipc/qualitometer/utils/SuiviPCUltils'
import { getMeasureByGroup } from 'quality/utils/PcMonitoringUtil'

const usePiezoDatas = (piezoDatas, datesSort) => {
    const {
        piezometers,
        piezometryDataTypes,
    } = useSelector(store => ({
        piezometers: store.PiezometryReducer.piezometersLight,
        piezometryDataTypes: store.PiezometryReducer.piezometryDataTypes,
    }), shallowEqual)

    const dataTypes = useMemo(() => [...getHardPiezoDataTypes(), ...piezometryDataTypes], [piezometryDataTypes])

    const piezoDatasFormatted = useMemo(() => {
        return piezoDatas.filter(({ id, type }) => !isUndefined(id) && !isUndefined(type)).map(({ id, type, measures = [] }) => {
            const piezo = piezometers.find(p => p.id == id)
            const unit = dataTypes.find(d => d.id === type)?.label ?? i18n[type]
            const keyPiezo = type === -1 ? 'NGF' : 'value'
            const startData = {
                parameter: {
                    value: `${i18n.piezometer} :${piezo.name} [${piezo.code}]`,
                    classNameColor: 'grey',
                    tooltip: `${piezo.name} [${piezo.code}]`,
                },
                group: { value: '', classNameColor: 'grey' },
                unit: { value: unit, code: unit, position: 'right', classNameColor: 'grey' },
                support: { value: '', classNameColor: 'grey' },
                fraction: { value: '', classNameColor: 'grey' },
            }
            const groupedMeasures = groupBy(measures, m => getDate(m.date, 'DD/MM/YYYY'))

            return datesSort.reduce((acc, key) => {
                const [date] = key.split('#:#')
                const foundDeltaDate = findApproximateValue(date, groupedMeasures)
                if (groupedMeasures[date]) {
                    const maxMeasure = maxBy(groupedMeasures[date], keyPiezo)
                    acc[key] = {
                        value: `${round(maxMeasure?.[keyPiezo], 2) || ''}`,
                        date: maxMeasure?.date,
                        positionCell: 'right',
                        classNameColor: 'grey',
                        cellType: 'right',
                    }
                } else if (foundDeltaDate.length) {
                    const maxMeasure = maxBy(groupedMeasures[foundDeltaDate[0]], keyPiezo)
                    acc[key] = {
                        value: `${round(maxMeasure?.[keyPiezo], 2) || ''}`,
                        date: maxMeasure?.date,
                        positionCell: 'right',
                        classNameColor: foundDeltaDate[1],
                        color: foundDeltaDate[1],
                        cellType: 'right',
                    }
                } else {
                    acc[key] = { value: '', date, positionCell: 'right', classNameColor: 'grey' }
                }
                return acc
            }, startData)
        })
    }, [piezoDatas, piezometers, dataTypes, datesSort])

    return piezoDatasFormatted
}

const useRegroupPiezoDatas = (piezoDatas, datesSort, regrouping) => {
    const {
        piezometers,
        piezometryDataTypes,
    } = useSelector(store => ({
        piezometers: store.PiezometryReducer.piezometersLight,
        piezometryDataTypes: store.PiezometryReducer.piezometryDataTypes,
    }), shallowEqual)

    const dataTypes = useMemo(() => [...getHardPiezoDataTypes(), ...piezometryDataTypes], [piezometryDataTypes])

    const piezoDatasFormatted = useMemo(() => {
        return piezoDatas.filter(({ id, type }) => !isUndefined(id) && !isUndefined(type)).map(({ id, type, measures = [] }) => {
            const piezo = piezometers.find(p => p.id == id)
            const label = dataTypes.find(d => d.id === type)?.label ?? i18n[type]
            const keyPiezo = type === -1 ? 'NGF' : 'value'
            const startData = {
                parameter: {
                    value: `${i18n.piezometer} :${piezo.name} [${piezo.code}]`,
                    classNameColor: 'grey',
                    tooltip: `${piezo.name} [${piezo.code}]`,
                },
                group: { value: '', classNameColor: 'grey' },
                unit: { value: label, code: label, position: 'right', classNameColor: 'grey' },
                support: { value: '', classNameColor: 'grey' },
                fraction: { value: '', classNameColor: 'grey' },
            }

            if (datesSort.includes('history')) {
                const measure = getMeasureByGroup(measures, keyPiezo, regrouping)
                return {
                    ...startData,
                    history: {
                        value: `${round(measure?.[keyPiezo], 2) || ''}`,
                        date: measure?.date,
                        positionCell: 'right',
                        classNameColor: 'grey',
                        cellType: 'right',
                    },
                }
            }
            const groupedMeasures = groupBy(measures, m => getYear(m.date))
            return datesSort.reduce((acc, key) => {
                const measure = getMeasureByGroup(groupedMeasures[key], keyPiezo)
                acc[key] = {
                    value: `${round(measure?.[keyPiezo], 2) || ''}`,
                    date: measure?.date,
                    classNameColor: 'grey',
                    cellType: 'right',
                    positionCell: 'right',
                }
                return acc
            }, startData)
        })
    }, [piezoDatas, piezometers, dataTypes, datesSort, regrouping])

    return piezoDatasFormatted
}

export {
    usePiezoDatas,
    useRegroupPiezoDatas,
}