/* eslint-disable consistent-return */
import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import SimpleDatePicker from '../../components/forms/SimpleDatePicker'
import Select from '../../components/forms/Select'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { intersectionWith, isUndefined, keyBy } from 'lodash'
import Input from '../../components/forms/Input'
import { Grid, Button as ButtonMui } from '@mui/material'
import SelectionTableModal from 'components/modal/SelectionTableModal'
import useBoolean from 'utils/customHook/useBoolean'
import { formatParameterSelectedPopup } from 'campaign/utils/CampaignUtils'
import SelectionSelect from 'components/forms/specific/SelectionSelect'
import { searchAllCharacters } from 'utils/StringUtil'
import ParameterAction from 'referencial/components/parameter/actions/ParameterAction'
import { hasValue } from 'utils/NumberUtil'
import ContributorAction from 'referencial/components/contributor/actions/ContributorAction'
import CampaignAction from 'campaign/actions/CampaignAction'
import QualityAction from 'quality/actions/QualityAction'
import moment from 'moment'
import StepperDialog from 'components/modal/StepperDialog'
import { ButtonMUI } from 'components/styled/Buttons'
import SimpleSelectionTable, { QualitometerFilterField } from 'components/datatable/SimpleSelectionTable'
import useListIndexed from 'utils/customHook/useListIndexed'
import { StyledFieldSet, StyledLegend } from 'components/StyledElements'
import Table from 'components/datatable/Table'
import CityAction from 'referencial/components/city/actions/CityAction'
import ToastrAction from 'toastr/actions/ToastrAction'
import { schemeAgencyIDHelpIcon } from '../../import/utils/ImportUtils'

const STEP_STATION = 0
const STEP_CODIFICATION = 1

const StationsStep = ({
    stations = [],
    setStations = () => {},

    defaultFilter = {},
    campaignStations = [],
}) => {
    const {
        qualitometers,
        cities,
    } = useSelector(store => ({
        qualitometers: store.QualityReducer.qualitometersLight,
        cities: store.CityReducer.cities,
    }), shallowEqual)

    const citiesIndexed = useListIndexed(cities, 'id')
    const qualitometersIndexed = useListIndexed(qualitometers, 'id')

    const qualitometersFormatted = useMemo(() => {
        const filteredQualitometers = hasValue(defaultFilter.campaign) ? intersectionWith(qualitometers, campaignStations, (qualito, cs) => qualito.id === cs.stationId) : qualitometers
        return filteredQualitometers.map(q => {
            const city = citiesIndexed[q.townCode]?.labelWithCode || ''
            return {
                id: q.id,
                code: q.code,
                name: q.name,
                city,
                labelSearch: searchAllCharacters(`${q.code}${q.name}${city}`),
                townCode: q.townCode,
            }
        })
    }, [campaignStations, citiesIndexed, defaultFilter.campaign, qualitometers])

    const selectedStations = stations.map(s => s.id)

    const onChange = list => {
        const stationIndexed = keyBy(stations, 'id')
        const listStation = list.map(id => {
            const station = stationIndexed[id]
            if (!isUndefined(station)) {
                return station
            }
            const qualito = qualitometersIndexed[id]
            if (!qualito) {
                return
            }
            return {
                id,
                code: qualito.code,
                name: qualito.name,
                projection: qualito.projection,
                x: qualito.x,
                y: qualito.y,
            }
        }).filter(s => !!s)
        setStations(listStation)
    }

    return (
        <SimpleSelectionTable
            onChange={onChange}

            listData={qualitometersFormatted}
            selectedList={selectedStations}

            listHeaders={['code', 'name', 'city']}
            listTitle={i18n.nonSelectedStations}
            selectedListTitle={i18n.selectedStations}
            maxHeightTable={'38vh'}

            filterField={QualitometerFilterField}
            filterFunction={(list, { filter = -1, resultFilter, searchValue, city }) => {
                const filterGroup = hasValue(city) ? list.filter(s => s.townCode === city) : list
                const filterSelection = filter !== -1 ? intersectionWith(filterGroup, resultFilter, (elem, res) => elem.id === res.id) : filterGroup
                const searchValueFormat = searchAllCharacters(searchValue)
                return searchValue ? filterSelection.filter(p => p.labelSearch.includes(searchValueFormat)) : filterSelection
            }}
        />
    )
}

StationsStep.propTypes = {
    stations: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
    })),
    setStations: PropTypes.func,
    defaultFilter: PropTypes.shape({
        campaign: PropTypes.number,
    }),
    campaignStations: PropTypes.arrayOf(PropTypes.shape({ })),
}

const CodificationStep = ({
    stations = [],
    setStations = () => {},
    codifications = [],
}) => {
    const {
        qualitometers,
        cities,
    } = useSelector(store => ({
        qualitometers: store.QualityReducer.qualitometersLight,
        cities: store.CityReducer.cities,
    }), shallowEqual)

    const [filter, setFilter] = useState({})

    const citiesIndexed = useListIndexed(cities, 'id')
    const qualitometersIndexed = useListIndexed(qualitometers, 'id')

    const stationsFormatted = useMemo(() => {
        return stations.map(s => {
            const qualito = qualitometersIndexed[s.id]
            const city = citiesIndexed[qualito?.townCode]
            return {
                id: s.id,
                code: qualito?.code,
                name: qualito?.name,
                city: city?.labelWithCode,
                codification: (
                    <Select
                        options={codifications}
                        nullLabel='&nbsp;'
                        onChange={c => setStations(prev => prev.map(p => p.id === s.id ? { ...p, codification: c } : p))}
                        value={s.codification}
                        noSort
                    />
                ),
                townCode: qualito?.townCode,
                labelSearch: searchAllCharacters(`${qualito.code}${qualito.name}${city}`),
            }
        })
    }, [citiesIndexed, codifications, qualitometersIndexed, stations])

    const stationsFiltered = useMemo(() => {
        const {
            filter: filterId = -1,
            resultFilter,
            searchValue,
            city,
        } = filter

        const filterCity = hasValue(city) ? stationsFormatted.filter(s => s.townCode === city) : stationsFormatted
        const filterFilter = filterId !== -1 ? intersectionWith(filterCity, resultFilter, (elem, res) => elem.id === res.id) : filterCity
        const searchValueFormat = searchAllCharacters(searchValue)
        return searchValue ? filterFilter.filter(p => p.labelSearch.includes(searchValueFormat)) : filterFilter
    }, [filter, stationsFormatted])

    return (
        <Grid container justifyContent='center'>
            <Grid item xs={12}>
                <StyledFieldSet>
                    <StyledLegend>{i18n.filter}</StyledLegend>
                    <QualitometerFilterField
                        filter={filter}
                        setFilter={setFilter}
                    />
                </StyledFieldSet>
            </Grid>
            <Grid item xs={12} style={{ paddingTop: '10px' }}>
                <Table
                    showTitle={false}
                    data={stationsFiltered}
                    type={{ headers: ['code', 'name', 'city', 'codification'] }}

                    maxHeight='42vh'

                    sortable
                    condensed
                    paging
                    nbPerPageLabel={[{ label: '10', value: 10 }]}
                />
            </Grid>
        </Grid>
    )
}

CodificationStep.propTypes = {
    stations: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        codification: PropTypes.number,
    })),
    setStations: PropTypes.func,
    codifications: PropTypes.array,
}

const StepperStations = ({
    isOpen = false,
    close = () => {},
    onSave = () => {},

    defaultFilter = {},
    campaignStations = [],
    codifications = [],
}) => {
    const [stations, setStations] = useState(defaultFilter.listStation)

    useEffect(() => {
        if (isOpen) {
            setStations(defaultFilter.listStation)
        }
    }, [defaultFilter.listStation, isOpen])

    return (
        <StepperDialog
            steps={[
                {
                    label: i18n.selection,
                    constant: STEP_STATION,
                    nextAvailable: !!stations.length,
                },
                {
                    label: i18n.parameters,
                    constant: STEP_CODIFICATION,
                },
            ]}
            open={isOpen}
            title={i18n.selectStations}
            closeDialog={close}
            renderSaveButton={step => (step === STEP_CODIFICATION) && (
                <ButtonMUI
                    onClick={() => {
                        onSave(stations)
                    }}
                    variant='contained'
                >
                    {i18n.register}
                </ButtonMUI>
            )}
            contentStyle={{ padding: '0 12' }}
            maxWidth='xl'
        >
            {
                step => (
                    <>
                        {step === STEP_STATION && (
                            <StationsStep
                                stations={stations}
                                setStations={setStations}

                                defaultFilter={defaultFilter}
                                campaignStations={campaignStations}
                            />
                        )}
                        {step === STEP_CODIFICATION && (
                            <CodificationStep
                                stations={stations}
                                setStations={setStations}
                                codifications={codifications}
                            />
                        )}
                    </>
                )
            }
        </StepperDialog>
    )
}

StepperStations.propTypes = {
    isOpen: PropTypes.bool,
    close: PropTypes.func,
    onSave: PropTypes.func,
    defaultFilter: PropTypes.shape({
        listStation: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number,
            codification: PropTypes.number,
            // ...
        })),
    }),
    campaignStations: PropTypes.arrayOf(PropTypes.shape({})),
    codifications: PropTypes.array,
}

const StationsEdilaboExportPanel = ({
    isEditMode = false,
    defaultFilter = {},
    onChange = () => {},
}) => {
    const dispatch = useDispatch()

    const {
        contributors,
        qualityCampaigns,
        parameters,
        parameterGroups,
        parameterGroupLinks,
        qualitometers,
        cities,
    } = useSelector(store => ({
        contributors: store.ContributorReducer.contributors,
        qualityCampaigns: store.QualityReducer.qualityCampaigns,
        parameters: store.ParameterReducer.parameters,
        parameterGroups: store.ParameterReducer.parameterGroups,
        parameterGroupLinks: store.ParameterReducer.parameterGroupLinks,
        qualitometers: store.QualityReducer.qualitometersLight,
        cities: store.CityReducer.cities,
    }), shallowEqual)

    const {
        value: isStationPopinOpen,
        setTrue: openStationPopin,
        setFalse: closeStationPopin,
    } = useBoolean(false)
    const {
        value: isParameterPopinOpen,
        setTrue: openParameterPopin,
        setFalse: closeParameterPopin,
    } = useBoolean(false)

    const [campaignStations, setCampaignStations] = useState([])
    const [campaignParameters, setCampaignParameters] = useState([])

    const codifications = useMemo(() => [
        { id: 0, label: 'Origine du code inconnue' },
        { id: 1, label: 'Code national attribué par les Agences de l\'eau pour les Eaux de Surface' },
        { id: 2, label: 'Code national attribué pour SISE\'EAU' },
        { id: 3, label: 'Code national attribué par le BRGM pour la BSS et ADES' },
        { id: 4, label: 'Code national attribué par l\'IFREMER pour QUADRIGE' },
        { id: 5, label: 'Code national attribué aux stations d\'épuration par les Agences de l\'Eau, dans le domaine de l\'assainissement urbain' },
        { id: 10, label: 'Code local attribué par le(s) maître(s) d\'ouvrage(s) ou propriétaire(s) de la station de prélèvement' },
        { id: 11, label: 'Code local attribué par le commanditaire de la demande' },
        { id: 12, label: 'Code local attribué par l\'intervenant chargé du prélèvement' },
        { id: 13, label: 'Code local attribué par le laboratoire d\'analyses' },
    ], [])


    useEffect(() => {
        if (hasValue(defaultFilter.campaign)) {
            dispatch(CampaignAction.getStationsParametersCampaign(defaultFilter.campaign)).then((res = {}) => {
                const {
                    stations = [],
                    parameters: listParameters = [],
                } = res
                setCampaignParameters(listParameters)
                setCampaignStations(stations)
            })
        }
    }, [defaultFilter.campaign, dispatch])

    useEffect(() => {
        if (!contributors.length) {
            dispatch(ContributorAction.fetchContributors())
        }
        if (!qualityCampaigns.length) {
            dispatch(CampaignAction.fetchQualityCampaigns('quality'))
        }
        if (!qualitometers.length) {
            dispatch(QualityAction.fetchQualitometersLight())
        }
        if (!parameters.length) {
            dispatch(ParameterAction.fetchParameters())
        }
        if (!cities.length) {
            dispatch(CityAction.fetchCities())
        }
        dispatch(ParameterAction.fetchParameterGroups())
        dispatch(ParameterAction.fetchParameterGroupLinks())
    }, [])

    const formatedParameters = useMemo(() => {
        const filteredParameters = hasValue(defaultFilter.campaign) ? intersectionWith(parameters, campaignParameters, (param, cp) => param.code === cp.parameterCode) : parameters
        return formatParameterSelectedPopup(filteredParameters.filter(p => p.active))
    }, [campaignParameters, defaultFilter.campaign, parameters])

    const defaultParameterList = useMemo(() => {
        return defaultFilter.selectedParameters?.map(s => s.parameterCode) || []
    }, [defaultFilter.selectedParameters])


    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <fieldset>
                        <legend className='italic-label padding-right-1 padding-left-1'>{i18n.criteriaFilter}</legend>
                        <Grid container columnSpacing={2} rowSpacing={1}>
                            <Grid item xs={12}>
                                <Select
                                    options={qualityCampaigns}
                                    label={i18n.campaigns}
                                    onChange={(id, c) => {
                                        if (defaultFilter.listStation.length || defaultFilter.selectedParameters.length) {
                                            dispatch(ToastrAction.warning(i18n.purgeStationsAndParams))
                                            onChange({
                                                listStation: [],
                                                selectedParameters: [],
                                                campaign: id,
                                                startDate: c.beginningApplication ? moment(c.beginningApplication, 'DD/MM/YYYY').valueOf() : moment().subtract(1, 'year').valueOf(),
                                                endDate: c.endingApplication ? moment(c.endingApplication, 'DD/MM/YYYY').valueOf() : moment().valueOf(),
                                            })
                                        } else {
                                            onChange({
                                                campaign: id,
                                                startDate: c.beginningApplication ? moment(c.beginningApplication, 'DD/MM/YYYY').valueOf() : moment().subtract(1, 'year').valueOf(),
                                                endDate: c.endingApplication ? moment(c.endingApplication, 'DD/MM/YYYY').valueOf() : moment().valueOf(),
                                            })
                                        }
                                    }}
                                    value={defaultFilter.campaign}
                                    nullLabel='&nbsp;'
                                    clearFunction
                                    keyValue='id'
                                    keyLabel='name'
                                    active={isEditMode}
                                    disabled={!isEditMode}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <SimpleDatePicker
                                    value={defaultFilter.startDate}
                                    label={i18n.startDate}
                                    active={isEditMode}
                                    disabled={hasValue(defaultFilter.campaign) || !isEditMode}
                                    onChange={v => onChange({ startDate: v })}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <SimpleDatePicker
                                    value={defaultFilter.endDate}
                                    label={i18n.endDate}
                                    active={isEditMode}
                                    disabled={hasValue(defaultFilter.campaign) || !isEditMode}
                                    onChange={v => onChange({ endDate: v })}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <ButtonMui
                                    variant='contained'
                                    onClick={openStationPopin}
                                    disabled={!isEditMode}
                                    fullWidth
                                >
                                    {`${i18n.selectStations} (${defaultFilter.listStation.length})`}
                                </ButtonMui>
                            </Grid>
                            <Grid item xs={6}>
                                <ButtonMui
                                    variant='contained'
                                    onClick={openParameterPopin}
                                    disabled={!isEditMode}
                                    fullWidth
                                >
                                    {`${i18n.selectedParameters} (${defaultFilter.selectedParameters.length})`}
                                </ButtonMui>
                            </Grid>
                            <Grid item xs={12}>
                                <Select
                                    options={contributors}
                                    label={i18n.producer}
                                    onChange={(id) => onChange({ producer: id })}
                                    value={defaultFilter.producer}
                                    nullLabel='&nbsp;'
                                    keyLabel='labelDisplay'
                                    displayWithCode
                                    obligatory={false}
                                    active={isEditMode}
                                    disabled={!isEditMode}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Select
                                    options={contributors}
                                    label={i18n.laboratory}
                                    onChange={(id) => onChange({ labo: id })}
                                    value={defaultFilter.labo}
                                    nullLabel='&nbsp;'
                                    keyLabel='labelDisplay'
                                    displayWithCode
                                    obligatory={false}
                                    active={isEditMode}
                                    disabled={!isEditMode}
                                />
                            </Grid>
                        </Grid>
                    </fieldset>
                </Grid>
                <Grid item xs={6}>
                    <fieldset>
                        <legend className='italic-label padding-right-1 padding-left-1'>{i18n.exportCriterias}</legend>
                        <Grid container columnSpacing={2} rowSpacing={1}>
                            <Grid item xs={12}>
                                <Select
                                    options={contributors}
                                    label={i18n.emetteur}
                                    onChange={(id) => onChange({ transmitter: id })}
                                    value={defaultFilter.transmitter}
                                    nullLabel='&nbsp;'
                                    keyLabel='labelDisplay'
                                    displayWithCode
                                    active={isEditMode}
                                    disabled={!isEditMode}
                                    obligatory={true}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Select
                                    options={contributors}
                                    label={i18n.destinataire}
                                    onChange={(id) => onChange({ receiver: id })}
                                    value={defaultFilter.receiver}
                                    nullLabel='&nbsp;'
                                    keyLabel='labelDisplay'
                                    displayWithCode
                                    active={isEditMode}
                                    disabled={!isEditMode}
                                    obligatory={true}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Input
                                    title={i18n.selectDirectory}
                                    active={isEditMode}
                                    disabled={!isEditMode}
                                    value={defaultFilter.exportPath}
                                    onChange={e => onChange({ exportPath: e })}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Input
                                    active={isEditMode}
                                    disabled={!isEditMode}
                                    title={i18n.name}
                                    value={defaultFilter.requestName}
                                    onChange={e => onChange({ requestName: e })}
                                    obligatory={true}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Select
                                    value={defaultFilter.schemeAgencyID}
                                    options={codifications}
                                    label={i18n.schemeAgencyID}
                                    labelSpan={ schemeAgencyIDHelpIcon() }
                                    obligatory
                                    onChange={v => onChange({ schemeAgencyID: v })}
                                    active={isEditMode}
                                    disabled={!isEditMode}
                                />
                            </Grid>
                        </Grid>
                    </fieldset>
                </Grid>
            </Grid>
            <StepperStations
                isOpen={isStationPopinOpen}
                close={closeStationPopin}
                onSave={listStation => {
                    onChange({ listStation })
                    closeStationPopin()
                }}

                defaultFilter={defaultFilter}
                campaignStations={campaignStations}
                codifications={codifications}
            />

            <SelectionTableModal
                isOpen={isParameterPopinOpen}
                onClose={closeParameterPopin}
                onValidate={list => {
                    closeParameterPopin()
                    const selectedParameters = list.map(code => ({ parameterCode: code }))
                    onChange({ selectedParameters })
                }}
                title={i18n.parametersSelection}

                listData={formatedParameters}
                defaultSelectionList={defaultParameterList}

                listHeaders={['name', 'type', 'nature', 'status']}
                listTitle={i18n.nonSelectedParameters}
                selectionListTitle={i18n.selectedParameters}
                maxHeightTable={'48vh'}

                filterField={({
                    filter,
                    setFilter,
                }) => (
                    <Grid container columnSpacing={2} rowSpacing={1} style={{ paddingTop: '5px' }}>
                        <Grid item xs={6}>
                            <Input
                                title={i18n.search}
                                value={filter.searchValue}
                                onChange={searchValue => setFilter(prev => ({ ...prev, searchValue }))}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <SelectionSelect
                                value={filter.selection}
                                onChange={(listParam, selection) => setFilter(prev => ({ ...prev, selection, listParam }))}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Select
                                label={i18n.parameterGroup}
                                options={parameterGroups}
                                onChange={groupParam => {
                                    const listGroupParam = parameterGroupLinks.filter(l => ['classGroupCode', 'usageGroupCode', 'usageGroupCode2', 'usageGroupCode3', 'usageGroupCode4', 'usageGroupCode5'].some(key => l[key] === groupParam)).map(l => l.parameterCode)
                                    setFilter(prev => ({ ...prev, groupParam, listGroupParam }))
                                }}
                                value={filter.groupParam}
                                keyValue='code'
                                clearFunction
                            />
                        </Grid>
                    </Grid>
                )}
                filterFunction={(list, { selection = '-1', listParam, searchValue, groupParam, listGroupParam }) => {
                    const filterGroup = hasValue(groupParam) ? intersectionWith(list, listGroupParam, (elem, id) => elem.id === id) : list
                    const filterSelection = selection !== '-1' ? intersectionWith(filterGroup, listParam, (elem, id) => elem.id === id) : filterGroup
                    const searchValueFormat = searchAllCharacters(searchValue)
                    return searchValue ? filterSelection.filter(p => p.labelSearch.includes(searchValueFormat)) : filterSelection
                }}
            />
        </>
    )
}

StationsEdilaboExportPanel.propTypes = {
    onChange: PropTypes.func,
    defaultFilter: PropTypes.shape({

    }),
    isEditMode: PropTypes.bool,
}

export default StationsEdilaboExportPanel
