/* eslint-disable camelcase */
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import { getExport, setModal } from '../../../../utils/linkUtils'
import DtoInstallation from '../../../dto/installation/DtoInstallation'
import SieauAction from '../../../../components/sieau/SieauAction'
import Table from '../../../../components/datatable/Table'
import AccessTypeModal from './modal/AccessTypeModal'
import { arrayOf } from '../../../../utils/StoreUtils'
import DtoSandreCode from '../../../../referencial/dto/DtoSandreCode'
import { getSandreLabel } from '../../../../utils/StringUtil'
import { SANDRE } from '../../../../referencial/constants/ReferencialConstants'

const InstallationAccessTypePanel = ({
    station = {},
    readMode = false,
    onChange = () => {},
    setPopup = () => {},
    sandreCodes = [],
}) => {
    const stationAccessType = station.link_accessTypes.map((p, index) => ({
        ...p,
        index,
    }))
    const tableData = stationAccessType.map(c => ({
        ...c,
        type: getSandreLabel(sandreCodes, SANDRE.INSTALLATION_TYPE_ACCES, c.typeAccess),
        nature: getSandreLabel(sandreCodes, SANDRE.INSTALLATION_NATURE_ACCES, c.nature),
        status: getSandreLabel(sandreCodes, SANDRE.INSTALLATIONS_ETATEQUIPEMENT, c.status),
    }))
    const headers = ['type', 'nature', 'amount', 'status']
    const exportAction = getExport(tableData, i18n.accessType, headers)
    const actions = !readMode ? [{
        iconName: 'note_add',
        tooltip: i18n.add,
        onClick: () => setModal(i18n.newAccessType, <AccessTypeModal id={ station.id } saveResult={ r => onChange({ link_accessTypes: [...stationAccessType, r] }) }/>, setPopup),
    }, exportAction] : [exportAction]

    return (
        <Table
            title={ i18n.accessType }
            className='blue'
            condensed
            data={ tableData }
            type={{ headers }}
            sortable={ !!tableData.length }
            actions={ actions }
            alterable={ !readMode }
            onAlter={
                (element) => setModal(i18n.editAccessType,
                    <AccessTypeModal
                        id={ station.id }
                        accessType={ stationAccessType.find(at => element.index === at.index) }
                        saveResult={ r => onChange({
                            link_accessTypes: [
                                ...stationAccessType.filter(at => at.index !== element.index),
                                { ...r, index: element.index },
                            ],
                        })}
                    />
                    , setPopup)
            }
            deletable={ !readMode }
            onDelete={ (element) => onChange({ link_accessTypes: stationAccessType.filter(at => at.index !== element.index) }) }
        />
    )
}

InstallationAccessTypePanel.propTypes = {
    station: PropTypes.instanceOf(DtoInstallation),
    sandreCodes: arrayOf(DtoSandreCode),
    readMode: PropTypes.bool,
    onChange: PropTypes.func,
    setPopup: PropTypes.func,
}

const mapStateToProps = store => ({
    sandreCodes: store.ReferencialReducer.sandreCodes,
})

const mapDispatchToProps = {
    setPopup: SieauAction.setPopup,
}

export default connect(mapStateToProps, mapDispatchToProps)(InstallationAccessTypePanel)