import { Button, Grid } from '@mui/material'
import PropTypes from 'prop-types'
import React, { useEffect, useMemo, useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import Table from '../../../../components/datatable/Table'
import Select from '../../../../components/forms/Select'
import { nbPerPageLabel } from '../../../../referencial/constants/ReferencialConstants'
import { getParametersNature, getParametersType } from '../../../../referencial/util/ReferencialUtils'
import { getStatusInformation } from '../../../../referencial/util/StatusUtils'
import { getLocalizationPicto } from '../../../../utils/AnalyseUtils'
import { DialogActionsMUI, DialogContentMUI, DialogMUI, DialogTitleMUI } from 'components/styled/Dialog'
import Icon from 'components/icon/Icon'
import useListIndexed from 'utils/customHook/useListIndexed'
import useSandreList from 'utils/customHook/useSandreList'
import { isNil } from 'lodash'
import useBoolean from 'utils/customHook/useBoolean'
import { StyledFieldSet } from 'components/StyledElements'

const UpdatePopin = ({
    isOpen = false,
    onClose = () => {},
    onValidate = () => {},

    fields = {},
}) => {
    const {
        parameters,
        units,
        fractions,
        supports,
    } = useSelector(store => ({
        parameters: store.ParameterReducer.parameters,
        units: store.UnitReducer.units,
        fractions: store.FractionReducer.fractions,
        supports: store.SupportReducer.supports,
    }), shallowEqual)

    const analysisPlaces = useSandreList('ANALYSES.LIEU')

    const indexedParameters = useListIndexed(parameters, 'code')

    const [unit, setUnit] = useState()
    const [fraction, setFraction] = useState()
    const [support, setSupport] = useState()
    const [place, setPlace] = useState()

    useEffect(() => {
        if (!isOpen) return

        setUnit(fields.unit)
        setFraction(fields.fraction)
        setSupport(fields.support)
        setPlace(fields.place)
    }, [isOpen])

    return (
        <DialogMUI
            onClose={onClose}
            fullWidth
            maxWidth='md'
            open={isOpen}
            PaperProps={{
                sx: {
                    minHeight: undefined,
                    maxHeight: undefined,
                },
            }}
        >
            <DialogTitleMUI>
                <Grid container justifyContent='space-between' alignItems='center' style={{ padding: '0 20' }}>
                    <Grid item >
                        {indexedParameters[fields.parameter]?.labelWithCode ?? ''}
                    </Grid>
                    <Grid item>
                        <Icon style={{ color: 'white' }} size='small' icon='close' onClick={onClose} />
                    </Grid>
                </Grid>
            </DialogTitleMUI>
            <DialogContentMUI>
                <StyledFieldSet>
                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                            <Select
                                label={i18n.unit}
                                value={unit}
                                options={units}
                                onChange={setUnit}
                                keyLabel='labelWithCode'
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Select
                                label={i18n.fraction}
                                value={fraction}
                                options={fractions}
                                onChange={setFraction}
                                keyLabel='labelWithCode'
                                keyValue='id'
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Select
                                label={i18n.support}
                                value={support}
                                options={supports}
                                onChange={setSupport}
                                keyLabel='labelWithCode'
                                keyValue='id'
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Select
                                label={i18n.place}
                                options={analysisPlaces}
                                onChange={setPlace}
                                value={place}
                                nullLabel='&nbsp;'
                            />
                        </Grid>
                    </Grid>
                </StyledFieldSet>
            </DialogContentMUI>
            <DialogActionsMUI>
                <Button onClick={() => onValidate({ unit, fraction, support, place })} variant='contained' color='primary'>
                    {i18n.validate}
                </Button>
            </DialogActionsMUI>
        </DialogMUI>
    )
}

UpdatePopin.propTypes = {
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
    onValidate: PropTypes.func,
    fields: PropTypes.shape({
        parameter: PropTypes.string,
        unit: PropTypes.string,
        fraction: PropTypes.string,
        support: PropTypes.string,
        place: PropTypes.string,
    }),
}

const ParametersPanel = ({
    selectedParameters = [],
    editMode = false,

    onDeleteParameter = () => {},
    onUpdateParameter = () => {},
}) => {
    const {
        parameters,
        units,
        fractions,
        supports,
    } = useSelector(store => ({
        parameters: store.ParameterReducer.parameters,
        units: store.UnitReducer.units,
        fractions: store.FractionReducer.fractions,
        supports: store.SupportReducer.supports,
    }), shallowEqual)

    const indexedUnits = useListIndexed(units, 'code')
    const indexedFractions = useListIndexed(fractions, 'code')
    const indexedSupports = useListIndexed(supports, 'code')
    const indexedParameters = useListIndexed(parameters, 'code')

    const types = useMemo(getParametersType, [])
    const natures = useMemo(getParametersNature, [])

    const {
        value: isOpen,
        setTrue: onOpen,
        setFalse: onClose,
    } = useBoolean(false)
    const [selectedParam, setSelectedParam] = useState()

    const formattedParameters = useMemo(() => {
        const filteredParameters = selectedParameters.filter(p => indexedParameters[p.parameterCode]?.active)
        return filteredParameters.map(p => {
            const parameter = indexedParameters[p.parameterCode]
            return {
                code: parameter.code,
                name: parameter.name,
                type: types[parameter.typeParam],
                nature: natures[parameter.nature],
                status: parameter.status ? getStatusInformation(parameter.status) : i18n.validated,
                unit: indexedUnits[p.unitCode]?.labelWithCode,
                fraction: indexedFractions[p.fractionCode]?.labelWithCode,
                support: indexedSupports[p.supportCode]?.labelWithCode,
                place: getLocalizationPicto(p.placeCode),

                data: {
                    parameter: p.parameterCode,
                    unit: p.unitCode,
                    fraction: p.fractionCode,
                    support: p.supportCode,
                    place: p.placeCode,
                },
            }
        })
    }, [indexedFractions, indexedParameters, indexedSupports, indexedUnits, natures, selectedParameters, types])

    return (
        <>
            <Table
                showTitle={false}
                data={formattedParameters}
                type={{ headers: ['code', 'name', 'type', 'nature', 'status', 'unit', 'fraction', 'support', 'place'] }}
                sortable
                deletable={editMode}
                onDelete={(_, i) => onDeleteParameter(i)}
                alterable={editMode}
                onAlter={(_, index) => {
                    setSelectedParam(index)
                    onOpen()
                }}
                condensed
                paging
                overflow='hidden'
                nbPerPageLabel={nbPerPageLabel}
            />
            <UpdatePopin
                isOpen={isOpen}
                onClose={onClose}
                onValidate={(changes) => {
                    const newParam = {
                        parameterCode: formattedParameters[selectedParam]?.code,
                        unitCode: changes.unit,
                        fractionCode: changes.fraction,
                        supportCode: changes.support,
                        placeCode: isNil(changes.place) ? undefined : `${changes.place}`,
                    }
                    onUpdateParameter(selectedParam, newParam)
                    onClose()
                }}

                fields={formattedParameters[selectedParam]?.data}
            />
        </>
    )
}

ParametersPanel.propTypes = {
    selectedParameters: PropTypes.arrayOf(PropTypes.shape({
        parameterCode: PropTypes.string,
        fractionCode: PropTypes.string,
        supportCode: PropTypes.string,
        unitCode: PropTypes.string,
        placeCode: PropTypes.string,
    })),
    onDeleteParameter: PropTypes.func,
    onUpdateParameter: PropTypes.func,
    editMode: PropTypes.bool,
}

export default ParametersPanel
