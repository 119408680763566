import { uniqBy } from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import AppStore from 'store/AppStore'
import ProgressCard from '../../../../../components/card/ProgressCard'
import Table from '../../../../../components/datatable/Table'
import Checkbox from '../../../../../components/forms/Checkbox'
import Input from '../../../../../components/forms/Input'
import NumberField from '../../../../../components/forms/NumberField'
import RadioButtons from '../../../../../components/forms/RadioButtons'
import DtoJob from '../../../../../import/dto/DtoJob'
import DtoParametrageDataType from '../../../../../piezometry/dto/DtoParametrageDataType'
import { nbPerPageLabelShort } from '../../../../../referencial/constants/ReferencialConstants'
import { getMeasureCoteList } from '../../../../../utils/JobUtils'
import { hasValue } from '../../../../../utils/NumberUtil'
import { arrayOf, removeNullKeys } from '../../../../../utils/StoreUtils'
import PiezometryAction from '../../../../actions/PiezometryAction'
import DtoPiezometer from '../../../../dto/DtoPiezometer'
import { StyledFieldSet, StyledLegend } from '../../../../../components/StyledElements'

class CSVGenericPiezoManualPanel extends Component {
    constructor(props) {
        super(props)
        this.state = {
            dataLoaded: true,
            progress: 0,
        }
    }

    componentDidMount() {
        if (!this.props.piezometryDataTypes.length) {
            AppStore.dispatch(PiezometryAction.fetchPiezometryDataTypes())
        }
        if (!this.props.job.parameters.parameters.length) {
            if (!this.props.isPiezo) {
                this.addParameterNew({ stationCode: this.props.hydrometricStation.code, stationId: this.props.hydrometricStation.id })
            } else {
                this.addParameterNew({ stationCode: this.props.piezometer.code, stationId: this.props.piezometer.id })
            }
            setTimeout(() => this.onChangeFilters({
                dateFormat: 'dd/MM/YYYY HH:mm:ss',
                hasHeader: true,
                nbHeaders: 1,
                hourWithDate: true,
            }), 500)
        }
    }

    onChangeParametersNew = (currentParameter, index, changes) => {
        const jobParameters = {
            ...this.props.job.parameters,
            parameters: Object.assign([], this.props.job.parameters.parameters, {
                [index]: JSON.stringify(removeNullKeys({ ...currentParameter, ...changes })),
            }),
        }
        this.props.onChangeJob({ parameters: jobParameters })
    }

    onChangeFilters = (changes) => {
        const parameters = {
            ...this.props.job.parameters,
            filters: [JSON.stringify({
                ...this.getFilters(),
                ...changes,
            })],
        }
        this.props.onChangeJob({ parameters })
    }

    onChangeColumns = (parameter, index, id, column) => {
        const actualData = parameter.data || []
        const newData = (() => {
            if (!hasValue(column)) {
                return actualData.filter(d => d.id !== id)
            }
            return uniqBy([{ id, column }, ...actualData], 'id')
        })()
        this.onChangeParametersNew(parameter, index, { data: newData })
    }

    findData = (parameter, id) => {
        const found = (parameter.data || []).find(d => d.id === id)
        return found ? found.column : null
    }

    getFilters = () => {
        const newFilters = this.props.job.parameters.filters.length ? JSON.parse(this.props.job.parameters.filters[0]) : {}
        return { measureCote: 2, ...newFilters }
    }

    getParametersNew = () => this.props.job.parameters.parameters.length ? this.props.job.parameters.parameters.map(p => JSON.parse(p)) : []

    getDataTypes = () => [{ id: this.props.selectedDataType, label: i18n.dataColumn }]

    onDeleteParameter = (index) => {
        if (this.props.isEditMode) {
            const jobParameters = {
                ...this.props.job.parameters,
                parameters: this.props.job.parameters.parameters.filter((_, i) => i !== index),
            }
            this.props.onChangeJob({ parameters: jobParameters })
        }
    }

    onDuplicateParameter = (index) => {
        if (this.props.isEditMode) {
            const jobParameters = {
                ...this.props.job.parameters,
                parameters: [ ...this.props.job.parameters.parameters, this.props.job.parameters.parameters[index] ],
            }
            this.props.onChangeJob({ parameters: jobParameters })
        }
    }

    getParametersData = (filters, parameters) => {
        const disabled = { disabled: !this.props.isEditMode }
        return parameters.map((p, index) => {
            const columnFields = this.getDataTypes(filters).reduce((acc, val) => ({
                ...acc,
                [`data${val.id}`]: <NumberField value={ this.findData(p, val.id) } onChange={ v => this.onChangeColumns(p, index, val.id, v) } {...disabled }/>,
            }), {})
            return {
                coeff: <NumberField value={ p.factor } onChange={ v => this.onChangeParametersNew(p, index, { factor: v }) } floatValue {...disabled }/>,
                offset: <NumberField value={ p.offset } onChange={ v => this.onChangeParametersNew(p, index, { offset: v }) } floatValue {...disabled }/>,
                date: <NumberField value={ p.dateColumn } onChange={ v => this.onChangeParametersNew(p, index, { dateColumn: v }) } {...disabled }/>,
                hour: <NumberField value={ p.hourColumn } onChange={ v => this.onChangeParametersNew(p, index, { hourColumn: v }) } {...disabled }/>,
                excelTab: <Input value={ p.sheet } onChange={ v => this.onChangeParametersNew(p, index, { sheet: v }) } {...disabled }/>,
                ...columnFields,
            }
        })
    }

    addParameterNew = (initial = {}) => {
        if (this.props.isEditMode) {
            const jobParameters = {
                ...this.props.job.parameters,
                parameters: [ ...this.props.job.parameters.parameters, JSON.stringify(initial)],
            }
            this.props.onChangeJob({ parameters: jobParameters })
        }
    }

    render() {
        if (this.state.dataLoaded) {
            const disabled = !this.props.isEditMode
            const filters = this.getFilters()
            const parameters = this.getParametersData(filters, this.getParametersNew())
            const baseHeaders = ['excelTab', 'coeff', 'offset', 'date']
            const hourColumn = filters.hourWithDate ? [] : ['hour']
            const columnHeaders = this.getDataTypes().map(type => `data${type.id}`)
            const customHeaders = this.getDataTypes().reduce((acc, val) => ({ ...acc, [`data${val.id}`]: val.label }), {})
            return (
                <div>
                    <div className='row no-margin valign-wrapper'>
                        <StyledFieldSet className='width-100'>
                            <StyledLegend>{ i18n.separator }</StyledLegend>
                            <Checkbox col={ 3 } label={ 'Virgule' } onChange={ v => this.onChangeFilters({ commaSeparator: v }) } disabled={ disabled } checked={ filters.commaSeparator } />
                            <Checkbox col={ 3 } label={ 'Point-virgule' } onChange={ v => this.onChangeFilters({ semicolonSeparator: v }) } disabled={ disabled } checked={ filters.semicolonSeparator } />
                            <Checkbox col={ 3 } label={ 'Espace' } onChange={ v => this.onChangeFilters({ spaceSeparator: v }) } disabled={ disabled } checked={ filters.spaceSeparator } />
                            <Checkbox col={ 3 } label={ 'Tabulation' } onChange={ v => this.onChangeFilters({ tabulationSeparator: v }) } disabled={ disabled } checked={ filters.tabulationSeparator } />
                        </StyledFieldSet>
                    </div>
                    <StyledFieldSet>
                        <StyledLegend>{ i18n.parameterSelection }</StyledLegend>
                        <div className='row no-margin valign-wrapper padding-top-1'>
                            <Checkbox col={ 3 } label={ i18n.hasHeader } onChange={ v => this.onChangeFilters({ hasHeader: v }) } disabled={ disabled } checked={ filters.hasHeader } />
                            <NumberField col={ 2 } title={ i18n.nbLine } value={ filters.nbHeaders } onChange={ v => this.onChangeFilters({ nbHeaders: v }) } disabled={ disabled } floatValue />
                            <Input col={ 3 } title={ i18n.formatDate } value={ filters.dateFormat } onChange={ v => this.onChangeFilters({ dateFormat: v }) } disabled={ disabled }/>
                            <Checkbox col={ 4 } label={ i18n.dateandHour } onChange={ v => this.onChangeFilters({ hourWithDate: v }) } disabled={ disabled } checked={ filters.hourWithDate } />
                        </div>
                        <div className='row no-margin'>
                            <NumberField col={ 3 } title={ i18n.excludedValue } value={ filters.excludedValue } onChange={ v => this.onChangeFilters({ excludedValue: v }) } disabled={ disabled } floatValue />
                            { this.props.isPiezo && (
                                <RadioButtons col={ 3 } elements={ getMeasureCoteList() } selected={ filters.measureCote } onChange={ v => this.onChangeFilters({ measureCote: v }) } title={ i18n.ratingExpression } disabled={ disabled }/>
                            )}
                            <div className='col s6' />
                        </div>
                    </StyledFieldSet>
                    <div className='row no-margin'>
                        <h6>{ i18n.indexStartsAt0 }</h6>
                    </div>
                    {
                        (parameters.length > 0) && (
                            <div className='job-parameter-table'>
                                <Table
                                    showTitle={ false }
                                    data={ parameters }
                                    nbPerPageLabel={ nbPerPageLabelShort }
                                    type={ { headers: [ ...baseHeaders, ...hourColumn, ...columnHeaders ] } }
                                    sortable
                                    condensed
                                    paging
                                    customHeaders={ customHeaders }
                                />
                            </div>
                        )
                    }
                </div>
            )
        }
        return <ProgressCard progress={ this.state.progress }/>
    }
}

CSVGenericPiezoManualPanel.propTypes = {
    job: PropTypes.instanceOf(DtoJob).isRequired,
    onChangeJob: PropTypes.func.isRequired,
    piezometryDataTypes: arrayOf(DtoParametrageDataType),
    piezometer: PropTypes.instanceOf(DtoPiezometer),
    isEditMode: PropTypes.bool,
    selectedDataType: PropTypes.number,
    isPiezo: PropTypes.bool,
}

CSVGenericPiezoManualPanel.defaultProps = {
    isPiezo: true,
}

const mapStateToProps = store => ({
    piezometryDataTypes: store.PiezometryReducer.piezometryDataTypes,
    piezometer: store.StationReducer.piezometer,
})

export default connect(mapStateToProps)(CSVGenericPiezoManualPanel)