import { Grid } from '@mui/material'
import i18n from 'simple-react-i18n'
import React from 'react'
import Row from '../../../../components/react/Row'
import RadioButtons from '../../../../components/forms/RadioButtons'
import { hasBooleanValue } from '../../../../utils/NumberUtil'
import SelectStationComponent from '../../../../components/modal/SelectStationComponent'
import Input from '../../../../components/forms/Input'
import { groupBy, orderBy } from 'lodash'
import SimpleDatePicker from '../../../../components/forms/SimpleDatePicker'
import NumberField from '../../../../components/forms/NumberField'
import { getColorCircleElement } from '../../../../utils/ColorUtil'
import { getDate } from '../../../../utils/DateUtil'
import ReactEchartsCore from 'echarts-for-react/lib/core'
import echarts from 'echarts/lib/echarts'
import { IPSColorIndex } from '../MapSituationUtils'
import MultiContributorsAutocomplete from 'referencial/components/contributor/components/MultiContributorsAutocomplete'
import StepperDialog from 'components/modal/StepperDialog'
import { ButtonMUI } from 'components/styled/Buttons'
import { StyledFieldSet, StyledLegend } from 'components/StyledElements'
import Checkbox from '../../../../components/forms/Checkbox'

const STEP = {
    STATIONS: 0,
    CALCULATE: 1,
}

const getOndePie = (results, date) => {
    if (results.length) {
        const group = groupBy(results, 'trendColor')
        const chartData = orderBy(Object.keys(group).map(key => {
            return {
                value: group[key].length,
                name: key === 'undefined' ? i18n.noClass : group[key][0].comment,
                color: key === 'undefined' ? 'gray' : (group[key][0].trendColor==='white' ? '#C0C0C0' : group[key][0].trendColor),
            }
        }), d => IPSColorIndex(d.color))

        const options = {
            color: chartData.map(o => o.color),
            tooltip: {
                trigger: 'item',
                formatter: '{b} : {c} ({d}%)',
            },
            series: [
                {
                    type: 'pie',
                    data: chartData,
                    avoidLabelOverlap: false,
                    radius: ['50%', '70%'],
                    label: {
                        normal: {
                            show: false,
                            position: 'center',
                        },
                    },
                    labelLine: {
                        normal: {
                            show: false,
                        },
                    },
                },
            ],
        }

        return (
            <ReactEchartsCore
                echarts={ echarts }
                option={ options }
                notMerge={ true }
                lazyUpdate={ true }
                className={ 'row no-margin' }
                style={ { height: 200 } }
            />
        )
    }
    return null
}

const getOndeLegend = (results, date) => {
    return (
        <Row className='padding-left-1'>
            <ul>
                <li> <span dangerouslySetInnerHTML={{ __html: getColorCircleElement('#C0C0C0') }} />{ i18n.missingData}</li>
            </ul>
            <ul>
                <li> <span dangerouslySetInnerHTML={{ __html: getColorCircleElement('blue') }} />{ i18n.visibleAcceptableFlow}</li>
                <li> <span dangerouslySetInnerHTML={{ __html: getColorCircleElement('yellow') }} />{ i18n.lowVisibleFlow }</li>
                <li> <span dangerouslySetInnerHTML={{ __html: getColorCircleElement('orange') }} />{ i18n.flowNotVisible }</li>
                <li> <span dangerouslySetInnerHTML={{ __html: getColorCircleElement('red') }} />{ i18n.assec }</li>
                <li> <span dangerouslySetInnerHTML={{ __html: getColorCircleElement('gray') }} />{ i18n.observationImpossible }</li>
            </ul>
        </Row>
    )
}

const getOndesDefaultParams = () => ({
    allStations: true,
    stationIds: [],
    nbDaysWithoutMeasuresMax: 30,
    stats: [1, 2, 3, 4, 5],
})

const getOndeDialog = (state, changeState, onSave, stations, dataTypes, st, contributors = []) => {
    const { msSelected, paramsDialogIsOpen } = state

    const params = JSON.parse(msSelected.params)

    const change = changes => changeState({ msSelected: { ...msSelected, ...changes } })

    const changeParams = (changes) => {
        changeState({ msSelected: { ...msSelected, params: JSON.stringify({ ...params, ...changes }) } })
    }

    return (
        <StepperDialog
            steps={[
                {
                    label: i18n.stations,
                    constant: STEP.STATIONS,
                },
                {
                    label: i18n.calcul,
                    constant: STEP.CALCULATE,
                },
            ]}
            open={paramsDialogIsOpen}
            title={i18n.calculationParams}
            sx={{ minHeight: 0 }}
            closeDialog={() => changeState({ paramsDialogIsOpen: false })}
            renderSaveButton={(step, resetStep) => (step === STEP.CALCULATE) && (
                <Grid container justifyContent='space-between'>
                    <Grid item xs={6}>
                        <ButtonMUI
                            onClick={() => {
                                resetStep()
                                onSave()
                            }}
                            variant='contained'
                            color='primary'
                        >
                            {i18n.save}
                        </ButtonMUI>
                    </Grid>
                    <Grid item xs={6}>
                        <ButtonMUI
                            onClick={() => {
                                resetStep()
                                onSave(true)
                            }}
                            variant='contained'
                            color='primary'
                        >
                            {i18n.saveAndCalculate}
                        </ButtonMUI>
                    </Grid>
                </Grid>
            )}
        >
            {(step) => {
                switch (step) {
                    case STEP.STATIONS:
                        const selectedStations = stations.filter(p => params.stationIds.includes(p.id))
                        return (
                            <Grid container spacing={1}>
                                <Grid item xs={6}>
                                    <RadioButtons
                                        elements={ [{ value: true, label: i18n.allStations }, { value: false, label: i18n.chooseStations }] }
                                        selected={ hasBooleanValue(params.allStations) ? params.allStations : false }
                                        onChange={ v => changeParams({ allStations: v }) }
                                        title={ '' }
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    {!(hasBooleanValue(params.allStations) ? params.allStations : false) && (
                                        <SelectStationComponent
                                            selectedStations={selectedStations}
                                            campaignEvents={[]}
                                            stationType={st}
                                            onChangeSelectedStation={tmpStations => changeParams({ stationIds: tmpStations.map(p => p.id) })}
                                        />
                                    )}
                                </Grid>
                            </Grid>
                        )
                    case STEP.CALCULATE:
                        const calculType = msSelected.calculType || 'now'
                        return (
                            <div>
                                <StyledFieldSet>
                                    <StyledLegend>{i18n.calcul}</StyledLegend>
                                    <Grid container sx={{ padding: '1rem' }} spacing={1}>
                                        <Grid container item xs={12} spacing={2}>
                                            <Grid item xs={6}>
                                                <Input
                                                    title={i18n.name}
                                                    onChange={v => change({ name: v })}
                                                    value={msSelected.name}
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <MultiContributorsAutocomplete
                                                    label={i18n.administrator}
                                                    disablePortal={false}
                                                    options={contributors}
                                                    values={msSelected.contributorCode}
                                                    onChange={contributorCode => change({ contributorCode })}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid container item xs={12} spacing={2}>
                                            <Grid item xs={6}>
                                                <RadioButtons
                                                    elements={ [
                                                        { code: 'daily', name: i18n.allDays },
                                                        { code: 'now', name: i18n.today },
                                                        { code: 'date', name: i18n.chooseDate },
                                                        { code: 'period', name: i18n.periode },
                                                    ] }
                                                    selected={ calculType }
                                                    onChange={ v => change({ calculType: v, scheduled: v === 'daily' }) }
                                                    title={ i18n.calculType }
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid container item xs={12} spacing={2}>
                                            {calculType === 'date' && (
                                                <Grid item xs={5}>
                                                    <SimpleDatePicker
                                                        label={i18n.date}
                                                        value={msSelected.startPeriod}
                                                        onChange={date => change({ startPeriod: date })}
                                                    />
                                                </Grid>
                                            )}
                                        </Grid>
                                        <Grid container item xs={12} spacing={2}>
                                            {calculType === 'period' && (
                                                <>
                                                    <Grid container item xs={5} spacing={2}>
                                                        <Grid item xs={6}>
                                                            <SimpleDatePicker
                                                                label={i18n.startDate}
                                                                value={msSelected.startPeriod}
                                                                onChange={date => change({ startPeriod: date })}
                                                                max={msSelected.endPeriod}
                                                                disabled={calculType !== 'period'}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <SimpleDatePicker
                                                                label={i18n.endDate}
                                                                value={msSelected.endPeriod}
                                                                onChange={date => change({ endPeriod: date })}
                                                                min={msSelected.startPeriod}
                                                                disabled={calculType !== 'period'}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={7}>
                                                        <RadioButtons
                                                            elements={[
                                                                { code: 'day', name: i18n.day },
                                                                { code: 'week', name: i18n.week },
                                                                { code: 'month', name: i18n.month },
                                                            ]}
                                                            selected={msSelected.recurrence}
                                                            onChange={v => change({ recurrence: v })}
                                                            title={i18n.recurrence}
                                                            disabled={calculType !== 'period'}
                                                        />
                                                    </Grid>
                                                </>
                                            )}
                                        </Grid>
                                    </Grid>
                                </StyledFieldSet>
                                <StyledFieldSet>
                                    <StyledLegend>{i18n.advancedOptions}</StyledLegend>
                                    <Grid container sx={{ padding: '1rem' }} spacing={1}>
                                        <Grid item xs={6}>
                                            <NumberField
                                                title={i18n.nbDaysWithoutAcceptableData}
                                                value={params.nbDaysWithoutMeasuresMax}
                                                onChange={v => changeParams({ nbDaysWithoutMeasuresMax: v })}
                                                min={1}
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Checkbox
                                                checked={msSelected.exportCSV}
                                                label={i18n.exportToCSV}
                                                onChange={v => change({ exportCSV: v })}
                                            />
                                        </Grid>
                                    </Grid>
                                </StyledFieldSet>
                            </div>
                        )
                    default:
                        return null
                }
            }}
        </StepperDialog>
    )
}

export { getOndeDialog, getOndeLegend, getOndesDefaultParams, getOndePie }