import AdministrationAction from 'administration/actions/AdministrationAction'
import { useEffect, useMemo } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'

const useGlobalParameter = (field, parser = setting => setting) => {
    const dispatch = useDispatch()
    const {
        globalParameters,
    } = useSelector(store => ({
        globalParameters: store.AdministrationReducer.globalParameters,
    }), shallowEqual)

    useEffect(() => {
        if (globalParameters.length === 0) {
            dispatch(AdministrationAction.fetchGlobalParameters())
        }
    }, [])

    const res = useMemo(() => {
        const setting = globalParameters.find(s => s.parameter === field)
        return parser(setting?.value)
    }, [globalParameters, field])
    return res
}

export default useGlobalParameter