import React, { Component } from 'react'
import { connect } from 'react-redux'
import { arrayOf, instanceOf } from '../../../../utils/StoreUtils'
import Row from '../../../../components/react/Row'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import Select from '../../../../components/forms/Select'
import moment from 'moment'
import NumberField from '../../../../components/forms/NumberField'
import { getYear } from '../../../../utils/DateUtil'
import { getStateLabels } from '../../../../utils/QualityUtils'
import Textarea from '../../../../components/forms/Textarea'
import WatermassAction from '../actions/WatermassAction'
import WatermassStateDto from '../dto/WatermassStateDto'
import WatermassDto from '../dto/WatermassDto'
import DtoQualityState from '../../../../quality/dto/states/DtoQualityState'


class WatermassStateModal extends Component {
    constructor(props) {
        super(props)
        this.state = { startDate: moment().valueOf(), watermass: this.props.watermass.code, ...props.watermassState }
    }

    componentWillReceiveProps(nextProps) {
        if (!this.props.popupIsOpen && nextProps.popupIsOpen) {
            $('#saveStates').on('click', () => {
                if (this.props.isNew) {
                    this.create()
                } else {
                    this.update()
                }
            })
            $('#deleteStates').on('click', () => {
                this.delete()
            })
        }
    }

    update = () => {
        this.props.update(this.state).then(() => {
            this.props.fetchWatermassStates(this.props.watermass.id)
            $('.modal').modal('close')
        })
    }

    create = () => {
        this.props.create(this.state).then(() => {
            this.props.fetchWatermassStates(this.props.watermass.id)
            $('.modal').modal('close')
        })
    }

    delete = () => {
        this.props.delete(this.state.watermass, this.state.stateId).then(() => {
            this.props.fetchWatermassStates(this.props.watermass.id)
            $('.modal').modal('close')
        })
    }

    render() {
        const valueList = Object.keys(getStateLabels()).map(key => ({ code: parseInt(key), name: getStateLabels()[key].value }))
        return (
            <div>
                <Row>
                    <Select col={ 8 } options={ this.props.qualityStates } value={ this.state.stateId } disabled={ !this.props.isNew }
                        label={ i18n.state } onChange={ v => this.setState({ stateId: v }) }
                    />
                    <NumberField col={ 4 } title={ i18n.year } value={ getYear(this.state.startDate) } disabled={ !this.props.isNew }
                        onChange={ v => v && this.setState({ startDate: moment().year(v).valueOf() }) }
                    />
                </Row>
                <Row>
                    <Select col={ 3 } options={ valueList } label={ i18n.value } onChange={ v => this.setState({ value: v }) } value={ this.state.value }/>
                    <Textarea col={ 9 } title={ i18n.comment } onChange={ v => this.setState({ comment: v }) } value={ this.state.comment } />
                </Row>
            </div>
        )
    }
}

WatermassStateModal.propTypes = {
    watermass: PropTypes.instanceOf(WatermassDto),
    watermassStates: arrayOf(WatermassStateDto),
    qualityStates: arrayOf(DtoQualityState),
    isNew: PropTypes.bool,
    watermassState: instanceOf(WatermassStateDto),
    popupIsOpen: PropTypes.bool,
    update: PropTypes.func,
    create: PropTypes.func,
    delete: PropTypes.func,
    fetchWatermassStates: PropTypes.func,
}

const mapStateToProps = store => {
    return {
        watermass: store.WatermassReducer.watermass,
        watermassStates: store.WatermassReducer.watermassStates,
        qualityStates: store.QualityReducer.qualityStates,
        popupIsOpen: store.SieauReducer.popupIsOpen,
    }
}

const mapDispatchToProps = {
    update: WatermassAction.updateWatermassState,
    create: WatermassAction.createWatermassState,
    delete: WatermassAction.deleteWatermassState,
    fetchWatermassStates: WatermassAction.fetchWatermassStates,
}

export default connect(mapStateToProps, mapDispatchToProps)(WatermassStateModal)