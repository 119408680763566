export default class DtoAnalysis {
    constructor(obj) {
        this.id = obj.id // Double
        this.sampleCode = obj.sample // Double //#
        this.stationId = obj.stationId // Option[Double]
        this.date = obj.dateStart // Option[DateTime] //#
        this.hourStart = obj.hourStart // Option[DateTime]
        this.producerId = obj.producerId // Option[Double]
        this.producerRef = obj.producerRef // Option[String]
        this.laboratoryId = obj.laboratoryId // Option[Double]
        this.laboratoryNum = obj.laboratoryNum // Option[String]
        this.laboratoryRef = obj.laboratoryRef // Option[String]
        this.accreditation = obj.accreditation // Option[String]
        this.result = obj.measure // Option[Double] //#
        this.parameter = obj.parameter // Option[String]
        this.unit = obj.unit // Option[String]
        this.remarkCode = obj.remarkCode // Option[String]
        this.localization = obj.localization // Option[String]
        this.trouble = obj.trouble // Option[String]
        this.qualification = obj.qualification // Option[String]
        this.method = obj.method // Option[String]
        this.fractionCode = obj.fractionCode // Option[String]
        this.methodFraction = obj.methodFraction // Option[String]
        this.analysisSupport = obj.support // Option[String] //#
        this.status = obj.status // Option[String]
        this.commentAnalysis = obj.commentAnalysis // Option[String]
        this.commentResult = obj.commentResult // Option[String]
        this.sampleNumber = obj.sampleNumber // Option[Int]
        this.uncertainty = obj.uncertainty // Option[Double]
        this.solventCode = obj.solventCode // Option[String]
        this.extractionMethod = obj.extractionMethod // Option[String]
        this.yieldExtraction = obj.yieldExtraction // Option[Double]
        this.volumeFilter = obj.volumeFilter // Option[Double]
        this.responsibleCode = obj.responsibleCode // Option[Int]
        this.resultRef = obj.resultRef // Option[Double]
        this.quantificationLimit = obj.quantificationLimit // Option[Double]
        this.detectionLimit = obj.detectionLimit // Option[Double]
        this.saturationThreshold = obj.saturationThreshold // Option[Double]
        this.operationCode = obj.operationCode // Option[String]
        this.fileCode = obj.fileCode // Option[String]
        this.startDepth = obj.startDepth // Option[Double]
        this.endDepth = obj.endDepth // Option[Double]
        this.operationDate = obj.operationDate // Option[DateTime]
        this.sampleDate = obj.sampleDate // Option[DateTime]
        this.sampler = obj.sampler // Option[Double]
        this.point = obj.point // Option[Double]
        this.operationId = obj.operationId // Option[Double]


        // difference between back & front
        this.sample = obj.sample // Double
        this.dateStart = obj.dateStart // Option[DateTime]
        this.measure = obj.measure // Option[Double]
        this.support = obj.support // Option[String]
    }
}