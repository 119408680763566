import PowerSupplyTypeDto from 'materiel/components/powerSupply/dto/PowerSupplyTypeDto'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import Checkbox from '../../../../components/forms/Checkbox'
import NumberField from '../../../../components/forms/NumberField'
import CentralTypeDto from '../../../../materiel/components/central/dto/CentralTypeDto'
import { hasValue } from '../../../../utils/NumberUtil'
import { EVENT_ACTION } from '../../../constants/PiezometryEventConstants'
import { getTitleCard, getYesOrNo } from '../EventFormUtils'

const EventCentralCard = ({
    central = {},
    formActions = [],
    disabled = {},
    lastReplacementDate = '',
    setFormAction = () => {},
    getBooleanChoiceWithAction = () => {},
    centralTypes = [],
    powerSupplyTypes = [],
    hasPowerSupply = true,
}) => {
    const powerSupplyType = powerSupplyTypes.find(t => t.id === central.powerSupplyType)
    return (
        <div className='card margin-top-1'>
            {getTitleCard(i18n.central, 'central', central, centralTypes)}
            <div className='card-content margin-top-1'>
                {
                    hasPowerSupply && (
                        <div className='row no-margin'>
                            <div className='col s8'>
                                <div className='row no-margin'>
                                    <NumberField
                                        value={(formActions.find(a => a.managementRule === EVENT_ACTION.MESURE_TENSION_CENTRALE && a.materielId === central.id) || {}).value}
                                        title={i18n.centralVoltage}
                                        floatValue
                                        onChange={v => setFormAction(EVENT_ACTION.MESURE_TENSION_CENTRALE, v, central.id, hasValue(v))}
                                        {...disabled}
                                    />
                                </div>
                                <div className='row no-margin'>
                                    <h6>{`${i18n.lastReplacement} : ${lastReplacementDate}`}</h6>
                                </div>
                            </div>
                            <div className='col s3 offset-s1'>
                                <Checkbox
                                    label={i18n.replacement}
                                    checked={formActions.find(a => a.managementRule === EVENT_ACTION.REMPLACEMENT_PILES_CENTRALE && a.materielId === central.id)}
                                    onChange={v => setFormAction(EVENT_ACTION.REMPLACEMENT_PILES_CENTRALE, null, central.id, v)}
                                    active={disabled.active}
                                    disabled={powerSupplyType?.isNotReplaceable || disabled.disabled}
                                />
                            </div>
                        </div>
                    )
                }
                <div className='row no-margin'>
                    <div className='col s8'>
                        <table>
                            {getYesOrNo()}
                            {getBooleanChoiceWithAction(i18n.resettingStations, EVENT_ACTION.REINITIALISATION_STATION, central.id, disabled)}
                            {getBooleanChoiceWithAction(i18n.firmwareUpdate, EVENT_ACTION.FIRMWARE_MIS_A_JOUR, central.id, disabled)}
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}

EventCentralCard.propTypes = {
    central: PropTypes.object,
    formActions: PropTypes.arrayOf(PropTypes.object),
    disabled: PropTypes.object,
    lastReplacementDate: PropTypes.string,
    setFormAction: PropTypes.func,
    getBooleanChoiceWithAction: PropTypes.func,
    centralTypes: PropTypes.arrayOf(PropTypes.instanceOf(CentralTypeDto)),
    powerSupplyTypes: PropTypes.arrayOf(PropTypes.instanceOf(PowerSupplyTypeDto)),
    hasPowerSupply: PropTypes.bool,
}

const mapStateToProps = store => ({
    centralTypes: store.CentralReducer.centralTypes,
    powerSupplyTypes: store.PowerSupplyReducer.powerSupplyTypes,
})

export default connect(mapStateToProps)(EventCentralCard)