import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Grid2 } from '@mui/material'
import useTitle from '../../../utils/customHook/useTitle'
import i18n from 'simple-react-i18n'
import Input from '../../../components/forms/Input'
import Select from '../../../components/forms/Select'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { getSortedTableData } from '../../../components/datatable/TableUtils'
import SieauAction from '../../../components/sieau/SieauAction'
import { nbPerPageLabel } from '../../../referencial/constants/ReferencialConstants'
import PerimeterDto from '../../dto/PerimeterDto'
import useActions from '../../../utils/customHook/useActions'
import CityAction from '../../../referencial/components/city/actions/CityAction'
import PerimetersStepper from './components/PerimetersStepper'
import ExportFileModal from '../../../components/modal/ExportFileModal'
import { capitalize, compact } from 'lodash'
import PerimetersThunk from '../../action/PerimetersThunk'
import { NEW_PERIMETER, PERIMETERS_STATES_COLORS } from '../constants/PerimetersConstants'
import useSandreList from '../../../utils/customHook/useSandreList'
import ContributorThunk from '../../../referencial/components/contributor/actions/ContributorThunk'
import MultiContributorsAutocomplete
    from '../../../referencial/components/contributor/components/MultiContributorsAutocomplete'
import PerimetersImportStepper from '../perimeters/components/PerimetersImportStepper'
import { exportFile } from '../../../utils/ExportDataUtil'
import { push } from '@lagunovsky/redux-react-router'
import useProgressDispatch from 'utils/customHook/useProgressDispatch'
import ProgressCard from 'components/card/ProgressCard'
import { WhiteCard } from 'components/styled/Card'
import UpdatePanel from 'components/updatePanel/UpdatePanel'
import ReferencialAction from 'referencial/action/ReferencialAction'
import PerimeterStatus from '../../../components/PerimeterStatus'
import Card from 'components/card/Card'
import { MAIN_RADIUS } from 'utils/constants/Theme'
import { useParams } from 'react-router'
import ConfirmModal from '../../../components/modal/ConfirmModal'
import PerimetersAction from 'perimeters/action/PerimetersAction'
import { CardTable } from 'components/datatable/NewTable'

const PERIMETER_HEADER = ['name', 'type', 'zoning', 'cities', 'nbParcels', 'nbOwners', 'nbOperators', 'status']

const PerimetersTable = ({
    perimeters = [],
    setPerimeter,
    setDeletePerimeterId,
    readMode,
}) => {
    const {
        selectedSearchValues,
        citiesIndex,
    } = useSelector(store => ({
        selectedSearchValues: store.AdministrationReducer.selectedSearchValues,
        citiesIndex: store.CityReducer.citiesIndex,
    }), shallowEqual)

    const { perimeter: searchValues } = selectedSearchValues
    const perimeterTypes = useSandreList('PERIMETRES.TYPE')

    const dispatch = useDispatch()

    const lexiconStatus = useSandreList('PARCELLES.STATUT')

    const perimetersFormated = perimeters.map(perimeter => {
        const perimeterCities = perimeter.cities?.map(p => capitalize(citiesIndex[p]?.name))

        return {
            perimeterId: perimeter.perimeterId,
            folderId: perimeter.folderId,
            name: perimeter.name,
            type: perimeterTypes.find(pt => pt.code === perimeter.perimeterType)?.name || perimeter.perimeterType || '',
            perimeterType: perimeter.perimeterType,
            zoning: perimeter.zoning,
            cities: perimeterCities?.join(', ') ?? '',
            nbParcels: perimeter.parcels,
            nbOwners: perimeter.ownersNumber,
            nbOperators: '',
            status: (
                <PerimeterStatus
                    color={PERIMETERS_STATES_COLORS[perimeter.status] || PERIMETERS_STATES_COLORS[0]}
                    label={lexiconStatus.find(s => s.code === perimeter.status)?.name ?? ''}
                />
            ),
        }
    })

    const initialSort = searchValues?.columnName && { column: searchValues.columnName, sort: searchValues.sort }

    const stationsHash = stations => stations.map(s => s.code).join('')

    const onTableSort = (columnName, sort) => {
        const sortedStations = getSortedTableData(perimetersFormated, { column: columnName, sort }, false)
        if (!searchValues || !searchValues.stations || stationsHash(searchValues.stations) !== stationsHash(sortedStations)) {
            dispatch(SieauAction.update('selectedSearchValues', 'perimeter', {
                stations: sortedStations,
                columnName,
                sort,
            }))
        }
    }

    const exportData = () => {
        exportFile({
            data: perimeters.length ? perimeters.map(d => ({
                identifier: { value: d.perimeterId },
                name: { value: d.name || '' },
                type: perimeterTypes.find(pt => pt.code === d.perimeterType).label || d.perimeterType || '',
                zoning: { value: d.zoning || '' },
                cities: { value: '' },
                nbParcels: { value: d.parcels.toString() },
                nbOwners: { value: d.ownersNumber.toString() },
                nbOperators: { value: '' },
                status: { value: '' },
                headers: ['identifier', ...PERIMETER_HEADER],
            })) : [],
            exportType: 'xlsx',
            titleFile: i18n.perimeters,
        })
    }

    return (
        <CardTable
            title={i18n.perimeters}
            actions={[{
                icon: 'download',
                tooltip: i18n.export,
                onClick: () => exportData(),
            }, {
                icon: 'note_add',
                tooltip: i18n.add,
                onClick: () => {
                    setPerimeter({ perimeterId: NEW_PERIMETER })
                },
                displayed: !readMode,
            }]}

            rows={perimetersFormated}
            rowsPerPageOptions={nbPerPageLabel}
            defaultSort={initialSort}
            onClickRow={setPerimeter}
            lineActions={[
                { icon: 'delete', onClick: (p) => setDeletePerimeterId(p.perimeterId), displayed: !readMode },
            ]}
            onSort={onTableSort}
            headers={PERIMETER_HEADER}
            headersLabel={{
                nbParcels: (<span style={{ whiteSpace: 'pre-wrap' }}>{i18n.nbParcelsReturnLine}</span>),
                nbOwners: (<span style={{ whiteSpace: 'pre-wrap' }}>{i18n.nbOwnersReturnLine}</span>),
                nbOperators: (<span style={{ whiteSpace: 'pre-wrap' }}>{i18n.nbOperatorsReturnLine}</span>),
            }}
        />
    )
}

PerimetersTable.propTypes = {
    perimeters: PropTypes.arrayOf(PerimeterDto),
    setPerimeter: PropTypes.func,
    setDeletePerimeterId: PropTypes.func,
    readMode: PropTypes.bool,
}

const PerimeterDashboard = () => {
    const { id } = useParams()

    const {
        cities,
        contributors,
        perimetersFolder,
        perimeters,
        sandreCodes,
        perimeterFolderLastEvent,
        deeds,
    } = useSelector(store => ({
        cities: store.CityReducer.cities,
        contributors: store.ContributorReducer.contributors,
        perimetersFolder: store.PerimetersReducer.perimetersFolder,
        perimeters: store.PerimetersReducer.perimeters,
        sandreCodes: store.ReferencialReducer.sandreCodes,
        perimeterFolderLastEvent: store.PerimetersReducer.perimeterFolderLastEvent,
        deeds: store.PerimetersReducer.deeds,
    }), shallowEqual)

    const parsedId = parseInt(id)

    const lexiconStatus = useSandreList('PARCELLES.STATUT')
    const lexiconStates = useSandreList('PARCELLES.ETAT')

    const [perimeterFolder, setPerimeterFolder] = useState({})

    const [readMode, setReadMode] = useState(true)
    const [importStepperIsOpen, setImportStepperIsOpen] = useState(false)
    const [exportOpen, setExportOpen] = useState(false)
    const [perimeter, setPerimeter] = useState({})
    const [deletePerimeterId, setDeletePerimeterId] = useState()

    const dispatch = useDispatch()

    const { isLoaded, progress } = useProgressDispatch(() => compact([
        dispatch(PerimetersThunk.getPerimeters(id)),
        !cities.length && dispatch(CityAction.fetchCities()),
        !perimetersFolder.length && dispatch(PerimetersThunk.getPerimetersFolders()),
        !sandreCodes.length && dispatch(ReferencialAction.fetchSandreCodes()),
        !contributors.length && dispatch(ContributorThunk.fetchContributors()),
        !perimeterFolderLastEvent?.perimeterFolderId && dispatch(PerimetersThunk.getPerimeterFolderLastEvent(parsedId)),
        !deeds.length && dispatch(PerimetersThunk.getMortgages()),
    ]), [])

    useEffect(() => {
        if (perimetersFolder.length) {
            const findedPerimeterFolder = perimetersFolder.find(pf => pf.folderId === parsedId) || {}
            setPerimeterFolder(findedPerimeterFolder)
        }
    }, [parsedId, perimetersFolder])

    useEffect(() => {
        dispatch(PerimetersThunk.getPerimetersFolderPrivateOwners(parsedId))
        dispatch(PerimetersThunk.getPerimetersFolderCompanyOwners(parsedId))
    }, [parsedId])

    useTitle(() => [{
        title: i18n.perimetersFolder,
        href: 'perimeter',
    }, {
        title: perimeterFolder.folderId ? perimeterFolder.name : id,
        href: `perimeter/${id}/dashboard`,
    }], [id, perimeterFolder])

    useActions(() => !readMode ? ({
        import: () => setImportStepperIsOpen(true),
        save: () => {
            dispatch(PerimetersAction.updatePerimetersFolder({
                folderId: parsedId,
                name: perimeterFolder.name,
                collectivity: perimeterFolder.collectivity,
                state: perimeterFolder.state,
                cityName: perimeterFolder.cityName,
            })).then(() => {
                dispatch(PerimetersThunk.getPerimetersFolders())
                setReadMode(true)
            })
        },
        cancel: () => setReadMode(true),
        delete: () => dispatch(PerimetersAction.deletePerimetersFolder(parsedId)).then(() => {
            dispatch(push('/perimeter/dashboard'))
        }),
    }) : ({
        export: () => setExportOpen(true),
        edit: () => setReadMode(false),
    }), [readMode, parsedId, perimeterFolder])

    const exportFormat = [{
        type: i18n.excelFile,
        callback: () => {},
    }, {
        type: i18n.csvFile,
        callback: () => {},
    }]

    return isLoaded ? (
        <Grid2 container columnSpacing={2} alignItems='flex-start' sx={{ padding: '0.75rem 1rem 0 0' }}>
            <Grid2 container size={9} direction='column' rowSpacing={1}>
                <Grid2>
                    <WhiteCard round>
                        <Grid2 container alignItems='center' sx={{ padding: '1rem' }} spacing={1}>
                            <Grid2 size={8}>
                                <Input
                                    title={i18n.name}
                                    value={perimeterFolder.name}
                                    onChange={name => setPerimeterFolder(prev => ({ ...prev, name }))}
                                    disabled={readMode}
                                    clearFunction
                                />
                            </Grid2>
                            <Grid2 size={8}>
                                <Input
                                    title={i18n.city}
                                    value={perimeterFolder.cityName}
                                    onChange={cityName => setPerimeterFolder(prev => ({ ...prev, cityName }))}
                                    disabled={readMode}
                                    clearFunction
                                />
                            </Grid2>
                            <Grid2 size={8}>
                                <MultiContributorsAutocomplete
                                    label={i18n.collectivity}
                                    onChange={collectivity => setPerimeterFolder(prev => ({ ...prev, collectivity }))}
                                    values={perimeterFolder.collectivity}
                                    options={contributors}
                                    disabled={readMode}
                                />
                            </Grid2>
                            <Grid2 container size={8} columnSpacing={2} rowSpacing={1}>
                                <Grid2 size={6}>
                                    <Select
                                        options={lexiconStates}
                                        label={i18n.stateOfProgressOfProcedure}
                                        nullLabel='&nbsp;'
                                        value={perimeterFolder.state}
                                        disabled={readMode}
                                        noSort
                                        onChange={state => setPerimeterFolder(prev => ({ ...prev, state }))}
                                    />
                                </Grid2>
                                <Grid2 size={6} sx={{ '& label': { color: readMode ? 'rgba(0, 0, 0, 0.42)' : '#000', fontWeight: 'bold', fontSize: '1rem' } }}>
                                    <label>{i18n.status}</label>
                                    <PerimeterStatus
                                        color={PERIMETERS_STATES_COLORS[perimeterFolder.status] || PERIMETERS_STATES_COLORS[0]}
                                        label={lexiconStatus.find(s => s.code === perimeterFolder.status)?.name ?? ''}
                                    />
                                </Grid2>
                            </Grid2>
                        </Grid2>
                    </WhiteCard>
                </Grid2>
                <Grid2>
                    <ConfirmModal
                        isOpen={!!deletePerimeterId}
                        title={i18n.sureDeletePerimeter}
                        onValidate={() => {
                            dispatch(PerimetersAction.deletePerimeter({
                                folderId: perimeterFolder.folderId,
                                perimeterId: deletePerimeterId,
                            }))
                            setDeletePerimeterId(undefined)
                        }}
                        onClose={() => setDeletePerimeterId(undefined)}
                    />
                    <PerimetersTable
                        perimeters={perimeters}
                        setPerimeter={setPerimeter}
                        setDeletePerimeterId={setDeletePerimeterId}
                        readMode={readMode}
                    />
                </Grid2>
            </Grid2>
            <Grid2 container direction='column' size={3} rowSpacing={1.25}>
                <Grid2>
                    <UpdatePanel
                        updateDate={perimeterFolder.updateDate}
                        updateLogin={perimeterFolder.updateLogin}
                        round
                    />
                </Grid2>
                {!!perimeterFolderLastEvent?.comment && (
                    <Grid2>
                        <Card noMargin cardStyle={{ width: '100%', padding: '10px', borderRadius: MAIN_RADIUS }}>
                            <span>{perimeterFolderLastEvent.comment}</span>
                        </Card>
                    </Grid2>
                )}
            </Grid2>
            <PerimetersImportStepper
                open={importStepperIsOpen}
                setOpen={setImportStepperIsOpen}
            />
            <ExportFileModal
                data={[{
                    name: i18n.plotsList,
                    formats: exportFormat,
                }, {
                    name: i18n.ownersList,
                    formats: exportFormat,
                }, {
                    name: i18n.operatorsList,
                    formats: exportFormat,
                }]}
                open={exportOpen}
                onClose={() => setExportOpen(false)}
            />
            <PerimetersStepper
                folderId={parsedId}
                perimeter={perimeter}
                setPerimeter={setPerimeter}
                readMode={readMode}
            />
        </Grid2>
    ) : (
        <Grid2 size={12} sx={{ marginTop: '1rem' }}>
            <ProgressCard progress={progress} />
        </Grid2>
    )
}

export default PerimeterDashboard