import React, { useEffect, useState } from 'react'
import { Grid } from '@mui/material'
import PropTypes from 'prop-types'
import { instanceOf } from '../../../../utils/StoreUtils'
import DtoIAEauModel from '../../../../iaeau/dto/DtoIAEauModel'
import Input from '../../../../components/forms/Input'
import i18n from 'simple-react-i18n'
import ColorPicker from '../../../../components/forms/ColorPicker'
import { HORIZON_TYPE_LABEL, MODEL_OBJECTIVES, MODEL_TYPES } from '../../../../iaeau/constants/IAEauConstants'
import NumberField from '../../../../components/forms/NumberField'
import Select from '../../../../components/forms/Select'
import Checkbox from '../../../../components/forms/Checkbox'

const getARIMAParamsExpert = (params, changeParams) => {
    return (
        <>
            <Grid container justifyContent='center' alignItems='center' spacing={3}>
                <Grid item xs={4}>
                    <NumberField value={params.p} title='p' onChange={v => changeParams({ p: v })}/>
                </Grid>
                <Grid item xs={4}>
                    <NumberField value={params.q} title='q' onChange={v => changeParams({ q: v })}/>
                </Grid>
                <Grid item xs={4}>
                    <NumberField value={params.d} title='d' onChange={v => changeParams({ d: v })}/>
                </Grid>
            </Grid>
            <Grid container justifyContent='center' alignItems='center' spacing={3}>
                <Grid item xs={4}>
                    <NumberField value={params.P} title='P' onChange={v => changeParams({ P: v })}/>
                </Grid>
                <Grid item xs={4}>
                    <NumberField value={params.Q} title='Q' onChange={v => changeParams({ Q: v })}/>
                </Grid>
                <Grid item xs={4}>
                    <NumberField value={params.D} title='D' onChange={v => changeParams({ D: v })}/>
                </Grid>
            </Grid>
        </>
    )
}

const getAUGURESParamsExpert = (params, changeParams) => {
    return (
        <>
            <Grid container justifyContent='center' alignItems='center' spacing={3}>
                <Grid item xs={4}>
                    <NumberField value={params.average} title='Moyenne' onChange={v => changeParams({ average: v })}/>
                </Grid>
            </Grid>
        </>
    )
}

const getTarriBRGMParams = (params, changeParams, model) => {
    return (
        <>
            <Grid container justifyContent='center' alignItems='center' spacing={3}>
                <Grid item xs={4}>
                    <NumberField value={params.sensibility} title={`Sensibilité (${HORIZON_TYPE_LABEL[model?.horizonMode || 'days']})`}
                        onChange={v => changeParams({ sensibility: v })} min={2}
                    />
                </Grid>
            </Grid>
        </>
    )
}

const getAUGURESParams = (params, changeParams) => {
    return (
        <>
            <Grid container justifyContent='center' alignItems='center' spacing={3}>
                <Grid item xs={4}>
                    <Select value={params.lineToTake}
                        options={ [
                            { value: 'area', label: 'Intervale de confiance' },
                            { value: 'average', label: 'Moyenne' },
                            { value: 'median', label: 'Médiane' },
                            { value: 'years', label: 'Années les plus probables' },
                        ] }
                        label={i18n.displayMode} onChange={v => changeParams({ lineToTake: v })}
                    />
                </Grid>
                <Grid item xs={4}>
                    <NumberField value={params.nbYearsToTake} title='Nb années à sélectionner' min={0} max={5}
                        onChange={v => changeParams({ nbYearsToTake: v })} disabled={params.lineToTake !== 'years'}
                    />
                </Grid>
            </Grid>
        </>
    )
}

const getARIMAParams = (params, changeParams) => {
    return (
        <>
            <Grid container justifyContent='center' alignItems='center' spacing={3}>
                <Grid item xs={4}>
                    <Select value={params.measureGroupMode}
                        options={ [
                            { value: 'MAX', label: 'Mesures Max' },
                            { value: 'MIN', label: 'Mesures Min' },
                            { value: 'AVERAGE', label: 'Mesures moyennes' },
                        ] }
                        label='Regroupement des mesures' onChange={v => changeParams({ measureGroupMode: v })}
                    />
                </Grid>
            </Grid>
            <Grid container justifyContent='center' alignItems='center' spacing={3}>
                <Grid item xs={4}><Checkbox checked={params.area30} label='Intervale de confiance 30 %' onChange={v => changeParams({ area30: v })} /></Grid>
            </Grid>
            <Grid container justifyContent='center' alignItems='center' spacing={3}>
                <Grid item xs={4}><Checkbox checked={params.area50} label='Intervale de confiance 50 %' onChange={v => changeParams({ area50: v })} /></Grid>
            </Grid>
            <Grid container justifyContent='center' alignItems='center' spacing={3}>
                <Grid item xs={4}><Checkbox checked={params.area90} label='Intervale de confiance 90 %' onChange={v => changeParams({ area90: v })} /></Grid>
            </Grid>
        </>
    )
}


const ModelStepSettings = ({
    stationType,
    id, // station id
    selectedModel,
    changeModel,
    changeParams,
    params,
}) => {
    // const dispatch = useDispatch()
    //
    // const {
    //
    // } = useSelector(store => ({
    //
    // }), shallowEqual)

    const [nbClickTitle, setNbClickTitle] = useState(0)

    useEffect(() => {
        if (selectedModel.typeModel === MODEL_TYPES.METEO_NAPPES) {
            changeParams({ dateMode: 'glissant', nbDaysCalculation: selectedModel.horizon })
        }
        if (selectedModel.typeModel === MODEL_TYPES.ARIMA) {
            changeParams({ p: 0, q: 0, d: 0, P: 0, Q: 0, D: 0, learningMode: false, validOnly: false, area50: true, area90: true,
                measureGroupMode: selectedModel.objective === MODEL_OBJECTIVES.CRUE ? 'MAX': (
                    selectedModel.objective === MODEL_OBJECTIVES.ETIAGE ? 'MIN' : 'AVERAGE'
                )
            })
        }
        if (selectedModel.typeModel === MODEL_TYPES.AUGURES) {
            changeParams({ lineToTake: 'area', nbYearsToTake: 5, average: 7 })
        }
        if (selectedModel.typeModel === MODEL_TYPES.TARRI_BRGM) {
            changeParams({ sensibility: 3 })
        }
        if (selectedModel.typeModel === MODEL_TYPES.SCIKIT_LEARN_GENERIC) {
            changeModel({ chainCode: MODEL_TYPES.SCIKIT_LEARN_GENERIC, horizon: 10 })
        }
    }, [])

    return (
        <div className='padding-top-2'>
            <Grid container justifyContent='center' alignItems='center' spacing={3} className='padding-top-3'>
                <h5 onClick={() => setNbClickTitle(nbClickTitle+1) }>Encore quelques informations et c'est terminé !</h5>
            </Grid>
            <Grid container justifyContent='center' alignItems='center' spacing={3} className='padding-top-2'>
                <Grid item xs={4}>
                    <Input value={selectedModel.name} onChange={v => changeModel({ name: v }) } title={'Nom du modèle'} />
                </Grid>
                <Grid item xs={4}>
                    <ColorPicker
                        label={i18n.color}
                        value={selectedModel.color}
                        onChange={v => changeModel({ color: v })}
                        style={{
                            cursor: 'pointer',
                            backgroundColor: selectedModel.color || '#000000',
                        }}
                    />
                </Grid>
            </Grid>
            {
                [MODEL_TYPES.AUGURES, MODEL_TYPES.ARIMA, MODEL_TYPES.TARRI_BRGM].includes(selectedModel.typeModel) && (
                    <>
                        <Grid container justifyContent='center' alignItems='center' spacing={3} className='padding-top-3' >
                            <h5>Autres paramètres</h5>
                        </Grid>
                        {
                            (() => {
                                switch (selectedModel.typeModel) {
                                    case MODEL_TYPES.AUGURES:
                                        return getAUGURESParams(params, changeParams)
                                    case MODEL_TYPES.ARIMA:
                                        return getARIMAParams(params, changeParams)
                                    case MODEL_TYPES.TARRI_BRGM:
                                        return getTarriBRGMParams(params, changeParams, selectedModel)
                                    default:
                                        return 'Aucune options en plus :('
                                }
                            })()
                        }
                    </>
                )
            }

            {
                nbClickTitle >= 5 && (
                    <>
                        <Grid container justifyContent='center' alignItems='center' spacing={3} className='padding-top-3' >
                            <h5>/!\ Mode Expert /!\</h5>
                        </Grid>
                        {
                            (() => {
                                switch (selectedModel.typeModel) {
                                    case MODEL_TYPES.ARIMA:
                                        return getARIMAParamsExpert(params, changeParams)
                                    case MODEL_TYPES.AUGURES:
                                        return getAUGURESParamsExpert(params, changeParams)
                                    default:
                                        return 'Aucune options en plus :('
                                }
                            })()
                        }
                    </>
                )
            }
        </div>
    )
}

ModelStepSettings.propTypes = {
    stationType: PropTypes.string,
    id: PropTypes.string,
    selectedModel: instanceOf(DtoIAEauModel),
    setSelectedModel: PropTypes.func,
    params: PropTypes.shape({}),
}

export default ModelStepSettings