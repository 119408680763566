import React, { useEffect, useMemo, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import Row from '../../../components/react/Row'
import Card from '../../../components/card/Card'
import Input from '../../../components/forms/Input'
import ReferencialAction from '../../action/ReferencialAction'
import { push } from '@lagunovsky/redux-react-router'
import NumberField from '../../../components/forms/NumberField'
import ToastrAction from 'toastr/actions/ToastrAction'
import SimpleDatePicker from 'components/forms/SimpleDatePicker'
import Select from 'components/forms/Select'
import { max, orderBy, uniqBy } from 'lodash'
import useBoolean from 'utils/customHook/useBoolean'
import useActions from 'utils/customHook/useActions'
import useTitle from 'utils/customHook/useTitle'
import useIsMounted from 'utils/customHook/useIsMounted'
import { useParams } from 'react-router'

const MatTypeApp = () => {
    const dispatch = useDispatch()
    const params = useParams()
    const isMounted = useIsMounted()
    const {
        materielTypes,
    } = useSelector(store => ({
        materielTypes: store.ReferencialReducer.materielTypes,
    }), shallowEqual)

    useEffect(() => {
        if (!materielTypes.length) {
            dispatch(ReferencialAction.fetchMaterielTypes())
        }
    }, [])

    const materielType = useMemo(() => {
        const parsedCode = parseInt(params.code)
        const type = materielTypes.find(mt => mt.field === params.field && mt.code === parsedCode)
        return type || { field: params.field, code: max(materielTypes.filter(m => m.field === params.field).map(m => m.code)) + 1 }
    }, [params.code, params.field, materielTypes])

    useTitle(() => [{
        title: i18n.referencials,
        href: '/referencial',
    }, {
        title: i18n.materielTypes,
        href: '/referencial/materielTypes',
    }, {
        title: params.field !== 'new' ? params.field : i18n.new,
        href: `/referencial/materielType/${params.field}/${params.code}/dashboard`,
    }], [params.field, params.code])

    const {
        value: isReadMode,
        toggle: toggleMode,
    } = useBoolean(params.field !== 'new')

    const [code, setCode] = useState()
    const [label, setLabel] = useState()
    const [field, setField] = useState()
    const [startDate, setStartDate] = useState()
    const [endDate, setEndDate] = useState()

    const initState = () => {
        setCode(materielType.code)
        setLabel(materielType.label)
        setField(materielType.field)
        setStartDate(materielType.startDate)
        setEndDate(materielType.endDate)
    }

    useEffect(() => {
        initState(materielType)
    }, [materielType])

    useActions(() => {
        if (isReadMode) {
            return {
                edit: toggleMode,
            }
        }
        return {
            save: () => {
                if (!code || !field || !label) {
                    dispatch(ToastrAction.error(i18n.allFieldsAreRequired))
                    return
                }

                const newMaterielType = {
                    code,
                    label,
                    field,
                    startDate,
                    endDate,
                }

                if (params.field !== 'new') {
                    dispatch(ReferencialAction.updateMaterielType(newMaterielType)).then(result => {
                        if (result && isMounted()) {
                            dispatch(ReferencialAction.fetchMaterielTypes())
                            toggleMode()
                        }
                    })
                    return
                }

                if (materielTypes.some(m => m.field === field && m.code === code)) {
                    dispatch(ToastrAction.error(i18n.undefinedOrTakenCodeAndName))
                    return
                }

                dispatch(ReferencialAction.addMaterielType(newMaterielType, field)).then(result => {
                    if (result && isMounted()) {
                        dispatch(ReferencialAction.fetchMaterielTypes())
                        dispatch(push('/referencial/materielTypes'))
                    }
                })
            },
            cancel: () => {
                if (params.field === 'new') {
                    dispatch(push('/referencial/materielTypes'))
                } else {
                    initState()
                    toggleMode()
                }
            },
        }
    }, [isReadMode, code, label, field, startDate, endDate])

    const filteredMaterielTypes = useMemo(() => {
        return orderBy(uniqBy(materielTypes, 'field'), 'field')
    }, [materielTypes])

    return (
        <div className='row margin-top-1'>
            <div className='col s10 offset-s1'>
                <Card className='padding-bottom-1 padding-top-1' col={12}>
                    <Row>
                        <NumberField
                            col={3}
                            title={i18n.code}
                            value={code}
                            onChange={setCode}
                            disabled={params.field !== 'new' || isReadMode}
                            obligatory
                        />
                        <Input
                            col={3}
                            title={i18n.name}
                            value={label}
                            onChange={setLabel}
                            maxLength={35}
                            disabled={isReadMode}
                            obligatory
                        />
                        <Select
                            col={6}
                            label={i18n.materielTypes}
                            value={field}
                            options={filteredMaterielTypes}
                            onChange={setField}
                            keyValue='field'
                            keyLabel='field'
                            disabled={params.field !== 'new' || isReadMode}
                            noNullValue
                            obligatory
                        />
                        <SimpleDatePicker
                            onChange={setStartDate}
                            label={i18n.startDate}
                            value={startDate}
                            col={6}
                            max={endDate}
                            disabled={isReadMode}
                        />
                        <SimpleDatePicker
                            onChange={setEndDate}
                            label={i18n.endDate}
                            value={endDate}
                            col={6}
                            min={startDate}
                            disabled={isReadMode}
                        />
                    </Row>
                </Card>
            </div>
        </div>
    )
}

export default MatTypeApp