import { Grid, Popover } from '@mui/material'
import Icon from 'components/icon/Icon'
import i18n from 'simple-react-i18n'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import useBoolean from 'utils/customHook/useBoolean'
import { sieauTooltip } from '../../utils/FormUtils'
import useSandreList from 'utils/customHook/useSandreList'

const LanguagesList = ({
    onClick = () => {},
}) => {
    const {
        value: isOpen,
        setTrue: open,
        setFalse: close,
    } = useBoolean(false)

    const [anchorEl, setAnchorEl] = useState()

    const id = open ? 'simple-popper' : undefined
    const languages = useSandreList('LANGUES')

    return (
        <div className='action-wrapper'>
            <a
                className={'right waves-effect nav-actions blue-arrow'}
                {...sieauTooltip(i18n.languages, 'dropdown_navbar', 'bottom')}
                onClick={e => {
                    open()
                    setAnchorEl(e.currentTarget)
                }}
            >
                <Icon id={id} icon='translate' ref={anchorEl}/>
            </a>
            <Popover
                open={isOpen}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                onClose={close}
            >
                {languages.map((l, i) => (
                    <Grid
                        container
                        sx={{
                            padding: '10',
                            cursor: 'pointer', '&:hover': {
                                backgroundColor: '#eee',
                            },
                            borderBottom: i < languages.length - 1 ? '1px solid #eee' : undefined,
                        }}
                        onClick={() => onClick(l)}
                    >
                        <Grid item xs={12}>
                            <h6>{l.mnemonique}</h6>
                        </Grid>
                    </Grid>
                ))}
            </Popover>
        </div>
    )
}

LanguagesList.propTypes = {
    onClick: PropTypes.func,
}

export default LanguagesList
