import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { push } from '@lagunovsky/redux-react-router'
import i18n from 'simple-react-i18n'
import ActionComponent from '../../../components/ActionComponent'
import { arrayOf, getMapStateToProps, getPropTypes } from '../../../utils/StoreUtils'
import Card from '../../../components/card/Card'
import StationMapDashboardPanel from '../../../station/components/dashboard/component/map/StationMapDashboardPanel'
import LinkedStationsPanel from '../../../station/components/associatedStation/LinkedStationsPanel'
import SieauAction from '../../../components/sieau/SieauAction'
import Input from '../../../components/forms/Input'
import Checkbox from '../../../components/forms/Checkbox'
import LastEventPanel from '../../../station/components/event/LastEventPanel'
import ContactCard from '../../../referencial/components/contact/components/ContactCard'
import StationUpdatePanel from '../../../station/components/update/StationUpdatePanel'
import { isEqual, omit } from 'lodash'
import InstallationAction from '../../actions/InstallationAction'
import ToastrAction from 'toastr/actions/ToastrAction'
import { getLogin, getSetting, getUser } from '../../../utils/SettingUtils'
import moment from 'moment'
import { getStationArrowNav } from '../../../utils/ActionUtils'
import { getLinks } from '../../../utils/StationUtils'
import InstallationInfosPanel from '../installations/components/InstallationInfosPanel'
import StationPiezoContributorPanel from '../../../station/components/link/StationPiezoContributorPanel'
import StationUsagePanel from '../../../station/components/link/StationUsagePanel'
import StationArrangementPanel from '../../../station/components/link/StationArrangementPanel'
import InstallationComElecPanel from '../installations/components/InstallationComElecPanel'
import InstallationAccessSitePanel from '../installations/components/InstallationAccessSitePanel'
import InstallationAccessCasingPanel from '../installations/components/InstallationAccessCasingPanel'
import StationLocationInfoPanel from '../../../station/components/location/StationLocationInfoPanel'
import Row from '../../../components/react/Row'
import InstallationStaffSafetyPanel from '../installations/components/InstallationStaffSafetyPanel'
import { downloadURI } from '../../../utils/ExportDataUtil'
import Select from '../../../components/forms/Select'
import WatershedAction from '../../../referencial/components/watershed/actions/WatershedAction'
import AquiferAction from '../../../referencial/components/aquifers/actions/AquiferAction'
import HydrogeologicalEntityAction from '../../../referencial/components/hydrogeologicalEntity/actions/HydrogeologicalEntityAction'
import { getVisitCheckBoxChange, getVisitDatePickerChange, getVisitSelectChange } from '../../../utils/VisitUtils'
import ContactDto from '../../../referencial/components/contact/dto/ContactDto'
import { onChangeDate } from '../../../utils/FormUtils'
import { getDate } from '../../../utils/DateUtil'
import DtoInstallation from '../../dto/installation/DtoInstallation'
import LocalisationMapModal from '../installations/components/modal/LocalisationMapModal'
import DtoHydrogeologicalEntity from '../../../station/dto/DtoHydrogeologicalEntity'
import BuildingsPanel from '../installations/components/BuildingsPanel'
import DtoInstallationTableLine from 'installation/dto/installation/DtoInstallationTableLine'
import StationSamplersPanel from 'station/components/link/StationSamplersPanel'
import { AGRI } from 'administration/components/user/constants/HabilitationConstants'
import DtoAccountHabilitation from 'account/dto/DtoAccountHabilitation'

const storeProps = {
    installation: false,
    cities: false,
    contacts: false,
    watersheds: true,
    sandreCodes: false,
    natures: true,
    aquifers: true,
    fieldModes: true,
    installationEvents: false,
    users: false,
    status: true,
}

class InstallationDescriptionPanel extends ActionComponent {
    constructor(props) {
        super(props)
        this.state = {
            installation: { ...props.installation },
            readMode: true,
        }
    }

    componentDidMount() {
        const { installation, watersheds, aquifers, hydrogeologicalEntities } = this.props
        this.props.fetch(storeProps)
        if (installation.id) {
            this.setState({ installation })
        }
        if (!watersheds.length) {
            this.props.fetchWatersheds()
        }
        if (!aquifers.length) {
            this.props.fetchAquifers()
        }
        if (!hydrogeologicalEntities.length) {
            this.props.fetchHydrogeologicalEntities()
        }

        if (installation.id) {
            this.props.fetchInstallationVisits(installation.id).then(() => {
                if (this.props.onChangeVisit) {
                    this.setState({ readMode: false })
                } else if (this.state.readMode) {
                    this.setReadOnlyMode()
                }
            })
        }

        if (this.props.onChangeVisit) {
            this.setState({ readMode: false })
        } else {
            this.setReadOnlyMode()
        }
    }

    componentDidUpdate = (prevProps) => {
        if (!isEqual(prevProps.installation, this.props.installation)) {
            this.setState({ installation: this.props.installation })
            if (!this.props.onChangeVisit) {
                this.setReadOnlyMode()
            }
        }
    }

    onDelete = () => this.props.push('/installation')

    onSave = (visit) => {
        const { installation } = this.state

        if (this.props.onChangeVisit) {
            return this.props.updateInstallationAndVisit(visit.idVisit,
                {
                    visit,
                    installation,
                }
            ).then(() => this.setReadOnlyMode())
        }

        return this.props.updateInstallation(
            installation
        ).then(() => this.setReadOnlyMode())
    }

    setEditMode = () => {
        this.setState({ readMode: false })
        const actions = {
            save: () => this.onSave(),
            cancel: () => {
                this.setState({ installation: this.props.installation })
                this.setReadOnlyMode()
            },
            delete: () => this.props.delete('installation', this.props.installation.id, this.onDelete),
            links: getLinks(this.props.installation, this.props),
            arrowNav: getStationArrowNav('installation', this.props.installationTable, this.props.installation.id, s => this.props.push(`/station/installation/${s.id}/description`)),
        }
        if (getUser().consultant === '1') {
            this.setActions(omit(actions, ['save', 'delete']))
        } else {
            this.setActions(actions)
        }
    }

    setReadOnlyMode = () => {
        if (this.props.onChangeVisit) {
            return
        }
        this.setState({ readMode: true })
        const { installationVisits } = this.props
        const visitExport = installationVisits.length ? { export: () => this.createAndDownloadEdition(installationVisits[0]) } : null
        const actions = {
            ...visitExport,
            links: getLinks(this.props.installation, this.props),
            arrowNav: getStationArrowNav('installation', this.props.installationTable, this.props.installation.id, s => this.props.push(`/station/installation/${s.id}/description`)),
            visitLinks: this.props.installationVisits.map(iv => ({ ...iv, idInstallation: this.props.installation.id })),
        }
        if (getUser().consultant === '1') {
            this.setActions(actions)
        } else {
            this.setActions({
                ...actions,
                edit: () => this.setEditMode(),
                delete: () => this.props.delete('installation', this.props.installation.id, this.onDelete),
            })
        }
    }

    createAndDownloadEdition = (installationVisit) => {
        const { installation } = this.state
        this.props.getEditionInstallation(installationVisit.idCampaign, installation.id).then(json => {
            downloadURI(json.targetPath)
        })
    }
    onChangeInstallation = (newObject) => {
        this.setState({ installation: { ...this.state.installation, ...newObject } })
    }

    onChangeVisit = (visitObject) => {
        if (visitObject && this.props.onChangeVisit) {
            this.props.onChangeVisit(visitObject)
        }
    }

    isDisplayed = (panel) => {
        return getSetting(this.props.applicationSettings, `PANEL_${this.state.installation.installationType}_${panel}`) !== 'hidden'
    }

    render() {
        const { installation, readMode, localizeMap } = this.state
        const { accountHabilitations } = this.props
        const mode = { readMode, editMode: !readMode }
        const params = {
            station: installation,
            onChange: this.onChangeInstallation,
            onChangeVisit: this.onChangeVisit,
            readMode,
        }

        return (
            <div className='row padding-top-1 margin-left-2 padding-bottom-5' id='InstallationDescriptionPanel'>
                <div className='col s9' style={ { marginBottom: '100px' } }>
                    <Card title={ i18n.description } >
                        <InstallationInfosPanel { ...params } />
                        <Row className='padding-1'>
                            <BuildingsPanel {...params} />
                        </Row>
                    </Card>
                    <Card title={ i18n.communicationElectricity } className='margin-top-1' noMargin={ false } displayIf={ this.isDisplayed('ELEC_COM') }>
                        <InstallationComElecPanel { ...params } />
                    </Card>
                    <Card title={ i18n.accessSite } className='margin-top-1' noMargin={ false } displayIf={ this.isDisplayed('ACCESS_SITE') }>
                        <InstallationAccessSitePanel { ...params } />
                    </Card>
                    <Card title={ i18n.accessCasing } className='margin-top-1' noMargin={ false } displayIf={ this.isDisplayed('ACCESS_CASING') }>
                        <InstallationAccessCasingPanel { ...params } />
                    </Card>
                    <Row className='margin-top-1 no-margin-bottom' noMargin={ false } displayIf={ this.isDisplayed('STAFF_SAFETY') }>
                        <InstallationStaffSafetyPanel { ...params } />
                    </Row>
                    <Row className='margin-top-1 no-margin-bottom' noMargin={ false } displayIf={ this.isDisplayed('CONTRIBUTORS') }>
                        <StationPiezoContributorPanel { ...params } />
                    </Row>
                    <Row className='margin-top-1 no-margin-bottom' noMargin={ false } displayIf={ this.isDisplayed('USAGES') }>
                        <StationUsagePanel { ...params } />
                    </Row>
                    <Row className='margin-top-1 no-margin-bottom' noMargin={ false } displayIf={ this.isDisplayed('ARRANGEMENTS') }>
                        <StationArrangementPanel { ...params } />
                    </Row>
                    <Row className='margin-top-1 no-margin-bottom' noMargin={false} displayIf={accountHabilitations.some((h) => h.habilitation === AGRI) && this.isDisplayed('SAMPLERS')}>
                        <StationSamplersPanel {...params}/>
                    </Row>
                </div>
                <div className='col s3' style={ { marginBottom: '100px' } }>
                    <StationUpdatePanel station={ installation } />
                    <Card noMargin={ false } className='margin-top-1 padding-bottom-1'>
                        <Row className={ `${!readMode && 'padding-top-1' || ''}` }>
                            <Input
                                col={ 12 }
                                title={ i18n.dataOrigin }
                                value={ installation.dataOrigin }
                                onChange={ v => {
                                    this.onChangeInstallation({ dataOrigin: v })
                                    this.onChangeVisit({ previousValue: installation.dataOrigin, newValue: v, field: i18n.dataOrigin })
                                } }
                                { ...mode }
                            />
                        </Row>
                        <Row>
                            <Select
                                col={ 12 }
                                label={ i18n.status }
                                value={ installation.status }
                                onChange={ v => {
                                    this.onChangeInstallation({
                                        status: v,
                                        statusLogin: getLogin(),
                                        statusDate: moment().valueOf(),
                                    })
                                    this.onChangeVisit(getVisitSelectChange(this.props.status, 'code', i18n.status, installation.status, v))
                                    this.onChangeVisit({ previousValue: installation.statusLogin, newValue: getLogin(), field: i18n.controlDoneBy })
                                    this.onChangeVisit({ previousValue: installation.statusDate, newValue: moment().valueOf(), field: i18n.controlDoneAt })
                                } }
                                options={ this.props.status }
                                keyValue='code'
                                integerValue
                                { ...mode }
                            />
                        </Row>
                        <Row className={ `${!readMode && 'padding-bottom-1' || ''}` }>
                            <Input
                                col={ 12 }
                                value={ getDate(installation.statusDate) }
                                title={ i18n.controlDoneAt }
                                onChange={ v => {
                                    onChangeDate(v, v2 => this.onChangeInstallation({ statusDate: v2 }))
                                    this.onChangeVisit(getVisitDatePickerChange(i18n.controlDoneAt, installation.statusDate, v))
                                } }
                                { ...mode }
                            />
                        </Row>
                        <Row>
                            <Select
                                col={ 12 }
                                label={ i18n.controlDoneBy }
                                value={ installation.statusLogin }
                                keyValue='name'
                                onChange={ v => {
                                    this.onChangeInstallation({ statusLogin: v })
                                    this.onChangeVisit(getVisitSelectChange(this.props.users.map(u => ({ code: u.login, name: u.name || u.login })), 'name', i18n.controlDoneBy, installation.statusLogin, v))
                                } }
                                options={ this.props.users.map(u => ({ code: u.login, name: u.name || u.login })) } { ...mode }
                            />
                        </Row>
                        <Row className={ `${!readMode && 'padding-bottom-1' || ''}` }>
                            <Input
                                col={ 12 }
                                value={ getDate(installation.endDate) }
                                title={ i18n.deliberationDate }
                                onChange={ v => {
                                    onChangeDate(v, v2 => this.onChangeInstallation({ endDate: v2 }))
                                    this.onChangeVisit(getVisitDatePickerChange(i18n.deliberationDate, installation.endDate, v))
                                } }
                                { ...mode }
                            />
                        </Row>
                        <Row>
                            <Input col={ 12 } value={ installation.closeComment } title={ i18n.become }
                                onChange={ v => {
                                    this.onChangeInstallation({ closeComment: v })
                                    this.onChangeVisit({ previousValue: installation.closeComment, newValue: v, field: i18n.become })
                                } } { ...mode }
                            />
                        </Row>
                        <Row>
                            <Checkbox col={ 12 } label={ i18n.confidential } checked={ installation.confidential === '1' }
                                onChange={ v => {
                                    this.onChangeInstallation({ confidential: v ? '1' : '0' })
                                    this.onChangeVisit(getVisitCheckBoxChange(i18n.confidential, installation.confidential === '1', v))
                                } } disabled={ this.state.readMode }
                            />
                        </Row>
                    </Card>
                    <Row className='padding-top-1'>
                        <ContactCard
                            title={ i18n.contact }
                            contactCode={ installation.ownerCode }
                            onChange={ v => {
                                this.onChangeInstallation({ ownerCode: v })
                                this.onChangeVisit(getVisitSelectChange(this.props.contacts, 'code', i18n.contact, installation.ownerCode, v))
                            } }
                            readMode={ readMode }
                        />
                    </Row>
                    <LastEventPanel id={ installation.id } events={ this.props.installationEvents } stationId={ installation.id }
                        stationType='installation'
                    />
                    <Card
                        title={ i18n.map }
                        noMargin={ false }
                        className='margin-top-1'
                        actions={
                            !this.state.readMode ?
                                [{
                                    iconName: 'my_location',
                                    onClick: () => this.setState({ localizeMap: true }),
                                }] : []
                        }
                    >
                        <StationMapDashboardPanel noMarkerTooltip station={this.props.installation} type={'installation'} />
                        <LocalisationMapModal
                            onChangeInstallation={this.onChangeInstallation}
                            installation={installation}
                            readMode={readMode}
                            open={localizeMap}
                            onClose={ () => this.setState({ localizeMap: false }) }
                        />
                    </Card>
                    <Card title={ i18n.localisation } noMargin={ false } className='margin-top-1'>
                        <StationLocationInfoPanel
                            onChange={ this.onChangeInstallation }
                            onChangeVisit={ this.onChangeVisit }
                            station={ installation }
                            readMode={ readMode }
                        />
                    </Card>
                    <LinkedStationsPanel
                        noMargin={ false }
                        className='margin-top-1'
                        onReMount={ this.props.onReMount }
                        station={ installation }
                        { ...mode }
                    />
                </div>
            </div>
        )
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextState.installation.LAST_FORM !== this.state.installation.LAST_FORM && nextState.installation.LAST_FORM.match(/INPUT|TEXT/g)) {
            return false
        }
        return true
    }
}

InstallationDescriptionPanel.propTypes = getPropTypes(storeProps, {
    onRemount: PropTypes.func,
    onChangeVisit: PropTypes.func, // used for changes on visit mode only
    contacts: arrayOf(ContactDto),
    installation: PropTypes.instanceOf(DtoInstallation),
    hydrogeologicalEntities: PropTypes.arrayOf(PropTypes.instanceOf(DtoHydrogeologicalEntity)),
    installationTable: arrayOf(DtoInstallationTableLine),
    accountHabilitations: arrayOf(DtoAccountHabilitation),
})

const mapStateToProps = (store) => getMapStateToProps(storeProps, {
    applicationSettings: store.AdministrationReducer.applicationSettings,
    watersheds: store.WatershedReducer.watersheds,
    aquifers: store.AquiferReducer.aquifers,
    contacts: store.ContactReducer.contacts,
    installation: store.InstallationReducer.installation,
    installationVisits: store.InstallationReducer.installationVisits,
    hydrogeologicalEntities: store.HydrogeologicalEntityReducer.hydrogeologicalEntities,
    installationTable: store.InstallationReducer.installationTable,
    accountHabilitations: store.AccountReducer.accountHabilitations,
})

const mapDispatchToProps = {
    push,
    updateInstallation: InstallationAction.updateInstallation,
    info: ToastrAction.info,
    fetch: SieauAction.fetch,
    delete: SieauAction.delete,
    getEditionInstallation: InstallationAction.getEditionInstallation,
    fetchWatersheds: WatershedAction.fetchWatersheds,
    fetchAquifers: AquiferAction.fetchAquifers,
    fetchHydrogeologicalEntities: HydrogeologicalEntityAction.fetchHydrogeologicalEntities,
    fetchInstallationVisits: InstallationAction.fetchInstallationVisits,
    updateInstallationAndVisit: InstallationAction.updateInstallationAndVisit,
}

export default connect(mapStateToProps, mapDispatchToProps)(InstallationDescriptionPanel)
