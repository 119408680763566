import ReactEcharts from 'echarts-for-react/lib/core'
import 'echarts/lib/chart/bar'
import echarts from 'echarts/lib/echarts'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import { volumesToShow } from '../../../agriAdministration/constants/AgriConstants'
import { exportPictureIcon } from '../../../components/echart/EChartUtils'
import Select from '../../../components/forms/Select'
import { getFullDate } from '../../../utils/DateUtil'
import { formatMilliers } from '../../../utils/StringUtil'

class ManagementUnitVolumesChart extends Component {
    constructor(props) {
        super(props)
        this.state = {
            volumeSelected: 'asked',
        }
    }

    getExportData = () => {
        const data = this.props.volumes.map((d, i) => {
            const stat = {
                login: d.login,
                statisticDate: getFullDate(d.statisticDate),
                module: d.module,
                value: d.value,
            }
            if (i === 0) {
                return { ...stat, headers: ['login', 'statisticDate', 'module', 'value'] }
            }
            return stat
        })
        return data
    }

    getVolumes = (volume, volumeSelected) => {
        const { requestedVolumes, attributedVolumes, authorizedVolumes, consumedVolumes } = volume
        switch (volumeSelected) {
            case 'asked':
                return requestedVolumes && requestedVolumes.value || '0 m3'
            case 'attributed':
                return attributedVolumes && attributedVolumes.value || '0 m3'
            case 'authorized':
                return authorizedVolumes && authorizedVolumes.value || '0 m3'
            case 'consumed':
                return consumedVolumes && consumedVolumes.value || '0 m3'
            default:
                return requestedVolumes && requestedVolumes.value || '0 m3'
        }
    }

    getOptions = () => {
        const { type, volumes } = this.props
        const { volumeSelected } = this.state
        const dataSeries = volumes.map((v) => {
            const value = this.getVolumes(v, volumeSelected)
            return {
                value: parseInt(value.slice(0, -2).replaceAll(' ', '')),
                name: type === 'cities' ? v.cityName.value : v.usage.value,
            }
        })
        return {
            title: {
                text: i18n.division,
            },
            tooltip: {
                formatter({ name, value, percent }) {
                    return `${name} : ${formatMilliers(value) || 0} m3 (${percent}%)`
                },
            },
            series: [
                {
                    type: 'pie',
                    data: dataSeries,
                    avoidLabelOverlap: false,
                    radius: ['50%', '70%'],
                    label: {
                        normal: {
                            show: false,
                            position: 'center',
                        },
                        emphasis: {
                            show: true,
                            textStyle: {
                                fontSize: '20',
                                fontWeight: 'bold',
                            },
                        },
                    },
                    labelLine: {
                        normal: {
                            show: false,
                        },
                    },
                },
            ],
            toolbox: {
                show: true,
                feature: {
                    saveAsImage: {
                        title: i18n.export,
                        icon: exportPictureIcon,
                        name: i18n.division,
                    },
                },
            },
        }
    }

    render() {
        const { volumeSelected } = this.state
        const { style } = this.props
        return (
            <div className='row no-margin card' style={style}>
                <div className='col s12 no-padding'>
                    <ReactEcharts
                        echarts={ echarts }
                        option={ this.getOptions() }
                        notMerge={ true }
                        lazyUpdate={ true }
                        className={ 'row no-margin' }
                        style={ { height: 200 } }
                    />
                </div>
                <Select
                    col={12}
                    label={i18n.volumesShow}
                    value={volumeSelected || 'asked'}
                    options={volumesToShow}
                    onChange={v => this.setState({ volumeSelected: v })}
                    noNullValue
                />
            </div>
        )
    }
}

ManagementUnitVolumesChart.propTypes = {
    type: PropTypes.string,
    style: PropTypes.shape({}),
    volumes: PropTypes.arrayOf(PropTypes.object),
}

export default connect()(ManagementUnitVolumesChart)