/* eslint-disable camelcase */
import { Button, Grid } from '@mui/material'
import Card from 'components/card/Card'
import React, { useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import useTitle from 'utils/customHook/useTitle'
import { getStationTitle } from 'utils/StationUtils'
import SimpleDatePicker from 'components/forms/SimpleDatePicker'
import Table from 'components/datatable/Table'
import Checkbox from 'components/forms/Checkbox'
import { useEffect } from 'react'
import AlertAction from 'alerting/actions/AlertAction'
import ParameterAction from 'referencial/components/parameter/actions/ParameterAction'
import { nbPerPageLabelHuge, SANDRE } from 'referencial/constants/ReferencialConstants'
import { getSandreLabel } from 'utils/StringUtil'
import { exportFile } from 'utils/ExportDataUtil'
import DtoPollutionSourceActivityParams from '../dto/DtoPollutionSourceActivityParams'
import ProgressCard from 'components/card/ProgressCard'
import SelectionSelect from 'components/forms/specific/SelectionSelect'
import MultiAutocomplete from 'components/forms/MultiAutocomplete'
import { intersection, sortBy } from 'lodash'
import useLocalStorage from 'utils/customHook/useLocalStorage'
import { SELECTION } from 'quality/constants/QualityConstants'

const tableHeaders = ['parameterName', 'priority', 'identifier', 'installationName', 'insee', 'city', 'category', 'activityCode', 'activityLibelle', 'inActivityDisplay', 'icpeDisplay', 'dischargeAgreementLabel']
const exportHeaders = ['parameterCode', 'parameterName', 'priority', 'identifier', 'installationName', 'insee', 'city', 'x', 'y', 'projection', 'category', 'activityCode', 'activityLibelle', 'inActivity', 'icpe', 'dischargeAgreement']

const STEPPollutionSearchApp = ({

}) => {
    const dispatch = useDispatch()
    const {
        installation,
        pollutionSourceParametersFullData,
        sandreCodes,
        parameters,
    } = useSelector(store => ({
        installation: store.InstallationReducer.installation,
        pollutionSourceParametersFullData: store.AlertReducer.pollutionSourceParametersFullData,
        sandreCodes: store.ReferencialReducer.sandreCodes,
        parameters: store.ParameterReducer.parameters,
    }), shallowEqual)

    useTitle(() => [{
        title: i18n.installation,
        href: 'installation',
    }, {
        title: getStationTitle(installation),
        href: `station/installation/${installation.id}`,
    }, {
        title: i18n.pollutionSearch,
        href: `station/installation/${installation.id}/STEPPollutionSearch`,
    }], [])

    const [dataloaded, setDataLoaded] = useState(false)
    const [inActivity, setInActivity] = useState(true)
    const [dischargeAgreement, setDischargeAgreement] = useState()
    const [icpe, setIcpe] = useState()
    const [startDate, setStartDate] = useState()
    const [endDate, setEndDate] = useState()
    const [selectionName, setSelectionName] = useState(useLocalStorage(`SELECTION_FILTERS_${SELECTION.PC}`), [])
    const [selectionCodes, setSelectionCodes] = useState()
    const [parametersSelected, setParametersSelected] = useState([])

    useEffect(() => {
        dispatch(ParameterAction.fetchParameters())

        if (selectionName?.[0]) {
            dispatch(ParameterAction.fetchSelectionParameter(selectionName[0])).then((params) => {
                dispatch(AlertAction.fetchPollutionSourceParams({
                    STEPId: installation.id,
                    parameters: params.map(d => d.parameterCode) || null,
                    inActivity,
                })).then(() => setDataLoaded(true))
            })
        } else {
            dispatch(AlertAction.fetchPollutionSourceParams({
                STEPId: installation.id,
                inActivity,
            })).then(() => setDataLoaded(true))
        }
    }, [])

    const data = pollutionSourceParametersFullData.map(d => ({
        parameterName: { value: d.parameterName },
        priority: { value: d.priority },
        identifier: { value: d.identifier, cellType: 'string' },
        installationName: { value: d.installationName },
        activityCode: { value: d.activityCode },
        parameterCode: { value: d.parameterCode },
        x: { value: d.x },
        y: { value: d.y },
        city: { value: d.cityName },
        insee: { value: d.city },
        category: { value: getSandreLabel(sandreCodes, SANDRE.ACTIVITES_CATEGORIE, d.activityCategory) },
        projection: { value: getSandreLabel(sandreCodes, SANDRE.PROJECTION, d.projection) },
        inActivity: { value: d.inActivity ? i18n.yes : i18n.no },
        icpe: { value: d.icpe ? i18n.yes : i18n.no },
        dischargeAgreement: { value: d.dischargeAgreement ? i18n.yes : i18n.no },
        inActivityDisplay: { value: d.inActivity && <i className='material-icons tiny table-checkbox'>check_box</i> },
        icpeDisplay: { value: d.icpe && <i className='material-icons tiny table-checkbox'>check_box</i> },
        dischargeAgreementLabel: { value: d.dischargeAgreement && <i className='material-icons tiny table-checkbox'>check_box</i> },
        activityLibelle: { value: d.activityName },
    }))

    const actions = [{
        iconName: 'file_download',
        tooltip: i18n.export,
        onClick: () => exportFile({
            data: [{ ...data[0], headers: exportHeaders }, ...data.slice(1, data.length)],
            titleFile: i18n.dangerousSituations,
        }),
    }]

    const getIntersection = () => {
        if (selectionCodes.length && parametersSelected.length) {
            return intersection(selectionCodes, parametersSelected)
        } else if (selectionCodes.length) {
            return selectionCodes
        } else if (parametersSelected.length) {
            return parametersSelected
        }
        return null
    }

    const filterActivties = () => {
        const intersect = getIntersection()
        dispatch(AlertAction.fetchPollutionSourceParams(new DtoPollutionSourceActivityParams({
            STEPId: installation.id,
            parameters: intersect,
            inActivity,
            startDate,
            endDate,
            icpe,
            dischargeAgreement,
        })))
    }

    return (
        <Grid style={{ padding: '10 10 0 20' }}>
            <Card>
                <Grid container direction='column' className='padding-1'>
                    <Grid item xs={12}>
                        <fieldset>
                            <legend>{i18n.parameters}</legend>
                            <Grid container item xs={12}>
                                <Grid item xs={6}>
                                    <SelectionSelect
                                        col={12}
                                        value={selectionName}
                                        onChange={(r, v) => {
                                            setSelectionCodes(r)
                                            setSelectionName(v)
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <MultiAutocomplete
                                        col={12}
                                        displayWithCode
                                        label={i18n.parameters}
                                        elements={sortBy(parameters.filter(p => p.active === '1'), 'name')}
                                        clearFunction
                                        values={parametersSelected}
                                        onChange={setParametersSelected}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container item xs={12}>
                                <Grid item xs={6}>
                                    <SimpleDatePicker
                                        col={12}
                                        value={startDate}
                                        id='startDate'
                                        label={i18n.startDate}
                                        onChange={setStartDate}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <SimpleDatePicker
                                        col={12}
                                        value={endDate}
                                        id='endDate'
                                        label={i18n.endDate}
                                        onChange={setEndDate}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container item xs={6} className='padding-left-1 padding-bottom-1'>
                                <Grid item xs={4} >
                                    <Checkbox
                                        label={i18n.inActivity}
                                        checked={inActivity}
                                        onChange={setInActivity}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <Checkbox
                                        label={i18n.icpe}
                                        checked={icpe}
                                        onChange={setIcpe}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <Checkbox
                                        label={i18n.dischargeAgreement}
                                        checked={dischargeAgreement}
                                        onChange={setDischargeAgreement}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container item xs={12} alignItems='center' justifyContent='flex-end' style={{ paddingRight: '10px' }}>
                                <Button variant='contained' color='primary' onClick={filterActivties} >{i18n.search}</Button>
                            </Grid>
                        </fieldset>
                    </Grid>
                    <Grid item xs={12} className='padding-top-1' style={{ width: 'inherit' }}>
                        {!dataloaded && <ProgressCard indeterminate /> ||
                            <Table col={12}
                                title={i18n.potentialSubstanceActivity}
                                data={data}
                                actions={actions}
                                sortable condensed paging color
                                nbPerPageLabel={nbPerPageLabelHuge}
                                customHeaders={{
                                    parameterName: i18n.parameter,
                                    installationName: i18n.establishment,
                                    icpeDisplay: i18n.icpe,
                                    dischargeAgreementLabel: i18n.agreement,
                                    inActivityDisplay: i18n.inActivity,
                                }}
                                type={{ headers: tableHeaders }}
                            />
                        }
                    </Grid>
                </Grid>
            </Card>
        </Grid>
    )
}

STEPPollutionSearchApp.propTypes = {
}


export default STEPPollutionSearchApp
