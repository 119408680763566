import { useMemo } from 'react'
import { useSelector, shallowEqual } from 'react-redux'

const useSandreList = (field, withReference) => {
    const {
        sandreCodes,
    } = useSelector(store => ({
        sandreCodes: store.ReferencialReducer.sandreCodes,
    }), shallowEqual)

    const sandreList = useMemo(() => sandreCodes.filter(c => c.field === field).map(sc => ({ ...sc, name: sc.name || `[${sc.code}]` })), [sandreCodes, field])

    if (withReference) {
        return sandreList.map(s => ({ ...s, code: s.reference }))
    }

    return sandreList
}

export default useSandreList