import React, { useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import WatermassSelect from '../forms/specific/WatermassSelect'
import WatershedSelect from '../forms/specific/WatershedSelect'
import { differenceBy, flatten, intersectionBy, omit, pick } from 'lodash'
import i18n from 'simple-react-i18n'
import Input from '../forms/Input'
import AdministrationAction from '../../administration/actions/AdministrationAction'
import { searchAllCharacters } from '../../utils/StringUtil'
import CityDto from '../../referencial/components/city/dto/CityDto'
import SelectionTable from '../datatable/SelectionTable'
import { hasValue } from 'utils/NumberUtil'
import SimpleFilterSelect from 'components/forms/specific/SimpleFilterSelect'

const SelectedElementsModal = ({
    selectedList = [],
    onChange = () => { },
    elementType = '',
    list = [],
}) => {
    const {
        cities,
        propsFilterResults,
    } = useSelector(store => ({
        cities: store.CityReducer.cities,
        propsFilterResults: store.StationReducer.filterResults,
    }), shallowEqual)

    const isQualityType = elementType === 'quality'
    const cache = pick(AdministrationAction.getCache(elementType), ['filter'])
    const stationsFormatted = differenceBy(list, selectedList, 'id').map(({ id, code, name, townCode }) => {
        const city = cities.find(c => c.id === townCode) || {}
        return {
            id,
            code,
            name,
            townCode,
            city: city.labelWithCode || `${townCode || ''} ${city.name || ''}`,
        }
    })

    const firstFilteredResults = (() => {
        if (hasValue(cache.filter) && cache.filter !== -1 && propsFilterResults.length) {
            return flatten(propsFilterResults.map(stationId => stationsFormatted.find(station => station.id === stationId) || []))
        }
        return stationsFormatted || []
    })

    const [searchValue, setSearchValue] = useState('')
    const [filter, setFilter] = useState(-1)
    const [filterResults, setFilterResults] = useState(firstFilteredResults)

    const [watermassStations, setWatermassStations] = useState([])
    const [watershedStations, setWatershedStations] = useState([])
    const [selectedStations, setSelectedStations] = useState(selectedList)
    const [stations, setStations] = useState(stationsFormatted)

    const getStationsFilter = () => {
        if (isQualityType) {
            return [
                ...filterResults,
                ...watermassStations,
                ...watershedStations,
            ]
        }
        return filterResults
    }

    const getFilteredStations = () => {
        const stationFilter = getStationsFilter()
        const availableStations = ((filter !== -5 && stationFilter.length) || filter === -5) ? intersectionBy(stations, stationFilter, 'id') : stations

        const searchValueFormat = searchAllCharacters(searchValue)
        return availableStations.filter(({ code, name, townCode }) => searchAllCharacters(`${code}${name}${townCode}`).includes(searchValueFormat))
    }

    const onAdd = station => {
        const stationsSorted = stations.filter(({ id }) => station.id !== id)
        const newSelectedStations = [...selectedStations, omit(station, ['nullValue3'])]
        setStations(stationsSorted)
        setSelectedStations(newSelectedStations)
        onChange(newSelectedStations)
    }

    const onDelete = station => {
        const stationsSorted = [...stations, omit(station, ['nullValue3'])]
        const newSelectedStations = selectedStations.filter(({ id }) => id !== station.id)
        setStations(stationsSorted)
        setSelectedStations(newSelectedStations)
        onChange(newSelectedStations)
    }

    const addAll = stationsParam => {
        const newStations = differenceBy(stations, stationsParam, 'id')
        const newSelectedStations = [...selectedStations, ...stationsParam.map(s => omit(s, ['nullValue3']))]
        setStations(newStations)
        setSelectedStations(newSelectedStations)
        onChange(newSelectedStations)
    }

    const deleteAll = () => {
        const newStationsFormatted = list.map(({ id, code, name, townCode }) => {
            const city = cities.find(c => c.id === townCode) || {}
            return {
                id,
                code,
                name,
                townCode,
                city: city.labelWithCode || `${townCode || ''} ${city.name || ''}`,
            }
        })
        setStations(newStationsFormatted)
        setSelectedStations([])
        onChange([])
    }

    const filteredStations = getFilteredStations()

    return (
        <div style={{ minHeight: '65vh' }}>
            <div className='row no-margin padding-top-1'>
                {elementType && (
                    <SimpleFilterSelect
                        stationType={elementType}
                        col={6}
                        onChange={(results, newFilter) => {
                            setFilterResults(results)
                            setFilter(newFilter)
                        }}
                        stations={list}
                    />
                )}
                <Input
                    col={6}
                    title={i18n.search}
                    value={searchValue}
                    onChange={v => setSearchValue(v)}
                    data-cy='searchInput'
                />
            </div>
            <div className='row no-margin'>
                {isQualityType && (
                    <WatermassSelect
                        col={6}
                        onChange={v => setWatermassStations(v)}
                        stationType='quality'
                    />
                )}
                {isQualityType && (
                    <WatershedSelect
                        col={6}
                        onChange={v => setWatershedStations(v)}
                        stationType='quality'
                    />
                )}
            </div>
            <SelectionTable
                maxHeight='38vh'

                listData={filteredStations}
                listHeaders={['code', 'city', 'name']}
                listTitle={i18n.nonSelectedStations}

                selectedData={selectedStations}
                selectedHeaders={['code', 'city', 'name']}
                selectedTitle={i18n.selectedStations}

                onAdd={onAdd}
                onDelete={onDelete}
                addAll={addAll}
                deleteAll={deleteAll}
            />
        </div>
    )
}

SelectedElementsModal.propTypes = {
    selectedList: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        code: PropTypes.string,
        name: PropTypes.string,
        townCode: PropTypes.string,
        city: PropTypes.string,
    })),
    list: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        code: PropTypes.string,
        name: PropTypes.string,
        townCode: PropTypes.string,
        city: PropTypes.string,
    })),
    cities: PropTypes.arrayOf(PropTypes.instanceOf(CityDto)),
    onChange: PropTypes.func,
    elementType: PropTypes.string,
    filterResults: PropTypes.arrayOf(PropTypes.number),
}

export default SelectedElementsModal
