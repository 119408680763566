import { Grid } from '@mui/material'
import { SimpleColorPicker } from 'components/forms/ColorPicker'
import { StyledFieldSet, StyledLegend } from 'components/StyledElements'
import { PropTypes } from 'prop-types'
import React, { useMemo } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import useListIndexed from 'utils/customHook/useListIndexed'
import Table from '../../../components/datatable/Table'
import { nbPerPageLabelShort } from '../../../referencial/constants/ReferencialConstants'
import { RemarkLegend } from '../qualityComponents/Legend'

const QualityGraphicLegendPanel = ({
    analysis = [],
    stations = {},
    onChange = () => {},
}) => {
    const {
        qualitometers,
    } = useSelector(store => ({
        qualitometers: store.QualityReducer.qualitometersLight,
    }), shallowEqual)

    const qualitometersIndex = useListIndexed(qualitometers, 'id')

    const formattedStations = useMemo(() => stations.map(({ id, color }) => {
        const qualito = qualitometersIndex[id]
        return {
            code: qualito?.code,
            name: qualito?.name,
            nullValue: (
                <>
                    <SimpleColorPicker
                        color={color}
                        style={{
                            margin: '4px',
                            width: '2rem',
                            height: '15px',
                            cursor: 'pointer',
                        }}
                        onChange={newColor => onChange(id, newColor)}
                        noLabel
                    />
                </>
            ),
        }
    }), [qualitometersIndex, stations])

    return (
        <Grid container>
            <Grid item xs={12}>
                <StyledFieldSet /* style={{ height: '100%' }}*/>
                    <StyledLegend>{`${i18n.stations} (${stations.length} ${stations.length === 1 ? i18n.element : i18n.elements})`}</StyledLegend>
                    <Table
                        data={formattedStations}
                        showTitle={false}
                        type={{ headers: ['nullValue', 'code', 'name'] }}
                        condensed
                        sortable
                        paging
                        nbPerPageLabel={nbPerPageLabelShort}
                    />
                </StyledFieldSet>
            </Grid>
            <Grid item xs={12}>
                <RemarkLegend
                    list={analysis}
                    col={4}
                />
            </Grid>
        </Grid>
    )
}

QualityGraphicLegendPanel.propTypes = {
    analysis: PropTypes.arrayOf(PropTypes.shape({
        // DtoAnalysis
        // calculateThresholdValue
    })),
    stations: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        color: PropTypes.string,
    })),
    onChange: PropTypes.func,
}

export default QualityGraphicLegendPanel