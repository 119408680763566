import React from 'react'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import DtoManagementUnitAUP from '../dto/DtoManagementUnitAUP'
import { Button, Dialog } from '@mui/material'
import NumberField from '../../../../components/forms/NumberField'
import Row from '../../../../components/react/Row'
import Input from 'components/forms/Input'
import { hasValue } from 'utils/NumberUtil'
import { StyledFieldSet, StyledLegend } from 'components/StyledElements'
import { DialogActionsMUI, DialogContentMUI, DialogTitleMUI } from 'components/styled/Dialog'
import Icon from 'components/icon/Icon'

const VolumesManagementUnitDialog = ({
    linkAUPToUpdate,
    openDialog,
    onChange = () => { },
    validUpdate = () => { },
    closeDialog = () => { },
}) => {
    const getValue = (value) => {
        return hasValue(value) ? value : 0
    }

    const getTotal = (link) => {
        return getValue(link.month01) + getValue(link.month02) + getValue(link.month03) + getValue(link.month04) +
        getValue(link.month05) + getValue(link.month06) + getValue(link.month07) + getValue(link.month08) +
        getValue(link.month09) + getValue(link.month10) + getValue(link.month11) + getValue(link.month12)
    }

    return (
        <Dialog
            fullWidth
            maxWidth='lg'
            onClose={closeDialog}
            open={openDialog}
        >
            <DialogTitleMUI style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                {i18n.updateVolumesByYears}
                <Icon style={{ color: 'white' }} size='small' icon='close' onClick={closeDialog} />
            </DialogTitleMUI>
            <DialogContentMUI>
                <Row className='padding-top-1'>
                    <NumberField
                        col={6}
                        title={i18n.yearStart}
                        value={linkAUPToUpdate.year}
                        onChange={(value) => onChange({ year: value })}
                    />
                    <NumberField
                        col={6}
                        title={i18n.yearEnd}
                        value={linkAUPToUpdate.yearEnd}
                        onChange={(value) => onChange({ yearEnd: value })}
                    />
                </Row>
                <Row>
                    <NumberField
                        col={6}
                        title={i18n.volume}
                        value={linkAUPToUpdate.aup}
                        onChange={(value) => onChange({ aup: value })}
                    />
                    <NumberField
                        col={6}
                        title={i18n.volumePrefectural}
                        value={linkAUPToUpdate.aupPrefectural}
                        onChange={(value) => onChange({ aupPrefectural: value })}
                    />
                </Row>
                <Row>
                    <NumberField
                        col={6}
                        title={i18n.volumeLowWater}
                        value={linkAUPToUpdate.aupLowWater}
                        onChange={(value) => onChange({ aupLowWater: value })}
                    />
                    <NumberField
                        col={6}
                        title={i18n.volumeExceptLowWater}
                        value={linkAUPToUpdate.aupExceptLowWater}
                        onChange={(value) => onChange({ aupExceptLowWater: value })}
                    />
                </Row>
                <Row>
                    <NumberField
                        col={3}
                        title={i18n.volumeLowWaterSup}
                        value={linkAUPToUpdate.aupLowWaterSup}
                        onChange={(value) => onChange({ aupLowWaterSup: value })}
                    />
                    <NumberField
                        col={3}
                        title={i18n.volumeLowWaterSout}
                        value={linkAUPToUpdate.aupLowWaterSout}
                        onChange={(value) => onChange({ aupLowWaterSout: value })}
                    />
                    <NumberField
                        col={3}
                        title={i18n.volumeExceptLowWaterSup}
                        value={linkAUPToUpdate.aupExceptLowWaterSup}
                        onChange={(value) => onChange({ aupExceptLowWaterSup: value })}
                    />
                    <NumberField
                        col={3}
                        title={i18n.volumeExceptLowWaterSout}
                        value={linkAUPToUpdate.aupExceptLowWaterSout}
                        onChange={(value) => onChange({ aupExceptLowWaterSout: value })}
                    />
                </Row>
                <Row>
                    <StyledFieldSet className='margin-1'>
                        <StyledLegend>{i18n.volumeByMonth}</StyledLegend>
                        <NumberField
                            col={1}
                            title={i18n.januaryShort}
                            value={linkAUPToUpdate.month01}
                            onChange={value => onChange({ month01: value })}
                            min={0}
                        />
                        <NumberField
                            col={1}
                            title={i18n.februaryShort}
                            value={linkAUPToUpdate.month02}
                            onChange={value => onChange({ month02: value })}
                            min={0}
                        />
                        <NumberField
                            col={1}
                            title={i18n.marchShort}
                            value={linkAUPToUpdate.month03}
                            onChange={value => onChange({ month03: value })}
                            min={0}
                        />
                        <NumberField
                            col={1}
                            title={i18n.aprilShort}
                            value={linkAUPToUpdate.month04}
                            onChange={value => onChange({ month04: value })}
                            min={0}
                        />
                        <NumberField
                            col={1}
                            title={i18n.mayShort}
                            value={linkAUPToUpdate.month05}
                            onChange={value => onChange({ month05: value })}
                            min={0}
                        />
                        <NumberField
                            col={1}
                            title={i18n.juneShort}
                            value={linkAUPToUpdate.month06}
                            onChange={value => onChange({ month06: value })}
                            min={0}
                        />
                        <NumberField
                            col={1}
                            title={i18n.julyShort}
                            value={linkAUPToUpdate.month07}
                            onChange={value => onChange({ month07: value })}
                            min={0}
                        />
                        <NumberField
                            col={1}
                            title={i18n.augustShort}
                            value={linkAUPToUpdate.month08}
                            onChange={value => onChange({ month08: value })}
                            min={0}
                        />
                        <NumberField
                            col={1}
                            title={i18n.septemberShort}
                            value={linkAUPToUpdate.month09}
                            onChange={value => onChange({ month09: value })}
                            min={0}
                        />
                        <NumberField
                            col={1}
                            title={i18n.octoberShort}
                            value={linkAUPToUpdate.month10}
                            onChange={value => onChange({ month10: value })}
                            min={0}
                        />
                        <NumberField
                            col={1}
                            title={i18n.novemberShort}
                            value={linkAUPToUpdate.month11}
                            onChange={value => onChange({ month11: value })}
                            min={0}
                        />
                        <NumberField
                            col={1}
                            title={i18n.decemberShort}
                            value={linkAUPToUpdate.month12}
                            onChange={value => onChange({ month12: value })}
                            min={0}
                        />
                        <Input
                            col={1}
                            title={i18n.total}
                            containerClassName='right bold'
                            onChange={value => onChange({ total: value })}
                            value={getTotal(linkAUPToUpdate)}
                            disabled
                        />
                    </StyledFieldSet>
                </Row>
            </DialogContentMUI>
            <DialogActionsMUI>
                <Button onClick={validUpdate} variant='contained' color='primary'>
                    {i18n.validate}
                </Button>
            </DialogActionsMUI>
        </Dialog>
    )
}

VolumesManagementUnitDialog.propTypes = {
    linkAUPToUpdate: PropTypes.instanceOf(DtoManagementUnitAUP),
    openDialog: PropTypes.bool,
    onChange: PropTypes.func,
    validUpdate: PropTypes.func,
    closeDialog: PropTypes.func,
}

export default VolumesManagementUnitDialog