import DtoCountry from '../../city/dto/DtoCountry'
import { RECEIVE_COUNTRIES, RECEIVE_COUNTRY } from '../constants/CountryConstants'

export function CountryReducer(state = {}, action) {
    switch (action.type) {
        case RECEIVE_COUNTRIES:
            return {
                ...state,
                countries: action.countries.map(c => new DtoCountry(c)),
            }
        case RECEIVE_COUNTRY:
            return {
                ...state,
                country: new DtoCountry(action.country),
            }
        default:
            return state
    }
}

export const store = {
    country: {},
    countries: [],
}