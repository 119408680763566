import PropTypes from 'prop-types'
import { Slide } from '@mui/material'
import React, { useEffect, useState } from 'react'


const SwapComponent = ({
    left, // leftComponent
    right, // rightComponent
    isSwapped, // boolean to trigger the swap
    timeout = 300, // speed of swapping
}) => {
    const [leftAbsolute, setLeftAbsolute] = useState(false)
    const [leftIsIn, setLeftIsIn] = useState(true)
    const [rightIsIn, setRightIsIn] = useState(false)


    useEffect(() => {
        if (isSwapped !== rightIsIn) {
            if (isSwapped) {
                setLeftIsIn(false)
                setTimeout(() => {
                    setLeftAbsolute(true)
                    setRightIsIn(true)
                }, timeout/2)
            } else {
                setRightIsIn(false)
                setTimeout(() => {
                    setLeftAbsolute(false)
                    setLeftIsIn(true)
                }, timeout/2)
            }
        }
    }, [isSwapped])

    return (
        <>
            <div
                style={{
                    position: leftAbsolute ? 'absolute' : 'relative',
                    display: leftAbsolute ? 'none' : null,
                }}
            >
                <Slide direction='right' in={leftIsIn} timeout={timeout/2 - 50}>
                    {left}
                </Slide>
            </div>
            <div
                style={{
                    position: leftAbsolute ? 'relative' : 'absolute',
                    display: leftAbsolute ? null : 'none',
                }}
            >
                <Slide direction='left' in={rightIsIn} timeout={timeout/2 - 50} >
                    {right}
                </Slide>
            </div>
        </>
    )
}

SwapComponent.propTypes = {
    left: PropTypes.element,
    right: PropTypes.element,
    leftSwown: PropTypes.bool,
    timeout: PropTypes.number,
}

export default SwapComponent