class _StackedSeries {
    constructor(listSeries) {
        this.listSeries = listSeries
    }

    getJson = () => {
        return this.listSeries.map(area => {
            const options = area.getJson()
            options.stack = 'groupedArea'
            return options
        })
    }
}

export default function StackedSeries(args) {
    return new _StackedSeries(args)
}


