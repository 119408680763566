export default class DtoCavity {
    constructor(obj) {
        this.id = obj.id
        this.typeCode = obj.typeCode
        this.nature = obj.nature
        this.confidentiality = obj.confidentiality
        this.confidentialityCompany = obj.confidentialityCompany
        this.startDate = obj.startDate
        this.author = obj.author
        this.updateAuthor = obj.updateAuthor
        this.validityDate = obj.validityDate
        this.validityAuthor = obj.validityAuthor
        this.broadcastAuthor = obj.broadcastAuthor
        this.location = obj.location
    }
}