import React from 'react'
import PropTypes from 'prop-types'
import { shallowEqual, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import { getSandreList } from '../../../utils/StoreUtils'
import Card from '../../../components/card/Card'
import StationMapDashboardPanel from '../../../station/components/dashboard/component/map/StationMapDashboardPanel'
import Input from '../../../components/forms/Input'
import DatePicker from '../../../components/forms/DatePicker'
import NumberField from '../../../components/forms/NumberField'
import Select from '../../../components/forms/Select'
import Textarea from '../../../components/forms/Textarea'
import Checkbox from '../../../components/forms/Checkbox'
import { SANDRE } from '../../../referencial/constants/ReferencialConstants'
import { hasValue } from '../../../utils/NumberUtil'
import LastEventPanel from '../../../station/components/event/LastEventPanel'
import ContactCard from '../../../referencial/components/contact/components/ContactCard'
import StationUpdatePanel from '../../../station/components/update/StationUpdatePanel'
import { some } from 'lodash'
import { getControlColor } from '../../../utils/InstallationUtils'
import { getColorCircleElement, getRGBColor } from '../../../utils/ColorUtil'
import Row from '../../../components/react/Row'
import LinkedStationsPanel from '../../../station/components/associatedStation/LinkedStationsPanel'
import MaterielAssigned from '../../../station/components/materiel/MaterielAssigned'

import InstallationInfosDashboard from '../installations/components/InstallationInfosDashboard'
import { H_MAT_MODULE } from '../../../account/constants/AccessRulesConstants'
import HabilitationRequired from '../../../utils/components/HabilitationRequired'
import { WhiteCard } from 'components/styled/Card'
import { Grid } from '@mui/material'
import InstallationCounterPanel from '../installationDefault/InstallationCounterPanel'

const StraitDashboardPanel = ({
    stationType = '',
}) => {
    const {
        installation,
        cities,
        sandreCodes,
        users,
        status,
        installationEvents,
    } = useSelector(store => ({
        installation: store.InstallationReducer.installation,
        cities: store.CityReducer.cities,
        sandreCodes: store.ReferencialReducer.sandreCodes,
        users: store.UserReducer.users,
        status: store.QualityReducer.status,
        installationEvents: store.InstallationReducer.installationEvents,
    }), shallowEqual)

    const getOriginStatusColor = () => {
        if (hasValue(installation.status)) {
            const color = getControlColor(installation.status)
            return getColorCircleElement(getRGBColor(color), true)
        }
        return null
    }

    const readMode = { readMode: true, editMode: false, hideNull: true }

    return (
        <Grid container>
            <Grid item xs={9} sx={{ padding: '10px' }}>
                <Card noMargin={false} className='margin-bottom-1'>
                    <InstallationInfosDashboard />
                </Card>
                <WhiteCard
                    title={i18n.characteristics}
                    smallCard
                    displayIf={some(['exploitationCode', 'comments'], key => hasValue(installation[key]))}
                    noMargin={false}
                    className='margin-bottom-1'
                >
                    <Grid container sx={{ padding: '0 20px' }}>
                        <Grid item xs={6}>
                            <Checkbox
                                componentClassName='padding-bottom-1'
                                checked={hasValue(installation.exploitationCode) ? installation.exploitationCode == 1 : null}
                                label={i18n.exploitation}
                                disabled {...readMode}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Textarea
                                value={installation.comments}
                                title={i18n.comment}
                                {...readMode}
                            />
                        </Grid>
                    </Grid>
                </WhiteCard>
                <InstallationCounterPanel
                    stationId={installation.id}
                    showTitle
                    showKeyFigures
                    inPopup
                />
            </Grid>
            <Grid item xs={3} sx={{ padding: '10px' }}>
                <StationUpdatePanel
                    station={installation}
                />
                <WhiteCard
                    cardStyle={{ display: 'grid', padding: '0 20px' }}
                    displayIf={some(['dataOrigin', 'closeComment', 'status', 'statusDate', 'deliberationDate', 'confidential'], key => hasValue(installation[key]))}
                >
                    {hasValue(installation.jobExecutionId) && (
                        <div className='row no-margin padding-left-1 padding-top-1'>
                            <h6 className='valign-wrapper'>
                                <i className='material-icons rem-3-size padding-right-1'>wifi</i>
                                {i18n.automaticUpdate}
                            </h6>
                        </div>
                    )}
                    <Input
                        title={i18n.dataOrigin}
                        value={installation.dataOrigin}
                        {...readMode}
                    />
                    <Select
                        label={[getOriginStatusColor(), i18n.status]}
                        value={installation.status}
                        options={status}
                        {...readMode}
                    />
                    <DatePicker
                        value={installation.statusDate}
                        id='statusDate'
                        title={i18n.controlDoneAt}
                        {...readMode}
                    />
                    <Select
                        label={i18n.controlDoneBy}
                        value={installation.statusLogin}
                        options={users.map(u => ({ code: u.login, name: u.name || u.login }))}
                        {...readMode}
                    />
                    <DatePicker
                        value={installation.endDate}
                        id='deliberationDate'
                        title={i18n.deliberationDate}
                        {...readMode}
                    />
                    <Input
                        value={installation.closeComment}
                        title={i18n.become}
                        {...readMode}
                    />
                    <Checkbox
                        componentClassName='padding-bottom-1'
                        label={i18n.confidential}
                        {...readMode}
                        checked={hasValue(installation.confidential) ? installation.confidential == '1' : null}
                    />
                </WhiteCard>
                <Row className='padding-top-1'>
                    <ContactCard
                        title={i18n.contact}
                        contactCode={installation.ownerCode}
                        {...readMode}
                    />
                </Row>
                <WhiteCard
                    cardStyle={{ display: 'grid', padding: '0 20px' }}
                >
                    <Input
                        title={i18n.address}
                        value={installation.address}
                        {...readMode}
                    />
                    <Select
                        value={installation.townCode}
                        label={i18n.city}
                        options={cities}
                        keyValue='id'
                        displayWithCode {...readMode}
                    />
                    <NumberField
                        title='X'
                        value={installation.x}
                        floatValue
                        {...readMode}
                    />
                    <NumberField
                        title='Y'
                        value={installation.y}
                        floatValue
                        {...readMode}
                    />
                    <NumberField
                        title='Z'
                        value={installation.altitude}
                        floatValue
                        {...readMode}
                    />
                    <Select
                        label={i18n.projection}
                        value={installation.projection}
                        options={getSandreList(sandreCodes, SANDRE.PROJECTION)}
                        {...readMode}
                    />
                </WhiteCard>
                <HabilitationRequired habilitation={H_MAT_MODULE}>
                    <MaterielAssigned
                        stationId={installation.id}
                        type={stationType}
                    />
                    <div className='padding-top-1' />
                </HabilitationRequired>
                <LastEventPanel
                    id={installation.id}
                    events={installationEvents}
                    stationId={installation.id}
                    stationType='installation'
                />
                <div className='padding-top-1' />
                <StationMapDashboardPanel
                    noMarkerTooltip
                    station={installation}
                    type={'installation'}
                />
                <div className='padding-top-1' />
                <LinkedStationsPanel
                    dashboardMode
                    checkable
                    station={installation}
                    withColors
                    hideTitle
                />
            </Grid>
        </Grid>
    )
}

StraitDashboardPanel.propTypes = {
    stationType: PropTypes.string,
    setDataLoaded: PropTypes.func,
}

export default StraitDashboardPanel