import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import Input from '../../../../../components/forms/Input'
import Row from '../../../../../components/react/Row'
import NumberField from '../../../../../components/forms/NumberField'
import DtoElecmecEquipment from 'installation/dto/installation/DtoElecmecEquipment'
import Checkbox from 'components/forms/Checkbox'
import { Box } from '@mui/material'

class ElecmecEquipmentModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            elecmecEquipment: { ...props.elecmecEquipment, idStation: props.id },
        }
    }

    componentDidUpdate(prevProps) {
        if (!prevProps.popupIsOpen && this.props.popupIsOpen) {
            $('#descriptionModal').on('click', this.onSave)
        }
    }
    onSave = () => {
        this.props.saveResult(new DtoElecmecEquipment(this.state.elecmecEquipment))
        $('.modal').modal('close')
    }

    onChangeElement = (changes) => this.setState({ elecmecEquipment: { ...this.state.elecmecEquipment, ...changes } })

    render() {
        const { elecmecEquipment } = this.state

        return (
            <div>
                <Row>
                    <NumberField
                        col={6}
                        value={elecmecEquipment.numGroup}
                        title={i18n.numGroup}
                        onChange={v => this.onChangeElement({ numGroup: v })}
                    />
                    <Input
                        col={6}
                        value={elecmecEquipment.nameGroup}
                        title={i18n.nameGroup}
                        onChange={v => this.onChangeElement({ nameGroup: v })}
                    />
                </Row>
                <Row>
                    <NumberField
                        col={6}
                        value={elecmecEquipment.nominalFlow}
                        title={i18n.nominalFlow}
                        onChange={v => this.onChangeElement({ nominalFlow: v })}
                    />
                    <Box className='padding-top-1'>
                        <Checkbox
                            col={6}
                            checked={elecmecEquipment.waterHammer}
                            label={i18n.waterHammer}
                            onChange={v => this.onChangeElement({ waterHammer: v })}
                        />
                    </Box>
                </Row>
                <Row>
                    <NumberField
                        col={6}
                        value={elecmecEquipment.hmt}
                        title={i18n.hmt}
                        floatValue
                        onChange={v => this.onChangeElement({ hmt: v })}
                    />
                    <NumberField
                        col={6}
                        value={elecmecEquipment.numberPumps}
                        title={i18n.numberPumps}
                        onChange={v => this.onChangeElement({ numberPumps: v })}
                    />
                </Row>
            </div>
        )
    }
}

ElecmecEquipmentModal.propTypes = {
    elecmecEquipment: PropTypes.instanceOf(DtoElecmecEquipment),
    id: PropTypes.number,
    popupIsOpen: PropTypes.bool,
    saveResult: PropTypes.func,
}


const mapStateToProps = store => ({
    popupIsOpen: store.SieauReducer.popupIsOpen,
})

ElecmecEquipmentModal.defaultProps = {
    elecmecEquipment: {},
}

export default connect(mapStateToProps)(ElecmecEquipmentModal)
