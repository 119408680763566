import { Button, Card, CardContent, Dialog, DialogActions, DialogContent, Grid2 } from '@mui/material'
import Autocomplete from 'components/forms/Autocomplete'
import NumberField from 'components/forms/NumberField'
import Select from 'components/forms/Select'
import { DefaultDialogTitle } from 'components/styled/Dialog'
import { StyledFieldSet, StyledLegend } from 'components/StyledElements'
import React, { useEffect, useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import useSandreList from 'utils/customHook/useSandreList'
import useUpdateEffect from 'utils/customHook/useUpdateEffect'
import PropTypes from 'prop-types'
import { isNil } from 'lodash'

const HydrobioFaunalDialog = ({
    isOpen = false,
    onClose = () => {},
    onValidate = () => {},
    title = '',
    hydrobioList: hydrobioListProps = {},
    microSampling = false,
}) => {
    const {
        taxons,
        remarks,
        units,
    } = useSelector(store => ({
        taxons: store.TaxonReducer.taxons,
        remarks: store.OperationReducer.remarks,
        units: store.UnitReducer.units,
    }), shallowEqual)

    const developLexicon = useSandreList('TAXONS.DEVELOPPEMENT')
    const sizeLexicon = useSandreList(`TAXONS.${hydrobioListProps.taxon}.TAILLE`)
    const trustLexicon = useSandreList('ANALYSES.CONFIANCE')

    const [hydrobioList, setHydrobioList] = useState(hydrobioListProps)

    const onChange = obj => setHydrobioList(prevHL => ({ ...prevHL, ...obj }))

    useUpdateEffect(() => {
        if (isOpen) setHydrobioList(hydrobioListProps)
    }, [isOpen])

    useEffect(() => {
        if (!microSampling) return
        const p1 = hydrobioList.enumerationP1 ?? 0
        const p2 = hydrobioList.enumerationP2 ?? 0
        const p3 = hydrobioList.enumerationP3 ?? 0
        const p4 = hydrobioList.enumerationP4 ?? 0
        onChange({ enumerationA: p1 + p2 + p3 + p4 })
    }, [hydrobioList.enumerationP1, hydrobioList.enumerationP2, hydrobioList.enumerationP3, hydrobioList.enumerationP4, microSampling])

    useEffect(() => {
        if (!microSampling) return
        const p5 = hydrobioList.enumerationP5 ?? 0
        const p6 = hydrobioList.enumerationP6 ?? 0
        const p7 = hydrobioList.enumerationP7 ?? 0
        const p8 = hydrobioList.enumerationP8 ?? 0
        onChange({ enumerationA: p5 + p6 + p7 + p8 })
    }, [hydrobioList.enumerationP5, hydrobioList.enumerationP6, hydrobioList.enumerationP7, hydrobioList.enumerationP8, microSampling])

    useEffect(() => {
        if (!microSampling) return
        const p9 = hydrobioList.enumerationP9 ?? 0
        const p10 = hydrobioList.enumerationP10 ?? 0
        const p11 = hydrobioList.enumerationP11 ?? 0
        const p12 = hydrobioList.enumerationP12 ?? 0
        onChange({ enumerationA: p9 + p10 + p11 + p12 })
    }, [hydrobioList.enumerationP9, hydrobioList.enumerationP10, hydrobioList.enumerationP11, hydrobioList.enumerationP12, microSampling])

    return (
        <Dialog
            maxWidth='lg'
            fullWidth
            open={isOpen}
        >
            <DefaultDialogTitle
                title={title}
                onClose={onClose}
            />
            <DialogContent style={{ overflowX: 'hidden' }}>
                <Card>
                    <CardContent>
                        <Grid2 container rowSpacing={1} columnSpacing={2}>
                            <Grid2 size={6}>
                                <Select
                                    label={i18n.taxon}
                                    options={taxons}
                                    value={hydrobioList.taxon}
                                    onChange={taxon => onChange({ taxon })}
                                    keyLabel='labelWithCode'
                                    disabled={!isNil(hydrobioListProps.taxon)}
                                />
                            </Grid2>
                            <Grid2 size={3}>
                                <Autocomplete
                                    label={i18n.unit}
                                    options={units}
                                    value={hydrobioList.unit}
                                    onChange={unit => onChange({ unit })}
                                    keyLabel='symbolWithName'
                                    keyFilter={['name', 'symbol', 'code']}
                                />
                            </Grid2>
                            <Grid2 size={3}>
                                <Select
                                    options={remarks}
                                    value={hydrobioList.remark}
                                    label={i18n.remark}
                                    keyLabel='name'
                                    keyValue='code'
                                    returnNull
                                    onChange={remark => onChange({ remark })}
                                />
                            </Grid2>
                            <Grid2 size={3}>
                                <Select
                                    label={i18n.development}
                                    value={hydrobioList.develop}
                                    options={developLexicon}
                                    keyLabel='name'
                                    keyValue='code'
                                    returnNull
                                    onChange={v => onChange({ develop: v })}
                                />
                            </Grid2>
                            <Grid2 size={3}>
                                <Select
                                    label={i18n.trust}
                                    value={hydrobioList.trust}
                                    options={trustLexicon}
                                    keyLabel='name'
                                    keyValue='code'
                                    returnNull
                                    onChange={v => onChange({ trust: v })}
                                />
                            </Grid2>
                            <Grid2 size={3}>
                                <NumberField
                                    title={i18n.density}
                                    value={hydrobioList.density}
                                    floatValue
                                    onChange={v => onChange({ density: v })}
                                />
                            </Grid2>
                            <Grid2 size={3}>
                                <Select
                                    label={i18n.size}
                                    value={hydrobioList.size}
                                    options={sizeLexicon}
                                    keyLabel='name'
                                    keyValue='code'
                                    returnNull
                                    onChange={v => onChange({ size: v })}
                                />
                            </Grid2>
                            {/* <Grid2 size={3}>
                                <NumberField // calcule indice boolean
                                    title={i18n.indice}
                                    value={hydrobioList.index}
                                    floatValue
                                    // onChange={result => setAnalysis(prev => ({ ...prev, result }))}
                                />
                            </Grid2> */}
                            <Grid2 size={12}>
                                <StyledFieldSet>
                                    <StyledLegend>{i18n.phaseA}</StyledLegend>
                                    <Grid2 container rowSpacing={1} columnSpacing={2}>
                                        <Grid2 size={3}>
                                            <NumberField
                                                title={`${i18n.enumeration} A`}
                                                value={hydrobioList.enumerationA}
                                                floatValue
                                                onChange={v => onChange({ enumerationA: v })}
                                                disabled={microSampling}
                                            />
                                        </Grid2>
                                        <Grid2 size={3}>
                                            <NumberField
                                                title={`${i18n.percent} A`}
                                                value={hydrobioList.percentageA}
                                                floatValue
                                                onChange={v => onChange({ percentageA: v })}
                                                disabled={microSampling}
                                            />
                                        </Grid2>
                                        {microSampling && (
                                            <>
                                                <Grid2 size={6} />
                                                <Grid2 size={3}>
                                                    <NumberField
                                                        title={`${i18n.enumeration} P1`}
                                                        value={hydrobioList.enumerationP1}
                                                        floatValue
                                                        onChange={v => onChange({ enumerationP1: v })}
                                                    />
                                                </Grid2>
                                                <Grid2 size={3}>
                                                    <NumberField
                                                        title={`${i18n.enumeration} P2`}
                                                        value={hydrobioList.enumerationP2}
                                                        floatValue
                                                        onChange={v => onChange({ enumerationP2: v })}
                                                    />
                                                </Grid2>
                                                <Grid2 size={3}>
                                                    <NumberField
                                                        title={`${i18n.enumeration} P3`}
                                                        value={hydrobioList.enumerationP3}
                                                        floatValue
                                                        onChange={v => onChange({ enumerationP3: v })}
                                                    />
                                                </Grid2>
                                                <Grid2 size={3}>
                                                    <NumberField
                                                        title={`${i18n.enumeration} P4`}
                                                        value={hydrobioList.enumerationP4}
                                                        floatValue
                                                        onChange={v => onChange({ enumerationP4: v })}
                                                    />
                                                </Grid2>
                                            </>
                                        )}
                                    </Grid2>
                                </StyledFieldSet>
                            </Grid2>
                            <Grid2 size={12}>
                                <StyledFieldSet>
                                    <StyledLegend>{i18n.phaseB}</StyledLegend>
                                    <Grid2 container rowSpacing={1} columnSpacing={2}>
                                        <Grid2 size={3}>
                                            <NumberField
                                                title={`${i18n.enumeration} B`}
                                                value={hydrobioList.enumerationB}
                                                floatValue
                                                onChange={v => onChange({ enumerationB: v })}
                                                disabled={microSampling}
                                            />
                                        </Grid2>
                                        <Grid2 size={3}>
                                            <NumberField
                                                title={`${i18n.percent} B`}
                                                value={hydrobioList.percentageB}
                                                floatValue
                                                onChange={v => onChange({ percentageB: v })}
                                                disabled={microSampling}
                                            />
                                        </Grid2>
                                        {microSampling && (
                                            <>
                                                <Grid2 size={6} />
                                                <Grid2 size={3}>
                                                    <NumberField
                                                        title={`${i18n.enumeration} P5`}
                                                        value={hydrobioList.enumerationP5}
                                                        floatValue
                                                        onChange={v => onChange({ enumerationP5: v })}
                                                    />
                                                </Grid2>
                                                <Grid2 size={3}>
                                                    <NumberField
                                                        title={`${i18n.enumeration} P6`}
                                                        value={hydrobioList.enumerationP6}
                                                        floatValue
                                                        onChange={v => onChange({ enumerationP6: v })}
                                                    />
                                                </Grid2>
                                                <Grid2 size={3}>
                                                    <NumberField
                                                        title={`${i18n.enumeration} P7`}
                                                        value={hydrobioList.enumerationP7}
                                                        floatValue
                                                        onChange={v => onChange({ enumerationP7: v })}
                                                    />
                                                </Grid2>
                                                <Grid2 size={3}>
                                                    <NumberField
                                                        title={`${i18n.enumeration} P8`}
                                                        value={hydrobioList.enumerationP8}
                                                        floatValue
                                                        onChange={v => onChange({ enumerationP8: v })}
                                                    />
                                                </Grid2>
                                            </>
                                        )}
                                    </Grid2>
                                </StyledFieldSet>
                            </Grid2>
                            <Grid2 size={12}>
                                <StyledFieldSet>
                                    <StyledLegend>{i18n.phaseC}</StyledLegend>
                                    <Grid2 container rowSpacing={1} columnSpacing={2}>
                                        <Grid2 size={3}>
                                            <NumberField
                                                title={`${i18n.enumeration} C`}
                                                value={hydrobioList.enumerationC}
                                                floatValue
                                                onChange={v => onChange({ enumerationC: v })}
                                                disabled={microSampling}
                                            />
                                        </Grid2>
                                        <Grid2 size={3}>
                                            <NumberField
                                                title={`${i18n.percent} C`}
                                                value={hydrobioList.percentageC}
                                                floatValue
                                                onChange={v => onChange({ percentageC: v })}
                                                disabled={microSampling}
                                            />
                                        </Grid2>
                                        {microSampling && (
                                            <>
                                                <Grid2 size={6} />
                                                <Grid2 size={3}>
                                                    <NumberField
                                                        title={`${i18n.enumeration} P9`}
                                                        value={hydrobioList.enumerationP9}
                                                        floatValue
                                                        onChange={v => onChange({ enumerationP9: v })}
                                                    />
                                                </Grid2>
                                                <Grid2 size={3}>
                                                    <NumberField
                                                        title={`${i18n.enumeration} P10`}
                                                        value={hydrobioList.enumerationP10}
                                                        floatValue
                                                        onChange={v => onChange({ enumerationP10: v })}
                                                    />
                                                </Grid2>
                                                <Grid2 size={3}>
                                                    <NumberField
                                                        title={`${i18n.enumeration} P11`}
                                                        value={hydrobioList.enumerationP11}
                                                        floatValue
                                                        onChange={v => onChange({ enumerationP11: v })}
                                                    />
                                                </Grid2>
                                                <Grid2 size={3}>
                                                    <NumberField
                                                        title={`${i18n.enumeration} P12`}
                                                        value={hydrobioList.enumerationP12}
                                                        floatValue
                                                        onChange={v => onChange({ enumerationP12: v })}
                                                    />
                                                </Grid2>
                                            </>
                                        )}
                                    </Grid2>
                                </StyledFieldSet>
                            </Grid2>
                            <Grid2 size={12}>
                                <StyledFieldSet>
                                    <StyledLegend>{i18n.phaseCbis}</StyledLegend>
                                    <Grid2 container rowSpacing={1} columnSpacing={2}>
                                        <Grid2 size={3}>
                                            <NumberField
                                                title={`${i18n.enumeration} C'`}
                                                value={hydrobioList.enumerationC2}
                                                floatValue
                                                onChange={v => onChange({ enumerationC2: v })}
                                                disabled={microSampling}
                                            />
                                        </Grid2>
                                    </Grid2>
                                </StyledFieldSet>
                            </Grid2>
                        </Grid2>
                    </CardContent>
                </Card>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => onValidate(hydrobioList)} variant='contained' color='primary'>
                    {i18n.validate}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

HydrobioFaunalDialog.propTypes = {
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
    onValidate: PropTypes.func,
    title: PropTypes.string,
    hydrobioList: PropTypes.shape({}),
    microSampling: PropTypes.bool,
}

const HydrobioFloristicDialog = ({
    isOpen = false,
    onClose = () => {},
    onValidate = () => {},
    title = '',
    hydrobioList: hydrobioListProps = {},
}) => {
    const {
        taxons,
        remarks,
        units,
    } = useSelector(store => ({
        taxons: store.TaxonReducer.taxons,
        remarks: store.OperationReducer.remarks,
        units: store.UnitReducer.units,
    }), shallowEqual)

    const developLexicon = useSandreList('TAXONS.DEVELOPPEMENT')
    const sizeLexicon = useSandreList(`TAXONS.${hydrobioListProps.taxon}.TAILLE`)
    const trustLexicon = useSandreList('ANALYSES.CONFIANCE')

    const [hydrobioList, setHydrobioList] = useState(hydrobioListProps)

    const onChange = obj => setHydrobioList(prevHL => ({ ...prevHL, ...obj }))

    useUpdateEffect(() => {
        if (isOpen) setHydrobioList(hydrobioListProps)
    }, [isOpen])

    return (
        <Dialog
            maxWidth='lg'
            fullWidth
            open={isOpen}
        >
            <DefaultDialogTitle
                title={title}
                onClose={onClose}
            />
            <DialogContent style={{ overflowX: 'hidden' }}>
                <Card>
                    <CardContent>
                        <Grid2 container rowSpacing={1} columnSpacing={2}>
                            <Grid2 size={6}>
                                <Select
                                    label={i18n.taxon}
                                    options={taxons}
                                    value={hydrobioList.taxon}
                                    onChange={taxon => onChange({ taxon })}
                                    keyLabel='labelWithCode'
                                    disabled={!isNil(hydrobioListProps.taxon)}
                                />
                            </Grid2>
                            <Grid2 size={3}>
                                <NumberField
                                    title={i18n.enumeration}
                                    value={hydrobioList.enumerationA}
                                    floatValue
                                    onChange={v => onChange({ enumerationA: v })}
                                />
                            </Grid2>
                            <Grid2 size={3}>
                                <Autocomplete
                                    label={i18n.unit}
                                    options={units}
                                    value={hydrobioList.unit}
                                    onChange={unit => onChange({ unit })}
                                    keyLabel='symbolWithName'
                                    keyFilter={['name', 'symbol', 'code']}
                                />
                            </Grid2>
                            <Grid2 size={3}>
                                <Select
                                    options={remarks}
                                    value={hydrobioList.remark}
                                    label={i18n.remark}
                                    keyLabel='name'
                                    keyValue='code'
                                    returnNull
                                    onChange={remark => onChange({ remark })}
                                />
                            </Grid2>
                            <Grid2 size={3}>
                                <Select
                                    label={i18n.development}
                                    value={hydrobioList.develop}
                                    options={developLexicon}
                                    keyLabel='name'
                                    keyValue='code'
                                    returnNull
                                    onChange={v => onChange({ develop: v })}
                                />
                            </Grid2>
                            <Grid2 size={3}>
                                <Select
                                    label={i18n.trust}
                                    value={hydrobioList.trust}
                                    options={trustLexicon}
                                    keyLabel='name'
                                    keyValue='code'
                                    returnNull
                                    onChange={v => onChange({ trust: v })}
                                />
                            </Grid2>
                            <Grid2 size={3}>
                                <NumberField
                                    title={i18n.density}
                                    value={hydrobioList.density}
                                    floatValue
                                    onChange={v => onChange({ density: v })}
                                />
                            </Grid2>
                            <Grid2 size={3}>
                                <Select
                                    label={i18n.size}
                                    value={hydrobioList.size}
                                    options={sizeLexicon}
                                    keyLabel='name'
                                    keyValue='code'
                                    returnNull
                                    onChange={v => onChange({ size: v })}
                                />
                            </Grid2>
                        </Grid2>
                    </CardContent>
                </Card>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => onValidate(hydrobioList)} variant='contained' color='primary'>
                    {i18n.validate}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

HydrobioFloristicDialog.propTypes = {
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
    onValidate: PropTypes.func,
    title: PropTypes.string,
    hydrobioList: PropTypes.shape({}),
}

export {
    HydrobioFaunalDialog,
    HydrobioFloristicDialog,
}