import ReferencialDto from '../../../dto/ReferencialDto'

export default class FractionItem extends ReferencialDto {
    constructor(object) {
        super(object)
        this.id = object.code
        this.code = parseInt(object.code, 10)
        this.supportCode = object.supportCode
        this.status = object.state
        this.headers = ['code', 'name', 'support', 'status']
    }
}