/* eslint-disable camelcase */
import PropTypes from 'prop-types'
import React, { useEffect, useMemo } from 'react'
import { connect, shallowEqual, useDispatch, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import Table from '../../../components/datatable/Table'
import SieauAction from '../../../components/sieau/SieauAction'
import DtoDistributionUnit from '../../../distributionUnit/dto/DtoDistributionUnit'
import DtoHydrometricStation from '../../../hydrometry/dto/DtoHydrometricStation'
import DtoInstallation from '../../../installation/dto/installation/DtoInstallation'
import DtoPiezometer from '../../../piezometry/dto/DtoPiezometer'
import DtoProductionUnit from '../../../productionUnit/dto/DtoProductionUnit'
import DtoQualitometer from '../../../quality/dto/DtoQualitometer'
import DtoSandreCode from '../../../referencial/dto/DtoSandreCode'
import { getFullDate } from '../../../utils/DateUtil'
import { getExport, setModal } from '../../../utils/linkUtils'
import { arrayOf, getLabel } from '../../../utils/StoreUtils'
import DtoParametrageDataType from '../../../piezometry/dto/DtoParametrageDataType'
import PiezometryAction from '../../../piezometry/actions/PiezometryAction'
import PluviometryAction from '../../../pluviometry/actions/PluviometryAction'
import HydrometryAction from '../../../hydrometry/actions/HydrometryAction'
import PointPrelModal from './modals/PointPrelModal'
import { genericPromise2 } from '../../../utils/ActionUtils'
import ApplicationConf from '../../../conf/ApplicationConf'
import ToastrAction from '../../../toastr/actions/ToastrAction'
import { getSandreLabel } from '../../../utils/StringUtil'
import { SANDRE } from '../../../referencial/constants/ReferencialConstants'

const pointPrelExists = (stationType, idStation, codepoint, dispatch, cb) => genericPromise2(ApplicationConf.station.pointPrelDataExists(), { method: 'POST', body: { stationType, idStation, codepoint } })
    .then(res => {
        if (!res.exists) {
            cb()
        } else {
            dispatch(ToastrAction.error('Veuillez passer sur la validation afin de supprimer les mesures associées au point de prélèvement pour pouvoir le supprimer ensuite.'))
        }
    })

const StationPointPrelPanel = ({
    station = {},
    readMode = false,
    onChange = () => {},
    setPopup = () => {},
    st = '',
    piezometryDataTypes = [],
    pluviometryDataTypes = [],
    hydrometryDataTypes = [],
}) => {
    const dispatch = useDispatch()

    const {
        sandreCodes
    } = useSelector(store => ({
        sandreCodes: store.ReferencialReducer.sandreCodes,
    }), shallowEqual)

    useEffect(() => {
        if (st === 'piezometry' && !piezometryDataTypes.length) {
            dispatch(PiezometryAction.fetchPiezometryDataTypes())
        }
        if (st === 'hydrometry' && !hydrometryDataTypes.length) {
            dispatch(HydrometryAction.fetchHydrometryDataTypes())
        }
        if (st === 'pluviometry' && !pluviometryDataTypes.length) {
            dispatch(PluviometryAction.fetchPluviometryDataTypes())
        }
    }, [st])

    const dt = useMemo(() => {
        if (st === 'piezometry') {
            return piezometryDataTypes
        }
        if (st === 'hydrometry') {
            return hydrometryDataTypes
        }
        return pluviometryDataTypes
    }, [st, piezometryDataTypes, hydrometryDataTypes, pluviometryDataTypes])
    const stationPointsPrel = station.link_pointPrels
    const pointTypes = [
        { code: 1, name: i18n.pointPrincipal },
        { code: 2, name: i18n.pointSec },
        { code: 3, name: i18n.pointInternalMonitoring },
    ]
    const tableData = stationPointsPrel.map(p => ({
        ...p,
        startDate: getFullDate(p.startDate),
        endDate: getFullDate(p.endDate),
        code: `${p.code} [${p.point}]`,
        dataType: getLabel(dt, p.typeId),
        projection: getSandreLabel(sandreCodes, SANDRE.PROJECTION, p.projection),
        pointType: getLabel(pointTypes, p.pointType),
    }))
    const headers = ['code', 'name', 'dataType', 'pointType', 'startDate', 'endDate', 'x', 'y', 'projection']
    const exportAction = getExport(tableData, i18n.samplePoint, headers)
    const actions = !readMode ? [{
        iconName: 'note_add',
        tooltip: i18n.add,
        onClick: () => setModal(i18n.newSamplePoint, <PointPrelModal id={ station.id } dt={ dt } saveResult={ r => onChange({ link_pointPrels: [...stationPointsPrel, { ...r, point: stationPointsPrel.length + 1 }] }) }/>, setPopup),
    }, exportAction] : [exportAction]

    return (
        <div id='usages'>
            <Table
                title={ i18n.samplePoint }
                condensed
                data={ tableData }
                type={{ headers }}
                sortable={ !!tableData.length }
                actions={ actions }
                alterable={ !readMode }
                onAlter={
                    (element) => setModal(i18n.newSamplePoint,
                        <PointPrelModal
                            id={ station.id }
                            dt={ dt }
                            pointprel={ { ...stationPointsPrel.find(u => element.point === u.point), isNew: false } }
                            saveResult={ r => onChange({
                                link_pointPrels: [
                                    ...stationPointsPrel.filter(p => p.point !== element.point),
                                    { ...r, point: element.point },
                                ],
                            })}
                        />
                        , setPopup)
                }
                deletable={ !readMode }
                onDelete={ (element) => {
                    pointPrelExists(st, station.id, element.point, dispatch, () => onChange({ link_pointPrels: stationPointsPrel.filter(u => u.point !== element.point) }))
                } }
            />
        </div>
    )
}

StationPointPrelPanel.propTypes = {
    station: PropTypes.oneOfType([
        PropTypes.instanceOf(DtoProductionUnit),
        PropTypes.instanceOf(DtoDistributionUnit),
        PropTypes.instanceOf(DtoQualitometer),
        PropTypes.instanceOf(DtoPiezometer),
        PropTypes.instanceOf(DtoHydrometricStation),
        PropTypes.instanceOf(DtoInstallation),
    ]),
    sandreCodes: arrayOf(DtoSandreCode),
    readMode: PropTypes.bool,
    onChange: PropTypes.func,
    setPopup: PropTypes.func,
    piezometryDataTypes: arrayOf(DtoParametrageDataType),
    pluviometryDataTypes: arrayOf(DtoParametrageDataType),
    hydrometryDataTypes: arrayOf(DtoParametrageDataType),
    st: PropTypes.string,
}

const mapStateToProps = store => ({
    piezometryDataTypes: store.PiezometryReducer.piezometryDataTypes,
    pluviometryDataTypes: store.PluviometryReducer.pluviometryDataTypes,
    hydrometryDataTypes: store.HydrometryReducer.hydrometryDataTypes,
})

const mapDispatchToProps = {
    setPopup: SieauAction.setPopup,
}

export default connect(mapStateToProps, mapDispatchToProps)(StationPointPrelPanel)