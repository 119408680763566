import React, { useState } from 'react'
import Card from '../../../components/card/Card'
import Select from '../../../components/forms/Select'
import i18n from 'simple-react-i18n'
import { getQualificationSelectOptions, getStatusSelectOptions } from '../../../utils/StatusUtil'
import PropTypes from 'prop-types'
import { arrayOf } from '../../../utils/StoreUtils'
import DtoPluviometerStats from '../../dto/DtoHydroStats'
import { orderBy } from 'lodash'


const PluvioSuiviCriterias = ({ reloadMeasures, pluviometerStatistics, filter, years }) => {
    const [tmpFilter, setTmpFilter] = useState(filter)
    const changeFilter = (changes) => setTmpFilter({ ...tmpFilter, ...changes })
    const currentDataType = tmpFilter.dataType ?? (pluviometerStatistics.some(h => h.typeId === 1) ? 1 : (pluviometerStatistics[0]?.typeId || 1))
    const groupModes = [
        { code: 'MAX', name: 'Max' },
        { code: 'MIN', name: 'Min' },
        { code: 'AVERAGE', name: i18n.average },
        { code: 'SUM', name: i18n.sum },
    ]

    return (
        <div className='row no-margin'>
            <div className='col s12 m12'>
                <div className='row no-margin'>
                    <Card>
                        <div className='row no-margin'>
                            <div className='col s2 padding-top-10-px'>
                                <Select
                                    options={ orderBy(pluviometerStatistics, ['order', 'typeId'], 'asc').map(h => ({ ...h, code: h.typeId, name: h.label })) }
                                    label={ i18n.chronicType }
                                    onChange={ v => changeFilter({ dataType: v }) }
                                    value={ currentDataType }
                                    noSort
                                />
                            </div>
                            <div className='col s2 padding-top-10-px'>
                                <Select options={ groupModes } label={ i18n.dailyRegroup } onChange={ v => changeFilter({ group: v }) }
                                    value={ tmpFilter.group }
                                />
                            </div>
                            <div className='col s2 padding-top-10-px'>
                                <Select options={ getStatusSelectOptions() } label={ i18n.status } nullLabel='&nbsp;'
                                    onChange={ v => changeFilter({ status: v }) } value={ tmpFilter.status }
                                />
                            </div>
                            <div className='col s2 padding-top-10-px'>
                                <Select options={ getQualificationSelectOptions() } label={ i18n.qualification } nullLabel='&nbsp;'
                                    onChange={ v => changeFilter({ qualification: v }) } value={ tmpFilter.qualification }
                                />
                            </div>
                            <div className='col s2 no-margin'>
                                { i18n.horizon }<br/>
                                <form className='row no-margin'>
                                    <div className='col s12 no-padding valign-wrapper'>
                                        <div className={`no-padding col s${tmpFilter.horizon === 'year' ? '7' : '12'}`}>
                                            <div className='col s12 no-padding'>
                                                <input name='horizon' type='radio' id={ 'year' } onChange={ () => changeFilter({ horizon: 'year' }) }
                                                    checked={ tmpFilter.horizon === 'year' }
                                                />
                                                <label htmlFor={ 'year' }>{ i18n.perYear }</label>
                                            </div>
                                        </div>
                                        { tmpFilter.horizon === 'year' ? (
                                            <div className='col s5'>
                                                <Select
                                                    options={ years }
                                                    onChange={ e => changeFilter({ year: e }) }
                                                    label={ i18n.year }
                                                    value={ tmpFilter.year }
                                                    noNullValue
                                                />
                                            </div>
                                        ) : null }
                                    </div>
                                    <div className='no-padding col s12'>
                                        <div className='col s12 no-padding'>
                                            <input name='horizon' type='radio' id={ 'historic' } onChange={ () => changeFilter({ horizon: 'historic' }) }
                                                checked={ tmpFilter.horizon === 'historic' }
                                            />
                                            <label htmlFor={ 'historic' }>{ i18n.perHistoric }</label>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className='col s2 no-margin padding-top-10-px'>
                                <a className='col s12 btn' onClick={ () => reloadMeasures(tmpFilter) }>
                                    { i18n.search }
                                </a>
                            </div>
                        </div>
                    </Card>
                </div>
            </div>
        </div>
    )
}

PluvioSuiviCriterias.propTypes = {
    reloadMeasures: PropTypes.func,
    pluviometerStatistics: arrayOf(DtoPluviometerStats),
    years: PropTypes.arrayOf(PropTypes.shape({
        code: PropTypes.number,
        name: PropTypes.number,
    })),
    filter: PropTypes.shape({}),
}

export default PluvioSuiviCriterias
