import { Grid } from '@mui/material'
import User from 'account/dto/User'
import { linkContributorsDepartments } from 'carto/constants/CartoConstants'
import { keys } from 'lodash'
import PropTypes from 'prop-types'
import React, { useEffect, useMemo, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import SieauParameterDto from '../../../administration/dto/SieauParameterDto'
import { hasValue } from '../../../utils/NumberUtil'
import { getSetting, getSettingJson } from '../../../utils/SettingUtils'
import HomeAction from '../../actions/HomeAction'
import DtoVigilance from '../../dto/DtoVigilance'
import VigilanceCard from './VigilanceCard'
import VigilanceModal from './VigilanceModal'

const VigilancePanel = ({
    townCode,
}) => {
    const {
        vigilances,
        applicationSettings,
        accountUser,
    } = useSelector(store => ({
        vigilances: store.HomeReducer.vigilances,
        applicationSettings: store.AdministrationReducer.applicationSettings,
        accountUser: store.AccountReducer.accountUser,
    }), shallowEqual)

    const [open, setOpen] = useState(false)
    const [vigilance, setVigilance] = useState({})

    const dispatch = useDispatch()

    const defaultDepartment = useMemo(() => {
        const contribSettings = hasValue(accountUser.contributorCode) && getSettingJson(applicationSettings, linkContributorsDepartments)?.find(s => s.contributor === accountUser.contributorCode)
        return contribSettings?.departments || getSetting(applicationSettings, 'meteoAlertDepartments')
    }, [accountUser.contributorCode, applicationSettings])

    useEffect(() => {
        const departments = townCode ? townCode.slice(0, 2) : defaultDepartment
        if (hasValue(departments) && departments.length >= 2) {
            dispatch(HomeAction.fetchVigilances(departments))
        }
    }, [defaultDepartment, dispatch, townCode])

    useEffect(() => {
        if (keys(vigilance).length) {
            setOpen(true)
        }
    }, [vigilance])

    return !!vigilances.length && (
        <Grid container className='white' sx={{ borderRadius: '2px', lineHeight: '1.5rem' }}>
            {vigilances.filter(v => v.texts[0].lang === 'fr-FR').map(vg => (
                <>
                    <a onClick={() => setVigilance(vg)} target='_blank' style={{ cursor: 'pointer', width: '100%' }}>
                        <VigilanceCard vigilance={vg} smallCard />
                    </a>
                </>
            ))}
            <VigilanceModal
                open={open}
                vigilance={vigilance}
                closePopin={() => {
                    setOpen(false)
                    setVigilance({})
                }}
            />
        </Grid>
    )
}

VigilancePanel.propTypes = {
    townCode: PropTypes.string,
    vigilances: PropTypes.arrayOf(PropTypes.instanceOf(DtoVigilance)),
    applicationSettings: PropTypes.arrayOf(PropTypes.instanceOf(SieauParameterDto)),
    accountUser: PropTypes.instanceOf(User),
    fetchVigilances: PropTypes.func,
    setPopup: PropTypes.func,
}

export default VigilancePanel
