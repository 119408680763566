import { push } from '@lagunovsky/redux-react-router'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import { H_HYDRO_OPTIONS } from '../../../account/constants/AccessRulesConstants'
import ActionComponent from '../../../components/ActionComponent'
import HomeAction from '../../../home/actions/HomeAction'
import StationAction from '../../../station/actions/StationAction'
import DtoDataType from '../../../station/dto/DtoDataType'
import { componentHasHabilitations } from '../../../utils/HabilitationUtil'
import { getSetting, getSettingBool, getSettingInt } from '../../../utils/SettingUtils'
import AdministrationAction from '../../actions/AdministrationAction'
import SieauParameterDto from '../../dto/SieauParameterDto'
import DataTypesTable from '../dataTypes/DataTypesTable'
import ShowAssociatedDataRuleTable from '../dataTypes/ShowAssociatedDataRuleTable'
import Select from '../../../components/forms/Select'
import Card from '../../../components/card/Card'
import ContributorAction from '../../../referencial/components/contributor/actions/ContributorAction'
import { arrayOf, getLabel } from '../../../utils/StoreUtils'
import ContributorDto from '../../../referencial/components/contributor/dto/ContributorDto'
import Button from '../../../components/forms/Button'
import FrequencyStatCalculationMethodPopup from '../piezometer/FrequencyStatCalculationMethodPopup'
import Checkbox from '../../../components/forms/Checkbox'

class AdminHydrometricStationApp extends ActionComponent {
    constructor(props) {
        super(props)
        this.state = {
            readMode: true,
            dataLoaded: false,
            project: 'SIH',
            applicationSettings: props.applicationSettings,
            frequencyCalculationPopupIsOpen: false,
        }
    }

    componentDidMount() {
        if (!componentHasHabilitations(H_HYDRO_OPTIONS)) { // A modifier quand react-router sera à jour
            this.props.push('/unauthorized')
            return
        }
        if (!this.props.contributors.length) {
            this.props.fetchContributors()
        }
        this.setReadMode()
        this.props.setTitle([{
            title: i18n.hydrometry,
            href: 'hydrometry',
        }, {
            title: i18n.hydrometryOptions,
            href: 'hydrometry/hydrometryOptions',
        }])
        this.setDataTypes()
    }

    setDataTypes = () => {
        const { project } = this.state
        this.props.fetchDataTypesByProject(project).then(() => {
            const { filteredDataTypes } = this.props
            this.setState({ dataLoaded: true, dataTypes: filteredDataTypes })
        })
    }

    setReadMode = () => {
        this.setState({ readMode: true })
        this.setActions({
            edit: () => this.setEditMode(),
        })
    }

    setEditMode = () => {
        this.setState({ readMode: false })
        this.setActions({
            save: () => this.onSave(),
            cancel: () => this.onCancel(),
        })
    }

    onCancel = () => {
        const { filteredDataTypes, applicationSettings } = this.props
        this.setState({ dataTypes: filteredDataTypes, applicationSettings })
        this.setReadMode()
    }

    onSave = () => {
        const { dataTypes, project } = this.state
        this.setState({ dataLoaded: false })
        this.props.updateDataTypesByProject(project, dataTypes).then(() => {
            this.setDataTypes()
        })
        const applicationSettings = this.state.applicationSettings.filter(e => e.value !== getSetting(this.props.applicationSettings, e.parameter))
        if (applicationSettings.length) {
            this.props.updateSieauParameters(applicationSettings)
        }
        this.setReadMode()
    }

    onChangeSetting = (setting, value) => {
        const stateObj = this.state.applicationSettings.filter(e => e.parameter !== setting)
        this.setState({ applicationSettings: [...stateObj, { parameter: setting, value: value ? value.toString() : value }] })
    }

    render() {
        const { readMode, dataLoaded, dataTypes, project, applicationSettings } = this.state
        const frequencyCalculationMethods = [
            { code: 'normal', label: 'Loi normale' },
            { code: 'trunc', label: 'Loi normale tronquée' },
            { code: 'percent', label: 'Pourcentage par rapport à la moyenne' },
            { code: 'noLaw', label: 'Sans loi statistique' },
        ]

        return (
            <div className='padding-1'>
                <Card title={ i18n.hydrometryOptions }>
                    <div className='row no-margin padding-top-1 padding-left-1'>
                        <h5>Méthode de calcul des périodes de retour</h5>
                    </div>
                    <div className='row no-margin padding-top-1 padding-left-1'>
                        <p>Cette méthode sera utilisée pour calculer les périodes de retour à la fois sur les modules
                            Piézométrie et Hydrométrie, pour les affichages sur les graphiques et les calculs des
                            classes dans les cartes de situation.</p>
                    </div>
                    <div className='row no-margin padding-top-1 padding-left-1 valign-wrapper'>
                        <div className='col s3'>
                            <Button title='Changer de méthode de calcul' icon='edit'
                                onClick={ () => this.setState({ frequencyCalculationPopupIsOpen: true }) }
                                disabled={ readMode }
                            />
                        </div>
                        <div className='col s9'>
                            <h6>Méthode actuelle
                                : { getLabel(frequencyCalculationMethods, getSetting(applicationSettings, 'FrequencyStatMode') ?? 'normal') }</h6>
                        </div>
                    </div>
                    <div className='row no-margin padding-top-1 padding-left-1'>
                        <Checkbox col={ 5 } label='Activer les exports HYDROPORTAIL' disabled={ readMode }
                            onChange={ v => this.onChangeSetting('HYDROPORTAILisActive', v.toString()) }
                            checked={ getSettingBool(applicationSettings, 'HYDROPORTAILisActive') ?? true }
                        />
                    </div>
                    <div className='row no-margin padding-top-1 padding-left-1'>
                        <h5>{ i18n.validationScreenDefaultValues }</h5>
                    </div>
                    <div className='row no-margin padding-top-1'>
                        <Select
                            disabled={ readMode }
                            col={ 4 }
                            label={ i18n.producer }
                            options={ this.props.contributors }
                            value={ getSettingInt(applicationSettings, 'validationDefaultProducerHydro') }
                            onChange={ v => this.onChangeSetting('validationDefaultProducerHydro', v) }
                            keyValue='id'
                            keyLabel='mnemonique'
                        />
                        <Select
                            disabled={ readMode }
                            col={ 4 }
                            label={ i18n.administrator }
                            options={ this.props.contributors }
                            value={ getSettingInt(applicationSettings, 'validationDefaultManagerHydro') }
                            onChange={ v => this.onChangeSetting('validationDefaultManagerHydro', v) }
                            keyValue='id'
                            keyLabel='mnemonique'
                        />
                        <Select
                            disabled={ readMode }
                            col={ 4 }
                            label={ i18n.validator }
                            options={ this.props.contributors }
                            value={ getSetting(applicationSettings, 'validationDefaultValidatorHydro') }
                            onChange={ v => this.onChangeSetting('validationDefaultValidatorHydro', v) }
                            keyValue='id'
                            keyLabel='mnemonique'
                        />
                    </div>
                </Card>
                { dataLoaded && (
                    <div className='padding-top-1'>
                        <DataTypesTable
                            project={project}
                            dataTypes={dataTypes}
                            readMode={readMode}
                            onChange={(data) => this.setState({ dataTypes: data })}
                        />
                    </div>
                )}
                <div className='padding-top-1' />
                {dataLoaded && (
                    <ShowAssociatedDataRuleTable
                        currentSettings={applicationSettings}
                        fromStationType='HYDRO'
                        toStationType='PIEZO'
                        onChangeRules={ rules => this.onChangeSetting('showDataTypesRules', rules) }
                        readMode={readMode}
                    />
                )}
                <div className='padding-top-1' />
                {dataLoaded && (
                    <ShowAssociatedDataRuleTable
                        currentSettings={applicationSettings}
                        fromStationType='HYDRO'
                        toStationType='PLUVIO'
                        onChangeRules={ rules => this.onChangeSetting('showDataTypesRules', rules) }
                        readMode={readMode}
                    />
                )}
                <div className='padding-top-9' />
                <FrequencyStatCalculationMethodPopup
                    selectedMode={getSetting(applicationSettings, 'FrequencyStatMode') ?? 'normal'}
                    changeMode={mode => this.onChangeSetting('FrequencyStatMode', mode)}
                    onClose={() => this.setState({ frequencyCalculationPopupIsOpen: false })}
                    isOpen={this.state.frequencyCalculationPopupIsOpen}
                />
            </div>
        )
    }
}

AdminHydrometricStationApp.propTypes = {
    applicationSettings: PropTypes.arrayOf(PropTypes.instanceOf(SieauParameterDto)),
    filteredDataTypes: PropTypes.arrayOf(PropTypes.instanceOf(DtoDataType)),
    setTitle: PropTypes.func,
    contributors: arrayOf(ContributorDto),
    updateDataTypesByProject: PropTypes.func,
    fetchContributors: PropTypes.func,
    fetchDataTypesByProject: PropTypes.func,
    push: PropTypes.func,
    updateSieauParameters: PropTypes.func,
}

const mapStateToProps = (store) => ({
    applicationSettings: store.AdministrationReducer.applicationSettings,
    contributors: store.ContributorReducer.contributors,
    filteredDataTypes: store.StationReducer.filteredDataTypes,
})

const mapDispatchToProps = {
    setTitle: HomeAction.setTitle,
    fetchContributors: ContributorAction.fetchContributors,
    updateDataTypesByProject: StationAction.updateDataTypesByProject,
    fetchDataTypesByProject: StationAction.fetchDataTypesByProject,
    updateSieauParameters: AdministrationAction.updateSieauParameters,
    push,
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminHydrometricStationApp)