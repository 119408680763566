import React from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import i18n from 'simple-react-i18n'
import Card from '../../../components/card/Card'
import Row from '../../../components/react/Row'
import normalIMG from '../../../assets/pictures/admin/PERIOD_normal.png'
import truncIMG from '../../../assets/pictures/admin/PERIOD_trunc.png'
import noLawIMG from '../../../assets/pictures/admin/PERIOD_noLaw.png'
import percentIMG from '../../../assets/pictures/admin/PERIOD_percent.png'
import ToastrAction from '../../../toastr/actions/ToastrAction'
import { sieauTooltip } from '../../../utils/FormUtils'

const FrequencyStatCalculationMethodPopup = ({
    selectedMode,
    changeMode,
    onClose,
    isOpen,
}) => {
    if (!isOpen) {
        return null
    }
    const getAction = (mode) => [{
        iconName: mode === selectedMode ? 'check_box' : 'check_box_outline_blank',
        onClick: mode === selectedMode ? () => {} : () => changeMode(mode),
    }]
    return (
        <Dialog
            onClose={onClose}
            fullWidth
            maxWidth='lg'
            open={isOpen}
        >
            <DialogTitle>Modifier la méthode de calcul des périodes de retour</DialogTitle>
            <DialogContent>
                <>
                    <Card title='Loi normale' actions={ getAction('normal') }>
                        <Row className='padding-left-2'>
                            <h6>La loi normale est utilisée dans le calcul des périodes de retour. C'est la méthode de calcul la plus utilisée par le BRGM et les DREAL a l'heure actuelle. <br/><br/>Avec cette méthode, du fait de certains biais statistiques liés à la loi normale, il est possible que certaines périodes de retour dépassent l'enveloppe min/max. <br/>Ces biais statistiques sont causés par le fait que la distribution des niveaux ne suit pas tout à fait une loi normale. C'est le cas dans l'exemple ci dessous avec la cinquantennale sèche qui dépasse l'enveloppe min.</h6>
                        </Row>
                        <Row className='padding-left-2'>
                            <img src={normalIMG} />
                        </Row>
                    </Card>
                    <div className='padding-top-2'/>
                    <Card title='Loi normale tronquée' actions={ getAction('trunc') }>
                        <Row className='padding-left-2'>
                            <h6>La loi normale est utilisée dans le calul des périodes de retour. Par contre, si un résultat dépasse l'enveloppe min/max, le résultat sera l'enveloppe min/max dépassée.</h6>
                        </Row>
                        <Row className='padding-left-2'>
                            <img src={truncIMG} />
                        </Row>
                    </Card>
                    <div className='padding-top-2'/>
                    <Card title='Pourcentage par rapport à la moyenne' actions={ getAction('percent') }>
                        <Row className='padding-left-2'>
                            <h6>Pour ce calcul, on se sert de la moyenne et du min ou max historique. En fonction de la période de retour, un coefficient est appliqué. <br/><br/>Par exemple pour la triennale humide, on a un coefficient de 0.333. On applique la formule suivante (avec avg la moyenne, max l'enveloppe max) : avg + (max - avg) x (1 - 0.333)². <br/>Pour une triennale sèche, on aura : avg - (avg - min) x (1 - 0.333)². <br/><br/>Cette méthode permet d'avoir des classes bien réparties pour chaque période de retour, avec la promesse de ne pas dépasser l'enveloppe min/max. <br/> Cependant, elle ne calcule pas de réelles périodes de retour mais plutôt des calculs statistiques qui s'en rapprochent.</h6>
                        </Row>
                        <Row className='padding-left-2'>
                            <img src={percentIMG} />
                        </Row>
                    </Card>
                    <div className='padding-top-2'/>
                    <Card title='Sans loi statistique' actions={ getAction('noLaw') }>
                        <Row className='padding-left-2'>
                            <h6>Aucune loi statistique est utilisée dans le calul des périodes de retour. Les résultats sont des vraies valeurs qui sont dans l'historique de la courbe. <br/><br/>Avec cette méthode, c'est impossible que certaines périodes de retour dépassent l'enveloppe min/max. <br/><br/>Par contre, il est possible qu'une même valeur se retrouve dans plusieures périodes de retour à la fois, si par exemple elle est présente un nombre de fois assez important dans l'historique. (On le voit dans l'exemple ci dessous au niveau de la flèche rouge)</h6>
                        </Row>
                        <Row className='padding-left-2'>
                            <img src={noLawIMG} />
                        </Row>
                    </Card>
                </>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} variant='contained' color='primary'>
                    {i18n.close}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default FrequencyStatCalculationMethodPopup
