import ApplicationConf from 'conf/ApplicationConf'
import { push } from '@lagunovsky/redux-react-router'
import fetch from 'isomorphic-fetch'
import i18n from 'simple-react-i18n'
import ToastrAction from 'toastr/actions/ToastrAction'
import LogAction from '../../../../log/actions/LogAction'
import {
    checkAuth,
    checkAuthWithoutKicking,
    checkError,
    getAuthorization,
    getJson,
} from '../../../../utils/ActionUtils'
import { setMessageModal } from '../../../../utils/FormUtils'
import { hasValue } from '../../../../utils/NumberUtil'
import {
    RECEIVE_ALL_SENSORS, RECEIVE_ALL_SENSOR_SITUATIONS, RECEIVE_SENSOR, RECEIVE_SENSORS_LAST_SITUATIONS, RECEIVE_SENSOR_FILES,
    RECEIVE_SENSOR_PICTURES,
    RECEIVE_SENSOR_TYPES, RESET_SENSOR,
} from '../constants/SensorConstants'

const SensorAction = {
    receiveSensors: sensors => ({ type: RECEIVE_ALL_SENSORS, sensors }),

    promiseSensors: withEquipment => fetch(ApplicationConf.materiel.allSensors(withEquipment), {
        method: 'GET',
        headers: getAuthorization(),
    })
        .then(checkAuth)
        .then(getJson)
        .then(checkError),

    fetchSensors: withEquipment => dispatch => SensorAction.promiseSensors(withEquipment)
        .then((json = []) => {
            dispatch(SensorAction.receiveSensors(json))
            if (json.length) {
                dispatch(SensorAction.fetchSensorsLastSituations())
            }
        }),

    receiveSensor(sensor) {
        return { type: RECEIVE_SENSOR, sensor }
    },

    promiseSensor(id) {
        return fetch(ApplicationConf.materiel.sensor(id), {
            headers: getAuthorization(),
        })
            .then(checkAuthWithoutKicking)
            .then(getJson)
            .then(checkError)
    },

    fetchSensor: id => dispatch => {
        return SensorAction.promiseSensor(id)
            .then((json = []) => {
                dispatch(SensorAction.receiveSensor(json))
                return json
            })
            .catch(e => {
                dispatch(LogAction.logError(`${i18n.fetchError + i18n.sensor} : ${e}`))
                dispatch(ToastrAction.error(i18n.fetchError + i18n.sensor))
            })
    },

    receiveSensorTypes(sensorTypes) {
        return { type: RECEIVE_SENSOR_TYPES, sensorTypes }
    },

    promiseSensorTypes(withEquipmentType) {
        return fetch(ApplicationConf.materiel.sensorAllTypes(withEquipmentType), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
    },

    fetchSensorTypes(withEquipmentType) {
        return (dispatch) => {
            return SensorAction.promiseSensorTypes(withEquipmentType)
                .then((json = {}) => {
                    dispatch(SensorAction.receiveSensorTypes(json))
                })
        }
    },

    updateSensorTypes(id, materielType) {
        return (dispatch) => {
            return fetch(ApplicationConf.materiel.sensorType(id), {
                method: 'PUT',
                headers: getAuthorization(),
                body: JSON.stringify(materielType),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(() => {
                    dispatch(SensorAction.fetchSensorTypes(true))
                    dispatch(ToastrAction.success(i18n.saveSuccess))
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.updateError + i18n.materielType} : ${err}`))
                    dispatch(ToastrAction.error(i18n.updateError + i18n.materielType))
                })
        }
    },
    deleteSensorType(id, callback = () => { }) {
        return (dispatch) => {
            return fetch(ApplicationConf.materiel.sensorType(id), {
                method: 'DELETE',
                headers: getAuthorization(),
            })
                .then(r => checkAuth(r, {
                    206: () => setMessageModal(i18n.deletingUsedMaterielTypeMessage),
                }))
                .then(getJson)
                .then(checkError)
                .then(json => {
                    if (json.delete >= 1) {
                        dispatch(SensorAction.fetchSensorTypes(true))
                        dispatch(ToastrAction.success(i18n.elementDeleteSuccess))
                        callback()
                    } else {
                        throw new Error()
                    }
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.deleteError + i18n.materielType} : ${err}`))
                    dispatch(ToastrAction.error(i18n.deleteError + i18n.materielType))
                })
        }
    },
    addSensorTypes(materielType, callback = () => { }) {
        return (dispatch) => {
            return fetch(ApplicationConf.materiel.sensorTypes(), {
                method: 'POST',
                headers: getAuthorization(),
                body: JSON.stringify(materielType),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(json => {
                    if (json.insert === 1 && json.id) {
                        dispatch(SensorAction.fetchSensorTypes(true))
                        dispatch(ToastrAction.success(i18n.addSuccess))
                        callback({ ...materielType, materielType: json.id })
                    } else {
                        throw new Error()
                    }
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.createError + i18n.materielType} : ${err}`))
                    dispatch(ToastrAction.error(i18n.createError + i18n.materielType))
                })
        }
    },

    updateSensor(sensor) {
        return (dispatch) => {
            return fetch(ApplicationConf.materiel.sensor(sensor.id), {
                method: 'PUT',
                headers: getAuthorization(),
                body: JSON.stringify(sensor),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(json => {
                    if (json.update === 1) {
                        dispatch(SensorAction.fetchSensors(true))
                        dispatch(ToastrAction.success(i18n.elementUpdateSuccess))
                        dispatch(SensorAction.fetchSensor(sensor.id))
                        return true
                    }
                    throw new Error()
                })
                .catch(err => {
                    const label = i18n.updateError + (err.message === '409 Conflict' ? i18n.immoNumberOrSerialNumberAlreadyUse : i18n.sensor)
                    dispatch(LogAction.logError(`${label} ${err}`))
                    dispatch(ToastrAction.error(label))
                })
        }
    },

    createSensor(sensor) {
        return (dispatch) => {
            return fetch(ApplicationConf.materiel.sensors(), {
                method: 'POST',
                headers: getAuthorization(),
                body: JSON.stringify(sensor),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(json => {
                    if (json.insert === 1 && hasValue(json.id)) {
                        dispatch(SensorAction.fetchSensors(true))
                        dispatch(push(`/materiel/sensor/${json.id}`))
                        dispatch(ToastrAction.success(i18n.elementCreateSuccess))
                        return json.id
                    }
                    throw new Error()
                })
                .catch(err => {
                    const label = i18n.updateError + (err.message === '409 Conflict' ? i18n.immoNumberOrSerialNumberAlreadyUse : i18n.sensor)
                    dispatch(LogAction.logError(`${label} ${err}`))
                    dispatch(ToastrAction.error(label))
                })
        }
    },

    deleteSensor(id) {
        return (dispatch) => {
            return fetch(ApplicationConf.materiel.sensor(id), {
                method: 'DELETE',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(json => {
                    if (json.delete > 0) {
                        dispatch(SensorAction.fetchSensors(true))
                        dispatch(push('/materiel/sensor'))
                        dispatch(ToastrAction.success(i18n.elementDeleteSuccess))
                    } else {
                        throw new Error()
                    }
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.deleteError + i18n.sensor} : ${err}`))
                    dispatch(ToastrAction.error(i18n.deleteError + i18n.sensor))
                })
        }
    },

    receiveSensorSituations(sensorSituations) {
        return { type: RECEIVE_ALL_SENSOR_SITUATIONS, sensorSituations }
    },

    fetchSensorSituations(id) {
        return (dispatch) => {
            return fetch(ApplicationConf.materiel.sensorSituations(id), {
                method: 'GET',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(json => {
                    dispatch(SensorAction.receiveSensorSituations(json))
                })
        }
    },

    saveSensorSituation(situation, shouldFetch = true) {
        return (dispatch) => {
            return fetch(ApplicationConf.materiel.sensorsSituations(), {
                method: 'POST',
                headers: getAuthorization(),
                body: JSON.stringify(situation),
            })
                .then(() => {
                    dispatch(SensorAction.fetchSensorsLastSituations())
                    dispatch(ToastrAction.success(i18n.situationAddSuccess))
                    if (shouldFetch) {
                        dispatch(SensorAction.fetchSensorSituations(situation.idSensor))
                    }
                })
        }
    },

    deleteSensorSituation(id, idSensor) {
        return (dispatch) => {
            return fetch(ApplicationConf.materiel.sensorSituation(id), {
                method: 'DELETE',
                headers: getAuthorization(),
            })
                .then(() => {
                    dispatch(SensorAction.fetchSensorsLastSituations())
                    dispatch(ToastrAction.success(i18n.situationDeleteSuccess))
                    dispatch(SensorAction.fetchSensorSituations(idSensor))
                })
        }
    },
    updateSensorSituation(idSituation, situation, openCampaginModal = undefined) {
        return (dispatch) => {
            return fetch(ApplicationConf.materiel.sensorSituation(idSituation), {
                method: 'PUT',
                headers: getAuthorization(),
                body: JSON.stringify(situation),
            })
                .then(() => {
                    dispatch(SensorAction.fetchSensorsLastSituations())
                    dispatch(ToastrAction.success(i18n.saveSuccess))
                    if (openCampaginModal) {
                        openCampaginModal(situation)
                    }
                })
        }
    },
    createEventAndUpdateSituation(urlEvent, event, situation, openCampaginModal = undefined) {
        return (dispatch) => {
            return fetch(urlEvent, {
                method: 'POST',
                headers: getAuthorization(),
                body: JSON.stringify(event),
            }).then(getJson)
                .then((json) => {
                    if (json.eventId) {
                        const result = Object.assign({}, situation, {
                            eventCode: json.eventId,
                        })
                        dispatch(SensorAction.updateSensorSituation(result.id, result))
                        if (openCampaginModal) {
                            dispatch(openCampaginModal(result))
                        }
                    } else {
                        dispatch(ToastrAction.error(i18n.saveError))
                    }
                })
        }
    },

    promiseSensorSituation(id) {
        return fetch(ApplicationConf.materiel.sensorSituations(id), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
    },

    receiveSensorLastSituations(sensorsLastSituations) {
        return { type: RECEIVE_SENSORS_LAST_SITUATIONS, sensorsLastSituations }
    },

    promiseSensorLastSituation: () => fetch(ApplicationConf.materiel.sensorsLastSituation(), {
        method: 'GET',
        headers: getAuthorization(),
    })
        .then(checkAuth)
        .then(getJson)
        .then(checkError),

    fetchSensorsLastSituations: () => dispatch => SensorAction.promiseSensorLastSituation()
        .then((json = []) => {
            dispatch(SensorAction.receiveSensorLastSituations(json))
        }),

    receiveSensorPictures(sensorPictures) {
        return {
            type: RECEIVE_SENSOR_PICTURES,
            sensorPictures,
        }
    },

    fetchSensorPictures(id) {
        return (dispatch) => {
            return fetch(ApplicationConf.materiel.sensorPicture(id), {
                method: 'GET',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then((json = []) => {
                    dispatch(SensorAction.receiveSensorPictures(json))
                })
                .catch((err) => {
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.pictures} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError + i18n.pictures))
                })
        }
    },

    receiveSensorFiles(sensorFiles) {
        return {
            type: RECEIVE_SENSOR_FILES,
            sensorFiles,
        }
    },

    fetchSensorFiles(id) {
        return (dispatch) => {
            return fetch(ApplicationConf.materiel.sensorFile(id), {
                method: 'GET',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then((json = []) => {
                    dispatch(SensorAction.receiveSensorFiles(json))
                })
                .catch((err) => {
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.files} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError + i18n.files))
                })
        }
    },

    resetSensor() {
        return {
            type: RESET_SENSOR,
        }
    },
}

export default SensorAction
