import React, { Component } from 'react'
import PropTypes from 'prop-types'
import FixedSlideNav from '../../../../components/navbar/FixedSlideNav'
import { links } from '../../../../quality/constants/StationsConstants'
import { DASHBOARD } from '../../../constants/InstallationConstants'
import { Outlet } from 'react-router-dom'
import HomeAction from 'home/actions/HomeAction'
import BoundaryError from 'log/components/BoundaryError'
import { connect } from 'react-redux'

class InstallationsApp extends Component {
    componentDidMount() {
        this.props.setHelpLink('', '')
    }

    getLink = (linkName) => {
        if (links[linkName].href) {
            return links[linkName]
        }
        return {
            ...links[linkName],
            href: `/installation/${linkName}`,
        }
    }

    render() {
        const navBarLinks = [DASHBOARD, 'territoryActivities', 'campaign', 'installationOptions', 'counter'].map(linkName => this.getLink(linkName))

        return (
            <div className='row no-margin'>
                <FixedSlideNav links={navBarLinks}/>
                <main>
                    <div className='row no-margin padding-left-1'>
                        <div className='content-page no-padding fullscreen'>
                            <BoundaryError>
                                <Outlet />
                            </BoundaryError>
                        </div>
                    </div>
                </main>
            </div>
        )
    }
}

InstallationsApp.defaultProps = {
    getLink: ('', ''),
}


InstallationsApp.propTypes = {
    children: PropTypes.element,
    getLink: PropTypes.func,
    setHelpLink: PropTypes.func,
}

const mapDispatchToProps = {
    setHelpLink: HomeAction.setHelpLink,
}

export default connect(null, mapDispatchToProps)(InstallationsApp)
