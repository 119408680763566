const getCurrentLang = () => {
    const currentLang = (navigator.language || navigator.userLanguage).match(/([A-z]){2}/g)
    if (currentLang.length == 2) {
        return currentLang
    }
    return ['fr', 'FR']
}

export { getCurrentLang }

