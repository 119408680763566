import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import Input from '../../../../components/forms/Input'
import NumberField from '../../../../components/forms/NumberField'
import Select from '../../../../components/forms/Select'
import Textarea from '../../../../components/forms/Textarea'
import Row from '../../../../components/react/Row'
import DtoAltimetrySystem from '../../../../piezometry/dto/DtoAltimetrySystem'
import DtoLandmark from '../../../../piezometry/dto/DtoLandmark'
import { SANDRE } from '../../../../referencial/constants/ReferencialConstants'
import DtoSandreCode from '../../../../referencial/dto/DtoSandreCode'
import { getDate, getHour } from '../../../../utils/DateUtil'
import { onChangeHour } from '../../../../utils/FormUtils'
import { hasValue } from '../../../../utils/NumberUtil'
import { getSandreList } from '../../../../utils/StoreUtils'
import { getSandreLabel } from '../../../../utils/StringUtil'
import SimpleDatePicker from 'components/forms/SimpleDatePicker'

const LandmarkModal = ({
    altimetrySystems = [],
    landmark = {},
    sandreCodes = [],
    onChange = () => { },
}) => {
    const altimetrySystemsFiltered = altimetrySystems.filter(as => hasValue(as.natureCode) && hasValue(as.startDate))
        .map(as => ({
            value: `${as.natureCode}:${as.startDate}`,
            label: `${getSandreLabel(sandreCodes, SANDRE.ALTIMETRIC_SYSTEM_NATURE, as.natureCode)} : ${getDate(as.startDate)}`,
        }))
    const selectedAS = hasValue(landmark.altimetrySystemNature) && hasValue(landmark.altimetrySystemDate) ? `${landmark.altimetrySystemNature}:${landmark.altimetrySystemDate}` : null
    const altimetrySystem = altimetrySystems.find(d => d.startDate === landmark.altimetrySystemDate)
    return (
        <div style={{ minHeight: '55vh' }}>
            <Row>
                <SimpleDatePicker
                    col={6}
                    value={landmark.startDate}
                    label={i18n.startDate}
                    onChange={v => onChange({ startDate: v })}
                    max={landmark.endDate}
                />
                <Input col={ 6 } title={ i18n.startHour } value={ getHour(landmark.startDate) }
                    onChange={ v => onChangeHour(v, v2 => onChange({ startDate: v2 }), { max: landmark.endDate }, landmark.startDate) }
                />
            </Row>
            <Row>
                <SimpleDatePicker
                    col={6}
                    value={landmark.endDate}
                    label={i18n.endDate}
                    onChange={v => onChange({ endDate: v })}
                    min={landmark.startDate}
                />
                <Input col={ 6 } title={ i18n.endHour } value={ getHour(landmark.endDate) }
                    onChange={ v => onChangeHour(v, v2 => onChange({ endDate: v2 }), { min: landmark.startDate }, landmark.endDate) }
                />
            </Row>
            <div className='row no-margin padding-top-1'>
                <Select
                    col={6}
                    options={getSandreList(sandreCodes, SANDRE.ORIGINE_REPERE)}
                    label={i18n.origin}
                    value={landmark.origin}
                    onChange={v => onChange({ origin: v })}
                />
            </div>
            <div className='row no-margin padding-top-1'>
                <Select
                    col={6}
                    label={i18n.altimetricSystem}
                    options={altimetrySystemsFiltered}
                    value={selectedAS}
                    onChange={v => {
                        if (hasValue(v) && v != -1 && v.split(':').length === 2) {
                            const result = v.split(':')
                            onChange({ altimetrySystemNature: parseInt(result[0]), altimetrySystemDate: parseInt(result[1]) })
                        } else {
                            onChange({ altimetrySystemNature: undefined, altimetrySystemDate: undefined,
                            })
                        }
                    }}
                    obligatory
                />
                <NumberField
                    col={3}
                    value={landmark.height}
                    title={i18n.height}
                    onChange={v => onChange({ height: v })}
                    floatValue
                    obligatory
                />
                <NumberField
                    col={3}
                    value={landmark.height + (altimetrySystem && altimetrySystem.altitude || 0)}
                    title={i18n.pressmark}
                    floatValue
                    disabled
                />
            </div>
            <div className='row no-margin padding-top-1'>
                <Textarea
                    col={12}
                    title={i18n.comment}
                    value={landmark.comment}
                    onChange={v => onChange({ comment: v })}
                />
            </div>
        </div>
    )
}

LandmarkModal.propTypes = {
    landmark: PropTypes.instanceOf(DtoLandmark),
    altimetrySystems: PropTypes.arrayOf(PropTypes.instanceOf(DtoAltimetrySystem)),
    onChange: PropTypes.func,
    sandreCodes: PropTypes.arrayOf(PropTypes.instanceOf(DtoSandreCode)),
}


const mapStateToProps = store => ({
    sandreCodes: store.ReferencialReducer.sandreCodes,
})

export default connect(mapStateToProps)(LandmarkModal)
