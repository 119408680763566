export default class LayerThemeDto {
    constructor(obj = {}) {
        this.id = obj.id
        this.name = obj.name
        this.typeLayer = obj.typeLayer
        this.url = obj.url
        this.layer = obj.layer
        this.theme = obj.theme
        this.source = obj.source
        this.projection = obj.projection
    }
}
