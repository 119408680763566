import { hasValue } from 'utils/NumberUtil'
import ReferencialDto from '../../../dto/ReferencialDto'

export default class ParameterDto extends ReferencialDto {
    constructor(object) {
        super(object)
        this.name = object.name
        this.creationDate = object.creationDate
        this.updateDate = object.updateDate
        this.author = object.author
        this.shortLabel = object.shortLabel
        this.shortLabelWithCode = `${object.shortLabel || object.name} ${object.code ? `[${object.code}]` : ''}${object.internalCode ? ` [${object.internalCode}]` : ''}`
        this.displayLabel = `${object.shortLabel || object.name} ${object.code ? `[${object.code}]` : ''}`
        this.displayName = object.shortLabel || object.name || (hasValue(object.code) && `<${object.code}>`) || ''
        this.longLabel = object.longLabel
        this.definition = object.definition
        this.reference = object.reference
        this.comment = object.comment
        this.internationalName = object.internationalName
        this.calculate = object.calculate
        this.nature = object.nature
        this.typeParam = object.typeParam
        this.chemicalSubstance = object.chemicalSubstance
        this.active = object.active
        this.unitReference1 = object.unitReference1
        this.unitReference2 = object.unitReference2
        this.unitReference3 = object.unitReference3
        this.unitReference4 = object.unitReference4
        this.unitReference5 = object.unitReference5
        this.unitReference6 = object.unitReference6
        this.defaultSupportCode = object.defaultSupportCode
        this.fractionCode = object.fractionCode
        this.siseCode = object.siseCode
        this.computedParameterCode = object.computedParameterCode
        this.casCode = object.casCode
        this.internalCode = object.internalCode
        this.banked = object.banked
        this.ld = object.ld
        this.lq = object.lq
        this.commentVulgarized = object.commentVulgarized
    }
}