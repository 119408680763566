import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import { ButtonGroup, Grid } from '@mui/material'
import ProgressCard from 'components/card/ProgressCard'
import Table from 'components/datatable/Table'
import { groupBy, range, toLower, uniqBy } from 'lodash'
import { getDate, getMonthYear, getWeekYear, getYear } from 'utils/DateUtil'
import Select from 'components/forms/Select'
import { getI18nTitleDataLength, geti18n } from 'utils/StringUtil'
import { nbPerPageLabelShort } from 'referencial/constants/ReferencialConstants'
import { ButtonMUI } from 'components/styled/Buttons'
import ProductionUnitAction from 'productionUnit/actions/ProductionUnitAction'
import { AccordionDetailsMUI, AccordionMUI, AccordionSummaryMUI } from 'components/styled/Accordions'
import { exportFile } from 'utils/ExportDataUtil'
import Icon from 'components/icon/Icon'
import useLocalStorage from 'utils/customHook/useLocalStorage'
import { getLocalStorageJson } from 'utils/FormUtils'

const OCCURENCE_TYPE = {
    DAY: { value: 'DAY', defaultValue: 7, nbValue: 30 },
    WEEK: { value: 'WEEK', defaultValue: 8, nbValue: 52 },
    MONTH: { value: 'MONTH', defaultValue: 12, nbValue: 24 },
    YEAR: { value: 'YEAR', defaultValue: 5, nbValue: 20 },
}

const ProductionUnitLinkedPiezo = () => {
    const dispatch = useDispatch()
    const [dataLoaded, setDataLoaded] = useState(false)
    const [occurence, setOccurence] = useState(OCCURENCE_TYPE.DAY)
    const [nbOccurence, setNbOccurence] = useState(OCCURENCE_TYPE.DAY.defaultValue)

    const buttonsOccurence = [
        { value: 'DAY', title: i18n.day, borderWidth: '2px 0px 2px 2px' },
        { value: 'WEEK', title: i18n.week, borderWidth: 2 },
        { value: 'MONTH', title: i18n.month, borderWidth: '2px 1px' },
        { value: 'YEAR', title: i18n.year, borderWidth: '2px 2px 2px 1px' },
    ]

    const [dashboardDisplay, setDashboardDisplay] = useLocalStorage('DASHBOARD_DISPLAY', {})

    const formatDate = useCallback(date => {
        switch (occurence.value) {
            case 'WEEK':
                return getWeekYear(date)
            case 'MONTH':
                return getMonthYear(date)
            case 'YEAR':
                return getYear(date)
            default:
                return getDate(date)
        }
    }, [occurence])

    const {
        linkedPiezoPrel,
        associatedSites,
    } = useSelector(store => ({
        linkedPiezoPrel: store.ProductionUnitReducer.linkedPiezoPrel,
        associatedSites: store.StationReducer.associatedSites,
    }), shallowEqual)

    const piezoIds = useMemo(() => {
        return associatedSites.filter(as => as.stationLinkedType === 1).map(s => s.stationLinkedId)
    }, [associatedSites])

    useEffect(() => {
        if (piezoIds.length && occurence && nbOccurence) {
            setDataLoaded(false)
            dispatch(ProductionUnitAction.fetchLinkedPiezoPrel({ ids: piezoIds, occurence: occurence.value, nbOccurence })).then(() => setDataLoaded(true))
        }
    }, [piezoIds, occurence, nbOccurence, dispatch])

    const getPiezoName = useCallback(({ idPiezometer, code, designation, name }) => {
        const idCode = code ? `${code}${designation ? `/${designation}` : ''}` : idPiezometer
        return `${idCode} ${name ? `- ${name}` : ''}`
    }, [])

    const headers = useMemo(() => {
        const addedPiezos = uniqBy(linkedPiezoPrel, 'idPiezometer').map(dt => getPiezoName(dt))
        return ['date', ...addedPiezos, 'sum']
    }, [getPiezoName, linkedPiezoPrel])

    const data = useMemo(() => {
        const grouped = groupBy(linkedPiezoPrel, 'measureDate')
        return Object.keys(grouped).map((date, index) => {
            const line = grouped[date]
            const others = line.filter(l => l.dataType !== -1).reduce((acc, l) => {
                return { ...acc, [getPiezoName(l)]: l.value, sum: acc.sum + l.value }
            }, { sum: 0 })
            return {
                date: formatDate(line[0].measureDate),
                ...others,
                headers: index === 0 ? headers : [],
            }
        })
    }, [linkedPiezoPrel, formatDate, getPiezoName, headers])

    const selectValues = useMemo(() => {
        const labelKey = toLower(occurence.value)
        return range(occurence.nbValue).map(index => ({ label: `${index + 1} ${getI18nTitleDataLength(geti18n(labelKey), geti18n(`${labelKey}s`), index + 1)}`, id: index + 1 }))
    }, [occurence])

    if (!piezoIds.length) {
        return null
    }

    return (
        <AccordionMUI
            round
            style={{ marginTop: 10 }}
            expanded={dashboardDisplay?.PROD?.PREL}
            onChange={(_, v) => {
                const actualDashboardDisplay = getLocalStorageJson('DASHBOARD_DISPLAY')
                setDashboardDisplay({
                    ...actualDashboardDisplay,
                    PROD: {
                        ...actualDashboardDisplay?.PROD,
                        PREL: v,
                    },
                })
            }}
        >
            <AccordionSummaryMUI iconColor='black' style={{ background: 'white', color: 'black' }}>
                <Grid container justifyContent='space-between'>
                    <Grid item >{i18n.production}</Grid>
                    <Grid item ><Icon icon='file_download' color='black' onClick={() => {
                        exportFile({
                            data,
                            exportType: 'xlsx',
                            titleFile: i18n.production,
                        })
                    }}
                    /></Grid>
                </Grid>
            </AccordionSummaryMUI>
            <AccordionDetailsMUI>
                <Grid container className='padding-1'>
                    <Grid container item xs={12}>
                        <Grid item xs={6}>
                            <ButtonGroup>
                                {
                                    buttonsOccurence.map((o) => (
                                        <ButtonMUI
                                            variant={occurence.value === o.value ? 'contained' : 'outlined'}
                                            onClick={() => {
                                                setOccurence(OCCURENCE_TYPE[o.value])
                                                setNbOccurence(OCCURENCE_TYPE[o.value].defaultValue)
                                            }}
                                            style={{ border: 'solid rgba(53, 96, 159, 1)', borderWidth: o.borderWidth, fontWeight: 600 }}
                                        >{o.title}</ButtonMUI>
                                    ))
                                }
                            </ButtonGroup>
                        </Grid>
                        <Grid item xs={6}>
                            <Select
                                value={nbOccurence}
                                label={i18n.occurency}
                                options={selectValues}
                                noSort
                                onChange={setNbOccurence}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        {
                            dataLoaded ? data.length && (
                                <Table
                                    col={12}
                                    data={data}
                                    type={{ headers }}
                                    sortable condensed paging
                                    showTitle={false}
                                    nbPerPageLabel={nbPerPageLabelShort}
                                    noSort
                                />
                            ) || undefined : <ProgressCard indeterminate className='padding-left-2 padding-right-1 padding-top-1' />
                        }
                    </Grid>
                </Grid>
            </AccordionDetailsMUI>
        </AccordionMUI >
    )
}

ProductionUnitLinkedPiezo.propTypes = {
}

export default ProductionUnitLinkedPiezo