import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import Input from '../../../components/forms/Input'
import Select from '../../../components/forms/Select'
import SimpleDatePicker from '../../../components/forms/SimpleDatePicker'
import CityDto from '../../../referencial/components/city/dto/CityDto'
import ContactItem from '../../../referencial/components/contact/dto/ContactItem'

class ContactLightPanel extends Component {
    constructor(props) {
        super(props)
        this.state = {
            contact: props.contact,
        }
    }

    onChangeValue = (obj) => {
        const { contact } = this.props
        this.props.onChange({
            ...contact,
            ...obj,
        })
    }

    onChangeValueCP = (value) => {
        const { cities } = this.props
        const citiesFiltered = cities.filter((c) => c.link_postalCode.find((cp) => cp.startsWith(value)))
        if (citiesFiltered.length === 1) {
            const cityCode = citiesFiltered[0].id
            this.onChangeValue({ postalBox: value, cityCode })
        } else {
            this.onChangeValue({ postalBox: value })
        }
    }

    onChangeValueCity = (value) => {
        const { citiesIndex } = this.props
        const city = citiesIndex[value] || {}
        if (city.link_postalCode && city.link_postalCode.length) {
            this.onChangeValue({ postalBox: city.link_postalCode[0], cityCode: value })
        } else {
            this.onChangeValue({ cityCode: value })
        }
    }

    render = () => {
        const { cities, contact, disabled } = this.props

        return (
            <div className='row no-margin' style={{ minHeight: '25vh' }}>
                <div className='row no-margin padding-top-1'>
                    <Input
                        col={6}
                        title={i18n.name}
                        value={contact.name}
                        onChange={value => this.onChangeValue({ name: value?.length ? value : undefined })}
                        disabled={disabled}
                    />
                    <Input
                        col={6}
                        title={i18n.phoneTel}
                        value={contact.phoneTel}
                        onChange={value => this.onChangeValue({ phoneTel: value?.length ? value : undefined })}
                        disabled={disabled}
                    />
                    <Input
                        col={6}
                        title={i18n.address}
                        value={contact.address}
                        onChange={value => this.onChangeValue({ address: value?.length ? value : undefined })}
                        disabled={disabled}
                    />
                    <Input
                        col={6}
                        title={i18n.mobile}
                        value={contact.mobile}
                        onChange={value => this.onChangeValue({ mobile: value?.length ? value : undefined })}
                        disabled={disabled}
                    />
                    <Input
                        col={6}
                        title={i18n.additionnalAddress}
                        value={contact.additionalAddress}
                        onChange={value => this.onChangeValue({ additionalAddress: value?.length ? value : undefined })}
                        disabled={disabled}
                    />
                    <Input
                        col={6}
                        title={i18n.fax}
                        value={contact.desktopTel}
                        onChange={value => this.onChangeValue({ desktopTel: value?.length ? value : undefined })}
                        disabled={disabled}
                    />
                    <Input
                        col={2}
                        title={i18n.postalCode}
                        value={contact.postalBox}
                        onChange={value => this.onChangeValueCP(value?.length ? value : undefined)}
                        disabled={disabled}
                    />
                    <Select
                        col={4}
                        options={cities}
                        label={i18n.city}
                        displayWithCode
                        onChange={city => this.onChangeValueCity(city)}
                        value={contact.cityCode}
                        disabled={disabled}
                    />
                    <Input
                        col={6}
                        title={i18n.mail}
                        value={contact.email}
                        onChange={value => this.onChangeValue({ email: value?.length ? value : undefined })}
                        disabled={disabled}
                    />
                    <SimpleDatePicker
                        col={6}
                        label={i18n.birthdate}
                        value={contact.birthdate}
                        onChange={value => this.onChangeValue({ birthdate: value || undefined })}
                        calendarPosition='top'
                        disabled={disabled}
                    />
                </div>
            </div>
        )
    }
}

ContactLightPanel.propTypes = {
    disabled: PropTypes.bool,
    contact: PropTypes.instanceOf(ContactItem),
    onChange: PropTypes.func,
    citiesIndex: PropTypes.shape({}),
    cities: PropTypes.arrayOf(PropTypes.instanceOf(CityDto)),
}

const mapStateToProps = store => ({
    citiesIndex: store.CityReducer.citiesIndex,
    cities: store.CityReducer.cities,
})

export default connect(mapStateToProps)(ContactLightPanel)