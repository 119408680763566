import { groupBy, isUndefined, maxBy, minBy, orderBy } from 'lodash'
import moment from 'moment'
import { MEASURE_COTE } from 'piezometry/constants/PiezometryConstants'
import { STATION_TYPE_NAME } from 'station/constants/StationConstants'
import { getMeasureValue } from 'utils/PiezometryUtils'
import { getDate, getDateWithHour, getMonthList } from '../../utils/DateUtil'
import { hasValue, round } from '../../utils/NumberUtil'
import DtoMeasure from '../dto/alert/DtoMeasure'

const getLastMeasuresFormat = (id, lastMeasuresList, type) => {
    if (lastMeasuresList && lastMeasuresList.length && type === 'piezometry') {
        const measure = lastMeasuresList.find(lastMeasure => lastMeasure.piezometerId === id)
        if (measure) {
            const output = new DtoMeasure({
                id: getDateWithHour(measure.lastMeasureDate, measure.lastMeasureHour).valueOf(),
                measureDate: getDateWithHour(measure.lastMeasureDate, measure.lastMeasureHour).valueOf(),
                value: measure.ngf,
                unit: measure.unit,
            })
            return [output]
        }
    } else if (lastMeasuresList && lastMeasuresList.length && ['pluviometry', 'hydrometry'].includes(type)) {
        const measure = lastMeasuresList.find(lastMeasure => lastMeasure.pluvioId === id || lastMeasure.hydroId === id || lastMeasure.id === id)
        if (measure) {
            return [measure]
        }
    }
    return []
}

const getAllLastMeasuresFormat = (id, lastMeasuresList, type, dataTypes, lastLandmark, groundRefAlti) => {
    if (lastMeasuresList && lastMeasuresList.length && type === STATION_TYPE_NAME.piezometry) {
        const measures = lastMeasuresList.filter(lastMeasure => lastMeasure.piezoId === id)
        return measures.map(m => {
            const dataType = dataTypes.find(d => d.id === m.dataType) || {}
            return {
                ...new DtoMeasure({
                    id,
                    measureDate: m.initialCreationDate && getDateWithHour(m.initialCreationDate, m.initialHour).valueOf(),
                    value: round(getMeasureValue({ NGF: m.initialNGF, ...m }, MEASURE_COTE.DEPTH, lastLandmark, groundRefAlti), 3),
                }),
                dataType: m.dataType,
                unit: dataType.unit || '',
                title: dataType.label || '',
            }
        })
    } else if (lastMeasuresList && lastMeasuresList.length && [STATION_TYPE_NAME.pluviometry, STATION_TYPE_NAME.hydrometry].includes(type)) {
        const measures = lastMeasuresList.filter(lastMeasure => lastMeasure.pluvioId === id || lastMeasure.hydroId === id || lastMeasure.id === id)
        return measures.map(m => {
            const dataType = dataTypes.find(d => d.id === m.dataType) || {}
            return {
                ...m,
                dataType: m.dataType,
                unit: dataType.unit || '',
                title: dataType.label || '',
            }
        })
    }
    return []
}

const getStationMeasuresChart = (listAllMeasure, beginDate, endDate, key) => {
    if (listAllMeasure && listAllMeasure.length) {
        const startDate = beginDate ? beginDate : minBy(listAllMeasure, m => m.date).date
        const filteredMeasures = listAllMeasure.filter(m => m.date >= startDate && hasValue(m[key]))
        if (startDate > moment(endDate).subtract('3', 'month').valueOf()) {
            const measures = filteredMeasures.map(m => ({ value: [m.date, round(m[key])] }))
            return orderBy(measures, m => m.value[0])
        }
        const group = groupBy(filteredMeasures, m => getDate(m.date))
        const groupedMeasures = Object.keys(group).map(g => ({
            value: [moment(group[g][0].date).hour(0).minute(0).second(0).valueOf(), round(maxBy(group[g], m => m[key])[key])],
        }))
        return orderBy(groupedMeasures, m => m.value[0])
    }
    return []
}

const getLastMeasuresPiezoConverted = (id, lastMeasuresList, lastLandmark, groundRefAlti) => {
    if (lastMeasuresList && lastMeasuresList.length) {
        const measure = lastMeasuresList.find(lastMeasure => lastMeasure.piezometerId === id)
        if (measure) {
            const output = new DtoMeasure({
                id: getDateWithHour(measure.lastMeasureDate, measure.lastMeasureHour).valueOf(),
                measureDate: getDateWithHour(measure.lastMeasureDate, measure.lastMeasureHour).valueOf(),
                value: round(getMeasureValue({ NGF: measure.ngf, ...measure }, MEASURE_COTE.DEPTH, lastLandmark, groundRefAlti), 3),
            })
            return [output]
        }
    }
    return []
}

const getMeasureArrayValues = (arr = []) => arr.map(o => o.value)

const getThresholdValue = (arr = []) => arr.map(({ value, isOverrunThreshold }) => ({ value, isOverrunThreshold }))
const getSign = (threshold) => threshold.isOverrunThreshold === '1' ? '>' : '<'

const getThresholdFormat = (threshold, dataType) => {
    const sign = getSign(threshold)
    const monthCurrent = moment().month() + 1
    const monthCorrespondent = `month${monthCurrent.toString().padStart(2, '0')}`
    const monthCorrespondentLabel = getMonthList().find(m => m.value === monthCurrent).name
    const value = !isUndefined(threshold.value) ? `${threshold.value} ${dataType.unit}` : `${threshold[monthCorrespondent]} ${dataType.unit} (${monthCorrespondentLabel})`
    return {
        ...threshold,
        title: `${threshold.title} (${dataType.label} ${sign} ${value})`,
    }
}

export {
    getLastMeasuresFormat,
    getAllLastMeasuresFormat,
    getStationMeasuresChart,
    getMeasureArrayValues,
    getThresholdValue,
    getLastMeasuresPiezoConverted,
    getSign,
    getThresholdFormat,
}
