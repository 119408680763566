import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Fancybox as NativeFancybox } from '@fancyapps/ui/dist/fancybox.esm.js'
import '@fancyapps/ui/dist/fancybox.css'

function Fancybox(props) {
    const delegate = props.delegate || '[data-fancybox]'

    useEffect(() => {
        const opts = props.options || {}

        NativeFancybox.bind(delegate, opts)

        return () => {
            NativeFancybox.destroy()
        }
    }, [])

    return <>{props.children}</>
}

export default Fancybox

Fancybox.propTypes = {
    delegate: PropTypes.string,
    options: PropTypes.shape({}),
    children: PropTypes.element,
}
