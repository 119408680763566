/* eslint-disable camelcase */
import DtoCaptureCivilEng from './DtoCaptureCivilEng'
import DtoCaptureEquipments from './DtoCaptureEquipments'

export default class DtoCaptureStation {
    constructor(obj) {
        this.idStation = obj.idStation
        this.prodSite = obj.prodSite
        this.aacCode = obj.aacCode
        this.sampleOrigin = obj.sampleOrigin
        this.qMoyJ = obj.qMoyJ
        this.waterNature = obj.waterNature
        this.hydroEntityCode = obj.hydroEntityCode

        this.link_civilEngs = obj.link_civilEngs ? obj.link_civilEngs.map(ce => new DtoCaptureCivilEng(ce)) : []
        this.link_equipments = obj.link_equipments ? obj.link_equipments.map(e => new DtoCaptureEquipments(e)) : []
    }
}