export default class DtoVisitModification {
    constructor(obj) {
        this.idInstallation = obj.idInstallation // Long,
        this.idCampaign = obj.idCampaign // Long,
        this.idVisit = obj.idVisit // Option[Long],
        this.field = obj.field // Option[Long],
        this.previousValue = obj.previousValue // Option[Long],
        this.newValue = obj.newValue // Option[Long],
        this.updateLogin = obj.updateLogin // Option[String],
        this.updateDate = obj.updateDate // Option[DateTime]
    }
}
