import React from 'react'
import PropTypes from 'prop-types'
import SimpleTabSideList from './SimpleTabSideList'
import { v4 as uuidv4 } from 'uuid'

const TabsSideBar = ({
    tabs = [],
    onChangeTab = () => {},
    openByDefault = false,
    containerStyle = {},
}) => {
    const tabsList = tabs.map(t => ({ ...t, id: t.id || uuidv4() }))

    const tabsFormatted = tabsList.map(({ title, icon, id }) => ({
        icon,
        constant: id,
        label: title,
    }))

    return (
        <SimpleTabSideList
            position='right'
            defaultTab={tabsList[0]?.id || 0}
            tabs={tabsFormatted}
            isOpenByDefault={openByDefault}
            onChangeTab={onChangeTab}
            containerStyle={containerStyle}
        >
            {tabId => (tabsList.find(({ id }) => id === tabId)?.content)}

        </SimpleTabSideList>
    )
}

TabsSideBar.propTypes = {
    tabs: PropTypes.arrayOf(PropTypes.shape({
        icon: PropTypes.string,
        title: PropTypes.string,
        content: PropTypes.element,
    })),
    onChangeTab: PropTypes.func,
    loadOnClick: PropTypes.bool,
    openByDefault: PropTypes.bool,
    containerStyle: PropTypes.shape({}),
}

export default TabsSideBar