export default class DtoHydroMeasureLight {
    constructor(obj) {
        this.date = obj[0] // DateTime
        this.value = obj[1] // Option[Double]
        this.codepoint = obj[2] // Option[Double]
        this.status = obj[3] // Option[Int]
        this.qualification = obj[4] // Option[Int]
        this.initialPoint = obj[5] // Option[Int]
        this.hydroId = obj[6] // Option[Int]
    }
}