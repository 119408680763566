import { push } from '@lagunovsky/redux-react-router'
import { isEqual, omit, template } from 'lodash'
import { v4 as uuidv4 } from 'uuid'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import ToastrAction from 'toastr/actions/ToastrAction'
import ActionComponent from '../../../../components/ActionComponent'
import Card from '../../../../components/card/Card'
import Input from '../../../../components/forms/Input'
import Select from '../../../../components/forms/Select'
import SieauAction from '../../../../components/sieau/SieauAction'
import HomeAction from '../../../../home/actions/HomeAction'
import { PATH_REFERENCIAL } from '../../../../home/constants/RouteConstants'
import { updateMaterialize } from '../../../../utils/MaterializeUtils'
import { getUser } from '../../../../utils/SettingUtils'
import { getSandreList } from '../../../../utils/StoreUtils'
import ReferencialAction from '../../../action/ReferencialAction'
import { SANDRE } from '../../../constants/ReferencialConstants'
import DtoSandreCode from '../../../dto/DtoSandreCode'
import ActivityAction from '../actions/ActivityAction'
import DtoActivity from '../dto/DtoActivity'
import { hasValue } from 'utils/NumberUtil'
import ApplicationConf from 'conf/ApplicationConf'
import DeleteModal from 'referencial/components/DeleteModal'
import logoInsee from '../../../../assets/pictures/logo-insee.png'
import { inseeActivityClassLink, inseeActivityDivisionLink, inseeActivityGroupLink, inseeActivitySectionLink, inseeActivitySubClassLink } from 'conf/SieauConstants'

class ActivityApp extends ActionComponent {
    constructor(props) {
        super(props)
        this.state = {
            isEditMode: props.isEditMode,
            activity: { ...this.props.activity },
        }
    }

    componentDidMount() {
        const { params, sandreCodes } = this.props
        if (params.code !== 'new') {
            this.changeEditMode(false)
            this.props.fetchActivity(params.code)
        } else {
            this.setState({ activity: {} })
            this.changeEditMode(true)
        }
        if (!sandreCodes.length) {
            this.props.fetchSandreCodes()
        }
        this.props.setHelpLink('', '')
        this.toNavbarActivity()
    }

    changeStateAttribute = (value) => {
        this.setState(({ activity }) => ({
            activity: {
                ...activity,
                ...value,
            },
        }))
    }

    componentWillUnmount = () => this.props.resetActivity()

    changeEditMode = (bool) => {
        this.setState({ isEditMode: bool })
    }

    getHrefLinkByCode = (code) => {
        if (code) {
            const codeWithoutFirstLetters = code.substring(1, code.length)
            const [codeBeforePoint, codeAfterPoint] = code.split('.')
            const codeValidBeforePoint = codeBeforePoint.replace(/[^0-9.]+/g, '').length === 2 && codeBeforePoint.replace(/[^a-zA-Z]+/g, '').length === 1
            if (code.length === 1 && code.match(/[a-zA-Z]+/g)) {
                // If code = 1 letter
                return template(inseeActivitySectionLink)({ codeActivity: code })
            } else if (code.length === 3 && code.replace(/[^0-9.]+/g, '').length === 2 && code.replace(/[^a-zA-Z]+/g, '').length === 1) {
                // If code = 1 Letter + 2 digits
                return template(inseeActivityDivisionLink)({ codeActivity: codeWithoutFirstLetters })
            } else if (codeValidBeforePoint && codeAfterPoint.replace(/[^0-9.]+/g, '').length === 1) {
                // If code = 1 Letter + 2 digits + "." + 1 digit
                return template(inseeActivityGroupLink)({ codeActivity: codeWithoutFirstLetters })
            } else if (codeValidBeforePoint && codeAfterPoint.replace(/[^0-9.]+/g, '').length === 2 && codeAfterPoint.replace(/[^a-zA-Z]+/g, '').length === 1) {
                // If code = 1 Letter + 2 digits + "." + 2 digits + 1 letter
                return template(inseeActivitySubClassLink)({ codeActivity: codeWithoutFirstLetters })
            } else if (codeValidBeforePoint && codeAfterPoint.replace(/[^0-9.]+/g, '').length === 2) {
                // If code = 1 Letter + 2 digits + "." + 2 digits
                return template(inseeActivityClassLink)({ codeActivity: codeWithoutFirstLetters })
            }
        }
        return ''
    }

    getActivityLink = (activity)=> {
        const link = this.getHrefLinkByCode(activity.codeactivite)
        return [
            !!link && {
                href: link,
                img: logoInsee,
                label: i18n.sandre.toUpperCase(),
            },
        ].filter(l => l)
    }

    toNavbarActivity() {
        const { isEditMode, activity } = this.state
        const { params, activities } = this.props

        const actions = (() => {
            if (params.code === 'new') {
                return {
                    save: () => {
                        if (hasValue(activity.codeactivite)) {
                            const existCode = activities.find(f => f.codeactivite === activity.codeactivite)
                            if (existCode) {
                                this.props.toastrError(i18n.codeAlreadyExists)
                            } else if (!activity.codeactivite) {
                                this.props.toastrError(i18n.thanksToEnterCode)
                            } else {
                                this.changeEditMode(false)
                                this.props.createActivity(activity)
                            }
                        } else {
                            this.props.toastrError(i18n.pleaseCompleteAllRequiredField)
                        }
                    },
                    cancel: () => {
                        this.props.push('/referencial/activity')
                        this.changeEditMode(false)
                    },
                }
            }
            if (isEditMode) {
                return {
                    save: () => {
                        if (hasValue(activity.codeactivite)) {
                            this.props.updateActivity(activity, params.code)
                            this.changeEditMode(false)
                        } else {
                            this.props.toastrError(i18n.pleaseCompleteAllRequiredField)
                        }
                    },
                    cancel: () => {
                        this.setState({ activity: this.props.activity })
                        this.changeEditMode(false)
                    },
                    deleteCheck: () => {
                        this.props.fetchCheckDelete(ApplicationConf.referencial.activityCountDelete(params.code))
                        const id = uuidv4()
                        const popup = {
                            id,
                            content: <DeleteModal
                                onDelete={() => {
                                    this.props.deleteActivity(params.code)
                                    this.setState({ activity: undefined })
                                    this.changeEditMode(false)
                                    this.props.push('/referencial/activity')
                                }}
                            />,
                            dismissible: false,
                        }
                        this.props.setPopup(popup)
                    },
                }
            }
            const links = this.getActivityLink(activity)
            return {
                edit: () => {
                    this.changeEditMode(true)
                },
                links,
            }
        })()
        if (getUser().consultant === '1') {
            this.setActions(omit(actions, ['save', 'edit', 'deleteCheck', 'replace']))
        } else {
            this.setActions(actions)
        }
        updateMaterialize()
    }

    componentDidUpdate = prevProps => {
        const { params } = this.props
        const { activity } = this.props
        const { isEditMode } = this.state
        if (!isEqual(prevProps.activity, activity)) {
            this.setState({ activity })
        }
        if (!isEqual(isEditMode, prevProps.isEditMode)) {
            this.toNavbarActivity()
        }
        if (prevProps.params.code !== params.code) {
            this.props.fetchActivity(params.code)
        } else if (params.code === 'new') {
            this.props.setTitle([
                {
                    title: i18n.referencials,
                    href: PATH_REFERENCIAL,
                },
                {
                    title: i18n.activities,
                    href: '/referencial/activity',
                },
                {
                    title: i18n.new,
                    href: '/referencial/activity/new',
                },
            ])
        } else if (!isEqual(prevProps.activity, activity)) {
            this.props.setTitle([
                {
                    title: i18n.referencials,
                    href: PATH_REFERENCIAL,
                },
                {
                    title: i18n.activities,
                    href: '/referencial/activity',
                },
                {
                    title: params.code + (activity.name ? ` - ${activity.name}` : ''),
                    href: `/referencial/activity/${params.code}`,
                },
            ])
            this.setState({ activity })
            this.toNavbarActivity()
        }
    }

    render() {
        const { isEditMode, activity } = this.state
        const { sandreCodes } = this.props
        const disabled = {
            active: isEditMode,
            disabled: !isEditMode,
        }

        return (
            <div className='row no-margin padding-1'>
                <Card title={i18n.description} maxWidth={1000}>
                    <div className='padding-1'>
                        <div className='row no-margin padding-top-3-px'>
                            <Input
                                col={6}
                                title={i18n.code}
                                value={activity.codeactivite}
                                onChange={value => this.changeStateAttribute({ codeactivite: value })}
                                maxLength={25}
                                id='new_code_activity'
                                obligatory
                                data-cy='code_activity'
                                {...disabled}
                            />
                            <Input
                                col={6}
                                title={i18n.codeV2}
                                value={activity.codeactivitev2}
                                onChange={value => this.changeStateAttribute({ codeactivitev2: value })}
                                maxLength={25}
                                data-cy='code_v2_activity'
                                {...disabled}
                            />
                        </div>
                        <div className='row no-margin padding-top-3-px'>
                            <Input
                                col={6}
                                title={i18n.label}
                                value={activity.libelle}
                                onChange={value => this.changeStateAttribute({ libelle: value })}
                                maxLength={255}
                                data-cy='libelle_activity'
                                {...disabled}
                            />
                        </div>
                        <div className='row no-margin padding-top-3-px'>
                            <Select
                                col={6}
                                options={getSandreList(sandreCodes, SANDRE.ACTIVITES_CATEGORIE)}
                                value={activity.categorieactivite}
                                label={i18n.categoryActivity}
                                keyValue='code'
                                returnNull
                                onChange={ v => this.changeStateAttribute({ categorieactivite: v })}
                                data-cy='category_activity'
                                {...disabled}
                            />
                            <Input
                                col={6}
                                title={i18n.section}
                                value={activity.section}
                                onChange={value => this.changeStateAttribute({ section: value })}
                                maxLength={5}
                                data-cy='section_activity'
                                {...disabled}
                            />
                        </div>
                    </div>
                </Card>
            </div>
        )
    }
}

ActivityApp.propTypes = {
    params: PropTypes.shape({
        id: PropTypes.string,
    }),
    activity: PropTypes.instanceOf(DtoActivity),
    sandreCodes: PropTypes.arrayOf(PropTypes.instanceOf(DtoSandreCode)),
    changeEditMode: PropTypes.func,
    fetchSandreCodes: PropTypes.func,
    isEditMode: PropTypes.bool,
    setHelpLink: PropTypes.func,
}

const mapStateToProps = store => ({
    activity: store.ActivityReducer.activity,
    activities: store.ActivityReducer.activities,
    sandreCodes: store.ReferencialReducer.sandreCodes,
})

const mapDispatchToProps = {
    push,
    toastrError: ToastrAction.error,
    fetchSandreCodes: ReferencialAction.fetchSandreCodes,
    setPopup: SieauAction.setPopup,
    resetActivity: ActivityAction.reset,
    deleteActivity: ActivityAction.deleteActivity,
    createActivity: ActivityAction.createActivity,
    updateActivity: ActivityAction.updateActivity,
    fetchActivity: ActivityAction.fetchActivity,
    fetchCheckDelete: ReferencialAction.fetchCheckDelete,
    setTitle: HomeAction.setTitle,
    setHelpLink: HomeAction.setHelpLink,
}


export default connect(mapStateToProps, mapDispatchToProps)(ActivityApp)
