import fetch from 'isomorphic-fetch'
import ApplicationConf from 'conf/ApplicationConf'
import { checkAuth, getJson } from 'utils/ActionUtils'
import { getAuthorization } from '../../utils/ActionUtils'

const RefJobAction = {
    fetchJobsRows() {
        return fetch(ApplicationConf.job.getAll(), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
    },
    fetchJob(id) {
        return fetch(ApplicationConf.job.get(id), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
    },
    fetchJobExecutions(jobId, limit) {
        return fetch(ApplicationConf.job.getExecutions(jobId, limit), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
    },
}

export default RefJobAction
