import FixedSlideNav from 'components/navbar/FixedSlideNav'
import BoundaryError from 'log/components/BoundaryError'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Outlet } from 'react-router-dom'
import i18n from 'simple-react-i18n'
import {
    H_CAMPAIGN_PLUVIO_DASHBOARD,
    H_CAMPAIGN_PLUVIO_TRACKING,
} from '../../../account/constants/AccessRulesConstants'
import CampaignAction from '../../../campaign/actions/CampaignAction'
import CityAction from '../../../referencial/components/city/actions/CityAction'
import { componentHasHabilitations } from '../../../utils/HabilitationUtil'
import PluviometryAction from '../../actions/PluviometryAction'

class PluviometryCampaignApp extends Component {
    getLinks = () => this.props.params.id !== 'new' ? [
        {
            href: `/pluviometry/campaign/${this.props.params.id}/dashboard`,
            icons: 'dashboard',
            name: i18n.dashboard,
            habilitation: H_CAMPAIGN_PLUVIO_DASHBOARD,
        },
        {
            href: `/pluviometry/campaign/${this.props.params.id}/tracking`,
            icons: 'insert_drive_file',
            name: i18n.tracking,
            habilitation: H_CAMPAIGN_PLUVIO_TRACKING,
        },
    ] : [{
        href: `/pluviometry/campaign/${this.props.params.id}/dashboard`,
        icons: 'dashboard',
        name: i18n.dashboard,
        habilitation: H_CAMPAIGN_PLUVIO_DASHBOARD,
    }].filter(l => componentHasHabilitations(l.habilitation))

    componentDidMount = () => {
        const { id } = this.props.params
        if (id !== 'new' && id !== 'duplicate') {
            this.props.fetchCampaign('pluviometry', id)
            this.props.fetchCampaignStation('pluviometry', id)
            this.props.fetchCampaignProgress('pluviometry', id)
        }
        this.props.fetchPluviometers()
        this.props.fetchCities()
    }

    componentWillUnmount = () => this.props.resetCampaign()

    render = () => (
        <div className='row no-margin'>
            <FixedSlideNav links={this.getLinks()} />
            <main>
                <div className='row no-margin'>
                    <div className='content-page no-padding fullscreen'>
                        <BoundaryError>
                            <Outlet />
                        </BoundaryError>
                    </div>
                </div>
            </main>
        </div>
    )
}

PluviometryCampaignApp.propTypes = {
    children: PropTypes.element,
    params: PropTypes.shape({
        id: PropTypes.string,
    }),

    resetCampaign: PropTypes.func,
    fetchCampaign: PropTypes.func,
    fetchCampaignStation: PropTypes.func,
    fetchCampaignProgress: PropTypes.func,
    fetchPluviometers: PropTypes.func,
    fetchCities: PropTypes.func,
}

const mapDispatchToProps = {
    resetCampaign: CampaignAction.resetCampaign,
    fetchCampaign: CampaignAction.fetchCampaign,
    fetchCampaignStation: CampaignAction.fetchCampaignStation,
    fetchCampaignProgress: CampaignAction.fetchCampaignProgress,
    fetchPluviometers: PluviometryAction.fetchPluviometers,
    fetchCities: CityAction.fetchCities,
}

export default connect(null, mapDispatchToProps)(PluviometryCampaignApp)
