import React from 'react'
import PropTypes from 'prop-types'
import { Tooltip } from '@mui/material'

const TooltipMUI = ({
    title = '',
    sx = {},
    placement = 'top',
    arrow = true,
    children,
}) => (
    <Tooltip
        placement={placement}
        arrow={arrow}
        PopperProps={{
            disablePortal: true,
            sx: {
                '& .MuiTooltip-tooltip': {
                    fontSize: '12px',
                    ...sx,
                },
            },
        }}
        title={title}
    >
        {children}
    </Tooltip>
)

TooltipMUI.propTypes = {
    title: PropTypes.string,
    sx: PropTypes.shape({}),
    placement: PropTypes.string,
    arrow: PropTypes.bool,
    children: PropTypes.element,
}

export default TooltipMUI
