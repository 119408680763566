import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import NumberField from '../../../../../components/forms/NumberField'
import Select from '../../../../../components/forms/Select'
import Textarea from '../../../../../components/forms/Textarea'
import Row from '../../../../../components/react/Row'
import { SANDRE } from '../../../../../referencial/constants/ReferencialConstants'
import DtoSandreCode from '../../../../../referencial/dto/DtoSandreCode'
import { getSandreList } from '../../../../../utils/StoreUtils'
import DtoBuilding from '../../../../dto/installation/DtoBuilding'

class BuildingModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            station: { ...props.station, idInstallation: props.id },
        }
    }

    componentDidUpdate(prevProps) {
        if (!prevProps.popupIsOpen && this.props.popupIsOpen) {
            $('#descriptionModal').on('click', this.onSave)
        }
    }
    onSave = () => {
        this.props.saveResult(new DtoBuilding(this.state.station))
        $('.modal').modal('close')
    }

    onChangeElement = (changes) => this.setState({ station: { ...this.state.station, ...changes } })

    render() {
        const { station } = this.state

        return (
            <div className='row no-margin padding-top-1'>
                <Row>
                    <Select
                        col={6}
                        label={i18n.type}
                        options={getSandreList(this.props.sandreCodes, SANDRE.INSTALLATIONS_BATIMENT)}
                        value={station.buildingType}
                        onChange={v => this.onChangeElement({ buildingType: v })}
                    />
                    <NumberField
                        col={6}
                        value={station.buildingArea}
                        title={i18n.area}
                        onChange={v => this.onChangeElement({ buildingArea: v })}
                        floatValue
                    />
                </Row>
                <Row className='padding-top-1'>
                    <Textarea col={12} title={i18n.comment} value={station.comment} onChange={v => this.onChangeElement({ comment: v })} />
                </Row>
            </div>
        )
    }
}

BuildingModal.propTypes = {
    station: PropTypes.instanceOf(DtoBuilding),
    sandreCodes: PropTypes.arrayOf(PropTypes.instanceOf(DtoSandreCode)),
    id: PropTypes.number,
    popupIsOpen: PropTypes.bool,
    saveResult: PropTypes.func,
}


const mapStateToProps = store => ({
    popupIsOpen: store.SieauReducer.popupIsOpen,
    sandreCodes: store.ReferencialReducer.sandreCodes,
})

BuildingModal.defaultProps = {
    station: {},
}

export default connect(mapStateToProps)(BuildingModal)
