import PropTypes from 'prop-types'
import React from 'react'
import i18n from 'simple-react-i18n'
import EChart from '../../../components/echart/EChart'
import Pie from '../../../components/echart/series/Pie'
import DtoSurvey from '../../dto/DtoSurvey'

const ProgressSurveyChart = ({
    survey,
}) => {
    const { link_declarations: declarations = [] } = survey

    return declarations.length && (
        <div className='row no-margin'>
            <EChart options={{
                series: [
                    Pie({
                        data: [
                            {
                                value: declarations.filter(dec => dec.statusCode === 4).length,
                                itemStyle: {
                                    normal: {
                                        color: 'blue',
                                    },
                                },
                                tooltip: {
                                    trigger: 'item',
                                    formatter: `${i18n.validated} : {c} ({d}%)`,
                                },
                            },
                            {
                                value: declarations.filter(dec => dec.statusCode === 3).length,
                                itemStyle: {
                                    normal: {
                                        color: 'green',
                                    },
                                },
                                tooltip: {
                                    trigger: 'item',
                                    formatter: `${i18n.finalize} : {c} ({d}%)`,
                                },
                            },
                            {
                                value: declarations.filter(dec => dec.statusCode < 3).length,
                                itemStyle: {
                                    normal: {
                                        color: 'grey',
                                    },
                                },
                                tooltip: {
                                    trigger: 'item',
                                    formatter: `${i18n.inProgress} : {c} ({d}%)`,
                                },
                            },
                        ],
                        selectedMode: 'single',
                        radius: ['0%', '35'],
                    }),
                    Pie({
                        data: [
                            {
                                value: declarations.filter(dec => dec.lastStep >= 4).length,
                                itemStyle: {
                                    normal: {
                                        color: 'green',
                                    },
                                },
                                tooltip: {
                                    trigger: 'item',
                                    formatter: `${i18n.step} 4 : {c} ({d}%)`,
                                },
                            },
                            {
                                value: declarations.filter(dec => dec.lastStep === 3).length,
                                itemStyle: {
                                    normal: {
                                        color: '#05A6A8',
                                    },
                                },
                                tooltip: {
                                    trigger: 'item',
                                    formatter: `${i18n.step} 3 : {c} ({d}%)`,
                                },
                            },
                            {
                                value: declarations.filter(dec => dec.lastStep === 2).length,
                                itemStyle: {
                                    normal: {
                                        color: '#2BB488',
                                    },
                                },
                                tooltip: {
                                    trigger: 'item',
                                    formatter: `${i18n.step} 2 : {c} ({d}%)`,
                                },
                            },
                            {
                                value: declarations.filter(dec => dec.lastStep === 1).length,
                                itemStyle: {
                                    normal: {
                                        color: '#7EB848',
                                    },
                                },
                                tooltip: {
                                    trigger: 'item',
                                    formatter: `${i18n.step} 1 : {c} ({d}%)`,
                                },
                            },
                            {
                                value: declarations.filter(dec => !dec.lastStep).length,
                                itemStyle: {
                                    normal: {
                                        color: 'grey',
                                    },
                                },
                                tooltip: {
                                    trigger: 'item',
                                    formatter: `${i18n.step} 0 : {c} ({d}%)`,
                                },
                            },
                        ],
                        selectedMode: 'single',
                        radius: ['55%', '70%'],
                    }),
                ],
                height: 200,
            }}
            />
        </div>
    ) || null
}

ProgressSurveyChart.propTypes = {
    survey: PropTypes.instanceOf(DtoSurvey),
}

export default ProgressSurveyChart
