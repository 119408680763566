import { push } from '@lagunovsky/redux-react-router'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { H_CAMPAIGN_QUALITO_DASHBOARD } from '../../../account/constants/AccessRulesConstants'
import CampaignAction from '../../../campaign/actions/CampaignAction'
import ProgressCard from '../../../components/card/ProgressCard'
import { componentHasHabilitations } from '../../../utils/HabilitationUtil'
import QualityCampaign from './QualityCampaign'


class QualityCampaignDashboard extends Component {
    state = {
        dataLoaded: false,
        progress: 0,
    }

    componentDidMount = () => {
        if (!componentHasHabilitations(H_CAMPAIGN_QUALITO_DASHBOARD)) { // A modifier quand react-router sera à jour
            this.props.push('/unauthorized')
            return
        }
        this.props.loadQualityCampaignDashboard(p => this.setState({ progress: p }))
            .then(() => this.setState({ dataLoaded: true }))
    }

    render = () => {
        const {
            dataLoaded,
            progress,
        } = this.state
        if (dataLoaded) {
            const {
                params,
            } = this.props
            return (
                <QualityCampaign
                    params={params}
                />
            )
        }
        return <ProgressCard progress={progress} />
    }
}

QualityCampaignDashboard.propTypes = {
    params: PropTypes.shape({
        id: PropTypes.string,
    }),
    push: PropTypes.func,
    loadQualityCampaignDashboard: PropTypes.func,
}

const mapDispatchToProps = {
    loadQualityCampaignDashboard: CampaignAction.loadQualityCampaignDashboard,
    push,
}

export default connect(null, mapDispatchToProps)(QualityCampaignDashboard)
