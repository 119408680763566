/* eslint-disable camelcase */
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import { Dialog, Grid, Button } from '@mui/material'
import { getDate } from 'utils/DateUtil'
import { nbPerPageLabelShort } from 'referencial/constants/ReferencialConstants'
import Table from 'components/datatable/Table'
import SimpleDatePicker from 'components/forms/SimpleDatePicker'
import NumberField from 'components/forms/NumberField'
import DtoSTEP from '../dto/DtoSTEP'
import DtoSTEPCollectionBasin from '../dto/DtoSTEPCollectionBasin'
import Input from 'components/forms/Input'
import { DialogActionsMUI, DialogContentMUI, DialogTitleMUI } from 'components/styled/Dialog'
import Icon from 'components/icon/Icon'
import { StyledFieldSet } from 'components/StyledElements'
import STEPCollectionsBasinsImportModal from './modal/STEPCollectionsBasinsImportModal'
import ExportFileModal from 'components/modal/ExportFileModal'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import ExportAction from 'export/actions/ExportAction'
import { formatData } from 'utils/ExportDataUtil'

const headers = ['basinIdentifier', 'basinName', 'connectionDate', 'countLinkSub', 'countLinkEstablishment']
const syntheticExportHeaders = ['siret', 'internalReferences', 'name', 'cityCode', 'city', 'categoryActivity', 'activityCode', 'activityLibelle', 'staff', 'effectiveTranche', 'icpe', 'dischargeAgreement', 'basinIdentifier', 'basinName']
const fullExportHeaders = ['siret', 'internalReferences', 'name', 'cityCode', 'city', 'x', 'y', 'projection', 'status', 'categoryActivity', 'activityCode', 'activityLibelle', 'activityCode2', 'activityLibelle2', 'activityCode3', 'activityLibelle3', 'activityCode4', 'activityLibelle4', 'staff', 'effectiveTranche', 'icpe', 'dischargeAgreement', 'basinIdentifier', 'basinName']

const STEPCollectionBasinsPanel = ({
    STEP = {},
    readMode = false,
    onChange = () => { },
}) => {
    const {
        installation,
    } = useSelector(store => ({
        installation: store.InstallationReducer.installation,
    }), shallowEqual)
    const dispatch = useDispatch()

    const [isOpen, setIsOpen] = useState(false)
    const [isNew, setIsNew] = useState(false)
    const [selectedIndex, setSelectedIndex] = useState(null)
    const [isOpenExport, setIsOpenExport] = useState(false)

    // basin state
    const [basinIdentifier, setBasinIdentifier] = useState(null)
    const [basinName, setBasinName] = useState(null)
    const [countLinkSub, setCountLinkSub] = useState(null)
    const [countLinkEstablishment, setCountLinkEstablishment] = useState(null)
    const [connectionDate, setConnectionDate] = useState(null)

    // import state
    const [isOpenImport, setIsOpenImport] = useState(false)

    const STEPBasins = STEP.link_basins.map((u, index) => ({
        ...u,
        index,
    }))
    const tableData = STEPBasins.map(es => ({
        ...es,
        connectionDate: getDate(es.connectionDate),
    }))

    const exportAction = {
        iconName: 'file_download',
        tooltip: i18n.add,
        onClick: () => setIsOpenExport(true),
    }

    const actions = !readMode ? [{
        iconName: 'note_add',
        tooltip: i18n.add,
        onClick: () => {
            setIsNew(true)
            setIsOpen(true)
        },
    }, {
        iconName: 'file_upload',
        tooltip: i18n.add,
        onClick: () => setIsOpenImport(true),
    }, exportAction] : [exportAction]

    const resetPopup = () => {
        setIsOpen(false)
        setIsNew(false)
        setSelectedIndex(null)
        setBasinIdentifier(null)
        setBasinName(null)
        setCountLinkSub(null)
        setCountLinkEstablishment(null)
        setConnectionDate(null)
    }

    const changeBasin = () => {
        const selectedBasin = STEPBasins.find(ls => ls.index === selectedIndex)
        const newBasin = new DtoSTEPCollectionBasin({
            ...selectedBasin,
            idInstallation: STEP.idStation,
            basinIdentifier,
            basinName,
            connectionDate,
            countLinkSub,
            countLinkEstablishment,
        })
        if (isNew) {
            onChange({
                link_basins: [...STEPBasins, newBasin],
            })
        } else {
            onChange({
                link_basins: [
                    ...STEPBasins.slice(0, selectedIndex),
                    { ...newBasin, index: selectedIndex },
                    ...STEPBasins.slice(selectedIndex + 1),
                ],
            })
        }
        resetPopup()
    }

    const setModal = (selectedBasin) => {
        const element = STEPBasins.find(es => selectedBasin.index === es.index)
        setIsOpen(true)
        setSelectedIndex(element.index)
        setBasinIdentifier(element.basinIdentifier)
        setBasinName(element.basinName)
        setCountLinkSub(element.countLinkSub)
        setCountLinkEstablishment(element.countLinkEstablishment)
        setConnectionDate(element.connectionDate)
    }

    const exportData = (data, type, exportHeaders, title) => {
        const dataWithHeader = data.length > 0 ? [{ ...data[0], headers: exportHeaders }, ...data.slice(1)] : []
        const dataFormatted = formatData(dataWithHeader)
        dispatch(ExportAction.export(dataFormatted, type, title))
    }

    const indusSiteExport = (type, exportHeaders) => {
        ExportAction.fetchStepBasinsExport(STEP.idStation).then(jsonData => {
            exportData(jsonData, type, exportHeaders, `${i18n.indusSiteList}_${installation?.name.replaceAll(' ', '_')}`)
        })
    }

    return (
        <Grid id='usages'>
            <Table
                title={i18n.collectionBasins}
                condensed paging sortable
                data={tableData}
                type={{ headers }}
                nbPerPageLabel={nbPerPageLabelShort}
                customHeaders={{
                    basinName: i18n.name,
                    basinIdentifier: i18n.identifier,
                }}
                actions={actions}
                alterable={!readMode}
                onAlter={element => setModal(element)}
                deletable={!readMode}
                onDelete={(element) => onChange({ link_basins: STEPBasins.filter(u => u.index !== element.index) })}
            />
            {isOpen && (
                <Dialog maxWidth='lg' open={isOpen}>
                    <DialogTitleMUI>
                        <Grid container justifyContent='space-between' alignItems='center'>
                            <Grid item>
                                {isNew ? i18n.editBasin : i18n.editBasin}
                            </Grid>
                            <Grid item>
                                <Icon
                                    style={{ color: 'white' }}
                                    size='small'
                                    icon={'close'}
                                    onClick={resetPopup}
                                />
                            </Grid>
                        </Grid>
                    </DialogTitleMUI>
                    <DialogContentMUI style={{ padding: '10 20 15' }}>
                        <StyledFieldSet>
                            <Grid container style={{ paddingTop: 5 }}>
                                <Grid container item xs={12} spacing={2} justifyContent='space-between'>
                                    <Grid item xs={6}>
                                        <Input
                                            title={i18n.identifier}
                                            value={basinIdentifier}
                                            onChange={setBasinIdentifier}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Input
                                            title={i18n.name}
                                            value={basinName}
                                            onChange={setBasinName}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container item xs={12} spacing={2}>
                                    <Grid item xs={6}>
                                        <SimpleDatePicker
                                            col={12}
                                            value={connectionDate}
                                            id='connectionDate'
                                            label={i18n.connectionDate}
                                            onChange={setConnectionDate}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container item xs={12} spacing={2} justifyContent='space-between' style={{ paddingTop: 5 }} w>
                                    <Grid item xs={6}>
                                        <NumberField
                                            col={12}
                                            title={i18n.countLinkEstablishment}
                                            value={countLinkEstablishment}
                                            disabled
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <NumberField
                                            col={12}
                                            title={i18n.countLinkSub}
                                            value={countLinkSub}
                                            disabled
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </StyledFieldSet>
                    </DialogContentMUI>
                    <DialogActionsMUI>
                        <Grid container justifyContent='center'>
                            <Button onClick={changeBasin} variant='contained'>
                                {isNew ? i18n.add : i18n.change}
                            </Button>
                        </Grid>
                    </DialogActionsMUI>
                </Dialog>
            )}
            <ExportFileModal
                open={isOpenExport}
                onClose={() => setIsOpenExport(false)}
                closeOnExport
                data={
                    [{
                        name: i18n.export,
                        group: 0,
                        formats: [{
                            type: i18n.excelFile,
                            callback: () => exportData(tableData, 'xlsx', headers, i18n.basinsList),
                        }, {
                            type: i18n.csvFile,
                            callback: () => exportData(tableData, 'csv', headers, i18n.basinsList),
                        }],
                    }, {
                        name: i18n.syntheticExport,
                        group: 1,
                        formats: [{
                            type: i18n.excelFile,
                            callback: () => indusSiteExport('xlsx', syntheticExportHeaders, `${i18n.indusSiteList}`),
                        }, {
                            type: i18n.csvFile,
                            callback: () => indusSiteExport('csv', syntheticExportHeaders, `${i18n.indusSiteList}`),
                        }],
                    }, {
                        name: i18n.exportFull,
                        group: 1,
                        formats: [{
                            type: i18n.excelFile,
                            callback: () => indusSiteExport('xlsx', fullExportHeaders, `${i18n.indusSiteList}`),
                        }, {
                            type: i18n.csvFile,
                            callback: () => indusSiteExport('csv', fullExportHeaders, `${i18n.indusSiteList}`),
                        }],
                    }]
                }
                groups={[{
                    value: 0,
                    name: i18n.basinsList,
                }, {
                    value: 1,
                    name: i18n.indusSiteList,
                }]}
            />

            <STEPCollectionsBasinsImportModal open={isOpenImport} setOpen={setIsOpenImport} STEP={STEP} />
        </Grid >
    )
}

STEPCollectionBasinsPanel.propTypes = {
    STEP: PropTypes.instanceOf(DtoSTEP),
    readMode: PropTypes.bool,
    onChange: PropTypes.func,
}

export default STEPCollectionBasinsPanel