import { hasValue } from 'utils/NumberUtil'

export default class DtoHydrobioList {
    constructor(obj) {
        this.qualitometer = obj.qualitometer // Double
        this.operation = obj.operation // Double
        this.id = obj.id // Double
        this.index = obj.index // Option[Double]
        this.taxon = obj.taxon // Option[String]
        this.enumerationA = obj.enumerationA // Option[Double]
        this.percentageA = obj.percentageA // Option[Double]
        this.enumerationB = obj.enumerationB // Option[Double]
        this.percentageB = obj.percentageB // Option[Double]
        this.enumerationC = obj.enumerationC // Option[Double]
        this.percentageC = obj.percentageC // Option[Double]
        this.unit = obj.unit // Option[Double]
        this.develop = obj.develop // Option[Double]
        this.remark = obj.remark || (!hasValue(obj.enumerationA) && !hasValue(obj.enumerationB) && !hasValue(obj.enumerationC) && !hasValue(obj.enumerationC2) && 0) || 1// Option[Double],
        this.trust = obj.trust // Option[Double]
        this.density = obj.density // Option[Double]
        this.enumerationC2 = obj.enumerationC2 // Option[Double]
        this.enumerationP1 = obj.enumerationP1 // Option[Double]
        this.enumerationP2 = obj.enumerationP2 // Option[Double]
        this.enumerationP3 = obj.enumerationP3 // Option[Double]
        this.enumerationP4 = obj.enumerationP4 // Option[Double]
        this.enumerationP5 = obj.enumerationP5 // Option[Double]
        this.enumerationP6 = obj.enumerationP6 // Option[Double]
        this.enumerationP7 = obj.enumerationP7 // Option[Double]
        this.enumerationP8 = obj.enumerationP8 // Option[Double]
        this.enumerationP9 = obj.enumerationP9 // Option[Double]
        this.enumerationP10 = obj.enumerationP10 // Option[Double]
        this.enumerationP11 = obj.enumerationP11 // Option[Double]
        this.enumerationP12 = obj.enumerationP12 // Option[Double]
        this.size = obj.size // Option[Int]
    }
}