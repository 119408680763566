import React, { useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import { push } from '@lagunovsky/redux-react-router'
import { arrayOf } from '../../../utils/StoreUtils'
import AgriAction from '../../../agriAdministration/actions/AgriAction'
import FormFilterPARs from '../panels/FormFilterPARs'
import { groupBy, orderBy, reverse } from 'lodash'
import { formatMilliers, getSandreLabel, searchAllCharacters } from 'utils/StringUtil'
import { nbPerPageLabel } from 'referencial/constants/ReferencialConstants'
import ReferencialAction from 'referencial/action/ReferencialAction'
import { Grid } from '@mui/material'
import DtoSurveyWithStats from '../../dto/DtoSurveyWithStats'
import { getUser } from '../../../utils/SettingUtils'
import ProgressCard from 'components/card/ProgressCard'
import TabList from 'components/list/TabList'
import { AccordionDetailsMUI, AccordionMUI, AccordionSummaryMUI } from 'components/styled/Accordions'
import useActions from 'utils/customHook/useActions'
import useTitle from 'utils/customHook/useTitle'
import Table from 'components/datatable/Table'
import CreatePARDialog from '../modals/CreatePARDialog'

const headers = ['nameDisplay', 'yearDisplay', 'detentions', 'statusDisplay', 'uge', 'lowWater', 'notLowWater', 'reserve', 'organismDisplay']
const emptyPAR = { periode: 1, tanks: false, statusCode: 1, linkSurveys: [], onlyDeclarationsCompleted: true }

const PARsListApp = ({}) => {
    const [filter, setFilter] = useState({})
    const [sortBy, setSortBy] = useState('date')
    const [open, setOpen] = useState(false)
    const [parsLoaded, setPARsLoaded] = useState(false)

    const {
        sandreCodes,
        PARs,
        surveysWithStats,
        contributors,
        accountUser,
    } = useSelector(store => ({
        sandreCodes: store.ReferencialReducer.sandreCodes,
        PARs: store.AgriReducer.PARs,
        surveysWithStats: store.AgriReducer.surveysWithStats,
        contributors: store.ContributorReducer.contributors,
        accountUser: store.AccountReducer.accountUser,
    }), shallowEqual)

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(AgriAction.fetchPARs()).then(() => setPARsLoaded(true))
        if (!sandreCodes.length) {
            dispatch(ReferencialAction.fetchSandreCodes())
        }
        if (!surveysWithStats.length) {
            dispatch(AgriAction.fetchSurveysWithStats())
        }
    }, [])

    useTitle(() => [{
        title: i18n.planning,
        href: 'planning/dashboard',
    }, {
        title: 'PARs',
        href: 'planning/par',
    }], [])

    useActions(() => {
        return getUser().isAdmin === '1' || getUser().metadata === '1' ? {
            new: () => setOpen(true),
        } : {}
    }, [])

    const getPanelList = (groups, title) => (
        <div className='padding-1'>
            <AccordionMUI defaultExpanded round>
                <AccordionSummaryMUI round>
                    {title}
                </AccordionSummaryMUI>
                <AccordionDetailsMUI nopadding>
                    <Table
                        showTitle={false}
                        data={groups}
                        nbPerPageLabel={nbPerPageLabel}
                        type={{ headers }}
                        customHeaders={{
                            uge: 'UG validées / UG',
                            nameDisplay: i18n.name,
                            yearDisplay: i18n.year,
                            statusDisplay: i18n.status,
                            organismDisplay: i18n.organism,
                        }}
                        onClick={p => dispatch(push(`/par/${p.id}`))}
                        condensed
                        sortable
                        round
                        paging
                        noFlexCell
                        color
                    />
                </AccordionDetailsMUI>
            </AccordionMUI>
        </div>
    )

    const getPARsByStatus = data => {
        const groupedPARs = groupBy(data, 'statusCode')
        return Object.keys(groupedPARs).map(key => {
            return getPanelList(groupedPARs[key], getSandreLabel(sandreCodes, 'AGRI_PAR.STATUT', key))
        })
    }

    const getPARsByDate = data => {
        const groupedPARs = groupBy(data, 'year')
        return reverse(Object.keys(groupedPARs).map(key => {
            return getPanelList(groupedPARs[key], key)
        }))
    }

    const getPARsByOrganism = data => {
        const groupedPARs = groupBy(data, 'organism')
        return Object.keys(groupedPARs).map(key => {
            return getPanelList(groupedPARs[key], key === '' ? i18n.none : key)
        })
    }

    const getHash = (par) => {
        return searchAllCharacters(['name', 'year', 'description'].map(key => par[key] || ''))
    }

    const getFilteredPARs = () => {
        const yearFilter = filter.year ? PARs.filter(par => par.year === filter.year) : PARs
        const statusFilter = filter.status ? yearFilter.filter(par => par.statusCode === filter.status) : yearFilter
        const searchValueFilter = filter.searchValue ? statusFilter.filter(i => getHash(i).includes(filter.searchValue)) : statusFilter

        return orderBy(searchValueFilter, 'year', 'desc')
    }

    const getPARs = () => {
        const filteredPARs = getFilteredPARs().map((p) => ({
            ...p,
            nameDisplay: { value: p.name },
            yearDisplay: { value: p.year },
            statusDisplay: { value: getSandreLabel(sandreCodes, 'AGRI_PAR.STATUT', p.statusCode) },
            detentions: { value: p.tanks ? i18n.yes : i18n.no },
            organismDisplay: { value: p.organism ? contributors.find((c) => c.id === p.organism)?.name : '' },
            uge: { value: `${p.nbUGValidated ?? ''} / ${p.nbUG ?? ''}` },
            lowWater: { value: formatMilliers(p.volumeLowWater) },
            notLowWater: { value: formatMilliers(p.volumeNotLowWater) },
            reserve: { value: formatMilliers(p.volumeReserved) },
        }))
        switch (sortBy) {
            case 'statut':
                return getPARsByStatus(filteredPARs)
            case 'date':
                return getPARsByDate(filteredPARs)
            case 'organism':
                return getPARsByOrganism(filteredPARs)
            default:
                return []
        }
    }

    const handleOnSave = (newPAR) => {
        dispatch(AgriAction.createPAR(newPAR)).then((v) => {
            setOpen(false)
            dispatch(push(`/par/${v}`))
        })
    }

    return (
        <div className='row no-margin' style={{ padding: 10 }}>
            <Grid container spacing={2} alignItems='flex-start'>
                <Grid item xs={12}>
                    <FormFilterPARs onChange={obj => setFilter(obj)} />
                </Grid>
            </Grid>
            <TabList
                onChangeTab={(v) => setSortBy(v)}
                defaultTab='date'
                tabs={[{
                    value: 'statut',
                    label: i18n.byStatus,
                    icon: 'edit',
                },
                {
                    value: 'date',
                    label: i18n.perYear,
                    icon: 'insert_invitation',
                },
                {
                    value: 'organism',
                    label: i18n.byOrganism,
                    icon: 'person',
                }]}
            >
                { parsLoaded ? getPARs() : <ProgressCard indeterminate round /> }
            </TabList>
            <CreatePARDialog
                onValidate={handleOnSave}
                onClose={() => setOpen(false)}
                par={{ ...emptyPAR, organism: accountUser.contributorCode }}
                open={open}
            />
        </div>
    )
}

PARsListApp.propTypes = {
    surveysWithStats: arrayOf(DtoSurveyWithStats),
}

export default PARsListApp
