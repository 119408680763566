import React, { useEffect, useMemo, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import NetworkAction from '../actions/NetworkAction'
import i18n from 'simple-react-i18n'
import { push } from '@lagunovsky/redux-react-router'
import { sandreNetworkDefaultLink } from '../../../../conf/SieauConstants'
import {
    PATH_REFERENCIAL,
    PATH_REFERENCIAL_NETWORK,
    PATH_REFERENCIAL_NETWORK_NEW,
} from '../../../../home/constants/RouteConstants'
import ReferentialAction from '../../../action/ReferencialAction'
import { find } from 'lodash'
import HomeAction from '../../../../home/actions/HomeAction'
import logoSandre from 'assets/pictures/logo_sandre.png'
import { getDataWithHeaders } from 'utils/ExportDataUtil'
import { substringText } from 'utils/StringUtil'
import useTitle from 'utils/customHook/useTitle'
import useActions from 'utils/customHook/useActions'
import SearchTable from 'referencial/components/SearchTable'
import { getExportReferencialData } from 'referencial/util/ReferencialUtils'
import { REFERENCIAL_TYPE_NAME } from 'referencial/constants/ReferencialConstants'

const headers = ['code', 'name', 'mnemonic', 'finality', 'dce', 'sandreCode']

const NetworksApp = () => {
    const {
        networks,
        referencialSandreCodes,
    } = useSelector(store => ({
        networks: store.NetworkReducer.networks,
        referencialSandreCodes: store.ReferencialReducer.referencialSandreCodes,
    }), shallowEqual)

    const [exportData, setExportData] = useState([])

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(NetworkAction.fetchNetworks())
        dispatch(ReferentialAction.fetchReferencialSandreCodes())
        dispatch(HomeAction.setHelpLink('', ''))
    }, [])

    useTitle(() => [{
        title: i18n.referencials,
        href: PATH_REFERENCIAL,
    }, {
        title: i18n.networks,
        href: PATH_REFERENCIAL_NETWORK,
    }], [])

    useActions(() => {
        return {
            new: () => dispatch(push(PATH_REFERENCIAL_NETWORK_NEW)),
            referencialActions: {
                actions: {
                    export: {
                        data: getDataWithHeaders(exportData, headers),
                        titleFile: i18n.networksList,
                    },
                    sync: {
                        updateAction: () => dispatch(NetworkAction.fetchSynchroSandreNetwork()),
                    },
                    purge: {
                        onPurge: () => dispatch(NetworkAction.purgeNetwork()),
                    },
                },
            },
            links: [{
                href: sandreNetworkDefaultLink,
                img: logoSandre,
                label: i18n.sandre.toUpperCase(),
            }],
        }
    }, [exportData])

    const lastUpdate = useMemo(() => find(referencialSandreCodes, o => o.field === 'RESEAUX'), [referencialSandreCodes])

    const networksFormatted = useMemo(() => {
        if (networks.length) {
            return getExportReferencialData(networks).map(network => ({
                ...network,
                name: substringText(network.name, 150),
                finality: substringText(network.finality, 60),
            }))
        }
        return []
    }, [networks])

    return (
        <SearchTable
            title={i18n.networksList}
            sessionKey={REFERENCIAL_TYPE_NAME.network}
            lastUpdate={lastUpdate}
            data={networksFormatted}
            setExportData={setExportData}
            headers={headers}
            onClick={network => dispatch(push(`/referencial/network/${network.id}/dashboard`))}
        />
    )
}

export default NetworksApp
