export default class DtoSelectionParameter {
    constructor(obj) {
        this.code = obj.code
        this.num = obj.num
        this.parameterCode = obj.parameterCode
        this.unitCode = obj.unitCode
        this.listType = obj.listType
        this.childCode = obj.childCode
    }
}
