import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import React, { useState } from 'react'
import AddQualitoStepStation from './steps/AddQualitoStepStation'
import StepperDialog from '../../../../components/modal/StepperDialog'
import AddQualitoStepOptions from './steps/AddQualitoStepOptions'
import { hasValue } from '../../../../utils/NumberUtil'
import JobAction from '../../../../import/actions/JobAction'
import { getManualJobId } from '../../../../utils/JobUtils'
import { QUALITO_IMPORT_TYPE } from './AddQualitoConstants'
import { getLogin } from '../../../../utils/SettingUtils'
import ToastrAction from '../../../../toastr/actions/ToastrAction'
import QualityAction from '../../../actions/QualityAction'
import { push } from '@lagunovsky/redux-react-router'
import AddStepExecution from 'station/components/addSteps/AddStepExecution'
import AddStepJob from 'station/components/addSteps/AddStepJob'

const STEP = {
    STATION_TYPE: 0,
    OPTIONS: 1,
    EXECUTION: 2,
    JOB: 3,
}

const setJobCode = (stationType, code, filters, parameters) => {
    switch (stationType) {
        case QUALITO_IMPORT_TYPE.ADES_QUALITO:
            return { filters: { ...filters, stationCodes: [code] }, parameters }
        case QUALITO_IMPORT_TYPE.NAIADES:
            return { filters, parameters: [code] }
        case QUALITO_IMPORT_TYPE.OROBNAT:
            return { filters }
        default:
            return filters
    }
}

const callExecuteJob = (id, cb) => JobAction.promiseExecuteJob(id).then(json => {
    if (hasValue(json.execution)) {
        cb(json.execution)
    } else {
        setTimeout(() => JobAction.promiseExecuteJob(id).then(newJson => {
            if (hasValue(newJson.execution)) {
                cb(newJson.execution)
            } else {
                throw new Error('Impossible to launch Job')
            }
        }), 5000)
    }
})

const createStation = (station, dispatch) => {
    dispatch(QualityAction.createQualitometer({ ...station, stationType: null }, id => {
        dispatch(push(`/station/quality/${id}/description`))
    }))
}

const AddQualitoStepper = ({
    isOpen = false,
    setIsOpen = () => {},
}) => {
    const dispatch = useDispatch()

    const {
        qualitometers,
        userStation,
    } = useSelector(store => ({
        qualitometers: store.QualityReducer.qualitometersListSpecific,
        userStation: store.UserReducer.userStation,
    }), shallowEqual)

    const [station, setStation] = useState({})
    const [filters, setFilters] = useState({})
    const [jobDataTypes, setJobDataTypes] = useState([])

    const changeStation = (changes) => setStation({ ...station, ...changes })
    const changeFilters = changes => setFilters({ ...filters, ...changes })

    const executeJob = (nextStep) => {
        JobAction.promiseJob(getManualJobId(station.stationType)).then(job => {
            const jobWithCode = setJobCode(station.stationType, station.code, filters)
            const oldParams = JSON.parse(job.parameters)
            const newJob = { ...job, parameters: {
                jobType: oldParams.jobType,
                routingKey: oldParams.routingKey,
                filters: jobWithCode.filters ? [JSON.stringify(jobWithCode.filters)] : [],
                dataTypes: jobDataTypes,
                parameters: jobWithCode.parameters,
                alertTypes: [],
            }, login: getLogin() }
            JobAction.promiseUpdateJob(newJob).then(res => {
                if (res.status !== 200) {
                    dispatch(ToastrAction.error(i18n.updateError + i18n.jobs))
                } else {
                    // changeStation({ jobExecutionId: 2877126, job: newJob }) // TODO: pour le test, à enlever
                    // nextStep()
                    callExecuteJob(newJob.id, jobExecutionId => {
                        changeStation({ jobExecutionId, job: newJob })
                        nextStep()
                    }).then(() => {})
                }
            })
        })
    }

    const isNextAvailable3 = () => {
        if (station?.jobExecution?.statusCode === 1 || station?.jobExecution?.statusCode === 2) {
            if (station.stationType === QUALITO_IMPORT_TYPE.OROBNAT) {
                return qualitometers?.filter(p => station?.ids?.includes(p.id))?.length === station.ids?.length
            }
            return !!qualitometers.find(p => p.code === station.code.replaceAll(/\/.*/g, ''))
        }
        return false
    }

    return (
        <StepperDialog
            steps={[
                {
                    label: i18n.station,
                    constant: STEP.STATION_TYPE,
                    nextAvailable: !!station.stationType,
                },
                {
                    label: i18n.options,
                    constant: STEP.OPTIONS,
                    nextAvailable: (hasValue(station.code) || !!filters?.codes?.length) && userStation.hasRights,
                    nextLabel: station.stationType === QUALITO_IMPORT_TYPE.EMPTY ? i18n.add : i18n.execute,
                    onNext: station.stationType === QUALITO_IMPORT_TYPE.EMPTY ? () => createStation(station, dispatch) : executeJob,
                },
                {
                    label: i18n.execution,
                    constant: STEP.EXECUTION,
                    nextAvailable: isNextAvailable3(),
                },
                {
                    label: i18n.programmation,
                    constant: STEP.JOB,
                },
            ]}
            open={isOpen}
            title={i18n.addOrUpdateStation}
            closeDialog={() => setIsOpen(false)}
        >
            {(step, nextStep, resetStep) => {
                const fullReset = () => {
                    resetStep()
                    setStation({})
                    setFilters({})
                    setJobDataTypes([])
                }
                const params = { setIsOpen, station, changeStation, filters, changeFilters, setFilters, nextStep, jobDataTypes, setJobDataTypes }
                switch (step) {
                    case STEP.STATION_TYPE:
                        return <AddQualitoStepStation {...params}/>
                    case STEP.OPTIONS:
                        return <AddQualitoStepOptions {...params}/>
                    case STEP.EXECUTION:
                        return <AddStepExecution {...params} fetchStations={QualityAction.fetchQualitometersListSpecific} />
                    case STEP.JOB:
                        return (<AddStepJob
                            {...params}
                            fullReset={fullReset}
                            setIsOpen={setIsOpen}
                            stationType='quality'
                            allStations={qualitometers}
                        />)
                    default:
                        return null
                }
            }}
        </StepperDialog>
    )
}

AddQualitoStepper.propTypes = {
    stationType: PropTypes.string,
    id: PropTypes.string,
    isOpen: PropTypes.bool,
    setIsOpen: PropTypes.func,
}

export default AddQualitoStepper