'use strict'
import AdministrationAction from 'administration/actions/AdministrationAction'
import { find, isEqual, remove } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import ToastrAction from 'toastr/actions/ToastrAction'
import { sieauTooltip } from 'utils/FormUtils'
import AdministrationUserHomePage from '../../administration/components/homepage/AdministrationUserHomePage'
import UserAction from '../../administration/components/user/actions/UserAction'
import PlatformApplicationChartPanel from '../../administration/components/user/components/detailPanel/charts/PlatformApplicationChartPanel'
import {
    BASIC_HOMEPAGE_VIEWS,
    PANEL_ACCESS,
    PANEL_HOME,
    PANEL_MAP,
    PANEL_MODULES,
    PANEL_OPTION,
    PANEL_STATISTICS,
} from '../../administration/components/user/constants/UserConstants'
import DtoUser from '../../administration/components/user/dto/DtoUser'
import KeyFigureDto from '../../administration/components/user/dto/KeyFigureDto'
import UserViewDto from '../../administration/components/user/dto/UserViewDto'
import ActionComponent from '../../components/ActionComponent'
import Card from '../../components/card/Card'
import KeyFigurePanel from '../../components/keyFigures/KeyFigurePanel'
import UpdatePanel from '../../components/updatePanel/UpdatePanel'
import CityDto from '../../referencial/components/city/dto/CityDto'
import ContactDto from '../../referencial/components/contact/dto/ContactDto'
import ContributorDto from '../../referencial/components/contributor/dto/ContributorDto'
import { hasValue } from '../../utils/NumberUtil'
import { getLogin } from '../../utils/SettingUtils'
import { getLabel } from '../../utils/StoreUtils'
import AccountAction from '../actions/AccountAction'
import DtoAccountHabilitation from '../dto/DtoAccountHabilitation'
import DtoAccountSettings from '../dto/DtoAccountSettings'
import UpdateUserForm from './form/UpdateUserForm'
import AccessAccountPanel from './panel/AccessAccountPanel'
import AccountAccessApplicationChartPanel from './panel/charts/AccountAccessApplicationChartPanel'
import MapAccountUserPanel from './panel/MapAccountUserPanel'
import ModulesAccountPanel from './panel/ModulesAccountPanel'
import UserOptionQualityPanel from './panel/option/UserOptionQualityPanel'
import StatisticAccountPanel from './panel/StatisticAccountPanel'
import laboIcon from 'assets/pictures/pictos/laboIcon.png'
import ProgressBar from 'components/progress/ProgressBar'
import HomeAction from 'home/actions/HomeAction'
import CityAction from 'referencial/components/city/actions/CityAction'

class AccountUserApp extends ActionComponent {
    constructor(props) {
        super(props)
        this.state = {
            view: PANEL_STATISTICS,
            settings: [],
            contact: {},
            userView: this.props.userView || [],
            profilePicture: {},
        }
    }

    componentWillMount() {
        this.props.fetchCities()
        this.props.fetchUserView()
        this.props.fetchApplicationHabilitations()
        this.props.setTitle([{
            title: i18n.myAccount,
            href: 'account',
        }])
        this.initState()
    }

    isValidEmail = (email) => (!!/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email))

    saveFunction = () => {
        const { contact, settings, userView } = this.state
        const { contacts, accountUser, accountUserSettings, userView: userViewProps } = this.props
        if (this.isValidEmail(contact.email)) {
            this.props.saveContact(contact, !contacts.find(c => c.code === contact.code))
        } else {
            this.props.toastrError(i18n.invalidEmail)
        }
        if (!isEqual(accountUserSettings, settings)) {
            const result = settings.map(o => ({
                ...o,
                value: o.value ? o.value.toString() : '',
            }))
            this.props.saveSettings(accountUser.login, result)
        }
        if (!isEqual(userViewProps, userView)) {
            this.props.saveUserView(userView.map(view => ({ ...view, login: accountUser.login })))
        }
    }

    componentWillReceiveProps(nextProps) {
        if ((nextProps.contacts.length || !isEqual(nextProps.contacts, this.props.contacts)) && nextProps.accountUser.contactCode && !this.state.contact.code) {
            this.initState(nextProps)
        }
        if (!isEqual(nextProps.userView, this.props.userView)) {
            this.setState({ userView: nextProps.userView })
        }
    }

    initState = (props = this.props) => {
        const findContact = find(props.contacts, (o) => {
            return o.code == props.accountUser.contactCode
        })
        if (findContact) {
            this.setState({
                contact: findContact,
            })
        }
    }

    changeContactValue = (key, value) => {
        const contact = {
            ...this.state.contact,
            [key]: value,
        }
        this.setState({ contact })
    }

    changeSettings = (sttngs) => {
        const tmp = [...this.getAccountSettings()]
        const filterSettings = remove(tmp, o => {
            return !find(sttngs, sett => o.parameter === sett.parameter)
        })
        const settings = [...filterSettings, ...sttngs].map(o => (new DtoAccountSettings({
            ...o,
            login: this.props.accountUser.login,
        })))
        this.setState({ settings })
    }

    changeSetting = (setting) => {
        const tmp = [...this.getAccountSettings()]
        const filterSettings = tmp.filter(o => o.parameter !== setting.parameter)
        const settings = [...filterSettings, setting].map(o => (new DtoAccountSettings({
            ...o,
            login: this.props.accountUser.login,
        })))
        this.setState({ settings })
    }

    changeHomePageView = (view, changes) => {
        const newView = new UserViewDto({ ...view, ...changes })
        const userView = this.state.userView.filter(v => v.panel != newView.panel)
        this.setState({ userView: [...userView, newView] })
    }

    resetView = () => {
        this.setState({ userView: BASIC_HOMEPAGE_VIEWS })
    }

    getUpdatePanel = () => {
        if (this.props.accountUser.loginMaj || this.props.accountUser.updateDate) {
            return (
                <div className='row margin-top-1 margin-bottom-0'>
                    <div className='col s12'>
                        <UpdatePanel updateLogin={ this.props.accountUser.loginMaj }
                            updateDate={ this.props.accountUser.updateDate }
                        />
                    </div>
                </div>
            )
        }
        return null
    }

    changeState = (view) => {
        this.setState({
            view,
        })
    }

    getAccountSettings = () => {
        if (this.state.settings.length) {
            return this.state.settings
        }
        return this.props.accountUserSettings
    }

    getPanel = () => {
        const { accountUserSettings } = this.props
        switch (this.state.view) {
            case PANEL_ACCESS:
                return (
                    <AccessAccountPanel/>
                )
            case PANEL_STATISTICS:
                return (
                    <StatisticAccountPanel/>
                )
            case PANEL_MAP:
                return accountUserSettings.length > 0 ? (
                    <MapAccountUserPanel
                        accountUser={this.props.accountUser}
                        changeSettings={ this.changeSettings }
                        accountUserSettings={ this.getAccountSettings() }
                    />
                ) : (
                    <ProgressBar indeterminate />
                )
            case PANEL_HOME:
                return (
                    <AdministrationUserHomePage
                        homepageViews={ this.state.userView }
                        onChangeView={ this.changeHomePageView }
                        resetView={ this.resetView }
                    />
                )
            case PANEL_OPTION:
                return (
                    <div className='row no-margin'>
                        <UserOptionQualityPanel accountUserSettings={ this.getAccountSettings() }
                            changeSetting={ this.changeSetting }
                        />
                    </div>
                )
            default:
                return (
                    <ModulesAccountPanel/>
                )
        }
    }

    saveFile = () => {
        const { profilePicture } = this.state
        if (profilePicture.content) {
            this.props.addProfilePicture(profilePicture).then(() => {
                this.props.fetchProfilePicture(getLogin())
                this.setState({ profilePicture: {} })
            })
        }
    }

    getUserProfils = () => {
        const { accountUser } = this.props

        const adminElement = (accountUser.isAdmin === '1' || accountUser.admin === '1') && (
            <>
                <i className='material-icons small center-align' style={{ color: '#161832' }}
                    { ...sieauTooltip(i18n.admin, null, 'bottom') }
                >school</i>
                <span style={{ position: 'absolute', paddingTop: '0.5rem', paddingLeft: '0.5rem' }}>{i18n.admin}</span>
            </>
        )

        const referencialManagerElement = (accountUser.metadata === '1') && (
            <>
                <i className='material-icons small center-align' style={{ color: '#161832' }}
                    { ...sieauTooltip(i18n.referencialManager, null, 'bottom') }
                >folder</i>
                <span style={{ position: 'absolute', paddingTop: '0.5rem', paddingLeft: '0.5rem' }}>{i18n.administrator}</span>
            </>
        )

        const technicienElement = (accountUser.isTechnicien === '1' || accountUser.labo === '1') && (
            <>
                <img src={ laboIcon } className='responsive-img' { ...sieauTooltip(i18n.technicien, null, 'bottom') }
                    style={ { width: '2rem' } }
                />
                <span style={{ position: 'absolute', paddingTop: '0.5rem', paddingLeft: '0.5rem' }}>{i18n.technicien}</span>
            </>
        )

        const consultantElement = (accountUser.consultant === '1') && (
            <>
                <i className='material-icons small center-align' style={{ color: '#161832' }}
                    { ...sieauTooltip(i18n.consultant, null, 'bottom') }
                >remove_red_eye</i>
                <span style={{ position: 'absolute', paddingTop: '0.5rem', paddingLeft: '0.5rem' }}>{i18n.consultant}</span>
            </>
        )

        return [adminElement, referencialManagerElement, technicienElement, consultantElement].filter(p => !!p)
    }

    getAdministratorCard = () => {
        const { accountUser } = this.props
        const profils = this.getUserProfils()
        if (accountUser) {
            return (
                <div className='col s12'>
                    <Card>
                        <div className='row no-margin padding-top-1 padding-left-1 padding-bottom-1'>
                            <div className='col s12 no-padding'>
                                <span style={{ fontSize: '1.5rem' }}>{accountUser.login}</span>
                            </div>
                            <div className='col s12 no-padding'>
                                <span style={{ fontSize: '1rem' }}>{i18n.profils}</span>
                            </div>
                            {profils.map((p, i) => (
                                <div key={i} className='col s12' style={{ fontSize: '1rem' }}>
                                    {p}
                                </div>
                            ))}
                            {hasValue(accountUser.contributorCode) && (
                                <div>
                                    <div className='col s12 no-padding'>
                                        <span style={ { fontSize: '1rem' } }>{i18n.administrator }</span>
                                    </div>
                                    <div className='col s12'>
                                        <span style={ { fontSize: '1rem' } }>{ getLabel(this.props.contributors, this.props.accountUser.contributorCode, 'mnemonique') }</span>
                                    </div>
                                </div>
                            )}

                        </div>
                    </Card>
                </div>
            )
        }
        return null
    }

    render() {
        const { accountStatistics, accountUserKeyFigures } = this.props
        const { profilePicture } = this.state
        return (
            <div className='row no-margin'>
                <div className='col s9'>
                    <div className='row no-margin'>
                        <div className='col s12'>
                            <UpdateUserForm contact={ this.state.contact }
                                changeContactValue={ this.changeContactValue }
                                profilePicture = { profilePicture }
                                changePictureToUpdate = { (obj) => this.setState(obj) }
                            />
                        </div>
                    </div>
                    <div className='row no-margin'>
                        <div className='col s12'>
                            <div className='row no-margin'>
                                <div className='col s12 no-padding'>
                                    <ul className='tabs tabs-fixed-width'>
                                        <li className='tab'>
                                            <a onClick={ () => this.changeState(PANEL_STATISTICS) }>{ i18n.statistics }</a>
                                        </li>
                                        <li className='tab'>
                                            <a onClick={ () => this.changeState(PANEL_MODULES) }>{ i18n.modules }</a>
                                        </li>
                                        <li className='tab'>
                                            <a onClick={ () => this.changeState(PANEL_ACCESS) }>{ i18n.access }</a>
                                        </li>
                                        <li className='tab'>
                                            <a onClick={ () => this.changeState(PANEL_OPTION) }>{ i18n.options }</a>
                                        </li>
                                        <li className='tab'>
                                            <a onClick={ () => this.changeState(PANEL_MAP) }>{ i18n.map }</a>
                                        </li>
                                        <li className='tab'>
                                            <a onClick={ () => this.changeState(PANEL_HOME) }>{ i18n.homepage }</a>
                                        </li>
                                    </ul>
                                </div>
                                <div className='col s12 no-padding'>
                                    <div className='row no-margin card'>
                                        <div className='col s12 card-content'>
                                            { this.getPanel() }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col s3'>
                    { this.getUpdatePanel() }
                    <div className='row no-margin'>
                        <div className='col s12'>
                            <KeyFigurePanel data={ accountUserKeyFigures } title={ 'Statistiques' }
                                icon={ 'donut_large' } singleLine
                            />
                        </div>
                        { this.getAdministratorCard() }
                        <div className='col s12'>
                            <AccountAccessApplicationChartPanel/>
                        </div>
                        <div className='col s12 margin-top-1'>
                            <PlatformApplicationChartPanel
                                showTools={false}
                                userStatistics={accountStatistics.filter(({ eventType }) => eventType === 'PLATFORM')}
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    componentDidUpdate() {
        $('ul.tabs').tabs()
    }

    componentDidMount() {
        $('ul.tabs').tabs()
        this.setActions({
            save: () => {
                this.saveFile()
                this.saveFunction()
            },
        })
    }
}

AccountUserApp.propTypes = {
    accountUser: PropTypes.instanceOf(DtoUser),
    accountUserKeyFigures: PropTypes.arrayOf(PropTypes.instanceOf(KeyFigureDto)),
    accountHabilitations: PropTypes.arrayOf(PropTypes.instanceOf(DtoAccountHabilitation)),
    accountUserSettings: PropTypes.arrayOf(PropTypes.instanceOf(DtoAccountSettings)),
    contacts: PropTypes.arrayOf(PropTypes.instanceOf(ContactDto)),
    cities: PropTypes.arrayOf(PropTypes.instanceOf(CityDto)),
    contributors: PropTypes.arrayOf(PropTypes.instanceOf(ContributorDto)),
    userView: PropTypes.arrayOf(PropTypes.instanceOf(UserViewDto)),
    fetchProfilePicture: PropTypes.func,
    addProfilePicture: PropTypes.func,
    fetchCities: PropTypes.func,
    setTitle: PropTypes.func,
    fetchUserView: PropTypes.func,
    fetchApplicationHabilitations: PropTypes.func,
}
const mapStateToProps = (store) => {
    return {
        contacts: store.ContactReducer.contacts,
        accountUser: store.AccountReducer.accountUser,
        accountStatistics: store.AccountReducer.accountStatistics,
        accountHabilitations: store.AccountReducer.accountHabilitations,
        accountUserKeyFigures: store.AccountReducer.accountUserKeyFigures,
        accountUserSettings: store.AccountReducer.accountUserSettings,
        cities: store.CityReducer.cities,
        contributors: store.ContributorReducer.contributors,
        userView: store.UserReducer.userView,
    }
}

const mapDispatchToProps = {
    saveContact: AccountAction.updateContact,
    saveSettings: AccountAction.updateSettings,
    saveUserView: UserAction.updateUserView,
    fetchProfilePicture: AdministrationAction.fetchProfilePicture,
    addProfilePicture: AdministrationAction.addProfilePicture,
    fetchCities: CityAction.fetchCities,
    setTitle: HomeAction.setTitle,
    fetchUserView: UserAction.fetchUserView,
    fetchApplicationHabilitations: UserAction.fetchApplicationHabilitations,
    toastrError: ToastrAction.error,
}
export default connect(mapStateToProps, mapDispatchToProps)(AccountUserApp)
