module.exports = {
    MODELS_TYPES: [
        { code: 0, name: 'Modèle installation', stationType: 'installation' },
        { code: 1, name: 'Fiches piézométrique', stationType: 'piezometry' },
        { code: 2, name: 'Bilans piézométrique', stationType: 'piezometryResearch' },
        { code: 3, name: 'Fiches qualité', stationType: 'quality' },
        { code: 4, name: 'Bilans qualité', stationType: 'qualityResearch' },
        { code: 5, name: 'Modèles mail', stationType: 'mail' },
        { code: 6, name: 'XSD', stationType: 'XSD' },
        { code: 7, name: 'IryQua - Déclaration', stationType: 'agriDeclaration' },
        { code: 8, name: 'IryQua - Synthèse', stationType: 'agriSynthese' },
        { code: 9, name: 'IryQua - Notification', stationType: 'agriNotification' },
        { code: 10, name: 'Bulletins', stationType: 'bulletins' },
        { code: 11, name: 'Fiches hydrométrique', stationType: 'hydrometry' },
        { code: 12, name: 'Bilans hydrométrique', stationType: 'hydrometryResearch' },
        { code: 13, name: 'Fiches pluviométrique', stationType: 'pluviometry' },
        { code: 14, name: 'Bilans pluviométrique', stationType: 'pluviometryResearch' },
    ],
}