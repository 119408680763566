export default class DtoPiezometerIndicator {
    constructor(object) {
        this.creationDate = object.creationDate
        this.date = object.date
        this.idIndicator = object.idIndicator
        this.idStation = object.idStation
        this.resultClass = object.resultClass
        this.trend = object.trend
        this.typeId = object.typeId
        this.value = object.value
    }
}