import { Grid } from '@mui/material'
import { AccordionDetailsMUI, AccordionMUI, AccordionSummaryMUI } from 'components/styled/Accordions'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import { TIMEZONE_INTEGRATION, TIMEZONE_LOCAL } from 'utils/DateUtil'
import DtoUser from '../../../../administration/components/user/dto/DtoUser'
import Checkbox from '../../../../components/forms/Checkbox'
import NumberField from '../../../../components/forms/NumberField'
import RadioButtons from '../../../../components/forms/RadioButtons'
import Select from '../../../../components/forms/Select'
import { getSetting } from '../../../../utils/SettingUtils'
import { getMapStateToProps, getPropTypes } from '../../../../utils/StoreUtils'
import DtoAccountSettings from '../../../dto/DtoAccountSettings'

const propsToFetch = {
    producers: true,
    contributors: true,
    contacts: true,
    status: true,
    qualifications: true,
    supports: true,
}

class UserOptionQualityPanel extends Component {
    constructor(props) {
        super(props)
    }

    componentDidUpdate() {
        $('.collapsible').collapsible('open', 0).collapsible('open', 1)
    }

    componentDidMount() {
        $('.collapsible').collapsible('open', 0).collapsible('open', 1)
    }

    createParameter = (parameter, value) => {
        return {
            login: this.props.accountUser.login,
            parameter,
            updateDate: moment().valueOf(),
            value,
        }
    }

    getContent = () => {
        const { contributors, contacts, producers, accountUserSettings } = this.props
        const LQCalculationOptions = [
            { value: '1', label: i18n.divideLQby2 },
            { value: '2', label: i18n.excludeResultsInfLQ },
            { value: '3', label: i18n.fixedValue },
        ]

        const LQCalculationValue = getSetting(accountUserSettings, 'TypeLimiteQuantification') === '3' ? (
            <div>
                <NumberField col={ 1 } title={ i18n.value } value={ getSetting(accountUserSettings, 'LQCalculationValue') }
                    onChange={ (v) => this.props.changeSetting(this.createParameter('LQCalculationValue', v)) } floatValue
                />
            </div>
        ) : null

        const timezones = [{
            label: i18n.localHour,
            code: TIMEZONE_LOCAL,
        }, {
            label: i18n.integrationHour,
            code: TIMEZONE_INTEGRATION,
        }]

        return (
            <div className='col s12 no-padding no-margin'>
                <div className='row no-margin card-content'>
                    <div className='row no-margin'>
                        <h5>{i18n.LQcalculationRule}</h5>
                    </div>
                    <div className='row no-margin'>
                        <RadioButtons col={ 9 } elements={ LQCalculationOptions } selected={ getSetting(accountUserSettings, 'TypeLimiteQuantification') || '1' }
                            onChange={ (v) => this.props.changeSetting(this.createParameter('TypeLimiteQuantification', v)) }
                        />
                        { LQCalculationValue }
                    </div>
                    <div className='row no-margin'>
                        <div className='s12'>
                            <h5>{i18n.defaultValue}</h5>
                        </div>
                    </div>
                    <div className='row no-margin padding-top-10-px'>
                        <Select
                            col={ 12 }
                            options={ producers }
                            label={ i18n.producer }
                            nullLabel='&nbsp;'
                            onChange={ v => this.props.changeSetting(this.createParameter('defaultProducer', v)) }
                            value={ getSetting(accountUserSettings, 'defaultProducer') }
                        />
                    </div>
                    <div className='row no-margin padding-top-10-px'>
                        <Select
                            col={ 6 }
                            options={ contributors }
                            label={ i18n.sampler }
                            nullLabel='&nbsp;'
                            onChange={ v => this.props.changeSetting(this.createParameter('defaultSampler', v)) }
                            value={ getSetting(accountUserSettings, 'defaultSampler') }
                        />
                        <Select
                            col={ 6 }
                            options={ contacts }
                            label={ i18n.contact }
                            nullLabel='&nbsp;'
                            onChange={ v => this.props.changeSetting(this.createParameter('defaultSamplerContact', v)) }
                            value={ getSetting(accountUserSettings, 'defaultSamplerContact') }
                        />
                    </div>
                    <div className='row no-margin padding-top-10-px'>
                        <Select
                            col={ 6 }
                            options={ contributors }
                            label={ i18n.determiner }
                            nullLabel='&nbsp;'
                            onChange={ v => this.props.changeSetting(this.createParameter('defaultDeterminer', v)) }
                            value={ getSetting(accountUserSettings, 'defaultDeterminer') }
                        />
                        <Select
                            col={ 6 }
                            options={ contacts }
                            label={ i18n.contact }
                            nullLabel='&nbsp;'
                            onChange={ v => this.props.changeSetting(this.createParameter('defaultDeterminerContact', v)) }
                            value={ getSetting(accountUserSettings, 'defaultDeterminerContact') }
                        />
                    </div>
                    <div className='row no-margin padding-top-10-px'>
                        <Select
                            col={ 12 }
                            options={ contributors }
                            label={ i18n.laboratory }
                            nullLabel='&nbsp;'
                            onChange={ v => this.props.changeSetting(this.createParameter('defaultLaboratory', v)) }
                            value={ getSetting(accountUserSettings, 'defaultLaboratory') }
                        />
                    </div>
                    <div className='row no-margin padding-top-10-px'>
                        <Select
                            col={ 6 }
                            options={ this.props.status }
                            label={ i18n.status }
                            nullLabel='&nbsp;'
                            onChange={ v => this.props.changeSetting(this.createParameter('defaultStatus', v)) }
                            value={ getSetting(accountUserSettings, 'defaultStatus') }
                        />
                        <Select
                            col={ 6 }
                            options={ this.props.qualifications }
                            label={ i18n.qualification }
                            nullLabel='&nbsp;'
                            onChange={ v => this.props.changeSetting(this.createParameter('defaultQualification', v)) }
                            value={ getSetting(accountUserSettings, 'defaultQualification') }
                        />
                    </div>
                    {/* <div className='row no-margin padding-top-10-px'>
                        <Select
                            col={ 6 }
                            options={ [] }
                            label={ i18n.preservationMode }
                            nullLabel='&nbsp;'
                            onChange={ v => this.props.changeSetting(this.createParameter('defaultPreservationMode', v)) }
                            value={ getSetting(accountUserSettings, 'defaultPreservationMode') }
                        />
                        <Select
                            col={ 6 }
                            options={ [] }
                            label={ i18n.secondaryPreservationMode }
                            nullLabel='&nbsp;'
                            onChange={ v => this.props.changeSetting(this.createParameter('defaultSecondaryPreservationMode', v)) }
                            value={ getSetting(accountUserSettings, 'defaultSecondaryPreservationMode') }
                        />
                    </div>
                    <div className='row no-margin padding-top-10-px'>
                        <Select
                            col={ 6 }
                            options={ [] }
                            label={ i18n.clogging }
                            nullLabel='&nbsp;'
                            onChange={ v => this.props.changeSetting(this.createParameter('defaultClogging', v)) }
                            value={ getSetting(accountUserSettings, 'defaultClogging') }
                        />
                        <Select
                            col={ 6 }
                            options={ [] }
                            label={ i18n.cloggingType }
                            nullLabel='&nbsp;'
                            onChange={ v => this.props.changeSetting(this.createParameter('defaultCloggingType', v)) }
                            value={ getSetting(accountUserSettings, 'defaultCloggingType') }
                        />
                    </div>
                    <div className='row no-margin padding-top-10-px'>
                        <Select
                            col={ 12 }
                            options={ [] }
                            label={ i18n.material }
                            nullLabel='&nbsp;'
                            onChange={ v => this.props.changeSetting(this.createParameter('defaultMaterial', v)) }
                            value={ getSetting(accountUserSettings, 'defaultMaterial') }
                        />
                    </div>
                    <div className='row no-margin padding-top-10-px'>
                        <Select
                            col={ 6 }
                            options={ [] }
                            label={ i18n.accreditation }
                            nullLabel='&nbsp;'
                            onChange={ v => this.props.changeSetting(this.createParameter('defaultAccreditation', v)) }
                            value={ getSetting(accountUserSettings, 'defaultAccreditation') }
                        />
                        <Select
                            col={ 6 }
                            options={ [] }
                            label={ i18n.approval }
                            nullLabel='&nbsp;'
                            onChange={ v => this.props.changeSetting(this.createParameter('defaultApproval', v)) }
                            value={ getSetting(accountUserSettings, 'defaultApproval') }
                        />
                    </div>
                    <div className='row no-margin padding-top-10-px'>
                        <Select
                            col={ 12 }
                            options={ [] }
                            label={ i18n.protocol }
                            nullLabel='&nbsp;'
                            onChange={ v => this.props.changeSetting(this.createParameter('defaultProtocol', v)) }
                            value={ getSetting(accountUserSettings, 'defaultProtocol') }
                        />
                    </div>
                    <div className='row no-margin padding-top-10-px'>
                        <Select
                            col={ 6 }
                            options={ [] }
                            label={ i18n.development }
                            nullLabel='&nbsp;'
                            onChange={ v => this.props.changeSetting(this.createParameter('defaultDevelopment', v)) }
                            value={ getSetting(accountUserSettings, 'defaultDevelopment') }
                        />
                        <Select
                            col={ 6 }
                            options={ [] }
                            label={ i18n.trust }
                            nullLabel='&nbsp;'
                            onChange={ v => this.props.changeSetting(this.createParameter('defaultTrust', v)) }
                            value={ getSetting(accountUserSettings, 'defaultTrust') }
                        />
                    </div> */}
                    <div className='row no-margin padding-top-10-px'>
                        {/* <Select
                            col={ 6 }
                            options={ [] }
                            label={ i18n.seasonality }
                            nullLabel='&nbsp;'
                            onChange={ v => this.props.changeSetting(this.createParameter('defaultSeasonality', v)) }
                            value={ getSetting(accountUserSettings, 'defaultSeasonality') }
                        /> */}
                        <Select
                            col={ 6 }
                            options={ this.props.supports }
                            label={ i18n.support }
                            nullLabel='&nbsp;'
                            onChange={ v => this.props.changeSetting(this.createParameter('defaultSupport', v)) }
                            value={ getSetting(accountUserSettings, 'defaultSupport') }
                        />
                    </div>
                    <div className='row no-margin padding-top-10-px'>
                        <NumberField col={ 6 } title={ i18n.creationMarginImportData } value={ getSetting(accountUserSettings, 'creationMarginImportData') }
                            onChange={ (v) => this.props.changeSetting(this.createParameter('creationMarginImportData', v)) }
                        />
                        <Select
                            col={6}
                            options={timezones}
                            label={i18n.timezone}
                            nullLabel='&nbsp;'
                            onChange={v => this.props.changeSetting(this.createParameter('usedTimezone', v))}
                            value={getSetting(accountUserSettings, 'usedTimezone')}
                        />
                    </div>
                    <div className='row no-margin padding-bottom-1 padding-top-10-px'>
                        <Checkbox col={ 6 } label={i18n.resumptionDataSiteRegardlessLocation}
                            checked={ ['True', '1', 'OUI'].includes(getSetting(accountUserSettings, 'resumptionDataSiteRegardlessLocation')) }
                            onChange={(e) => this.props.changeSetting(this.createParameter('resumptionDataSiteRegardlessLocation', e ? '1' : '0'))}
                        />
                        <Checkbox col={ 6 } label={i18n.displayFollowUpTableReverseOrder}
                            checked={ ['True', '1', 'OUI'].includes(getSetting(accountUserSettings, 'displayFollowUpTableReverseOrder')) }
                            onChange={(e) => this.props.changeSetting(this.createParameter('displayFollowUpTableReverseOrder', e ? '1' : '0'))}
                        />
                    </div>
                    <div className='row no-margin padding-bottom-1'>
                        <Checkbox col={ 6 } label={i18n.showOmnidiaCode}
                            checked={ ['True', '1', 'OUI'].includes(getSetting(accountUserSettings, 'showOmnidiaCode')) }
                            onChange={(e) => this.props.changeSetting(this.createParameter('showOmnidiaCode', e ? '1' : '0'))}
                        />
                        <Checkbox col={ 6 } label={i18n.viewMuseumNaturalHistoryCode}
                            checked={ ['True', '1', 'OUI'].includes(getSetting(accountUserSettings, 'viewMuseumNaturalHistoryCode')) }
                            onChange={(e) => this.props.changeSetting(this.createParameter('viewMuseumNaturalHistoryCode', e ? '1' : '0'))}
                        />
                    </div>
                    <div className='row no-margin padding-bottom-1'>
                        <Checkbox col={6} label={i18n.showCASCode} checked={['True', '1', 'OUI'].includes(getSetting(accountUserSettings, 'showCASCode'))}
                            onChange={(e) => this.props.changeSetting(this.createParameter('showCASCode', e ? '1' : '0'))}
                        />
                        <Checkbox col={6} label={i18n.showSISEAUCode} checked={['True', '1', 'OUI'].includes(getSetting(accountUserSettings, 'showSISEAUCode'))}
                            onChange={(e) => this.props.changeSetting(this.createParameter('showSISEAUCode', e ? '1' : '0'))}
                        />
                    </div>
                    <div className='row no-margin'>
                        <Checkbox
                            col={6}
                            label={i18n.displayAdvancedStatistics}
                            checked={getSetting(accountUserSettings, 'displayAdvancedStatistics') === '1'}
                            onChange={(e) => this.props.changeSetting(this.createParameter('displayAdvancedStatistics', e ? '1' : '0'))}
                        />
                    </div>
                </div>
            </div>
        )
    }

    render() {
        return (
            <div className='card col s12 no-padding no-margin'>
                <AccordionMUI defaultExpanded>
                    <AccordionSummaryMUI>
                        <Grid container alignItems='center' justifyContent='space-between'>
                            <Grid item xs={8}>
                                <span style={{ fontSize: '1.3rem' }}>
                                    {i18n.qualityOptions}
                                </span>
                            </Grid>
                        </Grid>
                    </AccordionSummaryMUI>
                    <AccordionDetailsMUI sx={{ padding: 0 }}>
                        {this.getContent()}
                    </AccordionDetailsMUI>
                </AccordionMUI>
            </div>
        )
    }
}

UserOptionQualityPanel.propTypes = getPropTypes(propsToFetch, {
    accountUser: PropTypes.instanceOf(DtoUser),
    accountUserSettings: PropTypes.arrayOf(PropTypes.instanceOf(DtoAccountSettings)),
    changeSetting: PropTypes.func,
})

const mapStateToProps = (store) => getMapStateToProps(propsToFetch, {
    accountUser: store.AccountReducer.accountUser,
})

export default connect(mapStateToProps)(UserOptionQualityPanel)
