import { MATERIEL_TYPES } from '../constants/MaterielConstants'

export default class MaterielTypeDto {
    constructor(obj) {
        const { link = '', code = '' } = MATERIEL_TYPES.find(t => obj.code === t.id) || {}
        this.id = obj.code
        this.label = obj.label
        this.code = code
        this.link = link
    }
}