'use strict'
import { createSlice } from '@reduxjs/toolkit'
import CampaignThunk from 'campaign/actions/CampaignThunk'
import PiezometryThunk from 'piezometry/actions/PiezometryThunk'
import DtoPiezometryStationMeasure from 'station/dto/piezometer/DtoPiezometryStationMeasure'
import AppStore from 'store/AppStore'
import CampaignDto from '../../campaign/dto/CampaignDto'
import DtoCampaignProgress from '../../campaign/dto/DtoCampaignProgress'
import DtoStationCampaign from '../../campaign/dto/DtoStationCampaign'
import DtoKeyFigure from '../../station/dto/DtoKeyFigure'
import DtoMeasureMethod from '../../station/dto/DtoMeasureMethod'
import { arrayOf, createIndex, updateIfExists } from '../../utils/StoreUtils'
import PiezometryAction from '../actions/PiezometryAction'
import DtoParametrageDataType from '../dto/DtoParametrageDataType'
import DtoPiezometer from '../dto/DtoPiezometer'
import DtoPiezometerContributorLink from '../dto/DtoPiezometerContributorLink'
import DtoPiezometerLight from '../dto/DtoPiezometerLight'
import DtoPiezometerListSpecific from '../dto/DtoPiezometerListSpecific'
import DtoPiezometerReferent from '../dto/DtoPiezometerRefrent'
import DtoPiezometerWithCampaignsAndEvents from '../dto/DtoPiezometerWithCampaignsAndEvents'
import NetworkPiezoLinkDto from '../dto/NetworkPiezoLinkDto'
import PiezometerAccessibilitieDto from '../dto/PiezometerAccessibilitieDto'
import PiezometerAllMeasureDto from '../dto/PiezometerAllMeasureDto'
import DtoPiezometerChartLandmarks from '../dto/situation/DtoPiezometerChartLandmarks'
import DtoPiezometrySituation from '../dto/situation/DtoPiezometrySituation'
import DtoPiezometrySituations from '../dto/situation/DtoPiezometrySituations'
import DtoPiezoSituationDays from '../dto/situation/DtoPiezoSituationDays'

// https://redux.js.org/tutorials/fundamentals/part-8-modern-redux#using-createslice

export const initialState = {
    piezometryKeyfigures: [],
    piezometers: [],
    piezometersLight: [],
    piezosIndex: {},
    piezosIndexCode: {},
    piezometerListSpecific: [],
    piezometersWithCampaignsAndEvents: [],
    piezometrySituation: [],
    piezometrySituations: [],
    piezometryDataTypes: [],
    piezometryMaxSituation: [],
    piezometrySampleSituation: [],
    piezometryRawSituation: [],
    piezometryTypeSituation: [],
    piezometryDaysSituation: [],
    cachePiezometrySituation: null,
    piezometersAccessibilities: [],
    piezometersContributors: [],
    networkPiezoLink: [],
    piezometryMeasureModes: [],
    piezometerChartLandmarks: [],
    piezometryCampaigns: [],
    piezometryCampaignsProgress: [],
    piezometryCampaignStations: [],
    piezometersReferents: [],
    piezometersOperators: [],
    piezoSituationLastMeasures: [],
}

const store = createSlice({
    name: 'piezometry',
    initialState,
    reducers: {
        createPiezometer: (state, action) => {
            state.piezometers = [...state.piezometers, new DtoPiezometer(action.payload)]
        },
        receivePiezometerKeyFigures: (state, action) => {
            state.piezometryKeyfigures = action.payload.map(el => new DtoKeyFigure(el))
        },
        receiveAllPiezometers: (state, action) => {
            state.piezometers = action.payload.map(piezo => new DtoPiezometer(piezo))
        },
        receiveAllPiezometersLight: (state, action) => {
            const piezos = action.payload.map(piezo => new DtoPiezometerLight(piezo))
            state.piezometersLight = piezos
            state.piezosIndex = createIndex(piezos)
            state.piezosIndexCode = createIndex(piezos, 'code')
        },
        receivePiezometerListSpecific: (state, action) => {
            state.piezometerListSpecific = action.payload.map(piezo => new DtoPiezometerListSpecific(piezo))
        },
        deletePiezometer: (state, action) => {
            state.piezometers = state.piezometers.filter(p => p.id !== action.payload)
        },
        receivePiezometrySituation: (state, action) => {
            state.piezometrySituation = action.payload.map(e => new PiezometerAllMeasureDto(e))
        },
        receivePiezometryMaxSituation: (state, action) => {
            state.piezometryMaxSituation = action.payload.map(e => new DtoPiezometrySituations(e))
        },
        receivePiezometrySampleSituation: (state, action) => {
            state.piezometrySampleSituation = action.payload.map(e => new DtoPiezometrySituations(e))
        },
        receivePiezometryRawSituation: (state, action) => {
            state.piezometryRawSituation = action.payload.map(e => new DtoPiezometrySituations(e))
        },
        receivePiezoSituationLastMeasures: (state, action) => {
            state.piezoSituationLastMeasures = action.payload.map(m => new DtoPiezometryStationMeasure(m))
        },
        receivePiezometryTypeSituation: (state, action) => {
            state.piezometryTypeSituation = action.payload.map(e => new DtoPiezometrySituations(e))
        },
        receivePiezometryDaysSituation: (state, action) => {
            state.piezometryDaysSituation = action.payload.map(e => new DtoPiezoSituationDays(e))
        },
        cachePiezoSituation: (state, action) => {
            state.cachePiezometrySituation = action.payload
        },
        refreshOnePiezoSituation: (state, action) => {
            state.piezometryTypeSituation = updateIfExists(state.piezometryTypeSituation, new DtoPiezometrySituations(action.payload?.types), 'piezometerId')
            state.piezometryDaysSituation = updateIfExists(state.piezometryDaysSituation, new DtoPiezoSituationDays(action.payload?.days), 'piezoId')
        },
        receivePiezometrySituations: (state, action) => {
            state.piezometrySituations = action.payload.map(e => new DtoPiezometrySituations(e))
        },
        receivePiezometryDataTypes: (state, action) => {
            state.piezometryDataTypes = action.payload.map(e => new DtoParametrageDataType(e))
        },
        receiveAllPiezometersAccessibilities: (state, action) => {
            state.piezometersAccessibilities = action.payload.map(e => new PiezometerAccessibilitieDto(e))
        },
        receiveAllPiezometersContributors: (state, action) => {
            state.piezometersContributors = action.payload.map(e => new DtoPiezometerContributorLink(e))
        },
        receiveAllPiezometersReferents: (state, action) => {
            state.piezometersReferents = action.payload.map(e => new DtoPiezometerReferent(e))
        },
        receiveAllPiezometersOperators: (state, action) => {
            state.piezometersOperators = action.payload.map(e => new DtoPiezometerReferent(e))
        },
        receivePiezometryMeasureModes: (state, action) => {
            state.piezometryMeasureModes = action.payload.map(e => new DtoMeasureMethod(e))
        },
        receivePiezometerStationDataCampaignsEventsRead: (state, action) => {
            state.piezometersWithCampaignsAndEvents = action.payload.map(e => new DtoPiezometerWithCampaignsAndEvents(e))
        },
        receivePiezometryCampaigns: (state, action) => {
            state.piezometryCampaigns = action.payload.map(e => new CampaignDto(e))
        },
        receivePiezometryCampaignsProgress: (state, action) => {
            state.piezometryCampaignsProgress = action.payload.map(e => new DtoCampaignProgress(e))
        },
        receivePiezometryCampaignsStations: (state, action) => {
            state.piezometryCampaignStations = action.payload.map(e => new DtoStationCampaign(e))
        },
        receiveNetworkPiezoLink: (state, action) => {
            state.networkPiezoLink = action.payload.map(e => new NetworkPiezoLinkDto(e))
        },
        receivePiezometryChartLandmark: (state, action) => {
            state.piezometerChartLandmarks = action.payload.map(e => new DtoPiezometerChartLandmarks(e))
        },
        resetPiezometryRawSituation: (state) => {
            state.piezometryRawSituation = []
        },
    },
    extraReducers: {
        [PiezometryThunk.fetchPiezometersLight.fulfilled]: (state, action) => {
            state.piezometersLight = action.payload.map(piezo => new DtoPiezometerLight(piezo))
        },
        [CampaignThunk.fetchPiezometryCampaigns.fulfilled]: (state, action) => {
            state.piezometryCampaigns = action.payload.map(e => new CampaignDto(e))
        },
        [CampaignThunk.fetchPiezometryCampaignsProgress.fulfilled]: (state, action) => {
            state.piezometryCampaignsProgress = action.payload.map(e => new DtoCampaignProgress(e))
        },
        [PiezometryThunk.fetchPiezometryRawSituation.fulfilled]: (state, action) => {
            state.piezometryRawSituation = action.payload.map(e => new DtoPiezometrySituations(e))
        },
    },
})

export const PiezometryActionConstant = store.actions
export default store.reducer

export const PiezometryStoreInfos = () => {
    const appStore = AppStore.getState()
    return {
        piezometersWithCampaignsAndEvents: {
            fetch: PiezometryAction.fetchPiezometersWithCampaignsAndEvents,
            store: appStore.PiezometryReducer.piezometersWithCampaignsAndEvents,
            propType: arrayOf(DtoPiezometerWithCampaignsAndEvents),
        },
        piezometers: {
            fetch: PiezometryAction.fetchPiezometers,
            store: appStore.PiezometryReducer.piezometers,
            propType: arrayOf(DtoPiezometer),
        },
        piezometryKeyfigures: {
            fetch: PiezometryAction.fetchKeyFigures,
            store: appStore.PiezometryReducer.piezometryKeyfigures,
            propType: arrayOf(DtoKeyFigure),
        },
        piezometrySituation: {
            fetch: PiezometryAction.fetchPiezometrySituation,
            store: appStore.PiezometryReducer.piezometrySituation,
            propType: arrayOf(DtoPiezometrySituation),
        },
        piezometrySituations: {
            fetch: PiezometryAction.fetchPiezometrySituations,
            store: appStore.PiezometryReducer.piezometrySituations,
            propType: arrayOf(DtoPiezometrySituations),
        },
    }
}