import React from 'react'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import { connect } from 'react-redux'
import { arrayOf, getSandreList } from '../../../../utils/StoreUtils'
import DtoSandreCode from '../../../../referencial/dto/DtoSandreCode'
import { SANDRE } from '../../../../referencial/constants/ReferencialConstants'
import SieauAction from '../../../../components/sieau/SieauAction'
import Row from '../../../../components/react/Row'
import NumberField from '../../../../components/forms/NumberField'
import DtoCaptureStation from '../dto/DtoCaptureStation'
import Select from '../../../../components/forms/Select'
import { getVisitSandreSelectChange } from '../../../../utils/VisitUtils'

const CaptureEquipmentsPanel = ({
    onChange = () => { },
    onChangeVisit = () => { },
    capture = {},
    readMode = false,
    sandreCodes = [],
}) => {
    const onChangeCaptureEquipments = (value) => {
        onChange({
            link_equipments: [
                {
                    idStation: capture.idStation,
                    ...capture.link_equipments[0],
                    ...value,
                },
            ],
        })
    }

    const equipments = capture.link_equipments[0] || {}

    return (
        <Row className='padding-top-1 padding-bottom-1'>
            <Row>
                <NumberField
                    col={ 6 }
                    value={ equipments.numberPumps }
                    title={ i18n.numberPumps }
                    onChange={ (v) => {
                        onChangeCaptureEquipments({ numberPumps: v })
                        onChangeVisit({ previousValue: equipments.numberPumps, newValue: v, field: i18n.numberPumps })
                    } }
                    readMode={ readMode }
                />
                <NumberField
                    col={ 6 }
                    value={ equipments.nominalFlow }
                    title={ i18n.nominalFlow }
                    onChange={ (v) => {
                        onChangeCaptureEquipments({ nominalFlow: v })
                        onChangeVisit({ previousValue: equipments.nominalFlow, newValue: v, field: i18n.nominalFlow })
                    } }
                    floatValue
                    readMode={ readMode }
                />
            </Row>
            <Row>
                <NumberField
                    col={ 6 }
                    value={ equipments.hmt }
                    title={ i18n.hmt }
                    onChange={ (v) => {
                        onChangeCaptureEquipments({ hmt: v })
                        onChangeVisit({ previousValue: equipments.hmt, newValue: v, field: i18n.hmt })
                    } }
                    floatValue
                    readMode={ readMode }
                />
                <Select
                    col={ 6 }
                    value={ equipments.pumpType }
                    label={ i18n.pumpType }
                    keyValue='code'
                    options={ getSandreList(sandreCodes, SANDRE.INSTALLATIONS_TYPEPOMPE) }
                    onChange={ (v) => {
                        onChangeCaptureEquipments({ pumpType: v })
                        onChangeVisit(getVisitSandreSelectChange(sandreCodes, SANDRE.INSTALLATIONS_TYPEPOMPE, i18n.pumpType, equipments.pumpType, v))
                    } }
                    integerValue
                    readMode={ readMode }
                />
            </Row>
            <Row>
                <NumberField
                    col={ 6 }
                    value={ equipments.depthCrepine }
                    title={ i18n.depthCrepine }
                    onChange={ (v) => {
                        onChangeCaptureEquipments({ depthCrepine: v })
                        onChangeVisit({ previousValue: equipments.depthCrepine, newValue: v, field: i18n.depthCrepine })
                    } }
                    floatValue
                    readMode={ readMode }
                />
                <NumberField
                    col={ 6 }
                    value={ equipments.dewateringColDiamExt }
                    title={ i18n.dewateringColDiamExt }
                    onChange={ (v) => {
                        onChangeCaptureEquipments({ dewateringColDiamExt: v })
                        onChangeVisit({ previousValue: equipments.dewateringColDiamExt, newValue: v, field: i18n.dewateringColDiamExt })
                    } }
                    floatValue
                    readMode={ readMode }
                />
            </Row>
            <Row>
                <Select
                    col={ 6 }
                    value={ equipments.dewateringColNature }
                    label={ i18n.dewateringColNature }
                    keyValue='code'
                    options={ getSandreList(sandreCodes, SANDRE.COLONNEEXHAURE_MATERIAU) }
                    onChange={ (v) => {
                        onChangeCaptureEquipments({ dewateringColNature: v })
                        onChangeVisit(getVisitSandreSelectChange(sandreCodes, SANDRE.COLONNEEXHAURE_MATERIAU, i18n.dewateringColNature, equipments.dewateringColNature, v))
                    } }
                    integerValue
                    readMode={ readMode }
                />
            </Row>
            <Row>
                <Select
                    col={ 6 }
                    value={ equipments.dewateringColType }
                    label={ i18n.dewateringColType }
                    keyValue='code'
                    options={ getSandreList(sandreCodes, SANDRE.INSTALLATIONS_TYPECOLONNEEXHAURE) }
                    onChange={ (v) => {
                        onChangeCaptureEquipments({ dewateringColType: v })
                        onChangeVisit(getVisitSandreSelectChange(sandreCodes, SANDRE.INSTALLATIONS_TYPECOLONNEEXHAURE, i18n.dewateringColType, equipments.dewateringColType, v))
                    } }
                    integerValue
                    readMode={ readMode }
                />
                <Select
                    col={ 6 }
                    value={ equipments.typeDetectionLevel }
                    label={ i18n.typeDetectionLevel }
                    keyValue='code'
                    options={ getSandreList(sandreCodes, SANDRE.INSTALLATIONS_TYPEDETECTION) }
                    onChange={ (v) => {
                        onChangeCaptureEquipments({ typeDetectionLevel: v })
                        onChangeVisit(getVisitSandreSelectChange(sandreCodes, SANDRE.INSTALLATIONS_TYPEDETECTION, i18n.typeDetectionLevel, equipments.typeDetectionLevel, v))
                    } }
                    integerValue
                    readMode={ readMode }
                />
            </Row>
            <Row>
                <NumberField
                    col={ 6 }
                    value={ equipments.depthProbe }
                    title={ i18n.depthProbe }
                    onChange={ (v) => {
                        onChangeCaptureEquipments({ depthProbe: v })
                        onChangeVisit({ previousValue: equipments.depthProbe, newValue: v, field: i18n.depthProbe })
                    } }
                    floatValue
                    readMode={ readMode }
                />
            </Row>
        </Row>
    )
}

CaptureEquipmentsPanel.propTypes = {
    capture: PropTypes.instanceOf(DtoCaptureStation),
    sandreCodes: arrayOf(DtoSandreCode),
    readMode: PropTypes.bool,
    onChange: PropTypes.func,
    onChangeVisit: PropTypes.func,
    setPopup: PropTypes.func,
}

const mapStateToProps = (store) => ({
    sandreCodes: store.ReferencialReducer.sandreCodes,
})

const mapDispatchTopProps = {
    setPopup: SieauAction.setPopup,
}

export default connect(mapStateToProps, mapDispatchTopProps)(CaptureEquipmentsPanel)
