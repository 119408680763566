export default class DtoSelection {
    constructor(obj) {
        this.id = obj.code
        this.code = obj.code
        this.name = obj.name
        this.listType = obj.listType
        this.parentCode = obj.parentCode
        this.default = obj.default
        this.parameters = obj.parameters

        this.users = obj.users
        this.administrators = obj.administrators
    }
}