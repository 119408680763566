module.exports = {
    maxGoogleFetch: 20,
    applicationName: 'SIEau',
    sandreParameterLink: 'http://www.sandre.eaufrance.fr/urn.php?urn=urn%3Asandre%3Adonnees%3APAR%3AFRA%3Acode%3A${ parameter }%3A%3A%3Areferentiel%3A2%3Ahtml',
    sandreParameterDefaultLink: 'http://www.sandre.eaufrance.fr/jeu-de-donnees/param%C3%A8tres',
    sandreHydrogeologicalEntityLink: 'https://id.eaufrance.fr/EntiteHydroGeol/',
    sandreHydrogeologicalEntityDefaultLink: 'https://bdlisa.eaufrance.fr/carte',
    sandreWatershedDefaultLink: 'http://www.sandre.eaufrance.fr/jeu-de-donnees/bassin-versant-rnde',
    sandreWatershedLevel1Link: 'https://id.eaufrance.fr/RegionHydro_Carthage2017/',
    sandreWatershedLevel2Link: 'https://id.eaufrance.fr/SecteurHydro_Carthage2017/',
    sandreWatershedLevel3Link: 'https://id.eaufrance.fr/SousSecteurHydro_Carthage2017/',
    sandreWatershedLevel4Link: 'https://id.eaufrance.fr/ZoneHydro_Carthage2017/',
    sandreFractionDefaultLink: 'http://www.sandre.eaufrance.fr/jeu-de-donnees/fraction-analys%C3%A9e',
    sandreFractionLink: 'http://id.eaufrance.fr/fan/',
    sandreNetworkDefaultLink: 'http://www.sandre.eaufrance.fr/atlas/srv/fre/catalog.search#/metadata/cf1a18df-bc82-4b7f-b6f2-a39237dc6b67',
    sandreNetworkLink: 'http://www.sandre.eaufrance.fr/urn.php?urn=urn:sandre:donnees:DC:FRA:code:${ network }:::referentiel:3.1:html',
    sandreWatermassDefaultLink: 'http://www.sandre.eaufrance.fr/notice-doc/masses-deau',
    sandreWatermassLink: 'https://id.eaufrance.fr/MasseDEauPlanDEau_VEDL2019/',
    sandreMarineEnvironmentLink: 'https://id.eaufrance.fr/MasseDEauCotiere_VEDL2019/', // ou si ne fonctionne pas => https://id.eaufrance.fr/MasseDEauTransition_VEDL2019/
    sandreGroundwaterPointLink: 'https://id.eaufrance.fr/MasseDEauSouterraine_VEDL2019/',
    sandreWatercourseLink: 'https://id.eaufrance.fr/MasseDEauRiviere_VEDL2019/',
    sandreUnitDefaultLink: 'http://id.eaufrance.fr/urf/',
    sandreUnitAllLink: 'http://www.sandre.eaufrance.fr/atlas/srv/fre/catalog.search#/metadata/dbae73ac-7118-4424-866e-c9a0980ee519',
    sandreSupportDefaultLink: 'http://id.eaufrance.fr/sup/',
    sandreSupportAllLink: 'http://www.sandre.eaufrance.fr/atlas/srv/fre/catalog.search#/metadata/d12064b7-3dd9-42e2-945c-14aca6aa3662',
    sandreContributorDefaultLink: 'http://www.sandre.eaufrance.fr/Rechercher-une-donnee-d-un-jeu?keyword=&ss_item_code=&ss_lbref_sandre=Intervenants',
    sandreContributorLink: 'http://id.eaufrance.fr/int/',
    sandreMethodDefaultLink: 'http://www.sandre.eaufrance.fr/atlas/srv/fre/catalog.search#/metadata/9bac10a7-f0cd-4560-b52f-f755a9b28fd5',
    sandreMethodLink: 'http://id.eaufrance.fr/met/',
    sandreTaxonLink: 'http://www.sandre.eaufrance.fr/urn.php?urn=urn:sandre:donnees:APT:FRA:code:',
    inerisParameterLink: 'http://www.ineris.fr/substances/fr/substance/cas/',
    inerisParameterDefaultLink: 'http://www.ineris.fr/substances/fr/search/index',
    echaParameterLink: 'https://echa.europa.eu/fr/',
    echaParameterDefaultLink: 'https://echa.europa.eu/fr/',
    eauFranceLink: 'http://www.sandre.eaufrance.fr/urn.php?urn=urn:sandre:donnees:STQ:FRA:code:',
    adesLink: 'http://www.ades.eaufrance.fr/FichePtEau.aspx?code=',
    infoterreLink: 'http://ficheinfoterre.brgm.fr/InfoterreFiche/ficheBss.action?id=',
    adesWatermassLink: 'http://www.ades.eaufrance.fr/fmasseseau/2009/',
    adesHydrogeologicalEntityLink: 'http://www.ades.eaufrance.fr/fhydro/',
    bdlisaHydrogeologicalEntityLink: 'https://bdlisa.eaufrance.fr/carte?entite=',
    bdlisaHydrogeologicalUnitLink: 'https://bdlisa.eaufrance.fr/hydrogeounit/',
    inseeCityLink: 'https://www.insee.fr/fr/metadonnees/cog/commune/COM${ code }-${ lowercaseName }',
    inseeCityDefaultLink: 'https://www.insee.fr/fr/information/2028028',
    inseeActivitySectionLink: 'https://www.insee.fr/fr/metadonnees/nafr2/section/${ codeActivity }?champRecherche=false',
    inseeActivityDivisionLink: 'https://www.insee.fr/fr/metadonnees/cpfr21/division/${ codeActivity }?champRecherche=false',
    inseeActivityGroupLink: 'https://www.insee.fr/fr/metadonnees/nafr2/groupe/${ codeActivity }?champRecherche=false',
    inseeActivityClassLink: 'https://www.insee.fr/fr/metadonnees/nafr2/classe/${ codeActivity }?champRecherche=true',
    inseeActivitySubClassLink: 'https://www.insee.fr/fr/metadonnees/nafr2/sousClasse/${ codeActivity }?champRecherche=false',
    inseeActivitiesDefaultLink: 'https://www.insee.fr/fr/information/2406147',
    georisquesCityLink: 'https://www.georisques.gouv.fr/mes-risques/connaitre-les-risques-pres-de-chez-moi/rapport?codeInsee=',
    georisquesCityDefaultLink: 'https://www.georisques.gouv.fr/mes-risques/connaitre-les-risques-pres-de-chez-moi?choice=commune',
    gesteauCityLink: 'http://www.gesteau.fr/',
    gesteauCityDefaultLink: 'http://www.gesteau.fr/',
    eaufranceCityLink: 'http://www.services.eaufrance.fr/donnees/commune/',
    carmencartoWatermassDefaultLink: 'http://carmen.carmencarto.fr/74/at_entree.map#',
    atlasanteLink: 'https://www.atlasante.fr/infofactures/R52/',
    societeDefaultUrl: 'https://www.societe.com',
    societeUrl: 'https://www.societe.com/cgi-bin/search?champs=',
    helpCenterUrl: 'https://helpcenter.aquasys.fr/',
    supportUrl: 'https://helpcenter.aquasys.fr/hc/',
    orobnat: 'https://www.data.gouv.fr/fr/datasets/resultats-du-controle-sanitaire-de-leau-du-robinet/',
    formationUrl: 'https://helpcenter.aquasys.fr/centre-de-formation/',
    mtesLink: 'http://assainissement.developpement-durable.gouv.fr/fiche.php?code=',
    basolLink: 'https://basol.developpement-durable.gouv.fr/fiche.php?index_sp=',
    cavityGeorisquesLink: 'http://fiches-risques.brgm.fr/georisques/cavite/',
    flowObstructionSandreLink: 'http://www.sandre.eaufrance.fr/geo/ObstEcoul/',
    iaeauApi: 'https://recette.ia-eau.fr/api/',
    sandreAquiferLink: 'http://www.sandre.eaufrance.fr/search/site/aquifer',
    supportPath: 'http://support.aquasys.fr/',
    aquaDBPath: 'https://aquadb.fr/',
    sandrePath: 'https://services.sandre.eaufrance.fr/',
    arrestsPath: 'http://propluvia.developpement-durable.gouv.fr/',
    vigilancesPath: 'https://www.meteoalarm.org/',
    googleMapsPath: 'http://maps.google.fr/maps?f=q&z=15&t=k&hl=fr&q=',
    googleMapsSearchPath: 'https://www.google.fr/maps/search/',
    geoApiPath: 'https://geo.api.gouv.fr/',
    aquasysPath: 'https://www.aquasys.fr/',
    postgrestPath: 'https://aquadb.fr/postgrest/',
    naiadesLink: 'http://www.naiades.eaufrance.fr/acces-donnees#/physicochimie/resultats?stations=',
    STEULink: 'http://assainissement.developpement-durable.gouv.fr/fiche.php?code=',
    apiCartoPath: 'https://apicarto.ign.fr/api/cadastre/parcelle?geom=',
    aqua6BODataPath: 'https://aqua6bo.aquadb.fr/data',
    aqua6BOServicesPath: 'https://aqua6bo.aquadb.fr/services',
    pubChemParameterDefaultLink: 'https://pubchem.ncbi.nlm.nih.gov/',
    pubChemParameterLink: 'https://pubchem.ncbi.nlm.nih.gov/compound/',
    inpnTaxonLink: 'https://inpn.mnhn.fr/espece/cd_nom/',
    roeInstallationLink: 'http://www.deb.developpement-durable.gouv.fr/roe/fiche.php?code=',
    vigilancesMeteoFrancePath: 'https://vigilance.meteofrance.fr/fr',
}
