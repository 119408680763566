
module.exports = {
    SET_CARTO_BACKGROUND: 'SET_CARTO_BACKGROUND',
    CARTO_BACKGROUND: 'CARTO_BACKGROUND',
    homeListOfSelectedStations: 'homeListOfSelectedStations',
    homeListOfSelectedLayers: 'homeListOfSelectedLayers',
    homeListOfLayersOpacity: 'homeListOfLayersOpacity',
    homeSelectedBackground: 'homeSelectedBackground',
    linkContributorsDepartments: 'linkContributorsDepartments',
    TAXON_MAP: {
        PRESENCE_MISSING: '1',
        ABUNDANCE: '2',
        OCCURENCE_FREQUENCY: '3',
    },
}