import React from 'react'
import { Navigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import { shallowEqual, useSelector } from 'react-redux'
import { ADMIN, CONSULT, GEST, TECH } from 'administration/components/user/constants/HabilitationConstants'

const HabilitationComponent = ({
    habilitation: {
        required = [],
        atLeastOne = [],
        requiredRoles = [],
        forbiddenRoles = [],
    },
    Component,
    ...props
}) => {
    const {
        accountUser,
        accountHabilitations,
    } = useSelector(store => ({
        accountUser: store.AccountReducer.accountUser,
        accountHabilitations: store.AccountReducer.accountHabilitations,
    }), shallowEqual)

    const requiredValid = required.every(c => accountHabilitations.some(h => h.habilitation === c))
    const atLeastOneValid = !atLeastOne.length || atLeastOne.some(c => accountHabilitations.some(h => h.habilitation === c))

    const functionTestRole = role => {
        return role === ADMIN && accountUser.isAdmin === '1'
            || role === GEST && accountUser.metadata === '1'
            || role === TECH && accountUser.labo === '1'
            || role === CONSULT && accountUser.consultant === '1'
    }

    const requiredRolesValid = !requiredRoles.length || requiredRoles.some(functionTestRole)
    const forbiddenRolesValid = !forbiddenRoles.some(functionTestRole)

    return requiredValid && atLeastOneValid && requiredRolesValid && forbiddenRolesValid ? <Component {...props} /> : <Navigate to='/unauthorized' />
}

HabilitationComponent.propTypes = {
    Component: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.func,
        PropTypes.array,
    ]).isRequired,
    habilitation: PropTypes.shape({
        required: PropTypes.arrayOf(PropTypes.string),
        atLeastOne: PropTypes.arrayOf(PropTypes.string),
        requiredRoles: PropTypes.arrayOf(PropTypes.string),
        forbiddenRoles: PropTypes.arrayOf(PropTypes.string),
    }).isRequired,
}

export default HabilitationComponent