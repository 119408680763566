export default class DtoCountry {
    constructor(obj = {}) {
        this.countryCode = obj.countryCode
        this.countryNum = obj.countryNum
        this.name = obj.name
        this.nameInter = obj.nameInter
        this.telCodeIndicator = obj.telCodeIndicator
        this.countryIndicator = obj.countryIndicator
        this.rgpdLevel = obj.rgpdLevel
    }
}