import DtoMaterielAssignment from './DtoMaterielAssignment'

export default class DtoStationSubscriptionAssignment extends DtoMaterielAssignment {
    constructor(obj) {
        super(obj)
        this.id = obj.idSubscriptionSituation
        this.idSubscriptionSituation = obj.idSubscriptionSituation
        this.idSubscription = obj.idSubscription
        this.materielId = obj.idSubscription
    }
}