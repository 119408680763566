
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import RadioButtons from '../../../../components/forms/RadioButtons'
import Select from '../../../../components/forms/Select'
import ContributorAction from '../../../../referencial/components/contributor/actions/ContributorAction'
import ContributorDto from '../../../../referencial/components/contributor/dto/ContributorDto'
import Job from '../../../dto/DtoJob'
import { integrationModeHelpIcon } from '../../../utils/ImportUtils'

import MultiContributorsAutocomplete from 'referencial/components/contributor/components/MultiContributorsAutocomplete'
import { getIntegrationModes } from '../../../../utils/JobUtils'
import { exportFile } from '../../../../utils/ExportDataUtil'
import { nbPerPageLabelMedium } from '../../../../referencial/constants/ReferencialConstants'
import { CardTable } from '../../../../components/datatable/NewTable'

class BathingWaterPanel extends Component {
    state = {
    }

    onDeleteCode = (s) => {
        if (this.props.isEditMode) {
            const codes = this.props.job.parameters.parameters.filter((code) => code !== s.credentials)
            this.props.addCode(codes)
        }
    }

    handleChangeDataIntegration = integration => {
        const { parameters } = this.props.job
        const newDataTypes = (() => {
            const data = parameters.dataTypes.filter(d => !d.includes('DATA'))
            if (integration) {
                return [...data, integration]
            }
            return data
        })()
        this.props.onChangeDataTypes(newDataTypes)
    }

    getDataTypes = type => {
        const { parameters } = this.props.job
        if (parameters.dataTypes && parameters.dataTypes.length) {
            return parameters.dataTypes.find(d => d.includes(type)) || parameters.dataTypes.find(d => d.includes('data')) || parameters.dataTypes
        }
        return ''
    }


    onChangeParameter = obj => {
        const newParameter = {
            ...this.state,
            ...obj,
        }
        this.setState(obj)
        this.props.handleChangeParameters([JSON.stringify(newParameter)])
    }

    addCode = () => {
        if (this.props.isEditMode) {
            const code = this.refs.txtAddCode.value
            const exist = this.props.job.parameters.parameters.find(p => p.toLowerCase() == code.toLowerCase())
            if (!code) {
                this.props.toastrError(i18n.youMustEnterAtLeastOneCode)
            } else if (exist) {
                this.props.toastrError(i18n.youHaveAlreadyEnteredThisCode)
            } else {
                this.props.addCode([
                    code,
                    ...this.props.job.parameters.parameters,
                ])
                this.refs.txtAddCode.value = ''
            }
        }
    }

    getStations = () => {
        return this.props.job.parameters.parameters.map((v) => ({ credentials: v }))
    }

    onChangeFilters = (changes) => {
        const parameters = {
            ...this.props.job.parameters,
            filters: [JSON.stringify({
                ...this.getFilters(),
                ...changes,
            })],
        }
        this.props.onChangeJob({ parameters })
    }


    getHistoPurge = () => {
        const parameter = this.props.job.parameters.parameters.length ? JSON.parse(this.props.job.parameters.parameters[0]) : {}
        return parameter.histoPurge
    }
    getFilters = () => {
        const newFilters = this.props.job.parameters.filters.length ? JSON.parse(this.props.job.parameters.filters[0]) : {}
        return { codesType: 'stationCodes', ...newFilters }
    }

    render() {
        const disabled = { disabled: !this.props.isEditMode }
        const filters = this.getFilters()
        const myData =this.getStations()
        const codesTypes = [
            { code: 'stationsMode', name: i18n.stations },
            { code: 'cityMode', name: i18n.cities },
            { code: 'departmentMode', name: i18n.departments },
        ]

        return (
            <div>
                <br />
                <div className='row no-margin valign-wrapper'>
                    <RadioButtons
                        col={ 3 }
                        title={ i18n.codificationType }
                        elements={ codesTypes }
                        onChange={ v => this.onChangeFilters({ selectStationsMode: v }) }
                        selected={ filters.selectStationsMode } {...disabled}
                        data-cy='codeType'
                    />
                    <div className='col s3 no-margin'>
                        <Select
                            value={this.getDataTypes('DATA')}
                            options={getIntegrationModes()}
                            label={i18n.dataIntegration}
                            labelSpan={integrationModeHelpIcon()}
                            onChange={this.handleChangeDataIntegration}
                            data-cy='integrationMode'
                            {...disabled}
                        />
                    </div>
                    <div className='col s3 no-margin'>
                        <MultiContributorsAutocomplete
                            options={this.props.contributors}
                            label={i18n.producer}
                            onChange={(id) => this.onChangeFilters({ producer: id === '' ? undefined : id })}
                            values={filters.producer}
                            keyLabel='labelDisplay'
                            displayWithCode
                            data-cy='jobProduct'
                            {...disabled}
                        />
                    </div>
                    <div className='col s3 no-margin' />
                </div>
                <div>
                    <div className='row valign-wrapper'>
                        <div className='col s3'>
                            <label>{ i18n.addCredential }</label>
                        </div>
                        <div className='input-field col s9 no-padding'>
                            <div className='col s8'>
                                <input id='txtAddCode' type='text' placeholder={ i18n.labelCode }
                                    ref='txtAddCode' data-cy='code'
                                    {...disabled}
                                />
                                <label className='tinyLabel'>{ i18n.labelCode }</label>
                            </div>
                            <a className='waves-effect waves-light btn col offset-s1 s3'
                                onClick={ this.addCode } data-cy='addCode'
                                {...disabled}
                            >
                                { i18n.add }
                            </a>
                        </div>
                    </div>
                    <div className='row valign-wrapper'>
                        <div className='input-field col s12'>
                            <CardTable
                                rows={ myData }
                                headers={ ['credentials']}
                                title={ i18n.credentials }
                                lineActions={[{
                                    icon: 'delete',
                                    onClick: (s) => this.onDeleteCode(s),
                                    displayed: this.props.isEditMode,
                                }]}
                                rowsPerPageOptions={ nbPerPageLabelMedium }
                                data-cy='bathing_table'
                                actions={[{
                                    icon: 'download',
                                    onClick: () => exportFile({
                                        data: [{ ...(myData[0]), headers: ['credentials'] }, ...(myData.slice(1, myData.length))],
                                        titleFile: i18n.credentials,
                                    }),
                                    tooltip: i18n.download,
                                }]}
                                displayHeaders={false}
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

BathingWaterPanel.propTypes = {
    job: PropTypes.instanceOf(Job).isRequired,
    isEditMode: PropTypes.bool,
    onChangeJob: PropTypes.func,
    addCode: PropTypes.func,
    handleChangeParameters: PropTypes.func.isRequired,
    contributors: PropTypes.arrayOf(PropTypes.instanceOf(ContributorDto)),
    fetchContributors: PropTypes.func,
    onChangeDataTypes: PropTypes.func,
    toastrError: PropTypes.func,
}

const mapStateToProps = store => ({
    contributors: store.ContributorReducer.contributors,
})

const mapDispatchToProps = {
    fetchContributors: ContributorAction.fetchContributors,
}

export default connect(mapStateToProps, mapDispatchToProps)(BathingWaterPanel)
