import React from 'react'
import ActionComponent from '../../../../components/ActionComponent'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import ApplicationConf from 'conf/ApplicationConf'
import AppStore from 'store/AppStore'
import NetworkAction from '../actions/NetworkAction'
import i18n from 'simple-react-i18n'
import NetworkDto from '../dto/NetworkDto'
import moment from 'moment'
import 'moment/locale/fr'
import SieauAction from '../../../../components/sieau/SieauAction'
import Input from '../../../../components/forms/Input'
import Checkbox from '../../../../components/forms/Checkbox'
import Textarea from '../../../../components/forms/Textarea'
import { getMapStateToProps, getPropTypes } from '../../../../utils/StoreUtils'
import { updateMaterialize } from '../../../../utils/MaterializeUtils'
import { isEqual, omit, sortBy, template } from 'lodash'
import { push } from '@lagunovsky/redux-react-router'
import {
    PATH_REFERENCIAL,
    PATH_REFERENCIAL_NETWORK,
    PATH_REFERENCIAL_NETWORK_LINK,
    PATH_REFERENCIAL_NETWORK_NEW,
} from '../../../../home/constants/RouteConstants'
import { v4 as uuidv4 } from 'uuid'
import ReplaceModal from '../../ReplaceModal'
import DeleteModal from '../../DeleteModal'
import ReferencialAction from '../../../action/ReferencialAction'
import { sandreNetworkLink } from '../../../../conf/SieauConstants'
import { getUser } from '../../../../utils/SettingUtils'
import HomeAction from 'home/actions/HomeAction'
import logoSandre from 'assets/pictures/logo_sandre.png'

moment.locale('fr')

const propsToFetch = {
    networks: false,
}

class NetworkApp extends ActionComponent {
    constructor(props) {
        super(props)
        this.state = {
            readMode: true,
            network: new NetworkDto({}),
        }
    }

    changeCode = (value) => {
        this.changeStateAttribute('code', parseInt(value))
    }

    changeSandreCode = (value) => {
        this.changeStateAttribute('sandreCode', value)
    }

    changeName = (value) => {
        this.changeStateAttribute('name', value)
    }

    changeMnemonic = (value) => {
        this.changeStateAttribute('mnemonic', value)
    }

    changeFinality = (value) => {
        this.changeStateAttribute('finality', value)
    }

    changeComment = (value) => {
        this.changeStateAttribute('comment', value)
    }

    changeStateAttribute = (attr, value) => {
        const network = Object.assign({}, this.state.network)
        network[attr] = value
        this.setState({ network })
    }

    setReadMode = () => {
        this.setState({ readMode: true })
    }

    setEditMode = () => {
        this.setState({ readMode: false })
    }

    componentWillMount() {
        if (this.props.params.code !== 'new') {
            AppStore.dispatch(NetworkAction.fetchNetwork(this.props.params.code))
            this.setReadMode()
        } else {
            this.setEditMode()
        }
        AppStore.dispatch(HomeAction.setHelpLink('', ''))
        this.toNavbarNetwork(this.props)
    }

    componentWillUnmount() {
        super.componentWillUnmount()
        AppStore.dispatch(NetworkAction.resetNetwork())
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.params.code !== this.props.params.code) {
            AppStore.dispatch(NetworkAction.fetchNetwork(nextProps.params.code))
        } else if (nextProps.params.code === 'new') {
            AppStore.dispatch(SieauAction.forceFetch('title', [
                {
                    title: i18n.referencials,
                    href: PATH_REFERENCIAL,
                },
                {
                    title: i18n.networks,
                    href: PATH_REFERENCIAL_NETWORK,
                },
                {
                    title: i18n.new,
                    href: PATH_REFERENCIAL_NETWORK_NEW,
                },
            ]))
        } else {
            if (!isEqual(nextProps.network, this.props.network)) {
                AppStore.dispatch(SieauAction.forceFetch('title', [
                    {
                        title: i18n.referencials,
                        href: PATH_REFERENCIAL,
                    },
                    {
                        title: i18n.networks,
                        href: PATH_REFERENCIAL_NETWORK,
                    },
                    {
                        title: this.props.params.code + (nextProps.network.name ? ` - ${nextProps.network.name}` : ''),
                        href: PATH_REFERENCIAL_NETWORK_LINK + this.props.params.code,
                    },
                ]))
                this.setState({ network: nextProps.network })
            }
            if (!isEqual(this.props.isEditMode, nextProps.isEditMode) || !isEqual(this.props.network.code, nextProps.network.code) || !isEqual(this.props.networks, nextProps.networks)) {
                this.toNavbarNetwork(nextProps)
            }
        }
    }

    onReplace = newCode => {
        AppStore.dispatch(NetworkAction.replaceNetwork(this.state.network.code, newCode.code))
    }

    getNetworkLinks = props => {
        return [
            {
                href: template(sandreNetworkLink)({ network: props.network.sandreCode }),
                img: logoSandre,
                label: i18n.sandre.toUpperCase(),
            },
        ]
    }

    toNavbarNetwork(props = this.props) {
        const actions = (() => {
            if (props.params.code === 'new' && (!props.network || !props.network.code)) {
                return {
                    save: () => {
                        const networks = sortBy(this.props.networks, ['code'])
                        const code = networks[this.props.networks.length-1].code + 1
                        const network = {
                            ...this.state.network,
                            code,
                        }
                        this.getRequiredFunction(NetworkAction.createNetwork(network))
                    },
                    cancel: () => {
                        AppStore.dispatch(push(PATH_REFERENCIAL_NETWORK))
                        this.setReadMode()
                    },
                }
            }
            if (!this.state.readMode) {
                return {
                    save: () => {
                        this.getRequiredFunction(NetworkAction.updateNetwork(this.state.network, this.state.network.code))
                    },
                    cancel: () => {
                        this.setState({ network: props.network })
                        this.setReadMode()
                    },
                }
            }
            return {
                edit: () => {
                    this.setEditMode()
                },
                deleteCheck: () => {
                    AppStore.dispatch(ReferencialAction.fetchCheckDelete(ApplicationConf.referencial.networkCountDelete(this.props.params.code)))
                    const id = uuidv4()
                    const popup = {
                        id,
                        content: <DeleteModal
                            onDelete={ () => AppStore.dispatch(NetworkAction.deleteNetwork(this.state.network.code)) }
                        />,
                        dismissible: false,
                    }
                    AppStore.dispatch(SieauAction.setPopup(popup))
                },
                replace: () => {
                    AppStore.dispatch(ReferencialAction.fetchCheckDelete(ApplicationConf.referencial.networkCountDelete(this.props.params.code)))
                    const id = uuidv4()
                    const popup = {
                        id,
                        content: <ReplaceModal
                            elements={ props.networks }
                            title={`${i18n.network} [${this.state.network.code}]`}
                            label={i18n.network}
                            onReplace={ this.onReplace }
                        />,
                        dismissible: false,
                    }
                    AppStore.dispatch(SieauAction.setPopup(popup))
                },
                links: this.getNetworkLinks(props),
            }
        })()
        if (getUser().consultant === '1') {
            this.setActions(omit(actions, ['save', 'edit', 'deleteCheck', 'replace']))
        } else {
            this.setActions(actions)
        }
        updateMaterialize()
    }

    getRequiredFunction = (actions) => {
        AppStore.dispatch(actions)
        this.setReadMode()
    }

    componentDidMount() {
        this.toNavbarNetwork()
    }

    componentDidUpdate() {
        this.toNavbarNetwork()
    }

    render() {
        const disabled = { active: !this.state.readMode, disabled: this.state.readMode }
        return (
            <div className='row no-margin'>
                <div id='file' className='col s12'>
                    <div className='card margin-top-0-75-rem'>
                        <div className='card-content'>
                            <div className='row no-margin'>
                                <div className='col s12 m2 l2' />
                                <div className='col s12 m8 l8'>
                                    <div className='row no-margin padding-top-3-px'>
                                        <Input
                                            col={ 3 }
                                            title={ i18n.code }
                                            value={ this.state.network.code }
                                            onChange={ this.changeCode }
                                            disabled
                                        />
                                        <Input
                                            col={ 3 }
                                            title={ i18n.sandreCode }
                                            value={ this.state.network.sandreCode }
                                            onChange={ this.changeSandreCode }
                                            maxLength={ 70 }
                                            { ...disabled }
                                        />
                                        <div className='center padding-top-10-px'>
                                            <Checkbox
                                                col={ 4 }
                                                label={ i18n.dceNetwork }
                                                checked={ this.state.network.dce === '1' }
                                                onChange={ (e) => this.changeStateAttribute('dce', e ? '1' : '0') }
                                                { ...disabled }
                                            />
                                        </div>
                                        <div className='center'>
                                            <Checkbox
                                                col={ 2 }
                                                label={ i18n.forEdit }
                                                checked={ this.state.network.toEdit === '1' }
                                                onChange={ (e) => this.changeStateAttribute('toEdit', e ? '1' : '0') }
                                                { ...disabled }
                                            />
                                        </div>
                                    </div>
                                    <div className='row no-margin padding-top-3-px'>
                                        <Input
                                            col={ 12 }
                                            title={ i18n.name }
                                            value={ this.state.network.name }
                                            onChange={ this.changeName }
                                            maxLength={ 200 }
                                            { ...disabled }
                                        />
                                    </div>
                                    <div className='row no-margin padding-top-3-px'>
                                        <Input
                                            col={ 3 }
                                            title={ i18n.mnemonic }
                                            value={ this.state.network.mnemonic }
                                            onChange={ this.changeMnemonic }
                                            maxLength={ 40 }
                                            { ...disabled }
                                        />
                                    </div>
                                    <div className='row no-margin padding-top-3-px'>
                                        <Textarea
                                            col={ 12 }
                                            title={ i18n.finality }
                                            value={ this.state.network.finality }
                                            onChange={ this.changeFinality }
                                            { ...disabled }
                                        />
                                    </div>
                                    <div className='row no-margin padding-top-7-px'>
                                        <Textarea
                                            col={ 12 }
                                            title={ i18n.comment }
                                            value={ this.state.network.comment }
                                            onChange={ this.changeComment }
                                            { ...disabled }
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

NetworkApp.propTypes = getPropTypes(propsToFetch, {
    params: PropTypes.shape({
        id: PropTypes.string,
    }),
    network: PropTypes.instanceOf(NetworkDto),
    getLink: PropTypes.func,
    isEnableEdit: PropTypes.bool,
    isEditMode: PropTypes.bool,
})

const mapStateToProps = store => getMapStateToProps(propsToFetch, {
    network: store.NetworkReducer.network,
    networks: store.NetworkReducer.networks,
})


export default connect(mapStateToProps)(NetworkApp)
