import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { instanceOf } from '../../../../utils/StoreUtils'
import DtoIAEauModel from '../../../../iaeau/dto/DtoIAEauModel'
import PiezometryAction from '../../../../piezometry/actions/PiezometryAction'
import PluviometryAction from '../../../../pluviometry/actions/PluviometryAction'
import HydrometryAction from '../../../../hydrometry/actions/HydrometryAction'
import { getSiteUrl } from '../../../../utils/mapUtils/SiteTypes'
import { STATION_NAME_ASSOCIATION } from '../../../constants/StationConstants'
import i18n from 'simple-react-i18n'
import { groupBy } from 'lodash'
import Table from '../../../../components/datatable/Table'
import { nbPerPageLabelShort } from '../../../../referencial/constants/ReferencialConstants'
import Card from '../../../../components/card/Card'
import Input from '../../../../components/forms/Input'
import ListComponent from '../../../../components/list/tableList/ListComponent'
import { execByType, hasLocalisationStation } from '../../../../utils/StationUtils'
import { getDistance } from '../../../../utils/mapUtils/CoordinateUtils'
import { minBy } from 'lodash'
import ToastrAction from '../../../../toastr/actions/ToastrAction'


const ModelStepModel = ({
    stationType,
    id, // station id
    selectedModel,
    changeModel,
    changeParams,
    params,
}) => {
    const dispatch = useDispatch()

    const {
        piezometers,
        pluviometers,
        hydrometricStations,
        piezometer,
        hydrometricStation,
    } = useSelector(store => ({
        piezometers: store.PiezometryReducer.piezometersLight,
        pluviometers: store.PluviometryReducer.pluviometers,
        hydrometricStations: store.HydrometryReducer.hydrometricStations,
        piezometer: store.StationReducer.piezometer,
        hydrometricStation: store.HydrometryReducer.hydrometricStation,
    }), shallowEqual)

    const [searchValue, setSearchValue] = useState('')

    useEffect(() => {
        if (!piezometers.length) {
            dispatch(PiezometryAction.fetchPiezometersLight())
        }
        if (!pluviometers.length) {
            dispatch(PluviometryAction.fetchPluviometers())
        }
        if (!hydrometricStations.length) {
            dispatch(HydrometryAction.fetchHydrometricStations())
        }
    }, [])

    const deleteStation = s => changeParams({ stations: (params.stations || []).filter(link => link.typeName !== s.typeName || link.id !== s.id) })
    const addStation = s => {
        changeParams({ stations: [ ...(params.stations || []), s] })
    }

    useEffect(() => {
        if (selectedModel.idScenario === -1 && pluviometers.length && params.stations.length === 1) { // ajout auto des stations ERA5 et MF pour le modèle générique
            const station = execByType(stationType, {
                piezometry: () => piezometer,
                hydrometry: () => hydrometricStation,
            })
            const pluviosWithDistance = pluviometers.filter(i => hasLocalisationStation(i))
                .map(i => {
                    return {
                        ...i,
                        distance: getDistance(station, i) / 1000,
                    }
                })
            const era5Station = minBy(pluviosWithDistance.filter(p => !p.code.startsWith('MF')), 'distance')
            const mf5Station = minBy(pluviosWithDistance.filter(p => p.code.startsWith('MF')), 'distance')
            if (!era5Station) {
                dispatch(ToastrAction.error('Station ERA5 introuvable'))
            }
            if (!mf5Station) {
                dispatch(ToastrAction.error('Station Météo France radar introuvable'))
            }
            changeParams({ stations: [ ...(params.stations || []), { ...era5Station, dataTypes: [1] }, { ...mf5Station, dataTypes: [1] }] })
        }
    }, [params.stations, selectedModel.idScenario, pluviometers])

    const onSearchValue = (station) => {
        return ((station.code || station.id).toString().toLowerCase().includes(searchValue.toLowerCase())
            || (station.name && station.name.toLowerCase().includes(searchValue.toLowerCase())))
    }

    const data = (params.stations || []).map(link => ({
        ...link,
        nullValue: (link.typeName === stationType && link.id == id) || selectedModel.idScenario === -1 ? null : { leftIcon: 'clear', leftIconColor: 'black', color: 'white', onClick: () => deleteStation(link) },
        type: <img src={getSiteUrl(STATION_NAME_ASSOCIATION[link.typeName])} className='tooltipped' data-tooltip={i18n[link.typeName]} style={{ maxHeight: '30px' } } />,
    }))
    const allStations = [].concat(piezometers, pluviometers, hydrometricStations)
        .filter(s => onSearchValue(s) && !data.find(associated => associated.stationLinkedId === s.id && associated.typeName === s.typeName))
    const groupedStations = groupBy(allStations, 'typeName')
    const groupTables = Object.keys(groupedStations).map(typeName => {
        return {
            title: (
                <div className='col s12 valign-wrapper'>
                    <div className='col s2 no-margin'>
                        <img src={getSiteUrl(STATION_NAME_ASSOCIATION[typeName])} style={{ maxHeight: '30px' } } />
                    </div>
                    <div className='col s3 no-margin'>
                        <h5>{ i18n[typeName] }</h5>
                    </div>
                    <div className='col s5 no-margin'>
                        <h5>{ `${groupedStations[typeName].length} ${i18n.stations}` }</h5>
                    </div>
                </div>
            ),
            component: (
                <Table showTitle={ false } condensed data={ groupedStations[typeName] } sortable paging nbPerPageLabel={ nbPerPageLabelShort }
                    onClick={ s => addStation({ id: s.id, code: s.code, typeName: s.typeName, name: s.name }) }
                    type={ { headers: ['code', 'name'] } }
                />
            ),
        }
    })
    return (
        <div>
            <div className='row no-margin'>
                <Table title={ 'Stations sélectionnées' } condensed data={ data } sortable paging nbPerPageLabel={ nbPerPageLabelShort }
                    type={ { headers: ['nullValue', 'type', 'code', 'name'] } } onClick={ () => {} }
                />
            </div>
            { !selectedModel.idScenario && (
                <div className='row no-margin'>
                    <Card title={ i18n.addStation }>
                        <div className='row padding-top-1 no-margin'>
                            <Input col={ 8 } tooltip={ i18n.searchThenEnter } title={ i18n.search }
                                value={ searchValue }
                                onEnterKeyPress={ setSearchValue }
                            />
                        </div>
                        <div className='row no-margin'>
                            <ListComponent tables={ groupTables } accordion/>
                        </div>
                    </Card>
                </div>
            ) }
        </div>

    )
}

ModelStepModel.propTypes = {
    stationType: PropTypes.string,
    id: PropTypes.string,
    selectedModel: instanceOf(DtoIAEauModel),
    setSelectedModel: PropTypes.func,
    changeParams: PropTypes.func,
    params: PropTypes.shape({}),
}

export default ModelStepModel