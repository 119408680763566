import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import ActionComponent from 'components/ActionComponent'
import i18n from 'simple-react-i18n'
import HomeAction from 'home/actions/HomeAction'
import ExportAction from 'export/actions/ExportAction'
import ToastrAction from 'toastr/actions/ToastrAction'
import WaitAction from 'wait/WaitAction'
import { getFullDate, shortenHumanize } from 'utils/DateUtil'
import {
    EXPORT_JOB_STATUS,
} from 'export/constants/ExportConstants'
import Table from 'components/datatable/Table'
import StationAction from 'station/actions/StationAction'
import DtoExportJob from 'station/dto/DtoExportJob'
import { orderBy } from 'lodash'
import { getCurrentLang } from 'utils/LangUtils'
import { nbPerPageLabel } from 'referencial/constants/ReferencialConstants'
import { getDuration, getLoadingBar, getStatusIcon, getStatusLabel } from 'utils/ExportJobUtils'
import moment from 'moment'
import { nFormatter } from 'utils/NumberUtil'
import { componentHasHabilitations } from 'utils/HabilitationUtil'
import { H_AGRI_SURVEYS_DASHBOARD } from 'account/constants/AccessRulesConstants'
import { push } from '@lagunovsky/redux-react-router'


class SurveyExportApp extends ActionComponent {
    isCheckingProgress = false

    state = {
        exportType: null,
        model: 0,
        tmpFilter: {
            startDate: null,
            endDate: null,
        },
        selectStationIsOpen: false,
        selectedStations: [],
        dataType: 0,
    }

    componentDidMount() {
        if (!componentHasHabilitations(H_AGRI_SURVEYS_DASHBOARD)) { // A modifier quand react-router sera à jour
            this.props.push('/unauthorized')
            return
        }
        this.setTitle()
        this.regularProgressUpdate()
    }

    setTitle = () => {
        const { survey } = this.props
        const { params } = this.props
        this.props.setTitle([{
            title: i18n.planning,
            href: 'planning',
        }, {
            title: i18n.surveys,
            href: 'planning/surveys',
        }, {
            title: `${survey.name || ''} [${survey.year || ''}]`,
            href: `survey/${params.id}`,
        }, {
            title: i18n.exportName,
            href: `survey/${params.id}/export`,
        }])
    }

    recursiveFetchExportJob = () => {
        if (!this.isCheckingProgress) {
            return
        }
        this.props.fetchExportJobsTmp('AGRI').then(response => {
            if (response.some(e => e.status === EXPORT_JOB_STATUS.WAITING || e.status === EXPORT_JOB_STATUS.IN_PROGRESS)) {
                setTimeout(() => this.recursiveFetchExportJob(), 5000)
            } else {
                this.isCheckingProgress = false
            }
        })
    }

    regularProgressUpdate = () => {
        this.isCheckingProgress = true
        this.recursiveFetchExportJob()
    }

    componentWillUnmount() {
        this.isCheckingProgress = false
    }

    render() {
        const data = orderBy(this.props.exportJobsTmp, 'updateDate', 'desc').map(e => ({
            ...e,
            status: { color: 'white', value: getStatusIcon(e.status, 50), setTooltip: () => (<div className='row no-margin valign-wrapper'>{getStatusIcon(e.status, 20)}<div className='padding-left-1'/>{getStatusLabel(e.status)}</div>) },
            type: { value: e.fileType },
            creationDate: { value: getFullDate(e.creationDate), format: 'dd/MM/yyyy HH:mm:ss', cellType: 'date' },
            updateDate: { value: getFullDate(e.updateDate), format: 'dd/MM/yyyy HH:mm:ss', cellType: 'date' },
            author: { value: e.login },
            exportName: { value: i18n[e.exportType] },
            fileLabel: { value: e.filePath },
            progression: { value: getLoadingBar(e.status, e.progress, 'Export/tmp/' + e.filePath) },
            size: { value: nFormatter(e.fileSize) },
            duration: { value: e.status === EXPORT_JOB_STATUS.FINISHED || e.status === EXPORT_JOB_STATUS.ERROR ? shortenHumanize((moment.utc(moment(e.updateDate).diff(moment(e.creationDate))).valueOf() || 0), { language: getCurrentLang()[0] }) : getDuration(e.creationDate) },
        }))
        return (
            <div className='row no-margin padding-1'>
                <Table
                    title={ i18n.exportsInProgress }
                    data={data}
                    type={ { headers: ['status', 'fileLabel', 'duration', 'size', 'creationDate', 'updateDate', 'exportName', 'progression'] } }
                    color
                    sortable
                    paging
                    nbPerPageLabel={ nbPerPageLabel }
                    round
                />
            </div>
        )
    }
}

SurveyExportApp.propTypes = ({
    setTitle: PropTypes.func,
    typeEnvironmentModels: PropTypes.arrayOf(PropTypes.string),
    exportModel: PropTypes.func,
    waitStart: PropTypes.func,
    waitStop: PropTypes.func,
    toastrWarning: PropTypes.func,
    exportJobsTmp: PropTypes.arrayOf(PropTypes.instanceOf(DtoExportJob)),
    fetchExportJobsTmp: PropTypes.func,
    runExportTmp: PropTypes.func,
    push: PropTypes.func,
})

const mapStateToProps = store => ({
    typeEnvironmentModels: store.ExportReducer.typeEnvironmentModels,
    exportJobsTmp: store.StationReducer.exportJobsTmp,
    survey: store.AgriReducer.survey,
})

const mapDispatchToProps = {
    setTitle: HomeAction.setTitle,
    exportModel: ExportAction.exportModel,
    toastrWarning: ToastrAction.warning,
    waitStart: WaitAction.waitStart,
    waitStop: WaitAction.waitStop,
    fetchExportJobsTmp: StationAction.fetchExportJobsTmp,
    runExportTmp: ExportAction.runExportTmp,
    push,
}

export default connect(mapStateToProps, mapDispatchToProps)(SurveyExportApp)
