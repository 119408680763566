import React, { useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import DtoEvent from '../../../../events/dto/DtoEvent'
import { getYear } from '../../../../utils/DateUtil'
import TechniqueEventCard from '../../../../station/components/event/TechniqueEventCard'
import SmallEventCard from '../../../../station/components/event/SmallEventCard'
import { EVENT_TYPES } from '../../../../events/constants/EventsConstants'
import GlobalCardList from '../../../../components/list/cardList/GlobalCardList'
import { groupBy, omit, orderBy } from 'lodash'
import { getEventColor } from '../../../../utils/ColorUtil'
import Select from '../../../../components/forms/Select'
import { i18nize } from '../../../../utils/StringUtil'
import { instanceOf } from '../../../../utils/StoreUtils'
import DtoHydrometricStation from '../../../dto/DtoHydrometricStation'
import { WhiteCard } from '../../../../components/styled/Card'

const Title = ({ stationEvents }) => {
    return (
        <div className='col s10'>
            {stationEvents.length ? `${i18n.events} (${stationEvents.length} ${i18n.elements})` : i18n.noEvents}
        </div>
    )
}

Title.propTypes = {
    stationEvents: PropTypes.arrayOf(DtoEvent),
}

const EventsToolPanelHydro = ({ hydrometricStation }) => {
    const [eventType, setEventType] = useState('')

    const {
        stationEvents,
    } = useSelector(store => ({
        stationEvents: store.EventsReducer.stationEvents,
    }), shallowEqual)


    const getEventCard = event => {
        return event.eventType === 'T' ?
            <TechniqueEventCard
                event={event}
                shortDate
                link={`station/hydrometry/${hydrometricStation.id}/event/`}
            />
            : <SmallEventCard
                event={event}
                shortDate
                link={`station/hydrometry/${hydrometricStation.id}/event/`}
            />
    }

    const events = eventType ? stationEvents.filter(d => d.eventType === eventType) : stationEvents
    const groupedEvents = groupBy(events, d => getYear(d.date))
    const data = Object.keys(groupedEvents).map(key => ({
        title: <div className='valign-wrapper no-margin no-padding'>
            {`${key} (${groupedEvents[key].length} ${i18n.elements})`}
        </div>,
        cards: orderBy(groupedEvents[key], e => e.date || e.eventDate, 'desc').map(e => ({
            color: getEventColor(e.eventType),
            content: getEventCard(e),
        })),
    }))
    return (
        <div>
            <div className='row margin-bottom-1'>
                <Select
                    className='no-margin no-padding'
                    col={12}
                    nullLabel='&nbsp;'
                    value={eventType}
                    options={i18nize(EVENT_TYPES).map(d => omit(d, 'icon'))}
                    label={i18n.eventTypes}
                    onChange={v => setEventType(v)}
                />
            </div>
            <WhiteCard
                className='transparent no-box-shadow'
                maxHeight={600}
                title={(<Title stationEvents={events}/>)}
                round
            >
                <GlobalCardList
                    smallTitle
                    data={data}
                    collapsible
                />
            </WhiteCard>
        </div>
    )
}

EventsToolPanelHydro.propTypes = {
    hydrometricStation: instanceOf(DtoHydrometricStation),
    stationEvents: PropTypes.arrayOf(DtoEvent),
}

export default EventsToolPanelHydro