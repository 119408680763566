import { Grid, TextField } from '@mui/material'
import ProgressBar from 'components/progress/ProgressBar'
import { orderBy } from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import UserAction from '../../administration/components/user/actions/UserAction'
import UserDto from '../../administration/components/user/dto/UserDto'
import Card from '../../components/card/Card'
import Icon from '../../components/icon/Icon'
import HomeAction from '../../home/actions/HomeAction'
import ContactAction from '../../referencial/components/contact/actions/ContactAction'
import ContactDto from '../../referencial/components/contact/dto/ContactDto'
import { getDate } from '../../utils/DateUtil'
import { formatPhone } from '../../utils/StringUtil'
import MessageAction from '../actions/MessageAction'
import DtoMessage from '../dto/DtoMessage'

class ConversationMessages extends Component {
    state = {
        listOfMessagesUser: [],
        message: '',
        dataLoaded: false,
    }

    componentDidMount() {
        const { params, contacts, users } = this.props
        const { login } = params
        this.props.setTitle([
            {
                title: i18n.messages,
                href: '/messages',
            },
            {
                title: login,
                href: `/messages/${login}`,
            },
        ])
        if (!contacts.length) {
            this.props.fetchContacts()
        }
        if (!users.length) {
            this.props.fetchUsers()
        }
        this.getMessages()
    }

    componentDidUpdate() {
        document.getElementById('listOfMessages').scrollTo(0, document.getElementById('listOfMessages').scrollHeight)
    }

    getMessages = () => {
        const { params } = this.props
        const { login } = params
        this.props.fetchMessages(login).then(() => {
            const { listOfMessagesUser } = this.props
            this.setState({
                listOfMessagesUser,
                dataLoaded: true,
            })
        })
    }

    handleChangeMessage = (e) => {
        const value = e.target.value
        this.setState({ message: value })
    }

    getListOfMessages = () => {
        const { listOfMessagesUser } = this.state
        const { params, contacts, users } = this.props
        const { login } = params

        return orderBy(listOfMessagesUser, 'updateDate').map(({ updateLogin, updateDate, message }) => {
            const user = users.find((u) => u.login === updateLogin) || {}
            const contact = contacts.find((c) => c.id === user.contactCode) || {}
            const phone = contact.phoneTel ? `${formatPhone(contact.phoneTel)} - ` : ''
            const mobile = contact.mobile ? `${formatPhone(contact.mobile)} - ` : ''
            const style = updateLogin === login ? {
                backgroundColor: 'white',
                borderRadius: '18px',
            } : {
                backgroundColor: '#e7eaef',
                marginLeft: '26%',
                border: 'solid 0.3em white',
            }
            return (
                <div style={{
                    ...style,
                    width: '70%',
                    borderRadius: '18px',
                    padding: '20px',
                    marginTop: '15px',
                    color: '#606060',
                }}
                >
                    <h5>{`${getDate(updateDate, 'DD/MM/YYYY - HH[h]mm')} - ${updateLogin}${contact?.name ? ` (${contact.name})` : ''}`}</h5>
                    <span style={{ fontSize: '14px', fontStyle: 'italic' }}>{phone} {mobile}</span>
                    <span style={{ fontSize: '14px', fontStyle: 'italic', color: '#5691be' }}>{contact.email || ''}</span>
                    <h6 style={{ whiteSpace: 'pre-wrap' }}>{message}</h6>
                </div>
            )
        })
    }

    sendMessage = () => {
        const { message } = this.state
        if (message) {
            const { login } = this.props.params
            const userMessage = { login, message }
            this.setState({ message: '', dataLoaded: false })
            this.props.sendMessage(userMessage).then(() => {
                this.getMessages()
            })
        }
    }

    render() {
        const { dataLoaded, message } = this.state
        return (
            <div id='contents-app' className='margin-top-1'>
                <div id='listOfMessages' className='row no-margin ' style={{ maxHeight: '74vh', overflowY: 'scroll' }}>
                    <Card maxWidth={1100} className='margin-top-1 transparent no-box-shadow' contentClassName={'transparent'}>
                        {dataLoaded ? this.getListOfMessages() : <ProgressBar indeterminate />}
                    </Card>
                </div>
                <Card maxWidth={1100} className='transparent no-box-shadow' contentClassName={'transparent'} style={{ display: 'flex' }}>
                    <Grid container alignItems='center' justifyContent='center' style={{ marginTop: '30px', border: 'solid 0.3em white', borderRadius: '18px', padding: '0 20px' }}>
                        <TextField
                            name='messageField'
                            multiline={true}
                            placeholder={i18n.yourMessage}
                            value={message}
                            onChange={(e) => this.handleChangeMessage(e)}
                            underlineStyle={{ border: 'none' }}
                            textareaStyle={{ color: '#606060' }}
                            style={{ minWidth: '93%', maxWidth: '93%', width: '90%', paddingRight: '50px', outline: '0', backgroundColor: 'transparent' }}
                            inputProps={{
                                style: {
                                    margin: 0,
                                    minHeight: 40,
                                    fontSize: 18,
                                },
                            }}
                        />
                        <Icon
                            icon='message'
                            style={{ fontSize: '3rem', float: 'right' }}
                            onClick={this.sendMessage}
                        />
                    </Grid>
                </Card>
            </div>
        )
    }
}

const mapStateToProps = store => ({
    listOfMessagesUser: store.MessagesReducer.listOfMessagesUser,
    user: store.UserReducer.user,
    users: store.UserReducer.users,
    contacts: store.ContactReducer.contacts,
})

const mapDispatchToProps = {
    fetchMessages: MessageAction.fetchMessages,
    sendMessage: MessageAction.sendMessage,
    setTitle: HomeAction.setTitle,
    fetchUsers: UserAction.fetchUsers,
    fetchContacts: ContactAction.fetchContacts,
}

ConversationMessages.propTypes = {
    params: PropTypes.shape({
        login: PropTypes.string,
    }),
    fetchMessages: PropTypes.func,
    fetchUsers: PropTypes.func,
    listOfMessagesUser: PropTypes.arrayOf(PropTypes.instanceOf(DtoMessage)),
    sendMessage: PropTypes.func,
    setTitle: PropTypes.func,
    user: PropTypes.instanceOf(UserDto),
    users: PropTypes.arrayOf(PropTypes.instanceOf(UserDto)),
    contacts: PropTypes.arrayOf(PropTypes.instanceOf(ContactDto)),
    fetchContacts: PropTypes.func,
}

export default connect(mapStateToProps, mapDispatchToProps)(ConversationMessages)