import { EVENT_TYPES_CODES } from 'events/constants/EventsConstants'
import { max, upperFirst } from 'lodash'
import moment from 'moment'
import React from 'react'
import { getDateWithHour } from '../../../utils/DateUtil'
import { getMaterielIconValue } from '../../../utils/MaterielUtils'
import { getLabel } from '../../../utils/StoreUtils'
import { EVENT_ACTION } from '../../constants/PiezometryEventConstants'

const centralAction = [
    EVENT_ACTION.MESURE_TENSION_CENTRALE,
    EVENT_ACTION.REMPLACEMENT_PILES_CENTRALE,
    EVENT_ACTION.REINITIALISATION_STATION,
    EVENT_ACTION.FIRMWARE_MIS_A_JOUR,
]

const telecomAction = [
    EVENT_ACTION.MESURE_TENSION_TELETRANSMISSION,
    EVENT_ACTION.REMPLACEMENT_PILES_TELETRANSMISSION,
    EVENT_ACTION.TEST_TRANSMISSION,
]

const sensorAction = [
    EVENT_ACTION.RECALIBRATION_CAPTEUR,
    EVENT_ACTION.REMPLACEMENT_PILES_CAPTEUR,
    EVENT_ACTION.TENSION_PILES_CAPTEUR,
    EVENT_ACTION.DESSICANT_REMPLACE,
    EVENT_ACTION.REMPLACEMENT_MESURE_NIVEAU,
]

const getActualMateriel = (stationType, materielCategory, materielList, props) => {
    const previousSituation = props[`${stationType + upperFirst(materielCategory)}Assignment`].filter(s => !s.situationEndDate || s.situationEndDate > moment().valueOf())
    return previousSituation.map(s => materielList.find(m => m.id === s.materielId)).filter(m => !!m)
}

const isLastEvent = (events = [], event = {}) => {
    const eventsDate = events.map(e => getDateWithHour(e.date, e.eventHour).valueOf())
    const lastDate = max(eventsDate)
    const eventDate = getDateWithHour(event.date, event.eventHour)?.valueOf() || 0
    return events.length ? eventDate >= lastDate : true
}

const getYesOrNo = className => (
    <tr className={className}>
        <th className='no-padding' />
        <th className='no-padding'>
            <div className='valign-wrapper chip' style={{ background: 'green' }}>
                <h5 className='center-align no-margin white-text font-size-17' style={{ 'padding-top': '7px' }}>OUI</h5>
            </div>
        </th>
        <th className='no-padding'>
            <div className='valign-wrapper chip' style={{ background: 'red' }}>
                <h5 className='center-align no-margin white-text font-size-17' style={{ 'padding-top': '7px' }}>NON</h5>
            </div>
        </th>
    </tr>
)

const getTitleCard = (title, materielCategory, materiel, materielTypes) => {
    if (materiel && materiel.id) {
        return (
            <div className='row no-padding card-title active'>
                <div className='valign-wrapper'>
                    <div className='col s2'>
                        <i className={'material-icons medium'}>
                            {getMaterielIconValue(materielCategory)}
                        </i>
                    </div>
                    <div className='col s8'>
                        <h5 className='center'>{title}</h5>
                    </div>
                    <div className='col s2'>
                        <h6 className='right'>
                            {`${getLabel(materielTypes, materiel.materielType)} ${materiel.imei || materiel.serialNumber || ''}`}
                        </h6>
                    </div>
                </div>
            </div>
        )
    }
    return (
        <div className='row no-padding card-title active'>
            <div className='col s12 center'>
                <h5>{title}</h5>
            </div>
        </div>
    )
}

const getActionMaterielType = action => {
    if (centralAction.includes(action)) {
        return 'central'
    }
    if (sensorAction.includes(action)) {
        return 'sensor'
    }
    if (telecomAction.includes(action) || action.includes('TEST_SIGNAL')) {
        return 'telecom'
    }
    return ''
}

const isUpdatableEvent = (event = {}, situations = []) => {
    return !situations.length
        || event.eventType === EVENT_TYPES_CODES.TO_PLAN
        || event.eventType === EVENT_TYPES_CODES.TO_MONITOR
        || event.eventType === EVENT_TYPES_CODES.COMMENT
}

export { getActualMateriel, getYesOrNo, getTitleCard, isLastEvent, getActionMaterielType, isUpdatableEvent }
