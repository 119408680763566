module.exports = {
    PICTURES_EXTENSIONS: ['.jpg', '.JPG', '.jpeg', '.JPEG', '.png', '.PNG', '.gif', '.GIF', '.img', '.IMG', '.jpe', '.JPE', '.bmp', '.BMP', '.tif', '.TIF'],
    DOCUMENTS_EXTENSIONS: ['.xsd', '.doc', '.DOC', '.docx', '.DOCX', '.pdf', '.PDF', '.xls', '.XLS', '.xlsx', '.XLSX', '.xlsm', '.XLSM', '.ods', '.ODS', '.odt', '.ODT', '.zip', '.ZIP', '.xml', '.XML', '.odp', '.ODP', '.ppt', '.PPT', '.pptx', '.PPTX', '.txt', '.TXT', '.sql', '.SQL', '.bat', '.BAT', '.html', '.HTML'],
    WORD_EXTENSIONS: ['doc', 'DOC', 'docx', 'DOCX', 'odt', 'ODT'],
    PDF_EXTENSIONS: ['pdf', 'PDF'],
    POWERPOINT_EXTENSIONS: ['odp', 'ODP', 'ppt', 'PPT', 'pptx', 'PPTX' ],
    EXCEL_EXTENSIONS: ['xls', 'XLS', 'xlsx', 'XLSX', 'xlsm', 'XLSM', 'ods', 'ODS'],
    TEXT_EXTENSIONS: ['txt', 'TXT'],
    DOCUMENT_TYPES: {
        TEXT: 'text/',
        APPLICATION: 'application/',
    },
    PICTURE_TYPES: {
        IMAGE: 'image/',
    },
    PICTURE: 'picture',
    DOCUMENT: 'document',
}