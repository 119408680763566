import React, { useMemo } from 'react'
import i18n from 'simple-react-i18n'
import { H_PERIMETERS_DASHBOARD } from '../account/constants/AccessRulesConstants'
import { componentHasHabilitations } from '../utils/HabilitationUtil'
import FixedSlideNav from '../components/navbar/FixedSlideNav'
import BoundaryError from '../log/components/BoundaryError'
import { Outlet, useParams } from 'react-router-dom'

const PerimetersApp = () => {
    const { id } = useParams()
    const navLinks = useMemo(() => {
        const links = parseInt(id) ? [
            {
                icons: 'dashboard',
                name: i18n.dashboard,
                href: `/perimeter/${id}/dashboard`,
                perimeters: H_PERIMETERS_DASHBOARD,
            }, {
                icons: 'map',
                name: i18n.map,
                href: `/perimeter/${id}/map`,
                perimeters: H_PERIMETERS_DASHBOARD,
            }, {
                icons: 'events',
                name: i18n.events,
                href: `/perimeter/${id}/events`,
                perimeters: H_PERIMETERS_DASHBOARD,
            }, {
                icons: 'perm_media',
                name: i18n.documents,
                href: `/perimeter/${id}/documents`,
                perimeters: H_PERIMETERS_DASHBOARD,
            }] : [{
            icons: 'dashboard',
            name: i18n.dashboard,
            href: '/perimeter/dashboard',
            perimeters: H_PERIMETERS_DASHBOARD,
        }, {
            icons: 'perm_media',
            name: i18n.deeds,
            href: '/perimeter/deeds',
            perimeters: H_PERIMETERS_DASHBOARD,
        }]

        return links.filter(l => componentHasHabilitations(l.perimeters))
    }, [id])

    return (
        <div className='row no-margin'>
            <FixedSlideNav links={navLinks}/>
            <main>
                <div className='row no-margin padding-left-1'>
                    <div className='content-page no-padding fullscreen'>
                        <BoundaryError>
                            <Outlet />
                        </BoundaryError>
                    </div>
                </div>
            </main>
        </div>
    )
}

export default PerimetersApp