import TileLayer from 'ol/layer/Tile'
import TileWMS from 'ol/source/TileWMS'
import Layers from 'utils/mapUtils/ILayers'

class _WMS extends Layers {
    constructor(obj) {
        super()
        this.url = obj.url
        this.params = obj.params
        this.style = obj.style
        this.layerParam = obj.layerParam
        this.opacity = obj.opacity
        this.visible = obj.visible
        this.projection = obj.projection
        this.layer = this.createLayer()
    }

    createLayer() {
        return new TileLayer({
            visible: this.visible,
            opacity: this.opacity || this.opacity === 0 ? this.opacity : 1,
            source: new TileWMS({
                url: this.url,
                projection: this.projection || 'EPSG:3857',
                params: { LAYERS: this.layerParam, TILED: true },
                serverType: 'geoserver',
            }),
        })
    }
    getLayer() {
        return this.layer
    }
}

export default function WMS(...args) {
    return new _WMS(...args)
}
