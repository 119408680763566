/* eslint-disable max-nested-callbacks */
import { Grid2 } from '@mui/material'
import Card from 'components/card/Card'
import PropTypes from 'prop-types'
import { exportPictureIcon } from 'components/echart/EChartUtils'
import Input from 'components/forms/Input'
import Textarea from 'components/forms/Textarea'
import Icon from 'components/icon/Icon'
import Tabs from 'components/Tabs'
import ReactEcharts from 'echarts-for-react'
import React, { useMemo, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { nbPerPageLabel } from 'referencial/constants/ReferencialConstants'
import i18n from 'simple-react-i18n'
import useBoolean from 'utils/customHook/useBoolean'
import { getDate } from 'utils/DateUtil'
import { searchAllCharacters } from 'utils/StringUtil'
import { difference, orderBy, uniq } from 'lodash'
import useActions from 'utils/customHook/useActions'
import useSandreList from 'utils/customHook/useSandreList'
import { NewTable, WrapperBox } from 'components/datatable/NewTable'
import HydrobioAction from 'quality/actions/HydrobioAction'
import ConfirmModal from 'components/modal/ConfirmModal'
import ToastrAction from 'toastr/actions/ToastrAction'
import OperationAction from 'quality/components/operation/actions/OperationAction'
import { HydrobioFaunalDialog } from './HydrobioListDialog'

const TABLE = 'TABLE'
const GRAPH = 'GRAPH'

const TablePanel = () => {
    const dispatch = useDispatch()

    const {
        hydrobioList,
        taxons,
        hydrobioOperation,
        accountUser,
    } = useSelector(store => ({
        hydrobioList: store.OperationReducer.hydrobioList,
        taxons: store.TaxonReducer.taxons,
        hydrobioOperation: store.OperationReducer.hydrobioOperation,
        accountUser: store.AccountReducer.accountUser,
    }), shallowEqual)

    const taxonsDevelopment = useSandreList('TAXONS.DEVELOPPEMENT')
    const taxonsSize = useSandreList(`TAXONS.${hydrobioOperation.support}.TAILLE`)

    const {
        value: isOpenConfirmDelete,
        setFalse: onCloseDelete,
        setTrue: onOpenDelete,
    } = useBoolean(false)
    const {
        value: isEditModalOpen,
        setFalse: onCloseEditModal,
        setTrue: onOpenEditModal,
    } = useBoolean(false)
    const [selectedHydrobioListId, setSelectedHydrobioListId] = useState()

    const specificHeaders = useMemo(() => {
        if (hydrobioOperation.microSampling !== '1') return ['phaseA', 'phaseB', 'phaseC', 'phaseCbis']
        return ['P1', 'P2', 'P3', 'P4', 'P5', 'P6', 'P7', 'P8', 'P9', 'P10', 'P11', 'P12']
    }, [hydrobioOperation.microSampling])

    const [searchValue, setSearchValue] = useState('')
    // const [threshold, setThreshold] = useState()
    // const [selection, setSelection] = useState(-1)
    // const [selectionResults, setSelectionResults] = useState([])

    const listFormated = useMemo(() => {
        return hydrobioList.map(list => {
            const {
                id,
                taxon: taxonCode,

                enumerationA = 0,
                enumerationB = 0,
                enumerationC = 0,
                enumerationC2 = 0,

                enumerationP1 = 0,
                enumerationP2 = 0,
                enumerationP3 = 0,
                enumerationP4 = 0,
                enumerationP5 = 0,
                enumerationP6 = 0,
                enumerationP7 = 0,
                enumerationP8 = 0,
                enumerationP9 = 0,
                enumerationP10 = 0,
                enumerationP11 = 0,
                enumerationP12 = 0,
            } = list
            const taxon = taxons.find(t => t.code === taxonCode)
            return {
                id,
                taxonCode: parseInt(taxonCode),
                taxon: taxon?.labelWithCode || taxonCode,
                phaseA: enumerationA,
                phaseB: enumerationB,
                phaseC: enumerationC,
                phaseCbis: enumerationC2,
                P1: enumerationP1,
                P2: enumerationP2,
                P3: enumerationP3,
                P4: enumerationP4,
                P5: enumerationP5,
                P6: enumerationP6,
                P7: enumerationP7,
                P8: enumerationP8,
                P9: enumerationP9,
                P10: enumerationP10,
                P11: enumerationP11,
                P12: enumerationP12,
                plenty: '',
                development: taxonsDevelopment.find(s => s.code === list.developCode)?.name,
                size: taxonsSize.find(s => s.code === list.sizeCode)?.name,
                searchValue: searchAllCharacters(taxon?.labelWithCode || taxonCode),
            }
        })
    }, [hydrobioList, taxons, taxonsDevelopment, taxonsSize])

    const listFiltered = useMemo(() => {
        const searchValueFormat = searchAllCharacters(searchValue)
        return searchValueFormat ? listFormated.filter(l => l.searchValue.includes(searchValueFormat)) : listFormated
    }, [listFormated, searchValue])

    const selectedHydrobioList = hydrobioList.find(hl => hl.id === selectedHydrobioListId)

    return (
        <>
            <Grid2 container spacing={1} style={{ padding: '10 10 5 10' }}>
                <Grid2 size={4}>
                    <Input
                        title={i18n.search}
                        value={searchValue}
                        onChange={setSearchValue}
                    />
                </Grid2>
                {/* <Grid2 size={4}>
                    <ThresholdSelect
                        selected={threshold}
                        onChange={setThreshold}
                        nullLabel='&nbsp;'
                    />
                </Grid2>
                <Grid2 size={4}>
                    <SelectionSelect
                        onChange={(results, value) => {
                            setSelection(value)
                            setSelectionResults(results)
                        }}
                    />
                </Grid2> */}
            </Grid2>
            <NewTable
                rows={orderBy(listFiltered, 'taxonCode')}
                rowsPerPageOptions={nbPerPageLabel}
                headers={['taxon', ...specificHeaders, 'plenty', 'development', 'size']}
                lineActions={[
                    {
                        icon: 'delete',
                        onClick: ({ id }) => {
                            setSelectedHydrobioListId(id)
                            onOpenDelete()
                        },
                        displayed: accountUser.consultant !== '1',
                    },
                    {
                        icon: 'edit',
                        onClick: ({ id }) => {
                            setSelectedHydrobioListId(id)
                            onOpenEditModal()
                        },
                        displayed: accountUser.consultant !== '1',
                    },
                ]}
                WrapperComponent={WrapperBox}
            />
            <ConfirmModal
                isOpen={isOpenConfirmDelete}
                title={i18n.deletingConfirmation}
                onValidate={() => {
                    const {
                        qualitometer,
                        operation,
                        id,
                    } = selectedHydrobioList
                    dispatch(HydrobioAction.deleteHydrobioList(qualitometer, operation, id))
                        .then(res => {
                            if (res.delete > 0) {
                                dispatch(ToastrAction.success(i18n.elementDeleteSuccess))
                                dispatch(OperationAction.fetchHydrobioList(qualitometer, operation))
                                onCloseDelete()
                                setSelectedHydrobioListId()
                            } else {
                                dispatch(ToastrAction.error(i18n.deleteError + i18n.enumeration))
                            }
                        })
                }}
                onClose={() => {
                    onCloseDelete()
                    setSelectedHydrobioListId()
                }}
            />
            <HydrobioFaunalDialog
                isOpen={isEditModalOpen}
                onClose={onCloseEditModal}
                onValidate={(updatedHydrobioList) => {
                    const {
                        qualitometer,
                        operation,
                        id,
                    } = updatedHydrobioList
                    dispatch(HydrobioAction.updateHydrobioList(qualitometer, operation, id, updatedHydrobioList))
                        .then(res => {
                            if (res.update > 0) {
                                dispatch(ToastrAction.success(i18n.elementUpdateSuccess))
                                dispatch(OperationAction.fetchHydrobioList(qualitometer, operation))
                                onCloseEditModal()
                                setSelectedHydrobioListId()
                            } else {
                                dispatch(ToastrAction.error(i18n.updateError + i18n.enumeration))
                            }
                        })
                }}
                title={i18n.updateEnumeration}
                hydrobioList={selectedHydrobioList}
                microSampling={hydrobioOperation.microSampling === '1'}
            />
        </>
    )
}

const Graph = ({
    selectedTaxon = [],
}) => {
    const {
        hydrobioList,
        taxons,
        hydrobioOperation,
    } = useSelector(store => ({
        hydrobioList: store.OperationReducer.hydrobioList,
        taxons: store.TaxonReducer.taxons,
        hydrobioOperation: store.OperationReducer.hydrobioOperation,
    }), shallowEqual)

    const listFormated = useMemo(() => {
        const listData = hydrobioList.map(({ enumerationA = 0, enumerationB = 0, enumerationC = 0, enumerationC2 = 0, taxon: taxonCode }) => {
            const taxon = taxons.find(({ code }) => taxonCode === code)
            return {
                value: enumerationA + enumerationB + enumerationC + enumerationC2,
                taxon: taxon?.labelWithCode || `<${taxonCode}>`,
                taxonShortLabel: taxon?.latinName || `<${taxonCode}>`,
                taxonCode,
            }
        })
        return orderBy(listData, 'taxonShortLabel')
    }, [hydrobioList, taxons])

    const listFiltered = useMemo(() => {
        return listFormated.filter(l => selectedTaxon.includes(l.taxonCode))
    }, [listFormated, selectedTaxon])

    const options = {
        series: [{
            type: 'bar',
            data: listFiltered,
            itemStyle: {
                normal: {
                    color: 'blue',
                    opacity: 1,
                },
            },
        }],
        xAxis: [{
            type: 'category',
            data: listFiltered.map(({ taxonShortLabel }) => taxonShortLabel),
            axisTick: {
                alignWithLabel: true,
            },
            axisLabel: {
                rotate: 50,
            },
        }],
        yAxis: [{
            type: 'value',
        }],
        tooltip: {
            formatter: ([{ data: { taxon, value } }]) => `${taxon}</br>${value} ${value > 1 ? i18n.enumerations : i18n.enumeration}`,
            trigger: 'axis',
            axisPointer: {
                show: true,
                type: 'shadow',
            },
        },
        grid: {
            top: '8%',
            left: '3%',
            right: '4%',
            bottom: '80',
            containLabel: true,
            height: 550,
        },
        toolbox: {
            right: '4%',
            top: '2%',
            feature: {
                saveAsImage: {
                    title: i18n.export,
                    icon: exportPictureIcon,
                    name: `hydrobio_operation_${getDate(hydrobioOperation.dateStart)}`,
                },
            },
        },
    }
    return (
        <ReactEcharts
            option={options}
            style={{ height: 630 }}
            notMerge
        />
    )
}

Graph.propTypes = {
    selectedTaxon: PropTypes.arrayOf(PropTypes.string),
}

const FilterTab = ({
    selectedTaxon = [],
    setSelectedTaxon = () => {},
}) => {
    const {
        hydrobioList,
        taxons,
    } = useSelector(store => ({
        hydrobioList: store.OperationReducer.hydrobioList,
        taxons: store.TaxonReducer.taxons,
    }), shallowEqual)

    const [searchValue, setSearchValue] = useState('')
    // const [dataType, setDataType] = useState()
    // const [selection, setSelection] = useState(-1)
    // const [selectionResults, setSelectionResults] = useState([])

    const listFormated = useMemo(() => {
        return hydrobioList.map(list => {
            const taxon = taxons.find(t => t.code === list.taxon)
            const isSelected = selectedTaxon.includes(list.taxon)
            return {
                code: list.taxon,
                name: taxon?.latinName,
                checkbox: {
                    value: (
                        <Icon
                            size='small'
                            icon={isSelected ? 'check_box' : 'check_box_outline_blank'}
                        />
                    ),
                },
                searchValue: searchAllCharacters(`${taxon?.latinName || ''} ${list.taxon}`),
                id: list.taxon,
                isSelected,
                colorLine: isSelected ? '#b8d2ff' : '#fff',
            }
        })
    }, [hydrobioList, selectedTaxon, taxons])

    const listFiltered = useMemo(() => {
        const searchValueFormat = searchAllCharacters(searchValue)
        return searchValueFormat ? listFormated.filter(l => l.searchValue.includes(searchValueFormat)) : listFormated
    }, [listFormated, searchValue])

    const isAllCheck = useMemo(() => listFiltered.every(l => l.isSelected), [listFiltered])

    const onClickAllCheck = () => {
        const taxonCodes = listFiltered.map(l => l.id)
        const newSelectedTaxon = isAllCheck ? difference(selectedTaxon, taxonCodes) : uniq([...selectedTaxon, ...taxonCodes])
        setSelectedTaxon(newSelectedTaxon)
    }

    return (
        <>
            {/* <Select
                label={i18n.dataTypes}
                options={[]}
                value={dataType}
                onChange={setDataType}
            />
            <SelectionSelect
                onChange={(results, value) => {
                    setSelection(value)
                    setSelectionResults(results)
                }}
            /> */}
            <Input
                title={i18n.search}
                value={searchValue}
                onChange={setSearchValue}
            />
            <NewTable
                rows={listFiltered}
                rowsPerPageOptions={nbPerPageLabel}
                headers={['code', 'name', { key: 'checkbox', value: (<Icon size='small' icon={isAllCheck ? 'check_box_outline_blank' : 'check_box'} />), onClick: onClickAllCheck, sticky: 'right' }]}
                maxHeight='53vh'
                onClickRow={({ isSelected, id }) => {
                    if (isSelected) {
                        setSelectedTaxon(prev => prev.filter(c => c !== id))
                    } else {
                        setSelectedTaxon(prev => [...prev, id])
                    }
                }}
            />
        </>
    )
}

FilterTab.propTypes = {
    selectedTaxon: PropTypes.arrayOf(PropTypes.string),
    setSelectedTaxon: PropTypes.func,
}

const GraphPanel = () => {
    const {
        hydrobioList,
    } = useSelector(store => ({
        hydrobioList: store.OperationReducer.hydrobioList,
    }), shallowEqual)

    const [selectedTaxon, setSelectedTaxon] = useState(() => hydrobioList.map(l => l.taxon))

    return (
        <Grid2 container spacing={1} style={{ padding: '10' }}>
            <Grid2 size={3}>
                <FilterTab
                    selectedTaxon={selectedTaxon}
                    setSelectedTaxon={setSelectedTaxon}
                />
            </Grid2>
            <Grid2 size={9}>
                <Graph selectedTaxon={selectedTaxon} />
            </Grid2>
        </Grid2>
    )
}

const HydrobioOperationFaunalList = () => {
    const dispatch = useDispatch()

    const {
        hydrobioList,
        taxons,
        accountUser,
        hydrobioOperation,
    } = useSelector(store => ({
        hydrobioList: store.OperationReducer.hydrobioList,
        taxons: store.TaxonReducer.taxons,
        accountUser: store.AccountReducer.accountUser,
        hydrobioOperation: store.OperationReducer.hydrobioOperation,
    }), shallowEqual)

    const taxonsDevelopment = useSandreList('TAXONS.DEVELOPPEMENT')
    const taxonsSize = useSandreList(`TAXONS.${hydrobioOperation.support}.TAILLE`)

    const { value: editMode } = useBoolean(false)
    const [comment, setComment] = useState(hydrobioOperation.resultComment || '')

    const {
        value: isNewModalOpen,
        setFalse: onCloseNewModal,
        setTrue: onOpenNewModal,
    } = useBoolean(false)

    useActions(() => {
        const exportAction = {
            export: () => {
                const headers = hydrobioOperation.microSampling !== '1' ? ['taxonCode', 'taxon', 'phaseA', 'phaseB', 'phaseC', 'phaseCbis', 'plenty', 'development', 'size']
                    : ['taxonCode', 'taxon', 'P1', 'P2', 'P3', 'P4', 'P5', 'P6', 'P7', 'P8', 'P9', 'P10', 'P11', 'P12', 'plenty', 'development', 'size']
                const data = hydrobioList.map(list => {
                    const {
                        taxon: taxonCode,

                        enumerationA = 0,
                        enumerationB = 0,
                        enumerationC = 0,
                        enumerationC2 = 0,

                        enumerationP1 = 0,
                        enumerationP2 = 0,
                        enumerationP3 = 0,
                        enumerationP4 = 0,
                        enumerationP5 = 0,
                        enumerationP6 = 0,
                        enumerationP7 = 0,
                        enumerationP8 = 0,
                        enumerationP9 = 0,
                        enumerationP10 = 0,
                        enumerationP11 = 0,
                        enumerationP12 = 0,
                    } = list
                    const taxon = taxons.find(t => t.code === taxonCode)
                    return {
                        taxonCode,
                        taxon: taxon?.latinName ?? '',
                        phaseA: enumerationA,
                        phaseB: enumerationB,
                        phaseC: enumerationC,
                        phaseCbis: enumerationC2,
                        P1: enumerationP1,
                        P2: enumerationP2,
                        P3: enumerationP3,
                        P4: enumerationP4,
                        P5: enumerationP5,
                        P6: enumerationP6,
                        P7: enumerationP7,
                        P8: enumerationP8,
                        P9: enumerationP9,
                        P10: enumerationP10,
                        P11: enumerationP11,
                        P12: enumerationP12,
                        plenty: '',
                        development: taxonsDevelopment.find(s => s.code === list.developCode)?.name,
                        size: taxonsSize.find(s => s.code === list.sizeCode)?.name,
                    }
                })
                return {
                    data: data.length ? [{ ...data[0], headers }, ...data.slice(1)] : [],
                    exportType: 'xlsx',
                    titleFile: `operation ${getDate(hydrobioOperation.dateStart)}`,
                }
            },
        }
        if (accountUser.consultant === '1') return { ...exportAction }
        if (![4, 10, 11, 13, 27].includes(hydrobioOperation.support)) return { new: onOpenNewModal, ...exportAction }
        return {
            calculateIndexes: {
                qualitometer: hydrobioOperation.qualitometer,
                operations: [hydrobioOperation.id],
                support: hydrobioOperation.support,
            },
            new: onOpenNewModal,
            ...exportAction,
        }
    }, [hydrobioList])

    return (
        <Card className='no-box-shadow' cardStyle={{ paddingTop: '10' }}>
            <div style={{ margin: '0 10 10 10' }}>
                <Textarea
                    title={i18n.comment}
                    value={comment}
                    onChange={setComment}
                    disabled={!editMode}
                    active={editMode}
                />
            </div>
            <Tabs
                defaultTab={TABLE}
                tabs={[
                    {
                        constant: TABLE,
                        label: i18n.table,
                    },
                    {
                        constant: GRAPH,
                        label: i18n.graph,
                    },
                ]}
            >
                {tab => (
                    <>
                        {tab === TABLE && <TablePanel />}
                        {tab === GRAPH && <GraphPanel />}
                    </>
                )}
            </Tabs>
            <HydrobioFaunalDialog
                isOpen={isNewModalOpen}
                onClose={onCloseNewModal}
                onValidate={(newHydrobioList) => {
                    const {
                        qualitometer,
                        operation,
                    } = newHydrobioList
                    dispatch(HydrobioAction.createHydrobioList(qualitometer, operation, newHydrobioList))
                        .then(res => {
                            if (res.insert > 0) {
                                dispatch(ToastrAction.success(i18n.elementCreateSuccess))
                                dispatch(OperationAction.fetchHydrobioList(qualitometer, operation))
                                onCloseNewModal()
                            } else {
                                dispatch(ToastrAction.error(i18n.createError + i18n.enumeration))
                            }
                        })
                }}
                title={i18n.newEnumeration}
                hydrobioList={{ qualitometer: hydrobioOperation.qualitometer, operation: hydrobioOperation.id, id: -1 }}
                microSampling={hydrobioOperation.microSampling === '1'}
            />
        </Card>
    )
}

export default HydrobioOperationFaunalList