import React from 'react'
import Pie from '../echart/series/Pie'
import EChart from '../echart/EChart'
import { hasValue } from '../../utils/NumberUtil'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'

const ProgressCircleChart = ({
    total = 0,
    complete = 0,
    title = '',
    height = 200,
}) => hasValue(complete) && (
    <div className='row no-margin'>
        <EChart options={{
            series: [
                Pie({
                    data: [
                        {
                            value: complete,
                            itemStyle: {
                                normal: {
                                    color: 'orange',
                                },
                            },
                            tooltip: {
                                trigger: 'item',
                                formatter: `${i18n.finalize} : {c} ({d}%)`,
                            },
                        },
                        {
                            value: total - complete,
                            itemStyle: {
                                normal: {
                                    color: 'grey',
                                },
                            },
                            tooltip: {
                                trigger: 'item',
                                formatter: `${i18n.inProgress} : {c} ({d}%)`,
                            },
                            selected: true,
                        },
                    ],
                    selectedMode: 'single',
                    radius: ['65%', '75%'],
                }),
            ],
            customTitle: {
                text: title,
                left: 'center',
                top: 'middle',
            },
            height,
        }}
        />
    </div>
)

ProgressCircleChart.propTypes = {
    complete: PropTypes.number,
    total: PropTypes.number,
    height: PropTypes.number,
    title: PropTypes.string,
}

export default ProgressCircleChart
