import { push } from '@lagunovsky/redux-react-router'
import HomeAction from 'home/actions/HomeAction'
import { isEqual } from 'lodash'
import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { connect, shallowEqual, useDispatch, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import UserAction from '../../administration/components/user/actions/UserAction'
import UserViewDto from '../../administration/components/user/dto/UserViewDto'
import ActionComponent from '../../components/ActionComponent'
import CmsAction from '../../events/actions/CmsAction'
import NotifNewsComponent from '../../events/components/news/NotifNewsComponent'
import CityAction from '../../referencial/components/city/actions/CityAction'
import { getUser } from '../../utils/SettingUtils'
import { PATH_CONTENTS_LINK, PATH_JOB, PATH_REFERENCIAL } from '../constants/RouteConstants'
import TwitterComponent from './contents/TwitterComponent'
import DashboardKeyFiguresPanel from './keyFigures/DashboardKeyFiguresPanel'
import NotifsArrestPanel from './prefectoralArrest/NotifsArrestPanel'
import NotifsVigilancePanel from './vigilance/NotifsVigilancePanel'
import PrefectoralArrestPanel from './prefectoralArrest/PrefectoralArrestPanel'
import VigilancePanel from './vigilance/VigilancePanel'
import QualityCard from 'quality/components/QualityCard'
import PiezometryCard from 'piezometry/components/PiezometryCard'
import HydrometryCard from 'hydrometry/components/HydrometryCard'
import PluviometryCard from 'pluviometry/components/PluviometryCard'
import ProductionUnitCard from 'productionUnit/components/ProductionUnitCard'
import AgriCard from 'agriAdministration/components/AgriCard'
import MapPanel from './MapPanel'
import { STATION_TYPE_NAME } from 'station/constants/StationConstants'
import CMSCategoryDto from 'events/dto/CMSCategoryDto'
import DtoArrest from 'home/dto/DtoArrest'
import DtoVigilance from 'home/dto/DtoVigilance'
import useTitle from 'utils/customHook/useTitle'
import useBoolean from 'utils/customHook/useBoolean'
import MaterielAction from 'materiel/actions/MaterielAction'

class _DashboardNotif extends ActionComponent {
    setComponentNotifications = () => {
        const {
            cmsCategories = [],
            userView = [],
            arrests = [],
            vigilances = [],
        } = this.props

        const contents = cmsCategories
            .filter(category => category.displayOnNotificationBar)
            .map(category => ({
                icon: category.icon,
                label: category.title,
                name: `content${category.id}`,
                color: '#00c7de',
                content: <NotifNewsComponent categoryId={ category.id } />,
                onClickTitle: () => this.props.push(PATH_CONTENTS_LINK + category.id),
            }))
        const actions = []
        const user = getUser()
        const arrestsView = userView.find(v => v.panel === 'arrests')
        if (arrests && arrests.length && arrestsView && arrestsView.visible) {
            actions.push({
                icon: 'warning',
                name: 'arrests',
                label: i18n.arrests,
                color: '#dea015',
                content: <NotifsArrestPanel />,
            })
        }
        const vigilancesView = userView.find(v => v.panel === 'vigilances')
        if (vigilances && vigilances.length && vigilancesView && vigilancesView.visible) {
            actions.push({
                icon: 'warning',
                name: 'vigilances',
                label: i18n.vigilances,
                color: '#159cde',
                content: <NotifsVigilancePanel />,
            })
        }
        if (user.isAdmin === '1') {
            actions.push({
                icon: 'cached',
                name: 'treatments',
                label: i18n.treatments,
                func: () => this.props.push(PATH_JOB),
            })
        }
        if (user.metadata === '1' || user.isAdmin === '1') {
            actions.push({
                icon: 'folder',
                name: 'referentials',
                label: i18n.referencials,
                func: () => this.props.push(PATH_REFERENCIAL),
            })
        }
        this.setNotifActions([ ...contents, ...actions ])
    }

    componentDidMount = () => {
        this.setComponentNotifications()
    }

    componentDidUpdate(prevProps) {
        if (!isEqual(this.props.cmsCategories, prevProps.cmsCategories) || !isEqual(this.props.userView, prevProps.userView)) {
            this.setComponentNotifications()
        }
    }

    render() {
        return null
    }
}

_DashboardNotif.propTypes = {
    userView: PropTypes.arrayOf(PropTypes.instanceOf(UserViewDto)),
    arrests: PropTypes.arrayOf(PropTypes.instanceOf(DtoArrest)),
    vigilances: PropTypes.arrayOf(PropTypes.instanceOf(DtoVigilance)),
    cmsCategories: PropTypes.arrayOf(PropTypes.instanceOf(CMSCategoryDto)),
}

const mapStateToProps = store => ({
    userView: store.UserReducer.userView,
    arrests: store.HomeReducer.arrests,
    vigilances: store.HomeReducer.vigilances,
    cmsCategories: store.EventsReducer.cmsCategories,
})

const mapDispatchToProps = {
    push,
}

const DashboardNotif = connect(
    mapStateToProps,
    mapDispatchToProps,
)(_DashboardNotif)

const Dashboard = () => {
    const dispatch = useDispatch()

    const {
        userView,
        settings,
    } = useSelector(store => ({
        userView: store.UserReducer.userView,
        settings: store.AdministrationReducer.settings,
    }), shallowEqual)

    const {
        value: isLoaded,
        setTrue: setLoadedToTrue,
    } = useBoolean(false)

    useEffect(() => {
        dispatch(UserAction.fetchUserView()).then(setLoadedToTrue)
        dispatch(MaterielAction.fetchMonitoredMateriels())
        dispatch(HomeAction.setHelpLink('', ''))
        dispatch(CmsAction.fetchCMSEvents())
        dispatch(CityAction.fetchCities())
    }, [])

    useTitle(() => [{
        title: i18n.dashboard,
        href: '',
    }], [])

    const qualityView = userView.find(v => v.panel === STATION_TYPE_NAME.quality)
    const piezoView = userView.find(v => v.panel === STATION_TYPE_NAME.piezometry)
    const hydroView = userView.find(v => v.panel === STATION_TYPE_NAME.hydrometry)
    const pluvioView = userView.find(v => v.panel === STATION_TYPE_NAME.pluviometry)
    const prodView = userView.find(v => v.panel === STATION_TYPE_NAME.productionUnit)
    const agriView = userView.find(v => v.panel === 'agriculture')
    // const perimeterView = userView.find(v => v.panel === 'perimeters')
    const mapView = userView.find(v => v.panel === 'map')

    const keyFiguresView = userView.find(v => v.panel === 'keyFigures')
    const arrestsView = userView.find(v => v.panel === 'arrests')
    const vigilancesView = userView.find(v => v.panel === 'vigilances')
    const tweetsView = userView.find(v => v.panel === 'tweets')

    const colSize = (
        (!keyFiguresView || keyFiguresView.visible)
        || (!arrestsView || arrestsView.visible)
        || (!tweetsView || tweetsView.visible)
    ) ? 9 : 12

    return isLoaded && (
        <main>
            <div className='row no-margin'>
                <div className={`col s${colSize}`}>
                    {
                        (!qualityView || qualityView.visible) && <QualityCard />
                    }
                    {
                        (!piezoView || piezoView.visible) && <PiezometryCard />
                    }
                    {
                        (!hydroView || hydroView.visible) && <HydrometryCard />
                    }
                    {
                        (!pluvioView || pluvioView.visible) && <PluviometryCard />
                    }
                    {
                        (!prodView || prodView.visible) && <ProductionUnitCard />
                    }
                    {/* {
                        // A remettre quand le back sera corrigé
                        (!perimeterView || perimeterView.visible) && <PerimetersFolderTable dashboard />
                    } */}
                    {
                        (!agriView || agriView.visible) && <AgriCard />
                    }
                    {
                        (!mapView || mapView.visible) && settings.length && <MapPanel />
                    }
                </div>
                <div className='col s3'>
                    <div className='row no-margin'>
                        {
                            (!keyFiguresView || keyFiguresView.visible) && <DashboardKeyFiguresPanel />
                        }
                    </div>
                    <div className='row no-margin'>
                        {
                            (!arrestsView || arrestsView.visible) && <PrefectoralArrestPanel />
                        }
                    </div>
                    <div className='row no-margin'>
                        {
                            (!vigilancesView || vigilancesView.visible) && <VigilancePanel />
                        }
                    </div>
                    <div className='row no-margin'>
                        <div className='card margin-top-1'>
                            {
                                (!tweetsView || tweetsView.visible) && <TwitterComponent />
                            }
                        </div>
                    </div>
                </div>
            </div>
            <DashboardNotif />
        </main>
    )
}

export default Dashboard
