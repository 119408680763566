import loading from 'assets/pictures/loading.gif'
import { push } from '@lagunovsky/redux-react-router'
import React, { useEffect, useMemo, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import { round, sortBy } from 'lodash'
import PiezometryBookmarksLine from '../../home/components/graph/PiezometryBookmarksLine'
import Card from '../../components/card/Card'
import Table from '../../components/datatable/Table'
import { getLabel } from '../../utils/StoreUtils'
import { getDate, getDateWithHourString } from '../../utils/DateUtil'
import CampaignPortletPanel from '../../campaign/components/CampaignPortletPanel'
import MonitoredMaterielPortletPanel from '../../materiel/components/dashboard/MonitoredMaterielPortletPanel'
import { getCampaignIcon, getCampaignProgress, getProgressBar } from '../../campaign/utils/CampaignUtils'
import { getColorCampaign } from '../../utils/CampaignUtil'
import ProgressCard from '../../components/card/ProgressCard'
import { componentHasHabilitations } from '../../utils/HabilitationUtil'
import {
    H_MAT_MODULE,
    H_PIEZO_MODULE,
} from '../../account/constants/AccessRulesConstants'
import HabilitationRequired from '../../utils/components/HabilitationRequired'
import moment from 'moment'
import { STATION_TYPE_CONSTANT, STATION_TYPE_NAME } from 'station/constants/StationConstants'
import UserThunk from 'administration/components/user/actions/UserThunk'
import CampaignThunk from 'campaign/actions/CampaignThunk'
import PiezometryThunk from 'piezometry/actions/PiezometryThunk'
import useStateProgress from 'utils/customHook/useStateProgress'
import { PiezometryActionConstant } from 'piezometry/reducers/PiezometryReducer'
import useAccountSetting from '../../utils/customHook/useAccountSetting'
import StationAction from '../../station/actions/StationAction'
import MapSituationPie from '../../station/components/mapSituation/MapSituationPie'
import { StyledFieldSet, StyledLegend } from '../../components/StyledElements'
import { Grid } from '@mui/material'

const panelTable = 'table'
const panelGraph = 'graph'
const piezometryHeaders = ['qualificationTableColor', 'nullValue', 'name', 'nbStations', 'startDate', 'progression', 'referent']

const PiezometryCard = () => {
    const {
        userBookmarks,
        piezometersLight,
        piezometryCampaigns,
        piezometryCampaignsProgress,
        piezometryMaxSituation,
        piezometryRawSituation,
        monitoredMateriels,
        citiesIndex,
        userBookmarksStatus,
        piezometersLightStatus,
        piezometryCampaignsStatus,
        piezometryCampaignsProgressStatus,
        contacts,
    } = useSelector(store => ({
        userBookmarks: store.UserReducer.userBookmarks,
        piezometersLight: store.PiezometryReducer.piezometersLight,
        piezometryCampaigns: store.PiezometryReducer.piezometryCampaigns,
        piezometryCampaignsProgress: store.PiezometryReducer.piezometryCampaignsProgress,
        piezometryMaxSituation: store.PiezometryReducer.piezometryMaxSituation,
        piezometryRawSituation: store.PiezometryReducer.piezometryRawSituation,
        monitoredMateriels: store.MaterielReducer.monitoredMateriels,
        citiesIndex: store.CityReducer.citiesIndex,
        userBookmarksStatus: store.DataManagerReducer.user.userBookmarksStatus,
        piezometersLightStatus: store.DataManagerReducer.piezometry.piezometersLightStatus,
        piezometryCampaignsStatus: store.DataManagerReducer.campaign.piezometryCampaignsStatus,
        piezometryCampaignsProgressStatus: store.DataManagerReducer.campaign.piezometryCampaignsProgressStatus,
        contacts: store.ContactReducer.contacts,
    }), shallowEqual)

    const { progress: progressBar, isLoaded: dataLoaded } = useStateProgress([userBookmarksStatus, piezometersLightStatus, piezometryCampaignsStatus, piezometryCampaignsProgressStatus])

    const mapSituationWidgetObj = useAccountSetting('MAP_SITUATION_DASHBOARD_WIDGET', setting => JSON.parse(setting ?? '{}'))

    const dispatch = useDispatch()
    const [mapSituationStats, setMapSituationStats] = useState([])

    const bookmarks = useMemo(() => {
        const piezoBookmarks = userBookmarks.filter(bk => bk.stationType === 1) // piezo
        return piezoBookmarks.map(b => b.identifiant)
    }, [userBookmarks])

    const foundBookmarks = useMemo(() => {
        return bookmarks.map(code => piezometersLight.find(s => s.code === code)).filter(p => !!p)
    }, [piezometersLight, bookmarks])

    useEffect(() => {
        dispatch(UserThunk.fetchBookmarks())
        dispatch(PiezometryThunk.fetchPiezometersLight())
        dispatch(CampaignThunk.fetchPiezometryCampaigns())
        dispatch(CampaignThunk.fetchPiezometryCampaignsProgress())
    }, [])

    useEffect(() => {
        const piezoMapIds = Object.keys(mapSituationWidgetObj).filter(key => key.startsWith('piezometry') && !!mapSituationWidgetObj[key]).map(k => parseInt(k.replace('piezometry-', '')))
        if (piezoMapIds.length) {
            Promise.all(piezoMapIds.map(id => StationAction.promiseMapSituationStats('piezometry', id))).then(jsonTab => setMapSituationStats(jsonTab.filter(t => t.length)))
        }
    }, [mapSituationWidgetObj])

    useEffect(() => {
        const criterias = { date: moment().valueOf(), nbDays: 30 }
        const ids = foundBookmarks.map(b => b.id)
        dispatch(PiezometryThunk.fetchPiezometryRawSituation({ criterias, piezometerIds: ids }))
    }, [bookmarks, dispatch, foundBookmarks, piezometersLight?.length])

    useEffect(() => () => dispatch(PiezometryActionConstant.resetPiezometryRawSituation()), [])

    const [panelType, setPanelType] = useState(panelTable)

    const campaigns = useMemo(() => piezometryCampaigns.reduce((acc, c) => {
        const {
            nbStation = 0,
            nbStationValidated = 0,
        } = piezometryCampaignsProgress.find(cp => cp.id === c.id) || {}
        if (nbStation !== 0 && nbStationValidated < nbStation && c.statut === 2) {
            return [
                ...acc,
                c,
            ]
        } return acc
    }, []), [piezometryCampaigns, piezometryCampaignsProgress])

    const data = useMemo(() => ({
        stations: piezometersLight,
        bookmarks,
        icon: 'show_chart',
        campaigns: campaigns.map(campaign => {
            const {
                nbStation = 0,
                nbStationValidated = 0,
            } = piezometryCampaignsProgress.find(cp => cp.id === campaign.id) || {}
            const progress = getCampaignProgress(nbStationValidated, nbStation)
            return {
                id: campaign.id,
                qualificationTableColor: {
                    color: getColorCampaign(false, progress, 2),
                },
                nullValue: getCampaignIcon(campaign.campaignType, 'small'),
                name: campaign.name,
                nbStations: nbStation,
                startDate: campaign.beginningApplication,
                progression: getProgressBar(nbStationValidated, nbStation, progress),
                referent: getLabel(contacts, campaign.contactCode, 'mnemonique'),
            }
        }),
        campaignHeader: { headers: piezometryHeaders },
        stationType: STATION_TYPE_CONSTANT.PIEZOMETRY,
        habilitation: componentHasHabilitations(H_PIEZO_MODULE),
        monitoredMateriels: monitoredMateriels.filter(m => m.typeSite === STATION_TYPE_CONSTANT.PIEZOMETRY),
    }), [bookmarks, campaigns, contacts, monitoredMateriels, piezometersLight, piezometryCampaignsProgress])

    const bookmarksStationPanel = useMemo(() => {
        if (!foundBookmarks.length) {
            return null
        }
        const stationResult = sortBy(foundBookmarks.map(station => {
            const cityFound = citiesIndex[station.townCode]
            const foundSituation = piezometryRawSituation.find(s => s.piezometerId == station.id) || piezometryMaxSituation.find(s => s.piezometerId == station.id)
            const colorLastMeasure = foundSituation?.thresholdColor ? foundSituation.thresholdColor : 'white'
            return {
                id: station.id,
                code: station.code,
                city: cityFound ? `${cityFound.name} [${cityFound.code}]` : station.townCode,
                name: station.name,
                historic: foundSituation ? `${getDate(foundSituation.startDate)} ${i18n.to} ${getDateWithHourString(foundSituation.lastMeasureDate, foundSituation.lastMeasureHour)}` : '',
                lastMeasure: {
                    value: foundSituation ? `${round(foundSituation.value, 2)} m` : '',
                    color: ['black', '#000000'].includes(colorLastMeasure) ? 'white' : colorLastMeasure,
                    className: foundSituation && foundSituation.threshold ? 'tooltipped' : '',
                    tooltip: foundSituation && foundSituation.threshold,
                },
                headers: ['code', 'city', 'name', 'historic', 'lastMeasure'],
            }
        }), 'name')
        if (panelType === panelTable) {
            const mapSituationPies = mapSituationStats.map(tab => {
                return (
                    <MapSituationPie stats={tab} st='piezometry' />
                )
            })
            const mapSituationCard = mapSituationPies.length ? (
                <Grid container justifyContent='center' alignItems='center' spacing={3}>
                    {mapSituationPies}
                </Grid>
            ) : undefined
            return (
                <>
                    {mapSituationCard}
                    <Table
                        showNbElements={false}
                        showTitle={false}
                        showIcon={true}
                        headerIcon={(
                            <a>
                                <i className='waves-effect material-icons'>grade</i>
                            </a>
                        )}
                        data={stationResult}
                        actions={[{
                            iconName: 'insert_chart',
                            onClick: () => setPanelType(panelGraph),
                        }]}
                        type={stationResult[0]}
                        link={`station/${STATION_TYPE_NAME.piezometry}`}
                        condensed
                        sortable
                    />
                </>
            )
        }
        return (
            <Card
                title={(
                    <a>
                        <i className='waves-effect material-icons'>grade</i>
                        {i18n.myFavourites} ({foundBookmarks.length} {i18n.elements})
                    </a>
                )}
                actions={[{
                    iconName: 'list',
                    onClick: () => setPanelType(panelTable),
                }]}
            >
                <PiezometryBookmarksLine piezometerBookmarks={foundBookmarks} />
            </Card>
        )
    }, [citiesIndex, foundBookmarks, panelType, piezometryMaxSituation, piezometryRawSituation])

    const bookmarksPanel = useMemo(() => {
        if (data?.bookmarks?.length > 0 && data?.stations?.length > 0) {
            return (
                <div className='row no-margin'>
                    <div className='section no-padding'>
                        <div className='row no-margin'>
                            {bookmarksStationPanel}
                        </div>
                    </div>
                </div>
            )
        }
        return null
    }, [bookmarksStationPanel, data?.bookmarks?.length, data?.stations?.length])

    const campaignsPanel = useMemo(() => {
        if (data.campaigns && data.campaigns.length > 0 && data.campaignHeader) {
            return (
                <div className='row no-margin'>
                    <div className='section no-padding'>
                        <div className='row no-margin'>
                            <CampaignPortletPanel
                                noHeader={true}
                                campaigns={data.campaigns}
                                stationType={STATION_TYPE_NAME.piezometry}
                                tableHeader={data.campaignHeader}
                            />
                        </div>
                    </div>
                </div>
            )
        }
        return null
    }, [data.campaignHeader, data.campaigns])

    if (dataLoaded) {
        if (!(data.habilitation && ((data.bookmarks.length > 0 && data.stations.length > 0) || data.campaigns.length > 0 || data.monitoredMateriels.length > 0 || data?.exploitations?.length > 0)) || !(bookmarksPanel || campaignsPanel)) {
            return null
        }
        return (
            <div className='row no-margin padding-top-1'>
                <Card
                    title={(
                        <a className='waves-effect' onClick={() => dispatch(push(`/${data.redirect || STATION_TYPE_NAME.piezometry}`))}>
                            <i className='material-icons '>{data.icon}</i>
                            {i18n[STATION_TYPE_NAME.piezometry]}
                        </a>
                    )}
                >
                    <div className='padding-1 padding-bottom-0'>
                        {bookmarksPanel}
                        {campaignsPanel}
                        <HabilitationRequired habilitation={H_MAT_MODULE}>
                            <MonitoredMaterielPortletPanel noHeader={true} stationType={data.stationType} />
                        </HabilitationRequired>
                    </div>
                </Card>
            </div>
        )
    }
    return (
        <div className='padding-top-1'>
            <Card>
                <div className='row no-margin center-align padding-top-5'>
                    <img src={loading} />
                    <ProgressCard withoutCard message={i18n.piezometryCardInProgress} progress={progressBar} />
                </div>
            </Card>
        </div>
    )
}

export default PiezometryCard