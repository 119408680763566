import { filter, orderBy, reduce } from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getI18nOrLabel } from 'utils/StringUtil'
import { ALL } from '../../../administration/components/user/constants/ApplicationHabilitationConstants'
import DtoApplicationHabilitation from '../../../administration/components/user/dto/DtoApplicationHabilitation'
import DtoUser from '../../../administration/components/user/dto/DtoUser'
import { getStationTypeFromUserConstants } from '../../../utils/StationUtils'
import DtoAccountHabilitation from '../../dto/DtoAccountHabilitation'

class ModulesAccountPanel extends Component {
    constructor(props) {
        super(props)
    }

    getIcon = (hasHabilitation = false) => hasHabilitation ? ['green', 'done'] : ['red', 'cancel']

    getValue = (value) => this.props.accountHabilitations.some(hab => hab.habilitation === value)

    getData = () => {
        const filterHabilitations = filter(this.props.applicationHabilitations, (o) => {
            return !o.parameter.includes(`_${ALL}`)
        })
        const reduceHabilitations = reduce(filterHabilitations, (a, b) => {
            (a[b.habilitationType] || (a[b.habilitationType] = [])).push(b)
            return a
        }, {})

        const finalList = orderBy(Object.keys(reduceHabilitations).map((b) => {
            return {
                parameter: b,
                title: getI18nOrLabel(getStationTypeFromUserConstants(b)),
                data: reduceHabilitations[b],
            }
        }), (o) => o.data.length, 'asc')

        return finalList.map((b) => {
            const hasHabilitation = this.getValue(b.parameter)
            const result = b.data.map((o) => {
                if (!o.title) {
                    return null
                }
                const icon = this.getIcon(hasHabilitation)
                return (
                    <div className='row no-margin'>
                        <div className='col s1 no-padding'>
                            <i className='material-icons ' style={ { color: icon[0] } }>{ icon[1] }</i>
                        </div>
                        <div className='col s11 no-padding'>
                            { getI18nOrLabel(o.title) }
                        </div>
                    </div>
                )
            })
            return (
                <div className='col l4 m6 s12'>
                    <div className='row no-margin card'>
                        <div className='col s12 center-align card-title active'>
                            { b.title }
                        </div>
                        <div className='col s12'>
                            { result }
                        </div>
                    </div>
                </div>
            )
        })
    }

    render() {
        if (this.props.accountHabilitations.length) {
            return (
                <div className='row no-margin'>
                    <div className='col s12 no-padding'>
                        { this.getData() }
                    </div>
                </div>
            )
        }
        return null
    }
}

ModulesAccountPanel.propTypes = {
    accountUser: PropTypes.instanceOf(DtoUser),
    accountHabilitations: PropTypes.arrayOf(PropTypes.instanceOf(DtoAccountHabilitation)),
    applicationHabilitations: PropTypes.arrayOf(PropTypes.instanceOf(DtoApplicationHabilitation)),
}

const mapStateToProps = (store) => {
    return {
        accountUser: store.AccountReducer.accountUser,
        accountHabilitations: store.AccountReducer.accountHabilitations,
        applicationHabilitations: store.UserReducer.applicationHabilitations,
    }
}
export default connect(mapStateToProps)(ModulesAccountPanel)
