export default class DtoPoint {
    constructor(obj) {
        this.idQualitometer = obj.idQualitometer // idQualitometer: Double
        this.idPoint = obj.idPoint // idPoint: Option[Double] = None
        this.code = obj.code // code: Option[String]
        this.identifiant = obj.identifiant // identifiant: Option[String]
        this.name = obj.name // name: Option[String]
        this.startDate = obj.startDate // startDate: Option[DateTime]
        this.endDate = obj.endDate // endDate: Option[DateTime]
        this.x = obj.x // x: Option[Double]
        this.y = obj.y // y: Option[Double]
        this.projection = obj.projection // projection: Option[Double]
        this.codeSupport = obj.codeSupport // codeSupport: Option[Float]
        this.labelWithCode = `${obj.name || ''}${obj.identifiant ? ` [${obj.identifiant}]` : ''}`
    }
}