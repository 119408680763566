export default class CampaignDto {
    constructor(obj) {
        this.id = obj.id
        this.requestType = obj.requestType
        this.codificationContext = obj.codificationContext
        this.name = obj.name
        this.beginningApplication = obj.beginningApplication
        this.endingApplication = obj.endingApplication
        this.comment = obj.comment
        this.contributorCode = obj.contributorCode
        this.contactName = obj.contactName
        this.recipientCode = obj.recipientCode
        this.payerCode = obj.payerCode
        this.samplerCode = obj.samplerCode
        this.supportCode = obj.supportCode
        this.campaignType = obj.campaignType
        this.authorizedBancarisation = obj.authorizedBancarisation
        this.contactCode = obj.contactCode
        this.updateLogin = obj.updateLogin
        this.updateDate = obj.updateDate
        this.statut = obj.statut
        this.administratorCode = obj.administratorCode
        this.stationType = obj.stationType
        this.marketRef = obj.marketRef
        this.eventCount = obj.eventCount
        this.selections = obj.selections
    }
}