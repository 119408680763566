'use strict'
import {
    RECEIVE_CULTURES_FAMILIES,
    RECEIVE_CULTURES_FAMILY,
    RESET_CULTURES_FAMILIES,
} from '../constants/CulturesFamilyConstants'
import DtoCulturesFamily from '../dto/DtoCulturesFamily'

export const store = {
    culturesFamily: {},
    culturesFamilies: [],
}

export function CulturesFamilyReducer(state = {}, action) {
    switch (action.type) {
        case RECEIVE_CULTURES_FAMILY:
            return {
                ...state,
                culturesFamily: new DtoCulturesFamily(action.culturesFamily),
            }
        case RECEIVE_CULTURES_FAMILIES:
            return {
                ...state,
                culturesFamilies: action.culturesFamilies.map((c) => new DtoCulturesFamily(c)),
            }
        case RESET_CULTURES_FAMILIES:
            return {
                ...state,
                culturesFamily: {},
                culturesFamilies: [],
            }
        default:
            return state
    }
}
