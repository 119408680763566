import React from 'react'
import { Navigate } from 'react-router-dom'
import { getPayload } from 'utils/ActionUtils'
import moment from 'moment'
import HomeAction from 'home/actions/HomeAction'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { URL_WANTED } from 'home/constants/HomeConstants'

const setUrlWanted = () => {
    const wantedUrl = window.location.href || ''
    const pathToPush = wantedUrl.substring(wantedUrl.indexOf('/#/') + 3)
    if (pathToPush && pathToPush !== 'login' && pathToPush !== 'home') {
        localStorage.setItem(URL_WANTED, pathToPush)
    }
}

const AuthenticatedComponent = ({ Component, ...props }) => {
    const dispatch = useDispatch()
    const payload = getPayload()
    if (!payload) {
        setUrlWanted()
        return <Navigate to='/login' />
    }
    const exp = moment(JSON.parse(payload).exp)
    if (moment().isAfter(exp)) {
        setUrlWanted()
        dispatch(HomeAction.logout())
        return <Navigate to='/login' />
    }
    return <Component {...props} />
}

AuthenticatedComponent.propTypes = {
    Component: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
}

export default AuthenticatedComponent