/* eslint-disable camelcase */
import SieauParameterDto from 'administration/dto/SieauParameterDto'
import Select from 'components/forms/Select'
import { transform } from 'ol/proj'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import { getDefaultCoordinates } from 'utils/mapUtils/CoordinateUtils'
import NumberField from '../../../components/forms/NumberField'
import OSM from '../../../utils/mapUtils/layers/OSM'
import OlMap from '../../../utils/mapUtils/Map'
import { getSetting } from '../../../utils/SettingUtils'


const MapAdminPanel = ({
    disabled,
    onChange = () => {},
}) => {
    const {
        applicationSettings,
    } = useSelector(store => ({
        applicationSettings: store.AdministrationReducer.applicationSettings,
    }), shallowEqual)

    const [x, setX] = useState(undefined)
    const [y, setY] = useState(undefined)
    const [zoom, setZoom] = useState(8)
    const [parameters, setParameters] = useState([])
    const [projection, setProjection] = useState(getSetting(applicationSettings, 'TypeProjection'))

    const createParameter = (parameter, value) => {
        return new SieauParameterDto({
            parameter,
            value,
        })
    }

    const changeMap = (value) => {
        const olZoom = value.getView().getZoom()
        const point = transform(value.getView().getCenter(), 'EPSG:3857', 'EPSG:4326')
        const olX = point[0]
        const olY = point[1]
        const zoomParameter = createParameter('defaultMapZoom', olZoom.toString())
        const xParameter = createParameter('defaultMapXCoordinate', olX.toString())
        const yParameter = createParameter('defaultMapYCoordinate', olY.toString())
        const newParameters = [xParameter, yParameter, zoomParameter]
        setX(olX)
        setY(olY)
        setZoom(olZoom)
        setParameters(newParameters)
        onChange([...parameters, createParameter('TypeProjection', projection)])
    }

    const onChangeProj = (value) => {
        setProjection(value)
        onChange([...parameters, createParameter('TypeProjection', value)])
    }

    const getValue = (parameter, defaultValue) => {
        const tmp = getSetting(applicationSettings, parameter)
        if (!tmp) {
            return defaultValue
        }
        return Number(tmp)
    }

    const defaultCoordinate = getDefaultCoordinates()
    const x_coordinate = getValue('defaultMapXCoordinate', defaultCoordinate[0])
    const y_coordinate = getValue('defaultMapYCoordinate', defaultCoordinate[1])
    const zoom_coordinate = getValue('defaultMapZoom', 9)
    const mapProjectionOption = [
        { value: '', label: '' },
        { value: '1', label: i18n.extendedLambertII },
        { value: '2', label: i18n.lambert93 },
        { value: '3', label: i18n.WGS84 },
    ]

    return (
        <div className='row no-margin'>
            <div className='col s12'>
                <div className='row no-margin'>
                    <div className='col s12'>
                        <Select
                            col={3}
                            label={i18n.defaultMapProjection}
                            options={mapProjectionOption}
                            noNullValue
                            keyLabel='label'
                            onChange={v => onChangeProj(v)}
                            value={projection}
                            disabled={disabled}
                        />
                        <NumberField col={ 3 } title={ i18n.longitude } value={ x } disabled/>
                        <NumberField col={ 3 } title={ i18n.latitude } value={ y } disabled/>
                        <NumberField col={ 3 } title={ i18n.zoom } value={ zoom } disabled/>
                    </div>
                </div>
                <div className='row no-margin'>
                    <div className='col s12'>
                        <OlMap
                            mapConf={ {
                                center: [x_coordinate, y_coordinate],
                                zoom: zoom_coordinate,
                                size: 600,
                            } }
                            layers={ [OSM()] }
                            onMoveEnd={ changeMap }
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

MapAdminPanel.propTypes = {
    disabled: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
}

export default MapAdminPanel
