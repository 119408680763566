import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import { isEqual } from 'lodash'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import MultiContributorsAutocomplete from 'referencial/components/contributor/components/MultiContributorsAutocomplete'
import i18n from 'simple-react-i18n'
import CampaignAction from '../../../../campaign/actions/CampaignAction'
import CampaignDto from '../../../../campaign/dto/CampaignDto'
import DatePicker from '../../../../components/forms/DatePicker'
import Input from '../../../../components/forms/Input'
import Select from '../../../../components/forms/Select'
import ContributorAction from '../../../../referencial/components/contributor/actions/ContributorAction'
import ContributorDto from '../../../../referencial/components/contributor/dto/ContributorDto'
import { hasValue } from '../../../../utils/NumberUtil'
import Job from '../../../dto/DtoJob'
import { exportFile } from '../../../../utils/ExportDataUtil'
import { nbPerPageLabelMedium } from '../../../../referencial/constants/ReferencialConstants'
import { CardTable } from '../../../../components/datatable/NewTable'

class ExportEDILABOPanel extends Component {
    constructor(props) {
        super(props)
        this.state = {
            parameters: props.job.parameters.parameters.map(p => JSON.parse(p)),
            onOpenPopup: false,
            paramEDILABO: {},
        }
    }

    componentDidMount = () => {
        if (!this.props.qualityCampaigns.length) {
            this.props.fetchQualityCampaigns('quality')
        }
        if (!this.props.contributors.length) {
            this.props.fetchContributors()
        }
    }

    componentDidUpdate = prevProps => {
        if (!isEqual(prevProps.job, this.props.job)) {
            this.setState({
                parameters: this.props.job.parameters.parameters.map(p => JSON.parse(p)),
            })
        }
    }

    onDeleteCode = (s) => {
        if (this.props.isEditMode) {
            const id = (this.props.qualityCampaigns.find(c => c.name === s.campaign) || {}).id
            const parameters = this.props.job.parameters.parameters.filter(p => JSON.parse(p).idC !== id)
            this.props.addCode(parameters)
        }
    }
    onChangeParameter = obj => {
        const newParameter = {
            ...this.state,
            ...obj,
        }
        this.setState(obj)
        this.props.onChangeParameters([JSON.stringify(newParameter)])
    }

    onChangeCampaign = campaign => {
        const paramEDILABO = {
            ...this.state.paramEDILABO,
            idC: campaign && campaign.id ? campaign.id : null,
            sender: hasValue(campaign.contributorCode) ? parseInt(campaign.contributorCode) : undefined,
            receiver: (() => {
                const samplerCode = hasValue(campaign.samplerCode) ? parseInt(campaign.samplerCode) : undefined
                const recipientCode = hasValue(campaign.recipientCode) ? parseInt(campaign.recipientCode) : undefined
                switch (campaign.requestType) {
                    case '1':
                        return samplerCode
                    case '2':
                        return recipientCode
                    case '3':
                        return recipientCode || samplerCode
                    default:
                        return undefined
                }
            })(),
        }
        this.setState({ paramEDILABO })
    }

    onAddNewExport = () => {
        const {
            paramEDILABO,
        } = this.state
        const parameters = [
            ...this.state.parameters,
            {
                ...paramEDILABO,
                parameters: [],
                stations: [],
            },
        ]
        this.props.onChangeParameters(parameters.map(p => JSON.stringify(p)))
        this.setState({ paramEDILABO: {}, onOpenPopup: false })
    }

    exportData = (parameters) => parameters.map((param) => ({
        campaign: (this.props.qualityCampaigns.find(c => c.id === param.idC) || {}).name,
        sender: (this.props.contributors.find(c => c.id === param.sender) || {}).name,
        receiver: (this.props.contributors.find(c => c.id === param.receiver) || {}).name,
        requestName: param.requestName,
        startDate: moment(param.startDate).format('DD/MM/YYYY'),
        endDate: moment(param.endDate).format('DD/MM/YYYY'),
        headers: ['campaign', 'requestName', 'sender', 'receiver', 'startDate', 'endDate'],
    }))

    render = () => {
        const {
            parameters,
            onOpenPopup,
            paramEDILABO,
        } = this.state
        const {
            contributors,
            qualityCampaigns,
            isEditMode,
            job,
            onChangeJob,
        } = this.props
        const parametersItem = parameters.map(param => ({
            campaign: (qualityCampaigns.find(c => c.id === param.idC) || {}).name,
            sender: (contributors.find(c => c.id === param.sender) || {}).name,
            receiver: (contributors.find(c => c.id === param.receiver) || {}).name,
            requestName: param.requestName,

        }))
        return (
            <div className='row no-margin'>
                <div className='row no-margin col s12 no-padding padding-bottom-1'>
                    <Input
                        col={6}
                        title={i18n.directory}
                        className={'no-padding'}
                        obligatory
                        value={job.parameters.path}
                        onChange={v => onChangeJob({ parameters: { ...job.parameters, path: v } })}
                        disabled={!isEditMode}
                    /></div>
                <div className='row no-margin col s12 padding-top-1'>
                    <CardTable
                        rows={ parametersItem }
                        headers={ ['campaign', 'requestName', 'sender', 'receiver']}
                        title={ i18n.campaigns }
                        lineActions={[{
                            icon: 'delete',
                            onClick: (s) => this.onDeleteCode(s),
                            displayed: this.props.isEditMode,
                        }]}
                        rowsPerPageOptions={ nbPerPageLabelMedium }
                        data-cy='ExportEdilabo_table'
                        actions={[{
                            icon: 'add_box',
                            onClick: () =>this.setState({ onOpenPopup: true }),
                            tooltip: i18n.add,
                            displayed: this.props.isEditMode,
                        },
                        {
                            icon: 'download',
                            onClick: () => exportFile({
                                data: [{ ...(parametersItem[0]), headers: ['campaign', 'requestName', 'sender', 'receiver'] }, ...(parametersItem.slice(1, parametersItem.length))],
                                titleFile: i18n.campaigns,
                            }),
                            tooltip: i18n.download,
                        }]}
                    />
                </div>
                <Dialog
                    onClose={() => this.setState({ paramEDILABO: {}, onOpenPopup: false })}
                    fullWidth
                    maxWidth='lg'
                    open={onOpenPopup}
                >
                    <DialogTitle>{i18n.addCampaignToExport}</DialogTitle>
                    <DialogContent>
                        <div className='row' style={{ minHeight: '40vh' }}>
                            <div className='col s12 padding-top-2'>
                                <Input
                                    col={6}
                                    title={i18n.fileName}
                                    value={paramEDILABO.fileName}
                                    obligatory
                                    onChange={v => this.setState({ paramEDILABO: { ...paramEDILABO, requestName: v } })}

                                />
                                <Select
                                    options={qualityCampaigns}
                                    label={i18n.campaigns}
                                    col={6}
                                    obligatory
                                    onChange={(_, c) => this.onChangeCampaign(c) }
                                    value={hasValue(paramEDILABO.idC) ? paramEDILABO.idC : null}
                                    nullLabel='&nbsp;'
                                    clearFunction
                                    keyValue='id'
                                    keyLabel='name'
                                />
                            </div>
                            <div className='col s12 padding-top-1'>
                                <MultiContributorsAutocomplete
                                    options={contributors}
                                    label={i18n.sender}
                                    col={6}
                                    obligatory
                                    onChange={v => this.setState({ paramEDILABO: { ...paramEDILABO, sender: v } })}
                                    values={hasValue(paramEDILABO.sender) ? paramEDILABO.sender : null}
                                    keyLabel='mnemonique'
                                />
                                <MultiContributorsAutocomplete
                                    options={contributors}
                                    label={i18n.receiver}
                                    col={6}
                                    obligatory
                                    onChange={v => this.setState({ paramEDILABO: { ...paramEDILABO, receiver: v } })}
                                    value={hasValue(paramEDILABO.receiver) ? paramEDILABO.receiver : null}
                                    keyLabel='mnemonique'
                                />
                            </div>
                            <div className='col s12 padding-top-1'>
                                <DatePicker
                                    col={6}
                                    obligatory
                                    title={i18n.startDate}
                                    value={paramEDILABO.startDate}
                                    onChange={(_, v) => this.setState({ paramEDILABO: { ...paramEDILABO, startDate: v } })}
                                />
                                <DatePicker
                                    col={6}
                                    obligatory
                                    title={i18n.endDate}
                                    value={paramEDILABO.endDate}
                                    onChange={(_, v) => this.setState({ paramEDILABO: { ...paramEDILABO, endDate: v } })}
                                />
                            </div>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.setState({ paramEDILABO: {}, onOpenPopup: false })} variant='outlined' >
                            {i18n.close}
                        </Button>
                        <Button onClick={this.onAddNewExport} variant='contained' color='primary'>
                            {i18n.validate}
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        )
    }
}


ExportEDILABOPanel.propTypes = {
    job: PropTypes.instanceOf(Job).isRequired,
    onChangeJob: PropTypes.func.isRequired,
    onChangeParameters: PropTypes.func,
    contributors: PropTypes.arrayOf(PropTypes.instanceOf(ContributorDto)),
    qualityCampaigns: PropTypes.arrayOf(PropTypes.instanceOf(CampaignDto)),
    isEditMode: PropTypes.bool,
    addCode: PropTypes.func,

    fetchQualityCampaigns: PropTypes.func,
    fetchContributors: PropTypes.func,
}

const mapStateToProps = store => ({
    contributors: store.ContributorReducer.contributors,
    qualityCampaigns: store.QualityReducer.qualityCampaigns,
})

const mapDispatchToProps = {
    fetchQualityCampaigns: CampaignAction.fetchQualityCampaigns,
    fetchContributors: ContributorAction.fetchContributors,
}

export default connect(mapStateToProps, mapDispatchToProps)(ExportEDILABOPanel)
