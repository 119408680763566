import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import { useDispatch } from 'react-redux'
import StationAction from 'station/actions/StationAction'
import { hasValue } from 'utils/NumberUtil'
import SuperMultiAutocomplete from '../SuperMultiAutocomplete'

const AssociatedStationSelect = ({
    col,
    stationType,
    stationTypeReturn,
    keyLabel,
    keyValue,
    value,
    options = [],
    onChange = () => {},
    label = '',
    disabled = false,
    displayWithCode = false,
}) => {
    const dispatch = useDispatch()

    const [station, setStation] = useState(value)
    const [associatedStation, setAssociatedStation] = useState([])

    useEffect(() => {
        if (hasValue(station) && stationType) {
            dispatch(StationAction.getAssociatedSites(station, stationType))
                .then(asso => setAssociatedStation(asso.filter(a => a.stationLinkedType === stationTypeReturn).map(a => a.stationLinkedId)))
        } else {
            setAssociatedStation([])
        }
    }, [dispatch, station, stationType, stationTypeReturn])

    useEffect(() => {
        onChange(associatedStation, station)
    }, [associatedStation])

    const fullLabel = useMemo(() => {
        if (!hasValue(station)) {
            return label
        }
        if (associatedStation.length <= 1) {
            return `${label} (${associatedStation.length} ${i18n.station})`
        }
        return `${label} (${associatedStation.length} ${i18n.stations})`
    }, [associatedStation.length, label, station])

    return (
        <SuperMultiAutocomplete
            col={col}
            label={fullLabel}
            options={options}
            onChange={setStation}
            values={station}
            keyLabel={keyLabel}
            keyValue={keyValue}
            disabled={disabled}
            displayWithCode={displayWithCode}
        />
    )
}

AssociatedStationSelect.propTypes = {
    col: PropTypes.number,
    stationType: PropTypes.number,
    stationTypeReturn: PropTypes.number,
    value: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
    ]),
    keyLabel: PropTypes.string,
    keyValue: PropTypes.string,
    label: PropTypes.string,
    disabled: PropTypes.bool,
    options: PropTypes.arrayOf(PropTypes.shape({
        // [keyValue]
        // [keyLabel]
        // ...rest
    })),
    onChange: PropTypes.func, // (list, id) => {}
    displayWithCode: PropTypes.bool,
}

export default AssociatedStationSelect