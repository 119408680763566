import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import ToastrAction from 'toastr/actions/ToastrAction'
import Input from '../../../../components/forms/Input'
import NumberField from '../../../../components/forms/NumberField'
import Select from '../../../../components/forms/Select'
import Textarea from '../../../../components/forms/Textarea'
import Row from '../../../../components/react/Row'
import { SANDRE } from '../../../../referencial/constants/ReferencialConstants'
import DtoSandreCode from '../../../../referencial/dto/DtoSandreCode'
import { getHour } from '../../../../utils/DateUtil'
import { onChangeHour } from '../../../../utils/FormUtils'
import { getSandreList } from '../../../../utils/StoreUtils'
import DtoMeasureMethod from '../../../dto/DtoMeasureMethod'
import SimpleDatePicker from 'components/forms/SimpleDatePicker'

class MeasureMethodModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            measureMethod: { ...props.measureMethod, idStation: props.id },
        }
    }

    componentDidUpdate(prevProps) {
        if (!prevProps.popupIsOpen && this.props.popupIsOpen) {
            $('#descriptionModal').on('click', this.onSave)
        }
    }
    onSave = () => {
        const { measureMethod } = this.state
        if (!measureMethod.startDate) {
            this.props.warning(i18n.pleaseCompleteAllRequiredField)
        } else {
            this.props.saveResult(new DtoMeasureMethod(this.state.measureMethod))
            $('.modal').modal('close')
        }
    }

    onChangeElement = (changes) => this.setState({ measureMethod: { ...this.state.measureMethod, ...changes } })

    render() {
        const { measureMethod } = this.state
        return (
            <div>
                <Row>
                    <SimpleDatePicker
                        col={6}
                        value={measureMethod.startDate}
                        label={i18n.startDate}
                        onChange={v => this.onChangeElement({ startDate: v })}
                        max={measureMethod.endDate}
                        obligatory
                    />
                    <Input
                        col={ 6 }
                        title={ i18n.startHour }
                        value={ getHour(measureMethod.startDate) }
                        onChange={ v => onChangeHour(v, v2 => this.onChangeElement({ startDate: v2 }), { max: measureMethod.endDate }, measureMethod.startDate) }
                    />
                </Row>
                <Row>
                    <SimpleDatePicker
                        col={6}
                        value={measureMethod.endDate}
                        label={i18n.endDate}
                        onChange={v => this.onChangeElement({ endDate: v })}
                        min={measureMethod.startDate}
                    />
                    <Input
                        col={ 6 }
                        title={ i18n.endHour }
                        value={ getHour(measureMethod.endDate) }
                        onChange={ v => onChangeHour(v, v2 => this.onChangeElement({ endDate: v2 }), { min: measureMethod.startDate }, measureMethod.endDate) }
                    />
                </Row>
                <div className='row no-margin padding-top-1'>
                    <Select
                        col={ 6 }
                        options={ getSandreList(this.props.sandreCodes, SANDRE.MEASURE_MODE) }
                        label={ i18n.method }
                        value={ measureMethod.method }
                        onChange={ v => this.onChangeElement({ method: v }) }
                    />
                    <Select
                        col={6}
                        options={getSandreList(this.props.sandreCodes, SANDRE.MEASURE_PERIODICITY)} label={i18n.periodicity}
                        keyLabel='comment'
                        value={measureMethod.periodicity}
                        onChange={v => this.onChangeElement({ periodicity: v })}
                    />
                </div>
                <div className='row no-margin padding-top-1'>
                    <div className={this.props.typeName !== 'piezometry' ? 'hide' : ''}>
                        <NumberField
                            col={ 6 }
                            value={ measureMethod.depth }
                            title={ i18n.depth }
                            onChange={ v => this.onChangeElement({ depth: v }) }
                            floatValue
                        />
                    </div>
                    <NumberField
                        col={ 6 }
                        value={ measureMethod.measureOffset }
                        title={ i18n.compensation }
                        onChange={ v => this.onChangeElement({ measureOffset: v }) }
                        floatValue
                    />
                </div>
                <div className='row no-margin padding-top-1'>
                    <NumberField
                        col={ 6 }
                        value={ measureMethod.precision }
                        title={ i18n.precision }
                        onChange={ v => this.onChangeElement({ precision: v }) }
                        floatValue
                    />
                    <Select
                        col={ 6 }
                        options={ getSandreList(this.props.sandreCodes, SANDRE.PERIODICITE_ACQUISITION) }
                        label={ i18n.periodicityAcqui }
                        value={ measureMethod.periodicityAcqui }
                        onChange={ v => this.onChangeElement({ periodicityAcqui: v }) }
                    />
                </div>
                <div className='row no-margin padding-top-1'>
                    <Textarea
                        col={ 12 }
                        title={ i18n.comment }
                        value={ measureMethod.comment }
                        onChange={ v => this.onChangeElement({ comment: v }) }
                    />
                </div>
            </div>
        )
    }
}

MeasureMethodModal.propTypes = {
    measureMethod: PropTypes.instanceOf(DtoMeasureMethod),
    id: PropTypes.number,
    sandreCodes: PropTypes.arrayOf(PropTypes.instanceOf(DtoSandreCode)),
    popupIsOpen: PropTypes.bool,
    saveResult: PropTypes.func,
    typeName: PropTypes.string,
    warning: PropTypes.func,
}


const mapStateToProps = store => ({
    sandreCodes: store.ReferencialReducer.sandreCodes,
    popupIsOpen: store.SieauReducer.popupIsOpen,
})

MeasureMethodModal.defaultProps = {
    measureMethod: {},
}

const mapDispatchToProps = {
    warning: ToastrAction.warning,
}

export default connect(mapStateToProps, mapDispatchToProps)(MeasureMethodModal)
