import { isEqual } from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import Card from '../../../components/card/Card'
import AppStore from '../../../store/AppStore'
import UserAction from '../user/actions/UserAction'
import { BASIC_HOMEPAGE_VIEWS } from '../user/constants/UserConstants'
import DtoUser from '../user/dto/DtoUser'
import UserViewDto from '../user/dto/UserViewDto'
import { DialogActionsMUI, DialogContentMUI, DialogMUI, DialogTitleMUI } from 'components/styled/Dialog'
import Icon from 'components/icon/Icon'
import { StyledFieldSet } from 'components/StyledElements'
import { Button } from '@mui/material'

class AdministrationHomePageModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            userView: props.userView,
        }
    }

    componentWillReceiveProps(nextProps) {
        if (!isEqual(nextProps.userView, this.props.userView)) {
            this.setState({ userView: nextProps.userView })
        }
    }

    onChangeView = (view, changes) => {
        const newView = new UserViewDto({ ...view, ...changes })
        const userView = this.state.userView.filter(v => v.panel != newView.panel)
        this.setState({ userView: [...userView, newView] })
    }

    resetView = () => {
        this.setState({ userView: BASIC_HOMEPAGE_VIEWS })
    }

    saveView = () => {
        if (!isEqual(this.props.userView, this.state.userView)) {
            AppStore.dispatch(UserAction.updateUserView(this.state.userView.map(view => ({ ...view, login: this.props.accountUser.login })))).then(() => this.props.setIsOpen(false))
        }
    }

    getCard = (name, size) => {
        const view = this.state.userView.find(v => v.panel === name) || { ...BASIC_HOMEPAGE_VIEWS.find(v => v.panel === name), visible: false }
        const actions = []
        if (view && view.visible) {
            if (['quality', 'piezometry', 'hydrometry', 'pluviometry', 'productionUnit'].includes(name)) {
                actions.push({
                    iconName: view.graphicMode ? 'list' : 'insert_chart',
                    onClick: () => {},
                })
            }
            actions.push({
                iconName: 'visibility',
                onClick: () => this.onChangeView(view, { visible: false }),
            })
        } else {
            actions.push({
                iconName: 'visibility_off',
                onClick: () => this.onChangeView(view, { visible: true }),
            })
        }
        return (
            <Card height={ size } title={ i18n[name] } noMargin={ false } className='margin-bottom-1' contentClassName='valign-wrapper' actions={ actions } cardStyle={view && view.visible ? {} : { opacity: 0.5 }}>
                { ['quality', 'piezometry', 'hydrometry', 'pluviometry', 'productionUnit'].includes(name) ? <i className='material-icons large absolute left-45' >{ view.displayMode ? 'insert_chart' : 'list' }</i> : null }
            </Card>
        )
    }

    render() {
        const bookmarks = ['quality', 'piezometry', 'hydrometry', 'pluviometry', 'productionUnit', 'agriculture', 'perimeters'].map(type => this.getCard(type, 70))
        return (
            <DialogMUI
                open={this.props.isOpen}
                PaperProps={{
                    sx: {
                        minWidth: '80vw',
                    },
                }}
            >
                <DialogTitleMUI style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    {i18n.editView}
                    <Icon style={{ color: 'white' }} size='small' icon='close' onClick={() => this.props.setIsOpen(false)} />
                </DialogTitleMUI>
                <DialogContentMUI>
                    <StyledFieldSet>
                        <div className='row'>
                            <div className='col s9 padding-top-1 padding-left-2'>
                                {bookmarks}
                                {this.getCard('map', 400)}
                            </div>
                            <div className='col s3 padding-top-1'>
                                {this.getCard('keyFigures', 100)}
                                {this.getCard('arrests', 100)}
                                {this.getCard('vigilances', 100)}
                                {this.getCard('tweets', 400)}
                            </div>
                        </div>
                    </StyledFieldSet>
                </DialogContentMUI>
                <DialogActionsMUI style={{ justifyContent: 'space-around' }}>
                    <Button onClick={this.resetView} color='primary' variant='contained' >
                        {i18n.reinit}
                    </Button>
                    <Button onClick={this.saveView} color='primary' variant='contained'>
                        {i18n.save}
                    </Button>
                </DialogActionsMUI>
            </DialogMUI>
        )
    }
}

AdministrationHomePageModal.propTypes = {
    userView: PropTypes.arrayOf(PropTypes.instanceOf(UserViewDto)),
    accountUser: PropTypes.instanceOf(DtoUser),
    isOpen: PropTypes.bool,
    setIsOpen: PropTypes.func,
}

const mapStateToProps = (store) => ({
    userView: store.UserReducer.userView,
    accountUser: store.AccountReducer.accountUser,
})

export default connect(mapStateToProps)(AdministrationHomePageModal)
