import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import CartographyPanel from '../../../components/map/CartographyPanel'
import CMSCategoryDto from '../../../events/dto/CMSCategoryDto'
import CMSEventDto from '../../../events/dto/CMSEventDto'
import { getCmsMarker } from '../../../utils/CmsUtils'
import { getFullDate } from '../../../utils/DateUtil'

const AlertCmsModal = ({
    cms = {},
    cmsCategories = [],
}) => {
    const category = cmsCategories.find(c => cms.idCategory === c.id) || {}
    return (
        <div>
            <div className={'row no-margin'}>
                <div className='col s1'>
                    <img src={getCmsMarker(cms, category)} className='left responsive-img margin-right-2 margin-bottom-1' />
                </div>
                <div className='col s11'>
                    <h5>{cms.title}</h5>
                </div>
            </div>
            <div className={'row no-margin'}>
                <div className='col s7'>
                    <p><b className='bold'>{i18n.startDate} :</b> {getFullDate(cms.dateDebut)}</p>
                    {cms.dateFin && <p><b className='bold'>{i18n.endDate} :</b> {getFullDate(cms.dateFin)}</p>}
                    {cms.subtitle && <p><b className='bold'>{i18n.subTitle} :</b> {cms.subtitle}</p>}
                    {cms.author && <p><b className='bold'>{i18n.author} :</b> {cms.author}</p>}
                    {cms.comment && <p><b className='bold'>{i18n.description} :</b><div dangerouslySetInnerHTML={{ __html: cms.comment || '' }} /></p>}
                    {cms.link && <p><b className='bold'>{i18n.link} :</b> {cms.link}</p>}
                </div>
                <div className='col s5'>
                    <CartographyPanel
                        layers={['STATIONS_POINTS']}
                        componentType='all'
                        fullScreenable={false}
                        stationsPoints={[{ ...cms, projection: 16, typeName: 'cms', markerIcon: getCmsMarker(cms, category) }]}
                        noPanels
                        noMarkerTooltip
                        height={300}
                    />
                </div>
            </div>
        </div>
    )
}

AlertCmsModal.propTypes = {
    cmsCategories: PropTypes.arrayOf(PropTypes.instanceOf(CMSCategoryDto)),
    cms: PropTypes.instanceOf(PropTypes.instanceOf(CMSEventDto)),
}

const mapStateToProps = store => ({
    cmsCategories: store.EventsReducer.cmsCategories,
})

export default connect(mapStateToProps)(AlertCmsModal)