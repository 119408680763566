export default class DtoQualitoDashboardStats {
    constructor(obj) {
        this.startDate = obj.startDate
        this.endDate = obj.endDate
        this.nbOperations = obj.nbOperations
        this.nbAnalysis = obj.nbAnalysis
        this.nv1 = obj.nv1
        this.nv2 = obj.nv2
        this.qbrute = obj.qbrute
        this.qincertaine = obj.qincertaine
        this.qincorrecte = obj.qincorrecte
        this.qcorrecte = obj.qcorrecte
        this.sbrute = obj.sbrute
        this.snv1 = obj.snv1
        this.snv2 = obj.snv2
        this.sinterpretee = obj.sinterpretee
    }
}