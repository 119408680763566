import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Grid } from '@mui/material'
import Icon from 'components/icon/Icon'
import { DialogContentMUI, DialogMUI, DialogTitleMUI } from 'components/styled/Dialog'
import { StyledFieldSet } from 'components/StyledElements'
import { shallowEqual, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import { groupBy, isUndefined, keys, uniqBy } from 'lodash'
import DtoSearchIndices from 'quality/dto/DtoSearchIndices'
import QualityChart from 'components/echart/QualityChart'
import ReactECharts from 'echarts-for-react'
import echarts from 'echarts/lib/echarts'
import useListIndexed from 'utils/customHook/useListIndexed'
import { INDICES_RANGE } from 'quality/constants/HydrobioConstant'

const getStationName = (station) => {
    if (station?.code) {
        return `[${station?.code}] ${station?.name || ''}`
    }
    return `${station?.name || ''}`
}

const IndiceGraph = ({
    indices = [],

    graphOptions = {},
    componentHeight = 600,
    withToolLegend = false,
    exportName,

    dataZoomPosition = { bottom: 40 },
    legendPosition = { bottom: 10, right: 20, top: undefined },
    toolboxPosition = { top: 5 },
}) => {
    const {
        qualitometers,
        parameters,
    } = useSelector(store => ({
        qualitometers: store.QualityReducer.qualitometersLight,
        parameters: store.ParameterReducer.parameters,
    }), shallowEqual)

    const {
        // regroupAxis = false,
        graphicTitle,

        minY = 0,
        maxY,
        xAxisSpace,
        maxXAxisSpace,
        showXSplitLines,
        showYSplitLines,

        // stationsOptions = [],
    } = graphOptions

    const parametersCode = useMemo(() => uniqBy(indices, 'parameter').map(a => a.parameter), [indices])

    const indicesGroupByStation = groupBy(indices, 'stationId')
    const data = keys(indicesGroupByStation).map(key => {
        const parseStationId = parseInt(key)
        const qualitometer = qualitometers.find(q => q.id === parseStationId)
        return {
            name: getStationName(qualitometer),
            dataList: indicesGroupByStation[key].map(i => ({
                date: i.sampleDate,
                value: i.result,
            })),
            type: 'bar',
        }
    })

    const parametersObj = parametersCode.map(code => parameters.find(p => p.code === code)).filter(u => !!u)
    const chartTitle = isUndefined(graphicTitle) ? `${parametersObj.map(p => p.displayLabel).join(', ')}` : graphicTitle

    return (
        <QualityChart
            title={chartTitle}
            exportName={exportName ?? `${i18n.follow} - ${parametersObj.map(p => p.displayName).join(', ')}`}
            grids={[{
                height: 'auto',
                bottom: 120,
                top: 10,
            }]}
            xAxis={[{
                splitLine: { show: showXSplitLines },
            }]}
            yAxis={[{
                name: i18n.indiceValue,
                min: minY,
                max: maxY,
                splitLine: { show: showYSplitLines },
            }]}
            series={data}

            headerHeight={0}
            footerHeight={120}
            componentHeight={componentHeight}

            xAxisSpace={xAxisSpace}
            maxXAxisSpace={maxXAxisSpace}

            dataZoomPosition={dataZoomPosition}
            legendPosition={legendPosition}
            toolboxPosition={toolboxPosition}

            // withToolTypeLine
            // withToolTypeBar
            withToolLegend={withToolLegend}
            withDataZoom
        />
    )
}

IndiceGraph.propTypes = {
    indices: PropTypes.arrayOf(PropTypes.instanceOf(DtoSearchIndices)),
    graphOptions: PropTypes.shape({
        regroupAxis: PropTypes.bool,
        graphicTitle: PropTypes.string,

        minY: PropTypes.number,
        maxY: PropTypes.number,
        xAxisSpace: PropTypes.oneOf(['year', 'month', 'month', 'AUTO']),
        maxXAxisSpace: PropTypes.oneOf(['year', 'month', 'week', 'AUTO']),
        showXSplitLines: PropTypes.bool,
        showYSplitLines: PropTypes.bool,

        stationsOptions: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number,
            color: PropTypes.string,
        })),

    }),
    graphHeight: PropTypes.number,
    componentHeight: PropTypes.number,
    withToolLegend: PropTypes.bool,
    exportName: PropTypes.string,
    dataZoomPosition: PropTypes.shape({}),
    legendPosition: PropTypes.shape({}),
    gridPosition: PropTypes.shape({}),
    toolboxPosition: PropTypes.shape({}),

}

const IndiceGraphModal = ({
    isOpen = false,
    closeGraph = () => { },
    indices = [],
}) => {
    const {
        parameters,
    } = useSelector(store => ({
        parameters: store.ParameterReducer.parameters,
    }), shallowEqual)

    const parameterCode = indices[0]?.parameter

    const parameterObj = parameters.find(p => p.code === parameterCode)
    const dialogTitle = `${i18n.followUp} ${parameterObj?.name || ''} ${parameterCode ? `[${parameterCode}]` : ''}`
    return (
        <DialogMUI
            maxWidth='lg'
            fullWidth
            open={isOpen}
            PaperProps={{
                sx: {
                    minHeight: undefined,
                    maxHeight: undefined,
                },
            }}
        >
            <DialogTitleMUI>
                <Grid container justifyContent='space-between' alignItems='center' style={{ padding: '0 20' }}>
                    <Grid item xs>
                        {dialogTitle}
                    </Grid>
                    <Grid item xs='auto'>
                        <Icon style={{ color: 'white' }} size='small' icon='close' onClick={closeGraph} />
                    </Grid>
                </Grid>
            </DialogTitleMUI>
            <DialogContentMUI style={{ overflowX: 'hidden' }}>
                <Grid container columnSpacing={2}>
                    <Grid item xs={12}>
                        <StyledFieldSet>
                            <IndiceGraph
                                indices={indices}
                            />
                        </StyledFieldSet>
                    </Grid>
                </Grid>
            </DialogContentMUI>
        </DialogMUI>
    )
}

IndiceGraphModal.propTypes = {
    isOpen: PropTypes.bool,
    closeGraph: PropTypes.func,
    indices: PropTypes.arrayOf(PropTypes.instanceOf(DtoSearchIndices)),
}

const IndiceRadarGraph = ({
    indices = [],
}) => {
    const {
        parameters,
    } = useSelector(store =>({
        parameters: store.ParameterReducer.parameters,
    }), shallowEqual)

    const indexedParameters = useListIndexed(parameters, 'code')

    const formattedIndicators = indices.map(i => {
        const indiceRange = INDICES_RANGE.find(ind => ind.code === `${i.parameter}`)
        return {
            name: indexedParameters[i.parameter]?.displayName ?? `${i.parameter}`,
            max: indiceRange?.max ?? 20,
            min: indiceRange?.min ?? 0,
        }
    })

    const options = {
        tooltip: {},
        radar: {
            shape: 'circle',
            radius: '110',
            name: {
                textStyle: {
                    color: '#fff',
                    backgroundColor: '#999',
                    borderRadius: 3,
                    padding: [3, 5],
                },
            },
            indicator: formattedIndicators,
        },
        series: [{
            name: i18n.hydrobioIndices,
            type: 'radar',
            areaStyle: { normal: {} },
            data: [
                {
                    value: indices.map(i => i.result),
                },
            ],
        }],
    }

    return (
        <ReactECharts
            echarts={echarts}
            option={options}
            notMerge
            lazyUpdate
            style={{ height: 300 }}
        />
    )
}

IndiceRadarGraph.propTypes = {
    indices: PropTypes.arrayOf(PropTypes.shape({})),
}

export {
    IndiceGraphModal,
    IndiceGraph,
    IndiceRadarGraph,
}