/* eslint-disable camelcase */
import DtoElecmecEquipment from 'installation/dto/installation/DtoElecmecEquipment'
import DtoPumpingEquipments from './DtoPumpingEquipments'

export default class DtoPumpingStation {
    constructor(obj) {
        this.idStation = obj.idStation
        this.building = obj.building
        this.aream2 = obj.aream2
        this.description = obj.description
        this.nominalCapacity = obj.nominalCapacity
        this.nominalHourlyCapacity = obj.nominalHourlyCapacity
        this.treatedParams = obj.treatedParams
        this.processingSector = obj.processingSector
        this.autorisationDecree = obj.autorisationDecree
        this.autorisationDecreeDate = obj.autorisationDecreeDate
        this.numDecree = obj.numDecree
        this.decreeDuration = obj.decreeDuration
        this.rejectAutorisation = obj.rejectAutorisation
        this.rejectAutorisationDate = obj.rejectAutorisationDate
        this.TNLevel = obj.TNLevel
        this.casingType = obj.casingType
        this.nominalFlow = obj.nominalFlow // Option[Double],
        this.potentialVolume = obj.potentialVolume // Option[Double],
        this.sampledVolume = obj.sampledVolume // Option[Double],

        this.link_elecmecEquipments = obj.link_elecmecEquipments ? obj.link_elecmecEquipments.map(ee => new DtoElecmecEquipment(ee)) : []
        this.link_equipments = obj.link_equipments ? obj.link_equipments.map(e => new DtoPumpingEquipments(e)) : []
    }
}