import { push } from '@lagunovsky/redux-react-router'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import CartographyPanel from '../../../components/map/CartographyPanel'
import DtoHydrometricStation from '../../../hydrometry/dto/DtoHydrometricStation'
import DtoInstallation from '../../../installation/dto/installation/DtoInstallation'
import DtoPiezometerLight from '../../../piezometry/dto/DtoPiezometerLight'
import PluviometerDto from '../../../pluviometry/dto/PluviometerDto'
import DtoQualitometerLight from '../../../quality/dto/DtoQualitometerLight'
import ContactDto from '../../../referencial/components/contact/dto/ContactDto'
import ContributorItem from '../../../referencial/components/contributor/dto/ContributorItem'
import DtoLocation from '../../../station/dto/DtoLocation'
import { hasValue } from '../../../utils/NumberUtil'
import { hasLocalisation } from '../../../utils/StationUtils'

class MaterielsMapComponent extends Component {
    getColor = (statusCode) => {
        switch (statusCode) {
            case 0: return 'red' // HS
            case 1: return 'green' // sur site
            case 3: return 'orange' // maintenance
            case 5: return 'gray' // reformé
            default: return 'blue' // en stock/le reste
        }
    }

    getAssignment = ({ siteType, siteCode, statusCode, administratorId }) => {
        const {
            piezometers,
            pluviometers,
            qualitometers,
            hydrometricStations,
            installations,
            contacts,
            contributors,
        } = this.props
        if (statusCode !== 1) {
            return {
                ...contributors.find(({ id }) => id === administratorId),
                markerIcon: `pictures/markers/PNG/map_contact_${this.getColor(statusCode)}.png`,
            }
        }
        switch (siteType) {
            case 1: return {
                ...piezometers.find(({ id }) => id === siteCode),
                markerIcon: `pictures/markers/PNG/map_piezo_${this.getColor(statusCode)}.png`,
            }
            case 2: return {
                ...pluviometers.find(({ id }) => id === siteCode),
                markerIcon: `pictures/markers/PNG/map_pluvio_${this.getColor(statusCode)}.png`,
            }
            case 3: return {
                ...qualitometers.find(({ id }) => id === siteCode),
                markerIcon: `pictures/markers/PNG/map_quality_${this.getColor(statusCode)}.png`,
            }
            case 4: return {
                ...hydrometricStations.find(({ id }) => id === siteCode),
                markerIcon: `pictures/markers/PNG/map_hydro_${this.getColor(statusCode)}.png`,
            }
            case 7: return {
                ...installations.find(({ id }) => id === siteCode),
                markerIcon: `pictures/markers/PNG/map_installation_${this.getColor(statusCode)}.png`,
            }
            case 9: return {
                ...contacts.find(({ id }) => id === siteCode),
                markerIcon: `pictures/markers/PNG/map_contact_${this.getColor(statusCode)}.png`,
            }
            default:
                return {}
        }
    }

    getHeaders = () => {
        switch (this.props.materielLink) {
            case 'sim':
            case 'subscription':
                return ['type', 'provider', 'stateDate', 'serialNumber', 'assignment']
            default:
                return ['type', 'manufacturer', 'stateDate', 'serialNumber', 'assignment']
        }
    }


    render() {
        const stations = this.props.materiels.filter(({ statusCode, administratorId }) => statusCode === 1 || hasValue(administratorId)).map(mat => {
            const assignment = this.getAssignment(mat)
            return {
                ...assignment,
                ...mat,
                localisation: assignment?.location || new DtoLocation(assignment),
            }
        })
        return (
            <CartographyPanel
                layers={['STATIONS_POINTS']}
                componentType={'materiel'}
                stationsPoints={stations.filter(s => hasLocalisation(s))}
                tableHeaders={this.getHeaders()}
                onListElementClick={m => this.props.push(m.link || `/materiel/${this.props.materielLink}/${m.materielId}`)}
            />
        )
    }
}

MaterielsMapComponent.propTypes = {
    materiels: PropTypes.arrayOf(PropTypes.shape({})),
    materielLink: PropTypes.string,

    piezometers: PropTypes.arrayOf(PropTypes.instanceOf(DtoPiezometerLight)),
    pluviometers: PropTypes.arrayOf(PropTypes.instanceOf(PluviometerDto)),
    qualitometers: PropTypes.arrayOf(PropTypes.instanceOf(DtoQualitometerLight)),
    hydrometricStations: PropTypes.arrayOf(PropTypes.instanceOf(DtoHydrometricStation)),
    installations: PropTypes.arrayOf(PropTypes.instanceOf(DtoInstallation)),
    contacts: PropTypes.arrayOf(PropTypes.instanceOf(ContactDto)),
    contributors: PropTypes.arrayOf(PropTypes.instanceOf(ContributorItem)),

    push: PropTypes.func,
}

MaterielsMapComponent.defaultProps = {
    materiels: [],
}

const mapStateToProps = store => ({
    piezometers: store.PiezometryReducer.piezometersLight,
    pluviometers: store.PluviometryReducer.pluviometers,
    qualitometers: store.QualityReducer.qualitometersLight,
    hydrometricStations: store.HydrometryReducer.hydrometricStations,
    installations: store.InstallationReducer.installations,
    contacts: store.ContactReducer.contacts,
    contributors: store.ContributorReducer.contributors,
})

const mapDispatchToProps = {
    push,
}

export default connect(mapStateToProps, mapDispatchToProps)(MaterielsMapComponent)
