import DtoCMSQuestionValue from './DtoCMSQuestionValue'

export default class DtoCMSQuestion {
    constructor(obj) {
        this.idSurvey = obj.idSurvey // Option[Long],
        this.idParagraph = obj.idParagraph // Option[Long],
        this.idQuestion = obj.idQuestion // Option[Long],
        this.description = obj.description // Option[String],
        this.questionType = obj.questionType // Option[Long],
        this.obligatory = obj.obligatory // Option[Boolean],
        this.order = obj.order // Option[Int],
        this.sandreField = obj.sandreField // Option[String],
        this.inputComment = obj.inputComment // Option[Boolean],

        this.link_questionValues = obj.link_questionValues ? obj.link_questionValues.map(u => new DtoCMSQuestionValue(u)) : [] // Option[Seq[CMSQuestionValue]]
    }
}
