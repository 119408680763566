import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import IAction from './IAction'

class Replace extends IAction {
    fn = () => {
        return this.props.onReplace
    }
    icon = () => {
        return 'compare_arrows'
    }
    label = () => {
        return i18n.replace
    }
    id = () => {
        return 'replace_action_navbar'
    }
}

Replace.propTypes = {
    onReplace: PropTypes.func.isRequired,
}

export default Replace
