import { H_TERRITORY_OPTIONS } from 'account/constants/AccessRulesConstants'
import { push } from '@lagunovsky/redux-react-router'
import HomeAction from 'home/actions/HomeAction'
import BoundaryError from 'log/components/BoundaryError'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Outlet } from 'react-router-dom'
import ReferencialAction from 'referencial/action/ReferencialAction'
import CityAction from 'referencial/components/city/actions/CityAction'
import CityDto from 'referencial/components/city/dto/CityDto'
import CultureAction from 'referencial/components/cultures/actions/CultureAction'
import DtoCulture from 'referencial/components/cultures/dto/DtoCulture'
import CulturesFamilyAction from 'referencial/components/culturesFamilies/actions/CulturesFamilyAction'
import DtoCulturesFamily from 'referencial/components/culturesFamilies/dto/DtoCulturesFamily'
import ManagementUnitAction from 'referencial/components/managementUnit/actions/ManagementUnitAction'
import DtoManagementUnit from 'referencial/components/managementUnit/dto/DtoManagementUnit'
import DtoSandreCode from 'referencial/dto/DtoSandreCode'
import { componentHasHabilitations } from 'utils/HabilitationUtil'

class TerritorySettingsApp extends Component {
    componentDidMount() {
        if (!componentHasHabilitations(H_TERRITORY_OPTIONS)) { // A modifier quand react-router sera à jour
            this.props.push('/unauthorized')
            return
        }
        const { sandreCodes, cultures, culturesFamilies, managementUnits, cities } = this.props
        this.props.setHelpLink('', '')
        if (!sandreCodes.length) {
            this.props.fetchSandreCodes()
        }
        if (!cultures.length) {
            this.props.fetchCultures()
        }
        if (!culturesFamilies.length) {
            this.props.fetchCulturesFamilies()
        }
        if (!managementUnits.length) {
            this.props.fetchManagementUnits()
        }
        if (!cities.length) {
            this.props.fetchCities()
        }
    }

    render() {
        return (
            <BoundaryError>
                <Outlet />
            </BoundaryError>
        )
    }
}

TerritorySettingsApp.propTypes = {
    params: PropTypes.shape({
        id: PropTypes.string,
    }),
    push: PropTypes.func,
    fetchSandreCodes: PropTypes.func,
    fetchCultures: PropTypes.func,
    fetchCulturesFamilies: PropTypes.func,
    fetchManagementUnits: PropTypes.func,
    fetchCities: PropTypes.func,
    setHelpLink: PropTypes.func,
    sandreCodes: PropTypes.arrayOf(PropTypes.instanceOf(DtoSandreCode)),
    cultures: PropTypes.arrayOf(PropTypes.instanceOf(DtoCulture)),
    culturesFamilies: PropTypes.arrayOf(PropTypes.instanceOf(DtoCulturesFamily)),
    managementUnits: PropTypes.arrayOf(PropTypes.instanceOf(DtoManagementUnit)),
    cities: PropTypes.arrayOf(PropTypes.instanceOf(CityDto)),
}

const mapStateToProps = store => ({
    sandreCodes: store.ReferencialReducer.sandreCodes,
    cultures: store.CultureReducer.cultures,
    culturesFamilies: store.CulturesFamilyReducer.culturesFamilies,
    managementUnits: store.ManagementUnitReducer.managementUnits,
    cities: store.CityReducer.cities,
})

const mapDispatchToProps = {
    fetchSandreCodes: ReferencialAction.fetchSandreCodes,
    fetchCultures: CultureAction.fetchCultures,
    fetchCulturesFamilies: CulturesFamilyAction.fetchCulturesFamilies,
    fetchManagementUnits: ManagementUnitAction.fetchManagementUnits,
    fetchCities: CityAction.fetchCities,
    setHelpLink: HomeAction.setHelpLink,
    push,
}

export default connect(mapStateToProps, mapDispatchToProps)(TerritorySettingsApp)
