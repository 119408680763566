import React, { useState } from 'react'
import { Button, Dialog } from '@mui/material'
import Icon from 'components/icon/Icon'
import { DialogActionsMUI, DialogContentMUI, DialogTitleMUI } from 'components/styled/Dialog'
import PropTypes from 'prop-types'
import { shallowEqual, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import Input from '../../../components/forms/Input'
import Select from '../../../components/forms/Select'
import CityDto from '../../../referencial/components/city/dto/CityDto'
import ContributorDto from '../../../referencial/components/contributor/dto/ContributorDto'

const IntervenantModal = ({
    intervenant,
    open,
    declarationMode,
    title,
    onCancel = () => {},
    onValidate = () => {},
}) => {
    const [intervenantState, setIntervenant] = useState(intervenant)

    const {
        cities,
        citiesHisto,
    } = useSelector(store => ({
        cities: store.CityReducer.cities,
        citiesHisto: store.CityReducer.citiesHisto,
    }), shallowEqual)

    const onChangeValue = (obj) => {
        setIntervenant({
            ...intervenantState,
            ...obj,
        })
    }

    const onChangeValueCP = (value) => {
        const citiesFiltered = cities.filter((c) => c.link_postalCode.find((cp) => cp.startsWith(value)))
        if (citiesFiltered.length === 1) {
            const cityCode = citiesFiltered[0].id
            onChangeValue({ postalCode: value, cityCode })
        } else {
            onChangeValue({ postalCode: value })
        }
    }

    const onChangeValueCity = (value) => {
        const city = cities.find((c) => c.id === value) || {}
        if (city.link_postalCode && city.link_postalCode.length) {
            onChangeValue({ postalCode: city.link_postalCode[0], cityCode: value, cityCodeDelegate: undefined })
        } else {
            onChangeValue({ cityCode: value, cityCodeDelegate: undefined })
        }
    }

    return (
        <Dialog
            fullWidth
            maxWidth='md'
            open={open}
        >
            <DialogTitleMUI style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                {title || i18n.editContributorLink}
                <Icon
                    style={{ color: 'white' }}
                    size='small'
                    icon='close'
                    onClick={() => {
                        setIntervenant(intervenant)
                        onCancel()
                    }}
                />
            </DialogTitleMUI>
            <DialogContentMUI>
                <div className='row no-margin padding-top-1'>
                    <Input
                        col={6}
                        title={i18n.name}
                        value={intervenantState.name}
                        onChange={value => onChangeValue({ name: value?.length ? value : undefined })}
                    />
                    <Input
                        col={6}
                        title={i18n.phoneTel}
                        value={intervenantState.phoneTel}
                        onChange={value => onChangeValue({ phoneTel: value?.length ? value : undefined })}
                    />
                    <Input
                        col={6}
                        title={i18n.address}
                        value={intervenantState.road}
                        onChange={value => onChangeValue({ road: value?.length ? value : undefined })}
                    />
                    <Input
                        col={6}
                        title={i18n.mobile}
                        value={intervenantState.mobile}
                        onChange={value => onChangeValue({ mobile: value?.length ? value : undefined })}
                    />
                    <Input
                        col={6}
                        title={i18n.additionnalAddress}
                        value={intervenantState.addressComplement}
                        onChange={value => onChangeValue({ addressComplement: value?.length ? value : undefined })}
                    />
                    <Input
                        col={6}
                        title={i18n.fax}
                        value={intervenantState.fax}
                        onChange={value => onChangeValue({ fax: value?.length ? value : undefined })}
                    />
                    <Input
                        col={2}
                        title={i18n.postalCode}
                        value={intervenantState.postalCode}
                        onChange={value => onChangeValueCP(value?.length ? value : undefined)}
                    />
                    <Select
                        col={4}
                        options={cities}
                        label={i18n.city}
                        displayWithCode
                        onChange={city => onChangeValueCity(city)}
                        value={intervenantState.cityCode}
                    />
                    <Input
                        col={6}
                        title={i18n.mail}
                        value={intervenantState.email}
                        onChange={value => onChangeValue({ email: value?.length ? value : undefined })}
                    />
                    {!declarationMode ? (
                        <>
                            <Input
                                col={2}
                                title={i18n.postalCodeDelegateShort}
                                value={intervenantState.postalCodeDelegate}
                                onChange={postalCodeDelegate => onChangeValue({ postalCodeDelegate })}
                            />
                            <Select
                                col={4}
                                options={citiesHisto.filter(c => c.cityAttachmentCode === intervenantState.cityCode)}
                                label={i18n.cityDelegate}
                                displayWithCode
                                onChange={cityCodeDelegate => onChangeValue({ cityCodeDelegate })}
                                value={intervenantState.cityCodeDelegate}
                            />
                        </>
                    ) : (
                        <>
                            <Input
                                col={6}
                                title={i18n.siret}
                                value={intervenantState.siret}
                                onChange={value => onChangeValue({ siret: value?.length ? value : undefined })}
                                maxLength={14}
                            />
                            <Input
                                col={6}
                                title={i18n.pacage}
                                value={intervenantState.pacage}
                                onChange={value => onChangeValue({ pacage: value?.length ? value : undefined })}
                            />
                        </>
                    )}
                </div>
            </DialogContentMUI>
            <DialogActionsMUI>
                <Button onClick={() => onValidate(intervenantState)} variant='contained' color='primary'>
                    {i18n.validate}
                </Button>
            </DialogActionsMUI>
        </Dialog>
    )
}

IntervenantModal.propTypes = {
    open: PropTypes.bool,
    declarationMode: PropTypes.bool,
    title: PropTypes.string,
    intervenant: PropTypes.instanceOf(ContributorDto),
    onCancel: PropTypes.func,
    onValidate: PropTypes.func,
    cities: PropTypes.arrayOf(PropTypes.instanceOf(CityDto)),
    citiesHisto: PropTypes.arrayOf(PropTypes.instanceOf(CityDto)),
}

export default IntervenantModal
