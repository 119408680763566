import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import Input from '../../../../components/forms/Input'
import NumberField from '../../../../components/forms/NumberField'
import Select from '../../../../components/forms/Select'
import Textarea from '../../../../components/forms/Textarea'
import Row from '../../../../components/react/Row'
import ContactDto from '../../../../referencial/components/contact/dto/ContactDto'
import ContributorDto from '../../../../referencial/components/contributor/dto/ContributorDto'
import { SANDRE } from '../../../../referencial/constants/ReferencialConstants'
import DtoSandreCode from '../../../../referencial/dto/DtoSandreCode'
import { getHour } from '../../../../utils/DateUtil'
import { onChangeHour } from '../../../../utils/FormUtils'
import { arrayOf, getSandreList } from '../../../../utils/StoreUtils'
import DtoArrangement from '../../../dto/DtoArrangement'
import SimpleDatePicker from 'components/forms/SimpleDatePicker'
import DtoVariousMateriel from 'station/dto/materiel/DtoVariousMateriel'

class ArrangementModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            arrangement: { ...props.arrangement, idStation: props.id },
        }
    }

    componentDidUpdate(prevProps) {
        if (!prevProps.popupIsOpen && this.props.popupIsOpen) {
            $('#descriptionModal').on('click', this.onSave)
        }
    }

    onSave = () => {
        this.props.saveResult(new DtoArrangement(this.state.arrangement))
        $('.modal').modal('close')
    }

    onChangeElement = (changes) => this.setState({ arrangement: { ...this.state.arrangement, ...changes } })

    render() {
        const { arrangement } = this.state
        return (
            <div>
                <Row>
                    <SimpleDatePicker
                        col={6}
                        value={arrangement.startDate}
                        label={i18n.startDate}
                        onChange={v => this.onChangeElement({ startDate: v })}
                        max={arrangement.endDate}
                    />
                    <Input col={ 6 } title={ i18n.startHour } value={ getHour(arrangement.startDate) }
                        onChange={ v => onChangeHour(v, v2 => this.onChangeElement({ startDate: v2 }), { max: arrangement.endDate }, arrangement.startDate) }
                    />
                </Row>
                <Row>
                    <SimpleDatePicker
                        col={6}
                        value={arrangement.endDate}
                        label={i18n.endDate}
                        onChange={v => this.onChangeElement({ endDate: v })}
                        min={arrangement.startDate}
                    />
                    <Input col={ 6 } title={ i18n.endHour } value={ getHour(arrangement.endDate) }
                        onChange={ v => onChangeHour(v, v2 => this.onChangeElement({ endDate: v2 }), { min: arrangement.startDate }, arrangement.endDate) }
                    />
                </Row>
                <div className='row no-margin padding-top-1'>
                    <Select col={ 6 } options={ getSandreList(this.props.sandreCodes, SANDRE.ARRANGEMENT_TYPES) } label={ i18n.type }
                        value={ arrangement.arrangementType } onChange={ v => this.onChangeElement({ arrangementType: v }) }
                    />
                    <Select
                        col={ 6 }
                        options={ this.props.matKeys }
                        value={ arrangement.keyCode }
                        label={i18n.key}
                        keyLabel='reference'
                        onChange={ v => this.onChangeElement({ keyCode: v }) }
                    />
                </div>
                <div className='row no-margin padding-top-1'>
                    <NumberField
                        col={ 6 }
                        value={ arrangement.value }
                        title={ i18n.value }
                        onChange={ v => this.onChangeElement({ value: v }) }
                        floatValue
                    />
                </div>
                <div className='row no-margin padding-top-1'>
                    <Textarea col={ 12 } title={ i18n.description } value={ arrangement.descriptive } onChange={ v => this.onChangeElement({ descriptive: v }) }/>
                </div>
            </div>
        )
    }
}

ArrangementModal.propTypes = {
    arrangement: PropTypes.instanceOf(DtoArrangement),
    id: PropTypes.number,
    sandreCodes: PropTypes.arrayOf(PropTypes.instanceOf(DtoSandreCode)),
    popupIsOpen: PropTypes.bool,
    saveResult: PropTypes.func,
    contributors: arrayOf(ContributorDto),
    contacts: arrayOf(ContactDto),
    matKeys: arrayOf(DtoVariousMateriel),
}


const mapStateToProps = store => ({
    sandreCodes: store.ReferencialReducer.sandreCodes,
    popupIsOpen: store.SieauReducer.popupIsOpen,
    contributors: store.ContributorReducer.contributors,
    contacts: store.ContactReducer.contacts,
})

ArrangementModal.defaultProps = {
    arrangement: {},
}

export default connect(mapStateToProps)(ArrangementModal)
