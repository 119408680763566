import { register } from 'ol/proj/proj4'
import * as olSphere from 'ol/sphere'
import proj4 from 'proj4'
import AddressApiDataGouvDto from 'referencial/components/contact/dto/AddressApiDataGouvDto'
import LocationApiDataGouvDto from 'referencial/components/contact/dto/LocationApiDataGouvDto'
import i18n from 'simple-react-i18n'
import { lane_type } from 'station/constants/LocationConstants'
import { path } from '../../conf/basepath'
import LogAction from '../../log/actions/LogAction'
import { FR } from '../../referencial/constants/ReferencialConstants'
import AppStore from '../../store/AppStore'
import { hasValue, round } from '../NumberUtil'
import { hasLocalisationStation } from '../StationUtils'

const getProjections = () => [
    // add other projections here
    { sandreCode: 2, code: 'EPSG:27562', name: 'Lambert Centre France', proj: '+proj=lcc +lat_1=46.8 +lat_0=46.8 +lon_0=0 +k_0=0.99987742 +x_0=600000 +y_0=200000 +a=6378249.2 +b=6356515 +towgs84=-168,-60,320,0,0,0,0 +pm=paris +units=m +no_defs' },
    { sandreCode: 5, code: 'EPSG:27572', name: 'Lambert zone II', proj: '+proj=lcc +lat_1=46.8 +lat_0=46.8 +lon_0=0 +k_0=0.99987742 +x_0=600000 +y_0=2200000 +a=6378249.2 +b=6356515 +towgs84=-168,-60,320,0,0,0,0 +pm=paris +units=m +no_defs' },
    { sandreCode: 7, code: 'EPSG:27572', name: 'Lambert II Carto', proj: '+proj=lcc +lat_1=46.8 +lat_0=46.8 +lon_0=0 +k_0=0.99987742 +x_0=600000 +y_0=2200000 +a=6378249.2 +b=6356515 +towgs84=-168,-60,320,0,0,0,0 +pm=paris +units=m +no_defs' },
    { sandreCode: 23, code: 'EPSG:2971', name: 'Guyane CSG67 UTM 22', proj: '+proj=utm +zone=22 +ellps=intl +towgs84=-186,230,110,0,0,0,0 +units=m +no_defs' },
    { sandreCode: 26, code: 'EPSG:2154', name: 'Lambert-93 France', proj: '+proj=lcc +lat_1=49 +lat_2=44 +lat_0=46.5 +lon_0=3 +x_0=700000 +y_0=6600000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs' },
    { sandreCode: 16, code: 'EPSG:4326', name: 'WGS84 UTM30', proj: null },
    { sandreCode: 31, code: 'EPSG:4326', name: 'WGS84G', proj: null },
    { sandreCode: 39, code: 'EPSG:4559', name: 'RRAF 1991 (French Antilles)', proj: '+proj=utm +zone=20 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs' },
    { sandreCode: 40, code: 'EPSG:2972', name: 'Guyane RGFG95 UTM 22', proj: '+proj=utm +zone=22 +ellps=GRS80 +towgs84=2,2,-2,0,0,0,0 +units=m +no_defs' },
]

const getProjectionsList = () => getProjections().map(p => ({ ...p, name: `${p.code} [${p.sandreCode}] - ${p.name}` }))

const getWGS84Coordinate = (loc) => {
    if (loc.projection) {
        // Specific cases
        if ([16, 31].includes(loc.projection)) {
            return [+loc.x, +loc.y]
        }
        if (loc.projection === 3003) {
            const result = proj4('+proj=tmerc +lat_0=0 +lon_0=9 +k=0.9996 +x_0=1500000 +y_0=0 +ellps=intl +towgs84=-104.1,-49.1,-9.9,0.971,-2.917,0.714,-11.68 +units=m +no_defs', 'WGS84', [+loc.x, +loc.y])
            if (result.includes(NaN)) {
                AppStore.dispatch(LogAction.logError(i18n.wrongCoordinates + loc.code))
                return [null, null]
            }
            return result
        }
        if (loc.projection === 0) {
            return [2.4983333333333335, 46.6058333]
        }

        // managed cases
        const found = getProjections().find(p => p.sandreCode === loc.projection)
        if (found) {
            return proj4(found.proj, 'WGS84', [+loc.x, +loc.y])
        }
    }
    return proj4('+proj=lcc +lat_1=46.8 +lat_0=46.8 +lon_0=0 +k_0=0.99987742 +x_0=600000 +y_0=2200000 +a=6378249.2 +b=6356515 +towgs84=-168,-60,320,0,0,0,0 +pm=paris +units=m +no_defs', 'WGS84', [+loc.x, +loc.y])
}

const isProjectionValid = (loc = {}) => {
    return !hasValue(loc.projection) || [0, 2, 5, 7, 16, 23, 26, 31, 39, 40, 3003].includes(loc.projection)
}


const defaultCoordinates = {
    'https://vendeeeau.aquasys.fr/api/': [-1.403488, 46.700867],
    'https://vendeeeau.sieau.aquasys.fr/api/': [-1.403488, 46.700867],
    'https://reseau.sieau.aquasys.fr/api/': [-0.363682, 49.170523],
    'https://nice.sieau.aquasys.fr/api/': [7.102891, 44.103643],
    'https://eaudazur.sieau.aquasys.fr/api/': [7.102891, 44.103643],
    'https://cacg.sieau.aquasys.fr/api/': [0.102891, 43.103643],
    'https://aprona.sieau.aquasys.fr/api/': [7.752111, 48.573405],
    'https://chlordecone.sieau.aquasys.fr/api/': [-61.024174, 14.641528],
    'https://aep49.sieau.aquasys.fr/api/': [-0.5518256, 47.4711615],
}

const getDefaultCoordinates = () => {
    if (defaultCoordinates[path]) {
        return defaultCoordinates[path]
    }
    return [-1.7541604, 47.1325782]
}

const filterStationsCoordinates = (stations, citiesIndex) => stations.filter(s => hasLocalisationStation(s) || s.townCode).map(s => {
    if (hasLocalisationStation(s)) {
        return s
    }
    const city = citiesIndex[s.townCode]
    if (city && (!city.countryCode || city.countryCode === FR) && hasLocalisationStation(city)) {
        const loc = { x: city.x, y: city.y, projection: city.projection }
        return { ...s, ...loc, localisation: loc }
    }
    return null
}).filter(s => !!s)

const filterStationCoordinates = (station, citiesIndex) => {
    if (hasLocalisationStation(station)) {
        return station
    }
    if (station && station.townCode) {
        const city = citiesIndex[station.townCode]
        if (city && (!city.countryCode || city.countryCode === FR) && hasLocalisationStation(city)) {
            const loc = { x: city.x, y: city.y, projection: city.projection }
            return { ...station, ...loc, localisation: loc }
        }
    }
    return null
}

const getWGS84KML = (location) => {
    // return proj4('EPSG:3857','+proj=merc +a=6378137 +b=6378137 +lat_ts=0.0 +lon_0=0.0 +x_0=0.0 +y_0=0 +k=1.0 +units=m +nadgrids=@null +wktext  +no_defs', [+location.x, +location.y])
    return proj4('+proj=merc +a=6378137 +b=6378137 +lat_ts=0.0 +lon_0=0.0 +x_0=0.0 +y_0=0 +k=1.0 +units=m +nadgrids=@null +wktext  +no_defs', 'WGS84', [+location.x, +location.y])
}

const getDistance = (station1, station2) => {
    return round(olSphere.getDistance(getWGS84Coordinate(station1), getWGS84Coordinate(station2)), 0)
}

const EPSG4326 = 'EPSG:4326'

const EPSG3857 = 'EPSG:3857'

const loadWMSProjections = () => {
    getProjections().filter(p => !!p.proj).forEach(p => {
        proj4.defs(p.code, p.proj)
    })
    /*
    if (ol.proj.setProj4) {
        ol.proj.setProj4(proj4)
    }
    if (ol.proj.proj4 && ol.proj.proj4.register) {
        ol.proj.proj4.register(proj4)
    }*/
    register(proj4)
}

const convertCoordinates = (fromProj, toProj, coordinates) => {
    const toProjFound = getProjections().find(p => p.sandreCode === toProj) || {}
    if (fromProj === 16) { // if from WGS84, no need to pass projection
        return proj4(toProjFound.proj, coordinates)
    }
    const fromProjFound = getProjections().find(p => p.sandreCode === fromProj) || {}
    return proj4(fromProjFound.proj, toProjFound.proj, coordinates)
}

const getAddressFormatted = (obj, addressKey) => {
    const [laneNumber, laneType, ...laneLabel] = obj[addressKey].split(' ')
    const address = new AddressApiDataGouvDto({
        laneNumber,
        laneType,
        laneLabel: laneLabel.join(' '),
        cityCode: obj.cityCode,
    })
    const location = new LocationApiDataGouvDto({
        address,
        cityCode: obj.cityCode,
    })
    return location
}

const canFetchLocation = (address) => {
    return isNaN(address.laneNumber) || !lane_type.map(type => type.toLowerCase()).includes(address.laneType.toLowerCase())
}

export { getWGS84Coordinate, isProjectionValid, getDefaultCoordinates, getDistance, getWGS84KML, EPSG4326, EPSG3857, filterStationsCoordinates, filterStationCoordinates, loadWMSProjections, getProjections, getProjectionsList, convertCoordinates, getAddressFormatted, canFetchLocation }
