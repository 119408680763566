import { AccordionSummary, Box, Collapse, Grid } from '@mui/material'
import { styled } from '@mui/styles'
import hydrometryMarker from 'assets/pictures/markers/map_hydro.png'
import piezometryMarker from 'assets/pictures/markers/map_piezo.png'
import pluviometryMarker from 'assets/pictures/markers/map_pluvio.png'
import installationMarker from 'assets/pictures/markers/map_installation_defaut.png'
import Checkbox from 'components/forms/Checkbox'
import Input from 'components/forms/Input'
import SimpleDatePicker from 'components/forms/SimpleDatePicker'
import { AccordionDetailsMUI, AccordionMUI } from 'components/styled/Accordions'
import { groupBy, keys, maxBy, minBy, orderBy, uniq } from 'lodash'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { useEffect, useMemo, useState } from 'react'
import i18n from 'simple-react-i18n'
import { getTextColorByBackground, getThresholdColorCode, getVigilanceColor } from 'utils/ColorUtil'
import { execByType } from 'utils/StationUtils'
import { STATION_TYPE_NAME } from 'station/constants/StationConstants'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import AdministrationAction from 'administration/actions/AdministrationAction'
import { StyledFieldSet, StyledLegend } from 'components/StyledElements'
import { hasValue } from 'utils/NumberUtil'
import { getSetting, getSettingJson } from 'utils/SettingUtils'
import HomeAction from 'home/actions/HomeAction'
import { linkContributorsDepartments } from 'carto/constants/CartoConstants'
import VigilanceCard from 'home/components/vigilance/VigilanceCard'
import VigilanceModal from 'home/components/vigilance/VigilanceModal'
import PiezometryAction from 'piezometry/actions/PiezometryAction'
import PluviometryAction from 'pluviometry/actions/PluviometryAction'
import HydrometryAction from 'hydrometry/actions/HydrometryAction'
import CityAction from 'referencial/components/city/actions/CityAction'
import CardMapStation from 'components/map/popup/CardMapStation'
import { THRESHOLD_COLORS_CODES } from 'utils/constants/ColorTheme'
import Select from 'components/forms/Select'
import { LARGE_RADIUS } from 'utils/constants/Theme'
import { MONITORING_OPTIONS } from 'alerting/constants/AlertConstants'

const Img = styled('img')({
    width: '20px',
    height: '25px',
    cursor: 'pointer',
    margin: '0px 5px 0px 5px',
})

const ALERT_STATIONS_DISPLAYED = 'ALERT_STATIONS_DISPLAYED'

const AlertMapTabs = ({
    sites = [],
    onClick = () => { },
    onChange = () => { },
    displayPiezo = true,
    displayPluvio = true,
    displayHydro = true,
    displayInst = false,
    selectedDate,
    hiddenNoThresholdStations,
    searchValue = '',
    onHover = () => { },
    dataTypePiezo = {},
    dataTypeHydro = {},
    dataTypePluvio = {},
    stationsIdByTypeName = {},
    selectedStationType = {},
    monitoringType = MONITORING_OPTIONS.ALL_MONITORING,
}) => {
    const {
        accountUser,
        vigilances,
        applicationSettings,
        installationsLight,
        hydrometricStations,
        piezometryDataTypes,
        pluviometryDataTypes,
        hydrometryDataTypes,
        cities,
    } = useSelector(store => ({
        accountUser: store.AccountReducer.accountUser,
        vigilances: store.HomeReducer.vigilances,
        applicationSettings: store.AdministrationReducer.applicationSettings,
        installationsLight: store.InstallationReducer.installationsLight,
        hydrometricStations: store.HydrometryReducer.hydrometricStations,
        piezometryDataTypes: store.PiezometryReducer.piezometryDataTypes,
        pluviometryDataTypes: store.PluviometryReducer.pluviometryDataTypes,
        hydrometryDataTypes: store.HydrometryReducer.hydrometryDataTypes,
        cities: store.CityReducer.cities,
    }), shallowEqual)

    const [displayAdvanceFilter, setDisplayAdvanceFilter] = useState(false)
    const [open, setOpen] = useState(false)
    const [vigilance, setVigilance] = useState({})
    const [stationSubType, setStationSubType] = useState(undefined)

    const dispatch = useDispatch()

    const showPiezo = stationsIdByTypeName[STATION_TYPE_NAME.piezometry]?.length
    const showHydro = stationsIdByTypeName[STATION_TYPE_NAME.hydrometry]?.length
    const showPluvio = stationsIdByTypeName[STATION_TYPE_NAME.pluviometry]?.length
    const showInst = stationsIdByTypeName[STATION_TYPE_NAME.installation]?.length

    const installationsTypes = uniq([ -1, ...installationsLight.filter(i => !!i.installationType && (stationsIdByTypeName[STATION_TYPE_NAME.installation] || []).includes(i.id)).map(i => i.installationType) ])
    const hydrometersTypes = uniq([ -1, ...hydrometricStations.filter(i => !!i.stationType && (stationsIdByTypeName[STATION_TYPE_NAME.hydrometry] || []).includes(i.id)).map(i => i.stationType) ])

    const defaultDepartment = useMemo(() => {
        const contribSettings = hasValue(accountUser.contributorCode) && getSettingJson(applicationSettings, linkContributorsDepartments)?.find(s => s.contributor === accountUser.contributorCode)
        return contribSettings?.departments || getSetting(applicationSettings, 'meteoAlertDepartments')
    }, [accountUser.contributorCode, applicationSettings])

    useEffect(() => {
        if (hasValue(defaultDepartment) && defaultDepartment.length >= 2) {
            dispatch(HomeAction.fetchVigilances(defaultDepartment))
        }
    }, [defaultDepartment, dispatch])

    useEffect(() => {
        if (keys(vigilance).length) {
            setOpen(true)
        }
    }, [vigilance])

    useEffect(() => {
        if (!piezometryDataTypes.length) {
            dispatch(PiezometryAction.fetchPiezometryDataTypes())
        }
        if (!pluviometryDataTypes.length) {
            dispatch(PluviometryAction.fetchPluviometryDataTypes())
        }
        if (!hydrometryDataTypes.length) {
            dispatch(HydrometryAction.fetchHydrometryDataTypes())
        }
        if (!cities.length) {
            dispatch(CityAction.fetchCities())
        }
    }, [])

    const toControlKey = `${i18n.toControl}#-#${THRESHOLD_COLORS_CODES.LIGHT_GREY}`
    const noThresholdsKey = `${i18n.noThresholds}#-#${THRESHOLD_COLORS_CODES.LIGHT_BLUE}`
    const unknownThresholdKey = `${i18n.unknownThreshold}#-#${THRESHOLD_COLORS_CODES.ORANGE}`
    const monitoringKey = `${i18n.monitoring}#-#${THRESHOLD_COLORS_CODES.GREEN}`

    const orderedThresholds = [i18n.noThresholds, i18n.unknownThreshold, i18n.toControl, i18n.monitoring]

    const thresholdGroup = sites.reduce((acc, s) => {
        s.thresholds.forEach(t => {
            const labelThreshold = t.name || t.title || ''
            if (labelThreshold && !acc[`${labelThreshold}#-#${t.htmlColor || getThresholdColorCode(t.color)}`]) {
                acc[`${labelThreshold}#-#${t.htmlColor || getThresholdColorCode(t.color)}`] = []
            }
        })
        return acc
    }, { [toControlKey]: [], [monitoringKey]: [] })

    const groupCardSites = sites.reduce((acc, s) => {
        const dataType = s.dataType || execByType(s.typeName, {
            piezometry: () => dataTypePiezo,
            hydrometry: () => dataTypeHydro,
            pluviometry: () => dataTypePluvio,
            installation: () => ({ ...s?.thresholds[0], alertLimit: 72 }),
            default: () => {},
        })
        const {
            alertLimit = 120,
        } = dataType
        const dateNow = moment()
        const dateBeforeNbHours = moment(dateNow.subtract(alertLimit, 'hour'))
        const level = (() => {
            if (s.typeName === STATION_TYPE_NAME.installation && dataType.id) {
                return dataType.id
            }
            if ((s.measureDate && s.measureDate.isBefore(dateBeforeNbHours)) || !s.lastMeasure.length) {
                return -1
            }
            if (!s.thresholds.length) {
                return 0
            }
            return s.trend
        })()
        const content = {
            content: (
                <CardMapStation
                    site={s}
                    onClick={onClick}
                    onHover={onHover}
                />
            ),
            color: s.color,
            level,
        }
        if (s.typeName === STATION_TYPE_NAME.installation && s.thresholds?.length > 0) {
            const threshold = s.thresholds[0] || {}
            const thresholdName = `${threshold.name}#-#${threshold.color || THRESHOLD_COLORS_CODES.LIGHT_GREY}`
            if (!acc[thresholdName]) {
                acc[thresholdName] = []
            }
            acc[thresholdName].push(content)
        } else if ((s.measureDate && s.measureDate.isBefore(dateBeforeNbHours)) || !s.lastMeasure.length) {
            if (!acc[toControlKey]) {
                acc[toControlKey] = []
            }
            acc[toControlKey].push(content)
        } else if (!s.thresholds.length) {
            if (!acc[noThresholdsKey]) {
                acc[noThresholdsKey] = []
            }
            acc[noThresholdsKey].push(content)
        } else if (s.thresholds.filter(t => !t.name && !t.title).length) {
            if (!acc[unknownThresholdKey]) {
                acc[unknownThresholdKey] = []
            }
            acc[unknownThresholdKey].push(content)
        } else if (s.trendThreshold.length) {
            s.trendThreshold.forEach(t => {
                if (t.name || t.title) {
                    acc[`${t.name || t.title || ''}#-#${t.htmlColor || getThresholdColorCode(t.color)}`].push(content)
                } else {
                    if (!acc[unknownThresholdKey]) {
                        acc[unknownThresholdKey] = []
                    }
                    acc[unknownThresholdKey].push(content)
                }
            })
        } else {
            if (!acc[monitoringKey]) {
                acc[monitoringKey] = []
            }
            acc[monitoringKey].push(content)
        }
        return acc
    }, thresholdGroup)

    const getThresholdOrder = (name, level, minLevel) => {
        if (orderedThresholds.some(t => t === name)) {
            return (orderedThresholds.findIndex(t => t === name) + minLevel) - orderedThresholds.length
        }
        return level
    }

    const cardList = orderBy(keys(groupCardSites).map(key => {
        const {
            [key]: cards,
        } = groupCardSites
        const maxAlert = maxBy(cards, 'level')
        const minAlert = minBy(cards, 'level')?.level || 0
        const [name, color] = key.split('#-#')
        const cardsLength = cards.length
        const level = hasValue(maxAlert?.level) ? maxAlert.level : -2
        return {
            title: `${name} (${cardsLength})`,
            titleColor: cardsLength ? color : THRESHOLD_COLORS_CODES.LIGHT_GREY,
            cards: orderBy(cards, 'level', 'desc'),
            level,
            order: getThresholdOrder(name, level, minAlert),
        }
    }), 'order', 'desc')

    const groupedVigilances = groupBy(vigilances, 'typeId')

    const handleChangeDisplay = (object) => {
        const settingValue = JSON.stringify({
            [STATION_TYPE_NAME.piezometry]: object.displayPiezo ?? displayPiezo,
            [STATION_TYPE_NAME.hydrometry]: object.displayHydro ?? displayHydro,
            [STATION_TYPE_NAME.pluviometry]: object.displayPluvio ?? displayPluvio,
            [STATION_TYPE_NAME.installation]: object.displayInst ?? displayInst,
        })
        dispatch(AdministrationAction.updateSetting(ALERT_STATIONS_DISPLAYED, settingValue))
        onChange(object)
    }

    const handleChangeStationType = (st, selected) => onChange({
        selectedStationType: {
            ...selectedStationType,
            [st]: selectedStationType[st]?.map(s => ({ ...s, selected })),
        },
    })

    const getStationTypesFiltered = () => {
        switch (stationSubType) {
            case STATION_TYPE_NAME.installation:
                return selectedStationType[stationSubType]?.filter(st => installationsTypes.includes(st.id)) || []
            case STATION_TYPE_NAME.hydrometry:
                return selectedStationType[stationSubType]?.filter(st => hydrometersTypes.includes(st.id)) || []
            default:
                return selectedStationType[stationSubType] || []
        }
    }

    const monitoringOptions = [{
        id: MONITORING_OPTIONS.ALL_MONITORING,
        name: i18n.allMonitoring,
    }, {
        id: MONITORING_OPTIONS.SITUATION_FLOOD,
        name: i18n.situationFlood,
    }, {
        id: MONITORING_OPTIONS.LOWWATER,
        name: i18n.lowWater,
    }]

    return (
        <Box>
            <Box sx={{ padding: '0.25rem 0.75rem 0.75rem', backgroundColor: '#DDD', borderRadius: LARGE_RADIUS }}>
                <Grid container sx={{ paddingTop: '0.75rem' }}>
                    <Grid item xs={12}>
                        <Input
                            title={i18n.search}
                            value={searchValue}
                            onChange={v => onChange({ searchValue: v })}
                        />
                    </Grid>
                </Grid>
                <Grid container>
                    {!!showPiezo && (
                        <Grid container item xs={2.5} alignItems='center' sx={{ padding: '0 0.75rem' }}>
                            <Grid
                                item
                                onClick={() => {
                                    handleChangeDisplay({ displayPiezo: !displayPiezo })
                                }}
                            >
                                <Checkbox
                                    label={<Img src={piezometryMarker} />}
                                    checked={displayPiezo}
                                    tooltip={i18n.piezometers}
                                />
                            </Grid>
                        </Grid>
                    )}
                    {!!showPluvio && (
                        <Grid container item xs={2.5} alignItems='center' sx={{ padding: '0 0.75rem' }}>
                            <Grid
                                item
                                onClick={() => {
                                    handleChangeDisplay({ displayPluvio: !displayPluvio })
                                }}
                            >
                                <Checkbox
                                    label={<Img src={pluviometryMarker} />}
                                    checked={displayPluvio}
                                    tooltip={i18n.pluviometers}
                                />
                            </Grid>
                        </Grid>
                    )}
                    {!!showHydro && (
                        <Grid container item xs={2.5} alignItems='center' sx={{ padding: '0 0.75rem' }}>
                            <Grid
                                item
                                onClick={() => {
                                    handleChangeDisplay({ displayHydro: !displayHydro })
                                    handleChangeStationType(STATION_TYPE_NAME.hydrometry, !displayHydro)
                                }}
                            >
                                <Checkbox
                                    label={<Img src={hydrometryMarker} />}
                                    checked={displayHydro}
                                    tooltip={i18n.hydrometricStations}
                                />
                            </Grid>
                            <Grid item>
                                <i className='material-icons clickable' onClick={() => setStationSubType(p => p !== STATION_TYPE_NAME.hydrometry ? STATION_TYPE_NAME.hydrometry : undefined)}>{stationSubType === STATION_TYPE_NAME.hydrometry ? 'expand_less' : 'expand_more'}</i>
                            </Grid>
                        </Grid>
                    )}
                    {!!showInst && (
                        <Grid container item xs={2.5} alignItems='center' sx={{ padding: '0 0.75rem' }}>
                            <Grid
                                item
                                onClick={() => {
                                    handleChangeDisplay({ displayInst: !displayInst })
                                    handleChangeStationType(STATION_TYPE_NAME.installation, !displayInst)
                                }}
                            >
                                <Checkbox
                                    label={<Img src={installationMarker} />}
                                    checked={displayInst}
                                    tooltip={i18n.installations}
                                />
                            </Grid>
                            <Grid item>
                                <i className='material-icons clickable' onClick={() => setStationSubType(p => p !== STATION_TYPE_NAME.installation ? STATION_TYPE_NAME.installation : undefined)}>{stationSubType === STATION_TYPE_NAME.installation ? 'expand_less' : 'expand_more'}</i>
                            </Grid>
                        </Grid>
                    )}
                    <Grid container item xs={2 + ([showPiezo, showHydro, showPluvio, showInst].filter(show => !show).length * 2)} justifyContent='flex-end'>
                        <Grid item>
                            <i className='material-icons clickable' style={{ border: '1px solid #000', borderRadius: '20px' }} onClick={() => setDisplayAdvanceFilter(p => !p)}>{displayAdvanceFilter ? 'expand_less' : 'expand_more'}</i>
                        </Grid>
                    </Grid>
                </Grid>
                {!!stationSubType && (
                    <Grid container sx={{ marginTop: '1rem' }} alignItems='center'>
                        {getStationTypesFiltered().map((st, i) => (
                            <Grid
                                item
                                xs={3}
                                sx={{ padding: '0 0.75rem' }}
                                key={i}
                                onClick={() => onChange({
                                    selectedStationType: {
                                        ...selectedStationType,
                                        [stationSubType]: orderBy([ ...selectedStationType[stationSubType].filter(s => s.id !== st.id), { ...st, selected: !st.selected } ], 'id'),
                                    },
                                })}
                            >
                                <Checkbox
                                    label={st.label}
                                    checked={st.selected}
                                    tooltip={st.label}
                                />
                            </Grid>
                        ))}
                    </Grid>
                )}
                <Collapse in={displayAdvanceFilter}>
                    <Grid
                        container
                        sx={{ paddingTop: '0.75rem' }}
                        rowSpacing={0.5}
                        columnSpacing={2}
                        alignItems='baseline'
                    >
                        <Grid item xs={4}>
                            <SimpleDatePicker
                                value={selectedDate}
                                id='date'
                                label={i18n.date}
                                onChange={v => onChange({ selectedDate: v })}
                            />
                        </Grid>
                        <Grid item xs={8} onClick={() => onChange({ hiddenNoThresholdStations: !hiddenNoThresholdStations })}>
                            <Checkbox
                                label={i18n.hiddenNoThresholdStations}
                                checked={hiddenNoThresholdStations}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Select
                                options={monitoringOptions}
                                label={i18n.monitoringType}
                                onChange={v => onChange({ monitoringType: v })}
                                value={monitoringType}
                                noNullValue
                            />
                        </Grid>
                    </Grid>
                </Collapse>
            </Box>
            {!!vigilances.length && (
                <StyledFieldSet>
                    <StyledLegend>{i18n.weatherWarnings}</StyledLegend>
                    {keys(groupedVigilances).map((key, i) => {
                        const bgColor = getVigilanceColor(groupedVigilances[key][0]?.vigilance)
                        const color = getTextColorByBackground(bgColor)
                        return (
                            <AccordionMUI
                                key={key}
                                className={`${groupedVigilances[key][0]?.vigilance ? bgColor : color} margin-bottom-1`}
                                defaultExpanded={i === 0}
                                round
                            >
                                <AccordionSummary
                                    className='smallSubListTitle'
                                    expandIcon={<i className='material-icons' style={{ color }}>expand_more</i>}
                                    style={{ color, minHeight: 40, height: 40, borderRadius: '5px' }}
                                    round
                                >
                                    {groupedVigilances[key][0]?.info || i18n.unknown}
                                </AccordionSummary>
                                <AccordionDetailsMUI nopadding>
                                    {groupedVigilances[key]?.map((vg, j) => (
                                        <a key={`${key}_${j}`} onClick={() => setVigilance(vg)} target='_blank' style={{ cursor: 'pointer', width: '100%' }}>
                                            <VigilanceCard vigilance={vg} smallCard />
                                        </a>
                                    ))}
                                </AccordionDetailsMUI>
                            </AccordionMUI>
                        )
                    })}
                    <VigilanceModal
                        open={open}
                        vigilance={vigilance}
                        closePopin={() => {
                            setOpen(false)
                            setVigilance({})
                        }}
                    />
                </StyledFieldSet>
            )}
            <StyledFieldSet>
                <StyledLegend>{i18n.situationByThresholds}</StyledLegend>
                {cardList.filter(cd => cd.cards.length).map((list, i) => {
                    const color = getTextColorByBackground(list.titleColor)
                    return (
                        <AccordionMUI
                            key={list.level}
                            defaultExpanded={i === 0}
                            round
                            sx={{
                                marginBottom: (i < (cardList.filter(cd => cd.cards.length).length - 1)) && '1rem',
                                marginTop: i === 0 && '1rem',
                            }}
                        >
                            <AccordionSummary
                                className='smallSubListTitle'
                                expandIcon={<i className='material-icons' style={{ color }}>expand_more</i>}
                                style={{ backgroundColor: list.titleColor, color, minHeight: 40, height: 40, borderRadius: '5px' }}
                                round
                            >
                                {list.title}
                            </AccordionSummary>
                            <AccordionDetailsMUI sx={{ padding: '1rem' }}>
                                {list.cards.map((card, iC) => (
                                    <Grid container item xs={12} key={iC} sx={{ paddingBottom: (iC < (list.cards.length - 1)) && '1rem' }}>
                                        {card.content}
                                    </Grid>
                                ))}
                            </AccordionDetailsMUI>
                        </AccordionMUI>
                    )
                })}
            </StyledFieldSet>
        </Box>
    )
}

AlertMapTabs.propTypes = {
    sites: PropTypes.arrayOf(PropTypes.instanceOf(PropTypes.object)),
    onClick: PropTypes.func,
    onHover: PropTypes.func,
    onChange: PropTypes.func,
    displayPiezo: PropTypes.bool,
    displayPluvio: PropTypes.bool,
    displayHydro: PropTypes.bool,
    displayInst: PropTypes.bool,
    selectedDate: PropTypes.number,
    hiddenNoThresholdStations: PropTypes.bool,
    searchValue: PropTypes.string,
    dataTypePiezo: PropTypes.shape({}),
    dataTypeHydro: PropTypes.shape({}),
    dataTypePluvio: PropTypes.shape({}),
    stationsIdByTypeName: PropTypes.shape({
        [STATION_TYPE_NAME.piezometry]: PropTypes.arrayOf(PropTypes.number),
        [STATION_TYPE_NAME.hydrometry]: PropTypes.arrayOf(PropTypes.number),
        [STATION_TYPE_NAME.pluviometry]: PropTypes.arrayOf(PropTypes.number),
        [STATION_TYPE_NAME.installation]: PropTypes.arrayOf(PropTypes.number),
    }),
    selectedStationType: PropTypes.shape({}),
    monitoringType: PropTypes.number,
}

export default AlertMapTabs