module.exports = {
    EVENT_ACTION: {
        MESURE_TENSION_TELETRANSMISSION: 'MESURE_TENSION_TELETRANSMISSION',
        REMPLACEMENT_PILES_TELETRANSMISSION: 'REMPLACEMENT_PILES_TELETRANSMISSION',
        TENSION_PILES_CAPTEUR: 'TENSION_PILES_CAPTEUR',
        REMPLACEMENT_PILES_CAPTEUR: 'REMPLACEMENT_PILES_CAPTEUR',
        MESURE_TENSION_CENTRALE: 'MESURE_TENSION_CENTRALE',
        REMPLACEMENT_PILES_CENTRALE: 'REMPLACEMENT_PILES_CENTRALE',
        MESURE_PROFONDEUR_STATION: 'MESURE_PROFONDEUR_STATION',
        TEST_TRANSMISSION: 'TEST_TRANSMISSION',
        FIRMWARE_MIS_A_JOUR: 'FIRMWARE_MIS_A_JOUR',
        REINITIALISATION_STATION: 'REINITIALISATION_STATION',
        REMPLACEMENT_MESURE_NIVEAU: 'REMPLACEMENT_MESURE_NIVEAU',
        RECALIBRATION_CAPTEUR: 'RECALIBRATION_CAPTEUR',
        DESSICANT_REMPLACE: 'DESSICANT_REMPLACE',
        RECUPERATION_DES_DONNEES: 'RECUPERATION_DES_DONNEES',
        JOINTS_TORIQUES_GRAISSES: 'JOINTS_TORIQUES_GRAISSES',
        DATE_HEURE_RECALIBREES: 'DATE_HEURE_RECALIBREES',
    },
    STEPS: {
        DEFAULT: 0,
        DIAGNOSTIC: 1,
        ACTION: 2,
        MATERIEL_ADD: 3,
        MATERIEL_CHOICE: 5,
        MATERIEL_SITUATION_OF_LAST: 6,
        MATERIEL_CATEGORY_CHOICE: 7,
        LANDMARK_ADD: 8,
    },
}