import { groupBy } from 'lodash'
import { useMemo } from 'react'
import { shallowEqual, useSelector } from 'react-redux'

const useSandreParameters = () => {
    const {
        sandreCodes,
    } = useSelector(store => ({
        sandreCodes: store.ReferencialReducer.sandreCodes,
    }), shallowEqual)

    const sandreParametersGroup = useMemo(() => {
        const filteredSandreCodes = sandreCodes.filter(sc => sc.field.startsWith('PARAMETRE.'))
        return groupBy(filteredSandreCodes, sc => sc.field.split('.')[1])
    }, [sandreCodes])

    return sandreParametersGroup
}

export default useSandreParameters