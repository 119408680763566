export default class AquiferItem {
    constructor(obj) {
        this.code = obj.code
        this.id = obj.code
        this.name = obj.name
        this.margatCode = obj.margatCode
        this.commentaire = obj.comment
        this.extensionpartielibre = obj.extensionpartielibre
        this.precisionextlibre = obj.precisionextlibre
        this.extensionpartiecaptive = obj.extensionpartiecaptive
        this.precisionextcaptive = obj.precisionextcaptive

        this.labelWithCode = obj.code ? `[${obj.code}] ${obj.name || ''}` : (obj.name || '')
    }
}