import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import Table from '../../../components/datatable/Table'
import SieauAction from '../../../components/sieau/SieauAction'
import DtoDistributionUnit from '../../../distributionUnit/dto/DtoDistributionUnit'
import CityDto from '../../../referencial/components/city/dto/CityDto'
import { getExport, setModal } from '../../../utils/linkUtils'
import { arrayOf } from '../../../utils/StoreUtils'
import AssociatedTownModal from './modals/AssociatedTownModal'

const headers = ['code', 'name', 'population', 'servedPopulation', 'memberDisplay']
const exportHeaders = ['code', 'name', 'population', 'servedPopulation', 'memberExport']

const StationAssociatedTownsPanel = ({
    station = {},
    readMode = false,
    onChange = () => { },
    setPopup = () => { },
    cities = [],
    invertedHeaderStyle = false,
}) => {
    const stationAssociatedTowns = station.link_associatedTowns || []
    const tableData = stationAssociatedTowns.map(at => {
        const city = cities.find(c => c.code === at.city) || {}
        return {
            ...city,
            ...at,
            memberDisplay: at.member ? <i className='material-icons tiny table-checkbox'>check_box</i> : null,
            memberExport: at.member ? i18n.yes : i18n.no,
        }
    })
    const exportAction = getExport(tableData, i18n.cities, exportHeaders, false, {}, invertedHeaderStyle ? 'black' : 'white')
    const actions = !readMode ? [{
        iconName: 'note_add',
        tooltip: i18n.add,
        color: invertedHeaderStyle ? 'black' : 'white',
        onClick: () => setModal(i18n.newTown, <AssociatedTownModal id={station.id} saveResult={r => onChange({ link_associatedTowns: [...stationAssociatedTowns, r] })} />, setPopup),
    }, exportAction] : [exportAction]

    return (
        <Table
            title={ i18n.cities }
            condensed
            data={ tableData }
            type={{ headers }}
            customHeaders={{ memberDisplay: i18n.member }}
            sortable={ !!tableData.length }
            actions={ actions }
            alterable={!readMode}
            invertedHeaderStyle={invertedHeaderStyle}
            round
            onAlter={
                element => setModal(i18n.editTown,
                    <AssociatedTownModal
                        associatedTown={element}
                        saveResult={ r => onChange({
                            link_associatedTowns: [
                                ...stationAssociatedTowns.filter(at => at.city !== element.city),
                                r,
                            ],
                        })}
                    />
                    , setPopup)
            }
            deletable={ !readMode }
            onDelete={(element) => onChange({ link_associatedTowns: stationAssociatedTowns.filter(at => at.city !== element.city) })}
        />
    )
}

StationAssociatedTownsPanel.propTypes = {
    station: PropTypes.instanceOf(DtoDistributionUnit),
    readMode: PropTypes.bool,
    onChange: PropTypes.func,
    setPopup: PropTypes.func,
    cities: arrayOf(CityDto),
    invertedHeaderStyle: PropTypes.bool,
}

const mapStateToProps = store => ({
    cities: store.CityReducer.cities,
})

const mapDispatchToProps = {
    setPopup: SieauAction.setPopup,
}

export default connect(mapStateToProps, mapDispatchToProps)(StationAssociatedTownsPanel)