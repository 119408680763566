/* eslint-disable camelcase */
import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import Table from '../../../../components/datatable/Table'
import DtoSTEP from '../dto/DtoSTEP'
import { getExport } from '../../../../utils/linkUtils'
import { hasValue } from 'utils/NumberUtil'
import useSandreList from 'utils/customHook/useSandreList'
import { SANDRE } from 'referencial/constants/ReferencialConstants'
import { getLabel } from 'utils/StoreUtils'
import STEPFollowPointModal from './modal/STEPFollowPointModal'

const headers = ['sandreCode', 'internalCodeSing', 'comment', 'x', 'y', 'projection']

const STEPFollowPointsPanel = ({
    STEP = {},
    readMode = false,
    onChange = () => { },
}) => {
    const [isOpen, setIsOpen] = useState(false)
    const [selectedPoint, setSelectedPoint] = useState(false)

    const projections = useSandreList(SANDRE.PROJECTION)
    const referencials = useSandreList(SANDRE.STEP_POINTS_REFERENTIELS)

    const STEPFollowPoints = STEP.link_followPoints.map((c, index) => ({
        ...c,
        index,
    }))

    const tableData = useMemo(() => STEPFollowPoints.map(sfp => {
        return {
            ...sfp,
            projection: getLabel(projections, sfp.projection),
            sandreCode: getLabel(referencials, sfp.sandreCode, 'mnemonique'),
            internalCodeSing: sfp.internalCode,
        }
    }), [STEPFollowPoints, projections, referencials])

    const updateFollowPoints = (point) => {
        if (!hasValue(selectedPoint.index)) {
            onChange({ link_followPoints: [...STEPFollowPoints, point] })
        } else {
            onChange({
                link_followPoints: STEPFollowPoints.map((d, i) => i === selectedPoint.index ? point : d),
            })
        }
    }

    const exportAction = getExport(tableData, i18n.followPoints, headers)
    const actions = !readMode
        ? [
            {
                iconName: 'note_add',
                tooltip: i18n.add,
                onClick: () => {
                    setIsOpen(true)
                    setSelectedPoint({})
                },
            },
            exportAction,
        ]
        : [exportAction]

    return (
        <>
            <Table
                title={i18n.followPoints}
                condensed
                data={tableData}
                className='blue'
                type={{ headers }}
                sortable={!!tableData.length}
                actions={actions}
                alterable={!readMode}
                onAlter={(element) => {
                    setSelectedPoint(STEPFollowPoints.find((c) => element.index === c.index))
                    setIsOpen(true)
                }}
                deletable={!readMode}
                onDelete={(element) =>
                    onChange({
                        link_followPoints: STEPFollowPoints.filter(
                            (at) => at.index !== element.index,
                        ),
                    })
                }
            />
            {isOpen && <STEPFollowPointModal
                idStation={STEP.idStation}
                followPoint={selectedPoint}
                setIsOpen={setIsOpen}
                isOpen={isOpen}
                onSave={updateFollowPoints}
            />}
        </>
    )
}

STEPFollowPointsPanel.propTypes = {
    STEP: PropTypes.instanceOf(DtoSTEP),
    readMode: PropTypes.bool,
    onChange: PropTypes.func,
    isOpen: PropTypes.bool,
    setIsOpen: PropTypes.func,
}

export default STEPFollowPointsPanel
