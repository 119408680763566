import { Grid, Button } from '@mui/material'
import PropTypes from 'prop-types'
import React, { useEffect, useMemo, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import ToastrAction from 'toastr/actions/ToastrAction'
import { DialogActionsMUI, DialogContentMUI, DialogMUI, DialogTitleMUI } from 'components/styled/Dialog'
import Icon from 'components/icon/Icon'
import { StyledFieldSet, StyledLegend } from 'components/StyledElements'
import RadioButtons from 'components/forms/RadioButtons'
import moment from 'moment'
import { getYear } from 'utils/DateUtil'
import { hasValue } from 'utils/NumberUtil'
import Table from 'components/datatable/Table'
import { nbPerPageLabelMedium } from 'referencial/constants/ReferencialConstants'
import AgriAction from 'agriAdministration/actions/AgriAction'
import { getTextSlot, setTableIndex } from 'exploitations/utils/AgriUtils'
import { useParams } from 'react-router-dom'

const headers = ['samplerName', 'pumpType', 'material', 'serialNumber', 'nature', 'identifier']

const AddExploitationWaterTurnsDialog = ({
    openAdd = false,
    setOpenAdd = () => { },
    addWaterTurns = () => { },
}) => {
    const dispatch = useDispatch()
    const { id } = useParams()

    const {
        waterTurnsSlots,
    } = useSelector(store => ({
        waterTurnsSlots: store.AgriReducer.waterTurnsSlots,
    }), shallowEqual)

    const [exploitationsToAdd, setExploitationsToAdd] = useState([])
    const [selectedSlot, setSelectedSlot] = useState()

    const nowYear = useMemo(() => getYear(moment.now()), [])

    const radioSlots = useMemo(() => {
        return waterTurnsSlots.filter(d => d.year === nowYear).map(d => {
            return {
                ...d,
                name: getTextSlot(d),
            }
        })
    }, [waterTurnsSlots])

    useEffect(() => {
        if (openAdd) {
            setSelectedSlot(waterTurnsSlots.filter(d => d.year === nowYear)?.[0]?.id)
        }
    }, [openAdd])

    const actions = [{
        iconName: 'add',
        tooltip: i18n.add,
        'data-cy': 'add_tmp_exp_water_turns_button',
        onClick: () => {
            if (hasValue(selectedSlot)) {
                AgriAction.addWaterTurns({ idExploitations: [parseInt(id)], idSlot: selectedSlot }).then(json => {
                    setExploitationsToAdd(setTableIndex([...exploitationsToAdd, ...json]))
                })
            } else {
                dispatch(ToastrAction.warning(i18n.pleaseCompleteAllRequiredField))
            }
        },
    }]

    return (
        <DialogMUI maxWidth='lg' fullWidth open={openAdd} data-cy='dialog_add_exp_water_turns'>
            <DialogTitleMUI>
                <Grid container justifyContent='space-between' alignItems='center'>
                    <Grid item >
                        {i18n.addWaterTurns}
                    </Grid>
                    <Grid item>
                        <Icon
                            style={{ color: 'white' }}
                            size='small'
                            icon={'close'}
                            onClick={() => {
                                setExploitationsToAdd([])
                                setSelectedSlot()
                                setOpenAdd(false)
                            }}
                        />
                    </Grid>
                </Grid>
            </DialogTitleMUI>
            <DialogContentMUI>
                <StyledFieldSet>
                    <StyledLegend className='padding-left-1 padding-right-1'>{i18n.slots}*</StyledLegend>
                    <RadioButtons
                        col={12}
                        className='no-padding no-margin'
                        elements={radioSlots}
                        selected={selectedSlot}
                        onChange={setSelectedSlot}
                    />
                </StyledFieldSet>
                <StyledFieldSet>
                    <StyledLegend className='padding-left-1 padding-right-1'>{i18n.waterTurns}</StyledLegend>
                    <Table
                        title={i18n.waterTurns}
                        actions={actions}
                        data={exploitationsToAdd}
                        type={{ headers }}
                        readMode={false}
                        sortable
                        condensed
                        paging
                        round
                        nbPerPageLabel={nbPerPageLabelMedium}
                        deletable={true}
                        onDelete={v => setExploitationsToAdd(exploitationsToAdd.filter(ead => ead.index !== v.index))}
                    />
                </StyledFieldSet>
            </DialogContentMUI>
            <DialogActionsMUI>
                <Grid xs={12} container justifyContent='flex-end' alignItems='flex-end'>
                    <Grid className='padding-left-1'>
                        <Button
                            variant='contained'
                            onClick={() => {
                                if (exploitationsToAdd.length) {
                                    addWaterTurns(exploitationsToAdd)
                                    setExploitationsToAdd([])
                                    setSelectedSlot()
                                    setOpenAdd(false)
                                } else {
                                    dispatch(ToastrAction.warning(i18n.nothingToDoOrProcess))
                                }
                            }}
                            data-cy='add_exp_water_turns_button'
                        >
                            {i18n.add}
                        </Button>
                    </Grid>
                </Grid>
            </DialogActionsMUI>
        </DialogMUI>
    )
}

AddExploitationWaterTurnsDialog.propTypes = {
    openAdd: PropTypes.bool,
    setOpenAdd: PropTypes.func,
    addWaterTurns: PropTypes.func,
}

export default AddExploitationWaterTurnsDialog