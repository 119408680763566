import ToastrAction from 'toastr/actions/ToastrAction'
import WaitAction from 'wait/WaitAction'
import ApplicationConf from '../../conf/ApplicationConf'
import {
    checkAuth,
    checkError,
    genericPromise,
    getAuthorization,
    getJson,
    getPutFetch,
    promiseAllProgress,
} from '../../utils/ActionUtils'
import AppStore from '../../store/AppStore'
import i18n from 'simple-react-i18n'
import { every } from 'lodash'
import CityAction from '../../referencial/components/city/actions/CityAction'
import QualityAction from '../../quality/actions/QualityAction'
import StationAction from '../../station/actions/StationAction'
import ParameterAction from '../../referencial/components/parameter/actions/ParameterAction'
import UnitAction from '../../referencial/components/unit/actions/UnitAction'
import LogAction from '../../log/actions/LogAction'
import DtoExportTerritoryActivitie from 'installation/dto/installation/DtoExportTerritoryActivitie'
import DtoExportPollutantsEmissions from 'installation/dto/installation/DtoExportPollutantsEmissions'
import { InstallationActionConstant } from 'installation/reducers/InstallationReducer'
import { QualityActionConstant } from 'quality/reducers/QualityReducer'

const InstallationAction = {
    promiseInstallations() {
        return fetch(ApplicationConf.installation.getAll(), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
    },
    fetchInstallations() {
        return (dispatch) => {
            return InstallationAction.promiseInstallations()
                .then(json => {
                    dispatch(InstallationActionConstant.ALL_INSTALLATIONS(json))
                })
        }
    },

    promiseInstallationsLight() {
        return genericPromise(`${ApplicationConf.installation.getAll()}?lightMode=true`)
    },
    fetchInstallationsLight: () => dispatch => {
        return InstallationAction.promiseInstallationsLight()
            .then((json = []) => {
                dispatch(InstallationActionConstant.ALL_INSTALLATIONS_LIGHT(json))
                return json
            })
            .catch(err => {
                dispatch(WaitAction.waitStop())
                dispatch(LogAction.logError(`${i18n.fetchError} : ${err}`))
                dispatch(ToastrAction.error(i18n.fetchError))
            })
    },

    promiseInstallationsWithGeo() {
        return fetch(ApplicationConf.installation.getAllWithGeo(), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
    },
    fetchInstallationsWithGeo() {
        return (dispatch) => {
            return InstallationAction.promiseInstallationsWithGeo()
                .then(json => {
                    dispatch(InstallationActionConstant.ALL_INSTALLATIONS_GEO(json))
                })
        }
    },

    promiseInstallationsByExploitationId(id) {
        return fetch(ApplicationConf.installation.getExploitationInstallationsGeo(id), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
    },

    fetchInstallationsByExploitationId(id) {
        return (dispatch) => {
            return InstallationAction.promiseInstallationsByExploitationId(id)
                .then((json = []) => {
                    dispatch(InstallationActionConstant.EXPLOITATION_INSTALLATIONS_GEO(json))
                })
                .catch((err) => {
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.installations} : ${err}`))
                    ToastrAction.error(i18n.fetchError + i18n.installations)
                })
        }
    },

    promiseCavity(id) {
        return genericPromise(ApplicationConf.installation.cavity(id))
    },
    fetchCavity(id) {
        return (dispatch) => {
            return InstallationAction.promiseCavity(id)
                .then((json=[]) => {
                    dispatch(InstallationActionConstant.CAVITY(json))
                })
                .catch(err => {
                    dispatch(WaitAction.waitStop())
                    dispatch(LogAction.logError(`${i18n.fetchError} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError))
                })
        }
    },

    promiseIndustrialSite(id) {
        return genericPromise(ApplicationConf.installation.industrialSite(id)).catch(json => {
            if (json.message !== '404') {
                throw new Error(json.error)
            }
        })
    },
    fetchIndustrialSite(id) {
        return (dispatch) => {
            return InstallationAction.promiseIndustrialSite(id)
                .then((json=[]) => {
                    dispatch(InstallationActionConstant.INDUSTRIAL_SITE(json))
                })
                .catch(err => {
                    dispatch(WaitAction.waitStop())
                    dispatch(LogAction.logError(`${i18n.fetchError} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError))
                })
        }
    },

    promiseIndustrialSites() {
        return genericPromise(ApplicationConf.installation.industrialSites())
    },
    fetchIndustrialSites() {
        return (dispatch) => {
            return InstallationAction.promiseIndustrialSites()
                .then((json=[]) => {
                    dispatch(InstallationActionConstant.INDUSTRIAL_SITES(json))
                })
                .catch(err => {
                    dispatch(WaitAction.waitStop())
                    dispatch(LogAction.logError(`${i18n.fetchError} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError))
                })
        }
    },

    loadIndustrialSiteDashboard() {
        return function (dispatch) {
            return Promise.all([
                CityAction.promiseCities(),
                QualityAction.promiseStatus(),
            ]).then(jsonTab => {
                dispatch(CityAction.receiveCities(jsonTab[0]))
                dispatch(QualityActionConstant.receiveStatus(jsonTab[1]))
            })
        }
    },

    promiseFlowObstructions() {
        return genericPromise(ApplicationConf.installation.flowObstructions())
    },
    fetchFlowObstructions() {
        return (dispatch) => {
            return InstallationAction.promiseFlowObstructions()
                .then((json=[]) => {
                    dispatch(InstallationActionConstant.receiveFlowObstructions(json))
                })
                .catch(err => {
                    dispatch(WaitAction.waitStop())
                    dispatch(LogAction.logError(`${i18n.fetchError} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError))
                })
        }
    },

    promiseFlowObstruction(id) {
        return fetch(ApplicationConf.installation.flowObstruction(id), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then(json => checkError(json, {
                404: () => ({}),
            }))
    },

    fetchFlowObstruction(id) {
        return (dispatch) => {
            return InstallationAction.promiseFlowObstruction(id)
                .then((json={}) => {
                    dispatch(InstallationActionConstant.FLOW_OBSTRUCTION(json))
                })
                .catch(err => {
                    dispatch(WaitAction.waitStop())
                    dispatch(LogAction.logError(`${i18n.fetchError} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError))
                })
        }
    },

    updateFlowObstruction(flowObstruction) {
        return (dispatch) => {
            return getPutFetch(ApplicationConf.installation.flowObstructions(), flowObstruction)
                .then(json => {
                    if (json.update >= 0 || json.insert >= 0) {
                        dispatch(InstallationActionConstant.updateInstallationFlowObstruction(flowObstruction))
                        dispatch(ToastrAction.success(i18n.elementUpdateSuccess))
                    } else {
                        throw new Error(json)
                    }
                }).catch((err) => {
                    dispatch(WaitAction.waitStop())
                    dispatch(LogAction.logError(`${i18n.updateError + i18n.flowObstruction} : ${err}`))
                    dispatch(ToastrAction.error(i18n.updateError + i18n.flowObstruction))
                })
        }
    },

    promisePollutedSoil(id) {
        return genericPromise(ApplicationConf.installation.pollutedSoil(id))
    },
    fetchPollutedSoil(id) {
        return (dispatch) => {
            return InstallationAction.promisePollutedSoil(id)
                .then((json=[]) => {
                    dispatch(InstallationActionConstant.POLLUTED_SOIL(json))
                })
                .catch(err => {
                    dispatch(WaitAction.waitStop())
                    dispatch(LogAction.logError(`${i18n.fetchError} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError))
                })
        }
    },

    promisePollutedSoils() {
        return genericPromise(ApplicationConf.installation.pollutedSoils())
    },
    fetchPollutedSoils() {
        return (dispatch) => {
            return InstallationAction.promisePollutedSoils()
                .then((json=[]) => {
                    dispatch(InstallationActionConstant.POLLUTED_SOILS(json))
                })
                .catch(err => {
                    dispatch(WaitAction.waitStop())
                    dispatch(LogAction.logError(`${i18n.fetchError} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError))
                })
        }
    },

    promiseActivities() {
        return genericPromise(ApplicationConf.installation.activities())
    },
    fetchActivities() {
        return (dispatch) => {
            return InstallationAction.promiseActivities()
                .then((json=[]) => {
                    dispatch(InstallationActionConstant.INSTALLATION_ACTIVITIES(json))
                })
                .catch(err => {
                    dispatch(WaitAction.waitStop())
                    dispatch(LogAction.logError(`${i18n.fetchError} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError))
                })
        }
    },

    fetchInstallation(id) {
        return (dispatch) => {
            dispatch(WaitAction.waitStart())
            return fetch(ApplicationConf.installation.get(id), {
                method: 'GET',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then(e => checkError(e, {
                    404: () => AppStore.dispatch(ToastrAction.error(i18n.unreachableStation)),
                }))
                .then(json => {
                    if (json.errors) {
                        dispatch(ToastrAction.error(i18n.fetchError + i18n.installation))
                        dispatch(LogAction.logError(`${i18n.fetchError + i18n.installation} : ${json.errors}`))
                    }
                    dispatch(WaitAction.waitStop())
                    dispatch(InstallationActionConstant.INSTALLATION(json))
                })
                .catch(err => {
                    dispatch(WaitAction.waitStop())
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.installation} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError + i18n.installation))
                })
        }
    },

    deleteInstallation(id, callback) {
        return (dispatch) => {
            dispatch(WaitAction.waitStart())
            return fetch(ApplicationConf.installation.installationPath(), {
                method: 'DELETE',
                headers: getAuthorization(),
                body: JSON.stringify({ id }),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(json => {
                    if (json.delete >= 1) {
                        dispatch(WaitAction.waitStop())
                        dispatch(InstallationActionConstant.DELETE_INSTALLATION(id))
                        dispatch(ToastrAction.success(i18n.elementDeleteSuccess))
                        callback()
                    } else {
                        throw new Error(json)
                    }
                })
                .catch((err) => {
                    dispatch(WaitAction.waitStop())
                    dispatch(LogAction.logError(`${i18n.deleteError + i18n.installation} : ${err}`))
                    dispatch(ToastrAction.error(i18n.deleteError + i18n.installation))
                })
        }
    },

    createInstallation(newInstallation, callback) {
        return function (dispatch) {
            dispatch(WaitAction.waitStart())
            return fetch(ApplicationConf.installation.installationPath(), {
                method: 'POST',
                headers: getAuthorization(),
                body: JSON.stringify(newInstallation),
            })
                .then(checkAuth)
                .then(getJson)
                .then(json => checkError(json, {
                    409: () => dispatch(ToastrAction.error(i18n.bssCodeAlreadyExists)),
                }))
                .then(json => {
                    if (json.insert >= 1 && json.id) {
                        dispatch(WaitAction.waitStop())
                        dispatch(InstallationActionConstant.CREATE_INSTALLATION({ ...newInstallation, id: json.id }))
                        dispatch(ToastrAction.success(i18n.elementCreateSuccess))
                        callback(json.id)
                    } else {
                        throw new Error(json)
                    }
                })
                .catch((err) => {
                    dispatch(WaitAction.waitStop())
                    dispatch(LogAction.logError(`${i18n.createError + i18n.installation} : ${err}`))
                    dispatch(ToastrAction.error(i18n.createError + i18n.installation))
                })
        }
    },

    fetchInstallationAssociations(id, callback = () => { }) {
        return (dispatch) => {
            return StationAction.promiseAssociatedSites(id, 7)
                .then((json = []) => {
                    dispatch(InstallationActionConstant.INSTALLATION_ASSOCIATIONS(json))
                    callback()
                })
        }
    },

    reset: () => dispatch => dispatch(InstallationActionConstant.RESET_INSTALLATION()),

    fetchInstallationBorehole(id) {
        return (dispatch) => {
            return fetch(ApplicationConf.installation.getBorehole(id), {
                method: 'GET',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then(e => checkError(e, {
                    404: () => { },
                }))
                .then(json => {
                    if (json.errors) {
                        throw new Error()
                    } else {
                        dispatch(WaitAction.waitStop())
                        dispatch(InstallationActionConstant.INSTALLATION_BOREHOLE(json))
                    }
                })
                .catch(err => {
                    dispatch(WaitAction.waitStop())
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.borehole} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError + i18n.borehole))
                })
        }
    },

    fetchInstallationCapture(id) {
        return (dispatch) => {
            return fetch(ApplicationConf.installation.getCapture(id), {
                method: 'GET',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then(e => checkError(e, {
                    404: () => { },
                }))
                .then(json => {
                    if (json.errors) {
                        throw new Error()
                    } else {
                        dispatch(WaitAction.waitStop())
                        dispatch(InstallationActionConstant.INSTALLATION_CAPTURE(json))
                    }
                })
                .catch(err => {
                    dispatch(WaitAction.waitStop())
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.capture} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError + i18n.capture))
                })
        }
    },

    fetchInstallationLifting(id) {
        return (dispatch) => {
            return fetch(ApplicationConf.installation.getLifting(id), {
                method: 'GET',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then(e => checkError(e, {
                    404: () => { },
                }))
                .then(json => {
                    if (json.errors) {
                        throw new Error()
                    } else {
                        dispatch(WaitAction.waitStop())
                        dispatch(InstallationActionConstant.INSTALLATION_LIFTING(json))
                    }
                })
                .catch(err => {
                    dispatch(WaitAction.waitStop())
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.lifting} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError + i18n.lifting))
                })
        }
    },

    fetchInstallationPumping(id) {
        return (dispatch) => {
            return fetch(ApplicationConf.installation.getPumping(id), {
                method: 'GET',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then(e => checkError(e, {
                    404: () => { },
                }))
                .then(json => {
                    if (json.errors) {
                        throw new Error()
                    } else {
                        dispatch(WaitAction.waitStop())
                        dispatch(InstallationActionConstant.INSTALLATION_PUMPING(json))
                    }
                })
                .catch(err => {
                    dispatch(WaitAction.waitStop())
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.pumpingStation} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError + i18n.pumpingStation))
                })
        }
    },

    fetchInstallationSTEP(id) {
        return (dispatch) => {
            return fetch(ApplicationConf.installation.getSTEP(id), {
                method: 'GET',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then(e => checkError(e, {
                    404: () => { },
                }))
                .then(json => {
                    if (json.errors) {
                        throw new Error()
                    } else {
                        dispatch(WaitAction.waitStop())
                        dispatch(InstallationActionConstant.INSTALLATION_STEP(json))
                    }
                })
                .catch(err => {
                    dispatch(WaitAction.waitStop())
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.STEP} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError + i18n.STEP))
                })
        }
    },

    fetchInstallationAgriTanks() {
        return (dispatch) => {
            return fetch(ApplicationConf.installation.getAgriTanks(), {
                method: 'GET',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then(json => {
                    dispatch(InstallationActionConstant.INSTALLATION_AGRI_TANKS(json))
                })
                .catch(err => {
                    dispatch(WaitAction.waitStop())
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.tanks} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError + i18n.tanks))
                })
        }
    },

    createAgriTank(tank, callback) {
        return function (dispatch) {
            return fetch(ApplicationConf.installation.createAgriTank(), {
                method: 'POST',
                headers: getAuthorization(),
                body: JSON.stringify(tank),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(json => {
                    if (json.id) {
                        dispatch(ToastrAction.success(i18n.elementCreateSuccess))
                        callback(json.id)
                    } else {
                        throw new Error(json)
                    }
                })
                .catch((err) => {
                    dispatch(WaitAction.waitStop())
                    dispatch(LogAction.logError(`${i18n.createError + i18n.detention} : ${err}`))
                    dispatch(ToastrAction.error(i18n.createError + i18n.detention))
                })
        }
    },

    fetchInstallationTank(id) {
        return (dispatch) => {
            return fetch(ApplicationConf.installation.getTank(id), {
                method: 'GET',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then(e => checkError(e, {
                    404: () => { },
                }))
                .then(json => {
                    if (json.errors) {
                        throw new Error()
                    } else {
                        dispatch(WaitAction.waitStop())
                        dispatch(InstallationActionConstant.INSTALLATION_TANK(json))
                    }
                })
                .catch(err => {
                    dispatch(WaitAction.waitStop())
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.tank} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError + i18n.tank))
                })
        }
    },

    fetchInstallationTreatment(id) {
        return (dispatch) => {
            return fetch(ApplicationConf.installation.getTreatment(id), {
                method: 'GET',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then(e => checkError(e, {
                    404: () => { },
                }))
                .then(json => {
                    if (json.errors) {
                        throw new Error()
                    } else {
                        dispatch(WaitAction.waitStop())
                        dispatch(InstallationActionConstant.INSTALLATION_TREATMENT(json))
                    }
                })
                .catch(err => {
                    dispatch(WaitAction.waitStop())
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.treatmentStation} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError + i18n.treatmentStation))
                })
        }
    },

    mergeInstallation(fromInstallationId, toInstallationId, callback) {
        return (dispatch) => {
            dispatch(WaitAction.waitStart())
            return fetch(ApplicationConf.installation.mergeInstallations(fromInstallationId, toInstallationId), {
                method: 'POST',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(json => {
                    if (json.update > 0) {
                        dispatch(WaitAction.waitStop())
                        dispatch(InstallationAction.fetchInstallations())
                        dispatch(InstallationAction.fetchInstallation(toInstallationId))
                        dispatch(InstallationAction.fetchInstallationBorehole(toInstallationId))
                        callback()
                    } else {
                        throw new Error()
                    }
                })
                .catch(err => {
                    dispatch(WaitAction.waitStop())
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.borehole} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError + i18n.borehole))
                })
        }
    },

    updateInstallation(installation) {
        return (dispatch) => {
            dispatch(WaitAction.waitStart())
            const fetches = [
                installation ? getPutFetch(ApplicationConf.installation.installationPath(), installation) : null,
            ].filter(fetch => !!fetch)
            return Promise.all(fetches)
                .then(results => {
                    if (every(results, json => json.update >= 0)) {
                        dispatch(WaitAction.waitStop())
                        dispatch(InstallationActionConstant.UPDATE_INSTALLATION(installation))
                        dispatch(ToastrAction.success(i18n.elementUpdateSuccess))
                    } else {
                        throw new Error(results)
                    }
                }).catch((err) => {
                    dispatch(WaitAction.waitStop())
                    dispatch(LogAction.logError(`${i18n.updateError + i18n.installation} : ${err}`))
                    dispatch(ToastrAction.error(i18n.updateError + i18n.installation))
                })
        }
    },

    updateInstallationBorehole(installation, borehole) {
        return (dispatch) => {
            dispatch(WaitAction.waitStart())
            const fetches = [
                borehole ? getPutFetch(ApplicationConf.installation.updateBorehole(), borehole) : null,
                installation ? getPutFetch(ApplicationConf.installation.installationPath(), installation) : null,
            ].filter(fetch => !!fetch)
            return Promise.all(fetches)
                .then(results => {
                    if (every(results, json => json.update >= 0)) {
                        dispatch(WaitAction.waitStop())
                        dispatch(InstallationActionConstant.UPDATE_INSTALLATION(installation))
                        dispatch(InstallationActionConstant.UPDATE_INSTALLATION_BOREHOLE(borehole))
                        dispatch(ToastrAction.success(i18n.elementUpdateSuccess))
                    } else {
                        throw new Error(results)
                    }
                }).catch((err) => {
                    dispatch(WaitAction.waitStop())
                    dispatch(LogAction.logError(`${i18n.updateError + i18n.borehole} : ${err}`))
                    dispatch(ToastrAction.error(i18n.updateError + i18n.borehole))
                })
        }
    },

    updateInstallationCapture(installation, capture) {
        return (dispatch) => {
            dispatch(WaitAction.waitStart())
            const fetches = [
                capture ? getPutFetch(ApplicationConf.installation.updateCapture(), capture) : null,
                installation ? getPutFetch(ApplicationConf.installation.installationPath(), installation) : null,
            ].filter(fetch => !!fetch)
            return Promise.all(fetches)
                .then(results => {
                    if (every(results, json => json.update >= 0)) {
                        dispatch(WaitAction.waitStop())
                        dispatch(InstallationActionConstant.UPDATE_INSTALLATION(installation))
                        dispatch(InstallationActionConstant.UPDATE_INSTALLATION_CAPTURE(capture))
                        dispatch(ToastrAction.success(i18n.elementUpdateSuccess))
                    } else {
                        throw new Error(results)
                    }
                }).catch((err) => {
                    dispatch(WaitAction.waitStop())
                    dispatch(LogAction.logError(`${i18n.updateError + i18n.capture} : ${err}`))
                    dispatch(ToastrAction.error(i18n.updateError + i18n.capture))
                })
        }
    },

    updateInstallationLifting(installation, lifting) {
        return (dispatch) => {
            dispatch(WaitAction.waitStart())
            const fetches = [
                lifting ? getPutFetch(ApplicationConf.installation.updateLifting(), lifting) : null,
                installation ? getPutFetch(ApplicationConf.installation.installationPath(), installation) : null,
            ].filter(fetch => !!fetch)
            return Promise.all(fetches)
                .then(results => {
                    if (every(results, json => json.update >= 0)) {
                        dispatch(WaitAction.waitStop())
                        dispatch(InstallationActionConstant.UPDATE_INSTALLATION(installation))
                        dispatch(InstallationActionConstant.UPDATE_INSTALLATION_LIFTING(lifting))
                        dispatch(ToastrAction.success(i18n.elementUpdateSuccess))
                    } else {
                        throw new Error(results)
                    }
                }).catch((err) => {
                    dispatch(WaitAction.waitStop())
                    dispatch(LogAction.logError(`${i18n.updateError + i18n.liftingStation} : ${err}`))
                    dispatch(ToastrAction.error(i18n.updateError + i18n.liftingStation))
                })
        }
    },

    updateInstallationPumping(installation, pumping) {
        return (dispatch) => {
            dispatch(WaitAction.waitStart())
            const fetches = [
                pumping ? getPutFetch(ApplicationConf.installation.updatePumping(), pumping) : null,
                installation ? getPutFetch(ApplicationConf.installation.installationPath(), installation) : null,
            ].filter(fetch => !!fetch)
            return Promise.all(fetches)
                .then(results => {
                    if (every(results, json => json.update >= 0)) {
                        dispatch(WaitAction.waitStop())
                        dispatch(InstallationActionConstant.UPDATE_INSTALLATION(installation))
                        dispatch(InstallationActionConstant.UPDATE_INSTALLATION_PUMPING(pumping))
                        dispatch(ToastrAction.success(i18n.elementUpdateSuccess))
                    } else {
                        throw new Error(results)
                    }
                }).catch((err) => {
                    dispatch(WaitAction.waitStop())
                    dispatch(LogAction.logError(`${i18n.updateError + i18n.pumpingStation} : ${err}`))
                    dispatch(ToastrAction.error(i18n.updateError + i18n.pumpingStation))
                })
        }
    },

    updateInstallationSTEP(installation, STEP) {
        return (dispatch) => {
            dispatch(WaitAction.waitStart())
            const fetches = [
                STEP ? getPutFetch(ApplicationConf.installation.updateSTEP(), STEP) : null,
                installation ? getPutFetch(ApplicationConf.installation.installationPath(), installation) : null,
            ].filter(fetch => !!fetch)
            return Promise.all(fetches)
                .then(results => {
                    if (every(results, json => json.update >= 0)) {
                        dispatch(WaitAction.waitStop())
                        dispatch(InstallationActionConstant.UPDATE_INSTALLATION(installation))
                        dispatch(InstallationActionConstant.UPDATE_INSTALLATION_STEP(STEP))
                        dispatch(ToastrAction.success(i18n.elementUpdateSuccess))
                    } else {
                        throw new Error(results)
                    }
                }).catch((err) => {
                    dispatch(WaitAction.waitStop())
                    dispatch(LogAction.logError(`${i18n.updateError + i18n.STEP} : ${err}`))
                    dispatch(ToastrAction.error(i18n.updateError + i18n.STEP))
                })
        }
    },

    updateInstallationTank(installation, tank) {
        return (dispatch) => {
            dispatch(WaitAction.waitStart())
            const fetches = [
                tank ? getPutFetch(ApplicationConf.installation.updateTank(), tank) : null,
                installation ? getPutFetch(ApplicationConf.installation.installationPath(), installation) : null,
            ].filter(fetch => !!fetch)
            return Promise.all(fetches)
                .then(results => {
                    if (every(results, json => json.update >= 0)) {
                        dispatch(WaitAction.waitStop())
                        dispatch(InstallationActionConstant.UPDATE_INSTALLATION(installation))
                        dispatch(InstallationActionConstant.UPDATE_INSTALLATION_TANK(tank))
                        dispatch(ToastrAction.success(i18n.elementUpdateSuccess))
                    } else {
                        throw new Error(results)
                    }
                }).catch((err) => {
                    dispatch(WaitAction.waitStop())
                    dispatch(LogAction.logError(`${i18n.updateError + i18n.tank} : ${err}`))
                    dispatch(ToastrAction.error(i18n.updateError + i18n.tank))
                })
        }
    },

    updateInstallationTreatment(installation, treatment) {
        return (dispatch) => {
            dispatch(WaitAction.waitStart())
            const fetches = [
                treatment ? getPutFetch(ApplicationConf.installation.updateTreatment(), treatment) : null,
                installation ? getPutFetch(ApplicationConf.installation.installationPath(), installation) : null,
            ].filter(fetch => !!fetch)
            return Promise.all(fetches)
                .then(results => {
                    if (every(results, json => json.update >= 0)) {
                        dispatch(WaitAction.waitStop())
                        dispatch(InstallationActionConstant.UPDATE_INSTALLATION(installation))
                        dispatch(InstallationActionConstant.UPDATE_INSTALLATION_TREATMENT(treatment))
                        dispatch(ToastrAction.success(i18n.elementUpdateSuccess))
                    } else {
                        throw new Error(results)
                    }
                }).catch((err) => {
                    dispatch(WaitAction.waitStop())
                    dispatch(LogAction.logError(`${i18n.updateError + i18n.treatmentStation} : ${err}`))
                    dispatch(ToastrAction.error(i18n.updateError + i18n.treatmentStation))
                })
        }
    },

    fetchInstallationEvents(id) {
        return (dispatch) => {
            return fetch(ApplicationConf.installation.events(id), {
                method: 'GET',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(json => {
                    if (json.errors) {
                        dispatch(ToastrAction.error(i18n.fetchError + i18n.events))
                        dispatch(LogAction.logError(`${i18n.fetchError + i18n.events} : ${json.errors}`))
                    }
                    dispatch(InstallationActionConstant.INSTALLATION_EVENTS(json))
                })
                .catch(err => {
                    dispatch(WaitAction.waitStop())
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.events} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError + i18n.events))
                })
        }
    },

    fetchInstallationEvent(installationId, eventId) {
        return dispatch => {
            return fetch(ApplicationConf.installation.event(installationId, eventId), {
                method: 'GET',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(json => {
                    dispatch(InstallationActionConstant.INSTALLATION_EVENT(json))
                })
                .catch(err => {
                    dispatch(WaitAction.waitStop())
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.event} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError + i18n.event))
                })
        }
    },

    addInstallationEvent(stationId, eventData, callback = () => { }) {
        return dispatch => {
            return fetch(ApplicationConf.installation.events(stationId), {
                method: 'POST',
                headers: getAuthorization(),
                body: JSON.stringify(eventData),
            })
                .then(checkAuth)
                .then(getJson)
                .then(json => {
                    if (json.insert === 1 && json.id) {
                        dispatch(InstallationActionConstant.CREATE_INSTALLATION_EVENT({ ...eventData, number: json.id }))
                        callback(json.id)
                        dispatch(ToastrAction.success(i18n.eventAddSuccess))
                        return json.id
                    }
                    throw new Error()
                })
                .catch(err => {
                    dispatch(WaitAction.waitStop())
                    dispatch(LogAction.logError(`${i18n.addError + i18n.event} : ${err}`))
                    dispatch(ToastrAction.error(i18n.addError + i18n.event))
                })
        }
    },

    updateInstallationEvent(stationId, eventId, eventData, callback = () => { }) {
        return dispatch => {
            dispatch(WaitAction.waitStart())
            return fetch(ApplicationConf.installation.event(stationId, eventId), {
                method: 'PUT',
                headers: getAuthorization(),
                body: JSON.stringify(eventData),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(json => {
                    if (json.update === 1) {
                        dispatch(WaitAction.waitStop())
                        dispatch(InstallationActionConstant.UPDATE_INSTALLATION_EVENT(eventData))
                        callback()
                        dispatch(ToastrAction.success(i18n.eventUpdateSuccess))
                    } else {
                        throw new Error()
                    }
                })
                .catch(err => {
                    dispatch(WaitAction.waitStop())
                    dispatch(LogAction.logError(`${i18n.updateError + i18n.event} : ${err}`))
                    dispatch(ToastrAction.error(i18n.updateError + i18n.event))
                })
        }
    },
    deleteInstallationEvent(stationId, eventId, callback = () => { }) {
        return dispatch => {
            dispatch(WaitAction.waitStart())
            return fetch(ApplicationConf.installation.event(stationId, eventId), {
                method: 'DELETE',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(json => {
                    if (json.delete === 1) {
                        dispatch(WaitAction.waitStop())
                        dispatch(InstallationActionConstant.DELETE_INSTALLATION_EVENT(eventId))
                        callback()
                        dispatch(ToastrAction.success(i18n.eventDeleteSuccess))
                    } else {
                        throw new Error()
                    }
                })
                .catch(err => {
                    dispatch(WaitAction.waitStop())
                    dispatch(LogAction.logError(`${i18n.deleteError + i18n.event} : ${err}`))
                    dispatch(ToastrAction.error(i18n.deleteError + i18n.event))
                })
        }
    },

    resetEvent: () => dispatch => dispatch(InstallationActionConstant.RESET_INSTALLATION_EVENT()),

    fetchInstallationAssociatedPiezometerMeasures(piezometerIds) {
        return (dispatch) => {
            if (!piezometerIds.length) {
                return dispatch(InstallationActionConstant.INSTALLATION_ASSOCIATED_PIEZOMETER_MEASURES([]))
            }
            return Promise.all(piezometerIds.map(piezometerId => fetch(ApplicationConf.piezometer.piezometerMeasures(piezometerId), {
                method: 'GET',
                headers: getAuthorization(),
            }).then(checkAuth).then(getJson).then(checkError)))
                .then(json => {
                    const result = json.map((piezometerMeasures, i) => ({
                        piezometerId: piezometerIds[i],
                        measures: piezometerMeasures,
                    }))
                    dispatch(InstallationActionConstant.INSTALLATION_ASSOCIATED_PIEZOMETER_MEASURES(result))
                })
                .catch(err => {
                    dispatch(WaitAction.waitStop())
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.measures} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError + i18n.measures))
                })
        }
    },

    promiseInstallationAnalysis(id, lightMode, startDate, endDate, forceload) {
        return fetch(ApplicationConf.installation.analysis(id, lightMode, startDate, endDate, forceload), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
            .catch((err) => {
                AppStore.dispatch(WaitAction.waitStop())
                AppStore.dispatch(LogAction.logError(`${i18n.fetchError + i18n.analysis} : ${err}`))
                AppStore.dispatch(ToastrAction.error(i18n.fetchError + i18n.analysis))
                return []
            })
    },

    fetchInstallationAnalysis(id, startDate, endDate, forceload) {
        return (dispatch) => {
            return InstallationAction.promiseInstallationAnalysis(id, undefined, startDate, endDate, forceload)
                .then(json => {
                    if (json.errors) {
                        dispatch(ToastrAction.error(i18n.fetchError + i18n.analysis))
                        dispatch(LogAction.logError(`${i18n.fetchError + i18n.analysis} : ${json.errors}`))
                    }
                    dispatch(InstallationActionConstant.INSTALLATION_ANALYSIS(json))
                    return json
                })
                .catch(err => {
                    dispatch(WaitAction.waitStop())
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.analysis} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError + i18n.analysis))
                })
        }
    },

    fetchInstallationAnalysisLight(id, startDate, endDate) {
        return (dispatch) => {
            return InstallationAction.promiseInstallationAnalysis(id, true, startDate, endDate)
                .then(json => {
                    return json
                })
                .catch(err => {
                    dispatch(WaitAction.waitStop())
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.analysis} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError + i18n.analysis))
                })
        }
    },

    fetchInstallationAnalysisByCriterias(id, obj) {
        return (dispatch) => {
            return fetch(ApplicationConf.installation.analysisByCriterias(id), {
                method: 'POST',
                headers: getAuthorization(),
                body: JSON.stringify(obj),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(json => json)
                .catch(err => {
                    dispatch(WaitAction.waitStop())
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.analysis} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError + i18n.analysis))
                })
        }
    },

    promiseInstallationAnalysisByType(data) {
        return fetch(ApplicationConf.installation.analysisByType(), {
            method: 'POST',
            headers: getAuthorization(),
            body: JSON.stringify(data),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
    },
    fetchInstallationAnalysisByType(data) {
        return (dispatch) => {
            return InstallationAction.promiseInstallationAnalysisByType(data)
                .then(json => {
                    if (json.errors) {
                        dispatch(ToastrAction.error(i18n.fetchError + i18n.analysis))
                        dispatch(LogAction.logError(`${i18n.fetchError + i18n.analysis} : ${json.errors}`))
                    }
                    dispatch(InstallationActionConstant.INSTALLATIONS_ANALYSIS(json))
                    return json
                })
                .catch(err => {
                    dispatch(WaitAction.waitStop())
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.analysis} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError + i18n.analysis))
                })
        }
    },

    updateArrangements(installationId, arrangements, callback = () => { }) {
        return function (dispatch) {
            dispatch(WaitAction.waitStart())
            return fetch(ApplicationConf.installation.arrangements(installationId), {
                method: 'PUT',
                headers: getAuthorization(),
                body: JSON.stringify(arrangements),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(json => {
                    if (json.update === 1) {
                        dispatch(WaitAction.waitStop())
                        dispatch(InstallationActionConstant.UPDATE_INSTALLATION_ARRANGEMENTS(arrangements))
                        dispatch(ToastrAction.success(i18n.elementUpdateSuccess))
                        callback()
                    } else {
                        throw new Error(json)
                    }
                })
                .catch((err) => {
                    dispatch(WaitAction.waitStop())
                    dispatch(LogAction.logError(`${i18n.updateError + i18n.arrangements} : ${err}`))
                    dispatch(ToastrAction.error(i18n.updateError + i18n.arrangements))
                })
        }
    },

    changeBssCode(installation, callback = () => { }) {
        return function (dispatch) {
            dispatch(WaitAction.waitStart())
            return fetch(ApplicationConf.installation.changeBssCode(), {
                method: 'PUT',
                headers: getAuthorization(),
                body: JSON.stringify(installation),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(json => {
                    if (json.update > 0) {
                        dispatch(WaitAction.waitStop())
                        dispatch(InstallationActionConstant.UPDATE_INSTALLATION(installation))
                        dispatch(ToastrAction.success(i18n.elementUpdateSuccess))
                        callback()
                    } else {
                        throw new Error(json)
                    }
                })
                .catch((err) => {
                    dispatch(WaitAction.waitStop())
                    dispatch(LogAction.logError(`${i18n.updateError + i18n.bsscode} : ${err}`))
                    dispatch(ToastrAction.error(i18n.updateError + i18n.bsscode))
                })
        }
    },

    promiseInstallationTypes() {
        return fetch(ApplicationConf.installation.getTypes(), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
    },
    fetchInstallationTypes() {
        return function (dispatch) {
            InstallationAction.promiseInstallationTypes()
                .then(json => {
                    dispatch(InstallationActionConstant.INSTALLATIONS_TYPES(json))
                })
        }
    },

    fetchInstallationTable(filter) {
        return (dispatch) => {
            return genericPromise(ApplicationConf.installation.table(), 'POST', filter)
                .then((json=[]) => {
                    dispatch(InstallationActionConstant.INSTALLATION_TABLE(json))
                })
                .catch(err => {
                    dispatch(WaitAction.waitStop())
                    dispatch(LogAction.logError(`${i18n.fetchError} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError))
                })
        }
    },

    fetchBoreholeTable(filter) {
        return (dispatch) => {
            return genericPromise(ApplicationConf.installation.boreholeTable(), 'POST', filter)
                .then((json=[]) => {
                    dispatch(InstallationActionConstant.BOREHOLE_TABLE(json))
                })
                .catch(err => {
                    dispatch(WaitAction.waitStop())
                    dispatch(LogAction.logError(`${i18n.fetchError} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError))
                })
        }
    },

    loadInstallationCounters: (progressCallback = () => { }) => dispatch => {
        return promiseAllProgress([
            InstallationAction.promiseInstallationsLight(),
            ParameterAction.promiseParameters(),
            UnitAction.promiseUnits(),
            InstallationAction.promiseInstallationTypes(),
            CityAction.promiseCities(),
            InstallationAction.promiseInstAnalysisParam(),
        ], progressCallback).then(jsonTab => {
            dispatch(InstallationActionConstant.ALL_INSTALLATIONS_LIGHT(jsonTab[0]))
            dispatch(ParameterAction.receiveParameters(jsonTab[1]))
            dispatch(UnitAction.receiveUnits(jsonTab[2]))
            dispatch(InstallationActionConstant.INSTALLATIONS_TYPES(jsonTab[3]))
            dispatch(CityAction.receiveCities(jsonTab[4]))
            dispatch(InstallationActionConstant.receiveInstallationAnalysisParameters(jsonTab[5]))
        }).catch(err => {
            dispatch(LogAction.logError(`${i18n.loadError} : ${err}`))
            dispatch(ToastrAction.error(i18n.loadError))
        })
    },
    updateInstallationsTypes(installationTypes) {
        return function (dispatch) {
            return fetch(ApplicationConf.installation.getTypes(), {
                method: 'POST',
                headers: getAuthorization(),
                body: JSON.stringify(installationTypes),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(json => {
                    if (json.update > 0) {
                        dispatch(ToastrAction.success(i18n.elementUpdateSuccess))
                    } else {
                        throw new Error(json)
                    }
                })
                .catch((err) => {
                    dispatch(LogAction.logError(`${i18n.updateError + i18n.installationType} : ${err}`))
                    dispatch(ToastrAction.error(i18n.updateError + i18n.installationType))
                })
        }
    },

    fetchAllCaptureAlimAreas: () => dispatch => {
        return fetch(ApplicationConf.installation.captureAlimArea(), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then(json => {
                dispatch(InstallationActionConstant.CAPTURE_ALIM_AREAS(json))
            })
    },

    fetchAllContributorLink: () => dispatch => fetch(ApplicationConf.installation.contributorLink(), {
        method: 'GET',
        headers: getAuthorization(),
    })
        .then(checkAuth)
        .then(getJson)
        .then(checkError)
        .then(json => dispatch(InstallationActionConstant.CONTRIBUTOR_LINKS(json))),

    promiseEditionInstallation: (campaignCode, idInstallation) => genericPromise(ApplicationConf.export.editionInstallation(campaignCode, idInstallation)),

    getEditionInstallation: (campaignCode, idInstallation) => {
        return (dispatch) => {
            return InstallationAction.promiseEditionInstallation(campaignCode, idInstallation)
                .then((json) => {
                    return json
                })
                .catch(err => {
                    dispatch(WaitAction.waitStop())
                    dispatch(LogAction.logError(`${i18n.fetchError} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError))
                })
        }
    },

    promiseFetchVisit: (idInstallation, idCampaign) => genericPromise(ApplicationConf.installation.getVisit(idInstallation, idCampaign)),

    fetchVisit: (idInstallation, idCampaign) => {
        return (dispatch) => {
            return InstallationAction.promiseFetchVisit(idInstallation, idCampaign)
                .then((json=[]) => {
                    dispatch(InstallationActionConstant.VISIT(json))
                    return json
                })
                .catch(err => {
                    dispatch(WaitAction.waitStop())
                    dispatch(LogAction.logError(`${i18n.fetchError} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError))
                })
        }
    },

    updateVisit: (idVisit, visit) => {
        return (dispatch) => {
            dispatch(WaitAction.waitStart())
            return fetch(ApplicationConf.installation.updateVisit(idVisit), {
                method: 'PUT',
                headers: getAuthorization(),
                body: JSON.stringify(visit),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(json => {
                    dispatch(WaitAction.waitStop())
                    if (json.update >= 0 || json.insert >= 0) {
                        dispatch(ToastrAction.success(i18n.elementUpdateSuccess))
                        return json
                    }
                    throw new Error()
                })
                .catch(err => {
                    dispatch(WaitAction.waitStop())
                    dispatch(LogAction.logError(`${i18n.updateError} : ${err}`))
                    dispatch(ToastrAction.error(i18n.updateError))
                })
        }
    },

    uploadFile: params => dispatch => {
        dispatch(WaitAction.waitStart())
        return fetch(ApplicationConf.files.uploadFtp(), {
            method: 'POST',
            headers: getAuthorization(),
            body: JSON.stringify(params),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
            .then(json => {
                if (json.insert) {
                    dispatch(WaitAction.waitStop())
                    return json
                }
                throw new Error(json)
            }).catch((err) => {
                dispatch(WaitAction.waitStop())
                dispatch(LogAction.logError(`${i18n.addError} : ${err}`))
                dispatch(ToastrAction.error(i18n.addError + i18n.element))
            })
    },

    fetchInstallationIndustrialSites: () => {
        return (dispatch) => {
            return genericPromise(ApplicationConf.installation.installationIndustrialSites())
                .then((json=[]) => {
                    dispatch(InstallationActionConstant.INSTALLATION_INDUSTRIAL_SITES(json))
                })
                .catch(err => {
                    dispatch(WaitAction.waitStop())
                    dispatch(LogAction.logError(`${i18n.fetchError} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError))
                })
        }
    },

    fetchIndustrialSiteTable: (filter) => {
        return (dispatch) => {
            return genericPromise(ApplicationConf.installation.industrialSiteTable(), 'POST', filter)
                .then((json=[]) => {
                    dispatch(InstallationActionConstant.INDUSTRIAL_SITE_TABLE(json))
                })
                .catch(err => {
                    dispatch(WaitAction.waitStop())
                    dispatch(LogAction.logError(`${i18n.fetchError} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError))
                })
        }
    },

    fetchTerritoryActivities() {
        return (dispatch) => {
            return fetch(ApplicationConf.installation.getTerritoryActivities(), {
                method: 'GET',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then((json = []) => {
                    dispatch(InstallationActionConstant.TERRITORY_ACTIVITIES(json))
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.territoryActivities} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError + i18n.territoryActivities))
                })
        }
    },

    fetchTerritoryPollutants() {
        return (dispatch) => {
            return fetch(ApplicationConf.installation.getTerritoryPollutants(), {
                method: 'GET',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then((json = []) => {
                    dispatch(InstallationActionConstant.TERRITORY_POLLUTANTS(json))
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.territoryPollutants} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError + i18n.territoryPollutants))
                })
        }
    },


    fetchTerritoryPollutantsEmissions() {
        return (dispatch) => {
            return fetch(ApplicationConf.installation.getTerritoryPollutantsEmissions(), {
                method: 'GET',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then((json = []) => {
                    dispatch(InstallationActionConstant.TERRITORY_POLLUTANTS_EMISSIONS(json))
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.territoryPollutantsEmissions} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError + i18n.territoryPollutantsEmissions))
                })
        }
    },
    promiseInstallationVisits: idInstallation => genericPromise(ApplicationConf.installation.getInstallationVisits(idInstallation)),

    fetchInstallationVisits: (idInstallation) => {
        return (dispatch) => {
            return InstallationAction.promiseInstallationVisits(idInstallation)
                .then((json=[]) => {
                    dispatch(InstallationActionConstant.VISIT_MODE(json))
                })
                .catch(err => {
                    dispatch(WaitAction.waitStop())
                    dispatch(LogAction.logError(`${i18n.fetchError} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError))
                })
        }
    },

    promiseSituationInstallations: (ids) => genericPromise(ApplicationConf.installation.getInstallationsSituation(), 'POST', { ids }),

    fetchSituationInstallations(ids) {
        return (dispatch) => {
            return InstallationAction.promiseSituationInstallations(ids)
                .then((json=[]) => {
                    dispatch(InstallationActionConstant.receiveInstallationsSituation(json))
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.installationSituation} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError + i18n.installationSituation))
                })
        }
    },

    fetchInstallationAssociatedEvents(installationCode) {
        return (dispatch) => {
            return genericPromise(ApplicationConf.installation.getInstallationAssociatedEvents(installationCode))
                .then((json={}) => {
                    dispatch(InstallationActionConstant.receiveInstallationsAssociatedEvents(json))
                    return json
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.installationEvents} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError + i18n.installationEvents))
                })
        }
    },

    fetchDistributionEstablishmentsActivitySection() {
        return (dispatch) => {
            return fetch(ApplicationConf.installation.getDistributionEstablishmentsActivitySection(), {
                method: 'GET',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then((json = []) => {
                    dispatch(InstallationActionConstant.DISTRIBUTION_ETABLISHMENTS_ACTIVITY_SECTION(json))
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.distributionEstablishmentsActivitySection} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError + i18n.distributionEstablishmentsActivitySection))
                })
        }
    },

    fetchTerritoryEmissionsActivity(activityCodeWithOptionalCateg) {
        return (dispatch) => {
            return fetch(ApplicationConf.installation.getEmissionsByActivity(), {
                method: 'POST',
                headers: getAuthorization(),
                body: JSON.stringify(activityCodeWithOptionalCateg),
            })
                .then(checkAuth)
                .then(getJson)
                .then((json = []) => {
                    dispatch(InstallationActionConstant.TERRITORY_EMISSIONS_ACTIVITY(json))
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.territoryPollutantsEmissions} ${i18n.activity} : ${err}`))
                    dispatch(ToastrAction.error(`${i18n.fetchError + i18n.territoryPollutantsEmissions} ${i18n.activity}`))
                })
        }
    },

    updateIndustrialSite(industrialSite, idInstallation) {
        return (dispatch) => {
            return fetch(ApplicationConf.installation.updateIndustrialSite(), {
                method: 'PUT',
                headers: getAuthorization(),
                body: JSON.stringify(industrialSite),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(json => {
                    if (json.update >= 1) {
                        dispatch(InstallationAction.fetchIndustrialSite(idInstallation))
                        dispatch(ToastrAction.success(i18n.industrialSite))
                    } else {
                        throw new Error()
                    }
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.industrialSite} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError + i18n.industrialSite))
                })
        }
    },

    createIndustrialSite(industrialSite, idInstallation) {
        return (dispatch) => {
            return fetch(ApplicationConf.installation.createIndustrialSite(idInstallation), {
                method: 'POST',
                headers: getAuthorization(),
                body: JSON.stringify(industrialSite),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(json => {
                    if (json.insert >= 1) {
                        dispatch(InstallationAction.fetchIndustrialSite(idInstallation))
                        dispatch(ToastrAction.success(i18n.industrialSite))
                    } else {
                        throw new Error()
                    }
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.industrialSite} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError + i18n.industrialSite))
                })
        }
    },

    fetchTerritoryEmissionsDeclarations(codeInstallation) {
        return (dispatch) => {
            return fetch(ApplicationConf.installation.getEmissionsDeclarations(codeInstallation), {
                method: 'GET',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then((json = []) => {
                    dispatch(InstallationActionConstant.TERRITORY_EMISSIONS_DECLARATIONS(json))
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.territoryPollutantsEmissions} ${i18n.activity} : ${err}`))
                    dispatch(ToastrAction.error(`${i18n.fetchError + i18n.territoryPollutantsEmissions} ${i18n.activity}`))
                })
        }
    },

    promiseTerritoryConventionsAnalysis() {
        return fetch(ApplicationConf.installation.getAllConventionsAnalysis(), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
    },
    fetchTerritoryConventionsAnalysis() {
        return (dispatch) => {
            InstallationAction.promiseTerritoryConventionsAnalysis()
                .then((json = []) => {
                    dispatch(InstallationActionConstant.TERRITORY_CONVENTIONS_ANALYSIS(json))
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.agreements} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError + i18n.agreements))
                })
        }
    },

    promiseTerritoryDagnosis: () => genericPromise(ApplicationConf.installation.getTerritoryDiagnosis()),

    fetchTerritoryDiagnosis: () => {
        return (dispatch) => {
            return InstallationAction.promiseTerritoryDagnosis()
                .then((json=[]) => {
                    dispatch(InstallationActionConstant.TERRITORY_DIAGNOSIS(json))
                })
                .catch(err => {
                    dispatch(WaitAction.waitStop())
                    dispatch(LogAction.logError(`${i18n.fetchError} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError))
                })
        }
    },

    promiseSTEPTerritoryArtisans: (data) => genericPromise(ApplicationConf.installation.getArtisansEmissions(), 'POST', data),

    fetchSTEPTerritoryArtisans: (data) => {
        return (dispatch) => {
            return InstallationAction.promiseSTEPTerritoryArtisans(data)
                .then((json=[]) => {
                    dispatch(InstallationActionConstant.STEP_TERRITORY_ARTISANS(json))
                })
                .catch(err => {
                    dispatch(WaitAction.waitStop())
                    dispatch(LogAction.logError(`${i18n.fetchError} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError))
                })
        }
    },

    promiseSTEPTerritoryIndustries: (data) => genericPromise(ApplicationConf.installation.getIndustriesEmissions(), 'POST', data),

    fetchSTEPTerritoryIndustries: (data) => {
        return (dispatch) => {
            return InstallationAction.promiseSTEPTerritoryIndustries(data)
                .then((json=[]) => {
                    dispatch(InstallationActionConstant.STEP_TERRITORY_INDUSTRIES(json))
                })
                .catch(err => {
                    dispatch(WaitAction.waitStop())
                    dispatch(LogAction.logError(`${i18n.fetchError} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError))
                })
        }
    },

    promiseSTEPTerritorySubscribers: (id) => genericPromise(ApplicationConf.installation.getDomesticSubscribersEmissions(id)),

    fetchSTEPTerritorySubscribers: (id) => {
        return (dispatch) => {
            return InstallationAction.promiseSTEPTerritorySubscribers(id)
                .then((json=[]) => {
                    dispatch(InstallationActionConstant.STEP_TERRITORY_SUBSCRIBERS(json))
                })
                .catch(err => {
                    dispatch(WaitAction.waitStop())
                    dispatch(LogAction.logError(`${i18n.fetchError} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError))
                })
        }
    },

    promiseSTEPTerritoryDagnosis: (id) => genericPromise(ApplicationConf.installation.getSTEPTerritoryDiagnosis(id)),

    fetchSTEPTerritoryDiagnosis: (id) => {
        return (dispatch) => {
            return InstallationAction.promiseSTEPTerritoryDagnosis(id)
                .then((json=[]) => {
                    dispatch(InstallationActionConstant.STEP_TERRITORY_DIAGNOSIS(json))
                })
                .catch(err => {
                    dispatch(WaitAction.waitStop())
                    dispatch(LogAction.logError(`${i18n.fetchError} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError))
                })
        }
    },

    promiseEstablishmentDischarges: () => genericPromise(ApplicationConf.installation.getEstablishmentDischarges()),

    fetchEstablishmentDischarges: () => {
        return (dispatch) => {
            return InstallationAction.promiseEstablishmentDischarges()
                .then((json=[]) => {
                    dispatch(InstallationActionConstant.ESTABLISHMENT_DISCHARGES(json))
                })
                .catch(err => {
                    dispatch(WaitAction.waitStop())
                    dispatch(LogAction.logError(`${i18n.fetchError} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError))
                })
        }
    },

    promiseSpecificExportTerritoryActivities: () => genericPromise(ApplicationConf.installation.specificExportTerritoryActivities()),

    fetchSpecificExportTerritoryActivities() {
        return (dispatch) => {
            return InstallationAction.promiseSpecificExportTerritoryActivities()
                .then((json = []) => {
                    return json.map(d => new DtoExportTerritoryActivitie(d))
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.fetchError} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError))
                    return []
                })
        }
    },

    promiseSpecificExportPollutantsEmissions: () => genericPromise(ApplicationConf.installation.specificExportPollutantsEmissions()),

    fetchSpecificExportPollutantsEmissions() {
        return (dispatch) => {
            return InstallationAction.promiseSpecificExportPollutantsEmissions()
                .then((json = []) => {
                    return json.map(d => new DtoExportPollutantsEmissions(d))
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.fetchError} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError))
                    return []
                })
        }
    },

    updateInstallationAndVisit: (idVisit, visit) => {
        return (dispatch) => {
            dispatch(WaitAction.waitStart())
            return fetch(ApplicationConf.installation.updateVisitAndInstallation(idVisit), {
                method: 'PUT',
                headers: getAuthorization(),
                body: JSON.stringify(visit),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(json => {
                    dispatch(WaitAction.waitStop())
                    if (json.update >= 0 || json.insert >= 0) {
                        dispatch(ToastrAction.success(i18n.elementUpdateSuccess))
                        return json
                    }
                    throw new Error()
                })
                .catch(err => {
                    dispatch(WaitAction.waitStop())
                    dispatch(LogAction.logError(`${i18n.updateError} : ${err}`))
                    dispatch(ToastrAction.error(i18n.updateError))
                })
        }
    },

    // Module PGSSE
    promiseDangersWithType: params => genericPromise(ApplicationConf.installation.fetchDangersWithType(), 'POST', params),

    fetchDangersWithType: (params) => {
        return (dispatch) => {
            return InstallationAction.promiseDangersWithType(params)
                .then((json=[]) => {
                    dispatch(InstallationActionConstant.DANGERS_TYPE(json))
                })
                .catch(err => {
                    dispatch(WaitAction.waitStop())
                    dispatch(LogAction.logError(`${i18n.fetchError} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError))
                })
        }
    },

    promiseDangersWithLocation: situationDate => genericPromise(ApplicationConf.installation.fetchDangersWithLocation(situationDate)),

    fetchDangersWithLocation: (situationDate) => {
        return (dispatch) => {
            return InstallationAction.promiseDangersWithLocation(situationDate)
                .then((json=[]) => {
                    dispatch(InstallationActionConstant.DANGERS_LOCATION(json))
                })
                .catch(err => {
                    dispatch(WaitAction.waitStop())
                    dispatch(LogAction.logError(`${i18n.fetchError} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError))
                })
        }
    },

    updateDanger: (danger) => {
        return (dispatch) => {
            dispatch(WaitAction.waitStart())
            return fetch(ApplicationConf.installation.updateDanger(), {
                method: 'PUT',
                headers: getAuthorization(),
                body: JSON.stringify(danger),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(json => {
                    dispatch(WaitAction.waitStop())
                    if (json.update >= 0 || json.insert >= 0) {
                        dispatch(ToastrAction.success(i18n.elementUpdateSuccess))
                        return json
                    }
                    throw new Error()
                })
                .catch(err => {
                    dispatch(WaitAction.waitStop())
                    dispatch(LogAction.logError(`${i18n.updateError} : ${err}`))
                    dispatch(ToastrAction.error(i18n.updateError))
                })
        }
    },

    promiseDanger: id => genericPromise(ApplicationConf.installation.getDanger(id)),

    fetchDanger: (id) => {
        return (dispatch) => {
            return InstallationAction.promiseDanger(id)
                .then((json=[]) => {
                    dispatch(InstallationActionConstant.DANGER(json))
                })
                .catch(err => {
                    dispatch(WaitAction.waitStop())
                    dispatch(LogAction.logError(`${i18n.fetchError} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError))
                })
        }
    },

    deleteDanger: id => {
        return (dispatch) => {
            dispatch(WaitAction.waitStart())
            return fetch(ApplicationConf.installation.getDanger(id), {
                method: 'DELETE',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(json => {
                    dispatch(WaitAction.waitStop())
                    if (!(json.delete >= 0)) {
                        throw new Error()
                    }
                })
                .catch(err => {
                    dispatch(WaitAction.waitStop())
                    dispatch(LogAction.logError(`${i18n.deleteError} : ${err}`))
                    dispatch(ToastrAction.error(i18n.deleteError))
                })
        }
    },
    // End Module PGSSE

    promiseBasins: () => genericPromise(ApplicationConf.installation.getBasins()),

    fetchBasins: () => {
        return (dispatch) => {
            return InstallationAction.promiseBasins()
                .then((json=[]) => {
                    dispatch(InstallationActionConstant.BASINS(json))
                })
                .catch(err => {
                    dispatch(WaitAction.waitStop())
                    dispatch(LogAction.logError(`${i18n.fetchError} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError))
                })
        }
    },

    fetchSTEPTable(filter) {
        return (dispatch) => {
            return genericPromise(ApplicationConf.installation.getSTEPTable(), 'POST', filter)
                .then((json=[]) => {
                    dispatch(InstallationActionConstant.STEP_TABLE(json))
                })
                .catch(err => {
                    dispatch(WaitAction.waitStop())
                    dispatch(LogAction.logError(`${i18n.fetchError} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError))
                })
        }
    },

    fetchTankTable(filter) {
        return (dispatch) => {
            return genericPromise(ApplicationConf.installation.getTankTable(), 'POST', filter)
                .then((json=[]) => {
                    dispatch(InstallationActionConstant.TANK_TABLE(json))
                })
                .catch(err => {
                    dispatch(WaitAction.waitStop())
                    dispatch(LogAction.logError(`${i18n.fetchError} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError))
                })
        }
    },

    fetchFlowObstructionTable(filter) {
        return (dispatch) => {
            return genericPromise(ApplicationConf.installation.getFlowObstructionTable(), 'POST', filter)
                .then((json=[]) => {
                    dispatch(InstallationActionConstant.FLOW_OBSTRUCTION_TABLE(json))
                })
                .catch(err => {
                    dispatch(WaitAction.waitStop())
                    dispatch(LogAction.logError(`${i18n.fetchError} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError))
                })
        }
    },

    fetchInstallationNetwork(id) {
        return (dispatch) => {
            return fetch(ApplicationConf.installation.getNetwork(id), {
                method: 'GET',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then(e => checkError(e, {
                    404: () => { },
                }))
                .then(json => {
                    if (json.errors) {
                        throw new Error()
                    } else {
                        dispatch(WaitAction.waitStop())
                        dispatch(InstallationActionConstant.INSTALLATION_NETWORK(json))
                    }
                })
                .catch(err => {
                    dispatch(WaitAction.waitStop())
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.STEP} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError + i18n.STEP))
                })
        }
    },

    updateInstallationNetwork(installation, network) {
        return (dispatch) => {
            dispatch(WaitAction.waitStart())
            const fetches = [
                network ? getPutFetch(ApplicationConf.installation.updateNetwork(), network) : null,
                installation ? getPutFetch(ApplicationConf.installation.installationPath(), installation) : null,
            ].filter(fetch => !!fetch)
            return Promise.all(fetches)
                .then(results => {
                    if (every(results, json => json.update >= 0)) {
                        dispatch(WaitAction.waitStop())
                        dispatch(InstallationActionConstant.UPDATE_INSTALLATION(installation))
                        dispatch(InstallationActionConstant.UPDATE_INSTALLATION_NETWORK(network))
                        dispatch(ToastrAction.success(i18n.elementUpdateSuccess))
                    } else {
                        throw new Error(results)
                    }
                }).catch((err) => {
                    dispatch(WaitAction.waitStop())
                    dispatch(LogAction.logError(`${i18n.updateError + i18n.network} : ${err}`))
                    dispatch(ToastrAction.error(i18n.updateError + i18n.network))
                })
        }
    },

    importInstallationDescription(data) {
        return function (dispatch) {
            return fetch(ApplicationConf.installation.importDesc(), {
                method: 'POST',
                headers: getAuthorization(),
                body: JSON.stringify(data),
            })
                .then(checkAuth)
                .then(getJson)
                .catch((err) => {
                    dispatch(LogAction.logError(`${i18n.importError} : ${err}`))
                    dispatch(ToastrAction.error(i18n.importError))
                })
        }
    },

    fetchInstallationAnalysisStats(id) {
        return (dispatch) => {
            return genericPromise(ApplicationConf.installation.analysisStats(id))
                .then((json = {}) => {
                    dispatch(InstallationActionConstant.receiveInstallationAnalysisStats(json))
                    return json
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.analysis} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError + i18n.analysis))
                })
        }
    },

    promiseInstAnalysisParam: (ids = []) => genericPromise(ApplicationConf.installation.analysisParameters(), 'POST', ids),

    fetchInstallationAnalysisParameters(ids) {
        return (dispatch) => {
            return InstallationAction.promiseInstAnalysisParam(ids)
                .then((json = {}) => {
                    dispatch(InstallationActionConstant.receiveInstallationAnalysisParameters(json))
                    return json
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.parameters} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError + i18n.parameters))
                })
        }
    },

    promiseLinkedPiezoMeasure: (id, occurence, nbOccurence) => genericPromise(ApplicationConf.piezometer.linkedPiezoMeasures(id, occurence, nbOccurence)),

    fetchLinkedPiezoMeasures(id, occurence, nbOccurence) {
        return (dispatch) => {
            return InstallationAction.promiseLinkedPiezoMeasure(id, occurence, nbOccurence)
                .then((json = {}) => {
                    dispatch(InstallationActionConstant.receiveInstallationLinkedPiezoMeasures(json))
                    return json
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.piezometerMesures} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError + i18n.piezometerMesures))
                })
        }
    },

    promiseLinkedHydroMeasure: (id, occurence, nbOccurence) => genericPromise(ApplicationConf.hydrometricStation.linkedHydroMeasures(id, occurence, nbOccurence)),

    fetchLinkedHydroMeasures(id, occurence, nbOccurence) {
        return (dispatch) => {
            return InstallationAction.promiseLinkedHydroMeasure(id, occurence, nbOccurence)
                .then((json = {}) => {
                    dispatch(InstallationActionConstant.receiveInstallationLinkedHydroMeasures(json))
                    return json
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.hydrometricStationMeasures} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError + i18n.hydrometricStationMeasures))
                })
        }
    },

    promiseNetworkTable: (filter) => genericPromise(ApplicationConf.installation.networkTable(), 'POST', filter),

    fetchNetworkTable(filter) {
        return (dispatch) => {
            return InstallationAction.promiseNetworkTable(filter)
                .then((json = []) => {
                    dispatch(InstallationActionConstant.NETWORK_TABLE(json))
                })
                .catch(err => {
                    dispatch(WaitAction.waitStop())
                    dispatch(LogAction.logError(`${i18n.fetchError} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError))
                })
        }
    },
}

export default InstallationAction
