import { createSlice } from '@reduxjs/toolkit'
import DtoCulture from '../dto/DtoCulture'
import DtoCultureRPG from '../dto/DtoCultureRPG'
import DtoCultureVentilation from '../dto/DtoCultureVentilation'
import DtoRPG from '../dto/DtoRPG'

export const initialState = {
    cultures: [],
    culture: {},
    culturesVentilations: [],
    cultureRPG: {},
    culturesRPG: [],
    rpg: [],
}

const store = createSlice({
    name: 'culture',
    initialState,
    reducers: {
        RECEIVE_CULTURE_RPG: (state, action) => {
            state.cultureRPG = new DtoCultureRPG(action.payload)
        },
        RECEIVE_ALL_CULTURES_RPG: (state, action) => {
            state.culturesRPG = action.payload.map((c) => new DtoCultureRPG(c))
        },
        RECEIVE_ALL_RPG: (state, action) => {
            state.rpg = action.payload.map((c) => new DtoRPG(c))
        },
        RECEIVE_ALL_CULTURE: (state, action) => {
            state.cultures = action.payload.map(c => new DtoCulture(c))
        },
        RECEIVE_ALL_CULTURES_VENTILATIONS: (state, action) => {
            state.culturesVentilations = action.payload.map(c => new DtoCultureVentilation(c))
        },
        RECEIVE_CULTURE: (state, action) => {
            state.culture = new DtoCulture(action.payload)
        },
        RESET_CULTURE: (state) => {
            state.culture = {}
            state.cultureRPG = {}
        },
    },
    extraReducers: {},
})

export const CultureActionConstant = store.actions
export default store.reducer