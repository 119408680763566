import { ENTRY_SETTINGS_FREQUENCY } from 'agriAdministration/constants/AgriConstants'
import React from 'react'
import i18n from 'simple-react-i18n'

const getSquare = (color, isLast) => {
    return (
        <div
            style={{
                backgroundColor: color || 'white',
                display: 'inline-block',
                width: '20px',
                height: '20px',
                border: 'solid black 1px',
                borderRight: isLast ? 'solid black 1px' : '',
            }}
        >&nbsp;</div>
    )
}

const setTableIndex = (table) => table.map((d, i) => ({ ...d, index: i }))

const getDayDisplay = (value, slotsDisplay, colors) => {
    if (value === '' || value) {
        const slots = value.split('')
        return slotsDisplay.map((i) => {
            return getSquare(colors[slots[i]]?.color, i === slotsDisplay.length - 1)
        })
    }
    return [getSquare('white', true)]
}

const toolFunctions = [
    {
        code: 0,
        name: 'Semaine hors week-end',
        days: ['day1', 'day2', 'day3', 'day4', 'day5'],
    }, {
        code: 1,
        name: 'Week-end',
        days: ['day6', 'day7'],
    }, {
        code: 2,
        name: 'Semaine entière',
        days: ['day1', 'day2', 'day3', 'day4', 'day5', 'day6', 'day7'],
    }, {
        code: 3,
        name: 'Tous les lundis',
        days: ['day1'],
    }, {
        code: 4,
        name: 'Tous les mardis',
        days: ['day2'],
    }, {
        code: 5,
        name: 'Tous les mercredis',
        days: ['day3'],
    }, {
        code: 6,
        name: 'Tous les jeudis',
        days: ['day4'],
    }, {
        code: 7,
        name: 'Tous les vendredis',
        days: ['day5'],
    }, {
        code: 8,
        name: 'Tous les samedis',
        days: ['day6'],
    }, {
        code: 9,
        name: 'Tous les dimanches',
        days: ['day7'],
    },
]

const getApplyDays = (selectionTool, colorInput) => {
    const tool = toolFunctions.find(d => d.code === selectionTool)
    return tool.days.reduce((acc, day) => ({
        ...acc,
        [day]: colorInput,
    }), {})
}

const getTextSlot = (waterTurnSlots) => {
    const slots = waterTurnSlots?.slots?.split('/')
    return slots && slots.map((slot => {
        const hours = slot?.split('-')
        return hours.map(hour => `${hour}h`).join('-') || ''
    })).join('  ') || i18n.notDefined
}

const getEntryFrequencyList = () => [{
    value: ENTRY_SETTINGS_FREQUENCY.WEEKLY,
    label: i18n.weekly,
}, {
    value: ENTRY_SETTINGS_FREQUENCY.MONTHLY,
    label: i18n.monthlyF,
}]

const servicesHeaders = ['codification', 'productCode', 'occurency', 'unity', 'quantity', 'siret', 'codifOCTAGRI', 'folder', 'cityCode', 'city']

export { getDayDisplay, toolFunctions, getSquare, getApplyDays, setTableIndex, getTextSlot, getEntryFrequencyList, servicesHeaders }
