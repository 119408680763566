module.exports = {
    RECEIVE_ALL_POWER_SUPPLIES: 'RECEIVE_ALL_POWER_SUPPLIES',
    RECEIVE_POWER_SUPPLY: 'RECEIVE_POWER_SUPPLY',
    RESET_POWER_SUPPLY: 'RESET_POWER_SUPPLY',
    RECEIVE_POWER_SUPPLY_TYPES: 'RECEIVE_POWER_SUPPLY_TYPES',
    RECEIVE_POWER_SUPPLIES_LAST_SITUATIONS: 'RECEIVE_POWER_SUPPLIES_LAST_SITUATIONS',
    RECEIVE_ALL_POWER_SUPPLY_SITUATIONS: 'RECEIVE_ALL_POWER_SUPPLY_SITUATIONS',
    RECEIVE_POWER_SUPPLY_PICTURES: 'RECEIVE_POWER_SUPPLY_PICTURES',
    RECEIVE_POWER_SUPPLY_FILES: 'RECEIVE_POWER_SUPPLY_FILES',
}
