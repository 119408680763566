/* eslint-disable consistent-return */
/* eslint-disable max-nested-callbacks */
import { Grid } from '@mui/material'
import Icon from 'components/icon/Icon'
import { DialogContentMUI, DialogMUI, DialogTitleMUI } from 'components/styled/Dialog'
import { StyledFieldSet } from 'components/StyledElements'
import { groupBy, isUndefined, keys, orderBy, sum } from 'lodash'
import PropTypes from 'prop-types'
import React, { useMemo } from 'react'
import i18n from 'simple-react-i18n'
import { AUTO, MONTH, WEEK, YEAR } from 'quality/constants/ChartConstant'
import { getBarWidth } from '../../../components/echart/EChartUtils'
import QualityChart from 'components/echart/QualityChart'
import { shallowEqual, useSelector } from 'react-redux'

const HydrobioGraph = ({
    hydrobioList = [],
    graphOptions = {},

    componentHeight = 600,

    dataZoomPosition = { bottom: 40 },
    legendPosition = { bottom: 10, right: 20, top: undefined },
    toolboxPosition = { top: 5 },
}) => {
    const {
        taxons,
    } = useSelector(store => ({
        taxons: store.TaxonReducer.taxons,
    }), shallowEqual)

    const {
        graphicTitle,
    } = graphOptions

    const taxonName = taxons.find(t => t.code === hydrobioList[0]?.taxon)?.labelWithCode ?? i18n.unknown

    const orderedHydrobios = orderBy(hydrobioList, 'operationDate', 'desc')
    const groupedHydrobios = groupBy(orderedHydrobios, 'operation')

    const hydrobiosFormatted = useMemo(() => {
        const measures = keys(groupedHydrobios).map(key => ({
            date: groupedHydrobios[key]?.[0]?.operationDate,
            value: sum(groupedHydrobios[key]?.flatMap(tax => tax.result)),
        }))
        return [{
            name: taxonName,
            color: 'blue',
            type: 'bar',
            connectNulls: false,
            barWidth: getBarWidth(measures.length),
            dataList: measures,
        }]
    }, [groupedHydrobios, taxonName])

    const yAxis = [{
        name: taxonName,
        min: 0,
    }]

    const chartTitle = isUndefined(graphicTitle) ? taxonName : graphicTitle

    return (
        <QualityChart
            title={chartTitle}
            exportName={taxonName}
            grids={[{
                height: 'auto',
                top: 10,
                bottom: 120,
            }]}
            yAxis={yAxis}
            series={hydrobiosFormatted}

            headerHeight={0}
            footerHeight={120}
            componentHeight={componentHeight}

            withDataZoom

            dataZoomPosition={dataZoomPosition}
            legendPosition={legendPosition}
            toolboxPosition={toolboxPosition}
        />
    )
}

HydrobioGraph.propTypes = {
    hydrobioList: PropTypes.arrayOf(PropTypes.shape({/* ...DtoSearchTaxon, ...calculateThresholdResult */ })),

    graphOptions: PropTypes.shape({
        regroupAxis: PropTypes.bool,
        graphicTitle: PropTypes.string,

        displayStatistics: PropTypes.bool,
        displayAverages: PropTypes.bool,
        nbPointMovingAverage: PropTypes.number,
        displayHype: PropTypes.bool,

        minY: PropTypes.number,
        maxY: PropTypes.number,
        xAxisSpace: PropTypes.oneOf([YEAR, MONTH, WEEK, AUTO]),
        maxXAxisSpace: PropTypes.oneOf([YEAR, MONTH, WEEK, AUTO]),
        showXSplitLines: PropTypes.bool,
        showYSplitLines: PropTypes.bool,

        stationsOptions: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number,
            color: PropTypes.string,
        })),
    }),

    componentHeight: PropTypes.number,

    dataZoomPosition: PropTypes.shape({}),
    legendPosition: PropTypes.shape({}),
    toolboxPosition: PropTypes.shape({}),
}

const HydrobioGraphModal = ({
    isOpen = false,
    closeGraph = () => { },

    hydrobioList = [],

    graphOptions = {},
}) => {
    const dialogTitle = `${i18n.followUp} ${i18n.hydrobio}`

    return (
        <DialogMUI
            maxWidth='lg'
            fullWidth
            open={isOpen}
            PaperProps={{
                sx: {
                    minHeight: undefined,
                    maxHeight: undefined,
                },
            }}
        >
            <DialogTitleMUI>
                <Grid container justifyContent='space-between' alignItems='center' style={{ padding: '0 20' }}>
                    <Grid item >
                        {dialogTitle}
                    </Grid>
                    <Grid item>
                        <Icon style={{ color: 'white' }} size='small' icon='close' onClick={closeGraph} />
                    </Grid>
                </Grid>
            </DialogTitleMUI>
            <DialogContentMUI style={{ overflowX: 'hidden' }}>
                <Grid container columnSpacing={2}>
                    <Grid item xs={12}>
                        <StyledFieldSet>
                            <HydrobioGraph
                                hydrobioList={hydrobioList}
                                graphOptions={graphOptions}
                                componentHeight={560}
                            />
                        </StyledFieldSet>
                    </Grid>
                </Grid>
            </DialogContentMUI>
        </DialogMUI>
    )
}

HydrobioGraphModal.propTypes = {
    isOpen: PropTypes.bool,
    closeGraph: PropTypes.func,

    hydrobioList: PropTypes.arrayOf(PropTypes.shape({/* ...DtoSearchTaxon, ...calculateThresholdResult */ })),

    graphOptions: PropTypes.shape({
        regroupAxis: PropTypes.bool,
        displayStatistics: PropTypes.bool,
        displayAverages: PropTypes.bool,
        nbPointMovingAverage: PropTypes.number,
        displayHype: PropTypes.bool,
    }),
}

export {
    HydrobioGraphModal,
    HydrobioGraph,
}