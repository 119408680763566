import { Button, Dialog, Grid } from '@mui/material'
import User from 'account/dto/User'
import Input from 'components/forms/Input'
import { differenceBy } from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import { authorizeFilter } from 'utils/HabilitationUtil'
import { searchAllCharacters } from 'utils/StringUtil'
import SelectionTable from '../../../../components/datatable/SelectionTable'
import DtoDistributionUnit from '../../../../distributionUnit/dto/DtoDistributionUnit'
import DtoHydrometricStation from '../../../../hydrometry/dto/DtoHydrometricStation'
import DtoInstallation from '../../../../installation/dto/installation/DtoInstallation'
import DtoPiezometer from '../../../../piezometry/dto/DtoPiezometer'
import DtoProductionUnit from '../../../../productionUnit/dto/DtoProductionUnit'
import DtoQualitometer from '../../../../quality/dto/DtoQualitometer'
import ContactAction from '../../../../referencial/components/contact/actions/ContactAction'
import ContactDto from '../../../../referencial/components/contact/dto/ContactDto'
import { getStationTypeCodeFromType } from '../../../../utils/StationUtils'
import DtoStationContacts from '../../../dto/DtoStationContacts'
import { DialogActionsMUI, DialogContentMUI, DialogTitleMUI } from 'components/styled/Dialog'
import Icon from 'components/icon/Icon'
import { StyledFieldSet } from 'components/StyledElements'

class StationContactsModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            stationContacts: {},
            selectedData: [...props.stationContacts],
            contacts: authorizeFilter(props.contacts, props.accountUser, undefined, 'contributor'),
            search: '',
            tmpSearch: '',
        }
    }

    componentDidMount() {
        if (!this.props.contacts.length) {
            this.props.fetchContacts()
        }
    }

    onAdd(contact) {
        if (!this.state.selectedData.includes(contact)) {
            this.setState({ selectedData: [...this.state.selectedData, contact] })
        }
    }

    addAll() {
        this.setState({ selectedData: [...this.state.contacts] })
    }

    onDelete(contact) {
        this.setState({ selectedData: this.state.selectedData.filter(e => e.name !== contact.name) })
    }

    deleteAll() {
        this.setState({ selectedData: [] })
    }

    getFilteredData = (data) => {
        const { searchValue } = this.state
        const includesValue = searchAllCharacters(searchValue || '')
        return data.filter(i => this.getHash(i).includes(includesValue))
    }

    getHash = (contact) => {
        return searchAllCharacters(['name', 'mobile'].map(key => contact[key]))
    }

    getDataSelectionTable() {
        return differenceBy(this.getFilteredData(this.state.contacts), this.state.selectedData, 'id')
    }

    render() {
        const { tmpSearch } = this.state
        const headers = ['name', 'mobile']
        return (
            <Dialog
                onClose={() => this.setState({ open: false })}
                fullWidth
                maxWidth='lg'
                open={true}
            >
                <DialogTitleMUI style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    {i18n.addContributor}
                    <Icon style={{ color: 'white' }} size='small' icon='close' onClick={this.props.closePopup} />
                </DialogTitleMUI>
                <DialogContentMUI>
                    <StyledFieldSet>
                        <Grid container spacing={2} alignItems='center' justifyContent='center'>
                            <Grid item xs={6}>
                                <Input
                                    title={i18n.search}
                                    value={tmpSearch}
                                    onChange={v => this.setState({ tmpSearch: v })}
                                />
                            </Grid>
                            <Grid container item xs={6} justifyContent='flex-end'>
                                <Button onClick={() => this.setState({ searchValue: tmpSearch })} variant='contained'>
                                    {i18n.search}
                                </Button>
                            </Grid>
                            <Grid item xs={12}>
                                <SelectionTable
                                    maxHeight='45vh'
                                    listTitle={i18n.contacts}
                                    listData={this.getDataSelectionTable()}
                                    listHeaders={headers}
                                    selectedHeaders={headers}
                                    selectedData={this.state.selectedData}
                                    selectedTitle={i18n.toContact}
                                    onAdd={(contact) => this.onAdd(contact)}
                                    addAll={() => this.addAll()}
                                    deleteAll={() => this.deleteAll()}
                                    onDelete={contact => this.onDelete(contact)}
                                />
                            </Grid>
                        </Grid>
                    </StyledFieldSet>
                </DialogContentMUI>
                <DialogActionsMUI>
                    <Button onClick={() => {
                        this.props.onChange({ link_contacts: [...this.state.selectedData.map(c => {
                            const obj = {
                                stationCode: this.props.station.id,
                                stationType: getStationTypeCodeFromType(this.props.station.typeName),
                                contactCode: c.code,
                            }
                            return new DtoStationContacts(obj)
                        })],
                        })
                        this.props.closePopup()
                    } } variant='contained' color='primary'
                    >
                        {i18n.save}
                    </Button>
                </DialogActionsMUI>
            </Dialog>
        )
    }
}

StationContactsModal.propTypes = {
    contacts: PropTypes.arrayOf(PropTypes.instanceOf(ContactDto)),
    stationContacts: PropTypes.arrayOf(PropTypes.instanceOf(ContactDto)),
    fetchContacts: PropTypes.func,
    saveResult: PropTypes.func,
    onChange: PropTypes.func,
    closePopup: PropTypes.func,
    station: PropTypes.oneOfType([
        PropTypes.instanceOf(DtoProductionUnit),
        PropTypes.instanceOf(DtoDistributionUnit),
        PropTypes.instanceOf(DtoQualitometer),
        PropTypes.instanceOf(DtoPiezometer),
        PropTypes.instanceOf(DtoHydrometricStation),
        PropTypes.instanceOf(DtoInstallation),
    ]),
    accountUser: PropTypes.instanceOf(User),
}

const mapStateToProps = store => ({
    contacts: store.ContactReducer.contacts,
    accountUser: store.AccountReducer.accountUser,
})

const mapDispatchToProps = {
    fetchContacts: ContactAction.fetchContacts,
}
export default connect(mapStateToProps, mapDispatchToProps)(StationContactsModal)