import PropTypes from 'prop-types'
import React from 'react'
import Icon from '../icon/Icon'

const ElementCard = ({
    children = undefined,
    color = 'white',
    className = '',
    icon = '',
    icon2 = '',
    iconColor = 'white',
    iconClassName = '',
    button = '',
    buttonColor = 'white',
    style = {},
    onClick = () => { },
    onClickButton = () => { },
}) => (
    <div className={`${color} ${className || ''}`} style={{ ...style, padding: '5px', margin: '2px', borderRadius: '10px' }}>
        <div className='row no-margin'>
            <div onClick={onClick}>
                {
                    icon ? (
                        <div className='col s11'>
                            <div className='col s1'>
                                <Icon
                                    icon={icon}
                                    className={iconClassName}
                                    style={{ color: iconColor }}
                                />
                            </div>
                            <div className={`${color !== 'white' ? 'white-text' : ''} col s10`}>
                                {children}
                            </div>
                            {
                                !!icon2 && (
                                    <div className='col s1'>
                                        <Icon
                                            icon={icon2}
                                            className={iconClassName}
                                            style={{ color: iconColor }}
                                        />
                                    </div>
                                )
                            }
                        </div>
                    ) : children
                }
            </div>
            {
                !!button && (
                    <div onClick={onClickButton}>
                        <div className='right'>
                            <Icon icon={button} style={{ color: buttonColor }} />
                        </div>
                    </div>
                )
            }
        </div>
    </div>
)

ElementCard.propTypes = {
    children: PropTypes.element.isRequired,
    icon: PropTypes.string,
    iconColor: PropTypes.string,
    icon2: PropTypes.string,
    iconClassName: PropTypes.string,
    button: PropTypes.string,
    buttonColor: PropTypes.string,
    onClick: PropTypes.func,
    onClickButton: PropTypes.func,
    color: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.object,
}

export default ElementCard
