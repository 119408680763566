import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { StyledFieldSet, StyledLegend } from '../../../../../../components/StyledElements'
import i18n from 'simple-react-i18n'
import Checkbox from '../../../../../../components/forms/Checkbox'
import Button from '../../../../../../components/forms/Button'
import Row from '../../../../../../components/react/Row'
import { hasValue } from '../../../../../../utils/NumberUtil'
import { groupBy, orderBy, range } from 'lodash'
import moment from 'moment'
import IAEauAction from '../../../../../../iaeau/IAEauAction'
import { getColorFromPalette } from '../../../../../../utils/ColorUtil'
import Line from '../../../../../../components/echart/series/Line'
import AppStore from '../../../../../../store/AppStore'
import WaitAction from '../../../../../../wait/WaitAction'
import { getDate, getMonthList } from '../../../../../../utils/DateUtil'
import { MODEL_TYPES } from '../../../../../../iaeau/constants/IAEauConstants'
import DtoPredStat from '../../../../../../iaeau/dto/DtoPredStat'
import Input from '../../../../../../components/forms/Input'
import { getMapSituationCalendar } from '../../../../../../station/components/mapSituation/MapSituationUtils'
import Icon from '../../../../../../components/icon/Icon'
import Select from '../../../../../../components/forms/Select'
import { Icon as IconMui, Popover } from '@mui/material'
import { ButtonMUI } from '../../../../../../components/styled/Buttons'
import { createIndex } from '../../../../../../utils/StoreUtils'
import DtoPredMeasure from '../../../../../../iaeau/dto/DtoPredMeasure'
import { getI18nOrLabel } from '../../../../../../utils/StringUtil'
import MultiBand from '../../../../../../components/echart/series/MultiBand'
import ToastrAction from '../../../../../../toastr/actions/ToastrAction'
import DtoHydrometricStation from '../../../../../dto/DtoHydrometricStation'

const toEchartMeasure = (mTab, measure) => ({ value: [measure.horizonMode === 'hours' ? measure.date : moment(mTab[0]).hour(12).valueOf(), mTab[1], { measure }, mTab[2], mTab[3]] })

const getDefaultSeries = (pred, results, gridsLength) => {
    const allSeries = groupBy(results, 'serieName')
    const nbLineSeries = Object.keys(allSeries).filter(key => hasValue(allSeries[key][0].value)).length
    return Object.keys(allSeries).flatMap((serieName, idx) => {
        const orderedMeasures = orderBy(allSeries[serieName], 'date')
        const band = (() => {
            if (hasValue(orderedMeasures[0].doubtMin)) {
                const lower = {
                    showSymbol: false,
                    color: pred?.model?.color || 'grey',
                    data: orderedMeasures.map(m => toEchartMeasure([m.date, m.doubtMin], m)),
                    name: `${pred.source} - ${getI18nOrLabel(serieName) && getI18nOrLabel(serieName) !== 'undefined' ? getI18nOrLabel(serieName) : 'Min'}`,
                    isArea: true,
                    xAxisIndex: gridsLength - 1,
                    yAxisIndex: gridsLength,
                }
                const upper = {
                    showSymbol: false,
                    color: pred?.model?.color || 'grey',
                    data: orderedMeasures.map(m => toEchartMeasure([m.date, m.doubtMax], m)),
                    name: `${pred.source} - ${getI18nOrLabel(serieName) && getI18nOrLabel(serieName) !== 'undefined' ? getI18nOrLabel(serieName) : 'Max'}`,
                    isArea: true,
                    xAxisIndex: gridsLength - 1,
                    yAxisIndex: gridsLength,
                }
                return [MultiBand({ bands: [lower, upper], noSort: true, noGap: false, stack: `${pred.source}${idx}` })]
            }
            return []
        })()
        const line = (() => {
            if (hasValue(orderedMeasures[0].value)) {
                return [Line({
                    data: orderedMeasures.map(m => toEchartMeasure([m.date, m.value], m)),
                    name: `${pred.source} - ${getI18nOrLabel(serieName) && getI18nOrLabel(serieName) !== 'undefined' ? getI18nOrLabel(serieName) : 'Prévision'}`,
                    color: nbLineSeries > 1 ? getColorFromPalette(idx) : (pred?.model?.color || 'black'),
                    connectNulls: true,
                    lineStyle: {
                        normal: {
                            color: nbLineSeries > 1 ? getColorFromPalette(idx) : (pred?.model?.color || 'black'),
                            width: pred?.model?.lineWidth || 2,
                            type: pred?.model?.lineType || 'dashed',
                            opacity: pred?.model?.lineOpacity || 1,
                        },
                    },
                    itemStyle: {
                        normal: {
                            color: nbLineSeries > 1 ? getColorFromPalette(idx) : (pred?.model?.color || 'black'),
                        },
                    },
                    showSymbol: false,
                    isPiezo: true,
                    xAxisIndex: gridsLength - 1,
                    yAxisIndex: gridsLength,
                })]
            }
            return []
        })()
        return [...band, ...line]
    })
}

const getSeries = (pred, results, gridsLength) => {
    switch (pred.model?.typeModel) {
        case MODEL_TYPES.HYPE:
            return []
        default:
            return getDefaultSeries(pred, results, gridsLength)
    }
}

const loadModelResults = ({ predStats, modelCheck, modelDate, iaeauModels, hydrometricStation, cb, gridsLength, typeId }) => {
    const preds = createIndex(predStats, 'source')
    const predsToLoad = Object.keys(modelCheck).filter(key => modelCheck[key]).map(key => preds[key]).filter(pred => pred.typeId === typeId)
    const promises = predsToLoad.map(pred => IAEauAction.promiseModelMeasures('hydrometry', hydrometricStation.id, pred.idModel, pred.source, modelDate[pred.source]))
    AppStore.dispatch(WaitAction.waitStart())
    Promise.all(promises).then(jsonTab => {
        AppStore.dispatch(WaitAction.waitStop())
        const series = jsonTab.flatMap((json, idx) => {
            const pred = predsToLoad[idx]
            const pred2 = pred.idModel ? { ...pred, model: iaeauModels.find(m => m.idModel === pred.idModel) } : pred
            return getSeries(pred2, json.map(j => new DtoPredMeasure(j)), gridsLength)
        })
        cb(series)
    }).catch(() => AppStore.dispatch(WaitAction.waitStop()))
}

const years = range(moment().year(), 1949, -1).map(y => ({ value: y, label: y }))

const HydroModelsTab = ({
    changeParent, // met à jour les state du parent (dont les séries liées à cette tab)
    hydrometricStation,
    gridsLength,
    typeId,
}) => {
    const dispatch = useDispatch()

    const { iaeauModels } = useSelector(store => ({
        iaeauModels: store.IAEauReducer.iaeauModels,
    }), shallowEqual)

    const [modelTitleClick, setModelTitleClick] = useState(0)
    const [predStats, setPredStats] = useState([])
    const [modelCheck, setModelCheck] = useState({})
    const [modelDate, setModelDate] = useState({})
    const [prevSucc, setPrevSucc] = useState({})
    const [modelCalendarDates, setModelCalendarDates] = useState({})
    const [selectedMonth, setSelectedMonth] = useState({})
    const [selectedYear, setSelectedYear] = useState({})
    const [calendarRefs, setCalendarRefs] = useState({})
    const [calendarOpen, setCalendarOpen] = useState({})


    useEffect(() => {
        AppStore.dispatch(IAEauAction.getModels('hydrometry', hydrometricStation.id))
        IAEauAction.promisePredStats('hydrometry', hydrometricStation.id).then(pr => {
            const newPred = pr.map(p => new DtoPredStat(p))
            setPredStats(newPred)
            setModelDate(newPred.reduce((acc, v) => ({ ...acc, [v.source]: v.maxSimulationDate }), {}))
            setModelCalendarDates(newPred.reduce((acc, v) => ({ ...acc, [v.source]: v.dates }), {}))
            setSelectedMonth(newPred.reduce((acc, v) => ({ ...acc, [v.source]: v.maxSimulationDate }), {}))
            setSelectedYear(newPred.reduce((acc, v) => ({ ...acc, [v.source]: v.maxSimulationDate }), {}))
            setPrevSucc(newPred.reduce((acc, v) => ({ ...acc, [v.source]: [v.previousDate, undefined] }), {}))
            setCalendarRefs(newPred.reduce((acc, v) => ({ ...acc, [v.source]: React.createRef() }), {}))
        })
    }, [])

    const reloadModelResultsDates = (newSelectedMonth, newSelectedYear, pred) => {
        IAEauAction.promiseModelResultDates('hydrometry', hydrometricStation.id, pred.idModel, pred.source, moment(newSelectedYear).month(moment(newSelectedMonth).month()).valueOf())
            .then(predResultDates => {
                setSelectedMonth({ ...selectedMonth, [pred.source]: newSelectedMonth })
                setSelectedYear({ ...selectedYear, [pred.source]: newSelectedYear })
                setModelCalendarDates({ ...modelCalendarDates, [pred.source]: predResultDates })
            })
    }

    const changePrevSucc = (pred, newDate) => {
        if (newDate) {
            setModelDate({ ...modelDate, [pred.source]: newDate })
            IAEauAction.promiseModelResultPrevSuccDates('hydrometry', hydrometricStation.id, pred.idModel, pred.source, newDate).then(res => {
                setPrevSucc({ ...prevSucc, [pred.source]: res }) // [previous, next]
            })
        }
    }


    const onValidate = () => {
        if (!Object.keys(modelCheck).filter(key => modelCheck[key]).length) {
            dispatch(ToastrAction.warning(i18n.noModelSelectedMessage))
            changeParent({ modelSeries: [] })
        } else {
            loadModelResults({ predStats, modelCheck, modelDate, iaeauModels, hydrometricStation, gridsLength, typeId,
                cb: series => changeParent({ modelSeries: series }),
            })
        }
    }
    if (!predStats.length) {
        return i18n.noModelAvailable
    }
    return (
        <div>
            <StyledFieldSet>
                <StyledLegend onClick={ () => setModelTitleClick(modelTitleClick + 1) }>{ i18n.models }</StyledLegend>
                <div className='padding-left-2 padding-right-1'>
                    {
                        predStats.map(pred => {
                            const key = pred.source
                            const dis = pred.typeId !== typeId
                            const disabled = { disabled: dis }
                            return (
                                <div className='row no-margin padding-bottom-1'>
                                    <StyledFieldSet>
                                        <StyledLegend>{ `${pred.source} (${pred.horizon} ${i18n[pred.horizonMode || 'days']})` }</StyledLegend>
                                        <Row className='valign-wrapper'>
                                            <Checkbox col={ 1 } checked={ modelCheck[key] } onChange={ v => setModelCheck({ ...modelCheck, [key]: v }) } {...disabled}/>
                                            <div className='col s1'>
                                                <ButtonMUI
                                                    variant={'outlined'}
                                                    onClick={() => prevSucc[key]?.[0] && !dis ? changePrevSucc(pred, prevSucc[key]?.[0]) : null }
                                                    style={{ border: prevSucc[key]?.[0] && !dis ? 'solid rgba(53, 96, 159, 1)' : 'solid grey', borderWidth: 2, fontWeight: 600, paddingLeft: 0, paddingRight: 0, minWidth: 40 }}
                                                ><IconMui style={{ fontSize: 22, color: prevSucc[key]?.[0] && !dis ? 'rgba(53, 96, 159, 1)' : 'grey' }}>keyboard_double_arrow_left</IconMui></ButtonMUI>
                                            </div>
                                            <div className='col s3' style={{ paddingLeft: 24 }} ref={ calendarRefs[key] } onClick={ () => !dis ? setCalendarOpen({ ...calendarOpen, [key]: true }) : null }>
                                                <Input title={ i18n.date } value={ getDate(modelDate[key]) } onChange={ () => setModelDate({ ...modelDate, [key]: modelDate[key] }) } {...disabled}/>
                                            </div>
                                            <div className='col s1'>
                                                <ButtonMUI
                                                    variant={'outlined'}
                                                    onClick={() => prevSucc[key]?.[1] && !dis ? changePrevSucc(pred, prevSucc[key]?.[1]) : null }
                                                    style={{ border: prevSucc[key]?.[1] && !dis ? 'solid rgba(53, 96, 159, 1)' : 'solid grey', borderWidth: 2, fontWeight: 600, paddingLeft: 0, paddingRight: 0, minWidth: 40 }}
                                                ><IconMui style={{ fontSize: 22, color: prevSucc[key]?.[1] && !dis  ? 'rgba(53, 96, 159, 1)' : 'grey' }}>keyboard_double_arrow_right</IconMui></ButtonMUI>
                                            </div>
                                            <div className='col s6'/>
                                            <Popover
                                                open={calendarOpen[key]}
                                                anchorEl={calendarRefs[key]?.current}
                                                anchorOrigin={{
                                                    vertical: 'bottom',
                                                    horizontal: 'left',
                                                }}
                                                transformOrigin={{
                                                    vertical: 'top',
                                                    horizontal: 'left',
                                                }}
                                                onClose={() => setCalendarOpen({ ...calendarOpen, [key]: false })}
                                            >
                                                <div style={ { width: 250 }}>
                                                    <Row className='padding-top-1 padding-left-1 padding-right-1'>
                                                        <div className='col s1 no-padding'>
                                                            <Icon style={{ fontSize: 27 }} clickable icon='chevron_left' onClick={ () => {
                                                                if (moment(selectedMonth[key]).month()+1 === 1) {
                                                                    reloadModelResultsDates(moment().month(11).valueOf(), moment(selectedYear[key]).subtract(1, 'year').valueOf(), pred)
                                                                } else {
                                                                    reloadModelResultsDates(moment(selectedMonth[key]).subtract(1, 'month').valueOf(), selectedYear[key], pred)
                                                                }
                                                            } }
                                                            />
                                                        </div>
                                                        <div className='col s5'>
                                                            <Select value={moment(selectedMonth[key]).month()+1} options={getMonthList()} onChange={v => reloadModelResultsDates(moment().month(v-1).valueOf(), selectedYear[key], pred)} noInputFieldClass noSort/>
                                                        </div>
                                                        <div className='col s1 no-padding'>
                                                            <Icon style={{ fontSize: 27 }} clickable icon='chevron_right' onClick={ () => {
                                                                if (moment(selectedMonth[key]).month()+1 === 12) {
                                                                    reloadModelResultsDates(moment().month(0).valueOf(), moment(selectedYear[key]).add(1, 'year').valueOf(), pred)
                                                                } else {
                                                                    reloadModelResultsDates(moment(selectedMonth[key]).add(1, 'month').valueOf(), selectedYear[key], pred)
                                                                }
                                                            } }
                                                            />
                                                        </div>
                                                        <div className='col s5'>
                                                            <Select value={moment(selectedYear[key]).year()} options={years} onChange={v => reloadModelResultsDates(selectedMonth, moment().year(v).valueOf(), pred)} noInputFieldClass noSort/>
                                                        </div>
                                                    </Row>
                                                    <Row>
                                                        {getMapSituationCalendar(
                                                            selectedYear[key],
                                                            selectedMonth[key],
                                                            modelCalendarDates[key] || [],
                                                            modelDate[key],
                                                            v => setModelDate({ ...modelDate, [key]: v })
                                                        )}
                                                    </Row>
                                                </div>
                                            </Popover>
                                        </Row>
                                    </StyledFieldSet>
                                </div>
                            )
                        })
                    }
                </div>
            </StyledFieldSet>
            <Row className='padding-bottom-1 padding-top-1 center-align'>
                <Button tooltip={ i18n.apply } onClick={ onValidate } icon='border_color' className={`btn-floating btn-large`}/>
            </Row>
        </div>
    )
}

HydroModelsTab.propTypes = {
    id: PropTypes.number,
    changeParent: PropTypes.func,
    hydrometricStation: PropTypes.instanceOf(DtoHydrometricStation),
    gridsLength: PropTypes.number,
    typeId: PropTypes.number,
}

export default HydroModelsTab