import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import Table from '../../../../../components/datatable/Table'
import SieauAction from '../../../../../components/sieau/SieauAction'
import { SANDRE } from '../../../../../referencial/constants/ReferencialConstants'
import DtoSandreCode from '../../../../../referencial/dto/DtoSandreCode'
import { getExport, setModal } from '../../../../../utils/linkUtils'
import { arrayOf } from '../../../../../utils/StoreUtils'
import { getSandreLabel } from '../../../../../utils/StringUtil'
import DtoInstallation from '../../../../dto/installation/DtoInstallation'
import BoreholeLithologyModal from './BoreholeLithologyModal'

const BoreholeLithologyTable = ({
    station = {},
    sandreCodes = [],
    readMode = false,
    onChange = () => {},
    setPopup = () => {},
    className = '',
}) => {
    const stationLithology = station.link_lithology.map((l, index) => ({
        ...l,
        index,
    }))
    const tableData = stationLithology.map(c => ({
        ...c,
        type: getSandreLabel(sandreCodes, SANDRE.LITHOLOGY_TYPE, c.type),
        lithology: getSandreLabel(sandreCodes, SANDRE.LITHOLOGY, c.lithologyCode),
    }))
    const headers = ['lithology', 'order', 'thickness', 'comment']
    const exportAction = getExport(tableData, i18n.lithology, headers)
    const actions = !readMode ? [{
        iconName: 'note_add',
        tooltip: i18n.add,
        onClick: () => setModal(i18n.newLithology, <BoreholeLithologyModal id={ station.id } saveResult={ r => onChange({ link_lithology: [...stationLithology, r] }) }/>, setPopup),
    }, exportAction] : [exportAction]

    return (
        <Table
            title={ i18n.lithology }
            className={className}
            condensed
            data={ tableData }
            type={{ headers }}
            sortable={ !!tableData.length }
            actions={ actions }
            alterable={ !readMode }
            onAlter={
                (element) => setModal(i18n.editLithology,
                    <BoreholeLithologyModal
                        id={ station.id }
                        lithology={ stationLithology.find(l => element.index === l.index) }
                        saveResult={ r => onChange({
                            link_lithology: [
                                ...stationLithology.filter(l => l.index !== element.index),
                                { ...r, index: element.index },
                            ],
                        })}
                    />
                    , setPopup)
            }
            deletable={ !readMode }
            onDelete={ (element) => onChange({ link_lithology: stationLithology.filter(l => l.index !== element.index) }) }
        />
    )
}

BoreholeLithologyTable.propTypes = {
    station: PropTypes.instanceOf(DtoInstallation),
    sandreCodes: arrayOf(DtoSandreCode),
    readMode: PropTypes.bool,
    onChange: PropTypes.func,
    setPopup: PropTypes.func,
    className: PropTypes.string,
}

const mapStateToProps = store => ({
    sandreCodes: store.ReferencialReducer.sandreCodes,
})

const mapDispatchToProps = {
    setPopup: SieauAction.setPopup,
}

export default connect(mapStateToProps, mapDispatchToProps)(BoreholeLithologyTable)