import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import DatePicker from '../../../../components/forms/DatePicker'
import Input from '../../../../components/forms/Input'
import Select from '../../../../components/forms/Select'
import Textarea from '../../../../components/forms/Textarea'
import { getDefaultValuesChoiceModal, getMatTypes } from '../../../../utils/MaterielUtils'
import DtoSimType from '../dto/DtoSimType'
import SimDto from '../dto/SimDto'

const SimEquipmentPanel = ({
    active = false,
    disabled = true,
    sim = {},
    simTypes = [],
    onChange = () => { },
}) => (
    <div className='col s12 margin-bottom-1'>
        <div className='row no-margin' style={{ paddingTop: '3px' }}>
            <Select
                options={getMatTypes(simTypes, sim.simType)}
                label={i18n.type}
                col={4}
                onChange={value => {
                    onChange({ simType: value })
                    getDefaultValuesChoiceModal(simTypes.find(c => c.materielType == value), onChange)
                }}
                value={sim.simType}
                nullLabel='&nbsp;'
                active={active}
                disabled={disabled}
            />
        </div>
        <div className='row no-margin' style={{ paddingTop: '3px' }}>
            <DatePicker
                onChange={(_, v) => onChange({ enableDate: v })}
                id='enableDate'
                title={i18n.activationDate}
                value={sim.enableDate}
                col={4}
                active={active}
                disabled={disabled}
                startDate={sim.purchaseDate}
                endDate={sim.disableDate}
            />
            <DatePicker
                onChange={(_, v) => onChange({ disableDate: v })}
                id='disableDate'
                title={i18n.closingLine}
                value={sim.disableDate}
                col={4}
                active={active}
                disabled={disabled}
                startDate={sim.enableDate}
            />
            {
                sim.disableDate && (
                    <Input
                        col={4}
                        title={i18n.closeCause}
                        value={sim.explanationDisable}
                        onChange={value => onChange({ explanationDisable: value })}
                        active={active}
                        disabled={disabled}
                    />
                )
            }
        </div>
        <div className='row no-margin' style={{ paddingTop: '8px' }}>
            <Textarea
                col={12}
                title={i18n.comment}
                value={sim.comment}
                onChange={value => onChange({ comment: value })}
                active={active}
                disabled={disabled}
            />
        </div>
    </div>
)

SimEquipmentPanel.propTypes = {
    disabled: PropTypes.bool,
    active: PropTypes.bool,
    onChange: PropTypes.func,
    sim: PropTypes.instanceOf(SimDto),
    simTypes: PropTypes.arrayOf(PropTypes.instanceOf(DtoSimType)),
}

const mapStateToProps = store => ({
    simTypes: store.SimReducer.simTypes,
})

export default connect(mapStateToProps)(SimEquipmentPanel)
