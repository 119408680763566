/* eslint-disable camelcase */
import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import { shallowEqual, useSelector } from 'react-redux'
import DtoInstallation from '../../../dto/installation/DtoInstallation'
import Row from '../../../../components/react/Row'
import Select from '../../../../components/forms/Select'
import Card from 'components/card/Card'
import StationWatermassPanel from 'station/components/link/StationWatermassPanel'
import { omit } from 'lodash'
import Checkbox from 'components/forms/Checkbox'
import Input from 'components/forms/Input'

const InstallationContextPanel = ({
    defaultStation = {},
    onChange = () => {},
    readMode = false,
}) => {
    const defaultStationWithWatermasses = useMemo(() => {
        const defaultLinkWatermasses = defaultStation.watermassCode ? [ { watermassCode: defaultStation.watermassCode } ] : []
        return defaultLinkWatermasses.length ? { ...defaultStation, link_watermasses: [ { watermassCode: defaultStation.watermassCode } ] } : defaultStation
    }, [defaultStation])

    const [station, setStation] = useState(defaultStationWithWatermasses)

    const {
        watersheds,
        hydrometricStations,
    } = useSelector(store => ({
        watersheds: store.WatershedReducer.watersheds,
        hydrometricStations: store.HydrometryReducer.hydrometricStations,
    }), shallowEqual)

    useEffect(() => {
        setStation(defaultStationWithWatermasses)
    }, [defaultStationWithWatermasses])

    const onChangeWatermassLinks = (newObj) => {
        const { link_watermasses } = newObj
        const watermassCode = link_watermasses?.[0]
        onChange({ ...omit(station, 'link_watermasses'), ...watermassCode })
        setStation({ ...station, link_watermasses })
    }

    const params = useMemo(() => ({
        station,
        onChange: onChangeWatermassLinks,
        readMode,
    }), [readMode, station])

    const onChangeStation = (newObj) => {
        setStation({ ...station, ...newObj })
        onChange(newObj)
    }

    const hydrometricStationsFormatted = useMemo(() => hydrometricStations.map(hydro => ({
        ...hydro,
        name: `[${hydro.code}] ${hydro.name}`,
    })), [hydrometricStations])

    return (
        <Card title={ i18n.context }id='context_card'>
            <Row className='padding-bottom-1'>
                <Row className={ `${!readMode && 'padding-top-1 ' || ''}` } style={{ paddingLeft: '5px' }}>
                    <Row>
                        <Checkbox
                            col={12}
                            label={i18n.referenceWork}
                            checked={station.referenceWork}
                            onChange={v => onChangeStation({ referenceWork: v })}
                            readMode={readMode}
                        />
                    </Row>
                    <Row className='padding-1'>
                        <StationWatermassPanel { ...params } limit={1} className='blue'/>
                    </Row>
                    <Row>
                        <Select
                            col={ 6 }
                            value={ station.watershed1 }
                            label={ i18n.watershed }
                            options={ watersheds }
                            keyValue='id'
                            onChange={ v => onChangeStation({ watershed1: v }) }
                            readMode={readMode}
                        />
                        <Select
                            col={ 6 }
                            value={ station.watershed2 }
                            label={ i18n.watershed2 }
                            options={ watersheds }
                            keyValue='id'
                            onChange={ v => onChangeStation({ watershed2: v }) }
                            readMode={readMode}
                        />
                    </Row>
                    <Row>
                        <Select
                            col={ 6 }
                            value={ station.watershed3 }
                            label={ i18n.watershed3 }
                            options={ watersheds }
                            keyValue='id'
                            onChange={ v => onChangeStation({ watershed3: v }) }
                            readMode={readMode}
                        />
                        <Select
                            col={ 6 }
                            value={ station.watershed4 }
                            label={ i18n.watershed4 }
                            options={ watersheds }
                            keyValue='id'
                            onChange={ v => onChangeStation({ watershed4: v }) }
                            readMode={readMode}
                        />
                    </Row>
                    <Row>
                        <Select
                            col={ 6 }
                            value={ station.hydroCode }
                            label={ i18n.stationHYDROCode }
                            options={ hydrometricStationsFormatted }
                            keyValue='code'
                            onChange={ v => onChangeStation({ hydroCode: v }) }
                            readMode={readMode}
                        />
                        <Input
                            col={ 6 }
                            value={ station.river }
                            title={ i18n.river }
                            onChange={ v => onChangeStation({ river: v }) }
                            readMode={readMode}
                        />
                    </Row>
                </Row>
            </Row>
        </Card>
    )
}

InstallationContextPanel.propTypes = {
    defaultStation: PropTypes.instanceOf(DtoInstallation),
    onChange: PropTypes.func,
    readMode: PropTypes.bool,
}

export default InstallationContextPanel
