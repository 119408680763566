import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import ToastrAction from 'toastr/actions/ToastrAction'
import Checkbox from '../../../../components/forms/Checkbox'
import Job from '../../../dto/DtoJob'
import NumberField from '../../../../components/forms/NumberField'
import MultiContributorsAutocomplete from 'referencial/components/contributor/components/MultiContributorsAutocomplete'
import useParseJson from 'utils/customHook/useParseJson'
import { Button, Grid } from '@mui/material'
import { isUndefined } from 'lodash'

const MaterielAccounting = ({
    job = {},
    onChangeJob = () => {},
    isEditMode = false,
}) => {
    const dispatch = useDispatch()
    const {
        contributors,
    } = useSelector(store => ({
        contributors: store.ContributorReducer.contributors,
    }), shallowEqual)

    const [fileName, setFileName] = useState()

    const {
        json: filters,
    } = useParseJson(job.parameters?.filters?.[0] ?? '{}')

    const onChange = (change) => {
        onChangeJob({
            parameters: {
                ...job.parameters,
                filters: [JSON.stringify({ ...filters, ...change })],
            },
        })
    }

    const openFile = e => {
        const reader = new FileReader()
        const fileOpened = e.target.files[0]

        if (isUndefined(fileOpened)) {
            return
        }

        reader.onload = upload => {
            const split = upload.target.result.split(',')

            if (split[0].indexOf('base64') > 0) {
                setFileName(fileOpened.name)
                onChange({ file: split[1], nameOfFile: fileOpened.name })
            } else {
                dispatch(ToastrAction.error(i18n.theSelectedFileMustBeInCsvFormat))
            }
        }
        reader.readAsDataURL(fileOpened)
    }

    const {
        rename,
        dataIntegration,
        updateImmo,
        hasHeader,
        nbHeaders,
        validator,
    } = filters

    return (
        <Grid container spacing={1} sx={{ padding: '10' }} alignItems='center'>
            <Grid item xs={6}>
                <Checkbox
                    label={i18n.hasHeader}
                    onChange={v => onChange({ hasHeader: v })}
                    disabled={!isEditMode}
                    checked={hasHeader}
                />
            </Grid>
            <Grid item xs={6}>
                <NumberField
                    title={i18n.nbLine}
                    value={nbHeaders ?? 6}
                    onChange={v => onChange({ nbHeaders: v })}
                    disabled={!isEditMode}
                    floatValue
                />
            </Grid>
            <Grid item xs={12}>
                <MultiContributorsAutocomplete
                    label='D.R.'
                    values={validator}
                    onChange={v => onChange({ validator: v })}
                    options={contributors}
                    disabled={!isEditMode}
                />
            </Grid>
            <Grid item xs={4}>
                <Checkbox
                    checked={!!rename}
                    label={i18n.fileRenaming}
                    onChange={v => onChange({ rename: v })}
                    disabled={!isEditMode}
                />
            </Grid>
            <Grid item xs={4}>
                <Checkbox
                    checked={!!dataIntegration}
                    label={i18n.noDataIntegrationIfError}
                    onChange={v => onChange({ dataIntegration: v })}
                    disabled={!isEditMode}
                />
            </Grid>
            <Grid item xs={4}>
                <Checkbox
                    checked={!!updateImmo}
                    label={i18n.updateImmo}
                    onChange={v => onChange({ updateImmo: v })}
                    disabled={!isEditMode}
                />
            </Grid>
            <Grid item xs={2}>
                <label>{i18n.importFile}</label>
            </Grid>
            <Grid item xs={2}>
                <Button
                    variant='contained'
                    color='primary'
                    component='label'
                    fullWidth
                    disabled={!isEditMode}
                >
                    <span>{i18n.importFile}</span>
                    <input
                        type='file'
                        accept='.xlsx, .XLSX'
                        hidden
                        onChange={openFile}
                    />
                </Button>
            </Grid>
            <Grid item xs={4}>
                <label style={{ fontSize: '1em' }}>{fileName ?? i18n.pleaseSelectFile}</label>
            </Grid>
        </Grid>
    )
}

MaterielAccounting.propTypes = {
    job: PropTypes.instanceOf(Job).isRequired,
    onChangeJob: PropTypes.func.isRequired,
    isEditMode: PropTypes.bool,
}

export default MaterielAccounting
