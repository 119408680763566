/* eslint-disable camelcase */
import DtoVisitActionDoc from './DtoVisitActionDoc'

export default class DtoVisitAction {
    constructor(obj) {
        this.idInstallation = obj.idInstallation // Long,
        this.idCampaign = obj.idCampaign // Long,
        this.idVisit = obj.idVisit // Long,
        this.responsible = obj.responsible // Option[Long],
        this.comment = obj.comment // Option[String],
        this.deadline = obj.deadline // Option[Int],
        this.idAction = obj.idAction // Option[Long],
        this.name = obj.name // Option[String],
        this.localisation = obj.localisation // Option[Long],
        this.category = obj.category // Option[Long],
        this.risk = obj.risk // Option[Long],
        this.correctionDate = obj.correctionDate // Option[DateTime],
        this.commentCorrection = obj.commentCorrection // Option[String],
        this.interventionType = obj.interventionType // Option[Long],
        this.equipmentType = obj.equipmentType // Option[Long],

        this.link_documents = obj.link_documents ? obj.link_documents.map((a, index) => new DtoVisitActionDoc(a, index)) : [] // Option[Seq[VisitActionWithLinks]]
    }
}
