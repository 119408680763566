import i18n from 'simple-react-i18n'
import HomeAction from 'home/actions/HomeAction'
import React, { useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { WhiteCard } from 'components/styled/Card'
import { Grid } from '@mui/material'
import PropTypes from 'prop-types'
import Input from 'components/forms/Input'
import { ButtonMUI } from 'components/styled/Buttons'

const SimpleSearchPanel = ({
    defaultSearchValue = '',
    setSearchValue = () => {},
}) => {
    const {
        globalResearch,
    } = useSelector(store => ({
        globalResearch: store.HomeReducer.globalResearch,
    }), shallowEqual)

    const [searchValueTmp, setSearchValueTmp] = useState(defaultSearchValue)

    const dispatch = useDispatch()

    useEffect(() => {
        if (globalResearch) {
            setSearchValue(globalResearch)
            dispatch(HomeAction.updateGlobalResearch(''))
        }
    }, [globalResearch])

    return (
        <WhiteCard round noMargin={false} title={i18n.criterias}>
            <Grid container alignItems='baseline' justifyContent='space-between' sx={{ padding: '1rem' }}>
                <Grid item xs={4}>
                    <Input
                        title={i18n.search}
                        tooltip={i18n.searchThenEnter}
                        value={searchValueTmp}
                        onChange={setSearchValueTmp}
                        onEnterKeyPress={() => setSearchValue(searchValueTmp)}
                        clearFunction
                    />
                </Grid>
                <Grid container item xs={4} justifyContent='flex-end' columnSpacing={1}>
                    <Grid item>
                        <ButtonMUI
                            variant='outlined'
                            onClick={() => {
                                setSearchValue('')
                                setSearchValueTmp('')
                            }}
                        >
                            {i18n.reinit}
                        </ButtonMUI>
                    </Grid>
                    <Grid item>
                        <ButtonMUI
                            variant='contained'
                            onClick={() => setSearchValue(searchValueTmp)}
                        >
                            {i18n.search}
                        </ButtonMUI>
                    </Grid>
                </Grid>
            </Grid>
        </WhiteCard>
    )
}

SimpleSearchPanel.propTypes = {
    defaultSearchValue: PropTypes.string,
    setSearchValue: PropTypes.func,
}

export default SimpleSearchPanel