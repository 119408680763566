import { RECEIVE_PARCEL_AND_SECTION } from '../../station/constants/StationConstants'

export function ApiCartoReducer(state = {}, action) {
    switch (action.type) {
        case RECEIVE_PARCEL_AND_SECTION:
            return {
                ...state,
                parcelAndSection: action.parcelAndSection,
            }
        default:
            return state
    }
}

export const store = {
    parcelAndSection: null,
}
