/* eslint-disable camelcase */
/* eslint-disable indent */
import { Button, Card, Dialog, Grid, Icon } from '@mui/material'
import { withStyles } from '@mui/styles'
import SettingDto from 'administration/dto/SettingDto'
import { SITU_POINT_PUMP, SITU_PUMP_COUNTER } from 'agriAdministration/constants/AgriConstants'
import Checkbox from 'components/forms/Checkbox'
import Input from 'components/forms/Input'
import NumberField from 'components/forms/NumberField'
import Select from 'components/forms/Select'
import SimpleDatePicker from 'components/forms/SimpleDatePicker'
import Textarea from 'components/forms/Textarea'
import { DialogActionsMUI, DialogTitleMUI } from 'components/styled/Dialog'
import DtoExploitation from 'exploitations/dto/DtoExploitation'
import { compact } from 'lodash'
import LogAction from 'log/actions/LogAction'
import DtoVariousMaterielSituation from 'materiel/components/variousMateriel/dto/DtoVariousMaterielSituation'
import DtoVariousMaterielType from 'materiel/components/variousMateriel/dto/DtoVariousMaterielType'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import DtoSandreCode from 'referencial/dto/DtoSandreCode'
import i18n from 'simple-react-i18n'
import DtoVariousMateriel from 'station/dto/materiel/DtoVariousMateriel'
import DtoDeclaration from 'survey/dto/DtoDeclaration'
import DtoSamplingPointDecla from 'survey/dto/DtoSamplingPointDecla'
import { secondaryBlue } from 'utils/constants/ColorTheme'
import { getDate } from 'utils/DateUtil'
import { hasValue } from 'utils/NumberUtil'
import { getSetting } from 'utils/SettingUtils'
import { getSandreLabel } from 'utils/StringUtil'

const styles = () => ({
    card: {
        padding: '8px',
    },
    selected: {
        padding: '8px',
        backgroundColor: `${secondaryBlue} !important`,
        color: 'white !important',
    },
    item: {
        padding: '4px !important',
    },
})

class ModalAddEquipements extends Component {
    constructor(props) {
        super(props)
        const pointId = props.point.idInstallation
        this.state = {
            link_equipments: props.declaration.link_declarationInstallation.find((p) => p.idInstallation === pointId).link_equipments || [],
            link_pumps_maj: props.declaration.link_pumps || [],
            link_counters_maj: props.declaration.link_counters || [],
            allPointsSituation: props.declaration.link_exploitationMateriel.filter((m) => m.siteType === SITU_POINT_PUMP &&
                props.declaration.link_exploitationInstallation.find((p) => m.siteCode === p.idInstallation)) || [],
            materiels: props.materiels.filter((m) => m.siteCode !== pointId),
            materielsSelected: props.materiels.filter((m) => m.siteCode === pointId),
            mergedMatSitu: [],
            mergedSelectedMatSitu: [],
            linkAssignments: [],
            linkCounters: [],
            panel: 'pumps',
            pump: null,
            counter: null,
        }
    }

    componentDidMount() {
        const { declaration } = this.props
        const { materiels, materielsSelected, link_equipments } = this.state

        const newMats = link_equipments.filter((mLink) => !mLink.idMatAttachment && mLink.mode === 'c')
        const deletedMats = link_equipments.filter((mLink) => !mLink.idMatAttachment && mLink.mode === 'd')

        const newSelectedMatsSitu = compact(newMats.map((mLink) => {
            return declaration.link_exploitationMateriel.find((mSitu) => mSitu.idVarious === mLink.idMat)
        }))
        const mergedSelectedMatSitu = compact([
            ...materielsSelected.filter((m) => !deletedMats.find((mLink) => m.idVarious === mLink.idMat)),
            ...newSelectedMatsSitu,
        ])

        const newMatsSitu = compact(deletedMats.map((mLink) => {
            return declaration.link_exploitationMateriel.find((mSitu) => mSitu.idVarious === mLink.idMat)
        }))
        const mergedMatSitu = compact([
            ...materiels.filter((m) => !newMats.find((mLink) => m.idVarious === mLink.idMat)),
            ...newMatsSitu,
        ])

        const linkAssignments = link_equipments.filter((linkE) => linkE.assignmentRate).map((linkE) => {
            return {
                ...linkE,
                idMat: linkE.idMat,
                assignmentRate: linkE.assignmentRate,
            }
        })

        let linkCounters = link_equipments.filter((linkE) => linkE.idMatAttachment).map((linkE) => {
            return {
                ...linkE,
                idPump: linkE.idMat,
                idCounter: linkE.idMatAttachment,
                mode: linkE.mode,
            }
        })
        mergedSelectedMatSitu.forEach((mat) => {
            const counterSitu = declaration.link_exploitationMateriel.find((mSitu) => mSitu.siteType === 8 && mat.idVarious === mSitu.siteCode)
            if (counterSitu && !linkCounters.find((linkC) => linkC.idPump === counterSitu.siteCode && linkC.idCounter === counterSitu.idVarious)) {
                linkCounters.push({
                    ...counterSitu,
                    idPump: counterSitu.siteCode,
                    idCounter: counterSitu.idVarious,
                    mode: 'u',
                })
            }
        })
        this.setState({ mergedMatSitu, mergedSelectedMatSitu, linkAssignments, linkCounters })
    }

    updateMatSelected = (pompeInfos) => {
        const { mergedMatSitu, mergedSelectedMatSitu } = this.state
        const matFind = mergedSelectedMatSitu.find((m) => m.idVarious === pompeInfos.id)
        if (matFind) {
            this.setState({
                mergedSelectedMatSitu: mergedSelectedMatSitu.filter((p) => p.idVarious !== pompeInfos.id),
                mergedMatSitu: [...mergedMatSitu, matFind],
            })
        } else {
            this.setState({
                mergedSelectedMatSitu: [...mergedSelectedMatSitu, mergedMatSitu.find((m) => m.idVarious === pompeInfos.id)],
                mergedMatSitu: mergedMatSitu.filter((p) => p.idVarious !== pompeInfos.id),
            })
        }
    }

    changePanel = (panel, matInfos) => {
        switch (panel) {
            case 'pumps':
                this.setState({ panel, pump: null })
                break
            case 'counters':
                this.setState({ panel, pump: matInfos, counter: null })
                break
            case 'counterDetails':
                this.setState({ panel, counter: matInfos })
                break
            default:
                this.setState({ panel, pump: null })
                break
        }
    }

    getPumpCard = (pompeInfos, selected) => {
        const { sandreCodes, classes, variousMaterielTypes, variousMateriels } = this.props
        const { linkCounters } = this.state
        const pumpNature = variousMaterielTypes.find((t) => t.materielType === pompeInfos.materielType)
        const pumpCategory = getSandreLabel(sandreCodes, 'MAT.MOBILITE', pompeInfos.mobilityCode)
        const pumpType = getSandreLabel(sandreCodes, 'POMPES.TYPE', pompeInfos.pumpType)
        const filteredCounters = variousMateriels.filter((c) => linkCounters.find((linkC) => linkC.idPump === pompeInfos.id && linkC.idCounter === c.id && linkC.mode !== 'd'))
        return (
            <Grid item xs={3} className='clickable' onClick={() => this.updateMatSelected(pompeInfos)}>
                <Card className={selected ? classes.selected : classes.card}>
                    <Grid container>
                        <Grid item xs={10}>
                            <span className='bold'>{i18n.id} : </span><span>{pompeInfos.reference || ''}</span>
                        </Grid>
                        {selected && (
                            <Grid item xs={2} onClick={(e) => {
                                e.stopPropagation()
                                this.changePanel('counters', pompeInfos)
                            }}
                            >
                                <Icon style={{ fontSize: '2rem' }}>edit</Icon>
                            </Grid>
                        )}
                        <Grid item xs={12}>
                            <span className='bold'>{i18n.nature} : </span><span>{pumpNature ? pumpNature.name : ''}</span>
                        </Grid>
                        <Grid item xs={12}>
                            <span className='bold'>{i18n.category} : </span><span>{pumpCategory || ''}</span>
                        </Grid>
                        <Grid item xs={12}>
                            <span className='bold'>{i18n.type} : </span><span>{pumpType || ''}</span>
                        </Grid>
                        <Grid item xs={12}>
                            <span className='bold'>{i18n.maxFlow} : </span><span>{pompeInfos.maxFlow || ''}</span>
                        </Grid>
                        <Grid item xs={12}>
                            <span className='bold'>{i18n.inUseFlow} : </span><span>{pompeInfos.operatingFlow || ''}</span>
                        </Grid>
                        <Grid item xs={12}>
                            <span className='bold'>{i18n.comment} : </span><span>{pompeInfos.comment || ''}</span>
                        </Grid>
                        <Grid item xs={12} className='borderTop-Grey'>
                            <span className='bold'>{`${filteredCounters.length > 1 ? i18n.countingDevices : i18n.countingDevice} : `}</span>
                        </Grid>
                        <Grid item xs={12}>
                            <span>{filteredCounters.length === 0 ? 'Aucun' : filteredCounters.map((mat, index) => `${mat.reference}${index + 1 !== filteredCounters.length ? ', ' : ''}`)}</span>
                        </Grid>
                    </Grid>
                </Card>
            </Grid>
        )
    }

    getPumps = (matsSitu, selected) => {
        const {
            variousMateriels,
            declaration,
            exploitation,
        } = this.props
        const { link_equipments, link_pumps_maj } = this.state
        return matsSitu.map((pointSitu) => {
            const updatedMat = link_equipments.find((mLink) => mLink.idMat === pointSitu.idVarious && mLink.mode !== selected ? 'd' : 'c')
            const pumpRep = exploitation.link_repartition_materiels.find((m) => m.linkType === 1 && m.idElement1 === pointSitu.siteCode && m.idElement2 === pointSitu.idVarious) || {}
            const pompeSituation = declaration.link_exploitationMateriel.find((m) => m.siteType === 8 && m.siteCode === pointSitu.idVarious)
            const pompeMat = variousMateriels.find((m) => m.id === pointSitu.idVarious) || {}
            const pompe = pompeMat.pump || {}
            const pompeUpdated = link_pumps_maj.find((p) => p.id === pompeMat.id)
            const pompeInfos = {
                ...pompeSituation,
                ...pompeMat,
                ...pompe,
                ...pompeUpdated,
                assignmentRate: updatedMat ? updatedMat.assignmentRate : pumpRep.repartition,
            }
            if (!pompeMat.id) {
                this.props.logError(`Le matériel ayant l'id ${pointSitu.idVarious} ne semble plus exister dans la table des matériels divers`)
                return null
            }
            return this.getPumpCard(pompeInfos, selected)
        }).filter(d => !!d)
    }

    updateCountersSelected = (idPump, idCounter) => {
        const { linkCounters } = this.state
        const linkFind = linkCounters.find((link) => link.idPump === idPump && link.idCounter === idCounter)
        if (linkFind) {
            const filteredLinks = linkCounters.filter((link) => link.idPump !== idPump || link.idCounter !== idCounter)
            if (linkFind.mode === 'd') {
                this.setState({ linkCounters: [...filteredLinks, { ...linkFind, mode: 'u' }] })
            } else {
                this.setState({ linkCounters: [...filteredLinks, { ...linkFind, mode: 'd' }] })
            }
        } else {
            this.setState({ linkCounters: [...linkCounters, { idPump, idCounter, mode: 'c' }] })
        }
    }

    getCounterCard = (compteurInfos, selected) => {
        const { classes, variousMaterielTypes } = this.props
        const { pump } = this.state
        const counterType = variousMaterielTypes.find((t) => t.materielType === compteurInfos.materielType)
        return (
            <Grid item xs={3} className='clickable' onClick={() => this.updateCountersSelected(pump.id, compteurInfos.id)}>
                <Card className={selected ? classes.selected : classes.card}>
                    <Grid container>
                        <Grid item xs={10} style={{ marginBottom: '5px' }}>
                            <span className='bold'>{i18n.id} : {compteurInfos.reference || ''}</span>
                        </Grid>
                        {selected && (
                            <Grid item xs={2} onClick={(e) => {
                                e.stopPropagation()
                                this.changePanel('counterDetails', compteurInfos)
                            }}
                            >
                                <Icon style={{ fontSize: '2rem' }}>edit</Icon>
                            </Grid>
                        )}
                        <Grid item xs={12}>
                            <span className='bold'>{i18n.type} : </span><span>{counterType ? counterType.name : ''}</span>
                        </Grid>
                        <Grid item xs={12}>
                            <span className='bold'>{i18n.brand} : </span><span>{compteurInfos.brand || ''}</span>
                        </Grid>
                        <Grid item xs={12}>
                            <span className='bold'>{i18n.readingCoefficient} : </span><span>{compteurInfos.readingCoefficient || ''}</span>
                        </Grid>
                        <Grid item xs={12}>
                            <span className='bold'>{i18n.installationDate} : </span><span>{getDate(compteurInfos.installationDate) || ''}</span>
                        </Grid>
                        <Grid item xs={12}>
                            <span className='bold'>{i18n.revisionDate} : </span><span>{getDate(compteurInfos.revisionDate) || ''}</span>
                        </Grid>
                        <Grid item xs={12}>
                            <span className='bold'>{i18n.shared} : </span><span>{compteurInfos.isShared ? i18n.yes : i18n.no}</span>
                        </Grid>
                        <Grid item xs={12}>
                            <span className='bold'>{i18n.comment} : </span><span>{compteurInfos.comment || ''}</span>
                        </Grid>
                    </Grid>
                </Card>
            </Grid>
        )
    }

    getCounters = (selected) => {
        const { allPointsSituation, linkCounters, pump, link_counters_maj } = this.state
        const { variousMateriels, declaration, exploitation } = this.props
        if (selected) {
            const filteredVariousMatCounters = variousMateriels.filter((c) => linkCounters.find((linkC) => linkC.idPump === pump.id && linkC.idCounter === c.id && linkC.mode !== 'd'))
            return filteredVariousMatCounters.map((counterMat) => {
                const compteurInfos = {
                    ...counterMat,
                    ...(counterMat.counter || {}),
                    ...link_counters_maj.find((p) => p.id === counterMat.id),
                }
                return this.getCounterCard(compteurInfos, selected)
            })
        }
        const allPumpsSituation = declaration.link_exploitationMateriel.filter((m) => m.siteType === SITU_PUMP_COUNTER &&
            allPointsSituation.find((p) => m.siteCode === p.idVarious)) || []
        let allIdsCounters = []
        return allPumpsSituation.map((pumpSitu) => {
            if (!allIdsCounters.includes(pumpSitu.idVarious) && !linkCounters.find((linkC) => linkC.idPump === pump.id && linkC.idCounter === pumpSitu.idVarious && linkC.mode !== 'd')) {
                allIdsCounters.push(pumpSitu.idVarious)
                const counterMat = variousMateriels.find((c) => c.id === pumpSitu.idVarious) || {}
                if (!hasValue(counterMat.administrator) || (counterMat.administrator === exploitation.operatorCode)) {
                    const compteurInfos = {
                        ...counterMat,
                        ...(counterMat.counter || {}),
                        ...link_counters_maj.find((p) => p.id === pumpSitu.idVarious),
                    }
                    return this.getCounterCard(compteurInfos, selected)
                }
                return ''
            }
            return ''
        })
    }

    onChangeCounter = (compteurInfos, key, value) => {
        const { link_counters_maj } = this.state
        const { declaration, point } = this.props
        const counterFind = link_counters_maj.find((c) => c.id === compteurInfos.id)
        if (counterFind) {
            this.setState({
                link_counters_maj: [
                    {
                        ...counterFind,
                        [key]: value,
                    },
                    ...link_counters_maj.filter((c) => c.id !== compteurInfos.id),
                ],
            })
        } else {
            this.setState({
                link_counters_maj: [
                    {
                        idSurvey: declaration.idSurvey,
                        idExploitation: declaration.idExploitation,
                        idInstallation: point.idInstallation,
                        ...compteurInfos,
                        [key]: value,
                    },
                    ...link_counters_maj,
                ],
            })
        }
    }

    onChangePump = (pompeInfos, key, value) => {
        const { link_pumps_maj } = this.state
        const { declaration, point } = this.props
        const pumpFind = link_pumps_maj.find((c) => c.id === pompeInfos.id)
        if (pumpFind) {
            this.setState({
                link_pumps_maj: [
                    {
                        ...pumpFind,
                        [key]: value,
                    },
                    ...link_pumps_maj.filter((c) => c.id !== pompeInfos.id),
                ],
            })
        } else {
            this.setState({
                link_pumps_maj: [
                    {
                        idSurvey: declaration.idSurvey,
                        idExploitation: declaration.idExploitation,
                        idInstallation: point.idInstallation,
                        ...pompeInfos,
                        [key]: value,
                    },
                    ...link_pumps_maj,
                ],
            })
        }
    }

    getForm = (type, matInfos) => {
        const { linkAssignments, linkCounters, pump } = this.state
        const { variousMaterielTypes, sandreCodes, applicationSettings, exploitation, point } = this.props
        const materielReadingCoeffEntry = getSetting(applicationSettings, 'materielReadingCoeffEntry')
        if (type === 'pump') {
            const pumpCategories = sandreCodes.filter((c) => c.field === 'MAT.MOBILITE')
            const pumpTypes = sandreCodes.filter((c) => c.field === 'POMPES.TYPE')
            const link = linkAssignments.find((lA) => lA.idMat === matInfos.id && !lA.idMatAttachment) || {}
            const pumpRep = exploitation.link_repartition_materiels.find((m) => m.linkType === 1 && m.idElement1 === point.idInstallation && m.idElement2 === matInfos.id) || {}
            const pumpTypesFiltered = variousMaterielTypes.filter((t) => t.category === 1)
            return (
                <Grid container spacing={1}>
                    <Grid item xs={6}>
                        <SimpleDatePicker
                            id='startDatePump'
                            label={i18n.pumpCommissioningDate}
                            value={link.startDate || link.situationDate}
                            onChange={(date) => this.onChangeAssignmentPump({ startDate: date }, matInfos)}
                            max={link.endDate}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <SimpleDatePicker
                            id='endDatePump'
                            label={i18n.pumpDecommissioningDate}
                            value={link.endDate || link.situationEndDate}
                            onChange={(date) => this.onChangeAssignmentPump({ endDate: date }, matInfos)}
                            min={link.startDate}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <NumberField
                            id='assignmentRatePump'
                            title={`${i18n.assignmentRate} (en %)`}
                            value={link.assignmentRate || pumpRep.repartition}
                            onChange={(v) => this.onChangeAssignmentPump({ assignmentRate: parseInt(v) || null }, matInfos)}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Select
                            id='pumpNaturePump'
                            label={i18n.nature}
                            value={matInfos.materielType}
                            onChange={(v) => this.onChangePump(matInfos, 'materielType', v)}
                            options={pumpTypesFiltered}
                            keyvalue='materielType'
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Select
                            id='mobilityCodePump'
                            label={i18n.category}
                            value={matInfos.mobilityCode}
                            onChange={(v) => this.onChangePump(matInfos, 'mobilityCode', v)}
                            options={pumpCategories}
                            keyvalue='code'
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Select
                            id='pumpNaturePump'
                            label={i18n.type}
                            value={matInfos.pumpType}
                            onChange={(v) => this.onChangePump(matInfos, 'pumpType', v)}
                            options={pumpTypes}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <NumberField
                            id='maxFlowPump'
                            title={i18n.maxFlow}
                            min={0}
                            value={matInfos.maxFlow}
                            onChange={(v) => this.onChangePump(matInfos, 'maxFlow', parseFloat(v))}
                            floatValue
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <NumberField
                            id='operatingFlowPump'
                            title={i18n.inUseFlow}
                            min={0}
                            value={matInfos.operatingFlow}
                            onChange={(v) => this.onChangePump(matInfos, 'operatingFlow', parseFloat(v))}
                            floatValue
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Checkbox
                            id='pumpSharedPump'
                            label={i18n.shared}
                            checked={matInfos.isShared || false}
                            onChange={(e) => this.onChangePump(matInfos, 'isShared', e)}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Checkbox
                            id='pumpRentPump'
                            label={i18n.forRent}
                            checked={matInfos.rent || false}
                            onChange={(e) => this.onChangePump(matInfos, 'rent', e)}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Textarea
                            id='commentPump'
                            title={i18n.comment}
                            value={matInfos.comment}
                            onChange={(v) => this.onChangePump(matInfos, 'comment', v)}
                            rows={2}
                        />
                    </Grid>
                </Grid>
            )
        }
        const counterTypesFiltered = variousMaterielTypes.filter((t) => t.category === 2)
        const link = linkCounters.find((linkC) => linkC.idCounter === matInfos.id && linkC.idPump === pump.id) || {}
        const counterRep = exploitation.link_repartition_materiels.find((m) => m.linkType === 2 && m.idElement1 === pump.id && m.idElement2 === matInfos.id) || {}
        return (
            <Grid container spacing={1}>
                <Grid item xs={6}>
                    <SimpleDatePicker
                        id='startDateCounter'
                        label={i18n.counterCommissioningDate}
                        value={link.startDate || link.situationDate}
                        onChange={(date) => this.onChangeAssignmentCounter({ startDate: date }, pump, matInfos)}
                        max={link.endDate}
                    />
                </Grid>
                <Grid item xs={6}>
                    <SimpleDatePicker
                        id='endDateCounter'
                        label={i18n.counterDecommissioningDate}
                        value={link.endDate || link.situationEndDate}
                        onChange={(date) => this.onChangeAssignmentCounter({ endDate: date }, pump, matInfos)}
                        min={link.startDate}
                    />
                </Grid>
                <Grid item xs={6}>
                    <NumberField
                        id='assignmentRateCounter'
                        title={`${i18n.assignmentRate} (en %)`}
                        value={link.assignmentRate || counterRep.repartition}
                        onChange={(v) => this.onChangeAssignmentCounter({ assignmentRate: parseInt(v) || null }, pump, matInfos)}
                    />
                </Grid>
                <Grid item xs={3} style={{ display: 'flex', alignItems: 'center' }}>
                    <Checkbox
                        id='counterSharedCounter'
                        label={i18n.shared}
                        checked={matInfos.isShared || false}
                        onChange={(e) => this.onChangeCounter(matInfos, 'isShared', e)}
                    />
                </Grid>
                <Grid item xs={3} style={{ display: 'flex', alignItems: 'center' }}>
                    <Checkbox
                        id='counterRentCounter'
                        label={i18n.forRent}
                        checked={matInfos.rent || false}
                        onChange={(e) => this.onChangeCounter(matInfos, 'rent', e)}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Select
                        id='typeCounter'
                        label={i18n.type}
                        value={matInfos.materielType}
                        onChange={(v) => this.onChangeCounter(matInfos, 'materielType', v)}
                        options={counterTypesFiltered}
                        keyvalue='materielType'
                    />
                </Grid>
                <Grid item xs={6}>
                    <Input
                        id='brandCounter'
                        title={i18n.brand}
                        value={matInfos.brand}
                        onChange={(v) => this.onChangeCounter(matInfos, 'brand', v)}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Input
                        id='serialNumberCounter'
                        title={i18n.reference}
                        value={matInfos.serialNumber}
                        onChange={(v) => this.onChangeCounter(matInfos, 'serialNumber', v)}
                    />
                </Grid>
                <Grid item xs={6}>
                    {materielReadingCoeffEntry === '1' || matInfos.materielType === 10 ? (
                        <Select
                            id='readingCoefficientCounter'
                            label={i18n.readingCoefficient}
                            value={matInfos.readingCoefficient}
                            onChange={(v) => this.onChangeCounter(matInfos, 'readingCoefficient', v)}
                            options={[{ value: 1 }, { value: 10 }]}
                            keyLabel='value'
                        />
                    ) : (
                        <NumberField
                            id='readingCoefficientCounter'
                            title={i18n.readingCoefficient}
                            min={0}
                            value={matInfos.readingCoefficient}
                            onChange={(v) => this.onChangeCounter(matInfos, 'readingCoefficient', parseFloat(v))}
                            floatValue
                        />
                    )}
                </Grid>
                <Grid item xs={6}>
                    <SimpleDatePicker
                        id='installationDateCounter'
                        label={i18n.installationDate}
                        value={matInfos.installationDate}
                        onChange={(v) => this.onChangeCounter(matInfos, 'installationDate', v)}
                    />
                </Grid>
                <Grid item xs={6}>
                    <SimpleDatePicker
                        id='revisionDateCounter'
                        label={i18n.revisionDate}
                        value={matInfos.revisionDate}
                        onChange={(v) => this.onChangeCounter(matInfos, 'revisionDate', v)}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Textarea
                        id='commentCounter'
                        title={i18n.comment}
                        value={matInfos.comment}
                        onChange={(v) => this.onChangeCounter(matInfos, 'comment', v)}
                        rows={3}
                    />
                </Grid>
            </Grid>
        )
    }

    getContent = () => {
        const {
            mergedMatSitu,
            mergedSelectedMatSitu,
            panel,
            pump,
            counter,
            link_pumps_maj,
            link_counters_maj,
        } = this.state
        switch (panel) {
            case 'pumps':
                return (
                    <>
                        <div className='padding-bottom-1' style={{ borderBottom: '1px solid grey' }}>
                            {mergedSelectedMatSitu.length > 1}<h5 style={{ padding: '0 15px' }}>{mergedSelectedMatSitu.length > 1 ? i18n.pumpsInstalled : i18n.pumpInstalled}</h5>
                            <Grid container spacing={1}>
                                {this.getPumps(mergedSelectedMatSitu, true)}
                            </Grid>
                        </div>
                        <div className='padding-bottom-1'>
                            <h5 style={{ padding: '0 15px' }}>{mergedMatSitu.length > 1 ? i18n.othersEquipAvailable : i18n.otherEquipAvailable}</h5>
                            <Grid container spacing={1}>
                                {this.getPumps(mergedMatSitu, false)}
                            </Grid>
                        </div>
                    </>
                )
            case 'counters':
                const pumpUpdated = {
                    ...pump,
                    ...link_pumps_maj.find((e) => e.id === pump.id),
                }
                return (
                    <>
                        <Grid item xs={12} className='padding-1' style={{ borderBottom: '1px solid grey' }}>
                            {this.getForm('pump', pumpUpdated)}
                        </Grid>
                        <Grid item xs={12} style={{ padding: '0 16px' }}>
                            <h5>{i18n.countingDeviceAssociated}</h5>
                        </Grid>
                        <Grid item xs={12} container spacing={1} style={{ borderBottom: '1px solid grey' }}>
                            {this.getCounters(true)}
                        </Grid>
                        <Grid item xs={12} style={{ padding: '0 16px' }}>
                            <h5>{i18n.otherCountingDevice}</h5>
                        </Grid>
                        <Grid item xs={12} container spacing={1}>
                            {this.getCounters(false)}
                        </Grid>
                    </>
                )
            case 'counterDetails':
                const counterUpdated = {
                    ...counter,
                    ...link_counters_maj.find((e) => e.id === counter.id),
                }
                return (
                    <Grid item xs={12} className='padding-1'>
                        {this.getForm('counter', counterUpdated)}
                    </Grid>
                )
            default:
                return ''
        }
    }

    getTitle = () => {
        const {
            panel,
            pump,
            counter,
            link_pumps_maj,
            link_counters_maj,
        } = this.state
        switch (panel) {
            default: case 'pumps':
                return (
                    <DialogTitleMUI>{i18n.materiels}</DialogTitleMUI>
                )
            case 'counters':
                const pumpUpdated = {
                    ...pump,
                    ...link_pumps_maj.find((e) => e.id === pump.id),
                }
                return (
                        <DialogTitleMUI>{i18n.pumpingEquipment} - {pumpUpdated.reference}</DialogTitleMUI>
                )
            case 'counterDetails':
                const counterUpdated = {
                    ...counter,
                    ...link_counters_maj.find((e) => e.id === counter.id),
                }
                return (
                        <DialogTitleMUI>{i18n.countingDevice} - {counterUpdated.reference}</DialogTitleMUI>
                )
        }
    }

    onChangeAssignmentPump = (obj, pump) => {
        const { linkAssignments } = this.state
        const linkUpdated = linkAssignments.find((link) => link.idMat === pump.id && !link.idMatAttachment) || {}
        const newLinks = [
            ...linkAssignments.filter((link) => link.idMat !== pump.id),
            {
                ...linkUpdated,
                idMat: pump.id,
                ...obj,
            },
        ]
        this.setState({ linkAssignments: newLinks })
    }

    onChangeAssignmentCounter = (obj, pump, counter) => {
        const { linkCounters } = this.state
        const linkUpdated = linkCounters.find((linkC) => linkC.idCounter === counter.id && linkC.idPump === pump.id) || {}
        const newLinks = [
            ...linkCounters.filter((linkC) => !(linkC.idCounter === counter.id && linkC.idPump === pump.id)),
            {
                ...linkUpdated,
                ...obj,
            },
        ]
        this.setState({ linkCounters: newLinks })
    }

    onSave = () => {
        const {
            exploitation,
            declaration,
            point,
        } = this.props
        const {
            materiels,
            materielsSelected,
            linkAssignments,
            linkCounters,
            mergedMatSitu,
            mergedSelectedMatSitu,
            link_pumps_maj,
            link_counters_maj,
        } = this.state
        let newlink_equipments = []
        const globalInfos = {
            idSurvey: declaration.idSurvey,
            idExploitation: exploitation.idExploitation,
            idInstallationAttachment: point.idInstallation,
        }
        mergedSelectedMatSitu.forEach((newMatSitu) => {
            const linkAssignment = linkAssignments.find((link) => link.idMat === newMatSitu.idVarious) || {}
            const newLink_equip = {
                ...globalInfos,
                idMat: newMatSitu.idVarious,
                assignmentRate: linkAssignment.assignmentRate,
                startDate: linkAssignment.startDate,
                endDate: linkAssignment.endDate,
            }
            if (materielsSelected.find((matSitu) => newMatSitu.idVarious === matSitu.idVarious)) {// update
                newlink_equipments.push({
                    ...newLink_equip,
                    mode: 'u',
                })
            } else if (materiels.find((matSitu) => newMatSitu.idVarious === matSitu.idVarious)) {// create
                newlink_equipments.push({
                    ...newLink_equip,
                    mode: 'c',
                })
            }
        })
        mergedMatSitu.forEach((newMatSitu) => {
            const linkAssignment = linkAssignments.find((link) => link.idMat === newMatSitu.idVarious) || {}
            if (materielsSelected.find((matSitu) => newMatSitu.idVarious === matSitu.idVarious)) {// delete
                newlink_equipments.push({
                    ...globalInfos,
                    idMat: newMatSitu.idVarious,
                    mode: 'd',
                    assignmentRate: linkAssignment.assignmentRate,
                    startDate: linkAssignment.startDate,
                    endDate: linkAssignment.endDate,
                })
            }
        })
        linkCounters.forEach((linkC) => {
            newlink_equipments.push({
                ...globalInfos,
                idMat: linkC.idPump,
                idMatAttachment: linkC.idCounter,
                mode: linkC.mode,
                startDate: linkC.startDate,
                endDate: linkC.endDate,
                assignmentRate: linkC.assignmentRate,
            })
        })
        this.props.onSave(newlink_equipments, link_pumps_maj, link_counters_maj)
    }

    onCancel = () => {
        this.props.onCancel()
    }

    render() {
        const { open } = this.props
        const { panel, pump } = this.state
        return (
            <Dialog
                fullWidth
                maxWidth='md'
                open={open}
            >
                {this.getTitle()}
                <div
                    className='padding-1'
                    style={{
                        height: '-webkit-fill-available',
                        overflowX: 'hidden',
                    }}
                >
                    {this.getContent()}
                </div>
                <DialogActionsMUI style={{ justifyContent: 'space-between' }}>
                    <Button
                        variant='outlined'
                        color='primary'
                        onClick={this.onCancel}
                    >
                        {i18n.cancel}
                    </Button>
                    <Button
                        variant='contained' color='primary'
                        onClick={() => this.changePanel(panel === 'counters' ? 'pumps' : 'counters', pump)}
                        disabled={panel === 'pumps'}
                    >
                        <Icon>keyboard_arrow_left</Icon>
                    </Button>
                    <Button
                        variant='contained'
                        color='primary'
                        onClick={this.onSave}
                    >
                        {i18n.validate}
                    </Button>
                </DialogActionsMUI>
            </Dialog>
        )
    }
}

ModalAddEquipements.propTypes = {
    classes: PropTypes.instanceOf(PropTypes.object),
    open: PropTypes.bool,
    point: PropTypes.instanceOf(DtoSamplingPointDecla),
    exploitation: PropTypes.instanceOf(DtoExploitation),
    declaration: PropTypes.instanceOf(DtoDeclaration),
    materiels: PropTypes.arrayOf(PropTypes.instanceOf(DtoVariousMaterielSituation)),
    variousMateriels: PropTypes.arrayOf(PropTypes.instanceOf(DtoVariousMateriel)),
    variousMaterielTypes: PropTypes.arrayOf(PropTypes.instanceOf(DtoVariousMaterielType)),
    sandreCodes: PropTypes.arrayOf(PropTypes.instanceOf(DtoSandreCode)),
    applicationSettings: PropTypes.arrayOf(PropTypes.instanceOf(SettingDto)),
    onCancel: PropTypes.func,
    onSave: PropTypes.func,
    logError: PropTypes.func,
}

const mapStateToProps = (store) => {
    return {
        variousMateriels: store.VariousMaterielReducer.variousMateriels,
        variousMaterielTypes: store.VariousMaterielReducer.variousMaterielTypes,
        sandreCodes: store.ReferencialReducer.sandreCodes,
        exploitation: store.AgriReducer.exploitation,
        applicationSettings: store.AdministrationReducer.applicationSettings,
    }
}

const mapDispatchToProps = {
    logError: LogAction.logError,
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ModalAddEquipements))
