/* eslint-disable camelcase */
import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { arrayOf, getLabel } from '../../../utils/StoreUtils'
import i18n from 'simple-react-i18n'
import DtoSandreCode from '../../../referencial/dto/DtoSandreCode'
import { getFullDate } from '../../../utils/DateUtil'
import { getSandreLabel } from '../../../utils/StringUtil'
import { SANDRE } from '../../../referencial/constants/ReferencialConstants'
import Table from '../../../components/datatable/Table'
import SieauAction from '../../../components/sieau/SieauAction'
import DtoProductionUnit from '../../../productionUnit/dto/DtoProductionUnit'
import DtoDistributionUnit from '../../../distributionUnit/dto/DtoDistributionUnit'
import DtoQualitometer from '../../../quality/dto/DtoQualitometer'
import DtoPiezometer from '../../../piezometry/dto/DtoPiezometer'
import DtoHydrometricStation from '../../../hydrometry/dto/DtoHydrometricStation'
import DtoInstallation from '../../../installation/dto/installation/DtoInstallation'
import { getExport, setModal } from '../../../utils/linkUtils'
import TempRefModal from './modals/TempRefModal'
import DtoParametrageDataType from '../../../piezometry/dto/DtoParametrageDataType'

const StationTempRefPanel = ({
    station = {},
    sandreCodes = [],
    readMode = false,
    onChange = () => {},
    setPopup = () => {},
    className = '',
    st = '',
    piezometryDataTypes = [],
    hydrometryDataTypes = [],
    pluviometryDataTypes = [],
}) => {
    const stationTempRefs = station.link_tempRefs.map((tr, index) => ({
        ...tr,
        index,
    }))
    const dt = useMemo(() => {
        if (st === 'piezometry') {
            return piezometryDataTypes
        }
        if (st === 'hydrometry') {
            return hydrometryDataTypes
        }
        return pluviometryDataTypes
    }, [st, piezometryDataTypes, hydrometryDataTypes, pluviometryDataTypes])
    const tableData = stationTempRefs.map(tr => ({
        ...tr,
        startDate: getFullDate(tr.startDate),
        endDate: getFullDate(tr.endDate),
        tempRef: getSandreLabel(sandreCodes, SANDRE.REF_TEMPORELLES, tr.tempRef),
        dataType: getLabel(dt, tr.typeId),
    }))
    const headers = ['startDate', 'endDate', 'dataType', 'tempRef', 'comment']
    const exportAction = getExport(tableData, i18n.tempRefs, headers)
    const actions = !readMode ? [{
        iconName: 'note_add',
        tooltip: i18n.add,
        onClick: () => setModal(i18n.newTempRefs, <TempRefModal dt={ dt } id={ station.id } typeName={station.typeName} saveResult={ r => onChange({ link_tempRefs: [...stationTempRefs, r] }) }/>, setPopup),
    }, exportAction] : [exportAction]

    return (
        <Table
            title={ i18n.tempRefs }
            condensed
            data={ tableData }
            type={{ headers }}
            className = {className}
            sortable={ !!tableData.length }
            actions={ actions }
            alterable={ !readMode }
            onAlter={
                (element) => setModal(i18n.editTempRefs,
                    <TempRefModal
                        id={ station.id }
                        dt={ dt }
                        typeName={station.typeName}
                        tempRef={ stationTempRefs.find(mm => element.index === mm.index) }
                        saveResult={ r => onChange({
                            link_tempRefs: [
                                ...stationTempRefs.filter(tr => tr.index !== element.index),
                                { ...r, index: element.index },
                            ],
                        })}
                    />
                    , setPopup)
            }
            deletable={ !readMode }
            onDelete={ (element) => onChange({ link_tempRefs: stationTempRefs.filter(tr => tr.index !== element.index) }) }
        />
    )
}

StationTempRefPanel.propTypes = {
    station: PropTypes.oneOfType([
        PropTypes.instanceOf(DtoProductionUnit),
        PropTypes.instanceOf(DtoDistributionUnit),
        PropTypes.instanceOf(DtoQualitometer),
        PropTypes.instanceOf(DtoPiezometer),
        PropTypes.instanceOf(DtoHydrometricStation),
        PropTypes.instanceOf(DtoInstallation),
    ]),
    sandreCodes: arrayOf(DtoSandreCode),
    readMode: PropTypes.bool,
    onChange: PropTypes.func,
    setPopup: PropTypes.func,
    className: PropTypes.string,
    piezometryDataTypes: arrayOf(DtoParametrageDataType),
    pluviometryDataTypes: arrayOf(DtoParametrageDataType),
    hydrometryDataTypes: arrayOf(DtoParametrageDataType),
    st: PropTypes.string,
}

const mapStateToProps = store => ({
    sandreCodes: store.ReferencialReducer.sandreCodes,
    piezometryDataTypes: store.PiezometryReducer.piezometryDataTypes,
    pluviometryDataTypes: store.PluviometryReducer.pluviometryDataTypes,
    hydrometryDataTypes: store.HydrometryReducer.hydrometryDataTypes,
})

const mapDispatchToProps = {
    setPopup: SieauAction.setPopup,
}

export default connect(mapStateToProps, mapDispatchToProps)(StationTempRefPanel)