import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { isEqualBy } from '../../utils/FormUtils'
import { hasBooleanValue } from '../../utils/NumberUtil'
import { searchAllCharacters } from '../../utils/StringUtil'


class Switch extends Component {
    constructor(props) {
        super(props)
        this.state = {
            selectId: props.id || searchAllCharacters((props.labelOn || props.labelOff) + uuidv4()).replaceAll(' ', '_').replace('numero', '').replace('nom', 'libelle'),
            checked: false,
        }
    }

    componentWillMount() {
        this.setState({ checked: this.props.checked })
    }

    componentWillReceiveProps(nextProps) {
        this.setState({ checked: nextProps.checked })
    }

    check = () => {
        const checked = !this.state.checked
        this.setState({ checked })
        if (this.props.onChange) {
            this.callOnChange(checked)
        }
    }

    callOnChange = (val) => {
        if (this.props.keyObj && this.props.changeObj) {
            this.props.changeObj({ [this.props.keyObj]: val, LAST_FORM: 'SWITCH' })
        }
        if (this.props.onChange) {
            this.props.onChange(val)
        }
    }

    getInput = () => {
        if (this.props.hideNull && !hasBooleanValue(this.props.checked)) {
            return null
        }
        const disabled = { disabled: this.props.disabled }
        return (
            <div className={ `switch ${this.props.componentClassName}` }>
                <label>
                    { this.props.labelOff || null }
                    <input id={ this.state.selectId } type='checkbox'
                        checked={ this.state.checked } onClick={ this.check } {...disabled}
                    />
                    <span className='lever'/>
                    { this.props.labelOn || null }
                </label>
            </div>
        )
    }


    render() {
        if (this.props.display) {
            return this.props.col ? (
                <div className={ `col s${this.props.col}` } >
                    { this.getInput() }
                </div>
            ) : this.getInput()
        }
        return null
    }

    shouldComponentUpdate(nextProps) {
        if (this.props.freezeOpti && isEqualBy(this.props, nextProps, ['checked', 'col', 'disabled', 'readMode'])) {
            return false
        }
        return true
    }
}

Switch.propTypes = {
    className: PropTypes.string,
    componentClassName: PropTypes.string,
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
    labelOn: PropTypes.string,
    labelOff: PropTypes.string,
    checked: PropTypes.bool.isRequired,
    condensed: PropTypes.bool,
    disabled: PropTypes.bool,
    col: PropTypes.number,
    simpleLabelColor: PropTypes.bool,
    display: PropTypes.bool,
    hideNull: PropTypes.bool,
    keyObj: PropTypes.string,
    changeObj: PropTypes.func,
    freezeOpti: PropTypes.bool,
    id: PropTypes.string,
}

Switch.defaultProps = {
    className: '',
    componentClassName: '',
    label: '',
    placeholder: '',
    disabled: false,
    display: true,
    labelOn: '',
    labelOff: '',
}

export default Switch
