import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import { isEqual, maxBy, omit } from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import ToastrAction from 'toastr/actions/ToastrAction'
import WaitAction from 'wait/WaitAction'
import Card from '../../../../../components/card/Card'
import Table from '../../../../../components/datatable/Table'
import Button from '../../../../../components/forms/Button'
import Checkbox from '../../../../../components/forms/Checkbox'
import Input from '../../../../../components/forms/Input'
import NumberField from '../../../../../components/forms/NumberField'
import Select from '../../../../../components/forms/Select'
import Textarea from '../../../../../components/forms/Textarea'
import EventsAction from '../../../../../events/actions/EventsAction'
import MaterielAction from '../../../../../materiel/actions/MaterielAction'
import CentralAction from '../../../../../materiel/components/central/actions/CentralAction'
import CentralPanel from '../../../../../materiel/components/central/components/CentralPanel'
import CentralDto from '../../../../../materiel/components/central/dto/CentralDto'
import CentralTypeDto from '../../../../../materiel/components/central/dto/CentralTypeDto'
import DtoCentralSituation from '../../../../../materiel/components/central/dto/DtoCentralSituation'
import DtoMaterielState from '../../../../../materiel/dto/DtoMaterielState'
import { getCentralSetting } from '../../../../../materiel/utils/MaterielUtils'
import PiezometryAction from '../../../../../piezometry/actions/PiezometryAction'
import DtoParametrageDataType from '../../../../../piezometry/dto/DtoParametrageDataType'
import { getLabel, removeNullKeys } from '../../../../../utils/StoreUtils'
import { getI18nTitleDataLength } from '../../../../../utils/StringUtil'
import StationAction from '../../../../actions/StationAction'
import DtoStation from '../../../../dto/DtoStation'
import DtoStationCentralAssignment from '../../../../dto/materiel/DtoStationCentralAssignment'
import SituationDetailPanel from '../SituationDetailPanel'

class CentralSituationDetail extends Component {
    state = {
        central: {},
        centralChannels: [],
        centralDefaultParam: {},
        centralDefaultchannels: [],
        previousCentralSetting: {},
        previousCentralChannels: [],
        open: false,
        isNewChannel: false,
        currentChannel: {},
        dataTypes: [],
    }

    componentDidMount() {
        const {
            stationCentralAssignments,
            materielStates,
            centrals,
            centralTypes,
            piezometryDataTypes,
        } = this.props
        if (!materielStates.length) {
            this.props.fetchMaterielStates()
        }
        if (!centrals.length) {
            this.props.fetchCentrals()
        }
        if (!centralTypes.length) {
            this.props.fetchCentralTypes()
        }
        if (!piezometryDataTypes.length) {
            this.props.fetchPiezometryDataTypes()
        }
        this.fetchPreviousCentralSetting(stationCentralAssignments)
    }

    componentDidUpdate(prevProps) {
        const {
            stationCentralAssignments,
            piezometryDataTypes,
        } = this.props
        if (stationCentralAssignments.length !== prevProps.stationCentralAssignments.length) {
            this.fetchPreviousCentralSetting(stationCentralAssignments)
        }
        if (piezometryDataTypes.length !== prevProps.piezometryDataTypes.length) {
            this.setState({ dataTypes: [{ id: 0, label: i18n.depth }, ...piezometryDataTypes] })
        }
    }

    fetchPreviousCentralSetting = stationCentralAssignments => {
        const lastSituation = maxBy(stationCentralAssignments, 'situationDate')
        if (lastSituation) {
            const lastMaterielId = lastSituation.idCentral
            this.props.fetchCentral(lastMaterielId).then(json => {
                this.props.fetchCentralChannels(json.id).then(channels =>
                    this.setState({ previousCentralChannels: channels }))
                this.setState({ previousCentralSetting: getCentralSetting(json) })
            })
        }
    }

    getCentralTitle = ({ serialNumber, reference }, { label }) => {
        const code = serialNumber || reference || ''
        return label ? `${label} - ${code}` : code || i18n.unknownMaterial
    }

    setCentralTypeParam = () => {
        const { centralType } = this.state.central
        const type = this.props.centralTypes.find(t => t.id === centralType) || {}
        this.setState({
            central: {
                ...this.state.central,
                ...omit(type, ['id', 'code', 'label', 'name', 'comment', 'updateDate', 'updateLogin', 'startDate', 'endDate', 'icon', 'headers']),
            },
        })
    }

    getRemoteTranmissionIcon = teletransmission => {
        switch (teletransmission) {
            case '1':
                return 'wifi_tethering'
            default:
                return 'portable_wifi_off'
        }
    }

    getCentralPanel = isEditMode => {
        const { central } = this.state
        if (central.id) {
            const centralChannels = this.state.centralChannels.map(c => ({
                channelId: c.channelId,
                pathNumber: c.channelId === 0 ? `${c.channelId}` : c.channelId,
                assignment: c.assignment,
                comment: c.comment,
                dataType: getLabel(this.state.dataTypes, c.dataType, null, 'id'),
                remoteTransmission: (
                    <i className='material-icons'>{this.getRemoteTranmissionIcon(c.remoteTransmission)}</i>
                ),
            }))
            const centralType = this.props.centralTypes.find(t => t.id === central.centralType) || {}
            const actions = [
                {
                    iconName: 'note_add',
                    onClick: () => this.setState({ currentChannel: { centralId: central.id }, open: true, isNewChannel: true }),
                },
            ]
            return (
                <div>
                    <div className='col s8 offset-s2'>
                        <div className='col s4'>
                            <a className='col s12 btn' onClick={() => this.setState({
                                central: {
                                    ...this.state.centralDefaultParam,
                                },
                                centralChannels: [
                                    ...this.state.centralDefaultchannels,
                                ],
                            })}
                            >
                                {i18n.defaultSettings}
                            </a>
                        </div>
                        <div className='col s4'>
                            <a className='col s12 btn' onClick={this.setCentralTypeParam}>
                                {i18n.typeSettings}
                            </a>
                        </div>
                        <div className='col s4'>
                            <a className={`col s12 btn ${this.props.stationCentralAssignments.length ? '' : 'disabled'}`} onClick={() => this.setState({
                                central: {
                                    ...central,
                                    ...this.state.previousCentralSetting,
                                },
                                centralChannels: [
                                    ...this.state.previousCentralChannels,
                                ],
                            })}
                            >
                                {i18n.previousSettings}
                            </a>
                        </div>
                    </div>
                    <div className='row col s10 offset-s1'>
                        <div className='card no-padding'>
                            <div className='col s12 card-title active'>
                                {this.getCentralTitle(central, centralType)}
                            </div>
                            <div className={'card-content margin-top-1'}>
                                <div className='row no-margin'>
                                    <CentralPanel id={central.id}
                                        central={central}
                                        onChange={changes => this.setState({ central: { ...this.state.central, ...changes } })}
                                        disabled={!isEditMode}
                                    />
                                </div>
                                <div className='row no-margin padding-top-3-px'>
                                    <div className='row margin-top-2'>
                                        <div className='col s12'>
                                            <Card title={getI18nTitleDataLength(i18n.path, i18n.paths, centralChannels.length)}
                                                active={isEditMode} actions={actions}
                                            >
                                                <Table
                                                    showTitle={false}
                                                    data={centralChannels}
                                                    type={{ headers: ['pathNumber', 'dataType', 'assignment', 'comment', 'remoteTransmission'] }}
                                                    condensed
                                                    sortable
                                                    alterable
                                                    onAlter={channel =>
                                                        this.setState({ currentChannel: this.state.centralChannels.find(c => c.channelId === channel.channelId) || {}, open: true, isNewChannel: false })
                                                    }
                                                    deletable
                                                    onDelete={channel =>
                                                        this.setState({ centralChannels: this.state.centralChannels.filter(c => c.channelId !== channel.channelId) })
                                                    }
                                                />
                                            </Card>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        return null
    }

    fetchCentral = id => {
        this.props.waitStart()
        this.props.fetchCentral(id).then(json => {
            this.props.fetchCentralChannels(json.id).then(channels => {
                this.setState({
                    centralChannels: channels,
                    centralDefaultchannels: channels,
                    central: json,
                    centralDefaultParam: json,
                })
                this.props.waitStop()
            })
        })
    }

    updateCentral = () => {
        if (!isEqual(this.state.central, this.state.centralDefaultParam)) {
            this.props.updateCentral(this.state.central)
        }
        if (!isEqual(this.state.centralChannels, this.state.centralDefaultchannels)) {
            this.props.updateAllCentralChannel(this.state.central.id, this.state.centralChannels)
        }
    }

    resetCentral = () => {
        this.props.resetCentral()
        this.setState({ central: {}, centralDefaultParam: {} })
    }

    handleClose = () => {
        const { centralChannels, currentChannel } = this.state
        if (currentChannel.channelId || currentChannel.channelId === 0) {
            this.setState({
                open: false,
                centralChannels: [...centralChannels.filter(c => c.channelId !== currentChannel.channelId), currentChannel],
                currentChannel: {},
            })
        } else {
            this.props.toastrError(i18n.selectChannel)
        }
    }

    onChangeChannel = changes =>
        this.setState({ currentChannel: removeNullKeys({ ...this.state.currentChannel, ...changes }) })

    render = () => {
        const {
            currentChannel,
            open,
            isNewChannel,
            dataTypes,
        } = this.state
        const {
            station,
            centrals,
            centralsLastSituations,
            centralTypes,
            stationCentralAssignments,
            addCentralSituation,
            saveCentralSituation,
            deleteCentralSituation,
            params,
            type,
        } = this.props

        return (
            <div>
                <SituationDetailPanel
                    params={params}
                    type={type}
                    getMaterielPanel={isEditMode => this.getCentralPanel(isEditMode)}
                    station={station}
                    materiels={centrals}
                    lastSituations={centralsLastSituations}
                    materielTypes={centralTypes}
                    stationMaterielAssignments={stationCentralAssignments}
                    addSituation={addCentralSituation}
                    saveSituation={saveCentralSituation}
                    deleteSituation={deleteCentralSituation}
                    fetchMateriel={this.fetchCentral}
                    updateMateriel={this.updateCentral}
                    resetMateriel={this.resetCentral}
                    keyMaterielType='centralType'
                    keyMaterielId='idCentral'
                />
                <Dialog
                    onClose={() => this.setState({ currentChannel: {}, open: false })}
                    fullWidth
                    maxWidth='lg'
                    open={open}
                >
                    <DialogTitle>{i18n.channel}</DialogTitle>
                    <DialogContent>
                        <div className='row no-margin'>
                            <NumberField col={2} title={i18n.channel} value={currentChannel.channelId} onChange={v => this.onChangeChannel({ channelId: v })} disabled={!isNewChannel} />
                            <Input col={5} title={i18n.name} value={currentChannel.name} onChange={v => this.onChangeChannel({ name: v })} />
                            <Select col={5} value={currentChannel.dataType} options={dataTypes} label={i18n.dataType} onChange={v => this.onChangeChannel({ dataType: v })} integerValue />
                        </div>
                        <div className='row no-margin valign-wrapper'>
                            <Textarea col={6} title={i18n.comment} value={currentChannel.comment} onChange={v => this.onChangeChannel({ comment: v })} />
                            <NumberField col={3} title={i18n.periodicity} value={currentChannel.periodicity} onChange={v => this.onChangeChannel({ periodicity: v })} />
                            <Checkbox col={3} checked={currentChannel.remoteTransmission === '1'} label={i18n.remoteTransmission} onChange={v => this.onChangeChannel({ remoteTransmission: v ? '1' : '0' })} />
                        </div>
                        <div className='row no-margin valign-wrapper padding-top-1'>
                            <Input col={6} title={i18n.assignment} value={currentChannel.assignment} onChange={v => this.onChangeChannel({ assignment: v })} />
                            <Checkbox col={6} checked={currentChannel.register === '1'} label={i18n.oneRegister} onChange={v => this.onChangeChannel({ register: v ? '1' : '0' })} />
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.setState({ currentChannel: {}, open: false })} variant='outlined' >
                            {i18n.cancel}
                        </Button>
                        <Button onClick={this.handleClose} variant='contained' color='primary'>
                            {i18n.validate}
                        </Button>
                    </DialogActions>
                </Dialog>

            </div>
        )
    }
}

CentralSituationDetail.propTypes = {
    params: PropTypes.shape({
        id: PropTypes.string,
        materialType: PropTypes.string,
    }),
    type: PropTypes.string,
    station: PropTypes.instanceOf(DtoStation),

    stationCentralAssignments: PropTypes.arrayOf(PropTypes.instanceOf(DtoStationCentralAssignment)),
    materielStates: PropTypes.arrayOf(PropTypes.instanceOf(DtoMaterielState)),
    central: PropTypes.instanceOf(CentralDto),
    centrals: PropTypes.arrayOf(PropTypes.instanceOf(CentralDto)),
    centralsLastSituations: PropTypes.arrayOf(PropTypes.instanceOf(DtoCentralSituation)),
    centralTypes: PropTypes.arrayOf(PropTypes.instanceOf(CentralTypeDto)),
    piezometryDataTypes: PropTypes.arrayOf(DtoParametrageDataType),

    resetCentral: PropTypes.func,
    updateCentral: PropTypes.func,
    fetchCentral: PropTypes.func,
    fetchCentrals: PropTypes.func,
    fetchMaterielStates: PropTypes.func,
    fetchCentralTypes: PropTypes.func,
    saveCentralSituation: PropTypes.func,
    addCentralSituation: PropTypes.func,
    deleteCentralSituation: PropTypes.func,
    fetchCentralChannels: PropTypes.func,
    updateAllCentralChannel: PropTypes.func,
    fetchPiezometryDataTypes: PropTypes.func,
    toastrError: PropTypes.func,
    waitStart: PropTypes.func,
    waitStop: PropTypes.func,
}

const mapStateToProps = store => ({
    stationCentralAssignments: store.StationReducer.stationCentralAssignments,
    materielStates: store.MaterielReducer.materielStates,
    central: store.CentralReducer.central,
    centrals: store.CentralReducer.centrals,
    centralsLastSituations: store.CentralReducer.centralsLastSituations,
    centralTypes: store.CentralReducer.centralTypes,
    piezometryDataTypes: store.PiezometryReducer.piezometryDataTypes,
})

const mapDispatchToProps = {
    resetCentral: CentralAction.resetCentral,
    updateCentral: CentralAction.updateCentral,
    fetchCentral: CentralAction.fetchCentral,
    fetchCentrals: CentralAction.fetchCentrals,
    fetchMaterielStates: MaterielAction.fetchMaterielStates,
    fetchCentralTypes: CentralAction.fetchCentralTypes,
    saveCentralSituation: StationAction.saveCentralSituation,
    addCentralSituation: StationAction.addCentralSituation,
    deleteCentralSituation: StationAction.deleteCentralSituation,
    fetchCentralChannels: CentralAction.fetchCentralChannels,
    updateAllCentralChannel: CentralAction.updateAllCentralChannel,
    fetchPiezometryDataTypes: PiezometryAction.fetchPiezometryDataTypes,
    addEvent: EventsAction.addEvent,
    toastrError: ToastrAction.error,
    waitStart: WaitAction.waitStart,
    waitStop: WaitAction.waitStop,
}

export default connect(mapStateToProps, mapDispatchToProps)(CentralSituationDetail)
