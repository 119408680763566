/* eslint-disable max-nested-callbacks */
import ReactECharts from 'echarts-for-react'
import echarts from 'echarts/lib/echarts'
import MessageCard from 'components/card/MessageCard'
import ProgressCard from 'components/card/ProgressCard'
import moment from 'moment'
import PluviometryAction from 'pluviometry/actions/PluviometryAction'
import PluviometerDto from 'pluviometry/dto/PluviometerDto'
import PropTypes from 'prop-types'
import React, { useEffect, useRef, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import { max, maxBy, min, minBy, orderBy, round, uniq, uniqBy } from 'lodash'
import { exportExcelIcon, exportPictureIcon, fullScreenIcon, getAxisIntervalFormatter } from 'components/echart/EChartUtils'
import { getDate } from 'utils/DateUtil'
import DtoPluvioMeasures from 'pluviometry/dto/measures/DtoPluvioMeasures'
import { exportFile } from 'utils/ExportDataUtil'
import { PluvioCumulOptionIcon } from 'pluviometry/components/pluviometryComponents/PluvioOption'
import SimpleChartTabs from 'components/echart/SimpleChartTabs'
import { Grid } from '@mui/material'
import { promiseAllProgress } from 'utils/ActionUtils'
import useBoolean from 'utils/customHook/useBoolean'
import useUpdateEffect from 'utils/customHook/useUpdateEffect'
import { AccordionDetailsMUI, AccordionMUI, AccordionSummaryMUI } from 'components/styled/Accordions'

const PluviometryGraph = ({
    dataMeasures = [],
    startDate,
    endDate,
    toggleFullScreen = () => {},
}) => {
    const {
        pluviometers,
    } = useSelector(store => ({
        pluviometers: store.PluviometryReducer.pluviometers,
    }), shallowEqual)

    const filteredDataMeasures = dataMeasures.filter(d => !!d)

    const echartRef = useRef()
    const displayToolbox = useRef(false)

    const defaultTimestamp = moment().valueOf()
    const chartMaxDate = moment(endDate || max(filteredDataMeasures.map(data => maxBy(data.measures, 'date')?.date)) || defaultTimestamp)
    const chartMinDate = moment(startDate || min(filteredDataMeasures.map(data => minBy(data.measures, 'date')?.date)) || defaultTimestamp)

    const {
        formatter,
        interval,
    } = getAxisIntervalFormatter(chartMaxDate, chartMinDate)

    const series = filteredDataMeasures.map(data => {
        const dataOrdered = orderBy(data.measures, 'date').map(({ date, value }) => ({
            value: [date, value],
        }))
        const pluvio = pluviometers.find(({ id }) => id === data.stationId)
        return {
            type: 'bar',
            data: dataOrdered,
            name: pluvio?.name || data.stationId,
            barMaxWidth: '50px',
        }
    })

    const getLegend = () => ({
        top: '5%',
        left: '2%',
        right: displayToolbox.current ? `${30 * 4 + 35}px` : '2%', // 30 * nbTool + 35
        type: 'scroll',
    })

    const getToolbox = () => ({
        top: '4%',
        right: '35px',
        showTitle: false,
        itemSize: 18,
        tooltip: {
            show: true,
            position: 'bottom',
        },
        feature: {
            saveAsImage: {
                show: displayToolbox.current,
                title: i18n.pictureExport,
                icon: exportPictureIcon,
                name: i18n.dataPluvio,
            },
            myToolExport: {
                show: displayToolbox.current,
                title: i18n.excelExport,
                icon: exportExcelIcon,
                onclick: () => {
                    const exportData = filteredDataMeasures.flatMap(({ measures, stationId }) => {
                        const pluvio = pluviometers.find(({ id }) => id === stationId)
                        return measures.map(m => ({
                            date: { value: getDate(m.date), format: 'dd/MM/yyyy', cellType: 'date' },
                            value: { value: m.value, format: '0.00000', cellType: 'number' },
                            name: pluvio?.name || stationId,
                        }))
                    })
                    exportFile({
                        data: exportData.length ? [
                            {
                                ...exportData[0],
                                headers: ['name', 'date', 'value'],
                            },
                            ...exportData.slice(1),
                        ] : [],
                        exportType: 'xlsx',
                        titleFile: i18n.dataPluvio,
                    }, true)
                },
            },
            restore: {
                show: displayToolbox.current,
                title: i18n.restore,
            },
            myToolFullScreen: {
                show: displayToolbox.current,
                title: i18n.fullScreen,
                icon: fullScreenIcon,
                onclick: toggleFullScreen,
            },
        },
    })

    const options = {
        series,
        legend: getLegend(),
        xAxis: [{
            type: 'time',
            boundaryGap: true,
            showSplitLine: true,
            axisLabel: {
                formatter,
                rotate: 50,
            },
            interval,
            min: chartMinDate.valueOf(),
            max: chartMaxDate.valueOf(),
        }],
        yAxis: {
            type: 'value',
            nameLocation: 'center',
            nameGap: 40,
            name: `${i18n.rain} (mm)`,
        },
        tooltip: {
            show: true,
            trigger: 'axis',
            axisPointer: {
                type: 'shadow',
                snap: true,
            },
            formatter: params => {
                return getDate(params[0].axisValue) + params.map(({ marker, seriesName, value: [, result], data: { unit = '' } }) => `<br/>${marker} ${seriesName}: ${result && round(result, 3)} ${unit}`).join('')
            },
        },
        grid: {
            top: '50px',
            bottom: '80px',
            left: '30px',
            right: '20px',
            containLabel: true,
            height: 250,
        },
        toolbox: getToolbox(),
        dataZoom: [{
            bottom: '10px',
            handleSize: '80%',
            filterMode: 'none',
            xAxisIndex: 0,
            handleStyle: {
                color: '#fff',
                shadowBlur: 3,
                shadowColor: 'rgba(0, 0, 0, 0.6)',
                shadowOffsetX: 2,
                shadowOffsetY: 2,
            },
        }],
    }
    return (
        <div
            onMouseOver={() => {
                displayToolbox.current = true
                echartRef.current.getEchartsInstance().setOption({
                    legend: getLegend(),
                    toolbox: getToolbox(),
                })
            }}
            onMouseOut={() => {
                displayToolbox.current = false
                echartRef.current.getEchartsInstance().setOption({
                    legend: getLegend(),
                    toolbox: getToolbox(),
                })
            }}
        >
            <ReactECharts
                echarts={echarts}
                option={options}
                notMerge={true}
                lazyUpdate={true}
                className={'row no-margin'}
                style={{ height: 350 }}
                ref={e => {
                    echartRef.current = e
                }}
            />
        </div>
    )
}

PluviometryGraph.propTypes = {
    dataMeasures: PropTypes.arrayOf(PropTypes.instanceOf(DtoPluvioMeasures)),
    startDate: PropTypes.number,
    endDate: PropTypes.number,
    toggleFullScreen: PropTypes.func,
}

const UnitPluviometryPanel = ({
    pluviometersLinked = [],
    expanded = false,
    setExpanded = () => { },
}) => {
    const dispatch = useDispatch()

    const {
        value: isFullScreen,
        toggle: toggleFullScreen,
    } = useBoolean(false)

    // const [station, setStation] = useState(pluviometersLinked[0]?.id)

    const [group, setGroup] = useState('SUM_AUTO')
    const [minDate, setMinDate] = useState(undefined)
    const [maxDate, setMaxDate] = useState(moment().valueOf())

    const [measures, setMeasures] = useState([])
    const {
        value: isLoaded,
        setTrue: loaded,
        setFalse: notLoaded,
    } = useBoolean()
    const [progress, setProgress] = useState(0)

    useEffect(() => {
        dispatch(PluviometryAction.fetchPluviometers())
    }, [dispatch])

    useUpdateEffect(() => {
        setMeasures([])
        notLoaded()
        const ids = uniq(pluviometersLinked.map(p => p.id))

        const defaultInput = {
            dataType: 1,
            groupFunc: group,
            chartMode: true,
            startDate: minDate,
            endDate: maxDate,
        }
        promiseAllProgress(ids.map(id => {
            return dispatch(PluviometryAction.getPluvioChronicMeasures({ ...defaultInput, stationId: id }))
                .then(data => setMeasures(prev => uniqBy([...prev, data], 'stationId')))
        }), setProgress).finally(loaded)
    }, [group, minDate, maxDate])

    // const filteredMeasure = measures.filter(m => m.stationId === station)

    return (
        <AccordionMUI round style={{ marginTop: 10, marginBottom: 10 }} expanded={expanded} onChange={setExpanded}>
            <AccordionSummaryMUI round iconColor='black' style={{ background: 'white', color: 'black' }}>{i18n.pluviometry}</AccordionSummaryMUI>
            <AccordionDetailsMUI>
                <div className={isFullScreen ? 'fullscreen-chart' : ''}>
                    <Grid container columnSpacing={2} alignItems='center' sx={{ padding: '8 5 0 0' }}>
                        <Grid item xs='auto'>
                            <div style={{ paddingLeft: '48px' }}>
                                <SimpleChartTabs
                                    onChange={(start, end) => {
                                        setMinDate(start)
                                        setMaxDate(end)
                                    }}
                                    disabled={!isLoaded}
                                />
                            </div>
                        </Grid>
                        <Grid item xs='auto'>
                            <PluvioCumulOptionIcon
                                defaultGroupType={group}
                                onChangeGroupType={setGroup}
                                disabled={!isLoaded}
                            />
                        </Grid>
                        {/* <Grid item xs={4}>
                            <Select
                                label={i18n.monitoringPoints}
                                value={station}
                                options={pluviometersLinked}
                                onChange={setStation}
                                keyValue={'id'}
                            />
                        </Grid> */}
                        <Grid item xs={12}>
                            {!isLoaded && (
                                <div style={{ marginTop: 20 }}>
                                    <ProgressCard
                                        progress={progress}
                                    />
                                </div>
                            )}
                            {(measures.length === 0) && (
                                <div style={{ marginTop: 15 }}>
                                    <MessageCard>{i18n.noDataToDisplay}</MessageCard>
                                </div>
                            )}
                            {(isLoaded && !(measures.length === 0)) && (
                                <Grid item xs={12}>
                                    <PluviometryGraph
                                        dataMeasures={measures}
                                        startDate={minDate}
                                        endDate={maxDate}
                                        toggleFullScreen={toggleFullScreen}
                                    />
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                </div>
            </AccordionDetailsMUI>
        </AccordionMUI>
    )
}

UnitPluviometryPanel.propTypes = {
    pluviometersLinked: PropTypes.arrayOf(PropTypes.instanceOf(PluviometerDto)).isRequired,
    expanded: PropTypes.bool,
    setExpanded: PropTypes.func,
}

export default UnitPluviometryPanel
export {
    PluviometryGraph,
}