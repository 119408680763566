import { FormControlLabel, Checkbox as CheckboxMui, Typography, Tooltip } from '@mui/material'
import React from 'react'
import PropTypes from 'prop-types'
import { disabledColor } from 'utils/constants/ColorTheme'
import { isNil } from 'lodash'

const SimpleCheckbox = ({
    checked = false,
    onToggle = () => {},
    label = '',
    labelStyle = {},
    tooltip,
    disabled,
    'data-cy': dataCy,
}) => {
    const checkbox = (
        <FormControlLabel
            data-cy={dataCy}
            control={(
                <CheckboxMui
                    checked={checked}
                    color='third'
                    onChange={onToggle}
                    inputProps={{ 'aria-label': 'controlled' }}
                    sx={{
                        '& .MuiSvgIcon-root': {
                            fontSize: '2rem',
                        },
                    }}
                    disabled={disabled}
                />
            )}
            label={(
                <Typography
                    sx={{
                        color: disabled ? `${disabledColor} !important` : '#161832 !important',
                        fontWeight: 'bold',
                        fontSize: '1rem',
                        ...labelStyle,
                    }}
                >
                    {label}
                </Typography>
            )}
            sx={{
                '& .MuiButtonBase-root': {
                    padding: '0 9px',
                },
            }}
        />
    )
    return isNil(tooltip) ? checkbox : <Tooltip title={tooltip}>{checkbox}</Tooltip>
}

SimpleCheckbox.propTypes = {
    checked: PropTypes.bool,
    onToggle: PropTypes.func,
    label: PropTypes.string,
    labelStyle: PropTypes.shape({}),
    tooltip: PropTypes.string,
    disabled: PropTypes.bool,
    'data-cy': PropTypes.string,
}

export default SimpleCheckbox
