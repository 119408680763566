import React from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import ActionComponent from '../../../../components/ActionComponent'
import { getMapStateToProps, getPropTypes } from '../../../../utils/StoreUtils'
import PropTypes from 'prop-types'
import Table from '../../../../components/datatable/Table'
import { getDate } from '../../../../utils/DateUtil'
import Card from '../../../../components/card/Card'
import { getSandreLabel, substringText } from '../../../../utils/StringUtil'
import { SANDRE } from '../../../../referencial/constants/ReferencialConstants'
import AppStore from '../../../../store/AppStore'
import SieauAction from '../../../../components/sieau/SieauAction'
import { v4 as uuidv4 } from 'uuid'
import InstallationArrangementsModal from './InstallationArrangementsModal'


const storeProps = {
    installation: false,
    sandreCodes: false,
}

const tableHeaders = { headers: ['startDate', 'endDate', 'descriptive', 'value', 'type'] }

class InstallationArrangementsTable extends ActionComponent {
    setArrangementsModal = () => {
        const id = uuidv4()

        const buttons = (
            <div>
                <a className='waves-effect waves-teal btn-flat modal-close'>{ i18n.cancel }</a>
                <a id='saveArrangements' className='waves-effect waves-teal btn-flat'>{ i18n.register }</a>
            </div>
        )
        const popup = {
            id,
            header: i18n.editArrangements,
            actions: buttons,
            fixedFooter: true,
            dismissible: false,
            className: 'hugeModal hightHeightModal',
            content: <InstallationArrangementsModal tableHeaders={ tableHeaders } />,
        }
        AppStore.dispatch(SieauAction.setPopup(popup))
    }

    getTable = (arrangements) => {
        if (arrangements && arrangements.length) {
            const data = arrangements.map(a => Object.assign({}, a, {
                startDate: getDate(a.startDate),
                endDate: getDate(a.endDate),
                type: getSandreLabel(this.props.sandreCodes, SANDRE.ARRANGEMENT_TYPES, a.arrangementType),
                descriptive: substringText(a.descriptive),
            }))
            return (
                <Table data={ data } sortable showTitle={ false } condensed
                    type={ tableHeaders }
                />
            )
        }
        return null
    }

    getSummary = (arrangements) => {
        if (arrangements && arrangements.length) {
            const space = <span className='padding-left-3'/>
            const data = arrangements.map(a => (<h6>{ getDate(a.startDate) } { space }
                { getSandreLabel(this.props.sandreCodes, SANDRE.ARRANGEMENT_TYPES, a.arrangementType) } { space } { a.descriptive }</h6>))
            return (
                <Card title={ i18n.arrangements } smallCard>
                    <div className='row no-margin padding-top-1 padding-left-1'>
                        { data }
                    </div>
                </Card>
            )
        }
        return null
    }

    render() {
        if (this.props.summarized) {
            return this.getSummary(this.props.installation.arrangements)
        }
        const actions = this.props.editMode ? [{
            iconName: 'edit',
            onClick: () => this.setArrangementsModal(),
        }] : []
        return (
            <Card title={ i18n.arrangements } actions={ actions } smallCard>
                { this.getTable(this.props.installation.arrangements) }
            </Card>
        )
    }
}

InstallationArrangementsTable.propTypes = getPropTypes(storeProps, {
    editMode: PropTypes.bool,
    summarized: PropTypes.bool,
})

const mapStateToProps = () => getMapStateToProps(storeProps)

export default connect(mapStateToProps)(InstallationArrangementsTable)