import ReferencialDto from '../../../dto/ReferencialDto'

export default class HydrogeologicalEntityItemDto extends ReferencialDto {
    constructor(object) {
        super(object)
        this.id = object.code
        this.denomination = object.denomination
        this.status = object.status
        this.headers = ['code', 'name', 'denomination', 'status']
    }
}