import { aqua6BOServicesPath, aqua6BODataPath, aquaDBPath, geoApiPath, iaeauApi, postgrestPath, sandrePath } from './SieauConstants'
import { contentsPath, path } from './basepath'
import { hasValue } from '../utils/NumberUtil'
import { isNil } from 'lodash'
import { toQueryParam } from 'utils/StringUtil'

// const localApi = 'http://localhost:8000/'

export default {
    log: {
        error: () => `${path}log/error`,
        info: () => `${path}log/info`,
        debug: () => `${path}log/debug`,
    },
    superAdmin: {
        select: () => `${path}selectSql`,
        exec: () => `${path}execSql`,
        get: () => `${path}getSql`,
        getLogs: () => `${path}journal/get`,
    },
    job: {
        jobPath() {
            return `${path}job/`
        },
        getAll(lightMode) {
            return this.jobPath() + (lightMode ? '?lightMode=true' : '')
        },
        getJobsForArchiving() {
            return `${path}job/archiving`
        },
        getFull: (id = '') => `${path}job/full/${id}`,
        log: (id, execId, limit) => `${path}job/${id}/execution/${execId}/log${hasValue(limit) ? `?limit=${limit}` : ''}`,
        get(id) {
            return this.jobPath() + id
        },
        getExecutions(jobId, limit) {
            return `${this.jobPath() + jobId}/execution${hasValue(limit) ? `?limit=${limit}` : ''}`
        },
        execute: id => `${path}job/${id}/execute`,
        executeManually() {
            return `${path}job/` + 'execute/manually'
        },
        abortExecution(jobId, jobExecutionId) {
            return `${this.jobPath() + jobId}/execution/${jobExecutionId}/abort`
        },
        getJobByJobExecutionId(id) {
            return `${path}job/fetchJobWithExecutionId/${id}`
        },
        add() {
            return this.jobPath()
        },
        update(id) {
            return this.jobPath() + id
        },
        delete(id) {
            return this.jobPath() + id
        },
        export(id) {
            return `${this.jobPath() + id}/export`
        },
        exportHistory() {
            return `${this.jobPath()}qesout/history`
        },
        exportOverview: () => `${path}job/exportOverview`,
        logFind: (jobExecutionId, stationCode) => `${path}job/log/find/${jobExecutionId}/${stationCode}`,
        qualityJobs: () => `${path}job/quality`,
        qualityExecutions: () => `${path}job/execution/quality`,
        jobTable() {
            return `${this.jobPath()}table`
        },
        hasStation: () => `${path}job/hasStation`,
        csvParamsImport: () => `${path}job/csv/import`,
        csvParamsExport: () => `${path}job/csv/export`,
        jobChain: (jobId) => `${path}job/chain/${jobId}`,
        updateJobChain: () => `${path}job/chain`,
        fileToProcess: (jobId, executionId) => `${path}job/${jobId}/execution/${executionId}/file/toProcess`,
    },
    integration: {
        edilabo: () => `${path}job/-7/execute`, // job system créé par le import engine et non modifiable par les utilisateurs`,
        ddass: () => `${path}job/-8/execute`, // job system créé par le import engine et non modifiable par les utilisateurs`,
        quesu: () => `${path}job/-9/execute`, // job system créé par le import engine et non modifiable par les utilisateurs`,
        qesout: () => `${path}job/-10/execute`, // job system créé par le import engine et non modifiable par les utilisateurs`,
        xsdValidation: () => `${path}integration/validationxsd`,
    },
    user: {
        userPath() {
            return `${path}user/`
        },
        get(userId) {
            return this.userPath() + userId
        },
        applicativeUsers() {
            return `${this.userPath()}applicative`
        },
        usersLight() {
            return `${this.userPath()}light`
        },
        delete(login) {
            return this.userPath() + login
        },
        getKeyFigures(login) {
            return `${this.userPath()}keyfigures/${login}`
        },
        getStatistics(login) {
            return `${this.userPath()}statistic/${login}`
        },
        getStationStatistics(login) {
            return `${this.userPath()}statistic/station/${login}`
        },
        getCmsStatistics(login) {
            return `${this.userPath()}statistic/cms/${login}`
        },
        getUsageStatistics(login) {
            return `${this.userPath()}statistic/usage/${login}`
        },
        userStations(login) {
            return `${this.userPath()}station/${login}`
        },
        put() {
            return this.userPath()
        },
        usersOfFilter(moduleFilter, filterCode) {
            return `${this.userPath()}listRight?module=${moduleFilter}&code=${filterCode}`
        },
        filters() {
            return `${this.userPath()}filters/station`
        },
        filterResults() {
            return `${this.userPath()}filters/station/execute`
        },
        filterResultsTmp() {
            return `${this.userPath()}filters/station/execute/tmp`
        },
        filterParamRequest() {
            return `${this.userPath()}filters/station/request`
        },
        getBookmarks() {
            return `${this.userPath()}bookmarks`
        },
        setBookmark() {
            return `${this.userPath()}bookmark`
        },
        settings() {
            return `${this.userPath()}parameters`
        },
        applicationSettings() {
            return `${this.userPath()}parameter/sieau`
        },
        applicationSetting(parameter) {
            return `${this.userPath()}parameter/sieau/${parameter}`
        },
        saveSettings(login) {
            return `${this.userPath()}parameters/${login}`
        },
        setting() {
            return `${this.userPath()}parameter`
        },
        habilitations(login = '') {
            if (login) {
                return `${this.userPath()}habilitations/${login}`
            }
            return `${this.userPath()}habilitations`
        },
        themeAssign() {
            return `${this.userPath()}theme`
        },
        assignedThemesLayers() {
            return `${this.userPath()}theme/all`
        },
        applicationHabilitations() {
            return `${this.userPath()}applicationHabilitations`
        },
        sieauParameters(parameter) {
            return `${this.setting()}/sieau/${parameter}`
        },
        userView() {
            return `${this.userPath()}view/`
        },
        genericUserView() {
            return `${this.userPath()}view/generic/`
        },
        changePassword() {
            return `${this.userPath()}password`
        },
        changePasswordByAdmin(login) {
            return `${this.userPath()}password/${login}`
        },
        getAllStatistics() {
            return `${this.userPath()}statistic`
        },
        getAllMobileNotifications() {
            return `${this.userPath()}mobileNotifications`
        },
        sendNotif() {
            return `${this.userPath()}sendNotif`
        },
        getAllCmsStatistics() {
            return `${this.userPath()}statistic/cms`
        },
        getAllUsageStatistics() {
            return `${this.userPath()}statistic/usage`
        },
        getAllStationStatistics() {
            return `${this.userPath()}statistic/station`
        },
        duplicateUser(login) {
            return `${this.userPath()}duplicate/${login}`
        },
        userStationHasRights(stationType, code) {
            return `${this.userPath()}rights/${stationType}/${code}`
        },
    },
    login() {
        return `${path}login`
    },
    logout() {
        return `${path}logout`
    },
    resetPassword() {
        return `${path}reset`
    },
    mailSupport() {
        return `${path}mail/support`
    },
    sensSms(smsApi) {
        return `${aqua6BOServicesPath}/sms?api=${smsApi}`
    },
    qualitometer: {
        qualitometerPath() {
            return `${path}qualitometer/`
        },
        qualitometerParameter(parameter) {
            return `${path}qualitometer?parameters=${parameter}`
        },
        getByJob: () => `${path}qualitometer/job`,
        getAll() {
            return this.qualitometerPath()
        },
        listSpecific() {
            return `${this.qualitometerPath()}listSpecific`
        },
        get(id) {
            return `${path}qualitometer/${id}`
        },
        getWaterMass(id) {
            return `${this.qualitometerPath() + id}/watermass`
        },
        getStationProducers: (id, bss) => `${path}qualitometer/${id}/${bss}/producers`,
        networkLinks: () => `${path}qualitometer/networkLink`,
        networkLink: id => `${path}qualitometer/${id}/networkLink`,
        getNetworks(id) {
            return `${this.qualitometerPath() + id}/network`
        },
        getContributors(id) {
            return `${this.qualitometerPath() + id}/contributor`
        },
        updateContributors(id) {
            return `${path}station/qualitometer/${id}/contributor`
        },
        getLocations(id) {
            return `${this.qualitometerPath() + id}/location`
        },
        operations: id => `${path}qualitometer/${id}/operation`,
        groupOperations: (ids = []) => `${path}operation/${ids.length ? `?ids=${ids.join(',')}` : ''}`,
        getOperations: id => `${path}qualitometer/${id}/operation`,
        getOperationsListSpecific: id => `${path}operation/${id}/listSpecific`,
        getOperationFilesWithRefs: () => `${path}operation/files`,
        hydrobioOperations: id => `${path}qualitometer/${id}/hydrobioOperation`,
        qualitometerHydrobioList: idStation => `${path}qualitometer/${idStation}/hydrobioOperation/list`,
        hydrobioOperation: (idStation, idOperation) => `${path}qualitometer/${idStation}/hydrobioOperation/${idOperation}`,
        hydrobioLists: (idStation, idOperation) => `${path}qualitometer/${idStation}/hydrobioOperation/${idOperation}/list`,
        hydrobioList: (idStation, idOperation, idList) => `${path}qualitometer/${idStation}/hydrobioOperation/${idOperation}/list/${idList}`,
        allHydrobioList: (idStation, idOperation) => `${path}qualitometer/${idStation}/hydrobioOperation/${idOperation}/allList`,
        environmentalConditions: (idQualitometer, idOperation) => `${path}qualitometer/${idQualitometer}/operation/${idOperation}/environmentalCondition`,
        environmentalCondition: (idQualitometer, idOperation, idCondition) => `${path}qualitometer/${idQualitometer}/operation/${idOperation}/environmentalCondition/${idCondition}`,
        getSample(qualitometerId, sampleId) {
            return `${this.qualitometerPath() + qualitometerId}/sample/${sampleId}`
        },
        getSamples: id => `${path}qualitometer/${id}/sample`,
        getAllEvents() {
            return `${this.qualitometerPath()}allEvents`
        },
        events(id) {
            return `${path}qualitometer/${id}/event`
        },
        event(qualitometerId, eventId) {
            return `${path}qualitometer/${qualitometerId}/event/${eventId}`
        },
        eventPostFacebook(qualitometerId, eventId) {
            return `${path}qualitometer/${qualitometerId}/event/${eventId}/facebook`
        },
        calculateParameter: (idQualito, idOperations) => `${path}qualitometer/${idQualito}/calculate/parameter?idOperations=${idOperations}`,
        calculateSEEE: (idQualito, idOperations = [], listIndexes = []) => `${path}qualitometer/${idQualito}/calculate/SEEE?${toQueryParam({ listIndexes, idOperations })}`,
        allResearchCriterias() {
            return `${path}qualitometer/superposition`
        },
        researchCriterias(id) {
            return `${path}qualitometer/superposition/${id}`
        },
        qualityStates(id) {
            return `${path}qualitometer/${id}/states`
        },
        searchQualityStates() {
            return `${path}qualitometer/search/states`
        },
        allQualityStates() {
            return `${path}qualitometer/states`
        },
        qualitometerByTaxon: (codeTaxon, codeQualito) => `${path}qualitometer/taxon/${codeTaxon}?codeQualito=${codeQualito}`,
        operationCampaign(id) {
            return `${path}qualitometer/campaign/${id}/operation`
        },
        points: id => `${path}qualitometer/${id}/point`,
        uniqPoints: () => `${path}qualitometer/point`,
        analysisList: (idStation, idOperation) => `${path}qualitometer/${idStation}/operation/${idOperation}/analysis`,
        analysis: (idStation, idOperation, idAnalysis) => `${path}qualitometer/${idStation}/operation/${idOperation}/analysis/${idAnalysis}`,
        qualitoDashboard: (id) => `${path}qualitometer/${id}/dashboard`,
        qualitoIntegrationOverview: () => `${path}qualitometer/integrationOverview`,
        synchronizeDescription: id => `${path}qualitometer/${id}/synchronize`,
        lastOperationAnalysis: (ids, date) => {
            const idsQuery = !isNil(ids) ? `ids=${ids.join(',')}` : ''
            const dateQuery = !isNil(date) ? `date=${date}` : ''
            const queryParam = [idsQuery, dateQuery].filter(q => !!q).join('&')
            return `${path}qualitometer/lastOperation/analysis${queryParam ? `?${queryParam}` : ''}`
        },
        lastYearIndices: () => `${path}qualitometer/indice/lastYear`,
    },
    piezometer: {
        piezometerPath() {
            return `${path}piezometer/`
        },
        getAll() {
            return this.piezometerPath()
        },
        listSpecific() {
            return `${this.piezometerPath()}listSpecific`
        },
        getPiezometerDataWithCampaignsAndEvents() {
            return `${this.piezometerPath()}dataCampaignsEvents`
        },
        campaignEvent: id => `${path}piezometer/campaign/${id}/event`,
        campaigns: id => `${path}piezometer/${id}/campaign`,
        getAllNetworkPiezoLink() {
            return `${this.piezometerPath()}networkLink`
        },
        getAllAccessibilities() {
            return `${this.piezometerPath()}accessibilities/`
        },
        getAccessibilities(idPiezo) {
            return `${this.piezometerPath() + idPiezo}/accessibilities/`
        },
        getAllContributorLinks() {
            return `${path}station/piezometer/contributor/`
        },
        get(id) {
            return this.piezometerPath() + id
        },
        chartMeasures() {
            return `${this.piezometerPath()}chartMeasures`
        },
        measuresStats(id) {
            return `${this.piezometerPath()}measure/${id}/stats`
        },
        piezometerMeasures(stationId) {
            return `${this.piezometerPath()}measure/${stationId}`
        },
        piezometerMeasuresMin(stationId) {
            return `${this.piezometerPath()}measure/${stationId}/min`
        },
        piezometerMeasuresBrute(stationId) {
            return `${this.piezometerPath()}measure/${stationId}/brute`
        },
        piezometerMeasuresReset(stationId) {
            return `${this.piezometerPath()}measure/${stationId}/reset`
        },
        piezometerMeasuresReplace(stationId) {
            return `${this.piezometerPath()}measure/${stationId}/replace`
        },
        piezometerTypeMeasures(stationId, type) {
            return `${this.piezometerPath()}measure/${stationId}/type/${type}`
        },
        piezometerSamples(stationId) {
            return `${this.piezometerPath()}sample/${stationId}`
        },
        getAllEvents(eventTypes) {
            return `${this.piezometerPath()}allEvents${eventTypes ? `?eventTypes=["${eventTypes.join('","')}"]` : ''}`
        },
        interventionEvents: () => `${path}piezometer/interventionEvent`,
        events(piezometerId) {
            return `${this.piezometerPath() + piezometerId}/event`
        },
        eventsWithDiagnostic() {
            return `${this.piezometerPath()}eventDiagnostic`
        },
        eventWithDiagnostic(eventId) {
            return `${this.piezometerPath()}eventDiagnostic/${eventId}`
        },
        event(piezometerId, eventId) {
            return `${this.piezometerPath() + piezometerId}/event/${eventId}`
        },
        eventPostFacebook(piezometerId, eventId) {
            return `${this.piezometerPath() + piezometerId}/event/${eventId}/facebook`
        },
        eventReplacementActions(piezoId, date) {
            return `${this.piezometerPath()}${piezoId}/event/lastReplacementActions/${date}`
        },
        situation(type) {
            return `${this.piezometerPath()}situation/stations/${type}`
        },
        situationLastMeasures() {
            return `${this.piezometerPath()}situation/lastMeasures`
        },
        dataTypes() {
            return `${this.piezometerPath()}dataTypes`
        },
        measureModes() {
            return `${this.piezometerPath()}measureModes`
        },
        thresholds(stationId, displayCote) {
            return `${this.piezometerPath()}piezometerThreshold/${stationId}${displayCote ? `?displayCote=${displayCote}` : ''}`
        },
        allThresholds(displayCote) {
            return `${this.piezometerPath()}piezometerThreshold${displayCote ? `?displayCote=${displayCote}` : ''}`
        },
        chartOptions(stationId) {
            return `${this.piezometerPath()}chartOptions/${stationId}`
        },
        diagnostic() {
            return `${this.piezometerPath()}diagnostic/`
        },
        diagnosticLinkMaterial() {
            return `${this.piezometerPath()}diagnostic/linkMaterial`
        },
        diagnosticLinkEventType() {
            return `${this.piezometerPath()}diagnostic/linkEventType`
        },
        solution() {
            return `${this.piezometerPath()}solution/`
        },
        solutionLinkProblem() {
            return `${this.piezometerPath()}solution/linkProblem`
        },
        diagnosticAction(piezoId, eventId) {
            return `${this.piezometerPath() + piezoId}/event/${eventId}/actions`
        },
        diagnosticActions: piezoId => `${path}piezometer/${piezoId}/event/actions`,
        chartLandmarks: () => `${path}piezometer/chartLandmarks`,
        recalculateNGF: () => `${path}piezometer/dataTypes/recalculateNGF`,
        recalculateVolumes: () => `${path}piezometer/dataTypes/recalculateVolumes`,
        linkedPiezoMeasures: (id, occurence, nbOccurence) => `${path}piezometer/${id}/${occurence}/${nbOccurence}`,
        linkedPiezoPrel: () => `${path}piezometer/linkedPrel`,
        linkedPiezosPrel: () => `${path}piezometer/linkedPrels`,
        causeIntervention: (piezoId, eventId) => `${path}piezometer/${piezoId}/event/${eventId}/causeIntervention`,
    },
    pluviometry: {
        pluviometers: () => `${path}pluviometer/`,
        pluviometersWithCampaignsAndEvents: () => `${path}pluviometer/pluvioWithCampaingsAndEvents`,
        pluviometer: id => `${path}pluviometer/${id}`,
        lastMeasures: () => `${path}pluviometer/lastmeasures/`,
        situationLastMeasures: () => `${path}pluviometer/situation/lastMeasures`,
        measuresStats: id => `${path}pluviometer/measure/${id}/stats`,
        normale: id => `${path}pluviometer/${id}/normale`,
        thresholds: () => `${path}pluviometer/threshold/`,
        threshold: id => `${path}pluviometer/${id}/threshold`,
        followUp: id => `${path}pluviometer/${id}/followup`,
        keyFigure: () => `${path}pluviometer/keyfigure`,
        allEvents: () => `${path}pluviometer/event`,
        events: id => `${path}pluviometer/${id}/event`,
        event: (pluvioId, eventId) => `${path}pluviometer/${pluvioId}/event/${eventId}`,
        dataTypes: () => `${path}pluviometer/dataTypes`,
        campaigns: id => `${path}pluviometer/${id}/campaign`,
        campaignEvents: id => `${path}pluviometer/campaign/${id}/event`,
        situation: (type) => `${path}pluviometer/situation/stations/${type}`,
        getAllNetworkPluvioLink: () => `${path}pluviometer/networkLink`,
        getAllContributorLinks: () => `${path}pluviometer/contributors`,
        chartMeasures: () => `${path}pluviometer/chartMeasures`,
        chronicMeasures: () => `${path}pluviometer/chronic/measures`,
    },
    hydrometricStation: {
        hydrometricStationPath() {
            return `${path}hydrologicalStation/`
        },
        getAll() {
            return this.hydrometricStationPath()
        },
        get(id) {
            return this.hydrometricStationPath() + id
        },
        getBookmarksStat() {
            return `${path}hydrologicalStation/chronic/stats/bookmarks`
        },
        getStat() {
            return `${path}hydrologicalStation/chronic/stats`
        },
        // networkLinks: () => `${path}hydrologicalStation/networkLink`,
        // networkLink: id => `${path}hydrologicalStation/${id}/networkLink`,
        hydrometricBruteMeasures: () => `${path}hydrologicalStation/measure/brute`,
        hydrometricRawSituation() {
            return `${this.hydrometricStationPath()}situation`
        },
        hydrometrciStationTypeMeasures: (id, dataType) => `${path}hydrologicalStation/measure/${id}/type/${dataType}`,
        hydrometricStationFlow: (id, startDate, endDate) => `${path}hydrologicalStation/${id}/measures?${startDate ? `startDate=${startDate}&` : ''}endDate=${endDate}`,
        hydrometricStationSituation() {
            return `${this.hydrometricStationPath()}measure/`
        },
        chartOptions(stationId) {
            return `${this.hydrometricStationPath()}chartOptions/${stationId}`
        },
        hydrometricThresholds() {
            return `${this.hydrometricStationPath()}threshold/`
        },
        hydrometricThreshold(id) {
            return `${this.hydrometricStationPath() + id}/threshold`
        },
        hydrometricThresholdWithDataType(id, dataType) {
            return `${this.hydrometricStationPath() + id}/threshold/${dataType}`
        },
        tideGaugeDatas: () => `${path}hydrologicalStation/tidegauge`,
        getAllEvents() {
            return `${this.hydrometricStationPath()}allEvents`
        },
        events(hydroId) {
            return `${path}hydrologicalStation/${hydroId}/event`
        },
        event(hydroId, eventId) {
            return `${path}hydrologicalStation/${hydroId}/event/${eventId}`
        },
        dataTypes() {
            return `${path}hydrologicalStation/dataTypes`
        },
        getObservationsById: id => `${path}hydrologicalStation/${id}/observation`,
        observation: () => `${path}hydrologicalStation/observation`,
        campaigns: id => `${path}hydrologicalStation/${id}/campaign`,
        campaignEvents: id => `${path}hydrologicalStation/campaign/${id}/event`,
        getAllContributorLinks: () => `${path}hydrologicalStation/contributors`,
        statistics: () => `${path}hydrologicalStation/chronic/stats`,
        chronicMeasures: () => `${path}hydrologicalStation/chronic/measures`,
        chronicsMeasures: () => `${path}hydrologicalStation/chronics/measures`,
        lastMeasures: () => `${path}hydrologicalStation/chronic/lastMeasures`,
        situationLastMeasures: () => `${path}hydrologicalStation/situation/lastMeasures`,
        situation: (type) => `${path}hydrologicalStation/situation/stations/${type}`,
        purgeHydroMeasures: (id) => `${path}hydrologicalStation/measure/${id}/brute/purge`,
        deleteHydroMeasures: (id) => `${path}hydrologicalStation/measure/${id}/brute/delete`,
        resetHydroMeasures: (id) => `${path}hydrologicalStation/measure/${id}/brute/reset`,
        linkedHydroMeasures: (id, occurence, nbOccurence) => `${path}hydrologicalStation/${id}/${occurence}/${nbOccurence}`,
    },
    productionUnit: {
        productionUnitPath() {
            return `${path}productionUnit/`
        },
        getAll() {
            return this.productionUnitPath()
        },
        get(id) {
            return this.productionUnitPath() + id
        },
        getAssociations(id) {
            return `${this.productionUnitPath()}associated/${id}`
        },
        events(id) {
            return `${this.productionUnitPath() + id}/event`
        },
        event(id, eventId) {
            return `${this.productionUnitPath() + id}/event/${eventId}`
        },
        links(id) {
            return `${this.productionUnitPath()}links/${id}`
        },
    },
    distributionUnit: {
        distributionUnitPath() {
            return `${path}distributionUnit/`
        },
        getAll() {
            return this.distributionUnitPath()
        },
        get(id) {
            return this.distributionUnitPath() + id
        },
        getLink(id) {
            return `${this.distributionUnitPath() + id}/link`
        },
        getAssociations(id) {
            return `${this.distributionUnitPath()}associated/${id}`
        },
        getSectors() {
            return `${this.distributionUnitPath()}sectors/`
        },
        getAllCaptureDownstreamDistribUnits() {
            return `${this.distributionUnitPath()}captureDownstreamDistribUnits`
        },
        counterTypes() {
            return `${this.distributionUnitPath()}counters/types`
        },
        counters() {
            return `${this.distributionUnitPath()}counters`
        },
        events(id) {
            return `${this.distributionUnitPath() + id}/event`
        },
        event(distriId, eventId) {
            return `${this.distributionUnitPath() + distriId}/event/${eventId}`
        },
    },
    installation: {
        installationPath() {
            return `${path}installation/`
        },
        getAll() {
            return this.installationPath()
        },
        table() {
            return `${this.installationPath()}table`
        },
        getAllWithGeo() {
            return `${this.installationPath()}geo`
        },
        getExploitationInstallationsGeo(id) {
            return `${this.installationPath()}geo/${id}`
        },
        get(id) {
            return this.installationPath() + id
        },
        captureAlimArea() {
            return `${this.installationPath()}aac`
        },
        getTypes() {
            return `${this.installationPath()}types`
        },
        getAssociations(id) {
            return `${this.installationPath()}associated/${id}`
        },
        boreholeTable() {
            return `${this.installationPath()}borehole/table`
        },
        getBorehole(id) {
            return `${this.installationPath()}borehole/${id}`
        },
        getCapture(id) {
            return `${this.installationPath()}capture/${id}`
        },
        getLifting(id) {
            return `${this.installationPath()}lifting/${id}`
        },
        getPumping(id) {
            return `${this.installationPath()}pumping/${id}`
        },
        getSTEP(id) {
            return `${this.installationPath()}STEP/${id}`
        },
        getAgriTanks() {
            return `${this.installationPath()}agri/tanks`
        },
        createAgriTank() {
            return `${this.installationPath()}agri/tank`
        },
        getTank(id) {
            return `${this.installationPath()}tank/${id}`
        },
        getTreatment(id) {
            return `${this.installationPath()}treatment/${id}`
        },
        updateBorehole() {
            return `${this.installationPath()}borehole/`
        },
        updateCapture() {
            return `${this.installationPath()}capture/`
        },
        updateLifting() {
            return `${this.installationPath()}lifting/`
        },
        updatePumping() {
            return `${this.installationPath()}pumping/`
        },
        updateSTEP() {
            return `${this.installationPath()}STEP/`
        },
        updateTank() {
            return `${this.installationPath()}tank/`
        },
        updateTreatment() {
            return `${this.installationPath()}treatment/`
        },
        getAllEvents() {
            return `${this.installationPath()}allEvents`
        },
        events(id) {
            return `${this.installationPath() + id}/event`
        },
        event(id, eventId) {
            return `${this.installationPath() + id}/event/${eventId}`
        },
        analysis(id, lightMode = false, startDate, endDate, forceload) {
            return `${this.installationPath() + id}/analysis${`?lightMode=${lightMode}`}${startDate ? `&startDate=${startDate}` : ''}${endDate ? `&endDate=${endDate}` : ''}${forceload ? `&forceload=${forceload}` : ''}`
        },
        analysisByCriterias(id) {
            return `${this.installationPath()}${id}/analysis`
        },
        analysisByType() {
            return `${this.installationPath()}type/analysis`
        },
        analysisStats(id) {
            return `${this.installationPath()}${id}/analysis/stats`
        },
        analysisParameters() {
            return `${this.installationPath()}analysis/parameters`
        },
        usages(id) {
            return `${this.get(id)}/usages`
        },
        contributorLinks(id) {
            return `${this.get(id)}/contributors`
        },
        arrangements(id) {
            return `${this.get(id)}/arrangements`
        },
        cartographyData(id) {
            return `${this.get(id)}/cartographyData`
        },
        casings(id) {
            return `${this.getBorehole(id)}/casings`
        },
        drillings(id) {
            return `${this.getBorehole(id)}/drilling`
        },
        boreholeComments(id) {
            return `${this.getBorehole(id)}/boreholeComment`
        },
        lithology(id) {
            return `${this.getBorehole(id)}/lithology`
        },
        aquifer(id) {
            return `${this.getBorehole(id)}/aquifer`
        },
        mergeInstallations(fromId, toId) {
            return `${this.installationPath()}merge/${fromId}/${toId}`
        },
        changeBssCode() {
            return `${this.installationPath()}changeBssCode/`
        },
        cavity(id) {
            return `${this.installationPath()}cavity/${id}`
        },
        industrialSites() {
            return `${this.installationPath()}industrialSite`
        },
        installationIndustrialSites() {
            return `${this.installationPath()}installationIndustrialSites`
        },
        industrialSiteTable() {
            return `${this.installationPath()}industrialSite/table`
        },
        industrialSite(id) {
            return `${this.installationPath()}industrialSite/${id}`
        },
        pollutedSoil(id) {
            return `${this.installationPath()}pollutedSoil/${id}`
        },
        pollutedSoils() {
            return `${this.installationPath()}pollutedSoil`
        },
        flowObstructions() {
            return `${this.installationPath()}flowObstruction`
        },
        flowObstruction(id) {
            return `${this.installationPath()}flowObstruction/${id}`
        },
        activities() {
            return `${this.installationPath()}activity/`
        },
        contributorLink: () => `${path}installation/contributor/link`,
        campaignEvents: id => `${path}installation/campaign/${id}/event`,
        getVisit: (idInstallation, idCampaign) => `${path}installation/visit/${idInstallation}/${idCampaign}`,
        updateVisit: (idVisit) => `${path}installation/visit/${idVisit}`,
        updateVisitAndInstallation: (idVisit) => `${path}installation/visitAndInstallation/${idVisit}`,
        getInstallationVisits: idInstallation => `${path}installation/visits/${idInstallation}`,
        getTerritoryDiagnosis() {
            return `${this.installationPath()}territory/diagnosis`
        },
        getTerritoryActivities() {
            return `${this.installationPath()}territory/activities`
        },
        getInstallationsSituation() {
            return `${this.installationPath()}situation`
        },
        getInstallationAssociatedEvents(installationCode) {
            return `${this.installationPath()}${installationCode}/associatedEvents`
        },
        getTerritoryPollutants() {
            return `${this.installationPath()}territory/pollutants`
        },
        getDistributionEstablishmentsActivitySection() {
            return `${this.installationPath()}territory/industrialSiteActivityStat`
        },
        getTerritoryPollutantsEmissions() {
            return `${this.installationPath()}territory/pollutantsEmissions`
        },
        getEmissionsByActivity() {
            return `${this.installationPath()}territory/emissionsactivity`
        },
        updateIndustrialSite() {
            return `${this.installationPath()}industrialSite`
        },
        createIndustrialSite(codeInstallation) {
            return `${this.installationPath()}industrialSite/${codeInstallation}`
        },
        getEmissionsDeclarations(codeInstallation) {
            return `${this.installationPath()}territory/emissionsdeclarations/${codeInstallation}`
        },
        getAllConventionsAnalysis() {
            return `${this.installationPath()}territory/conventions`
        },
        getArtisansEmissions() {
            return `${this.installationPath()}STEP/territory/artisans`
        },
        getIndustriesEmissions() {
            return `${this.installationPath()}STEP/territory/industries`
        },
        getDomesticSubscribersEmissions(id) {
            return `${this.installationPath()}STEP/territory/subscribers/${id}`
        },
        getSTEPTerritoryDiagnosis(id) {
            return `${this.installationPath()}STEP/territory/diagnosis/${id}`
        },
        getBasins() {
            return `${this.installationPath()}STEP/basins`
        },
        getEstablishmentDischarges() {
            return `${this.installationPath()}territory/establishmentDischarges`
        },
        specificExportTerritoryActivities() {
            return `${this.installationPath()}territory/specificExport/activities`
        },
        specificExportPollutantsEmissions() {
            return `${this.installationPath()}territory/specificExport/pollutantsEmissions`
        },
        fetchDangersWithType() {
            return `${this.installationPath()}pgsse/dangers/type`
        },
        fetchDangersWithLocation(situationDate) {
            return `${this.installationPath()}pgsse/dangers/location/${situationDate}`
        },
        updateDanger() {
            return `${this.installationPath()}pgsse/danger`
        },
        getDanger(id) {
            return `${this.installationPath()}pgsse/danger/${id}`
        },
        getSTEPTable() {
            return `${this.installationPath()}STEP/table`
        },
        getTankTable() {
            return `${this.installationPath()}tank/table`
        },
        getFlowObstructionTable() {
            return `${this.installationPath()}flowObstruction/table`
        },
        getNetwork(id) {
            return `${this.installationPath()}network/${id}`
        },
        updateNetwork() {
            return `${this.installationPath()}network/`
        },
        importBasins() {
            return `${path}installation/import/basins`
        },
        importDesc() {
            return `${this.installationPath()}import/description`
        },
        networkTable() {
            return `${this.installationPath()}network/table`
        },
    },
    event: {
        sendByMail: (stationType, stationId, eventId) => `${path}${stationType}/${stationId}/event/${eventId}/mail`,
    },
    nature: {
        naturePath() {
            return `${path}nature/`
        },
        get(id) {
            return this.naturePath() + id
        },
    },
    sandre: {
        sandrePath() {
            return `${path}sandre/`
        },
        get(field, code) {
            return `${this.sandrePath() + field}/${code}`
        },
        getField(field) {
            return this.sandrePath() + field
        },
    },
    sse: {
        get(user = '') {
            const sse = user ? `sse?user=${user}` : 'sse'
            return path + sse
        },
    },
    referencial: {
        referencialPath() {
            return `${path}referencial/`
        },
        globalParameters() {
            return `${this.referencialPath()}globalParameters`
        },
        getParameterGroupUsage() {
            return `${this.referencialPath()}parameterGroup/usages`
        },
        checkPurgeReferencialIsRunning(referencial) {
            return `${this.referencialPath() + referencial}/purge/running`
        },
        contributorStatut() {
            return `${this.referencialPath()}contributor/statut`
        },
        stationTypes() {
            return `${this.referencialPath()}stationType`
        },
        supports() {
            return `${this.referencialPath()}support`
        },
        support(code) {
            return `${this.referencialPath()}support/${code}`
        },
        supportCount() {
            return `${this.referencialPath()}support/count`
        },
        supportCountDelete(code) {
            return `${this.referencialPath()}support/countDelete/${code}`
        },
        updateSupport() {
            return `${path}synchronise/support`
        },
        saveSupport(code) {
            return `${this.referencialPath()}support/${code}`
        },
        deleteSupport(code) {
            return `${this.referencialPath()}support/${code}`
        },
        purgeSupport() {
            return `${this.referencialPath()}support`
        },
        replaceSupport() {
            return `${this.referencialPath()}support/replace`
        },
        parameter() {
            return `${this.referencialPath()}parameter`
        },
        parameterCount() {
            return `${this.referencialPath()}parameter/count`
        },
        parameterCountDelete(code) {
            return `${this.referencialPath()}parameter/countDelete/${code}`
        },
        saveParameter(code) {
            return `${this.referencialPath()}parameter/${code}`
        },
        deleteParameter(code) {
            return `${this.referencialPath()}parameter/${code}`
        },
        purgeParameter() {
            return `${this.referencialPath()}parameter`
        },
        replaceParameter() {
            return `${this.referencialPath()}parameter/replace`
        },
        selectedParameters(selectedParameters) {
            return `${this.parameter()}?codes=${selectedParameters}`
        },
        parameterId(code) {
            return `${this.referencialPath()}parameter/${code}`
        },
        updateParameter() {
            return `${path}synchronise/parametre`
        },
        uploadCsvParameter() {
            return `${path}referencial/parametre/upload/csv`
        },
        uploadXmlParameter() {
            return `${path}referencial/parametre/upload/xml`
        },
        updateGroupParameter() {
            return `${path}synchronise/groupparametre`
        },
        parameterGroup: () => `${path}referencial/parameterGroup`,
        deleteParameterGroup: (parameterGroupCode) => `${path}referencial/parameterGroup/${parameterGroupCode}`,
        parameterGroupLink: () => `${path}referencial/parameterGroup/link`,
        selectedUnits(selectedUnits) {
            return `${this.units()}?units=${selectedUnits}`
        },
        units() {
            return `${this.referencialPath()}unit`
        },
        unit(code) {
            return `${this.referencialPath()}unit/${code}`
        },
        unitCount() {
            return `${this.referencialPath()}unit/count`
        },
        unitCountDelete(code) {
            return `${this.referencialPath()}unit/countDelete/${code}`
        },
        saveUnit(code) {
            return `${this.referencialPath()}unit/${code}`
        },
        deleteUnit(code) {
            return `${this.referencialPath()}unit/${code}`
        },
        purgeUnit() {
            return `${this.referencialPath()}unit`
        },
        replaceUnit() {
            return `${this.referencialPath()}unit/replace`
        },
        updateUnit() {
            return `${path}synchronise/unite`
        },
        watermasses() {
            return `${this.referencialPath()}watermass`
        },
        watermass(code) {
            return `${this.referencialPath()}watermass/${code}`
        },
        watermassStations: code => `${path}referencial/watermass/${code}/stations`,
        watermassCount() {
            return `${this.referencialPath()}watermass/count`
        },
        watermassCountDelete(code) {
            return `${this.referencialPath()}watermass/countDelete/${code}`
        },
        saveWatermass(code) {
            return `${this.referencialPath()}watermass/${code}`
        },
        deleteWatermass(code) {
            return `${this.referencialPath()}watermass/${code}`
        },
        purgeWatermass() {
            return `${this.referencialPath()}watermass`
        },
        replaceWatermass() {
            return `${this.referencialPath()}watermass/replace`
        },
        watermassState(code) {
            return `${this.referencialPath()}watermass/state/${code}`
        },
        updateWatermass() {
            return `${path}synchronise/watermass`
        },
        selections: () => `${path}referencial/parameter/selection`,
        updateSelections() {
            return `${path}quality/selection/`
        },
        selection(type) {
            return `${path}quality/selection/${type}`
        },
        selectionParameter(code) {
            return `${path}quality/selection/${code}/parameter`
        },
        launchStepMoleculeSigniCalculation() {
            return `${path}quality/selection/calculateStepMoleculeSigni`
        },
        updateThresholds() {
            return `${path}quality/threshold/all`
        },
        threshold() {
            return `${path}quality/threshold`
        },
        qualityThreshold(id, thresholdType) {
            return `${path}quality/threshold/${id}/quality/${thresholdType}`
        },
        method() {
            return `${this.referencialPath()}method`
        },
        methodCount() {
            return `${this.referencialPath()}method/count`
        },
        methodCountDelete(code) {
            return `${this.referencialPath()}method/countDelete/${code}`
        },
        selectedMethod(code) {
            return `${this.referencialPath()}method/${code}`
        },
        saveMethod(code) {
            return `${this.referencialPath()}method/${code}`
        },
        deleteMethod(code) {
            return `${this.referencialPath()}method/${code}`
        },
        purgeMethod() {
            return `${this.referencialPath()}method`
        },
        replaceMethod() {
            return `${this.referencialPath()}method/replace`
        },
        updateMethod() {
            return `${path}synchronise/methode`
        },
        contributors() {
            return `${this.referencialPath()}contributor`
        },
        contributorsTable() {
            return `${this.referencialPath()}contributor/table`
        },
        contributorsGroups() {
            return `${this.referencialPath()}contributor/group`
        },
        contributorsGroup(code) {
            return `${this.referencialPath()}contributor/group/${code}`
        },
        contributor(code) {
            return `${this.referencialPath()}contributor/${code}`
        },
        deleteContributor(code) {
            return `${this.referencialPath()}contributor/${code}`
        },
        purgeContributor() {
            return `${this.referencialPath()}contributor`
        },
        replaceContributor() {
            return `${this.referencialPath()}contributor/replace`
        },
        contributorProducers() {
            return `${this.referencialPath()}contributor/producer`
        },
        contributorLaboratories() {
            return `${this.referencialPath()}contributor/laboratory`
        },
        contributorDeterminators() {
            return `${this.referencialPath()}contributor/determinator`
        },
        contributorCount() {
            return `${this.referencialPath()}contributor/count`
        },
        contributorCountDelete(code) {
            return `${this.referencialPath()}contributor/countDelete/${code}`
        },
        contributorSynchro() {
            return `${path}job/-5/execute` // job system créé par le import engine et non modifiable par les utilisateurs
        },
        contributorDomain() {
            return `${this.referencialPath()}contributor/domain`
        },
        contributorTypes() {
            return `${this.referencialPath()}contributor/type/qualito`
        },
        contributorsPLuvioType() {
            return `${this.referencialPath()}contributor/type/pluvio`
        },
        saveContributor(id) {
            return `${this.referencialPath()}contributor/${id}`
        },
        networks() {
            return `${this.referencialPath()}network`
        },
        network(code) {
            return `${this.referencialPath()}network/${code}`
        },
        networkCount() {
            return `${this.referencialPath()}network/count`
        },
        networkCountDelete(code) {
            return `${this.referencialPath()}network/countDelete/${code}`
        },
        saveNetwork(code) {
            return `${this.referencialPath()}network/${code}`
        },
        deleteNetwork(code) {
            return `${this.referencialPath()}network/${code}`
        },
        purgeNetwork() {
            return `${this.referencialPath()}network`
        },
        replaceNetwork() {
            return `${this.referencialPath()}network/replace`
        },
        updateNetwork() {
            return `${path}synchronise/network`
        },
        tanksTypes() {
            return `${this.referencialPath()}agriTanksTypes`
        },
        tanksType() {
            return `${this.referencialPath()}agriTanksType`
        },
        tanksTypeId(id) {
            return `${this.referencialPath()}agriTanksType/${id}`
        },
        status() {
            return `${this.referencialPath()}status`
        },
        qualification() {
            return `${this.referencialPath()}qualification`
        },
        remark() {
            return `${this.referencialPath()}remark`
        },
        fractions() {
            return `${this.referencialPath()}fraction`
        },
        fraction(code) {
            return `${this.referencialPath()}fraction/${code}`
        },
        fractionCount() {
            return `${this.referencialPath()}fraction/count`
        },
        fractionCountDelete(code) {
            return `${this.referencialPath()}fraction/countDelete/${code}`
        },
        saveFraction(code) {
            return `${this.referencialPath()}fraction/${code}`
        },
        deleteFraction(code) {
            return `${this.referencialPath()}fraction/${code}`
        },
        purgeFraction() {
            return `${this.referencialPath()}fraction`
        },
        replaceFraction() {
            return `${this.referencialPath()}fraction/replace`
        },
        updateFraction() {
            return `${path}synchronise/fraction`
        },
        contacts() {
            return `${this.referencialPath()}contact`
        },
        contact(code) {
            return `${this.referencialPath()}contact/${code}`
        },
        contactsTable() {
            return `${this.referencialPath()}contact/table`
        },
        contactCount() {
            return `${this.referencialPath()}contact/count`
        },
        contactCountDelete(code) {
            return `${this.referencialPath()}contact/countDelete/${code}`
        },
        saveContact() {
            return `${this.referencialPath()}contact`
        },
        deleteContact(code) {
            return `${this.referencialPath()}contact/${code}`
        },
        purgeContact() {
            return `${this.referencialPath()}contact`
        },
        replaceContact() {
            return `${this.referencialPath()}contact/replace`
        },
        contactsGroups() {
            return `${this.referencialPath()}contact/group`
        },
        contactsGroup(code) {
            return `${this.referencialPath()}contact/group/${code}`
        },
        usages() {
            return `${this.referencialPath()}usages`
        },
        usage(code) {
            return `${this.referencialPath()}usages/${code}`
        },
        usagesCount() {
            return `${this.referencialPath()}usages/count`
        },
        filterStation() {
            return `${this.referencialPath()}filterStation`
        },
        city(code) {
            return `${path}city/${code}`
        },
        cities() {
            return `${path}city`
        },
        cityCount() {
            return `${path}city/count`
        },
        cityCedex() {
            return `${path}city/cedex`
        },
        cityCountDelete(code) {
            return `${path}city/countDelete/${code}`
        },
        saveCity(code) {
            return `${path}city/${code}`
        },
        deleteCity(code) {
            return `${path}city/${code}`
        },
        purgeCity() {
            return `${path}city`
        },
        replaceCity() {
            return `${path}city/replace`
        },
        uploadCsvCity() {
            return `${path}city/upload/csv`
        },
        uploadCsvTaxon() {
            return `${path}referencial/taxon/upload/csv`
        },
        uploadXmlCity() {
            return `${path}city/upload/xml`
        },
        uploadXmlTaxon() {
            return `${path}referencial/taxon/upload/xml`
        },
        updateCity() {
            return `${path}job/-3/execute` // job system créé par le import engine et non modifiable par les utilisateurs
        },
        countries: () => `${path}country`,
        country: code => `${path}country/${code}`,
        countryCount: () => `${path}country/count`,
        citiesHisto() {
            return `${path}city/histo`
        },
        watersheds() {
            return `${path}watershed`
        },
        watershed(id) {
            return `${path}watershed/${id}`
        },
        watershedStations: id => `${path}watershed/${id}/stations`,
        watershedCount() {
            return `${path}watershed/count`
        },
        watershedCountDelete(code) {
            return `${path}watershed/countDelete/${code}`
        },
        saveWatershed(code) {
            return `${path}watershed/${code}`
        },
        deleteWatershed(code) {
            return `${path}watershed/${code}`
        },
        purgeWatershed() {
            return `${path}watershed`
        },
        replaceWatershed() {
            return `${path}watershed/replace`
        },
        updateWatershed() {
            return `${path}synchronise/bassindce`
        },
        uploadCsvWatershed() {
            return `${path}watershed/upload/csv`
        },
        uploadXmlWatershed() {
            return `${path}watershed/upload/xml`
        },
        altimetricSystemProductionMode() {
            return `${this.referencialPath()}altimetric/production`
        },
        altimetricSystemNature() {
            return `${this.referencialPath()}altimetric/nature`
        },
        altimetricSystemAltimetricSystem() {
            return `${this.referencialPath()}altimetric/system`
        },
        landmark() {
            return `${this.referencialPath()}landmark`
        },
        departments: () => `${path}referencial/department`,
        departmentsAquadb() {
            return 'https://aquadb.fr/administration/web/map/department'
        },
        departmentAquadb(code) {
            return `https://aquadb.fr/administration/web/map/department/${code}`
        },
        qualitometerType() {
            return `${this.referencialPath()}type/qualitometer`
        },
        piezometerType() {
            return `${this.referencialPath()}type/piezometer`
        },
        aquifers() {
            return `${this.referencialPath()}aquifer`
        },
        aquifer(code) {
            return `${this.referencialPath()}aquifer/${code}`
        },
        aquiferCount() {
            return `${this.referencialPath()}aquifer/count`
        },
        saveAquifer(code) {
            return `${this.referencialPath()}aquifer/${code}`
        },
        replaceAquifer() {
            return `${this.referencialPath()}aquifer/replace`
        },
        deleteAquifer(code) {
            return `${this.referencialPath()}aquifer/${code}`
        },
        aquiferCountDelete(code) {
            return `${this.referencialPath()}aquifer/countDelete/${code}`
        },
        culturesFamilies() {
            return `${this.referencialPath()}cultureFamily`
        },
        culturesFamily(code) {
            return `${this.referencialPath()}cultureFamily/${code}`
        },
        cultureFamilyCultures(code) {
            return `${this.referencialPath()}cultureFamily/${code}/cultures`
        },
        culturesFamilyCount() {
            return `${this.referencialPath()}cultureFamily/count`
        },
        saveCulturesFamily(code) {
            return `${this.referencialPath()}cultureFamily/${code}`
        },
        replaceCulturesFamily() {
            return `${this.referencialPath()}cultureFamily/replace`
        },
        deleteCulturesFamily(code) {
            return `${this.referencialPath()}cultureFamily/${code}`
        },
        culturesFamilyCountDelete(code) {
            return `${this.referencialPath()}cultureFamily/countDelete/${code}`
        },
        cultures() {
            return `${this.referencialPath()}culture`
        },
        culture(code) {
            return `${this.referencialPath()}culture/${code}`
        },
        cultureCount() {
            return `${this.referencialPath()}culture/count`
        },
        saveCulture(code) {
            return `${this.referencialPath()}culture/${code}`
        },
        replaceCulture() {
            return `${this.referencialPath()}culture/replace`
        },
        deleteCulture(code) {
            return `${this.referencialPath()}culture/${code}`
        },
        cultureCountDelete(code) {
            return `${this.referencialPath()}culture/countDelete/${code}`
        },
        culturesVentilations() {
            return `${this.referencialPath()}culturesVentilations`
        },
        RPG() {
            return `${this.referencialPath()}RPG`
        },
        countRPG() {
            return `${this.referencialPath()}RPG/count`
        },
        sectorCreate() {
            return `${this.referencialPath()}sector`
        },
        sector(id) {
            return `${this.referencialPath()}sector/${id}`
        },
        sectors() {
            return `${this.referencialPath()}sectors`
        },
        countSector() {
            return `${this.referencialPath()}sector/count`
        },
        getQualitometers(id) {
            return `${this.referencialPath()}sector/qualitometer/${id}`
        },
        uploadCsvRPG() {
            return `${this.referencialPath()}RPG/upload/csv`
        },
        culturesRPG() {
            return `${this.referencialPath()}cultureRPG`
        },
        cultureRPG(code) {
            return `${this.referencialPath()}cultureRPG/${code}`
        },
        activities() {
            return `${this.referencialPath()}activity`
        },
        activity(code) {
            return `${this.referencialPath()}activity/${code}`
        },
        activityCount() {
            return `${this.referencialPath()}activity/count`
        },
        activityCountDelete(code) {
            return `${this.referencialPath()}activity/countDelete/${code}`
        },
        saveActivity(code) {
            return `${this.referencialPath()}activity/${code}`
        },
        deleteActivity(code) {
            return `${this.referencialPath()}activity/${code}`
        },
        zones() {
            return `${this.referencialPath()}zone`
        },
        zone(code) {
            return `${this.referencialPath()}zone/${code}`
        },
        zoneCount() {
            return `${this.referencialPath()}zone/count`
        },
        saveZone(code) {
            return `${this.referencialPath()}zone/${code}`
        },
        replaceZone() {
            return `${this.referencialPath()}zone/replace`
        },
        deleteZone(code) {
            return `${this.referencialPath()}zone/${code}`
        },
        zoneCountDelete(code) {
            return `${this.referencialPath()}zone/countDelete/${code}`
        },
        driveModes() {
            return `${this.referencialPath()}conduite`
        },
        driveMode(code) {
            return `${this.referencialPath()}conduite/${code}`
        },
        driveModeCount() {
            return `${this.referencialPath()}conduite/count`
        },
        saveDriveMode(code) {
            return `${this.referencialPath()}conduite/${code}`
        },
        replaceDriveMode() {
            return `${this.referencialPath()}conduite/replace`
        },
        deleteDriveMode(code) {
            return `${this.referencialPath()}conduite/${code}`
        },
        driveModeCountDelete(code) {
            return `${this.referencialPath()}conduite/countDelete/${code}`
        },
        state() {
            return `${this.referencialPath()}state`
        },
        quality() {
            return `${this.referencialPath()}quality`
        },
        fieldMode() {
            return `${this.referencialPath()}fieldMode`
        },
        hydrogeologicalEntity(code) {
            return `${this.referencialPath()}hydrogeologicalEntity/${code}`
        },
        hydrogeologicalEntities() {
            return `${this.referencialPath()}hydrogeologicalEntity`
        },
        hydrogeologicalEntityStructure() {
            return `${this.referencialPath()}hydrogeologicalEntity/structure`
        },
        hydrogeologicalEntityState() {
            return `${this.referencialPath()}hydrogeologicalEntity/state`
        },
        hydrogeologicalEntityType() {
            return `${this.referencialPath()}hydrogeologicalEntity/type`
        },
        hydrogeologicalEntityCount() {
            return `${this.referencialPath()}hydrogeologicalEntity/count`
        },
        hydrogeologicalEntityCountDelete(code) {
            return `${this.referencialPath()}hydrogeologicalEntity/countDelete/${code}`
        },
        saveHydrogeologicalEntity(code) {
            return `${this.referencialPath()}hydrogeologicalEntity/${code}`
        },
        deleteHydrogeologicalEntity(code) {
            return `${this.referencialPath()}hydrogeologicalEntity/${code}`
        },
        purgeHydrogeologicalEntity() {
            return `${this.referencialPath()}hydrogeologicalEntity`
        },
        replaceHydrogeologicalEntity() {
            return `${this.referencialPath()}hydrogeologicalEntity/replace`
        },
        qualitometerRepresentiveness() {
            return `${this.referencialPath()}qualitometerRepresentiveness`
        },
        calculateParameters() {
            return `${this.referencialPath()}parameter/calculate`
        },
        cmsFile(fileName) {
            return `${contentsPath}CMS/${fileName}`
        },
        cmsEvents() {
            return `${path}cms`
        },
        cmsWithoutWP: () => `${path}cms/withoutWP`,
        cmsEvent(id) {
            return `${path}cms/${id}`
        },
        cmsEventLang(id, lang) {
            return `${path}cms/${id}/${lang}`
        },
        cmsSend(id) {
            return `${path}cms/send/${id}`
        },
        cmsCategories() {
            return `${path}cms/category`
        },
        cmsDocumentsStatistics() {
            return `${path}cms/documents/stats`
        },
        cmsDocumentsModuleStatistics(module) {
            return `${path}cms/documents/stats/module/${module}`
        },
        cmsDocumentsUserStatistics(login) {
            return `${path}cms/documents/stats/user/${login}`
        },
        getCMSSurvey(idSurvey) {
            return `${path}cms/survey/${idSurvey}`
        },
        getCMSSurveyRestricted(idSurvey, idInstallation) {
            return `${path}cms/survey/${idSurvey}/${idInstallation}`
        },
        getCMSSurveyId(visitDate) {
            return `${path}cms/survey/date/${visitDate}`
        },
        getCmsModel(idModel) {
            return `${path}cms/model/${idModel}`
        },
        playCmsModel(idModel) {
            return `${path}cms/model/${idModel}/play`
        },
        regenerate() {
            return `${path}cms/regenerate`
        },
        getCmsModels() {
            return `${path}cms/model`
        },
        getCmsModelStations(modelId) {
            return `${path}cms/model/${modelId}/stations`
        },
        getCmsModelObstacles(modelId) {
            return `${path}cms/model/${modelId}/obstacles`
        },
        getCmsModelTideGauges(modelId) {
            return `${path}cms/model/${modelId}/tidegauges`
        },
        getCmsDescriptions(cmsId) {
            return `${path}cms/descriptions/${cmsId}`
        },
        updateCmsDescriptions() {
            return `${path}cms/descriptions`
        },
        getCmsStations(cmsId) {
            return `${path}cms/model/stations/${cmsId}`
        },
        getCmsObstacles(cmsId) {
            return `${path}cms/model/obstacles/${cmsId}`
        },
        getCmsTideGauges(cmsId) {
            return `${path}cms/model/tidegauges/${cmsId}`
        },
        updateCmsModel() {
            return `${path}cms/model`
        },
        updateCmsModelStations() {
            return `${path}cms/model/stations`
        },
        updateCmsStations(cmsId) {
            return `${path}cms/model/stations/${cmsId}`
        },
        updateCmsModelObstacles() {
            return `${path}cms/model/obstacles`
        },
        updateCmsObstacles(cmsId) {
            return `${path}cms/model/obstacles/${cmsId}`
        },
        updateCmsModelTideGauges() {
            return `${path}cms/model/tidegauges`
        },
        updateCmsTideGauges(cmsId) {
            return `${path}cms/model/tidegauges/${cmsId}`
        },
        deleteCmsModel(modelId) {
            return `${path}cms/model/${modelId}`
        },
        sendCmsByModel(cmsId) {
            return `${path}cms/${cmsId}/model/send`
        },
        referencialSandreCodes() {
            return `${this.referencialPath()}sandre`
        },
        referencialSandreCode(field) {
            return `${this.referencialPath()}sandre/${field}`
        },
        updateTaxon() {
            return `${path}synchronise/taxon`
        },
        taxons: () => `${path}referencial/taxon`,
        taxon: code => `${path}referencial/taxon/${code}`,
        taxonCount: () => `${path}referencial/taxon/count`,
        taxonCountDelete: (code) => `${path}referencial/taxon/countDelete/${code}`,
        replaceTaxon: () => `${path}referencial/taxon/replace`,
        sandreCodes() {
            return `${path}sandre`
        },
        updateSandreCode() {
            return `${this.referencialPath()}sandre`
        },
        updateReferencialSandreCode() {
            return `${this.referencialPath()}referencialSandre`
        },
        getManagementUnit(id) {
            return `${this.referencialPath()}managementUnits/${id}`
        },
        getAllManagementUnits() {
            return `${this.referencialPath()}managementUnits`
        },
        updateManagementUnit() {
            return `${this.referencialPath()}managementUnits`
        },
        createManagementUnit() {
            return `${this.referencialPath()}managementUnits`
        },
        managementUnitsRestrictions() {
            return `${this.referencialPath()}managementUnitsRestrictions`
        },
        location() {
            return `${this.referencialPath()}location`
        },
        archiveCmsMessage(cmsId) {
            return `${path}cms/message/${cmsId}`
        },
        addCmsMessage() {
            return `${path}cms/message`
        },
        getCmsMessage(id) {
            return `${path}cms/messages/${id}`
        },
        duplicateCms(id) {
            return `${path}cms/duplicate/${id}`
        },
    },
    resource: {
        resources: () => `${path}referencial/resources`,
        resource: id => `${path}referencial/resources/${id}`,
        resourceCountDelete: id => `${path}referencial/resources/countDelete/${id}`,
    },
    campaign: {
        campaigns: stationType => `${path}campaign/${stationType}`,
        campaign: (stationType, id) => `${path}campaign/${stationType}/${id}`,
        campaignStations: stationType => `${path}campaign/${stationType}/station`,
        campaignStation: (stationType, id) => `${path}campaign/${stationType}/${id}/station`,
        campaignsProgress: stationType => `${path}campaign/${stationType}/progress`,
        campaignProgress: (stationType, id) => `${path}campaign/${stationType}/${id}/progress`,
        campaignByStation: (stationType, id) => `${path}${stationType}/${id}/campaign`,
        campaignParameter: id => `${path}campaign/quality/${id}/parameter`,
        campaignPlanning: id => `${path}campaign/quality/${id}/planning`,
        campaignFiles: id => `${path}campaign/quality/${id}/file`,
        edilaboFiles: id => `${path}campaign/quality/${id}/file/edilabo`,
        qualityFollowUp: (id) => `${path}campaign/quality/${id}/followUp`,
        planningFollowUp: (campaignId, planningId) => `${path}campaign/quality/${campaignId}/planning/${planningId}/followUp`,
        campaignVisits: idCampaign => `${path}campaign/visits/${idCampaign}`,
        deleteVisit: (idCampaign, idInstallation) => `${path}campaign/visit/${idCampaign}/${idInstallation}`,
        unlink: id => `${path}campaign/quality/${id}/unlinkOperation`,
        link: id => `${path}campaign/quality/${id}/linkOperation`,
        allCampaignsActions(url) {
            return `${path}campaign/visits/actions${url}`
        },
        allCampaignsInstallationWithStats(url) {
            return `${path}campaign/installations${url}`
        },
    },
    materiel: {
        materielPath() {
            return `${path}materiel/`
        },
        monitored() {
            return `${this.materielPath()}monitored`
        },
        states() {
            return `${this.materielPath()}state`
        },
        materielType: () => `${path}materiel/types`,
        addMaterielType(field) {
            return `${this.materielPath()}type/${field}`
        },
        settingRule: () => `${path}materiel/setting/rule`,
        materielByEvent: (id, date) => `${path}materiel/piezometer/${id}/event/${date}`,
        centralsSituationByEvent: (id, stationType, stationId) => `${path}materiel/central/situation/${stationType}/${stationId}/event/${id}`,
        powerSuppliesSituationByEvent: (id, stationType, stationId) => `${path}materiel/powerSupply/situation/${stationType}/${stationId}/event/${id}`,
        sensorsSituationByEvent: (id, stationType, stationId) => `${path}materiel/sensor/situation/${stationType}/${stationId}/event/${id}`,
        simsSituationByEvent: (id, stationType, stationId) => `${path}materiel/sim/situation/${stationType}/${stationId}/event/${id}`,
        telecomsSituationByEvent: (id, stationType, stationId) => `${path}materiel/telecom/situation/${stationType}/${stationId}/event/${id}`,
        variousMaterielsSituationByEvent: (id, stationType, stationId) => `${path}materiel/variousMateriel/situation/${stationType}/${stationId}/event/${id}`,
        equipmentsSituationByEvent: (id, stationType, stationId) => `${path}materiel/equipment/situation/${stationType}/${stationId}/event/${id}`,
        subscriptionsSituationByEvent: (id, stationType, stationId) => `${path}materiel/subscription/situation/${stationType}/${stationId}/event/${id}`,
        situationGeneric: matCategory => `${path}materiel/${matCategory}/situation/`,
        allCentrals: (withEquipment = false) => `${path}materiel/central?withEquipment=${withEquipment}`,
        centrals() {
            return `${this.materielPath()}central`
        },
        addCentralSituation() {
            return `${this.materielPath()}central/situation`
        },
        saveCentralSituation(idSituation) {
            return `${this.materielPath()}central/situation/${idSituation}`
        },
        deleteCentralSituation(idSituation) {
            return this.saveCentralSituation(idSituation)
        },
        centralSituations(idCentral) {
            return `${this.materielPath()}central/${idCentral}/situation`
        },
        centralLastSituations(idCentral) {
            return `${this.materielPath()}central/${idCentral}/lastSituation`
        },
        centralsLastSituation() {
            return `${this.materielPath()}central/lastSituation/`
        },
        centralChannel(idCentral) {
            return `${this.materielPath()}central/${idCentral}/channel`
        },
        centralAllChannel(idCentral) {
            return `${this.materielPath()}central/${idCentral}/allChannel`
        },
        centralRange(idCentral) {
            return `${this.materielPath()}central/${idCentral}/range`
        },
        central(id) {
            return `${this.materielPath()}central/${id}`
        },
        centralPicture(id) {
            return `${this.materielPath()}central/${id}/pictures`
        },
        centralFile(id) {
            return `${this.materielPath()}central/${id}/files`
        },
        centralTypes: () => `${path}materiel/central/type`,
        centralAllTypes: (withEquipmentType = false) => `${path}materiel/central/type?withEquipmentType=${withEquipmentType}`,
        centralType(id) {
            return `${this.materielPath()}central/type/${id}`
        },
        powerSupply(id) {
            return `${this.materielPath()}powerSupply/${id}`
        },
        allPowerSupplies: (withEquipment = false) => `${path}materiel/powerSupply?withEquipment=${withEquipment}`,
        powerSupplies() {
            return `${this.materielPath()}powerSupply`
        },
        powerSupplySituations(idCentral) {
            return `${this.materielPath()}powerSupply/${idCentral}/situation`
        },
        addPowerSupplySituation() {
            return `${this.materielPath()}powerSupply/situation`
        },
        powerSuppliesLastSituation() {
            return `${this.materielPath()}powerSupply/lastSituation/`
        },
        savePowerSupplySituation(idSituation) {
            return `${this.materielPath()}powerSupply/situation/${idSituation}`
        },
        deletePowerSupplySituation(idSituation) {
            return `${this.materielPath()}powerSupply/situation/${idSituation}`
        },
        powerSupplyPicture(id) {
            return `${this.materielPath()}powerSupply/${id}/pictures`
        },
        powerSupplyFile(id) {
            return `${this.materielPath()}powerSupply/${id}/files`
        },
        powerSupplyTypes: () => `${path}materiel/powerSupply/type`,
        powerSupplyAllTypes: (withEquipmentType = false) => `${path}materiel/powerSupply/type?withEquipmentType=${withEquipmentType}`,
        powerSupplyType(id) {
            return `${this.materielPath()}powerSupply/type/${id}`
        },
        allSensors: (withEquipment = false) => `${path}materiel/sensor?withEquipment=${withEquipment}`,
        sensors: () => `${path}materiel/sensor`,
        sensor: id => `${path}materiel/sensor/${id}`,
        sensorSituations: id => `${path}materiel/sensor/${id}/situation`,
        sensorsSituations: () => `${path}materiel/sensor/situation`,
        sensorsLastSituation: () => `${path}materiel/sensor/lastSituation/`,
        sensorSituation: id => `${path}materiel/sensor/situation/${id}`,
        sensorPicture: id => `${path}materiel/sensor/${id}/pictures`,
        sensorFile: id => `${path}materiel/sensor/${id}/files`,
        sensorTypes: () => `${path}materiel/sensor/type`,
        sensorAllTypes: (withEquipmentType = false) => `${path}materiel/sensor/type?withEquipmentType=${withEquipmentType}`,
        sensorType: id => `${path}materiel/sensor/type/${id}`,
        allSims: (withEquipment = false) => `${path}materiel/sim?withEquipment=${withEquipment}`,
        sims() {
            return `${this.materielPath()}sim`
        },
        sim(id) {
            return `${this.materielPath()}sim/${id}`
        },
        simWithSubscription(id) {
            return `${this.materielPath()}sim/withSubscription/${id}`
        },
        simSituations(idSim) {
            return `${this.materielPath()}sim/${idSim}/situation`
        },
        addSimSituation: () => `${path}materiel/sim/situation`,
        simsLastSituation() {
            return `${this.materielPath()}sim/lastSituation/`
        },
        saveSimSituation(idSituation) {
            return `${this.materielPath()}sim/situation/${idSituation}`
        },
        deleteSimSituation(idSituation) {
            return `${this.materielPath()}sim/situation/${idSituation}`
        },
        simPicture(id) {
            return `${this.materielPath()}sim/${id}/pictures`
        },
        simFile(id) {
            return `${this.materielPath()}sim/${id}/files`
        },
        simTypes: () => `${path}materiel/sim/type`,
        simAllTypes: (withEquipmentType = false) => `${path}materiel/sim/type?withEquipmentType=${withEquipmentType}`,
        simType(id) {
            return `${this.materielPath()}sim/type/${id}`
        },
        closeLastSimSubscription: idSim => `${path}materiel/sim/closeLastSubscription/${idSim}`,
        allTelecoms: (withEquipment = false) => `${path}materiel/telecom?withEquipment=${withEquipment}`,
        telecoms() {
            return `${this.materielPath()}telecom`
        },
        telecom: id => `${path}materiel/telecom/${id}`,
        telecomSituations(idTelecom) {
            return `${this.materielPath()}telecom/${idTelecom}/situation`
        },
        addTelecomSituation() {
            return `${this.materielPath()}telecom/situation`
        },
        telecomsLastSituation() {
            return `${this.materielPath()}telecom/lastSituation/`
        },
        saveTelecomSituation(idSituation) {
            return `${this.materielPath()}telecom/situation/${idSituation}`
        },
        deleteTelecomSituation(idSituation) {
            return `${this.materielPath()}telecom/situation/${idSituation}`
        },
        telecomPicture(id) {
            return `${this.materielPath()}telecom/${id}/pictures`
        },
        telecomFile(id) {
            return `${this.materielPath()}telecom/${id}/files`
        },
        telecomTypes: () => `${path}materiel/telecom/type`,
        telecomAllTypes: (withEquipmentType = false) => `${path}materiel/telecom/type?withEquipmentType=${withEquipmentType}`,
        telecomType(id) {
            return `${this.materielPath()}telecom/type/${id}`
        },
        variousMateriels() {
            return `${this.materielPath()}variousMateriel`
        },
        variousMateriel(id) {
            return `${this.materielPath()}variousMateriel/${id}`
        },
        variousMaterielSituations(idVariousMateriel) {
            return `${this.materielPath()}variousMateriel/${idVariousMateriel}/situation`
        },
        addVariousMaterielSituation() {
            return `${this.materielPath()}variousMateriel/situation`
        },
        variousSituations: () => `${path}materiel/variousMateriel/situations`,
        variousMaterielsLastSituation() {
            return `${this.materielPath()}variousMateriel/lastSituation/`
        },
        saveVariousMaterielSituation(idSituation) {
            return `${this.materielPath()}variousMateriel/situation/${idSituation}`
        },
        deleteVariousMaterielSituation(idSituation) {
            return `${this.materielPath()}variousMateriel/situation/${idSituation}`
        },
        variousMaterielPicture(id) {
            return `${this.materielPath()}variousMateriel/${id}/pictures`
        },
        variousMaterielFile(id) {
            return `${this.materielPath()}variousMateriel/${id}/files`
        },
        variousMaterielTypes: () => `${path}materiel/variousMateriel/type`,
        variousMaterielType(id) {
            return `${this.materielPath()}variousMateriel/type/${id}`
        },
        variousMaterielFromType(id) {
            return `${this.materielPath()}variousMateriel/specificType/${id}`
        },
        events() {
            return `${this.materielPath()}events`
        },
        event(id) {
            return `${this.materielPath()}events/${id}`
        },
        eventsExploitation(exploitationId) {
            return `${this.materielPath()}events/exploitation/${exploitationId}`
        },
        eventMat(materielId) {
            return `${this.materielPath()}events/materiel/${materielId}`
        },
        eventsTypes() {
            return `${this.materielPath()}eventsType`
        },
        eventsType(id) {
            return `${this.materielPath()}eventsType/${id}`
        },
        equipments: () => `${path}materiel/equipment/`,
        duplicateEquipment: (id, nbEquipment) => `${path}materiel/equipment/duplicate/${id}?nbEquipment=${nbEquipment}`,
        equipment: id => `${path}materiel/equipment/${id}`,
        equipmentTypes: () => `${path}materiel/equipment/type`,
        equipmentType: (id) => `${path}materiel/equipment/type/${id}`,
        equipmentsLastSituation: () => `${path}materiel/equipment/lastSituation/`,
        equipmentLastSituation: (id) => `${path}materiel/equipment/${id}/lastSituation`,
        equipmentSituations: (id) => `${path}materiel/equipment/${id}/situation`,
        equipmentSituation: (id) => `${path}materiel/equipment/situation/${id}`,
        createEquipmentSituation: () => `${path}materiel/equipment/situation/`,
        equipmentPicture: (id) => `${path}materiel/equipment/${id}/pictures`,
        equipmentFile: (id) => `${path}materiel/equipment/${id}/files`,
        subscriptionSim: id => `${path}materiel/subscription/${id}/sim`,
        subscriptionSimLink: (idSim, idSubscription) => `${path}materiel/subscription/${idSubscription}/sim/${idSim}`,
        simSubscription: id => `${path}materiel/sim/${id}/subscription`,
        createSimSubscription: () => `${path}materiel/sim/simSubscription`,
        subscriptions: () => `${path}materiel/subscription`,
        subscription: id => `${path}materiel/subscription/${id}`,
        subscriptionSituations: id => `${path}materiel/subscription/${id}/situation`,
        addSubscriptionSituation: () => `${path}materiel/subscription/situation`,
        subscriptionsLastSituation: () => `${path}materiel/subscription/lastSituation/`,
        simSubscriptionsLastSituation: () => `${path}materiel/sim/subscription/lastSituation/`,
        saveSubscriptionSituation: (idSituation) => `${path}materiel/subscription/situation/${idSituation}`,
        deleteSubscriptionSituation: (idSituation) => `${path}materiel/subscription/situation/${idSituation}`,
        subscriptionTypes: () => `${path}materiel/subscription/type`,
        materielAssignments(materielId, materielType) {
            return `${this.materielPath()}assignment/${materielType}/${materielId}`
        },
        getHydrometryCentralAssignment: id => `${path}materiel/central/hydrometry/station/${id}`,
        getPiezometerCentralAssignment: id => `${path}materiel/central/piezometer/station/${id}`,
        getPluviometerCentralAssignment: id => `${path}materiel/central/pluviometer/station/${id}`,
        getQualitometerCentralAssignment: id => `${path}materiel/central/qualitometer/station/${id}`,
        getInstallationCentralAssignment: id => `${path}materiel/central/installation/station/${id}`,
        getHydrometryPowerSupplyAssignment: id => `${path}materiel/powerSupply/hydrometry/station/${id}`,
        getPiezometerPowerSupplyAssignment: id => `${path}materiel/powerSupply/piezometer/station/${id}`,
        getPluviometerPowerSupplyAssignment: id => `${path}materiel/powerSupply/pluviometer/station/${id}`,
        getQualitometerPowerSupplyAssignment: id => `${path}materiel/powerSupply/qualitometer/station/${id}`,
        getInstallationPowerSupplyAssignment: id => `${path}materiel/powerSupply/installation/station/${id}`,
        getHydrometrySensorAssignment: id => `${path}materiel/sensor/hydrometry/station/${id}`,
        getPiezometerSensorAssignment: id => `${path}materiel/sensor/piezometer/station/${id}`,
        getPluviometerSensorAssignment: id => `${path}materiel/sensor/pluviometer/station/${id}`,
        getQualitometerSensorAssignment: id => `${path}materiel/sensor/qualitometer/station/${id}`,
        getInstallationSensorAssignment: id => `${path}materiel/sensor/installation/station/${id}`,
        getHydrometrySimAssignment: id => `${path}materiel/sim/hydrometry/station/${id}`,
        getPiezometerSimAssignment: id => `${path}materiel/sim/piezometer/station/${id}`,
        getPluviometerSimAssignment: id => `${path}materiel/sim/pluviometer/station/${id}`,
        getQualitometerSimAssignment: id => `${path}materiel/sim/qualitometer/station/${id}`,
        getInstallationSimAssignment: id => `${path}materiel/sim/installation/station/${id}`,
        getHydrometryTelecomAssignment: id => `${path}materiel/telecom/hydrometry/station/${id}`,
        getPiezometerTelecomAssignment: id => `${path}materiel/telecom/piezometer/station/${id}`,
        getPluviometerTelecomAssignment: id => `${path}materiel/telecom/pluviometer/station/${id}`,
        getQualitometerTelecomAssignment: id => `${path}materiel/telecom/qualitometer/station/${id}`,
        getInstallationTelecomAssignment: id => `${path}materiel/telecom/installation/station/${id}`,
        getHydrometryVariousMaterielAssignment: id => `${path}materiel/variousMateriel/hydrometry/station/${id}`,
        getPiezometerVariousMaterielAssignment: id => `${path}materiel/variousMateriel/piezometer/station/${id}`,
        getPluviometerVariousMaterielAssignment: id => `${path}materiel/variousMateriel/pluviometer/station/${id}`,
        getQualitometerVariousMaterielAssignment: id => `${path}materiel/variousMateriel/qualitometer/station/${id}`,
        getInstallationVariousMaterielAssignment: id => `${path}materiel/variousMateriel/installation/station/${id}`,
        getHydrometryEquipmentAssignment: id => `${path}materiel/equipment/hydrometry/station/${id}`,
        getPiezometerEquipmentAssignment: id => `${path}materiel/equipment/piezometer/station/${id}`,
        getPluviometerEquipmentAssignment: id => `${path}materiel/equipment/pluviometer/station/${id}`,
        getQualitometerEquipmentAssignment: id => `${path}materiel/equipment/qualitometer/station/${id}`,
        getInstallationEquipmentAssignment: id => `${path}materiel/equipment/installation/station/${id}`,

        getHydrometrySubscriptionAssignment: id => `${path}materiel/subscription/hydrometry/station/${id}`,
        getPiezometerSubscriptionAssignment: id => `${path}materiel/subscription/piezometer/station/${id}`,
        getPluviometerSubscriptionAssignment: id => `${path}materiel/subscription/pluviometer/station/${id}`,
        getQualitometerSubscriptionAssignment: id => `${path}materiel/subscription/qualitometer/station/${id}`,
        getInstallationSubscriptionAssignment: id => `${path}materiel/subscription/installation/station/${id}`,

        campaignMateriels: id => `${path}materiel/campaign/${id}`,
        type: () => `${path}materiel/type`,
    },
    keyfigures: {
        keyFigurePath() {
            return `${path}keyfigure/`
        },
        quality() {
            return `${this.keyFigurePath()}quality`
        },
        piezometry() {
            return `${this.keyFigurePath()}piezometry`
        },
        pluviometry() {
            return `${this.keyFigurePath()}pluviometry`
        },
        hydrometry() {
            return `${path}hydrologicalStation/keyfigure/`
        },
        getCountObservationsHydro: () => `${path}hydrologicalStation/countObservation/`,
        user() {
            return `${this.keyFigurePath()}user`
        },
    },
    operation: {
        operationPath: () => `${path}operation/`,
        operations: (ids = []) => `${path}operation/${ids.length ? `?ids=${ids.join(',')}` : ''}`,
        stationOperations: id => `${path}operation/${id}`,
        get: (id, codeStation) => `${path}operation/${codeStation}/${id}`,
        sample: (qualitoId, operationId) => `${path}qualitometer/${qualitoId}/sampleByOperationId/${operationId}`,
        samplings: (qualitoId, numSample) => `${path}qualitometer/${qualitoId}/sample/${numSample}/samplings`,
        networks: (qualitoId, operationId) => `${path}qualitometer/${qualitoId}/operation/${operationId}/networks`,
        analysisCreate: () => `${path}analysis/create`,
        analysisUpdate: () => `${path}analysis/update`,
        analysisDelete: () => `${path}analysis/delete`,
        analysis: (idOperation, idStation) => `${path}operation/${idStation}/analysis/${idOperation}`,
        groupAnalysis: (ids, codeStation) => `${path}operation/${codeStation}/analysis/?idOperations=${ids}`,
        validation() {
            return `${this.operationPath()}validation`
        },
        indices(qualitometerId, operationId) {
            return `${this.operationPath()}indices/${qualitometerId}/${operationId}`
        },
    },
    arrest: {
        get(departments = []) {
            return `${path}risk/arrest?departments=${departments}`
        },
    },
    vigilance: {
        get(departments = []) {
            return `${path}risk/vigilance?country=FR&departments=${departments}`
        },
    },
    analysis: {
        default: () => `${path}analysis`,
        searchAnalysisObjects: () => `${path}analysis`,
        searchAnalysis: () => `${path}analysis?lightMode=true`,
        searchQualityHydrobios: () => `${path}analysis/searchTaxons`,
        specific: () => `${path}analysis/specific`,
        searchIndices() {
            return `${path}analysis/searchIndices`
        },
        qualityIndicators() {
            return `${path}analysis/thresholdStation`
        },
        qualityIndicatorsResults(qualitometerId) {
            return `${path}analysis/qualityIndicator/results/${qualitometerId}`
        },
        errorType: () => `${path}analysis/errorType`,
        conformity: () => `${path}analysis/conformity`,
        control: () => `${path}analysis/control`, // updated version of conformity
    },
    environmentalCondition: {
        default: () => `${path}environmentalCondition`,
    },
    export: {
        export() {
            return `${path}export/`
        },
        environmentModels() {
            return `${this.export()}environmentModels`
        },
        installationsFull() {
            return `${this.export()}installations/full`
        },
        environmentModelsByType: (stationType) => `${path}export/environmentModels/${stationType}`,
        exportData: () => `${path}export/data`,
        exportZipData: () => `${path}export/zip/data`,
        exportZipJobFiles: () => `${path}export/zip/job/files`,
        kmlExport() {
            return `${path}layer/kmlExport`
        },
        exportModel: () => `${path}export/model`,
        exportResearchModel: () => `${path}export/model/research`,
        exportResearchFullCSV: () => `${path}export/qualitySearch/csv`,
        exportAEP() {
            return `${this.export()}aep`
        },
        editionDeclaration(id, model) {
            return `${this.export()}edition/declaration/${id}/${model}`
        },
        editionSynthesis(id, model) {
            return `${this.export()}edition/synthesis/${id}/${model}`
        },
        editionNotification(idExploitation, year, model) {
            return `${this.export()}edition/notification/${idExploitation}/${year}/${model}`
        },
        editionInstallation(campaignCode, idInstallation) {
            return `${this.export()}edition/installation/${campaignCode}/${idInstallation}`
        },
        exportContactsSurvey(idSurvey) {
            return `${this.export()}contacts/survey/${idSurvey}`
        },
        exportConsosSurvey(idSurvey, fileType) {
            return `${this.export()}consos/survey/${idSurvey}/${fileType}`
        },
        exportUsagesRealSurvey(idSurvey, fileType) {
            return `${this.export()}usages/survey/${idSurvey}/${fileType}`
        },
        exportSurveyNoUses(idSurvey, fileType) {
            return `${this.export()}noUses/survey/${idSurvey}/${fileType}`
        },
        exportConsosAllSurvey(idSurvey, fileType) {
            return `${this.export()}consosAll/survey/${idSurvey}/${fileType}`
        },
        exportOasis() {
            return `${this.export()}edition/survey/oasis`
        },
        exportOasisDeclarations() {
            return `${this.export()}edition/declarations/oasis`
        },
        exportAgencySurvey(id, exportType) {
            return `${this.export()}agency/survey/${id}/${exportType}`
        },
        exportEditionJson(id) {
            return `${this.export()}edition/json/declaration/${id}`
        },
        exportEditionFullJson(id) {
            return `${this.export()}edition/json/synthesis/${id}`
        },
        exportEDILABOPlanning: () => `${path}export/edilabo/planning`,
        exportEDILABORai: () => `${path}export/edilabo/rai`,
        exportJobsTmp: (stationType) => `${path}station/export/tmp?stationType=${stationType}`,
        runExportTmp: () => `${path}station/export/tmp`,
        exploitationsServices: () => `${path}export/exploitations/services`,
        exploitationsConsos: (fileType) => `${path}export/exploitations/consos/${fileType}`,
        volumesHisto: (startYear, endYear, fileType) => `${path}export/volumes/histo/${startYear}/${endYear}/${fileType}`,
        parFull: (id, fileType) => `${path}export/par/full/${id}/${fileType}`,
        fetchStepBasinsExport: (id) => `${path}export/step/basins/${id}`,
        jasperExport: () => `${path}export/station/jasper`,
        catchmentEvolutions: () => `${path}export/catchment/evolution`,
        hydroObsCampaign: (id, fileType) => `${path}export/hydro/obs/campaign/${id}/${fileType}`,
    },
    picture: {
        picture() {
            return `${path}files/picture/`
        },
        stationPicture(code, stationType) {
            return `${this.picture()}${code}/${stationType}`
        },
        pictureCompressPath(pictureName) {
            return `${path}files/compressPicture/${pictureName}/taux/0.5`
        },
    },
    files: {
        file() {
            return `${path}files/`
        },
        stationFile(code, stationType) {
            return `${this.file()}document/${code}/${stationType}`
        },
        delete() {
            return `${this.file()}delete`
        },
        edit() {
            return `${this.file()}updateFileFtp`
        },
        stationDocumentUpload: () => `${path}files/uploadDocumentFtp`,
        stationPictureUpload() {
            return `${this.file()}uploadPictureFtp`
        },
        uploadPublicPicture() {
            return `${this.file()}uploadPicturePublicFtp`
        },
        uploadProfilePicture() {
            return `${this.file()}uploadProfilePictureFtp`
        },
        cartoUpload() {
            return `${this.file()}uploadCartoFtp`
        },
        operationFiles: (idStation, idOperation) => `${path}files/document/${idStation}/operation/${idOperation}`,
        getDocument(name) {
            return `${this.file()}document/${name}`
        },
        pictos() {
            return `${path}files/picto`
        },
        lastDocuments() {
            return `${this.file()}last`
        },
        uploadFtp() {
            return `${this.file()}uploadFtp`
        },
        getProfilePicture(name) {
            return `${this.file()}profilePicture/${name}`
        },
        getPublicPicture(name) {
            return `${this.file()}publicPicture/${name}`
        },
        getModelsFiles(stationType) {
            return `${this.file()}modelsftp/${stationType}`
        },
        uploadModelsFile() {
            return `${this.file()}uploadModelsFtp/`
        },
        readFTP: () => `${path}files/readFTP/`,
    },
    layers: {
        getLayers(id = '') {
            return `${path}layer/theme/${id}`
        },
        getAuthorizedThemes() {
            return `${path}theme/authorizations`
        },
        getThemes() {
            return `${path}theme`
        },
        getTheme(id) {
            return `${path}theme/${id}`
        },
        getKML(name) {
            return `${contentsPath}CARTO/${name}.kml`
        },
        getCityKML(townCode) {
            return `${aquaDBPath}administration/web/map/city/${townCode}`
        },
        getWatermassWFS(watermassCode) {
            return `${sandrePath}geo/mdo_VEDL_FXX?SERVICE=WFS&REQUEST=getFeature&VERSION=1.1.0&srsName=epsg%3A3857&TYPENAME=MasseDEauSouterraine&FILTER=%3CFilter%3E%3CPropertyIsEqualTo%3E%3CPropertyName%3ECdMasseDEa%3C/PropertyName%3E%3CLiteral%3E${watermassCode}%3C/Literal%3E%3C/PropertyIsEqualTo%3E%3C/Filter%3E&outputFormat=text/xml%3B%20subtype%3Dgml/3.1.1`
        },
        getWatermassKML(watermassCode) {
            return `${aquaDBPath}layers/masseEau/${watermassCode}.kml`
        },
        getSitesByRadius() {
            return `${postgrestPath}rpc/searchbyradius`
        },
        getSitesByInsee() {
            return `${postgrestPath}rpc/searchbycity`
        },
        getSitesTypes() {
            return `${postgrestPath}gns_sites_types?select=id,parameter,definition`
        },
        getCityCenter(townCodes) {
            return `${postgrestPath}gns_cities?select=code,x_coordinate,y_coordinate&code=in.(${townCodes})`
        },
        getWatershedFromCoordinates() {
            return `${postgrestPath}rpc/getstationwatershed`
        },
        getWatershedKML(watershedCode) {
            return `${aquaDBPath}layers/bassinVersant/${watershedCode}.kml`
        },
    },
    statistics: {
        hype() {
            return `${path}statistics/hype`
        },
    },
    station: {
        associatedStations: (code, stationType) => `${path}station/link/${code}/${stationType}?lightMode=true`,
        getStationsLink: () => `${path}station/link/`,
        getMonitoredStations: () => `${path}station/monitored`,
        problem: () => `${path}station/problemSolution/problem`,
        getStationEsu: () => `${path}station/esu`,
        solution: () => `${path}station/problemSolution/solution`,
        problemSolutionLinks: () => `${path}station/problemSolution/links`,
        problemSolution: () => `${path}station/problemSolution`,
        allContributors: (stationType) => `${path}station/contributorLink/${stationType}`,
        allDataTypes: () => `${path}station/datatypes`,
        dataTypesByProject: (project) => `${path}station/datatypes/${project}`,
        linkedStationEvents: (stationCode, stationType) => `${path}station/link/events/${stationCode}/${stationType}`,
        mapSituation: (stationType) => `${path}station/${stationType}/mapSituation`,
        mapSituationStats: (stationType, id) => `${path}station/${stationType}/mapSituation/stats/${id}`,
        mapSituationId: (stationType, id) => `${path}station/${stationType}/mapSituation/${id}`,
        mapSituationResults: (stationType, id, date) => `${path}station/${stationType}/mapSituationResults/${id}/${date}`,
        mapSituationDates: (stationType, mapId, month) => `${path}station/${stationType}/mapSituationResultsDate/${mapId}/${month}`,
        mapSituationStation: (stationType, mapId, stationId) => `${path}station/${stationType}/mapSituationResultsStation/${mapId}/${stationId}`,
        mapSituationStationLast: (stationType, stationId, maxDate) => `${path}station/${stationType}/mapSituationResultsStationLast/${stationId}${maxDate ? `&maxDate=${maxDate}` : ''}`,
        getIAEauModels: (stationType, stationId) => `${path}${stationType}/${stationId}/models`,
        IAEauModel: (stationType, stationId) => `${path}${stationType}/${stationId}/model`,
        launchIAEauModel: (stationType, stationId) => `${path}${stationType}/${stationId}/launchModel`,
        getMeteoNappesModelId: (pizeoId) => `${path}piezometer/${pizeoId}/meteoNappesModel`,
        getPredStats: (stationType, stationId) => `${path}${stationType}/${stationId}/pred/stats`,
        getPredMeasuresWithHorizon: (stationType, stationId) => `${path}${stationType}/${stationId}/pred/measuresWithHorizon`,
        observatoryFollowResults: stationType => `${path}${stationType}/situation/observatory`,
        watermassLinksWithNames: stationType => `${path}station/${stationType}/referential/watermassLinks`,
        aquiferLinksWithNames: stationType => `${path}station/${stationType}/referential/aquiferLinks`,
        levelMinMaxStats: stationType => `${path}station/${stationType}/levelMinMaxStats`,
        pointPrelDataExists: () => `${path}station/verifyPointPrelExists`,
    },
    statisticApplication: {
        statisticApplication() {
            return `${path}statisticApplication/`
        },
        putUsagePiezometry() {
            return `${this.statisticApplication()}usage/piezometry`
        },
        putUsageQuality() {
            return `${this.statisticApplication()}usage/quality`
        },
    },
    pollution: {
        activitiesParams() {
            return `${path}risk/pollution/source/activites`
        },
        parameters(activity) {
            return `${path}risk/pollution/source/parameters/${activity}`
        },
        params() {
            return `${path}risk/pollution/source/params`
        },
    },
    alert: {
        testMail: () => `${path}risk/testMail`,
    },
    iaeau: {
        executeChainSync(chainId) {
            return `${iaeauApi}scheduler/sieau/chain/${chainId}`
        },
        stationIAEauLearning(stationId, stationType) {
            return `${path}station/iaeaulearning/${stationType}/${stationId}`
        },
        iaeauLearning() {
            return `${path}station/iaeaulearning/`
        },
        genericMeasures() {
            return `${path}risk/genericMeasures`
        },
        modelMeasures: (stationType, stationId) => `${path}${stationType}/measure/${stationId}/model/results`,
        modelResultDates: (stationType, stationId) => `${path}${stationType}/${stationId}/model/resultDates`,
        launchIAEauModelSync: () => `${path}risk/iaeau/model/executeSync`,
        launchIAEauModelHot: () => `${path}risk/iaeau/model/executeHot`,
    },
    towns: {
        getByCoordinate: (x, y) => `${geoApiPath}communes?lat=${y}&lon=${x}&fields=nom,code,codesPostaux,codeDepartement,codeRegion,population&format=json&geometry=centre`,
    },
    parcel: {
        getParcelAndSectionByCoordinate: (x, y) => `${aqua6BODataPath}/apicarto/parcelAndSection?xCoordinate=${x}&yCoordinate=${y}`,
    },
    message: {
        getUserMessages: login => `${path}user/messages/login/${login}`,
        lastMessages: () => `${path}user/messages/last`,
        sendMessage: () => `${path}user/messages`,
    },
    catchment: {
        catchments: () => `${path}catchment`,
        catchment: id => `${path}catchment/${id}`,
        points: () => `${path}catchment/point`,
        sumAnalysis: () => `${path}catchment/sumAnalysis`,
        countGroupParameters: () => `${path}catchment/groupParameter/count`,
    },
    agri: {
        agri() {
            return `${path}agri/`
        },
        pars() {
            return `${this.agri()}pars`
        },
        createPar() {
            return `${this.agri()}par`
        },
        par(id) {
            return `${this.agri()}par/${id}`
        },
        validatePar(id, attributionDate) {
            return `${this.agri()}par/validate/${id}/${attributionDate}`
        },
        rulesScenarios() {
            return `${this.agri()}rulesScenarios`
        },
        ruleScenarios(id) {
            return `${this.agri()}rulesScenarios/${id}`
        },
        scenarios() {
            return `${this.agri()}scenarios`
        },
        scenario(id) {
            return `${this.agri()}scenario/${id}`
        },
        createSurvey() {
            return `${this.agri()}survey`
        },
        duplicateSurvey() {
            return `${this.agri()}survey/duplicate/`
        },
        folder(id) {
            return `${this.agri()}dossier/${id}`
        },
        survey(id) {
            return `${this.agri()}survey/${id}`
        },
        surveyExportFull(id) {
            return `${this.agri()}survey/${id}/exportfull`
        },
        surveyParams(id) {
            return `${this.agri()}survey/${id}/params`
        },
        surveyRecalculateVolumes(id) {
            return `${this.agri()}survey/${id}/recalculateVolumes`
        },
        surveyValidateVolumes(id, authorisationDate) {
            return `${this.agri()}survey/${id}/validateVolumes/${authorisationDate}`
        },
        surveys() {
            return `${this.agri()}surveys`
        },
        droughtSectorsRestrictions() {
            return `${this.agri()}droughtSectorsRestrictions`
        },
        summarySurveys() {
            return `${this.agri()}surveys/summary`
        },
        surveysWithStats() {
            return `${this.agri()}surveys/stats`
        },
        exploitations() {
            return `${this.agri()}exploitations`
        },
        exploitationsExportFull() {
            return `${this.agri()}exploitations/exportfull`
        },
        exploitationsLight() {
            return `${this.agri()}exploitations/light`
        },
        matsRepartition() {
            return `${this.agri()}exploitations/matsRepartition`
        },
        exploitation() {
            return `${this.agri()}exploitation`
        },
        exploitationId(id) {
            return `${this.agri()}exploitation/${id}`
        },
        exploitationVolumes(id) {
            return `${this.agri()}exploitation/${id}/volumes`
        },
        declaration(id) {
            return `${this.agri()}declaration/${id}`
        },
        declarationExploitation(id) {
            return `${this.agri()}declaration/exploitation/${id}`
        },
        lastDeclarationExploitation(id) {
            return `${this.agri()}declaration/last/exploitation/${id}`
        },
        declarationsExploitation(id) {
            return `${this.agri()}declarations/exploitation/${id}`
        },
        getDeclarationContributors(id) {
            return `${this.agri()}declaration/updatedContributors/${id}`
        },
        declarationContributor() {
            return `${this.agri()}declaration/updatedContributor`
        },
        modesIrrigations() {
            return `${this.agri()}modesIrrigations`
        },
        calculVolume() {
            return `${this.agri()}declaration/calculatedVolume`
        },
        changeAdministrator(id) {
            return `${this.agri()}/admin/exploitation/${id}`
        },
        getManagementUnitsVolumesByDate(type, date, endDate) {
            return `${this.agri()}volumes/uge/${type}/${date}/${endDate}`
        },
        getManagementUnitsDetailsVolumesByDate(id, date, endDate) {
            return `${this.agri()}volumes/details/uge/${id}/${date}/${endDate}`
        },
        calculPointVolumeReal(id, idExploitation, startDate, endDate) {
            return `${this.agri()}point/${id}/calcVolumeReal/${idExploitation}/${startDate}/${endDate}`
        },
        getBreakdownSurvey(id) {
            return `${this.agri()}survey/${id}/breakdown`
        },
        getForecastUge(idUge, year) {
            return `${this.agri()}uge/forecast/${idUge}/${year}`
        },
        getForecastSurvey() {
            return `${this.agri()}survey/forecast`
        },
        getActualUseSurvey(id) {
            return `${this.agri()}survey/${id}/actualuse`
        },
        getActualConsumptionSurvey(id) {
            return `${this.agri()}survey/${id}/actualconsumption`
        },
        calculSurveyExploitationVolumes(idSurvey, idExploitation) {
            return `${this.agri()}survey/${idSurvey}/calcVolumesExploitation/${idExploitation}`
        },
        validateDeclarations() {
            return `${this.agri()}declarations/validate`
        },
        fetchDeclaration(idExploitation) {
            return `${this.agri()}declaration/${idExploitation}`
        },
        services() {
            return `${this.agri()}services`
        },
        service(idService) {
            return `${this.agri()}service/${idService}`
        },
        allExploitationsEvents() {
            return `${this.agri()}allEvents/exploitations`
        },
        exploitationEvents(idExploitation) {
            return `${this.agri()}events/exploitation/${idExploitation}`
        },
        exploitationsEvents() {
            return `${this.agri()}events/exploitations`
        },
        parEvents(idPAR) {
            return `${this.agri()}events/par/${idPAR}`
        },
        allSurveysEvents() {
            return `${this.agri()}allEvents/surveys`
        },
        surveyEvents(idSurvey) {
            return `${this.agri()}events/survey/${idSurvey}`
        },
        surveysEvents() {
            return `${this.agri()}events/surveys`
        },
        restrictions() {
            return `${this.agri()}waterTurns/restrictions`
        },
        slots() {
            return `${this.agri()}waterTurns/slots`
        },
        waterTurns() {
            return `${this.agri()}waterTurns`
        },
        addWaterTurns() {
            return `${this.agri()}waterTurns/add`
        },
        controlSurveyDeclarations() {
            return `${this.agri()}survey/control`
        },
        servicesSituations() {
            return `${this.agri()}services/situations`
        },
        calculateServicesSituation() {
            return `${this.agri()}services/calculate`
        },
    },
    perimeters: {
        perimetersFolders: () => `${path}perimeters/folders/`,
        getPerimeters: id => `${path}perimeters/${id}`,
        perimeters: () => `${path}perimeters`,
        getParcels: perimeter => `${path}perimeters/parcels/${perimeter.folderId}/${perimeter.perimeterId}`,
        getOwnersGroups: (folderId, perimeterId) => `${path}perimeters/parcels/${folderId}/${perimeterId}/ownerGroups`,
        parcel: () => `${path}perimeters/parcel`,
        searchParcels: () => `${path}perimeters/parcels/search`,
        searchGroupsOwners: (group, cityCode) => `${path}perimeters/ownerGroups/${group}/${cityCode}`,
        getOwnerGroups: (communalAccount, cityCode) => `${path}perimeters/ownersGroups/${communalAccount}/${cityCode}`,
        deeds: () => `${path}perimeters/deeds`,
        getDeed: id => `${path}perimeters/deed/${id}`,
        deed: () => `${path}perimeters/deed`,
        deedOwner: () => `${path}perimeters/deedOwner`,
        getPerimeterFolderLastEvent: folderId => `${path}perimeters/folders/${folderId}/lastEvent`,
        getPerimetersFolderPrivateOwners: folderId => `${path}perimeters/owners/private/${folderId}`,
        getPerimetersFolderCompanyOwners: folderId => `${path}perimeters/owners/company/${folderId}`,
    },
}
