import PropTypes from 'prop-types'
import React, { Component } from 'react'
import i18n from 'simple-react-i18n'
import Checkbox from '../../../../components/forms/Checkbox'
import Job from '../../../dto/DtoJob'

class ActivityPanel extends Component {
    componentDidMount() {
        this.onChangeFilters({})
    }

    getFilters = () => {
        const newFilters = this.props.job.parameters.filters.length ? JSON.parse(this.props.job.parameters.filters[0]) : {}
        return { purge: false, ...newFilters }
    }

    onChangeFilters = (changes) => {
        const parameters = {
            ...this.props.job.parameters,
            filters: [JSON.stringify({
                ...this.getFilters(),
                ...changes,
            })],
        }
        this.props.onChangeJob({ parameters })
    }

    render() {
        const disabled = { disabled: !this.props.isEditMode }
        const filters = this.getFilters()
        return (
            <div>
                <div className='row no-margin valign-wrapper'>
                    <Checkbox checked={ filters.purge } label={ i18n.overwriteReferential } onChange={ v => this.onChangeFilters({ purge: v }) } { ...disabled }/>
                </div>
            </div>
        )
    }
}

ActivityPanel.propTypes = {
    job: PropTypes.instanceOf(Job).isRequired,
    isEditMode: PropTypes.bool,
    onChangeJob: PropTypes.func,
}

export default ActivityPanel