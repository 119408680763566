export default class DtoExportOverview {
    constructor(obj) {
        this.stationId = obj.stationId
        this.code = obj.code
        this.name = obj.name
        this.city = obj.city
        this.num = obj.num
        this.startDate = obj.startDate
        this.endDate = obj.endDate

        this.exportDate = obj.exportDate
        this.exportStatus = obj.exportStatus
        this.exportColor = obj.exportColor
        this.exportExecId = obj.exportExecId

        this.ackDate = obj.ackDate
        this.ackStatus = obj.ackStatus
        this.ackColor = obj.ackColor
        this.ackExecId = obj.ackExecId

        this.lastExecDate = obj.lastExecDate
        this.lastExecStatus = obj.lastExecStatus
        this.lastExecColor = obj.lastExecColor
        this.lastExecId = obj.lastExecId

        this.exportInProgress = obj.exportInProgress
    }
}
