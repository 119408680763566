export default class DtoContributor {
    constructor(obj) {
        this.id = obj.id
        this.name = obj.name
        this.siret = obj.type
        this.creationDate = obj.creationDate
        this.road = obj.road
        this.department = obj.department
        this.domain = obj.domain
        this.email = obj.email
        this.phoneTel = obj.phoneTel
        this.cityCode = obj.cityCode
        this.countryCode = obj.countryCode
    }
}