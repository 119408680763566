import DtoLocation from '../../../station/dto/DtoLocation'

export default class DtoInstallationLight {
    constructor(obj = []) {
        this.id = obj[0] // Int,
        this.code = obj[1] // Option[String],
        this.name = obj[2] // Option[String],
        this.installationType = obj[3] // Option[Int],
        this.x = obj[4] // Option[Double],
        this.y = obj[5] // Option[Double],
        this.projection = obj[6] // Option[Double],
        this.townCode = obj[7] // Option[String],

        this.number = obj[0]
        this.typeName = 'installation'
        this.localisation = new DtoLocation({
            x: obj[4],
            y: obj[5],
            projection: obj[6],

        })
    }
}