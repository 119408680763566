/* eslint-disable camelcase */
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import Table from '../../../../components/datatable/Table'
import SieauAction from '../../../../components/sieau/SieauAction'
import { SANDRE } from '../../../../referencial/constants/ReferencialConstants'
import { getDate } from '../../../../utils/DateUtil'
import { getExport, setModal } from '../../../../utils/linkUtils'
import { arrayOf } from '../../../../utils/StoreUtils'
import { getSandreLabel } from '../../../../utils/StringUtil'
import DtoSandreCode from '../../../dto/DtoSandreCode'
import ContributorDto from '../dto/ContributorDto'
import CodificationModal from './modal/CodificationModal'


const ReferencialCodificationPanel = ({
    contributor = {},
    readMode = false,
    onChange = () => {},
    setPopup = () => {},
    sandreCodes = [],
    title,
}) => {
    const contributorCodifications = contributor.link_codificationContributors ? contributor.link_codificationContributors.map((c, index) => ({
        ...c,
        index,
    })) : []
    const tableData = contributorCodifications.map(c => ({
        ...c,
        startDate: getDate(c.startDate),
        endDate: getDate(c.endDate),
        codificationType: getSandreLabel(sandreCodes, SANDRE.INTERVENANTS_TYPE_CODIFICATION, c.codificationType),
    }))
    const headers = ['codificationType', 'codification', 'startDate', 'endDate']
    const exportAction = getExport(tableData, `${contributor.name} - ${i18n.codification}`, headers)
    const actions = !readMode ? [{
        iconName: 'note_add',
        tooltip: i18n.add,
        onClick: () => setModal(i18n.newCodification, <CodificationModal id={ contributor.code } saveResult={ r => onChange({ link_codificationContributors: [...contributorCodifications, r] }) }/>, setPopup),
    }, exportAction] : [exportAction]

    return (
        <Table
            title={title || i18n.others}
            condensed
            className='blue'
            data={ tableData }
            type={{ headers }}
            customHeaders={{
                codificationType: i18n.type,
                codification: i18n.value,
                startDate: i18n.begin,
                endDate: i18n.end,
            }}
            sortable={ !!tableData.length }
            actions={ actions }
            alterable={ !readMode }
            onAlter={
                (element) => setModal(i18n.editCodification,
                    <CodificationModal
                        id={ contributor.code }
                        codification={ contributorCodifications.find(c => element.index === c.index) }
                        saveResult={ r => onChange({
                            link_codificationContributors: [
                                ...contributorCodifications.filter(lc => lc.index !== element.index),
                                { ...r, index: element.index },
                            ],
                        })}
                    />
                    , setPopup)
            }
            deletable={ !readMode }
            onDelete={ (element) => onChange({ link_codificationContributors: contributorCodifications.filter(lc => lc.index !== element.index) }) }
        />
    )
}

ReferencialCodificationPanel.propTypes = {
    contributor: PropTypes.instanceOf(ContributorDto),
    sandreCodes: arrayOf(DtoSandreCode),
    readMode: PropTypes.bool,
    onChange: PropTypes.func,
    setPopup: PropTypes.func,
    title: PropTypes.string,
}

const mapStateToProps = store => ({
    sandreCodes: store.ReferencialReducer.sandreCodes,
})

const mapDispatchToProps = {
    setPopup: SieauAction.setPopup,
}

export default connect(mapStateToProps, mapDispatchToProps)(ReferencialCodificationPanel)