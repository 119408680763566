import PropTypes from 'prop-types'
import React from 'react'

const ColorfulCard = ({
    objectId,
    color,
    className = '',
    onClick = undefined,
    onMouseOver = () => {},
    onMouseOut = () => {},
    children,
}) => (
    <div
        onClick={onClick}
        className={`card colorful-card ${onClick ? 'clickable' : ''} ${className} margin-top-0`}
        onMouseOver={() => onMouseOver(objectId)}
        onMouseOut={() => onMouseOut(objectId)}
        style={{ borderRadius: 5, overflow: 'hidden', backgroundColor: `${color?.startsWith('#') ? color : color?.toLowerCase?.()}` }}
    >
        <div className='card-content no-padding' style={{ backgroundColor: 'white', width: '99%', float: 'right', overflow: 'hidden' }}>
            {children}
        </div>
    </div>
)

ColorfulCard.propTypes = {
    objectId: PropTypes.object,
    color: PropTypes.string,
    className: PropTypes.string,
    onClick: PropTypes.func,
    onMouseOver: PropTypes.func,
    onMouseOut: PropTypes.func,
    children: PropTypes.element.isRequired,
}

export default ColorfulCard
