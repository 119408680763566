import AccountAction from 'account/actions/AccountAction'
import { push } from '@lagunovsky/redux-react-router'
import { every, flatten, isNil, uniqBy } from 'lodash'
import { QualityActionConstant } from 'quality/reducers/QualityReducer'
import { ContactActionConstant } from 'referencial/components/contact/reducers/ContactReducer'
import { ContributorActionConstant } from 'referencial/components/contributor/reducers/ContributorReducer'
import i18n from 'simple-react-i18n'
import AppStore from 'store/AppStore'
import ToastrAction from 'toastr/actions/ToastrAction'
import WaitAction from 'wait/WaitAction'
import ApplicationConf from '../../conf/ApplicationConf'
import { contentsPath, path } from '../../conf/basepath'
import { PATH_ADMINISTRATION, PATH_ADMINISTRATION_CARTOGRAPHY } from '../../home/constants/RouteConstants'
import LogAction from '../../log/actions/LogAction'
import QualityAction from '../../quality/actions/QualityAction'
import ContactAction from '../../referencial/components/contact/actions/ContactAction'
import ContributorAction from '../../referencial/components/contributor/actions/ContributorAction'
import ParameterAction from '../../referencial/components/parameter/actions/ParameterAction'
import SupportAction from '../../referencial/components/support/actions/SupportAction'
import UnitAction from '../../referencial/components/unit/actions/UnitAction'
import StationAction from '../../station/actions/StationAction'
import {
    checkAuth,
    checkError,
    genericFetch,
    genericPromise,
    getAuthorization,
    getJson,
    promiseAllProgress,
} from '../../utils/ActionUtils'
import { getDate } from '../../utils/DateUtil'
import { exportFile } from '../../utils/ExportDataUtil'
import { getLocalStorageJson, setLocalStorageJson } from '../../utils/FormUtils'
import {
    ADD_SAVED_RESEARCH_CRITERIAS,
    CREATE_GLOBAL_PARAMETER,
    DELETE_GLOBAL_PARAMETER,
    NAME_MENU_PICTURE, RECEIVE_ADMIN_THEMES, RECEIVE_ADMIN_THEME_LAYERS, RECEIVE_ALL_MODELS_BY_TYPE,
    RECEIVE_APPLICATION_SETTINGS,
    RECEIVE_CARTOGRAPHY_THEMES,
    RECEIVE_GLOBAL_PARAMETERS,
    RECEIVE_LOGIN_PICTURE,
    RECEIVE_MENU_PICTURE,
    RECEIVE_PICTOS,
    RECEIVE_PROFILE_LOGIN_PICTURE,
    RECEIVE_PROFILE_PICTURE,
    RECEIVE_SAVED_RESEARCH_CRITERIAS,
    RECEIVE_SELECTED_SEARCH_VALUES,
    RECEIVE_SETTINGS,
    RECEIVE_THEME,
    RESET_ADMINISTRATION,
    RESET_CACHE,
    RESET_MAP_CHECBOXES,
    RESET_PROFILE_LOGIN_PICTURE,
    RESET_PROFILE_PICTURE,
    RESET_THEME,
    SET_CACHE,
    UPDATE_GLOBAL_PARAMETER,
    RECEIVE_USER_SETTINGS,
    RECEIVE_APPLICATIVE_USER_SETTINGS,
    RESET_USER_SETTINGS,
    RECEIVE_SETTING,
} from '../constants/AdministrationConstants'


const AdministrationAction = {
    receiveSavedResearchCriterias(criterias) {
        return { type: RECEIVE_SAVED_RESEARCH_CRITERIAS, criterias }
    },
    promiseSavedResearchCriterias() {
        return fetch(ApplicationConf.qualitometer.allResearchCriterias(), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
    },
    fetchSavedResearchCriterias: () => dispatch => AdministrationAction.promiseSavedResearchCriterias()
        .then(json => {
            dispatch(AdministrationAction.receiveSavedResearchCriterias(json))
        })
        .catch(err => {
            dispatch(LogAction.logError(`${i18n.fetchError + i18n.savedResearchCriterias} : ${err}`))
            dispatch(ToastrAction.error(i18n.fetchError + i18n.savedResearchCriterias))
        }),
    addSavedResearchCriterias(criteriasObj) {
        return (dispatch) => {
            return fetch(ApplicationConf.qualitometer.allResearchCriterias(), {
                method: 'POST',
                headers: getAuthorization(),
                body: JSON.stringify(criteriasObj),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(json => {
                    if (json.insert === 1 && json.id) {
                        dispatch({ type: ADD_SAVED_RESEARCH_CRITERIAS, criteria: { ...criteriasObj, listCode: json.id } })
                    } else {
                        throw new Error()
                    }
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.addError + i18n.savedResearchCriterias} : ${err}`))
                    dispatch(ToastrAction.error(i18n.addError + i18n.savedResearchCriterias))
                })
        }
    },
    updateSavedResearchCriterias(id, criteriasObj) {
        return (dispatch) => {
            return fetch(ApplicationConf.qualitometer.researchCriterias(id), {
                method: 'PUT',
                headers: getAuthorization(),
                body: JSON.stringify(criteriasObj),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(json => {
                    if (json.update === 1 && json.id) {
                        dispatch({ type: ADD_SAVED_RESEARCH_CRITERIAS, criteria: { ...criteriasObj, listCode: json.id } })
                    } else {
                        throw new Error()
                    }
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.addError + i18n.savedResearchCriterias} : ${err}`))
                    dispatch(ToastrAction.error(i18n.addError + i18n.savedResearchCriterias))
                })
        }
    },
    deleteSavedResearchCriterias(criteriasObjId) {
        return dispatch => {
            return fetch(ApplicationConf.qualitometer.researchCriterias(criteriasObjId), {
                method: 'DELETE',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(json => {
                    if (json.delete >= 1) {
                        dispatch(AdministrationAction.receiveSavedResearchCriterias(AppStore.getState().AdministrationReducer.savedResearchCriterias.filter(obj => obj.id !== criteriasObjId)))
                    } else {
                        throw new Error('')
                    }
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.deleteError + i18n.savedResearchCriterias} : ${err}`))
                    dispatch(ToastrAction.error(i18n.deleteError + i18n.savedResearchCriterias))
                })
        }
    },
    receiveSettings(settings) {
        return { type: RECEIVE_SETTINGS, settings }
    },
    promiseSettings() {
        return fetch(ApplicationConf.user.settings(), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
    },
    fetchSettings() {
        return (dispatch) => {
            return AdministrationAction.promiseSettings()
                .then(json => {
                    dispatch(AdministrationAction.receiveSettings(json))
                    // user settings are store in 2 reducers (AccountReducer and AdministrationReducer, why ?...), and 2 differents actions fetch them
                    // the next line avoid differences between the 2 reducers
                    dispatch(AccountAction.receiveAccountUserSettings(json))
                })
                .catch((err) => {
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.settings} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError + i18n.settings))
                })
        }
    },
    receiveSetting(setting) {
        return { type: RECEIVE_SETTING, setting }
    },
    promiseSetting(parameter) {
        return fetch(ApplicationConf.user.applicationSetting(parameter), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
    },
    fetchSetting(parameter) {
        return (dispatch) => {
            return AdministrationAction.promiseSetting(parameter)
                .then(json => {
                    dispatch(AdministrationAction.receiveSetting(json))
                    return json
                })
                .catch((err) => {
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.settings} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError + i18n.settings))
                })
        }
    },

    receiveApplicativeUserSettings(applicativeUserSettings) {
        return { type: RECEIVE_APPLICATIVE_USER_SETTINGS, payload: applicativeUserSettings }
    },

    receiveUserSettings(userSettings) {
        return { type: RECEIVE_USER_SETTINGS, payload: userSettings }
    },

    promiseUserSettings(login) {
        return fetch(ApplicationConf.user.saveSettings(login), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
    },

    fetchUserSettings(login, isApplicative = false) {
        return (dispatch) => {
            return AdministrationAction.promiseUserSettings(login)
                .then(json => {
                    if (isApplicative) {
                        dispatch(AdministrationAction.receiveApplicativeUserSettings(json))
                    } else {
                        dispatch(AdministrationAction.receiveUserSettings(json))
                    }
                    return json
                })
                .catch((err) => {
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.settings} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError + i18n.settings))
                })
        }
    },

    resetUserSettings() {
        return { type: RESET_USER_SETTINGS }
    },

    receiveApplicationSettings(settings) {
        return { type: RECEIVE_APPLICATION_SETTINGS, settings }
    },
    promiseApplicationSettings() {
        return fetch(ApplicationConf.user.applicationSettings(), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
    },
    fetchApplicationSettings() {
        return (dispatch) => {
            return AdministrationAction.promiseApplicationSettings()
                .then(json => {
                    dispatch(AdministrationAction.receiveApplicationSettings(json))
                })
                .catch((err) => {
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.settings} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError + i18n.settings))
                })
        }
    },
    receiveCartographyThemes(themes) {
        return { type: RECEIVE_CARTOGRAPHY_THEMES, themes }
    },
    fetchAdminCartographyThemes() {
        return (dispatch) => {
            return fetch(ApplicationConf.layers.getThemes(), {
                method: 'GET',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then(json => {
                    dispatch(AdministrationAction.receiveAdminThemes(json))
                })
                .catch((err) => {
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.themes} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError + i18n.themes))
                })
        }
    },
    receiveAdminThemes: themes => ({ type: RECEIVE_ADMIN_THEMES, themes }),

    fetchThemeLayers: id => dispatch => {
        return fetch(ApplicationConf.layers.getLayers(id), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then((json = []) => {
                dispatch(AdministrationAction.receiveAdminThemeLayers(json))
            })
            .catch(err => {
                dispatch(LogAction.logError(`${i18n.fetchError + i18n.mapLayers} : ${err}`))
                dispatch(ToastrAction.error(i18n.fetchError + i18n.mapLayers))
            })
    },
    receiveAdminThemeLayers: layers => ({ type: RECEIVE_ADMIN_THEME_LAYERS, layers }),

    fetchTheme: id => dispatch => {
        return fetch(ApplicationConf.layers.getTheme(id), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then(json => {
                if (!json.error) {
                    dispatch(AdministrationAction.receiveTheme(json))
                    dispatch(AdministrationAction.fetchThemeLayers(id))
                }
            })
            .catch(err => {
                dispatch(LogAction.logError(`${i18n.fetchError + i18n.themes} : ${err}`))
                dispatch(ToastrAction.error(i18n.fetchError + i18n.themes))
            })
    },
    receiveTheme: theme => ({ type: RECEIVE_THEME, theme }),
    resetTheme: () => ({ type: RESET_THEME }),

    createTheme: theme => dispatch => {
        return fetch(ApplicationConf.layers.getThemes(), {
            method: 'POST',
            headers: getAuthorization(),
            body: JSON.stringify(theme),
        })
            .then(checkAuth)
            .then(getJson)
            .then(json => {
                if (json.error) {
                    dispatch(ToastrAction.error(i18n.createError + i18n.theme))
                } else {
                    dispatch(push(`${PATH_ADMINISTRATION}/${PATH_ADMINISTRATION_CARTOGRAPHY}/${json.id}`))
                    dispatch(ToastrAction.success(i18n.elementCreateSuccess))
                }
            })
            .catch(err => {
                dispatch(LogAction.logError(`${i18n.createError + i18n.theme} : ${err}`))
                dispatch(ToastrAction.error(i18n.createError + i18n.theme))
            })
    },
    uploadKMLFileTheme: (file) => dispatch => {
        return fetch(ApplicationConf.files.cartoUpload(), {
            method: 'POST',
            headers: getAuthorization(),
            body: JSON.stringify(file),
        })
            .then(checkAuth)
            .then(getJson)
            .then(json => checkError(json, {
                415: () => dispatch(ToastrAction.error(i18n.fileTypeNotAuthorized)),
            }))
            .then(json => {
                if (json.insert) {
                    dispatch(ToastrAction.success(i18n.elementAddSuccess))
                } else {
                    throw new Error(json)
                }
            }).catch((err) => {
                dispatch(LogAction.logError(`${i18n.addError + i18n.element} : ${err}`))
                dispatch(ToastrAction.error(i18n.addError + i18n.element))
            })
    },
    updateTheme: (id, theme) => dispatch => {
        return fetch(ApplicationConf.layers.getTheme(id), {
            method: 'put',
            headers: getAuthorization(),
            body: JSON.stringify(theme),
        })
            .then(checkAuth)
            .then(getJson)
            .then(json => {
                if (json.error) {
                    dispatch(ToastrAction.error(i18n.updateError + i18n.theme))
                } else {
                    dispatch(ToastrAction.success(i18n.elementUpdateSuccess))
                }
            })
            .catch(err => {
                dispatch(LogAction.logError(`${i18n.updateError + i18n.theme} : ${err}`))
                dispatch(ToastrAction.error(i18n.updateError + i18n.theme))
            })
    },
    deleteTheme: id => dispatch => {
        return fetch(ApplicationConf.layers.getTheme(id), {
            method: 'DELETE',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then(() => {
                dispatch(push(`${PATH_ADMINISTRATION}/${PATH_ADMINISTRATION_CARTOGRAPHY}`))
                dispatch(ToastrAction.success(i18n.elementDeleteSuccess))
            })
            .catch(err => {
                dispatch(LogAction.logError(`${i18n.deleteError + i18n.theme} : ${err}`))
                dispatch(ToastrAction.error(i18n.deleteError + i18n.theme))
            })
    },
    promiseCartographyThemes() {
        return fetch(ApplicationConf.layers.getAuthorizedThemes(), {
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
    },
    fetchCartographyThemes: () => dispatch => AdministrationAction.promiseCartographyThemes().then(json => dispatch(AdministrationAction.receiveCartographyThemes(json))),
    updateCartographyTheme(theme, isNew) {
        return (dispatch) => {
            if (isNew) {
                dispatch(AdministrationAction.receiveCartographyThemes(AppStore.getState().AdministrationReducer.cartographyThemes.concat([theme])))
            } else {
                const themes = AppStore.getState().AdministrationReducer.cartographyThemes.filter(t => t.id !== theme.id)
                dispatch(AdministrationAction.receiveCartographyThemes(themes.concat([theme])))
            }
        }
    },
    receivePictos(pictos) {
        return { type: RECEIVE_PICTOS, pictos }
    },
    promisePictos() {
        return fetch(ApplicationConf.files.pictos(), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
    },
    fetchPictos() {
        return (dispatch) => {
            AdministrationAction.promisePictos()
                .then(json => {
                    if (!json.error) {
                        dispatch(AdministrationAction.receivePictos(json))
                    }
                })
                .catch((err) => {
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.pictos} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError + i18n.pictos))
                })
        }
    },
    updateSetting(settingName, value, refreshData = true) {
        return (dispatch) => {
            const setting = AppStore.getState().AdministrationReducer.userSettings.find(s => s.parameter === settingName)
            return fetch(ApplicationConf.user.setting(), {
                method: (!isNil(setting?.value) && !!setting.login) ? 'PUT' : 'POST',
                headers: getAuthorization(),
                body: JSON.stringify({
                    parameter: settingName,
                    value,
                }),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(json => {
                    if (json.insert !== 1 && json.update !== 1) {
                        throw new Error()
                    } else if (refreshData) {
                        dispatch(AccountAction.fetchAccountUserSettings())
                    }
                })
                .catch((err) => {
                    dispatch(LogAction.logError(i18n.updateError + i18n.setting + err))
                    dispatch(ToastrAction.error(i18n.updateError + i18n.setting))
                })
        }
    },
    updateSettings(settingsobj, callback = () => { }) {
        return (dispatch) => {
            dispatch(WaitAction.waitStart())
            const fetchArray = Object.keys(settingsobj).map(settingName => {
                const setting = AppStore.getState().AdministrationReducer.settings.find(s => s.parameter === settingName)
                return fetch(ApplicationConf.user.setting(), {
                    method: (!isNil(setting?.value) && !!setting.login) ? 'PUT' : 'POST',
                    headers: getAuthorization(),
                    body: JSON.stringify({
                        parameter: settingName,
                        value: settingsobj[settingName],
                    }),
                }).then(checkAuth).then(getJson)
            })
            return Promise.all(fetchArray)
                .then(json => {
                    dispatch(WaitAction.waitStop())
                    if (every(json, jsonElem => jsonElem.insert === 1 || jsonElem.update === 1)) {
                        dispatch(AdministrationAction.fetchSettings())
                        dispatch(ToastrAction.success(i18n.settingsUpdateSuccess))
                        callback()
                    } else {
                        dispatch(LogAction.logError(i18n.updateError + i18n.setting))
                        dispatch(ToastrAction.error(i18n.updateError + i18n.setting))
                        callback()
                    }
                })
                .catch((err) => {
                    dispatch(WaitAction.waitStop())
                    dispatch(LogAction.logError(i18n.updateError + i18n.setting + err))
                    dispatch(ToastrAction.error(i18n.updateError + i18n.setting))
                })
        }
    },
    setSelectedSearchValues(stationType, object) {
        return { type: RECEIVE_SELECTED_SEARCH_VALUES, stationType, object }
    },
    setCache(stationType, object) {
        const allValues = getLocalStorageJson('CACHE_VALUES') || {}
        allValues[stationType] = { ...(allValues[stationType] || {}), ...object }
        setLocalStorageJson('CACHE_VALUES', allValues)
        return { type: SET_CACHE, stationType, object }
    },
    getCache(stationType) {
        return (getLocalStorageJson('CACHE_VALUES') || {})[stationType] || {}
    },
    resetCache() {
        setLocalStorageJson('CACHE_VALUES', {})
        return { type: RESET_CACHE }
    },
    resetMapCheckboxes() {
        return { type: RESET_MAP_CHECBOXES }
    },
    reset() {
        return { type: RESET_ADMINISTRATION }
    },
    updateSieauParameters(parameters, noToaster) {
        return (dispatch) => {
            dispatch(WaitAction.waitStart())
            return Promise.all(parameters.map(p => {
                return fetch(ApplicationConf.user.sieauParameters(p.parameter), {
                    method: 'PUT',
                    headers: getAuthorization(),
                    body: JSON.stringify({ parameter: p.value }),
                }).then(checkAuth).then(getJson).then(checkError)
            }))
                .then(() => {
                    dispatch(WaitAction.waitStop())
                    dispatch(AdministrationAction.fetchApplicationSettings())
                    if (!noToaster) {
                        dispatch(ToastrAction.success(i18n.generalParametersSuccess))
                    }
                })
                .catch((err) => {
                    dispatch(WaitAction.waitStop())
                    dispatch(LogAction.logError(i18n.updateError + i18n.parameters + err))
                    dispatch(ToastrAction.error(i18n.updateError + i18n.parameters))
                })
        }
    },
    promiseUpdatePasswordPolicy(passwordPolicy) {
        return fetch(ApplicationConf.user.sieauParameters('passwordPolicy'), {
            method: 'PUT',
            headers: getAuthorization(),
            body: JSON.stringify({
                parameter: passwordPolicy,
            }),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
    },
    putPasswordPolicy(passwordPolicy) {
        return (dispatch) => {
            return AdministrationAction.promiseUpdatePasswordPolicy(passwordPolicy)
                .then(() => {
                    dispatch(ToastrAction.success(i18n.generalParametersSuccess))
                })
                .catch((err) => {
                    dispatch(LogAction.logError(`${i18n.fetchError} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError))
                })
        }
    },

    exportRSEauHistory() {
        return genericPromise(`${path}risk/rseau/history`).then(json => {
            const results = json.results.map(r => {
                const date = getDate(r.date)
                return {
                    date,
                    parcelle: r.landId,
                    strategie: 'Obs',
                    totalEauEcon: r.sparedWaterAmount,
                    parsimonieScore: r.parsimonyScore,
                    contrepartieScore: r.counterpartScore,
                    stressScore: r.waterStressScore,
                    stressDelai: r.waterStressDay,
                    dose: (json.doses.find(d => date === getDate(d.date)) || {}).dose,
                    gainNote: r.ecoNote,
                }
            })
            const strat = json.strategies.map(r => ({
                date: getDate(r.date),
                parcelle: r.landId,
                strategie: r.name,
                dose: r.dose,
                gain: r.gain,
                gainNote: r.gainNote,
            }))
            results[0].headers = ['date', 'parcelle', 'strategie', 'dose', 'gain', 'gainNote', 'totalEauEcon', 'parsimonieScore', 'contrepartieScore', 'stressScore', 'stressDelai']
            exportFile({
                data: [...results, ...strat],
                exportType: 'xlsx',
                titleFile: 'RSEauHistory',
            }, true)
        })
    },

    purgeRSEauHistory() {
        return genericPromise(`${path}risk/rseau/history`, 'DELETE').then(json => {
            if (json.delete > 0) {
                AppStore.dispatch(ToastrAction.success(i18n.success))
            }
        })
    },

    loadAdministrationPoblemSolution(callback = () => { }, progressCallback = () => { }) {
        return (dispatch) => {
            return promiseAllProgress([
                StationAction.promiseProblems(),
                StationAction.promiseSolutions(),
                StationAction.promiseProblemSolutionLinks(),
            ], progressCallback).then(jsonTab => {
                dispatch(StationAction.receiveProblems(jsonTab[0]))
                dispatch(StationAction.receiveSolutions(jsonTab[1]))
                dispatch(StationAction.receiveProblemSolutionLinks(jsonTab[2]))
                callback()
            })
        }
    },

    loadAdministrationSelectionTable(callback = () => { }, progressCallback = () => { }) {
        return function (dispatch) {
            return Promise.all([
                ParameterAction.promiseSelections(),
                ParameterAction.promiseParameters(),
            ]).then(jsonTab => {
                dispatch(ParameterAction.receiveSelections(jsonTab[0]))
                dispatch(ParameterAction.receiveParameters(jsonTab[1]))
                if (jsonTab[0] && jsonTab[0].length) {
                    const codeTab = jsonTab[0].map(i => i.code)
                    QualityAction.promiseQualitySelections(codeTab, progressCallback)
                        .then((json = []) => {
                            const newSelections = json.map((selectionList, index) => ({
                                code: codeTab[index],
                                selections: selectionList,
                            }))
                            dispatch(QualityActionConstant.receiveQualitySelection(uniqBy(newSelections, 'code')))
                            callback()
                        })
                } else {
                    callback()
                }
            }).catch((err) => {
                dispatch(LogAction.logError(`${i18n.loadError} : ${err}`))
                dispatch(ToastrAction.error(i18n.loadError))
            })
        }
    },
    loadQualityThresholdsApp(callback = () => { }, progressCallback = () => { }) {
        return function (dispatch) {
            return Promise.all([
                ParameterAction.promiseParameters(),
                QualityAction.promiseThresholds(),
                UnitAction.promiseUnits(),
            ]).then(jsonTab => {
                dispatch(ParameterAction.receiveParameters(jsonTab[0]))
                dispatch(QualityActionConstant.receiveThresholds(jsonTab[1]))
                dispatch(UnitAction.receiveUnits(jsonTab[2]))
                if (jsonTab[1] && jsonTab[1].length) {
                    dispatch(QualityAction.fetchAllQualityThresholds(jsonTab[1], () => {
                        callback()
                    }, progressCallback))
                } else {
                    callback()
                }
            }).catch((err) => {
                dispatch(LogAction.logError(`${i18n.loadError} : ${err}`))
                dispatch(ToastrAction.error(i18n.loadError))
            })
        }
    },
    loadAdminQualitometerApp(callback = () => { }) {
        return function (dispatch) {
            dispatch(WaitAction.waitStart())
            return Promise.all([
                AdministrationAction.promiseSettings(),
                ContributorAction.promiseProducers(),
                ContributorAction.promiseContributors(),
                ContactAction.promiseContacts(),
                QualityAction.promiseStatus(),
                QualityAction.promiseQualifications(),
                SupportAction.promiseSupports(),
                ContributorAction.promiseLaboratories(),
                ContributorAction.promiseDeterminators(),
            ]).then(jsonTab => {
                dispatch(AdministrationAction.receiveSettings(jsonTab[0]))
                dispatch(ContributorActionConstant.receiveProducers(jsonTab[1]))
                dispatch(ContributorActionConstant.receiveAllContributors(jsonTab[2]))
                dispatch(ContactActionConstant.receiveAllContacts(jsonTab[3]))
                dispatch(QualityActionConstant.receiveStatus(jsonTab[4]))
                dispatch(QualityActionConstant.receiveQualifications(jsonTab[5]))
                dispatch(SupportAction.receiveSupports(jsonTab[6]))
                dispatch(ContributorActionConstant.receiveLaboratories(jsonTab[7]))
                dispatch(ContributorActionConstant.receiveDeterminators(jsonTab[8]))
                dispatch(WaitAction.waitStop())
                callback()
            }).catch((err) => {
                dispatch(WaitAction.waitStop())
                dispatch(LogAction.logError(`${i18n.loadError} : ${err}`))
                dispatch(ToastrAction.error(i18n.loadError))
            })
        }
    },

    updateParameters(parameters) {
        return function (dispatch) {
            return fetch(ApplicationConf.user.applicationSettings(), {
                method: 'POST',
                headers: getAuthorization(),
                body: JSON.stringify(parameters),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(json => {
                    if (json.update > 0) {
                        dispatch(ToastrAction.success(i18n.elementUpdateSuccess))
                    } else {
                        throw new Error(json)
                    }
                })
                .catch((err) => {
                    dispatch(LogAction.logError(`${i18n.updateError + i18n.installationType} : ${err}`))
                    dispatch(ToastrAction.error(i18n.updateError + i18n.installationType))
                })
        }
    },

    addPublicFiles(files) {
        return (dispatch) => {
            return Promise.all(files.map(file => {
                return fetch(ApplicationConf.files.uploadPublicPicture(), {
                    method: 'POST',
                    headers: getAuthorization(),
                    body: JSON.stringify(file),
                }).then(checkAuth).then(getJson).then(checkError)
            }))
                .then(json => {
                    if (json[0].insert) {
                        dispatch(ToastrAction.success(i18n.elementAddSuccess))
                    } else {
                        throw new Error(json)
                    }
                })
                .catch((err) => {
                    dispatch(LogAction.logError(`${i18n.addError + i18n.element} : ${err}`))
                    dispatch(ToastrAction.error(i18n.addError + i18n.element))
                })
        }
    },

    addProfilePicture(profilPicture) {
        return (dispatch) => {
            return fetch(ApplicationConf.files.uploadProfilePicture(), {
                method: 'POST',
                headers: getAuthorization(),
                body: JSON.stringify(profilPicture),
            }).then(checkAuth).then(getJson).then(checkError)
                .then((json = {}) => {
                    if (json.insert) {
                        dispatch(ToastrAction.success(i18n.elementAddSuccess))
                    } else {
                        throw new Error(json)
                    }
                })
                .catch((err) => {
                    dispatch(LogAction.logError(`${i18n.addError + i18n.element} : ${err}`))
                    dispatch(ToastrAction.error(i18n.addError + i18n.element))
                })
        }
    },

    promiseProfilePicture(name) {
        return fetch(ApplicationConf.files.getProfilePicture(name), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
    },

    resetProfilePicture() {
        return { type: RESET_PROFILE_PICTURE }
    },

    resetProfileLoginPicture() {
        return { type: RESET_PROFILE_LOGIN_PICTURE }
    },

    receiveProfilePicture(profilePicture) {
        return { type: RECEIVE_PROFILE_PICTURE, profilePicture }
    },

    receiveProfileLoginPicture(profileLoginPicture) {
        return { type: RECEIVE_PROFILE_LOGIN_PICTURE, profileLoginPicture }
    },

    fetchProfilePicture(name) {
        return (dispatch, getState) => {
            const {
                accountUser,
            } = getState().AccountReducer
            return AdministrationAction.promiseProfilePicture(name)
                .then((json = {}) => {
                    if (name === accountUser.login && json) {
                        dispatch(AdministrationAction.receiveProfileLoginPicture(json))
                    } else if (json) {
                        dispatch(AdministrationAction.receiveProfilePicture(json))
                    }
                })
                .catch((err) => {
                    if (!err.toString().includes('404')) {
                        dispatch(ToastrAction.error(i18n.fetchError + i18n.pictures))
                        dispatch(LogAction.logError(`${i18n.fetchError + i18n.pictures} : ${err}`))
                    }
                })
        }
    },

    receiveLoginPicture(loginPicture) {
        return { type: RECEIVE_LOGIN_PICTURE, loginPicture }
    },

    receiveMenuPicture(menuPicture) {
        return { type: RECEIVE_MENU_PICTURE, menuPicture }
    },

    fetchPublicPicture(name, stationType) {
        return (dispatch) => {
            return fetch(ApplicationConf.files.getPublicPicture(name), {
                method: 'GET',
                headers: {
                    ...getAuthorization(),
                    pragma: 'no-cache',
                    'Cache-Control': 'no-cache',
                },
            })
                .then(checkAuth)
                .then(getJson)
                .then((json = []) => {
                    if (stationType === NAME_MENU_PICTURE) {
                        dispatch(AdministrationAction.receiveMenuPicture(json))
                    } else {
                        dispatch(AdministrationAction.receiveLoginPicture(json))
                    }
                })
                .catch((err) => {
                    if (!err.toString().includes('404')) {
                        dispatch(ToastrAction.error(i18n.fetchError + i18n.pictures))
                        dispatch(LogAction.logError(`${i18n.fetchError + i18n.pictures} : ${err}`))
                    }
                })
        }
    },

    fetchPictureWithoutCache(name) {
        return (dispatch) => {
            return fetch(`${contentsPath}PHOTOS/${name}`, {
                method: 'GET',
                headers: {
                    pragma: 'no-cache',
                    'Cache-Control': 'no-cache',
                },
            })
                .then((response) => {
                    return response.url
                })
                .catch((err) => {
                    if (!err.toString().includes('404')) {
                        dispatch(ToastrAction.error(i18n.fetchError + i18n.pictures))
                        dispatch(LogAction.logError(`${i18n.fetchError + i18n.pictures} : ${err}`))
                    }
                })
        }
    },

    receiveAllModelsByType: allModelsByType => ({ type: RECEIVE_ALL_MODELS_BY_TYPE, allModelsByType }),

    fetchAllModelsByType: (stationsTypes) => {
        return (dispatch) => {
            return Promise.all(stationsTypes.map(stationType => {
                return fetch(ApplicationConf.files.getModelsFiles(stationType), {
                    method: 'GET',
                    headers: getAuthorization(),
                }).then(checkAuth).then(getJson).then(checkError).then((json) => json.map((j) => {
                    return { ...j, stationType }
                }))
            }))
                .then(json => dispatch(AdministrationAction.receiveAllModelsByType(flatten(json))))
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.fetchError} ${i18n.models} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError + i18n.models))
                })
        }
    },

    addModelFile(model) {
        return (dispatch) => {
            return fetch(ApplicationConf.files.uploadModelsFile(), {
                method: 'POST',
                headers: getAuthorization(),
                body: JSON.stringify(model),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(json => {
                    if (json.insert) {
                        return dispatch(ToastrAction.success(i18n.elementAddSuccess))
                    }
                    throw new Error(json)
                })
                .catch((err) => {
                    dispatch(LogAction.logError(`${i18n.addError + i18n.element} : ${err}`))
                    dispatch(ToastrAction.error(i18n.addError + i18n.element))
                })
        }
    },

    promiseGlobalParameters() {
        return genericPromise(ApplicationConf.referencial.globalParameters(), 'GET', null, { 403: () => [] })
    },
    fetchGlobalParameters() {
        return genericFetch(AdministrationAction.promiseGlobalParameters(), RECEIVE_GLOBAL_PARAMETERS)
    },

    promiseCreateGlobalParameter(body) {
        return genericPromise(ApplicationConf.referencial.globalParameters(), 'POST', body)
    },
    createGlobalParameter(body) {
        return genericFetch(AdministrationAction.promiseCreateGlobalParameter(body), CREATE_GLOBAL_PARAMETER)
    },

    promiseUpdateGlobalParameter(body) {
        return genericPromise(ApplicationConf.referencial.globalParameters(), 'PUT', body)
    },
    updateGlobalParameter(body) {
        return genericFetch(AdministrationAction.promiseUpdateGlobalParameter(body), UPDATE_GLOBAL_PARAMETER)
    },

    promiseDeleteGlobalParameter(body) {
        return genericPromise(ApplicationConf.referencial.globalParameters(), 'DELETE', body)
    },
    deleteGlobalParameter(body) {
        return genericFetch(AdministrationAction.promiseDeleteGlobalParameter(body), DELETE_GLOBAL_PARAMETER)
    },

    checkUrl: (url) => () => {
        return fetch(url)
            .then(checkAuth)
            .then((json) => {
                return json.status === 200
            })
            .catch(() => {
                return false
            })
    },
    sensSms: (smsApi, body) => dispatch => {
        return fetch(ApplicationConf.sensSms(smsApi), {
            method: 'POST',
            headers: getAuthorization(),
            body: JSON.stringify(body),
        })
            .then(checkAuth)
            .then(getJson)
            .then(json => {
                if (json.error) {
                    dispatch(ToastrAction.error(i18n.smsNotSent))
                } else {
                    dispatch(ToastrAction.success(i18n.smsSent))
                }
            })
            .catch(err => {
                dispatch(LogAction.logError(`${i18n.smsNotSent} : ${err}`))
                dispatch(ToastrAction.error(i18n.smsNotSent))
            })
    },
}

export default AdministrationAction
