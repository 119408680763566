import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { sortBy } from 'lodash'
import { connect } from 'react-redux'
import { push } from '@lagunovsky/redux-react-router'
import i18n from 'simple-react-i18n'
import HomeAction from 'home/actions/HomeAction'
import Checkbox from 'components/forms/Checkbox'
import ReferencialAppList from 'referencial/components/ReferencialAppList'
import AgriAction from 'agriAdministration/actions/AgriAction'
import DtoAgriTanksType from 'exploitations/dto/DtoAgriTanksType'
import { getDate } from 'utils/DateUtil'
import { hasValue } from 'utils/NumberUtil'
import ProgressCard from 'components/card/ProgressCard'

const headersList = ['id', 'name', 'eligibilityIcon', 'percent', 'updateDate', 'updateLogin']
const headersExport = ['id', 'name', 'eligibility', 'percent', 'updateDate', 'updateLogin']

class TerritoryTanksTypesApp extends Component {
    constructor(props) {
        super(props)
        this.state = {
            searchValue: '',
            agriTanksTypesFormatted: [],
            dataLoaded: false,
        }
    }

    componentDidMount = () => {
        this.setTanksTypes()
        this.props.setHelpLink('', '')
    }

    setTanksTypes = () => {
        this.props.fetchTanksTypes().then(() => {
            const { agriTanksTypes } = this.props
            const agriTanksTypesFormatted = sortBy(agriTanksTypes, 'id').map((t) => {
                return {
                    ...t,
                    updateDate: getDate(t.updateDate),
                    eligibility: t.eligibility ? i18n.yes : i18n.no,
                    eligibilityIcon: t.eligibility ? (<Checkbox col={ 12 } checked disabled />) : '',
                    percent: hasValue(t.percent) ? `${t.percent} %` : '',
                    headers: headersExport,
                }
            })
            this.setState({ agriTanksTypesFormatted, dataLoaded: true })
        })
    }

    render() {
        const { searchValue, agriTanksTypesFormatted, dataLoaded } = this.state
        const { agriTanksTypes } = this.props

        if (dataLoaded) {
            return (
                <ReferencialAppList
                    title={i18n.detentions}
                    data={agriTanksTypesFormatted}
                    type={{ headers: headersList }}
                    referenceData={agriTanksTypes}
                    newAction={() => this.props.push('/territory/settings/tank/new')}
                    link='territory/settings/tank'
                    setTitleAction={this.props.setTitle([{
                        title: i18n.steering,
                        href: 'territory',
                    }, {
                        title: i18n.settings,
                        href: 'territory/settings',
                    }, {
                        title: i18n.detentions,
                        href: 'territory/settings/tanks',
                    }])}
                    customHeaders={{
                        eligibilityIcon: i18n.eligibility,
                    }}
                    filter={searchValue}
                    showUpdateButton={false}
                    showPurgeButton={false}
                    showRefencialActionsButton={false}
                    round
                />
            )
        }
        return (
            <div className='padding-1'>
                <ProgressCard indeterminate withMessage round />
            </div>
        )
    }
}

TerritoryTanksTypesApp.propTypes = {
    agriTanksTypes: PropTypes.arrayOf(PropTypes.instanceOf(DtoAgriTanksType)),
    fetchTanksTypes: PropTypes.func,
    setTitle: PropTypes.func,
    setHelpLink: PropTypes.func,
    push: PropTypes.func,
}

const mapStateToProps = store => {
    return {
        agriTanksTypes: store.AgriReducer.agriTanksTypes,
    }
}

const mapDispatchToProps = {
    fetchTanksTypes: AgriAction.fetchTanksTypes,
    updateGlobalResearch: HomeAction.updateGlobalResearch,
    setHelpLink: HomeAction.setHelpLink,
    setTitle: HomeAction.setTitle,
    push,
}
export default connect(mapStateToProps, mapDispatchToProps)(TerritoryTanksTypesApp)
