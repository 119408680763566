'use strict'
import { RECEIVE_ALL_SUPPORTS, RECEIVE_SUPPORT, RESET_SUPPORT } from '../constants/SupportConstants'
import SupportDto from '../dto/SupportDto'
import SupportItem from '../dto/SupportItem'
import { createIndex } from '../../../../utils/StoreUtils'

export function SupportReducer(state = {}, action) {
    switch (action.type) {
        case RECEIVE_ALL_SUPPORTS:
            const supports = action.supports.map(support => new SupportItem(support))
            return Object.assign({}, state, {
                supports,
                supportsIndex: createIndex(supports),
            })
        case RECEIVE_SUPPORT:
            return Object.assign({}, state, {
                support: new SupportDto(action.support),
            })
        case RESET_SUPPORT:
            return Object.assign({}, state, {
                support: {},
            })
        default:
            return state
    }
}

export const store = {
    supports: [],
    support: {},
    supportsIndex: {},
}