export default class DtoOperationListSpecific {
    constructor(obj) {
        this.id = obj[0]
        this.date = obj[1]
        this.nbAnalysis = obj[2]
        this.nbAnalysisValidated = obj[3]
        this.status = obj[4]
        this.qualification = obj[5]
        this.support = obj[6]
        this.sampler = obj[7]
        this.labo = obj[8]
        this.producer = obj[9]
        this.point = obj[10]
        this.refLabo = obj[11]
        this.pathPdf = obj[12]
    }
}