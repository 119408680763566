import { isNull } from 'lodash'

export default class DtoPrettyAnalysisLight {
    constructor(obj) {
        const ana = obj.map(e => isNull(e) ? undefined : e)
        this.qualitometer = ana[0]
        this.id = ana[1]
        this.sample = ana[2]
        this.operation = ana[3]
        this.sampleDate = ana[4]
        this.analysisDate = ana[5]
        this.parameter = ana[6]
        this.unit = ana[7]
        this.result = ana[8]
        this.remark = ana[9]
        this.support = ana[10]
        this.localization = ana[11]
        this.lq = ana[12]
        this.ld = ana[13]
        this.saturationThreshold = ana[14]
        this.qualification = ana[15]
        this.status = ana[16]
        this.fraction = ana[17]
        this.labo = ana[18]
        this.jobExecutionId = ana[19]
        this.importDate = ana[20]
        this.updateDate = ana[21]
        this.updateLogin = ana[22]
        this.numSampling = ana[23] // Option[Int]
        this.value = ana[24] ?? '' // Option[String] = None,
        this.color = ana[25] ?? 'white' // Option[String] = None,
        this.thresholdState = ana[26] // Option[String] = None,
        this.thresholdValue = ana[27] // Option[Double] = None,
        this.conformity = ana[28] // Option[Boolean] = None,
        this.threshold1 = ana[29] // Option[Double] = None,
        this.threshold2 = ana[30] // Option[Double] = None,
        this.threshold3 = ana[31] // Option[Double] = None,
        this.threshold4 = ana[32] // Option[Double] = None
    }
}