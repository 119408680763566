export default class DtoNetwork {
    constructor(obj) {
        this.id = obj.id // Option[Long],
        this.idStation = obj.idStation // Option[Long],
        this.typeCode = obj.typeCode // Option[Long],
        this.natureCode = obj.natureCode // Option[Long],
        this.codeMaterial = obj.codeMaterial // Option[Long],
        this.length = obj.length // Option[Double],
        this.section = obj.section // Option[Double]
    }
}