import { getOptions } from '../EChartUtils'

class _Scatter {
    constructor(obj) {
        this.obj = obj
    }

    getDefaultOptions = () => {
        return {
            name: 'scatter chart',
            type: 'scatter',
            data: [],
            itemStyle: {
                normal: {
                    color: 'black',
                },
            },
        }
    }

    getJson = () => {
        return getOptions(this.obj, this.getDefaultOptions(), {
            color: () => (
                {
                    itemStyle: {
                        normal: {
                            color: this.obj.color,
                        },
                    },
                }),
        })
    }
}

export default function Scatter(args) {
    return new _Scatter(args)
}


