module.exports = {
    CONTRIBUTOR: 'Contributor',
    MAP: 'Map',
    RECEIVE_ALL_CONTRIBUTORS: 'RECEIVE_ALL_CONTRIBUTORS',
    RECEIVE_CONTRIBUTORS_TABLE: 'RECEIVE_CONTRIBUTORS_TABLE',
    RECEIVE_CONTRIBUTOR: 'RECEIVE_CONTRIBUTOR',
    RECEIVE_CONTRIBUTOR_DOMAIN: 'RECEIVE_CONTRIBUTOR_DOMAIN',
    RESET_CONTRIBUTOR: 'RESET_CONTRIBUTOR',
    RECEIVE_PRODUCERS: 'RECEIVE_PRODUCERS',
    RECEIVE_LABORATORIES: 'RECEIVE_LABORATORIES',
    RECEIVE_DETERMINATORS: 'RECEIVE_DETERMINATORS',
    RECEIVE_CONTRIBUTOR_TYPES: 'RECEIVE_CONTRIBUTOR_TYPES',
    RECEIVE_ALL_CONTRIBUTORS_GROUPS: 'RECEIVE_ALL_CONTRIBUTORS_GROUPS',
    RECEIVE_CONTRIBUTORS_GROUP: 'RECEIVE_CONTRIBUTORS_GROUP',
    CREATE_CONTRIBUTORS_GROUPS: 'CREATE_CONTRIBUTORS_GROUPS',
    UPDATE_CONTRIBUTORS_GROUP: 'UPDATE_CONTRIBUTORS_GROUP',
    DELETE_CONTRIBUTORS_GROUP: 'DELETE_CONTRIBUTORS_GROUP',
}
