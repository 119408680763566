import React from 'react'
import { Grid } from '@mui/material'
import { groupBy, keys, maxBy, mean, round, sumBy, uniqBy } from 'lodash'
import { calculateAverage, calculateThresholdResult, filterResult, filterValid, searchMaxAnalysis, searchMinAnalysis, searchP90Analysis } from 'utils/AnalyseUtils'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'

const Chip = ({
    color = '',
}) => (
    <span
        style={{
            backgroundColor: color,
            color,
            padding: '0px 6px',
            borderRadius: '10px',
            marginRight: '5px',
        }}
    >
        {'0'}
    </span>
)

Chip.propTypes = {
    color: PropTypes.string,
}

const getStats = (validHydrobios, sortingType, qualityThresholds) => {
    const groupedHydrobios = groupBy(validHydrobios, 'qualitometer')
    if (sortingType === '1') {
        return keys(groupedHydrobios).map(key => searchMaxAnalysis(groupedHydrobios[key]))
    } else if (sortingType === '2') {
        return keys(groupedHydrobios).map(key => searchMinAnalysis(groupedHydrobios[key]))
    } else if (sortingType === '3') {
        return keys(groupedHydrobios).map(key => {
            const average = calculateAverage(groupedHydrobios[key])
            const hydrobio = groupedHydrobios[key][0]
            const averageHydrobio = {
                result: average,
                unit: hydrobio?.unit,
                fraction: hydrobio?.fraction,
                support: hydrobio?.support,
                qualitometer: hydrobio?.qualitometer,
            }
            return {
                ...averageHydrobio,
                ...calculateThresholdResult(averageHydrobio, qualityThresholds),
            }
        })
    } else if (sortingType === '4') {
        return keys(groupedHydrobios).map(key => searchP90Analysis(groupedHydrobios[key]))
    }
    return keys(groupedHydrobios).map(key => maxBy(groupedHydrobios[key], 'operationDate'))
}

const getStatsTaxon = (hydrobioList, sortingType, qualityThresholds) => {
    const validHydrobios = filterValid(filterResult(hydrobioList))
    return getStats(validHydrobios, sortingType, qualityThresholds)
}

const getPresenceMissingTaxon = (selectedTaxon, hydrobioList) => {
    const validHydrobios = filterValid(filterResult(hydrobioList))
    const groupedHydrobios = groupBy(validHydrobios, 'qualitometer')
    return keys(groupedHydrobios).map(key => {
        const isPresent = groupedHydrobios[key].some(l => l.taxon === selectedTaxon)
        return {
            qualitometer: groupedHydrobios[key][0].qualitometer,
            color: isPresent ? 'green' : 'red',
        }
    })
}

const PresenceMissingLegend = () => (
    <Grid container spacing='2'>
        <Grid item xs={6}>
            <Chip color='green'/>
            {i18n.present}
        </Grid>
        <Grid item xs={6}>
            <Chip color='red'/>
            {i18n.absent}
        </Grid>
    </Grid>
)

const getAbundanceColor = abundance => {
    if (abundance > 50) return 'blue'
    if (abundance > 25) return 'green'
    if (abundance > 10) return 'yellow'
    if (abundance > 5) return 'orange'
    return 'red'
}

const getAbundanceTaxon = (selectedTaxon, hydrobioList, sortingType) => {
    const validHydrobios = filterValid(filterResult(hydrobioList))
    const filteredList = validHydrobios.filter(l => l.taxon === selectedTaxon)
    const stats = getStats(filteredList, sortingType)
    const groupedHydrobios = groupBy(validHydrobios, 'qualitometer')
    const keyFiltered = keys(groupedHydrobios).filter(key => stats.some(s => `${s.qualitometer}` === key))

    if (sortingType === '3') { // average
        return keyFiltered.map(key => {
            const stat = stats.find(s => `${s.qualitometer}` === key)
            const groupedOperation = groupBy(groupedHydrobios[key], 'operation')
            const meanByOperation = mean(keys(groupedOperation).map(k => sumBy(groupedOperation[k], 'result')))
            const abundance = meanByOperation ? round(stat.result * 100 / meanByOperation, 3) : 0
            return {
                qualitometer: groupedHydrobios[key][0].qualitometer,
                color: getAbundanceColor(abundance),
            }
        })
    }

    return keyFiltered.map(key => {
        const stat = stats.find(s => `${s.qualitometer}` === key)
        const subList = groupedHydrobios[key].filter(l => l.operation === stat.operation)
        const sumList = sumBy(subList, 'result')
        const abundance = sumList ? round(stat.result * 100 / sumList, 3) : 0
        return {
            qualitometer: groupedHydrobios[key][0].qualitometer,
            color: getAbundanceColor(abundance),
        }
    })
}

const AbundanceLegend = () => (
    <Grid container spacing='2'>
        <Grid item xs={6}>
            <Chip color='blue'/>
            {'> 50'}
        </Grid>
        <Grid item xs={6}>
            <Chip color='green'/>
            {'<= 50 et > 25'}
        </Grid>
        <Grid item xs={6}>
            <Chip color='yellow'/>
            {'<= 25 et > 10'}
        </Grid>
        <Grid item xs={6}>
            <Chip color='orange'/>
            {'<= 10 et > 5'}
        </Grid>
        <Grid item xs={6}>
            <Chip color='red'/>
            {'<= 5'}
        </Grid>
    </Grid>
)

const getFrequencyColor = frequency => {
    if (frequency > 90) return 'blue'
    if (frequency > 75) return 'green'
    if (frequency > 50) return 'yellow'
    if (frequency > 25) return 'orange'
    return 'red'
}

const getOccurenceFrequencyTaxon = (selectedTaxon, hydrobioList) => {
    const validHydrobios = filterValid(filterResult(hydrobioList))
    const groupedHydrobios = groupBy(validHydrobios, 'qualitometer')

    return keys(groupedHydrobios).map(key => {
        const subList = groupedHydrobios[key]
        const nbAllOperation = uniqBy(subList, 'operation').length
        const nbDetected = uniqBy(subList.filter(l => l.taxon === selectedTaxon), 'operation').length
        const frequency = nbAllOperation ? round(nbDetected * 100 / nbAllOperation, 3) : 0
        return {
            qualitometer: groupedHydrobios[key][0].qualitometer,
            color: getFrequencyColor(frequency),
        }
    })
}

const OccurenceFrequencyLegend = () => (
    <Grid container spacing='2'>
        <Grid item xs={6}>
            <Chip color='blue'/>
            {'> 90'}
        </Grid>
        <Grid item xs={6}>
            <Chip color='green'/>
            {'<= 90 et > 75'}
        </Grid>
        <Grid item xs={6}>
            <Chip color='yellow'/>
            {'<= 75 et > 50'}
        </Grid>
        <Grid item xs={6}>
            <Chip color='orange'/>
            {'<= 50 et > 25'}
        </Grid>
        <Grid item xs={6}>
            <Chip color='red'/>
            {'<= 25'}
        </Grid>
    </Grid>
)

export {
    getStatsTaxon,
    getPresenceMissingTaxon,
    getAbundanceTaxon,
    getOccurenceFrequencyTaxon,
    PresenceMissingLegend,
    AbundanceLegend,
    OccurenceFrequencyLegend,
}