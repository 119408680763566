/* eslint-disable camelcase */
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { arrayOf } from '../../../../utils/StoreUtils'
import i18n from 'simple-react-i18n'
import DtoSandreCode from '../../../../referencial/dto/DtoSandreCode'
import Table from '../../../../components/datatable/Table'
import SieauAction from '../../../../components/sieau/SieauAction'
import { getExport, setModal } from '../../../../utils/linkUtils'
import DtoTankStation from '../dto/DtoTankStation'
import TankOperatorEquipmentModal from './modal/TankOperatorEquipmentModal'

const TankOperatorEquipmentPanel = ({
    tank = {},
    readMode = false,
    onChange = () => {},
    setPopup = () => {},
}) => {
    const formatText = (equip) => {
        const isFixing = equip.fixingState ? i18n.fixingState : null
        const isSealingState = equip.sealingState ? i18n.sealingState : null
        const isDuctPassageState = equip.ductPassageState ? i18n.ductPassageState : null
        const isImplantation = equip.implantationState ? i18n.implantationState : null

        const isDefined = value => value && <span>{value}<br /></span>

        return (<div>
            {isDefined(isFixing)}
            {isDefined(isSealingState)}
            {isDefined(isDuctPassageState)}
            {isDefined(isImplantation)}
        </div>)
    }

    const stationOperatorEquipments = tank.link_operatorEquipments.map((c, index) => ({
        ...c,
        index,
    }))
    const tableData = stationOperatorEquipments.map(c => ({
        ...c,
        state: formatText(c),
    }))
    const headers = ['equipment', 'state', 'comment']
    const exportAction = getExport(tableData, i18n.equipments, headers)
    const actions = !readMode ? [{
        iconName: 'note_add',
        tooltip: i18n.add,
        onClick: () => setModal(i18n.newEquipment, <TankOperatorEquipmentModal id={ tank.idStation } saveResult={ r => onChange({ link_operatorEquipments: [...stationOperatorEquipments, r] }) }/>, setPopup),
    }, exportAction] : [exportAction]

    return (
        <Table
            title={ i18n.equipments }
            condensed
            className='blue'
            data={ tableData }
            type={{ headers }}
            sortable={ !!tableData.length }
            actions={ actions }
            alterable={ !readMode }
            onAlter={
                (element) => setModal(i18n.editEquipment,
                    <TankOperatorEquipmentModal
                        id={ tank.idStation }
                        operatorEquipment={ stationOperatorEquipments.find(c => element.index === c.index) }
                        saveResult={ r => onChange({
                            link_operatorEquipments: [
                                ...stationOperatorEquipments.filter(lc => lc.index !== element.index),
                                { ...r, index: element.index },
                            ],
                        })}
                    />
                    , setPopup)
            }
            deletable={ !readMode }
            onDelete={ (element) => onChange({ link_operatorEquipments: stationOperatorEquipments.filter(lc => lc.index !== element.index) }) }
        />
    )
}

TankOperatorEquipmentPanel.propTypes = {
    tank: PropTypes.instanceOf(DtoTankStation),
    sandreCodes: arrayOf(DtoSandreCode),
    readMode: PropTypes.bool,
    onChange: PropTypes.func,
    setPopup: PropTypes.func,
}

const mapStateToProps = store => ({
    sandreCodes: store.ReferencialReducer.sandreCodes,
})

const mapDispatchToProps = {
    setPopup: SieauAction.setPopup,
}

export default connect(mapStateToProps, mapDispatchToProps)(TankOperatorEquipmentPanel)