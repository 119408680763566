import { Grid } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { H_TERRITORY_RSEAU } from 'account/constants/AccessRulesConstants'
import Card from 'components/card/Card'
import Table from 'components/datatable/Table'
import Input from 'components/forms/Input'
import Row from 'components/react/Row'
import ExportAction from 'export/actions/ExportAction'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { nbPerPageLabelShorty, SANDRE } from 'referencial/constants/ReferencialConstants'
import DtoSandreCode from 'referencial/dto/DtoSandreCode'
import i18n from 'simple-react-i18n'
import DtoSurveyForecast from 'survey/dto/DtoSurveyForecast'
import { mainBlue } from 'utils/constants/ColorTheme'
import { formatData } from 'utils/ExportDataUtil'
import { sieauTooltip } from 'utils/FormUtils'
import { componentHasHabilitations } from 'utils/HabilitationUtil'
import { hasValue } from 'utils/NumberUtil'
import { arrayOf } from 'utils/StoreUtils'
import { formatMilliers, formatPhone } from 'utils/StringUtil'
import ManagementUnitVolumesChart from '../charts/ManagementUnitVolumesChart'
import ManagementUnitVolumesHistogram from '../charts/ManagementUnitVolumesHistogram'
import IrrigEfficiencyTable from '../panel/IrrigEfficiencyTable'

const styleCell = {
    width: '60%',
    textAlign: 'right',
}

const useStyles = makeStyles(theme => ({
    tab: {
        border: `2px solid ${theme.palette.primary.main}`,
    },
}))

const TAB_EFFICIENCY = 'irrigEfficiency'
const TAB_BILAN = 'overview'
const TAB_POINTS_PREL = 'pointsPrelevement'
const TAB_SAMPLERS = 'samplers'
const TAB_USAGES = 'usages'
const TAB_CITIES = 'cities'

const ManagementUnitDetailTabs = ({
    data = {},
    filter = {},
}) => {
    const dispatch = useDispatch()
    const classes = useStyles()

    const showEfficiency = componentHasHabilitations(H_TERRITORY_RSEAU) && filter.volumesShow === 'irrigEfficiency'
    const {
        sandreCodes,
    } = useSelector(store => ({
        sandreCodes: store.ReferencialReducer.sandreCodes,
    }), shallowEqual)
    const [tab, setTab] = useState(showEfficiency ? TAB_EFFICIENCY :TAB_BILAN)

    const getActive = (panel) => {
        return panel === tab ? {
            backgroundColor: mainBlue,
            color: 'white',
        } : {}
    }

    const onChangePanel = panel => {
        setTab(panel)
    }

    const getCell = (value, style) => {
        return {
            value,
            style,
        }
    }

    // const getNature = (type, nature) => {
    //     switch (type) {
    //         case 0:
    //             return sandreCodes.find((c) => c.field === 'PREL_AGRI.NATURE_PRELEVEMENT' && c.code === nature) || {}
    //         case 1:
    //             return sandreCodes.find((c) => c.field === 'PREL_AGRI.NATURE_PRELEVEMENT_ESO' && c.code === nature) || {}
    //         case 2:
    //             return sandreCodes.find((c) => c.field === 'PREL_AGRI.NATURE_PRELEVEMENT_ESU' && c.code === nature) || {}
    //         default:
    //             return {}
    //     }
    // }

    const getProjection = (proj) => {
        return sandreCodes.find((c) => c.field === SANDRE.PROJECTION && c.code === proj) || {}
    }

    const getHead = (d, panel) => {
        switch (panel) {
            case TAB_POINTS_PREL:
                const type = (sandreCodes.find((c) => c.field === 'PREL_AGRI.TYPE_PRELEVEMENT' && c.code === d.typePrel) || {}).name || i18n.unknown
                // const nature = getNature(d.typePrel, d.nature).name || i18n.unknown
                const projection = getProjection(d.projection).name || i18n.unknown
                return {
                    code: getCell(d.code),
                    city: getCell(d.city),
                    name: getCell(d.name),
                    type: getCell(type),
                    codeParcelle: getCell(d.codeParcelle),
                    x: getCell(d.x),
                    y: getCell(d.y),
                    projection,
                }
            case TAB_USAGES:
                return {
                    usage: getCell(d.usage),
                    category: getCell(d.category),
                }
            case TAB_SAMPLERS:
                return {
                    codification: getCell(d.codification),
                    sampler: getCell((
                        <span
                            {...sieauTooltip(`${i18n.email} : ${d.email || ''} \n${i18n.phoneTel} : ${formatPhone(d.phoneTel) || ''} \n${i18n.mobile} : ${formatPhone(d.mobile) || ''}`, null, 'bottom')}
                        >
                            {d.sampler}
                        </span>
                    )),
                    samplerName: getCell(d.sampler),
                    city: getCell(d.city),
                    email: getCell(d.email),
                    phoneTel: getCell(formatPhone(d.phoneTel) || ''),
                    mobile: getCell(formatPhone(d.mobile) || ''),
                }
            case TAB_CITIES:
                return {
                    inseeCode: getCell(d.inseeCode),
                    cityName: getCell(d.cityName),
                }
            default:
                return {
                    id: getCell(d.id),
                    name: getCell(d.name),
                }
        }
    }

    const formatDataTable = (dataTable, panel) => {
        return dataTable.map((d) => {
            return {
                ...getHead(d, panel),
                requestedVolumes: getCell(hasValue(d.requestedVolumes) ? `${formatMilliers(d.requestedVolumes) || 0} m3` : '', styleCell),
                attributedVolumes: getCell(hasValue(d.attributedVolumes) ? `${formatMilliers(d.attributedVolumes) || 0} m3` : '', styleCell),
                authorizedVolumes: getCell(hasValue(d.authorizedVolumes) ? `${formatMilliers(d.authorizedVolumes) || 0} m3` : '', styleCell),
                consumedVolumes: getCell(hasValue(d.consumedVolumes) ? `${formatMilliers(d.consumedVolumes) || 0} m3` : '', styleCell),
            }
        })
    }

    const getData = (panel, isExport) => {
        const { results = {} } = data
        switch (panel) {
            case TAB_USAGES:
                const headersUsages = ['usage', 'category']
                return {
                    headers: headersUsages,
                    volumesData: formatDataTable(results.usagesVolumes || [], panel),
                }
            case TAB_SAMPLERS:
                const exportSamplers = ['codification', 'samplerName', 'city', 'email', 'phoneTel', 'mobile']
                const samplers = ['codification', 'sampler', 'city']
                const volumesSamplers = formatDataTable(results.samplersVolumes || [], panel)
                return isExport ? {
                    headers: exportSamplers,
                    volumesData: volumesSamplers,
                } : {
                    headers: samplers,
                    volumesData: volumesSamplers,
                }
            case TAB_CITIES:
                const headersCities = ['inseeCode', 'cityName']
                return {
                    headers: headersCities,
                    volumesData: formatDataTable(results.citiesVolumes || [], panel),
                }
            default: case TAB_POINTS_PREL:
                const exportPoints = ['codeParcelle', 'x', 'y', 'projection']
                const points = ['code', 'city', 'name', 'type']
                const volumesPoints = formatDataTable(results.pointsVolumes || [], panel)
                return isExport ? {
                    headers: [...points, ...exportPoints],
                    volumesData: volumesPoints,
                } : {
                    headers: points,
                    volumesData: volumesPoints,
                }
        }
    }

    const getVolumesAsked = (results = {}) => {
        const { pointsVolumes } = results
        const totalVolumesAsked = `${formatMilliers(pointsVolumes && pointsVolumes.length ? Math.round(pointsVolumes.map(({ requestedVolumes }) => requestedVolumes || 0).reduce((a, b) => a + b, 0)) : 0) || 0} m3`
        const totalAllocatedVolumes = `${formatMilliers(pointsVolumes && pointsVolumes.length ? Math.round(pointsVolumes.map(({ attributedVolumes }) => attributedVolumes || 0).reduce((a, b) => a + b, 0)) : 0) || 0} m3`
        const totalAuthorizedVolumes = `${formatMilliers(pointsVolumes && pointsVolumes.length ? Math.round(pointsVolumes.map(({ authorizedVolumes }) => authorizedVolumes || 0).reduce((a, b) => a + b, 0)) : 0) || 0} m3`
        return { totalVolumesAsked, totalAllocatedVolumes, totalAuthorizedVolumes }
    }

    const onExport = () => {
        const { headers, volumesData } = getData(tab, true)
        const headersExport = [...headers, 'requestedVolumes', 'consumedVolumes']
        const dataFormatted = formatData(volumesData.map((d, i) => {
            return i === 0 ? { headers: headersExport, ...d } : d
        }))
        dispatch(ExportAction.export(dataFormatted, 'xlsx', data.labelWithCode))
    }

    const getPanel = () => {
        if (tab === TAB_EFFICIENCY) {
            return (
                <Card className={classes.tab}>
                    <IrrigEfficiencyTable data={data?.results?.irrigDatas} />
                </Card>
            )
        }
        if (tab === TAB_BILAN) {
            const volumeAUP = data.AUP ? data.AUP.value : '0 m3'
            const { totalVolumesAsked, totalAllocatedVolumes, totalAuthorizedVolumes } = getVolumesAsked(data.results)
            return (
                <Card className={classes.tab}>
                    <Row noMargin={false} className='margin-bottom-0 padding-top-1'>
                        <div className='col s3 no-padding'>
                            <Input
                                col={12}
                                title={i18n.AUP}
                                value={volumeAUP}
                                disabled
                                style={{ textAlign: 'end' }}
                            />
                            <Input
                                col={12}
                                title={i18n.requestedVolumes}
                                value={totalVolumesAsked}
                                disabled
                                style={{ textAlign: 'end' }}
                            />
                            <Input
                                col={12}
                                title={i18n.allocatedVolumes}
                                value={totalAllocatedVolumes}
                                disabled
                                style={{ textAlign: 'end' }}
                            />
                            <Input
                                col={12}
                                title={i18n.authorizedVolumes}
                                value={totalAuthorizedVolumes}
                                disabled
                                style={{ textAlign: 'end' }}
                            />
                            <Input
                                col={12}
                                title={i18n.consumedVolumes}
                                value={data.consumedVolumes}
                                disabled
                                style={{ textAlign: 'end' }}
                            />
                        </div>
                        <div className='col s9'>
                            <ManagementUnitVolumesHistogram
                                volumes={[
                                    volumeAUP,
                                    totalVolumesAsked,
                                    totalAllocatedVolumes,
                                    totalAuthorizedVolumes,
                                    data.consumedVolumes,
                                ]}
                                height={500}
                            />
                        </div>
                    </Row>
                </Card>
            )
        }
        const { headers, volumesData } = getData(tab)
        return (
            <div className={`row no-margin no-padding ${classes.tab}`} style={{ display: 'flex', alignItems: 'stretch' }}>
                <div className={`col s${tab === TAB_USAGES || tab === TAB_CITIES ? 9 : 12} no-margin no-padding`}>
                    <Table
                        title={i18n[tab]}
                        data={volumesData}
                        paging
                        nbPerPageLabel={nbPerPageLabelShorty}
                        type={{ headers: [...headers, 'requestedVolumes', 'consumedVolumes'] }}
                        sortable
                        initialSort
                        color
                        actions={[{
                            iconName: 'file_download',
                            tooltip: i18n.export,
                            onClick: onExport,
                        }]}
                    />
                </div>
                {(tab === TAB_USAGES || tab === TAB_CITIES) && (
                    <div className='col s3 no-margin no-padding'>
                        <ManagementUnitVolumesChart volumes={volumesData} type={tab} style={{ height: '100%' }} />
                    </div>
                )}
            </div>
        )
    }

    return (
        <div className='row no-margin padding-1' style={{ height: '100%' }}>
            <ul className='tabs'>
                <Grid container>
                    {showEfficiency && (
                        <Grid item xs>
                            <li className='tab col s12' id={TAB_EFFICIENCY} onClick={() => onChangePanel(TAB_EFFICIENCY)}>
                                <a style={getActive(TAB_EFFICIENCY)}>
                                    <span className='bold'>{i18n.irrigEfficiency}</span>
                                </a>
                            </li>
                        </Grid>
                    )}
                    <Grid item xs>
                        <li className={'tab col s12'} id={TAB_BILAN} onClick={() => onChangePanel(TAB_BILAN)}>
                            <a style={getActive(TAB_BILAN)}>
                                <span className='bold'>{i18n.overview}</span>
                            </a>
                        </li>
                    </Grid>
                    <Grid item xs>
                        <li className={'tab col s12'} id={TAB_POINTS_PREL} onClick={() => onChangePanel(TAB_POINTS_PREL)}>
                            <a style={getActive(TAB_POINTS_PREL)}>
                                <span className='bold'>{i18n.pointsPrelevement}</span>
                            </a>
                        </li>
                    </Grid>
                    <Grid item xs>
                        <li className={'tab col s12'} id={TAB_USAGES} onClick={() => onChangePanel(TAB_USAGES)}>
                            <a style={getActive(TAB_USAGES)}>
                                <span className='bold'>{i18n.usages}</span>
                            </a>
                        </li>
                    </Grid>
                    <Grid item xs>
                        <li className={'tab col s12'} id={TAB_SAMPLERS} onClick={() => onChangePanel(TAB_SAMPLERS)}>
                            <a style={getActive(TAB_SAMPLERS)}>
                                <span className='bold'>{i18n.samplers}</span>
                            </a>
                        </li>
                    </Grid>
                    <Grid item xs>
                        <li className={'tab col s12'} id={TAB_CITIES} onClick={() => onChangePanel(TAB_CITIES)}>
                            <a style={getActive(TAB_CITIES)}>
                                <span className='bold'>{i18n.cities}</span>
                            </a>
                        </li>
                    </Grid>
                </Grid>
            </ul>
            {getPanel()}
        </div>
    )
}

ManagementUnitDetailTabs.propTypes = {
    open: PropTypes.bool,
    title: PropTypes.string,
    newVolumes: PropTypes.bool,
    onClose: PropTypes.func,
    export: PropTypes.func,
    fetchForecastsUge: PropTypes.func,
    push: PropTypes.func,
    data: PropTypes.shape({
        labelWithCode: PropTypes.string,
        results: PropTypes.shape({}),
        AUP: PropTypes.shape({
            value: PropTypes.string,
        }),
        typeName: PropTypes.string,
        managementCode: PropTypes.string,
        consumedVolumes: PropTypes.string,
    }),
    filter: PropTypes.shape({
        volumesShow: PropTypes.string,
        dateShow: PropTypes.number,
        dateShowEnd: PropTypes.number,
        showWatersheds: PropTypes.bool,
        showManagementUnits: PropTypes.bool,
        showCities: PropTypes.bool,
        showPtsPrel: PropTypes.bool,
        takeWaterTanks: PropTypes.bool,
        pointsType: PropTypes.number,
    }),
    year: PropTypes.number,
    sandreCodes: PropTypes.arrayOf(PropTypes.instanceOf(DtoSandreCode)),
    forecastsUge: arrayOf(DtoSurveyForecast),
}

export default ManagementUnitDetailTabs