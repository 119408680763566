import ActionComponent from 'components/ActionComponent'
import { push } from '@lagunovsky/redux-react-router'
import HomeAction from 'home/actions/HomeAction'
import { isEqual, omit } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import AppStore from 'store/AppStore'
import ToastrAction from 'toastr/actions/ToastrAction'
import { H_MAT_POWER_SUPPLY } from '../../../../account/constants/AccessRulesConstants'
import User from '../../../../account/dto/User'
import DtoFile from '../../../../components/file/dto/DtoFile'
import FilePanel from '../../../../components/file/FilePanel'
import DtoPicture from '../../../../components/picture/dto/DtoPicture'
import SmallPicturePanel from '../../../../components/picture/SmallPicturePanel'
import SieauAction from '../../../../components/sieau/SieauAction'
import UpdatePanel from '../../../../components/updatePanel/UpdatePanel'
import ContributorDto from '../../../../referencial/components/contributor/dto/ContributorDto'
import { componentHasHabilitations } from '../../../../utils/HabilitationUtil'
import { updateMaterialize } from '../../../../utils/MaterializeUtils'
import { getUser } from '../../../../utils/SettingUtils'
import DtoMaterielSettingRule from '../../../dto/DtoMaterielSettingRule'
import DtoMaterielState from '../../../dto/DtoMaterielState'
import GenericReferencialDto from '../../../dto/GenericReferencialDto'
import { isSaveBlocked } from '../../../utils/MaterielUtils'
import AdministratorPanel from '../../administrator/AdministratorPanel'
import AssigmentChartPanel from '../../chart/AssigmentChartPanel'
import LastSituationMarkerPanel from '../../situation/LastSituationMarkerPanel'
import SituationPanel from '../../situation/SituationPanel'
import PowerSupplyAction from '../actions/PowerSupplyAction'
import DtoPowerSupplySituation from '../dto/DtoPowerSupplySituation'
import PowerSupplyDto from '../dto/PowerSupplyDto'
import PowerSupplyPanel from './PowerSupplyPanel'
import { POWER_SUPPLY_ID } from 'materiel/constants/MaterielConstants'

class PowerSupplyApp extends ActionComponent {
    state = {
        powerSupply: {},
        isEditMode: false,
    }

    changeAdministrator = administrator => this.setState(({ powerSupply }) => ({ powerSupply: { ...powerSupply, administrator } }))

    changeStateAttribute = changes => this.setState(({ powerSupply }) => ({ powerSupply: { ...powerSupply, ...changes } }))

    changeEditMode = (bool) => {
        this.setState({ isEditMode: bool })
    }

    componentWillUnmount = () => {
        this.props.resetPowerSupply()
        this.setActions({})
    }

    fetchPowerSupply = id => this.props.fetchPowerSupply(id).then(json => {
        if (json.id) {
            this.props.fetchPowerSupplySituations(json.id)
            this.props.fetchPowerSupplyPictures(json.id)
            this.props.fetchPowerSupplyFiles(json.serialNumber)
            this.setState({ powerSupply: { ...json } })
        }
    })

    componentDidMount = () => {
        if (!componentHasHabilitations(H_MAT_POWER_SUPPLY)) { // A modifier quand react-router sera à jour
            this.props.push('/unauthorized')
            return
        }
        if (this.props.params.id !== 'new') {
            this.fetchPowerSupply(this.props.params.id)
        } else {
            this.changeEditMode(true)
        }
        if (!this.props.powerSupplies.length) {
            this.props.fetchPowerSupplies(true)
        }
        if (!this.props.powerSupplyTypes.length) {
            this.props.fetchPowerSupplyTypes()
        }
        AppStore.dispatch(HomeAction.setHelpLink('materiel', ''))
        this.applyActions()
    }

    componentDidUpdate = prevProps => {
        if (prevProps.params.id !== this.props.params.id) {
            this.fetchPowerSupply(this.props.params.id)
        }
        if (!isEqual(prevProps.powerSupply, this.props.powerSupply) || !isEqual(prevProps.powerSupplyTypes, this.props.powerSupplyTypes)) {
            const {
                reference = '',
                serialNumber = '',
                powerSupplyType,
                id,
            } = this.props.powerSupply
            const title = reference || serialNumber || id
            const type = (this.props.powerSupplyTypes.find(t => t.code === parseInt(powerSupplyType)) || {}).label || i18n.notDefined
            this.props.forceFetch('title', [
                {
                    title: i18n.materiels,
                    href: 'materiel',
                },
                {
                    title: i18n.powerSupplies,
                    href: 'materiel/powerSupply',
                },
                {
                    title: type,
                    href: `materiel/powerSupply/${this.props.params.id}`,
                },
                {
                    title,
                    href: `materiel/powerSupply/${this.props.params.id}`,
                },
            ])
        }
        this.applyActions()
    }

    applyActions = () => {
        const actions = (() => {
            if (this.props.params.id === 'new' && !this.props.powerSupply.id) {
                return {
                    save: () => {
                        const rules = this.props.materielSettingRules.filter(({ idMaterielType }) => idMaterielType === POWER_SUPPLY_ID)
                        if (isSaveBlocked(this.props.powerSupplies, this.state.powerSupply, rules, 'powerSupplyType')) return

                        this.props.createPowerSupply(this.state.powerSupply).then(id => {
                            if (id) {
                                const situation = {
                                    idPowerSupply: id,
                                    situationDate: this.state.powerSupply.purchaseDate,
                                    statusCode: 2,
                                }
                                this.props.savePowerSupplySituation(situation)
                            }
                        })
                    },
                    cancel: () => {
                        this.props.push('/materiel/powerSupply')
                    },
                }
            }
            if (this.state.isEditMode) {
                return {
                    save: () => {
                        const rules = this.props.materielSettingRules.filter(({ idMaterielType }) => idMaterielType === POWER_SUPPLY_ID)
                        if (isSaveBlocked(this.props.powerSupplies, this.state.powerSupply, rules, 'powerSupplyType')) return

                        this.props.updatePowerSupply(this.state.powerSupply).then(success => success && this.changeEditMode(false))
                    },
                    cancel: () => {
                        this.setState({ powerSupply: this.props.powerSupply })
                        this.changeEditMode(false)
                    },
                }
            }
            return {
                edit: () => {
                    this.changeEditMode(true)
                },
                delete: () => {
                    this.props.deletePowerSupply(this.props.params.id)
                },
                replace: () => {
                    this.props.toastrInfo(i18n.inDeveloppmentFunctionnality)
                },
            }
        })()
        if (getUser().consultant === '1') {
            this.setActions(omit(actions, ['save', 'edit', 'delete', 'replace']))
        } else {
            this.setActions(actions)
        }
        updateMaterialize()
    }

    deleteSituation = id => this.props.deletePowerSupplySituation(id, this.props.params.id)

    getMaterielNumber = ({ serialNumber, reference }) => serialNumber || reference || ''

    getPowerSuppliesFormat = () => {
        const { powerSupplies, powerSupplyTypes } = this.props
        return powerSupplies.filter(({ isEquipment }) => !isEquipment).map(powerSupply => {
            const label = (() => {
                const number = this.getMaterielNumber(powerSupply)
                if (powerSupply.powerSupplyType) {
                    const type = powerSupplyTypes.find(t => t.id === powerSupply.powerSupplyType)
                    if (type) {
                        return `${type.label} - ${number}`
                    }
                }
                return number
            })()
            return {
                id: powerSupply.id,
                label,
            }
        })
    }

    render = () => {
        const disabled = { active: this.state.isEditMode, disabled: !this.state.isEditMode }
        return (
            <div className='row no-margin'>
                <div id='file' className='col s12'>
                    <div className='card margin-top-0-75-rem'>
                        <div className='card-content'>
                            <div className='row no-margin'>
                                <div className='col s9'>
                                    <div className='col s1'>
                                        <LastSituationMarkerPanel
                                            materielSituation={ this.props.powerSupplySituations }
                                        />
                                    </div>
                                    <div className='col s11'>
                                        <div className='row no-margin padding-top-3-px'>
                                            <PowerSupplyPanel
                                                powerSupply={ this.state.powerSupply }
                                                onChange={ this.changeStateAttribute }
                                                { ...disabled }
                                            />
                                            {
                                                !this.props.powerSupply.isEquipment && (
                                                    <div className='col s12'>
                                                        <SituationPanel
                                                            materielSituation={this.props.powerSupplySituations}
                                                            active={this.state.isEditMode}
                                                            idMateriel={this.props.powerSupply.id}
                                                            serialNumber={this.props.powerSupply.serialNumber}
                                                            purchaseDate={this.props.powerSupply.purchaseDate}
                                                            idKey={'idPowerSupply'}
                                                            lastSituations={this.props.powerSuppliesLastSituations}
                                                            materials={this.getPowerSuppliesFormat()}
                                                            saveFunction={this.props.savePowerSupplySituation}
                                                            deleteFunction={this.deleteSituation}
                                                        />
                                                    </div>
                                                )
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className='col s3'>
                                    <div className='row no-margin'>
                                        <div className='col s12'>
                                            <UpdatePanel
                                                updateDate={this.props.powerSupply.updateDate}
                                                updateLogin={this.props.powerSupply.updateLogin}
                                                jobExecId={this.props.powerSupply.jobExecutionId}
                                            />
                                        </div>
                                        <div className='col s12'>
                                            <div className='row no-margin card'>
                                                <div className='col s12 card-content'>
                                                    <AdministratorPanel
                                                        onChange={this.changeAdministrator}
                                                        selectedValue={this.state.powerSupply.administrator}
                                                        isActive={!this.props.powerSupply.isEquipment && this.state.isEditMode}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col s12'>
                                            <AssigmentChartPanel
                                                materielSituation={ this.props.powerSupplySituations }
                                            />
                                            <SmallPicturePanel element={ 'powerSupply' }
                                                pictures={ this.props.powerSupplyPictures }
                                            />
                                            <FilePanel files={ this.props.powerSupplyFiles } />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

PowerSupplyApp.propTypes = {
    params: PropTypes.shape({
        id: PropTypes.number,
    }),
    powerSupply: PropTypes.instanceOf(PowerSupplyDto),
    getLink: PropTypes.func,
    changeEditMode: PropTypes.func,
    isEditMode: PropTypes.bool,
    accountUser: PropTypes.instanceOf(User),
    powerSupplyTypes: PropTypes.instanceOf(GenericReferencialDto),
    powerSupplySituations: PropTypes.arrayOf(PropTypes.instanceOf(DtoPowerSupplySituation)),
    materielStates: PropTypes.arrayOf(PropTypes.instanceOf(DtoMaterielState)),
    contributors: PropTypes.arrayOf(PropTypes.instanceOf(ContributorDto)),
    powerSupplyPictures: PropTypes.arrayOf(PropTypes.instanceOf(DtoPicture)),
    powerSupplyFiles: PropTypes.arrayOf(PropTypes.instanceOf(DtoFile)),
    powerSuppliesLastSituations: PropTypes.arrayOf(PropTypes.instanceOf(DtoPowerSupplySituation)),
    powerSupplies: PropTypes.arrayOf(PropTypes.instanceOf(PowerSupplyDto)),
    materielSettingRules: PropTypes.arrayOf(PropTypes.instanceOf(DtoMaterielSettingRule)),
    push: PropTypes.func,
}

const mapStateToProps = store => ({
    powerSupply: store.PowerSupplyReducer.powerSupply,
    powerSupplyTypes: store.PowerSupplyReducer.powerSupplyTypes,
    powerSupplySituations: store.PowerSupplyReducer.powerSupplySituations,
    powerSupplyPictures: store.PowerSupplyReducer.powerSupplyPictures,
    powerSupplyFiles: store.PowerSupplyReducer.powerSupplyFiles,
    powerSupplies: store.PowerSupplyReducer.powerSupplies,
    powerSuppliesLastSituations: store.PowerSupplyReducer.powerSuppliesLastSituations,

    materielStates: store.MaterielReducer.materielStates,
    accountUser: store.AccountReducer.accountUser,
    contributors: store.ContributorReducer.contributors,
    materielSettingRules: store.MaterielReducer.materielSettingRules,
})

const mapDispatchToProps = {
    push,
    resetPowerSupply: PowerSupplyAction.resetPowerSupply,
    fetchPowerSupply: PowerSupplyAction.fetchPowerSupply,
    fetchPowerSupplies: PowerSupplyAction.fetchPowerSupplies,
    fetchPowerSupplyTypes: PowerSupplyAction.fetchPowerSupplyTypes,
    forceFetch: SieauAction.forceFetch,
    createPowerSupply: PowerSupplyAction.createPowerSupply,
    updatePowerSupply: PowerSupplyAction.updatePowerSupply,
    deletePowerSupply: PowerSupplyAction.deletePowerSupply,
    toastrInfo: ToastrAction.info,
    deletePowerSupplySituation: PowerSupplyAction.deletePowerSupplySituation,
    fetchPowerSupplySituations: PowerSupplyAction.fetchPowerSupplySituations,
    fetchPowerSupplyPictures: PowerSupplyAction.fetchPowerSupplyPictures,
    fetchPowerSupplyFiles: PowerSupplyAction.fetchPowerSupplyFiles,
    savePowerSupplySituation: PowerSupplyAction.savePowerSupplySituation,
}

export default connect(mapStateToProps, mapDispatchToProps)(PowerSupplyApp)
