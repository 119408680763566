import { createIndex } from '../../../../utils/StoreUtils'
import { CITIES, CITY, RECEIVE_CITIES_HISTO, RECEIVE_CITY_CEDEX, RECEIVE_DEPARTMENTS, RESET_CITY } from '../constants/CityConstants'
import CityCedexDto from '../dto/CityCedexDto'
import CityDto from '../dto/CityDto'
import DtoCityHisto from '../dto/DtoCityHisto'
import DtoDepartments from '../dto/DtoDepartments'

export function CityReducer(state = {}, action) {
    switch (action.type) {
        case CITY:
            return {
                ...state,
                city: new CityDto(action.city),
            }
        case CITIES:
            const cities = action.cities.map(city => new CityDto(city))
            return {
                ...state,
                cities,
                citiesIndex: createIndex(cities),
            }
        case RECEIVE_CITY_CEDEX:
            return {
                ...state,
                cityCedex: action.cityCedex.map(cityCedex => new CityCedexDto(cityCedex)),
            }
        case RESET_CITY:
            return {
                ...state,
                city: {},
            }
        case RECEIVE_CITIES_HISTO:
            return {
                ...state,
                citiesHisto: action.citiesHisto.map(c => new DtoCityHisto(c)),
            }
        case RECEIVE_DEPARTMENTS:
            return {
                ...state,
                departments: action.payload.map(d => new DtoDepartments(d)),
            }
        default:
            return state
    }
}

export const store = {
    city: {},
    cities: [],
    cityCedex: [],
    citiesIndex: {},
    citiesHisto: [],
    departments: [],
}