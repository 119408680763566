import React, { useEffect } from 'react'
import i18n from 'simple-react-i18n'
import PropTypes from 'prop-types'
import Icon from '../../../components/icon/Icon'
import UserAction from 'administration/components/user/actions/UserAction'
import { hasValue } from 'utils/NumberUtil'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { Grid } from '@mui/material'

// Affiche un message si le code est déjà présent en base
const StationImportCodeMessage = ({
    code,
    stationType,
}) => {
    const dispatch = useDispatch()
    const {
        userStation,
    } = useSelector(store => ({
        userStation: store.UserReducer.userStation,
    }), shallowEqual)
    const { hasRights: stationRights, exists: stationExists } = userStation

    useEffect(() => {
        const codeWithoutDesignation = code.replaceAll(/\/.*/g, '')
        if (hasValue(codeWithoutDesignation)) {
            dispatch(UserAction.fetchUserStationHasRights(stationType, codeWithoutDesignation))
        }
    }, [code, dispatch, stationType])

    if (stationExists && !stationRights) {
        return (
            <Grid container justifyContent='space-between' alignItems='center' spacing={2}>
                <Grid item xs={1}>
                    <Icon icon='warning' style={{ color: 'red' }} />
                </Grid>
                <Grid item xs={11}>
                    <h6>{i18n.warningStationAlreadyExistsAndNoRights}</h6>
                </Grid>
            </Grid>
        )
    } else if (stationExists && stationRights) {
        return (
            <Grid container justifyContent='space-between' alignItems='center' spacing={2}>
                <Grid item xs={1}>
                    <Icon icon='warning' style={{ color: 'orange' }} />
                </Grid>
                <Grid item xs={11}>
                    <h6>{i18n.warningStationAlreadyExists}</h6>
                </Grid>
            </Grid>
        )
    }
    return null
}

StationImportCodeMessage.propTypes = {
    code: PropTypes.string,
    stationType: PropTypes.string,
}

export default StationImportCodeMessage