import PropTypes from 'prop-types'
import React, { Component } from 'react'
import i18n from 'simple-react-i18n'
import ProgressCard from '../../../../components/card/ProgressCard'
import Checkbox from '../../../../components/forms/Checkbox'
import Input from '../../../../components/forms/Input'
import NumberField from '../../../../components/forms/NumberField'
import Select from '../../../../components/forms/Select'
import Row from '../../../../components/react/Row'
import Job from '../../../dto/DtoJob'
import { Grid } from '@mui/material'
import PresentationCard from '../../../../components/card/PresentationCard'
import CenterIcon from '../../../../components/icon/CenterIcon'

class ScriptSSH extends Component {
    constructor(props) {
        super(props)
        this.state = {
            dataLoaded: true,
            progress: 0,
            fileName: '',
        }
    }

    onChangeFilters = (changes) => {
        const parameters = {
            ...this.props.job.parameters,
            filters: [JSON.stringify({
                ...this.getFilters(),
                ...changes,
            })],
        }
        this.props.onChangeJob({ parameters })
    }

    getFilters = () => this.props.job.parameters.filters.length ? JSON.parse(this.props.job.parameters.filters[0]) : { }

    render() {
        if (this.state.dataLoaded) {
            const disabled = !this.props.isEditMode
            const filters = this.getFilters()
            return (
                <div>
                    <div className='row no-margin valign-wrapper'>
                        <fieldset className='width-100'>
                            <legend>&nbsp;{ i18n.identifiants }&nbsp;</legend>
                            <Input col={ 3 } title={ i18n.host } onChange={ v => this.onChangeFilters({ host: v }) } disabled={ disabled } value={ filters.host } />
                            <Input col={ 3 } title={ i18n.user } onChange={ v => this.onChangeFilters({ user: v }) } disabled={ disabled } value={ filters.user } />
                            <Input col={ 3 } title={ i18n.password } onChange={ v => this.onChangeFilters({ password: v }) } disabled={ disabled } value={ filters.password } passwordType/>
                            <NumberField col={ 1 } title={ i18n.port } onChange={ v => this.onChangeFilters({ port: v }) } disabled={ disabled } value={ filters.port } />
                            <Input col={ 4 } title={ i18n.script } onChange={ v => this.onChangeFilters({ fileFilter: v }) } disabled={ disabled } value={ filters.fileFilter } />
                        </fieldset>
                    </div>

                </div>
            )
        }
        return <ProgressCard progress={ this.state.progress }/>
    }
}

ScriptSSH.propTypes = {
    job: PropTypes.instanceOf(Job).isRequired,
    onChangeJob: PropTypes.func.isRequired,
    isEditMode: PropTypes.bool,
}

export default ScriptSSH