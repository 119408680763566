import loading from 'assets/pictures/loading.gif'
import React, { useEffect, useMemo } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import Card from '../../components/card/Card'
import CartographyPanel from '../../components/map/CartographyPanel'
import { execByType } from '../../utils/StationUtils'
import ProgressCard from '../../components/card/ProgressCard'
import { componentHasHabilitations } from '../../utils/HabilitationUtil'
import {
    H_HYDRO_MODULE,
    H_INSTALLATION_MODULE,
    H_PIEZO_MODULE,
    H_PLUVIO_MODULE,
    H_PRODUCTION_MODULE,
    H_QUALITO_MODULE,
} from '../../account/constants/AccessRulesConstants'
import { getUser } from '../../utils/SettingUtils'
import UserThunk from 'administration/components/user/actions/UserThunk'
import QualityThunk from 'quality/actions/QualityThunk'
import PiezometryThunk from 'piezometry/actions/PiezometryThunk'
import useStateProgress from 'utils/customHook/useStateProgress'
import HydrometryThunk from 'hydrometry/actions/HydrometryThunk'
import ProductionUnitThunk from 'productionUnit/actions/ProductionUnitThunk'
import PluviometryThunk from 'pluviometry/actions/PluviometryThunk'
import InstallationThunk from 'installation/actions/InstallationThunk'
import DistributionUnitThunk from 'distributionUnit/actions/DistributionUnitThunk'
import ContactThunk from 'referencial/components/contact/actions/ContactThunk'
import ContributorThunk from 'referencial/components/contributor/actions/ContributorThunk'

const Cartography = () => {
    const {
        piezometersLight,
        contributors,
        pluviometers,
        qualitometers,
        installationsLight,
        contacts,
        productionUnits,
        hydrometricStations,
    } = useSelector(store => ({
        piezometersLight: store.PiezometryReducer.piezometersLight,
        contributors: store.ContributorReducer.contributors,
        pluviometers: store.PluviometryReducer.pluviometers,
        qualitometers: store.QualityReducer.qualitometersListSpecific,
        installationsLight: store.InstallationReducer.installationsLight,
        contacts: store.ContactReducer.contacts,
        productionUnits: store.ProductionUnitReducer.productionUnits,
        hydrometricStations: store.HydrometryReducer.hydrometricStations,
    }), shallowEqual)

    const user = useMemo(() => getUser(), [])
    const showContacts = useMemo(() => user && (user.metadata === '1' || user.isAdmin === '1' || user.admin === '1'), [user])

    const stations = useMemo(() => [
        ...qualitometers,
        ...piezometersLight,
        ...installationsLight,
        ...productionUnits,
        ...pluviometers,
        ...hydrometricStations,
        ...contacts.map(c => ({ ...c, code: c.code?.toString() || '' })),
        ...contributors.map(c => ({ ...c, code: c.code?.toString() || '' })),
    ].filter(s => execByType(s.typeName, {
        quality: () => componentHasHabilitations(H_QUALITO_MODULE),
        piezometry: () => componentHasHabilitations(H_PIEZO_MODULE),
        pluviometry: () => componentHasHabilitations(H_PLUVIO_MODULE),
        hydrometry: () => componentHasHabilitations(H_HYDRO_MODULE),
        installation: () => componentHasHabilitations(H_INSTALLATION_MODULE),
        productionUnit: () => componentHasHabilitations(H_PRODUCTION_MODULE),
        contact: () => showContacts,
        contributor: () => showContacts,
    })), [contacts, contributors, hydrometricStations, installationsLight, piezometersLight, pluviometers, productionUnits, qualitometers, showContacts])

    const navHeight = document.getElementById('nav-wrapper')?.offsetHeight || 0

    return (
        <div className='row no-margin padding-top-1'>
            <CartographyPanel
                layers={['STATIONS_POINTS']}
                componentType={'homepage'}
                stationsPoints={stations}
                stationsPanelTitle={i18n.stations}
                height={window.innerHeight - navHeight}
            />
        </div>
    )
}

const MapPanel = ({}) => {
    const {
        userBookmarksStatus,
        qualitometersListSpecificStatus,
        piezometersLightStatus,
        hydrometricStationsStatus,
        productionUnitsStatus,
        pluviometersStatus,
        installationsLightStatus,
        distributionUnitsStatus,
        contactsStatus,
        contributorsStatus,
    } = useSelector(store => ({
        userBookmarksStatus: store.DataManagerReducer.user.userBookmarksStatus,
        qualitometersListSpecificStatus: store.DataManagerReducer.quality.qualitometersListSpecificStatus,
        piezometersLightStatus: store.DataManagerReducer.piezometry.piezometersLightStatus,
        hydrometricStationsStatus: store.DataManagerReducer.hydrometry.hydrometricStationsStatus,
        productionUnitsStatus: store.DataManagerReducer.productionUnit.productionUnitsStatus,
        pluviometersStatus: store.DataManagerReducer.pluviometry.pluviometersStatus,
        installationsLightStatus: store.DataManagerReducer.installation.installationsLightStatus,
        distributionUnitsStatus: store.DataManagerReducer.distributionUnit.distributionUnitsStatus,
        contactsStatus: store.DataManagerReducer.contact.contactsStatus,
        contributorsStatus: store.DataManagerReducer.contributor.contributorsStatus,
    }), shallowEqual)

    const { progress, isLoaded } = useStateProgress([
        userBookmarksStatus,
        qualitometersListSpecificStatus,
        piezometersLightStatus,
        hydrometricStationsStatus,
        productionUnitsStatus,
        pluviometersStatus,
        installationsLightStatus,
        distributionUnitsStatus,
        contactsStatus,
        contributorsStatus,
    ])

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(UserThunk.fetchBookmarks())
        dispatch(QualityThunk.fetchQualitometersListSpecific())
        dispatch(PiezometryThunk.fetchPiezometersLight())
        dispatch(HydrometryThunk.fetchHydrometricStations())
        dispatch(ProductionUnitThunk.fetchProductionUnits())
        dispatch(PluviometryThunk.fetchPluviometers())
        dispatch(InstallationThunk.fetchInstallationsLight())
        dispatch(DistributionUnitThunk.fetchDistributionUnits())
        dispatch(ContactThunk.fetchContacts())
        dispatch(ContributorThunk.fetchContributors())
    }, [])

    return (
        <>
            {
                !isLoaded && (
                    <div className='padding-top-1'>
                        <Card>
                            <div className='row no-margin center-align padding-top-5'>
                                <img src={loading} />
                                <ProgressCard withoutCard message={i18n.mapCardInProgress} progress={progress} />
                            </div>
                        </Card>
                    </div>
                )
            }
            {
                isLoaded && (
                    <Cartography />
                )
            }
        </>
    )
}

export default MapPanel