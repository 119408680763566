import { uniq } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import ElementCard from '../../../../components/card/ElementCard'
import { EVENT_TYPES } from '../../../../events/constants/EventsConstants'
import DiagnosticDto from '../../../../events/dto/piezometryDiagnostic/DiagnosticDto'
import DiagnosticLinkEventTypeDto from '../../../../events/dto/piezometryDiagnostic/DiagnosticLinkEventTypeDto'
import DiagnosticLinkMaterialDto from '../../../../events/dto/piezometryDiagnostic/DiagnosticLinkMaterialDto'
import MaterielTypeDto from '../../../../materiel/dto/MaterielTypeDto'
import DtoCentral from '../../../../station/dto/materiel/DtoCentral'
import DtoEquipment from '../../../../station/dto/materiel/DtoEquipment'
import DtoPowerSupply from '../../../../station/dto/materiel/DtoPowerSupply'
import DtoSensor from '../../../../station/dto/materiel/DtoSensor'
import DtoSim from '../../../../station/dto/materiel/DtoSim'
import DtoTelecom from '../../../../station/dto/materiel/DtoTelecom'
import DtoVariousMateriel from '../../../../station/dto/materiel/DtoVariousMateriel'
import { i18nize } from '../../../../utils/StringUtil'

const ActionChoicePanel = ({
    event = {},
    diagnostics = [],
    onChangeDiagnostic = () => { },
    eventPiezometerDiagnostics = [],
    eventPiezometerDiagnosticsLinkEventTypes = [],
    eventPiezometerDiagnosticsLinkMaterials = [],
    stationCentral = [],
    stationPowerSupply = [],
    stationSensor = [],
    stationSim = [],
    stationTelecom = [],
    stationVariousMateriel = [],
    stationEquipment = [],
    materielTypes = [],
}) => {
    const materialInStation = [
        stationCentral.length && 1,
        stationSensor.length && 2,
        stationPowerSupply.length && 3,
        stationSim.length && 4,
        stationVariousMateriel.length && 5,
        stationTelecom.length && 6,
        stationEquipment.length && 7,
    ].filter(t => !!t)
    const materielInEquipments = materielTypes.filter(materielType => stationEquipment.find(m => m[materielType.link]?.length))
    const materielTypeIdInEquipments = materielInEquipments.map(o => o.id)
    const materielTypesId = uniq(materielTypeIdInEquipments.concat(materialInStation))

    const diagnosticsByMaterielLinks = (() => {
        if (materielTypesId.length) {
            const diagnosticIdByMaterielLink = uniq(eventPiezometerDiagnosticsLinkMaterials.filter(link => materielTypesId.includes(link.materialType)).map(link => {
                return link.codeDiagnostic
            }))
            const diagnosticByMaterielLink = eventPiezometerDiagnostics.filter(diagnostic => diagnosticIdByMaterielLink.includes(diagnostic.codeDiagnostic))
            return diagnosticByMaterielLink
        }
        return eventPiezometerDiagnostics
    })()

    const eventType = i18nize(EVENT_TYPES).find(e => e.code == event.eventType)
    const eventLinks = eventPiezometerDiagnosticsLinkEventTypes
        .filter(link => link.eventType === eventType.id)
        .map(link => diagnosticsByMaterielLinks.find(d => d.codeDiagnostic === link.codeDiagnostic))
        .filter(d => !!d)
    const diagnosticsContent = eventLinks.map(d => {
        const isSelected = diagnostics.find(diag => diag.codeDiagnostic === d.codeDiagnostic)
        return (
            <ElementCard
                icon='report_problem'
                color={ isSelected ? 'red' : 'white' }
                className='clickable'
                iconColor={ isSelected ? 'white' : 'red' }
                onClick={ () => onChangeDiagnostic(d) }
            >
                <h6>{ d.description }</h6>
            </ElementCard>
        )
    })

    return (
        <div>
            <div className='row no-margin valign-wrapper padding-left-1 padding-top-1'>
                <i className={ 'material-icons' }>trending_flat</i>
                <h6 className='padding-left-1'>{ i18n.selectDiagnostic }</h6>
            </div>
            <div className='scrollable-card' style={{ maxHeight: '70%' }}>
                {diagnosticsContent}
            </div>
        </div>
    )
}

ActionChoicePanel.propTypes = {
    event: PropTypes.object,
    diagnostics: PropTypes.arrayOf(PropTypes.instanceOf(DiagnosticDto)),
    onChangeDiagnostic: PropTypes.func,
    eventPiezometerDiagnostics: PropTypes.arrayOf(PropTypes.instanceOf(DiagnosticDto)),
    eventPiezometerDiagnosticsLinkEventTypes: PropTypes.arrayOf(PropTypes.instanceOf(DiagnosticLinkEventTypeDto)),
    eventPiezometerDiagnosticsLinkMaterials: PropTypes.arrayOf(PropTypes.instanceOf(DiagnosticLinkMaterialDto)),
    stationCentral: PropTypes.arrayOf(PropTypes.instanceOf(DtoCentral)),
    stationPowerSupply: PropTypes.arrayOf(PropTypes.instanceOf(DtoPowerSupply)),
    stationSensor: PropTypes.arrayOf(PropTypes.instanceOf(DtoSensor)),
    stationSim: PropTypes.arrayOf(PropTypes.instanceOf(DtoSim)),
    stationTelecom: PropTypes.arrayOf(PropTypes.instanceOf(DtoTelecom)),
    stationVariousMateriel: PropTypes.arrayOf(PropTypes.instanceOf(DtoVariousMateriel)),
    stationEquipment: PropTypes.arrayOf(PropTypes.instanceOf(DtoEquipment)),
    materielTypes: PropTypes.arrayOf(PropTypes.instanceOf(MaterielTypeDto)),
}

const mapStateToProps = store => ({
    eventPiezometerDiagnostics: store.EventsReducer.eventPiezometerDiagnostics,
    eventPiezometerDiagnosticsLinkEventTypes: store.EventsReducer.eventPiezometerDiagnosticsLinkEventTypes,
    eventPiezometerDiagnosticsLinkMaterials: store.EventsReducer.eventPiezometerDiagnosticsLinkMaterials,
    stationCentral: store.StationReducer.stationCentral,
    stationPowerSupply: store.StationReducer.stationPowerSupply,
    stationSensor: store.StationReducer.stationSensor,
    stationSim: store.StationReducer.stationSim,
    stationTelecom: store.StationReducer.stationTelecom,
    stationVariousMateriel: store.StationReducer.stationVariousMateriel,
    stationEquipment: store.StationReducer.stationEquipment,
    materielTypes: store.MaterielReducer.materielTypes,
})

export default connect(mapStateToProps)(ActionChoicePanel)