module.exports = {
    SUM_AUTO: 'SUM_AUTO',
    ALL: 'all',
    AUTO: 'auto',
    SUM_HOUR: 'SUM_HOUR',
    SUM_DAY: 'SUM_DAY',
    SUM_WEEK: 'SUM_WEEK',
    SUM_DECADE: 'SUM_DECADE',
    SUM_MONTH: 'SUM_MONTH',
    SUM_YEAR: 'SUM_YEAR',
    CUMUL: 'CUMUL',

    DAY: 'DAY',
    HOUR: 'HOUR',

    CUMUL_PERSO_MAX: 'CUMUL_PERSO_MAX',
    CUMUL_PERSO_MIN: 'CUMUL_PERSO_MIN',
    CUMUL_PERSO_SUM: 'CUMUL_PERSO_SUM',
    CUMUL_PERSO_AVERAGE: 'CUMUL_PERSO_AVERAGE',
    PERSONALIZED_GROUPING: 'personalizedGrouping',
    PLUVIO_TAB_DISPLAY_MODES: 'PLUVIO_TAB_DISPLAY_MODES',
}