import { Button as ButtonMUI, ButtonGroup, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import SimpleDatePicker from 'components/forms/SimpleDatePicker'
import ReactEcharts from 'echarts-for-react'
import 'echarts/lib/chart/line'
import 'echarts/lib/chart/lines'
import { flatMap, maxBy, minBy, round, template, uniqBy } from 'lodash'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import { getDate, getFullDate, getHour } from 'utils/DateUtil'
import {
    exportExcelIcon,
    exportPictureIcon,
    setYOptionsPiezo,
    yAutomaticScale,
} from '../../../../components/echart/EChartUtils'
import Button from '../../../../components/forms/Button'
import Checkbox from '../../../../components/forms/Checkbox'
import Icon from '../../../../components/icon/Icon'
import DtoHydrometrySituation from '../../../../hydrometry/dto/DtoHydrometrySituation'
import DtoHydrometryThreshold from '../../../../hydrometry/dto/DtoHydrometryThreshold'
import { MEASURE_COTE } from '../../../../piezometry/constants/PiezometryConstants'
import PiezometerAllMeasureDto from '../../../../piezometry/dto/PiezometerAllMeasureDto'
import PluviometerThresholdDto from '../../../../pluviometry/dto/PluviometerThresholdDto'
import DtoPiezometerChartOptions from '../../../../station/dto/piezometer/DtoPiezometerChartOptions'
import DtoPiezoThreshold from '../../../../station/dto/piezometer/DtoPiezoThreshold'
import { getColorFromPalettePluvio, getThresholdColor } from '../../../../utils/ColorUtil'
import { exportFile } from '../../../../utils/ExportDataUtil'
import { getLocalStorageJson, setLocalStorageJson, sieauTooltip } from '../../../../utils/FormUtils'
import { hasValue } from '../../../../utils/NumberUtil'
import { formatPluvioMeasures } from '../../../../utils/PluviometryUtils'
import { execByType } from '../../../../utils/StationUtils'
import {
    H24,
    H48,
    HISTORY,
    ONE_MONTH,
    ONE_WEEK,
    ONE_YEAR,
    OTHER,
    SIX_MONTHS,
} from '../../../constants/ChartFollowContants'
import { HYDRO, PIEZO, PLUVIO } from '../../../constants/MeasureTypeContants'
import { getStationMeasuresChart } from '../../../utils/MeasureUtil'

const chartsDateFormat = 'YYYY/MM/DD HH:mm:ss'

class FollowChartPanel extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            open: false,
            selectedBeginDate: props.beginDate,
            selectedEndDate: props.endDate,
            otherMinDate: undefined,
            otherMaxDate: undefined,
            showDatesDropdown: false,
            piezometerChartOptions: [],
            showGroupedDateDropdown: false,
            pluvioGrouped: 'SUM_AUTO',
            pluvioStacked: false,
            settingsTabSelected: false,
            piezometerCote: getLocalStorageJson('DISPLAY_COTE') || MEASURE_COTE.DEPTH,
        }
    }

    handleClose = () => {
        this.setState({
            open: false,
            selectedBeginDate: undefined,
            selectedEndDate: undefined,
        })
    }

    createAxis = (name, measureType, position, type = 'value', min, inverse = false, offset = 0) => ({
        name,
        type,
        position,
        measureType,
        inverse,
        min,
        nameLocation: 'center',
        nameGap: 30,
        boundaryGap: ['0%', '90%'],
        offset,
        splitLine: {
            show: true,
        },
        show: true,
    })

    getPiezometerOption = (type) => {
        if (type == 0) {
            return this.state.piezometerChartOptions.find(opt => !opt.dataType || parseInt(opt.dataType) <= 0) || {}
        }
        return this.state.piezometerChartOptions.find(opt => type == opt.dataType) || {}
    }

    getYAxis = (stationTypeSelected, listOfMesures) => {
        const pluvioIndex = stationTypeSelected.indexOf(PLUVIO)
        return stationTypeSelected.map((type, index) => {
            const position = pluvioIndex === -1 && index === 1 ? 'right' : 'left'
            const offset = (() => {
                if (pluvioIndex === -1) {
                    return index === 2 ? 60 : 0
                }
                if (pluvioIndex <= 2) {
                    return (index > pluvioIndex ? index - 1 : index) * 60
                }
                return index * 60
            })()
            switch (type) {
                case `${HYDRO}_HEIGHT`:
                    return this.createAxis(`${i18n.hydrometry} : ${i18n.height} (m)`, `${HYDRO}_HEIGHT`, position, 'value', undefined, false, offset)
                case `${HYDRO}_FLOW`:
                    return this.createAxis(`${i18n.hydrometry} : ${i18n.flow} `, `${HYDRO}_FLOW`, position, 'value', undefined, false, offset)
                case PLUVIO:
                    return this.createAxis(`${i18n.pluviometry} : ${i18n.rain} (mm)`, PLUVIO, stationTypeSelected.length === 1 ? 'left' : 'right', 'value', 0, true)
                case PIEZO:
                    const measures = listOfMesures.filter(f => f.measureType === PIEZO).map(a => a.measures)
                    const yScale = yAutomaticScale(...measures)
                    const yAxis = this.createAxis(`${i18n.chronic} (en m) - ${i18n.depthLastlandmark}`, PIEZO, position, 'value', undefined, (getLocalStorageJson('DISPLAY_COTE') || MEASURE_COTE.DEPTH) !== MEASURE_COTE.NGF, offset)
                    const option = this.getPiezometerOption(-1)
                    return { ...yAxis, ...yScale, ...setYOptionsPiezo(option, yScale, -1) }
                default:
                    return {}
            }
        })
    }

    getXAxis = (firstDate, lastDate) => ({
        type: 'time',
        position: 'bottom',
        min: firstDate.format(chartsDateFormat),
        max: lastDate.format(chartsDateFormat),
        axisLabel: {
            show: true,
            formatter: value => getDate(value),
        },
        axisPointer: {
            show: true,
            label: {
                show: true,
                formatter: ({ value }) => getFullDate(value),
            },
        },
        splitLine: {
            show: false,
        },
        show: true,
    })

    getData = (measures = []) => {
        return measures.map(o => ({
            ...o,
            measures: o.measures.map(b => ({
                name: b.id,
                value: [
                    b.measureDate,
                    b.value,
                ],
            })),
        }))
    }

    getMeasures = () => {
        const { endDate = moment(), beginDate, listOfSites = [] } = this.props
        return listOfSites.flatMap(site => {
            return execByType(site.typeName, {
                hydrometry: () => [
                    {
                        stationId: site.id,
                        stationCode: site.code,
                        stationName: `${site.name ? site.name : ''} ${i18n.hydrometry}: ${i18n.height}`,
                        measureType: `${HYDRO}_HEIGHT`,
                        measures: getStationMeasuresChart((site.measures || []).filter(m => m.typeId === 4), beginDate && beginDate.valueOf(), endDate.valueOf(), 'value'),
                    },
                    {
                        stationId: site.id,
                        stationCode: site.code,
                        stationName: `${site.name ? site.name : ''} ${i18n.hydrometry}: ${i18n.flow}`,
                        measureType: `${HYDRO}_FLOW`,
                        measures: getStationMeasuresChart((site.measures || []).filter(m => m.typeId === 5), beginDate && beginDate.valueOf(), endDate.valueOf(), 'value'),
                    },
                ],
                pluviometry: () => [
                    {
                        stationId: site.id,
                        stationCode: site.code,
                        stationName: `${site.name ? site.name : ''} ${i18n.pluviometry}`,
                        measureType: PLUVIO,
                        measures: formatPluvioMeasures(site.measures || [], beginDate && beginDate.valueOf(), endDate.valueOf(), this.state.pluvioGrouped),
                    },
                ],
                piezometry: () => [
                    {
                        stationId: site.id,
                        stationCode: site.code,
                        stationName: `${site.name ? site.name : ''} ${i18n.piezometry}`,
                        measureType: PIEZO,
                        measures: getStationMeasuresChart(site.measures || [], beginDate && beginDate.valueOf(), endDate.valueOf(), 'value'),
                    },
                ],
                default: () => [],
            })
        })
    }

    getThresholdMarkLine = (listOfThresholds) => {
        if (listOfThresholds && listOfThresholds.length) {
            return {
                silent: true,
                data: listOfThresholds.map(t => {
                    return {
                        yAxis: t.value,
                        symbol: 'none',
                        label: {
                            show: true,
                            position: 'middle',
                            formatter: () => t.title ? `${t.title} : ${round(t.value, 2)}` : '',
                        },
                        lineStyle: {
                            normal: {
                                color: getThresholdColor(t.color),
                                type: 'solid',
                            },
                        },
                    }
                }),
            }
        }
        return undefined
    }

    createLine = (data, yIndex, listOfThresholds, firstDate, lastDate, option = {}) => ({
        name: data.stationName,
        type: 'line',
        yAxisIndex: yIndex,
        showSymbol: false,
        hoverAnimation: true,
        connectNulls: true,
        silent: true,
        animationThreshold: 1000,
        animationDuration: (idx) => idx * 50,
        data: data.measures,
        markLine: this.getThresholdMarkLine(listOfThresholds, firstDate, lastDate),
        ...option,
    })

    createBar = (data, yIndex, listOfThresholds, firstDate, lastDate, nbMeasures, color) => {
        const barWidth = (() => {
            if (nbMeasures > 1000) {
                return '2px'
            }
            if (nbMeasures > 600) {
                return '3px'
            }
            if (nbMeasures > 100) {
                return '4px'
            }
            if (nbMeasures > 50) {
                return '6px'
            }
            if (nbMeasures > 20) {
                return '8px'
            }
            return '15px'
        })()
        return {
            name: data.stationName,
            type: 'bar',
            barWidth,
            yAxisIndex: yIndex,
            animationThreshold: 1000,
            animationDuration: (idx) => idx * 50,
            data: data.measures,
            color,
            stack: this.state.pluvioStacked ? 'pluvioStack' : null,
            markLine: this.getThresholdMarkLine(listOfThresholds, firstDate, lastDate),
        }
    }

    createSeries = (listOfMeasures, stationTypeSelected, firstDate, lastDate) => {
        const { hydrometryThresholds, pluviometerAllThresholds, piezometerThresholds } = this.props
        const maxDataPluvio = (maxBy(listOfMeasures.filter(l => l.measureType === PLUVIO), l => l.measures.length) || { measures: [] }).measures.length
        return uniqBy(listOfMeasures.map((o, idx) => {
            switch (o.measureType) {
                case `${HYDRO}_HEIGHT`:
                    const hydroHeightThresholds = hydrometryThresholds.filter(({ stationId }) => stationId == o.stationId).filter(t => t.dataType === '4')
                    return this.createLine(o, stationTypeSelected.indexOf(`${HYDRO}_HEIGHT`), hydroHeightThresholds, firstDate, lastDate)
                case `${HYDRO}_FLOW`:
                    const hydroFlowThresholds = hydrometryThresholds.filter(({ stationId }) => stationId == o.stationId).filter(t => t.dataType === '5')
                    return this.createLine(o, stationTypeSelected.indexOf(`${HYDRO}_FLOW`), hydroFlowThresholds, firstDate, lastDate)
                case PLUVIO:
                    const pluvioThresholds = pluviometerAllThresholds.filter(({ code }) => code === o.stationCode)
                    if (maxDataPluvio > 10000) {
                        return this.createLine(o, stationTypeSelected.indexOf(PLUVIO), pluvioThresholds, firstDate, lastDate, {
                            areaStyle: {},
                            color: getColorFromPalettePluvio(idx),
                        })
                    }
                    return this.createBar(o, stationTypeSelected.indexOf(PLUVIO), pluvioThresholds, firstDate, lastDate, maxDataPluvio, getColorFromPalettePluvio(idx))
                case PIEZO:
                    const piezoThresholds = piezometerThresholds.filter(({ code }) => code === o.stationCode)
                    return this.createLine(o, stationTypeSelected.indexOf(PIEZO), piezoThresholds, firstDate, lastDate)
                default:
                    return {}
            }
        }), 'name')
    }

    getLegend = (data = []) => ({
        data: data.map(o => o.stationName),
        top: 'bottom',
        type: 'scroll',
    })

    getDataZoom = () => [
        {
            type: 'slider',
            show: true,
            handleSize: 25,
            bottom: this.getBottomFromNumberOfElement(30),
        },
        {
            type: 'inside',
        },
    ]

    getBottomFromNumberOfElement(base) {
        const numberOfSites = this.props.listOfSites.length
        return numberOfSites ? base + (25 * Math.floor((numberOfSites - 1) / 4)) : base
    }

    getExportData = () => {
        const data = this.getMeasures().flatMap(measure => {
            return measure.measures.map(m => ({
                stationName: measure.stationName,
                date: getDate(m.date || m.creationDate || m.value[0]),
                hour: getHour(m.hour || m.date || m.value[0]),
                value: m.value[1],
            }))
        })
        if (data.length) {
            data[0] = { ...data[0], headers: ['stationName', 'date', 'hour', 'value'] }
        }
        return data
    }

    getTooltip = (series) => ({
        trigger: 'axis',
        axisPointer: {
            type: 'shadow',
            animation: false,
            label: {
                backgroundColor: '#505765',
            },
        },
        formatter: params => {
            const date = getFullDate(params[0].axisValue)
            const pluvioValues = series.filter(s => s.type === 'bar').map(bar => {
                return {
                    ...bar,
                    data: bar.data.find(d => getDate(d.value[0]) === getDate(params[0].axisValue)),
                    marker: `<span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:${bar.color.toString()};"></span>`,
                }
            })
            const listOfData = uniqBy(params, 'seriesName')
                .filter(({ seriesType, value }) => seriesType !== 'bar' && hasValue(value[1]))
                .map(o => `${o.marker} ${o.seriesName} : ${o.value[1]}`)
            return `${date}<br />${[
                ...listOfData,
                ...pluvioValues
                    .filter(o => hasValue(o.data) && hasValue(o.data.value[1]))
                    .map(o => `${o.marker} ${o.name} : ${o.data.value[1]}`),
            ].join('<br/>')}`
        },
    })

    getOption = () => {
        const { endDate = moment(), beginDate } = this.props

        const listOfMeasures = this.getMeasures()
        const stationTypeSelected = listOfMeasures.reduce((acc, m) => {
            if (!acc.includes(m.measureType)) {
                return [...acc, m.measureType]
            }
            return acc
        }, [])
        const firstMeasure = minBy(flatMap(listOfMeasures, m => m.measures), m => m.value[0])
        const lastDate = moment(endDate)
        const firstDate = moment(beginDate || firstMeasure && firstMeasure.value[0])
        const yAxis = this.getYAxis(stationTypeSelected, listOfMeasures)
        const xAxis = this.getXAxis(firstDate, lastDate)
        const series = this.createSeries(listOfMeasures, stationTypeSelected, firstDate, lastDate)
        const title = (() => {
            if (this.props.selectedTime === HISTORY) {
                return i18n.dataHistory
            }
            return template(i18n.dataFromStartToEnd)({
                start: getDate(firstDate),
                end: getDate(lastDate),
            })
        })()
        return {
            title: {
                left: 'center',
                text: title,
            },
            tooltip: this.getTooltip(series),
            legend: this.getLegend(listOfMeasures),
            grid: {
                left: 50 * (stationTypeSelected.length > 1 ? stationTypeSelected.length - 1 : 1),
                right: 50,
                bottom: this.getBottomFromNumberOfElement(90),
            },
            xAxis,
            yAxis,
            series,
            toolbox: {
                show: true,
                feature: {
                    saveAsImage: { title: i18n.export, icon: exportPictureIcon },
                    myToolExport: {
                        show: true,
                        title: i18n.excelExport,
                        icon: exportExcelIcon,
                        onclick: () => {
                            exportFile({
                                data: this.getExportData(),
                                exportType: 'xlsx',
                                titleFile: `follow_chart_${moment().valueOf()}`,
                            })
                        },
                    },
                },
            },
            dataZoom: this.getDataZoom(),
        }
    }

    changeDeterminedDateValue = (minDate, maxDate) => {
        const newState = {
            selectedTime: OTHER,
            beginDate: minDate,
            endDate: maxDate,
        }

        this.setState({ showDatesDropdown: false })
        this.props.changeParentState(newState)
    }

    onSelectOtherTab = () => {
        const newState = this.props.selectedTime !== OTHER ? {
            showDatesDropdown: true,
        } : {
            showDatesDropdown: !this.state.showDatesDropdown,
        }

        this.props.changeParentState({ selectedTime: OTHER })
        this.setState(newState)
    }

    changeGroupedDate = (group) => {
        this.setState({ pluvioGrouped: group, showGroupedDateDropdown: false })
        this.props.changeParentState({ pluvioGrouped: group })
    }

    onShowGroupedDate = () => {
        const newState = this.state.showGroupedDateDropdown ? { showGroupedDateDropdown: false } : { showGroupedDateDropdown: true }
        this.setState(newState)
    }

    getFilterDate = () => {
        const listOfDates = [
            {
                title: '24H',
                value: H24,
            },
            {
                title: '48H',
                value: H48,
            },
            {
                title: `1 ${i18n.week}`,
                value: ONE_WEEK,
            },
            {
                title: `1 ${i18n.month}`,
                value: ONE_MONTH,
            },
            {
                title: `6 ${i18n.month}`,
                value: SIX_MONTHS,
            },
            {
                title: `1 ${i18n.year}`,
                value: ONE_YEAR,
            },
            {
                title: i18n.history,
                value: HISTORY,
            },
        ]
        return (
            <div className='row no-margin'>
                <ButtonGroup style={{ backgroundColor: 'white' }}>
                    {
                        listOfDates.map(({
                            title,
                            value,
                        }) => (
                            <ButtonMUI
                                variant={this.props.selectedTime === value ? 'contained' : 'outlined'}
                                color='primary'
                                onClick={() => this.props.changeSelectedDateValue(value)}
                            >{title}</ButtonMUI>
                        )).concat(<ButtonMUI variant={this.props.selectedTime === OTHER ? 'contained' : 'outlined'} color='primary' onClick={this.onSelectOtherTab} >{i18n.otherPeriod}</ButtonMUI>)
                    }
                </ButtonGroup>
                {this.props.listOfSites.some(s => s.typeName === 'pluviometry') && <Icon className='relative' tooltip={i18n.pluvioTotal} style={{ top: '6px', margin: '0 5px' }} icon={'settings'} onClick={this.onShowGroupedDate} />}
                <div className={this.state.showDatesDropdown && this.props.selectedTime === OTHER ? 'chartDates-dropdown z-index-10' : 'hidden'}>
                    <div style={{ height: '10px' }} />
                    <div className='sieau-dropdown-panel padding-top-1 padding-bottom-1' style={{ width: '200px' }}>
                        <div className='collection-item row no-margin valign-wrapper' >
                            <SimpleDatePicker
                                id='chartOtherDatesMinDate'
                                label={i18n.startDate}
                                value={this.state.otherMinDate}
                                onChange={v => this.setState({ otherMinDate: v }) }
                                col={12}
                            />
                        </div>
                        <div className='collection-item row no-margin valign-wrapper padding-top-1'>
                            <SimpleDatePicker
                                id='chartOtherDatesMaxDate'
                                label={i18n.endDate}
                                value={this.state.otherMaxDate}
                                onChange={v => this.setState({ otherMaxDate: v })}
                                col={12}
                            />
                        </div>
                        <div className='row no-margin padding-top-1 padding-left-1 padding-right-1'>
                            <Button col={12} onClick={() => this.changeDeterminedDateValue(this.state.otherMinDate, this.state.otherMaxDate)} title={i18n.toLoad} />
                        </div>
                    </div>
                </div>
                <div className={this.state.showGroupedDateDropdown ? 'chartDates-dropdown z-index-10' : 'hidden'}>
                    <div style={{ height: '10px' }} />
                    <div className='sieau-dropdown-panel padding-top-1 padding-bottom-1  margin-top-1' style={{ width: '200px' }}>
                        <h5 className='center'>{i18n.dataPluvio}</h5>
                        <div className='collection-item row no-margin valign-wrapper padding-left-1' >
                            <Checkbox checked={this.state.pluvioGrouped === 'SUM_AUTO'} label={'Auto'} onChange={() => this.changeGroupedDate('SUM_AUTO') } />
                            <div style={ { 'padding-left': 5 } }/>
                            <Icon icon='info' style={ { fontSize: 18, color: 'grey' } }
                                tooltip={(
                                    <p className='no-margin'>Mode d'affichage par défaut.<br/>
                                          Les données de pluie sont cumulées en fonction de la période affichée.<br/>
                                        {'Si Période < 48 heures => Cumul horaire'}<br/>
                                        {'Si Période < 3 mois    => Cumul journalier'}<br/>
                                        {'Si Période < 1 an      => Cumul hebdomadaire'}<br/>
                                        {'Si Période < 5 ans     => Cumul mensuel'}<br/>
                                        {'Si Période > 5 ans     => Cumul annuel'}<br/>
                                    </p>
                                )}
                            />
                        </div>
                        <div className='collection-item row no-margin valign-wrapper padding-left-1' >
                            <Checkbox checked={this.state.pluvioGrouped === 'all'} label={`${i18n.allMeasures}`} onChange={() => this.changeGroupedDate('all')} />
                        </div>
                        <div className='collection-item row no-margin valign-wrapper padding-left-1' >
                            <Checkbox checked={this.state.pluvioGrouped === 'SUM_HOUR'} label={`${i18n.hourTotal}`} onChange={() => this.changeGroupedDate('SUM_HOUR')} />
                        </div>
                        <div className='collection-item row no-margin valign-wrapper padding-left-1' >
                            <Checkbox checked={this.state.pluvioGrouped === 'SUM_DAY'} label={`${i18n.dailyTotal}`} onChange={() => this.changeGroupedDate('SUM_DAY')} />
                        </div>
                        <div className='collection-item row no-margin valign-wrapper padding-left-1' >
                            <Checkbox checked={this.state.pluvioGrouped === 'SUM_WEEK'} label={`${i18n.weeklyTotal}`} onChange={() => this.changeGroupedDate('SUM_WEEK')} />
                        </div>
                        <div className='collection-item row no-margin valign-wrapper padding-left-1' >
                            <Checkbox checked={this.state.pluvioGrouped === 'SUM_DECADE'} label={`${i18n.decadeTotal}`} onChange={() => this.changeGroupedDate('SUM_DECADE')} />
                        </div>
                        <div className='collection-item row no-margin valign-wrapper padding-left-1' >
                            <Checkbox checked={this.state.pluvioGrouped === 'SUM_MONTH'} label={`${i18n.monthlyTotal}`} onChange={() => this.changeGroupedDate('SUM_MONTH')} />
                        </div>
                        <div className='collection-item row no-margin valign-wrapper padding-left-1 padding-bottom-1' >
                            <Checkbox checked={this.state.pluvioGrouped === 'SUM_YEAR'} label={`${i18n.yearTotal}`} onChange={() => this.changeGroupedDate('SUM_YEAR')} />
                        </div>
                        <div className='divider' />
                        {
                            this.props.listOfSites.filter(s => s.typeName === 'pluviometry').length >= 2 && (
                                <div className='collection-item row no-margin valign-wrapper padding-left-1' >
                                    <Checkbox checked={this.state.pluvioStacked} label={i18n.stackPluviometers} onChange={v => this.setState({ pluvioStacked: v, showGroupedDateDropdown: false })} />
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>
        )
    }

    getDisplayCote = () => {
        const piezometerCote = getLocalStorageJson('DISPLAY_COTE') || MEASURE_COTE.DEPTH
        const cote = (() => {
            switch (piezometerCote) {
                case MEASURE_COTE.DEPTH:
                    return { text: i18n.depthLastlandmark, className: 'blue' }
                case MEASURE_COTE.NGF:
                    return { text: 'NGF', className: 'indigo darken-3' }
                case MEASURE_COTE.GROUND:
                    return { text: i18n.ground, className: 'orange' }
                case MEASURE_COTE.LANDMARK:
                    return { text: i18n.landmarkHistory, className: 'brown darken-1' }
                default:
                    return null
            }
        })()
        return (
            <span
                onClick={() => this.setState({ settingsTabSelected: !this.state.settingsTabSelected, otherTabSelected: false })}
                className={`margin-left-1 blue arrests-level-panel cote-medal clickable white-text ${cote.className}`}
            >
                {cote.text}
            </span>)
    }

    onSubmitCote = () => {
        const { piezometerCote } = this.state
        setLocalStorageJson('DISPLAY_COTE', piezometerCote)
        this.setState({ settingsTabSelected: false })
        this.props.onChangeCote(piezometerCote)
    }

    render() {
        return (
            <div className='row no-margin '>
                <div className='col s12 no-padding '>
                    <div className='row no-margin'>
                        <Dialog
                            onClose={this.handleClose}
                            fullWidth
                            maxWidth='lg'
                            open={this.state.open}
                        >
                            <DialogTitle>{i18n.selection}</DialogTitle>
                            <DialogContent>
                                <SimpleDatePicker
                                    hintText={i18n.startData}
                                    locale='fr'
                                    value={this.state.selectedBeginDate}
                                    DateTimeFormat={global.Intl.DateTimeFormat}
                                    onChange={(_, selectedBeginDate) => this.setState({ selectedBeginDate })}
                                />
                                <SimpleDatePicker
                                    hintText={i18n.endData}
                                    locale='fr'
                                    value={this.state.selectedEndDate}
                                    DateTimeFormat={global.Intl.DateTimeFormat}
                                    onChange={(_, selectedEndDate) => this.setState({ selectedEndDate })}
                                />
                            </DialogContent>
                            <DialogActions>
                                <ButtonMUI onClick={this.handleClose} variant='outlined' >
                                    {i18n.cancel}
                                </ButtonMUI>
                                <Button onClick={this.handleClose} variant='contained' color='primary'>
                                    {i18n.validate}
                                </Button>
                            </DialogActions>
                        </Dialog>
                        <div className='col s12 no-padding'>
                            <div className='row no-margin'>
                                <div className='col s12 no-margin no-padding'>
                                    {this.getFilterDate()}
                                </div>
                            </div>
                            <div className='row card'>
                                <div className='col s4 right margin-top-1'>
                                    {this.getDisplayCote()}
                                    <div className={this.state.settingsTabSelected ? 'chartDates-dropdown' : 'hidden'} style={{ zIndex: '1' }}>
                                        <div style={{ height: '10px' }} />
                                        <div className='sieau-dropdown-panel padding-top-1 padding-bottom-1' style={{ width: '250px' }}>
                                            <div className='collection-item row no-margin valign-wrapper padding-left-1' >
                                                <Checkbox onChange={() => this.setState({ piezometerCote: MEASURE_COTE.DEPTH })} checked={this.state.piezometerCote === MEASURE_COTE.DEPTH} />
                                                <span className={'blue arrests-level-panel white-text'}>{i18n.depthLastlandmark}</span>
                                            </div>
                                            <div className='collection-item row no-margin valign-wrapper padding-left-1' >
                                                <Checkbox onChange={() => this.setState({ piezometerCote: MEASURE_COTE.NGF })} checked={this.state.piezometerCote === MEASURE_COTE.NGF} />
                                                <span className={'indigo darken-3 arrests-level-panel white-text'}>NGF</span>
                                            </div>
                                            <div className='collection-item row no-margin valign-wrapper padding-left-1' >
                                                <Checkbox onChange={() => this.setState({ piezometerCote: MEASURE_COTE.GROUND })} checked={this.state.piezometerCote === MEASURE_COTE.GROUND} />
                                                <span className={'orange arrests-level-panel white-text'}>{i18n.ground}</span>
                                            </div>
                                            <div className='collection-item row no-margin valign-wrapper padding-left-1' >
                                                <Checkbox onChange={() => this.setState({ piezometerCote: MEASURE_COTE.LANDMARK })} checked={this.state.piezometerCote === MEASURE_COTE.LANDMARK} />
                                                <span className={'brown darken-1 arrests-level-panel white-text'}>{i18n.landmarkHistory}</span>
                                            </div>
                                            <div className='row no-margin padding-top-1 padding-left-1 padding-right-1'>
                                                <Button col={12} onClick={this.onSubmitCote} title={i18n.toLoad} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col s12 card-content'>
                                    <ReactEcharts
                                        option={this.getOption()}
                                        style={{ minHeight: 'calc(100vh - 170px)' }}
                                        notMerge
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

FollowChartPanel.propTypes = {
    listOfSites: PropTypes.arrayOf(PropTypes.object),
    hydrometrySituation: PropTypes.arrayOf(PropTypes.instanceOf(DtoHydrometrySituation)),
    hydrometryThresholds: PropTypes.arrayOf(PropTypes.instanceOf(DtoHydrometryThreshold)),
    pluviometerAllThresholds: PropTypes.arrayOf(PropTypes.instanceOf(PluviometerThresholdDto)),
    piezometerThresholds: PropTypes.arrayOf(PropTypes.instanceOf(DtoPiezoThreshold)),
    piezometrySituation: PropTypes.arrayOf(PropTypes.instanceOf(PiezometerAllMeasureDto)),
    piezometerChartOptions: PropTypes.arrayOf(PropTypes.instanceOf(DtoPiezometerChartOptions)),
    changeSelectedDateValue: PropTypes.func,
    changeParentState: PropTypes.func,
    onChangeCote: PropTypes.func,
    selectedTime: PropTypes.string,
    endDate: PropTypes.instanceOf(Date),
    beginDate: PropTypes.instanceOf(Date),
}

FollowChartPanel.defaultProps = {
    hydrometrySituation: [],
    onChangeCote: () => { },
}

const mapStateToProps = store => ({
    hydrometrySituation: store.HydrometryReducer.hydrometrySituation,
    hydrometryThresholds: store.HydrometryReducer.hydrometryThresholds,
    pluviometerAllThresholds: store.PluviometryReducer.pluviometerAllThresholds,
    piezometerChartOptions: store.PiezometerStationReducer.piezometerChartOptions,
    piezometerThresholds: store.PiezometerStationReducer.allPiezometerThresholds,
    piezometrySituation: store.PiezometryReducer.piezometrySituation,
})

export default connect(mapStateToProps)(FollowChartPanel)
