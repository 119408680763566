/* eslint-disable camelcase */
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { orderBy } from 'lodash'
import i18n from 'simple-react-i18n'
import moment from 'moment'

import DtoSandreCode from '../../../referencial/dto/DtoSandreCode'
import DtoContact from '../../../quality/components/operation/dto/DtoContact'
import { getSandreLabel } from '../../../utils/StringUtil'
import { SANDRE } from '../../../referencial/constants/ReferencialConstants'
import AccessibilityModal from './modals/AccessibilityModal'
import Table from '../../../components/datatable/Table'
import SieauAction from '../../../components/sieau/SieauAction'
import DtoProductionUnit from '../../../productionUnit/dto/DtoProductionUnit'
import DtoDistributionUnit from '../../../distributionUnit/dto/DtoDistributionUnit'
import DtoQualitometer from '../../../quality/dto/DtoQualitometer'
import DtoPiezometer from '../../../piezometry/dto/DtoPiezometer'
import DtoHydrometricStation from '../../../hydrometry/dto/DtoHydrometricStation'
import DtoInstallation from '../../../installation/dto/installation/DtoInstallation'
import { getExport } from '../../../utils/linkUtils'
import { getFullDate } from '../../../utils/DateUtil'

const StationAccessibilitiesPanel = ({
    station = {},
    contacts = [],
    sandreCodes = [],
    readMode = false,
    onChange = () => {},
    onShowContact = () => { },
}) => {
    const [dialogParam, setDialogParam] = useState({ open: false })
    const stationAccessibilities = station.link_accessibilities.map((a, index) => ({
        ...a,
        index,
    }))
    const now = moment().valueOf()
    const data = stationAccessibilities.map(access => {
        const contact = contacts.find(c => c.code === access.contactCode)
        return {
            ...access,
            contact: contact && contact.name,
            condition: getSandreLabel(sandreCodes, SANDRE.ACCESSIBILITIES_TYPE_CONDITION, access.codeCondition),
            closing: getSandreLabel(sandreCodes, SANDRE.ACCESSIBILITIES_TYPE_FERMETURE, access.closingCode),
            startDate: getFullDate(access.startDate),
            endDate: getFullDate(access.endDate),
            color: access.endDate < now ? 'c0c0c0' : 'ffffff',
        }
    })
    const tableData = orderBy(data, ['endDate', 'order', 'startDate'])
    const headers = ['startDate', 'endDate', 'description', 'condition', 'closing', 'contact', 'order']
    const exportAction = getExport(tableData, i18n.access, headers)
    const actions = !readMode ? [{
        iconName: 'note_add',
        tooltip: i18n.add,
        onClick: () => setDialogParam({
            open: true,
            id: station.id,
            saveResult: r => {
                setDialogParam({ open: false })
                onChange({ link_accessibilities: [...stationAccessibilities, r] })
            },
        }),
    }, exportAction] : [exportAction]

    return (
        <div id='accessContact'>
            <Table
                title={ i18n.accessContact }
                condensed
                data={ tableData }
                type={{ headers }}
                sortable={ !!tableData.length }
                actions={ actions }
                onClick={({ contactCode }) => onShowContact(contactCode) }
                alterable={ !readMode }
                onAlter={(element) => setDialogParam({
                    open: true,
                    id: station.id,
                    accessibility: stationAccessibilities.find(a => element.index === a.index),
                    saveResult: r => {
                        setDialogParam({ open: false })
                        onChange({
                            link_accessibilities: [
                                ...stationAccessibilities.filter(a => a.index !== element.index),
                                { ...r, index: element.index },
                            ],
                        })
                    },
                })}
                deletable={ !readMode }
                onDelete={ (element) => onChange({ link_accessibilities: stationAccessibilities.filter(a => a.index !== element.index) }) }
                coloredLine
            />
            {dialogParam.open && (
                <AccessibilityModal
                    id={dialogParam.id}
                    accessibility={dialogParam.accessibility}
                    saveResult={dialogParam.saveResult}
                    onCancel={() => setDialogParam({ open: false })}
                />
            )}
        </div>
    )
}

StationAccessibilitiesPanel.propTypes = {
    station: PropTypes.oneOfType([
        PropTypes.instanceOf(DtoProductionUnit),
        PropTypes.instanceOf(DtoDistributionUnit),
        PropTypes.instanceOf(DtoQualitometer),
        PropTypes.instanceOf(DtoPiezometer),
        PropTypes.instanceOf(DtoHydrometricStation),
        PropTypes.instanceOf(DtoInstallation),
    ]),
    contacts: PropTypes.arrayOf(PropTypes.instanceOf(DtoContact)),
    sandreCodes: PropTypes.arrayOf(PropTypes.instanceOf(DtoSandreCode)),
    readMode: PropTypes.bool,
    onChange: PropTypes.func,
    onShowContact: PropTypes.func,
    setPopup: PropTypes.func,
}

const mapStateToProps = store => ({
    contacts: store.ContactReducer.contacts,
    sandreCodes: store.ReferencialReducer.sandreCodes,
})

const mapDispatchToProps = {
    setPopup: SieauAction.setPopup,
}

export default connect(mapStateToProps, mapDispatchToProps)(StationAccessibilitiesPanel)