module.exports = {
    RECEIVE_ALL_VARIOUS_MATERIELS: 'RECEIVE_ALL_VARIOUS_MATERIELS',
    RECEIVE_VARIOUS_MATERIEL: 'RECEIVE_VARIOUS_MATERIEL',
    RESET_VARIOUS_MATERIEL: 'RESET_VARIOUS_MATERIEL',
    RECEIVE_VARIOUS_MATERIEL_TYPES: 'RECEIVE_VARIOUS_MATERIEL_TYPES',
    RECEIVE_VARIOUS_MATERIELS_LAST_SITUATIONS: 'RECEIVE_VARIOUS_MATERIELS_LAST_SITUATIONS',
    RECEIVE_ALL_VARIOUS_MATERIEL_SITUATIONS: 'RECEIVE_ALL_VARIOUS_MATERIEL_SITUATIONS',
    RECEIVE_VARIOUS_MATERIEL_PICTURES: 'RECEIVE_VARIOUS_MATERIEL_PICTURES',
    RECEIVE_VARIOUS_MATERIEL_FILES: 'RECEIVE_VARIOUS_MATERIEL_FILES',
    RECEIVE_MAT_EVENTS_EXPLOITATION: 'RECEIVE_MAT_EVENTS_EXPLOITATION',
    RECEIVE_MAT_EVENTS_TYPE: 'RECEIVE_MAT_EVENTS_TYPE',
}
