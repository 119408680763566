import React, { useEffect, useMemo, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import WatermassAction from '../actions/WatermassAction'
import i18n from 'simple-react-i18n'
import { push } from '@lagunovsky/redux-react-router'
import { carmencartoWatermassDefaultLink, sandreWatermassDefaultLink } from '../../../../conf/SieauConstants'
import {
    PATH_REFERENCIAL,
    PATH_REFERENCIAL_WATERMASS,
    PATH_REFERENCIAL_WATERMASS_ITEM_NEW,
} from '../../../../home/constants/RouteConstants'
import { find } from 'lodash'
import HomeAction from '../../../../home/actions/HomeAction'
import logoSandre from '../../../../assets/pictures/logo_sandre.png'
import logoCarmen from '../../../../assets/pictures/logos/carmen.png'
import ReferencialAction from '../../../action/ReferencialAction'
import { getDataWithHeaders } from 'utils/ExportDataUtil'
import useTitle from 'utils/customHook/useTitle'
import useActions from 'utils/customHook/useActions'
import { getExportReferencialData } from 'referencial/util/ReferencialUtils'
import SearchTable from 'referencial/components/SearchTable'
import { REFERENCIAL_TYPE_NAME } from 'referencial/constants/ReferencialConstants'

const headers = ['code', 'name', 'euroCode', 'watermassType']

const WatermassesApp = () => {
    const {
        watermasses,
        referencialSandreCodes,
    } = useSelector(store => ({
        watermasses: store.WatermassReducer.watermasses,
        referencialSandreCodes: store.ReferencialReducer.referencialSandreCodes,
    }), shallowEqual)

    const [exportData, setExportData] = useState([])

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(WatermassAction.fetchWatermasses())
        dispatch(ReferencialAction.fetchReferencialSandreCodes)
        dispatch(HomeAction.setHelpLink)
    }, [])

    useTitle(() => [{
        title: i18n.referencials,
        href: PATH_REFERENCIAL,
    }, {
        title: i18n.watermasses,
        href: PATH_REFERENCIAL_WATERMASS,
    }], [])

    useActions(() => {
        return {
            new: () => dispatch(push(PATH_REFERENCIAL_WATERMASS_ITEM_NEW)),
            referencialActions: {
                actions: {
                    export: {
                        data: getDataWithHeaders(exportData, headers),
                        titleFile: i18n.watermassList,
                    },
                    sync: {
                        updateAction: () => dispatch(WatermassAction.fetchSynchroSandreWatermass()),
                    },
                    purge: {
                        onPurge: () => dispatch(WatermassAction.purgeWatermass()),
                    },
                },
            },
            links: [{
                href: carmencartoWatermassDefaultLink,
                img: logoCarmen,
                label: i18n.carmen.toUpperCase(),
            }, {
                href: sandreWatermassDefaultLink,
                img: logoSandre,
                label: i18n.sandre.toUpperCase(),
            }],
        }
    }, [exportData])

    const lastUpdate = useMemo(() => find(referencialSandreCodes, o => o.field === 'MASSES_EAUX'), [referencialSandreCodes])

    const data = useMemo(() => getExportReferencialData(watermasses), [watermasses])

    return (
        <SearchTable
            title={i18n.watermassList}
            sessionKey={REFERENCIAL_TYPE_NAME.watermass}
            lastUpdate={lastUpdate}
            data={data}
            setExportData={setExportData}
            headers={headers}
            onClick={watermass => dispatch(push(`/referencial/watermass/${watermass.id}/dashboard`))}
        />
    )
}

export default WatermassesApp
