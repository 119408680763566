import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { Card, CardContent, Grid2, Icon, Tooltip } from '@mui/material'
import { isNil } from 'lodash'

const SimpleTabList = ({
    defaultTab,
    controlTab,
    setControlTab,
    tabs = [],
    children,
    noPadding = false,

    headerComponent,
}) => {
    const [tab, setTab] = useState(defaultTab ?? tabs[0]?.constant)

    if (isNil(controlTab) + isNil(setControlTab) === 1) { // one is undefined and the other is defined
        // eslint-disable-next-line no-console
        console.error('controlTab and setControlTab must both be defined if you want to control the behavior')
    }

    const currentTab = controlTab ?? tab
    const setCurrentTab = setControlTab ?? setTab

    return (
        <Grid2 container columnSpacing={1} alignItems='flex-end'>
            <Grid2 size='grow' sx={{ paddingBottom: '8px' }}>
                {headerComponent}
            </Grid2>
            <Grid2 container size='auto' columnSpacing={0.5} sx={{ marginRight: '5px' }}>
                {tabs.map(({ label, icon, constant }) => {
                    const isCurrent = constant === currentTab
                    return (
                        <Grid2 key={constant} size='auto' sx={{ zIndex: 1 }}>
                            <Tooltip title={label}>
                                <div
                                    style={{
                                        backgroundColor: isCurrent ? 'white' : '#f0f0f0',
                                        borderRadius: '8px 8px 0 0',
                                        textAlign: 'center',
                                        display: 'flex',
                                        boxShadow: '0px -1px 2px 0px rgba(0,0,0,0.05)',
                                        cursor: 'pointer',
                                        padding: '10 22',
                                    }}
                                    onClick={() => {
                                        if (!isCurrent) {
                                            setCurrentTab(constant)
                                        }
                                    }}
                                    data-cy={`tab-${constant}`}
                                >
                                    <Icon fontSize='large' sx={{ color: isCurrent ? 'black' : '#333' }}>{icon}</Icon>
                                </div>
                            </Tooltip>
                        </Grid2>
                    )
                })}
            </Grid2>
            <Grid2 size={12}>
                <Card>
                    <CardContent noPadding={noPadding}>
                        {children(currentTab)}
                    </CardContent>
                </Card>
            </Grid2>
        </Grid2>
    )
}

SimpleTabList.propTypes = {
    defaultTab: PropTypes.string.isRequired,
    controlTab: PropTypes.string,
    setControlTab: PropTypes.func,
    tabs: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string,
        icon: PropTypes.string,
        constant: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })).isRequired,
    children: PropTypes.func.isRequired,
    noPadding: PropTypes.bool,

    headerComponent: PropTypes.element,
}

export default SimpleTabList
