export default class QualityExecutionDto {
    constructor(obj) {
        this.id = obj.id
        this.date = obj.date
        this.duration = obj.duration
        this.status = obj.status
        this.jobId = obj.jobId
        this.comment = obj.comment
        this.stations = obj.stations
        this.operations = obj.operations
        this.analysis = obj.analysis
        this.parameters = obj.parameters
        this.errors = obj.errors
        this.warnings = obj.warnings
        this.files = obj.files
    }
}
