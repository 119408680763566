const parseJsonObj = o => {
    try {
        return JSON.parse(o)
    } catch (e) {
        return {}
    }
}

const parseJsonArr = o => {
    try {
        return JSON.parse(o)
    } catch (e) {
        return {}
    }
}

export { parseJsonObj, parseJsonArr }

