/* eslint-disable camelcase */
import { Button, Divider, Grid } from '@mui/material'
import { styled } from '@mui/styles'
import Card from 'components/card/Card'
import Checkbox from 'components/forms/Checkbox'
import Select from 'components/forms/Select'
import SimpleTextArea from 'components/forms/SimpleTextArea'
import Icon from 'components/icon/Icon'
import { AccordionDetailsMUI, AccordionMUI, AccordionSummaryMUI } from 'components/styled/Accordions'
import DtoCMSParagraph from 'events/dto/survey/DtoCMSParagraph'
import { DANGER_ELEMENT_TYPE } from 'installation/constants/InstallationConstants'
import { groupBy, orderBy } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { SANDRE } from 'referencial/constants/ReferencialConstants'
import i18n from 'simple-react-i18n'
import { greyBlue } from 'utils/constants/ColorTheme'
import useSandreList from 'utils/customHook/useSandreList'
import { hasValue } from 'utils/NumberUtil'
import { getI18nTitleData } from 'utils/StringUtil'
import DtoCMSSurvey from '../../../../events/dto/DtoCMSSurvey'
import { arrayOf } from '../../../../utils/StoreUtils'
import VisitPicture from '../components/VisitPicture'
import DtoDanger from '../dto/DtoDanger'
import DtoDangerDocument from '../dto/DtoDangerDocument'
import DtoVisit from '../dto/DtoVisit'
import AddPicture from '../components/AddPicture'

const CheckButton = styled(({ ...other }) => <Button variant='outlined' {...other} />)({
    borderRadius: props => props.radius === 1 ? '5px 0 0 5px' : props.radius === 3 ? '0 5px 5px 0' : 0,
    border: props => props.border === 'yes' ? 'solid 1px #79da4c' : props.border === 'no' ? 'solid 1px #f32525' : 'solid 1px grey',
})

const LinkedQuestion = ({
    danger,
    cmsSurvey,
    visit,
}) => {
    const paragraph = cmsSurvey?.link_paragraphs.find(lp => lp.idParagraph === danger.idLinkedParagraph)
    const question = paragraph && paragraph.link_questions.find(lq => lq.idQuestion === danger.idLinkedQuestion) || {}
    const answer = visit.link_answers.find(la => la.idQuestion === danger.idLinkedQuestion) || {}

    const sandreFieldList = useSandreList(question.sandreField)

    return (
        <Grid container justifyContent='space-around' style={{ paddingBottom: '10px' }}>
            <Grid item container xs={12} direction='row' alignItems='center'>
                <Grid item xs={8} style={{ padding: '0 10px' }}>
                    <h5>{question?.description}</h5>
                </Grid>
                <Grid container item xs={4} justifyContent='flex-end' alignItems='center'>
                    <Grid item>
                        <CheckButton
                            radius={1}
                            border={answer.answerMultiChoice}
                            style={{
                                backgroundColor: answer.answerMultiChoice === 'yes' ? '#79da4c' : 'white',
                                color: answer.answerMultiChoice === 'yes' ? 'white' : 'black',
                            }}
                        >
                            {i18n.yes}
                        </CheckButton>
                        <CheckButton
                            radius={2}
                            border={answer.answerMultiChoice}
                            style={{
                                backgroundColor: answer.answerMultiChoice === 'no' ? '#f32525' : 'white',
                                color: answer.answerMultiChoice === 'no' ? 'white' : 'black',
                            }}
                        >
                            {i18n.no}
                        </CheckButton>
                        <CheckButton
                            radius={3}
                            border={answer.answerMultiChoice}
                            style={{
                                backgroundColor: answer.answerMultiChoice === 'withoutObject' ? 'grey' : 'white',
                                color: answer.answerMultiChoice === 'withoutObject' ? 'white' : 'black',
                            }}
                        >
                            {i18n.withoutObject}
                        </CheckButton>
                    </Grid>
                </Grid>
                <Grid item xs={6}>
                    {question.sandreField && (
                        <Select
                            col={12}
                            obligatory
                            options={sandreFieldList}
                            keyValue='code'
                            keyLabel='name'
                            noNullValue={true}
                            label={i18n.state}
                            value={answer.answerLong}
                            disabled
                        />
                    )}
                </Grid>
                <Grid item container xs={6} justifyContent='flex-end' alignItems='center'>
                    <Grid item>
                        <Checkbox
                            col={12}
                            label={i18n.isStateProblem}
                            checked={answer.answerBool}
                            disabled
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12} className='padding-left-1 padding-right-1'>
                    <SimpleTextArea
                        title={i18n.comment}
                        value={answer.answerText}
                        disabled
                    />
                </Grid>
            </Grid>
        </Grid>
    )
}

LinkedQuestion.propTypes = {
    danger: PropTypes.instanceOf(DtoDanger),
    cmsSurvey: PropTypes.instanceOf(DtoCMSSurvey),
    visit: PropTypes.instanceOf(DtoVisit),
}

const Danger = ({
    danger,
    isLast,
    onChange,
    dangers,
    visit,
    readMode,
    cmsSurvey,
}) => {
    const onDeleteDanger = () => {
        onChange({
            link_dangers: [
                ...dangers.filter(d => d.index !== danger.index),
            ],
        })
    }

    const onChangeDanger = (value) => {
        onChange({
            link_dangers: orderBy([
                ...dangers.filter(d => d.index !== danger.index),
                { ...danger, ...value },
            ], 'index'),
        })
    }

    const addNewDocument = (document) => {
        const newLinkDocuments = [
            ...(danger.link_documents || []),
            document,
        ]
        onChangeDanger({ link_documents: newLinkDocuments })
    }

    const onDeleteDocument = (document) => {
        onChangeDanger({ link_documents: [ ...danger.link_documents.filter(d => d.index !== document.index)] })
    }

    const onChangeDocument = (document, value) => {
        const newLinkDocuments = danger.link_documents.map(d => {
            if (d.index === document.index) {
                return new DtoDangerDocument({ ...document, ...value })
            }
            return d
        })
        onChangeDanger({ link_documents: newLinkDocuments })
    }

    const dangersList = useSandreList(SANDRE.INSTALLATIONS_DANGERS)

    const linkedDanger = danger.idLinkedParagraph !== -1
    const documents = linkedDanger ? visit.link_answers.find(la => la.idQuestion === danger.idLinkedQuestion)?.link_documents : danger.link_documents
    const displayPictures = documents && documents.map(doc => {
        return (
            <VisitPicture
                doc={doc}
                onDeleteDocument={!linkedDanger ? () => onDeleteDocument(doc) : null}
                onChangeDocument={value => onChangeDocument(doc, { description: value })}
                readMode={readMode}
                col={4}
            />
        )
    })
    return (
        <Grid container direction='row' justifyContent='center'>
            <Grid item xs={7}>
                <Grid container direction='column' justifyContent='center' style={{ padding: '10px 0' }}>
                    { linkedDanger && (
                        <Grid item container xs={12}>
                            <LinkedQuestion
                                danger={danger}
                                cmsSurvey={cmsSurvey}
                                visit={visit}
                            />
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <Select
                            col={6}
                            obligatory
                            options={dangersList}
                            keyValue='code'
                            keyLabel='name'
                            noNullValue={true}
                            label={i18n.dangerousSituation}
                            value={danger.dangerousSituation}
                            onChange={v => onChangeDanger({ dangerousSituation: v })}
                            disabled={readMode}
                        />
                    </Grid>
                    <Grid item xs={12} className='padding-left-1 padding-right-1'>
                        <SimpleTextArea
                            title={i18n.dangerComment}
                            value={danger.comment}
                            onChange={v => onChangeDanger({ comment: v })}
                            disabled={readMode}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item container xs={4} direction='row' alignItems='flex-start' justifyContent='space-evenly' style={{ paddingTop: '10px' }}>
                <Grid container spacing={2}>
                    {displayPictures}
                    {!linkedDanger && !readMode && (
                        <AddPicture
                            onUploadFile={newFileName => {
                                addNewDocument({
                                    idSurvey: visit.idSurvey,
                                    idCampaign: visit.idCampaign,
                                    idVisit: visit.idVisit,
                                    siteType: DANGER_ELEMENT_TYPE.INSTALLATION,
                                    siteCode: visit.idInstallation,
                                    docName: newFileName,
                                })
                            }}
                        />
                    )}
                </Grid>
            </Grid>
            {!readMode && (
                <Grid item container alignItems='flex-start' justifyContent='flex-end' xs={1}>
                    <Icon
                        size='small'
                        icon='delete'
                        onClick={onDeleteDanger}
                        tooltip={i18n.delete}
                    />
                </Grid>
            )}
            { !isLast && (
                <Grid item xs={12}>
                    <Divider />
                </Grid>
            )}
        </Grid>
    )
}

Danger.propTypes = {
    danger: PropTypes.instanceOf(DtoDanger),
    isLast: PropTypes.bool,
    onChange: PropTypes.func,
    dangers: arrayOf(DtoDanger),
    uploadFile: PropTypes.func,
    visit: PropTypes.instanceOf(DtoVisit),
    readMode: PropTypes.bool,
    cmsSurvey: PropTypes.instanceOf(DtoCMSSurvey),
}


const Paragraph = ({
    paragraphDangers,
    cmsSurvey,
    onChange,
    dangers,
    visit,
    readMode,
}) => {
    const paragraph = cmsSurvey.link_paragraphs.find(p => p.idParagraph === paragraphDangers[0]?.idLinkedParagraph)
    return (
        <Grid xs={12} className='margin-left-1 margin-right-1'>
            <AccordionMUI
                defaultExpanded={paragraph?.order === 1}
                sx={{ marginBottom: '5px', marginTop: '5px' }}
            >
                <AccordionSummaryMUI style={{ fontSize: '16px', backgroundColor: greyBlue }}>
                    {`${paragraph?.description || i18n.others} (${paragraphDangers.length} ${getI18nTitleData(i18n.danger, i18n.dangers, paragraphDangers)})`}
                </AccordionSummaryMUI>
                <AccordionDetailsMUI>
                    <Grid>
                        {
                            orderBy(paragraphDangers, 'order').map((d, i) => (
                                <Danger
                                    danger={d}
                                    isLast={i === paragraphDangers.length - 1}
                                    onChange={onChange}
                                    dangers={dangers}
                                    visit={visit}
                                    readMode={readMode}
                                    cmsSurvey={cmsSurvey}
                                />
                            ))
                        }
                    </Grid>
                </AccordionDetailsMUI>
            </AccordionMUI>
        </Grid>
    )
}

Paragraph.propTypes = {
    paragraphDangers: arrayOf(DtoCMSParagraph),
    dangers: arrayOf(DtoDanger),
    cmsSurvey: PropTypes.instanceOf(DtoCMSSurvey),
    onChange: PropTypes.func,
    uploadFile: PropTypes.func,
    visit: PropTypes.instanceOf(DtoVisit),
    readMode: PropTypes.bool,
}

const InstVisitDanger = ({
    readMode = true,
    visit = {},
    onChange = () => {},
}) => {
    const {
        cmsSurvey,
    } = useSelector(store => ({
        cmsSurvey: store.EventsReducer.cmsSurvey,
    }), shallowEqual)

    const dangers = visit.link_dangers.map((d, index) => {
        return {
            ...d,
            index,
            link_documents: d.link_documents.map((doc, i) => ({ ...doc, index: i })),
        }
    })
    const groupedDangers = groupBy(dangers, 'idLinkedParagraph')

    const addNewDanger = () => {
        onChange({
            link_dangers: [
                ...dangers,
                new DtoDanger({
                    idSurvey: visit.idSurvey,
                    siteType: DANGER_ELEMENT_TYPE.INSTALLATION,
                    siteCode: visit.idInstallation,
                    idVisit: visit.idVisit,
                    idCampaign: visit.idCampaign,
                    idLinkedParagraph: -1,
                }),
            ],
        })
    }

    return hasValue(cmsSurvey?.idSurvey) && (
        <Card
            title={`${i18n.preIdentificationDangerousSituations} (${dangers.length} ${getI18nTitleData(i18n.element, i18n.elements, dangers)})`}
            headerStyle={{ backgroundColor: greyBlue }}
            className='margin-right-1 margin-left-1'
            actions={[!readMode && { onClick: addNewDanger, iconName: 'note_add' }]}
            noMargin={false}
        >
            <Grid container xs={12} style={{ padding: '10px 0' }}>
                {
                    Object.keys(groupedDangers).map(key => {
                        return (
                            <Paragraph
                                paragraphDangers={groupedDangers[key]}
                                cmsSurvey={cmsSurvey}
                                onChange={onChange}
                                dangers={dangers}
                                visit={visit}
                                readMode={readMode}
                            />
                        )
                    })
                }
            </Grid>
        </Card>
    )
}

InstVisitDanger.propTypes = {
    visit: PropTypes.instanceOf(DtoVisit),
    onChange: PropTypes.func,
    readMode: PropTypes.bool,
    cmsSurvey: PropTypes.instanceOf(DtoCMSSurvey),
}

export default InstVisitDanger
