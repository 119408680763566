'use strict'
import { H_AGRI_SURVEYS_DASHBOARD } from 'account/constants/AccessRulesConstants'
import AgriAction from 'agriAdministration/actions/AgriAction'
import FixedSlideNav from 'components/navbar/FixedSlideNav'
import ProgressCard from 'components/card/ProgressCard'
import Row from 'components/react/Row'
import { push } from '@lagunovsky/redux-react-router'
import DtoAgriTanksType from 'exploitations/dto/DtoAgriTanksType'
import DtoExploitation from 'exploitations/dto/DtoExploitation'
import DtoExploitationExportFull from 'exploitations/dto/DtoExploitationExportFull'
import HomeAction from 'home/actions/HomeAction'
import BoundaryError from 'log/components/BoundaryError'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import i18n from 'simple-react-i18n'
import { connect } from 'react-redux'
import { Outlet } from 'react-router-dom'
import ReferencialAction from 'referencial/action/ReferencialAction'
import CityAction from 'referencial/components/city/actions/CityAction'
import CityDto from 'referencial/components/city/dto/CityDto'
import ContributorAction from 'referencial/components/contributor/actions/ContributorAction'
import ContributorDto from 'referencial/components/contributor/dto/ContributorDto'
import ManagementUnitAction from 'referencial/components/managementUnit/actions/ManagementUnitAction'
import DtoManagementUnit from 'referencial/components/managementUnit/dto/DtoManagementUnit'
import WatershedAction from 'referencial/components/watershed/actions/WatershedAction'
import WatershedDto from 'referencial/components/watershed/dto/WatershedDto'
import DtoSandreCode from 'referencial/dto/DtoSandreCode'
import { componentHasHabilitations } from 'utils/HabilitationUtil'
import { arrayOf } from 'utils/StoreUtils'

class PARApp extends Component {
    state = {
        parLoaded: false,
        exploitationsLoaded: false,
    }

    componentDidMount() {
        if (!componentHasHabilitations(H_AGRI_SURVEYS_DASHBOARD)) { // A modifier quand react-router sera à jour
            this.props.push('/unauthorized')
            return
        }
        this.props.setFormationPath('')
        this.props.setHelpLink('', '')
        const {
            params,
            exploitations,
            sandreCodes,
            contributors,
            cities,
            exploitationsExportFullData,
            watersheds,
            managementUnits,
            agriTanksTypes,
        } = this.props
        this.props.fetchPAR(params.id).then(() => {
            this.setState({ parLoaded: true })
        })
        this.props.fetchRulesScenarios()
        if (!exploitations.length) {
            this.props.fetchExploitations()
        }
        if (!sandreCodes.length) {
            this.props.fetchSandreCodes()
        }
        if (!contributors.length) {
            this.props.fetchContributors()
        }
        if (!cities.length) {
            this.props.fetchCities()
        }
        if (!exploitationsExportFullData.length) {
            this.props.fetchExploitationsExportFullData().then(() => {
                this.setState({ exploitationsLoaded: true })
            })
        } else {
            this.setState({ exploitationsLoaded: true })
        }
        if (!watersheds.length) {
            this.props.fetchWatersheds()
        }
        if (!managementUnits.length) {
            this.props.fetchManagementUnits()
        }
        if (!agriTanksTypes.length) {
            this.props.fetchTanksTypes()
        }
    }

    componentWillUnmount() {
        this.props.setFormationPath('')
        this.props.setHelpLink('', '')
    }

    getLink = () => {
        const { params: { id } } = this.props
        return [{
            href: `/par/${id}`,
            icons: 'dashboard',
            name: 'PAR',
        }, {
            href: `/par/${id}/events`,
            icons: 'view_column',
            name: i18n.journal,
        }]
    }

    render() {
        const { parLoaded, exploitationsLoaded } = this.state
        return (
            <div className='row no-margin'>
                <FixedSlideNav links={this.getLink()}/>
                <main>
                    <div className='row no-margin padding-left-1'>
                        <div className='content-page no-padding fullscreen'>
                            <BoundaryError>
                                {parLoaded && exploitationsLoaded ? (
                                    <Outlet />
                                ) : (
                                    <Row className='padding-1'>
                                        <ProgressCard indeterminate round />
                                    </Row>
                                )}
                            </BoundaryError>
                        </div>
                    </div>
                </main>
            </div>
        )
    }
}

PARApp.defaultProps = {
    getLink: ('', ''),
}

PARApp.propTypes = {
    params: PropTypes.shape({
        id: PropTypes.string,
    }),
    children: PropTypes.element,

    exploitations: arrayOf(DtoExploitation),
    sandreCodes: arrayOf(DtoSandreCode),
    contributors: arrayOf(ContributorDto),
    cities: arrayOf(CityDto),
    exploitationsExportFullData: arrayOf(DtoExploitationExportFull),
    managementUnits: PropTypes.arrayOf(PropTypes.instanceOf(DtoManagementUnit)),
    watersheds: PropTypes.arrayOf(PropTypes.instanceOf(WatershedDto)),
    agriTanksTypes: PropTypes.arrayOf(PropTypes.instanceOf(DtoAgriTanksType)),

    getLink: PropTypes.func,
    fetchPAR: PropTypes.func,
    fetchExploitationsExportFullData: PropTypes.func,
    fetchExploitations: PropTypes.func,
    fetchSandreCodes: PropTypes.func,
    fetchContributors: PropTypes.func,
    fetchCities: PropTypes.func,
    setFormationPath: PropTypes.func,
    setHelpLink: PropTypes.func,
    fetchWatersheds: PropTypes.func,
    fetchManagementUnits: PropTypes.func,
    fetchTanksTypes: PropTypes.func,
    fetchRulesScenarios: PropTypes.func,
    push: PropTypes.func,
}

const mapStateToProps = store => ({
    exploitationsExportFullData: store.AgriReducer.exploitationsExportFullData,
    exploitations: store.AgriReducer.exploitations,
    sandreCodes: store.ReferencialReducer.sandreCodes,
    contributors: store.ContributorReducer.contributors,
    cities: store.CityReducer.cities,
    watersheds: store.WatershedReducer.watersheds,
    managementUnits: store.ManagementUnitReducer.managementUnits,
    agriTanksTypes: store.AgriReducer.agriTanksTypes,
})

const mapDispatchToProps = {
    fetchPAR: AgriAction.fetchPAR,
    fetchExploitationsExportFullData: AgriAction.fetchExploitationsExportFullData,
    fetchExploitations: AgriAction.fetchExploitations,
    fetchSandreCodes: ReferencialAction.fetchSandreCodes,
    fetchContributors: ContributorAction.fetchContributors,
    fetchCities: CityAction.fetchCities,
    setFormationPath: HomeAction.setFormationPath,
    setHelpLink: HomeAction.setHelpLink,
    fetchWatersheds: WatershedAction.fetchWatersheds,
    fetchManagementUnits: ManagementUnitAction.fetchManagementUnits,
    fetchTanksTypes: AgriAction.fetchTanksTypes,
    fetchRulesScenarios: AgriAction.fetchRulesScenarios,
    push,
}

export default connect(mapStateToProps, mapDispatchToProps)(PARApp)
