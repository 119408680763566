import React, { useEffect, useMemo, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import ProgressCard from '../../../../components/card/ProgressCard'
import PropTypes from 'prop-types'
import ParameterAction from '../actions/ParameterAction'
import ParameterGroupDto from '../dto/ParameterGroupDto'
import ParameterGroupLinkDto from '../dto/ParameterGroupLinkDto'
import { PATH_REFERENCIAL_PARAMETER } from 'home/constants/RouteConstants'
import useActions from 'utils/customHook/useActions'
import useTitle from 'utils/customHook/useTitle'
import Table from 'components/datatable/Table'
import { nbPerPageLabel } from 'referencial/constants/ReferencialConstants'
import { getUser } from 'utils/SettingUtils'
import { push } from '@lagunovsky/redux-react-router'
import { PATH_REFERENCIAL_PARAMETERS_GROUP_NEW } from '../../../../home/constants/RouteConstants'

const type = { headers: ['id', 'name'] }

const ParametersGroupsApp = () => {
    const {
        parameterGroupLinks,
        parameterGroups,
        accountUser,
    } = useSelector(store => ({
        parameterGroupLinks: store.ParameterReducer.parameterGroupLinks,
        parameterGroups: store.ParameterReducer.parameterGroups,
        accountUser: store.AccountReducer.accountUser,
    }), shallowEqual)

    const [parameterGroupLoaded, setParameterGroupLoaded] = useState(false)
    const [parameterGroupLinksLoaded, setParameterGroupLinksLoaded] = useState(false)

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(ParameterAction.fetchParameterGroups())
        dispatch(ParameterAction.fetchParameterGroupLinks())
    }, [])

    useEffect(() => {
        setParameterGroupLoaded(true)
    }, [parameterGroups])

    useEffect(() => {
        setParameterGroupLinksLoaded(true)
    }, [parameterGroupLinks])

    const isConsultant = useMemo(() => getUser().consultant === '1', [])

    useActions(() => {
        const isEnableEdit = accountUser.metadata === '1' || accountUser.isAdmin === '1'
        const baseActions = {
            referencialActions: {
                actions: {
                    sync: {
                        updateAction: () => dispatch(ParameterAction.fetchSynchroSandreGroupParameter()),
                    },
                },
            },
        }
        const newAction = (isEnableEdit && !isConsultant) ? {
            new: () => dispatch(push(PATH_REFERENCIAL_PARAMETERS_GROUP_NEW)),
        } : {}
        return { ...baseActions, ...newAction }
    }, [])

    useTitle(() => [{
        title: i18n.referencials,
        href: 'referencial',
    }, {
        title: i18n.parameterGroup,
        href: PATH_REFERENCIAL_PARAMETER,
    }], [])

    const formatData = (data) => data.map(p => ({ ...p, id: p.code }))

    const classGroupsCodes = useMemo(() => parameterGroupLinks.filter(p => p.classGroupCode).map(p => p.classGroupCode), [parameterGroupLinks])
    const usageGroupsCodes = useMemo(() => [
        ...parameterGroupLinks.filter(p => p.usageGroupCode).map(p => p.usageGroupCode ? p.usageGroupCode : ''),
        ...parameterGroupLinks.filter(p => p.usageGroupCode2).map(p => p.usageGroupCode2 ? p.usageGroupCode2 : ''),
        ...parameterGroupLinks.filter(p => p.usageGroupCode3).map(p => p.usageGroupCode3 ? p.usageGroupCode3 : ''),
        ...parameterGroupLinks.filter(p => p.usageGroupCode4).map(p => p.usageGroupCode4 ? p.usageGroupCode4 : ''),
        ...parameterGroupLinks.filter(p => p.usageGroupCode5).map(p => p.usageGroupCode5 ? p.usageGroupCode5 : ''),
    ], [parameterGroupLinks])

    const classData = useMemo(() => formatData(parameterGroups.filter(p => classGroupsCodes.includes(p.code) || !usageGroupsCodes.includes(p.code))), [classGroupsCodes, parameterGroups, usageGroupsCodes])
    const usageData = useMemo(() => formatData(parameterGroups.filter(p => usageGroupsCodes.includes(p.code))), [parameterGroups, usageGroupsCodes])

    if (!parameterGroupLoaded || !parameterGroupLinksLoaded) {
        return <ProgressCard indeterminate />
    }

    return (
        <div className='row no-margin'>
            <div className='col s12'>
                <div className='row padding-top-5-px'>
                    <Table
                        title={i18n.classGroup}
                        data={classData}
                        nbPerPageLabel={nbPerPageLabel}
                        link={'referencial/parameterGroup'}
                        type={type}
                        condensed
                        sortable
                        paging
                    />
                </div>
                <div className='row padding-top-5-px'>
                    <Table
                        title={i18n.usageGroup}
                        data={usageData}
                        nbPerPageLabel={nbPerPageLabel}
                        link={'referencial/parameterGroup'}
                        type={type}
                        condensed
                        sortable
                        paging
                    />
                </div>
            </div>
        </div>
    )
}

ParametersGroupsApp.propTypes = {
    parameterGroupLinks: PropTypes.arrayOf(PropTypes.instanceOf(ParameterGroupLinkDto)),
    parameterGroups: PropTypes.arrayOf(PropTypes.instanceOf(ParameterGroupDto)),
    forceFetch: PropTypes.func,
    fetchParameterGroupLinks: PropTypes.func,
    fetchParameterGroups: PropTypes.func,
}

export default ParametersGroupsApp