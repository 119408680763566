import React, { useMemo, useState } from 'react'
import { connect, shallowEqual, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import Input from '../../../../components/forms/Input'
import Textarea from '../../../../components/forms/Textarea'
import Select from '../../../../components/forms/Select'
import { getMatTypes } from '../../../../utils/MaterielUtils'
import NetworkDto from '../../../../referencial/components/network/dto/NetworkDto'
import moment from 'moment'
import ContributorItem from '../../../../referencial/components/contributor/dto/ContributorItem'
import DtoVariousMaterielType from '../dto/DtoVariousMaterielType'
import SimpleDatePicker from '../../../../components/forms/SimpleDatePicker'
import { authorizeContributors, authorizeFilter } from 'utils/HabilitationUtil'
import User from 'account/dto/User'
import { removeNullKeys } from 'utils/StoreUtils'
import { omit } from 'lodash'
import ConfirmModal from 'components/modal/ConfirmModal'
import MultiContributorsAutocomplete from 'referencial/components/contributor/components/MultiContributorsAutocomplete'
import { isFieldRequired } from 'materiel/utils/MaterielUtils'
import { DATEACHAT_OBLIGATOIRE, NUMSERIE_OBLIGATOIRE } from 'materiel/constants/MaterielSettingRuleConstant'
import { VARIOUS_ID } from 'materiel/constants/MaterielConstants'

const VariousMaterielPanel = ({
    active = false,
    disabled = true,
    variousMateriel = {},
    variousMaterielTypes = [],
    contributors = [],
    accountUser = {},
    networks = [],
    onChange = () => { },
}) => {
    const {
        materielSettingRules,
    } = useSelector(store => ({
        materielSettingRules: store.MaterielReducer.materielSettingRules,
    }), shallowEqual)

    const [isOpen, setOpen] = useState(false)
    const [selectedType, setSelectedType] = useState()

    const rules = useMemo(() => {
        return materielSettingRules.filter(({ idMaterielType }) => idMaterielType === VARIOUS_ID)
    }, [materielSettingRules])

    return (
        <div className='col s12 margin-bottom-1'>
            <div className='row no-margin' style={{ paddingTop: '3px' }}>
                <Select
                    options={getMatTypes(variousMaterielTypes, variousMateriel.materielType)}
                    label={i18n.type}
                    col={3}
                    onChange={v => {
                        const type = variousMaterielTypes.find(({ id }) => v === id)
                        if (type) {
                            setOpen(true)
                            setSelectedType(type)
                        } else {
                            onChange({ materielType: v })
                        }
                    }}
                    value={variousMateriel.materielType}
                    nullLabel='&nbsp;'
                    active={active}
                    disabled={disabled}
                    obligatory
                />
                <Input
                    col={3}
                    title={i18n.serialNumber}
                    value={variousMateriel.serialNumber}
                    onChange={value => onChange({ serialNumber: value })}
                    active={active}
                    disabled={variousMateriel.isEquipment || disabled}
                    obligatory={isFieldRequired(rules, NUMSERIE_OBLIGATOIRE)}
                />
                <Input
                    col={3}
                    title={i18n.immoNumber}
                    value={variousMateriel.reference}
                    onChange={value => onChange({ reference: value })}
                    active={active}
                    disabled={variousMateriel.isEquipment || disabled}
                />
                <Input
                    col={3}
                    title={i18n.name}
                    value={variousMateriel.name}
                    onChange={value => onChange({ name: value })}
                    active={active}
                    disabled={disabled}
                />
            </div>
            <div className='row no-margin' style={{ paddingTop: '3px' }}>
                <MultiContributorsAutocomplete
                    options={authorizeContributors(contributors, accountUser, variousMateriel.manufacturerId)}
                    label={i18n.manufacturer}
                    col={6}
                    onChange={(id) => onChange({ manufacturerId: id })}
                    values={variousMateriel.manufacturerId}
                    disabled={variousMateriel.isEquipment || disabled}
                    keyLabel='labelDisplay'
                />
                <MultiContributorsAutocomplete
                    options={authorizeContributors(contributors, accountUser, variousMateriel.providerId)}
                    label={i18n.provider}
                    col={6}
                    onChange={(id) => onChange({ providerId: id })}
                    values={variousMateriel.providerId}
                    disabled={variousMateriel.isEquipment || disabled}
                    keyLabel='labelDisplay'
                />
            </div>
            <div className='row no-margin' style={{ paddingTop: '3px' }}>
                <Select
                    options={authorizeFilter(networks, accountUser, variousMateriel.networkCode)}
                    label={i18n.network}
                    col={12}
                    onChange={value => onChange({ networkCode: value })}
                    value={variousMateriel.networkCode}
                    nullLabel='&nbsp;'
                    active={active}
                    disabled={variousMateriel.isEquipment || disabled}
                />
            </div>
            <div className='row no-margin' style={{ paddingTop: '3px' }}>
                <SimpleDatePicker
                    col={3}
                    id='manufactureDate'
                    value={variousMateriel.manufactureDate}
                    label={i18n.manufactureDate}
                    onChange={v => onChange({ manufactureDate: v })}
                    disabled={variousMateriel.isEquipment || disabled}
                />
                <SimpleDatePicker
                    col={3}
                    id='purchaseDate'
                    value={variousMateriel.purchaseDate}
                    label={i18n.purchaseDate}
                    onChange={v => onChange({ purchaseDate: v })}
                    disabled={variousMateriel.isEquipment || disabled}
                    obligatory={isFieldRequired(rules, DATEACHAT_OBLIGATOIRE)}
                />
                <SimpleDatePicker
                    col={3}
                    id='manufactureDate'
                    value={variousMateriel.warrantyTime && moment(variousMateriel.purchaseDate).add(variousMateriel.warrantyTime, 'months').valueOf()}
                    label={i18n.warrantyExpirationDate}
                    disabled={variousMateriel.isEquipment || disabled}
                />
                <SimpleDatePicker
                    col={3}
                    id='withdrawalDate'
                    value={variousMateriel.withdrawalDate}
                    label={i18n.replaceDate}
                    onChange={v => onChange({ withdrawalDate: v })}
                    disabled={variousMateriel.isEquipment || disabled}
                />
            </div>
            <div className='row no-margin' style={{ paddingTop: '8px' }}>
                <Textarea
                    col={12}
                    title={i18n.comment}
                    value={variousMateriel.comment}
                    onChange={value => onChange({ comment: value })}
                    active={active}
                    disabled={disabled}
                />
            </div>
            <ConfirmModal
                isOpen={isOpen}
                title={i18n.materielDefaultValuesMessage}
                onValidate={() => {
                    setOpen(false)
                    onChange(removeNullKeys({
                        materielType: selectedType.id,
                        ...omit(selectedType, ['id', 'code', 'label', 'name', 'comment', 'updateDate', 'updateLogin', 'startDate', 'endDate', 'icon']),
                    }))
                }}
                onReject={() => {
                    setOpen(false)
                    onChange({ materielType: selectedType.id })
                }}
                onClose={() => setOpen(false)}
            />
        </div>
    )
}

VariousMaterielPanel.propTypes = {
    disabled: PropTypes.bool,
    active: PropTypes.bool,
    onChange: PropTypes.func,
    contributors: PropTypes.arrayOf(PropTypes.instanceOf(ContributorItem)),
    variousMateriel: PropTypes.shape({}),
    variousMaterielTypes: PropTypes.arrayOf(PropTypes.instanceOf(DtoVariousMaterielType)),
    networks: PropTypes.arrayOf(PropTypes.instanceOf(NetworkDto)),
    accountUser: PropTypes.instanceOf(User),
}

const mapStateToProps = store => ({
    contributors: store.ContributorReducer.contributors,
    variousMaterielTypes: store.VariousMaterielReducer.variousMaterielTypes,
    networks: store.NetworkReducer.networks,
    accountUser: store.AccountReducer.accountUser,
})

export default connect(mapStateToProps)(VariousMaterielPanel)
