export default class QualitometerListDto {
    constructor(obj) {
        this.id = obj.id
        this.code = obj.code
        this.name = obj.name
        this.x = obj.x
        this.y = obj.y
        this.projection = obj.projection
        this.altitude = obj.altitude
        this.localisation = {
            x: obj.x,
            y: obj.y,
            projection: obj.projection,
        }
        this.typeName = 'quality'
        this.stationType = obj.stationType || '0'
    }
}