import { Icon } from '@mui/material'
import { styled } from '@mui/styles'
import Checkbox from 'components/forms/Checkbox'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import DtoCulture from 'referencial/components/cultures/dto/DtoCulture'
import DtoCulturesFamily from 'referencial/components/culturesFamilies/dto/DtoCulturesFamily'
import DtoUsageAgri from 'referencial/components/usage/dto/DtoUsageAgri'
import DtoSandreCode from 'referencial/dto/DtoSandreCode'
import i18n from 'simple-react-i18n'
import Card from '../../../components/card/Card'
import Input from '../../../components/forms/Input'
import NumberField from '../../../components/forms/NumberField'
import Select from '../../../components/forms/Select'
import SimpleDatePicker from '../../../components/forms/SimpleDatePicker'
import Textarea from '../../../components/forms/Textarea'
import Row from '../../../components/react/Row'
import DtoInstallationWithGeoItem from '../../../installation/components/installations/dto/DtoInstallationWithGeoItem'
import CityDto from '../../../referencial/components/city/dto/CityDto'
import DtoDeclarationVolumes from '../../../survey/dto/DtoDeclarationVolumes'
import { formatMilliers } from 'utils/StringUtil'

const FieldsetStyle = styled('fieldset')({
    borderRadius: 5,
    paddingBottom: 0,
})

const LegendStyle = styled('legend')({
    padding: '0 4',
    display: 'flex',
    alignItems: 'center',
})

class VolumesPanel extends Component {
    constructor(props) {
        super(props)
        this.state = {
            volumes: props.volumes,
            point: {},
        }
    }

    onChangeValue = (obj) => {
        const { volumes } = this.props
        this.props.onChange({
            ...volumes,
            ...obj,
        })
    }

    onSelectPoint = (point) => {
        const { citiesIndex, volumes } = this.props
        const city = citiesIndex[point.townCode] || {}
        this.setState({ point: { ...point, city: `${city.code || ''} ${city.name || ''}` } })
        this.props.onChange({
            ...volumes,
            idInstallation: point.id,
        })
    }

    getSelectedPeriod = (idPeriode, usagePeriodes) => {
        return !!usagePeriodes.find((p) => p.idNbPeriode === idPeriode)
    }

    getUsagePanel = (usage) => {
        const { usages, cultures, culturesFamilies, sandreCodes, modesIrrigations } = this.props

        const usagesPeriodes = sandreCodes.filter((code) => code.field === 'USAGES.PERIODES')
        const usagesPeriodesDetails = sandreCodes.filter((code) => code.field === 'USAGES.PERIODES_DETAILS')
        const usageSaisonnal = (usage.link_periodes || []).find((p) => p.idPeriode === 2)
        const usageAnnuel = (usage.link_periodes || []).find((p) => p.idPeriode === 1)

        const usageFound = usage?.idUsage ? (usages.find((u) => u.idUsage === usage.idUsage) || {}).description : ''
        const culture = usage?.idCulture ? cultures.find((c) => c.id === usage.idCulture) : {}
        const culturesFamily = culture.idFamille ? (culturesFamilies.find((cF) => cF.id === culture.idFamille) || {}).name : ''

        const irrigationMode = usage?.irrigationMode ? (modesIrrigations.find((iM) => iM.id === usage.irrigationMode) || {}).name : ''
        const irrigationType = usage?.irrigationType ? (sandreCodes.find((c) => c.field === 'IRRIGATION.CATEGORIES' && c.code === usage.irrigationType) || {}).name : ''
        const groundType = usage?.groundType ? (sandreCodes.find((c) => c.field === 'USAGES.TYPES_SOLS' && c.code === usage.groundType) || {}).name : ''
        const groundDepth = usage?.groundDepth ? (sandreCodes.find((c) => c.field === 'USAGES.PROFONDEUR_SOLS' && c.code === usage.groundDepth) || {}).name : ''
        const prelevementRequest = usage?.requestPrelevement ? (sandreCodes.find((c) => c.field === 'USAGES.TYPES' && c.code === usage.requestPrelevement) || {}).name : ''

        const cultureInfos = usage.idCulture ? (
            <>
                <Row>
                    <Input col={4} title={i18n.culturesFamily} value={culturesFamily} disabled />
                    <Input col={4} title={i18n.culture} value={culture.name} disabled />
                </Row>
                <Row>
                    <Input col={4} title={i18n.areaha} value={usage.area} disabled />
                    <Input col={4} title={i18n.mainIrrigationType} value={irrigationType} disabled />
                    <Input col={4} title={i18n.modeIrrigation} value={irrigationMode} disabled />
                </Row>
                <Row>
                    <Input col={4} title={i18n.groundType} value={groundType} disabled />
                    <Input col={4} title={i18n.groundDepth} value={groundDepth} disabled />
                    <Input col={4} title={i18n.requestPrelevement} value={prelevementRequest} disabled />
                </Row>
            </>
        ) : ''

        const periodesInfos = usage?.link_periodes?.length ? (
            <>
                <Row>
                    <Select
                        col={4}
                        label={i18n.usePeriod}
                        value={usageSaisonnal ? 2 : usageAnnuel ? 1 : null}
                        options={usagesPeriodes}
                        disabled
                    />
                </Row>
                <Row className='padding-bottom-1'>
                    {usageSaisonnal && usagesPeriodesDetails.map((p) => (
                        <Checkbox
                            label={p.name}
                            col={2}
                            value={p.code}
                            checked={this.getSelectedPeriod(p.code, usage.link_periodes)}
                            disabled
                        />
                    ))}
                </Row>
            </>
        ) : ''
        return (
            <FieldsetStyle>
                <LegendStyle>{i18n.usage}</LegendStyle>
                <Input col={4} title={i18n.type} value={usageFound} disabled />
                {cultureInfos}
                {periodesInfos}
            </FieldsetStyle>
        )
    }

    render = () => {
        const { volumes, disabled, newVolumes, points } = this.props
        const { point } = this.state
        const pointInfos = volumes.pointInfos && volumes.pointInfos.id ? volumes.pointInfos : point

        return (
            <div className='row no-margin' style={{ minHeight: '25vh' }}>
                <div className='row no-margin padding-top-1'>
                    {newVolumes && (
                        <Row>
                            <Select
                                col={6}
                                id='point'
                                label={i18n.pointPrelevement}
                                value={pointInfos.id}
                                options={points}
                                keyValue='id'
                                keyLabel='code'
                                displayWithCode
                                onChange={(_, v) => this.onSelectPoint(v)}
                                disabled={disabled}
                                obligatory
                            />
                        </Row>
                    )}
                    <Row noMargin={false} className='margin-bottom-1'>
                        <Card title={`${i18n.pointPrelevement} ${pointInfos.code || ''}`}>
                            <div className='padding-1' style={{ color: 'rgba(0, 0, 0, 0.87)' }}>
                                <Row className='bold'>{pointInfos.city || ''}</Row>
                                <Row>{`${pointInfos.code ? `${pointInfos.code} - ` : ''}${`${pointInfos.parcel || ''} ${pointInfos.section || ''}${pointInfos.location ? ` - ${pointInfos.location}` : ''}`}`}</Row>
                            </div>
                        </Card>
                    </Row>
                    <Row noMargin={false} className='margin-bottom-1'>
                        {volumes.link_usages && this.getUsagePanel(volumes.link_usages)}
                    </Row>
                    <Row noMargin={false} className='margin-bottom-1'>
                        <FieldsetStyle>
                            <LegendStyle>{volumes.idSurvey ? <Icon style={{ marginRight: 4 }}>description</Icon> : ''}{i18n.askedLabel}</LegendStyle>
                            {disabled ? (
                                <Input col={3} title={i18n.volume} value={formatMilliers(volumes.askedVolume)} disabled />
                            ) : (
                                <NumberField
                                    col={3}
                                    title={i18n.volume}
                                    value={volumes.askedVolume}
                                    onChange={value => this.onChangeValue({ askedVolume: value })}
                                    disabled={volumes.idSurvey || disabled}
                                />
                            )}
                            {disabled ? (
                                <Input col={3} title={i18n.askedLowWaterVolume} value={formatMilliers(volumes.askedLowWaterVolume)} disabled />
                            ) : (
                                <NumberField
                                    col={3}
                                    title={i18n.askedLowWaterVolume}
                                    value={volumes.askedLowWaterVolume}
                                    onChange={value => this.onChangeValue({ askedLowWaterVolume: value })}
                                    disabled={volumes.idSurvey || disabled}
                                />
                            )}
                            <NumberField
                                col={3}
                                title={i18n.year}
                                value={volumes.askedYear}
                                onChange={value => this.onChangeValue({ askedYear: value })}
                                disabled={disabled}
                                obligatory
                            />
                        </FieldsetStyle>
                    </Row>
                    <Row noMargin={false} className='margin-bottom-1'>
                        <FieldsetStyle>
                            <LegendStyle>{i18n.attribution}</LegendStyle>
                            {disabled ? (
                                <Input col={3} title={i18n.volume} value={formatMilliers(volumes.attributedVolume)} disabled />
                            ) : (
                                <NumberField
                                    col={3}
                                    title={i18n.volume}
                                    value={volumes.attributedVolume}
                                    onChange={value => this.onChangeValue({ attributedVolume: value })}
                                    disabled={disabled}
                                />
                            )}
                            {disabled ? (
                                <Input col={3} title={i18n.lowWater} value={formatMilliers(volumes.attributedLowWaterVolume)} disabled />
                            ) : (
                                <NumberField
                                    col={3}
                                    title={i18n.lowWater}
                                    value={volumes.attributedLowWaterVolume}
                                    onChange={value => this.onChangeValue({ attributedLowWaterVolume: value })}
                                    disabled={disabled}
                                />
                            )}
                            <Input
                                col={3}
                                title={i18n.login}
                                value={volumes.attributionLogin}
                                onChange={value => this.onChangeValue({ attributionLogin: value })}
                                disabled={disabled}
                            />
                            <SimpleDatePicker
                                col={3}
                                label={i18n.date}
                                value={volumes.attributionDate}
                                onChange={value => this.onChangeValue({ attributionDate: value })}
                                disabled={disabled}
                            />
                            <Textarea
                                col={12}
                                title={i18n.comment}
                                noMargin={false}
                                value={volumes.attributionComment}
                                onChange={value => this.onChangeValue({ attributionComment: value })}
                                disabled={disabled}
                            />
                        </FieldsetStyle>
                    </Row>
                    <FieldsetStyle>
                        <LegendStyle>{i18n.authorisation}</LegendStyle>
                        {disabled ? (
                            <Input col={3} title={i18n.volume} value={formatMilliers(volumes.authorizedVolume)} disabled />
                        ) : (
                            <NumberField
                                col={3}
                                title={i18n.volume}
                                value={volumes.authorizedVolume}
                                onChange={value => this.onChangeValue({ authorizedVolume: value })}
                                disabled={disabled}
                            />
                        )}
                        {disabled ? (
                            <Input col={3} title={i18n.lowWater} value={formatMilliers(volumes.authorizedLowWaterVolume)} disabled />
                        ) : (
                            <NumberField
                                col={3}
                                title={i18n.lowWater}
                                value={volumes.authorizedLowWaterVolume}
                                onChange={value => this.onChangeValue({ authorizedLowWaterVolume: value })}
                                disabled={disabled}
                            />
                        )}
                        <Input
                            col={3}
                            title={i18n.login}
                            value={volumes.authorizedLogin}
                            onChange={value => this.onChangeValue({ authorizedLogin: value })}
                            disabled={disabled}
                        />
                        <SimpleDatePicker
                            col={3}
                            label={i18n.date}
                            value={volumes.authorizedDate}
                            onChange={value => this.onChangeValue({ authorizedDate: value })}
                            disabled={disabled}
                            calendarPosition='top'
                        />
                        <NumberField
                            col={3}
                            title={i18n.flow}
                            value={volumes.authorizedFlow}
                            onChange={value => this.onChangeValue({ authorizedFlow: value })}
                            disabled={disabled}
                        />
                        <Textarea
                            col={12}
                            title={i18n.comment}
                            noMargin={false}
                            value={volumes.authorizedComment}
                            onChange={value => this.onChangeValue({ authorizedComment: value })}
                            disabled={disabled}
                        />
                    </FieldsetStyle>
                </div>
            </div>
        )
    }
}

VolumesPanel.propTypes = {
    disabled: PropTypes.bool,
    title: PropTypes.string,
    newVolumes: PropTypes.bool,
    volumes: PropTypes.instanceOf(DtoDeclarationVolumes),
    points: PropTypes.arrayOf(PropTypes.instanceOf(DtoInstallationWithGeoItem)),
    citiesIndex: PropTypes.arrayOf(PropTypes.instanceOf(CityDto)),
    usages: PropTypes.arrayOf(PropTypes.instanceOf(DtoUsageAgri)),
    cultures: PropTypes.arrayOf(PropTypes.instanceOf(DtoCulture)),
    culturesFamilies: PropTypes.arrayOf(PropTypes.instanceOf(DtoCulturesFamily)),
    sandreCodes: PropTypes.arrayOf(PropTypes.instanceOf(DtoSandreCode)),
    modesIrrigations: PropTypes.arrayOf(PropTypes.instanceOf({})),
    onChange: PropTypes.func,
}

const mapStateToProps = (store) => {
    return {
        citiesIndex: store.CityReducer.citiesIndex,
        usages: store.UsageReducer.usages,
        cultures: store.CultureReducer.cultures,
        culturesFamilies: store.CulturesFamilyReducer.culturesFamilies,
        sandreCodes: store.ReferencialReducer.sandreCodes,
        modesIrrigations: store.AgriReducer.modesIrrigations,
    }
}

export default connect(mapStateToProps)(VolumesPanel)