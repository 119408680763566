export default class DtoMaterielChronicle {
    constructor(object) {
        this.matType = object.matType // String,
        this.idMat = object.idMat // Long,
        this.measureType = object.measureType // Long,
        this.measureDate = object.measureDate // Option[DateTime],
        this.value = object.value // Option[Double],
        this.endDate = object.endDate // Option[DateTime],
        this.idChronicle = object.idChronicle // Option[Long]
    }
}