import { keys, maxBy, min, minBy, max, last, uniqBy } from 'lodash'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { getChartDate, getMaxProvisionalDate, getSubstractTime } from '../../../components/echart/EChartUtils'
import { arrayOf } from '../../../utils/StoreUtils'
import HydrometryAction from '../../actions/HydrometryAction'
import DtoHydroMeasures from '../../dto/chronicMeasures/DtoHydroMeasures'
import DtoHydroStats from '../../dto/chronicMeasures/DtoHydroStats'
import DtoHydrometricStation from '../../dto/DtoHydrometricStation'
import DtoHydrometryThreshold from '../../dto/DtoHydrometryThreshold'
import Row from '../../../components/react/Row'
import { WhiteCard } from 'components/styled/Card'
import DtoEvent from '../../../events/dto/DtoEvent'
import EventsAction from '../../../events/actions/EventsAction'
import ChartReviewHydro from './ChartReviewHydro'
import ProgressCard from '../../../components/card/ProgressCard'
import { Grid } from '@mui/material'
import IAEauAction from '../../../iaeau/IAEauAction'
import SimpleChartTabsHydro from '../../../components/echart/SimpleChartTabsHydro'
import HydrometerStationAction from 'station/actions/HydrometerStationAction'
import MUIChartTabs from '../../../components/echart/MUIChartTabs'
import { HISTO, J90 } from '../../../alerting/constants/ChartFollowContants'
import useAccountSetting from '../../../utils/customHook/useAccountSetting'
import { CHART_SELECTED_TIME } from '../../../quality/constants/ChartConstant'
import ChartTabPredData from '../../../station/components/iaeauModels/ChartTabPredData'
import { getQualificationSelectOptions, getStatusSelectOptions } from '../../../utils/StatusUtil'

const PERSONALIZED_GROUPING = 'personalizedGrouping'

// TODO ATTENTION ! Composant réutilisé dans plusieurs écrans
const HydroChartPanel = ({
    id,
    showLegend,
    isPopin = true,
    useFromSituation= false,
}) => {
    const lastSelectedTime = useAccountSetting(CHART_SELECTED_TIME, v => v === HISTO ? v : (v ? parseInt(v) : J90))
    const [time, setTime] = useState(lastSelectedTime || J90)
    const getDefaultMinDate = () => lastSelectedTime === HISTO ? undefined : getSubstractTime(lastSelectedTime)
    const getDefaultMaxDate = () => lastSelectedTime === HISTO ? moment().valueOf() : undefined

    const [fullScreen, setFullScreen] = useState(false)
    const [minDate, setMinDate] = useState(getDefaultMinDate())
    const [maxDate, setMaxDate] = useState(getDefaultMaxDate())
    const [dataLoaded, setDataLoaded] = useState(false)
    const [hydroStatistics, setHydroStatistics] = useState([])
    const [progress, setProgress] = useState(0)
    const [displayModes, setDisplayModes] = useState({
        auto: true,
        max: false,
        min: false,
        average: false,
        brute: false,
        initialBrute: false,
    })
    const [accuracy, setAccuracy] = useState({
        qualifications: getQualificationSelectOptions().map(q => q.code),
        status: getStatusSelectOptions().map(s => s.code),
    })
    const [modelData, setModelData] = useState({ predMeasures: [], selectedPred: undefined, predDate: undefined, predStats: [] })


    const {
        hydroMeasures,
    } = useSelector(store => ({
        hydroMeasures: store.HydrometryReducer.hydroMeasures,
    }), shallowEqual)

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(HydrometryAction.fetchHydrometricThresholds())
        dispatch(HydrometryAction.fetchHydrometryDataTypes())
        dispatch(HydrometryAction.fetchHydrometricStation(id))
        dispatch(HydrometryAction.fetchHydroStatistics(id)).then((params = []) => setHydroStatistics(params.map(stats => new DtoHydroStats(stats))))
        dispatch(EventsAction.fetchStationEvents('hydrometry', id))
        dispatch(IAEauAction.getModels('hydrometry', parseInt(id)))
        dispatch(HydrometerStationAction.fetchHydrometerChartOptions(id))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    , [])

    const minDateFilterPreview = (date) => {
        const allFirstMeasures = hydroMeasures.flatMap(obj => obj.measures.length ? [obj.measures[0].date] : [])
        return date ?? min(allFirstMeasures) ?? minBy(hydroStatistics, 'startDate')?.startDate ?? moment().subtract(1, 'years').valueOf()
    }

    const maxDateFilterPreview = (date) => {
        if (date) {
            return date
        }
        const lastMeasureDate = maxBy([...hydroStatistics, ...hydroMeasures], 'endDate')?.endDate
        const todaysDay = moment().valueOf()
        const maxDatePredDataTmp = modelData && modelData.selectedPred && max(Object.keys(modelData.selectedPred).map(k => last(modelData.predMeasures[k])?.date))
        const maxDatePredData = maxDatePredDataTmp ? moment(maxDatePredDataTmp).add(1, 'day').endOf('day').valueOf() : todaysDay
        const tmpMax = lastMeasureDate && lastMeasureDate > todaysDay ? getMaxProvisionalDate(time, minDate, lastMeasureDate) : todaysDay
        return max([tmpMax, maxDatePredData])
    }

    useEffect(() => {
        setDataLoaded(false)
        const groupModeFunc = keys(displayModes).find(mode => displayModes[mode])
        const defaultGroupMode = [90, 365, 'HISTO', 'OTHER'].includes(time) ? 'MAX' : 'all'
        const inputs = uniqBy(hydroStatistics, 'typeId').map(stat => {
            const groupFunc = groupModeFunc === 'auto' ? defaultGroupMode : groupModeFunc
            const groupFuncIsSumDay = stat.typeId === -2 ? 'SUM_DAY' : groupFunc
            return {
                stationId: id,
                dataType: stat.typeId,
                groupFunc: groupFuncIsSumDay === PERSONALIZED_GROUPING ? displayModes.personalizedGroupingValue : groupFunc.toUpperCase(),
                chartMode: true,
                startDate: minDate,
                endDate: maxDate,
                status: accuracy.status,
                qualifications: accuracy.qualifications,
            }
        })
        dispatch(HydrometryAction.loadHydroChronicMeasures(inputs, p => setProgress(p))).then(() => setDataLoaded(true))
    }, [hydroStatistics, time, displayModes])

    const fullScreenStyle = (isPopin && fullScreen) ? {
        position: 'fixed',
        paddingTop: '1rem',
        top: '64px',
        left: 0,
        height: !isPopin ? `calc(${window.innerHeight}px - 64px)` : `calc(${window.innerHeight}px)`,
        width: `calc(${window.innerWidth}px)`,
        zIndex: 9,
        marginTop: !isPopin ? '5rem' : '0',
        overflowY: 'auto',
        backgroundColor: 'white',
    } : {}

    if (!hydroStatistics.length) {
        return null
    }

    const { minDate: formattedMinDate, maxDate: formattedMaxDate } = getChartDate(minDateFilterPreview(minDate), maxDateFilterPreview(maxDate), time)

    return (
        <WhiteCard noBoxShadow round>
            <Grid container sx={fullScreenStyle}>
                <Grid item xs={12}>
                    <Row className={(!isPopin && fullScreen) ? 'fullscreen-chart' : ''}>
                        <div className='col row no-margin padding-top-1' style={{ paddingLeft: 48 }}>
                            <Grid container alignItems='center' justifyContent='center'>
                                <Grid item>
                                    <MUIChartTabs
                                        time={time}
                                        onChangeTime={(newTime) => {
                                            setMinDate(newTime.minDate)
                                            setMaxDate(newTime.maxDate)
                                            setTime(newTime.time)
                                        }}
                                    />
                                </Grid>
                                <Grid item>
                                    <ChartTabPredData
                                        stationType='hydrometry'
                                        id={id}
                                        setParentModelData={setModelData}
                                        useFromSituation={useFromSituation}
                                    />
                                </Grid>
                                <Grid item sx={{ paddingLeft: '20px' }}>
                                    <SimpleChartTabsHydro
                                        displayModes={displayModes}
                                        changeDisplayModes={v => setDisplayModes(v)}
                                        displayModesUniqChoise
                                        changeAccuracy={v => setAccuracy(v)}
                                        hideAccuracy={false}
                                    />
                                </Grid>
                            </Grid>
                        </div>
                        <div className='col s12 row no-margin'>
                            <div>
                                {
                                    dataLoaded ? (
                                        <ChartReviewHydro
                                            id={id}
                                            hydroStatistics={hydroStatistics}
                                            fullScreen={fullScreen}
                                            setFullScreen={setFullScreen}
                                            hydroMeasures={hydroMeasures}
                                            minDate={formattedMinDate}
                                            maxDate={formattedMaxDate}
                                            showLegend={showLegend}
                                            isPopin={isPopin}
                                            modelData={modelData}
                                        />
                                    ) : <ProgressCard progress={progress} />
                                }
                            </div>
                        </div>
                    </Row>
                </Grid>
            </Grid>
        </WhiteCard >
    )
}

HydroChartPanel.propTypes = {
    id: PropTypes.number,
    hydrometricStation: PropTypes.instanceOf(DtoHydrometricStation),
    loadHydroChronicMeasures: PropTypes.func,
    hydroStatistics: arrayOf(DtoHydroStats),
    hydroMeasures: arrayOf(DtoHydroMeasures),
    hydrometryThresholds: arrayOf(DtoHydrometryThreshold),
    fetchHydrometricThresholds: PropTypes.func,
    stationEvents: arrayOf(DtoEvent),
    fetchHydrometryDataTypes: PropTypes.func,
    showLegend: PropTypes.bool,
    isPopin: PropTypes.bool,
    useFromSituation: PropTypes.bool, // permet de charger des données de prévision sur la situation (ne pas retirer)
}

export default HydroChartPanel