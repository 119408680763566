import CatchmentPointDto from 'catchment/dto/CatchmentPointDto'
import CartographyPanel from 'components/map/CartographyPanel'
import { intersectionWith } from 'lodash'
import PropTypes from 'prop-types'
import React, { useMemo } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'

const CatchmentPointsMap = ({ catchmentPoints }) => {
    const {
        piezometers,
        qualitometers,
        installations,
    } = useSelector(store => ({
        piezometers: store.PiezometryReducer.piezometersLight,
        qualitometers: store.QualityReducer.qualitometersLight,
        installations: store.InstallationReducer.installationsLight,
    }), shallowEqual)
    const comparator = (station, point) => station.code === point.codeWithoutDesignation
    const piezo = useMemo(() => intersectionWith(piezometers, catchmentPoints, comparator), [catchmentPoints, piezometers])
    const qualito = useMemo(() => intersectionWith(qualitometers, catchmentPoints, comparator), [catchmentPoints, qualitometers])
    const installation = useMemo(() => intersectionWith(installations, catchmentPoints, comparator), [catchmentPoints, installations])

    return (
        <CartographyPanel
            layers={['STATIONS_POINTS']}
            stationsPoints={[
                ...piezo,
                ...qualito,
                ...installation,
            ]}
            stationsPanelTitle={i18n.stations}
            heightToSubstract={450}
        />
    )
}

CatchmentPointsMap.propTypes = {
    catchmentPoints: PropTypes.arrayOf(PropTypes.instanceOf(CatchmentPointDto)),
}

export default CatchmentPointsMap