import PropTypes from 'prop-types'
import React, { useEffect, useMemo, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import CartographyPanel from '../../../../components/map/CartographyPanel'
import { PATH_REFERENCIAL, PATH_REFERENCIAL_PARAMETER, PATH_REFERENCIAL_PARAMETER_ITEM_LINK } from 'home/constants/RouteConstants'
import SimpleDatePicker from 'components/forms/SimpleDatePicker'
import ThresholdSelect from 'components/forms/specific/ThresholdSelect'
import Checkbox from 'components/forms/Checkbox'
import ParameterAction from '../actions/ParameterAction'
import ToastrAction from 'toastr/actions/ToastrAction'
import useTitle from 'utils/customHook/useTitle'
import useActions from 'utils/customHook/useActions'
import DtoThreshold from 'quality/dto/DtoThreshold'
import QualityAction from 'quality/actions/QualityAction'
import WaitAction from 'wait/WaitAction'
import Select from 'components/forms/Select'
import { orderBy } from 'lodash'
import AnalysisAction from 'quality/actions/AnalysisAction'
import DtoPrettyAnalysis from 'quality/dto/analyse/DtoPrettyAnalysis'
import { useParams } from 'react-router'

const ParameterMapCriteriaPanel = ({
    setFilter = () => {},
}) => {
    const [startDate, setStartDate] = useState()
    const [endDate, setEndDate] = useState()
    const [threshold, setThreshold] = useState()
    const [quantificationControl, setQuantificationControl] = useState(false)
    const [usedStat, setUsedStat] = useState('0')

    const valueOptions = useMemo(() => [
        { value: '0', label: i18n.lastData },
        { value: '1', label: i18n.max },
        { value: '2', label: i18n.min },
        { value: '3', label: i18n.average },
        { value: '4', label: i18n.percentile90 },
    ], [])

    return (
        <div className='row card'>
            <div className='card-title'>
                { i18n.criterias }
            </div>
            <div className='col s12 card-content'>
                <div className='row no-margin padding-top-3-px'>
                    <SimpleDatePicker
                        id='startDate'
                        col={ 6 }
                        label={ i18n.startDate }
                        value={ startDate }
                        onChange={ setStartDate }
                    />
                    <SimpleDatePicker
                        id='endDate'
                        col={ 6 }
                        label={ i18n.endDate }
                        value={ endDate }
                        onChange={ setEndDate }
                    />
                </div>
                <div className='row no-margin padding-top-3-px'>
                    <ThresholdSelect
                        col={ 12 }
                        selected={ threshold }
                        onChange={ setThreshold }
                    />
                </div>
                <div className='row no-margin padding-top-3-px' onClick={ () => setQuantificationControl(!quantificationControl) }>
                    <Checkbox
                        col={ 12 }
                        checked={ quantificationControl }
                        label={ i18n.quantificationControl }
                    />
                </div>
                <div className='row no-margin padding-top-3-px'>
                    <Select
                        col={ 8 }
                        label={ i18n.criteriaValue }
                        value={ usedStat }
                        stringValue
                        options={ valueOptions }
                        onChange={ setUsedStat }
                    />
                </div>
                <div className='row no-margin padding-top-3-px'>
                    <div className='center-align'>
                        <a className='btn btn-small' onClick={ () => setFilter({ startDate, endDate, threshold, quantificationControl, usedStat }) } >{ i18n.search }</a>
                    </div>
                </div>
            </div>
        </div>
    )
}

ParameterMapCriteriaPanel.propTypes = {
    tmpFilter: PropTypes.shape({
        startDate: PropTypes.string,
        endDate: PropTypes.string,
        threshold: PropTypes.arrayOf(DtoThreshold),
        quantificationControl: PropTypes.bool,
    }),
    setTmpFilter: PropTypes.func,
    setFilter: PropTypes.func,
}

const ParameterMapApp = () => {
    const { code } = useParams()
    const {
        parameter,
        qualitometers,
        qualityThresholds,
    } = useSelector(store => ({
        parameter: store.ParameterReducer.parameter,
        qualitometers: store.QualityReducer.qualitometersLight,
        qualityThresholds: store.QualityReducer.qualityThresholds,
    }), shallowEqual)

    const [filter, setFilter] = useState({})

    const dispatch = useDispatch()

    useTitle(() => [{
        title: i18n.referencials,
        href: PATH_REFERENCIAL,
    },
    {
        title: i18n.parameters,
        href: PATH_REFERENCIAL_PARAMETER,
    },
    {
        title: code + (parameter.name ? ` - ${parameter.name}` : ''),
        href: PATH_REFERENCIAL_PARAMETER_ITEM_LINK + code,
    }], [])

    useActions(() => ({}), [])

    useEffect(() => {
        if (!parameter.code) {
            dispatch(ParameterAction.fetchParameter(code))
        }
        if (!qualitometers.length) {
            dispatch(QualityAction.fetchQualitometersLight())
        }
    }, [dispatch, code, parameter, qualitometers.length])

    const [analysis, setAnalysis] = useState([])

    useEffect(() => {
        if (parameter.code) {
            const filterObj = {
                prettyMode: true, // Option[Boolean]

                threshold: filter.threshold, // Option[Int]
                parameters: [parameter.code], // Option[Seq[String]]
                startDate: filter.startDate, // Option[DateTime]
                endDate: filter.endDate, // Option[DateTime]
                quantificationControl: filter.quantificationControl, // Option[Boolean]
            }
            dispatch(WaitAction.waitStart())
            AnalysisAction.getAnalysis(filterObj)
                .then(json => setAnalysis(json.map(a => new DtoPrettyAnalysis(a))))
                .finally(() => dispatch(WaitAction.waitStop()))
        }
    }, [dispatch, filter, parameter.code])

    const sortedAnalysis = useMemo(() => orderBy(analysis, 'analysisDate', 'asc'), [analysis])

    const threshold = useMemo(() => qualityThresholds.find(t => t.thresholdCode == filter.threshold), [filter.threshold, qualityThresholds])
    const thresholds = useMemo(() => threshold ? threshold.thresholds : [], [threshold])

    useEffect(() => {
        if (parameter.code && filter.threshold) {
            const foundThreshold = thresholds.find(t => t.parameterCode === parameter.code)
            if (!foundThreshold) {
                dispatch(ToastrAction.warning(`${i18n.noParameterForThreshold} ${parameter.code}`))
            }
        }
    }, [parameter.code, qualityThresholds, filter.threshold, dispatch, thresholds])

    return (
        <CartographyPanel
            layers={['STATIONS_POINTS']}
            analysis={sortedAnalysis}
            stationsPanelTitle={i18n.stationsWithDetection}
            componentType={'quality'}
            qualityThresholds={thresholds}
            colorMode
            sortingType={filter.usedStat}
            panel={<ParameterMapCriteriaPanel setFilter={setFilter} />}
        />
    )
}

export default ParameterMapApp
