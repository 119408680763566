import PropTypes from 'prop-types'
import React, { Component } from 'react'
import DtoCity from '../../../../../home/dto/DtoCity'
import CityInformationPanel from './CityInformationPanel'

class CityInformationModal extends Component {
    constructor(props) {
        super(props)
    }

    componentDidMount() {
        $('.modal-city').modal()
    }

    render() {
        if (this.props.city && this.props.city.code) {
            return (
                <div id={this.props.id} className='modal modal-city'>
                    <div className='modal-content'>
                        <CityInformationPanel city={this.props.city}/>
                    </div>
                </div>
            )
        }
        return null
    }

    componentDidUpdate() {
        $('.modal-city').modal()
    }
}
CityInformationModal.propTypes = {
    city: PropTypes.instanceOf(DtoCity).isRequired,
    id: PropTypes.string.isRequired,
}
CityInformationModal.defaultProps = {
    city: {},
    id: '1',
}
export default CityInformationModal
