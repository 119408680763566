import React, { useState } from 'react'
import i18n from 'simple-react-i18n'
import PropTypes from 'prop-types'
import { shallowEqual, useSelector } from 'react-redux'
import SimpleFilterSelect from 'components/forms/specific/SimpleFilterSelect'
import { Button, Card, CardContent, Grid2, Icon } from '@mui/material'
import { INDICATORS_COLORS } from 'utils/constants/ColorTheme'
import MultiAutocomplete from 'components/forms/MultiAutocomplete'
import SimpleCheckbox from 'components/forms/SimpleCheckbox'
import { getTypeModule } from 'utils/StationUtils'
import Input from 'components/forms/Input'
import { hasValue } from 'utils/NumberUtil'
import FilterInsertModal from 'components/modal/FilterInsertModal'
import { FILTER_TYPE } from 'administration/components/user/constants/UserConstants'

const NO_INDICATOR = -1
const NORMAL = 0
const VIGILANCE = 1
const CRISIS = 2
const NO_DATA = 3

const ProductionUnitFilterPanel = ({
    stationType,
    data = [],

    onChangeSearchValue = () => { },
    setFilterResults = () => { },
    setFilter = () => { },
    setSector = () => { },
    setAdmin = () => { },
    setOperator = () => { },

    defaultSearchValue,
    defaultFilter,
    defaultFilterResults,
    defaultSector,
    defaultAdmin,
    defaultOperator,

    defaultIndicators = [NO_INDICATOR, NORMAL, VIGILANCE, CRISIS, NO_DATA],
    setFilterIndicators = () => { },
}) => {
    const {
        contributors,
        sectors,
    } = useSelector(store => ({
        contributors: store.ContributorReducer.contributors,
        sectors: store.SectorReducer.sectors,
    }), shallowEqual)

    const [open, setOpen] = useState(false)
    const [search, setSearch] = useState(defaultSearchValue)
    const [tmpFilter, setTmpFilter] = useState(defaultFilter || FILTER_TYPE.NOFILTER)
    const [tmpFilterResults, setTmpFilterResults] = useState(defaultFilterResults || [])
    const [tmpSector, setTmpSector] = useState(defaultSector)
    const [tmpIndicators, setTmpIndicators] = useState(defaultIndicators)
    const [tmpAdmin, setTmpAdmin] = useState(defaultAdmin || [])
    const [tmpOperator, setTmpOperator] = useState(defaultOperator || [])

    const typeModule = getTypeModule(stationType)

    const onValidate = (newSearch) => {
        onChangeSearchValue(newSearch || setSearch)
        setFilterResults(tmpFilterResults)
        setFilter(tmpFilter)
        setSector(tmpSector)
        setFilterIndicators(tmpIndicators)
        setAdmin(tmpAdmin)
        setOperator(tmpOperator)
    }

    return (
        <Card>
            <CardContent>
                <Grid2 container spacing={1}>
                    <Grid2 container size={12} spacing={1.5}>
                        <Grid2 size={3}>
                            <Input
                                tooltip={i18n.searchThenEnter}
                                title={i18n.search}
                                value={search}
                                onChange={setSearch}
                                onEnterKeyPress={(value) => {
                                    setSearch(value)
                                    onValidate(value)
                                }}
                                clearFunction
                                data-cy='search'
                            />
                        </Grid2>
                        <Grid2 size={3}>
                            <SimpleFilterSelect
                                stationType={stationType}
                                onChange={(filterStations, newFilter) => {
                                    setTmpFilterResults(filterStations)
                                    setTmpFilter(newFilter)
                                }}
                                nullLabel={i18n.allStations}
                                stations={data}
                            />
                        </Grid2>
                        <Grid2 container size={0.5} alignItems='center'>
                            <Icon sx={{ cursor: 'pointer' }} onClick={() => setOpen(true)} data-cy='open-filter' fontSize='md'>
                                {hasValue(tmpFilter) && tmpFilter !== FILTER_TYPE.BOOKMARK && tmpFilter !== FILTER_TYPE.NOFILTER ? 'edit' : 'note_add'}
                            </Icon>
                        </Grid2>
                        <Grid2 container size={5.5} spacing={2}>
                            <Grid2 size={4}>
                                <MultiAutocomplete
                                    elements={contributors}
                                    label={i18n.buildingOwner}
                                    onChange={setTmpAdmin}
                                    values={tmpAdmin}
                                    clearFunction
                                />
                            </Grid2>
                            <Grid2 size={4}>
                                <MultiAutocomplete
                                    elements={contributors}
                                    label={i18n.operator}
                                    onChange={setTmpOperator}
                                    values={tmpOperator}
                                    clearFunction
                                />
                            </Grid2>
                            <Grid2 size={4}>
                                <MultiAutocomplete
                                    elements={sectors}
                                    label={i18n.sectors}
                                    onChange={setTmpSector}
                                    values={tmpSector}
                                    clearFunction
                                />
                            </Grid2>
                        </Grid2>
                    </Grid2>
                    <Grid2 container size={12} justifyContent='space-between'>
                        <Grid2 container alignItems='center'>
                            <Grid2>
                                <SimpleCheckbox
                                    label={i18n.normal}
                                    checked={tmpIndicators.includes(NORMAL)}
                                    onToggle={() => setTmpIndicators(prev => prev.includes(NORMAL) ? prev.filter(p => p !== NORMAL) : [NORMAL, ...prev])}
                                    labelStyle={{ padding: '0.25rem 1rem', borderRadius: '5px', backgroundColor: INDICATORS_COLORS.BLUE, color: 'white !important' }}
                                />
                            </Grid2>
                            <Grid2 sx={{ paddingLeft: '3rem' }}>
                                <SimpleCheckbox
                                    label={i18n.vigilance}
                                    checked={tmpIndicators.includes(VIGILANCE)}
                                    onToggle={() => setTmpIndicators(prev => prev.includes(VIGILANCE) ? prev.filter(p => p !== VIGILANCE) : [VIGILANCE, ...prev])}
                                    labelStyle={{ padding: '0.25rem 1rem', borderRadius: '5px', backgroundColor: INDICATORS_COLORS.YELLOW, color: 'black !important' }}
                                />
                            </Grid2>
                            <Grid2 sx={{ paddingLeft: '3rem' }}>
                                <SimpleCheckbox
                                    label={i18n.crisis}
                                    checked={tmpIndicators.includes(CRISIS)}
                                    onToggle={() => setTmpIndicators(prev => prev.includes(CRISIS) ? prev.filter(p => p !== CRISIS) : [CRISIS, ...prev])}
                                    labelStyle={{ padding: '0.25rem 1rem', borderRadius: '5px', backgroundColor: INDICATORS_COLORS.RED, color: 'white !important' }}
                                />
                            </Grid2>
                            <Grid2 sx={{ paddingLeft: '3rem' }}>
                                <SimpleCheckbox
                                    label={i18n.noData}
                                    checked={tmpIndicators.includes(NO_DATA)}
                                    onToggle={() => setTmpIndicators(prev => prev.includes(NO_DATA) ? prev.filter(p => p !== NO_DATA) : [NO_DATA, ...prev])}
                                    labelStyle={{ padding: '0.25rem 1rem', borderRadius: '5px', backgroundColor: INDICATORS_COLORS.GREY, color: 'white !important' }}
                                />
                            </Grid2>
                            <Grid2 sx={{ paddingLeft: '3rem' }}>
                                <SimpleCheckbox
                                    label={i18n.notDetermined}
                                    checked={tmpIndicators.includes(NO_INDICATOR)}
                                    onToggle={() => setTmpIndicators(prev => prev.includes(NO_INDICATOR) ? prev.filter(p => p !== NO_INDICATOR) : [NO_INDICATOR, ...prev])}
                                    labelStyle={{ padding: '0.25rem 1rem', borderRadius: '5px', backgroundColor: INDICATORS_COLORS.BLACK, color: 'white !important' }}
                                />
                            </Grid2>
                        </Grid2>
                        <Grid2 container size='auto' justifyContent='flex-end' alignItems='center'>
                            <Button
                                variant='contained'
                                onClick={() => onValidate()}
                                data-cy='searchBtn'
                            >
                                {i18n.search}
                            </Button>
                        </Grid2>
                    </Grid2>
                </Grid2>
            </CardContent>
            <FilterInsertModal
                setOpen={setOpen}
                open={open}
                selectedFilter={tmpFilter}
                module={typeModule}
                isNew={!hasValue(tmpFilter) || tmpFilter === FILTER_TYPE.BOOKMARK || tmpFilter === FILTER_TYPE.NOFILTER}
                onChange={setFilter}
            />
        </Card>
    )
}

ProductionUnitFilterPanel.propTypes = {
    stationType: PropTypes.string,
    onChangeSearchValue: PropTypes.func,
    data: PropTypes.arrayOf(PropTypes.shape({})),
    setFilterResults: PropTypes.func,
    setFilter: PropTypes.func,
    filter: PropTypes.number,
    setSector: PropTypes.func,
    sector: PropTypes.number,
    setCity: PropTypes.func,
    city: PropTypes.number,
    defaultSearchValue: PropTypes.string,
    defaultFilter: PropTypes.number,
    defaultFilterResults: PropTypes.arrayOf(PropTypes.number),
    defaultSector: PropTypes.number,
    defaultCity: PropTypes.number,
    defaultIndicators: PropTypes.arrayOf(PropTypes.number),
    setFilterIndicators: PropTypes.func,
    setAdmin: PropTypes.func,
    setOperator: PropTypes.func,
    defaultAdmin: PropTypes.arrayOf(PropTypes.number),
    defaultOperator: PropTypes.arrayOf(PropTypes.number),
}


export default ProductionUnitFilterPanel