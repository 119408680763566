import React, { Component } from 'react'
import SuperAdminAction from '../actions/SuperAdminAction'
import { pick } from 'lodash'
import i18n from 'simple-react-i18n'
import { PANEL_JOURNAL } from '../constants/SuperAdminConstants'
import Table from 'components/datatable/Table'
import { nbPerPageLabel } from 'referencial/constants/ReferencialConstants'
import { getDate } from 'utils/DateUtil'
import Card from 'components/card/Card'
import { Button, Grid } from '@mui/material'
import Input from 'components/forms/Input'
import SimpleDatePicker from 'components/forms/SimpleDatePicker'
import DtoJournal from '../dto/DtoJournal'

class SuperAdminJournalPanel extends Component {
    constructor(props) {
        super(props)
        this.state = {
            view: PANEL_JOURNAL,
            logs: [],
            limit: 5000,
            startDate: null,
            endDate: null,
            searchValue: '',
            user: null,
            function: null,
            code: null,
        }
    }

    componentDidMount() {
        const filters = pick(this.state, ['limit', 'searchValue', 'user', 'function', 'code', 'endDate', 'startDate'])
        SuperAdminAction.promiseLogs(filters).then((logs) => this.setState({ logs: logs.map((l) => new DtoJournal(l)) }))
    }

    render() {
        const logTable = this.state.logs.map((log) => ({ ...log, date: getDate(log.date) }))
        const header = { headers: ['date', 'comment', 'user', 'function', 'code'] }
        return (
            <div className='row margin-10 padding-top-1'>
                <Card>
                    <Grid container spacing={2} padding={1}>
                        <Grid item xs={2}>
                            <Input
                                value={this.state.searchValue}
                                onChange={(value) => this.setState({ searchValue: value })}
                                title={i18n.search}
                            />
                        </Grid>
                        <Grid item xs={1}>
                            <SimpleDatePicker
                                value={this.state.startDate}
                                label={i18n.startDate}
                                onChange={(value) => this.setState({ startDate: value })}
                            />
                        </Grid>
                        <Grid item xs={1}>
                            <SimpleDatePicker
                                value={this.state.endDate}
                                label={i18n.endDate}
                                onChange={(value) => this.setState({ endDate: value })}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <Input
                                value={this.state.user}
                                title={i18n.user}
                                onChange={(value) => this.setState({ user: value })}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <Input
                                value={this.state.function}
                                title={i18n.function}
                                onChange={(value) => this.setState({ function: value })}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <Input
                                value={this.state.code}
                                title={i18n.code}
                                onChange={(value) => this.setState({ code: value })}
                            />
                        </Grid>
                        <Grid item xs={1}>
                            <Button
                                style={{ marginTop: 10 }}
                                onClick={() => {
                                    const filters = pick(this.state, ['limit', 'searchValue', 'user', 'function', 'code', 'startDate', 'endDate'])
                                    SuperAdminAction.promiseLogs(filters).then((logs) => this.setState({ logs: logs.map((l) => new DtoJournal(l)) }))
                                }}
                                variant='contained'
                            >
                                {i18n.search}
                            </Button>
                        </Grid>
                    </Grid>
                </Card>
                <Table
                    title='Journal'
                    data={logTable}
                    type={header}
                    nbPerPageLabel={nbPerPageLabel}
                    sortable
                    paging
                />
            </div>
        )
    }
}

export default SuperAdminJournalPanel
