export default class DtoService {
    constructor(obj) {
        this.idService = obj.idService // Option[Long],
        this.technicalId = obj.technicalId // Option[String],
        this.productCode = obj.productCode // Option[String],
        this.designation = obj.designation // Option[String],
        this.startDate = obj.startDate // Option[DateTime],
        this.endDate = obj.endDate // Option[DateTime],
        this.updateDate = obj.updateDate // Option[DateTime],
        this.updateLogin = obj.updateLogin // Option[String]
    }
}