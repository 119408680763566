import ReferencialDto from '../../../dto/ReferencialDto'

export default class SupportItem extends ReferencialDto {
    constructor(object) {
        super(object)
        this.id = object.code
        this.code = object.code
        this.status = object.status
        this.active = object.actif
        this.headers = ['code', 'name', 'status', 'active']
    }
}