import User from 'account/dto/User'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import MultiContributorsAutocomplete from 'referencial/components/contributor/components/MultiContributorsAutocomplete'
import i18n from 'simple-react-i18n'
import { authorizeContributors } from 'utils/HabilitationUtil'
import ContributorItem from '../../../referencial/components/contributor/dto/ContributorItem'

const AdministratorPanel = ({
    contributors = [],
    accountUser = {},
    onChange = () => { },
    selectedValue = -1,
    isActive = false,
}) => (
    <div className='row no-margin'>
        <div className='col s12 padding-top-1'>
            <label style={{ fontSize: '1rem', fontWeight: 'bold', color: isActive ? '#161832' : '#9e9e9e' }}>{i18n.administrator}</label>
        </div>
        <div className='col s12 padding-bottom-1'>
            <MultiContributorsAutocomplete
                options={authorizeContributors(contributors, accountUser, selectedValue)}
                label={' '}
                col={12}
                onChange={v => onChange(v)}
                values={selectedValue}
                disabled={!isActive}
                keyLabel='labelDisplay'
            />
        </div>
    </div>

)

AdministratorPanel.propTypes = {
    contributors: PropTypes.arrayOf(PropTypes.instanceOf(ContributorItem)),
    onChange: PropTypes.func,
    selectedValue: PropTypes.number,
    isActive: PropTypes.bool,
    accountUser: PropTypes.instanceOf(User),
}

const mapStateToProps = store => ({
    contributors: store.ContributorReducer.contributors,
    accountUser: store.AccountReducer.accountUser,
})

export default connect(mapStateToProps)(AdministratorPanel)
