export default class DtoSample {
    constructor(obj) {
        this.id = obj.id
        this.qualitometer = obj.qualitometer
        this.date = obj.date
        this.comment = obj.comment
        this.laboInterpretation = obj.laboInterpretation
        this.producer = obj.producer
        this.producerRef = obj.producerRef
        this.numLabo = obj.numLabo
        this.labo = obj.labo
        this.refLabo = obj.refLabo
        this.accreditation = obj.accreditation
        this.localization = obj.localization
        this.difficulty = obj.difficulty
        this.fraction = obj.fraction
        this.methodOfFraction = obj.methodOfFraction
        this.support = obj.support
        this.operator = obj.operator
        this.representativeness = obj.representativeness
        this.manager = obj.manager
        this.operation = obj.operation
        this.request = obj.request

        // unused ?
        this.startDate = obj.startDate
        this.startHour = obj.startHour
        this.laboNumber = obj.laboNumber
    }
}