import { find, orderBy } from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { createIconMaterialState } from '../../../utils/MaterielUtils'
import DtoMaterielSituation from '../../dto/DtoMaterielSituation'
import DtoMaterielState from '../../dto/DtoMaterielState'

class LastSituationMarkerPanel extends Component {
    constructor(props) {
        super(props)
    }

    getLastState = () => {
        if (this.props.materielSituation.length) {
            const orderSituation = orderBy(this.props.materielSituation, 'id', 'desc')
            const lastSituation = orderSituation[0]
            if (lastSituation.statusCode) {
                const findState = find(this.props.materielStates, (o) => {
                    return o.id === lastSituation.statusCode
                })
                if (findState) {
                    return createIconMaterialState(findState.label, findState.code, '8rem', false)
                }
                return null
            }
            return null
        }
        return null
    }

    render() {
        const lastState = this.getLastState()
        if (lastState) {
            return (
                <div className='valign-wrapper'>
                    { lastState }
                </div>
            )
        }
        return null
    }
}

LastSituationMarkerPanel.propTypes = {
    materielSituation: PropTypes.arrayOf(PropTypes.instanceOf(DtoMaterielSituation)),
    materielStates: PropTypes.arrayOf(PropTypes.instanceOf(DtoMaterielState)),
}
const mapStateToProps = (store) => {
    return {
        materielStates: store.MaterielReducer.materielStates,
    }
}
export default connect(mapStateToProps)(LastSituationMarkerPanel)