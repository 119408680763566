'use strict'
import { RECEIVE_ACTIVITY, RECEIVE_ALL_ACTIVITY, RESET_ACTIVITY } from '../constants/ActivityConstants'
import DtoActivity from '../dto/DtoActivity'

export const store = {
    activities: [],
    activity: {},
}

export function ActivityReducer(state = {}, action) {
    switch (action.type) {
        case RECEIVE_ALL_ACTIVITY:
            return {
                ...state,
                activities: action.activities.map(a => new DtoActivity(a)),
            }
        case RECEIVE_ACTIVITY:
            return {
                ...state,
                activity: new DtoActivity(action.activity),
            }
        case RESET_ACTIVITY:
            return {
                ...state,
                activities: [],
            }
        default:
            return state
    }
}
