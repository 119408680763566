'use strict'
import {
    RECEIVE_ALL_HYDROGEOLOGICAL_ENTITIES,
    RECEIVE_HYDROGEOLOGICAL_ENTITY,
    RECEIVE_HYDROGEOLOGICAL_ENTITY_STATE,
    RECEIVE_HYDROGEOLOGICAL_ENTITY_STRUCTURE,
    RECEIVE_HYDROGEOLOGICAL_ENTITY_TYPE,
    RESET_HYDROGEOLOGICAL_ENTITY,
} from '../constants/HydrogeologicalEntityConstants'
import HydrogeologicalEntityDto from '../dto/HydrogeologicalEntityDto'
import HydrogeologicalEntityItem from '../dto/HydrogeologicalEntityItem'
import HydrogeologicalEntityItemDto from '../dto/HydrogeologicalEntityItemDto'

export function HydrogeologicalEntityReducer(state = {}, action) {
    switch (action.type) {
        case RECEIVE_ALL_HYDROGEOLOGICAL_ENTITIES:
            return Object.assign({}, state, {
                hydrogeologicalEntities: action.hydrogeologicalEntities.map(hydrogeologicalEntity => new HydrogeologicalEntityItemDto(hydrogeologicalEntity)),
            })
        case RECEIVE_HYDROGEOLOGICAL_ENTITY:
            return Object.assign({}, state, {
                hydrogeologicalEntity: new HydrogeologicalEntityDto(action.hydrogeologicalEntity),
            })
        case RECEIVE_HYDROGEOLOGICAL_ENTITY_STRUCTURE:
            return Object.assign({}, state, {
                hydrogeologicalEntityStructure: action.hydrogeologicalEntityStructure.map(hydrogeologicalEntityStructure => new HydrogeologicalEntityItem(hydrogeologicalEntityStructure)),
            })
        case RECEIVE_HYDROGEOLOGICAL_ENTITY_STATE:
            return Object.assign({}, state, {
                hydrogeologicalEntityState: action.hydrogeologicalEntityState.map(hydrogeologicalEntityState => new HydrogeologicalEntityItem(hydrogeologicalEntityState)),
            })
        case RECEIVE_HYDROGEOLOGICAL_ENTITY_TYPE:
            return Object.assign({}, state, {
                hydrogeologicalEntityType: action.hydrogeologicalEntityType.map(hydrogeologicalEntityType => new HydrogeologicalEntityItem(hydrogeologicalEntityType)),
            })
        case RESET_HYDROGEOLOGICAL_ENTITY:
            return Object.assign({}, state, {
                hydrogeologicalEntity: {},
            })
        default:
            return state
    }
}

export const store = {
    hydrogeologicalEntities: [],
    hydrogeologicalEntity: {},
    hydrogeologicalEntityStructure: [],
    hydrogeologicalEntityState: [],
    hydrogeologicalEntityType: [],
}