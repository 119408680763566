import UserAction from 'administration/components/user/actions/UserAction'
import { partition } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import ContactAction from 'referencial/components/contact/actions/ContactAction'
import i18n from 'simple-react-i18n'
import ActionComponent from '../../components/ActionComponent'
import HomeAction from '../../home/actions/HomeAction'
import { getFullDate } from '../../utils/DateUtil'
import MessageAction from '../actions/MessageAction'
import GlobalMessageCard from '../components/GlobalMessageCard'
import DtoMessage from '../dto/DtoMessage'
import ProgressCard from 'components/card/ProgressCard'
import { AccordionDetailsMUI, AccordionMUI, AccordionSummaryMUI } from 'components/styled/Accordions'

const exportHeaders = ['login', 'updateDate', 'message']

class DashboardMessages extends ActionComponent {
    state = {
        messagesLoaded: false,
    }

    componentDidMount() {
        this.props.setTitle([
            {
                title: i18n.messages,
                href: '/messages',
            },
        ])
        const { contacts, users } = this.props
        if (!contacts.length) {
            this.props.fetchContacts()
        }
        if (!users.length) {
            this.props.fetchUsers()
        }
        this.props.fetchLastMessages().then(() => {
            const { listOfLastMessages } = this.props
            this.setState({
                messagesLoaded: true,
            })
            const actions = {
                export: () => {
                    const lastMessagesFiltered = listOfLastMessages.map(obj => {
                        return {
                            ...obj,
                            updateDate: getFullDate(obj.updateDate),
                        }
                    })
                    return {
                        data: lastMessagesFiltered.map(obj => {
                            return {
                                ...obj,
                                headers: exportHeaders,
                            }
                        }),
                        exportType: 'xlsx',
                        titleFile: i18n.messages,
                    }
                },
            }
            this.setActions(actions)
        })
    }

    getListOfMessages = (listOfMessages) => {
        return listOfMessages.map(message => (
            <GlobalMessageCard
                lastMessage={message}
            />
        ))
    }

    getListOfMessagesByStatus = () => {
        const allLastMessages = this.props.listOfLastMessages
        return partition(allLastMessages, (message) => message.login === message.updateLogin)
    }

    getScrollingBanners = (listMessages, propertieElement, defaultOpen) => {
        return (
            <div style={{ maxWidth: '1100px', margin: 'auto', paddingBottom: '16px' }}>
                <AccordionMUI defaultExpanded={defaultOpen} round>
                    <AccordionSummaryMUI round>
                        {`${propertieElement} (${listMessages.length} ${listMessages.length < 2 ? i18n.element : i18n.elements})`}
                    </AccordionSummaryMUI>
                    <AccordionDetailsMUI sx={{ padding: 0 }} round>
                        {this.getListOfMessages(listMessages)}
                    </AccordionDetailsMUI>
                </AccordionMUI>
            </div>
        )
    }

    render = () => {
        const [unansweredMessages, answeredMessages] = this.getListOfMessagesByStatus()
        const { messagesLoaded } = this.state
        return (
            <div id='contents-app' className='margin-top-1'>
                {messagesLoaded ? (
                    <div className='row no-margin'>
                        {this.getScrollingBanners(unansweredMessages, i18n.unansweredMessages, true)}
                        {this.getScrollingBanners(answeredMessages, i18n.answeredMessages)}
                    </div>
                ) : (
                    <div className='row padding-1'>
                        <ProgressCard withMessage message={i18n.messagesWait} indeterminate round />
                    </div>
                )}
            </div>
        )
    }
}

const mapStateToProps = (store) => {
    return {
        listOfLastMessages: store.MessagesReducer.listOfLastMessages,
        users: store.UserReducer.users,
        contacts: store.ContactReducer.contacts,
    }
}

const mapDispatchToProps = {
    fetchLastMessages: MessageAction.fetchLastMessages,
    setTitle: HomeAction.setTitle,
    fetchUsers: UserAction.fetchUsers,
    fetchContacts: ContactAction.fetchContacts,
}

DashboardMessages.propTypes = {
    fetchLastMessages: PropTypes.func,
    listOfLastMessages: PropTypes.arrayOf(PropTypes.instanceOf(DtoMessage)),
    setTitle: PropTypes.func,
}

export default connect(mapStateToProps, mapDispatchToProps)(DashboardMessages)
