import { groupBy, orderBy, take } from 'lodash'
import Map from 'ol/Map'
import * as olProj from 'ol/proj'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import CityDto from 'referencial/components/city/dto/CityDto'
import i18n from 'simple-react-i18n'
import Card from '../../components/card/Card'
import Input from '../../components/forms/Input'
import { searchAllCharacters } from '../StringUtil'
import { getWGS84Coordinate } from './CoordinateUtils'

class SearchBarMap extends Component {
    constructor(props) {
        super(props)
        this.state = {
            searchVal: '',
        }
    }

    getDropDown = () => {
        if (this.state.searchVal.length >= 3) {
            const searchValue = searchAllCharacters(this.state.searchVal)
            const cities = this.props.cities.filter(({ name }) => searchAllCharacters(name).includes(searchValue))
            const results = [
                ...this.props.stationsPoints.filter(({ code = '', name = '', townCode = '' }) => {
                    return searchAllCharacters(code + name + townCode).includes(searchValue) || cities.includes(townCode)
                }),
                ...cities,
            ]
            const groupedResults = groupBy(results, res => {
                if (res.typeName) {
                    return `${res.typeName}`
                }
                return 'city'
            })
            const content = Object.keys(groupedResults).map(key => {
                const elements = groupedResults[key]
                const orderedElements = orderBy(elements,
                    [
                        ({ code = '' }) => code ? code.indexOf(searchValue) === 0 : null,
                        ({ code = '' }) => code ? [' ', '(', '\''].includes(code[code.indexOf(searchValue) - 1]) : null,
                        ({ name = '' }) => name ? name.length : 0,
                    ], ['desc', 'desc', 'asc'])
                    .filter(({ localisation, x, y }) => localisation || (x && y))
                const rows = take(orderedElements, 5).map(({ localisation, x, y, projection, code = '', name = '' }) => (
                    <div className='row no-margin padding-left-1 padding-right-1 cartoSearchLineHeight truncate clickable hoverHighlight' onClick={() => {
                        this.setState({ searchVal: '' })
                        if (localisation) {
                            this.props.olMap.getView().setCenter(olProj.fromLonLat(getWGS84Coordinate(localisation)))
                        } else if (projection) {
                            this.props.olMap.getView().setCenter(olProj.fromLonLat(getWGS84Coordinate({ x, y, projection })))
                        } else {
                            this.props.olMap.getView().setCenter(olProj.fromLonLat([x, y]))
                        }
                        this.props.olMap.getView().setZoom(15)
                    }}
                    >
                        <b className='black-text'>{`${code} - ${name}`}</b>
                    </div>
                ))
                return (
                    <div>
                        <div className='row no-margin padding-left-1 globalSearchLineHeight'>
                            <h6 className='bold black-text'>{i18n[key]}</h6>
                        </div>
                        {rows}
                    </div>
                )
            })
            return (
                <div className='carto-dropdown dropdown-content blue-arrow row no-margin' id='cartoSearchDropdown'>
                    <Card noMargin={false} className='margin-top-1'>
                        <div className='row no-margin'>
                            <div className='col s12 no-padding title'>
                                <h6 className='center-align'>
                                    <b className='bold black-text'>{`${results.length} ${results.length > 1 ? i18n.results : i18n.result}`}</b>
                                </h6>
                                <p className='no-margin no-padding divider' />
                            </div>
                        </div>
                        {content}
                    </Card>
                </div>
            )
        }
        return <div className='sieau-dropdown dropdown-content blue-arrow row no-margin inherited-position' id='cartoSearchDropdown' />
    }

    onEnterSearchValue = (v) => {
        this.setState({ searchVal: v })
        if (v.length >= 3) {
            if (v) {
                $('.carto-search-field').dropdown('open')
            }
        } else {
            $('.carto-search-field').dropdown('close')
        }
    }

    render() {
        return (<div className='row valign-wrapper'>
            <div className='ol-control btn ol-control-searchbar-style carto-search-div right hide-on-med-and-down'>
                <Input
                    noInputFieldClass
                    className='no-margin black-text carto-search-field dropdown-button'
                    placeholder={i18n.search}
                    value={this.state.searchVal}
                    onChange={(value) => this.onEnterSearchValue(value)}
                    dropDownId='cartoSearchDropdown'
                    otherInputProps={{
                        onFocus: () => $('.carto-search-icon').addClass('active'),
                        onBlur: () => $('.carto-search-icon').removeClass('active'),
                    }}
                />
            </div>
            {this.getDropDown()}
        </div>)
    }
}

SearchBarMap.propTypes = {
    stationsPoints: PropTypes.arrayOf(PropTypes.object),
    cities: PropTypes.arrayOf(PropTypes.instanceOf(CityDto)),
    olMap: PropTypes.instanceOf(Map),
}

const mapStateToProps = store => ({
    cities: store.CityReducer.cities,
})

export default connect(mapStateToProps)(SearchBarMap)
