import { useCallback } from 'react'
import { useSelector, shallowEqual, useDispatch } from 'react-redux'
import { SANDRE } from 'referencial/constants/ReferencialConstants'
import StationAction from 'station/actions/StationAction'
import { STATION_TYPE_CONSTANT } from 'station/constants/StationConstants'
import { getFileExtension, getFileName, getFileType, getFormatedFileName } from 'utils/FileUtils'

/**
 * allows to upload a file for a station
 * @param {number} id - station id
 * @param {string} code - station code / identifier
 * @param {string} stationType - station type on string format (cf STATION_TYPE_CONSTANT)
 * @return {function} the upload function promise
 */
const useUploadFile = (id, code, stationType) => {
    const dispatch = useDispatch()

    return useCallback((e, cb) => {
        const reader = new FileReader()
        const file = e.target.files[0]
        const fileName = getFileName(file.name)
        const extension = getFileExtension(file.name)
        const newFileName = getFormatedFileName(code, 1, fileName, extension, undefined, undefined, stationType)

        reader.onload = upload => {
            const { sandreCode } = getFileType(upload.target.result)

            const fileFormated = {
                stationType: STATION_TYPE_CONSTANT[stationType],
                stationId: id,
                path: '',
                name: newFileName,
                content: upload.target.result,
            }

            dispatch(StationAction.addStationFile(fileFormated, sandreCode === SANDRE.TYPE_PHOTO ? 'picture' : 'doc')).then(() => {
                dispatch(StationAction.fetchPictures(code, 7))
                dispatch(StationAction.fetchFiles(code, 7))
            }).then(() => cb(newFileName))
        }
        reader.readAsDataURL(file)
    }, [code, dispatch, id, stationType])
}

const useInstUploadFile = () => {
    const {
        installation,
    } = useSelector(store => ({
        installation: store.InstallationReducer.installation,
    }), shallowEqual)

    return useUploadFile(installation.id, installation.code, 'installation')
}

export {
    useUploadFile,
    useInstUploadFile,
}