import 'echarts/lib/chart/bar'
import { filter, isEqual, orderBy, reduce, take } from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import DtoUserStatisticItem from '../../../../administration/components/user/dto/DtoUserStatisticItem'
import Table from '../../../../components/datatable/Table'
import { getDate, getFullDateMini } from '../../../../utils/DateUtil'
import DtoAccountStatistic from '../../../dto/DtoAccountStatistic'
import User from '../../../dto/User'

class AccountStatisticsPanel extends Component {
    constructor(props) {
        super(props)
    }

    shouldComponentUpdate(nextProps) {
        return !isEqual(nextProps.accountStatistics, this.props.accountStatistics)
            || !isEqual(nextProps.accountUser, this.props.accountUser)
    }

    getReduceStatistics = (statistics) => {
        return reduce(statistics, (a, b) => {
            if (!a[`${getDate(b.statisticDate)}_${b.value}`]) {
                a[`${getDate(b.statisticDate)}_${b.value}`] = {
                    module: b.module,
                    value: b.value,
                    datas: [],
                }
            }
            a[`${getDate(b.statisticDate)}_${b.value}`].datas.push(b)
            return a
        }, {})
    }

    getDatas = (statistics) => {
        const reduceValues = this.getReduceStatistics(statistics)
        return orderBy(Object.keys(reduceValues).map((o) => {
            const statisticObject = reduceValues[o]
            const listOfStatistics = statisticObject.datas
            const lastVisit = orderBy(listOfStatistics, 'statisticDate', 'desc')[0]
            return new DtoUserStatisticItem({
                module: statisticObject.module,
                value: statisticObject.value,
                lastAccess: getFullDateMini(lastVisit.statisticDate),
                statisticDate: lastVisit.statisticDate,
                nbAccess: listOfStatistics.length,
            })
        }), 'statisticDate', 'desc')
    }

    render() {
        const statistics = filter(this.props.accountStatistics, o => o.value)
        if (statistics.length) {
            const data = this.getDatas(statistics)
            return (
                <div className='row no-margin card active'>
                    <div className='col s12 no-padding'>
                        <Table title={ `5 ${i18n.userStatistics}` }
                            type={ new DtoUserStatisticItem({}) } data={ take(data, 5) }
                            exportButtonOnHeader exportData={ data }
                            showNbElements={ false }
                            activeHeader
                            exportName={ i18n.userStatisticsExport + this.props.accountUser.login }
                        />
                    </div>
                </div>
            )
        }
        return null
    }
}

AccountStatisticsPanel.propTypes = {
    accountUser: PropTypes.instanceOf(User),
    accountStatistics: PropTypes.arrayOf(PropTypes.instanceOf(DtoAccountStatistic)),
}
const mapStateToProps = (store) => {
    return {
        accountStatistics: store.AccountReducer.accountStatistics,
        accountUser: store.AccountReducer.accountUser,
    }
}
export default connect(mapStateToProps)(AccountStatisticsPanel)