import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import Input from '../../../../../components/forms/Input'
import NumberField from '../../../../../components/forms/NumberField'
import DtoBoreholeComment from '../dto/DtoBoreholeComment'

class BoreholeCommentModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            boreholeComment: { ...props.boreholeComment, idStation: props.id },
        }
    }

    componentDidUpdate(prevProps) {
        if (!prevProps.popupIsOpen && this.props.popupIsOpen) {
            $('#descriptionModal').on('click', this.onSave)
        }
    }
    onSave = () => {
        this.props.saveResult(new DtoBoreholeComment(this.state.boreholeComment))
        $('.modal').modal('close')
    }

    onChangeElement = (changes) => this.setState({ boreholeComment: { ...this.state.boreholeComment, ...changes } })

    render() {
        const { boreholeComment } = this.state
        return (
            <div>
                <div className='row no-margin padding-top-1'>
                    <NumberField col={ 2 } value={ boreholeComment.order } title={ i18n.order } onChange={ v => this.onChangeElement({ order: v }) } />
                    <NumberField col={ 2 } value={ boreholeComment.depth } title={ i18n.depth } onChange={ v => this.onChangeElement({ depth: v }) } floatValue/>
                    <Input col={ 8 } vale={ boreholeComment.comment } title={ i18n.comment } onChange={ v => this.onChangeElement({ comment: v }) } />
                </div>
            </div>
        )
    }
}

BoreholeCommentModal.propTypes = {
    boreholeComment: PropTypes.instanceOf(DtoBoreholeComment),
    id: PropTypes.number,
    popupIsOpen: PropTypes.bool,
    saveResult: PropTypes.func,
}


const mapStateToProps = store => ({
    popupIsOpen: store.SieauReducer.popupIsOpen,
})

BoreholeCommentModal.defaultProps = {
    boreholeComment: {},
}

export default connect(mapStateToProps)(BoreholeCommentModal)