import React, { Component } from 'react'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import Card from '../../../../components/card/Card'
import Input from '../../../../components/forms/Input'
import Select from '../../../../components/forms/Select'
import Checkbox from '../../../../components/forms/Checkbox'
import { INSTALLATION_ORIGIN, INSTALLATION_TYPE } from '../../../constants/InstallationConstants'
import { i18nize } from '../../../../utils/StringUtil'
import { arrayOf, getSandreList } from '../../../../utils/StoreUtils'
import { connect } from 'react-redux'
import DtoInstallationType from '../../../dto/installation/DtoInstallationType'
import CityDto from '../../../../referencial/components/city/dto/CityDto'
import DtoInstallationActivity from '../../../dto/industrialSite/DtoInstallationActivity'
import { getStatusesWithNoStatus } from '../../../../utils/QualityUtils'
import { SANDRE } from '../../../../referencial/constants/ReferencialConstants'
import DtoSandreCode from '../../../../referencial/dto/DtoSandreCode'
import { sieauTooltip } from 'utils/FormUtils'
import { isUndefined, sortBy, uniq } from 'lodash'
import DtoFilter from 'station/dto/DtoFilter'
import StationAction from 'station/actions/StationAction'
import { getSetting, getUser } from 'utils/SettingUtils'
import { hasValue } from 'utils/NumberUtil'
import FilterInsertModal from 'components/modal/FilterInsertModal'
import DtoUserBookmark from 'administration/components/user/dto/DtoUserBookmark'
import UserAction from 'administration/components/user/actions/UserAction'
import { Button, Grid } from '@mui/material'
import SuperMultiAutocomplete from 'components/forms/SuperMultiAutocomplete'
import MultiContributorsAutocomplete from 'referencial/components/contributor/components/MultiContributorsAutocomplete'
import DtoNetwork from 'station/dto/DtoNetwork'
import ContributorItem from 'referencial/components/contributor/dto/ContributorItem'
import DtoContributorLink from 'station/dto/DtoContributorLink'
import SieauParameterDto from 'administration/dto/SieauParameterDto'
import { isAepInstallation } from 'utils/InstallationUtils'
import { DEFAULT_CONTRIBUTOR_TYPE } from 'administration/components/user/constants/UserConstants'

class InstallationsFilterForm extends Component {
    constructor(props) {
        super(props)
        this.state = {
            open: false,
            filter: { ...props.defaultFilter },
            network: [],
            networklist: [],
        }
    }

    componentDidMount() {
        const {
            filters,
            userBookmarks,
        } = this.props

        if (!filters.length) {
            this.props.fetchFilters()
        }
        if (!userBookmarks.length) {
            this.props.fetchBookmarks()
        }
    }

    onChangeValue = (value, cb = () => { }) => {
        const { filter } = this.state
        return this.setState({ filter: { ...filter, ...value } }, cb)
    }

    getIcon = (panelName, iconName, i18N) => (
        <i className='material-icons right clickable map-right-icon'
            {...sieauTooltip(i18N)} onClick={ () => this.props.onChangePanel(panelName) }
        >
            { iconName }
        </i>
    )

    getSpecificFilters = () => {
        const { filter } = this.state
        switch (filter.installationType) {
            case INSTALLATION_TYPE.INDUSTRIAL_SITE:
                return [
                    <Grid item xs={2}>
                        <Select
                            label={i18n.activities}
                            options={this.props.activities}
                            value={filter.activity}
                            onChange={(v) => this.onChangeValue({ activity: v })}
                        />
                    </Grid>,
                    <Grid item xs={2}>
                        <Checkbox
                            label={i18n.icpe}
                            checked={filter.ICPE}
                            onChange={(v) => this.onChangeValue({ ICPE: v })}
                            componentClassName='padding-top-1'
                        />
                    </Grid>,
                    <Grid item xs={2} >
                        <Checkbox
                            label={i18n.dischargeAgreement}
                            checked={filter.dischargeAgreement}
                            onChange={(v) => this.onChangeValue({ dischargeAgreement: v })}
                            componentClassName='padding-top-1'
                        />
                    </Grid>,
                    <Grid item xs={2}/>,
                ]
            case INSTALLATION_TYPE.BOREHOLE:
                return [
                    <Grid item xs={2}>
                        <Select
                            label={i18n.control}
                            options={getStatusesWithNoStatus()}
                            value={filter.status}
                            keyvalue='code'
                            nullLabel='&nbsp;'
                            onChange={(v) => this.onChangeValue({ status: v })}
                            integerValue
                        />
                    </Grid>,
                    <Grid item xs={2} >
                        <Select
                            label={i18n.origin}
                            options={i18nize(INSTALLATION_ORIGIN.LIST)}
                            value={filter.dataOrigin}
                            keyvalue='code'
                            keyLabel='label'
                            nullLabel='&nbsp;'
                            onChange={(v) => this.onChangeValue({ dataOrigin: v })}
                            integerValue
                        />
                    </Grid>,
                    <Grid item xs={2} >
                        <Select
                            label={i18n.usage}
                            options={getSandreList(this.props.sandreCodes, SANDRE.USAGES)}
                            value={filter.usage}
                            keyvalue='code'
                            keyLabel='name'
                            nullLabel='&nbsp;'
                            onChange={(v) => this.onChangeValue({ usage: v })}
                            integerValue
                        />
                    </Grid>,
                    <Grid item xs={2} >
                        <Checkbox
                            label={i18n.withoutId}
                            checked={filter.withoutCode}
                            onChange={(v) => this.onChangeValue({ withoutCode: v })}
                            componentClassName='padding-top-1'
                        />
                    </Grid>,
                ]
            default:
                return [
                    <Grid item xs={2}>
                        <Select
                            label={i18n.control}
                            options={getStatusesWithNoStatus()}
                            value={filter.status}
                            keyvalue='code'
                            nullLabel='&nbsp;'
                            onChange={(v) => this.onChangeValue({ status: v })}
                            integerValue
                        />
                    </Grid>,
                    <Grid item xs={2} >
                        <Select
                            label={i18n.origin}
                            options={i18nize(INSTALLATION_ORIGIN.LIST)}
                            value={filter.dataOrigin}
                            keyvalue='code'
                            keyLabel='label'
                            nullLabel='&nbsp;'
                            onChange={(v) => this.onChangeValue({ dataOrigin: v })}
                            integerValue
                        />
                    </Grid>,
                    <Grid item xs={2}>
                        <Checkbox
                            label={ i18n.withoutId }
                            checked={ filter.withoutCode }
                            onChange={(v) => this.onChangeValue({ withoutCode: v })}
                            componentClassName='padding-top-1'
                        />
                    </Grid>,
                    <Grid item xs={2}/>,
                ]
        }
    }

    render() {
        const { filter, open, network, networklist } = this.state
        const { filters, contributorLinks, contributorsIndex, settings } = this.props

        const operatorType = parseInt(getSetting(settings, 'contributorTypeOperator')) || DEFAULT_CONTRIBUTOR_TYPE.OPERATOR
        const referentType = parseInt(getSetting(settings, 'contributorTypeAdministrator')) || DEFAULT_CONTRIBUTOR_TYPE.ADMINISTRATOR

        const installReferents = !isUndefined(contributorLinks) ? uniq(contributorLinks
            .filter(c => c.contributorType === referentType).map(l => l.idContributor))
            .map(id => contributorsIndex[id])
            .filter(c => !isUndefined(c)) : []

        const installOperators = !isUndefined(contributorLinks) ? uniq(contributorLinks
            .filter(c => c.contributorType === operatorType).map(l => l.idContributor))
            .map(id => contributorsIndex[id])
            .filter(c => !isUndefined(c)) : []

        const baseFilterOptions = [{ value: -5, label: i18n.bookmarks }, { value: -1, label: i18n.allStations }]

        const filterOptions = filters.filter(f => f.module === 'INST').map(f => ({ value: f.code, label: f.name }))

        const filtersWithBookmarks = [
            ...baseFilterOptions,
            ...sortBy(filterOptions, 'label'),
        ]
        return (
            <Card noMargin={false} round className='col s10 no-padding' >
                <Grid container rowSpacing={1} columnSpacing={2} className='padding-top-1' padding={1}>
                    <Grid container columnSpacing={2} item xs={12}>
                        <Grid item xs={2} >
                            <Input
                                title={i18n.search}
                                value={filter.searchValue}
                                onChange={(v) => this.onChangeValue({ searchValue: v })}
                                onEnterKeyPress={(v) => this.onChangeValue({ searchValue: v }, () => this.props.onValidate(filter))}
                            />
                        </Grid>
                        <Grid container item xs={2} >
                            <Grid item xs={11}>
                                <Select
                                    label={i18n.filters}
                                    options={filtersWithBookmarks}
                                    className='no-padding'
                                    noNullValue
                                    onChange={(v) => this.onChangeValue({ selectedFilter: v })}
                                    value={filter.selectedFilter}
                                />
                            </Grid>
                            <Grid item xs={1}>
                                {getUser().isAdmin == 1 && (
                                    <div className='col s1 no-padding' onClick={() => this.setState({ open: true })}>
                                        <i className='material-icons qualityFilterAddIcon clickable'>
                                            {filter.selectedFilter === -1 || filter.selectedFilter === -5 ? 'note_add' : 'edit'}
                                        </i>
                                    </div>)}
                            </Grid>
                        </Grid>
                        <Grid item xs={2} >
                            <Select
                                label={i18n.city}
                                options={this.props.cities}
                                value={filter.city}
                                keyLabel='labelWithCode'
                                onChange={(v) => this.onChangeValue({ city: v })}
                            />
                        </Grid>
                        {(!hasValue(filter.installationType) || isAepInstallation(filter.installationType)) && !!installReferents.length && (
                            <Grid item xs={2}>
                                <MultiContributorsAutocomplete
                                    label={i18n.buildingOwnerName}
                                    options={installReferents}
                                    onChange={(v) => this.onChangeValue({ referentIds: v })}
                                    keyValue='code'
                                    multiple
                                />
                            </Grid>
                        )}
                        {(!hasValue(filter.installationType) || isAepInstallation(filter.installationType)) && !!installOperators.length && (
                            <Grid item xs={2}>
                                <MultiContributorsAutocomplete
                                    label={i18n.operator}
                                    options={installOperators}
                                    onChange={(v) => this.onChangeValue({ operatorIds: v })}
                                    keyValue='code'
                                    multiple
                                />
                            </Grid>
                        )}
                        {!!networklist.length && (
                            <Grid item xs={2} >
                                <SuperMultiAutocomplete
                                    label={i18n.network}
                                    options={networklist}
                                    keyLabel='labelWithSandre'
                                    additionalKeysFilter={['sandreCode', 'name', 'mnemonic']}
                                    onChange={(v) => this.onChangeValue({ network: v })}
                                    values={network}
                                />
                            </Grid>
                        )}
                    </Grid>
                    <Grid item xs={2}>
                        <Select
                            label={i18n.type}
                            options={this.props.installationsTypes.filter(it => it.display)}
                            value={filter.installationType}
                            keyvalue='id'
                            nullLabel='&nbsp;'
                            onChange={(t) => this.onChangeValue({ installationType: t })}
                            integerValue
                        />
                    </Grid>
                    {this.getSpecificFilters()}
                    <Grid item xs={2}>
                        <Button
                            variant='contained'
                            fullWidth
                            onClick={() => this.props.onValidate(filter)}
                        >
                            {i18n.search}
                        </Button>
                    </Grid>

                </Grid>
                <FilterInsertModal
                    setOpen={op => this.setState({ open: op })}
                    open={open}
                    selectedFilter={filter.selectedFilter}
                    module='INST'
                    isNew={!hasValue(filter.selectedFilter) || filter.selectedFilter === -5 || filter.selectedFilter === -1}
                    onChange={(v) => this.onChangeValue({ selectedFilter: v })}
                />
            </Card>
        )
    }
}

InstallationsFilterForm.propTypes = {
    onValidate: PropTypes.func,
    onChangePanel: PropTypes.func,
    defaultFilter: PropTypes.shape({
        city: PropTypes.string,
        dataOrigin: PropTypes.number,
        forceLoa: PropTypes.bool,
        referentIds: PropTypes.arrayOf(PropTypes.number),
        operatorIds: PropTypes.arrayOf(PropTypes.number),
        installationType: PropTypes.number,
        module: PropTypes.string,
        searchValue: PropTypes.string,
        selectedFilter: PropTypes.number,
        status: PropTypes.number,
        withoutCode: PropTypes.bool,
    }),
    panel: PropTypes.string,
    activities: arrayOf(DtoInstallationActivity),
    installationsTypes: arrayOf(DtoInstallationType),
    cities: arrayOf(CityDto),
    sandreCodes: arrayOf(DtoSandreCode),
    filters: arrayOf(DtoFilter),
    fetchFilters: PropTypes.func,
    userBookmarks: arrayOf(DtoUserBookmark),
    fetchBookmarks: PropTypes.func,
    networklist: PropTypes.arrayOf(PropTypes.instanceOf(DtoNetwork)),
    contributorLinks: PropTypes.arrayOf(PropTypes.instanceOf(DtoContributorLink)),
    contributorsIndex: PropTypes.arrayOf(PropTypes.instanceOf(ContributorItem)),
    settings: PropTypes.arrayOf(PropTypes.instanceOf(SieauParameterDto)),
}
const mapStateToProps = store => ({
    installationsTypes: store.InstallationReducer.installationsTypes,
    cities: store.CityReducer.cities,
    activities: store.InstallationReducer.activities,
    sandreCodes: store.ReferencialReducer.sandreCodes,
    filters: store.StationReducer.filters,
    userBookmarks: store.UserReducer.userBookmarks,
    settings: store.AdministrationReducer.applicationSettings,
    contributorsIndex: store.ContributorReducer.contributorsIndex,
})

const mapDispatchToProps = {
    fetchFilters: StationAction.fetchFilters,
    fetchBookmarks: UserAction.fetchBookmarks,
}

export default connect(mapStateToProps, mapDispatchToProps)(InstallationsFilterForm)
