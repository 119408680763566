import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import Select from '../../../../components/forms/Select'
import DtoWatermassLink from '../../../../piezometry/dto/DtoWatermassLink'
import WatermassDto from '../../../../referencial/components/watermass/dto/WatermassDto'
import { checkMandatoryFields } from '../../../../utils/FormUtils'
import { arrayOf } from '../../../../utils/StoreUtils'

class WatermassModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            watermass: { ...props.watermass, idStation: props.id },
        }
    }

    componentDidUpdate(prevProps) {
        if (!prevProps.popupIsOpen && this.props.popupIsOpen) {
            $('#descriptionModal').on('click', this.onSave)
        }
    }

    onSave = () => {
        const mandatoryFields = [{ field: 'watermassCode', i18n: 'watermass' }]
        checkMandatoryFields(mandatoryFields, this.state.watermass, () => {
            this.props.saveResult(new DtoWatermassLink(this.state.watermass))
            $('.modal').modal('close')
        })
    }

    onChangeElement = (changes) => this.setState({ watermass: { ...this.state.watermass, ...changes } })

    render() {
        const { watermass } = this.state
        return (
            <div>
                <div className='row no-margin'>
                    <Select col={ 12 } value={ watermass.watermassCode } label={ i18n.watermass } options={ this.props.watermasses } keyValue='code' displayWithCode
                        onChange={ v => this.onChangeElement({ watermassCode: v }) }
                    />
                </div>
            </div>
        )
    }
}

WatermassModal.propTypes = {
    watermass: PropTypes.instanceOf(DtoWatermassLink),
    id: PropTypes.number,
    popupIsOpen: PropTypes.bool,
    saveResult: PropTypes.func,
    watermasses: arrayOf(WatermassDto),
}


const mapStateToProps = store => ({
    popupIsOpen: store.SieauReducer.popupIsOpen,
    watermasses: store.WatermassReducer.watermasses,
})

WatermassModal.defaultProps = {
    watermass: {},
}

export default connect(mapStateToProps)(WatermassModal)
