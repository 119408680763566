import ReferencialDto from '../../../dto/ReferencialDto'

export default class WatershedDto extends ReferencialDto {
    constructor(object) {
        super(object)
        this.area = object.area
        this.parent = object.parent
        this.comment = object.comment
        this.startMonthLowWater = object.startMonthLowWater
        this.endMonthLowWater = object.endMonthLowWater
        this.km_polygon = object.kml_polygon
        this.headers = ['code', 'name', 'area', 'startMonthLowWater', 'endMonthLowWater', 'km_polygon']
    }
}