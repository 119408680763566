import React, { Component } from 'react'
import i18n from 'simple-react-i18n'
import PropTypes from 'prop-types'
import { onChangeDate, onChangeHour } from '../../../../utils/FormUtils'
import { getDate, getFullDate, getHour } from '../../../../utils/DateUtil'
import { connect } from 'react-redux'
import { arrayOf, getSandreList } from '../../../../utils/StoreUtils'
import DtoPiezometer from '../../../dto/DtoPiezometer'
import DtoPiezometryStationMeasure from '../../../../station/dto/piezometer/DtoPiezometryStationMeasure'
import Row from '../../../../components/react/Row'
import Select from '../../../../components/forms/Select'
import DtoSandreCode from '../../../../referencial/dto/DtoSandreCode'
import { nbPerPageLabel, SANDRE } from '../../../../referencial/constants/ReferencialConstants'
import Input from '../../../../components/forms/Input'
import Button from '../../../../components/forms/Button'
import DtoStatus from '../../../../quality/dto/DtoStatus'
import DtoContributor from '../../../../station/dto/DtoContributor'
import { Dialog, Button as MuiButton } from '@mui/material'
import { hasValue } from '../../../../utils/NumberUtil'
import DtoQualification from '../../../../quality/dto/DtoQualification'
import DtoJobParameters from '../../../../import/dto/DtoJobParameters'
import JobAction from '../../../../import/actions/JobAction'
import { getLogin, getSettingInt } from '../../../../utils/SettingUtils'
import { getNGFValue, setMeasureLandmarkAndRefAlti } from '../../../../utils/PiezometryUtils'
import { MEASURE_COTE } from '../../../constants/PiezometryConstants'
import { maxBy, minBy, pick, uniqBy } from 'lodash'
import PiezometerStationAction from '../../../../station/actions/PiezometerStationAction'
import moment from 'moment'
import SebaManualPanel from './manualImport/SebaManualPanel'
import MISManualPanel from './manualImport/MISManualPanel'
import CSVGenericPiezoManualPanel from './manualImport/CSVGenericPiezoManualPanel'
import { getMeasureCoteList } from '../../../../utils/JobUtils'
import RadioButtons from '../../../../components/forms/RadioButtons'
import AppStore from '../../../../store/AppStore'
import PiezometryAction from '../../../actions/PiezometryAction'
import SieauParameterDto from '../../../../administration/dto/SieauParameterDto'
import ToastrAction from '../../../../toastr/actions/ToastrAction'
import Table from '../../../../components/datatable/Table'
import DtoJob from '../../../../import/dto/DtoJob'
import { WhiteCard } from '../../../../components/styled/Card'
import { integrationModeHelpIcon } from '../../../../import/utils/ImportUtils'
import { DialogActionsMUI, DialogContentMUI, DialogTitleMUI } from 'components/styled/Dialog'
import Icon from 'components/icon/Icon'
import { StyledFieldSet } from 'components/StyledElements'

class ImportToolPanel extends Component {
    constructor(props) {
        super(props)
        this.state = {
            validateActive: false,
            jobType: '',
            open: false,
            job: new DtoJobParameters({ import: {
                measureCote: 2,
                producer: getSettingInt(props.applicationSettings, 'validationDefaultProducer'),
                manager: getSettingInt(props.applicationSettings, 'validationDefaultManager'),
                validator: getSettingInt(props.applicationSettings, 'validationDefaultValidator'),
            } }),
            fileContent: null,
            logs: [],
            selectedJob: null,
        }
    }

    componentDidMount() {
        if (!this.props.jobs.length) {
            this.props.fetchJobs()
        }
    }

    onSave = () => {
        const { measures, piezometerImportedMeasure, datesImport, display, dates, piezometer } = this.props
        const { job } = this.state
        const { startDateImport, endDateImport } = datesImport
        const filteredImportedMeasure = piezometerImportedMeasure.filter(measure => measure.date >= startDateImport && measure.date <= endDateImport).map(m => {
            if (!m.isNew) {
                return m
            }
            return {
                ...m,
                status: job.import.status,
                qualification: job.import.qualification,
                nature: job.import.nature,
                validator: job.import.validator,
                manager: job.import.manager,
                producer: job.import.producer,
                cote: this.props.selectedDataType === -1 ? job.import.measureCote : null,
                dataType: this.props.selectedDataType,
                codepoint: this.props.selectedCodePoint,
            }
        })

        if (display === 'addEnd' || display === 'cumul') {
            const mergedMeasures = display === 'addEnd' ?
                filteredImportedMeasure.filter(measure => measure.date > dates.endDate) :
                uniqBy([
                    ...measures,
                    ...filteredImportedMeasure,
                ], m => getFullDate(m.date)).filter(m => m.updated || m.isNew)

            this.props.updatePiezometerRawMeasures(piezometer.id, mergedMeasures, measures, () => {
                this.onCancel()
                this.props.createTechnicalEvent(filteredImportedMeasure)
                this.props.reloadData()
            })
        } else if (display === 'replace') {
            const data = {
                measures: filteredImportedMeasure,
                user: getLogin(),
                start: startDateImport,
                end: endDateImport,
            }
            this.props.replacePiezometerRawMeasures(piezometer.id, data, measures, json => {
                this.onCancel()
                this.props.createTechnicalEvent(json)
                this.props.reloadData()
                AppStore.dispatch(PiezometryAction.refreshOnePiezometerSituation(this.props.piezometer.id, this.props.selectedDataType))
            })
        }
    }

    importData = () => {
        this.setState({ logs: [] })
        const { job, jobType, fileContent } = this.state
        const { piezometer, lastLandmark, groundRefAlti, dates } = this.props

        const jobParameters = new DtoJobParameters({
            ...job,
            user: getLogin(),
            jobType,
            fileContent,
            isManualImport: true,
            returnsData: true,
            routingKey: 'import',
            jobExecutionId: -1,
        })
        const isNGF = JSON.parse(job?.filters?.[0] ?? '{}')?.measureCote === 1
        this.props.executeJobManually(jobParameters, returnData => {
            const measures = (returnData?.measures || []).filter(m => m.dataType === this.props.selectedDataType)
            if (measures.length) {
                const piezometerImportedMeasure = measures.map(measure => {
                    const measureWithLandmark = setMeasureLandmarkAndRefAlti(measure, piezometer)
                    const NGF = isNGF ? measure.value : getNGFValue(measureWithLandmark, MEASURE_COTE.LANDMARK, lastLandmark, groundRefAlti) || getNGFValue(measureWithLandmark, MEASURE_COTE.DEPTH, lastLandmark, groundRefAlti)
                    return {
                        ...measureWithLandmark,
                        piezoId: piezometer.id,
                        status: job.import.status,
                        qualification: job.import.qualification,
                        nature: job.import.nature,
                        updateDate: moment().valueOf(),
                        updateLogin: getLogin(),
                        NGF,
                        initialNGF: NGF,
                        initialValue: measureWithLandmark.value,
                        validator: job.import.validator,
                        manager: job.import.manager,
                        producer: job.import.producer,
                        importDate: moment().valueOf(),
                        jobExecutionId: returnData.jobExecutionId,
                        isNew: true,
                        dataType: this.props.selectedDataType,
                    }
                })
                const minImportDate = minBy(measures, (v) => v.date)
                const maxImportDate = maxBy(measures, (v) => v.date)

                if (minImportDate.date < dates.minDate || (dates.maxDate && maxImportDate.date > dates.maxDate)) {
                    this.setState({ isOpenWarnImport: true })
                }
                this.props.changeParent({
                    piezometerImportedMeasure,
                    startDateImport: minImportDate?.date,
                    endDateImport: maxImportDate?.date,
                })
                this.setState({ validateActive: true, open: false })
            } else {
                AppStore.dispatch(ToastrAction.warning(i18n.noDataToDisplay))
                this.setState({ logs: returnData.logs })
            }
        })
    }

    onCancel = () => {
        this.props.onCancel()
        this.setState({
            validateActive: false,
            jobType: '',
            open: false,
            job: new DtoJobParameters(),
            fileContent: null,
        })
    }

    getPossibleImports = () => {
        return [
            {
                value: 'csvAll',
                label: i18n.CSVGeneric,
            },
            {
                value: 'seba',
                label: 'SEBA',
            },
            {
                value: 'MISfiles',
                label: 'Fichiers .MIS',
            },
        ]
    }

    setJobImport = (dataType, changes) => {
        const { job } = this.state
        switch (dataType) {
            case 'import':
                this.setState({ job: { ...job, import: { ...job.import, ...changes } } })
                break
            case 'parameters':
                this.setState({ job: { ...job, parameters: { ...job.parameters, ...changes } } })
                break
            case 'filters':
                this.setState({ job: { ...job, filters: { ...job.filters, ...changes } } })
                break
            case 'data':
                const newData = job.data.filter(d => d.id !== changes.id).concat(changes)
                this.setState({ job: { ...job, data: newData } })
                break
            default:
                break
        }
    }

    getDialogContent = () => {
        const { jobType, job } = this.state
        switch (jobType) {
            case 'csvAll':
                return (
                    <CSVGenericPiezoManualPanel
                        job={{ parameters: job }}
                        selectedDataType={ this.props.selectedDataType }
                        onChangeJob={(changes) => this.setState({ job: { ...job, ...changes.parameters } })}
                        isEditMode
                    />
                )
            case 'seba':
                return (
                    <SebaManualPanel
                        job={{ parameters: job }}
                        selectedDataType={ this.props.selectedDataType }
                        onChangeJob={(changes) => this.setState({ job: { ...job, ...changes.parameters } })}
                        isEditMode
                    />
                )
            case 'MISfiles':
                return (
                    <MISManualPanel
                        job={{ parameters: job }}
                        selectedDataType={ this.props.selectedDataType }
                        onChangeJob={(changes) => this.setState({ job: { ...job, ...changes.parameters } })}
                        isEditMode
                    />
                )
            default:
                return i18n.controlErrors
        }
    }

    getStatusColor = (status) => {
        switch (status) {
            case 'success':
                return { className: 'green-200' }
            case 'warning':
                return { className: 'yellow-200' }
            case 'error':
                return { className: 'red-200' }
            default:
                return null
        }
    }

    getLogsPanel = () => {
        if (!this.state.logs.length) {
            return null
        }
        const logs = this.state.logs.map(l => ({
            date: { value: getFullDate(l.date), ...this.getStatusColor(l.status) },
            status: { value: l.status, ...this.getStatusColor(l.status) },
            comment: { value: l.value, ...this.getStatusColor(l.status) },
        }))
        return (
            <Table
                data={logs}
                sortable
                paging
                color
                nbPerPageLabel={nbPerPageLabel}
                title={i18n.journal}
                type={{ headers: ['date', 'status', 'comment'] }}
                condensed
                noHightlight
            />
        )
    }

    onUploadFile = (e) => {
        const reader = new FileReader()
        const file = e.target.files[0]
        if (file) {
            reader.onloadend = (event) => {
                const split = event.target.result.split(',')
                this.setState({ fileContent: split[1] })
            }
            reader.readAsDataURL(file)
        }
    }

    render() {
        const { datesImport, qualifications, sandreCodes, contributors, display, piezometerImportedMeasure, changeParent } = this.props
        const { job, validateActive, jobType, open, selectedJob, isOpenWarnImport } = this.state
        const displays = [
            { code: 'cumul', label: i18n.cumulate },
            { code: 'addEnd', label: i18n.addEnd },
            { code: 'replace', label: i18n.replacing },
        ].filter(c => !!c)
        return (
            <WhiteCard title={i18n.importName} round>
                <fieldset className='margin-1'>
                    <legend>&nbsp;{i18n.fileToImport}&nbsp;</legend>
                    <Row>
                        <Select
                            col={12}
                            value={jobType}
                            options={this.getPossibleImports()}
                            label={i18n.format}
                            onChange={v => this.setState({ jobType: v, selectedJob: null })}
                            nullLabel='&nbsp;'
                        />
                    </Row>
                    <Row>
                        <fieldset className='margin-1'>
                            <legend>&nbsp;{i18n.useExistingTreatment}&nbsp;</legend>
                            <Row>
                                <Select
                                    col={12}
                                    label={ i18n.job }
                                    options={ this.props.jobs.filter(j => j.jobType === jobType) }
                                    value={ selectedJob?.id }
                                    disabled={ !hasValue(jobType) }
                                    onChange={ (_, v) => this.setState({ selectedJob: v }) } keyValue='id'
                                />
                            </Row>
                            <Row>
                                <Button
                                    col={12}
                                    title={i18n.apply}
                                    disabled={!hasValue(selectedJob)}
                                    onClick={() => {
                                        this.setState({ job: { ...job, ...pick(JSON.parse(selectedJob.parameters), ['parameters', 'filters', 'dataTypes']) } },
                                            () => AppStore.dispatch(ToastrAction.success(i18n.paramsSettingsApplied)),
                                        )
                                    }}
                                    icon='settings'
                                />
                            </Row>
                        </fieldset>
                    </Row>
                    <Row>
                        <div className='col s10 no-margin padding-left-1 padding-right-1 height-24'>
                            <div className='file-field input-field'>
                                <div className='btn'>
                                    <span>{i18n.importLabel}</span>
                                    <input
                                        type='file'
                                        onChange={this.onUploadFile}
                                    />
                                </div>
                                <div className='file-path-wrapper'>
                                    <input className='file-path validate' type='text' />
                                </div>
                            </div>
                        </div>
                        <div className='no-margin padding-bottom-1 padding-top-1 col s2'>
                            <Button
                                tooltip={i18n.parameters}
                                disabled={!(hasValue(this.state.fileContent) && hasValue(this.state.jobType))}
                                onClick={() => this.setState({ open: true })}
                                icon='settings'
                                className='btn-floating z-index-10'
                            />
                        </div>
                    </Row>
                </fieldset>
                <fieldset className={`margin-1 ${piezometerImportedMeasure.length ? '' : 'hide'}`}>
                    <legend>&nbsp;{i18n.importParam}&nbsp;</legend>
                    <Row>
                        <Input
                            col={6}
                            title={i18n.startDate}
                            value={getDate(datesImport.startDateImport)}
                            onChange={v => onChangeDate(v, v2 => changeParent({ startDateImport: v2 }), { max: datesImport.endDateImport }, datesImport.startDateImport)}
                        />
                        <Input
                            col={6}
                            title={i18n.startHour}
                            value={getHour(datesImport.startDateImport)}
                            onChange={v => onChangeHour(v, v2 => changeParent({ startDateImport: v2 }), { max: datesImport.endDateImport }, datesImport.startDateImport)}
                        />
                    </Row>
                    <Row>
                        <Input
                            col={6}
                            title={i18n.endDate}
                            value={getDate(datesImport.endDateImport)}
                            onChange={v => onChangeDate(v, v2 => changeParent({ endDateImport: v2 }), { max: datesImport.startDateImport }, datesImport.endDateImport)}
                        />
                        <Input
                            col={6}
                            title={i18n.endHour}
                            value={getHour(datesImport.endDateImport)}
                            onChange={v => onChangeHour(v, v2 => changeParent({ endDateImport: v2 }), { max: datesImport.startDateImport }, datesImport.endDateImport)}
                        />
                    </Row>
                    <Row>
                        <Select
                            col={12}
                            label={ i18n.dataIntegration }
                            value={ display }
                            options={ displays }
                            onChange={ v => changeParent({ display: v }) }
                            labelSpan={ integrationModeHelpIcon() }
                        />
                    </Row>
                </fieldset>
                <fieldset className={`margin-1 ${piezometerImportedMeasure.length ? '' : 'hide'}`}>
                    <legend>&nbsp;{i18n.qualificationData}&nbsp;</legend>
                    <Row>
                        <Select
                            col={6}
                            label={i18n.status}
                            value={job.import.status}
                            onChange={v => this.setJobImport('import', { status: v })}
                            options={this.props.status}
                            nullLabel='&nbsp;'
                        />
                        <Select
                            col={6}
                            label={i18n.qualification}
                            value={job.import.qualification}
                            onChange={v => this.setJobImport('import', { qualification: v })}
                            options={qualifications}
                            nullLabel='&nbsp;'
                        />
                    </Row>
                    <Row>
                        <Select
                            col={6}
                            label={i18n.nature}
                            value={job.import.nature}
                            onChange={v => this.setJobImport('import', { nature: v })}
                            options={getSandreList(sandreCodes, SANDRE.PIEZOMETER_MEASURE_NATURE, true)}
                            nullLabel='&nbsp;'
                        />
                    </Row>
                    <Row>
                        <RadioButtons col={12} elements={getMeasureCoteList()} selected={job.import.measureCote}
                            onChange={v => this.setJobImport('import', { measureCote: v })}
                            title={i18n.ratingExpression} disabled={ this.props.selectedDataType !== -1 }
                        />
                    </Row>
                    <Row>
                        <Select
                            col={12}
                            label={i18n.producer}
                            value={job.import.producer}
                            onChange={v => this.setJobImport('import', { producer: v })}
                            options={contributors}
                        />
                    </Row>
                    <Row>
                        <Select
                            col={6}
                            label={i18n.administrator}
                            value={job.import.manager}
                            onChange={v => this.setJobImport('import', { manager: v })}
                            options={this.props.contributors}
                        />
                        <Select
                            col={6}
                            label={i18n.validator}
                            value={job.import.validator}
                            onChange={v => this.setJobImport('import', { validator: v })}
                            options={this.props.contributors}
                        />
                    </Row>
                </fieldset>
                <Row className='padding-bottom-1 center-align'>
                    <Button
                        tooltip={i18n.cancel}
                        onClick={this.onCancel}
                        icon='cancel'
                        className='red btn-floating btn-large margin-left-2 margin-right-2 z-index-10 '
                        disabled={!validateActive}
                    />
                    <Button
                        tooltip={i18n.register}
                        onClick={this.onSave}
                        icon='save'
                        disabled={!validateActive}
                        className={`btn-floating btn-large z-index-10 ${validateActive ? 'pulse' : ''}`}
                    />
                </Row>
                {/* <Dialog
                    onRequestClose={() => this.setState({ open: false })}
                    open={open}
                    modal={true}
                    contentStyle={{ width: '70%', maxWidth: 'none' }}
                    actions={this.getActions()}> */}
                <Dialog
                    onClose={() => this.setState({ open: false })}
                    fullWidth
                    maxWidth='lg'
                    open={open}
                >
                    <DialogTitleMUI style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        {i18n.setting}
                        <Icon style={{ color: 'white' }} size='small' icon='close' onClick={() => this.setState({ open: false })} />
                    </DialogTitleMUI>
                    <DialogContentMUI>
                        {this.getDialogContent()}
                        {this.getLogsPanel()}
                    </DialogContentMUI>
                    <DialogActionsMUI>
                        <MuiButton onClick={this.importData} variant='contained' color='primary'>
                            {i18n.importLabel}
                        </MuiButton>
                    </DialogActionsMUI>
                </Dialog>
                <Dialog
                    open={isOpenWarnImport}
                    fullWidth
                    maxWidth='md'
                >
                    <DialogTitleMUI style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        {i18n.warning}
                        <Icon style={{ color: 'white' }} size='small' icon='close' onClick={() => this.setState({ isOpenWarnImport: false })} />
                    </DialogTitleMUI>
                    <DialogContentMUI>
                        <StyledFieldSet>
                            {i18n.adaptChartTabForImport}
                            <div className='padding-left-2'>1. {i18n.adaptChartTabForImport1}</div>
                            <div className='padding-left-2'>2. {i18n.adaptChartTabForImport2}</div>
                        </StyledFieldSet>
                    </DialogContentMUI>
                </Dialog>
            </WhiteCard>
        )
    }
}

ImportToolPanel.propTypes = {
    lastLandmark: PropTypes.number,
    groundRefAlti: PropTypes.number,
    changeParent: PropTypes.func,
    onCancel: PropTypes.func,
    createTechnicalEvent: PropTypes.func,
    executeJobManually: PropTypes.func,
    updatePiezometerRawMeasures: PropTypes.func,
    replacePiezometerRawMeasures: PropTypes.func,
    piezometer: PropTypes.instanceOf(DtoPiezometer),
    dates: PropTypes.shape({
        startDate: PropTypes.number,
        endDate: PropTypes.number,
        minDate: PropTypes.number,
        maxDate: PropTypes.number,
    }),
    datesImport: arrayOf(PropTypes.number),
    qualifications: arrayOf(DtoQualification),
    measures: arrayOf(DtoPiezometryStationMeasure),
    piezometerImportedMeasure: arrayOf(DtoPiezometryStationMeasure),
    status: arrayOf(DtoStatus),
    sandreCodes: arrayOf(DtoSandreCode),
    contributors: arrayOf(DtoContributor),
    display: PropTypes.string,
    reloadData: PropTypes.func,
    applicationSettings: arrayOf(SieauParameterDto),
    selectedDataType: PropTypes.number,
    fetchJobs: PropTypes.func,
    jobs: arrayOf(DtoJob),
    selectedCodePoint: PropTypes.number,
}

const mapStateToProps = store => ({
    piezometer: store.StationReducer.piezometer,
    sandreCodes: store.ReferencialReducer.sandreCodes,
    status: store.QualityReducer.status,
    qualifications: store.QualityReducer.qualifications,
    contributors: store.ContributorReducer.contributors,
    applicationSettings: store.AdministrationReducer.applicationSettings,
    jobs: store.JobReducer.jobs,
})

const mapDispatchToProps = {
    executeJobManually: JobAction.executeJobManually,
    updatePiezometerRawMeasures: PiezometerStationAction.updatePiezometerRawMeasures,
    replacePiezometerRawMeasures: PiezometerStationAction.replacePiezometerRawMeasures,
    fetchJobs: JobAction.fetchJobs,
}

export default connect(mapStateToProps, mapDispatchToProps)(ImportToolPanel)
