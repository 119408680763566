'use strict'
import {
    ADD_JOB_EXECUTIONS, JOB_CACHE,
    RECEIVE_EXPORT_HISTORY,
    RECEIVE_JOB,
    RECEIVE_JOB_EXECUTIONS,
    RECEIVE_JOB_FILES,
    RECEIVE_JOB_LOGS,
    RECEIVE_JOB_ROWS,
    RECEIVE_JOB_TABLE,
    RECEIVE_JOBS_LOGS,
    RECEIVE_QUALITY_EXECUTION,
    RECEIVE_QUALITY_JOB,
    RELOAD_JOB_LOGS,
    RESET_JOB_EXECUTIONS,
    RESET_JOB_ROWS,
    SELECT_FILTER,
    FILE_TO_PROCESS,
} from '../constants/JobConstants'
import DtoJob from '../dto/DtoJob'
import DtoJobExecution from '../dto/DtoJobExecution'
import { uniqWith } from 'lodash'
import DtoJobLog from '../dto/DtoJobLog'
import QualityExecutionDto from '../dto/QualityExecutionDto'
import QesoutHistoryDto from '../dto/QesoutHistoryDto'
import DtoJobFile from '../dto/DtoJobFile'
import DtoJobTable from '../dto/DtoJobTable'
import DtoJobLogLight from 'import/dto/DtoJobLogLight'
import moment from 'moment'

export function JobReducer(state = {}, action) {
    switch (action.type) {
        case SELECT_FILTER:
            return {
                ...state,
                filter: action.filter,
            }
        case RECEIVE_JOB_ROWS:
            return {
                ...state,
                jobs: action.data,
            }
        case RECEIVE_JOB:
            return {
                ...state,
                job: new DtoJob(action.job),
            }
        case RECEIVE_JOB_EXECUTIONS:
            return {
                ...state,
                jobExecutions: action.jobExecutions.map(el => new DtoJobExecution(el)),
            }
        case RECEIVE_JOB_FILES:
            return {
                ...state,
                jobFiles: action.jobFiles.map(el => new DtoJobFile(el)),
            }
        case FILE_TO_PROCESS:
            const {
                jobId,
                executionId,
                fileName,
            } = action.payload
            return {
                ...state,
                jobFiles: state.jobFiles.map(jobFile => {
                    if (jobFile.jobId === jobId && jobFile.jobExecutionId === executionId && jobFile.fileName === fileName) {
                        return new DtoJobFile({ ...jobFile, endDate: !jobFile.endDate ? moment().valueOf() : undefined })
                    }
                    return jobFile
                })
            }
        case ADD_JOB_EXECUTIONS:
            return {
                ...state,
                jobExecutions: uniqWith([...state.jobExecutions, ...action.jobExecutions.map(el => new DtoJobExecution(el))], (a, b) => {
                    return a.id === b.id && a.jobId === b.jobId
                }),
            }
        case RECEIVE_JOB_LOGS:
            return {
                ...state,
                jobLogsCount: action.jobLogs.count,
                jobLogs: action.jobLogs.data.map(el => new DtoJobLogLight({ ...el, jobExecutionId: action.jobExecutionId, jobId: action.jobId })),
                jobLogsStations: action.jobLogs.stations,
            }
        case RECEIVE_JOBS_LOGS:
            return {
                ...state,
                jobsLogs: action.jobsLogs.map(l => new DtoJobLog(l)),
            }
        case RELOAD_JOB_LOGS:
            return {
                ...state,
                jobLogs: [
                    ...state.jobLogs.filter(l => l.jobExecutionId != action.jobExecutionId),
                    ...action.jobsLogs.data.map(jl => new DtoJobLogLight({ ...jl, jobExecutionId: action.jobExecutionId, jobId: action.jobId })),
                ],
            }
        case RECEIVE_QUALITY_JOB:
            return {
                ...state,
                qualityJobs: action.qualityJobs.map(job => new DtoJob(job)),
            }
        case RECEIVE_QUALITY_EXECUTION:
            return {
                ...state,
                qualityExecutions: action.qualityExecutions.map(exec => new QualityExecutionDto(exec)),
            }
        case RECEIVE_EXPORT_HISTORY:
            return {
                ...state,
                qesoutHistory: action.data.map(exec => new QesoutHistoryDto(exec)),
            }
        case RECEIVE_JOB_TABLE:
            return {
                ...state,
                jobTable: {
                    data: action.data.data.map(job => new DtoJobTable(job)),
                    count: action.data.count,
                },
            }
        case RESET_JOB_ROWS:
            return {
                ...state,
                jobs: [],
            }
        case RESET_JOB_EXECUTIONS:
            return {
                ...state,
                jobExecutions: [],
            }
        case JOB_CACHE:
            return { ...state, jobTableCache: action.data }
        default:
            return state
    }
}

export const store = {
    jobs: [],
    jobsLogs: [],
    jobExecutions: [],
    filter: 'QUALITOMETER',
    job: new DtoJob(),
    jobLogs: [],
    qualityJobs: [],
    qualityExecutions: [],
    qesoutHistory: [],
    jobFiles: [],
    jobTable: { data: [], count: 0 },
    jobTableCache: null,
    jobLogsCount: null,
    jobLogsStations: [],
}
