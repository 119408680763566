/* eslint-disable indent */
import { Button, Divider, Grid } from '@mui/material'
import Icon from 'components/icon/Icon'
import { DialogContentMUI, DialogMUI, DialogTitleMUI } from 'components/styled/Dialog'
import PropTypes from 'prop-types'
import React from 'react'
import i18n from 'simple-react-i18n'

const ModalRemovePoint = ({ open, pointStatus, onCancel, onDeclareUnused, onDeclareAdjourn, onDeclareUsed, onRemovePoint }) => (
    <DialogMUI
        maxWidth='md'
        open={open}
        PaperProps={{
            sx: {
                minHeight: 'auto',
                maxHeight: 'auto',
            },
        }}
    >
        <DialogTitleMUI style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            {i18n.changeStatusPoint}
            <Icon style={{ color: 'white' }} size='small' icon='close' onClick={onCancel} />
        </DialogTitleMUI>
        <DialogContentMUI className='padding-bottom-0'>
            <Grid
                container
                direction='column'
                justifyContent='center'
                alignItems='center'
                style={{ height: '100%', padding: '10px' }}
            >
                <Grid item>
                    <Button
                        variant='contained'
                        color='primary'
                        onClick={onDeclareUnused}
                        startIcon={<Icon style={{ marginRight: 8 }}>invert_colors_off</Icon>}
                    >
                        {i18n.declareUnused}
                    </Button>
                </Grid>
                <Grid item>
                    <p style={{ padding: '0 10px', textAlign: 'center' }}>Le point de prélèvement sera déclaré comme non utilisé pour la présente déclaration, mais il reste bien rattaché à votre dossier.<br/>Si des usages ont été saisis, ils seront supprimés.</p>
                </Grid>
                <Divider className='margin-bottom-1' style={{ width: '100%' }} />
                <Grid item>
                    <Button
                        variant='contained'
                        color='primary'
                        onClick={pointStatus === 3 ? onDeclareUsed : onDeclareAdjourn}
                        startIcon={<Icon style={{ marginRight: 8 }}>{pointStatus === 3 ? 'invert_colors' : 'access_time'}</Icon>}
                    >
                        {pointStatus === 3 ? i18n.declareUsed : i18n.declareToAdjourn}
                    </Button>
                </Grid>
                <Grid item>
                    <p style={{ padding: '0 10px', whiteSpace: 'pre-wrap', textAlign: 'center' }}>
                        {pointStatus === 3 ? 'Le point de prélèvement sera déclaré comme utilisé pour la présente déclaration.'
                            : ('Le point de prélèvement restera utilisé pour l\'année en cours, et passera en non utilisé pour l\'année prochaine. Il reste bien rattaché à votre dossier.\nSi des usages prévisionnels ont été saisis, ils seront supprimés.')}
                    </p>                        </Grid>
                <Divider className='margin-bottom-1' style={{ width: '100%' }} />
                <Grid item>
                    <Button
                        variant='contained'
                        color='primary'
                        onClick={pointStatus === 2 ? onDeclareUsed : onRemovePoint}
                        startIcon={<Icon style={{ marginRight: 8 }}>{pointStatus === 2 ? 'invert_colors' : 'delete'}</Icon>}
                    >
                        {pointStatus === 2 ? i18n.declareUsed : i18n.closeSamplingPoint}
                    </Button>
                </Grid>
                <Grid item>
                    <p style={{ padding: '0 10px', textAlign: 'center' }}>
                        {pointStatus === 2 ? 'Le point de prélèvement sera déclaré comme utilisé pour la présente déclaration.'
                            : 'Le point de prélèvement sera clôturé dans votre dossier et ne sera donc plus accessible via votre compte.'}
                    </p>
                </Grid>
            </Grid>
        </DialogContentMUI>
    </DialogMUI>
)

ModalRemovePoint.propTypes = {
    pointStatus: PropTypes.number,
    onDeclareUsed: PropTypes.func,
    onDeclareUnused: PropTypes.func,
    onDeclareAdjourn: PropTypes.func,
    onRemovePoint: PropTypes.func,
    onCancel: PropTypes.func,
    open: PropTypes.bool,
}

export default ModalRemovePoint