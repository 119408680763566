import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import loading from 'assets/pictures/loading.gif'

class Wait extends React.Component {
    constructor(props) {
        super(props)
    }

    render() {
        if (this.props.isWait) {
            return (
                <div className='bg-overlay'>
                    <div className='wait'>
                        <img src={ loading } />
                    </div>
                </div>
            )
        }
        return null
    }
}


Wait.defaultProps = {
    name: 'ball-spin-fade-loader',
    color: 'black',
    fadeIn: 'none',
    isWait: false,
}

Wait.propTypes = {
    name: PropTypes.string,
    color: PropTypes.string,
    fadeIn: PropTypes.string,
    isWait: PropTypes.bool,
}

const mapStateToProps = store => {
    return {
        isWait: store.WaitReducer.isWait,
        name: store.WaitReducer.name,
        color: store.WaitReducer.color,
        fadeIn: store.WaitReducer.fadeIn,
    }
}

export default connect(mapStateToProps)(Wait)
