import React, { useState } from 'react'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import { Button, Card, Chip, Grid } from '@mui/material'
import { getObservationColor } from 'utils/HydroUtils'
import { getDate } from 'utils/DateUtil'
import { onChangeDate } from 'utils/FormUtils'
import Input from 'components/forms/Input'
import { getRGBColor } from 'utils/ColorUtil'
import { styled } from '@mui/styles'
import Select from '../../../components/forms/Select'
import { template } from 'lodash'

const SelectedChip = styled((props) => (
    <Chip
        sx={{
            backgroundColor: `${getRGBColor(getObservationColor(props?.value))} !important`,
            color: 'white',
            letterSpacing: '0.5px',
            padding: '0 2px',
            margin: '0 11px',
            fontWeight: 600,
            cursor: 'pointer',
            transform: 'scale(1.1)',
        }}
        {...props}
    />))({
})

const DEFAULT_OBSERVATION_VALUES = ['1', '1a', '1b', '1f', '2', '3', '4']

const ObservationFilter = ({
    onValidateFilter = () => {},
}) => {
    const valueOptions = [
        { label: i18n.visible, value: '1' },
        { label: i18n.visibleAcceptable, value: '1a' },
        { label: i18n.visibleLow, value: '1f' },
        { label: i18n.visibleNone, value: '2' },
        { label: i18n.assec, value: '3' },
        { label: i18n.impossible, value: '4' },
    ]

    const [startDate, setStartDate] = useState()
    const [endDate, setEndDate] = useState()
    const [observationValues, setValue] = useState(DEFAULT_OBSERVATION_VALUES)
    const [modalitesFilter, setModalitesFilter] = useState(3)

    const resetFilter = () => {
        setStartDate()
        setEndDate()
        setValue([])
        onValidateFilter({})
    }

    const onSetValue = (value) => {
        const change = value === '1f' ? ['1f', '1b'] : [value]
        if (observationValues?.includes(value)) {
            setValue(observationValues.filter(v => !change.includes(v)))
        } else {
            setValue([...observationValues, ...change])
        }
    }

    const tabModalites = [
        { id: 1, label: template(i18n.modalities)({ num: 4 }) },
        { id: 2, label: template(i18n.modalities)({ num: 5 }) },
        { id: 3, label: template(i18n.modalitiesTogether)({ num1: 4, num2: 5 }) },
    ]

    return (
        <Card>
            <Grid>
                <div className='col s12 row no-margin padding-top-10-px valign-wrapper'>
                    <Input
                        col={2}
                        value={getDate(startDate)}
                        title={i18n.startDate}
                        onChange={v => onChangeDate(v, v2 => setStartDate(v2))}
                    />
                    <Input
                        col={2}
                        value={getDate(endDate)}
                        title={i18n.endDate}
                        onChange={v => onChangeDate(v, v2 => setEndDate(v2))}
                    />
                    <Select
                        label={ i18n.modality }
                        options={ tabModalites }
                        value={ modalitesFilter }
                        col={ 3 }
                        onChange={ v => setModalitesFilter(v) }
                        noNullValue
                    />
                    <div className='col s8'>
                        {
                            valueOptions.map(valueOption => {
                                const checked = observationValues?.includes(valueOption.value)
                                if (checked) {
                                    return (
                                        <SelectedChip
                                            label={valueOption.label}
                                            className='white-text'
                                            onClick={ () => onSetValue(valueOption.value)}
                                            value={valueOption.value}
                                        />
                                    )
                                }
                                return (
                                    <Chip
                                        sx={{
                                            backgroundColor: 'white !important',
                                            color: getRGBColor(getObservationColor(valueOption?.value)),
                                            border: `solid 2px ${getRGBColor(getObservationColor(valueOption?.value))}`,
                                            letterSpacing: '0.5px',
                                            fontWeight: 600,
                                            margin: '0 9px',
                                            cursor: 'pointer',
                                            transition: 'all .2s ease-in-out',
                                            '&:hover': {
                                                transform: 'scale(1.15)',
                                            },
                                        }}
                                        label={valueOption.label}
                                        onClick={ () => onSetValue(valueOption.value)}
                                    />
                                )
                            })}
                    </div>
                </div>
                <Grid container className='padding-1' justifyContent='flex-end'>
                    <Grid item>
                        <Button
                            style={{ width: '150px', fontSize: '14px' }}
                            className='btn-flat secondary-button'
                            onClick={ () => resetFilter() }
                            variant='outlined'
                            color='primary'
                        >
                            {i18n.reinit}
                        </Button>
                    </Grid>
                    <Grid item className='padding-left-1'>
                        <Button
                            style={{ width: '150px', fontSize: '14px' }}
                            className='btn-flat primary-button'
                            onClick={ () =>
                                onValidateFilter({
                                    observationValues,
                                    startDate,
                                    endDate,
                                    modalitesFilter,
                                }) } variant='contained' color='primary'
                        >
                            {i18n.search}
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Card>
    )
}

ObservationFilter.propTypes = {
    onValidateFilter: PropTypes.func,
}

export default ObservationFilter