import Bar from '../../../../components/echart/series/Bar'
import { flatten, groupBy, range } from 'lodash'
import moment from 'moment/moment'
import { getMonthList, getYear } from '../../../../utils/DateUtil'
import i18n from 'simple-react-i18n'
import { exportPictureIcon } from '../../../../components/echart/EChartUtils'
import Axis from '../../../../components/echart/Axis'
import EChart from '../../../../components/echart/EChart'
import React, { useEffect, useState } from 'react'
import PluviometryAction from '../../../actions/PluviometryAction'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import ProgressCard from '../../../../components/card/ProgressCard'
import PropTypes from 'prop-types'
import PluviometerDto from '../../../dto/PluviometerDto'
import DtoPluvioMeasure from '../../../dto/measures/DtoPluvioMeasure'
import { Grid } from '@mui/material'
import { DATATYPE_RAIN } from 'utils/PluviometryUtils'

const Graph = ({
    pluviometerInfos,
    data,
}) => {
    const arrayYears = (m) => {
        const firstYear = getYear(m[0][0])
        const lastYear = getYear(moment().valueOf())
        return range(firstYear, lastYear+1)
    }
    const getRainBarMonths = (subData, name, color) => {
        return Bar({
            name,
            stack: 'MS', // MonthsStack
            label: {
                show: false,
            },
            color,
            data: subData,
        })
    }

    const dataYear = (year) => {
        return range(0, 12).map(month =>
            [moment().year(year).month(month).valueOf(), 0],
        )
    }
    const monthsStack = (measures) => {
        const colorTab = ['#148F77', '#1B4F72', '#21618C', '#85C1E9', '#17A1B4', '#82BFC8', '#456469', '#4B4569', '#1C0F5C', '#0F5C4C', '#100F5C', '#8685C4']
        // empty data processes
        const arrayYearTab = arrayYears(measures)
        const tabValueZeroYearData = arrayYearTab.forEach(m => dataYear(m))
        const dataAll = [...measures, ...flatten(tabValueZeroYearData)]
        const dataStack = groupBy(dataAll, m => moment(m[0]).month())
        const series = colorTab.flatMap((color, idx) => {
            return [getRainBarMonths(dataStack[idx].map(v => v[1]), getMonthList()[idx].name, color)]
        })
        return series
    }

    const optionsMonthsStack = {
        title: `[${pluviometerInfos.code}] - ${pluviometerInfos.name} - ${i18n.monthsStacked}`,
        series: monthsStack(data),
        height: 600,
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'shadow',
            },
        },
        toolbox: {
            show: true,
            feature: {
                saveAsImage: { title: i18n.export, icon: exportPictureIcon },
            },
            right: 65,
        },
        legend: {
            data: getMonthList().map(m => m.name),
            bottom: 0,
        },
        grid: {
            top: 60,
            height: 500,
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true,
        },
        yAxis: [Axis({
            type: 'value',
            showSplitLine: true,
            name: `${i18n.rain} [${i18n.milimetersSymbol}]`,
        })],
        xAxis: [Axis({
            type: 'category',
            data: arrayYears(data),
            showSplitLine: true,
        })],
    }

    return (
        <Grid sx={{ paddingTop: '30px' }}>
            <EChart options={ optionsMonthsStack } id='graphMonthsStackPluvioChart' />
        </Grid>
    )
}
Graph.propTypes = {
    pluviometerInfos: PropTypes.shape({
        code: PropTypes.string,
        name: PropTypes.string,
    }),
    data: PropTypes.arrayOf(PropTypes.instanceOf(DtoPluvioMeasure)),
}


const GraphMonthsStackRain = ({
    pluviometer,
}) => {
    const [dataLoaded, setDataLoaded] = useState(false)
    const [progress, setProgress] = useState(0)

    const dispatch = useDispatch()

    useEffect(()=> {
        setDataLoaded(false)
        const rainInput = {
            stationId: pluviometer.id,
            dataType: DATATYPE_RAIN,
            groupFunc: 'SUM_MONTH',
            chartMode: true,
        }

        dispatch(PluviometryAction.loadPluvioChronicMeasures([rainInput], p => {
            setProgress(p)
        })).then(() => {
            setDataLoaded(true)
        })
    }, [dispatch, pluviometer])

    const {
        pluvioMeasures,
    } = useSelector(store => ({
        pluvioMeasures: store.PluviometryReducer.pluvioMeasures,
    }), shallowEqual)

    const data = pluvioMeasures[0]?.measures.map(m => [m.date, m.value])

    return (
        <div className='col s12 row no-margin'>
            { !dataLoaded ? (
                <div style={{ marginTop: '20px' }}>
                    <ProgressCard
                        progress={progress}
                    />
                </div>
            ) : (
                <Graph
                    pluviometerInfos={{
                        code: pluviometer.code,
                        name: pluviometer.name,
                    }}
                    data={data}
                />
            )}
        </div>
    )
}
GraphMonthsStackRain.propTypes = {
    pluviometer: PropTypes.instanceOf(PluviometerDto),
}

export default GraphMonthsStackRain