import ReferencialDto from '../../../dto/ReferencialDto'

export default class SupportDto extends ReferencialDto {
    constructor(object) {
        super(object)
        this.id = object.code
        this.code = object.code
        this.creationDate = object.creationDate
        this.updateDate = object.updateDate
        this.author = object.author
        this.comment = object.comment
        this.internationalName = object.internationalName
        this.internalCode = object.internalCode
        this.actif = object.actif
        this.jobExecutionId= object.jobExecutionId
    }
}
