import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import NumberField from '../../../../components/forms/NumberField'
import Select from '../../../../components/forms/Select'
import DtoAquifer from '../../../../referencial/components/aquifers/dto/DtoAquifer'
import DtoSandreCode from '../../../../referencial/dto/DtoSandreCode'
import { arrayOf } from '../../../../utils/StoreUtils'
import DtoCasing from '../../../dto/DtoCasing'

class CasingModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            casing: { ...props.casing, idStation: props.id },
        }
    }

    componentDidUpdate(prevProps) {
        if (!prevProps.popupIsOpen && this.props.popupIsOpen) {
            $('#descriptionModal').on('click', this.onSave)
        }
    }
    onSave = () => {
        this.props.saveResult(new DtoCasing(this.state.casing))
        $('.modal').modal('close')
    }

    onChangeElement = (changes) => this.setState({ casing: { ...this.state.casing, ...changes } })

    render() {
        const { casing } = this.state
        return (
            <div>
                <div className='row no-margin'>
                    <Select
                        col={ 6 }
                        value={ casing.aquiferCode }
                        label={ i18n.aquifer }
                        onChange={ v => this.onChangeElement({ aquiferCode: v }) }
                        keyValue='code'
                        options={ this.props.aquifers }
                    />
                    <NumberField
                        col={ 6 }
                        value={ casing.order }
                        title={ i18n.order }
                        onChange={ v => this.onChangeElement({ order: v }) }
                        floatValue
                    />
                </div>
                <div className='row no-margin padding-top-1'>
                    <NumberField
                        col={ 6 }
                        value={ casing.startDepth }
                        title={ i18n.startDepth }
                        onChange={ v => this.onChangeElement({ startDepth: v }) }
                        floatValue
                    />
                    <NumberField
                        col={ 6 }
                        value={ casing.endDepth }
                        title={ i18n.endDepth }
                        onChange={ v => this.onChangeElement({ endDepth: v }) }
                        floatValue
                    />
                </div>
                <div className='row no-margin padding-top-1'>
                    <NumberField
                        col={ 6 }
                        value={ casing.aquiferRoof }
                        title={ i18n.aquiferRoof }
                        onChange={ v => this.onChangeElement({ aquiferRoof: v }) }
                        floatValue
                    />
                    <NumberField
                        col={ 6 }
                        value={ casing.aquiferFloor }
                        title={ i18n.aquiferFloor }
                        onChange={ v => this.onChangeElement({ aquiferFloor: v }) }
                        floatValue
                    />
                </div>
            </div>
        )
    }
}

CasingModal.propTypes = {
    casing: PropTypes.instanceOf(DtoCasing),
    id: PropTypes.number,
    sandreCodes: PropTypes.arrayOf(PropTypes.instanceOf(DtoSandreCode)),
    popupIsOpen: PropTypes.bool,
    saveResult: PropTypes.func,
    aquifers: arrayOf(DtoAquifer),
}

const mapStateToProps = store => ({
    aquifers: store.AquiferReducer.aquifers,
    popupIsOpen: store.SieauReducer.popupIsOpen,
})

CasingModal.defaultProps = {
    casing: {},
}

export default connect(mapStateToProps)(CasingModal)
