import { Dialog, Grid } from '@mui/material'
import Icon from 'components/icon/Icon'
import i18n from 'simple-react-i18n'
import { DialogContentMUI, DialogTitleMUI } from 'components/styled/Dialog'
import PropTypes from 'prop-types'
import React from 'react'
import { vigilancesPath } from '../../../conf/SieauConstants'
import DtoVigilance from '../../dto/DtoVigilance'
import VigilanceCard from './VigilanceCard'

const VigilanceModal = ({
    open = false,
    vigilance,
    closePopin = () => {},
}) => (
    <Dialog
        onClose={closePopin}
        fullWidth
        maxWidth='md'
        open={open}
    >
        <DialogTitleMUI>
            <Grid container justifyContent='space-between'>
                <Grid container item xs={10} alignItems='center'>
                    {i18n.vigilances}
                </Grid>
                <Grid container item xs={2} alignItems='center' justifyContent='flex-end'>
                    <Grid item>
                        <Icon size='small' icon='close' onClick={closePopin} />
                    </Grid>
                </Grid>
            </Grid>
        </DialogTitleMUI>
        <DialogContentMUI>
            <Grid item onClick={() => window.open(vigilancesPath, '_blank')}>
                <VigilanceCard vigilance={vigilance} />
            </Grid>
        </DialogContentMUI>
    </Dialog>
)

VigilanceModal.propTypes = {
    open: PropTypes.bool,
    vigilance: PropTypes.instanceOf(DtoVigilance),
    closePopin: PropTypes.func,
}

export default VigilanceModal