export default class DtoEventInstallation {
    constructor(obj) {
        this.id = obj.number
        this.code = obj.code
        this.number = obj.number
        this.eventDate = obj.eventDate
        this.eventType = obj.eventType
        this.comment = obj.comment
        this.eventHour = obj.eventHour
        this.diagnostic = obj.diagnostic
        this.diagnosticDeadline = obj.diagnosticDeadline
        this.contactCode = obj.contactCode
        this.ns = obj.ns
        this.campaignsCode = obj.campaignsCode
        this.updateLogin = obj.updateLogin
        this.updateDate = obj.updateDate
        this.action = obj.action
        this.stationType = 7
        this.stationCode = obj.stationCode
        this.stationId = obj.stationId || obj.code
    }
}