import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import Select from '../../../../../components/forms/Select'
import ContributorDto from '../../../../../referencial/components/contributor/dto/ContributorDto'
import { arrayOf } from '../../../../../utils/StoreUtils'
import DtoKeyPossessor from '../../../../dto/installation/DtoKeyPossessor'

class KeyPossessorModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            keyPossessor: { ...props.keyPossessor, idStation: props.id },
        }
    }

    componentDidUpdate(prevProps) {
        if (!prevProps.popupIsOpen && this.props.popupIsOpen) {
            $('#descriptionModal').on('click', this.onSave)
        }
    }
    onSave = () => {
        this.props.saveResult(new DtoKeyPossessor(this.state.keyPossessor))
        $('.modal').modal('close')
    }

    onChangeElement = (changes) => this.setState({ keyPossessor: { ...this.state.keyPossessor, ...changes } })

    render() {
        const { keyPossessor } = this.state
        return (
            <div>
                <div className='row no-margin padding-top-1'>
                    <Select
                        col={ 6 }
                        value={ keyPossessor.contributor }
                        label={ i18n.contributor }
                        onChange={ v => this.onChangeElement({ contributor: v }) }
                        keyValue='id'
                        options={ this.props.contributors }
                    />
                </div>
            </div>
        )
    }
}

KeyPossessorModal.propTypes = {
    keyPossessor: PropTypes.instanceOf(DtoKeyPossessor),
    id: PropTypes.number,
    popupIsOpen: PropTypes.bool,
    saveResult: PropTypes.func,
    contributors: arrayOf(ContributorDto),
}


const mapStateToProps = store => ({
    popupIsOpen: store.SieauReducer.popupIsOpen,
    contributors: store.ContributorReducer.contributors,
})

KeyPossessorModal.defaultProps = {
    contributor: {},
}

export default connect(mapStateToProps)(KeyPossessorModal)
