import React, { useMemo, useState } from 'react'
import SimpleDatePicker from 'components/forms/SimpleDatePicker'
import ApplicationConf from 'conf/ApplicationConf'
import { push } from '@lagunovsky/redux-react-router'
import HomeAction from 'home/actions/HomeAction'
import QualityAction from 'quality/actions/QualityAction'
import { useEffect } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import ToastrAction from 'toastr/actions/ToastrAction'
import useActions from 'utils/customHook/useActions'
import { hasValue } from 'utils/NumberUtil'
import { v4 as uuidv4 } from 'uuid'
import logoEauFrance from '../../../../assets/pictures/logos/eaufrance.jpg'
import logoSandre from '../../../../assets/pictures/logo_sandre.png'
import Checkbox from '../../../../components/forms/Checkbox'
import Input from '../../../../components/forms/Input'
import NumberField from '../../../../components/forms/NumberField'
import Select from '../../../../components/forms/Select'
import Textarea from '../../../../components/forms/Textarea'
import SieauAction from '../../../../components/sieau/SieauAction'
import { adesWatermassLink } from '../../../../conf/SieauConstants'
import {
    PATH_REFERENCIAL,
    PATH_REFERENCIAL_WATERMASS,
    PATH_REFERENCIAL_WATERMASS_ITEM_LINK,
    PATH_REFERENCIAL_WATERMASS_ITEM_NEW,
} from '../../../../home/constants/RouteConstants'
import { getUser } from '../../../../utils/SettingUtils'
import ReferencialAction from '../../../action/ReferencialAction'
import { getReferencialComponent, getWatermassLink } from '../../../util/ReferencialUtils'
import DeleteModal from '../../DeleteModal'
import ReplaceModal from '../../ReplaceModal'
import WatermassAction from '../actions/WatermassAction'
import WatermassDto from '../dto/WatermassDto'
import useTitle from 'utils/customHook/useTitle'
import { useParams } from 'react-router'

const WatermassApp = () => {
    const dispatch = useDispatch()
    const { code } = useParams()
    const {
        watermassProps,
        watermasses,
        stationsWithWatermass,
        qualitometers,
    } = useSelector(store => ({
        watermassProps: store.WatermassReducer.watermass,
        watermasses: store.WatermassReducer.watermasses,
        stationsWithWatermass: store.WatermassReducer.stationsWithWatermass,
        qualitometers: store.QualityReducer.qualitometers,
    }), shallowEqual)

    const [watermass, setWatermass] = useState(new WatermassDto({}))
    const [isEditMode, setIsEditMode] = useState(false)

    useEffect(() => () => dispatch(WatermassAction.reset()), [])

    useEffect(() => setWatermass(watermassProps), [watermassProps])

    useEffect(() => {
        if (code !== 'new') {
            dispatch(WatermassAction.fetchWatermass(code))
            if (!qualitometers.length) {
                dispatch(QualityAction.fetchQualitometersLight())
            }
            if (!stationsWithWatermass.length) {
                dispatch(WatermassAction.fetchStationsWithWatermass(code))
            }
            setIsEditMode(false)
        } else {
            setIsEditMode(true)
        }
        dispatch(HomeAction.setHelpLink('', ''))
    }, [code])

    const getWatermassLinks = () => {
        return [
            !!watermassProps.europeanCode && {
                href: `${adesWatermassLink + watermassProps.europeanCode}.pdf`,
                img: logoEauFrance,
                label: i18n.eaufrance.toUpperCase(),
            },
            !!(watermassProps.code && watermassProps.watermassType) && {
                href: getWatermassLink(watermassProps.code, watermassProps.watermassType),
                img: logoSandre,
                label: i18n.sandre.toUpperCase(),
            },
        ].filter(l => l)
    }

    const isConsultant = useMemo(() => getUser().consultant === '1', [])

    useActions(() => {
        if (isConsultant) {
            return {}
        }
        if (code === 'new' && (!watermassProps || !watermassProps.code)) {
            return {
                save: () => {
                    const existCode = watermasses.find(p => p.code === watermass.code)
                    if (existCode) {
                        dispatch(ToastrAction.error(i18n.codeAlreadyExists))
                    } else if (!watermass.code) {
                        dispatch(ToastrAction.error(i18n.thanksToEnterCode))
                    } else {
                        dispatch(WatermassAction.createWatermass(watermass))
                        setIsEditMode(false)
                    }
                },
                cancel: () => {
                    dispatch(push(PATH_REFERENCIAL_WATERMASS))
                    setIsEditMode(false)
                },
            }
        }
        if (isEditMode) {
            return {
                save: () => {
                    dispatch(WatermassAction.updateWatermass(watermass, watermass.code))
                    setIsEditMode(false)
                },
                cancel: () => {
                    setWatermass(watermassProps)
                    setIsEditMode(false)
                },
            }
        }
        return {
            edit: () => {
                setIsEditMode(true)
            },
            deleteCheck: () => {
                dispatch(ReferencialAction.fetchCheckDelete(ApplicationConf.referencial.watermassCountDelete(code)))
                const id = uuidv4()
                const popup = {
                    id,
                    content: <DeleteModal
                        onDelete={ () => dispatch(WatermassAction.deleteWatermass(watermass.code)) }
                    />,
                    dismissible: false,
                }
                dispatch(SieauAction.setPopup(popup))
            },
            replace: () => {
                dispatch(ReferencialAction.fetchCheckDelete(ApplicationConf.referencial.watermassCountDelete(code)))
                const id = uuidv4()
                const popup = {
                    id,
                    content: <ReplaceModal
                        elements={ watermasses }
                        title={`${i18n.watermass} [${watermass.code}]`}
                        label={i18n.watermass}
                        onReplace={ newCode => dispatch(WatermassAction.replaceWatermass(watermass.code, newCode.code)) }
                    />,
                    dismissible: false,
                }
                dispatch(SieauAction.setPopup(popup))
            },
            links: getWatermassLinks(),
        }
    }, [isConsultant, code, watermassProps, watermass, isEditMode])

    useTitle(() => {
        if (code === 'new') {
            return [
                {
                    title: i18n.referencials,
                    href: PATH_REFERENCIAL,
                },
                {
                    title: i18n.watermasses,
                    href: PATH_REFERENCIAL_WATERMASS,
                },
                {
                    title: i18n.new,
                    href: PATH_REFERENCIAL_WATERMASS_ITEM_NEW,
                },
            ]
        }
        return [
            {
                title: i18n.referencials,
                href: PATH_REFERENCIAL,
            },
            {
                title: i18n.watermasses,
                href: PATH_REFERENCIAL_WATERMASS,
            },
            {
                title: code + (watermassProps.label ? ` - ${watermassProps.label}` : ''),
                href: PATH_REFERENCIAL_WATERMASS_ITEM_LINK + code,
            },
        ]
    }, [code, watermassProps])

    const disabled = useMemo(() => ({
        active: isEditMode,
        disabled: !isEditMode,
    }), [isEditMode])

    const typeOptions = useMemo(() => [
        { id: '0', name: i18n.undergroundWaterPoint },
        { id: '1', name: i18n.waterFlow },
        { id: '2', name: i18n.waterPlan },
        { id: '3', name: i18n.hugeWaterFlow },
        { id: '4', name: i18n.marineEnvironnment },
        { id: '5', name: i18n.otherWaterFlowAndRavins },
        { id: '6', name: i18n.source },
        { id: '7', name: i18n.distributedWater },
        { id: '8', name: i18n.treatedWater },
    ], [])

    const typeSouterrainOptions = useMemo(() => [
        { id: '0', name: i18n.alluvionnaire },
        { id: '1', name: i18n.sedimentaryField },
        { id: '2', name: i18n.volcanicStructure },
        { id: '3', name: i18n.aquiferLocalSystem },
        { id: '4', name: i18n.hardlyPleated },
        { id: '5', name: i18n.socle },
    ], [])

    const watermassTypes = useMemo(() => getReferencialComponent(typeOptions), [typeOptions])
    const undergroundTypes = useMemo(() => getReferencialComponent(typeSouterrainOptions), [typeSouterrainOptions])

    return (
        <div className='col s12'>
            <div className='card padding-1'>
                <div className='row no-margin'>
                    <div className='row no-margin padding-top-5-px'>
                        <Input
                            col={ 3 }
                            title={ i18n.sandreCode }
                            value={ watermass.code }
                            onChange={ newCode => setWatermass({ ...watermass, code: newCode }) }
                            maxLength={ 22 }
                            disabled={code !== 'new' }
                            data-cy='code'
                            obligatory
                        />
                        <Input
                            col={ 3 }
                            title={ i18n.euroCode }
                            value={ watermass.europeanCode }
                            onChange={ europeanCode => setWatermass({ ...watermass, europeanCode }) }
                            maxLength={ 24 }
                            data-cy='europeanCode'
                            { ...disabled }
                        />
                    </div>
                </div>
                <div className='row no-margin padding-top-5-px'>
                    <Input
                        col={ 12 }
                        title={ i18n.name }
                        value={ watermass.label }
                        onChange={ label => setWatermass({ ...watermass, label }) }
                        maxLength={ 255 }
                        data-cy='label'
                        { ...disabled }
                    />
                </div>
                <div className='row no-margin'>
                    <div className='col s12 padding-top-5-px'>
                        <Checkbox
                            col={ 4 }
                            label={ i18n.freeFlowCaptive }
                            checked={ watermass.freeFlowCaptive === '1' }
                            onChange={ (e) => setWatermass({ ...watermass, freeFlowCaptive: e ? '1' : '0' }) }
                            { ...disabled }
                        />
                        <Checkbox
                            col={ 4 }
                            label={ i18n.freeFlow }
                            checked={ watermass.freeFlow === '1' }
                            onChange={ (e) => setWatermass({ ...watermass, freeFlow: e ? '1' : '0' }) }
                            { ...disabled }
                        />
                        <Checkbox
                            col={ 4 }
                            label={ i18n.captiveFlow }
                            checked={ watermass.captiveFlow === '1' }
                            onChange={ (e) => setWatermass({ ...watermass, captiveFlow: e ? '1' : '0' }) }
                            { ...disabled }
                        />
                        <Checkbox
                            col={ 4 }
                            label={ i18n.freeMajorityFlow }
                            checked={ watermass.freeMajorityFlow === '1' }
                            onChange={ (e) => setWatermass({ ...watermass, freeMajorityFlow: e ? '1' : '0' }) }
                            { ...disabled }
                        />
                        <Checkbox
                            col={ 4 }
                            label={ i18n.captiveMajorityFlow }
                            checked={ watermass.captiveMajorityFlow === '1' }
                            onChange={ (e) => setWatermass({ ...watermass, captiveMajorityFlow: e ? '1' : '0' }) }
                            data-cy='captiveMajorityFlow'
                            { ...disabled }
                        />
                        <Checkbox
                            col={ 4 }
                            label={ i18n.multiBasin }
                            checked={ watermass.multiBasin === '1' }
                            onChange={ (e) => setWatermass({ ...watermass, multiBasin: e ? '1' : '0' }) }
                            { ...disabled }
                        />
                        <Checkbox
                            col={ 4 }
                            label={ i18n.multiCountry }
                            checked={ watermass.multiCountry === '1' }
                            onChange={ (e) => setWatermass({ ...watermass, multiCountry: e ? '1' : '0' }) }
                            { ...disabled }
                        />
                        <Checkbox
                            col={ 4 }
                            label={ i18n.coastalFringe }
                            checked={ watermass.coastalFringe === '1' }
                            onChange={ (e) => setWatermass({ ...watermass, coastalFringe: e ? '1' : '0' }) }
                            { ...disabled }
                        />
                        <Checkbox
                            col={ 4 }
                            label={ i18n.karst }
                            checked={ watermass.karst === '1' }
                            onChange={ (e) => setWatermass({ ...watermass, karst: e ? '1' : '0' }) }
                            { ...disabled }
                        />
                        <Checkbox
                            col={ 4 }
                            label={ i18n.grouping }
                            checked={ watermass.grouping === '1' }
                            onChange={ (e) => setWatermass({ ...watermass, grouping: e ? '1' : '0' }) }
                            data-cy='grouping'
                            { ...disabled }
                        />
                    </div>
                </div>
                <div className='row no-margin padding-top-5-px'>
                    <Select
                        options={ watermassTypes }
                        label={ i18n.type }
                        col={ 6 }
                        onChange={ v => setWatermass({ ...watermass, watermassType: parseFloat(v) }) }
                        value={ hasValue(watermass.watermassType) ? watermass.watermassType.toString() : null }
                        nullLabel='&nbsp;'
                        data-cy='watermassType'
                        { ...disabled }
                    />
                    <Select
                        options={ undergroundTypes }
                        label={ i18n.undergroundType }
                        col={ 6 }
                        onChange={ v => setWatermass({ ...watermass, undergroundType: v }) }
                        value={ watermass.undergroundType }
                        nullLabel='&nbsp;'
                        data-cy='undergroundType'
                        { ...disabled }
                    />
                </div>
                <div className='row no-margin padding-top-5-px'>
                    <SimpleDatePicker
                        col={ 4 }
                        onChange={ insertionDate => setWatermass({ ...watermass, insertionDate }) }
                        id='insertionDate'
                        label={ i18n.insertionDate }
                        value={ watermass.insertionDate }
                        { ...disabled }
                    />
                    <SimpleDatePicker
                        col={ 4 }
                        onChange={ reportingYear => setWatermass({ ...watermass, reportingYear }) }
                        id='reportingYear'
                        label={ i18n.reportingYear }
                        value={ watermass.reportingYear }
                        { ...disabled }
                    />
                    <Input
                        col={ 4 }
                        title={ i18n.acronym }
                        value={ watermass.acronym }
                        onChange={ acronym => setWatermass({ ...watermass, acronym }) }
                        maxLength={ 15 }
                        { ...disabled }
                    />
                </div>
                <div className='row no-margin padding-top-5-px'>
                    <NumberField
                        col={ 4 }
                        title={ i18n.surface }
                        value={ watermass.surface }
                        onChange={ surface => setWatermass({ ...watermass, surface }) }
                        { ...disabled }
                        data-cy='surface'
                        floatValue
                    />
                    <NumberField
                        col={ 4 }
                        title={ i18n.surfaceUnderCover }
                        value={ watermass.surfaceUnderCover }
                        onChange={ surfaceUnderCover => setWatermass({ ...watermass, surfaceUnderCover }) }
                        { ...disabled }
                        data-cy='surfaceUnderCover'
                        floatValue
                    />
                    <Input
                        col={ 4 }
                        title={ i18n.precision }
                        value={ watermass.precision }
                        onChange={ precision => setWatermass({ ...watermass, precision }) }
                        maxLength={ 1 }
                        { ...disabled }
                    />
                </div>
                <div className='row no-margin padding-top-5-px'>
                    <Textarea
                        col={ 12 }
                        title={ i18n.comment }
                        value={ watermass.comment }
                        onChange={ comment => setWatermass({ ...watermass, comment }) }
                        maxLength={ 250 }
                        data-cy='comment'
                        { ...disabled }
                    />
                </div>
            </div>
        </div>
    )
}

export default WatermassApp
