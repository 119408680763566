import { Button, Dialog } from '@mui/material'
import Icon from 'components/icon/Icon'
import { DialogActionsMUI, DialogContentMUI, DialogTitleMUI } from 'components/styled/Dialog'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import DtoContact from '../../../quality/components/operation/dto/DtoContact'
import CityDto from '../../../referencial/components/city/dto/CityDto'
import ContactLightPanel from '../panels/ContactLightPanel'

class ContactModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            contact: props.contact,
        }
    }

    onChangeValue = (obj) => {
        this.setState(({ contact }) => ({
            contact: {
                ...contact,
                ...obj,
            },
        }))
    }

    onCancel = () => {
        this.setState({ contact: null })
        this.props.onCancel()
    }

    onValidate = () => {
        const { contact } = this.state
        this.props.onValidate(contact)
    }

    render = () => {
        const { open, title } = this.props
        const { contact } = this.state

        return (
            <Dialog
                onClose={() => this.setState({ open: false })}
                fullWidth
                maxWidth='md'
                open={open}
            >
                <DialogTitleMUI style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    {title || i18n.updateContributor}
                    <Icon style={{ color: 'white' }} size='small' icon='close' onClick={this.onCancel} />
                </DialogTitleMUI>
                <DialogContentMUI>
                    <ContactLightPanel
                        contact={contact}
                        onChange={this.onChangeValue}
                    />
                </DialogContentMUI>
                <DialogActionsMUI>
                    <Button onClick={this.onValidate} variant='contained' color='primary'>
                        {i18n.validate}
                    </Button>
                </DialogActionsMUI>
            </Dialog>
        )
    }
}

ContactModal.propTypes = {
    open: PropTypes.bool,
    title: PropTypes.string,
    contact: PropTypes.instanceOf(DtoContact),
    onCancel: PropTypes.func.isRequired,
    onValidate: PropTypes.func.isRequired,
    cities: PropTypes.arrayOf(PropTypes.instanceOf(CityDto)),
}

const mapStateToProps = store => ({
    cities: store.CityReducer.cities,
})

export default connect(mapStateToProps)(ContactModal)