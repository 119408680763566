import {
    Button,
    DialogContent,
    Grid,
} from '@mui/material'
import { withStyles } from '@mui/styles'
import AgriAction from 'agriAdministration/actions/AgriAction'
import { NUMBER } from 'components/keyFigures/KeyFigureConstants'
import KeyFigurePanel from 'components/keyFigures/KeyFigurePanel'
import { DialogActionsMUI, DialogMUI, DialogTitleMUI } from 'components/styled/Dialog'
import { push } from '@lagunovsky/redux-react-router'
import ExportAction from 'export/actions/ExportAction'
import PropTypes from 'prop-types'
import React from 'react'
import { useDispatch } from 'react-redux'
import i18n from 'simple-react-i18n'
import DtoSurveyForecast from 'survey/dto/DtoSurveyForecast'
import { formatData } from 'utils/ExportDataUtil'
import { arrayOf } from 'utils/StoreUtils'
import Icon from '../../../components/icon/Icon'
import { PATH_CONTENTS, PATH_REFERENCIAL_UNIT_MANAGEMENT_LINK } from '../../../home/constants/RouteConstants'
import ManagementUnitDetailTabs from './ManagementUnitDetailTabs'

const headers = ['survey', 'structureType', 'username', 'siret', 'codification', 'pointPrelevement', 'sampleType', 'sampleNature',
    'municipalityCode', 'municipalityName', 'cadastralParcel', 'section', 'parcel', 'idUge', 'nameUge', 'idUnderUge', 'nameUnderUge', 'usage',
    'familyCulture', 'culture', 'groundType', 'groundDepth', 'numberRotation', 'surface', 'irrigationMethod', 'sollication', 'annualDemandVolumeExport',
    'comment', 'x', 'y', 'projection', 'lowWater', 'notLowWater', 'january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september',
    'october', 'november', 'december']

const ManagementUnitModal = ({
    onClose = () => {},
    data = {},
    year,
    open = false,
    filter = {},
}) => {
    const dispatch = useDispatch()

    const onCloseModal = () => {
        onClose()
    }

    const getColor = (type) => {
        switch (type) {
            case 'POINT_GRAY':
                return 'lightgrey'
            case 'POINT_GREEN':
                return 'green'
            case 'POINT_ORANGE':
                return 'orange'
            case 'POINT_RED':
                return 'red'
            default:
                return 'lightgrey'
        }
    }

    const getKeyNumbers = (volumesData = {}) => {
        return [{
            title: i18n.pointsPrelevement,
            tooltip: i18n.pointsPrelevement,
            value: volumesData.pointsVolumes ? volumesData.pointsVolumes.length : 0,
            typeValue: NUMBER,
        }, {
            title: i18n.usages,
            tooltip: i18n.usages,
            value: volumesData.usagesVolumes ? volumesData.usagesVolumes.length : 0,
            typeValue: NUMBER,
        }, {
            title: i18n.samplers,
            tooltip: i18n.samplers,
            value: volumesData.samplersVolumes ? volumesData.samplersVolumes.length : 0,
            typeValue: NUMBER,
        }, {
            title: i18n.cities,
            tooltip: i18n.cities,
            value: volumesData.citiesVolumes ? volumesData.citiesVolumes.length : 0,
            typeValue: NUMBER,
        }]
    }

    const onExportUsagePrevi = () => {
        dispatch(AgriAction.fetchForecastsUge(data.managementCode, year)).then((forecastsUge) => {
            const dataFormatted = formatData(forecastsUge.map(fs => {
                const splitedVolume = fs?.volumeDistribution.split(';') || []
                return {
                    ...fs,
                    siret: { value: fs.siret, cellType: 'string' },
                    survey: { value: fs.survey },
                    codification: { value: fs.codification },
                    pointPrelevement: { value: fs.pointPrelevement },
                    usage: { value: fs.usage },
                    culture: { value: fs.culture },
                    surface: { value: fs.surface, cellType: 'number', format: '#,##0.0' },
                    annualDemandVolumeExport: { value: fs.annualDemandVolumeExport, cellType: 'number', format: '#,##0.0' },
                    irrigationMethod: { value: fs.irrigationMethod },
                    comment: { value: fs.comment },
                    projection: { value: fs.projection },
                    x: { value: fs.x, cellType: 'number' },
                    y: { value: fs.y, cellType: 'number' },
                    numberRotation: { value: fs.numberRotation, cellType: 'number', format: '#,##0.0' },
                    lowWater: { value: splitedVolume[0], cellType: 'number', format: '#,##0.0' },
                    notLowWater: { value: splitedVolume[1], cellType: 'number', format: '#,##0.0' },
                    january: { value: splitedVolume[2], cellType: 'number', format: '#,##0.0' },
                    february: { value: splitedVolume[3], cellType: 'number', format: '#,##0.0' },
                    march: { value: splitedVolume[4], cellType: 'number', format: '#,##0.0' },
                    april: { value: splitedVolume[5], cellType: 'number', format: '#,##0.0' },
                    may: { value: splitedVolume[6], cellType: 'number', format: '#,##0.0' },
                    june: { value: splitedVolume[7], cellType: 'number', format: '#,##0.0' },
                    july: { value: splitedVolume[8], cellType: 'number', format: '#,##0.0' },
                    august: { value: splitedVolume[9], cellType: 'number', format: '#,##0.0' },
                    september: { value: splitedVolume[10], cellType: 'number', format: '#,##0.0' },
                    october: { value: splitedVolume[11], cellType: 'number', format: '#,##0.0' },
                    november: { value: splitedVolume[12], cellType: 'number', format: '#,##0.0' },
                    december: { value: splitedVolume[13], cellType: 'number', format: '#,##0.0' },
                    headers,
                }
            }))
            dispatch(ExportAction.export(dataFormatted, 'xlsx', `${i18n.forecastUgeExport}_${data.labelWithCode}`))
        })
    }

    const onCommunicate = () => {
        onCloseModal()
        dispatch(push(`${PATH_CONTENTS}/0/0`))
    }

    const onConsultUGE = () => {
        onCloseModal()
        dispatch(push(`${PATH_REFERENCIAL_UNIT_MANAGEMENT_LINK}${data.managementCode}`))
    }

    const keyNumbers = getKeyNumbers(data.results)
    const title = (
        <Grid container justifyContent='space-between'>
            <Grid item xs={6} container justifyContent='flex-start' alignItems='center'>
                {data.labelWithCode}
                <Icon className='right' onClick={onConsultUGE}>launch</Icon>
            </Grid>
            <Grid item>
                <KeyFigurePanel
                    data={[keyNumbers[0], keyNumbers[1], keyNumbers[2], keyNumbers[3]]}
                    showAllValues
                    noMargin
                />
            </Grid>
        </Grid>
    )
    const DialogTitle = withStyles(theme => ({
        root: {
            background: `linear-gradient(
                    to right,
                    ${getColor(data.layer)},
                    ${getColor(data.layer)} 20px,
                    ${theme.palette.primary.grey} 20px,
                    ${theme.palette.primary.grey} 100%
                )`,
        },
    }))(DialogTitleMUI)

    return (
        <DialogMUI
            onClose={onCloseModal}
            open={open}
            PaperProps={{
                sx: {
                    minHeight: '95vh',
                    maxHeight: '95vh',
                    minWidth: '95vw',
                    maxWidth: '95vw',
                },
            }}
        >
            <DialogTitle>{title}</DialogTitle>
            <DialogContent className='padding-bottom-0'>
                <ManagementUnitDetailTabs data={data} filter={filter} />
            </DialogContent>
            <DialogActionsMUI style={{ justifyContent: 'space-between' }}>
                <Button onClick={onCloseModal} variant='outlined'>
                    {i18n.close}
                </Button>
                <Button onClick={onExportUsagePrevi} variant='outlined' >
                    {i18n.exportUsagePrevi}
                </Button>
                <Button onClick={onCommunicate} variant='contained' color='primary'>
                    {i18n.communicate}
                </Button>
            </DialogActionsMUI>
        </DialogMUI>
    )
}

ManagementUnitModal.propTypes = {
    open: PropTypes.bool,
    title: PropTypes.string,
    newVolumes: PropTypes.bool,
    onClose: PropTypes.func,
    export: PropTypes.func,
    fetchForecastsUge: PropTypes.func,
    push: PropTypes.func,
    data: PropTypes.shape({
        labelWithCode: PropTypes.string,
        results: PropTypes.shape({}),
        AUP: PropTypes.shape({
            value: PropTypes.string,
        }),
        typeName: PropTypes.string,
        managementCode: PropTypes.string,
        consumedVolumes: PropTypes.string,
    }),
    filter: PropTypes.shape({
        volumesShow: PropTypes.string,
        dateShow: PropTypes.number,
        dateShowEnd: PropTypes.number,
        showWatersheds: PropTypes.bool,
        showManagementUnits: PropTypes.bool,
        showCities: PropTypes.bool,
        showPtsPrel: PropTypes.bool,
        takeWaterTanks: PropTypes.bool,
        pointsType: PropTypes.number,
    }),
    year: PropTypes.number,
    forecastsUge: arrayOf(DtoSurveyForecast),
}

export default ManagementUnitModal