module.exports = {
    H24: 'H24',
    H48: 'H48',
    ONE_WEEK: 'ONE_WEEK',
    ONE_MONTH: 'ONE_MONTH',
    SIX_MONTHS: 'SIX_MONTHS',
    ONE_YEAR: 'ONE_YEAR',
    CUSTOM: 'CUSTOM',
    HISTORY: 'HISTORY',
    OTHER: 'OTHER',
    CIVIL_YEAR: 'CIVIL_YEAR',
    HYDRO_YEAR: 'HYDRO_YEAR',

    H_24: 1,
    H_48: 2,
    J7: 7,
    J30: 30,
    J90: 90,
    J365: 365,
    HISTO: 'HISTO',
}