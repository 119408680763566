import Layers from 'utils/mapUtils/ILayers'
import Tile from 'ol/layer/Tile'
import * as olSource from 'ol/source'

class _WHITE extends Layers {
    constructor() {
        super()
        this.layer = this.createLayer()
    }


    createLayer() {
        return new Tile({
            source: new olSource.OSM(),
            visible: false,
        })
    }
    getLayer() {
        return this.layer
    }
}

export default function WHITE(...args) {
    return new _WHITE(...args)
}
