import React from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { getYear } from 'utils/DateUtil'
import { log } from 'mathjs'
import { maxBy } from 'lodash'
import ReactECharts from 'echarts-for-react'
import echarts from 'echarts/lib/echarts'
import { getNumberFormat } from 'utils/NumberUtil'
import { formatMilliers } from 'utils/StringUtil'

const UnitLinkedVolumes = ({
}) => {
    const {
        linkedPiezosPrel,
    } = useSelector(store => ({
        linkedPiezosPrel: store.ProductionUnitReducer.linkedPiezosPrel,
    }), shallowEqual)

    const option = {
        series: [{
            type: 'bar',
            data: linkedPiezosPrel,
            itemStyle: {
                normal: {
                    color: 'blue',
                },
            },
            tooltip: {
                trigger: 'item',
                formatter: (d) => `${getYear(d.data.date)} : ${formatMilliers(d.value)} m3`,
            },
            barWidth: '60%',
        }],
        xAxis: [{
            type: 'category',
            position: 'bottom',
            data: linkedPiezosPrel.map(d => getYear(d.date)),
            gridIndex: 0,
            axisLabel: {
                show: true,
            },
            axisLine: { show: true },
            axisTick: { show: true },
            showSplitLine: true,
        }],
        yAxis: [{
            type: 'value',
            nameLocation: 'middle',
            name: 'm3',
            gridIndex: 0,
            showSplitLine: true,
            nameGap: (log(maxBy(linkedPiezosPrel, 'value')?.value || 0, 10) * 10) - 1,
            min: 0,
            axisLabel: {
                formatter: getNumberFormat,
            },
        }],
        tooltip: {
            trigger: 'item',
        },
        height: 110,
        grid: {
            top: '25%',
            left: '5%',
            right: '5%',
            containLabel: true,
        },
    }

    return (
        <ReactECharts
            echarts={echarts}
            option={option}
            notMerge={true}
            lazyUpdate={true}
            style={{ height: '150px' }}
        />
    )
}

UnitLinkedVolumes.propTypes = {
}

export default UnitLinkedVolumes