import React from 'react'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import Row from '../../../components/react/Row'
import Select from '../../../components/forms/Select'
import Checkbox from '../../../components/forms/Checkbox'
import Input from '../../../components/forms/Input'
import { getDate } from '../../../utils/DateUtil'
import DtoIndustrialSite from './dto/DtoIndustrialSite'
import { getSandreList } from '../../../utils/StoreUtils'
import { SANDRE } from '../../../referencial/constants/ReferencialConstants'
import { onChangeDate } from '../../../utils/FormUtils'
import NumberField from '../../../components/forms/NumberField'
import { shallowEqual, useSelector } from 'react-redux'
import { WhiteCard } from 'components/styled/Card'

const IndustrialSiteActivityPanel = ({
    industrialSite,
    readMode,
    onChangeIndustrialSite,
}) => {
    const {
        sandreCodes,
        activities,
    } = useSelector(store => ({
        sandreCodes: store.ReferencialReducer.sandreCodes,
        activities: store.InstallationReducer.activities,
    }), shallowEqual)

    return (
        <WhiteCard title={i18n.industrialSite}>
            <div className='row no-margin padding-top-1 padding-bottom-1'>
                <div className='col s12'>
                    <Row>
                        <Select
                            col={6}
                            label={i18n.activityStatus}
                            options={getSandreList(sandreCodes, SANDRE.INDUSTRIAL_SITE_STATUS)}
                            value={industrialSite.activityStatus ? industrialSite.activityStatus : 0}
                            onChange={(element) => onChangeIndustrialSite({ activityStatus: element })}
                            {...readMode}
                        />
                        <Checkbox
                            col={6}
                            label={i18n.icpe}
                            checked={industrialSite.icpe}
                            onChange={(element) => onChangeIndustrialSite({ icpe: element })}
                            disabled={!readMode.editMode}
                        />
                    </Row>
                    <Row>
                        <Select
                            col={6}
                            label={i18n.effectiveTranche}
                            options={getSandreList(sandreCodes, SANDRE.SITE_INDUS_EFFECTIF)}
                            value={industrialSite.effectiveTranche ? industrialSite.effectiveTranche : -1}
                            onChange={(element) => onChangeIndustrialSite({ effectiveTranche: element })}
                            {...readMode}
                        />
                        <div className='col s6'>
                            {!readMode.editMode ? (
                                <div className='row no-margin'>
                                    <h6>
                                        <span className='bold'>{i18n.effective} : </span>
                                        {industrialSite.staff}
                                    </h6>
                                </div>
                            ) : (
                                <NumberField
                                    col={6}
                                    title={i18n.effective}
                                    value={industrialSite.staff}
                                    onChange={(element) => onChangeIndustrialSite({ staff: element })}
                                />
                            )}
                        </div>
                    </Row>
                    <Row>
                        <Select
                            col={12}
                            label={i18n.categoryActivity}
                            options={getSandreList(sandreCodes, SANDRE.ACTIVITES_CATEGORIE)}
                            value={industrialSite.categoryActivity ? industrialSite.categoryActivity : 0}
                            onChange={(element) => onChangeIndustrialSite({ categoryActivity: element })}
                            {...readMode}
                        />
                    </Row>
                    <Row>
                        <Select
                            col={12}
                            label={i18n.mainActivity}
                            options={activities}
                            value={industrialSite.activityCode}
                            onChange={(element) => onChangeIndustrialSite({ activityCode: element })}
                            displayWithCode
                            {...readMode}
                        />
                    </Row>
                    <Row>
                        <Select
                            col={12}
                            label={i18n.activity2}
                            options={activities}
                            value={industrialSite.activityCode2}
                            onChange={(element) => onChangeIndustrialSite({ activityCode2: element })}
                            displayWithCode
                            {...readMode}
                        />
                    </Row>
                    <Row>
                        <Select
                            col={12}
                            label={i18n.activity3}
                            options={activities}
                            value={industrialSite.activityCode3}
                            onChange={(element) => onChangeIndustrialSite({ activityCode3: element })}
                            displayWithCode
                            {...readMode}
                        />
                    </Row>
                    <Row>
                        <Select
                            col={12}
                            label={i18n.activity4}
                            options={activities}
                            value={industrialSite.activityCode4}
                            onChange={(element) => onChangeIndustrialSite({ activityCode4: element })}
                            displayWithCode
                            {...readMode}
                        />
                    </Row>
                    <Row>
                        <Checkbox
                            col={6}
                            label={i18n.redevelopedSite}
                            checked={industrialSite.redevelopedSite}
                            onChange={(element) => onChangeIndustrialSite({ redevelopedSite: element })}
                            disabled={!readMode.editMode}
                        />
                        <Checkbox
                            col={6}
                            label={i18n.refurbishedSite}
                            checked={industrialSite.refurbishedSite}
                            onChange={(element) => onChangeIndustrialSite({ refurbishedSite: element })}
                            disabled={!readMode.editMode}
                        />
                    </Row>
                    <Row>
                        <Checkbox
                            col={12}
                            label={i18n.dischargeAgreement}
                            checked={industrialSite.dischargeAgreement}
                            onChange={(element) => onChangeIndustrialSite({ dischargeAgreement: element })}
                            disabled={!readMode.editMode}
                        />
                    </Row>
                    <Row className='margin-top-1 no-margin-bottom' noMargin={ false }>
                        <div className='col s6'>
                            {!readMode.editMode ? (
                                <div className='row no-margin'>
                                    <h6>
                                        <span className='bold'>{i18n.startDate} : </span>
                                        {getDate(industrialSite.agreementStartDate)}
                                    </h6>
                                </div>
                            ) : (
                                <Input
                                    col={12}
                                    title={i18n.startDate}
                                    value={getDate(industrialSite.agreementStartDate)}
                                    onChange={(v) => {
                                        onChangeDate(v, v2 => onChangeIndustrialSite({ agreementStartDate: v2 }))
                                    }}
                                />
                            )}
                        </div>
                        <div className='col s6'>
                            {!readMode.editMode ? (
                                <div className='row no-margin'>
                                    <h6>
                                        <span className='bold'>{i18n.endDate} : </span>
                                        {getDate(industrialSite.agreementEndDate)}
                                    </h6>
                                </div>
                            ) : (
                                <Input
                                    col={12}
                                    title={i18n.endDate}
                                    value={getDate(industrialSite.agreementEndDate)}
                                    onChange={(v) => {
                                        onChangeDate(v, v2 => onChangeIndustrialSite({ agreementEndDate: v2 }))
                                    }}
                                />
                            )}
                        </div>
                    </Row>
                </div>
            </div>
        </WhiteCard>
    )
}


IndustrialSiteActivityPanel.propTypes = {
    industrialSite: PropTypes.instanceOf(DtoIndustrialSite),
    readMode: PropTypes.instanceOf(PropTypes.bool),
    onChangeInstallationIndusSite: PropTypes.func,
    onChangeIndustrialSite: PropTypes.func,
}

export default IndustrialSiteActivityPanel
