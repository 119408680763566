/* eslint-disable camelcase */
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import { arrayOf, getLabel } from '../../../../utils/StoreUtils'
import { getExport, setModal } from '../../../../utils/linkUtils'
import DtoPiezometerContributorLink from '../../../../piezometry/dto/DtoPiezometerContributorLink'
import DtoInstallation from '../../../dto/installation/DtoInstallation'
import SieauAction from '../../../../components/sieau/SieauAction'
import Table from '../../../../components/datatable/Table'
import KeyPossessorModal from './modal/KeyPossessorModal'

const InstallationKeysPossessorPanel = ({
    station = {},
    contributors = [],
    readMode = false,
    onChange = () => {},
    setPopup = () => {},
}) => {
    const stationKeyPossessors = station.link_keyPossessors.map((kp, index) => ({
        ...kp,
        index,
    }))
    const tableData = stationKeyPossessors.map(c => ({
        ...c,
        contributor: getLabel(contributors, c.contributor, 'mnemonique'),
    }))
    const headers = ['contributor']
    const exportAction = getExport(tableData, i18n.contributors, headers)
    const actions = !readMode ? [{
        iconName: 'note_add',
        tooltip: i18n.add,
        onClick: () => setModal(i18n.newContributorLink, <KeyPossessorModal id={ station.id } saveResult={ r => onChange({ link_keyPossessors: [...stationKeyPossessors, r] }) }/>, setPopup),
    }, exportAction] : [exportAction]

    return (
        <Table
            title={ i18n.keysPossessor }
            className='blue'
            condensed
            data={ tableData }
            type={{ headers }}
            sortable={ !!tableData.length }
            actions={ actions }
            alterable={ !readMode }
            onAlter={
                (element) => setModal(i18n.editContributorLink,
                    <KeyPossessorModal
                        id={ station.id }
                        keyPossessor={ stationKeyPossessors.find(c => element.index === c.index) }
                        saveResult={ r => onChange({
                            link_keyPossessors: [
                                ...stationKeyPossessors.filter(lkp => lkp.index !== element.index),
                                { ...r, index: element.index },
                            ],
                        })}
                    />
                    , setPopup)
            }
            deletable={ !readMode }
            onDelete={ (element) => onChange({ link_keyPossessors: stationKeyPossessors.filter(lkp => lkp.index !== element.index) }) }
        />
    )
}

InstallationKeysPossessorPanel.propTypes = {
    station: PropTypes.instanceOf(DtoInstallation),
    contributors: arrayOf(DtoPiezometerContributorLink),
    readMode: PropTypes.bool,
    onChange: PropTypes.func,
    setPopup: PropTypes.func,
}

const mapStateToProps = store => ({
    contacts: store.ContactReducer.contacts,
    sandreCodes: store.ReferencialReducer.sandreCodes,
    contributors: store.ContributorReducer.contributors,
})

const mapDispatchToProps = {
    setPopup: SieauAction.setPopup,
}

export default connect(mapStateToProps, mapDispatchToProps)(InstallationKeysPossessorPanel)