import { push } from '@lagunovsky/redux-react-router'
import HomeAction from 'home/actions/HomeAction'
import { intersection, template } from 'lodash'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import ToastrAction from 'toastr/actions/ToastrAction'
import { getBeginingOfTheYear, getEndOfTheYear } from 'utils/DateUtil'
import { H_QUALITO_CONFORMITY } from '../../../../account/constants/AccessRulesConstants'
import ProgressCard from '../../../../components/card/ProgressCard'
import { formatData } from '../../../../utils/ExportDataUtil'
import { componentHasHabilitations } from '../../../../utils/HabilitationUtil'
import { hasValue } from '../../../../utils/NumberUtil'
import QualityAction from '../../../actions/QualityAction'
import AEPcriteriasPanel from './AEPcriteriasPanel'
import SimpleTabList from 'components/list/SimpleTabList'
import AEPoverviewPanel from '../AEPoverviewPanel'
import AEPwaterTypePanel from '../AEPwaterTypePanel'
import AEPparametersPanel from '../AEPparametersPanel'
import AEPnonCompliancesPanel from '../AEPnonCompliancesPanel'
import useBoolean from 'utils/customHook/useBoolean'
import useTitle from 'utils/customHook/useTitle'
import useProgressDispatch from 'utils/customHook/useProgressDispatch'
import ParameterAction from 'referencial/components/parameter/actions/ParameterAction'
import OldOperationAction from 'quality/components/operation/actions/OperationAction'
import ExportAction from 'export/actions/ExportAction'
import InstallationAction from 'installation/actions/InstallationAction'
import ProductionUnitAction from 'productionUnit/actions/ProductionUnitAction'
import DistributionUnitAction from 'distributionUnit/actions/DistributionUnitAction'
import ResourceAction from 'resource/actions/ResourceAction'
import SupportAction from 'referencial/components/support/actions/SupportAction'
import ContributorAction from 'referencial/components/contributor/actions/ContributorAction'
import UnitAction from 'referencial/components/unit/actions/UnitAction'
import AnalysisAction from 'quality/actions/AnalysisAction'
import DtoAnalysisLight from 'quality/dto/analyse/DtoAnalysisLight'
import { calculateThresholdResult } from 'utils/AnalyseUtils'
import OperationAction from 'quality/actions/OperationAction'
import DtoOperation from 'quality/dto/operation/DtoOperation'
import ExportFileModal from 'components/modal/ExportFileModal'

const OVERVIEW = 'overview'
const PARAMETERS = 'parameters'
const WATER_TYPE = 'waterType'
const NON_COMPLIANCES = 'nonCompliances'

const AEPoverviewApp = ({

}) => {
    const dispatch = useDispatch()

    const {
        qualityThresholds,
        typeEnvironmentModels,
    } = useSelector(store => ({
        qualityThresholds: store.QualityReducer.qualityThresholds,
        typeEnvironmentModels: store.ExportReducer.typeEnvironmentModels,
    }), shallowEqual)

    useEffect(() => {
        if (!componentHasHabilitations(H_QUALITO_CONFORMITY)) { // A modifier quand react-router sera à jour
            dispatch(push('/unauthorized'))
        }
        dispatch(HomeAction.setHelpLink('qualite', ''))
    }, [])

    useTitle(() => [{
        title: i18n.quality,
        href: 'quality',
    }, {
        title: i18n.AEPoverview,
        href: 'quality/conformityOverview',
    }], [])

    const {
        value: isExportModalOpen,
        setTrue: openExportModal,
        setFalse: closeExportModal,
    } = useBoolean(false)
    const [exportData, setExportData] = useState()

    const {
        value: isLoaded,
        setTrue: loaded,
        setFalse: notLoaded,
    } = useBoolean(false)

    const [progress, setProgress] = useState(0)

    const {
        isLoaded: isCriteriasLoaded,
        progress: criteriasProgress,
    } = useProgressDispatch(() => [
        dispatch(ParameterAction.fetchParameters()),
        dispatch(OldOperationAction.fetchRemarks()),
        dispatch(ExportAction.fetchEnvironmentModelsByType('qualityresearch')),
        dispatch(InstallationAction.fetchInstallations()),
        dispatch(ProductionUnitAction.fetchProductionUnits()),
        dispatch(DistributionUnitAction.fetchDistributionUnits()),
        dispatch(ResourceAction.fetchResources()),
        dispatch(SupportAction.fetchSupports()),
        dispatch(ContributorAction.fetchProducers()),
        dispatch(QualityAction.fetchQualifications()),
        dispatch(QualityAction.fetchStatus()),
        dispatch(UnitAction.fetchUnits()),
        dispatch(ContributorAction.fetchLaboratories()),
        dispatch(ContributorAction.fetchDeterminators()),
    ])

    const [analysis, setAnalysis] = useState([])
    const [operations, setOperations] = useState([])
    const [filter, setFilter] = useState({})

    const onValidate = useCallback((newFilter) => {
        const {
            support,
            year,
            producers,
            referenceThreshold,
            limitThreshold,

            filter: filterId,
            filterResults,
            productionUnitAssociation,
            installationAssociation,
            distributionUnitAssociation,
            resourceAssociation,
            catchmentAssociation,
        } = newFilter

        if (!hasValue(referenceThreshold) && !hasValue(limitThreshold)) {
            dispatch(ToastrAction.error(i18n.noThresholdSelectedError))
            return
        }

        const listIds = [
            (filterId !== -1 ? filterResults.map(s => s.id) : []),
            productionUnitAssociation,
            installationAssociation,
            distributionUnitAssociation,
            resourceAssociation,
            catchmentAssociation,
        ].filter(l => !!l?.length)
        const ids = listIds.length ? intersection(...listIds) : []
        if (!ids.length) {
            dispatch(ToastrAction.error(i18n.noQualitySelectedError))
            return
        }

        notLoaded()
        setProgress(0)
        setFilter({ ...newFilter, ids })
        const criterias = {
            lightMode: true,
            stations: ids,
            startDate: getBeginingOfTheYear(year),
            endDate: getEndOfTheYear(year),
            support,
            producers,
        }
        Promise.all([
            dispatch(OperationAction.getAllOperation(ids)).then(op => {
                setProgress(p => p + 50)
                setOperations(op.map(o => new DtoOperation(o)))
            }),
            AnalysisAction.getAnalysis(criterias).then(an => {
                setProgress(p => p + 50)
                setAnalysis(an.map(a => new DtoAnalysisLight(a)))
            }),
        ]).finally(loaded)
    }, [dispatch])

    const thresholdList = useMemo(() => {
        const referenceThreshold = filter.referenceThreshold && qualityThresholds.find(t => t.thresholdCode == filter.referenceThreshold)?.thresholds || []
        const limitThreshold = filter.limitThreshold && qualityThresholds.find(t => t.thresholdCode == filter.limitThreshold)?.thresholds || []
        return [
            ...referenceThreshold,
            ...limitThreshold,
        ]
    }, [filter.limitThreshold, filter.referenceThreshold, qualityThresholds])

    const formatedAnalysis = useMemo(() => {
        return analysis
            .filter(a => a.remark !== '0')
            .map(a => {
                const operation = operations.find(o => o.id === a.operation && o.qualitometer === a.qualitometer)
                return {
                    ...a,
                    ...calculateThresholdResult(a, thresholdList),
                    producer: operation?.producer,
                }
            })
    }, [analysis, operations, thresholdList])

    const onExport = (data) => {
        setExportData(data)
        openExportModal()
    }

    const exportTypes = useMemo(() => {
        const {
            ids,
            support,
            year,
            producers,
        } = filter
        const criterias = {
            stations: ids,
            startDate: getBeginingOfTheYear(year),
            endDate: getEndOfTheYear(year),
            support,
            producers,
            researchType: 31,
        }
        const models = typeEnvironmentModels.map(model => ({
            name: model,
            formats: [{
                type: i18n.excelFile,
                callback: () => dispatch(ExportAction.exportResearchModel(criterias, model)),
            }],
        }))

        return [
            {
                name: i18n.resultsTable,
                formats: [{
                    type: i18n.excelFile,
                    callback: () => dispatch(ExportAction.export(formatData(exportData.data), 'xlsx', exportData.titleFile)),
                }, {
                    type: i18n.csvFile,
                    callback: () => dispatch(ExportAction.export(formatData(exportData.data), 'csv', exportData.titleFile)),
                }],
            },
            ...models,
        ]
    }, [filter, typeEnvironmentModels, dispatch, exportData])


    return (
        <>
            {
                !isCriteriasLoaded && (
                    <ProgressCard progress={criteriasProgress} />
                )
            }
            {
                isCriteriasLoaded && (
                    <div className='padding-left-1 padding-right-1'>
                        <div className='row no-margin'>
                            <AEPcriteriasPanel
                                onValidate={onValidate}
                                defaultFilter={filter}
                            />

                            <SimpleTabList
                                defaultTab={OVERVIEW}
                                tabs={[
                                    {
                                        constant: OVERVIEW,
                                        label: i18n.overview,
                                        icon: 'playlist_add_check',
                                    },
                                    {
                                        constant: PARAMETERS,
                                        label: i18n.parameter,
                                        icon: 'bubble_chart',
                                    },
                                    {
                                        constant: WATER_TYPE,
                                        label: i18n.waterType,
                                        icon: 'format_list_bulleted',
                                    },
                                    {
                                        constant: NON_COMPLIANCES,
                                        label: i18n.noncompliances,
                                        icon: 'warning',
                                    },
                                ]}
                            >
                                {
                                    tab => {
                                        if (!hasValue(filter.referenceThreshold) && !hasValue(filter.limitThreshold)) {
                                            return (
                                                <div className='row no-margin padding-top-1 padding-left-3 padding-bottom-1' >
                                                    <h5>{i18n.pleaseSelectThresholdAndStation}</h5>
                                                </div>
                                            )
                                        }
                                        if (isLoaded && !analysis.length) {
                                            return (
                                                <div className='row no-margin padding-top-1 padding-left-3 padding-bottom-1' >
                                                    <h5>{template(i18n.noAnalysisForYear)({ year: filter.year })}</h5>
                                                </div>
                                            )
                                        }
                                        if (!isLoaded) {
                                            return (
                                                <ProgressCard progress={progress} />
                                            )
                                        }
                                        return (
                                            <div style={{ padding: '10px' }}>
                                                {
                                                    tab === OVERVIEW && (
                                                        <AEPoverviewPanel
                                                            filter={filter}
                                                            onExport={onExport}
                                                            thresholdList={thresholdList}
                                                            operations={operations}
                                                            analysis={formatedAnalysis}
                                                        />
                                                    )
                                                }
                                                {
                                                    tab === PARAMETERS && (
                                                        <AEPparametersPanel
                                                            filter={filter}
                                                            onExport={onExport}
                                                            thresholdList={thresholdList}
                                                            analysis={formatedAnalysis}
                                                        />
                                                    )
                                                }
                                                {
                                                    tab === WATER_TYPE && (
                                                        <AEPwaterTypePanel
                                                            filter={filter}
                                                            onExport={onExport}
                                                            thresholdList={thresholdList}
                                                            analysis={formatedAnalysis}
                                                        />
                                                    )
                                                }
                                                {
                                                    tab === NON_COMPLIANCES && (
                                                        <AEPnonCompliancesPanel
                                                            filter={filter}
                                                            onExport={onExport}
                                                            operations={operations}
                                                            analysis={formatedAnalysis}
                                                            thresholdList={thresholdList}
                                                        />
                                                    )
                                                }
                                            </div>
                                        )
                                    }
                                }
                            </SimpleTabList>
                        </div>
                    </div>
                )
            }
            <ExportFileModal
                open={isExportModalOpen}
                onClose={closeExportModal}
                data={exportTypes}
            />
        </>
    )
}

export default AEPoverviewApp
