/* eslint-disable camelcase */
import React from 'react'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import { connect } from 'react-redux'
import { arrayOf, getSandreList } from '../../../../utils/StoreUtils'
import DtoSandreCode from '../../../../referencial/dto/DtoSandreCode'
import { SANDRE } from '../../../../referencial/constants/ReferencialConstants'
import Row from '../../../../components/react/Row'
import DtoTankStation from '../dto/DtoTankStation'
import Select from '../../../../components/forms/Select'
import { getVisitSandreSelectChange } from '../../../../utils/VisitUtils'

const TankEquipmentsPanel = ({
    onChange = () => { },
    onChangeVisit = () => { },
    tank = {},
    readMode = false,
    sandreCodes = [],
}) => {
    const onChangeEquipments = (value) => {
        onChange({
            link_equipments: [
                {
                    idStation: tank.idStation,
                    ...tank.link_equipments[0],
                    ...value,
                },
            ],
        })
    }

    const equipments = tank.link_equipments[0] || {}

    return (
        <Row className='padding-top-1 padding-bottom-1'>
            <Row>
                <Select
                    col={6}
                    value={equipments.alimentationFlowMeasurement}
                    label={i18n.alimentationFlowMeasurement}
                    options={getSandreList(sandreCodes, SANDRE.INSTALLATIONS_MESUREDEBIT)}
                    onChange={(v) => {
                        onChangeEquipments({ alimentationFlowMeasurement: v })
                        onChangeVisit(getVisitSandreSelectChange(sandreCodes, SANDRE.INSTALLATIONS_MESUREDEBIT, i18n.alimentationFlowMeasurement, equipments.alimentationFlowMeasurement, v))
                    }}
                    integerValue
                    keyValue='code'
                    readMode={readMode}
                />
                <Select
                    col={ 6 }
                    value={ equipments.distributionFlowMeasurement }
                    label={ i18n.distributionFlowMeasurement }
                    options={ getSandreList(sandreCodes, SANDRE.INSTALLATIONS_MESUREDEBIT) }
                    onChange={ (v) => {
                        onChangeEquipments({ distributionFlowMeasurement: v })
                        onChangeVisit(getVisitSandreSelectChange(sandreCodes, SANDRE.INSTALLATIONS_MESUREDEBIT, i18n.distributionFlowMeasurement, equipments.distributionFlowMeasurement, v))
                    } }
                    integerValue
                    keyValue='code'
                    readMode={ readMode }
                />
                <Select
                    col={ 6 }
                    value={ equipments.typeDetectionLevel }
                    label={ i18n.typeDetectionLevel }
                    options={ getSandreList(sandreCodes, SANDRE.RESERVOIRS_TYPEDETECTIONNIVEAU) }
                    onChange={ (v) => {
                        onChangeEquipments({ typeDetectionLevel: v })
                        onChangeVisit(getVisitSandreSelectChange(sandreCodes, SANDRE.RESERVOIRS_TYPEDETECTIONNIVEAU, i18n.typeDetectionLevel, equipments.typeDetectionLevel, v))
                    } }
                    integerValue
                    keyValue='code'
                    readMode={ readMode }
                />
            </Row>
        </Row>
    )
}

TankEquipmentsPanel.propTypes = {
    tank: PropTypes.instanceOf(DtoTankStation),
    sandreCodes: arrayOf(DtoSandreCode),
    readMode: PropTypes.bool,
    onChange: PropTypes.func,
    onChangeVisit: PropTypes.func,
}

const mapStateToProps = (store) => ({
    sandreCodes: store.ReferencialReducer.sandreCodes,
})

export default connect(mapStateToProps)(TankEquipmentsPanel)
