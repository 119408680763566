'use strict'
import { AQUIFER, RECEIVE_ALL_AQUIFER, RESET_AQUIFER } from '../constants/AquiferConstants'
import AquiferItem from '../dto/AquiferItem'
import DtoAquifer from '../dto/DtoAquifer'

export const store = {
    aquifers: [],
    aquifer: {},
}

export function AquiferReducer(state = {}, action) {
    switch (action.type) {
        case RECEIVE_ALL_AQUIFER:
            return {
                ...state,
                aquifers: action.aquifers.map(a => new DtoAquifer(a)),
            }
        case AQUIFER:
            return {
                ...state,
                aquifer: new AquiferItem(action.aquifer),
            }
        case RESET_AQUIFER:
            return {
                ...state,
                aquifer: {},
                aquifers: [],
            }
        default:
            return state
    }
}
