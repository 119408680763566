import { push } from '@lagunovsky/redux-react-router'
import BoundaryError from 'log/components/BoundaryError'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Outlet } from 'react-router-dom'
import i18n from 'simple-react-i18n'
import { H_ADMINISTRATION } from '../../account/constants/AccessRulesConstants'
import FixedSlideNav from '../../components/navbar/FixedSlideNav'
import { componentHasHabilitations } from '../../utils/HabilitationUtil'

class AdministrationApp extends Component {
    componentWillMount() {
        if (!componentHasHabilitations(H_ADMINISTRATION)) { // A modifier quand react-router sera à jour
            this.props.push('/unauthorized')
            return
        }
    }

    render() {
        const base = '/administration/'
        const links = [
            {
                href: `${base}settings`,
                icons: 'build',
                name: i18n.settings,
            },
            {
                href: `${base}cartography`,
                icons: 'map',
                name: i18n.cartography,
            },
            {
                href: `${base}stats`,
                icons: 'data_usage',
                name: i18n.statistics,
            },
            {
                href: `${base}user`,
                icons: 'person_outline',
                name: i18n.users,
            },
            {
                href: `${base}homepage`,
                icons: 'dashboard',
                name: i18n.adminHomepage,
            },
            {
                href: `${base}contents`,
                icons: 'event_note',
                name: i18n.contentManagement,
            },
            {
                href: `${base}models`,
                icons: 'folder_open',
                name: i18n.models,
            },
            {
                href: `${base}parameters`,
                icons: 'settings',
                name: i18n.parameters,
            },
            // {
            //     href: base + 'problemSolution',
            //     icons: 'report_problem',
            //     name: i18n.problemSolution
            // },
        ]

        return (
            <div>
                <FixedSlideNav links={ links } />
                <div className='content-page no-padding fullscreen'>
                    <div className='row no-margin'>
                        <div className='col s12 no-padding'>
                            <BoundaryError>
                                <Outlet />
                            </BoundaryError>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

AdministrationApp.propTypes = {
    popup: PropTypes.element,
    children: PropTypes.element,
    getLink: PropTypes.func,
    push: PropTypes.func,
}

const mapStateToProps = store => ({
    popup: store.StationReducer.popup,
})

const mapDispatchToProps = {
    push,
}

export default connect(mapStateToProps, mapDispatchToProps)(AdministrationApp)
