import React from 'react'
import i18n from 'simple-react-i18n'
import PropTypes from 'prop-types'
import { AccordionDetails, Grid } from '@mui/material'
import { groupBy, orderBy } from 'lodash'
import { AccordionMUI, AccordionSummaryMUI } from 'components/styled/Accordions'
import ServicesTable from './ServicesTable'
import DtoServiceSituation from 'exploitations/dto/DtoServiceSituation'

const ServicesAccordion = ({
    data = [],
    yearKey = '0000',
    expanded = '',
    setExpanded = () => { },
}) => {
    const groupedSituationsServices = groupBy(data, d => d.calculationDate)
    const orderdedKeys = orderBy(Object.keys(groupedSituationsServices), d => parseInt(d), ['desc'])

    return (
        <Grid item xs={12}>
            <AccordionMUI
                TransitionProps={{ unmountOnExit: true }}
                expanded={expanded === `panel_${yearKey ?? ''}`}
                onChange={(_, isExpanded) => setExpanded(isExpanded ? `panel_${yearKey ?? ''}` : '')}
                sx={{ border: 'solid 1px grey', marginBottom: '5px', marginTop: '5px' }}
                key={`panel_${yearKey ?? ''}`}
            >
                <AccordionSummaryMUI>
                    <Grid container alignItems='center'>
                        {yearKey !== 'undefined' && yearKey || i18n.notDefined}
                    </Grid>
                </AccordionSummaryMUI>
                <AccordionDetails style={{ padding: '0 10 10 10' }}>
                    {orderdedKeys.map(situationKey => (
                        <ServicesTable
                            data={groupedSituationsServices[situationKey]}
                        />
                    ))}
                </AccordionDetails>
            </AccordionMUI>
        </Grid >
    )
}

ServicesAccordion.propTypes = {
    data: PropTypes.arrayOf(PropTypes.instanceOf(DtoServiceSituation)),
    yearKey: PropTypes.string,
    expanded: PropTypes.string,
    setExpanded: PropTypes.func,
}

export default ServicesAccordion