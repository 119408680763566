export default class AnalysisConformityDto {
    constructor(obj) {
        this.numSample = obj.numSample
        this.numAnalyse = obj.numAnalyse
        this.stationId = obj.stationId
        this.operationId = obj.operationId
        this.code = obj.code
        this.name = obj.name
        this.sampleDate = obj.sampleDate
        this.laboratory = obj.laboratory
        this.parameter = obj.parameter
        this.status = parseInt(obj.status) || obj.operationStatus
        this.result = obj.result
        this.unit = obj.unit
        this.support = obj.support
        this.fraction = obj.fraction
        this.remark = obj.remark
        this.errors = obj.errors
        this.qualification = parseInt(obj.qualification) || obj.operationQualification
    }
}