import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { applicationName, aquasysPath } from '../../conf/SieauConstants'
import aquasysLogoPath from 'assets/pictures/logoaQuasys.png'
import SlideNavLink from './links/SlideNavLink'
import SieauAction from '../sieau/SieauAction'
import AppStore from '../../store/AppStore'
import HomeAction from '../../home/actions/HomeAction'
import i18n from 'simple-react-i18n'
import { groupBy, keys, template, uniqBy } from 'lodash'
import { MESSAGES } from '../../administration/components/user/constants/ApplicationHabilitationConstants'
import {
    PATH_ADMINISTRATION,
    PATH_ALERT,
    PATH_DISTRIBUTION,
    PATH_GLOBAL_EVENTS,
    PATH_HYDROMETRY,
    PATH_INSTALLATION,
    PATH_RESOURCE,
    PATH_JOB,
    PATH_MATERIEL,
    PATH_PGSSE,
    PATH_MESSAGES,
    PATH_PIEZOMETRY,
    PATH_PLUVIOMETRY,
    PATH_PRODUCTION,
    PATH_QUALITY,
    PATH_REFERENCIAL,
    PATH_CATCHMENT, PATH_PERIMETERS,
} from '../../home/constants/RouteConstants'
import User from '../../account/dto/User'
import DtoAccountHabilitation from '../../account/dto/DtoAccountHabilitation'
import AccountPanel from './account/AccountPanel'
import SieauParameterDto from '../../administration/dto/SieauParameterDto'
import { FR } from '../../referencial/constants/ReferencialConstants'
import { getLogin, getSetting, getUser } from '../../utils/SettingUtils'
import AdministrationAction from '../../administration/actions/AdministrationAction'
import SettingDto from '../../administration/dto/SettingDto'
import ContributorAction from '../../referencial/components/contributor/actions/ContributorAction'
import { componentHasHabilitations } from '../../utils/HabilitationUtil'
import {
    H_DISTRIBUTION_MODULE,
    H_HYDRO_MODULE,
    H_INSTALLATION_MODULE,
    H_MAT_MODULE,
    H_PGSSE_MODULE,
    H_PIEZO_MODULE,
    H_PLUVIO_MODULE,
    H_PRODUCTION_MODULE,
    H_QUALITO_MODULE,
    H_SITUATION_MODULE,
    H_RESOURCE_MODULE,
    H_TERRITORY_DASHBOARD,
    H_AGRI_FOLDERS_DASHBOARD,
    H_AGRI_SURVEYS_DASHBOARD,
    H_CATCHMENT_MODULE, H_PERIMETERS_MODULE,
} from '../../account/constants/AccessRulesConstants'
import { NAME_MENU_PICTURE } from '../../administration/constants/AdministrationConstants'
import DtoPublicPicture from '../../station/dto/DtoPublicPicture'

const propsToFetch = {
    users: true,
    cmsCategories: true,
}

class SlideNav extends Component {
    componentDidMount() {
        this.props.fetch(propsToFetch)
        this.props.fetchContributors()
        this.props.fetchSettings()
        if (!this.props.menuPicture.length) {
            this.props.fetchPublicPicture(getSetting(this.props.applicationSettings, NAME_MENU_PICTURE), NAME_MENU_PICTURE)
        }
        this.props.fetchProfilePicture(getLogin())
    }

    logout = () => {
        AppStore.dispatch({ type: 'RESET_ALL_STORE' })
        AppStore.dispatch(HomeAction.logout())
    }

    createLinkObject = (habilitationParameters, title, link, icon, id, svg) => ({
        habilitationParameters,
        title,
        link,
        icon,
        id,
        svg,
    })

    createLink = (link, external = false) => {
        return (
            <SlideNavLink
                key={link.id}
                href={link.link}
                icon={link.icon}
                external={external}
                tooltip={false}
                data-cy={link.id}
                id={link.id}
                name={link.title}
            />
        )
    }

    getFilterModules = (modules) => modules.filter(o => componentHasHabilitations(o.habilitationParameters))

    getAepModules = () => {
        const modules = [
            this.createLinkObject(H_INSTALLATION_MODULE, i18n.installations, PATH_INSTALLATION, 'build', 'installation_menu'),
            this.createLinkObject(H_PERIMETERS_MODULE, i18n.perimeters, PATH_PERIMETERS, 'public_icon', 'perimeters_menu'),
            this.createLinkObject(H_CATCHMENT_MODULE, i18n.catchment, PATH_CATCHMENT, 'opacity', 'catchment_menu'),
            this.createLinkObject(H_PRODUCTION_MODULE, i18n.productionUnit, PATH_PRODUCTION, 'location_city', 'productionUnit_menu'),
            this.createLinkObject(H_DISTRIBUTION_MODULE, i18n.distributionUnit, PATH_DISTRIBUTION, 'map', 'distributionUnit_menu'),
            this.createLinkObject(H_PGSSE_MODULE, i18n.pgsse, PATH_PGSSE, 'error_outline', 'pgsse_menu'),
        ]
        const filterModules = this.getFilterModules(modules)
        return filterModules.map(o => this.createLink(o))
    }

    getModules = () => {
        const modules = [
            this.createLinkObject(H_QUALITO_MODULE, i18n.quality, PATH_QUALITY, 'pie_chart', 'qualito_menu'),
            this.createLinkObject(H_PIEZO_MODULE, i18n.piezometry, PATH_PIEZOMETRY, 'show_chart', 'piezometry_menu'),
            this.createLinkObject(H_HYDRO_MODULE, i18n.hydrometry, PATH_HYDROMETRY, 'multiline_chart', 'hydrometry_menu'),
            this.createLinkObject(H_PLUVIO_MODULE, i18n.pluviometry, PATH_PLUVIOMETRY, 'insert_chart', 'pluviometry_menu'),
            this.createLinkObject(H_RESOURCE_MODULE, i18n.resource, PATH_RESOURCE, 'filter_hdr', 'resource_menu'),
        ]
        const filterModules = this.getFilterModules(modules)
        return filterModules.map(o => this.createLink(o))
    }

    getCMSRules = (filteredCMS) => {
        const user = getUser()
        if (user.isAdmin === '1') {
            return filteredCMS
        }
        const grouped = groupBy(filteredCMS, 'menu')
        return keys(grouped).reduce((acc, key) => {
            const cmsCategory = grouped[key]
            // if one category has no managers => category is displayed
            if (cmsCategory.some(c => !c.managers)) {
                return [...acc, cmsCategory[0] ]
            }
            // else if every categores has at least one manager => category is displayed if user contributor is one of them (managers)
            return cmsCategory.some(c => c.managers.split(';').map(d => parseInt(d)).includes(user.contributorCode)) ? [...acc, cmsCategory[0]] : acc
        }, [])
    }

    getContent = () => {
        const { cmsCategories } = this.props
        if (cmsCategories?.length) {
            const filteredCMS = cmsCategories.filter(
                (category) => category.displayOnMenu,
            )
            const linksToDisplay = uniqBy(this.getCMSRules(filteredCMS), 'menu')
            const linkObjects = linksToDisplay.map((o) => {
                return this.createLinkObject([], o.menu, `/contents/${o.id}`, 'event_note', `${o.menu}_menu`)
            })
            return linkObjects.map(o => this.createLink(o))
        }
        return null
    }

    getReferencielLink = () => {
        if (this.props.accountUser.isAdmin === '1' || this.props.accountUser.metadata === '1') {
            const objectLink = this.createLinkObject(
                [],
                i18n.referencials,
                PATH_REFERENCIAL,
                'folder',
                'referencial',
            )
            return this.createLink(objectLink)
        }
        return null
    }

    getAdministrationLink = () => {
        if (this.props.accountUser.isAdmin === '1') {
            const objectLink = this.createLinkObject(
                [],
                i18n.administration,
                PATH_ADMINISTRATION,
                'settings',
                'administration_menu'
            )
            return this.createLink(objectLink)
        }
        return null
    }

    getAgriLink = () => {
        const modules = [
            this.createLinkObject(H_AGRI_FOLDERS_DASHBOARD, i18n.folders, '/dossiers', 'folder', 'folders_menu'),
            this.createLinkObject(H_AGRI_SURVEYS_DASHBOARD, i18n.planning, '/planning', 'edit_calendar', 'surveys_menu'),
            this.createLinkObject(H_TERRITORY_DASHBOARD, i18n.steering, '/territory', 'map', 'territory_menu'),
        ]
        const filterModules = this.getFilterModules(modules)
        return filterModules.map(o => this.createLink(o))
    }

    getSituationModule = () => {
        if (componentHasHabilitations(H_SITUATION_MODULE)) {
            const objectLink = this.createLinkObject(
                H_SITUATION_MODULE,
                i18n.situation,
                PATH_ALERT,
                'notifications_active',
                'situation_menu'
            )
            return this.createLink(objectLink)
        }
        return null
    }

    getTreatmentLink = () => {
        if (this.props.accountUser.isAdmin === '1' || this.props.accountUser.metadata === '1') {
            const objectLink = this.createLinkObject([], i18n.treatments, PATH_JOB, 'cached', 'job_menu')
            return this.createLink(objectLink)
        }
        return null
    }

    getJournal = () => {
        if (this.props.accountUser.isAdmin === '1' || this.props.accountUser.metadata === '1') {
            const objectLink = this.createLinkObject(
                [],
                i18n.journal,
                PATH_GLOBAL_EVENTS,
                'view_column',
                'journal_menu'
            )
            return this.createLink(objectLink)
        }
        return null
    }

    getMaterielLink = () => {
        if (componentHasHabilitations(H_MAT_MODULE)) {
            const objectLink = this.createLinkObject([], i18n.materiel, PATH_MATERIEL, 'router', 'materiel')
            return this.createLink(objectLink)
        }
        return null
    }

    getCommonLinks = () => {
        const lang = getSetting(this.props.applicationSettings, 'supportLanguage') || FR
        const formationToken = getSetting(this.props.applicationSettings, 'formationToken')
        const urlLang = (lang || FR).toLowerCase()
        const modules = [
            this.createLinkObject(
                [],
                i18n.about,
                `https://helpcenter.aquasys.fr/about_${urlLang}/?tmp=${formationToken}`,
                'help',
                'about_menu'
            ),
            this.createLinkObject(
                [],
                i18n.confidentiality,
                `https://helpcenter.aquasys.fr/confidential_${urlLang}/?tmp=${formationToken}`,
                'security',
                'confidentiality_menu'
            ),
        ]
        return modules.map(o => this.createLink(o, true))
    }

    getMessages = () => {
        const objectLink = this.createLinkObject(MESSAGES, i18n.messages, PATH_MESSAGES, 'message', 'messages_menu')
        return getUser().consultant !== '1' ? this.createLink(objectLink) : null
    }

    getExploitations = () => {
        const objectLink = this.createLinkObject([], i18n.folders, '/dossiers', 'folder', 'folders_menu_2')
        return this.createLink(objectLink)
    }

    getColor = (branche) => {
        switch (branche) {
            case 'develop': return 'BackgroundOrange600'
            case 'quali': return ''
            default: return ''
        }
    }

    render() {
        return (
            <ul id='slide-out' className='side-nav'>
                <div className='top-nav'>
                    <li>
                        <AccountPanel />
                    </li>
                    <SlideNavLink href='/' icon='home' tooltip={false} id='menu_home' name={i18n.homepage}/>
                    <li>
                        <div className='divider' />
                    </li>
                    {this.getModules()}
                    {this.getMaterielLink()}
                    <li>
                        <div className='divider' />
                    </li>
                    {this.getAepModules()}
                    <li>
                        <div className='divider' />
                    </li>
                    {this.getSituationModule()}
                    <li>
                        <div className='divider' />
                    </li>
                    {this.getContent()}
                    {this.getJournal()}
                    <li>
                        <div className='divider' />
                    </li>
                    {this.getAgriLink()}
                    <li>
                        <div className='divider' />
                    </li>
                    {this.getMessages()}
                    {this.getTreatmentLink()}
                    {this.getReferencielLink()}
                    {this.getAdministrationLink()}
                    <li>
                        <div className='divider' />
                    </li>
                    {this.getCommonLinks()}
                    <li>
                        <div className='divider' />
                    </li>
                </div>
                <div>
                    <ul>
                        <li>
                            <div className='divider' />
                        </li>
                        <li>
                            <a
                                className='waves-effect slide-nav-link'
                                onClick={this.logout}
                                style={{ display: 'flex', alignItems: 'center' }}
                                data-cy='logout'
                            >
                                <i className='material-icons'>power_settings_new</i>
                                <span
                                    className='truncate'
                                >
                                    {i18n.logout}
                                </span>
                            </a>
                        </li>
                    </ul>
                </div>
                <div className='bottom-nav'>
                    <div className='col s12 center-align'>
                        <a href={aquasysPath} target='_blank'>
                            <img
                                src={
                                    getSetting(
                                        this.props.applicationSettings,
                                        'applicationFavicon',
                                    ) || aquasysLogoPath
                                }
                                alt=''
                                width='30px'
                            />
                        </a>
                    </div>
                    <div className={`col s12 center-align ${this.getColor(process.env.REACT_APP_BRANCHE)}`}>
                        <span className='black-text'>
                            {template(i18n.signVersion)({
                                name:
                                    getSetting(this.props.applicationSettings, 'applicationName') ||
                                    applicationName,
                                version: process.env.REACT_APP_VERSION,
                            })}
                            <br />
                            { process.env.REACT_APP_BRANCHE == 'develop' ? (i18n.envRecette):(i18n.envProd) }
                            <br />
                            {i18n.sieauLicense}
                            <br />
                            <b>{i18n.aquasysCopyright}</b>
                        </span>
                    </div>
                </div>
            </ul>
        )
    }
}

SlideNav.propTypes = {
    accountUser: PropTypes.instanceOf(User),
    accountHabilitations: PropTypes.arrayOf(PropTypes.instanceOf(DtoAccountHabilitation)),
    settings: PropTypes.arrayOf(PropTypes.instanceOf(SettingDto)),
    habilitations: PropTypes.arrayOf(PropTypes.instanceOf(DtoAccountHabilitation)),
    users: PropTypes.arrayOf(PropTypes.instanceOf(User)),
    cmsCategories: PropTypes.arrayOf(PropTypes.object),
    applicationSettings: PropTypes.arrayOf(PropTypes.instanceOf(SieauParameterDto)),
    fetchContributors: PropTypes.func,
    fetchSettings: PropTypes.func,
    fetch: PropTypes.func,
    fetchPublicPicture: PropTypes.func,
    menuPicture: PropTypes.instanceOf(DtoPublicPicture),
    fetchProfilePicture: PropTypes.func,
}

SlideNav.defaultProps = {
    users: [],
    habilitations: [],
    cmsCategories: [],
}

const mapStateToProps = (store) => ({
    accountUser: store.AccountReducer.accountUser,
    accountHabilitations: store.AccountReducer.accountHabilitations,
    cmsCategories: store.EventsReducer.cmsCategories,
    applicationSettings: store.AdministrationReducer.applicationSettings,
    settings: store.AdministrationReducer.settings,
    menuPicture: store.AdministrationReducer.menuPicture,
})

const mapDispatchToProps = {
    fetchContributors: ContributorAction.fetchContributors,
    fetchSettings: AdministrationAction.fetchSettings,
    fetch: SieauAction.fetch,
    fetchPublicPicture: AdministrationAction.fetchPublicPicture,
    fetchProfilePicture: AdministrationAction.fetchProfilePicture,
}

export default connect(mapStateToProps, mapDispatchToProps)(SlideNav)
