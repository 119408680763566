import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import ElementCard from '../../../../components/card/ElementCard'
import Select from '../../../../components/forms/Select'
import DtoMaterielState from '../../../../materiel/dto/DtoMaterielState'
import ContributorDto from '../../../../referencial/components/contributor/dto/ContributorDto'
import { getMaterialStateIcon } from '../../../../utils/MaterielUtils'
import { STEPS } from '../../../constants/PiezometryEventConstants'
import MaterielCard from '../eventMaterielCard/MaterielCard'


const NewSituationPanel = ({
    action = {},
    diagnostic = {},
    materiel = {},
    materielCategory = '',
    materielTypeName = '',
    situationContributor = undefined,
    onChangeStep = () => { },
    onSelectNewState = () => { },
    onChangeAdministratior = () => { },

    materielStates = [],
    contributors = [],
}) => (
    <div>
        <div className='row no-margin'>
            <div className='col s6'>
                <ElementCard
                    icon='report_problem'
                    color='red'
                    className='clickable'
                    onClick={() => onChangeStep(STEPS.DIAGNOSTIC)}
                >
                    <h6>{diagnostic.description}</h6>
                </ElementCard>
            </div>
            <div className='col s6'>
                <ElementCard
                    icon='build'
                    color='green'
                    className='clickable'
                    onClick={() => onChangeStep(STEPS.ACTION)}
                >
                    <h6>{action.description}</h6>
                </ElementCard>
            </div>
        </div>
        <div className='row no-margin valign-wrapper padding-left-1 padding-top-1'>
            <i className={'material-icons'}>trending_flat</i>
            <h6 className='padding-left-1'>{i18n.selectNewStateMaterial}</h6>
        </div>
        <MaterielCard
            materielCategory={materielCategory}
            materielTypeName={materielTypeName}
            materiel={materiel}
        />
        <div className='row no-margin'>
            <Select
                col={12}
                value={situationContributor}
                label={i18n.administrator}
                options={contributors}
                keyValue='id'
                displayWithCode
                onChange={v => onChangeAdministratior(v)}
            />
        </div>
        {
            materielStates.filter(s => s.code !== 1).map(s => (
                <ElementCard
                    icon={getMaterialStateIcon(s.code).icon}
                    iconColor={getMaterialStateIcon(s.code).color}
                    className='clickable'
                    color='white' onClick={() => onSelectNewState(s.code)}
                >
                    {s.label}
                </ElementCard>
            ))
        }
    </div>
)

NewSituationPanel.propTypes = {
    action: PropTypes.object,
    diagnostic: PropTypes.object,
    materiel: PropTypes.object,
    situationContributor: PropTypes.number,
    materielCategory: PropTypes.string,
    materielTypeName: PropTypes.string,
    onChangeStep: PropTypes.func,
    onSelectNewState: PropTypes.func,
    onChangeAdministratior: PropTypes.func,

    materielStates: PropTypes.arrayOf(PropTypes.instanceOf(DtoMaterielState)),
    contributors: PropTypes.arrayOf(PropTypes.instanceOf(ContributorDto)),
}

const mapStateToProps = store => ({
    materielStates: store.MaterielReducer.materielStates,
    contributors: store.ContributorReducer.contributors,
})

export default connect(mapStateToProps)(NewSituationPanel)