import { find, isEqual, orderBy, reduce, take } from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import AppStore from 'store/AppStore'
import { getI18nOrLabel } from 'utils/StringUtil'
import {
    HYDROMETRY,
    PIEZOMETRY,
    QUALITY,
} from '../../../../administration/components/user/constants/StatisticConstants'
import DtoUserStationStatistic from '../../../../administration/components/user/dto/DtoUserStationStatistic'
import DtoUserStationStatisticItem from '../../../../administration/components/user/dto/DtoUserStationStatisticItem'
import Table from '../../../../components/datatable/Table'
import HydrometryAction from '../../../../hydrometry/actions/HydrometryAction'
import DtoHydrometricStation from '../../../../hydrometry/dto/DtoHydrometricStation'
import PiezometryAction from '../../../../piezometry/actions/PiezometryAction'
import DtoPiezometerLight from '../../../../piezometry/dto/DtoPiezometerLight'
import QualityAction from '../../../../quality/actions/QualityAction'
import DtoQualitometerLight from '../../../../quality/dto/DtoQualitometerLight'
import CityDto from '../../../../referencial/components/city/dto/CityDto'
import { getFullDateMini } from '../../../../utils/DateUtil'
import { getStationTypeNameFromType } from '../../../../utils/StationUtils'
import User from '../../../dto/User'

class AccountStationStatisticsPanel extends Component {
    constructor(props) {
        super(props)
    }

    componentWillMount() {
        if (!this.props.piezometers.length) {
            AppStore.dispatch(PiezometryAction.fetchPiezometersLight())
        }
        if (!this.props.qualitometers.length) {
            AppStore.dispatch(QualityAction.fetchQualitometersLight())
        }
        if (!this.props.hydrometricStations.length) {
            AppStore.dispatch(HydrometryAction.fetchHydrometricStations())
        }
    }

    shouldComponentUpdate(nextProps) {
        return !isEqual(nextProps.accountStationStatistics, this.props.accountStationStatistics)
            || !isEqual(nextProps.qualitometers, this.props.qualitometers)
            || !isEqual(nextProps.piezometers, this.props.piezometers)
            || !isEqual(nextProps.hydrometricStations, this.props.hydrometricStations)
            || !isEqual(nextProps.cities, this.props.cities)
            || !isEqual(nextProps.accountUser, this.props.accountUser)
    }

    getReduceStatistics = () => {
        return reduce(this.props.accountStationStatistics, (a, b) => {
            if (!a[`${b.module}_${b.value}`]) {
                a[`${b.module}_${b.value}`] = {
                    module: b.module,
                    code: b.value,
                    datas: [],
                }
            }
            a[`${b.module}_${b.value}`].datas.push(b)
            return a
        }, {})
    }

    getStations = (module) => {
        switch (module) {
            case QUALITY:
                return this.props.qualitometers
            case PIEZOMETRY:
                return this.props.piezometers
            case HYDROMETRY:
                return this.props.hydrometricStations
            default:
                return []
        }
    }

    getCity = (station) => {
        if (station && station.townCode) {
            const cityFound = this.props.cities.find((c) => c.code === station.townCode)
            if (cityFound) {
                if (cityFound.name) {
                    return `${cityFound.name} - [${cityFound.code}]`
                }
                return cityFound.code
            }
        }
        return ''
    }

    getStation = (module, code) => {
        const stations = this.getStations(module)
        if (stations.length) {
            return find(stations, (o) => {
                const codeDesignation = (() => {
                    const result = o.code
                    if (o.designation) {
                        return `${result}/${o.designation}`
                    }
                    return result
                })()
                return codeDesignation === code
            })
        }
        return null
    }

    getDatas = () => {
        const reduceValues = this.getReduceStatistics()
        return orderBy(Object.keys(reduceValues).map((o) => {
            const statisticObject = reduceValues[o]
            const code = (() => {
                const result = statisticObject.code
                if (result.includes(' - ')) {
                    return result.split(' - ')[0]
                }
                return result
            })()
            const listOfStatistics = statisticObject.datas
            const lastVisit = orderBy(listOfStatistics, 'statisticDate', 'desc')[0]
            const station = this.getStation(statisticObject.module, code)
            const city = this.getCity(station)
            const finalCode = (() => {
                if (station && station.name) {
                    return `${code} - ${station.name}`
                }
                return code
            })()
            return new DtoUserStationStatisticItem({
                stationType: getI18nOrLabel(getStationTypeNameFromType(statisticObject.module.toLowerCase())),
                code: finalCode,
                city,
                lastAccess: getFullDateMini(lastVisit.statisticDate),
                nbAccess: listOfStatistics.length,
            })
        }), 'nbAccess', 'desc')
    }

    render() {
        if (this.props.accountStationStatistics.length) {
            const data = this.getDatas()
            return (
                <div className='row no-margin card'>
                    <div className='col s12 no-padding'>
                        <Table title={ `10 ${i18n.userStationStatistics}` }
                            type={ new DtoUserStationStatisticItem({}) } data={ take(data, 10) }
                            exportButtonOnHeader exportData={ data }
                            showNbElements={ false }
                            activeHeader
                            exportName={ i18n.userStationStatisticsExport + this.props.accountUser.login }
                        />
                    </div>
                </div>
            )
        }
        return null
    }
}

AccountStationStatisticsPanel.propTypes = {
    accountUser: PropTypes.instanceOf(User),
    accountStationStatistics: PropTypes.arrayOf(PropTypes.instanceOf(DtoUserStationStatistic)),
    piezometers: PropTypes.arrayOf(PropTypes.instanceOf(DtoPiezometerLight)),
    qualitometers: PropTypes.arrayOf(PropTypes.instanceOf(DtoQualitometerLight)),
    hydrometricStations: PropTypes.arrayOf(PropTypes.instanceOf(DtoHydrometricStation)),
    cities: PropTypes.arrayOf(PropTypes.instanceOf(CityDto)),
}
const mapStateToProps = (store) => {
    return {
        userStationStatistics: store.UserReducer.userStationStatistics,
        piezometers: store.PiezometryReducer.piezometersLight,
        qualitometers: store.QualityReducer.qualitometersLight,
        hydrometricStations: store.HydrometryReducer.hydrometricStations,
        cities: store.CityReducer.cities,
        accountUser: store.AccountReducer.accountUser,
        accountStationStatistics: store.AccountReducer.accountStationStatistics,
    }
}
export default connect(mapStateToProps)(AccountStationStatisticsPanel)
