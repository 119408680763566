import { getOptions } from '../EChartUtils'

class _Pie {
    constructor(obj) {
        this.obj = obj
    }

    getDefaultOptions = () => {
        return {
            name: 'pie chart',
            type: 'pie',
            data: [],
            radius: ['60%', '80%'],
            hoverOffset: 3,
            avoidLabelOverlap: false,
            label: {
                normal: {
                    show: false,
                    position: 'center',
                },
                emphasis: {
                    show: true,
                    textStyle: {
                        fontSize: '10',
                        fontWeight: 'bold',
                    },
                },
            },
            labelLine: {
                normal: {
                    show: false,
                },
            },
            itemStyle: {
                emphasis: {
                    shadowBlur: 10,
                    shadowOffsetX: 0,
                    shadowColor: 'rgba(0, 0, 0, 0.5)',
                },
            },
        }
    }

    getJson = () => {
        return getOptions(this.obj, this.getDefaultOptions(), {})
    }
}

export default function Pie(args) {
    return new _Pie(args)
}


