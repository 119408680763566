
import { createSlice } from '@reduxjs/toolkit'
import DtoCampaignFile from 'campaign/dto/DtoCampaignFile'
import DtoEvent from '../../events/dto/DtoEvent'
import DtoCampaignAction from '../../installation/components/campaign/dto/DtoCampaignAction'
import DtoCampaignInstallationWithStats from '../../installation/components/campaign/dto/DtoCampaignInstallationWithStats'
import CentralAssignatedDto from '../../materiel/components/central/dto/CentralAssignatedDto'
import EquipmentAssignatedDto from '../../materiel/components/equipment/dto/EquipmentAssignatedDto'
import PowerSupplyAssignatedDto from '../../materiel/components/powerSupply/dto/PowerSupplyAssignatedDto'
import SensorAssignatedDto from '../../materiel/components/sensor/dto/SensorAssignatedDto'
import SimAssignatedDto from '../../materiel/components/sim/dto/SimAssignatedDto'
import TelecomAssignatedDto from '../../materiel/components/telecom/dto/TelecomAssignatedDto'
import VariousMaterielAssignatedDto from '../../materiel/components/variousMateriel/dto/VariousMaterielAssignatedDto'
import DtoDetailOperation from '../../quality/components/operation/dto/DtoDetailOperation'
import CampaignDto from '../dto/CampaignDto'
import CampaignPlanningDto from '../dto/CampaignPlanningDto'
import DtoCampaignProgress from '../dto/DtoCampaignProgress'
import DtoCampaignVisit from '../dto/DtoCampaignVisit'
import DtoParameterCampaign from '../dto/DtoParameterCampaign'
import DtoStationCampaign from '../dto/DtoStationCampaign'
import EDILABOFileDto from '../dto/EDILABOFileDto'

// https://redux.js.org/tutorials/fundamentals/part-8-modern-redux#using-createslice

export const initialState = {
    campaign: {},
    campaignProgress: {},
    campaignStations: [],
    campaignEvents: [],
    campaignOperations: [],
    campaignParameters: [],
    campaignPlannings: [],
    campaignVisits: [],

    campaignMaterielsEquipments: [],
    campaignMaterielsCentrals: [],
    campaignMaterielsSensors: [],
    campaignMaterielsSims: [],
    campaignMaterielsPowerSupplies: [],
    campaignMaterielsVariousMateriels: [],
    campaignMaterielsTelecoms: [],

    campaignsActions: [],
    campaignsInstallationWithStats: [],

    edilaboFiles: [],
    campaignFiles: [],
}

const store = createSlice({
    name: 'campaign',
    initialState,
    reducers: {
        campaign: (state, action) => {
            state.campaign = new CampaignDto(action.payload)
        },
        campaignProgress: (state, action) => {
            state.campaignProgress = new DtoCampaignProgress(action.payload)
        },
        campaignStations: (state, action) => {
            state.campaignStations = action.payload.map(campaignStation => new DtoStationCampaign(campaignStation))
        },
        campaignEvents: (state, action) => {
            state.campaignEvents = action.payload.map(e => new DtoEvent(e))
        },
        campaignOperations: (state, action) => {
            state.campaignOperations = action.payload.map(e => new DtoDetailOperation(e))
        },
        campaignMateriels: (state, action) => {
            state.campaignMaterielsEquipments = action.payload.equipments.map(e => new EquipmentAssignatedDto(e))
            state.campaignMaterielsCentrals = action.payload.centrals.map(c => new CentralAssignatedDto(c))
            state.campaignMaterielsSensors = action.payload.sensors.map(s => new SensorAssignatedDto(s))
            state.campaignMaterielsSims = action.payload.sims.map(s => new SimAssignatedDto(s))
            state.campaignMaterielsPowerSupplies = action.payload.powerSupplies.map(p => new PowerSupplyAssignatedDto(p))
            state.campaignMaterielsVariousMateriels = action.payload.variousMateriels.map(v => new VariousMaterielAssignatedDto(v))
            state.campaignMaterielsTelecoms = action.payload.telecoms.map(t => new TelecomAssignatedDto(t))
        },
        campaignParameters: (state, action) => {
            state.campaignParameters = action.payload.map(p => new DtoParameterCampaign(p))
        },
        campaignPlannings: (state, action) => {
            state.campaignPlannings = action.payload.map(p => new CampaignPlanningDto(p))
        },
        edilaboFiles: (state, action) => {
            state.edilaboFiles = action.payload.map(e => new EDILABOFileDto(e))
        },
        campaignFiles: (state, action) => {
            state.campaignFiles = action.payload.map(f => new DtoCampaignFile(f))
        },
        campaignVisits: (state, action) => {
            state.campaignVisits = action.payload.map(a => new DtoCampaignVisit(a))
        },
        campaignsActions: (state, action) => {
            state.campaignsActions = action.payload.map(campaignAction => new DtoCampaignAction(campaignAction))
        },
        campaignsInstallationWithStats: (state, action) => {
            state.campaignsInstallationWithStats = action.payload.map(campaignInstallationWithStat => new DtoCampaignInstallationWithStats(campaignInstallationWithStat))
        },
        reset: state => {
            state.campaign = {}
            state.campaignProgress = {}
            state.campaignStations = []
            state.campaignOperations = []
            state.campaignParameters = []
            state.campaignPlannings = []
            state.campaignEvents = []
            state.campaignVisits = []
            state.edilaboFiles = []
            state.campaignMaterielsEquipments = []
            state.campaignMaterielsCentrals = []
            state.campaignMaterielsSensors = []
            state.campaignMaterielsSims = []
            state.campaignMaterielsPowerSupplies = []
            state.campaignMaterielsVariousMateriels = []
            state.campaignMaterielsTelecoms = []
            state.campaignsActions = []
            state.campaignsInstallationWithStats = []
        },
    },
    extraReducers: {},
})

export const CampaignActionConstant = store.actions
export default store.reducer