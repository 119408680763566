import { push } from '@lagunovsky/redux-react-router'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import { v4 as uuidv4 } from 'uuid'
import SettingDto from '../../../administration/dto/SettingDto'
import CampaignDto from '../../../campaign/dto/CampaignDto'
import Icon from '../../../components/icon/Icon'
import SieauAction from '../../../components/sieau/SieauAction'
import DtoEvent from '../../../events/dto/DtoEvent'
import DiagnosticActionDto from '../../../events/dto/piezometryDiagnostic/DiagnosticActionDto'
import HomeAction from '../../../home/actions/HomeAction'
import { INSTALLATION } from '../../../home/constants/RouteConstants'
import DtoHydrometricStation from '../../../hydrometry/dto/DtoHydrometricStation'
import InstallationAction from '../../../installation/actions/InstallationAction'
import DtoInstallation from '../../../installation/dto/installation/DtoInstallation'
import DtoInstallationVisit from '../../../installation/dto/installation/DtoInstallationVisit'
import { getEventComment } from '../../../piezometry/components/validation/ValidationEventUtils'
import DtoPiezometerLight from '../../../piezometry/dto/DtoPiezometerLight'
import PluviometerDto from '../../../pluviometry/dto/PluviometerDto'
import DtoQualitometerLight from '../../../quality/dto/DtoQualitometerLight'
import ContactItem from '../../../referencial/components/contact/dto/ContactDto'
import { getDate, getFullDate, getHour } from '../../../utils/DateUtil'
import { round } from '../../../utils/NumberUtil'
import { getSetting } from '../../../utils/SettingUtils'
import { execByType } from '../../../utils/StationUtils'
import moment from 'moment'

const $ = window.$

class EventCard extends Component {
    constructor(props) {
        super(props)
        this.state = { key: uuidv4() }
        this.onPublish = this.onPublish.bind(this)
    }

    componentDidMount() {
        $('.event-tooltip').tooltip()
        if (this.props.installation.typeName === INSTALLATION && this.props.installation.id) {
            this.props.fetchInstallationVisits(this.props.installation.id)
        }
    }

    componentDidUpdate() {
        $('.event-tooltip').tooltip()
    }

    getEventTypeBalise(icon, label) {
        if (this.props.small) {
            return <h6 className='valign-wrapper'><Icon size='small' icon={icon} /> {label}</h6>
        }
        return <h5 className='valign-wrapper center-align'><Icon size='small' icon={icon} /> {label}</h5>
    }

    getContact(code) {
        if (code) {
            return this.props.contacts.find(o => o.code == code)
        }
        return null
    }

    getUpdateInfo = (event) => {
        return event.updateDate && event.updateLogin ? (
            <div className='row no-margin valign-wrapper padding-left-2 font-size-10'>
                {this.props.shortDate ?
                    `${i18n.atDate}${getFullDate(event.updateDate)} ${i18n.by} ${event.updateLogin}` :
                    `${i18n.lastUpdateOn} ${getDate(event.updateDate)} ${i18n.atHour} ${getHour(event.updateDate)} ${i18n.by} ${event.updateLogin}`}
            </div>
        ) : null
    }

    getEventType(event) {
        switch (event.eventType) {
            case 'M':
                return this.getEventTypeBalise('build', i18n.curative)
            case 'G':
                return this.getEventTypeBalise('directions_run', i18n.preventive)
            case 'P':
                return this.getEventTypeBalise('event_note', i18n.toPlan)
            case 'S':
                return this.getEventTypeBalise('visibility', i18n.toMonitor)
            case 'C':
                return this.getEventTypeBalise('comment', i18n.comment)
            case 'T':
                return this.getEventTypeBalise('build', i18n.technique)
            default:
                return this.getEventTypeBalise('more_horiz', i18n.undefinedIntervention)
        }
    }

    getInfos = (event) => {
        if (!this.props.small) {
            const infos = []
            if (event.staticLevel) {
                infos.push(`${i18n.staticLevel}: ${event.staticLevel}`)
            }
            if (event.centralLevel) {
                infos.push(`${i18n.centralLevel}: ${event.centralLevel}`)
            }
            if (event.volt) {
                infos.push(`${i18n.volt}: ${event.volt}V`)
            }
            return infos.join(' - ')
        }
        return ''
    }

    getEventTitle = (event) => {
        return getEventComment(event.comment)
    }

    getContent = (event) => {
        return (<p>{getEventComment(event.comment)}</p>)
    }

    onPublish() {
        const token = getSetting(this.props.settings, 'FACEBOOK')
        if (token && token.value) {
            const message = []
            message.push(this.getEventTitle(this.props.event))
            if (this.props.event.problem || this.props.event.solution) {
                message.push(this.props.event.comment)
            }
            message.push(getDate(this.props.event.date))
            this.props.fbPublisher(message.join(' \n'), token.value)
        }
    }

    openPopup = () => {
        const popup = {
            id: uuidv4(),
            header: i18n.shareTheEvent,
            actions: (<div><a className='waves-effect waves-teal btn-flat modal-close'>{i18n.close}</a></div>),
            content: (
                <div>
                    <h3>{i18n.inDeveloppmentFunctionnality}</h3>
                    {
                        getSetting(this.props.settings, 'FACEBOOK') && (
                            <div className='col s2'>
                                <div className='row no-margin center-align'>
                                    <a className='btn' onClick={this.onPublish}>Publier sur Facebook</a>
                                </div>
                            </div>
                        )
                    }
                </div>
            ),
        }
        this.props.setPopup(popup)
    }

    getCampaign = ({ campaignCode }) => {
        const campaigns = execByType(this.props.stationType, {
            piezometry: () => this.props.piezometryCampaigns,
            pluviometry: () => this.props.pluviometryCampaigns,
            hydrometry: () => this.props.hydrometryCampaigns,
            quality: () => this.props.qualityCampaigns,
            installation: () => this.props.installationCampaigns,
            default: () => [],
        })
        return campaigns.find(({ id }) => id === campaignCode)
    }

    render() {
        const {
            event,
            eventAllPiezometerActions,
            installation,
        } = this.props
        const contact = this.getContact(event.contactCode)
        const obj = {
            ...event,
            date: getDate(event.date),
            hour: getHour(event.eventHour),
        }

        const datetime = `${obj.date} - ${obj.hour}`
        const title = this.props.title ? (<h4>{this.props.title}</h4>) : null
        const ns = event.ns ? <h6>{`${i18n.staticLevel} : ${round(event.ns)}`}</h6> : null

        const elements = this.props.small ? {
            eventTitle: <h6 className='event-title'>{this.getEventTitle(event)}</h6>,
            leftType: this.getEventType(event),
            rightType: null,
        } : {
            eventTitle: <h5 className='event-title'>{this.getEventTitle(event)}</h5>,
            leftType: null,
            rightType: this.getEventType(event),
        }

        const campaign = this.getCampaign(event)
        const eventAction = eventAllPiezometerActions.filter(({ eventCode }) => eventCode === event.id)
        const replacement = eventAction.some(({ solutionCode }) => solutionCode === -101 || solutionCode === -106 || solutionCode === -108)

        const haveTwoSections = elements.rightType && event.contactCode && contact && contact.code

        const isClosed = event.closeDate < moment().valueOf()

        return (
            <div className='event-card'>
                <div className='row no-margin event-content valign-wrapper'>
                    <div className={`col s${haveTwoSections ? 9 : 12} clickable`} onClick={() => {
                        const visit = this.props.installationVisits.find(iv => iv.idCampaign === event.campaignCode)
                        if (visit) {
                            this.props.push(`/installation/campaign/${event.campaignCode}/visit/${installation.id}/1`)
                        } else if (event.campaignCode && installation.id) {
                            this.props.push(`/installation/campaign/${event.campaignCode}/visit/summary/${installation.id}`)
                        } else {
                            this.props.push(this.props.link + event.number)
                        }
                    }}
                    >
                        <div>
                            {title}
                            {elements.eventTitle}
                            <p><i>{datetime}</i></p>
                            {elements.leftType}
                            {ns}
                        </div>
                    </div>
                    {haveTwoSections && (
                        <div className='col s3'>
                            {elements.rightType}
                            {event.contactCode && contact && contact.code && (
                                <div className='row no-margin'>
                                    <div className='clickable event-img-max-size tooltipped event-tooltip' id={this.state.key}
                                        data-position='bottom' data-delay='20' data-tooltip={contact.name}
                                        onClick={() => this.props.push(`/referencial/contact/${event.contactCode}`)}
                                    >
                                        <Icon size='medium' icon='account_circle' />
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                </div>
                <div className='row no-margin event-footer'>
                    <div className='no-padding col s6'>
                        <div className='row no-margin'>
                            {
                                isClosed && (
                                    <div className='col s1 no-padding'>
                                        <Icon size='small' icon='lock' />
                                    </div>
                                )
                            }
                            <div className={`col s${isClosed ? 8 : 9}`}>
                                {this.getUpdateInfo(event)}
                            </div>
                            <div className='col s3'>
                                {this.getInfos(event)}
                            </div>
                        </div>
                    </div>
                    <div className='no-padding col s5 center-align'>
                        {event.graph === '1' && (<Icon size='small' icon='insert_chart' tooltip={i18n.displayOnCharts} />)}
                        {event.bankExport === '1' && (<Icon size='small' icon='launch' tooltip={i18n.eventBankExport} />)}
                        {event.chgtpiles === '1' && (<Icon size='small' icon='battery_std' tooltip={i18n.changeBatteries} />)}
                        {event.razstation === '1' && (<Icon size='small' icon='settings_backup_restore' tooltip={i18n.rebootStation} />)}
                        {event.downloadData && (<Icon size='small' icon='cloud_download' tooltip={i18n.downloadData} />)}
                        {event.transmissionTest && (<Icon size='small' icon='network_check' tooltip={i18n.transmissionTest} />)}
                        {event.replaceDesiccant && (<Icon size='small' icon='invert_colors' tooltip={i18n.replaceDesiccant} />)}
                        {event.firmwareUpdate && (<Icon size='small' icon='system_update_alt' tooltip={i18n.firmwareUpdate} />)}
                        {event.replaceORings && (<Icon size='small' icon='settings_input_component' tooltip={i18n.replaceORings} />)}
                        {event.calibrationUpdate && (<Icon size='small' icon='timer' tooltip={i18n.calibrateDateAndHour} />)}
                        {event.campaignCode && (<Icon size='small' icon='nature_people' tooltip={campaign && campaign.name || i18n.unknown} />)}
                        {replacement && (<Icon size='small' icon='swap_vert' tooltip={i18n.substituteMaterial} />)}
                    </div>
                    <div className='no-padding col s1'>
                        {
                            getSetting(this.props.settings, 'FACEBOOK') && (
                                <a className='tooltipped event-tooltip clickable' data-position='top'
                                    data-delay='20' data-tooltip={i18n.toShare} onClick={this.openPopup}
                                >
                                    <Icon size='small' icon='share' className='event-share' />
                                </a>
                            )
                        }
                    </div>
                </div>
            </div>
        )
    }

    componentWillUnmount() {
        $('.event-tooltip').tooltip('remove')
    }
}

EventCard.propTypes = {
    event: PropTypes.instanceOf(DtoEvent),
    title: PropTypes.string,
    small: PropTypes.boolean,
    shortDate: PropTypes.boolean,
    stationType: PropTypes.string,
    settings: PropTypes.arrayOf(PropTypes.instanceOf(SettingDto)),
    link: PropTypes.string,
    contacts: PropTypes.arrayOf(PropTypes.instanceOf(ContactItem)),
    showStationInfo: PropTypes.bool,
    qualitometers: PropTypes.arrayOf(PropTypes.instanceOf(DtoQualitometerLight)),
    piezometers: PropTypes.arrayOf(PropTypes.instanceOf(DtoPiezometerLight)),
    pluviometers: PropTypes.arrayOf(PropTypes.instanceOf(PluviometerDto)),
    hydrometricStations: PropTypes.arrayOf(PropTypes.instanceOf(DtoHydrometricStation)),
    installation: PropTypes.instanceOf(DtoInstallation),
    installations: PropTypes.arrayOf(PropTypes.instanceOf(DtoInstallation)),
    piezometryCampaigns: PropTypes.arrayOf(PropTypes.instanceOf(CampaignDto)),
    pluviometryCampaigns: PropTypes.arrayOf(PropTypes.instanceOf(CampaignDto)),
    hydrometryCampaigns: PropTypes.arrayOf(PropTypes.instanceOf(CampaignDto)),
    qualityCampaigns: PropTypes.arrayOf(PropTypes.instanceOf(CampaignDto)),
    installationCampaigns: PropTypes.arrayOf(PropTypes.instanceOf(CampaignDto)),
    eventAllPiezometerActions: PropTypes.arrayOf(PropTypes.instanceOf(DiagnosticActionDto)),
    installationVisits: PropTypes.arrayOf(PropTypes.instanceOf(DtoInstallationVisit)),
    fbPublisher: PropTypes.func,
    setPopup: PropTypes.func,
    push: PropTypes.func,
    fetchInstallationVisits: PropTypes.func,
}

EventCard.defaultProps = {
    small: true,
}

const mapStateToProps = store => ({
    settings: store.AdministrationReducer.settings,
    contacts: store.ContactReducer.contacts,
    installation: store.InstallationReducer.installation,
    installations: store.InstallationReducer.installations,
    qualitometers: store.QualityReducer.qualitometersLight,
    piezometers: store.PiezometryReducer.piezometersLight,
    pluviometers: store.PluviometryReducer.pluviometers,
    hydrometricStations: store.HydrometryReducer.hydrometricStations,
    piezometryCampaigns: store.PiezometryReducer.piezometryCampaigns,
    pluviometryCampaigns: store.PluviometryReducer.pluviometryCampaigns,
    hydrometryCampaigns: store.HydrometryReducer.hydrometryCampaigns,
    qualityCampaigns: store.QualityReducer.qualityCampaigns,
    installationCampaigns: store.InstallationReducer.installationCampaigns,
    eventAllPiezometerActions: store.EventsReducer.eventAllPiezometerActions,
    installationVisits: store.InstallationReducer.installationVisits,
})

const mapDispatchToProps = {
    push,
    fbPublisher: HomeAction.fbPublisher,
    setPopup: SieauAction.setPopup,
    fetchInstallationVisits: InstallationAction.fetchInstallationVisits,
}

export default connect(mapStateToProps, mapDispatchToProps)(EventCard)
