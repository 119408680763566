import React, { useEffect, useMemo } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import { orderBy, uniqBy } from 'lodash'
import Table from '../../../components/datatable/Table'
import Row from '../../../components/react/Row'
import ReferencialAction from '../../action/ReferencialAction'
import { nbPerPageLabel } from '../../constants/ReferencialConstants'
import { push } from '@lagunovsky/redux-react-router'
import { setLocalStorageJson } from '../../../utils/FormUtils'
import Card from '../../../components/card/Card'
import Select from '../../../components/forms/Select'
import { getDate } from '../../../utils/DateUtil'
import useTitle from 'utils/customHook/useTitle'
import useActions from 'utils/customHook/useActions'
import useLocalStorage from 'utils/customHook/useLocalStorage'
import Input from 'components/forms/Input'
import { Button } from '@mui/material'
import { searchAllCharacters } from 'utils/StringUtil'

const headers = ['field', 'code', 'name', 'startDate', 'endDate']

const MatTypesApp = () => {
    const {
        materielTypes,
    } = useSelector(store => ({
        materielTypes: store.ReferencialReducer.materielTypes,
    }), shallowEqual)

    const [filter, setFilter] = useLocalStorage('MAT_TYPES_FILTER', {})
    const [filterTmp, setFilterTmp] = useLocalStorage('MAT_TYPES_FILTER', {})

    const dispatch = useDispatch()

    useTitle(() => [{
        title: i18n.referencials,
        href: '/referencial',
    }, {
        title: i18n.materielTypes,
        href: '/referencial/materielTypes',
    }], [])

    useEffect(() => {
        if (!materielTypes.length) {
            dispatch(ReferencialAction.fetchMaterielTypes())
        }
    }, [dispatch, materielTypes.length])

    const filteredList = useMemo(() => filter.selectedField ? materielTypes.filter(matType => matType.field === filter.selectedField) : materielTypes, [filter.selectedField, materielTypes])

    const dataMatTypes = useMemo(() => orderBy(filteredList, ['field', 'code']).map(matType => ({
        ...matType,
        code: `${matType.code}`,
        name: matType.label,
        startDate: getDate(matType.startDate),
        endDate: getDate(matType.endDate),
        headers: ['field', 'code', 'name', 'startDate', 'endDate'],
    })), [filteredList])

    const data = useMemo(() => {
        return dataMatTypes
            .filter((obj) => searchAllCharacters(headers.map((key) => obj[key]).join())
                .includes(searchAllCharacters(filter.searchValue || '')))
    }, [dataMatTypes, filter])

    useActions(() => ({
        new: () => dispatch(push('/referencial/materielType/new/-1/dashboard')),
        referencialActions: {
            actions: {
                export: {
                    data,
                    titleFile: i18n.materielTypes,
                },
            },
        },
    }), [filter])

    const onFilterChange = (newFilter) => {
        setLocalStorageJson('MAT_TYPES_FILTER', newFilter)
        setFilterTmp(newFilter)
    }

    return (
        <>
            <Card noMargin={false} className='margin-left-1 margin-right-1' title={i18n.criterias}>
                <Row className='padding-top-1 padding-bottom-5-px'>
                    <Select
                        col={ 5 }
                        label={ i18n.materielTypes }
                        value={ filterTmp.selectedField }
                        options={ orderBy(uniqBy(materielTypes, 'field'), 'field') }
                        onChange={ v => onFilterChange({ ...filterTmp, selectedField: v }) }
                        nullLabel='&nbsp;'
                        keyValue='field'
                        keyLabel='field'
                    />
                    <Input
                        col={5}
                        title={i18n.search}
                        value={filterTmp.searchValue}
                        onChange={v => setFilterTmp({ ...filterTmp, searchValue: v })}
                        onEnterKeyPress={() => setFilter({ ...filterTmp, searchValue: filterTmp.searchValue })}
                    />
                    <div className='col s2 padding-top-1'>
                        <Button className='right' onClick={() => setFilter({ ...filterTmp, searchValue: filterTmp.searchValue })} variant='contained' color='primary'>
                            {i18n.search}
                        </Button>
                    </div>
                </Row>
            </Card>
            <div className='margin-1 padding-top-5-px referencial-table'>
                <Table
                    title={ i18n.materielTypes }
                    condensed paging searchable
                    nbPerPageLabel={ nbPerPageLabel }
                    filter={ filter.searchValue }
                    onChangeFilter={ value => onFilterChange({ ...filter, searchValue: value }) }
                    data={ data }
                    type={ { headers } }
                    sortable={ !!materielTypes.length }
                    onClick={ element => dispatch(push(`/referencial/materielType/${element.field}/${element.code}/dashboard`)) }
                />
            </div>
        </>
    )
}

export default MatTypesApp