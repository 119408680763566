import { HydrometryActionConstant } from 'hydrometry/reducers/HydrometryReducer'
import fetch from 'isomorphic-fetch'
import moment from 'moment'
import i18n from 'simple-react-i18n'
import ToastrAction from 'toastr/actions/ToastrAction'
import WaitAction from 'wait/WaitAction'
import ApplicationConf from '../../conf/ApplicationConf'
import HydrometryAction from '../../hydrometry/actions/HydrometryAction'
import LogAction from '../../log/actions/LogAction'
import {
    checkAuth,
    checkError,
    fetchWithRetries,
    getAuthorization,
    getJson,
    promiseAllProgress,
} from '../../utils/ActionUtils'
import {
    RECEIVE_HYDROMETER_CHART_OPTIONS,
    RECEIVE_HYDROMETRIC_ADDITIONAL_MEASURES,
    RECEIVE_HYDROMETRIC_STATION_FILES,
    RECEIVE_HYDROMETRIC_STATION_FLOW,
    RECEIVE_HYDROMETRIC_STATION_PICTURES,
    RESET_HYDROMETER_STATION, UPDATE_HYDROMETER_CHART_OPTIONS,
} from '../constants/hydro/HydrometerStationConstants'
import DtoHydrometryStationMeasure from '../dto/hydrometricStation/DtoHydrometryStationMeasure'
import { hasValue } from '../../utils/NumberUtil'

const hardCodedPictures = []

const hardCodedFiles = []

const HydrometerStationAction = {
    receiveHydrometricStationFlow(flowMeasures) {
        return { type: RECEIVE_HYDROMETRIC_STATION_FLOW, flowMeasures }
    },
    promiseHydrometricStationFlow(stationId, startDate, endDate = moment().valueOf()) {
        return fetch(ApplicationConf.hydrometricStation.hydrometricStationFlow(stationId, startDate, endDate), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
    },
    fetchHydrometricStationFlow(stationId, startDate = undefined, endDate = moment().valueOf(), callback = () => {}) {
        return (dispatch) => {
            return HydrometerStationAction.promiseHydrometricStationFlow(stationId, startDate, endDate)
                .then((json = []) => {
                    dispatch(HydrometerStationAction.receiveHydrometricStationFlow(json))
                    callback()
                })
                .catch((err) => {
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.hydrometricStationMeasures} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError + i18n.hydrometricStationMeasures))
                })
        }
    },

    receiveHydrologicalAdditionalMeasures(hydrologicalAdditionalMeasures) {
        return { type: RECEIVE_HYDROMETRIC_ADDITIONAL_MEASURES, hydrologicalAdditionalMeasures }
    },
    promiseHydrologicalAdditionalMeasures(hydroId, type, dates = {}) {
        return fetchWithRetries(ApplicationConf.hydrometricStation.hydrometrciStationTypeMeasures(hydroId, type), {
            method: 'POST',
            headers: getAuthorization(),
            body: JSON.stringify(dates),
        }).then(checkAuth).then(getJson).then(checkError)
    },
    fetchHydrologicalAdditionalMeasures(hydroId, types, dates = {}, callback = () => {}) {
        return function (dispatch) {
            return Promise.all(types.map(t => HydrometerStationAction.promiseHydrologicalAdditionalMeasures(hydroId, t, dates)))
                .then(jsonTab => {
                    const result = types.map((t, index) => ({ type: t, measures: jsonTab[index] }))
                    dispatch(HydrometerStationAction.receiveHydrologicalAdditionalMeasures(result))
                    callback()
                }).catch((err) => {
                    dispatch(WaitAction.waitStop())
                    dispatch(LogAction.logError(`${i18n.additionalData} : ${err}`))
                    dispatch(ToastrAction.error(i18n.additionalData))
                })
        }
    },

    fetchHydrometricStationListFlow: (stationId, startDate = undefined, endDate = Date.now()) => () => {
        const listPromise = stationId.map(id => HydrometerStationAction.promiseHydrometricStationFlow(id, startDate, endDate).then((measure = []) => ({ id, measure: measure.map(m => new DtoHydrometryStationMeasure(m)) })))
        return Promise.all(listPromise)
    },
    loadHydrometerMeasures(stationId, dates, progressCallback = () => {}, callback = () => {}) {
        return (dispatch) => {
            return promiseAllProgress([
                HydrometryAction.promiseHydrometryBruteMeasures(stationId, dates),
            ], progressCallback).then(jsonTab => {
                dispatch(HydrometryActionConstant.receiveHydrometryBruteMeasures(jsonTab[0]))
                callback()
            })
        }
    },
    receiveHydrometricStationPictures(pictures) {
        return { type: RECEIVE_HYDROMETRIC_STATION_PICTURES, pictures }
    },
    fetchHydrometricStationPictures() {
        return (dispatch) => {
            // TODO: fetch real pictures in link with station with ID :  stationId)
            dispatch(HydrometerStationAction.receiveHydrometricStationPictures(hardCodedPictures))
        }
    },

    receiveHydrometerChartOptions(chartOptions) {
        return { type: RECEIVE_HYDROMETER_CHART_OPTIONS, chartOptions }
    },
    promiseHydrometerChartOptions(stationId) {
        return fetch(ApplicationConf.hydrometricStation.chartOptions(stationId), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
            .then(tab => tab.map(dt => ({ ...dt, dataType: hasValue(dt.dataType) ? dt.dataType : '-1' })))
    },

    fetchHydrometerChartOptions: (stationId, callback = () => {}) => dispatch => {
        dispatch(WaitAction.waitStart())
        return HydrometerStationAction.promiseHydrometerChartOptions(stationId)
            .then((json = []) => {
                dispatch(HydrometerStationAction.receiveHydrometerChartOptions(json))
                dispatch(WaitAction.waitStop())
                callback(json)
            })
            .catch(err => {
                dispatch(WaitAction.waitStop())
                dispatch(LogAction.logError(`${i18n.fetchError + i18n.hydrometryOptions} : ${err}`))
                dispatch(ToastrAction.error(i18n.fetchError + i18n.hydrometryOptions))
            })
    },
    updateHydrometerChartOptions(stationId, options = [], callback = () => {}) {
        return (dispatch) => {
            dispatch(WaitAction.waitStart())
            return fetch(ApplicationConf.hydrometricStation.chartOptions(stationId), {
                method: 'PUT',
                headers: getAuthorization(),
                body: JSON.stringify(options),
            }).then(checkAuth).then(getJson).then(checkError)
                .then(json => {
                    if (json.update === options.length) {
                        dispatch({ type: UPDATE_HYDROMETER_CHART_OPTIONS, chartOptions: options })
                        dispatch(WaitAction.waitStop())
                        dispatch(ToastrAction.success(i18n.elementUpdateSuccess))
                        callback()
                    } else {
                        throw new Error(json)
                    }
                }).catch((err) => {
                    dispatch(WaitAction.waitStop())
                    dispatch(LogAction.logError(`${i18n.updateError + i18n.hydrometryOptions} : ${err}`))
                    dispatch(ToastrAction.error(i18n.updateError + i18n.hydrometryOptions))
                })
        }
    },
    receiveHydrometricStationFiles(files) {
        return { type: RECEIVE_HYDROMETRIC_STATION_FILES, files }
    },
    fetchHydrometricStationFiles() {
        return (dispatch) => {
            // TODO: fetch real files in link with station with ID : stationId
            dispatch(HydrometerStationAction.receiveHydrometricStationFiles(hardCodedFiles))
        }
    },
    reset () {
        return {
            type: RESET_HYDROMETER_STATION,
        }
    },
}

export default HydrometerStationAction
