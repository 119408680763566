import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { compact, groupBy, isNil, keys, maxBy, min, minBy, round, uniq, uniqBy } from 'lodash'
import moment from 'moment'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import HydrometryAction from 'hydrometry/actions/HydrometryAction'
import EventsAction from 'events/actions/EventsAction'
import { Grid, Popover } from '@mui/material'
import ProgressCard from 'components/card/ProgressCard'
import { WhiteCard } from 'components/styled/Card'
import { STATION_TYPE_NAME } from 'station/constants/StationConstants'
import MultiChart from 'components/echart/MultiChart'
import DtoHydrometricStation from 'hydrometry/dto/DtoHydrometricStation'
import DtoEvent from 'events/dto/DtoEvent'
import useProgressDispatch from 'utils/customHook/useProgressDispatch'
import { getDate, getDateWithHour, getDayDiff, getFullDate } from 'utils/DateUtil'
import { getColorFromPalette2, getEventColor, getRGBColor } from 'utils/ColorUtil'
import PluviometryAction from 'pluviometry/actions/PluviometryAction'
import PluviometerDto from 'pluviometry/dto/PluviometerDto'
import DtoHydroMeasures from 'hydrometry/dto/chronicMeasures/DtoHydroMeasures'
import DtoPluvioMeasures from 'pluviometry/dto/measures/DtoPluvioMeasures'
import { DATA_TYPE } from 'pluviometry/constants/PluviometryConstant'
import { HYDROMETER_HEIGHT_TYPE } from 'hydrometry/constants/HydrometryConstants'
import DtoHydroStats from 'hydrometry/dto/chronicMeasures/DtoHydroStats'
import DtoPluviometerStats from 'pluviometry/dto/DtoPluviometerStats'
import { renderToString } from 'react-dom/server'
import { statusIcon } from 'utils/StatusUtil'
import InstallationAction from 'installation/actions/InstallationAction'
import DtoInstallationAnalysisMeasureLight from 'installation/dto/installation/DtoInstallationAnalysisMeasureLight'
import DtoInstallationAnalysisMeasures from 'installation/dto/installation/DtoInstallationAnalysisMeasures'
import AdministrationAction from 'administration/actions/AdministrationAction'
import Icon from 'components/icon/Icon'
import SuperMultiAutocomplete from 'components/forms/SuperMultiAutocomplete'
import { getI18nOrLabel } from 'utils/StringUtil'
import { INSTALLATION_TYPES, INSTALLATION_TYPE } from 'installation/constants/InstallationConstants'
import { ButtonMUI } from 'components/styled/Buttons'
import GlobalParametersDto from 'administration/dto/GlobalParameterDto'
import { SIEAU } from 'administration/components/user/constants/StatisticConstants'
import StationAction from 'station/actions/StationAction'
import { getStationTypeCodeFromType } from 'utils/StationUtils'
import { getAxisIntervalFormatter, getBarWidth, getChartDate, getSubstractTime } from 'components/echart/EChartUtils'
import { getHardHydroDataTypes } from 'utils/HydroUtils'
import Card from 'components/card/Card'
import DtoParametrageDataType from 'piezometry/dto/DtoParametrageDataType'
import { hasBooleanValue } from 'utils/NumberUtil'
import { getLabel } from 'utils/StoreUtils'
import ParameterAction from 'referencial/components/parameter/actions/ParameterAction'
import { BROWN_COLORS } from 'components/constants/ColorConstant'
import ParameterDto from 'referencial/components/parameter/dto/ParameterDto'
import { EVENT_TYPES_CODES } from 'events/constants/EventsConstants'
import { InstallationActionConstant } from 'installation/reducers/InstallationReducer'
import MUIChartTabs from 'components/echart/MUIChartTabs'
import useAccountSetting from 'utils/customHook/useAccountSetting'
import { CHART_SELECTED_TIME } from 'quality/constants/ChartConstant'
import { HISTO, J90 } from 'alerting/constants/ChartFollowContants'

const PARAMETER_CODE_IIBSN = '98008' // Intended for the time, waiting for the evolution
const PARAMETER_CODE_VALVE_LEVEL = '98014' // Intended for the time, waiting for the evolution
const PARAMETER_CODE_FISH_PASS_LEVEL = '98021' // Intended for the time, waiting for the evolution

const IIBSN_HYDRO_PARAMETERS = [PARAMETER_CODE_IIBSN, PARAMETER_CODE_VALVE_LEVEL, PARAMETER_CODE_FISH_PASS_LEVEL]

const INSTALLATION_TYPE_PARAMETER = 'ROE_INSTALLATIONS_TYPE'
const DATA_TYPE_PARAMETER = 'ROE_INSTALLATIONS_VARIABLES'
const INSTALLATION_TYPES_ACCEPTED = [INSTALLATION_TYPE.STRAIT, INSTALLATION_TYPE.ELEC_COUNTER, INSTALLATION_TYPE.SLUICE, INSTALLATION_TYPE.OTHERS]
const DATA_TYPES_ACCEPTED = [HYDROMETER_HEIGHT_TYPE]

const InstallationKeyFigurePanel = ({
    hydroMeasures = [],
    pluvioMeasures = [],
    instMeasures = [],
    hydroDataTypes = [],
    pluvioDataTypes = [],
    instParameters = [],
}) => {
    const headers = [i18n.statistics, i18n.begin, i18n.end, i18n.nbMeasures]

    const hydroGroupedMeasures = groupBy(hydroMeasures, 'dataType')

    const hydroHistory = keys(hydroGroupedMeasures).filter(key => hydroDataTypes.find(t => t.typeId === parseInt(key)).displayData || !hasBooleanValue(hydroDataTypes.find(t => t.typeId === parseInt(key)).displayData)).map(key => {
        const stat = hydroDataTypes.find(t => t.typeId === parseInt(key))
        const datas = hydroGroupedMeasures[key]

        const measures = datas.flatMap(i => i.measures)

        const startDate = stat.startDate ? getDate(stat.startDate) : getDate(minBy(measures, 'date')?.date)
        const endDate = stat.endDate ? getDate(stat.endDate) : getDate(maxBy(measures, 'date')?.date)
        const nbOperation = stat.allMeasuresCount || uniqBy(measures, 'date').length || ''

        return {
            title: getLabel(hydroDataTypes, parseInt(key), null, 'typeId'),
            begin: startDate || '',
            end: endDate || '',
            nbOperation,
        }
    }).filter(s => s.nbOperation && s.nbOperation !== 0)

    const pluvioGroupedMeasures = groupBy(pluvioMeasures, 'dataType')

    const pluvioHistory = keys(pluvioGroupedMeasures).filter(key => pluvioDataTypes.find(t => t.typeId === parseInt(key)).displayData || !hasBooleanValue(pluvioDataTypes.find(t => t.typeId === parseInt(key)).displayData)).map(key => {
        const datas = pluvioGroupedMeasures[key]

        const minDate = minBy(datas, 'startDate')
        const maxDate = minBy(datas, 'endDate')
        const measures = datas.flatMap(i => i.measures)

        const startDate = minDate ? getDate(minDate) : getDate(minBy(measures, 'date')?.date)
        const endDate = maxDate ? getDate(maxDate) : getDate(maxBy(measures, 'date')?.date)
        const nbOperation = uniqBy(measures, 'date').length || ''

        return {
            title: getLabel(pluvioDataTypes, parseInt(key), null, 'typeId'),
            begin: startDate || '',
            end: endDate || '',
            nbOperation,
        }
    }).filter(s => s.nbOperation && s.nbOperation !== 0)

    const instGroupedMeasures = groupBy(instMeasures, 'parameterCode')

    const instHistory = keys(instGroupedMeasures).filter(key => instParameters.find(p => p.code === key).displayData || !hasBooleanValue(instParameters.find(p => p.code === key).displayData)).map(key => {
        const stat = instParameters.find(p => p.code === key)
        const datas = instGroupedMeasures[key]

        const measures = datas.flatMap(i => i.measures)

        const startDate = stat.startDate ? getDate(stat.startDate) : getDate(minBy(measures, 'date')?.date)
        const endDate = stat.endDate ? getDate(stat.endDate) : getDate(maxBy(measures, 'date')?.date)
        const nbOperation = stat.allMeasuresCount || uniqBy(measures, 'date').length || ''

        return {
            title: getLabel(instParameters, key, null, 'code'),
            begin: startDate || '',
            end: endDate || '',
            nbOperation,
        }
    }).filter(s => s.nbOperation && s.nbOperation !== 0)

    const history = [...hydroHistory, ...pluvioHistory, ...instHistory]

    const tableHeader = headers.map((o, i) => (
        <th
            className={`${i === 0 ? 'left' : 'center'}`}
            style={{
                color: 'black',
                lineHeight: '25px',
                padding: i===0 ? '5 0 5 25' : 5,
                fontSize: i === 0 ? '18px' : '16px',
            }}
        >
            {o}
        </th>
    ))

    const tableContent = history.map(hist => (
        <tr style={{ fontSize: '14.3px', lineHeight: '20px' }}>
            {keys(hist).map((key, index) => {
                if (index === 0) {
                    return (
                        <td className='right right-align bold '>
                            {hist[key]}
                        </td>
                    )
                }
                return (
                    <td className='center'>
                        {hist[key]}
                    </td>
                )
            })}
        </tr>
    ))

    return (
        <Card round>
            <table className='table condensed'>
                <thead>
                    <tr>{tableHeader}</tr>
                </thead>
                {!!history.length && (
                    <tbody>
                        {tableContent}
                    </tbody>
                )}
            </table>
            {!history.length && (
                <Grid className='text-align-center' style={{ fontSize: 14.3, color: 'black', verticalAlign: 'middle' }}>
                    <Icon style={{ fontSize: 50 }}>cloud_off</Icon>
                    <Grid>{i18n.noDataToDisplay}</Grid>
                </Grid>
            )}
        </Card >
    )
}

InstallationKeyFigurePanel.propTypes = {
    hydroMeasures: PropTypes.arrayOf(PropTypes.instanceOf(DtoHydroMeasures)),
    pluvioMeasures: PropTypes.arrayOf(PropTypes.instanceOf(DtoPluvioMeasures)),
    instMeasures: PropTypes.arrayOf(PropTypes.instanceOf(DtoInstallationAnalysisMeasures)),
    hydroDataTypes: PropTypes.arrayOf(PropTypes.instanceOf(DtoParametrageDataType)),
    pluvioDataTypes: PropTypes.arrayOf(PropTypes.instanceOf(DtoParametrageDataType)),
    instParameters: PropTypes.arrayOf(PropTypes.instanceOf(ParameterDto)),
}

const FlowObstructionChartOptions = ({
    hydroStats = [],
    setInstallationTypesChart = () => {},
    setDataTypesChart = () => {},
}) => {
    const {
        globalParameters,
    } = useSelector(store => ({
        globalParameters: store.AdministrationReducer.globalParameters,
    }), shallowEqual)

    const defaultInstallationTypes = useMemo(() => {
        const value = globalParameters.find(param => param.parameter === INSTALLATION_TYPE_PARAMETER)?.value
        return !isNil(value) ? value.split(',').map(Number) : [INSTALLATION_TYPE.STRAIT]
    }, [globalParameters])

    const defaultDataTypes = useMemo(() => {
        const value = globalParameters.find(param => param.parameter === DATA_TYPE_PARAMETER)?.value
        return !isNil(value) ? value.split(',').map(Number) : [HYDROMETER_HEIGHT_TYPE]
    }, [globalParameters])

    const [chartEl, setChartEl] = useState()
    const [installationTypes, setInstallationTypes] = useState(defaultInstallationTypes)
    const [dataTypes, setDataTypes] = useState(defaultDataTypes)

    const installationTypesOptions = useMemo(() => INSTALLATION_TYPES_ACCEPTED.map(instType => {
        const installationType = INSTALLATION_TYPES.find(type => type.code === instType) || {}
        return { code: installationType.code, name: getI18nOrLabel(installationType.label) }
    }), [])

    const dataTypesOptions = useMemo(() => uniqBy(hydroStats, 'typeId').filter(stat => DATA_TYPES_ACCEPTED.includes(stat.typeId)).map(stat => ({ code: stat.typeId, name: stat.label })), [hydroStats])

    const onValidate = () => {
        setInstallationTypesChart(installationTypes)
        setDataTypesChart(dataTypes)
        if (installationTypes !== defaultInstallationTypes) {
            setInstallationTypesChart(installationTypes)
        }
        if (dataTypes !== defaultDataTypes) {
            setDataTypesChart(dataTypes)
        }
        setChartEl(undefined)
    }

    const onCancel = () => {
        setInstallationTypes(defaultInstallationTypes)
        setDataTypes(defaultDataTypes)

        if (installationTypes !== defaultInstallationTypes) {
            setInstallationTypesChart(defaultInstallationTypes)
        }
        if (dataTypes !== defaultDataTypes) {
            setDataTypesChart(defaultDataTypes)
        }
        setChartEl(undefined)
    }

    return (
        <>
            <Grid item onClick={event => setChartEl(event.currentTarget)}>
                <Icon
                    tooltip={ i18n.chartOptions }
                    icon={'settings'}
                />
            </Grid>
            <Popover
                open={!!chartEl}
                anchorEl={chartEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                sx={{ borderRadius: '8px' }}
                onClose={() => setChartEl(undefined)}
            >
                <Grid container sx={{ padding: '10px', width: '450px', boxShadow: '0px 3px 16px -5px rgb(0 0 0 / 50%)' }}>
                    <Grid item xs={12}>
                        <h5 className='center'>{ i18n.chartOptions }</h5>
                    </Grid>
                    <Grid item xs={12} sx={{ paddingTop: '10px' }}>
                        <SuperMultiAutocomplete
                            col={12}
                            options={installationTypesOptions}
                            values={installationTypes}
                            label={i18n.installationsTypes}
                            onChange={setInstallationTypes}
                            keyValue='code'
                            keyLabel='name'
                            multiple
                        />
                    </Grid>
                    <Grid item xs={12} sx={{ paddingTop: '10px' }}>
                        <SuperMultiAutocomplete
                            col={12}
                            options={dataTypesOptions}
                            values={dataTypes}
                            label={i18n.dataTypes}
                            onChange={setDataTypes}
                            keyValue='code'
                            keyLabel='name'
                            multiple
                        />
                    </Grid>
                    <Grid container item xs={12} justifyContent='flex-end' sx={{ marginTop: '30px' }}>
                        <Grid item>
                            <ButtonMUI variant='outlined' sx={{ marginRight: 1 }} onClick={onCancel}>
                                {i18n.cancel}
                            </ButtonMUI>
                        </Grid>
                        <Grid item>
                            <ButtonMUI variant='contained' onClick={onValidate}>
                                {i18n.validate}
                            </ButtonMUI>
                        </Grid>
                    </Grid>
                </Grid>
            </Popover>
        </>
    )
}
FlowObstructionChartOptions.propTypes = {
    hydroStats: PropTypes.arrayOf(PropTypes.instanceOf(DtoHydroStats)),
    setInstallationTypesChart: PropTypes.func,
    setDataTypesChart: PropTypes.func,
    showTitle: PropTypes.bool,
}

const INDEX_HYDRO_HEIGHT = 1
const PLUVIO_GROUPED = 'SUM_AUTO'
const ROUND_VALUE = 3

const FlowObstructionMultiGraph = ({
    stationId,
    hydroStats = [],
    pluvioStats = [],
    graphicHeight = 250,
    stationsEvents = [],
    hydroMeasures = [],
    pluvioMeasures = [],
    instMeasures = [],
    hydroIds = [],
    pluvioIds = [],
    onFullScreen = () => {},
    defaultMinDate,
    defaultMaxDate,
}) => {
    const {
        accountUser,
        hydrometricStations,
        pluviometers,
        installationsLight,
        hydrometryThresholds,
        pluviometerAllThresholds,
        userSettings,
        installation,
        parameters,
    } = useSelector(store => ({
        accountUser: store.AccountReducer.accountUser,
        hydrometricStations: store.HydrometryReducer.hydrometricStations,
        pluviometers: store.PluviometryReducer.pluviometers,
        installationsLight: store.InstallationReducer.installationsLight,
        hydrometryThresholds: store.HydrometryReducer.hydrometryThresholds,
        pluviometerAllThresholds: store.PluviometryReducer.pluviometerAllThresholds,
        userSettings: store.AccountReducer.accountUserSettings,
        installation: store.InstallationReducer.installation,
        parameters: store.ParameterReducer.parameters,
    }), shallowEqual)

    const dispatch = useDispatch()

    const legendParameterName = `selectedLegend_${STATION_TYPE_NAME.installation}_${stationId}`

    useEffect(() => {
        dispatch(AdministrationAction.fetchUserSettings(accountUser.login))
    }, [])

    const { isLoaded: parametersLoaded } = useProgressDispatch(() => [ dispatch(AdministrationAction.fetchUserSettings(accountUser.login)) ], [])

    const legendSettingValue = userSettings.find(setting => setting.parameter === legendParameterName)?.value
    const legendSetting = legendSettingValue && JSON.parse(legendSettingValue)

    const events = stationsEvents.filter(e => {
        if (e.eventType === 'T') {
            return false
        }
        if (e.date) {
            return e.graph === '1' || e.typeName === STATION_TYPE_NAME.installation
        }
        return false
    })

    const eventsFormatted = useMemo(() => {
        const eventsToWatch = events.filter(e => e.eventType === EVENT_TYPES_CODES.TO_MONITOR)

        const dataCustomToWatch = eventsToWatch.map(e => {
            const startDate = e.startDate || getDateWithHour(e.date, e.eventHour).valueOf()
            const endDate = e.endDate || moment().add(1, 'days').valueOf()
            return {
                value: [startDate, endDate, e],
                itemStyle: { color: getRGBColor(getEventColor(e.eventType)) },
            }
        })

        const dataBar = events.map(e => ({
            date: e.startDate || getDateWithHour(e.date, e.eventHour).valueOf(),
            value: 1,
            color: getRGBColor(getEventColor(e.eventType)),
        }))

        return [{
            gridIndex: 0,
            name: i18n.events,
            serieId: 'events',
            gridName: i18n.events,
            dataList: [],
            type: 'custom',
            overrideSerie: {
                data: dataCustomToWatch,
                tooltip: {
                    trigger: 'item',
                    formatter: params => {
                        const { marker, seriesName, value: [startDate,, statusObj], data: { unit = '' } } = params
                        return `${getFullDate(startDate)}<br/>${marker} ${seriesName}: 1 ${unit} <div style="display: inline-grid; vertical-align: middle;">${renderToString(statusIcon(statusObj, 20))}</div>`
                    },
                },
                itemStyle: {
                    normal: {
                        opacity: 1,
                    },
                },
                areaStyle: {
                    normal: {},
                },
                renderItem: (_, api) => {
                    const start = api.coord([api.value(0), 0])
                    const end = api.coord([api.value(1), 0])
                    const height = api.size([0, 1])[1]
                    return {
                        type: 'rect',
                        shape: {
                            x: start[0],
                            y: start[1] - height,
                            width: end[0] - start[0],
                            height,
                        },
                        style: api.style(),
                    }
                },
            },
        }, {
            gridIndex: 0,
            name: i18n.events,
            serieId: 'events',
            gridName: i18n.events,
            dataList: dataBar,
            type: 'bar',
            barWidth: '10px',
        }]
    }, [events])

    const filteredHydroMeasures = hydroMeasures.filter(h => h.measures.length)
    const hydroGroupedMeasures = groupBy(filteredHydroMeasures, 'dataType')

    const now = moment()

    // TODO => To be upgraded with a settings screen when other customers use this screen
    const getLineStyle = (endDate, dataTypeName) => {
        if ((!isNil(endDate) && moment(endDate).isAfter(now)) || (dataTypeName.toLowerCase() === 'consigne') || (dataTypeName.toLowerCase() === 'noed') || (dataTypeName.toLowerCase() === 'noef')) {
            return 'dashed'
        }
        if (dataTypeName.toLowerCase() === 'objectif') {
            return 'dotted'
        }
        return 'solid'
    }

    // TODO => To be upgraded with a settings screen when other customers use this screen
    const getLineColor = (index, dataType, dataTypeName, stationCode, typeName = STATION_TYPE_NAME.hydrometry, valueIndex = 0) => {
        if (dataTypeName.toLowerCase() === 'consigne') {
            return 'black'
        }
        if (dataTypeName.toLowerCase() === 'plafond') {
            return 'purple'
        }
        if (dataTypeName.toLowerCase() === 'objectif') {
            return 'orange'
        }
        if (dataTypeName.toLowerCase() === 'plancher') {
            return 'yellow'
        }
        if (stationCode.includes('_AM')) {
            return 'green'
        }
        if (stationCode.includes('_AV')) {
            return 'red'
        }
        if ((dataType === HYDROMETER_HEIGHT_TYPE) && (typeName === STATION_TYPE_NAME.installation)) {
            return BROWN_COLORS[valueIndex] || 'brown'
        }
        if (dataTypeName.toLowerCase() === 'noed') {
            return 'royalblue'
        }
        if (dataTypeName.toLowerCase() === 'noef') {
            return 'lightblue'
        }
        if (dataType === HYDROMETER_HEIGHT_TYPE) {
            return 'blue'
        }
        return getColorFromPalette2(index)
    }

    const getArea = (dataTypeName) => {
        if (dataTypeName.toLowerCase() === 'plafond') {
            return {
                areaStyle: {
                    origin: 'end',
                    opacity: 1,
                    color: '#97aacb',
                },
            }
        }
        if (dataTypeName.toLowerCase() === 'plancher') {
            return {
                areaStyle: {
                    origin: 'start',
                    opacity: 1,
                    color: '#ff7f7f',
                },
            }
        }
        return {}
    }

    const hydroFormatted = useMemo(() => hydroStats.length ? keys(hydroGroupedMeasures).flatMap((key, index) => {
        const values = hydroGroupedMeasures[key]
        return values.flatMap(value => {
            const station = hydrometricStations.find(a => a.id === value.stationId)
            const stationName = station?.name || ''
            const hydroStat = hydroStats.find(h => h.typeId === value.dataType)
            const unit = hydroStat?.unit ? `(${hydroStat?.unit}) ` : ''

            const gridName = hydroStat?.label || key
            const gridIndex = (parseInt(value.dataType) === HYDROMETER_HEIGHT_TYPE || hydroStat.chronicFollowUp) ? INDEX_HYDRO_HEIGHT : index + 1
            const name = `${stationName}${hydroStat.label && ` - ${hydroStat.label}` || ''}`
            const lineStyle = getLineStyle(hydroStat.endDate, gridName)
            const color = getLineColor(index, parseInt(value.dataType), gridName, station?.code, STATION_TYPE_NAME.hydrometry)
            const dataList = value.measures
            const sampling = 'lttb'

            if (gridName.toLowerCase() === 'objectif') {
                return [{
                    gridIndex,
                    name,
                    unit,
                    lineStyle,
                    color,
                    gridName,
                    dataList,
                    sampling,
                    z: -1,
                    areaStyle: {
                        origin: 'end',
                        opacity: 1,
                        color: '#ecf1f9',
                    },
                }, {
                    gridIndex,
                    name,
                    unit,
                    lineStyle,
                    color,
                    gridName,
                    dataList,
                    sampling,
                    z: -1,
                    areaStyle: {
                        origin: 'start',
                        opacity: 1,
                        color: '#f8c9a9',
                    },
                }]
            }

            const area = getArea(hydroStat.label)

            return [{
                gridIndex,
                name,
                unit,
                lineStyle,
                color,
                gridName,
                dataList,
                sampling,
                ...area,
            }]
        })
    }) : [], [hydroGroupedMeasures, hydrometricStations, hydroStats])

    const hydroKeysFiltered = useMemo(() => hydroStats.length ? [`${HYDROMETER_HEIGHT_TYPE}`, ...keys(hydroGroupedMeasures).filter(key => {
        const dataType = parseInt(key)
        return dataType === !hydroStats.find(stat => stat.typeId === dataType)?.chronicFollowUp
    })] : [], [hydroGroupedMeasures, hydroStats])

    const filteredInstMeasures = instMeasures.filter(i => i.measures.length)
    const instGroupedMeasures = useMemo(() => groupBy(filteredInstMeasures, 'parameterCode'), [filteredInstMeasures])
    const instSortedKeys = useMemo(() => [ ...keys(instGroupedMeasures).filter(key => key === PARAMETER_CODE_IIBSN), ...keys(instGroupedMeasures).filter(key => IIBSN_HYDRO_PARAMETERS.includes(key) && key !== PARAMETER_CODE_IIBSN), ...keys(instGroupedMeasures).filter(key => !IIBSN_HYDRO_PARAMETERS.includes(key)) ], [instGroupedMeasures])
    const nbStraitValues = instSortedKeys.filter(key => IIBSN_HYDRO_PARAMETERS.includes(key)).length
    const nbHydroGrids = keys(groupBy(hydroFormatted, 'gridIndex')).length || (nbStraitValues ? 1 : 0)

    const filteredPluvioMeasures = pluvioMeasures.filter(p => p.measures.length)
    const pluvioGroupedMeasures = groupBy(filteredPluvioMeasures, 'dataType')

    const pluvioFormatted = useMemo(() => keys(pluvioGroupedMeasures).flatMap((key, index) => {
        const values = pluvioGroupedMeasures[key]
        return values.map(value => {
            const stationName = pluviometers.find(a => a.id === value.stationId)?.name
            const pluvioStat = pluvioStats.find(h => h.typeId === value.dataType)
            const unit = pluvioStat?.unit ? `(${pluvioStat?.unit}) ` : ''
            return {
                gridIndex: index + (nbHydroGrids + 1),
                name: stationName,
                unit,
                color: 'blue',
                gridName: pluvioStat?.label || key,
                dataList: value.measures,
                type: 'bar',
                connectNulls: false,
                barWidth: getBarWidth(value.measures.length),
            }
        })
    }), [pluvioGroupedMeasures, pluviometers, pluvioStats, nbHydroGrids])

    const nbPluvioGrids = keys(groupBy(pluvioFormatted, 'gridIndex')).length

    const instFormatted = useMemo(() => hydroStats.length ? instSortedKeys.flatMap((key, index) => {
        const values = instGroupedMeasures[key]
        return values.map((value, valueIndex) => {
            const station = installationsLight.find(a => a.id === value.stationId)
            const stationName = station?.name || ''
            const parameter = parameters.find(p => p.code === key)
            const hydroStat = hydroStats.find(h => h.typeId === value.dataType)
            const unit = hydroStat?.unit ? `(${hydroStat?.unit}) ` : ''
            return {
                gridIndex: IIBSN_HYDRO_PARAMETERS.includes(key) ? INDEX_HYDRO_HEIGHT : (index + (nbHydroGrids + nbPluvioGrids + 1) - nbStraitValues),
                name: `${stationName}${parameter?.name && ` - ${parameter?.name}` || ''}`,
                unit,
                lineStyle: getLineStyle(hydroStat.endDate, hydroStat.label),
                color: getLineColor(index, parseInt(value.dataType), hydroStat.label, station?.code, STATION_TYPE_NAME.installation, valueIndex),
                gridName: parameter?.name || key,
                dataList: value.measures,
                sampling: 'lttb',
                parameterCode: key,
            }
        })
    }) : [], [hydroStats, instSortedKeys, instGroupedMeasures, installationsLight, parameters, nbHydroGrids, nbPluvioGrids, nbStraitValues])

    const dataFormatted = [...eventsFormatted, ...hydroFormatted, ...pluvioFormatted, ...instFormatted]

    const hydroDataTypesId = useMemo(() => hydroStats.map(stat => stat.typeId), [hydroStats])

    const hydroThresholds = hydroStats.length && hydroFormatted.length ? uniqBy(hydrometryThresholds.filter(t => hydroIds.includes(parseInt(t.stationId)) && hydroDataTypesId.includes(parseInt(t.dataType))), 'id').map((t, index) => {
        const hydroStat = hydroStats.find(h => h.typeId === parseInt(t.dataType))
        const gridIndex = (parseInt(t.dataType) === HYDROMETER_HEIGHT_TYPE || hydroStat.chronicFollowUp) ? INDEX_HYDRO_HEIGHT : index + 1
        return {
            ...t,
            gridName: hydroStat?.label || t.dataType,
            gridIndex,
        }
    }) : []

    const pluvioDataTypesId = useMemo(() => pluvioStats.map(stat => stat.typeId), [pluvioStats])

    const pluvioThresholds = pluvioFormatted ? uniqBy(pluviometerAllThresholds.filter(t => pluvioIds.includes(parseInt(t.stationId)) && pluvioDataTypesId.includes(parseInt(t.dataType))), 'id').map((t, index) => {
        const pluvioStat = pluvioStats.find(h => h.typeId === parseInt(t.dataType))
        return {
            ...t,
            gridName: pluvioStat?.label || t.dataType,
            gridIndex: index + (nbHydroGrids + 1),
        }
    }) : []

    const thresholds = [...hydroThresholds, ...pluvioThresholds]

    const eventGrids = useMemo(() => [{
        gridIndex: 0,
        name: i18n.events,
        yOptions: {
            type: 'value',
            nameRotate: 0,
            nameGap: 20,
            minInterval: 1,
            axisLine: { show: false },
            axisTick: { show: false },
            axisLabel: { show: false },
        },
        xOptions: {
            axisLabel: { show: false },
            axisLine: { show: false },
            axisTick: { show: false },
        },
        gridOptions: {
            top: 0,
            left: '100px',
            height: 40,
        },
    }], [])

    const hydroGrids = useMemo(() => (hydroFormatted.length || instFormatted.some(i => IIBSN_HYDRO_PARAMETERS.includes(i.parameterCode))) ? hydroKeysFiltered.map((key, index) => {
        const dataType = parseInt(key)
        const hydroStat = hydroStats.find(h => h.typeId === dataType)
        const gridName = !hydroFormatted.length ? (parameters.find(p => p.code === PARAMETER_CODE_IIBSN)?.name || key) : (hydroStat?.label || key)
        const unit = hydroStat?.unit ? `(${hydroStat?.unit}) ` : ''
        return {
            gridIndex: index + 1,
            name: `${gridName} ${unit}`,
            dataType,
            gridOptions: {
                left: '100px',
                top: 25,
                height: graphicHeight,
            },
        }
    }) : [], [graphicHeight, hydroFormatted.length, hydroKeysFiltered, hydroStats, instFormatted, parameters])

    const pluvioGrids = useMemo(() => pluvioFormatted.length ? keys(pluvioGroupedMeasures).map((key, index) => {
        const pluvioStat = pluvioStats.find(h => h.typeId === parseInt(key))
        const unit = pluvioStat?.unit ? `(${pluvioStat?.unit}) ` : ''
        return {
            gridIndex: index + (nbHydroGrids + 1),
            name: `${pluvioStat?.label || key} ${unit}`,
            gridOptions: {
                left: '100px',
                top: 35,
                height: graphicHeight / 3,
            },
        }
    }) : [], [graphicHeight, nbHydroGrids, pluvioFormatted.length, pluvioGroupedMeasures, pluvioStats])

    const instGrids = useMemo(() => instFormatted.length ? instSortedKeys.filter(key => !IIBSN_HYDRO_PARAMETERS.includes(key)).map((key, index) => {
        const dataType = instGroupedMeasures[key]?.[0]?.dataType
        const parameter = parameters.find(p => p.code === key)
        const hydroStat = hydroStats.find(h => h.typeId === dataType)
        const unit = hydroStat?.unit ? `(${hydroStat?.unit}) ` : ''
        return {
            gridIndex: index + (nbHydroGrids + nbPluvioGrids + 1),
            name: `${parameter?.name || key} ${unit}`,
            gridOptions: {
                left: '100px',
                top: 35,
                height: graphicHeight / 3,
            },
        }
    }) : [], [graphicHeight, hydroStats, instFormatted.length, instGroupedMeasures, instSortedKeys, nbHydroGrids, nbPluvioGrids, parameters])

    const grids = [...eventGrids, ...hydroGrids, ...pluvioGrids, ...instGrids]

    const formatTooltip = params => {
        return getFullDate(params[0].axisValue) + params.map(({ marker, seriesName, value: [, result, statusObj], data: { unit = '' } }) => `<br/>${marker} ${seriesName}: ${result && round(result, ROUND_VALUE)} ${unit} <div style="display: inline-grid; vertical-align: middle;">${renderToString(statusIcon(statusObj, 20))}</div>`).join('')
    }

    const onChangeLegend = (selectedLegend) => {
        dispatch(AdministrationAction.updateSetting(legendParameterName, JSON.stringify(selectedLegend), !legendSettingValue))
    }

    return parametersLoaded && (
        <MultiChart
            data={dataFormatted}
            grids={grids}
            stationsEvents={stationsEvents}
            thresholds={thresholds}
            roundValue={ROUND_VALUE}
            footerHeight={70}
            exportName={installation.name || ''}
            withToolTypeLine
            withToolTypeBar
            withToolThreshold
            withToolLine
            withDataZoom
            withToolLog
            withFullScreen
            onFullScreen={onFullScreen}
            defaultDisplayMarker={false}
            tooltipFormatter={formatTooltip}
            defaultMinDate={defaultMinDate}
            defaultMaxDate={defaultMaxDate}
            defaultSelectedLegend={legendSetting}
            onChangeLegend={onChangeLegend}
            axisFormatter={getAxisIntervalFormatter}
        />
    )
}

FlowObstructionMultiGraph.propTypes = {
    stationId: PropTypes.number,
    hydroStations: PropTypes.arrayOf(PropTypes.instanceOf(DtoHydrometricStation)),
    pluvioStations: PropTypes.arrayOf(PropTypes.instanceOf(PluviometerDto)),
    hydroStats: PropTypes.arrayOf(PropTypes.instanceOf(DtoHydroStats)),
    pluvioStats: PropTypes.arrayOf(PropTypes.instanceOf(DtoPluviometerStats)),
    graphicHeight: PropTypes.number,
    stationsEvents: PropTypes.arrayOf(PropTypes.instanceOf(DtoEvent)),
    hydroMeasures: PropTypes.arrayOf(PropTypes.instanceOf(DtoHydroMeasures)),
    pluvioMeasures: PropTypes.arrayOf(PropTypes.instanceOf(DtoPluvioMeasures)),
    instMeasures: PropTypes.arrayOf(PropTypes.instanceOf(DtoInstallationAnalysisMeasures)),
    hydroIds: PropTypes.arrayOf(PropTypes.number),
    pluvioIds: PropTypes.arrayOf(PropTypes.number),
    onFullScreen: PropTypes.func,
    defaultMinDate: PropTypes.number,
    defaultMaxDate: PropTypes.number,
}

const FlowObstructionChartPanel = ({
    stationId,
    showTitle = true,
    showKeyFigures = false,
    inPopup = false,
    graphicHeight = 250,

    hydroStatistics = [],
    pluvioStatistics = [],
    idsPluvioDataType = [],
    hydroStationsEvents = [],
    pluvioStationsEvents = [],

    hydroIds = [],
    pluvioIds = [],
    instIds = [],

    setInstallationTypesChart = () => {},
    setDataTypesChart = () => {},
}) => {
    const {
        hydrometricStations,
        hydroDataTypes,
        installationEvents,
        parameters,
        installationAnalysisParameters,
    } = useSelector(store => ({
        hydrometricStations: store.HydrometryReducer.hydrometricStations,
        hydroDataTypes: store.StationReducer.hydroDataTypes,
        installationEvents: store.InstallationReducer.installationEvents,
        parameters: store.ParameterReducer.parameters,
        installationAnalysisParameters: store.InstallationReducer.installationAnalysisParameters,
    }), shallowEqual)

    const lastSelectedTime = useAccountSetting(CHART_SELECTED_TIME, v => v === HISTO ? v : (v ? parseInt(v) : J90))
    const getDefaultMinDate = () => lastSelectedTime === HISTO ? undefined : getSubstractTime(lastSelectedTime)
    const getDefaultMaxDate = () => lastSelectedTime === HISTO ? moment().valueOf() : undefined

    const [minDate, setMinDate] = useState(getDefaultMinDate())
    const [maxDate, setMaxDate] = useState(getDefaultMaxDate())
    const [chartTab, setChartTab] = useState(lastSelectedTime || J90)

    const [hydroMeasures, setHydroMeasures] = useState([])
    const [pluvioMeasures, setPluvioMeasures] = useState([])
    const [instMeasures, setInstMeasures] = useState([])

    const [fullScreen, setFullScreen] = useState(false)

    const dispatch = useDispatch()

    useEffect(() => {
        return () => {
            setInstMeasures([])
        }
    }, [])

    const AMhydroDataTypeIds = uniq(hydroStatistics.map(h => h.typeId).filter(h => h))
    const AVhydroDataTypeIds = uniq([...hydroStatistics.filter(h => !hydroDataTypes.find(dt => dt.id === h.typeId)?.monitoringChronicle), hydroStatistics.find(h => h.typeId === HYDROMETER_HEIGHT_TYPE)].map(h => h.typeId)).filter(h => !!h)

    const {
        isLoaded: hydroIsLoaded,
        progress: hydroProgress,
    } = useProgressDispatch(() => {
        if (!chartTab) {
            return []
        }
        setHydroMeasures([])
        const defaultGroupMode = minDate && minDate > moment(maxDate || moment().valueOf()).subtract('91', 'day').valueOf() ? 'all' : 'MAX'
        return hydroIds.map(id => {
            const hydroTypes = hydrometricStations.find(h => h.id === id)?.code?.includes('_AM') ? AMhydroDataTypeIds : AVhydroDataTypeIds
            const hydroInputs = hydroTypes.map(typeId => ({
                stationId: id,
                dataType: typeId,
                groupFunc: defaultGroupMode,
                chartMode: true,
                startDate: moment(minDate).subtract(1, 'day').valueOf(),
                endDate: moment(maxDate).add(1, 'day').valueOf(),
            }))
            return dispatch(HydrometryAction.loadHydroChronicMeasures(hydroInputs))
                .then(data => setHydroMeasures(prev => [...prev, ...data]))
        })
    }, [chartTab])

    const {
        isLoaded: pluvioIsLoaded,
        progress: pluvioProgress,
    } = useProgressDispatch(() => {
        if (!chartTab) {
            return []
        }
        setPluvioMeasures([])
        const defaultGroupMode = minDate && minDate > moment(maxDate || moment().valueOf()).subtract('1', 'month').valueOf() ? 'all' : 'MAX'
        return pluvioIds.map(id => {
            const pluvioInputs = idsPluvioDataType.map(typeId => ({
                stationId: id,
                dataType: typeId,
                groupFunc: [DATA_TYPE.VIRTUAL_EFFECTIVE_RAIN, DATA_TYPE.RAIN, DATA_TYPE.ETP].includes(typeId) ? PLUVIO_GROUPED : defaultGroupMode,
                chartMode: true,
                startDate: minDate,
                endDate: maxDate,
            }))
            return dispatch(PluviometryAction.loadPluvioChronicMeasures(pluvioInputs))
                .then(data => setPluvioMeasures(prev => [...prev, ...data]))
        })
    }, [chartTab])

    const {
        isLoaded: instIsLoaded,
        progress: instProgress,
    } = useProgressDispatch((cancelRef) => {
        if (!chartTab) {
            return []
        }
        setInstMeasures([])
        return instIds.flatMap(id => {
            return installationAnalysisParameters.map(param => {
                return dispatch(InstallationAction.fetchInstallationAnalysisByCriterias(id, { lightMode: true, startDate: minDate, endDate: maxDate, parameterCode: param, group: (chartTab === 'HISTO' || getDayDiff(maxDate, minDate) > 30) ? 'MAX' : 'all' }))
                    .then(data => {
                        const measuresFiltered = uniqBy(data.map(j => new DtoInstallationAnalysisMeasureLight(j)).filter(d => d.date >= minDate && d.date <= maxDate), 'date')
                        return !cancelRef.current && setInstMeasures(prev => [...prev, new DtoInstallationAnalysisMeasures({ stationId: id, dataType: HYDROMETER_HEIGHT_TYPE, measures: measuresFiltered, parameterCode: param })])
                    })
            })
        })
    }, [chartTab])

    const stationsEvents = [...hydroStationsEvents, ...pluvioStationsEvents, ...installationEvents.map(instEvent => ({ ...instEvent, typeName: STATION_TYPE_NAME.installation }))]

    const showProgress = !hydroIsLoaded || !pluvioIsLoaded || !instIsLoaded

    const fullScreenStyle = fullScreen ? {
        position: 'fixed',
        top: 0,
        left: 0,
        height: !inPopup ? `calc(${window.innerHeight}px - 5rem)` : `calc(${window.innerHeight}px)`,
        width: `calc(${window.innerWidth}px)`,
        zIndex: 9,
        marginTop: !inPopup ? '5rem' : '0',
        overflowY: 'auto',
        backgroundColor: 'white',
    } : {}

    const chartHeight = !fullScreen ? graphicHeight : (window.innerHeight / 2.1)

    const minDateFilterPreview = (date) => {
        const allFirstMeasures = hydroMeasures.flatMap(obj => obj.measures.length ? [obj.measures[0].date] : [])
        return date ?? min(allFirstMeasures) ?? minBy(hydroStatistics, 'startDate')?.startDate ?? moment().subtract(1, 'years').valueOf()
    }

    const { minDate: formattedMinDate, maxDate: formattedMaxDate } = getChartDate(minDateFilterPreview(minDate), maxDate, chartTab)

    return (
        <>
            {(showKeyFigures && instIsLoaded) && (
                <InstallationKeyFigurePanel
                    hydroMeasures={hydroMeasures}
                    pluvioMeasures={pluvioMeasures}
                    instMeasures={instMeasures}
                    hydroDataTypes={hydroStatistics}
                    pluvioDataTypes={pluvioStatistics}
                    instParameters={parameters}
                />
            )}
            <WhiteCard title={showTitle && i18n.overview} round cardStyle={{ marginTop: '10px' }} noMargin={false}>
                <Grid container sx={fullScreenStyle}>
                    <Grid container item xs={12} alignItems='center' columnSpacing={1} sx={{ paddingLeft: '100px', paddingTop: fullScreen ? '12px' : (showTitle ? '1rem' : '10px') }}>
                        <Grid item>
                            <MUIChartTabs
                                time={chartTab}
                                onChangeTime={(newTime) => {
                                    setMinDate(newTime.minDate)
                                    setMaxDate(newTime.maxDate)
                                    setChartTab(newTime.time)
                                }}
                            />
                        </Grid>
                        <FlowObstructionChartOptions
                            hydroStats={hydroStatistics}
                            setInstallationTypesChart={setInstallationTypesChart}
                            setDataTypesChart={setDataTypesChart}
                            showTitle={showTitle}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        {showProgress ? (
                            <ProgressCard progress={((hydroProgress + pluvioProgress + instProgress) / 3)} className='padding-top-4' message={i18n.loadingDataInProgress} />
                        ) : (
                            <FlowObstructionMultiGraph
                                stationId={stationId}
                                hydroStats={hydroStatistics}
                                pluvioStats={pluvioStatistics}
                                graphicHeight={chartHeight}
                                stationsEvents={stationsEvents}
                                hydroMeasures={hydroMeasures}
                                pluvioMeasures={pluvioMeasures}
                                instMeasures={instMeasures}
                                hydroIds={hydroIds}
                                pluvioIds={pluvioIds}
                                onFullScreen={() => setFullScreen(prevFullScreen => !prevFullScreen)}
                                defaultMinDate={formattedMinDate}
                                defaultMaxDate={formattedMaxDate}
                                showTitle={showTitle}
                            />
                        )}
                    </Grid>
                </Grid>
            </WhiteCard >
        </>
    )
}

FlowObstructionChartPanel.propTypes = {
    stationId: PropTypes.number,
    showTitle: PropTypes.bool,
    showKeyFigures: PropTypes.bool,
    inPopup: PropTypes.bool,
    graphicHeight: PropTypes.number,

    hydroStatistics: PropTypes.arrayOf(PropTypes.shape({})),
    pluvioStatistics: PropTypes.arrayOf(PropTypes.shape({})),
    idsPluvioDataType: PropTypes.arrayOf(PropTypes.number),
    hydroStationsEvents: PropTypes.arrayOf(PropTypes.shape({})),
    pluvioStationsEvents: PropTypes.arrayOf(PropTypes.shape({})),

    hydroIds: PropTypes.arrayOf(PropTypes.number),
    pluvioIds: PropTypes.arrayOf(PropTypes.number),
    instIds: PropTypes.arrayOf(PropTypes.number),

    setInstallationTypesChart: PropTypes.func,
    setDataTypesChart: PropTypes.func,
}

const InstallationLoaderChartPanel = ({
    stationId,
    showTitle,
    showKeyFigures,
    inPopup,
    graphHeight,

    idsHydroDataType = [],
    idsPluvioDataType = [],
    withChroniclesFollowUp = false,

    hydroIds = [],
    pluvioIds = [],
    instIds = [],

    onChangeInstallationTypes = () => {},
    onChangeDataTypes = () => {},
}) => {
    const [statsHydroIsLoaded, setStatsHydroIsLoaded] = useState(false)
    const [statsPluvioIsLoaded, setStatsPluvioIsLoaded] = useState(false)
    const [instsLoaded, setInstsLoaded] = useState(false)
    const [statsHydroProgress, setStatsHydroProgress] = useState(0)
    const [statsPluvioProgress, setStatsPluvioProgress] = useState(0)

    const [hydroStationsEvents, setHydroStationsEvents] = useState([])
    const [pluvioStationsEvents, setPluvioStationsEvents] = useState([])

    const [hydroStatistics, setHydroStatistics] = useState(getHardHydroDataTypes().map(dt => ({ ...dt, typeId: dt.id })))
    const [pluvioStatistics, setPluvioStatistics] = useState([])

    const dispatch = useDispatch()

    useEffect(() => {
        if (!instIds.length) {
            setInstsLoaded(true)
            return
        }
        setInstsLoaded(false)

        dispatch(InstallationAction.fetchInstallationAnalysisParameters(instIds)).then(() => setInstsLoaded(true))
    }, [instIds])

    useEffect(() => {
        if (!hydroIds.length) {
            setStatsHydroIsLoaded(true)
            return
        }
        setStatsHydroIsLoaded(false)

        dispatch(HydrometryAction.fetchSeveralHydroStatistics(hydroIds, setStatsHydroProgress)).then(hydroStats => {
            const allHydroStats = [...hydroStatistics, ...hydroStats]
            const hydroStatisticsFiltered = allHydroStats.filter(h => idsHydroDataType.includes(h.typeId) || (withChroniclesFollowUp && h.chronicFollowUp))
            setHydroStatistics(hydroStatisticsFiltered)
            setStatsHydroIsLoaded(true)
        })
        dispatch(EventsAction.fetchStationsEvents(STATION_TYPE_NAME.hydrometry, hydroIds)).then(events => {
            setHydroStationsEvents(events)
        })
    }, [hydroIds])

    useEffect(() => {
        if (!pluvioIds.length) {
            setStatsPluvioIsLoaded(true)
            return
        }
        setStatsPluvioIsLoaded(false)

        dispatch(PluviometryAction.fetchSeveralPluvioStatistics(pluvioIds, setStatsPluvioProgress)).then(pluvioStats => {
            const pluvioStatisticsFiltered = pluvioStats.filter(h => idsPluvioDataType.includes(h.typeId))
            setPluvioStatistics(pluvioStatisticsFiltered)
            setStatsPluvioIsLoaded(true)
        })
        dispatch(EventsAction.fetchStationsEvents(STATION_TYPE_NAME.pluviometry, pluvioIds)).then(events => {
            setPluvioStationsEvents(events)
        })
    }, [pluvioIds])

    useEffect(() => {
        return () => {
            dispatch(InstallationActionConstant.resetInstallationDashboard())
            setInstsLoaded(false)
        }
    }, [])

    const isLoaded = statsHydroIsLoaded && statsPluvioIsLoaded && instsLoaded
    const graphicHeight = graphHeight || window.innerHeight / 3.1

    return !isLoaded ? (
        <ProgressCard progress={(statsHydroProgress + statsPluvioProgress) / 2} className='padding-top-4' message={i18n.loadingStatisticsInProgress} />
    ) : (
        <FlowObstructionChartPanel
            stationId={stationId}
            showTitle={showTitle}
            showKeyFigures={showKeyFigures}
            inPopup={inPopup}
            graphicHeight={graphicHeight}

            hydroStatistics={hydroStatistics}
            pluvioStatistics={pluvioStatistics}
            idsPluvioDataType={[DATA_TYPE.RAIN]}
            hydroStationsEvents={hydroStationsEvents}
            pluvioStationsEvents={pluvioStationsEvents}

            hydroIds={hydroIds}
            pluvioIds={pluvioIds}
            instIds={instIds}

            setInstallationTypesChart={onChangeInstallationTypes}
            setDataTypesChart={onChangeDataTypes}
        />
    )
}

InstallationLoaderChartPanel.propTypes = {
    stationId: PropTypes.number,
    showTitle: PropTypes.bool,
    showKeyFigures: PropTypes.bool,
    inPopup: PropTypes.bool,
    graphHeight: PropTypes.number,

    idsHydroDataType: PropTypes.arrayOf(PropTypes.number),
    idsPluvioDataType: PropTypes.arrayOf(PropTypes.number),
    withChroniclesFollowUp: PropTypes.bool,

    hydroIds: PropTypes.arrayOf(PropTypes.number),
    pluvioIds: PropTypes.arrayOf(PropTypes.number),
    instIds: PropTypes.arrayOf(PropTypes.number),

    onChangeInstallationTypes: PropTypes.func,
    onChangeDataTypes: PropTypes.func,
}

const InstallationChartPanel = ({
    stationId,
    stationCode,
    graphHeight,
    showTitle,
    showKeyFigures,
    inPopup,
}) => {
    const {
        associatedSites,
        hydrometricStations,
        pluviometers,
        installationsLight,
        accountUser,
        hydroDataTypes,
    } = useSelector(store => ({
        associatedSites: store.StationReducer.associatedSites,
        hydrometricStations: store.HydrometryReducer.hydrometricStations,
        pluviometers: store.PluviometryReducer.pluviometers,
        installationsLight: store.InstallationReducer.installationsLight,
        accountUser: store.AccountReducer.accountUser,
        hydroDataTypes: store.StationReducer.hydroDataTypes,
    }), shallowEqual)

    const [installationTypesChart, setInstallationTypesChart] = useState([])
    const [dataTypesChart, setDataTypesChart] = useState(DATA_TYPES_ACCEPTED)

    const dispatch = useDispatch()

    const { isLoaded, progress } = useProgressDispatch(() => compact([
        dispatch(AdministrationAction.fetchGlobalParameters()),
        dispatch(StationAction.fetchAssociatedSites(stationCode, getStationTypeCodeFromType(STATION_TYPE_NAME.installation))),
        dispatch(ParameterAction.fetchParameters()),
        dispatch(HydrometryAction.fetchHydrometricThresholds()),
        dispatch(PluviometryAction.fetchPluviometerAllThresholds()),
        dispatch(InstallationAction.fetchInstallationEvents(stationId)),
        !hydrometricStations.length && dispatch(HydrometryAction.fetchHydrometricStations()),
        !hydroDataTypes.length && dispatch(StationAction.fetchDataTypesByProject('SIH')),
        !pluviometers.length && dispatch(PluviometryAction.fetchPluviometers()),
        !installationsLight.length && dispatch(InstallationAction.fetchInstallationsLight()),
    ]), [])

    const hydroIds = useMemo(() => {
        if (!associatedSites.length || !hydrometricStations.length) {
            return undefined
        }
        const associatedStationsHydro = associatedSites.filter(as => as.typeName === STATION_TYPE_NAME.hydrometry).map(as => as.stationLinkedId)
        return hydrometricStations.filter(h => associatedStationsHydro.includes(h.id)).map(hydro => hydro.id)
    }, [associatedSites, hydrometricStations])

    const pluvioIds = useMemo(() => {
        if (!associatedSites.length || !pluviometers.length) {
            return undefined
        }
        const associatedStationsPluvio = associatedSites.filter(as => as.typeName === STATION_TYPE_NAME.pluviometry).map(as => as.stationLinkedId)
        return pluviometers.filter(h => associatedStationsPluvio.includes(h.id)).map(pluvio => pluvio.id)
    }, [associatedSites, pluviometers])

    const instIds = useMemo(() => {
        if (!associatedSites.length || !installationsLight.length) {
            return undefined
        }
        if (!dataTypesChart.length) {
            return []
        }
        const associatedStationsInst = [stationId, ...associatedSites.filter(as => as.typeName === STATION_TYPE_NAME.installation && as.stationLinkedId !== stationId).map(as => as.stationLinkedId)]
        const filteredInstallationsByAssociated = installationsLight.filter(h => associatedStationsInst.includes(h.id))
        const filteredInstallationsByTypes = installationTypesChart.length ? filteredInstallationsByAssociated.filter(h => installationTypesChart.includes(h.installationType)) : filteredInstallationsByAssociated
        return filteredInstallationsByTypes.map(inst => inst.id)
    }, [associatedSites, installationTypesChart, installationsLight, dataTypesChart.length, stationId])

    const onChangeInstallationTypes = (value) => {
        setInstallationTypesChart(value)
        const param = new GlobalParametersDto({
            parameter: INSTALLATION_TYPE_PARAMETER,
            module: SIEAU,
            value: value.toString(),
            updateDate: moment().valueOf(),
            updateLogin: accountUser.login,
        })
        dispatch(AdministrationAction.updateGlobalParameter(param)).then(() => dispatch(AdministrationAction.fetchGlobalParameters()))
    }

    const onChangeDataTypes = (value) => {
        setDataTypesChart(value)
        const param = new GlobalParametersDto({
            parameter: DATA_TYPE_PARAMETER,
            module: SIEAU,
            value: value.toString(),
            updateDate: moment().valueOf(),
            updateLogin: accountUser.login,
        })
        dispatch(AdministrationAction.updateGlobalParameter(param)).then(() => dispatch(AdministrationAction.fetchGlobalParameters()))
    }

    return (isLoaded && hydroIds && pluvioIds && instIds) ? (
        <InstallationLoaderChartPanel
            stationId={stationId}
            showTitle={showTitle}
            showKeyFigures={showKeyFigures}
            inPopup={inPopup}
            graphicHeight={graphHeight}

            idsHydroDataType={[HYDROMETER_HEIGHT_TYPE]}
            idsPluvioDataType={[DATA_TYPE.RAIN]}
            withChroniclesFollowUp

            hydroIds={hydroIds}
            pluvioIds={pluvioIds}
            instIds={instIds}

            setInstallationTypesChart={onChangeInstallationTypes}
            setDataTypesChart={onChangeDataTypes}
        />
    ) : (
        !isLoaded && <ProgressCard progress={progress} className='padding-top-4' message={i18n.loadingAssociatedStationsInProgress} />
    )
}

InstallationChartPanel.propTypes = {
    stationId: PropTypes.number.isRequired,
    stationCode: PropTypes.string.isRequired,
    graphHeight: PropTypes.number,
    showTitle: PropTypes.bool,
    showKeyFigures: PropTypes.bool,
    inPopup: PropTypes.bool,
}

export default InstallationChartPanel