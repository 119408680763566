import React, { useEffect, useMemo, useState } from 'react'
import i18n from 'simple-react-i18n'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { Grid, Button, DialogActions, Card, Box, Grid2, Dialog, DialogContent, useTheme, Typography, DialogTitle, Icon as Icon2 } from '@mui/material'
import CityAction from '../../../../referencial/components/city/actions/CityAction'
import { DialogContentMUI, DialogMUI, DialogTitleMUI } from '../../../../components/styled/Dialog'
import PropTypes from 'prop-types'
import Icon from '../../../../components/icon/Icon'
import SimpleDatePicker from '../../../../components/forms/SimpleDatePicker'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import BusinessIcon from '@mui/icons-material/Business'
import { SmallStyledFieldSet, StyledFieldSet } from '../../../../components/StyledElements'
import Select from '../../../../components/forms/Select'
import { nbPerPageLabelShorty, nbPerPageLabelTiny } from '../../../../referencial/constants/ReferencialConstants'
import Input, { NewInput } from '../../../../components/forms/Input'
import ConfirmModal from '../../../../components/modal/ConfirmModal'
import ReferencialAction from '../../../../referencial/action/ReferencialAction'
import { exportFile } from '../../../../utils/ExportDataUtil'
import { capitalize, groupBy, keys, orderBy } from 'lodash'
import useSandreList from 'utils/customHook/useSandreList'
import { OWNER_TYPES } from 'perimeters/components/constants/PerimetersConstants'
import ContactAction from '../../../../referencial/components/contact/actions/ContactAction'
import ContributorAction from '../../../../referencial/components/contributor/actions/ContributorAction'
import { CardTable, NewTable } from '../../../../components/datatable/NewTable'
import { mainBlue, mainWhite } from 'utils/constants/ColorTheme'
import { ButtonMUI } from 'components/styled/Buttons'
import PrivateOwnerDto from 'perimeters/dto/PrivateOwnerDto'
import CompanyOwnerDto from 'perimeters/dto/CompanyOwnerDto'
import { CardTitle } from 'components/card/NewCard'
import { MAIN_RADIUS } from '../../../../utils/constants/Theme'
import PerimetersAction from 'perimeters/action/PerimetersAction'
import useProgressDispatch from 'utils/customHook/useProgressDispatch'
import ProgressBar from 'components/progress/ProgressBar'

const OWNERS_HEADER = ['type', 'name', 'address']
const OWNERS_GROUPS_HEADERS = [ 'nullValue', 'name', 'address']

const OwnerGroupPopover = ({
    parcelCityCode,
    searchedOwnerGroup = '',
    setOwnersGroups = () => {},
    onClose = () => {},
}) => {
    const [ownerGroups, setOwnerGroups] = useState({})
    const [selectedOwnerGroup, setSelectedOwnerGroup] = useState()

    const theme = useTheme()

    const dispatch = useDispatch()

    const formatOwner = (owner, ownerType) => ({
        ...owner,
        nullValue: (ownerType === OWNER_TYPES.PRIVATE) ? <Icon2 style={{ fontSize: '30px' }}>account_circle</Icon2> : <Icon2 style={{ fontSize: '30px' }}>business</Icon2>,
        address: `${owner.address ?? ''}${owner.postalCode ? `, ${owner.postalCode}` : ''}${owner.usualCityName ? `, ${owner.usualCityName}` : ''}`,
        name: `${owner.name} ${owner.firstname ?? ''}`,
        ownerType,
    })

    const { isLoaded } = useProgressDispatch(() => [
        dispatch(PerimetersAction.searchGroupsOwners(searchedOwnerGroup, parcelCityCode)).then(owners => {
            setOwnerGroups(groupBy([
                ...owners.privateOwners.map(po => formatOwner(po, OWNER_TYPES.PRIVATE)),
                ...owners.companyOwners.map(co => formatOwner(co, OWNER_TYPES.COMPANY)),
            ], 'communalAccount'))
        }),
    ], [searchedOwnerGroup])

    return (
        <Grid2
            container
            sx={{
                padding: theme.spacing(1),
                backgroundColor: mainWhite,
                borderRadius: '2px',
                maxWidth: '500px',
                overflow: 'hidden',
            }}
            rowSpacing={1}
        >
            <Grid2 container size={12} sx={{ borderBottom: 'solid 1px rgba(0, 0, 0, 0.2)', paddingBottom: '2px' }} alignItems='center'>
                <Grid2 size='grow'>
                    <Typography fontSize='16px'>Choisissez un des groupes correspondants à : {searchedOwnerGroup}</Typography>
                </Grid2>
                <Grid2>
                    <Icon className='clickable' sx={{ color: 'white', fontSize: '2rem' }} onClick={onClose}>close</Icon>
                </Grid2>
            </Grid2>
            <Grid2
                container
                size={12}
                sx={{
                    maxHeight: '300px',
                    overflow: 'hidden auto',
                }}
            >
                {!isLoaded ? <ProgressBar indeterminate /> : keys(ownerGroups).map(communalAccount => {
                    return (
                        <Grid2
                            size={12}
                            key={`Groupedeproprietaire_${communalAccount}`}
                            onClick={() => setSelectedOwnerGroup(communalAccount)}
                            sx={{
                                border: selectedOwnerGroup === communalAccount && `solid 2px ${mainBlue}`,
                                padding: selectedOwnerGroup !== communalAccount && '2px',
                                borderRadius: MAIN_RADIUS,
                                cursor: 'pointer',
                            }}
                        >
                            <CardTable
                                title={communalAccount}
                                rows={ownerGroups[communalAccount]}
                                headers={OWNERS_GROUPS_HEADERS}
                                rowsPerPageOptions={nbPerPageLabelTiny}
                            />
                        </Grid2>
                    )
                })}
            </Grid2>
            <Grid2 size={12} sx={{ marginTop: theme.spacing(1) }}>
                <ButtonMUI
                    variant='contained'
                    color='primary'
                    fullWidth
                    onClick={() => {
                        dispatch(PerimetersAction.getOwnerGroups(selectedOwnerGroup, parcelCityCode)).then(newOwnerGroups => {
                            dispatch(PerimetersAction.addOwnersGroups(newOwnerGroups))
                            setOwnersGroups(ownerGroups[selectedOwnerGroup].map(og => ({ ...og, groupId: newOwnerGroups[0]?.groupId })))
                            onClose()
                        })
                    }}
                    disabled={!selectedOwnerGroup}
                >
                    {i18n.toLoad}
                </ButtonMUI>
            </Grid2>
        </Grid2>
    )
}

OwnerGroupPopover.propTypes = {
    parcelCityCode: PropTypes.string,
    searchedOwnerGroup: PropTypes.string,
    setOwnersGroups: PropTypes.func,
    onClose: PropTypes.func,
}

const SearchOwnerInput = ({
    parcelCityCode,
    value = '',
    placeholder,
    setOwnersGroups = () => { },
    disabled = false,
}) => {
    const [tmpSearch, setTmpSearch] = useState(`${value ?? ''}`)
    const [search, setSearch] = useState(`${value ?? ''}`)
    const [openOwnerGroupTooltip, setOpenOwnerGroupTooltip] = useState(false)

    const onValidate = () => {
        setSearch(tmpSearch)
        setOpenOwnerGroupTooltip(true)
    }

    useEffect(() => {
        setOpenOwnerGroupTooltip(false)
    }, [tmpSearch])

    return (
        <Grid2 container size={12} sx={{ padding: '1rem 1.5rem' }} columnSpacing={2} alignItems='center'>
            <Grid2>
                <span>{placeholder}</span>
            </Grid2>
            <Grid2>
                <NewInput
                    value={tmpSearch}
                    onChange={setTmpSearch}
                    onEnterKeyPress={onValidate}
                    disabled={disabled}

                    tooltipProps={{
                        open: openOwnerGroupTooltip,
                        title: (
                            <OwnerGroupPopover
                                parcelCityCode={parcelCityCode}
                                searchedOwnerGroup={search}
                                setOwnersGroups={newOwnersGroups => {
                                    const newGroup = newOwnersGroups[0]?.communalAccount
                                    setTmpSearch(newGroup)
                                    setSearch(newGroup)
                                    setOwnersGroups(newOwnersGroups)
                                }}
                                onClose={() => setOpenOwnerGroupTooltip(false)}
                            />
                        ),
                        placement: 'top-start',
                        slotProps: {
                            tooltip: {
                                sx: {
                                    padding: '2px',
                                    color: '#000',
                                    maxWidth: '500px',
                                },
                            },
                        },
                    }}
                />
            </Grid2>
            <Grid2>
                <Button
                    onClick={onValidate}
                    disabled={disabled || !tmpSearch}
                    variant='contained'
                    color='primary'
                >
                    {i18n.search}
                </Button>
            </Grid2>
        </Grid2>

    )
}

SearchOwnerInput.propTypes = {
    parcelCityCode: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    placeholder: PropTypes.string,
    setOwnersGroups: PropTypes.func,
    label: PropTypes.string,
    disabled: PropTypes.bool,
}

const ContactForm = ({
    addNewOwnerContact = false,

    contact = {},
    setContact = () => {},

    noTitle = false,
    readMode = true,
}) => {
    const {
        cities,
    } = useSelector(store => ({
        cities: store.CityReducer.cities,
    }), shallowEqual)

    return (
        <StyledFieldSet>
            {!noTitle && (
                <Grid container sx={{ padding: '1rem 1.5rem' }} alignItems='center' columnSpacing={1}>
                    <Grid item>
                        <AccountCircleIcon fontSize={'large'}/>
                    </Grid>
                    <Grid item sx={{ fontSize: '18px' }}>
                        {addNewOwnerContact ? i18n.newPhysicalOwner : i18n.physicalOwner}
                    </Grid>
                </Grid>
            )}
            <Grid container rowSpacing={1} sx={{ padding: `${noTitle ? '1rem' : 0} 1.5rem 1rem` }}>
                <Grid container item xs={12} columnSpacing={2}>
                    <Grid item xs={6}>
                        <Input
                            title={i18n.name}
                            value={contact.name}
                            onChange={name => setContact(prev => ({ ...prev, name }))}
                            clearFunction
                            disabled={readMode}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Input
                            title={i18n.firstname}
                            value={contact.firstname}
                            onChange={firstname => setContact(prev => ({ ...prev, firstname }))}
                            clearFunction
                            disabled={readMode}
                        />
                    </Grid>
                </Grid>
                <Grid container item xs={12} columnSpacing={2}>
                    <Grid item xs={4}>
                        <SimpleDatePicker
                            label={i18n.birthdate}
                            value={contact.birthdate}
                            onChange={birthdate => setContact(prev => ({ ...prev, birthdate }))}
                            disabled={readMode}
                        />
                    </Grid>
                    <Grid item xs={8}>
                        <Input
                            title={i18n.placeOfBirth}
                            value={contact.placeOfBirth}
                            onChange={placeOfBirth => setContact(prev => ({ ...prev, placeOfBirth }))}
                            clearFunction
                            disabled={readMode}
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Input
                        title={i18n.address}
                        value={contact.address}
                        onChange={address => setContact(prev => ({ ...prev, address }))}
                        clearFunction
                        disabled={readMode}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Input
                        title={i18n.addressComplement}
                        value={contact.additionalAddress}
                        onChange={additionalAddress => setContact(prev => ({ ...prev, additionalAddress }))}
                        clearFunction
                        disabled={readMode}
                    />
                </Grid>
                <Grid container item xs={12} columnSpacing={2}>
                    <Grid item xs={4}>
                        <Input
                            title={i18n.postalCode}
                            value={contact.postalCode}
                            onChange={postalCode => setContact(prev => ({ ...prev, postalCode }))}
                            clearFunction
                            disabled={readMode}
                        />
                    </Grid>
                    <Grid item xs={8}>
                        <Input
                            title={i18n.city}
                            value={contact.usualCityName}
                            onChange={usualCityName => setContact(prev => ({ ...prev, usualCityName }))}
                            clearFunction
                            disabled={readMode}
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Select
                        options={cities}
                        label={i18n.administrativeCity}
                        nullLabel='&nbsp;'
                        value={contact.cityCode}
                        noSort
                        onChange={cityCode => setContact(prev => ({ ...prev, cityCode }))}
                        disabled={readMode}
                    />
                </Grid>
            </Grid>
        </StyledFieldSet>
    )
}

ContactForm.propTypes = {
    addNewOwnerContact: PropTypes.bool,
    contact: PropTypes.shape({}),
    setContact: PropTypes.func,
    noTitle: PropTypes.bool,
    readMode: PropTypes.bool,
}

const ContributorForm = ({
    addNewOwnerContributor = false,
    contributor = {},
    setContributor = () => {},
    noTitle = false,
    readMode = true,
}) => {
    const {
        cities,
    } = useSelector(store => ({
        cities: store.CityReducer.cities,
    }), shallowEqual)

    return (
        <StyledFieldSet>
            {!noTitle && (
                <Grid container sx={{ padding: '1rem 1.5rem' }} alignItems='center' columnSpacing={1}>
                    <Grid item>
                        <BusinessIcon fontSize={'large'}/>
                    </Grid>
                    <Grid item sx={{ fontSize: '18px' }}>
                        {addNewOwnerContributor ? i18n.newMoralOwner : i18n.moralOwner}
                    </Grid>
                </Grid>
            )}
            <Grid container rowSpacing={1} sx={{ padding: `${noTitle ? '1rem' : 0} 1.5rem 1rem` }}>
                <Grid container item xs={12} columnSpacing={2}>
                    <Grid item xs={7}>
                        <Input
                            title={i18n.name}
                            value={contributor.name}
                            onChange={name => setContributor(prev => ({ ...prev, name }))}
                            clearFunction
                            disabled={readMode}
                        />
                    </Grid>
                    <Grid item xs={5}>
                        <Input
                            title={i18n.siret}
                            value={contributor.siret}
                            onChange={siret => setContributor(prev => ({ ...prev, siret }))}
                            clearFunction
                            disabled={readMode}
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <SimpleDatePicker
                        label={i18n.creationDate}
                        value={contributor.creationDate}
                        onChange={creationDate => setContributor(prev => ({ ...prev, creationDate }))}
                        disabled={readMode}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Input
                        title={i18n.address}
                        value={contributor.address}
                        onChange={address => setContributor(prev => ({ ...prev, address }))}
                        clearFunction
                        disabled={readMode}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Input
                        title={i18n.addressComplement}
                        value={contributor.additionalAddress}
                        onChange={additionalAddress => setContributor(prev => ({ ...prev, additionalAddress }))}
                        clearFunction
                        disabled={readMode}
                    />
                </Grid>
                <Grid container item xs={12} columnSpacing={2}>
                    <Grid item xs={4}>
                        <Input
                            title={i18n.postalCode}
                            value={contributor.postalCode}
                            onChange={postalCode => setContributor(prev => ({ ...prev, postalCode }))}
                            clearFunction
                            disabled={readMode}
                        />
                    </Grid>
                    <Grid item xs={8}>
                        <Input
                            title={i18n.city}
                            value={contributor.usualCityName}
                            onChange={usualCityName => setContributor(prev => ({ ...prev, usualCityName }))}
                            clearFunction
                            disabled={readMode}
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Select
                        options={cities}
                        label={i18n.administrativeCity}
                        nullLabel='&nbsp;'
                        value={contributor.cityCode}
                        noSort
                        onChange={cityCode => setContributor(prev => ({ ...prev, cityCode }))}
                        disabled={readMode}
                    />
                </Grid>
            </Grid>
        </StyledFieldSet>
    )
}

ContributorForm.propTypes = {
    addNewOwnerContributor: PropTypes.bool,
    contributor: PropTypes.shape({}),
    setContributor: PropTypes.func,
    noTitle: PropTypes.bool,
    readMode: PropTypes.bool,
}

const DialogAddOwner = ({
    open = false,
    onValidate = () => {},
    onClose = () => {},
}) => {
    const {
        contributors,
        contacts,
        cities,
    } = useSelector(store => ({
        contributors: store.ContributorReducer.contributors,
        contacts: store.ContactReducer.contacts,
        cities: store.CityReducer.cities,
    }), shallowEqual)

    const [addNewOwnerContact, setAddNewOwnerContact] = useState(false)
    const [addNewOwnerContributor, setAddNewOwnerContributor] = useState(false)

    const [searchedOwnerCity, setSearchedOwnerCity] = useState()
    const [searchedOwnerName, setSearchedOwnerName] = useState()
    const [findedOwners, setFindedOwners] = useState()
    const [searchedOwner, setSearchedOwner] = useState()

    const [contact, setContact] = useState()
    const [contributor, setContributor] = useState()

    const resetSearchedOwner = () => {
        setSearchedOwnerCity(undefined)
        setSearchedOwnerName(undefined)
        setFindedOwners(undefined)
        setSearchedOwner(undefined)
    }

    const resetOwner = () => {
        setContact(undefined)
        setContributor(undefined)
    }

    useEffect(() => {
        return () => {
            resetSearchedOwner()

            setAddNewOwnerContact(false)
            setAddNewOwnerContributor(false)
        }
    }, [])

    useEffect(() => {
        if (searchedOwner) {
            setAddNewOwnerContact(false)
            setAddNewOwnerContributor(false)
            resetOwner()

            if (searchedOwner.ownerType === OWNER_TYPES.PRIVATE) {
                setContact(searchedOwner)
            } else {
                setContributor(searchedOwner)
            }
        }
    }, [searchedOwner])

    useEffect(() => {
        if (addNewOwnerContact) {
            resetSearchedOwner()

            setAddNewOwnerContributor(false)
            resetOwner()
        }
    }, [addNewOwnerContact])

    useEffect(() => {
        if (addNewOwnerContributor) {
            resetSearchedOwner()

            setAddNewOwnerContact(false)
            resetOwner()
        }
    }, [addNewOwnerContributor])

    return (
        <DialogMUI
            fullWidth
            maxWidth='lg'
            open={open}
        >
            <DialogTitleMUI>
                <Grid container justifyContent='space-between' alignItems='center' style={{ padding: '0 20' }}>
                    <Grid item>
                        {i18n.addAnOwner}
                    </Grid>
                    <Grid item>
                        <Icon style={{ color: 'white' }} size='small' icon='close' onClick={onClose} />
                    </Grid>
                </Grid>
            </DialogTitleMUI>
            <DialogContentMUI>
                <StyledFieldSet style={{ padding: '1.5rem' }}>
                    <Grid container rowSpacing={1}>
                        <Grid container item xs={12} columnSpacing={2} alignItems='baseline'>
                            <Grid item xs={12} sx={{ fontSize: '16px', '& p': { margin: 0 } }}>
                                <p>{i18n.searchAPrivateOrACompnyPerson}</p>
                            </Grid>
                            <Grid container item xs={12} sx={{ paddingTop: '1rem' }}>
                                <Grid item xs={6}>
                                    <Select
                                        options={cities}
                                        label={i18n.city}
                                        nullLabel='&nbsp;'
                                        value={searchedOwnerCity}
                                        onChange={setSearchedOwnerCity}
                                        noSort
                                    />
                                </Grid>
                            </Grid>
                            <Grid item xs={6}>
                                <Input
                                    title={i18n.name}
                                    value={searchedOwnerName}
                                    onChange={setSearchedOwnerName}
                                    clearFunction
                                />
                            </Grid>
                            <Grid container item xs={6} justifyContent='flex-end'>
                                <Grid item>
                                    <Button
                                        onClick={() => {
                                            const privateOwners = contacts.filter(c => {
                                                return (!searchedOwnerCity || c.cityCode === searchedOwnerCity) && (!searchedOwnerName || capitalize(c.name)?.includes(capitalize(searchedOwnerName)))
                                            }).map(c => ({
                                                ...c,
                                                ownerType: OWNER_TYPES.PRIVATE,
                                                nullValue: { value: <AccountCircleIcon style={{ fontSize: '30px' }} /> },
                                            }))
                                            const companyOwners = contributors.filter(c => {
                                                return (!searchedOwnerCity || c.cityCode === searchedOwnerCity) && (!searchedOwnerName || capitalize(c.name)?.includes(capitalize(searchedOwnerName)))
                                            }).map(c => ({
                                                ...c,
                                                ownerType: OWNER_TYPES.COMPANY,
                                                nullValue: { value: <BusinessIcon style={{ fontSize: '30px' }} /> },
                                            }))
                                            setFindedOwners([ ...privateOwners, ...companyOwners ])
                                        }}
                                        disabled={!searchedOwnerCity && !searchedOwnerName}
                                        variant='contained'
                                        color='primary'
                                    >
                                        {i18n.search}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <NewTable
                                rows={findedOwners}
                                rowsPerPageOptions={nbPerPageLabelTiny}
                                headers={['nullValue', ...OWNERS_HEADER ]}
                                onClickRow={setSearchedOwner}
                                WrapperComponent={SmallStyledFieldSet}
                            />
                        </Grid>
                        <Grid container item xs={12} columnSpacing={2} sx={{ paddingTop: '1rem !important' }}>
                            <Grid item>
                                <Button
                                    variant='contained'
                                    color='primary'
                                    onClick={() => setAddNewOwnerContact(true)}
                                >
                                    {i18n.newPhysicalOwner}
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    variant='contained'
                                    color='primary'
                                    onClick={() => setAddNewOwnerContributor(true)}
                                >
                                    {i18n.newMoralOwner}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </StyledFieldSet>
                {(searchedOwner || addNewOwnerContact || addNewOwnerContributor) && (
                    <>
                        {((searchedOwner?.ownerType === OWNER_TYPES.PRIVATE) || addNewOwnerContact) ? (
                            <ContactForm
                                addNewOwnerContact={addNewOwnerContact}
                                contact={contact}
                                setContact={setContact}
                                readMode={false}
                            />
                        ) : (
                            <ContributorForm
                                addNewOwnerContributor={addNewOwnerContributor}
                                contributor={contributor}
                                setContributor={setContributor}
                                readMode={false}
                            />
                        )}
                    </>
                )
                }
            </DialogContentMUI>
            <DialogActions>
                <Grid container alignItems='center' justifyContent='space-between'>
                    <Grid container item xs={4} columnSpacing={1}>
                        <Grid item>
                            <Button
                                variant='outlined'
                                onClick={onClose}
                            >
                                {i18n.cancel}
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid container item xs={4} justifyContent='flex-end'>
                        <Grid item>
                            <Button
                                variant='contained'
                                color='primary'
                                onClick={() => {
                                    if (contact) {
                                        onValidate(({ ...contact, ownerType: OWNER_TYPES.PRIVATE }))
                                    } else if (contributor) {
                                        onValidate(({ ...contributor, ownerType: OWNER_TYPES.COMPANY }))
                                    }
                                }}
                            >
                                {i18n.register}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </DialogActions>
        </DialogMUI>
    )
}

DialogAddOwner.propTypes = {
    open: PropTypes.bool,
    onValidate: PropTypes.func,
    onClose: PropTypes.func,
}

const DialogOwner = ({
    open = false,
    onValidate = () => {},
    onClose = () => {},
    ownerDetail = {},
    readMode,
}) => {
    const {
        privateOwnersIndex,
        companyOwnersIndex,
    } = useSelector(store => ({
        privateOwnersIndex: store.PerimetersReducer.perimetersFolderPrivateOwnersIndex,
        companyOwnersIndex: store.PerimetersReducer.perimetersFolderCompanyOwnersIndex,
    }), shallowEqual)

    const [owner, setOwner] = useState()

    useEffect(() => {
        setOwner(undefined)

        if (keys(ownerDetail).length) {
            if (ownerDetail.ownerType === OWNER_TYPES.PRIVATE) {
                setOwner(privateOwnersIndex[ownerDetail.id])
            } else {
                setOwner(companyOwnersIndex[ownerDetail.id])
            }
        }
    }, [open, ownerDetail])

    return (
        <Dialog
            maxWidth='md'
            open={open}
        >
            <DialogTitle>
                <Grid2 container justifyContent='space-between' alignItems='center'>
                    <Grid2 container size='grow' alignItems='center' columnSpacing={1}>
                        <Grid2>
                            {ownerDetail.ownerType === OWNER_TYPES.PRIVATE ? <AccountCircleIcon fontSize='large' /> : <BusinessIcon fontSize='large' />}
                        </Grid2>
                        <Grid2 sx={{ fontSize: '18px' }}>
                            {ownerDetail.ownerType === OWNER_TYPES.PRIVATE ? i18n.physicalOwner : i18n.moralOwner}
                        </Grid2>
                    </Grid2>
                    <Grid2 size='auto'>
                        <Icon className='clickable' sx={{ color: 'white', fontSize: '2rem' }} onClick={onClose}>close</Icon>
                    </Grid2>
                </Grid2>
            </DialogTitle>
            <DialogContent>
                {ownerDetail.ownerType === OWNER_TYPES.PRIVATE ? (
                    <ContactForm
                        contact={{ ...owner, birthdate: owner?.dateOfBirth }}
                        setContact={setOwner}
                        readMode={readMode}
                        noTitle
                    />
                ) : (
                    <ContributorForm
                        contributor={owner}
                        setContributor={setOwner}
                        readMode={readMode}
                        noTitle
                    />
                )}
            </DialogContent>
            <DialogActions>
                <Button
                    variant='contained'
                    color='primary'
                    disabled={readMode}
                    onClick={() => onValidate(owner)}
                >
                    {i18n.register}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

DialogOwner.propTypes = {
    open: PropTypes.bool,
    onValidate: PropTypes.func,
    onClose: PropTypes.func,
    ownerDetail: PropTypes.shape({}),
    readMode: PropTypes.bool,
}

const PerimetersOwnersTable = ({
    defaultOwnerGroup,
    privateOwners = [],
    companyOwners = [],
    readMode = true,
    onValidate = () => {},
    parcelCityCode,
}) => {
    const {
        cities,
        sandreCodes,
        contacts,
        contributors,
        ownersGroups,
    } = useSelector(store => ({
        cities: store.CityReducer.cities,
        sandreCodes: store.ReferencialReducer.sandreCodes,
        contacts: store.ContactReducer.contacts,
        contributors: store.ContributorReducer.contributors,
        ownersGroups: store.PerimetersReducer.ownersGroups,
    }), shallowEqual)

    const lexiconOwnerTypes = useSandreList('PARCELLES.TYPE_PROPRIETE')

    const [addOwnerPopupOpen, setAddOwnerPopupOpen] = useState(false)
    const [ownerToDelete, setOwnerToDelete] = useState()
    const [ownerGroup, setOwnerGroup] = useState(defaultOwnerGroup)
    const [ownerDetail, setOwnerDetail] = useState({})
    const [ownersGroup, setOwnersGroup] = useState(defaultOwnerGroup)

    const dispatch = useDispatch()

    useEffect(() => {
        if (!cities?.length) {
            dispatch(CityAction.fetchCities())
        }

        if (!sandreCodes?.length) {
            dispatch(ReferencialAction.fetchSandreCodes())
        }

        if (!contacts?.length) {
            dispatch(ContactAction.fetchContacts())
        }

        if (!contributors?.length) {
            dispatch(ContributorAction.fetchContributors())
        }
    }, [])

    useEffect(() => {
        setOwnerGroup(defaultOwnerGroup)
    }, [defaultOwnerGroup])

    const onValidateOwners = owners => {
        onValidate(owners)
    }

    useEffect(() => {
        if (ownersGroup?.length) {
            onValidate(ownersGroup)
        }
    }, [ownersGroup])

    const formatOwner = owner => ({
        ...owner,
        type: { value: lexiconOwnerTypes.find(lot => lot.code === owner.propertyType)?.name },
        address: { value: `${owner.address ?? ''}${owner.postalCode ? `, ${owner.postalCode}` : ''}${owner.usualCityName ? `, ${owner.usualCityName}` : ''}` },
        name: { value: `${owner.name} ${owner.firstname ?? ''}` },
    })

    const formattedPrivateOwners = privateOwners?.map(p => ({
        ...formatOwner(p),
        ownerType: OWNER_TYPES.PRIVATE,
        nullValue: { value: <AccountCircleIcon style={{ fontSize: '30px' }} /> },
    })) || []

    const formattedCompanyOwners = companyOwners?.map(p => ({
        ...formatOwner(p),
        ownerType: OWNER_TYPES.COMPANY,
        nullValue: { value: <BusinessIcon style={{ fontSize: '30px' }} /> },
    })) || []

    const orderedOwners = orderBy([ ...formattedPrivateOwners, ...formattedCompanyOwners ], 'name')

    const exportData = () => {
        exportFile({
            data: orderedOwners.map(oo => ({
                type: { value: oo.type || '' },
                name: { value: oo.name || '' },
                address: { value: oo.address || '' },
                headers: OWNERS_HEADER,
            })),
            exportType: 'xlsx',
            titleFile: i18n.owners,
        })
    }

    const ownerGroupCommunalAccount = useMemo(() => ownersGroups.find(og => og.groupId === ownerGroup)?.communalAccount, [ownerGroup, ownersGroups])

    return (
        <Card>
            <DialogAddOwner
                open={addOwnerPopupOpen}
                onValidate={owner => {
                    onValidateOwners([{ ...owner, isNew: true }])
                    setAddOwnerPopupOpen(false)
                }}
                onClose={() => setAddOwnerPopupOpen(false)}
            />
            <DialogOwner
                open={keys(ownerDetail).length}
                onValidate={owner => {
                    onValidateOwners([owner])
                }}
                onClose={() => setOwnerDetail({})}
                ownerDetail={ownerDetail}
                readMode={readMode}
            />
            <ConfirmModal
                isOpen={!!ownerToDelete}
                title={i18n.sureRemoveOwner}
                onValidate={() => {
                    // TODO
                    // onRemove(ownerToDelete)
                    setOwnerToDelete()
                }}
                onClose={() => {
                    setOwnerToDelete()
                }}
            />
            <Card>
                <CardTitle
                    title={i18n.owners}
                    actions={[{
                        icon: 'download',
                        tooltip: i18n.export,
                        onClick: () => exportData(),
                    }, {
                        icon: 'note_add',
                        tooltip: ownerGroup?.length ? i18n.add : i18n.searchOwnerGroupFirst,
                        onClick: () => setAddOwnerPopupOpen(true),
                        displayed: !readMode && ownerGroup?.length,
                    }]}
                />
                <SearchOwnerInput
                    parcelCityCode={parcelCityCode}
                    value={ownerGroupCommunalAccount}
                    setOwnersGroups={setOwnersGroup}
                    placeholder={i18n.ownerGroup}
                    disabled={readMode}
                />
                <NewTable
                    rows={orderedOwners}
                    rowsPerPageOptions={nbPerPageLabelShorty}
                    headers={['nullValue', ...OWNERS_HEADER ]}
                    lineActions={[
                        { icon: 'delete', onClick: setOwnerToDelete, displayed: !readMode },
                    ]}
                    onClickRow={setOwnerDetail}
                    WrapperComponent={Box}
                />
            </Card>
        </Card>
    )
}

PerimetersOwnersTable.propTypes = {
    defaultOwnerGroup: PropTypes.number,
    privateOwners: PropTypes.arrayOf(PropTypes.instanceOf(PrivateOwnerDto)),
    companyOwners: PropTypes.arrayOf(PropTypes.instanceOf(CompanyOwnerDto)),
    readMode: PropTypes.bool,
    onValidate: PropTypes.func,
    parcelCityCode: PropTypes.string,
}

export default PerimetersOwnersTable