module.exports = {
    CONTACT: 'Contact',
    GROUP: 'Group',
    MAP: 'Map',
    RECEIVE_ALL_CONTACTS: 'RECEIVE_ALL_CONTACTS',
    RECEIVE_CONTACTS_TABLE: 'RECEIVE_CONTACTS_TABLE',
    RECEIVE_CONTACT: 'RECEIVE_CONTACT',
    RESET_CONTACT: 'RESET_CONTACT',
    RECEIVE_ALL_CONTACTS_GROUPS: 'RECEIVE_ALL_CONTACTS_GROUPS',
    CREATE_CONTACTS_GROUPS: 'CREATE_CONTACTS_GROUP',
    RECEIVE_CONTACTS_GROUP: 'RECEIVE_CONTACTS_GROUP',
    DELETE_CONTACTS_GROUP: 'DELETE_CONTACTS_GROUP',
    UPDATE_CONTACTS_GROUP: 'UPDATE_CONTACTS_GROUP',
    RECEIVE_CONTACT_GROUP_ALERT: 'RECEIVE_CONTACT_GROUP_ALERT',
}