export default class DtoHydrometryBookmarksStats {
    constructor(obj) {
        this.code = obj.codeHydro
        this.townName = obj.townName
        this.name = obj.name
        this.typeStation = obj.typeStation
        this.startDate = obj.startDate
        this.endDate = obj.endDate
        this.lastValueHeight = obj.lastValueHeight
        this.lastValueFlow = obj.lastValueFlow
        this.lastDateHeight = obj.lastDateHeight
        this.lastDateFlow = obj.lastDateFlow
        this.lastObservationLabel = obj.lastObservationLabel
    }
}
