export default class DtoMapSituation {
    constructor(obj) {
        this.id = obj.id
        this.code = obj.id
        this.name = obj.name
        this.date = obj.date
        this.updateLogin = obj.updateLogin
        this.updateDate = obj.updateDate
        this.nbValues = obj.nbValues
        this.status = obj.status
        this.params = obj.params
        this.progress = obj.progress
        this.dates = obj.dates
        this.startDate = obj.startDate
        this.endDate = obj.endDate
        this.indicatorType = obj.indicatorType
        this.calculType = obj.calculType
        this.startPeriod = obj.startPeriod
        this.endPeriod = obj.endPeriod
        this.recurrence = obj.recurrence
        this.typeId = obj.typeId
        this.sheduled = obj.sheduled
        this.accessRight = obj.accessRight
        this.default = obj.default
        this.usePredict = obj.usePredict
        this.contributorCode = obj.contributorCode
        this.exportCSV = obj.exportCSV
    }
}