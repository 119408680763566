import { Button, Grid } from '@mui/material'
import { isEqual } from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import DtoEvent from '../../../../events/dto/DtoEvent'
import DtoQualitometerLight from '../../../../quality/dto/DtoQualitometerLight'
import NetworkLinkDto from '../../../../referencial/components/network/dto/NetworkLinkDto'
import { hasValue } from '../../../../utils/NumberUtil'
import { PARAMETER_PANEL, PLANNING_PANEL, STATION_PANEL } from '../../../constants/CampaignConstants'
import CampaignPlanningDto from '../../../dto/CampaignPlanningDto'
import MapCampaignStation from '../MapCampaignStation'
import SelectStation from '../SelectStation'
import ListQualityCampaignStation from './ListQualityCampaignStation'
import ParameterPopin from './ParameterPopin'
import ParametersPanel from './ParametersPanel'
import PlanningsPanel from './PlanningsPanel'
import PlanningStepper from './stepper/PlanningStepper'
import { DialogActionsMUI, DialogContentMUI, DialogMUI, DialogTitleMUI } from 'components/styled/Dialog'
import Icon from 'components/icon/Icon'


class TabPanel extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isStationsInList: true,
            tmpSelectedStations: [...props.selectedStations],
            tmpSelectedParameters: [...props.selectedParameters],
            selectedSelections: props.selectedSelections ? [...props.selectedSelections] : [],
            tmpPlanning: {},
            openStationPopup: false,
            openParameterPopup: false,
            openPlanningPopup: false,
            parameterListCode: props.parameterListCode,
        }
    }

    componentDidMount = () => {
        $('ul.tabs').tabs()
    }

    componentDidUpdate = prevProps => {
        $('ul.tabs').tabs()
        if (!isEqual(this.props.selectedStations, prevProps.selectedStations)) {
            this.setState({ tmpSelectedStations: [...this.props.selectedStations] })
        }
        if (!isEqual(this.props.selectedParameters, prevProps.selectedParameters)) {
            this.setState({ tmpSelectedParameters: [...this.props.selectedParameters] })
        }
        if (!isEqual(this.props.selectedSelections, prevProps.selectedSelections)) {
            this.setState({ selectedSelections: [...this.props.selectedSelections] })
        }
    }

    onCloseStationPopup = () => this.setState({ openStationPopup: false, tmpSelectedStations: [...this.props.selectedStations] })

    onValidateStationPopup = () => {
        this.props.onChangeStation(this.state.tmpSelectedStations)
        this.setState({ openStationPopup: false })
    }

    onCloseParameterPopup = () => this.setState({ openParameterPopup: false })

    onValidateParameterPopup = (selectedParameters, selectedSelections) => {
        this.props.onChangeParameter(selectedParameters, selectedSelections)
        this.setState({ openParameterPopup: false })
    }

    onClosePlanningPopup = () => this.setState({ openPlanningPopup: false, tmpPlanning: {}, indexPlanning: undefined })

    onValidatePlanningPopup = () => {
        const {
            tmpPlanning,
            indexPlanning,
        } = this.state
        this.props.onUpdatePlanning(tmpPlanning, indexPlanning)
        this.setState({ openPlanningPopup: false, tmpPlanning: {}, indexPlanning: undefined })
    }

    onChangePlanning = change => {
        this.setState({ tmpPlanning: { ...this.state.tmpPlanning, ...change } })
    }

    onAddNewPlanning = () => {
        this.setState({ openPlanningPopup: true, tmpPlanning: { stationsPlan: this.props.selectedStations.map(({ id }) => id), parametersPlan: [...this.props.selectedParameters], selection: this.props.parameterListCode } })
    }

    getStationsPanel = (stations, selectedStations, isStationsInList) => {
        const {
            onDeleteStation,
            accessibilities = [],
            editMode,
        } = this.props
        const stationsSelected = selectedStations.map(station => stations.find(s => s.id === station.id)).filter(s => s)
        return isStationsInList ? (
            <ListQualityCampaignStation
                editMode={editMode}
                onDeleteStation={onDeleteStation}
                accessibilities={accessibilities}
                stations={stationsSelected}
            />
        ) : (
            <MapCampaignStation
                selectedStations={selectedStations}
                stations={stationsSelected}
                stationType={'quality'}
                accessibilities={accessibilities}
                editMode={editMode}
            />
        )
    }

    render = () => {
        const {
            isStationsInList,
            tmpSelectedStations,
            tmpPlanning,
            openStationPopup,
            openParameterPopup,
            openPlanningPopup,
            selectedSelections,
            indexPlanning,
        } = this.state
        const {
            campaignPlannings,
            editMode,
            stations,
            networkLinks,
            selectedStations,
            selectedParameters,
            onDeleteParameter,
            onDeletePlanning,
            onUpdateParameter,
            campaignEvents,
            onChangeView,
            view,
            plannings,
        } = this.props

        const nbStation = selectedStations.length
        const stationTitle = `${i18n.stations} (${nbStation})`

        const nbParameter = selectedParameters.length
        const nbSelection = selectedSelections.length
        const parameterTitle = `${i18n.parameters} (${nbParameter}) / ${i18n.selections} (${nbSelection})`

        const nbPlanning = plannings.length
        const planningTitle = `${i18n.plannings} (${nbPlanning})`

        return (
            <div className='col s12 no-padding'>
                <ul className='tabs tabs-fixed-width'>
                    <li className='tab' onClick={() => view !== STATION_PANEL && onChangeView(STATION_PANEL)}>
                        <a>
                            {stationTitle}
                            {editMode && view === STATION_PANEL && <i className='material-icons right small padding-top-1' onClick={() => this.setState({ openStationPopup: true })}>note_add</i>}
                            {view === STATION_PANEL && <i className='material-icons right small padding-top-1' onClick={() => this.setState({ isStationsInList: !isStationsInList })}>{isStationsInList ? 'map' : 'list'}</i>}
                        </a>
                    </li>
                    <li className='tab' onClick={() => view !== PARAMETER_PANEL && onChangeView(PARAMETER_PANEL)}>
                        <a>
                            {parameterTitle}
                            {editMode && view === PARAMETER_PANEL && <i className='material-icons right small padding-top-1' onClick={() => this.setState({ openParameterPopup: true })}>note_add</i>}
                        </a>
                    </li>
                    {
                        !!campaignPlannings.length && (
                            <li className='tab' onClick={() => view !== PLANNING_PANEL && onChangeView(PLANNING_PANEL)}>
                                <a>
                                    {planningTitle}
                                    {editMode && view === PLANNING_PANEL && <i className='material-icons right small padding-top-1' onClick={this.onAddNewPlanning}>note_add</i>}
                                </a>
                            </li>
                        )
                    }
                </ul>
                <div>
                    {
                        view === STATION_PANEL && this.getStationsPanel(stations, selectedStations, isStationsInList)
                    }
                    {
                        view === PARAMETER_PANEL && (
                            <ParametersPanel
                                selectedParameters={selectedParameters}
                                onUpdateParameter={onUpdateParameter}
                                onDeleteParameter={onDeleteParameter}
                                editMode={editMode}
                            />
                        )
                    }
                    {
                        view === PLANNING_PANEL && (
                            <PlanningsPanel
                                plannings={plannings}
                                editMode={editMode}
                                selectedStations={selectedStations}
                                selectedParameters={selectedParameters}
                                onDelete={onDeletePlanning}
                                onUpdate={(planning, index) => this.setState({ tmpPlanning: { ...planning }, indexPlanning: index, openPlanningPopup: true })}
                                onDuplicate={planning => this.setState({ tmpPlanning: { ...planning }, openPlanningPopup: true })}
                            />
                        )
                    }
                </div>
                <DialogMUI
                    onClose={this.onCloseStationPopup}
                    fullWidth
                    maxWidth='xl'
                    open={openStationPopup}
                >
                    <DialogTitleMUI>
                        <Grid container justifyContent='space-between' alignItems='center' style={{ padding: '0 20px' }}>
                            <Grid item>
                                {i18n.selectStations}
                            </Grid>
                            <Grid item>
                                <Icon style={{ color: 'white' }} size='small' icon='close' onClick={this.onCloseStationPopup} />
                            </Grid>
                        </Grid>
                    </DialogTitleMUI>
                    <DialogContentMUI>
                        <SelectStation
                            stations={stations}
                            networkLinks={networkLinks}
                            selectedStations={tmpSelectedStations}
                            campaignEvents={campaignEvents}
                            stationType={'quality'}
                            onChangeSelectedStation={tmpStations => this.setState({ tmpSelectedStations: tmpStations })}
                        />
                    </DialogContentMUI>
                    <DialogActionsMUI>
                        <Button onClick={this.onValidateStationPopup} variant='contained' color='primary'>
                            {i18n.validate}
                        </Button>
                    </DialogActionsMUI>
                </DialogMUI>
                <ParameterPopin
                    onChange={obj => this.setState(obj)}
                    isOpen={openParameterPopup}
                    selectedParameters={selectedParameters}
                    selectedSelections={selectedSelections}
                    onClose={this.onCloseParameterPopup}
                    onValidate={this.onValidateParameterPopup}
                />
                <PlanningStepper
                    isOpen={openPlanningPopup}
                    isNew={hasValue(indexPlanning)}
                    onClose={this.onClosePlanningPopup}
                    onValidate={this.onValidatePlanningPopup}
                    onChange={this.onChangePlanning}
                    planning={tmpPlanning}
                    selectedStations={selectedStations}
                    selectedSelections={selectedSelections}
                    stations={stations}
                    selectedParameters={selectedParameters}
                />
            </div>
        )
    }
}

TabPanel.propTypes = {
    selectedStations: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
    })),
    selectedParameters: PropTypes.arrayOf(PropTypes.shape({
        parameterCode: PropTypes.string,
        fractionCode: PropTypes.string,
        supportCode: PropTypes.string,
        unitCode: PropTypes.string,
        placeCode: PropTypes.string,
    })),
    selectedSelections: PropTypes.arrayOf(PropTypes.string),
    campaignPlannings: PropTypes.arrayOf(PropTypes.instanceOf(CampaignPlanningDto)),
    view: PropTypes.oneOf([STATION_PANEL, PARAMETER_PANEL]),
    onChangeView: PropTypes.func,
    editMode: PropTypes.bool,
    stations: PropTypes.arrayOf(PropTypes.instanceOf(DtoQualitometerLight)),
    parameterListCode: PropTypes.string,
    accessibilities: PropTypes.arrayOf(PropTypes.shape({})),
    campaignEvents: PropTypes.arrayOf(PropTypes.instanceOf(DtoEvent)),
    networkLinks: PropTypes.arrayOf(PropTypes.instanceOf(NetworkLinkDto)),
    plannings: PropTypes.arrayOf(PropTypes.instanceOf(CampaignPlanningDto)),
    onChangeStation: PropTypes.func,
    onChangeParameter: PropTypes.func,
    onUpdatePlanning: PropTypes.func,
    onUpdateParameter: PropTypes.func,
    onDeleteStation: PropTypes.func,
    onDeleteParameter: PropTypes.func,
    onDeletePlanning: PropTypes.func,
}

const mapStateToProps = store => ({
    campaignPlannings: store.CampaignReducer.campaignPlannings,
    campaignEvents: store.CampaignReducer.campaignEvents,
    networkLinks: store.QualityReducer.networkLinks,
})

export default connect(mapStateToProps)(TabPanel)