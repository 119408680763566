'use strict'
import { createSlice } from '@reduxjs/toolkit'
import DistributionUnitThunk from 'distributionUnit/actions/DistributionUnitThunk'
import DtoDistributionCounter from 'distributionUnit/dto/DtoDistributionCounter'
import DtoDistributionCounterType from 'distributionUnit/dto/DtoDistributionCounterType'
import DtoAssociatedStation from 'station/dto/DtoAssociatedStation'
import AppStore from 'store/AppStore'
import DtoCaptureDownstreamDistribUnit from '../../installation/components/capture/dto/DtoCaptureDownstreamDistribUnit'
import { arrayOf, instanceOf } from '../../utils/StoreUtils'
import DistributionUnitAction from '../actions/DistributionUnitAction'
import DtoDistributionUnit from '../dto/DtoDistributionUnit'
import DtoDistributionUnitSector from '../dto/DtoDistributionUnitSector'

export const initialState = {
    distributionUnits: [],
    distributionUnit: {},
    distributionUnitSectors: [],
    distributionUnitAssociations: [],
    captureDownstreamDistribUnits: [],
    distributionCounterTypes: [],
    distributionCounters: [],
    distributionUnitLink: '',
}

const store = createSlice({
    name: 'distributionUnit',
    initialState,
    reducers: {
        createDistributionUnit: (state, action) => {
            state.distributionUnits = [...state.distributionUnits, new DtoDistributionUnit(action.payload)]
        },
        deleteDistributionUnit: (state, action) => {
            state.distributionUnits = state.distributionUnits.filter(du => du.id !== action.payload)
        },
        receiveAllDistributionUnits: (state, action) => {
            state.distributionUnits = action.payload.map(entity => new DtoDistributionUnit(entity))
        },
        receiveDistributionUnit: (state, action) => {
            state.distributionUnit = new DtoDistributionUnit(action.payload)
        },
        receiveDistributionUnitLink: (state, action) => {
            state.distributionUnitLink = action.payload
        },
        resetDistributionUnitLink: (state) => {
            state.distributionUnitLink = ''
        },
        resetDistributionUnit: (state) => {
            state.distributionUnit = {}
            state.distributionUnitAssociations = []
        },
        receiveDistributionUnitAssociations: (state, action) => {
            state.distributionUnitAssociations = action.payload.map(entity => new DtoAssociatedStation(entity))
        },
        receiveDistributionUnitSectors: (state, action) => {
            state.distributionUnitSectors = action.payload.map(entity => new DtoDistributionUnitSector(entity))
        },
        receiveCaptureDownstreamDistribUnit: (state, action) => {
            state.captureDownstreamDistribUnits = action.payload.map(entity => new DtoCaptureDownstreamDistribUnit(entity))
        },
        receiveDistributionCounterTypes: (state, action) => {
            state.distributionCounterTypes = action.payload.map(entity => new DtoDistributionCounterType(entity))
        },
        receiveDistributionCounters: (state, action) => {
            state.distributionCounters = action.payload.map(entity => new DtoDistributionCounter(entity))
        },
    },
    extraReducers: {
        [DistributionUnitThunk.fetchDistributionUnits.fulfilled]: (state, action) => {
            state.distributionUnits = action.payload.map(entity => new DtoDistributionUnit(entity))
        },
    },
})

export const DistributionUnitActionConstant = store.actions
export default store.reducer

export const DistributionUnitStoreInfos = () => {
    const appStore = AppStore.getState()
    return {
        distributionUnit: {
            fetch: DistributionUnitAction.fetchDistributionUnit,
            store: appStore.DistributionUnitReducer.distributionUnit,
            propType: instanceOf(DtoDistributionUnit),
            reset: DistributionUnitActionConstant.resetDistributionUnit,
        },
        distributionUnits: {
            fetch: DistributionUnitAction.fetchDistributionUnits,
            store: appStore.DistributionUnitReducer.distributionUnits,
            propType: arrayOf(DtoDistributionUnit),
        },
        distributionUnitAssociations: {
            fetch: DistributionUnitAction.fetchDistributionUnitsAssociations,
            store: appStore.DistributionUnitReducer.distributionUnitAssociations,
            propType: arrayOf(DtoAssociatedStation),
        },
        distributionUnitSectors: {
            fetch: DistributionUnitAction.fetchDistributionUnitSectors,
            store: appStore.DistributionUnitReducer.distributionUnitSectors,
            propType: arrayOf(DtoDistributionUnitSector),
        },
    }
}