import i18n from 'simple-react-i18n'
import ApplicationConf from 'conf/ApplicationConf'
import fetch from 'isomorphic-fetch'
import { push } from '@lagunovsky/redux-react-router'
import { RECEIVE_ALL_USAGE, RECEIVE_USAGE, RESET_USAGE } from '../constants/UsageConstants'
import ToastrAction from 'toastr/actions/ToastrAction'
import WaitAction from 'wait/WaitAction'
import { checkAuth, checkError, getAuthorization, getJson } from '../../../../utils/ActionUtils'
import { PATH_REFERENCIAL_USAGE, PATH_REFERENCIAL_USAGE_ID } from '../../../../home/constants/RouteConstants'
import LogAction from '../../../../log/actions/LogAction'

const UsageAction = {

    receiveUsage(usage) {
        return { type: RECEIVE_USAGE, usage }
    },

    receiveUsages(usages) {
        return { type: RECEIVE_ALL_USAGE, usages }
    },

    promiseUsages() {
        return fetch(ApplicationConf.referencial.usages(), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
    },

    fetchUsage(code) {
        return (dispatch) => {
            return fetch(ApplicationConf.referencial.usage(code), {
                method: 'GET',
                headers: getAuthorization(),

            })
                .then(checkAuth)
                .then(getJson)
                .then((json = {}) => {
                    dispatch(UsageAction.receiveUsage(json))
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.usage} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError + i18n.usage))
                })
        }
    },

    fetchUsages() {
        return (dispatch) => {
            return UsageAction.promiseUsages()
                .then((json = []) => {
                    dispatch(UsageAction.receiveUsages(json))
                })
                .catch((err) => {
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.usages} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError + i18n.usages))
                })
        }
    },

    updateUsage(usage) {
        return (dispatch) => {
            return fetch(ApplicationConf.referencial.usages(), {
                method: 'PUT',
                headers: getAuthorization(),
                body: JSON.stringify(usage),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(json => {
                    if (json.update === 1) {
                        dispatch(ToastrAction.success(i18n.elementUpdateSuccess))
                    } else {
                        throw new Error()
                    }
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.usage} : ${err}`))
                    dispatch(ToastrAction.error(i18n.updateError + i18n.usage))
                })
        }
    },

    createUsage(usage) {
        return (dispatch) => {
            return fetch(ApplicationConf.referencial.usages(), {
                method: 'POST',
                headers: getAuthorization(),
                body: JSON.stringify(usage),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(json => {
                    if (json.id) {
                        dispatch(push(PATH_REFERENCIAL_USAGE_ID + json.id))
                        dispatch(ToastrAction.success(i18n.successfulCreation))
                    }
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.createError + i18n.usage} : ${err}`))
                    dispatch(ToastrAction.error(i18n.createError + i18n.usage))
                })
        }
    },

    deleteUsage(id) {
        return (dispatch) => {
            dispatch(WaitAction.waitStart())
            return fetch(ApplicationConf.referencial.usage(id), {
                method: 'DELETE',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then((json) => {
                    if (json.delete) {
                        dispatch(ToastrAction.success(i18n.elementDeleteSuccess))
                        dispatch(push(PATH_REFERENCIAL_USAGE))
                    }
                    dispatch(WaitAction.waitStop())
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.deleteError + i18n.usage} : ${err}`))
                    dispatch(ToastrAction.error(i18n.deleteError + i18n.usage))
                })
        }
    },

    reset() {
        return { type: RESET_USAGE }
    },

}

export default UsageAction
