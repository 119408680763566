/* eslint-disable camelcase */
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import { arrayOf } from '../../../../utils/StoreUtils'
import { getExport, setModal } from '../../../../utils/linkUtils'
import DtoInstallation from '../../../dto/installation/DtoInstallation'
import SieauAction from '../../../../components/sieau/SieauAction'
import Table from '../../../../components/datatable/Table'
import ProtectionModal from './modal/ProtectionModal'
import { getSandreLabel } from '../../../../utils/StringUtil'
import DtoSandreCode from '../../../../referencial/dto/DtoSandreCode'
import { SANDRE } from '../../../../referencial/constants/ReferencialConstants'

const InstallationProtectionsPanel = ({
    station = {},
    readMode = false,
    onChange = () => {},
    setPopup = () => {},
    sandreCodes = [],
}) => {
    const stationProtections = station.link_protections.map((p, index) => ({
        ...p,
        index,
    }))
    const tableData = stationProtections.map(c => ({
        ...c,
        protectionType: getSandreLabel(sandreCodes, SANDRE.INSTALLATIONS_PROTECTION, c.protectionType, true),
        localisation: getSandreLabel(sandreCodes, SANDRE.INSTALLATIONS_EMPLACEMENT, c.placing, true),
    }))
    const headers = ['localisation', 'protectionType', 'comment']
    const exportAction = getExport(tableData, i18n.noIntrudersProtections, headers)
    const actions = !readMode ? [{
        iconName: 'note_add',
        tooltip: i18n.add,
        onClick: () => setModal(i18n.newProtection, <ProtectionModal id={ station.id } saveResult={ r => onChange({ link_protections: [...stationProtections, r] }) }/>, setPopup),
    }, exportAction] : [exportAction]

    return (
        <Table
            title={ i18n.noIntrudersProtections }
            className='blue'
            condensed
            data={ tableData }
            type={{ headers }}
            sortable={ !!tableData.length }
            actions={ actions }
            alterable={ !readMode }
            onAlter={
                (element) => setModal(i18n.editProtection,
                    <ProtectionModal
                        id={ station.id }
                        protection={ stationProtections.find(c => element.index === c.index) }
                        saveResult={ r => onChange({
                            link_protections: [
                                ...stationProtections.filter(p => p.index !== element.index),
                                { ...r, index: element.index },
                            ],
                        })}
                    />
                    , setPopup)
            }
            deletable={ !readMode }
            onDelete={ (element) => onChange({ link_protections: stationProtections.filter(p => p.index !== element.index) }) }
        />
    )
}

InstallationProtectionsPanel.propTypes = {
    station: PropTypes.instanceOf(DtoInstallation),
    sandreCodes: arrayOf(DtoSandreCode),
    readMode: PropTypes.bool,
    onChange: PropTypes.func,
    setPopup: PropTypes.func,
}

const mapStateToProps = store => ({
    sandreCodes: store.ReferencialReducer.sandreCodes,
})

const mapDispatchToProps = {
    setPopup: SieauAction.setPopup,
}

export default connect(mapStateToProps, mapDispatchToProps)(InstallationProtectionsPanel)