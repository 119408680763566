import { some } from 'lodash'
import PowerSupplyTypeDto from 'materiel/components/powerSupply/dto/PowerSupplyTypeDto'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import Table from '../../../../components/datatable/Table'
import Checkbox from '../../../../components/forms/Checkbox'
import NumberField from '../../../../components/forms/NumberField'
import TelecomTypeDto from '../../../../materiel/components/telecom/dto/TelecomTypeDto'
import { hasValue } from '../../../../utils/NumberUtil'
import { EVENT_ACTION } from '../../../constants/PiezometryEventConstants'
import { getTitleCard, getYesOrNo } from '../EventFormUtils'

const EventTelecomCard = ({
    telecom = {},
    hasSignalTestAction = false,
    disabled = {},
    formActions = [],
    lastReplacementDate = '',
    setFormAction = () => {},
    onChangeTestSignal = () => {},
    getBooleanChoiceWithAction = () => {},
    telecomTypes = [],
    displaySupply = true,
    powerSupplyTypes = [],
    hasPowerSupply = true,
}) => {
    const hasAction = some(['ORANGE', 'BOUYGUES', 'SFR'], fai => formActions.find(a => a.managementRule === `TEST_SIGNAL_${fai}` && telecom.id === a.materielId))
    const faiTable = ['Orange', 'Bouygues', 'SFR'].reduce((acc, val) => {
        const action = formActions.find(a => a.managementRule === `TEST_SIGNAL_${val.toUpperCase()}` && a.materielId === telecom.id)
        acc[val] = (
            <NumberField
                value={action ? action.value : null}
                onChange={v => setFormAction(`TEST_SIGNAL_${val.toUpperCase()}`, hasValue(v) && v, telecom.id, hasValue(v))}
                floatValue
                {...disabled}
            />
        )
        return acc
    }, {})
    const powerSupplyType = powerSupplyTypes.find(t => t.id === telecom.powerSupplyType)
    return (
        <div className='card margin-top-1'>
            {getTitleCard(i18n.transmission, 'telecom', telecom, telecomTypes)}
            <div className='card-content margin-top-1'>
                {
                    hasPowerSupply && (
                        <div className='row no-margin'>
                            <div className='col s8'>
                                {
                                    displaySupply && (
                                        <div className='row no-margin'>
                                            <NumberField
                                                value={(formActions.find(a => a.managementRule === EVENT_ACTION.MESURE_TENSION_TELETRANSMISSION && a.materielId === telecom.id) || {}).value}
                                                title={i18n.transmissionVoltage}
                                                floatValue
                                                onChange={v => setFormAction(EVENT_ACTION.MESURE_TENSION_TELETRANSMISSION, v, telecom.id, hasValue(v))}
                                                {...disabled}
                                            />
                                        </div>
                                    )
                                }
                                <div className='row no-margin'>
                                    <h6>{`${i18n.lastReplacement} : ${lastReplacementDate}`}</h6>
                                </div>
                            </div>
                            <div className='col s3 offset-s1'>
                                <Checkbox
                                    label={i18n.replacement}
                                    checked={formActions.find(a => a.managementRule === EVENT_ACTION.REMPLACEMENT_PILES_TELETRANSMISSION && a.materielId === telecom.id)}
                                    onChange={v => setFormAction(EVENT_ACTION.REMPLACEMENT_PILES_TELETRANSMISSION, null, telecom.id, v)}
                                    active={disabled.active}
                                    disabled={powerSupplyType?.isNotReplaceable || disabled.disabled}
                                />
                            </div>
                        </div>
                    )
                }
                <div className='row no-margin'>
                    <div className='row no-margin valign-wrapper'>
                        <div className='col s8'>
                            <table>
                                {getYesOrNo()}
                                {getBooleanChoiceWithAction(i18n.transmissionTest, EVENT_ACTION.TEST_TRANSMISSION, telecom.id, disabled)}
                            </table>
                        </div>
                        <div className='col s3 offset-s1 padding-left-1'>
                            <Checkbox
                                label={i18n.signTest}
                                checked={hasAction || hasSignalTestAction}
                                onChange={onChangeTestSignal}
                                {...disabled}
                            />
                        </div>
                    </div>
                </div>
                {
                    (hasAction || hasSignalTestAction) && (
                        <div className='row no-margin padding-top-1'>
                            <Table
                                data={[faiTable]}
                                type={{ headers: ['Orange', 'Bouygues', 'SFR'] }}
                                sortable
                                condensed
                                className='smallCard padding-top-1'
                                showTitle={false}
                            />
                        </div>
                    )
                }
            </div>
        </div>
    )
}

EventTelecomCard.propTypes = {
    telecom: PropTypes.object,
    hasSignalTestAction: PropTypes.bool,
    disabled: PropTypes.object,
    formActions: PropTypes.arrayOf(PropTypes.object),
    lastReplacementDate: PropTypes.string,
    setFormAction: PropTypes.func,
    onChangeTestSignal: PropTypes.func,
    getBooleanChoiceWithAction: PropTypes.func,
    telecomTypes: PropTypes.arrayOf(PropTypes.instanceOf(TelecomTypeDto)),
    displaySupply: PropTypes.bool,
    powerSupplyTypes: PropTypes.arrayOf(PropTypes.instanceOf(PowerSupplyTypeDto)),
    hasPowerSupply: PropTypes.bool,
}

const matStateToProps = store => ({
    telecomTypes: store.TelecomReducer.telecomTypes,
    powerSupplyTypes: store.PowerSupplyReducer.powerSupplyTypes,
})

export default connect(matStateToProps)(EventTelecomCard)