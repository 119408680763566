import DtoExploitation from '../../exploitations/dto/DtoExploitation'
import DtoExploitationExportFull from '../../exploitations/dto/DtoExploitationExportFull'
import DtoDeclaration from '../../survey/dto/DtoDeclaration'
import DtoSurvey from '../../survey/dto/DtoSurvey'
import DtoSurveyBreakdown from '../../survey/dto/DtoSurveyBreakdown'
import DtoSurveyWithStats from '../../survey/dto/DtoSurveyWithStats'
import DtoSurveyForecast from '../../survey/dto/DtoSurveyForecast'
import DtoSurveyActualUse from '../../survey/dto/DtoSurveyActualUse'
import DtoSurveyActualConsumption from '../../survey/dto/DtoSurveyActualConsumption'
import DtoDeclarationVolumes from '../../survey/dto/DtoDeclarationVolumes'
import DtoDeclarationExportFull from '../../survey/dto/DtoDeclarationExportFull'
import DtoExportAgencySurvey from '../../survey/dto/DtoExportAgencySurvey'
import DtoTerritoryUgeVolume from '../../territory/dto/DtoTerritoryUgeVolume'
import DtoIntervenantDeclaration from 'survey/dto/DtoIntervenantDeclaration'
import DtoService from 'territory/dto/DtoService'
import DtoExploitationEvent from 'exploitations/dto/DtoExploitationEvent'
import DtoExploitationLight from 'exploitations/dto/DtoExploitationLight'
import DtoWaterTurnsRestriction from 'territory/dto/DtoWaterTurnsRestriction'
import DtoAgriTanksType from 'exploitations/dto/DtoAgriTanksType'
import DtoWaterTurnsSlot from 'exploitations/dto/DtoWaterTurnsSlot'
import DtoWaterTurns from 'exploitations/dto/DtoWaterTurns'
import DtoServiceSituation from 'exploitations/dto/DtoServiceSituation'
import DtoSummarySurvey from 'exploitations/dto/DtoSummarySurvey'
import { createSlice } from '@reduxjs/toolkit'
import AgriThunk from 'agriAdministration/actions/AgriThunk'
import DtoDroughtSectorRestriction from 'exploitations/dto/DtoDroughtSectorRestriction'
import DtoPAR from 'agriAdministration/dto/DtoPAR'
import DtoPARScenario from 'agriAdministration/dto/DtoPARScenario'
import DtoRuleScenarios from 'agriAdministration/dto/DtoRuleScenarios'
import DtoDeclarationWithStats from 'survey/dto/DtoDeclarationWithStats'
import DtoPAREvent from 'exploitations/dto/DtoPAREvent'
import DtoExploitationMatRep from 'exploitations/dto/DtoExploitationMatRep'

export const initialState = {
    surveys: [],
    survey: new DtoSurvey({}),
    surveysWithStats: [],
    exploitations: [],
    exploitationsExportFullData: [],
    exploitation: new DtoExploitation({}),
    exploitationVolumes: [],
    declaration: new DtoDeclaration({}),
    declarationContributors: [],
    lastDeclarationStats: {},
    declarationsExploitation: [],
    volumesSurveys: [],
    breakdownsSurvey: [],
    forecastsSurvey: [],
    forecastsUge: [],
    actualUsesSurvey: [],
    actualConsumptionsSurvey: [],
    exportAgencySurveys: [],
    exportFullSurvey: [],
    modesIrrigations: [],
    services: [],
    exploitationEvents: [],
    exploitationsAllEvents: [],
    restrictions: [],
    exploitationsLight: [],
    declarationsWarnings: [],
    agriTanksTypes: [],
    agriTanksType: {},
    waterTurnsSlots: [],
    waterTurns: [],
    servicesSituations: [],
    summarySurveys: [],
    droughtSectorsRestrictions: [],
    PARs: [],
    PAR: new DtoPAR({}),
    scenario: new DtoPARScenario({}),
    rulesScenarios: [],
    parEvents: [],
    surveyVolumesExploitation: [],
    matsRepartitions: [],
}

const store = createSlice({
    name: 'agri',
    initialState,
    reducers: {
        receiveTanksTypes: (state, action) => {
            state.agriTanksTypes = action.payload.map(t => new DtoAgriTanksType(t))
        },
        receiveTanksType: (state, action) => {
            state.agriTanksType = new DtoAgriTanksType(action.payload)
        },
        resetTanksTypes: (state) => {
            state.agriTanksTypes = []
            state.agriTanksType = {}
        },
        receiveExploitationEvents: (state, action) => {
            state.exploitationEvents = action.payload.map(t => new DtoExploitationEvent(t))
        },
        receiveAllExploitationsEvents: (state, action) => {
            state.exploitationsAllEvents = action.payload.map(t => new DtoExploitationEvent(t))
        },
        receivePAREvents: (state, action) => {
            state.parEvents = action.payload.map(e => new DtoPAREvent(e))
        },
        receiveSurveys: (state, action) => {
            state.surveys = action.payload.map(t => new DtoSurvey(t))
        },
        receiveSurveysWithStats: (state, action) => {
            state.surveysWithStats = action.payload.map(t => new DtoSurveyWithStats(t))
        },
        receiveSurvey: (state, action) => {
            state.survey = new DtoSurvey(action.payload)
        },
        receiveExploitations: (state, action) => {
            state.exploitations = action.payload.map(exploitation => new DtoExploitation(exploitation))
        },
        receiveExploitationsLight: (state, action) => {
            state.exploitationsLight = action.payload.map(exploitation => new DtoExploitationLight(exploitation))
        },
        receiveExploitationsExportFullData: (state, action) => {
            state.exploitationsExportFullData = action.payload.map(exploitation => new DtoExploitationExportFull(exploitation))
        },
        receiveDeclaration: (state, action) => {
            state.declaration = new DtoDeclaration(action.payload)
        },
        receiveDeclarationContributors: (state, action) => {
            state.declarationContributors = action.payload.map(exploitation => new DtoIntervenantDeclaration(exploitation))
        },
        receiveModesIrrigations: (state, action) => {
            state.modesIrrigations = action.payload
        },
        receiveLastDeclarationStats: (state, action) => {
            state.lastDeclarationStats = new DtoDeclaration(action.payload)
        },
        receiveDeclarationsStatsExploitation: (state, action) => {
            state.declarationsExploitation = (action.payload.length ? action.payload : []).map((d) => new DtoDeclarationWithStats(d))
        },
        receiveExploitation: (state, action) => {
            state.exploitation = new DtoExploitation(action.payload)
        },
        receiveExploitationVolumes: (state, action) => {
            state.exploitationVolumes = action.payload.map(exploitation => new DtoDeclarationVolumes(exploitation))
        },
        receiveSurveysVolumes: (state, action) => {
            state.volumesSurveys = action.payload.map(exploitation => new DtoTerritoryUgeVolume(exploitation))
        },
        receiveBreakdownsSurvey: (state, action) => {
            state.breakdownsSurvey = action.payload.map(exploitation => new DtoSurveyBreakdown(exploitation))
        },
        receiveForecastsSurvey: (state, action) => {
            state.forecastsSurvey = action.payload.map(exploitation => new DtoSurveyForecast(exploitation))
        },
        receiveForecastsUge: (state, action) => {
            state.forecastsUge = action.payload.map(exploitation => new DtoSurveyForecast(exploitation))
        },
        receiveActualusesSurvey: (state, action) => {
            state.actualUsesSurvey = action.payload.map(exploitation => new DtoSurveyActualUse(exploitation))
        },
        receiveConsumptionsSurvey: (state, action) => {
            state.actualConsumptionsSurvey = action.payload.map(exploitation => new DtoSurveyActualConsumption(exploitation))
        },
        receiveExportAgencySurvey: (state, action) => {
            state.exportAgencySurveys = action.payload.map(exploitation => new DtoExportAgencySurvey(exploitation))
        },
        receiveSurveyDataExportFull: (state, action) => {
            state.exportFullSurvey = action.payload.map(exploitation => new DtoDeclarationExportFull(exploitation))
        },
        receiveServices: (state, action) => {
            state.services = action.payload.map(exploitation => new DtoService(exploitation))
        },
        receiveRestrictions: (state, action) => {
            state.restrictions = action.payload.map(exploitation => new DtoWaterTurnsRestriction(exploitation))
        },
        receiveSlots: (state, action) => {
            state.waterTurnsSlots = action.payload.map(exploitation => new DtoWaterTurnsSlot(exploitation))
        },
        receiveWaterTurns: (state, action) => {
            state.waterTurns = action.payload.map(exploitation => new DtoWaterTurns(exploitation))
        },
        receiveDeclarationsWarnings: (state, action) => {
            state.declarationsWarnings = action.payload
        },
        receiveServicesSituations: (state, action) => {
            state.servicesSituations = action.payload.map(d => new DtoServiceSituation(d))
        },
        receiveSummarySurveys: (state, action) => {
            state.summarySurveys = action.payload.map(d => new DtoSummarySurvey(d))
        },
        receiveDroughtSectorsRestrictions: (state, action) => {
            state.droughtSectorsRestrictions = action.payload.map(d => new DtoDroughtSectorRestriction(d))
        },
        receivePARs: (state, action) => {
            state.PARs = action.payload.map(p => new DtoPAR(p))
        },
        receivePAR: (state, action) => {
            state.PAR = new DtoPAR(action.payload)
        },
        receiveScenario: (state, action) => {
            state.scenario = new DtoPARScenario(action.payload)
        },
        receiveRulesScenarios: (state, action) => {
            state.rulesScenarios = action.payload.map(r => new DtoRuleScenarios(r))
        },
        receiveSurveyVolumesExploitation: (state, action) => {
            state.surveyVolumesExploitation = action.payload || []
        },
        receiveAllMatsRepartitions: (state, action) => {
            state.matsRepartitions = action.payload.map(r => new DtoExploitationMatRep(r))
        },
    },
    extraReducers: {
        [AgriThunk.fetchExploitationsLight.fulfilled]: (state, action) => {
            state.exploitationsLight = action.payload.map(exploitation => new DtoExploitationLight(exploitation))
        },
        [AgriThunk.fetchSurveysWithStats.fulfilled]: (state, action) => {
            state.surveysWithStats = action.payload.map(t => new DtoSurveyWithStats(t))
        },
    },
})

export const AgriActionConstant = store.actions
export default store.reducer
