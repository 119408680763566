import { INSTALLATION_TYPE } from 'installation/constants/InstallationConstants'
import { BLUE, GREEN, GREY, ORANGE } from '../components/constants/ColorConstant'
import { getColor } from './ColorUtil'


const getControlColor = (control) => {
    return control && [1, 2, 3, 4].includes(parseInt(control)) ? [GREY, GREEN, BLUE, ORANGE][parseInt(control) - 1] : GREY
}

const getControlObject = (control, content) => {
    const color = getControlColor(control)
    return {
        className: `width-0 colorful-card card-${getColor(color)}`,
        color: 'white',
        // tooltip: getLabel(orderBy(statusList, 'code)'), control || 1),
        value: content,
    }
}

const isAepInstallation = (installationType) => [
    INSTALLATION_TYPE.BOREHOLE,
    INSTALLATION_TYPE.STEP,
    INSTALLATION_TYPE.CAPTURE,
    INSTALLATION_TYPE.LIFTING_STATION,
    INSTALLATION_TYPE.PUMPING,
    INSTALLATION_TYPE.TREATMENT,
    INSTALLATION_TYPE.TANK,
    INSTALLATION_TYPE.AEP_PRODUCTION,
    INSTALLATION_TYPE.AEP_DISINFECTION,
    INSTALLATION_TYPE.AEP_OVERPRESSURE,
    INSTALLATION_TYPE.SECTOR_COUNT,
    INSTALLATION_TYPE.NETWORK,
].includes(installationType)

export { getControlColor, getControlObject, isAepInstallation }
