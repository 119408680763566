import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import { Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid } from '@mui/material'
import Button from 'components/forms/Button'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import SelectionSelect from 'components/forms/specific/SelectionSelect'
import ExportAction from 'export/actions/ExportAction'
import { getLogin } from 'utils/SettingUtils'
import moment from 'moment'
import DtoInstallation from 'installation/dto/installation/DtoInstallation'
import StationAction from 'station/actions/StationAction'
import SieauAction from 'components/sieau/SieauAction'
import { getStationTitle } from 'utils/StationUtils'
import ProgressCard from '../../../components/card/ProgressCard'
import { genericPromise } from '../../../utils/ActionUtils'
import ApplicationConf from '../../../conf/ApplicationConf'
import SimpleDatePicker from '../../../components/forms/SimpleDatePicker'
import Card from '../../../components/card/Card'
import ToastrAction from '../../../toastr/actions/ToastrAction'
import { template, orderBy } from 'lodash'
import { arrayOf } from '../../../utils/StoreUtils'
import DtoFileFTP from 'station/dto/DtoFileFTP'

const InstallationsExport = ({
    stationTypes,
}) => {
    const {
        installation,
        filesFtp,
    } = useSelector(store => ({
        installation: store.InstallationReducer.installation,
        filesFtp: store.StationReducer.filesFtp,
    }), shallowEqual)

    const dispatch = useDispatch()

    const [count, setCount] = useState(0)
    const [startDate, setStartDate] = useState(null)
    const [endDate, setEndDate] = useState(null)
    const [selection, setSelection] = useState(-1)
    const [dialogIsOpen, setDialogIsOpen] = useState(false)
    const [calculationStatus, setCalculationStatus] = useState('ATTENTE')

    useEffect(() => {
        dispatch(SieauAction.forceFetch('title', [{
            title: i18n[stationTypes[0]],
            href: stationTypes[0],
        }, {
            title: getStationTitle({ id: installation.id, code: installation.code, name: installation.name, designation: installation.designation }),
            href: `station/${stationTypes[0]}/${installation.id}`,
        }, {
            title: i18n.export,
            href: `station/${stationTypes[0]}/'${installation.id}/export`,
        }]))
    }, [])

    useEffect(() => {
        dispatch(StationAction.fetchFilesFtp('DATA', `STEP_flux_${installation.code}*.csv`))
        const timeout = setTimeout(() => {
            dispatch(StationAction.fetchFilesFtp('DATA', `STEP_flux_${installation.code}*.csv`))
            setCount(count+1)
        }, 10000)

        return () => clearTimeout(timeout)
    }, [count])

    const launchExport = () => {
        const params = {
            idInstallation: installation.id,
            codeInstallation: installation.code,
            startDate,
            endDate,
            codeSelection: selection,
        }
        const exportTmpObj = {
            id: 0,
            exportType: 'stepFlow',
            login: getLogin(),
            creationDate: moment().valueOf(),
            status: 1,
            stationType: 'installation',
            params: JSON.stringify(params),
        }
        dispatch(ExportAction.runExportTmp(exportTmpObj))
    }

    const setParameterSelection = (_, newSelection) => {
        setSelection(newSelection)
    }

    const launchCalculation = () => {
        if (selection && selection != -1) {
            setCalculationStatus('ENCOURS')
            genericPromise(ApplicationConf.referencial.launchStepMoleculeSigniCalculation(), 'POST', {
                startDate,
                endDate,
                selectionCode: selection,
                codeSTEP: installation.code,
            }, {
                404: () => setCalculationStatus('ERROR'),
                500: () => setCalculationStatus('ERROR'),
            })
                .then(() => {
                    setCalculationStatus('SUCCESS')
                })
        } else {
            dispatch(ToastrAction.error(template(i18n.fieldNotSet)({ field: i18n.selection })))
        }
    }

    const calculationDialog = () => {
        return (
            <Dialog
                onClose={() => setDialogIsOpen(false)}
                fullWidth
                maxWidth='lg'
                open={dialogIsOpen}
            >
                <DialogTitle>{i18n.moleculeSigniCalculation}</DialogTitle>
                <DialogContent>
                    <div style={{ height: 500 }}>
                        <div className='row no-margin'>
                            <SimpleDatePicker
                                col={3}
                                value={startDate}
                                id={'startDate'}
                                label={i18n.beginDate}
                                onChange={setStartDate}
                            />
                            <SimpleDatePicker
                                col={3}
                                value={endDate}
                                id={'endDate'}
                                label={i18n.endDate}
                                startDate={startDate}
                                onChange={setEndDate}
                            />
                            <SelectionSelect
                                col={6}
                                value={selection}
                                onChange={setParameterSelection}
                            />
                        </div>
                        <div className='row no-margin'>
                            {
                                calculationStatus === 'ATTENTE' ? (
                                    <Button
                                        col={3}
                                        title={i18n.recalculateSelection}
                                        onClick={launchCalculation}
                                        style={{ width: 400 }}
                                    />
                                ) : (calculationStatus === 'ENCOURS' ? (
                                    <ProgressCard indeterminate message={i18n.calculationInProgressWithTime}/>
                                ) : (calculationStatus === 'SUCCESS' ? (
                                    <h5 style={{ color: 'green' }}>{ i18n.updateSuccess }</h5>
                                ) : <h5 style={{ color: 'red' }}>{ `${i18n.error}. ` + `\nAvez vous bien un qualitomètre associé à cette STEP ?\nSinon ${i18n.pleaseContactTheAdmin}` }</h5>))
                            }
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        setDialogIsOpen(false)
                    }} title={i18n.close}
                    >
                        {i18n.close}
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }

    return (
        <div style={{ padding: 15 }}>
            <Card>
                <Grid container direction='row' alignItems='center' style={{ padding: 10 }}>
                    <Grid item xs={12} >
                        <SimpleDatePicker
                            col={2}
                            value={startDate}
                            id={'startDate'}
                            label={i18n.beginDate}
                            onChange={setStartDate}
                        />
                        <SimpleDatePicker
                            col={2}
                            value={endDate}
                            id={'endDate'}
                            label={i18n.endDate}
                            startDate={startDate}
                            onChange={setEndDate}
                        />
                        <SelectionSelect
                            col={2}
                            value={selection}
                            onChange={setParameterSelection}
                        />
                        <Button
                            col={3}
                            title={i18n.moleculeSigniCalculation}
                            onClick={() => {
                                setCalculationStatus('ATTENTE')
                                setDialogIsOpen(true)
                            }}
                            style={{ width: 400 }}
                        />
                        <Button
                            col={2}
                            title={i18n.calculateFlux}
                            onClick={launchExport}
                            style={ { width: 200, marginLeft: 10 } }
                        />
                    </Grid>
                    <Grid item xs={12} style={{ marginBottom: 15 }}>
                        <Divider style={{ width: '90%', margin: 'auto', backgroundColor: '#161832' }} />
                    </Grid>
                    {filesFtp && (
                        <Grid item xs={12}>
                            <p style={{ fontSize: 16, padding: 5, margin: 0 }}>{i18n.exportList}</p>
                            {orderBy(filesFtp.filter(f => !f.isDirectory), 'updateDate', 'desc').map((d) => {
                                return (
                                    <a className='col s12' style={{ padding: 5 }} href={d.url}>
                                        {d.name}
                                    </a>
                                )
                            })}
                        </Grid>
                    )}
                    {calculationDialog()}
                </Grid>
            </Card>
        </div>
    )
}

InstallationsExport.propTypes = {
    installation: PropTypes.instanceOf(DtoInstallation),
    filesFtp: arrayOf(DtoFileFTP),
    stationTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
}

export default InstallationsExport