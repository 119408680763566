import Card from 'components/card/Card'
import Checkbox from 'components/forms/Checkbox'
import PropTypes from 'prop-types'
import React, { useMemo, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import ResourceDto from 'resource/dto/ResourceDto'
import { nbPerPageLabelShort } from 'referencial/constants/ReferencialConstants'
import DtoDistributionUnit from 'distributionUnit/dto/DtoDistributionUnit'
import DtoHydrometricStation from 'hydrometry/dto/DtoHydrometricStation'
import DtoInstallation from 'installation/dto/installation/DtoInstallation'
import DtoPiezometerLight from 'piezometry/dto/DtoPiezometerLight'
import PluviometerDto from 'pluviometry/dto/PluviometerDto'
import DtoProductionUnit from 'productionUnit/dto/DtoProductionUnit'
import DtoQualitometerLight from 'quality/dto/DtoQualitometerLight'
import {
    DISTRIBUTION,
    HYDRO,
    INSTALLATION,
    PIEZO,
    PLUVIO,
    PRODUCTION,
    QUALITO,
    RESOURCE,
    CATCHMENT,
} from 'administration/components/user/constants/ApplicationHabilitationConstants'
import DtoUserStation from 'administration/components/user/dto/DtoUserStation'
import i18n from 'simple-react-i18n'
import Table from 'components/datatable/Table'
import SelectionTableModal from 'components/modal/SelectionTableModal'
import SimpleFilterSelect from 'components/forms/specific/SimpleFilterSelect'
import Input from 'components/forms/Input'
import { getStationTypeFromUserConstants } from 'utils/StationUtils'
import { searchAllCharacters } from 'utils/StringUtil'
import { SINGLE } from 'administration/components/user/constants/UserStationConstants'
import UserAction from 'administration/components/user/actions/UserAction'
import { AccordionDetailsMUI, AccordionMUI, AccordionSummaryMUI } from 'components/styled/Accordions'
import { Grid } from '@mui/material'

const SelectedStationDialog = ({
    isOpen = false,
    setOpen = () => { },
    stations = [],
    defaultSelected = [],
    stationType = '',
}) => {
    const dispatch = useDispatch()
    const {
        userStations,
        user,
    } = useSelector(store => ({
        userStations: store.UserReducer.userStations,
        user: store.UserReducer.user,
    }), shallowEqual)

    return isOpen && (
        <SelectionTableModal
            isOpen={isOpen}
            onClose={() => setOpen(false)}
            onValidate={newStationHabilitation => {
                const otherHabs = userStations.filter(s => s.stationType !== stationType)
                const selectedStationHabs = newStationHabilitation.map(id => {
                    return new DtoUserStation({
                        stationCode: `${id}`,
                        stationType,
                        login: user.login,
                        selectedMode: SINGLE,
                    })
                })
                dispatch(UserAction.updateUserStations(user.login, [...otherHabs, ...selectedStationHabs])).then(() => {
                    setOpen(false)
                })
            }}
            title={`${i18n.selectStationsOfType} ${i18n[getStationTypeFromUserConstants(stationType)].toLowerCase()}`}

            listData={stations}
            listHeaders={['code', 'city', 'name']}
            listTitle={i18n.nonSelectedStations}

            defaultSelectionList={defaultSelected}
            selectionListHeaders={['code', 'city', 'name']}
            selectionListTitle={i18n.selectedStations}

            maxHeightTable={'50vh'}
            filterField={({
                filter,
                setFilter,
            }) => (
                <Grid container spacing={'10px'} style={{ paddingTop: '5px' }}>
                    <Grid item xs={6}>
                        <SimpleFilterSelect
                            stationType={getStationTypeFromUserConstants(stationType)}
                            onChange={(resultFilter, newFilter) => {
                                setFilter(prev => ({ ...prev, resultFilter, filter: newFilter }))
                            }}
                            stations={stations}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Input
                            title={i18n.search}
                            value={filter.searchValue}
                            onChange={searchValue => setFilter(prev => ({ ...prev, searchValue }))}
                        />
                    </Grid>
                </Grid>
            )}
            filterFunction={(list, { searchValue, resultFilter = [], filter }) => {
                const searchValueFormat = searchAllCharacters(searchValue)
                const filterSearchValue = searchValue ? list.filter(p => searchAllCharacters(['code', 'city', 'name'].map(key => p[key])).includes(searchValueFormat)) : list
                return filter !== -1 ? resultFilter.map(s => filterSearchValue.find(e => e.id === s.id)).filter(e => !!e) : filterSearchValue
            }}
        />
    )
}

SelectedStationDialog.propTypes = {
    isOpen: PropTypes.bool,
    setOpen: PropTypes.func,
    stationType: PropTypes.oneOf([PIEZO, QUALITO, HYDRO, PLUVIO, PRODUCTION, DISTRIBUTION, INSTALLATION, CATCHMENT, RESOURCE]),
    defaultSelected: PropTypes.arrayOf(PropTypes.number),
    stations: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.instanceOf(DtoQualitometerLight)),
        PropTypes.arrayOf(PropTypes.instanceOf(DtoPiezometerLight)),
        PropTypes.arrayOf(PropTypes.instanceOf(DtoHydrometricStation)),
        PropTypes.arrayOf(PropTypes.instanceOf(DtoInstallation)),
        PropTypes.arrayOf(PropTypes.instanceOf(DtoDistributionUnit)),
        PropTypes.arrayOf(PropTypes.instanceOf(DtoProductionUnit)),
        PropTypes.arrayOf(PropTypes.instanceOf(PluviometerDto)),
        PropTypes.arrayOf(PropTypes.instanceOf(ResourceDto)),
        // add catchment
    ]),
}

const BasicHabilitationAccordion = ({
    stationType = '',
    title = '',
    stations = [],
}) => {
    const dispatch = useDispatch()
    const {
        userStations,
        cities,
        userHabilitations,
        user,
    } = useSelector(store => ({
        userStations: store.UserReducer.userStations,
        cities: store.CityReducer.cities,
        userHabilitations: store.UserReducer.userHabilitations,
        user: store.UserReducer.user,
    }), shallowEqual)

    const stationHabilitations = useMemo(() => {
        const habFiltered = userStations.filter(s => s.stationType === stationType)
        return habFiltered.map(h => parseInt(h.stationCode))
    }, [stationType, userStations])

    const isAllSelected = useMemo(() => {
        return userHabilitations.some(hab => hab.habilitation === `${stationType}_ALL`)
    }, [stationType, userHabilitations])

    const getListHabilitationStation = (isCheck) => {
        if (isCheck) {
            return userStations.filter(s => s.selectedMode === SINGLE && s.stationType !== stationType)
        }
        return userStations
    }

    const getListHabilitation = (isCheck) => {
        const habilitation = `${stationType}_ALL`
        if (!isCheck) {
            return userHabilitations.filter(hab => hab.habilitation !== habilitation)
        }
        return [
            ...userHabilitations,
            {
                login: user.login,
                habilitation,
            },
        ]
    }

    const [isOpen, setOpen] = useState(false)

    const stationsFormated = useMemo(() => {
        return stationHabilitations.map(id => {
            const station = stations.find(s => s.id == id)
            if (!station) {
                return {}
            }
            const code = station.designation ? `${station.code}/${station.designation}` : station.code
            const cityFound = station.townCode ? cities.find(c => c.code === station.townCode) : undefined
            const city = cityFound ? `${cityFound.name} - [${cityFound.code}]` : ''
            return {
                id: station.id,
                code,
                name: station.name,
                city,
            }
        }).filter(s => !!s)
    }, [cities, stationHabilitations, stations])

    const valueSelected = `(${stationHabilitations.length}/${stations.length})`

    const stationActions = [{
        iconName: 'edit',
        onClick: () => setOpen(true),
    }]

    return (
        <>
            <AccordionMUI defaultExpanded={false} className='margin-bottom-1'>
                <AccordionSummaryMUI>
                    <span className='bold'>{title}</span>
                </AccordionSummaryMUI>
                <AccordionDetailsMUI>
                    <div className='row no-margin'>
                        <div className='col s12 padding-bottom-1'>
                            <Checkbox
                                checked={isAllSelected}
                                label={i18n.allStations}
                                col={12}
                                onChange={isCheck => {
                                    const listHabilitation = getListHabilitation(isCheck)
                                    const listHabilitationStation = getListHabilitationStation(isCheck)
                                    dispatch(UserAction.updateUserHabilitations(user.login, listHabilitation))
                                    dispatch(UserAction.updateUserStations(user.login, listHabilitationStation))
                                }}
                            />
                        </div>
                    </div>
                    {
                        !isAllSelected && (
                            <div className='row no-margin'>
                                <Card title={`${i18n.selectedStations} ${valueSelected}`} actions={stationActions}>
                                    <Table
                                        type={{ headers: ['code', 'name', 'city'] }}
                                        data={stationsFormated}
                                        showNbElements={false}
                                        showTitle={false}
                                        nbPerPageLabel={nbPerPageLabelShort}
                                        paging
                                        condensed
                                        sortable
                                    />
                                </Card>
                            </div>
                        )
                    }
                </AccordionDetailsMUI>
            </AccordionMUI>
            <SelectedStationDialog
                isOpen={isOpen}
                setOpen={setOpen}
                stationType={stationType}
                stations={stations}
                defaultSelected={stationHabilitations}
            />
        </>
    )
}

BasicHabilitationAccordion.propTypes = {
    stationType: PropTypes.oneOf([PIEZO, QUALITO, PLUVIO, HYDRO, PRODUCTION, DISTRIBUTION, INSTALLATION, CATCHMENT, RESOURCE]),
    title: PropTypes.string,
    stations: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.instanceOf(DtoQualitometerLight)),
        PropTypes.arrayOf(PropTypes.instanceOf(DtoPiezometerLight)),
        PropTypes.arrayOf(PropTypes.instanceOf(DtoHydrometricStation)),
        PropTypes.arrayOf(PropTypes.instanceOf(DtoInstallation)),
        PropTypes.arrayOf(PropTypes.instanceOf(DtoDistributionUnit)),
        PropTypes.arrayOf(PropTypes.instanceOf(DtoProductionUnit)),
        PropTypes.arrayOf(PropTypes.instanceOf(PluviometerDto)),
        PropTypes.arrayOf(PropTypes.instanceOf(ResourceDto)),
        // add catchment
    ]),
}

const HabilitationAccordion = ({
    stationType = '',
    title = '',
    stations = [],
    noNetworkDisplay = false,
}) => {
    const dispatch = useDispatch()
    const {
        networks,
        contributors,
        userHabilitations,
        userStations,
        cities,
        user,
    } = useSelector(store => ({
        networks: store.NetworkReducer.networks,
        contributors: store.ContributorReducer.contributors,
        userHabilitations: store.UserReducer.userHabilitations,
        userStations: store.UserReducer.userStations,
        cities: store.CityReducer.cities,
        user: store.UserReducer.user,
    }), shallowEqual)

    const contributorHabilitations = useMemo(() => {
        const contributorHabsFiltered = userHabilitations.filter(c => c.habilitation.startsWith(`${stationType}_CONTRIB_`))
        return contributorHabsFiltered.map(n => parseInt(n.habilitation.split(`${stationType}_CONTRIB_`)[1]))
    }, [stationType, userHabilitations])

    const networkHabilitations = useMemo(() => {
        const networkHabsFiltered = userHabilitations.filter(n => n.habilitation.startsWith(`${stationType}_NETWORK_`))
        return networkHabsFiltered.map(n => parseInt(n.habilitation.split(`${stationType}_NETWORK_`)[1]))
    }, [stationType, userHabilitations])

    const stationHabilitations = useMemo(() => {
        const habFiltered = userStations.filter(s => s.stationType === stationType)
        return habFiltered.map(h => parseInt(h.stationCode))
    }, [stationType, userStations])

    const isAllSelected = useMemo(() => {
        return userHabilitations.some(hab => hab.habilitation === `${stationType}_ALL`)
    }, [stationType, userHabilitations])

    const getListHabilitationStation = (isCheck) => {
        if (isCheck) {
            return userStations.filter(s => s.selectedMode === SINGLE && s.stationType !== stationType)
        }
        return userStations
    }

    const getListHabilitation = (isCheck) => {
        const habilitation = `${stationType}_ALL`
        if (!isCheck) {
            return userHabilitations.filter(hab => hab.habilitation !== habilitation)
        }
        return [
            ...userHabilitations,
            {
                login: user.login,
                habilitation,
            },
        ]
    }

    const [isDialogStationOpen, setDialogStationOpen] = useState(false)
    const [isDialogContributorOpen, setDialogContributorOpen] = useState(false)
    const [isDialogNetworkOpen, setDialogNetworkOpen] = useState(false)

    const contributorsFormated = contributorHabilitations.map(id => contributors.find(n => n.id === id)).filter(s => !!s)

    const networksFormated = networkHabilitations.map(id => networks.find(n => n.id === id)).filter(s => !!s)

    const stationsFormated = useMemo(() => {
        return stationHabilitations.map(id => {
            const station = stations.find(s => s.id == id)
            if (!station) {
                return undefined
            }
            const code = station.designation ? `${station.code}/${station.designation}` : station.code
            const cityFound = station.townCode ? cities.find(c => c.code === station.townCode) : undefined
            const city = cityFound ? `${cityFound.name} - [${cityFound.code}]` : ''
            return {
                id: station.id,
                code,
                name: station.name,
                city,
            }
        }).filter(s => !!s)
    }, [cities, stationHabilitations, stations])

    const valueSelected = `(${stationsFormated.length}/${stations.length})`
    const contributorsSelected = `(${contributorsFormated.length}/${contributors.length})`
    const networksSelected = `(${networksFormated.length}/${networks.length})`

    const stationActions = [{
        iconName: 'edit',
        onClick: () => setDialogStationOpen(true),
    }]
    const contributorActions = [{
        iconName: 'edit',
        onClick: () => setDialogContributorOpen(true),
    }]
    const networkActions = [{
        iconName: 'edit',
        onClick: () => setDialogNetworkOpen(true),
    }]

    return (
        <>
            <AccordionMUI defaultExpanded={false} className='margin-bottom-1'>
                <AccordionSummaryMUI>
                    <span className='bold'>{title}</span>
                </AccordionSummaryMUI>
                <AccordionDetailsMUI>
                    <div className='row no-margin'>
                        <div className='col s12 padding-bottom-1'>
                            <Checkbox
                                checked={isAllSelected}
                                label={i18n.allStations}
                                col={12}
                                onChange={isCheck => {
                                    const listHabilitation = getListHabilitation(isCheck)
                                    const listHabilitationStation = getListHabilitationStation(isCheck)
                                    dispatch(UserAction.updateUserHabilitations(user.login, listHabilitation))
                                    dispatch(UserAction.updateUserStations(user.login, listHabilitationStation))
                                }}
                            />
                        </div>
                    </div>
                    {
                        !isAllSelected && (
                            <div className='row no-margin'>
                                <Card title={`${i18n.selectedStations} ${valueSelected}`} actions={stationActions}>
                                    <Table
                                        type={{ headers: ['code', 'name', 'city'] }}
                                        data={stationsFormated}
                                        showNbElements={false}
                                        showTitle={false}
                                        nbPerPageLabel={nbPerPageLabelShort}
                                        paging
                                        condensed
                                        sortable
                                    />
                                </Card>
                                <Card title={`${i18n.contributors} ${contributorsSelected}`} actions={contributorActions}>
                                    <Table
                                        type={{ headers: ['siret', 'name', 'mnemonique', 'postcode', 'city', 'phoneTel', 'domain', 'sandre', 'status'] }}
                                        data={contributorsFormated}
                                        showNbElements={false}
                                        showTitle={false}
                                        nbPerPageLabel={nbPerPageLabelShort}
                                        paging
                                        condensed
                                        sortable
                                    />
                                </Card>
                                {!noNetworkDisplay && (<Card title={`${i18n.networks} ${networksSelected}`} actions={networkActions}>
                                    <Table
                                        type={{ headers: ['code', 'name', 'mnemonic', 'finality', 'dce', 'sandreCode'] }}
                                        data={networksFormated}
                                        showNbElements={false}
                                        showTitle={false}
                                        nbPerPageLabel={nbPerPageLabelShort}
                                        paging
                                        condensed
                                        sortable
                                    />
                                </Card>)}
                            </div>
                        )
                    }
                </AccordionDetailsMUI>
            </AccordionMUI>
            <SelectedStationDialog
                isOpen={isDialogStationOpen}
                setOpen={setDialogStationOpen}
                stationType={stationType}
                stations={stations}
                defaultSelected={stationHabilitations}
            />
            <SelectionTableModal
                isOpen={isDialogContributorOpen}
                onClose={() => setDialogContributorOpen(false)}
                onValidate={newContributorHabilitation => {
                    const otherHabs = userHabilitations.filter(s => !s.habilitation.startsWith(`${stationType}_CONTRIB_`))
                    const contributorsHab = newContributorHabilitation.map(id => ({
                        login: user.login,
                        habilitation: `${stationType}_CONTRIB_${id}`,
                    }))
                    dispatch(UserAction.updateUserHabilitations(user.login, [...otherHabs, ...contributorsHab])).then(() => {
                        dispatch(UserAction.fetchUserHabilitations(user.login)).then(() => setDialogContributorOpen(false))
                    })
                }}
                title={i18n.selectContributors}

                listData={contributors}
                listHeaders={['siret', 'name', 'mnemonique', 'phoneTel']}
                listTitle={i18n.contributors}

                defaultSelectionList={contributorHabilitations}
                selectionListHeaders={['siret', 'name', 'mnemonique', 'phoneTel']}
                selectionListTitle={i18n.selectedContributors}

                maxHeightTable={'50vh'}
                filterField={({
                    filter,
                    setFilter,
                }) => (
                    <Grid container spacing={'10px'} style={{ paddingTop: '5px' }}>
                        <Grid item xs={6}>
                            <Input
                                title={i18n.search}
                                value={filter.searchValue}
                                onChange={searchValue => setFilter(prev => ({ ...prev, searchValue }))}
                            />
                        </Grid>
                    </Grid>
                )}
                filterFunction={(list, { searchValue }) => {
                    const searchValueFormat = searchAllCharacters(searchValue)
                    return searchValue ? list.filter(p => searchAllCharacters(['siret', 'name', 'mnemonique', 'phoneTel'].map(key => p[key])).includes(searchValueFormat)) : list
                }}
            />
            <SelectionTableModal
                isOpen={isDialogNetworkOpen}
                onClose={() => setDialogNetworkOpen(false)}
                onValidate={newContributorHabilitation => {
                    const otherHabs = userHabilitations.filter(s => !s.habilitation.startsWith(`${stationType}_NETWORK_`))
                    const contributorsHab = newContributorHabilitation.map(id => ({
                        login: user.login,
                        habilitation: `${stationType}_NETWORK_${id}`,
                    }))
                    dispatch(UserAction.updateUserHabilitations(user.login, [...otherHabs, ...contributorsHab])).then(() => {
                        dispatch(UserAction.fetchUserHabilitations(user.login)).then(() => setDialogNetworkOpen(false))
                    })
                }}
                title={i18n.selectNetworks}

                listData={networks}
                listHeaders={['code', 'name', 'mnemonic', 'sandreCode']}
                listTitle={i18n.networks}

                defaultSelectionList={networkHabilitations}
                selectionListHeaders={['code', 'name', 'mnemonic', 'sandreCode']}
                selectionListTitle={i18n.selectedNetworks}

                maxHeightTable={'50vh'}
                filterField={({
                    filter,
                    setFilter,
                }) => (
                    <Grid container spacing={'10px'} style={{ paddingTop: '5px' }}>
                        <Grid item xs={6}>
                            <Input
                                title={i18n.search}
                                value={filter.searchValue}
                                onChange={searchValue => setFilter(prev => ({ ...prev, searchValue }))}
                            />
                        </Grid>
                    </Grid>
                )}
                filterFunction={(list, { searchValue }) => {
                    const searchValueFormat = searchAllCharacters(searchValue)
                    return searchValue ? list.filter(p => searchAllCharacters(['code', 'name', 'mnemonic', 'sandreCode'].map(key => p[key])).includes(searchValueFormat)) : list
                }}
            />
        </>
    )
}

HabilitationAccordion.propTypes = {
    stationType: PropTypes.oneOf([PIEZO, QUALITO, PLUVIO, HYDRO, PRODUCTION, DISTRIBUTION, INSTALLATION, CATCHMENT, RESOURCE]),
    title: PropTypes.string,
    stations: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.instanceOf(DtoQualitometerLight)),
        PropTypes.arrayOf(PropTypes.instanceOf(DtoPiezometerLight)),
        PropTypes.arrayOf(PropTypes.instanceOf(DtoHydrometricStation)),
        PropTypes.arrayOf(PropTypes.instanceOf(DtoInstallation)),
        PropTypes.arrayOf(PropTypes.instanceOf(DtoDistributionUnit)),
        PropTypes.arrayOf(PropTypes.instanceOf(DtoProductionUnit)),
        PropTypes.arrayOf(PropTypes.instanceOf(PluviometerDto)),
        PropTypes.arrayOf(PropTypes.instanceOf(ResourceDto)),
        // add catchment
    ]),
    noNetworkDisplay: PropTypes.bool,
}

export {
    BasicHabilitationAccordion,
    HabilitationAccordion,
}