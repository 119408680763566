import { push } from '@lagunovsky/redux-react-router'
import { isEqual } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import ToastrAction from 'toastr/actions/ToastrAction'
import { H_MAT_OPTIONS } from '../../../account/constants/AccessRulesConstants'
import ActionComponent from '../../../components/ActionComponent'
import Table from '../../../components/datatable/Table'
import HomeAction from '../../../home/actions/HomeAction'
import { ADMINISTRATION, PATH_DASHBOARD, PATH_MATERIEL } from '../../../home/constants/RouteConstants'
import { componentHasHabilitations } from '../../../utils/HabilitationUtil'
import { getMaterielIconValue } from '../../../utils/MaterielUtils'
import MaterielAction from '../../actions/MaterielAction'
import { MATERIEL_TYPES } from '../../constants/MaterielConstants'
import MaterielTypeDto from '../../dto/MaterielTypeDto'

class AdministrationMaterielApp extends ActionComponent {
    constructor(props) {
        super(props)
        this.state = {
            materielTypes: [...props.materielTypes],
        }
    }

    componentDidMount = () => {
        if (!componentHasHabilitations(H_MAT_OPTIONS)) { // A modifier quand react-router sera à jour
            this.props.push('/unauthorized')
            return
        }
        this.props.setTitle([{
            title: i18n.materiel,
            href: `${PATH_MATERIEL}/${PATH_DASHBOARD}`,
        }, {
            title: i18n.administration,
            href: `${PATH_MATERIEL}/${ADMINISTRATION}`,
        }])
        this.setActions({
            save: this.onSave,
        })
    }

    componentDidUpdate = prevProps => {
        if (!isEqual(prevProps.materielTypes, this.props.materielTypes)) {
            this.setState({ materielTypes: [...this.props.materielTypes] })
        }
    }

    onSave = () => {
        if (!isEqual(this.props.materielTypes, this.state.materielTypes, (t1, t2) => t1.id === t2.id)) {
            const types = this.state.materielTypes.map(({ label, id }) => ({ label, code: id }))
            this.props.updateMaterielTypes(types)
        } else {
            this.props.toastrInfo(i18n.noModificationDetected)
        }
    }

    render = () => {
        const {
            materielTypes,
        } = this.state
        const data = MATERIEL_TYPES.map(({ code, labelKey, id }) => {
            const typeFound = materielTypes.find(t => t.id === id)
            return {
                nullValue: (
                    <i className={'material-icons'} >{getMaterielIconValue(code)}</i>
                ),
                label: typeFound && typeFound.label || i18n[labelKey],
                active: (
                    <i className={'material-icons'} >{typeFound ? 'check_box' : 'check_box_outline_blank'}</i>
                ),
                id,
            }
        })
        return (
            <div className='col s12'>
                <div className='row no-margin'>
                    <div className='card'>
                        <div className='row card-content'>
                            <div className='col s8 offset-s2 padding-top-1'>
                                <Table
                                    title={i18n.availableDevices}
                                    data={data}
                                    type={{ headers: ['nullValue', 'label', 'active'] }}
                                    onClick={({ label, id }) => {
                                        if (materielTypes.find(t => t.id === id)) {
                                            this.setState({ materielTypes: materielTypes.filter(t => t.id !== id) })
                                        } else {
                                            this.setState({ materielTypes: [...materielTypes, new MaterielTypeDto({ code: id, label })] })
                                        }
                                    }}
                                    condensed
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

AdministrationMaterielApp.propTypes = {
    isEditMode: PropTypes.bool,
    changeEditMode: PropTypes.func,

    materielTypes: PropTypes.arrayOf(PropTypes.instanceOf(MaterielTypeDto)),

    setTitle: PropTypes.func,
    updateMaterielTypes: PropTypes.func,
    push: PropTypes.func,
    toastrInfo: PropTypes.func,
}

const mapStateToProps = store => ({
    materielTypes: store.MaterielReducer.materielTypes,
})

const mapDispatchToProps = {
    setTitle: HomeAction.setTitle,
    updateMaterielTypes: MaterielAction.updateMaterielTypes,
    toastrInfo: ToastrAction.info,
    push,
}

export default connect(mapStateToProps, mapDispatchToProps)(AdministrationMaterielApp)
