export default class DtoSearchAnalysis {
    constructor(obj) {
        const [
            idAnalysis,
            idSample,
            idOperation,
            stationId,
            stationCode,
            stationName,
            sampleDate,
            labo,
            analysisDate,
            parameter,
            result,
            unit,
            support,
            fraction,
            status,
            qualification,
            x,
            y,
            projection,
            watershed,
            her1,
            remarkCode,
            localization,
            sampleProducer,
            quantitationLimit,
            detectionLimit,
            codePoint,
            namePoint,
            saturationThreshold,
        ] = obj
        this.idAnalysis = idAnalysis
        this.idSample = idSample
        this.idOperation = idOperation
        this.stationId = stationId
        this.stationCode = stationCode
        this.stationName = stationName
        this.sampleDate = sampleDate
        this.labo = labo
        this.analysisDate = analysisDate
        this.parameter = parameter
        this.result = result
        this.unit = unit
        this.support = support
        this.fraction = fraction
        this.status = status
        this.qualification = qualification
        this.x = x
        this.y = y
        this.projection = projection
        this.watershed = watershed
        this.her1 = her1
        this.remarkCode = remarkCode || '1'
        this.localization = localization
        this.sampleProducer = sampleProducer
        this.lq = quantitationLimit
        this.ld = detectionLimit
        this.codePoint = codePoint
        this.namePoint = namePoint
        this.saturationThreshold = saturationThreshold
        this.samplePoint = `${namePoint || ''} ${codePoint ? `[${codePoint}]` : ''}`
    }
}