export default class DtoCentralChannel {
    constructor(obj) {
        this.id = obj.pathId
        this.centralId = obj.centralId
        this.channelId = obj.channelId
        this.name = obj.name
        this.assignment = obj.assignment
        this.comment = obj.comment
        this.remoteTransmission = obj.remoteTransmission
        this.periodicity = obj.periodicity
        this.register = obj.register
        this.dataType = obj.dataType
    }
}
