export default class DtoQualityState {
    constructor(obj) {
        this.id = obj.id
        this.name = obj.name
        this.mnemonic = obj.mnemonic
        this.finality = obj.finality
        this.principal = obj.principal
        this.comment = obj.comment
        this.computedStateCode = obj.computedStateCode
        this.order = obj.order
        this.isClass = obj.isClass
    }
}