import pdf from 'assets/pictures/export/pdf.png'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import ToastrAction from 'toastr/actions/ToastrAction'

const PdfList = ({
    pdfList = [],
}) => {
    const pdfComponents = pdfList.map(pdfFile => (
        <li>
            <a href={pdfFile.url} target='_blank'>
                <div className='row no-margin valign-wrapper dropdownLink collection-item'>
                    <div className='col s12'>
                        <b>{pdfFile.name || ''}</b>
                    </div>
                </div>
            </a>
        </li>
    ))
    return (
        <div className='action-wrapper'>
            <ul id='dropdownExports' className='dropdown-content dropdownLinks' style={{ overflowY: 'hidden' }}>
                {pdfComponents}
            </ul>
            <a className='dropdown-button nav-actions' data-activates='dropdownExports'>
                <img className='clickable left detail-pdf-icon' src={pdf} />
            </a>
        </div>
    )
}

PdfList.propTypes = {
    pdfList: PropTypes.arrayOf(PropTypes.shape({
        url: PropTypes.string,
        name: PropTypes.string,
    })).isRequired,
}

const mapDispatchToProps = {
    toastrError: ToastrAction.error,
}

export default connect(null, mapDispatchToProps)(PdfList)
