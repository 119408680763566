/* eslint-disable camelcase */
import DtoMaterielChronicle from 'materiel/dto/DtoMaterielChronicle'
import DtoDeclarationVolumes from './DtoDeclarationVolumes'

export default class DtoDeclaration {
    constructor(obj) {
        this.idDeclaration = obj.idDeclaration // Long,
        this.idContributor = obj.idContributor // Long,
        this.statusCode = obj.statusCode // Option[Int],
        this.updateDate = obj.updateDate // Option[DateTime],
        this.updateLogin = obj.updateLogin // Option[String],
        this.comment = obj.comment // Option[String],
        this.lastStep = obj.lastStep // Option[Int],
        this.idSurvey = obj.idSurvey // Option[Long],
        this.idExploitation = obj.idExploitation // Option[Long]
        this.contactType = obj.contactType // Option[Long]
        this.attributionComment = obj.attributionComment // Option[String]

        this.nbPtPrevi = obj.nbPtPrevi // Option[Long]
        this.nbPtReal = obj.nbPtReal // Option[Long]
        this.prevVolume = obj.prevVolume // Option[Double]
        this.realVolume = obj.realVolume // Option[Double]
        this.attributedVolume = obj.attributedVolume // Option[Double],
        this.authorizedVolume = obj.authorizedVolume // Option[Double],
        this.nbPts = obj.nbPts // Option[Double]
        this.volumesAdjusted = obj.volumesAdjusted // Option[Boolean],
        this.year = obj.year // Option[Int] = None,
        this.link_volumes = obj.link_volumes ? obj.link_volumes.map((v) => new DtoDeclarationVolumes(v)) : [] // Option[Seq[DeclarationVolumes]] = None
        this.link_declarationInstallation = obj.link_declarationInstallation || [] // Option[Seq[SamplingPointExploitation]]
        this.link_chronicles = obj.link_chronicles ? obj.link_chronicles.map((c) => new DtoMaterielChronicle(c)) : [] // Option[Seq[MaterielChronicle]] = None
        this.link_exploitationInstallation = obj.link_exploitationInstallation || [] // Option[Seq[ExploitationSamplingPoint]] = None
        this.link_exploitationMateriel = obj.link_exploitationMateriel || [] // Option[Seq[VariousSituation]] = None
        this.link_pumps = obj.link_pumps || [] // Option[Seq[UpdatedPump]] = None,
        this.link_counters = obj.link_counters || [] // Option[Seq[UpdatedCounter]] = None
        this.link_contributorTypes = obj.link_contributorTypes || [] // Option[Seq[UpdatedContributorType]] = None

        this.name = obj.name // Option[String],
        this.email = obj.email // Option[String],
        this.phoneTel = obj.phoneTel // Option[String],
        this.mobile = obj.mobile // Option[String],
        this.cityCode = obj.cityCode // Option[String],
        this.city = obj.city // Option[String],
        this.structureType = obj.structureType // Option[String],
        this.codification = obj.codification // Option[String],
        this.status = obj.status // Option[String]
        this.volDetentionsEligibles = obj.volDetentionsEligibles // Option[Double] = None,
        this.tanks = obj.tanks // Option[String] = None,

        this.link_points = obj.link_points || [] // Option[Seq[DeclarationLinkPoint]] = None,
        this.eligibleTankVolume = obj.eligibleTankVolume // Option[Double] = None
    }
}