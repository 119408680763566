import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import Checkbox from '../../../../../components/forms/Checkbox'
import Input from '../../../../../components/forms/Input'
import NumberField from '../../../../../components/forms/NumberField'
import Select from '../../../../../components/forms/Select'
import Textarea from '../../../../../components/forms/Textarea'
import Row from '../../../../../components/react/Row'
import DtoParametrageDataType from '../../../../../piezometry/dto/DtoParametrageDataType'
import AppStore from '../../../../../store/AppStore'
import { arrayOf, instanceOf, removeNullKeys } from '../../../../../utils/StoreUtils'
import CentralAction from '../../actions/CentralAction'
import CentralDto from '../../dto/CentralDto'
import DtoCentralChannel from '../../dto/DtoCentralChannel'

class ChannelModal extends Component {
    constructor(props) {
        super(props)
        this.state = { channel: { ...props.channel, centralId: props.central.id } }
    }

    onChangeChannel = (changes) => this.setState({ channel: removeNullKeys({ ...this.state.channel, ...changes }) })

    afterUpdate = () => {
        $('.modal').modal('close')
        this.props.refreshDataFunction()
    }

    componentWillReceiveProps(nextProps) {
        if (!this.props.popupIsOpen && nextProps.popupIsOpen) {
            $('#saveChannel').on('click', () => AppStore.dispatch(CentralAction.updateCentralChannel(this.props.central.id, this.state.channel, this.props.isNew ? 'POST' : 'PUT', () => this.afterUpdate())))
        }
    }

    render() {
        const channel = this.state.channel
        const dataTypes = [{ id: 0, label: i18n.depth }, ...this.props.piezometryDataTypes]
        return (
            <div>
                <Row>
                    <NumberField col={ 1 } title={ i18n.channel } value={ channel.channelId }
                        onChange={ v => this.onChangeChannel({ channelId: v }) } disabled={ !this.props.isNew }
                    />
                    <Input col={ 4 } title={ i18n.name } value={ channel.name } onChange={ v => this.onChangeChannel({ name: v }) } />
                    <Select col={ 4 } value={ channel.dataType } options={ dataTypes } label={ i18n.dataType }
                        onChange={ v => this.onChangeChannel({ dataType: v }) } integerValue
                    />
                </Row>
                <Row className='valign-wrapper'>
                    <Textarea col={ 6 } title={ i18n.comment } value={ channel.comment }
                        onChange={ v => this.onChangeChannel({ comment: v }) }
                    />
                    <NumberField col={ 3 } title={ i18n.periodicity } value={ channel.periodicity }
                        onChange={ v => this.onChangeChannel({ periodicity: v }) }
                    />
                    <Checkbox col={ 3 } checked={ channel.remoteTransmission === '1' } label={ i18n.remoteTransmission }
                        onChange={ v => this.onChangeChannel({ remoteTransmission: v ? '1' : '0' }) }
                    />
                </Row>
                <Row className='valign-wrapper padding-top-1'>
                    <Input col={ 4 } title={ i18n.assignment } value={ channel.assignment }
                        onChange={ v => this.onChangeChannel({ assignment: v }) }
                    />
                    <Checkbox col={ 8 } checked={ channel.register === '1' } label={ i18n.acquisition }
                        onChange={ v => this.onChangeChannel({ register: v ? '1' : '0' }) }
                    />
                </Row>
            </div>
        )
    }
}

ChannelModal.propTypes = {
    central: instanceOf(CentralDto),
    channel: instanceOf(DtoCentralChannel),
    piezometryDataTypes: arrayOf(DtoParametrageDataType),
    refreshDataFunction: PropTypes.func,
    isNew: PropTypes.bool,
    popupIsOpen: PropTypes.bool,
}

const mapStateToProps = store => ({
    popupIsOpen: store.SieauReducer.popupIsOpen,
    piezometryDataTypes: store.PiezometryReducer.piezometryDataTypes,
})
export default connect(mapStateToProps)(ChannelModal)