import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getMapStateToProps, getPropTypes, getSandreList } from '../../../../utils/StoreUtils'
import i18n from 'simple-react-i18n'
import PropTypes from 'prop-types'
import Card from '../../../../components/card/Card'
import { hasValue } from '../../../../utils/NumberUtil'
import Input from '../../../../components/forms/Input'
import Select from '../../../../components/forms/Select'
import NumberField from '../../../../components/forms/NumberField'
import { SANDRE } from '../../../../referencial/constants/ReferencialConstants'
import AppStore from '../../../../store/AppStore'
import Icon from '../../../../components/icon/Icon'
import SieauAction from '../../../../components/sieau/SieauAction'
import InstallationsConfirmationMergeModal from './InstallationsConfirmationMergeModal'

const storeProps = {
    installation: false,
    installations: false,
    cities: false,
    contacts: false,
    sandreCodes: false,
}

class InstallationsMergeModal extends Component {
    constructor(props) {
        super(props)
        this.state = { mergeDirection: 'right', confirmMerge: false }
    }

    componentWillReceiveProps(nextProps) {
        if (!this.props.popupIsOpen && nextProps.popupIsOpen) {
            $('#mergeButton').on('click', () => {
                this.setState({ confirmMerge: true })
                $('.modal').modal('close')
            })
        }
        if (this.state.confirmMerge && this.props.popupIsOpen && !nextProps.popupIsOpen) {
            this.getMergeConfirmation()
        }
    }

    getMergeConfirmation = () => {
        const actions = (
            <div>
                <a className='waves-effect waves-teal btn-flat modal-close'>{ i18n.close }</a>
                <a id='confirmMerge' className='waves-effect waves-teal btn-flat'>{ i18n.confirmMerge }</a>
            </div>
        )
        const popup = {
            id: 'mergeConfirmationModal',
            header: i18n.mergeDirection,
            actions,
            content: (<InstallationsConfirmationMergeModal fromInstallation={ this.state.mergeDirection === 'right' ? this.props.installation : this.props.otherInstallation }
                toInstallation={ this.state.mergeDirection === 'right' ? this.props.otherInstallation : this.props.installation }
            />),
        }
        AppStore.dispatch(SieauAction.setPopup(popup))
    }

    getOriginIcon = (installation) => {
        return hasValue(installation.jobExecutionId) ?
            <i className='material-icons small tooltipped' data-tooltip={ `${i18n.automaticUpdate}</br>${installation.dataOrigin}` }>wifi</i> : null
    }

    getInstallationCard = (i) => {
        return (
            <Card title={ [this.getOriginIcon(i), `${i.code || i.id} - ${i.name}`] }>
                <div className='row no-margin padding-top-1'>
                    <Input col={ 12 } title={ i18n.code } value={ i.code } disabled/>
                </div>
                <div className='row no-margin'>
                    <Input col={ 12 } title={ i18n.name } value={ i.name } disabled/>
                </div>
                <div className='row no-margin'>
                    <Select col={ 12 } label={ i18n.city } value={ i.townCode } options={ this.props.cities } disabled/>
                </div>
                <div className='row no-margin'>
                    <Select col={ 12 } label={ i18n.owner } value={ i.ownerCode } options={ this.props.contacts } disabled/>
                </div>
                <div className='row no-margin'>
                    <NumberField col={ 6 } title={ 'X' } value={ i.x } disabled/>
                    <NumberField col={ 6 } title={ 'Y' } value={ i.y } disabled/>
                </div>
                <div className='row no-margin'>
                    <Input col={ 12 } title={ i18n.dataOrigin } value={ i.dataOrigin } disabled/>
                </div>
                <div className='row no-margin'>
                    <NumberField col={ 6 } title={ 'Z' } value={ i.altitude } disabled/>
                    <Select col={ 6 } label={ i18n.projection } value={ i.projection }
                        options={ getSandreList(this.props.sandreCodes, SANDRE.PROJECTION) } disabled
                    />
                </div>
            </Card>
        )
    }

    render() {
        return (
            <div>
                <div className='row no-margin'>
                    <div className='col s6'>
                        { this.getInstallationCard(this.props.installation) }
                    </div>
                    <div className='col s6'>
                        { this.getInstallationCard(this.props.otherInstallation) }
                    </div>
                </div>
                <div className='row no-margin'>
                    <Icon icon={ this.state.mergeDirection === 'right' ? 'call_missed_outgoing' : 'call_missed' } className='width-100 center-align medium blue-text' tooltip={ i18n.changeMergeDirection }
                        onClick={ () => {
                            const res = { mergeDirection: this.state.mergeDirection === 'right' ? 'left': 'right' }
                            this.setState(res)
                            this.props.onChangeState(res)
                        } }
                    />
                </div>
            </div>
        )
    }
}

InstallationsMergeModal.propTypes = getPropTypes(storeProps, {
    otherInstallation: PropTypes.object,
    popupIsOpen: PropTypes.bool,
    onChangeState: PropTypes.func,
})

const mapStateToProps = store => getMapStateToProps(storeProps, {
    popupIsOpen: store.SieauReducer.popupIsOpen,
})

export default connect(mapStateToProps)(InstallationsMergeModal)