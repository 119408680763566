/* eslint-disable camelcase */
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { arrayOf } from '../../../../utils/StoreUtils'
import i18n from 'simple-react-i18n'
import DtoSandreCode from '../../../../referencial/dto/DtoSandreCode'
import { getSandreLabel } from '../../../../utils/StringUtil'
import { SANDRE } from '../../../../referencial/constants/ReferencialConstants'
import Table from '../../../../components/datatable/Table'
import SieauAction from '../../../../components/sieau/SieauAction'
import { getExport, setModal } from '../../../../utils/linkUtils'
import DtoTankStation from '../dto/DtoTankStation'
import TankSecurityModal from './modal/TankSecurityModal'
import { getDate } from '../../../../utils/DateUtil'

const TankSecurityPanel = ({
    tank = {},
    sandreCodes = [],
    readMode = false,
    onChange = () => {},
    setPopup = () => {},
}) => {
    const stationSecurities = tank.link_securities.map((c, index) => ({
        ...c,
        index,
    }))
    const tableData = stationSecurities.map(c => ({
        ...c,
        equipment: getSandreLabel(sandreCodes, SANDRE.RADIO_SECURITE, c.equipment),
        state: getSandreLabel(sandreCodes, SANDRE.RESERVOIRS_ZONES_ETATS, c.equipmentStateCode),
        lastControleDate: getDate(c.lastControleDate),
    }))
    const headers = ['equipment', 'lastControleDate', 'state', 'comment']
    const exportAction = getExport(tableData, i18n.securities, headers)
    const actions = !readMode ? [{
        iconName: 'note_add',
        tooltip: i18n.add,
        onClick: () => setModal(i18n.newSecurity, <TankSecurityModal id={ tank.idStation } saveResult={ r => onChange({ link_securities: [...stationSecurities, r] }) }/>, setPopup),
    }, exportAction] : [exportAction]

    return (
        <Table
            title={ i18n.securities }
            condensed
            data={ tableData }
            className='blue'
            type={{ headers }}
            sortable={ !!tableData.length }
            actions={ actions }
            alterable={ !readMode }
            onAlter={
                (element) => setModal(i18n.editSecurity,
                    <TankSecurityModal
                        id={ tank.idStation }
                        security={ stationSecurities.find(c => element.index === c.index) }
                        saveResult={ r => onChange({
                            link_securities: [
                                ...stationSecurities.filter(lc => lc.index !== element.index),
                                { ...r, index: element.index },
                            ],
                        })}
                    />
                    , setPopup)
            }
            deletable={ !readMode }
            onDelete={ (element) => onChange({ link_securities: stationSecurities.filter(lc => lc.index !== element.index) }) }
        />
    )
}

TankSecurityPanel.propTypes = {
    tank: PropTypes.instanceOf(DtoTankStation),
    sandreCodes: arrayOf(DtoSandreCode),
    readMode: PropTypes.bool,
    onChange: PropTypes.func,
    setPopup: PropTypes.func,
}

const mapStateToProps = store => ({
    sandreCodes: store.ReferencialReducer.sandreCodes,
})

const mapDispatchToProps = {
    setPopup: SieauAction.setPopup,
}

export default connect(mapStateToProps, mapDispatchToProps)(TankSecurityPanel)