'use strict'
import PropTypes from 'prop-types'
import {
    OPEN_POPUP,
    POPUP,
    POPUP_CLOSED,
    RECEIVE_PROPS,
    RECEIVE_SIEAU_HYPE_TRENDS,
    RESET_ABORT_SIGNAL,
    RESET_POPUP,
    RESET_PROPS,
    RESET_SIEAU_HYPE_TRENDS,
    SET_LOADING_DATA,
    WAIT_PROPS,
} from './SieauConstants'
import AppStore from 'store/AppStore'
import { arrayOf } from '../../utils/StoreUtils'
import DtoHypeLineTrend from '../../quality/dto/HypeTrends/DtoHypeLineTrend'
import DtoHypeRupture from '../../quality/dto/HypeTrends/DtoHypeRupture'

export function SieauReducer(state = {}, action) {
    switch (action.type) {
        case WAIT_PROPS:
            return Object.assign({}, state, {
                waitProps: action.waitProps,
            })
        case POPUP:
            return Object.assign({}, state, {
                popup: action.popup,
            })
        case RECEIVE_PROPS:
            return Object.assign({}, state, {
                receivedProps: [].concat(state.receivedProps, [action.propName]),
            })
        case RESET_PROPS:
            return Object.assign({}, state, {
                receivedProps: state.receivedProps.filter(n => !action.resetProps.includes(n)),
            })
        case OPEN_POPUP:
            return Object.assign({}, state, {
                popupIsOpen: true,
            })
        case POPUP_CLOSED:
            return Object.assign({}, state, {
                popupIsOpen: false,
            })
        case RESET_POPUP:
            return Object.assign({}, state, {
                popupIsOpen: false,
                popup: null,
            })
        case RECEIVE_SIEAU_HYPE_TRENDS:
            return Object.assign({}, state, {
                hypeTrends: action.trends.map(el => new DtoHypeLineTrend(el)),
                hypeRuptures: action.ruptures.map(el => new DtoHypeRupture(el)),
            })
        case RESET_SIEAU_HYPE_TRENDS:
            return Object.assign({}, state, {
                hypeTrends: [],
                hypeRuptures: [],
            })
        case SET_LOADING_DATA:
            return Object.assign({}, state, {
                loadingData: action.loadingData,
            })
        case RESET_ABORT_SIGNAL:
            const controller = new AbortController() // eslint-disable-line no-undef
            return Object.assign({}, state, {
                abortController: controller,
                abortSignal: controller.signal,
            })
        case 'SET_SELECT_FUNCTIONS':
            return Object.assign({}, state, {
                changeSelectDropdown: action.changeSelectDropdown,
            })
        default:
            return state
    }
}

const controller = new AbortController() // eslint-disable-line no-undef

export const store = {
    waitProps: [],
    popup: null,
    popupIsOpen: false,
    receivedProps: [],
    loadingData: false,
    abortController: controller,
    abortSignal: controller.signal,
    changeSelectDropdown: () => {},
}

export const SieauStoreInfos = () => {
    const appStore = AppStore.getState()
    return {
        receivedProps: {
            store: appStore.SieauReducer.receivedProps,
            propType: arrayOf(PropTypes.string),
        },
        waitProps: {
            store: appStore.SieauReducer.waitProps,
            propType: arrayOf(PropTypes.string),
        },
    }
}
