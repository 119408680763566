/* eslint-disable camelcase */
import { Button, Dialog, DialogActions, DialogContent, Grid2 } from '@mui/material'
import Select from 'components/forms/Select'
import SimpleDatePicker from 'components/forms/SimpleDatePicker'
import { DefaultDialogTitle } from 'components/styled/Dialog'
import DtoExploitation from 'exploitations/dto/DtoExploitation'
import DtoExploitationService from 'exploitations/dto/DtoExploitationService'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { nbPerPageLabelShort, SANDRE } from 'referencial/constants/ReferencialConstants'
import i18n from 'simple-react-i18n'
import { getDate } from 'utils/DateUtil'
import { getExport } from 'utils/linkUtils'
import { getSandreLabel } from 'utils/StringUtil'
import { getSandreList } from '../../../utils/StoreUtils'
import { CardTable } from 'components/datatable/NewTable'
import { darkBlue } from 'utils/constants/ColorTheme'

const headers = ['service', 'startDate', 'endDate', 'unity', 'occurency']

const ExploitationServicesPanel = ({
    exploitation = {},
    readMode = false,
    onChange = () => { },
}) => {
    const [open, setOpen] = useState(false)
    const [isNew, setIsNew] = useState(false)
    const [selectedIndex, setSelectedIndex] = useState(null)

    // service state
    const [service, setService] = useState(null)
    const [startDate, setStartDate] = useState(null)
    const [endDate, setEndDate] = useState(null)
    const [unity, setUnity] = useState(null)
    const [occurency, setOccurency] = useState(null)

    const {
        sandreCodes,
        services,
    } = useSelector(store => ({
        sandreCodes: store.ReferencialReducer.sandreCodes,
        services: store.AgriReducer.services,
    }), shallowEqual)
    const exploitationServices = exploitation.link_services.map((u, index) => ({
        ...u,
        index,
    }))
    const tableData = exploitationServices.map(es => {
        const foundService = services.find(s => s.idService === es.idService)
        return {
            ...es,
            startDate: getDate(es.startDate),
            endDate: getDate(es.endDate),
            unity: getSandreLabel(sandreCodes, SANDRE.COMPTA_UNITE, es.unity),
            occurency: getSandreLabel(sandreCodes, SANDRE.COMPTA_OCCURENCE, es.occurency),
            service: foundService ? `${foundService.designation} (${foundService.technicalId || ''} - ${foundService.productCode || ''})` : '',
        }
    })
    const exportAction = getExport(tableData, `${exploitation.codification}_${i18n.services}`, headers)
    const actions = !readMode ? [{
        icon: 'note_add',
        color: 'white',
        tooltip: i18n.add,
        onClick: () => {
            setIsNew(true)
            setOpen(true)
        },
    }, exportAction] : [exportAction]

    const resetPopup = () => {
        setOpen(false)
        setIsNew(false)
        setSelectedIndex(null)
        setService(null)
        setStartDate(null)
        setEndDate(null)
        setUnity(null)
        setOccurency(null)
    }

    const changeService = () => {
        const selectedService = exploitationServices.find(ls => ls.index === selectedIndex)
        const newService = new DtoExploitationService({
            ...selectedService,
            idExploitation: exploitation.idExploitation,
            idService: service,
            startDate,
            endDate,
            occurency,
            unity,
        })
        if (isNew) {
            onChange({
                link_services: [...exploitationServices, newService],
            })
        } else {
            onChange({
                link_services: [
                    ...exploitationServices.slice(0, selectedIndex),
                    { ...newService, index: selectedIndex },
                    ...exploitationServices.slice(selectedIndex + 1),
                ],
            })
        }

        resetPopup()
    }

    const setModal = (selectedService) => {
        const element = exploitationServices.find(es => selectedService.index === es.index)
        setOpen(true)
        setSelectedIndex(element.index)
        setService(element.idService)
        setStartDate(element.startDate)
        setEndDate(element.endDate)
        setUnity(element.unity)
        setOccurency(element.occurency)
    }

    return (
        <Grid2 id='usages' className='padding-bottom-1'>
            <CardTable
                title={i18n.services}
                actions={actions}
                rows={tableData}
                lineActions={[{
                    icon: 'edit',
                    displayed: !readMode,
                    onClick: setModal,
                }, {
                    icon: 'delete',
                    displayed: !readMode,
                    onClick: (element) => onChange({ link_services: exploitationServices.filter(u => u.index !== element.index) }),
                } ]}
                rowsPerPageOptions={ nbPerPageLabelShort }
                headers={headers}
                color={darkBlue}
            />
            <Dialog maxWidth='lg' open={open}>
                <DefaultDialogTitle
                    title={isNew ? i18n.newService : i18n.editService}
                    onClose={resetPopup}
                />
                <DialogContent sx={{ minHeight: '350px' }}>
                    <Grid2 container spacing={1}>
                        <Grid2 size={12}>
                            <Select
                                label={i18n.service}
                                options={services.map(s => ({ ...s, technicalId: `${s.designation} (${s.technicalId || ''} - ${s.productCode || ''})` }))}
                                keyValue='idService'
                                keyLabel='technicalId'
                                value={service}
                                onChange={setService}
                                obligatory
                            />
                        </Grid2>
                        <Grid2 size={6}>
                            <SimpleDatePicker
                                value={startDate}
                                id='startDate'
                                label={i18n.startDate}
                                onChange={setStartDate}
                                obligatory
                            />
                        </Grid2>
                        <Grid2 size={6}>
                            <SimpleDatePicker
                                value={endDate}
                                id='endDate'
                                label={i18n.endDate}
                                onChange={setEndDate}
                            />
                        </Grid2>
                        <Grid2 size={6}>
                            <Select
                                label={i18n.unity}
                                options={getSandreList(sandreCodes, SANDRE.COMPTA_UNITE)}
                                value={unity}
                                onChange={setUnity}
                            />
                        </Grid2>
                        <Grid2 size={6}>
                            <Select
                                label={i18n.occurency}
                                options={getSandreList(sandreCodes, SANDRE.COMPTA_OCCURENCE)}
                                value={occurency}
                                onChange={setOccurency}
                            />
                        </Grid2>
                    </Grid2>
                </DialogContent>
                <DialogActions>
                    <Button onClick={changeService} variant='contained' color='primary'>
                        {isNew ? i18n.add : i18n.change}
                    </Button>
                </DialogActions>
            </Dialog>
        </Grid2>
    )
}

ExploitationServicesPanel.propTypes = {
    exploitation: PropTypes.instanceOf(DtoExploitation),
    readMode: PropTypes.bool,
    onChange: PropTypes.func,
}

export default ExploitationServicesPanel