import PropTypes from 'prop-types'
import React from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { STATION_TYPE_CONSTANT } from 'station/constants/StationConstants'
import UnitPiezometryPanel from '../../productionUnit/components/UnitPiezometryPanel'
import DtoStation from '../../station/dto/DtoStation'
import UnitQualityPanel from 'productionUnit/components/UnitQualityPanel'
import useGlobalParameter from 'utils/customHook/useGlobalParameter'
import { listStringParser } from 'utils/customHook/useApplicationSetting'

const UDI_REFERENCE_THRESHOLD = 'UDI_REFERENCE_THRESHOLD'

const DistributionUnitPanel = (props) => {
    const {
        associatedSites,
    } = useSelector(store => ({
        associatedSites: store.StationReducer.associatedSites,
    }), shallowEqual)

    const thresholds = useGlobalParameter(UDI_REFERENCE_THRESHOLD, listStringParser)

    const hasType = (type) => associatedSites.some(ass => ass.stationLinkedType === type)

    const qualitoIds = associatedSites.filter(ass => ass.stationLinkedType === 3).map(ass => ass.stationLinkedId)

    return (
        <div>
            { hasType(STATION_TYPE_CONSTANT.PIEZOMETRY) && <UnitPiezometryPanel {...props} associations={associatedSites} /> }
            { hasType(STATION_TYPE_CONSTANT.QUALITY) && qualitoIds.length > 0 && (<UnitQualityPanel ids={qualitoIds} thresholds={thresholds} defaultExpanded/>)}
        </div>
    )
}

DistributionUnitPanel.propTypes = {
    id: PropTypes.string,
    uncheckedStations: PropTypes.arrayOf(PropTypes.instanceOf(DtoStation)),
    setDataLoaded: PropTypes.func,
}

export default DistributionUnitPanel