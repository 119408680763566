/* eslint-disable consistent-return */
/* eslint-disable react-hooks/exhaustive-deps */
import { isUndefined } from 'lodash'
import { useEffect, useReducer, useRef } from 'react'
import { genericPromise2 } from 'utils/ActionUtils'

const useFetch = (url, opts) => {
    // Used to prevent state update if the component is unmounted
    const cancelRequest = useRef(false)

    const initialState = {
        error: undefined,
        data: undefined,
    }

    // Keep state logic separated
    const fetchReducer = (state, action) => {
        switch (action.type) {
            case 'loading':
                return { ...initialState }
            case 'fetched':
                return { ...initialState, data: action.payload }
            case 'error':
                return { ...initialState, error: action.payload }
            default:
                return state
        }
    }

    const [state, dispatch] = useReducer(fetchReducer, initialState)

    useEffect(() => {
        // Do nothing if the url is not given
        if (!url) {
            return
        }

        cancelRequest.current = false

        const fetchData = async () => {
            dispatch({ type: 'loading' })

            try {
                const response = await genericPromise2(url, opts)
                if (isUndefined(response)) {
                    throw new Error(response.statusText)
                }

                if (cancelRequest.current) {
                    return
                }

                dispatch({ type: 'fetched', payload: response })
            } catch (error) {
                if (cancelRequest.current) {
                    return
                }

                dispatch({ type: 'error', payload: error })
            }
        }

        fetchData()

        // Use the cleanup function for avoiding a possibly
        // state update after the component was unmounted
        return () => {
            cancelRequest.current = true
        }
    }, [url, opts])

    return state
}

export default useFetch