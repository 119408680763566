module.exports = {
    PATH_DASHBOARD: 'dashboard',
    PATH_DASHBOARD_LINK: '/dashboard',
    PATH_DASHBOARD_TRACKING: '/tracking',
    PATH_INVENTORY: 'inventory',
    ADMINISTRATION: 'administration',
    REFERENCIAL: 'referencial',
    PATH_REFERENCIAL: '/referencial',
    PATH_REFERENCIAL_LINK: '/referencial/',
    PATH_REFERENCIAL_DASHBOARD: '/referencial/dashboard',
    CONTRIBUTOR: 'contributor',
    CONTRIBUTOR_LINK: 'contributor/',
    PATH_REFERENCIAL_CONTRIBUTOR: '/referencial/contributor',
    PATH_REFERENCIAL_CONTRIBUTOR_LINK: '/referencial/contributor/',
    PATH_REFERENCIAL_CONTRIBUTOR_NEW: '/referencial/contributor/new',
    LINK_REFERENCIAL_CONTRIBUTOR: 'referencial/contributor',
    WATERSHED: 'watershed',
    WATERSHED_LINK: 'watershed/',
    PATH_REFERENCIAL_WATERSHED: '/referencial/watershed',
    PATH_REFERENCIAL_WATERSHED_ITEM: '/referencial/watershed',
    PATH_REFERENCIAL_WATERSHED_ITEM_LINK: '/referencial/watershed/',
    PATH_REFERENCIAL_WATERSHED_ITEM_NEW: '/referencial/watershed/new',
    LINK_REFERENCIAL_WATERSHED_ITEM: 'referencial/watershed',
    AQUIFER: 'aquifer',
    AQUIFER_LINK: 'aquifer/',
    PATH_REFERENCIAL_AQUIFER: '/referencial/aquifer',
    PATH_REFERENCIAL_AQUIFER_LINK: '/referencial/aquifer/',
    PATH_REFERENCIAL_AQUIFER_NEW: '/referencial/aquifer/new',
    LINK_REFERENCIAL_AQUIFER: 'referencial/aquifer',
    CITY: 'city',
    CITY_LINK: 'city/',
    PATH_REFERENCIAL_CITY: '/referencial/city',
    PATH_REFERENCIAL_CITY_LINK: '/referencial/city/',
    PATH_REFERENCIAL_CITY_NEW: '/referencial/city/new',
    LINK_REFERENCIAL_CITY: 'referencial/city',
    CONTACT: 'contact',
    CONTACT_LINK: 'contact/',
    PATH_REFERENCIAL_CONTACT: '/referencial/contact',
    PATH_REFERENCIAL_CONTACT_LINK: '/referencial/contact/',
    PATH_REFERENCIAL_CONTACT_NEW: '/referencial/contact/new',
    LINK_REFERENCIAL_CONTACT: 'referencial/contact',
    HYDROGEOLOGICAL_ENTITY: 'hydrogeologicalEntity',
    HYDROGEOLOGICAL_ENTITY_LINK: 'hydrogeologicalEntity/',
    PATH_REFERENCIAL_HYDROGEOLOGICAL_ENTITY: '/referencial/hydrogeologicalEntity',
    PATH_REFERENCIAL_HYDROGEOLOGICAL_ENTITY_LINK: '/referencial/hydrogeologicalEntity/',
    PATH_REFERENCIAL_HYDROGEOLOGICAL_ENTITY_NEW: '/referencial/hydrogeologicalEntity/new',
    LINK_REFERENCIAL_HYDROGEOLOGICAL_ENTITY: 'referencial/hydrogeologicalEntity',
    UNIT: 'unit',
    UNIT_LINK: 'unit/',
    PATH_REFERENCIAL_UNIT_ENTITY: '/referencial/unit',
    PATH_REFERENCIAL_UNIT_ENTITY_LINK: '/referencial/unit/',
    PATH_REFERENCIAL_UNIT_ENTITY_NEW: '/referencial/unit/new',
    LINK_REFERENCIAL_UNIT_ENTITY: 'referencial/unit',
    METHOD: 'method',
    METHOD_LINK: 'method/',
    PATH_REFERENCIAL_METHOD: '/referencial/method',
    PATH_REFERENCIAL_METHOD_LINK: '/referencial/method/',
    PATH_REFERENCIAL_METHOD_NEW: '/referencial/method/new',
    LINK_REFERENCIAL_METHOD: 'referencial/method',
    PARAMETER: 'parameter',
    PARAMETER_LINK: 'parameter/',
    PATH_REFERENCIAL_PARAMETER: '/referencial/parameter',
    PATH_REFERENCIAL_PARAMETER_ITEM: '/referencial/parameter',
    PATH_REFERENCIAL_PARAMETER_ITEM_LINK: '/referencial/parameter/',
    PATH_REFERENCIAL_PARAMETER_ITEM_NEW: '/referencial/parameter/new',
    LINK_REFERENCIAL_PARAMETER_ITEM: 'referencial/parameter',
    TAXON: 'taxon',
    TAXON_LINK: 'taxon/',
    LINK_REFERENCIAL_TAXON: 'referencial/taxon',
    PATH_REFERENCIAL_TAXON: '/referencial/taxon',
    PATH_REFERENCIAL_TAXON_LINK: '/referencial/taxon/',
    PATH_REFERENCIAL_TAXON_NEW: '/referencial/taxon/new',
    LINK_REFERENCIAL_USAGE: 'territory/settings/usages',
    PATH_REFERENCIAL_USAGE: '/territory/settings/usages',
    PATH_REFERENCIAL_USAGE_LINK: '/territory/settings/usages/',
    PATH_REFERENCIAL_USAGE_NEW: '/territory/settings/usages/new',
    PATH_REFERENCIAL_USAGE_ID: '/territory/settings/usage/',
    FRACTION: 'fraction',
    FRACTION_LINK: 'fraction/',
    PATH_REFERENCIAL_FRACTION: '/referencial/fraction',
    PATH_REFERENCIAL_FRACTION_LINK: '/referencial/fraction/',
    PATH_REFERENCIAL_FRACTION_NEW: '/referencial/fraction/new',
    LINK_REFERENCIAL_FRACTION: 'referencial/fraction',
    NETWORK: 'network',
    NETWORK_LINK: 'network/',
    PATH_REFERENCIAL_NETWORK: '/referencial/network',
    PATH_REFERENCIAL_NETWORK_LINK: '/referencial/network/',
    PATH_REFERENCIAL_NETWORK_NEW: '/referencial/network/new',
    LINK_REFERENCIAL_NETWORK: 'referencial/network',
    WATERMASS: 'watermass',
    WATERMASS_LINK: 'watermass/',
    PATH_REFERENCIAL_WATERMASS: '/referencial/watermass',
    PATH_REFERENCIAL_WATERMASS_ITEM: '/referencial/watermass',
    PATH_REFERENCIAL_WATERMASS_ITEM_LINK: '/referencial/watermass/',
    PATH_REFERENCIAL_WATERMASS_ITEM_NEW: '/referencial/watermass/new',
    LINK_REFERENCIAL_WATERMASS_ITEM: 'referencial/watermass',
    SUPPORT: 'support',
    SUPPORT_LINK: 'support/',
    PATH_REFERENCIAL_SUPPORT: '/referencial/support',
    PATH_REFERENCIAL_SUPPORT_LINK: '/referencial/support/',
    PATH_REFERENCIAL_SUPPORT_NEW: '/referencial/support/new',
    LINK_REFERENCIAL_SUPPORT: 'referencial/support',
    PATH_REFERENCIAL_UNIT_MANAGEMENT: '/referencial/managementUnit',
    PATH_REFERENCIAL_UNIT_MANAGEMENT_LINK: '/referencial/managementUnit/',
    PATH_REFERENCIAL_UNIT_MANAGEMENT_NEW: '/referencial/managementUnit/new',
    LINK_REFERENCIAL_UNIT_MANAGEMENT: 'referencial/managementUnit',
    PATH_REFERENCIAL_CULTURE: '/referencial/culture',
    PATH_REFERENCIAL_CULTURE_LINK: '/referencial/culture/',
    PATH_REFERENCIAL_CULTURE_NEW: '/referencial/culture/new',
    LINK_REFERENCIAL_CULTURE: 'referencial/culture',
    LINK_REFERENCIAL_COUNTRY: 'referencial/country',
    PATH_REFERENCIAL_COUNTRY: '/referencial/country/',
    PATH_REFERENCIAL_COUNTRY_NEW: '/referencial/country/new',
    PATH_REFERENCIAL_COUNTRY_LINK: '/referencial/country/',
    PATH_REFERENCIAL_CULTURES_RPG: '/referencial/culturesRPG',
    PATH_REFERENCIAL_CULTURES_RPG_LINK: '/referencial/cultureRPG/',
    PATH_REFERENCIAL_CULTURES_RPG_NEW: '/referencial/cultureRPG/new',
    LINK_REFERENCIAL_CULTURES_RPG: 'referencial/culturesRPG',
    PATH_REFERENCIAL_CULTURES_FAMILY: '/referencial/culturesFamily',
    PATH_REFERENCIAL_CULTURES_FAMILY_LINK: '/referencial/culturesFamily/',
    PATH_REFERENCIAL_CULTURES_FAMILY_NEW: '/referencial/culturesFamily/new',
    LINK_REFERENCIAL_CULTURES_FAMILY: 'referencial/culturesFamily',
    PATH_REFERENCIAL_ZONE: '/referencial/zone',
    PATH_REFERENCIAL_ZONE_LINK: '/referencial/zone/',
    PATH_REFERENCIAL_ZONE_NEW: '/referencial/zone/new',
    LINK_REFERENCIAL_ZONE: 'referencial/zone',
    PATH_REFERENCIAL_DRIVE_MODE: '/referencial/driveMode',
    PATH_REFERENCIAL_DRIVE_MODE_LINK: '/referencial/driveMode/',
    PATH_REFERENCIAL_DRIVE_MODE_NEW: '/referencial/driveMode/new',
    LINK_REFERENCIAL_DRIVE_MODE: 'referencial/driveMode',
    PATH_REFERENCIAL_SECTOR: '/referencial/sector',
    PATH_REFERENCIAL_SECTOR_LINK: '/referencial/sector/',
    PATH_REFERENCIAL_SECTOR_NEW: '/referencial/sector/new',
    LINK_REFERENCIAL_SECTOR: 'referencial/sector',
    PATH_CONTENTS: '/contents',
    PATH_CONTENTS_LINK: '/contents/',
    IMPORT: 'import',
    PATH_IMPORT: '/import',
    PATH_IMPORT_LINK: '/import/',
    GRAPHIC: 'graphic',
    PATH_GRAPHIC: '/graphic',
    JOB: 'job',
    PATH_JOB: '/job',
    PATH_JOB_EXECUTION: '/job/execution',
    PATH_JOB_LIST: '/job/list',
    CAMPAIGN: 'campaign',
    QUALITY: 'quality',
    QUALITY_LINK: 'quality/',
    QUALITY_CAMPAIGN_LINK: 'quality/campaign/',
    PATH_QUALITY_CAMPAIGN: 'quality/campaign',
    PATH_QUALITY: '/quality',
    PATH_QUALITY_CAMPAIGN_LINK: '/quality/campaign/',
    PATH_QUALITY_OPTIONS: '/quality/qualityOptions',
    PIEZOMETRY: 'piezometry',
    PIEZOMETRY_LINK: 'piezometry/',
    PIEZOMETRYOPTIONS: 'piezometryOptions',
    PIEZOMETRICFOLLOWUP: 'piezometricFollowUp',
    PIEZOMETRY_CAMPAIGN: 'piezometry/campaign',
    PIEZOMETRY_CAMPAIGN_LINK: 'piezometry/campaign/',
    PATH_PIEZOMETRY: '/piezometry',
    PATH_PIEZOMETRY_CAMPAIGN: '/piezometry/campaign',
    PATH_PIEZOMETRY_CAMPAIGN_LINK: '/piezometry/campaign/',
    PATH_PIEZOMETRY_CAMPAIGN_NEW_DASHBOARD: 'piezometry/campaign/new/dashboard',
    PATH_PIEZOMETRY_CAMPAIGN_DUPLICATE_DASHBOARD: 'piezometry/campaign/duplicate/dashboard',
    PATH_PIEZOMETRY_CAMPAIGN_DASHBOARD: (id) => `/piezometry/campaign/${id}/dashboard`,
    PATH_QUALITY_CAMPAIGN_DASHBOARD: (id) => `/quality/campaign/${id}/dashboard`,
    PLUVIOMETRY: 'pluviometry',
    PLUVIOMETRY_LINK: 'pluviometry/',
    PLUVIOMETRYOPTIONS: 'pluviometryOptions',
    PATH_PLUVIOMETRY: '/pluviometry',
    PATH_PLUVIOMETRY_CAMPAIGN: '/pluviometry/campaign',
    PLUVIOMETRY_CAMPAIGN_LINK: 'pluviometry/campaign/',
    HYDROMETRY: 'hydrometry',
    HYDROMETRY_LINK: 'hydrometry/',
    HYDROMETRYOPTIONS: 'hydrometryOptions',
    HYDROMETRICFOLLOWUP: 'hydrometricFollowUp',
    PATH_HYDROMETRY: '/hydrometry',
    PATH_HYDROMETRY_CAMPAIGN: '/hydrometry/campaign',
    HYDROMETRY_CAMPAIGN_LINK: '/hydrometry/campaign/',
    PRODUCTION: 'productionUnit',
    PRODUCTION_LINK: 'productionUnit/',
    PATH_PRODUCTION: '/productionUnit',
    INSTALLATION: 'installation',
    INSTALLATION_LINK: 'installation/',
    INSTALLATIONFOLLOWUP: 'installationFollowUp',
    PATH_INSTALLATION: '/installation',
    PATH_PERIMETERS: '/perimeter',
    RESOURCE: 'resource',
    RESOURCE_LINK: 'resource/',
    PATH_RESOURCE: '/resource',
    PATH_INSTALLATION_CAMPAIGN: '/installation/campaign',
    INSTALLATION_CAMPAIGN_LINK: '/installation/campaign/',
    PATH_INSTALLATION_TERRITORYACTIVITIES: '/installation/territoryActivities',
    DISTRIBUTION: 'distributionUnit',
    DISTRIBUTION_LINK: 'distributionUnit/',
    PATH_DISTRIBUTION: '/distributionUnit',
    PATH_DISTRIBUTION_OPTIONS: '/distributionUnit/options',
    PATH_CATCHMENT: '/catchment',
    PATH_GLOBALEVENTS: '/globalEvents',
    PATH_STATION: '/station',
    PATH_STATION_LINK: '/station/',
    PATH_MATERIEL: '/materiel',
    PATH_MATERIEL_LINK: 'materiel/',
    PATH_STATION_MATERIEL: 'materiel',
    PATH_STATION_VALIDATION: 'validation',
    PATH_MATERIEL_CENTRAL: 'central',
    PATH_MATERIEL_CENTRAL_LINK: 'central/',
    PATH_MATERIEL_POWER_SUPPLY: 'powerSupply',
    PATH_MATERIEL_POWER_SUPPLY_LINK: 'powerSupply/',
    PATH_MATERIEL_SENSOR: 'sensor',
    PATH_MATERIEL_SENSOR_LINK: 'sensor/',
    PATH_MATERIEL_SIM: 'sim',
    PATH_MATERIEL_SIM_LINK: 'sim/',
    PATH_MATERIEL_TELECOM: 'telecom',
    PATH_MATERIEL_TELECOM_LINK: 'telecom/',
    PATH_MATERIEL_VARIOUS_MATERIEL: 'variousMateriel',
    PATH_MATERIEL_VARIOUS_MATERIEL_LINK: 'variousMateriel/',
    PATH_MATERIEL_EQUIPMENT: 'equipment',
    PATH_MATERIEL_EQUIPMENT_LINK: 'equipment/',
    PATH_MATERIEL_SUBSCRIPTION: 'subscription',
    PATH_MATERIEL_SUBSCRIPTION_LINK: 'subscription/',
    PATH_PGSSE: '/pgsse',
    PATH_PGSSE_DANGERS: 'dangers',
    PATH_ADMINISTRATION: '/administration',
    PATH_ADMINISTRATION_SETTINGS: 'settings',
    PATH_ADMINISTRATION_STATS: 'stats',
    PATH_ADMINISTRATION_USER: 'user',
    PATH_ADMINISTRATION_CARTOGRAPHY: 'cartography',
    PATH_ADMINISTRATION_HOMEPAGE: 'homepage',
    PATH_ADMINISTRATION_CONTENTS: 'contents',
    PATH_ADMINISTRATION_PROBLEM_SOLUTION: 'problemSolution',
    PATH_ADMINISTRATION_MODELS: '/administration/models',
    PATH_ADMINISTRATION_PARAMETERS: '/administration/parameters',
    PATH_ALERT: '/alert',
    PATH_ALERT_FOLLOW: '/alert/follow',
    PATH_ALERT_CONFIGURATION: '/alert/alertOptions',
    PATH_MODEL_CONFIGURATION: '/alert/modelOptions',
    PATH_GLOBAL_EVENTS: '/globalEvents',
    PATH_ARRESTS: '/arrests',
    PATH_DOCUMENTS: '/documents',
    PATH_LOGIN: '/login',
    PATH_RESET: '/reset',
    PATH_SITEPLAN: '/sitePlan',
    PATH_ACCOUNT: '/account',
    SITUATION: 'situation',
    INTEGRATION_OVERVIEW: 'integrationOverview',
    TRACKING: 'tracking',
    FOLLOWED: 'followed',
    REQUEST: 'request',
    ANALYSIS: 'analysis',
    STATIONS: 'stations',
    DEVELOP: 'develop',
    UPDATE: 'update',
    RESEARCH: 'research',
    SELECTION: 'selection',
    THRESHOLD: 'threshold',
    INDICATORS: 'indicators',
    GENERALOPTIONS: 'generalOptions',
    CONFORMITYOVERVIEW: 'conformityOverview',
    DESCRIPTION: 'description',
    OPERATION: 'operation',
    OPERATION_LINK: 'operation/',
    SUIVIPC: 'suiviPC',
    SUIVIPCBIS: 'suiviPCBis',
    EVENT: 'event',
    EVENT_LINK: 'event/',
    PATH_EVENT: '/event',
    PATH_EVENT_LINK: '/event/',
    ASSOCIATED: 'associated',
    PICTURE: 'picture',
    PATH_EXPORT: 'export',
    INSTALLATION_OPTIONS: 'installationOptions',
    PATH_MESSAGES: '/messages',
    PATH_REFERENCIAL_CONTACTS_GROUP_NEW: '/referencial/contactGroup/new',
    PATH_REFERENCIAL_PARAMETERS_GROUP_NEW: '/referencial/parameterGroup/new',
    PATH_REFERENCIAL_CONTRIBUTORS_GROUP_NEW: '/referencial/contributorGroup/new',
}
