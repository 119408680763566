export default class DtoMatEvent {
    constructor(obj) {
        this.id = obj.id // Long
        this.materielType = obj.materielType // Option[String],
        this.idMateriel = obj.idMateriel // Option[Long],
        this.eventNumber = obj.eventNumber // Option[Long],
        this.eventDate = obj.eventDate // Option[DateTime],
        this.idType = obj.idType // Option[Long],
        this.comment = obj.comment // Option[String],
        this.referent = obj.referent // Option[Long],
        this.updateLogin = obj.updateLogin // Option[String],
        this.updateDate = obj.updateDate // Option[DateTime],
    }
}