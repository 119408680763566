import React from 'react'
import PropTypes from 'prop-types'
import { Alert } from '@mui/material'

const MUIAlert = ({
    severity,
    sx = {},
    children,
}) => (
    <Alert
        severity={severity}
        sx={{
            fontSize: '1rem',
            lineHeight: 'inherit',
            '& .MuiAlert-message': { padding: '1rem 0' },
            '& .MuiAlert-icon': { padding: '0.9rem 0' },
            ...sx,
        }}
    >
        {children}
    </Alert>
)

MUIAlert.propTypes = {
    severity: PropTypes.string,
    sx: PropTypes.shape({}),
    children: PropTypes.element,
}

export default MUIAlert