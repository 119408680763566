export default class DtoContactTableLine {
    constructor(obj) {
        this.id = obj[0] // Int,
        this.name = obj[1] // Option[String],
        this.isUser = obj[2] // Option[String],
        this.city = obj[3] // Option[String],
        this.email = obj[4] // Option[String],
        this.mobile = obj[5] // Option[String],
        this.phoneTel = obj[6] // Option[String],
        this.desktopTel = obj[7] // Option[String],
        this.postalCode = obj[8] // Option[String],
        this.socialReason = obj[9] // Option[String],
        this.country = obj[10] // Option[String],
    }
}