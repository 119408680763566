import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { v4 as uuidv4 } from 'uuid'

const $ = window.$

class Section extends Component {
    constructor(props) {
        super(props)
        this.state = {
            open: true,
            collapsible: uuidv4(),
        }
    }

    toggleOpen = () => {
        this.setState(({ open }) => ({ open: !open }))
    }

    componentDidMount() {
        $(`.${this.state.collapsible}`).collapsible('open', this.props.openFirst ? 0 : 1).collapsible('open', 1)
    }

    render() {
        return (
            <li className='desactive'>
                <ul className={ `no-margin ${this.state.collapsible}` } data-collapsible='expandable'>
                    <li className='sub-card-list'>
                        <div className='card-panel card-list-title margin-top-1 margin-bottom-0 row padding-1 collapsible-header valign-wrapper' style={ { background: this.props.color, height: '10%', borderBottom: '0px' } } onClick={ this.toggleOpen }>
                            <h6 className='col s11 white-text valign-wrapper' style={ { textAlign: 'left' } }>{ this.props.title } <div style={ { paddingLeft: '4px' } }> { `(${this.props.number})` } </div></h6>
                            <i className='col s2 right-align medium material-icons no-margin expand-style-thin'>{ this.state.open ? 'expand_less' : 'expand_more' }</i>
                        </div>
                        <div className='col s12 content no-padding collapsible-body' style={ { border: `solid ${this.props.color}` } }>
                            { this.props.children }
                        </div>
                    </li>
                </ul>
            </li>

        )
    }
}

Section.propTypes = {
    openFirst: PropTypes.bool,
    collapsible: PropTypes.bool,
    title: PropTypes.string.isRequired,
    number: PropTypes.number,
    color: PropTypes.string,
    children: PropTypes.element.isRequired,
}

Section.defaultProps = {
    collapsible: false,
}

export default Section
