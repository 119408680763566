import { pick, last, findLastIndex, isNil } from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import SieauParameterDto from '../../../../administration/dto/SieauParameterDto'
import Button from '../../../../components/forms/Button'
import Checkbox from '../../../../components/forms/Checkbox'
import Input from '../../../../components/forms/Input'
import NumberField from '../../../../components/forms/NumberField'
import Select from '../../../../components/forms/Select'
import Row from '../../../../components/react/Row'
import DtoJob from '../../../../import/dto/DtoJob'
import DtoJobExecution from '../../../../import/dto/DtoJobExecution'
import DtoQualification from '../../../../quality/dto/DtoQualification'
import { SANDRE } from '../../../../referencial/constants/ReferencialConstants'
import DtoSandreCode from '../../../../referencial/dto/DtoSandreCode'
import PiezometerStationAction from '../../../../station/actions/PiezometerStationAction'
import DtoContributor from '../../../../station/dto/DtoContributor'
import DtoPiezometryStationMeasure from '../../../../station/dto/piezometer/DtoPiezometryStationMeasure'
import AppStore from '../../../../store/AppStore'
import { getDate, getHour } from '../../../../utils/DateUtil'
import {
    checkMandatoryFields,
    getLocalStorageJson,
    onChangeDate,
    onChangeHour,
    setConfirmationModal,
    setLocalStorageJson,
} from '../../../../utils/FormUtils'
import { hasValue, round } from '../../../../utils/NumberUtil'
import { checkStatusQualificationConsistency, getMeasureValue, getNGFValue } from '../../../../utils/PiezometryUtils'
import { getSettingInt } from '../../../../utils/SettingUtils'
import { getQualificationSelectOptions, getStatusSelectOptions } from '../../../../utils/StatusUtil'
import { arrayOf, getSandreList, instanceOf, removeNullKeys } from '../../../../utils/StoreUtils'
import { MEASURE_COTE } from '../../../constants/PiezometryConstants'
import DtoPiezometer from '../../../dto/DtoPiezometer'
import SelectedMeasurePanel from './SelectedMeasurePanel'
import moment from 'moment'
import MultiContributorsAutocomplete from 'referencial/components/contributor/components/MultiContributorsAutocomplete'
import PiezometryAction from 'piezometry/actions/PiezometryAction'
import { WhiteCard } from '../../../../components/styled/Card'
import { StyledFieldSet, StyledLegend } from '../../../../components/StyledElements'
import { Collapse } from '@mui/material'

class ValidationToolPanel extends Component {
    constructor(props) {
        super(props)
        const validationFilters = getLocalStorageJson('PIEZO_VALIDATION_FILTERS') || {}
        const firstMeasure = props.dates.startDate ? props.measures.find(m => m.date >= props.dates.startDate) : undefined
        this.state = {
            status: null,
            qualification: null,
            nature: null,
            measureMode: null,
            validateActive: false,
            subTool: 'validation',
            producer: getSettingInt(props.applicationSettings, 'validationDefaultProducer'),
            manager: getSettingInt(props.applicationSettings, 'validationDefaultManager'),
            validator: getSettingInt(props.applicationSettings, 'validationDefaultValidator'),
            selection: 'window',
            value: null,
            withCancel: null,
            initialPoint: null,
            nbDaysMoreNext: 1,
            emptyFieldsDontClear: false,
            seeMoreCriterias: false,
            filterStatus: undefined,
            filterQualification: undefined,
            filterSuperior: undefined,
            filterInferior: undefined,
            filterProducer: undefined,
            filterManager: undefined,
            filterValidator: undefined,
            validateFullDay: getLocalStorageJson('VALIDATE_FULL_DATE') || false,
            ...pick(firstMeasure, ['producer', 'manager', 'validator']),
            ...validationFilters,
        }
    }

    componentDidUpdate = (prevProps) => {
        const firstMeasure = this.props.dates.startDate ? this.props.measures.find(m => m.date >= this.props.dates.startDate) : undefined
        if (firstMeasure && prevProps.dates.startDate !== this.props.dates.startDate) {
            const validationFilters = getLocalStorageJson('PIEZO_VALIDATION_FILTERS') || {}
            this.setState(prevState => ({
                producer: validationFilters.producer ?? firstMeasure.producer ?? prevState.producer,
                manager: validationFilters.manager ?? firstMeasure.manager ?? prevState.manager,
                validator: validationFilters.validator ?? firstMeasure.validator ?? prevState.validator,
            }))
        }
    }

    saveValidationFilters = () => {
        const validationFilters = pick(this.state, ['status', 'qualification', 'nature', 'measureMode', 'producer', 'manager', 'validator', 'emptyFieldsDontClear'])
        setLocalStorageJson('PIEZO_VALIDATION_FILTERS', validationFilters)
    }

    setCancelButtonWhenMoving = () => {
        if (this.state.selection === 'moving' && !this.state.validateActive) {
            this.setState({ withCancel: true })
        }
    }

    getUpdatedValue = () => {
        if (this.state.value && this.props.selectedPoint) {
            if (!this.props.piezoMode) {
                return { value: this.state.value }
            }
            const NGFValue = this.props.displayCote === MEASURE_COTE.NGF ? this.state.value : getNGFValue({ value: this.state.value }, this.props.displayCote, this.props.lastLandmark, this.props.groundRefAlti)
            const measureCoteValue = getMeasureValue({ NGF: NGFValue }, this.props.selectedPoint.cote, this.props.lastLandmark, this.props.groundRefAlti)
            return { value: measureCoteValue, NGF: NGFValue }
        }
        return {}
    }

    onCheckStatusQualification = () => {
        const message = checkStatusQualificationConsistency(this.state.status, this.state.qualification)
        if (message) {
            setConfirmationModal(this.onValidate, message, { confirmMessage: i18n.toContinue })
        } else {
            this.onValidate()
        }
    }

    onValidate = () => {
        if (this.state.subTool === 'newPoint') {
            const filterFields = this.state.emptyFieldsDontClear ? removeNullKeys : obj => obj
            const values = pick(filterFields({ ...this.state, date: this.props.dates.startDate }), ['date', 'value', 'status', 'qualification', 'nature', 'producer', 'manager', 'validator', 'measureMode', 'initialPoint'])
            checkMandatoryFields([{ field: 'date', i18n: 'date' }, { field: 'value', i18n: 'value' }], values, () => {
                const compensation = this.getCompensation()
                const valueWithCompensation = this.valueWithCompensation(values.value, compensation)
                // const depthWithCompensation = this.props.displayCote === MEASURE_COTE.NGF ? getMeasureValue({ value: valueWithCompensation, cote: MEASURE_COTE.NGF }, MEASURE_COTE.DEPTH, this.props.lastLandmark, this.props.groundRefAlti) : valueWithCompensation
                const NGFWithCompensation = this.props.displayCote === MEASURE_COTE.NGF ? valueWithCompensation : getMeasureValue({ value: valueWithCompensation, cote: this.props.displayCote }, MEASURE_COTE.NGF, this.props.lastLandmark, this.props.groundRefAlti)
                const newMeasure = {
                    ...values,
                    piezoId: this.props.piezometer.id,
                    isNew: true,
                    updated: true,
                    dataType: this.props.selectedDataType,
                    NGF: NGFWithCompensation,
                    cote: MEASURE_COTE.DEPTH,
                    value: valueWithCompensation,
                    codepoint: this.props.selectedCodePoint,
                }
                this.props.changeParent({ measures: [...this.props.measures, newMeasure] })
                this.setState({ validateActive: true })
            })
        } else {
            const start = this.props.dates.startDate
            const end = this.props.dates.endDate
            const filterFields = this.state.emptyFieldsDontClear ? removeNullKeys : obj => obj
            const values = pick(filterFields(this.state), ['status', 'qualification', 'nature', 'producer', 'manager', 'validator', 'measureMode', 'initialPoint'])
            const updatedValue = this.getUpdatedValue()
            const measures = this.props.measures.map(m => {
                if (this.filterMeasure(m, start, end)) {
                    return { ...m, ...values, updated: true, ...updatedValue }
                }
                return m
            })
            if (measures.length) {
                this.props.changeParent({ measures })
                this.setState({ validateActive: true })
            }
        }
    }

    onSave = () => {
        const updatedMeasures = this.props.measures.filter(m => m.updated)
        AppStore.dispatch(PiezometerStationAction.updatePiezometerTypeMeasures(
            this.props.piezometer.id, updatedMeasures, this.props.measures, measures => {
                const newDate = this.state.subTool === 'newPoint' ? { startDate: moment(updatedMeasures[0].date).add(this.state.nbDaysMoreNext, 'day').valueOf(), endDate: moment(updatedMeasures[0].date).add(this.state.nbDaysMoreNext, 'day').valueOf() } : {}
                this.props.changeParent({ measures, initialMeasures: measures, ...newDate })
                this.setState({ validateActive: false })
                if (this.state.subTool !== 'newPoint') {
                    this.props.createTechnicalEvent(updatedMeasures, this.state)
                }
                AppStore.dispatch(PiezometryAction.refreshOnePiezometerSituation(this.props.piezometer.id, -1))
            }, this.props.selectedDataType))
    }

    onCancel = () => {
        this.props.onCancel()
        this.setState({ validateActive: false, value: null, withCancel: null, initialPoint: null })
    }

    getSelectedMeasurePanel = () => {
        if (this.state.subTool === 'validation') {
            if (this.props.dates.startDate) {
                const start = this.props.dates.startDate
                const measures = this.props.measures.filter(m => m.date >= start)
                if (measures.length) {
                    // return this.getSelectedMeasureInfos(measures[0])
                    return <SelectedMeasurePanel measure={{ ...measures[0], unit: this.props.selectedDataType } } message={ this.state.selection !== 'point' ? i18n.firstMeasureDetails : i18n.selectedMeasureDetails } piezoMode={ this.props.piezoMode } displayCote={ this.props.displayCote } lastLandmark={ this.props.lastLandmark } groundRefAlti={ this.props.groundRefAlti }/>
                }
            }
        }
        if (this.state.subTool === 'edit') {
            return <SelectedMeasurePanel measure={ this.props.selectedPoint } message={ i18n.selectedMeasureDetails } piezoMode={ this.props.piezoMode }/>
        }
        return null
    }

    changeMeasure = (changes, newValue) => {
        if (this.props.piezoMode) {
            const diff = hasValue(newValue) ? round(newValue - this.props.selectedPoint.calculated) : null
            const newValues = hasValue(newValue) ? { value: this.props.selectedPoint.value + diff, NGF: this.props.selectedPoint.NGF + diff, calculated: newValue } : {}
            const newMeasure = { ...this.props.selectedPoint, ...changes, ...newValues, updated: true }
            const newMeasures = Object.assign([], this.props.measures, {
                [newMeasure.measureIndex]: newMeasure,
            })
            this.props.changeParent({ measures: newMeasures, selectedPoint: newMeasure })
        } else {
            const newMeasure = { ...this.props.selectedPoint, ...changes, value: newValue, updated: true }
            const newMeasures = Object.assign([], this.props.measures, {
                [newMeasure.measureIndex]: newMeasure,
            })
            this.props.changeParent({ measures: newMeasures, selectedPoint: newMeasure })
        }
    }

    valueWithCompensation = (v, compensation) => {
        if (!v) {
            return null
        }
        return compensation ? round(this.props.displayCote === MEASURE_COTE.NGF ? v - compensation : v + compensation) : v
    }

    getCompensation = () => this.props.piezometer.link_measureMethod.find(mm => hasValue(mm.measureOffset) && mm.startDate < moment().valueOf() && (!mm.endDate || mm.endDate > moment().valueOf()))?.measureOffset

    changeNewPoint = changes => this.setState({ newPoint: ({ ...this.state.newPoint, ...changes }) })

    withStatus = (m) => hasValue(this.state.filterStatus) ? m.status === this.state.filterStatus : true
    withQualification = (m) => hasValue(this.state.filterQualification) ? m.qualification === this.state.filterQualification : true
    withSuperior = (m) => {
        if (hasValue(this.state.filterSuperior)) {
            return isNil(m.NGF) ? m.value > this.state.filterSuperior : getMeasureValue({ NGF: m.NGF }, this.props.displayCote, this.props.lastLandmark, this.props.groundRefAlti) > this.state.filterSuperior
        } return true
    }
    withInferior = (m) => {
        if (hasValue(this.state.filterInferior)) {
            return isNil(m.NGF) ? m.value < this.state.filterInferior : getMeasureValue({ NGF: m.NGF }, this.props.displayCote, this.props.lastLandmark, this.props.groundRefAlti) < this.state.filterInferior
        } return true
    }
    withProducer = (m) => hasValue(this.state.filterProducer) ? m.producer === this.state.filterProducer : true
    withManager = (m) => hasValue(this.state.filterManager) ? m.manager === this.state.filterManager : true
    withValidator = (m) => hasValue(this.state.filterValidator) ? m.validator === this.state.filterValidator : true

    withSuperiorAndInferior = (m) => {
        if (hasValue(this.state.filterInferior) && hasValue(this.state.filterSuperior)) {
            const value = isNil(m.NGF) ? m.value : getMeasureValue({ NGF: m.NGF }, this.props.displayCote, this.props.lastLandmark, this.props.groundRefAlti)
            return value < this.state.filterInferior || value > this.state.filterSuperior
        }
        return (this.withInferior(m) && this.withSuperior(m))
    }
    filterMeasure = (m, start, end) => (m.date >= start) && (m.date <= end) && this.withStatus(m) && this.withQualification(m) && this.withSuperiorAndInferior(m) && this.withProducer(m) && this.withManager(m) && this.withValidator(m)

    render() {
        const { changeParent, dates } = this.props
        const selectedPoint = this.props.selectedPoint || {}
        const point = this.state.selection === 'point' || this.state.selection === 'moving' && dates.startDate ? this.props.measures.find(m => m.date === dates.startDate) : null

        const startDateTime = dates.startDate
        const endDateTime = dates.endDate
        const selectedMeasures = this.props.measures.filter(m => this.filterMeasure(m, startDateTime, endDateTime)).length
        const disabled = { disabled: ['point'].includes(this.state.selection) ? false : this.state.validateActive }
        const disabledButton = { disabled: (['point'].includes(this.state.selection) ? false : this.state.validateActive) || getLocalStorageJson('VALIDATE_FULL_DATE') }
        const disabledDates = { disabled: disabled.disabled || !['window', 'newPoint'].includes(this.state.selection) || getLocalStorageJson('VALIDATE_FULL_DATE') }
        const selectedValue = hasValue(this.state.value) ? this.state.value : (hasValue(selectedPoint.calculated) ? round(selectedPoint.calculated) : (
            hasValue(this.props.selectedPoint) ? (!this.props.piezoMode ? this.props.selectedPoint.value : getMeasureValue(this.props.selectedPoint, this.props.displayCote, this.props.lastLandmark, this.props.groundRefAlti)) : null
        ))
        const view = this.props.tableView ? 'tableau' : 'graphique'

        const compensation = this.getCompensation()

        return (
            <WhiteCard title={ i18n.piezoValidation } round>
                <div className='padding-top-1' id='validationToolPanel'/>
                <Row className='padding-bottom-1 center-align'>
                    <Button
                        tooltip={i18n.selectRange}
                        icon='settings_ethernet'
                        className={ `validToolBtn margin-right-1 ${this.state.subTool === 'validation' && this.state.selection === 'window' ? 'activated' : ''}` }
                        onClick={ () => {
                            this.props.changeParent({ selectionMode: 'start', startDate: null, endDate: null, draggable: false, selectedPoint: null })
                            this.setState({ subTool: 'validation', selection: 'window', initialPoint: null, value: null })
                        } }
                        {...disabledButton}
                    />
                    <Button
                        tooltip={i18n.selectHistory}
                        className={ `validToolBtn margin-right-1 ${this.state.subTool === 'validation' && this.state.selection === 'historic' ? 'activated' : ''}` }
                        onClick={ () => {
                            this.props.selectHisto()
                            this.props.changeParent({ draggable: false, selectionMode: null, selectedPoint: null })
                            this.setState({ subTool: 'validation', selection: 'historic', initialPoint: null, value: null })
                        } }
                        icon='insert_chart'
                        {...disabledButton}
                    />
                    <Button
                        tooltip={i18n.selectMeasure}
                        icon='edit_location'
                        className={ `validToolBtn margin-right-1 ${this.state.subTool === 'validation' && this.state.selection === 'point' ? 'activated' : ''}` }
                        onClick={ () => {
                            this.props.changeParent({ selectionMode: 'point', startDate: null, endDate: null, draggable: false, selectedPoint: null })
                            this.setState({ subTool: 'validation', selection: 'point', initialPoint: null, value: null })
                        } }
                        {...disabledButton}
                    />
                    {
                        !this.props.tableView && (
                            <Button
                                tooltip={i18n.modifyValues}
                                icon='pan_tool'
                                className={ `validToolBtn margin-right-1 ${this.state.selection === 'moving' ? 'activated' : ''}` }
                                onClick={ () => {
                                    if (!this.props.draggable) {
                                        this.props.changeParent({ draggable: true, startDate: null, endDate: null, selectedPoint: null, selectionMode: 'moving' })
                                    }
                                    this.setState({ subTool: 'edit', selection: 'moving', value: null })
                                } }
                                {...disabledButton}
                            />
                        )
                    }
                    <Button
                        tooltip={i18n.addMeasure}
                        onClick={ () => {
                            const lastMeasure = last(this.props.measures)
                            const newDate = lastMeasure ? moment(lastMeasure.date).add(1, 'day').valueOf() : null
                            this.props.changeParent({ selectionMode: null, startDate: newDate, endDate: newDate, draggable: false, selectedPoint: null })
                            this.setState({ subTool: 'newPoint', selection: 'newPoint', initialPoint: null, value: null })
                        } }
                        icon='add'
                        className={ `validToolBtn margin-right-1 ${this.state.subTool === 'newPoint' ? 'activated' : ''}` }
                        {...disabledButton}
                    />
                </Row>
                <div>
                    <Row>
                        <Checkbox
                            col={12}
                            checked={this.state.validateFullDay}
                            onChange={() => {
                                this.setState(prev => ({
                                    validateFullDay: !prev.validateFullDay,
                                }), setLocalStorageJson('VALIDATE_FULL_DATE', !this.state.validateFullDay))
                                const lastIndexValidated = findLastIndex(this.props.measures, m => m.status === 2 || m.status === 3)
                                const firstNotValidated = this.props.measures[lastIndexValidated + 1]
                                const lastMeasure = last(this.props.measures)
                                this.props.changeParent({
                                    startDate: moment(firstNotValidated?.date ?? lastMeasure.date).startOf('day').valueOf(), endDate: lastMeasure.date,
                                    endHour: lastMeasure.date,
                                })
                            }}
                            label={i18n.validateFullDay}
                            {...disabled}
                        />
                    </Row>
                    <StyledFieldSet style={{ margin: 10, backgroundColor: '#d9dfe4' }}>
                        <StyledLegend>{i18n.selectionCriteria}</StyledLegend>
                        { startDateTime && endDateTime && selectedMeasures > 0 && <Row className='padding-left-2 bold'>{ selectedMeasures } { selectedMeasures > 1 ? i18n.selectedMeasures : i18n.selectedMeasure }</Row> }
                        <Row className='padding-left-2 padding-bottom-1'>
                            <h6>{
                                this.props.selectionMode === 'start' ? `Sélectionnez la date de début sur le ${view}.` : (
                                    this.props.selectionMode === 'end' ? `Sélectionnez la date de fin sur le ${view}.` : (
                                        this.props.selectionMode === 'point' && !startDateTime && !endDateTime ? `Sélectionnez un point sur le ${view}.` : (
                                            this.props.selectionMode === 'moving' ? `Vous pouvez déplacer les points sur le ${view}.` : ''
                                        )
                                    )
                                )
                            }</h6>
                        </Row>
                        <Row>
                            <Input
                                col={ 6 }
                                title={ ['window', 'historic'].includes(this.state.selection) ? i18n.startDate : i18n.date }
                                value={ getDate(dates.startDate) }
                                {...disabledDates}
                                obligatory
                                onChange={ v => onChangeDate(v, v2 => {
                                    return this.state.selection === 'newPoint' ? changeParent({ startDate: v2, endDate: v2 }) : changeParent({ startDate: v2 })
                                }, { max: this.state.selection === 'newPoint' ? null : dates.endDate }, dates.startDate) }
                            />
                            <Input
                                col={ 6 }
                                title={ ['window', 'historic'].includes(this.state.selection) ? i18n.startHour : i18n.hour }
                                value={ getHour(dates.startDate) }
                                {...disabledDates}
                                obligatory
                                onChange={ v => onChangeHour(v, v2 => {
                                    return this.state.selection === 'newPoint' ? changeParent({ startDate: v2, endDate: v2 }) : changeParent({ startDate: v2 })
                                }, { max: this.state.selection === 'newPoint' ? null : dates.endDate }, dates.startDate) }
                            />
                        </Row>
                        {
                            ['window', 'historic', 'criterias'].includes(this.state.selection) && (
                                <Row >
                                    <Input col={ 6 } title={ i18n.endDate } value={ getDate(dates.endDate) } {...disabledDates}
                                        onChange={ v => onChangeDate(v, v2 => changeParent({ endDate: v2 }), { min: dates.startDate }, dates.endDate) } obligatory
                                    />
                                    <Input col={ 6 } title={ i18n.endHour } value={ getHour(dates.endDate) } {...disabledDates}
                                        onChange={ v => onChangeHour(v, v2 => changeParent({ endDate: v2 }), { min: dates.startDate }, dates.endDate) } obligatory
                                    />
                                </Row>
                            )
                        }
                        <Collapse in={this.state.seeMoreCriterias}>
                            <Row>
                                <Select col={ 6 } label={ i18n.status } value={ this.state.filterStatus } noSort
                                    onChange={ v => this.setState({ filterStatus: v }, this.saveValidationFilters) } options={ getStatusSelectOptions() }
                                    {...disabled}

                                />
                                <Select col={ 6 } label={ i18n.qualification } value={ this.state.filterQualification } noSort
                                    onChange={ v => this.setState({ filterQualification: v }, this.saveValidationFilters) } options={ getQualificationSelectOptions() }
                                    {...disabled}
                                />
                            </Row>
                            <Row>
                                <NumberField col={ 6 } title={i18n.valeurSup} value={ this.state.filterSuperior } noSort
                                    onChange={ v => this.setState({ filterSuperior: v }, this.saveValidationFilters) } floatValue
                                    {...disabled}

                                />
                                <NumberField col={ 6 } title={i18n.valeurInf} value={ this.state.filterInferior } noSort
                                    onChange={ v => this.setState({ filterInferior: v }, this.saveValidationFilters) } floatValue
                                    {...disabled}
                                />
                            </Row>
                            <Row>
                                <MultiContributorsAutocomplete
                                    col={12}
                                    label={i18n.producer}
                                    onChange={v => this.setState({ filterProducer: v }, this.saveValidationFilters)}
                                    values={this.state.filterProducer}
                                    options={this.props.contributors}
                                    {...disabled}
                                />
                            </Row>
                            <Row>
                                <MultiContributorsAutocomplete
                                    col={6}
                                    label={i18n.administrator}
                                    onChange={v => this.setState({ filterManager: v }, this.saveValidationFilters)}
                                    values={this.state.filterManager}
                                    options={this.props.contributors}
                                    {...disabled}
                                />
                                <MultiContributorsAutocomplete
                                    col={6}
                                    label={i18n.validator}
                                    values={this.state.filterValidator}
                                    onChange={v => this.setState({ filterValidator: v }, this.saveValidationFilters)}
                                    options={this.props.contributors}
                                    {...disabled}
                                />
                            </Row>
                        </Collapse>
                        <div style={{ height: 0 }}>
                            <i className='material-icons clickable' style={{
                                border: '1px solid #000',
                                borderRadius: '20px',
                                top: -13,
                                position: 'relative',
                                left: -14,
                                backgroundColor: '#d9dfe4',
                            }} onClick={() => this.setState({ seeMoreCriterias: !this.state.seeMoreCriterias }) }
                            >
                                {this.state.seeMoreCriterias ? 'expand_less' : 'expand_more'}
                            </i>
                        </div>
                    </StyledFieldSet>
                    <Row className='padding-top-1'>
                        <Select col={ 6 } label={ i18n.status } value={ this.state.status } noSort
                            onChange={ v => this.setState({ status: v }, this.saveValidationFilters) } options={ getStatusSelectOptions() }
                            nullLabel={ this.state.emptyFieldsDontClear ? i18n.unchanged : undefined}
                            {...disabled}

                        />
                        <Select col={ 6 } label={ i18n.qualification } value={ this.state.qualification } noSort
                            onChange={ v => this.setState({ qualification: v }, this.saveValidationFilters) } options={ getQualificationSelectOptions() }
                            nullLabel={ this.state.emptyFieldsDontClear ? i18n.unchanged : undefined}
                            {...disabled}
                        />
                    </Row>
                    <Row>
                        <Select col={ 6 } label={ i18n.measureNature } value={ this.state.nature } {...disabled}
                            onChange={ v => this.setState({ nature: v }, this.saveValidationFilters) } options={ getSandreList(this.props.sandreCodes, SANDRE.PIEZOMETER_MEASURE_NATURE, true) }
                            nullLabel={ this.state.emptyFieldsDontClear ? i18n.unchanged : undefined}
                        />
                        <Select col={ 6 } label={ i18n.obtainningMode } value={ this.state.measureMode } {...disabled}
                            onChange={ v => this.setState({ measureMode: v }, this.saveValidationFilters) } options={ getSandreList(this.props.sandreCodes, SANDRE.OBTAINING_MODE) }
                            nullLabel={ this.state.emptyFieldsDontClear ? i18n.unchanged : undefined}
                        />
                    </Row>
                    <Row>
                        <MultiContributorsAutocomplete
                            col={12}
                            label={i18n.producer}
                            onChange={v => this.setState({ producer: v }, this.saveValidationFilters)}
                            values={this.state.producer}
                            options={this.props.contributors}
                            placeholder={ this.state.emptyFieldsDontClear ? i18n.unchanged : undefined}
                            {...disabled}
                        />
                    </Row>
                    <Row>
                        <MultiContributorsAutocomplete
                            col={6}
                            label={i18n.administrator}
                            onChange={v => this.setState({ manager: v }, this.saveValidationFilters)}
                            values={this.state.manager}
                            options={this.props.contributors}
                            placeholder={ this.state.emptyFieldsDontClear ? i18n.unchanged : undefined}
                            {...disabled}
                        />
                        <MultiContributorsAutocomplete
                            col={6}
                            label={i18n.validator}
                            values={this.state.validator}
                            onChange={v => this.setState({ validator: v }, this.saveValidationFilters)}
                            options={this.props.contributors}
                            placeholder={ this.state.emptyFieldsDontClear ? i18n.unchanged : undefined}
                            {...disabled}
                        />
                    </Row>
                    <Row>
                        <Checkbox col={12} checked={this.state.emptyFieldsDontClear} onChange={v => this.setState({ emptyFieldsDontClear: v }, this.saveValidationFilters)} label={i18n.emptyFieldsDontClear} {...disabled}/>
                    </Row>
                    { ['point', 'moving', 'newPoint'].includes(this.state.selection) && (
                        <>
                            <Row className='valign-wrapper'>
                                <NumberField col={ 6 } title={ i18n.valueLabel } value={ selectedValue } onChange={
                                    this.state.selectionMode === 'moving' ? v => this.changeMeasure({}, v) : v => this.setState({ value: v })
                                } floatValue {...disabled}
                                />
                                <div className='col s6 padding-top-1'>
                                    <Checkbox label={ i18n.initialPoint } checked={ hasValue(this.state.initialPoint) ? this.state.initialPoint === 1 : (point && point.initialPoint === 1) }
                                        onChange={ v => this.setState({ initialPoint: v ? 1 : 2 }) } {...disabled}
                                    />
                                </div>
                            </Row>
                            {
                                compensation && this.state.selection === 'newPoint' ? (
                                    <Row className='padding-left-1'>
                                        <h6>{ i18n.manualCompensation + compensation }</h6>
                                    </Row>
                                ) : null
                            }
                        </>
                    ) }
                    {
                        this.state.selection === 'newPoint' && (
                            <Row >
                                <NumberField col={8} title={ i18n.nbDaysMore } value={ this.state.nbDaysMoreNext } onChange={ v => this.setState({ nbDaysMoreNext: v }) } />
                            </Row>
                        )
                    }
                </div>
                { this.getSelectedMeasurePanel() }
                <Row className='padding-bottom-1 padding-top-1 center-align'>
                    <Button tooltip={ i18n.apply } onClick={ this.onCheckStatusQualification } icon='border_color' className='btn-floating btn-large' {...disabled}/>
                    <Button tooltip={ i18n.cancel } onClick={ this.onCancel } icon='cancel' className='red btn-floating btn-large margin-left-2 margin-right-2' disabled={ this.state.withCancel ? false : !this.state.validateActive }/>
                    <Button tooltip={ i18n.register } onClick={ this.onSave } icon='save' disabled={ !this.state.validateActive } className={ `btn-floating btn-large ${this.state.validateActive ? 'pulse' : ''}` }/>
                </Row>
            </WhiteCard>
        )
    }
}

ValidationToolPanel.propTypes = {
    changeParent: PropTypes.func,
    piezometer: instanceOf(DtoPiezometer),
    dates: PropTypes.objectOf(PropTypes.number),
    qualifications: arrayOf(DtoQualification),
    sandreCodes: arrayOf(DtoSandreCode),
    selectionMode: PropTypes.string,
    measures: arrayOf(DtoPiezometryStationMeasure),
    onCancel: PropTypes.func,
    contributors: arrayOf(DtoContributor),
    createTechnicalEvent: PropTypes.func,
    jobs: arrayOf(DtoJob),
    jobExecutions: arrayOf(DtoJobExecution),
    selectHisto: PropTypes.func,
    selectedPoint: instanceOf(DtoPiezometryStationMeasure),
    draggable: PropTypes.bool,
    displayCote: PropTypes.number,
    lastLandmark: PropTypes.number,
    groundRefAlti: PropTypes.number,
    applicationSettings: PropTypes.arrayOf(PropTypes.instanceOf(SieauParameterDto)),
    piezoMode: PropTypes.bool,
    selectedDataType: PropTypes.number,
    tableView: PropTypes.bool,
    selectedCodePoint: PropTypes.number,
}

const mapStateToProps = store => ({
    piezometer: store.StationReducer.piezometer,
    qualifications: store.QualityReducer.qualifications,
    sandreCodes: store.ReferencialReducer.sandreCodes,
    contributors: store.ContributorReducer.contributors,
    jobs: store.JobReducer.jobs,
    jobExecutions: store.JobReducer.jobExecutions,
    applicationSettings: store.AdministrationReducer.applicationSettings,
})

export default connect(mapStateToProps)(ValidationToolPanel)