import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import InstallationOuvragePanel from 'installation/components/installations/components/InstallationOuvragePanel'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import i18n from 'simple-react-i18n'

class ModalOuvrage extends Component {
    constructor(props) {
        super(props)
        this.state = {
            ouvrage: props.ouvrage,
        }
    }

    onChangeValue = (obj) => {
        this.setState(({ ouvrage }) => ({
            ouvrage: {
                ...ouvrage,
                ...obj,
            },
        }))
    }

    onCancel = () => {
        this.setState({ ouvrage: null })
        this.props.onCancel()
    }

    onValidate = () => {
        const { ouvrage } = this.state
        this.props.onValidate(ouvrage)
    }

    render = () => {
        const { open, title, surveyMode } = this.props
        const { ouvrage } = this.state
        return (
            <Dialog
                onClose={() => this.setState({ open: false })}
                fullWidth
                maxWidth='md'
                open={open}
            >
                <DialogTitle>{title || i18n.updateWork}</DialogTitle>
                <DialogContent>
                    <InstallationOuvragePanel
                        work={ouvrage}
                        onChange={this.onChangeValue}
                        surveyMode={surveyMode}
                    />
                </DialogContent>
                <DialogActions style={{ justifyContent: 'space-between' }}>
                    <Button onClick={this.onCancel} variant='outlined' color='primary'>
                        {i18n.cancel}
                    </Button>
                    <Button onClick={this.onValidate} variant='contained' color='primary'>
                        {i18n.validate}
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}

ModalOuvrage.propTypes = {
    surveyMode: PropTypes.bool,
    open: PropTypes.bool,
    title: PropTypes.string,
    ouvrage: PropTypes.instanceOf({}),
    onCancel: PropTypes.func.isRequired,
    onValidate: PropTypes.func.isRequired,
}

export default ModalOuvrage