import React, { useState } from 'react'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import Input from 'components/forms/Input'
import Select from 'components/forms/Select'
import { SANDRE } from 'referencial/constants/ReferencialConstants'
import { Button, Card, Grid } from '@mui/material'
import MultiAutocomplete from 'components/forms/MultiAutocomplete'
import useSandreList from 'utils/customHook/useSandreList'
import { orderBy } from 'lodash'
import { DOCUMENT, PICTURE } from './constant/FileConstants'

const FileFilter = ({
    onValidateFilter = () => {},
    years,
    extensions,
}) => {
    const [searchValue, setSearchValue] = useState()
    const [naturePicture, setNaturePicture] = useState()
    const [natureDocument, setNatureDocument] = useState()

    const [extension, setExtension] = useState()
    const [year, setYear] = useState()
    const [active, setActive] = useState()
    const resetFilter = () => {
        setSearchValue()
        setNaturePicture()
        setNatureDocument()
        setExtension()
        setYear()
        setActive()
        onValidateFilter({})
    }
    const natures = (naturePicture || natureDocument) && [`${naturePicture}-${PICTURE}`, `${natureDocument}-${DOCUMENT}`]
    const typePictures = useSandreList(SANDRE.TYPE_PHOTO)
    const typeDocuments = useSandreList(SANDRE.TYPE_DOCUMENT)
    const typePicturesOrdered = [typePictures.find(tp => tp.code === 0), ...orderBy(typePictures.filter(tp => tp.code !== 0), 'name')]
    const typeDocumentsOrdered = [typeDocuments.find(tp => tp.code === 0), ...orderBy(typeDocuments.filter(tp => tp.code !== 0), 'name')]
    return (
        <div className='row no-margin white'>
            <Card className='row no-margin'>
                <div className='col s12 row no-margin padding-top-10-px'>
                    <Input
                        col={ 2 }
                        title={ i18n.search }
                        value={ searchValue }
                        onEnterKeyPress={ () =>
                            onValidateFilter({
                                searchValue,
                                year,
                                active,
                                extension,
                                natures,
                            })
                        }
                        onChange={ setSearchValue }
                    />
                    <MultiAutocomplete
                        elements={ extensions }
                        col={ 2 }
                        label={ i18n.extensions }
                        values={ extension }
                        onChange={ setExtension }
                        nullLabel='&nbsp;'
                    />
                    <Select
                        options={ typePicturesOrdered }
                        col={ 2 }
                        keyValue='name'
                        label={ i18n.pictureType }
                        value={ naturePicture }
                        onChange={ setNaturePicture }
                        nullLabel='&nbsp;'
                        noSort={true}
                        keyLabel='name'
                    />
                    <Select
                        options={ typeDocumentsOrdered }
                        col={ 2 }
                        keyValue='name'
                        label={ i18n.documentType }
                        value={ natureDocument }
                        onChange={ setNatureDocument }
                        nullLabel='&nbsp;'
                        noSort={true}
                        keyLabel='name'
                    />
                    <Select
                        options={ years }
                        col={ 2 }
                        label={ i18n.year }
                        value={ year }
                        onChange={ setYear }
                        nullLabel='&nbsp;'
                        noSort={true}
                    />
                    <Select
                        options={ [
                            { name: i18n.active, code: '1' },
                            { name: i18n.inactive, code: '0' },
                        ] }
                        col={ 2 }
                        label={ i18n.active }
                        value={ active }
                        onChange={ setActive }
                        nullLabel='&nbsp;'
                    />
                </div>
                <Grid container className='padding-1' justifyContent='flex-end'>
                    <Grid item>
                        <Button
                            style={{ width: '150px', fontSize: '14px' }}
                            className='btn-flat secondary-button'
                            onClick={ () => resetFilter() }
                            variant='outlined'
                            color='primary'
                        >
                            {i18n.reinit}
                        </Button>
                    </Grid>
                    <Grid item className='padding-left-1'>
                        <Button
                            style={{ width: '150px', fontSize: '14px' }}
                            className='btn-flat primary-button'
                            onClick={ () =>
                                onValidateFilter({
                                    searchValue,
                                    year,
                                    active,
                                    extension,
                                    natures,
                                }) } variant='contained' color='primary'
                        >
                            {i18n.search}
                        </Button>
                    </Grid>
                </Grid>
            </Card>
        </div>
    )
}

FileFilter.propTypes = {
    onValidateFilter: PropTypes.func,
    extensions: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string,
            code: PropTypes.string,
        })),
    years: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string,
            code: PropTypes.string,
        })),
}

export default FileFilter