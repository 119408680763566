import { uniq } from 'lodash'

export default class DtoInterventionEvent {
    constructor(obj) {
        this.piezometer = obj.piezometer // Int
        this.id = obj.id // Int
        this.date = obj.date // Option[DateTime]
        this.eventType = obj.eventType // Option[String]
        this.referent = obj.referent // Option[Int]
        this.comment = obj.comment // Option[String]
        this.campaign = obj.campaign // Option[Int]
        this.interventions = obj.interventions // Option[Array[String]]
        this.diagnostics = obj.diagnostics // Option[Array[String]]

        const uniqDiagnostics = obj.diagnostics?.map(d => parseInt(d.split(':')[0])).filter(d => !!d) ?? []
        this.uniqDiagnostics = uniq(uniqDiagnostics)
        const uniqSolutions = obj.diagnostics?.flatMap(d => d.split(':')[1].split(',').map(s => parseInt(s))).filter(s => !!s) ?? []
        this.uniqSolutions = uniq(uniqSolutions)
    }
}