import MessageCard from 'components/card/MessageCard'
import ProgressCard from 'components/card/ProgressCard'
import React, { useEffect, useMemo, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import useProgressDispatch from 'utils/customHook/useProgressDispatch'
import { isUndefined, uniqBy } from 'lodash'
import { getBeginingOfTheYear, getDate, getEndOfTheYear } from 'utils/DateUtil'
import { exportFile } from 'utils/ExportDataUtil'
import Select from 'components/forms/Select'
import { hasValue } from 'utils/NumberUtil'
import HydrometryAction from 'hydrometry/actions/HydrometryAction'
import HydroChart from 'components/echart/HydroChart'
import { getColorFromPalette2, getThresholdColor } from 'utils/ColorUtil'
import StationAction from 'station/actions/StationAction'
import { intParser } from 'utils/customHook/useApplicationSetting'
import { HydroCumulOptionIcon } from 'hydrometry/components/hydrometryComponents/HydroOption'
import { AUTO } from 'hydrometry/constants/HydroOptionConstant'
import DtoHydrometricStation from 'hydrometry/dto/DtoHydrometricStation'
import PropTypes from 'prop-types'
import useStartPeriod from 'catchment/utils/useStartPeriod'
import useEndPeriod from 'catchment/utils/useEndPeriod'
import { Card, CardContent } from '@mui/material'
import { CardTitle } from 'components/card/NewCard'

const CatchmentFollowUpHydro = ({
    hydrometricStationsLinked = [],
}) => {
    const dispatch = useDispatch()

    const {
        catchment,
        hydrometricStations,
        hydrometryThresholds,
        hydroDataTypes,
    } = useSelector(store => ({
        catchment: store.CatchmentReducer.catchment,
        hydrometricStations: store.HydrometryReducer.hydrometricStations,
        hydrometryThresholds: store.HydrometryReducer.hydrometryThresholds,
        hydroDataTypes: store.StationReducer.hydroDataTypes,
    }), shallowEqual)

    const startPeriod = useStartPeriod(intParser)
    const endPeriod = useEndPeriod(intParser)

    const [dataMeasures, setDataMeasures] = useState([])
    const [group, setGroup] = useState(AUTO)

    const getDefaultSelectPointId = () => {
        const mainPointFound = catchment.catchmentPoints?.find(({ mainPoint }) => mainPoint)
        const code = mainPointFound?.codeWithoutDesignation
        const idMainPoint = mainPointFound && hydrometricStations.find(q => q.code === code)?.id
        return idMainPoint ?? hydrometricStationsLinked[0]?.id
    }

    const [station, setStation] = useState(getDefaultSelectPointId)

    useEffect(() => {
        setStation(getDefaultSelectPointId)
    }, [catchment, hydrometricStations])

    useEffect(() => {
        dispatch(HydrometryAction.fetchHydrometricThresholds())
        dispatch(StationAction.fetchDataTypesByProject('SIH'))
    }, [])

    const {
        isLoaded,
        progress,
    } = useProgressDispatch((cancelRef) => {
        setDataMeasures([])
        const defaultInput = {
            groupFunc: group === 'AUTO' ? 'MAX' : group,
            chartMode: true,
            startDate: getBeginingOfTheYear(startPeriod),
            endDate: getEndOfTheYear(endPeriod),
        }
        return hydrometricStationsLinked.flatMap(({ id }) => {
            return [
                dispatch(HydrometryAction.getHydroChronicMeasures({ ...defaultInput, dataType: 4, stationId: id })), // hauteur
                dispatch(HydrometryAction.getHydroChronicMeasures({ ...defaultInput, dataType: 5, stationId: id })), // débit
            ].map(p => p.then(data => !cancelRef.current && data.measures.length && setDataMeasures(prev => uniqBy([data, ...prev], d => `${d.stationId}#${d.dataType}`))))
        })
    }, [group])

    const dataMeasuresFiltered = useMemo(() => {
        if (hasValue(station)) {
            return dataMeasures.filter(({ stationId }) => stationId === station)
        }
        return dataMeasures
    }, [dataMeasures, station])

    const formattedDataMeasures = useMemo(() => {
        return dataMeasuresFiltered.map((d, i) => {
            const thresholds = hydrometryThresholds.filter(t => !isUndefined(t.value) && t.stationId == d.stationId && t.dataType == d.dataType).map(t => ({
                value: t.value,
                title: t.title,
                color: t.htmlColor || getThresholdColor(t.color),
            }))
            const dataType = hydroDataTypes.find(h => h.id === d.dataType)
            const hydro = hydrometricStations.find(h => h.id === d.stationId)
            return {
                name: `${hydro?.name ?? ''} (${dataType?.name ?? ''})`,
                measures: d.measures,
                thresholds,
                gridIndex: i,
                color: getColorFromPalette2(i),
                lineStyle: {
                    type: dataType?.lineType,
                    opacity: dataType?.lineOpacity,
                    width: dataType?.lineWidth,
                },
            }
        })
    }, [dataMeasuresFiltered, hydrometryThresholds, hydroDataTypes, hydrometricStations])

    const formattedGrids = useMemo(() => {
        return dataMeasuresFiltered.map((d, i) => {
            const dataType = hydroDataTypes.find(h => h.id === d.dataType)
            return {
                name: dataType ? `${dataType.name ?? ''} ${dataType.unit ? `[${dataType.unit}]` : ''}` : i18n.unknown,
                gridOptions: {
                    top: i === 0 ? 0 : undefined,
                },
            }
        })
    }, [dataMeasuresFiltered, hydroDataTypes])

    const actions = [
        <HydroCumulOptionIcon
            key={0}
            defaultGroupType={group}
            onChangeGroupType={setGroup}
            disabled={!isLoaded}
        />,
        {
            icon: 'file_download',
            tooltip: i18n.export,
            onClick: () => {
                const exportData = dataMeasures.flatMap(({ measures, stationId, dataType }) => {
                    const hydro = hydrometricStations.find(({ id }) => id === stationId)
                    const dt = hydroDataTypes.find(h => h.id === dataType)
                    return measures.map(m => ({
                        code: hydro?.code,
                        date: { value: getDate(m.date), format: 'dd/MM/yyyy', cellType: 'date' },
                        value: { value: m.value, format: '0.00000', cellType: 'number' },
                        name: hydro?.name || stationId,
                        dataType: dt?.name ?? i18n.unknown,
                    }))
                })
                exportFile({
                    data: exportData.length ? [
                        {
                            ...exportData[0],
                            headers: ['code', 'name', 'dataType', 'date', 'value'],
                        },
                        ...exportData.slice(1),
                    ] : [],
                    exportType: 'xlsx',
                    titleFile: i18n.dataHydro,
                }, true)
            },
        },
    ]

    return (
        <Card data-cy='follow_up_hydro'>
            <CardTitle title={i18n.hydrometry} actions={actions} />
            <CardContent>
                {
                    !isLoaded && (
                        <ProgressCard progress={progress} />
                    )
                }
                {
                    isLoaded && (
                        <>
                            {
                                dataMeasures.length ? (
                                    <div style={{ padding: '0 5 10 5' }}>
                                        <div className='row no-margin' style={{ paddingTop: '8px' }}>
                                            <Select
                                                col={4}
                                                label={i18n.catchmentPoint}
                                                value={station}
                                                options={hydrometricStationsLinked}
                                                onChange={setStation}
                                                keyValue={'id'}
                                                noNullValue
                                            />
                                        </div>
                                        {!!dataMeasuresFiltered.length && (<HydroChart data={formattedDataMeasures} grids={formattedGrids} />)}
                                        {!dataMeasuresFiltered.length && (<MessageCard>{i18n.noDataToDisplayOnSelectedStation}</MessageCard>)}
                                    </div>
                                ) : (
                                    <MessageCard>{i18n.noDataToDisplay}</MessageCard>
                                )
                            }
                        </>
                    )
                }
            </CardContent>
        </Card>
    )
}

CatchmentFollowUpHydro.propTypes = {
    hydrometricStationsLinked: PropTypes.arrayOf(PropTypes.instanceOf(DtoHydrometricStation)).isRequired,
}

export default CatchmentFollowUpHydro