import ApplicationConf from 'conf/ApplicationConf'
import LogAction from 'log/actions/LogAction'
import i18n from 'simple-react-i18n'
import ToastrAction from 'toastr/actions/ToastrAction'
import { checkAuth, checkError, genericPromise2, getAuthorization, getJson } from 'utils/ActionUtils'
import WaitAction from 'wait/WaitAction'
import PerimetersThunk from './PerimetersThunk'
import { PerimeterActionConstant } from 'perimeters/reducer/PerimetersReducer'
import OwnerDto from 'perimeters/dto/OwnerDto'
import OwnerGroupDto from 'perimeters/dto/OwnerGroupDto'

const PerimetersAction = {
    createPerimetersFolder: perimetersFolderInput => dispatch => {
        dispatch(WaitAction.waitStart())
        return fetch(ApplicationConf.perimeters.perimetersFolders(), {
            method: 'POST',
            headers: getAuthorization(),
            body: JSON.stringify(perimetersFolderInput) })
            .then(checkAuth)
            .then(checkError)
            .then(getJson)
            .then(res => {
                dispatch(WaitAction.waitStop())
                dispatch(ToastrAction.success(i18n.perimetersFolderCreated))
                dispatch(PerimetersThunk.getPerimetersFolders())
                return res
            })
            .catch(() => {
                dispatch(WaitAction.waitStop())
                dispatch(ToastrAction.error(i18n.somethingWentWrong))
            })
    },

    updatePerimetersFolder: perimetersFolder => dispatch => {
        dispatch(WaitAction.waitStart())
        return fetch(ApplicationConf.perimeters.perimetersFolders(), {
            method: 'PUT',
            headers: getAuthorization(),
            body: JSON.stringify(perimetersFolder),
        })
            .then(checkAuth)
            .then(checkError)
            .then(getJson)
            .then(res => {
                dispatch(WaitAction.waitStop())
                dispatch(ToastrAction.success(i18n.perimetersFolderUpdated))
                dispatch(PerimetersThunk.getPerimetersFolders())
                return res
            })
            .catch(() => {
                dispatch(WaitAction.waitStop())
                dispatch(ToastrAction.error(i18n.somethingWentWrong))
            })
    },

    deletePerimetersFolder: id => dispatch => {
        dispatch(WaitAction.waitStart())
        return fetch(ApplicationConf.perimeters.perimetersFolders(), {
            method: 'DELETE',
            headers: getAuthorization(),
            body: JSON.stringify({
                id,
            }) })
            .then(checkAuth)
            .then(checkError)
            .then(getJson)
            .then(res => {
                dispatch(WaitAction.waitStop())
                dispatch(ToastrAction.success(i18n.perimetersFolderDeleted))
                dispatch(PerimetersThunk.getPerimetersFolders())
                return res
            })
            .catch(() => {
                dispatch(WaitAction.waitStop())
                dispatch(ToastrAction.error(i18n.somethingWentWrong))
            })
    },

    updatePerimeter: perimeter => dispatch => {
        dispatch(WaitAction.waitStart())
        return fetch(ApplicationConf.perimeters.perimeters(), {
            method: 'PUT',
            headers: getAuthorization(),
            body: JSON.stringify(perimeter),
        })
            .then(checkAuth)
            .then(checkError)
            .then(getJson)
            .then(res => {
                dispatch(WaitAction.waitStop())
                dispatch(ToastrAction.success(i18n.perimetersFolderUpdated))
                dispatch(PerimetersThunk.getPerimeters(perimeter.folderId))
                return res
            })
            .catch(err => {
                dispatch(WaitAction.waitStop())
                dispatch(LogAction.logError(`${i18n.updateError + i18n.perimeter} : ${err}`))
                dispatch(ToastrAction.error(i18n.updateError + i18n.perimeter))
            })
    },

    deletePerimeter: perimeterInput => dispatch => {
        dispatch(WaitAction.waitStart())
        return fetch(ApplicationConf.perimeters.perimeters(), {
            method: 'DELETE',
            headers: getAuthorization(),
            body: JSON.stringify(perimeterInput),
        })
            .then(checkAuth)
            .then(checkError)
            .then(getJson)
            .then(res => {
                dispatch(WaitAction.waitStop())
                dispatch(ToastrAction.success(i18n.perimetersFolderDeleted))
                dispatch(PerimetersThunk.getPerimeters(perimeterInput.folderId))
                return res
            })
            .catch(err => {
                dispatch(WaitAction.waitStop())
                dispatch(LogAction.logError(`${i18n.deleteError + i18n.perimeter} : ${err}`))
                dispatch(ToastrAction.error(i18n.deleteError + i18n.perimeter))
            })
    },

    createParcel: parcelInput => dispatch => {
        dispatch(WaitAction.waitStart())
        return fetch(ApplicationConf.perimeters.parcel(), {
            method: 'POST',
            headers: getAuthorization(),
            body: JSON.stringify(parcelInput) })
            .then(checkAuth)
            .then(checkError)
            .then(getJson)
            .then(res => {
                dispatch(WaitAction.waitStop())
                dispatch(ToastrAction.success(i18n.parcelCreated))
                return res
            })
            .catch(() => {
                dispatch(WaitAction.waitStop())
                dispatch(ToastrAction.error(i18n.somethingWentWrong))
            })
    },

    updateParcel: parcel => dispatch => {
        dispatch(WaitAction.waitStart())
        return fetch(ApplicationConf.perimeters.parcel(), {
            method: 'PUT',
            headers: getAuthorization(),
            body: JSON.stringify(parcel) })
            .then(checkAuth)
            .then(checkError)
            .then(getJson)
            .then(res => {
                dispatch(WaitAction.waitStop())
                dispatch(ToastrAction.success(i18n.parcelCreated))
                return res
            })
            .catch(() => {
                dispatch(WaitAction.waitStop())
                dispatch(ToastrAction.error(i18n.somethingWentWrong))
            })
    },

    deleteParcel: parcel => dispatch => {
        dispatch(WaitAction.waitStart())
        return fetch(ApplicationConf.perimeters.parcel(), {
            method: 'DELETE',
            headers: getAuthorization(),
            body: JSON.stringify(parcel) })
            .then(checkAuth)
            .then(checkError)
            .then(getJson)
            .then(res => {
                dispatch(WaitAction.waitStop())
                dispatch(ToastrAction.success(i18n.parcelCreated))
                dispatch(PerimetersAction.getParcels({
                    folderId: parcel.folderId,
                    perimeterId: parcel.perimeterId,
                }))
                return res
            })
            .catch(() => {
                dispatch(WaitAction.waitStop())
                dispatch(ToastrAction.error(i18n.somethingWentWrong))
            })
    },

    promiseGetDeedOwners: mortgageid => genericPromise2(ApplicationConf.perimeters.getDeed(mortgageid)),

    getDeedOwners: mortgageid => dispatch => {
        return PerimetersAction.promiseGetDeedOwners(mortgageid)
            .then((json = {}) => {
                return json
            })
            .catch(err => {
                dispatch(LogAction.logError(`${i18n.fetchError + i18n.deedOwners} : ${err}`))
                dispatch(ToastrAction.error(i18n.fetchError + i18n.deedOwners))
            })
    },

    promiseOwnersGroups: (folderId, perimeterId) => genericPromise2(ApplicationConf.perimeters.getOwnersGroups(folderId, perimeterId)),
    fetchOwnersGroups: (folderId, perimeterId) => dispatch => {
        return PerimetersAction.promiseOwnersGroups(folderId, perimeterId)
            .then((json = []) => {
                dispatch(PerimeterActionConstant.receiveOwnersGroups(json))
                return json
            })
            .catch(err => {
                dispatch(LogAction.logError(`${i18n.fetchError + i18n.ownersGroups} : ${err}`))
                dispatch(ToastrAction.error(i18n.fetchError + i18n.ownersGroups))
            })
    },

    promiseSearchParcels: filter => genericPromise2(ApplicationConf.perimeters.searchParcels(), { method: 'POST', body: filter }),
    searchParcels: filter => dispatch => {
        return PerimetersAction.promiseSearchParcels(filter)
            .then((json = []) => {
                return json
            })
            .catch(err => {
                dispatch(LogAction.logError(`${i18n.fetchError + i18n.parcels} : ${err}`))
                dispatch(ToastrAction.error(i18n.fetchError + i18n.parcels))
            })
    },

    promisesearchGroupsOwners: (group, cityCode) => genericPromise2(ApplicationConf.perimeters.searchGroupsOwners(group, cityCode)),
    searchGroupsOwners: (group, cityCode) => dispatch => {
        return PerimetersAction.promisesearchGroupsOwners(group, cityCode)
            .then((json = {}) => new OwnerDto(json))
            .catch(err => {
                dispatch(LogAction.logError(`${i18n.fetchError + i18n.ownersGroups} : ${err}`))
                dispatch(ToastrAction.error(i18n.fetchError + i18n.ownersGroups))
            })
    },

    createMortgage: mortgageInput => dispatch => {
        dispatch(WaitAction.waitStart())
        return fetch(ApplicationConf.perimeters.deed(), {
            method: 'POST',
            headers: getAuthorization(),
            body: JSON.stringify(mortgageInput) })
            .then(checkAuth)
            .then(checkError)
            .then(getJson)
            .then(res => {
                dispatch(WaitAction.waitStop())
                dispatch(ToastrAction.success(i18n.deedCreated))
                dispatch(PerimetersThunk.getMortgages())
                return res
            })
            .catch(() => {
                dispatch(WaitAction.waitStop())
                dispatch(ToastrAction.error(i18n.somethingWentWrong))
            })
    },

    updateMortgage: mortgage => dispatch => {
        dispatch(WaitAction.waitStart())
        return fetch(ApplicationConf.perimeters.deed(), {
            method: 'PUT',
            headers: getAuthorization(),
            body: JSON.stringify(mortgage) })
            .then(checkAuth)
            .then(checkError)
            .then(getJson)
            .then(res => {
                dispatch(WaitAction.waitStop())
                dispatch(ToastrAction.success(i18n.deedUpdated))
                dispatch(PerimetersThunk.getMortgages())
                return res
            })
            .catch(() => {
                dispatch(WaitAction.waitStop())
                dispatch(ToastrAction.error(i18n.somethingWentWrong))
            })
    },

    deleteMortgage: id => dispatch => {
        dispatch(WaitAction.waitStart())
        return fetch(ApplicationConf.perimeters.deed(), {
            method: 'DELETE',
            headers: getAuthorization(),
            body: JSON.stringify({
                id,
            }) })
            .then(checkAuth)
            .then(checkError)
            .then(getJson)
            .then(res => {
                dispatch(WaitAction.waitStop())
                dispatch(ToastrAction.success(i18n.parcelCreated))
                dispatch(PerimetersThunk.getMortgages())
                return res
            })
            .catch(() => {
                dispatch(WaitAction.waitStop())
                dispatch(ToastrAction.error(i18n.somethingWentWrong))
            })
    },

    promiseGetParcels: perimeter => genericPromise2(ApplicationConf.perimeters.getParcels(perimeter)),

    getParcels: perimeter => dispatch => {
        return PerimetersAction.promiseGetParcels(perimeter)
            .then((json = {}) => {
                return json
            })
            .catch(err => {
                dispatch(LogAction.logError(`${i18n.fetchError + i18n.parcels} : ${err}`))
                dispatch(ToastrAction.error(i18n.fetchError + i18n.parcels))
            })
    },

    addOwnersGroups: ownersGroups => dispatch => {
        dispatch(PerimeterActionConstant.addOwnersGroups(ownersGroups))
    },

    promisegetOwnerGroups: (communalAccount, cityCode) => genericPromise2(ApplicationConf.perimeters.getOwnerGroups(communalAccount, cityCode)),
    getOwnerGroups: (communalAccount, cityCode) => dispatch => {
        return PerimetersAction.promisegetOwnerGroups(communalAccount, cityCode)
            .then((json = []) => json.map(j => new OwnerGroupDto(j)))
            .catch(err => {
                dispatch(LogAction.logError(`${i18n.fetchError + i18n.ownersGroups} : ${err}`))
                dispatch(ToastrAction.error(i18n.fetchError + i18n.ownersGroups))
            })
    },
}

export default PerimetersAction
