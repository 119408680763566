import { Button, Dialog, DialogActions, DialogTitle, Grid, Icon } from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'
import i18n from 'simple-react-i18n'

export default function ModalWarningDeleteSurvey({ open, onCancel, onDelete }) {
    const body = (
        <div className='padding-1'>
            <span id='modal-text'>
                {i18n.sureDeleteSurvey}
            </span>
            <p id='modal-text'>
                {i18n.chooseDeleteSurvey}
            </p>
        </div>
    )

    return (
        <Dialog
            fullWidth
            maxWidth='md'
            open={open}
        >
            <DialogTitle>
                <Grid container alignItems='center'>
                    <Icon style={{ fontSize: '2.5rem', marginRight: 8 }}>warning</Icon>
                    <Grid item>{i18n.deleting}</Grid>
                </Grid>
            </DialogTitle>
            {body}
            <DialogActions style={{ justifyContent: 'space-between' }}>
                <Button variant='contained' color='primary' onClick={onCancel}>
                    {i18n.cancel}
                </Button>
                <h5 id='modal-description' className='bold' style={{ color: 'red' }}>{i18n.irreversibleAction}</h5>
                <Button variant='contained' className='red' style={{ color: 'white' }} onClick={onDelete}>
                    {i18n.confirm}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

ModalWarningDeleteSurvey.propTypes = {
    open: PropTypes.bool.isRequired,
    onCancel: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
}
