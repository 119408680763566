import PropTypes from 'prop-types'
import React, { Component } from 'react'

class Shared extends Component {
    componentDidMount() {
        $('.dropdown-button').dropdown()
    }

    componentDidUpdate() {
        $('.dropdown-button').dropdown()
    }

    render() {
        if (this.props.links && this.props.links.length > 0) {
            const links = this.props.links.map(link => {
                return (
                    <li>
                        <a onClick={ () => link.onClick(link) }>
                            <div className='row no-margin valign-wrapper dropdownLink collection-item'>
                                <div className='no-margin no-padding col s3 center-align'>
                                    {
                                        link.icon ? (
                                            <i className='material-icons' style={{ fontSize: '2rem' }}>{link.icon}</i>
                                        ) : (
                                            <img className='w-h-30' src={link.img} />
                                        )
                                    }
                                </div>
                                <div className='col s9'>
                                    <b>{ link.label ? link.label : '' }</b>
                                </div>
                            </div>
                        </a>
                    </li>
                )
            })
            return (
                <div className='action-wrapper'>
                    <ul id='dropdownLinks' className='dropdown-content dropdownLinks' style={{ overflowY: 'hidden' }}>
                        { links }
                    </ul>
                    <a className='dropdown-button anim-hover nav-actions' data-activates='dropdownLinks'>
                        <i className='material-icons left no-margin'>share</i>
                    </a>
                </div>
            )
        }
        return null
    }
}

Shared.propTypes = {
    links: PropTypes.arrayOf(PropTypes.shape({
        onClick: PropTypes.func,
        icon: PropTypes.any,
        img: PropTypes.string,
        label: PropTypes.string,
    })).isRequired,
}

export default Shared
