import { Button, Grid } from '@mui/material'
import Select from 'components/forms/Select'
import SuperMultiAutocomplete from 'components/forms/SuperMultiAutocomplete'
import { intersectionWith, uniq, uniqBy } from 'lodash'
import PropTypes from 'prop-types'
import DtoAnalysisLight from 'quality/dto/analyse/DtoAnalysisLight'
import DtoOperation from 'quality/dto/operation/DtoOperation'
import React, { useMemo, useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import MultiContributorsAutocomplete from 'referencial/components/contributor/components/MultiContributorsAutocomplete'
import i18n from 'simple-react-i18n'
import { H_HYDRO_MODULE, H_PIEZO_MODULE, H_PLUVIO_MODULE } from '../../../../account/constants/AccessRulesConstants'
import Checkbox from '../../../../components/forms/Checkbox'
import NumberField from '../../../../components/forms/NumberField'
import RadioButtons from '../../../../components/forms/RadioButtons'
import SimpleDatePicker from '../../../../components/forms/SimpleDatePicker'
import SelectionSelect from '../../../../components/forms/specific/SelectionSelect'
import ThresholdSelect from '../../../../components/forms/specific/ThresholdSelect'
import Icon from '../../../../components/icon/Icon'
import CollapseTopBar from '../../../../components/topBar/CollapseToBar'
import HabilitationRequired from '../../../../utils/components/HabilitationRequired'
import { hasValue } from '../../../../utils/NumberUtil'
import { getHardPiezoDataTypes } from '../../../../utils/PiezometryUtils'
import { getQualificationSelectOptions, getStatusSelectOptions } from '../../../../utils/StatusUtil'
import { i18nize } from '../../../../utils/StringUtil'
import { HORIZON_LIST, REGROUPING_LIST } from './constants/SuiviPCConstants'
import { getHardHydroDataTypes } from 'utils/HydroUtils'

const FieldsetHydro = ({
    hydroDatas = [{}],
    setHydroDatas = () => {},
}) => {
    const {
        hydrometricStations,
        hydrometryDataTypes,
    } = useSelector(store => ({
        hydrometricStations: store.HydrometryReducer.hydrometricStations,
        hydrometryDataTypes: store.HydrometryReducer.hydrometryDataTypes,
    }), shallowEqual)

    const dataTypes = useMemo(() => uniqBy([...hydrometryDataTypes, ...getHardHydroDataTypes()], 'id'), [hydrometryDataTypes])

    const onChangeHydro = (obj, index) => setHydroDatas(datas => datas.map((hydroData, i) => index === i ? { ...hydroData, ...obj } : hydroData))

    return (
        <HabilitationRequired habilitation={H_HYDRO_MODULE}>
            <fieldset>
                <legend>
                    {hydroDatas.length > 1 ? i18n.hydrometricStations : i18n.hydrometricStation}
                </legend>
                {
                    hydroDatas.map((hydroData, index) => {
                        return (
                            <Grid container spacing={2} columns={24}>
                                <Grid item xs={8}>
                                    <SuperMultiAutocomplete
                                        label={i18n.nameOfStation}
                                        options={hydrometricStations}
                                        values={hydroData.id}
                                        onChange={id => onChangeHydro({ id }, index)}
                                    />
                                </Grid>
                                <Grid item xs={8}>
                                    <SuperMultiAutocomplete
                                        options={dataTypes}
                                        values={hydroData.type}
                                        label={i18n.chronicType}
                                        keyLabel='label'
                                        onChange={type => onChangeHydro({ type }, index)}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    {
                                        hasValue(hydroData.id) && hydroData.type === 5 && (
                                            <Checkbox
                                                label={i18n.calculateFlux}
                                                checked={hydroData.calculateFlux}
                                                componentClassName={'padding-top-1'}
                                                onChange={calculateFlux => onChangeHydro({ calculateFlux }, index)}
                                            />
                                        )
                                    }
                                </Grid>
                                <Grid item xs={3}>
                                    {
                                        hasValue(hydroData.id) && hydroData.type === 5 && hydroData.calculateFlux && (
                                            <NumberField
                                                title={i18n.coeff}
                                                value={hydroData.coeffFlux}
                                                floatValue
                                                onChange={coeffFlux => onChangeHydro({ coeffFlux }, index)}
                                            />
                                        )
                                    }
                                </Grid>
                                <Grid item xs={1}>
                                    {
                                        hydroDatas.length - 1 === index && (
                                            <Icon
                                                icon={'add_circle'}
                                                onClick={() => setHydroDatas(datas => [...datas, {}])}
                                                className={'col s2 offset-s1'}
                                                style={{ fontSize: '3rem', color: '35609f' }}
                                                tooltip={i18n.addStation}
                                            />
                                        )
                                    }
                                </Grid>
                                <Grid item xs={1}>
                                    {
                                        hydroDatas.length > 1 && (
                                            <Icon
                                                icon={'remove_circle'}
                                                onClick={() => setHydroDatas(datas => datas.filter((_, i) => index !== i))}
                                                tooltip={i18n.deleteStation}
                                                style={{ fontSize: '3rem', color: '35609f' }}
                                                className={'col s2 offset-s1'}
                                            />
                                        )
                                    }
                                </Grid>
                            </Grid>
                        )
                    })
                }
            </fieldset>
        </HabilitationRequired>
    )
}

FieldsetHydro.propTypes = {
    hydroDatas: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        type: PropTypes.string,
        calculateFlux: PropTypes.bool,
        coeffFlux: PropTypes.number,
    })),
    setHydroDatas: PropTypes.func,
}

const FieldsetPiezo = ({
    piezoDatas = [{}],
    setPiezoDatas = () => { },
}) => {
    const {
        piezometers,
        piezometryDataTypes,
    } = useSelector(store => ({
        piezometers: store.PiezometryReducer.piezometersLight,
        piezometryDataTypes: store.PiezometryReducer.piezometryDataTypes,
    }), shallowEqual)

    const chronicTypes = useMemo(() => [
        { id: 'piezometryChronic', label: i18n.chronic },
        ...getHardPiezoDataTypes(),
        ...piezometryDataTypes,
    ], [piezometryDataTypes])

    const onChangePiezo = (obj, index) => setPiezoDatas(datas => datas.map((piezoData, i) => index === i ? { ...piezoData, ...obj } : piezoData))

    return (
        <HabilitationRequired habilitation={H_PIEZO_MODULE}>
            <fieldset>
                <legend>
                    {piezometers.length > 1 ? i18n.piezometers : i18n.piezometer}
                </legend>
                {
                    piezoDatas.map((piezoData, index) => {
                        return (
                            <Grid container spacing={2} columns={24}>
                                <Grid item xs={8}>
                                    <SuperMultiAutocomplete
                                        label={i18n.nameOfStation}
                                        options={piezometers.map(({ id, code, name }) => ({ id, name: name && `${name} [${code}]` || code }))}
                                        values={piezoData.id}
                                        onChange={id => onChangePiezo({ id }, index)}
                                    />
                                </Grid>
                                <Grid item xs={8}>
                                    <SuperMultiAutocomplete
                                        options={chronicTypes}
                                        values={piezoData.type}
                                        label={i18n.chronicType}
                                        keyLabel='label'
                                        onChange={type => onChangePiezo({ type }, index)}
                                    />
                                </Grid>
                                <Grid item xs={6} />
                                <Grid item xs={1}>
                                    {
                                        piezoDatas.length - 1 === index && (
                                            <Icon
                                                icon={'add_circle'}
                                                onClick={() => setPiezoDatas(datas => [...datas, {}])}
                                                className={'col s2 offset-s1'}
                                                style={{ fontSize: '3rem', color: '35609f' }}
                                                tooltip={i18n.addStation}
                                            />
                                        )
                                    }
                                </Grid>
                                <Grid item xs={1}>
                                    {
                                        piezoDatas.length > 1 && (
                                            <Icon
                                                icon={'remove_circle'}
                                                onClick={() => setPiezoDatas(datas => datas.filter((_, i) => index !== i))}
                                                tooltip={i18n.deleteStation}
                                                style={{ fontSize: '3rem', color: '35609f' }}
                                                className={'col s2 offset-s1'}
                                            />
                                        )
                                    }
                                </Grid>
                            </Grid>
                        )
                    })
                }
            </fieldset>
        </HabilitationRequired>
    )
}

FieldsetPiezo.propTypes = {
    piezoDatas: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        type: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
        ]),
    })),
    setPiezoDatas: PropTypes.func,
}

const FieldsetPluvio = ({
    pluvioDatas = [{ cumul: 1 }],
    setPluvioDatas = () => { },
}) => {
    const {
        pluviometers,
        pluviometryDataTypes,
    } = useSelector(store => ({
        pluviometers: store.PluviometryReducer.pluviometers,
        pluviometryDataTypes: store.PluviometryReducer.pluviometryDataTypes,
    }), shallowEqual)

    const onChangePluvio = (obj, index) => setPluvioDatas(datas => datas.map((piezoData, i) => index === i ? { ...piezoData, ...obj } : piezoData))

    return (
        <HabilitationRequired habilitation={H_PLUVIO_MODULE}>
            <fieldset>
                <legend>
                    {pluviometers.length > 1 ? i18n.pluviometers : i18n.pluviometer}
                </legend>
                {
                    pluvioDatas.map((pluvioData, index) => {
                        return (
                            <Grid container spacing={2} columns={24}>
                                <Grid item xs={8}>
                                    <SuperMultiAutocomplete
                                        label={i18n.nameOfStation}
                                        options={pluviometers}
                                        values={pluvioData.id}
                                        onChange={id => onChangePluvio({ id }, index)}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <SuperMultiAutocomplete
                                        options={pluviometryDataTypes}
                                        values={pluvioData.type}
                                        label={i18n.chronicType}
                                        keyLabel='label'
                                        onChange={type => onChangePluvio({ type }, index)}
                                    />

                                </Grid>
                                <Grid item xs={4}>
                                    <NumberField
                                        value={pluvioData.offset}
                                        title={i18n.offsetInDays}
                                        onChange={offset => onChangePluvio({ offset }, index)}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <NumberField
                                        value={pluvioData.cumul}
                                        title={i18n.cumulativeInDay}
                                        onChange={cumul => onChangePluvio({ cumul: cumul ?? 1 }, index)}
                                    />
                                </Grid>
                                <Grid item xs={2} />
                                <Grid item xs={1}>
                                    {
                                        pluvioDatas.length - 1 === index && (
                                            <Icon
                                                icon={'add_circle'}
                                                onClick={() => setPluvioDatas(datas => [...datas, { cumul: 1 }])}
                                                className={'col s2 offset-s1'}
                                                style={{ fontSize: '3rem', color: '35609f' }}
                                                tooltip={i18n.addStation}
                                            />
                                        )
                                    }
                                </Grid>
                                <Grid item xs={1}>
                                    {
                                        pluvioDatas.length > 1 && (
                                            <Icon
                                                icon={'remove_circle'}
                                                onClick={() => setPluvioDatas(datas => datas.filter((_, i) => index !== i))}
                                                tooltip={i18n.deleteStation}
                                                style={{ fontSize: '3rem', color: '35609f' }}
                                                className={'col s2 offset-s1'}
                                            />
                                        )
                                    }
                                </Grid>
                            </Grid>
                        )
                    })
                }
            </fieldset>
        </HabilitationRequired>
    )
}

FieldsetPluvio.propTypes = {
    pluvioDatas: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        type: PropTypes.number,
        offset: PropTypes.number,
        cumul: PropTypes.number,
    })),
    setPluvioDatas: PropTypes.func,
}

const SuiviPCcriteriaPanel = ({
    onValidate = () => { },
    analysis = [],
    operations = [],
    defaultFilter = {},
}) => {
    const {
        qualitometer,
        qualityCampaignStations,
        qualityCampaigns,
        parametersProps,
        parameterGroupUsage,
        contributors,
        supports,
        fractions,
        points,
    } = useSelector(store => ({
        qualitometer: store.QualityReducer.qualitometer,
        qualityCampaignStations: store.QualityReducer.qualityCampaignStations,
        qualityCampaigns: store.QualityReducer.qualityCampaigns,
        parametersProps: store.ParameterReducer.parameters,
        parameterGroupUsage: store.ParameterReducer.parameterGroupUsage,
        qualitometers: store.QualityReducer.qualitometersLight,
        contributors: store.ContributorReducer.contributors,
        supports: store.SupportReducer.supports,
        fractions: store.FractionReducer.fractions,
        points: store.QualityReducer.points,
    }), shallowEqual)

    const [startDate, setStartDate] = useState(defaultFilter.startDate)
    const [endDate, setEndDate] = useState(defaultFilter.endDate)
    const [parameters, setParameters] = useState(defaultFilter.parameters)
    const [selection, setSelection] = useState(defaultFilter.selection)
    const [selectionResults, setSelectionResults] = useState(defaultFilter.selectionResults)
    const [group, setGroup] = useState(defaultFilter.group)
    const [threshold, setThreshold] = useState(defaultFilter.threshold)

    const [producers, setProducers] = useState(defaultFilter.producers)
    const [laboratories, setLaboratories] = useState(defaultFilter.laboratories)
    const [point, setPoint] = useState(defaultFilter.point)
    const [campaign, setCampaign] = useState(defaultFilter.campaign)
    const [support, setSupport] = useState(defaultFilter.support)
    const [fraction, setFraction] = useState(defaultFilter.fraction)
    const [status, setStatus] = useState(defaultFilter.status)
    const [qualification, setQualification] = useState(defaultFilter.qualification)

    const [quantificationControl, setQuantificationControl] = useState(defaultFilter.quantificationControl)
    const [horizon, setHorizon] = useState(defaultFilter.horizon)
    const [regrouping, setRegrouping] = useState(defaultFilter.regrouping)
    const [detectionControl, setDetectionControl] = useState(defaultFilter.detectionControl)
    const [displayInListMode, setDisplayInListMode] = useState(defaultFilter.displayInListMode)
    const [displayAssociatedStations, setDisplayAssociatedStations] = useState(defaultFilter.displayAssociatedStations)
    const [displayAdvancedStatistics, setDisplayAdvancedStatistics] = useState(defaultFilter.displayAdvancedStatistics)
    const [groupEquivalences, setGroupEquivalences] = useState(defaultFilter.groupEquivalences)

    const [hydroDatas, setHydroDatas] = useState(defaultFilter.hydroDatas)
    const [piezoDatas, setPiezoDatas] = useState(defaultFilter.piezoDatas)
    const [pluvioDatas, setPluvioDatas] = useState(defaultFilter.pluvioDatas)

    const groupList = useMemo(() => uniqBy(parameterGroupUsage, 'groupCode'), [parameterGroupUsage])

    const statusList = useMemo(getStatusSelectOptions, [])

    const qualificationList = useMemo(getQualificationSelectOptions, [])

    const producerList = useMemo(() => {
        const uniqByProducer = uniqBy(operations, 'producer')
        const contributorList = uniqByProducer.map(obj => hasValue(obj.producer) && contributors.find(p => p.id === obj.producer))
        return contributorList.filter(c => !!c)
    }, [operations, contributors])

    const laboList = useMemo(() => {
        const uniqByProducer = uniqBy(analysis, 'labo')
        const contributorList = uniqByProducer.map(obj => hasValue(obj.labo) && contributors.find(p => p.id === obj.labo))
        return contributorList.filter(l => !!l)
    }, [analysis, contributors])

    const campaignCodes = qualityCampaignStations.filter(cs => cs.stationId === qualitometer.id).map(cs => cs.campaignId)
    const filteredCampaigns = qualityCampaigns.filter(cs => cs.campaignType === 3)
    const campaigns = uniq(campaignCodes).map(cCode => filteredCampaigns.find(c => c.id === cCode)).filter(c => !!c)

    return (
        <div style={{ margin: '0 20px 10px 20px' }}>
            <div style={{ backgroundColor: '#d9dfe4', borderLeft: '2px solid #161832', borderRight: '2px solid #161832', padding: '10px 0 5px 0' }}>
                <Grid container columnSpacing={2} rowSpacing={1} sx={{ padding: '0 10' }}>
                    <Grid item xs={2}>
                        <SimpleDatePicker
                            id='startDate'
                            label={i18n.startDate}
                            value={startDate}
                            onChange={setStartDate}
                            max={endDate}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <SimpleDatePicker
                            id='endDate'
                            label={i18n.endDate}
                            value={endDate}
                            onChange={setEndDate}
                            min={startDate}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <SelectionSelect
                            value={selection}
                            onChange={(results, value) => {
                                setSelection(value)
                                setSelectionResults(results)
                            }}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <SuperMultiAutocomplete
                            label={i18n.parameters}
                            options={selection !== '-1' ? intersectionWith(parametersProps, selectionResults, (p, code) => code === p.code) : parametersProps}
                            values={parameters}
                            onChange={setParameters}
                            keyLabel='labelWithCode'
                            keyValue='code'
                            multiple
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <Select
                            label={i18n.group}
                            options={groupList}
                            value={group}
                            onChange={setGroup}
                            clearFunction
                            keyValue='groupCode'
                            keyLabel='labelWithCode'
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <ThresholdSelect
                            selected={threshold}
                            onChange={setThreshold}
                            nullLabel='&nbsp;'
                        />
                    </Grid>
                    <Grid item xs={4} />
                    <Grid item xs={4}>
                        <Button
                            onClick={() => onValidate({
                                startDate,
                                endDate,
                                parameters,
                                selection,
                                selectionResults,
                                group,
                                threshold,

                                producers,
                                laboratories,
                                point,
                                campaign,
                                support,
                                fraction,
                                status,
                                qualification,

                                quantificationControl,
                                horizon,
                                regrouping,
                                detectionControl,
                                displayInListMode,
                                displayAssociatedStations,
                                displayAdvancedStatistics,
                                groupEquivalences,

                                hydroDatas,
                                piezoDatas,
                                pluvioDatas,
                            })}
                            variant='contained'
                            fullWidth
                        >
                            {i18n.visualize}
                        </Button>
                    </Grid>
                </Grid>
            </div>
            <CollapseTopBar>
                <div style={{ backgroundColor: '#d9dfe4', borderLeft: '2px solid #161832', borderRight: '2px solid #161832' }}>
                    <Grid container columnSpacing={2} rowSpacing={1} sx={{ padding: '0 10 10 10' }}>
                        <Grid item xs={3}>
                            <MultiContributorsAutocomplete
                                options={producerList}
                                label={i18n.producer}
                                keyLabel='labelDisplay'
                                keyValue='code'
                                onChange={setProducers}
                                multiple
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <MultiContributorsAutocomplete
                                options={laboList}
                                label={i18n.laboratory}
                                keyLabel='labelDisplay'
                                keyValue='code'
                                onChange={setLaboratories}
                                multiple
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <SuperMultiAutocomplete
                                options={points}
                                values={point}
                                label={i18n.samplePoint}
                                keyLabel='labelWithCode'
                                keyValue='idPoint'
                                onChange={setPoint}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <SuperMultiAutocomplete
                                options={campaigns}
                                values={campaign}
                                label={i18n.campaign}
                                onChange={setCampaign}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <Select
                                label={i18n.support}
                                options={supports}
                                value={support}
                                onChange={setSupport}
                                keyValue='id'
                                clearFunction
                                displayWithCode
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <Select
                                label={i18n.fraction}
                                options={fractions}
                                value={fraction}
                                onChange={setFraction}
                                keyValue='id'
                                clearFunction
                                displayWithCode
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <Select
                                options={statusList}
                                value={status}
                                label={i18n.status}
                                keyLabel='name'
                                keyValue='id'
                                onChange={setStatus}
                                noSort
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <Select
                                options={qualificationList}
                                value={qualification}
                                label={i18n.qualification}
                                keyLabel='name'
                                keyValue='id'
                                onChange={setQualification}
                                noSort
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FieldsetHydro
                                hydroDatas={hydroDatas}
                                setHydroDatas={setHydroDatas}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FieldsetPiezo
                                piezoDatas={piezoDatas}
                                setPiezoDatas={setPiezoDatas}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FieldsetPluvio
                                pluvioDatas={pluvioDatas}
                                setPluvioDatas={setPluvioDatas}
                            />
                        </Grid>
                        <Grid item xs={4} sx={{ height: '46px' }}>
                            <Checkbox
                                label={i18n.quantificationControl}
                                checked={quantificationControl}
                                onChange={setQuantificationControl}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <RadioButtons
                                title={i18n.horizon}
                                elements={i18nize(HORIZON_LIST)}
                                selected={horizon}
                                onChange={setHorizon}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            {
                                horizon !== 'operations' && (
                                    <RadioButtons
                                        elements={i18nize(REGROUPING_LIST)}
                                        title={i18n.regrouping}
                                        selected={regrouping}
                                        onChange={setRegrouping}
                                    />
                                )
                            }
                        </Grid>
                        <Grid item xs={4} sx={{ height: '46px' }}>
                            <Checkbox
                                label={i18n.detectionControl}
                                checked={detectionControl}
                                onChange={setDetectionControl}
                            />
                        </Grid>
                        <Grid item xs={4} sx={{ height: '46px' }}>
                            <Checkbox
                                label={i18n.displayInListMode}
                                checked={displayInListMode}
                                onChange={setDisplayInListMode}
                            />
                        </Grid>
                        <Grid item xs={4} sx={{ height: '46px' }}>
                            <Checkbox
                                label={i18n.displayAssociatedStations}
                                checked={displayAssociatedStations}
                                onChange={setDisplayAssociatedStations}
                            />
                        </Grid>
                        <Grid item xs={4} sx={{ height: '46px' }}>
                            <Checkbox
                                label={i18n.displayAdvancedStatistics}
                                checked={displayAdvancedStatistics}
                                onChange={setDisplayAdvancedStatistics}
                            />
                        </Grid>
                        <Grid item xs={4} sx={{ height: '46px' }}>
                            <Checkbox
                                label={i18n.groupEquivalences}
                                checked={groupEquivalences}
                                onChange={setGroupEquivalences}
                            />
                        </Grid>
                    </Grid>
                </div>
            </CollapseTopBar>
        </div>
    )
}

SuiviPCcriteriaPanel.propTypes = {
    onValidate: PropTypes.func,
    analysis: PropTypes.arrayOf(PropTypes.instanceOf(DtoAnalysisLight)),
    operations: PropTypes.arrayOf(PropTypes.instanceOf(DtoOperation)),
    defaultFilter: PropTypes.shape({/* see parent component */}),
}

export default SuiviPCcriteriaPanel
