import ReactEchartsCore from 'echarts-for-react/lib/core'
import 'echarts/lib/chart/bar'
import echarts from 'echarts/lib/echarts'
import { filter, orderBy, reduce } from 'lodash'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { enumerateBetweenDates, getMonthYear } from '../../../../utils/DateUtil'
import DtoAccountStatistic from '../../../dto/DtoAccountStatistic'

class AccountAccessChartPanel extends Component {
    constructor(props) {
        super(props)
    }

    getReduceStatistics = (statistics) => {
        return reduce(statistics, (a, b) => {
            (a[getMonthYear(b.statisticDate)] || (a[getMonthYear(b.statisticDate)] = [])).push(b)
            return a
        }, {})
    }


    getInitXAxis = (statistics) => {
        const orderStatistics = orderBy(filter(statistics, o => o.value), 'statisticDate', 'asc')
        const firstDate = orderStatistics[0]
        if (firstDate) {
            const rangeDate = enumerateBetweenDates(firstDate.statisticDate, moment(), 'months')
            return rangeDate.map((o) => getMonthYear(o))
        }
        return []
    }
    getOptions = (statistics) => {
        const xAxisDatas = this.getInitXAxis(statistics)
        const reduceDatas = this.getReduceStatistics(statistics)
        const dataSeries = xAxisDatas.map((o) => {
            if (reduceDatas[o]) {
                return reduceDatas[o].length
            }
            return 0
        })
        return {
            color: ['blue'],
            grid: {
                left: '3%',
                right: '4%',
                bottom: '3%',
                containLabel: true,
            },
            xAxis: {
                type: 'category',
                data: xAxisDatas,
            },
            yAxis: {
                type: 'value',
            },
            series: [{
                type: 'bar',
                data: dataSeries,
            }],
        }
    }

    render() {
        const statistics = filter(this.props.accountStatistics, o => o.value)
        if (statistics.length) {
            return (
                <div className='row no-margin card'>
                    <div className='col s12 no-padding'>
                        <ReactEchartsCore
                            echarts={ echarts }
                            option={ this.getOptions(statistics) }
                            notMerge={ true }
                            lazyUpdate={ true }
                            className={ 'row no-margin' }
                            style={ { height: 200 } }
                        />
                    </div>
                </div>
            )
        }
        return null
    }
}

AccountAccessChartPanel.propTypes = {
    accountStatistics: PropTypes.arrayOf(PropTypes.instanceOf(DtoAccountStatistic)),
}
const mapStateToProps = (store) => {
    return {
        accountStatistics: store.AccountReducer.accountStatistics,
    }
}
export default connect(mapStateToProps)(AccountAccessChartPanel)