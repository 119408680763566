import ReferencialDto from '../../../dto/ReferencialDto'

export default class UnitItem extends ReferencialDto {
    constructor(object) {
        super(object)
        this.id = object.code
        this.symbol = object.symbol
        this.status = object.status
        this.active = object.active
        this.headers = ['code', 'name', 'symbol', 'status']
    }
}
