import fetch from 'isomorphic-fetch'
import {
    ADD_ANALYSIS,
    RECEIVE_ALL_PC_SELECTION,
    RECEIVE_ASSOCIATED_STATIONS_ANALYSIS,
    RECEIVE_ASSOCIATED_STATIONS_OPERATIONS,
    RECEIVE_ASSOCIATED_STATIONS_POINTS,
    RECEIVE_ASSOCIATED_STATIONS_SAMPLES,
    RECEIVE_OPERATIONS,
    RECEIVE_QUALITY_THRESHOLD,
    RESET_SUIVI_PC
} from '../constants/SuivipcConstants'
import ApplicationConf from '../../../../../conf/ApplicationConf'
import { checkAuth, getJson } from 'utils/ActionUtils'
import { ceil, chunk, flatten, flattenDeep, groupBy } from 'lodash'
import AppStore from 'store/AppStore'
import ToastrAction from 'toastr/actions/ToastrAction'
import WaitAction from 'wait/WaitAction'
import { getAuthorization, promiseAllProgress } from '../../../../../utils/ActionUtils'
import i18n from 'simple-react-i18n'
import SieauAction from '../../../../../components/sieau/SieauAction'
import ParameterAction from '../../../../../referencial/components/parameter/actions/ParameterAction'
import ContributorAction from '../../../../../referencial/components/contributor/actions/ContributorAction'
import UnitAction from '../../../../../referencial/components/unit/actions/UnitAction'
import AdministrationAction from '../../../../../administration/actions/AdministrationAction'
import SupportAction from '../../../../../referencial/components/support/actions/SupportAction'
import OperationAction from '../../../../../quality/components/operation/actions/OperationAction'
import HydrometryAction from '../../../../../hydrometry/actions/HydrometryAction'
import QualityAction from '../../../../../quality/actions/QualityAction'
import FractionAction from '../../../../../referencial/components/fraction/actions/FractionAction'
import PluviometryAction from '../../../../../pluviometry/actions/PluviometryAction'
import PiezometryAction from '../../../../../piezometry/actions/PiezometryAction'
import PiezometerStationAction from '../../../../actions/PiezometerStationAction'
import LogAction from '../../../../../log/actions/LogAction'
import { QualityActionConstant } from 'quality/reducers/QualityReducer'
import { PiezometryActionConstant } from 'piezometry/reducers/PiezometryReducer'
import { HydrometryActionConstant } from 'hydrometry/reducers/HydrometryReducer'
import { PluviometryActionConstant } from 'pluviometry/reducers/PluviometryReducer'
import { ContributorActionConstant } from 'referencial/components/contributor/reducers/ContributorReducer'

const SuivipcAction = {
    fetchIfNeeded: (prop, fetchFunc, param1) => dispatch => {
        const elem = AppStore.getState().SuivipcReducer[prop]
        if (elem.length) {
            if (elem.length !== 0) {
                return { type: '' }
            }
        } else if (Object.keys(elem).length !== 0) {
            return { type: '' }
        }
        return dispatch(fetchFunc(param1))
    },

    receiveOperations: operations => ({ type: RECEIVE_OPERATIONS, operations }),
    promiseOperations: id => fetch(ApplicationConf.qualitometer.getOperations(id), {
        method: 'GET',
        headers: getAuthorization(),
    })
        .then(checkAuth)
        .then(getJson),
    fetchOperations: (stationId, progressCallback = () => { }, callback = () => { }) => dispatch => SuivipcAction.promiseOperations(stationId)
        .then(json => {
            if (json.error) {
                dispatch(LogAction.logError(`${i18n.fetchError}${i18n.operations} ${json.error}`))
                dispatch(ToastrAction.error(i18n.fetchError + i18n.operations))
                return []
            }
            return json
        })
        .then((json = []) => {
            dispatch(SuivipcAction.receiveOperations(json))
            dispatch(SieauAction.receiveProps('operations'))
            return json
        }).then(operations => {
            const ids = operations.map(({ id }) => id)
            const groupSize = ids.length / 15 > 10 ? 15 : ceil(ids.length / 15)
            const allAnalysis = chunk(ids, groupSize).map(groups => SuivipcAction.fetchGroupAnalysis(groups, stationId))
            promiseAllProgress(allAnalysis, progressCallback).then(jsonTab => {
                dispatch(SuivipcAction.receiveAnalysis(flatten(jsonTab), stationId))
                dispatch(SieauAction.receiveProps('analysis'))
                callback()
            }).catch(err => {
                dispatch(WaitAction.waitStop())
                dispatch(ToastrAction.error(i18n.fetchError + i18n.analysis))
                dispatch(LogAction.logError(i18n.fetchError + i18n.analysis + err))
            })
        }),

    fetchOperationAndAnalysis: (ids, progressCallback = () => { }) => async () => {
        if (!ids.length) {
            return { operations: [], analysis: [] }
        }
        const operations = await Promise.all(ids.map(id => SuivipcAction.promiseOperations(id)))
        const promiseAnalysis = ids.map((id, index) => {
            const operationIds = operations[index].map(o => o.id)
            const groupSize = operationIds.length / 15 > 10 ? 15 : ceil(operationIds.length / 15)
            return chunk(operationIds, groupSize).map(groups => SuivipcAction.fetchGroupAnalysis(groups, id))
        })
        const analysis = await promiseAllProgress(flatten(promiseAnalysis), progressCallback)
        return { operations: flatten(operations), analysis: flattenDeep(analysis) }
    },
    loadPcMonitoring: (progressCallback = () => { }) => dispatch => {
        return promiseAllProgress([
            QualityAction.promiseQualifications(),
            QualityAction.promiseStatus(),
            ContributorAction.promiseContributors(),
            ParameterAction.promiseParameters(),
            UnitAction.promiseUnits(),
            AdministrationAction.promiseSettings(),
            SupportAction.promiseSupports(),
            OperationAction.promiseRemarks(),
            FractionAction.promiseFractions(),
            ParameterAction.promiseParameterGroupUsage(),
            QualityAction.promiseThresholds(),
        ], progressCallback).then(jsonTab => {
            dispatch(QualityActionConstant.receiveQualifications(jsonTab[0]))
            dispatch(QualityActionConstant.receiveStatus(jsonTab[1]))
            dispatch(ContributorActionConstant.receiveAllContributors(jsonTab[2]))
            dispatch(ParameterAction.receiveParameters(jsonTab[3]))
            dispatch(UnitAction.receiveUnits(jsonTab[4]))
            dispatch(AdministrationAction.receiveSettings(jsonTab[5]))
            dispatch(SupportAction.receiveSupports(jsonTab[6]))
            dispatch(OperationAction.receiveRemarks(jsonTab[7]))
            dispatch(FractionAction.receiveFractions(jsonTab[8]))
            dispatch(ParameterAction.receiveParameterGroupUsage(jsonTab[9]))
            dispatch(QualityActionConstant.receiveThresholds(jsonTab[10]))
        }).catch((err) => {
            dispatch(LogAction.logError(`${i18n.loadError} : ${err}`))
            dispatch(ToastrAction.error(i18n.loadError))
        })
    },

    receiveAnalysis: (analysis, stationId) => ({ type: ADD_ANALYSIS, analysis, stationId }),
    fetchAnalysis: (id, codeStation) => fetch(ApplicationConf.operation.analysis(id, codeStation), {
        method: 'GET',
        headers: getAuthorization(),
    })
        .then(checkAuth)
        .then(getJson)
        .then((json = []) => {
            if (!json.error) {
                return json
            }
            return []
        }),
    fetchGroupAnalysis: (ids, codeStation) => fetch(ApplicationConf.operation.groupAnalysis(ids, codeStation), {
        method: 'GET',
        headers: getAuthorization(),
    })
        .then(checkAuth)
        .then(getJson)
        .then((json = []) => {
            if (!json.error) {
                return json
            }
            return []
        }),
    receiveQualityThresholds: qualityThresholds => ({ type: RECEIVE_QUALITY_THRESHOLD, qualityThresholds }),
    fetchQualityThresholds: id => dispatch => fetch(ApplicationConf.referencial.qualityThreshold(id, '0'), {
        method: 'GET',
        headers: getAuthorization(),
    })
        .then(checkAuth)
        .then(getJson)
        .then((json = []) => {
            dispatch(SuivipcAction.receiveQualityThresholds(json))
        }),
    receiveSelections: (selections) => {
        return { type: RECEIVE_ALL_PC_SELECTION, selections }
    },
    fetchSelections() {
        return (dispatch) => {
            return fetch(ApplicationConf.referencial.selection(0), {
                method: 'GET',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then((json = []) => {
                    dispatch(SuivipcAction.receiveSelections(json))
                }).then(() => {
                    dispatch(WaitAction.waitStop())
                })
                .catch(err => {
                    dispatch(LogAction.logError(i18n.fetchError + i18n.selections + err))
                    dispatch(WaitAction.waitStop())
                })
        }
    },
    loadSuiviPC: (id, threshold = null, progressCallback = () => { }) => dispatch => {
        const thresholdPromise = threshold ? [QualityAction.promiseQualityThreshold(threshold)] : []
        return (promiseAllProgress([
            QualityAction.promiseQualifications(),
            QualityAction.promiseStatus(),
            ContributorAction.promiseContributors(),
            ParameterAction.promiseParameters(),
            UnitAction.promiseUnits(),
            AdministrationAction.promiseSettings(),
            SupportAction.promiseSupports(),
            OperationAction.promiseRemarks(),
            HydrometryAction.promiseHydrometricStations(),
            FractionAction.promiseFractions(),
            PluviometryAction.promisePluviometers(),
            PiezometryAction.promisePiezometryDataTypes(),
            PiezometryAction.promisePiezometersLight(),
            ParameterAction.promiseParameterGroupUsage(),
            QualityAction.promiseThresholds(),
            HydrometryAction.promiseHydrometryDataTypes(),
            PluviometryAction.promisePluviometryDataTypes(),
            ...thresholdPromise,
        ], progressCallback).then(jsonTab => {
            dispatch(QualityActionConstant.receiveQualifications(jsonTab[0]))
            dispatch(QualityActionConstant.receiveStatus(jsonTab[1]))
            dispatch(ContributorActionConstant.receiveAllContributors(jsonTab[2]))
            dispatch(ParameterAction.receiveParameters(jsonTab[3]))
            dispatch(UnitAction.receiveUnits(jsonTab[4]))
            dispatch(AdministrationAction.receiveSettings(jsonTab[5]))
            dispatch(SupportAction.receiveSupports(jsonTab[6]))
            dispatch(OperationAction.receiveRemarks(jsonTab[7]))
            dispatch(HydrometryActionConstant.receiveAllHydrometricStations(jsonTab[8]))
            dispatch(FractionAction.receiveFractions(jsonTab[9]))
            dispatch(PluviometryActionConstant.receivePluviometers(jsonTab[10]))
            dispatch(PiezometryActionConstant.receivePiezometryDataTypes(jsonTab[11]))
            dispatch(PiezometryActionConstant.receiveAllPiezometersLight(jsonTab[12]))
            dispatch(ParameterAction.receiveParameterGroupUsage(jsonTab[13]))
            dispatch(QualityActionConstant.receiveThresholds(jsonTab[14]))
            dispatch(HydrometryActionConstant.receiveHydrometryDataTypes(jsonTab[15]))
            dispatch(PluviometryActionConstant.receivePluviometryDataTypes(jsonTab[16]))
            if (threshold) {
                const qualityThresholds = [{
                    thresholdCode: threshold.code,
                    thresholdType: threshold.thresholdType,
                    thresholds: jsonTab[17],
                    error: jsonTab[17].error,
                    errorMessage: jsonTab[17].message,
                }]
                dispatch(QualityActionConstant.receiveStationQualityThreshold(qualityThresholds))
            }
        }).catch((err) => {
            dispatch(LogAction.logError(`${i18n.loadError} : ${err}`))
            dispatch(ToastrAction.error(i18n.loadError))
        }))
    },
    loadStationMeasures: (piezoDatas, pluvioDatas, hydroDatas, startDate, endDate, progressCallback = () => { }) => dispatch => {
        const date = {
            startDate,
            endDate,
        }
        const piezoPromise = piezoDatas.map(pd => {
            switch (pd.type) {
                case 'piezometryChronic': return PiezometerStationAction.promisePiezometerMeasureBrute(pd.id, date).then(measures => ({ ...pd, measures, stationType: 'piezo' }))
                default: return PiezometerStationAction.oldPromisePiezometerAdditionalMeasures(pd.id, pd.type, date).then(measures => ({ ...pd, measures, stationType: 'piezo' }))
            }
        })
        const pluvioPromise = pluvioDatas.map(pd => {
            const input = {
                stationId: pd.id,
                dataType: 1,
                groupFunc: `CUMUL_PERSO_SUM_${(pd.cumul || 1) * 24}`,
                chartMode: true,
                ...date,
            }
            return PluviometryAction.promisePluvioChronicMeasures(input).then(measures => {
                const formatedMeasures = measures.map(([dateM, value]) => ({ date: dateM, value }))
                return ({ ...pd, measures: formatedMeasures, stationType: 'pluvio' })
            })
        })
        const hydroPromise = hydroDatas.map(hd => {
            const input = {
                stationId: hd.id,
                dataType: hd.type,
                groupFunc: 'MAX',
                chartMode: true,
                ...date,
            }
            return HydrometryAction.promiseHydroChronicMeasures(input).then(measures => {
                const formatedMeasures = measures.map(([dateM, value]) => ({ date: dateM, value }))
                return ({ ...hd, measures: formatedMeasures, stationType: 'hydro' })
            })
        })
        return promiseAllProgress([
            ...piezoPromise,
            ...pluvioPromise,
            ...hydroPromise,
        ], progressCallback).then((jsonTab = []) => jsonTab).catch((err) => {
            dispatch(LogAction.logError(`${i18n.loadError} : ${err}`))
            dispatch(ToastrAction.error(i18n.loadError))
        })
    },
    reset: () => {
        return {
            type: RESET_SUIVI_PC,
        }
    },
    fetchReset: () => {
        return (dispatch) => {
            dispatch(SuivipcAction.reset())
            dispatch(SieauAction.resetProps(['operations', 'analysis']))
        }
    },

    receiveAssociatedStationsSamples: samples => ({ type: RECEIVE_ASSOCIATED_STATIONS_SAMPLES, samples }),
    fetchAssociatedStationsSamples: listId => dispatch => {
        const promises = listId.map(id => SuivipcAction.promiseSamples(id))
        return Promise.all(promises).then(jsonTab => {
            dispatch(SuivipcAction.receiveAssociatedStationsSamples(flatten(jsonTab)))
        })
            .catch(err => {
                dispatch(ToastrAction.error(i18n.fetchError + i18n.samples))
                dispatch(LogAction.logError(`${i18n.fetchError + i18n.samples}, ${err}`))
            })
    },

    receiveAssociatedStationsOperations: operations => ({ type: RECEIVE_ASSOCIATED_STATIONS_OPERATIONS, operations }),
    receiveAssociatedStationsAnalysis: analysis => ({ type: RECEIVE_ASSOCIATED_STATIONS_ANALYSIS, analysis }),
    fetchAssociatedStationsOperations: (listId, progressCallback = () => { }, cb = () => { }) => dispatch => {
        const promises = listId.map(id => SuivipcAction.promiseOperations(id))
        return Promise.all(promises).then(jsonTab => {
            const operations = flatten(jsonTab)
            dispatch(SuivipcAction.receiveAssociatedStationsOperations(operations))

            const groupOperation = groupBy(operations, 'qualitometer')
            const groupSize = operations.length / 15 > 10 ? 15 : ceil(operations.length / 15)
            const allAnalysisPromises = Object.keys(groupOperation).flatMap(stationId => {
                const ids = groupOperation[stationId].map(({ id }) => id)
                return chunk(ids, groupSize).map(groups => SuivipcAction.fetchGroupAnalysis(groups, stationId))
            })

            promiseAllProgress(allAnalysisPromises, progressCallback).then(values => {
                dispatch(SuivipcAction.receiveAssociatedStationsAnalysis(flatten(values)))
                cb()
            }).catch(err => {
                dispatch(ToastrAction.error(i18n.fetchError + i18n.analysis))
                dispatch(LogAction.logError(i18n.fetchError + i18n.analysis + err))
            })
        })
            .catch(err => {
                dispatch(ToastrAction.error(i18n.fetchError + i18n.operations))
                dispatch(LogAction.logError(`${i18n.fetchError + i18n.operations}, ${err}`))
            })
    },
    receiveAssociatedStationsPoints: points => ({ type: RECEIVE_ASSOCIATED_STATIONS_POINTS, points }),
    fetchAssociatedStationsPoints: listId => dispatch => {
        const promises = listId.map(id => QualityAction.promiseQualitometerPoints(id).catch(() => []))
        return Promise.all(promises).then(jsonTab => {
            dispatch(SuivipcAction.receiveAssociatedStationsPoints(flatten(jsonTab)))
        })
    },
}

export default SuivipcAction
