import { Button } from '@mui/material'
import Icon from 'components/icon/Icon'
import { DialogActionsMUI, DialogContentMUI, DialogMUI, DialogTitleMUI } from 'components/styled/Dialog'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import i18n from 'simple-react-i18n'
import DtoInstallationWithGeoItem from '../../../installation/components/installations/dto/DtoInstallationWithGeoItem'
import DtoDeclarationVolumes from '../../../survey/dto/DtoDeclarationVolumes'
import VolumesPanel from '../panels/VolumesPanel'

class VolumesModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            volumes: props.volumes,
        }
    }

    onChangeValue = (obj) => {
        this.setState(({ volumes }) => ({
            volumes: {
                ...volumes,
                ...obj,
            },
        }))
    }

    onCancel = () => {
        this.setState({ volumes: null })
        this.props.onCancel()
    }

    onValidate = () => {
        const { volumes } = this.state
        this.props.onValidate(volumes)
    }

    render = () => {
        const { open, title, newVolumes, points, disabled } = this.props
        const { volumes } = this.state

        return (
            <DialogMUI
                onClose={() => this.setState({ open: false })}
                maxWidth='md'
                open={open}
            >
                <DialogTitleMUI style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    {title || i18n.updateVolumes}
                    <Icon style={{ color: 'white' }} size='small' icon='close' onClick={this.onCancel} />
                </DialogTitleMUI>
                <DialogContentMUI style={{ paddingBottom: 0 }}>
                    <VolumesPanel
                        volumes={volumes}
                        newVolumes={newVolumes}
                        onChange={this.onChangeValue}
                        points={points}
                        disabled={disabled}
                    />
                </DialogContentMUI>
                {!disabled && (
                    <DialogActionsMUI>
                        <Button onClick={this.onValidate} variant='contained' color='primary'>
                            {i18n.validate}
                        </Button>
                    </DialogActionsMUI>
                )}
            </DialogMUI>
        )
    }
}

VolumesModal.propTypes = {
    open: PropTypes.bool,
    disabled: PropTypes.bool,
    title: PropTypes.string,
    newVolumes: PropTypes.bool,
    volumes: PropTypes.instanceOf(DtoDeclarationVolumes),
    points: PropTypes.arrayOf(PropTypes.instanceOf(DtoInstallationWithGeoItem)),
    onCancel: PropTypes.func,
    onValidate: PropTypes.func,
}

VolumesModal.defaultProps = {
    volumes: {},
}

export default VolumesModal