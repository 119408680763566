import DtoMaterielType from '../../../dto/DtoMaterielType'
import { MAT_TELECOM } from '../../../constants/MaterielConstants'
import i18n from 'simple-react-i18n'

export default class TelecomTypeDto extends DtoMaterielType {
    constructor(obj) {
        super(obj)
        this.materielCategory = MAT_TELECOM
        this.materielLabel = i18n.telecom
        this.transmissionVolume = obj.transmissionVolume
        this.digitalLine = obj.digitalLine
        this.desiccant = obj.desiccant
        this.pileNumber = obj.pileNumber
        this.powerSupplyType = obj.powerSupplyType
        this.telecomMode = obj.telecomMode
        this.supplyTime = obj.supplyTime
    }
}
