export default class DtoTankLine {
    constructor(obj) {
        this.id = obj[0] // Int,
        this.code = obj[1] // Option[String],
        this.installationType = obj[2] // Option[Int],
        this.installationTypeLabel = obj[3] // Option[String],
        this.name = obj[4] // Option[String],
        this.townCode = obj[5] // Option[String],
        this.townName = obj[6] // Option[String],
        this.x = obj[7] // Option[Double],
        this.y = obj[8] // Option[Double],
        this.projection = obj[9] // Option[Int],
        this.projectionLabel = obj[10] // Option[String],
        this.dataOrigin = obj[11] // Option[String],
        this.jobExecutionId = obj[12] // Option[Long],
        this.internalReferences = obj[13] // Option[Long],
        this.status = obj[14] // Option[Int],
        this.statusLabel = obj[15] // Option[String],
        this.administratorId = obj[16] // Option[Int], // maitre d'ouvrage
        this.buildingOwnerName = obj[17] // Option[String]
        this.operatorId = obj[18] // Option[Int], // exploitant
        this.operatorName = obj[19] // Option[String],
        this.codeParcelle = obj[20] // Option[String],
        this.tankType = obj[21] // Option[Long],
        this.tankTypeLabel = obj[22] // Option[String],
        this.nbTanks = obj[23] // Option[Int],
        this.sectorId = obj[24] // Option[Long],
        this.sectorLabel = obj[25] // Option[String],
        this.typeName = 'installation'
    }
}