import DtoStationAssociation from 'station/dto/DtoStationAssociation'
import { RESET_SECTOR, RECEIVE_SECTOR_QUALITOMETERS, RECEIVE_SECTORS, RECEIVE_SECTOR } from '../constants/SectorConstants'
import DtoSector from '../dto/DtoSector'

export const store = {
    sectors: [],
    sector: {},
    qualitometersAssociations: [],
}

export function SectorReducer(state = {}, action) {
    switch (action.type) {
        case RECEIVE_SECTORS:
            return {
                ...state,
                sectors: action.sectors.map(a => new DtoSector(a)),
            }
        case RECEIVE_SECTOR:
            return {
                ...state,
                sector: new DtoSector(action.sector),
            }
        case RESET_SECTOR:
            return {
                ...state,
                sector: {},
                sectors: [],
            }
        case RECEIVE_SECTOR_QUALITOMETERS:
            return {
                ...state,
                qualitometersAssociations: action.qualitometers.map(q => new DtoStationAssociation(q)),
            }
        default:
            return state
    }
}
