import { STATION_NAME } from '../../station/constants/StationConstants'

export default class DtoQualityStation {
    constructor(obj) {
        this.id = obj.id
        this.nullValue = obj.nullValue || ''
        this.nullValue2 = obj.nullValue2 || ''
        this.code = obj.code
        this.city = obj.city || obj.townCode
        this.cityCode = obj.cityCode
        this.cityLabel = obj.cityLabel
        this.townCode = obj.townCode
        this.name = obj.name
        this.hiddenName = obj.hiddenName // use for filter qualitometer
        this.creation = obj.creation
        this.closeDate = obj.close
        this.address = obj.address
        this.localisation = obj.localisation
        this.typeName = 'quality'
        this.x = obj.x
        this.y = obj.y
        this.projection = obj.projection
        this.stationType = obj.stationType
        this.stationTypeLabel = obj.stationTypeLabel
        this.type = STATION_NAME.QUALITOMETER
        this.operations = obj.operations
        this.begin = obj.begin
        this.end = obj.end
        this.lineColor = obj.lineColor
        this.internalReferences = obj.internalReferences
        this.historyStart = obj.historyStart
        this.historyEnd = obj.historyEnd
        this.operationCount = obj.operationCount
        this.headers = ['code', 'city', 'name', 'creation', 'address', 'operations', 'begin', 'end', 'stationTypeLabel']
    }
}