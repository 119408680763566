const Materialize = window.Materialize

const updateMaterialize = () => {
    if (Materialize && Materialize.updateTextFields) {
        Materialize.updateTextFields()
    }
    $('ul.tabs').tabs()
    $('.modal').modal()
}

export { updateMaterialize }

