/* eslint-disable camelcase */
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import Table from '../../../../../components/datatable/Table'
import SieauAction from '../../../../../components/sieau/SieauAction'
import { getExport, setModal } from '../../../../../utils/linkUtils'
import DtoInstallationBorehole from '../../../../dto/borehole/DtoInstallationBorehole'
import BoreholeDrillingModal from './BoreholeDrillingModal'


const BoreholeDrillingTable = ({
    borehole = {},
    readMode = false,
    onChange = () => {},
    setPopup = () => {},
}) => {
    const drillings = borehole.link_drillings.map((c, index) => ({
        ...c,
        diameterSymbol: c.diameter,
        index,
    }))
    const tableData = drillings
    const headers = ['diameterSymbol', 'depth', 'comment']
    const exportAction = getExport(tableData, i18n.contributors, headers)
    const actions = !readMode ? [{
        iconName: 'note_add',
        tooltip: i18n.add,
        onClick: () => setModal(i18n.new, <BoreholeDrillingModal id={ borehole.id } saveResult={ r => onChange({ link_drillings: [...drillings, r] }) }/>, setPopup),
    }, exportAction] : [exportAction]

    return (
        <Table
            title={ i18n.drillings }
            condensed
            data={ tableData }
            type={{ headers }}
            sortable={ !!tableData.length }
            actions={ actions }
            alterable={ !readMode }
            onAlter={
                (element) => setModal(i18n.drillings,
                    <BoreholeDrillingModal
                        id={ borehole.id }
                        boreholeDriilling={ drillings.find(c => element.index === c.index) }
                        saveResult={ r => onChange({
                            link_drillings: [
                                ...drillings.filter(lc => lc.index !== element.index),
                                { ...r, index: element.index },
                            ],
                        })}
                    />
                    , setPopup)
            }
            deletable={ !readMode }
            onDelete={ (element) => onChange({ link_drillings: drillings.filter(lc => lc.index !== element.index) }) }
        />
    )
}

BoreholeDrillingTable.propTypes = {
    borehole: PropTypes.instanceOf(DtoInstallationBorehole),
    readMode: PropTypes.bool,
    onChange: PropTypes.func,
    setPopup: PropTypes.func,
}

const mapStateToProps = () => ({})

const mapDispatchToProps = {
    setPopup: SieauAction.setPopup,
}

export default connect(mapStateToProps, mapDispatchToProps)(BoreholeDrillingTable)