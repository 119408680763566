export default class DtoPollutionSourceActivityParams {
    constructor(obj) {
        this.STEPId = obj.STEPId // Option[Long],
        this.parameters = obj.parameters // Option[Seq[String]],
        this.inActivity = obj.inActivity // Option[Boolean],
        this.startDate = obj.startDate // Option[DateTime],
        this.endDate = obj.endDate // Option[DateTime]
        this.dischargeAgreement = obj.dischargeAgreement // Option[DateTime]
        this.icpe = obj.icpe // Option[DateTime]
    }
}