export default class DtoInstallationEquipment {
    constructor(obj) {
        this.idStation = obj.idStation
        this.equipmentIndex = obj.equipmentIndex
        this.equipmentCode = obj.equipmentCode
        this.equipmentName = obj.equipmentName
        this.startDate = obj.startDate
        this.endDate = obj.endDate
        this.comment = obj.comment
        // this.statusCode = obj.statusCode Boolean in database but not used for the moment
    }
}