import React, { useEffect, useMemo } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import { mapValues, orderBy, uniqBy } from 'lodash'
import Table from '../../../components/datatable/Table'
import Row from '../../../components/react/Row'
import { nbPerPageLabel, SANDRE } from '../../constants/ReferencialConstants'
import { push } from '@lagunovsky/redux-react-router'
import { setLocalStorageJson } from '../../../utils/FormUtils'
import Card from '../../../components/card/Card'
import Select from '../../../components/forms/Select'
import { getDate } from '../../../utils/DateUtil'
import { getSandreLabel, searchAllCharacters } from '../../../utils/StringUtil'
import { getStatusInformation } from '../../util/StatusUtils'
import useTitle from 'utils/customHook/useTitle'
import useActions from 'utils/customHook/useActions'
import ReferencialAction from 'referencial/action/ReferencialAction'
import useLocalStorage from 'utils/customHook/useLocalStorage'
import Input from 'components/forms/Input'
import { Button } from '@mui/material'
import { hasValue } from 'utils/NumberUtil'

const headers = ['field', 'code', 'name', 'mnemonique', 'displayStatus', 'reference']
const exportHeaders = ['field', 'code', 'name', 'mnemonique', 'status', 'reference', 'comment', 'creationDate', 'updateDate', 'author']

const LexiconsApp = () => {
    const {
        sandreCodes,
        referencialSandreCodes,
        referencialStatus,
    } = useSelector(store => ({
        sandreCodes: store.ReferencialReducer.sandreCodes,
        referencialSandreCodes: store.ReferencialReducer.referencialSandreCodes,
        referencialStatus: store.ReferencialReducer.referencialStatus,
    }), shallowEqual)

    const [filter, setFilter] = useLocalStorage('LEXICON_FILTER', {})
    const [filterTmp, setFilterTmp] = useLocalStorage('LEXICON_FILTER', {})

    const dispatch = useDispatch()

    useEffect(() => {
        if (!referencialStatus.length) {
            dispatch(ReferencialAction.fetchReferencialStatus())
        }
    }, [])

    useTitle(() => [{
        title: i18n.referencials,
        href: '/referencial',
    }, {
        title: i18n.lexicons,
        href: '/referencial/lexicons',
    }], [])

    const filteredList = useMemo(() => filter.selectedField ? sandreCodes.filter(sandreCode => sandreCode.field === filter.selectedField) : sandreCodes, [filter.selectedField, sandreCodes])
    const refList = useMemo(() => filteredList.filter(sandreCode => !referencialSandreCodes.some(rsf => rsf.code && rsf.field === sandreCode.field)), [filteredList, referencialSandreCodes])
    const lexiconByStatus = useMemo(() => hasValue(filter.status) ? refList.filter(lex => lex.status === filter.status) : refList, [filter.status, refList])
    const dataLexicons = useMemo(() => orderBy(lexiconByStatus, ['field', 'code']).map(sandreCode => ({
        ...sandreCode,
        updateDate: getDate(sandreCode.updateDate),
        creationDate: getDate(sandreCode.date),
        code: `${sandreCode.code}`,
        displayStatus: sandreCode.status === 3 ? getStatusInformation('3') : getSandreLabel(sandreCodes, SANDRE.DISPOSITIFS_ETATS, sandreCode.status),
        status: getSandreLabel(sandreCodes, SANDRE.DISPOSITIFS_ETATS, sandreCode.status),
        headers: exportHeaders,
    })), [lexiconByStatus, sandreCodes])

    const data = useMemo(() => {
        return dataLexicons
            .filter((obj) => searchAllCharacters(headers.map((key) => obj[key]).join())
                .includes(searchAllCharacters(filter.searchValue || '')))
    }, [dataLexicons, filter])

    const colorData = useMemo(() => data.map(u => ({ ...mapValues(u, o => ({ value: o })), fieldLink: u.field, id: u.code })), [data])

    useActions(() => {
        return {
            new: () => dispatch(push('/referencial/lexicon/new/-1/dashboard')),
            referencialActions: {
                actions: {
                    export: {
                        data,
                        titleFile: i18n.lexicons,
                    },
                },
            },
        }
    }, [filter])

    useEffect(() => {
        dispatch(ReferencialAction.fetchSandreCodes())
        dispatch(ReferencialAction.fetchReferencialSandreCodes())
    }, [])

    const onFilterChange = (newFilter) => {
        setLocalStorageJson('LEXICON_FILTER', newFilter)
        setFilterTmp(newFilter)
    }

    return (
        <>
            <Card noMargin={false} className='margin-left-1 margin-right-1' title={i18n.criterias}>
                <Row className='padding-top-1 padding-bottom-5-px'>
                    <Select
                        col={4}
                        label={i18n.lexicons}
                        value={filterTmp.selectedField}
                        options={orderBy(uniqBy(sandreCodes.filter(sandreCode => !referencialSandreCodes.some(rsf => rsf.code && rsf.field === sandreCode.field)), 'field'), 'field')}
                        onChange={v => onFilterChange({ ...filterTmp, selectedField: v })}
                        returnNull={true}
                        clearFunction
                        keyValue='field'
                        keyLabel='field'
                    />
                    <Input
                        col={3}
                        title={i18n.search}
                        value={filterTmp.searchValue}
                        onChange={v => setFilterTmp({ ...filterTmp, searchValue: v })}
                        onEnterKeyPress={() => setFilter({ ...filterTmp })}
                    />
                    <Select
                        options={referencialStatus}
                        label={i18n.status}
                        col={3}
                        onChange={v => setFilterTmp({ ...filterTmp, status: v })}
                        value={filterTmp.status}
                        nullLabel='&nbsp;'
                    />
                    <div className='col s2 padding-top-1'>
                        <Button className='right' onClick={() => setFilter({ ...filterTmp })} variant='contained' color='primary'>
                            {i18n.search}
                        </Button>
                    </div>
                </Row>
            </Card>
            <div className='margin-1 padding-top-5-px referencial-table'>
                <Table
                    title={i18n.nonSandreLexicons}
                    condensed paging
                    nbPerPageLabel={nbPerPageLabel}
                    customHeaders={{ displayStatus: i18n.status }}
                    data={colorData}
                    color
                    type={{ headers }}
                    sortable={!!sandreCodes.length}
                    onClick={element => dispatch(push(`/referencial/lexicon/${element.fieldLink}/${element.id}/dashboard`))}
                />
            </div>
        </>
    )
}

export default LexiconsApp