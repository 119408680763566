import React from 'react'
import PropTypes from 'prop-types'
import { Button, Dialog, Grid } from '@mui/material'
import i18n from 'simple-react-i18n'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { push } from '@lagunovsky/redux-react-router'
import { DialogActionsMUI, DialogContentMUI, DialogTitleMUI } from 'components/styled/Dialog'
import Icon from 'components/icon/Icon'

export default function ModalWarnNoConso({ open, toggleModal, onValidateDeclaration, instsWithoutIndex = [] }) {
    const {
        installationsWithGeo,
        declaration,
    } = useSelector(store => ({
        installationsWithGeo: store.InstallationReducer.installationsWithGeo,
        declaration: store.AgriReducer.declaration,
    }), shallowEqual)

    const dispatch = useDispatch()

    const handleClose = () => {
        onValidateDeclaration()
        toggleModal()
    }

    return (
        <Dialog open={open}>
            <DialogTitleMUI style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                {i18n.payAttention}
                <Icon style={{ color: 'white' }} size='small' icon='close' onClick={toggleModal} />
            </DialogTitleMUI>
            <DialogContentMUI>
                <p>{i18n.pointsWithoutConsoDeclared}</p>
                <Grid container alignItems='center' justifyContent='space-evenly'>
                    {instsWithoutIndex.map((id) => {
                        const inst = installationsWithGeo.find((i) => i.id === id)
                        return (
                            <>
                                <Grid item xs={7} className='bold'>{`${i18n.point} ${inst.code}${inst.name ? ` - ${inst.name}` : ''} - ${inst.location}  - ${inst.parcel} ${inst.section}`}</Grid>
                                <Grid item xs={4}>
                                    <Button variant='contained' onClick={() => dispatch(push(`/survey/${declaration.idSurvey}/declaration/${declaration.idDeclaration}/usages/${id}/reel`))}>{i18n.change}</Button>
                                </Grid>
                            </>
                        )
                    })}
                </Grid>
                <p id='modal-description' className='italic'>{i18n.contactAdmin}</p>
            </DialogContentMUI>
            <DialogActionsMUI>
                <Button variant='contained' onClick={handleClose}>
                    {i18n.ignoreAndValidateDeclaration}
                </Button>
            </DialogActionsMUI>
        </Dialog>
    )
}

ModalWarnNoConso.propTypes = {
    open: PropTypes.bool.isRequired,
    toggleModal: PropTypes.func.isRequired,
    onValidateDeclaration: PropTypes.func.isRequired,
    instsWithoutIndex: PropTypes.arrayOf(PropTypes.number),
}
