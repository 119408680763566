export default class DtoSector {
    constructor(obj) {
        this.id = obj.id // Option[Long],
        this.name = obj.name // Option[String],
        this.comment = obj.comment // Option[String],
        this.x = obj.x // Option[Double],
        this.y = obj.y // Option[Double],
        this.typeprojection = obj.typeprojection // Option[Double],
        this.administratorCode = obj.administratorCode // Option[Long],
        this.managementMode = obj.managementMode // Option[Long]
    }
}