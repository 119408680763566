import PropTypes from 'prop-types'
import { Card, CardActionArea, CardContent, CardMedia, Typography } from '@mui/material'

const ImageCard = ({
    title,
    description,
    img,
    alt = 'undefined img',
    imgHeight = 150,
    maxWidth = 345,
    onClick = () => {},
    active = false,
    noFocus = false,
}) => {
    return (
        <Card sx={{ maxWidth }} onClick={onClick} elevation={6}>
            <CardActionArea sx={active ? { backgroundColor: '#67acff', outline: 'none' } : {}} className={noFocus && !active ? 'cardUiNoFocus' : ''}>
                { img && (
                    <CardMedia
                        component='img'
                        height={imgHeight}
                        image={img}
                        alt={alt}
                    />
                ) }
                <CardContent>
                    <Typography gutterBottom variant='h5' component='div'>
                        {title}
                    </Typography>
                    <Typography variant='body2' color='text.secondary'>
                        {description}
                    </Typography>
                </CardContent>
            </CardActionArea>
        </Card>
    )
}

ImageCard.propTypes = {
    title: PropTypes.string,
    setSelectedModel: PropTypes.string,
    img: PropTypes.string,
    alt: PropTypes.string,
    imgHeight: PropTypes.number,
}

export default ImageCard