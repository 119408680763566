import moment from 'moment'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import i18n from 'simple-react-i18n'
import Card from '../../../components/card/Card'
import Table from '../../../components/datatable/Table'
import Button from '../../../components/forms/Button'
import Checkbox from '../../../components/forms/Checkbox'
import Input from '../../../components/forms/Input'
import Row from '../../../components/react/Row'
import { nbPerPageLabel } from '../../../referencial/constants/ReferencialConstants'
import { getDate } from '../../../utils/DateUtil'
import { exportFile } from '../../../utils/ExportDataUtil'
import { onChangeDate } from '../../../utils/FormUtils'
import { getExecStatusLabel } from '../../../utils/JobUtils'
import { arrayOf } from '../../../utils/StoreUtils'
import { searchAllCharacters } from '../../../utils/StringUtil'
import DtoJobFile from '../../dto/DtoJobFile'
import { connect } from 'react-redux'
import ExportAction from 'export/actions/ExportAction'
import { isEqual, orderBy, uniqBy } from 'lodash'
import Icon from 'components/icon/Icon'
import JobAction from 'import/actions/JobAction'

class ImportFiles extends Component {
    constructor(props) {
        super(props)
        this.state = {
            startDate: moment().subtract(30, 'days').valueOf(),
            endDate: moment().valueOf(),
            data: [],
            searchValue: '',
            checkGreen: true,
            checkYellow: true,
            checkRed: true,
            checkGrey: true,
        }
    }

    componentDidMount() {
        const { jobFiles } = this.props
        const smallDate = orderBy(jobFiles, 'startDate')?.[0]?.startDate
        this.setState({ startDate: smallDate }, () => this.calculateData())
    }

    componentDidUpdate = prevProps => {
        if (!isEqual(this.props.jobFiles, prevProps.jobFiles)) {
            this.calculateData()
        }
    }

    onExport = () => {
        const data = this.state.data
        if (data.length) {
            data[0].headers = ['fileLabel', 'execution', 'status', 'updateDate']
        }
        exportFile({
            data: this.state.data,
            exportType: 'xlsx',
            titleFile: i18n.files,
        })
    }

    getZip = () => {
        const { jobName } = this.props
        const { data } = this.state
        const filesPath = uniqBy(data.filter(d => d.filePath), 'fileLabel.value').map(d => d.filePath)
        this.props.zipExport(filesPath, `${i18n.files}_${i18n.job}_${jobName}`)
    }

    getColor = (file) => {
        if (file.status === 0) { // red
            return '#EF9A9A'
        }
        if (file.status === 1) { // green
            return '#A5D6A7'
        }
        if (file.status === 2) { // yellow
            return '#FFF176'
        }
        if (file.status === 4) { // grey
            return '#9e9e9e'
        }
        return 'white'
    }

    filterColor = (q) => {
        if (q.lineColor === '#A5D6A7') {
            return this.state.checkGreen
        }
        if (q.lineColor === '#FFF176') {
            return this.state.checkYellow
        }
        if (q.lineColor === '#EF9A9A') {
            return this.state.checkRed
        }
        if (q.lineColor === '#9e9e9e') {
            return this.state.checkGrey
        }
        return true
    }

    calculateData = () => {
        const data = orderBy(this.props.jobFiles, 'startDate').filter(f => !f.startDate || f.startDate >= this.state.startDate && f.startDate <= this.state.endDate).map(jobFile => {
            const status = getExecStatusLabel(jobFile)
            const updateDate = getDate(jobFile.startDate)
            return {
                fileLabel: { value: jobFile.fileName, className: jobFile.filePath && 'fileLabelWithPath' },
                execution: { value: jobFile.jobExecutionId },
                status: { value: status },
                updateDate: { value: updateDate, format: 'dd/MM/yyyy', cellType: 'date' },
                toProcess: {
                    value: (<Icon icon={jobFile.endDate < moment().valueOf() ? 'check_box' : 'check_box_outline_blank'} />),
                    onClick: () => {
                        this.props.fileToProcess(jobFile.jobId, jobFile.jobExecutionId, jobFile.fileName)
                    },
                },
                lineColor: this.getColor(jobFile),
                filePath: jobFile.filePath,

                hash: searchAllCharacters([jobFile.fileName, jobFile.jobExecutionId, status, updateDate].join('   ')),
            }
        })
        this.setState({ dataLoaded: true, data: data.filter(d => d.hash.includes(searchAllCharacters(this.state.searchValue)) && this.filterColor(d)) })
    }

    render() {
        const { showHistory } = this.props
        const cantLoadMore = this.state.data.length === this.props.nbJobFiles
        return (
            <Row className='padding-top-1 padding-right-1 padding-left-1'>
                <Card>
                    <Row className='valign-wrapper padding-top-1 padding-right-1'>
                        <Input col={ 1 } title={ i18n.startDate } value={ getDate(this.state.startDate) } onChange={ v => onChangeDate(v, v2 => this.setState({ startDate: v2 }), { max: this.state.endDate }) } />
                        <Input col={ 1 } title={ i18n.endDate } value={ getDate(this.state.endDate) } onChange={ v => onChangeDate(v, v2 => this.setState({ endDate: v2 }), { min: this.state.startDate }) } />
                        <Input col={ 4 } title={ i18n.search } value={ this.state.searchValue } onChange={ v => this.setState({ searchValue: v }) } />
                        <Checkbox col={ 1 } checked={ this.state.checkGreen } label={ <span className={ 'green darken-1 arrests-level-panel green-text' }>__</span> } onChange={ v => this.setState({ checkGreen: v }) } />
                        <Checkbox col={ 1 } checked={ this.state.checkYellow } label={ <span className={ 'yellow darken-1 arrests-level-panel yellow-text' }>__</span> } onChange={ v => this.setState({ checkYellow: v }) } />
                        <Checkbox col={ 1 } checked={ this.state.checkRed } label={ <span className={ 'red darken-1 arrests-level-panel red-text' }>__</span> } onChange={ v => this.setState({ checkRed: v }) } />
                        <Checkbox col={ 1 } checked={ this.state.checkGrey } label={ <span className={ 'grey darken-1 arrests-level-panel grey-text' }>__</span> } onChange={ v => this.setState({ checkGrey: v }) } />
                        <Button col={ 2 } title={ i18n.visualize } onClick={ this.calculateData } />
                    </Row>
                </Card>
                <Card
                    title={`${i18n.files} (${this.state.data.length} / ${this.props.nbJobFiles || 0} ${this.props.nbJobFiles > 0 ? i18n.elements : i18n.element})`}
                    actions={[
                        {
                            iconName: showHistory ? 'visibility_off' : 'visibility',
                            tooltip: showHistory ? i18n.hideAllHistory : i18n.viewAllHistory,
                            onClick: () => this.props.onChangeHistory(this.calculateData),
                        },
                        {
                            iconName: 'folder_zip',
                            tooltip: i18n.downloadFilesInZip,
                            onClick: this.getZip,
                        },
                        {
                            iconName: 'file_download',
                            tooltip: i18n.export,
                            onClick: this.onExport,
                        },
                        {
                            iconName: 'more_horiz',
                            color: `${cantLoadMore ? '#9e9e9e' : 'white'}`,
                            tooltip: i18n.displayMore,
                            onClick: cantLoadMore ? () => {} : () => this.props.setNbFiles(this.calculateData),
                        },
                    ]}
                >
                    <Table
                        id='jobFiles'
                        showTitle={false}
                        title={i18n.files}
                        data={this.state.data}
                        sortable
                        condensed
                        color
                        type={{ headers: ['fileLabel', 'execution', 'status', 'updateDate', 'toProcess'] }}
                        paging
                        nbPerPageLabel={nbPerPageLabel}
                        onClick={(file) => {
                            const path = file.filePath || `${this.props.jobPath}/${file.fileLabel.value}`
                            this.props.downloadFile(file.fileLabel.value, path)
                        }}
                    />
                </Card>
            </Row>
        )
    }
}

ImportFiles.propTypes = {
    jobFiles: arrayOf(DtoJobFile),
    nbJobFiles: PropTypes.number,
    setNbFiles: PropTypes.func,
    downloadFile: PropTypes.func,
    jobPath: PropTypes.string,
    zipExport: PropTypes.func,
    jobName: PropTypes.string,
    onChangeHistory: PropTypes.func,
    showHistory: PropTypes.bool,
    fileToProcess: PropTypes.func,
}

const mapDispatchToProps = {
    zipExport: ExportAction.exportZipJobFiles,
    fileToProcess: JobAction.fileToProcess,
}

export default connect(null, mapDispatchToProps)(ImportFiles)