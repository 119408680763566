import React, { useEffect } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { push } from '@lagunovsky/redux-react-router'
import i18n from 'simple-react-i18n'

import Row from '../../../components/react/Row'
import InstVisitInfos from './validation/InstVisitInfos'
import InstVisitConclusion from './validation/InstVisitConclusion'
import InstVisitActions from './validation/InstVisitActions'
import InstVisitModifications from './validation/InstVisitModifications'
import Card from '../../../components/card/Card'
import InstallationAction from '../../actions/InstallationAction'
import { componentHasHabilitations } from '../../../utils/HabilitationUtil'
import { H_CAMPAIGN_INSTALLATION_VISIT } from '../../../account/constants/AccessRulesConstants'
import { useParams } from 'react-router-dom'
import useTitle from 'utils/customHook/useTitle'
import CmsAction from 'events/actions/CmsAction'
import InstVisitDiagnostic from './validation/InstVisitDiagnostic'
import InstVisitDanger from './validation/InstVisitDanger'

const InstallationVisitSummary = () => {
    const dispatch = useDispatch()

    const { idInstallation, id } = useParams()

    useEffect(() => {
        if (!componentHasHabilitations(H_CAMPAIGN_INSTALLATION_VISIT)) { // A modifier quand react-router sera à jour
            dispatch(push('/unauthorized'))
            return
        }
        dispatch(InstallationAction.fetchInstallation(idInstallation))
        dispatch(InstallationAction.fetchVisit(idInstallation, id))
        $('.material-tooltip').remove()
    }, [])

    const {
        visit,
        campaign,
        installation,
        cmsSurveyId,
    } = useSelector(store => ({
        visit: store.InstallationReducer.visit,
        campaign: store.CampaignReducer.campaign,
        installation: store.InstallationReducer.installation,
        cmsSurveyId: store.EventsReducer.cmsSurveyId,
    }), shallowEqual)

    useEffect(() => {
        if (cmsSurveyId && installation.id) {
            dispatch(CmsAction.fetchCMSSurveyRestricted(cmsSurveyId, installation.id))
        }
    }, [cmsSurveyId, installation.id])

    useEffect(() => {
        if (visit.visitDate) {
            dispatch(CmsAction.fetchCMSSurveyId(visit.visitDate))
        }
    }, [visit.visitDate])

    useTitle(() => {
        return [
            {
                title: i18n.installation,
                href: '/installation',
            },
            {
                title: i18n.campaigns,
                href: '/installation/campaign',
            },
            {
                title: campaign.name || id,
                href: `/installation/campaign/${id}/dashboard`,
            },
            {
                title: `${installation.code ? `[${installation.code}]` : ''} - ${installation.name ? installation.name : ''}`,
                href: `/station/installation/${installation.id}`,
            },
        ]
    }, [installation, campaign])

    const params = {
        readMode: true,
        visit,
        summary: true,
    }

    return (
        <div className='padding-1 padding-left-2'>
            <Card>
                <Row noMargin={false}>
                    <Card title={i18n.visit}>
                        <Row className='padding-1'>
                            <InstVisitInfos {...params}/>
                        </Row>
                    </Card>
                    {
                        cmsSurveyId && (
                            <>
                                <Card title={i18n.diagnostic}>
                                    <Row className='padding-1'>
                                        <InstVisitDiagnostic {...params} />
                                    </Row>
                                </Card>
                                <Card title={i18n.dangers}>
                                    <Row className='padding-1'>
                                        <InstVisitDanger {...params} />
                                    </Row>
                                </Card>
                            </>
                        )
                    }
                    <Card title={i18n.modifications}>
                        <Row className='padding-1'>
                            <InstVisitModifications {...params} />
                        </Row>
                    </Card>
                    <Card title={i18n.actions}>
                        <Row className='padding-1'>
                            <InstVisitActions {...params}/>
                        </Row>
                    </Card>
                    <Card title={i18n.conclusion}>
                        <Row className='padding-1'>
                            <InstVisitConclusion {...params}/>
                        </Row>
                    </Card>
                </Row>
            </Card>
        </div>
    )
}

InstallationVisitSummary.propTypes = {
}

export default InstallationVisitSummary