import React, { Component } from 'react'
import { PANEL_CORRECT_CH_REPERE, PANEL_CORRECT_V6, PANEL_EXPORT_ADES } from '../../constants/SuperAdminConstants'
import AppStore from '../../../../../store/AppStore'
import HomeAction from '../../../../../home/actions/HomeAction'
import CorrectChRepereApp from './CorrectChRepereApp'
import CorrectV6App from './CorrectV6App'
import PropTypes from 'prop-types'
import DtoUserHabilitation from '../../../user/dto/DtoUserHabilitation'
import { connect } from 'react-redux'
import CorrectV6ExportApp from './CorrectV6ExportApp'

class CorrectV6ParentApp extends Component {
    constructor(props) {
        super(props)
        this.state = {
            view: PANEL_CORRECT_CH_REPERE,
        }
    }

    componentDidMount() {
        AppStore.dispatch(HomeAction.setTitle([{
            title: 'Correction migration V6',
            href: 'administration/correctV6',
        }]))
        $('ul.tabs').tabs()
    }

    changeState = (view) => {
        this.setState({
            view,
        })
    }

    getPanel = () => {
        switch (this.state.view) {
            default:
            case PANEL_CORRECT_CH_REPERE:
                return <CorrectChRepereApp />
            case PANEL_CORRECT_V6:
                return <CorrectV6App />
            case PANEL_EXPORT_ADES:
                return <CorrectV6ExportApp />
        }
    }

    render() {
        return (
            <div className='row no-margin'>
                <div className='col s12'>
                    <ul className='tabs tabs-fixed-width'>
                        <li className='tab'>
                            <a onClick={() => this.changeState(PANEL_CORRECT_CH_REPERE)}>Correction Ch de repère</a>
                        </li>
                        <li className='tab'>
                            <a onClick={() => this.changeState(PANEL_CORRECT_V6)}>Correction V6</a>
                        </li>
                        <li className='tab'>
                            <a onClick={() => this.changeState(PANEL_EXPORT_ADES)}>Mettre à jour ADES Journalisation</a>
                        </li>
                    </ul>
                </div>
                <div className='col s12'>{this.getPanel()}</div>
            </div>
        )
    }

    componentDidUpdate() {
        $('ul.tabs').tabs()
    }
}

CorrectV6ParentApp.propTypes = {
    userHabilitations: PropTypes.arrayOf(PropTypes.instanceOf(DtoUserHabilitation)),
}

const mapStateToProps = (store) => {
    return {
        userHabilitations: store.UserReducer.userHabilitations,
    }
}
export default connect(mapStateToProps)(CorrectV6ParentApp)
