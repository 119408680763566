import { Button, Dialog, DialogActions, DialogContent, Grid2 } from '@mui/material'
import { ENTRY_SETTINGS_FREQUENCY } from 'agriAdministration/constants/AgriConstants'
import ColorPicker from 'components/forms/ColorPicker'
import Input from 'components/forms/Input'
import NumberField from 'components/forms/NumberField'
import Select from 'components/forms/Select'
import Textarea from 'components/forms/Textarea'
import { DefaultDialogTitle } from 'components/styled/Dialog'
import { StyledFieldSet, StyledLegend } from 'components/StyledElements'
import { getEntryFrequencyList } from 'exploitations/utils/AgriUtils'
import { range } from 'lodash'
import PropTypes from 'prop-types'
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import i18n from 'simple-react-i18n'
import DtoWaterTurnsRestriction from 'territory/dto/DtoWaterTurnsRestriction'
import ToastrAction from 'toastr/actions/ToastrAction'
import { getDayList } from 'utils/DateUtil'
import { hasValue } from 'utils/NumberUtil'

const TerritoryWaterTurnsRestrictionsModal = ({
    openModal = false,
    isNew = false,
    restriction = {},
    onUpdate = () => { },
    setOpenModal = () => { },
}) => {
    const dispatch = useDispatch()

    const [level, setLevel] = useState()
    const [label, setLabel] = useState()
    const [description, setDescription] = useState()
    const [comment, setComment] = useState()
    const [color, setColor] = useState()
    const [entryFrequency, setEntryFrequency] = useState()
    const [entryFrequencyDay, setEntryFrequencyDay] = useState()

    useEffect(() => {
        setLevel(restriction.level)
        setLabel(restriction.label)
        setDescription(restriction.description)
        setComment(restriction.comment)
        setColor(restriction.color)
        setEntryFrequency(restriction.entryFrequency)
        setEntryFrequencyDay(restriction.entryFrequencyDay)
    }, [restriction])

    const onValidate = () => {
        if (hasValue(level)) {
            const newService = new DtoWaterTurnsRestriction({
                id: restriction.id,
                level,
                label,
                description,
                comment,
                color,
                entryFrequency,
                entryFrequencyDay,
            })
            onUpdate(newService)
        } else {
            dispatch(ToastrAction.warning(i18n.pleaseCompleteAllRequiredField))
        }
    }

    const entryDays = useMemo(() => {
        if (entryFrequency === ENTRY_SETTINGS_FREQUENCY.WEEKLY) {
            return getDayList()
        }
        return range(1, 32).map((d) => ({ value: d, label: d }))
    }, [entryFrequency])

    return (
        <Dialog open={openModal} maxWidth='lg'>
            <DefaultDialogTitle
                title={isNew ? i18n.newRestriction : i18n.editRestriction}
                onClose={() => setOpenModal(false)}
            />
            <DialogContent>
                <Grid2 container alignContent='flex-start' spacing={1}>
                    <Grid2 size={6}>
                        <Input
                            col={12}
                            title={i18n.label}
                            value={label}
                            onChange={setLabel}
                        />
                    </Grid2>
                    <Grid2 size={3}>
                        <NumberField
                            col={12}
                            title={i18n.level}
                            value={level}
                            onChange={setLevel}
                            obligatory
                        />
                    </Grid2>
                    <Grid2 size={3}>
                        <ColorPicker
                            label={i18n.color}
                            value={color}
                            onChange={setColor}
                        />
                    </Grid2>
                    <Grid2 size={6}>
                        <Textarea
                            col={12}
                            title={i18n.description}
                            value={description}
                            onChange={setDescription}
                        />
                    </Grid2>
                    <Grid2 size={6}>
                        <Textarea
                            col={12}
                            title={i18n.comment}
                            value={comment}
                            onChange={setComment}
                        />
                    </Grid2>
                    <Grid2 size={6}>
                        <StyledFieldSet>
                            <StyledLegend>{i18n.entry}</StyledLegend>
                            <Grid2 container alignContent='flex-start' spacing={2}>
                                <Grid2 size={6}>
                                    <Select
                                        col={12}
                                        label={i18n.frequency}
                                        value={entryFrequency}
                                        onChange={v => {
                                            setEntryFrequency(v)
                                            setEntryFrequencyDay()
                                        }}
                                        clearFunction
                                        integerValue
                                        options={getEntryFrequencyList()}
                                    />
                                </Grid2>
                                <Grid2 size={6}>
                                    <Select
                                        col={12}
                                        label={i18n.day}
                                        value={entryFrequencyDay}
                                        onChange={values => setEntryFrequencyDay(values)}
                                        clearFunction
                                        integerValue
                                        options={entryDays}
                                        noSort
                                    />
                                </Grid2>
                            </Grid2>
                        </StyledFieldSet>
                    </Grid2>
                </Grid2>
            </DialogContent>
            <DialogActions>
                <Button onClick={onValidate} variant='contained' color='primary'>
                    {i18n.validate}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

TerritoryWaterTurnsRestrictionsModal.propTypes = {
    openModal: PropTypes.bool,
    setOpenModal: PropTypes.func,
    isNew: PropTypes.bool,
    onUpdate: PropTypes.func,
    restriction: PropTypes.instanceOf(DtoWaterTurnsRestriction),
}

export default TerritoryWaterTurnsRestrictionsModal
