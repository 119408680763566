import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import ToastrAction from 'toastr/actions/ToastrAction'
import { hasValue } from 'utils/NumberUtil'
import Table from '../../../../components/datatable/Table'
import Checkbox from '../../../../components/forms/Checkbox'
import Input from '../../../../components/forms/Input'
import NumberField from '../../../../components/forms/NumberField'
import RadioButtons from '../../../../components/forms/RadioButtons'
import AdesStationDto from '../../../../import/dto/AdesStationDto'
import Job from '../../../../import/dto/DtoJob'
import CityCedexDto from '../../../../referencial/components/city/dto/CityCedexDto'
import { nbPerPageLabelTiny } from '../../../../referencial/constants/ReferencialConstants'

class InfoTerre extends Component {
    constructor(props) {
        super(props)
        this.state = {
            codeCity: '',
            codeBss: '',
        }
    }

    componentDidMount() {
        const parameters = this.getParameters()
        const value = parameters && parameters.selectStationsMode ? parameters.selectStationsMode : 'DEPT'
        const citys = parameters && parameters.cityCodes ? parameters.cityCodes :[]
        const stations = parameters && parameters.stationCodes ? parameters.stationCodes :[]
        this.onChangeParameters({ selectStationsMode: value, cityCodes: citys, stationCodes: stations })
    }

    getJsonFilters = filters => {
        return filters.length !== 0 ? JSON.parse(filters[0]) : {}
    }

    onDeleteBssCode = ({ bsscode }) => {
        if (this.props.isEditMode) {
            const parameters = this.getParameters()
            this.onChangeParameters({ stationCodes: parameters.stationCodes.filter(p => p !== bsscode) })
        }
    }

    onDeleteCityCode = ({ cityCode }) => {
        if (this.props.isEditMode) {
            const parameters = this.getParameters()
            this.onChangeParameters({ cityCodes: parameters.cityCodes.filter(p => p !== cityCode) })
        }
    }

    addCodeBss = () => {
        if (this.props.isEditMode) {
            const codeBss = this.state.codeBss
            const parameters = this.getParameters()
            if (!codeBss) {
                this.props.toastError(i18n.youMustEnterAtLeastOneBssCode)
            } else {
                this.onChangeParameters({ stationCodes: [ codeBss, ...parameters.stationCodes ] })
                this.state.codeBss = ''
            }
        }
    }

    addCity = () => {
        if (this.props.isEditMode) {
            const cityCode = this.state.codeCity
            const parameters = this.getParameters()
            if (!cityCode) {
                this.props.toastError(i18n.youMustEnterAtLeastOneCode)
            } else {
                this.onChangeParameters({ cityCodes: [ cityCode, ...parameters.cityCodes ] })
                this.state.codeCity = ''
            }
        }
    }

    onChangeParameters = (changes) => {
        const parameters = {
            ...this.props.job.parameters,
            parameters: [JSON.stringify({
                ...this.getParameters(),
                ...changes,
            })],
        }
        this.props.onChangeJob({ parameters })
    }

    getStations = () => {
        const parameters = this.getParameters()
        return parameters && parameters.stationCodes ? parameters.stationCodes.map(bsscode => new AdesStationDto({ bsscode })) : []
    }

    getCitys = () => {
        const parameters = this.getParameters()
        return parameters && parameters.cityCodes ? parameters.cityCodes.map(cityCode => new CityCedexDto({ cityCode })): []
    }

    getParameters = () => this.props.job.parameters.parameters.length ? JSON.parse(this.props.job.parameters.parameters[0]) : {}

    getFilter = key => hasValue(this.state.parameters[key]) ? this.state.parameters[key] : ''

    render() {
        const disabled = { disabled: !this.props.isEditMode }
        const selectStationsMode = [
            { code: 'DEPT', name: 'Choisir un département' },
            { code: 'STATION', name: 'Choisir une liste de station' },
            { code: 'CITY', name: 'Choisir une liste de commune' },
        ]
        const parameters = this.getParameters()
        const {
            codeBss,
            codeCity,
        } = this.state
        return (
            <div>
                <div className='row no-margin valign-wrapper'>
                    <div className='col s6 no-padding padding-bottom-1'>
                        <fieldset obligatory >
                            <legend obligatory >&nbsp;{i18n.selectStations}&nbsp;</legend>
                            <RadioButtons elements={selectStationsMode} selected={parameters.selectStationsMode || 'DEPT'} onChange={v => this.onChangeParameters({ selectStationsMode: v })} {...disabled} />
                        </fieldset>
                    </div>
                    <div className='col s6 no-padding padding-bottom-1'>
                        <Checkbox
                            checked={parameters.updateStation}
                            label={i18n.addOnlyNewsDataIntegration}
                            onChange={v => this.onChangeParameters({ updateStation: v })}
                            {...disabled}
                        />
                    </div>
                </div>
                <div className='col s12 no-padding padding-bottom-1'>
                    {
                        parameters.selectStationsMode === 'DEPT' ? (
                            <div className='col s12 no-padding padding-bottom-1'>
                                <NumberField
                                    col={6}
                                    title={i18n.departmentNumber}
                                    value={parameters.departmentCodes }
                                    onChange={ v => this.onChangeParameters({ departmentCodes: v }) }
                                />
                            </div>
                        ) : (
                            !parameters.selectStationsMode || parameters.selectStationsMode === 'STATION' ? (
                                <div className='input-field col s6 no-padding'>
                                    <div className='col s4'>
                                        <Input
                                            id='txtAddBssCode'
                                            type='number'
                                            title={i18n.stationCode}
                                            value={ codeBss }
                                            ref='txtAddBssCode' onChange={r => this.setState({ codeBss: r })}
                                            {...disabled}
                                        />
                                    </div>
                                    <a className='waves-effect waves-light btn col offset-s1 s4' onClick={this.addCodeBss}
                                        {...disabled}
                                    >
                                        {i18n.add}
                                    </a>
                                    <div className='row no-margin'>
                                        <div className='col s12 no-padding padding-bottom-1'>
                                            <div className='col s12'>
                                                <Table
                                                    type={ new AdesStationDto() }
                                                    data={ this.getStations() }
                                                    title={i18n.credentials}
                                                    nbPerPageLabel={nbPerPageLabelTiny}
                                                    onDelete={this.onDeleteBssCode}
                                                    deletable={this.props.isEditMode}
                                                    showNbElements
                                                    paging
                                                    exportButtonOnHeader
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <div className='input-field col s6 no-padding'>
                                    <div className='col s4'>
                                        <Input
                                            id='txtAddcity'
                                            type='text'
                                            value={ codeCity }
                                            title={i18n.addCity}
                                            ref='txtAddcity'
                                            ref='txtAddBssCode' onChange={r => this.setState({ codeCity: r })}
                                            {...disabled}
                                        />
                                    </div>
                                    <a className='waves-effect waves-light btn col offset-s1 s4' onClick={this.addCity}
                                        {...disabled}
                                    >
                                        {i18n.add}
                                    </a>

                                    <div className='row no-margin'>
                                        <div className='col s12 no-padding padding-bottom-1'>
                                            <div className='col s12'>
                                                <Table
                                                    type={ new CityCedexDto() }
                                                    data={ this.getCitys() }
                                                    title={i18n.credentials}
                                                    nbPerPageLabel={nbPerPageLabelTiny}
                                                    onDelete={this.onDeleteCityCode}
                                                    deletable={this.props.isEditMode}
                                                    showNbElements
                                                    paging
                                                    exportButtonOnHeader
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        )
                    }
                </div>
                <div className='row padding-botom '>
                &nbsp;
                </div>
            </div>
        )
    }
}

InfoTerre.propTypes = {
    job: PropTypes.instanceOf(Job).isRequired,
    isEditMode: PropTypes.bool,
    onChangeDataTypes: PropTypes.func,
    onChangeJob: PropTypes.func.isRequired,
    toastError: PropTypes.func,
}

const mapDispatchToProps = {
    toastError: ToastrAction.error,
}

const mapStateToProps = store => ({
    contributors: store.ContributorReducer.contributors,
})

export default connect(mapStateToProps, mapDispatchToProps)(InfoTerre)
