import User from 'account/dto/User'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import { authorizeContributors } from 'utils/HabilitationUtil'
import Input from '../../../../components/forms/Input'
import Select from '../../../../components/forms/Select'
import Textarea from '../../../../components/forms/Textarea'
import Row from '../../../../components/react/Row'
import ContributorDto from '../../../../referencial/components/contributor/dto/ContributorDto'
import { SANDRE } from '../../../../referencial/constants/ReferencialConstants'
import DtoSandreCode from '../../../../referencial/dto/DtoSandreCode'
import { getDate, getHour } from '../../../../utils/DateUtil'
import { onChangeDate, onChangeHour } from '../../../../utils/FormUtils'
import { arrayOf, getSandreList } from '../../../../utils/StoreUtils'
import DtoHydrogeologicalEntity from '../../../dto/DtoHydrogeologicalEntity'
import DtoHydrogeologicalEntityLink from '../../../dto/DtoHydrogeologicalEntityLink'

class HydrogeologicalEntityLModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            hydrologicalEntity: { ...props.hydrologicalEntity, idStation: props.id },
        }
    }

    componentDidUpdate(prevProps) {
        if (!prevProps.popupIsOpen && this.props.popupIsOpen) {
            $('#descriptionModal').on('click', this.onSave)
        }
    }
    onSave = () => {
        this.props.saveResult(new DtoHydrogeologicalEntityLink(this.state.hydrologicalEntity))
        $('.modal').modal('close')
    }

    onChangeElement = (changes) => this.setState({ hydrologicalEntity: { ...this.state.hydrologicalEntity, ...changes } })

    render() {
        const { hydrologicalEntity } = this.state
        const {
            contributors,
            sandreCodes,
            hydrogeologicalEntities,
            accountUser,
        } = this.props
        return (
            <div>
                <Row>
                    <Input col={ 6 } title={ i18n.startDate } value={ getDate(hydrologicalEntity.startDate) }
                        onChange={ v => onChangeDate(v, v2 => this.onChangeElement({ startDate: v2 }), { max: hydrologicalEntity.endDate }, hydrologicalEntity.startDate) }
                    />
                    <Input col={ 6 } title={ i18n.startHour } value={ getHour(hydrologicalEntity.startDate) }
                        onChange={ v => onChangeHour(v, v2 => this.onChangeElement({ startDate: v2 }), { max: hydrologicalEntity.endDate }, hydrologicalEntity.startDate) }
                    />
                </Row>
                <Row>
                    <Input col={ 6 } title={ i18n.endDate } value={ getDate(hydrologicalEntity.endDate) }
                        onChange={ v => onChangeDate(v, v2 => this.onChangeElement({ endDate: v2 }), { min: hydrologicalEntity.startDate }, hydrologicalEntity.endDate) }
                    />
                    <Input col={ 6 } title={ i18n.endHour } value={ getHour(hydrologicalEntity.endDate) }
                        onChange={ v => onChangeHour(v, v2 => this.onChangeElement({ endDate: v2 }), { min: hydrologicalEntity.startDate }, hydrologicalEntity.endDate) }
                    />
                </Row>
                <div className='row no-margin padding-top-1'>
                    <Select
                        col={ 6 }
                        value={ hydrologicalEntity.contributor }
                        label={ i18n.contributor }
                        onChange={ v => this.onChangeElement({ contributor: v }) }
                        keyValue='id'
                        options={authorizeContributors(contributors, accountUser, hydrologicalEntity.contributor) }
                        displayWithCode
                    />
                    <Select
                        col={ 6 }
                        value={ hydrologicalEntity.hydrologicalEntity }
                        label={ i18n.hydrogeologicalEntity }
                        onChange={ v => this.onChangeElement({ hydrologicalEntity: v }) }
                        keyValue='code'
                        options={hydrogeologicalEntities }
                        displayWithCode
                    />
                </div>
                <div className='row no-margin padding-top-1'>
                    <Select
                        col={ 6 }
                        options={getSandreList(sandreCodes, SANDRE.QUALITE_POINT_RESSOURCE) }
                        label={ i18n.qualityAssociation }
                        value={ hydrologicalEntity.qualityAssociation }
                        onChange={ v => this.onChangeElement({ qualityAssociation: v }) }
                    />
                </div>
                <div className='row no-margin padding-top-1'>
                    <Textarea
                        col={ 12 }
                        title={ i18n.comment }
                        value={ hydrologicalEntity.comment }
                        onChange={ v => this.onChangeElement({ comment: v }) }
                    />
                </div>
            </div>
        )
    }
}

HydrogeologicalEntityLModal.propTypes = {
    hydrologicalEntity: PropTypes.instanceOf(DtoHydrogeologicalEntityLink),
    id: PropTypes.number,
    popupIsOpen: PropTypes.bool,
    saveResult: PropTypes.func,
    hydrogeologicalEntities: arrayOf(DtoHydrogeologicalEntity),
    contributors: arrayOf(ContributorDto),
    sandreCodes: arrayOf(DtoSandreCode),
    accountUser: PropTypes.instanceOf(User),
}


const mapStateToProps = store => ({
    popupIsOpen: store.SieauReducer.popupIsOpen,
    contributors: store.ContributorReducer.contributors,
    hydrogeologicalEntities: store.StationReducer.hydrogeologicalEntities,
    sandreCodes: store.ReferencialReducer.sandreCodes,
    accountUser: store.AccountReducer.accountUser,
})

HydrogeologicalEntityLModal.defaultProps = {
    hydrologicalEntity: {},
}

export default connect(mapStateToProps)(HydrogeologicalEntityLModal)
