export default class WatermassStateDto {
    constructor(obj) {
        this.watermass = obj.watermass
        this.watermassType = obj.watermassType
        this.stateId = obj.stateId
        this.startDate = obj.startDate
        this.value = obj.value
        this.comment = obj.comment
        this.stateName = obj.stateName
        this.mnemonic = obj.mnemonic
        this.finality = obj.finality
        this.principal = obj.principal
        this.stateComment = obj.stateComment
        this.computedStateCode = obj.computedStateCode
        this.order = obj.order
        this.isClass = obj.isClass
    }
}