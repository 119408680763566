export default class DtoExploitationService {
    constructor(object) {
        this.idExploitation = object.idExploitation
        this.idService = object.idService
        this.startDate = object.startDate
        this.endDate = object.endDate
        this.occurency = object.occurency
        this.unity = object.unity
        this.updateDate = object.updateDate
        this.updateLogin = object.updateLogin
    }
}