import PcMonitoring from 'quality/components/pcMonitoring/PcMonitoring'
import React, { useEffect, useMemo } from 'react'
import useTitle from 'utils/customHook/useTitle'
import i18n from 'simple-react-i18n'
import { getStationTitle } from 'utils/StationUtils'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import ProductionUnitAction from 'productionUnit/actions/ProductionUnitAction'

const ProductionUnitPcMonitoringApp = () => {
    const dispatch = useDispatch()
    const { id } = useParams()

    const {
        productionUnit,
        prodUnitLinks,
    } = useSelector(store => ({
        productionUnit: store.ProductionUnitReducer.productionUnit,
        prodUnitLinks: store.ProductionUnitReducer.prodUnitLinks,
    }), shallowEqual)

    useTitle(() => [{
        title: i18n.productionUnit,
        href: 'productionUnit',
    }, {
        title: getStationTitle(productionUnit),
        href: `station/productionUnit/${id}`,
    }, {
        title: i18n.followUpPC,
        href: `station/productionUnit/${id}/pcMonitoring`,
    }], [])

    useEffect(() => {
        dispatch(ProductionUnitAction.fetchProductionUnitLinks(id))
    }, [dispatch, id])

    const ids = useMemo(() => prodUnitLinks.filter(ass => ass.siteType === 3).map(ass => ass.siteCode), [prodUnitLinks])

    return (
        <PcMonitoring
            ids={ids}
        />
    )
}

export default ProductionUnitPcMonitoringApp