export default class DtoProductionUnitLink {
    constructor(obj) {
        this.id = obj.id // Int,
        this.linkType = obj.linkType // Option[Int],
        this.siteCode = obj.siteCode // Option[Double],
        this.siteType = obj.siteType // Option[Int],
        this.startDate = obj.startDate // Option[DateTime],
        this.endDate = obj.endDate // Option[DateTime],
        this.updateDate = obj.updateDate // Option[DateTime],
        this.updateLogin = obj.updateLogin // Option[String],

        this.code = obj.code // Option[String] = None,
        this.name = obj.name // Option[Int] = None,

        this.nature = obj.nature // Option[String] = None,
        this.city = obj.city // Option[String] = None,

        this.ressourceType = obj.ressourceType // Option[String] = None,
        this.nbFollowPoints = obj.nbFollowPoints // Option[Int] = None,

        this.sector = obj.sector // Option[String] = None,
        this.nbCity = obj.nbCity // Option[Int] = None,
        this.nbFollow = obj.nbFollow // Option[Double] = None
    }
}
