import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import Table from '../../../components/datatable/Table'
import SieauAction from '../../../components/sieau/SieauAction'
import DtoHydrometricStation from '../../../hydrometry/dto/DtoHydrometricStation'
import DtoPiezometer from '../../../piezometry/dto/DtoPiezometer'
import PluviometerDto from '../../../pluviometry/dto/PluviometerDto'
import DtoContact from '../../../quality/components/operation/dto/DtoContact'
import DtoQualitometer from '../../../quality/dto/DtoQualitometer'
import CityAction from '../../../referencial/components/city/actions/CityAction'
import CityDto from '../../../referencial/components/city/dto/CityDto'
import ContactAction from '../../../referencial/components/contact/actions/ContactAction'
import { getExport } from '../../../utils/linkUtils'
import StationContactsModal from './modals/StationContactsModal'

class StationContactsPanel extends Component {
    constructor(props) {
        super(props)
        this.state = {
            open: false,
            stationContacts: [],
        }
    }

    componentDidMount() {
        if (!this.props.cities.length) {
            this.props.fetchCities()
        }
        if (!this.props.contacts.length) {
            this.props.fetchContacts()
        }
    }

    getData(stationContacts) {
        return stationContacts.map((value) => {
            const city = this.props.cities.find(c => c.code && value.cityCode && c.code === value.cityCode)
            return ({
                id: value.id,
                name: { value: value.name, color: 'white' },
                city: { value: city ? city.name : '', color: 'white' },
                cityCode: { value: value.cityCode, color: 'white' },
                phoneTel: { value: value.phoneTel, color: 'white' },
                mobile: { value: value.mobile, color: 'white' },
                email: { value: value.email, color: 'white' },
            })
        })
    }

    getModal(stationContacts) {
        const { station, onChange } = this.props
        return (
            <StationContactsModal
                id={station.id}
                station={station}
                stationContacts={stationContacts}
                saveResult={ r => this.setState({ stationContacts: r })}
                onChange={onChange}
                closePopup={() => this.setState({ open: false })}
            />
        )
    }

    render() {
        const { station, readMode, contacts } = this.props
        const stationContacts = contacts.filter(c => station.link_contacts.find(lc => c.code === lc.contactCode))
        const tableData = this.getData(stationContacts)
        const headers = ['name', 'city', 'cityCode', 'phoneTel', 'mobile', 'email']
        const exportAction = getExport(tableData, i18n.otherLocations, headers)
        const actions = !readMode ? [{
            iconName: 'note_add',
            tooltip: i18n.add,
            onClick: () => {
                this.setState({ open: true })
            },
        }, exportAction] : [exportAction]


        return (
            <div id='peopleToContact'>
                {this.state.open && this.getModal(stationContacts)}
                <Table
                    title = {i18n.peopleToContact}
                    condensed
                    data = {tableData}
                    type = {{ headers }}
                    sortable = { !!tableData.length }
                    actions = { actions }
                />
            </div>
        )
    }
}

StationContactsPanel.propTypes ={
    station: PropTypes.oneOfType([
        PropTypes.instanceOf(DtoQualitometer),
        PropTypes.instanceOf(DtoPiezometer),
        PropTypes.instanceOf(DtoHydrometricStation),
        PropTypes.instanceOf(PluviometerDto),
    ]),
    contacts: PropTypes.arrayOf(DtoContact),
    cities: PropTypes.arrayOf(PropTypes.instanceOf(CityDto)),
    fetchContacts: PropTypes.func,
    fetchCities: PropTypes.func,
    readMode: PropTypes.bool,
    setPopup: PropTypes.func,
    onChange: PropTypes.func,
}

const mapStateToProps = store => ({
    contacts: store.ContactReducer.contacts,
    cities: store.CityReducer.cities,
})

const mapDispatchToProps = {
    fetchContacts: ContactAction.fetchContacts,
    fetchCities: CityAction.fetchCities,
    setPopup: SieauAction.setPopup,
}

export default connect(mapStateToProps, mapDispatchToProps)(StationContactsPanel)