import React, { Component } from 'react'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import { connect } from 'react-redux'
import AppStore from '../../store/AppStore'
import ReferencialAction from '../action/ReferencialAction'

class DeleteModal extends Component {
    componentWillUnmount() {
        AppStore.dispatch(ReferencialAction.resetCheckDelete())
    }

    getSandreCodeDisabled() {
        if (this.props.checkDelete && this.props.checkDelete > 0) {
            return (
                <a className='waves-effect waves-teal btn-flat disabled modal-close' onClick={ this.props.onDelete } data-cy='confirm_delete_check_button' >{ i18n.confirm }</a>
            )
        }
        return (
            <a className='waves-effect waves-teal btn-flat modal-close' id='delete_confirm' onClick={ this.props.onDelete } data-cy='confirm_delete_check_button' >{ i18n.confirm }</a>
        )
    }

    getLoad = () => {
        if (this.props.checkDelete !== null) {
            return (
                <div>
                    <div className='row no-margin'>
                        <h5>{ i18n.numberOfUse + this.props.checkDelete }</h5>
                    </div>
                    <div className='modal-footer white'>
                        <a className='waves-effect waves-teal btn-flat modal-close'>{ i18n.close }</a>
                        { this.getSandreCodeDisabled() }
                    </div>
                </div>
            )
        } return (
            <div>
                <h5>Chargement ...</h5>
            </div>
        )
    }

    render() {
        return (
            <div>
                <div className='modal-header-assignment'>
                    <div className='row no-margin'>
                        <h4 className='left no-margin black-text'>{ i18n.deletingConfirmation }</h4>
                    </div>
                </div>
                { this.getLoad() }
            </div>
        )
    }
}

DeleteModal.propTypes = {
    onDelete: PropTypes.func.isRequired,
    checkDelete: PropTypes.number,
}

DeleteModal.defaultProps = {
    onDelete: () => {},
    checkDelete: null,
}

const mapStateToProps = store => {
    return {
        checkDelete: store.ReferencialReducer.checkDelete,
    }
}

export default connect(mapStateToProps)(DeleteModal)
