import React from 'react'
import PropTypes from 'prop-types'
import { orderBy } from 'lodash'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import Textarea from '../../../../components/forms/Textarea'
import DatePicker from '../../../../components/forms/DatePicker'
import moment from 'moment'
import DtoMaterielSituation from '../../../../materiel/dto/DtoMaterielSituation'
import DtoMaterielState from '../../../../materiel/dto/DtoMaterielState'
import Select from '../../../../components/forms/Select'
import {
    MATERIEL_STATE_ON_SITE_ID,
    MATERIEL_STATE_PROVISIONAL_ID,
    MATERIEL_STATE_TRANSFERT_ID,
} from '../../../../materiel/constants/MaterielStateConstants'
import Checkbox from '../../../../components/forms/Checkbox'

const SituationStationForm = ({
    situation = {},
    materialState = [],
    materiels = [],
    idKey = '',
    isLast = false,
    isEditMode = false,
    materielsOnSite = [],
    shouldReplace = false,
    newMaterielSituation = {},
    changeValue = () => { },
    onChangeNewMaterielSituation = () => { },
}) => {
    const getSelectSituationState = (() => {
        if (situation.situationEndDate) {
            const filterState = materialState.filter(o => o.id !== MATERIEL_STATE_ON_SITE_ID && o.id !== MATERIEL_STATE_TRANSFERT_ID && o.id !== MATERIEL_STATE_PROVISIONAL_ID)
            const datas = orderBy(filterState.map(o => ({
                id: o.code,
                name: o.label,
            })), 'id', 'asc')
            return (
                <Select
                    options={datas}
                    label={i18n.state}
                    col={3}
                    onChange={v => changeValue('statusCode', parseInt(v))}
                    value={situation.statusCode}
                    nullLabel='&nbsp;'
                />
            )
        }
        return null
    })()
    return (
        <div className='row'>
            <div className='col s12'>
                <div className='row'>
                    <form className='col s12'>
                        <div className='row no-margin'>
                            <Select
                                col={5}
                                value={situation[idKey]}
                                label={i18n.materiel}
                                options={materiels}
                                displayWithCode
                                keyValue={'id'}
                                disabled={situation.id || !isEditMode}
                                onChange={value => {
                                    if (!value) {
                                        if (situation.siteCode) {
                                            changeValue(idKey, '')
                                        }
                                    } else {
                                        changeValue(idKey, value)
                                    }
                                }}
                            />
                            <DatePicker
                                onChange={(id, v) => changeValue(id, +moment(v))}
                                id='situationDate'
                                title={i18n.creationDate}
                                value={situation.situationDate}
                                col={3}
                                disabled={situation.id || !isEditMode}
                            />
                            {
                                (situation.id || !isEditMode) && (
                                    <DatePicker
                                        onChange={(id, v) => changeValue(id, +moment(v))}
                                        id='situationEndDate'
                                        disabled={isLast || !isEditMode}
                                        title={i18n.endDate}
                                        value={situation.situationEndDate}
                                        col={3}
                                    />
                                )
                            }
                            {getSelectSituationState}
                        </div>
                        <div className='row no-margin padding-top-15-px'>
                            <Textarea
                                col={12}
                                title={i18n.comment}
                                value={situation.comment}
                                onChange={v => changeValue('comment', v)}
                                disabled={!isEditMode}
                            />
                        </div>
                        {
                            !!materielsOnSite.length && !situation.id && (
                                <div className='row no-margin padding-top-1'>
                                    <div className='col s12'>
                                        <Checkbox
                                            col={4}
                                            label={i18n.replaceMaterial}
                                            checked={shouldReplace}
                                            onChange={v => onChangeNewMaterielSituation({
                                                shouldReplace: v,
                                                newMaterielSituation: {},
                                            })}
                                            disabled={!isEditMode}
                                        />
                                        {
                                            shouldReplace && (
                                                <Select
                                                    options={materielsOnSite}
                                                    label={i18n.materialToReplace}
                                                    col={4}
                                                    onChange={v => onChangeNewMaterielSituation({
                                                        newMaterielSituation: { [idKey]: v },
                                                    })}
                                                    value={newMaterielSituation[idKey]}
                                                    nullLabel='&nbsp;'
                                                    disabled={!isEditMode}
                                                />
                                            )
                                        }
                                        {
                                            !!newMaterielSituation[idKey] && (
                                                <Select
                                                    options={materialState.filter(s => s.code !== 1 && s.code !== 4)}
                                                    label={i18n.newSituation}
                                                    col={4}
                                                    onChange={v => onChangeNewMaterielSituation({
                                                        newMaterielSituation: {
                                                            ...newMaterielSituation,
                                                            statusCode: v,
                                                        },
                                                    })}
                                                    value={newMaterielSituation.statusCode}
                                                    nullLabel='&nbsp;'
                                                    disabled={!isEditMode}
                                                />
                                            )
                                        }
                                    </div>
                                </div>
                            )
                        }
                    </form>
                </div>
            </div>
        </div>
    )
}

SituationStationForm.propTypes = {
    materialState: PropTypes.arrayOf(PropTypes.instanceOf(DtoMaterielState)),
    situation: PropTypes.instanceOf(DtoMaterielSituation),
    materiels: PropTypes.arrayOf(PropTypes.object),
    isLast: PropTypes.bool,
    changeValue: PropTypes.func,
    idKey: PropTypes.string,
    isEditMode: PropTypes.bool,
    materielsOnSite: PropTypes.array,
    shouldReplace: PropTypes.bool,
    newMaterielSituation: PropTypes.object,
    onChangeNewMaterielSituation: PropTypes.func,
}

const mapStateToProps = store => ({
    materialState: store.MaterielReducer.materielStates,
})

export default connect(mapStateToProps)(SituationStationForm)
