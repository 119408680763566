import PropTypes from 'prop-types'
import React from 'react'
import { sieauTooltip } from 'utils/FormUtils'

const ImportFile = ({
    onClick = () => { },
    format = '',
    tooltip = '',
}) => {
    return (
        <div className='action-wrapper' {...sieauTooltip(tooltip, 'import_file_action_navbar', 'bottom')}>
            <label>
                <input
                    type='file'
                    value=''
                    accept={format}
                    onChange={onClick}
                    style={{ display: 'None' }}
                />
                <i className={'material-icons clickable'} style={{ color: 'white' }}>file_upload</i>
            </label>
        </div>
    )
}

ImportFile.propTypes = {
    onClick: PropTypes.func,
    format: PropTypes.string,
    tooltip: PropTypes.string,
}

export default ImportFile