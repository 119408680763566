import React, { Component } from 'react'
import i18n from 'simple-react-i18n'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import TaxonDto from '../dto/TaxonDto'
import ProgressCard from '../../../../components/card/ProgressCard'
import CartographyPanel from '../../../../components/map/CartographyPanel'
import TaxonAction from '../actions/TaxonAction'
import QualitometerListDto from '../dto/QualitometerListDto'
import DtoQualitometerLight from '../../../../quality/dto/DtoQualitometerLight'

class TaxonCarto extends Component {
    state = {
        isLoading: true,
        progress: 0,
    }

    componentDidMount = () => {
        const { taxon, params } = this.props
        if (!taxon.code) {
            this.props.fetchTaxon(params.code)
        }
        const codeQualito = this.props.qualitometers.map(s => s.id)
        this.props.fetchQualitometersByTaxon(this.props.taxon.code, codeQualito, p => this.setState({ progress: p }))
            .then(() => this.setState({ isLoading: false }))
    }

    componentDidUpdate = prevProps => {
        const {
            taxon,
        } = this.props
        if (prevProps.taxon.code !== taxon.code) {
            this.setState({ isLoading: true })
            const codeQualito = this.props.qualitometers.map(s => s.id)
            this.props.fetchQualitometersByTaxon(this.props.taxon.code, codeQualito, p => this.setState({ progress: p }))
                .then(() => this.setState({ isLoading: false }))
        }
    }

    render = () => {
        const {
            isLoading,
            progress,
        } = this.state
        return (
            <div className='row no-margin'>
                {
                    !isLoading && (
                        <CartographyPanel
                            layers={['STATIONS_POINTS']}
                            stationsPoints={this.props.qualitometersByTaxon}
                            stationsPanelTitle={i18n.stations}
                            componentType={'quality'}
                            colorMode
                        />
                    )
                }
                {
                    isLoading && (
                        <div className='card no-margin'>
                            <div className='card-content'>
                                <ProgressCard
                                    progress={progress}
                                    withoutCard
                                />
                            </div>
                        </div>
                    )
                }
            </div>
        )
    }
}

TaxonCarto.propTypes = {
    params: PropTypes.shape({
        code: PropTypes.string,
    }),
    taxon: PropTypes.instanceOf(TaxonDto),
    qualitometers: PropTypes.arrayOf(PropTypes.instanceOf(DtoQualitometerLight)),
    qualitometersByTaxon: PropTypes.arrayOf(PropTypes.instanceOf(QualitometerListDto)),
    fetchQualitometersByTaxon: PropTypes.func,
    fetchTaxon: PropTypes.func,
}

const mapStateToProps = store => ({
    taxon: store.TaxonReducer.taxon,
    qualitometersByTaxon: store.TaxonReducer.qualitometers,
    qualitometers: store.QualityReducer.qualitometersLight,
})

const mapDispatchToProps = {
    fetchTaxon: TaxonAction.fetchTaxon,
    fetchQualitometersByTaxon: TaxonAction.fetchQualitometersByTaxon,
}

export default connect(mapStateToProps, mapDispatchToProps)(TaxonCarto)