import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import Select from '../../../../../components/forms/Select'
import Textarea from '../../../../../components/forms/Textarea'
import DatePicker from '../../../../../components/forms/DatePicker'
import Row from '../../../../../components/react/Row'
import DtoSTEPAssociatedIndustry from '../../dto/DtoSTEPAssociatedIndustry'
import { arrayOf } from '../../../../../utils/StoreUtils'
import DtoInstallationLight from '../../../../dto/installation/DtoInstallationLight'

class STEPAssociatedIndustryModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            industry: { ...props.industry, idStation: props.id },
        }
    }

    componentDidUpdate(prevProps) {
        if (!prevProps.popupIsOpen && this.props.popupIsOpen) {
            $('#descriptionModal').on('click', this.onSave)
        }
    }
    onSave = () => {
        this.props.saveResult(new DtoSTEPAssociatedIndustry(this.state.industry))
        $('.modal').modal('close')
    }

    onChangeElement = (changes) => this.setState({ industry: { ...this.state.industry, ...changes } })

    render() {
        const { industry } = this.state
        return (
            <div className='row no-margin padding-top-1'>
                <Row>
                    <Select
                        col={12}
                        label={i18n.industry}
                        value={industry.code}
                        options={this.props.installationsLight.filter(i => i.installationType === 1)}
                        keyValue='id'
                        onChange={v => this.onChangeElement({ code: parseInt(v) })}
                    />
                </Row>
                <Row>
                    <DatePicker col={6} value={industry.startDate} id='startDate' title={i18n.startDate}
                        onChange={(_, v) => this.onChangeElement({ startDate: v })}
                    />
                    <DatePicker col={6} value={industry.endDate} id='endDate' title={i18n.endDate}
                        onChange={(_, v) => this.onChangeElement({ endDate: v })}
                    />
                </Row>
                <Row className='padding-top-1'>
                    <Textarea col={12} title={i18n.comment} value={industry.comment} onChange={v => this.onChangeElement({ comment: v })} />
                </Row>
            </div>
        )
    }
}

STEPAssociatedIndustryModal.propTypes = {
    industry: PropTypes.instanceOf(DtoSTEPAssociatedIndustry),
    installationsLight: arrayOf(DtoInstallationLight),
    id: PropTypes.number,
    popupIsOpen: PropTypes.bool,
    saveResult: PropTypes.func,
}


const mapStateToProps = store => ({
    popupIsOpen: store.SieauReducer.popupIsOpen,
    installationsLight: store.InstallationReducer.installationsLight,
})

STEPAssociatedIndustryModal.defaultProps = {
    industry: {},
}

export default connect(mapStateToProps)(STEPAssociatedIndustryModal)
