import i18n from 'simple-react-i18n'
import ReferencialDto from '../../../dto/ReferencialDto'

export default class ParameterItem extends ReferencialDto {
    getTypes() {
        return [i18n.quantitative, i18n.qualitative]
    }

    getNature() {
        return [i18n.chimical, i18n.microbiological, i18n.physical, i18n.hydrobiological, i18n.environmental, i18n.synthesis]
    }

    constructor(object) {
        super(object)
        this.id = object.code
        this.name = object.name
        this.nameWithInternalCode = object.name + (object.internalCode ? ` [${object.internalCode}]` : '')
        this.type = ''
        this.status = object.status
        this.active = object.active
        this.nature = ''
        this.casCode = object.casCode || ''
        this.SISEeaucode = object.siseCode || ''
        this.ld = object.ld
        this.lq = object.lq
        this.headers = ['code', 'name', 'type', 'nature', 'casCode', 'SISEeaucode', 'status', 'ld', 'lq']

        if (object.active === '0') {
            this.color = 'grey lighten-2'
        }

        if (object.nature === '0' || object.nature === '1') {
            this.type = this.getTypes()[parseInt(object.nature)]
        }

        if (parseInt(object.typeParam) >= 0 && parseInt(object.typeParam) <= 5) {
            this.nature = this.getNature()[parseInt(object.typeParam)]
        }
    }
}