import ApplicationConf from '../../../conf/ApplicationConf'


const getUrls = (site) => {
    if (site.typeName) {
        const siteId = site.id
        switch (site.typeName) {
            case 'piezometry':
                return [
                    ApplicationConf.piezometer.events(siteId),
                    ApplicationConf.piezometer.campaigns(siteId),
                    ApplicationConf.piezometer.thresholds(siteId, 2),
                ]
            case 'pluviometry':
                return [
                    ApplicationConf.pluviometry.events(siteId),
                    ApplicationConf.pluviometry.campaigns(siteId),
                    ApplicationConf.pluviometry.threshold(siteId),
                ]
            case 'hydrometry':
                return [
                    ApplicationConf.hydrometricStation.events(siteId),
                    ApplicationConf.hydrometricStation.campaigns(siteId),
                    ApplicationConf.hydrometricStation.hydrometricThreshold(siteId),
                ]
            case 'installation':
                return [
                    ApplicationConf.installation.events(siteId),
                ]
            default:
                return undefined
        }
    }
    return undefined
}

export { getUrls }