import { push } from '@lagunovsky/redux-react-router'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import DtoInstallationVisit from '../../installation/dto/installation/DtoInstallationVisit'
import { arrayOf } from '../../utils/StoreUtils'
import Icon from '../icon/Icon'

class VisitLinks extends Component {
    componentDidMount() {
        $('.dropdown-button').dropdown()
    }
    componentDidUpdate() {
        $('.dropdown-button').dropdown()
    }

    render() {
        if (this.props.links && this.props.links.length > 0) {
            const links = this.props.links.map(link => (
                <li>
                    <div className='row no-margin valign-wrapper dropdownLink collection-item' onClick={ () => this.props.push(`/installation/campaign/${link.idCampaign}/visit/${link.idInstallation}/1`)}>
                        {
                            link.campaignName
                        }
                    </div>
                </li>
            ))
            return (
                <div className='action-wrapper'>
                    <ul id='dropdownLinks2' className='dropdown-content dropdownLinks'>
                        { links }
                    </ul>
                    <a className='dropdown-button anim-hover nav-actions' data-activates='dropdownLinks2'>
                        <Icon icon='play_circle_outline' tooltip='Visite(s) en cours' />
                    </a>
                </div>
            )
        }
        return null
    }
}

VisitLinks.propTypes = {
    links: arrayOf(DtoInstallationVisit),
    push: PropTypes.func,
}

const mapDispatchToProps = {
    push,
}

export default connect(null, mapDispatchToProps)(VisitLinks)
