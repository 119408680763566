export default class DtoEstablishmentDischarge {
    constructor(obj) {
        this.category = obj.categoryName // Option[String],
        this.city = obj.cityName // Option[String],
        this.name = obj.name // Option[String],
        this.identifier = obj.identifier // Option[String],
        this.siret = obj.siret // Option[String],
        this.cityCode = obj.cityCode // Option[String],
        this.activityCode = obj.activityCode // Option[String],
        this.activity = obj.activity // Option[String],
        this.dischargeAgreement = obj.dischargeAgreement // Option[Boolean],
        this.effective = obj.effective // Option[String],
        this.icpe = obj.icpe // Option[Boolean],
        this.x = obj.x // Option[Float],
        this.y = obj.y // Option[Float],
        this.idStation = obj.idStation // Option[String],
        this.step = obj.step // Option[String]
    }
}