import { Button, Grid } from '@mui/material'
import Checkbox from 'components/forms/Checkbox'
import Select from 'components/forms/Select'
import SimpleDatePicker from 'components/forms/SimpleDatePicker'
import InstallationAction from 'installation/actions/InstallationAction'
import React, { useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'


const DangersManagerForm = ({
}) => {
    const [installation, setInstallation] = useState(null)
    const [contributor, setContributor] = useState(null)
    const [sector, setSector] = useState(null)
    const [startDate, setStartDate] = useState(null)
    const [endDate, setEndDate] = useState(null)
    const [filterSolved, setFilterSolved] = useState(null)
    const {
        installations,
        contributors,
        sectors,
    } = useSelector(store => ({
        installations: store.InstallationReducer.installationsLight,
        contributors: store.ContributorReducer.contributors,
        sectors: store.SectorReducer.sectors,
    }), shallowEqual)

    const dispatch = useDispatch()

    const filterDangers = () => {
        dispatch(InstallationAction.fetchDangersWithType({
            startDate,
            endDate,
            idInstallation: installation,
            idContributor: contributor,
            idSector: sector,
            filterSolved,
        }))
    }

    return (
        <Grid container className='padding-1'>
            <Grid container item xs={12}>
                <Grid item xs={4}>
                    <Select
                        col={12}
                        options={installations}
                        label={i18n.installation}
                        nullLabel='&nbsp;'
                        value={installation}
                        keyValue='id'
                        onChange={v => setInstallation(v)}
                    />
                </Grid>
                <Grid item xs={4}>
                    <Select
                        col={12}
                        options={contributors}
                        label={i18n.contributor}
                        nullLabel='&nbsp;'
                        value={contributor}
                        onChange={v => setContributor(v)}
                    />
                </Grid>
                <Grid item xs={4}>
                    <Select
                        col={12}
                        options={sectors}
                        label={i18n.sector}
                        nullLabel='&nbsp;'
                        value={sector}
                        onChange={v => setSector(v)}
                    />
                </Grid>
            </Grid>
            <Grid container item xs={12} justifyContent='flex-start' alignItems='center'>
                <Grid item xs={2}>
                    <SimpleDatePicker
                        col={12}
                        id='startDate'
                        value={startDate}
                        label={i18n.beginDate}
                        onChange={v => setStartDate(v)}
                    />
                </Grid>
                <Grid item xs={2}>
                    <SimpleDatePicker
                        col={12}
                        value={endDate}
                        id='endDate'
                        label={i18n.endDate}
                        onChange={v => setEndDate(v)}
                    />
                </Grid>
                <Grid item xs={4}>
                    <Checkbox
                        col={12}
                        checked={filterSolved}
                        label={i18n.filterSolved}
                        onChange={v => setFilterSolved(v)}
                    />
                </Grid>
                <Grid container item xs={4} alignItems='center' justifyContent='flex-end' style={{ paddingRight: '10px' }}>
                    <Button variant='contained' color='primary' onClick={filterDangers}>{i18n.search}</Button>
                </Grid>
            </Grid>
        </Grid>
    )
}

DangersManagerForm.propTypes = {
}

export default DangersManagerForm
