/* eslint-disable camelcase */
import DtoExploitationCodification from '../../exploitations/dto/DtoExploitationCodification'
import DtoDeclaration from './DtoDeclaration'
import DtoSurveyParam from './DtoSurveyParam'

export default class DtoSurvey {
    constructor(obj) {
        this.idSurvey = obj.idSurvey // Long,
        this.year = obj.year // Option[Int],
        this.statusCode = obj.statusCode // Option[Int],
        this.startDate = obj.startDate // Option[DateTime],
        this.comment = obj.comment // Option[String],
        this.endDate = obj.endDate // Option[DateTime],
        this.updateDate = obj.updateDate // Option[DateTime],
        this.updateLogin = obj.updateLogin // Option[String],
        this.cmsId = obj.cmsId // Option[Int]
        this.adminEndDate = obj.adminEndDate // Option[DateTime]
        this.name = obj.name // Option[String],
        this.contributorId = obj.contributorId // Option[Long],
        this.ddtLimitDate = obj.ddtLimitDate // Option[DateTime],
        this.agencyLimitDate = obj.agencyLimitDate // Option[DateTime],
        this.surveyType = obj.surveyType // Option[Int],
        this.authorizationDate = obj.authorizationDate // Option[DateTime],
        this.exportNotifications = obj.exportNotifications // Option[Boolean],

        this.link_declarations = obj.link_declarations ? obj.link_declarations.map(d => new DtoDeclaration(d)) : [] // Option[Seq[Declaration]] = None
        this.link_codifications = obj.link_codifications ? obj.link_codifications.map(d => new DtoExploitationCodification(d)) : [] // Option[Seq[ExploitationCodification]] = None
        this.link_params = obj.link_params ? obj.link_params.map(p => new DtoSurveyParam(p)) : [] // Option[Seq[SurveyParam]] = None
    }
}