import AppStore from 'store/AppStore'
import { arrayOf } from '../../../../utils/StoreUtils'
import QualityIntegrationOverviewAction from '../actions/QualityIntegrationOverviewAction'
import {
    DELETE_ANALYSIS_CONFORMITY,
    RECEIVE_ALL_ANALYSE,
    RECEIVE_ALL_ANALYSE_ERROR,
    RECEIVE_ALL_OPERATION,
    RESET_INTEGRATION_OVERVIEW,
    UPDATE_ANALYSIS_CONFORMITY,
} from '../constants/IntegrationOverviewConstants'
import AnalyseErrorTypeDto from '../dto/AnalyseErrorTypeDto'
import AnalysisConformityDto from '../dto/AnalysisConformityDto'
import DtoOperation from '../dto/DtoOperation'
import { isUndefined } from 'lodash'

export function QualityIntegrationOverviewReducer(state = {}, action) {
    switch (action.type) {
        case RECEIVE_ALL_OPERATION:
            return {
                ...state,
                operations: action.operations.map(operation => new DtoOperation(operation)),
            }
        case RECEIVE_ALL_ANALYSE:
            return {
                ...state,
                analysis: action.analysis.map(analyse => new AnalysisConformityDto(analyse)),
            }
        case RECEIVE_ALL_ANALYSE_ERROR:
            return {
                ...state,
                analyseErrorTypes: action.analyseErrorTypes.map(err => new AnalyseErrorTypeDto(err)),
            }
        case UPDATE_ANALYSIS_CONFORMITY:
            const analysis = state.analysis.map(analyse => {
                const updatedAnalyse = action.payload.find(({ stationId, numAnalyse, numSample }) => analyse.stationId === stationId && analyse.numAnalyse === numAnalyse && analyse.numSample === numSample)
                if (!isUndefined(updatedAnalyse)) {
                    return updatedAnalyse
                }
                return analyse
            })
            return {
                ...state,
                analysis: analysis.map(analyse => new AnalysisConformityDto(analyse)),
            }
        case DELETE_ANALYSIS_CONFORMITY:
            return {
                ...state,
                analysis: state.analysis.filter(a => a.stationId !== action.payload.stationId || a.numAnalyse !== action.payload.numAnalyse || a.numSample !== action.payload.numSample),
            }
        case RESET_INTEGRATION_OVERVIEW:
            return {
                ...state,
                operations: [],
                analysis: [],
                analyseErrorTypes: [],
            }
        default:
            return state
    }
}

export const store = {
    operations: [],
    analysis: [],
    analyseErrorTypes: [],
}

export const SituationStoreInfos = () => {
    const appStore = AppStore.getState()
    return {
        situationOperations: {
            fetch: QualityIntegrationOverviewAction.fetchOperations,
            store: appStore.QualityIntegrationOverviewReducer.operations,
            propType: arrayOf(DtoOperation),
        },
    }
}