import PropTypes from 'prop-types'
import React from 'react'
import i18n from 'simple-react-i18n'
import CardList from './CardList'

const GlobalCardList = ({
    data = {},
    title = '',
    collapsible = true,
    dontOpenFirst = false,
    sortFunction = (a, b) => b - a,
    smallTitle = false,
    loadOnClick = false,
}) => {
    const sortedKeys = Object.keys(data).sort(sortFunction)
    const dataSort = sortedKeys.map(key => data[key])
    const nbCard = dataSort.reduce((res, sl) => sl.cards.length + res, 0)
    return (
        <div className='card-list'>
            {
                !!title && (
                    <div className='card'>
                        <div className='card-title'>
                            { title } ({ `${nbCard} ${i18n.element}` }{ (nbCard > 1) && 's' })
                        </div>
                    </div>
                )
            }
            <CardList smallTitle={ smallTitle } data={ dataSort } collapsible={ collapsible } dontOpenFirst={ dontOpenFirst } loadOnClick={ loadOnClick } />
        </div>
    )
}

GlobalCardList.propTypes = {
    data: PropTypes.arrayOf(PropTypes.shape({
        title: PropTypes.string.isRequired,
        cards: PropTypes.arrayOf(PropTypes.shape({
            color: PropTypes.string,
            onClick: PropTypes.func,
            content: PropTypes.element.isRequired,
        })).isRequired,
    })).isRequired,
    title: PropTypes.string,
    collapsible: PropTypes.bool,
    dontOpenFirst: PropTypes.bool,
    sortFunction: PropTypes.func,
    smallTitle: PropTypes.bool,
    loadOnClick: PropTypes.bool,
}

export default GlobalCardList
