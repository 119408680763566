/* eslint-disable camelcase */
import { getDate } from '../../utils/DateUtil'
import { formatPhone, formatSiret } from '../../utils/StringUtil'

export default class DtoExploitationExportFull {
    constructor(obj) {
        this.idExploitation = obj.idExploitation // Long
        this.status = obj.status // Option[String] = None,
        this.updateDate = obj.updateDate ? getDate(obj.updateDate) : '' // Option[DateTime] = None,
        this.activityEndDate = obj.activityEndDate ? getDate(obj.activityEndDate) : '' // Option[DateTime] = None,
        this.endDate = obj.activityEndDate // Option[DateTime] = None,
        this.codification = obj.codification // Option[String] = None,
        this.structureCode = obj.structureCode // Option[Long] = None,
        this.structureType = obj.structureType // Option[String] = None,
        this.civility = obj.civility // Option[String] = None,
        this.operatorCode = obj.operatorCode // Option[Long] = None,
        this.name = obj.name // Option[String] = None,
        this.birthdate = obj.birthdate ? getDate(obj.birthdate) : '' // Option[DateTime] = None,
        this.address = obj.address // Option[String] = None,
        this.additionnalAddress = obj.additionnalAddress // Option[String] = None,
        this.inseeCode = obj.inseeCode // Option[String] = None,
        this.postalCode = obj.postalCode // Option[String] = None,
        this.city = obj.city // Option[String] = None,
        this.phoneTel = obj.phoneTel ? formatPhone(obj.phoneTel) : '' // Option[String] = None,
        this.mobile = obj.mobile ? formatPhone(obj.mobile) : '' // Option[String] = None,
        this.fax = obj.fax ? formatPhone(obj.fax) : '' // Option[String] = None,
        this.email = obj.email // Option[String] = None,
        this.comments = obj.comments // Option[String] = None,
        this.siret = obj.siret ? formatSiret(obj.siret) : '' // Option[String] = None,
        this.pacage = obj.pacage // Option[String] = None,
        this.octagri_aura = obj.octagri_aura // Option[String] = None,
        this.octagri_local = obj.octagri_local // Option[String] = None,
        this.muse = obj.muse // Option[String] = None,
        this.agence = obj.agence // Option[String] = None
        this.nbPtsPrel = obj.nbPtsPrel // Option[Int] = None,
        this.lastDeclarationId = obj.lastDeclarationId // Option[Long] = None,
        this.lastDeclarationStatus = obj.lastDeclarationStatus // Option[Int] = None,
        this.lastDeclarationStep = obj.lastDeclarationStep // Option[Int] = None,
        this.lastDeclarationYear = obj.lastDeclarationYear // Option[Int] = None,
        this.legalRepSampler = obj.legalRepSampler // Option[String] = None,
        this.legalRepExploit = obj.legalRepExploit // Option[String] = None,
        this.contactsExploit = obj.contactsExploit // Option[String] = None,
        this.folderClosed = obj.folderClosed // Option[Boolean] = None,
        this.domestic = obj.domestic // Option[Boolean] = None,
        this.agri = obj.agri // Option[Boolean] = None,
        this.nonAgri = obj.nonAgri // Option[Boolean] = None
        this.procedureType = obj.procedureType // Option[Int] = None
        this.organism = obj.organism // Option[Long] = None

        this.link_points = obj.link_points || [] // Option[Seq[ExploitationLinkPoint]] = None,
        this.linkUsers = obj.linkUsers || [] // Option[Seq[ExploitationCodification]] = None,
    }
}