'use strict'
import { RECEIVE_ALL_USAGE, RECEIVE_USAGE, RESET_USAGE } from '../constants/UsageConstants'
import DtoUsageAgri from '../dto/DtoUsageAgri'

export const store = {
    usages: [],
    usage: {},
}

export function UsageReducer(state = {}, action) {
    switch (action.type) {
        case RECEIVE_ALL_USAGE:
            return {
                ...state,
                usages: action.usages.map(c => new DtoUsageAgri(c)),
            }
        case RECEIVE_USAGE:
            return {
                ...state,
                usage: new DtoUsageAgri(action.usage),
            }
        case RESET_USAGE:
            return {
                ...state,
                usage: {},
                usages: [],
            }
        default:
            return state
    }
}
