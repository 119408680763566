import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { v4 as uuidv4 } from 'uuid'
import { hasBooleanValue } from '../../utils/NumberUtil'
import { isEqualBy, sieauTooltip } from '../../utils/FormUtils'
import { searchAllCharacters } from '../../utils/StringUtil'
import DisplayedValue from './DisplayedValue'
import i18n from 'simple-react-i18n'

class Checkbox extends Component {
    constructor(props) {
        super(props)
        this.state = {
            selectId: props.id || searchAllCharacters(props.label + uuidv4()).replaceAll(' ', '_').replace('numero', '').replace('nom', 'libelle'),
            checked: false,
        }
    }

    componentWillMount() {
        this.setState({ checked: this.props.checked })
    }

    componentWillReceiveProps(nextProps) {
        this.setState({ checked: nextProps.checked })
    }

    check = (e) => {
        e.preventDefault()
        if (this.props.disabled) return

        const checked = !this.state.checked
        this.setState({ checked })
        if (this.props.keyObj && this.props.changeObj) {
            this.props.changeObj({ [this.props.keyObj]: e.shiftKey, LAST_FORM: 'CHECKBOX' })
        }
        if (this.props.onChange) {
            this.props.onChange(checked, e.shiftKey)
        }
    }

    getInput = () => {
        if (this.props.readMode) {
            return <DisplayedValue label={this.props.label} obligatory={false} value={this.props.checked ? i18n.yes : i18n.no} hideNull={this.props.hideNull} />
        }
        if (this.props.hideNull && !hasBooleanValue(this.props.checked)) {
            return null
        }
        const {
            tooltip,
            truncate,
        } = this.props
        const disabled = { disabled: this.props.disabled }
        return (
            <div className={this.props.componentClassName} {...(tooltip ? sieauTooltip(tooltip) : {})} data-cy={this.props['data-cy']} onClick={this.check}>
                <input
                    placeholder={ this.props.placeholder }
                    id={ this.state.selectId }
                    type='checkbox'
                    checked={ this.state.checked }
                    className={`${this.props.simpleLabelColor ? '' : 'sieau-input '}filled-in ${this.props.className}`}
                    onChange={() => {}}
                    {...disabled}
                />
                <label
                    htmlFor={ this.state.selectId }
                    style={truncate ? {
                        display: 'inherit',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                    } : {
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >{ this.props.label }</label>
            </div>
        )
    }


    render() {
        const { display, col, containerStyle } = this.props
        if (display) {
            return col ? (
                <div className={`col s${col}`} style={containerStyle}>
                    { this.getInput() }
                </div>
            ) : this.getInput()
        }
        return null
    }

    shouldComponentUpdate(nextProps) {
        if (this.props.freezeOpti && isEqualBy(this.props, nextProps, ['checked', 'col', 'disabled', 'readMode'])) {
            return false
        }
        return true
    }
}

Checkbox.propTypes = {
    className: PropTypes.string,
    componentClassName: PropTypes.string,
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
    label: PropTypes.string,
    checked: PropTypes.bool.isRequired,
    condensed: PropTypes.bool,
    disabled: PropTypes.bool,
    col: PropTypes.number,
    simpleLabelColor: PropTypes.bool,
    display: PropTypes.bool,
    hideNull: PropTypes.bool,
    keyObj: PropTypes.string,
    changeObj: PropTypes.func,
    freezeOpti: PropTypes.bool,
    tooltip: PropTypes.string,
    id: PropTypes.string,
    readMode: PropTypes.bool,
    containerStyle: PropTypes.shape({}),
    truncate: PropTypes.bool,
    'data-cy': PropTypes.string,
}

Checkbox.defaultProps = {
    className: '',
    componentClassName: '',
    label: '',
    placeholder: '',
    disabled: false,
    display: true,
    truncate: true,
}

export default Checkbox
