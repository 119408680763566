import {
    RECEIVE_GENERIC_MEASURES,
    RECEIVE_IAEAU_LEARNINGS,
    RECEIVE_IAEAU_MODELS,
    UPDATE_GENERIC_MEASURES,
} from '../constants/IAEauConstants'
import DtoGenericMeasure from '../dto/DtoGenericMeasure'
import DtoIAEauLearning from '../dto/DtoIAEauLearning'
import DtoIAEauModel from '../dto/DtoIAEauModel'

export const store = {
    iaeauLearnings: [],
    genericMeasures: [],
    iaeauModels: [],
}

export function IAEauReducer(state = {}, action) {
    switch (action.type) {
        case RECEIVE_IAEAU_LEARNINGS:
            return {
                ...state,
                iaeauLearnings: action.iaeauLearnings.map(d => new DtoIAEauLearning(d)),
            }
        case RECEIVE_GENERIC_MEASURES:
            return {
                ...state,
                genericMeasures: action.data.map(d => new DtoGenericMeasure(d)),
            }
        case UPDATE_GENERIC_MEASURES:
            return {
                ...state,
                genericMeasures: action.data.measures.map(d => new DtoGenericMeasure(d)),
            }
        case RECEIVE_IAEAU_MODELS:
            return {
                ...state,
                iaeauModels: action.data.map(d => new DtoIAEauModel(d)),
            }
        default:
            return state
    }
}