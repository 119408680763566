import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import Checkbox from '../../../../../components/forms/Checkbox'
import Textarea from '../../../../../components/forms/Textarea'
import Row from '../../../../../components/react/Row'
import DtoSandreCode from '../../../../../referencial/dto/DtoSandreCode'
import { arrayOf } from '../../../../../utils/StoreUtils'
import DtoStaffSafety from '../../../../dto/installation/DtoStaffSafety'

class StaffSafetyModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            staffSafety: { ...props.staffSafety, idStation: props.id },
        }
    }

    componentDidUpdate(prevProps) {
        if (!prevProps.popupIsOpen && this.props.popupIsOpen) {
            $('#descriptionModal').on('click', this.onSave)
        }
    }
    onSave = () => {
        this.props.saveResult(new DtoStaffSafety(this.state.staffSafety))
        $('.modal').modal('close')
    }

    onChangeElement = (changes) => this.setState({ staffSafety: { ...this.state.staffSafety, ...changes } })

    render() {
        const { staffSafety } = this.state
        return (
            <div>
                <Row>
                    <Checkbox
                        col={6}
                        checked={ staffSafety.isIntegrated }
                        label={i18n.integrated}
                        onChange={v => this.onChangeElement({ isIntegrated: v })}
                    />
                </Row>
                <Row className='padding-top-1'>
                    <Textarea
                        col={ 12 }
                        title={ i18n.comment }
                        value={ staffSafety.comment }
                        onChange={ v => this.onChangeElement({ comment: v }) }
                    />
                </Row>
            </div>
        )
    }
}

StaffSafetyModal.propTypes = {
    staffSafety: PropTypes.instanceOf(DtoStaffSafety),
    sandreCodes: arrayOf(DtoSandreCode),
    id: PropTypes.number,
    popupIsOpen: PropTypes.bool,
    saveResult: PropTypes.func,
}

const mapStateToProps = store => ({
    popupIsOpen: store.SieauReducer.popupIsOpen,
    sandreCodes: store.ReferencialReducer.sandreCodes,
})

export default connect(mapStateToProps)(StaffSafetyModal)
