import StepperDialog from 'components/modal/StepperDialog'
import React, { useMemo, useState } from 'react'
import i18n from 'simple-react-i18n'
import PropTypes from 'prop-types'
import { ButtonMUI } from 'components/styled/Buttons'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { isUndefined } from 'lodash'
import useUpdateEffect from 'utils/customHook/useUpdateEffect'
import moment from 'moment'
import OperationAction from '../../actions/OperationAction'
import WaitAction from 'wait/WaitAction'
import { AnalysisStep, ParameterStep } from './Step'

const STEP_PARAMETER = 0
const STEP_ANALYSIS = 1

const AnalysisStepper = ({
    isOpen = false,
    onClose = () => { },

    sampleId,
}) => {
    const dispatch = useDispatch()

    const {
        accountUser,
        qualitometer,
        operation,
    } = useSelector(store => ({
        accountUser: store.AccountReducer.accountUser,
        qualitometer: store.QualityReducer.qualitometer,
        operation: store.OperationReducer.operation,
    }), shallowEqual)

    const defaultOperation = useMemo(() => {
        const dateStart = +moment()
        return {
            dateStart,
            hourStart: dateStart,
            producer: accountUser.contributorCode,
            sampler: accountUser.contributorCode,
            laboratoryId: accountUser.contributorCode,
            support: '3', // eau
        }
    }, [accountUser.contributorCode])

    const [analysis, setAnalysis] = useState([])

    useUpdateEffect(() => {
        setAnalysis([])
    }, [isOpen, defaultOperation])

    return (
        <StepperDialog
            steps={[
                {
                    label: `${i18n.selection} / ${i18n.parameter}`,
                    constant: STEP_PARAMETER,
                    // nextAvailable: !!selectedParameters.length,
                },
                {
                    label: i18n.oneAnalysis,
                    constant: STEP_ANALYSIS,
                },
            ]}
            open={isOpen}
            title={i18n.newSample}
            closeDialog={onClose}
            renderSaveButton={step => (step === STEP_ANALYSIS) && (
                <ButtonMUI
                    onClick={() => {
                        if (analysis.length === 0) {
                            onClose()
                            return
                        }
                        const newAnalysis = analysis.map(a => ({
                            ...a,
                            id: 0,
                            qualitometer: operation.qualitometer,
                            sample: sampleId,
                            operation: operation.id,
                            analysisDate: operation.date,
                            difficulty: !isUndefined(a.difficulty) ? `${a.difficulty}` : undefined,
                            accreditation: !isUndefined(a.accreditation) ? `${a.accreditation}` : undefined,
                        }))
                        dispatch(WaitAction.waitStart())
                        dispatch(OperationAction.createAnalysisList(qualitometer.id, operation.id, newAnalysis)).then(() => {
                            dispatch(OperationAction.fetchAnalysis(operation.id, qualitometer.id)).finally(() => {
                                dispatch(WaitAction.waitStop())
                                onClose()
                            })
                        })
                    }}
                    variant='contained'
                >
                    {i18n.register}
                </ButtonMUI>
            )}
            contentStyle={{ padding: '0 12' }}
        >
            {
                step => (
                    <>
                        {step === STEP_PARAMETER && (
                            <ParameterStep
                                analysis={analysis}
                                setAnalysis={setAnalysis}
                            />
                        )}
                        {step === STEP_ANALYSIS && (
                            <AnalysisStep
                                analysis={analysis}
                                setAnalysis={setAnalysis}
                            />
                        )}
                    </>
                )
            }
        </StepperDialog>
    )
}

AnalysisStepper.propTypes = {
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,

    sampleId: PropTypes.number,
}

export default AnalysisStepper