import { intersectionWith } from 'lodash'
import PropTypes from 'prop-types'
import React, { useMemo } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import ParameterDto from '../../../../referencial/components/parameter/dto/ParameterDto'
import { searchAllCharacters } from '../../../../utils/StringUtil'
import SimpleSelectionTable, { ParameterFilterField } from 'components/datatable/SimpleSelectionTable'
import { getParametersNature, getParametersType } from 'referencial/util/ReferencialUtils'
import { getStatusInformation } from 'referencial/util/StatusUtils'
import useListIndexed from 'utils/customHook/useListIndexed'

const parameterFilterFunction = (list, { selection = '-1', listParam, searchValue }) => {
    const filterSelection = selection !== '-1' ? intersectionWith(list, listParam, (elem, id) => elem.id === id) : list
    const searchValueFormat = searchAllCharacters(searchValue)
    return searchValue ? filterSelection.filter(p => p.labelSearch.includes(searchValueFormat)) : filterSelection
}

const SelectParameter = ({
    selectedParameters = [],
    onChange = () => {},
}) => {
    const {
        parameters,
    } = useSelector(store => ({
        parameters: store.ParameterReducer.parameters,
    }), shallowEqual)

    const indexedParameters = useListIndexed(parameters, 'code')

    const formattedParameters = useMemo(() => {
        const types = getParametersType()
        const natures = getParametersNature()
        return parameters.map(param => ({
            id: param.code,
            name: param?.name.length > 40 ? param?.shortLabel : param?.name,
            type: types[param.type],
            nature: natures[param.nature],
            status: param.status ? getStatusInformation(param.status) : i18n.validated,
            labelSearch: searchAllCharacters([param.name, param.mnemonique, param.code].join(',')),
        }))
    }, [parameters])

    const onChangeSelected = (list = []) => {
        const newSelected = list.map(code => {
            const prev = selectedParameters.find(sp => code === sp.parameterCode)
            return prev ?? {
                parameterCode: code,
                unitCode: indexedParameters[code]?.unitReference1,
                fractionCode: '0',
                placeCode: '0',
                supportCode: '0',
            }
        })
        onChange({ selectedParameters: newSelected })
    }

    return (
        <SimpleSelectionTable
            onChange={onChangeSelected}

            listData={formattedParameters}
            selectedList={selectedParameters.map(sp => sp.parameterCode)}

            listHeaders={['name', 'type', 'nature', 'status']}
            listTitle={i18n.nonSelectedParameters}
            selectedTitle={i18n.selectedParameters}
            maxHeightTable='43vh'

            filterField={ParameterFilterField}
            filterFunction={parameterFilterFunction}
        />
    )
}

SelectParameter.propTypes = {
    parameters: PropTypes.arrayOf(PropTypes.instanceOf(ParameterDto)),
    selectedParameters: PropTypes.arrayOf(PropTypes.shape({
        parameterCode: PropTypes.string,
    })),
    onChange: PropTypes.func,
}

export default SelectParameter