import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import DtoSandreCode from 'referencial/dto/DtoSandreCode'
import i18n from 'simple-react-i18n'
import Checkbox from '../../../../components/forms/Checkbox'
import Select from '../../../../components/forms/Select'
import CmsAction from '../../../../events/actions/CmsAction'
import CMSCategoryDto from '../../../../events/dto/CMSCategoryDto'
import Job from '../../../dto/DtoJob'
import QualityAction from '../../../../quality/actions/QualityAction'
import DtoQualitometerLight from '../../../../quality/dto/DtoQualitometerLight'
import DtoThreshold from '../../../../quality/dto/DtoThreshold'
import AppStore from '../../../../store/AppStore'
import ReferentialAction from '../../../../referencial/action/ReferencialAction'


class OutlierQualityJobPanel extends Component {
    constructor(props) {
        super(props)
        this.state = {
            parameter: {
                cmsCategory: -1,
                cmsStatus: 2,
                conformitySandre: true,
                outliers: true,
                ...JSON.parse(props.job.parameters.parameters[0] || '{}'),
            },
        }
    }
    componentDidMount = () => {
        this.props.fetchThresholds()
        if (!this.props.sandreCodes.length) {
            AppStore.dispatch(ReferentialAction.fetchSandreCodes())
        }
    }

    onChange = (key, value) => {
        const parameter = { ...this.state.parameter, [key]: value }
        this.setState({ parameter })
        this.props.onChangeParameter([JSON.stringify(parameter)])
    }

    render = () => {
        const {
            // startDate,
            // endDate,
            // threshold,
            conformitySandre,
            outliers,
            cmsStatus,
            cmsCategory,
        } = this.state.parameter
        const {
            isEditMode,
            cmsCategories,
            sandreCodes,
        } = this.props
        const categories = cmsCategories.map(cms => ({ ...cms, icon: null }))
        const statusOptions = sandreCodes.filter((c) => c.field === 'CMS.STATUS')
        return (
            <div>
                <div className='row no-margin valign-wrapper padding-top-1'>
                    <h6>{i18n.outlierJobExplained}</h6>
                </div>
                <div className='row no-margin valign-wrapper padding-top-1'>
                    <Checkbox
                        col={3}
                        label={i18n.sandreConformity}
                        checked={conformitySandre}
                        onChange={(e) => this.onChange('conformitySandre', e)}
                        disabled={!isEditMode}
                    />
                    <Checkbox
                        col={3}
                        label={i18n.outliers}
                        checked={outliers}
                        onChange={(e) => this.onChange('outliers', e)}
                        disabled={!isEditMode}
                    />
                    <Select
                        col={3}
                        label={i18n.cmsCategory}
                        options={categories}
                        value={cmsCategory}
                        keyValue={'id'}
                        keyLabel='title'
                        onChange={v => this.onChange('cmsCategory', v)}
                        disabled={!isEditMode}
                    />
                    <Select
                        col={3}
                        options={statusOptions}
                        label={i18n.cmsStatus}
                        onChange={v => this.onChange('cmsStatus', parseInt(v))}
                        value={cmsStatus}
                        disabled={!isEditMode}
                    />
                </div>
            </div>
        )
    }
}

OutlierQualityJobPanel.propTypes = {
    job: PropTypes.instanceOf(Job).isRequired,
    isEditMode: PropTypes.bool,
    qualitometers: PropTypes.arrayOf(PropTypes.instanceOf(DtoQualitometerLight)),
    thresholds: PropTypes.arrayOf(PropTypes.instanceOf(DtoThreshold)),
    onChangeParameter: PropTypes.func,
    cmsCategories: PropTypes.arrayOf(PropTypes.instanceOf(CMSCategoryDto)),
    sandreCodes: PropTypes.arrayOf(PropTypes.instanceOf(DtoSandreCode)),

    fetchQualitometers: PropTypes.func,
    fetchThresholds: PropTypes.func,
    fetchCMSCategories: PropTypes.func,
}

const mapStateToProps = store => ({
    thresholds: store.QualityReducer.thresholds,
    cmsCategories: store.EventsReducer.cmsCategories,
    sandreCodes: store.ReferencialReducer.sandreCodes,
})

const mapDispatchToProps = {
    fetchThresholds: QualityAction.fetchThresholds,
    fetchCMSCategories: CmsAction.fetchCMSCategories,
}

export default connect(mapStateToProps, mapDispatchToProps)(OutlierQualityJobPanel)