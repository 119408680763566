import { hasBooleanValue } from '../../utils/NumberUtil'

export default class CMSCategoryDto {
    constructor(obj) {
        this.id = obj.id
        this.title = obj.title
        this.typeCategory = obj.typeCategory
        this.comment = obj.comment
        this.author = obj.author
        this.login = obj.login
        this.startDate = obj.startDate
        this.endDate = obj.endDate
        this.displayOnNotificationBar = hasBooleanValue(obj.displayOnNotificationBar) ? obj.displayOnNotificationBar : true
        this.icon = obj.icon || 'event_note'
        this.displayOnMenu = hasBooleanValue(obj.displayOnMenu) ? obj.displayOnMenu : true
        this.menu = obj.menu || 'Communication'
        this.managers = obj.managers
        this.headers = ['name', 'typeCategory', 'notifBar', 'menu', 'startDate', 'endDate', 'author', 'nullValue', 'nullValue1']
    }
}