export default class DtoOperation {
    constructor(obj) {
        this.qualitometer = obj.qualitometer // Double,
        this.id = obj.id // Double,
        this.date = obj.date // DateTime,
        this.length = obj.length // Option[Double] = None,
        this.mediumWidth = obj.mediumWidth // Option[Double] = None,
        this.interpretation = obj.interpretation // Option[String] = None,
        this.qualification = obj.qualification // Option[Double] = None,
        this.status = obj.status // Option[Double] = None,
        this.comment = obj.comment // Option[String] = None,
        this.methodOfPrincipalConservation = obj.methodOfPrincipalConservation // Option[Double] = None,
        this.methodOfSecondConservation = obj.methodOfSecondConservation // Option[Double] = None,
        this.wetArea = obj.wetArea // Option[Double] = None,
        this.particularSituation = obj.particularSituation // Option[Double] = None,
        this.point = obj.point // Option[Double],
        this.determiner = obj.determiner // Option[Double] = None,
        this.producer = obj.producer // Option[Double] = None,
        this.sampler = obj.sampler // Option[Double] = None,
        this.support = obj.support // Option[Double] = None,
        this.method = obj.method // Option[Double] = None,
        this.speed = obj.speed // Option[String] = None,
        this.shady = obj.shady // Option[Double] = None,
        this.continu = obj.continu // Option[String] = None,
        this.resultComment = obj.resultComment // Option[String] = None,
        this.microSampling = obj.microSampling // Option[String] = None,
        this.seasonality = obj.seasonality // Option[Double] = None,
        this.localization = obj.localization // Option[String] = None,
        this.producerContact = obj.producerContact // Option[Double] = None,
        this.samplerContact = obj.samplerContact // Option[Double] = None,
        this.determinerContact = obj.determinerContact // Option[Double] = None,
        this.jobExecutionId = obj.jobExecutionId // Option[Long] = None,
        this.analysisMethod = obj.analysisMethod // Option[Long] = None,
        this.accreditation = obj.accreditation // Option[String] = None,
        this.authorizationBanking = obj.authorizationBanking // Option[String] = None,
        this.campaign = obj.campaign // Option[Double] = None,
        this.updateDate = obj.updateDate // Option[DateTime] = None,
        this.updateLogin = obj.updateLogin // Option[String] = None
    }
}