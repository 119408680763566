import Checkbox from 'components/forms/Checkbox'
import { push } from '@lagunovsky/redux-react-router'
import DtoInstallationTableLine from 'installation/dto/installation/DtoInstallationTableLine'
import { chunk, flatten, groupBy, maxBy, minBy, omit, sortBy } from 'lodash'
import moment from 'moment'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import AppStore from 'store/AppStore'
import ToastrAction from 'toastr/actions/ToastrAction'
import { v4 as uuidv4 } from 'uuid'
import {
    H_STATION_HYDRO_EVENTS,
    H_STATION_INSTALLATION_EVENTS,
    H_STATION_PIEZO_EVENTS,
    H_STATION_PLUVIO_EVENTS,
    H_STATION_QUALITO_EVENTS,
    H_UNITS_EVENTS,
} from '../../../account/constants/AccessRulesConstants'
import CampaignDto from '../../../campaign/dto/CampaignDto'
import ActionComponent from '../../../components/ActionComponent'
import Card from '../../../components/card/Card'
import Input from '../../../components/forms/Input'
import Select from '../../../components/forms/Select'
import GlobalCardList from '../../../components/list/cardList/GlobalCardList'
import ExportFileModal from '../../../components/modal/ExportFileModal'
import SieauAction from '../../../components/sieau/SieauAction'
import EventPieChart from '../../../events/components/chart/EventPieChart'
import EventsTypeLegendPanel from '../../../events/components/chart/EventsTypeLegendPanel'
import EventVisitsBarChart from '../../../events/components/chart/EventVisitsBarChart'
import { EVENT_TYPES, EVENT_TYPES_CODES } from '../../../events/constants/EventsConstants'
import ExportAction from '../../../export/actions/ExportAction'
import HomeAction from '../../../home/actions/HomeAction'
import DtoHydrometricStation from '../../../hydrometry/dto/DtoHydrometricStation'
import DtoPiezometer from '../../../piezometry/dto/DtoPiezometer'
import DtoPiezometerLight from '../../../piezometry/dto/DtoPiezometerLight'
import PluviometerDto from '../../../pluviometry/dto/PluviometerDto'
import DtoProductionUnit from '../../../productionUnit/dto/DtoProductionUnit'
import DtoProductionUnitEvent from '../../../productionUnit/dto/DtoProductionUnitEvent'
import CityDto from '../../../referencial/components/city/dto/CityDto'
import { getStationArrowNav } from '../../../utils/ActionUtils'
import { getEventColor } from '../../../utils/ColorUtil'
import { getDate, getHour, getYear } from '../../../utils/DateUtil'
import { exportModelFile, formatData, getModelFileType } from '../../../utils/ExportDataUtil'
import { componentHasHabilitations } from '../../../utils/HabilitationUtil'
import { getSiteUrl } from '../../../utils/mapUtils/SiteTypes'
import { hasValue } from '../../../utils/NumberUtil'
import { getUser } from '../../../utils/SettingUtils'
import {
    execByType,
    execByTypes,
    findStationType,
    getLinks,
    getStation,
    getStations,
    getStationTitle,
    getStationTypeNameFromTypeCode,
} from '../../../utils/StationUtils'
import { arrayOf, getLabel, getMapStateToProps, getPropTypes } from '../../../utils/StoreUtils'
import { i18nize, searchAllCharacters } from '../../../utils/StringUtil'
import { STATION_NAME_ASSOCIATION } from '../../constants/StationConstants'
import DtoAssociatedStation from '../../dto/DtoAssociatedStation'
import DtoStation from '../../dto/DtoStation'
import EventCard from './EventCard'
import SimpleDatePicker from '../../../components/forms/SimpleDatePicker'

const $ = window.$

const propsToFetch = {
    stationEvents: false,
    installation: false,
    installationEvents: false,
    contacts: false,
}

class StationEventsApp extends ActionComponent {
    constructor(props) {
        super(props)
        this.state = {
            filter: {},
            tmpFilter: {},
            openExportModal: false,
            sortBy: 'date',
            isUnitOverview: this.props.stationTypes[0].includes('Unit') || this.props.stationTypes[0] === 'installation',
        }
    }

    setTitle = ({ code = '', id, name = '', designation = '' }) => {
        this.props.forceFetch('title', [{
            title: i18n[this.props.stationTypes[0]],
            href: this.props.stationTypes[0],
        }, {
            title: getStationTitle({ id, code, name, designation }),
            href: `station/${this.props.stationTypes[0]}/${id}`,
        }, {
            title: i18n.events,
            href: `station/${this.props.stationTypes[0]}/'${id}/description`,
        }])
    }

    setUnitEventPopup = () => {
        const station = getStation(this.props, this.props.stationTypes[0])
        const associations = this.props.associatedSites.map(s => ({ id: s.stationLinkedId, code: s.stationLinkedCode, name: s.stationlinkedName, typeName: s.typeName }))
        if (this.props.associatedSites.length) {
            const stations = [station].concat(associations).map(s => (
                <div className='row no-margin valign-wrapper padding-bottom-1 clickable' onClick={ () => {
                    AppStore.dispatch(push(`/station/${s.typeName}/${s.id}/event/0`))
                    $('.modal').modal('close')
                } }
                >
                    <img src={getSiteUrl(STATION_NAME_ASSOCIATION[s.typeName])} style={{ maxHeight: '25px' } } className='padding-right-1'/>
                    { `${s.code ? s.code : s.id} - ${s.name || ''}` }
                </div>
            ))
            const groups = chunk(stations, Math.ceil(stations.length / 2))
            const id = uuidv4()

            const actions = (
                <div>
                    <a className='waves-effect waves-teal btn-flat modal-close'>{ i18n.close }</a>
                </div>
            )
            const popup = {
                id,
                header: i18n.selectStation,
                actions,
                content: (
                    <div className='row no-margin'>
                        <div className='col s6'>
                            { groups[0] }
                        </div>
                        <div className='col s6'>
                            { groups.length > 0 ? groups[1] : null }
                        </div>
                    </div>
                ),
            }
            AppStore.dispatch(SieauAction.setPopup(popup))
        } else {
            AppStore.dispatch(push(`/station/${station.typeName}/${station.id}/event/0`))
        }
    }

    getCampaign = ({ campaignCode }) => {
        if (!campaignCode) {
            return undefined
        }
        const campaigns = execByType(this.props.stationTypes[0], {
            piezometry: () => this.props.piezometryCampaigns,
            pluviometry: () => this.props.pluviometryCampaigns,
            hydrometry: () => this.props.hydrometryCampaigns,
            quality: () => this.props.qualityCampaigns,
            installation: () => this.props.installationCampaigns,
            default: () => [],
        })
        return campaigns.find(({ id }) => id === campaignCode)
    }

    setComponentActions = () => {
        $('ul.tabs').tabs()
        const station = getStation(this.props, this.props.stationTypes[0])
        const stations = sortBy(flatten(execByTypes(this.props.stationTypes, {
            quality: () => this.props.qualitometers,
            piezometry: () => this.props.piezometers,
            hydrometry: () => this.props.hydrometricStations,
            pluviometry: () => this.props.pluviometers,
            productionUnit: () => this.props.productionUnits,
            distributionUnit: () => this.props.distributionUnits,
            installation: () => this.props.installationTable,
            default: () => [],
        })), o => o.name ? o.name.toUpperCase() : '}')
        const actions = {
            arrowNav: getStationArrowNav(this.props.stationTypes[0], stations, station.id, s => this.props.push(`/station/${this.props.stationTypes[0]}/${s.id}/event`)),
            links: getLinks(station, this.props),
            exportList: [{
                onClick: () => this.setState({ openExportModal: true }),
                label: i18n.excel,
            }],
        }
        if (!(getUser().consultant === '1')) {
            actions.new = () => this.state.isUnitOverview ? this.setUnitEventPopup() : this.props.push(`/station/${this.props.stationTypes[0]}/${station.id}/event/0`)
        }
        this.setActions(actions)
    }

    getExportData = () => {
        const events = this.getFilteredEvents(this.getAllEvents())
        return events.map(event => {
            return {
                date: { value: getDate(event.date), format: 'dd/MM/yyyy', cellType: 'date' },
                hour: { value: getHour(event.eventHour), cellType: 'right' },
                type: getLabel(i18nize(EVENT_TYPES), event.eventType),
                contact: getLabel(this.props.contacts, event.contactCode),
                comment: event.comment,
                staticLevel: { value: event.ns, format: '0.000', cellType: 'number' },
                centralLevel: { value: event.nc, format: '0.000', cellType: 'number' },
                chgtpiles: event.chgtpiles == 1 ? i18n.yes : '',
                razstation: event.razstation == 1 ? i18n.yes : '',
                graph: event.graph == 1 ? i18n.yes : '',
                bankExport: event.bankExport == 1 ? i18n.yes : '',
                volt: { value: event.volt, format: '0.000', cellType: 'number' },
                valeurcalee: { value: event.valeurcalee, format: '0.000', cellType: 'number' },
                sondeTemperature: { value: event.sondeTemperature, format: '0.000', cellType: 'number' },
                centralTemperature: { value: event.centralTemperature, format: '0.000', cellType: 'number' },
                headers: [
                    'date',
                    'hour',
                    'type',
                    'contact',
                    'comment',
                    'staticLevel',
                    'centralLevel',
                    'chgtpiles',
                    'razstation',
                    'graph',
                    'bankExport',
                    'volt',
                    'valeurcalee',
                    'sondeTemperature',
                    'centralTemperature',
                ],
            }
        })
    }

    getExportDataPiezo = () => {
        const events = this.getFilteredEvents(this.getAllEvents())
        return events.map(event => {
            const campaign = this.getCampaign(event)
            return {
                date: { value: getDate(event.date), format: 'dd/MM/yyyy', cellType: 'date' },
                hour: { value: getHour(event.eventHour), cellType: 'right' },
                type: getLabel(i18nize(EVENT_TYPES), event.eventType),
                contact: getLabel(this.props.contacts, event.contactCode),
                comment: event.comment,
                staticLevel: { value: event.ns, format: '0.000', cellType: 'number' },
                centralLevel: { value: event.nc, format: '0.000', cellType: 'number' },
                chgtpiles: event.chgtpiles == 1 ? i18n.yes : '',
                razstation: event.razstation == 1 ? i18n.yes : '',
                graph: event.graph == 1 ? i18n.yes : '',
                bankExport: event.bankExport == 1 ? i18n.yes : '',
                volt: { value: event.volt, format: '0.000', cellType: 'number' },
                valeurcalee: { value: event.valeurcalee, format: '0.000', cellType: 'number' },
                sondeTemperature: { value: event.sondeTemperature, format: '0.000', cellType: 'number' },
                centralTemperature: { value: event.centralTemperature, format: '0.000', cellType: 'number' },
                diagnostic: event.diagnostic ? i18n.yes : '',
                downloadData: event.downloadData ? i18n.yes : '',
                transmissionTest: event.transmissionTest ? i18n.yes : '',
                replaceDesiccant: event.replaceDesiccant ? i18n.yes : '',
                firmwareUpdate: event.firmwareUpdate ? i18n.yes : '',
                replaceORings: event.replaceORings ? i18n.yes : '',
                calibrationUpdate: event.calibrationUpdate ? i18n.yes : '',
                campaign: event.campaignCode ? campaign && campaign.name || i18n.unknown : '',
                headers: [
                    'date',
                    'hour',
                    'type',
                    'contact',
                    'comment',
                    'staticLevel',
                    'centralLevel',
                    'chgtpiles',
                    'razstation',
                    'graph',
                    'bankExport',
                    'volt',
                    'valeurcalee',
                    'sondeTemperature',
                    'centralTemperature',
                    'diagnostic',
                    'downloadData',
                    'transmissionTest',
                    'replaceDesiccant',
                    'firmwareUpdate',
                    'replaceORings',
                    'calibrationUpdate',
                    'campaign',
                ],
            }
        })
    }

    componentWillMount() {
        AppStore.dispatch(HomeAction.setBackPath(`${this.props.stationTypes[0]}`))
        const station = getStation(this.props, this.props.stationTypes[0])
        if (station.name) {
            this.setTitle(station)
        }
    }

    componentWillReceiveProps(nextProps) {
        const station = getStation(this.props, this.props.stationTypes[0])
        const nextStation = getStation(nextProps, this.props.stationTypes[0])
        if (nextStation.name && !station.name) {
            this.setTitle(nextStation)
        }
    }

    getEventComponent(event) {
        return (
            <EventCard
                event={event}
                small={false}
                link={ `/station/${event.stationType || this.props.stationTypes[0]}/${event.stationId || this.props.params.id}/event/` }
                showStationInfo={ this.state.isUnitOverview }
                stationType={this.props.stationTypes[0]}
            />
        )
    }

    getHash = (event) => {
        return searchAllCharacters(`${['problem', 'comment', 'solution'].map(key => event[key]).join('  ')}   ${getDate(event.date)}`)
    }

    getFilteredEvents = (allEvents) => {
        const { filter: { searchValue, startDate, endDate, showTechnicalEvents, type } } = this.state
        const filter1 = hasValue(searchValue) ? allEvents.filter(e => this.getHash(e).includes(searchAllCharacters(searchValue))) : allEvents
        const filter2 = hasValue(startDate) ? filter1.filter(e => e.date > startDate) : filter1
        const filter3 = hasValue(endDate) ? filter2.filter(e => e.date < endDate) : filter2
        const filter4 = !showTechnicalEvents ? filter3.filter(event => event.eventType !== EVENT_TYPES_CODES.TECHNIQUE) : filter3
        return hasValue(type) && type != -1 ? filter4.filter(e => e.eventType === type) : filter4
    }

    getDateFilteredEvents = (allEvents) => {
        if (!this.state.filter.startDate && !this.state.filter.endDate) {
            const maxEvent = allEvents.length ? maxBy(allEvents, 'date') : null
            const maxYear = maxEvent && maxEvent.date ? moment(maxEvent.date).year() : null
            if (maxYear) {
                return { title: maxYear, events: allEvents.filter(e => e && e.date && moment(e.date).year() === maxYear) }
            }
            return { title: '', events: [] }
        }
        const { filter: { startDate, endDate } } = this.state
        const filter1 = hasValue(startDate) ? allEvents.filter(e => e.date > startDate) : allEvents
        const events = hasValue(endDate) ? filter1.filter(e => e.date < endDate) : filter1
        if (!events.length) {
            return { title: '', events: [] }
        }
        const minYear = getYear(minBy(events, 'date').date)
        const maxYear = getYear(maxBy(events, 'date').date)
        return { title: minYear === maxYear ? minYear : `${minYear} - ${maxYear}`, events }
    }

    getGroupMethod = () => {
        switch (this.state.sortBy) {
            case 'author':
                return e => hasValue(e.contactCode) ? getLabel(this.props.contacts, e.contactCode) : e.updateLogin || i18n.unknownAuthor
            case 'eventType':
                return e => getLabel(i18nize(EVENT_TYPES), e.eventType) || i18n.unknownType
            default:
                return e => e.startDate || e.date ? moment(e.startDate || e.date).year() : i18n.thisYear
        }
    }

    getCardList = (allEventsByStations) => {
        const { unit, ...eventsByStations } = allEventsByStations
        const events = unit ? unit.map(u => ({ content: this.getEventComponent(u), color: getEventColor(u.eventType) })) : []
        const list = Object.keys(eventsByStations).map(key => {
            const stationTypeCode = findStationType(eventsByStations[key][0].stationType).code
            const station = getStations(this.props, stationTypeCode).find(s => key == s.code) || {}
            const icon = getSiteUrl(STATION_NAME_ASSOCIATION[getStationTypeNameFromTypeCode(stationTypeCode)])
            const stationCode = station.code ? `[${station.code}]` : ''
            return [ { content:
                    <div className='row no-margin valign-wrapper' style={{ paddingTop: '5px', paddingBottom: '5px' }}>
                        <div className='iconeTypeResearchWrapper margin-left-25'>
                            <img src={ icon } className='responsive-img iconeTypeResearch'/>
                        </div>
                        <div className='no-padding' style={{ marginLeft: '15px' }}>
                            { `${stationCode} ${getLabel(this.props.cities, station.townCode)} - ${station.name} (${eventsByStations[key].length} ${i18n[`event${eventsByStations[key].length >= 2 ? 's' : ''}`]})` }
                        </div>
                        <hr/>
                    </div>, color: getEventColor(...eventsByStations[key].map(e => e.eventType)),
            },
            ...eventsByStations[key].map(e => ({ content: this.getEventComponent(e), color: getEventColor(e.eventType) })),
            ]
        })
        return [ ...events, ...flatten(list)]
    }

    getData(events) {
        const eventsDesc = events.sort((a, b) => b.date - a.date)
        const groups = groupBy(eventsDesc, this.getGroupMethod())
        if (this.props.stationTypes[0].includes('Unit')) {
            const groupByStations = Object.keys(groups).reduce((acc, key) => ({ ...acc, [key]: groupBy(groups[key], e => e.stationCode ? e.stationCode : 'unit') }), {})
            return Object.keys(groupByStations).map(key => ({
                title: key,
                cards: this.getCardList(groupByStations[key]),
            }))
        }
        return Object.keys(groups).map(key => ({
            title: key,
            cards: groups[key].map(e => ({ content: this.getEventComponent(e), color: getEventColor(e.eventType) })),
        }))
    }

    onChangeSortBy = (sortById) => {
        this.setState({ sortBy: sortById })
    }

    getActive = (panel) => {
        return panel === this.state.sortBy ? 'active' : ''
    }

    getAllEvents = () => {
        return execByType(this.props.stationTypes[0], {
            installation: () => this.props.installationEvents,
            productionUnit: () => this.props.productionUnitEvents,
            default: () => this.props.stationEvents,
        })
    }

    onChangeFilter = (name, value) => {
        this.setState({ tmpFilter: { ...this.state.tmpFilter, [name]: value } })
    }

    onValidate = () => {
        this.setState({ filter: { ...this.state.tmpFilter } })
    }
    exportData = (events, type, title) => {
        if (!events.length) {
            return AppStore.dispatch(ToastrAction.error(i18n.noDataToExport))
        }
        const data = this.props.stationTypes[0] === 'piezometry' ? this.getExportDataPiezo(events) : this.getExportData(events)
        const dataFormatted = formatData(data)
        return this.props.standardExport(dataFormatted, type, title)
    }
    getExportModal = (events) => {
        const { openExportModal } = this.state
        const { typeEnvironmentModels: models } = this.props
        const station = getStation(this.props, this.props.stationTypes[0])
        const tableExport = [{
            name: i18n.resultsTable,
            formats: [{
                type: i18n.excelFile,
                callback: () => this.exportData(events, 'xlsx', `${station.code} - ${i18n.events}`),
            }, {
                type: i18n.csvFile,
                callback: () => this.exportData(events, 'csv', `${station.code} - ${i18n.events}`),
            }],
        }]
        const exportModel = models.map((model) => {
            const fileNameSplit = model.split('.')
            const name = fileNameSplit.slice(0, -1).join('')
            const ext = fileNameSplit[fileNameSplit.length - 1]
            return {
                name,
                formats: [{
                    type: getModelFileType(ext),
                    callback: () => exportModelFile({
                        stationId: station.id.toString(),
                        stationCode: station.code,
                        stationType: station.typeName,
                        environmentModels: models,
                        filenameModelExport: model,
                    }),
                }],
            }
        })
        return openExportModal && (
            <ExportFileModal
                open={openExportModal}
                onClose={() => this.setState({ openExportModal: false })}
                data={[ ...tableExport, ...exportModel ]}
            />
        )
    }
    render() {
        const events = this.getAllEvents()
        const filteredEvents = this.getFilteredEvents(events)
        const data = this.getData(filteredEvents)
        return (
            <Card maxWidth={ 1400 } className='padding-top-1 transparent no-box-shadow' contentClassName={ 'transparent' } >
                <div className='row'>
                    <div className='col s9'>
                        <div className='row no-margin'>
                            <Card className='margin-top-1'>
                                <div className='row no-margin'>
                                    <div className='col s12' >
                                        <h5 className='right'>{`${i18n.previsionalVisitNumber}: ${this.props.piezometer.previsionalVisitNumber || 0}` }</h5>
                                    </div>
                                </div>
                                <EventVisitsBarChart
                                    events={ events }
                                    previsionalVisitNumber={this.props.piezometer.previsionalVisitNumber || 0}
                                />
                            </Card>
                        </div>
                        <div className='row no-margin'>
                            <Card className='margin-top-1'>
                                <div className='col s12 row no-margin padding-top-10-px'>
                                    <Input
                                        col={ 3 }
                                        title={ i18n.search }
                                        value={ this.state.tmpFilter.searchValue }
                                        onChange={ v => this.onChangeFilter('searchValue', v) }
                                    />
                                    <SimpleDatePicker
                                        col={ 3 }
                                        label={ i18n.startDate }
                                        value={ this.state.tmpFilter.startDate }
                                        onChange={ (v) => this.onChangeFilter('startDate', v) }
                                    />
                                    <SimpleDatePicker
                                        col={ 3 }
                                        label={ i18n.endDate }
                                        value={ this.state.tmpFilter.endDate }
                                        onChange={ (v) => this.onChangeFilter('endDate', v) }
                                    />
                                    <Select
                                        options={ i18nize(EVENT_TYPES).map(e => omit(e, ['icon'])) }
                                        col={ 3 }
                                        label={ i18n.type }
                                        value={ this.state.tmpFilter.type }
                                        onChange={ v => this.onChangeFilter('type', v) }
                                        nullLabel='&nbsp;'
                                    />
                                </div>
                                <div className='row no-margin padding-left-1'>
                                    <Checkbox
                                        col={ 6 }
                                        checked={ this.state.tmpFilter.showTechnicalEvents }
                                        label={ i18n.showTechnicalEvents }
                                        onChange={ (e) => this.onChangeFilter('showTechnicalEvents', e) }
                                    />
                                </div>
                                <div className='col s12 padding-bottom-1'>
                                    <a className='col s3 btn right' onClick={ this.onValidate }>
                                        { i18n.search }
                                    </a>
                                </div>
                                <div className='row'>
                                    <div className='col s4 offset-s8 no-padding'>
                                        <ul className='tabs'>
                                            <li className='tab tooltipped col s4' id='date' onClick={ () => this.onChangeSortBy('date') } data-tooltip={ i18n.perYear }>
                                                <a className={ this.getActive('date') }><i className='material-icons inherited-line-height'>insert_invitation</i></a>
                                            </li>
                                            <li className='tab tooltipped col s4' id='author' onClick={ () => this.onChangeSortBy('author') } data-tooltip={ i18n.byAuthor }>
                                                <a className={ this.getActive('author') }><i className='material-icons inherited-line-height'>person</i></a>
                                            </li>
                                            <li className='tab tooltipped col s4' id='eventType' onClick={ () => this.onChangeSortBy('eventType') } data-tooltip={ i18n.byEventType }>
                                                <a className={ this.getActive('eventType') }><i className='material-icons inherited-line-height'>business_center</i></a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </Card>
                        </div>
                        <Card
                            className='margin-top-1 transparent no-box-shadow'
                            contentClassName={'transparent'}
                            title={ `${i18n.events} (${filteredEvents.length} ${i18n.elements})` }
                        >
                            <GlobalCardList
                                data={ data }
                                collapsible
                            />
                        </Card>
                    </div>
                    <div className='col s3'>
                        <Card className='margin-top-1'>
                            <EventsTypeLegendPanel />
                            <EventPieChart
                                events={ events }
                                title={ i18n.historic }
                            />
                            <EventPieChart
                                {...this.getDateFilteredEvents(events) }
                            />
                            {/* <EventTypesBarChart events={ events } /> */}
                        </Card>
                    </div>
                    { this.state.openExportModal && this.getExportModal(filteredEvents)}
                </div>
            </Card>
        )
    }

    componentDidMount() {
        const isAuthorized = execByType(this.props.stationTypes[0], {
            quality: () => componentHasHabilitations(H_STATION_QUALITO_EVENTS),
            piezometry: () => componentHasHabilitations(H_STATION_PIEZO_EVENTS),
            pluviometry: () => componentHasHabilitations(H_STATION_PLUVIO_EVENTS),
            hydrometry: () => componentHasHabilitations(H_STATION_HYDRO_EVENTS),
            installation: () => componentHasHabilitations(H_STATION_INSTALLATION_EVENTS),
            units: () => componentHasHabilitations(H_UNITS_EVENTS),
        })
        if (!isAuthorized) { // A modifier quand react-router sera à jour
            this.props.push('/unauthorized')
            return
        }
        this.setComponentActions()
        this.setTitle(getStation(this.props, this.props.stationTypes[0]))
    }

    componentDidUpdate() {
        this.setComponentActions()
        this.setTitle(getStation(this.props, this.props.stationTypes[0]))
    }
}

StationEventsApp.propTypes = getPropTypes(propsToFetch, {
    params: PropTypes.shape({
        id: PropTypes.string.isRequired,
    }).isRequired,
    stationTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
    productionUnit: PropTypes.instanceOf(DtoProductionUnit),
    distributionUnit: PropTypes.instanceOf(DtoProductionUnit),
    qualitometer: PropTypes.instanceOf(DtoStation),
    piezometer: PropTypes.instanceOf(DtoPiezometer),
    pluviometers: PropTypes.arrayOf(PropTypes.instanceOf(PluviometerDto)),
    pluviometer: PropTypes.instanceOf(PluviometerDto),
    hydrometricStation: PropTypes.instanceOf(DtoHydrometricStation),
    getLink: PropTypes.func,
    push: PropTypes.func,
    productionUnitEvents: PropTypes.arrayOf(PropTypes.instanceOf(DtoProductionUnitEvent)),
    associatedSites: PropTypes.arrayOf(PropTypes.instanceOf(DtoAssociatedStation)),
    cities: PropTypes.arrayOf(PropTypes.instanceOf(CityDto)),
    piezometers: PropTypes.arrayOf(PropTypes.instanceOf(DtoPiezometerLight)),
    piezometryCampaigns: PropTypes.arrayOf(PropTypes.instanceOf(CampaignDto)),
    pluviometryCampaigns: PropTypes.arrayOf(PropTypes.instanceOf(CampaignDto)),
    hydrometryCampaigns: PropTypes.arrayOf(PropTypes.instanceOf(CampaignDto)),
    qualityCampaigns: PropTypes.arrayOf(PropTypes.instanceOf(CampaignDto)),
    installationCampaigns: PropTypes.arrayOf(PropTypes.instanceOf(CampaignDto)),
    standardExport: PropTypes.func,
    typeEnvironmentModels: PropTypes.arrayOf(PropTypes.string),
    installationTable: arrayOf(DtoInstallationTableLine),
})

StationEventsApp.defaultProps = {
    getLink: ('', ''),
}

const mapStateToProps = store => getMapStateToProps(propsToFetch, {
    productionUnit: store.ProductionUnitReducer.productionUnit,
    distributionUnit: store.DistributionUnitReducer.distributionUnit,
    qualitometer: store.QualityReducer.qualitometer,
    piezometer: store.StationReducer.piezometer,
    pluviometers: store.PluviometryReducer.pluviometers,
    pluviometer: store.PluviometryReducer.pluviometer,
    hydrometricStation: store.HydrometryReducer.hydrometricStation,
    productionUnitEvents: store.ProductionUnitReducer.productionUnitEvents,
    associatedSites: store.StationReducer.associatedSites,
    cities: store.CityReducer.cities,
    piezometers: store.PiezometryReducer.piezometersLight,
    qualitometers: store.QualityReducer.qualitometersLight,
    productionUnits: store.ProductionUnitReducer.productionUnits,
    distributionUnits: store.DistributionUnitReducer.distributionUnits,
    installations: store.InstallationReducer.installations,
    hydrometricStations: store.HydrometryReducer.hydrometricStations,
    stationWatermass: store.StationReducer.stationWatermass,
    piezometryCampaigns: store.PiezometryReducer.piezometryCampaigns,
    pluviometryCampaigns: store.PluviometryReducer.pluviometryCampaigns,
    hydrometryCampaigns: store.HydrometryReducer.hydrometryCampaigns,
    qualityCampaigns: store.QualityReducer.qualityCampaigns,
    installationCampaigns: store.InstallationReducer.installationCampaigns,
    typeEnvironmentModels: store.ExportReducer.typeEnvironmentModels,
    installationTable: store.InstallationReducer.installationTable,
})

const mapDispatchToProps = {
    push,
    forceFetch: SieauAction.forceFetch,
    standardExport: ExportAction.export,
}

export default connect(mapStateToProps, mapDispatchToProps)(StationEventsApp)
