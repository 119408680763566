import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import { hasValue } from 'utils/NumberUtil'
import Table from '../../../components/datatable/Table'
import DtoProductionUnit from '../../../productionUnit/dto/DtoProductionUnit'
import { SANDRE } from '../../../referencial/constants/ReferencialConstants'
import { getFullDate } from '../../../utils/DateUtil'
import { getExport } from '../../../utils/linkUtils'
import { getLabel } from '../../../utils/StoreUtils'
import { getSandreLabel } from '../../../utils/StringUtil'
import ContributorModal from './modals/ContributorModal'

const StationPiezoContributorPanel = ({
    station = {},
    readMode = false,
    onChange = () => { },
}) => {
    const {
        contacts,
        sandreCodes,
        contributors,
    } = useSelector(store => ({
        contacts: store.ContactReducer.contacts,
        sandreCodes: store.ReferencialReducer.sandreCodes,
        contributors: store.ContributorReducer.contributors,
    }), shallowEqual)
    const [isOpen, setIsOpen] = useState(false)
    const [selectedContributor, setSelectedContributor] = useState({})
    const stationContributors = station.link_contributors.map((c, index) => ({
        ...c,
        index,
    }))
    const tableData = stationContributors.map(c => ({
        ...c,
        startDate: getFullDate(c.startDate),
        endDate: getFullDate(c.endDate),
        type: getSandreLabel(sandreCodes, SANDRE.CONTRIBUTOR_TYPES, c.contributorType),
        contributor: getLabel(contributors, c.idContributor, 'mnemonique'),
        contact: getLabel(contacts, c.contactCode),
    }))
    const headers = ['startDate', 'endDate', 'type', 'contributor', 'contact', 'internalReference']
    const exportAction = getExport(tableData, i18n.contributors, headers)
    const actions = !readMode ? [{
        iconName: 'note_add',
        tooltip: i18n.add,
        onClick: () => {
            setSelectedContributor({})
            setIsOpen(true)
        },
    }, exportAction] : [exportAction]

    const updateContributors = (contributor) => {
        if (!hasValue(selectedContributor.index)) {
            onChange({ link_contributors: [...stationContributors, contributor] })
        } else {
            onChange({
                link_contributors: stationContributors.map((d, i) => i === selectedContributor.index ? contributor : d),
            })
        }
    }

    return (
        <>
            <Table
                title={i18n.contributors}
                condensed
                data={tableData}
                type={{ headers }}
                sortable={!!tableData.length}
                actions={actions}
                alterable={!readMode}
                onAlter={element => {
                    setSelectedContributor(stationContributors.find(sc => sc.index === element.index))
                    setIsOpen(true)
                }}
                deletable={!readMode}
                onDelete={(element) => onChange({ link_contributors: stationContributors.filter(lc => lc.index !== element.index) })}
            />
            {isOpen && (
                <ContributorModal
                    idStation={station.id}
                    isOpen={isOpen}
                    setIsOpen={setIsOpen}
                    contributor={selectedContributor}
                    onRegister={updateContributors}
                />
            )}
        </>
    )
}

StationPiezoContributorPanel.propTypes = {
    station: PropTypes.instanceOf(DtoProductionUnit),
    readMode: PropTypes.bool,
    onChange: PropTypes.func,
}


export default StationPiezoContributorPanel