/* eslint-disable camelcase */
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { shallowEqual, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import STEPAssociatedTownModal from './modal/STEPAssociatedTownModal'
import Table from '../../../../components/datatable/Table'
import DtoSTEP from '../dto/DtoSTEP'
import { getExport } from '../../../../utils/linkUtils'
import Checkbox from 'components/forms/Checkbox'
import { hasValue } from 'utils/NumberUtil'

const headers = ['name', 'code', 'population', 'totalSevice', 'nbFollow']

const STEPAssociatedTownsPanel = ({
    STEP = {},
    readMode = false,
    onChange = () => { },
}) => {
    const {
        cities,
    } = useSelector(store => ({
        cities: store.CityReducer.cities,
    }), shallowEqual)

    const [isOpen, setIsOpen] = useState(false)
    const [selectedTown, setSelectedTown] = useState(false)

    const STEPAssociatedTowns = STEP.link_associatedTowns.map((c, index) => ({
        ...c,
        index,
    }))

    const tableData = STEPAssociatedTowns.map((t) => {
        const foundCity = cities.find((c) => c.code === t.code) || {}
        return {
            ...t,
            ...foundCity,
            totalSevice: t.totalSevice ? (<Checkbox checked disabled />) : false,
        }
    })

    const updateTowns = (town) => {
        if (!hasValue(selectedTown.index)) {
            onChange({ link_associatedTowns: [...STEPAssociatedTowns, town] })
        } else {
            onChange({
                link_associatedTowns: STEPAssociatedTowns.map((d, i) => i === selectedTown.index ? town : d),
            })
        }
    }

    const exportAction = getExport(tableData, i18n.cities, headers)
    const actions = !readMode
        ? [
            {
                iconName: 'note_add',
                tooltip: i18n.add,
                onClick: () => {
                    setIsOpen(true)
                    setSelectedTown({})
                },
            },
            exportAction,
        ]
        : [exportAction]

    return (
        <>
            <Table
                title={i18n.cities}
                condensed
                data={tableData}
                className='blue'
                type={{ headers }}
                sortable={!!tableData.length}
                actions={actions}
                alterable={!readMode}
                onAlter={(element) => {
                    setSelectedTown(STEPAssociatedTowns.find((c) => element.index === c.index))
                    setIsOpen(true)
                }}
                deletable={!readMode}
                onDelete={(element) =>
                    onChange({
                        link_associatedTowns: STEPAssociatedTowns.filter(
                            (at) => at.index !== element.index,
                        ),
                    })
                }
            />
            {isOpen && <STEPAssociatedTownModal
                idStation={STEP.idStation}
                town={selectedTown}
                setIsOpen={setIsOpen}
                isOpen={isOpen}
                onSave={updateTowns}
            />}
        </>
    )
}

STEPAssociatedTownsPanel.propTypes = {
    STEP: PropTypes.instanceOf(DtoSTEP),
    readMode: PropTypes.bool,
    onChange: PropTypes.func,
    isOpen: PropTypes.bool,
    setIsOpen: PropTypes.func,
}

export default STEPAssociatedTownsPanel
