import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'

const ImportFormatPanel = ({
    isEditMode = true,
}) => (
    <div>
        <div className='row no-margin valign-wrapper'>
            <div className='input-field col s3'>
                <label>{i18n.importFile}</label>
            </div>
            <div className='file-field col s9 no-padding'>
                <div className='input-field col s3'>
                    <a className='waves-effect waves-light btn col s12'>
                        {i18n.importLabel}
                        <input type='file' multiple disabled={!isEditMode}/>
                    </a>
                </div>
                <div className='input-field col s9 file-path-wrapper'>
                    <input
                        className='file-path validate'
                        type='text'
                        disabled
                        placeholder={i18n.selectFile}
                    />
                </div>
            </div>
        </div>
        <div className='row no-margin valign-wrapper'>
            <div className=' input-field col s3'>
                <label>{i18n.defineDirectory}</label>
            </div>
            <div className='input-field col s9'>
                <input
                    id='txtDirDef'
                    type='text'
                    placeholder={i18n.selectDirectory}
                    disabled={!isEditMode}
                />
            </div>
        </div>
        <div className='row no-margin valign-wrapper'>
            <div className='input-field col s3'>
                <label>{i18n.fileRenaming}</label>
            </div>
            <div className='input-field col s9'>
                <input
                    id='txtFileRenaming'
                    type='text'
                    placeholder={i18n.renamingExample}
                    disabled={!isEditMode}
                />
            </div>
        </div>
        <div className='row no-margin valign-wrapper'>
            <div className=' input-field col s3'>
                <label>{i18n.producer}</label>
            </div>
            <div className='input-field col s9'>
                <input id='producer' type='text' disabled={!isEditMode}/>
            </div>
        </div>
        <div className='row no-margin valign-wrapper'>
            <div className=' input-field col s3'>
                <label>{i18n.laboratory}</label>
            </div>
            <div className='input-field col s9'>
                <input id='laboratory' type='text' disabled={!isEditMode}/>
            </div>
        </div>
        <div className='row no-margin valign-wrapper'>
            <div className=' input-field col s3'>
                <label>{i18n.statuts}</label>
            </div>
            <div className='input-field col s9'>
                <input id='statuts' type='text' disabled={!isEditMode}/>
            </div>
        </div>
        <div className='row no-margin valign-wrapper'>
            <div className=' input-field col s3'>
                <label>{i18n.qualification}</label>
            </div>
            <div className='input-field col s9'>
                <input id='qualification' type='text' disabled={!isEditMode}/>
            </div>
        </div>
    </div>
)

ImportFormatPanel.propTypes = {
    isEditMode: PropTypes.bool,
}

const mapStateToProps = (/* store*/) => ({
})

const mapDispatchToProps = {
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ImportFormatPanel)