import ApplicationConf from '../../../../conf/ApplicationConf'
import {
    RECEIVE_ALL_SUBSCRIPTION_SITUATIONS,
    RECEIVE_ALL_SUBSCRIPTIONS,
    RECEIVE_SIM_SUBSCRIPTIONS_LAST_SITUATIONS,
    RECEIVE_SUBSCRIPTION,
    RECEIVE_SUBSCRIPTION_SIM_LINK,
    RECEIVE_SUBSCRIPTION_TYPES,
    RECEIVE_SUBSCRIPTIONS_LAST_SITUATIONS,
    RESET_SUBSCRIPTION,
} from '../constants/SubscriptionConstant'
import {
    checkAuth,
    checkAuthWithoutKicking,
    checkError,
    getAuthorization,
    getJson,
} from '../../../../utils/ActionUtils'
import ToastrAction from 'toastr/actions/ToastrAction'
import i18n from 'simple-react-i18n'
import fetch from 'isomorphic-fetch'
import { hasValue } from '../../../../utils/NumberUtil'
import { push } from '@lagunovsky/redux-react-router'
import LogAction from '../../../../log/actions/LogAction'

const SubscriptionAction = {
    receiveSubscriptions: subscriptions => ({ type: RECEIVE_ALL_SUBSCRIPTIONS, subscriptions }),
    promiseSubscriptions: () => fetch(ApplicationConf.materiel.subscriptions(), {
        method: 'GET',
        headers: getAuthorization(),
    })
        .then(checkAuth)
        .then(getJson)
        .then(checkError),
    fetchSubscriptions: () => dispatch => SubscriptionAction.promiseSubscriptions()
        .then((json = []) => {
            dispatch(SubscriptionAction.receiveSubscriptions(json))
            if (json.length) {
                dispatch(SubscriptionAction.fetchSubscriptionsLastSituations())
            }
        }),

    receiveSubscription: subscription => ({ type: RECEIVE_SUBSCRIPTION, subscription }),
    promiseSubscription: id => fetch(ApplicationConf.materiel.subscription(id), {
        headers: getAuthorization(),
    })
        .then(checkAuthWithoutKicking)
        .then(getJson)
        .then(checkError),
    fetchSubscription: id => dispatch => {
        return SubscriptionAction.promiseSubscription(id)
            .then((json = {}) => {
                dispatch(SubscriptionAction.receiveSubscription(json))
                return json
            })
            .catch(e => {
                dispatch(LogAction.logError(`${i18n.fetchError + i18n.subscription} : ${e}`))
                dispatch(ToastrAction.error(i18n.fetchError + i18n.subscription))
            })
    },

    createSubscription: subscription => dispatch => fetch(ApplicationConf.materiel.subscriptions(), {
        method: 'POST',
        headers: getAuthorization(),
        body: JSON.stringify(subscription),
    })
        .then(checkAuth)
        .then(getJson)
        .then(checkError)
        .then(json => {
            if (json.insert === 1 && hasValue(json.id)) {
                dispatch(SubscriptionAction.fetchSubscriptions())
                dispatch(push(`/materiel/subscription/${json.id}`))
                dispatch(ToastrAction.success(i18n.elementCreateSuccess))
                return json.id
            }
            throw new Error(json)
        })
        .catch(err => {
            const label = i18n.updateError + (err.message === '409 Conflict' ? i18n.immoNumberOrSerialNumberAlreadyUse : i18n.subscription)
            dispatch(LogAction.logError(`${label} ${err}`))
            dispatch(ToastrAction.error(label))
        }),

    deleteSubscription: id => dispatch => fetch(ApplicationConf.materiel.subscription(id), {
        method: 'DELETE',
        headers: getAuthorization(),
    })
        .then(checkAuth)
        .then(getJson)
        .then(checkError)
        .then(() => {
            dispatch(SubscriptionAction.fetchSubscriptions())
            dispatch(push('/materiel/subscription'))
            dispatch(ToastrAction.success(i18n.elementDeleteSuccess))
        })
        .catch(err => {
            dispatch(LogAction.logError(`${i18n.deleteError + i18n.subscription} : ${err}`))
            dispatch(ToastrAction.error(i18n.deleteError + i18n.subscription))
        }),

    updateSubscription: subscription => dispatch => fetch(ApplicationConf.materiel.subscription(subscription.id), {
        method: 'PUT',
        headers: getAuthorization(),
        body: JSON.stringify(subscription),
    })
        .then(checkAuth)
        .then(getJson)
        .then(checkError)
        .then(json => {
            if (json.update === 1) {
                dispatch(SubscriptionAction.fetchSubscriptions())
                dispatch(ToastrAction.success(i18n.elementUpdateSuccess))
                dispatch(SubscriptionAction.fetchSubscription(subscription.id))
                return true
            }
            throw new Error(json)
        })
        .catch(err => {
            const label = i18n.updateError + (err.message === '409 Conflict' ? i18n.immoNumberOrSerialNumberAlreadyUse : i18n.subscription)
            dispatch(LogAction.logError(`${label} ${err}`))
            dispatch(ToastrAction.error(label))
        }),

    receiveSubscriptionTypes: subscriptionTypes => ({ type: RECEIVE_SUBSCRIPTION_TYPES, subscriptionTypes }),
    promiseSubscriptionTypes: () => fetch(ApplicationConf.materiel.subscriptionTypes(), {
        method: 'GET',
        headers: getAuthorization(),
    })
        .then(checkAuth)
        .then(getJson)
        .then(checkError),
    fetchSubscriptionTypes: () => dispatch => SubscriptionAction.promiseSubscriptionTypes()
        .then((json = {}) => {
            dispatch(SubscriptionAction.receiveSubscriptionTypes(json))
        }),

    receiveSubscriptionsLastSituations: subscriptionsLastSituations => ({ type: RECEIVE_SUBSCRIPTIONS_LAST_SITUATIONS, subscriptionsLastSituations }),
    promiseSubscriptionsLastSituations: () => fetch(ApplicationConf.materiel.subscriptionsLastSituation(), {
        method: 'GET',
        headers: getAuthorization(),
    })
        .then(checkAuth)
        .then(getJson)
        .then(checkError),
    fetchSubscriptionsLastSituations: () => dispatch => SubscriptionAction.promiseSubscriptionsLastSituations()
        .then((json = []) => {
            dispatch(SubscriptionAction.receiveSubscriptionsLastSituations(json))
        }),

    receiveSubscriptionSituations: subscriptionSituations => ({ type: RECEIVE_ALL_SUBSCRIPTION_SITUATIONS, subscriptionSituations }),
    promiseSubscrciptionSituations: id => fetch(ApplicationConf.materiel.subscriptionSituations(id), {
        method: 'GET',
        headers: getAuthorization(),
    })
        .then(checkAuth)
        .then(getJson)
        .then(checkError),
    fetchSubscriptionSituations: id => dispatch => SubscriptionAction.promiseSubscrciptionSituations(id)
        .then(json => {
            dispatch(SubscriptionAction.receiveSubscriptionSituations(json))
        }),

    createSubscriptionSituation: (situation, shouldFetch = true) => dispatch => fetch(ApplicationConf.materiel.addSubscriptionSituation(), {
        method: 'POST',
        headers: getAuthorization(),
        body: JSON.stringify(situation),
    })
        .then(() => {
            dispatch(SubscriptionAction.fetchSubscriptionsLastSituations())
            dispatch(ToastrAction.success(i18n.situationAddSuccess))
            if (shouldFetch) {
                dispatch(SubscriptionAction.fetchSubscriptionSituations(situation.idSubscription))
            }
        }),

    deleteSubscriptionSituation: (id, idSubscription) => dispatch => fetch(ApplicationConf.materiel.deleteSubscriptionSituation(id), {
        method: 'DELETE',
        headers: getAuthorization(),
    })
        .then(() => {
            dispatch(SubscriptionAction.fetchSubscriptionsLastSituations())
            dispatch(ToastrAction.success(i18n.situationDeleteSuccess))
            dispatch(SubscriptionAction.fetchSubscriptionSituations(idSubscription))
        }),

    updateSubscriptionSituation: (idSituation, situation, openCampaginModal = undefined) => dispatch => fetch(ApplicationConf.materiel.saveSubscriptionSituation(idSituation), {
        method: 'PUT',
        headers: getAuthorization(),
        body: JSON.stringify(situation),
    })
        .then(() => {
            dispatch(SubscriptionAction.fetchSubscriptionsLastSituations())
            dispatch(ToastrAction.success(i18n.saveSuccess))
            if (openCampaginModal) {
                openCampaginModal(situation)
            }
        }),

    createEventAndUpdateSituation: (urlEvent, event, situation, openCampaginModal = undefined) => dispatch => fetch(urlEvent, {
        method: 'POST',
        headers: getAuthorization(),
        body: JSON.stringify(event),
    })
        .then(getJson)
        .then((json) => {
            if (json.eventId) {
                const result = {
                    ...situation,
                    eventCode: json.eventId,
                }
                dispatch(SubscriptionAction.updateSubscriptionSituation(result.id, result))
                if (openCampaginModal) {
                    dispatch(openCampaginModal(result))
                }
            } else {
                dispatch(ToastrAction.error(i18n.saveError))
            }
        }),
    receiveSimSubscriptionsLastSituations: simSubscriptionsLastSituations => ({ type: RECEIVE_SIM_SUBSCRIPTIONS_LAST_SITUATIONS, simSubscriptionsLastSituations }),
    promiseSimSubscriptionsLastSituations: () => fetch(ApplicationConf.materiel.simSubscriptionsLastSituation(), {
        method: 'GET',
        headers: getAuthorization(),
    })
        .then(checkAuth)
        .then(getJson)
        .then(checkError),
    fetchSimSubscriptionsLastSituations: () => dispatch => SubscriptionAction.promiseSimSubscriptionsLastSituations()
        .then((json = []) => {
            dispatch(SubscriptionAction.receiveSimSubscriptionsLastSituations(json))
        }),

    receiveSimsLink: simsLink => ({ type: RECEIVE_SUBSCRIPTION_SIM_LINK, simsLink }),
    promiseSimsLink: id => fetch(ApplicationConf.materiel.subscriptionSim(id), {
        method: 'GET',
        headers: getAuthorization(),
    })
        .then(checkAuth)
        .then(getJson)
        .then(checkError),
    fetchSimsLink: id => dispatch => SubscriptionAction.promiseSimsLink(id)
        .then(json => {
            dispatch(SubscriptionAction.receiveSimsLink(json))
        }),

    deleteSimSubscriptionLink: (idSim, idSubscription) => dispatch => fetch(ApplicationConf.materiel.subscriptionSimLink(idSim, idSubscription), {
        method: 'DELETE',
        headers: getAuthorization(),
    })
        .then(checkAuth)
        .then(getJson)
        .then(checkError)
        .then(json => {
            if (json.delete !== 0) {
                dispatch(ToastrAction.success(i18n.elementDeleteSuccess))
            } else {
                throw new Error(json)
            }
        })
        .catch((err) => {
            dispatch(LogAction.logError(`${i18n.deleteError} : ${err}`))
            dispatch(ToastrAction.error(i18n.deleteError))
        }),

    resetSubscription: () => ({ type: RESET_SUBSCRIPTION }),
}

export default SubscriptionAction
