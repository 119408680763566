import ReferencialDto from '../../../dto/ReferencialDto'

export default class WatermassDto extends ReferencialDto {
    constructor(object) {
        super(object)
        this.id = object.code
        this.code = object.code
        this.label = object.label
        this.name = object.label
        this.watermassType = object.watermassType
        this.europeanCode = object.europeanCode
        this.insertionDate = object.insertionDate
        this.freeFlowCaptive = object.freeFlowCaptive // Ecoulement libre et captif dissociés
        this.captiveMajorityFlow = object.captiveMajorityFlow // Ecoulement majoritairement captif
        this.surface = object.surface
        this.reportingYear = object.reportingYear // Année de reportage
        this.acronym = object.acronym // Acronyme opérateur
        this.coastalFringe = object.coastalFringe // Frange littorale
        this.undergroundType = object.undergroundType // Type souterrain
        this.karst = object.karst // Karstique
        this.grouping = object.grouping // Regroupées
        this.freeMajorityFlow = object.freeMajorityFlow // Ecoulement majoritairement libre
        this.surfaceUnderCover = object.surfaceUnderCover // surface sous couverture
        this.multiCountry = object.multiCountry // multi pays
        this.multiBasin = object.multiBasin
        this.comment = object.comment
        this.freeFlow = object.freeFlow // Ecoulement libre
        this.captiveFlow = object.captiveFlow // Ecoulement captif
        this.precision = object.precision
    }
}