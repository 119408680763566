export default class DtoAltimetrySystem {
    constructor(object) {
        this.idStation = object.idStation
        this.startDate = object.startDate
        this.endDate = object.endDate
        this.natureCode = object.natureCode
        this.altitude = object.altitude
        this.altimetrySystem = object.altimetrySystem
        this.productionMode = object.productionMode
        this.comment = object.comment
        this.precision = object.precision
    }
}
