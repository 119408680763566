import DtoFile from '../../../../components/file/dto/DtoFile'
import DtoPicture from '../../../../station/dto/DtoPicture'
import { RESET_ALL_MATERIELS } from '../../../constants/MaterielConstants'
import { RESET_EQUIPMENT } from '../../equipment/constants/EquipmentConstants'
import {
    RECEIVE_ALL_SIMS, RECEIVE_ALL_SIM_SITUATIONS, RECEIVE_SIM, RECEIVE_SIMS_LAST_SITUATIONS, RECEIVE_SIM_FILES,
    RECEIVE_SIM_PICTURES,
    RECEIVE_SIM_SUBSCRIPTION_LINK,
    RECEIVE_SIM_TYPES, RESET_SIM,
} from '../constants/SimConstants'
import DtoSimSituation from '../dto/DtoSimSituation'
import DtoSimType from '../dto/DtoSimType'
import SimDto from '../dto/SimDto'
import SimSubscriptionDto from '../dto/SimSubscriptionDto'

export const store = {
    sims: [],
    sim: {},
    simTypes: [],
    simSituations: [],
    simsLastSituations: [],
    subscriptionsLink: [],
    simPictures: [],
    simFiles: [],
}

export function SimReducer(state = {}, action) {
    switch (action.type) {
        case RECEIVE_ALL_SIMS:
            return {
                ...state,
                sims: action.sims.map(sim => new SimDto(sim)),
            }
        case RECEIVE_SIM:
            return {
                ...state,
                sim: new SimDto(action.sim),
            }
        case RECEIVE_SIM_TYPES:
            return {
                ...state,
                simTypes: action.simTypes.map(t => new DtoSimType(t)),
            }
        case RECEIVE_ALL_SIM_SITUATIONS:
            return {
                ...state,
                simSituations: action.simSituations.map(t => new DtoSimSituation(t)),
            }
        case RECEIVE_SIMS_LAST_SITUATIONS:
            return {
                ...state,
                simsLastSituations: action.simsLastSituations.map(s => new DtoSimSituation(s)),
            }
        case RECEIVE_SIM_PICTURES:
            return {
                ...state,
                simPictures: action.simPictures.map(el => new DtoPicture(el)),
            }
        case RECEIVE_SIM_FILES:
            return {
                ...state,
                simFiles: action.simFiles.map(el => new DtoFile(el)),
            }
        case RESET_SIM:
            return {
                ...state,
                sim: {},
                simSituations: [],
                simPictures: [],
                simFiles: [],
            }
        case RESET_EQUIPMENT:
            return {
                ...state,
                simTypes: [],
            }
        case RECEIVE_SIM_SUBSCRIPTION_LINK:
            return {
                ...state,
                subscriptionsLink: action.subscriptionsLink.map(link => new SimSubscriptionDto(link)),
            }
        case RESET_ALL_MATERIELS:
            return {
                ...state,
                ...store,
            }
        default:
            return state
    }
}