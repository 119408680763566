import { RECEIVE_TOWNS_INFO } from '../../administration/constants/AdministrationConstants'
import DtoGeoApiTown from '../DtoGeoApiTown'

export function GeoApiReducer(state = {}, action) {
    switch (action.type) {
        case RECEIVE_TOWNS_INFO:
            return {
                ...state,
                towns: action.towns.map(e => new DtoGeoApiTown(e)),
            }
        default:
            return state
    }
}

export const store = {
    towns: [],
}
