import DtoMaterielAssignment from './DtoMaterielAssignment'

export default class DtoStationSimAssignment extends DtoMaterielAssignment {
    constructor(obj) {
        super(obj)
        this.id = obj.idSimSituation
        this.idSimSituation = obj.idSimSituation
        this.idSim = obj.idSim
        this.materielId = obj.idSim
    }
}