export default class DtoSite {
    constructor(obj) {
        this.id = obj.id
        this.code = obj.code
        this.name = obj.name || obj.code
        this.xCoordinate = obj.xcoordinate
        this.yCoordinate = obj.ycoordinate
        this.type_id = obj.type_id
        this.projection = 16
    }
}
