export default class DtoDocumentStatistic {
    constructor(obj) {
        this.id = obj.id // Option[Int],
        this.login = obj.login // String,
        this.statisticDate = obj.statisticDate // DateTime,
        this.module = obj.module // String,
        this.identifiant = obj.identifiant // Option[String],
        this.document = obj.document // Option[String]
    }
}
