const {
    AGRI,
    DISTRIBUTION,
    HYDRO,
    INSTALLATION,
    PERIMETERS,
    RESOURCE,
    MAT,
    MAT_BATTERIES,
    MAT_CAPTEURS,
    MAT_CENTRALES,
    MAT_FOURNISSEURS,
    MAT_MODEMS,
    MAT_SIMS,
    MAT_TELECOMS,
    MAT_DIVERS,
    PIEZO,
    PIEZO_EXPORT,
    PLUVIO,
    PRODUCTION,
    QUALITO,
    QUALITO_DATA_CONSULT,
    QUALITO_EXPORT,
    QUALITO_IMPORT,
    QUALITO_RECHERCHES,
    QUALITO_SITUATIONS,
    QUALITO_SUPERPOSITION,
    SITUATION,
    SITUATION_FLOOD,
    SITUATION_MONITORING,
    SITUATION_POLLUTION,
    SITUATION_THEME,
    ADMIN,
    GEST,
    CONSULT,
    TECH,
    PGSSE,
    AGRI_TERRITORY,
    AGRI_DOCUMENTS,
    AGRI_SURVEYS,
    CATCHMENT,
    AGRI_RSEAU,
    PIEZO_MODEL,
    HYDRO_MODEL,
    QUALITO_PRESELECTION,
    QUALITO_SEUILS,
    QUALITO_DEMANDES,
    PIEZO_SUPERPOSITION,
    PLUVIO_SUPERPOSITION,
    HYDRO_SUPERPOSITION,
} = require('administration/components/user/constants/HabilitationConstants')

module.exports = {
    // Modules
    H_QUALITO_MODULE: { required: [QUALITO], atLeastOne: [], requiredRoles: [], forbiddenRoles: [] },
    H_PIEZO_MODULE: { required: [PIEZO], atLeastOne: [], requiredRoles: [], forbiddenRoles: [] },
    H_HYDRO_MODULE: { required: [HYDRO], atLeastOne: [], requiredRoles: [], forbiddenRoles: [] },
    H_PLUVIO_MODULE: { required: [PLUVIO], atLeastOne: [], requiredRoles: [], forbiddenRoles: [] },
    H_INSTALLATION_MODULE: { required: [INSTALLATION], atLeastOne: [], requiredRoles: [], forbiddenRoles: [] },
    H_PERIMETERS_MODULE: { required: [PERIMETERS], atLeastOne: [], requiredRoles: [], forbiddenRoles: [] },
    H_RESOURCE_MODULE: { required: [RESOURCE], atLeastOne: [], requiredRoles: [], forbiddenRoles: [] },
    H_PRODUCTION_MODULE: { required: [PRODUCTION], atLeastOne: [], requiredRoles: [], forbiddenRoles: [] },
    H_DISTRIBUTION_MODULE: { required: [DISTRIBUTION], atLeastOne: [], requiredRoles: [], forbiddenRoles: [] },
    H_MAT_MODULE: { required: [MAT], atLeastOne: [], requiredRoles: [], forbiddenRoles: [] },
    H_PGSSE_MODULE: { required: [PGSSE], atLeastOne: [], requiredRoles: [], forbiddenRoles: [] },
    H_SITUATION_MODULE: { required: [SITUATION], atLeastOne: [], requiredRoles: [], forbiddenRoles: [] },
    H_AGRI_MODULE: { required: [AGRI], atLeastOne: [], requiredRoles: [], forbiddenRoles: [] },
    H_CATCHMENT_MODULE: { required: [CATCHMENT], atLeastOne: [], requiredRoles: [], forbiddenRoles: [] },

    // Qualito
    H_QUALITO_DASHBOARD: { required: [QUALITO], atLeastOne: [], requiredRoles: [], forbiddenRoles: [] },
    H_QUALITO_IMPORT: { required: [QUALITO, QUALITO_IMPORT], atLeastOne: [], requiredRoles: [ADMIN], forbiddenRoles: [] },
    H_QUALITO_EXPORT: { required: [QUALITO, QUALITO_EXPORT], atLeastOne: [], requiredRoles: [ADMIN, GEST, TECH], forbiddenRoles: [] },
    H_QUALITO_INTEGRATION: { required: [QUALITO], atLeastOne: [QUALITO_RECHERCHES, QUALITO_SITUATIONS], requiredRoles: [], forbiddenRoles: [] },
    H_QUALITO_GRAPHIC: { required: [QUALITO, QUALITO_RECHERCHES, QUALITO_SUPERPOSITION], atLeastOne: [], requiredRoles: [], forbiddenRoles: [] },
    H_QUALITO_RESEARCH: { required: [QUALITO, QUALITO_RECHERCHES], atLeastOne: [], requiredRoles: [], forbiddenRoles: [] },
    H_QUALITO_RESEARCH_CRITERIAS: { required: [QUALITO, QUALITO_RECHERCHES, PRODUCTION, DISTRIBUTION], atLeastOne: [], requiredRoles: [], forbiddenRoles: [] },
    H_QUALITO_CAMPAIGN: { required: [QUALITO], atLeastOne: [], requiredRoles: [], forbiddenRoles: [] },
    H_QUALITO_CONFORMITY: { required: [QUALITO, QUALITO_RECHERCHES], atLeastOne: [], requiredRoles: [], forbiddenRoles: [] },
    H_QUALITO_OPTIONS: { required: [QUALITO], atLeastOne: [], requiredRoles: [ADMIN], forbiddenRoles: [] },

    H_QUALITO_OPTIONS_INDICATOR: { required: [QUALITO], atLeastOne: [], requiredRoles: [ADMIN], forbiddenRoles: [] },
    H_QUALITO_OPTIONS_SELECTION: { required: [QUALITO, QUALITO_PRESELECTION], atLeastOne: [], requiredRoles: [ADMIN], forbiddenRoles: [] },
    H_QUALITO_OPTIONS_THRESHOLD: { required: [QUALITO, QUALITO_SEUILS], atLeastOne: [], requiredRoles: [ADMIN], forbiddenRoles: [] },

    H_STATION_QUALITO_DASHBOARD: { required: [QUALITO], atLeastOne: [], requiredRoles: [], forbiddenRoles: [] },
    H_STATION_QUALITO_DESCRIPTION: { required: [QUALITO, QUALITO_DATA_CONSULT], atLeastOne: [], requiredRoles: [], forbiddenRoles: [] },
    H_STATION_QUALITO_EVENTS: { required: [QUALITO], atLeastOne: [], requiredRoles: [], forbiddenRoles: [] },
    H_STATION_QUALITO_DOCUMENTS: { required: [QUALITO], atLeastOne: [], requiredRoles: [], forbiddenRoles: [] },
    H_STATION_QUALITO_OPERATIONS: { required: [QUALITO], atLeastOne: [], requiredRoles: [], forbiddenRoles: [] },
    H_STATION_QUALITO_STATES: { required: [QUALITO], atLeastOne: [], requiredRoles: [], forbiddenRoles: [] },
    H_STATION_QUALITO_SUIVI_PC: { required: [QUALITO], atLeastOne: [], requiredRoles: [], forbiddenRoles: [] },
    H_STATION_QUALITO_HYDROBIO_OPERATIONS: { required: [QUALITO], atLeastOne: [], requiredRoles: [], forbiddenRoles: [] },
    H_STATION_QUALITO_HYDROBIO_MONITORING: { required: [QUALITO], atLeastOne: [], requiredRoles: [], forbiddenRoles: [] },
    H_STATION_QUALITO_MAT: { required: [QUALITO, MAT], atLeastOne: [], requiredRoles: [], forbiddenRoles: [] },

    H_CAMPAIGN_QUALITO_DASHBOARD: { required: [QUALITO], atLeastOne: [], requiredRoles: [], forbiddenRoles: [] },
    H_CAMPAIGN_QUALITO_TRACKING: { required: [QUALITO], atLeastOne: [], requiredRoles: [], forbiddenRoles: [] },

    H_QUALITY_EDILABO: { required: [QUALITO], atLeastOne: [QUALITO_DEMANDES], requiredRoles: [], forbiddenRoles: [] },

    // Piezo
    H_PIEZO_DASHBOARD: { required: [PIEZO], atLeastOne: [], requiredRoles: [], forbiddenRoles: [] },
    H_PIEZO_EXPORT: { required: [PIEZO, PIEZO_EXPORT], atLeastOne: [], requiredRoles: [], forbiddenRoles: [] },
    H_PIEZO_INTEGRATION: { required: [PIEZO], atLeastOne: [], requiredRoles: [], forbiddenRoles: [] },
    H_PIEZO_IMPORT: { required: [PIEZO], atLeastOne: [], requiredRoles: [], forbiddenRoles: [CONSULT] },
    H_PIEZO_EXPORT_OVERVIEW: { required: [PIEZO, PIEZO_EXPORT], atLeastOne: [], requiredRoles: [GEST, ADMIN], forbiddenRoles: [] },
    H_PIEZO_CAMPAIGN: { required: [PIEZO], atLeastOne: [], requiredRoles: [], forbiddenRoles: [] },
    H_PIEZO_OPTIONS: { required: [PIEZO], atLeastOne: [], requiredRoles: [ADMIN], forbiddenRoles: [] },
    H_PIEZO_GRAPHIC: { required: [PIEZO, PIEZO_SUPERPOSITION], atLeastOne: [], requiredRoles: [], forbiddenRoles: [] },
    H_PIEZO_DIAGNOSTICS: { required: [PIEZO], atLeastOne: [], requiredRoles: [], forbiddenRoles: [] },
    H_PIEZO_MAP_SITUATION: { required: [PIEZO], atLeastOne: [], requiredRoles: [GEST, ADMIN], forbiddenRoles: [] },

    H_STATION_PIEZO_DASHBOARD: { required: [PIEZO], atLeastOne: [], requiredRoles: [], forbiddenRoles: [] },
    H_STATION_PIEZO_DESCRIPTION: { required: [PIEZO], atLeastOne: [], requiredRoles: [], forbiddenRoles: [] },
    H_STATION_PIEZO_EVENTS: { required: [PIEZO], atLeastOne: [], requiredRoles: [], forbiddenRoles: [] },
    H_STATION_PIEZO_DOCUMENTS: { required: [PIEZO], atLeastOne: [], requiredRoles: [], forbiddenRoles: [] },
    H_STATION_PIEZO_FOLLOW_UP: { required: [PIEZO], atLeastOne: [], requiredRoles: [], forbiddenRoles: [] },
    H_STATION_PIEZO_VALIDATION: { required: [PIEZO], atLeastOne: [], requiredRoles: [], forbiddenRoles: [CONSULT] },
    H_STATION_PIEZO_MODELS: { required: [PIEZO_MODEL], atLeastOne: [], requiredRoles: [], forbiddenRoles: [CONSULT] },
    H_STATION_PIEZO_MAT: { required: [PIEZO, MAT], atLeastOne: [], requiredRoles: [], forbiddenRoles: [] },

    H_CAMPAIGN_PIEZO_DASHBOARD: { required: [PIEZO], atLeastOne: [], requiredRoles: [], forbiddenRoles: [] },
    H_CAMPAIGN_PIEZO_TRACKING: { required: [PIEZO], atLeastOne: [], requiredRoles: [], forbiddenRoles: [] },
    H_CAMPAIGN_PIEZO_MAT: { required: [PIEZO, MAT], atLeastOne: [], requiredRoles: [], forbiddenRoles: [] },

    // Pluvio
    H_PLUVIO_DASHBOARD: { required: [PLUVIO], atLeastOne: [], requiredRoles: [], forbiddenRoles: [] },
    H_PLUVIO_EXPORT: { required: [PLUVIO], atLeastOne: [], requiredRoles: [], forbiddenRoles: [] },
    H_PLUVIO_IMPORT: { required: [PLUVIO], atLeastOne: [], requiredRoles: [], forbiddenRoles: [CONSULT] },
    H_PLUVIO_SITUATION: { required: [PLUVIO], atLeastOne: [], requiredRoles: [], forbiddenRoles: [] },
    H_PLUVIO_OPTIONS: { required: [PLUVIO], atLeastOne: [], requiredRoles: [ADMIN], forbiddenRoles: [] },
    H_PLUVIO_CAMPAIGN: { required: [PLUVIO], atLeastOne: [], requiredRoles: [], forbiddenRoles: [] },
    H_PLUVIO_GRAPHIC: { required: [PLUVIO, PLUVIO_SUPERPOSITION], atLeastOne: [], requiredRoles: [], forbiddenRoles: [] },

    H_STATION_PLUVIO_DASHBOARD: { required: [PLUVIO], atLeastOne: [], requiredRoles: [], forbiddenRoles: [] },
    H_STATION_PLUVIO_DESCRIPTION: { required: [PLUVIO], atLeastOne: [], requiredRoles: [], forbiddenRoles: [] },
    H_STATION_PLUVIO_EVENTS: { required: [PLUVIO], atLeastOne: [], requiredRoles: [], forbiddenRoles: [] },
    H_STATION_PLUVIO_DOCUMENTS: { required: [PLUVIO], atLeastOne: [], requiredRoles: [], forbiddenRoles: [] },
    H_STATION_PLUVIO_VALIDATION: { required: [PLUVIO], atLeastOne: [], requiredRoles: [], forbiddenRoles: [CONSULT] },

    H_STATION_PLUVIO_MAT: { required: [PLUVIO, MAT], atLeastOne: [], requiredRoles: [], forbiddenRoles: [] },

    H_CAMPAIGN_PLUVIO_DASHBOARD: { required: [PLUVIO], atLeastOne: [], requiredRoles: [], forbiddenRoles: [] },
    H_CAMPAIGN_PLUVIO_TRACKING: { required: [PLUVIO], atLeastOne: [], requiredRoles: [], forbiddenRoles: [] },

    // Hydro
    H_HYDRO_DASHBOARD: { required: [HYDRO], atLeastOne: [], requiredRoles: [], forbiddenRoles: [] },
    H_HYDRO_EXPORT: { required: [HYDRO], atLeastOne: [], requiredRoles: [], forbiddenRoles: [] },
    H_HYDRO_SITUATION: { required: [HYDRO], atLeastOne: [], requiredRoles: [], forbiddenRoles: [] },
    H_HYDRO_CAMPAIGN: { required: [HYDRO], atLeastOne: [], requiredRoles: [], forbiddenRoles: [] },
    H_HYDRO_OPTIONS: { required: [HYDRO], atLeastOne: [], requiredRoles: [ADMIN], forbiddenRoles: [] },
    H_HYDRO_MAP_SITUATION: { required: [HYDRO], atLeastOne: [], requiredRoles: [GEST, ADMIN], forbiddenRoles: [] },
    H_HYDRO_GRAPHIC: { required: [HYDRO, HYDRO_SUPERPOSITION], atLeastOne: [], requiredRoles: [], forbiddenRoles: [] },

    H_STATION_HYDRO_DASHBOARD: { required: [HYDRO], atLeastOne: [], requiredRoles: [], forbiddenRoles: [] },
    H_STATION_HYDRO_IMPORT: { required: [HYDRO], atLeastOne: [], requiredRoles: [], forbiddenRoles: [] },
    H_STATION_HYDRO_DESCRIPTION: { required: [HYDRO], atLeastOne: [], requiredRoles: [], forbiddenRoles: [] },
    H_STATION_HYDRO_EVENTS: { required: [HYDRO], atLeastOne: [], requiredRoles: [], forbiddenRoles: [] },
    H_STATION_HYDRO_DOCUMENTS: { required: [HYDRO], atLeastOne: [], requiredRoles: [], forbiddenRoles: [] },
    H_STATION_HYDRO_FOLLOW_UP: { required: [HYDRO], atLeastOne: [], requiredRoles: [], forbiddenRoles: [] },

    H_CAMPAIGN_HYDRO_DASHBOARD: { required: [HYDRO], atLeastOne: [], requiredRoles: [], forbiddenRoles: [] },
    H_CAMPAIGN_HYDRO_TRACKING: { required: [HYDRO], atLeastOne: [], requiredRoles: [], forbiddenRoles: [] },
    H_STATION_HYDRO_MAT: { required: [HYDRO, MAT], atLeastOne: [], requiredRoles: [], forbiddenRoles: [] },
    H_STATION_HYDRO_VALIDATION: { required: [HYDRO], atLeastOne: [], requiredRoles: [], forbiddenRoles: [CONSULT] },
    H_STATION_HYDRO_MODELS: { required: [HYDRO_MODEL], atLeastOne: [], requiredRoles: [], forbiddenRoles: [CONSULT] },


    // Production
    H_PRODUCTION_DASHBOARD: { required: [PRODUCTION], atLeastOne: [], requiredRoles: [], forbiddenRoles: [] },

    // Distribution
    H_DISTRIBUTION_DASHBOARD: { required: [DISTRIBUTION], atLeastOne: [], requiredRoles: [], forbiddenRoles: [] },

    // Both Units
    H_UNITS_DASHBOARD: { required: [], atLeastOne: [PRODUCTION, DISTRIBUTION], requiredRoles: [], forbiddenRoles: [] },
    H_UNITS_DESCRIPTION: { required: [], atLeastOne: [PRODUCTION, DISTRIBUTION], requiredRoles: [], forbiddenRoles: [] },
    H_UNITS_EVENTS: { required: [], atLeastOne: [PRODUCTION, DISTRIBUTION], requiredRoles: [], forbiddenRoles: [] },
    H_UNITS_DOCUMENTS: { required: [], atLeastOne: [PRODUCTION, DISTRIBUTION], requiredRoles: [], forbiddenRoles: [] },
    H_UNITS_GRAPHIC: { required: [], atLeastOne: [PRODUCTION, DISTRIBUTION], requiredRoles: [], forbiddenRoles: [] },
    H_UNITS_SUIVIPC: { required: [], atLeastOne: [PRODUCTION, DISTRIBUTION], requiredRoles: [], forbiddenRoles: [] },
    H_UNITS_CONFORMITY: { required: [], atLeastOne: [PRODUCTION, DISTRIBUTION], requiredRoles: [], forbiddenRoles: [] },
    H_UNITS_COUNTERS: { required: [], atLeastOne: [DISTRIBUTION], requiredRoles: [], forbiddenRoles: [] },
    H_UNITS_OPTIONS: { required: [], atLeastOne: [DISTRIBUTION], requiredRoles: [], forbiddenRoles: [] },

    // Installation
    H_INSTALLATION_DASHBOARD: { required: [INSTALLATION], atLeastOne: [], requiredRoles: [], forbiddenRoles: [] },
    H_INSTALLATION_COUNTER: { required: [INSTALLATION], atLeastOne: [], requiredRoles: [], forbiddenRoles: [] },
    H_INSTALLATION_CAMPAIGN: { required: [INSTALLATION], atLeastOne: [], requiredRoles: [], forbiddenRoles: [] },
    H_INSTALLATION_TERRITORY_ACTIVITIES: { required: [INSTALLATION], atLeastOne: [], requiredRoles: [], forbiddenRoles: [] },
    H_INSTALLATION_OPTIONS: { required: [INSTALLATION], atLeastOne: [], requiredRoles: [ADMIN], forbiddenRoles: [] },

    H_STATION_INSTALLATION_DASHBOARD: { required: [INSTALLATION], atLeastOne: [], requiredRoles: [], forbiddenRoles: [] },
    H_STATION_INSTALLATION_DESCRIPTION: { required: [INSTALLATION], atLeastOne: [], requiredRoles: [], forbiddenRoles: [] },
    H_STATION_INSTALLATION_EVENTS: { required: [INSTALLATION], atLeastOne: [], requiredRoles: [], forbiddenRoles: [] },
    H_STATION_INSTALLATION_DOCUMENTS: { required: [INSTALLATION], atLeastOne: [], requiredRoles: [], forbiddenRoles: [] },
    H_STATION_INSTALLATION_FOLLOW_UP: { required: [INSTALLATION], atLeastOne: [], requiredRoles: [], forbiddenRoles: [] },
    H_STATION_INSTALLATION_AEP_INDICATOR: { required: [INSTALLATION], atLeastOne: [], requiredRoles: [], forbiddenRoles: [] },
    H_STATION_INSTALLATION_MAT: { required: [INSTALLATION, MAT], atLeastOne: [], requiredRoles: [], forbiddenRoles: [] },
    H_STATION_INSTALLATION_STEP: { required: [INSTALLATION], atLeastOne: [], requiredRoles: [], forbiddenRoles: [] },
    H_STATION_INSTALLATION_SUIVIPC: { required: [INSTALLATION], atLeastOne: [], requiredRoles: [], forbiddenRoles: [] },
    H_STATION_INSTALLATION_CONFORMITY: { required: [INSTALLATION], atLeastOne: [], requiredRoles: [], forbiddenRoles: [] },

    H_CAMPAIGN_INSTALLATION_DASHBOARD: { required: [INSTALLATION], atLeastOne: [], requiredRoles: [], forbiddenRoles: [] },
    H_CAMPAIGN_INSTALLATION_TRACKING: { required: [INSTALLATION], atLeastOne: [], requiredRoles: [], forbiddenRoles: [] },
    H_CAMPAIGN_INSTALLATION_VALIDATION: { required: [INSTALLATION], atLeastOne: [], requiredRoles: [], forbiddenRoles: [] },
    H_CAMPAIGN_INSTALLATION_VISIT: { required: [INSTALLATION], atLeastOne: [], requiredRoles: [], forbiddenRoles: [] },

    // Perimeters
    H_PERIMETERS_DASHBOARD: { required: [PERIMETERS], atLeastOne: [], requiredRoles: [], forbiddenRoles: [] },

    // Materiel
    H_MAT_DASHBOARD: { required: [MAT], atLeastOne: [], requiredRoles: [], forbiddenRoles: [] },
    H_MAT_CENTRAL: { required: [MAT_CENTRALES], atLeastOne: [], requiredRoles: [], forbiddenRoles: [] },
    H_MAT_SENSOR: { required: [MAT_CAPTEURS], atLeastOne: [], requiredRoles: [], forbiddenRoles: [] },
    H_MAT_POWER_SUPPLY: { required: [MAT_BATTERIES], atLeastOne: [], requiredRoles: [], forbiddenRoles: [] },
    H_MAT_TELECOM: { required: [MAT_TELECOMS], atLeastOne: [], requiredRoles: [], forbiddenRoles: [] },
    H_MAT_SIM: { required: [MAT_SIMS], atLeastOne: [], requiredRoles: [], forbiddenRoles: [] },
    H_MAT_VARIOUS: { required: [MAT_DIVERS], atLeastOne: [], requiredRoles: [], forbiddenRoles: [] },
    H_MAT_EQUIPMENT: { required: [MAT_MODEMS], atLeastOne: [], requiredRoles: [], forbiddenRoles: [] },
    H_MAT_SUBSCRIPTION: { required: [MAT_FOURNISSEURS], atLeastOne: [], requiredRoles: [], forbiddenRoles: [] },
    H_MAT_INVENTORY: { required: [], atLeastOne: [], requiredRoles: [], forbiddenRoles: [] },
    H_MAT_OPTIONS: { required: [MAT], atLeastOne: [], requiredRoles: [ADMIN], forbiddenRoles: [] },

    // Situation
    H_SITUATION_COMPLETE_MONITORING: { required: [], atLeastOne: [SITUATION_POLLUTION, SITUATION_MONITORING], requiredRoles: [], forbiddenRoles: [] },
    H_SITUATION_POLLUTION: { required: [SITUATION_POLLUTION], atLeastOne: [], requiredRoles: [], forbiddenRoles: [] },
    H_SITUATION_THEME: { required: [SITUATION_THEME], atLeastOne: [], requiredRoles: [], forbiddenRoles: [] },
    H_SITUATION_FLOOD: { required: [SITUATION_FLOOD], atLeastOne: [], requiredRoles: [], forbiddenRoles: [] },
    H_SITUATION_MONITORING: { required: [SITUATION_MONITORING], atLeastOne: [], requiredRoles: [], forbiddenRoles: [] },
    H_SITUATION_SEARCH: { required: [], atLeastOne: [], requiredRoles: [], forbiddenRoles: [] },

    // Territoire
    H_TERRITORY_DASHBOARD: { required: [AGRI, AGRI_TERRITORY], atLeastOne: [], requiredRoles: [], forbiddenRoles: [] },
    H_TERRITORY_SCENARIOS: { required: [AGRI, AGRI_TERRITORY], atLeastOne: [], requiredRoles: [], forbiddenRoles: [] },
    H_TERRITORY_USAGE: { required: [AGRI, AGRI_TERRITORY], atLeastOne: [], requiredRoles: [], forbiddenRoles: [] },
    H_TERRITORY_RSEAU: { required: [AGRI, AGRI_RSEAU], atLeastOne: [], requiredRoles: [], forbiddenRoles: [] },
    H_TERRITORY_OPTIONS: { required: [AGRI], atLeastOne: [], requiredRoles: [ADMIN], forbiddenRoles: [] },

    // Agri
    H_AGRI_FOLDERS_DASHBOARD: { required: [AGRI, AGRI_DOCUMENTS], atLeastOne: [], requiredRoles: [], forbiddenRoles: [] },
    H_AGRI_FOLDERS_VOLUMES: { required: [AGRI, AGRI_DOCUMENTS], atLeastOne: [], requiredRoles: [], forbiddenRoles: [] },
    H_AGRI_FOLDERS_MATERIELS: { required: [AGRI, AGRI_DOCUMENTS], atLeastOne: [], requiredRoles: [], forbiddenRoles: [] },
    H_AGRI_FOLDERS_DOCUMENTS: { required: [AGRI, AGRI_DOCUMENTS], atLeastOne: [], requiredRoles: [], forbiddenRoles: [] },
    H_AGRI_FOLDERS_CONSOS: { required: [AGRI, AGRI_DOCUMENTS], atLeastOne: [], requiredRoles: [], forbiddenRoles: [] },
    H_AGRI_FOLDERS_EVENTS: { required: [AGRI, AGRI_DOCUMENTS], atLeastOne: [], requiredRoles: [], forbiddenRoles: [] },
    H_AGRI_WATER_TURNS: { required: [AGRI, AGRI_DOCUMENTS], atLeastOne: [], requiredRoles: [], forbiddenRoles: [] },
    H_AGRI_SERVICES: { required: [AGRI, AGRI_DOCUMENTS], atLeastOne: [], requiredRoles: [], forbiddenRoles: [] },
    H_AGRI_FOLDERS_OPTIONS: { required: [AGRI], atLeastOne: [], requiredRoles: [ADMIN], forbiddenRoles: [] },

    H_AGRI_SURVEYS_DASHBOARD: { required: [AGRI, AGRI_SURVEYS], atLeastOne: [], requiredRoles: [], forbiddenRoles: [] },
    H_AGRI_SURVEYS_OPTIONS: { required: [AGRI], atLeastOne: [], requiredRoles: [ADMIN], forbiddenRoles: [] },

    // Référentiels
    H_REFERENCIAL_DASHBOARD: { required: [], atLeastOne: [], requiredRoles: [], forbiddenRoles: [] },
    H_REFERENCIAL_CITY: { required: [], atLeastOne: [], requiredRoles: [], forbiddenRoles: [] },
    H_REFERENCIAL_CONTACT: { required: [], atLeastOne: [], requiredRoles: [], forbiddenRoles: [] },
    H_REFERENCIAL_NETWORK: { required: [], atLeastOne: [PIEZO, HYDRO, QUALITO, PLUVIO], requiredRoles: [], forbiddenRoles: [] },
    H_REFERENCIAL_FRACTION: { required: [QUALITO], atLeastOne: [], requiredRoles: [], forbiddenRoles: [] },
    H_REFERENCIAL_CONTRIBUTOR: { required: [], atLeastOne: [], requiredRoles: [], forbiddenRoles: [] },
    H_REFERENCIAL_WATERMASS: { required: [], atLeastOne: [PIEZO, HYDRO, QUALITO, PLUVIO, AGRI], requiredRoles: [], forbiddenRoles: [] },
    H_REFERENCIAL_METHOD: { required: [QUALITO], atLeastOne: [], requiredRoles: [], forbiddenRoles: [] },
    H_REFERENCIAL_PARAMETER: { required: [QUALITO], atLeastOne: [], requiredRoles: [], forbiddenRoles: [] },
    H_REFERENCIAL_SUPPORT: { required: [QUALITO], atLeastOne: [], requiredRoles: [], forbiddenRoles: [] },
    H_REFERENCIAL_UNIT: { required: [QUALITO], atLeastOne: [], requiredRoles: [], forbiddenRoles: [] },
    H_REFERENCIAL_HYDROGEOLOGICAL_ENTITY: { required: [], atLeastOne: [PIEZO, HYDRO, QUALITO, PLUVIO], requiredRoles: [], forbiddenRoles: [] },
    H_REFERENCIAL_WATERSHED: { required: [], atLeastOne: [PIEZO, HYDRO, QUALITO, PLUVIO, AGRI], requiredRoles: [], forbiddenRoles: [] },
    H_REFERENCIAL_TAXON: { required: [QUALITO], atLeastOne: [], requiredRoles: [], forbiddenRoles: [] },
    H_REFERENCIAL_AQUIFER: { required: [], atLeastOne: [PIEZO, HYDRO, QUALITO, PLUVIO, AGRI], requiredRoles: [], forbiddenRoles: [] },
    H_REFERENCIAL_CULTURE: { required: [AGRI], atLeastOne: [], requiredRoles: [], forbiddenRoles: [] },
    H_REFERENCIAL_CULTURE_RPG: { required: [AGRI], atLeastOne: [], requiredRoles: [], forbiddenRoles: [] },
    H_REFERENCIAL_CULTURES_FAMILY: { required: [AGRI], atLeastOne: [], requiredRoles: [], forbiddenRoles: [] },
    H_REFERENCIAL_ZONE: { required: [], atLeastOne: [], requiredRoles: [], forbiddenRoles: [] },
    H_REFERENCIAL_DRIVEMODE: { required: [], atLeastOne: [], requiredRoles: [], forbiddenRoles: [] },
    H_REFERENCIAL_ACTIVITY: { required: [], atLeastOne: [], requiredRoles: [], forbiddenRoles: [] },
    H_REFERENCIAL_COUNTRY: { required: [], atLeastOne: [], requiredRoles: [], forbiddenRoles: [] },
    H_REFERENCIAL_SANDRE_CODES: { required: [], atLeastOne: [], requiredRoles: [], forbiddenRoles: [] },
    H_REFERENCIAL_LEXICONS: { required: [], atLeastOne: [], requiredRoles: [], forbiddenRoles: [] },
    H_REFERENCIAL_MAT_TYPES: { required: [], atLeastOne: [], requiredRoles: [], forbiddenRoles: [] },
    H_REFERENCIAL_UNIT_MANAGMENT: { required: [], atLeastOne: [], requiredRoles: [], forbiddenRoles: [] },
    H_REFERENCIAL_USAGE: { required: [AGRI], atLeastOne: [], requiredRoles: [], forbiddenRoles: [] },
    H_REFERENCIAL_RPG: { required: [AGRI], atLeastOne: [], requiredRoles: [], forbiddenRoles: [] },
    H_REFERENCIAL_SECTOR: { required: [], atLeastOne: [DISTRIBUTION, INSTALLATION], requiredRoles: [], forbiddenRoles: [] },

    // Administration
    H_ADMINISTRATION: { required: [], atLeastOne: [], requiredRoles: [ADMIN], forbiddenRoles: [] },

    // Captage
    H_CATCHMENTS_DASHBOARD: { required: [], atLeastOne: [], requiredRoles: [], forbiddenRoles: [] },
    H_CATCHMENTS_OVERVIEW: { required: [], atLeastOne: [], requiredRoles: [], forbiddenRoles: [] },
    H_CATCHMENT_DASHBOARD: { required: [], atLeastOne: [], requiredRoles: [], forbiddenRoles: [] },
    H_CATCHMENT_PCMONITORING: { required: [], atLeastOne: [], requiredRoles: [], forbiddenRoles: [] },
    H_CATCHMENT_CONFORMITY: { required: [], atLeastOne: [], requiredRoles: [], forbiddenRoles: [] },
    H_CATCHMENT_OPTIONS: { required: [], atLeastOne: [], requiredRoles: [ADMIN, GEST], forbiddenRoles: [] },


    // Ressources
    H_RESOURCE_DASHBOARD: { required: [RESOURCE], atLeastOne: [], requiredRoles: [], forbiddenRoles: [] },

    H_STATION_RESOURCE_DASHBOARD: { required: [RESOURCE], atLeastOne: [], requiredRoles: [], forbiddenRoles: [] },
    H_STATION_RESOURCE_DESCRIPTION: { required: [RESOURCE], atLeastOne: [], requiredRoles: [], forbiddenRoles: [] },
    H_STATION_RESOURCE_DOCUMENTS: { required: [RESOURCE], atLeastOne: [], requiredRoles: [], forbiddenRoles: [] },
    H_STATION_RESOURCE_SUIVIPC: { required: [RESOURCE], atLeastOne: [], requiredRoles: [], forbiddenRoles: [] },
    H_STATION_RESOURCE_CONFORMITY: { required: [RESOURCE], atLeastOne: [], requiredRoles: [], forbiddenRoles: [] },
    H_STATION_RESOURCE_OPTIONS: { required: [], atLeastOne: [], requiredRoles: [ADMIN], forbiddenRoles: [] },
}
