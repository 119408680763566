/* eslint-disable camelcase */
import { Button, Dialog, Grid } from '@mui/material'
import Other from 'components/actions/Other'
import Input from 'components/forms/Input'
import NumberField from 'components/forms/NumberField'
import ProgressCard from 'components/card/ProgressCard'
import { push } from '@lagunovsky/redux-react-router'
import HomeAction from 'home/actions/HomeAction'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import ToastrAction from 'toastr/actions/ToastrAction'
import { getUser } from 'utils/SettingUtils'
import AgriAction from '../../../agriAdministration/actions/AgriAction'
import ActionComponent from '../../../components/ActionComponent'
import Card from '../../../components/card/Card'
import LastUpdateCard from '../../../components/card/LastUpdateCard'
import SimpleDatePicker from '../../../components/forms/SimpleDatePicker'
import Row from '../../../components/react/Row'
import DtoExploitation from '../../../exploitations/dto/DtoExploitation'
import ContributorDto from '../../../referencial/components/contributor/dto/ContributorDto'
import DtoSandreCode from '../../../referencial/dto/DtoSandreCode'
import { arrayOf } from '../../../utils/StoreUtils'
import DtoDeclarationExportFull from '../../dto/DtoDeclarationExportFull'
import DtoSurvey from '../../dto/DtoSurvey'
import ModalWarningDeleteSurvey from '../declaration/modals/ModalWarningDeleteSurvey'
import FormSurvey from '../panels/FormSurvey'
import ProgressSurveyChart from '../panels/ProgressSurveyChart'
import SurveyDeclarationsPanel from '../panels/SurveyDeclarationsPanel'
import { DialogActionsMUI, DialogContentMUI, DialogTitleMUI } from 'components/styled/Dialog'
import Icon from 'components/icon/Icon'

class SurveyDashboardApp extends ActionComponent {
    constructor(props) {
        super(props)
        this.state = {
            dataLoaded: false,
            survey: { ...props.survey },
            exportFullSurvey: [],
            openDuplicate: false,
            duplicateName: null,
            duplicateYear: null,
            duplicateStartDate: null,
            duplicateEndDate: null,
            duplicateAdminEndDate: null,
            readMode: false,
            openDeleteWarningModal: false,
        }
    }

    componentDidMount = () => {
        const { params } = this.props
        this.getSurvey(params.id)
    }

    getSurvey = (id) => {
        this.props.fetchSurvey(id).then(() => {
            const { survey } = this.props
            this.setReadMode(survey)
            this.props.fetchSurveyAllExportData(id).then(() => {
                const { exportFullSurvey } = this.props
                this.setState({ exportFullSurvey, survey, dataLoaded: true })
            })
        })
    }

    setEditMode = () => {
        this.setState({ readMode: false })
        this.setActions({
            save: this.onSave,
            cancel: this.onCancel,
        })
    }

    setReadMode = (survey) => {
        this.setState({ readMode: true })
        const user = getUser()
        const deleteAction = (user?.isAdmin === '1') && survey.statusCode !== 1 ? {
            deleteCheck: this.deleteAction,
        } : {}
        const recalculateAction = (user?.isAdmin === '1' || getUser().metadata === '1') && survey.statusCode !== 3 ? {
            other: {
                other: (
                    <Other
                        className='clickable'
                        tooltip={i18n.recalculateVolumesPrevi}
                        icon='sync'
                        onClick={() => this.setState({ openRecalculDialog: true })}
                    />
                ),
            },
        } : {}
        const actions = user?.consultant === '1' ? {} : {
            edit: this.setEditMode,
            duplicate: () => this.duplicateAction(survey),
            ...recalculateAction,
            ...deleteAction,
        }
        this.setActions(actions)
    }

    deleteAction = () => {
        this.setState({ openDeleteWarningModal: true })
    }

    duplicateAction = (survey) => {
        this.setState({ openDuplicate: true, duplicateYear: survey?.year + 1 || null, duplicateName: `${survey?.name || ''} (${i18n.copy})` })
    }

    onSave = () => {
        const { survey } = this.state
        this.setState({ dataLoaded: false })
        this.props.updateSurvey(survey).then(() => {
            this.props.fetchSurvey(survey.idSurvey).then(() => {
                this.setState({ dataLoaded: true, survey: this.props.survey })
                this.setReadMode(this.props.survey)
            })
        })
    }

    onCancel = () => {
        this.setState({ survey: this.props.survey })
        this.setReadMode(this.props.survey)
    }

    setTitle = () => {
        const { params: { id } } = this.props
        const { survey } = this.props
        this.props.setTitle([{
            title: i18n.planning,
            href: 'planning',
        }, {
            title: i18n.surveys,
            href: 'planning/surveys',
        }, {
            title: `${survey.name || ''} [${survey.year || ''}]`,
            href: `survey/${id}`,
        }, {
            title: i18n.dashboard,
            href: `survey/${id}/dashboard`,
        }])
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.survey.idSurvey !== this.props.survey.idSurvey) {
            this.setState({ survey: this.props.survey })
        }
        this.setTitle()
    }

    onCloseDuplicateDialog = () => this.setState({ openDuplicate: false, duplicateYear: null, duplicateName: null })

    onDuplicate = () => {
        const { duplicateYear, duplicateName, duplicateStartDate, duplicateEndDate, duplicateAdminEndDate, survey } = this.state
        if (duplicateYear && duplicateStartDate && duplicateEndDate && duplicateAdminEndDate) {
            this.setState({ dataLoaded: false })
            this.props.duplicateSurvey({
                idSurvey: survey.idSurvey,
                newSurveyYear: duplicateYear,
                newSurveyName: duplicateName,
                startDate: duplicateStartDate,
                endDate: duplicateEndDate,
                adminEndDate: duplicateAdminEndDate,
            }).then(json => {
                this.onCloseDuplicateDialog()
                this.getSurvey(json.newIdSurvey)
                this.props.push(`/survey/${json.newIdSurvey}`)
            })
        } else {
            this.props.error(i18n.pleaseCompleteAllField)
        }
    }

    onDeleteSurvey = () => {
        const { survey } = this.state
        this.setState({ dataLoaded: false })
        this.props.deleteSurvey(survey.idSurvey).then(() => {
            this.props.push('/planning/surveys')
        })
    }

    getModalDuplicate = () => {
        const {
            openDuplicate,
            duplicateYear,
            duplicateName,
            duplicateStartDate,
            duplicateEndDate,
            duplicateAdminEndDate,
        } = this.state
        return (
            <Dialog
                fullWidth
                open={openDuplicate}
                maxWidth='md'
            >
                <DialogTitleMUI style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    {i18n.duplicateSurvey}
                    <Icon style={{ color: 'white' }} size='small' icon='close' onClick={this.onCloseDuplicateDialog} />
                </DialogTitleMUI>
                <DialogContentMUI>
                    <Grid style={{ height: '375px' }}>
                        <Row className='padding-top-1'>
                            <Input
                                col={6}
                                title={i18n.name}
                                value={duplicateName}
                                onChange={value => this.setState({ duplicateName: value })}
                                obligatory
                            />
                            <NumberField
                                col={6}
                                title={i18n.year}
                                value={duplicateYear}
                                onChange={value => this.setState({ duplicateYear: value })}
                                obligatory
                            />
                        </Row>
                        <Row>
                            <SimpleDatePicker
                                col={6}
                                label={i18n.startDate}
                                value={duplicateStartDate}
                                onChange={value => this.setState({ duplicateStartDate: value })}
                                max={duplicateEndDate}
                                obligatory
                            />
                            <SimpleDatePicker
                                col={6}
                                label={i18n.endDate}
                                value={duplicateEndDate}
                                onChange={value => this.setState({ duplicateEndDate: value })}
                                min={duplicateStartDate}
                                obligatory
                            />
                        </Row>
                        <Row>
                            <SimpleDatePicker
                                col={6}
                                label={i18n.adminEndDate}
                                value={duplicateAdminEndDate}
                                onChange={value => this.setState({ duplicateAdminEndDate: value })}
                                obligatory
                            />
                        </Row>
                    </Grid>
                </DialogContentMUI>
                <DialogActionsMUI>
                    <Button onClick={this.onDuplicate} variant='contained' color='primary'>
                        {i18n.duplicate}
                    </Button>
                </DialogActionsMUI>
            </Dialog>
        )
    }

    getModalWarningRecalcul = () => {
        const { survey } = this.props
        const { openRecalculDialog } = this.state

        return (
            <Dialog
                fullWidth
                maxWidth='md'
                open={openRecalculDialog}
            >
                <DialogTitleMUI style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Grid container alignItems='center'>
                        <Icon style={{ fontSize: '2.5rem', marginRight: 8 }}>warning</Icon>
                        <Grid item>{i18n.recalculateVolumesPrevi}</Grid>
                    </Grid>
                    <Icon style={{ color: 'white' }} size='small' icon='close' onClick={() => this.setState({ openRecalculDialog: false })} />
                </DialogTitleMUI>
                <DialogContentMUI className='padding-top-2 padding-bottom-2'>
                    <span id='modal-text' className='bold'>
                        {i18n.sureRecalculVolumes}
                    </span>
                </DialogContentMUI>
                <DialogActionsMUI style={{ justifyContent: 'space-between' }}>
                    <div />
                    <h5 id='modal-description' className='bold' style={{ color: 'red' }}>{i18n.irreversibleAction}</h5>
                    <Button
                        variant='contained'
                        className='red'
                        style={{ color: 'white' }}
                        onClick={() => this.props.recalculateVolumesSurvey(survey.idSurvey).then(() => this.setState({ openRecalculDialog: false }))}
                    >
                        {i18n.confirm}
                    </Button>
                </DialogActionsMUI>
            </Dialog>
        )
    }

    render = () => {
        const {
            dataLoaded,
            readMode,
            survey,
            exportFullSurvey,
            openDuplicate,
            openDeleteWarningModal,
        } = this.state

        return (
            <Row style={{ paddingBottom: 80 }}>
                {dataLoaded ? (
                    <div className='padding-1'>
                        <Grid container spacing={1} justifyContent='stretch'>
                            <Grid item xs={9}>
                                <Card title={i18n.generalInfos} round cardStyle={{ height: '100%' }}>
                                    <FormSurvey
                                        survey={survey}
                                        readMode={readMode}
                                        onChangeSurvey={obj => this.setState({ survey: { ...survey, ...obj } })}
                                    />
                                </Card>
                            </Grid>
                            <Grid item xs={3} container direction='column' spacing={1}>
                                <Grid item>
                                    <LastUpdateCard
                                        className='no-margin'
                                        element={survey}
                                        withHour
                                        round
                                    />
                                </Grid>
                                <Grid item>
                                    <Card title={i18n.declarationsProgress} round>
                                        <ProgressSurveyChart
                                            survey={survey}
                                        />
                                    </Card>
                                </Grid>
                            </Grid>
                        </Grid>
                        <div className='col s12 no-padding'>
                            <SurveyDeclarationsPanel
                                readMode={readMode}
                                survey={survey}
                                exportFullSurvey={exportFullSurvey}
                                onChange={value => this.setState(value)}
                                actualiseSelfAssign={(id, links) => {
                                    this.props.fetchSurvey(id).then(() => {
                                        this.setState({ survey: { ...this.props.survey, link_declarations: links } })
                                    })
                                }}
                                params={this.props.params}
                            />
                        </div>
                        {openDuplicate && this.getModalDuplicate()}
                        {openDeleteWarningModal && (
                            <ModalWarningDeleteSurvey
                                open={openDeleteWarningModal}
                                onCancel={() => this.setState({ openDeleteWarningModal: false })}
                                onDelete={this.onDeleteSurvey}
                            />
                        )}
                    </div>
                ) : (
                    <div className='padding-1'>
                        <ProgressCard indeterminate round />
                    </div>
                )}
                {this.getModalWarningRecalcul()}
            </Row >
        )
    }
}

SurveyDashboardApp.propTypes = {
    params: PropTypes.shape({
        id: PropTypes.string,
    }),
    exploitations: arrayOf(DtoExploitation),
    survey: PropTypes.instanceOf(DtoSurvey),
    contributors: arrayOf(ContributorDto),
    sandreCodes: arrayOf(DtoSandreCode),
    recalculateVolumesSurvey: PropTypes.func,
    fetchSurveyAllExportData: PropTypes.func,
    error: PropTypes.func,
    setTitle: PropTypes.func,
    exportFullSurvey: arrayOf(DtoDeclarationExportFull),
}

const mapStateToProps = store => ({
    survey: store.AgriReducer.survey,
    exploitations: store.AgriReducer.exploitations,
    sandreCodes: store.ReferencialReducer.sandreCodes,
    contributors: store.ContributorReducer.contributors,
    cities: store.CityReducer.cities,
    exportFullSurvey: store.AgriReducer.exportFullSurvey,
})

const mapDispatchToProps = {
    push,
    setTitle: HomeAction.setTitle,
    updateSurvey: AgriAction.updateSurvey,
    recalculateVolumesSurvey: AgriAction.recalculateVolumesSurvey,
    fetchSurvey: AgriAction.fetchSurvey,
    fetchSurveyAllExportData: AgriAction.fetchSurveyAllExportData,
    duplicateSurvey: AgriAction.duplicateSurvey,
    deleteSurvey: AgriAction.deleteSurvey,
    error: ToastrAction.error,
}

export default connect(mapStateToProps, mapDispatchToProps)(SurveyDashboardApp)
