/* eslint-disable camelcase */
import React from 'react'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import { connect } from 'react-redux'
import { arrayOf, getSandreList } from '../../../../utils/StoreUtils'
import DtoSandreCode from '../../../../referencial/dto/DtoSandreCode'
import { SANDRE } from '../../../../referencial/constants/ReferencialConstants'
import SieauAction from '../../../../components/sieau/SieauAction'
import Row from '../../../../components/react/Row'
import NumberField from '../../../../components/forms/NumberField'
import DtoLiftingStation from '../dto/DtoLiftingStation'
import Select from '../../../../components/forms/Select'
import { getVisitSandreSelectChange } from '../../../../utils/VisitUtils'

const LiftingEquipmentsPanel = ({
    onChange = () => { },
    onChangeVisit = () => { },
    lifting = {},
    readMode = false,
    sandreCodes = [],
}) => {
    const onChangeEquipments = (value) => {
        onChange({
            link_equipments: [
                {
                    idStation: lifting.idStation,
                    ...lifting.link_equipments[0],
                    ...value,
                },
            ],
        })
    }

    const equipments = lifting.link_equipments[0] || {}

    return (
        <Row className='padding-top-1 padding-bottom-1'>
            <Row>
                <NumberField
                    col={ 6 }
                    value={ equipments.numberPumps }
                    title={ i18n.numberPumps }
                    onChange={ (v) => {
                        onChangeEquipments({ numberPumps: v })
                        onChangeVisit({ previousValue: equipments.numberPumps, newValue: v, field: i18n.numberPumps })
                    } }
                    readMode={ readMode }
                />
                <NumberField
                    col={ 6 }
                    value={ equipments.nominalFlow }
                    title={ i18n.nominalFlow }
                    onChange={ (v) => {
                        onChangeEquipments({ nominalFlow: v })
                        onChangeVisit({ previousValue: equipments.nominalFlow, newValue: v, field: i18n.nominalFlow })
                    } }
                    readMode={ readMode }
                />
            </Row>
            <Row>
                <NumberField
                    col={ 6 }
                    value={ equipments.hmt }
                    title={ i18n.hmt }
                    onChange={ (v) => {
                        onChangeEquipments({ hmt: v })
                        onChangeVisit({ previousValue: equipments.hmt, newValue: v, field: i18n.hmt })
                    } }
                    floatValue
                    readMode={ readMode }
                />
            </Row>
            <hr />
            <Row>
                <Select
                    col={ 6 }
                    value={ equipments.typeDetectionLevel }
                    label={ i18n.typeDetectionLevel }
                    keyValue='code'
                    options={ getSandreList(sandreCodes, SANDRE.INSTALLATIONS_TYPEDETECTION) }
                    onChange={ (v) => {
                        onChangeEquipments({ typeDetectionLevel: v })
                        onChangeVisit(getVisitSandreSelectChange(sandreCodes, SANDRE.INSTALLATIONS_TYPEDETECTION, i18n.typeDetectionLevel, equipments.typeDetectionLevel, v))
                    } }
                    integerValue
                    readMode={ readMode }
                />
            </Row>
        </Row>
    )
}

LiftingEquipmentsPanel.propTypes = {
    lifting: PropTypes.instanceOf(DtoLiftingStation),
    sandreCodes: arrayOf(DtoSandreCode),
    readMode: PropTypes.bool,
    onChange: PropTypes.func,
    onChangeVisit: PropTypes.func,
    setPopup: PropTypes.func,
}

const mapStateToProps = (store) => ({
    sandreCodes: store.ReferencialReducer.sandreCodes,
})

const mapDispatchTopProps = {
    setPopup: SieauAction.setPopup,
}

export default connect(mapStateToProps, mapDispatchTopProps)(LiftingEquipmentsPanel)
