export default class DtoHydrometryDataTypeSituation {
    constructor(obj = {}) {
        this.hydroId = obj.hydroId
        this.startDate = obj.startDate
        this.lastMeasureDate = obj.lastMeasureDate
        this.lastMeasureHour = obj.lastMeasureHour
        this.min = obj.min
        this.max = obj.max
        this.value = obj.value
        this.measuresCount = obj.measuresCount
        this.allMeasuresCount = obj.allMeasuresCount
        this.dataTypeId = obj.dataTypeId
    }
}