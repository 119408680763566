import i18n from "simple-react-i18n";
import MessageCard from "../../../../components/card/MessageCard";
import React from "react";

const GraphicValidationPluvioMode =  ({}) => {
    return <MessageCard>{i18n.inDeveloppmentFunctionnality}</MessageCard>
}

GraphicValidationPluvioMode.propTypes = {
}

export default GraphicValidationPluvioMode
