/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { AutoSizer, MultiGrid, ScrollSync } from 'react-virtualized'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import { sieauTooltip } from 'utils/FormUtils'
import { orderBy } from 'lodash'

const ASC = 'asc'
const DESC = 'desc'
const NONE = 'none'

const STYLE = {
    border: '1px solid #ddd',
    backgroundColor: 'fff',
}
const STYLE_BOTTOM_LEFT_GRID = {
    borderRight: '1px solid #000',
    backgroundColor: '#fff',
}
const STYLE_TOP_LEFT_GRID = {
    backgroundColor: '#fff',
    borderBottom: '1px solid #000',
    borderRight: '1px solid #000',
    fontWeight: 'bold',
    fontSize: '16px',
}
const STYLE_TOP_RIGHT_GRID = {
    backgroundColor: '#fff',
    borderBottom: '1px solid #000',
    fontWeight: 'bold',
    fontSize: '16px',
}

const DEFAULT_CELL_STYLE = {
    display: 'flex',
    alignItems: 'center',
    borderRight: '1px solid #eee',
    backgroundColor: '#fff',
    whiteSpace: 'nowrap',
    padding: '0px 5px',
}

const getHeaderLabel = (keyLabel, value, rowIndex) => {
    if (value) {
        return value
    }
    if (rowIndex !== 0) {
        return ''
    }
    if (!/^\d+$/.test(keyLabel) && /^[0-9A-Za-z]+$/.test(keyLabel)) {
        try {
            return i18n[keyLabel]
        } catch (e) {
            return keyLabel
        }
    }
    return keyLabel
}

const MultiGridTableV2 = ({
    data = [],
    customHeaders = {},
    headers = [],

    fixedColumnCount = 0,
    fixedRowCount = 0,

    onClick = () => { },

    bodyHeight = '70%',
    headerHeight = 29,
    rowHeight = 19,
    columnWidth = 150,
    overscanColumnCount = 5,
    overscanRowCount = 10,
}) => {
    const [sort, setSort] = useState(() => ({ sortColumn: [], sortDirection: {} }))

    // used to sort data
    let tableRef = useRef().current
    let headerRef = useRef().current

    const sortedData = useMemo(() => {
        const {
            sortColumn,
            sortDirection,
        } = sort
        return orderBy(data, sortColumn.map(c => elem => elem[c]?.sortValue ?? elem[c]?.value), sortColumn.map(c => sortDirection[c]))
    }, [data, sort])

    useEffect(() => {
        tableRef?.forceUpdateGrids()
        headerRef?.forceUpdateGrids()
    }, [sortedData])

    // margin to fix some issue with the display with some component
    // height is bug sometime, bodyHeight + headerHeight = parentHeight
    return (
        <div style={{ height: bodyHeight, margin: '0 1 31 0' }}>
            <ScrollSync>
                {
                    ({ onScroll, scrollLeft, scrollTop }) => (
                        <AutoSizer>
                            {
                                ({ width, height }) => !!sortedData.length && (
                                    <div>
                                        <div
                                            style={{
                                                height: headerHeight,
                                                width,
                                            }}
                                        >
                                            <MultiGrid
                                                fixedColumnCount={fixedColumnCount}
                                                fixedRowCount={1}

                                                columnWidth={columnWidth}
                                                columnCount={headers.length}
                                                height={headerHeight}
                                                style={{ ...STYLE }}
                                                overscanColumnCount={overscanColumnCount}

                                                // enableFixedColumnScroll
                                                // enableFixedRowScroll
                                                // hideTopRightGridScrollbar
                                                // hideBottomLeftGridScrollbar

                                                // styleBottomLeftGrid={STYLE_BOTTOM_LEFT_GRID}
                                                styleTopLeftGrid={STYLE_TOP_LEFT_GRID}
                                                styleTopRightGrid={STYLE_TOP_RIGHT_GRID}

                                                cellRenderer={({ columnIndex, key, rowIndex, style }) => {
                                                    const keyLabel = headers[columnIndex]

                                                    const {
                                                        value,
                                                        tooltip,
                                                        style: customStyle = {},
                                                    } = customHeaders[keyLabel] || {}
                                                    const tooltipObj = tooltip ? sieauTooltip(tooltip, null, 'top') : {}

                                                    const headerStyle = {
                                                        ...DEFAULT_CELL_STYLE,
                                                        ...style,
                                                        ...customStyle,
                                                        borderBottom: '1px solid #000',
                                                        justifyContent: 'center',
                                                        fontWeight: 'bold',
                                                        fontSize: '16px',
                                                    }

                                                    return (
                                                        <div
                                                            className={'white clickable'}
                                                            style={headerStyle}
                                                            key={key}
                                                            onClick={() => {
                                                                setSort(({ sortDirection, sortColumn }) => {
                                                                    switch (sortDirection[keyLabel]) {
                                                                        case ASC: return { sortDirection: { ...sortDirection, [keyLabel]: DESC }, sortColumn }
                                                                        case DESC: return { sortDirection: { ...sortDirection, [keyLabel]: NONE }, sortColumn: sortColumn.filter(c => c !== keyLabel) }
                                                                        default: return { sortDirection: { ...sortDirection, [keyLabel]: ASC }, sortColumn: [...sortColumn, keyLabel] }
                                                                    }
                                                                })
                                                            }}
                                                            {...tooltipObj}
                                                        >
                                                            {
                                                                rowIndex === 0 && sort.sortDirection[keyLabel] === ASC && <i className='material-icons' style={{ fontSize: 18 }}>arrow_drop_up</i>
                                                            }
                                                            {
                                                                rowIndex === 0 && sort.sortDirection[keyLabel] === DESC && <i className='material-icons' style={{ fontSize: 18 }}>arrow_drop_down</i>
                                                            }
                                                            {getHeaderLabel(keyLabel, value, rowIndex)}
                                                        </div>
                                                    )
                                                }}
                                                rowHeight={headerHeight}
                                                rowCount={1}
                                                scrollLeft={scrollLeft}
                                                width={width}

                                                ref={ref => {
                                                    headerRef = ref
                                                }}
                                            />
                                        </div>
                                        <div
                                            style={{
                                                height,
                                                width,
                                            }}
                                        >
                                            <MultiGrid
                                                fixedColumnCount={fixedColumnCount}
                                                fixedRowCount={fixedRowCount}

                                                columnWidth={columnWidth}
                                                columnCount={headers.length}
                                                height={height}
                                                onScroll={onScroll}
                                                overscanColumnCount={overscanColumnCount}
                                                overscanRowCount={overscanRowCount}

                                                enableFixedColumnScroll
                                                enableFixedRowScroll
                                                hideTopRightGridScrollbar
                                                hideBottomLeftGridScrollbar

                                                style={STYLE}
                                                styleBottomLeftGrid={STYLE_BOTTOM_LEFT_GRID}
                                                styleTopLeftGrid={STYLE_TOP_LEFT_GRID}
                                                styleTopRightGrid={STYLE_TOP_RIGHT_GRID}

                                                cellRenderer={({ columnIndex, key, rowIndex, style }) => {
                                                    const keyLabel = headers[columnIndex]
                                                    const row = sortedData[rowIndex] || {}
                                                    const {
                                                        [keyLabel]: cell = {},
                                                    } = row

                                                    const {
                                                        value = '',
                                                        tooltip,
                                                        justifyContent = 'left',
                                                        classNameColor = 'white',
                                                        rightIcon,
                                                        rightIconSize = '1rem',
                                                        rightIconColor = 'grey',
                                                        onClick: onClickCell,
                                                    } = cell

                                                    const tooltipObj = tooltip ? sieauTooltip(tooltip, null, 'top') : {}
                                                    const cellStyle = {
                                                        ...style,
                                                        ...DEFAULT_CELL_STYLE,
                                                        justifyContent,
                                                        borderBottom: '1px solid #eee',
                                                    }

                                                    return (
                                                        <div className={`${classNameColor} clickable`} key={key} style={cellStyle} onClick={() => onClickCell ? onClickCell() : onClick(row)} {...tooltipObj}>
                                                            {value}
                                                            {!!rightIcon && <i className='material-icons' style={{ fontSize: rightIconSize, color: rightIconColor }}>{rightIcon}</i>}
                                                        </div>
                                                    )
                                                }}
                                                rowHeight={rowHeight}
                                                rowCount={sortedData.length}
                                                scrollTop={scrollTop}
                                                scrollLeft={scrollLeft}
                                                width={width}

                                                ref={ref => {
                                                    tableRef = ref
                                                }}
                                            />
                                        </div>
                                    </div>
                                )
                            }
                        </AutoSizer>
                    )
                }
            </ScrollSync>
        </div>
    )
}

MultiGridTableV2.propTypes = {
    data: PropTypes.array.isRequired,
    // data: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.shape({
    //     value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.element]),
    //     sortValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    //     classNameColor: PropTypes.string,
    //     tooltip: PropTypes.oneOfType([PropTypes.func, PropTypes.string, PropTypes.element]),
    //     justifyContent: PropTypes.string,
    //     rightIcon: PropTypes.string,
    //     rightIconSize: PropTypes.string,
    //     rightIconColor: PropTypes.string,
    //     style: PropTypes.object,
    // }))).isRequired,
    headers: PropTypes.arrayOf(PropTypes.string).isRequired,
    customHeaders: PropTypes.object,
    // customHeaders: PropTypes.objectOf(PropTypes.shape({
    //     value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.element]),
    //     tooltip: PropTypes.oneOfType([PropTypes.func, PropTypes.string, PropTypes.element]),
    //     style: PropTypes.object,
    // })).isRequired,

    fixedColumnCount: PropTypes.number,
    fixedRowCount: PropTypes.number,
    onClick: PropTypes.func,

    bodyHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    headerHeight: PropTypes.number,
    rowHeight: PropTypes.number,
    columnWidth: PropTypes.number,
    overscanColumnCount: PropTypes.number,
    overscanRowCount: PropTypes.number,
}

export default MultiGridTableV2