import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import { v4 as uuidv4 } from 'uuid'
import ColorfulCard from '../../components/card/ColorfulCard'
import { ORANGE } from '../../components/constants/ColorConstant'
import DatePicker from '../../components/forms/DatePicker'
import SieauAction from '../../components/sieau/SieauAction'
import EventsAction from '../../events/actions/EventsAction'
import AppStore from '../../store/AppStore'
import { getLabel, getMapStateToProps, getPropTypes } from '../../utils/StoreUtils'
import MonitoredStationEventDto from '../dto/MonitoredStationEventDto'

const storeProps = {
    cities: true,
}

class MonitoredStationCard extends Component {
    constructor(props) {
        super(props)
        AppStore.dispatch(SieauAction.fetch(storeProps))
        this.state = {
            closeDate: Date.now(),
        }
    }

    updateEvent = () => {
        const station = this.props.station
        AppStore.dispatch(EventsAction.updateEvent(station.type, station.id, station.eventId, this.state, this.refreshStationList))
    }

    refreshStationList = () => AppStore.dispatch(EventsAction.fetchMonitoredStations())

    openPopup = station => () => {
        const id = uuidv4()
        const popup = {
            id,
            header: i18n.eventMonitored,
            actions: (
                <div>
                    <a className='waves-effect waves-teal btn-flat modal-close'>{ i18n.close }</a>
                    <a className='waves-effect waves-teal btn-flat modal-close btn font-white' onClick={ this.updateEvent }>{ i18n.validate }</a>
                </div>
            ),
            content: (
                <div className='padding-bottom-5'>
                    <div className='row no-margin padding-top-3'>
                        <div className='row'>
                            {
                                station.eventProblem && (<h6><b>{ i18n.problem } :</b> { station.eventProblem }</h6>)
                            }
                            {
                                station.eventSolution && (<h6><b>{ i18n.solution } :</b> { station.eventSolution }</h6>)
                            }
                            {
                                station.eventComment && (<h6><b>{ i18n.comment } :</b> { station.eventComment }</h6>)
                            }
                        </div>
                        <fieldset>
                            <legend>&nbsp;{ i18n.closeTheEvent }&nbsp;</legend>
                            <div className='col s4 offset-s4 no-padding margin-top-3 margin-bottom-3'>
                                <DatePicker onChange={ (i, closeDate) => this.setState({ closeDate }) } id='end-date'
                                    title={ i18n.endDate }
                                    col={ 12 }
                                    value={ this.state.closeDate }
                                    startDate={ station.eventDate }
                                />
                            </div>
                        </fieldset>
                    </div>
                </div>
            ),
        }
        AppStore.dispatch(SieauAction.setPopup(popup))
    }

    render() {
        const { station, onClickEnable } = this.props
        const onClickCard = onClickEnable ? { onClick: this.openPopup(station) } : {}
        return (
            <ColorfulCard color={ORANGE} { ...onClickCard }>
                <div className='row no-margin valign-wrapper padding-top-1 padding-bottom-1 padding-left-1'>
                    <div className='col s2'>
                        <p className='collection-title'>{ station.code } { station.designation ? ` /${station.designation}` : '' }</p>
                    </div>
                    <div className='col s2'>
                        <p className='collection-title'>{ getLabel(this.props.cities, station.townCode, undefined, undefined, station.townCode) }</p>
                    </div>
                    <div className='col s4'>
                        <p className='collection-title'>{ station.name }</p>
                    </div>
                    <div className='col s4'>
                        <p className='collection-title'>{ station.eventProblem || station.eventSolution || station.eventComment }</p>
                    </div>
                </div>
            </ColorfulCard>
        )
    }
}

MonitoredStationCard.propTypes = getPropTypes(storeProps, {
    station: PropTypes.instanceOf(MonitoredStationEventDto),
    onClickEnable: PropTypes.bool,
})

MonitoredStationCard.defaultProps = {
    onClickEnable: true,
}

const mapStateToProps = () => getMapStateToProps(storeProps)

export default connect(mapStateToProps)(MonitoredStationCard)
