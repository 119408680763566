module.exports = {
    AUTO: 'AUTO',
    ALL: 'all',
    CUMUL: 'CUMUL',

    DAY: 'DAY',
    HOUR: 'HOUR',

    CUMUL_PERSO_MAX: 'CUMUL_PERSO_MAX',
    CUMUL_PERSO_MIN: 'CUMUL_PERSO_MIN',
    CUMUL_PERSO_SUM: 'CUMUL_PERSO_SUM',
    CUMUL_PERSO_AVERAGE: 'CUMUL_PERSO_AVERAGE',
}