import DtoMaterielAssignment from './DtoMaterielAssignment'

export default class DtoStationTelecomAssignment extends DtoMaterielAssignment {
    constructor(obj) {
        super(obj)
        this.id = obj.idTelecomSituation
        this.idTelecomSituation = obj.idTelecomSituation
        this.idTelecom = obj.idTelecom
        this.materielId = obj.idTelecom
    }
}