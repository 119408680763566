import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import Icon from '../../../../components/icon/Icon'
import CentralDto from '../../../../materiel/components/central/dto/CentralDto'
import CentralTypeDto from '../../../../materiel/components/central/dto/CentralTypeDto'
import EquipmentDto from '../../../../materiel/components/equipment/dto/EquipmentDto'
import EquipmentTypeDto from '../../../../materiel/components/equipment/dto/EquipmentTypeDto'
import PowerSupplyDto from '../../../../materiel/components/powerSupply/dto/PowerSupplyDto'
import PowerSupplyTypeDto from '../../../../materiel/components/powerSupply/dto/PowerSupplyTypeDto'
import SensorTypeDto from '../../../../materiel/components/sensor/dto/DtoSensorType'
import SensorDto from '../../../../materiel/components/sensor/dto/SensorDto'
import SimTypeDto from '../../../../materiel/components/sim/dto/DtoSimType'
import SimDto from '../../../../materiel/components/sim/dto/SimDto'
import SimSubscriptionDto from '../../../../materiel/components/sim/dto/SimSubscriptionDto'
import SubscriptionDto from '../../../../materiel/components/subscription/dto/SubscriptionDto'
import SubscriptionTypeDto from '../../../../materiel/components/subscription/dto/SubscriptionTypeDto'
import TelecomDto from '../../../../materiel/components/telecom/dto/TelecomDto'
import TelecomTypeDto from '../../../../materiel/components/telecom/dto/TelecomTypeDto'
import VariousMaterielTypeDto from '../../../../materiel/components/variousMateriel/dto/DtoVariousMaterielType'
import VariousMaterielDto from '../../../../materiel/components/variousMateriel/dto/VariousMaterielDto'
import DtoMaterielState from '../../../../materiel/dto/DtoMaterielState'
import { sieauTooltip } from '../../../../utils/FormUtils'
import { getMaterielIconValue, getMaterielPlural } from '../../../../utils/MaterielUtils'


class MaterielCard extends Component {
    componentDidMount = () => $('.tooltipped').tooltip()
    componentDidUpdate = () => $('.tooltipped').tooltip()
    componentWillUnmount = () => $('.tooltipped').tooltip('remove')

    getLabelSimAssociated = idSub => {
        const simSubscription = this.props.simSubscriptionsLastSituations.find(({ idSubscription }) => idSubscription === idSub)
        if (simSubscription) {
            const sim = this.props.sims.find(({ id }) => id === simSubscription.idSim)
            if (sim) {
                const type = this.props.simTypes.find(({ materielType }) => materielType === sim.materielType)
                return `${type ? `${type.name}: ` : ''} ${sim.serialNumber}`
            }
        }
        return ''
    }

    render = () => {
        const {
            newSituations = [],
            materielCategory = '',
            materielTypeName = '',
            materiel = undefined,
            situationDate = '',
            actions = {},
            undoMateriel = () => { },
        } = this.props

        const {
            remove,
            replace,
            add,
            cancel,
        } = actions

        const newSitCurrentMat = materiel ? newSituations.find(s => s.materielCategory === materielCategory && s.materielId === materiel.id) : undefined
        const newSitNextMat = materiel ? newSituations.find(s => s.materielCategory === materielCategory && s.previousMateriel === materiel.id) : undefined
        const newMateriel = newSitNextMat ? this.props[getMaterielPlural(materielCategory)].find(m => m.id === newSitNextMat.materielId) : null
        const newMaterielType = newMateriel && newMateriel.materielType ? this.props[`${materielCategory}Types`].find(t => t.materielType === newMateriel.materielType) : null

        const iconStyle = { fontSize: '3rem' }
        return (
            <div className='card no-margin' >
                <div className='card-content no-margin no-padding'>
                    <div className='row col s12 valign-wrapper margin-bottom-1 padding-top-1 padding-bottom-1'>
                        <div className='no-margin col s1 clickable'>
                            <i {...sieauTooltip(i18n[materielCategory])} className={'material-icons medium'} style={iconStyle}>
                                { getMaterielIconValue(materielCategory) }
                            </i>
                        </div>
                        <div className='no-margin col s9'>
                            <div className='no-margin col s12'>
                                <h6 className='col s6'>{materiel ? `${materielTypeName ? `${materielTypeName} -` : ''} ${materiel.numLine || materiel.imei || materiel.serialNumber || ''}` : i18n.noMateriel}</h6>
                                <h6 className='col s6'>{materiel && situationDate ? `${i18n.onSiteSince} ${situationDate}` : ''}</h6>
                                {
                                    materielCategory === 'subscription' && (
                                        <h6 className='col s12'>{this.getLabelSimAssociated(materiel.id)}</h6>
                                    )
                                }
                            </div>
                            {
                                newSitCurrentMat && newSitCurrentMat.statusCode !== 1 && (
                                    <div className='no-margin col s12'>
                                        <div className='col s6'>
                                            <div className='no-margin valign-wrapper' style={{ color: 'red' }}>
                                                <Icon icon='clear' />
                                                <h6>{(this.props.materielStates.find(matState => matState.code === newSitCurrentMat.statusCode) || {}).label}</h6>
                                            </div>
                                        </div>
                                        {
                                            newMateriel && (
                                                <div className='col s6'>
                                                    <div className='no-margin valign-wrapper' style={{ color: 'dodgerBlue' }}>
                                                        <Icon icon='arrow_forward' />
                                                        <h6>{`${newMaterielType ? `${newMaterielType.name} - ` : ''}${newMateriel.numLine || newMateriel.imei || newMateriel.serialNumber || ''}`}</h6>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    </div>
                                )
                            }
                        </div>
                        <div className='no-margin no-padding col s2'>
                            {
                                add && (
                                    <div className='col s6 clickable' onClick={() => {
                                        $('.tooltipped').tooltip('remove')
                                        add(materielCategory)
                                    }}
                                    >
                                        <i className=' material-icons tooltipped' {...sieauTooltip(i18n.add)} style={iconStyle}>note_add</i>
                                    </div>
                                )
                            }
                            {
                                !newSitCurrentMat && replace && (
                                    <div className='col s6 clickable' onClick={() => {
                                        $('.tooltipped').tooltip('remove')
                                        replace(materielCategory)
                                    }}
                                    >
                                        <i className=' material-icons tooltipped' {...sieauTooltip(i18n.replace)} style={iconStyle}>compare_arrows</i>
                                    </div>
                                )
                            }
                            {
                                !newSitCurrentMat && remove && (
                                    <div className='col s6 clickable' onClick={() => {
                                        $('.tooltipped').tooltip('remove')
                                        remove(materielCategory)
                                    }}
                                    >
                                        <i className=' material-icons tooltipped' {...sieauTooltip(i18n.remove)} style={iconStyle}>clear</i>
                                    </div>
                                )
                            }
                            {
                                newSitCurrentMat && (
                                    <div className='col s6 clickable' onClick={() => {
                                        $('.tooltipped').tooltip('remove')
                                        if (cancel) {
                                            cancel()
                                        } else {
                                            undoMateriel(materielCategory, newSitCurrentMat, newSitNextMat)
                                        }
                                    }}
                                    >
                                        <i className=' material-icons tooltipped' data-tooltip={i18n.cancel} style={iconStyle}>clear</i>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

MaterielCard.propTypes = {
    newSituations: PropTypes.arrayOf(PropTypes.object),
    materielCategory: PropTypes.string,
    materielTypeName: PropTypes.string,
    materiel: PropTypes.object,
    situationDate: PropTypes.string,
    actions: PropTypes.object,
    undoMateriel: PropTypes.func,

    centrals: PropTypes.arrayOf(PropTypes.instanceOf(CentralDto)),
    centralTypes: PropTypes.arrayOf(PropTypes.instanceOf(CentralTypeDto)),
    powerSupplies: PropTypes.arrayOf(PropTypes.instanceOf(PowerSupplyDto)),
    powerSupplyTypes: PropTypes.arrayOf(PropTypes.instanceOf(PowerSupplyTypeDto)),
    sensors: PropTypes.arrayOf(PropTypes.instanceOf(SensorDto)),
    sensorTypes: PropTypes.arrayOf(PropTypes.instanceOf(SensorTypeDto)),
    sims: PropTypes.arrayOf(PropTypes.instanceOf(SimDto)),
    simTypes: PropTypes.arrayOf(PropTypes.instanceOf(SimTypeDto)),
    telecoms: PropTypes.arrayOf(PropTypes.instanceOf(TelecomDto)),
    telecomTypes: PropTypes.arrayOf(PropTypes.instanceOf(TelecomTypeDto)),
    variousMateriels: PropTypes.arrayOf(PropTypes.instanceOf(VariousMaterielDto)),
    variousMaterielTypes: PropTypes.arrayOf(PropTypes.instanceOf(VariousMaterielTypeDto)),
    equipments: PropTypes.arrayOf(PropTypes.instanceOf(EquipmentDto)),
    equipmentTypes: PropTypes.arrayOf(PropTypes.instanceOf(EquipmentTypeDto)),
    subscriptions: PropTypes.arrayOf(PropTypes.instanceOf(SubscriptionDto)),
    subscriptionTypes: PropTypes.arrayOf(PropTypes.instanceOf(SubscriptionTypeDto)),
    simSubscriptionsLastSituations: PropTypes.arrayOf(PropTypes.instanceOf(SimSubscriptionDto)),
    materielStates: PropTypes.arrayOf(PropTypes.instanceOf(DtoMaterielState)),
}

const mapStateToProps = store => ({
    centrals: store.CentralReducer.centrals,
    centralTypes: store.CentralReducer.centralTypes,
    powerSupplies: store.PowerSupplyReducer.powerSupplies,
    powerSupplyTypes: store.PowerSupplyReducer.powerSupplyTypes,
    sensors: store.SensorReducer.sensors,
    sensorTypes: store.SensorReducer.sensorTypes,
    sims: store.SimReducer.sims,
    simTypes: store.SimReducer.simTypes,
    telecoms: store.TelecomReducer.telecoms,
    telecomTypes: store.TelecomReducer.telecomTypes,
    variousMateriels: store.VariousMaterielReducer.variousMateriels,
    variousMaterielTypes: store.VariousMaterielReducer.variousMaterielTypes,
    equipments: store.EquipmentReducer.equipments,
    equipmentTypes: store.EquipmentReducer.equipmentTypes,
    subscriptions: store.SubscriptionReducer.subscriptions,
    subscriptionTypes: store.SubscriptionReducer.subscriptionTypes,
    simSubscriptionsLastSituations: store.SubscriptionReducer.simSubscriptionsLastSituations,
    materielStates: store.MaterielReducer.materielStates,
})

export default connect(mapStateToProps)(MaterielCard)