/* eslint-disable consistent-return */
import { groupBy, isUndefined, uniqBy } from 'lodash'
import React, { useEffect, useMemo, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import Card from '../../../components/card/Card'
import { MEASURE_COTE } from '../../../piezometry/constants/PiezometryConstants'
import PiezometerStationAction from '../../../station/actions/PiezometerStationAction'
import { calculateValue, getRemarks } from '../../../utils/AnalyseUtils'
import { getDate } from '../../../utils/DateUtil'
import useListIndexed from 'utils/customHook/useListIndexed'
import AnalysisAction from 'quality/actions/AnalysisAction'
import DtoAnalysisLight from 'quality/dto/analyse/DtoAnalysisLight'
import { DAY } from 'quality/constants/ChartConstant'
import QualityChart from 'components/echart/QualityChart'

const IndicatorsChart = ({

}) => {
    const dispatch = useDispatch()
    const {
        qualitometer,
        qualitometers,
        parameters,
        units,
        qualityIndicators,
        associatedSites,
    } = useSelector(store => ({
        qualitometer: store.QualityReducer.qualitometer,
        qualitometers: store.QualityReducer.qualitometersLight,
        parameters: store.ParameterReducer.parameters,
        units: store.UnitReducer.units,
        qualityIndicators: store.QualityReducer.qualityIndicators,
        associatedSites: store.StationReducer.associatedSites,
    }), shallowEqual)

    const unitsIndex = useListIndexed(units, 'code')
    const parametersIndex = useListIndexed(parameters, 'code')
    const qualitometersIndex = useListIndexed(qualitometers, 'id')

    const indicator = useMemo(() => {
        return qualityIndicators.find(qi => `${qi.stationType}` === qualitometer.stationType)
    }, [qualitometer.stationType, qualityIndicators])


    const orderedParameters = useMemo(() => indicator?.indicators?.map(i => `${i.id}`) ?? [], [indicator?.indicators])

    const [analysis, setAnalysis] = useState([])

    useEffect(() => {
        if (!isUndefined(qualitometer.id) && !orderedParameters.length) {
            return
        }
        setAnalysis(list => list.filter(a => qualitometer.id !== a.qualitometer))
        const filter = {
            lightMode: true,
            stations: [qualitometer.id],
            parameters: orderedParameters,
        }
        AnalysisAction.getAnalysis(filter).then((list = []) => setAnalysis(prevList => [
            ...prevList,
            ...list.map(a => new DtoAnalysisLight(a)),
        ]))
    }, [dispatch, orderedParameters, qualitometer.id])

    useEffect(() => {
        const listId = associatedSites.filter(a => a.stationLinkedType === 3).map(site => site.stationLinkedId)
        if (!listId.length && !orderedParameters.length) {
            return
        }

        setAnalysis([])

        const controller = new AbortController()

        const filter = {
            lightMode: true,
            stations: listId,
            parameters: orderedParameters,
        }
        AnalysisAction.getAnalysis(filter, controller.signal).then((list = []) => setAnalysis(prevList => [
            ...prevList,
            ...list.map(a => new DtoAnalysisLight(a)),
        ]))

        return () => {
            controller?.abort()
        }
    }, [associatedSites, dispatch, orderedParameters])

    const [piezoMeasures, setPiezoMeasures] = useState([])
    const hasPiezoMeasures = piezoMeasures.some(m => m.measures.length)

    useEffect(() => {
        const listId = associatedSites.filter(a => a.stationLinkedType === 1).map(site => site.stationLinkedId)
        if (!listId.length) {
            return
        }

        const controller = new AbortController()
        const filters = listId.map(id => ({
            stationId: id,
            displayCote: MEASURE_COTE.NGF,
            groupFunc: 'MAX',
            dataType: -1,
        }))
        PiezometerStationAction.getPiezosChartMeasures(filters).then(measures => setPiezoMeasures(measures))

        return () => {
            controller?.abort()
        }
    }, [associatedSites, dispatch])

    const analysisGroup = groupBy(analysis, 'parameter')
    const nbParameter = orderedParameters.length

    const grids = orderedParameters.map(param => ({
        gridId: param,
        height: 150,
    }))

    const xAxis = orderedParameters.map((param, index) => ({
        gridId: param,
        xAxisId: param,
        ...(hasPiezoMeasures || index !== nbParameter - 1 ? {
            axisLine: { show: false },
            axisLabel: { show: false },
            axisTick: { show: false },
        } : {}),
    }))

    const yAxis = orderedParameters.map(param => {
        const parameterLabel = parametersIndex[param]?.displayName
        const parameterAnalysis = analysis.filter(a => a.parameter === param)
        const uniqUnits = uniqBy(parameterAnalysis, 'unit').map(a => a.unit)
        const unitsLabel = uniqUnits.map(unit => unitsIndex[unit]?.symbol).filter(u => !!u).join(', ')

        return {
            gridId: param,
            yAxisId: param,
            name: `${parameterLabel} (${unitsLabel})`,
        }
    })

    const piezoGrids = hasPiezoMeasures ? [{
        gridId: 'piezo',
        height: 150,
    }] : []

    const piezoXAxis = hasPiezoMeasures ? [{
        gridId: 'piezo',
        xAxisId: 'piezo',
    }] : []

    const piezoYAxis = hasPiezoMeasures ? [{
        name: `${i18n.piezometer} (NGF)`,
        gridId: 'piezo',
        yAxisId: 'piezo',
    }] : []

    const datas = orderedParameters.flatMap(param => {
        const analysisFiltered = analysisGroup[param] || []
        const groupByStation = groupBy(analysisFiltered, 'qualitometer')

        return Object.keys(groupByStation).map(key => {
            const qualitoAnalysis = groupByStation[key]
            const stationId = qualitoAnalysis[0].qualitometer

            const qualito = qualitometersIndex[stationId]

            return {
                name: `[${qualito?.code}] ${qualito?.name || ''}`,
                xAxisId: param,
                yAxisId: param,
                dataList: qualitoAnalysis.map(a => ({
                    date: a.sampleDate,
                    value: a.result,
                    result: a.result,
                    parameter: a.parameter,
                    remark: a.remark,
                    unit: unitsIndex[a.unit]?.symbol || i18n.unknownUnit,
                    marker: a.remark !== '1' ? getRemarks(a.remark)?.plot : 'none',
                })),
            }
        })
    })

    const piezoDatas = hasPiezoMeasures ? piezoMeasures.map(piezoMeasure => {
        const piezoLink = associatedSites.find(l => l.stationLinkedType === 1 && l.stationLinkedId === piezoMeasure.stationId)
        return {
            name: `[${piezoLink?.stationLinkedCode}] ${piezoLink?.stationLinkedName || ''}`,
            xAxisId: 'piezo',
            yAxisId: 'piezo',
            dataList: piezoMeasure.measures.map(m => ({
                date: m.date,
                value: m.value,
                result: m.value,
                name: i18n.depth,
                unit: 'm',
                marker: 'none',
            })),
        }
    }) : []

    return !!analysis.length && (
        <Card round>
            <QualityChart
                grids={[...grids, ...piezoGrids]}
                xAxis={[...xAxis, ...piezoXAxis]}
                yAxis={[...yAxis, ...piezoYAxis]}
                series={[...datas, ...piezoDatas]}

                headerHeight={0}
                footerHeight={120}
                xAxisSpace={DAY}

                roundValue={3}

                withToolTypeLine
                withToolTypeBar
                // withToolTypeStack
                withToolLog
                withToolThreshold
                withToolMarker
                withToolLine
                withToolLegend

                withDataZoom
                withHidingGrid
                withHidingYAxis

                dataZoomPosition={{ bottom: 60 }}
                legendPosition={{ bottom: 10, right: 20, top: undefined }}
                toolboxPosition={{ top: 5 }}

                tooltipFormatter={params => {
                    const groupDate = groupBy(params, p => getDate(p.data.value[0]))
                    return Object.keys(groupDate).map(key => {
                        const groupStation = groupBy(groupDate[key], 'seriesName')
                        const label = Object.keys(groupStation).map(stationCode => {
                            const labelParam = groupStation[stationCode].map(({ marker, value: [, , obj], data: { unit = '' } }) => `${marker} ${obj.name ?? parametersIndex[obj.parameter]?.displayLabel} : ${calculateValue(obj)} ${(unit)}`).join('<br/>')
                            return `${stationCode}<br/>${labelParam}`
                        }).join('<br/><br/>')
                        return `${key}<br/>${label}`
                    }).join('<br/><br/>')
                }}
            />
        </Card>
    )
}

export default IndicatorsChart
