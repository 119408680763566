import { Button, Dialog, Grid } from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'
import i18n from 'simple-react-i18n'
import Select from '../../../components/forms/Select'
import { DOCUMENTS_EXTENSIONS } from '../../constants/AdministrationConstants'
import { MODELS_TYPES } from './ModelConstants'
import { DialogActionsMUI, DialogContentMUI, DialogTitleMUI } from 'components/styled/Dialog'
import Icon from 'components/icon/Icon'
import { StyledFieldSet } from 'components/StyledElements'

const PopupAddModel = ({
    openDialog,
    stationType,
    changeStationType = () => {},
    onUploadFile = () => {},
    addModel = () => {},
    closeDialog = () => {},
}) => {
    return (
        <Dialog
            onClose={closeDialog}
            fullWidth
            maxWidth='lg'
            open={openDialog}
        >
            <DialogTitleMUI style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                {i18n.addFile}
                <Icon style={{ color: 'white' }} size='small' icon='close' onClick={closeDialog} />
            </DialogTitleMUI>
            <DialogContentMUI>
                <StyledFieldSet>
                    <Grid container sx={{ padding: '10px' }}>
                        <Grid item xs={6}>
                            <Select
                                label={i18n.modelTypeSelection}
                                value={stationType}
                                onChange={value => changeStationType(value)}
                                keyLabel='name'
                                keyValue='stationType'
                                options={MODELS_TYPES}
                                data-cy='station_type'
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <div className='col s10 file-field input-field'>
                                <div className='btn'>
                                    <span>{ i18n.fileToDownload }</span>
                                    <input
                                        accept={DOCUMENTS_EXTENSIONS}
                                        id='uploadFile'
                                        type='file'
                                        multiple
                                        onChange={value => onUploadFile(value)}
                                        data-cy='upload_file'
                                    />
                                </div>
                                <div className='file-path-wrapper'>
                                    <input className='file-path validate' type='text' />
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </StyledFieldSet>
            </DialogContentMUI>
            <DialogActionsMUI>
                <Button onClick={addModel} variant='contained' color='primary' data-cy='add_model_button'>
                    {i18n.addModel}
                </Button>
            </DialogActionsMUI>
        </Dialog>
    )
}

PopupAddModel.propTypes = {
    openDialog: PropTypes.bool,
    stationType: PropTypes.string,
    changeStationType: PropTypes.func,
    onUploadFile: PropTypes.func,
    addModel: PropTypes.func,
    closeDialog: PropTypes.func,
}

export default PopupAddModel