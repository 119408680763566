import { Accordion, AccordionDetails, AccordionSummary, Card, CardContent, CardHeader } from '@mui/material'
import LayerDto from 'home/dto/LayerDto'
import { Map, Overlay } from 'ol'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import { getLocalStorageJson } from 'utils/FormUtils'

class LegendsOverlay extends Component {
    constructor(props) {
        super(props)
    }

    getLegend = (url, layerName) => {
        const urlLower = url.toLowerCase()
        return `${url}${urlLower.indexOf('?') >= 0 ? urlLower.indexOf('version') >= 0 ? '' : 'version=1.3.0' : '?version=1.3.0'}&service=WMS&request=GetLegendGraphic&sld_version=1.1.0&format=image/jpeg&layer=${layerName}`
    }

    moveOverlay = (evt) => {
        //  TODO: Implement the function to move the overlay with setPosition (request from Michael)
        // Problem : the pointerdrag listener of Map seams to close the overlay (Map.js:124)
        // Note : The overlay's position starts at the top-left corner
    }

    render() {
        const enabledThemes = getLocalStorageJson('SELECTED_SEARCH_VALUES')
        const themeList = this.props.themeLayers.filter(layer => enabledThemes?.dashboardCheckboxes?.[layer.layer]?.enabled)
        return (
            <Card variant='outlined'>
                <CardHeader
                    // action={
                    //     <IconButton onmousedown={this.moveOverlay}>
                    //         <i className='material-icons'>open_with</i>
                    //     </IconButton>
                    // }
                    title={i18n.legendsCarto}
                />
                <CardContent>
                    {
                        themeList.map(layer =>
                            (
                                <Accordion>
                                    <AccordionSummary expandIcon={<i className='material-icons'>expand_more</i>}>
                                        {layer.name}
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        {layer.url ? <img src={this.getLegend(layer.url, layer.layer)} /> : 'Aucune Légende'}
                                    </AccordionDetails>
                                </Accordion>
                            ))
                    }
                </CardContent>
            </Card>
        )
    }
}

LegendsOverlay.propTypes = {
    themeLayers: PropTypes.arrayOf(PropTypes.instanceOf(LayerDto)),
    map: PropTypes.instanceOf(Map),
    overlay: PropTypes.instanceOf(Overlay),
}

const mapStateToProps = store => ({
    themeLayers: store.HomeReducer.themeLayers,
})

export default connect(mapStateToProps)(LegendsOverlay)