import { groupBy, sum } from 'lodash'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import Axis from '../../../components/echart/Axis'
import EChart from '../../../components/echart/EChart'
import { exportExcelIcon, exportPictureIcon, getBarWidth, getTimeAxisInterval } from '../../../components/echart/EChartUtils'
import Bar from '../../../components/echart/series/Bar'
import Line from '../../../components/echart/series/Line'
import DtoParametrageDataType from '../../../piezometry/dto/DtoParametrageDataType'
import { getColorFromPalettePluvio } from '../../../utils/ColorUtil'
import { getDate, getFullDate } from '../../../utils/DateUtil'
import { exportFile } from '../../../utils/ExportDataUtil'
import { hasValue } from '../../../utils/NumberUtil'
import { arrayOf, getLabel, objectOf } from '../../../utils/StoreUtils'
import DtoPluvioMeasures from '../../dto/measures/DtoPluvioMeasures'
import PluviometerDto from '../../dto/PluviometerDto'

class PluviometryGraphicChart extends Component {
    getExportData = (startDate, endDate) => {
        const type = `${this.getDataType()}${this.getUnit()}`
        const format = this.formatDateUse()
        return this.props.pluvioMeasures.flatMap(obj => {
            const station = this.props.pluviosIndex[obj.stationId]
            return obj.measures.filter(m => m.date > startDate && m.date < endDate).map(m => ({
                stationCode: station.code,
                stationName: station.name,
                date: moment(m.date).format(format),
                value: m.value,
                type,
                headers: ['stationCode', 'stationName', 'date', 'value', 'type'],
            }))
        })
    }

    formatDateUse = () => {
        switch (this.props.filter.pluvioGrouped) {
            case 'SUM_HOUR':
                return 'DD/MM/YYYY HH:00'
            case 'SUM_DAY':
            case 'SUM_DECADE':
                return 'DD/MM/YYYY'
            case 'SUM_WEEK':
                return 'WW/YYYY'
            case 'SUM_MONTH':
                return 'MM/YYYY'
            case 'SUM_YEAR':
                return 'YYYY'
            default:
                return 'DD/MM/YYYY HH:mm:ss'
        }
    }

    getUnit = () => {
        switch (this.props.filter.dataType) {
            case 1:
            case 2:
            case 3:
                return i18n.milimetersSymbol
            default:
                return ''
        }
    }

    getDataType = () => {
        return getLabel(this.props.pluviometryDataTypes, this.props.filter.dataType, null, 'id')
    }

    getNbElements = () => {
        if (this.props.filter.stacked) {
            return Object.keys(groupBy(this.props.pluvioMeasures.flatMap(m => m.measures), 'date')).length
        }
        return sum(this.props.pluvioMeasures.map(m => m.measures.length))
    }

    getTooltip = (unit) => {
        return {
            trigger: 'axis',
            formatter: params => {
                const date = getFullDate(params[0].value[0])
                const paramsOrder = params.reverse().filter(o => hasValue(o.value[1])).map(o => ({
                    marker: o.marker,
                    seriesName: o.seriesName,
                    value: o.value[1],
                }))
                const total = `Total : ${sum(params.map(o => o.value[1]))}<br/>`
                const result = paramsOrder.map(o => `${o.marker} ${o.seriesName} : ${o.value} ${unit}`).join('<br/>')
                return `${date}<br />${total}${result}`
            },
        }
    }

    getBarSeries = (filter) => {
        const stack = filter.stacked ? { stack: 'groupPluie' } : {}
        return this.props.pluvioMeasures.map((p, idx) => {
            const pluvio = this.props.pluviosIndex[p.stationId]
            return Bar({
                name: `${pluvio.code} - ${pluvio.name}`,
                barWidth: getBarWidth(this.getNbElements()),
                data: p.measures.map(m => [m.date, m.value]),
                areaStyle: {},
                color: getColorFromPalettePluvio(idx),
                ...stack,
            })
        })
    }

    getLineSeries = (filter) => {
        const stack = filter.stacked ? { stack: 'groupPluie', areaStyle: {} } : {}
        return this.props.pluvioMeasures.map((p, idx) => {
            const pluvio = this.props.pluviosIndex[p.stationId]
            return Line({
                name: `${pluvio.code} - ${pluvio.name}`,
                data: p.measures.map(m => [m.date, m.value]),
                color: getColorFromPalettePluvio(idx),
                ...stack,
            })
        })
    }

    render() {
        const { graphicOptions } = this.props
        const filter = this.props.filter
        const title = graphicOptions.graphicTitle || i18n.overlayGraphic
        const unit = this.getUnit()
        const options = {
            series: filter.graphicType === 'bar' ? this.getBarSeries(filter) : this.getLineSeries(filter),
            tooltip: this.getTooltip(unit),
            title,
            grid: {
                top: '5%',
                right: '10%',
                left: '10%',
                height: 320,
                bottom: 250,
            },
            xAxis: [Axis({
                type: 'time',
                boundaryGap: true,
                splitLine: {
                    show: hasValue(graphicOptions.showXSplitLines) ? graphicOptions.showXSplitLines : true,
                },
                min: filter.startDate,
                max: filter.endDate,
                axisLabel: {
                    show: true,
                    formatter: value => getDate(value),
                },
                maxInterval: getTimeAxisInterval(graphicOptions.intervalChoice),
            })],
            yAxis: [Axis({
                type: 'value',
                splitLine: {
                    interval: 10,
                    show: hasValue(graphicOptions.showYSplitLines) ? graphicOptions.showYSplitLines : true,
                },
                nameLocation: 'middle',
                nameGap: 40,
                scale: true,
                name: `${this.getDataType()}${unit ? ` [${unit}]` : ''}`,
                min: graphicOptions.minY,
                max: graphicOptions.maxY,
            })],
            axisPointer: {
                link: { xAxisIndex: 'all' },
            },
            dataZoom: [
                {
                    bottom: 0,
                    type: 'slider',
                    start: 0,
                    end: 100,
                },
                {
                    type: 'inside',
                    start: 0,
                    end: 100,
                },
            ],
            height: 660,
            gridHeight: 590,
            toolbox: {
                show: true,
                feature: {
                    restore: { title: i18n.restore },
                    saveAsImage: {
                        title: i18n.pictureExport,
                        icon: exportPictureIcon,
                    },
                    myToolExport: {
                        show: true,
                        title: i18n.excelExport,
                        icon: exportExcelIcon,
                        onclick: () => {
                            exportFile({
                                data: this.getExportData(filter.startDate, filter.endDate),
                                exportType: 'xlsx',
                                titleFile: title,
                            })
                        },
                    },
                },
                right: 50,
                top: 20,
            },
            legend: {
                bottom: 50,
                show: true,
            },
        }
        return (
            <div id='chart' className='row no-margin' style={{ paddingTop: '20px' }}>
                <EChart options={ options }/>
            </div>
        )
    }
}

PluviometryGraphicChart.propTypes = {
    filter: PropTypes.shape({
        graphicType: PropTypes.string,
        stacked: PropTypes.bool,
        dataType: PropTypes.number,
        pluvioGrouped: PropTypes.string,
        startDate: PropTypes.number,
        endDate: PropTypes.number,
    }),
    pluvioMeasures: arrayOf(DtoPluvioMeasures),
    pluviosIndex: objectOf(PluviometerDto),
    pluviometryDataTypes: arrayOf(DtoParametrageDataType),
    graphicOptions: PropTypes.shape({
        graphicTitle: PropTypes.string,
        minY: PropTypes.number,
        maxY: PropTypes.number,
        intervalChoice: PropTypes.string,
        showXSplitLines: PropTypes.bool,
        showYSplitLines: PropTypes.bool,
    }),
}

const mapStateToProps = store => ({
    pluviometers: store.PluviometryReducer.pluviometers,
    pluvioMeasures: store.PluviometryReducer.pluvioMeasures,
    pluviosIndex: store.PluviometryReducer.pluviosIndex,
    pluviometryDataTypes: store.PluviometryReducer.pluviometryDataTypes,
})

export default connect(mapStateToProps)(PluviometryGraphicChart)
