'use strict'
import {
    RECEIVE_ALL_WATERSHEDS,
    RECEIVE_STATION_WATERSHED,
    RECEIVE_STATION_WATERSHEDS,
    RECEIVE_WATERSHED,
    RESET_STATIONS_WITH_WATERSHED,
    RESET_WATERSHED,
    STATIONS_WITH_WATERSHED,
} from '../constants/WatershedConstants'
import WatershedDto from '../dto/WatershedDto'
import StationWithWatershedDto from '../dto/StationWithWatershedDto'

export function WatershedReducer(state = {}, action) {
    switch (action.type) {
        case RECEIVE_ALL_WATERSHEDS:
            return Object.assign({}, state, {
                watersheds: action.watersheds.map(watershed => new WatershedDto(watershed)),
            })
        case RECEIVE_WATERSHED:
            return Object.assign({}, state, {
                watershed: new WatershedDto(action.watershed),
            })
        case STATIONS_WITH_WATERSHED:
            return Object.assign({}, state, {
                stationsWithWatershed: Object.keys(action.stations).map(s => new StationWithWatershedDto(s)),
            })
        case RECEIVE_STATION_WATERSHED:
            return Object.assign({}, state, {
                stationWatershed: new WatershedDto(action.stationWatershed),
            })
        case RESET_STATIONS_WITH_WATERSHED:
            return Object.assign({}, state, {
                stationsWithWatershed: [],
            })
        case RESET_WATERSHED:
            return Object.assign({}, state, {
                watershed: {},
                stationWatershed: {},
                watershed1: {},
                watershed2: {},
                watershed3: {},
                watershed4: {},
            })
        case RECEIVE_STATION_WATERSHEDS:
            return Object.assign({}, state, {
                watershed1: new WatershedDto(action.watershed1),
                watershed2: new WatershedDto(action.watershed2),
                watershed3: new WatershedDto(action.watershed3),
                watershed4: new WatershedDto(action.watershed4),
            })
        default:
            return state
    }
}

export const store = {
    watersheds: [],
    watershed: {},
    stationsWithWatershed: [],
    stationWatershed: {},
    watershed1: {},
    watershed2: {},
    watershed3: {},
    watershed4: {},
}