'use strict'
import { createSlice } from '@reduxjs/toolkit'
import ContactThunk from '../actions/ContactThunk'
import ContactDto from '../dto/ContactDto'
import ContactGroupDto from '../dto/ContactGroupDto'
import DtoContactTableLine from '../dto/DtoContactTableLine'

export const initialState = {
    contacts: [],
    contact: {},
    contactsGroups: [],
    contactsGroup: {},
    contactsTableCount: 0,
    contactsTable: [],
}

const store = createSlice({
    name: 'contact',
    initialState,
    reducers: {
        receiveContactsTable: (state, action) => {
            state.contactsTableCount = action.payload.count
            state.contactsTable = action.payload.data.map(d => new DtoContactTableLine(d))
        },
        receiveAllContacts: (state, action) => {
            state.contacts = action.payload.map(contact => new ContactDto(contact))
        },
        receiveContact: (state, action) => {
            state.contact = new ContactDto(action.payload)
        },
        resetContact: (state) => {
            state.contact = {}
        },
        receiveAllContactsGroups: (state, action) => {
            state.contactsGroups = action.payload.map(contactsGroup => new ContactGroupDto(contactsGroup))
        },
        receiveContactsGroup: (state, action) => {
            state.contactsGroup = new ContactGroupDto(action.payload)
        },
    },
    extraReducers: {
        [ContactThunk.fetchContacts.fulfilled]: (state, action) => {
            state.contacts = action.payload.map(contact => new ContactDto(contact))
        },
    },
})

export const ContactActionConstant = store.actions
export default store.reducer
