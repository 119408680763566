import PropTypes from 'prop-types'
import React, { useState } from 'react'
import i18n from 'simple-react-i18n'
import Input from '../../../../components/forms/Input'
import { Button, Grid } from '@mui/material'
import { isUndefined } from 'lodash'
import JobAction from 'import/actions/JobAction'
import { useDispatch } from 'react-redux'

const ImportFileModal = ({
    url = '',
    onChange = () => {},
}) => {
    const dispatch = useDispatch()

    const [name, setName] = useState()
    const [directory, setDirectory] = useState(url)
    const [content, setContent] = useState('')

    const onImportFile = (e) => {
        const reader = new FileReader()
        const file = e.target.files[0]
        setName(file.name)
        reader.onload = upload => setContent(upload.target.result)
        reader.readAsDataURL(file)
    }

    const onUpload = () =>{
        const parameters = {
            stationType: 0,
            stationId: 0,
            path: directory || '',
            name,
            content,
        }
        dispatch(JobAction.uploadFile(parameters)).then(res => res && onChange(res.name))
    }
    return (
        <Grid container spacing={1} alignItems='center'>
            <Grid item xs={3}>
                <label style={{ color: '#9e9e9e', fontWeight: 'bold', fontSize: '1rem' }}>{i18n.importFile}</label>
            </Grid>
            <Grid item xs={2}>
                <Button
                    variant='contained'
                    color='primary'
                    component='label'
                    fullWidth
                >
                    <span>{i18n.chooseFile}</span>
                    <input
                        type='file'
                        hidden
                        onChange={onImportFile}
                    />
                </Button>
            </Grid>
            <Grid item xs={7}>
                <Input value={name ?? i18n.pleaseSelectFile} style={{ marginTop: '-0.5rem' }} disabled/>
            </Grid>
            <Grid item xs={3}>
                <label style={{ color: '#9e9e9e', fontWeight: 'bold', fontSize: '1rem' }}>{i18n.directory}</label>
            </Grid>
            <Grid item xs={9}>
                <Input
                    value= {directory}
                    onChange={setDirectory}
                    style={{ marginTop: '-0.5rem' }}
                />
            </Grid>
            <Grid item xs />
            <Grid item xs='auto'>
                <Button
                    variant='outlined'
                    color='primary'
                    fullWidth
                    className='modal-close'
                >
                    {i18n.cancel}
                </Button>
            </Grid>
            <Grid item xs='auto'>
                <Button
                    variant='contained'
                    color='primary'
                    fullWidth
                    className='modal-close'
                    onClick={onUpload}
                    disabled={isUndefined(name) || isUndefined(content)}
                >
                    {i18n.importLabelFtp}
                </Button>
            </Grid>
        </Grid>
    )
}

ImportFileModal.propTypes = {
    url: PropTypes.string,
    onChange: PropTypes.func,
    uploadFile: PropTypes.func,
}
export default ImportFileModal
