import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import Input from '../../../../../components/forms/Input'
import NumberField from '../../../../../components/forms/NumberField'
import DtoBoreholeDrilling from '../dto/DtoBoreholeDrilling'

class BoreholeDrillingModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            boreholeDriilling: { ...props.boreholeDriilling, idStation: props.id },
        }
    }

    componentDidUpdate(prevProps) {
        if (!prevProps.popupIsOpen && this.props.popupIsOpen) {
            $('#descriptionModal').on('click', this.onSave)
        }
    }
    onSave = () => {
        this.props.saveResult(new DtoBoreholeDrilling(this.state.boreholeDriilling))
        $('.modal').modal('close')
    }

    onChangeElement = (changes) => this.setState({ boreholeDriilling: { ...this.state.boreholeDriilling, ...changes } })

    render() {
        const { boreholeDriilling } = this.state
        return (
            <div>
                <div className='row no-margin padding-top-1'>
                    <NumberField col={ 4 } value={ boreholeDriilling.order } title={ i18n.order } onChange={ v => this.onChangeElement({ order: v }) } />
                    <NumberField col={ 4 } value={ boreholeDriilling.diameter } title={ i18n.diameter } onChange={ v => this.onChangeElement({ diameter: v }) } floatValue/>
                </div>
                <div className='row no-margin'>
                    <NumberField col={ 4 } value={ boreholeDriilling.depth } title={ i18n.depth } onChange={ v => this.onChangeElement({ depth: v }) } floatValue/>
                    <Input col={ 6 } value={ boreholeDriilling.comment } title={ i18n.comment } onChange={ v => this.onChangeElement({ comment: v }) } />
                </div>
            </div>
        )
    }
}

BoreholeDrillingModal.propTypes = {
    boreholeDriilling: PropTypes.instanceOf(DtoBoreholeDrilling),
    id: PropTypes.number,
    popupIsOpen: PropTypes.bool,
    saveResult: PropTypes.func,
}


const mapStateToProps = store => ({
    popupIsOpen: store.SieauReducer.popupIsOpen,
})

BoreholeDrillingModal.defaultProps = {
    boreholeDriilling: {},
}

export default connect(mapStateToProps)(BoreholeDrillingModal)