import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import ActionComponent from '../../../components/ActionComponent'
import { getMapStateToProps, getSandreList } from '../../../utils/StoreUtils'
import Card from '../../../components/card/Card'
import StationMapDashboardPanel from '../../../station/components/dashboard/component/map/StationMapDashboardPanel'
import Input from '../../../components/forms/Input'
import DatePicker from '../../../components/forms/DatePicker'
import NumberField from '../../../components/forms/NumberField'
import Select from '../../../components/forms/Select'
import Row from '../../../components/react/Row'
import Textarea from '../../../components/forms/Textarea'
import Checkbox from '../../../components/forms/Checkbox'
import { SANDRE } from '../../../referencial/constants/ReferencialConstants'
import { hasValue } from '../../../utils/NumberUtil'
import LastEventPanel from '../../../station/components/event/LastEventPanel'
import ContactCard from '../../../referencial/components/contact/components/ContactCard'
import StationUpdatePanel from '../../../station/components/update/StationUpdatePanel'
import { some } from 'lodash'
import { getControlColor } from '../../../utils/InstallationUtils'
import { getColorCircleElement, getRGBColor } from '../../../utils/ColorUtil'
import DtoInstallation from '../../dto/installation/DtoInstallation'
import CityDto from '../../../referencial/components/city/dto/CityDto'
import DtoSandreCode from '../../../referencial/dto/DtoSandreCode'
import ContributorDto from '../../../referencial/components/contributor/dto/ContributorDto'
import UserDto from '../../../administration/components/user/dto/UserDto'
import DtoStatus from '../../../quality/dto/DtoStatus'
import DtoInstallationEvent from '../../dto/installation/DtoInstallationEvent'
import LinkedStationsPanel from '../../../station/components/associatedStation/LinkedStationsPanel'
import MaterielAssigned from '../../../station/components/materiel/MaterielAssigned'

import InstallationInfosDashboard from '../installations/components/InstallationInfosDashboard'
import UnitChartPanel from '../../../productionUnit/components/UnitChartPanel'
import InstallationAction from '../../actions/InstallationAction'
import { formatMilliers } from '../../../utils/StringUtil'
import ProgressCard from '../../../components/card/ProgressCard'
import { H_MAT_MODULE } from '../../../account/constants/AccessRulesConstants'
import HabilitationRequired from '../../../utils/components/HabilitationRequired'
import { WhiteCard } from 'components/styled/Card'


class STEPDashboardPanel extends ActionComponent {
    state = {
        uncheckedStations: [],
        diagnosisLoaded: false,
    }

    componentDidMount = () => {
        const { installation } = this.props
        this.props.fetchSTEPTerritoryDiagnosis(installation.code).then(() => {
            this.setState({ diagnosisLoaded: true })
        })
    }

    getOriginIcon = (installation) => {
        return hasValue(installation.jobExecutionId) ? (
            <div className='row no-margin padding-left-1 padding-top-1'>
                <h6 className='valign-wrapper'>
                    <i className='material-icons rem-3-size padding-right-1'>wifi</i>
                    {i18n.automaticUpdate}
                </h6>
            </div>
        ) : null
    }

    getOriginStatusColor = (installation) => {
        if (hasValue(installation.status)) {
            const color = getControlColor(installation.status)
            return getColorCircleElement(getRGBColor(color), true)
        }
        return null
    }

    render() {
        const { installation, STEPTerritoryDiagnosis } = this.props
        const { diagnosisLoaded } = this.state
        const readMode = { readMode: true, editMode: false, hideNull: true }

        return (
            <div className='row no-margin padding-top-1 padding-bottom-7'>
                <div className='col s9'>
                    <Card className='margin-bottom-1' noMargin={false}>
                        <Row>
                            <InstallationInfosDashboard />
                        </Row>
                    </Card>
                    {
                        diagnosisLoaded && (
                            <div className='margin-bottom-1'>
                                <WhiteCard
                                    title='Chiffres clefs'
                                    // actions={[{
                                    //     iconName: 'file_download',
                                    //     tooltip: 'Export additionnel',
                                    //     onClick: () => this.getDiagnosisExport()
                                    // }]}
                                >
                                    <Row>
                                        {
                                            STEPTerritoryDiagnosis.map(td => {
                                                return (
                                                    <div className='col s6 offset-s3' >
                                                        <h6 className='col s6 bold text-align-right'>{td.stat}</h6>
                                                        <h6 className='col s6'>{formatMilliers(td.value)}</h6>
                                                    </div>
                                                )
                                            })
                                        }
                                    </Row>
                                </WhiteCard>
                            </div>
                        ) || (<ProgressCard indeterminate={true} className='margin-top-1'/>)
                    }

                    <WhiteCard
                        title={i18n.characteristics}
                        smallCard
                        displayIf={some(['exploitationCode', 'comments'], key => hasValue(installation[key]))}
                        noMargin={false}
                        className='margin-bottom-1'
                    >
                        <div className='row no-margin padding-top-1'>
                            <div className='col s6' >
                                <div className='row no-margin'>
                                    <Checkbox componentClassName='padding-bottom-1' col={12} checked={hasValue(installation.exploitationCode) ? installation.exploitationCode == 1 : null}
                                        label={i18n.exploitation} disabled {...readMode}
                                    />
                                </div>
                            </div>
                            <div className='col s6' >
                                <div className='row no-margin'>
                                    <Textarea col={12} value={installation.comments} title={i18n.comment} {...readMode} />
                                </div>
                            </div>
                        </div>
                    </WhiteCard>
                    <UnitChartPanel unit={this.props.installation} uncheckedStations={this.state.uncheckedStations} setDataLoaded={this.props.setDataLoaded} fromStationType='INST' />
                </div>
                <div className='col s3'>
                    <StationUpdatePanel station={installation} />
                    <Card displayIf={some(['dataOrigin', 'closeComment', 'status', 'statusDate', 'deliberationDate', 'confidential'], key => hasValue(installation[key]))}>
                        {this.getOriginIcon(installation)}
                        <div className='row no-margin'>
                            <Input col={12} title={i18n.dataOrigin} value={installation.dataOrigin} {...readMode} />
                        </div>
                        <div className='row no-margin'>
                            <Select col={12} label={[this.getOriginStatusColor(installation), i18n.status]} value={installation.status}
                                options={this.props.status} {...readMode}
                            />
                        </div>
                        <div className='row no-margin'>
                            <DatePicker col={12} value={installation.statusDate} id='statusDate' title={i18n.controlDoneAt} {...readMode} />
                        </div>
                        <div className='row no-margin'>
                            <Select col={12} label={i18n.controlDoneBy} value={installation.statusLogin}
                                options={this.props.users.map(u => ({ code: u.login, name: u.name || u.login }))} {...readMode}
                            />
                        </div>
                        <div className='row no-margin'>
                            <DatePicker col={12} value={installation.endDate} id='deliberationDate' title={i18n.deliberationDate} {...readMode} />
                        </div>
                        <div className='row no-margin'>
                            <Input col={12} value={installation.closeComment} title={i18n.become} {...readMode} />
                        </div>
                        <div className='row no-margin'>
                            <Checkbox componentClassName='padding-bottom-1' col={12} label={i18n.confidential} {...readMode}
                                checked={hasValue(installation.confidential) ? installation.confidential == '1' : null} disabled
                            />
                        </div>
                    </Card>
                    <Row className='margin-bottom-1' noMargin={false}>
                        <ContactCard
                            title={i18n.contact}
                            contactCode={installation.ownerCode}
                            hideNull
                            {...readMode}
                        />
                    </Row>
                    <Card>
                        <div className='row no-margin padding-top-1'>
                            <Input col={12} title={i18n.address} value={installation.address} {...readMode} />
                        </div>
                        <div className='row no-margin'>
                            <Select col={12} value={installation.townCode} label={i18n.city} options={this.props.cities} keyValue='id' displayWithCode {...readMode} />
                        </div>
                        <div className='row no-margin'>
                            <NumberField col={6} title='X' value={installation.x} floatValue {...readMode} />
                            <NumberField col={6} title='Y' value={installation.y} floatValue {...readMode} />
                        </div>
                        <div className='row no-margin'>
                            <NumberField col={6} title='Z' value={installation.altitude} floatValue {...readMode} />
                            <Select col={6} label={i18n.projection} value={installation.projection}
                                options={getSandreList(this.props.sandreCodes, SANDRE.PROJECTION)} {...readMode}
                            />
                        </div>
                    </Card>
                    <HabilitationRequired habilitation={H_MAT_MODULE}>
                        <div className='margin-bottom-1'>
                            <MaterielAssigned stationId={this.props.installation.id} type={this.props.stationType} />
                        </div>
                    </HabilitationRequired>
                    <LastEventPanel id={this.props.installation.id} events={this.props.installationEvents} stationId={this.props.installation.id}
                        stationType='installation'
                    />
                    <StationMapDashboardPanel noMarkerTooltip station={installation} type={'installation'} className='margin-bottom-1'/>
                    <LinkedStationsPanel
                        onReMount={ this.props.onReMount }
                        dashboardMode
                        checkable
                        changeUncheckedStations={ tab => this.setState({ uncheckedStations: tab })}
                        station={this.props.installation}
                        withColors
                        hideTitle
                    />
                </div>
            </div>
        )
    }
}

STEPDashboardPanel.propTypes = {
    onRemount: PropTypes.func,
    stationType: PropTypes.string,
    installation: PropTypes.instanceOf(DtoInstallation),
    cities: PropTypes.arrayOf(PropTypes.instanceOf(CityDto)),
    sandreCodes: PropTypes.arrayOf(PropTypes.instanceOf(DtoSandreCode)),
    contributors: PropTypes.arrayOf(PropTypes.instanceOf(ContributorDto)),
    users: PropTypes.arrayOf(PropTypes.instanceOf(UserDto)),
    status: PropTypes.arrayOf(PropTypes.instanceOf(DtoStatus)),
    installationEvents: PropTypes.arrayOf(PropTypes.instanceOf(DtoInstallationEvent)),
    STEPTerritoryDiagnosis: PropTypes.arrayOf(PropTypes.shape({
        stat: PropTypes.string,
        value: PropTypes.number,
    })),
}

const mapStateToProps = store => getMapStateToProps({
    installation: store.InstallationReducer.installation,
    cities: store.CityReducer.cities,
    sandreCodes: store.ReferencialReducer.sandreCodes,
    contributors: store.ContributorReducer.contributors,
    users: store.UserReducer.users,
    status: store.QualityReducer.status,
    installationEvents: store.InstallationReducer.installationEvents,
}, {
    STEPTerritoryDiagnosis: store.InstallationReducer.STEPTerritoryDiagnosis,
})

const mapDispatchToProps = {
    fetchSTEPTerritoryDiagnosis: InstallationAction.fetchSTEPTerritoryDiagnosis,
}

export default connect(mapStateToProps, mapDispatchToProps)(STEPDashboardPanel)