import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import ToastrAction from 'toastr/actions/ToastrAction'
import Job from '../../../dto/DtoJob'
import RadioButtons from '../../../../components/forms/RadioButtons'
import SimpleFilterSelect from '../../../../components/forms/specific/SimpleFilterSelect'
import AppStore from '../../../../store/AppStore'
import QualityAction from '../../../../quality/actions/QualityAction'
import DtoQualitometerLight from '../../../../quality/dto/DtoQualitometerLight'
import SuperMultiAutocomplete from '../../../../components/forms/SuperMultiAutocomplete'
import CampaignAction from '../../../../campaign/actions/CampaignAction'
import { arrayOf } from '../../../../utils/StoreUtils'
import CampaignDto from '../../../../campaign/dto/CampaignDto'
import Input from '../../../../components/forms/Input'
import JobAction from '../../../actions/JobAction'
import Select from '../../../../components/forms/Select'
import { IMPORT_FORMATS } from '../../../constants/JobFormat'
import SimpleDatePicker from '../../../../components/forms/SimpleDatePicker'
import { StyledFieldSet, StyledLegend } from '../../../../components/StyledElements'
import Checkbox from '../../../../components/forms/Checkbox'
import { getModelFileType } from 'utils/ExportDataUtil'

class PurgeQuality extends Component {
    constructor(props) {
        super(props)
        this.state = {
            filter: props.job.parameters.filters.length ? JSON.parse(props.job.parameters.filters[0]) : { mode: 'station' },
            jobList: [],
        }
    }

    componentDidMount() {
        if (!this.props.qualitometers.length) {
            AppStore.dispatch(QualityAction.fetchQualitometersLight())
        }
        if (!this.props.qualityCampaigns.length) {
            AppStore.dispatch(CampaignAction.fetchQualityCampaigns())
        }
        JobAction.promiseJobs(true).then(jobs => this.setState({ jobList: jobs.map(j => ({ code: j[0], name: j[1] })) }))
    }

    onChangeFilter = (changes, valu) => {
        const parameters = {
            ...this.props.job.parameters,
            filters: [JSON.stringify({
                ...this.state.filter,
                ...changes,
            })],
        }
        this.setState({ filter: { ...this.state.filter, ...changes } })
        this.props.onChangeJob({ parameters })
        this.state.filter.mode= valu
    }

    onChangeFilters = (changes) => {
        const parameters = {
            ...this.props.job.parameters,
            filters: [JSON.stringify({
                ...this.state.filter,
                ...changes,
            })],
        }
        this.setState({ filter: { ...this.state.filter, ...changes } })
        this.props.onChangeJob({ parameters })
    }

    getMode =(value)=> {
        return this.state.filter.mode=== value
    }

    render() {
        const modes = [
            { code: 'station', name: i18n.stations },
            { code: 'operation', name: i18n.operations },
            { code: 'duplicate', name: i18n.duplicateOp },
        ]
        const campaignText = [
            { code: true, name: i18n.duplicateOpCampaignYes },
            { code: false, name: i18n.duplicateOpCampaignNo },
        ]
        const producerText = [
            { code: true, name: i18n.duplicateOpProducerYes },
            { code: false, name: i18n.duplicateOpProducerNo },
        ]
        const samplerText = [
            { code: true, name: i18n.duplicateOpSamplerYes },
            { code: false, name: i18n.duplicateOpSamplerNo },
        ]
        const LaboratoryText = [
            { code: true, name: i18n.duplicateOpLaboYes },
            { code: false, name: i18n.duplicateOpLaboNo },
        ]
        const pointText = [
            { code: true, name: i18n.duplicateOpPointYes },
            { code: false, name: i18n.duplicateOpPointNo },
        ]
        const samplingText = [
            { code: true, name: i18n.duplicateOpSamplingYes },
            { code: false, name: i18n.duplicateOpSamplingNo },
        ]
        const localText = [
            { code: true, name: i18n.duplicateOpLocalYes },
            { code: false, name: i18n.duplicateOpLocalNo },
        ]
        const anaText = [
            { code: true, name: i18n.duplicateOpAnaYes },
            { code: false, name: i18n.duplicateOpAnaNo },
        ]
        const { filter } = this.state
        return (
            <div>
                <div className='row no-margin'>
                    <RadioButtons
                        col={4}
                        title='Type de purge'
                        selected={filter.mode}
                        elements={modes}
                        onChange={value => this.onChangeFilter({ mode: value }, value)}
                        active={this.props.isEditMode}
                        disabled={!this.props.isEditMode}
                        obligatory={true}
                    />
                    <SimpleFilterSelect
                        col={4}
                        onChange={(v, f) => this.onChangeFilters({ stations: f && f !== -1 ? v.map(s => s.id) : null })}
                        stations={this.props.qualitometers}
                        stationType={'quality'}
                        disabled={ !this.props.isEditMode }
                        value={filter.stations}
                        ignoreCache
                    />
                    <SuperMultiAutocomplete
                        label={i18n.campaign}
                        options={this.props.qualityCampaigns}
                        values={filter.campaignIds}
                        onChange={v => this.onChangeFilters({ campaignIds: v })}
                        multiple
                        col={4}
                        disabled={ !this.props.isEditMode || this.getMode('duplicate') }
                    />
                </div>
                <div className='row no-margin'>
                    <Input
                        col={4}
                        title='jobExecutions (séparés par des ,)'
                        value={filter.jobExecutionIds}
                        onChange={v => this.onChangeFilters({ jobExecutionIds: v })}
                        disabled={ !this.props.isEditMode || this.getMode('duplicate') }
                    />
                    <Select
                        col={ 4 }
                        options={ this.state.jobList }
                        label={i18n.job}
                        value={ filter.jobId }
                        onChange={ v => this.onChangeFilters({ jobId: v }) }
                        disabled={ !this.props.isEditMode || this.getMode('duplicate') }
                    />
                    <Select
                        value={ filter.jobType }
                        options={ IMPORT_FORMATS() }
                        group
                        label={ i18n.format }
                        col={ 4 }
                        onChange={ v => this.onChangeFilters({ jobType: v }) }
                        disabled={ !this.props.isEditMode || this.getMode('duplicate') }
                    />
                </div>
                <div className='row no-margin'>
                    <SimpleDatePicker
                        col={ 3 }
                        value={ filter.startDate }
                        onChange={ v => this.onChangeFilters({ startDate: v }) }
                        label={ i18n.startDate }
                        disabled={ !this.props.isEditMode || !this.getMode('operation') }
                    />
                    <SimpleDatePicker
                        col={ 3 }
                        value={ filter.endDate }
                        onChange={ v => this.onChangeFilters({ endDate: v }) }
                        label={ i18n.endDate }
                        disabled={ !this.props.isEditMode || !this.getMode('operation') }
                    />
                </div>
                <div className='row no-margin'>
                    <StyledFieldSet className='padding-1'>
                        <StyledLegend>{i18n.duplicateOp}</StyledLegend>
                        <Select
                            col={ 4 }
                            options={ campaignText }
                            defaultValue={ true}
                            label={i18n.campaign}
                            value={ filter.campaign }
                            onChange={ v => this.onChangeFilters({ campaign: v }) }
                            disabled={ !this.props.isEditMode || !this.getMode('duplicate') }
                        />
                        <Select
                            col={ 4 }
                            options={ producerText }
                            label={i18n.producer}
                            defaultValue={ true}
                            value={ filter.producer }
                            onChange={ v => this.onChangeFilters({ producer: v }) }
                            disabled={ !this.props.isEditMode || !this.getMode('duplicate') }
                        />
                        <Select
                            col={ 4 }
                            options={ samplerText }
                            label={i18n.sampler}
                            defaultValue={ true}
                            value={ filter.sampler }
                            onChange={ v => this.onChangeFilters({ sampler: v }) }
                            disabled={ !this.props.isEditMode || !this.getMode('duplicate') }
                        />
                        <Select
                            col={ 4 }
                            options={ LaboratoryText }
                            label={i18n.laboratory}
                            defaultValue={ true}
                            value={ filter.laboratory }
                            onChange={ v => this.onChangeFilters({ laboratory: v }) }
                            disabled={ !this.props.isEditMode || !this.getMode('duplicate') }
                        />
                        <Select
                            col={ 4 }
                            options={ pointText }
                            label={i18n.point}
                            defaultValue={ true}
                            value={ filter.point }
                            onChange={ v => this.onChangeFilters({ point: v }) }
                            disabled={ !this.props.isEditMode || !this.getMode('duplicate') }
                        />
                        <Select
                            col={ 4 }
                            options={ samplingText }
                            label={i18n.sampling}
                            defaultValue={ true}
                            value={ filter.specimen }
                            onChange={ v => this.onChangeFilters({ specimen: v }) }
                            disabled={ !this.props.isEditMode || !this.getMode('duplicate') }
                        />
                        <Select
                            col={ 4 }
                            options={ localText }
                            label={i18n.localisation}
                            defaultValue={ true}
                            value={ filter.localisation }
                            onChange={ v => this.onChangeFilters({ localisation: v }) }
                            disabled={ !this.props.isEditMode || !this.getMode('duplicate') }
                        />
                        <Select
                            col={ 4 }
                            options={ anaText }
                            label={i18n.numerAnalys}
                            defaultValue={ true}
                            value={ filter.numerAnalys }
                            onChange={ v => this.onChangeFilters({ numerAnalys: v }) }
                            disabled={ !this.props.isEditMode || !this.getMode('duplicate') }
                        />
                        <Checkbox col={4}
                            label={i18n.opWithoutAna}
                            checked={filter.opWithoutAna ? filter.opWithoutAna: false}
                            onChange={ v => this.onChangeFilters({ opWithoutAna: v }) }
                            disabled={ !this.props.isEditMode || !this.getMode('duplicate') }
                        />

                        <Checkbox col={4}
                            label={i18n.pointWithoutOp}
                            checked={filter.pointWithoutOp ? filter.pointWithoutOp: false}
                            onChange={ v => this.onChangeFilters({ pointWithoutOp: v }) }
                            disabled={ !this.props.isEditMode || !this.getMode('duplicate') }
                        />

                        <Checkbox col={4}
                            label={i18n.noDelete}
                            checked={filter.noDelete ? filter.noDelete: false}
                            onChange={ v => this.onChangeFilters({ noDelete: v }) }
                            disabled={ !this.props.isEditMode || !this.getMode('duplicate') }
                        />

                    </StyledFieldSet>
                </div>
            </div>
        )
    }
}

PurgeQuality.propTypes = {
    job: PropTypes.instanceOf(Job).isRequired,
    handleChangeParameters: PropTypes.func.isRequired,
    onChangeJob: PropTypes.func.isRequired,
    isEditMode: PropTypes.bool,

    toastrError: PropTypes.func,
    qualitometers: arrayOf(DtoQualitometerLight),
    qualityCampaigns: arrayOf(CampaignDto),
}

const mapStateToProps = store => ({
    qualitometers: store.QualityReducer.qualitometersLight,
    qualityCampaigns: store.QualityReducer.qualityCampaigns,
})

const mapDispatchToProps = {
    toastrError: ToastrAction.error,
}

export default connect(mapStateToProps, mapDispatchToProps)(PurgeQuality)
