import DtoSTEPEquipments from './DtoSTEPEquipments'
import DtoSTEPCivilEng from './DtoSTEPCivilEng'
/* eslint-disable camelcase */
import DtoSTEPAssociatedTown from './DtoSTEPAssociatedTown'
import DtoSTEPAssociatedIndustry from './DtoSTEPAssociatedIndustry'
import DtoSTEPCollectionBasin from './DtoSTEPCollectionBasin'
import DtoSTEPRejectPoint from './DtoSTEPRejectPoint'
import DtoSTEPFollowPoint from './DtoSTEPFollowPoint'

export default class DtoSTEP {
    constructor(obj) {
        this.idStation = obj.idStation // Int = 0,
        this.autorisationDecree = obj.autorisationDecree // Option[Boolean],
        this.numDecree = obj.numDecree // Option[String],
        this.autorisationDecreeDate = obj.autorisationDecreeDate // Option[DateTime],
        this.decreeDuration = obj.decreeDuration // Option[Int],
        this.treatmentCapacity = obj.treatmentCapacity // Option[Double],
        this.wastewaterTreatmentSector = obj.wastewaterTreatmentSector // Option[Long],
        this.sludgeTreatmentSector = obj.sludgeTreatmentSector // Option[Long],
        this.sludgeTreatment = obj.sludgeTreatment // Option[Long],
        this.finishingLagoon = obj.finishingLagoon // Option[Boolean],
        this.rejectType = obj.rejectType // Option[Long],
        this.reuseTreatedWastedwater = obj.reuseTreatedWastedwater // Option[Boolean],
        this.sandCleaningMaterialsIUnit = obj.sandCleaningMaterialsIUnit // Option[Boolean],
        this.fatsUnit = obj.fatsUnit // Option[Boolean],
        this.drainMaterialUnit = obj.drainMaterialUnit // Option[Boolean],
        this.sludgeDisposalSystem = obj.sludgeDisposalSystem // Option[Long],
        this.receivingMedium = obj.receivingMedium // Option[String],
        this.populationEquivalent = obj.populationEquivalent // Option[Long],
        this.icpe = obj.icpe // Option[Boolean],
        this.azoteTreatment = obj.azoteTreatment // Option[Boolean],
        this.phosphorusTreatment = obj.phosphorusTreatment // Option[Boolean],
        this.disinfection = obj.disinfection // Option[Boolean],
        this.spreadingPlan = obj.spreadingPlan // Option[Boolean],
        this.watermassCode = obj.watermassCode // Option[String],
        this.watershed = obj.watershed // Option[Long],
        this.networkType = obj.networkType // Option[Long],
        this.nominalCapacityDB05 = obj.nominalCapacityDB05 // Option[Double],
        this.percentile95 = obj.percentile95 // Option[Double],
        this.maxCharge = obj.maxCharge // Option[Double],
        this.incomingFlow = obj.incomingFlow // Option[Double],
        this.agriSpreadingQuantity = obj.agriSpreadingQuantity // Option[Double],

        this.link_equipments = obj.link_equipments ? obj.link_equipments.map(e => new DtoSTEPEquipments(e)) : []
        this.link_civilEngs = obj.link_civilEngs ? obj.link_civilEngs.map(ce => new DtoSTEPCivilEng(ce)) : []
        this.link_associatedTowns = obj.link_associatedTowns ? obj.link_associatedTowns.map(at => new DtoSTEPAssociatedTown(at)) : []
        this.link_associatedIndustries = obj.link_associatedIndustries ? obj.link_associatedIndustries.map(ai => new DtoSTEPAssociatedIndustry(ai)) : []
        this.link_basins = obj.link_basins ? obj.link_basins.map(lb => new DtoSTEPCollectionBasin(lb)) : []
        this.link_rejectPoints = obj.link_rejectPoints ? obj.link_rejectPoints.map(lb => new DtoSTEPRejectPoint(lb)) : []
        this.link_followPoints = obj.link_followPoints ? obj.link_followPoints.map(lb => new DtoSTEPFollowPoint(lb)) : []
    }
}