/* eslint-disable camelcase */
import DtoDanger from 'installation/components/campaign/dto/DtoDanger'

export default class DtoEntityWithLocation {
    constructor(obj) {
        this.name = obj.name // Option[String],
        this.intValue = obj.intValue // Option[Int],
        this.code = obj.code // Option[String],
        this.siret = obj.siret // Option[String],
        this.x = obj.x // Option[Double],
        this.y = obj.y // Option[Double],
        this.projection = obj.projection // Option[Double],
        this.townCode = obj.townCode // Option[String],
        this.siteType = obj.siteType // Option[Long],
        this.siteCode = obj.siteCode // Option[Long],
        this.hasDiagnostic = obj.hasDiagnostic // Option[Boolean],
        this.idCampaign = obj.idCampaign // Option[Long],


        this.link_dangers = obj.link_dangers ? obj.link_dangers.map(d => new DtoDanger(d)) : [] // Option[Seq[CmsDanger]]
    }
}
