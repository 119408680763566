export default class StationWithWatermassDto {
    constructor(object) {
        this.id = object.idStation
        this.code = object.idStation?.toString() || ''
        this.watermassCode = object.watermassCode
        this.name = object.name
        this.type = object.typeStation
        this.startDate = object.startDate
        this.endDate = object.endDate
        this.townCode = object.townCode
        this.x = object.x
        this.y = object.y
        this.projection = object.projection
    }
}