import React, { useState } from 'react'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import Select from '../../../../../components/forms/Select'
import { Button, Grid } from '@mui/material'
import NumberField from 'components/forms/NumberField'
import { DialogActionsMUI, DialogContentMUI, DialogMUI, DialogTitleMUI } from 'components/styled/Dialog'
import Icon from 'components/icon/Icon'
import { hasValue } from 'utils/NumberUtil'
import { StyledFieldSet } from 'components/StyledElements'
import DtoSTEPFollowPoint from '../../dto/DtoSTEPFollowPoint'
import Input from 'components/forms/Input'
import SimpleTextArea from 'components/forms/SimpleTextArea'
import useSandreList from 'utils/customHook/useSandreList'
import { SANDRE } from 'referencial/constants/ReferencialConstants'

const STEPFollowPointModal = ({
    idStation,
    followPoint: followPointProps,
    isOpen = false,
    setIsOpen = () => {},
    onSave = () => {},
}) => {
    const [followPoint, setFollowPoint] = useState(followPointProps)

    const projections = useSandreList(SANDRE.PROJECTION)
    const referencials = useSandreList(SANDRE.STEP_POINTS_REFERENTIELS)

    const onChangeElement = (values) => setFollowPoint(fp => ({ ...fp, ...values }))

    return (
        <DialogMUI open={isOpen}>
            <DialogTitleMUI style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                {hasValue(followPoint.index) ? i18n.editFollowPoint : i18n.newFollowPoint}
                <Icon style={{ color: 'white' }} size='small' icon='close' onClick={() => setIsOpen(false)} />
            </DialogTitleMUI>
            <DialogContentMUI>
                <StyledFieldSet>
                    <Grid container item xs={12} justifyContent='space-between' alignItems='center'>
                        <Grid item xs={5.8}>
                            <Select
                                value={followPoint.sandreCode}
                                label={i18n.referentielsPoint}
                                options={referencials}
                                displayWithCode
                                onChange={(v) => onChangeElement({ sandreCode: v })}
                            />
                        </Grid>
                        <Grid item xs={5.8}>
                            <Input
                                value={followPoint.internalCode}
                                title={i18n.internalCodeSing}
                                onChange={v => onChangeElement({ internalCode: v })}
                            />
                        </Grid>
                    </Grid>
                    <Grid container item xs={12} justifyContent='space-between' alignItems='center'>
                        <Grid item xs={12}>
                            <SimpleTextArea
                                value={followPoint.comment}
                                title={i18n.comment}
                                onChange={v => onChangeElement({ comment: v })}
                            />
                        </Grid>
                    </Grid>
                    <Grid container item xs={12} justifyContent='space-between' alignItems='center' sx={{ paddingTop: '5px' }}>
                        <Grid item xs={5.8}>
                            <NumberField
                                value={followPoint.x}
                                title={i18n.x}
                                onChange={v => onChangeElement({ x: v })}
                                floatValue
                            />
                        </Grid>
                        <Grid item xs={5.8}>
                            <NumberField
                                value={followPoint.y}
                                title={i18n.y}
                                onChange={v => onChangeElement({ y: v })}
                                floatValue
                            />
                        </Grid>
                    </Grid>
                    <Grid container item xs={12} justifyContent='space-between' alignItems='center'>
                        <Grid item xs={5.8}>
                            <Select
                                value={followPoint.projection}
                                label={i18n.projection}
                                options={projections}
                                keyValue='id'
                                displayWithCode
                                onChange={(v) => onChangeElement({ projection: v })}
                            />
                        </Grid>
                    </Grid>
                </StyledFieldSet>
            </DialogContentMUI>
            <DialogActionsMUI>
                <Grid container justifyContent='center'>
                    <Button
                        onClick={() => {
                            setIsOpen(false)
                            onSave(new DtoSTEPFollowPoint({ ...followPoint, idInstallation: idStation }))
                        }}
                        variant='contained'
                        color='primary'
                        className='center'
                    >
                        {i18n.register}
                    </Button>
                </Grid>
            </DialogActionsMUI>
        </DialogMUI>
    )
}

STEPFollowPointModal.propTypes = {
    idStation: PropTypes.number,
    followPoint: PropTypes.instanceOf(DtoSTEPFollowPoint),
    onSave: PropTypes.func,
    isOpen: PropTypes.bool,
    setIsOpen: PropTypes.func,
}

export default STEPFollowPointModal
