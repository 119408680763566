import ActionComponent from '../../../../components/ActionComponent'
import PropTypes from 'prop-types'
import DtoHydrometricStation from '../../../dto/DtoHydrometricStation'
import { connect } from 'react-redux'
import { setActions } from '../../../../components/ActionUtil'

class HydrometerSuiviTable extends ActionComponent {
    componentDidMount() {
        this.onChangeTab('')
    }
    onChangeTab = () => {
        setActions({
            exportmodel: () => ({
                stationId: this.props.hydrometricStation.id.toString(),
                stationCode: this.props.hydrometricStation.code,
                stationType: 'hydrometry',
                environmentModels: this.props.typeEnvironmentModels,
            }),
        })
    }

    render() {
        return ''
    }
}

HydrometerSuiviTable.propTypes = {
    hydrometricStation: PropTypes.instanceOf(DtoHydrometricStation),
    typeEnvironmentModels: PropTypes.arrayOf(PropTypes.string),

}

const mapStateToProps = store => {
    return {
        hydrometricStation: store.HydrometryReducer.hydrometricStation,
        typeEnvironmentModels: store.ExportReducer.typeEnvironmentModels,
    }
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(HydrometerSuiviTable)