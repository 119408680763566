import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import { isEqual, maxBy, omit } from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import ToastrAction from 'toastr/actions/ToastrAction'
import WaitAction from 'wait/WaitAction'
import Card from '../../../../../components/card/Card'
import Table from '../../../../../components/datatable/Table'
import Button from '../../../../../components/forms/Button'
import Checkbox from '../../../../../components/forms/Checkbox'
import Input from '../../../../../components/forms/Input'
import NumberField from '../../../../../components/forms/NumberField'
import Select from '../../../../../components/forms/Select'
import Textarea from '../../../../../components/forms/Textarea'
import MaterielAction from '../../../../../materiel/actions/MaterielAction'
import CentralAction from '../../../../../materiel/components/central/actions/CentralAction'
import CentralPanel from '../../../../../materiel/components/central/components/CentralPanel'
import EquipmentAction from '../../../../../materiel/components/equipment/actions/EquipmentAction'
import EquipmentPanel from '../../../../../materiel/components/equipment/components/EquipmentPanel'
import DtoEquipmentSituation from '../../../../../materiel/components/equipment/dto/DtoEquipmentSituation'
import EquipmentDto from '../../../../../materiel/components/equipment/dto/EquipmentDto'
import EquipmentTypeDto from '../../../../../materiel/components/equipment/dto/EquipmentTypeDto'
import PowerSupplyAction from '../../../../../materiel/components/powerSupply/actions/PowerSupplyAction'
import PowerSupplyPanel from '../../../../../materiel/components/powerSupply/components/PowerSupplyPanel'
import SensorAction from '../../../../../materiel/components/sensor/actions/SensorAction'
import SensorPanel from '../../../../../materiel/components/sensor/components/SensorPanel'
import SimAction from '../../../../../materiel/components/sim/actions/SimAction'
import SimPanel from '../../../../../materiel/components/sim/components/SimPanel'
import TelecomAction from '../../../../../materiel/components/telecom/actions/TelecomAction'
import TelecomPanel from '../../../../../materiel/components/telecom/components/TelecomPanel'
import VariousMaterielAction from '../../../../../materiel/components/variousMateriel/actions/VariousMaterielAction'
import VariousMaterielPanel from '../../../../../materiel/components/variousMateriel/components/VariousMaterielPanel'
import DtoMaterielState from '../../../../../materiel/dto/DtoMaterielState'
import {
    getCentralSetting,
    getEquipmentSetting,
    getPowerSupplySetting,
    getSensorSetting,
    getTelecomSetting,
} from '../../../../../materiel/utils/MaterielUtils'
import PiezometryAction from '../../../../../piezometry/actions/PiezometryAction'
import DtoParametrageDataType from '../../../../../piezometry/dto/DtoParametrageDataType'
import { getLabel, removeNullKeys } from '../../../../../utils/StoreUtils'
import { getI18nTitleDataLength } from '../../../../../utils/StringUtil'
import StationAction from '../../../../actions/StationAction'
import DtoStation from '../../../../dto/DtoStation'
import DtoStationEquipmentAssignment from '../../../../dto/materiel/DtoStationEquipmentAssignment'
import SituationDetailPanel from '../SituationDetailPanel'

class EquipmentSituationDetail extends Component {
    state = {
        materiel: {},
        materielDefaultParam: {},
        previousMaterielSetting: {},
        centralChannels: [],
        centralDefaultchannels: [],
        previousCentralChannels: [],
        open: false,
        isNewChannel: false,
        currentChannel: {},
        dataTypes: [],
    }

    componentDidMount() {
        const {
            stationEquipmentAssignments,
            materielStates,
            equipments,
            equipmentTypes,
            piezometryDataTypes,
        } = this.props
        if (!materielStates.length) {
            this.props.fetchMaterielStates()
        }
        if (!equipments.length) {
            this.props.fetchEquipments()
        }
        if (!equipmentTypes.length) {
            this.props.fetchEquipmentTypes()
        }
        if (!piezometryDataTypes.length) {
            this.props.fetchPiezometryDataTypes()
        }
        this.props.fetchCentralTypes()
        this.props.fetchPowerSupplyTypes()
        this.props.fetchSensorTypes()
        this.props.fetchSimTypes()
        this.props.fetchTelecomTypes()
        this.props.fetchVariousMaterielTypes()
        this.props.fetchPiezometryDataTypes()
        this.fetchPreviousEquipmentSetting(stationEquipmentAssignments)
    }

    componentDidUpdate(prevProps) {
        const {
            stationEquipmentAssignments,
            piezometryDataTypes,
        } = this.props
        if (stationEquipmentAssignments.length !== prevProps.stationEquipmentAssignments.length) {
            this.fetchPreviousEquipmentSetting(stationEquipmentAssignments)
        }
        if (piezometryDataTypes.length !== prevProps.piezometryDataTypes.length) {
            this.setState({ dataTypes: [{ id: 0, label: i18n.depth }, ...piezometryDataTypes] })
        }
    }

    fetchPreviousEquipmentSetting = stationEquipmentAssignments => {
        const lastSituation = maxBy(stationEquipmentAssignments, 'situationDate')
        if (lastSituation) {
            const lastMaterielId = lastSituation.idEquipment
            this.props.fetchEquipment(lastMaterielId).then(json => {
                const {
                    equipment = {},
                    central = [],
                    sensor = [],
                    telecom = [],
                    powerSupply = [],
                } = json
                if (equipment.centralIntegrated && central.id) {
                    this.props.fetchCentralChannels(central.id).then(channels =>
                        this.setState({ previousCentralChannels: channels }))
                }
                this.setState({
                    previousMaterielSetting: {
                        equipment: getEquipmentSetting(equipment),
                        central: getCentralSetting(central),
                        sensor: getSensorSetting(sensor),
                        telecom: getTelecomSetting(telecom),
                        powerSupply: getPowerSupplySetting(powerSupply),
                        sim: {},
                        variousMateriel: {},
                    },
                })
            })
        }
    }

    getEquipmentTitle = ({ serialNumber, reference }, { label }) => {
        const code = serialNumber || reference || ''
        return label ? `${label} - ${code}` : code || i18n.unknownMaterial
    }

    setEquipmentTypeParam = () => {
        const { equipmentType } = this.state.materiel.equipment
        const type = this.props.equipmentTypes.find(t => t.id === equipmentType) || {}
        this.setState({
            materiel: {
                ...this.state.materiel,
                equipment: {
                    ...this.state.materiel.equipment,
                    ...omit(type, ['id', 'code', 'label', 'name', 'comment', 'updateDate', 'updateLogin', 'startDate', 'endDate', 'icon', 'headers']),
                },
            },
        })
    }

    setPreviousEquipmentParam = () => this.setState({
        materiel: {
            equipment: {
                ...this.state.materiel.equipment,
                ...this.state.previousMaterielSetting.equipment,
            },
            central: [
                ...this.state.materiel.central,
                ...this.state.previousMaterielSetting.central,
            ],
            sensor: [
                ...this.state.materiel.sensor,
                ...this.state.previousMaterielSetting.sensor,
            ],
            telecom: [
                ...this.state.materiel.telecom,
                ...this.state.previousMaterielSetting.telecom,
            ],
            powerSupply: [
                ...this.state.materiel.powerSupply,
                ...this.state.previousMaterielSetting.powerSupply,
            ],
            sim: [
                ...this.state.materiel.sim,
                ...this.state.previousMaterielSetting.sim,
            ],
            variousMateriel: [
                ...this.state.materiel.variousMateriel,
                ...this.state.previousMaterielSetting.variousMateriel,
            ],
        },
    })

    getRemoteTranmissionIcon = teletransmission => {
        switch (teletransmission) {
            case '1':
                return 'wifi_tethering'
            default:
                return 'portable_wifi_off'
        }
    }

    changeStateAttributeMat = (mat, index, changes) => {
        const materiel = this.state.materiel[mat][index]
        const materielChange = { ...materiel, ...changes }
        const objectChange = this.state.materiel[mat].map((d, i) => index === i ? materielChange : d)
        this.setState({ materiel: { ...this.state.materiel, [mat]: objectChange } })
    }

    onDeleteCentralChannel = (channel, index) => {
        const central = this.state.materiel.central[index]
        const centralUpdate = { ...central, centralChannels: [...central.centralChannels.filter(c => c.channelId !== channel.channelId)] }
        const objectChange = this.state.materiel.central.map((c, i) => index === i ? centralUpdate : c)
        this.setState({ materiel: { ...this.state.materiel, central: objectChange } })
    }

    getCentralPanel = (central, index, isEditMode) => {
        const channels = central.centralChannels.length ? central.centralChannels : central.centralChannelsDefault
        const centralChannels = channels.map(c => ({
            channelId: c.channelId,
            pathNumber: c.channelId === 0 ? `${c.channelId}` : c.channelId,
            assignment: c.assignment,
            comment: c.comment,
            dataType: getLabel(this.state.dataTypes, c.dataType, null, 'id'),
            remoteTransmission: (
                <i className='material-icons'>{this.getRemoteTranmissionIcon(c.remoteTransmission)}</i>
            ),
        }))
        const actions = [
            {
                iconName: 'note_add',
                onClick: () => this.setState({ currentChannel: { centralId: central.id }, open: true, isNewChannel: true }),
            },
        ]
        return (
            <div className='row no-margin card'>
                <div className='col s12 card-title active'>
                    {i18n.central}
                </div>
                <div className={'card-content margin-top-1'}>
                    <CentralPanel id={central.id}
                        central={central}
                        onChange={changes => this.changeStateAttributeMat('central', index, changes)}
                        disabled={!isEditMode}
                    />
                    <div className='row no-margin padding-top-3-px'>
                        <div className='row margin-top-2'>
                            <div className='col s12'>
                                <Card title={getI18nTitleDataLength(i18n.path, i18n.paths, centralChannels.length)}
                                    active={isEditMode} actions={actions}
                                >
                                    <Table
                                        showTitle={false}
                                        data={centralChannels}
                                        type={{ headers: ['pathNumber', 'dataType', 'assignment', 'comment', 'remoteTransmission'] }}
                                        condensed
                                        sortable
                                        alterable
                                        onAlter={channel =>
                                            this.setState({ currentChannel: central.centralChannels.find(c => c.channelId === channel.channelId) || {}, open: true, isNewChannel: false })
                                        }
                                        deletable
                                        onDelete={channel => this.onDeleteCentralChannel(channel, index)}
                                    />
                                </Card>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    getPowerSupplyPanel = (powerSupply, index, isEditMode) => (
        <div className='row no-margin card'>
            <div className='col s12 card-title active'>
                {i18n.powerSupply}
            </div>
            <div className={'card-content margin-top-1'}>
                <PowerSupplyPanel id={powerSupply.id}
                    powerSupply={powerSupply}
                    onChange={changes => this.changeStateAttributeMat('powerSupply', index, changes)}
                    disabled={!isEditMode}
                />
            </div>
        </div>
    )

    getSensorPanel = (sensor, index, isEditMode) => (
        <div className='row no-margin card'>
            <div className='col s12 card-title active'>
                {i18n.sensor}
            </div>
            <div className={'card-content margin-top-1'}>
                <SensorPanel id={sensor.id}
                    sensor={sensor}
                    onChange={changes => this.changeStateAttributeMat('sensor', index, changes)}
                    disabled={!isEditMode}
                />
            </div>
        </div>
    )

    getSimPanel = (sim, index, isEditMode) => (
        <div className='row no-margin card'>
            <div className='col s12 card-title active'>
                {i18n.sim}
            </div>
            <div className={'card-content margin-top-1'}>
                <SimPanel id={sim.id}
                    sim={sim}
                    onChange={changes => this.changeStateAttributeMat('sim', index, changes)}
                    disabled={!isEditMode}
                />
            </div>
        </div>
    )

    getTelecomPanel = (telecom, index, isEditMode) => (
        <div className='row no-margin card'>
            <div className='col s12 card-title active'>
                {i18n.telecom}
            </div>
            <div className={'card-content margin-top-1'}>
                <TelecomPanel
                    telecom={telecom}
                    onChange={changes => this.changeStateAttributeMat('telecom', index, changes)}
                    disabled={!isEditMode}
                />
            </div>
        </div>
    )

    getVariousMaterielPanel = (variousMateriel, index, isEditMode) => (
        <div className='row no-margin card'>
            <div className='col s12 card-title active'>
                {i18n.variousMateriel}
            </div>
            <div className={'card-content margin-top-1'}>
                <VariousMaterielPanel id={variousMateriel.id}
                    variousMateriel={variousMateriel}
                    onChange={changes => this.changeStateAttributeMat('variousMateriel', index, changes)}
                    disabled={!isEditMode}
                />
            </div>
        </div>
    )

    getEquipmentPanel = isEditMode => {
        const { central = [], sensor = [], powerSupply = [], equipment = {}, telecom = [], sim = [], variousMateriel = [] } = this.state.materiel
        if (equipment.equipmentId) {
            const equipmentType = this.props.equipmentTypes.find(t => t.id === equipment.equipmentType) || {}
            return (
                <div>
                    <div className='col s8 offset-s2'>
                        <div className='col s4'>
                            <a className='col s12 btn' onClick={() => this.setState({
                                materiel: {
                                    ...this.state.materielDefaultParam,
                                },
                            })}
                            >
                                {i18n.defaultSettings}
                            </a>
                        </div>
                        <div className='col s4'>
                            <a className='col s12 btn' onClick={this.setEquipmentTypeParam}>
                                {i18n.typeSettings}
                            </a>
                        </div>
                        <div className='col s4'>
                            <a className={`col s12 btn ${this.props.stationEquipmentAssignments.length ? '' : 'disabled'}`} onClick={this.setPreviousEquipmentParam}>
                                {i18n.previousSettings}
                            </a>
                        </div>
                    </div>
                    <div className='row col s10 offset-s1'>
                        <div className='card no-padding'>
                            <div className='col s12 card-title active'>
                                {this.getEquipmentTitle(equipment, equipmentType)}
                            </div>
                            <div className={'card-content margin-top-1'}>
                                <div className='row no-margin'>
                                    <EquipmentPanel id={equipment.equipmentId}
                                        equipment={equipment}
                                        onChange={changes => this.setState({ materiel: { ...this.state.materiel, equipment: { ...equipment, ...changes } } })}
                                        disabled={!isEditMode}
                                    />
                                </div>
                                <div className='row no-margin padding-top-3-px'>
                                    {equipment.centralIntegrated && central.map((c, i) => this.getCentralPanel(c, i, isEditMode))}
                                    {equipment.powerSupplyIntegrated && powerSupply.map((p, i) => this.getPowerSupplyPanel(p, i, isEditMode))}
                                    {equipment.sensorIntegrated && sensor.map((s, i) => this.getSensorPanel(s, i, isEditMode))}
                                    {equipment.simIntegrated && sim.map((s, i) => this.getSimPanel(s, i, isEditMode))}
                                    {equipment.telecomIntegrated && telecom.map((t, i) => this.getTelecomPanel(t, i, isEditMode))}
                                    {equipment.variousMaterielIntegrated && variousMateriel.map((v, i) => this.getVariousMaterielPanel(v, i, isEditMode))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        return null
    }

    fetchEquipment = id => {
        this.props.waitStart()
        this.props.fetchEquipment(id).then(json => {
            if (json.equipment.centralIntegrated && json.central.id) {
                if (!this.props.piezometryDataTypes.length) {
                    this.props.fetchPiezometryDataTypes()
                }
                this.props.fetchCentralChannels(json.central.id).then(channels => {
                    this.setState({
                        centralChannels: channels,
                        centralDefaultchannels: channels,
                        materiel: json,
                        materielDefaultParam: json,
                    })
                    this.props.waitStop()
                })
            } else {
                this.setState({
                    materiel: json,
                    materielDefaultParam: json,
                })
                this.props.waitStop()
            }
        })
    }

    updateEquipment = () => {
        if (!isEqual(this.state.materiel, this.state.materielDefaultParam)) {
            this.props.updateEquipment(this.state.materiel)
        }
    }

    resetEquipment = () => {
        this.props.resetEquipment()
        this.setState({ materiel: {}, materielDefaultParam: {} })
    }

    handleClose = () => {
        const { currentChannel, materiel } = this.state
        if (currentChannel.channelId || currentChannel.channelId === 0) {
            const centralIndex = materiel.central.indexOf(materiel.central.find(c => c.id === currentChannel.centralId))
            const centralNewChannel = materiel.central[centralIndex]
            const centralUpdate = { ...centralNewChannel, centralChannels: [...centralNewChannel.centralChannels.filter(c => c.channelId !== currentChannel.channelId), currentChannel] }
            const objectChange = materiel.central.map((c, i) => centralIndex === i ? centralUpdate : c)
            this.setState({
                open: false,
                currentChannel: {},
                materiel: { ...materiel, central: objectChange },
            })
        } else {
            this.props.toastrError(i18n.selectChannel)
        }
    }

    onChangeChannel = changes =>
        this.setState({ currentChannel: removeNullKeys({ ...this.state.currentChannel, ...changes }) })

    render = () => {
        const {
            currentChannel,
            open,
            isNewChannel,
            dataTypes,
        } = this.state
        const {
            station,
            equipments,
            equipmentsLastSituations,
            equipmentTypes,
            stationEquipmentAssignments,
            addEquipmentSituation,
            saveEquipmentSituation,
            deleteEquipmentSituation,
            params,
            type,
        } = this.props
        return (
            <div>
                <SituationDetailPanel
                    params={params}
                    type={type}
                    getMaterielPanel={isEditMode => this.getEquipmentPanel(isEditMode)}
                    station={station}
                    materiels={equipments}
                    lastSituations={equipmentsLastSituations}
                    materielTypes={equipmentTypes}
                    stationMaterielAssignments={stationEquipmentAssignments}
                    addSituation={addEquipmentSituation}
                    saveSituation={saveEquipmentSituation}
                    deleteSituation={deleteEquipmentSituation}
                    fetchMateriel={this.fetchEquipment}
                    updateMateriel={this.updateEquipment}
                    resetMateriel={this.resetEquipment}
                    keyMaterielType='equipmentType'
                    keyMaterielId='idEquipment'
                />
                <Dialog
                    onClose={() => this.setState({ currentChannel: {}, open: false })}
                    fullWidth
                    maxWidth='lg'
                    open={open}
                >
                    <DialogTitle>{i18n.channel}</DialogTitle>
                    <DialogContent>
                        <div className='row no-margin'>
                            <NumberField col={2} title={i18n.channel} value={currentChannel.channelId} onChange={v => this.onChangeChannel({ channelId: v })} disabled={!isNewChannel} />
                            <Input col={5} title={i18n.name} value={currentChannel.name} onChange={v => this.onChangeChannel({ name: v })} />
                            <Select col={5} value={currentChannel.dataType} options={dataTypes} label={i18n.dataType} onChange={v => this.onChangeChannel({ dataType: v })} integerValue />
                        </div>
                        <div className='row no-margin valign-wrapper'>
                            <Textarea col={6} title={i18n.comment} value={currentChannel.comment} onChange={v => this.onChangeChannel({ comment: v })} />
                            <NumberField col={3} title={i18n.periodicity} value={currentChannel.periodicity} onChange={v => this.onChangeChannel({ periodicity: v })} />
                            <Checkbox col={3} checked={currentChannel.remoteTransmission === '1'} label={i18n.remoteTransmission} onChange={v => this.onChangeChannel({ remoteTransmission: v ? '1' : '0' })} />
                        </div>
                        <div className='row no-margin valign-wrapper padding-top-1'>
                            <Input col={6} title={i18n.assignment} value={currentChannel.assignment} onChange={v => this.onChangeChannel({ assignment: v })} />
                            <Checkbox col={6} checked={currentChannel.register === '1'} label={i18n.oneRegister} onChange={v => this.onChangeChannel({ register: v ? '1' : '0' })} />
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.setState({ currentChannel: {}, open: false })} variant='outlined' >
                            {i18n.cancel}
                        </Button>
                        <Button onClick={this.handleClose} variant='contained' color='primary'>
                            {i18n.validate}
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        )
    }
}

EquipmentSituationDetail.propTypes = {
    params: PropTypes.shape({
        id: PropTypes.string,
        materialType: PropTypes.string,
    }),
    type: PropTypes.string,
    station: PropTypes.instanceOf(DtoStation),

    stationEquipmentAssignments: PropTypes.arrayOf(PropTypes.instanceOf(DtoStationEquipmentAssignment)),
    materielStates: PropTypes.arrayOf(PropTypes.instanceOf(DtoMaterielState)),
    equipment: PropTypes.instanceOf(EquipmentDto),
    equipments: PropTypes.arrayOf(PropTypes.instanceOf(EquipmentDto)),
    equipmentsLastSituations: PropTypes.arrayOf(PropTypes.instanceOf(DtoEquipmentSituation)),
    equipmentTypes: PropTypes.arrayOf(PropTypes.instanceOf(EquipmentTypeDto)),
    piezometryDataTypes: PropTypes.arrayOf(DtoParametrageDataType),

    resetEquipment: PropTypes.func,
    updateEquipment: PropTypes.func,
    fetchEquipment: PropTypes.func,
    fetchEquipments: PropTypes.func,
    fetchMaterielStates: PropTypes.func,
    fetchEquipmentTypes: PropTypes.func,
    saveEquipmentSituation: PropTypes.func,
    addEquipmentSituation: PropTypes.func,
    deleteEquipmentSituation: PropTypes.func,
    fetchCentralChannels: PropTypes.func,

    fetchCentralTypes: PropTypes.func,
    fetchPowerSupplyTypes: PropTypes.func,
    fetchSensorTypes: PropTypes.func,
    fetchSimTypes: PropTypes.func,
    fetchTelecomTypes: PropTypes.func,
    fetchVariousMaterielTypes: PropTypes.func,

    updateAllCentralChannel: PropTypes.func,
    fetchPiezometryDataTypes: PropTypes.func,
    toastrError: PropTypes.func,
    waitStart: PropTypes.func,
    waitStop: PropTypes.func,
}

const mapStateToProps = store => ({
    stationEquipmentAssignments: store.StationReducer.stationEquipmentAssignments,
    materielStates: store.MaterielReducer.materielStates,
    equipment: store.EquipmentReducer.equipment,
    equipments: store.EquipmentReducer.equipments,
    equipmentsLastSituations: store.EquipmentReducer.equipmentsLastSituations,
    equipmentTypes: store.EquipmentReducer.equipmentTypes,
    piezometryDataTypes: store.PiezometryReducer.piezometryDataTypes,
})

const mapDispatchToProps = {
    resetEquipment: EquipmentAction.resetEquipment,
    updateEquipment: EquipmentAction.updateEquipment,
    fetchEquipment: EquipmentAction.fetchEquipment,
    fetchEquipments: EquipmentAction.fetchEquipments,
    fetchMaterielStates: MaterielAction.fetchMaterielStates,
    fetchEquipmentTypes: EquipmentAction.fetchEquipmentTypes,
    saveEquipmentSituation: StationAction.saveEquipmentSituation,
    addEquipmentSituation: StationAction.addEquipmentSituation,
    deleteEquipmentSituation: StationAction.deleteEquipmentSituation,
    fetchCentralChannels: CentralAction.fetchCentralChannels,
    fetchCentralTypes: CentralAction.fetchCentralTypes,
    fetchPowerSupplyTypes: PowerSupplyAction.fetchPowerSupplyTypes,
    fetchSensorTypes: SensorAction.fetchSensorTypes,
    fetchSimTypes: SimAction.fetchSimTypes,
    fetchTelecomTypes: TelecomAction.fetchTelecomTypes,
    fetchVariousMaterielTypes: VariousMaterielAction.fetchVariousMaterielTypes,
    updateAllCentralChannel: CentralAction.updateAllCentralChannel,
    fetchPiezometryDataTypes: PiezometryAction.fetchPiezometryDataTypes,
    toastrError: ToastrAction.error,
    waitStart: WaitAction.waitStart,
    waitStop: WaitAction.waitStop,
}

export default connect(mapStateToProps, mapDispatchToProps)(EquipmentSituationDetail)
