export default class DtoPredMeasure {
    constructor(obj) {
        this.idStation = obj.idStation
        this.typeId = obj.typeId
        this.simulationDate = obj.simulationDate
        this.date = obj.date
        this.value = obj.value
        this.source = obj.source
        this.idModel = obj.idModel
        this.doubtMin = obj.doubtMin
        this.doubtMax = obj.doubtMax
        this.jobExecutionId = obj.jobExecutionId
        this.creationDate = obj.creationDate
        this.updateDate = obj.updateDate
        this.updateLogin = obj.updateLogin
        this.gap = obj.gap
        this.serieName = obj.serieName
        this.sourceHorizon = obj.sourceHorizon
        this.horizonMode = obj.horizonMode
        this.horizon = obj.horizon
    }
}