import React, { useEffect, useMemo } from 'react'
import { nbPerPageLabel } from '../constants/ReferencialConstants'
import Table from '../../components/datatable/Table'
import { getFullDate } from '../../utils/DateUtil'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import useSessionStorage from 'utils/customHook/useSessionStorage'
import { filterAllCharacters } from 'utils/StringUtil'
import SimpleSearchPanel from './SimpleSearchPanel'

const SearchTable = ({
    sessionKey = 'searchTable',
    lastUpdate = {},
    title = '',
    data = [],
    setExportData = () => {},
    color = false,
    headers = [],
    headersFilter = headers,
    customHeaders = {},
    customWidthHeaders = {},
    onClick = () => {},
}) => {
    const [searchValue, setSearchValue] = useSessionStorage(`referencial_${sessionKey}`, '')

    const filteredData = useMemo(() => filterAllCharacters(data, headersFilter, searchValue), [data, searchValue])

    useEffect(() => {
        setExportData(filteredData)
    }, [filteredData])

    return (
        <>
            <SimpleSearchPanel
                defaultSearchValue={searchValue}
                setSearchValue={setSearchValue}
            />
            {lastUpdate?.updateUse && lastUpdate?.updateDate && (
                <div className='row no-margin-bottom'>
                    <div className='col s4 center offset-s8 card'>
                        <div className='col s12'>
                            { i18n.lastUpdateOn } { getFullDate(lastUpdate.updateDate) } { i18n.by } { lastUpdate.updateUser }
                        </div>
                    </div>
                </div>
            )}
            <div className='margin-1 padding-top-5-px referencial-table'>
                <Table
                    title={title}
                    showTitle={title?.length > 0}
                    condensed paging
                    nbPerPageLabel={nbPerPageLabel}
                    data={filteredData}
                    color={color}
                    type={{ headers }}
                    customHeaders={customHeaders}
                    customWidthHeaders={customWidthHeaders}
                    sortable={!!filteredData.length}
                    onClick={onClick}
                />
            </div>
        </>
    )
}

SearchTable.propTypes = {
    sessionKey: PropTypes.string,
    lastUpdate: PropTypes.shape({
        updateUser: PropTypes.string,
        updateDate: PropTypes.string,
    }),
    title: PropTypes.string,
    data: PropTypes.array,
    setExportData: PropTypes.func,
    color: PropTypes.bool,
    headers: PropTypes.arrayOf(PropTypes.string),
    headersFilter: PropTypes.arrayOf(PropTypes.string),
    customHeaders: PropTypes.object,
    customWidthHeaders: PropTypes.object,
    onClick: PropTypes.func,
}

export default SearchTable
