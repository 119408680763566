import { differenceBy, intersectionBy } from 'lodash'
import React, { useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import ProgressCard from '../../../../components/card/ProgressCard'
import QualityAction from '../../../../quality/actions/QualityAction'
import { STATION_QUALITOMETER_NAMES } from '../../../../station/constants/StationConstants'
import { DELETE, INSERT, UPDATE } from '../../../../utils/constants/ActionConstants'
import { getLabel } from '../../../../utils/StoreUtils'
import { Indicator, PopinIndicator, StepperIndicator } from './ComponentsOptionIndicator'
import useTitle from 'utils/customHook/useTitle'
import useBoolean from 'utils/customHook/useBoolean'
import { Grid } from '@mui/material'
import useProgressDispatch from 'utils/customHook/useProgressDispatch'
import ParameterAction from 'referencial/components/parameter/actions/ParameterAction'
import useActions from 'utils/customHook/useActions'

const ListIndicators = ({
    indicators = [],
    setIndicators = () => {},
    isEditMode = false,
}) => {
    const {
        value: isOpen,
        setTrue: open,
        setFalse: close,
    } = useBoolean(false)
    const {
        value: isIndicatorOpen,
        setTrue: openIndicator,
        setFalse: closeIndicator,
    } = useBoolean(false)

    const [selectedType, setSelectedType] = useState()
    const [selectedCode, setSelectedCode] = useState()

    const selectedIndicator = indicators.find(i => i.stationType === selectedType) ?? { stationType: selectedType, indicators: [] }
    const selectedIndicatorThreshold = selectedIndicator.indicators.find(i => i.id === selectedCode)

    return (
        <>
            {
                STATION_QUALITOMETER_NAMES.map(stationType => {
                    const indicator = indicators.find(i => i.stationType === stationType.code) ?? { stationType: stationType.code, indicators: [] }
                    return (
                        <Indicator
                            key={stationType.code}
                            indicator={indicator}
                            isEditMode={isEditMode}

                            onUpdate={type => {
                                setSelectedType(type)
                                open()
                            }}

                            onUpdateIndicator={({ type, code }) => {
                                setSelectedType(type)
                                setSelectedCode(code)
                                openIndicator()
                            }}
                            onDeleteIndicator={({ type, code }) => {
                                setIndicators(prev => prev.map(indi => {
                                    if (indi.stationType === type) {
                                        return {
                                            ...indi,
                                            indicators: indi.indicators.filter(t => t.id !== code),
                                        }
                                    }
                                    return indi
                                }))
                            }}
                        />
                    )
                })
            }
            <StepperIndicator
                isOpen={isOpen}
                onClose={close}
                onSave={newIndicator => {
                    setIndicators(prevIndicators => {
                        if (prevIndicators.some(i => i.stationType === newIndicator.stationType)) {
                            return prevIndicators.map(i => i.stationType === newIndicator.stationType ? { ...newIndicator, isUpdated: true } : i)
                        }
                        return [...prevIndicators, newIndicator]
                    })
                    setSelectedType()
                    close()
                }}
                selectedIndicator={selectedIndicator}
            />
            <PopinIndicator
                isOpen={isIndicatorOpen}
                close={closeIndicator}
                validate={newIndicatorThreshold => {
                    setIndicators(prevIndicators => {
                        return prevIndicators.map(indi => {
                            if (indi.stationType === selectedType) {
                                return {
                                    ...indi,
                                    indicators: indi.indicators.map(i => {
                                        if (i.id === selectedCode) {
                                            return newIndicatorThreshold
                                        }
                                        return i
                                    }),
                                    isUpdated: true,
                                }
                            }
                            return indi
                        })
                    })
                    setSelectedType()
                    setSelectedCode()
                    closeIndicator()
                }}
                indicator={selectedIndicatorThreshold}
            />
        </>
    )
}

ListIndicators.propTypes = {
    indicators: PropTypes.arrayOf(PropTypes.shape({
        stationType: PropTypes.number,
        indicator: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number,
            threshold: PropTypes.number,
        })),
    })),
    setIndicators: PropTypes.func,
    isEditMode: PropTypes.bool,
}

const QualityIndicatorsApp = ({

}) => {
    const dispatch = useDispatch()

    const {
        qualityIndicators,
        thresholds,
        parameters,
    } = useSelector(store => ({
        qualityIndicators: store.QualityReducer.qualityIndicators,
        thresholds: store.QualityReducer.thresholds,
        parameters: store.ParameterReducer.parameters,
    }), shallowEqual)

    const {
        value: isEditMode,
        setTrue: editMode,
        setFalse: readMode,
    } = useBoolean(false)

    const [indicators, setIndicators] = useState([])

    const {
        isLoaded,
        progress,
    } = useProgressDispatch(() => [
        dispatch(ParameterAction.fetchParameters()),
        dispatch(QualityAction.fetchThresholds()),
        dispatch(QualityAction.fetchQualitometersLight()),
        dispatch(QualityAction.fetchQualityIndicators()).then(setIndicators),
    ], [])

    useTitle(() => [{
        title: i18n.quality,
        href: '/quality',
    }, {
        title: i18n.qualityOptions,
        href: '/quality/qualityOptions',
    }, {
        title: i18n.qualityIndicators,
        href: '/quality/qualityOptions/indicators',
    }], [])

    useActions(() => {
        if (isEditMode) {
            return {
                cancel: () => {
                    readMode()
                    setIndicators(qualityIndicators)
                },
                save: () => {
                    const createdIndicators = STATION_QUALITOMETER_NAMES.flatMap(({ code: stationType }) => {
                        const indicator = indicators.find(i => i.stationType === stationType) ?? { indicators: [] }
                        const propsIndicator = qualityIndicators.find(i => i.stationType === stationType) ?? { indicators: [] }
                        return differenceBy(indicator.indicators, propsIndicator.indicators, 'id').map(i => ({
                            updateType: INSERT,
                            stationType,
                            parameterCode: i.id,
                            thresholdType: i.threshold,
                        }))
                    })
                    const deletedIndicators = STATION_QUALITOMETER_NAMES.flatMap(({ code: stationType }) => {
                        const indicator = indicators.find(i => i.stationType === stationType) ?? { indicators: [] }
                        const propsIndicator = qualityIndicators.find(i => i.stationType === stationType) ?? { indicators: [] }
                        return differenceBy(propsIndicator.indicators, indicator.indicators, 'id').map(i => ({
                            updateType: DELETE,
                            stationType,
                            parameterCode: i.id,
                        }))
                    })
                    const updatedIndicators = STATION_QUALITOMETER_NAMES.flatMap(({ code: stationType }) => {
                        const indicator = indicators.find(i => i.stationType === stationType) ?? { indicators: [] }
                        const propsIndicator = qualityIndicators.find(i => i.stationType === stationType) ?? { indicators: [] }
                        return intersectionBy(indicator.indicators, propsIndicator.indicators, 'id').filter(i => propsIndicator.indicators.find(pi => pi.id === i.id)?.threshold !== i.threshold).map(i => ({
                            updateType: UPDATE,
                            stationType,
                            parameterCode: i.id,
                            thresholdType: i.threshold,
                        }))
                    })
                    const updateActions = [
                        ...createdIndicators,
                        ...deletedIndicators,
                        ...updatedIndicators,
                    ]
                    dispatch(QualityAction.updateQualityIndicators(updateActions, indicators, readMode))
                },
            }
        }
        return {
            edit: editMode,
            export: () => {
                const data = qualityIndicators.reduce((acc, indi) => {
                    indi.indicators.map(i => {
                        acc.push(({
                            stationType: getLabel(STATION_QUALITOMETER_NAMES, indi.stationType),
                            codeParameter: { value: i.id, cellType: 'right' },
                            parameter: getLabel(parameters, i.id, 'name'),
                            threshold: getLabel(thresholds, i.threshold),
                        }))
                    })
                    return acc
                }, [])
                return {
                    data: data.length ? [
                        { ...data[0], headers: Object.keys(data[0]) },
                        ...data.slice(1),
                    ] : [],
                    exportType: 'xlsx',
                    titleFile: i18n.qualityIndicators,
                }
            },
        }
    }, [isEditMode, qualityIndicators, indicators, parameters, thresholds])

    return (
        <div style={{ maxWidth: '1200px', marginLeft: 'auto', marginRight: 'auto', paddingBottom: '100px' }}>
            {
                !isLoaded && (
                    <ProgressCard progress={progress} />
                )
            }
            {
                isLoaded && (
                    <Grid container spacing={1} sx={{ paddingTop: '10px' }}>
                        <ListIndicators
                            indicators={indicators}
                            setIndicators={setIndicators}
                            isEditMode={isEditMode}
                        />
                    </Grid>
                )
            }
        </div>
    )
}

export default QualityIndicatorsApp
