import ApplicationConf from '../../../conf/ApplicationConf'
import MapStyles from '../../../utils/mapUtils/MapStyles'
// import WFS from '../../../utils/mapUtils/layers/WFS'
import KML from '../../../utils/mapUtils/layers/KML'
import { STATION_TYPE } from '../../../station/constants/StationConstants'
import { hasLocalisation } from '../../../utils/StationUtils'
import i18n from 'simple-react-i18n'

const allLayersProps = (props, station = {}) => {
    const exists = props.exists || {}

    return {
        WATERMASS: {
            name: i18n.watermass,
            serviceUrl: ApplicationConf.layers.getWatermassKML,
            color: MapStyles.FILL_BLUE,
            layerType: KML,
            codeToFetch: props.watermass,
            shouldAdd: (exists.WATERMASS),
            shouldExists: !exists.WATERMASS && props.watermass && props.watermass !== '',
        },
        TOWN: {
            name: i18n.city,
            serviceUrl: ApplicationConf.layers.getCityKML,
            color: MapStyles.FILL_BLACK,
            layerType: KML,
            codeToFetch: props.town,
            shouldAdd: (exists.TOWN),
            shouldExists: !exists.TOWN && props.town && props.town!== '',
        },
        STATION: {
            name: i18n.station,
            shouldExists: !exists.STATION && props.layers.includes('STATION') && station.id &&
            (hasLocalisation(station) || (station.townCode)),
        },
        UDI: {
            name: i18n.distributionUnit,
            serviceUrl: ApplicationConf.layers.getKML,
            color: MapStyles.FILL_RED,
            layerType: KML,
            codeToFetch: `${station.code}-UDI`,
            shouldAdd: (exists.UDI),
            shouldExists: !exists.UDI && props.stationType === 'distributionUnit' && station.code,
        },
        STATION_WATERSHED: {
            name: `${i18n.watershed} : ${props.stationWatershed.name}`,
            serviceUrl: ApplicationConf.layers.getWatershedKML,
            color: MapStyles.FILL_BLUE,
            layerType: KML,
            codeToFetch: props.stationWatershed.code,
            shouldAdd: exists.STATION_WATERSHED,
            shouldExists: !exists.STATION_WATERSHED && props.stationWatershed && props.stationWatershed.code,
        },
        STATION_WATERSHED_1: {
            name: `${i18n.watershed} : ${props.watershed1.name}`,
            serviceUrl: ApplicationConf.layers.getWatershedKML,
            color: MapStyles.FILL_BLUE,
            layerType: KML,
            codeToFetch: props.watershed1.code,
            shouldAdd: exists.STATION_WATERSHED_1,
            shouldExists: !exists.STATION_WATERSHED_1 && props.watershed1 && props.watershed1.code,
        },
        STATION_WATERSHED_2: {
            name: `${i18n.watershed} : ${props.watershed2.name}`,
            serviceUrl: ApplicationConf.layers.getWatershedKML,
            color: MapStyles.FILL_BLUE,
            layerType: KML,
            codeToFetch: props.watershed2.code,
            shouldAdd: exists.STATION_WATERSHED_2,
            shouldExists: !exists.STATION_WATERSHED_2 && props.watershed2 && props.watershed2.code,
        },
        STATION_WATERSHED_3: {
            name: `${i18n.watershed} : ${props.watershed3.name}`,
            serviceUrl: ApplicationConf.layers.getWatershedKML,
            color: MapStyles.FILL_BLUE,
            layerType: KML,
            codeToFetch: props.watershed3.code,
            shouldAdd: exists.STATION_WATERSHED_3,
            shouldExists: !exists.STATION_WATERSHED_3 && props.watershed3 && props.watershed3.code,
        },
        STATION_WATERSHED_4: {
            name: `${i18n.watershed} : ${props.watershed4.name}`,
            serviceUrl: ApplicationConf.layers.getWatershedKML,
            color: MapStyles.FILL_BLUE,
            layerType: KML,
            codeToFetch: props.watershed4.code,
            shouldAdd: exists.STATION_WATERSHED_4,
            shouldExists: !exists.STATION_WATERSHED_4 && props.watershed4 && props.watershed4.code,
        },
        STATION_KML: {
            name: `${i18n.station} : ${station.name}`,
            serviceUrl: ApplicationConf.layers.getKML,
            color: MapStyles.FILL_RED,
            layerType: KML,
            codeToFetch: station.code,
            shouldAdd: (exists.STATION_KML),
            shouldExists: !exists.STATION_KML && props.stationKMLExists && props.stationKMLExists.exists,
        },
        NEAR_PERIMETER: {
            name: i18n.nearPerimeter,
            serviceUrl: ApplicationConf.layers.getKML,
            color: MapStyles.FILL_YELLOW,
            layerType: KML,
            codeToFetch: 'PP_rapproche_14',
            shouldAdd: (exists.nearPerimeter),
            shouldExists: !exists.NEAR_PERIMETER &&
                ((parseInt(station.stationType) === STATION_TYPE.POINT_EAU_SOUTERRAINE
                || parseInt(station.stationType) === STATION_TYPE.EAU_TRAITEE
                || parseInt(station.stationType) === STATION_TYPE.UNITE_DE_PRODUCTION)),
        },
        FAR_PERIMETER: {
            name: i18n.farPerimeter,
            serviceUrl: ApplicationConf.layers.getKML,
            color: MapStyles.FILL_ORANGE,
            layerType: KML,
            codeToFetch: 'PP_eloigne_14',
            shouldAdd: (exists.NEAR_PERIMETER),
            shouldExists: !exists.NEAR_PERIMETER &&
                ((parseInt(station.stationType) === STATION_TYPE.POINT_EAU_SOUTERRAINE
                || parseInt(station.stationType) === STATION_TYPE.EAU_TRAITEE
                || parseInt(station.stationType) === STATION_TYPE.UNITE_DE_PRODUCTION)),
        },
        ONE_THEME_LAYER: {
            shouldExists: !exists.ONE_THEME_LAYER && props.oneThemeLayer.name,
        },
        POLLUTED_SOIL: {
            name: i18n.pollutedSoils,
            shouldExists: !exists.POLLUTED_SOIL && props.externalSites && props.externalSites.length !== 0 && props.sitesTypes.length !== 0,
        },
        INDUSTRIAL_SITE: {
            name: i18n.industrialSites,
            shouldExists: !exists.INDUSTRIAL_SITE && props.externalSites && props.externalSites.length !== 0 && props.sitesTypes.length !== 0,
        },
        QUALITOMETER: {
            name: i18n.qualitometers,
        },
        PIEZOMETER: {
            name: i18n.piezometers,
        },
        HYDROMETRIC_STATION: {
            name: i18n.hydrometricStations,
        },
        PLUVIOMETER: {
            name: i18n.pluviometers,
        },
        PRODUCTION_UNIT: {
            name: i18n.productionUnit,
        },
        DISTRIBUTION_UNIT: {
            name: i18n.distributionUnit,
        },
        INSTALLATION: {
            name: i18n.installations,
        },
        STATIONS_POINTS: {
            name: i18n.stations,
            shouldExists: !exists.STATIONS_POINTS &&
            (props.stationsPoints && props.stationsPoints.length !== 0) ||
            (props.analysis && props.analysis.length !== 0),
        },
    }
}

const LAYERS = {
    WATERMASS: 'WATERMASS',
    TOWN: 'TOWN',
    STATION: 'STATION',
    UDI: 'UDI',
    STATION_WATERSHED: 'STATION_WATERSHED',
    STATION_WATERSHED_1: 'STATION_WATERSHED_1',
    STATION_WATERSHED_2: 'STATION_WATERSHED_2',
    STATION_WATERSHED_3: 'STATION_WATERSHED_3',
    STATION_WATERSHED_4: 'STATION_WATERSHED_4',
    STATION_KML: 'STATION_KML',
    NEAR_PERIMETER: 'NEAR_PERIMETER',
    FAR_PERIMETER: 'FAR_PERIMETER',
    ONE_THEME_LAYER: 'ONE_THEME_LAYER',
    POLLUTED_SOIL: 'POLLUTED_SOIL',
    INDUSTRIAL_SITE: 'INDUSTRIAL_SITE',
    STATIONS_POINTS: 'STATIONS_POINTS',
}

export { allLayersProps, LAYERS }
