import ExportFileModal from 'components/modal/ExportFileModal'
import React, { useMemo } from 'react'
import i18n from 'simple-react-i18n'
import PropTypes from 'prop-types'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import ExportAction from 'export/actions/ExportAction'
import { formatData } from 'utils/ExportDataUtil'
import DtoOperation from 'station/components/suivipc/qualitometer/dto/DtoOperation'
import { groupBy, isUndefined, orderBy, round, uniqBy } from 'lodash'
import { calculateAverage, calculateGeometricAverage, calculateRangeInterquartile, calculateStandardDeviation, filterDetection, filterQuantification, filterResult, filterValid, searchMaxValue, searchMinValue, searchP90Value } from 'utils/AnalyseUtils'
import { getYear } from 'utils/DateUtil'
import ToastrAction from 'toastr/actions/ToastrAction'
import { useRegroupPiezoDatas } from '../hook/usePiezoDatas'
import { useRegroupHydroDatas } from '../hook/useHydroDatas'
import { useRegroupPluvioDatas } from '../hook/usePluvioDatas'
import useListIndexed from 'utils/customHook/useListIndexed'
import DtoQualityThresholds from 'quality/dto/QualityThreshold/DtoQualityThresholds'
import { getAnalyseByGroup } from 'quality/utils/PcMonitoringUtil'

const PcMonitoringRegroupExportModal = ({
    analysis = [],
    threshold,

    hydroDatas = [],
    piezoDatas = [],
    pluvioDatas = [],

    horizon = 'year',
    regrouping = 'max',

    groupEquivalences = false,

    exportModel = [],

    isExportModalOpen = false,
    closeExportModal = () => { },
}) => {
    const dispatch = useDispatch()

    const {
        parameters,
        parameterGroupUsage,
        units,
        fractions,
        supports,
        settings,
        thresholds,
    } = useSelector(store => ({
        parameters: store.ParameterReducer.parameters,
        parameterGroupUsage: store.ParameterReducer.parameterGroupUsage,
        units: store.UnitReducer.units,
        fractions: store.FractionReducer.fractions,
        supports: store.SupportReducer.supports,
        settings: store.AdministrationReducer.settings,
        thresholds: store.QualityReducer.thresholds,
    }), shallowEqual)

    const parametersIndex = useListIndexed(parameters, 'code')
    const unitsIndex = useListIndexed(units, 'code')
    const fractionsIndex = useListIndexed(fractions, 'id')
    const supportsIndex = useListIndexed(supports, 'id')

    const dates = useMemo(() => {
        if (horizon === 'year') {
            return orderBy(uniqBy(analysis, a => getYear(a.analysisDate)), 'analysisDate').map(a => `${getYear(a.analysisDate)}`)
        }
        return ['history']
    }, [horizon, analysis])

    const shouldCalculateFlow = hydroDatas.some(({ calculateFlux }) => calculateFlux)

    const piezoDatasFormatted = useRegroupPiezoDatas(piezoDatas, dates, regrouping)
    const {
        hydroDatasFormatted,
        hydroFluxData,
    } = useRegroupHydroDatas(hydroDatas, dates, regrouping)
    const pluvioDatasFormatted = useRegroupPluvioDatas(pluvioDatas, dates, regrouping)

    const formatUnitLabel = unit => {
        if (isUndefined(unit)) {
            return ''
        }
        if (shouldCalculateFlow) {
            return `${unit.symbol} * m3/s`
        }
        return unit.symbol
    }

    const formatExportData = () => {
        const analysisGroupByParameter = groupBy(analysis, ({ parameter, unit, fraction, support }) => groupEquivalences ? parameter : `${parameter}#:#${unit}#:#${fraction}#:#${support}`)
        return Object.keys(analysisGroupByParameter).map(paramKey => {
            const analysisGroup = analysisGroupByParameter[paramKey].map(a => {
                const dateFormat = horizon === 'year' ? getYear(a.analysisDate) : 'history'
                const hydroValue = hydroFluxData.reduce((sumFlux, { measures, coeffFlux = 1 }) => {
                    const m = measures[dateFormat]
                    return m ? sumFlux + m.value * coeffFlux : sumFlux
                }, 0)
                const valueFlux = shouldCalculateFlow ? `${round(a.value.replace('<', '').replace('>', '') * hydroValue, 5)}` : a.value
                return {
                    ...a,
                    valueFlux,
                }
            })

            const [parameterCode] = paramKey.split('#:#')
            const parameter = parametersIndex[parameterCode]

            const analysisGroupByYear = groupBy(analysisGroup, a => horizon === 'year' ? getYear(a.analysisDate) : 'history')

            const valuesObj = Object.keys(analysisGroupByYear).reduce((acc, year) => {
                const analyse = getAnalyseByGroup(analysisGroupByYear[year], regrouping, settings)
                if (isUndefined(analyse)) {
                    return acc
                }
                acc[year] = {
                    value: analyse.valueFlux,
                    color: analyse.color === 'white' && isUndefined(analyse.result) ? 'grey' : analyse.color,
                    cellType: 'right',
                }
                return acc
            }, {})

            const unitCodes = uniqBy(analysisGroup, 'unit').map(a => a.unit).filter(u => !isUndefined(u))
            const fractionCodes = uniqBy(analysisGroup, 'fraction').map(a => a.fraction).filter(u => !isUndefined(u))
            const supportCodes = uniqBy(analysisGroup, 'support').map(a => a.support).filter(u => !isUndefined(u))
            const group = parameterGroupUsage.find(p => p.parameter === parameterCode)

            const validAnalysis = filterValid(filterResult(analysisGroup))

            const p90 = searchP90Value(validAnalysis)
            const min = searchMinValue(validAnalysis)
            const max = searchMaxValue(validAnalysis)
            const average = calculateAverage(validAnalysis, settings)
            const geometricAverage = calculateGeometricAverage(validAnalysis)
            const rangeInterquartile = calculateRangeInterquartile(validAnalysis)
            const standardDeviation = calculateStandardDeviation(validAnalysis)

            const nbAnalysis = validAnalysis.length
            const nbQuantification = filterQuantification(validAnalysis).length
            const nbDetection = filterDetection(validAnalysis).length
            const nbOverrun = validAnalysis.filter(a => !['blue', 'white'].includes(a.color)).length

            const overrunTx = nbAnalysis ? `${round(nbOverrun * 100 / nbAnalysis, 2)} %` : '0%'
            const quantTx = nbAnalysis ? `${round(nbQuantification * 100 / nbAnalysis, 3)} %` : '0%'
            const detectTx = nbAnalysis ? `${round(nbDetection * 100 / nbAnalysis, 3)} %` : '0%'

            return {
                codeGroup: { value: group?.groupCode, format: '0', cellType: 'number' },
                group: group?.groupName,
                codeParameter: { value: parameterCode, cellType: 'right' },
                parameter: parameter?.name,
                codeUnit: { value: unitCodes.join(', '), cellType: 'right' },
                unit: unitCodes.map(code => formatUnitLabel(unitsIndex[code])).join(', '),
                supportCode: { value: supportCodes.join(', '), cellType: 'right' },
                support: supportCodes.map(code => supportsIndex[code]?.name).join(', '),
                fractionCode: { value: fractionCodes.join(', '), cellType: 'right' },
                fraction: fractionCodes.map(code => fractionsIndex[code]?.name).join(', '),
                ...valuesObj,
                min: { value: min, color: 'grey', cellType: 'right' },
                averageShort: { value: average, color: 'grey', cellType: 'right' },
                max: { value: max, color: 'grey', cellType: 'right' },
                percentile90: { value: p90, color: 'grey', cellType: 'right' },
                geometricAverageShort: { value: geometricAverage, color: 'grey', cellType: 'right' },
                rangeInterquartile: { value: rangeInterquartile, color: 'grey', cellType: 'right' },
                standardDeviation: { value: standardDeviation, color: 'grey', cellType: 'right' },
                overrunNb: { value: nbOverrun, color: 'grey', cellType: 'right' },
                overrunTx: { value: overrunTx, color: 'grey', cellType: 'right' },
                nbAnalysis: { value: nbAnalysis, color: 'grey', cellType: 'right' },
                nbQuant: { value: nbQuantification, color: 'grey', cellType: 'right' },
                nbDetect: { value: nbDetection, color: 'grey', cellType: 'right' },
                quantTx: { value: quantTx, color: 'grey', cellType: 'right' },
                detectTx: { value: detectTx, color: 'grey', cellType: 'right' },
                threshold1: { value: analysisGroup[0].threshold?.threshold1, format: '0.000', cellType: 'number' },
                threshold2: { value: analysisGroup[0].threshold?.threshold2, format: '0.000', cellType: 'number' },
                threshold3: { value: analysisGroup[0].threshold?.threshold3, format: '0.000', cellType: 'number' },
                threshold4: { value: analysisGroup[0].threshold?.threshold4, format: '0.000', cellType: 'number' },
            }
        })
    }

    const formatSubstances = exportDatas => {
        if (!threshold) {
            return []
        }
        const thresholdFound = thresholds.find(t => `${t.code}` === threshold.thresholdCode)

        const analysisValues = dates.reduce((acc, key) => {
            const keyData = exportDatas.map(d => d[key]).filter(d => d?.color && !['white', 'blue', 'grey'].includes(d.color))
            const overrunedParameters = keyData.map(d => {
                return (
                    <span>
                        {`\u2022 ${parametersIndex[d.parameter]?.displayName}: ${d.result} - ${i18n.threshold}(${d.thresholdValue})`}
                        <br />
                    </span>
                )
            })
            acc[key] = {
                value: keyData.length,
                cellType: 'right',
                color: overrunedParameters.length > 0 ? 'red' : 'grey',
            }
            return acc
        }, {})
        const values = dates.map(key => analysisValues[key].value)
        const overrunNb = values.filter(v => v !== 0).length

        const overrunTx = values.length ? `${round(overrunNb * 100 / values.length, 2)} %` : '0%'
        return [{
            parameter: { value: thresholdFound?.name, color: 'grey' },
            group: { value: '', color: 'grey' },
            unit: { value: '', color: 'grey' },
            support: { value: '', color: 'grey' },
            fraction: { value: '', color: 'grey' },
            ...analysisValues,
            overrunTx: {
                value: overrunTx,
                color: 'grey',
                cellType: 'right',
            },
            overrunNb: {
                value: overrunNb,
                color: 'grey',
                cellType: 'right',
            },
        }]
    }

    const headers = [
        'codeGroup',
        'group',
        'codeParameter',
        'parameter',
        'codeUnit',
        'unit',
        'supportCode',
        'support',
        'fractionCode',
        'fraction',
        ...dates,
        'min',
        'averageShort',
        'max',
        'percentile90',
        'geometricAverageShort',
        'nbDetect',
        'nbQuant',
        'nbAnalysis',
        'detectTx',
        'quantTx',
        'threshold1',
        'threshold2',
        'threshold3',
        'threshold4',
    ]

    const onExport = type => {
        const data = formatExportData()
        const dataOrdered = [
            ...formatSubstances(data),
            ...piezoDatasFormatted,
            ...pluvioDatasFormatted,
            ...hydroDatasFormatted,
            ...orderBy(data, 'codeParameter.value'),
        ]
        if (dataOrdered.length) {
            const exportData = [
                {
                    ...dataOrdered[0],
                    headers,
                },
                ...dataOrdered.slice(1),
            ]
            dispatch(ExportAction.export(formatData(exportData), type, i18n.followUpPC))
        } else {
            dispatch(ToastrAction.warning(i18n.noDataToExport))
        }
    }

    return (
        <ExportFileModal
            open={isExportModalOpen}
            onClose={() => closeExportModal(false)}
            data={[
                {
                    name: i18n.resultsTable,
                    formats: [{
                        type: i18n.excelFile,
                        callback: () => onExport('xlsx'),
                    }, {
                        type: i18n.csvFile,
                        callback: () => onExport('csv'),
                    }],
                },
                ...exportModel,
            ]}
        />
    )
}

PcMonitoringRegroupExportModal.propTypes = {
    analysis: PropTypes.arrayOf(PropTypes.shape({/* ...DtoAnalysis, ...calculateThresholdResult */ })),
    operations: PropTypes.arrayOf(PropTypes.instanceOf(DtoOperation)),
    threshold: PropTypes.instanceOf(DtoQualityThresholds),

    hydroDatas: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        type: PropTypes.string,
        calculateFlux: PropTypes.bool,
        coeffFlux: PropTypes.number,
        measures: PropTypes.arrayOf(PropTypes.shape({})),
    })),
    piezoDatas: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        type: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
        ]),
        measures: PropTypes.arrayOf(PropTypes.shape({})),
    })),
    pluvioDatas: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        type: PropTypes.number,
        offset: PropTypes.number,
        cumul: PropTypes.number,
        measures: PropTypes.arrayOf(PropTypes.shape({})),
    })),

    horizon: PropTypes.oneOf(['operations', 'year', 'historic']),
    regrouping: PropTypes.oneOf(['max', 'moyenne', 'percentile']),

    groupEquivalences: PropTypes.bool,
    exportModel: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        formats: PropTypes.arrayOf(PropTypes.shape({
            type: PropTypes.string,
            callback: PropTypes.func,
        })),
    })),

    isExportModalOpen: PropTypes.bool,
    closeExportModal: PropTypes.func,
}

export default PcMonitoringRegroupExportModal