import ReferencialDto from '../../../dto/ReferencialDto'

export default class FractionDto extends ReferencialDto {
    constructor(object) {
        super(object)
        this.id = object.code
        this.comment = object.comment
        this.internationalName = object.internationalName
        this.state = object.state
        this.author = object.author
        this.creationDate = object.creationDate
        this.updateDate = object.updateDate
        this.nature = object.nature
        this.actif = object.actif
        this.supportCode = object.supportCode
        this.jobExecutionId= object.jobExecutionI
    }
}