import { last, orderBy } from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import ColorfulCard from '../../../components/card/ColorfulCard'
import DtoEvent from '../../../events/dto/DtoEvent'
import CityDto from '../../../referencial/components/city/dto/CityDto'
import { STATION_NAME_ASSOCIATION } from '../../../station/constants/StationConstants'
import { getEventColor } from '../../../utils/ColorUtil'
import { getDateWithHour } from '../../../utils/DateUtil'
import { getSiteUrl } from '../../../utils/mapUtils/SiteTypes'
import { getStations } from '../../../utils/StationUtils'
import { arrayOf, getLabel } from '../../../utils/StoreUtils'
import EventCard from './EventCard'

class LastEventPanel extends Component {
    getLastEvent = () => {
        const { stationId, stationType, events } = this.props

        if (events?.length) {
            const data = last(orderBy(events.filter(e => (e.eventType !== 'T' || !e.problem) && e.date), e => getDateWithHour(e.date, e.eventHour).valueOf())) // on n'affiche pas les évènements techniques
            if (data) {
                const station = getStations(this.props, data.stationType).find(s => s.code === data.stationCode) || null
                return (
                    <div className='row margin-bottom-1 no-margin-top'>
                        <div className='col s12 no-padding'>
                            <ColorfulCard color={getEventColor(data.eventType)}>
                                <div className='row no-margin'>
                                    <div className='col s12 no-padding'>
                                        {
                                            !!station && data.stationCode !== stationId && (
                                                <div>
                                                    <div className='row no-margin valign-wrapper' style={{ paddingTop: '5px', paddingBottom: '5px' }}>
                                                        <div className='iconeTypeResearchWrapper margin-left-25'>
                                                            <img src={getSiteUrl(STATION_NAME_ASSOCIATION[station.typeName])} className='responsive-img iconeTypeResearch' />
                                                        </div>
                                                        <div className='no-padding' style={{ marginLeft: '15px' }}>
                                                            {`${station.code} ${getLabel(this.props.cities, station.townCode)} - ${station.name}`}
                                                        </div>
                                                    </div>
                                                    <hr />
                                                </div>
                                            )
                                        }
                                        <EventCard
                                            event={data}
                                            small
                                            link={`/station/${data.stationType || stationType}/${data.stationId || this.props.stationId}/event/`}
                                            showStationInfo={['productionUnit', 'distributionUnit', 'installation'].includes(stationType)}
                                            stationType={stationType}
                                        />
                                    </div>
                                </div>
                            </ColorfulCard>
                        </div>
                    </div>
                )
            }
            return null
        }
        return null
    }

    render() {
        return this.getLastEvent()
    }
}

LastEventPanel.propTypes = {
    stationId: PropTypes.string,
    stationType: PropTypes.string,
    events: PropTypes.arrayOf(PropTypes.instanceOf(DtoEvent)),
    cities: arrayOf(CityDto),
}

const mapStateToProps = store => ({
    pluviometers: store.PluviometryReducer.pluviometers,
    piezometers: store.PiezometryReducer.piezometersLight,
    hydrometricStations: store.HydrometryReducer.hydrometricStations,
    qualitometers: store.QualityReducer.qualitometersLight,
    productionUnits: store.ProductionUnitReducer.productionUnits,
    distributionUnits: store.DistributionUnitReducer.distributionUnits,
    installations: store.InstallationReducer.installations,

    cities: store.CityReducer.cities,
})

export default connect(mapStateToProps)(LastEventPanel)
