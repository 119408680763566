export default class UserDto {
    constructor(user) {
        this.login = user.login
        this.name = user.name
        this.firstname = user.firstname
        this.admin = user.isAdmin || user.admin
        this.metadata = user.metadata
        this.labo = user.labo
        this.blocked = user.blocked
        this.lastConnexion = user.lastConnexion
        this.picture = user.picture
        this.password = user.password
        this.mail = user.mail
        this.mobile = user.mobile
        this.tel = user.tel
        this.postal = user.postal
        this.town = user.town
        this.updateDate = user.updateDate
        this.loginMaj = user.loginMaj
        this.beginDate = user.beginDate
        this.endDate = user.endDate
        this.contactCode = user.contactCode
        this.contributorCode = user.contributorCode
        this.resetPassword = user.resetPassword
        this.connexionAttempts = user.connexionAttempts
        this.pictureName = user.pictureName
        this.isApplicative = user.isApplicative
        this.token = user.token // Only to applicative users
        this.headers = ['login', 'name', 'mail', 'mobile', 'tel', 'admin', 'metadata', 'organism', 'lastConnexion']

        this.consultant = user.consultant
    }
}