import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import Input from '../../../../components/forms/Input'
import Icon from '../../../../components/icon/Icon'
import { searchAllCharacters } from '../../../../utils/StringUtil'
import SimpleSelectionTable from 'components/datatable/SimpleSelectionTable'
import { Grid } from '@mui/material'
import useDebounce from 'utils/customHook/useDebounce'

const selectionFilterFunction = (list, filters) => {
    const {
        searchValue,
    } = filters

    const searchValueFormat = searchAllCharacters(searchValue)
    return searchValue ? list.filter(s => s.labelSearch.includes(searchValueFormat)) : list
}

const SelectionFilterField = ({
    filter = {},
    setFilter = () => { },

    onCreate = () => {},
}) => {
    const [stateFilter, setStateFilter] = useState(filter)
    /*
        searchValue
    */

    useDebounce(() => {
        setFilter(stateFilter)
    }, 500, [stateFilter])

    return (
        <Grid container style={{ paddingTop: '5px' }} spacing={1} alignItems='center'>
            <Grid item xs>
                <Input
                    title={i18n.search}
                    value={stateFilter.searchValue}
                    onChange={searchValue => setStateFilter(prev => ({ ...prev, searchValue }))}
                />
            </Grid>
            <Grid item xs='auto'>
                <Icon
                    size='small'
                    icon='note_add'
                    onClick={onCreate}
                    tooltip={i18n.createNewSelection}
                />
            </Grid>
        </Grid>
    )
}

SelectionFilterField.propTypes = {
    filter: PropTypes.shape({
        searchValue: PropTypes.string,
    }),
    setFilter: PropTypes.func,
    onCreate: PropTypes.func,
}

const SelectSelection = ({
    selectedSelections = [],
    onCreate = [],
    onChange = [],
}) => {
    const {
        selections,
    } = useSelector(store => ({
        selections: store.ParameterReducer.selectionsWithParam,
    }), shallowEqual)

    const formattedSelections = selections.map(selection => {
        const {
            code,
            name = '',
            parameters = [],
        } = selection
        return {
            id: code,
            name,
            nbParam: `${parameters.length}`,
            labelSearch: searchAllCharacters([code, name].filter(l => !!l).join(',')),
        }
    })

    return (
        <SimpleSelectionTable
            onChange={onChange}

            listData={formattedSelections}
            selectedList={selectedSelections}

            listHeaders={['name', 'nbParam']}
            listTitle={i18n.nonSelectedSelections}
            selectedListTitle={i18n.selectedSelections}
            maxHeightTable='43vh'

            filterField={({ filter, setFilter }) => <SelectionFilterField filter={filter} setFilter={setFilter} onCreate={onCreate} />}
            filterFunction={selectionFilterFunction}
        />
    )
}

SelectSelection.propTypes = {
    selectedSelections: PropTypes.arrayOf(PropTypes.string),
    onChange: PropTypes.func,
    onCreate: PropTypes.func,
}

export default SelectSelection