/* eslint-disable camelcase */
import { Button, Dialog } from '@mui/material'
import Select from 'components/forms/Select'
import SimpleDatePicker from 'components/forms/SimpleDatePicker'
import Textarea from 'components/forms/Textarea'
import Icon from 'components/icon/Icon'
import Row from 'components/react/Row'
import { DialogActionsMUI, DialogContentMUI, DialogTitleMUI } from 'components/styled/Dialog'
import DtoMatEvent from 'materiel/components/variousMateriel/dto/DtoMatEvent'
import DtoMatEventsType from 'materiel/components/variousMateriel/dto/DtoMatEventsType'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import DtoVariousMateriel from 'station/dto/materiel/DtoVariousMateriel'
import ToastrAction from 'toastr/actions/ToastrAction'

const emptyEvent = {
    materielType: 'divers',
    idMateriel: null,
    idType: null,
    eventDate: null,
    comment: null,
}

class MatEventModal extends Component {
    constructor(props) {
        super(props)
        const idMateriel = props.materiels.length ? props.materiels[0]?.id : null
        this.state = {
            event: props.event || { ...emptyEvent, idMateriel, idType: 1 },
        }
    }

    handleChange = (obj) => {
        this.setState(({ event }) => ({ event: { ...event, ...obj } }))
    }

    onCancel = () => {
        this.setState({ event: { ...emptyEvent } })
        this.props.onCancel()
    }

    onDelete = () => {
        const { event } = this.props
        this.setState({ event: { ...emptyEvent } })
        this.props.onDelete(event)
    }

    onValidate = () => {
        const { event } = this.state
        if (event.idMateriel && event.idType && event.eventDate) {
            this.setState({ event: { ...emptyEvent } })
            this.props.onValidate({ ...event, eventDate: new Date(event.eventDate).getTime() })
        } else {
            this.props.warning(i18n.pleaseCompleteAllRequiredField)
        }
    }

    render() {
        const { event } = this.state
        const { idMateriel, idType, eventDate, comment } = event
        const { open, matEventsTypes, materiels } = this.props
        return (
            <Dialog
                fullWidth
                maxWidth='md'
                style={{ margin: 'auto' }}
                open={open}
            >
                <DialogTitleMUI style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    {i18n.reportFailure}
                    <Icon style={{ color: 'white' }} size='small' icon='close' onClick={this.onCancel} />
                </DialogTitleMUI>
                <DialogContentMUI>
                    <Row className='padding-top-1'>
                        <Select
                            id='equipment'
                            col={4}
                            required
                            noNullValue
                            label={i18n.equipment}
                            value={idMateriel}
                            onChange={(v) => this.handleChange({ idMateriel: v })}
                            integerValue
                            options={materiels}
                            keyvalue='id'
                            keyLabel='reference'
                            obligatory
                        />
                        <Select
                            id='idType'
                            col={4}
                            required
                            noNullValue
                            label={i18n.eventType}
                            value={idType}
                            onChange={(v) => this.handleChange({ idType: v })}
                            integerValue
                            options={matEventsTypes}
                            keyvalue='id'
                            obligatory
                        />
                        <SimpleDatePicker
                            id='eventDate'
                            col={4}
                            label={i18n.date}
                            value={eventDate}
                            onChange={(v) => this.handleChange({ eventDate: v })}
                            obligatory
                        />
                        <Textarea
                            col={12}
                            id='comment'
                            title={i18n.comment}
                            value={comment}
                            onChange={(v) => this.handleChange({ comment: v })}
                            rows={10}
                        />
                    </Row>
                </DialogContentMUI>
                <DialogActionsMUI style={event.id && { justifyContent: 'space-between' }}>
                    {event.id && (
                        <Button
                            variant='outlined'
                            className='red'
                            style={{ color: 'white' }}
                            onClick={this.onDelete}
                        >
                            {i18n.delete}
                        </Button>
                    )}
                    <Button
                        variant='contained'
                        color='primary'
                        onClick={this.onValidate}
                    >
                        {i18n.validate}
                    </Button>
                </DialogActionsMUI>
            </Dialog>
        )
    }
}

MatEventModal.propTypes = {
    event: PropTypes.instanceOf(DtoMatEvent),
    classes: PropTypes.instanceOf(PropTypes.object),
    onCancel: PropTypes.func,
    onValidate: PropTypes.func,
    onDelete: PropTypes.func,
    warning: PropTypes.func,
    open: PropTypes.bool,
    materiels: PropTypes.arrayOf(PropTypes.instanceOf(DtoVariousMateriel)),
    matEventsTypes: PropTypes.arrayOf(PropTypes.instanceOf(DtoMatEventsType)),
}

const mapStateToProps = (store) => {
    return {
        exploitation: store.AgriReducer.exploitation,
        matEventsTypes: store.VariousMaterielReducer.matEventsTypes,
    }
}

const mapDispatchToProps = {
    warning: ToastrAction.warning,
}

export default connect(mapStateToProps, mapDispatchToProps)(MatEventModal)
