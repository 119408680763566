import { Grid } from '@mui/material'
import Table from 'components/datatable/Table'
import Input from 'components/forms/Input'
import NumberField from 'components/forms/NumberField'
import { PropTypes } from 'prop-types'
import React, { useEffect, useMemo, useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { nbPerPageLabelTiny } from 'referencial/constants/ReferencialConstants'
import i18n from 'simple-react-i18n'
import Checkbox from '../../../components/forms/Checkbox'
import RadioButtons from '../../../components/forms/RadioButtons'
import MUIIcon from '@mui/material/Icon'
import { StyledFieldSet, StyledLegend } from 'components/StyledElements'
import useListIndexed from 'utils/customHook/useListIndexed'
import { AUTO_Y_SCALE, MANUAL_Y_SCALE, MONTH, WEEK, YEAR, AUTO } from 'quality/constants/ChartConstant'
import useDebounce from 'utils/customHook/useDebounce'

const UpDownComponent = ({
    setParameters = () => {},
    index,
    nbParameters,
}) => {
    const upDisabled = index === 0
    const downDisabled = index === nbParameters - 1

    const updateDatas = (newIndex) => {
        setParameters(parameters => {
            const param = parameters[index]
            const paramToSwap = parameters[newIndex]
            if (newIndex > index) { // Up
                const beforeElement = parameters.slice(0, index)
                const afterElement = parameters.slice(index + 2, parameters.length)
                setParameters([...beforeElement, paramToSwap, param, ...afterElement])
            } else if (newIndex < index) { // Down
                const beforeElement = parameters.slice(0, index - 1)
                const afterElement = parameters.slice(index + 1, parameters.length)
                setParameters([...beforeElement, param, paramToSwap, ...afterElement])
            }
        })
    }

    return (
        <Grid container sx={{ textAlign: 'center' }}>
            <Grid item xs={12}>
                <MUIIcon
                    sx={{
                        cursor: !upDisabled && 'pointer',
                        fontSize: '1.5rem',
                        color: upDisabled && '#9e9e9e',
                        transition: 'transform ease-in-out 0.3s',
                        '&:hover': {
                            transform: !upDisabled && 'scale(1.5)',
                        },
                    }}
                    onClick={() => !upDisabled && updateDatas(index - 1)}
                >
                    keyboard_arrow_up_rounded_icon
                </MUIIcon>
            </Grid>
            <Grid item xs={12}>
                <MUIIcon
                    sx={{
                        cursor: !downDisabled && 'pointer',
                        fontSize: '1.5rem',
                        color: downDisabled && '#9e9e9e',
                        transition: 'transform ease-in-out 0.3s',
                        '&:hover': {
                            transform: !downDisabled && 'scale(1.5)',
                        },
                    }}
                    onClick={() => !downDisabled && updateDatas(index + 1)}
                >
                    keyboard_arrow_down_rounded_icon
                </MUIIcon>
            </Grid>
        </Grid>
    )
}

UpDownComponent.propTypes = {
    setParameters: PropTypes.func,
    index: PropTypes.number,
    nbParameters: PropTypes.number,
}

const nbPointMovingAveragePoints = [
    { value: 1, label: '3' },
    { value: 2, label: '5' },
    { value: 3, label: '7' },
    { value: 4, label: '9' },
]

const QualityGraphicOptions = ({
    onChangeOptions = () => {},
    options = {},
}) => {
    const {
        propsParameters,
    } = useSelector(store => ({
        propsParameters: store.ParameterReducer.parameters,
    }), shallowEqual)

    const parametersIndex = useListIndexed(propsParameters, 'code')

    const [regroupAxis, setRegroupAxis] = useState(options.regroupAxis)
    const [displayAverages, setDisplayAverages] = useState(options.displayAverages)
    const [nbPointMovingAverage, setNbPointMovingAverage] = useState(options.nbPointMovingAverage)
    const [graphicTitle, setGraphicTitle] = useState(options.graphicTitle)
    const [minY, setMinY] = useState(options.minY)
    const [maxY, setMaxY] = useState(options.maxY)
    const [maxXAxisSpace, setMaxXAxisSpace] = useState(options.maxXAxisSpace || AUTO)
    const [yChoice, setYChoice] = useState(options.yChoice)
    const [showXSplitLines, setShowXSplitLines] = useState(options.showXSplitLines)
    const [showYSplitLines, setShowYSplitLines] = useState(options.showYSplitLines)
    const [parameters, setParameters] = useState(options.parameters)

    useEffect(() => {
        setParameters(options.parameters || [])
    }, [options.parameters])

    const parametersFormatted = useMemo(() => parameters.map((code, i) => {
        const parameter = parametersIndex[code]
        return {
            code,
            name: parameter?.displayName?.slice(0, 95),
            showedIndex: i + 1,
            upDownValue: (
                <UpDownComponent
                    setParameters={setParameters}
                    index={i}
                    nbParameters={parameters.length}
                />
            ),
        }
    }), [parameters, parametersIndex])

    useDebounce(() => {
        onChangeOptions(prevOptions => ({
            ...prevOptions,
            regroupAxis,
            displayAverages,
            nbPointMovingAverage,
            graphicTitle,
            minY,
            maxY,
            maxXAxisSpace,
            yChoice,
            showXSplitLines,
            showYSplitLines,
            parameters,
        }))
    }, 500, [regroupAxis, displayAverages, nbPointMovingAverage, graphicTitle, minY, maxY, maxXAxisSpace, yChoice, showXSplitLines, showYSplitLines, parameters])

    useEffect(() => {
        if (yChoice === AUTO_Y_SCALE) {
            setMinY(undefined)
            setMaxY(undefined)
        }
    }, [yChoice])

    const maxXAxisSpaces = useMemo(() => [
        {
            code: AUTO,
            name: i18n.automatic,
        }, {
            code: YEAR,
            name: i18n.perYear,
        }, {
            code: MONTH,
            name: i18n.perMonth,
        }, {
            code: WEEK,
            name: i18n.perWeek,
        },
    ], [])

    const yMode = useMemo(() => [
        {
            code: AUTO_Y_SCALE,
            name: i18n.automatic,
        }, {
            code: MANUAL_Y_SCALE,
            name: i18n.manual,
        },
    ], [])

    const minMaxDisabled = yChoice !== MANUAL_Y_SCALE

    return (
        <Grid container rowSpacing={2} sx={{ padding: '10px' }}>
            <Grid item xs={12}>
                <Checkbox
                    checked={regroupAxis}
                    onChange={setRegroupAxis}
                    label={i18n.groupEquivalences}
                />
            </Grid>
            <Grid item xs={6}>
                <Checkbox
                    checked={ displayAverages }
                    onChange={ setDisplayAverages }
                    label={ i18n.displayAverages }
                />
            </Grid>
            <Grid item xs={6}>
                <RadioButtons
                    selected={ nbPointMovingAverage }
                    elements={ nbPointMovingAveragePoints }
                    title={ i18n.numberOfPoints }
                    onChange={ setNbPointMovingAverage }
                    disabled={ !displayAverages }
                />
            </Grid>
            <Grid item xs={12}>
                <Input
                    value={graphicTitle}
                    title={i18n.title}
                    onChange={setGraphicTitle}
                />
            </Grid>
            <Grid item xs={12}>
                <StyledFieldSet style={{ margin: 0 }}>
                    <StyledLegend>{i18n.yScale}</StyledLegend>
                    <Checkbox
                        componentClassName='margin-left-1 padding-top-1 padding-bottom-1'
                        checked={showYSplitLines}
                        label={`${i18n.show} / ${i18n.toggle} ${i18n.Ygrid}`}
                        onChange={setShowYSplitLines}
                    />
                    <RadioButtons
                        title={i18n.chartScale}
                        elements={yMode}
                        selected={yChoice}
                        onChange={setYChoice}
                        className='padding-bottom-1'
                    />
                    <NumberField
                        title={i18n.min}
                        value={minY}
                        onChange={setMinY}
                        disabled={minMaxDisabled}
                    />
                    <NumberField
                        title={i18n.max}
                        value={maxY}
                        onChange={setMaxY}
                        disabled={minMaxDisabled}
                    />
                </StyledFieldSet>
            </Grid>
            <Grid item xs={12}>
                <StyledFieldSet style={{ margin: 0 }}>
                    <StyledLegend>{i18n.xScale}</StyledLegend>
                    <Checkbox
                        componentClassName='margin-left-1 padding-top-1 padding-bottom-1'
                        checked={showXSplitLines}
                        label={`${i18n.show} / ${i18n.toggle} ${i18n.Xgrid}`}
                        onChange={setShowXSplitLines}
                    />
                    <RadioButtons
                        elements={maxXAxisSpaces}
                        selected={maxXAxisSpace}
                        onChange={setMaxXAxisSpace}
                        colOption={6}
                    />
                </StyledFieldSet>
            </Grid>
            <Grid item xs={12} sx={{ padding: '10px 0' }}>
                <Table
                    data={parametersFormatted}
                    title={i18n.parameters}
                    type={{ headers: ['showedIndex', 'code', 'name', 'upDownValue'] }}
                    condensed
                    paging
                    nbPerPageLabel={nbPerPageLabelTiny}
                />
            </Grid>
        </Grid>
    )
}

QualityGraphicOptions.propTypes = {
    onChangeOptions: PropTypes.func,
    options: PropTypes.shape({
        displayAverages: PropTypes.bool,
        stackAverages: PropTypes.bool,
        nbPointMovingAverage: PropTypes.number,

        // displayMeasures,
        // displayThresholds,
        // graphicTitle,
        // minY,
        // maxY,
        // maxXAxisSpace,
        // showXSplitLines,
        // showYSplitLines,
        // parameters,
    }),
}

export default QualityGraphicOptions