import React from 'react'
import i18n from 'simple-react-i18n'
import { exportFile } from './ExportDataUtil'

const setModal = (modalTitle, content, setPopup) => {
    const buttons = (
        <div className='margin-right-20'>
            <a className='waves-effect waves-teal btn-flat center secondary-button margin-right-1 modal-close'>{ i18n.cancel }</a>
            <a id='descriptionModal' className='waves-effect waves-teal center primary-button btn-flat'>{ i18n.register }</a>
        </div>
    )
    const popup = {
        id: 'descriptionModalId',
        header: modalTitle,
        actions: buttons,
        fixedFooter: true,
        dismissible: false,
        content,
    }
    setPopup(popup)
}

const getExport = (data, fileTitle, headers, agencyOption = false, exportAgencySurveys = {}, color = 'white') => {
    const exported = data.map(ex => ({
        ...ex,
        headers,
    }))
    return {
        icon: 'file_download',
        iconName: 'file_download',
        tooltip: i18n.export,
        color,
        onClick: () => agencyOption ? (
            exportFile({
                data: exported,
                exportType: 'xlsx',
                titleFile: fileTitle,
            }, null, null, null, exportAgencySurveys, agencyOption)
        ) : (
            exportFile({
                data: exported,
                exportType: 'xlsx',
                titleFile: fileTitle,
            })
        ),
    }
}
export { setModal, getExport }
