import loading from 'assets/pictures/loading.gif'
import { push } from '@lagunovsky/redux-react-router'
import React, { useEffect, useMemo, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import { sortBy } from 'lodash'
import QualityHeatMap from '../../home/components/graph/QualityHeatMap'
import DtoSimpleStation from 'home/dto/DtoSimpleStation'
import Card from '../../components/card/Card'
import Table from '../../components/datatable/Table'
import { getLabel } from '../../utils/StoreUtils'
import { getDate } from '../../utils/DateUtil'
import CampaignPortletPanel from '../../campaign/components/CampaignPortletPanel'
import MonitoredMaterielPortletPanel from '../../materiel/components/dashboard/MonitoredMaterielPortletPanel'
import { getCampaignProgress, getProgressBar, getQualityCampaignIcon } from '../../campaign/utils/CampaignUtils'
import { getColorCampaign } from '../../utils/CampaignUtil'
import ProgressCard from '../../components/card/ProgressCard'
import { getUserBookmarksByStationType } from '../../utils/UserUtil'
import { componentHasHabilitations } from '../../utils/HabilitationUtil'
import {
    H_MAT_MODULE,
    H_QUALITO_MODULE,
} from '../../account/constants/AccessRulesConstants'
import HabilitationRequired from '../../utils/components/HabilitationRequired'
import { STATION_TYPE_CONSTANT, STATION_TYPE_NAME } from 'station/constants/StationConstants'
import UserThunk from 'administration/components/user/actions/UserThunk'
import QualityThunk from 'quality/actions/QualityThunk'
import CampaignThunk from 'campaign/actions/CampaignThunk'
import useStateProgress from 'utils/customHook/useStateProgress'

const panelTable = 'table'
const panelGraph = 'graph'
const qualityHeaders = ['qualificationTableColor', 'nullValue', 'name', 'nbStations', 'startDate', 'progression', 'referent']

const QualityCard = () => {
    const {
        userBookmarks,
        qualitometers,
        qualityCampaigns,
        qualityCampaignsProgress,
        monitoredMateriels,
        citiesIndex,
        userBookmarksStatus,
        qualitometersListSpecificStatus,
        qualityCampaignsStatus,
        qualityCampaignsProgressStatus,
        contacts,
    } = useSelector(store => ({
        userBookmarks: store.UserReducer.userBookmarks,
        qualitometers: store.QualityReducer.qualitometersListSpecific,
        qualityCampaigns: store.QualityReducer.qualityCampaigns,
        qualityCampaignsProgress: store.QualityReducer.qualityCampaignsProgress,
        monitoredMateriels: store.MaterielReducer.monitoredMateriels,
        citiesIndex: store.CityReducer.citiesIndex,
        userBookmarksStatus: store.DataManagerReducer.user.userBookmarksStatus,
        qualitometersListSpecificStatus: store.DataManagerReducer.quality.qualitometersListSpecificStatus,
        qualityCampaignsStatus: store.DataManagerReducer.campaign.qualityCampaignsStatus,
        qualityCampaignsProgressStatus: store.DataManagerReducer.campaign.qualityCampaignsProgressStatus,
        contacts: store.ContactReducer.contacts,
    }), shallowEqual)

    const { progress: progressBar, isLoaded: dataLoaded } = useStateProgress([
        userBookmarksStatus,
        qualitometersListSpecificStatus,
        qualityCampaignsStatus,
        qualityCampaignsProgressStatus,
    ])

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(UserThunk.fetchBookmarks())
        dispatch(QualityThunk.fetchQualitometersListSpecific())
        dispatch(CampaignThunk.fetchQualityCampaigns())
        dispatch(CampaignThunk.fetchQualityCampaignsProgress())
    }, [])

    const [panelType, setPanelType] = useState(panelTable)

    const bookmarks = useMemo(() => getUserBookmarksByStationType(userBookmarks, STATION_TYPE_NAME.quality), [userBookmarks])

    const campaigns = useMemo(() => qualityCampaigns.reduce((acc, c) => {
        const {
            nbStation = 0,
            nbStationValidated = 0,
        } = qualityCampaignsProgress.find(cp => cp.id === c.id) || {}
        if (nbStation !== 0 && nbStationValidated < nbStation && c.statut === 2) {
            return [
                ...acc,
                c,
            ]
        } return acc
    }, []), [qualityCampaigns, qualityCampaignsProgress])

    const foundBookmarks = useMemo(() => bookmarks.reduce((acc, code) => {
        const station = qualitometers.find(s => s.code === code)
        if (station) {
            return [...acc, station]
        }
        return acc
    }, []), [qualitometers, bookmarks])

    const data = useMemo(() => ({
        stations: qualitometers,
        bookmarks,
        icon: 'pie_chart',
        campaigns: campaigns.map(campaign => {
            const {
                nbStation,
                progress = 0,
                progressTotal = 0,
            } = qualityCampaignsProgress.find(cp => cp.id === campaign.id) || {}
            const progressValue = getCampaignProgress(progress, progressTotal)
            return {
                id: campaign.id,
                qualificationTableColor: {
                    color: getColorCampaign(false, progressValue, 2),
                },
                nullValue: getQualityCampaignIcon(campaign.campaignType, 'small'),
                name: campaign.name,
                nbStations: nbStation,
                startDate: campaign.beginningApplication,
                progression: getProgressBar(progress, progressTotal, progressValue, i18n.analyseOn, i18n.analysisOn),
                referent: getLabel(contacts, campaign.contactCode, 'mnemonique'),
            }
        }),
        campaignHeader: { headers: qualityHeaders },
        stationType: STATION_TYPE_CONSTANT.QUALITY,
        habilitation: componentHasHabilitations(H_QUALITO_MODULE),
        monitoredMateriels: monitoredMateriels.filter(m => m.typeSite === STATION_TYPE_CONSTANT.QUALITY),
    }), [bookmarks, campaigns, contacts, monitoredMateriels, qualitometers, qualityCampaignsProgress])

    const bookmarksStationPanel = useMemo(() => {
        if (!foundBookmarks.length) {
            return null
        }
        const stationResult = sortBy(foundBookmarks.map(station => {
            const cityFound = citiesIndex[station.townCode]
            const result = new DtoSimpleStation(station)
            return {
                ...result,
                city: cityFound ? `${cityFound.name} [${cityFound.code}]` : station.townCode,
                historic: station.historyStart && station.historyEnd ? `${getDate(station.historyStart)} ${i18n.to} ${getDate(station.historyEnd)}` : '',
                operations: station.operationCount,
            }
        }), 'name')
        if (panelType === panelTable) {
            return (
                <Table
                    showNbElements={false}
                    showTitle={false}
                    showIcon={true}
                    headerIcon={(
                        <a>
                            <i className='waves-effect material-icons'>grade</i>
                        </a>
                    )}
                    data={stationResult}
                    actions={[{
                        iconName: 'insert_chart',
                        onClick: () => setPanelType(panelGraph),
                    }]}
                    type={stationResult[0]}
                    link={`station/${STATION_TYPE_NAME.quality}`}
                    condensed
                    sortable
                />
            )
        }
        return (
            <Card
                title={(
                    <a>
                        <i className='waves-effect material-icons'>grade</i>
                        {i18n.myFavourites} ({foundBookmarks.length} {i18n.elements})
                    </a>
                )}
                actions={[{
                    iconName: 'list',
                    onClick: () => setPanelType(panelTable),
                }]}
            >
                <QualityHeatMap qualitometers={foundBookmarks} />
            </Card>
        )
    }, [citiesIndex, foundBookmarks, panelType])

    const bookmarksPanel = useMemo(() => {
        if (data?.bookmarks?.length > 0 && data?.stations?.length > 0) {
            return (
                <div className='row no-margin'>
                    <div className='section no-padding'>
                        <div className='row no-margin'>
                            {bookmarksStationPanel}
                        </div>
                    </div>
                </div>
            )
        }
        return null
    }, [bookmarksStationPanel, data?.bookmarks?.length, data?.stations?.length])

    const campaignsPanel = useMemo(() => {
        if (data.campaigns && data.campaigns.length > 0 && data.campaignHeader) {
            return (
                <div className='row no-margin'>
                    <div className='section no-padding'>
                        <div className='row no-margin'>
                            <CampaignPortletPanel
                                noHeader={true}
                                campaigns={data.campaigns}
                                stationType={STATION_TYPE_NAME.quality}
                                tableHeader={data.campaignHeader}
                            />
                        </div>
                    </div>
                </div>
            )
        }
        return null
    }, [data.campaignHeader, data.campaigns])

    if (dataLoaded) {
        if (!(data.habilitation && ((data.bookmarks.length > 0 && data.stations.length > 0) || data.campaigns.length > 0 || data.monitoredMateriels.length > 0 || data?.exploitations?.length > 0)) || !(bookmarksPanel || campaignsPanel)) {
            return null
        }
        return (
            <div className='row no-margin padding-top-1'>
                <Card
                    title={(
                        <a className='waves-effect' onClick={() => dispatch(push(`/${data.redirect || STATION_TYPE_NAME.quality}`))}>
                            <i className='material-icons '>{data.icon}</i>
                            {i18n[STATION_TYPE_NAME.quality]}
                        </a>
                    )}
                >
                    <div className='padding-1 padding-bottom-0'>
                        {bookmarksPanel}
                        {campaignsPanel}
                        <HabilitationRequired habilitation={H_MAT_MODULE}>
                            <MonitoredMaterielPortletPanel noHeader={true} stationType={data.stationType} />
                        </HabilitationRequired>
                    </div>
                </Card>
            </div>
        )
    }
    return (
        <div className='padding-top-1'>
            <Card>
                <div className='row no-margin center-align padding-top-5'>
                    <img src={loading} />
                    <ProgressCard withoutCard message={i18n.qualityCardInProgress} progress={progressBar} />
                </div>
            </Card>
        </div>
    )
}

export default QualityCard