/* eslint-disable camelcase */
import DtoTankCivilEng from './DtoTankCivilEng'
import DtoTankEquipments from './DtoTankEquipments'
import DtoTankZone from './DtoTankZone'
import DtoTankOperatorEquipment from './DtoTankOperatorEquipment'
import DtoTankSecurity from './DtoTankSecurity'

export default class DtoTankStation {
    constructor(obj) {
        this.idStation = obj.idStation

        this.link_civilEngs = obj.link_civilEngs ? obj.link_civilEngs.map(ce => new DtoTankCivilEng(ce)) : []
        this.link_equipments = obj.link_equipments ? obj.link_equipments.map(e => new DtoTankEquipments(e)) : []
        this.link_zones = obj.link_zones ? obj.link_zones.map(z => new DtoTankZone(z)) : []
        this.link_operatorEquipments = obj.link_operatorEquipments ? obj.link_operatorEquipments.map(oe => new DtoTankOperatorEquipment(oe)) : []
        this.link_securities = obj.link_securities ? obj.link_securities.map(s => new DtoTankSecurity(s)) : []
    }
}