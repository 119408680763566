import React from 'react'
import PropTypes from 'prop-types'
import DisplayedValue from './DisplayedValue'
import { hasValue } from '../../utils/NumberUtil'
import i18n from 'simple-react-i18n'
import { v4 as uuidv4 } from 'uuid'
import { searchAllCharacters } from '../../utils/StringUtil'

const selectStyle = {
    display: 'unset',
    color: 'black',
    backgroundColor: 'white',
    borderRadius: '5px',
    lineHeight: '0',
    height: '2rem',
    padding: '0',
    fontSize: '1rem',
}

const optionStyle = {
    color: 'black',
    backgroundColor: 'hite',
}

const labelStyle = {
    left: '1.2rem',
    fontSize: '1rem',
    position: 'relative',
}

const DEFAULTVALUE = 'nullSelectedValue'

const SimpleSelect = ({
    label = '',
    obligatory,
    value = DEFAULTVALUE,
    options = [],
    onChange = () => {},
    col,
    keyValue,
    keyName,
    id,
    disabled,
    readMode,
    style,
    styleLabel,
    nullLabel,
    returnNull,
    noNullValue,
    integerValue,
    className,
    selectClassName,
}) => {
    const componentId = id || searchAllCharacters(label + uuidv4()).replaceAll(' ', '_').replace('numero', '').replace('nom', 'libelle')
    if (readMode) {
        const found = hasValue(value) ? options.find((e) => keyValue ? e[keyValue] == value : (hasValue(e.value) ? e.value == value : e.code == value)) : null
        return (
            <div
                className={col ? `col s${col}` : 'col'}
                style={{ display: 'block' }}
            >
                <DisplayedValue
                    label={label}
                    obligatory={obligatory}
                    value={found ? keyName ? found[keyName] : (found.label ? found.label : found.name) || '' : ''}
                />
            </div>
        )
    }

    const onChangeValue = (v) => {
        const valueFound = options.find((o) => String(keyValue ? o[keyValue] : (hasValue(o.value) ? o.value : o.code)) === v) || {}
        const valueSelected = keyValue ? valueFound[keyValue] : (hasValue(valueFound.value) ? valueFound.value : valueFound.code)
        onChange(integerValue ? hasValue(parseInt(valueSelected)) ? parseInt(valueSelected) : null : valueSelected)
    }

    const select = (
        <select
            id={componentId}
            value={value}
            onChange={(e) => onChangeValue(e.target.value)}
            style={{
                borderColor: disabled ? 'rgba(118, 118, 118, 0.3)' : '#7a7a7a',
                ...selectStyle,
                ...style,
            }}
            disabled={disabled}
            className={`${selectClassName || ''} ${disabled ? '' : 'clickable'}`}
        >
            {!noNullValue && (
                <option value={returnNull ? null : DEFAULTVALUE} style={{ ...optionStyle }}>{nullLabel || ''}</option>
            )}
            {options.map(option => {
                const key = keyValue ? option[keyValue] : (hasValue(option.value) ? option.value : option.code)
                return (
                    <option
                        key={key}
                        value={key}
                        style={{ ...optionStyle }}
                    >
                        {'- '.repeat(option.dephValue || 0) + (keyName ? option[keyName] : (option.label ? option.label : option.name))}
                    </option>
                )
            })}
        </select>
    )

    if (!label && !col) {
        return select
    }
    return (
        <div
            className={`${col ? `col s${col}` : 'col'} ${className || ''}`}
            style={{
                display: 'block',
                marginTop: '-5px',
                marginBottom: '1rem',
            }}
        >
            {label && (
                <label
                    htmlFor={componentId}
                    className='bold'
                    style={{
                        color: disabled ? '#9e9e9e' : '#161832',
                        ...labelStyle,
                        ...styleLabel,
                    }}
                >{label}{obligatory ? <span className='primary-color-text'>{ i18n.obligatoryField }</span> : null }</label>
            )}
            {select}
        </div>
    )
}

SimpleSelect.propTypes = {
    label: PropTypes.string,
    value: PropTypes.number,
    options: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.number,
        name: PropTypes.string,
        dephValue: PropTypes.number,
    })).isRequired,
    onChange: PropTypes.func.isRequired,
    col: PropTypes.number,
    keyValue: PropTypes.string,
    keyName: PropTypes.string,
    id: PropTypes.string,
    disabled: PropTypes.bool,
    readMode: PropTypes.bool,
    nullLabel: PropTypes.string,
    returnNull: PropTypes.bool,
    noNullValue: PropTypes.bool,
    integerValue: PropTypes.bool,
    style: PropTypes.object,
    styleLabel: PropTypes.object,
    className: PropTypes.string,
    selectClassName: PropTypes.string,
    obligatory: PropTypes.bool,
}

export default SimpleSelect