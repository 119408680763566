import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import { isEqual, omit } from 'lodash'
import WaitAction from 'wait/WaitAction'
import DtoMaterielState from '../../../../../materiel/dto/DtoMaterielState'
import MaterielAction from '../../../../../materiel/actions/MaterielAction'
import StationAction from '../../../../actions/StationAction'
import SituationDetailPanel from '../SituationDetailPanel'
import DtoStation from '../../../../dto/DtoStation'
import DtoStationSubscriptionAssignment from '../../../../dto/materiel/DtoStationSubscriptionAssignment'
import SubscriptionAction from '../../../../../materiel/components/subscription/actions/SubscriptionAction'
import DtoSubscription from '../../../../dto/materiel/DtoSubscription'
import SubscriptionPanel from '../../../../../materiel/components/subscription/components/SubscriptionPanel'
import SubscriptionTypeDto from '../../../../../materiel/components/subscription/dto/SubscriptionTypeDto'
import DtoSim from '../../../../dto/materiel/DtoSim'
import Select from '../../../../../components/forms/Select'
import SimAction from '../../../../../materiel/components/sim/actions/SimAction'
import SimTypeDto from '../../../../../materiel/components/sim/dto/DtoSimType'
import SimSubscriptionDto from '../../../../../materiel/components/sim/dto/SimSubscriptionDto'
import Checkbox from '../../../../../components/forms/Checkbox'
import SubscriptionSituationDto from '../../../../../materiel/components/subscription/dto/SubscriptionSituationDto'

class SubscriptionSituationDetail extends Component {
    state = {
        subscription: {},
        subscriptionDefaultParam: {},
        associatedSimId: -1,
        keepLastSim: undefined,
    }

    componentDidMount() {
        const {
            materielStates,
            subscriptions,
            subscriptionTypes,
        } = this.props
        if (!materielStates.length) {
            this.props.fetchMaterielStates()
        }
        if (!subscriptions.length) {
            this.props.fetchSubscriptions()
        }
        if (!subscriptionTypes.length) {
            this.props.fetchSubscriptionTypes()
        }
        if (!this.props.sims.length) {
            this.props.fetchSims()
        }
        if (!this.props.simTypes.length) {
            this.props.fetchSimTypes()
        }
        if (!this.props.simSubscriptionsLastSituations.length) {
            this.props.fetchSimSubscriptionsLastSituations()
        }
    }

    getSubscriptionTitle = ({ numLine, numPath }, { label }) => {
        const code = numLine || numPath || ''
        return label ? `${label} - ${code}` : code || i18n.unknownMaterial
    }

    setSubscriptionTypesTypeParam = () => {
        const { materielType } = this.state.subscription
        const type = this.props.subscriptionTypes.find(t => t.id === materielType) || {}
        this.setState({
            subscription: {
                ...this.state.subscription,
                ...omit(type, ['id', 'code', 'label', 'name', 'comment', 'updateDate', 'updateLogin', 'startDate', 'endDate', 'icon', 'headers']),
            },
        })
    }

    getSubscriptionPanel = isEditMode => {
        const { subscription } = this.state
        if (subscription.idSubscription) {
            const subscriptionTypes = this.props.subscriptionTypes.find(t => t.id === subscription.subscriptionType) || {}
            return (
                <div>
                    <div className='col s8 offset-s2'>
                        <div className='col s4 offset-s2'>
                            <a className='col s12 btn' onClick={ () => this.setState({
                                subscription: {
                                    ...this.state.subscriptionDefaultParam,
                                },
                            }) }
                            >
                                { i18n.defaultSettings }
                            </a>
                        </div>
                        <div className='col s4'>
                            <a className='col s12 btn' onClick={ this.setSubscriptionTypesTypeParam }>
                                { i18n.typeSettings }
                            </a>
                        </div>
                    </div>
                    <div className='row col s10 offset-s1'>
                        <div className='card no-padding'>
                            <div className='col s12 card-title active'>
                                { this.getSubscriptionTitle(subscription, subscriptionTypes) }
                            </div>
                            <div className={ 'card-content margin-top-1' }>
                                <div className='row no-margin'>
                                    <SubscriptionPanel
                                        id={ subscription.id }
                                        subscription={ subscription }
                                        onChange={ changes => this.setState({ subscription: { ...this.state.subscription, ...changes } }) }
                                        disabled={ !isEditMode }
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        return null
    }

    fetchSubscription = id => {
        this.props.waitStart()
        this.props.fetchSubscription(id).then(json => {
            const associatedSimId = this.props.simSubscriptionsLastSituations.find(sit => sit.idSubscription === id) || {}
            this.setState({
                subscription: json,
                subscriptionDefaultParam: json,
                associatedSimId: associatedSimId.idSim,
            })
            this.props.waitStop()
        })
    }

    updateSubscription = () => {
        if (!isEqual(this.state.subscription, this.state.subscriptionDefaultParam)) {
            this.props.updateSubscription(this.state.subscription)
        }
    }

    resetSubscription = () => {
        this.props.resetSubscription()
        this.setState({ subscription: {}, subscriptionDefaultParam: {} })
    }

    getMaterielNumber = ({ imei, serialNumber, reference, numLine, id }) => imei || serialNumber || reference || numLine || id || ''

    getSimsData = () => {
        const { sims, simTypes } = this.props
        return sims.map(o => {
            const label = (() => {
                const result = this.getMaterielNumber(o)
                if (o.simType) {
                    const type = simTypes.find(b => b.id === o.simType)
                    if (type) {
                        return `${type.label} - ${result}`
                    }
                }
                return result
            })()
            return {
                code: o.id,
                label,
            }
        })
    }

    additionalStep = ({ shouldReplace, replaceMat }) => {
        const { subscriptionType } = this.state.subscription
        const {
            associatedSimId,
            keepLastSim,
        } = this.state
        const type = this.props.subscriptionTypes.find(t => t.id === subscriptionType) || {}
        const prevSim = this.props.simSubscriptionsLastSituations.find(sit => sit.idSubscription === replaceMat) || {}
        const sims = this.getSimsData()
        return type.label !== 'RTC' && type?.label !== 'Ligne Numérique/Box' && (
            <div className='row no-margin'>
                <div className='col s12 center'>
                    <h5>{i18n.associatedSimSubscription}</h5>
                </div>
                {
                    shouldReplace && (
                        <div className='col s12'>
                            <Checkbox
                                col={12}
                                label={i18n.keepLastSim}
                                checked={keepLastSim}
                                onChange={v => this.setState({ keepLastSim: v, associatedSimId: v ? prevSim.idSim : associatedSimId })}
                            />
                        </div>
                    )
                }
                <div className='col s12 padding-top-2'>
                    <Select
                        col={12}
                        options={sims}
                        label={i18n.sims}
                        nullLabel='&nbsp;'
                        onChange={v => this.setState({ associatedSimId: v })}
                        value={associatedSimId}
                    />
                </div>
            </div>
        )
    }

    addSituation = (situation, stationId, stationType) => {
        const { subscriptionType } = this.state.subscription
        const type = this.props.subscriptionTypes.find(t => t.id === subscriptionType) || {}
        if (type.label === 'RTC' && type?.label !== 'Ligne Numérique/Box') {
            return this.props.addSubscriptionSituation(situation, stationId, stationType)
        } else if (situation.idSubscription === this.state.subscription.idSubscription) {
            const prevSim = this.props.simSubscriptionsLastSituations.find(sit => sit.idSubscription === situation.idSubscription) || {}
            if (prevSim.idSim !== this.state.associatedSimId) {
                const simSubscription = {
                    idSubscription: situation.idSubscription,
                    idSim: this.state.associatedSimId,
                    startDate: situation.situationDate,
                }
                return this.props.createSimSubscription(simSubscription).then(() => {
                    this.props.fetchSimSubscriptionsLastSituations()
                    this.props.fetchSimsLink()
                    this.props.addSubscriptionSituation(situation, stationId, stationType)
                })
            }
        }
        return this.props.addSubscriptionSituation(situation, stationId, stationType)
    }

    render = () => {
        const {
            station,
            subscriptions,
            subscriptionsLastSituations,
            subscriptionTypes,
            stationSubscriptionAssignments,
            saveSubscriptionSituation,
            deleteSubscriptionSituation,
            params,
            type,
        } = this.props
        return (
            <SituationDetailPanel
                params={ params }
                type={type}
                getMaterielPanel={ isEditMode => this.getSubscriptionPanel(isEditMode) }
                station={ station }
                materiels={subscriptions }
                lastSituations={subscriptionsLastSituations}
                materielTypes={ subscriptionTypes }
                stationMaterielAssignments={ stationSubscriptionAssignments }
                addSituation={ this.addSituation }
                saveSituation={ saveSubscriptionSituation }
                deleteSituation={ deleteSubscriptionSituation }
                fetchMateriel={ this.fetchSubscription }
                updateMateriel={ this.updateSubscription }
                resetMateriel={ this.resetSubscription }
                keyMaterielType='subscriptionType'
                keyMaterielId='idSubscription'
                additionalStep={additionnalProps => this.additionalStep(additionnalProps) }
            />
        )
    }
}

SubscriptionSituationDetail.propTypes = {
    params: PropTypes.shape({
        id: PropTypes.string,
        materialType: PropTypes.string,
    }),
    type: PropTypes.string,
    station: PropTypes.instanceOf(DtoStation),

    stationSubscriptionAssignments: PropTypes.arrayOf(PropTypes.instanceOf(DtoStationSubscriptionAssignment)),
    materielStates: PropTypes.arrayOf(PropTypes.instanceOf(DtoMaterielState)),
    subscription: PropTypes.instanceOf(DtoSubscription),
    subscriptions: PropTypes.arrayOf(PropTypes.instanceOf(DtoSubscription)),
    subscriptionsLastSituations: PropTypes.arrayOf(PropTypes.instanceOf(SubscriptionSituationDto)),
    subscriptionTypes: PropTypes.arrayOf(PropTypes.instanceOf(SubscriptionTypeDto)),
    sims: PropTypes.arrayOf(PropTypes.instanceOf(DtoSim)),
    simTypes: PropTypes.arrayOf(PropTypes.instanceOf(SimTypeDto)),
    simSubscriptionsLastSituations: PropTypes.arrayOf(PropTypes.instanceOf(SimSubscriptionDto)),
    resetSubscription: PropTypes.func,
    updateSubscription: PropTypes.func,
    fetchSubscription: PropTypes.func,
    fetchSubscriptions: PropTypes.func,
    fetchMaterielStates: PropTypes.func,
    fetchSubscriptionTypes: PropTypes.func,
    fetchMaterielTypes: PropTypes.func,
    saveSubscriptionSituation: PropTypes.func,
    addSubscriptionSituation: PropTypes.func,
    deleteSubscriptionSituation: PropTypes.func,
    fetchSims: PropTypes.func,
    fetchSimTypes: PropTypes.func,
    waitStart: PropTypes.func,
    waitStop: PropTypes.func,
    fetchSimSubscriptionsLastSituations: PropTypes.func,
    fetchSimsLink: PropTypes.func,
    createSimSubscription: PropTypes.func,
}

const mapStateToProps = store => ({
    stationSubscriptionAssignments: store.StationReducer.stationSubscriptionAssignments,
    materielStates: store.MaterielReducer.materielStates,
    subscription: store.SubscriptionReducer.subscription,
    subscriptions: store.SubscriptionReducer.subscriptions,
    subscriptionsLastSituations: store.SubscriptionReducer.subscriptionsLastSituations,
    subscriptionTypes: store.SubscriptionReducer.subscriptionTypes,
    sims: store.SimReducer.sims,
    simTypes: store.SimReducer.simTypes,
    simSubscriptionsLastSituations: store.SubscriptionReducer.simSubscriptionsLastSituations,
})

const mapDispatchToProps = {
    resetSubscription: SubscriptionAction.resetSubscription,
    updateSubscription: SubscriptionAction.updateSubscription,
    fetchSubscriptions: SubscriptionAction.fetchSubscriptions,
    fetchSubscription: SubscriptionAction.fetchSubscription,
    fetchMaterielStates: MaterielAction.fetchMaterielStates,
    fetchSubscriptionTypes: SubscriptionAction.fetchSubscriptionTypes,
    saveSubscriptionSituation: StationAction.saveSubscriptionSituation,
    addSubscriptionSituation: StationAction.addSubscriptionSituation,
    deleteSubscriptionSituation: StationAction.deleteSubscriptionSituation,
    fetchSims: SimAction.fetchSims,
    fetchSimTypes: SimAction.fetchSimTypes,
    waitStart: WaitAction.waitStart,
    waitStop: WaitAction.waitStop,
    fetchSimSubscriptionsLastSituations: SubscriptionAction.fetchSimSubscriptionsLastSituations,
    fetchSimsLink: SubscriptionAction.fetchSimsLink,
    createSimSubscription: SimAction.createSimSubscription,
}

export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionSituationDetail)