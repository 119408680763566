import Tile from 'ol/layer/Tile'
import XYZ from 'ol/source/XYZ'
import Layers from 'utils/mapUtils/ILayers'

class _IGNMap extends Layers {
    constructor() {
        super()
        this.layer = this.createLayer()
    }


    createLayer() {
        return new Tile({
            source: new XYZ({
                url: 'https://data.geopf.fr/wmts?SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=GEOGRAPHICALGRIDSYSTEMS.PLANIGNV2&STYLE=normal&FORMAT=image/png&TILEMATRIXSET=PM&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}',
            }),
        })
    }

    getLayer() {
        return this.layer
    }
}

export default function IGNMap(...args) {
    return new _IGNMap(...args)
}


