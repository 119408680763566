import PropTypes from 'prop-types'
import React, { Component } from 'react'
import i18n from 'simple-react-i18n'
import Job from '../../../dto/DtoJob'

class AquaDBSelectionPanel extends Component {
    constructor(props) {
        super(props)
        this.state = this.props.job.parameters.filters.length ? JSON.parse(this.props.job.parameters.filters[0]) : { user: '', selection: '' }
        this.handleChange = this.handleChange.bind(this)
    }

    handleChange(event) {
        if (event.target.id === 'aquadbUser') {
            this.setState({ user: event.target.value }, () => {
                this.onChangeFilters()
            })
        }
        if (event.target.id === 'aquadbSelectionName') {
            this.setState({ selection: event.target.value }, () => {
                this.onChangeFilters()
            })
        }
    }

    onChangeFilters() {
        const parameters = {
            ...this.props.job.parameters,
            filters: [JSON.stringify(this.state)],
        }
        this.props.onChangeJob({ parameters })
    }

    render() {
        const disabled = { disabled: !this.props.isEditMode }
        return (
            <div>
                <div className='row valign-wrapper'>
                    <div className='col s3'>
                        <label>{ i18n.aquadbSelection }</label>
                    </div>
                    <div className='input-field col s9 no-padding'>
                        <div className='col s8'>
                            <input id='aquadbUser' type='text' placeholder={ i18n.aquadbUserName } ref='aquadbUser' {...disabled} onChange={this.handleChange} value={ this.state.user }/>
                            <label className='tinyLabel'>{ i18n.aquadbUser }</label>
                        </div>
                    </div>
                </div>
                <div className='row valign-wrapper'>
                    <div className='col s3'/>
                    <div className='input-field col s9 no-padding'>
                        <div className='col s8'>
                            <input id='aquadbSelectionName' type='text' placeholder={ i18n.aquadbSelectionName } ref='aquadbSelectionName' {...disabled} onChange={this.handleChange} value={ this.state.selection }/>
                            <label className='tinyLabel'>{ i18n.aquadbSelection }</label>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

AquaDBSelectionPanel.propTypes = {
    job: PropTypes.instanceOf(Job).isRequired,
    isEditMode: PropTypes.bool,
    onChangeJob: PropTypes.func,
}

export default AquaDBSelectionPanel