import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import React, { useEffect } from 'react'
import { Grid } from '@mui/material'
import ImageCard from '../../../../components/card/ImageCard'
import PropTypes from 'prop-types'
import { instanceOf } from '../../../../utils/StoreUtils'
import DtoIAEauModel from '../../../../iaeau/dto/DtoIAEauModel'


const ModelStepUse = ({
    stationType,
    id, // station id
    selectedModel,
    changeModel,
}) => {
    const dispatch = useDispatch()

    const {

    } = useSelector(store => ({

    }), shallowEqual)

    // const [isOpen, setIsOpen] = useState(false)

    useEffect(() => {

    }, [])

    return (
        <div className='padding-top-2'>
            <Grid container justifyContent='center' alignItems='center' spacing={3}>
                <h5>Dans quel cadre souhaitez vous utiliser les résultats du modèle ?</h5>
            </Grid>
            <Grid container justifyContent='center' alignItems='center' spacing={3}>
                <Grid item xs={4}>
                    <ImageCard
                        img='pictures/iaeauModels/stationDashboard.png'
                        alt='stationDashboard'
                        title='Tableau de bord'
                        description='Les résultats du modèle seront directement affichés sur le graphique du tableau de bord.'
                        maxWidth={345}
                        imgHeight={140}
                        active={!!selectedModel.displayDashboard}
                        onClick={() => changeModel({ displayDashboard: !selectedModel.displayDashboard })}
                        noFocus
                    />
                </Grid>
                <Grid item xs={4}>
                    <ImageCard
                        img='pictures/iaeauModels/situation.png'
                        alt='Situation'
                        title='Situation'
                        description='Les résultats du modèle seront utilisés sur la situation, et dans les calculs des cartes de situation.'
                        maxWidth={345}
                        imgHeight={140}
                        active={!!selectedModel.displaySituation}
                        onClick={() => changeModel({ displaySituation: !selectedModel.displaySituation })}
                        noFocus
                    />
                </Grid>
                <Grid item xs={4}>
                    <ImageCard
                        img='pictures/iaeauModels/emailAlert.png'
                        alt='alert'
                        title='Alertes'
                        description={'Les résultats du modèle seront utilisés par les traitements de détection d\'alertes. (En cours de développement)'}
                        maxWidth={345}
                        imgHeight={140}
                        active={!!selectedModel.displayAlert}
                        onClick={() => changeModel({ displayAlert: !selectedModel.displayAlert })}
                        noFocus
                    />
                </Grid>
            </Grid>
            <Grid container justifyContent='center' alignItems='center' spacing={3} className='padding-top-4'>
                <h5>Comment souhaitez vous programmer le calcul du modèle ?</h5>
            </Grid>
            <Grid container justifyContent='center' alignItems='center' spacing={3}>
                <Grid item xs={4}>
                    <ImageCard
                        title='Lancement manuel'
                        description='Le modèle sera recalculé sur demande.'
                        maxWidth={345}
                        active={!selectedModel.calculAuto}
                        onClick={() => changeModel({ calculAuto: false, calculationMode: 'now' })}
                        noFocus
                    />
                </Grid>
                <Grid item xs={4}>
                    <ImageCard
                        title='Recalcul automatique'
                        description={'Le modèle sera recalculé tous les jours'}
                        maxWidth={345}
                        active={!!selectedModel.calculAuto && selectedModel.calculationMode === 'auto'}
                        onClick={() => changeModel({ calculAuto: true, calculationMode: 'auto' })}
                        noFocus
                    />
                </Grid>
                <Grid item xs={4}>
                    <ImageCard
                        title={['Recalcul auto ', <br/>, ' (dernière mesure uniquement)']}
                        description={'Le modèle sera recalculé tous les jours à partir de la dernière mesure, et seul ce calcul sera conservé. (Les calculs sur les dates antérieures ne seront pas conservés ni accessibles aux utilisateurs)'}
                        maxWidth={345}
                        active={!!selectedModel.calculAuto && selectedModel.calculationMode === 'lastMeasure'}
                        onClick={() => changeModel({ calculAuto: true, calculationMode: 'lastMeasure' })}
                        noFocus
                    />
                </Grid>
            </Grid>
        </div>
    )
}

ModelStepUse.propTypes = {
    stationType: PropTypes.string,
    id: PropTypes.string,
    selectedModel: instanceOf(DtoIAEauModel),
    setSelectedModel: PropTypes.func,
}

export default ModelStepUse