export default class DtoInstallationCasing {
    constructor(obj) {
        this.idInstallation = obj.idInstallation // Long,
        this.depth = obj.depth // Option[Double],
        this.cementing = obj.cementing // Option[Boolean],
        this.concreteCoping = obj.concreteCoping // Option[Boolean],
        this.protectionHead = obj.protectionHead // Option[Boolean],
        this.completionDate = obj.completionDate // Option[DateTime],
        this.declarationDate = obj.declarationDate // Option[DateTime],
        this.receiptDate = obj.receiptDate // Option[DateTime],
        this.receiptNumber = obj.receiptNumber // Option[String],
        this.comment = obj.comment // Option[String],
        this.owner = obj.owner // Option[Int],
        this.id = obj.id // Option[String]
        this.copingHeight = obj.copingHeight // Option[Double]
        this.reserveFlow = obj.reserveFlow // Option[Double]
        this.modernizedPerimeterASP = obj.modernizedPerimeterASP // Option[Boolean]
        this.specificPrescriptions = obj.specificPrescriptions // Option[Boolean]
    }
}