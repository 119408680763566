import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@mui/material'
import AgriAction from 'agriAdministration/actions/AgriAction'
import Input from 'components/forms/Input'
import SimpleDatePicker from 'components/forms/SimpleDatePicker'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import i18n from 'simple-react-i18n'
import DtoService from 'territory/dto/DtoService'

const TerritoryServiceModal = ({
    openModal = false,
    isNew = false,
    service = {},
    setOpenModal = () => { },
}) => {
    const dispatch = useDispatch()

    const [technicalId, setTechnicalId] = useState(null)
    const [productCode, setProductCode] = useState(null)
    const [designation, setDesignation] = useState(null)
    const [startDate, setStartDate] = useState(null)
    const [endDate, setEndDate] = useState(null)

    useEffect(() => {
        setTechnicalId(service.technicalId)
        setProductCode(service.productCode)
        setDesignation(service.designation)
        setStartDate(service.startDate)
        setEndDate(service.endDate)
    }, [service])

    const onValidate = () => {
        const id = isNew ? -1 : service.idService
        const newService = new DtoService({
            idService: id,
            technicalId,
            productCode,
            designation,
            startDate,
            endDate,
        })
        dispatch(AgriAction.updateService(
            id,
            newService
        )).then(() => {
            dispatch(AgriAction.fetchServices())
            setOpenModal(false)
        })
    }

    return (
        <Dialog open={openModal} maxWidth='lg'>
            <DialogTitle>
                {isNew ? i18n.newService : i18n.editService}
            </DialogTitle>
            <DialogContent>
                <Grid container className='padding-top-1' style={{ minHeight: '350px' }} alignContent='flex-start'>
                    <Grid item xs={6} className='padding-right-1'>
                        <Input
                            col={12}
                            title={i18n.technicalId}
                            value={technicalId}
                            onChange={setTechnicalId}
                        />
                    </Grid>
                    <Grid item xs={6} className='padding-left-1'>
                        <Input
                            col={12}
                            title={i18n.productCode}
                            value={productCode}
                            onChange={setProductCode}
                        />
                    </Grid>
                    <Grid item xs={6} className='padding-right-1'>
                        <Input
                            col={12}
                            title={i18n.designation}
                            value={designation}
                            onChange={setDesignation}
                        />
                    </Grid>
                    <Grid container item xs={12}>
                        <Grid item xs={6} className='padding-right-1'>
                            <SimpleDatePicker
                                col={12}
                                value={startDate}
                                id='startDate'
                                label={i18n.startDate}
                                onChange={v => setStartDate(v)}
                                obligatory
                            />
                        </Grid>
                        <Grid item xs={6} className='padding-left-1'>
                            <SimpleDatePicker
                                col={12}
                                value={endDate}
                                id='endDate'
                                label={i18n.endDate}
                                onChange={v => setEndDate(v)}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setOpenModal(false)} variant='outlined'>
                    {i18n.close}
                </Button>
                <Button onClick={() => onValidate({})} variant='contained' color='primary'>
                    {i18n.validate}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

TerritoryServiceModal.propTypes = {
    openModal: PropTypes.bool,
    isNew: PropTypes.bool,
    setOpenModal: PropTypes.func,
    service: PropTypes.instanceOf(DtoService),
}

export default TerritoryServiceModal
