import User from 'account/dto/User'
import AdministrationAction from 'administration/actions/AdministrationAction'
import GlobalParametersDto from 'administration/dto/GlobalParameterDto'
import { linkContributorsDepartments } from 'carto/constants/CartoConstants'
import Icon from 'components/icon/Icon'
import { push } from '@lagunovsky/redux-react-router'
import DtoInstallationSituation from 'installation/dto/installation/DtoInstallationSituation'
import { isEqual, isNil, isNumber, keys, maxBy, orderBy, uniq, uniqBy } from 'lodash'
import moment from 'moment'
import DtoPluvioMeasureObj from 'pluviometry/dto/measures/DtoPluvioMeasureObj'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import { STATION_QUALITOMETER_NAMES_HYDRO, STATION_TYPE_NAME } from 'station/constants/StationConstants'
import DtoFilter from 'station/dto/DtoFilter'
import { getCmsMarker } from 'utils/CmsUtils'
import { getThresholdColorCode } from 'utils/ColorUtil'
import { getFullDate, getFullDateMini } from 'utils/DateUtil'
import { componentHasHabilitations } from 'utils/HabilitationUtil'
import { getHardHydroDataTypes } from 'utils/HydroUtils'
import { getCardHydroMarker, getCardPiezoMarker, getCardPluvioMarker, getHydroMarker, getHydroTideMarker, getInstMarkerByType, getPiezoMarker, getPluvioMarker } from 'utils/SvgUtil'
import { hasValue } from 'utils/NumberUtil'
import { getHardPiezoDataTypes } from 'utils/PiezometryUtils'
import { getSetting, getSettingJson, getUser, hasHabilitation } from 'utils/SettingUtils'
import { execByType, findStationType, getStationFilterByModule, getTypeModule, hasLocalisationStation } from 'utils/StationUtils'
import { getI18nOrLabel, geti18n, searchAllCharacters } from 'utils/StringUtil'
import { getTrendLevel, getTrendThreshold, getTrendThresholdColor, TrendLevelObject } from 'utils/TrendLevelUtil'
import { getUserBookmarksByStationType } from 'utils/UserUtil'
import WaitAction from 'wait/WaitAction'
import {
    H_SITUATION_COMPLETE_MONITORING,
    H_SITUATION_FLOOD,
    H_SITUATION_MONITORING,
    H_SITUATION_POLLUTION,
    H_SITUATION_THEME,
} from '../../../account/constants/AccessRulesConstants'
import UserAction from '../../../administration/components/user/actions/UserAction'
import {
    SITUATION_MONITORING,
    SITUATION_POLLUTION,
} from '../../../administration/components/user/constants/HabilitationConstants'
import DtoUserBookmark from '../../../administration/components/user/dto/DtoUserBookmark'
import SieauParameterDto from '../../../administration/dto/SieauParameterDto'
import ActionComponent from '../../../components/ActionComponent'
import Card from '../../../components/card/Card'
import CartographyPanel from '../../../components/map/CartographyPanel'
import { setStationPointScale } from '../../../components/map/mapTreatments/AddingLayers'
import ProgressBar from '../../../components/progress/ProgressBar'
import CmsAction from '../../../events/actions/CmsAction'
import CMSCategoryDto from '../../../events/dto/CMSCategoryDto'
import CMSEventDto from '../../../events/dto/CMSEventDto'
import HomeAction from '../../../home/actions/HomeAction'
import { PATH_ALERT } from '../../../home/constants/RouteConstants'
import DtoArrest from '../../../home/dto/DtoArrest'
import HydrometryAction from '../../../hydrometry/actions/HydrometryAction'
import DtoHydroMeasures from '../../../hydrometry/dto/chronicMeasures/DtoHydroMeasures'
import DtoHydrometricStation from '../../../hydrometry/dto/DtoHydrometricStation'
import DtoHydrometryThreshold from '../../../hydrometry/dto/DtoHydrometryThreshold'
import InstallationAction from '../../../installation/actions/InstallationAction'
import { INSTALLATION_TYPE, INSTALLATION_TYPES } from '../../../installation/constants/InstallationConstants'
import DtoInstallation from '../../../installation/dto/installation/DtoInstallation'
import PiezometryAction from '../../../piezometry/actions/PiezometryAction'
import DtoPiezometerLight from '../../../piezometry/dto/DtoPiezometerLight'
import PluviometryAction from '../../../pluviometry/actions/PluviometryAction'
import PluviometerDto from '../../../pluviometry/dto/PluviometerDto'
import PluviometerThresholdDto from '../../../pluviometry/dto/PluviometerThresholdDto'
import ProductionUnitAction from '../../../productionUnit/actions/ProductionUnitAction'
import DtoProductionUnit from '../../../productionUnit/dto/DtoProductionUnit'
import QualityAction from '../../../quality/actions/QualityAction'
import DtoQualitometerLight from '../../../quality/dto/DtoQualitometerLight'
import StationAction from '../../../station/actions/StationAction'
import DtoPiezometryStationMeasure from '../../../station/dto/piezometer/DtoPiezometryStationMeasure'
import DtoPiezoThreshold from '../../../station/dto/piezometer/DtoPiezoThreshold'
import AlertAction from '../../actions/AlertAction'
import { DESCRIPTION, MONITORING_OPTIONS, SITUATION_MAP_X_COORDINATE, SITUATION_MAP_Y_COORDINATE, SITUATION_MAP_ZOOM } from '../../constants/AlertConstants'
import {
    AGRICULTURE,
    GROUND_AND_BASEMENT,
    HYDROBIOLOGY,
    HYDROMETRIC_STATION,
    HYDROMETRY,
    HYDROMORPHOLOGY,
    INDUSTRY,
    INSTALLATION_BOREHOLE,
    INSTALLATION_CAPTURE,
    INSTALLATION_CAVITY,
    INSTALLATION_FLOW_OBSTRUCTION,
    INSTALLATION_INDUSTRIAL_SITE,
    INSTALLATION_POLLUTED_SOIL,
    INSTALLATION_SAMPLE_WORK,
    INSTALLATION_STEP,
    METEOROLOGY,
    PHYSICO_CHEMISTRY,
    PIEZOMETER,
    PIEZOMETRY,
    PLUVIOMETER,
    QUALITOMETER,
    QUALITOMETER_SURFACE,
    SERVICES,
} from '../../constants/ThemeConstants'
import { getAllLastMeasuresFormat, getMeasureArrayValues } from '../../utils/MeasureUtil'
import AlertMapTabs from '../map/AlertMapTabs'
import AlertNewCmsStepper from '../map/AlertNewCmsStepper'
import EventsMapTabs from '../map/EventsMapTabs'
import ThemesMapTab from '../map/ThemesMapTab'
import AlertMapPopin from './AlertMapPopin'
import { getUrls } from './AlertMapUtils'
import DtoFlowObstruction from 'installation/components/flowObstruction/dto/DtoFlowObstruction'
import DtoSandreCode from 'referencial/dto/DtoSandreCode'
import ReferencialAction from 'referencial/action/ReferencialAction'
import DtoCmsModel from 'events/dto/DtoCmsModel'
import Fancybox from 'components/fancybox/Fancybox'
import { formatData, getDataWithHeaders } from 'utils/ExportDataUtil'
import ExportAction from 'export/actions/ExportAction'
import { mainWhite, secondaryBlue, THRESHOLD_COLORS_CODES } from 'utils/constants/ColorTheme'
import { TIDE_GAUGE_TYPE } from 'hydrometry/constants/HydrometryConstants'
import meteoFranceLogo from 'assets/pictures/logos/meteofrance.png'
import { vigilancesMeteoFrancePath } from 'conf/SieauConstants'
import SettingDto from 'administration/dto/SettingDto'
import AccountAction from 'account/actions/AccountAction'
import { SIEAU } from 'administration/components/user/constants/StatisticConstants'
import ToastrAction from 'toastr/actions/ToastrAction'
import { transform } from 'ol/proj'
import DtoAccountSettings from 'account/dto/DtoAccountSettings'
import CardMapStation from 'components/map/popup/CardMapStation'

const DEFAULT_FILTER = -1
const ADMIN_ALERT_FILTERS = 'ADMIN_ALERT_FILTERS'
const ALERT_STATIONS_DISPLAYED = 'ALERT_STATIONS_DISPLAYED'
const IIBSN_CONSIGNE_ID = 9
const IS_OVERRUN_THRESHOLD = '1'
const ISNT_OVERRUN_THRESHOLD = '0'

const STATIONS_HEADERS = ['code', 'type', 'name', 'city', 'openData', 'lastMeasure', 'measureDate', 'threshold', 'events', 'campaigns']
const ARTICLES_HEADERS = ['title', 'subtitle', 'category', 'comment', 'author', 'status', 'updateDate']

class AlertMapApp extends ActionComponent {
    constructor(props) {
        super(props)

        this.state = {
            visibilityOption: [i18n.archived, i18n.draft],
            displayPiezo: true,
            displayPluvio: true,
            displayHydro: true,
            displayInst: false,
            selectedDate: moment().valueOf(),
            selectedTab: '',
            selectedSite: {},
            dataLoaded: false,
            progress: 0,
            panel: DESCRIPTION,
            onlyDescription: false,
            sortBy: 'date',
            selectedSiteEvents: [],
            selectedSiteCampaigns: [],
            selectedSiteThreshold: [],
            isCities: false,
            monthlyMeasures: [],
            filter: {},
            filteredBookmarks: [],
            filterTheme: ['PIEZOMETRY', 'HYDROMETRY', 'METEOROLOGY', 'HYDROMORPHOLOGY', 'HYDROBIOLOGY', 'PHYSICO_CHEMISTRY', 'AGRICULTURE', 'INDUSTRY', 'SERVICES', 'GROUND_AND_BASEMENT'],
            searchValue: '',
            piezometryAllDataTypes: [],
            hydrometryAllDataTypes: [],
            pluviometryAllDataTypes: [],
            stationsIdByTypeName: {},
            adminAlertFiltersValue: {},
            hiddenNoThresholdStations: false,
            openAlertNewCms: false,
            selectedStationType: {
                [STATION_TYPE_NAME.hydrometry]: orderBy([ ...STATION_QUALITOMETER_NAMES_HYDRO.map(h => ({ id: h.code, label: h.code === 4 ? i18n.tidegauge : h.name, selected: true })), { id: -1, label: i18n.unknown, selected: true }], 'id'),
                [STATION_TYPE_NAME.installation]: orderBy([ ...INSTALLATION_TYPES.map(h => ({ id: h.code, label: getI18nOrLabel(h.label), selected: true })), { id: -1, label: i18n.unknown, selected: true }], 'id'),
            },
            territoryImageSmall: false,
            meteoImageSmall: false,
            tempSitesDefined: false,
            mapConf: undefined,
            monitoringType: MONITORING_OPTIONS.ALL_MONITORING,
        }
    }

    componentDidUpdate(prevProps) {
        if (!isEqual(prevProps.citiesIndex, this.props.citiesIndex)) {
            this.setComponentActions()
        }
    }

    getFilterResultObject = (filters, stationType, filter) => {
        const { userBookmarks } = this.props
        if (filter === -1) {
            return undefined
        } else if (filter === -5) {
            return getUserBookmarksByStationType(userBookmarks, stationType).map(c => c.toUpperCase())
        }
        const filtersByModules = getStationFilterByModule(filters, stationType)
        const foundFilter = filtersByModules.find(f => f.code == filter)
        if (foundFilter) {
            return () => this.props.fetchFilterResults(foundFilter).then(results => results.map(res => res.id))
        }
        return []
    }

    getFilterValue = (filter) => isNumber(filter) ? filter : DEFAULT_FILTER
    getAlertLimit = (alertLimit, defaultLimit) => isNumber(alertLimit) ? alertLimit : defaultLimit
    getDefaultDisplayed = (displayByDefault, defaultDisplay) => hasValue(displayByDefault) ? displayByDefault : defaultDisplay
    getDisplayedModule = (displayModule, defaultDisplay) => hasValue(displayModule) ? displayModule : defaultDisplay

    getDefaultFilter = (adminAlertFiltersValue, stationType) => ({
        filter: this.getFilterValue(adminAlertFiltersValue[stationType]?.filter),
        alertLimit: this.getAlertLimit(adminAlertFiltersValue[stationType]?.alertLimit, stationType !== STATION_TYPE_NAME.installation ? 120 : 72),
        displayByDefault: this.getDefaultDisplayed(adminAlertFiltersValue[stationType]?.displayByDefault, stationType !== STATION_TYPE_NAME.installation),
        displayModule: this.getDisplayedModule(adminAlertFiltersValue[stationType]?.displayModule, true),
    })

    loadSituationDashboard() {
        const { globalParameters, userSettings, filters } = this.props

        const value = globalParameters.find(param => param.parameter === ADMIN_ALERT_FILTERS)?.value
        const adminAlertFiltersValue = !isNil(value) ? JSON.parse(value) : {}

        const alertStationsDisplayed = userSettings.find(param => param.parameter === ALERT_STATIONS_DISPLAYED)?.value
        const alertStationsDisplayedValue = !isNil(alertStationsDisplayed) ? JSON.parse(alertStationsDisplayed) : {}

        const adminAlertFilters = {
            [STATION_TYPE_NAME.piezometry]: this.getDefaultFilter(adminAlertFiltersValue, STATION_TYPE_NAME.piezometry),
            [STATION_TYPE_NAME.hydrometry]: this.getDefaultFilter(adminAlertFiltersValue, STATION_TYPE_NAME.hydrometry),
            [STATION_TYPE_NAME.pluviometry]: this.getDefaultFilter(adminAlertFiltersValue, STATION_TYPE_NAME.pluviometry),
            [STATION_TYPE_NAME.installation]: this.getDefaultFilter(adminAlertFiltersValue, STATION_TYPE_NAME.installation),
        }

        const piezoFilter = adminAlertFilters[STATION_TYPE_NAME.piezometry]?.filter
        const hydroFilter = adminAlertFilters[STATION_TYPE_NAME.hydrometry]?.filter
        const pluvioFilter = adminAlertFilters[STATION_TYPE_NAME.pluviometry]?.filter
        const instFilter = adminAlertFilters[STATION_TYPE_NAME.installation]?.filter

        const selectedFiltersObj = {
            [STATION_TYPE_NAME.piezometry]: adminAlertFilters[STATION_TYPE_NAME.piezometry].displayModule ? this.getFilterResultObject(filters, STATION_TYPE_NAME.piezometry, piezoFilter) : [],
            [STATION_TYPE_NAME.hydrometry]: adminAlertFilters[STATION_TYPE_NAME.hydrometry].displayModule ? this.getFilterResultObject(filters, STATION_TYPE_NAME.hydrometry, hydroFilter) : [],
            [STATION_TYPE_NAME.pluviometry]: adminAlertFilters[STATION_TYPE_NAME.pluviometry].displayModule ? this.getFilterResultObject(filters, STATION_TYPE_NAME.pluviometry, pluvioFilter) : [],
            [STATION_TYPE_NAME.installation]: adminAlertFilters[STATION_TYPE_NAME.installation].displayModule ? this.getFilterResultObject(filters, STATION_TYPE_NAME.installation, instFilter) : [],
        }

        const zoom = userSettings.find(param => param.parameter === SITUATION_MAP_ZOOM)?.value || adminAlertFiltersValue.zoom
        const longitude = userSettings.find(param => param.parameter === SITUATION_MAP_X_COORDINATE)?.value || adminAlertFiltersValue.longitude
        const latitude = userSettings.find(param => param.parameter === SITUATION_MAP_Y_COORDINATE)?.value || adminAlertFiltersValue.latitude

        this.props.loadSituationDashboard(p => this.setState({ progress: p }), selectedFiltersObj)
            .then((stationsIdByTypeName) => {
                this.setState({
                    dataLoaded: true,
                    stationsIdByTypeName,
                    adminAlertFilters,
                    displayPiezo: alertStationsDisplayedValue[STATION_TYPE_NAME.piezometry] ?? adminAlertFilters[STATION_TYPE_NAME.piezometry].displayByDefault,
                    displayHydro: alertStationsDisplayedValue[STATION_TYPE_NAME.hydrometry] ?? adminAlertFilters[STATION_TYPE_NAME.hydrometry].displayByDefault,
                    displayPluvio: alertStationsDisplayedValue[STATION_TYPE_NAME.pluviometry] ?? adminAlertFilters[STATION_TYPE_NAME.pluviometry].displayByDefault,
                    displayInst: alertStationsDisplayedValue[STATION_TYPE_NAME.installation] ?? adminAlertFilters[STATION_TYPE_NAME.installation].displayByDefault,
                    territoryImageSmall: adminAlertFiltersValue.territoryImageSmall,
                    mapConf: (hasValue(longitude) && hasValue(latitude)) && {
                        center: [longitude, latitude],
                        zoom,
                    },
                }, this.setComponentActions)
                this.props.fetchPiezometerChartLandmarks(this.props.piezoSituationLastMeasures.map(s => s.piezoId))
            })
    }

    preLoadSituationDashboard = () => {
        this.props.fetchFilters().then(() => this.props.fetchGlobalParameters().then(() => this.props.fetchUserSettings().then(() => {
            this.loadSituationDashboard()
            this.setReloadSituation()
        })))
    }

    reloadSituation = () => {
        const { selectedSite } = this.state
        if (!selectedSite.id) {
            this.loadSituationDashboard()
        }
    }

    setReloadSituation = () => {
        const { globalParameters } = this.props

        const value = globalParameters.find(param => param.parameter === ADMIN_ALERT_FILTERS)?.value
        const adminAlertFiltersValue = !isNil(value) ? JSON.parse(value) : {}

        const ms = (adminAlertFiltersValue.automaticRefresh && adminAlertFiltersValue.automaticRefresh * 60000) || 300000

        this.reloadInterval = setInterval(this.reloadSituation, ms)
    }

    componentWillUnmount() {
        this.props.receiveTempFormattedSites([])
        clearInterval(this.reloadInterval)
    }

    componentDidMount() {
        const { userBookmarks, hydrometryDataTypes, piezometryDataTypes, pluviometryDataTypes, flowObstructions, sandreCodes, cmsModels } = this.props
        this.props.fetchBookmarks().then(() => this.setState({ filteredBookmarks: userBookmarks.filter(bk => bk.stationType === 1 || bk.stationType === 2 || bk.stationType === 4) }))
        this.props.fetchDataTypes(getTypeModule(STATION_TYPE_NAME.piezometry)).then((piezometryAllDataTypes = []) => this.setState({ piezometryAllDataTypes }))
        this.props.fetchDataTypes(getTypeModule(STATION_TYPE_NAME.hydrometry)).then((hydrometryAllDataTypes = []) => this.setState({ hydrometryAllDataTypes }))
        this.props.fetchDataTypes(getTypeModule(STATION_TYPE_NAME.pluviometry)).then((pluviometryAllDataTypes = []) => this.setState({ pluviometryAllDataTypes }))

        if (!hydrometryDataTypes.length) {
            this.props.fetchHydrometryDataTypes()
        }
        if (!piezometryDataTypes.length) {
            this.props.fetchPiezometryDataTypes()
        }
        if (!pluviometryDataTypes.length) {
            this.props.fetchPluviometryDataTypes()
        }
        if (!flowObstructions.length) {
            this.props.fetchFlowObstructions()
        }
        if (!sandreCodes.length) {
            this.props.fetchSandreCodes()
        }
        if (!cmsModels.length) {
            this.props.fetchAllCMSModels()
        }

        this.preLoadSituationDashboard()

        this.props.fetchCMSWithoutWP()
        this.props.setTitle([
            {
                title: i18n.situation,
                href: PATH_ALERT,
            },
            {
                title: i18n.dashboard,
            },
        ])

        const departments = this.getDepartments()
        if (hasValue(departments) && departments.length >= 2) {
            this.props.fetchArrests(departments)
        }
    }

    getDepartments = () => {
        const { accountUser, applicationSettings } = this.props
        const contribSettings = hasValue(accountUser.contributorCode) ? (getSettingJson(applicationSettings, linkContributorsDepartments) || []).find((s) => s.contributor === accountUser.contributorCode) : null
        return contribSettings?.departments || getSetting(applicationSettings, 'arrestDepartments')
    }

    getSiteThresholds = (site, dateBeforeNbHours) => {
        if (site.measureDate && site.measureDate.isBefore(dateBeforeNbHours)) {
            return i18n.toControl
        } else if (!site.lastMeasure.length) {
            return i18n.toControl
        }
        switch (site.trend) {
            case 3:
                return i18n.crisis
            case 2:
                return i18n.vigilance
            default:
                return i18n.monitoring
        }
    }

    exportData = (exportedData, exportType, title) => {
        if (exportedData.length) {
            this.props.export(formatData(exportedData), exportType, title)
        } else {
            this.props.warning(i18n.noDataToExport)
        }
    }

    getStationsExport = (exportType) => {
        const { adminAlertFilters } = this.state
        const { citiesIndex } = this.props

        const dateNow = moment()
        const data = this.getSites().map(s => {
            const nbHoursBefore = adminAlertFilters[s.typeName].alertLimit
            const dateBeforeNbHours = moment(dateNow.subtract(nbHoursBefore, 'hour'))
            const threshold = this.getSiteThresholds(s, dateBeforeNbHours)

            return ({
                ...s,
                type: geti18n(s.typeName),
                openData: s.jobExecutionId ? i18n.yes : i18n.no,
                measureDate: s.lastMeasure[0] ? getFullDate(s.lastMeasure[0].measureDate) : '',
                lastMeasure: { value: s.lastMeasure.length ? s.lastMeasure[0].value : '', cellType: 'number' },
                city: citiesIndex && s.townCode && citiesIndex[s.townCode] ? citiesIndex[s.townCode].labelWithCode : '',
                threshold,
                events: s.currentComments || s.currentMonitoring ? i18n.yes : i18n.no,
                campaigns: s.currentCampaign ? i18n.yes : i18n.no,
            })
        })

        const exportedData = getDataWithHeaders(data, STATIONS_HEADERS)
        const title = `${i18n.situation}_${i18n.stations}`

        this.exportData(exportedData, exportType, title)
    }

    getArticlesExport = (exportType) => {
        const { cmsCategories, sandreCodes } = this.props

        const cmsStatus = sandreCodes.filter((c) => c.field === 'CMS.STATUS')

        const data = this.getActiveCms().map(cms => ({
            ...cms,
            category: cmsCategories.find(c => c.id === cms.idCategory)?.title || '',
            status: cmsStatus.find(s => s.code === cms.status)?.name || '',
            updateDate: getFullDateMini(cms.updateDate) || '',
        }))

        const exportedData = getDataWithHeaders(data, ARTICLES_HEADERS)
        const title = `${i18n.situation}_${i18n.articles}`

        this.exportData(exportedData, exportType, title)
    }

    getExportChoice = () => {
        return {
            exportChoices: [{
                name: i18n.stationsExport,
                formats: [{
                    type: i18n.csv,
                    callback: () => this.getStationsExport('csv'),
                },
                {
                    type: i18n.exportXLSX,
                    callback: () => this.getStationsExport('xlsx'),
                }],
            }, {
                name: i18n.itemsExport,
                formats: [{
                    type: i18n.csv,
                    callback: () => this.getArticlesExport('csv'),
                },
                {
                    type: i18n.exportXLSX,
                    callback: () => this.getArticlesExport('xlsx'),
                }],
            }],
        }
    }

    setComponentActions = (tabId) => {
        if (!tabId && !hasHabilitation(SITUATION_MONITORING, false) && hasHabilitation(SITUATION_POLLUTION, false)) {
            this.setState({ selectedTab: 'cmsPollutionEvents' })
        } else {
            this.setState({ selectedTab: tabId })
        }

        const user = getUser()
        const actions = {
            exportChoice: this.getExportChoice(),
        }
        const newAction = (user.admin === '1' || user.metadata === '1') ? { new: () => this.setState({ openAlertNewCms: true }) } : {}

        this.setActions({ ...actions, ...newAction })
    }

    onGetPopinInfo = (site, panelToOpen, eventsSortBy, onlyDescription) => {
        if (site.typeName === STATION_TYPE_NAME.installation) {
            const { installationsSituation } = this.props
            const installationSituation = installationsSituation.find(i => i.stationId === site.id)
            this.props.fetchInstallationAssociatedEvents(site.code).then(json => {
                const selectedSiteEvents = json ? keys(json).flatMap(key => json[key]) : []
                this.setState({
                    selectedSiteEvents,
                    selectedSiteThreshold: [installationSituation.threshold],
                    selectedSite: site,
                    panel: panelToOpen,
                    onlyDescription: false,
                    sortBy: eventsSortBy ? eventsSortBy : 'date',
                })
            })
        } else {
            const urls = getUrls(site)
            if (urls && !onlyDescription) {
                this.props.waitStart()
                this.props.loadStationData(urls)
                    .then(jsonTab => {
                        this.setState({
                            selectedSiteEvents: jsonTab[0],
                            selectedSiteCampaigns: jsonTab[1],
                            selectedSiteThreshold: jsonTab[2],
                            selectedSite: site,
                            panel: panelToOpen,
                            onlyDescription: false,
                            sortBy: eventsSortBy ? eventsSortBy : 'date',
                        })
                        this.props.waitStop()
                    })
            } else {
                this.setState({
                    selectedSite: site,
                    onlyDescription,
                    panel: panelToOpen,
                })
            }
        }
    }

    getActiveCms = () => {
        const {
            searchValue,
        } = this.state.filter

        const searchCharacters = searchAllCharacters(searchValue)
        return hasValue(searchValue) ? this.props.cmsEvents.filter(site => searchAllCharacters(site.title).includes(searchCharacters)) : this.props.cmsEvents
    }

    getVisibleCmsMarkers = () => {
        const now = +moment()
        const {
            searchValue,
        } = this.state.filter
        const searchCharacters = searchAllCharacters(searchValue)
        const cmsEvents = hasValue(searchValue) ? this.props.cmsEvents.filter(site => searchAllCharacters(site.title).includes(searchCharacters)) : this.props.cmsEvents
        const eventPublish = !this.state.visibilityOption.includes(i18n.published) ?
            cmsEvents.filter(oe => oe.status === 1 && (!oe.dateFin || oe.dateFin > now)) : []
        const eventDraft = !this.state.visibilityOption.includes(i18n.draft) ?
            getUser().admin !== '1' ? cmsEvents.filter(oe => oe.status === 2 && oe.author === getUser().login)
                : cmsEvents.filter(oe => oe.status === 2) : []
        const eventArchived = !this.state.visibilityOption.includes(i18n.archived) ?
            cmsEvents.filter(oe => oe.status === 3 || oe.dateFin < now) : []
        const cmsEventsFiltered = [...eventPublish, ...eventDraft, ...eventArchived]
        return cmsEventsFiltered
    }

    getVisibleStationByTheme() {
        return this.state.filterTheme.flatMap(theme => {
            switch (theme) {
                case 'PIEZOMETRY':
                    return this.props.piezometers
                case 'HYDROMETRY':
                    return this.props.hydrometricStations
                case 'METEOROLOGY':
                    return this.props.pluviometers
                case 'HYDROMORPHOLOGY':
                    return [...this.props.hydrometricStations, ...this.props.flowObstruction]
                case 'HYDROBIOLOGY':
                    return this.props.surfaceQualitometer
                case 'PHYSICO_CHEMISTRY':
                    return this.props.qualitometers
                case 'AGRICULTURE':
                    return this.props.sampleWorks
                case 'INDUSTRY':
                    return [...this.props.sampleWorks, ...this.props.industrialSites, ...this.props.pollutedSoils]
                case 'SERVICES':
                    return [...this.props.sampleWorks, ...this.props.step, ...this.props.captures]
                case 'GROUND_AND_BASEMENT':
                    return [...this.props.boreholes, ...this.props.cavities]
                default:
                    return []
            }
        })
    }

    getAllStationByTheme() {
        if (this.state.selectedTab === 'themes' || this.state.selectedTab === 'cmsPollutionEvents') {
            return [
                { name: PIEZOMETRY, dataList: [{ station: PIEZOMETER, data: this.props.piezometers }] },
                { name: HYDROMETRY, dataList: [{ station: HYDROMETRIC_STATION, data: this.props.hydrometricStations }] },
                { name: METEOROLOGY, dataList: [{ station: PLUVIOMETER, data: this.props.pluviometers }] },
                { name: HYDROMORPHOLOGY, dataList: [{ station: HYDROMETRIC_STATION, data: this.props.hydrometricStations }, { station: INSTALLATION_FLOW_OBSTRUCTION, data: this.props.flowObstruction }] },
                { name: HYDROBIOLOGY, dataList: [{ station: QUALITOMETER_SURFACE, data: this.props.surfaceQualitometer }] },
                { name: PHYSICO_CHEMISTRY, dataList: [{ station: QUALITOMETER, data: this.props.qualitometers }] },
                { name: AGRICULTURE, dataList: [{ station: INSTALLATION_SAMPLE_WORK, data: this.props.sampleWorks }] },
                { name: INDUSTRY, dataList: [{ station: INSTALLATION_SAMPLE_WORK, data: this.props.sampleWorks }, { station: INSTALLATION_INDUSTRIAL_SITE, data: this.props.industrialSites }, { station: INSTALLATION_POLLUTED_SOIL, data: this.props.pollutedSoils }] },
                { name: SERVICES, dataList: [{ station: INSTALLATION_SAMPLE_WORK, data: this.props.sampleWorks }, { station: INSTALLATION_STEP, data: this.props.step }, { station: INSTALLATION_CAPTURE, data: this.props.captures }] },
                { name: GROUND_AND_BASEMENT, dataList: [{ station: INSTALLATION_BOREHOLE, data: this.props.boreholes }, { station: INSTALLATION_CAVITY, data: this.props.cavities }] },
            ]
        } return []
    }

    setThemeFilter(filter) {
        let array = [...this.state.filterTheme]
        const index = this.state.filterTheme.indexOf(filter)
        if (index > -1) {
            array.splice(index, 1)
            this.setState({ filterTheme: [...array] })
        } else {
            this.setState({ filterTheme: [...this.state.filterTheme, filter] })
        }
    }

    getMapPanels = sites => {
        const {
            piezometryAllDataTypes,
            hydrometryAllDataTypes,
            pluviometryAllDataTypes,
            stationsIdByTypeName,
            adminAlertFilters,
            selectedStationType,
            monitoringType,
        } = this.state
        const {
            hydrometryDataTypes,
            piezometryDataTypes,
            pluviometryDataTypes,
        } = this.props
        const dataTypePiezo = {
            ...[...getHardPiezoDataTypes(), ...piezometryDataTypes].find(d => d.id === -1),
            ...piezometryAllDataTypes.find(d => d.id === -1),
        }
        const dataTypeHydro = {
            ...[...getHardHydroDataTypes(), ...hydrometryDataTypes].find(d => d.id === 4),
            ...hydrometryAllDataTypes.find(d => d.id === 4),
        }
        const dataTypePluvio = {
            ...pluviometryDataTypes.find(d => d.id === 1),
            ...pluviometryAllDataTypes.find(d => d.id === 1),
        }
        return [
            {
                id: 'alertStations',
                icon: 'location_on',
                title: i18n.situation,
                content: (
                    <AlertMapTabs
                        onClick={(site, panelToOpen, eventsSortBy) => this.onGetPopinInfo(site, panelToOpen, eventsSortBy)}
                        onHover={(site, huge) => setStationPointScale(site, huge)}
                        sites={sites}
                        onChange={change => this.setState(change)}
                        displayPiezo={this.state.displayPiezo}
                        displayPluvio={this.state.displayPluvio}
                        displayHydro={this.state.displayHydro}
                        displayInst={this.state.displayInst}
                        selectedDate={this.state.selectedDate}
                        hiddenNoThresholdStations={this.state.hiddenNoThresholdStations}
                        searchValue={this.state.searchValue}
                        dataTypePiezo={dataTypePiezo}
                        dataTypeHydro={dataTypeHydro}
                        dataTypePluvio={dataTypePluvio}
                        stationsIdByTypeName={stationsIdByTypeName}
                        adminAlertFilters={adminAlertFilters}
                        selectedStationType={selectedStationType}
                        monitoringType={monitoringType}
                    />
                ),
                habilitation: H_SITUATION_MONITORING,
            },
            {
                id: 'cmsEvents',
                icon: 'event_note',
                title: i18n.communication,
                content: (
                    <EventsMapTabs
                        onSetVisibilityOption={visibilityOption => this.setState({ visibilityOption })}
                        onHover={(cms, huge) => setStationPointScale(cms, huge, 'cms')}
                        cmsEvents={this.getActiveCms()}
                        arrests={this.props.arrests}
                    />
                ),
                habilitation: H_SITUATION_FLOOD,
            },
            {
                id: 'themes',
                icon: 'format_list_bulleted',
                title: i18n.themes,
                content: (
                    <ThemesMapTab
                        onClick={(site, panelToOpen, eventsSortBy) => this.onGetPopinInfo(site, panelToOpen, eventsSortBy, true)}
                        allStation={this.getAllStationByTheme()}
                        onHover={(station, huge) => setStationPointScale(station, huge)}
                        onCheck={(filterTheme) => this.setThemeFilter(filterTheme)}
                        selectedThemes={this.state.filterTheme}
                    />
                ),
                habilitation: H_SITUATION_THEME,
            },
        ].filter(p => componentHasHabilitations(p.habilitation))
    }

    formatMarker = (site, piezoDataType, hydroDataType, pluvioDataType, dataTypeInst) => {
        const {
            hydrometryDataTypes,
            piezometryDataTypes,
            pluviometryDataTypes,
            hydroSituationLastMeasures,
            piezoSituationLastMeasures,
            hydrometryThresholds,
            pluviometerAllThresholds,
            pluvioSituationLastMeasures,
            piezometerChartLandmarks,
            allPiezometerThresholds,
            flowObstructions,
        } = this.props
        const { filteredBookmarks, adminAlertFilters } = this.state

        const dataTypePiezo = [...getHardPiezoDataTypes().filter(dt => !piezometryDataTypes.some(odt => odt.id === dt.id)), ...piezometryDataTypes]
        const dataTypeHydro = [...getHardHydroDataTypes().filter(dt => !hydrometryDataTypes.some(odt => odt.id === dt.id)), ...hydrometryDataTypes]
        const dataTypePluvio = pluviometryDataTypes

        const allDataTypes = {
            piezometry: dataTypePiezo,
            hydrometry: dataTypeHydro,
            pluviometry: dataTypePluvio,
        }

        const { lastMeasure, thresholds } = execByType(site.typeName, {
            hydrometry: () => ({
                lastMeasure: getAllLastMeasuresFormat(site.id, hydroSituationLastMeasures, STATION_TYPE_NAME.hydrometry, dataTypeHydro),
                thresholds: hydrometryThresholds.filter(t => parseInt(t.stationId) === site.id),
            }),
            pluviometry: () => ({
                lastMeasure: getAllLastMeasuresFormat(site.id, pluvioSituationLastMeasures, STATION_TYPE_NAME.pluviometry, dataTypePluvio),
                thresholds: pluviometerAllThresholds.filter(t => t.code === site.code),
            }),
            piezometry: () => {
                const chartLandmarks = piezometerChartLandmarks.find(s => s.id === site.id) || {}
                return {
                    lastMeasure: getAllLastMeasuresFormat(site.id, piezoSituationLastMeasures, STATION_TYPE_NAME.piezometry, dataTypePiezo, chartLandmarks.lastLandmark, chartLandmarks.groundRefAlti),
                    thresholds: allPiezometerThresholds.filter(t => t.code === site.code),
                }
            },
            installation: () => {
                const { installationsSituation } = this.props
                const installationSituation = installationsSituation.find(i => i.stationId === site.id)
                const dataType = dataTypeHydro.find(d => d.id === installationSituation?.lastMeasure?.dataType) || {}
                return {
                    lastMeasure: installationSituation?.lastMeasure ? [{ ...installationSituation.lastMeasure, unit: dataType.unit || '', title: dataType.label || '' }] : [],
                    thresholds: installationSituation?.threshold ? [installationSituation.threshold] : [],
                }
            },
            default: () => ({
                lastMeasure: [],
                thresholds: [],
            }),
        })

        const dataTypes = allDataTypes[site.typeName]
        const lastMeasureFormatted = orderBy(uniqBy(lastMeasure, 'dataType'), 'dataType').map(lm => {
            const measureDate = lm.lastMeasureDate || lm.measureDate || lm.date
            return {
                ...lm,
                unit: lm?.dataType ? dataTypes?.find(dt => dt.id === lm.dataType)?.unit : '',
                measureDate,
                dataTypeLabel: lm?.dataType ? dataTypes?.find(dt => dt.id === lm.dataType)?.label : '',
            }
        })

        const thresholdsDataTypes = uniq(thresholds.map(th => th.dataType))
        const lastMeasureByThreshold = lastMeasure.filter(lm => thresholdsDataTypes.includes(`${lm.dataType}`))
        const lastMeasures = thresholdsDataTypes.length && lastMeasureByThreshold.length ? lastMeasureByThreshold : lastMeasure
        const lastMeasuresFormatted = lastMeasures.map(lm => ({ ...lm, date: lm.date || lm.measureDate }))

        const allTrendObjects = thresholdsDataTypes.map(tdt => {
            const lastMeasuresFiltered = lastMeasuresFormatted.filter(lm => `${lm.dataType}` === `${tdt}`)
            return TrendLevelObject(
                getMeasureArrayValues(lastMeasuresFiltered),
                thresholds.filter(dt => lastMeasuresFiltered.some(lm => `${lm.dataType}` === `${dt?.dataType || dt}`)),
                tdt,
            )
        })

        const allTrendThresholds = thresholdsDataTypes.flatMap(tdt => {
            const objectTrend = allTrendObjects.find(to => `${to.dataType}` === `${tdt}`)
            const objTrendFormatted = (!objectTrend && allTrendObjects.some(to => !to.dataType)) ? allTrendObjects.find(to => !to.dataType) : objectTrend
            return getTrendThreshold(objTrendFormatted, site.typeName === 'piezometry')
        })

        const thresholdsToCompare = allTrendThresholds.length ? allTrendThresholds : allTrendObjects
        const maxLastMeasure = thresholdsToCompare.length ? maxBy(lastMeasuresFormatted.filter(lm => thresholdsToCompare.some(tt => `${tt.dataType}` === `${lm.dataType}`)), 'date') : lastMeasuresFormatted[0]

        const trendObject = allTrendObjects.find(ato => `${ato.dataType}` === `${maxLastMeasure?.dataType}`) || TrendLevelObject(
            maxLastMeasure ? getMeasureArrayValues([maxLastMeasure]) : [],
            thresholds.filter(dt => `${maxLastMeasure?.dataType}` === `${dt?.dataType || dt}`),
        )

        const dataType = {
            ...execByType(site.typeName, {
                piezometry: () => piezoDataType,
                hydrometry: () => hydroDataType,
                pluviometry: () => pluvioDataType,
                installation: () => dataTypeInst,
                default: () => {},
            }),
            alertLimit: adminAlertFilters[site.typeName].alertLimit,
        }
        const measureDate = maxLastMeasure && moment(maxLastMeasure.measureDate || maxLastMeasure.date)
        const trend = getTrendLevel(trendObject)

        const trendThreshold = allTrendThresholds.length ? [allTrendThresholds.find(att => `${att.dataType}` === `${maxLastMeasure?.dataType}`)].filter(a => !!a) : []

        const thresholdColor = thresholds?.[0]?.htmlColor || thresholds?.[0]?.color
        const color = (site.typeName === STATION_TYPE_NAME.installation && thresholdColor) ? thresholdColor : getTrendThresholdColor(thresholds, trendThreshold[0], measureDate, lastMeasures[0], dataType)
        const markerIcon = execByType(site.typeName, {
            piezometry: () => getPiezoMarker(color),
            hydrometry: () => site.stationType === TIDE_GAUGE_TYPE ? getHydroTideMarker(color) : getHydroMarker(color),
            pluviometry: () => getPluvioMarker(color),
            installation: () => getInstMarkerByType(site.installationType, color),
            default: () => '',
        })
        const cardMarkerIcon = execByType(site.typeName, {
            piezometry: () => getCardPiezoMarker(mainWhite),
            hydrometry: () => site.stationType === TIDE_GAUGE_TYPE ? getHydroTideMarker(color, true) : getCardHydroMarker(mainWhite),
            pluviometry: () => getCardPluvioMarker(mainWhite),
            installation: () => getInstMarkerByType(site.installationType, color, true),
            default: () => '',
        })
        const markerName = execByType(site.typeName, {
            piezometry: () => i18n.piezometer,
            hydrometry: () => site.stationType === TIDE_GAUGE_TYPE ? i18n.tidegauge : i18n.hydrometricStation,
            pluviometry: () => i18n.pluviometricStation,
            installation: () => getI18nOrLabel(INSTALLATION_TYPES.find(i => i.code === site.installationType)?.label || site.typeName),
            default: () => getI18nOrLabel(site.typeName),
        })
        const biefCode = execByType(site.typeName, {
            installation: () => flowObstructions.find(f => f.id === site.id)?.biefCode,
            default: () => undefined,
        })

        return {
            ...site,
            name: site?.name?.toUpperCase() || '',
            biefCode,
            dataType,
            lastMeasure,
            lastMeasures: lastMeasureFormatted,
            measures: this.state.monthlyMeasures.length ? this.state.monthlyMeasures : [],
            measureDate,
            trend,
            trendObject,
            trendThreshold,
            thresholds,
            siteType: findStationType(site.typeName).code,
            cardMarkerIcon,
            markerIcon,
            markerName,
            fromSituation: true,
            color,
            bookmarked: execByType(site.typeName, {
                hydrometry: () => filteredBookmarks.find(bk => bk.stationType === 4 && bk.identifiant === site.code),
                pluviometry: () => filteredBookmarks.find(bk => bk.stationType === 2 && bk.identifiant === site.code),
                piezometry: () => filteredBookmarks.find(bk => bk.stationType === 1 && bk.identifiant === site.code),
                installation: () => filteredBookmarks.find(bk => bk.stationType === 7 && bk.identifiant === site.code),
                default: () => false,
            }),
        }
    }

    filterByStationType = (site) => {
        const { selectedStationType } = this.state

        const siteFormatted = {
            ...site,
            installationType: site.installationType || -1,
            stationType: site.stationType || -1,
        }

        switch (siteFormatted.typeName) {
            case STATION_TYPE_NAME.installation:
                return selectedStationType[siteFormatted.typeName]?.find(st => st.id === siteFormatted.installationType)?.selected
            default:
                return selectedStationType[siteFormatted.typeName]?.find(st => st.id === siteFormatted.stationType)?.selected
        }
    }

    getSites = () => {
        const {
            searchValue,
            displayHydro,
            displayPiezo,
            displayPluvio,
            displayInst,
            piezometryAllDataTypes,
            hydrometryAllDataTypes,
            pluviometryAllDataTypes,
            stationsIdByTypeName,
            selectedStationType,
            monitoringType,
        } = this.state
        const {
            hydrometryDataTypes,
            piezometryDataTypes,
            pluviometryDataTypes,
            sites,
            citiesIndex,
        } = this.props
        const now = moment()
        const sitesFiltered = keys(stationsIdByTypeName).length ? sites.filter(s => stationsIdByTypeName[s.typeName]?.includes(s.id)) : sites
        const filteredSitesByStationType = sitesFiltered.filter(site => !keys(selectedStationType).includes(site.typeName) || this.filterByStationType(site))
        const filterHydro = displayHydro ? filteredSitesByStationType : filteredSitesByStationType.filter(s => s.typeName !== STATION_TYPE_NAME.hydrometry)
        const filterPiezo = displayPiezo ? filterHydro : filterHydro.filter(s => s.typeName !== STATION_TYPE_NAME.piezometry)
        const filterPluvio = displayPluvio ? filterPiezo : filterPiezo.filter(s => s.typeName !== STATION_TYPE_NAME.pluviometry)
        const filterInst = displayInst ? filterPluvio : filterPluvio.filter(s => s.typeName !== STATION_TYPE_NAME.installation)
        const searchCharacters = searchAllCharacters(searchValue)
        const filterDate = filterInst.filter(s => !hasValue(s.closeDate) || moment(s.closeDate).isAfter(now))
        const filterSearchValue = hasValue(searchValue) ? filterDate.filter(site => searchAllCharacters(`${site.code}${site.name}${site.townCode}${citiesIndex[site.townCode]?.name}`).includes(searchCharacters)) : filterDate
        const dataTypePiezo = {
            ...[...getHardPiezoDataTypes(), ...piezometryDataTypes].find(d => d.id === -1),
            ...piezometryAllDataTypes.find(d => d.id === -1),
        }
        const dataTypeHydro = {
            ...[...getHardHydroDataTypes(), ...hydrometryDataTypes].find(d => d.id === 4),
            ...hydrometryAllDataTypes.find(d => d.id === 4),
        }
        const dataTypePluvio = {
            ...pluviometryDataTypes.find(d => d.id === 1),
            ...pluviometryAllDataTypes.find(d => d.id === 1),
        }
        const dataTypeInst = {
            ...[...getHardHydroDataTypes(), ...hydrometryDataTypes].find(d => d.id === IIBSN_CONSIGNE_ID),
            ...hydrometryAllDataTypes.find(d => d.id === IIBSN_CONSIGNE_ID),
        }
        return filterSearchValue.map(s => this.formatMarker(s, dataTypePiezo, dataTypeHydro, dataTypePluvio, dataTypeInst)).filter(s => {
            if (monitoringType === MONITORING_OPTIONS.SITUATION_FLOOD) {
                return !s.thresholds?.length || s.thresholds.some(t => t.isOverrunThreshold === IS_OVERRUN_THRESHOLD)
            } else if (monitoringType === MONITORING_OPTIONS.LOWWATER) {
                return !s.thresholds?.length || s.thresholds.some(t => !t.isOverrunThreshold || (t.isOverrunThreshold === ISNT_OVERRUN_THRESHOLD))
            }
            return s
        })
    }

    getProgressBar = () => (
        <Card>
            <div className='padding-top-2 padding-left-2 padding-right-2 padding-bottom-2'>
                <ProgressBar progress={this.state.progress} withMessage />
            </div>
        </Card>
    )

    onClosePopin = () => {
        this.setState({ selectedSite: {}, panel: DESCRIPTION })
    }

    getTitleAndColor = site => {
        const { adminAlertFilters } = this.state
        const dateNow = moment()
        const dateBeforeNbHours = moment(dateNow.subtract(adminAlertFilters[site.typeName].alertLimit, 'hour'))
        if (!site.thresholds.length) {
            return { title: i18n.noThresholds, color: THRESHOLD_COLORS_CODES.LIGHT_BLUE }
        } else if (site.thresholds.filter(t => !t.name && !t.title).length) {
            return { title: i18n.unknownThreshold, color: THRESHOLD_COLORS_CODES.ORANGE }
        } else if ((site.measureDate && site.measureDate.isBefore(dateBeforeNbHours)) || !site.lastMeasure.length) {
            return { title: i18n.toControl, color: THRESHOLD_COLORS_CODES.LIGHT_GREY }
        } else if (site.trendThreshold.length) {
            const t = site.trendThreshold[0]
            if (t.name || t.title) {
                return { title: `${t.name || t.title} (${t.value})`, color: t.htmlColor || getThresholdColorCode(t.color) }
            }
            return { title: i18n.unknownThreshold, color: THRESHOLD_COLORS_CODES.ORANGE }
        }
        return { title: i18n.monitoring, color: THRESHOLD_COLORS_CODES.GREEN }
    }

    addPopupContent = s => {
        const { title, color } = this.getTitleAndColor(s, s.dataType)
        const colorByType = s.typeName === STATION_TYPE_NAME.installation ? s.color : color
        return {
            ...s,
            onMapClick: () => this.onGetPopinInfo(s),
            popupContent: (
                <CardMapStation
                    site={{ ...s, color: colorByType, alertLabel: title }}
                    isMap
                    onClick={() => this.onGetPopinInfo(s)}
                    detailed
                />
            ),
        }
    }

    getData = (sites, cmsMarkers) => {
        if (this.state.selectedTab === 'themes') {
            return this.getVisibleStationByTheme()
        } else if (this.state.selectedTab === 'cmsPollutionEvents') {
            const stations = [
                ...this.props.qualitometers,
                ...this.props.piezometersLight,
                ...this.props.installations,
                ...this.props.productionUnits,
                ...this.props.pluviometers,
                ...this.props.hydrometricStations,
                ...cmsMarkers,
            ]
            return stations
        } else if (!componentHasHabilitations(H_SITUATION_COMPLETE_MONITORING)) {
            return []
        } else if (!componentHasHabilitations(H_SITUATION_POLLUTION)) {
            return sites.map(this.addPopupContent)
        } else if (!componentHasHabilitations(H_SITUATION_MONITORING)) {
            return cmsMarkers
        }
        return [...sites.map(this.addPopupContent), ...cmsMarkers]
    }

    createParameter = (parameter, value) => new DtoAccountSettings({
        login: this.props.accountUser.login,
        parameter,
        updateDate: moment().valueOf(),
        value,
    })

    changeMap = (value) => {
        const { userSettings, accountUser } = this.props
        const zoom = value.getView().getZoom()
        const point = transform(value.getView().getCenter(), 'EPSG:3857', 'EPSG:4326')

        const zoomParameter = this.createParameter(SITUATION_MAP_ZOOM, zoom.toString())
        const xParameter = this.createParameter(SITUATION_MAP_X_COORDINATE, point[0].toString())
        const yParameter = this.createParameter(SITUATION_MAP_Y_COORDINATE, point[1].toString())
        const result = [xParameter, yParameter, zoomParameter]

        const settings = [...userSettings.filter(us => ![SITUATION_MAP_ZOOM, SITUATION_MAP_X_COORDINATE, SITUATION_MAP_Y_COORDINATE].includes(us.parameter)), ...result]
        this.props.saveSettings(accountUser.login, settings, false, false)
    }

    render() {
        const {
            selectedSite,
            selectedSiteEvents,
            selectedSiteCampaigns,
            selectedSiteThreshold,
            hiddenNoThresholdStations,
            dataLoaded,
            openAlertNewCms,
            territoryImageSmall,
            meteoImageSmall,
            tempSitesDefined,
            mapConf,
        } = this.state

        const { globalParameters, accountUser } = this.props

        if (dataLoaded) {
            const sites = this.getSites()
            if (!tempSitesDefined) {
                this.props.receiveTempFormattedSites(sites)
                this.setState({ tempSitesDefined: true })
            }
            const cmsMarkers = this.getVisibleCmsMarkers().map(cms => ({ ...cms, projection: 16 })).filter(cms => hasLocalisationStation(cms)).map(cms => {
                const category = this.props.cmsCategories.find(c => c.id === cms.idCategory) || {}
                const markerIcon = getCmsMarker(cms, category)
                return {
                    ...cms,
                    typeName: 'cms',
                    markerIcon,
                }
            })

            const stationsPoints = this.getData(sites, cmsMarkers)
            const stationPointsFiltered = hiddenNoThresholdStations ? stationsPoints.filter(s => s.thresholds?.length) : stationsPoints

            const value = globalParameters.find(param => param.parameter === ADMIN_ALERT_FILTERS)?.value
            const adminAlertFiltersValue = !isNil(value) ? JSON.parse(value) : {}

            const territoryStateLink = adminAlertFiltersValue?.territoryStateLink
            const territoryImageLink = adminAlertFiltersValue?.territoryImageLink
            const widgetMeteofrance = adminAlertFiltersValue?.widgetMeteofrance

            const territoryImageSize = territoryImageSmall ? { height: '6rem', width: '8rem' } : { height: '300px', width: '300px' }
            const imgStyle = { cursor: 'pointer', ...territoryImageSize, objectFit: 'cover', borderRadius: '5px' }

            const showMeteoFranceWidget = !!widgetMeteofrance?.length && window.navigator.userAgent.includes('Firefox')

            return (
                <div
                    className='dropable'
                    style={{
                        minWidth: 'calc(100vw - 100px)',
                        minHeight: 'calc(100vh - 100px)',
                        position: 'relative',
                        overflow: 'hidden',
                    }}
                >
                    <CartographyPanel
                        cmsEvents={this.getActiveCms()}
                        layers={['STATIONS_POINTS']}
                        componentType={'alerts'}
                        stationsPoints={stationPointsFiltered}
                        stationsPanelTitle={i18n.stations}
                        panels={this.getMapPanels(sites)}
                        onChangeTab={tabId => this.setComponentActions(tabId)}
                        noStationPanel
                        pollutionPanel={true}
                        tabsOpenByDefault
                        popupStyle={{ padding: 0 }}
                        mapConf={mapConf}
                        onMoveEnd={this.changeMap}
                    />
                    {!!territoryImageLink?.length && (
                        <Card round cardStyle={{ position: 'absolute', bottom: `${showMeteoFranceWidget ? (meteoImageSmall ? '12rem' : '15.5rem') : '3rem'}`, left: '1rem', ...territoryImageSize }}>
                            {territoryStateLink?.length ? (
                                <div style={{ position: 'relative', width: '100%', height: '100%' }}>
                                    <Icon
                                        icon={territoryImageSmall ? 'unfold_more_outlined' : 'unfold_less_outlined'}
                                        tooltip={territoryImageSmall ? i18n.enlarge : i18n.reduct}
                                        style={{
                                            position: 'absolute',
                                            top: '-0.7rem',
                                            right: '-0.7rem',
                                            backgroundColor: mainWhite,
                                            color: secondaryBlue,
                                            borderRadius: '50%',
                                            height: '2.5rem',
                                            width: '2.5rem',
                                            fontSize: '2rem',
                                            padding: '0.25rem',
                                            transform: 'rotate(45deg)',
                                        }}
                                        onClick={() => {
                                            const newTerritoryImageSmall = !territoryImageSmall
                                            const param = new GlobalParametersDto({
                                                parameter: ADMIN_ALERT_FILTERS,
                                                module: SIEAU,
                                                value: JSON.stringify({
                                                    ...adminAlertFiltersValue,
                                                    territoryImageSmall: newTerritoryImageSmall,
                                                }),
                                                updateDate: moment().valueOf(),
                                                updateLogin: accountUser.login,
                                            })
                                            this.props.updateGlobalParameter(param).then(json => {
                                                if (json > 0) {
                                                    this.props.fetchGlobalParameters()
                                                }
                                            })
                                            this.setState({ territoryImageSmall: newTerritoryImageSmall })
                                        }}
                                    />
                                    <img
                                        src={territoryImageLink}
                                        onClick={() => window.open(territoryStateLink, '_blank')}
                                        style={imgStyle}
                                    />
                                </div>
                            ) : (
                                <Fancybox>
                                    <a href={ territoryImageLink } data-fancybox='images' data-caption={ i18n.territoryImageLink }>
                                        <img src={ territoryImageLink } style={imgStyle} />
                                    </a>
                                </Fancybox>
                            )}
                        </Card>
                    )}
                    {showMeteoFranceWidget && (
                        <Card round cardStyle={{ position: 'absolute', bottom: '3rem', left: '1rem' }}>
                            <Icon
                                icon={meteoImageSmall ? 'unfold_more_outlined' : 'unfold_less_outlined'}
                                tooltip={meteoImageSmall ? i18n.enlarge : i18n.reduct}
                                style={{
                                    position: 'absolute',
                                    top: '-0.7rem',
                                    right: '-0.7rem',
                                    backgroundColor: mainWhite,
                                    color: secondaryBlue,
                                    borderRadius: '50%',
                                    height: '2.5rem',
                                    width: '2.5rem',
                                    fontSize: '2rem',
                                    padding: '0.25rem',
                                    transform: 'rotate(45deg)',
                                }}
                                onClick={() => this.setState({ meteoImageSmall: !meteoImageSmall })}
                            />
                            {meteoImageSmall ? (
                                <img
                                    src={meteoFranceLogo}
                                    className='clickable'
                                    style={{ height: '8rem', width: 'auto', borderRadius: '5px' }}
                                    onClick={() => window.open(vigilancesMeteoFrancePath, '_blank')}
                                />
                            ) : <iframe src={widgetMeteofrance} style={{ borderRadius: 5 }} frameBorder='0' />}
                        </Card>
                    )}
                    <AlertMapPopin
                        siteData={{
                            selectedSite,
                            selectedSiteEvents,
                            selectedSiteCampaigns,
                            selectedSiteThreshold,
                        }}
                        onlyDescription={this.state.onlyDescription}
                        onClose={this.onClosePopin}
                        citiesIndex={this.props.citiesIndex}
                        navigateTo={this.props.navigateTo}
                        onReload={(site, panel) => this.onGetPopinInfo(site, panel)}
                    />
                    <AlertNewCmsStepper
                        isOpen={openAlertNewCms}
                        setIsOpen={(isOpen) => this.setState({ openAlertNewCms: isOpen })}
                    />
                </div>
            )
        }
        return this.getProgressBar()
    }
}

AlertMapApp.propTypes = {
    connectDropTarget: PropTypes.func.isRequired,
    hydroSituationLastMeasures: PropTypes.arrayOf(PropTypes.instanceOf(DtoHydroMeasures)),
    hydrometryThresholds: PropTypes.arrayOf(PropTypes.instanceOf(DtoHydrometryThreshold)),
    pluviometerAllThresholds: PropTypes.arrayOf(PropTypes.instanceOf(PluviometerThresholdDto)),
    pluvioSituationLastMeasures: PropTypes.arrayOf(PropTypes.instanceOf(DtoPluvioMeasureObj)),
    allPiezometerThresholds: PropTypes.arrayOf(PropTypes.instanceOf(DtoPiezoThreshold)),
    piezoSituationLastMeasures: PropTypes.arrayOf(PropTypes.instanceOf(DtoPiezometryStationMeasure)),
    monthlyMeasures: PropTypes.arrayOf(PropTypes.instanceOf(DtoPiezometryStationMeasure)),
    sites: PropTypes.arrayOf(PropTypes.object),
    arrests: PropTypes.arrayOf(PropTypes.instanceOf(DtoArrest)),
    citiesIndex: PropTypes.object,
    piezometersLight: PropTypes.arrayOf(DtoPiezometerLight),
    navigateTo: PropTypes.func,
    cmsCategories: PropTypes.arrayOf(CMSCategoryDto),
    cmsEvents: PropTypes.arrayOf(CMSEventDto),
    applicationSettings: PropTypes.arrayOf(PropTypes.instanceOf(SieauParameterDto)),
    piezometers: PropTypes.arrayOf(PropTypes.instanceOf(DtoPiezometerLight)),
    hydrometricStations: PropTypes.arrayOf(PropTypes.instanceOf(DtoHydrometricStation)),
    pluviometers: PropTypes.arrayOf(PropTypes.instanceOf(PluviometerDto)),
    flowObstruction: PropTypes.arrayOf(PropTypes.instanceOf(DtoInstallation)),
    sampleWorks: PropTypes.arrayOf(PropTypes.instanceOf(DtoInstallation)),
    industrialSites: PropTypes.arrayOf(PropTypes.instanceOf(DtoInstallation)),
    pollutedSoils: PropTypes.arrayOf(PropTypes.instanceOf(DtoInstallation)),
    step: PropTypes.arrayOf(PropTypes.instanceOf(DtoInstallation)),
    boreholes: PropTypes.arrayOf(PropTypes.instanceOf(DtoInstallation)),
    cavities: PropTypes.arrayOf(PropTypes.instanceOf(DtoInstallation)),
    captures: PropTypes.arrayOf(PropTypes.instanceOf(DtoInstallation)),
    surfaceQualitometer: PropTypes.arrayOf(PropTypes.instanceOf(DtoQualitometerLight)),
    qualitometers: PropTypes.arrayOf(PropTypes.instanceOf(DtoQualitometerLight)),
    productionUnits: PropTypes.arrayOf(PropTypes.instanceOf(DtoProductionUnit)),
    installations: PropTypes.arrayOf(PropTypes.instanceOf(DtoInstallation)),
    fetchQualitometers: PropTypes.func,
    fetchPiezometersLight: PropTypes.func,
    fetchHydrometricStations: PropTypes.func,
    fetchInstallations: PropTypes.func,
    fetchPluviometers: PropTypes.func,
    fetchProductionUnits: PropTypes.func,
    fetchPiezometerChartLandmarks: PropTypes.func,
    userbookmarks: PropTypes.arrayOf(PropTypes.instanceOf(DtoUserBookmark)),
    fetchBookmarks: PropTypes.func,
    loadStationData: PropTypes.func,
    accountUser: PropTypes.instanceOf(User),
    installationsSituation: PropTypes.arrayOf(PropTypes.instanceOf(DtoInstallationSituation)),
    fetchGlobalParameters: PropTypes.func,
    fetchUserSettings: PropTypes.func,
    globalParameters: PropTypes.arrayOf(PropTypes.instanceOf(GlobalParametersDto)),
    userSettings: PropTypes.arrayOf(PropTypes.instanceOf(SettingDto)),
    filters: PropTypes.arrayOf(PropTypes.instanceOf(DtoFilter)),
    fetchFilters: PropTypes.func,
    fetchFilterResults: PropTypes.func,
    fetchInstallationAssociatedEvents: PropTypes.func,
    fetchFlowObstructions: PropTypes.func,
    flowObstructions: PropTypes.arrayOf(PropTypes.instanceOf(DtoFlowObstruction)),
    sandreCodes: PropTypes.arrayOf(PropTypes.instanceOf(DtoSandreCode)),
    fetchSandreCodes: PropTypes.func,
    fetchAllCMSModels: PropTypes.func,
    cmsModels: PropTypes.arrayOf(PropTypes.instanceOf(DtoCmsModel)),
    export: PropTypes.func,
    receiveTempFormattedSites: PropTypes.func,
    tempFormattedSites: PropTypes.arrayOf(PropTypes.shape({})),
    updateGlobalParameter: PropTypes.func,
    success: PropTypes.func,
    saveSettings: PropTypes.func,
}

const mapStateToProps = store => ({
    hydroSituationLastMeasures: store.HydrometryReducer.hydroSituationLastMeasures,
    hydrometryThresholds: store.HydrometryReducer.hydrometryThresholds,
    pluviometerAllThresholds: store.PluviometryReducer.pluviometerAllThresholds,
    pluvioSituationLastMeasures: store.PluviometryReducer.pluvioSituationLastMeasures,
    allPiezometerThresholds: store.PiezometerStationReducer.allPiezometerThresholds,
    piezoSituationLastMeasures: store.PiezometryReducer.piezoSituationLastMeasures,
    monthlyMeasures: store.PiezometerStationReducer.measures,
    sites: [
        ...store.PiezometryReducer.piezometersWithCampaignsAndEvents,
        ...store.HydrometryReducer.hydrometricStations,
        ...store.PluviometryReducer.pluviometersWithCampaignsAndEvents,
        ...store.InstallationReducer.installationsLight,
    ],
    arrests: store.HomeReducer.arrests,
    citiesIndex: store.CityReducer.citiesIndex,
    cmsEvents: store.EventsReducer.cmsEvents,
    cmsCategories: store.EventsReducer.cmsCategories,
    applicationSettings: store.AdministrationReducer.applicationSettings,
    piezometers: store.PiezometryReducer.piezometersLight,
    hydrometricStations: store.HydrometryReducer.hydrometricStations,
    piezometersLight: store.PiezometryReducer.piezometersLight,
    pluviometers: store.PluviometryReducer.pluviometers,
    flowObstruction: store.InstallationReducer.installationsLight.filter(i => i.installationType === INSTALLATION_TYPE.FLOW_OBSTRUCTION),
    sampleWorks: store.InstallationReducer.installationsLight.filter(i => i.installationType === INSTALLATION_TYPE.SAMPLE_WORK),
    industrialSites: store.InstallationReducer.installationsLight.filter(i => i.installationType === INSTALLATION_TYPE.INDUSTRIAL_SITE),
    pollutedSoils: store.InstallationReducer.installationsLight.filter(i => i.installationType === INSTALLATION_TYPE.POLLUTED_SOIL),
    step: store.InstallationReducer.installationsLight.filter(i => i.installationType === INSTALLATION_TYPE.STEP),
    boreholes: store.InstallationReducer.installationsLight.filter(i => i.installationType === INSTALLATION_TYPE.BOREHOLE),
    cavities: store.InstallationReducer.installationsLight.filter(i => i.installationType === INSTALLATION_TYPE.CAVITY),
    captures: store.InstallationReducer.installationsLight.filter(i => i.installationType === INSTALLATION_TYPE.CAPTURE),
    surfaceQualitometer: store.QualityReducer.qualitometersLight.filter(q => q.stationType !== '0'),
    qualitometers: store.QualityReducer.qualitometersLight,
    installations: store.InstallationReducer.installationsLight,
    productionUnits: store.ProductionUnitReducer.productionUnits,
    piezometerChartLandmarks: store.PiezometryReducer.piezometerChartLandmarks,
    userBookmarks: store.UserReducer.userBookmarks,
    hydrometryDataTypes: store.HydrometryReducer.hydrometryDataTypes,
    piezometryDataTypes: store.PiezometryReducer.piezometryDataTypes,
    pluviometryDataTypes: store.PluviometryReducer.pluviometryDataTypes,
    installationDataTypes: store.InstallationReducer.installationDataTypes,
    accountUser: store.AccountReducer.accountUser,
    installationsSituation: store.InstallationReducer.installationsSituation,
    globalParameters: store.AdministrationReducer.globalParameters,
    userSettings: store.AccountReducer.accountUserSettings,
    filters: store.StationReducer.filters,
    flowObstructions: store.InstallationReducer.flowObstructions,
    sandreCodes: store.ReferencialReducer.sandreCodes,
    cmsModels: store.EventsReducer.cmsModels,
    tempFormattedSites: store.AlertReducer.tempFormattedSites,
})

const mapDispatchToProps = {
    loadSituationDashboard: AlertAction.loadSituationDashboard,
    loadStationData: AlertAction.loadStationData,
    waitStart: WaitAction.waitStart,
    waitStop: WaitAction.waitStop,
    setTitle: HomeAction.setTitle,
    fetchArrests: HomeAction.fetchArrests,
    fetchCMSWithoutWP: CmsAction.fetchCMSWithoutWP,
    navigateTo: push,
    fetchQualitometers: QualityAction.fetchQualitometersLight,
    fetchPiezometersLight: PiezometryAction.fetchPiezometersLight,
    fetchHydrometricStations: HydrometryAction.fetchHydrometricStations,
    fetchInstallations: InstallationAction.fetchInstallations,
    fetchPluviometers: PluviometryAction.fetchPluviometers(),
    fetchProductionUnits: ProductionUnitAction.fetchProductionUnits,
    fetchPiezometerChartLandmarks: PiezometryAction.fetchPiezometerChartLandmarks,
    fetchBookmarks: UserAction.fetchBookmarks,
    fetchDataTypes: StationAction.fetchDataTypes,
    fetchHydrometryDataTypes: HydrometryAction.fetchHydrometryDataTypes,
    fetchPiezometryDataTypes: PiezometryAction.fetchPiezometryDataTypes,
    fetchPluviometryDataTypes: PluviometryAction.fetchPluviometryDataTypes,
    fetchGlobalParameters: AdministrationAction.fetchGlobalParameters,
    fetchUserSettings: AccountAction.fetchAccountUserSettings,
    fetchFilters: StationAction.fetchFilters,
    fetchFilterResults: StationAction.fetchFilterResults,
    fetchInstallationAssociatedEvents: InstallationAction.fetchInstallationAssociatedEvents,
    fetchFlowObstructions: InstallationAction.fetchFlowObstructions,
    fetchSandreCodes: ReferencialAction.fetchSandreCodes,
    fetchAllCMSModels: CmsAction.fetchAllCMSModels,
    receiveTempFormattedSites: AlertAction.receiveTempFormattedSites,
    export: ExportAction.export,
    updateGlobalParameter: AdministrationAction.updateGlobalParameter,
    success: ToastrAction.success,
    saveSettings: AccountAction.updateSettings,
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(AlertMapApp)