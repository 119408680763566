import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import NumberField from '../../../../components/forms/NumberField'
import Select from '../../../../components/forms/Select'
import ContactDto from '../../../../referencial/components/contact/dto/ContactDto'
import ContributorDto from '../../../../referencial/components/contributor/dto/ContributorDto'
import { SANDRE } from '../../../../referencial/constants/ReferencialConstants'
import DtoSandreCode from '../../../../referencial/dto/DtoSandreCode'
import { arrayOf, getSandreList } from '../../../../utils/StoreUtils'
import DtoLithology from '../../../dto/DtoLithology'

class LithologyModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            lithology: { ...props.lithology, idStation: props.id, order: 1 },
        }
    }

    componentDidUpdate(prevProps) {
        if (!prevProps.popupIsOpen && this.props.popupIsOpen) {
            $('#descriptionModal').on('click', this.onSave)
        }
    }

    onSave = () => {
        this.props.saveResult(new DtoLithology(this.state.lithology))
        $('.modal').modal('close')
    }

    onChangeElement = (changes) => this.setState({ lithology: { ...this.state.lithology, ...changes } })

    render() {
        const { lithology } = this.state
        return (
            <div>
                <div className='row no-margin'>
                    <Select col={ 6 } options={ getSandreList(this.props.sandreCodes, SANDRE.LITHOLOGY) } label={ i18n.name }
                        value={ lithology.lithologyCode } onChange={ v => this.onChangeElement({ lithologyCode: v }) }
                    />
                    <NumberField
                        col={ 6 }
                        value={ lithology.thickness }
                        title={ i18n.thickness }
                        onChange={ v => this.onChangeElement({ thickness: v }) }
                        floatValue
                    />
                </div>
            </div>
        )
    }
}

LithologyModal.propTypes = {
    lithology: PropTypes.instanceOf(DtoLithology),
    id: PropTypes.number,
    sandreCodes: PropTypes.arrayOf(PropTypes.instanceOf(DtoSandreCode)),
    popupIsOpen: PropTypes.bool,
    saveResult: PropTypes.func,
    contributors: arrayOf(ContributorDto),
    contacts: arrayOf(ContactDto),
}


const mapStateToProps = store => ({
    sandreCodes: store.ReferencialReducer.sandreCodes,
    popupIsOpen: store.SieauReducer.popupIsOpen,
    contributors: store.ContributorReducer.contributors,
    contacts: store.ContactReducer.contacts,
})

LithologyModal.defaultProps = {
    lithology: {},
}

export default connect(mapStateToProps)(LithologyModal)
