import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import CentralTypeDto from '../central/dto/CentralTypeDto'
import { removeNullKeys } from '../../../utils/StoreUtils'
import Icon from '../../../components/icon/Icon'
import Input from '../../../components/forms/Input'
import i18n from 'simple-react-i18n'
import ContributorDto from '../../../referencial/components/contributor/dto/ContributorDto'
import Select from '../../../components/forms/Select'
import Card from '../../../components/card/Card'
import PowerSupplyTypeDto from '../powerSupply/dto/PowerSupplyTypeDto'
import TelecomTypeDto from '../telecom/dto/TelecomTypeDto'
import DtoSandreCode from '../../../referencial/dto/DtoSandreCode'
import {
    CENTRAL,
    EQUIPMENT,
    MAT_CENTRAL,
    MAT_DIVERS,
    MAT_EQUIPMENT,
    MAT_POWER_SUPPLY,
    MAT_SENSOR,
    MAT_SIM,
    MAT_TELECOM,
    POWER_SUPPLY,
    SENSOR,
    SIM,
    TELECOM,
    VARIOUS,
} from '../../constants/MaterielConstants'
import ActionComponent from '../../../components/ActionComponent'
import CentralAction from '../central/actions/CentralAction'
import AppStore from '../../../store/AppStore'
import PowerSupplyAction from '../powerSupply/actions/PowerSupplyAction'
import SensorAction from '../sensor/actions/SensorAction'
import TelecomAction from '../telecom/actions/TelecomAction'
import SimAction from '../sim/actions/SimAction'
import VariousMaterielAction from '../variousMateriel/actions/VariousMaterielAction'
import SieauAction from '../../../components/sieau/SieauAction'
import DtoSensorType from '../sensor/dto/DtoSensorType'
import DtoSimType from '../sim/dto/DtoSimType'
import DtoVariousMaterielType from '../variousMateriel/dto/DtoVariousMaterielType'
import EquipmentAction from '../equipment/actions/EquipmentAction'
import EquipmentTypeDto from '../equipment/dto/EquipmentTypeDto'
import MaterielTypeDto from '../../dto/MaterielTypeDto'
import CentralTypePanel from '../central/components/CentralTypePanel'
import PowerSupplyTypePanel from '../powerSupply/components/PowerSupplyTypePanel'
import SensorTypePanel from '../sensor/components/SensorTypePanel'
import TelecomTypePanel from '../telecom/components/TelecomTypePanel'
import EquipmentTypePanel from '../equipment/components/EquipmentTypePanel'
import { Dialog, DialogContent, DialogTitle } from '@mui/material'
import { getMaterielIconValue } from '../../../utils/MaterielUtils'
import NumberField from '../../../components/forms/NumberField'
import { isEqual, orderBy, uniqBy } from 'lodash'
import { hasValue } from '../../../utils/NumberUtil'
import SimpleDatePicker from 'components/forms/SimpleDatePicker'
import ReferencialSandreCodeDto from 'referencial/dto/ReferencialSandreCodeDto'

class MaterielTypeForm extends ActionComponent {
    constructor(props) {
        super(props)
        this.state = {
            materielType: props.materielType,
            isNew: false,
        }
    }

    componentDidMount = () => {
        this.setActions({
            save: this.onSaveType,
            new: this.setNewTypeModal,
            delete: this.onDeleteType,
        })
    }

    componentDidUpdate = prevProps => {
        if (!isEqual(prevProps.materielType, this.props.materielType)) {
            this.setState({ materielType: this.props.materielType, isNew: false })
        }
    }

    getNewType = type => {
        switch (type) {
            case CENTRAL: return new CentralTypeDto({})
            case SENSOR: return new DtoSensorType({})
            case EQUIPMENT: return new EquipmentTypeDto({})
            case SIM: return new DtoSimType({})
            case TELECOM: return new TelecomTypeDto({})
            case POWER_SUPPLY: return new PowerSupplyTypeDto({})
            case VARIOUS: return new DtoVariousMaterielType({})
            default: return undefined
        }
    }

    setNewTypeModal = () => {
        const elements = this.props.materielTypes.filter(({ id, code }) => id !== 8 && code).map(({ code }) => this.getNewType(code)).map(t => (
            <div className='col s4' onClick={() => {
                this.setState({ materielType: t, isNew: true })
                $('.modal').modal('close')
            }}
            >
                <div className='center-align'><Icon icon={getMaterielIconValue(t.materielCategory)} className='medium' /></div>
                <div className='center-align'>{t.materielLabel}</div>
            </div>
        ))
        const popup = {
            id: 'bssCodeModal',
            header: i18n.materielType,
            content: (
                <div className='row no-margin'>
                    {elements}
                </div>
            ),
        }
        AppStore.dispatch(SieauAction.setPopup(popup))
    }

    onSaveType = () => {
        const {
            materielType,
        } = this.state
        if (materielType) {
            const cb = matType => this.setState({ materielType: matType, isNew: false })
            switch (materielType.materielCategory) {
                case MAT_CENTRAL:
                    if (this.state.isNew) {
                        this.props.addCentralTypes(materielType, cb)
                    } else {
                        this.props.updateCentralTypes(materielType.materielType, materielType)
                    }
                    break
                case MAT_POWER_SUPPLY:
                    if (this.state.isNew) {
                        this.props.addPowerSupplyTypes(materielType, cb)
                    } else {
                        this.props.updatePowerSupplyTypes(materielType.materielType, materielType)
                    }
                    break
                case MAT_SENSOR:
                    if (this.state.isNew) {
                        this.props.addSensorTypes(materielType, cb)
                    } else {
                        this.props.updateSensorTypes(materielType.materielType, materielType)
                    }
                    break
                case MAT_TELECOM:
                    if (this.state.isNew) {
                        this.props.addTelecomTypes(materielType, cb)
                    } else {
                        this.props.updateTelecomTypes(materielType.materielType, materielType)
                    }
                    break
                case MAT_SIM:
                    if (this.state.isNew) {
                        this.props.addSimTypes(materielType, cb)
                    } else {
                        this.props.updateSimTypes(materielType.materielType, materielType)
                    }
                    break
                case MAT_DIVERS:
                    if (this.state.isNew) {
                        this.props.addVariousMaterielTypes(materielType, cb)
                    } else {
                        this.props.updateVariousMaterielTypes(materielType.materielType, materielType)
                    }
                    break
                case MAT_EQUIPMENT:
                    const formatType = {
                        ...materielType,
                        centralType: materielType.centralType.filter(t => hasValue(t)),
                        sensorType: materielType.sensorType.filter(t => hasValue(t)),
                        simType: materielType.simType.filter(t => hasValue(t)),
                        powerSupplyType: materielType.powerSupplyType.filter(t => hasValue(t)),
                        telecomType: materielType.telecomType.filter(t => hasValue(t)),
                        variousMaterielType: materielType.variousMaterielType.filter(t => hasValue(t)),
                    }
                    if (this.state.isNew) {
                        this.props.addEquipmentTypes(formatType, cb)
                    } else {
                        this.props.updateEquipmentTypes(formatType.materielType, formatType)
                    }
                    break
                default:
                    break
            }
        }
    }

    onDeleteType = () => {
        const {
            materielType,
        } = this.state
        if (materielType) {
            const cb = () => this.setState({ materielType: null })
            switch (materielType.materielCategory) {
                case MAT_CENTRAL:
                    this.props.deleteCentralType(materielType.materielType, cb)
                    break
                case MAT_POWER_SUPPLY:
                    this.props.deletePowerSupplyType(materielType.materielType, cb)
                    break
                case MAT_SENSOR:
                    this.props.deleteSensorType(materielType.materielType, cb)
                    break
                case MAT_TELECOM:
                    this.props.deleteTelecomType(materielType.materielType, cb)
                    break
                case MAT_SIM:
                    this.props.deleteSimType(materielType.materielType, cb)
                    break
                case MAT_DIVERS:
                    this.props.deleteVariousMaterielType(materielType.materielType, cb)
                    break
                case MAT_EQUIPMENT:
                    this.props.deleteEquipmentType(materielType.materielType, cb)
                    break
                default:
                    break
            }
        }
    }

    onChangeType = changes => this.setState({ materielType: removeNullKeys({ ...this.state.materielType, ...changes }) })

    getMaterielForm = (matType) => {
        const { contributors, referencialSandreCodes, sandreCodes } = this.props
        switch (matType.materielCategory) {
            case MAT_CENTRAL:
                return (
                    <CentralTypePanel
                        materielType={matType}
                        onChange={this.onChangeType}
                    />
                )
            case MAT_POWER_SUPPLY:
                return (
                    <PowerSupplyTypePanel
                        materielType={matType}
                        onChange={this.onChangeType}
                    />
                )
            case MAT_SENSOR:
                return (
                    <SensorTypePanel
                        materielType={matType}
                        onChange={this.onChangeType}
                    />
                )
            case MAT_TELECOM:
                return (
                    <TelecomTypePanel
                        materielType={matType}
                        onChange={this.onChangeType}
                    />
                )
            case MAT_EQUIPMENT:
                return (
                    <EquipmentTypePanel
                        materielType={matType}
                        onChange={this.onChangeType}
                        openPopin={() => this.setState({ isOpen: true })}
                    />
                )
            case MAT_DIVERS:
                return (
                    <>
                        <div className='row no-margin'>
                            <Select
                                col={6}
                                options={contributors}
                                label={i18n.provider}
                                value={matType.provider}
                                onChange={v => this.onChangeType({ provider: v })}
                                keyValue='id'
                                keyLabel='mnemonique'
                            />
                            <Select
                                col={6}
                                options={contributors}
                                label={i18n.manufacturer}
                                value={matType.manufacturerId}
                                onChange={v => this.onChangeType({ manufacturerId: v })}
                                keyValue='id'
                                keyLabel='mnemonique'
                            />
                        </div>
                        <div className='row no-margin'>
                            <Select
                                col={6}
                                label={i18n.sandreNomenclature}
                                value={matType.refSandre}
                                options={orderBy(uniqBy(referencialSandreCodes.filter(rsf => rsf.code), 'field'), 'field')}
                                onChange={v => this.onChangeType({ refSandre: v })}
                                nullLabel='&nbsp;'
                                keyValue='field'
                                keyLabel='labelWithCode'
                            />
                            <Select
                                col={6}
                                options={matType.refSandre ? orderBy(sandreCodes.filter(sandreCode => sandreCode.field === matType.refSandre), 'reference') : []}
                                label={i18n.sandreCode}
                                value={matType.sandreCode}
                                onChange={v => this.onChangeType({ sandreCode: v })}
                                keyValue='code'
                                keyLabel='labelWithReference'
                                noSort
                            />
                        </div>
                    </>
                )
            default:
                return (
                    <div className='row no-margin'>
                        <Select
                            col={6}
                            options={contributors}
                            label={i18n.provider}
                            value={matType.provider}
                            onChange={v => this.onChangeType({ provider: v })}
                            keyValue='id'
                            keyLabel='mnemonique'
                        />
                        <Select
                            col={6}
                            options={contributors}
                            label={i18n.manufacturer}
                            value={matType.manufacturerId}
                            onChange={v => this.onChangeType({ manufacturerId: v })}
                            keyValue='id'
                            keyLabel='mnemonique'
                        />
                    </div>
                )
        }
    }

    render() {
        const { materielType, isOpen } = this.state
        if (materielType) {
            const {
                materielTypes,
            } = this.props
            const matType = ['central', 'powerSupply', 'sensor', 'sim', 'telecom', 'variousMateriel']
            return (
                <Card title={(
                    <div className='row no-margin valign-wrapper'>
                        <Icon icon={getMaterielIconValue(materielType.materielCategory)} className='small' />
                        <div className='bold'>{materielType.materielLabel}</div>
                    </div>
                )}
                >
                    <div className='row no-margin padding-top-1'>
                        <Input
                            col={8}
                            title={i18n.type}
                            value={materielType.name}
                            onChange={v => this.onChangeType({ name: v })}
                        />
                        <SimpleDatePicker
                            col={4}
                            label={i18n.startDate}
                            value={materielType.startDate}
                            id='matTypeStartDate'
                            onChange={v => this.onChangeType({ startDate: v })}
                            max={materielType.endDate}
                        />
                    </div>
                    <div className='row no-margin'>
                        <Input
                            col={8}
                            title={i18n.comment}
                            value={materielType.comment}
                            onChange={v => this.onChangeType({ comment: v })}
                        />
                        <SimpleDatePicker
                            col={4}
                            label={i18n.endDate}
                            value={materielType.endDate}
                            id='matTypeEndDate'
                            onChange={v => this.onChangeType({ endDate: v })}
                            min={materielType.startDate}
                        />
                    </div>
                    {
                        materielType.materielCategory !== MAT_SIM && (
                            <div className='row no-margin'>
                                <NumberField
                                    col={4}
                                    title={i18n.warranty}
                                    value={materielType.warrantyTime}
                                    onChange={v => this.onChangeType({ warrantyTime: v })}
                                />
                            </div>
                        )
                    }
                    <Card title={i18n.defaultValue}>
                        <div className='padding-top-1' />
                        {this.getMaterielForm(materielType)}
                    </Card>
                    <Dialog
                        onClose={() => this.setState({ isOpen: false })}
                        fullWidth
                        maxWidth='md'
                        open={isOpen}
                    >
                        <DialogTitle>{i18n.pleaseChooseMaterielType}</DialogTitle>
                        <DialogContent>
                            <div style={{ padding: '20px' }}>
                                {matType.map(mat => !!materielTypes.find(({ link }) => link === mat) && (
                                    <div className='padding-bottom-2'>
                                        <div className='col s12 chip blue white-text flex justify-center align-center clickable'
                                            onClick={() => this.setState({
                                                materielType: {
                                                    ...materielType,
                                                    [`${mat}Type`]: [
                                                        ...materielType[`${mat}Type`],
                                                        undefined,
                                                    ],
                                                },
                                                isOpen: false,
                                            })}
                                        >
                                            <Icon icon={getMaterielIconValue(mat)} />
                                            <h5 className='no-margin align-center' style={{ paddingLeft: '1%' }}>
                                                {i18n[mat]}
                                            </h5>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </DialogContent>
                    </Dialog>
                </Card>
            )
        }
        return null
    }
}

MaterielTypeForm.propTypes = {
    materielType: PropTypes.oneOfType([
        PropTypes.instanceOf(CentralTypeDto),
        PropTypes.instanceOf(DtoSensorType),
        PropTypes.instanceOf(PowerSupplyTypeDto),
        PropTypes.instanceOf(TelecomTypeDto),
        PropTypes.instanceOf(DtoSimType),
        PropTypes.instanceOf(DtoVariousMaterielType),
        PropTypes.instanceOf(EquipmentTypeDto),
    ]),
    materielTypes: PropTypes.arrayOf(PropTypes.instanceOf(MaterielTypeDto)),
    contributors: PropTypes.arrayOf(PropTypes.instanceOf(ContributorDto)),
    sandreCodes: PropTypes.arrayOf(PropTypes.instanceOf(DtoSandreCode)),
    referencialSandreCodes: PropTypes.arrayOf(PropTypes.instanceOf(ReferencialSandreCodeDto)),
    deleteCentralType: PropTypes.func,
    deletePowerSupplyType: PropTypes.func,
    deleteSensorType: PropTypes.func,
    deleteTelecomType: PropTypes.func,
    deleteSimType: PropTypes.func,
    deleteVariousMaterielType: PropTypes.func,
    deleteEquipmentType: PropTypes.func,
    addCentralTypes: PropTypes.func,
    updateCentralTypes: PropTypes.func,
    addPowerSupplyTypes: PropTypes.func,
    updatePowerSupplyTypes: PropTypes.func,
    addSensorTypes: PropTypes.func,
    updateSensorTypes: PropTypes.func,
    addTelecomTypes: PropTypes.func,
    updateTelecomTypes: PropTypes.func,
    addSimTypes: PropTypes.func,
    updateSimTypes: PropTypes.func,
    addVariousMaterielTypes: PropTypes.func,
    updateVariousMaterielTypes: PropTypes.func,
    addEquipmentTypes: PropTypes.func,
    updateEquipmentTypes: PropTypes.func,
}

const mapStateToProps = store => ({
    materielTypes: store.MaterielReducer.materielTypes,
    contributors: store.ContributorReducer.contributors,
    sandreCodes: store.ReferencialReducer.sandreCodes,
    referencialSandreCodes: store.ReferencialReducer.referencialSandreCodes,
})

const mapDispatchToProps = {
    deleteCentralType: CentralAction.deleteCentralType,
    deletePowerSupplyType: PowerSupplyAction.deletePowerSupplyType,
    deleteSensorType: SensorAction.deleteSensorType,
    deleteTelecomType: TelecomAction.deleteTelecomType,
    deleteSimType: SimAction.deleteSimType,
    deleteVariousMaterielType: VariousMaterielAction.deleteVariousMaterielType,
    deleteEquipmentType: EquipmentAction.deleteEquipmentType,
    addCentralTypes: CentralAction.addCentralTypes,
    updateCentralTypes: CentralAction.updateCentralTypes,
    addPowerSupplyTypes: PowerSupplyAction.addPowerSupplyTypes,
    updatePowerSupplyTypes: PowerSupplyAction.updatePowerSupplyTypes,
    addSensorTypes: SensorAction.addSensorTypes,
    updateSensorTypes: SensorAction.updateSensorTypes,
    addTelecomTypes: TelecomAction.addTelecomTypes,
    updateTelecomTypes: TelecomAction.updateTelecomTypes,
    addSimTypes: SimAction.addSimTypes,
    updateSimTypes: SimAction.updateSimTypes,
    addVariousMaterielTypes: VariousMaterielAction.addVariousMaterielTypes,
    updateVariousMaterielTypes: VariousMaterielAction.updateVariousMaterielTypes,
    addEquipmentTypes: EquipmentAction.addEquipmentTypes,
    updateEquipmentTypes: EquipmentAction.updateEquipmentTypes,
}

export default connect(mapStateToProps, mapDispatchToProps)(MaterielTypeForm)