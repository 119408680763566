export default class DtoEnvironmentalCondition {
    constructor(obj) {
        this.idQualitometer = obj.idQualitometer // Double
        this.idOperation = obj.idOperation // Double
        this.idCondition = obj.idCondition // Double
        this.date = obj.date // Option[DateTime]
        this.hour = obj.hour // Option[DateTime]
        this.comment = obj.comment // Option[String]
        this.qualification = obj.qualification // Option[Double]
        this.status = obj.status // Option[Double]
        this.method = obj.method // Option[Double]
        this.parameterCode = obj.parameterCode // Option[Double]
        this.unitCode = obj.unitCode // Option[String]
        this.measure = obj.measure // Option[Double]
    }
}