/* eslint-disable camelcase */
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid2 } from '@mui/material'
import NumberField from 'components/forms/NumberField'
import SimpleSelect from 'components/forms/SimpleSelect'
import Icon from 'components/icon/Icon'
import { push } from '@lagunovsky/redux-react-router'
import DtoExploitationExportFull from 'exploitations/dto/DtoExploitationExportFull'
import DtoExploitationService from 'exploitations/dto/DtoExploitationService'
import { compact, isEqual, orderBy, sortBy, uniqBy } from 'lodash'
import VariousMaterielDto from 'materiel/components/variousMateriel/dto/VariousMaterielDto'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import SelectContactModal from 'survey/components/declaration/modals/SelectContactModal'
import ToastrAction from 'toastr/actions/ToastrAction'
import { getDate } from 'utils/DateUtil'
import { sieauTooltip } from 'utils/FormUtils'
import { getLabel } from 'utils/StoreUtils'
import WaitAction from 'wait/WaitAction'
import AgriAction from '../../agriAdministration/actions/AgriAction'
import ActionComponent from '../../components/ActionComponent'
import Card from '../../components/card/Card'
import Checkbox from '../../components/forms/Checkbox'
import Input from '../../components/forms/Input'
import Select from '../../components/forms/Select'
import SimpleDatePicker from '../../components/forms/SimpleDatePicker'
import Textarea from '../../components/forms/Textarea'
import CartographyPanel from '../../components/map/CartographyPanel'
import ProgressCard from 'components/card/ProgressCard'
import Row from '../../components/react/Row'
import SieauAction from '../../components/sieau/SieauAction'
import UpdatePanel from '../../components/updatePanel/UpdatePanel'
import HomeAction from '../../home/actions/HomeAction'
import InstallationAction from '../../installation/actions/InstallationAction'
import DtoInstallationWithGeoItem from '../../installation/components/installations/dto/DtoInstallationWithGeoItem'
import DtoInstallation from '../../installation/dto/installation/DtoInstallation'
import ContactAction from '../../referencial/components/contact/actions/ContactAction'
import ContactItem from '../../referencial/components/contact/dto/ContactItem'
import ContributorAction from '../../referencial/components/contributor/actions/ContributorAction'
import ReferencialCodificationPanel from '../../referencial/components/contributor/components/ReferencialCodificationPanel'
import ContributorDto from '../../referencial/components/contributor/dto/ContributorDto'
import { nbPerPageLabel, nbPerPageLabelShort, SANDRE } from '../../referencial/constants/ReferencialConstants'
import DtoSandreCode from '../../referencial/dto/DtoSandreCode'
import AppStore from '../../store/AppStore'
import DtoDeclaration from '../../survey/dto/DtoDeclaration'
import DtoSurvey from '../../survey/dto/DtoSurvey'
import { getExport } from '../../utils/linkUtils'
import { hasValue } from '../../utils/NumberUtil'
import { getLogin, getUser } from '../../utils/SettingUtils'
import { formatMilliers, formatSiret, getI18nTitleData, getSandreLabel } from '../../utils/StringUtil'
import DtoExploitation from '../dto/DtoExploitation'
import DeclarationStatsCard from './cards/DeclarationStatsCard'
import IntervenantCard from './cards/IntervenantCard'
import ContactModal from './modal/ContactModal'
import InstallationStepperModal from './modal/InstallationStepperModal'
import IntervenantModal from './modal/IntervenantModal'
import ModalWarningDeleteExploit from './modal/ModalWarningDeleteExploit'
import NewLandModal from './modal/NewLandModal'
import SelectPointsPrelModal from './modal/SelectPointsPrelModal'
import SelectUsersModal from './modal/SelectUsersModal'
import ExploitationServicesPanel from './panels/ExploitationServicesPanel'
import UserAction from 'administration/components/user/actions/UserAction'
import DtoUserBookmark from 'administration/components/user/dto/DtoUserBookmark'
import DtoUserLight from 'administration/components/user/dto/DtoUserLight'
import DtoManagementUnit from 'referencial/components/managementUnit/dto/DtoManagementUnit'
import WatershedDto from 'referencial/components/watershed/dto/WatershedDto'
import { SAMPLE_TYPES } from 'agriAdministration/constants/AgriConstants'
import DtoAquifer from 'referencial/components/aquifers/dto/DtoAquifer'
import { CardTable } from 'components/datatable/NewTable'
import { darkBlue } from 'utils/constants/ColorTheme'

const headerPoints = ['nullValue', 'nullValue2', 'code', 'name', 'city', 'uge', 'codeParcelle', 'lieuDit', 'status', 'begin', 'end']
const headerUsers = ['login', 'gestCheckbox', 'adminCheckbox']
const headerSurveys = ['survey', 'year', 'status', 'realVolume', 'nbPts']

class ExploitationDashboardApp extends ActionComponent {
    constructor(props) {
        super(props)
        this.state = {
            idExploitation: parseInt(props.params.id),
            dataLoaded: false,
            pointsLoaded: false,
            openModalSelectUsers: false,
            openModalIntervenant: false,
            preleveurInitial: {},
            preleveur: {},
            openModalSelectContributors: false,
            openModalContact: false,
            selectedContact: null,
            contactsExploitationInitial: [],
            contactsExploitation: [],
            openModalSelectPointsPrel: false,
            openModalPointPrel: false,
            selectedPoint: null,
            exploitation: {},
            exploitationPoints: [],
            readMode: true,
            hasDeclarationInProgress: false,
            openModalWarningSave: false,
            showPointHisto: false,
            openDeleteWarningModal: false,
            eventsToCreate: [],
            mapFullScreen: false,
            modalData: {},
        }
    }

    componentDidMount() {
        const { idExploitation } = this.state
        const { services, exploitationsExportFullData } = this.props
        this.props.fetchExploitation(idExploitation).then(() => {
            const { exploitation } = this.props
            if (exploitation.idExploitation !== idExploitation) {
                this.props.push('/dossiers')
            } else {
                this.props.fetchDeclarationsByExploitationId(idExploitation).then(() => {
                    const { contacts, installationsWithGeo } = this.props
                    if (!installationsWithGeo.length) {
                        this.props.fetchInstallationsWithGeo().then(() => {
                            this.setPointsPrel(exploitation)
                        })
                    } else {
                        this.setPointsPrel(exploitation)
                    }
                    if (!contacts.length) {
                        this.props.fetchContacts().then(() => {
                            this.setExploitation(exploitation, true)
                        })
                    } else {
                        this.setExploitation(exploitation, true)
                    }
                })
            }
            this.setReadOnlyMode()
        })
        this.props.fetchDeclarationByExploitationId(idExploitation).then((declaration) => {
            if (declaration && declaration.statusCode !== 4) {
                this.props.toastrWarning('Les modifications apportées durant l\'enquête en cours, qu\'elles concernent les points de prélèvement, les consommations réelles (année écoulée), les prévisions (année à venir), les équipements de pompage (EP) et/ou les dispositifs de comptage (DC), ne sont pas affichées dans les dossiers, tant que l\'enquête n\'est pas clôturée / validée.', '', { timeOut: 10000, preventDuplicates: true })
                this.setState({ hasDeclarationInProgress: true })
            }
        })
        if (!services.length) {
            this.props.fetchServices()
        }
        if (!exploitationsExportFullData.length) {
            this.props.fetchExploitationsExportFullData()
        }
    }

    setExploitation = (exploitation, init = false) => {
        const { contacts } = this.props
        this.props.fetchContributor(exploitation.operatorCode).then(() => {
            const { contributor: preleveur } = this.props
            const legalRepresentative = preleveur && preleveur.structureType !== 1 ? contacts.find((c) => c.id === preleveur.legalRepresentative) : null
            const contactsExploitation = compact(exploitation.link_contributors.map((c) => {
                const contributorFind = contacts.find(
                    (contact) => contact.code === c.contactCode,
                )
                if (contributorFind) {
                    return {
                        ...contributorFind,
                        contributorType: c.contributorType,
                    }
                }
                return null
            }))
            const allContacts = legalRepresentative && !contactsExploitation.find((c) => c.contributorType === -1) ? compact([
                {
                    ...legalRepresentative,
                    contributorType: -1,
                },
                ...contactsExploitation,
            ]) : contactsExploitation
            this.props.setTitle([
                {
                    title: i18n.folders,
                    href: 'dossiers',
                },
                {
                    title: `${preleveur.name} ${exploitation.codification ? `[${exploitation.codification}]` : ''}`,
                    href: `dossiers/${exploitation.idExploitation}/dashboard`,
                },
            ])
            this.setState(({ contactsExploitationInitial, preleveurInitial }) => ({
                dataLoaded: true,
                preleveur,
                contactsExploitation: allContacts,
                exploitation,
                contactsExploitationInitial: init ? allContacts : contactsExploitationInitial,
                preleveurInitial: init ? preleveur : preleveurInitial,
            }))
            this.props.waitStop()
        })
    }

    getMarker = (stateCode) => {
        switch (stateCode) {
            default: case 1:
                return { color: 'green', marker: 'pictures/markers/map_qualite_green.png' }
            case 2:
                return { color: 'yellow', marker: 'pictures/markers/map_qualite_yellow.png' }
            case 3:
                return { color: 'red', marker: 'pictures/markers/map_qualite_red.png' }
            case 4:
                return { color: 'white', marker: 'pictures/markers/map_qualite_white.png' }
        }
    }

    setPointsPrel = (exploitation) => {
        const { installationsWithGeo, citiesIndex } = this.props
        const exploitationPoints = compact(exploitation.link_samplings.map((linkPoint) => {
            const point = installationsWithGeo.find((i) => i.id === linkPoint.idInstallation)
            const histo = linkPoint.endDate && linkPoint.endDate < moment().valueOf()
            const city = citiesIndex[point.townCode] || {}
            const { color, marker } = this.getMarker(histo ? 4 : linkPoint.stateCode)
            return point ? {
                ...point,
                stateCode: linkPoint.stateCode,
                typeName: 'other',
                markerIcon: marker,
                onClickPopup: () => {},
                popupContent: (
                    <div
                        className='row no-margin valign-wrapper clickable'
                        style={{
                            background: `linear-gradient(
                                to right,
                                ${color},
                                ${color} 2rem,
                                white 2rem,
                                white 100%
                            )`,
                            borderRadius: 10,
                        }}
                    >
                        <div className='col s12' style={{ marginLeft: '2rem' }}>
                            <Row><p className='bold'>{city.labelWithCode}</p></Row>
                            <Row>{point.location}</Row>
                            <Row>{`${point.code} - ${point.parcel || ''} ${point.section || ''}`}</Row>
                        </div>
                    </div>
                ),
            } : null
        }))
        this.setState({ exploitationPoints, pointsLoaded: true })
    }

    onChangeType = (initialType, type, contributor) => {
        const { contactsExploitation } = this.state
        const index = contactsExploitation.findIndex((c) => c.id === contributor.id && c.contributorType === initialType)
        const elementChange = { ...contactsExploitation.find((c) => c.id === contributor.id), contributorType: type }
        const newLinks = contactsExploitation.map((elem, i) => i === index ? elementChange : elem)
        this.setState({ contactsExploitation: newLinks })
    }

    getListContributors = () => {
        const { contactsExploitation, readMode } = this.state
        const { sandreCodes } = this.props
        const hasLegalRep = contactsExploitation.find((c) => c.contributorType === -1)
        const typesContributors = [...sandreCodes.filter((c) => c.field === 'EXPLOITATIONS.TYPEINTERVENANT')]
        return contactsExploitation.map((c) => {
            const selectOptions = hasLegalRep && c.contributorType !== -1 ? [...typesContributors] : [{ code: -1, name: i18n.legalRepresentative }, ...typesContributors]
            const type = selectOptions.find((t) => t.code === c.contributorType) || {}
            const select = (
                <SimpleSelect
                    value={type.code}
                    onChange={(v) => this.onChangeType(type.code, parseInt(v), c)}
                    options={selectOptions}
                    keyValue='code'
                    keyName='name'
                    style={{ width: 'auto', margin: '3px 0' }}
                />
            )
            return (
                <Grid2 size={4} key={c.id}>
                    <IntervenantCard
                        intervenant={c}
                        title={!readMode ? select : type.name ? type.name : i18n.unknown}
                        actions={this.getEditButtonContact(c, c.contributorType)}
                        style={{ height: '100%' }}
                        round
                    />
                </Grid2>
            )
        })
    }

    getEditButtonContact = (contact, type) => {
        const { readMode } = this.state
        if (!readMode) {
            return [
                { onClick: () => this.changeState({ selectedContact: contact, openModalContact: true }), iconName: 'edit', tooltip: i18n.change },
                { onClick: () => this.onRemoveContact(contact.id, type), iconName: 'delete', tooltip: i18n.delete },
            ]
        }
        return [{ onClick: () => this.props.push(`/referencial/contact/${contact.id}`), iconName: 'launch' }]
    }

    onRemoveContact = (contactId, type) => {
        const { exploitation } = this.state
        const updatedExploitation = {
            ...exploitation,
            link_contributors: exploitation.link_contributors.filter((c) => !(c.contactCode === contactId && c.contributorType === type)),
        }
        this.setExploitation(updatedExploitation)
    }

    getEditButtonIntervenant = () => {
        const { readMode, preleveur } = this.state
        if (!readMode) {
            return [{ onClick: () => this.changeState({ openModalIntervenant: true }), iconName: 'edit' }]
        }
        return [{ onClick: () => this.props.push(`/referencial/contributor/${preleveur.id}`), iconName: 'launch' }]
    }

    setEditMode = () => {
        const { exploitation } = this.props
        this.setState({ readMode: false })
        const actions = {
            cancel: () => {
                this.setExploitation(exploitation)
                this.setPointsPrel(exploitation)
                this.setReadOnlyMode()
            },
        }
        if (!(getUser().consultant === '1')) {
            actions.save = () => this.onSaveCheck()
        }
        this.setActions(actions)
    }

    setReadOnlyMode = () => {
        this.setState({ readMode: true })
        const user = getUser()
        if (!(user.consultant === '1')) {
            const deleteAction = user?.isAdmin === '1' ? {
                deleteCheck: this.deleteAction,
            } : {}
            this.setActions({
                edit: () => this.setEditMode(),
                ...deleteAction,
            })
        } else {
            this.setActions({})
        }
    }

    deleteAction = () => {
        this.setState({ openDeleteWarningModal: true })
    }

    onDeleteExploitation = (parameters) => {
        const { exploitation } = this.props
        this.setState({ dataLoaded: false })
        this.props.deleteExploitation(exploitation.idExploitation, parameters).then(() => {
            this.props.push('/dossiers')
        })
    }

    onValidateContributor = (contributor, contributorType) => {
        const { exploitation, contactsExploitation } = this.state
        if (contributor.id) {
            if (exploitation.link_contributors.find((c) => c.contactCode === contributor.id && c.contributorType === contributorType)) {
                this.props.toastrInfo(i18n.elementsAlreadyLinked)
            } else {
                const indexs = contactsExploitation.map((c, i) => {
                    if (c.id === contributor.id) {
                        return i
                    }
                    return null
                }).filter(c => hasValue(c))
                const newContacts = contactsExploitation.map((elem, i) => indexs.includes(i) ? { ...contributor, contributorType: elem.contributorType } : elem)
                const updatedExploitation = {
                    ...exploitation,
                    link_contributors: [
                        ...exploitation.link_contributors,
                        {
                            contributorType,
                            contactCode: contributor.id,
                            idExploitation: exploitation.idExploitation,
                        }],
                }
                this.setState({ contactsExploitation: [...newContacts, { ...contributor, contributorType }], exploitation: updatedExploitation, openModalSelectContributors: false })
            }
        } else {
            this.props.createContact(contributor, 'noRedirection').then((contactId) => {
                if (contactId) {
                    const updatedExploitation = {
                        ...exploitation,
                        link_contributors: [
                            ...exploitation.link_contributors,
                            {
                                contributorType,
                                contactCode: contactId,
                                idExploitation: exploitation.idExploitation,
                            },
                        ],
                    }
                    this.props.fetchContacts().then(() => {
                        this.setExploitation(updatedExploitation)
                    })
                }
                this.setState({ openModalSelectContributors: false })
            })
        }
    }

    onSaveCheck = () => {
        const { hasDeclarationInProgress, contactsExploitationInitial, contactsExploitation, preleveurInitial, preleveur } = this.state
        if (hasDeclarationInProgress && (
            !isEqual(sortBy(contactsExploitationInitial, 'id'), sortBy(contactsExploitation, 'id')) ||
            !isEqual(new ContributorDto(preleveurInitial), new ContributorDto(preleveur))
        )) {
            this.setState({ openModalWarningSave: true })
        } else {
            this.onSaveExploitation()
        }
    }

    onSaveExploitation = (overwrite) => {
        const { exploitation, idExploitation, contactsExploitation, preleveur } = this.state
        const { declaration, exploitation: initialExploitation, sandreCodes } = this.props
        const formatedContacts = contactsExploitation.map((c) => {
            return {
                contactCode: c.id,
                contributorType: c.contributorType,
                idExploitation: exploitation.idExploitation,
            }
        })
        const exploitationWithLinks = {
            ...exploitation,
            link_contributors: formatedContacts,
        }
        const eventModel = {
            idExploitation: exploitation.idExploitation,
            eventDate: Date.now(),
            updateLogin: getLogin(),
        }
        const eventsPointsUpdateDelete = initialExploitation.link_samplings.map((linkPoint) => {
            const linkUpdated = exploitation.link_samplings.find((newLink) => newLink.idInstallation === linkPoint.idInstallation)
            const event = {
                ...eventModel,
                associateType: 'installation',
                associateId: linkPoint.idInstallation,
            }
            if (linkUpdated) {
                if (linkPoint.startDate === linkUpdated.startDate &&
                    linkPoint.endDate === linkUpdated.endDate &&
                    linkPoint.stateCode === linkUpdated.stateCode &&
                    linkPoint.distribution === linkUpdated.distribution) {
                    return null
                }
                return { ...event, eventType: 'u' }
            }
            return { ...event, eventType: 'd' }
        })
        const eventsPointsCreate = exploitation.link_samplings.map((linkPoint) => {
            const initalLink = initialExploitation.link_samplings.find((oldLink) => oldLink.idInstallation === linkPoint.idInstallation)
            if (initalLink) {
                return null
            }
            return {
                ...eventModel,
                associateType: 'installation',
                associateId: linkPoint.idInstallation,
                eventType: 'c',
            }
        })
        const typesContributors = [{ code: -1, name: i18n.legalRepresentative }, ...sandreCodes.filter((c) => c.field === 'EXPLOITATIONS.TYPEINTERVENANT')]
        const eventsContactsUpdateDelete = initialExploitation.link_contributors.map((linkContact) => {
            const linkUpdated = exploitation.link_contributors.find((newLink) => newLink.contactCode === linkContact.contactCode)
            const event = {
                ...eventModel,
                associateType: 'contact',
                associateId: linkContact.contactCode,
                comment: getLabel(typesContributors, linkContact.contributorType),
            }
            if (linkUpdated) {
                if (linkContact.startDate === linkUpdated.startDate &&
                    linkContact.endDate === linkUpdated.endDate &&
                    linkContact.contributorType === linkUpdated.contributorType) {
                    return null
                }
                return { ...event, eventType: 'u' }
            }
            return { ...event, eventType: 'd' }
        })
        const eventsContactsCreate = exploitation.link_contributors.map((linkContact) => {
            const initalLink = initialExploitation.link_contributors.find((oldLink) => oldLink.contactCode === linkContact.contactCode)
            if (initalLink) {
                return null
            }
            return {
                ...eventModel,
                associateType: 'contact',
                associateId: linkContact.contactCode,
                eventType: 'c',
                comment: getLabel(typesContributors, linkContact.contributorType),
            }
        })
        const allEvents = compact([
            ...eventsPointsCreate,
            ...eventsPointsUpdateDelete,
            ...eventsContactsCreate,
            ...eventsContactsUpdateDelete,
        ])
        if (allEvents.length) {
            this.props.createExploitationsEvents(allEvents)
        }
        const folder = {
            idExploitation,
            exploitationWithLinks,
            preleveur,
            contacts: contactsExploitation,
            installations: [],
            overwriteCurrentDeclarationUpdate: overwrite,
            idSurveyToOverwrite: overwrite ? declaration.idSurvey : null,
        }
        this.setState({ dataLoaded: false, openModalWarningSave: false })
        this.props.waitStart()
        this.setReadOnlyMode()
        this.props.updateExploitationFolder(folder, idExploitation).then(() => {
            this.props.fetchContacts().then(() => {
                this.props.fetchExploitation(idExploitation).then(() => {
                    this.setExploitation(this.props.exploitation)
                })
            })
        })
    }

    getSelectContributorModal = () => {
        const { contactsExploitation, openModalSelectContributors } = this.state
        const hasLegalRep = contactsExploitation.find((c) => c.contributorType === -1)
        return openModalSelectContributors && (
            <SelectContactModal
                title={i18n.addContributor}
                showLegalRepresentative={!hasLegalRep}
                open={openModalSelectContributors}
                onSave={this.onValidateContributor}
                onCancel={() => this.setState({ openModalSelectContributors: false })}
                showBirthdate
            />
        )
    }

    getButtonsContacts = () => {
        const { readMode } = this.state
        if (!readMode) {
            return [
                { onClick: () => this.setState({ openModalSelectContributors: true }), iconName: 'note_add', tooltip: i18n.add },
            ]
        }
        return []
    }

    getSelectPointsPrelModal = () => {
        const { exploitation, openModalSelectPointsPrel } = this.state
        return openModalSelectPointsPrel && (
            <SelectPointsPrelModal
                exploitation={exploitation}
                open={openModalSelectPointsPrel}
                onCancel={() => this.setState({ openModalSelectPointsPrel: false })}
                onValidate={this.onValidatePtsPrelSelection}
                onCreateNew={() => this.setState({ openModalPointPrel: true, selectedPoint: {} })}
            />
        )
    }

    onValidatePtsPrelSelection = (link_points) => {
        const { exploitation } = this.state
        const newExploit = {
            ...exploitation,
            link_samplings: [
                ...link_points,
            ],
        }
        this.setPointsPrel(newExploit)
        this.setState({ exploitation: newExploit, openModalSelectPointsPrel: false })
    }

    getModalPointPrel = () => {
        const { selectedPoint, openModalPointPrel, readMode } = this.state
        return (
            <InstallationStepperModal
                title={readMode ? `${i18n.pointPrelevement} ${selectedPoint.code}` : !selectedPoint.id ? i18n.createNewPointPrel : `${i18n.updatePointPrel} ${selectedPoint.code}`}
                point={selectedPoint}
                open={openModalPointPrel}
                onCancel={() => this.setState({ openModalPointPrel: false, selectedPoint: null })}
                onValidate={this.onValidatePointPrel}
                disabled={readMode}
            />
        )
    }

    onValidatePointPrel = (point) => {
        if (point.id) {
            this.props.updateInstallation(point, point).then(() => {
                this.updatePointsExploit(point)
            })
        } else {
            this.props.createInstallation({ ...point, installationType: 18 }, id => {
                this.updatePointsExploit({ ...point, id })
            })
        }
    }

    updatePointsExploit = (point) => {
        this.props.waitStart()
        this.props.fetchInstallationsWithGeo().then(() => {
            this.props.fetchInstallationAgriTanks().then(() => {
                const { installationsWithGeo } = this.props
                const pointGeo = installationsWithGeo.find((i) => i.id === point.id)
                const { exploitationPoints, exploitation } = this.state
                const newPoints = [
                    ...exploitationPoints.filter((p) => p.id !== point.id),
                    pointGeo,
                ]
                const linkPointFound = exploitation.link_samplings.find((linkPoint) => linkPoint.idInstallation === point.id) || {}
                const newExploitation = {
                    ...exploitation,
                    link_samplings: [
                        ...exploitation.link_samplings.filter((linkPoint) => linkPoint.idInstallation !== point.id),
                        {
                            ...linkPointFound,
                            idExploitation: exploitation.idExploitation,
                            idInstallation: point.id,
                            link_sampleCasings: point.link_sampleCasing ? [...point.link_sampleCasing] : [],
                            link_samples: point.link_sampleCharacteristics ? [...point.link_sampleCharacteristics] : [],
                            link_sampleTanks: point.link_sampleTanks ? [...point.link_sampleTanks] : [],
                            stateCode: hasValue(linkPointFound.stateCode) ? linkPointFound.stateCode : 1,
                        },
                    ],
                }
                this.props.waitStop()
                this.setState({ exploitation: newExploitation, exploitationPoints: newPoints, openModalPointPrel: false, selectedPoint: null })
            })
        })
    }

    getModalContact = () => {
        const { selectedContact, openModalContact } = this.state
        return (
            <ContactModal
                title={!selectedContact.id && i18n.createNewContact}
                contact={selectedContact}
                open={openModalContact}
                onCancel={() => this.setState({ openModalContact: false, selectedContact: null })}
                onValidate={this.onValidateContact}
            />
        )
    }

    onValidateContact = (contact) => {
        const { contactsExploitation } = this.state
        const indexs = contactsExploitation.map((c, i) => {
            if (c.id === contact.id) {
                return i
            }
            return null
        }).filter(c => hasValue(c))
        const newContacts = contactsExploitation.map((elem, i) => indexs.includes(i) ? { ...contact, contributorType: elem.contributorType } : elem)
        this.setState({ contactsExploitation: newContacts, openModalContact: false, selectedContact: null })
    }

    getModalIntervenant = () => {
        const { preleveur, openModalIntervenant } = this.state
        return openModalIntervenant && (
            <IntervenantModal
                intervenant={preleveur}
                open={openModalIntervenant}
                onCancel={() => this.setState({ openModalIntervenant: false })}
                onValidate={this.onValidateIntervenant}
            />
        )
    }

    onValidateIntervenant = (preleveur) => {
        this.setState({ preleveur, openModalIntervenant: false })
    }

    onChangeValueExploit = (value) => {
        this.setState(({ exploitation }) => ({
            exploitation: {
                ...exploitation,
                ...value,
            },
        }))
    }

    onChangeValuePreleveur = (object) => {
        this.setState(({ preleveur }) => ({
            preleveur: {
                ...preleveur,
                ...object,
            },
        }))
    }

    getStatus = (stateCode) => {
        switch (stateCode) {
            case 1:
                return i18n.used
            case 2:
                return i18n.notUsed
            case 3:
                return i18n.closed
            default:
                return ''
        }
    }

    changeState = (obj) => {
        this.setState({ ...obj })
    }

    openPoint = (point) => {
        if (point.id) {
            this.props.fetchInstallation(point.id).then(() => {
                const { installation } = this.props
                this.changeState({ selectedPoint: installation, openModalPointPrel: true })
            })
        }
    }

    onChangeLinkPoint = (linkPoint, obj) => {
        const { exploitation } = this.state
        const linkFound = exploitation.link_samplings.find((l) => l.idInstallation === linkPoint.idInstallation)
        const linkUpdated = {
            ...linkFound,
            ...obj,
        }
        this.setState({
            exploitation: {
                ...exploitation,
                link_samplings: [
                    ...exploitation.link_samplings.filter((l) => l.idInstallation !== linkPoint.idInstallation),
                    linkUpdated,
                ],
            },
        })
    }

    getLineRepartition = (link) => {
        const { exploitationsExportFullData } = this.props
        const exploitationFound = exploitationsExportFullData.find((e) => e.idExploitation === link.idExploitation) || {}
        return (
            <>
                <span>{exploitationFound.codification} : {hasValue(link.distribution) ? `${link.distribution} %` : i18n.unknown}</span><br />
            </>
        )
    }

    getPumpValue = (pumpSitu, exportValue) => {
        const { variousMateriels } = this.props
        if (pumpSitu.length > 1) {
            if (exportValue) {
                return pumpSitu.map((p) => {
                    const matFound = variousMateriels.find(({ id }) => id === p.idVarious) || {}
                    return ` ${matFound.reference || i18n.unknown}`
                }).toString()
            }
            const tooltip = (
                <div>
                    {pumpSitu.map((p) => {
                        const matFound = variousMateriels.find(({ id }) => id === p.idVarious) || {}
                        return <Fragment key={matFound.id}><span>{matFound.reference}</span><br /></Fragment>
                    })}
                </div>
            )
            return <span {...sieauTooltip(tooltip, null, 'right')}>{`${pumpSitu.length} ${i18n.pumps}`}</span>
        }
        const matFound = variousMateriels.find(({ id }) => id === pumpSitu[0].idVarious)
        if (matFound) {
            return matFound.reference
        }
        return i18n.unknown
    }

    getTanksValue = (link_sampleTanks, exportValue) => {
        if (exportValue) {
            return link_sampleTanks.map((t) => ` ${t.id} (${formatMilliers(t.capacity)} m3)`).toString()
        }
        const tooltip = (
            <div>
                {link_sampleTanks.map((t) => <Fragment key={t.id}><span>{`${t.id} : ${formatMilliers(t.capacity) || 0} m3`}</span><br /></Fragment>)}
            </div>
        )
        const total = link_sampleTanks.reduce((acc, t) => acc + t.capacity, 0) || 0
        return <span {...sieauTooltip(tooltip, null, 'right')}>{`${formatMilliers(total)} m3`}</span>
    }

    getTablePoints = (pointsToShow, title) => {
        const { citiesIndex, managementUnits, watersheds, aquifers, sandreCodes } = this.props
        const { exploitation, readMode, exploitationPoints } = this.state
        const exploitationPumpsSitu = exploitation.link_materiel.filter((s) =>
            s.siteType === 7 &&
            pointsToShow.find(({ idInstallation }) => idInstallation === s.siteCode) &&
            (!s.situationDate || s.situationDate <= moment().valueOf()) &&
            (!s.situationEndDate || s.situationEndDate >= moment().valueOf()),
        )
        const data = compact(pointsToShow.map((linkPoint) => {
            const point = exploitationPoints.find((i) => i.id === linkPoint.idInstallation)
            if (point) {
                const city = citiesIndex[point.townCode] || {}
                const link_shared = linkPoint.link_shared || []
                const isShared = link_shared.filter((p) => p.idExploitation !== exploitation.idExploitation).length > 0
                const total = link_shared.reduce((acc, l) => acc + (l.distribution || 0), 0)
                const tooltip = (
                    <>
                        {link_shared.map((p) => this.getLineRepartition(p))}
                        <span className='bold'>{i18n.total} : {`${total} %`}</span><br />
                    </>
                )
                const ug = point.managementCode ? managementUnits.find((u) => u.managementCode === point.managementCode) : undefined
                const sug = point.subManagementCode ? managementUnits.find((u) => u.managementCode === point.subManagementCode) : undefined
                const pumpSitu = exploitationPumpsSitu.filter((situ) => linkPoint.idInstallation === situ.siteCode)
                const tooltipLocation = (
                    <>
                        {i18n.watershed} : {watersheds.find((w) => w.id === point?.bvCode)?.name}<br />
                        {i18n.aquifer} : {aquifers.find((w) => w.id === point?.accompanyingAquiferCode)?.name}<br />
                        {i18n.climateSector} : {citiesIndex[point.townCode]?.climateSector ? sandreCodes.find((s) => s.field === 'PREL_AGRI.SECTEUR_CLIMATIQUE' && s.code === citiesIndex[point.townCode]?.climateSector)?.referenceWithLabel ?? '' : ''}
                    </>
                )
                const tooltipUG = (
                    <>
                        {i18n.nameUge} : {ug?.labelWithCode || ''}<br />
                        {i18n.nameSubUge} : {sug?.labelWithCode || ''}
                    </>
                )
                return {
                    nullValue: readMode ? null : (<i className='material-icons clickable' onClick={() => this.openPoint(point)}>edit</i>),
                    nullValue2: (
                        <div
                            {...sieauTooltip(tooltipLocation)}
                            style={{
                                backgroundColor: point.sampleType === SAMPLE_TYPES.UNDERGROUND ? '#002262' : '#BDD6EC',
                                borderRadius: 20,
                                border: '1px solid black',
                                height: 20,
                                width: 20,
                            }}
                        />
                    ),
                    color: linkPoint.stateCode === 1 ? 'white' : 'lightgrey',
                    ...point,
                    city: city.labelWithCode,
                    uge: <div {...sieauTooltip(tooltipUG)}>{sug?.id || ug?.id}</div>,
                    codeParcelle: `${point.parcel || ''} ${point.section || ''}`,
                    lieuDit: point.location,
                    status: readMode ? this.getStatus(linkPoint.stateCode) : (
                        <Select
                            value={linkPoint.stateCode}
                            options={[
                                { value: 1, name: i18n.used },
                                { value: 2, name: i18n.notUsed },
                                { value: 3, name: i18n.closed },
                            ]}
                            onChange={value => this.onChangeLinkPoint(linkPoint, { stateCode: parseInt(value) })}
                        />
                    ),
                    begin: readMode ? getDate(linkPoint.startDate) : (
                        <SimpleDatePicker
                            value={linkPoint.startDate}
                            onChange={value => this.onChangeLinkPoint(linkPoint, { startDate: value })}
                            dateClassName='tableDatePicker'
                        />
                    ),
                    end: readMode ? getDate(linkPoint.endDate) : (
                        <SimpleDatePicker
                            value={linkPoint.endDate}
                            onChange={value => this.onChangeLinkPoint(linkPoint, { endDate: value })}
                            dateClassName='tableDatePicker'
                        />
                    ),
                    sharedIcon: isShared ? (
                        <div {...sieauTooltip(() => tooltip, null, 'right')}>
                            <Icon>people</Icon>
                        </div>
                    ) : null,
                    shared: isShared ? i18n.yes : i18n.no,
                    division: readMode ? (!isShared ? '100 %' : (hasValue(linkPoint.distribution) ? `${linkPoint.distribution} %` : i18n.unknown)) : (
                        <NumberField
                            value={linkPoint.distribution}
                            onChange={value => this.onChangeLinkPoint(linkPoint, { distribution: value })}
                        />
                    ),
                    pump: pumpSitu.length ? this.getPumpValue(pumpSitu, true) : '',
                    pumpValue: pumpSitu.length ? this.getPumpValue(pumpSitu) : '',
                    detention: linkPoint?.link_sampleTanks?.length ? this.getTanksValue(linkPoint.link_sampleTanks, true) : '',
                    tanksValue: linkPoint?.link_sampleTanks?.length ? this.getTanksValue(linkPoint.link_sampleTanks) : '',
                }
            }
            return null
        }))
        const actions =
            !readMode ? [{
                tooltip: i18n.select,
                icon: 'compare_arrows',
                color: 'white',
                onClick: () => this.changeState({ openModalSelectPointsPrel: true }),
            }] : [getExport(data, `${exploitation.codification} - ${i18n.pointsPrelevement}`, [...headerPoints, 'shared', 'division', 'pump', 'detention'])]
        return (
            <CardTable
                title={title}
                rows={orderBy(data, ['townCode', 'city', 'parcel', 'section', 'code'])}
                actions={actions}
                rowsPerPageOptions={nbPerPageLabel}
                headers={readMode ? [...headerPoints, 'sharedIcon', 'pumpValue', 'tanksValue'].filter((h) => h !== 'status') : [...headerPoints, 'division'] }
                headersLabel={{
                    sharedIcon: i18n.shared,
                    codeParcelle: <span style={{ whiteSpace: 'pre-wrap' }}>{i18n.codeParcelleWrap}</span>,
                    pumpValue: i18n.pump,
                    tanksValue: i18n.detention,
                    uge: 'UG',
                }}
                onClickRow={(s) => readMode && this.openPoint(s)}
                color={darkBlue}
            />
        )
    }

    getLands = () => {
        const { exploitation, readMode } = this.state
        const data = (exploitation.link_lands || []).map(land => {
            return {
                nullValue: land.id !== 0 && !readMode ? { leftIcon: 'edit', leftIconColor: 'black', onClick: () => this.setLandModal(land) } : null,
                nullValue2: !readMode ? { leftIcon: 'clear', leftIconColor: 'black', onClick: () => this.setState({ exploitation: { ...exploitation, link_lands: exploitation.link_lands.filter(l => l.id !== land.id) } }) } : null,
                code: { value: land.code },
                pointPrelevement: { value: land.idSamplingPoint ? (this.props.installationsWithGeo.find(i => i.id === land.idSamplingPoint) || {}).code || '' : '' },
                culture: '',
                irrigationMethod: '',
                surface: { value: hasValue(land.area) ? `${land.area}ha` : '' },
            }
        })
        return (
            <CardTable
                title={data.length > 1 ? i18n.parcels : i18n.parcel}
                rows={data}
                actions={readMode ? [] : [{ icon: 'note_add', color: 'white', onClick: () => this.setLandModal() }]}
                rowsPerPageOptions={nbPerPageLabel}
                headers={['nullValue', 'nullValue2', 'code', 'pointPrelevement', 'culture', 'irrigationMethod', 'surface']}
                color={darkBlue}
            />
        )
    }

    setLandModal = (land) => {
        const changeLand = (newLand) => {
            const link_lands = land ? uniqBy([newLand, ...this.state.exploitation.link_lands], 'id') : [...this.state.exploitation.link_lands, newLand]
            this.setState({ exploitation: { ...this.state.exploitation, link_lands } })
        }
        const popup = {
            id: 'parcelmodal',
            header: i18n.parcel,
            content: <NewLandModal land={land} onChangeLand={changeLand} />,
        }
        AppStore.dispatch(SieauAction.setPopup(popup))
    }

    getLastDeclarationPanel = () => {
        const { declarationsExploitation, surveys } = this.props
        const lastDeclarationStats = orderBy(declarationsExploitation.map((d) => ({
            ...d,
            year: (surveys.find(({ idSurvey }) => idSurvey === d.idSurvey) || {}).year,
        })), 'year', 'desc')[0]
        return (
            <DeclarationStatsCard
                title={`${i18n.lastDeclaration} - ${lastDeclarationStats.year}`}
                className='margin-top-1 padding-bottom-1'
                noMargin={false}
                declaration={lastDeclarationStats}
            />
        )
    }

    getTableHistoSurveys = () => {
        const { declarationsExploitation, surveys, sandreCodes, exploitation } = this.props
        const data = orderBy(declarationsExploitation.map((d) => {
            const survey = surveys.find(({ idSurvey }) => idSurvey === d.idSurvey) || {}
            return {
                survey: survey.name,
                year: survey.year,
                status: getSandreLabel(sandreCodes, 'DECLARATIONS.STATUT', d.statusCode),
                realVolume: `${formatMilliers(d.realVolume)} m3`,
                nbPts: d.nbPts,
            }
        }), 'year', 'desc')
        return (
            <div className='margin-top-1'>
                <CardTable
                    title={i18n.historic}
                    rows={data}
                    rowsPerPageOptions={nbPerPageLabelShort}
                    headers={headerSurveys}
                    headersLabel={{
                        realVolume: <span style={{ whiteSpace: 'pre-wrap' }}>{i18n.realVolumeWrap}</span>,
                        nbPts: <span style={{ whiteSpace: 'pre-wrap' }}>{i18n.nbPtsWrap}</span>,
                    }}
                    actions={[getExport(data, `${exploitation.codification} - ${i18n.historic} ${i18n.surveys}`, headerSurveys)]}
                    color={darkBlue}
                />
            </div>
        )
    }

    selfAssign = (exploitation) => {
        this.props.changeExploitationAdministrator(exploitation.idExploitation, true).then(() => {
            this.props.fetchExploitation(exploitation.idExploitation).then(() => this.setExploitation(this.props.exploitation))
        })
    }

    getCardUsers = () => {
        const { exploitation, readMode } = this.state
        const { usersLight } = this.props
        const filteredUsers = usersLight.filter((u) => exploitation.link_codifications.find((c) => u.login === c.codification))
        const formattedUsers = filteredUsers.map((u) => ({
            ...u,
            gestCheckbox: u.metadata === '1' ? (<Checkbox col={12} checked disabled />) : '',
            adminCheckbox: u.isAdmin === '1' ? (<Checkbox col={12} checked disabled />) : '',
        }))
        const user = getUser()
        const selftAssignAction = user?.consultant !== '1' && !filteredUsers.some(({ login }) => login === user?.login) ? [{
            tooltip: i18n.selfAssign,
            icon: 'supervisor_account',
            color: 'white',
            onClick: () => this.selfAssign(exploitation),
        }] : []
        const actions = !readMode ? [{
            tooltip: i18n.select,
            icon: 'edit',
            color: 'white',
            onClick: () => this.changeState({ openModalSelectUsers: true }),
        }] : [
            ...selftAssignAction,
            getExport(formattedUsers, `${exploitation.codification} - ${i18n.users}`, headerUsers),
        ]

        return (
            <CardTable
                title={formattedUsers.length > 1 ? i18n.users : i18n.user}
                rows={orderBy(formattedUsers, 'id')}
                actions={actions}
                rowsPerPageOptions={nbPerPageLabel}
                headers={headerUsers}
                headersLabel={{ adminCheckbox: i18n.adminShort, gestCheckbox: i18n.administrator }}
                color={darkBlue}
            />
        )
    }

    getCurrentCodificationsTable = (preleveur) => {
        const { sandreCodes, exploitation } = this.props
        const contributorCodifications = preleveur.link_codificationContributors && preleveur.link_codificationContributors.length ? [...preleveur.link_codificationContributors] : []
        const now = Date.now()
        const tableData = contributorCodifications.filter((c) => (!c.startDate || c.startDate <= now) && (!c.endDate || c.endDate >= now)).map((c, index) => ({
            ...c,
            index,
        })).map(c => ({
            ...c,
            codificationType: getSandreLabel(sandreCodes, SANDRE.INTERVENANTS_TYPE_CODIFICATION, c.codificationType),
        }))
        const headers = ['codificationType', 'codification']
        return (
            <CardTable
                title={i18n.codifications}
                rows={tableData}
                headers={headers}
                headersLabel={{
                    codificationType: i18n.type,
                    codification: i18n.value,
                }}
                actions={[getExport(tableData, `${exploitation.codification} - ${i18n.codification}`, headers)]}
                color={darkBlue}
            />
        )
    }

    getSelectUsersModal = () => {
        const { exploitation, openModalSelectUsers } = this.state
        return openModalSelectUsers && (
            <SelectUsersModal
                exploitation={exploitation}
                open={openModalSelectUsers}
                onCancel={() => this.setState({ openModalSelectUsers: false })}
                onValidate={this.onValidateUsers}
            />
        )
    }

    onValidateUsers = (link_users) => {
        this.setState(({ exploitation }) => ({
            exploitation: {
                ...exploitation,
                link_codifications: [
                    ...link_users,
                ],
            },
            openModalSelectUsers: false,
        }))
    }

    getWarningSaveModal = () => (
        <Dialog
            onClose={() => this.setState({ openModalWarningSave: false })}
            fullWidth
            maxWidth='md'
            open={open}
        >
            <DialogTitle style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Grid2 container alignItems='center'>
                    <Icon style={{ fontSize: '2.5rem', marginRight: 8 }}>warning</Icon>
                    <Grid2>{i18n.payAttention}</Grid2>
                </Grid2>
                <Icon style={{ color: 'white' }} size='small' icon='close' onClick={() => this.setState({ openModalWarningSave: false })} />
            </DialogTitle>
            <DialogContent className='padding-bottom-0'>
                <p>Une déclaration est en cours sur ce dossier. Vos modifications remplaceront les changements éventuels faits par l'usager sur sa déclaration.</p>
                <p className='italic-label'>Uniquement les modifications de l'usager concernant les contacts, préleveur et propriétaires de points seront remplacées.</p>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => this.onSaveExploitation(true)} variant='contained' color='primary'>
                    {i18n.validate}
                </Button>
            </DialogActions>
        </Dialog>
    )

    showPointsData = () => {
        const { exploitation, readMode, showPointHisto } = this.state
        const linkPointsFiltered = readMode ? exploitation.link_samplings.filter((p) => !p.endDate || p.endDate >= moment().valueOf()) : exploitation.link_samplings
        const pointsHisto = exploitation.link_samplings.filter((p) => p.endDate && p.endDate < moment().valueOf())
        return (
            <>
                <div className='col s12 margin-top-1'>
                    {this.getTablePoints(linkPointsFiltered, getI18nTitleData(i18n.pointPrelevement, i18n.pointsPrelevement, linkPointsFiltered))}
                    {pointsHisto.length && readMode ? (
                        <Button
                            className='margin-top-1 right'
                            variant={showPointHisto ? 'outlined' : 'contained'}
                            color='primary'
                            onClick={() => this.setState({ showPointHisto: !showPointHisto })}
                            {...sieauTooltip(showPointHisto ? i18n.clickToHide : i18n.clickToDisplay, null, 'bottom')}
                        >
                            {`${pointsHisto.length} ${getI18nTitleData(i18n.pointPrelevementHisto, i18n.pointsPrelevementHisto, pointsHisto)}`}
                        </Button>
                    ) : null}
                </div>
                {pointsHisto.length && readMode && showPointHisto ? (
                    <div className='col s12 margin-top-1'>
                        {this.getTablePoints(pointsHisto, getI18nTitleData(i18n.pointPrelevementHisto, i18n.pointsPrelevementHisto, pointsHisto))}
                    </div>
                ) : null}
            </>
        )
    }

    toggleFavExploit = (isFav) => {
        const { idExploitation } = this.state
        const bookmark = {
            login: getLogin(),
            identifiant: String(idExploitation),
            stationType: 10,
        }
        if (isFav) {
            this.props.deleteBookmark(bookmark)
        } else {
            this.props.createBookmark(bookmark)
        }
    }

    render() {
        const {
            exploitation,
            dataLoaded,
            preleveur,
            pointsLoaded,
            readMode,
            openModalPointPrel,
            openModalContact,
            exploitationPoints,
            openModalWarningSave,
            openDeleteWarningModal,
            mapFullScreen,
            idExploitation,
        } = this.state

        const { declarationsExploitation, sandreCodes, userBookmarks, contributors } = this.props

        const typesStructure = sandreCodes.filter((c) => c.field === 'INTERVENANTS.TYPE_STRUCTURE')
        const procedureTypes = sandreCodes.filter((c) => c.field === 'DOSSIERS.TYPE_PROCEDURE')
        const isFav = userBookmarks.find((b) => b.login === getLogin() && b.stationType === 10 && parseInt(b.identifiant) === idExploitation)
        return (
            <div className='row no-margin padding-top-1 padding-bottom-7'>
                {dataLoaded ? (
                    <div>
                        <div className='col s9 no-padding row'>
                            <div className='col s12'>
                                <Card
                                    title={i18n.generalInfos}
                                    actions={[{ onClick: () => this.toggleFavExploit(isFav), iconName: isFav ? 'star' : 'star_border', tooltip: isFav ? i18n.removeFavorite : i18n.addFavorite }]}
                                    round
                                >
                                    <Row className='padding-1'>
                                        <div className='col s4'>
                                            <Input
                                                col={12}
                                                value={preleveur.name}
                                                title={i18n.name}
                                                onChange={(value) => this.onChangeValuePreleveur({ name: value })}
                                                disabled={readMode}
                                            />
                                            <Input
                                                col={12}
                                                value={readMode ? formatSiret(preleveur.siret) : preleveur.siret}
                                                title={i18n.siret}
                                                onChange={(value) => this.onChangeValuePreleveur({ siret: value })}
                                                disabled={readMode}
                                                maxLength={14}
                                            />
                                            <SimpleDatePicker
                                                col={12}
                                                id='activityStartDate'
                                                label={i18n.activityStartDate}
                                                value={preleveur.activityStartDate}
                                                onChange={(v) => this.onChangeValuePreleveur({ activityStartDate: v })}
                                                disabled={readMode}
                                            />
                                            <SimpleDatePicker
                                                col={12}
                                                id='activityEndDate'
                                                label={i18n.activityEndDate}
                                                value={preleveur.activityEndDate}
                                                onChange={(v) => this.onChangeValuePreleveur({ activityEndDate: v })}
                                                disabled={readMode}
                                            />
                                        </div>
                                        <div className='col s8'>
                                            <Input
                                                col={4}
                                                value={exploitation.codification}
                                                title={i18n.codification}
                                                disabled
                                            />
                                            <Select
                                                col={4}
                                                label={i18n.status}
                                                options={sandreCodes.filter((c) => c.field === 'CMS.STATUS')}
                                                value={parseInt(exploitation.status) || null}
                                                keyValue='code'
                                                onChange={(value) => this.onChangeValueExploit({ status: value?.toString() })}
                                                disabled={readMode}
                                            />
                                            <Select
                                                col={4}
                                                label={i18n.organism}
                                                options={contributors}
                                                value={exploitation.organism}
                                                keyValue='id'
                                                onChange={value => this.onChangeValueExploit({ organism: value })}
                                                disabled={readMode}
                                            />
                                            <Select
                                                col={6}
                                                label={i18n.structureType}
                                                options={typesStructure}
                                                value={preleveur.structureType}
                                                keyValue='code'
                                                onChange={value => this.onChangeValuePreleveur({ structureType: value })}
                                                disabled={readMode}
                                            />
                                            <Select
                                                col={6}
                                                label={i18n.procedure}
                                                options={procedureTypes}
                                                value={exploitation.procedureType}
                                                keyValue='code'
                                                onChange={value => this.onChangeValueExploit({ procedureType: value })}
                                                disabled={readMode}
                                            />
                                            <Textarea
                                                col={12}
                                                value={exploitation.comment}
                                                title={i18n.comment}
                                                onChange={(value) => this.onChangeValueExploit({ comment: value })}
                                                disabled={readMode}
                                            />
                                        </div>
                                    </Row>
                                </Card>
                            </div>
                            <div className='col s12 margin-top-1'>
                                <Card title={i18n.contributors} actions={this.getButtonsContacts()} round>
                                    <Grid2 container className='padding-1' spacing={1}>
                                        <Grid2 size={4}>
                                            <IntervenantCard title={i18n.sampler} round intervenant={preleveur} actions={this.getEditButtonIntervenant()} style={{ height: '100%' }} contributor />
                                        </Grid2>
                                        {this.getListContributors()}
                                    </Grid2>
                                </Card>
                            </div>
                            {pointsLoaded && this.showPointsData()}
                            {!readMode && (
                                <div className='col s12 margin-top-1'>
                                    <ReferencialCodificationPanel
                                        contributor={preleveur}
                                        title={i18n.codifications}
                                        onChange={(value) => this.onChangeValuePreleveur(value)}
                                    />
                                </div>
                            )}
                            <div className='col s12 margin-top-1'>
                                {this.getLands()}
                            </div>
                            <div className='col s12 margin-top-1'>
                                <ExploitationServicesPanel exploitation={exploitation} readMode={readMode} onChange={this.onChangeValueExploit} />
                            </div>
                        </div>
                        <div className='col s3'>
                            <div className='margin-bottom-1'>
                                <UpdatePanel updateDate={exploitation.updateDate} updateLogin={exploitation.updateLogin} round />
                            </div>
                            {this.getCardUsers()}
                            {declarationsExploitation?.length ? (
                                <>
                                    {this.getLastDeclarationPanel()}
                                    {this.getTableHistoSurveys()}
                                </>
                            ) : null}
                            {!openModalPointPrel && (
                                <div className='margin-top-1'>
                                    <CartographyPanel
                                        layers={['STATIONS_POINTS']}
                                        satelliteMode
                                        noSearchBar
                                        componentType='all'
                                        stationsPoints={exploitationPoints}
                                        height={250}
                                        noPanels={!mapFullScreen}
                                        onToggleFullScreen={() => this.setState({ mapFullScreen: !mapFullScreen })}
                                        popupStyle={mapFullScreen ? { padding: 0 } : { width: 0, minWidth: 0, height: 0, minHeight: 0, padding: 0, overflow: 'hidden' } }
                                        round
                                    />
                                </div>
                            )}
                            {readMode && (
                                <div className='margin-top-1'>
                                    {this.getCurrentCodificationsTable(preleveur)}
                                </div>
                            )}
                        </div>
                        {this.getSelectContributorModal()}
                        {this.getSelectUsersModal()}
                        {openModalContact && this.getModalContact()}
                        {this.getModalIntervenant()}
                        {this.getSelectPointsPrelModal()}
                        {openModalPointPrel && this.getModalPointPrel()}
                        {openModalWarningSave && this.getWarningSaveModal()}
                        {openDeleteWarningModal && (
                            <ModalWarningDeleteExploit
                                open={openDeleteWarningModal}
                                onCancel={() => this.setState({ openDeleteWarningModal: false })}
                                onDelete={this.onDeleteExploitation}
                            />
                        )}
                    </div>
                ) : <ProgressCard indeterminate withMessage round />}
            </div>
        )
    }
}

ExploitationDashboardApp.propTypes = {
    fetchExploitation: PropTypes.func,
    updateExploitationFolder: PropTypes.func,
    fetchDeclarationByExploitationId: PropTypes.func,
    fetchDeclarationsByExploitationId: PropTypes.func,
    changeExploitationAdministrator: PropTypes.func,
    fetchContributor: PropTypes.func,
    fetchContacts: PropTypes.func,
    fetchInstallationsWithGeo: PropTypes.func,
    fetchInstallation: PropTypes.func,
    createInstallation: PropTypes.func,
    updateInstallation: PropTypes.func,
    deleteExploitation: PropTypes.func,
    setTitle: PropTypes.func,
    createContact: PropTypes.func,
    waitStart: PropTypes.func,
    waitStop: PropTypes.func,
    push: PropTypes.func,
    fetchInstallationAgriTanks: PropTypes.func,
    createExploitationsEvents: PropTypes.func,
    createBookmark: PropTypes.func,
    deleteBookmark: PropTypes.func,
    declaration: PropTypes.instanceOf(DtoDeclaration),
    sandreCodes: PropTypes.arrayOf(PropTypes.instanceOf(DtoSandreCode)),
    exploitation: PropTypes.arrayOf(PropTypes.instanceOf(DtoExploitation)),
    surveys: PropTypes.arrayOf(PropTypes.instanceOf(DtoSurvey)),
    contributor: PropTypes.arrayOf(PropTypes.instanceOf(ContributorDto)),
    contacts: PropTypes.arrayOf(PropTypes.instanceOf(ContactItem)),
    installationsWithGeo: PropTypes.arrayOf(PropTypes.instanceOf(DtoInstallationWithGeoItem)),
    installation: PropTypes.arrayOf(PropTypes.instanceOf(DtoInstallation)),
    citiesIndex: PropTypes.shape({}),
    usersLight: PropTypes.arrayOf(PropTypes.instanceOf(DtoUserLight)),
    services: PropTypes.arrayOf(PropTypes.instanceOf(DtoExploitationService)),
    exploitationsExportFullData: PropTypes.arrayOf(PropTypes.instanceOf(DtoExploitationExportFull)),
    variousMateriels: PropTypes.arrayOf(PropTypes.instanceOf(VariousMaterielDto)),
    userBookmarks: PropTypes.arrayOf(PropTypes.instanceOf(DtoUserBookmark)),
    managementUnits: PropTypes.arrayOf(PropTypes.instanceOf(DtoManagementUnit)),
    watersheds: PropTypes.arrayOf(PropTypes.instanceOf(WatershedDto)),
    aquifers: PropTypes.arrayOf(PropTypes.instanceOf(DtoAquifer)),
    contributors: PropTypes.arrayOf(PropTypes.instanceOf(ContributorDto)),
}

const mapStateToProps = (store) => {
    return {
        exploitation: store.AgriReducer.exploitation,
        declaration: store.AgriReducer.declaration,
        declarationsExploitation: store.AgriReducer.declarationsExploitation,
        surveys: store.AgriReducer.surveys,
        contributor: store.ContributorReducer.contributor,
        sandreCodes: store.ReferencialReducer.sandreCodes,
        contacts: store.ContactReducer.contacts,
        installationsWithGeo: store.InstallationReducer.installationsWithGeo,
        installation: store.InstallationReducer.installation,
        citiesIndex: store.CityReducer.citiesIndex,
        usersLight: store.UserReducer.usersLight,
        services: store.AgriReducer.services,
        exploitationsExportFullData: store.AgriReducer.exploitationsExportFullData,
        variousMateriels: store.VariousMaterielReducer.variousMateriels,
        userBookmarks: store.UserReducer.userBookmarks,
        managementUnits: store.ManagementUnitReducer.managementUnits,
        watersheds: store.WatershedReducer.watersheds,
        aquifers: store.AquiferReducer.aquifers,
        contributors: store.ContributorReducer.contributors,
    }
}

const mapDispatchToProps = {
    fetchExploitation: AgriAction.fetchExploitation,
    fetchExploitationsExportFullData: AgriAction.fetchExploitationsExportFullData,
    updateExploitationFolder: AgriAction.updateExploitationFolder,
    fetchDeclarationByExploitationId: AgriAction.fetchDeclarationByExploitationId,
    fetchDeclarationsByExploitationId: AgriAction.fetchDeclarationsByExploitationId,
    changeExploitationAdministrator: AgriAction.changeExploitationAdministrator,
    fetchServices: AgriAction.fetchServices,
    deleteExploitation: AgriAction.deleteExploitation,
    createExploitationsEvents: AgriAction.createExploitationsEvents,
    fetchContributor: ContributorAction.fetchContributor,
    fetchContacts: ContactAction.fetchContacts,
    fetchInstallationsWithGeo: InstallationAction.fetchInstallationsWithGeo,
    fetchInstallationAgriTanks: InstallationAction.fetchInstallationAgriTanks,
    fetchInstallation: InstallationAction.fetchInstallation,
    createInstallation: InstallationAction.createInstallation,
    updateInstallation: InstallationAction.updateInstallation,
    createBookmark: UserAction.createBookmark,
    deleteBookmark: UserAction.deleteBookmark,
    setTitle: HomeAction.setTitle,
    createContact: ContactAction.createContact,
    waitStart: WaitAction.waitStart,
    waitStop: WaitAction.waitStop,
    toastrInfo: ToastrAction.info,
    toastrWarning: ToastrAction.warning,
    push,
}

export default connect(mapStateToProps, mapDispatchToProps)(ExploitationDashboardApp)
