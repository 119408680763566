export default class DtoMaterielStationEvent {
    constructor(obj, siteType) {
        this.code = obj.code
        this.number = obj.number
        this.date = obj.date
        this.comment = obj.comment
        this.contactCode = obj.contactCode
        this.eventType = obj.eventType
        this.siteType = siteType
    }
}
