import { Grid } from '@mui/material'
import Icon from 'components/icon/Icon'
import PropTypes from 'prop-types'
import React, { useMemo } from 'react'
import i18n from 'simple-react-i18n'
import { StyledSpan } from 'components/StyledElements'
import { shallowEqual, useSelector } from 'react-redux'

const UsersDisplay = ({
    userOption = 0,
    stateAdministrators = [],
    stateUsers = [],
}) => {
    const {
        users,
        applicativeUsers,
    } = useSelector(store => ({
        users: store.UserReducer.users,
        applicativeUsers: store.UserReducer.applicativeUsers,
    }), shallowEqual)

    const allUsers = useMemo(() => [...users, ...applicativeUsers], [users, applicativeUsers])

    return (
        <Grid item>
            <Grid container justifyContent='center' alignItems='center' >
                <Grid item>
                    <Icon size='large' icon={'account_circle'} />
                </Grid>
                <Grid item style={{ paddingLeft: '5px' }}>
                    <StyledSpan>
                        {i18n.selectedUsers} : <span style={{ fontSize: '20px' }}>
                            {userOption === 0 ? allUsers.length : stateUsers.length + allUsers.filter(u => stateAdministrators.includes(u.contributorCode)).length}
                        </span>
                    </StyledSpan>
                    <Grid>{i18n.users} : <span style={{ fontSize: '20px' }}>{allUsers.length}</span></Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

UsersDisplay.propTypes = {
    userOption: PropTypes.number,
    allUsers: PropTypes.arrayOf(PropTypes.shape({})),
    stateAdministrators: PropTypes.arrayOf(PropTypes.number),
    stateUsers: PropTypes.arrayOf(PropTypes.shape({})),
}

export default UsersDisplay