import { push } from '@lagunovsky/redux-react-router'
import React from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import { getVigilanceColor } from '../../../utils/ColorUtil'
import { getMapStateToProps, getPropTypes } from '../../../utils/StoreUtils'
import { getText } from '../../../utils/VigilanceUtil'


const propsToFetch = {
    vigilances: false,
}

const NotifsVigilancePanel = ({
    vigilances = [],
    goTo,
}) => {
    if (vigilances.length !== 0) {
        return (
            <div className='col s12 no-padding notif-vigilance'>
                <div className='collection row no-margin'>
                    {
                        vigilances.slice(0, 5).filter(v => v.texts[0].lang === 'fr-FR'). map(vigilance => (
                            <div className={ `collection-item row no-padding valign-wrapper ${getVigilanceColor(vigilance.vigilance)}` }>
                                <div className='no-margin no-padding col s2 center-align'>
                                    <p className='center-align no-margin bold'>
                                        { [
                                            vigilance.info,
                                            <br/>,
                                        ] }
                                    </p>
                                </div>
                                <div className='no-padding col s10 white'>
                                    <h6 className='truncate title padding-left-1'>
                                        <b className='bold'>
                                            { vigilance.info }
                                        </b>
                                    </h6>
                                    <div className='truncate padding-left-1'>
                                        Du { vigilance.start } au { vigilance.end }
                                    </div>
                                    <div className='truncate padding-left-1'>
                                        { getText(vigilance.texts) }
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                    <div className='collection-item clickable center-align'>
                        <p
                            className='no-margin'
                            onClick={ () => goTo('/vigilances') }
                        >
                            { i18n.viewMore }
                        </p>
                    </div>
                </div>
            </div>
        )
    }
    return <div className='col s12'>{ i18n.noVigilancesToDisplay }</div>
}

NotifsVigilancePanel.propTypes = getPropTypes(propsToFetch)

const mapStateToProps = () => getMapStateToProps(propsToFetch)

const mapDispatchToProps = {
    goTo: push,
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(NotifsVigilancePanel)
