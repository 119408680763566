module.exports = {
    RECEIVE_RESOURCES: 'RECEIVE_RESOURCES',
    RECEIVE_RESOURCE: 'RECEIVE_RESOURCE',
    RESET_RESSOURCE: 'RESET_RESSOURCE',
    OPTIONS: {
        RESOURCE_START_PERIOD: 'RESOURCE_START_PERIOD',
        RESOURCE_END_PERIOD: 'RESOURCE_END_PERIOD',
        RESOURCE_NITRATES_THRESHOLD: 'RESOURCE_NITRATES_THRESHOLD',
        RESOURCE_PESTICIDE_THRESHOLD_1: 'RESOURCE_PESTICIDE_THRESHOLD_1',
        RESOURCE_PESTICIDE_THRESHOLD_2: 'RESOURCE_PESTICIDE_THRESHOLD_2',
        RESOURCE_SPECIFIC_THRESHOLD: 'RESOURCE_SPECIFIC_THRESHOLD',
        RESOURCE_PESTICIDES: 'RESOURCE_PESTICIDES',
        RESOURCE_LIST_SUM_PESTICIDES: 'RESOURCE_LIST_SUM_PESTICIDES',
        RESOURCE_PRODUCERS_EXCLUDED: 'RESOURCE_PRODUCERS_EXCLUDED',
    },
}
