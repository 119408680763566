import React from 'react'
import PropTypes from 'prop-types'
import { CircularProgress, circularProgressClasses } from '@mui/material'
import { isNil } from 'lodash'

/*
TODO: make a new component that manage a custom style and other functionnality
*/
const CircularProgressWithLabel = ({ value, ...props }) => (
    <div
        style={{
            position: 'absolute',
            left: '50%',
            top: '50%',
            zIndex: 11,
            display: 'inline-flex',
        }}
    >
        {
            !isNil(value) && (
                <div
                    style={{
                        height: '40',
                        width: '40',
                        top: '-20px',
                        left: '-20px',
                        fontSize: '12px',
                        color: 'black',
                        position: 'absolute',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: 'white',
                        borderRadius: '50%',
                    }}
                >
                    {`${Math.round(value)}%`}
                </div>
            )
        }
        <CircularProgress
            thickness={4}
            sx={{
                top: '-20px',
                left: '-20px',
                bottom: 0,
                right: 0,
                position: 'absolute',
                [`& .${circularProgressClasses.circle}`]: {
                    strokeLinecap: 'round',
                },
                [`& .${circularProgressClasses.svg}`]: {
                    backgroundColor: 'transparent',
                },
            }}
            {...props}
        />
    </div>
)

CircularProgressWithLabel.propTypes = {
    value: PropTypes.number,
    // props from CircularProgress
}

export default CircularProgressWithLabel