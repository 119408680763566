/* eslint-disable camelcase */
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import Table from '../../../components/datatable/Table'
import SieauAction from '../../../components/sieau/SieauAction'
import DtoDistributionUnit from '../../../distributionUnit/dto/DtoDistributionUnit'
import DtoHydrometricStation from '../../../hydrometry/dto/DtoHydrometricStation'
import DtoInstallation from '../../../installation/dto/installation/DtoInstallation'
import DtoPiezometer from '../../../piezometry/dto/DtoPiezometer'
import DtoProductionUnit from '../../../productionUnit/dto/DtoProductionUnit'
import DtoQualitometer from '../../../quality/dto/DtoQualitometer'
import { SANDRE } from '../../../referencial/constants/ReferencialConstants'
import DtoSandreCode from '../../../referencial/dto/DtoSandreCode'
import { getFullDate } from '../../../utils/DateUtil'
import { getExport, setModal } from '../../../utils/linkUtils'
import { arrayOf, getLabel } from '../../../utils/StoreUtils'
import { getSandreLabel } from '../../../utils/StringUtil'
import ArrangementModal from './modals/ArrangementModal'
import MaterielAction from '../../../materiel/actions/MaterielAction'
import { hasValue } from 'utils/NumberUtil'
import VariousMaterielAction from 'materiel/components/variousMateriel/actions/VariousMaterielAction'
import DtoVariousMateriel from 'station/dto/materiel/DtoVariousMateriel'
import { shallowEqual } from 'react-redux'

const StationArrangementPanel = ({
    station = {},
    sandreCodes = [],
    readMode = false,
    onChange = () => {},
    setPopup = () => {},
}) => {
    const dispatch = useDispatch()
    const [matKeys, setMatKeys] = useState([])

    const {
        variousMaterielTypes,
    } = useSelector(store => ({
        variousMaterielTypes: store.VariousMaterielReducer.variousMaterielTypes,
    }), shallowEqual)

    const idType = variousMaterielTypes.find(v => v.name === 'CLE')?.id

    useEffect(() => {
        if (!variousMaterielTypes.length) {
            dispatch(VariousMaterielAction.fetchVariousMaterielTypes())
        }
    }, [variousMaterielTypes])

    useEffect(() => {
        if (hasValue(idType)) {
            MaterielAction.promiseVariousMaterielFromType(idType).then(keys => setMatKeys(keys.map(k => new DtoVariousMateriel(k))))
        }
    }, [idType])

    const stationArrangements = station.link_arrangements.map((a, index) => ({
        ...a,
        index,
    }))
    const tableData = stationArrangements.map(c => ({
        ...c,
        startDate: getFullDate(c.startDate),
        endDate: getFullDate(c.endDate),
        type: getSandreLabel(sandreCodes, SANDRE.ARRANGEMENT_TYPES, c.type),
        description: c.descriptive,
        key: c.keyCode ? getLabel(matKeys, c.keyCode, 'reference', 'id') : undefined,
    }))
    const headers = ['startDate', 'endDate', 'description', 'value', 'type', 'key']
    const exportAction = getExport(tableData, i18n.arrangements, headers)
    const actions = !readMode ? [{
        iconName: 'note_add',
        tooltip: i18n.add,
        onClick: () => setModal(i18n.newArrangement, <ArrangementModal id={ station.id } saveResult={ r => onChange({ link_arrangements: [...stationArrangements, r] }) } matKeys={matKeys}/>, setPopup),
    }, exportAction] : [exportAction]

    return (
        <div id='arrangements'>
            <Table
                title={ i18n.arrangements }
                condensed
                data={ tableData }
                type={{ headers }}
                sortable={ !!tableData.length }
                actions={ actions }
                alterable={ !readMode }
                onAlter={
                    (element) => setModal(i18n.editArrangement,
                        <ArrangementModal
                            id={ station.id }
                            arrangement={ stationArrangements.find(a => element.index === a.index) }
                            saveResult={ r => onChange({
                                link_arrangements: [
                                    ...stationArrangements.filter(a => a.index !== element.index),
                                    { ...r, index: element.index },
                                ],
                            })}
                            matKeys={matKeys}
                        />
                        , setPopup)
                }
                deletable={ !readMode }
                onDelete={ (element) => onChange({ link_arrangements: stationArrangements.filter(a => a.index !== element.index) }) }
            />
        </div>
    )
}

StationArrangementPanel.propTypes = {
    station: PropTypes.oneOfType([
        PropTypes.instanceOf(DtoProductionUnit),
        PropTypes.instanceOf(DtoDistributionUnit),
        PropTypes.instanceOf(DtoQualitometer),
        PropTypes.instanceOf(DtoPiezometer),
        PropTypes.instanceOf(DtoHydrometricStation),
        PropTypes.instanceOf(DtoInstallation),
    ]),
    sandreCodes: arrayOf(DtoSandreCode),
    readMode: PropTypes.bool,
    onChange: PropTypes.func,
    setPopup: PropTypes.func,
}

const mapStateToProps = store => ({
    sandreCodes: store.ReferencialReducer.sandreCodes,
})

const mapDispatchToProps = {
    setPopup: SieauAction.setPopup,
}

export default connect(mapStateToProps, mapDispatchToProps)(StationArrangementPanel)