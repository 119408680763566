import { Button, Dialog } from '@mui/material'
import { DialogActionsMUI, DialogContentMUI, DialogTitleMUI } from 'components/styled/Dialog'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import DtoCultureVentilation from 'referencial/components/cultures/dto/DtoCultureVentilation'
import i18n from 'simple-react-i18n'
import ToastrAction from 'toastr/actions/ToastrAction'
import { hasValue } from 'utils/NumberUtil'
import Icon from '../../../components/icon/Icon'
import CultureVentilationPanel from '../panel/CultureVentilationPanel'

class CultureVentilationModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            cultureVentilation: props.cultureVentilation,
        }
    }

    onChangeValue = (obj) => {
        this.setState(({ cultureVentilation }) => ({
            cultureVentilation: {
                ...cultureVentilation,
                ...obj,
            },
        }))
    }

    onCancel = () => {
        this.setState({ cultureVentilation: null })
        this.props.onCancel()
    }

    hasRequiredValue = (cultureVentilation) => {
        if (hasValue(cultureVentilation.year) &&
            hasValue(cultureVentilation.cultureId)) {
            return true
        }
        return false
    }

    onValidate = () => {
        const { cultureVentilation } = this.state
        if (!this.hasRequiredValue(cultureVentilation)) {
            this.props.warning(i18n.pleaseCompleteAllRequiredField)
        } else if (cultureVentilation.total > 100) {
            this.props.warning(i18n.totalOver100Percent)
        } else {
            this.props.onValidate(cultureVentilation)
        }
    }

    render = () => {
        const { open, title } = this.props
        const { cultureVentilation } = this.state

        return (
            <Dialog
                onClose={() => this.setState({ open: false })}
                maxWidth='md'
                open={open}
            >
                <DialogTitleMUI style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    {title || i18n.culturesSettings}
                    <Icon style={{ color: 'white' }} size='small' icon='close' onClick={this.onCancel} />
                </DialogTitleMUI>
                <DialogContentMUI>
                    <CultureVentilationPanel
                        cultureVentilation={cultureVentilation}
                        onChange={this.onChangeValue}
                    />
                </DialogContentMUI>
                <DialogActionsMUI>
                    <Button onClick={this.onValidate} variant='contained' color='primary'>
                        {i18n.validate}
                    </Button>
                </DialogActionsMUI>
            </Dialog>
        )
    }
}

CultureVentilationModal.propTypes = {
    open: PropTypes.bool,
    title: PropTypes.string,
    cultureVentilation: PropTypes.instanceOf(DtoCultureVentilation),
    onCancel: PropTypes.func.isRequired,
    onValidate: PropTypes.func.isRequired,
    warning: PropTypes.func,
}

const mapDispatchToProps = {
    warning: ToastrAction.warning,
}

export default connect(null, mapDispatchToProps)(CultureVentilationModal)