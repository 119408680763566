import { userActions } from 'administration/components/user/reducers/UserReducer'
import ApplicationConf from 'conf/ApplicationConf'
import fetch from 'isomorphic-fetch'
import { QualityActionConstant } from 'quality/reducers/QualityReducer'
import { ContactActionConstant } from 'referencial/components/contact/reducers/ContactReducer'
import { ContributorActionConstant } from 'referencial/components/contributor/reducers/ContributorReducer'
import i18n from 'simple-react-i18n'
import ToastrAction from 'toastr/actions/ToastrAction'
import {
    checkAuth,
    genericCreatePromise,
    genericDeletePromise,
    genericPromise, genericPromise2,
    genericUpdatePromise,
    getJson,
} from 'utils/ActionUtils'
import WaitAction from 'wait/WaitAction'
import UserAction from '../../../../administration/components/user/actions/UserAction'
import LogAction from '../../../../log/actions/LogAction'
import ContactAction from '../../../../referencial/components/contact/actions/ContactAction'
import ContributorAction from '../../../../referencial/components/contributor/actions/ContributorAction'
import FractionAction from '../../../../referencial/components/fraction/actions/FractionAction'
import MethodAction from '../../../../referencial/components/methods/actions/MethodAction'
import ParameterAction from '../../../../referencial/components/parameter/actions/ParameterAction'
import SupportAction from '../../../../referencial/components/support/actions/SupportAction'
import UnitAction from '../../../../referencial/components/unit/actions/UnitAction'
import AppStore from '../../../../store/AppStore'
import { checkError, getAuthorization, promiseAllProgress } from '../../../../utils/ActionUtils'
import QualityAction from '../../../actions/QualityAction'
import {
    RECEIVE_ALL_FRACTIONS,
    RECEIVE_ALL_HYDROBIO_LIST,
    RECEIVE_ALL_QUALITOMETER_OPERATIONS,
    RECEIVE_ALL_REMARKS,
    RECEIVE_CALCULATE_PARAMETERS,
    RECEIVE_ENVIRONMENTAL_CONDITION,
    RECEIVE_HYDROBIO_LIST,
    RECEIVE_HYDROBIO_OPERATION,
    RECEIVE_HYDROBIO_OPERATIONS,
    RECEIVE_OPERATION,
    RECEIVE_OPERATION_ANALYSIS,
    RECEIVE_OPERATION_FILES,
    RECEIVE_OPERATION_INDICES,
    RESET_ANALYSIS_OPERATION,
    RESET_OPERATION,
    RESET_SINGLE_OPERATION,
} from '../constants/OperationConstants'
import { flatten, uniq } from 'lodash'
import DtoSampling from '../dto/DtoSampling'

const OperationAction = {
    receiveOperation: operation => ({ type: RECEIVE_OPERATION, operation }),

    promiseOperation: (id, idStation) => fetch(ApplicationConf.operation.get(id, idStation), {
        method: 'GET',
        headers: getAuthorization(),
    })
        .then(checkAuth)
        .then(getJson)
        .then(checkError),

    fetchOperation: (id, idStation) => dispatch => OperationAction.promiseOperation(id, idStation)
        .then(json => {
            dispatch(OperationAction.receiveOperation(json))
            return json
        }),

    createOperation: (stationId, operation, sample) => genericCreatePromise(genericPromise(ApplicationConf.operation.stationOperations(stationId), 'POST', { operation, sample })),

    updateOperation: (operation, sample) => genericUpdatePromise(genericPromise(ApplicationConf.operation.get(operation.id, operation.qualitometer), 'PUT', { operation, sample })),

    deleteOperation: (stationId, operation, sample) => genericDeletePromise(genericPromise(ApplicationConf.operation.get(operation.id, operation.qualitometer), 'DELETE', { operation, sample })),

    receiveEnvironmentalConditions: environmentalConditions => ({ type: RECEIVE_ENVIRONMENTAL_CONDITION, payload: environmentalConditions }),

    promiseEnvironmentalConditions: (idQualitometer, idOperation) => fetch(ApplicationConf.qualitometer.environmentalConditions(idQualitometer, idOperation), {
        method: 'GET',
        headers: getAuthorization(),
    })
        .then(checkAuth)
        .then(getJson)
        .then(checkError),

    fetchEnvironmentalConditions: (idQualitometer, idOperation) => dispatch => OperationAction.promiseEnvironmentalConditions(idQualitometer, idOperation)
        .then(json => {
            dispatch(OperationAction.receiveEnvironmentalConditions(json))
            return json
        }),

    createEnvironmentalCondition: (idQualitometer, idOperation, condition) => dispatch => {
        dispatch(WaitAction.waitStart())
        return fetch(ApplicationConf.qualitometer.environmentalConditions(idQualitometer, idOperation), {
            method: 'POST',
            headers: getAuthorization(),
            body: JSON.stringify(condition),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
            .then(json => {
                dispatch(WaitAction.waitStop())
                if (json.insert === 1) {
                    dispatch(ToastrAction.success(i18n.elementCreateSuccess))
                    dispatch(OperationAction.fetchEnvironmentalConditions(idQualitometer, idOperation))
                } else {
                    throw new Error('Error during create')
                }
            })
            .catch(err => {
                dispatch(LogAction.logError(`${i18n.createError + i18n.operation} : ${err}`))
                dispatch(ToastrAction.error(i18n.createError + i18n.operation))
            })
    },

    updateEnvironmentalCondition: (idQualitometer, idOperation, idCondition, condition) => dispatch => {
        dispatch(WaitAction.waitStart())
        return fetch(ApplicationConf.qualitometer.environmentalCondition(idQualitometer, idOperation, idCondition), {
            method: 'PUT',
            headers: getAuthorization(),
            body: JSON.stringify(condition),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
            .then(json => {
                dispatch(WaitAction.waitStop())
                if (json.update === 1) {
                    dispatch(ToastrAction.success(i18n.elementUpdateSuccess))
                    dispatch(OperationAction.fetchEnvironmentalConditions(idQualitometer, idOperation))
                } else {
                    throw new Error('Error during update')
                }
            })
            .catch(err => {
                dispatch(LogAction.logError(`${i18n.updateError + i18n.operation} : ${err}`))
                dispatch(ToastrAction.error(i18n.updateError + i18n.operation))
            })
    },

    deleteEnvironmentalCondition: (idQualitometer, idOperation, idCondition) => dispatch => {
        dispatch(WaitAction.waitStart())
        return fetch(ApplicationConf.qualitometer.environmentalCondition(idQualitometer, idOperation, idCondition), {
            method: 'DELETE',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
            .then(json => {
                dispatch(WaitAction.waitStop())
                if (json.delete === 1) {
                    dispatch(ToastrAction.success(i18n.elementDeleteSuccess))
                    dispatch(OperationAction.fetchEnvironmentalConditions(idQualitometer, idOperation))
                } else {
                    throw new Error('Error during delete')
                }
            })
            .catch(err => {
                dispatch(LogAction.logError(`${i18n.deleteError + i18n.operation} : ${err}`))
                dispatch(ToastrAction.error(i18n.deleteError + i18n.operation))
            })
    },

    fetchIndices: (qualitometerId, operationId, callback = () => { }) => dispatch => OperationAction.promiseIndices(qualitometerId, operationId)
        .then(json => {
            dispatch(OperationAction.receiveIndices(json))
            callback()
        }),
    promiseIndices: (qualitometerId, operationId) => fetch(ApplicationConf.operation.indices(qualitometerId, operationId), {
        method: 'GET',
        headers: getAuthorization(),
    })
        .then(checkAuth)
        .then(getJson)
        .then(checkError),
    receiveIndices: indices => ({ type: RECEIVE_OPERATION_INDICES, indices }),
    updateOperationIndex: (index, method, callback = () => { }) => dispatch => {
        dispatch(WaitAction.waitStart())
        return fetch(ApplicationConf.operation.indices(index.qualitometerId, index.operationId), {
            method, // PUT, POST or DELETE
            headers: getAuthorization(),
            body: JSON.stringify(index),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
            .then(json => {
                dispatch(WaitAction.waitStop())
                if (json.update >= 1 || json.insert >= 1 || json.delete >= 1) {
                    AppStore.dispatch(OperationAction.fetchIndices(index.qualitometerId, index.operationId, () => {
                        AppStore.dispatch(ToastrAction.success(i18n.elementUpdateSuccess))
                        callback()
                    }))
                } else {
                    throw new Error('Error during update')
                }
            })
            .catch(err => {
                dispatch(LogAction.logError(`${i18n.updateError + i18n.indice} : ${err}`))
                dispatch(ToastrAction.error(i18n.updateError + i18n.indice))
            })
    },
    receiveOperations: operations => ({ type: RECEIVE_ALL_QUALITOMETER_OPERATIONS, operations }),
    fetchQualitometerOperations: id => dispatch => fetch(ApplicationConf.qualitometer.getOperations(id), {
        method: 'GET',
        headers: getAuthorization(),
    })
        .then(checkAuth)
        .then(getJson)
        .then(json => dispatch(OperationAction.receiveOperations(json))),

    receiveQualitometerHydrobioOperations: hydrobioOperations => ({ type: RECEIVE_HYDROBIO_OPERATIONS, payload: hydrobioOperations }),
    promiseQualitometerHydrobioOperations: id => genericPromise(ApplicationConf.qualitometer.hydrobioOperations(id)),
    fetchQualitometerHydrobioOperations: id => dispatch => OperationAction.promiseQualitometerHydrobioOperations(id).then(json => {
        dispatch(OperationAction.receiveQualitometerHydrobioOperations(json))
    }),

    getAllHydrobioOperation: (stationIds, progressCallback = () => {}) => () => {
        const operationPromise = stationIds.map(id => OperationAction.promiseQualitometerHydrobioOperations(id))
        return promiseAllProgress(operationPromise, progressCallback).then(jsonTab => {
            return flatten(jsonTab)
        }).catch(() => [])
    },

    receiveQualitometerHydrobioOperation: hydrobioOperation => ({ type: RECEIVE_HYDROBIO_OPERATION, payload: hydrobioOperation }),
    promiseQualitometerHydrobioOperation: (idStation, idOperation) => genericPromise(ApplicationConf.qualitometer.hydrobioOperation(idStation, idOperation)),
    fetchQualitometerHydrobioOperation: (idStation, idOperation) => dispatch => OperationAction.promiseQualitometerHydrobioOperation(idStation, idOperation).then(json => {
        dispatch(OperationAction.receiveQualitometerHydrobioOperation(json))
    }),

    receiveHydrobioList: hydrobioList => ({ type: RECEIVE_HYDROBIO_LIST, payload: hydrobioList }),
    promiseHydrobioList: (idStation, idOperation) => genericPromise(ApplicationConf.qualitometer.hydrobioLists(idStation, idOperation)),
    fetchHydrobioList: (idStation, idOperation) => dispatch => OperationAction.promiseHydrobioList(idStation, idOperation).then(json => {
        dispatch(OperationAction.receiveHydrobioList(json))
    }),

    receiveAllHydrobioList: hydrobioLists => ({ type: RECEIVE_ALL_HYDROBIO_LIST, payload: hydrobioLists }),
    promiseAllHydrobioList: (idStation) => genericPromise(ApplicationConf.qualitometer.qualitometerHydrobioList(idStation)),
    fetchAllHydrobioList: (idStation) => dispatch => OperationAction.promiseAllHydrobioList(idStation).then(json => {
        dispatch(OperationAction.receiveAllHydrobioList(json))
    }),

    receiveAnalysis: operationAnalysis => ({ type: RECEIVE_OPERATION_ANALYSIS, operationAnalysis }),
    promiseSingleAnalysis: (idQualito, idOperation, analysisId) => QualityAction.promiseSearchAnalysis({ stations: [idQualito], operations: [idOperation], analysisId }, false),
    promiseAnalysis: (idOperation, idQualito, threshold) => QualityAction.promiseSearchAnalysis({ stations: [idQualito], operations: [idOperation], threshold, prettyMode: true }),
    fetchAnalysis: (idOperation, idQualito, threshold) => dispatch => QualityAction.promiseSearchAnalysis({ stations: [idQualito], operations: [idOperation], threshold, prettyMode: true })
        .then((json = []) => {
            dispatch(OperationAction.receiveAnalysis(json))
            return json
        })
        .catch(err => {
            dispatch(LogAction.logError(`${i18n.fetchError + i18n.analysis} : ${err}`))
            dispatch(ToastrAction.error(i18n.fetchError + i18n.analysis))
        }),
    createAnalysis: (analysis) => genericPromise(ApplicationConf.operation.analysisCreate(), 'POST', analysis)
        .catch(err => {
            AppStore.dispatch(LogAction.logError(`${i18n.createError + i18n.analysis} : ${err}`))
            AppStore.dispatch(ToastrAction.error(i18n.createError + i18n.analysis))
        }),
    updateAnalysis: (analysis) => genericPromise(ApplicationConf.operation.analysisUpdate(), 'PUT', analysis)
        .catch(err => {
            AppStore.dispatch(LogAction.logError(`${i18n.updateError + i18n.analysis} : ${err}`))
            AppStore.dispatch(ToastrAction.error(i18n.updateError + i18n.analysis))
        }),
    deleteAnalysis: (analysis) => genericPromise(ApplicationConf.operation.analysisDelete(), 'DELETE', analysis)
        .catch(err => {
            AppStore.dispatch(LogAction.logError(`${i18n.deleteError + i18n.analysis} : ${err}`))
            AppStore.dispatch(ToastrAction.error(i18n.deleteError + i18n.analysis))
        }),
    createAnalysisList: (stationId, operationId, analysisList) => dispatch => fetch(ApplicationConf.qualitometer.analysisList(stationId, operationId), {
        method: 'POST',
        headers: getAuthorization(),
        body: JSON.stringify(analysisList),
    })
        .then(checkAuth)
        .then(getJson)
        .then(checkError)
        .catch(err => {
            dispatch(LogAction.logError(`${i18n.createError + i18n.analysis} : ${err}`))
            dispatch(ToastrAction.error(i18n.createError + i18n.analysis))
        }),
    receiveRemarks: remarks => ({ type: RECEIVE_ALL_REMARKS, remarks }),
    promiseRemarks: () => fetch(ApplicationConf.referencial.remark(), {
        method: 'GET',
        headers: getAuthorization(),
    })
        .then(checkAuth)
        .then(getJson)
        .then(checkError),

    fetchRemarks: () => (dispatch, getState) => {
        const {
            OperationReducer: { remarks },
        } = getState()
        if (!remarks.length) {
            return OperationAction.promiseRemarks()
                .then((json = []) => {
                    dispatch(OperationAction.receiveRemarks(json))
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.remarkCode} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError + i18n.remarkCode))
                })
        }
        return Promise.resolve()
    },

    receiveFractions: fractions => ({ type: RECEIVE_ALL_FRACTIONS, fractions }),
    fetchFractions: () => dispatch => fetch(ApplicationConf.referencial.fractions(), {
        method: 'GET',
        headers: getAuthorization(),
    })
        .then(checkAuth).then(getJson)
        .then((json = []) => {
            dispatch(OperationAction.receiveFractions(json))
        }).then(() => {
            dispatch(WaitAction.waitStop())
        })
        .catch(err => {
            dispatch(LogAction.logError(`${i18n.fetchError + i18n.fractions} : ${err}`))
            dispatch(ToastrAction.error(i18n.fetchError + i18n.fractions))
        }),
    receiveCalculateParameters: calculateParameters => ({ type: RECEIVE_CALCULATE_PARAMETERS, calculateParameters }),
    promiseCalculateParameters: () => fetch(ApplicationConf.referencial.calculateParameters(), {
        method: 'GET',
        headers: getAuthorization(),
    })
        .then(checkAuth)
        .then(getJson)
        .then(checkError),
    fetchCalculateParameters: () => dispatch => OperationAction.promiseCalculateParameters()
        .then((json = []) => {
            dispatch(OperationAction.receiveCalculateParameters(json))
        }).catch(err => {
            dispatch(LogAction.logError(`${i18n.errorCalculateParameters} : ${err}`))
            dispatch(ToastrAction.error(i18n.errorCalculateParameters))
        }),
    calculateParametersOperation: (idQualito, idsOperations) => dispatch => {
        dispatch(WaitAction.waitStart())
        return fetch(ApplicationConf.qualitometer.calculateParameter(idQualito, idsOperations), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then((json = []) => {
                dispatch(ToastrAction.success(`Nb insertion : ${json.insert}<br/>Déjà calculé : ${json.alreadyExist}`))
                dispatch(OperationAction.fetchQualitometerOperations(idQualito))
                if (idsOperations.length === 1) {
                    dispatch(OperationAction.resetAnalysisOperation())
                    dispatch(OperationAction.fetchAnalysis(idsOperations[0], idQualito)) // TODO : changer pour la migration qualité ?
                }
                dispatch(WaitAction.waitStop())
            }).catch(err => {
                dispatch(LogAction.logError(`${i18n.errorCalculateParameters} : ${err}`))
                dispatch(ToastrAction.error(i18n.errorCalculateParameters))
            })
    },
    calculateSEEE: (idQualito, idsOperations = [], options = []) => dispatch => {
        dispatch(WaitAction.waitStart())
        return fetch(ApplicationConf.qualitometer.calculateSEEE(idQualito, idsOperations, options), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then((json = []) => {
                dispatch(ToastrAction.success(`Nb insertion : ${json.value}`))
                if (idsOperations.length === 1) {
                    dispatch(OperationAction.fetchIndices(idQualito, idsOperations[0]))
                        .finally(() => dispatch(WaitAction.waitStop()))
                } else {
                    dispatch(WaitAction.waitStop())
                }
            }).catch(err => {
                dispatch(LogAction.logError(`${i18n.errorCalculateParameters} : ${err}`))
                dispatch(ToastrAction.error(i18n.errorCalculateParameters))
            })
    },
    receiveFiles: files => ({ type: RECEIVE_OPERATION_FILES, files }),
    promiseFiles: (idStation, idOperation) => fetch(ApplicationConf.files.operationFiles(idStation, idOperation), {
        method: 'GET',
        headers: getAuthorization(),
    })
        .then(checkAuth)
        .then(getJson)
        .then(checkError),
    fetchFiles: (idStation, idOperation) => dispatch => OperationAction.promiseFiles(idStation, idOperation)
        .then((json = []) => {
            dispatch(OperationAction.receiveFiles(uniq(json)))
        })
        .catch((err) => {
            dispatch(LogAction.logError(`${i18n.fetchError + i18n.files} : ${err}`))
            dispatch(ToastrAction.error(i18n.fetchError + i18n.files))
        }),
    loadOperationApp: (id, idStation, threshold, progressCallback = () => { }) => dispatch => {
        const operationPromise = id !== 'new' ? [
            OperationAction.promiseOperation(id, idStation),
            OperationAction.promiseAnalysis(parseInt(id), parseInt(idStation), threshold),
            OperationAction.promiseIndices(idStation, id),
            OperationAction.promiseEnvironmentalConditions(idStation, id),
            OperationAction.promiseSample(idStation, id),
        ] : []
        return promiseAllProgress([
            QualityAction.promiseThresholds(),
            OperationAction.promiseRemarks(),
            OperationAction.promiseCalculateParameters(),
            QualityAction.promiseQualifications(),
            QualityAction.promiseStatus(),
            SupportAction.promiseSupports(),
            MethodAction.promiseMethods(),
            UnitAction.promiseUnits(),
            ContributorAction.promiseContributors(),
            ContributorAction.promiseProducers(),
            ContactAction.promiseContacts(),
            FractionAction.promiseFractions(),
            ParameterAction.promiseParameters(),
            UserAction.promiseUsers(),
            ContributorAction.promiseLaboratories(),
            ContributorAction.promiseDeterminators(),
            ...operationPromise,
        ], progressCallback)
            .then(jsonTab => {
                dispatch(QualityActionConstant.receiveThresholds(jsonTab[0]))
                dispatch(OperationAction.receiveRemarks(jsonTab[1]))
                dispatch(OperationAction.receiveCalculateParameters(jsonTab[2]))
                dispatch(QualityActionConstant.receiveQualifications(jsonTab[3]))
                dispatch(QualityActionConstant.receiveStatus(jsonTab[4]))
                dispatch(SupportAction.receiveSupports(jsonTab[5]))
                dispatch(MethodAction.receiveMethods(jsonTab[6]))
                dispatch(UnitAction.receiveUnits(jsonTab[7]))
                dispatch(ContributorActionConstant.receiveAllContributors(jsonTab[8]))
                dispatch(ContributorActionConstant.receiveProducers(jsonTab[9]))
                dispatch(ContactActionConstant.receiveAllContacts(jsonTab[10]))
                dispatch(FractionAction.receiveFractions(jsonTab[11]))
                dispatch(ParameterAction.receiveParameters(jsonTab[12]))
                dispatch(userActions.receiveUsers(jsonTab[13]))
                dispatch(ContributorActionConstant.receiveLaboratories(jsonTab[14]))
                dispatch(ContributorActionConstant.receiveDeterminators(jsonTab[15]))
                if (id !== 'new') {
                    dispatch(OperationAction.receiveOperation(jsonTab[16]))
                    dispatch(OperationAction.receiveAnalysis(jsonTab[17]))
                    dispatch(OperationAction.receiveIndices(jsonTab[18]))
                    dispatch(OperationAction.receiveEnvironmentalConditions(jsonTab[19]))
                }
                return id !== 'new' ? jsonTab[20] : {}
            }).catch((err) => {
                dispatch(LogAction.logError(`${i18n.loadError} : ${err}`))
                dispatch(ToastrAction.error(i18n.loadError))
            })
    },
    loadHydrobioOperations: (id, onProgress = () => {}) => dispatch => {
        return promiseAllProgress([
            OperationAction.promiseQualitometerHydrobioOperations(id),
            SupportAction.promiseSupports(),
            MethodAction.promiseMethods(),
            QualityAction.promiseStatus(),
            QualityAction.promiseQualifications(),
        ], onProgress).then(jsonTab => {
            dispatch(OperationAction.receiveQualitometerHydrobioOperations(jsonTab[0]))
            dispatch(SupportAction.receiveSupports(jsonTab[1]))
            dispatch(MethodAction.receiveMethods(jsonTab[2]))
            dispatch(QualityActionConstant.receiveStatus(jsonTab[3]))
            dispatch(QualityActionConstant.receiveQualifications(jsonTab[4]))
        })
    },

    promiseSample: (qualitoId, operationId) => genericPromise2(ApplicationConf.operation.sample(qualitoId, operationId)),

    promiseSampleSamplings: (qualitoId, numSample) => genericPromise2(ApplicationConf.operation.samplings(qualitoId, numSample)).then(json => json.map(j => new DtoSampling(j))),
    promiseUpdateSampleSamplings: (qualitoId, numSample, samplings) => genericUpdatePromise(genericPromise2(ApplicationConf.operation.samplings(qualitoId, numSample), { method:'POST', body: samplings })),

    promiseOperationNetworks: (qualitoId, operationId) => genericPromise2(ApplicationConf.operation.networks(qualitoId, operationId)),
    promiseUpdateOperationNetworks: (qualitoId, operationId, networks) => genericUpdatePromise(genericPromise2(ApplicationConf.operation.networks(qualitoId, operationId), { method: 'POST', body: networks })),

    reset: () => {
        return {
            type: RESET_OPERATION,
        }
    },
    fetchReset: () => {
        return (dispatch) => {
            dispatch(OperationAction.reset())
        }
    },
    resetSingle: () => {
        return {
            type: RESET_SINGLE_OPERATION,
        }
    },
    fetchResetSingle: () => {
        return (dispatch) => {
            dispatch(OperationAction.resetSingle())
        }
    },
    resetAnalysisOperation: () => {
        return {
            type: RESET_ANALYSIS_OPERATION,
        }
    },

}

export default OperationAction
