import { maxBy } from 'lodash'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import AlertPanel from '../../../../components/alert/AlertPanel'
import Card from '../../../../components/card/Card'
import ListComponent from '../../../../components/list/tableList/ListComponent'
import { getColorCircleElement } from '../../../../utils/ColorUtil'
import { getDateWithHourString } from '../../../../utils/DateUtil'
import { getControlAlert } from '../../../../utils/PiezometryUtils'
import DtoPiezometer from '../../../dto/DtoPiezometer'
import DtoMeasureStats from '../../../../station/dto/piezometer/DtoMeasureStats'
import { arrayOf } from '../../../../utils/StoreUtils'
import { round } from '../../../../utils/NumberUtil'
import AppStore from '../../../../store/AppStore'
import DtoDataType from '../../../../station/dto/DtoDataType'
import StationAction from '../../../../station/actions/StationAction'
import { execByType } from '../../../../utils/StationUtils'

class DatasAlertPanel extends Component {
    constructor(props) {
        super(props)
        if (!props.piezoDataTypes.length) {
            AppStore.dispatch(StationAction.fetchDataTypesByProject('SIES'))
        }
    }

    getControlErrors = () => {
        if (this.props.station && this.props.station.controlErrors?.length) {
            const componentObj = {
                title: (
                    <div className='row no-margin'>
                        <AlertPanel comment={ `${this.props.station.controlErrors?.length} ${i18n.errors}` }
                            title={ 'Erreurs de cohérence' }
                            icon={ 'warning' } color={ 'red' }
                            round={this.props.round}
                        />
                    </div>
                ),
                component: (
                    <Card>
                        { this.props.station.controlErrors.map(err => getControlAlert(err)) }
                    </Card>
                ),
            }
            return (
                <ListComponent tables={ [componentObj] } accordion displayBlock round={this.props.round}/>
            )
        }
        return null
    }

    getAlertPanel = (alerts) => {
        const worst = maxBy(alerts, 'alert')
        const componentObj = {
            title: (
                <div className='row no-margin'>
                    <AlertPanel comment={ `${i18n.noData} ${i18n.since} ${worst.alertHours < 24 ? worst.alertHours : worst.alert} ${worst.alertHours < 24 ? i18n.hours : i18n.days}` }
                        title={ `[${this.props.station.code}] - ${this.props.station.name}` }
                        icon={ 'warning' } color={ worst.color }
                        round={this.props.round}
                    />
                </div>
            ),
            component: (
                <Card>
                    { alerts.map(this.getAlertLine) }
                </Card>
            ),
        }
        return (
            <ListComponent tables={ [componentObj] } accordion displayBlock round={this.props.round} />
        )
    }

    getAlertLine = alert => (
        <div className='row no-margin'>
            <span className='padding-left-1'>{ getColorCircleElement(alert.color, true) }</span>
            <span className='bold'>{ getDateWithHourString(alert.date) }</span>
            <span className='padding-left-1'>{ alert.label }</span>
        </div>
    )

    getDt = () => {
        return execByType(this.props.stationType, {
            piezometry: () => this.props.piezoDataTypes,
            hydrometry: () => this.props.hydroDataTypes,
            pluviometry: () => this.props.pluvioDataTypes,
        })
    }

    getAlerts = () => {
        return this.props.stats.flatMap(stat => {
            const dt = this.getDt().find(d => d.id === stat.typeId)
            if (!dt || !stat.endDate) {
                return []
            }
            if (dt.warningLimit || dt.alertLimit) { // alert is active
                const warning = moment().subtract(dt.warningLimit || 0, 'hours').valueOf() > stat.endDate
                const alert = moment().subtract(dt.alertLimit || 0, 'hours').valueOf() > stat.endDate
                if (alert || warning) { // there is alert !
                    const alertHours = moment.duration(moment().diff(moment(stat.endDate))).asHours()
                    return {
                        color: alert ? 'red' : 'orange',
                        label: dt.name,
                        alertHours,
                        alert: round(alertHours / 24, 0),
                        date: stat.endDate,
                    }
                }
                return []
            }
            return []
        })
    }

    render() {
        if (this.props.controlErrorsOnly) {
            return (
                <div>
                    { this.getControlErrors() }
                </div>
            )
        }

        const alerts = this.getAlerts()

        if (!alerts.length && !this.props.station?.controlErrors?.length) {
            return null
        }

        return (
            <div className='margin-bottom-1'>
                { alerts.length ? this.getAlertPanel(alerts) : null }
                { this.getControlErrors() }
            </div>
        )
    }
}

DatasAlertPanel.propTypes = {
    stationType: PropTypes.string,
    stats: arrayOf(DtoMeasureStats),
    piezoDataTypes: arrayOf(DtoDataType),
    hydroDataTypes: arrayOf(DtoDataType),
    pluvioDataTypes: arrayOf(DtoDataType),
    station: PropTypes.instanceOf(DtoPiezometer),
    controlErrorsOnly: PropTypes.bool,
    round: PropTypes.bool,
}

const mapStateToProps = store => {
    return {
        piezoDataTypes: store.StationReducer.piezoDataTypes,
        hydroDataTypes: store.StationReducer.hydroDataTypes,
        pluvioDataTypes: store.StationReducer.pluvioDataTypes,
    }
}
export default connect(mapStateToProps)(DatasAlertPanel)
