export default class DtoTerritoryPollutantsEmissions {
    constructor(obj) {
        this.source = obj.source
        this.category = obj.category
        this.section = obj.section
        this.activityCode = obj.activityCode
        this.activity = obj.activity
        this.parameterCode = obj.parameterCode
        this.parameter = obj.parameter
        this.nbMeasurementsCouple = obj.nbMeasurementsCouple
        this.medianDailyFlows = obj.medianDailyFlows
        this.standardDeviationDailyFlows = obj.standardDeviationDailyFlows
        this.nbMeasurementsCoupleWithNbEmployees = obj.nbMeasurementsCoupleWithNbEmployees
        this.slopeRegression = obj.slopeRegression
        this.constantRegression = obj.constantRegression
        this.rSquare = obj.rSquare
        this.headers = ['source', 'category', 'section', 'activityCode', 'activity', 'parameterCode', 'parameter', 'nbMeasurementsCouple', 'medianDailyFlows', 'standardDeviationDailyFlows', 'nbMeasurementsCoupleWithNbEmployees', 'slopeRegression', 'constantRegression', 'rSquare']
    }
}