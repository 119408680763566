import { push } from '@lagunovsky/redux-react-router'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import DtoStation from '../../station/dto/DtoStation'
import AppStore from '../../store/AppStore'

const $ = window.$

class PopupContent extends Component {
    constructor(props) {
        super(props)
        this.state = {
            selected: '',
            selectedStation: {},
            selectedLayer: {},
        }
        // DO NOT REMOVE THESE .bind(this) !!!
        this.pushToSituation = this.pushToSituation.bind(this)
        this.pushToSieau = this.pushToSieau.bind(this)
    }

    getFeatureProxy(feature) {
        const handler = {
            get: (target, name) => {
                // You must use .get to access to props of feature.
                if (name === 'id') {
                    return target.getId()
                }
                return target.get(name)
            },
        }
        return new Proxy(feature, handler)
    }

    componentWillMount() {
        const feature = this.getFeatureProxy(this.props.features[0])
        const lay = this.props.layers.find(la => la && la.checkUuid(this.props.features[0].getId()))
        if (lay) {
            this.setState({ selectedLayer: { lay, feature }, selectedStation: lay?.getStation(lay, feature) })
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.features) {
            if (this.props.features.length > 1) {
                this.props.features.forEach(element => {
                    const feature = this[`couche${element.getId()}`]
                    if (feature) {
                        feature.removeEventListener('click', this.handlerListener(element))
                    }
                })
            }
            if (nextProps.layers.filter(l => !!l).length !== 0) {
                const lay = nextProps.layers.find(la => la.checkUuid(nextProps.features[0].getId()))
                if (lay) {
                    const feature = this.getFeatureProxy(nextProps.features[0])
                    this.setState({ selectedLayer: { lay, feature }, selectedStation: lay?.getStation(lay, feature) })
                }
            }
        }
    }

    componentDidMount() {
        const { selectedStation } = this.state
        this.createListener()
        if ($('.popupStation').length) {
            if (!selectedStation.fromSituation && selectedStation.typeName === 'contact' || selectedStation.typeName === 'contributor') {
                $('.popupStation')[0].addEventListener('click', () => AppStore.dispatch(push(`/referencial/${selectedStation.typeName}/${selectedStation.id}`)))
            } else if (!selectedStation.fromSituation) {
                $('.popupStation')[0].addEventListener('click', () => AppStore.dispatch(push(`/station/${selectedStation.typeName}/${selectedStation.id}`)))
            }
        }
    }

    componentDidUpdate(prevProps) {
        // no props check create too much listener
        if (prevProps.features !== this.props.features) {
            this.createListener()
        }
    }

    createListener = () => {
        const { features } = this.props
        const { selectedStation } = this.state
        if (features.length > 1) {
            features.forEach((element) => {
                // React can't trigger click on the map. You must use EventListener, after the DOM render.
                const feature = this[`couche${element.getId()}`]
                if (feature) {
                    feature.removeEventListener('click', this.handlerListener(element))
                    feature.addEventListener('click', this.handlerListener(element))
                }
            })
        }
        const stationPopup = $('.popupStation')
        if (stationPopup.length) {
            if (selectedStation.fromSituation) {
                stationPopup[0].removeEventListener('click', this.pushToSituation, true)
                stationPopup[0].addEventListener('click', this.pushToSituation, true)
            } else if (selectedStation.onClickPopup) {
                stationPopup[0].removeEventListener('click', selectedStation.onClickPopup, true)
                stationPopup[0].addEventListener('click', selectedStation.onClickPopup, true)
            } else {
                stationPopup[0].removeEventListener('click', this.pushToSieau, true)
                stationPopup[0].addEventListener('click', this.pushToSieau, true)
            }
        }
    }

    pushToSituation = () => {
        const { selectedStation } = this.state
        if (selectedStation.onMapClick) {
            selectedStation.onMapClick()
        } else {
            AppStore.dispatch(push(`/alert/follow/${selectedStation.code}/${selectedStation.typeName}`))
        }
    }

    pushToSieau = () => {
        const { selectedStation } = this.state
        if (selectedStation.typeName === 'contact' || selectedStation.typeName === 'contributor') {
            AppStore.dispatch(push(`/referencial/${selectedStation.typeName}/${selectedStation.id}`))
        } else {
            AppStore.dispatch(push(`/station/${selectedStation.typeName}/${selectedStation.id}`))
        }
    }

    handlerListener(element) {
        return () => {
            const feature = this.getFeatureProxy(element)
            const lay = this.props.layers.find(la => la.checkUuid(element.getId()))
            this.setState({ selectedLayer: { lay, feature }, selectedStation: lay.getStation(lay, feature) }, this.createListener)
        }
    }

    getContent(list) {
        const { hover, currentStation } = this.props
        const { selectedLayer } = this.state

        return list.length > 1 ? (
            <div className='row no-margin'>
                <div className='col s4 border-right'>
                    <ul id='menu'>
                        {list}
                    </ul>
                </div>
                <div className='col s8 popupStation'>
                    { selectedLayer.lay && selectedLayer.lay.getPopup(this.props.getStation, selectedLayer.lay, selectedLayer.feature, hover, currentStation) }
                </div>
            </div>
        ) : (
            <div className='row no-margin'>
                { selectedLayer.lay && selectedLayer.lay.getPopup(this.props.getStation, selectedLayer.lay, selectedLayer.feature, hover, currentStation) }
            </div>
        )
    }

    render() {
        const { features, layers } = this.props
        // creation of childs components is too hard because you must use eventlistener over 2 components..
        const list = features.map(element => {
            const lay = layers.filter(l => !!l).find(la => la.checkUuid(element.getId()))
            const feature = this.getFeatureProxy(element)
            return lay ? (
                <li key={element.getId()}>
                    <a
                        className='truncate clickable blue-text'
                        ref={(el) => {
                            this[`couche${element.getId()}`] = el
                        }}
                    >
                        {lay.getStation(lay, feature).name || ''}
                    </a>
                </li>
            ) : null
        }).filter(elem => !!elem)
        const content = this.getContent(list)
        return (
            <div id='popover-content' style={{ width: list.length > 1 ? '600px' : '400px' }}>{content}</div>
        )
    }
}

PopupContent.propTypes = {
    layers: PropTypes.arrayOf(PropTypes.object),
    features: PropTypes.arrayOf(PropTypes.object),
    hover: PropTypes.bool,
    currentStation: PropTypes.instanceOf(DtoStation),
    getStation: PropTypes.func,
}

PopupContent.defaultProps = {
    layers: [],
    features: [],
    hover: false,
    getStation: null,
}

export default PopupContent