import ApplicationConf from 'conf/ApplicationConf'
import fetch from 'isomorphic-fetch'
import i18n from 'simple-react-i18n'
import ToastrAction from 'toastr/actions/ToastrAction'
import LogAction from '../../../../log/actions/LogAction'
import { checkAuth, getAuthorization, getText } from '../../../../utils/ActionUtils'
import {
    RECEIVE_ACTIVITY_COUNT,
    RECEIVE_AQUIFER_COUNT,
    RECEIVE_CITY_COUNT,
    RECEIVE_CONTACT_COUNT,
    RECEIVE_CONTRIBUTOR_COUNT,
    RECEIVE_COUNTRY_COUNT, RECEIVE_CULTURES_FAMILY_COUNT, RECEIVE_CULTURE_COUNT, RECEIVE_DRIVE_MODE_COUNT,
    RECEIVE_FRACTION_COUNT,
    RECEIVE_HYDROGEOLOGICALENTITY_COUNT,
    RECEIVE_METHOD_COUNT,
    RECEIVE_NETWORK_COUNT,
    RECEIVE_PARAMETER_COUNT,
    RECEIVE_RPG_COUNT,
    RECEIVE_SUPPORT_COUNT,
    RECEIVE_TAXON_COUNT,
    RECEIVE_UNIT_COUNT,
    RECEIVE_USAGE_COUNT,
    RECEIVE_WATERMASS_COUNT,
    RECEIVE_WATERSHED_COUNT,
    RECEIVE_ZONE_COUNT,
    RECEIVE_SECTOR_COUNT,
} from '../constants/DashboardConstants'

const DashboardAction = {
    fetchEveryCount: () => dispatch => Promise.all([
        DashboardAction.fetchCityCount(),
        DashboardAction.fetchContactCount(),
        DashboardAction.fetchNetworkCount(),
        DashboardAction.fetchFractionCount(),
        DashboardAction.fetchContributorCount(),
        DashboardAction.fetchWatermassCount(),
        DashboardAction.fetchMethodCount(),
        DashboardAction.fetchParameterCount(),
        DashboardAction.fetchSupportCount(),
        DashboardAction.fetchUnitCount(),
        DashboardAction.fetchHydrogeologicalEntityCount(),
        DashboardAction.fetchWatershedCount(),
        DashboardAction.fetchTaxonCount(),
        DashboardAction.fetchAquiferCount(),
        DashboardAction.fetchCultureCount(),
        DashboardAction.fetchCulturesFamilyCount(),
        DashboardAction.fetchActivityCount(),
        DashboardAction.fetchCountryCount(),
        DashboardAction.fetchUsageCount(),
        DashboardAction.fetchRPGCount(),
        DashboardAction.fetchSectorCount(),
    ])
        .then(json => {
            dispatch(DashboardAction.receiveCityCount(json[0]))
            dispatch(DashboardAction.receiveContactCount(json[1]))
            dispatch(DashboardAction.receiveNetworkCount(json[2]))
            dispatch(DashboardAction.receiveFractionCount(json[3]))
            dispatch(DashboardAction.receiveContributorCount(json[4]))
            dispatch(DashboardAction.receiveWatermassCount(json[5]))
            dispatch(DashboardAction.receiveMethodCount(json[6]))
            dispatch(DashboardAction.receiveParameterCount(json[7]))
            dispatch(DashboardAction.receiveSupportCount(json[8]))
            dispatch(DashboardAction.receiveUnitCount(json[9]))
            dispatch(DashboardAction.receiveHydrogeologicalEntityCount(json[10]))
            dispatch(DashboardAction.receiveWatershedCount(json[11]))
            dispatch(DashboardAction.receiveTaxonCount(json[12]))
            dispatch(DashboardAction.receiveAquiferCount(json[13]))
            dispatch(DashboardAction.receiveCultureCount(json[14]))
            dispatch(DashboardAction.receiveCulturesFamilyCount(json[15]))
            dispatch(DashboardAction.receiveActivityCount(json[16]))
            dispatch(DashboardAction.receiveCountryCount(json[17]))
            dispatch(DashboardAction.receiveUsageCount(json[18]))
            dispatch(DashboardAction.receiveRPGCount(json[19]))
            dispatch(DashboardAction.receiveSectorCount(json[20]))
        })
        .catch(err => {
            dispatch(LogAction.logError(`${i18n.fetchError + i18n.keyFigures} : ${err}`))
            dispatch(ToastrAction.error(i18n.fetchError + i18n.keyFigures))
        }),

    receiveAquiferCount(aquiferCount) {
        return { type: RECEIVE_AQUIFER_COUNT, aquiferCount }
    },

    fetchAquiferCount() {
        return fetch(ApplicationConf.referencial.aquiferCount(), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getText)
    },

    receiveActivityCount(activityCount) {
        return { type: RECEIVE_ACTIVITY_COUNT, activityCount }
    },

    fetchActivityCount() {
        return fetch(ApplicationConf.referencial.activityCount(), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getText)
    },

    receiveCountryCount(countryCount) {
        return { type: RECEIVE_COUNTRY_COUNT, countryCount }
    },

    fetchCountryCount() {
        return fetch(ApplicationConf.referencial.countryCount(), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getText)
    },

    receiveCultureCount(cultureCount) {
        return { type: RECEIVE_CULTURE_COUNT, cultureCount }
    },

    fetchCultureCount() {
        return fetch(ApplicationConf.referencial.cultureCount(), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getText)
    },

    receiveCulturesFamilyCount(culturesFamilyCount) {
        return { type: RECEIVE_CULTURES_FAMILY_COUNT, culturesFamilyCount }
    },

    fetchCulturesFamilyCount() {
        return fetch(ApplicationConf.referencial.culturesFamilyCount(), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getText)
    },


    receiveZoneCount(zoneCount) {
        return { type: RECEIVE_ZONE_COUNT, zoneCount }
    },

    fetchZoneCount() {
        return fetch(ApplicationConf.referencial.zoneCount(), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getText)
    },


    receiveDriveModeCount(driveModeCount) {
        return { type: RECEIVE_DRIVE_MODE_COUNT, driveModeCount }
    },

    fetchDriveModeCount() {
        return fetch(ApplicationConf.referencial.driveModeCount(), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getText)
    },


    receiveCityCount(cityCount) {
        return { type: RECEIVE_CITY_COUNT, cityCount }
    },

    fetchCityCount() {
        return fetch(ApplicationConf.referencial.cityCount(), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getText)
    },

    receiveContactCount(contactCount) {
        return { type: RECEIVE_CONTACT_COUNT, contactCount }
    },

    fetchContactCount() {
        return fetch(ApplicationConf.referencial.contactCount(), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getText)
    },

    receiveNetworkCount(networkCount) {
        return { type: RECEIVE_NETWORK_COUNT, networkCount }
    },

    fetchNetworkCount() {
        return fetch(ApplicationConf.referencial.networkCount(), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getText)
    },

    receiveFractionCount(fractionCount) {
        return { type: RECEIVE_FRACTION_COUNT, fractionCount }
    },

    fetchFractionCount() {
        return fetch(ApplicationConf.referencial.fractionCount(), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getText)
    },

    receiveContributorCount(contributorCount) {
        return { type: RECEIVE_CONTRIBUTOR_COUNT, contributorCount }
    },

    fetchContributorCount() {
        return fetch(ApplicationConf.referencial.contributorCount(), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getText)
    },

    receiveWatermassCount(watermassCount) {
        return { type: RECEIVE_WATERMASS_COUNT, watermassCount }
    },

    fetchWatermassCount() {
        return fetch(ApplicationConf.referencial.watermassCount(), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getText)
    },

    receiveMethodCount(methodCount) {
        return { type: RECEIVE_METHOD_COUNT, methodCount }
    },

    fetchMethodCount() {
        return fetch(ApplicationConf.referencial.methodCount(), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getText)
    },

    receiveParameterCount(parameterCount) {
        return { type: RECEIVE_PARAMETER_COUNT, parameterCount }
    },

    fetchParameterCount() {
        return fetch(ApplicationConf.referencial.parameterCount(), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getText)
    },

    receiveSupportCount(supportCount) {
        return { type: RECEIVE_SUPPORT_COUNT, supportCount }
    },

    fetchSupportCount() {
        return fetch(ApplicationConf.referencial.supportCount(), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getText)
    },

    receiveUnitCount(unitCount) {
        return { type: RECEIVE_UNIT_COUNT, unitCount }
    },

    fetchUnitCount() {
        return fetch(ApplicationConf.referencial.unitCount(), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getText)
    },

    receiveHydrogeologicalEntityCount(hydrogeologicalEntityCount) {
        return { type: RECEIVE_HYDROGEOLOGICALENTITY_COUNT, hydrogeologicalEntityCount }
    },

    fetchHydrogeologicalEntityCount() {
        return fetch(ApplicationConf.referencial.hydrogeologicalEntityCount(), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getText)
    },

    receiveWatershedCount(watershedCount) {
        return { type: RECEIVE_WATERSHED_COUNT, watershedCount }
    },

    fetchWatershedCount() {
        return fetch(ApplicationConf.referencial.watershedCount(), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getText)
    },

    receiveRPGCount(rpgCount) {
        return { type: RECEIVE_RPG_COUNT, rpgCount }
    },

    fetchRPGCount() {
        return fetch(ApplicationConf.referencial.countRPG(), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getText)
    },

    receiveSectorCount(sectorCount) {
        return { type: RECEIVE_SECTOR_COUNT, sectorCount }
    },

    fetchSectorCount() {
        return fetch(ApplicationConf.referencial.countSector(), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getText)
    },

    receiveUsageCount(usageCount) {
        return { type: RECEIVE_USAGE_COUNT, usageCount }
    },

    fetchUsageCount() {
        return fetch(ApplicationConf.referencial.usagesCount(), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getText)
    },

    receiveTaxonCount: taxonCount => ({ type: RECEIVE_TAXON_COUNT, taxonCount }),

    fetchTaxonCount: () => fetch(ApplicationConf.referencial.taxonCount(), {
        method: 'GET',
        headers: getAuthorization(),
    })
        .then(checkAuth)
        .then(getText),

}

export default DashboardAction
