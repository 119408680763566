import { groupBy, isNil, isUndefined, maxBy, round, uniqBy } from 'lodash'
import { useMemo } from 'react'
import { useSelector, shallowEqual } from 'react-redux'
import { getDate, getYear } from 'utils/DateUtil'
import { findApproximateValue } from 'station/components/suivipc/qualitometer/utils/SuiviPCUltils'
import { getMeasureByGroup } from 'quality/utils/PcMonitoringUtil'
import { getHardHydroDataTypes } from 'utils/HydroUtils'

const useHydroDatas = (hydroDatas, datesSort) => {
    const {
        hydrometricStations,
        hydrometryDataTypes,
    } = useSelector(store => ({
        hydrometricStations: store.HydrometryReducer.hydrometricStations,
        hydrometryDataTypes: store.HydrometryReducer.hydrometryDataTypes,
    }), shallowEqual)

    const dataTypes = useMemo(() => uniqBy([...hydrometryDataTypes, ...getHardHydroDataTypes()], 'id'), [hydrometryDataTypes])

    const hydroDatasFormatted = useMemo(() => {
        return hydroDatas.filter(({ id, type }) => id && type).map(({ id, type, measures = [], coeffFlux = 1, calculateFlux }) => {
            const hydro = hydrometricStations.find(p => p.id == id)
            const { label, unit } = dataTypes.find(d => d.id === type) ?? {}
            const startData = {
                parameter: {
                    value: `${hydro.name} [${hydro.code}]`,
                    classNameColor: 'grey',
                    tooltip: `${hydro.name} [${hydro.code}]`,
                },
                group: { value: '', classNameColor: 'grey' },
                unit: { value: `${label ?? ''} ${unit ? `[${unit}]` : ''}`, code: unit, position: 'right', classNameColor: 'grey' },
                support: { value: '', classNameColor: 'grey' },
                fraction: { value: '', classNameColor: 'grey' },
            }
            const groupedMeasures = groupBy(measures.filter(v => !isUndefined(v.value)), m => getDate(m.date, 'DD/MM/YYYY'))

            const hydroDataTmp = datesSort.reduce((acc, key) => {
                const [date] = key.split('#:#')
                const foundDeltaDate = findApproximateValue(date, groupedMeasures)
                if (groupedMeasures[date]) {
                    const maxMeasure = maxBy(groupedMeasures[date], 'value')
                    acc[key] = {
                        value: `${round(maxMeasure.value, 2)}`,
                        resultWithFlux: maxMeasure.value * coeffFlux,
                        date: maxMeasure.date,
                        classNameColor: 'grey',
                        shouldCalculate: calculateFlux,
                        cellType: 'right',
                        positionCell: 'right',
                    }
                } else if (foundDeltaDate.length) {
                    const maxMeasure = maxBy(groupedMeasures[foundDeltaDate[0]], 'value')
                    acc[key] = {
                        value: `${round(maxMeasure.value, 2)}`,
                        resultWithFlux: maxMeasure.value * coeffFlux,
                        date: maxMeasure.date,
                        classNameColor: foundDeltaDate[1],
                        shouldCalculate: calculateFlux,
                        cellType: 'right',
                        positionCell: 'right',
                    }
                } else {
                    acc[key] = { value: '', resultWithFlux: 1, shouldCalculate: calculateFlux, cellType: 'right', classNameColor: 'grey' }
                }
                return acc
            }, startData)
            return hydroDataTmp
        })
    }, [dataTypes, datesSort, hydroDatas, hydrometricStations])

    return hydroDatasFormatted
}

const useRegroupHydroDatas = (hydroDatas, datesSort, regrouping) => {
    const {
        hydrometricStations,
        hydrometryDataTypes,
    } = useSelector(store => ({
        hydrometricStations: store.HydrometryReducer.hydrometricStations,
        hydrometryDataTypes: store.HydrometryReducer.hydrometryDataTypes,
    }), shallowEqual)

    const dataTypes = useMemo(() => uniqBy([...hydrometryDataTypes, ...getHardHydroDataTypes()], 'id'), [hydrometryDataTypes])

    const hydroDatasFormatted = useMemo(() => {
        return hydroDatas.filter(({ id, type }) => id && type).map(({ id, type, measures = [], coeffFlux = 1, calculateFlux }) => {
            const hydro = hydrometricStations.find(p => p.id == id)
            const { label, unit } = dataTypes.find(d => d.id === type) ?? {}
            const startData = {
                parameter: {
                    value: `${hydro.name} [${hydro.code}]`,
                    classNameColor: 'grey',
                    tooltip: `${hydro.name} [${hydro.code}]`,
                },
                group: { value: '', classNameColor: 'grey' },
                unit: { value: `${label ?? ''} ${unit ? `[${unit}]` : ''}`, code: unit, position: 'right', classNameColor: 'grey' },
                support: { value: '', classNameColor: 'grey' },
                fraction: { value: '', classNameColor: 'grey' },
            }

            const filteredMeasures = measures.filter(v => !isUndefined(v.value))

            if (datesSort.includes('history')) {
                const measure = getMeasureByGroup(filteredMeasures, 'value', regrouping)
                return {
                    ...startData,
                    history: {
                        value: !isNil(measure?.value) ? `${round(measure?.value, 2)}` : '',
                        resultWithFlux: measure?.value * coeffFlux,
                        date: measure?.date,
                        shouldCalculate: calculateFlux,
                        classNameColor: 'grey',
                        cellType: 'right',
                        positionCell: 'right',
                    },
                }
            }
            const groupedMeasures = groupBy(filteredMeasures, m => getYear(m.date))
            return datesSort.reduce((acc, key) => {
                const measure = getMeasureByGroup(groupedMeasures[key], 'value', regrouping)
                acc[key] = {
                    value: !isNil(measure?.value) ? `${round(measure?.value, 2)}` : '',
                    resultWithFlux: measure?.value * coeffFlux,
                    date: measure?.date,
                    shouldCalculate: calculateFlux,
                    classNameColor: 'grey',
                    cellType: 'right',
                    positionCell: 'right',
                }
                return acc
            }, startData)
        })
    }, [dataTypes, datesSort, hydroDatas, hydrometricStations, regrouping])

    const hydroFluxData = useMemo(() => {
        const hydroFlux = hydroDatas.filter(({ id, type, calculateFlux }) => !isUndefined(id) && !isUndefined(type) && !isUndefined(calculateFlux))
        return hydroFlux.map(({ id, measures, coeffFlux = 1 }) => {
            const filteredMeasures = measures.filter(v => !isUndefined(v.value))

            if (datesSort.includes('history')) {
                const measure = getMeasureByGroup(filteredMeasures, 'value', regrouping)
                return {
                    id,
                    coeffFlux,
                    measures: {
                        history: measure,
                    },
                }
            }

            const groupedMeasures = groupBy(filteredMeasures, m => getYear(m.date))
            return {
                id,
                coeffFlux,
                measures: datesSort.reduce((acc, key) => {
                    const measure = getMeasureByGroup(groupedMeasures[key], 'value', regrouping)
                    acc[key] = measure
                    return acc
                }, {}),
            }
        })
    }, [datesSort, hydroDatas, regrouping])

    return {
        hydroDatasFormatted,
        hydroFluxData,
    }
}

export {
    useHydroDatas,
    useRegroupHydroDatas,
}