import { Button } from '@mui/material'
import CampaignDto from 'campaign/dto/CampaignDto'
import Card from 'components/card/Card'
import Select from 'components/forms/Select'
import SimpleDatePicker from 'components/forms/SimpleDatePicker'
import { EVENT_TYPES } from 'events/constants/EventsConstants'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import DtoStationType from 'referencial/dto/DtoStationType'
import i18n from 'simple-react-i18n'
import { execByType, findStationType } from 'utils/StationUtils'

class FormGlobalEvents extends Component {
    constructor(props) {
        super(props)
        this.state = {
            ...props.filter,
        }
    }

    getCampaigns = type => execByType(type, {
        piezometry: () => this.props.piezometryCampaigns,
        pluviometry: () => this.props.pluviometryCampaigns,
        hydrometry: () => this.props.hydrometryCampaigns,
        quality: () => this.props.qualityCampaigns,
        installation: () => this.props.installationCampaigns,
        productionUnit: () => [],
        distributionUnit: () => [],
        default: () => [
            ...this.props.piezometryCampaigns,
            ...this.props.pluviometryCampaigns,
            ...this.props.hydrometryCampaigns,
            ...this.props.qualityCampaigns,
            ...this.props.installationCampaigns,
        ],
    })

    getStationsByType = (stations, type) => {
        if (type) {
            const stationType = findStationType(type)
            return stationType ? stations.filter(s => s.typeName === stationType.type) : stations
        }
        return stations
    }

    render = () => {
        const {
            type,
            stationType,
            campaign,
            startDate,
            endDate,
            eventType,
        } = this.state
        const {
            stations,
            onValidate,
            stationTypes,
        } = this.props

        const stationTypesFormat = stationTypes.map(s => ({
            code: s.code,
            type: s.name,
            name: i18n[s.i18n],
        }))

        const eventTypes = EVENT_TYPES.map(evt => ({
            code: evt.code,
            label: i18n[evt.label],
        }))

        return (
            <Card className='row'>
                <div className='col s12 padding-top-1'>
                    <Select
                        col={3}
                        label={i18n.stationType}
                        options={stationTypesFormat}
                        value={type}
                        onChange={v => this.setState({ type: v })}
                        nullLabel='&nbsp;'
                    />
                    <Select
                        col={6}
                        label={i18n.station}
                        options={this.getStationsByType(stations, type)}
                        value={stationType}
                        onChange={(v) => this.setState({ stationType: v })}
                        clearFunction
                    />
                    <Select
                        col={3}
                        label={i18n.campaign}
                        options={this.getCampaigns(type)}
                        value={campaign}
                        onChange={v => this.setState({ campaign: v })}
                        clearFunction
                    />
                </div>
                <div className='col s12 padding-bottom-1' style={{ display: 'flex', alignItems: 'center' }}>
                    <SimpleDatePicker
                        id='startDate'
                        col={3}
                        label={i18n.startDate}
                        value={startDate}
                        onChange={(v) => this.setState({ startDate: v })}
                    />
                    <SimpleDatePicker
                        id='endDate'
                        col={3}
                        label={i18n.endDate}
                        value={endDate}
                        onChange={(v) => this.setState({ endDate: v })}
                    />
                    <Select
                        col={3}
                        label={i18n.eventTypes}
                        options={eventTypes}
                        value={eventType}
                        onChange={v => this.setState({ eventType: v })}
                        nullLabel='&nbsp;'
                    />
                    <div className='col s3' style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button variant='contained' onClick={() => onValidate({ type, stationType, campaign, startDate, endDate, eventType })}>
                            {i18n.search}
                        </Button>
                    </div>
                </div>
            </Card>
        )
    }
}

FormGlobalEvents.propTypes = {
    filter: PropTypes.objectOf({
        type: PropTypes.number,
        stationType: PropTypes.number,
        campaign: PropTypes.number,
        startDate: PropTypes.number,
        endDate: PropTypes.number,
        eventType: PropTypes.number,
    }),
    onValidate: PropTypes.func,
    stations: PropTypes.objectOf({
        id: PropTypes.number,
        code: PropTypes.string,
        typeName: PropTypes.string,
        name: PropTypes.string,
    }),

    piezometryCampaigns: PropTypes.arrayOf(PropTypes.instanceOf(CampaignDto)),
    pluviometryCampaigns: PropTypes.arrayOf(PropTypes.instanceOf(CampaignDto)),
    hydrometryCampaigns: PropTypes.arrayOf(PropTypes.instanceOf(CampaignDto)),
    qualityCampaigns: PropTypes.arrayOf(PropTypes.instanceOf(CampaignDto)),
    installationCampaigns: PropTypes.arrayOf(PropTypes.instanceOf(CampaignDto)),
    stationTypes: PropTypes.arrayOf(PropTypes.instanceOf(DtoStationType)),
}

const mapStateToProps = store => ({
    piezometryCampaigns: store.PiezometryReducer.piezometryCampaigns,
    pluviometryCampaigns: store.PluviometryReducer.pluviometryCampaigns,
    hydrometryCampaigns: store.HydrometryReducer.hydrometryCampaigns,
    qualityCampaigns: store.QualityReducer.qualityCampaigns,
    installationCampaigns: store.InstallationReducer.installationCampaigns,
    stationTypes: store.ReferencialReducer.stationTypes,
})

export default connect(mapStateToProps)(FormGlobalEvents)