import React from 'react'
import PropTypes from 'prop-types'
import { Card, Grid, Icon } from '@mui/material'
import i18n from 'simple-react-i18n'
import { useInstUploadFile } from 'utils/customHook/useUploadFile'

const AddPicture = ({
    onUploadFile = () => { },
}) => {
    const uploadFile = useInstUploadFile()

    return (
        <Grid item xs={3} className='file-field'>
            <Card sx={{ height: '130px' }}>
                <Grid
                    container
                    justifyContent='center'
                    direction='column'
                    alignItems='center'
                    sx={{
                        height: '100%',
                        border: 'dashed 2px grey',
                        color: 'grey',
                        '&:hover': {
                            color: 'black',
                            border: 'dashed 2px black',
                        },
                    }}
                >
                    <Icon sx={{ fontSize: '50px' }}>add_box_outline</Icon>
                    {i18n.addPictureOrFile}
                    <input
                        accept='.pdf, image/*'
                        id='uploadFile'
                        type='file'
                        multiple
                        title=''
                        onChange={e => uploadFile(e, onUploadFile)}
                    />
                </Grid>
            </Card>
        </Grid>
    )
}

AddPicture.propTypes = {
    onUploadFile: PropTypes.func,
}

export default AddPicture