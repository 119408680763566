/* eslint-disable camelcase */
import DtoUsageDeclaration from 'survey/dto/DtoUsageDeclaration'
import DtoTankDeclaration from './DtoTankDeclaration'

export default class DtoDeclarationVolumes {
    constructor(obj) {
        this.idExploitation = obj.idExploitation // Option[Long] = None
        this.idInstallation = obj.idInstallation // Option[Long] = None

        this.askedYear = obj.askedYear // Option[Int] = None
        this.idSurvey = obj.idSurvey // Option[Long] = None

        this.askedVolume = obj.askedVolume // Option[Double] = None
        this.askedLowWaterVolume = obj.askedLowWaterVolume // Option[Double] = None

        this.attributedVolume = obj.attributedVolume // Option[Double] = None
        this.attributedLowWaterVolume = obj.attributedLowWaterVolume // Option[Double] = None
        this.attributionComment = obj.attributionComment // Option[String] = None
        this.attributionLogin = obj.attributionLogin // Option[String] = None
        this.attributionDate = obj.attributionDate // Option[DateTime] = None

        this.authorizedVolume = obj.authorizedVolume // Option[Double] = None
        this.authorizedLowWaterVolume = obj.authorizedLowWaterVolume // Option[Double] = None
        this.authorizedFlow = obj.authorizedFlow //  Option[Double] = None
        this.authorizedComment = obj.authorizedComment // Option[String] = None
        this.authorizedLogin = obj.authorizedLogin // Option[String] = None
        this.authorizedDate = obj.authorizedDate // Option[DateTime] = None
        this.link_usages = obj.link_usages ? new DtoUsageDeclaration(obj.link_usages) : null // Option[InstallationUsageProvisionalWithLinks] = None
        this.link_tanks = obj.link_tanks ? obj.link_tanks.map((t) => new DtoTankDeclaration(t)) : [] // Option[Seq[DeclarationTankWithLinks]] = None

        // used front side
        this.idProvisionalUsage = obj.idProvisionalUsage // Option[Long] = None
    }
}