import StepperDialog from 'components/modal/StepperDialog'
import React, { useEffect, useMemo, useState } from 'react'
import i18n from 'simple-react-i18n'
import PropTypes from 'prop-types'
import { ButtonMUI } from 'components/styled/Buttons'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { intersectionWith, isNil, isUndefined, keyBy } from 'lodash'
import useUpdateEffect from 'utils/customHook/useUpdateEffect'
import moment from 'moment'
import WaitAction from 'wait/WaitAction'
import { push } from '@lagunovsky/redux-react-router'
import ToastrAction from 'toastr/actions/ToastrAction'
import { Card, CardContent, Grid2 } from '@mui/material'
import SimpleDatePicker from 'components/forms/SimpleDatePicker'
import Textarea from 'components/forms/Textarea'
import Select from 'components/forms/Select'
import SimpleSelectionTable, { TaxonFilterField } from 'components/datatable/SimpleSelectionTable'
import { searchAllCharacters } from 'utils/StringUtil'
import OperationAction from 'quality/actions/OperationAction'
import HydrobioAction from 'quality/actions/HydrobioAction'

const STEP_OPERATION = 0
const STEP_TAXON = 1

const DescriptionStep = ({
    operation = {},
    setOperation = () => {},
    points = [],
}) => {
    const {
        supports,
        contributors,
    } = useSelector(store => ({
        supports: store.SupportReducer.supports,
        contributors: store.ContributorReducer.contributors,
    }), shallowEqual)

    const formattedPoints = useMemo(() => {
        return points.map(p => {
            const support = supports.find(s => s.code === `${p.codeSupport}`)
            return {
                name: `${p.name || (support?.name ?? 'Eau')} [${p.identifiant || (support?.code ?? '031')}] ${p.code ?? ''} ${p.startDepth || p.endDepth ? `${p.startDepth ?? '(...)'}m -> ${p.endDepth ?? '(...)'}m` : ''}`,
                id: p.idPoint,
            }
        })
    }, [points, supports])

    return (
        <Card>
            <CardContent>
                <Grid2 container columnSpacing={2} rowSpacing={1}>
                    <Grid2 size={4}>
                        <SimpleDatePicker
                            value={operation.date}
                            label={i18n.date}
                            onChange={date => setOperation(p => ({ ...p, date }))}
                            obligatory
                        />
                    </Grid2>
                    <Grid2 size={4}>
                        <Select
                            label={i18n.support}
                            options={supports}
                            value={operation.support}
                            onChange={support => setOperation(p => ({ ...p, support }))}
                            obligatory
                        />
                    </Grid2>
                    <Grid2 size={4}>
                        <Select
                            label={i18n.samplePoint}
                            options={formattedPoints}
                            value={operation.point}
                            onChange={point => setOperation(p => ({ ...p, point }))}
                            obligatory
                        />
                    </Grid2>
                    <Grid2 size={4}>
                        <Select
                            options={contributors}
                            label={i18n.producer}
                            value={operation.producer}
                            nullLabel='&nbsp;'
                            keyLabel='labelDisplay'
                            displayWithCode
                            onChange={producer => setOperation(p => ({ ...p, producer }))}
                        />
                    </Grid2>
                    <Grid2 size={4}>
                        <Select
                            options={contributors}
                            label={i18n.sampler}
                            value={operation.sampler}
                            nullLabel='&nbsp;'
                            displayWithCode
                            keyLabel='labelDisplay'
                            onChange={sampler => setOperation(p => ({ ...p, sampler }))}
                        />
                    </Grid2>
                    <Grid2 size={4}>
                        <Select
                            options={contributors}
                            label={i18n.determiner}
                            value={operation.determiner}
                            nullLabel='&nbsp;'
                            displayWithCode
                            keyLabel='labelDisplay'
                            onChange={labo => setOperation(p => ({ ...p, labo }))}
                        />
                    </Grid2>
                    <Grid2 size={12}>
                        <Textarea
                            title={i18n.comment}
                            value={operation.comment}
                            onChange={comment => setOperation(p => ({ ...p, comment }))}
                        />
                    </Grid2>
                </Grid2>
            </CardContent>
        </Card>
    )
}

DescriptionStep.propTypes = {
    operation: PropTypes.shape({
        dateStart: PropTypes.number,
        hourStart: PropTypes.number,
        support: PropTypes.number,
        producer: PropTypes.number,
        sampler: PropTypes.number,
        labo: PropTypes.number,
        comment: PropTypes.string,
    }),
    setOperation: PropTypes.func,
    points: PropTypes.arrayOf(PropTypes.shape({})),
}

const TaxonStep = ({
    hydrobioLists = [],
    setHydrobioLists = () => {},
}) => {
    const {
        taxons,
    } = useSelector(store => ({
        taxons: store.TaxonReducer.taxons,
    }), shallowEqual)

    const selectedTaxons = hydrobioLists.map(t => t.taxon)

    const onChange = listTaxons => {
        setHydrobioLists(prevList => {
            const indexedList = keyBy(prevList, 'taxon')
            return listTaxons.map(p => indexedList[p] ?? { taxon: p })
        })
    }

    const formattedTaxons = useMemo(() => taxons.map(t => ({
        ...t,
        labelSearch: [t.latinName, t.code].map(l => searchAllCharacters(l)).join('#'),
    })), [taxons])

    return (
        <SimpleSelectionTable
            onChange={onChange}

            listData={formattedTaxons}
            selectedList={selectedTaxons}

            listHeaders={[{ key: 'labelWithCode', value: i18n.name }]}
            listTitle={i18n.nonSelectedTaxons}
            selectedListTitle={i18n.selectedTaxons}
            maxHeightTable={'43vh'}

            filterField={TaxonFilterField}
            filterFunction={(list, { selection = '-1', listTaxon, searchValue }) => {
                const filterSelection = selection !== '-1' ? intersectionWith(list, listTaxon, (elem, id) => elem.id === id) : list
                const searchValueFormat = searchAllCharacters(searchValue)
                return searchValue ? filterSelection.filter(t => t.labelSearch.includes(searchValueFormat)) : filterSelection
            }}
        />
    )
}

TaxonStep.propTypes = {
    hydrobioLists: PropTypes.arrayOf(PropTypes.shape({})),
    setHydrobioLists: PropTypes.func,
}

const OperationHydrobioStepper = ({
    isOpen = false,
    onClose = () => { },
}) => {
    const dispatch = useDispatch()

    const {
        accountUser,
        qualitometer,
    } = useSelector(store => ({
        accountUser: store.AccountReducer.accountUser,
        qualitometer: store.QualityReducer.qualitometer,
    }), shallowEqual)

    const defaultOperation = useMemo(() => {
        return {
            date: +moment(),
            producer: accountUser.contributorCode,
            sampler: accountUser.contributorCode,
            determiner: accountUser.contributorCode,
        }
    }, [accountUser.contributorCode])

    const [operation, setOperation] = useState(defaultOperation)
    const [hydrobioLists, setHydrobioLists] = useState([])

    useUpdateEffect(() => {
        setOperation(defaultOperation)
        setHydrobioLists([])
    }, [isOpen, defaultOperation])

    const filteredPoints = useMemo(() => {
        return qualitometer.link_samplePoints
            .filter(p => isNil(operation.support) || isNil(p.codeSupport) || `${p.codeSupport}` === operation.support)
            .filter(p => isNil(p.startDate) || p.startDate <= operation.date)
            .filter(p => isNil(p.endtDate) || p.endtDate >= operation.date)
    }, [operation.date, operation.support, qualitometer.link_samplePoints])

    useEffect(() => {
        if (filteredPoints.some(p => p.idPoint === operation.point)) return

        if (filteredPoints.length === 1) {
            setOperation(p => ({ ...p, point: filteredPoints[0].idPoint }))
            return
        }

        setOperation(p => ({ ...p, point: undefined }))
    }, [filteredPoints, operation.point])

    return (
        <StepperDialog
            steps={[
                {
                    label: i18n.sample,
                    constant: STEP_OPERATION,
                    nextAvailable: !isNil(operation.date) && !isNil(operation.point) && !isNil(operation.support),
                },
                {
                    label: `${i18n.selection} / ${i18n.taxons}`,
                    constant: STEP_TAXON,
                },
            ]}
            open={isOpen}
            title={i18n.newSample}
            closeDialog={onClose}
            renderSaveButton={step => (step === STEP_TAXON) && (
                <ButtonMUI
                    variant='contained'
                    onClick={() => {
                        const newOperation = {
                            id: -1,
                            qualitometer: qualitometer.id,
                            ...operation,
                            support: operation.support ? parseInt(operation.support) : undefined,
                        }
                        dispatch(WaitAction.waitStart())
                        OperationAction.create(newOperation).then(({ idOperation }) => {
                            if (isUndefined(idOperation)) {
                                dispatch(WaitAction.waitStop())
                                dispatch(ToastrAction.error(i18n.createError))
                                return
                            }
                            const formatedHydrobioLists = hydrobioLists.map((hl, i) => ({
                                ...hl,
                                qualitometer: qualitometer.id,
                                operation: idOperation,
                                id: i,
                            }))
                            dispatch(HydrobioAction.createAllHydrobioList(qualitometer.id, idOperation, formatedHydrobioLists)).then(() => {
                                dispatch(push(`/station/quality/${qualitometer.id}/hydrobioOperation/${idOperation}`))
                            }).finally(() => dispatch(WaitAction.waitStop()))
                        })
                    }}
                >
                    {i18n.register}
                </ButtonMUI>
            )}
        >
            {
                step => (
                    <>
                        {step === STEP_OPERATION && (
                            <DescriptionStep
                                operation={operation}
                                setOperation={setOperation}
                                points={filteredPoints}
                            />
                        )}
                        {step === STEP_TAXON && (
                            <TaxonStep
                                hydrobioLists={hydrobioLists}
                                setHydrobioLists={setHydrobioLists}
                            />
                        )}
                    </>
                )
            }
        </StepperDialog>
    )
}

OperationHydrobioStepper.propTypes = {
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
}

export default OperationHydrobioStepper