import React, { useState } from 'react'
import i18n from 'simple-react-i18n'
import PropTypes from 'prop-types'
import { Grid } from '@mui/material'
import { groupBy } from 'lodash'
import CampaignCard from 'campaign/components/CampaignCard'
import TabList from 'components/list/TabList'
import { AccordionDetailsMUI, AccordionMUI, AccordionSummaryMUI } from 'components/styled/Accordions'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { ACTION_COLOR, SORT_BY } from 'station/constants/CampaignConstants'
import { getI18nTitleDataLength, getSandreLabel, i18nize } from 'utils/StringUtil'
import { getInstallationName } from 'utils/VisitUtils'
import CampaignActionCard from './CampaignActionCard'
import InstallationAction from 'installation/actions/InstallationAction'
import { getLabel } from 'utils/StoreUtils'
import { CAMPAIGN_STATUT_FOR_FILTER, CAMPAIGN_TYPES } from 'campaign/constants/CampaignConstants'
import { hasValue } from 'utils/NumberUtil'
import { getYearOrString } from 'utils/DateUtil'
import { SANDRE } from 'referencial/constants/ReferencialConstants'
import { mediumGrey } from 'utils/constants/ColorTheme'
import CampaignActionPopup from './CampaignActionPopup'
import DtoCampaignProgress from 'campaign/dto/DtoCampaignProgress'
import DtoCampaignAction from '../dto/DtoCampaignAction'

const AccordionDisplay = ({
    groupCard = [],
    title = '',
    color = '',
    openFirst = false,
}) => {
    return (
        <AccordionMUI defaultExpanded={openFirst}>
            <AccordionSummaryMUI style={{ backgroundColor: color }}>
                {title} {`(${groupCard.length} ${getI18nTitleDataLength(i18n.element, i18n.elements, groupCard.length)})`}
            </AccordionSummaryMUI>
            <AccordionDetailsMUI style={{ padding: 0 }}>
                {groupCard}
            </AccordionDetailsMUI>
        </AccordionMUI>
    )
}

AccordionDisplay.propTypes = {
    groupCard: PropTypes.arrayOf(PropTypes.element),
    title: PropTypes.string,
    color: PropTypes.string,
    openFirst: PropTypes.bool,
}

const GroupCampaignByStatus = ({
}) => {
    const {
        contacts,
        campaignsInstallationWithStats,
    } = useSelector(store => ({
        contacts: store.ContactReducer.contacts,
        campaignsInstallationWithStats: store.CampaignReducer.campaignsInstallationWithStats,
    }), shallowEqual)

    const grouped = groupBy(campaignsInstallationWithStats, 'campaignStatus')
    const keys = Object.keys(grouped)
    return keys.map((key, i) => {
        const campaignCards = grouped[key].map(d => {
            const contact = contacts.find(c => campaignsInstallationWithStats.contactCode === c.code)
            return (
                <Grid key={d.id}>
                    <CampaignCard
                        campaign={{
                            ...d,
                            statut: d.campaignStatus,
                            beginningApplication: d.startDate,
                            endingApplication: d.endDate,
                        }}
                        progress={new DtoCampaignProgress({
                            nbStation: d.nbStation,
                            nbStationValidated: d.nbStationValidated,
                            progress: d.nbStationValidated,
                            progressTotal: d.nbStation,
                        })}
                        contact={contact}
                        stationType='installation'
                    />
                </Grid>
            )
        })
        return (
            <Grid key={key} style={{ marginBottom: i === keys.length - 1 ? 0 : 10 }}>
                <AccordionDisplay
                    groupCard = { campaignCards }
                    title={i18n[getLabel(CAMPAIGN_STATUT_FOR_FILTER, key) || 'notDefined']}
                    color={ACTION_COLOR[parseInt(key)] || mediumGrey}
                    openFirst={i === 0}
                />
            </Grid>
        )
    })
}

GroupCampaignByStatus.propTypes = {
}

const GroupCampaignByReferent = ({
}) => {
    const {
        contacts,
        campaignsInstallationWithStats,
    } = useSelector(store => ({
        contacts: store.ContactReducer.contacts,
        campaignsInstallationWithStats: store.CampaignReducer.campaignsInstallationWithStats,
    }), shallowEqual)

    const grouped = groupBy(campaignsInstallationWithStats, c => hasValue(c.contactCode) ? getLabel(contacts, c.contactCode) : i18n.unknownReferent)
    const keys = Object.keys(grouped)
    return keys.map((key, i) => {
        const campaignCards = grouped[key].map(d => {
            const contact = contacts.find(c => campaignsInstallationWithStats.contactCode === c.code)
            return (
                <Grid key={d.id}>
                    <CampaignCard
                        campaign={{
                            ...d,
                            statut: d.campaignStatus,
                            beginningApplication: d.startDate,
                            endingApplication: d.endDate,
                        }}
                        progress={new DtoCampaignProgress({
                            nbStation: d.nbStation,
                            nbStationValidated: d.nbStationValidated,
                            progress: d.nbStationValidated,
                            progressTotal: d.nbStation,
                        })}
                        contact={contact}
                        stationType='installation'
                    />
                </Grid>
            )
        })
        return (
            <Grid key={key} style={{ marginBottom: i === keys.length - 1 ? 0 : 10 }}>
                <AccordionDisplay
                    groupCard={campaignCards}
                    title={key}
                    openFirst={i === 0}
                />
            </Grid>
        )
    })
}

GroupCampaignByReferent.propTypes = {
}

const GroupCampaignByDate = ({
}) => {
    const {
        contacts,
        campaignsInstallationWithStats,
    } = useSelector(store => ({
        contacts: store.ContactReducer.contacts,
        campaignsInstallationWithStats: store.CampaignReducer.campaignsInstallationWithStats,
    }), shallowEqual)

    const grouped = groupBy(campaignsInstallationWithStats, c => getYearOrString(c.startDate, i18n.thisYear))
    const keys = Object.keys(grouped)
    return keys.map((key, i) => {
        const campaignCards = grouped[key].map(d => {
            const contact = contacts.find(c => campaignsInstallationWithStats.contactCode === c.code)
            return (
                <Grid key={d.id}>
                    <CampaignCard
                        campaign={{
                            ...d,
                            statut: d.campaignStatus,
                            beginningApplication: d.startDate,
                            endingApplication: d.endDate,
                        }}
                        progress={new DtoCampaignProgress({
                            nbStation: d.nbStation,
                            nbStationValidated: d.nbStationValidated,
                            progress: d.nbStationValidated,
                            progressTotal: d.nbStation,
                        })}
                        contact={contact}
                        stationType='installation'
                    />
                </Grid>
            )
        })
        return (
            <Grid key={key} style={{ marginBottom: i === 0 ? 0 : 10 }}>
                <AccordionDisplay
                    groupCard={campaignCards}
                    title={key}
                    openFirst={i === keys.length-1}
                />
            </Grid>
        )
    }).reverse()
}

GroupCampaignByDate.propTypes = {
}

const GroupCampaignByType = ({
}) => {
    const {
        contacts,
        campaignsInstallationWithStats,
    } = useSelector(store => ({
        contacts: store.ContactReducer.contacts,
        campaignsInstallationWithStats: store.CampaignReducer.campaignsInstallationWithStats,
    }), shallowEqual)
    const grouped = groupBy(campaignsInstallationWithStats, ({ campaignType = 4 }) => campaignType)
    const keys = Object.keys(grouped)
    return keys.map((key, i) => {
        const campaignCards = grouped[key].map(d => {
            const contact = contacts.find(c => campaignsInstallationWithStats.contactCode === c.code)
            return (
                <Grid key={d.id}>
                    <CampaignCard
                        campaign={{
                            ...d,
                            statut: d.campaignStatus,
                            beginningApplication: d.startDate,
                            endingApplication: d.endDate,
                        }}
                        progress={new DtoCampaignProgress({
                            nbStation: d.nbStation,
                            nbStationValidated: d.nbStationValidated,
                            progress: d.nbStationValidated,
                            progressTotal: d.nbStation,
                        })}
                        contact={contact}
                        stationType='installation'
                    />
                </Grid>
            )
        })
        const title = getLabel(i18nize(CAMPAIGN_TYPES), key) || i18n.unknownType
        return (
            <Grid key={key} style={{ marginBottom: i === keys.length - 1 ? 0 : 10 }}>
                <AccordionDisplay
                    groupCard={campaignCards}
                    title={title}
                    openFirst={i === 0}
                />
            </Grid>
        )
    })
}

GroupCampaignByType.propTypes = {
}

const GroupCampaignByAction = ({
}) => {
    const dispatch = useDispatch()
    const {
        sandreCodes,
        campaignsActions,
        installationsTypes,
    } = useSelector(store => ({
        sandreCodes: store.ReferencialReducer.sandreCodes,
        campaignsActions: store.CampaignReducer.campaignsActions,
        installationsTypes: store.InstallationReducer.installationsTypes,
    }), shallowEqual)

    const [open, setOpen] = useState(false)
    const [dataLoaded, setDataLoaded] = useState(false)
    const [selectedAction, setSelectedAction] = useState(new DtoCampaignAction({}))

    const grouped = groupBy(campaignsActions, 'deadline')
    const keys = Object.keys(grouped)
    const accordions = keys.map((key, i) => {
        const campaignCards = grouped[key].map(d => {
            const installationName = getInstallationName(installationsTypes, d)
            return (
                <CampaignActionCard
                    campaignAction={d}
                    installationName={installationName}
                    onClick={() => {
                        setOpen(true)
                        setSelectedAction(d)
                        dispatch(InstallationAction.fetchVisit(d.idInstallation, d.idCampaign)).then(() => setDataLoaded(true))
                    }}
                />
            )
        })
        return (
            <Grid key={key} style={{ marginBottom: i === keys.length - 1 ? 0 : 10 }}>
                <AccordionDisplay
                    groupCard={campaignCards}
                    title={getSandreLabel(sandreCodes, SANDRE.VISITES_ACTIONS_ECHEANCE, key) || i18n.notDefined}
                    color={ACTION_COLOR[key] || mediumGrey}
                    openFirst={i === 0}
                />
            </Grid>
        )
    })

    return (
        <>
            {accordions}
            <CampaignActionPopup
                open={open}
                dataLoaded={dataLoaded}
                setClose={() => {
                    setOpen(false)
                    setDataLoaded(false)
                }}
                campaignAction={selectedAction}
            />
        </>
    )
}

GroupCampaignByAction.propTypes = {
}


const CampaignAndActionTabs = ({
    sortBy = 0,
    setSortBy = () => { },
}) => {
    const {
        campaignsActions,
        campaignsInstallationWithStats,
    } = useSelector(store => ({
        campaignsActions: store.CampaignReducer.campaignsActions,
        campaignsInstallationWithStats: store.CampaignReducer.campaignsInstallationWithStats,
    }), shallowEqual)


    return (
        <TabList
            onChangeTab={setSortBy}
            tabs={[{
                value: SORT_BY.STATUS,
                label: i18n.byStatus,
                icon: 'edit',
            }, {
                value: SORT_BY.DATE,
                label: i18n.byDate,
                icon: 'insert_invitation',
            }, {
                value: SORT_BY.REFERENT,
                label: i18n.byReferent,
                icon: 'person',
            }, {
                value: SORT_BY.TYPE,
                label: i18n.byCampaignType,
                icon: 'business_center',
            }, {
                value: SORT_BY.ACTIONS,
                label: i18n.byAction,
                icon: 'build',
            }]}
        >
            <Grid className='padding-1'>
                {(!campaignsInstallationWithStats.length && sortBy !== 'action' || !campaignsActions.length && sortBy === 'action')
                    ? (
                        <Grid style={{ padding: '10%' }}>
                            <Grid className='text-align-center'>
                                <i className='material-icons medium'>nature_people</i>
                            </Grid>
                            <Grid className='center font-size-20'>{i18n.NoneResultForThisResearch}</Grid>
                        </Grid>
                    ) : <>
                        {sortBy === SORT_BY.STATUS && <GroupCampaignByStatus/>}
                        {sortBy === SORT_BY.REFERENT && <GroupCampaignByReferent/>}
                        {sortBy === SORT_BY.TYPE && <GroupCampaignByType/>}
                        {sortBy === SORT_BY.DATE && <GroupCampaignByDate/>}
                        {sortBy === SORT_BY.ACTIONS && <GroupCampaignByAction/>}
                    </>
                }
            </Grid>
        </TabList>
    )
}

CampaignAndActionTabs.propTypes = {
    sortBy: PropTypes.number,
    setSortBy: PropTypes.func,
}

export default CampaignAndActionTabs