import ReferencialDto from '../../../dto/ReferencialDto'

export default class NetworkDto extends ReferencialDto {
    constructor(object) {
        super(object)
        this.id = object.code
        this.sandreCode = object.sandreCode
        this.dce = object.dce
        this.toEdit = object.toEdit
        this.mnemonique = object.mnemonique
        this.mnemonic = object.mnemonic
        this.finality = object.finality
        this.comment = object.comment
        this.contributorCode = object.contributorCode
        this.labelWithSandre = (object.sandreCode ? `[${object.sandreCode}] ` : '') + (object.mnemonic ? object.mnemonic : object.name)
        this.nameWithSandre = (object.mnemonic ? object.mnemonic : object.name) + (object.sandreCode ? ` [${object.sandreCode}]` : '')
        this.headers = ['code', 'name', 'mnemonic', 'finality', 'dce', 'sandreCode']
    }
}