import React from 'react'
import { Grid } from '@mui/material'
import i18n from 'simple-react-i18n'
import PropTypes from 'prop-types'
import PresentationCard from 'components/card/PresentationCard'
import { HYDRO_IMPORT_TYPE } from 'hydrometry/constants/HydrometryConstants'
import DtoHydrometricStation from 'hydrometry/dto/DtoHydrometricStation'

const getDefaultParams = (stationType) => {
    switch (stationType) {
        case HYDRO_IMPORT_TYPE.HYDRO_PORTAIL:
            return {
                filters: [],
                dataTypes: ['stationIntegration', 'DATA_ADD_NEW_DATA', 'withPred', 'heightIntegration', 'debitIntegration', 'withEvents', 'withThresholds'],
            }
        default:
            return { filters: {}, dataTypes: [] }
    }
}

const updateParams = (stationType, setFilters, setJobDataTypes) => {
    const params = getDefaultParams(stationType)
    setFilters(params.filters)
    setJobDataTypes(params.dataTypes)
}

const AddHydroStepStation = ({
    station,
    changeStation,
    nextStep,
    setFilters,
    setJobDataTypes,
}) => {
    const centerStyle = {
        'align-items': 'center',
        display: 'flex',
        justifyContent: 'center',
    }

    return (
        <div className='padding-top-2'>
            <Grid container justifyContent='center' alignItems='center' spacing={3}>
                <h4>{i18n.newStationStep1}</h4>
            </Grid>
            <Grid container justifyContent='center' alignItems='center' spacing={3}>
                <Grid item xs={6} >
                    <PresentationCard
                        iconComponent={
                            <img
                                className='responsive-img'
                                src={'pictures/manualImports/hydroportail.png'}
                                height={70}
                                width={70}
                                style={{
                                    textAlign: 'center',
                                    display: 'table-cell',
                                    verticalAlign: 'middle',
                                }}
                            />}
                        iconContainerStyle={centerStyle}
                        title='Hydro Portail'
                        onClick={() => {
                            changeStation({ stationType: HYDRO_IMPORT_TYPE.HYDRO_PORTAIL })
                            updateParams(HYDRO_IMPORT_TYPE.HYDRO_PORTAIL, setFilters, setJobDataTypes)
                            nextStep()
                        }}
                        isFocused={station.stationType === HYDRO_IMPORT_TYPE.HYDRO_PORTAIL}
                        description={i18n.hydroPortailDescription}
                    />
                </Grid>
                <Grid item xs={6}>
                    <PresentationCard
                        iconComponent={<img className='responsive-img' src={'pictures/markers/map_hydro.png'} height={50} width={50} />}
                        iconContainerStyle={centerStyle}
                        title='Point vide'
                        onClick={() => {
                            changeStation({ stationType: HYDRO_IMPORT_TYPE.EMPTY })
                            updateParams(HYDRO_IMPORT_TYPE.EMPTY, setFilters, setJobDataTypes)
                            nextStep()
                        }}
                        description={i18n.newHydroDescription}
                        isFocused={station.stationType === HYDRO_IMPORT_TYPE.EMPTY}
                    />
                </Grid>
            </Grid>
        </div>
    )
}

AddHydroStepStation.propTypes = {
    station: PropTypes.instanceOf(DtoHydrometricStation),
    nextStep: PropTypes.func,
    changeStation: PropTypes.func,
    setFilters: PropTypes.func,
    setJobDataTypes: PropTypes.func,
}

export default AddHydroStepStation