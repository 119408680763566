import { Button, Grid, Tooltip } from '@mui/material'
import SimpleTextArea from 'components/forms/SimpleTextArea'
import { push } from '@lagunovsky/redux-react-router'
import InstallationAction from 'installation/actions/InstallationAction'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import i18n from 'simple-react-i18n'
import Row from '../../../../components/react/Row'
import { downloadURI } from '../../../../utils/ExportDataUtil'
import DtoVisit from '../dto/DtoVisit'

const InstVisitConclusion = ({
    visit = {},
    readMode = true,
    summary = false,
    onChange = () => {},
    launchSave = () => {},
}) => {
    const dispatch = useDispatch()
    const [openTooltip, setOpenTooltip] = useState(false)
    const finalizeVisit = () => {
        launchSave(true)
        dispatch(push(`/installation/campaign/${visit.idCampaign}/validation`))
    }

    const createAndDownloadEdition = (idCampaign, idInstallation) => {
        dispatch(InstallationAction.getEditionInstallation(idCampaign, idInstallation)).then(json => {
            downloadURI(json.targetPath)
        })
    }

    return (
        <>
            <Grid className='padding-left-1 padding-right-1'>
                <SimpleTextArea
                    col={12}
                    title={ i18n.observations }
                    value={visit.comments}
                    onChange={v => onChange({ comments: v })}
                    disabled={readMode}
                    rows={20}
                />
            </Grid>
            <Grid container className='padding-top-1 padding-right-1' justifyContent='flex-end' spacing={2}>
                {!summary && (
                    <Grid item hidden={summary}>
                        <Tooltip
                            placement='top-start'
                            arrow
                            PopperProps={{
                                disablePortal: true,
                            }}
                            open={openTooltip}
                            disableFocusListener
                            disableHoverListener
                            disableTouchListener
                            title={
                                <Grid style={{ padding: 5 }}>
                                    <Row>
                                        <span style={{ fontSize: '14px' }}>{i18n.sureFinalize}</span>
                                    </Row>
                                    <Row>
                                        <span style={{ fontSize: '14px' }}>{i18n.chooseFinalize}</span>
                                    </Row>
                                    <Grid container justifyContent='flex-end' className='padding-top-1'>
                                        <Button variant='outlined' color='primary' className='white margin-right-1' onClick={() => setOpenTooltip(false)}>{i18n.no}</Button>
                                        <Button onClick={finalizeVisit} variant='contained' >{i18n.yes}</Button>
                                    </Grid>
                                </Grid>
                            }
                        >
                            <Button
                                onClick={() => setOpenTooltip(true)}
                                variant='contained'
                            >
                                {i18n.finalized}
                            </Button>
                        </Tooltip>
                    </Grid>
                )}
                <Grid item>
                    {!readMode && (
                        <Button
                            onClick={() => createAndDownloadEdition(visit.idCampaign, visit.idInstallation)}
                            variant='contained'
                        >
                            {i18n.export}
                        </Button>

                    )}
                </Grid>
            </Grid>
        </>
    )
}

InstVisitConclusion.propTypes = {
    visit: PropTypes.instanceOf(DtoVisit),
    onChange: PropTypes.func,
    launchSave: PropTypes.func,
    readMode: PropTypes.bool,
    summary: PropTypes.bool,
}

export default InstVisitConclusion