/* eslint-disable camelcase */
import { Button, Dialog } from '@mui/material'
import UserDto from 'administration/components/user/dto/UserDto'
import SelectionTable from 'components/datatable/SelectionTable'
import Checkbox from 'components/forms/Checkbox'
import Input from 'components/forms/Input'
import Icon from 'components/icon/Icon'
import { DialogActionsMUI, DialogContentMUI, DialogTitleMUI } from 'components/styled/Dialog'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { nbPerPageLabelShort } from 'referencial/constants/ReferencialConstants'
import i18n from 'simple-react-i18n'
import { searchAllCharacters } from 'utils/StringUtil'
import DtoExploitation from '../../dto/DtoExploitation'

const headers = ['login', 'administrator', 'admin']

const headersUser = ['login', 'name', 'mail', 'mobile', 'tel', 'admin', 'metadata', 'organism', 'lastConnexion']

class SelectUsersModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            link_users: [...props.exploitation.link_codifications],
            searchValue: '',
        }
    }

    addUser = (user) => {
        const { link_users } = this.state
        const { exploitation } = this.props
        this.setState({
            link_users: [
                ...link_users,
                {
                    idExploitation: exploitation.idExploitation,
                    codification: user.login,
                    isActive: true,
                }],
        })
    }

    removeUser = (user) => {
        const { link_users } = this.state
        const newLinks = link_users.filter((u) => u.codification !== user.login)
        this.setState({ link_users: newLinks })
    }

    getFilteredData = (data) => {
        const { searchValue } = this.state
        const includesValue = searchAllCharacters(searchValue || '')
        return data.filter(i => this.getHash(i).includes(includesValue))
    }

    getHash = (user) => {
        return searchAllCharacters(headersUser.map(key => user[key]))
    }

    getUsers = () => {
        const { users } = this.props
        const { link_users } = this.state
        const filteredUsers = this.getFilteredData(users.filter((u) => !link_users.find((uExploit) => u.login === uExploit.codification)))
        const formatedUsers = filteredUsers.map((u) => {
            return {
                ...u,
                administrator: u.metadata === '1' ? (<Checkbox col={12} checked disabled />) : '',
                admin: u.admin === '1' ? (<Checkbox col={12} checked disabled />) : '',
            }
        })
        return [formatedUsers, headers]
    }

    getSelectedUsers = () => {
        const { users } = this.props
        const { link_users } = this.state
        const selectedUsers = link_users.map((uExploit) => {
            const userFind = users.find((u) => u.login === uExploit.codification) || {}
            return {
                ...userFind,
                administrator: userFind.metadata === '1' ? (<Checkbox col={12} checked disabled />) : '',
                admin: userFind.admin === '1' ? (<Checkbox col={12} checked disabled />) : '',
            }
        })
        const filteredUsers = this.getFilteredData(selectedUsers)
        return [filteredUsers, headers]
    }

    onCancel = () => {
        const { exploitation } = this.props
        this.setState({ link_users: exploitation.link_codifications, searchValue: '' })
        this.props.onCancel()
    }

    onValidate = () => {
        const { link_users } = this.state
        this.setState({ searchValue: '' })
        this.props.onValidate(link_users)
    }

    render() {
        const { open } = this.props
        const { searchValue } = this.state

        const listData = this.getUsers()
        const selectedData = this.getSelectedUsers()

        return (
            <Dialog
                onClose={() => this.setState({ open: false })}
                fullWidth
                maxWidth='lg'
                open={open}
            >
                <DialogTitleMUI style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    {i18n.selection}
                    <Icon style={{ color: 'white' }} size='small' icon='close' onClick={this.onCancel} />
                </DialogTitleMUI>
                <DialogContentMUI>
                    <div className='row padding-top-1 no-margin'>
                        <div className='col s4'>
                            <Input title={i18n.search} value={searchValue}
                                onChange={(value) => this.setState({ searchValue: value })}
                            />
                        </div>
                    </div>
                    <SelectionTable
                        listData={listData[0]}
                        listHeaders={listData[1]}
                        listTitle={listData[0].length > 1 ? i18n.users : i18n.user}
                        selectedData={selectedData[0]}
                        selectedHeaders={selectedData[1]}
                        selectedTitle={selectedData[0].length > 1 ? i18n.selectedUsers : i18n.selectedUser}
                        onAdd={this.addUser}
                        onDelete={this.removeUser}
                        nbPerPage={nbPerPageLabelShort}
                    />
                </DialogContentMUI>
                <DialogActionsMUI>
                    <Button onClick={this.onValidate} variant='contained' color='primary'>
                        {i18n.validate}
                    </Button>
                </DialogActionsMUI>
            </Dialog>
        )
    }
}

SelectUsersModal.propTypes = {
    users: PropTypes.arrayOf(PropTypes.instanceOf(UserDto)),
    exploitation: PropTypes.instanceOf(DtoExploitation),
    onValidate: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
}

const mapStateToProps = (store) => {
    return {
        users: store.UserReducer.users,
    }
}

export default connect(mapStateToProps)(SelectUsersModal)
