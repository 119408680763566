import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import ActionComponent from '../../../../components/ActionComponent'
import MessageCard from '../../../../components/card/MessageCard'
import DtoEvent from '../../../../events/dto/DtoEvent'
import HomeAction from '../../../../home/actions/HomeAction'
import DtoParametrageDataType from '../../../../piezometry/dto/DtoParametrageDataType'
import HydrometerStationAction from '../../../../station/actions/HydrometerStationAction'
import DtoHydrometerAdditionalMeasures from '../../../../station/dto/hydrometricStation/DtoHydrometerAdditionalMeasures'
import AppStore from '../../../../store/AppStore'
import { getStationTitle } from '../../../../utils/StationUtils'
import { arrayOf } from '../../../../utils/StoreUtils'
import HydrometryAction from '../../../actions/HydrometryAction'
import DtoHydrometricBruteMeasure from '../../../dto/DtoHydrometricBruteMeasure'
import DtoHydrometricStation from '../../../dto/DtoHydrometricStation'
import DtoHydrometryThreshold from '../../../dto/DtoHydrometryThreshold'
import HydrometerSuiviChart from './HydrometerSuiviChart'
import HydrometerSuiviTable from './HydrometerSuiviTable'

class HydrometerSuiviApp extends ActionComponent {
    constructor(props) {
        super(props)
        this.state = {
            selectedPanel: 'chart',
            dataLoaded: false,
            data: [],
        }
    }

    setTitle = (station) => {
        AppStore.dispatch(HomeAction.setTitle([{
            title: i18n.hydrometry,
            href: 'hydrometry',
        }, {
            title: getStationTitle(station),
            href: `station/hydrometry/${station.id}`,
        }, {
            title: i18n.hydrometricFollowUp,
            href: `station/hydrometry/${station.id}/hydrometryFollowUp`,
        }]))
    }

    getActive = (panel) => {
        return panel === this.state.selectedPanel ? 'active' : ''
    }

    setPanel = (panel) => {
        if (panel !== this.state.selectedPanel) {
            this.setState({ selectedPanel: panel })
        }
    }


    getPanel = () => {
        if (this.state.selectedPanel === 'tracking') {
            return <HydrometerSuiviTable hydrometricStation = { this.props.hydrometricStation }/>
        }
        if (this.state.selectedPanel === 'chart') {
            return <HydrometerSuiviChart hydrometricStation = { this.props.hydrometricStation } />
        }

        return <MessageCard>{i18n.noDataToDisplay}</MessageCard>
    }

    render() {
        return (
            <div>
                {/* <div className='row no-margin'>
                    <ul className='tabs tabs-fixed-width no-margin'>
                        <li className='tab'><a className={ this.getActive('tracking') } onClick={ () => this.setPanel('tracking') }>{ i18n.table }</a></li>
                        <li className='tab'><a className={ this.getActive('chart') } onClick={ () => this.setPanel('chart') }>{ i18n.graph }</a></li>
                    </ul>
                </div> */}
                { this.getPanel() }
            </div>
        )
    }
}

HydrometerSuiviApp.propTypes = {
    hydrometricStation: PropTypes.instanceOf(DtoHydrometricStation),
    hydrometryBruteMeasures: arrayOf(DtoHydrometricBruteMeasure),
    stationEvents: arrayOf(DtoEvent),
    hydrometryThresholds: arrayOf(DtoHydrometryThreshold),
    hydrometryDataTypes: PropTypes.arrayOf(PropTypes.instanceOf(DtoParametrageDataType)),
    hydrologicalAdditionalMeasures: PropTypes.arrayOf(PropTypes.instanceOf(DtoHydrometerAdditionalMeasures)),
    fetchHydrometryDataTypes: PropTypes.func,
    fetchHydrometricThresholds: PropTypes.func,
    loadHydrometerMeasures: PropTypes.func,
    fetchHydrologicalAdditionalMeasures: PropTypes.func,
}

const mapStateToProps = store => {
    return {
        hydrometricStation: store.HydrometryReducer.hydrometricStation,
        hydrometryBruteMeasures: store.HydrometryReducer.hydrometryBruteMeasures,
        stationEvents: store.EventsReducer.stationEvents,
        hydrometryThresholds: store.HydrometryReducer.hydrometryThresholds,
        hydrometryDataTypes: store.HydrometryReducer.hydrometryDataTypes,
        hydrologicalAdditionalMeasures: store.HydrometerStationReducer.hydrologicalAdditionalMeasures,
    }
}

const mapDispatchToProps = {
    fetchHydrometryDataTypes: HydrometryAction.fetchHydrometryDataTypes,
    fetchHydrometricThresholds: HydrometryAction.fetchHydrometricThresholds,
    loadHydrometerMeasures: HydrometerStationAction.loadHydrometerMeasures,
    fetchHydrologicalAdditionalMeasures: HydrometerStationAction.fetchHydrologicalAdditionalMeasures,
}


export default connect(mapStateToProps, mapDispatchToProps)(HydrometerSuiviApp)
