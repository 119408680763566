import React, { useMemo, useState } from 'react'
import i18n from 'simple-react-i18n'
import PropTypes from 'prop-types'
import { Button, Card, CardContent, Grid2, Icon } from '@mui/material'
import SimpleDatePicker from 'components/forms/SimpleDatePicker'
import Select from 'components/forms/Select'
import Textarea from 'components/forms/Textarea'
import { shallowEqual, useSelector } from 'react-redux'
import Input from 'components/forms/Input'
import SelectionSelect from 'components/forms/specific/SelectionSelect'
import SimpleSelectionTable from 'components/datatable/SimpleSelectionTable'
import { intersectionWith, isUndefined, keyBy } from 'lodash'
import { searchAllCharacters } from 'utils/StringUtil'
import { formatParameterSelectedPopup } from 'campaign/utils/CampaignUtils'
import { SANDRE, nbPerPageLabel } from 'referencial/constants/ReferencialConstants'
import useListIndexed from 'utils/customHook/useListIndexed'
import { getLocalizationLabel, getLocalizationPicto } from 'utils/AnalyseUtils'
import Autocomplete from 'components/forms/Autocomplete'
import useSandreList from 'utils/customHook/useSandreList'
import { ALIGN, CardTable } from 'components/datatable/NewTable'

const OperationStep = ({
    operation = {},
    setOperation = () => { },
    points = [],
}) => {
    const {
        supports,
        contributors,
    } = useSelector(store => ({
        supports: store.SupportReducer.supports,
        contributors: store.ContributorReducer.contributors,
    }), shallowEqual)

    const formattedPoints = useMemo(() => {
        return points.map(p => {
            const support = supports.find(s => s.code === `${p.codeSupport}`)
            return {
                name: `${p.name || (support?.name ?? 'Eau')} [${p.identifiant || (support?.code ?? '031')}] ${p.code ?? ''} ${p.startDepth || p.endDepth ? `${p.startDepth ?? '(...)'}m -> ${p.endDepth ?? '(...)'}m` : ''}`,
                id: p.idPoint,
            }
        })
    }, [points, supports])

    return (
        <Card>
            <CardContent>
                <Grid2 container columnSpacing={2} rowSpacing={1}>
                    <Grid2 size={4}>
                        <SimpleDatePicker
                            value={operation.date}
                            label={i18n.date}
                            onChange={date => setOperation(p => ({ ...p, date }))}
                            obligatory
                        />
                    </Grid2>
                    <Grid2 size={4}>
                        <Select
                            label={i18n.support}
                            options={supports}
                            value={operation.support}
                            onChange={support => setOperation(p => ({ ...p, support }))}
                        />
                    </Grid2>
                    <Grid2 size={4}>
                        <Select
                            label={i18n.samplePoint}
                            options={formattedPoints}
                            value={operation.point}
                            onChange={point => setOperation(p => ({ ...p, point }))}
                            obligatory
                        />
                    </Grid2>
                    <Grid2 size={4}>
                        <Select
                            options={contributors}
                            label={i18n.producer}
                            value={operation.producer}
                            nullLabel='&nbsp;'
                            keyLabel='labelDisplay'
                            displayWithCode
                            onChange={producer => setOperation(p => ({ ...p, producer }))}
                        />
                    </Grid2>
                    <Grid2 size={4}>
                        <Select
                            options={contributors}
                            label={i18n.sampler}
                            value={operation.sampler}
                            nullLabel='&nbsp;'
                            displayWithCode
                            keyLabel='labelDisplay'
                            onChange={sampler => setOperation(p => ({ ...p, sampler }))}
                        />
                    </Grid2>
                    <Grid2 size={4}>
                        <Select
                            options={contributors}
                            label={i18n.laboratory}
                            value={operation.labo}
                            nullLabel='&nbsp;'
                            displayWithCode
                            keyLabel='labelDisplay'
                            onChange={labo => setOperation(p => ({ ...p, labo }))}
                        />
                    </Grid2>
                    <Grid2 size={12}>
                        <Textarea
                            title={i18n.comment}
                            value={operation.comment}
                            onChange={comment => setOperation(p => ({ ...p, comment }))}
                        />
                    </Grid2>
                </Grid2>
            </CardContent>
        </Card>
    )
}

OperationStep.propTypes = {
    operation: PropTypes.shape({
        dateStart: PropTypes.number,
        hourStart: PropTypes.number,
        support: PropTypes.number,
        producer: PropTypes.number,
        sampler: PropTypes.number,
        labo: PropTypes.number,
        comment: PropTypes.string,
    }),
    setOperation: PropTypes.func,
    points: PropTypes.arrayOf(PropTypes.shape({})),
}

const ParameterFilterField = ({
    filter = {},
    setFilter = () => { },
}) => (
    <Grid2 container spacing={'10px'} style={{ paddingTop: '5px' }}>
        <Grid2 size={6}>
            <Input
                title={i18n.search}
                value={filter.searchValue}
                onChange={searchValue => setFilter(prev => ({ ...prev, searchValue }))}
            />
        </Grid2>
        <Grid2 size={6}>
            <SelectionSelect
                value={filter.selection}
                onChange={(listParam, selection) => setFilter(prev => ({ ...prev, selection, listParam }))}
            />
        </Grid2>
    </Grid2>
)

ParameterFilterField.propTypes = {
    filter: PropTypes.shape({
        searchValue: PropTypes.strung,
        selection: PropTypes.strung,
        listParam: PropTypes.arrayOf(PropTypes.string),
    }),
    setFilter: PropTypes.func,
}

const ParameterStep = ({
    analysis = [],
    setAnalysis = () => { },
}) => {
    const {
        parameters,
    } = useSelector(store => ({
        parameters: store.ParameterReducer.parameters,
    }), shallowEqual)

    const activeParameters = useMemo(() => formatParameterSelectedPopup(parameters.filter(p => p.active)), [parameters])

    const selectedParameters = analysis.map(a => a.parameter)

    const onChange = listParameter => {
        setAnalysis(prevAnalysis => {
            const indexedAnalysis = keyBy(prevAnalysis, 'parameter')
            return listParameter.map(p => indexedAnalysis[p] ?? { parameter: p })
        })
    }

    return (
        <Grid2 container>
            <Grid2 size={12}>
                <SimpleSelectionTable
                    onChange={onChange}
                    title={i18n.pesticidesSelection}

                    listData={activeParameters}
                    selectedList={selectedParameters}

                    listHeaders={['name', 'type', 'nature', 'status']}
                    listTitle={i18n.nonSelectedParameters}
                    selectedListTitle={i18n.selectedParameters}
                    maxHeightTable={'43vh'}

                    filterField={ParameterFilterField}
                    filterFunction={(list, { selection = '-1', listParam, searchValue }) => {
                        const filterSelection = selection !== '-1' ? intersectionWith(list, listParam, (elem, id) => elem.id === id) : list
                        const searchValueFormat = searchAllCharacters(searchValue)
                        return searchValue ? filterSelection.filter(p => p.labelSearch.includes(searchValueFormat)) : filterSelection
                    }}
                />
            </Grid2>
        </Grid2>
    )
}

ParameterStep.propTypes = {
    analysis: PropTypes.arrayOf(PropTypes.shape({
        parameter: PropTypes.string,
    })),
    setAnalysis: PropTypes.func,
}

const AnalysisFields = ({
    analysisFields = {},
    setAnalysisFields = () => { },
    nbSelectedAnalysis = 0,
    onValidate = () => { },
}) => {
    const {
        units,
        fractions,
        remarks,
    } = useSelector(store => ({
        units: store.UnitReducer.units,
        fractions: store.FractionReducer.fractions,
        remarks: store.OperationReducer.remarks,
    }), shallowEqual)

    const analysisPlace = useSandreList('ANALYSES.LIEU')
    const analysisAccreditation = useSandreList(SANDRE.ANALYSES_ACCREDITATION)
    const analysisDifficulty = useSandreList(SANDRE.ANALYSES_DIFFICULTE)

    return (
        <Card>
            <CardContent>
                <Grid2 container columnSpacing={2} rowSpacing={1} alignItems='center'>
                    <Grid2 size={3}>
                        <Autocomplete
                            label={i18n.unit}
                            options={units}
                            value={analysisFields.unit}
                            onChange={unit => setAnalysisFields(prevFields => ({ ...prevFields, unit }))}
                            keyLabel='symbolWithName'
                            keyFilter={['name', 'symbol', 'code']}
                        />
                    </Grid2>
                    <Grid2 size={3}>
                        <Select
                            col={3}
                            label={i18n.fraction}
                            options={fractions}
                            value={analysisFields.fraction}
                            keyValue='id'
                            onChange={fraction => setAnalysisFields(prevFields => ({ ...prevFields, fraction }))}
                            clearFunction
                            displayWithCode
                        />
                    </Grid2>
                    <Grid2 size={3}>
                        <Select
                            col={6}
                            label={i18n.place}
                            options={analysisPlace}
                            value={analysisFields.localization}
                            onChange={localization => setAnalysisFields(prevFields => ({ ...prevFields, localization: !isUndefined(localization) ? `${localization}` : undefined }))}
                            clearFunction
                            keyLabel='labelWithCode'
                        />
                    </Grid2>
                    <Grid2 size={3}>
                        <span style={{ paddingLeft: 10, fontSize: 14, color: '#222450' }}>
                            {`${nbSelectedAnalysis} ${nbSelectedAnalysis > 1 ? i18n.selectedAnalysis : i18n.selectedOneAnalysis}`}
                        </span>
                    </Grid2>
                    <Grid2 size={3}>
                        <Select
                            col={6}
                            options={remarks}
                            value={analysisFields.remark}
                            label={i18n.remark}
                            keyLabel='nameWithCode'
                            keyValue='code'
                            returnNull
                            noSort
                            onChange={remark => setAnalysisFields(prevFields => ({ ...prevFields, remark }))}
                        />
                    </Grid2>
                    <Grid2 size={3}>
                        <Select
                            col={3}
                            options={analysisAccreditation}
                            value={analysisFields.accreditation}
                            label={i18n.accreditation}
                            returnNull
                            onChange={accreditation => setAnalysisFields(prevFields => ({ ...prevFields, accreditation }))}
                        />
                    </Grid2>
                    <Grid2 size={3}>
                        <Select
                            col={3}
                            options={analysisDifficulty}
                            value={analysisFields.difficulty}
                            label={i18n.difficulty}
                            returnNull
                            onChange={difficulty => setAnalysisFields(prevFields => ({ ...prevFields, difficulty }))}
                        />
                    </Grid2>
                    <Grid2 size={3}>
                        <Button
                            onClick={onValidate}
                            variant='contained'
                            color='primary'
                            fullWidth
                        >
                            {i18n.validate}
                        </Button>
                    </Grid2>
                </Grid2>
            </CardContent>
        </Card>
    )
}

AnalysisFields.propTypes = {
    analysisFields: PropTypes.shape({
        unit: PropTypes.string,
        fraction: PropTypes.string,
        localization: PropTypes.string,
        remark: PropTypes.string,
        accreditation: PropTypes.number,
        difficulty: PropTypes.number,
    }),
    setAnalysisFields: PropTypes.func,
    nbSelectedAnalysis: PropTypes.number,
    onValidate: PropTypes.func,
}

const AnalysisStep = ({
    analysis = [],
    setAnalysis = () => { },
}) => {
    const {
        parameters,
        units,
        fractions,
        remarks,
    } = useSelector(store => ({
        parameters: store.ParameterReducer.parameters,
        units: store.UnitReducer.units,
        fractions: store.FractionReducer.fractions,
        remarks: store.OperationReducer.remarks,
    }), shallowEqual)

    const analysisAccreditation = useSandreList(SANDRE.ANALYSES_ACCREDITATION)
    const analysisDifficulty = useSandreList(SANDRE.ANALYSES_DIFFICULTE)

    const indexedParameters = useListIndexed(parameters, 'code')
    const indexedUnits = useListIndexed(units, 'code')
    const indexedFractions = useListIndexed(fractions, 'code')

    const [selectedAnalysis, setSelectedAnalysis] = useState({}) // key: parameter code, value: boolean
    const [filter, setFilter] = useState({})
    const [analysisFields, setAnalysisFields] = useState({})

    const isSomeAnalysisSelected = Object.keys(selectedAnalysis).some(key => selectedAnalysis[key])

    const formattedAnalysis = useMemo(() => {
        return analysis.map(a => {
            const isSelected = selectedAnalysis[a.parameter]
            const parameter = indexedParameters[a.parameter]
            const unit = indexedUnits[a.unit]
            const fraction = indexedFractions[a.fraction]

            const remark = remarks.find(r => r.code === a.remark)?.nameWithCode
            const accreditation = analysisAccreditation.find(accre => accre.code === a.accreditation)?.name
            const difficulty = analysisDifficulty.find(diff => diff.code === a.difficulty)?.name

            return {
                parameter: parameter?.displayLabel,
                unit: unit?.symbolWithCode,
                fraction: fraction?.labelWithCode,
                place: !isUndefined(a.localization) && getLocalizationPicto(a.localization),
                check: (<Icon>{isSelected ? 'check_box' : 'check_box_outline_blank'}</Icon>),
                colorLine: isSelected ? '#b8d2ff' : '#fff',
                code: a.parameter,
                remark,
                accreditation,
                difficulty,
                searchValue: [parameter?.name, parameter?.shortLabel, parameter?.code, parameter?.internalCode, unit?.symbolWithCode, fraction?.labelWithCode, remark, accreditation, difficulty, getLocalizationLabel(a.localization)].map((label = '') => searchAllCharacters(label)),
            }
        })
    }, [analysis, analysisAccreditation, analysisDifficulty, indexedFractions, indexedParameters, indexedUnits, remarks, selectedAnalysis])


    const filteredAnalysis = useMemo(() => {
        const { selection = '-1', listParam, searchValue } = filter
        const filterSelection = selection !== '-1' ? intersectionWith(formattedAnalysis, listParam, (a, code) => a.code === code) : formattedAnalysis
        const searchValueFormat = searchAllCharacters(searchValue)
        return searchValue ? filterSelection.filter(p => p.searchValue.some(v => v.includes(searchValueFormat))) : filterSelection
    }, [filter, formattedAnalysis])

    const isAllAnalysisSelected = filteredAnalysis.every(a => selectedAnalysis[a.code])

    const onClick = () => {
        setSelectedAnalysis(prevSelected => {
            return filteredAnalysis.reduce((acc, a) => {
                acc[a.code] = !isAllAnalysisSelected
                return acc
            }, { ...prevSelected })
        })
    }

    const actions = filteredAnalysis.length ? [{
        icon: isAllAnalysisSelected ? 'check_box' : 'check_box_outline_blank',
        tooltip: i18n.edit,
        onClick,
    }] : []

    return (
        <Grid2 container justifyContent='center' rowSpacing={1}>
            <Grid2 size={12}>
                <Card>
                    <CardContent>
                        <ParameterFilterField
                            filter={filter}
                            setFilter={setFilter}
                        />
                    </CardContent>
                </Card>
            </Grid2>
            <Grid2 size={12}>
                <CardTable
                    title={i18n.analysis}
                    actions={actions}

                    rows={filteredAnalysis}
                    headers={['parameter', 'unit', 'fraction', 'remark', 'accreditation', 'difficulty', 'place', { key: 'check', value: '', sticky: ALIGN.RIGHT }]}

                    maxHeight={isSomeAnalysisSelected ? '36vh' : '45vh'}
                    onClickRow={({ code }) => setSelectedAnalysis(prevSelected => ({ ...prevSelected, [code]: !prevSelected[code] }))}
                    rowsPerPageOptions={nbPerPageLabel}
                />
            </Grid2>
            {
                isSomeAnalysisSelected && (
                    <Grid2 size={12}>
                        <AnalysisFields
                            analysisFields={analysisFields}
                            setAnalysisFields={setAnalysisFields}
                            nbSelectedAnalysis={Object.keys(selectedAnalysis).filter(key => selectedAnalysis[key]).length}
                            onValidate={() => {
                                setAnalysis(prevAnalysis => {
                                    return prevAnalysis.map(a => {
                                        if (selectedAnalysis[a.parameter]) {
                                            return { parameter: a.parameter, ...analysisFields }
                                        }
                                        return a
                                    })
                                })
                                setAnalysisFields({})
                                setSelectedAnalysis({})
                            }}
                        />
                    </Grid2>
                )
            }
        </Grid2>
    )
}

AnalysisStep.propTypes = {
    analysis: PropTypes.arrayOf(PropTypes.shape({
        parameter: PropTypes.string,
    })),
    setAnalysis: PropTypes.func,
}

export {
    AnalysisStep,
    OperationStep,
    ParameterStep,
}