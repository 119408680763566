import React, { useState } from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import i18n from 'simple-react-i18n'
import Row from '../../../../../components/react/Row'
import PropTypes from 'prop-types'
import Select from '../../../../../components/forms/Select'
import { arrayOf } from '../../../../../utils/StoreUtils'
import NetworkDto from '../../../../../referencial/components/network/dto/NetworkDto'

const OperationNetworkModal = ({
    isOpen = false,
    onValidate = () => {},
    onClose = () => { },
    selectedNetwork = {},
    networks = [],
}) => {
    const [network, setNetwork] = useState(selectedNetwork)
    return (
        <Dialog
            fullWidth
            maxWidth='sm'
            open={isOpen}
            onClose={() => {}}
        >
            <DialogTitle>{i18n.network}</DialogTitle>
            <DialogContent>
                <Row>
                    <Select
                        options={ networks }
                        value={ network }
                        keyLabel='nameWithSandre'
                        keyValue='sandreCode'
                        onChange={ setNetwork }
                    />
                </Row>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} variant='outlined' >
                    {i18n.cancel}
                </Button>
                <Button onClick={() => onValidate(network) } variant='contained' color='primary'>
                    {i18n.save}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

OperationNetworkModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onValidate: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    onReject: PropTypes.func,
    selectedNetwork: PropTypes.string,
    networks: arrayOf(NetworkDto),
}

export default OperationNetworkModal