import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import Input from '../../../components/forms/Input'
import FilterInsertModal from '../../../components/modal/FilterInsertModal'
import { hasValue } from '../../../utils/NumberUtil'
import Card from 'components/card/Card'
import { Button, Grid } from '@mui/material'
import MultiContributorsAutocomplete from 'referencial/components/contributor/components/MultiContributorsAutocomplete'
import useBoolean from 'utils/customHook/useBoolean'
import AdministrationAction from 'administration/actions/AdministrationAction'
import SuperMultiAutocomplete from 'components/forms/SuperMultiAutocomplete'
import SimpleFilterSelect from 'components/forms/specific/SimpleFilterSelect'
import { getUser } from 'utils/SettingUtils'
import { getTypeModule } from 'utils/StationUtils'
import DtoNetwork from 'station/dto/DtoNetwork'
import DtoStation from 'station/dto/DtoStation'
import { isUndefined, uniq } from 'lodash'
import DtoContributorLink from 'station/dto/DtoContributorLink'
import DtoPiezometerContributorLink from 'piezometry/dto/DtoPiezometerContributorLink'
import useApplicationSetting, { intParser } from 'utils/customHook/useApplicationSetting'
import { DEFAULT_CONTRIBUTOR_TYPE } from 'administration/components/user/constants/UserConstants'


const StationFilterFields = ({
    defaultFilter = {},
    onValidate = () => { },
    stationType,
    stations,
    networklist = [],
    contributorLinks = [],
}) => {
    const dispatch = useDispatch()
    const MODULE = getTypeModule(stationType)
    const {
        value: isOpen,
        setTrue: openPopin,
        setValue: setOpen,
    } = useBoolean(false)

    const {
        contributorsIndex,
    } = useSelector(store => ({
        contributorsIndex: store.ContributorReducer.contributorsIndex,
    }), shallowEqual)
    const operatorType = useApplicationSetting('contributorTypeOperator', intParser) || DEFAULT_CONTRIBUTOR_TYPE.OPERATOR
    const referentType = useApplicationSetting('contributorTypeAdministrator', intParser) || DEFAULT_CONTRIBUTOR_TYPE.ADMINISTRATOR

    const referentsList = uniq(contributorLinks.filter(c => c.contributorType === referentType).map(l => l.idContributor)).map(id => contributorsIndex[id]).filter(c => !isUndefined(c))
    const operatorsList = uniq(contributorLinks.filter(c => c.contributorType === operatorType).map(l => l.idContributor)).map(id => contributorsIndex[id]).filter(c => !isUndefined(c))

    const [searchValue, setSearchValue] = useState(defaultFilter.searchValue)
    const [referentIds, setReferentIds] = useState(defaultFilter.referentIds ?? [])
    const [operatorIds, setOperatorIds] = useState(defaultFilter.operatorIds ?? [])
    const [filter, setFilter] = useState(defaultFilter.filter)
    const [filterResults, setFilterResults] = useState([])
    const [filterParameters, setFilterParameters] = useState([])
    const [isValidateOpen, setIsOpenValidate] = useState()
    const [network, setNetwork] = useState(defaultFilter.network)

    const onLaunchSearch = (value) => {
        dispatch(AdministrationAction.setCache(stationType, { searchValue: value || searchValue, referentIds, operatorIds, network }))
        dispatch(AdministrationAction.setSelectedSearchValues(stationType, { searchValue: value || searchValue, referentIds, operatorIds, network }))

        if (filterParameters?.length) {
            setIsOpenValidate(true)
        } else {
            onValidate({
                searchValue: value || searchValue,
                filter,
                filterResults,
                network,
                referentIds,
                operatorIds,
            })
        }
    }

    return (
        <Card noMargin={false} round padding={10} >
            <Grid container alignItems='end' justifyContent='space-between' padding={'10px'}>
                <Grid container item xs={9} spacing={1}>
                    <Grid item xs={3}>
                        <Input
                            tooltip={i18n.searchThenEnter}
                            title={i18n.search}
                            value={searchValue}
                            onChange={setSearchValue}
                            onEnterKeyPress={(value) => {
                                setSearchValue(value)
                                onLaunchSearch(value)
                            }}
                            clearFunction
                        />
                    </Grid>
                    <Grid container item xs={3}>
                        <Grid item xs={11}>
                            <SimpleFilterSelect
                                stations={stations}
                                stationType={stationType}
                                onChange={(results, newFilter, newFilterParams) => {
                                    setFilterResults(results)
                                    setFilter(newFilter)
                                    setFilterParameters(newFilterParams)
                                }}
                                isValidateOpen={isValidateOpen}
                                launchSearch={(results, newFilter) => {
                                    onValidate({
                                        searchValue,
                                        filter: newFilter,
                                        filterResults: results,
                                        network,
                                        referentIds,
                                        operatorIds,
                                    })
                                }}
                                setIsOpenValidate={v => setIsOpenValidate(v)}
                            />
                        </Grid>
                        <Grid item xs={1}>
                            {getUser().isAdmin == 1 && (
                                <div onClick={openPopin}>
                                    <i className='material-icons qualityFilterAddIcon clickable' id='add_filter_icon'>
                                        {hasValue(filter) && filter !== -5 && filter !== -1 ? 'edit' : 'note_add'}
                                    </i>
                                </div>
                            )}
                            <FilterInsertModal
                                setOpen={setOpen}
                                open={isOpen}
                                selectedFilter={filter}
                                module={MODULE}
                                isNew={!hasValue(filter) || filter === -5 || filter === -1}
                                onChange={setFilter}
                            />
                        </Grid>
                    </Grid>
                    {!!referentsList.length && (
                        <Grid item xs={3}>
                            <MultiContributorsAutocomplete
                                label={i18n.administrator}
                                options={referentsList}
                                onChange={setReferentIds}
                                keyValue='code'
                                multiple
                            />
                        </Grid>
                    )}
                    {!!operatorsList.length && (
                        <Grid item xs={3}>
                            <MultiContributorsAutocomplete
                                label={i18n.operator}
                                options={operatorsList}
                                onChange={setOperatorIds}
                                keyValue='code'
                                multiple
                            />
                        </Grid>
                    )}
                    {!!networklist.length && (
                        <Grid item xs={3}>
                            <SuperMultiAutocomplete
                                label={i18n.network}
                                options={networklist}
                                keyLabel='labelWithSandre'
                                additionalKeysFilter={['sandreCode', 'name', 'mnemonic']}
                                onChange={setNetwork}
                                values={network}
                            />
                        </Grid>
                    )}
                </Grid>
                <Grid item xs={2}>
                    <Button
                        variant='contained'
                        fullWidth
                        onClick={() => onLaunchSearch()}
                    >
                        {i18n.search}
                    </Button>
                </Grid>
            </Grid>
        </Card>
    )
}

StationFilterFields.propTypes = {
    defaultFilter: PropTypes.shape({
        filter: PropTypes.number,
        network: PropTypes.number,
        referentIds: PropTypes.arrayOf(PropTypes.number),
        operatorIds: PropTypes.arrayOf(PropTypes.number),
        searchValue: PropTypes.string,
    }),
    onValidate: PropTypes.func,
    stationType: PropTypes.string,
    module: PropTypes.string,
    stations: PropTypes.arrayOf(PropTypes.instanceOf(DtoStation)),
    networklist: PropTypes.arrayOf(PropTypes.instanceOf(DtoNetwork)),
    contributorLinks: PropTypes.arrayOf(PropTypes.oneOfType([
        PropTypes.instanceOf(DtoContributorLink),
        PropTypes.instanceOf(DtoPiezometerContributorLink),
    ])),
}

export default StationFilterFields
