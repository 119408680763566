export default class SolutionDto {
    constructor(object) {
        this.code = object.codeSolution
        this.codeSolution = object.codeSolution
        this.description = object.description
        this.name = object.description
        this.active = object.active
        this.updateDate = object.updateDate
        this.updateLogin = object.updateLogin
        this.materialChange = object.materialChange
        this.managementRule = object.managementRule
        this.value = object.value
        this.materielId = object.materielId
        this.comment = object.comment
    }
}
