module.exports = {
    RECEIVE_ALL_CULTURE: 'RECEIVE_ALL_CULTURE',
    RECEIVE_ALL_CULTURES_VENTILATIONS: 'RECEIVE_ALL_CULTURES_VENTILATIONS',
    RECEIVE_CULTURE: 'RECEIVE_CULTURE',
    CULTURES: 'CULTURES',
    CULTURES_RPG: 'CULTURES_RPG',
    CULTURES_FAMLILIES: 'CULTURES_FAMLILIES',
    RESET_CULTURE: 'RESET_CULTURE',
    seasonList: [
        { value: 0, label: 'Pluriannuelle' },
        { value: 1, label: 'Printemps' },
        { value: 2, label: 'Automne' },
    ],
}
