import { isNull } from 'lodash'

export default class DtoPiezometerLight {
    constructor(obj = []) {
        const piezo = obj.map(e => isNull(e) ? undefined : e)
        this.id = piezo[0]
        this.code = piezo[1]
        this.name = piezo[2]
        this.x = piezo[3]
        this.y = piezo[4]
        this.projection = piezo[5]
        this.townCode = piezo[6]
        this.typeName = 'piezometry'
    }
}
