import React from 'react'
import PropTypes from 'prop-types'
import ReactECharts from 'echarts-for-react'
import echarts from 'echarts/lib/echarts'
import { formatMilliers } from 'utils/StringUtil'
import i18n from 'simple-react-i18n'
import { colorLowWater, colorNotLowWater } from 'agriAdministration/constants/AgriConstants'

export const RepartitionPARChart = ({
    datas = [],
}) => {
    const askedVolumesLowWater = datas.reduce((acc, d) => acc + (d.lowWaterAsked || 0), 0)
    const askedVolumesNotLowWater = datas.reduce((acc, d) => acc + (d.notLowWaterAsked || 0), 0)
    const allocatedVolumesLowWater = datas.reduce((acc, d) => acc + (d.lowWaterAllocated || 0), 0)
    const allocatedVolumesNotLowWater = datas.reduce((acc, d) => acc + (d.notLowWaterAllocated || 0), 0)
    const AUPVolumesLowWater = datas.reduce((acc, d) => acc + (d.lowWaterAUP || 0), 0)
    const AUPVolumesNotLowWater = datas.reduce((acc, d) => acc + (d.notLowWaterAUP || 0), 0)

    const reservedVolumesLowWater = AUPVolumesLowWater - allocatedVolumesLowWater
    const reservedVolumesNotLowWater = AUPVolumesNotLowWater - allocatedVolumesNotLowWater

    const option = {
        xAxis: {
            type: 'category',
            data: [i18n.asked, i18n.allocated, i18n.reserve, i18n.AUP],
        },
        yAxis: {
            type: 'value',
        },
        series: [
            {
                name: i18n.lowWater,
                data: [askedVolumesLowWater, allocatedVolumesLowWater, (reservedVolumesLowWater < 0 ? 0 : reservedVolumesLowWater), AUPVolumesLowWater],
                type: 'bar',
                stack: 'total',
                itemStyle: { color: colorLowWater },
            },
            {
                name: i18n.notLowWater,
                data: [askedVolumesNotLowWater, allocatedVolumesNotLowWater, (reservedVolumesNotLowWater < 0 ? 0 : reservedVolumesNotLowWater), AUPVolumesNotLowWater],
                type: 'bar',
                stack: 'total',
                itemStyle: { color: colorNotLowWater },
            },
        ],
        legend: {
            data: [ i18n.lowWater, i18n.notLowWater ],
            orient: 'vertical',
            top: 'center',
            right: '0%',
        },
        grid: {
            left: '0%',
            right: '22%',
            bottom: '0%',
            top: '5%',
            containLabel: true,
        },
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'shadow',
            },
            formatter: (params) => {
                return params[0]?.name + params.map((p) => `<br/>${p.seriesName} : ${formatMilliers(p.value)} m3`).reduce((acc, p) => acc + p, '')
            },
        },
    }

    return (
        <div>
            <ReactECharts
                echarts={echarts}
                option={option}
                notMerge={ true }
                lazyUpdate={true}
                className={'row no-margin'}
                style={{ height: 200, minWidth: 500 }}
            />
        </div>
    )
}

RepartitionPARChart.propTypes = {
    datas: PropTypes.array,
}