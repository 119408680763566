import {
    RECEIVE_ALL_TELECOM_SITUATIONS,
    RECEIVE_ALL_TELECOMS,
    RECEIVE_TELECOM,
    RECEIVE_TELECOM_FILES,
    RECEIVE_TELECOM_PICTURES,
    RECEIVE_TELECOMS_LAST_SITUATIONS,
    RECEIVE_TELECOMS_TYPES,
    RESET_TELECOM,
} from '../constants/TelecomConstants'
import ApplicationConf from '../../../../conf/ApplicationConf'
import {
    checkAuth,
    checkAuthWithoutKicking,
    checkError,
    getAuthorization,
    getJson,
} from '../../../../utils/ActionUtils'
import ToastrAction from 'toastr/actions/ToastrAction'
import i18n from 'simple-react-i18n'
import fetch from 'isomorphic-fetch'
import { hasValue } from '../../../../utils/NumberUtil'
import { push } from '@lagunovsky/redux-react-router'
import { setMessageModal } from '../../../../utils/FormUtils'
import LogAction from '../../../../log/actions/LogAction'

const TelecomAction = {
    receiveTelecoms(telecoms) {
        return { type: RECEIVE_ALL_TELECOMS, telecoms }
    },

    promiseTelecoms(withEquipment) {
        return fetch(ApplicationConf.materiel.allTelecoms(withEquipment), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
    },
    fetchTelecoms(withEquipment) {
        return (dispatch) => {
            return TelecomAction.promiseTelecoms(withEquipment)
                .then((json = []) => {
                    dispatch(TelecomAction.receiveTelecoms(json))
                    if (json.length) {
                        dispatch(TelecomAction.fetchTelecomsLastSituations())
                    }
                })
        }
    },

    receiveTelecom(telecom) {
        return { type: RECEIVE_TELECOM, telecom }
    },

    promiseTelecom(id) {
        return fetch(ApplicationConf.materiel.telecom(id), {
            headers: getAuthorization(),
        })
            .then(checkAuthWithoutKicking)
            .then(getJson)
            .then(checkError)
    },

    fetchTelecom: id => dispatch => {
        return TelecomAction.promiseTelecom(id)
            .then((json = {}) => {
                dispatch(TelecomAction.receiveTelecom(json))
                return json
            })
            .catch(e => {
                dispatch(LogAction.logError(`${i18n.fetchError + i18n.telecom} : ${e}`))
                dispatch(ToastrAction.error(i18n.fetchError + i18n.telecom))
            })
    },

    receiveTelecomTypes(telecomTypes) {
        return { type: RECEIVE_TELECOMS_TYPES, telecomTypes }
    },

    promiseTelecomTypes(withEquipmentType) {
        return fetch(ApplicationConf.materiel.telecomAllTypes(withEquipmentType), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
    },

    fetchTelecomTypes(withEquipmentType) {
        return (dispatch) => {
            return TelecomAction.promiseTelecomTypes(withEquipmentType)
                .then((json = {}) => {
                    dispatch(TelecomAction.receiveTelecomTypes(json))
                })
        }
    },

    updateTelecomTypes(id, materielType) {
        return (dispatch) => {
            return fetch(ApplicationConf.materiel.telecomType(id), {
                method: 'PUT',
                headers: getAuthorization(),
                body: JSON.stringify(materielType),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(() => {
                    dispatch(TelecomAction.fetchTelecomTypes(true))
                    dispatch(ToastrAction.success(i18n.saveSuccess))
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.updateError + i18n.materielType} : ${err}`))
                    dispatch(ToastrAction.error(i18n.updateError + i18n.materielType))
                })
        }
    },

    deleteTelecomType(id, callback = () => { }) {
        return (dispatch) => {
            return fetch(ApplicationConf.materiel.telecomType(id), {
                method: 'DELETE',
                headers: getAuthorization(),
            })
                .then(r => checkAuth(r, {
                    206: () => setMessageModal(i18n.deletingUsedMaterielTypeMessage),
                }))
                .then(getJson)
                .then(checkError)
                .then(json => {
                    if (json.delete >= 1) {
                        dispatch(TelecomAction.fetchTelecomTypes(true))
                        dispatch(ToastrAction.success(i18n.elementDeleteSuccess))
                        callback()
                    } else {
                        throw new Error()
                    }
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.deleteError + i18n.materielType} : ${err}`))
                    dispatch(ToastrAction.error(i18n.deleteError + i18n.materielType))
                })
        }
    },

    addTelecomTypes(materielType, callback = () => { }) {
        return (dispatch) => {
            return fetch(ApplicationConf.materiel.telecomTypes(), {
                method: 'POST',
                headers: getAuthorization(),
                body: JSON.stringify(materielType),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(json => {
                    if (json.insert === 1 && json.id) {
                        dispatch(TelecomAction.fetchTelecomTypes(true))
                        dispatch(ToastrAction.success(i18n.addSuccess))
                        callback({ ...materielType, materielType: json.id })
                    } else {
                        throw new Error()
                    }
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.createError + i18n.materielType} : ${err}`))
                    dispatch(ToastrAction.error(i18n.createError + i18n.materielType))
                })
        }
    },

    receiveTelecomSituations(telecomSituations) {
        return { type: RECEIVE_ALL_TELECOM_SITUATIONS, telecomSituations }
    },

    fetchTelecomSituations(id) {
        return (dispatch) => {
            return fetch(ApplicationConf.materiel.telecomSituations(id), {
                method: 'GET',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(json => {
                    dispatch(TelecomAction.receiveTelecomSituations(json))
                })
        }
    },

    saveTelecomSituation(situation, shouldFetch = true) {
        return (dispatch) => {
            return fetch(ApplicationConf.materiel.addTelecomSituation(), {
                method: 'POST',
                headers: getAuthorization(),
                body: JSON.stringify(situation),
            })
                .then(() => {
                    dispatch(TelecomAction.fetchTelecomsLastSituations())
                    dispatch(ToastrAction.success(i18n.situationAddSuccess))
                    if (shouldFetch) {
                        dispatch(TelecomAction.fetchTelecomSituations(situation.idTelecom))
                    }
                })
        }
    },
    deleteTelecomSituation(id, idTelecom) {
        return (dispatch) => {
            return fetch(ApplicationConf.materiel.deleteTelecomSituation(id), {
                method: 'DELETE',
                headers: getAuthorization(),
            })
                .then(() => {
                    dispatch(TelecomAction.fetchTelecomsLastSituations())
                    dispatch(ToastrAction.success(i18n.situationDeleteSuccess))
                    dispatch(TelecomAction.fetchTelecomSituations(idTelecom))
                })
        }
    },
    updateTelecomSituation(idSituation, situation, openCampaginModal = undefined) {
        return (dispatch) => {
            return fetch(ApplicationConf.materiel.saveTelecomSituation(idSituation), {
                method: 'PUT',
                headers: getAuthorization(),
                body: JSON.stringify(situation),
            })
                .then(() => {
                    dispatch(TelecomAction.fetchTelecomsLastSituations())
                    dispatch(ToastrAction.success(i18n.saveSuccess))
                    if (openCampaginModal) {
                        openCampaginModal(situation)
                    }
                })
        }
    },
    createEventAndUpdateSituation(urlEvent, event, situation, openCampaginModal = undefined) {
        return (dispatch) => {
            return fetch(urlEvent, {
                method: 'POST',
                headers: getAuthorization(),
                body: JSON.stringify(event),
            }).then(getJson)
                .then((json) => {
                    if (json.eventId) {
                        const result = Object.assign({}, situation, {
                            eventCode: json.eventId,
                        })
                        dispatch(TelecomAction.updateTelecomSituation(result.id, result))
                        if (openCampaginModal) {
                            dispatch(openCampaginModal(result))
                        }
                    } else {
                        dispatch(ToastrAction.error(i18n.saveError))
                    }
                })
        }
    },
    promiseTelecomSituation(id) {
        return fetch(ApplicationConf.materiel.telecomSituations(id), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
    },

    receiveTelecomLastSituations(telecomsLastSituations) {
        return { type: RECEIVE_TELECOMS_LAST_SITUATIONS, telecomsLastSituations }
    },

    promiseTelecomLastSituation: () => fetch(ApplicationConf.materiel.telecomsLastSituation(), {
        method: 'GET',
        headers: getAuthorization(),
    })
        .then(checkAuth)
        .then(getJson)
        .then(checkError),

    fetchTelecomsLastSituations: () => dispatch => TelecomAction.promiseTelecomLastSituation()
        .then((json = []) => {
            dispatch(TelecomAction.receiveTelecomLastSituations(json))
        }),

    receiveTelecomPictures(telecomPictures) {
        return {
            type: RECEIVE_TELECOM_PICTURES,
            telecomPictures,
        }
    },

    fetchTelecomPictures(id) {
        return (dispatch) => {
            return fetch(ApplicationConf.materiel.telecomPicture(id), {
                method: 'GET',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then((json = []) => {
                    dispatch(TelecomAction.receiveTelecomPictures(json))
                })
                .catch((err) => {
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.pictures} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError + i18n.pictures))
                })
        }
    },

    receiveTelecomFiles(telecomFiles) {
        return {
            type: RECEIVE_TELECOM_FILES,
            telecomFiles,
        }
    },

    fetchTelecomFiles(id) {
        return (dispatch) => {
            return fetch(ApplicationConf.materiel.telecomFile(id), {
                method: 'GET',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then((json = []) => {
                    dispatch(TelecomAction.receiveTelecomFiles(json))
                })
                .catch((err) => {
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.files} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError + i18n.files))
                })
        }
    },

    createTelecom(telecom) {
        return (dispatch) => {
            return fetch(ApplicationConf.materiel.telecoms(), {
                method: 'POST',
                headers: getAuthorization(),
                body: JSON.stringify(telecom),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(json => {
                    if (json.insert === 1 && hasValue(json.id)) {
                        dispatch(TelecomAction.fetchTelecoms(true))
                        dispatch(push(`/materiel/telecom/${json.id}`))
                        dispatch(ToastrAction.success(i18n.elementCreateSuccess))
                        return json.id
                    }
                    throw new Error(json)
                })
                .catch(err => {
                    const label = i18n.updateError + (err.message === '409 Conflict' ? i18n.immoNumberOrSerialNumberAlreadyUse : i18n.telecom)
                    dispatch(LogAction.logError(`${label} ${err}`))
                    dispatch(ToastrAction.error(label))
                })
        }
    },

    deleteTelecom(id) {
        return (dispatch) => {
            return fetch(ApplicationConf.materiel.telecom(id), {
                method: 'DELETE',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(() => {
                    dispatch(TelecomAction.fetchTelecoms(true))
                    dispatch(push('/materiel/telecom'))
                    dispatch(ToastrAction.success(i18n.elementDeleteSuccess))
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.deleteError + i18n.telecom} : ${err}`))
                    dispatch(ToastrAction.error(i18n.deleteError + i18n.telecom))
                })
        }
    },
    updateTelecom(telecom) {
        return (dispatch) => {
            return fetch(ApplicationConf.materiel.telecom(telecom.id), {
                method: 'PUT',
                headers: getAuthorization(),
                body: JSON.stringify(telecom),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(json => {
                    if (json.update === 1) {
                        dispatch(TelecomAction.fetchTelecoms(true))
                        dispatch(ToastrAction.success(i18n.elementUpdateSuccess))
                        dispatch(TelecomAction.fetchTelecom(telecom.id))
                        return true
                    }
                    throw new Error(json)
                })
                .catch(err => {
                    const label = i18n.updateError + (err.message === '409 Conflict' ? i18n.immoNumberOrSerialNumberAlreadyUse : i18n.telecom)
                    dispatch(LogAction.logError(`${label} ${err}`))
                    dispatch(ToastrAction.error(label))
                })
        }
    },
    resetTelecom() {
        return {
            type: RESET_TELECOM,
        }
    },
}

export default TelecomAction
