import { push } from '@lagunovsky/redux-react-router'
import { isEqual, omit, template } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import ToastrAction from 'toastr/actions/ToastrAction'
import UserAction from '../../../administration/components/user/actions/UserAction'
import ActionComponent from '../../../components/ActionComponent'
import Card from '../../../components/card/Card'
import Table from '../../../components/datatable/Table'
import Input from '../../../components/forms/Input'
import NumberField from '../../../components/forms/NumberField'
import Select from '../../../components/forms/Select'
import Textarea from '../../../components/forms/Textarea'
import Row from '../../../components/react/Row'
import CityDto from '../../../referencial/components/city/dto/CityDto'
import ContactItem from '../../../referencial/components/contact/dto/ContactItem'
import { nbPerPageLabel, SANDRE } from '../../../referencial/constants/ReferencialConstants'
import DtoSandreCode from '../../../referencial/dto/DtoSandreCode'
import LinkedStationsPanel from '../../../station/components/associatedStation/LinkedStationsPanel'
import StationMapDashboardPanel from '../../../station/components/dashboard/component/map/StationMapDashboardPanel'
import StationContactsPanel from '../../../station/components/link/StationContactsPanel'
import StationLocationPanel from '../../../station/components/link/StationLocationPanel'
import StationMeasureMethodPanel from '../../../station/components/link/StationMeasureMethodPanel'
import StationNetworkPanel from '../../../station/components/link/StationNetworkPanel'
import StationPiezoContributorPanel from '../../../station/components/link/StationPiezoContributorPanel'
import StationUpdatePanel from '../../../station/components/update/StationUpdatePanel'
import { NEW } from '../../../station/constants/StationConstants'
import { getStationArrowNav } from '../../../utils/ActionUtils'
import { getLogin, getUser } from '../../../utils/SettingUtils'
import { getBookmarks, getLinks } from '../../../utils/StationUtils'
import { getSandreList } from '../../../utils/StoreUtils'
import { getUserBookmarksByStationType } from '../../../utils/UserUtil'
import PluviometryAction from '../../actions/PluviometryAction'
import { MONTHS } from '../../constants/PluviometryConstant'
import PluviometerDto from '../../dto/PluviometerDto'
import PluviometerNormalDto from '../../dto/PluviometerNormalDto'
import SimpleDatePicker from '../../../components/forms/SimpleDatePicker'
import StationPointPrelPanel from '../../../station/components/link/StationPointPrelPanel'
import StationTempRefPanel from '../../../station/components/link/StationTempRefPanel'

const tableNormales = ['month', 'rain', 'etp', 'effectiveRain']

class PluviometerDescriptionPanel extends ActionComponent {
    state = {
        pluviometer: { ...this.props.pluviometer },
        normales: [],
        readMode: true,
    }

    componentDidMount() {
        if (this.props.pluviometer.id) {
            this.setState({ pluviometer: this.props.pluviometer })
        }
        if (this.props.id === NEW) {
            this.setEditMode()
        } else {
            this.setReadOnlyMode()
        }
        if (this.props.normales.length !== 0) {
            this.setState({ normales: this.props.normales })
        }
    }

    componentDidUpdate(prevProps) {
        if (!isEqual(this.props.pluviometer, prevProps.pluviometer)) {
            this.setState({ pluviometer: this.props.pluviometer })
            this.setReadOnlyMode()
        }
        if (this.props.normales.length !== prevProps.normales.length) {
            this.setState({ normales: this.props.normales })
        }
    }

    onSave = () => {
        if (!this.state.pluviometer.code) {
            this.props.toastrError(template(i18n.fieldNotSet)({ field: i18n.code }))
        } else if (this.props.id === NEW && this.props.pluviometers.find(q => q.code === this.state.pluviometer.code)) {
            this.props.toastrError(i18n.codeMeteoFranceAlreadyExists)
        } else if (this.props.id === NEW) {
            this.props.toastrInfo(i18n.inDeveloppmentFunctionnality)
            // add action to create new pluvio
        } else {
            this.props.updatePluviometer(this.state.pluviometer)
            this.props.updatePluviometerNormales(this.state.pluviometer.id, this.state.normales)
            this.setReadOnlyMode()
        }
    }

    setEditMode = () => {
        this.setState({ readMode: false })
        const otherActions = {
            delete: () => this.props.deletePluviometer(this.props.pluviometer.id).then(() => this.props.push('/pluviometry')),
            save: () => this.onSave(),
            cancel: () => {
                if (this.props.id === NEW) {
                    this.props.push('/pluviometry')
                } else {
                    this.setState({
                        pluviometer: this.props.pluviometer,
                        normales: this.props.normales,
                    })
                    this.setReadOnlyMode()
                }
            },
            links: getLinks(this.props.pluviometer, this.props),
            arrowNav: getStationArrowNav('pluviometry', this.props.pluviometers, this.props.pluviometer.id, s => this.props.push(`/station/pluviometry/${s.id}/description`)),
        }
        const actions = this.props.id === NEW ? otherActions : { ...otherActions }
        this.setActions(getUser().consultant === '1' ? omit(actions, ['save', 'delete']) : actions)
    }

    setReadOnlyMode = () => {
        this.setState({ readMode: true })
        const actions = {
            exportmodel: () => ({
                stationId: this.props.pluviometer.id.toString(),
                stationCode: this.props.pluviometer.code,
                stationType: this.props.pluviometer.typeName,
                environmentModels: this.props.typeEnvironmentModels,
            }),
            edit: () => this.setEditMode(),
            links: getLinks(this.props.pluviometer, this.props),
            arrowNav: getStationArrowNav('pluviometry', this.props.pluviometers, this.props.pluviometer.id, s => this.props.push(`/station/pluviometry/${s.id}/description`)),
            delete: () => this.props.deletePluviometer(this.props.pluviometer.id)
                .then(() => this.props.push('/pluviometry')),
        }
        this.setActions(getUser().consultant === '1' ? omit(actions, ['edit', 'delete']) : actions)
    }

    setBookmark = (deleteBookmark = false) => {
        const { pluviometer } = this.state
        if (deleteBookmark) {
            this.props.deleteBookmark({ identifiant: pluviometer.code, login: getLogin(), stationType: 2 })
        } else {
            this.props.createBookmark({ identifiant: pluviometer.code, login: getLogin(), stationType: 2 })
        }
    }

    onChangePluviometer = (value) => {
        this.setState({
            pluviometer: {
                ...this.state.pluviometer,
                ...value,
            },
        })
    }

    onChangeNormal = (month, key, value) => {
        const normales = this.state.normales.filter(n => n.month !== month)
        const norm = this.state.normales.find(n => n.month === month) || { month, id: this.state.pluviometer.id }
        this.setState({
            normales: [
                ...normales,
                new PluviometerNormalDto({
                    ...norm,
                    [key]: parseFloat(value),
                }),
            ],
        })
    }

    getTableNormales = () => {
        const readMode = { readMode: this.state.readMode, editMode: !this.state.readMode }
        const data = MONTHS.map(m => {
            const normale = this.state.normales.find(e => m.id === e.month)
            return {
                month: i18n[m.value],
                rain: <Input col={ 12 }
                    value={ normale && normale.value || '' }
                    onChange={ v => this.onChangeNormal(m.id, 'value', v) }
                    {...readMode}
                />,
                etp: <Input col={ 12 }
                    value={ normale && normale.etp || '' }
                    onChange={ v => this.onChangeNormal(m.id, 'etp', v) }
                    {...readMode}
                />,
                effectiveRain: <Input col={ 12 }
                    value={ normale && normale.effectiveRain || '' }
                    onChange={ v => this.onChangeNormal(m.id, 'effectiveRain', v) }
                    {...readMode}
                />,
            }
        })
        const normalTitle = this.state.normales.find(e => e.month === -1)
        return (
            <div className='padding-top-1'>
                <Card title={ normalTitle && normalTitle.title || i18n.normal }>
                    <Table data={ data }
                        sortable
                        showTitle={ false }
                        paging
                        nbPerPageLabel={ nbPerPageLabel }
                        condensed
                        type={ { headers: tableNormales } }
                    />
                </Card>
            </div>
        )
    }

    getDataFollowUp = () => {
        return []
    }

    onChangeWork = (value) => {
        const { pluviometer } = this.state
        const pluviometerWork = pluviometer.link_work[0] || {}
        this.onChangePluviometer({
            link_work: [{
                ...pluviometerWork,
                idStation: pluviometer.id,
                ...value,
            }],
        })
    }

    onChangeGeoData = (value) => {
        const { pluviometer } = this.state
        const linkGeo = pluviometer.link_geo && pluviometer.link_geo[0] || {}
        this.onChangePluviometer({ link_geo: [{ ...linkGeo, idStation: pluviometer.id, ...value }] })
    }

    render() {
        const { pluviometer = {}, readMode } = this.state
        const mode = { readMode, editMode: !readMode }
        const params = {
            station: pluviometer,
            onChange: this.onChangePluviometer,
            readMode,
        }
        const work = pluviometer.link_work[0] || {}
        const geoData = pluviometer.link_geo && pluviometer.link_geo[0] || {}

        return (
            <div className='row margin-left-2 padding-top-1'>
                <div className='col s9' style={{ marginBottom: '100px' }}>
                    <Card title={i18n.description}>
                        <Row className='padding-top-1'>
                            <Input col={ 6 }
                                value={ pluviometer.code }
                                title={ i18n.code }
                                disabled={ this.props.id !== NEW }
                                onChange={ v => this.onChangePluviometer({ code: v }) }
                                {...mode}
                            />
                            <Input col={ 5 }
                                value={ pluviometer.name }
                                title={ i18n.name }
                                onChange={ v => this.onChangePluviometer({ name: v }) }
                                {...mode}
                            />
                            <div className='col s1'>
                                { getBookmarks(pluviometer.code, getUserBookmarksByStationType(this.props.userBookmarks, 2), true, this.setBookmark) }
                            </div>
                        </Row>
                        <Row className={ !readMode && 'padding-bottom-1' || '' }>
                            <SimpleDatePicker
                                col={ 6 }
                                value={ pluviometer.creationDate }
                                id='creation'
                                label={ i18n.creationDate }
                                onChange={ (v) => this.onChangePluviometer({ creationDate: v }) }
                                {...mode}
                            />
                            <SimpleDatePicker
                                col={ 6 }
                                value={ pluviometer.closeDate }
                                id='close'
                                label={ i18n.closeDate }
                                onChange={ (v) => this.onChangePluviometer({ closeDate: v }) }
                                {...mode}
                            />
                        </Row>
                        <Row className={ !readMode && 'padding-bottom-1' || '' }>
                            <Textarea col={ 12 }
                                value={ pluviometer.comment }
                                title={ i18n.comment }
                                onChange={ v => this.onChangePluviometer({ comment: v }) }
                                {...mode}
                            />
                        </Row>
                    </Card>
                    { this.getTableNormales() }
                    <Row className='padding-top-1'>
                        <StationPiezoContributorPanel { ...params }/>
                    </Row>

                    <Row className='padding-top-1'>
                        <StationMeasureMethodPanel { ...params }/>
                    </Row>
                    <Row className='padding-top-1'>
                        <StationPointPrelPanel { ...params } st='pluviometry'/>
                    </Row>
                    <Row className='padding-top-1'>
                        <StationTempRefPanel { ...params } st='pluviometry'/>
                    </Row>
                    <Row className='padding-top-1'>
                        <StationNetworkPanel { ...params }/>
                    </Row>
                    <Row className='padding-top-1'>
                        <Card title={ i18n.technicalCharacteristics }>
                            <div className={ `row no-margin ${!readMode && 'padding-top-1 padding-bottom-1' || ''}` } style={{ paddingLeft: '5px' }}>
                                <Row>
                                    <Input
                                        col={ 6 }
                                        title={ i18n.stationType }
                                        value={ work.stationType }
                                        onChange={ v => this.onChangeWork({ stationType: v }) }
                                        readMode={ readMode }
                                    />
                                    <Input
                                        col={ 6 }
                                        title={ i18n.phone }
                                        value={ work.phone }
                                        onChange={ v => this.onChangeWork({ phone: v }) }
                                        readMode={ readMode }
                                    />
                                </Row>
                                <Row>
                                    <Input
                                        col={ 6 }
                                        title={ i18n.sensorType }
                                        value={ work.sensorType }
                                        onChange={ v => this.onChangeWork({ sensorType: v }) }
                                        readMode={ readMode }
                                    />
                                    <Input
                                        col={ 6 }
                                        title={ i18n.serialNumber }
                                        value={ work.serialNumber }
                                        onChange={ v => this.onChangeWork({ serialNumber: v }) }
                                        readMode={ readMode }
                                    />
                                </Row>
                                <Row>
                                    <Textarea
                                        col={ 12 }
                                        title={ i18n.comment }
                                        value={ work.comment }
                                        onChange={ v => this.onChangeWork({ comment: v }) }
                                        readMode={ readMode }
                                    />
                                </Row>
                            </div>
                        </Card>

                    </Row>
                    <Row className='padding-top-1'>
                        <StationLocationPanel { ...params }/>
                    </Row>
                    <Row className='padding-top-1'>
                        <StationContactsPanel { ...params }/>
                    </Row>
                </div>
                <div className='col s3' style={{ marginBottom: '100px' }}>
                    <StationUpdatePanel station={ pluviometer }/>
                    <Card title={ i18n.contact } noMargin={ false } className='margin-top-1'>
                        <Row className={ `${!readMode && 'padding-top-1' || ''}` }>
                            <Select
                                col={ 12 }
                                value={ pluviometer.contactCode }
                                options={ this.props.contacts }
                                keyValue='id'
                                onChange={ v => this.onChangePluviometer({ contactCode: v }) }
                                readMode= {readMode}
                            />
                        </Row>
                    </Card>
                    <StationMapDashboardPanel noMarkerTooltip station={pluviometer}
                        type={ 'pluviometry' }
                    />
                    <Card title={i18n.location} noMargin={false} className='margin-top-1'>
                        <Row className={ `${!readMode && 'padding-top-1' || ''}`}>
                            <Input col={ 12 } title={ i18n.address } value={ pluviometer.address } onChange={ v => this.onChangePluviometer({ address: v }) } {...mode}/>
                        </Row>
                        <Row>
                            <Select col={ 12 } value={ pluviometer.townCode } label={ i18n.city } options={ this.props.cities } keyValue='id' displayWithCode
                                onChange={ v => this.onChangePluviometer({ townCode: v }) } {...mode}
                            />
                        </Row>
                        <Row displayIf={pluviometer.declarationTownCode !== pluviometer.townCode || !readMode}>
                            <Select col={ 12 } value={ pluviometer.declarationTownCode } label={ i18n.declarationTownCode } options={ this.props.cities } keyValue='id' displayWithCode
                                onChange={ v => this.onChangePluviometer({ declarationTownCode: v }) } {...mode}
                            />
                        </Row>
                        <Row>
                            <Input col={ 12 } value={ pluviometer.countryCode } title={ i18n.countryCode } onChange={ v => this.onChangePluviometer({ countryCode: v }) } {...mode}/>
                        </Row>
                        <Row>
                            <Textarea col={ 12 } noMargin={ false } classname={ !readMode && 'margin-bottom-1' || '' } title={ i18n.localisation } value={ pluviometer.localization } onChange={ v => this.onChangePluviometer({ localization: v }) } {...mode}/>
                        </Row>
                        <Row>
                            <NumberField col={ 6 } title='X' value={ pluviometer.x } floatValue onChange={ v => this.onChangePluviometer({ x: v }) } {...mode}/>
                            <NumberField col={ 6 } title='Y' value={ pluviometer.y } floatValue onChange={ v => this.onChangePluviometer({ y: v }) } {...mode}/>
                        </Row>
                        <Row>
                            <Select col={ 12 } label={ i18n.projection } value={ pluviometer.projection || pluviometer.projectionType }
                                options={ getSandreList(this.props.sandreCodes, SANDRE.PROJECTION) }
                                onChange={ v => this.onChangePluviometer({ projection: v, projectionType: v }) } {...mode} integerValue
                            />
                        </Row>
                        <Row>
                            <NumberField col={ 12 } value={ pluviometer.altitude } title={ i18n.altitudeM } floatValue onChange={ (v) => this.onChangePluviometer({ altitude: v }) } {...mode}/>
                        </Row>
                        <Row>
                            <Input col={ 6 } title={ i18n.ignMap } value={ geoData.ignMap } onChange={ v => this.onChangeGeoData({ ignMap: v }) } {...mode}/>
                            <Input col={ 6 } title={ i18n.geologicalMap } value={ geoData.geologicalMap } onChange={ v => this.onChangeGeoData({ geologicalMap: v }) } {...mode}/>
                        </Row>
                        <Row>
                            <Input col={ 6 } title={ i18n.section } value={ geoData.parcel } onChange={ v => this.onChangeGeoData({ parcel: v }) } {...mode}/>
                            <Input col={ 6 } title={ i18n.parcel } value={ geoData.section } onChange={ v => this.onChangeGeoData({ section: v }) } {...mode}/>
                        </Row>
                    </Card>
                    <LinkedStationsPanel noMargin={ false } className='margin-top-1' onReMount={ this.props.onRemount } station={ pluviometer } {...mode}/>
                </div>
            </div>
        )
    }
}

PluviometerDescriptionPanel.propTypes = {
    cities: PropTypes.arrayOf(PropTypes.instanceOf(CityDto)),
    contacts: PropTypes.arrayOf(PropTypes.instanceOf(ContactItem)),
    pluviometers: PropTypes.arrayOf(PropTypes.instanceOf(PluviometerDto)),
    pluviometer: PropTypes.instanceOf(PluviometerDto),
    normales: PropTypes.instanceOf(PluviometerNormalDto),
    id: PropTypes.number,
    sandreCodes: PropTypes.arrayOf(PropTypes.instanceOf(DtoSandreCode)),
    typeEnvironmentModels: PropTypes.arrayOf(PropTypes.string),
}

const mapStateToProps = store => ({
    cities: store.CityReducer.cities,
    contacts: store.ContactReducer.contacts,
    pluviometers: store.PluviometryReducer.pluviometers,
    pluviometer: store.PluviometryReducer.pluviometer,
    normales: store.PluviometryReducer.normales,
    userBookmarks: store.UserReducer.userBookmarks,
    sandreCodes: store.ReferencialReducer.sandreCodes,
    typeEnvironmentModels: store.ExportReducer.typeEnvironmentModels,
})

const mapDispatchToProps = {
    push,
    toastrInfo: ToastrAction.info,
    toastrError: ToastrAction.error,
    updatePluviometer: PluviometryAction.updatePluviometer,
    updatePluviometerNormales: PluviometryAction.updatePluviometerNormales,
    deletePluviometer: PluviometryAction.deletePluviometer,
    deleteBookmark: UserAction.deleteBookmark,
    createBookmark: UserAction.createBookmark,

}

export default connect(mapStateToProps, mapDispatchToProps)(PluviometerDescriptionPanel)
