import PropTypes from 'prop-types'
import React, { Component } from 'react'
import i18n from 'simple-react-i18n'
import AppStore from '../../store/AppStore'
import Button from '../forms/Button'
import ProgressBar from '../progress/ProgressBar'
import SieauAction from '../sieau/SieauAction'
import { Card, CardContent } from '@mui/material'

class ProgressCard extends Component {
    render() {
        const { withCancel, withoutCard, progress, withMessage, message, indeterminate, className } = this.props
        if (withoutCard) {
            return (
                <div style={{ padding: '20px' }}>
                    <ProgressBar progress={progress} withMessage={withMessage} message={message} />
                </div>
            )
        }
        return (
            <div className={className}>
                <Card>
                    <CardContent>
                        <div style={{ padding: '20px' }}>
                            <ProgressBar progress={progress} withMessage={withMessage} message={message} indeterminate={indeterminate} />
                        </div>
                        {withCancel && (
                            <div className='center-align center-block padding-bottom-1'>
                                <Button
                                    title={i18n.cancel}
                                    onClick={() => {
                                        AppStore.dispatch(SieauAction.setLoadingData(false))
                                        this.props.whenCancel()
                                    }}
                                />
                            </div>
                        )}
                    </CardContent>
                </Card>
            </div>
        )
    }
}

ProgressCard.propTypes = {
    progress: PropTypes.number,
    withMessage: PropTypes.bool,
    message: PropTypes.string,
    withoutCard: PropTypes.bool,
    withCancel: PropTypes.bool,
    whenCancel: PropTypes.func,
    indeterminate: PropTypes.bool,
    className: PropTypes.string,
}

ProgressCard.defaultProps = {
    withMessage: true,
    whenCancel: () => { },
}

export default ProgressCard
