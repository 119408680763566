import { Button, Grid } from '@mui/material'
import Input from 'components/forms/Input'
import SimpleDatePicker from 'components/forms/SimpleDatePicker'
import SimpleFilterSelect from 'components/forms/specific/SimpleFilterSelect'
import WatermassSelect from 'components/forms/specific/WatermassSelect'
import WatershedSelect from 'components/forms/specific/WatershedSelect'
import SuperMultiAutocomplete from 'components/forms/SuperMultiAutocomplete'
import Icon from 'components/icon/Icon'
import SelectionTableModal from 'components/modal/SelectionTableModal'
import { push } from '@lagunovsky/redux-react-router'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { useEffect, useMemo, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import CityAction from 'referencial/components/city/actions/CityAction'
import FractionAction from 'referencial/components/fraction/actions/FractionAction'
import SupportAction from 'referencial/components/support/actions/SupportAction'
import i18n from 'simple-react-i18n'
import { STATION_TYPE_CONSTANT, STATION_TYPE_NAME } from 'station/constants/StationConstants'
import ToastrAction from 'toastr/actions/ToastrAction'
import useAbortController from 'utils/customHook/useAbortController'
import useBoolean from 'utils/customHook/useBoolean'
import useProgressDispatch from 'utils/customHook/useProgressDispatch'
import useTitle from 'utils/customHook/useTitle'
import useUpdateEffect from 'utils/customHook/useUpdateEffect'
import { searchAllCharacters } from 'utils/StringUtil'
import { H_QUALITO_GRAPHIC } from '../../../account/constants/AccessRulesConstants'
import Card from '../../../components/card/Card'
import ProgressCard from '../../../components/card/ProgressCard'
import ThresholdSelect from '../../../components/forms/specific/ThresholdSelect'
import CollapseTopBar from '../../../components/topBar/CollapseToBar'
import ExportAction from '../../../export/actions/ExportAction'
import { calculateThresholdResult } from '../../../utils/AnalyseUtils'
import { componentHasHabilitations } from '../../../utils/HabilitationUtil'
import QualityAction from '../../actions/QualityAction'
import OperationAction from '../operation/actions/OperationAction'
import QualityGraphicLegendPanel from './QualityGraphicLegendPanel'
import QualityGraphicOptions from './QualityGraphicOptions'
import AnalysisAction from 'quality/actions/AnalysisAction'
import Tabs from 'components/Tabs'
import ParameterAction from 'referencial/components/parameter/actions/ParameterAction'
import { CartoAnalysisPanel } from '../qualityResearch/map/CartoPanel'
import { QualityActionConstant } from 'quality/reducers/QualityReducer'
import SimpleTabSideList from 'components/navbar/SimpleTabSideList'
import { ParameterGraph } from '../qualityComponents/ParameterGraph'
import UnitAction from 'referencial/components/unit/actions/UnitAction'
import MessageCard from 'components/card/MessageCard'
import { AUTO, AUTO_Y_SCALE, DEFAULT_NB_POINT_MOVING_AVERAGE } from 'quality/constants/ChartConstant'
import { secondaryBlue } from 'utils/constants/ColorTheme'
import { getColorFromPalette } from 'utils/ColorUtil'
import MultiParameterGraph from '../qualityComponents/MultiParameterGraph'
import DtoQualityThreshold from 'quality/dto/QualityThreshold/DtoQualityThreshold'
import DtoAnalysis from 'quality/dto/analyse/DtoAnalysis'
import useActions from 'utils/customHook/useActions'
import ExportFileModal from 'components/modal/ExportFileModal'
import useListIndexed from 'utils/customHook/useListIndexed'
import { formatData } from 'utils/ExportDataUtil'
import { getDate } from 'utils/DateUtil'
import { getLabel } from 'utils/StoreUtils'
import AssociatedSectorSelect from 'components/forms/specific/AssociatedSectorSelect'
import AssociatedStationSelect from 'components/forms/specific/AssociatedStationSelect'
import { isUndefined } from 'lodash'

const GRAPH = 'GRAPH'
const CARTO = 'CARTO'

const OPTION = 'OPTION'
const LEGEND = 'LEGEND'

const StationsField = ({
    stations = [],
    setStations = () => {},
}) => {
    const {
        qualitometers,
        cities,
        installations,
        distributionUnits,
        productionUnits,
    } = useSelector(store => ({
        qualitometers: store.QualityReducer.qualitometersLight,
        cities: store.CityReducer.cities,
        installations: store.InstallationReducer.installationsLight,
        distributionUnits: store.DistributionUnitReducer.distributionUnits,
        productionUnits: store.ProductionUnitReducer.productionUnits,
    }), shallowEqual)

    const citiesIndex = useListIndexed(cities, 'id')

    const {
        value: isOpen,
        setTrue: openModal,
        setFalse: closeModal,
    } = useBoolean(false)

    const formattedQualitometers = useMemo(() => {
        return qualitometers.map(q => ({
            id: q.id,
            code: q.code,
            city: citiesIndex[q.townCode]?.name,
            name: q.name,
        }))
    }, [qualitometers, citiesIndex])

    return (
        <>
            <Grid container>
                <Grid item xs={3}>
                    <Icon
                        icon='list'
                        style={{ color: secondaryBlue, fontSize: '2.5rem', border: 'solid 4px' }}
                        tooltip={i18n.stations}
                        onClick={openModal}
                    />
                </Grid>
                <Grid container item xs={9} alignItems='center'>
                    <label style={{ color: '#161832', fontSize: '1rem', fontWeight: 'bold' }}>
                        {`${stations.length} ${stations.length > 1 ? i18n.selectedStations : i18n.selectedStation}`}
                    </label>
                </Grid>
            </Grid>
            <SelectionTableModal
                isOpen={isOpen}
                onClose={closeModal}
                onValidate={selectedStations => {
                    setStations(selectedStations)
                    closeModal()
                }}
                title={i18n.selectStations}

                listData={formattedQualitometers}
                listHeaders={['code', 'city', 'name']}
                listTitle={i18n.nonSelectedStations}

                defaultSelectionList={stations}
                selectionListHeaders={['code', 'city', 'name']}
                selectionListTitle={i18n.selectedStations}

                maxHeightTable={'45vh'}
                filterField={({
                    filter,
                    setFilter,
                }) => (
                    <Grid container spacing={'10px'} style={{ paddingTop: '5px' }}>
                        <Grid item xs={3}>
                            <Input
                                title={i18n.search}
                                value={filter.searchValue}
                                onChange={searchValue => setFilter(prev => ({ ...prev, searchValue }))}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <SimpleFilterSelect
                                stationType={STATION_TYPE_NAME.quality}
                                onChange={(resultFilter, newFilter) => {
                                    setFilter(prev => ({ ...prev, resultFilter, filter: newFilter }))
                                }}
                                stations={qualitometers}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <WatermassSelect
                                onChange={watermass => setFilter(prev => ({ ...prev, watermass }))}
                                stationType='quality'
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <WatershedSelect
                                onChange={watershed => setFilter(prev => ({ ...prev, watershed }))}
                                stationType='quality'
                            />
                        </Grid>

                        <Grid item xs={3}>
                            <AssociatedSectorSelect
                                value={filter.sector}
                                onChange={(list, value) => setFilter(prev => ({ ...prev, sector: value, sectorAssociatedStations: list }))}
                                stationTypeReturn={STATION_TYPE_CONSTANT.QUALITY}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <AssociatedStationSelect
                                label={i18n.installation}
                                options={installations}
                                value={filter.installation}
                                onChange={(list, value) => setFilter(prev => ({ ...prev, installation: value, instAssociatedStations: list }))}
                                stationType={STATION_TYPE_CONSTANT.INSTALLATION}
                                stationTypeReturn={STATION_TYPE_CONSTANT.QUALITY}
                                keyValue='code'
                                displayWithCode
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <AssociatedStationSelect
                                label={i18n.productionUnit}
                                options={productionUnits}
                                value={filter.productionUnit}
                                onChange={(list, value) => setFilter(prev => ({ ...prev, productionUnit: value, prodAssociatedStations: list }))}
                                stationType={STATION_TYPE_CONSTANT.PRODUCTION}
                                stationTypeReturn={STATION_TYPE_CONSTANT.QUALITY}
                                keyValue='code'
                                displayWithCode
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <AssociatedStationSelect
                                label={i18n.distributionUnit}
                                options={distributionUnits}
                                value={filter.distributionUnit}
                                onChange={(list, value) => setFilter(prev => ({ ...prev, distributionUnit: value, distriAssociatedStations: list }))}
                                stationType={STATION_TYPE_CONSTANT.DISTRIBUTION}
                                stationTypeReturn={STATION_TYPE_CONSTANT.QUALITY}
                                keyValue='code'
                                displayWithCode
                            />
                        </Grid>
                    </Grid>
                )}
                filterFunction={(list, filter) => {
                    const {
                        searchValue,
                        resultFilter = [],
                        filter: filterValue,
                        sector,
                        sectorAssociatedStations = [],
                        installation,
                        instAssociatedStations = [],
                        productionUnit,
                        prodAssociatedStations = [],
                        distributionUnit,
                        distriAssociatedStations = [],
                    } = filter
                    const searchValueFormat = searchAllCharacters(searchValue)
                    const filterSearchValue = searchValue ? list.filter(p => searchAllCharacters(['code', 'city', 'name'].map(key => p[key])).includes(searchValueFormat)) : list
                    const filterList = filterValue !== -1 ? resultFilter.map(s => filterSearchValue.find(e => e.id === s.id)).filter(e => !!e) : filterSearchValue
                    const filterInstallation = !isUndefined(installation) ? filterList.filter(s => instAssociatedStations.includes(s.id)) : filterList
                    const filterProduction = !isUndefined(productionUnit) ? filterInstallation.filter(s => prodAssociatedStations.includes(s.id)) : filterInstallation
                    const filterDistribution = !isUndefined(distributionUnit) ? filterProduction.filter(s => distriAssociatedStations.includes(s.id)) : filterProduction
                    return !isUndefined(sector) ? filterDistribution.filter(s => sectorAssociatedStations.includes(s.id)) : filterDistribution
                }}
            />
        </>
    )
}

StationsField.propTypes = {
    stations: PropTypes.arrayOf(PropTypes.number),
    setStations: PropTypes.func,
}

const QualityGraphicFilter = ({
    defaultFilter = {},
    onValidate = () => {},
    hideStationsCriteria = false,
    // refCollapseTopBar,
    // selectStationField,
}) => {
    const {
        supports,
        propsParameters,
    } = useSelector(store => ({
        supports: store.SupportReducer.supports,
        propsParameters: store.ParameterReducer.parameters,
    }), shallowEqual)

    const [parameters, setParameters] = useState(defaultFilter.parameters)
    const [startDate, setStartDate] = useState(defaultFilter.startDate)
    const [endDate, setEndDate] = useState(defaultFilter.endDate)
    const [support, setSupport] = useState(defaultFilter.support)
    const [threshold, setThreshold] = useState(defaultFilter.threshold)
    const [stations, setStations] = useState(defaultFilter.stations)

    const parametersFormated = useMemo(() => propsParameters.map(p => ({
        ...p,
        icon: p.status === '3' && <Icon size='tiny' icon='lock' />,
    })), [propsParameters])

    return (
        <CollapseTopBar /*ref={refCollapseTopBar}*/>
            <div style={{ padding: '10 15', backgroundColor: '#d9dfe4', borderLeft: '2px solid #161832', borderRight: '2px solid #161832' }}>
                <Grid container columnSpacing={1} rowSpacing={0.5}>
                    <Grid item xs={4}>
                        <SuperMultiAutocomplete
                            label={i18n.parameters}
                            options={parametersFormated}
                            keyValue='code'
                            onChange={setParameters}
                            values={parameters}
                            keyLabel='labelWithCode'
                            multiple
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <SimpleDatePicker
                            label={i18n.startDate}
                            onChange={value => setStartDate(moment(value).valueOf())}
                            id='startDate'
                            value={startDate}
                            max={endDate}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <SimpleDatePicker
                            label={i18n.endDate}
                            onChange={value => setEndDate(moment(value).valueOf())}
                            id='endDate'
                            value={endDate}
                            min={startDate}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <SuperMultiAutocomplete
                            label={i18n.support}
                            options={supports}
                            values={support}
                            onChange={v => setSupport(v)}
                            displayWithCode
                        />
                    </Grid>
                    <Grid item xs={2}>
                        {
                            !hideStationsCriteria && (
                                <StationsField
                                    stations={stations}
                                    setStations={setStations}
                                />
                            )
                        }
                    </Grid>
                    <Grid item xs={4}>
                        <ThresholdSelect
                            onChange={setThreshold}
                            selected={threshold}
                        />
                    </Grid>
                    <Grid item xs={6} />
                    <Grid item xs={2}>
                        <Button
                            onClick={() => onValidate({
                                parameters,
                                startDate,
                                endDate,
                                support,
                                threshold,
                                stations,
                            })}
                            fullWidth
                            variant='contained'
                            color='primary'
                        >
                            <Icon icon={'search'} />
                            {i18n.visualize}
                        </Button>
                    </Grid>
                </Grid>
            </div>
        </CollapseTopBar>
    )
}

QualityGraphicFilter.propTypes = {
    defaultFilter: PropTypes.shape({}),
    onValidate: PropTypes.func,
    hideStationsCriteria: PropTypes.bool,

    refCollapseTopBar: PropTypes.object,
    selectStationField: PropTypes.element,
}

const GraphTab = ({
    analysis = [],
    thresholds = [],

    filter = {},
}) => {
    const [graphOptions, setGraphOptions] = useState({
        nbPointMovingAverage: DEFAULT_NB_POINT_MOVING_AVERAGE,
        showXSplitLines: true,
        showYSplitLines: true,
        graphicTitle: i18n.overlayGraphic,
        regroupAxis: true,
        yChoice: AUTO_Y_SCALE,
        maxXAxisSpace: AUTO,
        parameters: filter.parameters,
        stationsOptions: filter.stations.map((id, index) => ({
            id,
            color: getColorFromPalette(index),
        })),
    })

    useEffect(() => {
        setGraphOptions(options => ({
            ...options,
            parameters: filter.parameters || [],
            stationsOptions: filter.stations.map((id, index) => ({
                id,
                color: getColorFromPalette(index),
            })),
        }))
    }, [filter])

    const formattedAnalysis = useMemo(() => {
        return analysis.map(a => ({
            ...a,
            ...calculateThresholdResult(a, thresholds),
        }))
    }, [analysis, thresholds])

    return (
        <div className='relative no-overflow' style={{ paddingBottom: '75px' }}>
            <SimpleTabSideList
                position='right'
                defaultTab={OPTION}
                tabs={[{
                    icon: 'perm_data_setting',
                    constant: OPTION,
                    label: i18n.chartOptions,
                }, {
                    icon: 'help',
                    constant: LEGEND,
                    label: i18n.legend,
                }]}
            >
                {
                    tab => (
                        <>
                            {
                                tab === OPTION && (
                                    <QualityGraphicOptions
                                        onChangeOptions={setGraphOptions}
                                        options={graphOptions}
                                        graphOptions={graphOptions}
                                    />
                                )
                            }
                            {
                                tab === LEGEND && (
                                    <QualityGraphicLegendPanel
                                        analysis={formattedAnalysis}
                                        stations={graphOptions.stationsOptions}
                                        onChange={(id, color) => setGraphOptions(options => ({
                                            ...options,
                                            stationsOptions: options.stationsOptions.map(s => s.id === id ? ({ ...s, color }) : s),
                                        }))}
                                    />
                                )
                            }
                        </>
                    )
                }
            </SimpleTabSideList>
            <Card>
                {
                    filter.parameters.length === 1 && (
                        <ParameterGraph
                            analysis={formattedAnalysis}
                            thresholds={thresholds}

                            graphOptions={graphOptions}

                            componentHeight={750}

                            withToolLegend
                            withArea

                            toolboxPosition={{ top: 5, right: 60 }}
                            dataZoomPosition={{ bottom: 60 }}
                        />
                    )
                }
                {
                    filter.parameters.length > 1 && (
                        <MultiParameterGraph
                            analysis={formattedAnalysis}
                            thresholds={thresholds}

                            graphOptions={graphOptions}

                            toolboxPosition={{ top: 5, right: 60 }}
                            dataZoomPosition={{ bottom: 60 }}
                        />
                    )
                }
            </Card>
        </div>
    )
}

GraphTab.propTypes = {
    analysis: PropTypes.arrayOf(PropTypes.instanceOf(DtoAnalysis)),
    thresholds: PropTypes.arrayOf(PropTypes.instanceOf(DtoQualityThreshold)),
    filter: PropTypes.shape({
        parameters: PropTypes.arrayOf(PropTypes.string),
        selectStations: PropTypes.arrayOf(PropTypes.number),
    }),
}

const QualityGraphicApp = ({
    defaultStations = [],
    hideStationsCriteria = false,
}) => {
    const dispatch = useDispatch()

    const {
        controllerRef,
        initController,
    } = useAbortController()

    const {
        analysis,
        qualityThresholds,
        typeEnvironmentModels,
        qualitometers,
        units,
        contributors,
        parameters,
        qualifications,
        remarks,
        status,
        fractions,
        supports,
    } = useSelector(store => ({
        analysis: store.QualityReducer.analysis,
        qualityThresholds: store.QualityReducer.qualityThresholds,
        typeEnvironmentModels: store.ExportReducer.typeEnvironmentModels,
        qualitometers: store.QualityReducer.qualitometersLight,
        units: store.UnitReducer.units,
        contributors: store.ContributorReducer.contributors,
        parameters: store.ParameterReducer.parameters,
        qualifications: store.QualityReducer.qualifications,
        remarks: store.OperationReducer.remarks,
        status: store.QualityReducer.status,
        fractions: store.FractionReducer.fractions,
        supports: store.SupportReducer.supports,
    }), shallowEqual)

    const [filter, setFilter] = useState({
        parameters: [],
        // startDate: undefined,
        endDate: moment().endOf('year').valueOf(),
        support: '3', // eau
        // threshold: undefined,
        stations: defaultStations,
    })
    /*
    filter = {
        parameters: array of string
        startDate: number
        endDate: number
        support: string
        threshold: string
        stations: array of number
    }
    */

    useEffect(() => {
        if (defaultStations.length !== 0) {
            setFilter(p => ({
                ...p,
                stations: defaultStations,
            }))
        }
    }, [defaultStations])

    const {
        value: isExportModalOpen,
        setTrue: openExportModal,
        setFalse: closeExportModal,
    } = useBoolean(false)

    const {
        value: isFirstSearchDone,
        setTrue: setFirstSearchDone,
    } = useBoolean(false)

    const {
        value: isSearching,
        setTrue: setSearchingToTrue,
        setFalse: setSearchingToFalse,
    } = useBoolean(false)
    const [searchProgress, setSearchProgress] = useState(0)

    useEffect(() => {
        if (!componentHasHabilitations(H_QUALITO_GRAPHIC)) { // A modifier quand react-router sera à jour
            dispatch(push('/unauthorized'))
        }
    }, [])

    useTitle(() => [{
        title: i18n.quality,
        href: 'quality',
    }, {
        title: i18n.graphics,
        href: 'quality/graphic',
    }], [])

    const {
        isLoaded,
        progress,
    } = useProgressDispatch(() => {
        return [
            dispatch(ParameterAction.fetchParameters()),
            dispatch(CityAction.fetchCities()),
            dispatch(SupportAction.fetchSupports()),
            dispatch(OperationAction.fetchRemarks()),
            dispatch(QualityAction.fetchQualifications()),
            dispatch(QualityAction.fetchStatus()),
            dispatch(FractionAction.fetchFractions()),
            dispatch(QualityAction.fetchQualitometersLight()),
            dispatch(UnitAction.fetchUnits()),
            dispatch(ExportAction.fetchEnvironmentModelsByType('qualityResearch')),
        ]
    }, [])

    const thresholds = qualityThresholds.find(t => t.thresholdCode == filter.threshold)?.thresholds || []

    useUpdateEffect(() => {
        if (!filter.parameters.length) {
            dispatch(ToastrAction.error(i18n.noParameterSelectedError))
            return
        }
        if (!filter.startDate || !filter.endDate) {
            dispatch(ToastrAction.error(i18n.noDateSelectedError))
            return
        }
        if (!filter.stations.length) {
            dispatch(ToastrAction.error(i18n.noStationSelectedError))
            return
        }
        const thresholdFound = thresholds.some(t => filter.parameters.includes(t.parameterCode))
        if (!thresholdFound) {
            dispatch(ToastrAction.info(i18n.noThresholToApply))
        }

        dispatch(QualityActionConstant.resetQualityAnalysis())
        setSearchingToTrue()
        setSearchProgress(0)
        if (controllerRef.current.signal.aborted) {
            initController()
        }
        const analysisFilter = {
            lightMode: true, // Option[Boolean] = None,

            parameters: filter.parameters.length ? filter.parameters : undefined, // Option[Seq[String]] = None,
            startDate: filter.startDate, // Option[DateTime] = None,
            endDate: filter.endDate, // Option[DateTime] = None,
            // support: filter.support, // Option[String] = None,
            stations: filter.stations, // Option[Seq[Int]] = None
        }
        dispatch(AnalysisAction.fetchAnalysis(analysisFilter, controllerRef.current.signal, setSearchProgress))
            .then((nbResult = 0) => {
                setSearchingToFalse()
                setFirstSearchDone()
                if (!nbResult) {
                    dispatch(ToastrAction.info(i18n.noResults))
                }
            })
    }, [filter])

    useActions(() => {
        return {
            exportList: [{
                onClick: openExportModal,
                label: i18n.excel,
            }],
        }
    }, [])

    const exportTypes = useMemo(() => {
        const models = typeEnvironmentModels.map(model => ({
            name: model,
            formats: [{
                type: i18n.excelFile,
                callback: () => dispatch(ExportAction.exportResearchModel(filter, model)),
            }],
        }))

        const onExport = (extension) => {
            const data = analysis.map(a => {
                const qualification = qualifications.find(q => q.code == a.qualification)
                const remark = getLabel(remarks, a.remark)
                const statusLabel = getLabel(status, a.status)
                const station = qualitometers.find(q => q.id === a.qualitometer)
                return {
                    stationCode: station?.code,
                    stationName: station?.name,
                    sampleDate: a.sampleDate ? getDate(a.sampleDate) : '',
                    producer: getLabel(contributors, a.producer),
                    labo: getLabel(contributors, a.labo),
                    analysisDate: a.analysisDate ? getDate(a.analysisDate) : '',
                    codeParameter: a.parameter,
                    parameterLabel: getLabel(parameters, a.parameter),
                    result: a.result,
                    codeUnit: a.unit,
                    unit: getLabel(units, a.unit, 'symbol'),
                    supportCode: a.support,
                    support: getLabel(supports, a.support),
                    fractionCode: a.fraction,
                    fraction: getLabel(fractions, a.fraction),
                    remarkCode: a.remark,
                    remark,
                    statusCode: a.status,
                    status: statusLabel,
                    qualificationCode: a.qualification,
                    qualification: qualification?.name,
                }
            })
            const dataWithHeaders = data.length ? [
                { ...data[0], headers: Object.keys(data[0]) },
                ...data.slice(1),
            ] : []
            dispatch(ExportAction.export(formatData(dataWithHeaders), extension, i18n.sampleDetail))
        }

        return [
            {
                name: i18n.resultsTable,
                formats: [{
                    type: i18n.excelFile,
                    callback: () => onExport('xlsx'),
                }, {
                    type: i18n.csvFile,
                    callback: () => onExport('csv'),
                }],
            },
            ...models,
        ]
    }, [analysis, contributors, dispatch, filter, fractions, parameters, qualifications, qualitometers, remarks, status, supports, typeEnvironmentModels, units])

    return (
        <div style={{ padding: '0 10' }}>
            {
                !isLoaded && (
                    <ProgressCard progress={progress} />
                )
            }
            {
                isLoaded && (
                    <>
                        <QualityGraphicFilter
                            // refCollapseTopBar={this.refCollapseTopBar}
                            defaultFilter={filter}
                            onValidate={setFilter}
                            hideStationsCriteria={hideStationsCriteria}
                        />
                        <div style={{ paddingTop: 10 }}>
                            <Tabs
                                defaultTab={GRAPH}
                                tabs={[
                                    {
                                        constant: GRAPH,
                                        label: i18n.graph,
                                    },
                                    {
                                        constant: CARTO,
                                        label: i18n.cartography,
                                    },
                                ]}
                            >
                                {
                                    tab => {
                                        if (isSearching) {
                                            return (
                                                <ProgressCard progress={searchProgress} />
                                            )
                                        }
                                        if (!isFirstSearchDone) {
                                            return (
                                                <MessageCard>{i18n.pleaseSearch}</MessageCard>
                                            )
                                        }
                                        if (!analysis.length) {
                                            return (
                                                <MessageCard>{i18n.noDataToDisplay}</MessageCard>
                                            )
                                        }
                                        return (
                                            <>
                                                {
                                                    tab === GRAPH && (
                                                        <GraphTab
                                                            analysis={analysis}
                                                            thresholds={thresholds}
                                                            filter={filter}
                                                        />
                                                    )
                                                }
                                                {
                                                    tab === CARTO && (
                                                        <CartoAnalysisPanel
                                                            analysis={analysis}
                                                            listParameters={filter.parameters}
                                                            thresholds={thresholds}
                                                            filter={filter}
                                                        />
                                                    )
                                                }
                                            </>
                                        )
                                    }
                                }
                            </Tabs>
                        </div>
                    </>
                )
            }
            <ExportFileModal
                open={isExportModalOpen}
                onClose={closeExportModal}
                data={exportTypes}
            />
        </div>
    )
}

QualityGraphicApp.propTypes = {
    defaultStations: PropTypes.arrayOf(PropTypes.number),
    hideStationsCriteria: PropTypes.bool,
}

export default QualityGraphicApp
