import { RECEIVE_LAST_MESSAGES, RECEIVE_MESSAGES_USER } from '../constants/MessagesConstants'
import DtoMessage from '../dto/DtoMessage'

export const store = {
    listOfMessagesUser: [],
    listOfLastMessages: [],
}
export function MessagesReducer(state = {}, action) {
    switch (action.type) {
        case RECEIVE_LAST_MESSAGES:
            return {
                ...state,
                listOfLastMessages: action.data.map(message => new DtoMessage(message)),
            }
        case RECEIVE_MESSAGES_USER:
            return {
                ...state,
                listOfMessagesUser: action.listOfMessagesUser.map(message => new DtoMessage(message)),
            }
        default:
            return state
    }
}
