/* eslint-disable camelcase */
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import { getExport, setModal } from '../../../../utils/linkUtils'
import DtoInstallation from '../../../dto/installation/DtoInstallation'
import SieauAction from '../../../../components/sieau/SieauAction'
import Table from '../../../../components/datatable/Table'
import InstallationEquipmentsModal from './modal/InstallationEquipmentsModal'
import { arrayOf, getSandreList } from '../../../../utils/StoreUtils'
import DtoSandreCode from '../../../../referencial/dto/DtoSandreCode'
import { getSandreLabel } from '../../../../utils/StringUtil'
import { getDate } from '../../../../utils/DateUtil'

const InstallationEquipmentsPanel = ({
    station = {},
    readMode = false,
    sandreCodes = [],
    sandreCode = '',
    onChange = () => { },
    setPopup = () => { },
}) => {
    const sandreList = getSandreList(sandreCodes, sandreCode)
    const installationEquipments = sandreList.map(element => {
        const data = station.link_equipments.find(le => element.code === le.equipmentCode)
        return data ? {
            ...element,
            ...data,
        } : null
    }).filter(ie => !!ie)
    const tableData = installationEquipments.map(ie => ({
        ...ie,
        installation: getDate(ie.startDate),
        withdrawal: getDate(ie.endDate),
    }))
    const headers = ['name', 'installation', 'withdrawal', 'comment']
    const exportAction = getExport(tableData, i18n.equipment, headers)
    const availableEquipments = sandreList.map(element => {
        return station.link_equipments.some(le => element.code === le.equipmentCode) ? null : element
    }).filter(ie => !!ie)
    const actions = !readMode ? [{
        iconName: 'note_add',
        tooltip: i18n.add,
        onClick: () => setModal(i18n.newEquipment, <InstallationEquipmentsModal id={station.id} isNew availableEquipments={availableEquipments} saveResult={r => onChange({ link_equipments: [...installationEquipments, r] })} />, setPopup),
    }, exportAction] : [exportAction]

    return (
        <Table
            className='blue'
            condensed
            title={i18n.equipmentList}
            data={tableData}
            type={{ headers }}
            sortable={!!tableData.length}
            actions={actions}
            alterable={!readMode}
            onAlter={
                (element) => setModal(`${i18n.equipment} : ${getSandreLabel(sandreCodes, sandreCode, element.equipmentCode)}`,
                    <InstallationEquipmentsModal
                        id={station.id}
                        equipment={installationEquipments.find(c => element.equipmentCode === c.equipmentCode)}
                        availableEquipments={availableEquipments}
                        saveResult={r => onChange({
                            link_equipments: [
                                ...station.link_equipments.filter(le => le.equipmentCode !== element.equipmentCode),
                                r,
                            ],
                        })}
                    />
                    , setPopup)
            }
            deletable={!readMode}
            onDelete={element => onChange({ link_equipments: installationEquipments.filter(b => b.equipmentCode !== element.equipmentCode) })}
        />
    )
}

InstallationEquipmentsPanel.propTypes = {
    station: PropTypes.instanceOf(DtoInstallation),
    sandreCodes: arrayOf(DtoSandreCode),
    sandreCode: PropTypes.string.isRequired,
    readMode: PropTypes.bool,
    onChange: PropTypes.func,
    setPopup: PropTypes.func,
}

const mapStateToProps = store => ({
    sandreCodes: store.ReferencialReducer.sandreCodes,
})

const mapDispatchToProps = {
    setPopup: SieauAction.setPopup,
}

export default connect(mapStateToProps, mapDispatchToProps)(InstallationEquipmentsPanel)