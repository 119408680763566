import React from 'react'
import i18n from 'simple-react-i18n'
import PropTypes from 'prop-types'
import Select from 'components/forms/Select'
import { getIntegrationModes } from 'utils/JobUtils'
import { integrationModeHelpIcon } from 'import/utils/ImportUtils'
import { hasValue } from 'utils/NumberUtil'
import Input from 'components/forms/Input'
import { StyledFieldSet, StyledLegend, StyledSpan } from 'components/StyledElements'
import { Grid } from '@mui/material'
import StationImportCodeMessage from 'import/components/utils/StationImportCodeMessage'
import Table from 'components/datatable/Table'
import RadioButtons from 'components/forms/RadioButtons'
import SimpleDatePicker from 'components/forms/SimpleDatePicker'
import { shallowEqual, useSelector } from 'react-redux'
import Checkbox from 'components/forms/Checkbox'

const AddPluvioStepOptionsMF = ({
    jobDataTypes,
    setJobDataTypes,
    changeFilters,
    filters = {},
}) => {
    const {
        pluviometryDataTypes,
        cities,
    } = useSelector(store => ({
        pluviometryDataTypes: store.PluviometryReducer.pluviometryDataTypes,
        cities: store.CityReducer.cities,
    }), shallowEqual)

    const integrationModes = getIntegrationModes().map(im => im.value)

    const onChangeDataIntegration = (dataType) => {
        const filteredDataTypes = jobDataTypes.filter(jdt => !integrationModes.includes(jdt))
        setJobDataTypes(dataType && [...filteredDataTypes, dataType] || filteredDataTypes)
    }

    const mfDataTypes = ['rain', 'radiation', 'temperature', 'etp']
    const dataTypes = mfDataTypes.map(eraDt => {
        return {
            nullValue: <Checkbox checked={filters[eraDt]} onChange={v => changeFilters({ [eraDt]: v })} />,
            name: i18n[eraDt],
            dataType: <Select value={filters[`${eraDt}Dt`]} options={pluviometryDataTypes} onChange={v => changeFilters({ [`${eraDt}Dt`]: v })} disabled={!filters[eraDt]} />,
        }
    })

    const codesTypes = [
        { code: 'stations', name: i18n.stations },
        { code: 'cities', name: i18n.cities },
        { code: 'departments', name: i18n.departments },
    ]

    const stationToAdd = filters?.stationCodes?.[0]
    const cityToAdd = filters?.cityCodes?.[0]
    const departmentToAdd = filters?.departmentCodes?.[0]

    return (
        <>
            <StyledFieldSet>
                <StyledLegend>
                    <StyledSpan>{i18n.options}</StyledSpan>
                </StyledLegend>
                <Grid>
                    <Grid container justifyContent='space-between' spacing={2} sx={{ paddingTop: '10px', paddingBottom: '10px' }}>
                        <Grid item xs={12}>
                            <Grid item xs={6}>
                                <RadioButtons
                                    elements={codesTypes}
                                    selected={filters.codeMode}
                                    onChange={v => changeFilters({ codeMode: v, cityCodes: [], departmentCodes: [], stationCodes: [] })}
                                />
                            </Grid>
                        </Grid>
                        {
                            filters.codeMode === 'stations' && (
                                <>
                                    <Grid item xs={3}>
                                        <Input
                                            value={stationToAdd}
                                            onChange={v => changeFilters({ stationCodes: [v] })}
                                            title={i18n.code}
                                            noSpace
                                        />
                                    </Grid>
                                    <Grid container item xs={9}>
                                        {(hasValue(stationToAdd) && <StationImportCodeMessage
                                            code={stationToAdd}
                                            stationType='pluviometry'
                                        />)}
                                    </Grid>
                                </>
                            )
                        }
                        {
                            filters.codeMode === 'cities' && (
                                <Grid item xs={3}>
                                    <Select
                                        label={i18n.city}
                                        options={cities}
                                        value={cityToAdd}
                                        onChange={v => changeFilters({ cityCodes: [v] })}
                                        keyValue='id'
                                    />
                                </Grid>
                            )
                        }
                        {
                            filters.codeMode === 'departments' && (
                                <Grid item xs={3}>
                                    <Input
                                        title={i18n.code}
                                        value={departmentToAdd}
                                        onChange={v => changeFilters({ departmentCodes: [v] })}
                                        maxLength={3}
                                    />
                                </Grid>
                            )
                        }

                    </Grid>
                </Grid>
            </StyledFieldSet>
            <StyledFieldSet>
                <StyledLegend>
                    <StyledSpan>{i18n.parameters}</StyledSpan>
                </StyledLegend>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <Select
                            value={jobDataTypes.find(d => integrationModes.includes(d))}
                            options={getIntegrationModes()}
                            label={i18n.dataIntegration}
                            labelSpan={integrationModeHelpIcon()}
                            onChange={onChangeDataIntegration}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <SimpleDatePicker
                            label={i18n.startDate}
                            value={filters.startDate}
                            onChange={date => changeFilters({ startDate: date })}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Table
                            data={dataTypes}
                            showTitle={false}
                            type={{ headers: ['nullValue', 'name', 'dataType'] }}
                            condensed
                            sortable
                            showNbElements={false}
                        />
                    </Grid>
                </Grid>
            </StyledFieldSet>
        </>

    )
}

AddPluvioStepOptionsMF.propTypes = {
    jobDataTypes: PropTypes.arrayOf(PropTypes.string),
    setJobDataTypes: PropTypes.func,
    changeFilters: PropTypes.func,
    filters: PropTypes.shape({}),
}

export default AddPluvioStepOptionsMF