export default class DtoPollutionSourceParameter {
    constructor(obj) {
        this.parameterCode = obj.parameterCode // Option[String],
        this.parameterName = obj.parameterName // Option[String],
        this.installationName = obj.installationName // Option[String],
        this.identifier = obj.identifier // Option[String],
        this.city = obj.city // Option[String],
        this.cityName = obj.cityName // Option[String],
        this.priority = obj.priority // Option[Int],
        this.activityCode = obj.activityCode // Option[String],
        this.activityName = obj.activityName // Option[String],
        this.activityCategory = obj.activityCategory // Option[Long],
        this.x = obj.x // Option[Double],
        this.y = obj.y // Option[Double],
        this.projection = obj.projection // Option[Double]
        this.dischargeAgreement = obj.dischargeAgreement // Option[Boolean]
        this.icpe = obj.icpe // Option[Boolean]
        this.inActivity = obj.inActivity // Option[Boolean]
    }
}