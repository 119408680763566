import React from 'react'
import echarts from 'echarts/lib/echarts'
import ReactECharts from 'echarts-for-react'
import PropTypes from 'prop-types'
import { round } from 'lodash'
import i18n from 'simple-react-i18n'
import { exportExcelIcon, exportPictureIcon } from './EChartUtils'
import { exportFile } from 'utils/ExportDataUtil'

const breakLine = (name = '', nbChar = 30) => {
    const splitName = name.split(' ')
    let temp = 0
    return splitName.reduce((acc, cur) => {
        if (!acc) {
            temp = cur.length
            return cur
        }
        if (temp + cur.length > nbChar) {
            temp = cur.length
            return `${acc}\n${cur}`
        }
        temp = temp + cur.length
        return `${acc} ${cur}`
    }, '')
}

const defaultformatExportData = (data = []) => data.length ? [{ ...data[0], headers: Object.keys(data[0]) }, ...data.slice(1)] : []

const Pie = ({
    title = '',
    data = [],
    formatExportData = defaultformatExportData,
    tooltipFormatter = `{b} : {c} ${i18n.elements} ({d}%)`,
    height = 300,
}) => {
    const options = {
        title: {
            text: title,
            left: 'center',
        },
        tooltip: {
            trigger: 'item',
            formatter: tooltipFormatter,
        },
        legend: {
            type: 'scroll',
            orient: 'vertical',
            right: 60,
            top: 50,
            bottom: 20,
            align: 'right',
            formatter: breakLine,
            textStyle: {
                overflow: 'breakAll',
            },
        },
        toolbox: {
            showTitle: false,
            right: 60,
            itemSize: 18,
            tooltip: {
                show: true,
                position: 'bottom',
                confine: true,
            },
            feature: {
                saveAsImage: {
                    title: i18n.pictureExport,
                    icon: exportPictureIcon,
                    name: title,
                },
                myToolExport: {
                    title: i18n.excelExport,
                    icon: exportExcelIcon,
                    onclick: () => {
                        exportFile({
                            data: formatExportData(data),
                            exportType: 'xlsx',
                            titleFile: title,
                        })
                    },
                },
            },
        },
        series: [
            {
                type: 'pie',
                radius: round(height / 3),
                center: ['30%', '50%'],
                data,
                label: { show: false },
                top: 30,
            },
        ],
    }

    return (
        <ReactECharts
            echarts={echarts}
            option={options}
            notMerge={true}
            lazyUpdate={true}
            style={{ height }}
        />
    )
}

Pie.propTypes = {
    title: PropTypes.string,
    data: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        value: PropTypes.number,
    })),
    formatExportData: PropTypes.func,
    tooltipFormatter: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    height: PropTypes.number,
}

export default Pie