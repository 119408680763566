import React, { useState } from 'react'
import i18n from 'simple-react-i18n'
import { v4 as uuidv4 } from 'uuid'
import PropTypes from 'prop-types'
import { Editor } from 'react-draft-wysiwyg'
import { ContentState, convertToRaw, EditorState } from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'
import countryFlagEmoji from 'country-flag-emoji'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { Grid } from '@mui/material'

/* Docs draft-js
    https://draftjs.org/
    https://jpuri.github.io/react-draft-wysiwyg/
    https://reactrocket.com/post/draft-js-persisting-content/
    https://github.com/facebook/draft-js/issues/2118
    https://www.npmjs.com/package/html-to-draftjs
*/

// .public-DraftStyleDefault-block margin: '0.1rem 0'

const RichTextEditor = ({
    readMode,
    disabled,
    obligatory,
    col = 12,
    value,
    label,
    onChange,
    noMargin = false,
}) => {
    const [id] = useState(uuidv4())
    const getEditorStateInit = () => {
        if (value) {
            const blocksFromHtml = htmlToDraft(value)
            const { contentBlocks, entityMap } = blocksFromHtml
            const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap)
            return EditorState.createWithContent(contentState)
        }
        return EditorState.createEmpty()
    }
    const [editorState, setEditorState] = useState(getEditorStateInit())

    const getDisplayCard = () => {
        // {renderHTML(draftToHtml(convertToRaw(editorState.getCurrentContent())))}
        return (
            <div className='ignore-css' dangerouslySetInnerHTML={{ __html: value || '' }} />
        )
    }

    const onEditorStateChange = (editorStateUpdated) => {
        onChange(draftToHtml(convertToRaw(editorStateUpdated.getCurrentContent())))
        setEditorState(editorStateUpdated)
    }

    const getEditorForm = () => {
        return (
            <Grid container item xs={col} sx={{ marginTop: !noMargin && '1rem' }}>
                <Grid item xs={12} sx={{ color: !disabled && 'rgb(22, 24, 50)', paddingLeft: '1.2rem', fontWeight: 'bold' }}>
                    {label}
                    {obligatory && <span className='primary-color-text'>{i18n.obligatoryField}</span>}
                </Grid>
                <Grid
                    item xs={12}
                    sx={{
                        backgroundColor: '#fff',
                        border: `1px solid ${disabled ? '#ddd' : '#7a7a7a'}`,
                        borderRadius: '5px',
                        paddingLeft: '5px',
                        paddingRight: '5px',
                        '& .public-DraftStyleDefault-block': {
                            margin: '0.1rem 0',
                        },
                    }}
                >
                    <Editor
                        wrapperId={id}
                        toolbarClassName='toolbarClassName'
                        wrapperClassName='wrapperClassName'
                        editorClassName='editorClassName'
                        editorState={editorState}
                        onEditorStateChange={onEditorStateChange}
                        readOnly={disabled}
                        toolbar={{
                            options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'emoji', 'remove', 'history'],
                            emoji: {
                                // icon: 'flag',
                                className: undefined,
                                component: undefined,
                                popupClassName: undefined,
                                emojis: [
                                    '😀', '😁', '😂', '😃', '😉', '😋', '😎', '😍', '😗', '🤗', '🤔', '😣', '😫', '😴', '😌', '🤓',
                                    '😛', '😜', '😠', '😇', '😷', '😈', '👻', '😺', '😸', '😹', '😻', '😼', '😽', '🙀', '🙈',
                                    '🙉', '🙊', '👼', '👮', '🕵', '💂', '👳', '🎅', '👸', '👰', '👲', '🙍', '🙇', '🚶', '🏃', '💃',
                                    '⛷', '🏂', '🏌', '🏄', '🚣', '🏊', '⛹', '🏋', '🚴', '👫', '💪', '👈', '👉', '👉', '👆', '🖕',
                                    '👇', '🖖', '🤘', '🖐', '👌', '👍', '👎', '✊', '👊', '👏', '🙌', '🙏', '🐵', '🐶', '🐇', '🐥',
                                    '🐸', '🐌', '🐛', '🐜', '🐝', '🍉', '🍄', '🍔', '🍤', '🍨', '🍪', '🎂', '🍰', '🍾', '🍷', '🍸',
                                    '🍺', '🌍', '🚑', '⏰', '🌙', '🌝', '🌞', '⭐', '🌟', '🌠', '🌨', '🌩', '⛄', '🔥', '🎄', '🎈',
                                    '🎉', '🎊', '🎁', '🎗', '🏀', '🏈', '🎲', '🔇', '🔈', '📣', '🔔', '🎵', '🎷', '💰', '🖊', '📅',
                                    '✅', '❎', ...countryFlagEmoji.list.map((e) => e.emoji),
                                ],
                            },
                        }}
                    />
                </Grid>
            </Grid>
        )
    }

    if (readMode) {
        return getDisplayCard()
    }
    return getEditorForm()
}

RichTextEditor.propTypes = {
    readMode: PropTypes.bool,
    disabled: PropTypes.bool,
    obligatory: PropTypes.bool,
    col: PropTypes.number,
    value: PropTypes.string,
    label: PropTypes.string,
    onChange: PropTypes.func,
    noMargin: PropTypes.bool,
}

export default RichTextEditor
