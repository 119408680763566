import { chunk, isEqual, orderBy, uniqBy } from 'lodash'
import PropTypes from 'prop-types'
import React, { useState } from 'react'

import i18n from 'simple-react-i18n'
import Checkbox from '../../../../../components/forms/Checkbox'
import Row from '../../../../../components/react/Row'
import { getFrequencyStats } from '../../../../../piezometry/components/suivi/utils/SuiviStatsUtils'
import { StyledFieldSet, StyledLegend } from '../../../../../components/StyledElements'
import Select from '../../../../../components/forms/Select'
import Button from '../../../../../components/forms/Button'
import RadioButtons from '../../../../../components/forms/RadioButtons'
import SimpleDatePicker from '../../../../../components/forms/SimpleDatePicker'
import moment from 'moment/moment'
import { getUser } from '../../../../../utils/SettingUtils'
import { useDispatch } from 'react-redux'
import HydrometerStationAction from '../../../../../station/actions/HydrometerStationAction'
import DtoHydrometricStation from '../../../../dto/DtoHydrometricStation'


const HydroSuiviStatTab = ({
    hydrometer,
    statsSeries,
    changeStatsSeries,
    histoYears,
    dataTypeSelected,
    changeDataType,
    hydroStatistics,
    setFrequencyStats,
    loadFrequencyStats,
    hydrometerChartOptions = [],
    datesChartOptions = {},
    linearMode = false,
    setLinearMode = {},
    validOnly = false,
    setValidOnly = {},
}) => {
    const hydroOption = hydrometerChartOptions.find(opt => parseInt(opt.dataType || -1) === dataTypeSelected)

    const [dataType, setDataType] = useState(dataTypeSelected)

    const [isShowSeriesObjet, setIsShowSeriesObjet] = useState(statsSeries)
    const [tmpValidOnly, setTmpValidOnly] = useState(validOnly)
    const [tmpLinearMode, setTmpLinearMode] = useState(linearMode)

    const [tmpStartDateChartOptions, setTmpStartDateChartOptions] = useState(datesChartOptions.startDate)
    const [tmpEndDateChartOptions, setTmpEndDateChartOptions] = useState(datesChartOptions.endDate)


    const dispatch = useDispatch()

    const change = (stat, v) => {
        setIsShowSeriesObjet(prev => ({ ...prev, [stat]: v }))
        setFrequencyStats(stat)
    }

    const onSave = () => {
        if (dataTypeSelected !== dataType) {
            changeDataType(dataType)
        }
        if (validOnly !== tmpValidOnly) {
            setValidOnly(tmpLinearMode)
        }
        if (linearMode !== tmpLinearMode) {
            setLinearMode(tmpLinearMode)
        }
        changeStatsSeries(isShowSeriesObjet)
        loadFrequencyStats(dataType, tmpValidOnly)
    }

    const freChecksHeight = chunk(getFrequencyStats(histoYears), 2).map(([freS, freH]) => (
        <Row>
            <Checkbox col={ 6 } label={ freS.name } checked={ isShowSeriesObjet[freS.code] } onChange={ v => change(freS.code, v)}/>
            <Checkbox col={ 6 } label={ freH.name } checked={ isShowSeriesObjet[freH.code] } onChange={ v => change(freH.code, v)}/>
        </Row>
    ))

    const statsUsed = uniqBy(orderBy(hydroStatistics, ['order', 'typeId'], 'asc').map(h => ({
        id: h.typeId,
        label: h.label,
    })), 'id')

    const validRadioElements = [
        { code: true, name: i18n.yes },
        { code: false, name: i18n.no },
    ]

    const saveOptions = () => {
        datesChartOptions.setStartDate(tmpStartDateChartOptions)
        datesChartOptions.setEndDate(tmpEndDateChartOptions)
        const newOptions = [
            hydrometerChartOptions.filter(opt => parseInt(opt.dataType || -1) !== -1),
            {
                ...hydroOption,
                dataType: `${dataTypeSelected}`,
                statsCalculationStartDate: tmpStartDateChartOptions,
                statsCalculationEndDate: tmpEndDateChartOptions,
            },
        ]
        dispatch(HydrometerStationAction.updateHydrometerChartOptions(hydrometer.id, newOptions))
    }

    const disabled = getUser().consultant === '1'

    return (
        <div>
            <StyledFieldSet>
                <StyledLegend>{ i18n.dataType }</StyledLegend>
                <Select
                    col={ 10 }
                    value={ dataType }
                    options={ statsUsed }
                    onChange={ v => setDataType(v) }
                    noNullValue
                    noSort
                />
            </StyledFieldSet>
            <StyledFieldSet>
                <StyledLegend>{ i18n.options }</StyledLegend>
                <Row className='padding-top-1'>
                    <RadioButtons
                        col={ 12 }
                        elements={ validRadioElements }
                        selected={ tmpValidOnly }
                        onChange={ () => setTmpValidOnly(prev => !prev) }
                        disabled={disabled}
                        title={ i18n.calcValidDataOnly }
                    />
                </Row>
                <Row><h5>{i18n.datesCalculsStats}</h5></Row>
                <Row><h6>{i18n.dateYearStat}</h6></Row>
                <Row>
                    <SimpleDatePicker
                        col={4}
                        value={tmpStartDateChartOptions}
                        label={i18n.startDate}
                        onChange={setTmpStartDateChartOptions}
                        disabled={disabled}
                    />
                    <SimpleDatePicker
                        col={4}
                        value={tmpEndDateChartOptions}
                        label={i18n.endDate}
                        onChange={setTmpEndDateChartOptions}
                        max={moment().startOf('year').valueOf()}
                        disabled={disabled}
                    />
                    { !disabled && <Button col={4} title={i18n.register} onClick={saveOptions} />}
                </Row>
                <Row className='padding-left-1'>
                    <Checkbox checked={ tmpLinearMode } onChange={ setTmpLinearMode } label={i18n.linearMode} />
                </Row>
            </StyledFieldSet>
            <StyledFieldSet>
                <StyledLegend>{ i18n.statistics }</StyledLegend>
                <div className='divider'/>
                <Row>
                    <Checkbox
                        col={ 12 }
                        label={ i18n.enveloppeMinMax }
                        checked={ isShowSeriesObjet.enveloppe }
                        onChange={ v => setIsShowSeriesObjet({ ...isShowSeriesObjet, enveloppe: v }) }
                    />
                </Row>
                <Row>
                    <Checkbox
                        col={ 12 }
                        label={ i18n.averageStatistics }
                        checked={ isShowSeriesObjet.average }
                        onChange={ v => setIsShowSeriesObjet({ ...isShowSeriesObjet, average: v }) }
                    />
                </Row>
            </StyledFieldSet>
            <StyledFieldSet>
                <StyledLegend>{ i18n.frequencyStats }</StyledLegend>
                { freChecksHeight.length ? freChecksHeight : i18n.noDataOverThisPeriod }
            </StyledFieldSet>
            <Row className='padding-bottom-1 padding-top-1 center-align'>
                <Button
                    tooltip={ i18n.apply }
                    onClick={ onSave }
                    icon='border_color'
                    className={`btn-floating btn-large ${!isEqual(isShowSeriesObjet, statsSeries) ? 'pulse' : ''}`}
                />
            </Row>
        </div>
    )
}

HydroSuiviStatTab.propTypes = {
    dataTypeSelected: PropTypes.number,
    hydroStatistics: PropTypes.arrayOf(PropTypes.shape({
        typeId: PropTypes.number,
        label: PropTypes.string,
    })),
    statsSeries: PropTypes.objectOf(PropTypes.bool),
    changeStatsSeries: PropTypes.func,
    changeParentState: PropTypes.func,
    histoYears: PropTypes.number,
    changeDataType: PropTypes.func,
    displayLog: PropTypes.bool,
    changeDisplayLog: PropTypes.func,
    setFrequencyStats: PropTypes.func,
    loadFrequencyStats: PropTypes.func,
    hydrometer: PropTypes.instanceOf(DtoHydrometricStation),
    hydrometerChartOptions: PropTypes.func,
    datesChartOptions: PropTypes.shape({
        startDate: PropTypes.number,
        endDate: PropTypes.number,
    }),
    linearMode: PropTypes.bool,
    setLinearMode: PropTypes.func,
    validOnly: PropTypes.bool,
    setValidOnly: PropTypes.func,
}


export default HydroSuiviStatTab