module.exports = {
    BLUE: 'BLUE',
    WHITE: 'WHITE',
    YELLOW: 'YELLOW',
    GREEN: 'GREEN',
    RED: 'RED',
    ORANGE: 'ORANGE',
    GREY: 'GREY',
    DARKGREEN: 'DARKGREEN',
    LIGHTBLUE: 'LIGHTBLUE',
    DARKBLUE: 'DARKBLUE',
    LIGHTGREY: 'LIGHTGREY',
    BROWN: 'BROWN',
    BLUE_OBSERVATIONS: 'BLUE_OBSERVATIONS',
    YELLOW_OBSERVATIONS: 'YELLOW_OBSERVATIONS',
    ORANGE_OBSERVATIONS: 'ORANGE_OBSERVATIONS',
    RED_OBSERVATIONS: 'RED_OBSERVATIONS',
    GREY_OBSERVATIONS: 'GREY_OBSERVATIONS',
    COLOR_TAB: {
        1: ['BLUE', 'RED'],
        2: ['BLUE', 'ORANGE', 'RED'],
        3: ['BLUE', 'YELLOW', 'ORANGE', 'RED'],
        4: ['BLUE', 'GREEN', 'YELLOW', 'ORANGE', 'RED'],
    },
    BROWN_COLORS: [
        '#a52a2a',
        '#a0522d',
        '#b5651d',
        '#f4a460',
        '#c19a6b',
        '#321414',
    ],
    COLORS_CONSTANTS: [
        {
            constant: 'BROWN',
            color: 'brown',
            rgb: 'rgb(165,42,42)',
        },
        {
            constant: 'BLUE',
            color: 'blue',
            rgb: 'rgb(0,0,255)',
        },
        {
            constant: 'DARKBLUE',
            color: 'mediumblue',
            rgb: 'rgb(0,0,205)',
        },
        {
            constant: 'LIGHTBLUE',
            color: 'aqua',
            rgb: 'rgb(0,255,255)',
        },
        {
            constant: 'WHITE',
            color: 'white',
            rgb: 'rgb(255,255,255)',
        }, {
            constant: 'YELLOW',
            color: 'yellow',
            rgb: 'rgb(255,236,19)',
        }, {
            constant: 'GREEN',
            color: 'green',
            rgb: 'rgb(84, 203, 89)',
        }, {
            constant: 'DARKGREEN',
            color: 'darkgreen',
            rgb: 'rgb(24,115,17)',
        }, {
            constant: 'RED',
            color: 'red',
            rgb: 'rgb(255,0,0)',
        }, {
            constant: 'ORANGE',
            color: 'orange',
            rgb: 'rgb(255,173,29)',
        }, {
            constant: 'GREY',
            color: 'grey',
            rgb: 'rgb(136,136,136)',
        }, {
            constant: 'LIGHTGREY',
            color: 'lightgrey',
            rgb: 'rgb(189,189,189)',
        },
        {
            constant: 'BLUE_OBSERVATIONS',
            color: 'blue-observation',
            rgb: 'rgb(0, 141, 202)',
        },
        {
            constant: 'YELLOW_OBSERVATIONS',
            color: 'yellow-observation',
            rgb: 'rgb(255, 215, 0)',
        },
        {
            constant: 'ORANGE_OBSERVATIONS',
            color: 'orange-observation',
            rgb: 'rgb(237, 119, 19)',
        },
        {
            constant: 'RED_OBSERVATIONS',
            color: 'red-observation',
            rgb: 'rgb(203, 13, 29)',
        },
        {
            constant: 'GREY_OBSERVATIONS',
            color: 'grey-observation',
            rgb: 'rgb(169, 170, 172)',
        },
        {
            constant: 'null',
            color: '',
            rgb: '',
        },
    ],
}
