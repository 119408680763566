export default class DtoHydroMeasures {
    constructor(obj) {
        this.stationId = obj.stationId // Int, //id du piézo
        this.dataType = obj.dataType // Int, // Type des données. -1 -> profondeur, -2 -> prélèvements, > 3 -> données complémentaires (cf table parametrage_data)
        this.groupFunc = obj.groupFunc // Option[String], // regroupement des données : Vide -> retourne tout, "MAX" -> max journaliers, "MIN", "AVERAGE", "SUM", "ENVELOPPE_MIN", "ENVELOPPE_MAX", "ENVELOPPE_AVERAGE"
        this.startDate = obj.startDate // Option[DateTime] = None, // date de début des données
        this.endDate = obj.endDate // Option[DateTime] = None, // date de fin des données
        this.validOnly = obj.validOnly // Option[Boolean] = None // mettre à "true" pour ne récupérer que les données validées, sinon l'ensemble des données est récupéré
        this.chartMode = obj.chartMode // le mode graphique retroune des DtoHydroMeasureLight (plus rapide, plus light). Sinon, retourne des DtoHydroMeasure normaux
        this.measures = obj.measures // list de DtoPluvioMeasure ou DtoPluvioMeasureLight (en fonction du chartMode)
        this.codepoint = obj.codepoint
    }
}
