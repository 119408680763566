import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import Input from '../../../components/forms/Input'
import NumberField from '../../../components/forms/NumberField'
import Select from '../../../components/forms/Select'
import SieauAction from '../../../components/sieau/SieauAction'
import DtoInstallationWithGeoItem from '../../../installation/components/installations/dto/DtoInstallationWithGeoItem'
import AppStore from '../../../store/AppStore'
import LandRSEauSettingModal from './LandRSEauSettingModal'

class NewLandModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            land: props.land || {
                id: 0,
            },
        }
    }

    setRSEauSettingModal = () => {
        const popup = {
            id: 'parcelmodal',
            header: i18n.parcel,
            content: <LandRSEauSettingModal landId={ this.state.land.id } />,
        }
        AppStore.dispatch(SieauAction.setPopup(popup))
    }

    change = changes => this.setState({ land: { ...this.state.land, ...changes } })

    render = () => {
        const { land } = this.state
        return (
            <div>
                <div className='row no-margin padding-top-1'>
                    <Input
                        col={6}
                        title={i18n.code}
                        value={land.code}
                        onChange={value => this.change({ code: value })}
                    />
                    <Select
                        col={6}
                        label={i18n.pointPrelevement}
                        value={land.idSamplingPoint}
                        options={ this.props.installationsWithGeo }
                        onChange={i => this.change({ idSamplingPoint: i })}
                        keyValue='id'
                        keyLabel='code'
                        displayWithCode
                    />
                </div>
                <div className='row no-margin padding-top-1'>
                    <NumberField
                        col={6}
                        title={i18n.areaha}
                        value={land.area}
                        onChange={value => this.change({ area: value })}
                        floatValue
                    />
                </div>
                <div className='row no-margin padding-top-1 padding-bottom-1'>
                    <a className='waves-effect waves-teal btn-flat modal-close btn font-white primary-button right margin-left-1' onClick={ () => this.props.onChangeLand(this.state.land) }>{ i18n.validate }</a>
                    { land.id !== 0 && <a className='waves-effect waves-teal btn-flat btn font-white primary-button right margin-left-1' onClick={ () => this.setRSEauSettingModal() }>{ 'Modifier les paramètres RSEau' }</a> }
                    <a className='waves-effect waves-teal btn-flat modal-close secondary-button right margin-left-1'>{ i18n.close }</a>
                </div>
            </div>
        )
    }
}

NewLandModal.propTypes = {
    land: PropTypes.shape({
        id: PropTypes.number,
    }),
    installationsWithGeo: PropTypes.arrayOf(PropTypes.instanceOf(DtoInstallationWithGeoItem)),
    onChangeLand: PropTypes.func,
}

const mapStateToProps = store => ({
    installationsWithGeo: store.InstallationReducer.installationsWithGeo,
})

export default connect(mapStateToProps)(NewLandModal)