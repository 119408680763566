export default class QesoutHistoryDto {
    constructor(obj = {}) {
        this.code = obj.code
        this.num = obj.num
        this.startDate = obj.startDate
        this.endDate = obj.endDate
        this.integrationDate = obj.integrationDate
        this.sendDate = obj.sendDate
        this.cancelDate = obj.cancelDate
        this.login = obj.login
        this.fileName = obj.fileName
    }
}
