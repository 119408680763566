import { groupBy, isNil, pick, uniq, isNumber, isNaN } from 'lodash'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import Card from '../../../components/card/Card'
import ProgressBar from '../../../components/progress/ProgressBar'
import PiezometerStationAction from '../../../station/actions/PiezometerStationAction'
import StationAction from '../../../station/actions/StationAction'
import DtoMeasureStats from '../../../station/dto/piezometer/DtoMeasureStats'
import DtoPiezoThreshold from '../../../station/dto/piezometer/DtoPiezoThreshold'
import { getLocalStorageJson } from '../../../utils/FormUtils'
import { arrayOf, instanceOf } from '../../../utils/StoreUtils'
import PiezometryAction from '../../actions/PiezometryAction'
import { HIDDEN_CHARTS, MEASURE_COTE, PIEZO_L_STORAGE } from '../../constants/PiezometryConstants'
import DtoParametrageDataType from '../../dto/DtoParametrageDataType'
import PiezometryExploitedChartPanel from './PiezometryExploitedChartPanel'
import DtoPiezometer from '../../dto/DtoPiezometer'
import { WhiteCard } from 'components/styled/Card'
import Row from 'components/react/Row'
import AppStore from '../../../store/AppStore'
import IAEauAction from '../../../iaeau/IAEauAction'
import { hasBooleanValue } from '../../../utils/NumberUtil'
import AnalysisAction from '../../../quality/actions/AnalysisAction'
import DtoAssociatedStation from '../../../station/dto/DtoAssociatedStation'
import DtoAnalysisLight from '../../../quality/dto/analyse/DtoAnalysisLight'
import { STATION_TYPE_NAME } from '../../../station/constants/StationConstants'
import { getQualificationSelectOptions, getStatusSelectOptions } from '../../../utils/StatusUtil'
import DtoPiezoChartMeasures from 'piezometry/dto/chart/DtoPiezoChartMeasures'
import { Grid } from '@mui/material'
import MUIChartTabs from '../../../components/echart/MUIChartTabs'
import ChartTabPredData from '../../../station/components/iaeauModels/ChartTabPredData'
import ChartOptionsChip from '../../../components/echart/ChartOptionsChip'
import { HISTO, J90 } from '../../../alerting/constants/ChartFollowContants'
import { CHART_SELECTED_TIME, CHART_SELECTED_TIME_OTHER, OTHER } from '../../../quality/constants/ChartConstant'

// TODO ATTENTION ! Composant réutilisé dans plusieurs écrans
class PiezometryExploitedPanel extends Component {
    constructor(props) {
        super(props)
        const storedHiddenCharts = getLocalStorageJson(HIDDEN_CHARTS) || {}
        const tmpDisplayCote = getLocalStorageJson(PIEZO_L_STORAGE.DISPLAY_COTE) || props.displayCote || MEASURE_COTE.DEPTH // cote de calcul de la hauteur d'eau affichée (Profondeur, NGF, sol, ...)
        const noSol = !props.piezometer.link_landmarks?.some(l => l.origin === 3) // pas de repère de type SOL = pas d'affichage possible en mode SOL
        const selectedTimeTmp = getLocalStorageJson(CHART_SELECTED_TIME) || J90
        const selectedTime = isNaN(parseInt(selectedTimeTmp)) ? (selectedTimeTmp === HISTO ? J90 : selectedTimeTmp) : parseInt(selectedTimeTmp)
        const minDateFromPreviousTime = (() => {
            if (isNumber(selectedTime)) {
                if (selectedTime <= 2) {
                    return moment().subtract(selectedTime, 'days').valueOf()
                }
                return moment().subtract(selectedTime, 'days').startOf('day').valueOf()
            }
            return undefined
        })()
        const selectedOtherDates = selectedTime === OTHER ? (getLocalStorageJson(CHART_SELECTED_TIME_OTHER) ?? {}) : {}
        this.state = {
            time: selectedTime,
            minDate: selectedOtherDates.minDate ?? minDateFromPreviousTime,
            maxDate: selectedOtherDates.maxDate,
            hiddenCharts: storedHiddenCharts,
            displayCote: tmpDisplayCote === MEASURE_COTE.GROUND && noSol ? MEASURE_COTE.NGF : tmpDisplayCote,
            dataLoaded: false,
            progress: 0,
            displayModes: getLocalStorageJson(PIEZO_L_STORAGE.DISPLAY_MODES_DASHBOARD) ?? { // différents modes d'affichage disponibles
                auto: true,
                brute: false,
                min: false,
                max: false,
                average: false,
                // ici, il y a aussi les checkbox des modèles (la clé est le nom du modèle)
            },
            filterStatusQualif: getLocalStorageJson(PIEZO_L_STORAGE.STATUS_QUALIF_DASHBOARD) ?? { // filtre sur statut et qualification (fait côté back)
                qualifications: getQualificationSelectOptions().map(q => q.code),
                status: getStatusSelectOptions().map(s => s.code),
            },
            predMeasures: [],
            selectedPred: undefined,
            predDate: undefined,
            predStats: [],
            qualitoMeasures: [],
            stats: [],
        }
    }

    componentDidMount() {
        if (!this.props.noLoad) {
            this.props.fetchPiezometer(this.props.id)
        }
        this.props.fetchPiezometerThresholds(this.props.id, MEASURE_COTE.NGF)
        this.props.fetchAssociatedSites(this.props.piezometer.code, 1)
            .then(() => this.props.fetchPiezoMeasuresStats(parseInt(this.props.id)))
            .then(r => {
                this.setState({ stats: r }, () => this.loadData(this.state))
                const statsFiltered = r.filter(stat => !hasBooleanValue(stat.showData) || stat.showData)
                const idsNitrates = statsFiltered.map(stat => {
                    return statsFiltered.find(h => h.typeId === stat.typeId)?.codeParameter
                }).filter(m => !isNil(m)).filter(p => !(p === ''))
                const assIdsQualito = this.props.associatedSites.filter(asso => asso.typeName === STATION_TYPE_NAME.quality).map(att => att.stationLinkedId)
                this.props.fetchGenericAnalysis({
                    lightMode: true,
                    stations: assIdsQualito,
                    parameters: idsNitrates,
                    support: '3', // support 'eau'
                }).then(json => this.setState({ qualitoMeasures: groupBy(json.map(a => new DtoAnalysisLight(a)), STATION_TYPE_NAME.qualitometer) }))
            })
        if (this.props.piezometerThresholds.length) {
            this.setState({ piezometerThresholds: this.props.piezometerThresholds.filter(t => t.code === this.props.piezometer.code) })
        }
        if (!this.props.piezometryDataTypes.length) {
            this.props.fetchPiezometryDataTypes()
        }
        this.props.fetchPiezometerChartOptions(this.props.id)
        AppStore.dispatch(IAEauAction.getModels('piezometry', parseInt(this.props.id)))
    }

    loadData = (dates) => {
        const { filterStatusQualif: { qualifications, status } } = this.state
        const piezoStat = this.props.piezometerStatistics.find(p => p.typeId === -1) || {}
        const fetchDates = { startDate: dates.minDate ? moment(dates.minDate).subtract(6, 'hours').valueOf() : undefined, endDate: dates.maxDate }
        const duration = piezoStat ? moment.duration(moment(fetchDates.endDate || moment().valueOf()).diff(fetchDates.startDate)) : null
        const autoDisplayMode = duration ? (duration.as('days') >= 91 ? 'MAX' : 'RAW') : 'MAX'
        const sampleDisplayMode = duration ? (duration.as('days') >= 91 ? 'SUM' : 'RAW') : 'SUM'

        const baseInput = { ...fetchDates, stationId: parseInt(this.props.id) }

        const modes = this.state.displayModes
        const piezoModes = uniq([
            modes.brute ? 'RAW' : (modes.auto ? autoDisplayMode : null),
            modes.max ? 'MAX' : null,
            modes.min ? 'MIN' : null,
            modes.average ? 'AVERAGE' : null,
            modes.personalizedGrouping ? modes.personalizedGroupingValue : null,
        ].filter(m => !!m))
        const piezo = piezoModes.map(p => ({
            ...baseInput,
            displayCote: this.state.displayCote,
            groupFunc: p,
            qualifications,
            status,
            dataType: -1,
        }))

        const sampleFound = this.props.piezometerStatistics.find(p => p.typeId === -2)
        const sample = !sampleFound ? [] : [{
            ...baseInput,
            dataType: -2,
            qualifications,
            status,
            groupFunc: sampleDisplayMode,
        }]

        const other = this.props.piezometerStatistics.filter(p => p.typeId > 0).map(p => ({
            ...baseInput,
            dataType: p.typeId,
            groupFunc: autoDisplayMode,
            qualifications,
            status,
            displayCote: p.isPiezo ? this.state.displayCote : null,
        }))

        const aditionnalData = this.props.noAdditionnalData ? [] : [...sample, ...other]

        this.setState({ ...dates, dataLoaded: false, progress: 0 })

        this.props.loadPiezoChartMeasures([ ...piezo, ...aditionnalData], p => this.setState({ progress: p })).then(() => {
            this.setState({ dataLoaded: true })
            this.props.setDataLoaded()
        })
    }

    changeDates = (dates, forced) => {
        if (this.state.minDate !== dates.minDate || this.state.maxDate !== dates.maxDate || forced) {
            this.loadData(dates)
        }
    }

    getProgressBar = () => (
        <Card>
            <div className='padding-top-7 padding-left-2 padding-right-2 padding-bottom-2'>
                <ProgressBar progress={ this.state.progress } withMessage/>
            </div>
        </Card>
    )

    getPanel = () => {
        const picked = pick(this.state, ['minDate', 'maxDate', 'hiddenCharts', 'displayCote', 'displayModes', 'predMeasures', 'selectedPred', 'predStats', 'qualitoMeasures', 'stats', 'chartTab'])
        return (
            <div className='side-component-content'>
                { this.state.dataLoaded && this.props.piezometer ? <PiezometryExploitedChartPanel { ...picked } /> : this.getProgressBar() }
            </div>
        )
    }

    render() {
        const { time, displayModes, displayCote, filterStatusQualif } = this.state
        const { id } = this.props
        if (this.props.piezometerStatistics.length) {
            return (
                <WhiteCard round noBoxShadow>
                    <Row className={this.state.fullScreen ? 'fullscreen-chart' : ''}>
                        {/* <div className='col s12 row no-margin padding-top-1' style={{ paddingLeft: 48 }}> */}
                        {/*     <ChartTabs */}
                        {/*         onChangeDate={(changes, forced) => this.changeDates(changes, forced)} */}
                        {/*         displayCote={this.props.displayCote} */}
                        {/*         depthMode={this.props.depthMode} */}
                        {/*         withPiezoCote */}
                        {/*         options={getHardPiezoDataTypes().concat(this.props.piezometryDataTypes)} */}
                        {/*         changeParent={changes => this.setState(changes, () => this.loadData(pick(this.state, ['minDate', 'maxDate'])))} */}
                        {/*         changeParentSimple={changes => this.setState(changes)} */}
                        {/*         withModels */}
                        {/*         id={this.props.id} */}
                        {/*         stationType='piezometry' */}
                        {/*         useFromSituation={this.props.useFromSituation} */}
                        {/*         hideAccuracy={false} */}
                        {/*         noSol={!this.props.piezometer.link_landmarks.some(l => l.origin === 3)} // pas de mode SOL */}
                        {/*     /> */}
                        {/* </div> */}
                        <div className='col row no-margin' style={{ paddingLeft: 48 }}>
                            <Grid container alignItems='center' justifyContent='center'>
                                <Grid item>
                                    <MUIChartTabs
                                        time={time}
                                        onChangeTime={(changes) => this.changeDates(changes)}
                                    />
                                </Grid>
                                <Grid item>
                                    <ChartTabPredData
                                        stationType='piezometry'
                                        id={id}
                                        setParentModelData={changes => this.setState(changes)}
                                        useFromSituation={this.props.useFromSituation}
                                    />
                                </Grid>
                                <Grid item sx={{ paddingLeft: '20px' }}>
                                    <ChartOptionsChip
                                        displayModes={displayModes}
                                        displayCote={displayCote}
                                        filterStatusQualif={filterStatusQualif}
                                        changeParent={changes => this.setState(changes, () => this.loadData(pick(this.state, ['minDate', 'maxDate'])))}
                                        withReferenceLevel={true}
                                        noSol={!this.props.piezometer.link_landmarks.some(l => l.origin === 3)} // pas de mode SOL
                                        displayModesKey={PIEZO_L_STORAGE.DISPLAY_MODES_DASHBOARD}
                                        filterStatusQualifKey={PIEZO_L_STORAGE.STATUS_QUALIF_DASHBOARD}
                                    />
                                </Grid>
                            </Grid>
                        </div>
                        <div className='col s12 row no-margin'>
                            {this.getPanel()}
                        </div>
                    </Row>
                </WhiteCard>
            )
        }
        return null
    }
}

PiezometryExploitedPanel.propTypes = {
    params: PropTypes.shape({
        id: PropTypes.string,
        type: PropTypes.string,
    }),
    id: PropTypes.string,
    piezometer: instanceOf(DtoPiezometer),
    piezometryDataTypes: PropTypes.arrayOf(PropTypes.instanceOf(DtoParametrageDataType)),
    piezometerThresholds: PropTypes.arrayOf(PropTypes.instanceOf(DtoPiezoThreshold)),
    setDataLoaded: PropTypes.func,
    piezometerStatistics: arrayOf(DtoMeasureStats),
    fetchPiezoMeasuresStats: PropTypes.func,
    fetchPiezometerChartOptions: PropTypes.func,
    fetchPiezometer: PropTypes.func,
    fetchPiezometryDataTypes: PropTypes.func,
    noAdditionnalData: PropTypes.bool, // permet de ne pas afficher les données complémentaires,
    displayCote: PropTypes.number, // mode d'affichage piézo par défaut
    depthMode: PropTypes.bool, // permet d'afficher uniquement le mode profondeur
    fetchPiezometerThresholds: PropTypes.func,
    showTitle: PropTypes.bool,
    loadPiezoChartMeasures: PropTypes.func,
    useFromSituation: PropTypes.bool, // permet de charger des données de prévision sur la situation (ne pas retirer)
    getAnalysis: PropTypes.func,
    associatedSites: PropTypes.arrayOf(PropTypes.instanceOf(DtoAssociatedStation)),
    fetchGenericAnalysis: PropTypes.func,
    fetchAssociatedSites: PropTypes.func,
    chartMeasures: PropTypes.arrayOf(PropTypes.instanceOf(DtoPiezoChartMeasures)),
    noLoad: PropTypes.bool,
}

const mapStateToProps = store => ({
    piezometer: store.StationReducer.piezometer,
    piezometryDataTypes: store.PiezometryReducer.piezometryDataTypes,
    piezometerThresholds: store.PiezometerStationReducer.piezometerThresholds,
    piezometerStatistics: store.PiezometerStationReducer.piezometerStatistics,
    associatedSites: store.StationReducer.associatedSites,
    chartMeasures: store.PiezometerStationReducer.chartMeasures,
})

const mapDispatchToProps = {
    fetchPiezoMeasuresStats: PiezometerStationAction.fetchPiezoMeasuresStats,
    fetchPiezometer: StationAction.fetchPiezometer,
    loadPiezoChartMeasures: PiezometerStationAction.loadPiezoChartMeasures,
    fetchPiezometryDataTypes: PiezometryAction.fetchPiezometryDataTypes,
    fetchPiezometerChartOptions: PiezometerStationAction.fetchPiezometerChartOptions,
    fetchPiezometerThresholds: PiezometerStationAction.fetchPiezometerThresholds,
    fetchGenericAnalysis: AnalysisAction.fetchGenericAnalysis,
    fetchAssociatedSites: StationAction.fetchAssociatedSites,
}

PiezometryExploitedPanel.defaultProps = {
    setDataLoaded: () => {},
    showTitle: true,
}

export default connect(mapStateToProps, mapDispatchToProps)(PiezometryExploitedPanel)
