import { isNil } from 'lodash'
import moment from 'moment'
import useApplicationSetting, { intParser } from 'utils/customHook/useApplicationSetting'

const useEndPeriod = (parser = setting => setting) => {
    const regroupment = useApplicationSetting('CATCHMENT_REGROUPING', intParser) ?? 2
    const startPeriod = useApplicationSetting('CATCHMENT_START_PERIOD', intParser)
    const endPeriod = useApplicationSetting('CATCHMENT_END_PERIOD', intParser)

    if (isNil(startPeriod) && isNil(endPeriod)) return parser(moment().year())

    return parser(endPeriod ?? startPeriod + regroupment - 1)
}

export default useEndPeriod