import mtes from 'assets/pictures/logos/mtes.png'
import sandre from 'assets/pictures/logos/sandre.png'
import JobAction from 'import/actions/JobAction'
import { head, isEqual, omit } from 'lodash'
import moment from 'moment'
import PropTypes from 'prop-types'
import queryString from 'query-string'
import React from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import ToastrAction from 'toastr/actions/ToastrAction'
import AlertHydro from '../../../alerting/components/job/AlertHydro'
import AlertPiezo from '../../../alerting/components/job/AlertPiezo'
import AlertPluvio from '../../../alerting/components/job/AlertPluvio'
import OutlierQualityJobPanel from './formats/OutlierQualityJobPanel'
import ActionComponent from '../../../components/ActionComponent'
import Other from '../../../components/actions/Other'
import Checkbox from '../../../components/forms/Checkbox'
import Input from '../../../components/forms/Input'
import Select from '../../../components/forms/Select'
import Row from '../../../components/react/Row'
import ExportJob from '../../../export/components/formats/ExportJob'
import ExportQuesu from '../../../export/components/formats/ExportQuesu'
import PostFacebookJob from '../../../export/components/formats/PostFacebookJob'
import SSEActions from '../../../sse/actions/SSEActions'
import { JOB_COMPLETED } from '../../../sse/constants/EventsConstants'
import DtoKeyFigure from '../../../station/dto/DtoKeyFigure'
import { getDate, shortenHumanize } from '../../../utils/DateUtil'
import { getExecStatusIcon } from '../../../utils/JobUtils'
import { hasValue } from '../../../utils/NumberUtil'
import { getUser } from '../../../utils/SettingUtils'
import { arrayOf } from '../../../utils/StoreUtils'
import { IMPORT_FORMATS } from '../../constants/JobFormat'
import Job from '../../dto/DtoJob'
import DtoJobFile from '../../dto/DtoJobFile'
import ActivityPanel from './formats/ActivityPanel'
import AdesPiezoWebServicePanel from './formats/AdesPiezoWebServicePanel'
import AdesQualitoWebServicePanel from './formats/AdesQualitoWebServicePanel'
import AquaDBSelectionPanel from './formats/AquaDBSelectionPanel'
import AquasysPivEauPanel from './formats/AquasysPivEauPanel'
import CSVGenericPanel from './formats/CSVGenericPanel'
import ArchiveJobPanel from './formats/ArchiveJobPanel'
import EstablishmentPanel from './formats/EstablishmentPanel'
import EDILABOPanel from './formats/ExportEDILABOPanel'
import ExportEdilaboPanelRai from './formats/ExportEdilaboPanelRai'
import ExportQesoutPanel from './formats/ExportQesoutPanel'
import ExportQesoutPanelQualite from './formats/ExportQesoutPanelQualite'
import FTPJobPanel from './formats/FTPJobPanel'
import ScriptSSH from './formats/ScriptSSH'
import HubeauPiezoPanel from './formats/HubeauPiezoPanel'
import HubeauQualityPanel from './formats/HubeauQualityPanel'
import HubeauHydrobioPanel from './formats/HubeauHydrobioPanel'
import HydroPanel from './formats/HydroPanel'
import ImportCalypsoPanel from './formats/ImportCalypsoPanel'
import ImportDdassDistriPanel from './formats/ImportDdassDistriPanel'
import ImportEdiLaboPanel from './formats/ImportEdiLaboPanel'
import ImportEdiLaboDAIPanel from './formats/ImportEdiLaboDAIPanel'
import ImportFormatPanel from './formats/ImportFormatPanel'
import ImportLowWaterPanel from './formats/ImportLowWaterPanel'
import ImportPiveauPanel from './formats/ImportPiveauPanel'
import ImportQesoutPanel from './formats/ImportQesoutPanel'
import ImportQuesuPanel from './formats/ImportQuesuPanel'
import ImportRsdePanel from './formats/ImportRsdePanel'
import ImportSiapLabPanel from './formats/ImportSiapLabPanel'
import InfoTerre from './formats/InfoTerre'
import LiguraPanel from './formats/LiguraPanel'
import MailImportPanel from './formats/MailImportPanel'
import MISImportPanel from './formats/MISImportPanel'
import ParatronicPanel from './formats/ParatronicPanel'
import PiezoIndicatorsPanel from './formats/PiezoIndicatorsPanel'
import PurgeMateriel from './formats/PurgeMateriel'
import PurgeQuality from './formats/PurgeQuality'
import SaurVolumePanel from './formats/SaurVolumePanel'
import SebaPanel from './formats/SebaPanel'
import SurfaceQualitometerPanel from './formats/SurfaceQualitometerPanel'
import SynopPanel from './formats/SynopPanel'
import TapWaterPanel from './formats/TapWaterPanel'
import TideGaugePanel from './formats/TideGaugePanel'
import TideGaugeOceanogramPanel from './formats/TideGaugeOceanogramPanel'
import TrameMateriel from './formats/TrameMateriel'
import VeoliaPanel from './formats/VeoliaPanel'
import VeoliaPortalPanel from './formats/VeoliaPortalPanel'
import VeoliaVolumePanel from './formats/VeoliaVolumePanel'
import WeatherMeasuresPanel from './formats/WeatherMeasuresPanel'
import ImportFiles from './ImportFiles'
import ImportLogs from './ImportLogs'
import SchedulePanel from './SchedulePanel'
import ERA5Panel from './formats/ERA5Panel'
import MeteoFrancePanel from './formats/MeteoFrancePanel'
import EcologicalStatePanel from './formats/EcologicalStatePanel'
import SieauInstancePanel from './formats/SieauInstancePanel'
import { push } from '@lagunovsky/redux-react-router'
import DtoJobExecution from 'import/dto/DtoJobExecution'
import ImportQuadrigePanel from './formats/ImportQuadrigePanel'
import DtoJobLight from '../../dto/DtoJobLight'
import { StyledFieldSet, StyledLegend } from '../../../components/StyledElements'
import MultiContributorsAutocomplete from '../../../referencial/components/contributor/components/MultiContributorsAutocomplete'
import ContributorDto from '../../../referencial/components/contributor/dto/ContributorDto'
import AppStore from '../../../store/AppStore'
import ContributorAction from '../../../referencial/components/contributor/actions/ContributorAction'
import CalculSeee from './formats/CalculSeee'
import SelfControlPanel from './formats/SelfControlPanel'
import CatchmentTrendJob from 'catchment/components/job/CatchmentTrendJob'
import MaterielAccounting from './formats/MaterielAccounting'
import MeteoFranceRadarPanel from './formats/MeteoFranceRadarPanel'
import ImportOmnidiaPanel from './formats/ImportOmnidiaPanel'
import ExportHydroportailPanel from '../../../export/components/formats/ExportHydroportailPanel'
import BathingWaterPanel from './formats/BathingWaterPanel'
import HomeAction from 'home/actions/HomeAction'
import AlertClimatic from './formats/AlertClimatic'
import QualityPostControl from './formats/QualityPostControl'


const incrementFiles = 500

class ImportCard extends ActionComponent {
    constructor(props) {
        super(props)
        const { jobExecutionId } = queryString.parse(this.props.location.search)
        const job = props.job && props.params && props.job.id == props.params.id ? props.job : new Job()
        const jobExecutions = props.jobExecutions || []
        this.state = {
            job,
            jobExecutions,
            isEditMode: false,
            nbExecutions: 10,
            nbFiles: incrementFiles,
            panel: hasValue(jobExecutionId) ? 'journal' : 'definition',
            excludeEmpty: false,
            alljobs: [],
            showHistory: false,
        }
    }

    executeIcon = () => {
        if (this.state.job.jobType && this.state.job.jobType.includes('wsAdes') && this.props.params.id == 0) {
            return null
        }
        return (<Other className='clickable' onClick={ this.onExecute } tooltip={ i18n.execute }
            icon='play_circle_outline'
        />)
    }

    updateJob = job => {
        this.setState({ job })
    }

    toggleEditMode = () => {
        this.setState({ isEditMode: !this.state.isEditMode })
    }

    onDuplicate = () => {
        this.props.push('/import/0')
        this.setState({ job: omit(this.state.job, ['name', 'id']), isEditMode: true })
    }

    createActions = () => {
        const { isEditMode, job = {} } = this.state
        const label = isEditMode ? 'cancel' : 'edit'
        const actions = {
            [label]: this.toggleEditMode,
            other: {
                other: this.executeIcon(),
            },
        }

        if (isEditMode) {
            actions.save = this.onSave
        } else {
            actions.duplicate = this.onDuplicate
        }

        if (parseInt(this.props.params.id) !== 0) {
            actions.delete = this.onDelete
            actions.export = this.onExport
        }
        if (parseInt(this.props.params.id) < 0) {
            delete actions.delete
        }
        const jobType = this.getJobFormat(job.jobType)
        if (jobType?.type === 'export') {
            actions.links = [{
                img: sandre,
                href: 'http://www.sandre.eaufrance.fr/PS5/',
                label: 'Sandre',
            }]
        }
        if (jobType?.value === 'tapWater') {
            actions.links = [{
                img: mtes,
                href: 'https://www.data.gouv.fr/fr/datasets/resultats-du-controle-sanitaire-de-leau-du-robinet/',
                label: 'Orobnat',
            }]
        }
        if (jobType?.value === 'importQuesu') {
            actions.links = [{
                img: mtes,
                href: 'https://www.sandre.eaufrance.fr/ftp/documents/fr/scn/quesu/3/sandre_scenario_quesu_3.pdf',
                label: 'sandre-quesu',
            }]
        }
        if (jobType?.value === 'exportQuesu') {
            actions.links = [{
                img: mtes,
                href: 'https://www.sandre.eaufrance.fr/ftp/documents/fr/scn/quesu/3/sandre_scenario_quesu_3.pdf',
                label: 'sandre-quesu',
            }]
        }
        if (this.props.url === '/import/0') {
            this.setActions(omit(actions, ['duplicate']))
        } else {
            this.setActions(actions)
        }
    }

    handleJobCompleted = () => {
        this.props.fetchFullJob(this.props.params.id, this.state.nbExecutions, false, this.state.nbFiles, this.state.showHistory)
    }

    componentDidMount() {
        $('ul.tabs').tabs()
        this.createActions()
        this.props.subscribe(JOB_COMPLETED, this.handleJobCompleted)
        JobAction.promiseJobs(true).then(jobs => {
            this.setState({ allJobs: jobs.map(j => new DtoJobLight(j)) })
        })
        if (!this.props.contributors.length) {
            AppStore.dispatch(ContributorAction.fetchContributors())
        }
    }

    componentWillUpdate(nextProps) {
        if (this.props.job && nextProps.job && this.props.job.id !== nextProps.job.id) {
            this.setState({ job: nextProps.job })
        }
        if (!isEqual(nextProps.jobExecutions, this.state.jobExecutions)) {
            this.setState({ jobExecutions: nextProps.jobExecutions })
        }
    }

    componentDidUpdate(prevProps = { params: 0 }, prevState) {
        if (
            !isEqual(prevState.jobExecutions, this.state.jobExecutions) ||
            prevProps.params.id !== this.props.params.id ||
            prevState.isEditMode !== this.state.isEditMode ||
            prevState.job.jobType !== this.state.job.jobType
        ) {
            this.createActions()
        }
        $('ul.tabs').tabs()
    }

    handleChangePath = event => {
        this.updateJob({
            ...this.state.job,
            parameters: {
                ...this.state.job.parameters,
                path: event.target.value,
            },
        })
    }

    onChangeJob = changes => this.updateJob({
        ...this.state.job,
        ...changes,
    })

    handleChangeFilters = filters => {
        this.updateJob({
            ...this.state.job,
            parameters: {
                ...this.state.job.parameters,
                filters,
            },
        })
    }

    handleChangeEmails = emails => {
        this.updateJob({
            ...this.state.job,
            parameters: {
                ...this.state.job.parameters,
                emails,
            },
        })
    }

    handleChangeEmailsAlert = emailsAlert => {
        this.updateJob({
            ...this.state.job,
            parameters: {
                ...this.state.job.parameters,
                emailsAlert,
            },
        })
    }

    handleChangeContactsIds = contactsIds => {
        this.updateJob({
            ...this.state.job,
            parameters: {
                ...this.state.job.parameters,
                contactsIds,
            },
        })
    }

    handleChangeDataTypes = dataTypes => {
        this.updateJob({
            ...this.state.job,
            parameters: {
                ...this.state.job.parameters,
                dataTypes,
            },
        })
    }

    handleChangeAlertTypes = alertTypes => {
        this.updateJob({
            ...this.state.job,
            parameters: {
                ...this.state.job.parameters,
                alertTypes,
            },
        })
    }

    handleChangeParameters = parameters => {
        this.updateJob({
            ...this.state.job,
            parameters: {
                ...this.state.job.parameters,
                parameters,
            },
        })
    }

    onChangePlanification = cron => {
        this.updateJob({
            ...this.state.job,
            cron,
        })
    }
    addBssCode = codes => {
        const parameters = {
            ...this.state.job.parameters,
            parameters: codes,
        }
        this.updateJob({
            ...this.state.job,
            parameters,
        })
    }
    addCode = codes => {
        this.updateJob({
            ...this.state.job,
            parameters: {
                ...this.state.job.parameters,
                parameters: codes,
            },
        })
    }
    addStationToAlert = stations => {
        this.updateJob({
            ...this.state.job,
            parameters: {
                ...this.state.job.parameters,
                parameters: stations,
            },
        })
    }
    handleChangeFile = files => this.updateJob({
        ...this.state.job,
        parameters: {
            ...this.state.job.parameters,
            parameters: files,
        },
    })


    saveJob = () => {
        if (this.state.job.jobType.includes('importEdilabo')
            && (this.state.job.parameters.parameters[0].indexOf('status') == -1 || this.state.job.parameters.parameters[0].indexOf('qualification') == -1)
        ) {
            this.props.toastError(i18n.pleaseFillInAllTheRequiredFields)
        } else {
            this.setState({ isEditMode: !this.state.isEditMode })
            if (this.state.job.id) {
                this.props.updateJob({ ...this.state.job })
            } else {
                this.props.addJob({ ...this.state.job })
            }
        }
    }


    onSave = () => {
        const { job } = this.state
        if (job.jobType) {
            switch (true) {
                case job.jobType.includes('wsAdes'):
                    if (!this.state.job.name) {
                        this.props.toastError(i18n.nameAndCodeRequired)
                    } else {
                        this.saveJob()
                    }
                    break
                case job.jobType.includes('paratronicLNSXML'):
                    const jobFilters = JSON.parse(job.parameters.filters[0])
                    const jobParameters = job.parameters.parameters
                    if (!jobFilters?.handledData?.length) {
                        this.props.toastError(i18n.noDataTypeDefined)
                    } else if (jobFilters?.handledData?.some(d => !(d.dataCategory && d.dataType && d.discriminate))) {
                        this.props.toastError(i18n.catTypeDiscrRequired)
                    } else if (!jobParameters?.length) {
                        this.props.toastError(i18n.noStationSelected)
                    } else if (jobParameters?.some(param => !JSON.parse(param)?.stationId)) {
                        this.props.toastError(i18n.stationUndefinedRequired)
                    } else {
                        this.saveJob()
                    }
                    break
                default:
                    this.saveJob()
                    break
            }
        } else {
            this.saveJob()
        }
    }

    onExecute = () => {
        if (this.state.job.id) {
            this.props.updateJob({ ...this.state.job }, this.state.nbExecutions).then(() => {
                this.props.executeJob(this.state.job.id, this.state.nbExecutions, this.setAutoReload)
            })
        } else {
            this.props.addJob({ ...this.state.job }).then((json = {}) => {
                if (json.id) {
                    this.props.executeJob(json.id, this.state.nbExecutions, this.setAutoReload)
                }
            })
        }
    }

    setAutoReload = () => {
        const newJob = { ...this.state.job, nbExecutions: this.props.job.nbExecutions }
        if (this.state.panel !== 'journal') {
            this.setState({ panel: 'journal', job: newJob })
        } else {
            this.setState({ job: newJob })
        }
    }

    onDelete = () => {
        this.props.deleteJob(this.state.job.id)
    }

    onExport = () => ({
        data: this.props.jobExecutions,
        exportType: 'xlsx',
        titleFile: `${i18n.job}_n${this.props.params.id}_${i18n.executions}`,
    })

    changeSelectedFormat = jobType => {
        this.updateJob({
            ...this.state.job,
            jobType,
            parameters: {
                ...this.state.job.parameters,
                filters: [],
            },
        })
    }

    getJobFormat = (type) => {
        return IMPORT_FORMATS().find(o => o.value === type)
    }

    getFormatComponent = () => {
        const { isEditMode, job } = this.state
        const jobType = this.getJobFormat(job.jobType)
        if (jobType) {
            switch (true) {
                case jobType.value === 'catchmentTrend':
                    return (
                        <CatchmentTrendJob
                            job={job}
                            onChangeParameters={this.handleChangeParameters}
                        />
                    )
                case jobType.value === 'postFacebook':
                    return (
                        <PostFacebookJob
                            job={job}
                            isEditMode={isEditMode}
                            onChangeFiles={this.handleChangeFile}
                        />
                    )
                case jobType.value === 'edilaboRai':
                    return (
                        <ExportEdilaboPanelRai
                            job={ job }
                            isEditMode={ isEditMode }
                            handleChangeParameters={this.handleChangeParameters}
                            onChangeFilters={ this.handleChangeFilters }
                        />
                    )
                case jobType.value === 'importRsde':
                    return (
                        <ImportRsdePanel
                            job={job}
                            onChangeJob={ this.onChangeJob }
                            isEditMode={isEditMode}
                            handleChangeParameters={this.handleChangeParameters}
                            onChangeDataTypes={this.handleChangeDataTypes}
                        />
                    )
                case jobType.value === 'hydroportailExport':
                    return (
                        <ExportHydroportailPanel
                            job={ job }
                            onChangeJob={ this.onChangeJob }
                            isEditMode={ isEditMode }
                        />
                    )
                case jobType.value === 'quesout':
                    return (
                        <ExportQesoutPanel
                            job={ job }
                            onChangeJob={ this.onChangeJob }
                            isEditMode={ isEditMode }
                        />
                    )
                case jobType.value === 'qesoutQualito':
                    return (
                        <ExportQesoutPanelQualite
                            job={ job }
                            onChangeJob={ this.onChangeJob }
                            isEditMode={ isEditMode }
                        />
                    )
                case jobType.value === 'quesoutAck':
                    const disabled = !isEditMode
                    const ackFilters = this.state.job.parameters.filters.length ? this.state.job.parameters.filters[0] : 'true'
                    return (
                        <div>
                            <Row>
                                <Input col={ 6 } title={ i18n.directory } value={ job.parameters.path } onChange={ v => this.onChangeJob({ parameters: { ...job.parameters, path: v } }) } disabled={ disabled } />
                                <Input col={ 6 } title={ i18n.filter } value={ job.parameters.pathType } onChange={ v => this.onChangeJob({ parameters: { ...job.parameters, pathType: v } }) } disabled={ disabled } />
                            </Row>
                            <Row>
                                <Checkbox col={ 4 } label={ 'Ne pas traiter les fichiers présents dans le journal' } disabled={ disabled } checked={ ackFilters === 'true' }
                                    onChange={ v => this.onChangeJob({ parameters: { ...job.parameters, filters: [v ? 'true' : 'false'] } }) }
                                />
                                <Checkbox col={ 4 } label={ 'Supprimer les fichiers ACK et export lorsque le ACK est positif' } disabled={ disabled } checked={ job.parameters.propertiesFile }
                                    onChange={ v => this.onChangeJob({ parameters: { ...job.parameters, propertiesFile: v } }) }
                                />
                                <div className='col s4' />
                            </Row>
                        </div>
                    )
                case jobType.value === 'edilaboExport':
                    return (
                        <EDILABOPanel
                            job={job}
                            onChangeJob={this.onChangeJob}
                            onChangeParameters={this.handleChangeParameters}
                            isEditMode={isEditMode}
                            addCode={ this.addCode }
                        />
                    )
                case jobType.value === 'exportQuesu':
                    return (
                        <ExportQuesu
                            job={ job }
                            addBssCode={ this.addBssCode }
                            isEditMode={ isEditMode }
                            onChangeDataTypes={ this.handleChangeDataTypes }
                            onChangeFilters={ this.handleChangeFilters }
                            onChangeJob={ this.onChangeJob }
                            onChangeEmails={ this.handleChangeEmails }
                        />
                    )
                case jobType.type === 'export':
                    return (
                        <ExportJob
                            job={ job }
                            addBssCode={ this.addBssCode }
                            isEditMode={ isEditMode }
                            onChangeDataTypes={ this.handleChangeDataTypes }
                            onChangeFilters={ this.handleChangeFilters }
                            onChangeEmails={ this.handleChangeEmails }
                        />
                    )
                case jobType.value === 'alertHydro':
                    return (
                        <AlertHydro
                            job={ job }
                            isEditMode={ isEditMode }
                            onChangeEmails={ this.handleChangeEmailsAlert }
                            onChangeContactsIds={ this.handleChangeContactsIds }
                            onChangeStation={ this.addStationToAlert }
                            onChangeAlertTypes={ this.handleChangeAlertTypes }
                        />
                    )
                case jobType.value === 'alertPluvio':
                    return (
                        <AlertPluvio
                            job={ job }
                            isEditMode={ isEditMode }
                            onChangeEmails={ this.handleChangeEmailsAlert }
                            onChangeContactsIds={ this.handleChangeContactsIds }
                            onChangeStation={ this.addStationToAlert }
                            onChangeAlertTypes={ this.handleChangeAlertTypes }
                            onChangeJob={ this.onChangeJob }
                        />
                    )
                case jobType.value === 'alertPiezo':
                    return (
                        <AlertPiezo
                            job={ job }
                            isEditMode={ isEditMode }
                            onChangeEmails={ this.handleChangeEmailsAlert }
                            onChangeContactsIds={ this.handleChangeContactsIds }
                            onChangeStation={ this.addStationToAlert }
                            onChangeAlertTypes={ this.handleChangeAlertTypes }
                        />
                    )
                case jobType.value === 'outlierQuality':
                    return (
                        <OutlierQualityJobPanel
                            job={job}
                            isEditMode={isEditMode}
                            onChangeParameter={this.handleChangeParameters}
                        />
                    )
                case jobType.value === 'wsAdesPiezo':
                    return (
                        <AdesPiezoWebServicePanel
                            job={ job }
                            addBssCode={ this.addBssCode }
                            onChangeJob={ this.onChangeJob }
                            isEditMode={ isEditMode }
                            onChangeDataTypes={ this.handleChangeDataTypes }
                            onChangeFilters={ this.handleChangeFilters }
                        />
                    )
                case jobType.value === 'importEtatEco':
                    return (
                        <EcologicalStatePanel
                            job={ job }
                            onChangeDataTypes={ this.handleChangeDataTypes }
                            onChangeFilters={ this.handleChangeFilters }
                            isEditMode={ isEditMode }
                            addCode={ this.addCode }
                        />
                    )
                case jobType.value === 'wsAdesQualito':
                    return (
                        <AdesQualitoWebServicePanel
                            job={ job }
                            addBssCode={ this.addBssCode }
                            onChangeJob={ this.onChangeJob }
                            isEditMode={ isEditMode }
                            onChangeDataTypes={ this.handleChangeDataTypes }
                            onChangeFilters={ this.handleChangeFilters }
                        />
                    )
                case jobType.value === 'ligura':
                    return (
                        <LiguraPanel
                            job={job}
                            onChangeDataTypes={this.handleChangeDataTypes}
                            isEditMode={isEditMode}
                            addCode={this.addCode}
                        />
                    )
                case jobType.value === 'synop':
                    return (
                        <SynopPanel
                            job={ job }
                            onChangeDataTypes={ this.handleChangeDataTypes }
                            isEditMode={ isEditMode }
                            addCode={this.addCode}
                        />
                    )
                case jobType.value === 'hydro':
                    return (
                        <HydroPanel
                            job={ job }
                            onChangeDataTypes={ this.handleChangeDataTypes }
                            isEditMode={ isEditMode }
                            addHydroCode={ this.addCode }
                            onChangeJob={ this.onChangeJob }
                        />
                    )
                case jobType.value === 'tidegauge':
                    return (
                        <TideGaugePanel
                            job={ job }
                            onChangeDataTypes={ this.handleChangeDataTypes }
                            onChangeJob={ this.onChangeJob }
                            isEditMode={ isEditMode }
                            addCode={ this.addCode }
                        />
                    )
                case jobType.value === 'CalculSeee':
                    return (
                        <CalculSeee
                            job={ job }
                            onChangeJob={ this.onChangeJob }
                            onChangeDataTypes={ this.handleChangeDataTypes }
                            onChangeFilters={ this.handleChangeFilters }
                            isEditMode={ isEditMode }
                            addCode={ this.addCode }
                        />
                    )
                case jobType.value === 'oceanogram':
                    return (
                        <TideGaugeOceanogramPanel
                            job={ job }
                            onChangeDataTypes={ this.handleChangeDataTypes }
                            onChangeJob={ this.onChangeJob }
                            isEditMode={ isEditMode }
                            addCode={ this.addCode }
                        />
                    )
                case jobType.value === 'surfaceQualitometer':
                    return (
                        <SurfaceQualitometerPanel
                            job={ job }
                            onChangeJob={ this.onChangeJob }
                            onChangeDataTypes={ this.handleChangeDataTypes }
                            onChangeFilters={ this.handleChangeFilters }
                            isEditMode={ isEditMode }
                            addCode={ this.addCode }
                        />
                    )
                case jobType.value === 'piezoIndicatorsCalculation':
                    return (
                        <PiezoIndicatorsPanel
                            job={ job }
                            onChangeJob={ this.onChangeJob }
                            isEditMode={ isEditMode }
                        />
                    )
                case jobType.value === 'contributorSynchro':
                    const paramContributor = job.parameters && job.parameters.parameters.length && JSON.parse(job.parameters.parameters[0]) || {}
                    return (
                        <div className='row no-margin valign-wrapper'>
                            <div className='col s4'>
                                <Checkbox
                                    checked={paramContributor.create}
                                    id='create'
                                    label={i18n.createContributor}
                                    onChange={v => this.handleChangeParameters([JSON.stringify({ ...paramContributor, create: v })])}
                                    {...!isEditMode}
                                />
                            </div>
                            <div className='col s4'>
                                <Checkbox
                                    checked={paramContributor.coord}
                                    id='coord' label={i18n.updateXandY}
                                    onChange={v => this.handleChangeParameters([JSON.stringify({ ...paramContributor, coord: v })])}
                                    {...!isEditMode}
                                />
                            </div>
                        </div>
                    )
                case jobType.value === 'cityCenters':
                    const filters = job.parameters && job.parameters.filters.length && JSON.parse(job.parameters.filters[0]) || {}
                    return (
                        <Row>
                            <Input col={4} title={i18n.departmentNumber} value={filters.department} onChange={v => this.handleChangeFilters([JSON.stringify({ department: v })])} {...!isEditMode }/>
                        </Row>
                    )
                case jobType.value === 'infoTerreBoreholesDepartment':
                    return (
                        <InfoTerre
                            job={ job }
                            onChangeDataTypes={ this.handleChangeDataTypes }
                            onChangeFilters={ this.handleChangeFilters }
                            isEditMode={ isEditMode }
                            onChangeJob={ this.onChangeJob }
                            onChangeEmails={ this.handleChangeEmails }
                        />
                    )
                case jobType.value === 'csvAll':
                    return (
                        <CSVGenericPanel
                            job={ job }
                            onChangeJob={ this.onChangeJob }
                            isEditMode={ isEditMode }
                        />
                    )

                case jobType.value === 'ArchiveJob':
                    return (
                        <ArchiveJobPanel
                            job={ job }
                            onChangeJob={ this.onChangeJob }
                            isEditMode={ isEditMode }
                        />
                    )
                case jobType.value === 'newslettersGeneration':
                    return null
                case jobType.value === 'seba':
                    return (
                        <SebaPanel
                            job={ job }
                            onChangeJob={ this.onChangeJob }
                            isEditMode={ isEditMode }
                        />
                    )
                case jobType.value === 'paratronicLNSXML':
                    return (
                        <ParatronicPanel
                            job={ job }
                            onChangeJob={ this.onChangeJob }
                            isEditMode={ isEditMode }
                        />
                    )
                case jobType.value === 'MISfiles':
                    return (
                        <MISImportPanel
                            job={ job }
                            onChangeJob={ this.onChangeJob }
                            isEditMode={ isEditMode }
                        />
                    )
                case jobType.value === 'modelCalculation':
                    return null
                case jobType.value === 'mailUploadToFtp':
                    return (
                        <MailImportPanel
                            job={ job }
                            onChangeJob={ this.onChangeJob }
                            isEditMode={ isEditMode }
                        />
                    )
                case jobType.value === 'distantFtp':
                    return (
                        <FTPJobPanel
                            job={ job }
                            onChangeJob={ this.onChangeJob }
                            isEditMode={ isEditMode }
                        />
                    )
                case jobType.value === 'scriptSSH':
                    return (
                        <ScriptSSH
                            job={ job }
                            onChangeJob={ this.onChangeJob }
                            isEditMode={ isEditMode }
                        />
                    )
                case jobType.value === 'veoliaPortalToFtp':
                    return (
                        <VeoliaPortalPanel
                            job={ job }
                            onChangeJob={ this.onChangeJob }
                            isEditMode={ isEditMode }
                        />
                    )
                case jobType.value === 'tapWater':
                    return (
                        <TapWaterPanel
                            job={ job }
                            onChangeJob={ this.onChangeJob }
                            handleChangeParameters={ this.handleChangeParameters }
                            onChangeDataTypes={this.handleChangeDataTypes}
                            isEditMode={ isEditMode }
                        />
                    )
                case jobType.value === 'hydrobio':
                    return (
                        <HubeauHydrobioPanel
                            job={ job }
                            onChangeJob={ this.onChangeJob }
                            onChangeDataTypes={ this.handleChangeDataTypes }
                            isEditMode={ isEditMode }
                        />
                    )
                case jobType.value === 'importOmnidia':
                    return (
                        <ImportOmnidiaPanel
                            job={ job }
                            onChangeJob={ this.onChangeJob }
                            onChangeDataTypes={ this.handleChangeDataTypes }
                            isEditMode={ isEditMode }
                        />
                    )
                case jobType.value === 'quadrige':
                    return (
                        <ImportQuadrigePanel
                            job={ job }
                            handleChangeParameters={this.handleChangeParameters}
                            onChangeDataTypes={this.handleChangeDataTypes}
                            onChangeJob={ this.onChangeJob }
                            isEditMode={ isEditMode }
                        />
                    )
                case jobType.value === 'HUBEAUpiezo':
                    return (
                        <HubeauPiezoPanel
                            job={ job }
                            onChangeJob={ this.onChangeJob }
                            isEditMode={ isEditMode }
                        />
                    )
                case jobType.value === 'HUBEAUquality':
                    return (
                        <HubeauQualityPanel
                            job={ job }
                            onChangeJob={ this.onChangeJob }
                            onChangeDataTypes={this.handleChangeDataTypes}
                            isEditMode={ isEditMode }
                        />
                    )
                case jobType.value === 'era5':
                    return (
                        <ERA5Panel
                            job={ job }
                            onChangeJob={ this.onChangeJob }
                            isEditMode={ isEditMode }
                        />
                    )
                case jobType.value === 'mfObs':
                    return (
                        <MeteoFrancePanel
                            job={ job }
                            onChangeJob={ this.onChangeJob }
                            isEditMode={ isEditMode }
                        />
                    )
                case jobType.value === 'mfRadar':
                    return (
                        <MeteoFranceRadarPanel
                            job={ job }
                            onChangeJob={ this.onChangeJob }
                            isEditMode={ isEditMode }
                        />
                    )
                case jobType.value === 'sieauInstance':
                    return (
                        <SieauInstancePanel
                            job={ job }
                            onChangeJob={ this.onChangeJob }
                            isEditMode={ isEditMode }
                        />
                    )
                case jobType.value === 'selfControl':
                    return (
                        <SelfControlPanel
                            job={ job }
                            onChangeJob={ this.onChangeJob }
                            isEditMode={ isEditMode }
                        />
                    )
                case jobType.value === 'qualityPostControl':
                    return (
                        <QualityPostControl
                            job={job}
                            onChangeJob={this.onChangeJob}
                            isEditMode={isEditMode}
                        />
                    )
                case jobType.value === 'importQesoutPiezo':
                    const measureOptionsP = [ {
                        value: '',
                        label: i18n.noDataIntegration,
                    },
                    {
                        value: 'DATA_ADD_LAST_DATA',
                        label: i18n.lastDataIntegration,
                    },
                    {
                        value: 'DATA_REPLACE_HISTO',
                        label: i18n.fullDataIntegration,
                    },
                    {
                        value: 'DATA_REPLACE_DATA',
                        label: i18n.ReplaceDataIntegration,
                    },
                    ]
                    return (
                        <ImportQesoutPanel
                            job={ job }
                            onChangeJob={ this.onChangeJob }
                            isEditMode={ isEditMode }
                            type = { 'QUANTITY' }
                            handleChangeParameters={ this.handleChangeParameters }
                            onChangeDataTypes={ this.handleChangeDataTypes }
                            measureOptions = { measureOptionsP }
                        />
                    )
                case jobType.value === 'importQesoutQuality':
                    const measureOptionsQ = [
                        {
                            value: '',
                            label: i18n.noDataIntegration,
                        },
                        {
                            value: 'DATAIntegration',
                            label: i18n.lastDataIntegration,
                        },
                        {
                            value: 'DATAFullIntegration',
                            label: i18n.fullDataIntegration,
                        },
                        {
                            value: 'DATAReplaceIntegration',
                            label: i18n.ReplaceDataIntegration,
                        },
                    ]
                    return (
                        <ImportQesoutPanel
                            job={ job }
                            onChangeJob={ this.onChangeJob }
                            isEditMode={ isEditMode }
                            handleChangeParameters={ this.handleChangeParameters }
                            onChangeDataTypes={ this.handleChangeDataTypes }
                            type = { 'QUALITY' }
                            measureOptions = { measureOptionsQ }
                        />
                    )
                case jobType.value === 'importQuesu':
                    return (
                        <ImportQuesuPanel
                            job={job}
                            isEditMode={isEditMode}
                            onChangeJob={this.onChangeJob}
                            handleChangeParameters={this.handleChangeParameters}
                            onChangeDataTypes={this.handleChangeDataTypes}
                        />
                    )
                case jobType.value === 'lowWater':
                    return (
                        <ImportLowWaterPanel
                            job={job}
                            isEditMode={isEditMode}
                            handleChangeParameters={this.handleChangeParameters}
                            onChangeFilters={ this.handleChangeFilters }
                        />
                    )
                case jobType.value === 'calypso':
                    return (
                        <ImportCalypsoPanel
                            job={job}
                            isEditMode={isEditMode}
                            handleChangeParameters={this.handleChangeParameters}
                            onChangeDataTypes={this.handleChangeDataTypes}
                        />
                    )
                case jobType.value === 'importEdilabo':
                    return (
                        <ImportEdiLaboPanel
                            job={job}
                            isEditMode={isEditMode}
                            handleChangeParameters={this.handleChangeParameters}
                            onChangeDataTypes={this.handleChangeDataTypes}
                        />
                    )
                case jobType.value === 'importEdilaboDAI':
                    return (
                        <ImportEdiLaboDAIPanel
                            job={job}
                            isEditMode={isEditMode}
                            handleChangeParameters={this.handleChangeParameters}
                            onChangeDataTypes={this.handleChangeDataTypes}
                        />
                    )
                case jobType.value === 'ddassdistri':
                    return (
                        <ImportDdassDistriPanel
                            job={job}
                            isEditMode={isEditMode}
                            onChangeJob={ this.onChangeJob }
                            handleChangeParameters={this.handleChangeParameters}
                            onChangeDataTypes={this.handleChangeDataTypes}
                        />
                    )
                case jobType.value === 'importPiveau':
                    return (
                        <ImportPiveauPanel
                            job={job}
                            isEditMode={isEditMode}
                            handleChangeParameters={this.handleChangeParameters}
                        />
                    )
                case jobType.value === 'importMateriel':
                    return (
                        <TrameMateriel
                            job={job}
                            onChangeJob={this.onChangeJob}
                            handleChangeParameters={this.handleChangeParameters}
                            isEditMode={isEditMode}
                        />
                    )
                case jobType.value === 'materielCompta':
                    return (
                        <MaterielAccounting
                            job={job}
                            onChangeJob={this.onChangeJob}
                            handleChangeParameters={this.handleChangeParameters}
                            isEditMode={isEditMode}
                        />
                    )
                case jobType.value === 'jobPurgeMateriel':
                    return (
                        <PurgeMateriel
                            job={job}
                            onChangeJob={this.onChangeJob}
                            handleChangeParameters={this.handleChangeParameters}
                            isEditMode={isEditMode}
                        />
                    )
                case jobType.value === 'jobPurgeQuality':
                    return (
                        <PurgeQuality
                            job={job}
                            onChangeJob={this.onChangeJob}
                            handleChangeParameters={this.handleChangeParameters}
                            isEditMode={isEditMode}
                        />
                    )
                case jobType.value === 'Veolia':
                    return (
                        <VeoliaPanel
                            job={job}
                            onChangeJob={this.onChangeJob}
                            handleChangeParameters={this.handleChangeParameters}
                            isEditMode={isEditMode}
                        />
                    )
                case jobType.value === 'aquadbSelection':
                    return (
                        <AquaDBSelectionPanel
                            job={job}
                            onChangeJob={ this.onChangeJob }
                            onChangeFilters={ this.handleChangeFilters }
                            isEditMode={isEditMode}
                        />
                    )
                case jobType.value === 'importSiapLab':
                    return (
                        <ImportSiapLabPanel
                            job={job}
                            isEditMode={isEditMode}
                            onChangeJob={this.onChangeJob}
                            handleChangeParameters={this.handleChangeParameters}
                            onChangeDataTypes={this.handleChangeDataTypes}
                        />
                    )
                case jobType.value === 'veoliaVolume':
                    return (
                        <VeoliaVolumePanel
                            job={job}
                            onChangeJob={this.onChangeJob}
                            handleChangeParameters={this.handleChangeParameters}
                            isEditMode={isEditMode}
                        />
                    )
                case jobType.value === 'saurVolume':
                    return (
                        <SaurVolumePanel
                            job={job}
                            onChangeJob={this.onChangeJob}
                            handleChangeParameters={this.handleChangeParameters}
                            isEditMode={isEditMode}
                        />
                    )
                case jobType.value === 'activity':
                    return (
                        <ActivityPanel
                            job={ job }
                            onChangeJob={ this.onChangeJob }
                            isEditMode={ isEditMode }
                        />
                    )
                case jobType.value === 'establishment':
                    return (
                        <EstablishmentPanel
                            job={ job }
                            onChangeJob={ this.onChangeJob }
                            isEditMode={ isEditMode }
                        />
                    )
                case jobType.value === 'weatherMeasures':
                    return (
                        <WeatherMeasuresPanel
                            job={ job }
                            onChangeJob={ this.onChangeJob }
                            isEditMode={ isEditMode }
                        />
                    )
                case jobType.value === 'bathingWater':
                    return (
                        <BathingWaterPanel
                            job={ job }
                            addCode={ this.addCode }
                            onChangeJob={ this.onChangeJob }
                            onChangeDataTypes={this.handleChangeDataTypes}
                            isEditMode={ isEditMode }
                        />
                    )

                case jobType.value === 'alerteClimatic':
                    return (
                        <AlertClimatic
                            job={ job }
                            addCode={ this.addCode }
                            onChangeJob={ this.onChangeJob }
                            onChangeDataTypes={this.handleChangeDataTypes}
                            isEditMode={ isEditMode }
                        />
                    )
                case jobType.type === 'import':
                    return (
                        <ImportFormatPanel
                            job={ job }
                            isEditMode={ isEditMode }
                            onChangeDataTypes={ this.handleChangeDataTypes }
                            onChangeFilters={ this.handleChangeFilters }
                        />
                    )
                default:
                    return <AquasysPivEauPanel job={ job } isEditMode={ isEditMode }/>
            }
        }
        return <AquasysPivEauPanel job={ this.state.job } isEditMode={ isEditMode }/>
    }

    getKeyfigures = () => {
        const lastExecution = head(this.state.jobExecutions.sort((a, b) => b.date - a.date))
        const res = [
            new DtoKeyFigure({
                title: i18n.executions,
                value: this.props.job.nbExecutions,
            }),
        ]
        if (lastExecution) {
            return [
                ...res,
                new DtoKeyFigure({
                    title: i18n.lastStatus,
                    value: getExecStatusIcon(lastExecution, 50),
                }),
                new DtoKeyFigure({
                    title: i18n.duration,
                    value: shortenHumanize(lastExecution.duration * 1000),
                }),
                new DtoKeyFigure({
                    title: i18n.lastExecution,
                    value: moment(lastExecution.date).format('DD/MM/YYYY hh:mm'),
                }),
            ]
        }
        return res
    }

    setNbFiles = (callback = () => {}) => {
        this.setState(
            { nbFiles: this.state.nbFiles + incrementFiles },
            () => this.props.fetchFullJob(this.props.params.id, this.state.nbExecutions, false, this.state.nbFiles, this.state.showHistory).then(() => callback()),
        )
    }

    getPanel = () => {
        const { showHistory } = this.state
        switch (this.state.panel) {
            case 'definition':
                const disabled = { disabled: !this.state.isEditMode, isEditMode: this.state.isEditMode }
                const disabledWithUpdate = this.state.job.id ? { disabled: true } : {}
                const jobType = this.getJobFormat(this.state.job.jobType)
                return (
                    <div id='definition' className='col s12 no-padding'>
                        <div className='card no-margin'>
                            <div className='card-content'>
                                <StyledFieldSet className='padding-1'>
                                    <StyledLegend>{i18n.description}</StyledLegend>
                                    <div className='row no-margin valign-wrapper'>
                                        <Select
                                            value={ this.state.job.jobType || null }
                                            options={ IMPORT_FORMATS() }
                                            group
                                            label={ i18n.format }
                                            col={ 5 }
                                            obligatory
                                            onChange={ this.changeSelectedFormat }
                                            data-cy='jobFormat'
                                            { ...{ ...disabled, ...disabledWithUpdate } }
                                        />
                                        <div className='col s8'>
                                            {jobType?.description || ''}
                                        </div>
                                    </div>
                                    <div className='row no-margin valign-wrapper'>
                                        <Input col={ 5 } title={ i18n.name } data-cy='jobName' value={ this.state.job.name } onChange={ v => this.updateJob({ ...this.state.job, name: v }) } { ...disabled }/>
                                        <Input col={ 8 } title={ i18n.comment } data-cy='jobComment' value={ this.state.job.description } onChange={ v => this.updateJob({ ...this.state.job, description: v }) } { ...disabled }/>
                                    </div>
                                    <div className='row no-margin valign-wrapper'>
                                        <MultiContributorsAutocomplete
                                            label={i18n.administrator}
                                            disablePortal={false}
                                            limit={3}
                                            options={this.props.contributors}
                                            values={hasValue(this.state.job.managers) ? this.state.job.managers.split(';').map(s => parseInt(s)) : []}
                                            onChange={codes => this.updateJob({ ...this.state.job, managers: codes?.join(';') ? codes.join(';') : undefined })}
                                            multiple={true}
                                            disabled={!getUser().isAdmin === '1' || disabled.disabled}
                                            col={5}
                                            data-cy='jobAdmin'
                                        />
                                        <div className='col s8'>
                                            { this.state.job.login && this.state.job.updateDate && <Row><div className='col s12'>{ `${i18n.updatedOn + getDate(this.state.job.updateDate)} ${i18n.by} ${this.state.job.login}` }</div></Row> || '' }
                                        </div>
                                    </div>
                                </StyledFieldSet>
                                <StyledFieldSet className='padding-1'>
                                    <StyledLegend>{i18n.parameters}</StyledLegend>
                                    { this.getFormatComponent() }
                                </StyledFieldSet>
                            </div>
                        </div>
                    </div>
                )
            case 'programmation':
                return (
                    <div id='programmation' className='col s12 no-padding'>
                        <SchedulePanel
                            job={ this.state.job }
                            onChangePlanification={ this.onChangePlanification }
                            onChangeEmails={ this.handleChangeEmails }
                            onChangeContactsIds={ this.handleChangeContactsIds }
                            isEditMode={ this.state.isEditMode }
                            jobs={ this.state.allJobs }
                        />
                    </div>
                )
            case 'journal':
                return (
                    <div id='journal' className='col s12 no-padding'>
                        <ImportLogs
                            jobExecutions={ this.state.jobExecutions }
                            job={ this.state.job }
                            loadMore={ () => {
                                this.props.fetchFullJob(this.props.params.id, this.state.nbExecutions + 10, this.state.excludeEmpty, this.state.nbFiles, showHistory)
                                this.setState({ nbExecutions: this.state.nbExecutions + 10 })
                            } }
                            onExcludeEmpty={ () => {
                                this.setState({ excludeEmpty: !this.state.excludeEmpty }, () => {
                                    this.props.fetchFullJob(this.props.params.id, this.state.nbExecutions, this.state.excludeEmpty, this.state.nbFiles, showHistory)
                                })
                            } }
                            excludeEmpty={ this.state.excludeEmpty }
                            location={ this.props.location }
                            reloadExecutions={ () => this.props.fetchFullJob(this.props.params.id, this.state.nbExecutions, false, this.state.nbFiles, showHistory) }
                        />
                    </div>
                )
            case 'files':
                return (
                    <div id='journal' className='col s12 no-padding'>
                        <ImportFiles
                            jobFiles={ this.props.jobFiles }
                            nbJobFiles={ this.props.job.nbFiles }
                            setNbFiles={ this.setNbFiles }
                            downloadFile={ this.props.downloadFile }
                            jobPath={ this.props.job.parameters.path }
                            jobName={ this.state.job.name || '' }
                            showHistory={ showHistory }
                            onChangeHistory={(callback) => {
                                this.setState({ showHistory: !showHistory },
                                    () => this.props.fetchFullJob(this.props.params.id, this.state.nbExecutions, this.state.excludeEmpty, this.state.nbFiles, !showHistory).then(() => {
                                        callback()
                                    }))
                            }}
                        />
                    </div>
                )
            default:
                return null
        }
    }

    getActive = (panel) => {
        return panel === this.state.panel ? 'active' : ''
    }

    render() {
        return (
            <div className='row no-margin'>
                <div className='col s12 no-padding'>
                    <div className='row no-margin'>
                        <div className='col s12 no-padding'>
                            <ul className='tabs tabs-fixed-width'>
                                <li className='tab'>
                                    <a id='link_to_definition' className={ this.getActive('definition') } onClick={ () => this.setState({ panel: 'definition' }) }>{ i18n.definition }</a>
                                </li>
                                <li className='tab'>
                                    <a id='link_to_programmation' className={ this.getActive('programmation') } onClick={ () => this.setState({ panel: 'programmation' }) }>{ i18n.programmation }</a>
                                </li>
                                <li className={ `tab ${this.props.params.id !== '0' ? '' : 'disabled'}` }>
                                    <a id='link_to_journal'className={ this.getActive('journal') } onClick={ () => this.setState({ panel: 'journal' }) }>{ `${i18n.journal} (${this.props.job.nbExecutions || 0} ${i18n.executions})` }</a>
                                </li>
                                <li className={ `tab ${this.props.params.id !== '0' ? '' : 'disabled'}` }>
                                    <a id='link_to_file' className={ this.getActive('files') } onClick={ () => this.setState({ panel: 'files' }) }>{ `${i18n.files} (${this.props.jobFiles.length})` }</a>
                                </li>
                            </ul>
                        </div>
                        { this.getPanel() }
                    </div>
                </div>
            </div>
        )
    }

    componentWillUnmount() {
        this.props.unsubscribe(JOB_COMPLETED, this.handleJobCompleted)
        AppStore.dispatch(HomeAction.setActions([]))
    }
}

ImportCard.propTypes = {
    jobExecutions: PropTypes.arrayOf(PropTypes.instanceOf(DtoJobExecution)),
    params: PropTypes.shape({
        id: PropTypes.string,
    }),
    fetchFullJob: PropTypes.func,
    job: PropTypes.objectOf(Job).isRequired,
    jobFiles: arrayOf(DtoJobFile),
    downloadFile: PropTypes.func,
    contributors: arrayOf(ContributorDto),
}

const mapStateToProps = store => ({
    job: store.JobReducer.job,
    jobFiles: store.JobReducer.jobFiles,
    jobExecutions: store.JobReducer.jobExecutions,
    contributors: store.ContributorReducer.contributors,
})

const mapDispatchToProps = {
    toastError: ToastrAction.error,
    addJob: JobAction.addJob,
    updateJob: JobAction.updateJob,
    deleteJob: JobAction.deleteJob,
    executeJob: JobAction.executeJob,
    fetchJobExecutions: JobAction.fetchJobExecutions,
    fetchJobsLogs: JobAction.fetchJobsLogs,
    subscribe: SSEActions.subscribe,
    unsubscribe: SSEActions.unsubscribe,
    fetchFullJob: JobAction.fetchFullJob,
    downloadFile: JobAction.downloadFile,
    push,
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ImportCard)
