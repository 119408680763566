import ActionComponent from 'components/ActionComponent'
import { push } from '@lagunovsky/redux-react-router'
import { isEqual, omit } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import ToastrAction from 'toastr/actions/ToastrAction'
import UserDto from '../../../../administration/components/user/dto/UserDto'
import SieauParameterDto from '../../../../administration/dto/SieauParameterDto'
import Card from '../../../../components/card/Card'
import Input from '../../../../components/forms/Input'
import NumberField from '../../../../components/forms/NumberField'
import Select from '../../../../components/forms/Select'
import Row from '../../../../components/react/Row'
import SieauAction from '../../../../components/sieau/SieauAction'
import HomeAction from '../../../../home/actions/HomeAction'
import {
    PATH_REFERENCIAL,
    PATH_REFERENCIAL_COUNTRY,
    PATH_REFERENCIAL_COUNTRY_NEW,
} from '../../../../home/constants/RouteConstants'
import { updateMaterialize } from '../../../../utils/MaterializeUtils'
import { getUser } from '../../../../utils/SettingUtils'
import { arrayOf, getSandreList } from '../../../../utils/StoreUtils'
import ReferencialAction from '../../../action/ReferencialAction'
import { SANDRE } from '../../../constants/ReferencialConstants'
import DtoSandreCode from '../../../dto/DtoSandreCode'
import DtoCountry from '../../city/dto/DtoCountry'
import CountryAction from '../actions/CountryAction'

class CountryApp extends ActionComponent {
    state = {
        country: new DtoCountry({}),
        isEditMode: false,
        countrySandreCodes: getSandreList(this.props.sandreCodes, SANDRE.PAYS_RGPD),
    }

    changeEditMode = (bool) => {
        this.setState({ isEditMode: bool })
    }

    setCountryActions() {
        const {
            country,
            countries,
        } = this.props
        const { params } = this.props
        const actions = (() => {
            if (params.code === 'new') {
                return {
                    save: () => {
                        const existCode = countries.find(c => c.countryCode === this.state.country.countryCode)
                        if (existCode) {
                            this.props.toastrError(i18n.codeAlreadyExists)
                        } else if (!this.state.country.countryCode) {
                            this.props.toastrError(i18n.thanksToEnterCode)
                        } else {
                            this.props.createCountry(this.state.country)
                            this.changeEditMode(false)
                        }
                    },
                    cancel: () => {
                        this.props.push(PATH_REFERENCIAL_COUNTRY)
                        this.changeEditMode(false)
                    },
                }
            }
            if (this.state.isEditMode) {
                return {
                    save: () => {
                        this.props.updateCountry(this.state.country)
                        this.changeEditMode(false)
                    },
                    cancel: () => {
                        this.setState({ country })
                        this.changeEditMode(false)
                    },
                }
            }
            return {
                edit: () => {
                    this.changeEditMode(true)
                },
                delete: () => this.props.deleteCountry(this.state.country.countryCode),
            }
        })()
        if (getUser().consultant === '1') {
            this.setActions(omit(actions, ['save', 'edit', 'deleteCheck', 'replace']))
        } else {
            this.setActions(actions)
        }
        updateMaterialize()
    }

    componentDidMount() {
        if (this.props.sandreCodes.length === 0) {
            this.props.fetchSandreCodes().then(() => this.setState({ countrySandreCodes: getSandreList(this.props.sandreCodes, SANDRE.PAYS_RGPD) }))
        }
        if (this.props.params.code !== 'new') {
            this.props.fetchCountry(this.props.params.code).then(() => this.setState({ country: this.props.country }))
            this.changeEditMode(false)
        } else {
            this.changeEditMode(true)
        }
        this.setCountryActions(this.props)
        if (this.props.params.code === 'new') {
            this.props.setTitle([
                {
                    title: i18n.referencials,
                    href: PATH_REFERENCIAL,
                },
                {
                    title: i18n.countries,
                    href: PATH_REFERENCIAL_COUNTRY,
                },
                {
                    title: i18n.new,
                    href: PATH_REFERENCIAL_COUNTRY_NEW,
                },
            ])
        } else {
            this.props.setTitle([
                {
                    title: i18n.referencials,
                    href: PATH_REFERENCIAL,
                },
                {
                    title: i18n.countries,
                    href: PATH_REFERENCIAL_COUNTRY,
                },
                {
                    title: this.props.params.code,
                    href: PATH_REFERENCIAL_COUNTRY + this.props.params.code,
                },
            ])
        }
    }

    componentDidUpdate(prevProps) {
        this.setCountryActions()
        if (this.props.params?.code === 'new') {
            this.props.setTitle([
                {
                    title: i18n.referencials,
                    href: PATH_REFERENCIAL,
                },
                {
                    title: i18n.countries,
                    href: PATH_REFERENCIAL_COUNTRY,
                },
                {
                    title: i18n.new,
                    href: PATH_REFERENCIAL_COUNTRY_NEW,
                },
            ])
        } else if (!isEqual(this.props.params?.code, prevProps.params?.code)) {
            this.props.fetchCountry(this.state.country.countryCode)
            this.props.setTitle([
                {
                    title: i18n.referencials,
                    href: PATH_REFERENCIAL,
                },
                {
                    title: i18n.countries,
                    href: PATH_REFERENCIAL_COUNTRY,
                },
                {
                    title: this.state.country.countryCode,
                    href: PATH_REFERENCIAL_COUNTRY + this.state.country.countryCode,
                },
            ])
        }
    }

    onChangeCountry = (value) => {
        this.setState({
            country: {
                ...this.state.country,
                ...value,
            },
        })
    }

    render() {
        const disabled = { active: this.state.isEditMode, disabled: !this.state.isEditMode }
        const { country, countrySandreCodes } = this.state

        return (
            <div className='row no-margin'>
                <div id='file' className='col s12'>
                    <Card className='margin-top-1 padding-bottom-1' noMargin={false}>
                        <Row>
                            <Row className='col s12'>
                                <Card title={i18n.description} className='padding-bottom-1 padding-top-1'>
                                    <Row className='padding-top-1'>
                                        <Input
                                            col={ 4 }
                                            title={ i18n.code }
                                            value={ country.countryCode }
                                            onChange={ v => this.onChangeCountry({ countryCode: v }) }
                                            maxLength={ 2 }
                                            { ...disabled }
                                        />
                                        <Input
                                            col={ 4 }
                                            id='country_name'
                                            title={ i18n.name }
                                            value={ country.name }
                                            onChange={ v => this.onChangeCountry({ name: v }) }
                                            maxLength={ 50 }
                                            { ...disabled }
                                        />
                                        <Input
                                            col={ 4 }
                                            title={ i18n.internationalName }
                                            value={ country.nameInter }
                                            onChange={ v => this.onChangeCountry({ nameInter: v }) }
                                            maxLength={ 50 }
                                            { ...disabled }
                                        />
                                    </Row>
                                    <Row className='padding-top-1'>
                                        <NumberField
                                            col={ 3 }
                                            title={ i18n.number }
                                            value={ country.countryNum }
                                            onChange={ v => this.onChangeCountry({ countryNum: v }) }
                                            maxLength={ 255 }
                                            { ...disabled }
                                        />
                                        <Input
                                            col={ 3 }
                                            title={ i18n.tel }
                                            value={ country.telCodeIndicator }
                                            onChange={ v => this.onChangeCountry({ telCodeIndicator: v }) }
                                            maxLength={ 255 }
                                            { ...disabled }
                                        />
                                        <Input
                                            col={ 3 }
                                            title={ i18n.indicator}
                                            value={ country.countryIndicator }
                                            onChange={ v => this.onChangeCountry({ countryIndicator: v }) }
                                            maxLength={ 255 }
                                            { ...disabled }
                                        />
                                        <Select
                                            col={3}
                                            label={i18n.level}
                                            options={countrySandreCodes}
                                            value={country.rgpdLevel}
                                            onChange={(v) => this.onChangeCountry({ rgpdLevel: v })}
                                            keyLabel='name'
                                            keyValue='code'
                                            { ...disabled }
                                        />
                                    </Row>
                                </Card>
                            </Row>
                        </Row>
                    </Card>
                </div>
            </div>
        )
    }
}

CountryApp.propTypes = {
    params: PropTypes.shape({
        code: PropTypes.string,
    }),
    isEditMode: PropTypes.bool,
    sandreCodes: PropTypes.arrayOf(PropTypes.instanceOf(DtoSandreCode)),
    users: arrayOf(UserDto),
    applicationSettings: PropTypes.arrayOf(PropTypes.instanceOf(SieauParameterDto)),
    countries: PropTypes.arrayOf(PropTypes.instanceOf(DtoCountry)),
    country: PropTypes.instanceOf(DtoCountry),
    fetchCountries: PropTypes.func,
    fetchCountry: PropTypes.func,
    setTitle: PropTypes.func,
    toastrError: PropTypes.func,
    createCountry: PropTypes.func,
    updateCountry: PropTypes.func,
    push: PropTypes.func,
    deleteCountry: PropTypes.func,
    setPopup: PropTypes.func,
    fetchSandreCodes: PropTypes.func,
}

const mapStateToProps = store => ({
    users: store.UserReducer.users,
    applicationSettings: store.AdministrationReducer.applicationSettings,
    countries: store.CountryReducer.countries,
    country: store.CountryReducer.country,
    sandreCodes: store.ReferencialReducer.sandreCodes,
})

const mapDispatchToProps = {
    push,
    fetchCountries: CountryAction.fetchCountries,
    fetchCountry: CountryAction.fetchCountry,
    setTitle: HomeAction.setTitle,
    toastrError: ToastrAction.error,
    createCountry: CountryAction.createCountry,
    updateCountry: CountryAction.updateCountry,
    deleteCountry: CountryAction.deleteCountry,
    setPopup: SieauAction.setPopup,
    fetchSandreCodes: ReferencialAction.fetchSandreCodes,
}

export default connect(mapStateToProps, mapDispatchToProps)(CountryApp)
