import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import ReferencialAppList from '../../../../referencial/components/ReferencialAppList'
import SieauAction from '../../../../components/sieau/SieauAction'
import { getLabel } from '../../../../utils/StoreUtils'
import { push } from '@lagunovsky/redux-react-router'
import { LIST_PANEL, MAP_PANEL } from '../../../constants/MaterielConstants'
import SubscriptionAction from '../actions/SubscriptionAction'
import { isUndefined, orderBy } from 'lodash'
import MaterielsMapComponent from '../../map/MaterielsMapComponent'
import { PATH_MATERIEL_SUBSCRIPTION } from '../../../../home/constants/RouteConstants'
import { createIconMaterialAssignment, createIconMaterialState } from '../../../../utils/MaterielUtils'
import SimSubscriptionFilterForm from '../../filterPanel/SimSubscriptionFilterForm'
import CityDto from '../../../../referencial/components/city/dto/CityDto'
import DtoMaterielState from '../../../dto/DtoMaterielState'
import DtoHydrometricStation from '../../../../hydrometry/dto/DtoHydrometricStation'
import SubscriptionSituationDto from '../dto/SubscriptionSituationDto'
import ContributorDto from '../../../../referencial/components/contributor/dto/ContributorDto'
import DtoPiezometerLight from '../../../../piezometry/dto/DtoPiezometerLight'
import SubscriptionDto from '../dto/SubscriptionDto'
import { getDate } from '../../../../utils/DateUtil'
import SubscriptionTypeDto from '../dto/SubscriptionTypeDto'
import DtoQualitometerLight from '../../../../quality/dto/DtoQualitometerLight'
import PluviometerDto from '../../../../pluviometry/dto/PluviometerDto'
import DtoInstallation from '../../../../installation/dto/installation/DtoInstallation'
import queryString from 'query-string'
import AppStore from 'store/AppStore'
import HomeAction from 'home/actions/HomeAction'
import { componentHasHabilitations } from '../../../../utils/HabilitationUtil'
import { H_MAT_SUBSCRIPTION } from '../../../../account/constants/AccessRulesConstants'
import { searchAllCharacters } from '../../../../utils/StringUtil'
import { hasValue } from 'utils/NumberUtil'

const HEADERS = ['type', 'provider', 'lineNumber', 'openDate', 'state', 'stateDate', 'assignment', 'administrator']

class SubscriptionsApp extends Component {
    constructor(props) {
        super(props)
        const { idType } = queryString.parse(this.props.location.search)
        this.state = {
            view: LIST_PANEL,
            filter: {
                matType: isUndefined(idType) ? undefined : parseInt(idType),
            },
        }
    }

    componentDidMount() {
        if (!componentHasHabilitations(H_MAT_SUBSCRIPTION)) { // A modifier quand react-router sera à jour
            this.props.push('/unauthorized')
            return
        }
        AppStore.dispatch(HomeAction.setHelpLink('materiel', ''))
        if (!this.props.subscriptions.length) {
            this.props.fetchSubscriptions()
        }
        if (!this.props.subscriptionTypes.length) {
            this.props.fetchSubscriptionTypes()
        }
    }

    getHash = mat => searchAllCharacters(HEADERS.map(key => mat[key]).join(''))

    getFilterMateriels = materiels => {
        const {
            network,
            state,
            administrator,
            provider,
            matType,
            searchValue,
            displayOutOfService,
        } = this.state.filter
        const filterAdministrator = administrator ? materiels.filter(({ administratorId }) => administrator === administratorId) : materiels
        const filterProvider = provider ? filterAdministrator.filter(({ providerId }) => provider === providerId) : filterAdministrator
        const filterStatusCode = hasValue(state) ? filterProvider.filter(({ statusCode }) => statusCode === state) : filterProvider
        const filterType = matType ? filterStatusCode.filter(({ materielType }) => materielType === matType) : filterStatusCode
        const filterNetwork = network ? filterType.filter(({ networkCode }) => network === networkCode) : filterType
        const filterOutOfService = displayOutOfService ? filterNetwork : filterNetwork.filter(({ statusCode }) => statusCode !== 0 && statusCode !== 5 && statusCode !== 6)
        const searchValueFormated = searchAllCharacters(searchValue)
        return searchValue ? filterOutOfService.filter(mat => this.getHash(mat).includes(searchValueFormated)) : filterOutOfService
    }

    getExportData = (materiels) => {
        if (!materiels.length) {
            return []
        }
        const [head, ...tail] = materiels
        return [{ ...head, headers: HEADERS }, ...tail]
    }

    getPanel = subscriptions => {
        if (this.state.view === MAP_PANEL) {
            return (
                <MaterielsMapComponent
                    materiels={subscriptions}
                    materielLink={PATH_MATERIEL_SUBSCRIPTION}
                />
            )
        }
        const formattedSubscriptions = subscriptions.map(mat => ({
            ...mat,
            state: createIconMaterialState(mat.state, mat.statusCode),
            assignment: mat.statusCode === 1 && createIconMaterialAssignment(mat.assignment, mat.siteType) || '',
        }))

        return (
            <ReferencialAppList
                title={i18n.subscriptions}
                data={formattedSubscriptions}
                exportData={this.getExportData(subscriptions)}
                type={{ headers: HEADERS }}
                newAction={() => this.props.push('/materiel/subscription/new')}
                showNewButton={true}
                showPurgeButton={false}
                setTitleAction={SieauAction.forceFetch('title', [{
                    title: i18n.materiel,
                    href: 'materiel',
                }, {
                    title: i18n.subscriptions,
                    href: 'materiel/subscription',
                }])}
                onClick={({ materielId }) => this.props.push(`/materiel/subscription/${materielId}`)}
                lastUpdate={null}
                searchable={false}
            />
        )
    }

    getCorrespondingSite = ({ siteType, siteCode }) => {
        switch (siteType) {
            case 1:
                return this.props.piezometers.find(({ id }) => id === siteCode)
            case 2:
                return this.props.pluviometers.find(({ id }) => id === siteCode)
            case 3:
                return this.props.qualitometers.find(({ id }) => id === siteCode)
            case 4:
                return this.props.hydrometricStations.find(({ id }) => id === siteCode)
            case 7:
                return this.props.installations.find(({ id }) => id === siteCode)
            default:
                return {}
        }
    }

    getSubscriptionTypes = () => this.props.subscriptionTypes.filter(({ id, name }) => !!this.props.subscriptions.find(({ subscriptionType }) => subscriptionType === id) && name).map(({ id, name = '' }) => ({
        id,
        label: name,
    }))

    onChangeView = view => this.setState({ view })

    onValidate = filter => {
        this.setState({ filter })
    }

    render() {
        const materiels = this.props.subscriptions.map(mat => {
            const lastSituation = this.props.subscriptionsLastSituations.find(s => s.idSubscription === mat.id)
            const labelState = lastSituation ? getLabel(this.props.materielStates, lastSituation.statusCode) : ''
            const site = lastSituation && lastSituation.statusCode === 1 && lastSituation.siteType && lastSituation.siteCode && this.getCorrespondingSite(lastSituation) || {}
            const labelAssignment = (lastSituation && lastSituation.statusCode === 1 && lastSituation.siteName) || site.code || ''
            const city = site.townCode && this.props.cities.find((o) => o.code === site.townCode) || undefined
            const cityLabel = city ? `${city.name} - [${city.code}]` : (site.townCode || '')
            const departmentLabel = city && city.departmentNumber ? city.departmentNumber : ''
            return {
                materielId: mat.id,
                type: getLabel(this.props.subscriptionTypes, mat.materielType),
                provider: getLabel(this.props.contributors, mat.providerId, 'labelDisplay', 'id'),
                state: labelState,
                statusCode: lastSituation && lastSituation.statusCode,
                stateDate: lastSituation && getDate(lastSituation.situationDate),
                openDate: getDate(mat.openingDate),
                city: cityLabel,
                administratorId: mat.administrator,
                lineNumber: mat.numLine,
                assignment: labelAssignment,
                siteType: lastSituation && lastSituation.siteType,
                departmentShort: departmentLabel,
                providerId: mat.providerId,
                networkCode: mat.networkCode,
                materielType: mat.materielType,
                siteCode: lastSituation && lastSituation.siteCode,
                administrator: getLabel(this.props.contributors, mat.administrator, 'labelDisplay', 'code'),
            }
        })
        const filteredMateriels = this.getFilterMateriels(materiels)
        return (
            <div className='col no-padding s12'>
                <div className='row no-margin'>
                    <div className='col s12'>
                        <div className='row no-margin-bottom'>
                            <SimSubscriptionFilterForm
                                view={this.state.view}
                                filter={this.state.filter}
                                changeView={this.onChangeView}
                                materiels={materiels}
                                typeList={orderBy(this.getSubscriptionTypes(), 'label')}
                                onValidate={this.onValidate}
                                showEquipmentCheck={false}
                                materielType='SUBSCRIPTION'
                            />
                        </div>
                    </div>
                    {this.getPanel(filteredMateriels)}
                </div>
            </div>
        )
    }
}

SubscriptionsApp.propTypes = {
    location: PropTypes.shape({
        pathname: PropTypes.string,
        search: PropTypes.object,
    }),
    allowModifications: PropTypes.bool,
    getLink: PropTypes.func,
    subscriptions: PropTypes.arrayOf(PropTypes.instanceOf(SubscriptionDto)),
    subscriptionTypes: PropTypes.arrayOf(PropTypes.instanceOf(SubscriptionTypeDto)),
    subscriptionsLastSituations: PropTypes.arrayOf(PropTypes.instanceOf(SubscriptionSituationDto)),
    cities: PropTypes.arrayOf(PropTypes.instanceOf(CityDto)),
    materielStates: PropTypes.arrayOf(PropTypes.instanceOf(DtoMaterielState)),
    piezometers: PropTypes.arrayOf(PropTypes.instanceOf(DtoPiezometerLight)),
    pluviometers: PropTypes.arrayOf(PropTypes.instanceOf(PluviometerDto)),
    qualitometers: PropTypes.arrayOf(PropTypes.instanceOf(DtoQualitometerLight)),
    hydrometricStations: PropTypes.arrayOf(PropTypes.instanceOf(DtoHydrometricStation)),
    installations: PropTypes.arrayOf(PropTypes.instanceOf(DtoInstallation)),
    contributors: PropTypes.arrayOf(PropTypes.instanceOf(ContributorDto)),
    push: PropTypes.func,
    fetchSubscriptionTypes: PropTypes.func,
    fetchSubscriptions: PropTypes.func,
    fetchMaterielStates: PropTypes.func,
    fetchContributors: PropTypes.func,
    fetchCities: PropTypes.func,
}

const mapStateToProps = store => ({
    subscriptions: store.SubscriptionReducer.subscriptions,
    subscriptionTypes: store.SubscriptionReducer.subscriptionTypes,
    subscriptionsLastSituations: store.SubscriptionReducer.subscriptionsLastSituations,
    piezometers: store.PiezometryReducer.piezometersLight,
    pluviometers: store.PluviometryReducer.pluviometers,
    qualitometers: store.QualityReducer.qualitometersLight,
    hydrometricStations: store.HydrometryReducer.hydrometricStations,
    installations: store.InstallationReducer.installations,
    materielStates: store.MaterielReducer.materielStates,
    cities: store.CityReducer.cities,
    contributors: store.ContributorReducer.contributors,
})

const mapDispatchToProps = {
    push,
    fetchSubscriptionTypes: SubscriptionAction.fetchSubscriptionTypes,
    fetchSubscriptions: SubscriptionAction.fetchSubscriptions,
}

export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionsApp)
