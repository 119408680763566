import PropTypes from 'prop-types'
import Graph from '../../../components/echart/series/Graph'
import { Grid } from '@mui/material'
import Card from '../../../components/card/Card'
import EChart from '../../../components/echart/EChart'
import React from 'react'
import { arrayOf } from '../../../utils/StoreUtils'
import Job from '../../dto/DtoJob'
import { initial } from 'lodash'


const JobChainGraph = ({ chain, onClick, jobs, selectedJob }) => {
    const options = {
        series: [Graph({
            zoom: 5,
            data: chain.map(id => {
                const job = jobs.find(j => j.id === id)
                if (job) {
                    return {
                        symbol: 'circle',
                        symbolSize: 10,
                        name: job?.name || '???',
                        id: job?.id || '???',
                        // code: job.id.toString(),
                        // value: job.id.toString(),
                        job,
                        draggable: true,
                        label: {
                            position: 'bottom',
                            color: 'black',
                            formatter: ({ data }) => {
                                return `${data.name}`
                            },
                        },
                        itemStyle: {
                            color: selectedJob.id === id ? '#26a69a' : '#35609f',
                        },
                    }
                }
                return null
            }).filter(c => c),
            links: initial(chain).map((id, idx) => ({ source: id.toString(), target: chain[idx+1].toString() })),
            layout: 'force',
            force: { repulsion: 100 },
            roam: true,
            cursor: 'pointer',
            tooltip: {
                trigger: 'item',
                formatter: ({ data }) => {
                    return `${data.name}`
                },
            },
            label: {
                show: true,
            },
        })],
        height: 300,
    }

    return (
        <Grid>
            <Card>
                <EChart options={options} id='jobGraph' onClick={node => onClick(node.data.job)}/>
            </Card>
        </Grid>
    )
}

JobChainGraph.propTypes = {
    chain: PropTypes.arrayOf(PropTypes.number),
    jobs: arrayOf(Job),
    onClick: PropTypes.func,
}

export default JobChainGraph