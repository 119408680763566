module.exports = {
    RECEIVE_PIEZOMETER_KEYFIGURES: 'RECEIVE_PIEZOMETER_KEYFIGURES',
    RECEIVE_ALL_PIEZOMETERS: 'RECEIVE_ALL_PIEZOMETERS',
    RECEIVE_ALL_PIEZOMETERS_LIGHT: 'RECEIVE_ALL_PIEZOMETERS_LIGHT',
    RECEIVE_PIEZOMETER_LIST_SPECIFIC: '(RECEIVE_PIEZOMETER_LIST_SPECIFIC',
    RECEIVE_PIEZOMETER_STATION_DATA_CAMPAIGNS_EVENTS_READ: 'RECEIVE_PIEZOMETER_STATION_DATA_CAMPAIGNS_EVENTS_READ',
    DELETE_PIEZOMETER: 'DELETE_PIEZOMETER',
    RECEIVE_PIEZOMETRY_SITUATION: 'RECEIVE_PIEZOMETRY_SITUATION',
    RECEIVE_PIEZOMETRY_SITUATIONS: 'RECEIVE_PIEZOMETRY_SITUATIONS',
    RECEIVE_PIEZOMETRY_DATA_TYPES: 'RECEIVE_PIEZOMETRY_DATA_TYPES',
    RECEIVE_PIEZOMETRY_MAX_SITUATION: 'RECEIVE_PIEZOMETRY_MAX_SITUATION',
    RECEIVE_PIEZOMETRY_SAMPLE_SITUATION: 'RECEIVE_PIEZOMETRY_SAMPLE_SITUATION',
    RECEIVE_PIEZOMETRY_RAW_SITUATION: 'RECEIVE_PIEZOMETRY_RAW_SITUATION',
    RECEIVE_PIEZOMETRY_TYPE_SITUATION: 'RECEIVE_PIEZOMETRY_TYPE_SITUATION',
    RECEIVE_PIEZOMETRY_DAYS_SITUATION: 'RECEIVE_PIEZOMETRY_DAYS_SITUATION',
    RECEIVE_ALL_PIEZOMETERS_ACCESSIBILITIES: 'RECEIVE_ALL_PIEZOMETERS_ACCESSIBILITIES',
    RECEIVE_ALL_PIEZOMETERS_CONTRIBUTORS: 'RECEIVE_ALL_PIEZOMETERS_CONTRIBUTORS',
    RECEIVE_NETWORK_PIEZO_LINK: 'RECEIVE_NETWORK_PIEZO_LINK',
    RECEIVE_ALL_PIEZOMETERS_REFERENTS: 'RECEIVE_ALL_PIEZOMETERS_REFERENTS',
    RECEIVE_PIEZOMETRY_MEASURE_MODES: 'RECEIVE_PIEZOMETRY_MEASURE_MODES',
    CACHE_PIEZO_SITUATION: 'CACHE_PIEZO_SITUATION',
    REFRESH_ONE_PIEZO_SITUATION: 'REFRESH_ONE_PIEZO_SITUATION',
    CREATE_PIEZOMETER: 'CREATE_PIEZOMETER',
    HIDDEN_CHARTS: 'HIDDEN_CHARTS',
    RECEIVE_PIEZOMETRY_CHART_LANDMARK: 'RECEIVE_PIEZOMETRY_CHART_LANDMARK',
    PIEZOMETRY_SERIES_NAME: {
        MEASURES: 'measures',
        SAMPLES: 'samples',
        BRUTE: 'piezometerMeasureBrute',
        BRUTE_MEASURES: 'bruteMeasures',
        MIN: 'piezometerMeasureMin',
    },
    MEASURE_COTE: {
        NGF: 1,
        DEPTH: 2,
        GROUND: 3,
        LANDMARK: 4,
    },
    MEASURE_COTES: [{
        key: 1,
        label: 'NGF',
    }, {
        key: 2,
        label: 'Profondeur / Dernier repère',
    }, {
        key: 3,
        label: 'Sol',
    }, {
        key: 4,
        label: 'Historique des repères',
    }],
    MEASURE_MODES: [{
        key: 'ALL',
        label: 'Aucun',
    }, {
        key: 'MIN',
        label: 'Min',
    }, {
        key: 'MAX',
        label: 'Max',
    }, {
        key: 'AVERAGE',
        label: 'Moyenne',
    }, {
        key: 'SUM',
        label: 'Somme',
    }],
    MEASURE_FREQUENCY: [{
        key: '1',
        label: 'Aucune',
    }, {
        key: '24',
        label: 'Jour',
    }, {
        key: '168',
        label: 'Semaine',
    }, {
        key: '744',
        label: 'Mois',
    }, {
        key: '8760',
        label: 'Année',
    }],
    PIEZO_L_STORAGE: {
        DISPLAY_COTE: 'DISPLAY_COTE',
        DISPLAY_MODES_DASHBOARD: 'PIEZO_DISPLAY_MODES_DASHBOARD',
        STATUS_QUALIF_DASHBOARD: 'PIEZO_STATUS_QUALIF_DASHBOARD',
        DISPLAY_MODES_SUIVI: 'PIEZO_DISPLAY_MODES_SUIVI',
        STATUS_QUALIF_SUIVI: 'PIEZO_STATUS_QUALIF_SUIVI',
    },
    UNIT_METER: 'm',
    UNIT_METER_CUBIC: 'm3',
}
