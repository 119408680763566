import React, { useEffect, useMemo, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import { getLabel } from '../../../utils/StoreUtils'
import Table from '../../../components/datatable/Table'
import { getFullDate } from '../../../utils/DateUtil'
import { getResultFormat } from '../../../utils/AnalyseUtils'
import { nbPerPageLabelHuge } from '../../../referencial/constants/ReferencialConstants'
import InstallationAction from 'installation/actions/InstallationAction'
import useTitle from 'utils/customHook/useTitle'
import useActions from 'utils/customHook/useActions'
import { getStationArrowNav } from 'utils/ActionUtils'
import { round } from 'lodash'
import { push } from '@lagunovsky/redux-react-router'
import ProgressCard from 'components/card/ProgressCard'
import { Button, Grid } from '@mui/material'
import SimpleDatePicker from 'components/forms/SimpleDatePicker'
import Card from 'components/card/Card'

const headers = ['date', 'parameter', 'result', 'unit', 'reference', 'producer', 'comment']

const InstallationFollowUpApp = () => {
    const dispatch = useDispatch()

    const [dataLoaded, setDataLoaded] = useState(false)
    const [startDate, setStartDate] = useState()
    const [endDate, setEndDate] = useState()

    const {
        installation,
        installationAnalysis,
        parameters,
        contributors,
        units,
        installationTable,
        installationAnalysisCount,
    } = useSelector(store => ({
        installation: store.InstallationReducer.installation,
        installationTable: store.InstallationReducer.installationTable,
        installationAnalysis: store.InstallationReducer.installationAnalysis,
        parameters: store.ParameterReducer.parameters,
        contributors: store.ContributorReducer.contributors,
        units: store.UnitReducer.units,
        installationAnalysisCount: store.InstallationReducer.installationAnalysisCount,
    }), shallowEqual)

    useTitle(() => {
        return [{
            title: i18n.installation,
            href: 'installation',
        }, {
            title: installation.code !== '' || installation.name !== '' || installation.designation !== '' ? installation.code + (installation?.designation && `/${installation.designation}` || '') + (installation.name && ` - ${installation.name}` || '') : `[${installation.id}]`,
            href: `station/installation/${installation.id}`,
        }, {
            title: i18n.followUp,
            href: `station/installation/${installation.id}/installationFollowUp`,
        }]
    }, [installation])

    useEffect(() => {
        if (installation.id) {
            dispatch(InstallationAction.fetchInstallationAnalysis(installation.id)).then(() => setDataLoaded(true))
        }
    }, [installation.id])

    const reload = (forceLoad) => {
        setDataLoaded(false)
        dispatch(InstallationAction.fetchInstallationAnalysis(installation.id, startDate, endDate, forceLoad)).then(() => setDataLoaded(true))
    }

    // componentDidMount() {
    //     if (!componentHasHabilitations(H_STATION_INSTALLATION_FOLLOW_UP)) { // A modifier quand react-router sera à jour
    //         this.props.push('/unauthorized')
    //         return
    //     }
    //     this.setExport()
    // }

    const analysisData = useMemo(() => installationAnalysis.map(a => ({
        ...a,
        date: getFullDate(a.date),
        parameter: getLabel(parameters, a.parameter, 'labelWithCode'),
        result: getResultFormat(a),
        unit: getLabel(units, a.unit, 'labelWithCode', 'id'),
        producer: getLabel(contributors, a.producer),
        comment: a.analysisComment,
    })), [installationAnalysis])

    const exportData = useMemo(() => installationAnalysis.map((a, i) => ({
        date: { value: getFullDate(a.date), format: 'dd/MM/yyyy HH:mm:ss', cellType: 'date' },
        reference: { value: a.reference },
        parameter: { value: getLabel(parameters, a.parameter, 'labelWithCode') },
        result: { value: round(a.result, 2), format: '0.00', cellType: 'number' },
        unit: { value: getLabel(units, a.unit, 'labelWithCode', 'id') },
        producer: { value: getLabel(contributors, a.producer) },
        comment: { value: a.analysisComment },
        headers: i === 0 ? headers : [],
    })), [installationAnalysis])

    useActions(() => {
        return {
            export: () => ({
                data: exportData,
                exportType: 'xlsx',
                titleFile: `${i18n.followUp} - ${i18n.installation} - [${installation.code}] ${installation.name}`,
            }),
            arrowNav: getStationArrowNav('installation', installationTable, installation.id, s => push(`/station/installation/${s.id}/installationFollowUp`)),
        }
    })

    return dataLoaded ?
        (
            <>
                <Grid container xs={12} className='padding-left-2 padding-right-1 padding-top-1'>
                    <Grid item xs={12}>
                        <Card round className='padding-1'>
                            <Grid container>
                                <SimpleDatePicker
                                    col={5}
                                    label={i18n.startDate}
                                    onChange={v => setStartDate(v)}
                                    id='startDate'
                                    value={startDate}
                                    max={endDate}
                                />
                                <SimpleDatePicker
                                    col={5}
                                    label={i18n.endDate}
                                    onChange={v => setEndDate(v)}
                                    id='endDate'
                                    value={endDate}
                                    min={startDate}
                                />
                                <Grid container item xs={2} alignItems='center' justifyContent='flex-end'>
                                    <Button onClick={reload} variant='contained'>
                                        {i18n.search}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Card>
                    </Grid>
                    <Grid item xs={12} className='padding-top-1'>
                        <Table
                            title={i18n.dataFollowUp}
                            data={analysisData}
                            orderable
                            sortable
                            condensed
                            paging
                            nbPerPageLabel={nbPerPageLabelHuge}
                            type={{ headers }}
                            count={installationAnalysisCount}
                            countDisplayed={5000}
                            forceLoad={() => reload(true)}
                            round
                        />
                    </Grid>
                </Grid>
            </>
        ) : <ProgressCard indeterminate className='padding-left-2 padding-right-1 padding-top-1' />
}

InstallationFollowUpApp.propTypes = {}

export default InstallationFollowUpApp