module.exports = {
    WATERMASS: 'WATERMASS',
    WATERMASSES: 'WATERMASSES',
    RESET_WATERMASS: 'RESET_WATERMASS',
    STATIONS_WITH_WATERMASS: 'STATIONS_WITH_WATERMASS',
    RESET_WATERMASS_STATIONS: 'RESET_WATERMASS_STATIONS',
    RECEIVE_WATERMASS_STATES: 'RECEIVE_WATERMASS_STATES',
    UPDATE_WATERMASS_STATE: 'UPDATE_WATERMASS_STATE',
    CREATE_WATERMASS_STATE: 'CREATE_WATERMASS_STATE',
    DELETE_WATERMASS_STATE: 'DELETE_WATERMASS_STATE',
}
