import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import SieauParameterDto from '../../../administration/dto/SieauParameterDto'
import { arrayOf } from '../../../utils/StoreUtils'
import { usedUrl } from '../../../conf/basepath'
import { getDefaultCoordinates } from '../../../utils/mapUtils/CoordinateUtils'
import { getSetting } from '../../../utils/SettingUtils'
import KML from '../../../utils/mapUtils/layers/KML'
import WMS from '../../../utils/mapUtils/layers/WMS'
import OSM from '../../../utils/mapUtils/layers/OSM'
import LayerDto from '../../../home/dto/LayerDto'
import Map from '../../../utils/mapUtils/Map'
import { parseNowVariableURL } from '../../../utils/UrlUtils'
import SettingDto from '../../dto/SettingDto'

const $ = window.$

class LayerTestPanel extends Component {
    constructor(props) {
        super(props)
        this.state = {
            mapConf: {
                center: [parseFloat(getSetting(this.props.settings, 'defaultMapXCoordinate')) || getDefaultCoordinates()[0],
                    parseFloat(getSetting(this.props.settings, 'defaultMapYCoordinate')) || getDefaultCoordinates()[1]],
                zoom: parseFloat(getSetting(this.props.settings, 'defaultMapZoom')) || 9,
            },
        }
    }

    componentDidMount() {
        this.setState({
            mapConf: Object.assign({}, this.state.mapConf, {
                size: window.innerHeight - $('#sieau-map').offset().top,
            }),
        })
    }

    getMap = () => {
        const layers = this.props.layers.map(l => l.type === 0 ? WMS({
            url: parseNowVariableURL(l.url, getSetting(this.props.applicationSettings, 'myMaps')),
            params: { layers: l.layer },
            layerParam: l.layer,
            opacity: l.opacity,
            visible: true,
            projection: l.projection,
        }) : KML({

            url: parseNowVariableURL(l.url, getSetting(this.props.applicationSettings, 'myMaps')),
            params: { layers: l.layer },
            layerParam: l.layer,
            opacity: l.opacity,
            visible: true,
            extractStyles: true,
        })
        ).filter(l => l)
        const allLayers = [OSM()].concat(layers)

        return (
            <div className='row no-margin'>
                <Map mapConf={ this.state.mapConf } layers={ allLayers }/>
            </div>
        )
    }

    render() {
        return (
            <div className='col s12 no-padding' style={{ position: 'sticky', top: 80 }}>
                <div className='row no-margin-bottom'>
                    <div className='col s12 no-padding card no-overflow no-margin'>
                        { this.state.mapConf.size ? this.getMap() : <div id='sieau-map' /> }
                    </div>
                </div>
            </div>
        )
    }
}

LayerTestPanel.propTypes = {
    layers: PropTypes.arrayOf(PropTypes.instanceOf(LayerDto)),
    settings: PropTypes.instanceOf(SettingDto),
    applicationSettings: arrayOf(SieauParameterDto),
}

LayerTestPanel.defaultProps = {
    layers: [],
}

const mapStateToProps = store => ({
    settings: store.AdministrationReducer.settings,
    applicationSettings: store.AdministrationReducer.applicationSettings,
})

export default connect(mapStateToProps)(LayerTestPanel)
