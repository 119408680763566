import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { isEqualBy } from '../../utils/FormUtils'
import { searchAllCharacters } from '../../utils/StringUtil'
import DisplayedValue from './DisplayedValue'

class Textarea extends Component {
    constructor(props) {
        super(props)
        this.state = {
            textareaId: props.id || searchAllCharacters(props.title + uuidv4()).replaceAll(' ', '_').replace('numero', '').replace('nom', 'libelle'),
        }
    }

    componentDidMount() {
        $(`#${this.state.textareaId}`).trigger('autoresize')
        if (this.props.value) {
            $(`#${this.state.textareaId}`).val(this.props.value)
        }
    }

    componentDidUpdate() {
        $(`#${this.state.textareaId}`).trigger('autoresize')
        $(`#${this.state.textareaId}`).val(this.props.value || '')
    }

    onChangeValue = (event) => {
        if (this.props.onEnterKeyPress && (event.keyCode === 13 || event.which === 13)) {
            this.props.onEnterKeyPress(event.target.value)
        }
    }

    callOnChange = (val) => {
        if (this.props.keyObj && this.props.changeObj) {
            this.props.changeObj({ [this.props.keyObj]: val, LAST_FORM: `TEXT-${uuidv4()}` })
        }
        if (this.props.onChange) {
            this.props.onChange(val)
        }
    }

    getTextarea = () => {
        const { height } = this.props
        if (this.props.readMode) {
            return <DisplayedValue label={ this.props.title } value={ this.props.value } hideNull={ this.props.hideNull } displayValueBelow/>
        }
        const id = this.state.textareaId
        const disabled = { readOnly: this.props.disabled }
        return (
            <div className='input-field no-padding' >
                <textarea className={ `materialize-textarea no-padding sieau-input form-control ${this.props.noMargin ? 'no-margin' : ''}` } style={{ height }}
                    data-mode={ id } id={ id } onKeyPress={ (e) => this.onChangeValue(e) }
                    onChange={ (e) => this.callOnChange(e.target.value) } data-tooltip={ this.props.tooltip } { ...disabled } maxLength={ this.props.maxLength } rows={this.props.rows}
                    placeholder={ this.props.placeholder } data-cy={this.props['data-cy']}
                />
                <label htmlFor={ id } >{ this.props.title }</label>
            </div>
        )
    }

    render() {
        return this.props.col ? (
            <div className={ `col s${this.props.col}` } >
                { this.getTextarea() }
            </div>
        ) : this.getTextarea()
    }

    shouldComponentUpdate(nextProps) {
        if (this.props.freezeOpti && isEqualBy(this.props, nextProps, ['value', 'col', 'disabled', 'readMode'])) {
            return false
        }
        return true
    }
}

Textarea.propTypes = {
    title: PropTypes.string,
    className: PropTypes.string,
    onEnterKeyPress: PropTypes.func,
    onChange: PropTypes.func,
    tooltip: PropTypes.string,
    value: PropTypes.string,
    col: PropTypes.number,
    disabled: PropTypes.bool,
    readMode: PropTypes.bool,
    hideNull: PropTypes.bool,
    maxLength: PropTypes.number,
    noMargin: PropTypes.bool,
    keyObj: PropTypes.string,
    changeObj: PropTypes.func,
    freezeOpti: PropTypes.bool,
    rows: PropTypes.number,
    id: PropTypes.string,
    placeholder: PropTypes.string,
    height: PropTypes.string,

    'data-cy': PropTypes.string,
}

Textarea.defaultProps = {
    onChange: () => { },
    noMargin: true,
    rows: 3,
    title: '',
    placeholder: '',
    height: 'auto',
}

export default Textarea
