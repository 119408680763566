export default class DtoStationAssociation {
    constructor(obj) {
        this.code = obj.code // String,
        this.stationType = obj.stationType // Long,
        this.id = obj.id // Int,
        this.name = obj.name // Option[String],
        this.stationLinkedCode = obj.stationLinkedCode // String,
        this.stationLinkedType = obj.stationLinkedType // Long,
        this.stationLinkedId = obj.stationLinkedId // Int,
        this.stationLinkedName = obj.stationLinkedName // Option[String],
        this.typeName = obj.typeName // String,
        this.linkType = obj.linkType // Option[String] = None,
        this.countType = obj.countType // Int,
        this.x = obj.x // Option[Double],
        this.y = obj.y // Option[Double],
        this.projection = obj.projection // Option[Double],
        this.city = obj.city // Option[String]
    }
}