import React, { useEffect } from 'react'
import Table from '../../../../components/datatable/Table'
import i18n from 'simple-react-i18n'
import { nbPerPageLabel, SANDRE } from '../../../../referencial/constants/ReferencialConstants'
import { getLabel, getObjectLabel } from '../../../../utils/StoreUtils'
import { getFullDate } from '../../../../utils/DateUtil'
import { getMeasureStatusColorTable } from '../../../../utils/PiezometryUtils'
import PropTypes from 'prop-types'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { getSandreIndexLabel } from '../../../../utils/StringUtil'
import QualityAction from '../../../../quality/actions/QualityAction'
import DtoQualification from '../../../../quality/dto/DtoQualification'
import DtoStatus from '../../../../quality/dto/DtoStatus'

const TableViewValidationPluvioMode = ({
    selection,
    selectionData,
    setSelectionData,
    measuresData,
    startDate,
    endDate,
    setStartDate,
    setEndDate,
    setValue,
    setInitialPoint,
    setStatus,
    setQualification,
    setNature,
    setMeasureMode,
    setProducer,
    setManager,
    setValidator,
}) => {
    const [measures] = measuresData
    const { measures: measuresBrut } = measures

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(QualityAction.fetchQualifications())
        dispatch(QualityAction.fetchStatus())
    }, [])

    const {
        contributorsIndex,
        sandreIndex,
        qualifications,
        statuses,
    } = useSelector(store => ({
        contributorsIndex: store.ContributorReducer.contributorsIndex,
        sandreIndex: store.ReferencialReducer.sandreIndex,
        qualifications: store.QualityReducer.qualifications,
        statuses: store.QualityReducer.status,
    }), shallowEqual)

    const tableMeasures = measuresBrut.map(d => {
        const color = (d.date >= startDate && d.date <= endDate) ? '#7fdde9' : 'white'
        return {
            measure: d,
            date: { value: getFullDate(d.date), color },
            qualification: { value: getLabel(qualifications, d.qualification), color },
            status: { value: getLabel(statuses, d.status), color: getMeasureStatusColorTable(d.status) },
            initialPoint: { value: d.initialPoint === 1 ? i18n.yes : '', color },
            producer: { value: getObjectLabel(contributorsIndex[d.producer], 'mnemonique'), color },
            value: { value: d.value, color },
            initialValue: { value: d.initialValue, color },
            nature: { value: getSandreIndexLabel(sandreIndex, SANDRE.PIEZOMETER_MEASURE_NATURE, d.nature), color },
        }
    })
    const selectPoint = (point) => {
        if (selection && tableMeasures.length) {
            const date = point.date
            if (selection === 'window') {
                if (selectionData === 'end') {
                    setSelectionData('start')
                    setStartDate(date)
                } else if (selectionData === 'start') {
                    if (date < startDate) {
                        setStartDate(date)
                        setEndDate(startDate)
                    } else {
                        setSelectionData(null)
                        setEndDate(date)
                    }
                }
            } else if (selection === 'point') {
                setStartDate(date)
                setEndDate(date)
                setValue(point.value)
                setInitialPoint(point.initialPoint)
            }
            setQualification(point.qualification)
            setStatus(point.status)
            setNature(point.nature)
            setMeasureMode(point.measureMode)
            setProducer(point.producer)
            setManager(point.manager)
            setValidator(point.validator)
        }
    }
    return (
        <Table
            title={ i18n.measures }
            data={ tableMeasures }
            paging
            nbPerPageLabel={ nbPerPageLabel }
            type={{ headers: ['date', 'value', 'initialValue', 'status', 'qualification', 'nature', 'producer', 'initialPoint'] }}
            onClick={ m => selectPoint(m.measure) }
            condensed
            sortable
            color
        />
    )
}
TableViewValidationPluvioMode.propTypes = {
    selection: PropTypes.string,
    selectionData: PropTypes.string,
    setSelectionData: PropTypes.func,
    /*    measuresData: PropTypes.arrayOf(PropTypes.instanceOf(DtoPluvioMeasures)),*/
    startDate: PropTypes.number,
    endDate: PropTypes.number,
    setStartDate: PropTypes.func,
    setEndDate: PropTypes.func,
    setValue: PropTypes.func,
    setInitialPoint: PropTypes.func,
    setStatus: PropTypes.func,
    setQualification: PropTypes.func,
    qualifications: PropTypes.arrayOf(PropTypes.instanceOf(DtoQualification)),
    status: PropTypes.arrayOf(PropTypes.instanceOf(DtoStatus)),

}
export default TableViewValidationPluvioMode