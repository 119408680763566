'use strict'
import {
    RECEIVE_ALL_EQUIPMENT,
    RECEIVE_ALL_EQUIPMENT_SITUATIONS,
    RECEIVE_EQUIPMENT,
    RECEIVE_EQUIPMENT_FILES,
    RECEIVE_EQUIPMENT_PICTURES,
    RECEIVE_EQUIPMENT_TYPES,
    RECEIVE_EQUIPMENTS_LAST_SITUATIONS,
    RESET_EQUIPMENT,
} from '../constants/EquipmentConstants'
import EquipmentDto from '../dto/EquipmentDto'
import DtoEquipmentSituation from '../dto/DtoEquipmentSituation'
import DtoPicture from '../../../../station/dto/DtoPicture'
import DtoFile from '../../../../components/file/dto/DtoFile'
import EquipmentTypeDto from '../dto/EquipmentTypeDto'
import { RESET_ALL_MATERIELS } from '../../../constants/MaterielConstants'

export const store = {
    equipments: [],
    equipment: {},
    equipmentTypes: [],
    equipmentSituations: [],
    equipmentsLastSituations: [],
    equipmentPictures: [],
    equipmentFiles: [],
}

export function EquipmentReducer(state = {}, action) {
    switch (action.type) {
        case RECEIVE_ALL_EQUIPMENT:
            return { ...state,
                equipments: action.equipments.map(equipment => new EquipmentDto(equipment)),
            }
        case RECEIVE_EQUIPMENT:
            return { ...state,
                equipment: new EquipmentDto(action.equipment),
            }
        case RECEIVE_ALL_EQUIPMENT_SITUATIONS:
            return { ...state,
                equipmentSituations: action.equipmentSituations.map(situation => new DtoEquipmentSituation(situation)),
            }
        case RECEIVE_EQUIPMENTS_LAST_SITUATIONS:
            return { ...state,
                equipmentsLastSituations: action.equipmentsLastSituations.map(situation => new DtoEquipmentSituation(situation)),
            }
        case RECEIVE_EQUIPMENT_PICTURES:
            return { ...state,
                equipmentPictures: action.equipmentPictures.map(picture => new DtoPicture(picture)),
            }
        case RECEIVE_EQUIPMENT_FILES:
            return { ...state,
                equipmentFiles: action.equipmentFiles.map(file => new DtoFile(file)),
            }
        case RECEIVE_EQUIPMENT_TYPES:
            return { ...state,
                equipmentTypes: action.equipmentTypes.map(type => new EquipmentTypeDto(type)),
            }
        case RESET_EQUIPMENT:
            return { ...state,
                equipment: {},
                equipmentSituations: [],
                equipmentPictures: [],
                equipmentFiles: [],
            }
        case RESET_ALL_MATERIELS:
            return {
                ...state,
                ...store,
            }
        default:
            return state
    }
}
