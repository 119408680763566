import DtoLinkRule from './DtoLinkRule'

export default class DtoPARScenario {
    constructor(obj) {
        this.id = obj.id // Long,
        this.idPAR = obj.idPAR // Long,
        this.managementCode = obj.managementCode // Long,
        this.name = obj.name // String,
        this.description = obj.description // Option[String],
        this.statusCode = obj.statusCode // Option[Int],
        this.updateLogin = obj.updateLogin // Option[String],
        this.updateDate = obj.updateDate // Option[DateTime]

        this.linkRules = (obj.linkRules || []).map((r) => new DtoLinkRule(r)) // Option[Seq[LinkRuleScenario]]
    }
}