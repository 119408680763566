import React from 'react'
import PropTypes from 'prop-types'
import { getFullDateMini } from '../../utils/DateUtil'
import i18n from 'simple-react-i18n'
import { isNil } from 'lodash'
import { Card, CardContent } from '@mui/material'

const UpdatePanel = ({
    updateLogin = '',
    updateDate = 0,
    jobExecId,
}) => !!updateLogin && !!updateDate && (
    <Card>
        <CardContent>
            <span>{i18n.updatedAt} {getFullDateMini(updateDate)} {i18n.by} {updateLogin} {isNil(jobExecId) ? '' : `${i18n.fromTreatment} n°${jobExecId}`}</span>
        </CardContent>
    </Card>
)

UpdatePanel.propTypes = {
    updateLogin: PropTypes.string,
    updateDate: PropTypes.number,
    jobExecId: PropTypes.number,
}

export default UpdatePanel