/* eslint-disable camelcase */
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@mui/material'
import Select from 'components/forms/Select'
import SimpleDatePicker from 'components/forms/SimpleDatePicker'
import Textarea from 'components/forms/Textarea'
import FileUploadInput from 'import/dto/FileUploadInput'
import InstallationAction from 'installation/actions/InstallationAction'
import VisitPicture from 'installation/components/campaign/components/VisitPicture'
import { DANGER_ELEMENT_TYPE } from 'installation/constants/InstallationConstants'
import { orderBy } from 'lodash'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { SANDRE } from 'referencial/constants/ReferencialConstants'
import i18n from 'simple-react-i18n'
import ToastrAction from 'toastr/actions/ToastrAction'
import useUpdateEffect from 'utils/customHook/useUpdateEffect'
import { hasValue } from 'utils/NumberUtil'
import { getSandreList } from 'utils/StoreUtils'

const OtherEntityModal = ({
    isNew = false,
    open = false,
    setOpen = () => { },
    readMode = false,
    setIsNew = () => { },
}) => {
    const dispatch = useDispatch()

    const [type, setType] = useState(null)
    const [codeSite, setCodeSite] = useState(null)
    const [newDangerousSituation, setNewDangerousSituation] = useState(null)
    const [definitionDate, setDefinitionDate] = useState(null)
    const [dangerComment, setDangerComment] = useState(null)
    const [documents, setDocuments] = useState([])
    const [dialogTitle, setDialogTitle] = useState(null)
    const [resolutionDate, setResolutionDate] = useState(null)

    const {
        contributors,
        sectors,
        sandreCodes,
        danger,
    } = useSelector(store => ({
        contributors: store.ContributorReducer.contributors,
        sectors: store.SectorReducer.sectors,
        sandreCodes: store.ReferencialReducer.sandreCodes,
        danger: store.InstallationReducer.danger,
    }), shallowEqual)

    const resetPopup = () => {
        setType(null)
        setCodeSite(null)
        setNewDangerousSituation(null)
        setDefinitionDate(null)
        setResolutionDate(null)
        setDangerComment(null)
        setDocuments([])
        setOpen(false)
        setIsNew(false)
    }

    useUpdateEffect(() => {
        if (danger.idDanger && danger.siteType !== DANGER_ELEMENT_TYPE.INSTALLATION) {
            setDialogTitle(i18n.editDanger)
            setOpen(true)
            setIsNew(false)
            setType(danger.siteType)
            setCodeSite(danger.siteCode)
            setNewDangerousSituation(danger.dangerousSituation)
            setDefinitionDate(danger.definitionDate)
            setResolutionDate(danger.resolutionDate)
            setDangerComment(danger.comment)
            setDocuments(danger.link_documents)
        }
    }, [danger])

    const updateDanger = () => {
        if (!type || !codeSite || !newDangerousSituation || !definitionDate) {
            dispatch(ToastrAction.warning(i18n.pleaseCompleteAllRequiredField))
        } else {
            dispatch(InstallationAction.updateDanger({
                idDanger: !isNew && danger.idDanger || null,
                siteType: type,
                siteCode: codeSite,
                dangerousSituation: newDangerousSituation,
                comment: dangerComment,
                definitionDate,
                resolutionDate,
                link_documents: documents.map(doc => ({
                    siteType: type,
                    siteCode: codeSite,
                    comment: doc.description,
                    docName: doc.docName,
                })),
            })).then(() => {
                dispatch(InstallationAction.fetchDangersWithType({}))
                resetPopup()
            })
        }
    }

    const deleteDanger = () => {
        dispatch(InstallationAction.deleteDanger(danger.idDanger)).then(() => dispatch(InstallationAction.fetchDangersWithType({})))
        resetPopup()
    }

    const newDangerTypes = [{
        code: DANGER_ELEMENT_TYPE.CONTRIBUTOR,
        name: i18n.contributor,
    }, {
        code: DANGER_ELEMENT_TYPE.SECTOR,
        name: i18n.sector,
    }]

    const getList = () => {
        if (type === 2) {
            return contributors
        }
        return sectors
    }

    const displayPictures = orderBy(documents, 'docName').map(doc => {
        return (
            <VisitPicture
                doc={doc}
                onChangeDocument={value => setDocuments([...documents.filter(d => d.docName !== doc.docName), { ...doc, description: value }])}
                onDeleteDocument={() => setDocuments([...documents.filter(d => d.docName !== doc.docName)])}
                readMode={readMode}
            />)
    })

    const onUploadFile = (e) => {
        const reader = new FileReader()
        const file = e.target.files[0]
        reader.onload = upload => {
            dispatch(InstallationAction.uploadFile(new FileUploadInput({
                stationType: 0,
                stationId: 0,
                path: 'PHOTOS/',
                name: file.name,
                content: upload.target.result,
            }))).then(res => {
                setDocuments([
                    ...documents,
                    {
                        docName: res.name,
                    },
                ])
            })
        }
        reader.readAsDataURL(file)
    }

    return (
        <Dialog
            open={open}
            fullWidth
            maxWidth='md'
        >
            <DialogTitle><h5>{isNew ? i18n.newDangerousSituation : dialogTitle}</h5></DialogTitle>
            <DialogContent>
                <Grid container className='padding-top-1'>
                    <Grid container item>
                        <Grid item xs={6} className='padding-left-1 padding-right-1'>
                            <Select
                                col={12}
                                obligatory
                                options={newDangerTypes}
                                label={i18n.type}
                                nullLabel='&nbsp;'
                                value={type}
                                onChange={v => setType(v)}
                                disabled={readMode}
                            />
                        </Grid>
                        {hasValue(type) && (
                            <Grid item xs={6} className='padding-left-1 padding-right-1'>
                                <Select
                                    col={12}
                                    obligatory
                                    options={getList()}
                                    label={i18n.code}
                                    nullLabel='&nbsp;'
                                    value={codeSite}
                                    onChange={v => setCodeSite(v)}
                                    disabled={readMode}
                                />
                            </Grid>
                        )}
                    </Grid>
                    <Grid container>
                        <Grid item xs={6} className='padding-left-1 padding-right-1'>
                            <Select
                                col={12}
                                obligatory
                                options={getSandreList(sandreCodes, SANDRE.INSTALLATIONS_DANGERS)}
                                keyValue='code'
                                keyLabel='name'
                                noNullValue={true}
                                label={i18n.dangerousSituation}
                                value={newDangerousSituation}
                                onChange={v => setNewDangerousSituation(v)}
                                disabled={readMode}
                            />
                        </Grid>
                        <Grid container item xs={12}>
                            <Grid item xs={6} className='padding-left-1 padding-right-1'>
                                <SimpleDatePicker
                                    col={12}
                                    value={definitionDate}
                                    id='endDate'
                                    label={i18n.definitionDate}
                                    onChange={v => setDefinitionDate(v)}
                                    disabled={readMode}
                                    obligatory
                                />
                            </Grid>
                            <Grid item xs={6} className='padding-left-1 padding-right-1'>
                                <SimpleDatePicker
                                    col={12}
                                    value={resolutionDate}
                                    id='endDate'
                                    label={i18n.resolutionDate}
                                    onChange={v => setResolutionDate(v)}
                                    disabled={readMode}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12} className='padding-left-1 padding-right-1'>
                            <Textarea
                                col={12}
                                title={i18n.dangerComment}
                                value={dangerComment}
                                onChange={v => setDangerComment(v)}
                                disabled={readMode}
                            />
                        </Grid>
                    </Grid>
                    <Grid item container xs={12} justifyContent='space-evenly' style={{ padding: '10px 10px' }}>
                        {!readMode && (
                            <Grid item xs={12} style={{ padding: '10px 0' }}>
                                <Button
                                    variant='contained'
                                    component='label'
                                    color='primary'
                                >
                                    {i18n.addPicture}
                                    <input
                                        type='file'
                                        hidden
                                        onChange={e => onUploadFile(e)}
                                    />
                                </Button>
                            </Grid>
                        )}
                        <Grid item container xs={12} direction='row' alignItems='flex-start' justifyContent='space-evenly'>
                            {displayPictures}
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={resetPopup} variant='outlined'>
                    {i18n.cancel}
                </Button>
                {!readMode && (
                    <Button onClick={deleteDanger} variant='outlined'>
                        {i18n.delete}
                    </Button>
                )}
                {!readMode && (
                    <Button onClick={updateDanger} variant='contained' color='primary'>
                        {i18n.register}
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    )
}

OtherEntityModal.propTypes = {
    isNew: PropTypes.bool,
    setIsNew: PropTypes.func,
    open: PropTypes.bool,
    setOpen: PropTypes.func,
    readMode: PropTypes.bool,
}

export default OtherEntityModal
