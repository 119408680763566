import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import Card from 'components/card/Card'
import Select from 'components/forms/Select'
import SimpleDatePicker from 'components/forms/SimpleDatePicker'
import Row from 'components/react/Row'
import { SANDRE } from 'referencial/constants/ReferencialConstants'
import DtoSandreCode from 'referencial/dto/DtoSandreCode'
import { arrayOf, getSandreList } from 'utils/StoreUtils'
import { Button, Grid } from '@mui/material'

const emptyFilter = {
    startDate: undefined,
    endDate: undefined,
    status: undefined,
}

const FormFilterSurveys = ({
    onChange = () => { },
    sandreCodes = [],
}) => {
    const [tmpFilter, setTmpFilter] = useState(emptyFilter)

    const onReset = () => {
        onChange(emptyFilter)
        setTmpFilter(emptyFilter)
    }

    return (
        <Row>
            <Card round>
                <Row className='padding-top-1'>
                    <Row>
                        <SimpleDatePicker
                            col={4}
                            value={tmpFilter.startDate}
                            label={i18n.startDate}
                            onChange={v => setTmpFilter({ ...tmpFilter, startDate: v })}
                            max={tmpFilter.endDate}
                        />
                        <SimpleDatePicker
                            col={4}
                            value={tmpFilter.endDate}
                            label={i18n.endDate}
                            onChange={v => setTmpFilter({ ...tmpFilter, endDate: v })}
                            min={tmpFilter.startDate}
                        />
                        <Select
                            col={4}
                            label={i18n.status}
                            options={getSandreList(sandreCodes, SANDRE.CMS_STATUT)}
                            value={tmpFilter.status}
                            onChange={v => setTmpFilter({ ...tmpFilter, status: v })}
                            integerValue
                            keyValue='code'
                            nullLabel='&nbsp;'
                        />
                    </Row>
                    <Grid container className='padding-1' justifyContent='flex-end'>
                        <Grid item>
                            <Button variant='outlined' onClick={onReset}>
                                {i18n.reinit}
                            </Button>
                        </Grid>
                        <Grid item className='padding-left-1'>
                            <Button variant='contained' onClick={() => onChange(tmpFilter)}>
                                {i18n.search}
                            </Button>
                        </Grid>
                    </Grid>
                </Row>
            </Card>
        </Row>
    )
}

FormFilterSurveys.propTypes = {
    onChange: PropTypes.func,
    sandreCodes: arrayOf(DtoSandreCode),
}

const mapStateToProps = store => ({
    sandreCodes: store.ReferencialReducer.sandreCodes,
})

export default connect(mapStateToProps)(FormFilterSurveys)