import React, { useMemo, useState } from 'react'
import societe from 'assets/pictures/logos/societe.png'
import logoSandre from 'assets/pictures/logo_sandre.png'
import Input from 'components/forms/Input'
import CartographyPanel from 'components/map/CartographyPanel'
import { find } from 'lodash'
import { useEffect } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { REFERENCIAL_TYPE_NAME } from 'referencial/constants/ReferencialConstants'
import i18n from 'simple-react-i18n'
import { getDataWithHeaders } from 'utils/ExportDataUtil'
import AdministrationAction from '../../../../administration/actions/AdministrationAction'
import Card from '../../../../components/card/Card'
import Select from '../../../../components/forms/Select'
import SieauAction from '../../../../components/sieau/SieauAction'
import { sandreContributorDefaultLink, societeDefaultUrl } from '../../../../conf/SieauConstants'
import HomeAction from '../../../../home/actions/HomeAction'
import {
    LINK_REFERENCIAL_CONTRIBUTOR,
    PATH_REFERENCIAL,
    PATH_REFERENCIAL_CONTRIBUTOR,
    PATH_REFERENCIAL_CONTRIBUTOR_NEW,
} from '../../../../home/constants/RouteConstants'
import { default as ReferencialAction, default as ReferentialAction } from '../../../action/ReferencialAction'
import { getExportReferencialData, getReferencialData } from '../../../util/ReferencialUtils'
import CityAction from '../../city/actions/CityAction'
import CountryAction from '../../country/actions/CountryAction'
import ReferencialAppList from '../../ReferencialAppList'
import ContributorAction from '../actions/ContributorAction'
import { CONTRIBUTOR, MAP } from '../constants/ContributorConstants'
import useLocalStorage from 'utils/customHook/useLocalStorage'
import { push } from '@lagunovsky/redux-react-router'
import { GROUP } from 'referencial/components/contact/constants/ContactConstants'
import ContributorsGroupsApp from './ContributorsGroupsApp'
import ProgressCard from 'components/card/ProgressCard'
import { Button, Grid2 } from '@mui/material'

const ContributorsPanel = () => {
    const {
        contributorsTable,
        cities,
        countries,
        sandreCodes,
        referencialSandreCodes,
        globalResearch,
        applicationSettings,
        referencialStatus,
    } = useSelector(store => ({
        contributorsTable: store.ContributorReducer.contributorsTable,
        cities: store.CityReducer.cities,
        countries: store.CountryReducer.countries,
        sandreCodes: store.ReferencialReducer.sandreCodes,
        referencialSandreCodes: store.ReferencialReducer.referencialSandreCodes,
        globalResearch: store.HomeReducer.globalResearch,
        applicationSettings: store.AdministrationReducer.applicationSettings,
        referencialStatus: store.ReferencialReducer.referencialStatus,
    }), shallowEqual)

    const [filter, setFilter] = useLocalStorage(`referencial_${REFERENCIAL_TYPE_NAME.contributor}`, {})
    const [dataLoaded, setDataLoaded] = useState(false)

    const dispatch = useDispatch()

    const fetchData = () => dispatch(ContributorAction.fetchContributorsTable({
        searchValue: filter.searchValue,
        city: filter.citySelected?.id,
        domain: filter.domain,
        countryCode: filter.countrySelected,
        status: filter.status?.toString(),
    }))

    useEffect(() => {
        dispatch(HomeAction.setHelpLink('', ''))
        fetchData().then(() => setDataLoaded(true))

        if (!referencialSandreCodes.length) {
            dispatch(ReferentialAction.fetchReferencialSandreCodes())
        }
        if (globalResearch) {
            setFilter({ ...filter, searchValue: globalResearch })
            dispatch(HomeAction.updateGlobalResearch(''))
        }
        if (!cities.length) {
            dispatch(CityAction.fetchCities())
        }
        if (!sandreCodes.length) {
            dispatch(ReferencialAction.fetchSandreCodes())
        }
        if (!countries.length) {
            dispatch(CountryAction.fetchCountries())
        }
        if (!applicationSettings.length) {
            dispatch(AdministrationAction.fetchApplicationSettings())
        }
        dispatch(ContributorAction.fetchContributors(true))
        if (!referencialStatus.length) {
            dispatch(ReferencialAction.fetchReferencialStatus())
        }
    }, [])

    const lastUpdate = useMemo(() => find(referencialSandreCodes, o => o.field === 'INTERVENANTS'), [referencialSandreCodes])

    const onResetFilters = () => {
        setFilter({})
        dispatch(ContributorAction.fetchContributorsTable())
    }

    const internationalContext = useMemo(() => applicationSettings.find(a => a.parameter === 'ContexteInternational'), [applicationSettings])
    const isInternationalContext = useMemo(() => internationalContext ? internationalContext.value.toLowerCase() === 'oui' : false, [internationalContext])
    const countryCodeHeader = useMemo(() => isInternationalContext ? ['countryCode'] : [], [isInternationalContext])
    const headers = useMemo(() => ['siret', 'name', 'mnemonique', 'postalCode', 'city', 'phoneTel', 'domain', 'sandre', 'status', ...countryCodeHeader], [countryCodeHeader])

    const exportData = useMemo(() => getDataWithHeaders(contributorsTable, headers), [contributorsTable, headers])

    return (
        <div>
            <Card title={i18n.filters} className='row margin-left-1 margin-right-1' noMargin={ false }>
                <Grid2 container spacing={1} sx={{ padding: '10px' }}>
                    <Grid2 size={8}>
                        <Input
                            title={ i18n.search }
                            value={ filter.searchValue }
                            onChange={ value => setFilter({ ...filter, searchValue: value }) }
                            onEnterKeyPress={fetchData}
                        />
                    </Grid2>
                    <Grid2 size={4}>
                        <Select
                            options={referencialStatus}
                            label={i18n.status}
                            onChange={v => setFilter({ ...filter, status: v })}
                            value={filter.status}
                            nullLabel='&nbsp;'
                        />
                    </Grid2>
                    {isInternationalContext && (
                        <Grid2 size={3}>
                            <Select
                                label={i18n.country}
                                options={countries}
                                value={filter.countrySelected}
                                keyValue='countryCode'
                                keyLabel='name'
                                onChange={value => setFilter({ ...filter, countrySelected: value })}
                            />
                        </Grid2>
                    )}
                    <Grid2 size={3}>
                        <Select
                            options={ cities }
                            label={ i18n.city }
                            onChange={(_, value) => setFilter({ ...filter, citySelected: value })}
                            value={ filter.citySelected?.id }
                        />
                    </Grid2>
                    <Grid2 size={3}>
                        <Select
                            label={i18n.domain}
                            options={sandreCodes.filter(s => s.field === 'INTERVENANTS.DOMAINES')}
                            value={filter.domain}
                            keyValue='code'
                            keyLabel='name'
                            onChange={value => setFilter({ ...filter, domain: value })}
                            integerValue
                        />
                    </Grid2>
                    <Grid2 container size='grow' justifyContent='flex-end' alignItems='center'>
                        <Grid2>
                            <Button variant='outlined' onClick={onResetFilters}>
                                {i18n.reinit}
                            </Button>
                        </Grid2>
                        <Grid2>
                            <Button variant='contained' onClick={fetchData}>
                                {i18n.search}
                            </Button>
                        </Grid2>
                    </Grid2>
                </Grid2>
            </Card>
            <div className='margin-top-1 margin-bottom-1'>
                {dataLoaded ? (
                    <ReferencialAppList
                        lastUpdate={ lastUpdate }
                        title={ i18n.contributorsList }
                        data={ getReferencialData(contributorsTable) }
                        type={{ headers }}
                        link={ LINK_REFERENCIAL_CONTRIBUTOR }
                        action={ ContributorAction.fetchContributors }
                        newAction={ () => dispatch(push(PATH_REFERENCIAL_CONTRIBUTOR_NEW)) }
                        setTitleAction={ SieauAction.forceFetch('title', [{
                            title: i18n.referencials,
                            href: PATH_REFERENCIAL,
                        }, {
                            title: i18n.contributors,
                            href: PATH_REFERENCIAL_CONTRIBUTOR,
                        }]) }
                        actions={{
                            export: {
                                data: getExportReferencialData(exportData),
                                titleFile: i18n.contributorsList,
                            },
                            sync: {
                                updateAction: () => dispatch(ContributorAction.fetchSynchroSandreContributor()),
                            },
                            purge: {
                                onPurge: () => dispatch(ContributorAction.purgeContributor()),
                            },
                        }}
                        links={ [{
                            href: societeDefaultUrl,
                            img: societe,
                            label: i18n.societe.toUpperCase(),
                        }, {
                            href: sandreContributorDefaultLink,
                            img: logoSandre,
                            label: i18n.sandre.toUpperCase(),
                        }] }
                        searchable={false}
                        showExportButton={false}
                        showUpdateButton={false}
                        showPurgeButton={false}
                    />
                ) : (
                    <div className='padding-1'>
                        <ProgressCard indeterminate withMessage round />
                    </div>
                )}
            </div>
        </div>
    )
}

const ContributorsApp = () => {
    const {
        contributors,
    } = useSelector(store => ({
        contributors: store.ContributorReducer.contributors,
    }), shallowEqual)

    const [panel, setPanel] = useState(CONTRIBUTOR)

    const onChangePanel = newPanel => {
        if (panel !== newPanel) {
            setPanel(newPanel)
        }
    }

    return (
        <div className='row no-margin'>
            <div className='col-s12 margin-left-1 margin-right-1'>
                <ul className='tabs tabs-fixed-width'>
                    <li className='tab'>
                        <a onClick={ () => onChangePanel(CONTRIBUTOR) }>{ i18n.contributors }</a>
                    </li>
                    <li className='tab'>
                        <a onClick={ () => onChangePanel(GROUP) }>{ i18n.contributorsGroups }</a>
                    </li>
                    <li className='tab'>
                        <a onClick={ () => onChangePanel(MAP) }>{ i18n.map }</a>
                    </li>
                </ul>
            </div>
            { panel === CONTRIBUTOR && <ContributorsPanel /> }
            { panel === GROUP && <ContributorsGroupsApp /> }
            { panel === MAP && (
                <div style={{ padding: '5px 10px' }}>
                    <CartographyPanel layers={['STATIONS_POINTS']}
                        componentType='all'
                        stationsPoints={contributors}
                        stationsPanelTitle={i18n.contributors}
                        heightToSubstract={450}
                    />
                </div>
            )}
        </div>
    )
}

export default ContributorsApp
