import ReferencialDto from '../../../dto/ReferencialDto'

export default class UnitDto extends ReferencialDto {
    constructor(object) {
        super(object)
        this.id = object.code
        this.symbol = object.symbol
        this.comment = object.comment
        this.internationalName = object.internationalName
        this.author = object.author
        this.creationDate = object.creationDate
        this.updateDate = object.updateDate
        this.active = object.active
        this.equivalentsInternCodes = object.equivalentsInternCodes
        this.symbolWithName = `${object.name || ''} ${object.symbol ? `[${object.symbol}]` : ''}`
        this.internalCode = object.internalCode
        this.symbolWithCode = object.code ? `${object.symbol || ''} [${object.code}]` : object.symbol
    }
}
