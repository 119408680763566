import {sieauTooltip} from "../../utils/FormUtils"
import React from "react"

const IconTool = ({icon, toolIcon, height, width, tooltip, setTool, iconSelected}) => {
    return (
        <li style={{display: 'inline-block'}}>
            <img
                className={`validationIcon clickable no-margin ${toolIcon === iconSelected && 'activated'}`}
                style={{height: height, width: width}}
                onClick={() => setTool(toolIcon)}
                src={icon} {...sieauTooltip(tooltip)}
            />
        </li>
    )
}
export default IconTool