import ReferencialDto from '../../../dto/ReferencialDto'

export default class ContactGroupDto extends ReferencialDto {
    constructor(object) {
        super(object)
        this.code = object.groupCode
        this.name = object.name
        this.numberOfMembers = object.count
        this.contacts = object.contacts
    }
}