const DEFAULT_QUALITY_THRESHOLD_ID = '50'

module.exports = {
    RECEIVE_QUALITY_SELECTION: 'RECEIVE_QUALITY_SELECTION',
    RECEIVE_THRESHOLDS: 'RECEIVE_THRESHOLDS',
    RECEIVE_STATION_QUALITY_THRESHOLD: 'RECEIVE_STATION_QUALITY_THRESHOLD',
    RECEIVE_STATUS: 'RECEIVE_STATUS',
    QUALIFICATIONS: 'QUALIFICATIONS',
    FILTER_STATIONS: 'FILTER_STATIONS',
    RECEIVE_ALL_QUALITOMETERS: 'RECEIVE_ALL_QUALITOMETERS',
    RECEIVE_QUALITOMETER: 'RECEIVE_QUALITOMETER',
    UPDATE_QUALITOMETER: 'UPDATE_QUALITOMETER',
    RESET_QUALITOMETER: 'RESET_QUALITOMETER',
    DELETE_QUALITOMETER: 'DELETE_QUALITOMETER',
    UPDATE_QUALITOMETER_CONTRIBUTORS: 'UPDATE_QUALITOMETER_CONTRIBUTORS',
    RECEIVE_SEARCH_ANALYSIS: 'RECEIVE_SEARCH_ANALYSIS',
    RECEIVE_SAMPLE: 'RECEIVE_SAMPLE',
    RECEIVE_KEYFIGURES: 'RECEIVE_KEYFIGURES',
    RECEIVE_QUALITY_INDICATORS: 'RECEIVE_QUALITY_INDICATORS',
    RESET_QUALITY: 'RESET_QUALITY',
    RESET_QUALITY_SAMPLE: 'RESET_QUALITY_SAMPLE',
    RESET_QUALITY_ANALYSIS: 'RESET_QUALITY_ANALYSIS',
    RESET_QUALITY_THRESHOLDS: 'RESET_QUALITY_THRESHOLDS',
    DEFAULT_QUALITY_THRESHOLD_ID,
    RECEIVE_HYPE_TRENDS: 'RECEIVE_HYPE_TRENDS',
    RECEIVE_HYPE_RUPTURES: 'RECEIVE_HYPE_RUPTURES',
    RESET_HYPE_TRENDS: 'RESET_HYPE_TRENDS',
    RESET_HYPE_RUPTURES: 'RESET_HYPE_RUPTURES',
    RECEIVE_QUALITY_INDICATORS_RESULTS: 'RECEIVE_QUALITY_INDICATORS_RESULTS',
    UPDATE_QUALITY_INDICATORS: 'UPDATE_QUALITY_INDICATORS',
    RECEIVE_SEARCH_INDICES: 'RECEIVE_SEARCH_INDICES',
    RECEIVE_QUALITOMETER_STATES: 'RECEIVE_QUALITOMETER_STATES',
    RECEIVE_ALL_QUALITY_STATES: 'RECEIVE_ALL_QUALITY_STATES',
    UPDATE_QUALITOMETER_STATE: 'UPDATE_QUALITOMETER_STATE',
    CREATE_QUALITOMETER: 'CREATE_QUALITOMETER',
    CREATE_QUALITOMETER_STATE: 'CREATE_QUALITOMETER_STATE',
    DELETE_QUALITOMETER_STATE: 'DELETE_QUALITOMETER_STATE',
    RECEIVE_QUALITY_CAMPAIGNS: 'RECEIVE_QUALITY_CAMPAIGNS',
    RECEIVE_QUALITY_CAMPAIGN_STATIONS: 'RECEIVE_QUALITY_CAMPAIGN_STATIONS',
    RECEIVE_QUALITY_CAMPAIGNS_PROGRESS: 'RECEIVE_QUALITY_CAMPAIGNS_PROGRESS',
    RECEIVE_QUALITY_NETWORK_LINKS: 'RECEIVE_QUALITY_NETWORK_LINKS',
    RECEIVE_QUALITY_PRODUCERS: 'RECEIVE_QUALITY_PRODUCERS',
    RECEIVE_ALL_QUALITOMETERS_LIGHT: 'RECEIVE_ALL_QUALITOMETERS_LIGHT',
    RECEIVE_QUALITOMETERS_LIST_SPECIFIC: 'RECEIVE_QUALITOMETERS_LIST_SPECIFIC',
    RECEIVE_QUALITOMETER_POINTS: 'RECEIVE_QUALITOMETER_POINTS',
    RECEIVE_UNIQ_QUALITOMETER_POINTS: 'RECEIVE_UNIQ_QUALITOMETER_POINTS',
    RECEIVE_MODELS_FILES: 'RECEIVE_MODELS_FILES',
    RECEIVE_ESU_LIGHT: 'RECEIVE_ESU_LIGHT',
    POTABILITY_LIMIT_ID: 41,
    RESULT: 'RESULT',
    GRAPH: 'GRAPH',
    SOURCE: 'SOURCE',
    INDICE: 'INDICE',
    HYPE_TRENDS_CONSTANTS: {
        MANN_KENDALL: 'MANN_KENDALL',
        LINEAR_REGRESSION: 'LINEAR_REGRESSION',
        AVERAGE_RUPTURE: 'AVERAGE_RUPTURE',
        TREND_RUPTURE: 'TREND_RUPTURE',
    },
    RESEARCH_TYPE: {
        INDICES: -1,
        STATES: -2,
        TAXON: -3,
        WATER: 31,
    },
    DEFAULT_NB_PARAMETER_DISPLAY: 10,
    SELECTION: {
        PC: 0,
        TAXON: 1,
        INDICE: 2,
        ENVIRONMENTAL_PARAMETER: 3,
        STATE: 4,
    },
    THRESHOLD: {
        PC: '0',
        TAXON: '1',
        INDICE: '2',
        ENVIRONMENTAL_PARAMETER: '3',
        STATE: '4',
    },
    // code sandre trié, 28 inconnu, 35 clade ¯\_(ツ)_/¯, 39 giga-classe, 30 parvordre (gelée)
    taxonLevelOrder: [31, 32, 0, 23, 26, 1, 33, 2, 34, 27, 3, 4, 5, 18, 36, 6, 7, 8, 17, 29, 9, 10, 11, 37, 12, 38, 13, 14, 24, 25, 15, 16, 19, 20, 21, 22],
}
