export default class DtoInstallationIndustrialSite {
    constructor(obj) {
        // installation data
        this.id = obj.id // Int,
        this.code = obj.code // Option[String],
        this.installationType = obj.installationType // Option[Int],
        this.name = obj.name // Option[String],
        this.townCode = obj.townCode // Option[String],
        this.dataOrigin = obj.dataOrigin // Option[String],

        // industrial site data
        this.status = obj.activityStatus // Option[Int]
        this.activityCode = obj.activityCode // Option[String],
        this.activityCode2 = obj.activityCode2 // Option[String],
        this.activityCode3 = obj.activityCode3 // Option[String],
        this.activityCode4 = obj.activityCode4 // Option[String],
        this.staff = obj.staff // Option[Int],
        this.effectiveTranche = obj.effectiveTranche // Option[Int],
        this.siret = obj.siret
        this.categoryActivity = obj.categoryActivity // Option[Int],
        this.icpe = obj.icpe // Option[Boolean],
        this.dischargeAgreement = obj.dischargeAgreement // Option[Boolean],
        this.agreementStartDate = obj.agreementStartDate // Option[DateTime],
        this.agreementEndDate = obj.agreementEndDate // Option[DateTime],

        // for front
        this.type = obj.type
        this.typeName = obj.typeName
        this.city = obj.city
        this.x = obj.x
        this.y = obj.y
        this.projection = obj.projection
        this.projectionLabel = obj.projectionLabel
        this.activity = obj.activityLibelle
        this.activityLibelle2 = obj.activityLibelle2
        this.activityLibelle3 = obj.activityLibelle3
        this.activityLibelle4 = obj.activityLibelle4
        this.icpeLabel = obj.icpeLabel
        this.dischargeAgreementLabel = obj.dischargeAgreementLabel

        this.headers = ['siret', 'name', 'city', 'activity', 'staff', 'effectiveTranche', 'icpeLabel', 'dischargeAgreementLabel']
        this.exportHeaders = [
            'siret', 'name', 'city', 'x', 'y', 'projectionLabel', 'activityCode', 'activity', 'activityCode2', 'activityLibelle2', 'activityCode3', 'activityLibelle3',
            'activityCode4', 'activityLibelle4', 'staff', 'effectiveTranche',
        ]
    }
}

