import { isEqual, omit } from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import WaitAction from 'wait/WaitAction'
import MaterielAction from '../../../../../materiel/actions/MaterielAction'
import VariousMaterielAction from '../../../../../materiel/components/variousMateriel/actions/VariousMaterielAction'
import VariousMaterielPanel from '../../../../../materiel/components/variousMateriel/components/VariousMaterielPanel'
import DtoVariousMaterielSituation from '../../../../../materiel/components/variousMateriel/dto/DtoVariousMaterielSituation'
import DtoVariousMaterielType from '../../../../../materiel/components/variousMateriel/dto/DtoVariousMaterielType'
import VariousMaterielDto from '../../../../../materiel/components/variousMateriel/dto/VariousMaterielDto'
import DtoMaterielState from '../../../../../materiel/dto/DtoMaterielState'
import StationAction from '../../../../actions/StationAction'
import DtoStation from '../../../../dto/DtoStation'
import DtoStationVariousMaterielAssignment from '../../../../dto/materiel/DtoStationVariousMaterielAssignment'
import SituationDetailPanel from '../SituationDetailPanel'

class VariousMaterielSituationDetail extends Component {
    state = {
        variousMateriel: {},
        variousMaterielDefaultParam: {},
    }

    componentDidMount() {
        const {
            materielStates,
            variousMateriels,
            variousMaterielTypes,
        } = this.props
        if (!materielStates.length) {
            this.props.fetchMaterielStates()
        }
        if (!variousMateriels.length) {
            this.props.fetchVariousMateriels()
        }
        if (!variousMaterielTypes.length) {
            this.props.fetchVariousMaterielTypes()
        }
    }

    getVariousMaterielTitle = ({ serialNumber, reference }, { label }) => {
        const code = serialNumber || reference || ''
        return label ? `${label} - ${code}` : code || i18n.unknownMaterial
    }

    setVariousMaterielTypeParam = () => {
        const { materielType } = this.state.variousMateriel
        const type = this.props.variousMaterielTypes.find(t => t.id === materielType) || {}
        this.setState({
            variousMateriel: {
                ...this.state.variousMateriel,
                ...omit(type, ['id', 'code', 'label', 'name', 'comment', 'updateDate', 'updateLogin', 'startDate', 'endDate', 'icon', 'headers']),
            },
        })
    }

    getVariousMaterielPanel = isEditMode => {
        const { variousMateriel } = this.state
        if (variousMateriel.id) {
            const variousMaterielType = this.props.variousMaterielTypes.find(t => t.id === variousMateriel.materielType) || {}
            return (
                <div>
                    <div className='col s8 offset-s2'>
                        <div className='col s4 offset-s2'>
                            <a className='col s12 btn' onClick={() => this.setState({
                                variousMateriel: {
                                    ...this.state.variousMaterielDefaultParam,
                                },
                            })}
                            >
                                {i18n.defaultSettings}
                            </a>
                        </div>
                        <div className='col s4'>
                            <a className='col s12 btn' onClick={this.setVariousMaterielTypeParam}>
                                {i18n.typeSettings}
                            </a>
                        </div>
                    </div>
                    <div className='row col s10 offset-s1'>
                        <div className='card no-padding'>
                            <div className='col s12 card-title active'>
                                {this.getVariousMaterielTitle(variousMateriel, variousMaterielType)}
                            </div>
                            <div className={'card-content margin-top-1'}>
                                <div className='row no-margin'>
                                    <VariousMaterielPanel id={variousMateriel.id}
                                        variousMateriel={variousMateriel}
                                        onChange={changes => this.setState({ variousMateriel: { ...this.state.variousMateriel, ...changes } })}
                                        disabled={!isEditMode}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        return null
    }

    fetchVariousMateriel = id => {
        this.props.waitStart()
        this.props.fetchVariousMateriel(id).then(json => {
            this.setState({ variousMateriel: json, variousMaterielDefaultParam: json })
            this.props.waitStop()
        })
    }

    updateVariousMateriel = () => {
        if (!isEqual(this.state.variousMateriel, this.state.variousMaterielDefaultParam)) {
            this.props.updateVariousMateriel(this.state.variousMateriel)
        }
    }

    resetVariousMateriel = () => {
        this.props.resetVariousMateriel()
        this.setState({ variousMateriel: {}, variousMaterielDefaultParam: {} })
    }

    render = () => {
        const {
            station,
            variousMateriels,
            variousMaterielsLastSituations,
            variousMaterielTypes,
            stationVariousMaterielAssignments,
            addVariousMaterielSituation,
            saveVariousMaterielSituation,
            deleteVariousMaterielSituation,
            params,
            type,
        } = this.props
        return (
            <SituationDetailPanel
                params={params}
                type={type}
                getMaterielPanel={isEditMode => this.getVariousMaterielPanel(isEditMode)}
                station={station}
                materiels={variousMateriels}
                lastSituations={variousMaterielsLastSituations}
                materielTypes={variousMaterielTypes}
                stationMaterielAssignments={stationVariousMaterielAssignments}
                addSituation={addVariousMaterielSituation}
                saveSituation={saveVariousMaterielSituation}
                deleteSituation={deleteVariousMaterielSituation}
                fetchMateriel={this.fetchVariousMateriel}
                updateMateriel={this.updateVariousMateriel}
                resetMateriel={this.resetVariousMateriel}
                keyMaterielType='materielType'
                keyMaterielId='idVarious'
            />
        )
    }
}

VariousMaterielSituationDetail.propTypes = {
    params: PropTypes.shape({
        id: PropTypes.string,
        materialType: PropTypes.string,
    }),
    type: PropTypes.string,
    station: PropTypes.instanceOf(DtoStation),

    stationVariousMaterielAssignments: PropTypes.arrayOf(PropTypes.instanceOf(DtoStationVariousMaterielAssignment)),
    materielStates: PropTypes.arrayOf(PropTypes.instanceOf(DtoMaterielState)),
    variousMateriel: PropTypes.instanceOf(VariousMaterielDto),
    variousMateriels: PropTypes.arrayOf(PropTypes.instanceOf(VariousMaterielDto)),
    variousMaterielsLastSituations: PropTypes.arrayOf(PropTypes.instanceOf(DtoVariousMaterielSituation)),
    variousMaterielTypes: PropTypes.arrayOf(PropTypes.instanceOf(DtoVariousMaterielType)),

    resetVariousMateriel: PropTypes.func,
    updateVariousMateriel: PropTypes.func,
    fetchVariousMateriel: PropTypes.func,
    fetchVariousMateriels: PropTypes.func,
    fetchMaterielStates: PropTypes.func,
    fetchVariousMaterielTypes: PropTypes.func,
    saveVariousMaterielSituation: PropTypes.func,
    addVariousMaterielSituation: PropTypes.func,
    deleteVariousMaterielSituation: PropTypes.func,
    waitStart: PropTypes.func,
    waitStop: PropTypes.func,
}

const mapStateToProps = store => ({
    stationVariousMaterielAssignments: store.StationReducer.stationVariousMaterielAssignments,
    materielStates: store.MaterielReducer.materielStates,
    variousMateriel: store.VariousMaterielReducer.variousMateriel,
    variousMateriels: store.VariousMaterielReducer.variousMateriels,
    variousMaterielsLastSituations: store.VariousMaterielReducer.variousMaterielsLastSituations,
    variousMaterielTypes: store.VariousMaterielReducer.variousMaterielTypes,
})

const mapDispatchToProps = {
    resetVariousMateriel: VariousMaterielAction.resetVariousMateriel,
    updateVariousMateriel: VariousMaterielAction.updateVariousMateriel,
    fetchVariousMateriel: VariousMaterielAction.fetchVariousMateriel,
    fetchVariousMateriels: VariousMaterielAction.fetchVariousMateriels,
    fetchMaterielStates: MaterielAction.fetchMaterielStates,
    fetchVariousMaterielTypes: VariousMaterielAction.fetchVariousMaterielTypes,
    saveVariousMaterielSituation: StationAction.saveVariousMaterielSituation,
    addVariousMaterielSituation: StationAction.addVariousMaterielSituation,
    deleteVariousMaterielSituation: StationAction.deleteVariousMaterielSituation,
    waitStart: WaitAction.waitStart,
    waitStop: WaitAction.waitStop,
}

export default connect(mapStateToProps, mapDispatchToProps)(VariousMaterielSituationDetail)