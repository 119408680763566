export default class DtoJobFile {
    constructor(obj) {
        this.jobId = obj.jobId
        this.jobExecutionId = obj.jobExecutionId
        this.fileName = obj.fileName
        this.status = obj.status
        this.startDate = obj.startDate
        this.endDate = obj.endDate
        this.filePath = obj.filePath
    }
}