'use strict'
import { RECEIVE_ALL_FRACTIONS, RECEIVE_FRACTION, RESET_FRACTION } from '../constants/FractionConstants'
import FractionItem from '../dto/FractionItem'
import FractionDto from '../dto/FractionDto'

export function FractionReducer(state = {}, action) {
    switch (action.type) {
        case RECEIVE_ALL_FRACTIONS:
            return Object.assign({}, state, {
                fractions: action.fractions.map(fraction => new FractionItem(fraction)),
            })
        case RECEIVE_FRACTION:
            return Object.assign({}, state, {
                fraction: new FractionDto(action.fraction),
            })
        case RESET_FRACTION:
            return Object.assign({}, state, {
                fraction: {},
            })
        default:
            return state
    }
}

export const store = {
    fractions: [],
    fraction: {},
}