import { Grid } from '@mui/material'
import smileyBad from '../../assets/pictures/pictos/smileyBad.png'
import i18n from 'simple-react-i18n'
import React from 'react'

const InDevelopmentBackground = () => (
    <div className='col s12 padding-top-2'>
        <div className='row no-margin'>
            <Grid container justifyContent='center' alignItems='center' spacing={3}>
                <Grid item xs={8}>
                    <img src={smileyBad} style={{
                        display: 'block',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        width: '30%',
                    }}
                    />
                </Grid>
                <Grid item xs={8}>
                    <h2 style={{ textAlign: 'center' }}>{i18n.inDeveloppmentFunctionnality}</h2>
                </Grid>
                <Grid item xs={8}>
                    <h6 style={{ textAlign: 'center', fontSize: '2rem', opacity: 0.5 }}>{'Ne soyez pas tristes ! Elle arrive très bientôt.'}</h6>
                </Grid>
            </Grid>
        </div>
    </div>
)

export default InDevelopmentBackground