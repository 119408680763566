import Icon from 'components/icon/Icon'
import { round } from 'lodash'
import moment from 'moment'
import PropTypes from 'prop-types'
import React from 'react'
import i18n from 'simple-react-i18n'
import { getFullDate, getFullDateMini } from 'utils/DateUtil'
import { hasValue } from 'utils/NumberUtil'
import { CAMPAIGNS, DESCRIPTION, EVENTS } from '../../constants/AlertConstants'
import { sieauTooltip } from 'utils/FormUtils'
import { shallowEqual, useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { STATION_TYPE_NAME } from 'station/constants/StationConstants'
import { selectedColor } from 'utils/constants/ColorTheme'

const useStyles = makeStyles(() => ({
    bold: {
        fontSize: '14px',
        fontWeight: 'bold',
    },
}))

const AlertSitePanel = ({
    site = {},
    measureDate = '',
    onClick = () => {},
    onToggle,
    onHover = () => {},
    withoutIcon = false,
    title = '',
    detailed = true,
}) => {
    const classes = useStyles()

    const {
        cardMarkerIcon = '',
        markerIcon = '',
        markerName = '',
        icon = '',
        tooltip = '',
        name = '',
        code = '',
        cityName = '',
        lastMeasure = [],
        typeName = '',
        color = 'white',
    } = site

    const cardIcon = cardMarkerIcon || markerIcon
    const lastMeasuresFiltered = lastMeasure.filter(m => hasValue(m?.value))

    const { piezometryDataTypes, pluviometryDataTypes, hydrometryDataTypes, citiesIndex } = useSelector(store => ({
        piezometryDataTypes: store.PiezometryReducer.piezometryDataTypes,
        pluviometryDataTypes: store.PluviometryReducer.pluviometryDataTypes,
        hydrometryDataTypes: store.HydrometryReducer.hydrometryDataTypes,
        citiesIndex: store.CityReducer.citiesIndex,
    }), shallowEqual)

    const getLastMeasureUnit = (type, lastMeasureValue) => {
        const units = {
            pluviometry: pluviometryDataTypes,
            hydrometry: hydrometryDataTypes,
            piezometry: piezometryDataTypes,
        }

        const typeUnit = units[type]
        const dataType = type === 'piezometry' ? -1 : parseInt(lastMeasureValue?.dataType)
        const unit = typeUnit?.find(u => parseInt(u.id) === dataType)?.unit

        return unit?.toString().replaceAll('[', '').replaceAll(']', '') ?? ''
    }

    const isContact = ['contact', 'contributor'].includes(typeName)

    const city = citiesIndex[site?.townCode]

    return (
        <Grid
            container
            sx={{ cursor: 'pointer', border: !withoutIcon && `solid 3px ${color}`, borderRadius: !withoutIcon && '5px', overflow: 'hidden' }}
            onMouseOver={() => onHover(site, true)}
            onMouseOut={() => onHover(site, false)}
        >
            <Grid
                container item xs={12}
                alignItems='center'
                onClick={() => {
                    if (onToggle) {
                        onToggle()
                    } else {
                        onClick(site, DESCRIPTION)
                    }
                }}
            >
                {!withoutIcon && (
                    <Grid container item xs={1} alignItems='center' sx={{ height: '100%', backgroundColor: color }}>
                        <Grid item xs={12} sx={{ '& img': { height: cardMarkerIcon ? '30px' : '40px' }, textAlign: 'center' }}>
                            {cardIcon.startsWith('<svg') ? (
                                <img
                                    src={`data:image/svg+xml;utf8,${cardIcon}`} {...sieauTooltip(markerName, null, 'top')}
                                />
                            ) : (
                                <img
                                    src={cardIcon} {...sieauTooltip(markerName, null, 'top')}
                                />
                            )}
                        </Grid>
                    </Grid>
                )}
                <Grid container item xs={withoutIcon ? 12 : 11}>
                    <Grid container item xs={12} sx={{ padding: '0.75rem' }}>
                        <Grid container item xs={12}>
                            {isContact && (
                                <Grid item xs={12}>
                                    {site.name ?? ''}
                                </Grid>
                            )}
                            <Grid container item xs={12}>
                                {isContact ? (
                                    <Grid item xs={12}>
                                        {`${site.road ?? ''}${site.postalCode ? `, ${site.postalCode}` : ''}${site.city ? `, ${site.city}` : ''}`}
                                    </Grid>
                                ) : (
                                    <>
                                        <Grid item xs={12} className={classes.bold}>
                                            {`${code} ${name ? `- ${name}` : ''}`}
                                        </Grid>
                                        <Grid item xs={12}>
                                            {`${cityName ? `${cityName} -` : city?.name ? `${city?.name} -` : ''} ${site.townCode ?? ''}`}
                                        </Grid>
                                    </>
                                )}
                            </Grid>
                        </Grid>
                        {!!(!isContact && detailed) && (
                            <Grid container item xs={12}>
                                {typeName === STATION_TYPE_NAME.quality ? (
                                    (site.historyStart && site.historyEnd) ? (
                                        <Grid container item xs={12}>
                                            <Grid item xs={12}>
                                                <b>{i18n.historyDate}</b>
                                            </Grid>
                                            <Grid container item xs={12}>
                                                <Grid item sx={{ paddingRight: '5px' }}>
                                                    {getFullDateMini(site.historyStart)}
                                                </Grid>
                                                <Grid item sx={{ paddingRight: '5px' }}>
                                                    {i18n.to}
                                                </Grid>
                                                <Grid item>
                                                    {getFullDateMini(site.historyEnd)}
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <b>{i18n.lastOperation2}</b>
                                            </Grid>
                                            <Grid container item xs={12}>
                                                <Grid item sx={{ paddingRight: '5px' }}>
                                                    {getFullDateMini(site.historyEnd)}
                                                </Grid>
                                                <Grid item>
                                                    {site.operationCount ?? 0} {site.operationCount && site.operationCount.length > 1 ? i18n.analyses : i18n.analysis}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    ) : (
                                        <Grid item>
                                            {i18n.noValue}
                                        </Grid>
                                    )
                                ) : (
                                    <Grid container item xs={12}>
                                        <Grid item xs={12}>
                                            {`${site.creationDate ? `${moment(site.creationDate).year()} -` : ''} ${site.measureDate?.year() ?? ''}`}
                                        </Grid>
                                        {lastMeasuresFiltered.length ? lastMeasuresFiltered.map(m => (
                                            <Grid container item xs={12}>
                                                {(m.title || title) && (
                                                    <Grid item sx={{ paddingRight: '5px' }}>
                                                        {m.title || title} :
                                                    </Grid>
                                                )}
                                                <Grid item sx={{ paddingRight: '5px' }}>
                                                    {getFullDate(moment(m.measureDate || m.date)) || measureDate} -
                                                </Grid>
                                                <Grid item>
                                                    {`${round(m.value, 3)} ${m?.unit || getLastMeasureUnit(typeName, m)}`}
                                                </Grid>
                                            </Grid>
                                        )) : (
                                            <Grid item xs={12}>
                                                {i18n.noValue}
                                            </Grid>
                                        )}
                                    </Grid>
                                )}
                            </Grid>
                        )}
                    </Grid>
                    {!!detailed && (
                        <Grid container item xs={12}>
                            {!!icon.leftIcon && (
                                <Grid container item xs={12} sx={{ padding: '0 10px 2px' }}>
                                    <Grid item>
                                        <Icon
                                            size={20}
                                            icon={icon.leftIcon}
                                            tooltip={tooltip}
                                            sx={{ color: icon.leftIconColor }}
                                            onClick={icon.leftIconClick}
                                        />
                                    </Grid>
                                </Grid>
                            )}
                            <Grid container item xs={12} justifyContent='flex-end' sx={{ backgroundColor: selectedColor, padding: '2px 10px', marginTop: icon.leftIcon && '0.5rem', gap: '0.5rem' }}>
                                {hasValue(site.jobExecutionId) && (
                                    <Grid item>
                                        <Icon
                                            size={20}
                                            icon='wifi'
                                            tooltip={i18n.openData}
                                        />
                                    </Grid>
                                )}
                                {site.bookmarked && (
                                    <Grid item>
                                        <Icon
                                            size={20}
                                            icon='grade'
                                            tooltip={i18n.bookmark}
                                        />
                                    </Grid>
                                )}
                                {(site.currentComments || site.currentMonitoring) && (
                                    <Grid item>
                                        <Icon
                                            size={20}
                                            icon='event'
                                            onClick={() => onClick(site, EVENTS, 'date')}
                                            tooltip={i18n.currentEvents}
                                        />
                                    </Grid>
                                )}
                                {hasValue(site.currentMonitoring) && (
                                    <Grid item>
                                        <Icon
                                            size={20}
                                            icon='notifications'
                                            onClick={() => onClick(site, EVENTS, 'eventType')}
                                            tooltip={i18n.oversight}
                                        />
                                    </Grid>
                                )}
                                {hasValue(site.currentCampaign) && (
                                    <Grid item>
                                        <Icon
                                            size={20}
                                            icon='nature_people'
                                            onClick={() => onClick(site, CAMPAIGNS)}
                                            tooltip={i18n.currentCampaign}
                                        />
                                    </Grid>
                                )}
                                <Grid item>
                                    <Icon
                                        size={20}
                                        icon='info'
                                        onClick={() => onClick(site, DESCRIPTION)}
                                        tooltip={i18n.description}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    )}
                </Grid>
            </Grid>
        </Grid>
    )
}

AlertSitePanel.propTypes = {
    measureDate: PropTypes.string,
    onClick: PropTypes.func,
    onToggle: PropTypes.func,
    site: PropTypes.shape({}),
    onHover: PropTypes.func,
    message: PropTypes.string,
    withoutIcon: PropTypes.boolean,
    unit: PropTypes.string,
    title: PropTypes.string,
    detailed: PropTypes.boolean,
}

export default AlertSitePanel