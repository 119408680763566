/* eslint-disable camelcase */
import { Button, Dialog, DialogActions, DialogContent } from '@mui/material'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import SelectionTable from '../../../../components/datatable/SelectionTable'
import Input from '../../../../components/forms/Input'
import { searchAllCharacters } from '../../../../utils/StringUtil'
import CultureAction from '../../cultures/actions/CultureAction'
import DtoCulture from '../../cultures/dto/DtoCulture'
import DtoCulturesFamily from '../dto/DtoCulturesFamily'

const headers = ['id', 'family', 'name']

class SelectCulturesModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            selectedCultures: [],
            searchValue: '',
        }
    }

    componentDidMount() {
        const { cultures } = this.props
        if (!cultures.length) {
            this.props.fetchCultures().then(() => {
                this.setSelectedCultures()
            })
        } else {
            this.setSelectedCultures()
        }
    }

    setSelectedCultures = () => {
        const { selectedIds, culturesFamilies, cultures } = this.props
        const selectedCultures = cultures.filter((c) => selectedIds.includes(c.id)).map((c) => {
            const family = culturesFamilies.find(({ id }) => id === c.idFamille) || {}
            return {
                id: c.id,
                family: family.name,
                name: c.name,
            }
        })
        this.setState({ selectedCultures })
    }

    addCulture = (culture) => {
        this.setState(({ selectedCultures }) => ({
            selectedCultures: [
                ...selectedCultures,
                culture,
            ],
        }))
    }

    removeCulture = (culture) => {
        this.setState(({ selectedCultures }) => ({
            selectedCultures: [
                ...selectedCultures.filter((c) => c.id !== culture.id),
            ],
        }))
    }

    getFilteredData = (data) => {
        const { searchValue } = this.state
        const includesValue = searchAllCharacters(searchValue || '')
        return data.filter(i => this.getHash(i).includes(includesValue))
    }

    getHash = (culture) => {
        return searchAllCharacters(headers.map(key => culture[key]))
    }

    getCultures = () => {
        const { cultures, culturesFamilies } = this.props
        const { selectedCultures } = this.state
        const filteredCultures = this.getFilteredData(cultures.filter((c) => !selectedCultures.find((sC) => c.id === sC.id)))
        return filteredCultures.map((c) => {
            const family = culturesFamilies.find(({ id }) => id === c.idFamille) || {}
            return {
                id: c.id,
                family: family.name,
                name: c.name,
            }
        })
    }

    getSelectedCultures = () => {
        const { selectedCultures } = this.state
        return this.getFilteredData(selectedCultures)
    }

    onCancel = () => {
        this.setSelectedCultures()
        this.setState({ searchValue: '' })
        this.props.onCancel()
    }

    onValidate = () => {
        const { selectedCultures } = this.state
        this.setState({ searchValue: '' })
        this.props.onValidate(selectedCultures)
    }

    render() {
        const { open } = this.props
        const { searchValue } = this.state

        const listData = this.getCultures()
        const selectedData = this.getSelectedCultures()

        return (
            <Dialog
                fullWidth
                maxWidth='lg'
                open={open}
            >
                <DialogContent>
                    <div className='row padding-top-1 no-margin'>
                        <div className='col s4'>
                            <Input title={i18n.search} value={searchValue}
                                onChange={(value) => this.setState({ searchValue: value })}
                            />
                        </div>
                    </div>
                    <SelectionTable
                        listData={listData}
                        listHeaders={headers}
                        listTitle={listData.length > 1 ? i18n.cultures : i18n.culture}
                        selectedData={selectedData}
                        selectedHeaders={headers}
                        selectedTitle={selectedData.length > 1 ? i18n.selectedCultures : i18n.selectedCulture}
                        onAdd={this.addCulture}
                        onDelete={this.removeCulture}
                        maxHeight='35vh'
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.onCancel} variant='outlined' >
                        {i18n.cancel}
                    </Button>
                    <Button onClick={this.onValidate} variant='contained' color='primary'>
                        {i18n.validate}
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}

SelectCulturesModal.propTypes = {
    cultures: PropTypes.arrayOf(PropTypes.instanceOf(DtoCulture)),
    culturesFamilies: PropTypes.arrayOf(PropTypes.instanceOf(DtoCulturesFamily)),
    selectedIds: PropTypes.arrayOf(PropTypes.number),
    onValidate: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    fetchCultures: PropTypes.func,
}

const mapStateToProps = (store) => {
    return {
        cultures: store.CultureReducer.cultures,
        culturesFamilies: store.CulturesFamilyReducer.culturesFamilies,
    }
}

const mapDispatchToProps = {
    fetchCultures: CultureAction.fetchCultures,
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectCulturesModal)
