module.exports = {
    parametresCSV: [
        { field: 'piezoId', type: 'int', file: 'general', default: 1 },
        { field: 'pluvioId', type: 'int', file: 'general', default: 3 },
        { field: 'ratioPrel', type: 'int', file: 'general', default: 100 },
        { field: 'tetafc', type: 'float', file: 'parametres.csv', default: 0.298 },
        { field: 'tetawp', type: 'float', file: 'parametres.csv', default: 0.12 },
        { field: 'pmax', type: 'float', file: 'parametres.csv', default: 1 },
        { field: 'kru', type: 'float', file: 'parametres.csv', default: 0.75 },
        { field: 'res0', type: 'float', file: 'parametres.csv', default: 298 },
        { field: 'ksol', type: 'float', file: 'parametres.csv', default: 0.3 },
        { field: 'tbase', type: 'float', file: 'parametres.csv', default: 8 },
        { field: 'tinstal', type: 'float', file: 'parametres.csv', default: 135 },
        { field: 'temerg', type: 'float', file: 'parametres.csv', default: 100 },
        { field: 'ts1', type: 'float', file: 'parametres.csv', default: 950 },
        { field: 'ts2', type: 'float', file: 'parametres.csv', default: 1580 },
        { field: 'tmaxlai', type: 'float', file: 'parametres.csv', default: 1265 },
        { field: 'tmat', type: 'float', file: 'parametres.csv', default: 1950 },
        { field: 'vroot', type: 'float', file: 'parametres.csv', default: 0.016 },
        { field: 'kcmax', type: 'float', file: 'parametres.csv', default: 1.2 },
        { field: 'laimax', type: 'float', file: 'parametres.csv', default: 5 },
        { field: 'rue', type: 'float', file: 'parametres.csv', default: 1.35 },
        { field: 'hipot', type: 'float', file: 'parametres.csv', default: 0.5 },
        { field: 'estresslai', type: 'float', file: 'parametres.csv', default: 1.25 },
        { field: 'estresstdm', type: 'float', file: 'parametres.csv', default: 1.25 },
        { field: 'alpha1', type: 'float', file: 'parametres.csv', default: 4 },
        { field: 'alpha2', type: 'float', file: 'parametres.csv', default: 1 },
        { field: 'beta', type: 'float', file: 'parametres.csv', default: 2.5 },
        { field: 'dens', type: 'float', file: 'parametres.csv', default: 8.25 },
        { field: 'densopt', type: 'float', file: 'parametres.csv', default: 8.3 },
        { field: 'laiopt', type: 'float', file: 'parametres.csv', default: 3 },
        { field: 'penhi', type: 'float', file: 'parametres.csv', default: 0.12 },
        { field: 'humgrec', type: 'float', file: 'parametres.csv', default: 30 },
        { field: 'jsem', type: 'date', file: 'parametres.csv', default: 1618783200000 },
        { field: 'jrecol', type: 'date', file: 'parametres.csv', default: 1640991600000 },
        { field: 'mulch', type: 'float', file: 'parametres.csv', default: 0 },
        { field: 'gge', type: 'float', file: 'parametres.csv', default: 0 },
        { field: 'itest', type: 'float', file: 'parametres.csv', default: 0 },
        { field: 'seuilRU', type: 'float', file: 'parametres.csv', default: 70 },
        { field: 'dosap', type: 'float', file: 'parametres.csv', default: 0 },
        { field: 'dosem', type: 'float', file: 'parametres.csv', default: 0 },
        { field: 'jdir', type: 'date', file: 'parametres.csv', default: 1609455600000 },
        { field: 'jfir', type: 'date', file: 'parametres.csv', default: 1640991600000 },
        { field: 'quota', type: 'float', file: 'parametres.csv', default: 500 },
        { field: 'f1fix', type: 'float', file: 'parametres.csv', default: 0.1 },
        { field: 'f2varirr', type: 'float', file: 'parametres.csv', default: 0.08 },
        { field: 'f2varnrj', type: 'float', file: 'parametres.csv', default: 0.5 },
        { field: 'f3vente', type: 'float', file: 'parametres.csv', default: 0.17 },
        { field: 'obsRES', type: 'text', file: 'parametres.csv', default: '' },
        { field: 'obsLAI', type: 'text', file: 'parametres.csv', default: '' },
        { field: 'obsTDM', type: 'text', file: 'parametres.csv', default: '' },
        { field: 'obsY', type: 'float', file: 'parametres.csv', default: 13.7 },
        { field: 'LAI0', type: 'text', file: 'parametres.csv', default: '' },
        { field: 'FITRES1', type: 'text', file: 'parametres.csv', default: '' },
        { field: 'FITRES2', type: 'text', file: 'parametres.csv', default: '' },
        { field: 'FITRES3', type: 'text', file: 'parametres.csv', default: '' },
        { field: 'inc_Tr', type: 'text', file: 'parametres.csv', default: 'per_Tr_inc.csv' },
        { field: 'inc_d', type: 'text', file: 'parametres.csv', default: 'per_d_inc.csv' },
        { field: 'inc_a', type: 'text', file: 'parametres.csv', default: 'per_a_inc.csv' },
        { field: 'psys_Trda', type: 'text', file: 'parametres.csv', default: 'per_sysCE.csv' },
        { field: 'ips1', type: 'float', file: 'parametres.csv', default: 180 },
        { field: 'ips2', type: 'float', file: 'parametres.csv', default: 250 },
        { field: 'ndays', type: 'float', file: 'irrigmaker.txt', default: 7 }, // irigmaker.txt
        { field: 'dose', type: 'float', file: 'irrigmaker.txt', default: 30 },
        { field: 'skipif', type: 'float', file: 'irrigmaker.txt', default: 20 },
        { field: 'quota', type: 'float', file: 'irrigmaker.txt', default: 300 },
        { field: 'tcr', type: 'float', file: 'irrigmaker.txt', default: 20 },
        { field: 'vfod', type: 'float', file: 'irrigmaker.txt', default: 5 },
        { field: 'horizon', type: 'float', file: 'irrigmaker.txt', default: 4 },
        { field: 'enoughtR', type: 'float', file: 'irrigmaker.txt', default: 15 },
        { field: 'PE', type: 'float', file: 'param_EE.csv', default: 0.08 }, // param_EE
        { field: 'Pvente', type: 'float', file: 'param_EE.csv', default: 170 },
        { field: 'Pnrj', type: 'float', file: 'param_EE.csv', default: 0.5 },
        { field: 'Cfixe', type: 'float', file: 'param_EE.csv', default: 100 },
        { field: 'MN', type: 'float', file: 'param_EE.csv', default: 1.5 },
        { field: 'MA', type: 'float', file: 'param_EE.csv', default: 2 },
        { field: 'MC', type: 'float', file: 'param_EE.csv', default: 3 },
        { field: 'infDD1', type: 'float', file: 'param_EE.csv', default: 1 },
        { field: 'infDD2', type: 'float', file: 'param_EE.csv', default: 1 },
        { field: 'DD1', type: 'float', file: 'param_EE.csv', default: 7 },
        { field: 'DD2', type: 'float', file: 'param_EE.csv', default: 15 },
        { field: 'SeuilRU', type: 'float', file: 'param_EE.csv', default: 60 },
        { field: 'P0', type: 'float', file: 'param_EE.csv', default: 20 },
        { field: 'n0', type: 'float', file: 'param_EE.csv', default: 4 },
    ],
}