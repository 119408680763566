import Checkbox from 'components/forms/Checkbox'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import NumberField from '../../../../components/forms/NumberField'
import Select from '../../../../components/forms/Select'
import ContributorDto from '../../../../referencial/components/contributor/dto/ContributorDto'
import PowerSupplyTypeDto from '../dto/PowerSupplyTypeDto'

const PowerSupplyTypePanel = ({
    materielType = {},
    contributors = [],
    onChange = () => {},
    showContributorField = true,
    disabled = false,
}) => (
    <div>
        {
            showContributorField && (
                <div className='row no-margin'>
                    <Select
                        col={6}
                        options={contributors}
                        label={i18n.provider}
                        value={materielType.provider}
                        onChange={v => onChange({ provider: v })}
                        keyValue='id'
                        keyLabel='mnemonique'
                        disabled={disabled}
                    />
                    <Select
                        col={6}
                        options={contributors}
                        label={i18n.manufacturer}
                        value={materielType.manufacturerId}
                        onChange={v => onChange({ manufacturerId: v })}
                        keyValue='id'
                        keyLabel='mnemonique'
                        disabled={disabled}
                    />
                </div>
            )
        }
        <div className='row no-margin'>
            <NumberField
                col={6}
                title={`${i18n.lifeTime} (${i18n.month})`}
                value={materielType.lifeTime}
                onChange={v => onChange({ lifeTime: v })}
                disabled={disabled}
            />
            <NumberField
                col={6}
                title={`${i18n.voltage} (V)`}
                value={materielType.voltage}
                onChange={v => onChange({ voltage: v })}
                disabled={disabled}
            />
        </div>
        <div className='row no-margin padding-bottom-1'>
            <Checkbox
                col={12}
                label={i18n.noReplaceable}
                checked={materielType.isNotReplaceable}
                onChange={v => onChange({ isNotReplaceable: v })}
                disabled={disabled}
            />
        </div>
    </div>
)

PowerSupplyTypePanel.propTypes = {
    materielType: PropTypes.instanceOf(PowerSupplyTypeDto),
    onChange: PropTypes.func,
    showContributorField: PropTypes.bool,
    disabled: PropTypes.bool,
    contributors: PropTypes.arrayOf(PropTypes.instanceOf(ContributorDto)),
}

const mapStateToProps = store => ({
    contributors: store.ContributorReducer.contributors,
})

export default connect(mapStateToProps)(PowerSupplyTypePanel)