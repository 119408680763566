import { Button, Card, CardContent, Dialog, DialogActions, DialogContent, Grid2 } from '@mui/material'
import { CardTable } from 'components/datatable/NewTable'
import Select from 'components/forms/Select'
import SimpleDatePicker from 'components/forms/SimpleDatePicker'
import Textarea from 'components/forms/Textarea'
import { DefaultDialogTitle } from 'components/styled/Dialog'
import { orderBy } from 'lodash'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { nbPerPageLabel } from 'referencial/constants/ReferencialConstants'
import i18n from 'simple-react-i18n'
import { getDate } from 'utils/DateUtil'
import { exportFile } from 'utils/ExportDataUtil'
import { hasValue } from 'utils/NumberUtil'
import { getLabel, getSandreList } from 'utils/StoreUtils'
import { getSandreLabel, substringText } from 'utils/StringUtil'

const CatchmentSDAGETable = ({
    sdages = [],
    setCatchment = () => { },
    readMode = true,
}) => {
    const {
        contributors,
        catchment,
        sandreCodes,
    } = useSelector(store => ({
        contributors: store.ContributorReducer.contributors,
        catchment: store.CatchmentReducer.catchment,
        sandreCodes: store.ReferencialReducer.sandreCodes,
    }), shallowEqual)

    const [isOpen, setIsOpen] = useState(false)

    const [startDate, setStartDate] = useState()
    const [endDate, setEndDate] = useState()
    const [codeSDAGE, setCodeSDAGE] = useState(0)
    const [comment, setComment] = useState()
    const [contributor, setContributor] = useState()
    const [index, setIndex] = useState()

    const sdageList = getSandreList(sandreCodes, 'CAPTAGES.SDAGE')

    const sdagesFormated = orderBy(sdages.map((sdage, i) => {
        const sdageLabel = getSandreLabel(sandreCodes, 'CAPTAGES.SDAGE', sdage.codeSDAGE)
        return {
            sdage: { value: sdageLabel },
            startDate: { value: getDate(sdage.startDate) },
            endDate: { value: getDate(sdage.endDate) },
            contributor: { value: getLabel(contributors, sdage.contributor) },
            comment: { value: substringText(sdage.comment, 50) },
            startDateTimestamp: sdage.startDate,
            endDateTimestamp: sdage.endDate,
            codeSDAGE: sdage.codeSDAGE,
            commentFull: sdage.comment,
            contributorCode: sdage.contributor,
            index: i,
        }
    }), 'startDateTimestamp', 'desc')

    const exportAction = {
        icon: 'file_download',
        iconName: 'file_download',
        tooltip: i18n.export,
        onClick: () => {
            const exportDataFormated = orderBy(sdages, 'startDate', 'desc').map(sdage => {
                const sdageLabel = getSandreLabel(sandreCodes, 'CAPTAGES.SDAGE', sdage.codeSDAGE)
                return {
                    sdage: sdageLabel,
                    startDate: { value: getDate(sdage.startDate), format: 'dd/MM/yyyy', cellType: 'date' },
                    endDate: { value: getDate(sdage.endDate), format: 'dd/MM/yyyy', cellType: 'date' },
                    contributor: getLabel(contributors, sdage.contributor),
                    comment: sdage.comment,
                }
            })
            const exportData = exportDataFormated.length ? [{ ...exportDataFormated[0], headers: ['sdage', 'startDate', 'endDate', 'contributor', 'comment'] }, ...exportDataFormated.slice(1)] : []
            exportFile({
                data: exportData,
                exportType: 'xlsx',
                titleFile: `${catchment.code || catchment.name || catchment.aacNum || ''}_${i18n.sdage}`,
            })
        },
    }

    const actions = [exportAction, {
        iconName: 'note_add',
        icon: 'note_add',
        tooltip: i18n.add,
        onClick: () => setIsOpen(true),
        displayed: !readMode,
        'data-cy': 'new_sdage',
    }]

    const onClose = () => {
        setIsOpen(false)
        setStartDate(undefined)
        setEndDate(undefined)
        setCodeSDAGE(0)
        setComment(undefined)
        setContributor(undefined)
        setIndex(undefined)
    }

    const onValidate = () => {
        const newSdage = {
            startDate,
            endDate,
            codeSDAGE,
            comment,
            contributor,
        }
        if (hasValue(index)) {
            setCatchment(prev => ({ ...prev, sdages: [...sdages.slice(0, index), newSdage, ...sdages.slice(index + 1)] }))
        } else {
            setCatchment(prev => ({ ...prev, sdages: [...sdages, newSdage] }))
        }
        onClose()
    }

    const onDelete = (s) => setCatchment(prev => ({ ...prev, sdages: [...sdages.slice(0, s.index), ...sdages.slice(s.index + 1)] }))
    const onEdit = (s) => {
        setStartDate(s.startDateTimestamp)
        setEndDate(s.endDateTimestamp)
        setCodeSDAGE(s.codeSDAGE)
        setComment(s.commentFull)
        setContributor(s.contributorCode)
        setIndex(s.index)
        setIsOpen(true)
    }

    return (
        <>
            <CardTable
                title={i18n.sdage}
                actions={actions}

                rows={sdagesFormated}
                headers={['sdage', 'startDate', 'endDate', 'contributor', 'comment']}
                lineActions={[
                    { icon: 'edit', onClick: onEdit, displayed: !readMode },
                    { icon: 'delete', onClick: onDelete, displayed: !readMode },
                ]}

                rowsPerPageOptions={nbPerPageLabel}
            />
            <Dialog
                maxWidth='lg'
                onClose={onClose}
                open={isOpen}
            >
                <DefaultDialogTitle
                    title={i18n.sdage}
                    onClose={onClose}
                />
                <DialogContent>
                    <Card>
                        <CardContent>
                            <Grid2 container spacing={1} alignItems='center'>
                                <Grid2 size={3}>
                                    <Select
                                        options={sdageList}
                                        label={i18n.sdage}
                                        value={codeSDAGE}
                                        noSort
                                        onChange={setCodeSDAGE}
                                        noNullValue
                                        data-cy='codeSDAGE'
                                    />
                                </Grid2>
                                <Grid2 size={3}>
                                    <SimpleDatePicker
                                        value={startDate}
                                        id='startDate'
                                        label={i18n.beginDate}
                                        onChange={setStartDate}
                                        data-cy='startDate'
                                    />
                                </Grid2>
                                <Grid2 size={3}>
                                    <SimpleDatePicker
                                        value={endDate}
                                        id='endDate'
                                        label={i18n.endDate}
                                        onChange={setEndDate}
                                        data-cy='endDate'
                                    />
                                </Grid2>
                                <Grid2 size={3}>
                                    <Select
                                        options={contributors}
                                        label={i18n.contributor}
                                        nullLabel='&nbsp;'
                                        onChange={setContributor}
                                        value={contributor}
                                        data-cy='contributor'
                                    />
                                </Grid2>
                                <Grid2 size={12}>
                                    <Textarea
                                        title={i18n.comment}
                                        value={comment}
                                        onChange={setComment}
                                        data-cy='sdage_comment'
                                    />
                                </Grid2>
                            </Grid2>
                        </CardContent>
                    </Card>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onValidate} variant='contained' data-cy='validate_sdage'>
                        {i18n.validate}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

CatchmentSDAGETable.propTypes = {
    sdages: PropTypes.arrayOf(PropTypes.shape({})),
    setCatchment: PropTypes.func,
    readMode: PropTypes.bool,
}

export default CatchmentSDAGETable