import DtoLocation from './DtoLocation'
import DtoAltimetrySystem from './DtoAltimetrySystem'
import DtoQualitometerLandmark from './DtoQualitometerLandmark'
import DtoQualitometerAquifer from './DtoQualitometerAquifer'

export default class DtoStation {
    constructor(obj) {
        this.id = obj.id
        this.name = obj.name
        this.code = obj.code
        this.comment = obj.comment
        this.creationDate = obj.creation
        this.creation = obj.creation
        this.close = obj.close
        this.type = obj.type
        this.stationType = obj.stationType || '0'
        this.typeName = 'quality'
        this.location = obj.location
        this.nature = obj.naturex
        this.designation = obj.designation
        this.localisation = new DtoLocation(obj, obj.altitude)
        this.x = obj.x
        this.y = obj.y
        this.altitude = obj.altitude
        this.projection = obj.projection
        this.contactCode = obj.contactCode
        this.qualitometerType = obj.qualitometerType
        this.waterSourceType = obj.waterSourceType
        this.previsionalVisitNumber = obj.previsionalVisitNumber
        this.townCode = obj.townCode
        this.fieldMode = obj.fieldMode
        this.stateCode = obj.stateCode
        this.finality = obj.finality
        this.qualityCode = obj.qualityCode
        this.address = obj.address
        this.representativenessCode = obj.representativenessCode
        this.contributors = obj.contributors ? obj.contributors.map((c) => ({
            id: c.idContributor,
            type: c.contributorType,
            endDate: c.endDate,
        })) : []
        this.updateLogin = obj.updateLogin
        this.updateDate = obj.updateDate

        if (obj.qualitometerAltimetrySystem) {
            this.qualitometerAltimetrySystem = obj.qualitometerAltimetrySystem.map((o) => {
                return new DtoAltimetrySystem(o)
            })
        } else {
            this.qualitometerAltimetrySystem = []
        }
        if (obj.qualitometerLandmarks) {
            this.qualitometerLandmarks = obj.qualitometerLandmarks.map((o) => {
                return new DtoQualitometerLandmark(o)
            })
        } else {
            this.qualitometerLandmarks = []
        }
        if (obj.qualitometerAquiferLink) {
            this.qualitometerAquiferLink = obj.qualitometerAquiferLink.map((o) => {
                return new DtoQualitometerAquifer(o)
            })
        } else {
            this.qualitometerAquiferLink = []
        }
        this.hydrogeologicalEntities = obj.hydrogeologicalEntities || (obj.qualitometerHydrologicalEntityLink ? obj.qualitometerHydrologicalEntityLink.map(link => link.idHydrologicalEntity) : [])
    }
}