import Tabs from 'components/Tabs'
import React, { useEffect } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import useTitle from 'utils/customHook/useTitle'
import { getStationTitle } from 'utils/StationUtils'
import DevelopmentApp from 'inDevelop/components/DevelopmentApp'
import HydrobioOperationDescription from './HydrobioOperationDescription'
import { getDate } from 'utils/DateUtil'
import { getLabel } from 'utils/StoreUtils'
import OperationAction from '../../operation/actions/OperationAction'
import MethodAction from 'referencial/components/methods/actions/MethodAction'
import QualityAction from 'quality/actions/QualityAction'
import SupportAction from 'referencial/components/support/actions/SupportAction'
import EnvironmentalConditionsPanel from '../../operation/components/environmentalCondition/EnvironmentalConditionsPanel'
import TaxonAction from 'referencial/components/taxon/actions/TaxonAction'
import useSandreList from 'utils/customHook/useSandreList'
import HydrobioOperationFaunalList from './HydrobioOperationFaunalList'
import HydrobioOperationFloristicList from './HydrobioOperationFloristicList'
import { useParams } from 'react-router'
import OperationHydrobioPressuresPanel from './OperationHydrobioPressuresPanel'
import OperationIndicesPanel from 'quality/components/operation/components/indices/OperationIndicesPanel'

const DESCRIPTION = 'DESCRIPTION'
const FACIES = 'FACIES'
const MICRO_SAMPLE = 'MICRO_SAMPLE'
const FAUNAL_LIST = 'FAUNAL_LIST'
const FLORISTIC_LIST = 'FLORISTIC_LIST'
const INDICES = 'INDICES'
const PRESSURES = 'PRESSURES'
const ENVIRONMENTAL_CONDITIONS = 'ENVIRONMENTAL_CONDITIONS'

const HydrobioOperationApp = () => {
    const dispatch = useDispatch()
    const { id: idStation, idOperation } = useParams()

    const {
        qualitometer,
        supports,
        hydrobioOperation,
    } = useSelector(store => ({
        qualitometer: store.QualityReducer.qualitometer,
        supports: store.SupportReducer.supports,
        hydrobioOperation: store.OperationReducer.hydrobioOperation,
    }), shallowEqual)

    const supportFaunal = useSandreList('HYDROBIO_SUPPORT_FAUNISTIQUE')
    const supportFloristic = useSandreList('HYDROBIO_SUPPORT_FLORISTIQUE')

    useTitle(() => [{
        title: i18n.quality,
        href: 'quality',
    }, {
        title: getStationTitle(qualitometer),
        href: `station/quality/${idStation}`,
    }, {
        title: i18n.hydrobioOperations,
        href: `station/quality/${idStation}/hydrobioOperation`,
    }, {
        title: idOperation === 'new' ? idOperation : `${i18n.operation} : ${getDate(hydrobioOperation.date)} - ${getLabel(supports, hydrobioOperation.support)}`,
        href: `station/quality/${idStation}/hydrobioOperation/${idOperation}`,
    }], [qualitometer, hydrobioOperation, supports])

    useEffect(() => {
        dispatch(MethodAction.fetchMethods())
        dispatch(QualityAction.fetchStatus())
        dispatch(QualityAction.fetchQualifications())
        dispatch(SupportAction.fetchSupports())
        dispatch(TaxonAction.fetchTaxons())
        dispatch(OperationAction.fetchRemarks())
    }, [])

    useEffect(() => {
        dispatch(OperationAction.fetchQualitometerHydrobioOperation(idStation, idOperation))
        dispatch(OperationAction.fetchHydrobioList(idStation, idOperation))
        dispatch(OperationAction.fetchEnvironmentalConditions(idStation, idOperation))
        dispatch(OperationAction.fetchIndices(idStation, idOperation))
    }, [idStation, idOperation])

    return (
        <div style={{ padding: '10 10 100 20' }}>
            <Tabs
                defaultTab={DESCRIPTION}
                tabs={[
                    {
                        constant: DESCRIPTION,
                        label: i18n.description,
                    },
                    {
                        constant: FACIES,
                        label: i18n.facies, // label à changer
                    },
                    {
                        constant: MICRO_SAMPLE,
                        label: i18n.microSampling,
                        displayed: !!hydrobioOperation.microSampling,
                    },
                    {
                        constant: FAUNAL_LIST,
                        label: i18n.faunalList,
                        displayed: supportFaunal.some(s => s.name === `${hydrobioOperation.support}`),
                    },
                    {
                        constant: FLORISTIC_LIST,
                        label: i18n.floristicList,
                        displayed: supportFloristic.some(s => s.name === `${hydrobioOperation.support}`),
                    },
                    {
                        constant: INDICES,
                        label: i18n.indices,
                    },
                    {
                        constant: PRESSURES,
                        label: i18n.pressures,
                    },
                    {
                        constant: ENVIRONMENTAL_CONDITIONS,
                        label: i18n.environmentalConditions,
                    },
                ]}
            >
                {tab => (
                    <>
                        {tab === DESCRIPTION && <HydrobioOperationDescription idStation={idStation} idOperation={idOperation} />}
                        {tab === FACIES && <DevelopmentApp />}
                        {tab === MICRO_SAMPLE && <DevelopmentApp />}
                        {tab === FAUNAL_LIST && <HydrobioOperationFaunalList />}
                        {tab === FLORISTIC_LIST && <HydrobioOperationFloristicList />}
                        {tab === INDICES && (
                            <OperationIndicesPanel
                                qualitometerId={hydrobioOperation.qualitometer}
                                operationId={hydrobioOperation.id}
                                support={hydrobioOperation.support}
                            />
                        )}
                        {tab === PRESSURES && <OperationHydrobioPressuresPanel />}
                        {tab === ENVIRONMENTAL_CONDITIONS && <EnvironmentalConditionsPanel operation={hydrobioOperation}/>}
                    </>
                )}
            </Tabs>
        </div>
    )
}

export default HydrobioOperationApp