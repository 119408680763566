import DtoMaterielSituation from '../../../dto/DtoMaterielSituation'

export default class DtoTelecomSituation extends DtoMaterielSituation {
    constructor(obj) {
        super(obj)
        this.id = obj.idTelecomSituation
        this.idTelecom = obj.idTelecom
        this.materielId = obj.idTelecom
    }
}
