import AdministrationAction from 'administration/actions/AdministrationAction'
import { LIST, MAP } from 'catchment/constants/CatchmentConstant'
import Input from 'components/forms/Input'
import Select from 'components/forms/Select'
import SieauAction from 'components/sieau/SieauAction'
import { push } from '@lagunovsky/redux-react-router'
import PropTypes from 'prop-types'
import React, { useMemo, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { nbPerPageLabel } from 'referencial/constants/ReferencialConstants'
import i18n from 'simple-react-i18n'
import useActions from 'utils/customHook/useActions'
import useTitle from 'utils/customHook/useTitle'
import { hasValue } from 'utils/NumberUtil'
import { getLabel } from 'utils/StoreUtils'
import { searchAllCharacters } from 'utils/StringUtil'
import CatchmentPointsMap from '../CatchmentPointsMap'
import useSandreList from 'utils/customHook/useSandreList'
import Checkbox from 'components/forms/Checkbox'
import { STATION_TYPE_NAME } from 'station/constants/StationConstants'
import SimpleFilterSelect from 'components/forms/specific/SimpleFilterSelect'
import { Button, Card, CardContent, Grid2 } from '@mui/material'
import { CardTable, sortRows } from 'components/datatable/NewTable'
import SimpleTabList from 'components/list/SimpleTabList'
import StarRoundedIcon from '@mui/icons-material/StarRounded'
import { favOrange } from 'utils/constants/ColorTheme'

const FilterFields = ({
    defaultFilters = {},
    onValidate = () => {},
}) => {
    const dispatch = useDispatch()
    const {
        catchments,
    } = useSelector(store => ({
        catchments: store.CatchmentReducer.catchments,
    }), shallowEqual)

    const sdages = useSandreList('CAPTAGES.SDAGE')
    const [searchValue, setSearchValue] = useState(defaultFilters.searchValue)
    const [sdage, setSDAGE] = useState(defaultFilters.sdage)
    const [displayActiveSDAGE, setdisplayActiveSDAGE] = useState(defaultFilters.displayActiveSDAGE)
    const [filter, setFilter] = useState(defaultFilters.filter)
    const [resultFilter, setResultFilter] = useState(defaultFilters.resultFilter)

    const onSearch = () => {
        onValidate({
            searchValue,
            sdage,
            displayActiveSDAGE,
            filter,
            resultFilter,
        })
        dispatch(AdministrationAction.setSelectedSearchValues('catchment', { searchValue }))
    }

    return (
        <Card>
            <CardContent noPadding>
                <Grid2 container spacing={1} alignItems='center' padding={'10px'}>
                    <Grid2 size={2}>
                        <Input
                            tooltip={i18n.searchThenEnter}
                            title={i18n.search}
                            value={searchValue}
                            onChange={setSearchValue}
                            onEnterKeyPress={onSearch}
                            clearFunction
                            data-cy='search'
                        />
                    </Grid2>
                    <Grid2 size={2}>
                        <SimpleFilterSelect
                            stationType={STATION_TYPE_NAME.catchment}
                            onChange={(results, newFilter) => {
                                setFilter(newFilter)
                                setResultFilter(results)
                            }}
                            stations={catchments}
                            data-cy='filter'
                        />
                    </Grid2>
                    <Grid2 size={2}>
                        <Select
                            options={sdages}
                            label={i18n.sdage}
                            nullLabel='&nbsp;'
                            value={sdage}
                            noSort
                            onChange={setSDAGE}
                            data-cy='sdage'
                        />
                    </Grid2>
                    <Grid2 size='auto'>
                        <Checkbox
                            label={i18n.displayActiveSDAGE}
                            checked={displayActiveSDAGE}
                            onChange={setdisplayActiveSDAGE}
                            data-cy='activeSDAGE'
                        />
                    </Grid2>
                    <Grid2 size='grow' />
                    <Grid2 size='auto'>
                        <Button
                            variant='contained'
                            onClick={onSearch}
                            data-cy='submit'
                        >
                            {i18n.search}
                        </Button>
                    </Grid2>
                </Grid2>
            </CardContent>
        </Card>
    )
}

FilterFields.propTypes = {
    defaultFilters: PropTypes.shape({}),
    onValidate: PropTypes.func,
}

const CatchmentTable = ({
    catchments = [],
}) => {
    const dispatch = useDispatch()
    const {
        catchmentPoints,
        contributors,
        departments,
        selectedSearchValues,
        userBookmarks,
    } = useSelector(store => ({
        catchmentPoints: store.CatchmentReducer.catchmentPoints,
        contributors: store.ContributorReducer.contributors,
        departments: store.CityReducer.departments,
        selectedSearchValues: store.AdministrationReducer.selectedSearchValues,
        userBookmarks: store.UserReducer.userBookmarks,
    }), shallowEqual)

    const catchmentBookmarks = useMemo(() => {
        return userBookmarks.filter(b => b.stationType === 11).map(b => b.identifiant)
    }, [userBookmarks])

    const catchmentFormated = catchments.map(catchment => {
        const points = catchmentPoints.filter(p => p.id === catchment.id)
        const mainPoint = points.find(p => p.mainPoint)
        const buildingOwner = mainPoint?.owner || (!!points.length && points.every(p => p.owner === points[0].owner) && points[0].owner)
        const isFav = catchmentBookmarks.some(code => code === catchment.code)
        return {
            id: catchment.id,
            nullValue: { value: isFav ? <StarRoundedIcon sx={{ color: favOrange }} /> : undefined, noPadding: true },
            department: { value: getLabel(departments, catchment.department, 'labelWithCode') },
            code: { value: catchment.code },
            catchment: { value: catchment.name },
            mainCatchmentPoint: { value: mainPoint?.code },
            nbPoint: { value: points.length },
            grenelle: { value: catchment.grenelleWork ? i18n.yes : i18n.no },
            buildingOwner: { value: buildingOwner ? getLabel(contributors, buildingOwner, 'labelDisplay', 'id') : '' },
            aac: { value: catchment.aacNum },
        }
    })

    const { catchment: searchValues } = selectedSearchValues

    const onTableSort = (sort) => {
        dispatch(SieauAction.update('selectedSearchValues', 'catchment', { stations: sortRows(catchmentFormated, sort), sort }))
    }

    return (
        <CardTable
            title={i18n.catchments}

            rows={catchmentFormated}
            headers={['nullValue', 'department', 'code', 'catchment', 'mainCatchmentPoint', 'nbPoint', 'grenelle', 'buildingOwner', 'aac']}

            onClickRow={s => dispatch(push(`/station/catchment/${s.id}`))}
            defaultSort={searchValues?.sort}
            onSort={onTableSort}
            rowsPerPageOptions={nbPerPageLabel}

            data-cy='catchments_table'
        />
    )
}

CatchmentTable.propTypes = {
    catchments: PropTypes.arrayOf(PropTypes.shape({})),
}

const CatchmentsDashboardApp = () => {
    const {
        catchments,
        catchmentPoints,
        selectedSearchValues,
    } = useSelector(store => ({
        catchments: store.CatchmentReducer.catchments,
        catchmentPoints: store.CatchmentReducer.catchmentPoints,
        selectedSearchValues: store.AdministrationReducer.selectedSearchValues,
    }), shallowEqual)

    const [filters, setFilters] = useState({ searchValue: selectedSearchValues.catchment?.searchValue || '', displayActiveSDAGE: false, filter: -1, resultFilter: [] })

    const dispatch = useDispatch()

    useTitle(() => [{
        title: i18n.catchments,
        href: 'catchment',
    }, {
        title: i18n.dashboard,
        href: 'catchment',
    }], [])

    useActions(() => ({
        new: () => dispatch(push('/station/catchment/new/description')),
    }), [])

    const catchmentsFiltered = useMemo(() => {
        const {
            filter,
            resultFilter,
            displayActiveSDAGE,
            sdage,
            searchValue,
        } = filters

        const listCatchments = filter === -1 ? catchments : resultFilter
        const searchValueFormated = searchAllCharacters(searchValue)
        const searchActiveSDAGE = displayActiveSDAGE ? listCatchments.filter(({ activeSdage }) => activeSdage) : listCatchments
        const searchValueFilter = searchValueFormated ? searchActiveSDAGE.filter(({ name, code, aacNum }) => searchAllCharacters(`${name}${code}${aacNum}`).includes(searchValueFormated)) : searchActiveSDAGE
        return hasValue(sdage) ? searchValueFilter.filter(({ usedSdage }) => usedSdage === sdage) : searchValueFilter
    }, [filters, catchments])

    return (
        <div style={{ marginTop: '10px', marginRight: '10px' }}>
            <SimpleTabList
                defaultTab={LIST}
                tabs={[
                    {
                        constant: LIST,
                        label: i18n.table,
                        icon: 'list',
                    },
                    {
                        constant: MAP,
                        label: i18n.map,
                        icon: 'map',
                    },
                ]}
                noPadding
                headerComponent={<FilterFields defaultFilters={filters} onValidate={setFilters} />}
            >
                {tab => (
                    <>
                        {tab === LIST && (<CatchmentTable catchments={catchmentsFiltered}/>)}
                        {tab === MAP && (<CatchmentPointsMap catchmentPoints={catchmentPoints}/>)}
                    </>
                )}
            </SimpleTabList>
        </div>
    )
}

export default CatchmentsDashboardApp