import { isNil } from 'lodash'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import Card from '../../../../components/card/Card'
import Table from '../../../../components/datatable/Table'
import Select from '../../../../components/forms/Select'
import { nbPerPageLabel } from '../../../../referencial/constants/ReferencialConstants'
import { getLocalizationPicto } from '../../../../utils/AnalyseUtils'
import { getLabel } from '../../../../utils/StoreUtils'
import { getI18nTitleDataLength } from '../../../../utils/StringUtil'
import useSandreList from 'utils/customHook/useSandreList'
import { StyledFieldSet } from 'components/StyledElements'
import { Grid } from '@mui/material'
import { ButtonMUI } from 'components/styled/Buttons'
import useUpdateEffect from 'utils/customHook/useUpdateEffect'
import useListIndexed from 'utils/customHook/useListIndexed'

const UpdateParameter = ({
    selectedParameters = [],
    onChange = () => {},
}) => {
    const {
        parameters,
        fractions,
        supports,
        units,
    } = useSelector(store => ({
        parameters: store.ParameterReducer.parameters,
        fractions: store.FractionReducer.fractions,
        supports: store.SupportReducer.supports,
        units: store.UnitReducer.units,
    }), shallowEqual)

    const indexedParameters = useListIndexed(parameters, 'code')

    const analysisPlaces = useSandreList('ANALYSES.LIEU')

    const [tmpSelected, setTmpSelected] = useState([])

    const [fraction, setFraction] = useState()
    const [support, setSupport] = useState()
    const [place, setPlace] = useState() // hasValue(v) && `${v}` || ''

    useUpdateEffect(() => {
        setTmpSelected([])
        setFraction()
        setSupport()
        setPlace()
    }, [selectedParameters])

    const parametersFormat = selectedParameters.map(({ parameterCode, fractionCode, supportCode, placeCode, unitCode }, i) => {
        const isSelected = tmpSelected.some(index => index === i)
        const parameter = indexedParameters[parameterCode]
        return {
            name: parameter?.name.length > 60 ? parameter?.shortLabel : parameter?.name,
            unit: getLabel(units, unitCode, 'symbolWithCode'),
            fraction: getLabel(fractions, fractionCode, 'labelWithCode'),
            support: getLabel(supports, supportCode, 'labelWithCode'),
            place: getLocalizationPicto(placeCode),
            nullValue: <i className={'material-icons'} >{isSelected ? 'check_box' : 'check_box_outline_blank'}</i>,
            color: isSelected ? '#b8d2ff' : '#fff',
            code: parameterCode,
            isSelected,
            index: i,
        }
    })
    const nbParameterSelected = selectedParameters.length

    const actions = tmpSelected.length === selectedParameters.length ? [{
        onClick: () => setTmpSelected([]),
        iconName: 'check_box_outline_blank',
        tooltip: i18n.unselectAll,
    }] : [{
        onClick: () => setTmpSelected(selectedParameters.map((_, i) => i)),
        iconName: 'check_box',
        tooltip: i18n.selectAll,
    }]

    return (
        <div style={{ marginTop: '10px' }}>
            <Card
                title={`${i18n.parameters} (${nbParameterSelected} ${getI18nTitleDataLength(i18n.element, i18n.elements, nbParameterSelected)})`}
                actions={actions}
            >
                <Table
                    showTitle={false}
                    data={parametersFormat}
                    type={{ headers: ['name', 'unit', 'fraction', 'support', 'place', 'nullValue'] }}
                    coloredLine
                    maxHeight='45vh'
                    onClick={({ index, isSelected }) => {
                        setTmpSelected(prev => {
                            if (isSelected) return prev.filter(i => index !== i)
                            return [...prev, index]
                        })
                    }}
                    duplicable
                    onDuplicate={({ index }) => {
                        onChange({ selectedParameters: [...selectedParameters.slice(0, index), selectedParameters[index], selectedParameters[index], ...selectedParameters.slice(index + 1, selectedParameters.length)] })
                    }}
                    deletable
                    onDelete={({ index }) => {
                        onChange({ selectedParameters: selectedParameters.filter((_, i) => index !== i) })
                    }}
                    sortable
                    condensed
                    paging
                    nbPerPageLabel={nbPerPageLabel}
                />
            </Card>
            <StyledFieldSet>
                <Grid container spacing={1} alignItems='center'>
                    <Grid item xs={3}>
                        <Select
                            label={i18n.fractions}
                            value={fraction}
                            options={fractions}
                            onChange={setFraction}
                            keyValue='id'
                            keyLabel='labelWithCode'
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <Select
                            label={i18n.support}
                            value={support}
                            options={supports}
                            onChange={setSupport}
                            keyValue='id'
                            keyLabel='labelWithCode'
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <Select
                            options={analysisPlaces}
                            value={place}
                            label={i18n.place}
                            returnNull
                            onChange={setPlace}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <ButtonMUI
                            onClick={() => {
                                onChange({ selectedParameters: selectedParameters.map((param, i) => {
                                    if (tmpSelected.some(index => index === i)) {
                                        return {
                                            ...param,
                                            fractionCode: fraction,
                                            supportCode: support,
                                            placeCode: isNil(place) ? undefined : `${place}`,
                                        }
                                    }
                                    return param
                                }) })
                            }}
                            variant='contained'
                            fullWidth
                        >
                            {i18n.apply}
                        </ButtonMUI>
                    </Grid>
                </Grid>
            </StyledFieldSet>
        </div>
    )
}

UpdateParameter.propTypes = {
    selectedParameters: PropTypes.arrayOf(PropTypes.shape({
        parameterCode: PropTypes.string,
        fractionCode: PropTypes.string,
        supportCode: PropTypes.string,
        unitCode: PropTypes.string,
        placeCode: PropTypes.string,
    })),
    onChange: PropTypes.func,
}

export default UpdateParameter