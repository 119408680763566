import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import NumberField from '../../../../../components/forms/NumberField'
import Select from '../../../../../components/forms/Select'
import Row from '../../../../../components/react/Row'
import { SANDRE } from '../../../../../referencial/constants/ReferencialConstants'
import DtoSandreCode from '../../../../../referencial/dto/DtoSandreCode'
import { arrayOf, getSandreList } from '../../../../../utils/StoreUtils'
import DtoAccessType from '../../../../dto/installation/DtoAccessType'

class AccessTypeModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            accessType: { ...props.accessType, idStation: props.id },
        }
    }

    componentDidUpdate(prevProps) {
        if (!prevProps.popupIsOpen && this.props.popupIsOpen) {
            $('#descriptionModal').on('click', this.onSave)
        }
    }

    onSave = () => {
        this.props.saveResult(new DtoAccessType(this.state.accessType))
        $('.modal').modal('close')
    }

    onChangeElement = (changes) => this.setState({ accessType: { ...this.state.accessType, ...changes } })

    render() {
        const { accessType } = this.state
        return (
            <div>
                <Row>
                    <Select
                        col={ 6 }
                        label={ i18n.type }
                        options={ getSandreList(this.props.sandreCodes, SANDRE.INSTALLATION_TYPE_ACCES) }
                        value={ accessType.typeAccess }
                        onChange={ v => this.onChangeElement({ typeAccess: v }) }
                    />
                    <Select
                        col={ 6 }
                        label={ i18n.nature }
                        options={ getSandreList(this.props.sandreCodes, SANDRE.INSTALLATION_NATURE_ACCES) }
                        value={ accessType.nature }
                        onChange={ v => this.onChangeElement({ nature: v }) }
                    />
                </Row>
                <Row>
                    <NumberField
                        col={ 6 }
                        title={ i18n.amount }
                        value={ accessType.amount }
                        onChange={ v => this.onChangeElement({ amount: v }) }
                    />
                    <Select
                        col={6}
                        label={i18n.status}
                        options={getSandreList(this.props.sandreCodes, SANDRE.INSTALLATIONS_ETATEQUIPEMENT)}
                        value={accessType.status}
                        onChange={v => this.onChangeElement({ status: v })}
                    />
                </Row>
            </div>
        )
    }
}

AccessTypeModal.propTypes = {
    accessType: PropTypes.instanceOf(DtoAccessType),
    sandreCodes: arrayOf(DtoSandreCode),
    id: PropTypes.number,
    popupIsOpen: PropTypes.bool,
    saveResult: PropTypes.func,
}

const mapStateToProps = store => ({
    sandreCodes: store.ReferencialReducer.sandreCodes,
    popupIsOpen: store.SieauReducer.popupIsOpen,
})

export default connect(mapStateToProps)(AccessTypeModal)
