import { H_REFERENCIAL_METHOD } from 'account/constants/AccessRulesConstants'
import FixedSlideNav from 'components/navbar/FixedSlideNav'
import BoundaryError from 'log/components/BoundaryError'
import React, { useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { Outlet, useParams } from 'react-router-dom'
import { REFERENCIAL_TYPE_NAME } from 'referencial/constants/ReferencialConstants'
import i18n from 'simple-react-i18n'
import { componentHasHabilitations } from 'utils/HabilitationUtil'
import MethodAction from '../actions/MethodAction'

const MethodRouterApp = () => {
    const dispatch = useDispatch()
    const { code } = useParams()

    const navLinks = useMemo(() => {
        return [{
            icons: 'dashboard',
            name: i18n.dashboard,
            href: `/referencial/${REFERENCIAL_TYPE_NAME.method}/${code}/dashboard`,
            habilitation: H_REFERENCIAL_METHOD,
        }].filter(l => componentHasHabilitations(l.habilitation))
    }, [code])

    useEffect(() => {
        dispatch(MethodAction.fetchMethods())
        if (code !== 'new') {
            dispatch(MethodAction.fetchMethod(code))
        }
    }, [dispatch, code])

    return (
        <div className='row no-margin'>
            <FixedSlideNav links={navLinks} />
            <main>
                <div className='row no-margin padding-left-1'>
                    <div className='content-page no-padding fullscreen'>
                        <BoundaryError>
                            <Outlet />
                        </BoundaryError>
                    </div>
                </div>
            </main>
        </div>
    )
}

export default MethodRouterApp