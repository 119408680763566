import React, { useCallback, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import TextField from '@mui/material/TextField'
import { default as MuiAutocomplete, createFilterOptions } from '@mui/material/Autocomplete'
import { searchAllCharacters } from 'utils/StringUtil'
import { v4 as uuidv4 } from 'uuid'
import { hasValue } from 'utils/NumberUtil'
import { makeStyles, styled } from '@mui/styles'
import i18n from 'simple-react-i18n'
import { orderBy } from 'lodash'

const Listbox = styled('ul')({
    position: 'absolute',
    backgroundColor: 'white',
    width: 'fit-content',
    margin: '0',
    padding: '0 !important',
    border: '1px solid #53a1ff',
    maxHeight: '250px',
    overflowY: 'auto',
    minWidth: '100%',
    display: 'block',
    '& li': {
        cursor: 'pointer',
        height: '20px',
        fontWeight: 'normal',
        display: 'block',
        whiteSpace: 'nowrap',
        minHeight: '1.2em',
        padding: '0px 4px 1px',
        '&:hover': {
            background: '#e0e0e0',
        },
        '&.selected_value': {
            background: '',
        },
    },
})

const useStyles = makeStyles(() => ({
    root: {
        top: '-5px',
        position: 'relative',
        height: '46px',

        '& .MuiInputLabel-outlined': {
            top: '-15px',
            color: ({ disabled }) => disabled && '#9e9e9e' || '#161832',
            fontWeight: 'bold',
            left: '2px',
            fontSize: '1rem',
        },
        '& .MuiInputLabel-outlined.Mui-focused': {
            color: '#53a1ff',
        },
    },
    inputRoot: {
        padding: '0 !important',
        top: '19px',
        backgroundColor: 'white',

        '& .MuiOutlinedInput-notchedOutline': {
            border: 'none !important',
        },

        '&.Mui-focused .MuiOutlinedInput-input': {
            borderColor: '#53a1ff !important',
        },
    },
    input: {
        height: '2rem !important',
        margin: '0 !important',
        borderRadius: '5px',
        paddingLeft: '5px',
        paddingRight: '5px',
        minHeight: '2rem',
        position: 'relative',
        width: '100%',
        '-webkit-text-fill-color': 'rgba(0,0,0,0.87) !important',
    },
    clearIndicator: {
        padding: '5 0 4 0 !important',
    },
    popupIndicator: {
        padding: '5 0 4 0 !important',
    },
}))

const Autocomplete = ({
    col,
    disabled = false,
    displayWithCode = false,
    id = '',
    keyFilter = [],
    keyLabel = 'label',
    keyValue = 'id',
    label = '',
    nullLabel = '',
    onChange = () => { },
    options = [],
    value = -1,
    obligatory = false,
}) => {
    const classes = useStyles({ disabled })
    const [autocompleteId] = useState(id || searchAllCharacters(label + uuidv4()).replaceAll(' ', '_').replace('numero', '').replace('nom', 'libelle'))

    const getOptionLabel = useCallback(option => {
        if (!option) {
            return ''
        }
        const code = displayWithCode ? option[keyValue] : null
        const codeShown = hasValue(code) && code !== '' ? ` [${code}]` : ''
        return (option[keyLabel] || option.mnemonique || option.name || option.label || nullLabel) + codeShown
    }, [displayWithCode, keyLabel, keyValue, nullLabel])

    const iconStyle = {
        color: disabled ? '#9e9e9e' : '#161832',
        cursor: disabled ? 'default' : 'pointer',
        fontSize: '0.9em',
        fontWeight: 'bold',
    }

    const optionsOrdered = useMemo(() => {
        const order = orderBy(options, opt => searchAllCharacters(opt[keyLabel] || opt.mnemonique || opt.name || opt.label || nullLabel))
        return [
            { [keyLabel]: ' ' },
            ...order,
        ]
    }, [keyLabel, nullLabel, options])

    const autocomplete = (
        <MuiAutocomplete
            id={autocompleteId}
            value={value}
            disableListWrap
            getOptionLabel={optionValue => {
                const option = options.find(o => o[keyValue] === optionValue)
                return getOptionLabel(option)
            }}
            disabled={disabled}
            options={optionsOrdered}
            ListboxComponent={Listbox}
            clearIcon={<i className='material-icons' style={iconStyle}>close</i>}
            popupIcon={<i className='material-icons' style={iconStyle}>expand_more</i>}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={`${label} ${obligatory ? '*' : ''}`}
                    InputProps={{
                        ...params.InputProps,
                        notched: false,
                    }}
                    inputProps={{
                        ...params.inputProps,
                        style: {
                            border: `1px solid ${disabled && '#ddd' || '#7a7a7a'}`,
                        },
                    }}
                    InputLabelProps={{
                        ...params.InputLabelProps,
                        shrink: false,
                    }}
                />
            )}
            renderOption={(props, option, { inputValue }) => {
                const optionLabel = getOptionLabel(option)
                return (
                    <li {...props} key={props.id} style={{ paddingLeft: 8, backgroundColor: optionLabel === inputValue ? '#CCC' : '#FFF' }}>{optionLabel}</li>
                )
            }}
            onChange={(_, obj) => onChange(obj?.[keyValue], obj)}
            filterOptions={createFilterOptions({
                stringify: option => keyFilter.map(key => option[key] || '').join('#') || getOptionLabel(option),
            })}
            clearText={i18n.clearLabel}
            closeText={i18n.close}
            openText={i18n.open}
            noOptionsText={i18n.noOption}
            classes={classes}
            // open
        />
    )

    if (col) {
        return (
            <div className={col ? `col s${col}` : ''}>
                {autocomplete}
            </div>
        )
    }
    return autocomplete
}

Autocomplete.propTypes = {
    col: PropTypes.number,
    disabled: PropTypes.bool,
    displayWithCode: PropTypes.bool,
    id: PropTypes.string,
    keyFilter: PropTypes.arrayOf(PropTypes.string),
    keyLabel: PropTypes.string,
    keyValue: PropTypes.string,
    label: PropTypes.string,
    nullLabel: PropTypes.string,
    onChange: PropTypes.func,
    options: PropTypes.arrayOf(PropTypes.shape({
        // [keyValue]
        // [keyLabel]
        // ...rest
    })),
    value: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
    ]),
    obligatory: PropTypes.bool,
}

export default Autocomplete