import React from 'react'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import { round, template } from 'lodash'
import Icon from '../../../components/icon/Icon'
import { hasValue } from '../../../utils/NumberUtil'
import { DESCRIPTION } from '../../../home/constants/RouteConstants'
import { CAMPAIGNS, EVENTS } from '../../../alerting/constants/AlertConstants'
import { getDate, getFullDateMini } from '../../../utils/DateUtil'
import DtoStation from '../../../station/dto/DtoStation'
import { getIndicatorComment } from './MapSituationUtils'
import { sieauTooltip } from '../../../utils/FormUtils'
import { PIEZOMETER_SPI_TRENDS } from '../../constants/piezo/PiezometerStationConstants'
import { getLabel } from '../../../utils/StoreUtils'
import Row from '../../../components/react/Row'
import { getI18nOrLabel } from '../../../utils/StringUtil'

const StationMapSituationCard = ({
    station = {},
    // icon = '',
    // color = '',
    // tooltip = '',
    onClick = () => {},
    divider = true,
    onToggle = () => {},
    onHover = () => {},
    message = i18n.noData,
    withoutIcon = false,
    stabilityPercent,
    valueLabel,
}) => {
    const stabilityTooltip = () => [`Seuil de stabilité : ${station.stabilityGap}`,
        `${station.indicatorValue} - ${station.stabilityMeasureValue} = ${round(station.indicatorValue - station.stabilityMeasureValue, 3)} => Tendance ${getLabel(PIEZOMETER_SPI_TRENDS, station.trendLabel, 'label', 'imgLabel')}`,
        `Ce seuil est calculé à partir de ${stabilityPercent ?? 10}% / 2 de l'écart entre le min et le max historique.`,
    ].join('\n')
    return (
        <div className='row no-margin clickable' style={{ maxHeight: '50px !important' }} onClick={() => onClick(station, DESCRIPTION)} onMouseOver={() => onHover(station, true)} onMouseOut={() => onHover(station, false)}>
            <div className='col s12 no-padding' style={{ maxHeight: '50px !important' }}>
                <div className='row no-margin' onClick={onToggle} style={{ maxHeight: '50px !important', alignItems: 'center' }}>
                    {
                        !withoutIcon && (
                            <div className='col s1 no-padding'>
                                {
                                    station.markerIcon?.startsWith('<svg') ? (
                                        <img style={{ width: '26px', height: '26px', left: 'auto', top: 'auto', margin: '10' }} src={`data:image/svg+xml;utf8,${station.markerIcon}`} />
                                    ) : (
                                        <img style={{ width: '36px', height: '42px' }} src={station.markerIcon} />
                                    )
                                }
                            </div>
                        )
                    }
                    <div className='col s11 no-padding' style={{ maxHeight: '50px !important' }}>
                        <div className='row no-margin'>
                            <div className='col s12 no-padding'>
                                <span className='font-size-14'>
                                    <b>[ {station.code} ] {station.name} - {station.city ? `${station.city}` : ''}</b>
                                </span>
                            </div>
                        </div>

                        <Row>
                            {
                                station.indicatorDate ? (
                                    <div className='col s12 no-padding'>
                                        <div className='col s9 no-padding'>
                                            <Row>
                                                <span className='font-size-14 no-padding'>
                                                    {`${i18n.date}: `}
                                                </span>
                                                <span className='bold no-padding' style={{ fontSize: '16px' }}>
                                                    {getFullDateMini(station.indicatorDate)}
                                                </span>
                                            </Row>
                                            {hasValue(valueLabel) && station.indicatorValue &&
                                                <Row>
                                                    <span className='font-size-14 no-padding'>
                                                        {`${valueLabel}: `}
                                                    </span>
                                                    <span className='bold no-padding' style={{ fontSize: '16px' }}>
                                                        {round(station.indicatorValue, 3)}
                                                    </span>
                                                </Row>
                                            }
                                            {hasValue(station.thresholdValue) &&
                                                <Row>
                                                    <span className='font-size-14 no-padding'>
                                                        {`${i18n.threshold}: `}
                                                    </span>
                                                    <span className='font-size-14 bold no-padding'>
                                                        {round(station.thresholdValue, 3)}
                                                    </span>
                                                </Row>
                                            }
                                        </div>
                                        {
                                            hasValue(station.stabilityMeasureValue) &&
                                            <div className='col s3 no-padding' {...sieauTooltip(stabilityTooltip)}>
                                                <Row>
                                                    <span className='font-size-14 no-padding'>
                                                        {`${i18n.stability}: `}
                                                    </span>
                                                </Row>
                                                <Row>
                                                    <span className='bold no-padding' style={{ fontSize: '16px' }} >
                                                        {round(station.stabilityMeasureValue, 3)}
                                                    </span>
                                                    <Icon icon='help' style={ {
                                                        paddingLeft: 5,
                                                        fontSize: 14,
                                                        // position: 'relative',
                                                    } }
                                                    />
                                                </Row>
                                            </div>
                                        }
                                        {
                                            hasValue(station.stabilityMeasureValue) &&
                                            <div className='col s3 no-padding' {...sieauTooltip(stabilityTooltip)}>
                                                <Row>
                                                    <span className='font-size-14 no-padding'>
                                                        {`${i18n.error}: `}
                                                    </span>
                                                </Row>
                                                <Row>
                                                    <span className='bold no-padding' style={{ fontSize: '16px' }} >
                                                        {getI18nOrLabel(station.message)}
                                                    </span>
                                                </Row>
                                            </div>
                                        }
                                    </div>
                                ) : (
                                    <div className='col s12 no-padding margin-left-1'>
                                        <span className={'font-size-14 bold no-padding'}>
                                            {message}
                                        </span>
                                    </div>
                                )
                            }
                        </Row>
                    </div>
                </div>
                <div className='event-card'>
                    <div className='row no-margin no-padding event-footer'>
                        <div className='left margin-left-1'>
                            {
                                hasValue(station.jobExecutionId) && (
                                    <Icon
                                        size='small'
                                        icon='wifi'
                                        tooltip={'Open Data'}
                                    />
                                )
                            }
                        </div>
                        <div className='left' style={{ paddingLeft: '7px' }}>
                            {
                                station.bookmarked && (
                                    <Icon
                                        size='small'
                                        icon='grade'
                                        tooltip={i18n.bookmark}
                                    />
                                )
                            }
                        </div>
                        <div className='left' style={{ paddingLeft: '7px' }}>
                            {template(i18n.calculAtformeasure)({ date: getFullDateMini(station.simulationDate) })}
                        </div>
                        <div className='right'>
                            {
                                (station.currentComments || station.currentMonitoring) && (
                                    <Icon
                                        size='small'
                                        icon='event'
                                        onClick={() => onClick(station, EVENTS, 'date')}
                                        tooltip={i18n.currentEvents}
                                    />
                                )
                            }
                            {
                                hasValue(station.currentMonitoring) && (
                                    <Icon
                                        size='small'
                                        icon='notifications'
                                        onClick={() => onClick(station, EVENTS, 'eventType')}
                                        tooltip={i18n.oversight}
                                    />
                                )
                            }
                            {
                                hasValue(station.currentCampaign) && (
                                    <Icon
                                        size='small'
                                        icon='nature_people'
                                        onClick={() => onClick(station, CAMPAIGNS)}
                                        tooltip={i18n.currentCampaign}
                                    />
                                )
                            }
                            <Icon
                                size='small'
                                icon='info'
                                onClick={() => onClick(station, DESCRIPTION)}
                                tooltip={i18n.description}
                            />
                        </div>
                    </div>
                </div>
                {divider && <div className='divider' />}
            </div>
        </div>
    )
}

StationMapSituationCard.propTypes = {
    isCheck: PropTypes.bool,
    icon: PropTypes.string,
    color: PropTypes.string,
    tooltip: PropTypes.string,
    measureDate: PropTypes.string,
    divider: PropTypes.bool,
    onClick: PropTypes.func,
    toggleable: PropTypes.bool,
    onToggle: PropTypes.func,
    station: PropTypes.instanceOf(DtoStation),
    onHover: PropTypes.func,
    message: PropTypes.string,
    withoutIcon: PropTypes.boolean,
    stabilityPercent: PropTypes.number,
    valueLabel: PropTypes.string
}

export default StationMapSituationCard
