export default class DtoPluviometerWithCampaignsAndEvents {
    constructor(obj = {}) {
        this.id = obj.id
        this.name = obj.name
        this.typeName = 'pluviometry'
        this.creationDate = obj.creationDate
        this.closeDate = obj.closeDate
        this.x = obj.x
        this.y = obj.y
        this.projection = obj.projectionType
        this.altitude = obj.altitude
        this.measureExpression = obj.measureExpression
        this.comment = obj.comment
        this.townCode = obj.townCode
        this.contactCode = obj.contactCode
        this.designation = obj.designation
        this.code = obj.code
        this.address = obj.address
        this.countryCode = obj.countryCode
        this.contributorCode = obj.contributorCode
        this.jobExecutionId = obj.jobExecutionId
        this.updateLogin = obj.updateLogin
        this.updateDate = obj.updateDate
        this.localisation = {
            x: obj.x,
            y: obj.y,
            projection: obj.projectionType,
        }
        this.declarationTownCode = obj.declarationTownCode
        this.currentCampaign = obj.currentCampaign
        this.currentComments = obj.currentComments
        this.currentMonitoring = obj.currentMonitoring
    }
}