import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import CollapsibleCard from '../../../../components/card/CollapsibleCard'
import Table from '../../../../components/datatable/Table'
import Row from '../../../../components/react/Row'
import { INSTALLATION_TYPE } from '../../../constants/InstallationConstants'
import { nbPerPageLabelShort } from '../../../../referencial/constants/ReferencialConstants'
import { getDate } from '../../../../utils/DateUtil'
import BoreholeDescriptionPanel from '../../borehole/BoreholeDescriptionPanel'
import CaptureStationDescriptionPanel from '../../capture/CaptureStationDescriptionPanel'
import InstallationDescriptionPanel from '../../installationDefault/InstallationDescriptionPanel'
import LiftingStationDescriptionPanel from '../../liftingStation/LiftingStationDescriptionPanel'
import PumpingStationDescriptionPanel from '../../pumping/PumpingStationDescriptionPanel'
import STEPDescriptionPanel from '../../step/STEPDescriptionPanel'
import TankStationDescriptionPanel from '../../tank/TankStationDescriptionPanel'
import TreatmentStationDescriptionPanel from '../../treatment/TreatmentStationDescriptionPanel'
import DtoVisit from '../dto/DtoVisit'
import SectorAction from 'referencial/components/sector/actions/SectorAction'
import Icon from 'components/icon/Icon'

const InstVisitModifications = ({
    visit = {},
    readMode = true,
    summary = false,
    addNewChange = () => { },
}) => {
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(SectorAction.fetchSectors())
    }, [])

    const {
        installation,
    } = useSelector(store => ({
        installation: store.InstallationReducer.installation,
    }), shallowEqual)

    const dataTable = visit.link_modifications.map(m => ({
        ...m,
        updateDate: getDate(m.updateDate),
        initialValue: m.previousValue,
        updateUser: m.updateLogin,
        status: !m.updateLogin && <Icon icon='unpublished' tooltip={i18n.notSync} />,
    }))

    const getDescriptionInstallation = () => {
        switch (installation.installationType) {
            case INSTALLATION_TYPE.BOREHOLE:
                return <BoreholeDescriptionPanel onChangeVisit={ addNewChange } />
            case INSTALLATION_TYPE.LIFTING_STATION:
                return <LiftingStationDescriptionPanel onChangeVisit={ addNewChange } />
            case INSTALLATION_TYPE.CAPTURE:
                return <CaptureStationDescriptionPanel onChangeVisit={ addNewChange } />
            case INSTALLATION_TYPE.TANK:
                return <TankStationDescriptionPanel onChangeVisit={ addNewChange } />
            case INSTALLATION_TYPE.TREATMENT:
                return <TreatmentStationDescriptionPanel onChangeVisit={ addNewChange } />
            case INSTALLATION_TYPE.PUMPING:
                return <PumpingStationDescriptionPanel onChangeVisit={ addNewChange } />
            case INSTALLATION_TYPE.STEP:
                return <STEPDescriptionPanel onChangeVisit={ addNewChange } />
            default:
                return <InstallationDescriptionPanel onChangeVisit={ addNewChange } />
        }
    }

    return (
        <Row>
            <>
                <div className={`row ${summary || readMode ? 'margin-left-1' : 'margin-left-3'} margin-right-1 margin-bottom-1`} style={{ position: 'sticky', top: '105px', zIndex: '150' }}>
                    { !readMode && (
                        <CollapsibleCard
                            header={`${i18n.modifications} (${dataTable.length} ${dataTable.length <= 1 ? i18n.element : i18n.elements})`}
                            className='card-title'
                            noPadding={false}
                            style={{ padding: '5px 0 5px 15px', background: 'grey' }}
                        >
                            <Table
                                showTitle={false}
                                data={dataTable}
                                type={{ headers: ['field', 'initialValue', 'newValue', 'updateUser', 'updateDate', 'status'] }}
                                paging
                                nbPerPageLabel={nbPerPageLabelShort}
                                sortable
                                condensed
                            />
                        </CollapsibleCard>
                    ) || (
                        <Table
                            title={i18n.modifications}
                            data={dataTable}
                            type={{ headers: ['field', 'initialValue', 'newValue', 'updateUser', 'updateDate', 'status'] }}
                            className='blue'
                            paging
                            nbPerPageLabel={nbPerPageLabelShort}
                            sortable
                            condensed
                        />
                    )}
                </div>
                {!readMode && getDescriptionInstallation() }
            </>
        </Row>
    )
}

InstVisitModifications.propTypes = {
    visit: PropTypes.instanceOf(DtoVisit),
    readMode: PropTypes.bool,
    summary: PropTypes.bool,
    addNewChange: PropTypes.func,
}

export default InstVisitModifications
