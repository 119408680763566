import { isUndefined, omit } from 'lodash'
import {
    DATEACHAT_OBLIGATOIRE,
    DATE_SERVICE_OBLIGATOIRE,
    NUMIMEI_OBLIGATOIRE,
    NUMLINE_OBLIGATOIRE,
    NUMSERIE_OBLIGATOIRE,
} from '../constants/MaterielSettingRuleConstant'
import i18n from 'simple-react-i18n'
import AppStore from 'store/AppStore'
import ToastrAction from 'toastr/actions/ToastrAction'

const getMaterialsInStock = (materials, lastSituations, keyMaterial) => materials.filter(m => {
    const lastSituation = lastSituations.find(situation => situation[keyMaterial] === m.id)
    return (!lastSituation || (lastSituation && lastSituation.statusCode === 2))
})

const checkRule = (rule, { serialNumber, purchaseDate, imei, numLine, openingDate }) => {
    switch (rule) {
        case NUMSERIE_OBLIGATOIRE: return !serialNumber && i18n.serialNumber
        case DATEACHAT_OBLIGATOIRE: return !purchaseDate && i18n.purchaseDate
        case NUMIMEI_OBLIGATOIRE: return !imei && i18n.imeiAndSerialNumber
        case NUMLINE_OBLIGATOIRE: return !numLine && i18n.lineNumber
        case DATE_SERVICE_OBLIGATOIRE: return !openingDate && i18n.openDate
        default: return rule && i18n.unknown
    }
}

const isFieldRequired = (rules, constant) => !!rules.find(r => r.rule === constant)?.shouldUse

const isSaveBlocked = (materiels, materiel, rules, keyType) => {
    const paramsToEnter = [
        ...rules.map(({ rule, shouldUse }) => shouldUse && checkRule(rule, materiel)),
        isUndefined(materiel[keyType]) && i18n.type,
    ].filter(p => p)
    if (paramsToEnter.length > 0) {
        AppStore.dispatch(ToastrAction.info(`${i18n.pleaseEnterFollowingInformations} : ${paramsToEnter.filter(p => p).map(p => p)}`))
        return true
    }

    const isSerialNumberExist = materiel.serialNumber && materiels.some(e => e.serialNumber === materiel.serialNumber && e.id !== materiel.id)
    const isImmoNumber = materiel.reference && materiels.some(e => e.reference === materiel.reference && e.id !== materiel.id)
    const isImeiExist = materiel.imei && materiels.some(e => e.imei === materiel.imei && e.id !== materiel.id)
    if (isSerialNumberExist || isImmoNumber || isImeiExist) {
        AppStore.dispatch(ToastrAction.info(i18n.immoNumberOrSerialNumberAlreadyUse))
        return true
    }

    return false
}

const getEquipmentSetting = equipment => ({
    centralIntegrated: false,
    sensorIntegrated: false,
    simIntegrated: false,
    telecomIntegrated: false,
    powerSupplyIntegrated: false,
    variousMaterielIntegrated: false,
    ...omit(equipment, [
        'equipmentId',
        'id',
        'equipmentType',
        'centralId',
        'sensorId',
        'simId',
        'telecomId',
        'powerSupplyId',
        'variousMaterielId',
        'comment',
        'purchaseDate',
        'warrantyTime',
        'manufactureDate',
        'providerId',
        'serialNumber',
        'reference',
        'administrator',
        'updateDate',
        'updateLogin',
        'codeNetwork',
    ]),
})

const getCentralSetting = central => ({
    powerSupplyType: undefined,
    pileNumber: undefined,
    callFrequency: undefined,
    callHour: undefined,
    transmissionVolume: undefined,
    telecomType: undefined,
    desiccant: undefined,
    ...omit(central, [
        'id',
        'code',
        'reference',
        'comment',
        'centralType',
        'purchaseDate',
        'warrantyTime',
        'serialNumber',
        'withdrawalDate',
        'providerId',
        'manufacturerId',
        'replacementDate',
        'manufactureDate',
        'situationDate',
        'department',
        'city',
        'stationCode',
        'updateLogin',
        'updateDate',
        'administrator',
        'state',
    ]),
})

const getSensorSetting = sensor => ({
    range: undefined,
    length: undefined,
    height: undefined,
    powerSupplyType: undefined,
    desiccant: undefined,
    ...omit(sensor, [
        'id',
        'code',
        'sensorType',
        'providerId',
        'manufacturerId',
        'cellNumber',
        'purchaseDate',
        'withdrawalDate',
        'manufactureDate',
        'warrantyTime',
        'comment',
        'state',
        'reference',
        'serialNumber',
        'department',
        'city',
        'stationCode',
        'updateLogin',
        'updateDate',
        'administrator',
    ]),
})

const getTelecomSetting = telecom => ({
    transmissionVolume: undefined,
    imei: undefined,
    lineNumber: undefined,
    ...omit(telecom, [
        'id',
        'code',
        'reference',
        'telecomType',
        'materielType',
        'serialNumber',
        'comment',
        'openDate',
        'closeDate',
        'closeCause',
        'providerId',
        'updateDate',
        'updateLogin',
        'loginReferent',
        'manufacturerId',
        'updateLogin',
        'updateDate',
        'administrator',
        'purchaseDate',
    ]),
})

const getPowerSupplySetting = powerSupply => ({
    lifeTime: undefined,
    voltage: undefined,
    ...omit(powerSupply, [
        'id',
        'code',
        'reference',
        'powerSupplyType',
        'serialNumber',
        'purchaseDate',
        'withdrawalDate',
        'providerId',
        'manufactureDate',
        'warrantyTime',
        'comment',
        'state',
        'situationDate',
        'department',
        'city',
        'stationCode',
        'immoNumber',
        'updateLogin',
        'updateDate',
        'administrator',
        'manufacturerId',
    ]),
})

// const getVariousMaterielSetting = variousMateriel => ({
//     ...omit(variousMateriel, [])
// })

// const getSimSetting = sim => ({
//     ...omit(sim, [])
// })

export {
    getMaterialsInStock,
    checkRule,
    isFieldRequired,
    isSaveBlocked,
    getEquipmentSetting,
    getCentralSetting,
    getPowerSupplySetting,
    getSensorSetting,
    getTelecomSetting,
    // getSimSetting,
    // getVariousMaterielSetting,
}
