export default class DtoManagementUnitAUP {
    constructor(obj) {
        this.managementCode = obj.managementCode // Long,
        this.year = obj.year // Option[Int],
        this.aup = obj.aup // Option[Double],
        this.aupLowWater = obj.aupLowWater // Option[Double],
        this.aupExceptLowWater = obj.aupExceptLowWater // Option[Double],
        this.yearEnd = obj.yearEnd // Option[Int],
        this.month01 = obj.month01 // Option[Double],
        this.month02 = obj.month02 // Option[Double],
        this.month03 = obj.month03 // Option[Double],
        this.month04 = obj.month04 // Option[Double],
        this.month05 = obj.month05 // Option[Double],
        this.month06 = obj.month06 // Option[Double],
        this.month07 = obj.month07 // Option[Double],
        this.month08 = obj.month08 // Option[Double],
        this.month09 = obj.month09 // Option[Double],
        this.month10 = obj.month10 // Option[Double],
        this.month11 = obj.month11 // Option[Double],
        this.month12 = obj.month12 // Option[Double],
        this.aupLowWaterSup = obj.aupLowWaterSup // Option[Double],
        this.aupLowWaterSout = obj.aupLowWaterSout // Option[Double],
        this.aupExceptLowWaterSup = obj.aupExceptLowWaterSup // Option[Double],
        this.aupExceptLowWaterSout = obj.aupExceptLowWaterSout // Option[Double],
        this.aupPrefectural = obj.aupPrefectural // Option[Double]
    }
}