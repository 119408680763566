module.exports = {
    WAIT_PROPS: 'WAIT_PROPS',
    POPUP: 'POPUP',
    RECEIVE_PROPS: 'RECEIVE_PROPS',
    RESET_PROPS: 'RESET_PROPS',
    OPEN_POPUP: 'OPEN_POPUP',
    RESET_POPUP: 'RESET_POPUP',
    POPUP_CLOSED: 'POPUP_CLOSED',
    RECEIVE_SIEAU_HYPE_TRENDS: 'RECEIVE_SIEAU_HYPE_TRENDS',
    RESET_SIEAU_HYPE_TRENDS: 'RESET_SIEAU_HYPE_TRENDS',
    SET_LOADING_DATA: 'SET_LOADING_DATA',
    RESET_ABORT_SIGNAL: 'RESET_ABORT_SIGNAL',
    PANEL_DESCRIPTION: 'PANEL_DESCRIPTION',
    PANEL_MAP: 'PANEL_MAP',
}