import { createSlice } from '@reduxjs/toolkit'

export const initialState = {}

const store = createSlice({
    name: 'tmp',
    initialState,
    reducers: {
        init: (_, action) => ({ ...action.payload }),
        reset: () => ({}),

        editMode: (state) => ({ ...state, isEditMode: true }),
        readMode: (state) => ({ ...state, isEditMode: false }),
        selectedOperation: (state, action) => ({ ...state, selectedOperations: { ...state.selectedOperations, [action.payload]: !state.selectedOperations?.[action.payload] } }),
        resetSelectedOperations: (state) => ({ ...state, selectedOperations: {} }),
    },
    extraReducers: {},
})

export const TmpActionConstant = store.actions
export default store.reducer