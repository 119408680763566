import ReferencialAppList from '../../ReferencialAppList'
import React, { useMemo } from 'react'
import { sortBy } from 'lodash'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { push } from '@lagunovsky/redux-react-router'
import i18n from 'simple-react-i18n'
import UsageAction from '../actions/UsageAction'
import HomeAction from '../../../../home/actions/HomeAction'
import Checkbox from '../../../../components/forms/Checkbox'
import { getSandreLabel } from '../../../../utils/StringUtil'
import ReferencialAction from '../../../action/ReferencialAction'
import useProgressDispatch from 'utils/customHook/useProgressDispatch'
import ProgressCard from 'components/card/ProgressCard'

const headersList = ['id', 'name', 'category', 'eligibilityIcon', 'cultureIcon', 'simplifiedCultureIcon', 'multipleEntryIcon', 'calculAutoIcon', 'verifyCoherenceIcon', 'volumeRealEntryIcon', 'showVolumeIfCounterIcon']
const headersExport = ['id', 'name', 'category', 'eligibility', 'culture', 'simplifiedCulture', 'multipleEntry', 'calculAuto', 'verifyCoherence', 'volumeRealEntry', 'showVolumeIfCounter']

const UsagesApp = () => {
    const {
        usages,
        sandreCodes,
    } = useSelector(store => ({
        usages: store.UsageReducer.usages,
        sandreCodes: store.ReferencialReducer.sandreCodes,
    }), shallowEqual)

    const dispatch = useDispatch()

    const { isLoaded } = useProgressDispatch(() => [
        dispatch(ReferencialAction.fetchSandreCodes()),
        dispatch(UsageAction.fetchUsages()),
    ], [])


    const usagesFormatted = useMemo(() => {
        if (!isLoaded) {
            return []
        }
        return sortBy(usages, 'idUsage').map((u) => {
            return {
                ...u,
                name: u.description,
                category: getSandreLabel(sandreCodes, 'USAGES.CATEGORIE', u.category),
                eligibilityIcon: u.eligibility ? (<Checkbox col={ 12 } checked disabled />) : '',
                cultureIcon: u.isCulture ? (<Checkbox col={ 12 } checked disabled />) : '',
                multipleEntryIcon: u.multipleEntry ? (<Checkbox col={ 12 } checked disabled />) : '',
                calculAutoIcon: u.calculAuto ? (<Checkbox col={ 12 } checked disabled />) : '',
                verifyCoherenceIcon: u.checkCoherence ? (<Checkbox col={ 12 } checked disabled />) : '',
                volumeRealEntryIcon: u.volumeRealEntry ? (<Checkbox col={ 12 } checked disabled />) : '',
                showVolumeIfCounterIcon: u.showVolumeIfCounter ? (<Checkbox col={ 12 } checked disabled />) : '',
                simplifiedCultureIcon: u.simplifiedCulture ? (<Checkbox col={ 12 } checked disabled />) : '',
                eligibility: u.eligibility ? i18n.yes : i18n.no,
                culture: u.isCulture ? i18n.yes : i18n.no,
                multipleEntry: u.multipleEntry ? i18n.yes : i18n.no,
                calculAuto: u.calculAuto ? i18n.yes : i18n.no,
                verifyCoherence: u.checkCoherence ? i18n.yes : i18n.no,
                volumeRealEntry: u.volumeRealEntry ? i18n.yes : i18n.no,
                showVolumeIfCounter: u.showVolumeIfCounter ? i18n.yes : i18n.no,
                simplifiedCulture: u.simplifiedCulture ? i18n.yes : i18n.no,
                headers: headersExport,
            }
        })
    }, [isLoaded, sandreCodes, usages])

    if (isLoaded) {
        return (
            <ReferencialAppList
                title={i18n.usages}
                data={usagesFormatted}
                type={{ headers: headersList }}
                referenceData={usages}
                newAction={() => dispatch(push('/territory/settings/usage/new'))}
                link='territory/settings/usage'
                setTitleAction={() => dispatch(HomeAction.setTitle([{
                    title: i18n.steering,
                    href: 'territory',
                }, {
                    title: i18n.settings,
                    href: 'territory/settings',
                }, {
                    title: i18n.usages,
                    href: 'territory/settings/usages',
                }]))}
                customHeaders={{
                    eligibilityIcon: i18n.eligibility,
                    cultureIcon: i18n.culture,
                    multipleEntryIcon: i18n.multipleEntry,
                    calculAutoIcon: i18n.calculAuto,
                    verifyCoherenceIcon: i18n.verifyCoherence,
                    volumeRealEntryIcon: i18n.volumeRealEntry,
                    showVolumeIfCounterIcon: i18n.showVolumeIfCounter,
                    simplifiedCultureIcon: i18n.simplifiedCulture,
                }}
                showUpdateButton={false}
                showPurgeButton={false}
                showRefencialActionsButton={false}
                round
            />
        )
    }
    return (
        <div className='padding-1'>
            <ProgressCard indeterminate withMessage round />
        </div>
    )
}

export default UsagesApp