module.exports = {
    RECEIVE_ALL_SIMS: 'RECEIVE_ALL_SIMS',
    RECEIVE_SIM: 'RECEIVE_SIM',
    RESET_SIM: 'RESET_SIM',
    RECEIVE_SIM_TYPES: 'RECEIVE_SIM_TYPES',
    RECEIVE_SIMS_LAST_SITUATIONS: 'RECEIVE_SIMS_LAST_SITUATIONS',
    RECEIVE_ALL_SIM_SITUATIONS: 'RECEIVE_ALL_SIM_SITUATIONS',
    RECEIVE_SIM_PICTURES: 'RECEIVE_SIM_PICTURES',
    RECEIVE_SIM_FILES: 'RECEIVE_SIM_FILES',
    RECEIVE_SIM_SUBSCRIPTION_LINK: 'RECEIVE_SIM_SUBSCRIPTION_LINK',
}
