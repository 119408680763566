import { Grid } from '@mui/material'
import Card from 'components/card/Card'
import Icon from 'components/icon/Icon'
import PropTypes from 'prop-types'
import React from 'react'
import { getTextColorByBackground } from 'utils/ColorUtil'
import { MAIN_RADIUS } from 'utils/constants/Theme'

const AlertPanel = ({
    title,
    icon,
    color,
    comment,
    round = true,
}) => (
    <Card cardStyle={{ borderRadius: round ? MAIN_RADIUS : 0, width: '100%' }}>
        <Grid container>
            <Grid container item xs={3} className={`${color}`} alignItems='center' justifyContent='center'>
                <Icon
                    size='small'
                    icon={icon}
                    color={getTextColorByBackground(color)}
                    clickable={false}
                />
            </Grid>
            <Grid container item xs={9} direction='column' sx={{ padding: '0.35rem 0.65rem' }}>
                <b className='bold'>{title}</b>
                <span>{comment}</span>
            </Grid>
        </Grid>
    </Card>
)

AlertPanel.propTypes = {
    title: PropTypes.string,
    icon: PropTypes.string,
    color: PropTypes.string,
    comment: PropTypes.string,
    round: PropTypes.bool,
}

export default AlertPanel