import DtoFile from '../../../../components/file/dto/DtoFile'
import DtoPicture from '../../../../station/dto/DtoPicture'
import { RESET_ALL_MATERIELS } from '../../../constants/MaterielConstants'
import { RESET_EQUIPMENT } from '../../equipment/constants/EquipmentConstants'
import {
    RECEIVE_ALL_SENSORS, RECEIVE_ALL_SENSOR_SITUATIONS, RECEIVE_SENSOR, RECEIVE_SENSORS_LAST_SITUATIONS, RECEIVE_SENSOR_FILES,
    RECEIVE_SENSOR_PICTURES,
    RECEIVE_SENSOR_TYPES, RESET_SENSOR,
} from '../constants/SensorConstants'
import DtoSensorSituation from '../dto/DtoSensorSituation'
import DtoSensorType from '../dto/DtoSensorType'
import SensorDto from '../dto/SensorDto'

export const store = {
    sensors: [],
    sensor: {},
    sensorTypes: [],
    sensorSituations: [],
    sensorsLastSituations: [],
    sensorPictures: [],
    sensorFiles: [],
}

export function SensorReducer(state = {}, action) {
    switch (action.type) {
        case RECEIVE_ALL_SENSORS:
            return {
                ...state,
                sensors: action.sensors.map(sensor => new SensorDto(sensor)),
            }
        case RECEIVE_SENSOR:
            return {
                ...state,
                sensor: new SensorDto(action.sensor),
            }
        case RECEIVE_SENSOR_TYPES:
            return {
                ...state,
                sensorTypes: action.sensorTypes.map(t => new DtoSensorType(t)),
            }
        case RECEIVE_ALL_SENSOR_SITUATIONS:
            return {
                ...state,
                sensorSituations: action.sensorSituations.map(m => new DtoSensorSituation(m)),
            }
        case RECEIVE_SENSORS_LAST_SITUATIONS:
            return {
                ...state,
                sensorsLastSituations: action.sensorsLastSituations.map(s => new DtoSensorSituation(s)),
            }
        case RECEIVE_SENSOR_PICTURES:
            return {
                ...state,
                sensorPictures: action.sensorPictures.map(el => new DtoPicture(el)),
            }
        case RECEIVE_SENSOR_FILES:
            return {
                ...state,
                sensorFiles: action.sensorFiles.map(el => new DtoFile(el)),
            }
        case RESET_SENSOR:
            return {
                ...state,
                sensor: {},
                sensorSituations: [],
                sensorPictures: [],
                sensorFiles: [],
            }
        case RESET_EQUIPMENT:
            return {
                ...state,
                sensorTypes: [],
            }
        case RESET_ALL_MATERIELS:
            return {
                ...state,
                ...store,
            }
        default:
            return state
    }
}