import { Button, Grid } from '@mui/material'
import Icon from 'components/icon/Icon'
import { DialogActionsMUI, DialogContentMUI, DialogMUI, DialogTitleMUI } from 'components/styled/Dialog'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import MultiContactsAutocomplete from 'referencial/components/contact/components/MultiContactsAutocomplete'
import MultiContributorsAutocomplete from 'referencial/components/contributor/components/MultiContributorsAutocomplete'
import i18n from 'simple-react-i18n'
import { authorizeContributors, authorizeFilter } from 'utils/HabilitationUtil'
import Input from '../../../../components/forms/Input'
import Select from '../../../../components/forms/Select'
import DtoPiezometerContributorLink from '../../../../piezometry/dto/DtoPiezometerContributorLink'
import { SANDRE } from '../../../../referencial/constants/ReferencialConstants'
import { getHour } from '../../../../utils/DateUtil'
import { onChangeHour } from '../../../../utils/FormUtils'
import { getSandreList } from '../../../../utils/StoreUtils'
import { StyledFieldSet } from 'components/StyledElements'
import SimpleDatePicker from 'components/forms/SimpleDatePicker'

const ContributorModal = ({
    contributor = {},
    isOpen = false,
    setIsOpen = () => { },
    onRegister = () => { },
    idStation,
}) => {
    const [stateContributor, setStateContributor] = useState(contributor)

    const {
        sandreCodes,
        contributors,
        contacts,
        accountUser,
    } = useSelector(store => ({
        sandreCodes: store.ReferencialReducer.sandreCodes,
        contributors: store.ContributorReducer.contributors,
        contacts: store.ContactReducer.contacts,
        accountUser: store.AccountReducer.accountUser,
    }), shallowEqual)

    const onChangeElement = (changes) => setStateContributor({ ...stateContributor, ...changes })

    return (
        <DialogMUI open={isOpen}>
            <DialogTitleMUI style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                {i18n.editContributorLink}
                <Icon style={{ color: 'white' }} size='small' icon='close' onClick={() => setIsOpen(false)} />
            </DialogTitleMUI>
            <DialogContentMUI>
                <StyledFieldSet>
                    <Grid container justifyContent='space-between' spacing={2} style={{ paddingTop: 10 }}>
                        <Grid item xs={6}>
                            <SimpleDatePicker
                                col={12}
                                value={stateContributor.startDate}
                                label={i18n.startDate}
                                onChange={v => onChangeElement({ startDate: v })}
                                max={stateContributor.endDate}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Input
                                col={12}
                                title={i18n.startHour}
                                value={getHour(stateContributor.startDate)}
                                onChange={v => onChangeHour(v, v2 => onChangeElement({ startDate: v2 }), { max: stateContributor.endDate }, stateContributor.startDate)}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <SimpleDatePicker
                                col={6}
                                value={stateContributor.endDate}
                                label={i18n.endDate}
                                onChange={v => onChangeElement({ endDate: v })}
                                min={stateContributor.startDate}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Input
                                col={6}
                                title={i18n.endHour}
                                value={getHour(stateContributor.endDate)}
                                onChange={v => onChangeHour(v, v2 => onChangeElement({ endDate: v2 }), { min: stateContributor.startDate }, stateContributor.endDate)}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Select
                                col={12}
                                options={getSandreList(sandreCodes, SANDRE.CONTRIBUTOR_TYPES)}
                                label={i18n.type}
                                value={stateContributor.contributorType}
                                onChange={v => onChangeElement({ contributorType: v })}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <MultiContributorsAutocomplete
                                col={6}
                                values={stateContributor.idContributor}
                                label={i18n.contributor}
                                onChange={v => onChangeElement({ idContributor: v })}
                                options={authorizeContributors(contributors, accountUser, stateContributor.idContributor)}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <MultiContactsAutocomplete
                                col={6}
                                values={stateContributor.contactCode}
                                label={i18n.contact}
                                onChange={v => onChangeElement({ contactCode: v })}
                                options={authorizeFilter(contacts, accountUser, stateContributor.contactCode)}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Input
                                col={12}
                                title={i18n.internalReference}
                                value={stateContributor.internalReference}
                                onChange={v => onChangeElement({ internalReference: v })}
                            />
                        </Grid>
                    </Grid>
                </StyledFieldSet>

            </DialogContentMUI>
            <DialogActionsMUI>
                <Button
                    onClick={() => {
                        setIsOpen(false)
                        onRegister(new DtoPiezometerContributorLink({ ...stateContributor, idStation }))
                    }}
                    variant='contained'
                    color='primary'
                    className='center'
                >
                    {i18n.register}
                </Button>
            </DialogActionsMUI>
        </DialogMUI>

    )
}

ContributorModal.propTypes = {
    contributor: PropTypes.instanceOf(DtoPiezometerContributorLink),
    isOpen: false,
    setIsOpen: PropTypes.func,
    onRegister: PropTypes.func,
    idStation: PropTypes.number,
}


export default ContributorModal
