import PropTypes from 'prop-types'
import React from 'react'
import i18n from 'simple-react-i18n'
import AppStore from 'store/AppStore'
import { v4 as uuidv4 } from 'uuid'
import ImportFileFormat from '../modal/ImportFileFormat'
import SieauAction from '../sieau/SieauAction'
import IAction from './IAction'

class Update extends IAction {
    fn = () => {
        return () => {
            const id = uuidv4()
            const content = (
                <ImportFileFormat
                    defaultFormat={this.props.defaultFormat}
                    onUpdate={this.props.onUpdate}
                    onUpdateBis={this.props.onUpdateBis}
                    textValue1={this.props.textValue1}
                    textValue2={this.props.textValue2}
                    biAction={this.props.biAction}
                    importCsv={this.props.importCsv}
                    importXml={this.props.importXml}
                    onImportCsv={this.props.onImportCsv}
                    onImportXml={this.props.onImportXml}
                    importOrUpdate={this.props.importOrUpdate}
                    typeGroup={this.props.typeGroup}
                    actifAuto={this.props.actifAuto}
                    fileContent={this.props.fileContent}
                />
            )
            const actions = (
                <div>
                    <a className='waves-effect waves-teal btn-flat modal-close'>{i18n.close}</a>
                </div>
            )
            const popup = {
                id,
                actions,
                content,
                dismissible: false,
            }
            AppStore.dispatch(SieauAction.setPopup(popup))
        }
    }
    icon = () => {
        return 'sync'
    }
    label = () => {
        return i18n.update
    }
    id = () => {
        return 'update_action_navbar'
    }
}

Update.propTypes = {
    onUpdate: PropTypes.func.isRequired,
    defaultFormat: PropTypes.string,
    onUpdateBis: PropTypes.func,
    textValue1: PropTypes.string,
    textValue2: PropTypes.string,
    biAction: PropTypes.bool,
    importCsv: PropTypes.bool,
    importXml: PropTypes.bool,
    onImportCsv: PropTypes.func,
    onImportXml: PropTypes.func,
    fileContent: PropTypes.func,
    importOrUpdate: PropTypes.func,
    typeGroup: PropTypes.func,
    actifAuto: PropTypes.func,
}

Update.defaultprops = {
    defaultFormat: '',
}

export default Update