/* eslint-disable no-console */
import React, { Component } from 'react'
import { v4 as uuidv4 } from 'uuid'
import moment from 'moment'
import i18n from 'simple-react-i18n'
import PropTypes from 'prop-types'
import { getDate } from '../../utils/DateUtil'
import { hasValue } from '../../utils/NumberUtil'
import AppStore from '../../store/AppStore'
import ToastrAction from 'toastr/actions/ToastrAction'
import { getComponentWithId } from '../../utils/StoreUtils'
import DisplayedValue from './DisplayedValue'
import { isEqualBy } from '../../utils/FormUtils'
import { searchAllCharacters } from '../../utils/StringUtil'

const $ = window.$

class DatePicker extends Component {
    constructor(props) {
        super(props)
        this.state = { id: props.id || searchAllCharacters(props.title + uuidv4()).replaceAll(' ', '_').replace('numero', '').replace('nom', 'libelle'), display: false, inputValue: '' }
    }

    getDatePicker = () => {
        if (this.props.readMode) {
            return (
                <div id={ `${this.state.id}-input-field` }>
                    <DisplayedValue label={ this.props.title } value={ this.state.inputValue } hideNull={ this.props.hideNull }/>
                </div>
            )
        }
        const datepickerPart = this.state.display ? [
            (<i className={ `material-icons datepicker-icon ${this.props.disabled ? 'grey-text' : ''}` }
                style={ { right: 5 } }
            >event_note</i>),
            (<input type='hidden' className='datepicker hidden-datepicker no-margin' id={ this.state.id } data-value={ moment().valueOf() }
                style={ { right: 5 } }
            />),
        ] : null
        const disabled = { readOnly: this.props.disabled }
        return (
            <div>
                <div id={ `${this.state.id}-input-field` } className='input-field' >
                    <input type='text' className='sieau-input no-margin ' placeholder={ i18n.dateFormat } id={ `${this.state.id}-input` } value={ this.state.inputValue }
                        onChange={ v => this.onChangeInputValue(v.target.value) } { ...disabled }
                    />
                    <label htmlFor={ this.state.id } >{ this.props.title }</label>
                    { datepickerPart }
                </div>
            </div>
        )
    }

    onChangeInputValue = (v) => {
        if (v === '' && this.props.mustHaveValue) {
            AppStore.dispatch(ToastrAction.error(i18n.dateFieldMustHaveValue))
        } else {
            if (v.length >= 10) {
                const regex = /\d\d\/\d\d\/\d\d\d\d/g
                const date = moment(v, 'DD/MM/YYYY')
                if (v.length === 10 && v[2] === '/' && v[5] === '/' && date.isValid() && regex.test(v)) {
                    if ((hasValue(this.props.startDate) && date.valueOf() < this.props.startDate) || (hasValue(this.props.endDate) && date.valueOf() > this.props.endDate)) {
                        AppStore.dispatch(ToastrAction.error(i18n.startEndDateError))
                    } else {
                        $(`#${this.state.id}`).pickadate('picker').set('select', date.valueOf())
                        this.callOnChange(this.state.id, date.valueOf())
                    }
                } else {
                    AppStore.dispatch(ToastrAction.error(i18n.incorrectDateInput))
                }
            } else if (v === '') {
                this.callOnChange(this.state.id, null)
            }
            this.setState({ inputValue: v })
        }
    }

    callOnChange = (id, value) => {
        if (this.props.keyObj && this.props.changeObj) {
            this.props.changeObj({ [this.props.keyObj]: value, LAST_FORM: 'DATE' })
        }
        if (this.props.onChange) {
            this.props.onChange(id, value)
        }
    }

    getDateLimits = () => {
        if (this.props.limitMaxDate || this.props.endDate || this.props.startDate) {
            const todayMax = this.props.limitMaxDate ? moment().valueOf() : undefined
            return {
                min: this.props.startDate ? this.props.startDate : undefined,
                max: this.props.endDate ? this.props.endDate : todayMax,
            }
        }
        return {}
    }

    initDatePicker = () => {
        if (!hasValue(this.state.id)) {
            console.error(i18n.datepickerError)
        } else if ($(`#${this.state.id}`)) {
            $(`#${this.state.id}`).pickadate(Object.assign({}, this.getDateLimits(), {
                labelMonthNext: i18n.nextMonth,
                labelMonthPrev: i18n.previousMonth,
                labelMonthSelect: i18n.selectMonth,
                labelYearSelect: i18n.selectYear,
                monthsFull: [i18n.january, i18n.february, i18n.march, i18n.april, i18n.may, i18n.june, i18n.july, i18n.august, i18n.september, i18n.october, i18n.november, i18n.december],
                monthsShort: [i18n.januaryShort, i18n.februaryShort, i18n.marchShort, i18n.aprilShort, i18n.mayShort, i18n.juneShort, i18n.julyShort, i18n.augustShort, i18n.septemberShort, i18n.octoberShort, i18n.novemberShort, i18n.decemberShort],
                weekdaysFull: [i18n.sunday, i18n.monday, i18n.tuesday, i18n.wednesday, i18n.thursday, i18n.friday, i18n.saturday],
                weekdaysShort: [i18n.sundayShort, i18n.mondayShort, i18n.tuesdayShort, i18n.wednesdayShort, i18n.thursdayShort, i18n.fridayShort, i18n.saturdayShort],
                weekdaysLetter: [i18n.sundayLetter, i18n.mondayLetter, i18n.tuesdayLetter, i18n.wednesdayLetter, i18n.thursdayLetter, i18n.fridayLetter, i18n.saturdayLetter],
                selectMonths: true,
                selectYears: 60,
                today: i18n.today,
                clear: i18n.clean,
                close: i18n.close,
                closeOnSelect: false,
                format: 'dd/mm/yyyy',
                formatSubmit: 'dd/mm/yyyy',
                firstDay: '1',
                onSet: (datepicker) => {
                    const id = $('.picker__input--active').attr('id')
                    const datepickerComponent = getComponentWithId(`#${id}-input`)
                    if (datepickerComponent) {
                        datepickerComponent.onDateChange(datepicker)
                    }
                    this.setClearEvent()
                },
                onOpen: () => {
                    this.setClearEvent()
                },
            }))
        }
    }
    setClearEvent = () => {
        $('.picker__clear').click(() => {
            if (this.props.mustHaveValue) {
                AppStore.dispatch(ToastrAction.error(i18n.dateFieldMustHaveValue))
            } else {
                setTimeout(() => {
                    const id = $('.picker--opened .picker__clear').attr('aria-controls')
                    const datepickerComponent = getComponentWithId(`#${id}-input`)
                    if (datepickerComponent) {
                        datepickerComponent.onDateErased()
                    }
                }, 200)
            }
        })
    }
    componentDidUpdate() {
        if (this.props.disabled && $(`#${this.state.id}`) && $(`#${this.state.id}`).pickadate('picker')) {
            $(`#${this.state.id}`).pickadate('picker').stop()
        } else if (!this.props.disabled) {
            this.initDatePicker()
            if (this.props.value && $(`#${this.state.id}`) && $(`#${this.state.id}`).pickadate('picker') && !$('.picker__input--active').attr('id')) {
                $(`#${this.state.id}`).pickadate('picker').set('select', this.props.value)
            }
        }
    }
    componentDidMount() {
        if (!this.props.disabled) {
            this.initDatePicker()
        }
        this.setState({ display: true })
        if (hasValue(this.props.value)) {
            this.setState({ inputValue: getDate(this.props.value) })
        }
    }
    onDateErased = () => {
        const lastValue = this.state.inputValue
        this.setState({ inputValue: '' })
        if ($(`#${this.state.id}-input`).val() || lastValue) {
            this.callOnChange(this.state.id, '')
        }
    }
    onDateChange = (datepicker) => {
        if (datepicker.select && datepicker.select !== this.props.value) {
            if ((hasValue(this.props.startDate) && datepicker.select < this.props.startDate) || (hasValue(this.props.endDate) && datepicker.select > this.props.endDate)) {
                AppStore.dispatch(ToastrAction.error(i18n.startEndDateError))
            } else {
                const { id } = this.state
                this.setState({ inputValue: moment(datepicker.select).format('DD/MM/YYYY') }, () => {
                    $(`#${id}-input`).val(moment(datepicker.select).format('DD/MM/YYYY'))
                    this.callOnChange(id, datepicker.select)
                })
                if ($(`#${id}`) && $(`#${id}`).pickadate) {
                    $(`#${id}`).pickadate('picker').close()
                }
            }
        }
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.value !== nextProps.value) {
            this.setState({ inputValue: hasValue(nextProps.value) ? getDate(nextProps.value) : '' })
        }
    }

    render() {
        return this.props.col ? (
            <div className={ `col s${this.props.col}` } >
                { this.getDatePicker() }
            </div>
        ) : this.getDatePicker()
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (this.state.inputValue !== nextState.inputValue) {
            return true
        }
        if (this.props.freezeOpti && isEqualBy(this.props, nextProps, ['value', 'col', 'disabled', 'readMode'])) {
            return false
        }
        return true
    }
}

DatePicker.propTypes = {
    title: PropTypes.string,
    value: PropTypes.number.isRequired,
    onChange: PropTypes.func,
    col: PropTypes.number,
    id: PropTypes.string,
    disabled: PropTypes.bool,
    startDate: PropTypes.number,
    endDate: PropTypes.number,
    mustHaveValue: PropTypes.bool,
    readMode: PropTypes.bool,
    hideNull: PropTypes.bool,
    limitMaxDate: PropTypes.bool,
    keyObj: PropTypes.string,
    changeObj: PropTypes.func,
    freezeOpti: PropTypes.bool,
}

DatePicker.defaultProps = {
    title: '',
    value: '',
    disabled: false,
    readMode: false,
}

export default DatePicker
