import { H_AGRI_SURVEYS_DASHBOARD } from 'account/constants/AccessRulesConstants'
import { push } from '@lagunovsky/redux-react-router'
import HomeAction from 'home/actions/HomeAction'
import BoundaryError from 'log/components/BoundaryError'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Outlet } from 'react-router-dom'
import i18n from 'simple-react-i18n'
import { componentHasHabilitations } from 'utils/HabilitationUtil'
import FixedSlideNav from '../../components/navbar/FixedSlideNav'

class PlanificationApp extends Component {
    componentDidMount() {
        if (!componentHasHabilitations(H_AGRI_SURVEYS_DASHBOARD)) { // A modifier quand react-router sera à jour
            this.props.push('/unauthorized')
            return
        }
        this.props.setFormationPath('demarrer-une-enquete')
        this.props.setHelpLink('enquete-usagers', '')
    }

    componentWillUnmount() {
        this.props.setFormationPath('')
        this.props.setHelpLink('', '')
    }

    getLink = () => {
        return [{
            href: '/planning/dashboard',
            icons: 'dashboard',
            name: i18n.dashboard,
        }, {
            href: '/planning/par',
            icons: 'event_note',
            name: 'PAR',
        }]
    }

    render() {
        const navBarLinks = this.getLink()
        return (
            <div className='row no-margin'>
                <FixedSlideNav links={navBarLinks}/>
                <main>
                    <div className='row no-margin padding-left-1'>
                        <div className='content-page no-padding fullscreen'>
                            <BoundaryError>
                                <Outlet />
                            </BoundaryError>
                        </div>
                    </div>
                </main>
            </div>
        )
    }
}

PlanificationApp.defaultProps = {
    getLink: ('', ''),
}


PlanificationApp.propTypes = {
    params: PropTypes.shape({
        id: PropTypes.string,
    }),
    children: PropTypes.element,
    setFormationPath: PropTypes.func,
    setHelpLink: PropTypes.func,
    push: PropTypes.func,
}

const mapDispatchToProps = {
    setFormationPath: HomeAction.setFormationPath,
    setHelpLink: HomeAction.setHelpLink,
    push,
}

export default connect(null, mapDispatchToProps)(PlanificationApp)
