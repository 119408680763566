import ReferencialDto from '../../../dto/ReferencialDto'

export default class DtoActivity extends ReferencialDto {
    constructor(obj) {
        super(obj)
        this.id = obj.codeactivite
        this.codeactivite = obj.codeactivite
        this.codeactivitev2 = obj.codeactivitev2
        this.libelle = obj.libelle
        this.categorieactivite = obj.categorieactivite
        this.section = obj.section
    }
}