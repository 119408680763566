import createClass from 'create-react-class'
import Map from 'ol/Map'
import Overlay from 'ol/Overlay'
import PropTypes from 'prop-types'
import React from 'react'
import ReactDOM from 'react-dom'
import DtoStation from '../../station/dto/DtoStation'
import PopupContent from './PopupContent'

export default createClass({

    getDefaultProps() {
        return {
            olMap: {},
            event: {},
            layers: [],
            popupProps: { filters: [] },
        }
    },
    getInitialState() {
        return {
            feature: [],
            isPopupOpened: false,
        }
    },
    componentDidMount() {
        const container = ReactDOM.findDOMNode(this.refs.popup)
        this.popup = new Overlay({
            positioning: 'bottom-center',
            autoPan: true,
            autoPanAnimation: {
                duration: 250,
            },
            offset: [0, -5],
        })
        this.props.olMap.addOverlay(this.popup)
        this.popup.setElement(container)
    },

    componentWillReceiveProps(nextProps) {
        const arr = []
        const popup = this.popup
        nextProps.olMap.on('pointermove', evt => {
            const featureOnHover = nextProps.olMap.forEachFeatureAtPixel(evt.pixel, feature => feature)
            if (featureOnHover && featureOnHover.getProperties().hover === true && this.state.isPopupOpened === false) {
                this.setState({ feature: [featureOnHover] })
                popup.setPosition(evt.coordinate)
            } else if (this.state.isPopupOpened === false) {
                popup.setPosition(undefined)
            }
        })
        nextProps.olMap.forEachFeatureAtPixel(this.props.olMap.getEventPixel(nextProps.event), feature => {
            const havePopup = (() => {
                const layer = this.props.layers.find(la => la.checkUuid(feature.getId()))
                if (layer) {
                    return layer.getPopup()
                }
                return null
            })
            const isOnFilter = this.props.popupProps.filters.includes(feature.getGeometry().getType())
            if (havePopup && isOnFilter) {
                arr.push(feature)
            }
        })
        if (arr.length > 0) {
            this.setState({ feature: arr, isPopupOpened: true })
            popup.setPosition(this.props.olMap.getCoordinateFromPixel(this.props.olMap.getEventPixel(nextProps.event)))
        } else {
            this.setState({ isPopupOpened: false })
            popup.setPosition(undefined)
        }
    },

    getContent() {
        if (this.state.feature.length > 0) {
            const filterLayers = this.state.feature.map(ft => this.props.layers.find(la => la.checkUuid(ft.getId())))
            return (
                <PopupContent
                    features={ this.state.feature }
                    layers={ filterLayers }
                    hover={ this.state.isPopupOpened }
                    currentStation={ this.props.currentStation }
                    getStation={ this.props.getStation }
                />
            )
        }
        return []
    },

    render() {
        const { popupStyle } = this.props
        return (
            <div ref='popup' style={popupStyle}>
                { this.getContent() }
            </div>
        )
    },

    propTypes: {
        olMap: PropTypes.instanceOf(Map),
        event: PropTypes.shape({ clientX: PropTypes.number, clientY: PropTypes.number }),
        layers: PropTypes.arrayOf(PropTypes.object),
        popupProps: PropTypes.shape({ filters: PropTypes.arrayOf(PropTypes.string) }),
        popupStyle: PropTypes.shape({}),
        currentStation: PropTypes.instanceOf(DtoStation),
        getStation: PropTypes.func,
    },
})
