import { groupBy } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import { HYDRO, PIEZO, PLUVIO } from '../../../constants/MeasureTypeContants'
import PointPanel from './PointPanel'


const getTypeName = type => {
    switch (type) {
        case HYDRO:
            return i18n.hydrometry
        case PLUVIO:
            return i18n.pluviometry
        case PIEZO:
            return i18n.piezometry
        default:
            return i18n.unknownType
    }
}

const AllPointPanel = ({
    marginTop = true,
    sites = [],
    toggle = false,
    onToggle = () => {},
    onClick = () => {},
    maxHeight = undefined,
}) => {
    const filterByTypes = groupBy(sites, 'siteType')
    return (
        <div className={ `row ${marginTop && 'margin-top-20'}` }>
            {
                Object.keys(filterByTypes).map(siteTypeId => {
                    const filteredSites = filterByTypes[siteTypeId]
                    return (
                        <div>
                            <PointPanel
                                sites={ filteredSites }
                                title={ getTypeName(siteTypeId) }
                                toggleable={ toggle }
                                onToggle={ onToggle }
                                onClick={ onClick }
                                maxHeight={ maxHeight }
                            />
                        </div>
                    )
                })
            }
        </div>
    )
}

AllPointPanel.propTypes = {
    sites: PropTypes.arrayOf(PropTypes.object),
    marginTop: PropTypes.bool,
    toggle: PropTypes.bool,
    onToggle: PropTypes.func,
    onClick: PropTypes.func,
    maxHeight: PropTypes.string,
}

AllPointPanel.defaultProps = {
    marginTop: true,
    maxHeight: undefined,
    toggle: false,
}

export default connect(null)(AllPointPanel)