export default class JobExecution {
    constructor(id, date, duration, statusCode, jobId) {
        this.id = id
        this.date = date
        this.duration = duration
        this.statusCode = statusCode
        this.status = statusCode
        this.jobId = jobId
    }
}
