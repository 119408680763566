import AssociatedCatchmentSelect from 'components/forms/specific/AssociatedCatchmentSelect'
import AssociatedStationSelect from 'components/forms/specific/AssociatedStationSelect'
import SimpleFilterSelect from 'components/forms/specific/SimpleFilterSelect'
import { orderBy, range } from 'lodash'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { useMemo, useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import { STATION_TYPE_CONSTANT, STATION_TYPE_NAME } from 'station/constants/StationConstants'
import Button from 'components/forms/Button'
import ThresholdSelect from 'components/forms/specific/ThresholdSelect'
import CollapseTopBar from 'components/topBar/CollapseToBar'
import SuperMultiAutocomplete from 'components/forms/SuperMultiAutocomplete'
import MultiContributorsAutocomplete from 'referencial/components/contributor/components/MultiContributorsAutocomplete'

const AEPcriteriasPanel = ({
    defaultFilter = {},
    onValidate = () => {},
}) => {
    const {
        qualitometers,
        producersProps,
        supports,
        productionUnits,
        installations,
        distributionUnits,
        resources,
    } = useSelector(store => ({
        qualitometers: store.QualityReducer.qualitometersLight,
        producersProps: store.ContributorReducer.producers,
        supports: store.SupportReducer.supports,
        productionUnits: store.ProductionUnitReducer.productionUnits,
        installations: store.InstallationReducer.installations,
        distributionUnits: store.DistributionUnitReducer.distributionUnits,
        resources: store.ResourceReducer.resources,
    }), shallowEqual)

    const [producers, setProducers] = useState(defaultFilter.producers)
    const [year, setYear] = useState(defaultFilter.year)
    const [referenceThreshold, setReferenceThreshold] = useState(defaultFilter.referenceThreshold)
    const [limitThreshold, setLimitThreshold] = useState(defaultFilter.limitThreshold)
    const [support, setSupport] = useState(defaultFilter.support)
    const [filter, setFilter] = useState(defaultFilter.filter)
    const [filterResults, setFilterResults] = useState(defaultFilter.filterResults)
    const [productionUnit, setProductionUnit] = useState(defaultFilter.productionUnit)
    const [productionUnitAssociation, setProductionUnitAssociation] = useState([])
    const [installation, setInstallation] = useState(defaultFilter.installation)
    const [installationAssociation, setInstallationAssociation] = useState([])
    const [distributionUnit, setDistributionUnit] = useState(defaultFilter.distributionUnit)
    const [distributionUnitAssociation, setDistributionUnitAssociation] = useState([])
    const [resource, setResource] = useState(defaultFilter.resource)
    const [resourceAssociation, setResourceAssociation] = useState([])
    const [catchment, setCatchment] = useState(defaultFilter.catchment)
    const [catchmentAssociation, setCatchmentAssociation] = useState([])

    const yearList = useMemo(() => {
        const list = range(moment().year(), 1899, -1).map(y => ({ code: y, name: y }))
        return orderBy(list, 'code', 'desc')
    }, [])

    return (
        <CollapseTopBar>
            <div className='card-content grey-background padding-1'>
                <div className='row no-margin'>
                    <SimpleFilterSelect
                        stationType={STATION_TYPE_NAME.quality}
                        col={9}
                        onChange={(res, f) => {
                            setFilter(f)
                            setFilterResults(res)
                        }}
                        stations={qualitometers}
                    />
                    <SuperMultiAutocomplete
                        col={3}
                        label={i18n.year}
                        options={yearList}
                        values={year}
                        keyValue='code'
                        onChange={setYear}
                        noSort
                    />
                </div>
                <div className='row no-margin padding-top-1'>
                    <AssociatedCatchmentSelect
                        value={catchment}
                        onChange={(asso, id) => {
                            setCatchment(id)
                            setCatchmentAssociation(asso)
                        }}
                        col={3}
                        stationTypeReturn={STATION_TYPE_CONSTANT.QUALITY}
                    />
                    <AssociatedStationSelect
                        label={i18n.installation}
                        value={installation}
                        options={installations}
                        onChange={(asso, id) => {
                            setInstallation(id)
                            setInstallationAssociation(asso)
                        }}
                        col={3}
                        stationType={STATION_TYPE_CONSTANT.INSTALLATION}
                        stationTypeReturn={STATION_TYPE_CONSTANT.QUALITY}
                        keyLabel={'name'}
                        keyValue={'code'}
                    />

                    <AssociatedStationSelect
                        label={i18n.productionUnit}
                        value={productionUnit}
                        options={productionUnits}
                        onChange={(asso, id) => {
                            setProductionUnit(id)
                            setProductionUnitAssociation(asso)
                        }}
                        col={3}
                        stationType={STATION_TYPE_CONSTANT.PRODUCTION}
                        stationTypeReturn={STATION_TYPE_CONSTANT.QUALITY}
                        keyLabel={'name'}
                        keyValue={'code'}
                    />
                    <AssociatedStationSelect
                        label={i18n.distributionUnit}
                        value={distributionUnit}
                        options={distributionUnits}
                        onChange={(asso, id) => {
                            setDistributionUnit(id)
                            setDistributionUnitAssociation(asso)
                        }}
                        col={3}
                        stationType={STATION_TYPE_CONSTANT.DISTRIBUTION}
                        stationTypeReturn={STATION_TYPE_CONSTANT.QUALITY}
                        keyLabel={'name'}
                        keyValue={'code'}
                    />
                </div>
                <div className='row no-margin padding-top-1'>
                    <AssociatedStationSelect
                        label={i18n.resource}
                        value={resource}
                        options={resources}
                        onChange={(asso, id) => {
                            setResource(id)
                            setResourceAssociation(asso)
                        }}
                        col={3}
                        stationType={STATION_TYPE_CONSTANT.RESOURCE}
                        stationTypeReturn={STATION_TYPE_CONSTANT.QUALITY}
                        keyLabel={'name'}
                        keyValue={'code'}
                    />
                    <MultiContributorsAutocomplete
                        col={6}
                        label={i18n.producer}
                        options={producersProps}
                        keyValue='code'
                        onChange={setProducers}
                        values={producers}
                        multiple
                    />
                    <SuperMultiAutocomplete
                        col={3}
                        label={i18n.support}
                        options={supports}
                        onChange={setSupport}
                        values={support}
                        displayWithCode
                    />
                </div>
                <div className='row no-margin valign-wrapper'>
                    <ThresholdSelect
                        col={3}
                        title={i18n.referenceThreshold}
                        selected={referenceThreshold}
                        onChange={setReferenceThreshold}
                    />
                    <ThresholdSelect
                        col={3}
                        title={i18n.limitThreshold}
                        selected={limitThreshold}
                        onChange={setLimitThreshold}
                    />
                    <Button
                        col={3}
                        className='offset-s3'
                        title={i18n.search}
                        onClick={() => onValidate({
                            producers,
                            year,
                            referenceThreshold,
                            limitThreshold,
                            support,
                            filter,
                            filterResults,
                            productionUnitAssociation,
                            installationAssociation,
                            distributionUnitAssociation,
                            resourceAssociation,
                            catchmentAssociation,
                        })}
                    />
                </div>
            </div>
        </CollapseTopBar>
    )
}

AEPcriteriasPanel.propTypes = {
    onValidate: PropTypes.func,
    defaultFilter: PropTypes.shape({
        producers: PropTypes.arrayOf(PropTypes.number),
        year: PropTypes.number,
        threshold: PropTypes.number,
        support: PropTypes.string,
        filter: PropTypes.number,
        filterResults: PropTypes.arrayOf(PropTypes.number),
    }),
}

export default AEPcriteriasPanel