import React, { useState, useMemo } from 'react'
import { connect } from 'react-redux'
import { filterStationCoordinates, getDefaultCoordinates } from 'utils/mapUtils/CoordinateUtils'
import { transform } from 'ol/proj'
import { PropTypes } from 'prop-types'
import CityDto from 'referencial/components/city/dto/CityDto'
import { objectOf } from 'utils/StoreUtils'
import OlMap from 'utils/mapUtils/Map'
import SingleStationPoint from 'utils/mapUtils/layers/SingleStationPoint'
import { execByType } from 'utils/StationUtils'
import { getSiteType } from 'utils/mapUtils/SiteTypes'
import DtoStation from 'station/dto/DtoStation'
import useDebounce from 'utils/customHook/useDebounce'
import { Grid } from '@mui/material'
import OSM from 'utils/mapUtils/layers/OSM'

const SimpleMoveMapPanel = ({
    station,
    handleChangeValue = () => {},
    citiesIndex,
    type,
    editMap,
    code,
    zoom = 14,
}) => {
    const [x, setX] = useState(station.x)
    const [y, setY] = useState(station.y)
    const [tempZoom, setTempZoom] = useState(zoom)

    const coordinates = useMemo(() => {
        const stationCoordinates = filterStationCoordinates(({ ...station, x, y }), citiesIndex)
        if (stationCoordinates) {
            return [stationCoordinates.x, stationCoordinates.y]
        } else if (editMap) {
            return getDefaultCoordinates()
        }
        return null
    }, [citiesIndex, editMap])

    const layersGroup = useMemo(() => {
        const location = { ...station, x: coordinates?.[0], y: coordinates?.[1], projection: 16 }
        const osm = OSM()
        if (!type) {
            return [osm]
        }
        return [osm,
            SingleStationPoint({
                site: location,
                type: execByType(type, {
                    contact: () => getSiteType('CONTACT', 1),
                    quality: () => getSiteType('QUALITOMETER', 1),
                    piezometry: () => getSiteType('PIEZOMETER', 1),
                    pluviometry: () => getSiteType('PLUVIOMETER', 1),
                    hydrometry: () => getSiteType('HYDROMETRIC_STATION', 1),
                    productionUnit: () => getSiteType('UNIT', 1),
                    distributionUnit: () => getSiteType('UNIT', 1),
                    installation: () => {
                        return (() => {
                            switch (station.installationType) {
                                case 0:
                                    return getSiteType('INSTALLATION_BOREHOLE', 1)
                                case 1:
                                    return getSiteType('INSTALLATION_INDUSTRIAL_SITE', 1)
                                case 2:
                                    return getSiteType('INSTALLATION_STEP', 1)
                                case 3:
                                    return getSiteType('INSTALLATION_FLOW_OBSTRUCTION', 1)
                                case 4:
                                    return getSiteType('INSTALLATION_POLLUTED_SOIL', 1)
                                case 5:
                                    return getSiteType('INSTALLATION_FISHING_SPOT', 1)
                                case 6:
                                    return getSiteType('INSTALLATION_CAVITY', 1)
                                case 7:
                                    return getSiteType('INSTALLATION_SAMPLE_WORK', 1)
                                case 8:
                                    return getSiteType('INSTALLATION_CAPTURE', 1)
                                case 9:
                                    return getSiteType('INSTALLATION_LIFTING_STATION', 1)
                                case 11:
                                    return getSiteType('INSTALLATION_TREATMENT', 1)
                                case 12:
                                    return getSiteType('INSTALLATION_TANK', 1)
                                default:
                                    return getSiteType('INSTALLATION', 1)
                            }
                        })()
                    },
                }),
            })]
    }, [coordinates])

    const changeMap = (value) => {
        const newZoom = value.getView().getZoom()
        const point = transform(value.getView().getCenter(), 'EPSG:3857', 'EPSG:4326')
        const [olX, olY] = point
        setX(olX)
        setY(olY)
        setTempZoom(newZoom)
    }

    useDebounce(() => handleChangeValue({ ...station, x, y, projection: 16, zoom: tempZoom }), 500, [station, x, y, tempZoom])

    const map = useMemo(() => (
        <OlMap
            mapConf={{
                center: coordinates,
                zoom,
                size: 300,
            }}
            layers={layersGroup}
            onMoveEnd={ (!!editMap && code !== 'new') ? changeMap : () => {} }
            onClickMap={() => {}}
        />
    ), [code, coordinates, editMap, layersGroup])

    return !!coordinates && (
        <Grid item xs={12} sx={{ borderRadius: '5px', overflow: 'hidden' }}>
            {map}
        </Grid>
    )
}

SimpleMoveMapPanel.propTypes = {
    station: PropTypes.instanceOf(DtoStation),
    handleChangeValue: PropTypes.func,
    citiesIndex: objectOf(CityDto),
    type: PropTypes.string,
    editMap: PropTypes.bool,
    code: PropTypes.string,
    zoom: PropTypes.number,
}

const mapStateToProps = store => {
    return {
        citiesIndex: store.CityReducer.citiesIndex,
    }
}

export default connect(mapStateToProps)(SimpleMoveMapPanel)