import { Grid } from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import { lightGrey } from 'utils/constants/ColorTheme'
import Input from '../../../../components/forms/Input'
import DtoCampaignAction from '../../../../installation/components/campaign/dto/DtoCampaignAction'
import { SANDRE } from '../../../../referencial/constants/ReferencialConstants'
import { isInstallationType } from '../../../../utils/CampaignUtil'
import { getSandreLabel } from '../../../../utils/StringUtil'


const CampaignActionCard = ({
    campaignAction = new DtoCampaignAction(),
    onClick = () => { },
    installationName = '',
}) => {
    const {
        sandreCodes,
    } = useSelector((store) => ({
        sandreCodes: store.ReferencialReducer.sandreCodes,
    }), shallowEqual)

    return (
        <Grid
            className={'clickable card'}
            onClick={onClick}
            style={{ margin: '5 10' }}
        >
            <Grid item xs={12}
                style={{
                    padding: '5 10',
                    backgroundColor: lightGrey,
                    fontWeight: 'bold',
                    fontSize: '14.3px',
                    margin: 0,
                }}
            >
                {`${installationName} ${campaignAction.campaignName} ${campaignAction.campaignStartDate && (` ${i18n.fromDate} ${campaignAction.campaignStartDate || ''}`)} `}
            </Grid>
            <Grid item xs={12}>
                <Grid container item>
                    <Input
                        col={3}
                        value={getSandreLabel(sandreCodes, SANDRE.VISITES_ACTIONS_RISQUE, campaignAction.risk)}
                        title={i18n.risk}
                        readMode
                    />
                    <Input
                        col={3}
                        value={getSandreLabel(sandreCodes, SANDRE.VISITES_ACTIONS_SECTEUR, campaignAction.localisation)}
                        title={i18n.localisation}
                        readMode
                    />
                    <Input
                        col={3}
                        value={getSandreLabel(sandreCodes, isInstallationType(campaignAction.installationType) && SANDRE.EQUIPEMENTS_TYPE_AEP || SANDRE.EQUIPEMENTS_TYPE_ASST, campaignAction.equipmentType)}
                        title={i18n.equipmentType}
                        readMode
                    />
                    <Input
                        col={3}
                        value={getSandreLabel(sandreCodes, SANDRE.VISITES_ACTIONS_RESPONSABILITE, campaignAction.responsible)}
                        title={i18n.responsible}
                        readMode
                    />
                </Grid>
                <Grid container item xs={12}>
                    <Input
                        col={3}
                        value={getSandreLabel(sandreCodes, SANDRE.VISITES_ACTIONS_CATEGORIE, campaignAction.category)}
                        title={i18n.category}
                        readMode
                    />
                    <Input
                        col={3}
                        value={getSandreLabel(sandreCodes, SANDRE.VISITES_ACTIONS_TYPE_INTERVENTION, campaignAction.interventionType)}
                        title={i18n.interventionType}
                        readMode
                    />
                    <Input
                        col={6}
                        value={campaignAction.comment}
                        title={i18n.comment}
                        readMode
                    />
                </Grid>
            </Grid>
        </Grid>
    )
}

CampaignActionCard.propTypes = {
    campaignAction: PropTypes.instanceOf(DtoCampaignAction),
    onClick: PropTypes.func,
    installationName: PropTypes.string,
}

export default CampaignActionCard
