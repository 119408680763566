import { orderBy, pick } from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import Table from '../../../../components/datatable/Table'
import Input from '../../../../components/forms/Input'
import NumberField from '../../../../components/forms/NumberField'
import RadioButtons from '../../../../components/forms/RadioButtons'
import Select from '../../../../components/forms/Select'
import SelectStationModal from '../../../../components/modal/SelectStationModal'
import Row from '../../../../components/react/Row'
import DtoParametrageDataType from '../../../../piezometry/dto/DtoParametrageDataType'
import PluviometryAction from '../../../../pluviometry/actions/PluviometryAction'
import PluviometerDto from '../../../../pluviometry/dto/PluviometerDto'
import { nbPerPageLabelShort } from '../../../../referencial/constants/ReferencialConstants'
import { getDate } from '../../../../utils/DateUtil'
import { onChangeDate } from '../../../../utils/FormUtils'
import { arrayOf } from '../../../../utils/StoreUtils'
import Job from '../../../dto/DtoJob'

class WeatherMeasuresPanel extends Component {
    constructor(props) {
        super(props)
        this.state = {
            selectStationIsOpen: false,
            selectedStations: null,
        }
    }

    componentDidMount() {
        if (!this.props.pluviometryDataTypes.length) {
            this.props.fetchPluviometryDataTypes()
        }
        if (!this.props.pluviometers.length) {
            this.props.fetchPluviometers()
        }
    }

    onChangeFiltersNew = (changes) => {
        const parameters = {
            ...this.props.job.parameters,
            filters: [JSON.stringify({
                ...this.getFilters(),
                ...changes,
            })],
        }
        this.props.onChangeJob({ parameters })
    }

    getFilters = () => {
        const newFilters = this.props.job.parameters.filters.length ? JSON.parse(this.props.job.parameters.filters[0]) : {}
        return { measureCote: 2, ...newFilters }
    }

    getHandledData = (filters) => {
        const disabled = !this.props.isEditMode
        const dataTypes = [
            { value: 'rain', label: i18n.rain },
            { value: 'radiation', label: i18n.radiation },
            { value: 'temperature', label: i18n.temperature },
            { value: 'humidity', label: i18n.humidity },
            { value: 'forecastId', label: i18n.forecast },
            { value: 'ETP', label: i18n.etpShort },
        ]
        return dataTypes.map((d) => {
            const onChange = (v) => {
                if (this.props.isEditMode) {
                    this.onChangeFiltersNew({ [d.value]: v })
                }
            }
            return {
                label: d.label,
                dataType: <Select value={ filters[d.value] } options={ this.props.pluviometryDataTypes } onChange={ v => onChange(v) } keyValue='id' keyLabel='label' disabled={ disabled }/>,
            }
        })
    }

    onCloseModal = () => {
        const stations = orderBy((this.state.selectedStations || []).map(s => pick(s, ['id', 'code', 'name'])), 'id')
        this.onChangeFiltersNew({ stations })
        this.setState({ selectStationIsOpen: false })
    }

    changeWMId = (filters, station, newId) => {
        const stations = orderBy([ ...(filters.stations || []).filter(s => s.id !== station.id), { ...station, idWM: newId } ], 'id')
        this.onChangeFiltersNew({ stations })
    }

    render() {
        const disabled = { disabled: !this.props.isEditMode }
        const filters = this.getFilters()
        const data = (filters.stations || []).map(s => ({
            ...s,
            idWM: <NumberField min={0} value={s.idWM} onChange={ id => this.changeWMId(filters, s, id) } {...disabled}/>,
        }))
        const addDataAction = this.props.isEditMode ? [{
            onClick: () => this.setState({ selectStationIsOpen: true }),
            iconName: 'edit',
            tooltip: `${i18n.edit}`,
        }] : []
        const importMode = [
            { code: 1, name: 'Import en continu' },
            { code: 2, name: 'Import par dates' },
        ]
        const WMAPIList = [
            { code: 'JSON', name: 'JSON' },
            { code: 'Fichiers', name: 'Fichiers' },
        ]
        const disabledContinue = { disabled: !this.props.isEditMode || (filters.importMode || 1) !== 1 }
        const disabledDates = { disabled: !this.props.isEditMode || (filters.importMode || 1) !== 2 }
        return (
            <div>
                <div className='row no-margin padding-top-1'>
                    <RadioButtons col={ 3 } title={ i18n.importMode } elements={ importMode } selected={ filters.importMode || 1 } onChange={ v => this.onChangeFiltersNew({ importMode: v }) } {...disabled}/>
                    <NumberField col={ 2 } title={ 'Nombre de jours observés ' } min={ 0 } value={ filters.obsDays } onChange={ v => this.onChangeFiltersNew({ obsDays: v }) } {...disabledContinue}/>
                    <NumberField col={ 2 } title={ 'Nombre de jours prévision (max 9)' } min={ 0 } max={ 9 } value={ filters.prevDays } onChange={ v => this.onChangeFiltersNew({ prevDays: v }) } {...disabledContinue}/>
                    <Input col={ 2 } value={ getDate(filters.startDate) } title={ i18n.startDate } onChange={ v => onChangeDate(v, v2 => this.onChangeFiltersNew({ startDate: v2 })) } {...disabledDates}/>
                    <Input col={ 2 } value={ getDate(filters.endDate) } title={ i18n.endDate } onChange={ v => onChangeDate(v, v2 => this.onChangeFiltersNew({ endDate: v2 })) } {...disabledDates}/>
                </div>
                <div className='row no-margin padding-top-1'>
                    <div className='col s3'>
                        <Table data={ this.getHandledData(filters) } title={ i18n.dataTypes } type={ { headers: ['label', 'dataType'] }}
                            activeHeader condensed sortable showNbElements={ false }
                        />
                    </div>
                    <div className='col s3'>
                        <Row>
                            <RadioButtons onChange={ v => this.onChangeFiltersNew({ apiMode: v }) } elements={ WMAPIList } title='API utilisée' selected={ filters.apiMode } />
                        </Row>
                        <Row>
                            <Input title={ 'Produit Observations Horaire' } value={ filters.hourlyObsProduct } onChange={ v => this.onChangeFiltersNew({ hourlyObsProduct: v }) } />
                        </Row>
                        <Row>
                            <Input title={ 'Produit Observations Journalier' } value={ filters.dailyObsProduct } onChange={ v => this.onChangeFiltersNew({ dailyObsProduct: v }) } />
                        </Row>
                        <Row>
                            <Input title={ 'Produit Prévisions Horaire' } value={ filters.hourlyPrevProduct } onChange={ v => this.onChangeFiltersNew({ hourlyPrevProduct: v }) } />
                        </Row>
                        <Row>
                            <Input title={ 'Produit Prévisions Journalier' } value={ filters.dailyPrevProduct } onChange={ v => this.onChangeFiltersNew({ dailyPrevProduct: v }) } />
                        </Row>
                    </div>
                    <div className='col s6'>
                        <Table
                            data={ data }
                            nbPerPageLabel={ nbPerPageLabelShort }
                            type={ { headers: ['code', 'name', 'idWM'] } }
                            sortable
                            condensed
                            title={i18n.pluviometers}
                            paging
                            activeHeader
                            actions={ addDataAction }
                        />
                    </div>
                </div>
                <div className='row'>
                    <SelectStationModal
                        onClose={ this.onCloseModal }
                        selectStationIsOpen={ this.state.selectStationIsOpen }
                        selectedStations={this.state.selectedStations || filters.stations || []}
                        campaignEvents={[]}
                        stationType='pluviometry'
                        onChangeSelectedStation={tmpStations => this.setState({ selectedStations: tmpStations })}
                    />
                </div>
            </div>
        )
    }
}


WeatherMeasuresPanel.propTypes = {
    job: PropTypes.instanceOf(Job).isRequired,
    onChangeJob: PropTypes.func.isRequired,
    pluviometryDataTypes: arrayOf(DtoParametrageDataType),
    pluviometers: arrayOf(PluviometerDto),
    isEditMode: PropTypes.bool,
    fetchPluviometryDataTypes: PropTypes.func,
    fetchPluviometers: PropTypes.func,
}

const mapStateToProps = store => ({
    pluviometers: store.PluviometryReducer.pluviometers,
    pluviometryDataTypes: store.PluviometryReducer.pluviometryDataTypes,
})

const mapDispatchToProps = {
    fetchPluviometryDataTypes: PluviometryAction.fetchPluviometryDataTypes,
    fetchPluviometers: PluviometryAction.fetchPluviometers,
}

export default connect(mapStateToProps, mapDispatchToProps)(WeatherMeasuresPanel)