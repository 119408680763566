import React, { useEffect, useState } from 'react'
import { arrayOf, getLabel, instanceOf } from '../../../../utils/StoreUtils'
import { shallowEqual, useSelector, useDispatch } from 'react-redux'
import DtoJob from '../../../../import/dto/DtoJob'
import DtoPiezometryStationMeasure from '../../../../station/dto/piezometer/DtoPiezometryStationMeasure'
import Row from '../../../../components/react/Row'
import { getDate, getFullDate } from '../../../../utils/DateUtil'
import { hasValue, round } from '../../../../utils/NumberUtil'
import { getQualificationColor, getStatusIcon } from '../../../../utils/AnalyseUtils'
import { getColorCircleElement, getRGBColor } from '../../../../utils/ColorUtil'
import { getSandreLabel } from '../../../../utils/StringUtil'
import { SANDRE } from '../../../../referencial/constants/ReferencialConstants'
import i18n from 'simple-react-i18n'
import PropTypes from 'prop-types'
import DtoStatus from '../../../../quality/dto/DtoStatus'
import DtoQualification from '../../../../quality/dto/DtoQualification'
import DtoSandreCode from '../../../../referencial/dto/DtoSandreCode'
import DtoContributor from '../../../../station/dto/DtoContributor'
import { getMeasureValue } from '../../../../utils/PiezometryUtils'
import DtoMeasureStats from '../../../../station/dto/piezometer/DtoMeasureStats'
import JobAction from '../../../../import/actions/JobAction'


const SelectedMeasurePanel = ({
    measure = {},
    message= '',
    displayCote= undefined,
    lastLandmark= undefined,
    groundRefAlti= undefined,
}) =>{
    const {
        status,
        qualifications,
        sandreCodes,
        contributors,
        piezometerStatistics,
    } = useSelector(store => ({
        jobExecutions: store.JobReducer.jobExecutions,
        jobs: store.JobReducer.jobs,
        status: store.QualityReducer.status,
        qualifications: store.QualityReducer.qualifications,
        sandreCodes: store.ReferencialReducer.sandreCodes,
        contributors: store.ContributorReducer.contributors,
        piezometerStatistics: store.PiezometerStationReducer.piezometerStatistics,
    }), shallowEqual)

    const [job, setJob] = useState({})

    const dispatch = useDispatch()


    useEffect(() => {
        if (measure?.jobExecutionId) {
            dispatch(JobAction.getJobByJobExecutionId(measure.jobExecutionId)).then(res => {
                setJob(res)
            })
        }
    }, [measure])

    if (measure) {
        const unit = piezometerStatistics.find(t => t.typeId === measure.unit)?.unit ?? ''
        const calculated = getMeasureValue(measure, displayCote, lastLandmark, groundRefAlti)
        const calculatedInitial = getMeasureValue(measure, displayCote, lastLandmark, groundRefAlti, true)
        return (
            <div>
                <Row><div className='col s12'><h6 className='bold'>{ message }</h6></div></Row>
                <Row><div className='col s12'>{ `${getFullDate(measure.date)} : ${round(calculated)} ${unit}${calculated !== calculatedInitial ? ` (${i18n.corrected})` : ''}` }</div></Row>
                { measure.updateDate && measure.updateLogin && <Row><div className='col s12'>{ `${i18n.updatedOn + getDate(measure.updateDate)} ${i18n.by} ${measure.updateLogin}` }</div></Row> || '' }
                <Row>
                    { hasValue(measure.status) && <div className='col s6'>{ getStatusIcon(measure.status, status) }{ getLabel(status, measure.status) }</div> || '' }
                    { hasValue(measure.qualification) && <div className='col s6'>{ getColorCircleElement(getRGBColor(getQualificationColor(measure.qualification)), true) }{ getLabel(qualifications, measure.qualification) }</div> || '' }
                </Row>
                <Row>
                    { hasValue(measure.nature) && <div className='col s6'>{ (`Nature : ${getSandreLabel(sandreCodes, SANDRE.PIEZOMETER_MEASURE_NATURE, measure.nature)}`) }</div> || '' }
                    { hasValue(measure.producer) && <div className='col s6'>{ (`${i18n.producer} : ${getLabel(contributors, measure.producer, 'mnemonique')}`) }</div> || '' }
                </Row>
                <Row>
                    { hasValue(measure.landmark) && <div className='col s6'>{ `${i18n.landmark} : ${measure.landmark}${unit}` }</div> || '' }
                    { hasValue(measure.refAlti) && <div className='col s6'>{ `${i18n.refAlti} : ${measure.refAlti}${unit}` }</div> || '' }
                </Row>
                <Row>
                    { hasValue(measure.measureMode) && <div className='col s6'>{ (`${i18n.obtainningMode} : ${getSandreLabel(sandreCodes, SANDRE.OBTAINING_MODE, measure.measureMode)}`) }</div> || '' }
                    { hasValue(measure.initialPoint) && <div className='col s6'>{ `${i18n.initialPoint} : ${measure.initialPoint === 1 ? i18n.yes : i18n.no}` }</div> || '' }
                </Row>
                <Row>
                    { hasValue(measure.manager) && <div className='col s6'>{ (`${i18n.administrator} : ${getLabel(contributors, measure.manager, 'mnemonique')}`) }</div> || '' }
                    { hasValue(measure.validator) && <div className='col s6'>{ (`${i18n.validator} : ${getLabel(contributors, measure.validator, 'mnemonique')}`) }</div> || '' }
                </Row>
                { (calculated !== calculatedInitial) && <Row><div className='col s12'>{ `${i18n.beforeCorrectionValue} : ${round(calculatedInitial)}${unit}` }</div></Row> || '' }
                { measure.importDate && <Row><div className='col s12'>{ `${i18n.createdOn} ${getFullDate(measure.importDate)}` }</div></Row> || '' }
                { job && <Row><div className='col s12'>{ `${i18n.importName} : ${job?.name || ''} - ${job?.login || ''}`}</div></Row> || '' }
            </div>
        )
    }
    return null
}

SelectedMeasurePanel.propTypes = {
    measure: instanceOf(DtoPiezometryStationMeasure),
    jobs: arrayOf(DtoJob),
    message: PropTypes.string,
    status: arrayOf(DtoStatus),
    qualifications: arrayOf(DtoQualification),
    sandreCodes: arrayOf(DtoSandreCode),
    contributors: arrayOf(DtoContributor),
    piezoMode: PropTypes.bool,
    displayCote: PropTypes.number,
    lastLandmark: PropTypes.number,
    groundRefAlti: PropTypes.number,
    piezometerStatistics: arrayOf(DtoMeasureStats),
}

export default SelectedMeasurePanel