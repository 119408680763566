import React, { useEffect, useMemo } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import useTitle from 'utils/customHook/useTitle'
import { getStationTitle } from 'utils/StationUtils'
import i18n from 'simple-react-i18n'
import QualityGraphicApp from 'quality/components/graphic/QualityGraphicApp'
import { useParams } from 'react-router'
import ProductionUnitAction from 'productionUnit/actions/ProductionUnitAction'

const ProductionUnitAssociatedGraphic = () => {
    const dispatch = useDispatch()
    const { id } = useParams()

    const {
        productionUnit,
        prodUnitLinks,
    } = useSelector(store => ({
        productionUnit: store.ProductionUnitReducer.productionUnit,
        prodUnitLinks: store.ProductionUnitReducer.prodUnitLinks,
    }), shallowEqual)

    useTitle(() => [{
        title: i18n.productionUnit,
        href: 'productionUnit',
    }, {
        title: getStationTitle(productionUnit),
        href: `station/productionUnit/${id}`,
    }, {
        title: i18n.graphics,
        href: `station/productionUnit/${id}/graphic`,
    }], [productionUnit])

    useEffect(() => {
        dispatch(ProductionUnitAction.fetchProductionUnitLinks(id))
    }, [dispatch, id])

    const ids = useMemo(() => prodUnitLinks.filter(ass => ass.siteType === 3).map(ass => ass.siteCode), [prodUnitLinks])

    return (
        <QualityGraphicApp
            defaultStations={ids}
            hideStationsCriteria
        />
    )
}

export default ProductionUnitAssociatedGraphic