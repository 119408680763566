export default class DtoCityHisto {
    constructor(obj = {}) {
        this.code = obj.cityCode
        this.cityCode = obj.cityCode
        this.name = obj.name
        this.situation = obj.situation
        this.departmentNumber = obj.departmentNumber
        this.area = obj.area
        this.countryCode = obj.countryCode
        this.cityAttachmentCode = obj.cityAttachmentCode
        this.labelWithCode = `${obj.name} [${obj.cityCode}]`
    }
}