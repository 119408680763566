import PropTypes from 'prop-types'
import React, { useState } from 'react'
import i18n from 'simple-react-i18n'
import Card from 'components/card/Card'
import { Button, Grid } from '@mui/material'
import Input from 'components/forms/Input'
import SimpleCheckbox from 'components/forms/SimpleCheckbox'

const emptyFilter = {
    searchValue: undefined,
    lowWaterCompliance: undefined,
    notLowWaterCompliance: undefined,
    incomplete: true,
    conform: true,
    closeOverrun: true,
    overrun: true,
    ugWithoutScenario: true,
}

const FormFilterScenarios = ({ onChange = () => { } }) => {
    const [tmpFilter, setTmpFilter] = useState(emptyFilter)

    const onReset = () => {
        onChange(emptyFilter)
        setTmpFilter(emptyFilter)
    }

    return (
        <Card title={i18n.criterias} round>
            <Grid container alignItems='center' className='padding-1' spacing={1}>
                <Grid item xs={3}>
                    <Input
                        col={12}
                        containerClassName='no-margin'
                        title={ i18n.search }
                        value={ tmpFilter.searchValue }
                        onChange={(v) => setTmpFilter({ ...tmpFilter, searchValue: v })}
                        onEnterKeyPress={() => onChange(tmpFilter)}
                    />
                </Grid>
                <Grid item xs={9} style={{ display: 'flex', alignItems: 'center' }}>
                    <SimpleCheckbox
                        checked={tmpFilter.lowWaterCompliance}
                        label={i18n.lowWaterCompliance}
                        onToggle={(_, v) => setTmpFilter({ ...tmpFilter, lowWaterCompliance: v })}
                    />
                    <SimpleCheckbox
                        checked={tmpFilter.notLowWaterCompliance}
                        label={i18n.notLowWaterCompliance}
                        onToggle={(_, v) => setTmpFilter({ ...tmpFilter, notLowWaterCompliance: v })}
                    />
                    <SimpleCheckbox
                        checked={tmpFilter.ugWithoutScenario}
                        label={i18n.managementUnitsWithoutScenario}
                        onToggle={(_, v) => setTmpFilter({ ...tmpFilter, ugWithoutScenario: v })}
                    />
                </Grid>
                <Grid item xs={12} container justifyContent='space-between'>
                    <Grid item xs='auto' container>
                        <Grid item>
                            <SimpleCheckbox
                                label={i18n.AUPtoComplete}
                                checked={tmpFilter.incomplete}
                                onToggle={(_, v) => setTmpFilter({ ...tmpFilter, incomplete: v })}
                                labelStyle={{ padding: '0.25rem 1rem', borderRadius: '5px', backgroundColor: 'grey', color: 'white !important' }}
                                tooltip={i18n.AUPtoCompleteDescription}
                            />
                        </Grid>
                        <Grid item sx={{ paddingLeft: '2rem' }}>
                            <SimpleCheckbox
                                label={i18n.compatibleAUP}
                                checked={tmpFilter.conform}
                                onToggle={(_, v) => setTmpFilter({ ...tmpFilter, conform: v })}
                                labelStyle={{ padding: '0.25rem 1rem', borderRadius: '5px', backgroundColor: 'green', color: 'white !important' }}
                                tooltip={i18n.compatibleAUPDescription}
                            />
                        </Grid>
                        <Grid item sx={{ paddingLeft: '2rem' }}>
                            <SimpleCheckbox
                                label={`${i18n.closeOverrunAUP} (>80%)`}
                                checked={tmpFilter.closeOverrun}
                                onToggle={(_, v) => setTmpFilter({ ...tmpFilter, closeOverrun: v })}
                                labelStyle={{ padding: '0.25rem 1rem', borderRadius: '5px', backgroundColor: 'orange', color: 'white !important' }}
                                tooltip={i18n.closeOverrunAUPDescription}
                            />
                        </Grid>
                        <Grid item sx={{ paddingLeft: '2rem' }}>
                            <SimpleCheckbox
                                label={i18n.overrunAUP}
                                checked={tmpFilter.overrun}
                                onToggle={(_, v) => setTmpFilter({ ...tmpFilter, overrun: v })}
                                labelStyle={{ padding: '0.25rem 1rem', borderRadius: '5px', backgroundColor: 'red', color: 'white !important' }}
                                tooltip={i18n.overrunAUPDescription}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs='auto' container>
                        <Grid item>
                            <Button variant='outlined' onClick={onReset}>
                                {i18n.reinit}
                            </Button>
                        </Grid>
                        <Grid item className='padding-left-1'>
                            <Button variant='contained' onClick={() => onChange(tmpFilter)}>
                                {i18n.search}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Card>
    )
}

FormFilterScenarios.propTypes = {
    onChange: PropTypes.func,
}

export default FormFilterScenarios