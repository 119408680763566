import { groupBy, isUndefined, maxBy, round } from 'lodash'
import { useMemo } from 'react'
import { useSelector, shallowEqual } from 'react-redux'
import i18n from 'simple-react-i18n'
import { getDate, getYear } from 'utils/DateUtil'
import { findApproximateValue } from 'station/components/suivipc/qualitometer/utils/SuiviPCUltils'
import { getMeasureByGroup } from 'quality/utils/PcMonitoringUtil'
import moment from 'moment'

const usePluvioDatas = (pluvioDatas, datesSort) => {
    const {
        pluviometers,
        pluviometryDataTypes,
    } = useSelector(store => ({
        pluviometers: store.PluviometryReducer.pluviometers,
        pluviometryDataTypes: store.PluviometryReducer.pluviometryDataTypes,
    }), shallowEqual)

    const pluvioDatasFormatted = useMemo(() => {
        return pluvioDatas.filter(({ id, type }) => !isUndefined(id) && !isUndefined(type)).map(({ id, type, offset = 0, measures = [] }) => {
            const pluvio = pluviometers.find(p => p.id == id)
            const dataType = pluviometryDataTypes.find(d => d.id === type)
            const startData = {
                parameter: {
                    value: `${i18n.pluviometer} :${pluvio.name} [${pluvio.code}]`,
                    classNameColor: 'grey',
                    tooltip: `${pluvio.name} [${pluvio.code}]`,
                },
                group: { value: '', classNameColor: 'grey' },
                unit: { value: dataType?.label, code: dataType?.label, position: 'right', classNameColor: 'grey' },
                support: { value: '', classNameColor: 'grey' },
                fraction: { value: '', classNameColor: 'grey' },
            }
            const groupedMeasures = groupBy(measures, m => getDate(moment(m.date).add(offset, 'day').valueOf(), 'DD/MM/YYYY'))
            const returned = datesSort.reduce((acc, key) => {
                const [date] = key.split('#:#')
                const foundDeltaDate = findApproximateValue(date, groupedMeasures)
                if (groupedMeasures[date]) {
                    const maxMeasure = maxBy(groupedMeasures[date], 'value')
                    acc[key] = {
                        value: `${round(maxMeasure.value, 2)}`,
                        date: maxMeasure.date,
                        positionCell: 'right',
                        classNameColor: 'grey',
                        color: 'grey',
                        cellType: 'right',
                    }
                } else if (foundDeltaDate.length) {
                    const maxMeasure = maxBy(groupedMeasures[foundDeltaDate[0]], 'value')
                    acc[key] = {
                        value: `${round(maxMeasure.value, 2)}`,
                        date: maxMeasure.date,
                        positionCell: 'right',
                        classNameColor: foundDeltaDate[1],
                        color: foundDeltaDate[1],
                        cellType: 'right',
                    }
                } else {
                    acc[key] = { value: '', result: '', date, positionCell: 'right', classNameColor: 'grey' }
                }
                return acc
            }, startData)
            return returned
        })
    }, [pluvioDatas, pluviometers, pluviometryDataTypes, datesSort])

    return pluvioDatasFormatted
}

const useRegroupPluvioDatas = (pluvioDatas, datesSort, regrouping) => {
    const {
        pluviometers,
        pluviometryDataTypes,
    } = useSelector(store => ({
        pluviometers: store.PluviometryReducer.pluviometers,
        pluviometryDataTypes: store.PluviometryReducer.pluviometryDataTypes,
    }), shallowEqual)

    const pluvioDatasFormatted = useMemo(() => {
        return pluvioDatas.filter(({ id, type }) => !isUndefined(id) && !isUndefined(type)).map(({ id, type, offset = 0, measures = [] }) => {
            const pluvio = pluviometers.find(p => p.id == id)
            const dataType = pluviometryDataTypes.find(d => d.id === type)
            const startData = {
                parameter: {
                    value: `${i18n.pluviometer} :${pluvio.name} [${pluvio.code}]`,
                    classNameColor: 'grey',
                    tooltip: `${pluvio.name} [${pluvio.code}]`,
                },
                group: { value: '', classNameColor: 'grey' },
                unit: { value: dataType?.label, code: dataType?.label, position: 'right', classNameColor: 'grey' },
                support: { value: '', classNameColor: 'grey' },
                fraction: { value: '', classNameColor: 'grey' },
            }

            const filteredMeasures = measures.filter(({ value }) => !isUndefined(value))

            if (datesSort.includes('history')) {
                const measure = getMeasureByGroup(filteredMeasures, 'value', regrouping)
                return {
                    ...startData,
                    history: {
                        value: `${round(measure?.value, 2)}`,
                        date: measure?.date,
                        classNameColor: 'grey',
                        cellType: 'right',
                        positionCell: 'right',
                    },
                }
            }
            const groupedMeasures = groupBy(filteredMeasures, m => getYear(moment(m.date).add(offset, 'day').valueOf()))
            return datesSort.reduce((acc, key) => {
                const measure = getMeasureByGroup(groupedMeasures[key], 'value', regrouping)
                acc[key] = {
                    value: `${round(measure?.value, 2)}`,
                    date: measure?.date,
                    classNameColor: 'grey',
                    cellType: 'right',
                    positionCell: 'right',
                }
                return acc
            }, startData)
        })
    }, [pluvioDatas, pluviometers, pluviometryDataTypes, datesSort, regrouping])

    return pluvioDatasFormatted
}

export {
    usePluvioDatas,
    useRegroupPluvioDatas,
}