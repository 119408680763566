export default class DtoParameterCampaign {
    constructor(obj) {
        this.campaignId = obj.campaignId
        this.parameterCode = obj.parameterCode
        this.unitCode = obj.unitCode
        this.fractionCode = obj.fractionCode
        this.placeCode = obj.placeCode
        this.supportCode = obj.supportCode
        this.frequency = obj.frequency
    }
}