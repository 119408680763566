import React, { Component } from 'react'
import { connect } from 'react-redux'
import ProgressCard from '../../../components/card/ProgressCard'
import DtoProblem from '../../../station/dto/sites/DtoProblem'
import DtoSolution from '../../../station/dto/sites/DtoSolution'
import AppStore from '../../../store/AppStore'
import { arrayOf } from '../../../utils/StoreUtils'
import AdministrationAction from '../../actions/AdministrationAction'

class ProblemSolutionApp extends Component {
    constructor(props) {
        super(props)
        this.state = { dataLoaded: true, progress: 0 }
    }

    componentWillMount() {
        AppStore.dispatch(AdministrationAction.loadAdministrationPoblemSolution(() => this.setState({ dataLoaded: true }), p => this.setState({ progress: p })))
    }

    render() {
        if (this.state.dataLoaded) {
            return (
                <div>
                    test problem solutions
                </div>
            )
        }
        return <ProgressCard progress={ this.state.progress } />
    }
}

ProblemSolutionApp.propTypes = {
    eventProblems: arrayOf(DtoProblem),
    eventSolutions: arrayOf(DtoSolution),
}

const mapStateToProps = store => ({
    eventProblems: store.StationReducer.eventProblems,
    eventSolutions: store.StationReducer.eventSolutions,
})

export default connect(mapStateToProps)(ProblemSolutionApp)