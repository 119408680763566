export default class DtoPredStat {
    constructor(obj) {
        this.source = obj.source
        this.typeId = obj.typeId
        this.idModel = obj.idModel
        this.maxSimulationDate = obj.maxSimulationDate
        this.previousDate = obj.previousDate
        this.horizon = obj.horizon
        this.horizonMode = obj.horizonMode
        this.dates = obj.dates
        this.model = obj.model
    }
}