import React from 'react'
import i18n from 'simple-react-i18n'
import PropTypes from 'prop-types'
import { Grid } from '@mui/material'
import PresentationCard from 'components/card/PresentationCard'
import { IMPORT_STEPS } from 'installation/constants/InstallationConstants'
import excelIcon from 'assets/pictures/logos/excel.svg'
import kmlIcon from 'assets/pictures/logos/kml.svg'

const centerStyle = {
    'align-items': 'center',
    display: 'flex',
    justifyContent: 'center',
}

const ImportTypeStep = ({
    focus = false,
    setFocus = () => { },
    setImportStep = () => { },
}) => {
    return (
        <>
            <Grid container justifyContent='center' alignItems='center' spacing={3} className='padding-top-2'>
                <h4>{i18n.chooseImportType}</h4>
            </Grid>
            <Grid container justifyContent='center' alignItems='center' spacing={3}>
                <Grid item xs={6} >
                    <PresentationCard
                        iconComponent={
                            <object id='color-change-svg' data={excelIcon} type='image/svg+xml' style={{ height: '4rem', width: '100%', paddingRight: 5 }} />
                        }
                        iconContainerStyle={centerStyle}
                        title='Excel'
                        onClick={() => {
                            setImportStep(IMPORT_STEPS.OPTIONS)
                            setFocus('excel')
                        }}
                        isFocused={focus === 'excel'}
                        description={i18n.importExcel}
                    />
                </Grid>
                <Grid item xs={6} >
                    <PresentationCard
                        disabled
                        iconComponent={
                            <object id='color-change-svg' data={kmlIcon} type='image/svg+xml' style={{ height: '4rem', width: '100%', paddingRight: 5 }} />
                        }
                        iconContainerStyle={centerStyle}
                        isFocused={focus === 'kml'}
                        title={i18n.km_polygon}
                        description={i18n.inDeveloppmentFunctionnality}
                    />
                </Grid>
            </Grid>
        </>
    )
}

ImportTypeStep.propTypes = {
    focus: PropTypes.bool,
    setFocus: PropTypes.func,
    setImportStep: PropTypes.func,
}

export default ImportTypeStep