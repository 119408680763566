export default class AddressApiDataGouvDto {
    constructor(obj) {
        this.additionalAddress = obj.additionalAddress // Option[String],
        this.laneNumber = obj.laneNumber // Option[String],
        this.repeatIndex = obj.repeatIndex // Option[String],
        this.laneType = obj.laneType // Option[String],
        this.laneLabel = obj.laneLabel // Option[String],
        this.postalCode = obj.postalCode // Option[String],
        this.cityName = obj.cityName // Option[String],
        this.specialDistribution = obj.specialDistribution // Option[String],
        this.cityCode = obj.cityCode // Option[String],
        this.cedexCode = obj.cedexCode // Option[String],
        this.cedexLabel = obj.cedexLabel // Option[String],
        this.foreignCityName = obj.foreignCityName // Option[String],
        this.foreignCountryCode = obj.foreignCountryCode // Option[String],
        this.foreignCountryLabel = obj.foreignCountryLabel // Option[String]
    }
}