import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { getSortedTableData } from 'components/datatable/TableUtils'
import { getStationsHash } from 'utils/StationUtils'
import SieauAction from 'components/sieau/SieauAction'
import { isObject, keys } from 'lodash'
import Table from 'components/datatable/Table'
import { nbPerPageLabel } from 'referencial/constants/ReferencialConstants'
import { push } from '@lagunovsky/redux-react-router'

const DashboardStationsPanel = ({
    stationType,
    data = [],
}) => {
    const dispatch = useDispatch()

    const {
        selectedSearchValues,
    } = useSelector(store => ({
        selectedSearchValues: store.AdministrationReducer.selectedSearchValues,
    }), shallowEqual)

    const [columnName, setColumnName] = useState()
    const [sort, setSort] = useState()

    const searchValues = selectedSearchValues[stationType]
    const initialSort = (searchValues && searchValues.columnName) && { column: searchValues.columnName, sort: searchValues.sort }

    const setSelectedSearchValueStations = useCallback(() => {
        const sortedStations = getSortedTableData(data.stations, { column: columnName, sort }, false)
        if (!searchValues || !searchValues.stations || getStationsHash(searchValues.stations) !== getStationsHash(sortedStations)) {
            dispatch(SieauAction.update('selectedSearchValues', stationType, { stations: sortedStations, columnName, sort }))
        }
    }, [columnName, data.stations, dispatch, searchValues, sort, stationType])

    useEffect(() => {
        setSelectedSearchValueStations()
    }, [sort, data, stationType, setSelectedSearchValueStations])

    const tableStationsData = data.stations.map(station => {
        const color = station.color || (station.closeDate ? 'grey' : 'white')
        return keys(station).reduce((acc, key) => {
            if (isObject(station[key])) {
                acc[key] = { ...station[key], color }
            } else {
                acc[key] = { value: station[key], color }
            }
            return acc
        }, {})
    })

    return (
        <Table
            id='stationsList'
            title={data.title}
            data={tableStationsData}
            color
            paging
            initialSort={initialSort}
            legendPanel={data.legendPanel}
            nbPerPageLabel={nbPerPageLabel}
            onSort={(newColumnName, newSort) => {
                setColumnName(newColumnName)
                setSort(newSort)
            }}
            type={data.type}
            onClick={s => dispatch(push(`/station/${stationType}/${s.id.value}`))}
            condensed
            sortable
            invertedHeaderStyle
            round
        />
    )
}

DashboardStationsPanel.propTypes = {
    stationType: PropTypes.string,
    data: PropTypes.arrayOf(PropTypes.shape({})),
}

export default DashboardStationsPanel