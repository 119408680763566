import React, { useState } from 'react'
import i18n from 'simple-react-i18n'
import PropTypes from 'prop-types'
import DtoPiezometerChartOptions from '../../../../station/dto/piezometer/DtoPiezometerChartOptions'
import { arrayOf } from '../../../../utils/StoreUtils'
import NumberField from '../../../../components/forms/NumberField'
import Checkbox from '../../../../components/forms/Checkbox'
import { hasValue, round } from '../../../../utils/NumberUtil'
import Row from '../../../../components/react/Row'
import RadioButtons from '../../../../components/forms/RadioButtons'
import { getMeasureValue, getNGFValue } from '../../../../utils/PiezometryUtils'
import Button from '../../../../components/forms/Button'
import PiezometerStationAction from '../../../../station/actions/PiezometerStationAction'
import { WhiteCard } from '../../../../components/styled/Card'
import { StyledFieldSet, StyledLegend } from '../../../../components/StyledElements'
import { CHARTOPTIONS } from '../../suivi/constants/PiezometerSuiviConstants'
import { useDispatch } from 'react-redux'

// ATTENTION : composant réutilisé sur piézo (suivi et validation)
const OptionsToolPanel = ({
    tab = CHARTOPTIONS,
    piezometerChartOptions,
    typeId,
    isPiezo,
    displayCote,
    landmarkValue,
    changeParent = () => {},
    id,
    modeDate,
    yScale,
    groundRefAlti,
    withDateFormat = true,
}) => {
    const dispatch = useDispatch()
    const [validateActive, setValidateActive] = useState(false)
    const [initialOptions, setInitialOptions] = useState(piezometerChartOptions)


    const getPiezoValue = (value) => {
        if (!isPiezo) {
            return value
        }
        if (hasValue(value)) {
            return round(getMeasureValue({ NGF: value }, displayCote, landmarkValue, groundRefAlti), 2)
        }
        return null
    }

    const getPiezoNGF = (value) => {
        if (!isPiezo) {
            return value
        }
        if (hasValue(value)) {
            return round(getNGFValue({ value }, displayCote, landmarkValue, groundRefAlti), 2)
        }
        return null
    }

    const onCancel = () => {
        changeParent({ tabLocked: false, piezometerChartOptions: initialOptions })
        setValidateActive(false)
    }

    const onSave = () => {
        dispatch(PiezometerStationAction.updatePiezometerChartOptions(id, piezometerChartOptions, () => {
            setValidateActive(false)
            setInitialOptions(piezometerChartOptions)
            changeParent({ tabLocked: false })
        }))
    }

    const chartOption = piezometerChartOptions.find(d => parseInt(d.dataType ?? -1) === typeId) || new DtoPiezometerChartOptions({
        dataType: typeId.toString(),
        intervalYear: 5,
        displayXIntervalYear: '1',
        displayYIntervalYear: '1',
        displayData: true,
    })
    const change = (changes) => {
        const newOption = { ...chartOption, ...changes }
        changeParent({ tabLocked: true, piezometerChartOptions: [ ...piezometerChartOptions.filter(c => parseInt(c.dataType ?? -1) !== typeId), newOption] })
        setValidateActive(true)
    }
    const scales = [
        { value: 'auto', label: i18n.automatic },
        { value: 'manual', label: i18n.manual },
    ]
    const mode = hasValue(chartOption.maxYear) || hasValue(chartOption.minYear) ? 'manual' : 'auto'
    if (tab !== CHARTOPTIONS) {
        return null
    }
    const modeDateList = [
        {
            code: 'allDate',
            name: 'DD/MM/YYYY hh:mm:ss',
        }, {
            code: 'dailyDate',
            name: 'DD/MM/YYYY',
        },
    ]
    return (
        <WhiteCard className='margin-top-1' title={i18n.chartOptions} round>
            <div className='row no-margin padding-1'>
                <StyledFieldSet>
                    <StyledLegend>&nbsp;{ i18n.yScale }&nbsp;</StyledLegend>
                    <Row>
                        <NumberField
                            col={ 12 }
                            value={ chartOption.intervalYear || 5 } min={ 1 }
                            title={ i18n.intervalCount }
                            onChange={v => change({ intervalYear: v })}
                        />
                    </Row>
                    <Row>
                        <Checkbox
                            col={ 12 }
                            checked={ hasValue(chartOption.displayYIntervalYear) ? chartOption.displayYIntervalYear === '1' : true }
                            label={ i18n.Ygrid }
                            onChange={v => change({ displayYIntervalYear: v ? '1' : '0' })}
                        />
                    </Row>
                    <Row className='padding-top-1'>
                        <RadioButtons title={ i18n.chartScale } elements={ scales } col={ 12 }
                            selected={ mode }
                            onChange={ v => {
                                if (v !== mode) {
                                    if (v === 'manual') {
                                        change({ maxYear: getPiezoNGF(yScale.max), minYear: getPiezoNGF(yScale.min) })
                                    }
                                    if (v === 'auto') {
                                        change({ maxYear: null, minYear: null })
                                    }
                                }
                            } }
                        />
                    </Row>
                    <Row>
                        <NumberField
                            col={ 12 } disabled={ mode === 'auto' }
                            value={ getPiezoValue(chartOption.maxYear) }
                            title={ i18n.max }
                            onChange={v => change({ maxYear: getPiezoNGF(v) })} floatValue
                        />
                    </Row>
                    <Row>
                        <NumberField
                            col={ 12 } disabled={ mode === 'auto' }
                            value={ getPiezoValue(chartOption.minYear) }
                            title={ i18n.min }
                            onChange={v => change({ minYear: getPiezoNGF(v) })} floatValue
                        />
                    </Row>
                </StyledFieldSet>
                <StyledFieldSet>
                    <StyledLegend>&nbsp;{i18n.xScale}&nbsp;</StyledLegend>
                    <Row>
                        <Checkbox
                            col={ 12 }
                            checked={ hasValue(chartOption.displayXIntervalYear) ? chartOption.displayXIntervalYear === '1' : true }
                            label={ i18n.Xgrid }
                            onChange={v => change({ displayXIntervalYear: v ? '1' : '0' })}
                        />
                    </Row>
                </StyledFieldSet>
                {
                    withDateFormat && (
                        <StyledFieldSet>
                            <StyledLegend>{ i18n.formatDate }</StyledLegend>
                            <RadioButtons selected={modeDate} onChange={v => changeParent({ modeDate: v })} elements={modeDateList} />
                        </StyledFieldSet>
                    )
                }
            </div>
            <Row className='padding-bottom-1 center-align'>
                <Button
                    tooltip={ i18n.cancel }
                    onClick={ onCancel }
                    icon='cancel'
                    className='red btn-floating btn-large margin-left-2 margin-right-2 z-index-10 '
                    disabled={ !validateActive }
                />
                <Button
                    tooltip={ i18n.register }
                    onClick={ onSave }
                    icon='save'
                    disabled={ !validateActive }
                    className={ `btn-floating btn-large z-index-10 ${validateActive ? 'pulse' : ''}` }
                />
            </Row>
        </WhiteCard>
    )
}

OptionsToolPanel.propTypes = {
    tab: PropTypes.string,
    id: PropTypes.string,
    piezometerChartOptions: arrayOf(DtoPiezometerChartOptions),
    changeParent: PropTypes.func,
    dataType: PropTypes.number,
    yScale: PropTypes.objectOf(PropTypes.number),
    displayCote: PropTypes.number,
    landmarkValue: PropTypes.number,
    groundRefAlti: PropTypes.number,
    modeDate: PropTypes.string,
    typeId: PropTypes.number,
    isPiezo: PropTypes.bool,
    withDateFormat: PropTypes.bool,
}

export default OptionsToolPanel