/* eslint-disable camelcase */

export default class DtoDangerDocument {
    constructor(obj, docIndex) {
        this.idSurvey = obj.idSurvey // Option[Long],
        this.idCampaign = obj.idCampaign // Option[Long],
        this.idVisit = obj.idVisit // Option[Long],
        this.siteType = obj.siteType // Option[Long],
        this.siteCode = obj.siteCode // Option[Long],
        this.idDanger = obj.idDanger // Option[Long],
        this.docName = obj.docName // Option[String],
        this.description = obj.description // Option[String],

        this.index = docIndex || 10000
    }
}
