module.exports = {
    POLLUTION_LEVEL_COLOR: [
        { rate: 1, color: 'BLUE', html: '#2691ff' },
        { rate: 2, color: 'CYAN', html: '#7dccb9' },
        { rate: 3, color: 'GREEN', html: '#4caf50' },
        { rate: 4, color: 'YELLOW', html: '#ffec13' },
        { rate: 5, color: 'ORANGE_LIGHT', html: '#fdc97d' },
        { rate: 6, color: 'ORANGE', html: '#f6a542' },
        { rate: 7, color: 'RED_LIGHT', html: '#f95e54' },
        { rate: 8, color: 'RED', html: '#f31919' },
    ],
}
