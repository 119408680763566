/* eslint-disable camelcase */
import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import Table from '../../../../components/datatable/Table'
import DtoSTEP from '../dto/DtoSTEP'
import { getExport } from '../../../../utils/linkUtils'
import { hasValue } from 'utils/NumberUtil'
import { getLabel } from 'utils/StoreUtils'
import useSandreList from 'utils/customHook/useSandreList'
import { SANDRE } from 'referencial/constants/ReferencialConstants'
import STEPRejectPointModal from './modal/STEPRejectPointModal'
import Checkbox from 'components/forms/Checkbox'

const headers = ['sandreCode', 'internalCodeSing', 'name', 'envType', 'sensitiveAreaName', 'nitrogenSensitivity', 'rejectOver1500m', 'comment']

const STEPRejectPointsPanel = ({
    STEP = {},
    readMode = false,
    onChange = () => { },
}) => {
    const [isOpen, setIsOpen] = useState(false)
    const [selectedPoint, setSelectedPoint] = useState(false)

    const STEPRejectedPoints = STEP.link_rejectPoints.map((c, index) => ({
        ...c,
        index,
    }))

    const projections = useSandreList(SANDRE.PROJECTION)
    const referencials = useSandreList(SANDRE.STEP_POINTS_REFERENTIELS)
    const recEnv = useSandreList(SANDRE.MILIEU_RECEPTEUR)

    const tableData = useMemo(() => STEPRejectedPoints.map((srp) => {
        return {
            ...srp,
            sandreCode: getLabel(referencials, srp.idPoint, 'mnemonique'),
            internalCodeSing: srp.pointCode,
            name: srp.envName,
            projection: getLabel(projections, srp.projection),
            envType: getLabel(recEnv, srp.envType),
            rejectOver1500m: srp.rejectOver1500m ? (<Checkbox checked disabled />) : false,
            nitrogenSensitivity: srp.nitrogenSensitivity ? (<Checkbox checked disabled />) : false,
        }
    }), [STEPRejectedPoints, projections, recEnv, referencials])

    const updateRejectPoints = (rejectPoint) => {
        if (!hasValue(selectedPoint.index)) {
            onChange({ link_rejectPoints: [...STEPRejectedPoints, rejectPoint] })
        } else {
            onChange({
                link_rejectPoints: STEPRejectedPoints.map((d, i) => i === selectedPoint.index ? rejectPoint : d),
            })
        }
    }

    const exportAction = getExport(tableData, i18n.rejectPoints, headers)
    const actions = !readMode
        ? [
            {
                iconName: 'note_add',
                tooltip: i18n.add,
                onClick: () => {
                    setIsOpen(true)
                    setSelectedPoint({})
                },
            },
            exportAction,
        ]
        : [exportAction]

    return (
        <>
            <Table
                title={i18n.rejectPoints}
                condensed
                data={tableData}
                className='blue'
                type={{ headers }}
                customHeaders={{
                    referentielsPoint: <span style={{ whiteSpace: 'pre-wrap' }}>{i18n.referentielsPoint}</span>,
                    rejectOver1500m: <span style={{ whiteSpace: 'pre-wrap' }}>{i18n.rejectOver1500m}</span>,
                    nitrogenSensitivity: <span style={{ whiteSpace: 'pre-wrap' }}>{i18n.nitrogenSensitivity}</span>,
                }}
                sortable={!!tableData.length}
                actions={actions}
                alterable={!readMode}
                onAlter={(element) => {
                    setSelectedPoint(STEPRejectedPoints.find((c) => element.index === c.index))
                    setIsOpen(true)
                }}
                deletable={!readMode}
                onDelete={(element) =>
                    onChange({
                        link_rejectPoints: STEPRejectedPoints.filter(
                            (at) => at.index !== element.index,
                        ),
                    })
                }
            />
            {isOpen && <STEPRejectPointModal
                idStation={STEP.idStation}
                rejectPoint={selectedPoint}
                setIsOpen={setIsOpen}
                isOpen={isOpen}
                onSave={updateRejectPoints}
            />}
        </>
    )
}

STEPRejectPointsPanel.propTypes = {
    STEP: PropTypes.instanceOf(DtoSTEP),
    readMode: PropTypes.bool,
    onChange: PropTypes.func,
    isOpen: PropTypes.bool,
    setIsOpen: PropTypes.func,
}

export default STEPRejectPointsPanel
