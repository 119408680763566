import React from 'react'
import PropTypes from 'prop-types'
import { useLocation, useParams } from 'react-router'

const ComponentWithNavObject = ({ Component, ...props }) => {
    const params = useParams()
    const location = useLocation()
    return (
        <Component params={params} location={location} {...props} />
    )
}

ComponentWithNavObject.propTypes = {
    Component: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.func,
        PropTypes.array,
    ]).isRequired,
}

export default ComponentWithNavObject