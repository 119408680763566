'use strict'
import React, { Component } from 'react'
import AppStore from 'store/AppStore'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import {
    getComponentWithId,
    getLabel,
    getMapStateToProps,
    getPropType,
    getPropTypes,
    objectOf,
} from '../../utils/StoreUtils'
import ExportAction from '../../export/actions/ExportAction'
import ToastrAction from 'toastr/actions/ToastrAction'
import { getSiteMarker, getSiteUrl } from '../../utils/mapUtils/SiteTypes'
import { STATION_NAME_ASSOCIATION, STATION_TYPE_NAME } from '../../station/constants/StationConstants'
import { path } from '../../conf/basepath'
import { getDate } from '../../utils/DateUtil'
import {
    filterStationCoordinates,
    filterStationsCoordinates,
    getWGS84Coordinate,
} from '../../utils/mapUtils/CoordinateUtils'
import DtoAnalysisTableItem from '../../quality/dto/DtoAnalysisTableItem'
import { groupBy, orderBy } from 'lodash'
import CityDto from '../../referencial/components/city/dto/CityDto'
import PropTypes from 'prop-types'
import Map from 'ol/Map'
import { geti18n } from '../../utils/StringUtil'
import { analysisHasLocalisation, execByType, getStationType } from '../../utils/StationUtils'
import { List, ListItem, ListItemIcon, ListItemText } from '@mui/material'
import { INSTALLATION_TYPES } from '../../installation/constants/InstallationConstants'


const propsToFetch = {
    cities: false,
    qualitometers: false,
}

class CartographyExportPanel extends Component {
    getBaseUrl = () => {
        return path.replace('api/', '')
    }

    formatData = (body) => {
        const header = {}
        body[0].headers.map(e => {
            header[e] = { value: (() => {
                try {
                    return i18n[e]
                } catch (err) {
                    return e
                }
            })(),
            }
        })

        return [header].concat(body).map(line => {
            return body[0].headers.map(key => {
                if (line[key] || line[key] == 0) {
                    if (line[key].value) {
                        return Object.assign({}, line[key], { value: line[key].value.toString() })
                    }
                    return { value: line[key].toString() }
                }
                return { value: '' }
            })
        })
    }

    getStationsPoints = () => {
        const points = filterStationsCoordinates(this.props.stationsPoints, this.props.citiesIndex)
            .map(point => {
                const loc = getWGS84Coordinate(point)
                return Object.assign({}, point, { localisation: { x: loc[0], y: loc[1], projection: 16 } })
            })
        const { visible } = this.props
        return points.filter(p => execByType(p.typeName, {
            quality: () => visible.QUALITOMETER || visible[`quality${p.stationType}`],
            installation: () => visible.INSTALLATION || visible[`installationType${p.installationType}`],
            piezometry: () => visible.PIEZOMETER,
            pluviometry: () => visible.PLUVIOMETER,
            hydrometry: () => visible.HYDROMETRIC_STATION,
            default: () => false,
        })).map(point => {
            const stationType = execByType(point.typeName, {
                quality: () => point.stationType,
                installation: () => {
                    const installationType= INSTALLATION_TYPES.find(type => type.code === point.installationType)
                    if (!installationType) {
                        return ''
                    }
                    return i18n[installationType.label]
                },
                default: () =>'',
            })
            return {
                code: point.typeName === STATION_TYPE_NAME.installation ? point.id.toString() : point.code || '',
                name: point.name || '',
                cityCode: point.townCode || '',
                city: point.townCode ? getLabel(this.props.cities, point.townCode) : '',
                location: point.location,
                type: i18n[point.typeName],
                x: point.localisation.x,
                y: point.localisation.y,
                projection: point.localisation.projection,
                stationType,
                startDate: point.creationDate ? getDate(point.creationDate) : '',
                endDate: point.close ? getDate(point.close) : '',
                marker: point.markerIcon || (this.getBaseUrl() + getSiteUrl(STATION_NAME_ASSOCIATION[point.typeName])),
            }
        })
    }

    getExternalSites = () => {
        if (this.props.layers && (this.props.layers.includes('POLLUTED_SOIL') || this.props.layers.includes('INDUSTRIAL_SITE'))) {
            return this.props.externalSites.map(site => ({
                code: site.code,
                name: site.name || '',
                location: '',
                city: site.townCode ? getLabel(this.props.cities, site.townCode, 'labelWithCode') : '',
                type: geti18n(site.typeName),
                x: site.xCoordinate,
                y: site.yCoordinate,
                projection: site.projection,
                stationType: getLabel(this.props.sitesTypes, site.type_id, 'definition', 'id'),
                startDate: '',
                endDate: '',
                marker: site.markerIcon || (this.getBaseUrl() + getSiteUrl(getLabel(this.props.sitesTypes, site.type_id, 'parameter', 'id'))),
            }))
        }
        return []
    }
    getStation = () => {
        const station = filterStationCoordinates(this.props.station, this.props.citiesIndex)
        if (this.props.layers && this.props.layers.includes('STATION') && station && station.code) {
            const loc = getWGS84Coordinate(station)
            return {
                code: station.typeName === STATION_TYPE_NAME.installation ? station.id.toString() : station.code || '',
                name: station.name || '',
                cityCode: { value: station.townCode, cellType: 'right' },
                city: station.townCode ? getLabel(this.props.cities, station.townCode) : '',
                location: station.location,
                type: geti18n(station.typeName),
                x: { value: loc[0], format: '0.0000000000', cellType: 'number' },
                y: { value: loc[1], format: '0.0000000000', cellType: 'number' },
                projection: { value: 16, format: '0', cellType: 'number' },
                stationType: getStationType(parseInt((station.stationType))).libelle,
                startDate: station.creationDate ? getDate(station.creationDate) : '',
                endDate: station.close ? getDate(station.close) : '',
                marker: station.markerIcon || (this.getBaseUrl() + getSiteUrl(STATION_NAME_ASSOCIATION[station.typeName])),
            }
        }
        return []
    }

    getAnalysis = () => {
        if (this.props.analysis && this.props.analysis.length) {
            const groupedAnalysis = groupBy(this.props.analysis, 'stationCode')
            return Object.keys(groupedAnalysis).map(code => {
                const analyses = orderBy(groupedAnalysis[code], ['date'], ['desc'])
                const found = this.props.qualitometers.find(q => q.id === analyses[0].stationId)
                return {
                    code,
                    name: analyses[0].stationName,
                    cityCode: { value: found.townCode, cellType: 'right' },
                    city: found ? getLabel(this.props.cities, found.townCode) : '',
                    location: found.location,
                    type: geti18n(analyses.typeName),
                    x: { value: analyses[0].x, format: '0.0000000000', cellType: 'number' },
                    y: { value: analyses[0].y, format: '0.0000000000', cellType: 'number' },
                    projection: { value: analyses[0].projection, format: '0', cellType: 'number' },
                    stationType: found.stationType,
                    startDate: getDate(found.creationDate),
                    endDate: getDate(found.close),
                    marker: analyses[0].markerIcon || (this.getBaseUrl() + getSiteUrl(`QUALITOMETER_${analyses[0].color.toUpperCase()}`)),
                }
            })
        }
        return []
    }

    onExportMarkersKML = () => {
        // const data = [].concat(this.getStation(), this.getStationsPoints(), this.getExternalSites(), this.getAnalysis())
        const component = getComponentWithId('#sieau-map')
        if (component) {
            const data = component.getLayers().filter(l => l.objPoints && l.objPoints.site && l.layer?.values_?.visible).flatMap(l => l.objPoints.site).map(obj => ({
                code: obj.code || '',
                city: `${obj.city?.value}` || `${obj.city}` || `${obj.cityLabel?.value}` || `${obj.cityLabel}` || `${obj.townCode}` || '',
                name: obj.name || '',
                location: obj.location || '',
                stationType: obj.stationType?.toString() || '',
                x: obj.x,
                y: obj.y,
                projection: obj.projection,
                startDate: obj.startDate || '',
                endDate: obj.endDate || '',
                marker: obj.markerIcon || (this.getBaseUrl() + (obj.markerIcon || getSiteMarker(obj))),
            }))
            const usedData = data.filter(d => analysisHasLocalisation(d) && d.marker)
            if (usedData.length) {
                AppStore.dispatch(ExportAction.kmlExport(usedData))
            } else {
                AppStore.dispatch(ToastrAction.error(i18n.noDataToExport))
            }
            this.props.onClose()
        }
    }

    onExportMarkersXLS = () => {
        const data = [].concat(this.getStation(), this.getStationsPoints(), this.getExternalSites(), this.getAnalysis())

        if (data.length) {
            const exportData = {
                data: data.map(d => ({ ...d, headers: ['code', 'name', 'cityCode', 'city', 'type', 'stationType', 'x', 'y', 'projection', 'marker'] })),
                exportType: 'xlsx',
                titleFile: i18n.mapMarkers,
            }
            AppStore.dispatch(ExportAction.export(this.formatData(exportData.data), exportData.exportType, exportData.titleFile))
        } else {
            AppStore.dispatch(ToastrAction.error(i18n.noDataToExport))
        }
        this.props.onClose()
    }

    onExportMarkersCSV = () => {
        const data = [].concat(this.getStation(), this.getStationsPoints(), this.getExternalSites(), this.getAnalysis())

        if (data.length) {
            const exportData = {
                data: data.map(d => ({ ...d, headers: ['code', 'name', 'cityCode', 'city', 'type', 'stationType', 'x', 'y', 'projection', 'marker'] })),
                exportType: 'csv',
                titleFile: i18n.mapMarkers,
            }
            AppStore.dispatch(ExportAction.export(this.formatData(exportData.data), exportData.exportType, exportData.titleFile))
        } else {
            AppStore.dispatch(ToastrAction.error(i18n.noDataToExport))
        }
        this.props.onClose()
    }

    // onExportPNG = () => {
    //     this.props.map.once('postcompose', event => {
    //         const canvas = event.context.canvas
    //         canvas.toBlob(blob => {
    //             download(blob, 'map.png', blob.type)
    //         })
    //     })
    //     this.props.map.renderSync()
    // }

    render() {
        return (
            <List>
                <ListItem button onClick={() => this.onExportMarkersXLS()}>
                    <ListItemIcon>
                        <i className='material-icons left no-margin'>list</i>
                    </ListItemIcon>
                    <ListItemText primary={ i18n.xlsExport } />
                </ListItem>
                <ListItem button onClick={() => this.onExportMarkersCSV()}>
                    <ListItemIcon>
                        <i className='material-icons left no-margin'>description</i>
                    </ListItemIcon>
                    <ListItemText primary={i18n.csvExport} />
                </ListItem>
                <ListItem button onClick={() => this.onExportMarkersKML()}>
                    <ListItemIcon>
                        <i className='material-icons left no-margin'>map</i>
                    </ListItemIcon>
                    <ListItemText primary={i18n.kmlExport} />
                </ListItem>
            </List>
        )
    }
}

CartographyExportPanel.propTypes = getPropTypes(propsToFetch, {
    analysis: PropTypes.arrayOf(PropTypes.instanceOf(DtoAnalysisTableItem)),
    stationsPoints: getPropType('qualitometers'),
    layers: PropTypes.arrayOf(PropTypes.string),
    externalSites: PropTypes.arrayOf(PropTypes.object),
    sitesTypes: PropTypes.arrayOf(PropTypes.object),
    citiesIndex: objectOf(CityDto),
    map: PropTypes.instanceOf(Map),
    visible: PropTypes.shape({
        DISTRIBUTION_UNIT: PropTypes.bool,
        HYDROMETRIC_STATION: PropTypes.bool,
        INSTALLATION: PropTypes.bool,
        PIEZOMETER: PropTypes.bool,
        PLUVIOMETER: PropTypes.bool,
        PRODUCTION_UNIT: PropTypes.bool,
        QUALITOMETER: PropTypes.bool,
        installationType0: PropTypes.bool,
        installationType1: PropTypes.bool,
        installationType2: PropTypes.bool,
        installationType3: PropTypes.bool,
        installationType4: PropTypes.bool,
        installationType5: PropTypes.bool,
        installationType6: PropTypes.bool,
        installationType7: PropTypes.bool,
        installationType8: PropTypes.bool,
        installationType9: PropTypes.bool,
        installationType10: PropTypes.bool,
        installationType11: PropTypes.bool,
        installationType12: PropTypes.bool,
        installationType13: PropTypes.bool,
        installationType14: PropTypes.bool,
        installationType16: PropTypes.bool,
        installationType17: PropTypes.bool,
        installationType18: PropTypes.bool,
        installationType20: PropTypes.bool,
        'installationType-1': PropTypes.bool,
        qualitometerType0: PropTypes.bool,
        qualitometerType1: PropTypes.bool,
        qualitometerType2: PropTypes.bool,
        qualitometerType3: PropTypes.bool,
        qualitometerType4: PropTypes.bool,
        qualitometerType5: PropTypes.bool,
        qualitometerType6: PropTypes.bool,
        qualitometerType7: PropTypes.bool,
        qualitometerType8: PropTypes.bool,
    }),
})

const mapStateToProps = store => getMapStateToProps(propsToFetch, {
    externalSites: store.StationReducer.externalSites,
    sitesTypes: store.StationReducer.sitesTypes,
    citiesIndex: store.CityReducer.citiesIndex,
})

CartographyExportPanel.defaultProps = {
    stationsPoints: [],
}

export default connect(mapStateToProps)(CartographyExportPanel)
