import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import TelecomDto from '../dto/TelecomDto'
import GenericReferencialDto from '../../../dto/GenericReferencialDto'
import Select from '../../../../components/forms/Select'
import Input from '../../../../components/forms/Input'
import Textarea from '../../../../components/forms/Textarea'
import { getDefaultValuesChoiceModal, getMatTypes } from '../../../../utils/MaterielUtils'
import Checkbox from '../../../../components/forms/Checkbox'
import NumberField from '../../../../components/forms/NumberField'
import PowerSupplyTypeDto from '../../powerSupply/dto/PowerSupplyTypeDto'

const TelecomEquipmentPanel = ({
    telecom = {},
    telecomTypes = [],
    powerSupplyTypes = [],
    active = false,
    disabled = true,
    displayDesiccant = true,
    displaySupply = true,
    onChange = () => { },
}) => (
    <div className='col s12 margin-bottom-1' >
        <div className='row no-margin valign-wrapper' style={{ paddingTop: '3px' }}>
            <Select
                options={getMatTypes(telecomTypes, telecom.telecomType)}
                label={i18n.telecomType}
                col={4}
                obligatory
                onChange={value => {
                    onChange({ telecomType: value })
                    getDefaultValuesChoiceModal(telecomTypes.find(c => c.materielType == value), onChange)
                }}
                value={telecom.telecomType}
                nullLabel='&nbsp;'
                active={active}
                disabled={disabled}
            />
            <Input
                col={4}
                title={i18n.imeiAndSerialNumber}
                value={telecom.imei}
                onChange={value => onChange({ imei: value })}
                active={active}
                obligatory
                disabled={disabled}
            />
            <Checkbox
                col={4}
                label={i18n.digitalLineOrRTC}
                checked={telecom.digitalLine}
                onChange={value => onChange({ digitalLine: value })}
                active={active}
                disabled={disabled}
            />
        </div>
        <div className={`row no-margin ${displayDesiccant && displaySupply ? 'valign-wrapper' : ''}`} style={{ paddingTop: '3px' }}>
            {
                displaySupply && (
                    <div className='col s9'>
                        <Select
                            options={getMatTypes(powerSupplyTypes, telecom.powerSupplyType)}
                            label={i18n.powerSupplyType}
                            col={4}
                            onChange={value => onChange({ powerSupplyType: value })}
                            value={telecom.powerSupplyType}
                            nullLabel='&nbsp;'
                            active={active}
                            disabled={disabled}
                        />
                        <NumberField
                            col={4}
                            title={i18n.batteryCount}
                            value={telecom.pileNumber}
                            onChange={value => onChange({ pileNumber: value })}
                            active={active}
                            disabled={disabled}
                        />
                        <NumberField
                            col={4}
                            title={i18n.supplyTime}
                            value={telecom.supplyTime}
                            onChange={value => onChange({ supplyTime: value })}
                            active={active}
                            disabled={disabled}
                        />
                    </div>
                )
            }
            {
                displayDesiccant && (
                    <Checkbox
                        col={3}
                        label={i18n.desiccant}
                        checked={telecom.desiccant === '1'}
                        onChange={value => onChange({ desiccant: value ? '1' : '0' })}
                        active={active}
                        disabled={disabled}
                    />
                )
            }
        </div>
        <div className='row no-margin' style={{ paddingTop: telecom.closeDate ? '3px' : '8px' }}>
            <Textarea
                col={12}
                title={i18n.comment}
                value={telecom.comment}
                onChange={value => onChange({ comment: value })}
                active={active}
                disabled={disabled}
            />
        </div>
    </div >
)

TelecomEquipmentPanel.propTypes = {
    disabled: PropTypes.bool,
    active: PropTypes.bool,
    onChange: PropTypes.func,
    displayDesiccant: PropTypes.bool,
    displaySupply: PropTypes.bool,
    telecom: PropTypes.instanceOf(TelecomDto),
    telecomTypes: PropTypes.arrayOf(GenericReferencialDto),
    powerSupplyTypes: PropTypes.arrayOf(PropTypes.instanceOf(PowerSupplyTypeDto)),
}

const mapStateToProps = store => ({
    telecomTypes: store.TelecomReducer.telecomTypes,
    powerSupplyTypes: store.PowerSupplyReducer.powerSupplyTypes,
})

export default connect(mapStateToProps)(TelecomEquipmentPanel)
