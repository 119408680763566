import React, { useMemo, useState } from 'react'
import { connect, shallowEqual, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import EquipmentTypeDto from '../dto/EquipmentTypeDto'
import EquipmentDto from '../dto/EquipmentDto'
import ContributorDto from '../../../../referencial/components/contributor/dto/ContributorDto'
import Input from '../../../../components/forms/Input'
import Textarea from '../../../../components/forms/Textarea'
import Select from '../../../../components/forms/Select'
import { removeNullKeys } from '../../../../utils/StoreUtils'
import { omit } from 'lodash'
import NetworkDto from '../../../../referencial/components/network/dto/NetworkDto'
import CentralTypeDto from '../../central/dto/CentralTypeDto'
import PowerSupplyTypeDto from '../../powerSupply/dto/PowerSupplyTypeDto'
import DtoSensorType from '../../sensor/dto/DtoSensorType'
import TelecomTypeDto from '../../telecom/dto/TelecomTypeDto'
import DtoSimType from '../../sim/dto/DtoSimType'
import DtoVariousMaterielType from '../../variousMateriel/dto/DtoVariousMaterielType'
import moment from 'moment'
import { authorizeContributors, authorizeFilter } from 'utils/HabilitationUtil'
import User from 'account/dto/User'
import ConfirmModal from 'components/modal/ConfirmModal'
import MultiContributorsAutocomplete from 'referencial/components/contributor/components/MultiContributorsAutocomplete'
import { EQUIPMENT_ID } from 'materiel/constants/MaterielConstants'
import { isFieldRequired } from 'materiel/utils/MaterielUtils'
import { DATEACHAT_OBLIGATOIRE, NUMSERIE_OBLIGATOIRE } from 'materiel/constants/MaterielSettingRuleConstant'
import SimpleDatePicker from 'components/forms/SimpleDatePicker'

const EquipmentPanel = ({
    active = false,
    disabled = true,
    equipment = {},
    equipmentTypes = [],
    centralTypes = [],
    powerSupplyTypes = [],
    sensorTypes = [],
    telecomTypes = [],
    simTypes = [],
    variousMaterielTypes = [],
    contributors = [],
    accountUser = [],
    networks = [],
    onChange = () => { },
    onChangeState = () => { },
}) => {
    const {
        materielSettingRules,
    } = useSelector(store => ({
        materielSettingRules: store.MaterielReducer.materielSettingRules,
    }), shallowEqual)

    const [isOpen, setOpen] = useState(false)
    const [selectedType, setSelectedType] = useState()

    const rules = useMemo(() => {
        return materielSettingRules.filter(({ idMaterielType }) => idMaterielType === EQUIPMENT_ID)
    }, [materielSettingRules])

    const getTypeNewValue = (matType) => removeNullKeys(omit(matType, ['id', 'code', 'label', 'name', 'comment', 'updateDate', 'updateLogin', 'startDate', 'endDate', 'icon', 'headers' ]))

    return (
        <div className='col s12 margin-bottom-1'>
            <div className='row no-margin padding-top-3-px'>
                <Select
                    options={equipmentTypes.filter(e => !!e.label)}
                    label={i18n.type}
                    col={4}
                    value={parseInt(equipment.equipmentType)}
                    onChange={v => {
                        const type = equipmentTypes.find(({ id }) => v === id)
                        if (type) {
                            setOpen(true)
                            setSelectedType(type)
                        } else {
                            onChange({ equipmentType: v })
                        }
                    }}
                    nullLabel='&nbsp;'
                    disabled={disabled}
                    obligatory
                />
                <Input
                    col={4}
                    title={i18n.serialNumber}
                    value={equipment.serialNumber}
                    onChange={value => onChange({ serialNumber: value })}
                    active={active}
                    disabled={disabled}
                    obligatory={isFieldRequired(rules, NUMSERIE_OBLIGATOIRE)}
                />
                <Input col={4}
                    title={i18n.immoNumber}
                    value={equipment.reference}
                    onChange={value => onChange({ reference: value })}
                    active={active}
                    disabled={disabled}
                />
            </div>
            <div className='row no-margin padding-top-3-px'>
                <MultiContributorsAutocomplete
                    options={authorizeContributors(contributors, accountUser, equipment.manufacturerId)}
                    label={i18n.manufacturer}
                    col={6}
                    onChange={(id) => onChange({ manufacturerId: id })}
                    values={equipment.manufacturerId}
                    keyLabel='labelDisplay'
                    disabled={disabled}
                />
                <MultiContributorsAutocomplete
                    options={authorizeContributors(contributors, accountUser, equipment.providerId)}
                    label={i18n.provider}
                    col={6}
                    onChange={(id) => onChange({ providerId: id })}
                    values={equipment.providerId}
                    keyLabel='labelDisplay'
                    disabled={disabled}
                />
            </div>
            <div className='row no-margin padding-top-3-px'>
                <Select
                    options={authorizeFilter(networks, accountUser, equipment.networkCode)}
                    label={i18n.network}
                    col={12}
                    onChange={value => onChange({ networkCode: value })}
                    value={equipment.networkCode}
                    nullLabel='&nbsp;'
                    active={active}
                    disabled={disabled}
                />
            </div>
            <div className='row no-margin padding-top-3-px'>
                <SimpleDatePicker
                    id='manufactureDate'
                    label={i18n.manufactureDate}
                    value={equipment.manufactureDate}
                    onChange={v => onChange({ manufactureDate: v })}
                    col={4}
                    disabled={disabled}
                />
                <SimpleDatePicker
                    id='purchaseDate'
                    label={i18n.purchaseDate}
                    value={equipment.purchaseDate}
                    onChange={v => onChange({ purchaseDate: v })}
                    col={4}
                    disabled={disabled}
                    obligatory={isFieldRequired(rules, DATEACHAT_OBLIGATOIRE)}
                />
                <SimpleDatePicker
                    label={i18n.warrantyExpirationDate}
                    value={equipment.warrantyTime && moment(equipment.purchaseDate).add(equipment.warrantyTime, 'months').valueOf()}
                    col={4}
                    disabled
                />
            </div>
            <div className='row no-margin padding-top-9-px'>
                <Textarea
                    col={12}
                    title={i18n.comment}
                    value={equipment.comment}
                    onChange={value => onChange({ comment: value })}
                    active={active}
                    disabled={disabled}
                />
            </div>
            <ConfirmModal
                isOpen={isOpen}
                title={i18n.materielDefaultValuesMessage}
                onValidate={() => {
                    setOpen(false)
                    const {
                        id,
                        centralType,
                        powerSupplyType,
                        sensorType,
                        simType,
                        telecomType,
                        variousMaterielType,
                        provider,
                    } = selectedType
                    const centrals = centralType.map(type => centralTypes.find(({ materielType }) => materielType === type) || {})
                    const powerSupplies = powerSupplyType.map(type => powerSupplyTypes.find(({ materielType }) => materielType === type) || {})
                    const sensors = sensorType.map(type => sensorTypes.find(({ materielType }) => materielType === type) || {})
                    const sims = simType.map(type => simTypes.find(({ materielType }) => materielType === type) || {})
                    const telecoms = telecomType.map(type => telecomTypes.find(({ materielType }) => materielType === type) || {})
                    const variousMateriels = variousMaterielType.map(type => variousMaterielTypes.find(({ materielType }) => materielType === type) || {})
                    onChangeState({
                        equipment: {
                            equipmentType: id,
                            ...removeNullKeys({
                                ...omit(selectedType, ['id', 'code', 'label', 'name', 'comment', 'updateDate', 'updateLogin', 'startDate', 'endDate', 'icon']),
                                providerId: provider,
                            }),
                            centralIntegrated: !!centrals.length,
                            sensorIntegrated: !!sensors.length,
                            simIntegrated: !!sims.length,
                            telecomIntegrated: !!telecoms.length,
                            powerSupplyIntegrated: !!powerSupplies.length,
                            variousMaterielIntegrated: !!variousMateriels.length,
                        },
                        central: centrals.map(type => ({
                            ...getTypeNewValue(type),
                            providerId: type.provider,
                            centralType: type.materielType,
                            centralChannels: [],
                        })),
                        powerSupply: powerSupplies.map(type => ({
                            ...getTypeNewValue(type),
                            powerSupplyType: type.materielType,
                            providerId: type.provider,
                        })),
                        sensor: sensors.map(type => ({
                            ...getTypeNewValue(type),
                            sensorType: type.materielType,
                            providerId: type.provider,
                        })),
                        sim: sims.map(type => ({
                            ...getTypeNewValue(type),
                            simType: type.materielType,
                            providerId: type.provider,
                        })),
                        telecom: telecoms.map(type => ({
                            ...getTypeNewValue(type),
                            telecomType: type.materielType,
                            providerId: type.provider,
                        })),
                        variousMateriel: variousMateriels.map(type => ({
                            ...getTypeNewValue(type),
                            variousMaterielType: type.materielType,
                            providerId: type.provider,
                        })),
                    })
                }}
                onReject={() => {
                    setOpen(false)
                    onChange({ equipmentType: selectedType.id })
                }}
                onClose={() => setOpen(false)}
            />
        </div>
    )
}

EquipmentPanel.propTypes = {
    disabled: PropTypes.bool,
    active: PropTypes.bool,
    onChange: PropTypes.func,
    equipment: PropTypes.instanceOf(EquipmentDto),
    equipmentTypes: PropTypes.arrayOf(PropTypes.instanceOf(EquipmentTypeDto)),
    contributors: PropTypes.arrayOf(PropTypes.instanceOf(ContributorDto)),
    onChangeState: PropTypes.func,
    networks: PropTypes.arrayOf(PropTypes.instanceOf(NetworkDto)),
    centralTypes: PropTypes.arrayOf(PropTypes.instanceOf(CentralTypeDto)),
    powerSupplyTypes: PropTypes.arrayOf(PropTypes.instanceOf(PowerSupplyTypeDto)),
    sensorTypes: PropTypes.arrayOf(PropTypes.instanceOf(DtoSensorType)),
    telecomTypes: PropTypes.arrayOf(PropTypes.instanceOf(TelecomTypeDto)),
    simTypes: PropTypes.arrayOf(PropTypes.instanceOf(DtoSimType)),
    variousMaterielTypes: PropTypes.arrayOf(PropTypes.instanceOf(DtoVariousMaterielType)),
    accountUser: PropTypes.instanceOf(User),
}

const mapStateToProps = store => ({
    contributors: store.ContributorReducer.contributors,
    equipmentTypes: store.EquipmentReducer.equipmentTypes,
    networks: store.NetworkReducer.networks,
    centralTypes: store.CentralReducer.centralTypes,
    powerSupplyTypes: store.PowerSupplyReducer.powerSupplyTypes,
    sensorTypes: store.SensorReducer.sensorTypes,
    telecomTypes: store.TelecomReducer.telecomTypes,
    simTypes: store.SimReducer.simTypes,
    variousMaterielTypes: store.VariousMaterielReducer.variousMaterielTypes,
    accountUser: store.AccountReducer.accountUser,
})

export default connect(mapStateToProps)(EquipmentPanel)
