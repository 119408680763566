import { Button, Grid } from '@mui/material'
import Icon from 'components/icon/Icon'
import { push } from '@lagunovsky/redux-react-router'
import { difference, groupBy, maxBy, orderBy, uniq } from 'lodash'
import PropTypes from 'prop-types'
import React, { useEffect, useMemo, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import useActions from 'utils/customHook/useActions'
import AlertAction from '../../../../alerting/actions/AlertAction'
import { POLLUTION_LEVEL_COLOR } from '../../../../alerting/constants/PollutionConstants'
import Card from '../../../../components/card/Card'
import ProgressCard from '../../../../components/card/ProgressCard'
import Table from '../../../../components/datatable/Table'
import Checkbox from '../../../../components/forms/Checkbox'
import Input from '../../../../components/forms/Input'
import NumberField from '../../../../components/forms/NumberField'
import Select from '../../../../components/forms/Select'
import CartographyPanel from '../../../../components/map/CartographyPanel'
import { setStationPointScale } from '../../../../components/map/mapTreatments/AddingLayers'
import InstallationAction from '../../../../installation/actions/InstallationAction'
import ParameterAction from '../../../../referencial/components/parameter/actions/ParameterAction'
import { SANDRE } from '../../../../referencial/constants/ReferencialConstants'
import { getStationArrowNav } from '../../../../utils/ActionUtils'
import { exportFile } from '../../../../utils/ExportDataUtil'
import { filterStationCoordinates, getDistance } from '../../../../utils/mapUtils/CoordinateUtils'
import { hasValue } from '../../../../utils/NumberUtil'
import { getLinks, hasLocalisationStation } from '../../../../utils/StationUtils'
import { getLabel, getObjectLabel } from '../../../../utils/StoreUtils'
import { searchAllCharacters } from '../../../../utils/StringUtil'
import WaitAction from 'wait/WaitAction'
import useBoolean from 'utils/customHook/useBoolean'
import useSandreList from 'utils/customHook/useSandreList'
import { AccordionDetailsMUI, AccordionMUI, AccordionSummaryMUI } from 'components/styled/Accordions'
import SelectionSelect from 'components/forms/specific/SelectionSelect'
import SuperMultiAutocomplete from 'components/forms/SuperMultiAutocomplete'

const RatePanelList = ({
    rate = '',
    industrialSites = [],
}) => {
    const dispatch = useDispatch()

    const {
        citiesIndex,
        parameters,
    } = useSelector(store => ({
        citiesIndex: store.CityReducer.citiesIndex,
        parameters: store.ParameterReducer.parameters,
    }), shallowEqual)

    const tableData = industrialSites.map(i => ({
        id: i.id,
        name: `${i.name || ''} [${i.code}]`,
        city: getObjectLabel(citiesIndex[i.townCode], 'labelWithCode'),
        sortValue: i.name || '',
        codeParameter: getLabel(parameters, i.parameterCode, 'displayLabel'),
    }))

    const dataWithValue = tableData.filter(({ sortValue }) => sortValue)
    const dataWithoutValue = tableData.filter(({ sortValue }) => !sortValue)
    return (
        <AccordionMUI defaultExpanded={false}>
            <AccordionSummaryMUI style={{ backgroundColor: POLLUTION_LEVEL_COLOR.find(p => p.rate == rate)?.html ?? 'white' }}>
                {`${i18n.level} ${rate} (${industrialSites.length} ${i18n.industrialSites})`}
            </AccordionSummaryMUI>
            <AccordionDetailsMUI style={{ padding: 0 }}>
                {
                    !!industrialSites.length && (
                        <Table
                            condensed
                            data={[...orderBy(dataWithValue, 'sortValue'), ...dataWithoutValue]}
                            type={{ headers: ['name', 'city', 'codeParameter'] }}
                            showTitle={false}
                            onLineOver={(station) => setStationPointScale(station, true)}
                            onLineOut={(station) => setStationPointScale(station, false)}
                            onClick={({ id }) => dispatch(push(`/station/installation/${id}`))}
                        />
                    )
                }
                {
                    !industrialSites.length && i18n.noIndustrialSite
                }
            </AccordionDetailsMUI>
        </AccordionMUI>
    )
}

RatePanelList.propTypes = {
    rate: PropTypes.string,
    industrialSites: PropTypes.arrayOf(PropTypes.shape({})),
}

const PotentialSourcesPanel = ({
    sites = [],
    filter = {},
    setFilter = () => {},
}) => {
    const {
        pollutionSourceActivities,
    } = useSelector(store => ({
        pollutionSourceActivities: store.AlertReducer.pollutionSourceActivities,
    }), shallowEqual)

    const industrialSiteFamily = useSandreList(SANDRE.INDUSTRIAL_SITE_FAMILY)

    const groups = groupBy(pollutionSourceActivities.filter(p => hasValue(p.rate) && hasValue(p.activityCode)), 'rate')
    const rates = orderBy(Object.keys(groups), v => parseInt(v), ['desc'])

    return (
        <Grid container rowSpacing={1} style={{ marginTop: '10' }}>
            <Grid item xs={10}>
                <Select
                    label={i18n.family}
                    options={industrialSiteFamily}
                    value={filter.familyCode}
                    onChange={v => setFilter(prev => ({ ...prev, familyCode: v }))}
                    nullLabel='&nbsp;'
                    displayWithCode
                />
            </Grid>
            <Grid item xs={12}>
                <div onClick={() => setFilter(prev => ({ ...prev, activeIndustrialSites: !prev.activeIndustrialSites }))}>
                    <Checkbox
                        checked={filter.activeIndustrialSites}
                        label={i18n.activeIndustrialSites}
                    />
                </div>
            </Grid>
            {
                rates.map(rate => {
                    const activityCodes = groups[rate].map(obj => obj.activityCode)
                    const industrialSitesFiltered = sites.filter(i => activityCodes.some(code => [i.activityCode, i.activityCode2, i.activityCode3, i.activityCode4].includes(code)))
                    return (
                        <Grid item xs={12}>
                            <RatePanelList
                                rate={rate}
                                industrialSites={industrialSitesFiltered}
                            />
                        </Grid>
                    )
                })
            }
        </Grid>
    )
}

PotentialSourcesPanel.propTypes = {
    sites: PropTypes.arrayOf(PropTypes.shape({})),
    filter: PropTypes.shape({
        activeIndustrialSites: PropTypes.bool,
        familyCode: PropTypes.number,
    }),
    setFilter: PropTypes.func,
}

const getColor = rate => (POLLUTION_LEVEL_COLOR.find(p => p.rate == rate)?.color ?? 'BLUE').toLowerCase()

const Cartographie = ({
    filter = {},
    setFilter = () => {},
}) => {
    const {
        qualitometer,
        pollutionSourceActivities,
        industrialSites,
        pollutedSoils,
        installationsIndex,
        citiesIndex,
    } = useSelector(store => ({
        qualitometer: store.QualityReducer.qualitometer,
        pollutionSourceActivities: store.AlertReducer.pollutionSourceActivities,
        industrialSites: store.InstallationReducer.industrialSites,
        pollutedSoils: store.InstallationReducer.pollutedSoils,
        installationsIndex: store.InstallationReducer.installationsIndex,
        citiesIndex: store.CityReducer.citiesIndex,
    }), shallowEqual)

    const qualitometerWithCoordinate = useMemo(() => filterStationCoordinates(qualitometer, citiesIndex), [citiesIndex, qualitometer])

    const allActivities = pollutionSourceActivities.filter(p => hasValue(p.rate) && hasValue(p.activityCode))

    const formatedIndustrialSites = useMemo(() => {
        if (!hasLocalisationStation(qualitometerWithCoordinate)) {
            return []
        }
        const activeFilter = filter.activeIndustrialSites ? industrialSites.filter(i => !i.activityStatus || i.activityStatus != 2) : industrialSites
        const familyFilter = filter.familyCode ? activeFilter.filter(i => i.familyCode === filter.familyCode) : activeFilter
        const fileteredIndustrialSites = familyFilter.filter(i => allActivities.some(a => [i.activityCode, i.activityCode2, i.activityCode3, i.activityCode4].includes(a.activityCode)))
        return fileteredIndustrialSites
            .map(industrialSite => {
                const siteActivies = allActivities.filter(a => [industrialSite.activityCode, industrialSite.activityCode2, industrialSite.activityCode3, industrialSite.activityCode4].includes(a.activityCode))
                const worstActivity = maxBy(siteActivies, 'rate')
                return {
                    ...industrialSite,
                    ...installationsIndex[industrialSite.id],
                    parameterCode: worstActivity.parameterCode,
                    markerIcon: `pictures/markers/map_installation_${getColor(worstActivity.rate)}.png`,
                }
            })
            .filter(s => hasLocalisationStation(s) && (getDistance(s, qualitometerWithCoordinate) < filter.radius * 1000))
    }, [filter.activeIndustrialSites, allActivities, filter.familyCode, industrialSites, installationsIndex, qualitometerWithCoordinate, filter.radius])

    const formatedPollutedSoils = useMemo(() => {
        if (!hasLocalisationStation(qualitometerWithCoordinate)) {
            return []
        }
        const filteredPollutedSoils = pollutedSoils.filter(pollutedSoil => allActivities.some(a => a.activityCode === pollutedSoil.activityCode))
        return filteredPollutedSoils
            .map(pollutedSoil => {
                const siteActivies = allActivities.filter(a => a.activityCode === pollutedSoil.activityCode)
                const worstActivity = maxBy(siteActivies, 'rate')
                return {
                    ...pollutedSoil,
                    ...installationsIndex[pollutedSoil.id],
                    parameterCode: worstActivity.parameterCode,
                    markerIcon: `pictures/markers/map_installation_${getColor(worstActivity.rate)}.png`,
                }
            })
            .filter(s => hasLocalisationStation(s) && (getDistance(s, qualitometerWithCoordinate) < filter.radius * 1000))
    }, [allActivities, installationsIndex, pollutedSoils, qualitometerWithCoordinate, filter.radius])

    const sites = [...formatedIndustrialSites, ...formatedPollutedSoils]
    return (
        <CartographyPanel
            layers={['STATIONS_POINTS', 'STATION']}
            componentType='quality'
            station={qualitometer}
            stationsPoints={sites}
            height={725}
            panels={[{
                icon: 'bubble_chart',
                title: i18n.potentialSources,
                content: (
                    <PotentialSourcesPanel
                        sites={sites}
                        filter={filter}
                        setFilter={setFilter}
                    />
                ),
            }]}
        />
    )
}

Cartographie.propTypes = {
    filter: PropTypes.shape({
        activeIndustrialSites: PropTypes.bool,
        familyCode: PropTypes.number,
        radius: PropTypes.number,
    }),
    setFilter: PropTypes.func,
}

const Filters = ({
    defaultFilter = {},
    setFilter = () => {},
    parameterList = [],
}) => {
    const {
        parameterGroups,
        parameterGroupLinks,
    } = useSelector(store => ({
        parameterGroups: store.ParameterReducer.parameterGroups,
        parameterGroupLinks: store.ParameterReducer.parameterGroupLinks,
    }), shallowEqual)

    const [radius, setRadius] = useState(defaultFilter.radius)
    const [selectedParameters, setSelectedParameters] = useState(defaultFilter.selectedParameters)
    const [searchValue, setSearchValue] = useState()
    const [groupParam, setGroupParam] = useState()
    const [selection, setSelection] = useState('-1')
    const [selectionResults, setSelectionResults] = useState([])

    useEffect(() => {
        if (!hasValue(radius)) {
            setRadius(30)
        }
    }, [radius])

    const formattedParameters = useMemo(() => {
        return parameterList.map(p => {
            const isSelected = selectedParameters.includes(p.code)
            return {
                code: p.code,
                parameter: p.name,
                searchValue: searchAllCharacters(p.name),
                isSelected,
                nullValue: (
                    <Icon
                        icon={isSelected ? 'check_box' : 'check_box_outline_blank'}
                        size='tiny'
                    />
                ),
            }
        })
    }, [parameterList, selectedParameters])

    const filteredParameters = useMemo(() => {
        const searchValueFormat = searchAllCharacters(searchValue)
        const parameterCodes = parameterGroupLinks.filter(({ classGroupCode }) => groupParam === classGroupCode).map(({ parameterCode }) => parameterCode)

        const filterSearchValue = searchValueFormat ? formattedParameters.filter(p => p.searchValue.includes(searchValueFormat)) : formattedParameters
        const filterGroupParam = hasValue(groupParam) ? filterSearchValue.filter(p => parameterCodes.includes(p.code)) : filterSearchValue
        return hasValue(selection) && selection !== '-1' ? filterGroupParam.filter(p => selectionResults.includes(p.code)) : filterGroupParam
    }, [formattedParameters, groupParam, parameterGroupLinks, searchValue, selection, selectionResults])

    const isAllSelected = filteredParameters.every(p => p.isSelected)

    const onSelectAll = () => {
        const selectListParam = filteredParameters.map(p => p.code)
        if (isAllSelected) {
            setSelectedParameters(prev => difference(prev, selectListParam))
        } else {
            setSelectedParameters(prev => uniq([...prev, ...selectListParam]))
        }
    }

    return (
        <Grid container rowSpacing={1} alignItems='flex-start' style={{ padding: '10' }}>
            <Grid item xs={5}>
                <NumberField
                    title={`${i18n.radius} (km)`}
                    value={radius}
                    onChange={setRadius}
                />
            </Grid>
            <Grid item xs={12}>
                <SuperMultiAutocomplete
                    label={i18n.parameterGroup}
                    options={parameterGroups}
                    onChange={setGroupParam}
                    values={groupParam}
                    keyValue='code'
                    keyLabel='name'
                />
            </Grid>
            <Grid item xs={12}>
                <SelectionSelect
                    onChange={(results, value) => {
                        setSelection(value)
                        setSelectionResults(results)
                    }}
                />
            </Grid>
            <Grid item xs={12}>
                <Input
                    title={i18n.search}
                    value={searchValue}
                    onChange={setSearchValue}
                />
            </Grid>
            <Grid item xs={12}>
                <Table
                    showTitle={false}
                    data={filteredParameters}
                    sortable
                    onClick={({ code, isSelected }) => setSelectedParameters(prev => {
                        if (isSelected) {
                            return prev.filter(c => c !== code)
                        }
                        return [...prev, code]
                    })}
                    maxHeight='44vh'
                    type={{ headers: ['nullValue', 'parameter'] }}
                    customHeaders={{
                        nullValue: (
                            <Icon
                                icon={isAllSelected ? 'check_box' : 'check_box_outline_blank'}
                                size='tiny'
                                onClick={onSelectAll}
                            />
                        ),
                        parameter: (
                            <>
                                {i18n.parameter}
                                <span style={{ fontWeight: 'normal', fontSize: 12, paddingLeft: 5 }}>
                                    {`(${selectedParameters.length} ${selectedParameters.length > 1 ? i18n.selectedParameters : i18n.selectedParameter})`}
                                </span>
                            </>
                        ),
                    }}
                    condensed
                    className='no-margin'
                    noHightlight
                />
            </Grid>
            <Grid item xs={9} />
            <Grid item xs={3}>
                <Button
                    fullWidth
                    disabled={!selectedParameters.length}
                    onClick={() => {
                        setFilter(prev => ({
                            ...prev,
                            radius,
                            selectedParameters,
                        }))
                    }}
                    variant='contained'
                >
                    {i18n.search}
                </Button>
            </Grid>
        </Grid>
    )
}

Filters.propTypes = {
    defaultFilter: PropTypes.shape({
        radius: PropTypes.number,
        selectedParameters: PropTypes.arrayOf(PropTypes.string),
    }),
    setFilter: PropTypes.func,
    parameterList: PropTypes.arrayOf(PropTypes.shape({})),
}

const SuiviPCSourcePanel = ({
    parameterList = [],
}) => {
    const dispatch = useDispatch()

    const {
        qualitometer,
        qualitometers,
        industrialSites,
        pollutedSoils,
        installationsIndex,
        parameterGroups,
        parameterGroupLinks,
        citiesIndex,
        pollutionSourceActivities,
        parameters,
    } = useSelector(store => ({
        qualitometer: store.QualityReducer.qualitometer,
        qualitometers: store.QualityReducer.qualitometersLight,
        industrialSites: store.InstallationReducer.industrialSites,
        pollutedSoils: store.InstallationReducer.pollutedSoils,
        installationsIndex: store.InstallationReducer.installationsIndex,
        parameterGroups: store.ParameterReducer.parameterGroups,
        parameterGroupLinks: store.ParameterReducer.parameterGroupLinks,
        citiesIndex: store.CityReducer.citiesIndex,
        pollutionSourceActivities: store.AlertReducer.pollutionSourceActivities,
        parameters: store.ParameterReducer.parameters,
    }), shallowEqual)

    const [filter, setFilter] = useState({ radius: 30, selectedParameters: [], activeIndustrialSites: true })

    useEffect(() => {
        if (!industrialSites.length) {
            dispatch(InstallationAction.fetchIndustrialSites())
        }
        if (!pollutedSoils.length) {
            dispatch(InstallationAction.fetchPollutedSoils())
        }
        if (!Object.keys(installationsIndex).length) {
            dispatch(InstallationAction.fetchInstallationsLight())
        }
        if (!parameterGroups.length) {
            dispatch(ParameterAction.fetchParameterGroups())
        }
        if (!parameterGroupLinks.length) {
            dispatch(ParameterAction.fetchParameterGroupLinks())
        }

        return () => {
            dispatch(AlertAction.resetPollutionSourceActivities())
        }
    }, [])

    useActions(() => {
        return {
            exportList: [{
                onClick: () => {
                    const qualitometerWithCoordinate = filterStationCoordinates(qualitometer, citiesIndex)

                    if (!hasLocalisationStation(qualitometerWithCoordinate)) {
                        return
                    }

                    const allActivities = pollutionSourceActivities.filter(p => hasValue(p.rate) && hasValue(p.activityCode))

                    const activeFilter = filter.activeIndustrialSites ? industrialSites.filter(i => !i.activityStatus || i.activityStatus != 2) : industrialSites
                    const familyFilter = filter.familyCode ? activeFilter.filter(i => i.familyCode === filter.familyCode) : activeFilter
                    const fileteredIndustrialSites = familyFilter.filter(i => allActivities.some(a => [i.activityCode, i.activityCode2, i.activityCode3, i.activityCode4].includes(a.activityCode)))
                    const formatedIndustrialSites = fileteredIndustrialSites
                        .map(industrialSite => {
                            const siteActivies = allActivities.filter(a => [industrialSite.activityCode, industrialSite.activityCode2, industrialSite.activityCode3, industrialSite.activityCode4].includes(a.activityCode))
                            const worstActivity = maxBy(siteActivies, 'rate')

                            const { x, y, projection, code, name, townCode } = installationsIndex[industrialSite.id] || {}
                            const city = citiesIndex[townCode]

                            return {
                                code,
                                name,
                                cityCode: townCode,
                                city: city?.name || '',
                                level: worstActivity.rate,
                                parameterCode: worstActivity.parameterCode,
                                parameter: getLabel(parameters, worstActivity.parameterCode, 'displayName'),

                                x,
                                y,
                                projection,
                            }
                        })
                        .filter(s => hasLocalisationStation(s) && (getDistance(s, qualitometerWithCoordinate) < filter.radius * 1000))

                    const filteredPollutedSoils = pollutedSoils.filter(pollutedSoil => allActivities.some(a => a.activityCode === pollutedSoil.activityCode))
                    const formatedPollutedSoils = filteredPollutedSoils
                        .map(pollutedSoil => {
                            const siteActivies = allActivities.filter(a => a.activityCode === pollutedSoil.activityCode)
                            const worstActivity = maxBy(siteActivies, 'rate')

                            const { x, y, projection, code, name, townCode } = installationsIndex[pollutedSoil.id] || {}
                            const city = citiesIndex[townCode]

                            return {
                                code,
                                name,
                                cityCode: townCode,
                                city: city?.name || '',
                                level: worstActivity.rate,
                                parameterCode: worstActivity.parameterCode,
                                parameter: getLabel(parameters, worstActivity.parameterCode, 'displayName'),

                                x,
                                y,
                                projection,
                            }
                        })
                        .filter(s => hasLocalisationStation(s) && (getDistance(s, qualitometerWithCoordinate) < filter.radius * 1000))

                    const sites = orderBy([...formatedIndustrialSites, ...formatedPollutedSoils], 'level', 'desc')
                    exportFile({
                        data: sites.length ? [
                            {
                                ...sites[0],
                                headers: ['code', 'name', 'cityCode', 'city', 'level', 'parameterCode', 'parameter']
                            },
                            ...sites.slice(1),
                        ] : [],
                        exportType: 'xlsx',
                        titleFile: `${i18n.industrialSites}`,
                    })
                },
                label: i18n.industrialSites,
            }],
            links: getLinks(qualitometer),
            arrowNav: getStationArrowNav('quality', qualitometers, qualitometer.id, s => dispatch(push(`/station/quality/${s.id}/suiviPC`))),
        }
    }, [citiesIndex, filter.activeIndustrialSites, filter.familyCode, filter.radius, industrialSites, installationsIndex, parameters, pollutedSoils, pollutionSourceActivities, qualitometer, qualitometers])

    const {
        value: isLoaded,
        setTrue: setLoadedToTrue,
        setFalse: setLoadedToFalse,
    } = useBoolean(true)
    const [progress, setProgress] = useState(100)

    useEffect(() => {
        if (filter.selectedParameters.length === 1) {
            dispatch(WaitAction.waitStart())
            dispatch(AlertAction.fetchPollutionActivities(filter.selectedParameters)).finally(() => dispatch(WaitAction.waitStop()))
        } else if (filter.selectedParameters.length) {
            setProgress(0)
            setLoadedToFalse()
            dispatch(AlertAction.fetchPollutionActivities(filter.selectedParameters)).finally(setLoadedToTrue)
        }
    }, [dispatch, filter.selectedParameters])

    return (
        <>
            {
                !isLoaded && (
                    <ProgressCard progress={progress} />
                )
            }
            {
                isLoaded && (
                    <Card>
                        <Grid container>
                            <Grid item xs={4}>
                                <Filters
                                    defaultFilter={filter}
                                    setFilter={setFilter}
                                    parameterList={parameterList}
                                />
                            </Grid>
                            <Grid item xs={8}>
                                <Cartographie
                                    filter={filter}
                                    setFilter={setFilter}
                                />
                            </Grid>
                        </Grid>
                    </Card>
                )
            }
        </>
    )
}

SuiviPCSourcePanel.propTypes = {
    parameterList: PropTypes.arrayOf(PropTypes.shape({
        code: PropTypes.string,
        name: PropTypes.string,
    })),
}

export default SuiviPCSourcePanel