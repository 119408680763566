import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import { getMapStateToProps, getPropTypes, getSandreList } from '../../../../utils/StoreUtils'
import { checkMandatoryFields } from '../../../../utils/FormUtils'
import { ARRANGEMENT_MANDATORY_FIELDS } from '../../../constants/InstallationConstants'
import { SANDRE } from '../../../../referencial/constants/ReferencialConstants'
import Card from '../../../../components/card/Card'
import Select from '../../../../components/forms/Select'
import Table from '../../../../components/datatable/Table'
import { getDate } from '../../../../utils/DateUtil'
import { getSandreLabel } from '../../../../utils/StringUtil'
import DatePicker from '../../../../components/forms/DatePicker'
import AppStore from '../../../../store/AppStore'
import InstallationAction from '../../../actions/InstallationAction'
import Textarea from '../../../../components/forms/Textarea'
import NumberField from '../../../../components/forms/NumberField'
import Button from '../../../../components/forms/Button'

const storeProps = {
    installation: false,
    sandreCodes: false,
}

class InstallationArrangementsModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            arrangements: [],
            arrangement: {},
        }
    }

    componentWillMount() {
        this.setState({
            arrangements: this.props.installation.arrangements,
            arrangement: {},
        })
    }

    componentWillReceiveProps(nextProps) {
        if (!this.props.popupIsOpen && nextProps.popupIsOpen) {
            $('#saveArrangements').on('click', () => {
                AppStore.dispatch(InstallationAction.updateArrangements(this.props.installation.id,
                    this.state.arrangements.map(c => Object.assign({}, c, { installationId: this.props.installation.id }))
                    , () => $('.modal').modal('close')))
            })
        }
    }

    onChangeArrangement = (key, value) => {
        const obj = Object.assign({}, this.state.arrangement)
        obj[key] = value
        this.setState({ arrangement: obj })
    }

    addArrangement = () => {
        checkMandatoryFields(ARRANGEMENT_MANDATORY_FIELDS, this.state.arrangement, () => {
            this.setState({
                arrangements: this.state.arrangements.concat([this.state.arrangement]),
                arrangement: {},
            })
        })
    }

    getTable = (arrangements) => {
        if (arrangements && arrangements.length) {
            const data = arrangements.map((a, i) => Object.assign({}, a, {
                index: i,
                startDate: getDate(a.startDate),
                endDate: getDate(a.endDate),
                type: getSandreLabel(this.props.sandreCodes, SANDRE.ARRANGEMENT_TYPES, a.arrangementType),
            }))
            return (
                <Table data={ data } sortable showTitle={ false } deletable condensed
                    onDelete={ v => this.setState({ arrangements: this.state.arrangements.filter((_, index) => index !== v.index) }) }
                    type={ this.props.tableHeaders }
                />
            )
        }
        return null
    }

    render() {
        if (this.props.popupIsOpen) {
            const arrangement = this.state.arrangement
            return (
                <div>
                    <div className='row no-margin'>
                        <Card title={ i18n.newArrangement } >
                            <div className='row no-margin padding-top-1'>
                                <DatePicker col={ 2 } value={ arrangement.startDate } id='arrangementStartDate' title={ i18n.startDate } endDate={ arrangement.endDate }
                                    onChange={ (_, v) => this.onChangeArrangement('startDate', v) }
                                />
                                <DatePicker col={ 2 } value={ arrangement.endDate } id='arrangementEndDate' title={ i18n.endDate } startDate={ arrangement.startDate }
                                    onChange={ (_, v) => this.onChangeArrangement('endDate', v) }
                                />
                                <Select col={ 4 } value={ arrangement.arrangementType } label={ i18n.type }
                                    options={ getSandreList(this.props.sandreCodes, SANDRE.ARRANGEMENT_TYPES) }
                                    onChange={ v => this.onChangeArrangement('arrangementType', v) } integerValue
                                />
                                <NumberField col={ 2 } title={ i18n.valueLabel } value={ arrangement.value } floatValue
                                    onChange={ v => this.onChangeArrangement('value', v) }
                                />
                            </div>
                            <div className='row no-margin'>
                                <Textarea col={ 4 } value={ arrangement.descriptive } title={ i18n.descriptive }
                                    onChange={ v => this.onChangeArrangement('descriptive', v) }
                                />
                            </div>
                            <div className='row no-margin padding-top-1 padding-bottom-1 padding-right-1'>
                                <div className='col s10' />
                                <Button col={ 2 } title={ i18n.add } onClick={ () => this.addArrangement() } icon='note_add'/>
                            </div>
                        </Card>
                    </div>
                    <div className='row no-margin'>
                        <Card title={ i18n.arrangements }>
                            { this.getTable(this.state.arrangements) }
                        </Card>
                    </div>
                </div>
            )
        }
        return null
    }
}

InstallationArrangementsModal.propTypes = getPropTypes(storeProps, {
    tableHeaders: PropTypes.arrayOf(PropTypes.string),
})

const mapStateToProps = store => getMapStateToProps(storeProps, {
    popupIsOpen: store.SieauReducer.popupIsOpen,
})

export default connect(mapStateToProps)(InstallationArrangementsModal)
