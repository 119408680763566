export default class DtoBoreholeAquifer {
    constructor(obj) {
        this.idStation = obj.idStation
        this.aquiferCode = obj.aquiferCode
        this.order = obj.order
        this.depth = obj.depth
        this.comment = obj.comment
        this.aquifer = obj.aquifer
        this.headers = ['aquifer', 'depth', 'comment']
    }
}