import loading from 'assets/pictures/loading.gif'
import { push } from '@lagunovsky/redux-react-router'
import React, { useEffect, useMemo, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import { sortBy } from 'lodash'
import DtoSimpleStation from 'home/dto/DtoSimpleStation'
import Card from '../../components/card/Card'
import Table from '../../components/datatable/Table'
import { getDate } from '../../utils/DateUtil'
import CampaignPortletPanel from '../../campaign/components/CampaignPortletPanel'
import MonitoredMaterielPortletPanel from '../../materiel/components/dashboard/MonitoredMaterielPortletPanel'
import ProgressCard from '../../components/card/ProgressCard'
import { getUserBookmarksByStationType } from '../../utils/UserUtil'
import { componentHasHabilitations } from '../../utils/HabilitationUtil'
import {
    H_MAT_MODULE,
    H_PLUVIO_MODULE,
} from '../../account/constants/AccessRulesConstants'
import HabilitationRequired from '../../utils/components/HabilitationRequired'
import { STATION_TYPE_NAME } from 'station/constants/StationConstants'
import UserThunk from 'administration/components/user/actions/UserThunk'
import useStateProgress from 'utils/customHook/useStateProgress'
import PluviometryThunk from 'pluviometry/actions/PluviometryThunk'
import useAccountSetting from '../../utils/customHook/useAccountSetting'
import StationAction from '../../station/actions/StationAction'
import MapSituationPie from '../../station/components/mapSituation/MapSituationPie'
import { Grid } from '@mui/material'

const panelTable = 'table'
const panelGraph = 'graph'
const pluvioStationType = 2

const PluviometryCard = () => {
    const {
        userBookmarks,
        pluviometers,
        monitoredMateriels,
        citiesIndex,
        userBookmarksStatus,
        pluviometersStatus,
    } = useSelector(store => ({
        userBookmarks: store.UserReducer.userBookmarks,
        pluviometers: store.PluviometryReducer.pluviometers,
        monitoredMateriels: store.MaterielReducer.monitoredMateriels,
        citiesIndex: store.CityReducer.citiesIndex,
        userBookmarksStatus: store.DataManagerReducer.user.userBookmarksStatus,
        pluviometersStatus: store.DataManagerReducer.pluviometry.pluviometersStatus,
    }), shallowEqual)

    const { progress: progressBar, isLoaded: dataLoaded } = useStateProgress([userBookmarksStatus, pluviometersStatus])

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(UserThunk.fetchBookmarks())
        dispatch(PluviometryThunk.fetchPluviometers())
    }, [])

    const [panelType, setPanelType] = useState(panelTable)
    const mapSituationWidgetObj = useAccountSetting('MAP_SITUATION_DASHBOARD_WIDGET', setting => JSON.parse(setting ?? '{}'))
    const [mapSituationStats, setMapSituationStats] = useState([])

    const bookmarks = useMemo(() => getUserBookmarksByStationType(userBookmarks, STATION_TYPE_NAME.pluviometry), [userBookmarks])

    useEffect(() => {
        const hydroMapIds = Object.keys(mapSituationWidgetObj).filter(key => key.startsWith('pluviometry') && !!mapSituationWidgetObj[key]).map(k => parseInt(k.replace('pluviometry-', '')))
        if (hydroMapIds.length) {
            Promise.all(hydroMapIds.map(id => StationAction.promiseMapSituationStats('pluviometry', id))).then(jsonTab => setMapSituationStats(jsonTab.filter(t => t.length)))
        }
    }, [mapSituationWidgetObj])

    const foundBookmarks = useMemo(() => bookmarks.reduce((acc, code) => {
        const station = pluviometers.find(s => s.code === code)
        if (station) {
            return [...acc, station]
        }
        return acc
    }, []), [pluviometers, bookmarks])

    const data = useMemo(() => ({
        stations: pluviometers,
        bookmarks,
        icon: 'insert_chart',
        campaignPanel: null,
        campaigns: [],
        stationType: pluvioStationType,
        habilitation: componentHasHabilitations(H_PLUVIO_MODULE),
        monitoredMateriels: monitoredMateriels.filter(m => m.typeSite === pluvioStationType),
    }), [bookmarks, monitoredMateriels, pluviometers])

    const bookmarksStationPanel = useMemo(() => {
        if (!foundBookmarks.length) {
            return null
        }
        const stationResult = sortBy(foundBookmarks.map(station => {
            const cityFound = citiesIndex[station.townCode]
            const result = new DtoSimpleStation(station)
            return {
                ...result,
                city: cityFound ? `${cityFound.name} [${cityFound.code}]` : station.townCode,
                historic: station.historyStart && station.historyEnd ? `${getDate(station.historyStart)} ${i18n.to} ${getDate(station.historyEnd)}` : '',
                operations: station.operationCount,
            }
        }), 'name')
        if (panelType === panelTable) {
            const mapSituationPies = mapSituationStats.map(tab => {
                return (
                    <MapSituationPie stats={tab} st='pluviometry' />
                )
            })
            const mapSituationCard = mapSituationPies.length ? (
                <Grid container justifyContent='center' alignItems='center' spacing={3}>
                    {mapSituationPies}
                </Grid>
            ) : undefined
            return (
                <>
                    {mapSituationCard}
                    <Table
                        showNbElements={false}
                        showTitle={false}
                        showIcon={true}
                        headerIcon={(
                            <a>
                                <i className='waves-effect material-icons'>grade</i>
                            </a>
                        )}
                        data={stationResult}
                        actions={[{
                            iconName: 'insert_chart',
                            onClick: () => setPanelType(panelGraph),
                        }]}
                        type={stationResult[0]}
                        link={`station/${STATION_TYPE_NAME.pluviometry}`}
                        condensed
                        sortable
                    />
                </>
            )
        }
        return (
            <Card
                title={(
                    <a>
                        <i className='waves-effect material-icons'>grade</i>
                        {i18n.myFavourites} ({foundBookmarks.length} {i18n.elements})
                    </a>
                )}
                actions={[{
                    iconName: 'list',
                    onClick: () => setPanelType(panelTable),
                }]}
            >
                {null}
            </Card>
        )
    }, [citiesIndex, foundBookmarks, panelType])

    const bookmarksPanel = useMemo(() => {
        if (data?.bookmarks?.length > 0 && data?.stations?.length > 0) {
            return (
                <div className='row no-margin'>
                    <div className='section no-padding'>
                        <div className='row no-margin'>
                            {bookmarksStationPanel}
                        </div>
                    </div>
                </div>
            )
        }
        return null
    }, [bookmarksStationPanel, data?.bookmarks?.length, data?.stations?.length])

    const campaignsPanel = useMemo(() => {
        if (data.campaigns && data.campaigns.length > 0 && data.campaignHeader) {
            return (
                <div className='row no-margin'>
                    <div className='section no-padding'>
                        <div className='row no-margin'>
                            <CampaignPortletPanel
                                noHeader={true}
                                campaigns={data.campaigns}
                                stationType={STATION_TYPE_NAME.pluviometry}
                                tableHeader={data.campaignHeader}
                            />
                        </div>
                    </div>
                </div>
            )
        }
        return null
    }, [data.campaignHeader, data.campaigns])

    if (dataLoaded) {
        if (!(data.habilitation && ((data.bookmarks.length > 0 && data.stations.length > 0) || data.campaigns.length > 0 || data.monitoredMateriels.length > 0 || data?.exploitations?.length > 0)) || !(bookmarksPanel || campaignsPanel)) {
            return null
        }
        return (
            <div className='row no-margin padding-top-1'>
                <Card
                    title={(
                        <a className='waves-effect' onClick={() => dispatch(push(`/${data.redirect || STATION_TYPE_NAME.pluviometry}`))}>
                            <i className='material-icons '>{data.icon}</i>
                            {i18n[STATION_TYPE_NAME.pluviometry]}
                        </a>
                    )}
                >
                    <div className='padding-1 padding-bottom-0'>
                        {bookmarksPanel}
                        {campaignsPanel}
                        <HabilitationRequired habilitation={H_MAT_MODULE}>
                            <MonitoredMaterielPortletPanel noHeader={true} stationType={data.stationType} />
                        </HabilitationRequired>
                    </div>
                </Card>
            </div>
        )
    }
    return (
        <div className='padding-top-1'>
            <Card>
                <div className='row no-margin center-align padding-top-5'>
                    <img src={loading} />
                    <ProgressCard withoutCard message={i18n.pluviometryCardInProgress} progress={progressBar} />
                </div>
            </Card>
        </div>
    )
}

export default PluviometryCard