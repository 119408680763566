/* eslint-disable camelcase */
import User from 'account/dto/User'
import SimpleDatePicker from 'components/forms/SimpleDatePicker'
import { orderBy } from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import MultiContributorsAutocomplete from 'referencial/components/contributor/components/MultiContributorsAutocomplete'
import ContributorItem from 'referencial/components/contributor/dto/ContributorItem'
import { nbPerPageLabelTiny } from 'referencial/constants/ReferencialConstants'
import { authorizeContributors } from 'utils/HabilitationUtil'
import i18n from 'simple-react-i18n'
import { getDate } from 'utils/DateUtil'
import { getSandreLabel } from 'utils/StringUtil'
import Checkbox from '../../../components/forms/Checkbox'
import Input from '../../../components/forms/Input'
import NumberField from '../../../components/forms/NumberField'
import Select from '../../../components/forms/Select'
import Textarea from '../../../components/forms/Textarea'
import DtoVariousMaterielType from '../../../materiel/components/variousMateriel/dto/DtoVariousMaterielType'
import VariousMaterielDto from '../../../materiel/components/variousMateriel/dto/VariousMaterielDto'
import DtoSandreCode from '../../../referencial/dto/DtoSandreCode'
import { hasValue } from '../../../utils/NumberUtil'
import { CardTable } from 'components/datatable/NewTable'
import { Grid2 } from '@mui/material'
import { darkBlue } from 'utils/constants/ColorTheme'

const styleLabelSelect = {
    fontSize: '13px',
}

const PUMPS_TYPES_CAT = 1

class PumpPanel extends Component {
    constructor(props) {
        super(props)
        this.state = {
            pump: props.pump,
            newCodificationId: -1,
        }
    }

    onChangeValue = (obj) => {
        const { pump } = this.props
        this.props.onChange({
            ...pump,
            ...obj,
        })
    }

    onChangeCodification = (obj, id) => {
        const { pump } = this.props
        const codificationUpdated = {
            ...(pump.link_codifications || []).find((c) => c.id === id),
            ...obj,
        }
        this.props.onChange({
            ...pump,
            link_codifications: [
                ...(pump.link_codifications || []).filter((c) => c.id !== id),
                codificationUpdated,
            ],
        })
    }

    addCodification = () => {
        const { pump } = this.props
        const { newCodificationId } = this.state
        this.props.onChange({
            ...pump,
            link_codifications: [
                ...(pump.link_codifications || []),
                {
                    id: newCodificationId,
                    materielType: 5,
                    materielId: pump.id || -1,
                    codificationType: null,
                    internalRef: null,
                    startDate: null,
                    endDate: null,
                },
            ],
        })
        this.setState({ newCodificationId: newCodificationId - 1 })
    }

    onDeleteCodification = (codification) => {
        const { pump } = this.props
        this.props.onChange({
            ...pump,
            link_codifications: [...(pump.link_codifications || []).filter((c) => c.id !== codification.id)],
        })
    }

    getFormattedCodifications = (codifications) => {
        const { disabled, sandreCodes } = this.props
        if (disabled) {
            return (codifications || []).map((c) => {
                return {
                    ...c,
                    codificationType: getSandreLabel(sandreCodes, 'MAT_DIVERS.CODIFICATIONS', c.codificationType),
                    startDate: getDate(c.startDate),
                    endDate: getDate(c.endDate),
                }
            })
        }
        return (codifications || []).map((c) => {
            return {
                ...c,
                codificationType: (
                    <Select
                        id={`${c.id}_codificationType_pump`}
                        value={c.codificationType}
                        options={sandreCodes.filter((s) => s.field === 'MAT_DIVERS.CODIFICATIONS')}
                        onChange={(v) => this.onChangeCodification({ codificationType: v }, c.id)}
                    />
                ),
                internalRef: (
                    <Input
                        id={`${c.id}_internalRef_pump`}
                        value={c.internalRef}
                        onChange={(v) => this.onChangeCodification({ internalRef: v }, c.id)}
                    />
                ),
                startDate: (
                    <SimpleDatePicker
                        id={`${c.id}_startDate_pump`}
                        value={c.startDate}
                        onChange={(v) => this.onChangeCodification({ startDate: v }, c.id)}
                        dateClassName='tableDatePicker'
                    />
                ),
                endDate: (
                    <SimpleDatePicker
                        id={`${c.id}_endDate_pump`}
                        value={c.endDate}
                        onChange={(v) => this.onChangeCodification({ endDate: v }, c.id)}
                        dateClassName='tableDatePicker'
                    />
                ),
            }
        })
    }

    render = () => {
        const { pump, disabled, variousMaterielTypes, sandreCodes, contributors, accountUser } = this.props
        const pumpInfos = pump.pump || {}
        const pumpCategories = sandreCodes.filter((c) => c.field === 'MAT.MOBILITE')
        const pumpTypes = sandreCodes.filter((c) => c.field === 'POMPES.TYPE')
        return (
            <div>
                <Grid2 container sx={{ minHeight: '25vh', padding: '10px' }} spacing={1}>
                    <Grid2 size={4}>
                        <Input
                            title={i18n.identifier}
                            value={pump.reference}
                            onChange={value => this.onChangeValue({ reference: value })}
                            disabled={hasValue(pump.id) || disabled}
                        />
                    </Grid2>
                    <Grid2 size={4}>
                        <Input
                            title={i18n.name}
                            value={pump.name}
                            onChange={value => this.onChangeValue({ name: value })}
                            disabled={disabled}
                        />
                    </Grid2>
                    <Grid2 size={4}>
                        <MultiContributorsAutocomplete
                            options={authorizeContributors(contributors, accountUser, pump.administrator)}
                            label={i18n.administrator}
                            onChange={value => this.onChangeValue({ administrator: value })}
                            values={pump.administrator}
                            disabled={disabled}
                            keyLabel='mnemonique'
                        />
                    </Grid2>
                    <Grid2 size={4}>
                        <Select
                            label={i18n.nature}
                            value={pump.materielType}
                            options={variousMaterielTypes.filter(({ category }) => category === PUMPS_TYPES_CAT)}
                            onChange={value => this.onChangeValue({ materielType: value })}
                            keyValue='id'
                            disabled={disabled}
                            styleLabel={styleLabelSelect}
                        />
                    </Grid2>
                    <Grid2 size={4}>
                        <Select
                            label={i18n.category}
                            value={pump.mobilityCode}
                            options={pumpCategories}
                            onChange={value => this.onChangeValue({ mobilityCode: value })}
                            keyValue='code'
                            disabled={disabled}
                            styleLabel={styleLabelSelect}
                        />
                    </Grid2>
                    <Grid2 size={4}>
                        <Select
                            label={i18n.pumpType}
                            value={pumpInfos.pumpType}
                            options={pumpTypes}
                            onChange={value => this.onChangeValue({ pump: { ...pumpInfos, pumpType: value } })}
                            keyValue='code'
                            disabled={disabled}
                            styleLabel={styleLabelSelect}
                        />
                    </Grid2>
                    <Grid2 size={4}>
                        <NumberField
                            title={i18n.maxFlow}
                            value={pumpInfos.maxFlow}
                            onChange={value => this.onChangeValue({ pump: { ...pumpInfos, maxFlow: value } })}
                            disabled={disabled}
                            floatValue
                        />
                    </Grid2>
                    <Grid2 size={4}>
                        <NumberField
                            title={i18n.inUseFlow}
                            value={pumpInfos.operatingFlow}
                            onChange={value => this.onChangeValue({ pump: { ...pumpInfos, operatingFlow: value } })}
                            disabled={disabled}
                            floatValue
                        />
                    </Grid2>
                    <Grid2 size={2} container alignItems='center'>
                        <Checkbox
                            checked={pump.isShared}
                            label={i18n.shared}
                            onChange={value => this.onChangeValue({ isShared: value })}
                            disabled={disabled}
                        />
                    </Grid2>
                    <Grid2 size={2} container alignItems='center'>
                        <Checkbox
                            checked={pump.rent}
                            label={i18n.forRent}
                            onChange={value => this.onChangeValue({ rent: value })}
                            disabled={disabled}
                        />
                    </Grid2>
                    <Grid2 size={12}>
                        <Textarea
                            title={i18n.comment}
                            noMargin={false}
                            value={pump.comment}
                            onChange={value => this.onChangeValue({ comment: value })}
                            disabled={disabled}
                        />
                    </Grid2>
                </Grid2>
                <CardTable
                    title={i18n.codifications}
                    actions={!disabled ? [{
                        tooltip: i18n.new,
                        icon: 'note_add',
                        onClick: this.addCodification,
                        color: 'white',
                    }] : []}
                    rows={orderBy(this.getFormattedCodifications((pump.link_codifications || [])), 'id', 'desc')}
                    lineActions={[{
                        icon: 'delete',
                        displayed: !disabled,
                        onClick: this.onDeleteCodification,
                    }]}
                    rowsPerPageOptions={ nbPerPageLabelTiny }
                    headers={[
                        { key: 'codificationType', value: i18n.type },
                        { key: 'internalRef', value: i18n.value },
                        { key: 'startDate', value: i18n.begin },
                        { key: 'endDate', value: i18n.end },
                    ]}
                    color={darkBlue}
                />
            </div>
        )
    }
}

PumpPanel.propTypes = {
    disabled: PropTypes.bool,
    title: PropTypes.string,
    pump: PropTypes.instanceOf(VariousMaterielDto),
    variousMaterielTypes: PropTypes.arrayOf(PropTypes.instanceOf(DtoVariousMaterielType)),
    sandreCodes: PropTypes.arrayOf(PropTypes.instanceOf(DtoSandreCode)),
    onChange: PropTypes.func,
    contributors: PropTypes.arrayOf(PropTypes.instanceOf(ContributorItem)),
    accountUser: PropTypes.instanceOf(User),
}

const mapStateToProps = (store) => {
    return {
        variousMaterielTypes: store.VariousMaterielReducer.variousMaterielTypes,
        sandreCodes: store.ReferencialReducer.sandreCodes,
        contributors: store.ContributorReducer.contributors,
        accountUser: store.AccountReducer.accountUser,
    }
}

export default connect(mapStateToProps)(PumpPanel)