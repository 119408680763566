export default class DtoParametrageDataType {
    constructor(obj) {
        this.id = obj.id
        this.label = obj.label
        this.displayData = obj.displayData || obj.showData
        this.showData = obj.showData || obj.displayData
        this.warningDelay = obj.warningDelay
        this.alertDelay = obj.alertDelay
        this.unit = obj.unit
        this.order = obj.order
        this.isPiezo = obj.isPiezo
        this.color = obj.color
        this.lineType = obj.lineType
        this.lineWidth = obj.lineWidth
        this.lineOpacity = obj.lineOpacity
        this.numberDecimal = obj.numberDecimal
    }
}