import { Grid2, Dialog, Button, DialogActions, DialogContent, Icon } from '@mui/material'
import AgriAction from 'agriAdministration/actions/AgriAction'
import HomeAction from 'home/actions/HomeAction'
import { isNil, lowerCase, orderBy, range, sortBy } from 'lodash'
import React, { useEffect, useMemo, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import useActions from 'utils/customHook/useActions'
import TerritoryWaterTurnsRestrictionsModal from './modal/TerritoryWaterTurnsRestrictionsModal'
import ToastrAction from 'toastr/actions/ToastrAction'
import Checkbox from 'components/forms/Checkbox'
import { DefaultDialogTitle } from 'components/styled/Dialog'
import Textarea from 'components/forms/Textarea'
import { getLogin } from 'utils/SettingUtils'
import { sieauTooltip } from 'utils/FormUtils'
import ManagementUnitAction from 'referencial/components/managementUnit/actions/ManagementUnitAction'
import { getDate, getDayList } from 'utils/DateUtil'
import RestrictionsFilterPanel from './panel/RestrictionsFilterPanel'
import { ENTRY_SETTINGS_FREQUENCY, RESTRICTIONS_TYPES, SAMPLE_TYPES } from 'agriAdministration/constants/AgriConstants'
import useApplicationSetting from 'utils/customHook/useApplicationSetting'
import notificationIcon from 'assets/pictures/logos/notification.png'
import RestrictionsNotifDialog from './modal/RestrictionsNotifDialog'
import { hasValue } from 'utils/NumberUtil'
import { CardTable } from 'components/datatable/NewTable'
import { darkBlue } from 'utils/constants/ColorTheme'
import { getEntryFrequencyList } from 'exploitations/utils/AgriUtils'
import Select from 'components/forms/Select'
import useProgressDispatch from 'utils/customHook/useProgressDispatch'
import ProgressCard from 'components/card/ProgressCard'

const headers = ['nullValue', 'level', 'label', 'description', 'comment', 'entry']
const exportHeaders = ['level', 'label', 'description', 'comment', 'entry', 'color']

const emptyFilter = {
    searchValue: '',
    resourceType: undefined,
}

const TerritoryRestrictionsApp = ({}) => {
    const dispatch = useDispatch()
    const [openModal, setOpenModal] = useState(false)
    const [openModalNotifs, setOpenModalNotifs] = useState(false)
    const [modalExemptionData, setModalExemptionData] = useState({ open: false })

    const [selectedRestriction, setSelectedRestriction] = useState()
    const [isNew, setIsNew] = useState(null)
    const [readMode, setReadMode] = useState(true)
    const [filters, setFilters] = useState(emptyFilter)
    const [filterNotifs, setFilterNotifs] = useState()

    const { isLoaded } = useProgressDispatch(() => [dispatch(AgriAction.fetchRestrictions())], [])

    useEffect(() => {
        dispatch(HomeAction.setTitle([{
            title: i18n.steering,
            href: 'territory',
        }, {
            title: i18n.restrictionsManagement,
            href: 'territory/restrictions',
        }]))
    }, [])

    const {
        restrictions,
        droughtSectorsRestrictions,
        managementUnits,
        managementUnitsRestrictions,
        sandreCodes,
    } = useSelector(store => ({
        restrictions: store.AgriReducer.restrictions,
        droughtSectorsRestrictions: store.AgriReducer.droughtSectorsRestrictions,
        managementUnits: store.ManagementUnitReducer.managementUnits,
        managementUnitsRestrictions: store.ManagementUnitReducer.managementUnitsRestrictions,
        sandreCodes: store.ReferencialReducer.sandreCodes,
    }), shallowEqual)

    const [localManagementUnitsRestrictions, setManagementUnitsRestrictions] = useState(managementUnitsRestrictions)
    const [localDroughtSectorsRestrictions, setDroughtSectorsRestrictions] = useState(droughtSectorsRestrictions)

    const typeRestriction = useApplicationSetting('agriTypeRestriction', setting => setting ? parseInt(setting) : RESTRICTIONS_TYPES.MANAGEMENT_UNITS)

    const entryFrequency = getEntryFrequencyList()
    const daysList = getDayList()

    const getLabelEntry = (frequency, day) => {
        if (!isNil(frequency) && !isNil(day)) {
            const frequencyLabel = entryFrequency.find(f => f.value === frequency)?.label
            const dayLabel = frequency === ENTRY_SETTINGS_FREQUENCY.WEEKLY ? daysList.find(l => l.value === day)?.label : day
            return `${frequencyLabel} (${i18n.onDate} ${dayLabel})`
        }
        return ''
    }

    const getEntryDays = (frequency) => {
        if (frequency === ENTRY_SETTINGS_FREQUENCY.WEEKLY) {
            return getDayList()
        }
        return range(1, 32).map((d) => ({ value: d, label: d }))
    }

    const getStyleLevel = (color) => ({
        backgroundColor: color || '#52D100',
        width: '20px',
        border: 'solid black 1px',
    })

    const sortedRestrictions = useMemo(() => {
        return sortBy(restrictions, 'level').map(d => ({
            ...d,
            nullValue: (
                <div style={{ backgroundColor: d.color, width: '20px', border: 'solid black 1px' }}>&nbsp;</div>
            ),
            entry: getLabelEntry(d.entryFrequency, d.entryFrequencyDay),
        }))
    }, [restrictions])

    const onExport = () => {
        const data = sortedRestrictions.map(r => ({
            level: { value: r.level },
            label: { value: r.label },
            description: { value: r.description },
            comment: { value: r.comment },
            color: { value: r.color, color: r.color },
            entry: { value: getLabelEntry(r.entryFrequency, r.entryFrequencyDay) },
        }))
        return {
            data: data.length ? [
                { ...data[0], headers: exportHeaders },
                ...data.slice(1),
            ] : [],
            titleFile: i18n.restrictions,
        }
    }

    useEffect(() => {
        setManagementUnitsRestrictions(managementUnitsRestrictions)
    }, [managementUnitsRestrictions])

    useEffect(() => {
        setDroughtSectorsRestrictions(droughtSectorsRestrictions)
    }, [droughtSectorsRestrictions])

    const onSave = (dataUGE, dataDroughtSectors) => {
        setReadMode(true)
        dispatch(ManagementUnitAction.updateManagementUnitsRestrictions(dataUGE)).then(() => {
            dispatch(ManagementUnitAction.fetchManagementUnitsRestrictions())
        })
        dispatch(AgriAction.updateDroughtSectorsRestrictions(dataDroughtSectors)).then(() => {
            dispatch(AgriAction.fetchDroughtSectorsRestrictions())
        })
    }

    useActions(() => {
        return readMode ? {
            new: () => {
                setIsNew(true)
                setSelectedRestriction({})
                setOpenModal(true)
            },
            export: () => onExport(sortedRestrictions),
            edit: () => setReadMode(false),
            share: [{
                id: 'notif',
                onClick: () => setOpenModalNotifs(true),
                img: notificationIcon,
                label: i18n.notification,
            }],
        } : {
            save: () => onSave(localManagementUnitsRestrictions, localDroughtSectorsRestrictions),
            cancel: () => {
                dispatch(ManagementUnitAction.fetchManagementUnitsRestrictions())
                dispatch(AgriAction.fetchDroughtSectorsRestrictions())
                setReadMode(true)
            },
        }
    }, [readMode, sortedRestrictions, localManagementUnitsRestrictions, localDroughtSectorsRestrictions])

    const onUpdate = (data) => {
        const otherRestrictions = restrictions.filter(d => d.id !== data.id)
        if (otherRestrictions.some((r) => r.level === data.level)) {
            dispatch(ToastrAction.warning(i18n.levelAlreadyExist))
        } else {
            const newData = [
                ...otherRestrictions,
                data,
            ]
            dispatch(AgriAction.updateRestrictions(newData)).then(() => {
                setOpenModal(false)
                dispatch(AgriAction.fetchRestrictions())
            })
        }
    }

    const onDelete = (deletedRes) => {
        const filteredData = restrictions.filter(sr => sr.id !== deletedRes.id)
        dispatch(AgriAction.updateRestrictions(filteredData)).then(() => {
            dispatch(AgriAction.fetchRestrictions())
        })
    }

    const headersRestrictions = useMemo(() => {
        if (typeRestriction === RESTRICTIONS_TYPES.DROUGHT_SECTORS) {
            return ['nullValue', 'droughtSector', 'type', 'normal', ...restrictions.map((r) => String(r.id)), 'exemption', 'entry', 'updateDate', 'nullValue2']
        }
        return ['nullValue', 'managementUnit', 'type', 'normal', ...restrictions.map((r) => String(r.id)), 'exemption', 'entry', 'updateDate', 'nullValue2']
    }, [restrictions, typeRestriction])

    const customHeadersRestrictions = useMemo(() => {
        let obj = {}
        restrictions.forEach((r) => {
            obj[r.id] = r.label
        })
        return { updateDate: i18n.update, ...obj }
    }, [restrictions])

    const getFieldsEntry = (frequency, day, onChange = () => {}) => ({
        value: (
            <Grid2 container sx={{ paddingTop: '10px' }}>
                <Grid2 size={6}>
                    <Select
                        col={12}
                        label={i18n.frequency}
                        value={frequency}
                        onChange={v => onChange({ entryFrequency: v, entryFrequencyDay: undefined })}
                        clearFunction
                        integerValue
                        options={entryFrequency}
                    />
                </Grid2>
                <Grid2 size={6}>
                    <Select
                        col={12}
                        label={i18n.day}
                        value={day}
                        onChange={v => onChange({ entryFrequencyDay: v })}
                        clearFunction
                        integerValue
                        options={getEntryDays(frequency)}
                        noSort
                    />
                </Grid2>
            </Grid2>
        ),
    })

    const dashboardData = useMemo(() => {
        if (typeRestriction === RESTRICTIONS_TYPES.DROUGHT_SECTORS) {
            const onChangeValue = (ds, obj = {}) => {
                const newRestrictions = [
                    ...localDroughtSectorsRestrictions.filter((dsR) => !(dsR.idSector === ds.idSector && dsR.resourceType === ds.resourceType)),
                    {
                        ...ds,
                        ...obj,
                        updateLogin: getLogin(),
                        updateDate: null,
                    },
                ]
                setDroughtSectorsRestrictions(newRestrictions)
            }
            const getCheckboxs = (ds) => {
                const normalChecked = !localDroughtSectorsRestrictions.some((ugeR) => ugeR.idSector === ds.idSector && ugeR.resourceType === ds.resourceType) ||
                    localDroughtSectorsRestrictions.some((ugeR) => ugeR.idSector === ds.idSector && ugeR.resourceType === ds.resourceType && ugeR.idRestriction === -1)
                let obj = {
                    normal: {
                        value: (
                            <Checkbox
                                col
                                checked={normalChecked}
                                onChange={() => onChangeValue(ds, { idRestriction: -1 })}
                                disabled={readMode}
                            />
                        ),
                        sortValue: normalChecked,
                    },
                }
                restrictions.forEach((r) => {
                    const isChecked = localDroughtSectorsRestrictions.some((ugeR) => ugeR.idSector === ds.idSector && ugeR.resourceType === ds.resourceType && ugeR.idRestriction === r.id)
                    obj[r.id] = {
                        value: (
                            <Checkbox
                                col
                                checked={isChecked}
                                onChange={() => onChangeValue(ds, { idRestriction: r.id, entryFrequency: r.entryFrequency, entryFrequencyDay: r.entryFrequencyDay })}
                                disabled={readMode}
                            />
                        ),
                        sortValue: isChecked,
                    }
                })
                return obj
            }
            const droughtSectors = sandreCodes.filter((s) => s.field === 'PREL_AGRI.SECTEUR_SECHERESSE')
            const valuesFormatted = orderBy([
                ...localDroughtSectorsRestrictions.map((dsR) => {
                    const ds = droughtSectors.find((u) => u.code === dsR.idSector) || {}
                    const restr = restrictions.find((r) => r.id === dsR.idRestriction) || {}
                    return {
                        idSector: { value: dsR.idSector },
                        nullValue: { value: <div style={getStyleLevel(restr?.color)}>&nbsp;</div>, sortValue: restr.level },
                        droughtSector: { value: ds.name },
                        ...getCheckboxs(dsR),
                        exemption: { value: readMode ?
                            (!dsR.exemption ? '' : <Icon {...sieauTooltip(dsR.exemption, null, 'left')}>info</Icon>) :
                            <Icon className='clickable' onClick={() => setModalExemptionData({ open: true, restriction: dsR, title: `${`${ds.name} - `}${restr.label}` })}>edit</Icon>,
                        },
                        updateDate: { value: getDate(dsR.updateDate) },
                        exemptionValue: { value: dsR.exemption },
                        resourceType: { value: dsR.resourceType },
                        type: { value: dsR.resourceType === SAMPLE_TYPES.UNDERGROUND ? i18n.underground : i18n.superficial },
                        name: ds.name,
                        resource: dsR.resourceType,
                        entry: readMode ?
                            getLabelEntry(dsR.entryFrequency, dsR.entryFrequencyDay) :
                            getFieldsEntry(dsR.entryFrequency, dsR.entryFrequencyDay, (v) => onChangeValue(dsR, v)),
                        nullValue2: {
                            value: readMode && (
                                <Icon
                                    className='clickable'
                                    onClick={() => {
                                        setFilterNotifs({ sampleType: dsR.resourceType, droughtSector: dsR.idSector })
                                        setOpenModalNotifs(true)
                                    }}
                                >
                                    share
                                </Icon>
                            ),
                        },
                    }
                }),
                ...[...droughtSectors.map((ds) => ({ ...ds, resourceType: 1 })), ...droughtSectors.map((ds) => ({ ...ds, resourceType: 2 }))].filter((ds) => !localDroughtSectorsRestrictions.some((ugeR) => ugeR.idSector === ds.code && ugeR.resourceType === ds.resourceType)).flatMap((ds) => {
                    return [{
                        idSector: { value: ds.idSector },
                        nullValue: { value: <div style={getStyleLevel()}>&nbsp;</div>, sortValue: -1 },
                        droughtSector: { value: ds.name },
                        ...getCheckboxs(ds),
                        type: { value: ds.resourceType === SAMPLE_TYPES.UNDERGROUND ? i18n.underground : i18n.superficial },
                        resourceType: { value: ds.resourceType },
                        exemption: { value: readMode ? undefined : <Icon className='clickable' onClick={() => setModalExemptionData({ open: true, restriction: { idSector: ds.code, idRestriction: -1, resourceType: ds.resourceType }, title: `${`${ds.name} - `}${i18n.normal}` })}>edit</Icon> },
                        name: ds.name,
                        resource: ds.resourceType,
                        entry: readMode ? '' : getFieldsEntry(ds.entryFrequency, ds.entryFrequencyDay, (v) => onChangeValue(ds, v)),
                        nullValue2: {
                            value: readMode && (
                                <Icon
                                    className='clickable'
                                    onClick={() => {
                                        setFilterNotifs({ sampleType: ds.resourceType, droughtSector: ds.idSector })
                                        setOpenModalNotifs(true)
                                    }}
                                >
                                    share
                                </Icon>
                            ),
                        },
                    }]
                }),
            ], ['name', 'resource'])
            const valuesFiltered = valuesFormatted.filter((v) => lowerCase(`${v.idSector?.value} ${v.droughtSector?.value} ${v.exemptionValue?.value} ${v.updateDate?.value}`).includes(lowerCase(filters.searchValue)))
            const typeFiltered = filters.resourceType ? valuesFiltered.filter((v) => v.resourceType?.value === filters.resourceType) : valuesFiltered
            return typeFiltered
        }
        const onChangeValue = (ugR, obj = {}) => {
            const newRestrictions = [
                ...localManagementUnitsRestrictions.filter((ugeR) => !(ugeR.managementCode === ugR.managementCode && ugeR.resourceType === ugR.resourceType)),
                {
                    ...ugR,
                    ...obj,
                    updateLogin: getLogin(),
                    updateDate: null,
                },
            ]
            setManagementUnitsRestrictions(newRestrictions)
        }
        const getCheckboxs = (ugR) => {
            const normalChecked = !localManagementUnitsRestrictions.some((ugeR) => ugeR.managementCode === ugR.managementCode && ugeR.resourceType === ugR.resourceType) ||
                localManagementUnitsRestrictions.some((ugeR) => ugeR.managementCode === ugR.managementCode && ugeR.resourceType === ugR.resourceType && ugeR.idRestriction === -1)
            let obj = {
                normal: {
                    value: (
                        <Checkbox
                            col
                            checked={normalChecked}
                            onChange={() => onChangeValue(ugR, { idRestriction: -1 })}
                            disabled={readMode}
                        />
                    ),
                    sortValue: normalChecked,
                },
            }
            restrictions.forEach((r) => {
                const isChecked = localManagementUnitsRestrictions.some((ugeR) => ugeR.managementCode === ugR.managementCode && ugeR.resourceType === ugR.resourceType && ugeR.idRestriction === r.id)
                obj[r.id] = {
                    value: (
                        <Checkbox
                            col
                            checked={isChecked}
                            onChange={() => onChangeValue(ugR, { idRestriction: r.id, entryFrequency: r.entryFrequency, entryFrequencyDay: r.entryFrequencyDay })}
                            disabled={readMode}
                        />
                    ),
                    sortValue: isChecked,
                }
            })
            return obj
        }
        const valuesFormatted = orderBy([
            ...localManagementUnitsRestrictions.map((ugeR) => {
                const uge = managementUnits.find((u) => u.managementCode === ugeR.managementCode) || {}
                const restr = restrictions.find((r) => r.id === ugeR.idRestriction) || {}
                return {
                    managementCode: { value: ugeR.managementCode },
                    nullValue: { value: <div style={getStyleLevel(restr?.color)}>&nbsp;</div>, sortValue: restr.level },
                    managementUnit: { value: `[${uge.id}] ${uge.name}` },
                    ...getCheckboxs(ugeR),
                    exemption: {
                        value: readMode ?
                            (!ugeR.exemption ? '' : <Icon {...sieauTooltip(ugeR.exemption, null, 'left')}>info</Icon>) :
                            <Icon className='clickable' onClick={() => setModalExemptionData({ open: true, restriction: ugeR, title: `${`[${uge.id}] ${uge.name}` + ' - '}${restr.label}` })}>edit</Icon>,
                    },
                    updateDate: { value: getDate(ugeR.updateDate) },
                    exemptionValue: { value: ugeR.exemption },
                    resourceType: { value: ugeR.resourceType },
                    type: { value: ugeR.resourceType === SAMPLE_TYPES.UNDERGROUND ? i18n.underground : i18n.superficial },
                    name: `[${uge.id}] ${uge.name}`,
                    resource: ugeR.resourceType,
                    entry: readMode ?
                        getLabelEntry(ugeR.entryFrequency, ugeR.entryFrequencyDay) :
                        getFieldsEntry(ugeR.entryFrequency, ugeR.entryFrequencyDay, (v) => onChangeValue(ugeR, v)),
                    nullValue2: {
                        value: readMode && (
                            <Icon
                                className='clickable'
                                onClick={() => {
                                    setFilterNotifs({ sampleType: ugeR.resourceType, managementUnit: uge.parent || uge.managementCode, subManagementUnit: uge.parent ? uge.managementCode : undefined })
                                    setOpenModalNotifs(true)
                                }}
                            >
                                share
                            </Icon>
                        ),
                    },
                    id: uge.id,
                    parent: hasValue(uge.parent) ? (managementUnits.find((m) => m.managementCode === uge.parent) || {}).id : '',
                    idForOrder: uge.parent || uge.managementCode,
                }
            }),
            ...[...managementUnits.map((m) => ({ ...m, resourceType: 1 })), ...managementUnits.map((m) => ({ ...m, resourceType: 2 }))].filter((m) => !localManagementUnitsRestrictions.some((ugeR) => ugeR.managementCode === m.managementCode && ugeR.resourceType === m.resourceType)).flatMap((u) => {
                return [{
                    managementCode: { value: u.managementCode },
                    nullValue: { value: <div style={getStyleLevel()}>&nbsp;</div>, sortValue: -1 },
                    managementUnit: { value: `[${u.id}] ${u.name}` },
                    ...getCheckboxs(u),
                    type: { value: u.resourceType === SAMPLE_TYPES.UNDERGROUND ? i18n.underground : i18n.superficial },
                    resourceType: { value: u.resourceType },
                    exemption: { value: readMode ? undefined : <Icon onClick={() => setModalExemptionData({ open: true, restriction: { managementCode: u.managementCode, idRestriction: -1, resourceType: u.resourceType }, title: `${`[${u.id}] ${u.name}` + ' - '}${i18n.normal}` })}>edit</Icon> },
                    name: `[${u.id}] ${u.name}`,
                    resource: u.resourceType,
                    entry: readMode ? '' : getFieldsEntry(u.entryFrequency, u.entryFrequencyDay, (v) => onChangeValue(u, v)),
                    nullValue2: {
                        value: readMode && (
                            <Icon
                                className='clickable'
                                onClick={() => {
                                    setFilterNotifs({ sampleType: u.resourceType, managementUnit: u.parent || u.managementCode, subManagementUnit: u.parent ? u.managementCode : undefined })
                                    setOpenModalNotifs(true)
                                }}
                            >
                                share
                            </Icon>
                        ),
                    },
                    id: u.id,
                    parent: hasValue(u.parent) ? (managementUnits.find((m) => m.managementCode === u.parent) || {}).id : '',
                    idForOrder: u.parent || u.managementCode,
                }]
            }),
        ], ['idForOrder', 'parent', 'id', 'resource'])
        const valuesFiltered = valuesFormatted.filter((v) => lowerCase(`${v.managementCode.value} ${v.managementUnit.value} ${v.exemptionValue?.value} ${v.updateDate?.value}`).includes(lowerCase(filters.searchValue)))
        const typeFiltered = filters.resourceType ? valuesFiltered.filter((v) => v.resourceType.value === filters.resourceType) : valuesFiltered
        return typeFiltered
    }, [localManagementUnitsRestrictions, managementUnits, restrictions, readMode, filters, typeRestriction, localDroughtSectorsRestrictions, sandreCodes])

    if (!isLoaded) {
        <Grid2 className='padding-1'>
            <ProgressCard indeterminate withMessage round />
        </Grid2>
    }

    return (
        <Grid2 className='padding-1' sx={{ marginBottom: '60px' }}>
            <CardTable
                title={i18n.restrictions}
                rows={sortedRestrictions}
                headers={headers}
                headersLabel={{ displayedColor: i18n.color }}
                lineActions={[
                    { icon: 'edit', onClick: (restriction) => {
                        setSelectedRestriction(sortedRestrictions.find(s => s.id === restriction.id))
                        setOpenModal(true)
                    }, displayed: !readMode },
                    { icon: 'delete', onClick: onDelete, displayed: !readMode },
                ]}
                color={darkBlue}
            />
            <Grid2 className='padding-top-1'>
                <RestrictionsFilterPanel onValidate={setFilters} />
            </Grid2>
            <Grid2 className='padding-top-1'>
                <CardTable
                    title={i18n.dashboard}
                    rows={dashboardData}
                    headers={headersRestrictions}
                    headersLabel={customHeadersRestrictions}
                    color={darkBlue}
                />
            </Grid2>
            <TerritoryWaterTurnsRestrictionsModal
                openModal={openModal}
                setOpenModal={setOpenModal}
                restriction={selectedRestriction}
                onUpdate={onUpdate}
                isNew={isNew}
            />
            {openModalNotifs && (
                <RestrictionsNotifDialog
                    filter={filterNotifs}
                    open={openModalNotifs}
                    onClose={() => {
                        setFilterNotifs({})
                        setOpenModalNotifs(false)
                    }}
                />
            )}
            {modalExemptionData.open && (
                <Dialog open={modalExemptionData.open} maxWidth='lg'>
                    <DefaultDialogTitle
                        title={modalExemptionData.title}
                        onClose={() => setModalExemptionData({ open: false })}
                    />
                    <DialogContent>
                        <Textarea
                            title={ i18n.exemption }
                            value={ modalExemptionData.restriction.exemption }
                            onChange={(v) => setModalExemptionData({ ...modalExemptionData, restriction: { ...modalExemptionData.restriction, exemption: v } })}
                            rows={10}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant='contained'
                            color='primary'
                            onClick={() => {
                                if (typeRestriction === RESTRICTIONS_TYPES.DROUGHT_SECTORS) {
                                    const newRestrictions = [
                                        ...localDroughtSectorsRestrictions.filter((dsR) => !(dsR.idSector === modalExemptionData.restriction.idSector && dsR.resourceType === modalExemptionData.restriction.resourceType)),
                                        {
                                            ...modalExemptionData.restriction,
                                            updateLogin: getLogin(),
                                            updateDate: null,
                                        },
                                    ]
                                    setDroughtSectorsRestrictions(newRestrictions)
                                } else {
                                    const newRestrictions = [
                                        ...localManagementUnitsRestrictions.filter((ugeR) => !(ugeR.managementCode === modalExemptionData.restriction.managementCode && ugeR.resourceType === modalExemptionData.restriction.resourceType)),
                                        {
                                            ...modalExemptionData.restriction,
                                            updateLogin: getLogin(),
                                            updateDate: null,
                                        },
                                    ]
                                    setManagementUnitsRestrictions(newRestrictions)
                                }
                                setModalExemptionData({ open: false })
                            }}
                        >
                            {i18n.validate}
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </Grid2>
    )
}

export default TerritoryRestrictionsApp
