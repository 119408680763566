module.exports = {
    RECEIVE_ALL_LOCATION: 'RECEIVE_ALL_LOCATION',
    lane_type: [
        'AIRE', 'Aire',
        'ALL', 'Allée',
        'AV', 'Avenue',
        'BASE', 'Base',
        'BD', 'Boulevard',
        'CAMI', 'Cami',
        'CAR', 'Carrefour',
        'CHE', 'Chemin',
        'CHEM', 'Cheminement',
        'CHS', 'Chaussée',
        'CITE', 'Cité',
        'CLOS', 'Clos',
        'COIN', 'Coin',
        'COR', 'Corniche',
        'COTE', 'Cote',
        'COUR', 'Cour',
        'CRS', 'Cours',
        'DOM', 'Domaine',
        'DSC', 'Descente',
        'ECA', 'Ecart',
        'ESP', 'Esplanade',
        'FG', 'Faubourg',
        'GARE', 'Gare',
        'GR', 'Grande Rue',
        'HAM', 'Hameau',
        'HLE', 'Halle',
        'ILOT', 'Ilôt',
        'IMP', 'Impasse',
        'LD', 'Lieu dit',
        'LOT', 'Lotissement',
        'MAR', 'Marché',
        'MTE', 'Montée',
        'PARC', 'Parc',
        'PAS', 'Passage',
        'PL', 'Place',
        'PLAN', 'Plan',
        'PLN', 'Plaine',
        'PLT', 'Plateau',
        'PONT', 'Pont',
        'PORT', 'Port',
        'PRO', 'Promenade',
        'PRV', 'Parvis',
        'QUA', 'Quartier',
        'QUAI', 'Quai',
        'RES', 'Résidence',
        'RLE', 'Ruelle',
        'ROC', 'Rocade',
        'RPT', 'Rond Point',
        'RTE', 'Route',
        'RUE', 'Rue',
        'SEN', 'Sentier',
        'SQ', 'Square',
        'TOUR', 'Tour',
        'TPL', 'Terre-plein',
        'TRA', 'Traverse',
        'VLA', 'Villa',
        'VLGE', 'Village',
        'VOIE', 'Voie',
        'ZA', 'Zone artisanale',
        'ZAC', 'Zone d\'aménagement concerté',
        'ZAD', 'Zone d\'aménagement différé',
        'ZI', 'Zone industrielle',
        'ZONE', 'Zone',
    ],
}
