export default class DtoOperation {
    constructor(obj) {
        this.id = obj.id
        this.date = obj.dateStart
        this.qualification = obj.qualification
        this.status = obj.status
        this.support = obj.support
        this.laboratory = obj.laboratoryId
        this.majDate = obj.majDate
        this.majLogin = obj.majLogin
        this.producer = obj.producer
        this.qualitometer = obj.qualitometer
        this.producerRef = obj.producerRef
        this.sampler = obj.sampler
        this.fraction = obj.fraction
        this.point = obj.point
        this.campaignCode = obj.campaignCode
        this.countAnaNotQualified = obj.countAnaNotQualified
        this.countCorrectAnalysis = obj.countCorrectAnalysis
        this.countIncorrectAnalysis = obj.countIncorrectAnalysis
        this.countUncertainAnalysis = obj.countUncertainAnalysis
    }
}