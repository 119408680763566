import ApplicationConf from 'conf/ApplicationConf'
import { getLogin } from '../../utils/SettingUtils'
import {
    CLOSE_EVENT_SOURCE_CONNECTION, START_EVENTS_SUBSCRIPTION, START_EVENT_SOURCE_CONNECTION, SUBSCRIBE_SERVER_EVENT,
    SUBSCRIBE_SERVER_EVENTS,
    UNSUBSCRIBE_SERVER_EVENT,
    UNSUBSCRIBE_SERVER_EVENTS,
} from '../constants/SSEConstants'

const SSEActions = {
    connect: () => dispatch => {
        const login = getLogin()
        // TODO implement Auth connection
        return dispatch(SSEActions.start(ApplicationConf.sse.get(login), () => {
            dispatch(SSEActions.startSubscriptions())
        }))
    },

    subscribe: (type, action) => ({
        type: SUBSCRIBE_SERVER_EVENT,
        event: { type, action },
    }),

    subscribeEvents: (events = []) => ({
        type: SUBSCRIBE_SERVER_EVENTS,
        events,
    }),

    unsubscribe: (type, action) => ({
        type: UNSUBSCRIBE_SERVER_EVENT,
        event: { type, action },
    }),

    unsubscribeEvents: (events = []) => ({
        type: UNSUBSCRIBE_SERVER_EVENTS,
        events,
    }),

    start: (url, onConnect = () => {}) => ({
        type: START_EVENT_SOURCE_CONNECTION,
        onConnect,
        url,
    }),

    startSubscriptions: () => ({
        type: START_EVENTS_SUBSCRIPTION,
    }),

    stop: () => ({
        type: CLOSE_EVENT_SOURCE_CONNECTION,
    }),
}

export default SSEActions
