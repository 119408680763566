import React from 'react'
import i18n from 'simple-react-i18n'
import Checkbox from '../forms/Checkbox'
import createClass from 'create-react-class'
import PropTypes from 'prop-types'
import { setSieauTooltip } from '../../utils/FormUtils'
import Icon from '../icon/Icon'
import { v4 as uuidv4 } from 'uuid'

export default createClass({
    propTypes: {
        headers: PropTypes.arrayOf(PropTypes.string).isRequired,
        onSort: PropTypes.func,
        checkable: PropTypes.bool,
        checked: PropTypes.bool,
        onChange: PropTypes.func,
        customHeaders: PropTypes.shape({}),
        headersTip: PropTypes.objectOf(PropTypes.string), // permet de mettre un tooltip sur les headers
        headersIcon: PropTypes.objectOf(PropTypes.shape({ // permet de mettre une icone sur les headers
            icon: PropTypes.string,
            color: PropTypes.string,
        })),
        headersOnClick: PropTypes.shape({}),
        sortColumn: PropTypes.shape({
            column: PropTypes.string.isRequired,
            sort: PropTypes.string.isRequired,
        }),
        centerNumberHeaders: PropTypes.bool,
        condensed: PropTypes.bool,
        customWidthHeaders: PropTypes.shape({}),
    },
    render() {
        const { customWidthHeaders, headers, headersTip, headersIcon } = this.props
        const customStyleHeaders = Object.keys(this.props.customWidthHeaders).length === headers.length ?
            headers.reduce((acc, header) => ({ ...acc, [header]: { style: { width: customWidthHeaders[header], maxWidth: customWidthHeaders[header] } } }), {}) : []
        const rows = headers.map((header) => {
            if (header === 'check') {
                if (this.props.condensed) {
                    return (
                        <th {...customStyleHeaders[header]} className='table-body-condensed column-checkbox'>
                            <Checkbox checked={ this.props.checked } condensed={ this.props.condensed }
                                onChange={ this.props.onChange }
                            />
                        </th>
                    )
                }
                return (
                    <th {...customStyleHeaders[header]} className='column-checkbox'>
                        <Checkbox checked={ this.props.checked } onChange={ this.props.onChange }/>
                    </th>
                )
            }

            const name = (() => {
                if (this.props.customHeaders[header]) {
                    return this.props.customHeaders[header]
                }
                if (!/^\d+$/.test(header) && /^[0-9A-Za-z]+$/.test(header)) {
                    try {
                        return i18n[header]
                    } catch (e) {
                        return (
                            <a>
                                <i className='waves-effect material-icons'>grade</i>
                            </a>
                        )
                    }
                }
                return header
            })()
            const idUUid = uuidv4()

            const hover = headersTip?.[header] ? { onMouseOver: () => setSieauTooltip(idUUid, headersTip?.[header]), onMouseOut: () => setSieauTooltip(null), onMouseDown: () => setSieauTooltip(null) } : {}

            const icon = headersIcon?.[header] ? <Icon icon={headersIcon?.[header]?.icon} style={{ color: headersIcon?.[header]?.color }} className='padding-left-1'/> : null

            const onClickFunc = this.props.headersOnClick[header] || (() => this.props.onSort(header))

            const centerHeader = this.props.centerNumberHeaders && !!(parseInt(name) + 1000) ? 'centerText' : ''

            if (icon) {
                return (
                    <th id={idUUid} {...customStyleHeaders[header]} {...hover} key={header} onClick={onClickFunc} className={`clickable no-padding ${centerHeader}`}><span className={'valign-wrapper'}>{name}<i aria-hidden='true' />{icon}</span></th>
                )
            }

            return (
                <th id={idUUid} {...customStyleHeaders[header]} {...hover} key={header} onClick={onClickFunc} className={`clickable no-padding ${centerHeader}`}>{name}<i aria-hidden='true' /></th>
            )
        })
        return (
            <tr>
                { rows }
            </tr>
        )
    },
})
