import { Grid, Tooltip } from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'
import i18n from 'simple-react-i18n'
import { getVigilanceColor } from '../../../utils/ColorUtil'
import { substringText } from '../../../utils/StringUtil'
import { getText } from '../../../utils/VigilanceUtil'
import DtoVigilance from '../../dto/DtoVigilance'

const VigilanceCard = ({
    vigilance,
    smallCard = false,
    containerStyle = {},
}) => (
    <Grid
        container
        item xs={12}
        className={`${vigilance.vigilance ? getVigilanceColor(vigilance.vigilance) : ''} ${smallCard ? 'border-bottom-light' : ''}`}
        sx={!smallCard ? {
            width: '80%',
            margin: 'auto',
            marginTop: '50px',
            marginBottom: '36px',
            borderRadius: '2px',
            boxShadow: '0px 5px 8px rgb(0 0 0 / 8%) !important',
            transition: 'transform 0.25s ease-in-out !important',
            '&:hover': {
                transform: 'scale(1.05)',
            },
            ...containerStyle,
        } : containerStyle}
    >
        <Tooltip
            placement='top-start'
            arrow
            PopperProps={{
                disablePortal: true,
            }}
            title={vigilance.texts && substringText(getText(vigilance.texts))}
        >
            <>
                <Grid container item xs={2} alignItems='center'>
                    <Grid container item xs={12} justifyContent='center'>
                        <Grid item>
                            <img src={vigilance.url} alt='vigilanceImage' width='auto' style={{ height: '2.5rem' }} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container item xs={10} className={`white ${smallCard ? 'padding-left-2' : ''}`} sx={{ padding: `${!smallCard ? '0 0.75rem' : ''}` }}>
                    <Grid item xs={12}>
                        {!smallCard && (
                            <h4 className='clickable bold' style={{ marginTop: '1rem', fontSize: '2rem' }} >{`${vigilance.info} - ${vigilance.locationName} (${vigilance.locationCode})`}</h4>
                        )}
                        {smallCard && (
                            <b className='bold'>{`${vigilance.info} - ${vigilance.locationName} (${vigilance.locationCode})`}</b>
                        )}
                    </Grid>
                    <Grid item xs={12}>
                        {!smallCard && (
                            <h6 className='clickable' style={{ marginBottom: '1rem' }} >{`${i18n.fromDate} ${vigilance.start} ${i18n.to} ${vigilance.end}`}</h6>
                        )}
                        {smallCard && (
                            <span>{`${i18n.fromDate} ${vigilance.start} ${i18n.to} ${vigilance.end}`}</span>
                        )}
                    </Grid>
                </Grid>
            </>
        </Tooltip>
    </Grid>
)

VigilanceCard.propTypes = {
    vigilance: PropTypes.instanceOf(DtoVigilance),
    smallCard: PropTypes.bool,
    containerStyle: PropTypes.shape({}),
}

export default VigilanceCard
