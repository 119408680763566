import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import AppStore from 'store/AppStore'
import ToastrAction from 'toastr/actions/ToastrAction'
import ProgressCard from '../../../../components/card/ProgressCard'
import Table from '../../../../components/datatable/Table'
import Checkbox from '../../../../components/forms/Checkbox'
import Input from '../../../../components/forms/Input'
import Select from '../../../../components/forms/Select'
import DtoParametrageDataType from '../../../../piezometry/dto/DtoParametrageDataType'
import DtoPiezometerLight from '../../../../piezometry/dto/DtoPiezometerLight'
import { nbPerPageLabelShort } from '../../../../referencial/constants/ReferencialConstants'
import { getIntegrationModes } from '../../../../utils/JobUtils'
import { arrayOf } from '../../../../utils/StoreUtils'
import JobAction from '../../../actions/JobAction'
import Job from '../../../dto/DtoJob'
import { integrationModeHelpIcon } from '../../../utils/ImportUtils'
import { Buffer } from 'buffer'

class HubeauPiezoPanel extends Component {
    constructor(props) {
        super(props)
        this.state = {
            dataLoaded: false,
            progress: 0,
            brute: false,
            max: false,
            min: false,
        }
    }

    componentDidMount() {
        AppStore.dispatch(JobAction.loadCSVImportPanel(p => this.setState({ progress: p }), () => this.setState({ dataLoaded: true })))
    }

    onChangeParametersNew = (currentParameter, index, changes) => {
        const jobParameters = {
            ...this.props.job.parameters,
            parameters: Object.assign([], this.props.job.parameters.parameters, {
                [index]: JSON.stringify({ ...currentParameter, ...changes }),
            }),
        }
        this.props.onChangeJob({ parameters: jobParameters })
    }

    onChangeAllParameters = (oldParameters, changes) => {
        const jobParameters = {
            ...this.props.job.parameters,
            parameters: oldParameters.map(p => JSON.stringify({ ...p, ...changes })),
        }
        this.props.onChangeJob({ parameters: jobParameters })
    }

    onChangeFilters = (changes) => {
        const parameters = {
            ...this.props.job.parameters,
            filters: [JSON.stringify({
                ...this.getFilters(),
                ...changes,
            })],
        }
        this.props.onChangeJob({ parameters })
    }

    getFilters = () => this.props.job.parameters.filters.length ? JSON.parse(this.props.job.parameters.filters[0]) : {}

    getParametersNew = () => this.props.job.parameters.parameters.length ? this.props.job.parameters.parameters.map(p => JSON.parse(p)) : []

    onDeleteParameter = (index) => {
        if (this.props.isEditMode) {
            const jobParameters = {
                ...this.props.job.parameters,
                parameters: this.props.job.parameters.parameters.filter((_, i) => i !== index),
            }
            this.props.onChangeJob({ parameters: jobParameters })
        }
    }

    onDuplicateParameter = (index) => {
        if (this.props.isEditMode) {
            const jobParameters = {
                ...this.props.job.parameters,
                parameters: [ ...this.props.job.parameters.parameters, this.props.job.parameters.parameters[index] ],
            }
            this.props.onChangeJob({ parameters: jobParameters })
        }
    }

    getParametersData = (parameters) => {
        const disabled = { disabled: !this.props.isEditMode }
        return parameters.map((p, index) => {
            return {
                station: <Input value={ p.station } onChange={ v => this.onChangeParametersNew(p, index, { station: v }) } {...disabled }/>,
                chronic: <Checkbox checked={p.chronic} onChange={v => this.onChangeParametersNew(p, index, { chronic: v })} {...disabled}/>,
                chronic_tr: <Checkbox checked={p.chronic_tr} onChange={v => this.onChangeParametersNew(p, index, { chronic_tr: v })} {...disabled}/>,
                index,
            }
        })
    }

    addParameterNew = () => {
        if (this.props.isEditMode) {
            const jobParameters = {
                ...this.props.job.parameters,
                parameters: [ ...this.props.job.parameters.parameters, '{}'],
            }
            this.props.onChangeJob({ parameters: jobParameters })
        }
    }

    getSetAllCheckbox = (jsParameters, stateName, checkboxesName) => (
        <div>
            <div className='row no-margin'>
                <p className='bold'>{ i18n[stateName] }</p>
            </div>
            {
                this.props.isEditMode && (
                    <div className='row no-margin'>
                        <Checkbox checked={this.state[stateName]} onChange={v => {
                            this.setState({ [stateName]: v })
                            this.onChangeAllParameters(jsParameters, { [checkboxesName]: v })
                        }}
                        />
                    </div>
                )
            }
        </div>
    )

    onImportFile = (e) => {
        if (this.props.isEditMode) {
            const reader = new FileReader()
            const file = e.target.files[0]
            reader.onload = upload => {
                const split = upload.target.result.split(',')
                if (split[0].indexOf('text/csv') > 0 || split[0].indexOf('vnd.ms-excel') > 0 || split[0].indexOf('text/plain') > 0) {
                    const buf = Buffer.from(split[1], 'base64').toString()
                    const codes = buf.replace(/;/g, '')
                    const finalCodes = codes.split('\n').map(s => s.trim()).filter(s => !!s)
                    this.onChangeAllParameters(finalCodes.map(c => ({ station: c })), {})
                } else {
                    AppStore.dispatch(ToastrAction.error(i18n.theSelectedFileMustBeInCsvFormat))
                }
            }
            reader.readAsDataURL(file)
        }
    }

    exportData = (parameters) => {
        let data = []
        parameters.map(p => {
            if (p.station.props.value) {
                data.push({
                    station: p.station.props.value,
                    chronic: p.chronic.props.checked ? `${i18n.yes}` : `${i18n.no}`,
                    chronic_tr: p.chronic_tr.props.checked ? `${i18n.yes}` : `${i18n.no}`,
                    headers: ['station', 'chronic', 'chronic_tr'],
                })
            }
        })
        return data
    }

    render() {
        if (this.state.dataLoaded) {
            const jsParameters = this.getParametersNew()
            const filters = this.getFilters()
            const parameters = this.getParametersData(jsParameters)
            const baseHeaders = ['station', 'chronic', 'chronic_tr']
            const customHeaders = {
                chronic: this.getSetAllCheckbox(jsParameters, 'chronic', 'chronic'),
                chronic_tr: this.getSetAllCheckbox(jsParameters, 'chronic_tr', 'chronic_tr'),
            }
            const integrationModes = getIntegrationModes()
            const data = this.exportData(parameters)
            const addDataAction = this.props.isEditMode ? [{
                onClick: this.addParameterNew,
                iconName: 'add_box',
                tooltip: `${i18n.add}`,
            }] : []
            return (
                <div>
                    <div className='row no-margin'>
                        <Select col={ 4 } options={ integrationModes } obligatory={true} label={ i18n.dataIntegration } value={ filters.integrationMode } labelSpan={ integrationModeHelpIcon() }
                            onChange={ v => this.onChangeFilters({ integrationMode: v }) }
                            disabled={ !this.props.isEditMode }
                        />
                        <div className='col s5 no-padding btn file-field'>
                            <span>{ 'Importer un fichier (une ligne = un code BSS)' }</span>
                            <input
                                type='file'
                                accept='.csv, .txt, .CSV, .TXT'
                                onChange={ this.onImportFile }
                                disabled={ !this.props.isEditMode }
                            />
                        </div>
                        <div className='col s1' />
                    </div>
                    <div className='job-parameter-table'>
                        <Table
                            data={ parameters }
                            nbPerPageLabel={ nbPerPageLabelShort }
                            type={ { headers: baseHeaders } }
                            sortable
                            condensed
                            paging
                            customHeaders={ customHeaders }
                            exportButtonOnHeader
                            actions={ addDataAction }
                            activeHeader
                            exportData={ data }
                            deletable={this.props.isEditMode}
                            onDelete={({ index }) => this.onDeleteParameter(index)}
                            duplicable={this.props.isEditMode}
                            onDuplicate={({ index }) => this.onDuplicateParameter(index)}
                        />
                    </div>
                </div>
            )
        }
        return <ProgressCard progress={ this.state.progress }/>
    }
}

HubeauPiezoPanel.propTypes = {
    job: PropTypes.instanceOf(Job).isRequired,
    onChangeJob: PropTypes.func.isRequired,
    piezometryDataTypes: arrayOf(DtoParametrageDataType),
    piezometers: arrayOf(DtoPiezometerLight),
    isEditMode: PropTypes.bool,
}

const mapStateToProps = store => ({
    piezometryDataTypes: store.PiezometryReducer.piezometryDataTypes,
    piezometers: store.PiezometryReducer.piezometersLight,
})

export default connect(mapStateToProps)(HubeauPiezoPanel)
