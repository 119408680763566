import Card from 'components/card/Card'
import Input from 'components/forms/Input'
import SimpleTabSideList from 'components/navbar/SimpleTabSideList'
import { difference, orderBy, uniq } from 'lodash'
import React, { useMemo, useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import { searchAllCharacters } from 'utils/StringUtil'
import PropTypes from 'prop-types'
import DtoOperation from 'quality/components/operation/dto/DtoOperation'
import Table from 'components/datatable/Table'
import HydrobioMonitoringOperationGraph from 'quality/components/hydrobio/monitoring/HydrobioMonitoringOperationGraph'
import HydrobioMonitoringOverlayGraph from 'quality/components/hydrobio/monitoring/HydrobioMonitoringOverlayGraph'
import useUpdateEffect from 'utils/customHook/useUpdateEffect'

const TAXON = 'TAXON'

const TabTaxon = ({
    listTaxons = [],
    selectedTaxons = [],
    setSelectedTaxons = () => { },
}) => {
    const {
        taxons,
    } = useSelector(store => ({
        taxons: store.TaxonReducer.taxons,
    }), shallowEqual)

    const [searchValue, setSearchValue] = useState('')

    const taxonsFormated = useMemo(() => listTaxons.map(code => {
        const isSelected = selectedTaxons.includes(code)

        const taxon = taxons.find(t => t.code === code)
        return {
            nullValue: (
                <i className='material-icons tiny table-checkbox'>{isSelected ? 'check_box' : 'check_box_outline_blank'}</i>
            ),
            taxon: taxon?.labelWithCode || '',
            searchValue: searchAllCharacters(`${code}${taxon?.name || ''}${taxon?.latinName || ''}`),
            code,
            isSelected,
        }
    }), [listTaxons, selectedTaxons, taxons])

    const taxonsFiltered = useMemo(() => {
        const searchCharacter = searchAllCharacters(searchValue)
        return searchCharacter ? taxonsFormated.filter(t => t.searchValue.includes(searchCharacter)) : taxonsFormated
    }, [taxonsFormated, searchValue])

    const taxonsOrdered = useMemo(() => orderBy(taxonsFiltered, 'taxon'), [taxonsFiltered])

    const isAllSelected = taxonsFiltered.every(t => t.isSelected)

    const onSelectAll = () => {
        const selectListTaxon = taxonsFiltered.map(t => t.code)
        if (isAllSelected) {
            setSelectedTaxons(prev => difference(prev, selectListTaxon))
        } else {
            setSelectedTaxons(prev => uniq([...prev, ...selectListTaxon]))
        }
    }

    return (
        <div className='padding-top-1'>
            <Input
                title={i18n.search}
                value={searchValue}
                onChange={setSearchValue}
            />
            <Table
                showTitle={false}
                data={taxonsOrdered}
                sortable
                onClick={({ code, isSelected }) => setSelectedTaxons(prev => {
                    if (isSelected) {
                        return prev.filter(p => p !== code)
                    }
                    return [...prev, code]
                })}
                maxHeight={'60vh'}
                customHeaders={{ nullValue: (<i className='material-icons tiny table-checkbox' onClick={onSelectAll}>{isAllSelected ? 'check_box' : 'check_box_outline_blank'}</i>) }}
                type={{ headers: ['nullValue', 'taxon'] }}
                condensed
                className='no-margin'
                noHightlight
            />
        </div>
    )
}

TabTaxon.propTypes = {
    listTaxons: PropTypes.arrayOf(PropTypes.string),
    selectedTaxons: PropTypes.arrayOf(PropTypes.string),
    setSelectedTaxons: PropTypes.func,
}

const HydrobioMonitoringGraph = ({
    hydrobioLists = [],
    hydrobioOperations = [],
}) => {
    const [selectedTaxons, setSelectedTaxons] = useState([])
    const listTaxons = useMemo(() => uniq(hydrobioLists.map(l => l.taxon)), [hydrobioLists])

    useUpdateEffect(() => {
        setSelectedTaxons([])
    }, [hydrobioLists, hydrobioOperations])

    return (
        <Card height={700}>
            <div className='row no-margin relative no-overflow'>
                <SimpleTabSideList
                    position='right'
                    defaultTab={TAXON}
                    tabs={[{
                        icon: 'edit',
                        constant: TAXON,
                        label: i18n.taxons,
                    }]}
                >
                    {
                        tab => (
                            <>
                                {
                                    tab === TAXON && (
                                        <TabTaxon
                                            listTaxons={listTaxons}
                                            selectedTaxons={selectedTaxons}
                                            setSelectedTaxons={setSelectedTaxons}
                                        />
                                    )
                                }
                            </>
                        )
                    }
                </SimpleTabSideList>
                <div>
                    {
                        !selectedTaxons.length && (
                            <HydrobioMonitoringOperationGraph
                                hydrobioLists={hydrobioLists}
                                hydrobioOperations={hydrobioOperations}
                            />
                        )
                    }
                    {
                        !!selectedTaxons.length && (
                            <HydrobioMonitoringOverlayGraph
                                hydrobioLists={hydrobioLists}
                                selectedTaxons={selectedTaxons}
                            />
                        )
                    }
                </div>
            </div>
        </Card>
    )
}

HydrobioMonitoringGraph.propTypes = {
    hydrobioLists: PropTypes.arrayOf(PropTypes.shape({
        // DtoHydrobioList
        date: PropTypes.number,
        support: PropTypes.string,
        total: PropTypes.number,
    })),
    hydrobioOperations: PropTypes.arrayOf(PropTypes.instanceOf(DtoOperation)),
}

export default HydrobioMonitoringGraph