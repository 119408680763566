import DtoJobParameters from './DtoJobParameters'

export default class DtoJob {
    constructor(obj = {}) {
        this.id = obj.id
        this.description = obj.description
        this.cron = obj.cron
        this.jobType = obj.jobType
        this.login = obj.login
        this.name = obj.name
        this.nbExecutions = obj.nbExecutions
        this.nbFiles = obj.nbFiles
        this.parameters = new DtoJobParameters(JSON.parse(obj.parameters || '{}'))
        this.jobNextId = obj.jobNextId
        this.managers = obj.managers
        this.updateDate = obj.updateDate
    }
}
