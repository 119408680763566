/* eslint-disable camelcase */
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import Table from '../../../../../components/datatable/Table'
import SieauAction from '../../../../../components/sieau/SieauAction'
import DtoDistributionUnit from '../../../../../distributionUnit/dto/DtoDistributionUnit'
import DtoHydrometricStation from '../../../../../hydrometry/dto/DtoHydrometricStation'
import DtoPiezometer from '../../../../../piezometry/dto/DtoPiezometer'
import DtoProductionUnit from '../../../../../productionUnit/dto/DtoProductionUnit'
import DtoQualitometer from '../../../../../quality/dto/DtoQualitometer'
import DtoAquifer from '../../../../../referencial/components/aquifers/dto/DtoAquifer'
import { getExport, setModal } from '../../../../../utils/linkUtils'
import { arrayOf, getLabel } from '../../../../../utils/StoreUtils'
import DtoInstallation from '../../../../dto/installation/DtoInstallation'
import BoreholeAquiferModal from './BoreholeAquiferModal'

const BoreholeAquiferTable = ({
    station = {},
    aquifers = [],
    readMode = false,
    onChange = () => {},
    setPopup = () => {},
}) => {
    const otherAquifer = station.link_aquifers.filter(a => a.order === 0)
    const stationAquifers = station.link_aquifers.filter(a => a.order === 1).map((c, index) => ({
        ...c,
        index,
    }))
    const tableData = stationAquifers.map(c => ({
        ...c,
        aquifer: getLabel(aquifers, c.aquiferCode),
    }))
    const headers = ['aquifer', 'depth', 'comment']
    const exportAction = getExport(tableData, i18n.aquifers, headers)
    const actions = !readMode ? [{
        iconName: 'note_add',
        tooltip: i18n.add,
        onClick: () => setModal(i18n.stratigraphy, <BoreholeAquiferModal id={ station.id } saveResult={ r => onChange({ link_aquifers: [...otherAquifer, ...stationAquifers, r] }) }/>, setPopup),
    }, exportAction] : [exportAction]

    return (
        <Table
            title={ i18n.stratigraphy }
            condensed
            data={ tableData }
            type={{ headers }}
            sortable={ !!tableData.length }
            actions={ actions }
            alterable={ !readMode }
            onAlter={
                (element) => setModal(i18n.stratigraphy,
                    <BoreholeAquiferModal
                        id={ station.id }
                        boreholeAquifer={ stationAquifers.find(c => element.index === c.index) }
                        saveResult={ r => onChange({
                            link_aquifers: [
                                ...otherAquifer,
                                ...stationAquifers.filter(lc => lc.index !== element.index),
                                { ...r, index: element.index },
                            ],
                        })}
                    />
                    , setPopup)
            }
            deletable={ !readMode }
            onDelete={ (element) => onChange({ link_aquifers: [ ...otherAquifer, ...stationAquifers.filter(lc => lc.index !== element.index)] }) }
        />
    )
}

BoreholeAquiferTable.propTypes = {
    station: PropTypes.oneOfType([
        PropTypes.instanceOf(DtoProductionUnit),
        PropTypes.instanceOf(DtoDistributionUnit),
        PropTypes.instanceOf(DtoQualitometer),
        PropTypes.instanceOf(DtoPiezometer),
        PropTypes.instanceOf(DtoHydrometricStation),
        PropTypes.instanceOf(DtoInstallation),
    ]),
    aquifers: arrayOf(DtoAquifer),
    readMode: PropTypes.bool,
    onChange: PropTypes.func,
    setPopup: PropTypes.func,
}

const mapStateToProps = store => ({
    aquifers: store.AquiferReducer.aquifers,
})

const mapDispatchToProps = {
    setPopup: SieauAction.setPopup,
}

export default connect(mapStateToProps, mapDispatchToProps)(BoreholeAquiferTable)