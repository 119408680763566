import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import ReactECharts from 'echarts-for-react'
import echarts from 'echarts/lib/echarts'
import DtoAnalysisUltraLight from 'quality/dto/analyse/DtoAnalysisUltraLight'
import i18n from 'simple-react-i18n'
import { Grid } from '@mui/material'
import useApplicationSetting, { floatParser, intParser, listStringParser } from 'utils/customHook/useApplicationSetting'
import { findIndex, groupBy, isUndefined, keyBy, max, range, round, sumBy } from 'lodash'
import { NITRATES_CODE } from 'catchment/constants/CatchmentConstant'
import moment from 'moment'
import { shallowEqual, useSelector } from 'react-redux'
import { searchMaxAnalysis, searchMaxResult, searchP90Result, tabPastelColorsBySize } from 'utils/AnalyseUtils'
import { getPercentage } from 'utils/NumberUtil'
import { exportPictureIcon } from 'components/echart/EChartUtils'
import CatchmentDto from 'catchment/dto/CatchmentDto'
import CatchmentPointDto from 'catchment/dto/CatchmentPointDto'
import useStartPeriod from 'catchment/utils/useStartPeriod'
import useEndPeriod from 'catchment/utils/useEndPeriod'

const DEFAULT_NITRATE_THRESHOLDS = [2, 10, 25, 50]
const DEFAULT_PESTICIDES_THRESHOLDS = [0.1, 2]
const DEFAULT_SUM_PESTICIDES_THRESHOLDS = [0.5, 5]

const EvolutionChart = ({
    title = '',
    dateList = [],
    datas = [],
}) => {
    const series = datas.map(({ name, dataList, color }) => {
        const formattedDatas = dataList.map(d => ({
            value: [d.date, d.value],
        }))
        return {
            type: 'bar',
            data: formattedDatas,
            name,
            // name: color, // p90 <= 2 mg/l
            barMaxWidth: 30,
            stack: true,
            itemStyle: {
                color,
            },
            label: {
                show: true,
                formatter: obj => {
                    return round(obj.data.value[1], 2) || ''
                },
                position: 'inside',
                color: '#000',
            },
        }
    })

    const options = {
        title: {
            text: title,
            left: 'center',
        },
        series,
        xAxis: [{
            type: 'category',
            data: dateList.toReversed?.() ?? [...dateList].reverse(), // compatibility issue
            boundaryGap: true,
            showSplitLine: true,
            axisLabel: {
                rotate: 50,
            },
        }],
        yAxis: [{
            type: 'value',
            name: i18n.catchmentPercentage,
            position: 'left',
            nameLocation: 'center',
            nameGap: 50,
            boundaryGap: true,
            min: 0,
            max: 100,
        }],
        legend: {
            top: '5%',
            left: '10%',
            right: '8%',
            type: 'scroll',
        },
        grid: {
            top: '15%',
            left: '6%',
            right: '3%',
            bottom: '80',
            containLabel: true,
            height: 300,
        },
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'shadow',
                snap: true,
            },
            formatter: params => {
                return params.map(({ marker, seriesName, value: [, value] }) => `${marker} ${seriesName}: ${round(value, 3)}%`).join('</br>')
            },
        },
        toolbox: {
            right: '3%',
            top: '5%',
            feature: {
                saveAsImage: {
                    title: i18n.export,
                    icon: exportPictureIcon,
                    name: i18n.evolutionContaminationNitratesPesticides,
                },
            },
        },
    }

    return (
        <ReactECharts
            echarts={echarts}
            option={options}
            notMerge
            lazyUpdate
            className={'row no-margin'}
            style={{ height: 400 }}
        />
    )
}

EvolutionChart.propTypes = {
    title: PropTypes.string,
    dateList: PropTypes.arrayOf(PropTypes.string),
    datas: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        color: PropTypes.string,
        dataList: PropTypes.arrayOf(PropTypes.shape({
            date: PropTypes.string,
            value: PropTypes.number,
        })),
    })),
}

const NitratesChart = ({
    catchments = [],
    catchmentPoints = [],
    groupedAnalysis = [],
    useP90 = false,
}) => {
    const {
        qualitometers,
    } = useSelector(store => ({
        qualitometers: store.QualityReducer.qualitometers,
    }), shallowEqual)

    const threshold1 = useApplicationSetting('CATCHMENT_NITRATES_DISTRIBUTION_THRESHOLD_1', floatParser)
    const threshold2 = useApplicationSetting('CATCHMENT_NITRATES_DISTRIBUTION_THRESHOLD_2', floatParser)
    const threshold3 = useApplicationSetting('CATCHMENT_NITRATES_DISTRIBUTION_THRESHOLD_3', floatParser)
    const threshold4 = useApplicationSetting('CATCHMENT_NITRATES_DISTRIBUTION_THRESHOLD_4', floatParser)

    const regroupment = useApplicationSetting('CATCHMENT_REGROUPING', setting => setting ? parseInt(setting) : 2)

    const startPeriod = useStartPeriod(intParser)
    const endPeriod = useEndPeriod(intParser)

    const rangeDate = range(endPeriod, startPeriod - 1, -regroupment)
    const formatDate = date => date === startPeriod || regroupment === 1 ? `${date}` : `${Math.max(startPeriod, date - regroupment + 1)}-${date}`
    const dateList = rangeDate.map(formatDate)

    const settingThresholds = [threshold1, threshold2, threshold3, threshold4].filter(t => !isUndefined(t))
    const thresholds = settingThresholds.length ? settingThresholds : DEFAULT_NITRATE_THRESHOLDS
    const thresholdsColors = tabPastelColorsBySize[thresholds.length + 1]

    const dataByDate = rangeDate.reduce((acc, date) => {
        acc[date] = groupBy(catchments, c => {
            const startDate = moment(date - regroupment + 1, 'YYYY').startOf('year').valueOf()
            const endDate = moment(date, 'YYYY').endOf('year').valueOf()
            const points = catchmentPoints.filter(p => p.id === c.id)
            const ids = points.map(p => qualitometers.find(q => q.code === p.codeWithoutDesignation)?.id).filter(id => !isUndefined(id))

            const analysis = ids.flatMap(id => groupedAnalysis[id] || []).filter(a => a.analysisDate > startDate && a.analysisDate < endDate)
            if (analysis.length === 0) {
                return 'noData'
            }
            const result = useP90 ? searchP90Result(analysis) : searchMaxResult(analysis)
            const indice = findIndex(thresholds, t => result < t)
            return indice !== -1 ? `threshold${indice}` : `threshold${thresholds.length}`
        })
        return acc
    }, {})

    const getName = i => {
        const data = useP90 ? 'P90' : 'MAX'
        if (i === 0) {
            return `${data} <= ${thresholds[i]} mg/l`
        }
        if (i === thresholds.length) {
            return `${data} > ${thresholds[i - 1]} mg/l`
        }
        return `${thresholds[i - 1]} < ${data} <= ${thresholds[i]} mg/l`
    }

    const datas = thresholdsColors.map((color, i) => ({
        name: getName(i),
        color,
        dataList: rangeDate.map(date => {
            const value = dataByDate[date][`threshold${i}`]?.length ?? 0
            return {
                date: formatDate(date),
                value: getPercentage(value, catchments.length),
            }
        }),
    }))

    return (
        <EvolutionChart
            title={i18n.distributionNitratesConcentration}
            dateList={dateList}
            datas={[
                ...datas,
                {
                    name: i18n.noData,
                    color: '#DDDDDD',
                    dataList: rangeDate.map(date => {
                        const value = dataByDate[date].noData?.length ?? 0
                        return {
                            date: formatDate(date),
                            value: getPercentage(value, catchments.length),
                        }
                    }),
                }
            ]}
        />
    )
}

NitratesChart.propTypes = {
    catchments: PropTypes.arrayOf(PropTypes.instanceOf(CatchmentDto)),
    catchmentPoints: PropTypes.arrayOf(PropTypes.oneOfType([
        PropTypes.instanceOf(CatchmentPointDto),
        PropTypes.shape({
            // CatchmentPointDto
            mainPoint: PropTypes.bool,
        }),
    ])),
    groupedAnalysis: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.instanceOf(DtoAnalysisUltraLight))),
    useP90: PropTypes.bool,
}

const PesticidesChart = ({
    catchments = [],
    catchmentPoints = [],
    groupedAnalysis = {},
}) => {
    const {
        qualitometers,
        qualityThresholds,
    } = useSelector(store => ({
        qualitometers: store.QualityReducer.qualitometers,
        qualityThresholds: store.QualityReducer.qualityThresholds,
    }), shallowEqual)

    const threshold1 = useApplicationSetting('CATCHMENT_PESTICIDES_DISTRIBUTION_THRESHOLD_1', floatParser)
    const threshold2 = useApplicationSetting('CATCHMENT_PESTICIDES_DISTRIBUTION_THRESHOLD_2', floatParser)
    const sumThreshold1 = useApplicationSetting('CATCHMENT_SUM_PESTICIDES_DISTRIBUTION_THRESHOLD_1', floatParser)
    const sumThreshold2 = useApplicationSetting('CATCHMENT_SUM_PESTICIDES_DISTRIBUTION_THRESHOLD_2', floatParser)

    const listSumPesticides = useApplicationSetting('CATCHMENT_LIST_SUM_PESTICIDES', listStringParser)
    const regroupment = useApplicationSetting('CATCHMENT_REGROUPING', setting => setting ? parseInt(setting) : 2)

    const startPeriod = useStartPeriod(intParser)
    const endPeriod = useEndPeriod(intParser)

    const specificThreshold = useApplicationSetting('CATCHMENT_SPECIFIC_THRESHOLD')
    const threshold = qualityThresholds.find(t => t.thresholdCode === specificThreshold)
    const thresholdsIndexed = keyBy(threshold?.thresholds ?? [], 'parameterCode')

    const rangeDate = range(endPeriod, startPeriod - 1, -regroupment)
    const formatDate = date => date === startPeriod || regroupment === 1 ? `${date}` : `${Math.max(startPeriod, date - regroupment + 1)}-${date}`
    const dateList = rangeDate.map(formatDate)

    const settingThresholds = [threshold1, threshold2].filter(t => !isUndefined(t))
    const thresholds = settingThresholds.length ? settingThresholds : DEFAULT_PESTICIDES_THRESHOLDS
    const thresholdsColors = tabPastelColorsBySize[thresholds.length + 1]

    const settingSumThresholds = [sumThreshold1 ?? threshold1, sumThreshold2 ?? threshold2].filter(t => !isUndefined(t))
    const sumThresholds = settingSumThresholds.length ? settingSumThresholds : DEFAULT_SUM_PESTICIDES_THRESHOLDS
    // const sumThresholdsColors = tabPastelColorsBySize[sumThresholds.length + 1]

    const dataByDate = rangeDate.reduce((acc, date) => {
        const startDate = moment(date - regroupment + 1, 'YYYY').startOf('year').valueOf()
        const endDate = moment(date, 'YYYY').endOf('year').valueOf()
        acc[date] = groupBy(catchments, c => {
            const points = catchmentPoints.filter(p => p.id === c.id)
            const ids = points.map(p => qualitometers.find(q => q.code === p.codeWithoutDesignation)?.id).filter(id => !isUndefined(id))

            const analysis = ids.flatMap(id => groupedAnalysis[id] || []).filter(a => a.analysisDate > startDate && a.analysisDate < endDate)
            if (analysis.length === 0) {
                return 'noData'
            }
            const quantifiedAnalysis = analysis.filter(a => a.remark === '1')
            if (quantifiedAnalysis.length === 0) return 'threshold0'

            const maxAnalysis = searchMaxAnalysis(quantifiedAnalysis)
            const paramThreshold = thresholdsIndexed[maxAnalysis.parameter]
            const thresholdsToTest = paramThreshold ? [paramThreshold.threshold1 ?? thresholds[0], paramThreshold.threshold2 ?? thresholds[1]].filter(t => !isUndefined(t)) : thresholds

            const maxResult = maxAnalysis.result
            const indiceResult = findIndex(thresholdsToTest, t => maxResult < t)

            const filteredAnalysis = listSumPesticides.length ? quantifiedAnalysis.filter(a => listSumPesticides.includes(a.parameter)) : quantifiedAnalysis
            const groupOperation = groupBy(filteredAnalysis, p => `${p.qualitometer}#${p.operation}`)
            const maxSum = max(Object.keys(groupOperation).some(key => sumBy(groupOperation[key], 'result')))
            const indiceSum = findIndex(sumThresholds, t => maxSum < t)

            const indice = max([indiceResult, indiceSum])

            return indice !== -1 ? `threshold${indice}` : `threshold${thresholds.length}`
        })
        return acc
    }, {})

    const getName = i => {
        if (i === 0) {
            // return `${i18n.respectThreshold} ${thresholds[i]} μg/l (${i18n.maxByPesticides}) ${i18n.and} ${sumThresholds[i]} μg/l (${i18n.sum})`
            return `${i18n.respectThreshold} (${i18n.maxByPesticides}) ${i18n.and} ${sumThresholds[i]} μg/l (${i18n.sum})`
        }
        // return `${i18n.exceedingThreshold} ${thresholds[i - 1]} μg/l (${i18n.maxByPesticides}) ${i18n.and}/${i18n.or} ${sumThresholds[i - 1]} μg/l (${i18n.sum})`
        return `${i18n.exceedingThreshold} (${i18n.maxByPesticides}) ${i18n.and}/${i18n.or} ${sumThresholds[i - 1]} μg/l (${i18n.sum})`
    }

    const datas = thresholdsColors.map((color, i) => ({
        name: getName(i),
        color,
        dataList: rangeDate.map(date => {
            const value = dataByDate[date][`threshold${i}`]?.length ?? 0
            return {
                date: formatDate(date),
                value: getPercentage(value, catchments.length),
            }
        }),
    }))

    return (
        <EvolutionChart
            title={i18n.distributionRespectAEPThreshold}
            dateList={dateList}
            datas={[
                ...datas,
                {
                    name: i18n.noData,
                    color: '#DDDDDD',
                    dataList: rangeDate.map(date => {
                        const value = dataByDate[date].noData?.length ?? 0
                        return {
                            date: formatDate(date),
                            value: getPercentage(value, catchments.length),
                        }
                    }),
                }
            ]}
        />
    )
}

PesticidesChart.propTypes = {
    catchments: PropTypes.arrayOf(PropTypes.instanceOf(CatchmentDto)),
    catchmentPoints: PropTypes.arrayOf(PropTypes.oneOfType([
        PropTypes.instanceOf(CatchmentPointDto),
        PropTypes.shape({
            // CatchmentPointDto
            mainPoint: PropTypes.bool,
        }),
    ])),
    groupedAnalysis: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.instanceOf(DtoAnalysisUltraLight))),
}

const DistributionChart = ({
    catchments = [],
    catchmentPoints = [],
    analysis = [],
    useP90 = false,
}) => {
    const groupedNitratesAnalysis = useMemo(() => {
        const nitratesAnalysis = analysis.filter(({ parameter }) => parameter === NITRATES_CODE).filter(a => a.remark === '1')
        return groupBy(nitratesAnalysis, 'qualitometer')
    }, [analysis])

    const groupPesticidesAnalysis = useMemo(() => {
        const pesticidesAnalysis = analysis.filter(({ parameter }) => parameter !== NITRATES_CODE)
        return groupBy(pesticidesAnalysis, 'qualitometer')
    }, [analysis])

    return (
        <Grid container>
            <Grid item xs={6}>
                <NitratesChart
                    catchments={catchments}
                    catchmentPoints={catchmentPoints}

                    groupedAnalysis={groupedNitratesAnalysis}
                    useP90={useP90}
                />
            </Grid>
            <Grid item xs={6}>
                <PesticidesChart
                    catchments={catchments}
                    catchmentPoints={catchmentPoints}

                    groupedAnalysis={groupPesticidesAnalysis}
                />
            </Grid>
        </Grid>
    )
}

DistributionChart.propTypes = {
    catchments: PropTypes.arrayOf(PropTypes.instanceOf(CatchmentDto)),
    catchmentPoints: PropTypes.arrayOf(PropTypes.oneOfType([
        PropTypes.instanceOf(CatchmentPointDto),
        PropTypes.shape({
            // CatchmentPointDto
            mainPoint: PropTypes.bool,
        }),
    ])),
    analysis: PropTypes.arrayOf(PropTypes.instanceOf(DtoAnalysisUltraLight)),
    useP90: PropTypes.bool,
}

export default DistributionChart