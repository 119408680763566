import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import Input from '../../../../components/forms/Input'
import Row from '../../../../components/react/Row'
import { setModal } from '../../../../utils/FormUtils'
import Job from '../../../dto/DtoJob'
import ImportFileModal from './ImportFileModal'

class ImportEdiLaboDAIPanel extends Component {
    constructor(props) {
        super(props)
        const parameter = props.job.parameters.parameters.length ? JSON.parse(props.job.parameters.parameters[0]) : {}
        this.state = {
            directory: undefined,
            filter: undefined,
            rename: false,
            ...parameter,
        }
    }


    setAdvancedFtpModal =() => {
        setModal({
            title: i18n.importParam,
            content: <ImportFileModal url={this.state.directory} onChange={name =>{
                this.onChangeParameter({ filter: name })
                this.setState({ filter: name })
            } }
            />,
        })
    }

    onChangeParameter = obj => {
        const newParameter = {
            ...this.state,
            ...obj,
        }
        this.setState(obj)
        this.props.handleChangeParameters([JSON.stringify(newParameter)])
    }

    getDataTypes = type => {
        const { parameters } = this.props.job
        if (parameters.dataTypes?.length) {
            return parameters.dataTypes.find(d => d.includes(type)) || ''
        }
        return ''
    }

    handleChangeDataIntegration = integration => {
        const { parameters } = this.props.job
        const newDataTypes = (() => {
            const data = parameters.dataTypes.filter(d => !d.includes('data'))
            return integration ? [...data, integration] : data
        })()
        this.props.onChangeDataTypes(newDataTypes)
    }

    render() {
        const {
            directory,
            filter,
        } = this.state
        const disabled = { disabled: !this.props.isEditMode }
        return (
            <div>
                <br />
                <Row className='valign-wrapper'>
                    <Input col={ 6 } title={i18n.selectDirectory} value={directory} obligatory onChange={e => this.onChangeParameter({ directory: e })} {...disabled}/>
                    <div className='col s2'>
                        <a className='btn btn-small secondary-color-back ' onClick={ () => this.setAdvancedFtpModal()} ><span title={ i18n.importLabelFtp }> <i className='material-icons'>input </i> </span></a>
                    </div>
                    <Input col={ 4 } title={i18n.filter} value={filter} obligatory onChange={e => this.onChangeParameter({ filter: e })} {...disabled}/>
                </Row>

            </div>
        )
    }
}

ImportEdiLaboDAIPanel.propTypes = {
    job: PropTypes.instanceOf(Job).isRequired,
    isEditMode: PropTypes.bool,
    handleChangeParameters: PropTypes.func.isRequired,
    onChangeDataTypes: PropTypes.func.isRequired,
}


export default connect()(ImportEdiLaboDAIPanel)
