import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { getDate } from 'utils/DateUtil'
import { DATE } from './KeyFigureConstants'
import { getLongNumber, getNumberFormat } from '../../utils/NumberUtil'
import { filter } from 'lodash'
import { sieauTooltip } from '../../utils/FormUtils'
import { deburr, lowerCase } from 'lodash'
import { Card } from '@mui/material'
import { getI18nOrLabel } from 'utils/StringUtil'

const SimpleKeyFigurePanel = ({
    data,
    showAllValues,
    singleLine = false,
}) => {
    const filterValue = useMemo(() => showAllValues ? data : filter(data, o => o.value && o.value !== '0'), [data, showAllValues])

    const getLabelDate = (d) => {
        if (d.typeValue) {
            if (d.typeValue === DATE) {
                return [getDate(d.value), getDate(d.value)]
            }
            return [getLongNumber(d.value), getNumberFormat(d.value)]
        }
        return [d.value, d.value]
    }

    return (
        <Card
            sx={{
                borderRadius: '4px',
                boxShadow: '0px 4px 7px rgba(0, 0, 0, 0.1)',
                padding: '12px',
                backgroundColor: '#53a1ff',
                marginBottom: '12px',
            }}
        >
            {filterValue.map((d) => {
                const value = getLabelDate(d)
                const title = getI18nOrLabel(d.title)
                const tooltip = getI18nOrLabel(d.tooltip)
                const tooltipObject = sieauTooltip(`${value[1]} ${tooltip || title}`, null, 'bottom')
                const className = singleLine ? 'col l12 m12 s12' : 'col'
                return (
                    <div className={ `white-text ${className}` } key={deburr(lowerCase(title))}>
                        <span id={deburr(lowerCase(title))}>{ title }</span>
                        <a className='white-text' { ...tooltipObject }>
                            <h4 className='no-margin'>{ value[0] }</h4>
                        </a>
                    </div>
                )
            })}
        </Card>
    )
}
SimpleKeyFigurePanel.propTypes = {
    data: PropTypes.arrayOf(PropTypes.shape({
        title: PropTypes.string,
        tooltip: PropTypes.string,
        value: PropTypes.number,
        typeValue: PropTypes.string,
    })),
    showAllValues: PropTypes.bool,
    title: PropTypes.string,
    icon: PropTypes.string,
    singleLine: PropTypes.bool,
    noMargin: PropTypes.bool,
    round: PropTypes.bool,
}

export default SimpleKeyFigurePanel
