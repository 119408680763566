import React from 'react'
import i18n from 'simple-react-i18n'
import InDevelopmentBackground from '../../../../../inDevelop/components/InDevelopmentBackground'
import { Grid } from '@mui/material'
import AddPiezoStepOptionsAdes from './AddPiezoStepOptionsAdes'
import AddPiezoStepOptionsEmpty from './AddPiezoStepOptionsEmpty'
import { PIEZO_IMPORT_TYPE } from '../AddPiezoConstants'


const AddPiezoStepOptions = (props) => {
    const getOptionsPanel = () => {
        switch (props.station.stationType) {
            case PIEZO_IMPORT_TYPE.ADES_PIEZO:
                return <AddPiezoStepOptionsAdes {...props}/>
            case PIEZO_IMPORT_TYPE.EMPTY:
                return <AddPiezoStepOptionsEmpty {...props}/>
            default:
                return <InDevelopmentBackground />
        }
    }
    return (
        <div className='padding-top-2'>
            <Grid container justifyContent='center' alignItems='center' spacing={3}>
                <h4>{i18n.newStationStep2}</h4>
            </Grid>
            { getOptionsPanel() }
        </div>
    )
}

AddPiezoStepOptions.propTypes = {
}

export default AddPiezoStepOptions