import React from 'react'
import ineris from 'assets/pictures/logos/ineris.png'
import logoSandre from 'assets/pictures/logo_sandre.png'
import pubChem from 'assets/pictures/logos/pubChem.png'
import Table from 'components/datatable/Table'
import SimpleDatePicker from 'components/forms/SimpleDatePicker'
import ApplicationConf from 'conf/ApplicationConf'
import { push } from '@lagunovsky/redux-react-router'
import HomeAction from 'home/actions/HomeAction'
import { template } from 'lodash'
import { useEffect, useMemo, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import CityAction from 'referencial/components/city/actions/CityAction'
import FractionAction from 'referencial/components/fraction/actions/FractionAction'
import SupportAction from 'referencial/components/support/actions/SupportAction'
import UnitAction from 'referencial/components/unit/actions/UnitAction'
import i18n from 'simple-react-i18n'
import ToastrAction from 'toastr/actions/ToastrAction'
import useActions from 'utils/customHook/useActions'
import { v4 as uuidv4 } from 'uuid'
import Checkbox from '../../../../components/forms/Checkbox'
import Input from '../../../../components/forms/Input'
import NumberField from '../../../../components/forms/NumberField'
import Select from '../../../../components/forms/Select'
import Textarea from '../../../../components/forms/Textarea'
import Row from '../../../../components/react/Row'
import SieauAction from '../../../../components/sieau/SieauAction'
import {
    inerisParameterLink,
    pubChemParameterLink,
    sandreParameterLink,
} from '../../../../conf/SieauConstants'
import {
    PATH_REFERENCIAL,
    PATH_REFERENCIAL_PARAMETER,
    PATH_REFERENCIAL_PARAMETER_ITEM_LINK,
    PATH_REFERENCIAL_PARAMETER_ITEM_NEW,
} from '../../../../home/constants/RouteConstants'
import SSEActions from '../../../../sse/actions/SSEActions'
import {
    REFERENCIAL_PARAMETER_PURGED, REFERENCIAL_PARAMETER_PURGE_LOADING,
} from '../../../../sse/constants/EventsConstants'
import { checkMandatoryFields } from '../../../../utils/FormUtils'
import { getData } from '../../../../utils/SSEUtils'
import { default as ReferencialAction, default as ReferentialAction } from '../../../action/ReferencialAction'
import { getReferencialComponent } from '../../../util/ReferencialUtils'
import DeleteModal from '../../DeleteModal'
import ReplaceModal from '../../ReplaceModal'
import ParameterAction from '../actions/ParameterAction'
import { CLASS_GROUP, PARAMETER, USAGE_GROUP } from '../constants/ParameterConstants'
import ParameterDto from '../dto/ParameterDto'
import useTitle from 'utils/customHook/useTitle'
import { largestReal, smallestReal } from 'utils/NumberUtil'
import { useParams } from 'react-router'

const headers = ['id', 'name']

const ParameterApp = () => {
    const { code } = useParams()
    const {
        parameterProps,
        parameters,
        parameterGroupLinks,
        parameterGroups,
        referencialStatus,
        fractions,
        supports,
        units,
        cities,
        qualityThresholds,
    } = useSelector(store => ({
        parameterProps: store.ParameterReducer.parameter,
        parameters: store.ParameterReducer.parameters,
        parameterGroupLinks: store.ParameterReducer.parameterGroupLinks,
        parameterGroups: store.ParameterReducer.parameterGroups,
        referencialStatus: store.ReferencialReducer.referencialStatus,
        fractions: store.FractionReducer.fractions,
        supports: store.SupportReducer.supports,
        units: store.UnitReducer.units,
        cities: store.CityReducer.cities,
        qualityThresholds: store.QualityReducer.qualityThresholds,
    }), shallowEqual)

    const [parameter, setParameter] = useState(new ParameterDto({}))
    const [purgeIsRunning, setPurgeIsRunning] = useState(false)
    const [purgeProgress, setPurgeProgress] = useState(0)
    const [isEditMode, setIsEditMode] = useState(false)
    const [groupsTab, setGroupsTab] = useState(CLASS_GROUP)

    const dispatch = useDispatch()

    const onPurgeProgress = (event) => {
        const { percent } = getData(event)
        if (percent || percent === 0) {
            setPurgeProgress(percent)
        }
        if (percent === 100) {
            setPurgeIsRunning(false)
        }
    }

    useEffect(() => () => {
        dispatch(SSEActions.unsubscribe(REFERENCIAL_PARAMETER_PURGE_LOADING, onPurgeProgress))
        dispatch(ParameterAction.reset())
    }, [])

    useEffect(() => setParameter(parameterProps), [parameterProps])

    useEffect(() => {
        dispatch(HomeAction.setHelpLink('', ''))

        dispatch(ReferentialAction.fetchCheckPurgeReferencialIsRunning(PARAMETER)).then((json = {}) => setPurgeIsRunning(json.isRunning))

        dispatch(SSEActions.subscribe(REFERENCIAL_PARAMETER_PURGE_LOADING, onPurgeProgress))
        dispatch(SSEActions.subscribe(REFERENCIAL_PARAMETER_PURGED, onPurgeProgress))

        if (purgeIsRunning) {
            setIsEditMode(false)
        } else if (code !== 'new') {
            dispatch(ParameterAction.fetchParameter(code))
            dispatch(ParameterAction.fetchQualitometers(code))
            setIsEditMode(false)
        } else {
            setIsEditMode(true)
        }

        if (!parameterGroups.length) {
            dispatch(ParameterAction.fetchParameterGroups())
        }

        if (!parameterGroupLinks.length) {
            dispatch(ParameterAction.fetchParameterGroupLinks())
        }

        if (!fractions.length) {
            dispatch(FractionAction.fetchFractions())
        }

        if (!supports.length) {
            dispatch(SupportAction.fetchSupports())
        }

        if (!units.length) {
            dispatch(UnitAction.fetchUnits())
        }

        if (!cities.length) {
            dispatch(CityAction.fetchCities())
        }

        if (!referencialStatus.length) {
            dispatch(ReferencialAction.fetchReferencialStatus())
        }

        $('ul.tabs').tabs()
    }, [code])

    useEffect(() => {
        if (code !== 'new' && parameter.code && qualityThresholds.length) {
            const foundThreshold = qualityThresholds.find(qt => qt.thresholds.find(t => t.parameterCode === parameter.code))
            if (!foundThreshold) {
                dispatch(ToastrAction.warning(`${i18n.noParameterForThreshold} ${parameter.code}`))
            }
        }
    }, [code, parameter.code, qualityThresholds])

    const getActions = () => ({
        edit: () => {
            setIsEditMode(true)
        },
        deleteCheck: () => {
            dispatch(ReferencialAction.fetchCheckDelete(ApplicationConf.referencial.parameterCountDelete(code)))
            const id = uuidv4()
            const popup = {
                id,
                content: <DeleteModal
                    onDelete={ () => dispatch(ParameterAction.deleteParameter(parameter.code)) }
                />,
                dismissible: false,
            }
            dispatch(SieauAction.setPopup(popup))
        },
        replace: () => {
            dispatch(ReferencialAction.fetchCheckDelete(ApplicationConf.referencial.parameterCountDelete(code)))
            const id = uuidv4()
            const popup = {
                id,
                content: <ReplaceModal
                    elements={ parameters }
                    title={`${i18n.parameter} [${parameter.code}]`}
                    label={i18n.parameter}
                    onReplace={ newCode => dispatch(ParameterAction.replaceParameter(parameter.code, newCode.code)) }
                />,
                dismissible: false,
            }
            dispatch(SieauAction.setPopup(popup))
        },
    })

    const getParameterLinks = () => {
        return [
            !!parameterProps.code && {
                href: template(sandreParameterLink)({ parameter: parameterProps.code }),
                img: logoSandre,
                label: i18n.sandre.toUpperCase(),
            },
            !!parameterProps.casCode && {
                href: inerisParameterLink + parameterProps.casCode,
                img: ineris,
                label: i18n.ineris.toUpperCase(),
            },
            !!parameterProps.casCode && {
                href: pubChemParameterLink + parameterProps.casCode,
                img: pubChem,
                label: i18n.pubChem.toUpperCase(),
            },
        ].filter(l => l)
    }

    const mandatoryFileds = useMemo(() => [
        { field: 'code', i18n: 'sandreCode' },
        { field: 'name', i18n: 'name' },
    ], [])

    useActions(() => {
        const links = getParameterLinks()
        const isNewActions = code === 'new' && (!parameterProps || !parameterProps.code)
        const actions = (() => {
            if (!purgeIsRunning) {
                if (isNewActions) {
                    return {
                        save: () => {
                            checkMandatoryFields(mandatoryFileds, parameter, () => {
                                const existCode = parameters.find(p => p.code === parameter.code)
                                if (existCode) {
                                    dispatch(ToastrAction.error(i18n.codeAlreadyExists))
                                } else if (!parameter.code) {
                                    dispatch(ToastrAction.error(i18n.thanksToEnterCode))
                                } else {
                                    dispatch(ParameterAction.createParameter(parameter))
                                    setIsEditMode(false)
                                }
                            })
                        },
                        cancel: () => {
                            dispatch(push(PATH_REFERENCIAL_PARAMETER))
                            setIsEditMode(false)
                        },
                    }
                } else if (isEditMode) {
                    return {
                        save: () => {
                            dispatch(ParameterAction.updateParameter(parameter, parameter.code))
                            setIsEditMode(false)
                        },
                        cancel: () => {
                            setParameter(parameterProps)
                            setIsEditMode(false)
                        },
                    }
                }
                return {
                    ...getActions(),
                }
            }
            return {
                loading: [
                    {
                        label: i18n.purge.toUpperCase(),
                        value: purgeProgress,
                    },
                ],
            }
        })()
        return (!isNewActions && !isEditMode && links.length > 0) ? { ...actions, links } : actions
    }, [code, parameterProps, parameter, isEditMode])

    useTitle(() => {
        if (code === 'new') {
            return [
                {
                    title: i18n.referencials,
                    href: PATH_REFERENCIAL,
                },
                {
                    title: i18n.parameters,
                    href: PATH_REFERENCIAL_PARAMETER,
                },
                {
                    title: i18n.new,
                    href: PATH_REFERENCIAL_PARAMETER_ITEM_NEW,
                },
            ]
        }
        return [
            {
                title: i18n.referencials,
                href: PATH_REFERENCIAL,
            },
            {
                title: i18n.parameters,
                href: PATH_REFERENCIAL_PARAMETER,
            },
            {
                title: code + (parameterProps.name ? ` - ${parameterProps.name}` : ''),
                href: PATH_REFERENCIAL_PARAMETER_ITEM_LINK + code,
            },
        ]
    }, [code, parameterProps])

    const data = useMemo(() => {
        const groupLinks = parameterGroupLinks.find(param => param.parameterCode === parameter.code) || {}
        const groupsParameter = groupsTab === CLASS_GROUP ? parameterGroups.filter(group =>
            (groupLinks.classGroupCode && group.code === groupLinks.classGroupCode)
        ) : parameterGroups.filter(group =>
            (groupLinks.usageGroupCode && group.code === groupLinks.usageGroupCode) ||
            (groupLinks.usageGroupCode2 && group.code === groupLinks.usageGroupCode2) ||
            (groupLinks.usageGroupCode3 && group.code === groupLinks.usageGroupCode3) ||
            (groupLinks.usageGroupCode4 && group.code === groupLinks.usageGroupCode4) ||
            (groupLinks.usageGroupCode5 && group.code === groupLinks.usageGroupCode5)
        )
        return groupsParameter.map((value) => {
            return ({
                ...value,
                id: value.code,
            })
        })
    }, [groupsTab, parameter.code, parameterGroupLinks, parameterGroups])

    const onChangePanel = (newPanel) => {
        if (groupsTab !== newPanel) {
            setGroupsTab(newPanel)
        }
    }

    const disabled = useMemo(() => ({
        active: isEditMode,
        disabled: !isEditMode,
    }), [isEditMode])

    const natureOptions = useMemo(() => [
        { id: '0', name: i18n.chimical },
        { id: '1', name: i18n.microbiological },
        { id: '2', name: i18n.physical },
        { id: '3', name: i18n.hydrobiological },
        { id: '4', name: i18n.environmental },
        { id: '5', name: i18n.synthesis },
    ], [])

    const typeOptions = useMemo(() => [
        { id: '0', name: i18n.quantitative },
        { id: '1', name: i18n.qualitative },
    ], [])

    const activeIcon = useMemo(() => parameter.status === '3' ? <i className='material-icons'>lock</i> : null, [parameter.status])

    const referencialUnits = useMemo(() => getReferencialComponent(units), [units])
    const referencialFractions = useMemo(() => getReferencialComponent(fractions), [fractions])
    const referencialSupports = useMemo(() => getReferencialComponent(supports), [supports])
    const referencialNatures = useMemo(() => getReferencialComponent(natureOptions), [natureOptions])
    const referencialTypes = useMemo(() => getReferencialComponent(typeOptions), [typeOptions])

    return (
        <div className='row no-margin'>
            <div className='col s12'>
                <div id='file'>
                    <div className='card margin-bottom-1'>
                        <div className='card-content'>
                            <div className='row no-margin'>
                                <div className='col s12 m1 l1' />
                                <div className='col s12 m10 l10'>
                                    <div className='row no-margin padding-top-3-px'>
                                        <Input
                                            col={ 3 }
                                            title={ i18n.sandreCode }
                                            value={ parameter.code }
                                            onChange={ newCode => setParameter({ ...parameter, code: newCode }) }
                                            obligatory
                                            maxLength={ 5 }
                                            disabled={ code !== 'new' }
                                        />
                                        <Input
                                            col={ 3 }
                                            title={ i18n.shortCaption }
                                            value={ parameter.shortLabel }
                                            onChange={ shortLabel => setParameter({ ...parameter, shortLabel }) }
                                            maxLength={ 10 }
                                            { ...disabled }
                                        />
                                        <div className='col s2 no-padding'>
                                            <Select
                                                options={ referencialStatus }
                                                label={ i18n.status }
                                                col={ 10 }
                                                onChange={ status => setParameter({ ...parameter, status: status?.toString() }) }
                                                value={ parseInt(parameter.status) }
                                                nullLabel='&nbsp;'
                                                { ...disabled }
                                            />
                                            <div className='col s2 padding-top-10-px'>
                                                { activeIcon }
                                            </div>
                                        </div>
                                        <div className='center padding-top-10-px'>
                                            <Checkbox
                                                col={ 1 }
                                                label={ i18n.active }
                                                checked={ parameter.active === '1' }
                                                onChange={ e => setParameter({ ...parameter, active: e ? '1' : '0' }) }
                                                { ...disabled }
                                            />
                                        </div>
                                        <div className='center'>
                                            <Checkbox
                                                col={ 3 }
                                                label={ i18n.bankinkParameter }
                                                checked={ parameter.banked === '1' }
                                                onChange={ e => setParameter({ ...parameter, banked: e ? '1' : '0' }) }
                                                { ...disabled }
                                            />
                                        </div>
                                    </div>
                                    <div className='row no-margin padding-top-3-px'>
                                        <Input
                                            col={ 6 } title={ i18n.name }
                                            value={ parameter.name }
                                            id='parameter_name'
                                            onChange={ name => setParameter({ ...parameter, name }) }
                                            obligatory
                                            maxLength={ 255 }
                                            { ...disabled }
                                        />
                                        <SimpleDatePicker
                                            onChange={ creationDate => setParameter({ ...parameter, creationDate }) }
                                            id='creationDate'
                                            label={ i18n.creationDate }
                                            value={ parameter.creationDate }
                                            col={ 3 }
                                            { ...disabled }
                                            endDate={ parameter.updateDate }
                                        />
                                        <SimpleDatePicker
                                            onChange={ updateDate => setParameter({ ...parameter, updateDate }) }
                                            id='updateDate'
                                            label={ i18n.modificationDate }
                                            value={ parameter.updateDate }
                                            col={ 3 }
                                            { ...disabled }
                                            startDate={ parameter.creationDate }
                                        />
                                    </div>
                                    <div className='row no-margin'>
                                        <div className='col s6 no-padding'>
                                            <div className='row no-margin padding-top-3-px'>
                                                <Input
                                                    col={ 12 }
                                                    title={ i18n.extendedCaption }
                                                    value={ parameter.longLabel }
                                                    onChange={ longLabel => setParameter({ ...parameter, longLabel }) }
                                                    maxLength={25}
                                                    { ...disabled }
                                                />
                                            </div>
                                            <div className='row no-margin padding-top-3-px'>
                                                <Select
                                                    options={ referencialTypes }
                                                    label={ i18n.type }
                                                    col={ 6 }
                                                    onChange={ nature => setParameter({ ...parameter, nature }) }
                                                    value={ parameter.nature }
                                                    nullLabel='&nbsp;'
                                                    { ...disabled }
                                                />
                                                <Select
                                                    options={ referencialNatures }
                                                    label={ i18n.nature }
                                                    col={ 6 }
                                                    onChange={ typeParam => setParameter({ ...parameter, typeParam }) }
                                                    value={ parameter.typeParam }
                                                    nullLabel='&nbsp;'
                                                    { ...disabled }
                                                />
                                            </div>
                                            <div className='row no-margin padding-top-3-px'>
                                                <Textarea
                                                    col={ 12 }
                                                    title={ i18n.comment }
                                                    value={ parameter.comment }
                                                    onChange={ comment => setParameter({ ...parameter, comment }) }
                                                    { ...disabled }
                                                />
                                            </div>
                                            <div className='row no-margin padding-top-3-px'>
                                                <Textarea
                                                    col={12}
                                                    title={i18n.commentVulgarized}
                                                    value={parameter.commentVulgarized}
                                                    onChange={commentVulgarized => setParameter({ ...parameter, commentVulgarized })}
                                                    {...disabled}
                                                />
                                            </div>
                                            <div className='row no-margin padding-top-7-px'>
                                                <Textarea
                                                    col={ 12 }
                                                    title={ i18n.definition }
                                                    value={ parameter.definition }
                                                    onChange={ definition => setParameter({ ...parameter, definition }) }
                                                    { ...disabled }
                                                />
                                            </div>
                                            <div className='row no-margin padding-top-7-px'>
                                                <Input
                                                    col={ 12 }
                                                    title={ i18n.author }
                                                    value={ parameter.author }
                                                    onChange={ author => setParameter({ ...parameter, author }) }
                                                    maxLength={ 50 }
                                                    { ...disabled }
                                                />
                                            </div>
                                            <div className='row no-margin padding-top-5-px'>
                                                <Textarea
                                                    col={ 12 }
                                                    title={ i18n.reference }
                                                    value={ parameter.reference }
                                                    onChange={ reference => setParameter({ ...parameter, reference }) }
                                                    { ...disabled }
                                                />
                                            </div>
                                        </div>
                                        <div className='col s6 no-padding padding-top-3-px'>
                                            <Input
                                                col={ 12 }
                                                title={ i18n.internationalCaption }
                                                value={ parameter.internationalName }
                                                onChange={ internationalName => setParameter({ ...parameter, internationalName }) }
                                                maxLength={ 255 }
                                                { ...disabled }
                                            />
                                        </div>
                                        <div className='col s6 no-padding padding-top-3-px'>
                                            <Select
                                                col={ 12 }
                                                minLength={ 1 }
                                                label={ `${i18n.referenceUnit} 1` }
                                                onChange={ unitReference1 => setParameter({ ...parameter, unitReference1 }) }
                                                options={ referencialUnits }
                                                limit={ 10 }
                                                maxLength={ 5 }
                                                value={ parameter.unitReference1 }
                                                { ...disabled }
                                                clearFunction
                                            />
                                            <Select
                                                col={ 12 }
                                                minLength={ 1 }
                                                label={ `${i18n.referenceUnit} 2` }
                                                onChange={ unitReference2 => setParameter({ ...parameter, unitReference2 }) }
                                                options={ referencialUnits }
                                                limit={ 10 }
                                                value={ parameter.unitReference2 }
                                                maxLength={ 5 }
                                                { ...disabled }
                                                clearFunction
                                            />
                                            <Select
                                                col={ 12 }
                                                minLength={ 1 }
                                                label={ `${i18n.referenceUnit} 3` }
                                                onChange={ unitReference3 => setParameter({ ...parameter, unitReference3 }) }
                                                options={ referencialUnits }
                                                limit={ 10 }
                                                value={ parameter.unitReference3 }
                                                maxLength={ 5 }
                                                { ...disabled }
                                                clearFunction
                                            />
                                            <Select
                                                col={ 12 }
                                                minLength={ 1 }
                                                label={ `${i18n.referenceUnit} 4` }
                                                onChange={ unitReference4 => setParameter({ ...parameter, unitReference4 }) }
                                                options={ referencialUnits }
                                                limit={ 10 }
                                                value={ parameter.unitReference4 }
                                                maxLength={ 5 }
                                                { ...disabled }
                                                clearFunction
                                            />
                                            <Select
                                                col={ 12 }
                                                minLength={ 1 }
                                                label={ `${i18n.referenceUnit} 5` }
                                                onChange={ unitReference5 => setParameter({ ...parameter, unitReference5 }) }
                                                options={ referencialUnits }
                                                limit={ 10 }
                                                value={ parameter.unitReference5 }
                                                maxLength={ 5 }
                                                { ...disabled }
                                                clearFunction
                                            />
                                            <Select
                                                col={ 12 }
                                                minLength={ 1 }
                                                label={ `${i18n.referenceUnit} 6` }
                                                onChange={ unitReference6 => setParameter({ ...parameter, unitReference6 }) }
                                                options={ referencialUnits }
                                                maxLength={ 5 }
                                                limit={ 10 }
                                                value={ parameter.unitReference6 }
                                                { ...disabled }
                                                clearFunction
                                            />
                                            <Row>
                                                <NumberField
                                                    col={ 6 }
                                                    value={ parameter.ld }
                                                    title={ i18n.ld }
                                                    onChange={ ld => setParameter({ ...parameter, ld }) }
                                                    floatValue
                                                    min={smallestReal}
                                                    max={largestReal}
                                                    { ...disabled }
                                                />
                                                <NumberField
                                                    col={ 6 }
                                                    value={ parameter.lq }
                                                    title={ i18n.lq }
                                                    onChange={ lq => setParameter({ ...parameter, lq }) }
                                                    floatValue
                                                    min={smallestReal}
                                                    max={largestReal}
                                                    { ...disabled }
                                                />
                                            </Row>
                                        </div>
                                    </div>
                                    <div className='row no-margin padding-top-7-px'>
                                        <Input
                                            col={ 4 }
                                            title={ i18n.substance }
                                            value={ parameter.chemicalSubstance }
                                            onChange={ chemicalSubstance => setParameter({ ...parameter, chemicalSubstance }) }
                                            maxLength={ 255 }
                                            { ...disabled }
                                        />
                                        <Select
                                            options={ referencialSupports }
                                            label={ i18n.support }
                                            col={ 4 }
                                            onChange={ defaultSupportCode => setParameter({ ...parameter, defaultSupportCode }) }
                                            value={ parseInt(parameter.defaultSupportCode) }
                                            nullLabel='&nbsp;'
                                            maxLength={ 5 }
                                            { ...disabled }
                                            clearFunction
                                        />
                                        <Select
                                            options={ referencialFractions }
                                            label={ i18n.fraction }
                                            col={ 4 }
                                            onChange={ fractionCode => setParameter({ ...parameter, fractionCode: fractionCode?.toString() }) }
                                            value={ parseInt(parameter.fractionCode) }
                                            maxLength={ 5 }
                                            nullLabel='&nbsp;'
                                            { ...disabled }
                                            clearFunction
                                        />
                                    </div>
                                    <div className='row no-margin'>
                                        <div className='padding-top-3-px'>
                                            <Checkbox
                                                col={ 12 }
                                                label={ i18n.calculatedParameter }
                                                checked={ parameter.calculate === '1' }
                                                onChange={ (e) => setParameter({ ...parameter, calculate: e ? '1' : '0' }) }
                                                { ...disabled }
                                            />
                                        </div>
                                    </div>
                                    <div className='row no-margin padding-top-10-px'>
                                        <Textarea
                                            col={ 12 }
                                            title={ i18n.calculatedParameters }
                                            value={ parameter.computedParameterCode }
                                            onChange={ computedParameterCode => setParameter({ ...parameter, computedParameterCode }) }
                                            { ...disabled }
                                        />
                                    </div>
                                    <div className='row no-margin padding-top-7-px'>
                                        <Input
                                            col={ 4 }
                                            title={ i18n.casCode }
                                            value={ parameter.casCode }
                                            onChange={ newCasCode => setParameter({ ...parameter, casCode: newCasCode }) }
                                            maxLength={ 20 }
                                            { ...disabled }
                                        />
                                        <Input
                                            col={ 8 }
                                            title={ i18n.SISEeaucode }
                                            value={ parameter.siseCode }
                                            onChange={ siseCode => setParameter({ ...parameter, siseCode }) }
                                            maxLength={ 255 }
                                            { ...disabled }
                                        />
                                    </div>
                                    <div className='row no-margin padding-top-3-px'>
                                        <Textarea
                                            col={ 12 }
                                            title={ i18n.internalCode }
                                            value={ parameter.internalCode }
                                            onChange={ internalCode => setParameter({ ...parameter, internalCode }) }
                                            maxLength={ 255 }
                                            { ...disabled }
                                        />
                                    </div>
                                    <div className='row no-margin padding-top-5-px'>
                                        <div className='col-s12 margin-left-1 margin-right-1' style={{ padding: 5, backgroundColor: '#161832' }}>
                                            <ul className='tabs tabs-fixed-width margin-top-0'>
                                                <li className='tab'>
                                                    <a onClick={ () => onChangePanel(CLASS_GROUP) }>{ i18n.classGroup }</a>
                                                </li>
                                                <li className='tab'>
                                                    <a onClick={ () => onChangePanel(USAGE_GROUP) }>{ i18n.usageGroup }</a>
                                                </li>
                                            </ul>
                                            <Table
                                                title={i18n.parameterGroup}
                                                data={data}
                                                type={{ headers }}
                                                padding
                                                sortable
                                                initialSort={{ column: 'name', sort: 'asc' }}
                                                link='referencial/parameterGroup'
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ParameterApp
