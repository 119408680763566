/* eslint-disable camelcase */
import { Button, Divider, Grid } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { styled } from '@mui/styles'
import Checkbox from 'components/forms/Checkbox'
import Select from 'components/forms/Select'
import SimpleTextArea from 'components/forms/SimpleTextArea'
import { AccordionDetailsMUI, AccordionMUI, AccordionSummaryMUI } from 'components/styled/Accordions'
import DtoCMSSurvey from 'events/dto/DtoCMSSurvey'
import DtoCMSQuestion from 'events/dto/survey/DtoCMSQuestion'
import { DANGER_ELEMENT_TYPE } from 'installation/constants/InstallationConstants'
import { orderBy } from 'lodash'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import Textarea from '../../../../components/forms/Textarea'
import DtoSandreCode from '../../../../referencial/dto/DtoSandreCode'
import { arrayOf, getSandreList } from '../../../../utils/StoreUtils'
import VisitPicture from '../components/VisitPicture'
import DtoAnswer from '../dto/DtoAnswer'
import DtoAnswerDocument from '../dto/DtoAnswerDocument'
import DtoDanger from '../dto/DtoDanger'
import DtoVisit from '../dto/DtoVisit'
import AddPicture from '../components/AddPicture'

const CheckButton = styled(({ ...other }) => <Button variant='outlined' {...other} />)({
    borderRadius: props => props.radius === 1 ? '5px 0 0 5px' : props.radius === 3 ? '0 5px 5px 0' : 0,
    border: props => props.border === 'yes' ? 'solid 1px #79da4c' : props.border === 'no' ? 'solid 1px #f32525' : 'solid 1px grey',
})

const Question = ({
    question,
    visit,
    onChange,
    readMode,
    sandreCodes,
}) => {
    const answer = visit.link_answers.find(la => la.idQuestion === question.idQuestion) || {}
    const answerWithIndexs = answer.link_documents && {
        ...answer,
        link_documents: answer.link_documents.map((d, index) => ({ ...d, index })),
    } || {}

    const isDanger = (newAnswer) => newAnswer.answerMultiChoice === 'no' || newAnswer.answerBool

    const updateQuestionAndAddDanger = (newAnswer, deleteDanger = false) => {
        const newAnswers = [
            ...visit.link_answers.filter(la => !(la.idQuestion === question.idQuestion && question.idParagraph === la.idParagraph)),
            newAnswer,
        ]
        const newDangers = [
            ...visit.link_dangers.filter(d => !(d.idLinkedQuestion === question.idQuestion && d.idLinkedParagraph === question.idParagraph)),
            !deleteDanger && new DtoDanger({
                idSurvey: visit.idSurvey,
                siteCode: visit.idInstallation,
                siteType: DANGER_ELEMENT_TYPE.INSTALLATION,
                idVisit: visit.idVisit,
                idCampaign: visit.idCampaign,
                idLinkedQuestion: question.idQuestion,
                idLinkedParagraph: question.idParagraph,
            }) || null,
        ].filter(d => !!d)

        onChange({
            link_answers: newAnswers,
            link_dangers: newDangers,
        })
    }

    const updateQuestion = (newAnswer) => {
        const newAnswers = [
            ...visit.link_answers.filter(la => !(la.idQuestion === question.idQuestion && question.idParagraph === la.idParagraph)),
            newAnswer,
        ]
        onChange({ link_answers: newAnswers })
    }

    const onChangeQuestion = (value) => {
        const newObjectAnswer = new DtoAnswer({
            recipientCode: visit.idInstallation,
            idCampaign: visit.idCampaign,
            recipientType: DANGER_ELEMENT_TYPE.INSTALLATION,
            idSurvey: visit.idSurvey,
            idVisit: visit.idVisit,
            idQuestion: question.idQuestion,
            idParagraph: question.idParagraph,
            ...answerWithIndexs,
            ...value,
        })
        const newAnswer = (newObjectAnswer.answerBool
            || newObjectAnswer.answerDate
            || newObjectAnswer.answerLong
            || newObjectAnswer.answerMultiChoice
            || newObjectAnswer.answerText
            || newObjectAnswer?.link_documents.length) ? newObjectAnswer : {}
        const isDangerOld = isDanger(answerWithIndexs)
        const isDangerNew = isDanger(newAnswer)

        if ((!isDangerOld && isDangerNew) || (isDangerOld && isDangerNew)) {
            updateQuestionAndAddDanger(newAnswer)
        } else if (isDangerOld && !isDangerNew) {
            updateQuestionAndAddDanger(newAnswer, true)
        } else {
            updateQuestion(newAnswer)
        }
    }

    const addNewDocument = (document) => {
        const newLinkDocuments = [
            ...(answerWithIndexs.link_documents || []),
            document,
        ]
        onChangeQuestion({ link_documents: newLinkDocuments })
    }

    const onChangeDocument = (document, value) => {
        const newLinkDocuments = answerWithIndexs.link_documents.map(d => {
            if (d.index === document.index) {
                return new DtoAnswerDocument({ ...document, ...value })
            }
            return d
        })
        onChangeQuestion({ link_documents: newLinkDocuments })
    }

    const onDeleteDocument = (document) => {
        onChangeQuestion({ link_documents: [...answerWithIndexs.link_documents.filter(d => d.index !== document.index)] })
    }

    const displayPictures = answerWithIndexs.link_documents && answerWithIndexs.link_documents.map(doc => {
        return (
            <VisitPicture
                doc={doc}
                onDeleteDocument={() => onDeleteDocument(doc)}
                onChangeDocument={value => onChangeDocument(doc, { description: value })}
                readMode={readMode}
                col={4}
            />
        )
    })

    const amc = answerWithIndexs.answerMultiChoice // answerWithIndexs.answerMultiChoice

    return (
        <Grid container direction='row' alignItems='flex-start'>
            <Grid container item xs={12} md={8} style={{ padding: '10px' }}>
                <Grid item xs={7} style={{ padding: '0 15px' }}>
                    <h5>{question.description}</h5>
                </Grid>
                <Grid container item xs={5} justifyContent='flex-end' alignItems='center'>
                    <Grid item>
                        <CheckButton
                            radius={1}
                            border={amc}
                            style={{
                                backgroundColor: amc === 'yes' ? '#79da4c' : 'white',
                                color: amc === 'yes' ? 'white' : 'black',
                            }}
                            onClick={() => onChangeQuestion({ answerMultiChoice: amc === 'yes' ? undefined : 'yes' })}
                            disabled={readMode}
                        >
                            {i18n.yes}
                        </CheckButton>
                        <CheckButton
                            radius={2}
                            border={amc}
                            style={{
                                backgroundColor: amc === 'no' ? '#f32525' : 'white',
                                color: amc === 'no' ? 'white' : 'black',
                            }}
                            onClick={() => onChangeQuestion({ answerMultiChoice: amc === 'no' ? undefined : 'no' })}
                            disabled={readMode}
                        >
                            {i18n.no}
                        </CheckButton>
                        <CheckButton
                            radius={3}
                            border={amc}
                            style={{
                                backgroundColor: amc === 'withoutObject' ? 'grey' : 'white',
                                color: amc === 'withoutObject' ? 'white' : 'black',
                            }}
                            onClick={() => onChangeQuestion({ answerMultiChoice: amc === 'withoutObject' ? undefined : 'withoutObject' })}
                            disabled={readMode}
                        >
                            {i18n.withoutObject}
                        </CheckButton>
                    </Grid>
                </Grid>
                <Grid item xs={6}>
                    {question.sandreField && (
                        <Select
                            obligatory
                            options={getSandreList(sandreCodes, question.sandreField)}
                            keyValue='code'
                            keyLabel='name'
                            label={i18n.state}
                            value={answerWithIndexs.answerLong}
                            onChange={v => onChangeQuestion({ answerLong: v })}
                            disabled={readMode}
                        />
                    )}
                </Grid>
                <Grid item container xs={6} justifyContent='flex-end' alignItems='center'>
                    <Grid item>
                        <Checkbox
                            col={12}
                            label={i18n.isStateProblem}
                            checked={answerWithIndexs.answerBool}
                            onChange={v => onChangeQuestion({ answerBool: v })}
                            disabled={readMode}
                        />
                    </Grid>
                </Grid>
                { question.inputComment && (
                    <SimpleTextArea
                        col={12}
                        title={i18n.comment}
                        value={answerWithIndexs.answerText}
                        {...readMode}
                        onChange={v => onChangeQuestion({ answerText: v })}
                        disabled={readMode}
                        readMode={readMode}
                    />
                )}
            </Grid>
            <Grid item container xs={12} md={4} justifyContent='space-evenly' style={{ padding: '10px 0' }}>
                <Grid container spacing={2}>
                    { displayPictures }
                    {!readMode && (
                        <AddPicture
                            onUploadFile={newFileName => {
                                addNewDocument({
                                    idSurvey: visit.idSurvey,
                                    idInstallation: visit.idInstallation,
                                    idLinkedQuestion: question.idQuestion,
                                    docName: newFileName,
                                    idVisit: visit.idVisit,
                                    idCampaign: visit.idCampaign,
                                })
                            }}
                        />
                    )}
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Divider />
            </Grid>
        </Grid>
    )
}

Question.propTypes = {
    question: PropTypes.instanceOf(DtoCMSQuestion),
    visit: PropTypes.instanceOf(DtoVisit),
    onChange: PropTypes.func,
    uploadFile: PropTypes.func,
    readMode: PropTypes.bool,
    sandreCodes: arrayOf(DtoSandreCode),
}

const useStyles = makeStyles(() => ({
    root: {
        minHeight: 35,
        maxHeight: 35,
        '&.Mui-expanded': {
            minHeight: 45,
            maxHeight: 45,
        },
    },
}))

const InstVisitDiagnostic = ({
    readMode = true,
    cmsSurvey = {},
    sandreCodes = [],
    visit = {},
    onChange = () => {},
}) => {
    const classes = useStyles()

    const [expanded, setExpanded] = useState('panel0')

    const getColorFromAnswers = (paragraph, answers) => {
        if (paragraph.link_questions.length > 0 && paragraph.link_questions.length === answers.length) {
            return 'green'
        }
        return 'grey'
    }

    return (
        <Grid container>
            {
                cmsSurvey.link_paragraphs.map((paragraph, index) => {
                    const answers = visit.link_answers.filter(la => la.idParagraph === paragraph.idParagraph)
                    const answersWithoutParagraph = answers.filter(a => !!a.idQuestion)
                    const paragraphAnswer = answers.find(a => !a.idQuestion)
                    const bckgroundColor = getColorFromAnswers(paragraph, answersWithoutParagraph)
                    return (
                        <Grid item className='margin-left-1 margin-right-1' xs={12} key={`paragraph_${index}`}>
                            <AccordionMUI
                                expanded={expanded === `panel${index}`}
                                onChange={(_, isExpanded) => setExpanded(isExpanded ? `panel${index}` : false)}
                                sx={{ border: `solid 1px ${bckgroundColor}`, marginBottom: '5px', marginTop: '5px' }}
                            >
                                <AccordionSummaryMUI
                                    sx={{ color: 'white', fontSize: '16px', background: bckgroundColor }}
                                    classes={classes}
                                >
                                    {`${paragraph.description} (${paragraph.link_questions.length} ${i18n.questions} - ${answersWithoutParagraph.length} ${i18n.answers})`}
                                </AccordionSummaryMUI>
                                <AccordionDetailsMUI>
                                    <>
                                        {
                                            orderBy(paragraph.link_questions, 'order').map(q => (
                                                <Question
                                                    question={q}
                                                    visit={visit}
                                                    onChange={onChange}
                                                    readMode={readMode}
                                                    sandreCodes={sandreCodes}
                                                />
                                            ))
                                        }
                                        <Grid item xs={12} style={{ padding: '10px 20px' }}>
                                            <Textarea
                                                title={i18n.generalComment}
                                                value={paragraphAnswer?.answerText}
                                                rows={2}
                                                onChange={v => {
                                                    onChange({
                                                        link_answers: [
                                                            ...visit.link_answers.filter(la => !(la.idParagraph === paragraph.idParagraph && !la.idQuestion)),
                                                            new DtoAnswer({
                                                                recipientCode: visit.idInstallation,
                                                                idCampaign: visit.idCampaign,
                                                                recipientType: DANGER_ELEMENT_TYPE.INSTALLATION,
                                                                idSurvey: visit.idSurvey,
                                                                idVisit: visit.idVisit,
                                                                ...paragraph,
                                                                answerText: v,
                                                            }),
                                                        ],
                                                    })
                                                }}
                                                disabled={readMode}
                                            />
                                        </Grid>
                                    </>
                                </AccordionDetailsMUI>
                            </AccordionMUI>
                        </Grid>
                    )
                })
            }
        </Grid>
    )
}

InstVisitDiagnostic.propTypes = {
    visit: PropTypes.instanceOf(DtoVisit),
    onChange: PropTypes.func,
    readMode: PropTypes.bool,
    sandreCodes: arrayOf(DtoSandreCode),
    cmsSurvey: PropTypes.instanceOf(DtoCMSSurvey),
}

const mapStateToProps = store => ({
    sandreCodes: store.ReferencialReducer.sandreCodes,
    cmsSurvey: store.EventsReducer.cmsSurvey,
})


export default connect(mapStateToProps)(InstVisitDiagnostic)
