import i18n from 'simple-react-i18n'


const getMapSituationStats = () => [
    { stat: 'ENVELOPPE_MIN', name: i18n.enveloppeMin },
    { stat: 'PERIOD_100_S', name: i18n.fre_100_S },
    { stat: 'PERIOD_80_S', name: i18n.fre_80_S },
    { stat: 'PERIOD_50_S', name: i18n.fre_50_S },
    { stat: 'PERIOD_20_S', name: i18n.fre_20_S },
    { stat: 'PERIOD_10_S', name: i18n.fre_10_S },
    { stat: 'PERIOD_5_S', name: i18n.fre_5_S },
    { stat: 'PERIOD_3_S', name: i18n.fre_3_S },
    { stat: 'ENVELOPPE_AVERAGE', name: i18n.enveloppeAvg },
    { stat: 'PERIOD_3_H', name: i18n.fre_3_H },
    { stat: 'PERIOD_5_H', name: i18n.fre_5_H },
    { stat: 'PERIOD_10_H', name: i18n.fre_10_H },
    { stat: 'PERIOD_20_H', name: i18n.fre_20_H },
    { stat: 'PERIOD_50_H', name: i18n.fre_50_H },
    { stat: 'PERIOD_80_H', name: i18n.fre_80_H },
    { stat: 'PERIOD_100_H', name: i18n.fre_100_H },
    { stat: 'ENVELOPPE_MAX', name: i18n.enveloppeMax },
]

export { getMapSituationStats }

