import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import React, { useEffect } from 'react'
import i18n from 'simple-react-i18n'
import Row from '../../../../../components/react/Row'
import Input from '../../../../../components/forms/Input'
import Select from '../../../../../components/forms/Select'
import { hasBooleanValue, hasValue } from '../../../../../utils/NumberUtil'
import Checkbox from '../../../../../components/forms/Checkbox'
import NetworkAction from '../../../../../referencial/components/network/actions/NetworkAction'
import StationImportCodeMessage from '../../../../../import/components/utils/StationImportCodeMessage'
import { integrationModeHelpIcon } from '../../../../../import/utils/ImportUtils'
import Icon from '../../../../../components/icon/Icon'
import { StyledFieldSet, StyledLegend, StyledSpan } from '../../../../../components/StyledElements'

const measureOptions = () => [
    {
        value: 'DATA_NOTHING',
        label: i18n.noDataIntegration,
    },
    {
        value: 'DATA_REPLACE_HISTO',
        label: 'Remplacer tout l\'historique',
    },
    {
        value: 'DATA_ADD_NEW_DATA',
        label: 'Ajouter uniquement les nouvelles données',
    },
]

const handleChangeDataIntegration = (integration, jobDataTypes, setJobDataTypes) => {
    const dataTypes = jobDataTypes.filter(d => !d.includes('DATA'))
    if (integration) {
        dataTypes.push(integration)
    }
    setJobDataTypes(dataTypes)
}

// const handleChangeStationIntegration = (integration, jobDataTypes, setJobDataTypes) => {
//     const dataTypes = jobDataTypes.filter(d => !d.includes('station'))
//     if (integration) {
//         dataTypes.push('stationIntegration')
//     }
//     setJobDataTypes(dataTypes)
// }

const getDataTypes = (type, jobDataTypes) => {
    if (jobDataTypes && jobDataTypes.length) {
        return jobDataTypes.find(d => d.includes(type)) || ''
    }
    return ''
}


const AddPiezoStepOptionsAdes = ({
    station,
    changeStation,
    filters,
    changeFilters,
    jobDataTypes,
    setJobDataTypes,
}) => {
    const dispatch = useDispatch()

    const {
        networks,
    } = useSelector(store => ({
        networks: store.NetworkReducer.networks,
    }), shallowEqual)

    // const [isOpen, setIsOpen] = useState(false)

    useEffect(() => {
        if (!networks.length) {
            dispatch(NetworkAction.fetchNetworks())
        }
    }, [])

    const descriptionImportModes = [
        { code: 'all', name: i18n.waterPointAndHisto },
        { code: 'PointEau', name: i18n.pointeauOnly },
    ]
    const oldCodeWithoutDesignation = hasValue(station.code) && station.code.length === 10 && !station.code.startsWith('BSS') && station.code[5] === 'X'
    const isNotBss = hasValue(station.code) && station.code.length !== 10
    return (
        <StyledFieldSet>
            <StyledLegend>
                <StyledSpan>{i18n.options}</StyledSpan>
            </StyledLegend>
            <div>
                <Row className='padding-top-2 valign-wrapper'>
                    <Input col={3} value={station.code} onChange={v => changeStation({ code: v })} title={i18n.code} noSpace/>
                    <div className='col s9'>
                        { (oldCodeWithoutDesignation || isNotBss) ? (
                            <Row>
                                <div className='col s1'>
                                    <Icon icon='warning' style={{ color: 'orange' }}/>
                                </div>
                                <div className='col s11'>
                                    <h6>{ oldCodeWithoutDesignation ? i18n.oldCodeWithoutDesignationMessage : i18n.isNotBssCode }</h6>
                                </div>
                            </Row>
                        ) : (hasValue(station.code) && <StationImportCodeMessage code={station.code} stationType='piezometry'/>) }
                    </div>
                </Row>
                <Row className='padding-top-2 valign-wrapper'>
                    <Select col={ 4 } label={ i18n.importMode } options={ descriptionImportModes } value={ filters.descriptionImportMode } onChange={ v => changeFilters({ descriptionImportMode: v }) } />
                    <Checkbox
                        col={4}
                        checked={ hasBooleanValue(filters.syncEvents) ? filters.syncEvents : true }
                        label={ i18n.syncEvents }
                        onChange={ v => changeFilters({ syncEvents: v }) }
                    />
                    <Checkbox
                        col={4}
                        checked={ hasBooleanValue(filters.syncNetworks) ? filters.syncNetworks : true }
                        label={ i18n.syncNetworks }
                        onChange={ v => changeFilters({ syncNetworks: v }) }
                    />
                </Row>
                <Row className='padding-top-2  valign-wrapper'>
                    <Select col={ 4 } options={ measureOptions() } obligatory={true} label={ i18n.dataIntegration } value={ getDataTypes('DATA', jobDataTypes) } labelSpan={ integrationModeHelpIcon() }
                        onChange={ v => handleChangeDataIntegration(v, jobDataTypes, setJobDataTypes) }
                    />
                    <Checkbox
                        col={8}
                        checked={ filters.dontCheckInternalReferences }
                        label={ i18n.dontCheckInternalReferences }
                        onChange={ v => changeFilters({ dontCheckInternalReferences: v }) }
                    />
                </Row>
                <Row className='padding-top-2 valign-wrapper'>
                    <Checkbox col={ 3 } checked={ filters.addCodesToADESNetwork } label={ i18n.addCodesToADESNetwork } onChange={ v => changeFilters({ addCodesToADESNetwork: v }) } />
                    <Select options={ networks } keyLabel='mnemonic' col={ 5 } label={ i18n.network } nullLabel='&nbsp;' onChange={ value => changeFilters({ selectedNetwork: value }) } value={ filters.selectedNetwork } keyValue='id'/>
                    <div className='col s4'/>
                </Row>
            </div>
        </StyledFieldSet>
    )
}

AddPiezoStepOptionsAdes.propTypes = {
}

export default AddPiezoStepOptionsAdes