/* eslint-disable camelcase */
export default class DtoInstallationTank {
    constructor(obj) {
        this.idInstallation = obj.idInstallation // Long,
        this.capacity = obj.capacity // Option[Double],
        this.recoveryFlow = obj.recoveryFlow // Option[Double],
        this.fillingMode = obj.fillingMode // Option[Long],
        this.comment = obj.comment // Option[String],
        this.id = obj.id // Option[String],
        this.sharedRetention = obj.sharedRetention // Option[Boolean],
        this.usedPercentage = obj.usedPercentage // Option[Double],
        this.idTank = obj.idTank // Option[Long],
        this.fillingType = obj.fillingType // Option[Long],
        this.fillingPeriod = obj.fillingPeriod // Option[Long]
        this.reserveFlow = obj.reserveFlow // Option[Double],
        this.tankType = obj.tankType // Option[Long]
        this.area = obj.area // Option[Double]

        this.link_periodes = obj.link_periodes || [] // Seq[DtoTankPeriode]
    }
}