export default class DtoJobLogLight {
    constructor(obj = {}) {
        this.status = obj[0]
        this.date = obj[1]
        this.fileName = obj[2]
        this.value = obj[3]
        this.station = obj[4]
        this.jobExecutionId = obj.jobExecutionId
        this.jobId = obj.jobId
    }
}
