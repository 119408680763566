import React, { useEffect, useMemo, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { push } from '@lagunovsky/redux-react-router'
import i18n from 'simple-react-i18n'
import ManagementUnitAction from '../actions/ManagementUnitAction'
import { getDate } from '../../../../utils/DateUtil'
import { hasValue } from 'utils/NumberUtil'
import { orderBy } from 'lodash'
import useTitle from 'utils/customHook/useTitle'
import useActions from 'utils/customHook/useActions'
import SearchTable from 'referencial/components/SearchTable'
import { getDataWithHeaders } from 'utils/ExportDataUtil'
import { REFERENCIAL_TYPE_NAME } from 'referencial/constants/ReferencialConstants'

const headers = ['id', 'parent', 'name', 'lowWaterStartDate', 'lowWaterEndDate']

const ManagementUnitsApp = () => {
    const {
        managementUnits,
    } = useSelector(store => ({
        managementUnits: store.ManagementUnitReducer.managementUnits,
    }), shallowEqual)

    const [exportData, setExportData] = useState([])

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(ManagementUnitAction.fetchManagementUnits())
    }, [])

    useTitle(() => [{
        title: i18n.referencials,
        href: '/referencial',
    }, {
        title: i18n.managementUnits,
        href: '/referencial/managementUnit',
    }], [])

    useActions(() => {
        return {
            referencialActions: {
                actions: {
                    export: {
                        data: getDataWithHeaders(exportData, headers),
                        titleFile: i18n.managementUnits,
                    },
                },
            },
            new: () => {
                dispatch(push('/referencial/managementUnit/new'))
            },
        }
    }, [exportData])

    const formattedUnits = useMemo(() => orderBy(managementUnits.map((mu) => ({
        ...mu,
        id: mu.id,
        parent: hasValue(mu.parent) ? (managementUnits.find((m) => m.managementCode === mu.parent) || {}).id : '',
        lowWaterStartDate: getDate(mu.lowWaterStartDate, 'DD/MM'),
        lowWaterEndDate: getDate(mu.lowWaterEndDate, 'DD/MM'),
        hiddenCode: mu.managementCode,
        idForOrder: mu.parent || mu.managementCode,
    })), ['idForOrder', 'parent', 'id']), [managementUnits])

    return (
        <SearchTable
            title={i18n.listManagementUnits}
            sessionKey={REFERENCIAL_TYPE_NAME.managementUnit}
            data={formattedUnits}
            setExportData={setExportData}
            headers={headers}
            onClick={managementUnit => dispatch(push(`/referencial/managementUnit/${managementUnit.managementCode}/dashboard`))}
        />
    )
}

export default ManagementUnitsApp
