import React, { Component } from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import PropTypes from 'prop-types'
import AppStore from '../../store/AppStore'
import SieauAction from '../sieau/SieauAction'

class Popup extends Component {
    componentDidMount() {
        $('.modal').modal({
            complete: () => {
                if (!this.props.dontReset) {
                    AppStore.dispatch(SieauAction.resetPopup())
                } else {
                    AppStore.dispatch(SieauAction.setPopupIsClosed())
                }
                this.props.onClose()
            },
            ready: () => {
                this.props.callback()
                AppStore.dispatch(SieauAction.openPopup())
            },
            dismissible: this.props.dismissible,
        })
        if (this.props.idPopup && this.props.id) {
            $(`#${this.props.idPopup}`).modal('open')
        }
    }

    componentDidUpdate() {
        $('.modal').modal({
            complete: () => {
                if (!this.props.dontReset) {
                    AppStore.dispatch(SieauAction.resetPopup())
                } else {
                    AppStore.dispatch(SieauAction.setPopupIsClosed())
                }
                this.props.onClose()
            },
            ready: () => {
                this.props.callback()
                AppStore.dispatch(SieauAction.openPopup())
            },
            dismissible: this.props.dismissible,
        })
        if (this.props.idPopup && this.props.id) {
            $(`#${this.props.idPopup}`).modal('open')
        }
    }

    getAction = () => {
        if (this.props.actions) {
            return (
                <div className='modal-footer white'>
                    {
                        this.props.actions || (<a className='modal-action modal-close waves-effect waves-green btn-flat'>{ i18n.close }</a>)
                    }
                </div>
            )
        }
        return null
    }

    render() {
        if (this.props.idPopup && this.props.id) {
            return (
                <div
                    id={ this.props.idPopup }
                    className={ `modal ${this.props.fixedFooter ? 'modal-fixed-footer ' : ''}${this.props.className}` }
                    style={ { maxHeight: (this.props.size || '80%') } }
                >
                    <div className='modal-content white'>
                        <h4>{ this.props.header }</h4>
                        <div>{ this.props.content }</div>
                    </div>
                    { this.getAction() }
                </div>
            )
        }
        return null
    }
}

Popup.propTypes = {
    header: PropTypes.string,
    actions: PropTypes.string,
    id: PropTypes.string,
    idPopup: PropTypes.string,
    fixedFooter: PropTypes.bool,
    bottomSheet: PropTypes.bool,
    content: PropTypes.element,
    className: PropTypes.string,
    size: PropTypes.string,
    onClose: PropTypes.func,
    dismissible: PropTypes.bool,
    callback: PropTypes.func,
    dontReset: PropTypes.bool,
}

Popup.defaultProps = {
    size: 'small',
    className: '',
    onClose: () => {
    },
    dismissible: true,
    callback: () => {
    },
}

const mapStateToProps = store => {
    return { ...store.SieauReducer.popup }
}

export default connect(mapStateToProps)(Popup)
