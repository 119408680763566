import { round } from 'lodash'
import i18n from 'simple-react-i18n'
import Line from '../components/echart/series/Line'
import { getThresholdColor } from './ColorUtil'

const getHardHydroDataTypes = () => [
    { id: 4, label: i18n.height, unit: 'm' },
    { id: 5, label: i18n.flow },
    { id: 6, label: i18n.speed },
]

const getHydroThresholdsMarkLine = (thresholds = []) => {
    const lines = thresholds.map(t => {
        return {
            yAxis: t.value,
            symbol: 'none',
            label: {
                show: true,
                position: 'middle',
                formatter: () => t.title ? `${t.title} : ${round(t.value, 2)}` : '',
            },
            lineStyle: {
                normal: {
                    color: getThresholdColor(t.color),
                    type: 'solid',
                },
            },
        }
    })
    return { silent: false, data: lines }
}

const getHydroHeights = (rawMeasures, gridIndex = 0, thresholds = [], overrideOptions = {}, withEvents = false) => {
    return Line({
        data: rawMeasures,
        name: i18n.height,
        yAxisIndex: withEvents ? gridIndex + 1 : gridIndex,
        xAxisIndex: gridIndex,
        markLine: getHydroThresholdsMarkLine(thresholds), // thresholds
        connectNulls: false,
        showSymbol: false,
        color: '#222',
        serieId: 'height',
        ...overrideOptions,
    })
}

const getHydroFlow = (flowMeasures, gridIndex = 0, thresholds = [], overrideOptions = {}, withEvents = false) => {
    return Line({
        data: flowMeasures,
        name: i18n.flow,
        yAxisIndex: withEvents ? gridIndex + 1 : gridIndex,
        xAxisIndex: gridIndex,
        markLine: getHydroThresholdsMarkLine(thresholds), // thresholds
        connectNulls: false,
        showSymbol: false,
        color: '#3980fa',
        serieId: 'height',
        ...overrideOptions,
    })
}

const getHydroAdditionalMeasures = (measures, label, color, type, gridIndex = 0, thresholds = [], overrideOptions = {}, withEvents = false) => {
    return Line({
        data: measures,
        name: label,
        yAxisIndex: withEvents ? gridIndex + 1 : gridIndex,
        xAxisIndex: gridIndex,
        markLine: getHydroThresholdsMarkLine(thresholds), // thresholds
        connectNulls: false,
        showSymbol: false,
        color,
        serieId: `additional${type}`,
        ...overrideOptions,
    })
}

const getModalite = (observationType, observationModalites4, observationModalites5) => {
    switch (observationType) {
        case 1: return observationModalites4
        case 2: return observationModalites5
        default: return []
    }
}

const getObservationColor = modaliteReference => {
    switch (modaliteReference) {
        case '1':
        case '1a':
            return 'BLUE_OBSERVATIONS'
        case '1f':
            return 'YELLOW_OBSERVATIONS'
        case '2':
            return 'ORANGE_OBSERVATIONS'
        case '3':
            return 'RED_OBSERVATIONS'
        case '4':
            return 'GREY_OBSERVATIONS'
        default:
            return 'WHITE'
    }
}
const getCardObservationColor = modaliteReference => {
    switch (modaliteReference) {
        case '1':
        case '1a':
            return 'rgb(0, 141, 202)'
        case '1b':
        case '1f':
            return 'rgb(255, 215, 0)'
        case '2':
            return 'rgb(237, 119, 19)'
        case '3':
            return 'rgb(203, 13, 29)'
        case '4':
            return 'rgb(169, 170, 172)'
        default:
            return 'white'
    }
}

export { getHardHydroDataTypes, getHydroHeights, getHydroFlow, getHydroAdditionalMeasures, getObservationColor, getCardObservationColor, getModalite }
