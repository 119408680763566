import { Dialog, DialogContent } from '@mui/material'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import ParameterDto from '../../../../referencial/components/parameter/dto/ParameterDto'
import ParameterStepper from './stepper/ParameterStepper'
import SelectionStepper from './stepper/SelectionStepper'


const STATION = 1
const PARAM = 2

class ParameterPopin extends Component {
    state = {
        isOpen: false,
        showParameter: false,
        showSelection: false,
        selectedParameters: [],
        selectedSelections: [],
    }

    componentDidUpdate = prevProps => {
        if (prevProps.isOpen !== this.props.isOpen) {
            this.setState({ isOpen: this.props.isOpen, selectedParameters: [...this.props.selectedParameters], selectedSelections: [...this.props.selectedSelections] })
        }
    }

    prevStep = () => {
        if (this.state.step === STATION) {
            this.props.onClose()
        } else {
            this.setState({ step: STATION })
        }
    }

    nextStep = () => {
        if (this.state.step === PARAM) {
            this.setState({ step: STATION })
            this.props.onValidate()
        } else {
            this.setState({ step: PARAM })
        }
    }

    onClose = () => {
        this.setState({ isOpen: false, showParameter: false, showSelection: false })
        this.props.onClose()
    }

    onValidateSelections = () => {
        const {
            selectedParameters,
            selectedSelections,
        } = this.state
        this.setState({ isOpen: false, showParameter: false, showSelection: false })
        this.props.onValidate(selectedParameters, selectedSelections)
    }

    onValidateParameters = () => {
        const {
            selectedParameters,
        } = this.state
        this.setState({ isOpen: false, showParameter: false, showSelection: false })
        this.props.onValidate(selectedParameters, [])
    }

    render = () => {
        const {
            isOpen,
            showSelection,
            showParameter,
            selectedParameters,
            selectedSelections,
        } = this.state

        const styleDivider = {
            borderLeft: '1px solid #38546d',
            borderRight: '1px solid #16222c',
            height: '13rem',
            right: '50%',
            position: 'absolute',
        }

        return (
            <div>
                <Dialog
                    onClose={this.onClose}
                    fullWidth
                    maxWidth='md'
                    open={isOpen}
                >
                    <DialogContent>
                        <div className='row no-margin padding-top-2 padding-bottom-3'>
                            <div className='col s5'>
                                <div className='col s8 offset-s4'>
                                    <i className='col s12 material-icons center padding-bottom-1' style={{ fontSize: '8rem' }}>bubble_chart</i>
                                    <a className='col s12 waves-effect waves-teal btn-flat btn font-white' onClick={() => this.setState({ showParameter: true, isOpen: false })}>
                                        {i18n.parameter}
                                    </a>
                                </div>
                            </div>
                            <div className='col s2'>
                                <div style={styleDivider} />
                            </div>
                            <div className='col s5'>
                                <div className='col s8'>
                                    <i className='col s12 material-icons center padding-bottom-1' style={{ fontSize: '8rem' }}>group_work</i>
                                    <a className='col s12 waves-effect waves-teal btn-flat btn font-white' onClick={() => this.setState({ showSelection: true, isOpen: false })}>
                                        {i18n.selection}
                                    </a>

                                </div>
                            </div>
                        </div>
                    </DialogContent>
                </Dialog>
                {
                    showSelection && (
                        <SelectionStepper
                            isOpen={showSelection}
                            selectedSelections={selectedSelections}
                            selectedParameters={selectedParameters}
                            onClose={this.onClose}
                            onValidate={this.onValidateSelections}
                            onChange={obj => this.setState(obj)}
                        />
                    )
                }
                {
                    showParameter && (
                        <ParameterStepper
                            isOpen={showParameter}
                            onClose={this.onClose}
                            onValidate={this.onValidateParameters}
                            selectedParameters={selectedParameters}
                            onChange={obj => this.setState(obj)}
                        />
                    )
                }
            </div>
        )
    }
}

ParameterPopin.propTypes = {
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
    onValidate: PropTypes.func,
    onChange: PropTypes.func,

    selectedSelections: PropTypes.arrayOf(PropTypes.string),

    selectedParameters: PropTypes.arrayOf(PropTypes.shape({
        parameterCode: PropTypes.string,
        fractionCode: PropTypes.string,
        supportCode: PropTypes.string,
        unitCode: PropTypes.string,
        placeCode: PropTypes.string,
    })),
    parameters: PropTypes.arrayOf(PropTypes.instanceOf(ParameterDto)),
}

const mapStateToProps = store => ({
    parameters: store.ParameterReducer.parameters,
})

export default connect(mapStateToProps)(ParameterPopin)