export default class DtoExploitationLight {
    constructor(object) {
        this.idExploitation = object.idExploitation // Long,
        this.codification = object.codification // Option[String] = None,
        this.name = object.name // Option[String] = None,
        this.updateDate = object.updateDate // Option[DateTime] = None,
        this.updateLogin = object.updateLogin // Option[String] = None,
        this.activityEndDate = object.activityEndDate // Option[DateTime] = None,
        this.structureType = object.structureType // Option[String] = None,
        this.address = object.address // Option[String] = None,
        this.additionnalAddress = object.additionnalAddress // Option[String] = None,
        this.inseeCode = object.inseeCode // Option[String] = None,
        this.postalCode = object.postalCode // Option[String] = None,
        this.city = object.city // Option[String] = None,
        this.phoneTel = object.phoneTel // Option[String] = None,
        this.mobile = object.mobile // Option[String] = None,
        this.fax = object.fax // Option[String] = None,
        this.email = object.email // Option[String] = None,
        this.siret = object.siret // Option[String] = None
    }
}