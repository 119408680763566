/* eslint-disable max-nested-callbacks */
import React, { useMemo } from 'react'
import i18n from 'simple-react-i18n'
import echarts from 'echarts/lib/echarts'
import ReactECharts from 'echarts-for-react'
import { countBy, groupBy, range, reverse, round } from 'lodash'
import { shallowEqual, useSelector } from 'react-redux'
import { exportPictureIcon } from 'components/echart/EChartUtils'
import { hasValue } from 'utils/NumberUtil'
import PropTypes from 'prop-types'
import CatchmentDto from 'catchment/dto/CatchmentDto'
import moment from 'moment'
import { getContaminationState } from 'catchment/utils/CatchmentUtil'
import useApplicationSetting, { intParser, listStringParser } from 'utils/customHook/useApplicationSetting'
import CatchmentPointDto from 'catchment/dto/CatchmentPointDto'
import { NITRATES_CODE } from 'catchment/constants/CatchmentConstant'
import DtoAnalysisUltraLight from 'quality/dto/analyse/DtoAnalysisUltraLight'
import useStartPeriod from 'catchment/utils/useStartPeriod'
import useEndPeriod from 'catchment/utils/useEndPeriod'
// import { getSandreLabel } from 'utils/StringUtil'

const ContaminationEvolutionChart = ({
    catchments = [],
    catchmentPoints = [],
    analysis = [],
    useP90 = false,
    referenceSdages = [],
}) => {
    const {
        qualitometers,
        qualityThresholds,
    } = useSelector(store => ({
        qualitometers: store.QualityReducer.qualitometers,
        qualityThresholds: store.QualityReducer.qualityThresholds,
    }), shallowEqual)

    const thresholdNitrates = useApplicationSetting('CATCHMENT_NITRATES_THRESHOLD', setting => setting ? parseFloat(setting) : 50)
    const thresholdPesticides1 = useApplicationSetting('CATCHMENT_PESTICIDE_THRESHOLD_1', setting => setting ? parseFloat(setting) : 0.1)
    const thresholdPesticides2 = useApplicationSetting('CATCHMENT_PESTICIDE_THRESHOLD_2', setting => setting ? parseFloat(setting) : 0.5)
    const regroupment = useApplicationSetting('CATCHMENT_REGROUPING', setting => setting ? parseInt(setting) : 2)
    const specificThreshold = useApplicationSetting('CATCHMENT_SPECIFIC_THRESHOLD')

    const listSumPesticides = useApplicationSetting('CATCHMENT_LIST_SUM_PESTICIDES', listStringParser)
    const calculationMethod = useApplicationSetting('CATCHMENT_OVERVIEW_CALCULATION_METHOD', listStringParser)

    const threshold = qualityThresholds.find(t => t.thresholdCode === specificThreshold)

    const optionsCalculState = {
        thresholdNitrates,
        thresholdPesticides1,
        thresholdPesticides2,
        useP90,
        threshold,
        listSumPesticides,
        calculationMethod,
    }

    const startPeriod = useStartPeriod(intParser)
    const endPeriod = useEndPeriod(intParser)

    const rangeDate = range(endPeriod, startPeriod - 1, -regroupment)
    const formatDate = date => date === startPeriod || regroupment === 1 ? `${date}` : `${Math.max(startPeriod, date - regroupment + 1)}-${date}`
    const dateList = rangeDate.map(formatDate)

    const nitratesAnalysisGroup = useMemo(() => {
        const nitratesAnalysis = analysis.filter(({ parameter }) => parameter === NITRATES_CODE)
        return groupBy(nitratesAnalysis, 'qualitometer')
    }, [analysis])

    const pesticidesAnalysisGroup = useMemo(() => {
        const pesticidesAnalysis = analysis.filter(({ parameter }) => parameter !== NITRATES_CODE)
        return groupBy(pesticidesAnalysis, 'qualitometer')
    }, [analysis])

    const dataByDate = rangeDate.reduce((acc, date) => {
        acc[date] = groupBy(catchments, c => {
            const startDate = moment(date - regroupment + 1, 'YYYY').startOf('year').valueOf()
            const endDate = moment(date, 'YYYY').endOf('year').valueOf()
            const points = catchmentPoints.filter(p => p.id === c.id)
            const ids = points.map(p => qualitometers.find(q => q.code === p.codeWithoutDesignation)?.id).filter(id => hasValue(id))

            const nitrates = ids.flatMap(id => nitratesAnalysisGroup[id] || []).filter(a => a.analysisDate > startDate && a.analysisDate < endDate)
            const pesticides = ids.flatMap(id => pesticidesAnalysisGroup[id] || []).filter(a => a.analysisDate > startDate && a.analysisDate < endDate)
            return getContaminationState(nitrates, pesticides, optionsCalculState)
        })
        return acc
    }, {})

    const createSeries = (key, color, opacColor, name) => {
        const {
            dataLastSDAGE,
            dataOther,
        } = Object.keys(dataByDate).reduce((acc, date) => {
            const {
                reference = 0,
                other = 0,
            } = countBy(dataByDate[date][key] || [], c => referenceSdages.includes(c.usedSdage) ? 'reference' : 'other')
            acc.dataLastSDAGE.push({ value: [formatDate(parseInt(date)), reference / catchments.length * 100], name: 'reference' })
            acc.dataOther.push({ value: [formatDate(parseInt(date)), other / catchments.length * 100], name: 'other' })
            return acc
        }, { dataLastSDAGE: [], dataOther: [] })
        return [{
            type: 'bar',
            label: {
                show: !!dataOther.length,
                formatter: obj => {
                    return round(obj.data.value[1], 2) || ''
                },
                position: 'inside',
                color: '#000',
            },
            data: dataOther,
            name,
            stack: true,
            barMaxWidth: 60,
            itemStyle: {
                color,
            },
        }, {
            type: 'bar',
            label: {
                show: !!dataLastSDAGE.length,
                formatter: obj => {
                    return round(obj.data.value[1], 2) || ''
                },
                position: 'inside',
                color: '#000',
            },
            data: dataLastSDAGE,
            name,
            stack: true,
            barMaxWidth: 60,
            itemStyle: {
                color: opacColor,
            },
        }]
    }

    const series = [
        ...createSeries('missingData', '#DDDDDD', '#EEEEEE', i18n.missingData),
        ...createSeries('nitratesAndPesticides', '#E40B00', '#F57B76', i18n.nitratesAndPesticides),
        ...createSeries('pesticides', '#FF6619', '#FFA77A', i18n.pesticides),
        ...createSeries('nitrates', '#FFD500', '#FFEA80', i18n.nitrates),
        ...createSeries('noContaminationHigherThanThreshold', '#3355FF', '#889CFC', i18n.noContaminationHigherThanThreshold),
    ]

    const options = {
        title: {
            text: i18n.evolutionContaminationNitratesPesticides,
            left: 'center',
        },
        series,
        xAxis: [{
            type: 'category',
            data: reverse(dateList),
            axisLabel: {
                rotate: 50,
            },
        }],
        yAxis: [{
            type: 'value',
            max: 100,
            min: 0,
            name: i18n.catchmentPercentage,
            nameLocation: 'middle',
            nameGap: 40,
        }],
        legend: {
            top: '5%',
            left: '10%',
            right: '20%',
            type: 'scroll',
        },
        grid: {
            top: '15%',
            left: '6%',
            right: '3%',
            bottom: '80',
            containLabel: true,
            height: 400,
        },
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'shadow',
                snap: true,
            },
            formatter: params => {
                const sdageResult = params.filter(p => p.data.name === 'reference' && p.value[1])
                const sdageLabel = sdageResult.length ? `${i18n.referenceSDAGE}</br> ${sdageResult.map(({ marker, seriesName, value: [, value] }) => `${marker} ${seriesName}: ${round(value, 3)}%`).join('</br>')}</br>` : ''
                const otherResult = params.filter(p => p.data.name === 'other')
                const otherLabel = `${i18n.otherCatchment}</br> ${otherResult.map(({ marker, seriesName, value: [, value] }) => `${marker} ${seriesName}: ${round(value, 3)}%`).join('</br>')}`
                return `${sdageLabel}${otherLabel}`
            },
        },
        toolbox: {
            right: '3%',
            top: '5%',
            feature: {
                saveAsImage: {
                    title: i18n.export,
                    icon: exportPictureIcon,
                    name: i18n.evolutionContaminationNitratesPesticides,
                },
            },
        },
    }
    return (
        <ReactECharts
            echarts={echarts}
            option={options}
            notMerge={true}
            lazyUpdate={true}
            className={'row no-margin'}
            style={{ height: 500 }}
        />
    )
}

ContaminationEvolutionChart.propTypes = {
    catchments: PropTypes.arrayOf(PropTypes.instanceOf(CatchmentDto)),
    catchmentPoints: PropTypes.arrayOf(PropTypes.instanceOf(CatchmentPointDto)),
    analysis: PropTypes.arrayOf(PropTypes.instanceOf(DtoAnalysisUltraLight)),
    useP90: PropTypes.bool,
    referenceSdages: PropTypes.arrayOf(PropTypes.number),
}

export default ContaminationEvolutionChart