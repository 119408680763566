import * as olFormat from 'ol/format'
import VectorLayer from 'ol/layer/Vector'
import VectorSource from 'ol/source/Vector'
import { checkStatus } from 'utils/ActionUtils'
import Layers from 'utils/mapUtils/ILayers'
import LogAction from '../../../log/actions/LogAction'
import AppStore from '../../../store/AppStore'
import { removeNullKeys } from '../../StoreUtils'
import { EPSG3857 } from '../CoordinateUtils'

class _KML extends Layers {
    constructor(obj) {
        super()
        this.obj = obj
        this.kmlFormatWater = new olFormat.KML({
            extractStyles: obj.extractStyles || false,
        })
        this.popup = obj.popup
        this.layer = this.createLayer()
    }
    onChangeVisibility(visibility) {
        this.layer.setVisible(visibility)
    }


    getHeaders() {
        if (this.obj.headers) {
            return { Accept: 'application/vnd.google-earth.kml+xml' }
        }
        return {}
    }

    createLayer() {
        const that = this
        const vct = new VectorSource({
            loader () {
                fetch(that.obj.url, {
                    headers: that.getHeaders(),
                    method: 'GET',
                })
                    .then(response => checkStatus({
                        200: ((rep) => rep.text()),
                        204: (() => []),
                    }, response))
                    .then((response = []) => {
                        const feature = that.kmlFormatWater.readFeatures(response, { featureProjection: EPSG3857 })
                        feature.map(f => f.setId(that.registerFeature()))
                        vct.addFeatures(feature)
                    }).catch(err => AppStore.dispatch(LogAction.logError(err.stack)))
            },
            format: this.kmlFormatWater,
        })
        return new VectorLayer(removeNullKeys({
            source: vct,
            style: this.obj.extractStyles ? null : this.obj.style,
        }))
    }

    getLayer() {
        return this.layer
    }

    getPopup() {
        return this.popup
    }
}

export default function KML(args) {
    return new _KML(args)
}
