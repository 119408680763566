import { filter, find, reduce } from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import DatasAlertPanel from '../../../piezometry/components/alerts/datas/DatasAlertPanel'
import ThresholdAlertPanel from '../../../piezometry/components/alerts/thresholds/ThresholdAlertPanel'
import DtoPiezometerLight from '../../../piezometry/dto/DtoPiezometerLight'
import DtoPiezometryStationMeasure from '../../../station/dto/piezometer/DtoPiezometryStationMeasure'
import DtoPiezoThreshold from '../../../station/dto/piezometer/DtoPiezoThreshold'

class ProductionPiezometerAlertPanel extends Component {
    constructor(props) {
        super(props)
    }

    findPiezometer = (piezometerId) => {
        return find(this.props.piezometers, (piezo) => {
            return piezo.id == piezometerId
        })
    }
    getReducePiezometerMeasure = () => {
        if (this.props.measures && this.props.measures.length) {
            return reduce(this.props.measures, (a, b) => {
                (a[b.piezometerId] || (a[b.piezometerId] = [])).push(b)
                return a
            }, {})
        }
        return null
    }
    getDataAlert = (reducePiezometerMeasure) => {
        const result = Object.keys(reducePiezometerMeasure).map((o) => {
            const piezometer = this.findPiezometer(o)
            return (
                <DatasAlertPanel measures={ reducePiezometerMeasure[o] } piezometer={ piezometer }/>
            )
        })
        return (
            <div className='row no-margin'>{ result }</div>
        )
    }

    getThresholdAlert = (reducePiezometerMeasure) => {
        const result = Object.keys(reducePiezometerMeasure).map((o) => {
            const piezometer = this.findPiezometer(o)
            if (piezometer) {
                const thresholds = filter(this.props.piezometerThresholds, (threshold) => {
                    return threshold.bssCode == piezometer.code
                })
                return (
                    <ThresholdAlertPanel measures={ reducePiezometerMeasure[o] } piezometer={ piezometer }
                        piezometerThresholds={ thresholds }
                    />
                )
            }
            return null
        })
        return (
            <div className='row no-margin'>{ result }</div>
        )
    }

    render() {
        const reduceMeasure = this.getReducePiezometerMeasure()
        if (reduceMeasure) {
            const dataAlert = this.getDataAlert(reduceMeasure)
            const thresholdAlert = this.getThresholdAlert(reduceMeasure)
            return (
                <div className='margin-bottom-1'>
                    { dataAlert }
                    { thresholdAlert }
                </div>
            )
        }

        return null
    }
}

ProductionPiezometerAlertPanel.propTypes = {
    measures: PropTypes.arrayOf(PropTypes.instanceOf(DtoPiezometryStationMeasure)),
    piezometers: PropTypes.arrayOf(PropTypes.instanceOf(DtoPiezometerLight)),
    piezometerMeasureMin: PropTypes.arrayOf(PropTypes.instanceOf(DtoPiezometryStationMeasure)),
    piezometerThresholds: PropTypes.arrayOf(PropTypes.instanceOf(DtoPiezoThreshold)),
}
const mapStateToProps = store => {
    return {
        measures: store.PiezometerStationReducer.measures,
        piezometers: store.PiezometryReducer.piezometersLight,
        piezometerMeasureMin: store.PiezometerStationReducer.piezometerMeasureMin,
        piezometerThresholds: store.PiezometerStationReducer.allPiezometerThresholds,
    }
}
export default connect(mapStateToProps)(ProductionPiezometerAlertPanel)
