import floodIcon from 'assets/pictures/pictos/inondation.png'
import pollutionIcon from 'assets/pictures/pictos/pollution.png'
import React from 'react'
import { getNewsColor } from './ColorUtil'


const getCategoryMarker = (category) => {
    switch (category.typeCategory) {
        case 1:
            return 'inondation'
        case 2:
            return 'sols'
        default:
            return 'cms'
    }
}

const getCmsIcon = (typeCategory, style = {}) => {
    switch (typeCategory) {
        case 1:
            return <img src={ floodIcon } style={ { height: '30px', ...style } }/>
        case 2:
            return <img src={ pollutionIcon } style={ { height: '30px', ...style } }/>
        default:
            return null
    }
}

const getCmsColor = (cms) => {
    const color = getNewsColor(cms.levelContent)
    return color === 'null' ? 'blue' : color.toLowerCase()
}

const getCmsMarker = (cms, category) => {
    return `pictures/markers/PNG/map_${getCategoryMarker(category)}_${getCmsColor(cms)}.png`
}

export { getCmsMarker, getCmsIcon }

