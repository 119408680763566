import ReferencialDto from '../../../dto/ReferencialDto'

export default class DtoCulture extends ReferencialDto {
    constructor(obj) {
        super(obj)
        this.teteRotation = obj.teteRotation // Option[Boolean] = None,
        this.legumineuse = obj.legumineuse // Option[Boolean] = None,
        this.periodeSemence = obj.periodeSemence // Option[Int] = None,
        this.status = obj.status // Option[Int] = None,
        this.referentiel = obj.referentiel // Option[Boolean] = None,
        this.idFamille = obj.idFamille // Option[Int] = None,
        this.limitCropRotations = obj.limitCropRotations // Option[Boolean] = None,
        this.usermaj = obj.usermaj // Option[String] = None,
        this.datemaj = obj.datemaj // Option[DateTime] = None

        this.linkCulturesRPG = obj.linkCulturesRPG || [] // Option[Seq[String]] = None
    }
}