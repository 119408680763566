import React, { useEffect, useMemo, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import PropTypes from 'prop-types'
import ParameterItem from '../dto/ParameterItem'
import ParameterAction from '../actions/ParameterAction'
import { nbPerPageLabel, REFERENCIAL_TYPE_NAME } from '../../../constants/ReferencialConstants'
import {
    echaParameterDefaultLink,
    inerisParameterDefaultLink,
    pubChemParameterDefaultLink,
    sandreParameterDefaultLink,
} from '../../../../conf/SieauConstants'
import AppStore from '../../../../store/AppStore'
import { push } from '@lagunovsky/redux-react-router'
import ReferentialAction from '../../../action/ReferencialAction'
import ReferencialAction from '../../../action/ReferencialAction'
import { find, flatten, groupBy, orderBy, intersection } from 'lodash'
import {
    LINK_REFERENCIAL_PARAMETER_ITEM,
    PATH_REFERENCIAL,
    PATH_REFERENCIAL_PARAMETER,
    PATH_REFERENCIAL_PARAMETER_ITEM_NEW,
} from '../../../../home/constants/RouteConstants'
import SSEActions from '../../../../sse/actions/SSEActions'
import {
    REFERENCIAL_PARAMETER_PURGE_LOADING,
    REFERENCIAL_PARAMETER_PURGED,
} from '../../../../sse/constants/EventsConstants'
import { PARAMETER, CLASS_GROUP } from '../constants/ParameterConstants'
import { getData } from '../../../../utils/SSEUtils'
import HomeAction from '../../../../home/actions/HomeAction'
import Select from '../../../../components/forms/Select'
import ParametersGroupsApp from './ParametersGroupsApp'
import SelectionSelect from 'components/forms/specific/SelectionSelect'
import Card from 'components/card/Card'
import Row from 'components/react/Row'
import Input from 'components/forms/Input'
import { searchAllCharacters } from 'utils/StringUtil'
import { hasValue } from 'utils/NumberUtil'
import AlertAction from 'alerting/actions/AlertAction'
import { getLabel, getSandreList } from 'utils/StoreUtils'
import { SANDRE } from 'referencial/constants/ReferencialConstants'
import InstallationAction from 'installation/actions/InstallationAction'
import CityAction from 'referencial/components/city/actions/CityAction'
import Table from 'components/datatable/Table'
import { getFullDate } from 'utils/DateUtil'
import { getUser } from 'utils/SettingUtils'
import WaitAction from 'wait/WaitAction'
import logoSandre from 'assets/pictures/logo_sandre.png'
import ineris from 'assets/pictures/logos/ineris.png'
import echa from 'assets/pictures/logos/echa.png'
import pubChem from 'assets/pictures/logos/pubChem.png'
import useTitle from 'utils/customHook/useTitle'
import useActions from 'utils/customHook/useActions'
import RadioButtons from 'components/forms/RadioButtons'
import ImportFileContent from 'import/components/content/ImportFileContent'
import Checkbox from 'components/forms/Checkbox'
import { Grid } from '@mui/material'
import SimpleDatePicker from 'components/forms/SimpleDatePicker'
import { getDataWithHeaders } from 'utils/ExportDataUtil'
import useLocalStorage from 'utils/customHook/useLocalStorage'

const headersCsv = ['<CdParametre>*', '<NomParametre>', '<StParametre>', '<DateCreationParametre>', '<DateMajParametre>', '<AuteurParametre>', '<LbCourtParametre>', '<LbLongParametre>', '<NomlntParametre>', '<ParametreCalcule>', '<NatureParametre>', '<TypeParametre>', '<CdUniteReference1>', '<CdUniteReference2>', '<CdUniteReference3>', '<CdCASSubstanceChimique>']

const ImportParameterFileContent = ({
    title = '',
    subtitle = '',
    extension = '',
    importAllParameters = 1,
    setImportAllParameters = () => {},
    typeUsage = 1,
    setTypeUsage = () => {},
    actifAuto = true,
    setActifAuto = () => {},
    onImportFile = () => {},
}) => {
    const selectModes = useMemo(() => {
        return [
            { code: 1, name: i18n.importAllParameters },
            { code: 2, name: i18n.onlyUpdate },
        ]
    }, [])

    const selecttypeUsage = useMemo(() => {
        return [
            { code: 1, name: i18n.classParameter },
            { code: 2, name: i18n.usageParameter },
        ]
    }, [])

    return (
        <>
            <ImportFileContent
                title={title}
                subtitle={subtitle}
                extension={extension}
                headersCsv={headersCsv}
                onImportFile={f => onImportFile(f)}
            />
            <Grid container justifyContent={'center'} alignItems={'center'} >
                <Grid item xs={10} style={{ margin: '3rem 0 1rem 0' }}>
                    <Grid container justifyContent={'center'} alignItems={'center'}>
                        <Grid item xs={5}>
                            <fieldset className='width-100'>
                                <RadioButtons
                                    col={ 12 }
                                    colOption={ 12 }
                                    elements={ selectModes }
                                    onChange={ setImportAllParameters }
                                    selected={ importAllParameters }
                                />
                            </fieldset>
                        </Grid>
                        {extension === '.xml' && (
                            <>
                                <Grid item xs={2} />
                                <Grid item xs={5}>
                                    <fieldset className='width-100'>
                                        <RadioButtons
                                            col={ 12 }
                                            colOption={ 12 }
                                            elements={ selecttypeUsage }
                                            onChange={ setTypeUsage }
                                            selected={ typeUsage }
                                        />
                                    </fieldset>
                                </Grid>
                            </>
                        )}
                    </Grid>
                </Grid>
                <Grid item xs={extension === '.xml' ? 10 : 4} onClick={() => setActifAuto(!actifAuto)}>
                    <Checkbox
                        checked={actifAuto}
                        label={i18n.activateAllSupports}
                    />
                </Grid>
            </Grid>
        </>
    )
}

ImportParameterFileContent.propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.string,
    extension: PropTypes.string,
    importAllParameters: PropTypes.number,
    setImportAllParameters: PropTypes.func,
    typeUsage: PropTypes.numnber,
    setTypeUsage: PropTypes.func,
    actifAuto: PropTypes.bool,
    setActifAuto: PropTypes.func,
    onImportFile: PropTypes.func,
}

const ExportParameterContent = ({
    exportOption = 1,
    setExportOption = () => {},
    startDate = '',
    setStartDate = () => {},
    endDate = '',
    setEndDate = () => {},
    activeEstablishments = false,
    setActiveEstablishments = () => {},
}) => {
    const exportOptions = useMemo(() => {
        return [
            { code: 1, name: i18n.resultsTable },
            { code: 2, name: i18n.potentialSourcesPollution },
        ]
    }, [])

    return (
        <fieldset className='width-100'>
            <RadioButtons
                col={ 10 }
                activeOneBelowOther={true}
                className={'offset-s2'}
                elements={ exportOptions }
                selected={ exportOption }
                onChange={ setExportOption }
            />
            {exportOption === 2 && (
                <fieldset>
                    <legend>{i18n.criterias}</legend>
                    <Row>
                        <SimpleDatePicker
                            col={3}
                            label={i18n.startDate}
                            onChange={setStartDate}
                            id='startDate'
                            value={startDate}
                            max={endDate}
                        />
                        <SimpleDatePicker
                            col={3}
                            label={i18n.endDate}
                            onChange={setEndDate}
                            id='endDate'
                            value={endDate}
                            min={startDate}
                        />
                        <Checkbox
                            col={ 3 }
                            componentClassName='padding-top-10-px'
                            checked={ activeEstablishments }
                            onChange={setActiveEstablishments}
                            label={ i18n.activeEstablishments }
                        />
                        <a className='margin-1 waves-effect waves-teal center btn-flat secondary-button right' onClick={() => {
                            setStartDate('')
                            setEndDate('')
                            setActiveEstablishments(false)
                        }}
                        >
                            {i18n.reinit}
                        </a>
                    </Row>
                </fieldset>
            )}
        </fieldset>
    )
}

ExportParameterContent.propTypes = {
    exportOption: PropTypes.number,
    setExportOption: PropTypes.func,
    startDate: PropTypes.string,
    setStartDate: PropTypes.func,
    endDate: PropTypes.string,
    setEndDate: PropTypes.func,
    activeEstablishments: PropTypes.bool,
    setActiveEstablishments: PropTypes.func,
}

const ParametersFilters = ({
    defaultFilter = {},
    setFilter = () => {},
}) => {
    const {
        referencialStatus,
    } = useSelector(store => ({
        referencialStatus: store.ReferencialReducer.referencialStatus,
    }), shallowEqual)

    const [searchValue, setSearchValue] = useState(defaultFilter.searchValue)
    const [parameterStatus, setParameterStatus] = useState(defaultFilter.parameterStatus)
    const [parameterActive, setParameterActive] = useState(defaultFilter.parameterActive)
    const [selection, setSelection] = useState(defaultFilter.selection)
    const [selectionResults, setSelectionResults] = useState(defaultFilter.selectionResults)

    const onResetFilter = () => {
        setFilter({})
        setSearchValue('')
        setParameterStatus(null)
        setParameterActive(0)
        setSelection('')
        setSelectionResults([])
    }

    const parametersActiveOptions = useMemo(() => [
        {
            code: 0,
            name: i18n.everybody,
        }, {
            code: 1,
            name: i18n.active,
        }, {
            code: 2,
            name: i18n.inactive,
        },
    ], [])

    return (
        <Card
            className='row margin-left-1 margin-right-1'
            title={ i18n.filters }
            contentClassName='margin-top-1'
            noMargin={ false }
        >
            <Row>
                <Input
                    col={6}
                    title={i18n.search}
                    value={searchValue}
                    onChange={setSearchValue}
                    onEnterKeyPress={() => setFilter({ searchValue, parameterStatus, parameterActive, selection, selectionResults })}
                />
            </Row>
            <Row>
                <Select
                    options={referencialStatus}
                    label={i18n.status}
                    col={4}
                    onChange={setParameterStatus}
                    value={parameterStatus}
                    nullLabel='&nbsp;'
                />
                <Select
                    options={parametersActiveOptions}
                    label={i18n.active}
                    col={2}
                    onChange={setParameterActive}
                    value={parameterActive}
                    noNullValue
                />
                <SelectionSelect
                    col={6}
                    value={selection}
                    onChange={(r, v) => {
                        setSelection(v)
                        setSelectionResults(r)
                    }}
                />
            </Row>
            <Row>
                <a
                    className='margin-1 waves-effect waves-teal center btn-flat primary-button right'
                    onClick={() => setFilter({
                        searchValue,
                        parameterStatus,
                        parameterActive,
                        selection,
                        selectionResults,
                    })}
                >
                    {i18n.search}
                </a>
                <a className='margin-1 waves-effect waves-teal center btn-flat secondary-button right' onClick={onResetFilter}>
                    {i18n.reinit}
                </a>
            </Row>
        </Card>
    )
}

ParametersFilters.propTypes = {
    defaultFilter: PropTypes.string,
    setFilter: PropTypes.func,
}

const headers = ['code', 'name', 'type', 'nature', 'casCode', 'SISEeaucode', 'status', 'ld', 'lq']
const headersMultipleSelections = ['parameterCode', 'parameterName', 'level', 'code', 'name', 'townCode', 'city', 'x', 'y', 'projection', 'activity', 'effectiveTranche', 'icpe', 'dischargeAgreement', 'close']

const ParametersPanel = () => {
    const {
        parameters,
        referencialStatus,
        referencialSandreCodes,
        accountUser,
        globalResearch,
        activities,
        sandreCodes,
        industrialSites,
        installationsIndex,
        pollutionSourceActivities,
        citiesIndex,
    } = useSelector(store => ({
        parameters: store.ParameterReducer.parameters,
        referencialStatus: store.ReferencialReducer.referencialStatus,
        referencialSandreCodes: store.ReferencialReducer.referencialSandreCodes,
        accountUser: store.AccountReducer.accountUser,
        globalResearch: store.HomeReducer.globalResearch,
        activities: store.InstallationReducer.activities,
        sandreCodes: store.ReferencialReducer.sandreCodes,
        industrialSites: store.InstallationReducer.industrialSites,
        installationsIndex: store.InstallationReducer.installationsIndex,
        pollutionSourceActivities: store.AlertReducer.pollutionSourceActivities,
        citiesIndex: store.CityReducer.citiesIndex,
    }), shallowEqual)

    const [filter, setFilter] = useLocalStorage(`referencial_${REFERENCIAL_TYPE_NAME.parameter}`, {})

    const [purgeProgress, setPurgeProgress] = useState(0)
    const [purgeIsRunning, setPurgeIsRunning] = useState(false)
    const [actifAuto, setActifAuto] = useState(false)
    const [importAllParameters, setImportAllParameters] = useState(1)
    const [typeUsage, setTypeUsage] = useState(1)
    const [fileContent, setFileContent] = useState('')

    const [exportOption, setExportOption] = useState(1)
    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')
    const [activeEstablishments, setActiveEstablishments] = useState(false)

    const dispatch = useDispatch()

    const onPurgeProgress = (event) => {
        const { percent } = getData(event)
        if (percent || percent === 0) {
            setPurgeProgress(percent)
        }
        if (percent === 100) {
            setPurgeIsRunning(false)
        }
    }

    useTitle(() => [{
        title: i18n.referencials,
        href: PATH_REFERENCIAL,
    }, {
        title: i18n.parameters,
        href: PATH_REFERENCIAL_PARAMETER,
    }], [])

    useEffect(() => {
        if (globalResearch) {
            setFilter({ ...filter, searchValue: globalResearch })
            dispatch(HomeAction.updateGlobalResearch(''))
        }
    }, [dispatch, filter, globalResearch, setFilter])

    useEffect(() => {
        dispatch(ReferentialAction.fetchReferencialSandreCodes())
        dispatch(ReferentialAction.fetchReferencialStatus())
        dispatch(HomeAction.setHelpLink())

        dispatch(ParameterAction.fetchParameters(true))

        dispatch(ReferentialAction.fetchCheckPurgeReferencialIsRunning(PARAMETER)).then((json = {}) => setPurgeIsRunning(json.isRunning))

        dispatch(SSEActions.subscribe(REFERENCIAL_PARAMETER_PURGE_LOADING, onPurgeProgress))
        dispatch(SSEActions.subscribe(REFERENCIAL_PARAMETER_PURGED, onPurgeProgress))

        if (!activities.length) {
            dispatch(InstallationAction.fetchActivities())
        }

        if (!sandreCodes.length) {
            dispatch(ReferencialAction.fetchSandreCodes())
        }

        if (!industrialSites.length) {
            dispatch(WaitAction.waitStart())
            dispatch(InstallationAction.fetchIndustrialSites()).then(() => {
                dispatch(WaitAction.waitStop())
            })
        }

        if (!Object.keys(installationsIndex).length) {
            dispatch(InstallationAction.fetchInstallationsLight())
        }

        dispatch(CityAction.fetchCities())

        return () => dispatch(SSEActions.unsubscribe(REFERENCIAL_PARAMETER_PURGE_LOADING, onPurgeProgress))
    }, [])

    const links = useMemo(() => [{
        href: sandreParameterDefaultLink,
        img: logoSandre,
        label: i18n.sandre.toUpperCase(),
    }, {
        href: inerisParameterDefaultLink,
        img: ineris,
        label: i18n.ineris.toUpperCase(),
    }, {
        href: echaParameterDefaultLink,
        img: echa,
        label: i18n.echa.toUpperCase(),
    }, {
        href: pubChemParameterDefaultLink,
        img: pubChem,
        label: i18n.pubChem.toUpperCase(),
    }], [])

    const handlePugre = () => {
        setPurgeIsRunning(true)
        dispatch(ParameterAction.purgeParameter())
    }

    const getHash = (parameter) => searchAllCharacters(headers.map(key => parameter[key] && parameter[key].value ? parameter[key].value : parameter[key]))

    const parametersBySearch = useMemo(() => {
        const parametersFormatted = parameters.map(p => new ParameterItem(p))
        const parametersBySearchValue = filter.searchValue ? parametersFormatted.filter(i => getHash(i, headers).includes(searchAllCharacters(filter.searchValue || ''))) : parametersFormatted
        const parametersByStatus = hasValue(filter.parameterStatus) ? parametersBySearchValue.filter(s => parseInt(s.status) === filter.parameterStatus) : parametersBySearchValue
        const parametersByActive = filter.parameterActive ? parametersByStatus.filter(s => (filter.parameterActive === 2 && s.active !== '1') || filter.parameterActive === parseInt(s.active)) : parametersByStatus
        return filter.selectionResults ? parametersByActive.filter(s => !filter.selectionResults.length || filter.selectionResults.includes(s.code)) : parametersByActive
    }, [filter.parameterActive, filter.parameterStatus, filter.searchValue, filter.selectionResults, parameters])

    const parametersOfSelection = useMemo(() => orderBy(parametersBySearch, 'code', 'asc'), [parametersBySearch])

    useEffect(() => {
        if (parametersOfSelection && parametersOfSelection.length > 0) {
            dispatch(AlertAction.fetchPollutionActivities(parametersOfSelection.map(p => p.code)))
        }
    }, [dispatch, parametersOfSelection])

    const psaFiltered = useMemo(() => orderBy(pollutionSourceActivities.filter(p => hasValue(p.rate) && hasValue(p.activityCode)), ['desc']), [pollutionSourceActivities])
    const psaGrouped = useMemo(() => groupBy(psaFiltered, 'rate'), [psaFiltered])
    const psaRates = useMemo(() => orderBy(Object.keys(psaGrouped), v => parseInt(v), ['desc']), [psaGrouped])

    const industrialSitesFormatted = useMemo(() => flatten(psaRates.map(rate => {
        const activitiesGroups = psaGrouped[rate].map(obj => obj.activityCode)
        const activitiesParametersCodes = psaGrouped[rate].map(obj => obj.parameterCode)
        const industrialSitesFiltered = industrialSites.filter(i => intersection(activitiesGroups, [i.activityCode, i.activityCode2, i.activityCode3, i.activityCode4]).length)
            .map((i, index) => ({ ...i, parameterCode: activitiesParametersCodes[index] }))
        return industrialSitesFiltered.map(r => ({ ...r, ...installationsIndex[r.id] }))
            .map(i => ({
                ...i,
                level: rate,
                city: citiesIndex[i.townCode]?.name,
                parameterCode: i.parameterCode,
                parameterName: (parameters.find(p => p.code === i.parameterCode) || {}).name,
                dischargeAgreement: i.dischargeAgreement ? i18n.yes : i18n.no,
                icpe: i.icpe ? i18n.yes : i18n.no,
                close: i.agreementEndDate ? i18n.yes : i18n.no,
                effectiveTranche: i.effectiveTranche ? getSandreList(sandreCodes, SANDRE.SITE_INDUS_EFFECTIF)[i.effectiveTranche]?.name : getSandreList(sandreCodes, SANDRE.SITE_INDUS_EFFECTIF)[-1]?.name,
                activity: `${getLabel(activities, i.activityCode).replace(';', ',')} [${i.activityCode}]`,
                headers: headersMultipleSelections,
            }))
    })), [activities, citiesIndex, industrialSites, installationsIndex, parameters, psaGrouped, psaRates, sandreCodes])

    const industrialSiteData = useMemo(() => {
        if (industrialSitesFormatted && industrialSitesFormatted.length) {
            const industrialSitesFiltered = activeEstablishments ? industrialSitesFormatted.filter(i => !i.activityStatus || i.activityStatus != 2) : industrialSitesFormatted
            return industrialSitesFiltered.filter(e => (!startDate || (!e.agreementStartDate || e.agreementStartDate <= startDate)) && (!endDate || (!e.agreementEndDate || e.agreementEndDate >= endDate)))
        }
        return []
    }, [activeEstablishments, endDate, industrialSitesFormatted, startDate])

    const exportData = useMemo(() => {
        if (exportOption === 1) {
            return {
                data: getDataWithHeaders(parametersBySearch, headers),
                titleFile: i18n.parametersList,
            }
        }
        return {
            data: industrialSiteData,
            titleFile: i18n.industrialSites,
        }
    }, [exportOption, industrialSiteData, parametersBySearch])

    const onImportFile = (e) => {
        const reader = new FileReader()
        const file = e.target.files[0]
        reader.onload = upload => setFileContent(upload.target.result)
        reader.readAsDataURL(file)
    }

    const isConsultant = useMemo(() => getUser().consultant === '1', [])

    useActions(() => {
        const isEnableEdit = accountUser.metadata === '1' || accountUser.isAdmin === '1'
        const actions = {}
        const actionsReferencial = {
            sync: {
                updateAction: () => dispatch(ParameterAction.fetchSynchroSandreParameter()),
                onImportXml: () => dispatch(ParameterAction.fetchImportParameterXML(fileContent, importAllParameters === 1, actifAuto, typeUsage === 1)),
                onImportCsv: () => dispatch(ParameterAction.fetchImportParameterCSV(fileContent, importAllParameters === 1, actifAuto)),
                xmlContent: (
                    <ImportParameterFileContent
                        title={i18n.xmlSyncChoice}
                        subtitle={i18n.importXmlFileToZanderReference}
                        extension={'.xml'}
                        importAllParameters={importAllParameters}
                        setImportAllParameters={setImportAllParameters}
                        typeUsage={typeUsage}
                        setTypeUsage={setTypeUsage}
                        actifAuto={actifAuto}
                        setActifAuto={setActifAuto}
                        onImportFile={onImportFile}
                    />
                ),
                csvContent: (
                    <ImportParameterFileContent
                        title={i18n.csvSyncChoice}
                        subtitle={i18n.importCsvZanderReference}
                        extension={'.csv'}
                        importAllParameters={importAllParameters}
                        setImportAllParameters={setImportAllParameters}
                        typeUsage={typeUsage}
                        setTypeUsage={setTypeUsage}
                        actifAuto={actifAuto}
                        setActifAuto={setActifAuto}
                        onImportFile={onImportFile}
                    />
                ),
                fileContentIsValid: !!fileContent,
            },
        }
        if (isEnableEdit && !isConsultant) {
            actions.new = () => dispatch(push(PATH_REFERENCIAL_PARAMETER_ITEM_NEW))
        }
        if (isEnableEdit && !isConsultant) {
            if (purgeIsRunning) {
                actions.loading = [{
                    label: i18n.purge.toUpperCase(),
                    value: purgeProgress,
                }]
            } else {
                actionsReferencial.purge = {
                    onPurge: handlePugre,
                }
            }
        }
        if (isEnableEdit) {
            actionsReferencial.export = {
                ...exportData,
                exportContent: (
                    <ExportParameterContent
                        exportOption={exportOption}
                        setExportOption={setExportOption}
                        startDate={startDate}
                        setStartDate={setStartDate}
                        endDate={endDate}
                        setEndDate={setEndDate}
                        activeEstablishments={activeEstablishments}
                        setActiveEstablishments={setActiveEstablishments}
                    />
                ),
            }
        }
        actions.links = links
        actions.referencialActions = {
            actions: actionsReferencial,
        }
        return actions
    }, [isConsultant, importAllParameters, typeUsage, exportOption, exportData, startDate, endDate, activeEstablishments, fileContent])

    const lastUpdate = useMemo(() => find(referencialSandreCodes, o => o.field === 'PARAMETRES'), [referencialSandreCodes])

    const colorData = useMemo(() => orderBy(parametersBySearch.map(p => {
        const obj = Object.keys(p).reduce((a, key) => ({
            ...a,
            [key]: {
                value: p[key],
            },
        }), {})
        return {
            ...obj,
            id: p.id,
            status: { value: referencialStatus.find(r => r.code === parseInt(p.status))?.name || p.status },
            lineColor: p.active === '1' ? { color: 'white' } : { color: 'grey lighten-1' },
        }
    }), data => data.code, 'asc'), [parametersBySearch, referencialStatus])

    return (
        <div>
            <ParametersFilters
                defaultFilter={filter}
                setFilter={setFilter}
            />
            <div className='row no-margin'>
                <div className='col s12'>
                    {
                        lastUpdate && lastUpdate.updateUser && lastUpdate.updateDate && (
                            <div className='row no-margin-bottom'>
                                <div className='col s4 center offset-s8 card'>
                                    <div className='col s12'>
                                        { i18n.lastUpdateOn } { getFullDate(lastUpdate.updateDate) } { i18n.by } { lastUpdate.updateUser }
                                    </div>
                                </div>
                            </div>
                        )
                    }
                    <div className='padding-top-5-px padding-bottom-2 referencial-table'>
                        <Table
                            title={i18n.parametersList}
                            data={colorData}
                            nbPerPageLabel={nbPerPageLabel}
                            link={LINK_REFERENCIAL_PARAMETER_ITEM}
                            store={AppStore}
                            color
                            type={{ headers }}
                            condensed
                            sortable
                            paging
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

const ParametersApp = () => {
    const [panel, setPanel] = useState(PARAMETER)

    const onChangePanel = (newPanel) => {
        if (panel !== newPanel) {
            setPanel(newPanel)
        }
    }

    useEffect(() => {
        $('ul.tabs').tabs()
    }, [])

    return (
        <div className='row no-margin'>
            <div className='col-s12 margin-left-1 margin-right-1'>
                <ul className='tabs tabs-fixed-width'>
                    <li className='tab'>
                        <a onClick={ () => onChangePanel(PARAMETER) }>{ i18n.parameters }</a>
                    </li>
                    <li className='tab'>
                        <a onClick={ () => onChangePanel(CLASS_GROUP) }>{ i18n.parameterGroup }</a>
                    </li>
                </ul>
            </div>
            {panel === PARAMETER && (
                <ParametersPanel />
            )}
            {panel === CLASS_GROUP && (
                <ParametersGroupsApp />
            )}
        </div>
    )
}

export default ParametersApp
