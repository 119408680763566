import { push } from '@lagunovsky/redux-react-router'
import HomeAction from 'home/actions/HomeAction'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import AppStore from 'store/AppStore'
import { H_QUALITO_CAMPAIGN } from '../../../account/constants/AccessRulesConstants'
import CampaignAction from '../../../campaign/actions/CampaignAction'
import DashboardCampaigns from '../../../campaign/components/DashboardCampaigns'
import CampaignDto from '../../../campaign/dto/CampaignDto'
import DtoCampaignProgress from '../../../campaign/dto/DtoCampaignProgress'
import ProgressCard from '../../../components/card/ProgressCard'
import SieauAction from '../../../components/sieau/SieauAction'
import { PATH_QUALITY, PATH_QUALITY_CAMPAIGN } from '../../../home/constants/RouteConstants'
import { componentHasHabilitations } from '../../../utils/HabilitationUtil'
import DtoQualitometerLight from '../../dto/DtoQualitometerLight'


class QualityCampaigns extends Component {
    state = {
        progress: 0,
        dataLoaded: false,
    }

    componentDidMount = () => {
        if (!componentHasHabilitations(H_QUALITO_CAMPAIGN)) { // A modifier quand react-router sera à jour
            this.props.push('/unauthorized')
            return
        }
        AppStore.dispatch(HomeAction.setHelpLink('qualite', ''))
        this.props.loadQualityCampaigns(p => this.setState({ progress: p }))
            .then(() => this.setState({ dataLoaded: true }))
        this.props.forceFetch('title', [{
            title: i18n.quality,
            href: PATH_QUALITY,
        }, {
            title: i18n.campaigns,
            href: PATH_QUALITY_CAMPAIGN,
        }])
    }

    render = () => {
        const {
            progress,
            dataLoaded,
        } = this.state
        const {
            qualitometers,
            qualityCampaigns,
            qualityCampaignsProgress,
        } = this.props
        return dataLoaded ? (
            <DashboardCampaigns
                stations={qualitometers}
                campaigns={qualityCampaigns}
                campaignsProgress={qualityCampaignsProgress}
                stationType={'quality'}
            />
        ) : (
            <ProgressCard progress={progress} />
        )
    }
}

QualityCampaigns.propTypes = {
    getLink: PropTypes.func,
    qualitometers: PropTypes.arrayOf(PropTypes.instanceOf(DtoQualitometerLight)),
    qualityCampaigns: PropTypes.arrayOf(PropTypes.instanceOf(CampaignDto)),
    qualityCampaignsProgress: PropTypes.arrayOf(PropTypes.instanceOf(DtoCampaignProgress)),

    forceFetch: PropTypes.func,
    loadQualityCampaigns: PropTypes.func,
    push: PropTypes.func,
}

const mapStateToProps = store => ({
    qualitometers: store.QualityReducer.qualitometersLight,
    qualityCampaigns: store.QualityReducer.qualityCampaigns,
    qualityCampaignsProgress: store.QualityReducer.qualityCampaignsProgress,
})

const mapDispatchToPorops = {
    forceFetch: SieauAction.forceFetch,
    loadQualityCampaigns: CampaignAction.loadQualityCampaigns,
    push,
}

export default connect(mapStateToProps, mapDispatchToPorops)(QualityCampaigns)