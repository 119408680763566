export default class DtoPiezometerThresholdItem {
    constructor(object) {
        this.name = object.name
        this.overrun = object.overrun
        this.color = object.color
        this.level = object.level
        this.nullValue = object.nullValue
        this.headers = ['name', 'overrun', 'color', 'level', 'nullValue']
    }
}
