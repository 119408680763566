export default class DtoMaterielStationCampaign {
    constructor(obj, siteType, siteId) {
        this.id = obj.id
        this.name = obj.name
        this.beginningApplication = obj.beginningApplication
        this.endingApplication = obj.endingApplication
        this.comment = obj.comment
        this.siteType = siteType
        this.siteId = siteId
    }
}