/* eslint-disable react/forbid-prop-types */
import { Grid2 } from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'
import i18n from 'simple-react-i18n'
import { nbPerPageLabel } from '../../referencial/constants/ReferencialConstants'
import { ALIGN, CardTable } from './NewTable'
import { isNil } from 'lodash'

const SelectionTable = ({
    maxHeight,
    height,
    componentClassName = '',

    selectedData = [],
    selectedHeaders = [],
    selectedCustomHeaders = {},
    selectedTitle = '',

    listData = [],
    listHeaders = [],
    listCustomHeaders = {},
    listTitle = '',

    onAdd = () => { },
    onDelete = () => { },
    addAll,
    deleteAll,
    nbPerPage,
}) => {
    const actionAddAll = [{
        onClick: () => addAll(listData),
        icon: 'add',
        tooltip: i18n.addAll,
        displayed: !isNil(addAll),
    }]

    const actionDeleteAll = [{
        onClick: deleteAll,
        icon: 'close',
        tooltip: i18n.deleteAll,
        displayed: !isNil(deleteAll),
    }]

    const selectedDataWithIndex = selectedData.map((data, i) => ({
        ...data,
        index: i,
    }))

    return (
        <Grid2 container spacing={1} className={componentClassName} style={{ height }}>
            <Grid2 size={6}>
                <CardTable
                    title={listTitle}
                    actions={actionAddAll}

                    rows={listData}
                    headers={listHeaders}
                    headersLabel={listCustomHeaders}

                    lineActions={[{ icon: 'add', onClick: onAdd, align: ALIGN.RIGHT }]}
                    onClickRow={onAdd}
                    maxHeight={maxHeight}
                    rowsPerPageOptions={nbPerPage || nbPerPageLabel}

                    data-cy='unselectedTable'
                />
            </Grid2>
            <Grid2 size={6}>
                <CardTable
                    title={selectedTitle}
                    actions={actionDeleteAll}

                    rows={selectedDataWithIndex}
                    headers={selectedHeaders}
                    headersLabel={selectedCustomHeaders}

                    lineActions={[{ icon: 'close', onClick: onDelete }]}
                    onClickRow={onDelete}
                    maxHeight={maxHeight}
                    rowsPerPageOptions={nbPerPage || nbPerPageLabel}

                    data-cy='selectedTable'
                />
            </Grid2>
        </Grid2>
    )
}

SelectionTable.propTypes = {
    maxHeight: PropTypes.string,
    height: PropTypes.string,
    componentClassName: PropTypes.string,

    selectedData: PropTypes.arrayOf(PropTypes.object),
    selectedHeaders: PropTypes.arrayOf(PropTypes.string),
    selectedCustomHeaders: PropTypes.object,
    selectedTitle: PropTypes.string,

    listData: PropTypes.arrayOf(PropTypes.object),
    listHeaders: PropTypes.arrayOf(PropTypes.string),
    listCustomHeaders: PropTypes.object,
    listTitle: PropTypes.string,

    onAdd: PropTypes.func,
    addAll: PropTypes.func,
    onDelete: PropTypes.func,
    deleteAll: PropTypes.func,
    nbPerPage: PropTypes.arrayOf(PropTypes.object),
}

export default SelectionTable