import i18n from 'simple-react-i18n'
import { MAT_SENSOR } from '../../../constants/MaterielConstants'
import DtoMaterielType from '../../../dto/DtoMaterielType'

export default class DtoSensorType extends DtoMaterielType {
    constructor(obj) {
        super(obj)
        this.materielCategory = MAT_SENSOR
        this.materielLabel = i18n.sensor
        this.range = obj.range
        this.length = obj.length
        this.manufacturerId = obj.manufacturerId
        this.height = obj.height
        this.powerSupplyType = obj.powerSupplyType
        this.desiccant = obj.desiccant
        this.pileNumber = obj.pileNumber
        this.supplyTime = obj.supplyTime
    }
}
