import React, { useMemo } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { nbPerPageLabel } from '../../../referencial/constants/ReferencialConstants'
import PropTypes from 'prop-types'
import { Grid } from '@mui/material'
import i18n from 'simple-react-i18n'
import { push } from '@lagunovsky/redux-react-router'
import { PERIMETERS_STATES_COLORS } from '../constants/PerimetersConstants'
import { searchAllCharacters } from '../../../utils/StringUtil'
import { compact, isUndefined, keyBy, uniq } from 'lodash'
import useSandreList from '../../../utils/customHook/useSandreList'
import { hasValue } from 'utils/NumberUtil'
import useProgressDispatch from 'utils/customHook/useProgressDispatch'
import PerimetersThunk from 'perimeters/action/PerimetersThunk'
import CityAction from 'referencial/components/city/actions/CityAction'
import ContributorThunk from 'referencial/components/contributor/actions/ContributorThunk'
import ProgressCard from 'components/card/ProgressCard'
import { WhiteCard } from 'components/styled/Card'
import Card from 'components/card/Card'
import { componentHasHabilitations } from 'utils/HabilitationUtil'
import { H_PERIMETERS_MODULE } from 'account/constants/AccessRulesConstants'
import { NewTable } from 'components/datatable/NewTable'

const PERIMETERS_FOLDER_HEADER = ['name', 'city', 'collectivity', 'nbPerimeters', 'stateOfProgress', 'status']

const ParentCard = ({
    dashboard = false,
    title = '',
    children,
}) => !dashboard ? <WhiteCard title={title} round>{children}</WhiteCard> : <Card title={title} round>{children}</Card>

ParentCard.propTypes = {
    dashboard: PropTypes.bool,
    title: PropTypes.string,
    children: PropTypes.element,
}

const PerimetersFolderTable = ({
    dashboard = false,
}) => {
    const dispatch = useDispatch()

    const {
        selectedSearchValues,
        contributors,
        citiesIndex,
        cities,
        perimetersFolder,
    } = useSelector(store => ({
        selectedSearchValues: store.AdministrationReducer.selectedSearchValues,
        contributors: store.ContributorReducer.contributors,
        cities: store.CityReducer.cities,
        citiesIndex: store.CityReducer.citiesIndex,
        perimetersFolder: store.PerimetersReducer.perimetersFolder,
    }), shallowEqual)

    const { isLoaded, progress } = useProgressDispatch(() => compact([
        !perimetersFolder.length && dispatch(PerimetersThunk.getPerimetersFolders()),
        !cities.length && dispatch(CityAction.fetchCities()),
        !contributors.length && dispatch(ContributorThunk.fetchContributors()),
    ]), [])

    const { perimetersFolder: searchValues } = selectedSearchValues
    const {
        searchValue,
        status,
        state,
        city,
        collectivity,
    } = searchValues ?? {}

    const citiesSearchIndexed = useMemo(() => {
        const formattedCities = uniq(perimetersFolder.flatMap(f => f.cities ?? [])).map(id => {
            const cityI = citiesIndex[id]
            return cityI ? {
                id,
                labelSearch: [cityI.name, cityI.code].map(l => searchAllCharacters(l)).join('#'),
            } : null
        })

        return keyBy(formattedCities, 'id')
    }, [citiesIndex, perimetersFolder])

    const filterData = () => {
        const searchValueCharacters = searchAllCharacters(searchValue)
        const statusParsed = parseInt(status)
        const stateParsed = parseInt(state)
        const collectivityParsed = parseInt(collectivity)


        const searchFilter = (folder) => {
            const folderContributor = contributors.find(c => c.id === folder.collectivity)
            const folderNameMatches = searchAllCharacters(folder.name).includes(searchValueCharacters)
            const contributorNameMatches = folderContributor?.name
                ? searchAllCharacters(folderContributor.name).includes(searchValueCharacters)
                : false

            const cityNameMatches = folder.cities?.some(cityId => {
                const cityData = citiesSearchIndexed[cityId]
                return cityData?.labelSearch.includes(searchValueCharacters)
            })

            return folderNameMatches || contributorNameMatches || cityNameMatches
        }

        const searchFilteredData = searchValue ? perimetersFolder.filter(searchFilter) : perimetersFolder

        const statusFilteredData = !isUndefined(status)
            ? searchFilteredData.filter(folder => statusParsed === parseInt(folder.status))
            : searchFilteredData

        const stateFilteredData = !isUndefined(state)
            ? statusFilteredData.filter(folder => stateParsed === parseInt(folder.state))
            : statusFilteredData

        const cityFilteredData = !isUndefined(city)
            ? stateFilteredData.filter(folder => folder.cities?.includes(city))
            : stateFilteredData

        const collectivityFilteredData = !isUndefined(collectivity)
            ? cityFilteredData.filter(folder => collectivityParsed === parseInt(folder.collectivity))
            : cityFilteredData

        return collectivityFilteredData
    }

    const lexiconStatus = useSandreList('PARCELLES.STATUT')
    const lexiconStates = useSandreList('PARCELLES.ETAT')

    const perimetersFolderFormated = filterData().map(folder => ({
        folderId: folder.folderId,
        name: folder.name,
        city: folder.cityName,
        stateOfProgress: lexiconStates.find(s => s.code === folder.state)?.name ?? '',
        defaultStatus: folder.status,
        status: hasValue(folder.status) ? (
            <Grid container item xs={'auto'} alignItems={'center'}>
                <Grid item paddingRight={'5px'}>
                    <Grid
                        item
                        sx={{
                            padding: '0.5rem',
                            backgroundColor: PERIMETERS_STATES_COLORS[folder.status] ?? PERIMETERS_STATES_COLORS[0],
                            borderRadius: '50rem',
                            border: 'solid 2px black',
                        }}
                    />
                </Grid>
                <Grid item>
                    {lexiconStatus.find(s => s.code === folder.status)?.name ?? ''}
                </Grid>
            </Grid>
        ) : '',
        collectivity: hasValue(folder.collectivity) ? contributors?.find(c => c.id === folder.collectivity)?.name : '',
        nbPerimeters: folder.perimeters ?? '',
    }))

    const habilitations = componentHasHabilitations(H_PERIMETERS_MODULE) && perimetersFolderFormated.length

    return (
        <Grid item xs={12} sx={{ paddingTop: (dashboard && habilitations) && '10px' }}>
            {!isLoaded ? (
                <ProgressCard progress={progress} />
            ) : !!habilitations && (
                <ParentCard
                    dashboard={dashboard}
                    title={!dashboard ? `${i18n.perimetersFolder} (${perimetersFolderFormated.length} ${perimetersFolderFormated.length > 1 ? i18n.elements : i18n.element})` : (
                        <a className='waves-effect' onClick={() => dispatch(push('/perimeter'))}>
                            <i className='material-icons '>public</i>
                            {i18n.perimetersFolder}
                        </a>
                    )}
                >
                    <NewTable
                        rows={perimetersFolderFormated}
                        headers={PERIMETERS_FOLDER_HEADER}
                        headersLabel={{
                            nbPerimeters: i18n.nbPerimetersReturnLine,
                            stateOfProgress: i18n.stateOfProgressReturnLine,
                        }}
                        rowsPerPageOptions={nbPerPageLabel}
                        onClickRow={f => dispatch(push(`/perimeter/${f.folderId}`))}
                        data-cy='perimeters_table'
                    />
                </ParentCard>
            )}
        </Grid>
    )
}

PerimetersFolderTable.propTypes = {
    dashboard: PropTypes.bool,
}

export default PerimetersFolderTable