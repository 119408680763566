import PropTypes from 'prop-types'
import React from 'react'
import { Grid } from '@mui/material'
import i18n from 'simple-react-i18n'

const ImportFileContent = ({
    title = '',
    subtitle = '',
    extension = '',
    headersCsv = [],
    onImportFile = () => {},
}) => {
    return (
        <Grid container justifyContent={'center'} alignItems={'center'} style={{ textAlign: 'center' }}>
            <Grid item xs={10} style={{ marginBottom: '5rem' }}>
                <h4>{title}</h4>
            </Grid>
            <Grid item xs={10}>
                <h5 className='caption'>{subtitle}</h5>
                <form action='#' >
                    <div className='file-field input-field'>
                        <div className='btn'>
                            <span>{ i18n.browse }</span>
                            <input type='file' accept={extension} onChange={onImportFile} />
                        </div>
                        <div className='file-path-wrapper'>
                            <input className='file-path validate' type='text' style={{ backgroundColor: 'white' }} />
                        </div>
                    </div>
                </form>
            </Grid>
            {(extension === '.csv' && headersCsv?.length) && (
                <>
                    <Grid item xs={12}>{`${i18n.csvFileMustContain}: `}{headersCsv.map((h, i) => (<span key={i} style={{ margin: '0 5px' }}>{`"${h}"`}</span>))}</Grid>
                    <Grid item xs={12}>{i18n.allFieldsAreRequired}</Grid>
                </>
            )}
        </Grid>
    )
}

ImportFileContent.propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.string,
    extension: PropTypes.string,
    headersCsv: PropTypes.arrayOf(PropTypes.string),
    onImportFile: PropTypes.func,
}

export default ImportFileContent
