import AppStore from '../../../../store/AppStore'
import { arrayOf } from '../../../../utils/StoreUtils'
import DtoCalculateParameters from '../../integrationOverview/dto/DtoCalculateParameters'
import OperationAction from '../actions/OperationAction'
import {
    RECEIVE_OPERATION_ANALYSIS,
    RECEIVE_ALL_FRACTIONS,
    RECEIVE_ALL_QUALITOMETER_OPERATIONS,
    RECEIVE_ALL_REMARKS,
    RECEIVE_CALCULATE_PARAMETERS, RECEIVE_ENVIRONMENTAL_CONDITION, RECEIVE_OPERATION,
    RECEIVE_OPERATION_FILES,
    RECEIVE_OPERATION_INDICES,
    RESET_ANALYSIS_OPERATION,
    RESET_OPERATION,
    RESET_SINGLE_OPERATION,
    RECEIVE_HYDROBIO_OPERATIONS,
    RECEIVE_HYDROBIO_OPERATION,
    RECEIVE_HYDROBIO_LIST,
    RECEIVE_ALL_HYDROBIO_LIST,
} from '../constants/OperationConstants'
import DtoDetailOperation from '../dto/DtoDetailOperation'
import DtoEnvironmentalCondition from '../dto/DtoEnvironmentalCondition'
import DtoFraction from '../dto/DtoFraction'
import DtoHydrobioList from '../dto/DtoHydrobioList'
import DtoIndice from '../dto/DtoIndice'
import DtoOperation from '../dto/DtoOperation'
import DtoRemark from '../dto/DtoRemark'
import DtoPrettyAnalysisLight from '../../dashboard/dto/DtoPrettyAnalysisLight'
import { groupBy } from 'lodash'

export function OperationReducer(state = {}, action) {
    switch (action.type) {
        case RECEIVE_ALL_QUALITOMETER_OPERATIONS:
            return {
                ...state,
                operations: action.operations.map(el => new DtoOperation(el)),
            }
        case RECEIVE_OPERATION:
            return {
                ...state,
                operation: new DtoDetailOperation(action.operation),
            }
        case RECEIVE_ALL_REMARKS:
            return {
                ...state,
                remarks: action.remarks.map(el => new DtoRemark(el)),
            }
        case RECEIVE_ALL_FRACTIONS:
            return {
                ...state,
                fractions: action.fractions.map(el => new DtoFraction(el)),
            }
        case RECEIVE_OPERATION_ANALYSIS:
            return {
                ...state,
                operationAnalysis: action.operationAnalysis.map(el => new DtoPrettyAnalysisLight(el)),
            }
        case RECEIVE_ENVIRONMENTAL_CONDITION:
            return {
                ...state,
                environmentalConditions: action.payload.map(ec=>new DtoEnvironmentalCondition(ec)),
            }
        case RECEIVE_HYDROBIO_OPERATIONS:
            return {
                ...state,
                hydrobioOperations: action.payload.map(o => new DtoOperation(o)),
            }
        case RECEIVE_ALL_HYDROBIO_LIST:
            return {
                ...state,
                hydrobioLists: action.payload.map(l => new DtoHydrobioList(l)),
            }
        case RECEIVE_HYDROBIO_OPERATION:
            return {
                ...state,
                hydrobioOperation: new DtoDetailOperation(action.payload),
            }
        case RECEIVE_HYDROBIO_LIST:
            return {
                ...state,
                hydrobioList: action.payload.map(l => new DtoHydrobioList(l)),
            }
        case RECEIVE_CALCULATE_PARAMETERS:
            return {
                ...state,
                calculateParameters: action.calculateParameters.map(el => new DtoCalculateParameters(el)),
            }
        case RECEIVE_OPERATION_INDICES:
            const pressuresParameter = [20001, 20002, 20003, 20004, 20005, 20006, 20007, 20008, 20009, 20010, 20011, 20012]
            const list = action.indices.map(el => new DtoIndice(el))
            const {
                indices = [],
                pressures = [],
            } = groupBy(list, i => pressuresParameter.some(p => p === i.parameter) ? 'pressures' : 'indices')
            return {
                ...state,
                operationIndices: indices,
                operationPressures: pressures,
            }
        case RECEIVE_OPERATION_FILES:
            return {
                ...state,
                files: action.files,
            }
        case RESET_OPERATION:
            return {
                ...state,
                operation: {},
                operationAnalysis: [],
                operations: [],
                calculateParameters: [],
                hydrobioOperations: [],
                hydrobioList: [],
                hydrobioLists: [],
            }
        case RESET_SINGLE_OPERATION:
            return {
                ...state,
                operation: {},
            }
        case RESET_ANALYSIS_OPERATION:
            return {
                ...state,
                operationAnalysis: [],
            }
        default:
            return state
    }
}

export const store = {
    operation: {},
    operations: [],
    remarks: [],
    fractions: [],
    operationAnalysis: [],
    contacts: [],
    calculateParameters: [],
    operationIndices: [],
    operationPressures: [],
    files: [],
    environmentalConditions: [],
    hydrobioOperations: [],
    hydrobioLists: [],
    hydrobioOperation: {},
    hydrobioList: [],
}

export const OperationStoreInfos = () => {
    const appStore = AppStore.getState()
    return {
        remarks: {
            fetch: OperationAction.fetchRemarks,
            store: appStore.OperationReducer.remarks,
            propType: arrayOf(DtoRemark),
        },
    }
}