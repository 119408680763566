import { Button, Dialog, DialogActions, DialogContent } from '@mui/material'
import GlobalParametersDto from 'administration/dto/GlobalParameterDto'
import Input from 'components/forms/Input'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import i18n from 'simple-react-i18n'
import Row from '../../../components/react/Row'


const PopupAddParameter = ({
    parameter,
    openDialog,
    addParameter = () => {},
    closeDialog = () => {},
}) => {
    const [param, setParam] = useState(parameter?.parameter || '')
    const [module, setModule] = useState(parameter?.module || '')
    const [value, setValue] = useState(parameter?.value || '')

    return (
        <Dialog
            onClose={closeDialog}
            fullWidth
            open={openDialog}
        >
            <DialogContent>
                <div className='row margin-bottom-2'>
                    <div className='card row no-margin'>
                        <div className='card-title activator'>
                            { parameter ? i18n.editParameter : i18n.addParameter}
                        </div>
                    </div>
                    <div className='col s10 offset-s1 margin-top-2'>
                        <Row>
                            <Input title={i18n.parameter}
                                value={ param }
                                onChange = { v => setParam(v)}
                            />
                            <Input title={i18n.module} value={ module }
                                onChange = { v => setModule(v)}
                            />
                            <Input title={i18n.value} value={ value }
                                onChange = { v => setValue(v)}
                            />
                        </Row>
                    </div>
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => closeDialog()} variant='outlined' >
                    {i18n.cancel}
                </Button>
                <Button onClick={() => addParameter({
                    parameter: param,
                    module,
                    value,
                })} variant='contained' color='primary'
                >
                    {parameter ? i18n.save : i18n.addParameter}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

PopupAddParameter.propTypes = {
    parameter: PropTypes.instanceOf(GlobalParametersDto),
    openDialog: PropTypes.bool,
    addParameter: PropTypes.func,
    closeDialog: PropTypes.func,
}

export default PopupAddParameter