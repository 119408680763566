import PropTypes from 'prop-types'
import React from 'react'
import i18n from 'simple-react-i18n'
import DtoAssociatedStation from '../../../../station/dto/DtoAssociatedStation'
import AllPointPanel from './AllPointPanel'
import PointPanel from './PointPanel'

const AssociatedPanel = ({
    sites = [],
    selectedSites = [],
    changeSelectedSite = () => {},
    onSelectSite = () => {},
    site = {},
    associatedSites = [],
}) => {
    const panelSites = sites.filter(o => {
        return (site.code !== o.code || site.typeName !== o.typeName)
            && !associatedSites.map(m => (m.code !== o.code || m.typeName !== o.typeName))
                .some(element => element === false)
            && o.lastMeasure.length
    }).filter(o => associatedSites.map(m => (m.id !== o.id || m.typeName !== o.typeName))).map(o => ({
        ...o,
        isCheck: !!selectedSites.find(s => s.code === o.code && s.typeName === o.typeName),
    })).sort((a, b) => b.trend - a.trend)
    if (site.code) {
        return (
            <div className='row no-margin'>
                <div className='col s12 no-padding' style={{ maxHeight: '91%' }}>
                    {
                        site && (
                            <div className='row no-margin'>
                                <div className='col s12 no-padding'>
                                    <PointPanel
                                        sites={ [site] }
                                        onClick={ onSelectSite }
                                        title={ i18n.followedSite }
                                        showSymbol={false}
                                    />
                                </div>
                            </div>
                        )
                    }
                    {
                        !!associatedSites.length && (
                            <div className='row no-margin'>
                                <div className='col s12 no-padding'>
                                    <PointPanel
                                        sites={ associatedSites.map(o => ({
                                            ...o,
                                            isCheck: !!selectedSites.find(s => s.code === o.code && s.typeName === o.typeName),
                                        })) }
                                        onClick={ onSelectSite }
                                        title={ i18n.associatedStations }
                                        onToggle={ changeSelectedSite }
                                    />
                                </div>
                            </div>
                        )
                    }
                    <AllPointPanel
                        sites={ panelSites }
                        onToggle={ changeSelectedSite }
                        onClick={ onSelectSite }
                        marginTop={ false }
                        maxHeight={ 500 }
                    />
                </div>
            </div>
        )
    }
    return null
}

AssociatedPanel.propTypes = {
    site: PropTypes.instanceOf(PropTypes.object),
    selectedSites: PropTypes.instanceOf(PropTypes.instanceOf(PropTypes.object)),
    sites: PropTypes.instanceOf(PropTypes.object),
    changeSelectedSite: PropTypes.func,
    onSelectSite: PropTypes.func,
    associatedSites: PropTypes.arrayOf(PropTypes.instanceOf(DtoAssociatedStation)),
}

export default AssociatedPanel