/* eslint-disable camelcase */
import DtoAltimetrySystem from '../../piezometry/dto/DtoAltimetrySystem'
import DtoLandmark from '../../piezometry/dto/DtoLandmark'
import DtoNetworkLink from '../../piezometry/dto/DtoNetworkLink'
import DtoPiezometerContributorLink from '../../piezometry/dto/DtoPiezometerContributorLink'
import DtoWatermassLink from '../../piezometry/dto/DtoWatermassLink'
import DtoDespoliation from '../../station/dto/DtoDespoliation'
import DtoGeoData from '../../station/dto/DtoGeoData'
import DtoLithology from '../../station/dto/DtoLithology'
import DtoLithologyType from '../../station/dto/DtoLithologyType'
import DtoLocation from '../../station/dto/DtoLocation'
import DtoLocationLink from '../../station/dto/DtoLocationLink'
import DtoMeasureMethod from '../../station/dto/DtoMeasureMethod'
import DtoStationContacts from '../../station/dto/DtoStationContacts'
import DtoWorkLink from '../../station/dto/DtoWorkLink'
import DtoPointPrel from '../../station/dto/DtoPointPrel'
import DtoTempRef from '../../station/dto/DtoTempRef'

export default class DtoHydrometricStation {
    constructor(object) {
        this.id = object.id
        this.code = object.code
        this.name = object.name
        this.stationType = object.stationType
        this.creationDate = object.creationDate
        this.closeDate = object.closeDate
        this.x = object.x
        this.y = object.y
        this.projection = object.projection
        this.projectionType = object.projectionType
        this.altitude = object.altitude
        this.comment = object.comment
        this.townCode = object.townCode
        this.contactCode = object.contactCode
        this.localisation = new DtoLocation(object, object.altitude)
        this.updateLogin = object.updateLogin
        this.updateDate = object.updateDate
        this.watershedHead = object.watershedHead
        this.hydrographicCode = object.hydrographicCode

        this.link_altimetrySystems = object.link_altimetrySystems && object.link_altimetrySystems.length ? object.link_altimetrySystems.map(l => new DtoAltimetrySystem(l)) : []
        this.link_contributors = object.link_contributors ? object.link_contributors.map(c => new DtoPiezometerContributorLink(c)) : []
        this.link_despoliationModes = object.link_despoliationModes ? object.link_despoliationModes.map(d => new DtoDespoliation(d)) : []
        this.link_geo = object.link_geo ? object.link_geo.map(g => new DtoGeoData(g)) : []
        this.link_landmarks = object.link_landmarks ? object.link_landmarks.map(l => new DtoLandmark(l)) : []
        this.link_lithologicType = object.link_lithologicType ? object.link_lithologicType.map(lt => new DtoLithologyType(lt)) : []
        this.link_lithology = object.link_lithology ? object.link_lithology.map(ll => new DtoLithology(ll)) : []
        this.link_locations = object.link_locations ? object.link_locations.map(l => new DtoLocationLink(l)) : []
        this.link_measureMethod = object.link_measureMethod ? object.link_measureMethod.map(lmm => new DtoMeasureMethod(lmm)) : []
        this.link_networks = object.link_networks ? object.link_networks.map(l => new DtoNetworkLink(l)) : []
        this.link_watermasses = object.link_watermasses ? object.link_watermasses.map(l => new DtoWatermassLink(l)) : []
        this.link_work = object.link_work ? object.link_work.map(lw => new DtoWorkLink(lw)) : []
        this.link_contacts = object.link_contacts ? object.link_contacts.map(lc => new DtoStationContacts(lc)) : []
        this.link_pointPrels = object.link_pointPrels ? object.link_pointPrels.map(lw => new DtoPointPrel(lw)) : []
        this.link_tempRefs = object.link_tempRefs ? object.link_tempRefs.map(tr => new DtoTempRef(tr)) : []

        this.typeName = 'hydrometry'
        this.nature = object.hydroObjectNature
        this.cote = object.cote
        this.fieldMode = object.fieldMode
        this.designation = object.designation
        this.waterSourceType = object.waterpointType
        this.address = object.address
        this.jobExecutionId = object.jobExecutionId
        this.watershedCode = object.watershedCode
    }
}