export default class DtoInstallationAnalysisTable {
    constructor(obj) {
        this.code = obj[0] // Int,
        this.analysisDate = obj[1] // Option[DateTime],
        this.date = obj[1]
        this.producerCode = obj[2] // Option[Int],
        this.producer = obj[2]
        this.result = obj[3] // Option[Float],
        this.parameterCode = obj[4] // Option[String],
        this.parameter = obj[4]
        this.unitCode = obj[5] // Option[String],
        this.unit = obj[5]
        this.remarkCode = obj[6] // Option[String],
        this.analysisRef = obj[7] // Option[String],
        this.reference = obj[7]
        this.analysisComment = obj[8] // Option[String]
        this.comment = obj[8]
    }
}
