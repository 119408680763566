/* eslint-disable camelcase */
import DtoNetworkLink from '../../piezometry/dto/DtoNetworkLink'
import DtoPiezometerContributorLink from '../../piezometry/dto/DtoPiezometerContributorLink'
import DtoGeoData from '../../station/dto/DtoGeoData'
import DtoLocationLink from '../../station/dto/DtoLocationLink'
import DtoMeasureMethod from '../../station/dto/DtoMeasureMethod'
import DtoStationContacts from '../../station/dto/DtoStationContacts'
import DtoWorkLink from '../../station/dto/DtoWorkLink'
import DtoPointPrel from '../../station/dto/DtoPointPrel'
import DtoTempRef from '../../station/dto/DtoTempRef'

export default class PluviometerDto {
    constructor(obj = {}) {
        this.id = obj.id
        this.name = obj.name
        this.creationDate = obj.creationDate
        this.closeDate = obj.closeDate
        this.x = obj.x
        this.y = obj.y
        this.projection = obj.projectionType
        this.projectionType = obj.projectionType
        this.altitude = obj.altitude
        this.measureExpression = obj.measureExpression
        this.comment = obj.comment
        this.townCode = obj.townCode
        this.contactCode = obj.contactCode
        this.code = obj.code
        this.administrator = obj.administrator
        this.localisation = {
            x: obj.x,
            y: obj.y,
            projection: obj.projectionType,
        }
        this.link_contributors = obj.link_contributors ? obj.link_contributors.map(c => new DtoPiezometerContributorLink(c)): []
        this.link_geo = obj.link_geo ? obj.link_geo.map(g => new DtoGeoData(g)) : []
        this.link_locations = obj.link_locations ? obj.link_locations.map(l => new DtoLocationLink(l)) : []
        this.link_measureMethod = obj.link_measureMethod ? obj.link_measureMethod.map(lmm => new DtoMeasureMethod(lmm)) : []
        this.link_networks = obj.link_networks ? obj.link_networks.map(l => new DtoNetworkLink(l)) : []
        this.link_work = obj.link_work ? obj.link_work.map(lw => new DtoWorkLink(lw)) : []
        this.link_contacts = obj.link_contacts ? obj.link_contacts.map(lc => new DtoStationContacts(lc)) : []
        this.link_pointPrels = obj.link_pointPrels ? obj.link_pointPrels.map(lw => new DtoPointPrel(lw)) : []
        this.link_tempRefs = obj.link_tempRefs ? obj.link_tempRefs.map(tr => new DtoTempRef(tr)) : []
        this.location = obj.address
        this.typeName = 'pluviometry'
        this.jobExecutionId = obj.jobId
    }
}
