import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import MessageCard from '../../../components/card/MessageCard'
import Card from '../../../components/card/Card'
import { Grid } from '@mui/material'
import Input from '../../../components/forms/Input'
import ColorPicker from '../../../components/forms/ColorPicker'
import i18n from 'simple-react-i18n'
import Select from '../../../components/forms/Select'
import { MODEL_OBJECTIVES_LABEL } from '../../../iaeau/constants/IAEauConstants'
import NumberField from '../../../components/forms/NumberField'
import Checkbox from '../../../components/forms/Checkbox'
import IAEauAction from '../../../iaeau/IAEauAction'
import HomeAction from '../../../home/actions/HomeAction'
import { execByType, getStationTitle } from '../../../utils/StationUtils'
import { StyledFieldSet, StyledLegend } from '../../../components/StyledElements'
import RadioButtons from '../../../components/forms/RadioButtons'
import SimpleDatePicker from '../../../components/forms/SimpleDatePicker'
import { getLabel } from '../../../utils/StoreUtils'
import { hasValue } from '../../../utils/NumberUtil'
import useActions from '../../../utils/customHook/useActions'
import Textarea from '../../../components/forms/Textarea'
import Row from '../../../components/react/Row'
import { useParams } from 'react-router'

const IAEauModelApp = ({
    stationType,
}) => {
    const dispatch = useDispatch()
    const { modelId, id } = useParams()

    const {
        iaeauModels,
        piezometer,
        hydrometricStation,
    } = useSelector(store => ({
        iaeauModels: store.IAEauReducer.iaeauModels,
        piezometer: store.StationReducer.piezometer,
        hydrometricStation: store.HydrometryReducer.hydrometricStation,
    }), shallowEqual)

    const [selectedModel, setSelectedModel] = useState({})
    const [nbTitleClick, setNbTitleClick] = useState(0)
    const changeModel = (changes) => setSelectedModel({ ...selectedModel, ...changes })

    useActions(() => {
        return {
            save: () => {
                // if (selectedModel.calculationMode === 'period' && moment(selectedModel.simulationDate || moment().valueOf()).add(30, 'days').valueOf() < (selectedModel.simulationEndDate || moment().valueOf())) {
                //     dispatch(ToastrAction.error('Le calcul sur une période ne peut pas excéder 30 jours'))
                // } else {
                IAEauAction.updateModel(stationType, parseInt(id), selectedModel).then(() => {
                    dispatch(IAEauAction.getModels(stationType, parseInt(id)))
                })
                // }
            },
        }
    }, [selectedModel])

    useEffect(() => {
        if (selectedModel) {
            const station = execByType(stationType, {
                piezometry: () => piezometer,
                hydrometry: () => hydrometricStation,
            })
            dispatch(HomeAction.setTitle([{
                title: i18n[stationType],
                href: `${stationType}`,
            }, {
                title: getStationTitle(station),
                href: `station/${stationType}/${station.id}`,
            }, {
                title: i18n.models,
                href: `station/${stationType}/${station.id}/iaeauModels`,
            }, {
                title: `${selectedModel.name} [${selectedModel.typeModel}]`,
                href: `station/${stationType}/${station.id}/iaeauModels/${selectedModel.idModel}`,
            }]))
        }
    }, [selectedModel])

    useEffect(() => {
        if (iaeauModels.length) {
            setSelectedModel(iaeauModels.find(m => m.idModel === parseInt(modelId)))
        } else {
            dispatch(IAEauAction.getModels(stationType, parseInt(id)))
        }
    }, [iaeauModels])

    if (!selectedModel) {
        return <MessageCard>Modèle introuvable</MessageCard>
    }
    const objectives = Object.keys(MODEL_OBJECTIVES_LABEL).map(o => ({ value: o, label: i18n[MODEL_OBJECTIVES_LABEL[o]] }))
    const horizonModes = [{ value: 'days', label: 'Jours' }, { value: 'weeks', label: 'Semaine' }, { value: 'months', label: 'Mois' }]

    return (
        <div className='padding-top-1 padding-left-1 padding-right-1'>
            <Card title={`${selectedModel.name} [${selectedModel.typeModel}]`} onClickTitle={() => setNbTitleClick(nbTitleClick+1)}>
                <div className='padding-top-1 padding-left-1 padding-right-1'>
                    <Grid container justifyContent='center' alignItems='center' spacing={3}>
                        <Grid item xs={6}>
                            <StyledFieldSet>
                                <StyledLegend>Généralités</StyledLegend>
                                <div className='padding-top-1 padding-left-1 padding-right-1'>
                                    <Grid container justifyContent='center' alignItems='center' spacing={3}>
                                        <Grid item xs={4}>
                                            <Input value={selectedModel.name} onChange={v => changeModel({ name: v }) } title={'Nom du modèle'} />
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Input value={selectedModel.typeModel} onChange={() => {}} title={'Type'} disabled/>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Select value={selectedModel.objective} options={objectives} onChange={() => {}} label={'Objectif'} disabled/>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Input value={selectedModel.chainCode} onChange={v => changeModel({ chainCode: v }) } title={'Code chaine (Pour AQUASYS)'} />
                                        </Grid>
                                    </Grid>
                                    <Grid container justifyContent='center' alignItems='center' spacing={3}>
                                        <Grid item xs={3}>
                                            <ColorPicker
                                                label={i18n.color}
                                                value={selectedModel.color}
                                                onChange={v => changeModel({ color: v })}
                                                style={{
                                                    cursor: 'pointer',
                                                    backgroundColor: selectedModel.color,
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Select
                                                value={ selectedModel.lineType || 'dashed' }
                                                options={ [{ value: 'solid', label: 'Plein' }, { value: 'dashed', label: 'Traits pointillés' }, { value: 'dotted', label: 'Pointillés' }] }
                                                label={ i18n.lineType }
                                                onChange={v => changeModel({ lineType: v })}
                                                noNullValue
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <NumberField
                                                title={i18n.lineWidth}
                                                value={selectedModel.lineWidth || 2 }
                                                onChange={v => changeModel({ lineWidth: v })}
                                                min={1}
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <NumberField
                                                title={i18n.lineOpacity}
                                                value={hasValue(selectedModel.lineOpacity) ? selectedModel.lineOpacity : 1 }
                                                onChange={v => changeModel({ lineOpacity: v })}
                                                min={0}
                                                max={1}
                                                floatValue
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container justifyContent='center' alignItems='center' spacing={3}>
                                        <Grid item xs={6}>
                                            <Checkbox checked={selectedModel.displayDashboard} onChange={v => changeModel({ displayDashboard: v }) } label={'Utiliser sur le tableau de bord'} />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Checkbox checked={selectedModel.displaySituation} onChange={v => changeModel({ displaySituation: v }) } label={'Utiliser sur les cartes de situation'} />
                                        </Grid>
                                    </Grid>
                                    <Grid container justifyContent='center' alignItems='center' spacing={3}>
                                        <Grid item xs={6}>
                                            <Checkbox checked={selectedModel.displayAlert} onChange={v => changeModel({ displayAlert: v }) } label={'Utiliser sur les traitements d\'alertes'} />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Checkbox checked={selectedModel.displaySuivi} onChange={v => changeModel({ displaySuivi: v }) } label={'Utiliser sur le suivi graphique'} />
                                        </Grid>
                                    </Grid>
                                </div>
                            </StyledFieldSet>
                        </Grid>
                        <Grid item xs={6}>
                            <StyledFieldSet>
                                <StyledLegend>Modes de calculs</StyledLegend>
                                <div className='padding-top-2'/>
                                <Grid container justifyContent='center' alignItems='center' spacing={3}>
                                    <RadioButtons
                                        elements={[
                                            { code: 'auto', name: i18n.allDays },
                                            { code: 'now', name: i18n.today },
                                            { code: 'date', name: i18n.chooseDate },
                                            { code: 'period', name: i18n.periode },
                                            { code: 'lastMeasure', name: i18n.lastMeasure },
                                        ]}
                                        selected={selectedModel.calculationMode || 'now' }
                                        onChange={v => changeModel({ calculationMode: v })}
                                        title={i18n.calculType}
                                    />
                                </Grid>
                                <div className='padding-top-2'/>
                                <Grid container justifyContent='center' alignItems='center' spacing={3}>
                                    <Grid item xs={6}>
                                        <SimpleDatePicker
                                            label={selectedModel.calculationMode === 'period' ? i18n.startDate : i18n.date}
                                            value={selectedModel.simulationDate}
                                            onChange={date => changeModel({ simulationDate: date })}
                                            disabled={!['period', 'date'].includes(selectedModel.calculationMode || 'now')}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <SimpleDatePicker
                                            label={i18n.endDate}
                                            value={selectedModel.simulationEndDate}
                                            onChange={date => changeModel({ simulationEndDate: date })}
                                            disabled={selectedModel.calculationMode !== 'period'}
                                        />
                                    </Grid>
                                </Grid>
                                <div className='padding-top-2'/>
                                <Grid container justifyContent='center' alignItems='center' spacing={3}>
                                    <Grid item xs={4}>
                                        <NumberField value={selectedModel.horizon} onChange={v => changeModel({ horizon: v }) } title={`Horizon (${getLabel(horizonModes, selectedModel.horizonMode || 'day', 'label', 'value')})`} disabled/>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Select value={selectedModel.horizonMode || 'days'} onChange={v => changeModel({ horizonMode: v }) } label={'Pas de temps horizon'}
                                            options={ horizonModes } disabled
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Select value={selectedModel.recurPeriod || 'days'} onChange={v => changeModel({ recurPeriod: v }) } label={'Récurrence pour le calcul périodique'}
                                            options={ horizonModes }
                                        />
                                    </Grid>
                                </Grid>
                            </StyledFieldSet>
                        </Grid>
                    </Grid>
                </div>
            </Card>
            {
                nbTitleClick >= 5 ? (
                    <Card title='Paramètres' >
                        <Row>
                            <Textarea col={12} value={selectedModel.params} onChange={v => changeModel({ params: v }) } />
                        </Row>
                    </Card>
                ) : null
            }
        </div>
    )
}

IAEauModelApp.propTypes = {
    params: PropTypes.shape({
        modelId: PropTypes.string,
    }),
    stationType: PropTypes.string,
    id: PropTypes.string,
    modelId: PropTypes.string,
}

export default IAEauModelApp