import Checkbox from 'components/forms/Checkbox'
import Select from 'components/forms/Select'
import SimpleDatePicker from 'components/forms/SimpleDatePicker'
import SelectionSelect from 'components/forms/specific/SelectionSelect'
import ThresholdSelect from 'components/forms/specific/ThresholdSelect'
import SuperMultiAutocomplete from 'components/forms/SuperMultiAutocomplete'
import CollapseTopBar from 'components/topBar/CollapseToBar'
import { intersectionWith, uniqBy } from 'lodash'
import PropTypes from 'prop-types'
import DtoAnalysisLight from 'quality/dto/analyse/DtoAnalysisLight'
import DtoOperation from 'quality/dto/operation/DtoOperation'
import React, { useMemo, useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import MultiContributorsAutocomplete from 'referencial/components/contributor/components/MultiContributorsAutocomplete'
import i18n from 'simple-react-i18n'
import { hasValue } from 'utils/NumberUtil'
import { getQualificationSelectOptions, getStatusSelectOptions } from 'utils/StatusUtil'

const DEFAULT_SELECTION = '-1'

const PcMonitoringFilter = ({
    onValidate = () => { },
    analysis = [],
    operations = [],
    defaultFilter = {},
    ids = [],
}) => {
    const {
        parametersProps,
        parameterGroupUsage,
        qualitometers,
        contributors,
        supports,
        fractions,
    } = useSelector(store => ({
        parametersProps: store.ParameterReducer.parameters,
        parameterGroupUsage: store.ParameterReducer.parameterGroupUsage,
        qualitometers: store.QualityReducer.qualitometersLight,
        contributors: store.ContributorReducer.contributors,
        supports: store.SupportReducer.supports,
        fractions: store.FractionReducer.fractions,
    }), shallowEqual)

    const [startDate, setStartDate] = useState()
    const [endDate, setEndDate] = useState()
    const [parameters, setParameters] = useState()
    const [selection, setSelection] = useState(defaultFilter.selection ?? DEFAULT_SELECTION)
    const [selectionResults, setSelectionResults] = useState([])
    const [group, setGroup] = useState()
    const [threshold, setThreshold] = useState()
    const [qualitometer, setQualitometer] = useState()

    const [producers, setProducers] = useState()
    const [laboratories, setLaboratories] = useState()
    const [point, setPoint] = useState()
    const [support, setSupport] = useState()
    const [fraction, setFraction] = useState()
    const [quantificationControl, setQuantificationControl] = useState()
    const [detectionControl, setDetectionControl] = useState()
    const [groupEquivalences, setGroupEquivalences] = useState(defaultFilter.groupEquivalences)
    const [status, setStatus] = useState()
    const [qualification, setQualification] = useState()

    const groupList = useMemo(() => uniqBy(parameterGroupUsage, 'groupCode'), [parameterGroupUsage])

    const statusList = useMemo(getStatusSelectOptions, [])

    const qualificationList = useMemo(getQualificationSelectOptions, [])

    const qualitometerList = useMemo(() => ids.map(id => qualitometers.find(q => q.id === id)).filter(q => !!q).map(q => ({
        id: q.id,
        name: `${q.name} [${q.code}]`,
    })), [qualitometers, ids])

    const producerList = useMemo(() => {
        const uniqByProducer = uniqBy(operations, 'producer')
        const contributorList = uniqByProducer.map(obj => hasValue(obj.producer) && contributors.find(p => p.id === obj.producer))
        return contributorList.filter(c => !!c)
    }, [operations, contributors])

    const laboList = useMemo(() => {
        const uniqByProducer = uniqBy(analysis, 'labo')
        const contributorList = uniqByProducer.map(obj => hasValue(obj.labo) && contributors.find(p => p.id === obj.labo))
        return contributorList.filter(l => !!l)
    }, [analysis, contributors])

    return (
        <div style={{ margin: '0 20px 10px 20px' }}>
            <div style={{ backgroundColor: '#d9dfe4', borderLeft: '2px solid #161832', borderRight: '2px solid #161832', padding: '10px 0 5px 0' }}>
                <div className='row no-margin'>
                    <SimpleDatePicker
                        id='startDate'
                        label={i18n.startDate}
                        value={startDate}
                        onChange={setStartDate}
                        col={2}
                        max={endDate}
                    />
                    <SimpleDatePicker
                        id='endDate'
                        label={i18n.endDate}
                        value={endDate}
                        onChange={setEndDate}
                        col={2}
                        min={startDate}
                    />
                    <SelectionSelect
                        col={4}
                        value={selection}
                        onChange={(results, value) => {
                            setSelection(value)
                            setSelectionResults(results)
                        }}
                    />
                    <SuperMultiAutocomplete
                        col={2}
                        label={i18n.parameters}
                        options={selection !== '-1' ? intersectionWith(parametersProps, selectionResults, (p, code) => code === p.code) : parametersProps}
                        values={parameters}
                        onChange={setParameters}
                        keyLabel='labelWithCode'
                        keyValue='code'
                        multiple
                    />
                    <Select
                        col={2}
                        label={i18n.group}
                        options={groupList}
                        value={group}
                        onChange={setGroup}
                        clearFunction
                        keyValue='groupCode'
                        keyLabel='labelWithCode'
                    />
                </div>
                <div className='row no-margin padding-top-1'>
                    <ThresholdSelect
                        col={4}
                        selected={threshold}
                        onChange={setThreshold}
                        nullLabel='&nbsp;'
                    />
                    <Select
                        col={4}
                        label={i18n.qualitometer}
                        options={qualitometerList}
                        value={qualitometer}
                        onChange={setQualitometer}
                        keyValue='id'
                        clearFunction
                    />
                    <div className='col s4 margin-top-1'>
                        <a
                            className='col s12 btn'
                            onClick={() => onValidate({
                                startDate,
                                endDate,
                                parameters,
                                selection,
                                selectionResults,
                                group,
                                threshold,
                                qualitometer,

                                producers,
                                laboratories,
                                point,
                                support,
                                fraction,
                                quantificationControl,
                                detectionControl,
                                groupEquivalences,
                                status,
                                qualification,
                            })}
                        >
                            {i18n.visualize}
                        </a>
                    </div>
                </div>
            </div>
            <CollapseTopBar>
                <div style={{ backgroundColor: '#d9dfe4', borderLeft: '2px solid #161832', borderRight: '2px solid #161832' }}>
                    <div className='row no-margin padding-top-1'>
                        <MultiContributorsAutocomplete // mnemonique
                            col={4}
                            options={producerList}
                            label={i18n.producer}
                            keyLabel='labelDisplay'
                            keyValue='code'
                            onChange={setProducers}
                            multiple
                        />
                        <MultiContributorsAutocomplete
                            col={4}
                            options={laboList}
                            label={i18n.laboratory}
                            keyLabel='labelDisplay'
                            keyValue='code'
                            onChange={setLaboratories}
                            multiple
                        />
                        <Select
                            col={4}
                            options={[]}
                            value={point}
                            label={i18n.samplePoint}
                            keyLabel='labelWithCode'
                            keyValue='idPoint'
                            integerValue
                            disabled
                            onChange={setPoint}
                        />
                    </div>
                    <div className='row no-margin padding-top-1'>
                        <Select
                            col={3}
                            label={i18n.support}
                            options={supports}
                            value={support}
                            onChange={setSupport}
                            keyValue='id'
                            clearFunction
                            displayWithCode
                        />
                        <Select
                            col={3}
                            label={i18n.fraction}
                            options={fractions}
                            value={fraction}
                            onChange={setFraction}
                            keyValue='id'
                            clearFunction
                            displayWithCode
                        />
                        <Select
                            col={3}
                            options={statusList}
                            value={status}
                            label={i18n.status}
                            keyLabel='name'
                            keyValue='id'
                            onChange={setStatus}
                            noSort
                        />
                        <Select
                            col={3}
                            options={qualificationList}
                            value={qualification}
                            label={i18n.qualification}
                            keyLabel='name'
                            keyValue='id'
                            onChange={setQualification}
                            noSort
                        />
                    </div>

                    <div className='row no-margin padding-top-1 padding-bottom-1'>
                        <Checkbox
                            col={3}
                            label={i18n.quantificationControl}
                            checked={quantificationControl}
                            onChange={setQuantificationControl}
                        />
                        <Checkbox
                            col={3}
                            label={i18n.detectionControl}
                            checked={detectionControl}
                            onChange={setDetectionControl}
                        />
                        <Checkbox
                            col={3}
                            label={i18n.groupEquivalences}
                            checked={groupEquivalences}
                            onChange={setGroupEquivalences}
                        />
                        {/* <Checkbox
                            col={3}
                            label={i18n.displayAdvancedStatistics}
                            // checked={displayAdvancedStatistics}
                            onChange={(e) => this.setState({ displayAdvancedStatistics: e })}
                        /> */}
                    </div>
                </div>
            </CollapseTopBar>
        </div>
    )
}

PcMonitoringFilter.propTypes = {
    onValidate: PropTypes.func,
    ids: PropTypes.arrayOf(PropTypes.number),
    analysis: PropTypes.arrayOf(PropTypes.instanceOf(DtoAnalysisLight)),
    operations: PropTypes.arrayOf(PropTypes.instanceOf(DtoOperation)),
    defaultFilter: PropTypes.shape({
        groupEquivalences: PropTypes.bool,
    }),
}

export default PcMonitoringFilter