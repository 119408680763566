import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import React, { useEffect } from 'react'
import Row from '../../../../components/react/Row'
import ImageCard from '../../../../components/card/ImageCard'
import PropTypes from 'prop-types'
import { instanceOf } from '../../../../utils/StoreUtils'
import DtoIAEauModel from '../../../../iaeau/dto/DtoIAEauModel'
import InDevelopmentBackground from '../../../../inDevelop/components/InDevelopmentBackground'
import i18n from 'simple-react-i18n'
import { Grid } from '@mui/material'
import NumberField from '../../../../components/forms/NumberField'
import Select from '../../../../components/forms/Select'

const ModelStepHorizon = ({
    stationType,
    id, // station id
    selectedModel,
    changeModel,
}) => {
    const dispatch = useDispatch()

    const {

    } = useSelector(store => ({

    }), shallowEqual)

    // const [isOpen, setIsOpen] = useState(false)

    useEffect(() => {

    }, [])

    const horizonModes = [{ value: 'days', label: 'Jours' }, { value: 'weeks', label: 'Semaine' }, { value: 'months', label: 'Mois' }]

    return (
        <div className='padding-top-2'>
            <Grid container justifyContent='center' alignItems='center' spacing={3}>
                <h5>Quel est la méthode de prévision ?</h5>
            </Grid>
            <Grid container justifyContent='center' alignItems='center' spacing={3}>
                <Grid item xs={4}>
                    <ImageCard
                        title='Définir une période'
                        description='Je définis un nombre de jours, de mois, ou de semaines de prévision spécifique à mon modèle.'
                        maxWidth={400}
                        active={!!selectedModel.horizon && !selectedModel.idScenario}
                        onClick={() => changeModel({ horizon: 10, idScenario: null })}
                    />
                </Grid>
                <Grid item xs={4}>
                    <ImageCard
                        title='Simuler un scénario'
                        description='Utiliser des données observées en tant que simulation des données prédites.'
                        maxWidth={400}
                        active={!!selectedModel.idScenario && selectedModel.idScenario > 0}
                        onClick={() => changeModel({ horizon: null, idScenario: 1 })}
                    />
                </Grid>
                <Grid item xs={4}>
                    <ImageCard
                        title='Utiliser un modèle pré-défini'
                        description='Utiliser un modèle déjà prêt avec un horizon de prévision déjà fixé.'
                        maxWidth={400}
                        active={!!selectedModel.idScenario && selectedModel.idScenario === -1}
                        onClick={() => changeModel({ horizon: null, idScenario: -1 })}
                    />
                </Grid>
            </Grid>
            <Row>
                { !!selectedModel.idScenario && selectedModel.idScenario > 0 && <InDevelopmentBackground /> }
                { !!selectedModel.horizon && (
                    <div>
                        <Grid container justifyContent='center' alignItems='center' spacing={3}>
                            <Grid item xs={8}>
                                <h5 style={{ textAlign: 'center' }}>{'Quelle horizon de prévision souhaitez vous ?'}</h5>
                            </Grid>
                        </Grid>
                        <Grid container justifyContent='center' alignItems='center' spacing={3}>
                            <Grid item xs={4}>
                                <NumberField value={selectedModel.horizon} onChange={v => changeModel({ horizon: v })}/>
                            </Grid>
                            <Grid item xs={4}>
                                <Select value={selectedModel.horizonMode || 'days'} onChange={v => changeModel({ horizonMode: v }) }
                                    options={ horizonModes }
                                />
                            </Grid>
                        </Grid>
                    </div>
                ) }
            </Row>
        </div>
    )
}

ModelStepHorizon.propTypes = {
    stationType: PropTypes.string,
    id: PropTypes.string,
    selectedModel: instanceOf(DtoIAEauModel),
    setSelectedModel: PropTypes.func,
}

export default ModelStepHorizon