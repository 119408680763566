export default class DtoFilterParam {
    constructor(obj) {
        this.module = obj.module // Option[String],
        this.codeList = obj.codeList // Option[String],
        this.order = obj.order // Option[Long],
        this.filterResult = obj.filterResult // Option[Boolean],
        this.variableName = obj.variableName // Option[String],
        this.parameterName = obj.parameterName // Option[String],
        this.filterType = obj.filterType // Option[Long],
        this.sandreCode = obj.sandreCode // Option[Long],
        this.subRequest = obj.subRequest // Option[String],
        this.value = obj.value // Option[String]
    }
}
