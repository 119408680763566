import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import Checkbox from '../../../components/forms/Checkbox'
import { getI18nTitleData, searchAllCharacters } from '../../../utils/StringUtil'
import CartographyThemeDto from '../../dto/CartographyThemeDto'
import UserAction from '../user/actions/UserAction'
import UserDto from '../user/dto/UserDto'
import SelectionTableModal from 'components/modal/SelectionTableModal'
import Input from '../../../components/forms/Input'
import { uniq } from 'lodash'

const headers = ['login', 'admin']

const headersUser = ['login', 'name', 'mail', 'mobile', 'tel', 'admin', 'metadata', 'organism', 'lastConnexion']

class LayerRightsModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            selectedUsers: props.selectedUsers.slice(),
            searchValue: '',
        }
    }

    getUsers = () => {
        const { users, applicativeUsers } = this.props
        return uniq([...users, ...applicativeUsers]).map((u) => {
            return {
                ...u,
                admin: u.admin === '1' ? (<Checkbox col={12} checked disabled />) : '',
                id: u.login,
            }
        })
    }

    getSelectedUsers = () => {
        const { selectedUsers: selectedUsersState } = this.state
        const { users, applicativeUsers } = this.props
        const allUsers = uniq([...users, ...applicativeUsers])
        const selectedUsers = selectedUsersState.map((login) => {
            const userFind = allUsers.find((u) => u.login === login) || {}
            return {
                ...userFind,
                admin: userFind.admin === '1' ? (<Checkbox col={12} checked disabled />) : '',
                id: userFind.login,
            }
        })
        return selectedUsers.map(({ id }) => id)
    }

    onValidate = (selectedUsers) => {
        this.props.assignThemeToUsers(this.props.theme.id, selectedUsers).then(() => {
            this.props.toggleModal()
        })
    }

    render() {
        const { open, theme, usersLoaded } = this.props

        const listData = this.getUsers() || []
        const selectedData = this.getSelectedUsers() || []

        return (
            <SelectionTableModal
                isOpen={open}
                onClose={this.props.toggleModal}
                onValidate={this.onValidate}
                title={theme.name}

                listData={listData}
                listHeaders={headers}
                listTitle={getI18nTitleData(i18n.user, i18n.users, listData)}

                defaultSelectionList={selectedData}
                selectionListHeaders={headers}
                selectionListTitle={getI18nTitleData(i18n.selectedUser, i18n.selectedUsers, selectedData)}

                maxHeightTable={'35vh'}
                titleBackgroundColor={theme?.color}
                color={'white'}
                openProgressBar={usersLoaded}
                filterField={({
                    filter,
                    setFilter,
                }) => (
                    <div className='row no-margin' style={{ padding: '10px 0px' }}>
                        <Input
                            col={6}
                            title={i18n.search}
                            value={filter.searchValue}
                            onChange={searchValue => setFilter(prev => ({ ...prev, searchValue }))}
                        />
                    </div>
                )}
                filterFunction={(list, { searchValue }) => {
                    const searchValueFormat = searchAllCharacters(searchValue)
                    return searchValue ? list.filter(p => searchAllCharacters(headersUser.map(key => p[key])).includes(searchValueFormat)) : list
                }}
            />
        )
    }
}

LayerRightsModal.propTypes = {
    selectedUsers: PropTypes.arrayOf(PropTypes.string),
    users: PropTypes.arrayOf(PropTypes.instanceOf(UserDto)),
    applicativeUsers: PropTypes.arrayOf(PropTypes.instanceOf(UserDto)),
    theme: PropTypes.instanceOf(CartographyThemeDto),
    assignThemeToUsers: PropTypes.func,
    toggleModal: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    usersLoaded: PropTypes.bool,
}

const mapStateToProps = store => ({
    users: store.UserReducer.users,
    applicativeUsers: store.UserReducer.applicativeUsers,
})

const mapDispatchToProps = {
    assignThemeToUsers: UserAction.assignThemeToUsers,
}

export default connect(mapStateToProps, mapDispatchToProps)(LayerRightsModal)
