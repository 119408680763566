import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import Input from '../../../../components/forms/Input'
import NumberField from '../../../../components/forms/NumberField'
import Select from '../../../../components/forms/Select'
import Textarea from '../../../../components/forms/Textarea'
import Row from '../../../../components/react/Row'
import DtoAltimetrySystem from '../../../../piezometry/dto/DtoAltimetrySystem'
import { SANDRE } from '../../../../referencial/constants/ReferencialConstants'
import DtoSandreCode from '../../../../referencial/dto/DtoSandreCode'
import { getHour } from '../../../../utils/DateUtil'
import { onChangeHour } from '../../../../utils/FormUtils'
import { getSandreList } from '../../../../utils/StoreUtils'
import SimpleDatePicker from 'components/forms/SimpleDatePicker'

const AltimetrySystemModal = ({
    altimetrySystem = {},
    sandreCodes = [],
    onChange = () => { },
}) => (
    <div style={{ minHeight: '55vh' }}>
        <Row>
            <SimpleDatePicker
                col={6}
                value={altimetrySystem.startDate}
                label={i18n.startDate}
                onChange={v => onChange({ startDate: v })}
                max={altimetrySystem.endDate}
            />
            <Input col={ 6 } title={ i18n.startHour } value={ getHour(altimetrySystem.startDate) }
                onChange={ v => onChangeHour(v, v2 => onChange({ startDate: v2 }), { max: altimetrySystem.endDate }, altimetrySystem.startDate) }
            />
        </Row>
        <Row>
            <SimpleDatePicker
                col={6}
                value={altimetrySystem.endDate}
                label={i18n.endDate}
                onChange={v => onChange({ endDate: v })}
                min={altimetrySystem.startDate}
            />
            <Input col={ 6 } title={ i18n.endHour } value={ getHour(altimetrySystem.endDate) }
                onChange={ v => onChangeHour(v, v2 => onChange({ endDate: v2 }), { min: altimetrySystem.startDate }, altimetrySystem.endDate) }
            />
        </Row>
        <div className='row no-margin padding-top-1'>
            <Select
                col={6}
                options={getSandreList(sandreCodes, SANDRE.ALTIMETRIC_SYSTEM_NATURE)}
                label={i18n.nature}
                value={altimetrySystem.natureCode}
                onChange={v => onChange({ natureCode: v })}
            />
            <NumberField
                col={6}
                value={altimetrySystem.altitude}
                title={i18n.altitude}
                onChange={v => onChange({ altitude: v })}
                floatValue
            />
        </div>
        <div className='row no-margin padding-top-1'>
            <Select
                col={6}
                options={getSandreList(sandreCodes, SANDRE.ALTIMETRIC_SYSTEM)}
                keyLabel='name'
                label={i18n.altimetrySystems}
                value={altimetrySystem.altimetrySystem}
                onChange={v => onChange({ altimetrySystem: v })}
            />
            <Select
                col={6}
                options={getSandreList(sandreCodes, SANDRE.ALTIMETRIC_SYSTEM_OBTENTION)}
                label={i18n.productionMode}
                value={altimetrySystem.productionMode}
                onChange={v => onChange({ productionMode: v })}
            />
        </div>
        <div className='row no-margin padding-top-1'>
            <Select
                col={6}ALTIMETRIC_SYSTEM_PRECISION
                options={getSandreList(sandreCodes, SANDRE.ALTIMETRIC_SYSTEM_PRECISION)}
                label={i18n.precision}
                value={altimetrySystem.precision}
                onChange={v => onChange({ precision: v })}
            />
        </div>
        <div className='row no-margin padding-top-1'>
            <Textarea
                col={12}
                title={i18n.comment}
                value={altimetrySystem.comment}
                onChange={v => onChange({ comment: v })}
            />
        </div>
    </div>
)

AltimetrySystemModal.propTypes = {
    altimetrySystem: PropTypes.instanceOf(DtoAltimetrySystem),
    onChange: PropTypes.func,
    sandreCodes: PropTypes.arrayOf(PropTypes.instanceOf(DtoSandreCode)),
}

const mapStateToProps = store => ({
    sandreCodes: store.ReferencialReducer.sandreCodes,
})

export default connect(mapStateToProps)(AltimetrySystemModal)
