/* eslint-disable camelcase */
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import Table from '../../../../../components/datatable/Table'
import SieauAction from '../../../../../components/sieau/SieauAction'
import DtoDistributionUnit from '../../../../../distributionUnit/dto/DtoDistributionUnit'
import DtoHydrometricStation from '../../../../../hydrometry/dto/DtoHydrometricStation'
import DtoPiezometer from '../../../../../piezometry/dto/DtoPiezometer'
import DtoProductionUnit from '../../../../../productionUnit/dto/DtoProductionUnit'
import DtoQualitometer from '../../../../../quality/dto/DtoQualitometer'
import DtoAquifer from '../../../../../referencial/components/aquifers/dto/DtoAquifer'
import { SANDRE } from '../../../../../referencial/constants/ReferencialConstants'
import DtoSandreCode from '../../../../../referencial/dto/DtoSandreCode'
import { getExport, setModal } from '../../../../../utils/linkUtils'
import { arrayOf, getLabel } from '../../../../../utils/StoreUtils'
import { getSandreLabel } from '../../../../../utils/StringUtil'
import DtoInstallation from '../../../../dto/installation/DtoInstallation'
import DtoBoreholeCasing from '../dto/DtoBoreholeCasing'
import BoreholeCasingModal from './BoreholeCasingModal'

const BoreholeCasingTable = ({
    station = {},
    readMode = false,
    onChange = () => {},
    setPopup = () => {},
    aquifers = [],
    sandreCodes = [],
}) => {
    const stationCasings = station.link_casings.map((c, index) => ({
        ...c,
        index,
    }))
    const tableData = stationCasings.map(c => ({
        ...c,
        cementing: c.cementing ? <i className='material-icons tiny table-checkbox'>check_box</i> : null,
        strainers: c.strainers ? <i className='material-icons tiny table-checkbox'>check_box</i> : null,
        diameterSymbol: c.internalCasingDiameter,
        depth: c.startDepth,
        aquifer: getLabel(aquifers, c.aquiferCode),
        casingMaterial: getSandreLabel(sandreCodes, SANDRE.CASING_MATERIAL, c.materialCode),
    }))
    const headers = new DtoBoreholeCasing({}).headers
    const exportAction = getExport(tableData, i18n.casings, headers)
    const actions = !readMode ? [{
        iconName: 'note_add',
        tooltip: i18n.add,
        onClick: () => setModal(i18n.casings, <BoreholeCasingModal id={ station.id } saveResult={ r => onChange({ link_casings: [...stationCasings, r] }) }/>, setPopup),
    }, exportAction] : [exportAction]

    return (
        <Table
            title={ i18n.casings }
            condensed
            data={ tableData }
            type={{ headers }}
            sortable={ !!tableData.length }
            actions={ actions }
            alterable={ !readMode }
            onAlter={
                (element) => setModal(i18n.casings,
                    <BoreholeCasingModal
                        id={ station.id }
                        boreholeCasing={ stationCasings.find(c => element.index === c.index) }
                        saveResult={ r => onChange({
                            link_casings: [
                                ...stationCasings.filter(lc => lc.index !== element.index),
                                { ...r, index: element.index },
                            ],
                        })}
                    />
                    , setPopup)
            }
            deletable={ !readMode }
            onDelete={ (element) => onChange({ link_casings: stationCasings.filter(lc => lc.index !== element.index) }) }
        />
    )
}

BoreholeCasingTable.propTypes = {
    station: PropTypes.oneOfType([
        PropTypes.instanceOf(DtoProductionUnit),
        PropTypes.instanceOf(DtoDistributionUnit),
        PropTypes.instanceOf(DtoQualitometer),
        PropTypes.instanceOf(DtoPiezometer),
        PropTypes.instanceOf(DtoHydrometricStation),
        PropTypes.instanceOf(DtoInstallation),
    ]),
    aquifers: arrayOf(DtoAquifer),
    sandreCodes: arrayOf(DtoSandreCode),
    readMode: PropTypes.bool,
    onChange: PropTypes.func,
    setPopup: PropTypes.func,
}

const mapStateToProps = store => ({
    aquifers: store.AquiferReducer.aquifers,
    sandreCodes: store.ReferencialReducer.sandreCodes,
})

const mapDispatchToProps = {
    setPopup: SieauAction.setPopup,
}

export default connect(mapStateToProps, mapDispatchToProps)(BoreholeCasingTable)