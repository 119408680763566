import PropTypes from 'prop-types'
import { Component } from 'react'
import { connect } from 'react-redux'
import ToastrAction from '../actions/ToastrAction'
import '../toastr_conf.js'


const toastr = window.toastr

class Toastr extends Component {
    constructor(props) {
        super(props)
    }

    componentDidUpdate(prevProps) {
        if (this.props.error && this.props.error != prevProps.error) {
            toastr.error(this.props.error, this.props.title, this.props.option)
            this.props.store.dispatch(ToastrAction.error(''))
        }
        if (this.props.success && this.props.success != prevProps.success) {
            toastr.success(this.props.success, this.props.title, this.props.option)
            this.props.store.dispatch(ToastrAction.success(''))
        }
        if (this.props.warning && this.props.warning != prevProps.warning) {
            toastr.warning(this.props.warning, this.props.title, this.props.option)
            this.props.store.dispatch(ToastrAction.warning(''))
        }
        if (this.props.info && this.props.info != prevProps.info) {
            toastr.info(this.props.info, this.props.title, this.props.option)
            this.props.store.dispatch(ToastrAction.info(''))
        }
    }

    render() {
        return null
    }
}

Toastr.propTypes = {
    error: PropTypes.string,
    success: PropTypes.string,
    warning: PropTypes.string,
    info: PropTypes.string,
    option: PropTypes.object,
    title: PropTypes.string,
    store: PropTypes.object.isRequired,
}

const mapStateToProps = store => ({
    error: store.ToastrReducer.error,
    success: store.ToastrReducer.success,
    warning: store.ToastrReducer.warning,
    info: store.ToastrReducer.info,
    option: store.ToastrReducer.option,
    title: store.ToastrReducer.title,
})

export default connect(mapStateToProps)(Toastr)
