import Layers from 'utils/mapUtils/ILayers'
import Tile from 'ol/layer/Tile'
import * as olSource from 'ol/source'

class _OSM extends Layers {
    constructor() {
        super()
        this.layer = this.createLayer()
    }


    createLayer() {
        return new Tile({
            source: new olSource.OSM(),
        })
    }
    getLayer() {
        return this.layer
    }
}

export default function OSM(...args) {
    return new _OSM(...args)
}
