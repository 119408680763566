export default class DtoFlowObstructionLine {
    constructor(obj) {
        this.id = obj[0] // Int,
        this.name = obj[1] // Option[String],
        this.bief = obj[2] // Option[String],
        this.townName = obj[3] // Option[String],
        this.nature = obj[4] // Option[String],
        this.remoteManaged = obj[5] // Option[Boolean],
        this.inUse = obj[6] // Option[Boolean],
    }
}