import { createAsyncThunk } from '@reduxjs/toolkit'
import { H_PIEZO_MODULE, H_QUALITO_MODULE } from 'account/constants/AccessRulesConstants'
import ApplicationConf from 'conf/ApplicationConf'
import LogAction from 'log/actions/LogAction'
import i18n from 'simple-react-i18n'
import { STATION_TYPE_NAME } from 'station/constants/StationConstants'
import { REJECTED, IDLE } from 'store/DataManagerConstants'
import ToastrAction from 'toastr/actions/ToastrAction'
import { genericPromise } from 'utils/ActionUtils'
import { isAuthorized } from 'utils/HabilitationUtil'

const CampaignThunk = {
    fetchQualityCampaigns: createAsyncThunk(
        'fetchQualityCampaigns',
        async (_, { rejectWithValue, dispatch }) => {
            try {
                const response = await genericPromise(ApplicationConf.campaign.campaigns(STATION_TYPE_NAME.quality), 'GET')
                return response
            } catch (err) {
                dispatch(LogAction.logError(`${i18n.fetchError} ${i18n.campaigns} : ${err}`))
                dispatch(ToastrAction.error(`${i18n.fetchError} ${i18n.campaigns}`))
                return rejectWithValue(err)
            }
        },
        {
            condition: (arg = { forceFetch: false }, { getState }) => {
                const state = getState()
                if (!isAuthorized(H_QUALITO_MODULE, state)) {
                    return false
                }
                const { qualityCampaignsStatus } = state.DataManagerReducer.campaign
                if (arg.forceFetch) {
                    return qualityCampaignsStatus !== REJECTED
                }
                return qualityCampaignsStatus === IDLE
            },
            dispatchConditionRejection: true,
        },
    ),

    fetchQualityCampaignsProgress: createAsyncThunk(
        'fetchQualityCampaignsProgress',
        async (_, { rejectWithValue, dispatch }) => {
            try {
                const response = await genericPromise(ApplicationConf.campaign.campaignsProgress(STATION_TYPE_NAME.quality), 'GET')
                return response
            } catch (err) {
                dispatch(LogAction.logError(`${i18n.fetchError} ${i18n.campaignsProgress} : ${err}`))
                dispatch(ToastrAction.error(`${i18n.fetchError} ${i18n.campaignsProgress}`))
                return rejectWithValue(err)
            }
        },
        {
            condition: (arg = { forceFetch: false }, { getState }) => {
                const state = getState()
                if (!isAuthorized(H_QUALITO_MODULE, state)) {
                    return false
                }
                const { qualityCampaignsProgressStatus } = state.DataManagerReducer.campaign
                if (arg.forceFetch) {
                    return qualityCampaignsProgressStatus !== REJECTED
                }
                return qualityCampaignsProgressStatus === IDLE
            },
            dispatchConditionRejection: true,
        },
    ),

    fetchPiezometryCampaigns: createAsyncThunk(
        'fetchPiezometryCampaigns',
        async (_, { rejectWithValue, dispatch }) => {
            try {
                const response = await genericPromise(ApplicationConf.campaign.campaigns(STATION_TYPE_NAME.piezometry), 'GET')
                return response
            } catch (err) {
                dispatch(LogAction.logError(`${i18n.fetchError} ${i18n.campaigns} : ${err}`))
                dispatch(ToastrAction.error(`${i18n.fetchError} ${i18n.campaigns}`))
                return rejectWithValue(err)
            }
        },
        {
            condition: (arg = { forceFetch: false }, { getState }) => {
                const state = getState()
                if (!isAuthorized(H_PIEZO_MODULE, state)) {
                    return false
                }
                const { piezometryCampaignsStatus } = state.DataManagerReducer.campaign
                if (arg.forceFetch) {
                    return piezometryCampaignsStatus !== REJECTED
                }
                return piezometryCampaignsStatus === IDLE
            },
            dispatchConditionRejection: true,
        },
    ),

    fetchPiezometryCampaignsProgress: createAsyncThunk(
        'fetchPiezometryCampaignsProgress',
        async (_, { rejectWithValue, dispatch }) => {
            try {
                const response = await genericPromise(ApplicationConf.campaign.campaignsProgress(STATION_TYPE_NAME.piezometry), 'GET')
                return response
            } catch (err) {
                dispatch(LogAction.logError(`${i18n.fetchError} ${i18n.campaignsProgress} : ${err}`))
                dispatch(ToastrAction.error(`${i18n.fetchError} ${i18n.campaignsProgress}`))
                return rejectWithValue(err)
            }
        },
        {
            condition: (arg = { forceFetch: false }, { getState }) => {
                const state = getState()
                if (!isAuthorized(H_PIEZO_MODULE, state)) {
                    return false
                }
                const { piezometryCampaignsProgressStatus } = state.DataManagerReducer.campaign
                if (arg.forceFetch) {
                    return piezometryCampaignsProgressStatus !== REJECTED
                }
                return piezometryCampaignsProgressStatus === IDLE
            },
            dispatchConditionRejection: true,
        },
    ),
}

export default CampaignThunk