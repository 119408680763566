import { createAsyncThunk } from '@reduxjs/toolkit'
import { H_PIEZO_MODULE } from 'account/constants/AccessRulesConstants'
import ApplicationConf from 'conf/ApplicationConf'
import { chunk, flatten } from 'lodash'
import LogAction from 'log/actions/LogAction'
import i18n from 'simple-react-i18n'
import { REJECTED, IDLE } from 'store/DataManagerConstants'
import ToastrAction from 'toastr/actions/ToastrAction'
import { genericPromise } from 'utils/ActionUtils'
import { isAuthorized } from 'utils/HabilitationUtil'
import PiezometryAction from './PiezometryAction'

const PiezometryThunk = {
    fetchPiezometersLight: createAsyncThunk(
        'fetchPiezometersLight',
        async (_, { rejectWithValue, dispatch }) => {
            try {
                const response = await genericPromise(`${ApplicationConf.piezometer.getAll()}?lightMode=true`, 'GET')
                return response
            } catch (err) {
                dispatch(LogAction.logError(`${i18n.fetchError} ${i18n.piezometers} : ${err}`))
                dispatch(ToastrAction.error(`${i18n.fetchError} ${i18n.piezometers}`))
                return rejectWithValue(err)
            }
        },
        {
            condition: (arg = { forceFetch: false }, { getState }) => {
                const state = getState()
                if (!isAuthorized(H_PIEZO_MODULE, state)) {
                    return false
                }
                const { piezometersLightStatus } = state.DataManagerReducer.piezometry
                if (arg.forceFetch) {
                    return piezometersLightStatus !== REJECTED
                }
                return piezometersLightStatus === IDLE
            },
            dispatchConditionRejection: true,
        },
    ),

    fetchPiezometryRawSituation: createAsyncThunk(
        'fetchPiezometryRawSituation',
        async (dataObj, { rejectWithValue, dispatch }) => {
            const { criterias, piezometerIds } = dataObj
            try {
                if (!piezometerIds.length) {
                    return []
                }
                const groupsSize = piezometerIds.length / 50 > 20 ? piezometerIds.length / 20 : 50
                const defaultCriterias = {
                    nbDaysAlert: 5,
                    nbDaysUndetected: 3,
                    dataType: -1,
                }
                const promises = chunk(piezometerIds, groupsSize).map(stationsGroup => PiezometryAction.promisePiezometrySituation({ ...defaultCriterias, ...criterias }, stationsGroup, 'type'))
                const response = await Promise.all(promises)
                return flatten(response)
            } catch (err) {
                dispatch(LogAction.logError(`${i18n.dataError} : ${err}`))
                dispatch(ToastrAction.error(`${i18n.dataError}`))
                return rejectWithValue(err)
            }
        },
        {
            condition: (_, { getState }) => {
                const state = getState()
                return isAuthorized(H_PIEZO_MODULE, state)
            },
            dispatchConditionRejection: true,
        },
    ),
}

export default PiezometryThunk