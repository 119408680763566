import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import Job from '../../dto/DtoJob'
import i18n from 'simple-react-i18n'
import cronstrue from 'cronstrue/i18n'
import { template } from 'lodash'
import Select from '../../../components/forms/Select'
import { getCurrentLang } from '../../../utils/LangUtils'
import { hasValue } from '../../../utils/NumberUtil'
import AlertAction from '../../../alerting/actions/AlertAction'
import Card from '../../../components/card/Card'
import Row from '../../../components/react/Row'
import { arrayOf } from '../../../utils/StoreUtils'
import JobChainStepper from './JobChainStepper'
import JobAction from '../../actions/JobAction'
import JobChainGraph from './JobChainGraph'
import { numberList } from 'utils/ListUtil'
import SieauParameterDto from '../../../administration/dto/SieauParameterDto'

const SchedulePanel = ({
    job,
    jobs,
    onChangePlanification,
    onChangeEmails,
    isEditMode,
}) => {
    const {
        applicationSettings,
    } = useSelector(store => ({
        applicationSettings: store.AdministrationReducer.applicationSettings,
    }), shallowEqual)

    const [days, setDays] = useState('1')
    const [hours, setHours] = useState('0')
    const [minutes, setMinutes] = useState('0')
    const [chainStepperIsOpen, setChainStepperIsOpen] = useState(false)
    const [selectedJob, setSelectedJob] = useState({})
    const [chain, setChain] = useState([])
    const [planified, setPlanified] = useState(!!job?.cron)

    const dispatch = useDispatch()

    const hoursOptions = useMemo(() => [ { value: '*', label: i18n.everyHours }, ...numberList(24) ], [])
    const minutesOptions = useMemo(() => [
        { value: '*/5', label: template(i18n.everyCustomMinutes)({ minute: 5 }) },
        { value: '*/10', label: template(i18n.everyCustomMinutes)({ minute: 10 }) },
        { value: '*/15', label: template(i18n.everyCustomMinutes)({ minute: 15 }) },
        { value: '*/20', label: template(i18n.everyCustomMinutes)({ minute: 20 }) },
        { value: '*/30', label: template(i18n.everyCustomMinutes)({ minute: 30 }) },
        ...numberList(60),
    ], [])

    const daysOptions = useMemo(() => [
        { value: '*', label: i18n.allDays },
        { value: '1', label: i18n.monday },
        { value: '2', label: i18n.tuesday },
        { value: '3', label: i18n.wednesday, disabled: true },
        { value: '4', label: i18n.thursday },
        { value: '5', label: i18n.friday },
        { value: '6', label: i18n.saturday },
        { value: '0', label: i18n.sunday },
    ], [])

    const handleChangeMinutes = (newMinutes) => {
        setMinutes(newMinutes)
        if (newMinutes.includes('/')) {
            const value = '*'
            setHours(value)
            setDays(value)
        }
    }

    const handleChangeHours = (newHours) => {
        setHours(newHours)
        if (newHours.includes('/')) {
            const value = '*'
            setDays(value)
        }
    }

    const handleChangeDays = selected => {
        const newDays = (hasValue(selected) && selected != -1) ? selected.toString() : '*'
        setDays(newDays)
    }

    const changePlanification = () => {
        const cron = ['0', minutes, hours, '*', '*', days, '*'].join(' ')
        onChangePlanification(cron)
    }

    const togglePlanification = () => {
        if (planified) {
            changePlanification()
        } else {
            onChangePlanification(null)
        }
    }

    const setCron = () => {
        if (job.cron && job.cron.split(' ').length === 7) {
            const cron = job.cron.split(' ')
            const newDays = cron[5]
            const newHours = cron[2]
            const newMinutes = cron[1] == '*' ? '0' : cron[1]
            setDays(newDays)
            setHours(newHours)
            setMinutes(newMinutes)
        }
    }

    useEffect(() => {
        setCron()
        if (job.id) {
            JobAction.getJobChain(job.id).then(c => setChain(c))
        }
    }, [job.id])

    const handleChangeEmails = e => {
        const emails = e.target.value.split(';')
        onChangeEmails(emails)
    }

    const emails = useMemo(() => job.parameters?.emails?.join(';'), [job.parameters])

    useEffect(() => {
        togglePlanification()
    }, [planified, hours, minutes, days])

    const cronText = useMemo(() => {
        try {
            return cronstrue.toString(job.cron, { verbose: true, locale: getCurrentLang()[0] })
        } catch (e) {
            return null
        }
    }, [job.cron])

    return (
        <>
            <div className='card job-form no-margin'>
                <div className='card-content'>
                    <div className='row'>
                        <div className='col s12'>
                            <span>{ i18n.emailOnJobFailed }</span>
                            <input
                                type='text'
                                placeholder={ i18n.emailSelection }
                                value={ emails }
                                onChange={ handleChangeEmails }
                                disabled={ !isEditMode }
                            />
                        </div>
                        {(job.jobType && job.jobType.includes('alert')) && (
                            <div className='col s12 padding-bottom-1'>
                                <div className='col s2'>
                                    <span>{i18n.testMailSending}</span>
                                </div>
                                <div className='col s2'>
                                    <div className='col s12 no-padding'>
                                        <a className={`waves-effect waves-light btn ${!isEditMode ? 'disabled' : ''}`} onClick={() => dispatch(AlertAction.testMail(emails.split(';'), 'testDataMissing'))}>
                                            {i18n.missingData}
                                        </a>
                                    </div>
                                </div>
                                <div className='col s2'>
                                    <div className='col s12 no-padding'>
                                        <a className={`waves-effect waves-light btn ${!isEditMode ? 'disabled' : ''}`} onClick={() => dispatch(AlertAction.testMail(emails.split(';'), 'testThresholdOverflow'))}>
                                            {i18n.thresholdOverflow}
                                        </a>
                                    </div>
                                </div>
                            </div>
                        )}
                        <div className='switch col s12'>
                            <label>
                                { i18n.jobScheduling }
                                <input
                                    type='checkbox'
                                    id='planification'
                                    onChange={() => setPlanified(!planified)}
                                    disabled={ !isEditMode }
                                    checked={ planified }
                                />
                                <span className='lever'/>
                            </label>
                            <span>{ cronText }</span>
                        </div>
                        <div className='input-field col s12'>
                            <Select
                                disabled={ !planified || !isEditMode }
                                value={ hours || 0 }
                                options={ hoursOptions }
                                label={ i18n.jobHour }
                                col={ 3 }
                                onChange={ handleChangeHours }
                                noNullValue
                            />
                            <Select
                                disabled={ !planified || !isEditMode }
                                value={ minutes || 0 }
                                options={ minutesOptions }
                                label={ i18n.jobMinute }
                                col={ 3 }
                                onChange={ handleChangeMinutes }
                                noNullValue
                            />
                            <Select
                                disabled={ !planified || !isEditMode }
                                value={ days }
                                options={ daysOptions }
                                label={ i18n.jobDay }
                                col={ 3 }
                                onChange={ handleChangeDays }
                                noNullValue
                            />
                        </div>
                    </div>
                </div>
            </div>
            <Card title={'Chaine de traitements'}>
                <h6>Cliquez sur un traitement pour modifier la chaine.</h6>
                <Row>
                    {jobs?.length && (
                        <JobChainGraph
                            jobs={jobs}
                            chain={chain}
                            onClick={(newSelectedJob) => {
                                setChainStepperIsOpen(true)
                                setSelectedJob(newSelectedJob)
                            }}
                            selectedJob={job}
                        />
                    )}
                </Row>
            </Card>
            { chainStepperIsOpen && <JobChainStepper
                isOpen={chainStepperIsOpen}
                setIsOpen={setChainStepperIsOpen}
                chain={chain}
                changeChain={setChain}
                jobs={jobs}
                selectedJob={selectedJob}
                currentJob={job}
            /> }
        </>
    )
}

SchedulePanel.propTypes = {
    job: PropTypes.instanceOf(Job).isRequired,
    jobs: arrayOf(Job),
    onChangePlanification: PropTypes.func.isRequired,
    onChangeEmails: PropTypes.func.isRequired,
    onChangeContactsIds: PropTypes.func.isRequired,
    isEditMode: PropTypes.bool,
    testMail: PropTypes.func,
    applicationSettings: arrayOf(SieauParameterDto),
}
export default SchedulePanel
