import { Button, Grid } from '@mui/material'
import Icon from 'components/icon/Icon'
import { DialogActionsMUI, DialogContentMUI, DialogMUI, DialogTitleMUI } from 'components/styled/Dialog'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import i18n from 'simple-react-i18n'
import { StyledFieldSet } from 'components/StyledElements'
import DtoDistributionNetwork from 'distributionUnit/dto/DtoDistributionNetwork'
import Select from 'components/forms/Select'
import DtoNetworkTableLine from 'installation/components/network/dto/DtoNetworkTableLine'
import Input from 'components/forms/Input'

const DistributionUnitNetworkModal = ({
    network = {},
    isOpen = false,
    setIsOpen = () => { },
    onRegister = () => { },
    idInstallation,
    networks = [],
}) => {
    const [stateNetwork, setStateNetwork] = useState(network)

    const onChangeElement = (changes) => setStateNetwork({ ...stateNetwork, ...changes })

    return (
        <DialogMUI open={isOpen}>
            <DialogTitleMUI style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                {i18n.editNetwork}
                <Icon style={{ color: 'white' }} size='small' icon='close' onClick={() => setIsOpen(false)} />
            </DialogTitleMUI>
            <DialogContentMUI>
                <StyledFieldSet>
                    <Grid container justifyContent='space-between' spacing={2} style={{ paddingTop: 10 }}>
                        <Grid item xs={6}>
                            <Select
                                options={networks}
                                label={i18n.network}
                                value={stateNetwork.idNetwork}
                                onChange={(v, obj) => onChangeElement({ idNetwork: v, ...obj })}
                                displayWithCode
                                keyValue='id'
                                noNullValue
                            />
                        </Grid>
                    </Grid>
                    <Grid container justifyContent='space-between' spacing={2} style={{ paddingTop: 10 }}>
                        <Grid item xs={6}>
                            <Input
                                title={i18n.code}
                                value={stateNetwork.code}
                                disabled
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Input
                                title={i18n.name}
                                value={stateNetwork.name}
                                disabled
                            />
                        </Grid>
                    </Grid>
                </StyledFieldSet>
            </DialogContentMUI>
            <DialogActionsMUI>
                <Button
                    onClick={() => {
                        setIsOpen(false)
                        onRegister(new DtoDistributionNetwork({ ...stateNetwork, idInstallation }))
                    }}
                    variant='contained'
                    color='primary'
                    className='center'
                >
                    {i18n.register}
                </Button>
            </DialogActionsMUI>
        </DialogMUI>

    )
}

DistributionUnitNetworkModal.propTypes = {
    network: PropTypes.instanceOf(DtoDistributionNetwork),
    networks: PropTypes.arrayOf(PropTypes.instanceOf(DtoNetworkTableLine)),
    isOpen: false,
    setIsOpen: PropTypes.func,
    onRegister: PropTypes.func,
    idInstallation: PropTypes.number,
}


export default DistributionUnitNetworkModal
