import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import DtoUserCmsStatistic from '../../../user/dto/DtoUserCmsStatistic'
import DtoUserStationStatistic from '../../../user/dto/DtoUserStationStatistic'
import DtoUserStatistic from '../../../user/dto/DtoUserStatistic'
import DtoUserUsageStatistic from '../../../user/dto/DtoUserUsageStatistic'
import AccessChartPanel from './charts/AccessChartPanel'
import CmsStatisticsPanel from './statistic/CmsStatisticsPanel'
import StationStatisticsPanel from './statistic/StationStatisticsPanel'
import StatisticsPanel from './statistic/StatisticsPanel'
import UsageStatisticsPanel from './statistic/UsageStatisticsPanel'

class UserStatisticPanel extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <div className='row no-margin card'>
                <div className='padding-8-10'>
                    <div className='col s12'>
                        <div className='row no-margin'>
                            <div className='col s8 no-padding'>
                                <AccessChartPanel
                                    userStatistics={this.props.userStatistics}
                                />
                            </div>
                            <div className='col s4 no-padding'>
                                <StatisticsPanel
                                    userStatistics={this.props.userStatistics}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='col s12 margin-top-1'>
                        <UsageStatisticsPanel
                            userUsageStatistics={this.props.userUsageStatistics}
                        />
                    </div>
                    <div className='col s12 margin-top-1'>
                        <StationStatisticsPanel
                            userStationStatistics={this.props.userStationStatistics}
                        />
                    </div>
                    <div className='col s12 margin-top-1'>
                        <CmsStatisticsPanel
                            userCmsStatistics={this.props.userCmsStatistics}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

UserStatisticPanel.propTypes = {
    userStatistics: PropTypes.arrayOf(PropTypes.instanceOf(DtoUserStatistic)),
    userCmsStatistics: PropTypes.arrayOf(PropTypes.instanceOf(DtoUserCmsStatistic)),
    userUsageStatistics: PropTypes.arrayOf(PropTypes.instanceOf(DtoUserUsageStatistic)),
    userStationStatistics: PropTypes.arrayOf(PropTypes.instanceOf(DtoUserStationStatistic)),

}

const mapStateToProps = (store) => {
    return {
        userStatistics: store.UserReducer.userStatistics,
        userCmsStatistics: store.UserReducer.userCmsStatistics,
        userUsageStatistics: store.UserReducer.userUsageStatistics,
        userStationStatistics: store.UserReducer.userStationStatistics,
    }
}

export default connect(mapStateToProps)(UserStatisticPanel)