import React, { Component } from 'react'
import { getLabel, getMapStateToProps, getPropTypes } from '../../../../utils/StoreUtils'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import Table from '../../../../components/datatable/Table'
import { nbPerPageLabelShort } from '../../../../referencial/constants/ReferencialConstants'
import { hasValue } from '../../../../utils/NumberUtil'
import PropTypes from 'prop-types'
import NumberField from '../../../../components/forms/NumberField'
import { orderBy } from 'lodash'
import AppStore from '../../../../store/AppStore'
import SieauAction from '../../../../components/sieau/SieauAction'
import InstallationsMergeModal from './InstallationsMergeModal'
import CityDto from '../../../../referencial/components/city/dto/CityDto'
import { setStationPointScale } from '../../../../components/map/mapTreatments/AddingLayers'

const storeProps = {
    installation: false,
    installations: false,
}

class NearbyInstallationsPanel extends Component {
    constructor(props) {
        super(props)
        this.state = { mergeDirection: 'right', mergeConfirmation: false }
    }

    getOriginIcon = (installation) => {
        return hasValue(installation.jobExecutionId) ?
            <i className='material-icons tooltipped rem-2-5-size' data-tooltip={`${i18n.automaticUpdate}</br>${installation.dataOrigin}`}>wifi</i> : null
    }

    getMergeIcon = (installation) => {
        return <i className='material-icons tooltipped blue-text rem-2-5-size clickable' data-tooltip={i18n.merge} onClick={() => this.openMergeModal(installation)}>call_merge</i>
    }


    openMergeModal = (otherInstallation) => {
        const actions = (
            <div>
                <a id='mergeButton' className='waves-effect waves-teal btn-flat'>{i18n.merge}</a>
                <a className='waves-effect waves-teal btn-flat modal-close'>{i18n.close}</a>
            </div>
        )
        const popup = {
            id: 'mergeModal',
            header: i18n.mergeDirection,
            actions,
            className: 'hugeModal hightHeightModal',
            content: (<InstallationsMergeModal otherInstallation={otherInstallation} onChangeState={(changes, cb) => this.setState(changes, cb)}/>),
            dontReset: true,
        }
        AppStore.dispatch(SieauAction.setPopup(popup))
    }

    render() {
        const stations = orderBy(this.props.nearbyInstallations, i => parseInt(i.distance)).map(installation => ({
            ...installation,
            origin: this.getOriginIcon(installation),
            name: (
                <div className='row no-margin'>
                    <div className='col s12'>
                        <div className='row no-margin'>
                            {`${installation.code || installation.id} - ${installation.distance}${i18n.metersSymbol}`}
                        </div>
                        <div className='row no-margin'>
                            {`${installation.name} - ${getLabel(this.props.cities, installation.townCode, 'labelWithCode')}`}
                        </div>
                    </div>
                </div>
            ),
            merge: this.getMergeIcon(installation),
        }))
        return (
            <div className='row no-margin'>
                <div className='row no-margin padding-top-1'>
                    <NumberField col={6} title={`${i18n.distance} (${i18n.metersSymbol})`} value={this.props.maxDistance} onChange={v => this.props.onChangeMaxDistance(v)}/>
                </div>
                <div className='row no-margin'>
                    <Table
                        data={stations}
                        paging nbPerPageLabel={nbPerPageLabelShort}
                        title={i18n.nearbyInstallations}
                        onLineOver={(station) => setStationPointScale(station, true)}
                        onLineOut={(station) => setStationPointScale(station, false)}
                        showNbElements={false}
                        type={{ headers: ['origin', 'name', 'merge'] }}
                    />
                </div>
            </div>
        )
    }
}

NearbyInstallationsPanel.propTypes = getPropTypes(storeProps, {
    nearbyInstallations: PropTypes.arrayOf(PropTypes.object),
    onChangeMaxDistance: PropTypes.func,
    maxDistance: PropTypes.number,
    cities: PropTypes.arrayOf(PropTypes.instanceOf(CityDto)),
})

const mapStateToProps = store => getMapStateToProps(storeProps, {
    cities: store.CityReducer.cities,
})

export default connect(mapStateToProps)(NearbyInstallationsPanel)