'use strict'
import React, { useState } from 'react'
import i18n from 'simple-react-i18n'
import PropTypes from 'prop-types'
import Input from '../../../../components/forms/Input'
import ToastrAction from 'toastr/actions/ToastrAction'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import Select from '../../../../components/forms/Select'
import { DialogActionsMUI, DialogContentMUI, DialogMUI, DialogTitleMUI } from 'components/styled/Dialog'
import { Button, Grid } from '@mui/material'
import Icon from 'components/icon/Icon'
import { hasValue } from 'utils/NumberUtil'

const NewInstallationDialog = ({
    open = false,
    closeDialog = () => { },
    title = '',
    onValidate = () => { },
}) => {
    const dispatch = useDispatch()

    const {
        installationsTypes,
    } = useSelector(store => ({
        installationsTypes: store.InstallationReducer.installationsTypes,
    }), shallowEqual)

    const [code, setCode] = useState()
    const [installationType, setInstallationType] = useState()

    const onSave = () => {
        if (code && hasValue(installationType)) {
            onValidate({ code, installationType })
        } else {
            dispatch(ToastrAction.info(i18n.pleaseCompleteAllField))
        }
    }

    return (
        <DialogMUI
            open={open}
            PaperProps={{
                sx: {
                    minHeight: 'auto',
                    maxHeight: 'auto',
                },
            }}
        >
            <DialogTitleMUI sx={{ margin: '0px', padding: '0px' }}>
                <Grid container justifyContent='space-between' alignItems='center' sx={{ padding: '10px 20px' }}>
                    <Grid item >
                        {title}
                    </Grid>
                    <Grid item>
                        <Icon style={{ color: 'white' }} size='small' icon='close' onClick={closeDialog} />
                    </Grid>
                </Grid>
            </DialogTitleMUI>
            <DialogContentMUI>
                <Grid container justifyContent='space-between' sx={{ padding: '1rem' }}>
                    <Grid item xs={5.8}>
                        <Select
                            label={i18n.installationType}
                            value={installationType}
                            onChange={setInstallationType}
                            options={installationsTypes.filter(it => it.display)}
                            keyLabel='name'
                            keyValue='code'
                        />
                    </Grid>
                    <Grid item xs={5.8}>
                        <Input
                            title={i18n.code}
                            onChange={setCode}
                            noSpace
                        />
                    </Grid>
                </Grid>
            </DialogContentMUI>
            <DialogActionsMUI>
                <Grid container justifyContent='flex-end' alignItems='flex-end'>
                    <Button
                        variant='contained'
                        onClick={onSave}
                    >
                        {i18n.save}
                    </Button>
                </Grid>
            </DialogActionsMUI>
        </DialogMUI>
    )
}

NewInstallationDialog.propTypes = {
    open: PropTypes.bool,
    closeDialog: PropTypes.func,
    title: PropTypes.string,
    onValidate: PropTypes.func,
}

export default NewInstallationDialog
