import ReactEchartsCore from 'echarts-for-react/lib/core'
import 'echarts/lib/chart/bar'
import echarts from 'echarts/lib/echarts'
import { groupBy, orderBy } from 'lodash'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import i18n from 'simple-react-i18n'
import { exportExcelIcon, exportPictureIcon } from 'components/echart/EChartUtils'
import { exportFile } from 'utils/ExportDataUtil'
import Select from 'components/forms/Select'
import { formatMilliers } from 'utils/StringUtil'
import Card from '../../../components/card/Card'

const EvolVolumesChartPanel = ({
    statistics = [],
    keyVolume = 'volume',
    select,
    selectOptions = [],
}) => {
    const [volume, setVolume] = useState(keyVolume)

    const getReduceStatistics = () => {
        const groupedValues = groupBy(statistics, 'year')
        return Object.keys(groupedValues).map((year) => {
            return groupedValues[year].map((d) => d[volume] || 0).reduce((acc, v) => acc + v, 0)
        })
    }


    const getInitXAxis = () => {
        const orderStatistics = orderBy(statistics, 'year', 'asc')
        const firstDate = orderStatistics[0]
        if (firstDate) {
            return Object.keys(groupBy(statistics, 'year'))
        }
        return []
    }

    const getExportData = () => {
        const data = statistics.map((d, i) => {
            const stat = {
                ...d,
                value: d[volume],
            }
            if (i === 0) {
                return {
                    ...stat,
                    headers: ['year', 'value'],
                }
            }
            return stat
        })
        return data
    }

    const getOptions = () => {
        const xAxisDatas = getInitXAxis()
        const reduceDatas = getReduceStatistics()
        return {
            color: ['blue'],
            grid: {
                left: '3%',
                right: '4%',
                bottom: '3%',
                containLabel: true,
            },
            xAxis: {
                type: 'category',
                data: xAxisDatas,
            },
            yAxis: {
                type: 'value',
            },
            series: [{
                type: 'bar',
                data: reduceDatas,
            }],
            tooltip: {
                trigger: 'item',
                formatter: (data) => {
                    return `${data.name} : ${formatMilliers(data.value)} m3`
                },
            },
            toolbox: {
                show: true,
                feature: {
                    restore: { title: i18n.restore },
                    saveAsImage: { title: i18n.export, icon: exportPictureIcon, name: i18n.userConnexionStatistic },
                    myToolExport: {
                        show: true,
                        title: i18n.excelExport,
                        icon: exportExcelIcon,
                        onclick: () => {
                            exportFile({
                                data: getExportData(),
                                exportType: 'xlsx',
                                titleFile: i18n.userConnexionStatistic,
                            })
                        },
                    },
                },
                right: 50,
            },
        }
    }

    return (
        <Card className='row no-margin' round>
            {select && (
                <Select
                    col={6}
                    options={selectOptions}
                    value={volume}
                    onChange={(v) => setVolume(v)}
                    style={{ position: 'absolute', zIndex: 1 }}
                    noNullValue
                    noSort
                />
            )}
            <div className='col s12 no-padding'>
                <ReactEchartsCore
                    echarts={ echarts }
                    option={ getOptions() }
                    notMerge={ true }
                    lazyUpdate={ true }
                    className={ 'row no-margin' }
                    style={ { height: 200 } }
                />
            </div>
        </Card>
    )
}

EvolVolumesChartPanel.propTypes = {
    statistics: PropTypes.arrayOf(PropTypes.shape({
        year: PropTypes.number,
    })),
    keyVolume: PropTypes.string,
    select: PropTypes.bool,
    selectOptions: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.number,
        label: PropTypes.number,
    })),
}

export default EvolVolumesChartPanel