import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import Job from '../../../dto/DtoJob'
import JobDashboard from '../../../dto/JobDashboard'
import JobAction from '../../../actions/JobAction'
import { removeNullKeys } from '../../../../utils/StoreUtils'
import Select from '../../../../components/forms/Select'
import Input from '../../../../components/forms/Input'
import { nbPerPageLabelMedium } from '../../../../referencial/constants/ReferencialConstants'
import { CardTable } from '../../../../components/datatable/NewTable'


class ArchiveJobPanel extends Component {
    constructor(props) {
        super(props)
        this.state = {
            parameter: {
                ...JSON.parse(props.job.parameters.parameters[0] || '{}'),
            },
        }
    }
    componentDidMount() {
        this.props.fetchJobsForArchiving()
    }


    getFilters = () => {
        const newFilters = this.props.job.parameters.filters.length ? JSON.parse(this.props.job.parameters.filters[0]) : {}
        return { ...newFilters }
    }
    getParametersNew = () => this.props.job.parameters.parameters.map(p => JSON.parse(p))


    getParametersData = (filters, parameters) => {
        const disabled = { disabled: !this.props.isEditMode }
        const myFrequence = [ {
            value: 'YEAR',
            label: i18n.perYear,
        },
        {
            value: 'MONTH',
            label: i18n.perMonth,
        },
        {
            value: 'DAY',
            label: i18n.perDay,
        },
        ]
        const delai = [ {
            value: '1',
            label: i18n.supOneDay,
        },
        {
            value: '2',
            label: i18n.supSevenDay,
        },
        {
            value: '3',
            label: i18n.supOneMonth,
        },
        {
            value: '4',
            label: i18n.supThreeMonth,
        },
        {
            value: '5',
            label: i18n.supOneYear,
        },
        ]
        const listJob =
            this.props.jobs.map(j => {
                return { value: j.id, label: `${j.name} ${j.description}` }
            })


        // this.props.jobList.map(job => ({ ...job, icon: null }))
        // nullValue: <Icon icon='delete' tooltip={ i18n.delete } onClick={ () => this.onDeleteParameter(index) } />,
        return parameters.map((p, index) => {
            return {
                job: <Select value={ p.job } options={ listJob } onChange={ v => this.onChangeParametersNew(p, index, { job: v }) } {...disabled }/>,
                defineDirectory: <Input value={ p.dir } onChange={ v => this.onChangeParametersNew(p, index, { dir: v }) } {...disabled }/>,
                nameArchiving: <Input value={ p.name } placeholder={ 'Archive' } onChange={ v => this.onChangeParametersNew(p, index, { name: v }) } {...disabled }/>,
                modeGrouping: <Select value={ p.frequence } options={myFrequence} onChange={ v => this.onChangeParametersNew(p, index, { frequence: v }) } {...disabled }/>,
                criteriaFilter: <Input value={ p.filter } onChange={ v => this.onChangeParametersNew(p, index, { filter: v }) } {...disabled }/>,
                delaiArchiving: <Select value={ p.delai } options={delai} onChange={ v => this.onChangeParametersNew(p, index, { delai: v }) } {...disabled }/>,
                index,
            }
        })
    }

    onChangeJobParameter = (changes) => {
        const jobParameters = {
            ...this.props.job.parameters,
            ...changes,
        }
        this.props.onChangeJob({ parameters: jobParameters })
    }

    onChangeParametersNew = (currentParameter, index, changes) => {
        const jobParameters = {
            ...this.props.job.parameters,
            parameters: Object.assign([], this.props.job.parameters.parameters, {
                [index]: JSON.stringify(removeNullKeys({ ...currentParameter, ...changes })),
            }),
        }
        this.props.onChangeJob({ parameters: jobParameters })
    }

    onChangeFilters = (changes) => {
        const parameters = {
            ...this.props.job.parameters,
            filters: [JSON.stringify({
                ...this.getFilters(),
                ...changes,
            })],
        }
        this.props.onChangeJob({ parameters })
    }

    onChange = (key, value) => {
        const parameter = { ...this.state.parameter, [key]: value }
        this.setState({ parameter })
        this.props.onChangeParameter([JSON.stringify(parameter)])
    }
    addParameterNew = () => {
        if (this.props.isEditMode) {
            const jobParameters = {
                ...this.props.job.parameters,
                parameters: [ ...this.props.job.parameters.parameters, '{}'],
            }
            this.props.onChangeJob({ parameters: jobParameters })
        }
    }
    onChangeDataCategory = (value) => {
        const { job } = this.props
        if (!job.parameters.parameters.length) {
            return this.updateDataCategory(value)
        }
        return this.setState({ tmpDataCategory: value })
    }
    onDeleteParameter = (elt) => {
        if (this.props.isEditMode) {
            const jobParameters = {
                ...this.props.job.parameters,
                parameters: this.props.job.parameters.parameters.filter((_, i) => i !== elt.index),
            }
            this.props.onChangeJob({ parameters: jobParameters })
        }
    }

    render = () => {
        const filters = this.getFilters()
        const parameters = this.getParametersData(filters, this.getParametersNew())
        const baseHeaders = [ 'job', 'defineDirectory', 'nameArchiving', 'modeGrouping', 'criteriaFilter', 'delaiArchiving']


        return (
            <div className='job-parameter-table'>
                <CardTable
                    rows={ parameters }
                    headers={ baseHeaders }
                    title={ i18n.credentials }
                    lineActions={[{
                        icon: 'delete',
                        onClick: (elt) => {
                            if (this.props.isEditMode) { 
                                const jobParameters = {
                                    ...this.props.job.parameters,
                                    parameters: this.props.job.parameters.parameters.filter((_, i) => i !== elt.index),
                                }
                                this.props.onChangeJob({ parameters: jobParameters })
                            }
                        },
                        displayed: this.props.isEditMode,
                    }]}
                    rowsPerPageOptions={ nbPerPageLabelMedium }
                    data-cy='Archive_table'
                    actions={[{
                        icon: 'add_box',
                        onClick: () =>{
                            if (this.props.isEditMode) {
                                const jobParameters = {
                                    ...this.props.job.parameters,
                                    parameters: [ ...this.props.job.parameters.parameters, '{}'],
                                }
                                this.props.onChangeJob({ parameters: jobParameters })
                            }
                        },
                        tooltip: i18n.add,
                    }]}
                />
            </div>
        )
    }
}

ArchiveJobPanel.propTypes = {
    job: PropTypes.instanceOf(Job).isRequired,
    isEditMode: PropTypes.bool,
    onChangeParameter: PropTypes.func,
    onChangeJob: PropTypes.func.isRequired,
    fetchJobsForArchiving: PropTypes.func,
    jobs: PropTypes.arrayOf(PropTypes.instanceOf(JobDashboard)),

}

const mapStateToProps = store => ({
    jobs: store.JobReducer.jobs,
})

const mapDispatchToProps = {
    fetchJobsForArchiving: JobAction.fetchJobsForArchiving,
}

export default connect(mapStateToProps, mapDispatchToProps)(ArchiveJobPanel)