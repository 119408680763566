import DtoMaterielType from '../../../dto/DtoMaterielType'
import { MAT_DIVERS } from '../../../constants/MaterielConstants'
import i18n from 'simple-react-i18n'

export default class DtoVariousMaterielType extends DtoMaterielType {
    constructor(obj) {
        super(obj)
        this.materielCategory = MAT_DIVERS
        this.category = obj.category
        this.materielLabel = i18n.variousMateriel
        this.refSandre = obj.refSandre // Option[String],
        this.sandreCode = obj.sandreCode // Option[Long]
    }
}
