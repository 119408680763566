import React, { useMemo, useState } from 'react'
import { mainBlue } from 'utils/constants/ColorTheme'
import PropTypes from 'prop-types'
import { Grid, Paper } from '@mui/material'
import { experimentalStyled as styled } from '@mui/material/styles'
import useUpdateEffect from 'utils/customHook/useUpdateEffect'

const Item = styled(Paper)(({ disabled, selected }) => ({
    backgroundColor: disabled && '#ACACAC' || selected && mainBlue || 'B8BBBF',
    textAlign: 'center',
    color: disabled && '#777' || selected && 'white' || 'black',
    fontWeight: 'bold',
    fontSize: '14px',
    height: '48px',
    lineHeight: '48px',
    cursor: disabled ? 'normal' : 'pointer',
    textTransform: 'uppercase',
    borderRadius: '0px',
    border: 'none',

    ':hover': {
        backgroundColor: disabled && '#ACACAC' || selected && mainBlue || 'white',
        color: disabled && '#777' || selected && 'white' || mainBlue,
    },
}))

const Tabs = ({
    tabs = [],
    defaultTab = '',
    children = () => {},
}) => {
    const [currentTab, setCurrentTab] = useState(defaultTab)

    useUpdateEffect(() => {
        setCurrentTab(defaultTab)
    }, [defaultTab])

    const filteredTabs = useMemo(() => {
        return tabs.filter(({ displayed = true }) => displayed)
    }, [tabs])

    return (
        <>
            <Grid container columns={filteredTabs.length}>
                {
                    filteredTabs.map(({ constant, label, disabled = false }) => (
                        <Grid item key={constant} xs={1}>
                            <Item disabled={disabled} selected={constant === currentTab} onClick={() => !disabled && setCurrentTab(constant)}>
                                {label}
                            </Item>
                        </Grid>
                    ))
                }
            </Grid>
            {children(currentTab)}
        </>
    )
}

Tabs.propTypes = {
    tabs: PropTypes.arrayOf(PropTypes.shape({
        constant: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        label: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired,
        displayed: PropTypes.bool,
        disabled: PropTypes.bool,
    })).isRequired,
    defaultTab: PropTypes.oneOfType([PropTypes.string, PropTypes.number]), // constant
    children: PropTypes.func.isRequired,
}

export default Tabs