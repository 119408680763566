import { useState } from 'react'

/**
 * Provide the same utility as useState in addition of an object fill up with utilities functions
 * @param {!Array} defaultArray - The default value of the list
 * @return {[Array, {
 *     setArray: setArray,
 *     add: add,
 *     addAll: addAll,
 *     filter: filter,
 *     update: update,
 *     remove: remove,
 *     removeAll: removeAll,
 *     clear: clear
 * }]} The array, the setter and methods.
 */
const useArray = (defaultArray) => {
    const [array, setArray] = useState(defaultArray)

    /**
     * Add an element at the end of the array
     * @function add
     * @param {*} element - The element to add
     * @returns {void}
     */
    const add = element => setArray(a => [...a, element])

    /**
     * Add many elements at the end of the array
     * @function addAll
     * @param {array} elements - The elements to add
     * @returns {void}
     */
    const addAll = elements => setArray(a => [...a, ...elements])

    /**
     * Filter the array by using the callback
     * @function filter
     * @param {function(*): boolean} callback - The function to filter the array
     * @returns {void}
     */
    const filter = callback => setArray(a => a.filter(callback))

    /**
     * update the element at the index passed as argument by the new element
     * @function update
     * @param {number} index - The index of the element to update
     * @param {*} newElement - The new element
     * @returns {void}
     */
    const update = (index, newElement) => setArray(a => [...a.slice(0, index), newElement, ...a.slice(index + 1, a.length)])

    /**
     * Remove the element at the index passed as argument from the array
     * @function remove
     * @param {number} index - The index of the element to remove
     * @returns {void}
     */
    const remove = index => setArray(a => [...a.slice(0, index), ...a.slice(index + 1, a.length)])

    /**
     * Remove all elements at the indexes passed as argument from the array
     * @function removeAll
     * @param {number[]} listIndex - Elements to remonve
     * @returns {void}
     */
    const removeAll = listIndex => setArray(a => a.filter((_, i) => !listIndex.includes(i)))

    /**
     * Clear the array
     * @function clear
     * @returns {void}
     */
    const clear = () => setArray([])

    return [array, { setArray, add, addAll, filter, update, remove, removeAll, clear }]
}

export default useArray