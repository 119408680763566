'use strict'
import { round } from 'lodash'
import moment from 'moment'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import AppStore from 'store/AppStore'
import Axis from '../../../components/echart/Axis'
import EChart from '../../../components/echart/EChart'
import Line from '../../../components/echart/series/Line'
import Spinner from '../../../components/progress/Spinner'
import SieauAction from '../../../components/sieau/SieauAction'
import DtoPiezometerLight from '../../../piezometry/dto/DtoPiezometerLight'
import { getColorFromPalette } from '../../../utils/ColorUtil'
import { getDateWithHour } from '../../../utils/DateUtil'
import { arrayOf, getLabel, getMapStateToProps, getPropType, getPropTypes } from '../../../utils/StoreUtils'

const propsToFetch = {
    piezometrySituation: false,
}

class PiezometryBookmarksLine extends Component {
    componentWillMount() {
        if (this.props.piezometers.length && !this.props.piezometrySituation.length) {
            AppStore.dispatch(SieauAction.fetch('piezometrySituation', this.props.piezometers))
        }
    }

    componentWillReceiveProps(nextProps) {
        if (!nextProps.piezometrySituation.length && !this.props.piezometers.length && nextProps.piezometers.length) {
            AppStore.dispatch(SieauAction.fetch('piezometrySituation', nextProps.piezometers))
        }
    }

    render() {
        if (this.props.piezometrySituation.length) {
            const measureList = this.props.piezometerBookmarks.map(p => this.props.piezometrySituation.find(s => s.piezometerId === p.id))

            const series = measureList.map((piezo, index) => {
                const values = piezo.measures.reduce((acc, m) => {
                    const date = getDateWithHour(m.creationDate, m.hour)
                    acc.push({ value: [date.valueOf(), round(m.value, 3)] })
                    if (m.continuity === 1) {
                        acc.push({ value: [date.add(1, 'minute').valueOf(), null] })
                    }
                    return acc
                }, [])
                return Line({
                    data: values,
                    name: getLabel(this.props.piezometerBookmarks, piezo.piezometerId, null, 'id'),
                    connectNulls: false,
                    color: getColorFromPalette(index),
                    showSymbol: false,
                })
            })

            const options = {
                grid: {
                    top: '8%',
                    left: '3%',
                    right: '4%',
                    bottom: '80',
                    containLabel: true,
                    height: 200,
                },
                series,
                xAxis: [Axis({
                    type: 'time',
                    position: 'bottom',
                    splitArea: {
                        show: true,
                    },
                    axisLabel: { formatter: value => `${moment(value).format('DD/MM')}\n${moment(value).format('YYYY')}` },
                })],
                yAxis: [Axis({
                    type: 'value',
                    showSplitLine: true,
                })],
                height: 200,
                dataZoom: [{
                    type: 'inside',
                    start: 80,
                    end: 100,
                    bottom: '10',
                    filterMode: 'empty',
                }, {
                    start: 80,
                    end: 100,
                    handleSize: '80%',
                    filterMode: 'empty',
                    handleStyle: {
                        color: '#fff',
                        shadowBlur: 3,
                        shadowColor: 'rgba(0, 0, 0, 0.6)',
                        shadowOffsetX: 2,
                        shadowOffsetY: 2,
                    },
                }],
            }
            return (
                <EChart options={ options }/>
            )
        }
        return <Spinner />
    }
}

PiezometryBookmarksLine.propTypes = getPropTypes(propsToFetch, {
    piezometerBookmarks: getPropType('piezometers'),
    piezometers: arrayOf(DtoPiezometerLight),
})

const mapStateToProps = store => getMapStateToProps(propsToFetch, {
    piezometers: store.PiezometryReducer.piezometersLight,
})

export default connect(mapStateToProps)(PiezometryBookmarksLine)
