export default class DtoCaptureEquipments {
    constructor(obj) {
        this.idStation = obj.idStation
        this.numberPumps = obj.numberPumps
        this.nominalFlow = obj.nominalFlow
        this.hmt = obj.hmt
        this.pumpType = obj.pumpType
        this.depthCrepine = obj.depthCrepine
        this.dewateringColDiamExt = obj.dewateringColDiamExt
        this.dewateringColType = obj.dewateringColType
        this.dewateringColNature = obj.dewateringColNature
        this.typeDetectionLevel = obj.typeDetectionLevel
        this.depthProbe = obj.depthProbe
    }
}
