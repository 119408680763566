import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import ActionComponent from '../../../components/ActionComponent'
import { getMapStateToProps, getPropTypes, getSandreList } from '../../../utils/StoreUtils'
import Card from '../../../components/card/Card'
import StationMapDashboardPanel from '../../../station/components/dashboard/component/map/StationMapDashboardPanel'
import Input from '../../../components/forms/Input'
import DatePicker from '../../../components/forms/DatePicker'
import NumberField from '../../../components/forms/NumberField'
import Row from '../../../components/react/Row'
import Select from '../../../components/forms/Select'
import Textarea from '../../../components/forms/Textarea'
import Checkbox from '../../../components/forms/Checkbox'
import { SANDRE } from '../../../referencial/constants/ReferencialConstants'
import { hasValue } from '../../../utils/NumberUtil'
import LastEventPanel from '../../../station/components/event/LastEventPanel'
import ContactCard from '../../../referencial/components/contact/components/ContactCard'
import StationUpdatePanel from '../../../station/components/update/StationUpdatePanel'
import { maxBy, some } from 'lodash'
import { getControlColor } from '../../../utils/InstallationUtils'
import { getColorCircleElement, getRGBColor } from '../../../utils/ColorUtil'
import AlertPanel from '../../../components/alert/AlertPanel'
import moment from 'moment'
import { getDate } from '../../../utils/DateUtil'
import InstallationArrangementsTable from './arrangements/InstallationArrangementsTable'
import LinkedStationsPanel from '../../../station/components/associatedStation/LinkedStationsPanel'
import DtoInstallationType from '../../dto/installation/DtoInstallationType'
import CaptureDownstreamDistribUnitPanel from '../capture/components/CaptureDownstreamDistribUnitPanel'
import DistributionUnitAction from '../../../distributionUnit/actions/DistributionUnitAction'
import InstallationAction from '../../../installation/actions/InstallationAction'
import MaterielAssigned from '../../../station/components/materiel/MaterielAssigned'
import InstallationInfosDashboard from '../installations/components/InstallationInfosDashboard'
import UnitChartPanel from '../../../productionUnit/components/UnitChartPanel'
import { getStatuses } from '../../../utils/QualityUtils'
import { H_DISTRIBUTION_MODULE, H_MAT_MODULE } from '../../../account/constants/AccessRulesConstants'
import HabilitationRequired from '../../../utils/components/HabilitationRequired'
import { WhiteCard } from 'components/styled/Card'
import InstallationCounterPanel from './InstallationCounterPanel'
import { INSTALLATION_TYPE } from 'installation/constants/InstallationConstants'
import LiftingLinkedProduction from '../liftingStation/LiftingLinkedProduction'

const storeProps = {
    installation: false,
    cities: false,
    watersheds: false,
    users: false,
    sandreCodes: false,
    contributors: false,
    installationEvents: false,
}

class InstallationDashboardPanel extends ActionComponent {
    state = {
        uncheckedStations: [],
    }
    componentDidMount() {
        if (!this.props.captureDownstreamDistribUnits.length) {
            this.props.fetchCaptureDownstreamDistribUnits()
        }

        if (!this.props.installationsTypes.length) {
            this.props.fetchInstallationTypes()
        }
    }

    getCartographyData = (installation) => {
        return installation?.link_geo?.length ? installation.link_geo[0] : {}
    }

    getOriginIcon = (installation) => {
        return hasValue(installation.jobExecutionId) ? (
            <div className='row no-margin padding-left-1 padding-top-1'>
                <h6 className='valign-wrapper'>
                    <i className='material-icons rem-3-size padding-right-1'>wifi</i>
                    {i18n.automaticUpdate}
                </h6>
            </div>
        ) : null
    }

    getOriginStatusColor = (installation) => {
        if (hasValue(installation.status)) {
            const color = getControlColor(installation.status)
            return getColorCircleElement(getRGBColor(color), true)
        }
        return null
    }

    getDiagnosticAlertPanel = () => {
        const diagnosticEvents = this.props.installationEvents.filter(i => i.diagnostic && i.diagnosticDeadline)
        if (diagnosticEvents.length) {
            const lastDate = maxBy(diagnosticEvents, 'diagnosticDeadline').diagnosticDeadline
            if (moment().isSameOrAfter(moment(lastDate).year(moment(lastDate).year() - 2))) {
                const color = moment().isSameOrAfter(moment(lastDate).year(moment(lastDate).year() - 1)) ? 'red' : 'orange'
                return (
                    <AlertPanel comment={i18n.diagnosticDeadlineOn + getDate(lastDate)}
                        title={i18n.diagnosticToPlan}
                        icon={'warning'} color={color}
                    />
                )
            }
        }
        return null
    }

    hasType = (type) => this.props.associatedSites.filter(ass => ass.stationLinkedType === type).length

    render() {
        const { installation } = this.props
        const readMode = { readMode: true, editMode: false, hideNull: true }

        const cartographyData = this.getCartographyData(installation)
        return (
            <div className='row no-margin padding-top-1 padding-bottom-7'>
                <div className='col s9'>
                    <Card noMargin={false} className='margin-bottom-1 no-margin-top' round>
                        <InstallationInfosDashboard />
                    </Card>
                    <WhiteCard
                        title={i18n.characteristics}
                        smallCard
                        displayIf={some(['exploitationCode', 'comments'], key => hasValue(installation[key]))}
                        className='margin-bottom-1'
                        noMargin={false}
                        round
                    >
                        <div className='row no-margin padding-top-1'>
                            <div className='col s6' >
                                <div className='row no-margin'>
                                    <Checkbox componentClassName='padding-bottom-1' col={12} checked={hasValue(installation.exploitationCode) ? installation.exploitationCode == 1 : null}
                                        label={i18n.exploitation} disabled {...readMode}
                                    />
                                </div>
                            </div>
                            <div className='col s6' >
                                <div className='row no-margin'>
                                    <Textarea col={12} value={installation.comments} title={i18n.comment} {...readMode} />
                                </div>
                            </div>
                        </div>
                    </WhiteCard>
                    <Row>
                        <UnitChartPanel unit={this.props.installation} uncheckedStations={this.state.uncheckedStations} setDataLoaded={this.props.setDataLoaded} fromStationType='INST' />
                    </Row>
                    <InstallationCounterPanel
                        stationId={installation.id}
                        showTitle
                        showKeyFigures
                        inPopup
                    />
                    <InstallationArrangementsTable summarized />
                    <HabilitationRequired habilitation={H_DISTRIBUTION_MODULE}>
                        <Row displayIf={installation.installationType === 8}>
                            <Card title={i18n.distributionUnit} className='margin-bottom-1' noMargin={false}>
                                <CaptureDownstreamDistribUnitPanel station={installation} />
                            </Card>
                        </Row>
                    </HabilitationRequired>
                    {installation.installationType === INSTALLATION_TYPE.CAPTURE && <LiftingLinkedProduction /> || null}
                </div>
                <div className='col s3'>
                    <StationUpdatePanel station={installation} />
                    {this.getDiagnosticAlertPanel()}
                    <Card round displayIf={some(['dataOrigin', 'closeComment', 'status', 'statusDate', 'deliberationDate', 'confidential'], key => hasValue(installation[key]))}>
                        {this.getOriginIcon(installation)}
                        <div className='row no-margin'>
                            <Input col={12} title={i18n.dataOrigin} value={installation.dataOrigin} {...readMode} />
                        </div>
                        <div className='row no-margin'>
                            <Select col={12} label={[this.getOriginStatusColor(installation), i18n.status]} value={installation.status}
                                options={getStatuses()} {...readMode}
                            />
                        </div>
                        <div className='row no-margin'>
                            <DatePicker col={12} value={installation.statusDate} id='statusDate' title={i18n.controlDoneAt} {...readMode} />
                        </div>
                        <div className='row no-margin'>
                            <Select col={12} label={i18n.controlDoneBy} value={installation.statusLogin}
                                options={this.props.users.map(u => ({ code: u.login, name: u.name || u.login }))} {...readMode}
                            />
                        </div>
                        <div className='row no-margin'>
                            <DatePicker col={12} value={installation.endDate} id='deliberationDate' title={i18n.deliberationDate} {...readMode} />
                        </div>
                        <div className='row no-margin'>
                            <Input col={12} value={installation.closeComment} title={i18n.become} {...readMode} />
                        </div>
                        <div className='row no-margin'>
                            <Checkbox componentClassName='padding-bottom-1' col={12} label={i18n.confidential} {...readMode}
                                checked={hasValue(installation.confidential) ? installation.confidential == '1' : null} disabled
                            />
                        </div>
                    </Card>
                    <Row className='padding-top-1'>
                        <ContactCard
                            title={i18n.contact}
                            contactCode={installation.ownerCode}
                            {...readMode}
                        />
                    </Row>
                    <div className='padding-top-1' />
                    <Card round>
                        <div className='row no-margin padding-top-1'>
                            <Input col={12} title={i18n.address} value={installation.address} {...readMode} />
                        </div>
                        <div className='row no-margin'>
                            <Select col={12} value={installation.townCode} label={i18n.city} options={this.props.cities} keyValue='id' displayWithCode {...readMode} />
                        </div>
                        <div className='row no-margin'>
                            <NumberField col={6} title='X' value={installation.x} floatValue {...readMode} />
                            <NumberField col={6} title='Y' value={installation.y} floatValue {...readMode} />
                        </div>
                        <div className='row no-margin'>
                            <NumberField col={6} title='Z' value={installation.altitude} floatValue {...readMode} />
                            <Select col={6} label={i18n.projection} value={installation.projection}
                                options={getSandreList(this.props.sandreCodes, SANDRE.PROJECTION)} {...readMode}
                            />
                        </div>
                        <div className='row no-margin'>
                            <Input col={6} title={i18n.section} value={cartographyData.parcel} {...readMode} />
                            <Input col={6} title={i18n.parcel} value={cartographyData.section} {...readMode} />
                        </div>
                        <div className='row no-margin'>
                            <Input col={6} title={i18n.ignMap} value={cartographyData.ignMap} {...readMode} />
                            <Input col={6} title={i18n.geologicalMap} value={cartographyData.geologicalMap} {...readMode} />
                        </div>
                    </Card>
                    <div className='padding-top-1' />
                    <HabilitationRequired habilitation={H_MAT_MODULE}>
                        <div>
                            <MaterielAssigned stationId={this.props.installation.id} type={this.props.stationType} />
                            <div className='padding-top-1' />
                        </div>
                    </HabilitationRequired>
                    <LastEventPanel id={this.props.installation.id} events={this.props.installationEvents} stationId={this.props.installation.id}
                        stationType='installation'
                    />
                    <div className='padding-top-1' />
                    <StationMapDashboardPanel noMarkerTooltip station={installation} type={'installation'} />
                    <div className='padding-top-1' />
                    <LinkedStationsPanel
                        onReMount={ this.props.onReMount }
                        dashboardMode
                        checkable
                        changeUncheckedStations={ tab => this.setState({ uncheckedStations: tab })}
                        station={this.props.installation}
                        withColors
                        hideTitle
                    />
                </div>
            </div>
        )
    }
}

InstallationDashboardPanel.propTypes = getPropTypes(storeProps, {
    setDataLoaded: PropTypes.func,
    onRemount: PropTypes.func,
    stationType: PropTypes.string,
    installationsTypes: PropTypes.arrayOf(PropTypes.instanceOf(DtoInstallationType)),
})

const mapStateToProps = (store) => getMapStateToProps(storeProps, {
    installationsTypes: store.InstallationReducer.installationsTypes,
    captureDownstreamDistribUnits: store.DistributionUnitReducer.captureDownstreamDistribUnits,
    associatedSites: store.StationReducer.associatedSites,
})

const mapDispatchToProps = {
    fetchCaptureDownstreamDistribUnits: DistributionUnitAction.fetchCaptureDownstreamDistribUnits,
    fetchInstallationTypes: InstallationAction.fetchInstallationTypes,
}

export default connect(mapStateToProps, mapDispatchToProps)(InstallationDashboardPanel)