import { Grid } from '@mui/material'
import Table from 'components/datatable/Table'
import InstallationAction from 'installation/actions/InstallationAction'
import DtoDanger from 'installation/components/campaign/dto/DtoDanger'
import { DANGER_ELEMENT_TYPE, INSTALLATION_TYPES } from 'installation/constants/InstallationConstants'
import { orderBy } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { SANDRE } from 'referencial/constants/ReferencialConstants'
import i18n from 'simple-react-i18n'
import { getDate } from 'utils/DateUtil'
import { exportFile } from 'utils/ExportDataUtil'
import { getImgInstallation } from 'utils/mapUtils/SiteTypes'
import { hasValue } from 'utils/NumberUtil'
import { arrayOf, getLabel } from 'utils/StoreUtils'
import { getSandreLabel } from 'utils/StringUtil'

const headers = ['impact', 'type', 'dangerousSituation', 'definitionDate']

const DangersManagerTable = ({
    dangers = [],
}) => {
    const {
        sandreCodes = [],
        installationsTypes,
    } = useSelector(store => ({
        sandreCodes: store.ReferencialReducer.sandreCodes,
        installationsTypes: store.InstallationReducer.installationsTypes,
    }), shallowEqual)

    const dispatch = useDispatch()

    const getNameDisplay = (icon, text) => {
        return (
            <Grid container alignItems='center'>
                <Grid item>{icon}</Grid>
                <Grid item className='padding-left-1'>
                    <span> {text}</span>
                </Grid>
            </Grid>
        )
    }

    const getName = (danger) => {
        switch (danger.siteType) {
            case DANGER_ELEMENT_TYPE.INSTALLATION:
                const imgObj = getImgInstallation(danger.intValue)
                const icon = <img className='circle' style={{ height: '30px' }} src={imgObj.url} />
                const instType = INSTALLATION_TYPES.find(it => it.code === danger.intValue) || {}
                const text = ` ${instType?.name && `${i18n[instType.label]} - ` || ''} ${danger.name} [${danger.code || danger.siteCode}]`
                return getNameDisplay(icon, text)
            case DANGER_ELEMENT_TYPE.CONTRIBUTOR:
                const icon2 = <i className='material-icons'>business</i>
                const text2 = `${danger?.intValue && `${danger?.intValue} - ` || ''} ${danger.name} [${danger.siret || danger.code}]`
                return getNameDisplay(icon2, text2)
            case DANGER_ELEMENT_TYPE.SECTOR:
                const icon3 = <i className='material-icons right blue-text clickable map-right-icon'>map</i>
                const text3 = `${danger.name} [${danger.siteCode}]`
                return getNameDisplay(icon3, text3)
            default:
                return danger.name
        }
    }

    const data = orderBy(dangers, 'definitionDate').map(danger => ({
        ...danger,
        impact: { value: getName(danger) },
        dangerousSituation: { value: getSandreLabel(sandreCodes, SANDRE.INSTALLATIONS_DANGERS, danger.dangerousSituation) },
        definitionDate: { value: getDate(danger.definitionDate) },
        type: { value: danger.siteType === DANGER_ELEMENT_TYPE.INSTALLATION ? getLabel(installationsTypes, danger.intValue): null },
        lineColor: hasValue(danger.resolutionDate) && 'grey',
    }))

    const actions = [{
        iconName: 'file_download',
        tooltip: i18n.export,
        onClick: () => exportFile({
            data: [{ ...data[0], headers }, ...data.slice(1, data.length)],
            titleFile: i18n.dangerousSituations,
        }),
    }]

    return (
        <Grid style={{ padding: '10px' }}>
            <Table
                col={12}
                condensed
                sortable
                actions={actions}
                title={i18n.dangerousSituations}
                data={data}
                color
                onClick={d => {
                    dispatch(InstallationAction.fetchDanger(d.idDanger))
                }}
                type={{ headers }}
            />
        </Grid>
    )
}

DangersManagerTable.propTypes = {
    dangers: arrayOf(DtoDanger),
    openUpdateDanger: PropTypes.func,
}

export default DangersManagerTable
