import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import Select from '../../../../components/forms/Select'
// import Checkbox from '../../../../components/forms/Checkbox'
// import Select from '../../../../components/forms/Select'
// import { getMatTypes } from '../../../../utils/MaterielUtils'
import TelecomTypeDto from '../../telecom/dto/TelecomTypeDto'
import EquipmentTypeDto from '../dto/EquipmentTypeDto'
import MaterielTypeDto from '../../../dto/MaterielTypeDto'
import CentralTypeDto from '../../central/dto/CentralTypeDto'
import DtoSensorType from '../../sensor/dto/DtoSensorType'
import PowerSupplyTypeDto from '../../powerSupply/dto/PowerSupplyTypeDto'
import ContributorDto from '../../../../referencial/components/contributor/dto/ContributorDto'
import DtoSimType from '../../sim/dto/DtoSimType'
import DtoVariousMaterielType from '../../variousMateriel/dto/DtoVariousMaterielType'
import Card from '../../../../components/card/Card'
import { getMatTypes } from '../../../../utils/MaterielUtils'
import { replace } from '../../../../utils/FormUtils'
import Icon from '../../../../components/icon/Icon'
import CentralTypePanel from '../../central/components/CentralTypePanel'
import { hasValue } from '../../../../utils/NumberUtil'
import SensorTypePanel from '../../sensor/components/SensorTypePanel'
import TelecomTypePanel from '../../telecom/components/TelecomTypePanel'
import PowerSupplyTypePanel from '../../powerSupply/components/PowerSupplyTypePanel'

const EquipmentTypePanel = ({
    materielType = {},
    onChange = () => { },
    openPopin = () => { },
    centralTypes = [],
    sensorTypes = [],
    powerSupplyTypes = [],
    contributors = [],
    telecomTypes = [],
    simTypes = [],
    variousMaterielTypes = [],
}) => {
    const {
        centralType,
        sensorType,
        simType,
        powerSupplyType,
        telecomType,
        variousMaterielType,
    } = materielType
    const actions = [
        {
            iconName: 'add',
            onClick: openPopin,
        },
    ]
    return (
        <div>
            <div className='row no-margin'>
                <Select
                    col={6}
                    options={contributors}
                    label={i18n.provider}
                    value={materielType.provider}
                    onChange={v => onChange({ provider: v })}
                    keyValue='id'
                    keyLabel='mnemonique'
                />
                <Select
                    col={6}
                    options={contributors}
                    label={i18n.manufacturer}
                    value={materielType.manufacturerId}
                    onChange={v => onChange({ manufacturerId: v })}
                    keyValue='id'
                    keyLabel='mnemonique'
                />
            </div>
            <Card title={i18n.materiels} actions={actions}>
                <div className='row no-margin padding-bottom-1'>
                    {
                        centralType.length > 0 && (
                            <fieldset>
                                <legend>{i18n.central}</legend>
                                {
                                    centralType.map((type, index) => (
                                        <div>
                                            <div className='row no-margin valign-wrapper'>
                                                <Select
                                                    col={11}
                                                    label={i18n.centralType}
                                                    options={getMatTypes(centralTypes, type)}
                                                    value={type}
                                                    onChange={newType => onChange({ centralType: replace(centralType, newType, index) })}
                                                />
                                                <div className='col s1'>
                                                    <Icon
                                                        icon='clear'
                                                        onClick={() => onChange({ centralType: centralType.filter((_, i) => i !== index) })}
                                                    />
                                                </div>
                                            </div>
                                            {
                                                hasValue(type) && (
                                                    <CentralTypePanel
                                                        materielType={centralTypes.find(({ id }) => id === type)}
                                                        showContributorField={false}
                                                        disabled
                                                    />
                                                )
                                            }
                                        </div>
                                    ))
                                }
                            </fieldset>
                        )
                    }
                    {
                        sensorType.length > 0 && (
                            <fieldset>
                                <legend>{i18n.sensor}</legend>
                                {
                                    sensorType.map((type, index) => (
                                        <div>
                                            <div className='row no-margin valign-wrapper'>
                                                <Select
                                                    col={11}
                                                    label={i18n.sensorType}
                                                    options={getMatTypes(sensorTypes, type)}
                                                    value={type}
                                                    onChange={newType => onChange({ sensorType: replace(sensorType, newType, index) })}
                                                />
                                                <div className='col s1'>
                                                    <Icon
                                                        icon='clear'
                                                        onClick={() => onChange({ sensorType: sensorType.filter((_, i) => i !== index) })}
                                                    />
                                                </div>
                                            </div>
                                            {
                                                hasValue(type) && (
                                                    <SensorTypePanel
                                                        materielType={sensorTypes.find(({ id }) => id === type)}
                                                        showContributorField={false}
                                                        disabled
                                                    />
                                                )
                                            }
                                        </div>
                                    ))
                                }
                            </fieldset>
                        )
                    }
                    {
                        telecomType.length > 0 && (
                            <fieldset>
                                <legend>{i18n.telecom}</legend>

                                {
                                    telecomType.map((type, index) => (
                                        <div>
                                            <div className='row no-margin valign-wrapper'>
                                                <Select
                                                    col={11}
                                                    label={i18n.telecomType}
                                                    options={getMatTypes(telecomTypes, type)}
                                                    value={type}
                                                    onChange={newType => onChange({ telecomType: replace(telecomType, newType, index) })}
                                                />
                                                <div className='col s1'>
                                                    <Icon
                                                        icon='clear'
                                                        onClick={() => onChange({ telecomType: telecomType.filter((_, i) => i !== index) })}
                                                    />
                                                </div>
                                            </div>
                                            {
                                                hasValue(type) && (
                                                    <TelecomTypePanel
                                                        materielType={telecomTypes.find(({ id }) => id === type)}
                                                        showContributorField={false}
                                                        disabled
                                                    />
                                                )
                                            }
                                        </div>
                                    ))
                                }
                            </fieldset>
                        )
                    }
                    {
                        simType.length > 0 && (
                            <fieldset>
                                <legend>{i18n.sim}</legend>
                                {
                                    simType.map((type, index) => (
                                        <div>
                                            <div className='row no-margin valign-wrapper'>
                                                <Select
                                                    col={11}
                                                    label={i18n.simType}
                                                    options={getMatTypes(simTypes, type)}
                                                    value={type}
                                                    onChange={newType => onChange({ simType: replace(simType, newType, index) })}
                                                />
                                                <div className='col s1'>
                                                    <Icon
                                                        icon='clear'
                                                        onClick={() => onChange({ simType: simType.filter((_, i) => i !== index) })}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                            </fieldset>
                        )
                    }
                    {
                        powerSupplyType.length > 0 && (
                            <fieldset>
                                <legend>{i18n.powerSupply}</legend>
                                {
                                    powerSupplyType.map((type, index) => (
                                        <div>
                                            <div className='row no-margin valign-wrapper'>
                                                <Select
                                                    col={11}
                                                    label={i18n.powerSupplyType}
                                                    options={getMatTypes(powerSupplyTypes, type)}
                                                    value={type}
                                                    onChange={newType => onChange({ powerSupplyType: replace(powerSupplyType, newType, index) })}
                                                />
                                                <div className='col s1'>
                                                    <Icon
                                                        icon='clear'
                                                        onClick={() => onChange({ powerSupplyType: powerSupplyType.filter((_, i) => i !== index) })}
                                                    />
                                                </div>
                                            </div>
                                            {
                                                hasValue(type) && (
                                                    <PowerSupplyTypePanel
                                                        materielType={powerSupplyTypes.find(({ id }) => id === type)}
                                                        showContributorField={false}
                                                        disabled
                                                    />
                                                )
                                            }
                                        </div>
                                    ))
                                }
                            </fieldset>
                        )
                    }
                    {
                        variousMaterielType.length > 0 && (
                            <fieldset>
                                <legend>{i18n.variousMateriel}</legend>
                                {
                                    variousMaterielType.map((type, index) => (
                                        <div>
                                            <div className='row no-margin valign-wrapper'>
                                                <Select
                                                    col={11}
                                                    label={i18n.variousMaterielType}
                                                    options={getMatTypes(variousMaterielTypes, type)}
                                                    value={type}
                                                    onChange={newType => onChange({ variousMaterielType: replace(variousMaterielType, newType, index) })}
                                                />
                                                <div className='col s1'>
                                                    <Icon
                                                        icon='clear'
                                                        onClick={() => onChange({ variousMaterielType: variousMaterielType.filter((_, i) => i !== index) })}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                            </fieldset>
                        )
                    }
                </div>
            </Card>
        </div>
    )
}

EquipmentTypePanel.propTypes = {
    materielType: PropTypes.instanceOf(EquipmentTypeDto),
    onChange: PropTypes.func,
    openPopin: PropTypes.func,
    contributors: PropTypes.arrayOf(PropTypes.instanceOf(ContributorDto)),
    materielTypes: PropTypes.arrayOf(PropTypes.instanceOf(MaterielTypeDto)),
    centralTypes: PropTypes.arrayOf(PropTypes.instanceOf(CentralTypeDto)),
    sensorTypes: PropTypes.arrayOf(PropTypes.instanceOf(DtoSensorType)),
    powerSupplyTypes: PropTypes.arrayOf(PropTypes.instanceOf(PowerSupplyTypeDto)),
    telecomTypes: PropTypes.arrayOf(PropTypes.instanceOf(TelecomTypeDto)),
    simTypes: PropTypes.arrayOf(PropTypes.instanceOf(DtoSimType)),
    variousMaterielTypes: PropTypes.arrayOf(PropTypes.instanceOf(DtoVariousMaterielType)),
}

const mapStateToProps = store => ({
    contributors: store.ContributorReducer.contributors,
    materielTypes: store.MaterielReducer.materielTypes,
    centralTypes: store.CentralReducer.centralTypes,
    powerSupplyTypes: store.PowerSupplyReducer.powerSupplyTypes,
    sensorTypes: store.SensorReducer.sensorTypes,
    telecomTypes: store.TelecomReducer.telecomTypes,
    simTypes: store.SimReducer.simTypes,
    variousMaterielTypes: store.VariousMaterielReducer.variousMaterielTypes,
})

export default connect(mapStateToProps)(EquipmentTypePanel)