module.exports = {
    DESCRIPTION: 'DESCRIPTION',
    ANALYSIS: 'ANALYSIS',
    INDICES: 'INDICES',
    BILAN: 'BILAN',
    ENVIRONMENT: 'ENVIRONMENT',
    placeOptions: [
        { value: '1', label: 'insitu' },
        { value: '2', label: 'laboratory' },
    ],
}
