'use strict'
import i18n from 'simple-react-i18n'
import {
    H_REFERENCIAL_ACTIVITY,
    H_REFERENCIAL_AQUIFER,
    H_REFERENCIAL_CITY,
    H_REFERENCIAL_CONTACT,
    H_REFERENCIAL_CONTRIBUTOR,
    H_REFERENCIAL_COUNTRY,
    H_REFERENCIAL_CULTURE,
    H_REFERENCIAL_DRIVEMODE,
    H_REFERENCIAL_FRACTION,
    H_REFERENCIAL_HYDROGEOLOGICAL_ENTITY,
    H_REFERENCIAL_METHOD,
    H_REFERENCIAL_NETWORK,
    H_REFERENCIAL_PARAMETER,
    H_REFERENCIAL_RPG,
    H_REFERENCIAL_SECTOR,
    H_REFERENCIAL_SUPPORT,
    H_REFERENCIAL_TAXON,
    H_REFERENCIAL_UNIT,
    H_REFERENCIAL_USAGE,
    H_REFERENCIAL_WATERMASS,
    H_REFERENCIAL_WATERSHED,
    H_REFERENCIAL_ZONE,
} from '../../../../account/constants/AccessRulesConstants'
import {
    RECEIVE_ACTIVITY_COUNT,
    RECEIVE_AQUIFER_COUNT,
    RECEIVE_CITY_COUNT,
    RECEIVE_CONTACT_COUNT,
    RECEIVE_CONTRIBUTOR_COUNT,
    RECEIVE_COUNTRY_COUNT, RECEIVE_CULTURES_FAMILY_COUNT, RECEIVE_CULTURE_COUNT, RECEIVE_DRIVE_MODE_COUNT,
    RECEIVE_FRACTION_COUNT,
    RECEIVE_HYDROGEOLOGICALENTITY_COUNT,
    RECEIVE_METHOD_COUNT,
    RECEIVE_NETWORK_COUNT,
    RECEIVE_PARAMETER_COUNT,
    RECEIVE_SUPPORT_COUNT,
    RECEIVE_TAXON_COUNT,
    RECEIVE_UNIT_COUNT,
    RECEIVE_USAGE_COUNT,
    RECEIVE_RPG_COUNT,
    RECEIVE_WATERMASS_COUNT,
    RECEIVE_WATERSHED_COUNT,
    RECEIVE_ZONE_COUNT,
    RECEIVE_SECTOR_COUNT,
} from '../constants/DashboardConstants'


export function DashboardReducer(state = {}, action) {
    switch (action.type) {
        case RECEIVE_CULTURE_COUNT:
            return {
                ...state,
                culture: {
                    ...state.culture,
                    number: action.cultureCount,
                    name: i18n.cultures,
                },
            }
        case RECEIVE_RPG_COUNT:
            return {
                ...state,
                rpg: {
                    ...state.rpg,
                    number: action.rpgCount,
                },
            }
        case RECEIVE_CULTURES_FAMILY_COUNT:
            return {
                ...state,
                culturesFamily: {
                    ...state.culturesFamily,
                    number: action.culturesFamilyCount,
                    name: i18n.culturesFamilies,
                },
            }
        case RECEIVE_ZONE_COUNT:
            return {
                ...state,
                zone: {
                    ...state.zone,
                    number: action.zoneCount,
                    name: i18n.zones,
                },
            }
        case RECEIVE_DRIVE_MODE_COUNT:
            return {
                ...state,
                driveMode: {
                    ...state.driveMode,
                    number: action.driveModeCount,
                    name: i18n.driveModes,
                },
            }
        case RECEIVE_AQUIFER_COUNT:
            return {
                ...state,
                aquifer: {
                    ...state.aquifer,
                    number: action.aquiferCount,
                    name: i18n.aquifers,
                },
            }
        case RECEIVE_CITY_COUNT:
            return {
                ...state,
                city: {
                    ...state.city,
                    number: action.cityCount,
                    name: i18n.cities,
                },
            }
        case RECEIVE_CONTACT_COUNT:
            return {
                ...state,
                contact: {
                    ...state.contact,
                    number: action.contactCount,
                    name: i18n.contacts,
                },
            }
        case RECEIVE_NETWORK_COUNT:
            return {
                ...state,
                network: {
                    ...state.network,
                    number: action.networkCount,
                    name: i18n.networks,
                },
            }
        case RECEIVE_FRACTION_COUNT:
            return {
                ...state,
                fraction: {
                    ...state.fraction,
                    number: action.fractionCount,
                    name: i18n.fractions,
                },
            }
        case RECEIVE_CONTRIBUTOR_COUNT:
            return {
                ...state,
                contributor: {
                    ...state.contributor,
                    number: action.contributorCount,
                    name: i18n.contributors,
                },
            }
        case RECEIVE_WATERMASS_COUNT:
            return {
                ...state,
                watermass: {
                    ...state.watermass,
                    number: action.watermassCount,
                    name: i18n.watermasses,
                },
            }
        case RECEIVE_METHOD_COUNT:
            return {
                ...state,
                method: {
                    ...state.method,
                    number: action.methodCount,
                    name: i18n.methods,
                },
            }
        case RECEIVE_PARAMETER_COUNT:
            return {
                ...state,
                parameter: {
                    ...state.parameter,
                    number: action.parameterCount,
                    name: i18n.parameters,
                },
            }
        case RECEIVE_SUPPORT_COUNT:
            return {
                ...state,
                support: {
                    ...state.support,
                    number: action.supportCount,
                    name: i18n.supports,
                },
            }
        case RECEIVE_UNIT_COUNT:
            return {
                ...state,
                unit: {
                    ...state.unit,
                    number: action.unitCount,
                    name: i18n.units,
                },
            }
        case RECEIVE_HYDROGEOLOGICALENTITY_COUNT:
            return {
                ...state,
                hydrogeologicalEntity: {
                    ...state.hydrogeologicalEntity,
                    number: action.hydrogeologicalEntityCount,
                    name: i18n.hydrogeologicalEntities,
                },
            }
        case RECEIVE_WATERSHED_COUNT:
            return {
                ...state,
                watershed: {
                    ...state.watershed,
                    number: action.watershedCount,
                    name: i18n.watersheds,
                },
            }
        case RECEIVE_TAXON_COUNT:
            return {
                ...state,
                taxon: {
                    ...state.taxon,
                    number: action.taxonCount,
                    name: i18n.taxons,
                },
            }
        case RECEIVE_ACTIVITY_COUNT:
            return {
                ...state,
                activity: {
                    ...state.activity,
                    number: action.activityCount,
                    name: i18n.activities,
                },
            }
        case RECEIVE_COUNTRY_COUNT:
            return {
                ...state,
                country: {
                    ...state.country,
                    number: action.countryCount,
                    name: i18n.country,
                },
            }
        case RECEIVE_USAGE_COUNT:
            return {
                ...state,
                usage: {
                    ...state.usage,
                    number: action.usageCount,
                    name: i18n.usages,
                },
            }
        case RECEIVE_SECTOR_COUNT:
            return {
                ...state,
                sector: {
                    ...state.sector,
                    number: action.sectorCount,
                    name: i18n.sectors,
                },
            }
        default:
            return state
    }
}

export const store = {
    city: {
        id: 'COMMUNES',
        icon: 'location_city',
        url: 'city',
        name: '',
        date: '',
        number: '0',
        habilitation: H_REFERENCIAL_CITY,
    },
    contact: {
        id: 'CONTACTS',
        icon: 'contacts',
        url: 'contact',
        name: '',
        date: '',
        number: '0',
        habilitation: H_REFERENCIAL_CONTACT,
    },
    network: {
        id: 'RESEAUX',
        icon: 'language',
        url: 'network',
        name: '',
        date: '',
        number: '0',
        habilitation: H_REFERENCIAL_NETWORK,
    },
    fraction: {
        id: 'FRACTIONS',
        icon: 'gradient',
        url: 'fraction',
        name: '',
        date: '',
        number: '0',
        habilitation: H_REFERENCIAL_FRACTION,
    },
    contributor: {
        id: 'INTERVENANTS',
        icon: 'domain',
        url: 'contributor',
        name: '',
        date: '',
        number: '0',
        habilitation: H_REFERENCIAL_CONTRIBUTOR,
    },
    watermass: {
        id: 'MASSES_EAUX',
        icon: 'blur_circular',
        url: 'watermass',
        name: '',
        date: '',
        number: '0',
        habilitation: H_REFERENCIAL_WATERMASS,
    },
    method: {
        id: 'METHODES',
        icon: 'style',
        url: 'method',
        name: '',
        date: '',
        number: '0',
        habilitation: H_REFERENCIAL_METHOD,
    },
    parameter: {
        id: 'PARAMETRES',
        icon: 'bubble_chart',
        url: 'parameter',
        name: '',
        date: '',
        number: '0',
        habilitation: H_REFERENCIAL_PARAMETER,
    },
    support: {
        id: 'SUPPORTS',
        icon: 'widgets',
        url: 'support',
        name: '',
        date: '',
        number: '0',
        habilitation: H_REFERENCIAL_SUPPORT,
    },
    unit: {
        id: 'UNITES',
        icon: 'nature',
        url: 'unit',
        name: '',
        date: '',
        number: '0',
        habilitation: H_REFERENCIAL_UNIT,
    },
    hydrogeologicalEntity: {
        id: 'ENTITES_HYDROGEOLOGIQUES',
        icon: 'explicit',
        url: 'hydrogeologicalEntity',
        name: '',
        date: '',
        number: '0',
        habilitation: H_REFERENCIAL_HYDROGEOLOGICAL_ENTITY,
    },
    watershed: {
        id: 'BASSINS_VERSANTS',
        icon: 'landscape',
        url: 'watershed',
        name: '',
        date: '',
        number: '0',
        habilitation: H_REFERENCIAL_WATERSHED,
    },
    taxon: {
        id: 'TAXONS',
        icon: 'bug_report',
        url: 'taxon',
        name: '',
        date: '',
        number: '0',
        habilitation: H_REFERENCIAL_TAXON,
    },
    aquifer: {
        id: 'AQUIFERS',
        icon: 'line_weight',
        url: 'aquifer',
        name: '',
        date: '',
        number: '0',
        habilitation: H_REFERENCIAL_AQUIFER,
    },
    culture: {
        id: 'CULTURES',
        icon: 'local_florist',
        url: 'culture',
        name: '',
        date: '',
        number: '0',
        habilitation: H_REFERENCIAL_CULTURE,
    },
    zone: {
        id: 'ZONES',
        icon: 'map',
        url: 'zone',
        name: '',
        date: '',
        number: '0',
        habilitation: H_REFERENCIAL_ZONE,
    },
    driveMode: {
        id: 'DRIVE_MODES',
        icon: 'speed',
        url: 'driveMode',
        name: '',
        date: '',
        number: '0',
        habilitation: H_REFERENCIAL_DRIVEMODE,
    },
    activity: {
        id: 'ACTIVITY',
        icon: 'list',
        url: 'activity',
        name: '',
        date: '',
        number: '0',
        habilitation: H_REFERENCIAL_ACTIVITY,
    },
    country: {
        id: 'COUNTRY',
        icon: 'list',
        url: 'country',
        name: '',
        date: '',
        number: '0',
        habilitation: H_REFERENCIAL_COUNTRY,
    },
    usage: {
        id: 'USAGE',
        icon: 'art_track',
        url: 'usage',
        name: '',
        date: '',
        number: '0',
        habilitation: H_REFERENCIAL_USAGE,
    },
    rpg: {
        id: 'RPG',
        icon: 'border_all',
        url: 'rpg',
        name: 'RPG Explorer',
        date: '',
        number: '0',
        habilitation: H_REFERENCIAL_RPG,
    },
    sector: {
        id: 'SECTOR',
        icon: 'location_searching',
        url: 'sector',
        date: '',
        number: '0',
        habilitation: H_REFERENCIAL_SECTOR,
    },
}