import { Button, Dialog, Grid, Icon as IconMUI } from '@mui/material'
import Checkbox from 'components/forms/Checkbox'
import Icon from 'components/icon/Icon'
import { DialogActionsMUI, DialogContentMUI, DialogTitleMUI } from 'components/styled/Dialog'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import i18n from 'simple-react-i18n'

export default function ModalWarningDeleteExploit({ open, onCancel, onDelete }) {
    const [deleteInstallations, setDeleteInstallations] = useState(true)
    const [deleteContacts, setDeleteContacts] = useState(true)
    const [deleteIntervenant, setDeleteIntervenant] = useState(true)
    const [deleteMateriels, setDeleteMateriels] = useState(true)
    const [deleteUsers, setDeleteUsers] = useState(true)

    return (
        <Dialog
            fullWidth
            maxWidth='md'
            open={open}
        >
            <DialogTitleMUI style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Grid container alignItems='center'>
                    <IconMUI style={{ fontSize: '2.5rem', marginRight: 8 }}>warning</IconMUI>
                    <Grid item>{i18n.deleting}</Grid>
                </Grid>
                <Icon style={{ color: 'white' }} size='small' icon='close' onClick={onCancel} />
            </DialogTitleMUI>
            <DialogContentMUI className='padding-top-1 padding-bottom-0'>
                <span id='modal-text'>
                    {i18n.sureDeleteExploitation}
                </span>
                <p id='modal-text'>
                    {i18n.chooseDeleteExploitation}
                </p>
                <p>{i18n.delete} :</p>
                <Checkbox
                    label={i18n.installations}
                    checked={deleteInstallations}
                    onChange={() => setDeleteInstallations(!deleteInstallations)}
                />
                <Checkbox
                    label={i18n.materiels}
                    checked={deleteMateriels}
                    onChange={() => setDeleteMateriels(!deleteMateriels)}
                />
                <Checkbox
                    label={i18n.sampler}
                    checked={deleteIntervenant}
                    onChange={() => setDeleteIntervenant(!deleteIntervenant)}
                />
                <Checkbox
                    label={i18n.contacts}
                    checked={deleteContacts}
                    onChange={() => setDeleteContacts(!deleteContacts)}
                />
                <Checkbox
                    label={i18n.users}
                    checked={deleteUsers}
                    onChange={() => setDeleteUsers(!deleteUsers)}
                />
                <p className='italic-label'>{i18n.onlyLinkedElementsDelete}</p>
            </DialogContentMUI>
            <DialogActionsMUI style={{ justifyContent: 'space-between' }}>
                <div />
                <h5 id='modal-description' className='bold' style={{ color: 'red' }}>{i18n.irreversibleAction}</h5>
                <Button
                    variant='contained'
                    className='red'
                    style={{ color: 'white' }}
                    onClick={() => onDelete({
                        deleteInstallations,
                        deleteContacts,
                        deleteIntervenant,
                        deleteMateriels,
                        deleteUsers,
                    })}
                >
                    {i18n.confirm}
                </Button>
            </DialogActionsMUI>
        </Dialog>
    )
}

ModalWarningDeleteExploit.propTypes = {
    open: PropTypes.bool.isRequired,
    onCancel: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
}
