import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import Select from '../../../../../components/forms/Select'
import Textarea from '../../../../../components/forms/Textarea'
import Row from '../../../../../components/react/Row'
import { SANDRE } from '../../../../../referencial/constants/ReferencialConstants'
import DtoSandreCode from '../../../../../referencial/dto/DtoSandreCode'
import { arrayOf, getSandreList } from '../../../../../utils/StoreUtils'
import DtoProtection from '../../../../dto/installation/DtoProtection'

class ProtectionModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            protection: { ...props.protection, idStation: props.id },
        }
    }

    componentDidUpdate(prevProps) {
        if (!prevProps.popupIsOpen && this.props.popupIsOpen) {
            $('#descriptionModal').on('click', this.onSave)
        }
    }
    onSave = () => {
        this.props.saveResult(new DtoProtection(this.state.protection))
        $('.modal').modal('close')
    }

    onChangeElement = (changes) => this.setState({ protection: { ...this.state.protection, ...changes } })

    render() {
        const { protection } = this.state
        return (
            <div>
                <Row>
                    <Select
                        col={ 6 }
                        label={ i18n.localisation }
                        options={ getSandreList(this.props.sandreCodes, SANDRE.INSTALLATIONS_EMPLACEMENT) }
                        value={ protection.placing }
                        onChange={ v => this.onChangeElement({ placing: v }) }
                    />
                    <Select
                        col={ 6 }
                        label={ i18n.type }
                        options={ getSandreList(this.props.sandreCodes, SANDRE.INSTALLATIONS_PROTECTION) }
                        value={ protection.protectionType }
                        onChange={ v => this.onChangeElement({ protectionType: v }) }
                    />
                </Row>
                <div className='row no-margin padding-top-1'>
                    <Textarea
                        col={12}
                        title={i18n.comment}
                        value={protection.comment}
                        onChange={v => this.onChangeElement({ comment: v })}
                    />
                </div>
            </div>
        )
    }
}

ProtectionModal.propTypes = {
    protection: PropTypes.instanceOf(DtoProtection),
    sandreCodes: arrayOf(DtoSandreCode),
    id: PropTypes.number,
    popupIsOpen: PropTypes.bool,
    saveResult: PropTypes.func,
}

const mapStateToProps = store => ({
    popupIsOpen: store.SieauReducer.popupIsOpen,
    sandreCodes: store.ReferencialReducer.sandreCodes,
})

export default connect(mapStateToProps)(ProtectionModal)
