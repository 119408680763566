import { Grid } from '@mui/material'
import Table from 'components/datatable/Table'
import { AccordionDetailsMUI, AccordionMUI, AccordionSummaryMUI } from 'components/styled/Accordions'
import { getEventTypeInfo } from 'events/utils/EventUtils'
import { countBy, groupBy, isUndefined, keys, orderBy, uniq } from 'lodash'
import React, { useMemo } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { nbPerPageLabelShort } from 'referencial/constants/ReferencialConstants'
import { getDate, getYear } from 'utils/DateUtil'
import useListIndexed from 'utils/customHook/useListIndexed'
import useSandreList from 'utils/customHook/useSandreList'
import i18n from 'simple-react-i18n'
import PropTypes from 'prop-types'
import DtoInterventionEvent from 'piezometry/dto/DtoInterventionEvent'

const GroupAccordion = ({
    defaultExpanded = false,
    title = i18n.unknown,
    events = [],
    headers = [],
}) => {
    const allInterventionType = events.flatMap(e => e.interventions?.map(i => i.split(':')[0]) ?? [])
    const {
        CURATIF: nbCurative = 0,
        PREVENTIF: nbPreventive = 0,
        AUTRE: nbOther = 0,
    } = countBy(allInterventionType, i => i)

    return (
        <AccordionMUI defaultExpanded={defaultExpanded}>
            <AccordionSummaryMUI sx={{ height: 40 }}>
                <Grid container alignItems='center' spacing={1}>
                    <Grid item xs={6} style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>
                        <span style={{ fontSize: '1.3rem', whiteSpace: 'nowrap' }}>
                            {title}
                        </span>
                    </Grid>
                    <Grid item xs={2}>
                        <span style={{ fontSize: '1.3rem' }}>
                            {`${nbCurative} Curatifs`}
                        </span>
                    </Grid>
                    <Grid item xs={2}>
                        <span style={{ fontSize: '1.3rem' }}>
                            {`${nbPreventive} Préventifs`}
                        </span>
                    </Grid>
                    <Grid item xs={2}>
                        <span style={{ fontSize: '1.3rem' }}>
                            {`${nbOther} Autres`}
                        </span>
                    </Grid>
                </Grid>
            </AccordionSummaryMUI>
            <AccordionDetailsMUI sx={{ padding: 0 }}>
                <Table
                    data={orderBy(events, ['stationCode', 'timestamp'], ['asc', 'desc'])}
                    sortable
                    showTitle={false}
                    paging
                    color
                    nbPerPageLabel={nbPerPageLabelShort}
                    type={{ headers }}
                />
            </AccordionDetailsMUI>
        </AccordionMUI>
    )
}

GroupAccordion.propTypes = {
    defaultExpanded: PropTypes.bool,
    title: PropTypes.string,
    events: PropTypes.arrayOf(PropTypes.shape({
        stationCode: PropTypes.string,
        timestamp: PropTypes.number,
        interventions: PropTypes.arrayOf(PropTypes.string),
    })),
    headers: PropTypes.arrayOf(PropTypes.string),
}

const Tooltip = ({
    listLabel = [],
}) => (
    <div>
        {listLabel.map(label => (
            <>
                <span>- {label}</span>
                <br />
            </>
        ))}
    </div>
)

Tooltip.propTypes = {
    listLabel: PropTypes.arrayOf(PropTypes.string),
}

const EventsGroupByDate = ({
    events = [],
}) => {
    const {
        eventDiagnostics,
        eventSolutions,
        piezometers,
    } = useSelector(store => ({
        eventDiagnostics: store.EventsReducer.eventPiezometerDiagnostics,
        eventSolutions: store.EventsReducer.eventPiezometerSolutions,
        piezometers: store.PiezometryReducer.piezometersLight,
    }), shallowEqual)

    const piezometersIndexed = useListIndexed(piezometers)

    const interventionPreventive = useSandreList('CAUSE_INTERVENTION.PREVENTIF')
    const interventionCurative = useSandreList('CAUSE_INTERVENTION.CURATIF')
    const interventionOther = useSandreList('CAUSE_INTERVENTION.AUTRE')

    const interventions = {
        PREVENTIF: interventionPreventive,
        CURATIF: interventionCurative,
        AUTRE: interventionOther,
    }

    const eventTypeInfo = useMemo(() => getEventTypeInfo(), [])

    const formattedEvents = events.map(e => {
        const causes = e.interventions?.map(intervention => {
            const [type, id] = intervention.split(':')
            const parseId = parseInt(id)
            return interventions[type]?.find(i => i.code === parseId)?.name ?? ''
        })

        const diagnostics = e.uniqDiagnostics.map(id => eventDiagnostics.find(d => d.codeDiagnostic === id)?.description).filter(d => !!d)
        const solutions = e.uniqSolutions.map(id => eventSolutions.find(d => d.codeSolution === id)?.description).filter(d => !!d)

        const piezo = piezometersIndexed[e.piezometer]
        return {
            eventType: { value: eventTypeInfo[e.eventType].label, leftIcon: eventTypeInfo[e.eventType].icon, leftIconColor: 'black', leftIconSize: '16px', style: { marginLeft: '5px' } },
            date: { value: getDate(e.date) },
            station: { value: piezo?.code, setTooltip: () => piezo?.name },
            cause: { value: causes?.join(', ') ?? '' },
            diagnostics: { value: e.uniqDiagnostics.length ?? 0, setTooltip: diagnostics?.length ? () => (<Tooltip listLabel={diagnostics} />) : undefined },
            actions: { value: e.uniqSolutions.length ?? 0, setTooltip: solutions?.length ? () => (<Tooltip listLabel={solutions} />) : undefined },

            timestamp: e.date,
            stationCode: piezo?.code,
            interventions: e.interventions,
        }
    })
    const groupedEvent = groupBy(formattedEvents, e => !isUndefined(e.timestamp) ? getYear(e.timestamp) : undefined)
    const dates = keys(groupedEvent).map(d => parseInt(d)).filter(d => !!d)
    return (
        <Grid container direction='column' spacing={1}>
            {
                orderBy(dates, d => d, 'desc').map((date, i) => (
                    <Grid item>
                        <GroupAccordion
                            title={date}
                            events={groupedEvent[date]}
                            defaultExpanded={i === 0}
                            headers={['eventType', 'station', 'date', 'cause', 'diagnostics', 'actions']}
                        />
                    </Grid>
                ))
            }
        </Grid>
    )
}

EventsGroupByDate.propTypes = {
    events: PropTypes.arrayOf(PropTypes.instanceOf(DtoInterventionEvent)),
}

const EventsGroupByReferent = ({
    events = [],
}) => {
    const {
        eventDiagnostics,
        eventSolutions,
        piezometers,
        contacts,
    } = useSelector(store => ({
        eventDiagnostics: store.EventsReducer.eventPiezometerDiagnostics,
        eventSolutions: store.EventsReducer.eventPiezometerSolutions,
        piezometers: store.PiezometryReducer.piezometersLight,
        contacts: store.ContactReducer.contacts,
    }), shallowEqual)

    const piezometersIndexed = useListIndexed(piezometers)
    const contactsIndexed = useListIndexed(contacts)

    const interventionPreventive = useSandreList('CAUSE_INTERVENTION.PREVENTIF')
    const interventionCurative = useSandreList('CAUSE_INTERVENTION.CURATIF')
    const interventionOther = useSandreList('CAUSE_INTERVENTION.AUTRE')

    const interventions = {
        PREVENTIF: interventionPreventive,
        CURATIF: interventionCurative,
        AUTRE: interventionOther,
    }

    const eventTypeInfo = useMemo(() => getEventTypeInfo(), [])

    const formattedEvents = events.map(e => {
        const causes = e.interventions?.map(intervention => {
            const [type, id] = intervention.split(':')
            const parseId = parseInt(id)
            return interventions[type]?.find(i => i.code === parseId)?.name ?? ''
        })

        const diagnostics = e.uniqDiagnostics.map(id => eventDiagnostics.find(d => d.codeDiagnostic === id)?.description).filter(d => !!d)
        const solutions = e.uniqSolutions.map(id => eventSolutions.find(d => d.codeSolution === id)?.description).filter(d => !!d)

        const piezo = piezometersIndexed[e.piezometer]
        return {
            eventType: { value: eventTypeInfo[e.eventType].label, leftIcon: eventTypeInfo[e.eventType].icon, leftIconColor: 'black', leftIconSize: '16px', style: { marginLeft: '5px' } },
            date: { value: getDate(e.date) },
            station: { value: piezo?.code, setTooltip: () => piezo?.name },
            cause: { value: causes?.join(', ') ?? '' },
            diagnostics: { value: e.uniqDiagnostics.length ?? 0, setTooltip: diagnostics?.length ? () => (<Tooltip listLabel={diagnostics} />) : undefined },
            actions: { value: e.uniqSolutions.length ?? 0, setTooltip: solutions?.length ? () => (<Tooltip listLabel={solutions} />) : undefined },
            referent: contactsIndexed[e.referent]?.name ?? i18n.unknown,

            timestamp: e.date,
            stationCode: piezo?.code,
            interventions: e.interventions,
        }
    })
    const groupedEvents = groupBy(formattedEvents, 'referent')
    const orderedKeys = uniq([i18n.unknown, ...keys(groupedEvents)]).reverse()
    return (
        <Grid container direction='column' spacing={1}>
            {
                orderedKeys.map((key, i) => (
                    <Grid item>
                        <GroupAccordion
                            title={key}
                            events={groupedEvents[key]}
                            defaultExpanded={i === 0}
                            headers={['eventType', 'station', 'date', 'cause', 'diagnostics', 'actions']}
                        />
                    </Grid>
                ))
            }
        </Grid>
    )
}

EventsGroupByReferent.propTypes = {
    events: PropTypes.arrayOf(PropTypes.instanceOf(DtoInterventionEvent)),
}

const EventsGroupByEventType = ({
    events = [],
}) => {
    const {
        eventDiagnostics,
        eventSolutions,
        piezometers,
    } = useSelector(store => ({
        eventDiagnostics: store.EventsReducer.eventPiezometerDiagnostics,
        eventSolutions: store.EventsReducer.eventPiezometerSolutions,
        piezometers: store.PiezometryReducer.piezometersLight,
    }), shallowEqual)

    const piezometersIndexed = useListIndexed(piezometers)

    const interventionPreventive = useSandreList('CAUSE_INTERVENTION.PREVENTIF')
    const interventionCurative = useSandreList('CAUSE_INTERVENTION.CURATIF')
    const interventionOther = useSandreList('CAUSE_INTERVENTION.AUTRE')

    const interventions = {
        PREVENTIF: interventionPreventive,
        CURATIF: interventionCurative,
        AUTRE: interventionOther,
    }

    const eventTypeInfo = getEventTypeInfo()

    const formattedEvents = events.map(e => {
        const causes = e.interventions?.map(intervention => {
            const [type, id] = intervention.split(':')
            const parseId = parseInt(id)
            return interventions[type]?.find(i => i.code === parseId)?.name ?? ''
        })

        const diagnostics = e.uniqDiagnostics.map(id => eventDiagnostics.find(d => d.codeDiagnostic === id)?.description).filter(d => !!d)
        const solutions = e.uniqSolutions.map(id => eventSolutions.find(d => d.codeSolution === id)?.description).filter(d => !!d)

        const piezo = piezometersIndexed[e.piezometer]

        return {
            date: { value: getDate(e.date) },
            station: { value: piezo?.code },
            stationName: { value: piezo?.name },
            cause: { value: causes?.join(', ') ?? '' },
            diagnostics: { value: e.uniqDiagnostics.length ?? 0, setTooltip: diagnostics?.length ? () => (<Tooltip listLabel={diagnostics} />) : undefined },
            actions: { value: e.uniqSolutions.length ?? 0, setTooltip: solutions?.length ? () => (<Tooltip listLabel={solutions} />) : undefined },
            eventType: e.eventType,

            timestamp: e.date,
            stationCode: piezo?.code,
            interventions: e.interventions,
        }
    })

    const groupedEvents = groupBy(formattedEvents, 'eventType')

    return (
        <Grid container direction='column' spacing={1}>
            {
                keys(groupedEvents).map((key, i) => !!groupedEvents[key]?.length && (
                    <Grid item>
                        <GroupAccordion
                            title={eventTypeInfo[key]?.label}
                            events={groupedEvents[key]}
                            defaultExpanded={i === 0}
                            headers={['station', 'stationName', 'date', 'cause', 'diagnostics', 'actions']}
                        />
                    </Grid>
                ))
            }
        </Grid>
    )
}

EventsGroupByEventType.propTypes = {
    events: PropTypes.arrayOf(PropTypes.instanceOf(DtoInterventionEvent)),
}

const EventsGroupByStation = ({
    events = [],
}) => {
    const {
        eventDiagnostics,
        eventSolutions,
        piezometers,
    } = useSelector(store => ({
        eventDiagnostics: store.EventsReducer.eventPiezometerDiagnostics,
        eventSolutions: store.EventsReducer.eventPiezometerSolutions,
        piezometers: store.PiezometryReducer.piezometersLight,
    }), shallowEqual)

    const piezometersIndexed = useListIndexed(piezometers)

    const interventionPreventive = useSandreList('CAUSE_INTERVENTION.PREVENTIF')
    const interventionCurative = useSandreList('CAUSE_INTERVENTION.CURATIF')
    const interventionOther = useSandreList('CAUSE_INTERVENTION.AUTRE')

    const interventions = {
        PREVENTIF: interventionPreventive,
        CURATIF: interventionCurative,
        AUTRE: interventionOther,
    }

    const eventTypeInfo = useMemo(() => getEventTypeInfo(), [])

    const formattedEvents = events.map(e => {
        const causes = e.interventions?.map(intervention => {
            const [type, id] = intervention.split(':')
            const parseId = parseInt(id)
            return interventions[type]?.find(i => i.code === parseId)?.name ?? ''
        })

        const diagnostics = e.uniqDiagnostics.map(id => eventDiagnostics.find(d => d.codeDiagnostic === id)?.description).filter(d => !!d)
        const solutions = e.uniqSolutions.map(id => eventSolutions.find(d => d.codeSolution === id)?.description).filter(d => !!d)

        const piezo = piezometersIndexed[e.piezometer]
        return {
            eventType: { value: eventTypeInfo[e.eventType].label, leftIcon: eventTypeInfo[e.eventType].icon, leftIconColor: 'black', leftIconSize: '16px', style: { marginLeft: '5px' } },
            date: { value: getDate(e.date) },
            cause: { value: causes?.join(', ') ?? '' },
            diagnostics: { value: e.uniqDiagnostics.length ?? 0, setTooltip: diagnostics?.length ? () => (<Tooltip listLabel={diagnostics} />) : undefined },
            actions: { value: e.uniqSolutions.length ?? 0, setTooltip: solutions?.length ? () => (<Tooltip listLabel={solutions} />) : undefined },
            stationId: e.piezometer,

            timestamp: e.date,
            stationCode: piezo?.code,
            interventionType: e.interventionType,
        }
    })
    const groupedEvents = groupBy(formattedEvents, 'stationId')
    const orderedKeys = uniq(['undefined', ...keys(groupedEvents)]).reverse()
    return (
        <Grid container direction='column' spacing={1}>
            {
                orderedKeys.map((key, i) => {
                    const piezo = piezometersIndexed[key]
                    return (
                        <Grid item>
                            <GroupAccordion
                                title={`${piezo?.code ? `[${piezo.code}] `: ''}${piezo?.name ?? ''}` || i18n.unknown}
                                events={groupedEvents[key]}
                                defaultExpanded={i === 0}
                                headers={['eventType', 'date', 'cause', 'diagnostics', 'actions']}
                            />
                        </Grid>
                    )
                })
            }
        </Grid>
    )
}

EventsGroupByStation.propTypes = {
    events: PropTypes.arrayOf(PropTypes.instanceOf(DtoInterventionEvent)),
}

const EventsGroupByCauseIntervention = ({
    events = [],
}) => {
    const {
        eventDiagnostics,
        eventSolutions,
        piezometers,
    } = useSelector(store => ({
        eventDiagnostics: store.EventsReducer.eventPiezometerDiagnostics,
        eventSolutions: store.EventsReducer.eventPiezometerSolutions,
        piezometers: store.PiezometryReducer.piezometersLight,
    }), shallowEqual)

    const piezometersIndexed = useListIndexed(piezometers)

    const interventionPreventive = useSandreList('CAUSE_INTERVENTION.PREVENTIF')
    const interventionCurative = useSandreList('CAUSE_INTERVENTION.CURATIF')
    const interventionOther = useSandreList('CAUSE_INTERVENTION.AUTRE')

    const interventions = {
        PREVENTIF: interventionPreventive,
        CURATIF: interventionCurative,
        AUTRE: interventionOther,
    }

    const eventTypeInfo = getEventTypeInfo()

    const formattedEvents = events.flatMap(e => {
        const causes = e.interventions?.map(intervention => {
            const [type, id] = intervention.split(':')
            const parseId = parseInt(id)
            return interventions[type]?.find(i => i.code === parseId)?.name ?? ''
        })

        const diagnostics = e.uniqDiagnostics.map(id => eventDiagnostics.find(d => d.codeDiagnostic === id)?.description).filter(d => !!d)
        const solutions = e.uniqSolutions.map(id => eventSolutions.find(d => d.codeSolution === id)?.description).filter(d => !!d)

        const piezo = piezometersIndexed[e.piezometer]
        const baseEvent = {
            eventType: { value: eventTypeInfo[e.eventType].label, leftIcon: eventTypeInfo[e.eventType].icon, leftIconColor: 'black', leftIconSize: '16px', style: { marginLeft: '5px' } },
            date: { value: getDate(e.date) },
            station: { value: piezo?.code, setTooltip: () => piezo?.name },
            cause: { value: causes?.join(', ') ?? '' },
            diagnostics: { value: e.uniqDiagnostics.length ?? 0, setTooltip: diagnostics?.length ? () => (<Tooltip listLabel={diagnostics} />) : undefined },
            actions: { value: e.uniqSolutions.length ?? 0, setTooltip: solutions?.length ? () => (<Tooltip listLabel={solutions} />) : undefined },

            timestamp: e.date,
            stationCode: piezo?.code,
            interventions: e.interventions,
        }
        if (!causes?.length) {
            return [{ ...baseEvent, intervention: i18n.unknown }]
        }
        return causes.map(c => ({
            ...baseEvent,
            intervention: c,
        }))
    })

    const groupedEvents = groupBy(formattedEvents, 'intervention')
    const orderedKeys = uniq([i18n.unknown, ...keys(groupedEvents)]).reverse()
    return (
        <Grid container direction='column' spacing={1}>
            {
                orderedKeys.map((key, i) => (
                    <Grid item>
                        <GroupAccordion
                            title={key}
                            events={groupedEvents[key]}
                            defaultExpanded={i === 0}
                            headers={['eventType', 'station', 'date', 'cause', 'diagnostics', 'actions']}
                        />
                    </Grid>
                ))
            }
        </Grid>
    )
}

EventsGroupByCauseIntervention.propTypes = {
    events: PropTypes.arrayOf(PropTypes.instanceOf(DtoInterventionEvent)),
}

export {
    EventsGroupByDate,
    EventsGroupByReferent,
    EventsGroupByEventType,
    EventsGroupByStation,
    EventsGroupByCauseIntervention,
}