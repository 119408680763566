import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import Input from '../../../../components/forms/Input'
import Select from '../../../../components/forms/Select'
import Textarea from '../../../../components/forms/Textarea'
import Row from '../../../../components/react/Row'
import ContactDto from '../../../../referencial/components/contact/dto/ContactDto'
import ContributorDto from '../../../../referencial/components/contributor/dto/ContributorDto'
import { SANDRE } from '../../../../referencial/constants/ReferencialConstants'
import DtoSandreCode from '../../../../referencial/dto/DtoSandreCode'
import { getHour } from '../../../../utils/DateUtil'
import { onChangeHour } from '../../../../utils/FormUtils'
import { arrayOf, getSandreList } from '../../../../utils/StoreUtils'
import DtoFunction from '../../../dto/DtoFunction'
import SimpleDatePicker from 'components/forms/SimpleDatePicker'

class FunctionModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            functionElement: { ...props.functionElement, idStation: props.id },
        }
    }

    componentDidUpdate(prevProps) {
        if (!prevProps.popupIsOpen && this.props.popupIsOpen) {
            $('#descriptionModal').on('click', this.onSave)
        }
    }

    onSave = () => {
        this.props.saveResult(new DtoFunction(this.state.functionElement))
        $('.modal').modal('close')
    }

    onChangeElement = (changes) => this.setState({ functionElement: { ...this.state.functionElement, ...changes } })

    render() {
        const { functionElement } = this.state
        return (
            <div>
                <Row>
                    <SimpleDatePicker
                        col={6}
                        value={functionElement.startDate}
                        label={i18n.startDate}
                        onChange={v => this.onChangeElement({ startDate: v })}
                        max={functionElement.endDate}
                    />
                    <Input col={ 6 } title={ i18n.startHour } value={ getHour(functionElement.startDate) }
                        onChange={ v => onChangeHour(v, v2 => this.onChangeElement({ startDate: v2 }), { max: functionElement.endDate }, functionElement.startDate) }
                    />
                </Row>
                <Row>
                    <SimpleDatePicker
                        col={6}
                        value={functionElement.endDate}
                        label={i18n.endDate}
                        onChange={v => this.onChangeElement({ endDate: v })}
                        min={functionElement.startDate}
                    />
                    <Input col={ 6 } title={ i18n.endHour } value={ getHour(functionElement.endDate) }
                        onChange={ v => onChangeHour(v, v2 => this.onChangeElement({ endDate: v2 }), { min: functionElement.startDate }, functionElement.endDate) }
                    />
                </Row>
                <div className='row no-margin padding-top-1'>
                    <Select col={ 6 } options={ getSandreList(this.props.sandreCodes, SANDRE.FONCTIONS) } label={ i18n.functions }
                        value={ functionElement.functionCode } onChange={ v => this.onChangeElement({ functionCode: v }) }
                    />
                </div>
                <div className='row no-margin padding-top-1'>
                    <Textarea col={ 12 } title={ i18n.description } value={ functionElement.description } onChange={ v => this.onChangeElement({ description: v }) }/>
                </div>
            </div>
        )
    }
}

FunctionModal.propTypes = {
    functionElement: PropTypes.instanceOf(DtoFunction),
    id: PropTypes.number,
    sandreCodes: PropTypes.arrayOf(PropTypes.instanceOf(DtoSandreCode)),
    popupIsOpen: PropTypes.bool,
    saveResult: PropTypes.func,
    contributors: arrayOf(ContributorDto),
    contacts: arrayOf(ContactDto),
}


const mapStateToProps = store => ({
    sandreCodes: store.ReferencialReducer.sandreCodes,
    popupIsOpen: store.SieauReducer.popupIsOpen,
    contributors: store.ContributorReducer.contributors,
    contacts: store.ContactReducer.contacts,
})

FunctionModal.defaultProps = {
    functionElement: {},
}

export default connect(mapStateToProps)(FunctionModal)
