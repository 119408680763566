import fetch from 'isomorphic-fetch'
import { RECEIVE_MANAGEMENT_UNIT, RECEIVE_MANAGEMENT_UNITS, RECEIVE_MANAGEMENT_UNITS_RESTRICTIONS } from '../constants/ManagementUnitConstants'
import ApplicationConf from '../../../../conf/ApplicationConf'
import { checkAuth, getAuthorization, getJson } from '../../../../utils/ActionUtils'
import ToastrAction from 'toastr/actions/ToastrAction'
import i18n from 'simple-react-i18n'
import LogAction from '../../../../log/actions/LogAction'

const ManagementUnitAction = {
    receiveManagementUnit(managementUnit) {
        return { type: RECEIVE_MANAGEMENT_UNIT, managementUnit }
    },
    fetchManagementUnit(id) {
        return (dispatch) => {
            return fetch(ApplicationConf.referencial.getManagementUnit(id), {
                method: 'GET',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then((json = []) => {
                    dispatch(ManagementUnitAction.receiveManagementUnit(json))
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.managementUnit} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError + i18n.managementUnit))
                })
        }
    },
    receiveManagementUnits: (managementUnits) => {
        return { type: RECEIVE_MANAGEMENT_UNITS, managementUnits }
    },
    fetchManagementUnits: () => dispatch => {
        return fetch(ApplicationConf.referencial.getAllManagementUnits(), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then(json => {
                dispatch(ManagementUnitAction.receiveManagementUnits(json))
            })
            .catch(err => {
                dispatch(LogAction.logError(`${i18n.fetchError + i18n.managementUnit} : ${err}`))
                dispatch(ToastrAction.error(i18n.fetchError + i18n.managementUnit))
            })
    },

    receiveManagementUnitsRestrictions: (managementUnitsRestrictions) => {
        return { type: RECEIVE_MANAGEMENT_UNITS_RESTRICTIONS, managementUnitsRestrictions }
    },
    fetchManagementUnitsRestrictions: () => dispatch => {
        return fetch(ApplicationConf.referencial.managementUnitsRestrictions(), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then(json => {
                dispatch(ManagementUnitAction.receiveManagementUnitsRestrictions(json))
            })
            .catch(err => {
                dispatch(LogAction.logError(`${i18n.fetchError + i18n.managementUnit} : ${err}`))
                dispatch(ToastrAction.error(i18n.fetchError + i18n.managementUnit))
            })
    },

    updateManagementUnitsRestrictions: (managementUnitsRestrictions) => dispatch => {
        return fetch(ApplicationConf.referencial.managementUnitsRestrictions(), {
            method: 'PUT',
            headers: getAuthorization(),
            body: JSON.stringify(managementUnitsRestrictions),
        })
            .then(checkAuth)
            .then(getJson)
            .then(json => {
                if (json.update >= 1) {
                    dispatch(ToastrAction.success(i18n.elementUpdateSuccess))
                } else {
                    throw new Error()
                }
            })
            .catch(err => {
                dispatch(LogAction.logError(`${i18n.fetchError + i18n.restrictions} : ${err}`))
                dispatch(ToastrAction.error(i18n.fetchError + i18n.restrictions))
            })
    },

    createManagementUnit: (managementUnit) => dispatch => {
        return fetch(ApplicationConf.referencial.createManagementUnit(), {
            method: 'POST',
            headers: getAuthorization(),
            body: JSON.stringify(managementUnit),
        })
            .then(checkAuth)
            .then(getJson)
            .then(json => {
                if (json.insert === 1) {
                    dispatch(ToastrAction.success(i18n.unitSuccessUpdated))
                    return json.id
                }
                throw new Error()
            })
            .catch(err => {
                dispatch(LogAction.logError(`${i18n.fetchError + i18n.managementUnit} : ${err}`))
                dispatch(ToastrAction.error(i18n.fetchError + i18n.managementUnit))
            })
    },

    updateManagementUnit: (managementUnit) => dispatch => {
        return fetch(ApplicationConf.referencial.updateManagementUnit(), {
            method: 'PUT',
            headers: getAuthorization(),
            body: JSON.stringify(managementUnit),
        })
            .then(checkAuth)
            .then(getJson)
            .then(json => {
                if (json.update >= 1) {
                    dispatch(ToastrAction.success(i18n.unitSuccessUpdated))
                } else {
                    throw new Error()
                }
            })
            .catch(err => {
                dispatch(LogAction.logError(`${i18n.fetchError + i18n.managementUnit} : ${err}`))
                dispatch(ToastrAction.error(i18n.fetchError + i18n.managementUnit))
            })
    },
}

export default ManagementUnitAction
