import React, { useMemo } from 'react'
import i18n from 'simple-react-i18n'
import moment from 'moment'
import PropTypes from 'prop-types'
import DtoWaterTurnsSlot from 'exploitations/dto/DtoWaterTurnsSlot'
import { AccordionDetails, Grid } from '@mui/material'
import { groupBy } from 'lodash'
import { AccordionMUI, AccordionSummaryMUI } from 'components/styled/Accordions'
import WaterTurnsTable from 'exploitations/components/waterTurns/WaterTurnsTable'
import { shallowEqual, useSelector } from 'react-redux'
import { getYear } from 'utils/DateUtil'

const WaterTurnsAccordion = ({
    data = [],
    readMode = false,
    colors = [],
    onOpenUpdate = () => { },
    deleteWaterTurns = () => { },
    yearKey = '0000',
    onApply = () => { },
    expanded = '',
    setExpanded = () => { },
}) => {
    const currentYear = useMemo(() => getYear(moment.now()) === parseInt(yearKey), [yearKey])

    const {
        waterTurnsSlots,
    } = useSelector(store => ({
        waterTurnsSlots: store.AgriReducer.waterTurnsSlots,
    }), shallowEqual)
    const groupedSlotData = groupBy(data, 'idSlot')

    return (
        <Grid item xs={12}>
            <AccordionMUI
                TransitionProps={{ unmountOnExit: true }}
                expanded={expanded === `panel_${yearKey ?? ''}`}
                onChange={(_, isExpanded) => setExpanded(isExpanded ? `panel_${yearKey ?? ''}` : '')}
                sx={{ border: 'solid 1px grey', marginBottom: '5px', marginTop: '5px' }}
                key={`panel_${yearKey ?? ''}`}
            >
                <AccordionSummaryMUI>
                    <Grid container alignItems='center'>
                        {yearKey !== 'undefined' && yearKey || i18n.notDefined}
                    </Grid>
                </AccordionSummaryMUI>
                <AccordionDetails style={{ paddingBottom: 0, paddingLeft: 10, paddingRight: 10 }}>
                    {Object.keys(groupedSlotData).map(idSlotKey => (
                        <Grid className='padding-bottom-1' key={`table_${idSlotKey}`}>
                            <WaterTurnsTable
                                slot={waterTurnsSlots.find(wts => wts.id === parseInt(idSlotKey)) || { nbSlots: 1, id: -1 }}
                                data={groupedSlotData[idSlotKey]}
                                readMode={readMode}
                                colors={colors}
                                openUpdate={onOpenUpdate}
                                onDelete={deleteWaterTurns}
                                onApply={onApply}
                                currentYear={currentYear}
                            />
                        </Grid>
                    ))}
                </AccordionDetails>
            </AccordionMUI>
        </Grid >
    )
}

WaterTurnsAccordion.propTypes = {
    data: PropTypes.arrayOf(PropTypes.shape({})),
    slot: PropTypes.instanceOf(DtoWaterTurnsSlot),
    colors: PropTypes.shape({}),
    readMode: PropTypes.bool,
    setOpenAdd: PropTypes.func,
    onOpenUpdate: PropTypes.func,
    deleteWaterTurns: PropTypes.func,
    onApply: PropTypes.func,
    yearKey: PropTypes.string,
    expanded: PropTypes.string,
    setExpanded: PropTypes.func,
}

export default WaterTurnsAccordion