import { groupBy, minBy, range, sum, orderBy, partition } from 'lodash'
import moment from 'moment'
import React from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import Axis from '../../../components/echart/Axis'
import EChart from '../../../components/echart/EChart'
import { getStatuses } from '../../../utils/QualityUtils'
import { getLabel } from '../../../utils/StoreUtils'
import { getStatusColor } from '../../../utils/AnalyseUtils'
import Bar from '../../../components/echart/series/Bar'
import { getColorCircleElement } from '../../../utils/ColorUtil'

// fais le bien au moins le composant 😅
// et tu as pleins de waring eslint
const OperationsCountChart = ({}) => {
    const {
        qualitoDashboard,
    } = useSelector(store => ({
        qualitoDashboard: store.QualityReducer.qualitoDashboard,
    }), shallowEqual)

    // fais qu'un seul return sur le composant, ça peut poser problème avec les hook
    if (!(qualitoDashboard?.stationsData || []).length) {
        return null
    }

    const redirect = (params) => { // TODO : redirection
        // const startDate = moment(`01/01/${year}`, 'DD/MM/YYYY').valueOf()
        // const endDate = moment(`31/12/${year}`, 'DD/MM/YYYY').valueOf()
        // AppStore.dispatch(push(`/station/quality/${stationId}/suiviPC?startDate=${startDate}&endDate=${endDate}`))
    }

    const minYear = minBy(qualitoDashboard.stationsData.flatMap(s => s.operations), 'year')?.year
    const yearTab = minYear ? range(minYear, moment().year() + 1) : []

    // aucun push, c'est pas compliqué à faire et ça sera plus lisible et maintenable
    const series = []
    const grid = []
    const xAxis = []
    const yAxis = []
    const gridsHeights = [10]

    qualitoDashboard.stationsData.forEach((station, idx) => {
        grid.push({
            top: sum(gridsHeights),
            right: 0,
            height: 120,
            left: qualitoDashboard.stationsData.length > 1 ? 60 : 30,
        })
        gridsHeights.push(150)
        xAxis.push(Axis({
            data: yearTab,
            gridIndex: idx
        }))
        yAxis.push(Axis({
            type: 'value',
            gridIndex: idx,
            minInterval: 1,
            showSplitLine: true,
            ...(qualitoDashboard.stationsData.length > 1 ? {
                nameLocation: 'middle',
                name: station.stationCode
            } : {})
        }))

        const groups = groupBy(station.operations, op => op.status)
        Object.keys(groups).forEach(key => {
            const ops = groups[key]

            const values = ops.reduce((acc, value) => {
                acc[value.year] = value.nb
                return acc
            }, {})

            series.push(Bar({
                stack: station.stationCode,
                gridIndex: idx,
                name: `${station.stationCode} ${i18n.operations} ${getLabel(getStatuses(), ops[0].status)}`,
                color: getStatusColor(ops[0].status),
                data: yearTab.map(y => values[y] || 0),
                xAxisIndex: idx,
                yAxisIndex: idx
            }))
        })
    })

    // le composant MultiChart peu peut être bien simplifier le tout
    const options = {
        series,
        xAxis,
        yAxis,
        height: sum(gridsHeights),
        name: 'total opérations',
        // gridHeight: sum(gridsHeights),
        grid,
        axisPointer: {
            link: { xAxisIndex: 'all' },
        },
        tooltip: {
            formatter: params => {
                const serie = series[params.seriesIndex]
                const year = yearTab[params.dataIndex]

                const displayed = orderBy(series.filter(s => s.obj.data[params.dataIndex] > 0), s => [s.obj.stack], ['asc']).map(s => {
                    return `${getColorCircleElement(s.obj.color)} ${s.obj.name} : ${s.obj.data[params.dataIndex]}`
                })
                const [thisStation, others] = partition(displayed, s => s.includes(serie.obj.stack))

                return `${year}<br/>${thisStation.join('<br/>')}<br/><br/>${others.join('<br/>')}`
            }
        }
    }
    return (
        <EChart options={ options } onClick={ redirect }/>
    )
}

export default OperationsCountChart
