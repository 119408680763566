import React, { useEffect, useMemo, useState } from 'react'
import Input from '../../../../../components/forms/Input'
import i18n from 'simple-react-i18n'
import SuperMultiAutocomplete from '../../../../../components/forms/SuperMultiAutocomplete'
import { getQualificationSelectOptions, getStatusSelectOptions, statusIcon } from '../../../../../utils/StatusUtil'
import MultiContributorsAutocomplete
    from '../../../../../referencial/components/contributor/components/MultiContributorsAutocomplete'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import useAbortController from '../../../../../utils/customHook/useAbortController'
import OperationAction from '../../../../actions/OperationAction'
import DtoOperationListSpecific from '../../dto/DtoOperationListSpecific'
import { groupBy, isNil, keys, last, omit, orderBy, sumBy, uniq, uniqBy } from 'lodash'
import { Accordion, AccordionSummary, Button, Card, CardContent, Divider, Grid2, Popover } from '@mui/material'
import SimpleTabList from '../../../../../components/list/SimpleTabList'
import MessageCard from '../../../../../components/card/MessageCard'
import ImportXmlQualityPopup from '../../../../../import/components/popup/ImportXmlQualityPopup'
import ProgressCard from '../../../../../components/card/ProgressCard'
import SupportAction from '../../../../../referencial/components/support/actions/SupportAction'
import PropTypes from 'prop-types'
import useActions from 'utils/customHook/useActions'
import { getLinks, getStationTitle } from 'utils/StationUtils'
import { getStationArrowNav } from 'utils/ActionUtils'
import { push } from '@lagunovsky/redux-react-router'
import useBoolean from 'utils/customHook/useBoolean'
import OperationPCStepper from '../stepper/OperationPCStepper'
import useTitle from 'utils/customHook/useTitle'
import useListIndexed from 'utils/customHook/useListIndexed'
import { searchAllCharacters } from 'utils/StringUtil'
import { getFullDate, getYear } from 'utils/DateUtil'
import { getIconQualification } from 'utils/AnalyseUtils'
import ExportFileModal from 'components/modal/ExportFileModal'
import ExportAction from 'export/actions/ExportAction'
import { exportModelFile, formatData, getModelFileType } from 'utils/ExportDataUtil'
import QualityAction from 'quality/actions/QualityAction'
import { getQualifications, getStatuses } from 'utils/QualityUtils'
import { useParams } from 'react-router'
import { NewTable, SORT, WrapperAccordionDetails } from 'components/datatable/NewTable'

const YEAR = 'YEAR'
const PRODUCER = 'PRODUCER'
const LABORATORY = 'LABORATORY'

const FilterFields = ({
    defaultFilter = {},
    onValidate = () => {},

    operations = [],
}) => {
    const {
        supports,
        contributors,
    } = useSelector(store => ({
        supports: store.SupportReducer.supports,
        contributors: store.ContributorReducer.contributors,
    }), shallowEqual)

    const indexedSupports = useListIndexed(supports, 'id')
    const indexedContributors = useListIndexed(contributors, 'id')

    const [filter, setFilter] = useState(defaultFilter)

    const status = useMemo(getStatusSelectOptions, [])
    const qualifications = useMemo(getQualificationSelectOptions, [])

    const filteredSupports = useMemo(() => {
        return uniqBy(operations, 'support').map(o => indexedSupports[o.support]).filter(s => !isNil(s))
    }, [indexedSupports, operations])

    const samplers = useMemo(() => {
        return uniqBy(operations, 'sampler').map(o => indexedContributors[o.sampler]).filter(s => !isNil(s))
    }, [indexedContributors, operations])

    const labos = useMemo(() => {
        return uniq(operations.flatMap(o => o.labo ?? [])).map(id => indexedContributors[id]).filter(s => !isNil(s))
    }, [indexedContributors, operations])

    const producers = useMemo(() => {
        return uniqBy(operations, 'producer').map(o => indexedContributors[o.producer]).filter(s => !isNil(s))
    }, [indexedContributors, operations])

    return (
        <Card>
            <CardContent>
                <Grid2 container spacing={1}>
                    <Grid2 size={3}>
                        <Input
                            value={filter.searchValue}
                            title={i18n.search}
                            onChange={v => setFilter(prev => ({ ...prev, searchValue: v }))}
                        />
                    </Grid2>
                    <Grid2 size={3}>
                        <SuperMultiAutocomplete
                            options={filteredSupports}
                            label={i18n.support}
                            displayWithCode
                            onChange={v => setFilter(prev => ({ ...prev, support: v }))}
                            values={filter.support}
                        />
                    </Grid2>
                    <Grid2 size={3}>
                        <SuperMultiAutocomplete
                            options={status}
                            label={i18n.status}
                            onChange={v => setFilter(prev => ({ ...prev, status: v }))}
                            values={filter.status}
                            keyValue='code'
                            noSort
                        />
                    </Grid2>
                    <Grid2 size={3}>
                        <SuperMultiAutocomplete
                            options={qualifications}
                            label={i18n.qualification}
                            onChange={v => setFilter(prev => ({ ...prev, qualification: v }))}
                            values={filter.qualification}
                            keyValue='code'
                            noSort
                        />
                    </Grid2>
                    <Grid2 size={3}>
                        <MultiContributorsAutocomplete
                            label={i18n.sampler}
                            options={samplers}
                            values={filter.samplers}
                            onChange={v => setFilter(prev => ({ ...prev, samplers: v }))}
                            keyLabel='labelDisplay'
                            displayWithCode
                            multiple
                        />
                    </Grid2>
                    <Grid2 size={3}>
                        <MultiContributorsAutocomplete
                            label={i18n.laboratory}
                            options={labos}
                            values={filter.labos}
                            onChange={v => setFilter(prev => ({ ...prev, labos: v }))}
                            keyLabel='labelDisplay'
                            displayWithCode
                            multiple
                        />
                    </Grid2>
                    <Grid2 size={3}>
                        <MultiContributorsAutocomplete
                            label={i18n.producer}
                            options={producers}
                            values={filter.producers}
                            onChange={v => setFilter(prev => ({ ...prev, producers: v }))}
                            keyLabel='labelDisplay'
                            displayWithCode
                            multiple
                        />
                    </Grid2>
                    <Grid2 size={9} />
                    <Grid2 size={3}>
                        <Button
                            onClick={() => onValidate(filter)}
                            variant='contained'
                            fullWidth
                        >
                            {i18n.search}
                        </Button>
                    </Grid2>
                </Grid2>
            </CardContent>
        </Card>
    )
}

FilterFields.propTypes = {
    defaultFilter: PropTypes.shape({}),
    onValidate: PropTypes.func,
    operations: PropTypes.arrayOf(PropTypes.shape({})),
}

const AccordionGroup = ({
    title = '',
    operations = [],
    defaultExpanded = false,
    fetchOperationFiles = () => {},
}) => {
    const dispatch = useDispatch()

    const [shouldLoad, setShouldLoad] = useState(defaultExpanded)

    useEffect(() => {
        if (shouldLoad) {
            fetchOperationFiles(operations)
        }
    }, [shouldLoad])


    const nbAnalysis = sumBy(operations, o => o.nbAnalyse ?? 0)
    return (
        <Accordion
            defaultExpanded={defaultExpanded}
            onChange={(_, value) => {
                if (value) {
                    setShouldLoad(true)
                }
            }}
        >
            <AccordionSummary>
                <Grid2 container alignItems='center' sx={{ width: '100%' }}>
                    <Grid2 size={8}>
                        <span style={{ fontSize: '1.3rem' }}>
                            {title}
                        </span>
                    </Grid2>
                    <Grid2 size={2}>
                        <span style={{ fontSize: '1.3rem' }}>
                            {`${operations.length} ${operations.length > 1 ? i18n.operations : i18n.operation}`}
                        </span>
                    </Grid2>
                    <Grid2 size={2}>
                        <span style={{ fontSize: '1.3rem' }}>
                            {`${nbAnalysis} ${nbAnalysis > 1 ? i18n.oneAnalysis : i18n.analysis}`}
                        </span>
                    </Grid2>
                </Grid2>
            </AccordionSummary>
            <NewTable
                rows={operations}
                headers={['qualificationTableColor', 'date', 'nbAnalysis', 'support', 'sampler', 'laboratory', 'producer', 'samplePoint', 'nullValue']}
                onClickRow={(op) => dispatch(push(`/station/quality/${op.qualitometer}/operation/${op.id}`))}

                defaultSort={{ column: 'date', direction: SORT.DESC }}
                WrapperComponent={WrapperAccordionDetails}
            />
        </Accordion>
    )
}

AccordionGroup.propTypes = {
    title: PropTypes.string,
    operations: PropTypes.arrayOf(PropTypes.shape({})),
    defaultExpanded: PropTypes.bool,
    fetchOperationFiles: PropTypes.func,
}

const GroupOperations = ({
    operations = [],
    group = 'year',
    fetchOperationFiles = () => {},
}) => {
    const { ['undefined']: listUndefined = [], ...groupedOperations } = groupBy(operations, `group.${group}`)

    return (
        <Grid2 container spacing={1}>
            {orderBy(keys(groupedOperations), a => a, 'desc').map((key, i) => (
                <Grid2 key={key} size={12}>
                    <AccordionGroup
                        title={key}
                        operations={groupedOperations[key]}
                        defaultExpanded={i === 0}
                        fetchOperationFiles={fetchOperationFiles}
                    />
                </Grid2>
            ))}
            {listUndefined.length > 0 && (
                <Grid2 size={12}>
                    <AccordionGroup
                        title={i18n.undefined}
                        operations={listUndefined}
                        defaultExpanded={keys(groupedOperations).length === 0}
                        fetchOperationFiles={fetchOperationFiles}
                    />
                </Grid2>
            )}
        </Grid2>
    )
}

GroupOperations.propTypes = {
    operations: PropTypes.arrayOf(PropTypes.shape({})),
    group: PropTypes.string,
    fetchOperationFiles: PropTypes.func,
}

const PdfIcon = ({
    refLabo = '',
    pathPdf = '',
    setFilePopupParams = () => {},
}) => {
    const foundFiles = uniq(pathPdf.split(';'))
    return !!foundFiles.length && refLabo && (
        <div>
            <i
                className='clickable right material-icons font-size-16'
                onClick={e => {
                    e.preventDefault()
                    e.stopPropagation()
                    setFilePopupParams({
                        popupIsOpen: true,
                        anchorEl: e.currentTarget,
                        files: foundFiles.map(path => ({ url: path, name: last(path.split('/')) })),
                    })
                }}
            >
                attach_file
            </i>
        </div>
    )
}

PdfIcon.propTypes = {
    refLabo: PropTypes.string,
    pathPdf: PropTypes.string,
    setFilePopupParams: PropTypes.func,
}

const ExportModal = ({
    isOpen = false,
    close = () => {},

    operations = [],
    filter = {},
}) => {
    const dispatch = useDispatch()

    const {
        typeEnvironmentModels,
        qualitometer,
    } = useSelector(store => ({
        typeEnvironmentModels: store.ExportReducer.typeEnvironmentModels,
        qualitometer: store.QualityReducer.qualitometer,
    }), shallowEqual)

    const qualifications = useMemo(getQualifications, [])
    const status = useMemo(getStatuses, [])

    const operationsWithHeader = useMemo(() => {
        if (operations.length === 0) return []

        const formattedOperations = operations.map(operation => {
            const { op } = operation
            return {
                date: { value: getFullDate(op.date), format: 'dd/MM/yyyy HH:mm:ss', cellType: 'date' },
                nbAnalysis: { value: op.nbAnalysis, format: '0', cellType: 'number' },
                qualificationCode: { value: op.qualification, format: '0', cellType: 'number' },
                qualification: { value: qualifications.find(q => q.code === op.qualification)?.name ?? '' },
                statusCode: { value: op.status, format: '0', cellType: 'number' },
                status: { value: status.find(s => s.code === op.status)?.name ?? '' },
                supportCode: { value: op.support },
                support: operation.support,
                sampler: operation.sampler,
                laboratory: operation.laboratory,
                producer: operation.producer,
                samplePoint: operation.samplePoint,
            }
        })

        return formattedOperations.length ? [
            { ...formattedOperations[0], headers: keys(formattedOperations[0]) },
            ...formattedOperations.slice(1),
        ] : []
    }, [operations, qualifications, status])

    const tableExport = operationsWithHeader.length === 0 ? [] : [{
        name: i18n.resultsTable,
        formats: [{
            type: i18n.excelFile,
            callback: () => dispatch(ExportAction.export(formatData(operationsWithHeader), 'xlsx', `${qualitometer.code} - ${i18n.operations}`)),
        }, {
            type: i18n.csvFile,
            callback: () => dispatch(ExportAction.export(formatData(operationsWithHeader), 'csv', `${qualitometer.code} - ${i18n.operations}`)),
        }],
    }]

    const exportModel = typeEnvironmentModels.map((model) => {
        const fileNameSplit = model.split('.')
        const name = fileNameSplit.slice(0, -1).join('')
        const ext = fileNameSplit[fileNameSplit.length - 1]
        return {
            name,
            formats: [{
                type: getModelFileType(ext),
                callback: () => exportModelFile({
                    stationId: qualitometer.id.toString(),
                    stationCode: qualitometer.code,
                    stationType: qualitometer.typeName,
                    environmentModels: typeEnvironmentModels,
                    filenameModelExport: model,
                    qualityFilter: {
                        stations: [qualitometer.id],
                        support: filter.support,
                        status: isNil(filter.status) ? undefined : `${filter.status}`,
                        qualification: isNil(filter.qualification) ? undefined : `${filter.qualification}`,
                        samplers: filter.samplers,
                        laboratories: filter.labos,
                        producers: filter.producers,
                    },
                }),
            }],
        }
    })

    return (
        <ExportFileModal
            open={isOpen}
            onClose={close}
            data={[ ...tableExport, ...exportModel ]}
        />
    )
}

ExportModal.propTypes = {
    isOpen: PropTypes.bool,
    close: PropTypes.func,

    operations: PropTypes.arrayOf(PropTypes.shape({})),
    filter: PropTypes.shape({}),
}

const OperationsListApp = () => {
    const dispatch = useDispatch()
    const { id } = useParams()

    const {
        controllerRef,
        initController,
    } = useAbortController()

    const {
        qualitometer,
        qualitometers,
        accountUser,
        supports,
        contributors,
    } = useSelector(store => ({
        qualitometer: store.QualityReducer.qualitometer,
        qualitometers: store.QualityReducer.qualitometersLight,
        accountUser: store.AccountReducer.accountUser,
        supports: store.SupportReducer.supports,
        contributors: store.ContributorReducer.contributors,
    }), shallowEqual)

    const indexedSupports = useListIndexed(supports, 'id')
    const indexedContributors = useListIndexed(contributors, 'id')

    const [currentTab, setCurrentTab] = useState(YEAR)
    const [filter, setFilter] = useState({})
    const [operations, setOperations] = useState([])
    const [filePopupParams, setFilePopupParams] = useState({ popupIsOpen: false, anchorEl: undefined, files: [] })
    const [operationFiles, setOperationFiles] = useState([])

    const {
        value: isLoaded,
        setTrue: setLoaded,
        setFalse: setNotLoaded,
    } = useBoolean(false)
    const {
        value: isOperationPopupOpen,
        setTrue: openOperationPopup,
        setFalse: closeOperationPopup,
    } = useBoolean(false)
    const {
        value: isImportModalOpen,
        setTrue: openImportModal,
        setFalse: closeImportModal,
    } = useBoolean(false)
    const {
        value: isExportModalOpen,
        setTrue: openExportModal,
        setFalse: closeExportModal,
    } = useBoolean(false)

    useTitle(() => [{
        title: i18n.quality,
        href: 'quality',
    }, {
        title: getStationTitle(qualitometer),
        href: `station/quality/${qualitometer.id}`,
    }, {
        title: i18n.operations,
        href: `station/quality/${qualitometer.id}/operation`,
    }], [qualitometer])

    useActions(() => {
        const managerActions = accountUser.isAdmin === '1' || accountUser.metadata === '1' ? { calculate: operations.map(o => o.operationId), import: openImportModal } : {}
        const actions = {
            ...managerActions,
            new: openOperationPopup,
            links: getLinks(qualitometer),
            arrowNav: getStationArrowNav('quality', qualitometers, qualitometer.id, s => dispatch(push(`/station/quality/${s.id}/operation`))),
            exportList: [{
                onClick: openExportModal,
                label: i18n.excel,
            }],
            controlAnalysis: { stations: [qualitometer.id] },
        }
        if (accountUser.metadata !== '1') {
            return omit(actions, ['new'])
        }
        return actions
    }, [accountUser, operations, qualitometer, qualitometers])

    useEffect(() => {
        if (supports.length === 0) {
            dispatch(SupportAction.fetchSupports())
        }
        if (qualitometers.length === 0) {
            dispatch(QualityAction.fetchQualitometersLight())
        }
    }, [])

    const loadOperations = () => {
        setNotLoaded()
        OperationAction.operationsListSpecific(id).then(json => {
            const operationsList = json.map(op => new DtoOperationListSpecific(op))
            setOperations(operationsList)
        }).finally(setLoaded)
    }

    useEffect(() => {
        initController()
        loadOperations()
        return () => {
            controllerRef.current.abort()
        }
    }, [])

    const fetchOperationFiles = (ope) => {
        const refs = ope.flatMap(o => o.op?.refLabo)
        if (refs.length) {
            OperationAction.getOperationFilesWithRefs(refs).then(json => {
                setOperationFiles(uniqBy([
                    ...operationFiles,
                    ...json,
                ]), 'refLabo')
            })
        }
    }

    const formattedOperations = useMemo(() => {
        return operations.map(op => {
            const notValidated = (op.nbAnalysis || 0) - (op.nbAnalysisValidated || 0)
            const icon = notValidated > 0 && (
                <div className='margin-right-1 valign-wrapper' style={{ fontSize: '0.70rem' }}>
                    <i className='material-icons tiny no-margin'>{getIconQualification(4)}</i>
                    <span>{notValidated}</span>
                </div>
            )
            const label = notValidated > 0 && `${notValidated} ${i18n.notValidated}`

            const date = getFullDate(op.date)
            const support = indexedSupports[op.support]?.name ?? ''
            const sampler = indexedContributors[op.sampler]?.labelDisplay ?? ''
            const listLabo = op.labo.map(l => indexedContributors[l]?.labelDisplay).filter(l => !isNil(l))
            const laboratories = listLabo.join(', ')
            const laboLabel = listLabo.length > 0 ? listLabo[0] + (listLabo.length > 1 ? ', ...' : '') : ''
            const producer = indexedContributors[op.producer]?.labelDisplay || (!isNil(op.producer) ? `<${op.producer}>` : '')
            const pathPDF = operationFiles.find(of => op.refLabo.includes(of.refLabo))?.path

            return {
                qualificationTableColor: { value: statusIcon(op, 25, true), className: 'no-padding' },
                date: { value: date, sortValue: op.date },
                nbAnalysis: icon ? {
                    value: (
                        <div>
                            <span>
                                {op.nbAnalysis}
                            </span>
                            <div style={{ display: 'flex' }}>
                                {icon}
                            </div>
                        </div>
                    ),
                    tooltip: label,
                    className: 'tooltipped',
                } : { value: op.nbAnalysis },
                support: { value: support },
                sampler: { value: sampler },
                laboratory: { value: laboLabel, setTooltip: listLabo.length > 1 ? () => listLabo.join('\n') : undefined },
                producer: { value: producer },
                samplePoint: { value: op.point },
                nullValue: { value: op.refLabo && pathPDF && <PdfIcon refLabo={op.refLabo} pathPdf={pathPDF} setFilePopupParams={setFilePopupParams} /> },
                id: op.id,
                qualitometer: qualitometer.id,
                nbAnalyse: op.nbAnalysis,
                op,
                group: {
                    year: getYear(op.date),
                    producer: producer || 'undefined',
                    laboratory: laboratories || 'undefined',
                },
                filterField: {
                    support: op.support,
                    status: op.status,
                    qualification: op.qualification,
                    sampler: op.sampler,
                    labo: op.labo,
                    producer: op.producer,
                    searchLabel: searchAllCharacters(`${date}#${support}#${sampler}#${laboratories}#${producer}#${op.point}`),
                },
            }
        })
    }, [indexedContributors, indexedSupports, operations, qualitometer.id, operationFiles])

    const filteredOperations = useMemo(() => {
        const {
            searchValue = '',
            support,
            status,
            qualification,
            samplers = [],
            labos = [],
            producers = [],
        } = filter

        const searchCharacters = searchAllCharacters(filter.searchValue)

        const withSupport = isNil(support) ? formattedOperations : formattedOperations.filter(op => `${op.filterField.support}` === filter.support)
        const withStatus = isNil(status) ? withSupport : withSupport.filter(op => op.filterField.status === filter.status)
        const withQualification = isNil(qualification) ? withStatus : withStatus.filter(op => op.filterField.qualification === filter.qualification)

        const withSamplers = samplers.length === 0 ? withQualification : withQualification.filter(op => filter.samplers.includes(op.filterField.sampler))
        const withLabos = labos.length === 0 ? withSamplers : withSamplers.filter(op => filter.labos.some(l => op.filterField.labo.includes(l)))
        const withProducers = producers.length === 0 ? withLabos : withLabos.filter(op => filter.producers.includes(op.filterField.producer))

        return orderBy(searchValue ? withProducers.filter(op => op.filterField.searchLabel.includes(searchCharacters)) : withProducers, op => op.date)
    }, [formattedOperations, filter])

    const tabs = useMemo(() => [
        {
            constant: YEAR,
            label: i18n.perYear,
            icon: 'insert_invitation',
        },
        {
            constant: PRODUCER,
            label: i18n.byProducer,
            icon: 'business_center',
        },
        {
            constant: LABORATORY,
            label: i18n.byLaboratory,
            icon: 'colorize',
        },
    ], [])

    if (!isLoaded) {
        return (
            <ProgressCard indeterminate />
        )
    }

    return (
        <div style={{ paddingTop: '10px', width: '1200px', marginRight: 'auto', marginLeft: 'auto' }}>
            <Grid2 container spacing={1}>
                <Grid2 size={12}>
                    <FilterFields
                        defaultFilter={filter}
                        onValidate={setFilter}

                        operations={operations}
                    />
                </Grid2>
                <Grid2 size={12}>
                    <SimpleTabList
                        controlTab={currentTab}
                        setControlTab={setCurrentTab}
                        tabs={tabs}
                    >
                        {
                            tab => {
                                if (!filteredOperations.length) {
                                    return (
                                        <MessageCard>{i18n.noOperationForTheseCriteria}</MessageCard>
                                    )
                                }
                                return (
                                    <div style={{ padding: '10' }}>
                                        {tab === YEAR && (<GroupOperations operations={filteredOperations} group='year' fetchOperationFiles={fetchOperationFiles} />)}
                                        {tab === PRODUCER && (<GroupOperations operations={filteredOperations} group='producer' fetchOperationFiles={fetchOperationFiles} />)}
                                        {tab === LABORATORY && (<GroupOperations operations={filteredOperations} group='laboratory' fetchOperationFiles={fetchOperationFiles} />)}
                                    </div>
                                )
                            }
                        }
                    </SimpleTabList>
                </Grid2>
            </Grid2>
            <Popover
                open={filePopupParams.popupIsOpen}
                anchorEl={filePopupParams.anchorEl}
                onClose={() => setFilePopupParams({ popupIsOpen: false, anchorEl: undefined, files: [] })}
            >
                <div className='row no-margin' style={{ display: 'grid' }}>
                    {filePopupParams.files.map(file => (
                        <div key={file.url} className='col s12 clickable'>
                            <a href={file.url} target='_blank'><h6>{file.name}</h6></a>
                            <Divider />
                        </div>
                    ))}
                </div>
            </Popover>
            <ImportXmlQualityPopup
                callbackImport={loadOperations}
                stations={[qualitometer.code]}
                isOpen={isImportModalOpen}
                onClose={closeImportModal}
            />
            <OperationPCStepper
                isOpen={isOperationPopupOpen}
                onClose={closeOperationPopup}
            />
            <ExportModal
                isOpen={isExportModalOpen}
                close={closeExportModal}

                operations={filteredOperations}
                filter={filter}
            />
        </div>
    )
}

export default OperationsListApp