export default class DtoHydrometryObservation {
    constructor(obj = {}) {
        this.siteCode = obj.siteCode // Int
        this.observationDate = obj.observationDate // Option[DateTime]
        this.observationType = obj.observationType // Option[Int]
        this.observationCode = obj.observationCode // Option[String]
        this.comment = obj.comment // Option[String]
        this.updateLogin = obj.updateLogin // Option[String]
        this.updateDate = obj.updateDate // Option[DateTime]
        this.pictureName = obj.pictureName // Option[String]
        this.idEvent = obj.idEvent // Option[Int]
    }
}