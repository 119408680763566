import { Grid } from '@mui/material'
import { StyledFieldSet } from 'components/StyledElements'
import PropTypes from 'prop-types'
import React from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import Input from '../../../../../components/forms/Input'
import Select from '../../../../../components/forms/Select'
import SimpleDatePicker from '../../../../../components/forms/SimpleDatePicker'
import { hasValue } from '../../../../../utils/NumberUtil'


const FormPopupExportEDILABO = ({
    paramEDILABO = {},
    onChange = () => { },
    fullForm = false,
}) => {
    const {
        contributors,
    } = useSelector(store => ({
        contributors: store.ContributorReducer.contributors,
    }), shallowEqual)

    return (
        <StyledFieldSet style={{ padding: '15px 10px' }}>
            <Grid spacing={1} container justifyContent='center'>
                <Grid item xs={6}>
                    <Input
                        title={i18n.fileName}
                        value={paramEDILABO.requestName}
                        onChange={v => onChange({ requestName: v })}
                        obligatory
                    />
                </Grid>
                <Grid item xs={6}>
                    <Input
                        title={i18n.directory}
                        value={paramEDILABO.exportPath}
                        onChange={v => onChange({ exportPath: v })}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Select
                        options={contributors}
                        label={i18n.sender}
                        onChange={v => onChange({ sender: v })}
                        value={hasValue(paramEDILABO.sender) ? `${paramEDILABO.sender}` : null}
                        nullLabel='&nbsp;'
                        clearFunction
                        displayWithCode
                        keyLabel='labelDisplay'
                        obligatory
                    />
                </Grid>
                <Grid item xs={6}>
                    <Select
                        options={contributors}
                        label={i18n.receiver}
                        onChange={v => onChange({ receiver: v })}
                        value={hasValue(paramEDILABO.receiver) ? `${paramEDILABO.receiver}` : null}
                        nullLabel='&nbsp;'
                        clearFunction
                        displayWithCode
                        keyLabel='labelDisplay'
                        obligatory
                    />
                </Grid>
                {
                    fullForm && (
                        <>
                            <Grid item xs={6}>
                                <Select
                                    options={contributors}
                                    label={i18n.laboratory}
                                    onChange={v => onChange({ laboratory: v })}
                                    value={hasValue(paramEDILABO.laboratory) ? `${paramEDILABO.laboratory}` : null}
                                    nullLabel='&nbsp;'
                                    clearFunction
                                    displayWithCode
                                    keyLabel='labelDisplay'
                                    obligatory
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Select
                                    options={contributors}
                                    label={i18n.producer}
                                    onChange={v => onChange({ producer: v })}
                                    value={hasValue(paramEDILABO.producer) ? `${paramEDILABO.producer}` : null}
                                    nullLabel='&nbsp;'
                                    clearFunction
                                    displayWithCode
                                    keyLabel='labelDisplay'
                                    obligatory
                                />
                            </Grid>
                        </>
                    )
                }
                <Grid item xs={6}>
                    <SimpleDatePicker
                        id='startDate'
                        label={i18n.startDate}
                        value={paramEDILABO.startDate}
                        onChange={v => onChange({ startDate: v })}
                        obligatory
                    />
                </Grid>
                <Grid item xs={6}>
                    <SimpleDatePicker
                        id='endDate'
                        label={i18n.endDate}
                        value={paramEDILABO.endDate}
                        onChange={v => onChange({ endDate: v })}
                        obligatory
                    />
                </Grid>
            </Grid>
        </StyledFieldSet>
    )
}

FormPopupExportEDILABO.propTypes = {
    paramEDILABO: PropTypes.shape({
        requestName: PropTypes.string,
        exportPath: PropTypes.string,
        sender: PropTypes.number,
        receiver: PropTypes.number,
        startDate: PropTypes.number,
        endDate: PropTypes.number,
    }),
    onChange: PropTypes.func,
    fullForm: PropTypes.func,
}

export default FormPopupExportEDILABO