/* eslint-disable camelcase */
import React from 'react'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import { connect } from 'react-redux'
import { SANDRE } from '../../../../referencial/constants/ReferencialConstants'
import { getSandreLabel } from '../../../../utils/StringUtil'
import { getExport, setModal } from '../../../../utils/linkUtils'
import Table from '../../../../components/datatable/Table'
import DtoSandreCode from '../../../../referencial/dto/DtoSandreCode'
import SieauAction from '../../../../components/sieau/SieauAction'
import BuildingModal from './modal/BuildingModal'
import DtoInstallation from '../../../dto/installation/DtoInstallation'

const BuildingsPanel = ({
    station = {},
    sandreCodes = [],
    readMode = false,
    onChange = () => { },
    setPopup = () => { },
}) => {
    const stationBuildings = station.link_buildings.map((c, index) => ({
        ...c,
        index,
    }))
    const tableData = stationBuildings.map(c => ({
        ...c,
        area: c.buildingArea,
        type: getSandreLabel(sandreCodes, SANDRE.INSTALLATIONS_BATIMENT, c.buildingType),
    }))
    const headers = ['type', 'area', 'comment']
    const exportAction = getExport(tableData, i18n.buildings, headers)
    const actions = !readMode ? [{
        iconName: 'note_add',
        tooltip: i18n.add,
        onClick: () => setModal(i18n.newBuilding, <BuildingModal id={station.idStation} saveResult={r => onChange({ link_buildings: [...stationBuildings, r] })} />, setPopup),
    }, exportAction] : [exportAction]

    return (
        <Table
            title={i18n.buildings}
            condensed
            className='blue'
            data={tableData}
            type={{ headers }}
            sortable={!!tableData.length}
            actions={actions}
            alterable={!readMode}
            onAlter={
                (element) => setModal(i18n.editBuilding,
                    <BuildingModal
                        id={station.idStation}
                        STEP={stationBuildings.find(b => element.index === b.index)}
                        saveResult={r => onChange({
                            link_buildings: [
                                ...stationBuildings.filter(b => b.index !== element.index),
                                { ...r, index: element.index },
                            ],
                        })}
                    />
                    , setPopup)
            }
            deletable={!readMode}
            onDelete={(element) => onChange({ link_buildings: stationBuildings.filter(b => b.index !== element.index) })}
        />
    )
}

BuildingsPanel.propTypes = {
    station: PropTypes.instanceOf(DtoInstallation),
    sandreCodes: PropTypes.arrayOf(PropTypes.instanceOf(DtoSandreCode)),
    readMode: PropTypes.bool,
    onChange: PropTypes.func,
    setPopup: PropTypes.func,
}

const mapStateToProps = store => ({
    contacts: store.ContactReducer.contacts,
    sandreCodes: store.ReferencialReducer.sandreCodes,
    contributors: store.ContributorReducer.contributors,
})

const mapDispatchToProps = {
    setPopup: SieauAction.setPopup,
}

export default connect(mapStateToProps, mapDispatchToProps)(BuildingsPanel)