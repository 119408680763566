import i18n from 'simple-react-i18n'
import Row from '../../components/react/Row'
import React, { useEffect, useState } from 'react'
import ProgressCard from '../../components/card/ProgressCard'
import moment from 'moment/moment'
import HydrometryAction from '../../hydrometry/actions/HydrometryAction'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { min, minBy, orderBy, uniq } from 'lodash'
import { fullScreenIcon, getAxisIntervalFormatter, toEchartsData } from '../../components/echart/EChartUtils'
import Axis from '../../components/echart/Axis'
import { fixedNumber, nFormatter } from '../../utils/NumberUtil'
import EChart from '../../components/echart/EChart'
import Line from '../../components/echart/series/Line'
import ChartTabs from '../../components/echart/ChartTabs'
import useUpdateEffect from '../../utils/customHook/useUpdateEffect'
import { getFullDate } from '../../utils/DateUtil'
import ReactDOMServer from 'react-dom/server'
import { statusIcon } from '../../utils/StatusUtil'
import {
    getColorFromPalette,
} from '../../utils/ColorUtil'
import GroupedHydroPanel from '../../hydrometry/components/stationDashboard/GroupedHydroPanel'
import PropTypes from 'prop-types'
import DtoHydrometricStation from '../../hydrometry/dto/DtoHydrometricStation'
import { arrayOf } from '../../utils/StoreUtils'
import DtoHydroMeasures from '../../hydrometry/dto/chronicMeasures/DtoHydroMeasures'
import DtoStationAssociation from '../../station/dto/DtoStationAssociation'
import MessageCard from '../../components/card/MessageCard'
import { AccordionDetailsMUI, AccordionMUI, AccordionSummaryMUI } from 'components/styled/Accordions'

const GraphicHydroHeightFlow = ({
    hydroMeasures,
    hydrometricStations,
    minDate,
    maxDate,
    fullScreen,
    setFullScreen,
}) => {
    const {
        hydrometryDataTypes,
    } = useSelector(store => ({
        hydrometryDataTypes: store.HydrometryReducer.hydrometryDataTypes,
    }), shallowEqual)

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(HydrometryAction.fetchHydrometryDataTypes())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const dataTypeHeight = hydrometryDataTypes.find(h => h.id === 4)
    const unitHeight = dataTypeHeight?.unit ? `[${dataTypeHeight?.unit}]` : ''

    const dataTypeFlow = hydrometryDataTypes.find(h => h.id === 5)
    const unitFlow = dataTypeFlow?.unit ? `[${dataTypeFlow?.unit}]` : ''
    const allMeasures = hydroMeasures.map(h => minBy(h.measures, 'date')?.date)
    const chartMinDate = minDate? minDate : min(allMeasures)

    const chartMaxDate = maxDate ? moment(maxDate) : moment()
    const axisLabelObj = getAxisIntervalFormatter(chartMaxDate, moment(minDate || chartMinDate))

    const grids = [{
        top: 75,
        right: '2%',
        height: 210,
        left: 60,
    },
    {
        top: 325,
        right: '2%',
        height: 210,
        left: 60,
    }]

    const xAxis = [
        Axis({
            type: 'time',
            position: 'bottom',
            min: chartMinDate,
            max: maxDate || moment().valueOf(),
            gridIndex: 0,
            interval: axisLabelObj.interval,
            axisLabel: { show: true, formatter: axisLabelObj.formatter },
            axisLine: { show: true },
            axisTick: { show: true },
            showSplitLine: true,
        }),
        Axis({
            type: 'time',
            position: 'bottom',
            min: chartMinDate,
            max: maxDate || moment().valueOf(),
            gridIndex: 1,
            interval: axisLabelObj.interval,
            axisLabel: { show: true, formatter: axisLabelObj.formatter },
            axisLine: { show: true },
            axisTick: { show: true },
            showSplitLine: true,
        }),
    ]

    const yAxis = [
        Axis({
            type: 'value',
            nameLocation: 'middle',
            name: `${i18n.height}  ${unitHeight}`,
            gridIndex: 0,
            showSplitLine: true,
            axisLabel: { formatter: nFormatter },
            nameGap: 40,
        }), Axis({
            type: 'value',
            nameLocation: 'middle',
            name: `${i18n.flow}  ${unitFlow}`,
            gridIndex: 1,
            showSplitLine: true,
            axisLabel: { formatter: nFormatter },
            nameGap: 40,
        }),
    ]

    const getLine = (id, measures, grid, type, idx) => {
        const informationsStation = hydrometricStations.find(s => s.id === id)
        return Line({
            name: `${informationsStation?.name} (${type})`,
            barWidth: 4,
            data: toEchartsData(measures),
            color: getColorFromPalette(idx),
            connectNulls: false,
            showSymbol: true,
            xAxisIndex: grid,
            yAxisIndex: grid,
        })
    }

    const measuresHeight = hydroMeasures.filter(m => m.dataType === 4)
    const measuresFlow = hydroMeasures.filter(m => m.dataType === 5)

    const seriesHeight = measuresHeight.map((m, idx) => {
        return getLine(m.stationId, m.measures, 0, i18n.height, idx)
    })

    const seriesFlow = measuresFlow.map((m, idx) => {
        return getLine(m.stationId, m.measures, 1, i18n.flow, idx)
    })
    const options = {
        series: [...seriesHeight, ...seriesFlow],
        tooltip: {
            trigger: 'axis',
            formatter: params => {
                const date = getFullDate(params[0].value[2].date)
                const paramsOrder = orderBy(params.filter(o => o.seriesName !== i18n.events).map(o => {
                    return ({
                        seriesIndex: o.seriesIndex,
                        marker: o.marker,
                        seriesName: o.seriesName,
                        value: o.value[2].value,
                        status: ReactDOMServer.renderToString(statusIcon(o.value[2], 20)),
                        axisIndex: o.axisIndex,
                    })
                }), 'seriesIndex', 'asc')
                const resultHeight = paramsOrder.filter(p => p.axisIndex === 0).map(o => (`<span style="display:flex;align-items:center;">${o.marker} ${o.seriesName} : ${fixedNumber(o.value)} ${unitHeight} <span style="padding-left:5px">${o.status}</span> </span>`)).join('')
                const resultFlow = paramsOrder.filter(p => p.axisIndex === 1).map(o => (`<span style="display:flex;align-items:center;">${o.marker} ${o.seriesName} : ${fixedNumber(o.value)} ${unitFlow} <span style="padding-left:5px">${o.status}</span> </span>`)).join('')
                return `${date} <br /> ${i18n.height} : <br /> ${resultHeight} <br /> ${i18n.flow} : <br />${resultFlow}`
            },
        },
        grid: grids,
        xAxis,
        yAxis,
        axisPointer: {
            link: { xAxisIndex: 'all' },
        },
        setDataZoom: true,
        height: 550,
        legend: {
            top: 30,
            type: 'scroll',
        },
        toolbox: {
            show: true,
            feature: {
                myToolFullScreen: {
                    show: true,
                    title: i18n.fullScreen,
                    icon: fullScreenIcon,
                    onclick: () => setFullScreen(!fullScreen),
                },
            },
        },
    }
    return <EChart options={options} id='productionUnitDashboardChart' scrollable={fullScreen} />
}
GraphicHydroHeightFlow.propTypes = {
    hydroMeasures: arrayOf(DtoHydroMeasures),
    hydrometricStations: PropTypes.arrayOf(PropTypes.instanceOf(DtoHydrometricStation)),
    minDate: PropTypes.number,
    maxDate: PropTypes.number,
    fullScreen: PropTypes.bool,
    setFullScreen: PropTypes.func,
}

const UnitHydrometryPanel = ({
    associations,
    expanded = false,
    setExpanded = () => { },
}) => {
    const [fullScreen, setFullScreen] = useState(false)
    const [dataLoaded, setDataLoaded] = useState(false)
    const progress = 0

    const [minDate, setMinDate] = useState(undefined)
    const [maxDate, setMaxDate] = useState(moment().valueOf())

    const [hydroGrouped, setHydroGrouped] = useState('AUTO')

    const {
        hydrometricStations,
    } = useSelector(store => ({
        hydrometricStations: store.HydrometryReducer.hydrometricStations,
    }), shallowEqual)

    const dispatch = useDispatch()

    useEffect(()=> {
        dispatch(HydrometryAction.fetchHydrometricStations())
    }, [dispatch])


    const [measures, setMeasures] = useState([])

    const stationsFiltered = associations.filter(s => s.stationLinkedType === 4)
    const idStationsFiltered = uniq(stationsFiltered.map(s => (s.stationLinkedId)))

    const loadData = (dateMin, dateMax) => {
        setDataLoaded(false)
        const defaultGroupMode = dateMin && dateMin > moment(dateMax).subtract('91', 'day').valueOf() ? 'all' : 'MAX'
        const inputsHeight = idStationsFiltered.map(stat => ({
            stationId: stat,
            dataType: 4,
            groupFunc: hydroGrouped === 'AUTO' ? defaultGroupMode : hydroGrouped,
            chartMode: true,
            startDate: dateMin,
            endDate: dateMax,
        }))
        const inputsFlow = idStationsFiltered.map(stat => ({
            stationId: stat,
            dataType: 5,
            groupFunc: hydroGrouped === 'AUTO' ? defaultGroupMode : hydroGrouped,
            chartMode: true,
            startDate: dateMin,
            endDate: dateMax,
        }))
        const inputs = [...inputsHeight, ...inputsFlow]
        HydrometryAction.getHydrosChronicMeasures(inputs).then(v => {
            setMeasures(v)
            setDataLoaded(true)
        })
    }

    useUpdateEffect(()=> {
        loadData(minDate, maxDate)
    }, [hydroGrouped, minDate, maxDate])

    const onChangeDates = (dates, forced) => {
        if (minDate !== dates.minDate || maxDate !== dates.maxDate || forced) {
            setMinDate(dates?.minDate)
            setMaxDate(dates?.maxDate)
        }
    }

    const measuresFiltered = measures.filter(h => h.measures.length > 0).filter(m => m.dataType === 4 || m.dataType === 5)

    return (
        <AccordionMUI round style={{ marginTop: 10, marginBottom: 10 }} expanded={expanded} onChange={setExpanded}>
            <AccordionSummaryMUI round iconColor='black' style={{ background: 'white', color: 'black' }}>{i18n.hydrometry}</AccordionSummaryMUI>
            <AccordionDetailsMUI>
                <Row className={fullScreen ? 'fullscreen-chart' : ''}>
                    <div className='col s12 row no-margin padding-top-1' style={{ paddingLeft: 48 }}>
                        <div>
                            <ChartTabs onChangeDate={(dates, forced) => onChangeDates(dates, forced)} />
                        </div>
                        <GroupedHydroPanel hydroGrouped={hydroGrouped} setHydroGrouped={setHydroGrouped} />
                    </div>
                    <div className='col s12 row no-margin'>
                        {!dataLoaded && (
                            <div style={{ marginTop: 20 }}>
                                <ProgressCard
                                    progress={progress}
                                />
                            </div>
                        )}
                        {(measuresFiltered.length === 0) && (
                            <div style={{ marginTop: 15 }}>
                                <MessageCard>{i18n.noDataToDisplay}</MessageCard>
                            </div>
                        )}
                        {(dataLoaded && !(measuresFiltered.length === 0)) && (
                            <GraphicHydroHeightFlow
                                hydroMeasures={measures}
                                hydrometricStations={hydrometricStations}
                                minDate={minDate}
                                maxDate={maxDate}
                                fullScreen={fullScreen}
                                setFullScreen={setFullScreen}
                            />
                        )}
                    </div>
                </Row>
            </AccordionDetailsMUI>
        </AccordionMUI>
    )
}
UnitHydrometryPanel.propTypes = {
    associations: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.instanceOf(DtoStationAssociation)),
        PropTypes.arrayOf(PropTypes.shape({
            code: PropTypes.string,
            id: PropTypes.number,
            name: PropTypes.string,
            stationLinkedCode: PropTypes.string,
            stationLinkedId: PropTypes.number,
            stationLinkedName: PropTypes.string,
            stationLinkedType: PropTypes.number,
            stationType: PropTypes.number,
        })),
    ]),
    expanded: PropTypes.bool,
    setExpanded: PropTypes.func,
}

export default UnitHydrometryPanel