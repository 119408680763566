export default class DtoStationCampaign {
    constructor(obj) {
        this.campaignId = obj.campaignId
        this.stationId = obj.stationId
        this.visitOrder = obj.visitOrder
        this.visitDate = obj.visitDate
        this.access = obj.access
        this.contactPhone = obj.contactPhone
        this.todo = obj.todo
        this.visitValidated = obj.visitValidated
        this.previsionalVisitNumber = obj.previsionalVisitNumber
    }
}