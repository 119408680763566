import React from 'react'
import PropTypes from 'prop-types'
import SlideNavLink from './links/SlideNavLink'

const FixedSlideNav = ({ links = [] }) => {
    return (
        <div style={{ top: '64px' }} className='left-bar reduce z-depth-5 no-padding'>
            {
                links.map((link, idx) => (
                    <SlideNavLink
                        key={idx}
                        id={link.id}
                        href={link.href}
                        name={link.name}
                        className={link.className}
                        icon={link.icons}
                        external={link.external}
                        tooltip={link.tooltip}
                        data-cy={link['data-cy']}
                    />
                ))
            }
        </div>
    )
}

FixedSlideNav.propTypes = {
    links: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string,
        href: PropTypes.string.isRequired,
        name: PropTypes.string,
        className: PropTypes.string,
        icons: PropTypes.string,
        external: PropTypes.bool,
        tooltip: PropTypes.bool,
    })).isRequired,
}

export default FixedSlideNav
