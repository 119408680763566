import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import NumberField from '../../../../components/forms/NumberField'
import Select from '../../../../components/forms/Select'
import { SANDRE } from '../../../../referencial/constants/ReferencialConstants'
import DtoSandreCode from '../../../../referencial/dto/DtoSandreCode'
import { getSandreList } from '../../../../utils/StoreUtils'
import DtoLocationLink from '../../../dto/DtoLocationLink'

class LocationModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            location: { ...props.location, idStation: props.id },
        }
    }

    componentDidUpdate(prevProps) {
        if (!prevProps.popupIsOpen && this.props.popupIsOpen) {
            $('#descriptionModal').on('click', this.onSave)
        }
    }

    onSave = () => {
        this.props.saveResult(new DtoLocationLink(this.state.location))
        $('.modal').modal('close')
    }

    onChangeElement = (changes) => this.setState({ location: { ...this.state.location, ...changes } })

    render() {
        const { location } = this.state
        return (
            <div>
                <div className='row no-margin'>
                    <Select
                        col={ 6 }
                        options={ getSandreList(this.props.sandreCodes, SANDRE.PROJECTION) }
                        label={ i18n.projection }
                        value={ location.projection }
                        onChange={ v => this.onChangeElement({ projection: v }) }
                    />
                    <NumberField
                        col={ 6 }
                        value={ location.x }
                        title={ i18n.x }
                        onChange={ v => this.onChangeElement({ x: v }) }
                        floatValue
                    />
                </div>
                <div className='row no-margin padding-top-1'>
                    <NumberField
                        col={ 6 }
                        value={ location.y }
                        title={ i18n.y }
                        onChange={ v => this.onChangeElement({ y: v }) }
                        floatValue
                    />
                    <NumberField
                        col={ 6 }
                        value={ location.z }
                        title={ i18n.z }
                        onChange={ v => this.onChangeElement({ z: v }) }
                        floatValue
                    />
                </div>
                <div className='row no-margin padding-top-1'>
                    <NumberField
                        col={ 6 }
                        value={ location.precision }
                        title={ i18n.precision }
                        onChange={ v => this.onChangeElement({ precision: v }) }
                        floatValue
                    />
                </div>
            </div>
        )
    }
}

LocationModal.propTypes = {
    location: PropTypes.instanceOf(DtoLocationLink),
    id: PropTypes.number,
    sandreCodes: PropTypes.arrayOf(PropTypes.instanceOf(DtoSandreCode)),
    popupIsOpen: PropTypes.bool,
    saveResult: PropTypes.func,
}


const mapStateToProps = store => ({
    sandreCodes: store.ReferencialReducer.sandreCodes,
    popupIsOpen: store.SieauReducer.popupIsOpen,
})

LocationModal.defaultProps = {
    location: {},
}

export default connect(mapStateToProps)(LocationModal)
