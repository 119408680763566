import AEPOverview from 'quality/components/AEPoverview/AEPOverview'
import React, { useMemo } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import useTitle from 'utils/customHook/useTitle'
import { getStationTitle } from 'utils/StationUtils'
import i18n from 'simple-react-i18n'
import { useParams } from 'react-router'

const ResourceAEPApp = () => {
    const { id } = useParams()

    const {
        resource,
        associatedSites,
    } = useSelector(store => ({
        resource: store.ResourceReducer.resource,
        associatedSites: store.StationReducer.associatedSites,
    }), shallowEqual)

    useTitle(() => [{
        title: i18n.resource,
        href: 'resource',
    }, {
        title: getStationTitle(resource),
        href: `station/resource/${id}`,
    }, {
        title: i18n.AEPoverview,
        href: `station/resource/${id}/conformityOverview`,
    }], [])

    const ids = useMemo(() => associatedSites.filter(site => site.stationLinkedType === 3).map(site => site.stationLinkedId), [associatedSites])

    return (
        <AEPOverview
            ids={ids}
        />
    )
}

export default ResourceAEPApp