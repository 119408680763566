import { isNull } from 'lodash'

export default class DtoSumAnalysisLight {
    constructor(obj) {
        const ana = obj.map(e => isNull(e) ? undefined : e)
        this.id = ana[0] // Int
        this.qualitometer = ana[1] // Double
        this.operation = ana[2] // Double
        this.sampleDate = ana[3] // Option[DateTime] = None
        this.result = ana[4] // Option[Double] = None

        this.status = '2'
        this.remark = '1'
    }
}