import React, { Component } from 'react'
import i18n from 'simple-react-i18n'
import AppStore from '../../../../store/AppStore'
import ToastrAction from '../../../../toastr/actions/ToastrAction'
import { uniq } from 'lodash'
import AdesStationDto from '../../../dto/AdesStationDto'
import Table from '../../../../components/datatable/Table'
import { nbPerPageLabelTiny } from '../../../../referencial/constants/ReferencialConstants'
import Row from '../../../../components/react/Row'
import Select from '../../../../components/forms/Select'
import Input from '../../../../components/forms/Input'
import Checkbox from '../../../../components/forms/Checkbox'
import { integrationModeHelpIcon } from '../../../utils/ImportUtils'
import RadioButtons from '../../../../components/forms/RadioButtons'
import PropTypes from 'prop-types'
import Job from '../../../dto/DtoJob'
import { arrayOf } from '../../../../utils/StoreUtils'
import NetworkDto from '../../../../referencial/components/network/dto/NetworkDto'
import { connect } from 'react-redux'
import { getIntegrationModes } from '../../../../utils/JobUtils'
import { Buffer } from 'buffer'

class SieauInstancePanel extends Component {
    constructor(props) {
        super(props)
        this.state = {
        }
    }

    componentDidMount() {
        const filters = this.getFilters()
        this.onChangeFilters({
            importType: 'piezometry',
            updateStation: true,
            validOnly: true,
            dataIntegrationMode: 'DATA_ADD_LAST_DATA',
            codes: [],
            ...filters,
        })
    }

    onDeleteBssCode = ({ bsscode }) => {
        if (this.props.isEditMode) {
            const filters = this.getFilters()
            this.onChangeFilters({ codes: filters.codes.filter(p => p !== bsscode) })
        }
    }

    addBssCode = () => {
        if (this.props.isEditMode) {
            const filters = this.getFilters()
            const code = this.refs.txtAddBssCode.value
            const exist = filters.codes.find(p => p.toLowerCase() == code.toLowerCase())
            if (!code) {
                AppStore.dispatch(ToastrAction.error(i18n.youMustEnterAtLeastOneBssCode))
            } else if (exist) {
                AppStore.dispatch(ToastrAction.error(i18n.youHaveAlreadyEnteredThisBssCode))
            } else {
                this.onChangeFilters({ codes: [ code, ...filters.codes ] })
                this.refs.txtAddBssCode.value = ''
            }
        }
    }

    onChangeBssFile = e => {
        if (this.props.isEditMode) {
            const reader = new FileReader()
            const file = e.target.files[0]
            reader.onload = upload => {
                const split = upload.target.result.split(',')
                if (split[0].indexOf('text/csv') > 0 || split[0].indexOf('vnd.ms-excel') > 0) {
                    const buf = Buffer.from(split[1], 'base64').toString()
                    const codes = buf.replace(/;/g, '').split('\n').map(b => b.trim())
                    const filteredCodes = codes.filter((c) => c !== '')
                    const filters = this.getFilters()
                    this.onChangeFilters({ codes: uniq([ ...filters.codes, ...filteredCodes ]) })
                } else {
                    AppStore.dispatch(ToastrAction.error(i18n.theSelectedFileMustBeInCsvFormat))
                }
                this.refs.importFile.value = ''
            }
            reader.readAsDataURL(file)
        }
    }

    getStations = () => {
        const filters = this.getFilters()
        return (filters.codes || []).map(bsscode => new AdesStationDto({ bsscode }))
    }

    onChangeFilters = (changes) => {
        const parameters = {
            ...this.props.job.parameters,
            filters: [JSON.stringify({
                ...this.getFilters(),
                ...changes,
            })],
        }
        this.props.onChangeJob({ parameters })
    }

    getFilters = () => {
        return this.props.job.parameters.filters.length ? JSON.parse(this.props.job.parameters.filters[0]) : {}
    }

    getStationsPanel = () => {
        const disabled = { disabled: !this.props.isEditMode }
        return (
            <div>
                <div className='row no-margin valign-wrapper'>
                    <div className='col s3'>
                        <label>{ i18n.importFile }</label>
                    </div>
                    <div className='file-field col s9 no-padding input-field'>
                        <div className='col s3 no-padding btn'>
                            <span>{ i18n.importLabel }</span>
                            <input
                                type='file'
                                ref='importFile'
                                accept='.csv'
                                onChange={ this.onChangeBssFile }
                                {...disabled}
                            />
                        </div>
                        <div className='file-path-wrapper col s9'>
                            <input
                                className='file-path'
                                ref='fileInput'
                                type='text'
                                placeholder={ i18n.selectFile }
                                {...disabled}
                            />
                        </div>
                    </div>
                </div>
                <div className='row valign-wrapper'>
                    <div className='col s3'>
                        <label>{ i18n.addCredential }</label>
                    </div>
                    <div className='input-field col s9 no-padding'>
                        <div className='col s8'>
                            <input id='txtAddBssCode' type='text' placeholder={ i18n.BSSExample } ref='txtAddBssCode'/>
                            <label className='tinyLabel'>{ i18n.theCodeMustBePresentInTheRepositoryAndDefinedByTheCode }</label>
                        </div>
                        <a
                            className='waves-effect waves-light btn col offset-s1 s3'
                            onClick={ this.addBssCode }
                        >
                            { i18n.add }
                        </a>
                    </div>
                </div>
                <div className='row valign-wrapper'>
                    <div className='col s12'>
                        <Table
                            type={ new AdesStationDto() }
                            data={ this.getStations() }
                            title={ i18n.credentials }
                            nbPerPageLabel={ nbPerPageLabelTiny }
                            onDelete={ this.onDeleteBssCode }
                            deletable={ this.props.isEditMode }
                            showNbElements
                            activeHeader
                            paging
                            exportButtonOnHeader
                        />
                    </div>
                </div>
            </div>
        )
    }

    render() {
        const disabled = { disabled: !this.props.isEditMode }

        const importType = [
            { code: 'piezometry', name: i18n.piezometry },
        ]
        const validRadioElements = [
            { code: true, name: i18n.yes },
            { code: false, name: i18n.no },
        ]
        const filters = this.getFilters()
        return (
            <div>
                <div className='row no-margin valign-wrapper'>
                    <Select
                        col={2}
                        label={i18n.importMode}
                        options={importType}
                        value={filters.importType}
                        onChange={v => this.onChangeFilters({ importType: v })}
                        { ...disabled }
                    />
                    <Checkbox
                        col={3}
                        checked={ filters.updateStation }
                        label={ i18n.integrateStationInfo }
                        onChange={ v => this.onChangeFilters({ updateStation: v }) }
                        { ...disabled }
                    />
                    <Select col={4} options={ getIntegrationModes() } label={ i18n.dataIntegration } value={ filters.dataIntegrationMode } labelSpan={ integrationModeHelpIcon() }
                        onChange={ v => this.onChangeFilters({ dataIntegrationMode: v }) }
                        { ...disabled }
                    />
                    <RadioButtons
                        col={ 3 }
                        elements={ validRadioElements }
                        selected={ filters.validOnly }
                        onChange={ v => this.onChangeFilters({ validOnly: v }) }
                        { ...disabled }
                        title={ i18n.validData }
                    />
                </div>
                <Row>
                    <Input col={ 4 } title={ 'URL instance' } onChange={ v => this.onChangeFilters({ instanceUrl: v }) } { ...disabled } value={ filters.instanceUrl } />
                    <Input col={ 4 } title={ i18n.user } onChange={ v => this.onChangeFilters({ user: v }) } { ...disabled } value={ filters.user } />
                    <Input col={ 4 } title={ i18n.password } onChange={ v => this.onChangeFilters({ password: v }) } { ...disabled } value={ filters.password } passwordType/>
                </Row>
                { this.getStationsPanel() }
            </div>
        )
    }
}

SieauInstancePanel.propTypes = {
    job: PropTypes.instanceOf(Job).isRequired,
    addBssCode: PropTypes.func.isRequired,
    isEditMode: PropTypes.bool,
    onChangeDataTypes: PropTypes.func,
    onChangeFilters: PropTypes.func,
    onChangeJob: PropTypes.func,
    networks: arrayOf(NetworkDto),
}

const mapStateToProps = store => ({
    networks: store.NetworkReducer.networks,
})

export default connect(mapStateToProps)(SieauInstancePanel)
