export default class DtoCmsTideGauge {
    constructor(obj) {
        this.cmsId = obj.cmsId
        this.tideGaugeId = obj.tideGaugeId
        this.stationCode = obj.stationCode
        this.amCoeff = obj.amCoeff
        this.pmCoeff = obj.pmCoeff
        this.amHighSea = obj.amHighSea
        this.pmHighSea = obj.pmHighSea
        this.amLowSea = obj.amLowSea
        this.pmLowSea = obj.pmLowSea
        this.order = obj.order
    }
}
