import { Grid } from '@mui/material'
import Checkbox from 'components/forms/Checkbox'
import NumberField from 'components/forms/NumberField'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import Select from '../../../../components/forms/Select'
import CityDto from '../../../../referencial/components/city/dto/CityDto'
import { arrayOf } from '../../../../utils/StoreUtils'

class AssociatedTownModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            associatedTown: props.associatedTown,
        }
    }

    componentWillReceiveProps(nextProps) {
        if (!this.props.popupIsOpen && nextProps.popupIsOpen) {
            $('#descriptionModal').on('click', this.onSave)
        }
    }

    onSave = () => {
        this.props.saveResult({ idStation: this.props.id, ...this.state.associatedTown })
        $('.modal').modal('close')
    }

    onChangeElement = (value) => this.setState({ associatedTown: { ...this.state.associatedTown, ...value } })

    render() {
        const { associatedTown } = this.state
        return (
            <Grid container>
                <Grid item xs={6}>
                    <Select
                        col={12}
                        value={associatedTown.city}
                        label={i18n.cities}
                        onChange={v => this.onChangeElement({ city: v })}
                        options={this.props.cities}
                    />
                </Grid>
                <Grid item xs={6} style={{ padding: '15 0 0 20' }}>
                    <Checkbox
                        col={12}
                        checked={associatedTown.member}
                        label={i18n.member}
                        onChange={v => this.onChangeElement({ member: v })}
                    />
                </Grid>
                <Grid item xs={6} className='margin-top-1'>
                    <NumberField
                        value={associatedTown.servedPopulation}
                        title={i18n.servedPopulation}
                        onChange={v => this.onChangeElement({ servedPopulation: v })}
                    />
                </Grid>
            </Grid>
        )
    }
}

AssociatedTownModal.propTypes = {
    associatedTown: PropTypes.string,
    popupIsOpen: PropTypes.bool,
    saveResult: PropTypes.func,
    cities: arrayOf(CityDto),
    id: PropTypes.number,
}


const mapStateToProps = store => ({
    cities: store.CityReducer.cities,
    popupIsOpen: store.SieauReducer.popupIsOpen,
})

AssociatedTownModal.defaultProps = {
    associatedTown: {},
}

export default connect(mapStateToProps)(AssociatedTownModal)
