import React from 'react'
import i18n from 'simple-react-i18n'
import Select from '../../../components/forms/Select'
import Input from '../../../components/forms/Input'
import { SANDRE } from '../../constants/ReferencialConstants'
import NumberField from '../../../components/forms/NumberField'
import SimpleDatePicker from 'components/forms/SimpleDatePicker'
import { orderBy, uniqBy } from 'lodash'
import { Grid } from '@mui/material'
import Textarea from 'components/forms/Textarea'
import { shallowEqual, useSelector } from 'react-redux'
import { PropTypes } from 'prop-types'
import useSandreList from 'utils/customHook/useSandreList'

const SandreCodePanel = ({
    sandreCode = {},
    readMode = {},
    onChange = () => { },
    field = '',
}) => {
    const {
        referencialSandreCodes,
        sandreCodes,
    } = useSelector(store => ({
        referencialSandreCodes: store.ReferencialReducer.referencialSandreCodes,
        sandreCodes: store.ReferencialReducer.sandreCodes,
    }), shallowEqual)

    const dispState = useSandreList(SANDRE.DISPOSITIFS_ETATS)

    const isReferencialSC = referencialSandreCodes.some(s => s.field === field && s.code)

    return (
        <Grid container>
            <Grid container item xs={12} justifyContent='space-between' alignItems='center' spacing={2}>
                <Grid item xs={3}>
                    <NumberField
                        title={i18n.code}
                        value={sandreCode.code}
                        onChange={v => onChange({ code: v })}
                        disabled={field !== 'new' && readMode}
                        obligatory
                        data-cy='code_lexicon'
                    />
                </Grid>
                <Grid item xs={3}>
                    <Input
                        title={i18n.name}
                        value={sandreCode.name}
                        onChange={v => onChange({ name: v })}
                        maxLength={100}
                        disabled={readMode}
                        obligatory
                        data-cy='name_lexicon'
                    />
                </Grid>
                {!isReferencialSC && (
                    <Grid item xs={6}>
                        <Select
                            label={i18n.lexicons}
                            value={sandreCode.field}
                            options={orderBy(uniqBy(sandreCodes.filter(sc => !referencialSandreCodes.some(rsf => rsf.code && rsf.field === sc.field)), 'field'), 'field')}
                            onChange={v => onChange({ field: v })}
                            keyValue='field'
                            keyLabel='field'
                            disabled={readMode}
                            noNullValue
                            obligatory
                            data-cy='field_lexicon'
                        />
                    </Grid>
                )}
                {isReferencialSC && (
                    <Grid item xs={6}>
                        <Select
                            label={i18n.nomenclature}
                            value={sandreCode.field}
                            options={orderBy(uniqBy(referencialSandreCodes.filter(rsf => rsf.code), 'field'), 'field')}
                            onChange={v => onChange({ field: v })}
                            keyValue='field'
                            keyLabel='field'
                            disabled={readMode}
                            noNullValue
                            obligatory
                        />
                    </Grid>
                )}
            </Grid>
            <Grid container item xs={12} justifyContent='space-between' alignItems='center' spacing={2}>
                <Grid item xs={6}>
                    <Input
                        title={i18n.mnemonique}
                        value={sandreCode.mnemonique}
                        onChange={v => onChange({ mnemonique: v })}
                        maxLength={35}
                        disabled={readMode}
                        data-cy='mnemonique_lexicon'
                    />
                </Grid>
                <Grid item xs={6}>
                    <Input
                        title={i18n.reference}
                        value={sandreCode.reference}
                        onChange={v => onChange({ reference: v })}
                        maxLength={35}
                        disabled={readMode}
                        data-cy='reference_lexicon'
                    />
                </Grid>
            </Grid>
            <Grid container item xs={12} justifyContent='space-between' alignItems='center' spacing={2}>
                <Grid item xs={6}>
                    <Input
                        title={i18n.author}
                        value={sandreCode.author}
                        onChange={v => onChange({ author: v })}
                        maxLength={35}
                        disabled={readMode}
                        data-cy='author_lexicon'
                    />
                </Grid>
                <Grid item xs={6}>
                    <Select
                        options={dispState}
                        label={i18n.status}
                        value={sandreCode.status}
                        onChange={v => onChange({ status: v })}
                        disabled={readMode}
                        data-cy='status_lexicon'
                    />
                </Grid>
            </Grid>
            <Grid container item xs={12} justifyContent='space-between' alignItems='center' spacing={2}>
                <Grid item xs={6}>
                    <SimpleDatePicker
                        id='creation_lexicon'
                        label={i18n.creationDate}
                        value={sandreCode.creation}
                        onChange={(v) => onChange({ creation: v })}
                        disabled={readMode}
                        data-cy='creation_lexicon'
                    />
                </Grid>
                <Grid item xs={6}>
                    <SimpleDatePicker
                        id='updateDate_lexicon'
                        label={i18n.updateDate}
                        value={sandreCode.updateDate}
                        onChange={(v) => onChange({ updateDate: v })}
                        disabled={readMode}
                        data-cy='updateDate_lexicon'
                    />
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Textarea
                    title={i18n.comment}
                    value={sandreCode.comment}
                    onChange={v => onChange({ comment: v })}
                    disabled={readMode}
                    data-cy='comment_lexicon'
                />
            </Grid>
        </Grid>
    )
}

SandreCodePanel.propTypes = {
    sandreCode: PropTypes.shape({}),
    readMode: PropTypes.bool,
    onChange: PropTypes.func,
    field: PropTypes.string,
}

export default SandreCodePanel