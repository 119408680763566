import { Button, Dialog, DialogActions, DialogTitle, Grid, Icon } from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'
import i18n from 'simple-react-i18n'

export default function ModalNothingDeclared({ open, toggleModal, push }) {
    const getBack = () => {
        push()
        toggleModal()
    }

    const handleClose = () => {
        toggleModal()
    }

    const body = (
        <div className='padding-1'>
            <span id='modal-text'>
                Vous n'avez déclaré aucun point de prélèvement utilisé et aucun usage. Etes vous sûr de vouloir continuer ?
            </span>
            <p id='modal-text'>
                Pour ajouter des usages et des volumes, cliquez sur chaque point de prélèvement utilisé puis ajouter les usages réels, les usages prévisionnels ainsi que les volumes demandés.
            </p>
            <p id='modal-description' className='italic-label'>{i18n.contactAdmin}</p>
        </div>
    )

    return (
        <Dialog
            fullWidth
            maxWidth='md'
            open={open}
        >
            <DialogTitle>
                <Grid container alignItems='center'>
                    <Icon style={{ fontSize: '2.5rem', marginRight: 8 }}>warning</Icon>
                    <Grid item>{i18n.declarationEmpty}</Grid>
                </Grid>
            </DialogTitle>
            {body}
            <DialogActions style={{ justifyContent: 'space-between' }}>
                <Button variant='contained' color='primary' onClick={getBack}>
                    {i18n.return}
                </Button>
                <Button variant='contained' color='primary' onClick={handleClose}>
                    {i18n.close}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

ModalNothingDeclared.propTypes = {
    open: PropTypes.bool.isRequired,
    toggleModal: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
}
