module.exports = {
    RECEIVE_ALL_EQUIPMENT: 'RECEIVE_ALL_EQUIPMENT',
    RECEIVE_EQUIPMENT: 'RECEIVE_EQUIPMENT',
    RESET_EQUIPMENT: 'RESET_EQUIPMENT',
    RECEIVE_ALL_EQUIPMENT_SITUATIONS: 'RECEIVE_ALL_EQUIPMENT_SITUATIONS',
    RECEIVE_EQUIPMENTS_LAST_SITUATIONS: 'RECEIVE_EQUIPMENTS_LAST_SITUATIONS',
    RECEIVE_EQUIPMENT_PICTURES: 'RECEIVE_EQUIPMENT_PICTURES',
    RECEIVE_EQUIPMENT_FILES: 'RECEIVE_EQUIPMENT_FILES',
    RECEIVE_EQUIPMENT_TYPES: 'RECEIVE_EQUIPMENT_TYPES',
}
