import { WAIT } from './WaitConstants'

export default function WaitReducer(state = {}, action) {
    switch (action.type) {
        case WAIT: {
            return Object.assign({}, state, {
                isWait: action.wait,
            })
        }
        default:
            return state
    }
}
