/* eslint-disable consistent-return */
import { useCallback, useEffect, useState } from 'react'

/**
 * Hook to store data in the session storage
 * @param {string} key - The default value of the list
 * @param {*} defaultValue - The default value of the list
 * @param {boolean} overrideSessionValue - param to specifie if the defaultValue should override the session value or not
 * @return {[*, function, function]} The value, the setter and a function to remove the property from the storage
 */
const useSessionStorage = (key, defaultValue, overrideSessionValue = false) => {
    const [value, setValue] = useState(() => {
        const jsonValue = window.sessionStorage.getItem(key)
        if (jsonValue !== null && !overrideSessionValue) {
            return JSON.parse(jsonValue)
        }
        if (typeof initialValue === 'function') {
            return defaultValue()
        }
        return defaultValue
    })

    useEffect(() => {
        if (value === undefined) {
            return window.sessionStorage.removeItem(key)
        }
        window.sessionStorage.setItem(key, JSON.stringify(value))
    }, [key, value])

    const remove = useCallback(() => {
        setValue(undefined)
    }, [])

    return [value, setValue, remove]
}

export default useSessionStorage