/* eslint-disable camelcase */
export default class DtoExportAgencySurvey {
    constructor(obj) {
        this.recipientInstitution = obj.recipientInstitution // Option[String],
        this.relevantInstitution = obj.relevantInstitution // Option[String],
        this.counterNum = obj.counterNum // Option[Int],
        this.socialReason = obj.socialReason // Option[String],
        this.siret = obj.siret // Option[String],
        this.mainCollectionPoint = obj.mainCollectionPoint // Option[String],
        this.counterRef = obj.counterRef // Option[String],
        this.designationWork = obj.designationWork // Option[String],
        this.city = obj.city // Option[String],
        this.natureResource = obj.natureResource // Option[String],
        this.usage = obj.usage // Option[String],
        this.startIndex = obj.startIndex // Option[Double],
        this.endIndex = obj.endIndex // Option[Double],
        this.volume = obj.volume // Option[Double],
        this.incident = obj.incident // Option[String],
        this.stopDate = obj.stopDate // Option[String],
        this.stopIndex = obj.stopIndex // Option[Double],
        this.resumptionDate = obj.resumptionDate // Option[String],
        this.resumptionIndex = obj.resumptionIndex // Option[Double],
        this.estimatedVol = obj.estimatedVol // Option[Double],
        this.passage_0 = obj.passage_0 // Option[String],
        this.noserie = obj.noserie // Option[String],
        this.brand = obj.brand // Option[String],
        this.deviceType = obj.deviceType // Option[String],
        this.validationDateAgency = obj.validationDateAgency // Option[String],
        this.dateInstallationReview = obj.dateInstallationReview // Option[String],
        this.verificationType = obj.verificationType // Option[String],
        this.readingCoefficient = obj.readingCoefficient // Option[Double],
        this.installationFlow = obj.installationFlow // Option[Double],
        this.characteristicsMeter10 = obj.characteristicsMeter10 // Option[String],
        this.characteristicsMeter11 = obj.characteristicsMeter11 // Option[String],
        this.effectiveFlow = obj.effectiveFlow // Option[String],
        this.characteristicsMeter13 = obj.characteristicsMeter13 // Option[String],
        this.sprayIrrigation = obj.sprayIrrigation // Option[Double],
        this.otherMethodIrrigation = obj.otherMethodIrrigation // Option[Double],
        this.gravityIrrigation = obj.gravityIrrigation // Option[Double],
        this.characteristicActivity4 = obj.characteristicActivity4 // Option[String],
        this.estimatedVolume = obj.estimatedVolume // Option[String],
        this.antifreezeVolume = obj.antifreezeVolume // Option[Double],
        this.characteristicActivity7 = obj.characteristicActivity7 // Option[String],
        this.characteristicActivity8 = obj.characteristicActivity8 // Option[String],
        this.characteristicActivity9 = obj.characteristicActivity9 // Option[String],
        this.authorizedVolume = obj.authorizedVolume // Option[String],
        this.sau = obj.sau // Option[String],
        this.informationCompl3 = obj.information // Option[String],
        this.informationPoint = obj.informationPoint // Option[String],
        this.informationFolder = obj.informationFolder // Option[String]
    }
}