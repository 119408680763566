export default class MaterielAssignmentDto {
    constructor(obj) {
        this.id = obj.id
        this.startDate = obj.startDate
        this.endDate = obj.endDate
        this.comment = obj.comment
        this.stationId = obj.stationId
        this.stationAdress = obj.stationAdress
        this.stationType = obj.stationType
        this.materielId = obj.materielId
        this.stateCode = obj.stateCode
        this.materielType = obj.materielType
    }
}