import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import { exportFile } from '../../utils/ExportDataUtil'
import IAction from './IAction'

class Export extends IAction {
    fn = () => {
        if (this.props.onExportAsync) {
            return () => this.props.onExportAsync(res => exportFile(res))
        }
        return () => exportFile(this.props.onExport())
    }

    icon = () => {
        return 'file_download'
    }

    label = () => {
        return i18n.export
    }
    id = () => {
        return 'export_action_navbar'
    }
}

Export.propTypes = {
    onExport: PropTypes.func,
    onExportAsync: PropTypes.func,
}

export default Export
