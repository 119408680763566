export default class DtoPiezometrySituations {
    constructor(obj) {
        this.piezometerId = obj.piezometerId
        this.startDate = obj.startDate
        this.lastMeasureDate = obj.lastMeasureDate
        this.min = obj.min
        this.max = obj.max
        this.value = obj.value
        this.status = obj.status
        this.qualification = obj.qualification
        this.ngf = obj.ngf
        this.measuresCount = obj.measuresCount // nb mesures depuis le 1er janvier de l'année de la date demandée
        this.allMeasuresCount = obj.allMeasuresCount || obj.measuresCount
        this.threshold = obj.threshold
        this.thresholdColor = obj.thresholdColor
        this.dataType = obj.dataType
        this.controlErrors = obj.controlErrors || []
        this.adesExportDate = obj.adesExportDate
    }
}