export default class DtoOperation {
    constructor(obj) {
        this.id = obj.id
        this.codeQualitometer = obj.codeQualitometer
        this.date = obj.dateStart
        this.producer = obj.producerCode
        this.status = obj.status
        this.qualification = obj.qualification
        this.laboratoryId = obj.laboratoryId
        this.jobExecutionId = obj.jobExecutionId
    }
}