/* eslint-disable camelcase */

export default class DtoAnswerDocument {
    constructor(obj, docIndex) {
        this.idSurvey = obj.idSurvey // Option[Long] = None,
        this.idInstallation = obj.idInstallation // Option[Long] = None,
        this.idLinkedQuestion = obj.idLinkedQuestion // Option[Long] = None,
        this.docName = obj.docName // Option[String] = None,
        this.description = obj.description // Option[String] = None,
        this.idVisit = obj.idVisit // Option[Long] = None,
        this.idCampaign = obj.idCampaign // Option[Long] = None

        this.index = docIndex || 10000
    }
}
