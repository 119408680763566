'use strict'
import DtoHydroFile from '../../piezometry/dto/DtoPiezometryFile'
import DtoHydroPicture from '../../piezometry/dto/DtoPiezometryPicture'
import {
    RECEIVE_HYDROMETER_CHART_OPTIONS,
    RECEIVE_HYDROMETRIC_ADDITIONAL_MEASURES,
    RECEIVE_HYDROMETRIC_STATION_FILES,
    RECEIVE_HYDROMETRIC_STATION_FLOW,
    RECEIVE_HYDROMETRIC_STATION_PICTURES,
    RESET_HYDROMETER_STATION,
} from '../constants/hydro/HydrometerStationConstants'
import DtoHydrometerAdditionalMeasures from '../dto/hydrometricStation/DtoHydrometerAdditionalMeasures'
import DtoHydrometryStationMeasure from '../dto/hydrometricStation/DtoHydrometryStationMeasure'
import DtoHydrometerChartOptions from '../dto/hydrometricStation/DtoHydrometerChartOptions'

export const store = {
    hydrometricFlow: [],
    pictures: [],
    files: [],
    hydrologicalAdditionalMeasures: [],
    chartOptions: [],
}

export function HydrometerStationReducer(state = {}, action) {
    switch (action.type) {
        case RECEIVE_HYDROMETRIC_STATION_FLOW:
            return {
                ...state,
                hydrometricFlow: action.flowMeasures.map(measure => new DtoHydrometryStationMeasure(measure)),
            }
        case RECEIVE_HYDROMETRIC_STATION_PICTURES:
            return {
                ...state,
                pictures: action.pictures.map(picture => new DtoHydroPicture(picture)),
            }
        case RECEIVE_HYDROMETRIC_STATION_FILES:
            return {
                ...state,
                files: action.files.map(file => new DtoHydroFile(file)),
            }
        case RECEIVE_HYDROMETER_CHART_OPTIONS:
            return {
                ...state,
                chartOptions: action.chartOptions.map(file => new DtoHydrometerChartOptions(file)),
            }
        case RECEIVE_HYDROMETRIC_ADDITIONAL_MEASURES:
            return {
                ...state,
                hydrologicalAdditionalMeasures: action.hydrologicalAdditionalMeasures.map(measure => new DtoHydrometerAdditionalMeasures(measure)),
            }
        case RESET_HYDROMETER_STATION :
            return {
                ...state,
                ...store,
            }
        default:
            return state
    }
}
