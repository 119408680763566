import PropTypes from 'prop-types'
import React from 'react'
import { v4 as uuidv4 } from 'uuid'
import Button from '../../forms/Button'
import Checkbox from '../../forms/Checkbox'
import SubList from '../simpleList/SubList'

class SectionListPaginate extends SubList {
    constructor(props) {
        super(props)
        this.state = {
            open: true,
            collapsible: uuidv4(),
            paginationStart: 0,
            paginationEnd: 10,
            page: 1,
        }
    }

    toggleOpen = () => {
        this.setState(prevState => {
            return { open: !prevState }
        })
    }

    componentDidMount() {
        $(`.${this.state.collapsible}`).collapsible('open', this.props.openFirst ? 0 : 1).collapsible('open', 1)
    }

    onChangePages = (way, maxPage) => {
        const { paginationStart, paginationEnd, page } = this.state
        const lengthToDisplay = 10
        if (way && page < maxPage) {
            this.setState({ paginationStart: paginationStart + lengthToDisplay, paginationEnd: paginationEnd + lengthToDisplay, page: page + 1 })
        } else if (!way && page !== 1) {
            this.setState({ paginationStart: paginationStart - lengthToDisplay, paginationEnd: paginationEnd - lengthToDisplay, page: page - 1 })
        }
    }

    onSetPages = (way, maxPage, length, endElement) => {
        if (way) {
            this.setState({ paginationStart: length - endElement, paginationEnd: length, page: maxPage })
        } else if (!way) {
            this.setState({ paginationStart: 0, paginationEnd: 10, page: 1 })
        }
    }

    render() {
        const lengthToDisplay = 10
        const { paginationStart, paginationEnd, page } = this.state
        const { data, color, textColor, checked, themeName, onCheck, number, title } = this.props
        const dataLength = data.length
        const maxPage = Math.ceil(dataLength / lengthToDisplay)
        const endElement = Number.isInteger((dataLength / lengthToDisplay)) ? 10 : parseInt((dataLength / lengthToDisplay).toString().split('.')[1])
        const dataByPage = data.slice(paginationStart, paginationEnd)
        return (
            <li className='desactive'>
                <ul className={ `no-margin ${this.state.collapsible}` } data-collapsible='expandable'>
                    <li className='sub-card-list'>
                        <div className='card-panel card-list-title margin-top-1 margin-bottom-0 row padding-1 collapsible-header valign-wrapper'
                            style={ { background: color, height: '7%' } } onClick={ this.toggleOpen }
                        >
                            <h6 className='col s12 valign-wrapper' style={ { textAlign: 'left', color: textColor } }>
                                { title }
                                <div style={ { paddingLeft: '4px', color: textColor } }>{ `(${number})` }</div>
                            </h6>
                            { onCheck &&
                                <div onClick={ () => this.props.onCheck(themeName) }>
                                    <Checkbox checked={ checked } />
                                </div>
                            }
                            <i className='col s1 right-align medium material-icons no-margin expand-style-thin' style={ { color: textColor } }>
                                { this.state.open ? 'expand_more' : 'expand_less' }
                            </i>
                        </div>
                        <div className='col s12 content no-padding collapsible-body'>
                            {
                                dataByPage.map((d, i) => {
                                    return (
                                        <div key={ i }>
                                            { d.content }
                                        </div>
                                    )
                                })
                            }
                            { data.length > lengthToDisplay && (
                                <div className='valign-wrapper' style={ { paddingTop: '10px' } }>
                                    <Button
                                        className={ page === 1 ? 'disabled' : 'background-blue-primary' }
                                        title={ '<' }
                                        onClick={ () => this.onChangePages(false, maxPage) }
                                    />
                                    <div style={ { paddingLeft: '10px', paddingRight: '10px' } }>
                                        <Button
                                            className={ page === 1 ? 'disabled' : 'background-blue-primary' }
                                            title={ '1' }
                                            onClick={ () => this.onSetPages(false, maxPage, dataLength, endElement) }
                                        />
                                    </div>
                                    <Button
                                        className={ 'background-blue-primary' }
                                        title={ '...' }
                                    />
                                    <div style={ { paddingLeft: '10px', paddingRight: '10px' } }>
                                        <Button
                                            className={ page === maxPage ? 'disabled' : 'background-blue-primary' }
                                            title={ maxPage }
                                            onClick={ () => this.onSetPages(true, maxPage, dataLength, endElement) }
                                        />
                                    </div>
                                    <Button
                                        className={ page === maxPage ? 'disabled' : 'background-blue-primary' }
                                        title={ '>' }
                                        onClick={ () => this.onChangePages(true, maxPage) }
                                    />
                                </div>
                            )
                            }
                        </div>
                    </li>
                </ul>
            </li>

        )
    }
}

SectionListPaginate.propTypes = {
    openFirst: PropTypes.bool,
    collapsible: PropTypes.bool,
    title: PropTypes.string.isRequired,
    number: PropTypes.number,
    color: PropTypes.string,
    textColor: PropTypes.string,
    data: PropTypes.element,
    onCheck: PropTypes.func,
    checked: PropTypes.bool,
    themeName: PropTypes.string,
}

SectionListPaginate.defaultProps = {
    collapsible: false,
}

export default SectionListPaginate
