import React, { useEffect, useState } from 'react'
import i18n from 'simple-react-i18n'
import PropTypes from 'prop-types'
import Icon from 'components/icon/Icon'
import { Grid } from '@mui/material'
import RefJobAction from 'domain/job/RefJobAction'
import JobAction from 'import/actions/JobAction'
import { getFullDate } from 'utils/DateUtil'
import Loading from 'components/actions/Loading'
import Table from 'components/datatable/Table'
import { orderBy } from 'lodash'
import { nbPerPageLabelShorty } from 'referencial/constants/ReferencialConstants'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import InstallationAction from 'installation/actions/InstallationAction'
import DtoJobLogLight from 'import/dto/DtoJobLogLight'

const ImportExecutionStep = ({
    jobExecutionId,
}) => {
    const dispatch = useDispatch()
    const [logs, setLogs] = useState([])
    const [job, setJob] = useState()
    const {
        installation,
    } = useSelector(store => ({
        installation: store.InstallationReducer.installation,
    }), shallowEqual)

    const automaticCallLogs = () => {
        RefJobAction.fetchJobExecutions(-500, 10).then(res => {
            JobAction.requestJobLogs(-500, jobExecutionId).then(res2 => {
                const jobLogs = res2.data.map(el => new DtoJobLogLight(el, jobExecutionId))
                const jobExecution = res.find(e => e.id === jobExecutionId)
                setJob(jobExecution)
                setLogs(jobLogs.map(l => ({
                    ...l,
                    color: l.status === 'success' ? 'green-200' : (l.status === 'error' ? 'red-200' : (l.status === 'warning' ? 'yellow-200' : null)),
                    date: getFullDate(l.date),
                })))
                if (jobExecution?.statusCode === undefined) {
                    setTimeout(() => automaticCallLogs(), 2000)
                } else if ([1, 2].includes(jobExecution?.statusCode)) {
                    dispatch(InstallationAction.fetchInstallationSTEP(installation.id))
                }
            })
        })
    }

    useEffect(() => automaticCallLogs(), [])

    return (
        <div className='padding-top-2'>
            {
                (() => {
                    switch (job?.statusCode) {
                        case 0:
                            return (
                                <>
                                    <Grid container justifyContent='center' alignItems='center' spacing={3}>
                                        <h4>{i18n.jobFailedMessage}</h4>
                                    </Grid>
                                    <Grid container justifyContent='center' alignItems='center' spacing={3} className='padding-top-2'>
                                        <h5>{i18n.jobFailedDescription}</h5>
                                    </Grid>
                                    <Grid container justifyContent='center' alignItems='center' spacing={3} className='padding-top-4'>
                                        <Icon icon='error' style={{ color: 'red' }} size='large' clickable={false} />
                                    </Grid>
                                </>
                            )
                        case 1:
                        case 2:
                        case 4:
                            return (
                                <>
                                    <Grid container justifyContent='center' alignItems='center' spacing={3}>
                                        <h4>{i18n.jobSuccessMessage}</h4>
                                    </Grid>
                                    <Grid container justifyContent='center' alignItems='center' spacing={3} className='padding-top-2'>
                                        <h5>{i18n.jobSuccessDescription}</h5>
                                    </Grid>
                                    <Grid container justifyContent='center' alignItems='center' spacing={3} className='padding-top-4'>
                                        <Icon icon='check_circle' style={{ color: 'green' }} size='large' />
                                    </Grid>
                                </>
                            )
                        case undefined:
                            return (
                                <>
                                    <Grid container justifyContent='center' alignItems='center' spacing={3}>
                                        <h4>{i18n.jobProgressMessage}</h4>
                                    </Grid>
                                    <Grid container justifyContent='center' alignItems='center' spacing={3} className='padding-top-2'>
                                        <h5>{i18n.jobProgressDescription}</h5>
                                    </Grid>
                                    <Grid container justifyContent='center' alignItems='center' spacing={3} className='padding-top-4'>
                                        <img src={Loading} />
                                    </Grid>
                                </>
                            )
                        default:
                            return (
                                <>
                                    <Grid container justifyContent='center' alignItems='center' spacing={3}>
                                        <h4>{i18n.jobUnexpectedMessage}</h4>
                                    </Grid>
                                    <Grid container justifyContent='center' alignItems='center' spacing={3} className='padding-top-2'>
                                        <h5>{i18n.jobUnexpectedDescription}</h5>
                                    </Grid>
                                    <Grid container justifyContent='center' alignItems='center' spacing={3} className='padding-top-4'>
                                        <Icon icon='error' style={{ color: 'red' }} size='large' clickable={false} />
                                    </Grid>
                                </>
                            )
                    }
                })()
            }
            <Grid container justifyContent='center' alignItems='center' spacing={3} className='padding-top-2'>
                <Grid item xs={11}>
                    <Table
                        className='padding-left-1'
                        condensed
                        sortable
                        data={orderBy(logs, 'id', 'desc')}
                        paging
                        nbPerPageLabel={nbPerPageLabelShorty}
                        type={{ headers: ['station', 'date', 'value', 'status'] }}
                        customHeaders={{ station: i18n.elementLabel, value: i18n.valueLabel }}
                        maxHeight='62vh'
                        overflow='auto'
                        inverseDisplay
                        showTitle={false}
                    />
                </Grid>
            </Grid>
        </div>
    )
}

ImportExecutionStep.propTypes = {
    jobExecutionId: PropTypes.number,
}

export default ImportExecutionStep