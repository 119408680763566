import React from 'react'
import PropTypes from 'prop-types'
import createClass from 'create-react-class'

export default createClass({
    propTypes: {
        onFilter: PropTypes.func,
    },
    render () {
        return (
            <div className='dataTables_filter'>
                <input type='text' className='input-field validate form-control input-sm' placeholder='Rechercher' aria-controls='tableStationQualito'
                    onChange={ this.props.onFilter }
                />
            </div>
        )
    },
})
