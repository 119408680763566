import PropTypes from 'prop-types'
import { connect, shallowEqual, useSelector } from 'react-redux'
import DtoAccountHabilitation from '../../account/dto/DtoAccountHabilitation'
import { ADMIN, CONSULT, GEST, TECH } from '../../administration/components/user/constants/HabilitationConstants'
import DtoUser from '../../administration/components/user/dto/DtoUser'

const HabilitationRequired = ({
    habilitation: {
        required = [],
        atLeastOne = [],
        requiredRoles = [],
        forbiddenRoles = [],
    },
    children,
}) => {
    const {
        accountUser,
        accountHabilitations,
    } = useSelector(store => ({
        accountUser: store.AccountReducer.accountUser,
        accountHabilitations: store.AccountReducer.accountHabilitations,
    }), shallowEqual)

    const requiredValid = required.every(c => accountHabilitations.some(h => h.habilitation === c))
    const atLeastOneValid = !atLeastOne.length || atLeastOne.some(c => accountHabilitations.some(h => h.habilitation === c))

    const functionTestRole = role => {
        return role === ADMIN && accountUser.isAdmin === '1'
            || role === GEST && accountUser.metadata === '1'
            || role === TECH && accountUser.labo === '1'
            || role === CONSULT && accountUser.consultant === '1'
    }

    const requiredRolesValid = !requiredRoles.length || requiredRoles.some(functionTestRole)
    const forbiddenRolesValid = !forbiddenRoles.some(functionTestRole)

    return requiredValid && atLeastOneValid && requiredRolesValid && forbiddenRolesValid && children
}

HabilitationRequired.propTypes = {
    habilitation: PropTypes.shape({
        required: PropTypes.arrayOf(PropTypes.string),
        atLeastOne: PropTypes.arrayOf(PropTypes.string),
        isAdminNeeded: PropTypes.bool,
        isAdminAllowed: PropTypes.bool,
    }).isRequired,
    children: PropTypes.element.isRequired,
    accountUser: PropTypes.instanceOf(DtoUser),
    accountHabilitations: PropTypes.arrayOf(PropTypes.instanceOf(DtoAccountHabilitation)),
}

const mapStateToProps = (store) => ({
    accountUser: store.AccountReducer.accountUser,
    accountHabilitations: store.AccountReducer.accountHabilitations,
})

export default connect(mapStateToProps)(HabilitationRequired)