import moment from 'moment'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import i18n from 'simple-react-i18n'
import Checkbox from '../../../components/forms/Checkbox'
import Input from '../../../components/forms/Input'
import RadioButtons from '../../../components/forms/RadioButtons'
import Textarea from '../../../components/forms/Textarea'
import Row from '../../../components/react/Row'
import { EVENT_TYPES_CODES } from '../../../events/constants/EventsConstants'
import { getDate, getHour } from '../../../utils/DateUtil'
import { onChangeDate, onChangeHour } from '../../../utils/FormUtils'
import { getUser } from '../../../utils/SettingUtils'

class EventFormModal extends Component {
    constructor(props) {
        super(props)
        const date = this.props.initialDate || moment().valueOf()
        const contactCode = getUser().contactCode
        this.state = {
            event: {
                date,
                eventHour: date,
                comment: '',
                graph: '1',
                bankExport: '0',
                eventType: 'P',
                contactCode,
            },
        }
        props.onChange(this.state.event)
    }

    onChangeEvent = change => {
        const event = { ...this.state.event, ...change }
        this.setState({ event })
        this.props.onChange(event)
    }

    render() {
        const eventTypeList = [
            {
                code: EVENT_TYPES_CODES.COMMENT,
                label: i18n.comment,
            },
            {
                code: EVENT_TYPES_CODES.TO_PLAN,
                label: i18n.toPlan,
            },
            {
                code: EVENT_TYPES_CODES.TO_MONITOR,
                label: i18n.toMonitor,
            },
        ]
        const {
            eventType,
            date,
            eventHour,
            comment,
            graph,
            bankExport,
        } = this.state.event
        return (
            <div>
                <Row className='padding-bottom-1'>
                    <RadioButtons
                        selected={eventType}
                        elements={eventTypeList}
                        onChange={v => this.onChangeEvent({ eventType: v, bankExport: '0' })}
                    />
                </Row>
                <Row className='padding-bottom-1'>
                    <Input
                        col={6}
                        title={i18n.startDate}
                        value={getDate(date)}
                        onChange={v => onChangeDate(v, v2 => this.onChangeEvent({ date: v2 }))}
                    />
                    <Input
                        col={6}
                        title={i18n.startHour}
                        value={getHour(eventHour)}
                        onChange={v => onChangeHour(v, v2 => this.onChangeEvent({ eventHour: v2 }))}
                    />
                </Row>
                <Row className='padding-bottom-1'>
                    <Textarea
                        col={12}
                        title={i18n.comment}
                        value={comment}
                        onChange={v => this.onChangeEvent({ comment: v })}
                    />
                </Row>
                <Row>
                    <Checkbox
                        col={6}
                        label={i18n.displayGraph}
                        display
                        checked={graph === '1'}
                        onChange={v => this.setState({ graph: v ? '1' : '0' })}
                    />
                    {eventType === EVENT_TYPES_CODES.COMMENT && (
                        <Checkbox
                            col={6}
                            label={i18n.bankExport}
                            display
                            checked={bankExport === '1'}
                            onChange={v => this.setState({ bankExport: v ? '1' : '0' })}
                        />
                    )}
                </Row>
            </div>
        )
    }
}

EventFormModal.propTypes = {
    onChange: PropTypes.func,
    initialDate: PropTypes.number,
}

EventFormModal.defaultProps = {
    onChange: () => {},
}

export default EventFormModal
