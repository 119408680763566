import PropTypes from 'prop-types'
import React, { Component } from 'react'

class ProfileCard extends Component {
    render() {
        const clickable = this.props.onClick ? 'clickable' : ''
        return (
            <div className='col s2'>
                <div onClick={ this.props.onClick } className={ `card ${clickable} profileCard col s12` }>
                    <div className='card-content no-padding'>
                        { this.props.children }
                    </div>
                </div>
            </div>
        )
    }
}

ProfileCard.propTypes = {
    onClick: PropTypes.func,
    children: PropTypes.element.isRequired,
}

export default ProfileCard
