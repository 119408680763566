import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { countBy, groupBy, isEqual, maxBy, uniqBy } from 'lodash'
import CartographyStationsPanel from './CartographyStationsPanel'
import CartographyMapPanel from './CartographyMapPanel'
import CartographyControlPanel from './CartographyControlPanel'
import CartographyBackgroundPanel from './CartographyBackgroundPanel'
import CartographyAnalysisPanel from './CartographyAnalysisPanel'
import { allLayersProps } from './constants/MapLayersConstants'
import { arrayOf, getPropType, instanceOf, objectOf } from '../../utils/StoreUtils'
import {
    STATION_NAME,
    STATION_NAME_ASSOCIATION,
    STATION_QUALITOMETER_NAMES,
} from '../../station/constants/StationConstants'
import i18n from 'simple-react-i18n'
import CityAction from '../../referencial/components/city/actions/CityAction'
import AppStore from '../../store/AppStore'
import WatershedDto from '../../referencial/components/watershed/dto/WatershedDto'
import { INSTALLATION_TYPES } from '../../installation/constants/InstallationConstants'
import CityDto from '../../referencial/components/city/dto/CityDto'
import { filterStationsCoordinates } from '../../utils/mapUtils/CoordinateUtils'
import { searchMaxAnalysis, searchMinAnalysis, calculateAverage, calculateThresholdResult, filterValid, filterResult } from '../../utils/AnalyseUtils'
import { hasBooleanValue } from '../../utils/NumberUtil'
import AdministrationAction from '../../administration/actions/AdministrationAction'
import CMSEventDto from '../../events/dto/CMSEventDto'
import PollutionMapTabs from '../../alerting/components/map/PollutionMapTabs'
import { getLogin, getSettingInt, hasHabilitation } from '../../utils/SettingUtils'
import {
    SITUATION_MONITORING,
    SITUATION_POLLUTION,
} from '../../administration/components/user/constants/HabilitationConstants'
import ToastrAction from 'toastr/actions/ToastrAction'
import DtoQualitometerLight from 'quality/dto/DtoQualitometerLight'
import SieauParameterDto from '../../administration/dto/SieauParameterDto'
import SettingDto from 'administration/dto/SettingDto'
import { TAXON_MAP, homeListOfSelectedLayers, homeListOfSelectedStations } from 'carto/constants/CartoConstants'
import LayerDto from 'home/dto/LayerDto'
import { searchP90Analysis } from '../../utils/AnalyseUtils'
import CartographyHydrobiosPanel from './CartographyHydrobiosPanel'
import DtoSearchHydrobio from 'quality/dto/taxons/DtoSearchHydrobio'
import DtoSearchHydrobioLight from 'quality/dto/taxons/DtoSearchHydrobioLight'
import { getAbundanceTaxon, getOccurenceFrequencyTaxon, getPresenceMissingTaxon, getStatsTaxon } from './utils/TaxonPredefinedMap'

const $ = window.$
const qualitometerTypes = STATION_QUALITOMETER_NAMES.map(type => `3_${type.code}`)
const installationTypes = INSTALLATION_TYPES.map(type => `7_${type.code}`)
const other = 'other'
const nameStationTypes = [...qualitometerTypes, ...installationTypes, '3', 'PLUVIOMETER', 'PIEZOMETER', 'HYDROMETRIC_STATION', 'PRODUCTION_UNIT', 'DISTRIBUTION_UNIT', '7', 'CONTACT', 'CONTRIBUTOR']

class CartographyPanel extends Component {
    constructor(props) {
        super(props)
        const defaultLayers = props.defaultLayers.reduce((acc, v) => ({ ...acc, [v.layer]: true }), {})
        const baseState = props.layers.reduce((acc, layer) => {
            if (layer === 'STATIONS_POINTS') {
                acc.visible.STATIONS_POINTS = true
                acc.exists.STATIONS_POINTS = true
                return nameStationTypes
                    .reduce((newAcc, stationLayer) => this.generateVisibleAndExists(newAcc, stationLayer), acc)
            }
            if (layer === 'QUALITOMETER') {
                return [...qualitometerTypes, 'QUALITOMETER']
                    .reduce((newAcc, stationLayer) => this.generateVisibleAndExists(newAcc, stationLayer), acc)
            }
            return this.generateVisibleAndExists(acc, layer)
        }, {
            visible: defaultLayers,
            exists: defaultLayers,
            defaultLayers,
        })
        // const allState = props.componentType === 'homepage' || props.componentType === 'territory' || props.componentType === 'alerts' ? Object.keys(this.props.selectedSearchValues.dashboardCheckboxes || {})
        //     .reduce((acc, layerName) => this.generateVisibleAndExists(acc, layerName), baseState) : baseState
        this.state = baseState
    }

    generateVisibleAndExists = (state, layer) => {
        const { selectedSearchValues, componentType } = this.props
        const newState = Object.assign({}, state)
        const selectedCheckboxes = selectedSearchValues[componentType === 'homepage' ? 'dashboardCheckboxes' : 'mapCheckboxes']
        newState.visible[layer] = selectedCheckboxes && selectedCheckboxes[layer] ? selectedCheckboxes[layer].enabled : true
        newState.exists[layer] = newState.visible[layer]
        return newState
    }

    getLayerType = (typeName) => {
        return STATION_NAME_ASSOCIATION[typeName]
    }

    checkStationCharge = () => {
        if (this.props.stationsPoints) {
            const maxNumber = getSettingInt(this.props.applicationSettings, 'cartoStationsMaxNumber') || 15000
            const newVisible = { ...this.state.visible }
            const groups = groupBy(this.props.stationsPoints, 'typeName')
            Object.keys(groups).forEach(typeName => {
                if (typeName === 'quality') {
                    const qualityCount = countBy(groups.quality, 'stationType')
                    Object.keys(qualityCount).forEach(qualityType => {
                        if (qualityCount[qualityType] > maxNumber) {
                            newVisible[`3_${qualityType}`] = false
                        }
                    })
                } else if (typeName === 'installation') {
                    const installationCount = countBy(groups.installation, 'installationType')
                    Object.keys(installationCount).forEach(installationType => {
                        if (installationCount[installationType] > maxNumber) {
                            newVisible[`7_${installationType}`] = false
                        }
                    })
                } else if (groups[typeName].length > maxNumber) {
                    newVisible[STATION_NAME_ASSOCIATION[typeName]] = false
                }
            })
            if (!isEqual(this.state.visible, newVisible)) {
                this.setState({ visible: newVisible })
            }
        }
    }

    componentDidMount() {
        const { settings } = this.props
        this.checkStationCharge()
        this.checkIfLayerExists(this.props)
        if (!Object.keys(this.props.citiesIndex).length) {
            AppStore.dispatch(CityAction.fetchCities())
        }
        if (!settings.length) {
            this.props.fetchUserSettings(this.props.login ?? getLogin()).then(() => {
                this.setVisibleState()
            })
        } else {
            this.setVisibleState()
        }
    }

    getSelectedStationsParameter = () => {
        const { settings } = this.props
        const selectedStationsParameter = settings.find(s => s.parameter === homeListOfSelectedStations)?.value
        return selectedStationsParameter
    }

    getCartographyStations = () => {
        const selectedStationsParameter = this.getSelectedStationsParameter()
        if (selectedStationsParameter?.length >= 0) {
            return selectedStationsParameter
        }
        return null
    }

    getSelectedLayersParameter = () => {
        const { settings } = this.props
        const selectedLayersParameter = settings.find(s => s.parameter === homeListOfSelectedLayers)?.value
        return selectedLayersParameter
    }

    getCartographyLayers = () => {
        const selectedLayersParameter = this.getSelectedLayersParameter()
        if (selectedLayersParameter?.length) {
            return selectedLayersParameter
        }
        return ''
    }

    getCheckboxesType = () => {
        if (this.props.stationsPoints) {
            if (this.props.pollutionPanel) {
                return this.props.componentType === 'alerts' ? 'dashboardCheckboxes' : 'mapCheckboxes'
            }
            return this.props.componentType === 'homepage' ? 'dashboardCheckboxes' : 'mapCheckboxes'
        }
        return ''
    }

    getVisibleStations = () => {
        const stringStations = this.getCartographyStations()
        const { componentType } = this.props
        if (stringStations === null || componentType === 'showAll' || (componentType !== 'homepage' && componentType !== 'specificUser')) {
            return nameStationTypes
        } else if (stringStations.length > 0) {
            return stringStations.split(',')
        }
        return []
    }

    setVisibleState = () => {
        const stations = this.getVisibleStations()
        const stationsToNotShow = nameStationTypes.filter(n => !stations.includes(n))

        const stringLayers = this.getCartographyLayers()
        const layers = stringLayers.length > 0 ? stringLayers.split(',').map(item => {
            const layerName = this.props.themeLayers.find(l => l && l.id === parseInt(item))?.layer
            return layerName
        }) : []

        this.onChangeVisible(stationsToNotShow, false, () => this.onChangeVisible([...layers, other]))

        const obj = {
            ...stationsToNotShow.reduce((a, v) => ({ ...a, [v]: { enabled: false } }), {}),
            ...stations.reduce((a, v) => ({ ...a, [v]: { enabled: true } }), {}),
        }
        const checkboxesType = this.getCheckboxesType()
        this.props.setSelectedSearchValues(checkboxesType, obj)
    }

    componentWillUnmount() {
        AppStore.dispatch(AdministrationAction.resetMapCheckboxes())
    }

    componentDidUpdate(prevProps) {
        if (this.props.stationsPoints && this.props.stationsPoints.length > 100 ? this.props.stationsPoints.length !== prevProps.stationsPoints.length : !isEqual(prevProps.stationsPoints, this.props.stationsPoints)) {
            this.checkStationCharge()
        }
    }

    componentWillReceiveProps = nextProps => this.checkIfLayerExists(nextProps)

    onChangeVisible = (layerNames, choosedValue, callback = () => {}) => {
        const layers = layerNames.filter(l => !!l)
        const newState = {
            visible: {
                ...this.state.visible,
                ...(layers.reduce((acc, layer) => ({ ...acc, [layer]: hasBooleanValue(choosedValue) ? choosedValue : !this.state.visible[layer] }), {})),
            },
            exists: {
                ...this.state.exists,
                ...(layers.reduce((acc, layer) => ({ ...acc, ...(!this.state.visible[layer] ? { [layer]: true } : {}) }), {})),
            },
        }
        this.setState(newState, callback)
    }

    checkIfLayerExists(nextProps) {
        const {
            exists,
        } = this.state
        const defaultLayers = nextProps.defaultLayers.reduce((acc, v) => ({ ...acc, [v.layer]: true }), {})
        this.props.layers.map(layerName => {
            const propsWithExists = { ...nextProps, exists }
            const layerProps = allLayersProps(propsWithExists, nextProps.station)[layerName]
            if (layerProps && layerProps.shouldExists) {
                exists[layerName] = true
            }
        })
        if (!isEqual(exists, this.state.exists)) {
            this.setState({ exists })
        }
        if (!isEqual({ defaultLayers }, this.state.defaultLayers)) {
            this.setState({ defaultLayers })
        }
    }

    getControlPanel = () => (
        <div className='col s12 no-padding'>
            <CartographyControlPanel
                station={this.props.station}
                onChangeVisible={this.onChangeVisible}
                visible={{ ...this.state.defaultLayers, ...this.state.visible }}
                oneThemeLayer={this.props.oneThemeLayer}
                componentType={this.props.componentType}
                exists={{ ...this.state.defaultLayers, ...this.state.exists }}
                watermass={this.props.watermass}
                layers={this.props.layers}
                withThemeLayers={this.props.withThemeLayers}
                login={ this.props.login ?? getLogin()}
            />
        </div>
    )

    getBackgroundPanel = () => (
        <CartographyBackgroundPanel/>
    )

    getStationsPanel() {
        if (this.props.stationsPoints) {
            if (this.props.pollutionPanel) {
                return (
                    <div>
                        {
                            this.props.pollutionPanel &&
                            <PollutionMapTabs
                                cmsEvents={this.props.cmsEvents}
                            />
                        }
                        <CartographyStationsPanel style={{ float: 'left' }}
                            stationsPoints={ this.props.stationsPoints }
                            noStationsPanelTitle={ this.props.noStationsPanelTitle }
                            citiesIndex={ this.props.citiesIndex }
                            onListElementClick={ this.props.onListElementClick }
                            tableHeaders={ this.props.tableHeaders }
                            visible={{ ...this.state.defaultLayers, ...this.state.visible }}
                            onChangeVisible={this.onChangeVisible}
                            checkboxesType={ this.props.componentType === 'alerts' ? 'dashboardCheckboxes' : 'mapCheckboxes' }
                            login={ this.props.login ?? getLogin()}
                        />
                    </div>
                )
            }
            return (
                <CartographyStationsPanel
                    stationsPoints={ this.props.stationsPoints }
                    noStationsPanelTitle={ this.props.noStationsPanelTitle }
                    citiesIndex={ this.props.citiesIndex }
                    onListElementClick={ this.props.onListElementClick }
                    tableHeaders={ this.props.tableHeaders }
                    visible={{ ...this.state.defaultLayers, ...this.state.visible }}
                    onChangeVisible={ this.onChangeVisible }
                    checkboxesType={ this.props.componentType === 'homepage' ? 'dashboardCheckboxes' : 'mapCheckboxes' }
                    login={ this.props.login ?? getLogin()}
                />
            )
        } else if (this.props.analysis) {
            return (
                <CartographyAnalysisPanel
                    analysis={ this.props.analysis }
                    stationsPanelTitle={this.props.stationsPanelTitle}
                    thresholds={ this.props.qualityThresholds }
                    researchType={ this.props.researchType }
                    sortingType={ this.props.sortingType }
                />
            )
        } else if (this.props.hydrobioList) {
            return (
                <CartographyHydrobiosPanel
                    hydrobioList={ this.props.hydrobioList }
                    stationsPanelTitle={this.props.stationsPanelTitle}
                    thresholds={ this.props.qualityThresholds }
                />
            )
        }
        return null
    }

    getComponentWithPanels = map => (
        <div className='row'>
            <div className={ `col s${this.props.noPanels ? 12 : 6}` } >
                { map }
            </div>
            {
                !this.props.noPanels && (
                    <div className='col s6'>
                        { this.props.panel }
                        { this.getStationsPanel() }
                        { this.getControlPanel() }
                        { this.getBackgroundPanel() }
                    </div>
                )
            }
        </div>
    )

    getComponentWithTabs() {
        if (this.props.noPanels) {
            return []
        }
        const tabs = []
        if (this.props.panel) {
            tabs.push({
                id: 'criterias',
                icon: 'border_color',
                title: i18n.criterias,
                content: this.props.panel,
            })
        }

        if (!this.props.noStationPanel) {
            tabs.push({
                id: 'stations',
                icon: 'list',
                title: i18n.stations,
                content: this.getStationsPanel(),
            })
        }


        if (this.props.pollutionPanel && hasHabilitation(SITUATION_POLLUTION, false)) {
            if (!hasHabilitation(SITUATION_MONITORING, false)) {
                tabs.push({
                    id: 'cmsPollutionEvents',
                    icon: 'sms_failed',
                    title: i18n.situationPollution,
                    content: this.getStationsPanel(),
                })
                this.props.panels.map(p => tabs.push(p))
            } else {
                this.props.panels.map(p => tabs.push(p))
                tabs.push({
                    id: 'cmsPollutionEvents',
                    icon: 'sms_failed',
                    title: i18n.situationPollution,
                    content: this.getStationsPanel(),
                })
            }
        } else {
            this.props.panels.map(p => tabs.push(p))
        }

        const controlPanel = this.getControlPanel()
        if (controlPanel) {
            tabs.push({
                id: 'layers',
                icon: 'layers',
                title: i18n.mapLayers,
                content: controlPanel,
            })
        }

        tabs.push({
            id: 'backgrounds',
            icon: 'photos',
            title: i18n.backgroundLayers,
            content: this.getBackgroundPanel(),
        })

        return tabs
    }

    getAnalysisForEachQualitometer = () => {
        const {
            analysis,
            sortingType,
            qualityThresholds,
        } = this.props
        const validAnalysis = filterValid(filterResult(analysis))
        const groupedAnalysis = groupBy(validAnalysis, 'qualitometer')
        if (sortingType === '1') {
            return Object.keys(groupedAnalysis).map(key => searchMaxAnalysis(groupedAnalysis[key]))
        } else if (sortingType === '2') {
            return Object.keys(groupedAnalysis).map(key => searchMinAnalysis(groupedAnalysis[key]))
        } else if (sortingType === '3') {
            return Object.keys(groupedAnalysis).map(key => {
                const average = calculateAverage(groupedAnalysis[key], this.props.settings)
                const analyse = groupedAnalysis[key][0]
                const averageAnalyse = {
                    result: average,
                    parameter: analyse?.parameter,
                    unit: analyse?.unit,
                    fraction: analyse?.fraction,
                    support: analyse?.support,
                    qualitometer: analyse?.qualitometer,
                }
                return {
                    ...averageAnalyse,
                    ...calculateThresholdResult(averageAnalyse, qualityThresholds),
                }
            })
        } else if (sortingType === '4') {
            return Object.keys(groupedAnalysis).map(key => searchP90Analysis(groupedAnalysis[key]))
        }
        return Object.keys(groupedAnalysis).map(key => maxBy(groupedAnalysis[key], 'analysisDate'))
    }

    getFormattedStation = (data) => data.map(a => {
        const foundStation = this.props.qualitometers.find(q => q.id === a.qualitometer)
        return {
            code: foundStation?.code,
            name: foundStation?.name,
            id: a.qualitometer,
            typeName: 'quality',
            localisation: {
                x: foundStation?.x,
                y: foundStation?.y,
                projection: foundStation?.projection,
            },
            x: foundStation?.x,
            y: foundStation?.y,
            projection: foundStation?.projection,
            townCode: foundStation?.townCode,
            type: STATION_NAME.QUALITOMETER,
            color: a.color,
            markerIcon: `pictures/markers/map_gray_qualite_${a.color}.png`,
        }
    })

    getStations = () => {
        if (this.props.stationsPoints) {
            return this.props.stationsPoints
        } else if (this.props.analysis) {
            const analysis = this.getAnalysisForEachQualitometer()
            const qualitoWithNoData = uniqBy(this.props.analysis, 'qualitometer').map(a => a.qualitometer).filter(id => !analysis.some(a => a.qualitometer === id)).map(id => ({ qualitometer: id, color: 'white' }))
            return this.getFormattedStation([...analysis, ...qualitoWithNoData])
        } else if (this.props.hydrobioList) {
            const hydrobioDatas = (() => {
                const {
                    hydrobioList,
                    sortingType,
                    qualityThresholds,
                    selectedTaxon,
                } = this.props
                switch (this.props.taxonMap) {
                    case TAXON_MAP.PRESENCE_MISSING: return getPresenceMissingTaxon(selectedTaxon, hydrobioList)
                    case TAXON_MAP.ABUNDANCE: return getAbundanceTaxon(selectedTaxon, hydrobioList, sortingType)
                    case TAXON_MAP.OCCURENCE_FREQUENCY: return getOccurenceFrequencyTaxon(selectedTaxon, hydrobioList)
                    default: return getStatsTaxon(hydrobioList, sortingType, qualityThresholds)
                }
            })()
            return this.getFormattedStation(hydrobioDatas)
        }
        return []
    }

    render() {
        const stations = filterStationsCoordinates(this.getStations(), this.props.citiesIndex)
        const map = (
            <CartographyMapPanel
                layers={ this.props.layers }
                height={ this.state.fullScreen ? window.innerHeight - $('.nav-wrapper').height() : this.props.height }
                componentType={ this.props.componentType }
                station={ this.props.station }
                oneThemeLayer={ this.props.oneThemeLayer }
                visible={ { ...this.state.defaultLayers, ...this.state.visible } }
                stationType={ this.props.stationType }
                exists={ { ...this.state.defaultLayers, ...this.state.exists } }
                town={ this.props.town }
                watermass={ this.props.watermass }
                satelliteMode={ this.props.satelliteMode }
                defaultZoom={ this.props.defaultZoom }
                onMoveEnd={ this.props.onMoveEnd }
                colorMode={ this.props.colorMode }
                fullScreen={ this.state.fullScreen }
                stationsPoints={ stations }
                tabs={ this.getComponentWithTabs() }
                onChangeTab={ this.props.onChangeTab }
                openByDefault={ this.props.tabsOpenByDefault }
                stationsPointsExport={ this.props.stationsPoints }
                layersExport={ this.props.layers }
                stationExport={ this.props.station }
                analysisExport={ this.props.analysis || this.props.hydrobioList }
                analysisMode={ this.props.analysis?.length || this.props.hydrobioList?.length }
                onClickMap={ this.props.onClickMap }
                tabsLoadOnClick={ this.props.tabsLoadOnClick }
                noMarkerTooltip={ this.props.noMarkerTooltip }
                noSearchBar={ this.props.noSearchBar }
                noTownsOnClick={ this.props.noTownsOnClick }
                getTownObjects={ this.props.getTownObjects }
                getStation={ this.props.getStation }
                forceZoom={ this.props.forceZoom }
                popupStyle={ this.props.popupStyle }
                onToggleFullScreen={this.props.onToggleFullScreen}
                styleContainer={this.props.styleContainer}
                mapConf={this.props.mapConf}
            />
        )

        return (
            <div className={ `row no-margin ${this.state.fullScreen && 'fullscreen-map'}${this.props.componentType === 'homepage' && '-homepage'}` }>
                <div className='col s12 no-padding'>
                    {
                        this.props.withPanels ?
                            this.getComponentWithPanels(map)
                            :
                            map }
                </div>
            </div>
        )
    }
}

CartographyPanel.propTypes = {
    panel: PropTypes.element,
    panels: PropTypes.arrayOf(PropTypes.shape({
        icon: PropTypes.string,
        title: PropTypes.string,
        content: PropTypes.element,
    })),
    height: PropTypes.number,
    layers: PropTypes.arrayOf(PropTypes.string),
    satelliteMode: PropTypes.bool,
    stationType: PropTypes.string,
    watermass: PropTypes.string,
    town: PropTypes.string,
    station: getPropType('qualitometer'),
    stationsPoints: getPropType('qualitometers'),
    stationsPanelTitle: PropTypes.string,
    noStationsPanelTitle: PropTypes.bool,
    analysis: getPropType('analysis'),
    hydrobioList: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.instanceOf(DtoSearchHydrobio)),
        PropTypes.arrayOf(PropTypes.instanceOf(DtoSearchHydrobioLight)),
    ]),
    oneThemeLayer: PropTypes.object,
    qualityThresholds: getPropType('qualityThresholds'),
    withPanels: PropTypes.bool,
    noPanels: PropTypes.bool,
    noStationPanel: PropTypes.bool,
    noMarkerTooltip: PropTypes.bool,
    noTownsOnClick: PropTypes.bool,
    defaultZoom: PropTypes.number,
    expandable: PropTypes.bool,
    expanded: PropTypes.bool,
    fullScreenable: PropTypes.bool,
    componentType: PropTypes.string.isRequired,
    fullScreen: PropTypes.bool,
    onFullScreen: PropTypes.func,
    withThemeLayers: PropTypes.bool,
    onMoveEnd: PropTypes.func,
    colorMode: PropTypes.bool,
    tabsOpenByDefault: PropTypes.bool,
    stationWatershed: PropTypes.instanceOf(WatershedDto),
    watershed1: instanceOf(WatershedDto),
    watershed2: instanceOf(WatershedDto),
    watershed3: instanceOf(WatershedDto),
    watershed4: instanceOf(WatershedDto),
    researchType: PropTypes.number,
    tableHeaders: PropTypes.arrayOf(PropTypes.tableHeaders),
    onListElementClick: PropTypes.func,
    citiesIndex: objectOf(CityDto),
    sortingType: PropTypes.string,
    onChangeTab: PropTypes.func,
    onClickMap: PropTypes.func,
    tabsLoadOnClick: PropTypes.bool,
    pollutionPanel: PropTypes.bool,
    cmsEvents: arrayOf(CMSEventDto),
    getTownObjects: PropTypes.func,
    getStation: PropTypes.func,
    forceZoom: PropTypes.bool,
    popupStyle: PropTypes.shape({}),
    noSearchBar: PropTypes.bool,
    qualitometers: PropTypes.arrayOf(PropTypes.instanceOf(DtoQualitometerLight)),
    selectedSearchValues: PropTypes.shape({
        quality: PropTypes.shape({
            searchValue: PropTypes.string,
            filter: PropTypes.number,
        }),
        piezometry: PropTypes.shape({
            searchValue: PropTypes.string,
            filter: PropTypes.number,
        }),
        dashboardCheckboxes: PropTypes.shape({}),
    }),
    applicationSettings: arrayOf(SieauParameterDto),
    toastrWarning: PropTypes.func,
    setSelectedSearchValues: PropTypes.func,
    fetchUserSettings: PropTypes.func,
    onToggleFullScreen: PropTypes.func,
    settings: PropTypes.arrayOf(PropTypes.instanceOf(SettingDto)),
    themeLayers: PropTypes.arrayOf(PropTypes.instanceOf(LayerDto)),
    defaultLayers: PropTypes.arrayOf(PropTypes.instanceOf(LayerDto)),
    styleContainer: PropTypes.shape({}),
    taxonMap: PropTypes.string,
    selectedTaxon: PropTypes.string,
    mapConf: PropTypes.shape({
        size: PropTypes.number,
        center: PropTypes.arrayOf(PropTypes.number),
        zoom: PropTypes.number,
    }),
    login: PropTypes.string,
}

CartographyPanel.defaultProps = {
    showAllLayers: false,
    satelliteMode: false,
    panel: null,
    withPanels: false,
    defaultZoom: 9,
    noPanels: false,
    noStationPanel: false,
    noMarkerTooltip: false,
    fullScreenable: true,
    fullScreen: false,
    tabsOpenByDefault: false,
    layers: [],
    defaultLayers: [],
    panels: [],
    withThemeLayers: true,
    oneThemeLayer: {},
    onMoveEnd: () => {},
    onToggleFullScreen: () => {},
    qualityThresholds: [],
    sortingType: 0,
    pollutionPanel: false,
    forceZoom: false,
    componentType: 'all',
}

const mapStateToProps = store => ({
    externalSites: store.StationReducer.externalSites,
    sitesTypes: store.StationReducer.sitesTypes,
    stationKMLExists: store.StationReducer.stationKMLExists,
    stationWatershed: store.WatershedReducer.stationWatershed,
    watershed1: store.WatershedReducer.watershed1,
    watershed2: store.WatershedReducer.watershed2,
    watershed3: store.WatershedReducer.watershed3,
    watershed4: store.WatershedReducer.watershed4,
    citiesIndex: store.CityReducer.citiesIndex,
    qualitometers: store.QualityReducer.qualitometersLight,
    selectedSearchValues: store.AdministrationReducer.selectedSearchValues,
    applicationSettings: store.AdministrationReducer.applicationSettings,
    settings: store.AdministrationReducer.userSettings,
    themeLayers: store.HomeReducer.themeLayers,
})

const mapDispatchToProps = {
    toastrWarning: ToastrAction.warning,
    setSelectedSearchValues: AdministrationAction.setSelectedSearchValues,
    fetchUserSettings: AdministrationAction.fetchUserSettings,
}

export default connect(mapStateToProps, mapDispatchToProps)(CartographyPanel)
