import { Button, Card, Dialog, Grid, Icon as IconMUI } from '@mui/material'
import DtoHydrometryObservation from 'hydrometry/dto/DtoHydrometryObservation'
import PropTypes from 'prop-types'
import React, { useMemo, useState } from 'react'
import { SANDRE } from 'referencial/constants/ReferencialConstants'
import { getDate, getDateWithHour, getFullDate, getHour } from 'utils/DateUtil'
import i18n from 'simple-react-i18n'
import useSandreList from 'utils/customHook/useSandreList'
import ColorfulCard from 'components/card/ColorfulCard'
import { getCardObservationColor, getModalite } from 'utils/HydroUtils'
import Fancybox from 'components/fancybox/Fancybox'
import ApplicationConf from 'conf/ApplicationConf'
import { LIGHTGREY } from 'components/constants/ColorConstant'
import { DialogActionsMUI, DialogContentMUI, DialogTitleMUI } from 'components/styled/Dialog'
import Icon from 'components/icon/Icon'
import SimpleDatePicker from 'components/forms/SimpleDatePicker'
import Input from 'components/forms/Input'
import Select from 'components/forms/Select'
import Textarea from 'components/forms/Textarea'
import moment from 'moment'
import { onChangeHour } from 'utils/FormUtils'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import HydrometryAction from 'hydrometry/actions/HydrometryAction'
import { getUser } from 'utils/SettingUtils'
import ConfirmModal from 'components/modal/ConfirmModal'
import { PICTURE, PICTURES_EXTENSIONS } from 'components/file/constant/FileConstants'
import { STATION_TYPE_CONSTANT, STATION_TYPE_NAME } from 'station/constants/StationConstants'
import { formatedFileName, getFileExtension } from 'utils/FileUtils'
import { getSandreLabel } from 'utils/StringUtil'
import { template } from 'lodash'
import StationAction from 'station/actions/StationAction'
import EventsAction from 'events/actions/EventsAction'
import { HYDROMETRY } from 'home/constants/RouteConstants'

const ObservationDialog = ({
    observation,
    open,
    onClose = () => {},
}) => {
    const [picture, setPicture] = useState()

    const {
        hydrometricStation,
        hydrometryCampaigns,
        hydrometryCampaignStations,
        sandreCodes,
    } = useSelector(store => ({
        hydrometricStation: store.HydrometryReducer.hydrometricStation,
        hydrometryCampaigns: store.HydrometryReducer.hydrometryCampaigns,
        hydrometryCampaignStations: store.HydrometryReducer.hydrometryCampaignStations,
        sandreCodes: store.ReferencialReducer.sandreCodes,
    }), shallowEqual)

    const { id: stationId, code: stationCode } = hydrometricStation

    const campaignsList = useMemo(() => {
        return hydrometryCampaigns.filter((c) => c.statut === 2
            && hydrometryCampaignStations.some((s) => s.campaignId === c.id && s.stationId === stationId))
    }, [stationId, hydrometryCampaigns, hydrometryCampaignStations])

    const [observationUpdated, setObservation] = useState({
        ...observation,
        date: observation.observationDate,
        hour: observation.observationDate,
        campaignCode: campaignsList.length === 1 ? campaignsList[0].id : undefined,
    })
    const onChangeObservation = (changes) => setObservation({ ...observationUpdated, ...changes })

    const observationTypes = useSandreList(SANDRE.HYDRO_OBSERVATIONS)
    const observationModalites4 = useSandreList(SANDRE.HYDRO_OBSERVATIONS_MODALITES_4)
    const observationModalites5 = useSandreList(SANDRE.HYDRO_OBSERVATIONS_MODALITES_5)

    const isConditionValidated = !!observationUpdated.observationType && !!observationUpdated.date && !!observationUpdated.hour && !!observationUpdated.observationCode
    const observationValeurs = getModalite(observationUpdated.observationType, observationModalites4, observationModalites5)

    const dispatch = useDispatch()

    const saveObservation = () => {
        const newPictureName = picture ? `${stationCode}_OBSERVATION_1_${moment().format('YYYYMMDD-HHmmss')}_HYDROMETRY_${formatedFileName(picture?.formattedName)}.${picture.extension}` : observationUpdated.pictureName
        if (picture) {
            if (observation.pictureName) {
                dispatch(StationAction.deleteFile({
                    name: observation.pictureName,
                    fileType: 'picture',
                }))
            }
            dispatch(StationAction.addStationFile({
                stationType: STATION_TYPE_CONSTANT.HYDROMETRY,
                stationId,
                path: 'PHOTOS/',
                name: newPictureName,
                content: picture.content,
            }, PICTURE))
            dispatch(StationAction.fetchPictures(stationCode, STATION_TYPE_CONSTANT.HYDROMETRY))
        }
        const observationDate = getDateWithHour(observationUpdated.date, observationUpdated.hour).valueOf()
        const event = {
            bankExport: '0',
            chgtpiles: '0',
            code: stationId,
            comment: template(i18n.observationEventComment)({
                observationCateg: getSandreLabel(sandreCodes, SANDRE.HYDRO_OBSERVATIONS, observationUpdated.observationType),
                observationType: observationValeurs.find((m) => m.code === Number(observationUpdated.observationCode))?.name ?? '',
            }),
            date: observationDate,
            endDate: observationDate,
            eventDate: observationDate,
            eventHour: observationDate,
            eventType: 'C',
            graph: '0',
            razstation: '0',
            campaignCode: observationUpdated.campaignCode,
        }
        const obs = {
            ...observationUpdated,
            siteCode: stationId,
            observationDate,
            actualObservationDate: observation.observationDate,
            actualObservationType: observation.observationType,
            actualObservationCode: observation.observationCode,
            pictureName: newPictureName,
        }
        if (observation.idEvent) {
            dispatch(EventsAction.updateEvent(HYDROMETRY, stationId, observation.idEvent, { ...event, id: observation.idEvent })).then(() => {
                dispatch(HydrometryAction.updateHydrometerObservation({ ...obs, idEvent: observation.idEvent })).then(onClose)
                dispatch(EventsAction.fetchStationsEvents(HYDROMETRY, [stationId]))
            })
        } else {
            dispatch(EventsAction.addEvent(
                STATION_TYPE_NAME.hydrometry,
                stationId,
                event,
                (idEvent) => {
                    dispatch(HydrometryAction.updateHydrometerObservation({ ...obs, idEvent })).then(onClose)
                    dispatch(EventsAction.fetchStationsEvents(HYDROMETRY, [stationId]))
                },
            ))
        }
    }

    const onUploadFile = (event) => {
        // Read File
        if (event.target.files && event.target.files[0]) {
            let reader = new FileReader()
            const file = event.target.files[0]
            reader.onload = (e) => {
                setPicture({
                    stationType: STATION_TYPE_CONSTANT.HYDROMETRY,
                    stationId,
                    path: '',
                    name: file.name,
                    content: e.target.result,
                    fileType: PICTURE,
                    active: 1,
                    extension: file.name && getFileExtension(file.name),
                })
                setObservation({ ...observationUpdated, pictureName: file.name })
            }
            reader.readAsDataURL(event.target.files[0])
        }
    }

    return (
        <Dialog
            onClose={onClose}
            fullWidth
            maxWidth='lg'
            open={open}
        >
            <DialogTitleMUI sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                {i18n.updateObservation}
                <Icon
                    sx={{ color: 'white' }}
                    size='small'
                    icon='close'
                    onClick={onClose}
                />
            </DialogTitleMUI>
            <DialogContentMUI className='padding-top-1 no-margin'>
                <Grid container spacing={1}>
                    <Grid item xs={3}>
                        <SimpleDatePicker
                            col={12}
                            obligatory
                            label={i18n.date}
                            value={observationUpdated.date}
                            max={moment().valueOf()}
                            onChange={v => onChangeObservation({ date: v })}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <Input
                            col={12}
                            obligatory
                            value={getHour(observationUpdated.hour)}
                            title={i18n.startHour}
                            onChange={v => onChangeHour(v, v2 => onChangeObservation({ hour: v2 }))}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Select
                            col={12}
                            noSort={true}
                            options={campaignsList}
                            label={i18n.campaign}
                            value={observationUpdated.campaignCode}
                            onChange={v => onChangeObservation({ campaignCode: v })}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Select
                            noNullValue
                            obligatory
                            col={12}
                            noSort={true}
                            options={observationTypes}
                            label={i18n.observationType}
                            value={observationUpdated.observationType}
                            onChange={v => onChangeObservation({ observationType: v, observationCode: undefined })}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Select
                            obligatory
                            col={12}
                            noSort={true}
                            options={observationValeurs}
                            label={i18n.value}
                            value={observationUpdated.observationCode}
                            onChange={v => onChangeObservation({ observationCode: v?.toString() })}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Textarea col={12} value={observationUpdated.comment} title={i18n.comment} onChange={v => onChangeObservation({ comment: v })} />
                        <div className='col s12 file-field input-field'>
                            <div className='btn'>
                                <span>{ i18n.importLabel }</span>
                                <input
                                    accept={PICTURES_EXTENSIONS.join(', ')}
                                    id='uploadFile'
                                    type='file'
                                    onChange={ onUploadFile }
                                />
                            </div>
                            <div className='file-path-wrapper'>
                                <input className='file-path validate no-margin' type='text' value={observationUpdated.pictureName} />
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </DialogContentMUI>
            <DialogActionsMUI>
                <Button disabled={!isConditionValidated} onClick={() => saveObservation()} variant='contained' color='primary'>
                    {i18n.validate}
                </Button>
            </DialogActionsMUI>
        </Dialog>
    )
}

ObservationDialog.propTypes = {
    observation: PropTypes.instanceOf(DtoHydrometryObservation),
    open: PropTypes.bool,
    onClose: PropTypes.func,
}

const ObservationCard = ({
    observation = {},
    readMode,
}) => {
    const [openUpdate, setOpenUpdate] = useState(false)
    const [openDelete, setOpenDelete] = useState(false)

    const {
        hydrometricStation,
    } = useSelector(store => ({
        hydrometricStation: store.HydrometryReducer.hydrometricStation,
    }), shallowEqual)

    const observationTypes = useSandreList(SANDRE.HYDRO_OBSERVATIONS)
    const observationModalites4 = useSandreList(SANDRE.HYDRO_OBSERVATIONS_MODALITES_4)
    const observationModalites5 = useSandreList(SANDRE.HYDRO_OBSERVATIONS_MODALITES_5)
    const updateDate = getDate(observation.updateDate)
    const updateHour = getHour(observation.updateDate)
    const observationDate = getFullDate(observation.observationDate)
    const observationType = observationTypes.find(sandreCode => sandreCode.code === observation.observationType)?.name
    const typeModalite = observation?.observationType === 1 ? observationModalites4 : observationModalites5
    const modalite = typeModalite.find(o => o.code === parseInt(observation?.observationCode))

    const dispatch = useDispatch()

    const onDelete = () => {
        if (observation.pictureName) {
            dispatch(StationAction.deleteFile({
                name: observation.pictureName,
                fileType: 'picture',
            }))
            dispatch(StationAction.fetchPictures(hydrometricStation.code, STATION_TYPE_CONSTANT.HYDROMETRY))
        }
        dispatch(HydrometryAction.deleteHydrometerObservation(observation))
        setOpenDelete(false)
    }

    return (
        <>
            <ColorfulCard color={getCardObservationColor(modalite?.reference)}>
                <Card className='no-margin' style={{ borderRadius: '0px' }}>
                    <Grid container justifyContent='space-between'>
                        <Grid item>
                            <h6 className='col s12 bold no-margin-bottom'>{ `${modalite?.name ?? i18n.unknown} ${observationType && `[${observationType}]`}` }</h6>
                            <h6 className='col s12'>{ `${observationDate} ${observation.comment ? ` - ${observation.comment}` : ''}` } </h6>
                        </Grid>
                        {observation?.pictureName && (
                            <Grid item className='padding-1'>
                                <Grid container justifyContent='flex-end'>
                                    <Fancybox>
                                        <a href={ApplicationConf.picture.pictureCompressPath(observation?.pictureName)} data-fancybox='images' data-caption={observation?.pictureName}>
                                            <img src={ApplicationConf.picture.pictureCompressPath(observation?.pictureName)} style={{ maxWidth: '100%', maxHeight: '70px' }} />
                                        </a>
                                    </Fancybox>
                                </Grid>
                            </Grid>
                        )}
                        {!readMode && getUser()?.consultant !== '1' && (
                            <Grid item>
                                <Grid container alignItems='center' sx={{ height: '100%' }}>
                                    <IconMUI className='clickable' onClick={() => setOpenUpdate(true)}>edit</IconMUI>
                                    <IconMUI
                                        className='clickable margin-right-1 margin-left-1'
                                        onClick={() => setOpenDelete(true)}
                                    >
                                        delete
                                    </IconMUI>
                                </Grid>
                            </Grid>
                        )}
                        <Grid item xs={12} sx={{ backgroundColor: LIGHTGREY }}>
                            <p className='font-size-10 padding-left-1'>
                                { observation.updateLogin && `${i18n.writtenBy}  ${observation.updateLogin}` } { i18n.atDate } { updateDate } { i18n.atHour } { updateHour }
                            </p>
                        </Grid>
                    </Grid>
                </Card>
            </ColorfulCard>
            {openUpdate && (
                <ObservationDialog
                    observation={observation}
                    open={openUpdate}
                    onClose={() => setOpenUpdate(false)}
                />
            )}
            {openDelete && (
                <ConfirmModal
                    isOpen={openDelete}
                    title={i18n.deletingConfirmation}
                    onValidate={onDelete}
                    onClose={() => setOpenDelete(false)}
                />
            )}
        </>
    )
}

ObservationCard.propTypes = {
    observation: PropTypes.instanceOf(DtoHydrometryObservation),
    readMode: PropTypes.bool,
}

export default ObservationCard