export default class DtoTerritoryUgeVolume {
    constructor(obj) {
        this.idUge = obj.idUge // Option[String],
        this.nameUge = obj.nameUge // Option[String],
        this.idSubUge = obj.idSubUge // Option[String],
        this.nameSubUge = obj.nameSubUge // Option[String],
        this.volume = obj.volume // Option[Double]
        this.volume = obj.volume // Option[Double]
        this.tanks = obj.tanks // Option[Double] = None
    }
}