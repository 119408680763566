import React, { useState, useEffect } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import Card from 'components/card/Card'
import Input from 'components/forms/Input'
import i18n from 'simple-react-i18n'
import Row from 'components/react/Row'
import PropTypes from 'prop-types'
import { template } from 'lodash'
import ToastrAction from 'toastr/actions/ToastrAction'
import StationMapDashboardPanel from 'station/components/dashboard/component/map/StationMapDashboardPanel'
import LinkedStationsPanel from 'station/components/associatedStation/LinkedStationsPanel'
import StationUpdatePanel from 'station/components/update/StationUpdatePanel'
import ResourceAction from 'resource/actions/ResourceAction'
import ResourceDto from 'resource/dto/ResourceDto'
import { SANDRE } from 'referencial/constants/ReferencialConstants'
import DtoSandreCode from 'referencial/dto/DtoSandreCode'
import Select from 'components/forms/Select'
import Textarea from 'components/forms/Textarea'
import useActions from 'utils/customHook/useActions'
import useSandreList from 'utils/customHook/useSandreList'

const ResourceDescriptionPanel = ({
    id,
}) => {
    const {
        resourceProps,
    } = useSelector(store => ({
        resourceProps: store.ResourceReducer.resource,
    }), shallowEqual)

    const [readMode, setReadMode] = useState(true)
    const [resource, setResource] = useState({})
    const resourceTypes = useSandreList(SANDRE.MILIEU_EAU)

    const dispatch = useDispatch()

    const onChangeResource = value => {
        setResource({ ...resource, ...value })
    }

    const onSave = () => {
        if (!resource.code) {
            ToastrAction.error(template(i18n.fieldNotSet)({ field: i18n.code }))
        } else {
            dispatch(ResourceAction.updateResource(resource))
            setReadMode(true)
        }
    }

    useEffect(() => {
        if (resourceProps && resourceProps.id) {
            setResource(resourceProps)
        } else {
            dispatch(ResourceAction.fetchResource(id))
        }
    }, [resourceProps])

    useActions(() => {
        if (!readMode) {
            return {
                cancel: () => setReadMode(true),
                save: onSave,
            }
        }
        return {
            edit: () => setReadMode(false),
            delete: () => dispatch(ResourceAction.deleteResource(id)),
        }
    }, [resource, readMode])

    return (
        <Row noMargin={false} className={ 'margin-left-2' }>
            <div className='col s9 padding-top-1' style={{ marginBottom: '100px' }}>
                <Card title={i18n.description}>
                    <Row className='padding-top-1'>
                        <Input col={6}
                            value={resource.code}
                            title={i18n.code}
                            disabled
                            onChange={v => onChangeResource({ code: v })}
                        />
                        <Input col={ 6 }
                            value={ resource.name }
                            title={ i18n.name }
                            disabled={readMode}
                            maxLength={25}
                            onChange={v => onChangeResource({ name: v }) }
                        />
                    </Row>
                    <Row>
                        <Input col={ 6 }
                            value={ resource.nature }
                            title={ i18n.nature }
                            disabled={readMode}
                            onChange={v => onChangeResource({ nature: v }) }
                        />
                        <Select
                            col={ 6 }
                            options={ resourceTypes }
                            label={ i18n.resourceType }
                            nullLabel='&nbsp;'
                            onChange={ v => onChangeResource({ resourceType: v.toString() }) }
                            value={ resource.resourceType }
                            disabled={readMode}
                        />
                    </Row>
                    <Row>
                        <Textarea
                            col={ 12 }
                            style={{ margin: '4px 0 6px' }}
                            noMargin={false}
                            rows={5}
                            value={ resource.descriptif }
                            title={ i18n.descriptif }
                            onChange={ v => onChangeResource({ descriptif: v }) }
                            disabled={readMode}
                        />
                    </Row>
                    <Row>
                        <Textarea
                            col={ 12 }
                            style={{ margin: '4px 0 6px' }}
                            noMargin={false}
                            rows={5}
                            value={ resource.exploitationComment }
                            title={ i18n.exploitationComment }
                            onChange={ v => onChangeResource({ exploitationComment: v }) }
                            disabled={readMode}
                        />
                    </Row>
                </Card>
            </div>
            <div className='col s3 padding-top-1' style={{ marginBottom: '100px' }}>
                <StationUpdatePanel station={ resource }/>
                <StationMapDashboardPanel noMarkerTooltip station={resource} type={'resource'} />
                <LinkedStationsPanel noMargin={false} className='margin-top-1' station={resourceProps} { ...{ readMode, editMode: !readMode } } />
            </div>
        </Row>
    )
}

ResourceDescriptionPanel.propTypes = {
    id: PropTypes.number,
    fetchResource: PropTypes.func,
    updateResource: PropTypes.func,
    resource: PropTypes.instanceOf(ResourceDto),
    sandreCodes: PropTypes.arrayOf(PropTypes.instanceOf(DtoSandreCode)),
}

export default ResourceDescriptionPanel
