/* eslint-disable camelcase */
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import { getExport, setModal } from '../../../../utils/linkUtils'
import SieauAction from '../../../../components/sieau/SieauAction'
import Table from '../../../../components/datatable/Table'
import { arrayOf, getSandreList } from '../../../../utils/StoreUtils'
import DtoSandreCode from '../../../../referencial/dto/DtoSandreCode'
import { SANDRE } from '../../../../referencial/constants/ReferencialConstants'
import { getSandreLabel } from '../../../../utils/StringUtil'
import DtoTankStation from '../dto/DtoTankStation'
import TankZonesModal from './modal/TankZonesModal'

const TankZonesPanel = ({
    tank = {},
    readMode = false,
    sandreCodes = [],
    onChange = () => {},
    setPopup = () => {},
}) => {
    const sandreList = getSandreList(sandreCodes, SANDRE.RESERVOIRS_ZONES)
    const stationTank = sandreList.map(element => {
        const data = tank.link_zones.find(ss => element.code === ss.code) || {}
        return {
            ...element,
            ...data,
            state: data.stateCode ? getSandreLabel(sandreCodes, SANDRE.RESERVOIRS_ZONES_ETATS, data.stateCode) : null,
        }
    })
    const headers = ['name', 'state', 'comment']
    const exportAction = getExport(stationTank, i18n.staffSafety, headers)
    const actions = [exportAction]

    return (
        <Table
            title={ i18n.states }
            className='blue'
            condensed
            data={ stationTank }
            type={{ headers }}
            sortable={ !!stationTank.length }
            actions={ actions }
            alterable={ !readMode }
            onAlter={
                (element) => setModal(`${i18n.editZones} : ${getSandreLabel(sandreCodes, SANDRE.RESERVOIRS_ZONES, element.code)}`,
                    <TankZonesModal
                        id={ tank.idStation }
                        tank={ stationTank.find(c => element.code === c.code) }
                        saveResult={ r => onChange({
                            link_zones: [
                                ...tank.link_zones.filter(ss => ss.code !== element.code),
                                r,
                            ],
                        })}
                    />
                    , setPopup)
            }
        />
    )
}

TankZonesPanel.propTypes = {
    tank: PropTypes.instanceOf(DtoTankStation),
    sandreCodes: arrayOf(DtoSandreCode),
    readMode: PropTypes.bool,
    onChange: PropTypes.func,
    setPopup: PropTypes.func,
}

const mapStateToProps = store => ({
    sandreCodes: store.ReferencialReducer.sandreCodes,
})

const mapDispatchToProps = {
    setPopup: SieauAction.setPopup,
}

export default connect(mapStateToProps, mapDispatchToProps)(TankZonesPanel)