export default class DtoProblem {
    constructor(obj) {
        this.id = obj.id
        this.name = obj.name || obj.label
        this.label = obj.label
        this.active = obj.active
        this.principalProblem = obj.principalProblem
        this.updateDate = obj.updateDate
        this.updateLogin = obj.updateLogin
        this.endDate = obj.endDate
    }
}