import ActionComponent from 'components/ActionComponent'
import { groupBy, minBy, range } from 'lodash'
import moment from 'moment'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import Axis from '../../../components/echart/Axis'
import EChart from '../../../components/echart/EChart'
import Bar from '../../../components/echart/series/Bar'
import { getYear } from '../../../utils/DateUtil'
import { getMapStateToProps, getPropType, getPropTypes } from '../../../utils/StoreUtils'

const storeProps = {
}

class EventVisitsBarChart extends ActionComponent {
    render() {
        const minDate = minBy(this.props.events, 'date')
        const minYear = minDate && (getYear(minDate.date) < getYear(moment()) - 10) ? getYear(minDate.date) : getYear(moment()) - 10
        const yearRange = range(minYear, getYear(moment()) + 1)

        const groupedEvents = groupBy(this.props.events.filter(e => e.eventType === 'M' || e.eventType === 'G'), e => getYear(e.date))

        const data = yearRange.map(year => {
            const value = groupedEvents[year] ? groupedEvents[year].length : 0
            return {
                name: year,
                value,
                itemStyle: {
                    normal: {
                        color: value >= this.props.previsionalVisitNumber ? 'green' : 'red',
                    },
                    emphasis: {
                        color: value >= this.props.previsionalVisitNumber ? 'green' : 'red',
                    },
                },
            }
        })

        const groupedEvents2 = groupBy(this.props.events.filter(e => !(e.eventType === 'M' || e.eventType === 'G')), e => getYear(e.date))

        const data2 = yearRange.map(year => {
            const value = groupedEvents2[year] ? groupedEvents2[year].length : 0
            return {
                name: year,
                value,
                itemStyle: {
                    normal: {
                        color: 'grey',
                    },
                    emphasis: {
                        color: 'grey',
                    },
                },
            }
        })

        const options = {
            series: [Bar({
                data,
                name: i18n.curativePreventiveEvents,
            }), Bar({
                data: data2,
                name: i18n.otherEvents,
            })],
            tooltip: {
                trigger: 'item',
            },
            yAxis: [Axis({
                type: 'value',
            })],
            xAxis: [Axis({
                type: 'category',
                data: yearRange,
                axisLabel: {
                    fontSize: 10,
                },
            })],
            height: 150,
            gridHeight: 140,
        }
        return (
            <div className='row no-margin'>
                <EChart options={ options } />
            </div>
        )
    }
}

EventVisitsBarChart.propTypes = getPropTypes(storeProps, {
    events: getPropType('stationEvents'),
    previsionalVisitNumber: PropTypes.number,
})

const mapStateToProps = () => getMapStateToProps(storeProps, {
})

export default connect(mapStateToProps)(EventVisitsBarChart)
