import { round } from '../../../utils/NumberUtil'
import { orderBy } from 'lodash'

class _MultiBand { // les enveloppes doivent êtres triées de la série la plus basse à la plus haute (tri fait dans ce composant de toute façon en fonction de la première mesure ...)
    // const obj = {
    //     bands: [
    //         {
    //             objet d'options pour la série, avec au moins name et data
    //         },
    //         {
    //             objet d'options pour la série, avec au moins name et data
    //         }
    //     ]
    // }
    constructor(obj) {
        this.obj = { ...obj, isMultiBand: true, isPiezo: true, initialBands: obj.bands }
    }

    updateObj = (changes) => {
        this.obj = { ...this.obj, ...changes }
    }

    getJson = () => {
        const currentValues = [] // sert à calculer les valeurs
        const ordered = this.obj.noSort ? this.obj.bands : orderBy(this.obj.bands, b => b.data[0].value[1], 'asc')
        return ordered.map((b, index) => {
            const gap = this.obj.noGap ? 0 : (index === 0 ? 1000 : 0)
            return {
                ...b,
                data: b.data.map((d, idx) => {
                    const previous = currentValues[idx] || 0
                    currentValues[idx] = d?.value[1] || d[1] || 0
                    if (d.value) {
                        return {
                            ...d,
                            value: [d.value[0], d.value[1] !== null ? round(d.value[1] - previous) + gap : null, d.value[2], d.value[1]],
                            realValue: d.value[1],
                            depthHackValue: d.noDepthHackValue ? undefined : (ordered[index-1]?.data ?? [])[idx]?.value?.[1] ?? d.value[1], // Hack pour le suivi piézo en mode profondeur
                            noTooltip: b.noTooltip, // suivi piézo : pour ne pas afficher les lignes qui sont en dessus et en dessous
                            isPeriod: b.isPeriod, // suivi piézo : pour dire au getTooltip si on est en mode Périodes (areas superposées) ou non
                        }
                    }
                    return [d[0], d[1] !== null ? round(d[1] - previous) + gap : null, d[2], d[3]]
                }),
                stack: this.obj.stack || 'multiBandStack',
                type: 'line',
                itemStyle: {
                    normal: { color: b.color },
                },
                areaStyle: index !== 0 ? {
                    normal: { color: b.color, opacity: this.obj.noOpacity ? undefined : 0.50 },
                } : undefined,
                lineStyle: {
                    normal: {
                        opacity: 0,
                    },
                },
                connectNulls: true,
                z: -1,
                color: undefined,
            }
        })
        // const maxEnveloppes = this.obj.bands.reverse().map(b => {
        //     return {
        //         ...b.common || {},
        //         ...b.max,
        //         data: b.max.data.map((d, idx) => {
        //             const previous = currentValues[idx] || 0
        //             currentValues[idx] = d?.value[1] || d[1] || 0
        //             if (d.value) {
        //                 return { ...d, value: [d.value[0], d.value[1] !== null ? round(d.value[1] - previous) : null, d.value[2], d.value[3]], realValue: d.value[1] }
        //             }
        //             return [d[0], d[1] !== null ? round(d[1] - previous) : null, d[2], d[3]]
        //         }),
        //         stack: 'multiBandStack',
        //         type: 'line',
        //         itemStyle: {
        //             normal: { color: b.max.color || b.common.color },
        //         },
        //         areaStyle: {
        //             normal: { color: b.max.color || b.common.color, opacity: 0.25 },
        //         },
        //         lineStyle: {
        //             normal: {
        //                 opacity: 0,
        //             },
        //         },
        //         connectNulls: true,
        //         z: -1,
        //     }
        // })
        // return [...minEnveloppes, ...maxEnveloppes]
    }
}
export default function MultiBand(args) {
    return new _MultiBand(args)
}
