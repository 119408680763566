/* eslint-disable camelcase */
import React, { useEffect, useMemo, useState } from 'react'
import { Button, Grid } from '@mui/material'
import { StyledFieldSet, StyledLegend, StyledSpan } from 'components/StyledElements'
import i18n from 'simple-react-i18n'
import SimpleTextArea from 'components/forms/SimpleTextArea'
import { PropTypes } from 'prop-types'
import DtoFilterParam from 'station/dto/DtoFilterParam'
import Input from 'components/forms/Input'
import Select from 'components/forms/Select'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import ReferencialAction from 'referencial/action/ReferencialAction'
import { orderBy, uniqBy } from 'lodash'
import NumberField from 'components/forms/NumberField'
import MultiAutocomplete from 'components/forms/MultiAutocomplete'
import Checkbox from 'components/forms/Checkbox'
import SimpleDatePicker from 'components/forms/SimpleDatePicker'
import UserAction from 'administration/components/user/actions/UserAction'
import { BOOLEAN, DATE, FLOAT, NUMBER, PARAM_OPTIONS, SANDRE, STRING, SUBREQUEST } from 'utils/UserUtil'
import useSandreList from 'utils/customHook/useSandreList'
import RadioButtons from 'components/forms/RadioButtons'

const DateDisplay = ({
    param = {},
    onChangeParam = () => { },
}) => {
    return (
        <Grid item xs={3}>
            <SimpleDatePicker
                label={i18n.defaultValueSing}
                value={parseInt(param?.value)}
                onChange={v => onChangeParam({ value: `${v}` })}
                disabled={!param.filterResult}
            />
        </Grid>
    )
}

DateDisplay.propTypes = {
    param: PropTypes.instanceOf(DtoFilterParam),
    onChangeParam: PropTypes.func,
}

const SubRequestDisplay = ({
    param = {},
    onChangeParam = () => { },
}) => {
    const dispatch = useDispatch()
    const [list, setList] = useState([])

    useEffect(() => {
        if (param.subRequest) {
            dispatch(UserAction.getFilterParamList(param.subRequest)).then(json => setList(json || []))
        }
    }, [])

    return (
        <>
            <Grid item xs={3.5}>
                <SimpleTextArea
                    title={i18n.request}
                    value={param.subRequest}
                    onChange={v => onChangeParam({ subRequest: v })}
                    onEnterKeyPress={() => {
                        dispatch(UserAction.getFilterParamList(param.subRequest)).then(json => setList(json || []))
                    }}
                    obligatory
                />
            </Grid>
            {param.subRequest && !!list?.length && (
                <Grid item xs={3}>
                    <MultiAutocomplete
                        label={i18n.defaultValueSing}
                        elements={list}
                        clearFunction
                        values={param.value?.split(',')}
                        onChange={values => onChangeParam({ value: values.length && values.join(',') || undefined })}
                        disabled={!param.filterResult}
                    />
                </Grid>
            )}
        </>
    )
}

SubRequestDisplay.propTypes = {
    param: PropTypes.instanceOf(DtoFilterParam),
    onChangeParam: PropTypes.func,
}

const StringDisplay = ({
    param = {},
    onChangeParam = () => { },
}) => {
    return (
        <Grid container item xs={3}>
            <Input
                title={i18n.defaultValueSing}
                value={param.value}
                onChange={value => onChangeParam({ value })}
                disabled={!param.filterResult}
                containerStyle={{ width: '100%' }}
            />
        </Grid>
    )
}

StringDisplay.propTypes = {
    param: PropTypes.instanceOf(DtoFilterParam),
    onChangeParam: PropTypes.func,
}

const validRadioElements = [
    { code: 'true', name: 'yes' },
    { code: 'not true', name: 'no' },
]

const BooleanDisplay = ({
    param = {},
    onChangeParam = () => { },
}) => {
    return (
        <Grid item xs={3}>
            <RadioButtons
                col={12}
                elements={validRadioElements.map(d => ({ ...d, name: i18n[d.name] }))}
                selected={param.value}
                onChange={value => onChangeParam({ value })}
                disabled={!param.filterResult}
            />
        </Grid>
    )
}

BooleanDisplay.propTypes = {
    param: PropTypes.instanceOf(DtoFilterParam),
    onChangeParam: PropTypes.func,
}

const SandreDisplay = ({
    param = {},
    onChangeParam = () => { },
}) => {
    const {
        sandreCodes,
    } = useSelector(store => ({
        sandreCodes: store.ReferencialReducer.sandreCodes,
    }), shallowEqual)

    const sandreList = useSandreList(param.sandreCode)

    const lexiconsList = useMemo(() => orderBy(uniqBy(sandreCodes, 'field'), 'field'), [sandreCodes])

    return (
        <>
            <Grid item xs={3}>
                <Select
                    label={i18n.lexicon}
                    value={param.sandreCode}
                    options={lexiconsList}
                    onChange={v => onChangeParam({ sandreCode: v })}
                    keyValue='field'
                    keyLabel='field'
                    obligatory
                />
            </Grid>
            {!!sandreList.length && param.sandreCode && (
                <Grid item xs={3}>
                    <MultiAutocomplete
                        label={i18n.defaultValueSing}
                        elements={sandreList}
                        clearFunction
                        values={param.value?.split(',').flatMap(d => parseInt(d))}
                        onChange={(values) => {
                            onChangeParam({ value: values.length && values.join(',') || undefined })
                        }}
                        disabled={!param.filterResult}
                    />
                </Grid>
            )}
        </>
    )
}

SandreDisplay.propTypes = {
    param: PropTypes.instanceOf(DtoFilterParam),
    onChangeParam: PropTypes.func,
}

const NumberDisplay = ({
    param = {},
    onChangeParam = () => { },
    floatValue = false,
}) => {
    return (
        <Grid item xs={3}>
            <NumberField
                title={i18n.defaultValueSing}
                value={floatValue ? parseFloat(param?.value) : parseInt(param?.value)}
                onChange={v => onChangeParam({ value: `${v}` })}
                floatValue={floatValue}
                disabled={!param.filterResult}
            />
        </Grid>
    )
}

NumberDisplay.propTypes = {
    param: PropTypes.instanceOf(DtoFilterParam),
    onChangeParam: PropTypes.func,
    floatValue: PropTypes.bool,
}

const FilterParam = ({
    param = {},
    onChangeParam = () => {},
}) => {
    return (
        <StyledFieldSet>
            <StyledLegend>{param.variableName}</StyledLegend>
            <Grid container spacing={2} alignItems='center'>
                <Grid item xs={2}>
                    <Input title={i18n.alias} value={param.parameterName} onChange={v => onChangeParam({ parameterName: v })} />
                </Grid>
                <Grid item xs={1.5}>
                    <Select
                        label={i18n.type}
                        value={param.filterType}
                        options={PARAM_OPTIONS.map(d => ({ ...d, name: i18n[d.name] }))}
                        onChange={v => onChangeParam({ filterType: v, value: undefined, subRequest: '' })}
                    />
                </Grid>
                <Grid item xs={2}>
                    <Checkbox
                        label={i18n.filterResult}
                        checked={param.filterResult}
                        onChange={v => onChangeParam({ filterResult: v, value: undefined })}
                    />
                </Grid>
                {param.filterType === NUMBER && <NumberDisplay param={param} onChangeParam={onChangeParam} />}
                {param.filterType === FLOAT && <NumberDisplay param={param} onChangeParam={onChangeParam} floatValue />}
                {param.filterType === SANDRE && <SandreDisplay param={param} onChangeParam={onChangeParam} />}
                {param.filterType === BOOLEAN && <BooleanDisplay param={param} onChangeParam={onChangeParam} />}
                {param.filterType === STRING && <StringDisplay param={param} onChangeParam={onChangeParam} />}
                {param.filterType === SUBREQUEST && <SubRequestDisplay param={param} onChangeParam={onChangeParam} />}
                {param.filterType === DATE && <DateDisplay param={param} onChangeParam={onChangeParam} />}
            </Grid>
        </StyledFieldSet>
    )
}

FilterParam.propTypes = {
    param: PropTypes.instanceOf(DtoFilterParam),
    onChangeParam: PropTypes.func,
}

const getRows = (innerHeight, value) => {
    if (value) {
        return innerHeight < 900 ? 5 : 9
    }
    return innerHeight < 900 ? 11 : 18
}

const RequestStep = ({
    filterData = {},
    setFilterData = () => { },
    executeFilter = () => { },
    children = undefined,
}) => {
    const { innerHeight } = window
    const dispatch = useDispatch()

    const {
        referencialSandreCodes,
    } = useSelector(store => ({
        referencialSandreCodes: store.ReferencialReducer.referencialSandreCodes,
    }), shallowEqual)

    useEffect(() => {
        if (!referencialSandreCodes.length) {
            dispatch(ReferencialAction.fetchReferencialSandreCodes())
        }
    }, [dispatch, referencialSandreCodes])

    const onChangeTextArea = (request) => {
        const matches = [...request.matchAll('\\$(\\w*)')]
        const params = matches.map((m, index) => {
            const found = filterData?.link_parameters?.find(param => m[1].includes(param.variableName) || param.variableName.includes(m[1]))
            return new DtoFilterParam({
                ...found,
                order: index,
                variableName: m[1],
            })
        })
        setFilterData({ ...filterData, filterSelection: request, link_parameters: params })
    }

    const onChangeParam = (param, value) => {
        const newParams = filterData.link_parameters.map((p) => {
            return p.order === param.order ? new DtoFilterParam({ ...param, ...value }) : p
        })
        setFilterData(fd => ({ ...fd, link_parameters: newParams }))
    }

    return (
        <Grid sx={{ paddingBottom: '150px' }}>
            <StyledFieldSet>
                <StyledLegend>
                    <StyledSpan>{i18n.filter}</StyledSpan>
                </StyledLegend>
                <SimpleTextArea
                    col={12}
                    rows={getRows(innerHeight, filterData.link_parameters?.length)}
                    title={i18n.request}
                    value={filterData.filterSelection}
                    onChange={onChangeTextArea}
                />
                <Grid container justifyContent='flex-end' className='padding-1'>
                    <Button
                        variant='contained'
                        onClick={executeFilter}
                    >
                        {i18n.test}
                    </Button>
                </Grid>
                {children}
            </StyledFieldSet>
            {!!filterData?.link_parameters?.length && (
                <StyledFieldSet>
                    <StyledLegend>
                        <StyledSpan>{i18n.parameters}</StyledSpan>
                    </StyledLegend>
                    {filterData?.link_parameters?.map(param => <FilterParam key={param.variableName} param={param} onChangeParam={value => onChangeParam(param, value)} />)}
                </StyledFieldSet>
            )}
        </Grid>
    )
}

RequestStep.propTypes = {
    filterData: PropTypes.shape({}), // DtoFilter
    resultsDisplay: PropTypes.func,
    executeFilter: PropTypes.func,
    setFilterData: PropTypes.func,
    children: PropTypes.element,
}

export default RequestStep