export default class DtoLiftingCivilEng {
    constructor(obj) {
        this.idStation = obj.idStation
        this.tankNature = obj.tankNature
        this.antiAcidCoating = obj.antiAcidCoating
        this.altiLevel = obj.altiLevel
        this.waterEnterLevel = obj.waterEnterLevel
        this.bottomTankLevel = obj.bottomTankLevel
        this.depthTank = obj.depthTank
        this.overfull = obj.overfull
        this.overfullLevel = obj.overfullLevel
        this.bufferTank = obj.bufferTank
        this.bufferTankVolume = obj.bufferTankVolume
    }
}