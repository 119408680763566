import ReactEcharts from 'echarts-for-react/lib/core'
import 'echarts/lib/chart/bar'
import echarts from 'echarts/lib/echarts'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import { mainBlue } from 'utils/constants/ColorTheme'
import { exportPictureIcon } from '../../../components/echart/EChartUtils'
import { getNumberFormat } from '../../../utils/NumberUtil'

class ManagementUnitVolumesHistogram extends Component {
    constructor(props) {
        super(props)
    }

    getColor = (volumeAUP, volume) => {
        if (!volumeAUP) {
            return 'lightgrey'
        }
        if (volume > volumeAUP) {
            return 'red'
        }
        if (((volume / volumeAUP) * 100) >= 80) {
            return 'orange'
        }
        return 'green'
    }

    getOptions = () => {
        const { volumes } = this.props
        const volumeAUP = parseInt(volumes[0].slice(0, -2).replaceAll(' ', ''))
        const dataSeries = volumes.map((v, i) => {
            if (v) {
                const value = parseInt(v.slice(0, -2).replaceAll(' ', ''))
                if (i === 0) {
                    return {
                        value,
                        valueFormatted: value,
                        itemStyle: {
                            color: mainBlue,
                        },
                    }
                }
                return {
                    value,
                    itemStyle: {
                        color: this.getColor(volumeAUP, value),
                    },
                }
            }
            return null
        })
        return {
            xAxis: {
                type: 'value',
                max: 'dataMax',
                axisLabel: {
                    formatter: getNumberFormat,
                },
            },
            yAxis: {
                type: 'category',
                data: [i18n.AUP, i18n.requestedVolumes, i18n.allocatedVolumes, i18n.authorizedVolumes, i18n.consumedVolumes],
                inverse: true,
                animationDuration: 300,
                animationDurationUpdate: 300,
            },
            series: [{
                realtimeSort: true,
                type: 'bar',
                data: dataSeries,
                label: {
                    show: true,
                    valueAnimation: true,
                    position: 'right',
                    formatter({ valueFormatted }) {
                        return valueFormatted
                    },
                },
            }],
            grid: {
                top: 25,
                bottom: 25,
                left: 150,
                right: 100,
            },
            legend: { show: false },
            animationDuration: 0,
            animationDurationUpdate: 300,
            animationEasing: 'linear',
            animationEasingUpdate: 'linear',
            toolbox: {
                show: true,
                feature: {
                    saveAsImage: {
                        title: i18n.export,
                        icon: exportPictureIcon,
                        name: i18n.volumes,
                    },
                },
            },
        }
    }

    render() {
        const { style, height } = this.props
        return (
            <div className='row no-margin' style={style}>
                <div className='col s12 no-padding'>
                    <ReactEcharts
                        echarts={ echarts }
                        option={ this.getOptions() }
                        notMerge={ true }
                        lazyUpdate={ true }
                        className={ 'row no-margin' }
                        style={{ height: height || 230 }}
                    />
                </div>
            </div>
        )
    }
}

ManagementUnitVolumesHistogram.propTypes = {
    type: PropTypes.string,
    height: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    style: PropTypes.shape({}),
    volumes: PropTypes.arrayOf(PropTypes.string),
}

export default connect()(ManagementUnitVolumesHistogram)
