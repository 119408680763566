export default class DtoCampaignVisit {
    constructor(obj) {
        this.idInstallation = obj.idInstallation // Long,
        this.installationType = obj.installationType // Option[Long],
        this.code = obj.code // Option[Long],
        this.townCode = obj.townCode // Option[String],
        this.name = obj.name // Option[String],
        this.ownerCode = obj.ownerCode // Option[Long],
        this.technicianLogin = obj.technicianLogin // Option[String],
        this.visitDate = obj.visitDate // Option[DateTime],
        this.nbActions = obj.nbActions // Option[Int],
        this.visitStatus = obj.visitStatus // Option[Long]
    }
}