/* eslint-disable camelcase */
import DtoAnswer from './DtoAnswer'
import DtoDanger from './DtoDanger'
import DtoVisitAction from './DtoVisitAction'
import DtoVisitModification from './DtoVisitModification'

export default class DtoVisit {
    constructor(obj) {
        this.idInstallation = obj.idInstallation // Long,
        this.idCampaign = obj.idCampaign // Long,
        this.idVisit = obj.idVisit // Long,
        this.visitDate = obj.visitDate // Option[DateTime],
        this.campaignType = obj.campaignType // Option[Long],
        this.technicianLogin = obj.technicianLogin // Option[String],
        this.otherTechnicians = obj.otherTechnicians // Option[String],
        this.participants = obj.participants // Option[String],
        this.comments = obj.comments // Option[String],
        this.statusVisit = obj.statusVisit // Option[Long],
        this.updateDate = obj.updateDate // Option[DateTime],
        this.updateLogin = obj.updateLogin // Option[String],
        this.startDate = obj.startDate // Option[DateTime],
        this.endDate = obj.endDate // Option[DateTime],
        this.eventId = obj.eventId // Option[Long],

        this.idSurvey = obj.idSurvey

        this.link_actions = obj.link_actions ? obj.link_actions.map(a => new DtoVisitAction(a)) : [] // Option[Seq[VisitActionWithLinks]]
        this.link_modifications = obj.link_modifications ? obj.link_modifications.map(a => new DtoVisitModification(a)) : [] // Option[Seq[VisitModification]]
        this.link_answers = obj.link_answers ? obj.link_answers.map(a => new DtoAnswer(a)) : [] // Option[Seq[VisitModification]]
        this.link_dangers = obj.link_dangers ? obj.link_dangers.map(a => new DtoDanger(a)) : [] // Option[Seq[VisitModification]]
    }
}