import PropTypes from 'prop-types'
import AppStore from 'store/AppStore'
import { arrayOf, deleteKeys } from '../../utils/StoreUtils'
import HomeAction from '../actions/HomeAction'
import { RECEIVE_ARRESTS } from '../constants/ArrestConstants'
import {
    ACTIONS,
    ADD_NOTIFICATION,
    BACK_PATH,
    DISCONNECTED,
    LOGIN_FAIL,
    NAVBAR_LINKS,
    READ_NOTIFICATION,
    RECEIVE_LAST_DOCUMENTS,
    RECEIVE_NOTIFICATIONS,
    RECEIVE_THEME_LAYERS,
    SET_FORMATION_PATH,
    SET_HELP_LINK,
    SHOW_WELCOME_POPUP,
    TITLE,
    UPDATE_GLOBAL_RESEARCH,
} from '../constants/HomeConstants'
import { RECEIVE_DEPARTMENTS_AQUADB } from '../constants/RefrencialConstants'
import { RECEIVE_VIGILANCES } from '../constants/VigilanceConstants'
import DocumentDto from '../dto/DocumentDto'
import DtoArrest from '../dto/DtoArrest'
import DtoDepartments from '../dto/DtoDepartments'
import DtoVigilance from '../dto/DtoVigilance'
import LayerDto from '../dto/LayerDto'


export function HomeReducer(state = {}, action) {
    switch (action.type) {
        case LOGIN_FAIL:
            return {
                ...state,
                message: action.message,
            }
        case DISCONNECTED:
            return {
                ...state,
                message: action.message,
            }
        case TITLE:
            return {
                ...state,
                title: action.title,
            }
        case BACK_PATH:
            return {
                ...state,
                backPath: action.backPath,
            }
        case NAVBAR_LINKS:
            return {
                ...state,
                links: action.links,
            }
        case RECEIVE_ARRESTS:
            return {
                ...state,
                arrests: action.arrests.map(el => new DtoArrest(el)),
            }
        case RECEIVE_VIGILANCES:
            return {
                ...state,
                vigilances: action.vigilances.map(el => new DtoVigilance(el)),
            }
        case RECEIVE_DEPARTMENTS_AQUADB:
            return {
                ...state,
                departments: action.departments.map(el => new DtoDepartments(el)),
            }
        case ACTIONS:
            return {
                ...state,
                actions: action.actions,
            }
        case RECEIVE_NOTIFICATIONS:
            return {
                ...state,
                notifications: action.notifications,
            }
        case READ_NOTIFICATION:
            return {
                ...state,
                notifications: deleteKeys(state.notifications, [action.notification]),
            }
        case ADD_NOTIFICATION:
            return {
                ...state,
                notifications: {
                    ...state.notifications,
                    ...action.notification,
                },
            }
        case RECEIVE_THEME_LAYERS:
            return {
                ...state,
                themeLayers: action.layers.map(l => new LayerDto(l)),
            }
        case RECEIVE_LAST_DOCUMENTS:
            return {
                ...state,
                lastDocuments: action.documents.map(d => new DocumentDto(d)),
            }
        case SHOW_WELCOME_POPUP:
            return {
                ...state,
                showWelcomePopup: action.value,
            }
        case UPDATE_GLOBAL_RESEARCH:
            return { ...state, globalResearch: action.value }
        case SET_HELP_LINK:
            return {
                ...state,
                helpLink: {
                    ...state.helpLink,
                    module: action.module,
                    number: action.number,
                },
            }
        case SET_FORMATION_PATH:
            return {
                ...state,
                helpLink: {
                    ...state.helpLink,
                    formationPath: action.formationPath,
                },
            }
        default:
            return state
    }
}

export const store = {
    message: '',
    token: '',
    title: [],
    backPath: '',
    links: [],
    helpLink: {},
    arrests: [],
    vigilances: [],
    departments: [],
    actions: [],
    notifications: {},
    themeLayers: [],
    lastDocuments: [],
    showWelcomePopup: false,
    globalResearch: '',
}

export const HomeStoreInfos = () => {
    const appStore = AppStore.getState()
    return {
        title: {
            fetch: HomeAction.setTitle,
            store: appStore.HomeReducer.title,
            propType: arrayOf(PropTypes.shape({
                title: PropTypes.string,
                href: PropTypes.string,
            })),
        },
        notifications: {
            fetch: HomeAction.fetchNotifications,
            reset: HomeAction.resetNotifications,
            store: appStore.HomeReducer.notifications,
            propType: PropTypes.objectOf(PropTypes.number),
        },
        departments: {
            fetch: HomeAction.fetchDepartments,
            store: appStore.HomeReducer.departments,
            propType: arrayOf(PropTypes.instanceOf(DtoDepartments)),
        },
        arrests: {
            fetch: HomeAction.fetchArrests,
            reset: HomeAction.resetArrests,
            store: appStore.HomeReducer.arrests,
            propType: arrayOf(PropTypes.instanceOf(DtoArrest)),
        },
        vigilances: {
            fetch: HomeAction.fetchVigilances,
            reset: HomeAction.resetVigilances,
            store: appStore.HomeReducer.vigilances,
            propType: arrayOf(PropTypes.instanceOf(DtoVigilance)),
        },
        themeLayers: {
            fetch: HomeAction.fetchThemeLayers,
            store: appStore.HomeReducer.themeLayers,
            propType: arrayOf(PropTypes.arrayOf(LayerDto)),
            update: HomeAction.updateThemeLayer,
        },
        lastDocuments: {
            fetch: HomeAction.fetchLastDocuments,
            store: appStore.HomeReducer.lastDocuments,
            propType: arrayOf(PropTypes.arrayOf(DocumentDto)),
        },
        helpLink: {
            fetch: HomeAction.setHelpLink,
            store: appStore.HomeReducer.helpLink,
            propType: PropTypes.shape({
                module: PropTypes.string,
                number: PropTypes.string,
            }),
        },
    }
}
