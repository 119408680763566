import ReactEchartsCore from 'echarts-for-react/lib/core'
import 'echarts/lib/chart/bar'
import echarts from 'echarts/lib/echarts'
import { filter, groupBy, orderBy } from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import DtoMaterielSituation from '../../dto/DtoMaterielSituation'
import DtoMaterielState from '../../dto/DtoMaterielState'

class AssigmentChartPanel extends Component {
    constructor(props) {
        super(props)
    }

    getOptions = () => {
        const reduceState = groupBy(this.props.materielSituation, 'statusCode')
        const listOfDatas = filter(orderBy(this.props.materielStates.map(state => {
            const datas = reduceState[state.code]
            if (datas && datas.length) {
                const result = {
                    name: state.label,
                    nbData: 0,
                }
                if (datas) {
                    return { ...result,
                        nbData: datas.length,
                    }
                }
                return result
            }
            return null
        }), 'data', 'desc'), o => o)
        const yAxisDatas = listOfDatas.map((o) => {
            return o.name
        })
        const dataSeries = listOfDatas.map((o) => {
            return o.nbData
        })
        return {
            grid: {
                left: '3%',
                right: '4%',
                bottom: '3%',
                containLabel: true,
            },
            xAxis: {
                type: 'value',
                interval: 1,
            },
            yAxis: {
                type: 'category',
                data: yAxisDatas,
            },
            series: [{
                type: 'bar',
                data: dataSeries,
            }],
        }
    }

    render() {
        return (
            <div className='row no-margin'>
                <div className='col s12'>
                    <ReactEchartsCore
                        echarts={ echarts }
                        option={ this.getOptions() }
                        notMerge={ true }
                        lazyUpdate={ true }
                        className={ 'row no-margin' }
                        style={ { height: 250 } }
                    />
                </div>
            </div>
        )
    }
}

AssigmentChartPanel.propTypes = {
    materielSituation: PropTypes.arrayOf(PropTypes.instanceOf(DtoMaterielSituation)),
    materielStates: PropTypes.arrayOf(PropTypes.instanceOf(DtoMaterielState)),
}

const mapStateToProps = store => ({
    materielStates: store.MaterielReducer.materielStates,
})

export default connect(mapStateToProps)(AssigmentChartPanel)
