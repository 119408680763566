import User from 'account/dto/User'
import ConfirmModal from 'components/modal/ConfirmModal'
import { omit, round } from 'lodash'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { useMemo, useState } from 'react'
import { connect, shallowEqual, useSelector } from 'react-redux'
import MultiContributorsAutocomplete from 'referencial/components/contributor/components/MultiContributorsAutocomplete'
import i18n from 'simple-react-i18n'
import { authorizeContributors, authorizeFilter } from 'utils/HabilitationUtil'
import { removeNullKeys } from 'utils/StoreUtils'
import Checkbox from '../../../../components/forms/Checkbox'
import Input from '../../../../components/forms/Input'
import NumberField from '../../../../components/forms/NumberField'
import Select from '../../../../components/forms/Select'
import Textarea from '../../../../components/forms/Textarea'
import ContributorDto from '../../../../referencial/components/contributor/dto/ContributorDto'
import NetworkDto from '../../../../referencial/components/network/dto/NetworkDto'
import { getMatTypes } from '../../../../utils/MaterielUtils'
import PowerSupplyTypeDto from '../../powerSupply/dto/PowerSupplyTypeDto'
import DtoSensorType from '../dto/DtoSensorType'
import { isFieldRequired } from 'materiel/utils/MaterielUtils'
import { DATEACHAT_OBLIGATOIRE, NUMSERIE_OBLIGATOIRE } from 'materiel/constants/MaterielSettingRuleConstant'
import SimpleDatePicker from 'components/forms/SimpleDatePicker'
import { SENSOR_ID } from 'materiel/constants/MaterielConstants'

const SensorPanel = ({
    active = false,
    disabled = true,
    sensor = {},
    sensorTypes = [],
    powerSupplyTypes = [],
    contributors = [],
    accountUser = {},
    networks = [],
    onChange = () => { },
}) => {
    const {
        materielSettingRules,
    } = useSelector(store => ({
        materielSettingRules: store.MaterielReducer.materielSettingRules,
    }), shallowEqual)

    const [isOpen, setOpen] = useState(false)
    const [selectedType, setSelectedType] = useState()

    const rules = useMemo(() => {
        return materielSettingRules.filter(({ idMaterielType }) => idMaterielType === SENSOR_ID)
    }, [materielSettingRules])

    return (
        <div className='col s12 margin-bottom-1'>
            <div className='row no-margin' style={{ paddingTop: '3px' }}>
                <Select
                    options={getMatTypes(sensorTypes, sensor.sensorType)}
                    label={i18n.type}
                    col={4}
                    onChange={v => {
                        const type = sensorTypes.find(({ id }) => v === id)
                        if (type) {
                            setOpen(true)
                            setSelectedType(type)
                        } else {
                            onChange({ sensorType: v })
                        }
                    }}
                    value={sensor.sensorType}
                    nullLabel='&nbsp;'
                    active={active}
                    disabled={disabled}
                    obligatory
                />
                <Input
                    col={4}
                    title={i18n.serialNumber}
                    value={sensor.serialNumber}
                    onChange={value => onChange({ serialNumber: value })}
                    active={active}
                    disabled={sensor.isEquipment || disabled}
                    obligatory={isFieldRequired(rules, NUMSERIE_OBLIGATOIRE)}
                />
                <Input
                    col={4}
                    title={i18n.immoNumber}
                    value={sensor.reference}
                    onChange={value => onChange({ reference: value })}
                    active={active}
                    disabled={sensor.isEquipment || disabled}
                />
            </div>
            <div className='row no-margin valign-wrapper' style={{ paddingTop: '3px' }}>
                <MultiContributorsAutocomplete
                    options={authorizeContributors(contributors, accountUser, sensor.manufacturerId)}
                    label={i18n.manufacturer}
                    col={4}
                    onChange={(id) => onChange({ manufacturerId: id })}
                    values={sensor.manufacturerId}
                    disabled={sensor.isEquipment || disabled}
                    keyLabel='labelDisplay'
                />
                <MultiContributorsAutocomplete
                    options={authorizeContributors(contributors, accountUser, sensor.providerId)}
                    label={i18n.provider}
                    col={4}
                    onChange={(id) => onChange({ providerId: id })}
                    values={sensor.providerId}
                    disabled={sensor.isEquipment || disabled}
                    keyLabel='labelDisplay'
                />
                <Checkbox
                    col={4}
                    label={i18n.desiccant}
                    checked={sensor.desiccant === '1'}
                    onChange={e => onChange({ desiccant: e ? '1' : '0' })}
                    active={active}
                    disabled={disabled}
                />
            </div>
            <div className='row no-margin' style={{ paddingTop: '3px' }}>
                <Select
                    options={authorizeFilter(networks, accountUser, sensor.networkCode)}
                    label={i18n.network}
                    col={12}
                    onChange={value => onChange({ networkCode: value })}
                    value={sensor.networkCode}
                    nullLabel='&nbsp;'
                    active={active}
                    disabled={sensor.isEquipment || disabled}
                />
            </div>
            <div className='row no-margin' style={{ paddingTop: '3px' }}>
                <Input
                    col={4}
                    title={i18n.scaleInBar}
                    value={sensor.range}
                    onChange={value => onChange({ range: value })}
                    active={active}
                    disabled={disabled}
                />
                <NumberField
                    col={4}
                    title={i18n.lengthInMetter}
                    value={sensor.length}
                    onChange={value => onChange({ length: round(value, 2) })}
                    active={active}
                    disabled={disabled}
                    floatValue
                />
            </div>
            <div className='row no-margin' style={{ paddingTop: '3px' }}>
                <Select
                    options={getMatTypes(powerSupplyTypes, sensor.powerSupplyType)}
                    label={i18n.powerSupplyType}
                    col={4}
                    onChange={value => onChange({ powerSupplyType: value })}
                    value={sensor.powerSupplyType}
                    nullLabel='&nbsp;'
                    active={active}
                    disabled={disabled}
                />
                <NumberField
                    col={4}
                    title={i18n.batteryCount}
                    value={sensor.pileNumber}
                    onChange={value => onChange({ pileNumber: value })}
                    active={active}
                    disabled={disabled}
                />
                <NumberField
                    col={4}
                    title={i18n.supplyTime}
                    value={sensor.supplyTime}
                    onChange={value => onChange({ supplyTime: value })}
                    active={active}
                    disabled={disabled}
                />
            </div>
            <div className='row no-margin' style={{ paddingTop: '3px' }}>
                <SimpleDatePicker
                    id='manufactureDate'
                    label={i18n.manufactureDate}
                    value={sensor.manufactureDate}
                    onChange={v => onChange({ manufactureDate: v })}
                    col={3}
                    disabled={sensor.isEquipment || disabled}
                    max={sensor.purchaseDate}
                />
                <SimpleDatePicker
                    id='purchaseDate'
                    label={i18n.purchaseDate}
                    value={sensor.purchaseDate}
                    onChange={v => onChange({ purchaseDate: v })}
                    col={3}
                    disabled={sensor.isEquipment || disabled}
                    min={sensor.manufactureDate}
                    obligatory={isFieldRequired(rules, DATEACHAT_OBLIGATOIRE)}
                />
                <SimpleDatePicker
                    label={i18n.warrantyExpirationDate}
                    value={sensor.warrantyTime && moment(sensor.purchaseDate).add(sensor.warrantyTime, 'months').valueOf()}
                    col={3}
                    disabled
                />
                <SimpleDatePicker
                    id='withdrawalDate'
                    label={i18n.replaceDate}
                    value={sensor.withdrawalDate}
                    onChange={v => onChange({ withdrawalDate: v })}
                    col={3}
                    disabled={sensor.isEquipment || disabled}
                    min={sensor.purchaseDate}
                />
            </div>
            <div className='row no-margin' style={{ paddingTop: '8px' }}>
                <Textarea
                    col={12}
                    title={i18n.comment}
                    value={sensor.comment}
                    onChange={value => onChange({ comment: value })}
                    active={active}
                    disabled={disabled}
                />
            </div>
            <ConfirmModal
                isOpen={isOpen}
                title={i18n.materielDefaultValuesMessage}
                onValidate={() => {
                    setOpen(false)
                    onChange(removeNullKeys({
                        sensorType: selectedType.id,
                        ...omit(selectedType, ['id', 'code', 'label', 'name', 'comment', 'updateDate', 'updateLogin', 'startDate', 'endDate', 'icon']),
                    }))
                }}
                onReject={() => {
                    setOpen(false)
                    onChange({ sensorType: selectedType.id })
                }}
                onClose={() => setOpen(false)}
            />
        </div>
    )
}

SensorPanel.propTypes = {
    disabled: PropTypes.bool,
    active: PropTypes.bool,
    onChange: PropTypes.func,
    sensor: PropTypes.shape({}),
    sensorTypes: PropTypes.instanceOf(DtoSensorType),
    powerSupplyTypes: PropTypes.arrayOf(PropTypes.instanceOf(PowerSupplyTypeDto)),
    contributors: PropTypes.arrayOf(PropTypes.instanceOf(ContributorDto)),
    networks: PropTypes.arrayOf(PropTypes.instanceOf(NetworkDto)),
    accountUser: PropTypes.instanceOf(User),
}

const mapStateToProps = store => ({
    contributors: store.ContributorReducer.contributors,
    sensorTypes: store.SensorReducer.sensorTypes,
    powerSupplyTypes: store.PowerSupplyReducer.powerSupplyTypes,
    networks: store.NetworkReducer.networks,
    accountUser: store.AccountReducer.accountUser,
})

export default connect(mapStateToProps)(SensorPanel)
