import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import ToastrAction from 'toastr/actions/ToastrAction'
import NumberField from '../../../../components/forms/NumberField'
import Job from '../../../dto/DtoJob'

class PurgeMateriel extends Component {
    constructor(props) {
        super(props)
        this.state = {
            filter: props.job.parameters.filters.length ? JSON.parse(props.job.parameters.filters[0]) : {},
        }
    }

    onChangeFilters = (changes) => {
        const parameters = {
            ...this.props.job.parameters,
            filters: [JSON.stringify({
                ...this.state.filter,
                ...changes,
            })],
        }
        this.setState({ filter: { ...this.state.filter, ...changes } })
        this.props.onChangeJob({ parameters })
    }

    render() {
        return (
            <div className='row no-margin'>
                <NumberField
                    col={4}
                    title={i18n.executionId}
                    value={this.state.filter.jobExecutionId}
                    onChange={value => this.onChangeFilters({ jobExecutionId: value })}
                    active={this.props.isEditMode}
                    disabled={!this.props.isEditMode}
                    obligatory={true}
                />
            </div>
        )
    }
}

PurgeMateriel.propTypes = {
    job: PropTypes.instanceOf(Job).isRequired,
    handleChangeParameters: PropTypes.func.isRequired,
    onChangeJob: PropTypes.func.isRequired,
    isEditMode: PropTypes.bool,

    toastrError: PropTypes.func,
}

const mapDispatchToProps = {
    toastrError: ToastrAction.error,
}

export default connect(null, mapDispatchToProps)(PurgeMateriel)
