import DtoAccountHabilitation from 'account/dto/DtoAccountHabilitation'
import { AGRI } from 'administration/components/user/constants/HabilitationConstants'
import { push } from '@lagunovsky/redux-react-router'
import DtoInstallationTableLine from 'installation/dto/installation/DtoInstallationTableLine'
import { isEqual, omit } from 'lodash'
import moment from 'moment'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import StationSamplersPanel from 'station/components/link/StationSamplersPanel'
import { greyBlue } from 'utils/constants/ColorTheme'
import { arrayOf } from 'utils/StoreUtils'
import ActionComponent from '../../../components/ActionComponent'
import Card from '../../../components/card/Card'
import Checkbox from '../../../components/forms/Checkbox'
import Input from '../../../components/forms/Input'
import NumberField from '../../../components/forms/NumberField'
import Select from '../../../components/forms/Select'
import Textarea from '../../../components/forms/Textarea'
import Row from '../../../components/react/Row'
import SieauAction from '../../../components/sieau/SieauAction'
import QualityAction from '../../../quality/actions/QualityAction'
import AquiferAction from '../../../referencial/components/aquifers/actions/AquiferAction'
import ContactAction from '../../../referencial/components/contact/actions/ContactAction'
import ContactCard from '../../../referencial/components/contact/components/ContactCard'
import WatershedAction from '../../../referencial/components/watershed/actions/WatershedAction'
import StationAction from '../../../station/actions/StationAction'
import LinkedStationsPanel from '../../../station/components/associatedStation/LinkedStationsPanel'
import StationMapDashboardPanel from '../../../station/components/dashboard/component/map/StationMapDashboardPanel'
import LastEventPanel from '../../../station/components/event/LastEventPanel'
import StationArrangementPanel from '../../../station/components/link/StationArrangementPanel'
import StationPiezoContributorPanel from '../../../station/components/link/StationPiezoContributorPanel'
import StationUsagePanel from '../../../station/components/link/StationUsagePanel'
import StationLocationInfoPanel from '../../../station/components/location/StationLocationInfoPanel'
import StationUpdatePanel from '../../../station/components/update/StationUpdatePanel'
import AppStore from '../../../store/AppStore'
import { getStationArrowNav } from '../../../utils/ActionUtils'
import { getDate } from '../../../utils/DateUtil'
import { downloadURI } from '../../../utils/ExportDataUtil'
import { onChangeDate } from '../../../utils/FormUtils'
import { getLogin, getSetting, getUser } from '../../../utils/SettingUtils'
import { getLinks } from '../../../utils/StationUtils'
import { getVisitCheckBoxChange, getVisitDatePickerChange, getVisitSelectChange } from '../../../utils/VisitUtils'
import InstallationAction from '../../actions/InstallationAction'
import { INSTALLATION_TYPE } from '../../constants/InstallationConstants'
import DtoInstallationBorehole from '../../dto/borehole/DtoInstallationBorehole'
import DtoInstallation from '../../dto/installation/DtoInstallation'
import BuildingsPanel from '../installations/components/BuildingsPanel'
import InstallationAccessCasingPanel from '../installations/components/InstallationAccessCasingPanel'
import InstallationAccessSitePanel from '../installations/components/InstallationAccessSitePanel'
import InstallationComElecPanel from '../installations/components/InstallationComElecPanel'
import InstallationInfosPanel from '../installations/components/InstallationInfosPanel'
import InstallationStaffSafetyPanel from '../installations/components/InstallationStaffSafetyPanel'
import LocalisationMapModal from '../installations/components/modal/LocalisationMapModal'
import BoreholeAquiferTable from './aquifer/components/BoreholeAquiferTable'
import DtoBoreholeAquifer from './aquifer/dto/DtoBoreholeAquifer'
import BoreholeCommentTable from './boreholeComment/components/BoreholeCommentTable'
import BoreholeCasingTable from './casing/components/BoreholeCasingTable'
import BoreholeCivilGenPanel from './civilGen/BoreholeCivilGenPanel'
import BoreholeDrillingTable from './drilling/components/BoreholeDrillingTable'
import BoreholeEquipmentsPanel from './equipments/BoreholeEquipmentsPanel'
import BoreholeLithologyTable from './lithology/components/BoreholeLithologyTable'
import BoreholeManagementPanel from './management/BoreholeManagementPanel'

class BoreholeDescriptionPanel extends ActionComponent {
    constructor(props) {
        super(props)
        this.state = {
            installation: new DtoInstallation({}),
            borehole: new DtoInstallationBorehole({}),
            readMode: true,
        }
    }

    componentDidMount() {
        if (this.props.installation.id) {
            this.setState({ installation: this.props.installation })
            if (this.props.installation.installationType === INSTALLATION_TYPE.BOREHOLE) {
                AppStore.dispatch(InstallationAction.fetchInstallationBorehole(this.props.installation.id))
            }
        }

        if (this.props.installationBorehole.id) {
            this.setState({ borehole: this.props.installationBorehole })
        }

        if (!this.props.installationBorehole.id && this.props.installation.installationType === INSTALLATION_TYPE.BOREHOLE) {
            this.setState({ borehole: { ...this.state.borehole, id: this.props.installation.id } })
        }

        if (!this.props.status.length) {
            this.props.fetchStatus()
        }

        if (!this.props.installationEvents.length) {
            this.props.fetchInstallationEvents(this.props.installation.id)
        }

        if (!this.props.contacts.length) {
            this.props.fetchContacts()
        }

        if (!this.props.watersheds.length) {
            this.props.fetchWatersheds()
        }

        if (!this.props.natures.length) {
            this.props.fetchNatures()
        }

        if (!this.props.aquifers.length) {
            this.props.fetchAquifers()
        }

        if (!this.props.fieldModes.length) {
            this.props.fetchFieldModes()
        }

        this.props.fetchInstallationVisits(this.props.installation.id).then(() => {
            if (this.props.onChangeVisit) {
                this.setState({ readMode: false })
            } else if (this.state.readMode) {
                this.setReadOnlyMode()
            }
        })

        if (this.props.onChangeVisit) {
            this.setState({ readMode: false })
        } else {
            this.setReadOnlyMode()
        }
    }

    componentDidUpdate(prevProps) {
        if (!isEqual(prevProps.installation, this.props.installation)) {
            this.setState({ installation: this.props.installation })
            if (!this.props.onChangeVisit) {
                this.setReadOnlyMode()
            }
            if (this.props.installation.installationType === INSTALLATION_TYPE.BOREHOLE) {
                AppStore.dispatch(InstallationAction.fetchInstallationBorehole(this.props.installation.id))
            }
        }
        if (prevProps.installationBorehole.id !== this.props.installationBorehole.id) {
            this.setState({ borehole: this.props.installationBorehole })
        }
    }

    onDelete = () => {
        this.props.deleteInstallation(this.props.installation.id, () => this.props.push('/installation'))
    }

    getObjectIfHasChanged = (propsObject, stateObject) => {
        if (!isEqual(propsObject, stateObject)) {
            return stateObject
        }
        return null
    }

    onSave = (visit) => {
        const { installation, borehole } = this.state
        if (this.props.onChangeVisit) {
            return this.props.updateInstallationAndVisit(visit.idVisit,
                {
                    visit,
                    installation,
                    borehole,
                }
            ).then(() => this.setReadOnlyMode())
        }

        return this.props.updateInstallationBorehole(
            installation,
            borehole
        ).then(() => this.setReadOnlyMode())
    }

    setEditMode = () => {
        this.setState({ readMode: false })
        const actions = {
            save: () => this.onSave(),
            cancel: () => {
                this.setState({ installation: this.props.installation, borehole: this.props.installationBorehole })
                this.setReadOnlyMode()
            },
            delete: () => this.onDelete(),
            links: getLinks(this.props.installation, this.props),
            arrowNav: getStationArrowNav('installation', this.props.installationTable, this.props.installation.id, s => this.props.push(`/station/installation/${s.id}/description`)),
        }
        if (getUser().consultant === '1') {
            this.setActions(omit(actions, ['save', 'delete']))
        } else {
            this.setActions(actions)
        }
    }

    setReadOnlyMode = () => {
        if (this.props.onChangeVisit) {
            return
        }
        const { installationVisits } = this.props
        const visitExport = installationVisits.length ? { export: () => this.createAndDownloadEdition(installationVisits[0]) } : null
        this.setState({ readMode: true })
        const actions = {
            ...visitExport,
            links: getLinks(this.props.installation, this.props),
            arrowNav: getStationArrowNav('installation', this.props.installationTable, this.props.installation.id, s => this.props.push(`/station/installation/${s.id}/description`)),
            visitLinks: this.props.installationVisits.map(iv => ({ ...iv, idInstallation: this.props.installation.id })),
        }
        if (getUser().consultant === '1') {
            this.setActions(actions)
        } else {
            this.setActions({
                ...actions,
                edit: () => this.setEditMode(),
                delete: () => this.onDelete(),
            })
        }
    }

    createAndDownloadEdition = (installationVisit) => {
        const { installation } = this.state
        this.props.getEditionInstallation(installationVisit.idCampaign, installation.id).then(json => {
            downloadURI(json.targetPath)
        })
    }

    onChangeInstallation = (newObject) => this.setState({ installation: { ...this.state.installation, ...newObject } })

    onChangeVisit = (visitObject) => {
        if (visitObject && this.props.onChangeVisit) {
            this.props.onChangeVisit(visitObject)
        }
    }

    onChangeBorehole = (newObject) => this.setState({ borehole: { ...this.state.borehole, ...newObject } })

    onChangeAquifer = (value) => {
        const aquifers = (this.state.borehole.link_aquifers || []).filter(a => a.order !== 0)
        const obj = {
            ...this.state.borehole,
            link_aquifers: value ? [...aquifers, new DtoBoreholeAquifer({ aquiferCode: value, order: 0, idStation: this.props.installation.id })] : aquifers,
        }

        this.setState({ borehole: obj })
    }

    isDisplayed = (panel) => {
        return getSetting(this.props.applicationSettings, `PANEL_${this.state.installation.installationType}_${panel}`) !== 'hidden'
    }

    render() {
        const { installation, borehole, readMode, localizeMap } = this.state
        const { accountHabilitations } = this.props
        const mode = { readMode, editMode: !readMode }

        const aquifer = borehole.link_aquifers && borehole.link_aquifers.length ? borehole.link_aquifers.find(a => a.order === 0) || {} : {}
        const params = {
            station: installation,
            onChange: this.onChangeInstallation,
            onChangeVisit: this.onChangeVisit,
            readMode,
        }

        const boreholeParams = {
            station: borehole,
            borehole,
            onChange: this.onChangeBorehole,
            onChangeVisit: this.onChangeVisit,
            readMode,
        }

        return (
            <div className='row no-margin padding-top-1 padding-bottom-5 padding-left-2' id='BoreholeDescriptionPanel'>
                <div className='col s9' style={ { marginBottom: '100px' } }>
                    <Card title={ i18n.description }>
                        <InstallationInfosPanel { ...params } />
                        <Row>
                            <NumberField
                                col={ 6 }
                                value={ borehole.sharedVolumeCapture }
                                title={ i18n.sharedVolumeCapture }
                                onChange={ v => {
                                    this.onChangeBorehole({ sharedVolumeCapture: v })
                                    this.onChangeVisit({ previousValue: borehole.sharedVolumeCapture, newValue: v, field: i18n.sharedVolumeCapture })
                                } }
                                { ...mode }
                            />
                            <NumberField
                                col={ 6 }
                                value={ borehole.operatingDayTime }
                                title={ i18n.operatingDayTime }
                                onChange={ v => {
                                    this.onChangeBorehole({ operatingDayTime: v })
                                    this.onChangeVisit({ previousValue: borehole.operatingDayTime, newValue: v, field: i18n.operatingDayTime })
                                } }
                                { ...mode }
                            />
                            <NumberField
                                col={6}
                                title={i18n.withdrawnFlowTest}
                                value={borehole.withdrawnFlowTest}
                                onChange={v => {
                                    this.onChangeBorehole({ withdrawnFlowTest: v })
                                    this.onChangeVisit({ previousValue: borehole.withdrawnFlowTest, newValue: v, field: i18n.withdrawnFlowTest })
                                }}
                                floatValue
                                {...mode}
                            />
                        </Row>
                        <Card title={i18n.management} noBoxShadow headerStyle={{ backgroundColor: greyBlue }} className='padding-left-1 padding-right-1 padding-top-1' >
                            <BoreholeManagementPanel { ...boreholeParams } />
                        </Card>
                        <Card title={i18n.civilEngineering} noBoxShadow headerStyle={{ backgroundColor: greyBlue }} className='padding-left-1 padding-right-1 padding-top-1' >
                            <BoreholeCivilGenPanel {...boreholeParams} />
                            <Row className='padding-left-1 padding-right-1'>
                                <BuildingsPanel {...params} />
                            </Row>
                        </Card>
                        <Card title={i18n.equipments} noBoxShadow headerStyle={{ backgroundColor: greyBlue }} className='padding-left-1 padding-right-1 padding-top-1' >
                            <BoreholeEquipmentsPanel { ...boreholeParams } />
                        </Card>
                    </Card>
                    <Card title={ i18n.communicationElectricity } className='margin-top-1' noMargin={ false } displayIf={ this.isDisplayed('ELEC_COM') }>
                        <InstallationComElecPanel { ...params } />
                    </Card>
                    <Card title={ i18n.accessSite } className='margin-top-1' noMargin={ false } displayIf={ this.isDisplayed('ACCESS_SITE') }>
                        <InstallationAccessSitePanel { ...params } />
                    </Card>
                    <Card title={ i18n.casingAccess } className='margin-top-1' noMargin={ false } displayIf={ this.isDisplayed('ACCESS_CASING') }>
                        <InstallationAccessCasingPanel { ...params } />
                    </Card>
                    <Row className='margin-top-1 no-margin-bottom' noMargin={ false } displayIf={ this.isDisplayed('STAFF_SAFETY') }>
                        <InstallationStaffSafetyPanel { ...params } />
                    </Row>
                    <Card title={ i18n.characteristics } className='margin-top-1' noMargin={ false }>
                        <div className='row no-margin padding-top-1 padding-bottom-1'>
                            <Row>
                                <Checkbox componentClassName='padding-bottom-1' col={ 6 } checked={ installation.exploitationCode == 1 } label={ i18n.exploitation }
                                    onChange={ v => {
                                        this.onChangeInstallation({ exploitationCode: v ? 1 : 0 })
                                        this.onChangeVisit(getVisitCheckBoxChange(i18n.exploitation, installation.exploitationCode, v))
                                    } } disabled={ readMode }
                                />
                                <Checkbox componentClassName='padding-bottom-1' col={ 6 } label={ i18n.waterTableAccompaniment } checked={ borehole.waterTableAccompaniment }
                                    onChange={ (v) => {
                                        this.onChangeBorehole({ waterTableAccompaniment: v })
                                        this.onChangeVisit(getVisitCheckBoxChange(i18n.waterTableAccompaniment, installation.waterTableAccompaniment, v))
                                    } } disabled={ readMode }
                                />
                            </Row>
                            <Row>
                                <Select col={ 6 } value={ borehole.nature } label={ i18n.nature } options={ this.props.natures } keyvalue='code'
                                    onChange={ v => {
                                        this.onChangeBorehole({ nature: v })
                                        this.onChangeVisit(getVisitSelectChange(this.props.natures, 'code', i18n.nature, borehole.nature, v))
                                    } } { ...mode } integerValue
                                />
                                <Select col={ 6 } value={ borehole.watershed } label={ i18n.watershed } options={ this.props.watersheds } keyValue='id' displayWithCode
                                    onChange={ v => {
                                        this.onChangeBorehole({ watershed: v })
                                        this.onChangeVisit(getVisitSelectChange(this.props.watersheds, 'id', i18n.watershed, borehole.watershed, v))
                                    } } { ...mode }
                                />
                            </Row>
                            <Row>
                                <NumberField col={ 6 } title={ i18n.depth } value={ borehole.depth } floatValue
                                    onChange={ v => {
                                        this.onChangeBorehole({ depth: v })
                                        this.onChangeVisit({ previousValue: borehole.depth, newValue: v, field: i18n.depth })
                                    } } { ...mode }
                                />
                                <Select col={ 6 } value={ aquifer.aquiferCode } label={ i18n.aquifer } options={ this.props.aquifers } keyValue='code'
                                    onChange={ v => {
                                        this.onChangeAquifer(v)
                                        this.onChangeVisit(getVisitSelectChange(this.props.aquifers, 'code', i18n.aquifer, aquifer.aquiferCode, v))
                                    } } { ...mode }
                                />
                            </Row>
                            <Row>
                                <Input col={ 6 } value={ borehole.pumpType } title={ i18n.pumpType }
                                    onChange={ v => {
                                        this.onChangeBorehole({ pumpType: v })
                                        this.onChangeVisit({ previousValue: borehole.pumpType, newValue: v, field: i18n.pumpType })
                                    } } { ...mode }
                                />
                                <Select col={ 6 } value={ borehole.fieldMode } label={ i18n.fieldMode } keyvalue='code' options={ this.props.fieldModes }
                                    onChange={ v => {
                                        this.onChangeBorehole({ fieldMode: v })
                                        this.onChangeVisit(getVisitSelectChange(this.props.fieldModes, 'code', i18n.fieldMode, borehole.fieldMode, v))
                                    } } { ...mode } integerValue
                                />
                            </Row>
                            <Row>
                                <NumberField col={ 6 } value={ borehole.hourWaterFlow } title={ i18n.hourWaterFlow }
                                    onChange={ v => {
                                        this.onChangeBorehole({ hourWaterFlow: v })
                                        this.onChangeVisit({ previousValue: borehole.hourWaterFlow, newValue: v, field: i18n.hourWaterFlow })
                                    } } { ...mode } floatValue
                                />
                                <NumberField col={ 6 } value={ borehole.allowedHourWaterFlow } title={ i18n.allowedHourWaterFlow }
                                    onChange={ v => {
                                        this.onChangeBorehole({ allowedHourWaterFlow: v })
                                        this.onChangeVisit({ previousValue: borehole.allowedHourWaterFlow, newValue: v, field: i18n.allowedHourWaterFlow })
                                    } } { ...mode } floatValue
                                />
                            </Row>
                            <Row>
                                <NumberField
                                    col={ 6 }
                                    value={ borehole.dayVolumeAllowed }
                                    title={ i18n.dayVolumeAllowed }
                                    onChange={ v => {
                                        this.onChangeBorehole({ dayVolumeAllowed: v })
                                        this.onChangeVisit({ previousValue: borehole.dayVolumeAllowed, newValue: v, field: i18n.dayVolumeAllowed })
                                    } }
                                    { ...mode }
                                />
                            </Row>
                            <Row>
                                <NumberField col={ 6 } value={ borehole.yearWaterFlow } title={ i18n.yearWaterFlow }
                                    onChange={ v => {
                                        this.onChangeBorehole({ yearWaterFlow: v })
                                        this.onChangeVisit({ previousValue: borehole.yearWaterFlow, newValue: v, field: i18n.yearWaterFlow })
                                    } } { ...mode } floatValue
                                />
                                <NumberField col={ 6 } value={ borehole.allowedYearWaterVolume } title={ i18n.allowedYearWaterVolume }
                                    onChange={ v => {
                                        this.onChangeBorehole({ allowedYearWaterVolume: v })
                                        this.onChangeVisit({ previousValue: borehole.allowedYearWaterVolume, newValue: v, field: i18n.allowedYearWaterVolume })
                                    } } { ...mode } floatValue
                                />
                            </Row>
                            <Row>
                                <Textarea col={ 6 } value={ installation.comments } title={ i18n.comment }
                                    onChange={ v => {
                                        this.onChangeInstallation({ comments: v })
                                        this.onChangeVisit({ previousValue: installation.comments, newValue: v, field: i18n.comment })
                                    } } { ...mode }
                                />
                                <Input col={ 6 } value={ borehole.surfaceGeology } title={ i18n.surfaceGeology }
                                    onChange={ v => {
                                        this.onChangeBorehole({ surfaceGeology: v })
                                        this.onChangeVisit({ previousValue: borehole.surfaceGeology, newValue: v, field: i18n.surfaceGeology })
                                    } } { ...mode }
                                />
                            </Row>
                            <Row>
                                <Input col={ 6 } value={ borehole.cementationTubing } title={ i18n.cementationTubing }
                                    onChange={ v => {
                                        this.onChangeBorehole({ cementationTubing: v })
                                        this.onChangeVisit({ previousValue: borehole.cementationTubing, newValue: v, field: i18n.cementationTubing })
                                    } } { ...mode }
                                />
                                <NumberField col={ 6 } title={ `${i18n.pumpDepth}(${i18n.metersSymbol})` } value={ borehole.pumpDepth }
                                    onChange={ v => {
                                        this.onChangeBorehole({ pumpDepth: v })
                                        this.onChangeVisit({ previousValue: borehole.pumpDepth, newValue: v, field: `${i18n.pumpDepth}(${i18n.metersSymbol})` })
                                    } } floatValue { ...mode }
                                />
                            </Row>
                            <Row>
                                <Checkbox col={ 6 } checked={ borehole.overflowPresence } label={ i18n.overflowPresence }
                                    onChange={ v => {
                                        this.onChangeBorehole({ overflowPresence: v })
                                        this.onChangeVisit(getVisitCheckBoxChange(i18n.overflowPresence, borehole.overflowPresence, v))
                                    } } { ...mode }
                                />
                                <NumberField col={ 6 } title={ `${i18n.drillingBlowRate} (m3/h)` } value={ borehole.drillingBlowRate }
                                    onChange={ v => {
                                        this.onChangeBorehole({ drillingBlowRate: v })
                                        this.onChangeVisit({ previousValue: borehole.drillingBlowRate, newValue: v, field: `${i18n.drillingBlowRate} (m3/h)` })
                                    } } floatValue { ...mode }
                                />
                            </Row>
                            <Row>
                                <Checkbox col={ 6 } checked={ borehole.gravitationalStation } label={ i18n.gravitationalStation }
                                    onChange={ v => {
                                        this.onChangeBorehole({ gravitationalStation: v })
                                        this.onChangeVisit(getVisitCheckBoxChange(i18n.gravitationalStation, borehole.gravitationalStation, v))
                                    } } { ...mode }
                                />
                                <NumberField col={ 6 } title={ i18n.overflowRating } value={ borehole.overflowRating }
                                    onChange={ v => {
                                        this.onChangeBorehole({ overflowRating: v })
                                        this.onChangeVisit({ previousValue: installation.overflowRating, newValue: v, field: i18n.overflowRating })
                                    } } floatValue { ...mode }
                                />
                            </Row>
                        </div>
                    </Card>
                    <Row className='padding-top-1' displayIf={ this.isDisplayed('CONTRIBUTORS') }>
                        <StationPiezoContributorPanel { ...params } />
                    </Row>
                    <Row className='padding-top-1' displayIf={ this.isDisplayed('USAGES') }>
                        <StationUsagePanel { ...params } />
                    </Row>
                    <Row className='padding-top-1' displayIf={ this.isDisplayed('ARRANGEMENTS') }>
                        <StationArrangementPanel { ...params } />
                    </Row>
                    <Row className='padding-top-1' displayIf={ this.isDisplayed('DRILLINGS') }>
                        <BoreholeDrillingTable { ...boreholeParams } />
                    </Row>
                    <Row className='padding-top-1' displayIf={ this.isDisplayed('COMMENTS') }>
                        <BoreholeCommentTable { ...boreholeParams } />
                    </Row>
                    <Row className='padding-top-1' displayIf={ this.isDisplayed('LITHOLOGY') }>
                        <BoreholeLithologyTable { ...boreholeParams } />
                    </Row>
                    <Row className='padding-top-1' displayIf={ this.isDisplayed('STRATIGRAPHY') }>
                        <BoreholeAquiferTable { ...boreholeParams } />
                    </Row>
                    <Row className='padding-top-1' displayIf={ this.isDisplayed('CASINGS') }>
                        <BoreholeCasingTable { ...boreholeParams } />
                    </Row>
                    <Card>
                        <div className='row no-margin padding-top-1 padding-bottom-1'>
                            <div className='col s6'>
                                <Row>
                                    <Checkbox componentClassName='padding-bottom-1' col={ 12 } label={ i18n.superficialWaterIsolation } checked={ borehole.superficialWaterIsolation }
                                        onChange={ (v) => {
                                            this.onChangeBorehole({ superficialWaterIsolation: v })
                                            this.onChangeVisit(getVisitCheckBoxChange(i18n.superficialWaterIsolation, borehole.superficialWaterIsolation, v))
                                        } } disabled={ readMode }
                                    />
                                </Row>
                                <Row>
                                    <Checkbox componentClassName='padding-bottom-1' col={ 12 } label={ i18n.supIsolationToVerify } checked={ borehole.supIsolationToVerify }
                                        onChange={ (v) => {
                                            this.onChangeBorehole({ supIsolationToVerify: v })
                                            this.onChangeVisit(getVisitCheckBoxChange(i18n.supIsolationToVerify, borehole.supIsolationToVerify, v))
                                        } } disabled={ readMode }
                                    />
                                </Row>
                                <Row>
                                    <Checkbox componentClassName='padding-bottom-1' col={ 12 } label={ i18n.nonIsolatedWaterTables } checked={ borehole.nonIsolatedWaterTables }
                                        onChange={ (v) => {
                                            this.onChangeBorehole({ nonIsolatedWaterTables: v })
                                            this.onChangeVisit(getVisitCheckBoxChange(i18n.nonIsolatedWaterTables, borehole.nonIsolatedWaterTables, v))
                                        } } disabled={ readMode }
                                    />
                                </Row>
                                <Row>
                                    <Checkbox componentClassName='padding-bottom-1' col={ 12 } label={ i18n.isolatedWaterTables } checked={ borehole.isolatedWaterTables }
                                        onChange={ (v) => {
                                            this.onChangeBorehole({ isolatedWaterTables: v })
                                            this.onChangeVisit(getVisitCheckBoxChange(i18n.isolatedWaterTables, borehole.isolatedWaterTables, v))
                                        } } disabled={ readMode }
                                    />
                                </Row>
                            </div>
                            <div className='col s6'>
                                <Row>
                                    <Checkbox componentClassName='padding-bottom-1' col={ 12 } label={ i18n.isolationToVerify } checked={ borehole.isolationToVerify }
                                        onChange={ (v) => {
                                            this.onChangeBorehole({ isolationToVerify: v })
                                            this.onChangeVisit(getVisitCheckBoxChange(i18n.isolationToVerify, borehole.isolationToVerify, v))
                                        } } disabled={ readMode }
                                    />
                                </Row>
                                <Row>
                                    <Textarea col={ 12 } value={ borehole.isolationComment } title={ i18n.isolationComment }
                                        onChange={ v => {
                                            this.onChangeBorehole({ isolationComment: v })
                                            this.onChangeVisit({ previousValue: installation.isolationComment, newValue: v, field: i18n.isolationComment })
                                        } } { ...mode }
                                    />
                                </Row>
                            </div>
                        </div>
                    </Card>
                    <Row className='margin-top-1 no-margin-bottom' noMargin={false} displayIf={accountHabilitations.some((h) => h.habilitation === AGRI) && this.isDisplayed('SAMPLERS')}>
                        <StationSamplersPanel {...params}/>
                    </Row>
                </div>
                <div className='col s3' style={ { marginBottom: '100px' } }>
                    <StationUpdatePanel station={ installation } />
                    <Card>
                        <Row className='padding-top-1'>
                            <Input
                                col={ 12 }
                                title={ i18n.dataOrigin }
                                value={ installation.dataOrigin }
                                onChange={ v => {
                                    this.onChangeInstallation({ dataOrigin: v })
                                    this.onChangeVisit({ previousValue: installation.dataOrigin, newValue: v, field: i18n.dataOrigin })
                                } }
                                { ...mode }
                            />
                        </Row>
                        <Row>
                            <Select
                                col={ 12 }
                                label={ i18n.status }
                                value={ installation.status }
                                onChange={ v => {
                                    this.onChangeInstallation({
                                        status: v,
                                        statusLogin: getLogin(),
                                        statusDate: moment().valueOf(),
                                    })
                                    this.onChangeVisit(getVisitSelectChange(this.props.status, 'code', i18n.status, installation.status, v))
                                } }
                                options={ this.props.status }
                                keyvalue='code'
                                integerValue
                                { ...mode }
                            />
                        </Row>
                        <Row>
                            <Input
                                col={ 12 }
                                value={ getDate(installation.statusDate) }
                                title={ i18n.controlDoneAt }
                                onChange={ v => {
                                    onChangeDate(v, v2 => this.onChangeInstallation({ statusDate: v2 }))
                                    this.onChangeVisit(getVisitDatePickerChange(i18n.controlDoneAt, installation.statusDate, v))
                                } }
                                { ...mode }
                            />
                        </Row>
                        <Row>
                            <Select
                                col={ 12 }
                                label={ i18n.controlDoneBy }
                                value={ installation.statusLogin }
                                keyvalue='name'
                                onChange={ v => {
                                    this.onChangeInstallation({ statusLogin: v })
                                    this.onChangeVisit(getVisitSelectChange(this.props.users.map(u => ({ code: u.login, name: u.name || u.login })), 'name', i18n.controlDoneBy, installation.statusLogin, v))
                                } }
                                options={ this.props.users.map(u => ({ code: u.login, name: u.name || u.login })) } { ...mode }
                            />
                        </Row>
                        <Row>
                            <Input col={ 12 } title={ i18n.arrestCode } value={ borehole.arrestCode }
                                onChange={ v => {
                                    this.onChangeBorehole({ arrestCode: v })
                                    this.onChangeVisit({ previousValue: borehole.arrestCode, newValue: v, field: i18n.arrestCode })
                                } } { ...mode }
                            />
                        </Row>
                        <Row className='padding-bottom-1'>
                            <Input
                                col={ 12 }
                                value={ getDate(borehole.arrestDate) }
                                title={ i18n.arrestDate }
                                onChange={ v => {
                                    onChangeDate(v, v2 => this.onChangeBorehole({ arrestDate: v2 }))
                                    this.onChangeVisit(getVisitDatePickerChange(i18n.arrestDate, borehole.arrestDate, v))
                                } }
                                { ...mode }
                            />
                        </Row>
                        <Row>
                            <NumberField col={ 12 } title={ i18n.arrestValidityDuration } value={ borehole.arrestValidityDuration }
                                onChange={ (_, v) => {
                                    this.onChangeBorehole({ arrestValidityDuration: v })
                                    this.onChangeVisit({ previousValue: borehole.arrestValidityDuration, newValue: v, field: i18n.arrestValidityDuration })
                                } } floatValue { ...mode }
                            />
                        </Row>
                        <Row className='padding-bottom-1'>
                            <Input
                                col={ 12 }
                                value={ getDate(installation.endDate) }
                                title={ i18n.deliberationDate }
                                onChange={ v => {
                                    onChangeDate(v, v2 => this.onChangeInstallation({ endDate: v2 }))
                                    this.onChangeVisit(getVisitDatePickerChange(i18n.deliberationDate, installation.endDate, v))
                                } }
                                { ...mode }
                            />
                        </Row>
                        <Row>
                            <Input col={ 12 } value={ installation.closeComment } title={ i18n.become }
                                onChange={ v => {
                                    this.onChangeInstallation({ closeComment: v })
                                    this.onChangeVisit({ previousValue: installation.closeComment, newValue: v, field: i18n.become })
                                } } { ...mode }
                            />
                        </Row>
                        <Row>
                            <Checkbox col={ 12 } label={ i18n.confidential } checked={ installation.confidential === '1' }
                                onChange={ v => {
                                    this.onChangeInstallation({ confidential: v ? '1' : '0' })
                                    this.onChangeVisit(getVisitCheckBoxChange(i18n.confidential, installation.confidential === '1', v))
                                } } disabled={ this.state.readMode }
                            />
                        </Row>
                    </Card>
                    <Row className='padding-top-1'>
                        <ContactCard
                            title={ i18n.contact }
                            contactCode={ installation.ownerCode }
                            onChange={ v => {
                                this.onChangeInstallation({ ownerCode: v })
                                this.onChangeVisit(getVisitSelectChange(this.props.contacts, 'code', i18n.contact, installation.ownerCode, v))
                            } }
                            readMode={ readMode }
                        />
                    </Row>
                    <LastEventPanel id={ this.props.installation.id } events={ this.props.installationEvents } stationId={ this.props.installation.id }
                        stationType='installation'
                    />
                    <Card
                        title={ i18n.map }
                        noMargin={ false }
                        className='margin-top-1'
                        actions={
                            !this.state.readMode ?
                                [{
                                    iconName: 'my_location',
                                    onClick: () => this.setState({ localizeMap: true }),
                                }] : []
                        }
                    >
                        <StationMapDashboardPanel noMarkerTooltip station={this.props.installation} type={'installation'} />
                        <LocalisationMapModal
                            onChangeInstallation={this.onChangeInstallation}
                            installation={installation}
                            readMode={readMode}
                            open={localizeMap}
                            onClose={ () => this.setState({ localizeMap: false }) }
                        />
                    </Card>
                    <Card title={ i18n.localisation } noMargin={ false } className='margin-top-1 no-margin-bottom'>
                        <StationLocationInfoPanel { ...params } />
                    </Card>
                    <LinkedStationsPanel onReMount={ this.props.onReMount } station={ this.props.installation } { ...mode } noMargin={ false } className='margin-top-1' />
                </div>
            </div>
        )
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextState.installation.LAST_FORM !== this.state.installation.LAST_FORM && nextState.installation.LAST_FORM.match(/INPUT|TEXT/g)) {
            return false
        }
        return true
    }
}

BoreholeDescriptionPanel.propTypes = {
    onRemount: PropTypes.func,
    onChangeVisit: PropTypes.func, // used for changes on visit mode only
    installationTable: arrayOf(DtoInstallationTableLine),
    accountHabilitations: arrayOf(DtoAccountHabilitation),
}

const mapStateToProps = (store) => ({
    applicationSettings: store.AdministrationReducer.applicationSettings,
    installation: store.InstallationReducer.installation,
    contacts: store.ContactReducer.contacts,
    installationEvents: store.InstallationReducer.installationEvents,
    users: store.UserReducer.users,
    status: store.QualityReducer.status,
    installationBorehole: store.InstallationReducer.installationBorehole,
    watersheds: store.WatershedReducer.watersheds,
    natures: store.StationReducer.natures,
    aquifers: store.AquiferReducer.aquifers,
    fieldModes: store.StationReducer.fieldModes,
    installationVisits: store.InstallationReducer.installationVisits,
    installationTable: store.InstallationReducer.installationTable,
    accountHabilitations: store.AccountReducer.accountHabilitations,
})

const mapDispatchToProps = {
    push,
    delete: SieauAction.delete,
    setPopup: SieauAction.setPopup,
    changeBssCode: InstallationAction.changeBssCode,
    fetchStatus: QualityAction.fetchStatus,
    fetchInstallationEvents: InstallationAction.fetchInstallationEvents,
    fetchContacts: ContactAction.fetchContacts,
    fetchWatersheds: WatershedAction.fetchWatersheds,
    fetchNatures: StationAction.fetchNatures,
    fetchAquifers: AquiferAction.fetchAquifers,
    fetchFieldModes: StationAction.fetchFieldModes,
    deleteInstallation: InstallationAction.deleteInstallation,
    updateInstallationBorehole: InstallationAction.updateInstallationBorehole,
    getEditionInstallation: InstallationAction.getEditionInstallation,
    fetchInstallationVisits: InstallationAction.fetchInstallationVisits,
    updateInstallationAndVisit: InstallationAction.updateInstallationAndVisit,
}

export default connect(mapStateToProps, mapDispatchToProps)(BoreholeDescriptionPanel)