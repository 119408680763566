import { createSlice } from '@reduxjs/toolkit'
import MaterielAssignmentDto from '../dto/MaterielAssignmentDto'
import MonitoredMaterielDto from '../dto/MonitoredMaterielDto'
import DtoMaterielState from '../dto/DtoMaterielState'
import DtoMaterielStationEvent from '../dto/DtoMaterielStationEvent'
import { flatten, uniqWith } from 'lodash'
import DtoMaterielStationCampaign from '../dto/DtoMaterielStationCampaign'
import DtoMaterielCampaign from '../dto/DtoMaterielCampaign'
import MaterielTypeDto from '../dto/MaterielTypeDto'
import DtoMaterielSettingRule from '../dto/DtoMaterielSettingRule'
import MaterielThunk from 'materiel/actions/MaterielThunk'

// https://redux.js.org/tutorials/fundamentals/part-8-modern-redux#using-createslice

export const initialState = {
    materielAssignments: [],
    monitoredMateriels: [],
    materielStates: [],
    stationsEvents: [],
    stationsCampaigns: [],
    materielCampaign: [],
    materielTypes: [],
    materielSettingRules: [],
}

const store = createSlice({
    name: 'materiel',
    initialState,
    reducers: {
        monitored: (state, action) => {
            state.monitoredMateriels = action.payload.map(mm => new MonitoredMaterielDto(mm))
        },
        assignments: (state, action) => {
            state.materielAssignments = action.payload.map(ma => new MaterielAssignmentDto(ma))
        },
        states: (state, action) => {
            state.materielStates = action.payload.map(el => new DtoMaterielState(el))
        },
        campaign: (state, action) => {
            state.materielCampaign = flatten([
                action.payload.centrals.map(mc => new DtoMaterielCampaign(mc)),
                action.payload.equipments.map(mc => new DtoMaterielCampaign(mc)),
                action.payload.powerSupplies.map(mc => new DtoMaterielCampaign(mc)),
                action.payload.sensors.map(mc => new DtoMaterielCampaign(mc)),
                action.payload.sims.map(mc => new DtoMaterielCampaign(mc)),
                action.payload.telecoms.map(mc => new DtoMaterielCampaign(mc)),
                action.payload.variousMateriels.map(mc => new DtoMaterielCampaign(mc)),
            ])
        },
        addStationsEvents: (state, action) => {
            state.stationsEvents = uniqWith([].concat(state.stationsEvents, action.payload.stationsEvents.map(el => new DtoMaterielStationEvent(el, action.payload.siteType))), (a, b) => {
                return a.code === b.code && a.number === b.number
            })
        },
        addStationsCampaigns: (state, action) => {
            state.stationsCampaigns = uniqWith([...state.stationsCampaigns, ...action.payload.stationsCampaigns.map(el => new DtoMaterielStationCampaign(el, action.payload.siteType, action.payload.siteId))], (a, b) => {
                return a.id === b.id && a.siteType === b.siteType && a.siteId === b.siteId
            })
        },
        resetAssignments: (state) => {
            state.materielAssignments = []
        },
        types: (state, action) => {
            state.materielTypes = action.payload.map(type => new MaterielTypeDto(type))
        },
    },
    extraReducers: {
        [MaterielThunk.fetchSettingRules.fulfilled]: (state, action) => {
            state.materielSettingRules = action.payload.map(rule => new DtoMaterielSettingRule(rule))
        },
    },
})

export const materielActions = store.actions
export default store.reducer