import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import TelecomDto from '../../dto/TelecomDto'
import SimDto from '../../../sim/dto/SimDto'
import Table from '../../../../../components/datatable/Table'
import i18n from 'simple-react-i18n'
import TelecomSimDto from '../../dto/TelecomSimDto'
import Card from '../../../../../components/card/Card'
import AppStore from '../../../../../store/AppStore'
import ToastrAction from 'toastr/actions/ToastrAction'


class SimTablePanel extends Component {
    constructor(props) {
        super(props)
    }

    getDatas = () => {
        return []
    }

    render() {
        const actions = [
            {
                iconName: 'note_add',
                onClick: () => AppStore.dispatch(ToastrAction.info(i18n.inDeveloppmentFunctionnality)),
            },
        ]
        return (
            <Card title={ i18n.sim } active={ this.props.active } actions={ actions }>
                <Table showTitle={ false } data={ this.getDatas() } type={ new TelecomSimDto({}) } condensed
                    sortable
                    onClick={ () => {
                    } }
                />
            </Card>
        )
    }
}

SimTablePanel.propTypes = {
    active: PropTypes.bool,
    telecom: PropTypes.instanceOf(PropTypes.instanceOf(TelecomDto)),
    sims: PropTypes.arrayOf(PropTypes.instanceOf(SimDto)),
}
SimTablePanel.defaultProps = {
    active: false,
}
const mapStateToProps = store => {
    return {
        sims: store.SimReducer.sims,
    }
}
export default connect(mapStateToProps)(SimTablePanel)
