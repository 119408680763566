/* eslint-disable camelcase */
import React from 'react'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import { connect } from 'react-redux'
import { arrayOf, getSandreList } from '../../../../utils/StoreUtils'
import DtoSandreCode from '../../../../referencial/dto/DtoSandreCode'
import { SANDRE } from '../../../../referencial/constants/ReferencialConstants'
import SieauAction from '../../../../components/sieau/SieauAction'
import Row from '../../../../components/react/Row'
import DtoSTEP from '../dto/DtoSTEP'
import Select from '../../../../components/forms/Select'
import { getVisitSandreSelectChange } from '../../../../utils/VisitUtils'
import Checkbox from 'components/forms/Checkbox'

const STEPEquipmentsPanel = ({
    onChange = () => { },
    onChangeVisit = () => { },
    STEP = {},
    readMode = false,
    sandreCodes = [],
}) => {
    const onChangeSTEPEquipments = (value) => {
        onChange({
            link_equipments: [
                {
                    idStation: STEP.idStation,
                    ...STEP.link_equipments[0],
                    ...value,
                },
            ],
        })
    }

    const equipments = STEP.link_equipments[0] || {}

    return (
        <Row className='padding-top-1 padding-bottom-1'>
            <Row>
                <Select
                    col={ 6 }
                    value={ equipments.inputVolumeMeasurementDevice }
                    label={ i18n.inputVolumeMeasurementDevice }
                    options={ getSandreList(sandreCodes, SANDRE.INSTALLATIONS_TYPEDETECTION) }
                    onChange={ (v) => {
                        onChangeSTEPEquipments({ inputVolumeMeasurementDevice: v })
                        onChangeVisit(getVisitSandreSelectChange(sandreCodes, SANDRE.INSTALLATIONS_TYPEDETECTION, i18n.inputVolumeMeasurementDevice, equipments.inputVolumeMeasurementDevice, v))
                    } }
                    keyValue='code'
                    integerValue
                    readMode={ readMode }
                />
                <Select
                    col={ 6 }
                    value={ equipments.outputVolumeMeasurementDevice }
                    label={ i18n.outputVolumeMeasurementDevice }
                    options={ getSandreList(sandreCodes, SANDRE.INSTALLATIONS_MESUREDEBIT) }
                    onChange={ (v) => {
                        onChangeSTEPEquipments({ outputVolumeMeasurementDevice: v })
                        onChangeVisit(getVisitSandreSelectChange(sandreCodes, SANDRE.INSTALLATIONS_MESUREDEBIT, i18n.outputVolumeMeasurementDevice, equipments.outputVolumeMeasurementDevice, v))
                    } }
                    keyValue='code'
                    integerValue
                    readMode={ readMode }
                />
            </Row>
            <Row>
                <Checkbox
                    col={6}
                    label={i18n.piezometer}
                    checked={equipments.piezometer}
                    onChange={v => onChangeSTEPEquipments({ piezometer: v })}
                    readMode={readMode}
                />
            </Row >
        </Row>
    )
}

STEPEquipmentsPanel.propTypes = {
    STEP: PropTypes.instanceOf(DtoSTEP),
    sandreCodes: arrayOf(DtoSandreCode),
    readMode: PropTypes.bool,
    onChange: PropTypes.func,
    onChangeVisit: PropTypes.func,
    setPopup: PropTypes.func,
}

const mapStateToProps = (store) => ({
    sandreCodes: store.ReferencialReducer.sandreCodes,
})

const mapDispatchTopProps = {
    setPopup: SieauAction.setPopup,
}

export default connect(mapStateToProps, mapDispatchTopProps)(STEPEquipmentsPanel)
