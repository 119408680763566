import { Button, Grid } from '@mui/material'
import { DialogActionsMUI, DialogContentMUI, DialogMUI, DialogTitleMUI } from 'components/styled/Dialog'
import PropTypes from 'prop-types'
import React, { useMemo, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import CampaignAction from '../../../../campaign/actions/CampaignAction'
import FormCampaign from '../../../../campaign/components/campaignDashboard/FormCampaign'
import DtoStationCampaign from '../../../../campaign/dto/DtoStationCampaign'
import Table from '../../../../components/datatable/Table'
import Icon from '../../../../components/icon/Icon'
import { checkMandatoryFields } from '../../../../utils/FormUtils'
import DtoPiezometer from '../../../dto/DtoPiezometer'
import { StyledFieldSet } from 'components/StyledElements'

const PiezometryNewCampaignModal = ({
    isOpen = false,
    onClose = () => { },
    onValidate = () => { },
    stations = [],
}) => {
    const dispatch = useDispatch()
    const {
        citiesIndex,
        accountUser,
    } = useSelector(store => ({
        citiesIndex: store.CityReducer.citiesIndex,
        accountUser: store.AccountReducer.accountUser,
    }), shallowEqual)

    const [campaign, setCampaign] = useState({
        name: '',
        // beginningApplication: undefined,
        // endingApplication: undefined,
        contactCode: accountUser.contactCode,
        comment: '',
        statut: 1,
        campaignType: 2,
    })

    const data = useMemo(() => {
        return stations.map(s => ({
            ...s,
            city: citiesIndex[s.townCode]?.labelWithCode,
            nullValue: s.selected ? <Icon icon='check_box' /> : '',
        }))
    }, [citiesIndex, stations])

    const reset = () => {
        setCampaign({
            name: '',
            // beginningApplication: undefined,
            // endingApplication: undefined,
            contactCode: accountUser.contactCode,
            comment: '',
            statut: 1,
            campaignType: 2,
        })
    }

    const onClosePopin = () => {
        reset()
        onClose()
    }

    const onValidatePopin = () => {
        checkMandatoryFields([{ field: 'beginningApplication', i18n: 'startDate' }, { field: 'endingApplication', i18n: 'endDate' }], campaign, () => {
            dispatch(CampaignAction.createCampaign(campaign, 'piezometry')).then((json = {}) => {
                if (json.insert === 1 && json.id) {
                    const campaignStations = stations.map(s => new DtoStationCampaign({
                        campaignId: json.id,
                        stationId: s.id,
                        visitOrder: 0,
                    }))
                    dispatch(CampaignAction.updateCampaignStations(campaignStations, 'piezometry', json.id)).then(() => {
                        dispatch(CampaignAction.fetchPiezometryCampaigns())
                        reset()
                        onValidate()
                    })
                }
            })
        })
    }

    return (
        <DialogMUI
            fullWidth
            maxWidth='xl'
            open={isOpen}
        >
            <DialogTitleMUI>
                <Grid container justifyContent='space-between' alignItems='center' style={{ padding: '0 20' }}>
                    <Grid item >
                        {i18n.newCampaign}
                    </Grid>
                    <Grid item>
                        <Icon style={{ color: 'white' }} size='small' icon='close' onClick={onClosePopin} />
                    </Grid>
                </Grid>
            </DialogTitleMUI>
            <DialogContentMUI>
                <div className='row no-margin' style={{ height: '65vh' }}>
                    <StyledFieldSet style={{ padding: '5 8 10 8' }}>
                        <FormCampaign
                            campaign={campaign}
                            onChangeCampaign={change => setCampaign(c => ({ ...c, ...change }))}
                        />
                    </StyledFieldSet>
                    <div className='row no-margin padding-top-1'>
                        <Table
                            data={data}
                            sortable
                            orderable
                            title={i18n.stations}
                            type={{ headers: ['code', 'name', 'city', 'nullValue'] }}
                            condensed
                            maxHeight='38%'
                        />
                    </div>
                </div>
            </DialogContentMUI>
            <DialogActionsMUI>
                <Grid item xs={1}>
                    <Button
                        onClick={onValidatePopin}
                        variant='contained'
                    >
                        {i18n.validate}
                    </Button>
                </Grid>
            </DialogActionsMUI>
        </DialogMUI>
    )
}

PiezometryNewCampaignModal.propTypes = {
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
    onValidate: PropTypes.func,
    stations: PropTypes.arrayOf(PropTypes.instanceOf(DtoPiezometer)),
}

export default PiezometryNewCampaignModal
