module.exports = {
    // Cumul Perso
    CUMUL_PERSO_MAX: 'CUMUL_PERSO_MAX',
    CUMUL_PERSO_MIN: 'CUMUL_PERSO_MIN',
    CUMUL_PERSO_SUM: 'CUMUL_PERSO_SUM',
    CUMUL_PERSO_AVERAGE: 'CUMUL_PERSO_AVERAGE',
    PERSONALIZED_GROUPING: 'personalizedGrouping',
    // Regroupement
    MAX: 'MAX',
    MIN: 'MIN',
    AVERAGE: 'AVERAGE',
    BRUTE: 'BRUTE',
}