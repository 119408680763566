import React, { useEffect, useMemo, useState } from 'react'
import georisques from 'assets/pictures/georisques.png'
import gesteau from 'assets/pictures/gesteau.png'
import logoInsee from 'assets/pictures/logo-insee.png'
import eaufrance from 'assets/pictures/logos/eaufrance.jpg'
import { push } from '@lagunovsky/redux-react-router'
import ImportFileContent from 'import/components/content/ImportFileContent'
import { find } from 'lodash'
import i18n from 'simple-react-i18n'
import useTitle from 'utils/customHook/useTitle'
import { getDataWithHeaders } from 'utils/ExportDataUtil'
import {
    eaufranceCityLink,
    georisquesCityDefaultLink,
    gesteauCityDefaultLink,
    inseeCityDefaultLink,
} from '../../../../conf/SieauConstants'
import HomeAction from '../../../../home/actions/HomeAction'
import {
    PATH_REFERENCIAL,
    PATH_REFERENCIAL_CITY,
    PATH_REFERENCIAL_CITY_NEW,
} from '../../../../home/constants/RouteConstants'
import ReferentialAction from '../../../action/ReferencialAction'
import CityAction from '../actions/CityAction'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import useActions from 'utils/customHook/useActions'
import SearchTable from 'referencial/components/SearchTable'
import { getExportReferencialData } from 'referencial/util/ReferencialUtils'
import { REFERENCIAL_TYPE_NAME } from 'referencial/constants/ReferencialConstants'
import useProgressDispatch from 'utils/customHook/useProgressDispatch'
import ProgressCard from 'components/card/ProgressCard'

const cityHeaders = ['code', 'name', 'email', 'phone']
const headers = ['code', 'name', 'situation', 'departmentNumber', 'area', 'countryCode', 'x', 'y', 'projection', 'email', 'phone', 'population']
const headersCsv = ['Code*', 'Nom*', 'Pays', 'Situation', 'Email', 'Telephone', 'Superficie', 'Population', 'X', 'Y', 'Code Projection']

const CitiesApp = () => {
    const {
        cities,
        referencialSandreCodes,
    } = useSelector(store => ({
        cities: store.CityReducer.cities,
        referencialSandreCodes: store.ReferencialReducer.referencialSandreCodes,
    }), shallowEqual)

    const [fileContent, setFileContent] = useState('')
    const [exportData, setExportData] = useState([])

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(HomeAction.setHelpLink('', ''))
    }, [])

    const {
        isLoaded,
        progress,
    } = useProgressDispatch(() => {
        const promises = [() => CityAction.fetchCities(true), ReferentialAction.fetchReferencialSandreCodes]
        return promises.map(p => dispatch(p()))
    }, [])

    useTitle(() => [{
        title: i18n.referencials,
        href: PATH_REFERENCIAL,
    }, {
        title: i18n.towns,
        href: PATH_REFERENCIAL_CITY,
    }], [])

    const lastUpdate = useMemo(() => find(referencialSandreCodes, o => o.field === 'COMMUNES'), [referencialSandreCodes])

    const onImportFile = (e) => {
        const reader = new FileReader()
        const file = e.target.files[0]
        reader.onload = upload => setFileContent(upload.target.result)
        reader.readAsDataURL(file)
    }

    const getFileContent = (title, subtitle, extension) => (
        <ImportFileContent
            title={title}
            subtitle={subtitle}
            extension={extension}
            headersCsv={headersCsv}
            onImportFile={f => onImportFile(f)}
        />
    )

    const data = useMemo(() => getExportReferencialData(cities), [cities])

    useActions(() => {
        return {
            new: () => dispatch(push(PATH_REFERENCIAL_CITY_NEW)),
            referencialActions: {
                actions: {
                    export: {
                        data: getDataWithHeaders(exportData, headers),
                        titleFile: i18n.cityList,
                    },
                    sync: {
                        updateAction: () => dispatch(CityAction.fetchSynchroSandreCity()),
                        onImportXml: () => dispatch(CityAction.fetchImportCityXML(fileContent)),
                        onImportCsv: () => dispatch(CityAction.fetchImportCityCSV(fileContent)),
                        xmlContent: getFileContent(i18n.xmlSyncChoice, i18n.importXmlFileToZanderReference, '.xml'),
                        csvContent: getFileContent(i18n.csvSyncChoice, i18n.importCsvZanderReference, '.csv'),
                        fileContentIsValid: !!fileContent,
                    },
                    purge: {
                        onPurge: () => dispatch(CityAction.purgeCity()),
                    },
                },
            },
            links: [{
                href: inseeCityDefaultLink,
                img: logoInsee,
                label: i18n.insee.toUpperCase(),
            }, {
                href: georisquesCityDefaultLink,
                img: georisques,
                label: i18n.georisques.toUpperCase(),
            }, {
                href: gesteauCityDefaultLink,
                img: gesteau,
                label: i18n.gesteau.toUpperCase(),
            }, {
                href: eaufranceCityLink,
                img: eaufrance,
                label: i18n.eaufrance.toUpperCase(),
            }],
        }
    }, [exportData, fileContent])

    return !isLoaded ? (
        <ProgressCard progress={progress} withMessage />
    ) : (
        <SearchTable
            title={i18n.cityList}
            sessionKey={REFERENCIAL_TYPE_NAME.city}
            lastUpdate={lastUpdate}
            data={data}
            setExportData={setExportData}
            headers={cityHeaders}
            onClick={city => dispatch(push(`/referencial/city/${city.id}/dashboard`))}
        />
    )
}

export default CitiesApp
