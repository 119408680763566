import { styled } from '@mui/styles'
import { darkBlue } from 'utils/constants/ColorTheme'
import { MAIN_RADIUS } from 'utils/constants/Theme'

const StyledFieldSet = styled('fieldset')({
    borderRadius: '5px',
    border: 'solid 1px grey',
    padding: '5 8',
    backgroundColor: 'white',
    margin: '10 0 0 0',
})

const SmallStyledFieldSet = styled('fieldset')({
    borderRadius: MAIN_RADIUS,
    border: 'solid 1px grey',
    padding: '0.5rem',
    backgroundColor: 'white',
    margin: 0,
})

const StyledLegend = styled('legend')({
    backgroundColor: 'white',
    border: 'solid 1px grey',
    borderRadius: '5px',
    padding: '0 10',
    color: darkBlue,
    fontWeight: 'bold',
})

const StyledSpan = styled('span')({
    lineheight: '12px',
    fontSize: '16px',
})

export { StyledFieldSet, SmallStyledFieldSet, StyledLegend, StyledSpan }