/* eslint-disable camelcase */
import DtoBoreholeCasing from '../../components/borehole/casing/dto/DtoBoreholeCasing'
import DtoBoreholeAquifer from '../../components/borehole/aquifer/dto/DtoBoreholeAquifer'
import DtoBoreholeDrilling from '../../components/borehole/drilling/dto/DtoBoreholeDrilling'
import DtoBoreholeComment from '../../components/borehole/boreholeComment/dto/DtoBoreholeComment'
import DtoLithology from '../../../station/dto/DtoLithology'
import DtoBoreholeManagement from './DtoBoreholeManagement'
import DtoBoreholeCivilEng from './DtoBoreholeCivilEng.js'
import DtoBoreholeEquipments from './DtoBoreholeEquipments.js'

export default class DtoInstallationBorehole {
    constructor(obj) {
        this.id = obj.id
        this.nature = obj.nature
        this.superficialWaterIsolation = obj.superficialWaterIsolation
        this.supIsolationToVerify = obj.supIsolationToVerify
        this.nonIsolatedWaterTables = obj.nonIsolatedWaterTables
        this.isolatedWaterTables = obj.isolatedWaterTables
        this.isolationToVerify = obj.isolationToVerify
        this.isolationComment = obj.isolationComment
        this.waterTableAccompaniment = obj.waterTableAccompaniment
        this.watershed = obj.watershed
        this.hourWaterFlow = obj.hourWaterFlow
        this.yearWaterFlow = obj.yearWaterFlow
        this.cementationTubing = obj.cementationTubing
        this.pumpType = obj.pumpType
        this.zReferenceMark = obj.zReferenceMark
        this.referenceMarkComment = obj.referenceMarkComment
        this.arrestCode = obj.arrestCode
        this.arrestDate = obj.arrestDate
        this.serviceDate = obj.serviceDate
        this.allowedHourWaterFlow = obj.allowedHourWaterFlow
        this.allowedYearWaterVolume = obj.allowedYearWaterVolume
        this.operator = obj.operator
        this.managementUnit = obj.managementUnit
        this.distributionUnit = obj.distributionUnit
        this.fieldMode = obj.fieldMode
        this.depth = obj.depth
        this.surfaceGeology = obj.surfaceGeology
        this.pumpDepth = obj.pumpDepth
        this.drillingBlowRate = obj.drillingBlowRate
        this.overflowPresence = obj.overflowPresence
        this.overflowRating = obj.overflowRating
        this.arrestValidityDuration = obj.arrestValidityDuration
        this.gravitationalStation = obj.gravitationalStation
        this.withdrawnFlowTest = obj.withdrawnFlowTest

        this.dayVolumeAllowed = obj.dayVolumeAllowed
        this.sharedVolumeCapture = obj.sharedVolumeCapture
        this.operatingDayTime = obj.operatingDayTime

        this.link_casings = obj.link_casings ? obj.link_casings.map(c => new DtoBoreholeCasing(c)) : []
        this.link_lithology = obj.link_lithology ? obj.link_lithology.map(u => new DtoLithology(u)) : []
        this.link_aquifers = obj.link_aquifers && obj.link_aquifers.length ? obj.link_aquifers.map(a => new DtoBoreholeAquifer(a)) : []
        this.link_drillings = obj.link_drillings && obj.link_drillings.length ? obj.link_drillings.map(a => new DtoBoreholeDrilling(a)) : []
        this.link_boreholeComments = obj.link_boreholeComments && obj.link_boreholeComments.length ? obj.link_boreholeComments.map(a => new DtoBoreholeComment(a)) : []
        this.link_managements = obj.link_managements ? obj.link_managements.map(m => new DtoBoreholeManagement(m)) : []
        this.link_civilEngs = obj.link_civilEngs ? obj.link_civilEngs.map(a => new DtoBoreholeCivilEng(a)) : []
        this.link_equipments = obj.link_equipments ? obj.link_equipments.map(a => new DtoBoreholeEquipments(a)) : []
    }
}