/* eslint-disable camelcase */
import { Button, Dialog } from '@mui/material'
import Card from 'components/card/Card'
import Table from 'components/datatable/Table'
import Input from 'components/forms/Input'
import Icon from 'components/icon/Icon'
import { DialogActionsMUI, DialogContentMUI, DialogTitleMUI } from 'components/styled/Dialog'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { nbPerPageLabelShort } from 'referencial/constants/ReferencialConstants'
import DtoSandreCode from 'referencial/dto/DtoSandreCode'
import i18n from 'simple-react-i18n'
import { searchAllCharacters } from 'utils/StringUtil'
import DtoExploitation from '../../dto/DtoExploitation'

class SelectPointsPrelModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            link_points: [...props.exploitation.link_samplings],
            searchValue: '',
        }
    }

    addPoint = (point) => {
        const { link_points } = this.state
        const { exploitation } = this.props
        this.setState({
            link_points: [
                ...link_points,
                {
                    idInstallation: point.id,
                    idExploitation: exploitation.idExploitation,
                    stateCode: 1,
                }],
        })
    }

    removePoint = (point) => {
        const { link_points } = this.state
        const newLinks = link_points.filter((p) => p.idInstallation !== point.id)
        this.setState({ link_points: newLinks })
    }

    getFilteredData = (data) => {
        const { searchValue } = this.state
        const includesValue = searchAllCharacters(searchValue || '')
        return data.filter(i => this.getHash(i).includes(includesValue))
    }

    getHash = (point) => {
        return searchAllCharacters(point.headers.map(key => point[key]))
    }

    getPoints = () => {
        const { installationsWithGeo, citiesIndex } = this.props
        const { link_points } = this.state
        const filteredPoints = this.getFilteredData(installationsWithGeo.filter((i) => !link_points.find((pExploit) => i.id === pExploit.idInstallation)))
        const headers = [
            'code',
            'city',
            'codeParcelle',
            'lieuDit',
            'nullValue',
        ]
        const formatedPoints = filteredPoints.map((p) => {
            const city = citiesIndex[p.townCode] || {}
            return {
                point: p,
                code: p.code,
                name: p.name,
                city: city.labelWithCode,
                codeParcelle: `${p.parcel || ''} ${p.section || ''}`,
                lieuDit: p.location,
                nullValue: (<i className='material-icons clickable' onClick={() => this.addPoint(p)}>add</i>),
            }
        })
        return (
            <Card title={`${formatedPoints.length > 1 ? i18n.pointsPrelevement : i18n.pointPrelevement} (${formatedPoints.length})`}>
                <div className='row no-margin'>
                    <Table
                        showTitle={false}
                        condensed
                        data={formatedPoints}
                        type={{ headers }}
                        sortable
                        customHeaders={{ codeParcelle: (<div>Code<br />parcellaire</div>) }}
                        clickable
                        onClick={(p) => this.addPoint(p.point)}
                        paging
                        nbPerPageLabel={nbPerPageLabelShort}
                    />
                </div>
            </Card>
        )
    }

    getSelectedPoints = () => {
        const { installationsWithGeo, citiesIndex } = this.props
        const { link_points } = this.state
        const selectedPoints = link_points.filter((pExploit) => installationsWithGeo.find((i) => i.id === pExploit.idInstallation)).map((pExploit) => {
            const pointFound = installationsWithGeo.find((i) => i.id === pExploit.idInstallation) || {}
            const city = citiesIndex[pointFound.townCode] || {}
            return {
                ...pointFound,
                city: city.labelWithCode,
                codeParcelle: `${pointFound.parcel || ''} ${pointFound.section || ''}`,
                lieuDit: pointFound.location,
            }
        })
        const filteredPoints = this.getFilteredData(selectedPoints)
        const headers = [
            'code',
            'city',
            'codeParcelle',
            'lieuDit',
        ]
        const plurial = filteredPoints.length > 1
        return (
            <Card title={`${plurial ? i18n.selectedPointsPrelevement : i18n.selectedPointPrelevement} (${filteredPoints.length}${filteredPoints.length !== selectedPoints.length ? ` ${plurial ? 'affichés' : 'affiché'} sur ${selectedPoints.length}` : ''})`}>
                <div className='row no-margin'>
                    <Table
                        showTitle={false}
                        condensed
                        data={filteredPoints}
                        type={{ headers }}
                        sortable
                        customHeaders={{ codeParcelle: (<div>Code<br />parcellaire</div>) }}
                        paging
                        nbPerPageLabel={nbPerPageLabelShort}
                        clickable
                        onClick={(s) => this.removePoint(s)}
                        deletable
                        onDelete={(s) => this.removePoint(s)}
                    />
                </div>
            </Card>
        )
    }

    onCancel = () => {
        const { exploitation } = this.props
        this.setState({ link_points: exploitation.link_samplings, searchValue: '' })
        this.props.onCancel()
    }

    onValidate = () => {
        const { link_points } = this.state
        this.setState({ searchValue: '' })
        this.props.onValidate(link_points)
    }

    onCreateNew = () => {
        this.onValidate()
        this.props.onCreateNew()
    }

    render() {
        const { open } = this.props
        const { searchValue } = this.state

        return (
            <Dialog
                fullWidth
                maxWidth='lg'
                open={open}
            >
                <DialogTitleMUI style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    {i18n.selection}
                    <Icon style={{ color: 'white' }} size='small' icon='close' onClick={this.onCancel} />
                </DialogTitleMUI>
                <DialogContentMUI>
                    <div className='row padding-top-1 no-margin'>
                        <div className='col s4'>
                            <Input title={i18n.search} value={searchValue}
                                onChange={(value) => this.setState({ searchValue: value })}
                            />
                        </div>
                    </div>
                    <div className='row no-margin'>
                        <div className='col s6'>
                            {this.getPoints()}
                        </div>
                        <div className='col s6'>
                            {this.getSelectedPoints()}
                        </div>
                    </div>
                </DialogContentMUI>
                <DialogActionsMUI style={{ justifyContent: 'space-between' }}>
                    <Button onClick={this.onCreateNew} variant='contained' color='primary'>
                        {i18n.createNewPointPrel}
                    </Button>
                    <Button onClick={this.onValidate} variant='contained' color='primary'>
                        {i18n.validate}
                    </Button>
                </DialogActionsMUI>
            </Dialog>
        )
    }
}

SelectPointsPrelModal.propTypes = {
    sandreCodes: PropTypes.arrayOf(PropTypes.instanceOf(DtoSandreCode)),
    exploitation: PropTypes.instanceOf(DtoExploitation),
    onCancel: PropTypes.func.isRequired,
    onValidate: PropTypes.func.isRequired,
    onCreateNew: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    citiesIndex: PropTypes.shape({}),
    installationsWithGeo: PropTypes.arrayOf(PropTypes.object),
}

const mapStateToProps = (store) => {
    return {
        sandreCodes: store.ReferencialReducer.sandreCodes,
        citiesIndex: store.CityReducer.citiesIndex,
        installationsWithGeo: store.InstallationReducer.installationsWithGeo,
    }
}

export default connect(mapStateToProps)(SelectPointsPrelModal)
