import { Grid } from '@mui/material'
import Card from 'components/card/Card'
import Checkbox from 'components/forms/Checkbox'
import HomeAction from 'home/actions/HomeAction'
import { ADMINISTRATION, PATH_DASHBOARD, PATH_MATERIEL } from 'home/constants/RouteConstants'
import MaterielThunk from 'materiel/actions/MaterielThunk'
import { SUBSCRIPTION_ID, TELECOM_ID } from 'materiel/constants/MaterielConstants'
import { DATEACHAT_OBLIGATOIRE, DATE_SERVICE_OBLIGATOIRE, NUMIMEI_OBLIGATOIRE, NUMLINE_OBLIGATOIRE, NUMSERIE_OBLIGATOIRE } from 'materiel/constants/MaterielSettingRuleConstant'
import MaterielTypeDto from 'materiel/dto/MaterielTypeDto'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import useActions from 'utils/customHook/useActions'

const Field = ({
    setSettingRules = () => { },
    materielType = {},
    settingRules = [],
    fields = [],
}) => {
    return (
        <Grid item xs = { 6} spacing = { 3} >
            <Card title={materielType.label}>
                <div className='row no-margin padding-top-1 padding-bottom-1'>
                    {
                        fields.map(field => {
                            const settingRule = settingRules.find(({ rule, idMaterielType }) => rule === field.constant && idMaterielType === materielType.id)
                            return (
                                <Checkbox
                                    col={12}
                                    label={field.label}
                                    checked={settingRule?.shouldUse}
                                    onChange={check => setSettingRules([
                                        ...settingRules.filter(({ rule, idMaterielType }) => rule !== field.constant || idMaterielType !== materielType.id),
                                        { ...settingRule, idMaterielType: materielType.id, rule: field.constant, shouldUse: check },
                                    ])}
                                />
                            )
                        })
                    }
                </div>
            </Card>
        </Grid >
    )
}

Field.propTypes = {
    setSettingRules: PropTypes.func,
    materielType: PropTypes.instanceOf(MaterielTypeDto),
    settingRules: PropTypes.shape({
        idMaterielType: PropTypes.number,
        rule: PropTypes.string,
        shouldUse: PropTypes.bool,
    }),
    fields: PropTypes.shape({
        constant: PropTypes.string,
        label: PropTypes.string,
    }),
}

const MaterielRuleApp = () => {
    const {
        materielTypes,
        materielSettingRules,
    } = useSelector(store => ({
        materielTypes: store.MaterielReducer.materielTypes,
        materielSettingRules: store.MaterielReducer.materielSettingRules,
    }), shallowEqual)

    const [settingRules, setSettingRules] = useState(materielSettingRules)

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(MaterielThunk.fetchSettingRules())
        dispatch(HomeAction.setTitle([{
            title: i18n.materiel,
            href: `${PATH_MATERIEL}/${PATH_DASHBOARD}`,
        }, {
            title: i18n.administration,
            href: `${PATH_MATERIEL}/${ADMINISTRATION}`,
        }]))
    }, [])

    useActions(() => {
        return {
            save: () => dispatch(MaterielThunk.saveSettingRules(settingRules)),
        }
    }, [settingRules])

    useEffect(() => {
        setSettingRules(materielSettingRules)
    }, [materielSettingRules])

    return (
        <div className='col s12'>
            <div className='row no-margin'>
                <div className='card'>
                    <div className='row card-content'>
                        <Grid container justifyContent='center' alignItems='center'>
                            <Grid container item xs={8} spacing={3}>
                                {
                                    materielTypes.map(type => {
                                        switch (type.id) {
                                            case SUBSCRIPTION_ID:
                                                return (
                                                    <Field
                                                        setSettingRules={setSettingRules}
                                                        materielType={type}
                                                        settingRules={settingRules}
                                                        fields={[{
                                                            constant: NUMLINE_OBLIGATOIRE,
                                                            label: i18n.lineNumberObligatory,
                                                        }, {
                                                            constant: DATE_SERVICE_OBLIGATOIRE,
                                                            label: i18n.openDateObligatory,
                                                        }]}
                                                    />
                                                )
                                            case TELECOM_ID:
                                                return (
                                                    <Field
                                                        setSettingRules={setSettingRules}
                                                        materielType={type}
                                                        settingRules={settingRules}
                                                        fields={[{
                                                            constant: NUMIMEI_OBLIGATOIRE,
                                                            label: i18n.imeiObligatory,
                                                        }, {
                                                            constant: DATEACHAT_OBLIGATOIRE,
                                                            label: i18n.purchaseDateObligatory,
                                                        }]}
                                                    />
                                                )
                                            default:
                                                return (
                                                    <Field
                                                        setSettingRules={setSettingRules}
                                                        materielType={type}
                                                        settingRules={settingRules}
                                                        fields={[{
                                                            constant: NUMSERIE_OBLIGATOIRE,
                                                            label: i18n.serialNumberObligatory,
                                                        }, {
                                                            constant: DATEACHAT_OBLIGATOIRE,
                                                            label: i18n.purchaseDateObligatory,
                                                        }]}
                                                    />
                                                )
                                        }
                                    })
                                }
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MaterielRuleApp