import { WhiteCard } from 'components/styled/Card'
import { maxBy, some } from 'lodash'
import moment from 'moment'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import { H_MAT_MODULE } from '../../../account/constants/AccessRulesConstants'
import ActionComponent from '../../../components/ActionComponent'
import AlertPanel from '../../../components/alert/AlertPanel'
import Card from '../../../components/card/Card'
import Checkbox from '../../../components/forms/Checkbox'
import DatePicker from '../../../components/forms/DatePicker'
import Input from '../../../components/forms/Input'
import NumberField from '../../../components/forms/NumberField'
import Select from '../../../components/forms/Select'
import Textarea from '../../../components/forms/Textarea'
import Row from '../../../components/react/Row'
import SieauAction from '../../../components/sieau/SieauAction'
import UnitChartPanel from '../../../productionUnit/components/UnitChartPanel'
import ContactCard from '../../../referencial/components/contact/components/ContactCard'
import { SANDRE } from '../../../referencial/constants/ReferencialConstants'
import LinkedStationsPanel from '../../../station/components/associatedStation/LinkedStationsPanel'
import StationMapDashboardPanel from '../../../station/components/dashboard/component/map/StationMapDashboardPanel'
import LastEventPanel from '../../../station/components/event/LastEventPanel'
import MaterielAssigned from '../../../station/components/materiel/MaterielAssigned'
import StationUpdatePanel from '../../../station/components/update/StationUpdatePanel'
import AppStore from '../../../store/AppStore'
import { getColorCircleElement, getRGBColor } from '../../../utils/ColorUtil'
import HabilitationRequired from '../../../utils/components/HabilitationRequired'
import { getDate } from '../../../utils/DateUtil'
import { getControlColor } from '../../../utils/InstallationUtils'
import { hasBooleanValue, hasValue } from '../../../utils/NumberUtil'
import { getMapStateToProps, getPropTypes, getSandreList } from '../../../utils/StoreUtils'
import InstallationAction from '../../actions/InstallationAction'
import { INSTALLATION_TYPE } from '../../constants/InstallationConstants'
import InstallationInfosDashboard from '../installations/components/InstallationInfosDashboard'
import BoreholeAquiferDashboard from './aquifer/components/BoreholeAquiferDashboard'
import InstallationArrangementDashboard from './arrangements/InstallationArrangementDashboard'
import BoreholeCommentDashboard from './boreholeComment/components/BoreholeCommentDashboard'
import BoreholeCasingDashboard from './casing/components/BoreholeCasingDashboard'
import BoreholeDrillingDashboard from './drilling/components/BoreholeDrillingDashboard'
import BoreholeLithologyDashboard from './lithology/components/BoreholeLithologyDashboard'
import ThresholdIndicators from 'piezometry/components/alerts/thresholds/ThresholdIndicators'
import InstallationLinkedProduction from '../installationDefault/InstallationLinkedProduction'
import { Grid } from '@mui/material'

const storeProps = {
    installation: false,
    cities: false,
    installationBorehole: false,
    watersheds: true,
    status: true,
    users: true,
    sandreCodes: false,
    natures: true,
    aquifers: true,
    fieldModes: true,
    contributors: false,
    installationEvents: false,
}

class BoreholeDashboardPanel extends ActionComponent {
    state = {
        uncheckedStations: [],
    }
    componentWillMount() {
        AppStore.dispatch(SieauAction.fetch(storeProps))
        if (this.props.installation.id) {
            if (this.props.installation.installationType === INSTALLATION_TYPE.BOREHOLE) {
                AppStore.dispatch(InstallationAction.fetchInstallationBorehole(this.props.installation.id))
            }
        }
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.installation.id != nextProps.installation.id) {
            if (nextProps.installation.installationType === INSTALLATION_TYPE.BOREHOLE) {
                AppStore.dispatch(InstallationAction.fetchInstallationBorehole(nextProps.installation.id))
            }
        }
    }

    getAquifer = (borehole) => {
        const found = borehole && borehole.link_aquifers && borehole.link_aquifers.length ? borehole.link_aquifers.find(a => a.order === 0) : null
        return found ? found.aquiferId : null
    }

    getGeoData = (installation) => {
        return installation?.link_geo?.length ? installation.link_geo[0] : {}
    }

    getOriginIcon = (installation) => {
        return hasValue(installation.jobExecutionId) ? (
            <div className='row no-margin padding-left-1 padding-top-1'>
                <h6 className='valign-wrapper'>
                    <i className='material-icons rem-3-size padding-right-1'>wifi</i>
                    {i18n.automaticUpdate}
                </h6>
            </div>
        ) : null
    }

    getOriginStatusColor = (installation) => {
        if (hasValue(installation.status)) {
            const color = getControlColor(installation.status)
            return getColorCircleElement(getRGBColor(color), true)
        }
        return null
    }

    getDiagnosticAlertPanel = () => {
        const diagnosticEvents = this.props.installationEvents.filter(i => i.diagnostic && i.diagnosticDeadline)
        if (diagnosticEvents.length) {
            const lastDate = maxBy(diagnosticEvents, 'diagnosticDeadline').diagnosticDeadline
            if (moment().isSameOrAfter(moment(lastDate).year(moment(lastDate).year() - 2))) {
                const color = moment().isSameOrAfter(moment(lastDate).year(moment(lastDate).year() - 1)) ? 'red' : 'orange'
                return (
                    <AlertPanel comment={i18n.diagnosticDeadlineOn + getDate(lastDate)}
                        title={i18n.diagnosticToPlan}
                        icon={'warning'} color={color}
                    />
                )
            }
        }
        return null
    }

    render() {
        const { associatedSites } = this.props

        const installation = this.props.installation
        const borehole = this.props.installationBorehole
        const readMode = { readMode: true, editMode: false, hideNull: true }

        const cartographyData = this.getGeoData(installation)
        const params = {
            station: installation,
        }

        const boreholeParams = {
            station: borehole,
        }

        return (
            <div className='row no-margin padding-top-1 padding-bottom-7'>
                <div className='col s9'>
                    <Card noMargin={false} round className='margin-bottom-1'>
                        <Row>
                            <InstallationInfosDashboard />
                        </Row>
                    </Card>
                    <WhiteCard
                        title={i18n.characteristics}
                        smallCard
                        round
                        noMargin={false}
                        className='margin-bottom-1'
                    >
                        <div className='row no-margin padding-top-1'>
                            <div className='col s6' >
                                <div className='row no-margin'>
                                    <Checkbox componentClassName='padding-bottom-1' col={12} checked={hasValue(installation.exploitationCode) ? installation.exploitationCode == 1 : null}
                                        label={i18n.exploitation} disabled {...readMode}
                                    />
                                </div>
                                <div className='row no-margin'>
                                    <Select col={12} value={borehole.nature} label={i18n.nature} options={this.props.natures} {...readMode} />
                                </div>
                                <div className='row no-margin'>
                                    <Input col={12} value={borehole.use} title={i18n.use} {...readMode} />
                                </div>
                                <div className='row no-margin'>
                                    <NumberField col={12} title={i18n.depth} value={borehole.depth ? borehole.depth + i18n.metersSymbol : null} floatValue {...readMode} />
                                </div>
                                <div className='row no-margin'>
                                    <Input col={12} value={borehole.pumpType} title={i18n.pumpType} {...readMode} />
                                </div>
                                <div className='row no-margin'>
                                    <Input col={12} value={borehole.hourWaterFlow} title={i18n.hourWaterFlow} {...readMode} />
                                </div>
                                <div className='row no-margin'>
                                    <Input col={12} value={borehole.yearWaterFlow || (borehole.hourWaterFlow * 20 * 365)} title={i18n.yearWaterFlow} {...readMode} />
                                </div>
                                <div className='row no-margin'>
                                    <Input col={12} value={borehole.cementationTubing} title={i18n.cementationTubing} {...readMode} />
                                </div>
                                <div className='row no-margin'>
                                    <Checkbox col={12} checked={borehole.overflowPresence} label={i18n.overflowPresence} {...readMode} />
                                </div>
                                <div className='row no-margin'>
                                    <Checkbox col={12} checked={borehole.gravitationalStation} label={i18n.gravitationalStation} {...readMode} />
                                </div>
                            </div>
                            <div className='col s6' >
                                <div className='row no-margin'>
                                    <Textarea col={12} value={installation.comments} title={i18n.comment} {...readMode} />
                                </div>
                                <div className='row no-margin'>
                                    <Checkbox col={12} label={i18n.waterTableAccompaniment} checked={borehole.waterTableAccompaniment} disabled {...readMode} />
                                </div>
                                <div className='row no-margin padding-top-1'>
                                    <Select col={12} value={borehole.watershed} label={i18n.watershed} options={this.props.watersheds} keyValue='id' displayWithCode {...readMode} />
                                </div>
                                <div className='row no-margin'>
                                    <Select col={12} value={this.getAquifer(borehole)} label={i18n.aquifer} options={this.props.aquifers} {...readMode} />
                                </div>
                                <div className='row no-margin'>
                                    <Select col={12} value={borehole.fieldMode} label={i18n.fieldMode} options={this.props.fieldModes} {...readMode} />
                                </div>
                                <div className='row no-margin'>
                                    <Input col={12} value={borehole.surfaceGeology} title={i18n.surfaceGeology} {...readMode} />
                                </div>
                                <div className='row no-margin'>
                                    <NumberField col={12} title={i18n.pumpDepth} value={borehole.pumpDepth ? borehole.pumpDepth + i18n.metersSymbol : null} floatValue {...readMode} />
                                </div>
                                <div className='row no-margin'>
                                    <NumberField col={12} title={i18n.drillingBlowRate} value={borehole.drillingBlowRate ? `${borehole.drillingBlowRate} m3/h` : null} floatValue {...readMode} />
                                </div>
                                <div className='row no-margin'>
                                    <NumberField col={12} title={i18n.overflowRating} value={borehole.overflowRating} floatValue {...readMode} />
                                </div>
                            </div>
                        </div>
                    </WhiteCard>
                    <UnitChartPanel unit={this.props.installation} uncheckedStations={this.state.uncheckedStations} setDataLoaded={this.props.setDataLoaded} fromStationType='INST' dontShowEmptyCharts />
                    <InstallationLinkedProduction />
                    <div className='row no-margin'>
                        <div className='col s4 no-padding-left' >
                            <BoreholeDrillingDashboard {...boreholeParams} />
                        </div>
                        <div className='col s4 no-padding-left' >
                            <BoreholeCasingDashboard {...boreholeParams} />
                        </div>
                        <div className='col s4 no-padding-left' >
                            <BoreholeCommentDashboard {...boreholeParams} />
                        </div>
                    </div>
                    <InstallationArrangementDashboard {...params} />
                    <Grid container justifyContent='space-between' spacing={2}>
                        {
                            !!borehole?.link_lithology?.length && (
                                <Grid item xs>
                                    <BoreholeLithologyDashboard {...boreholeParams} />
                                </Grid>
                            )
                        }
                        {
                            !!borehole?.link_aquifers?.length && (
                                <Grid item xs>
                                    <BoreholeAquiferDashboard {...boreholeParams} />
                                </Grid>
                            )
                        }
                        {
                            (some(['superficialWaterIsolation', 'supIsolationToVerify', 'nonIsolatedWaterTables', 'isolatedWaterTables', 'isolationToVerify'], val => hasBooleanValue(borehole[val])) || hasValue(borehole.isolationComment)) && (
                                <Grid item xs>
                                    <WhiteCard round>
                                        <div className='row no-margin'>
                                            <Checkbox col={12} label={i18n.superficialWaterIsolation} checked={borehole.superficialWaterIsolation} disabled {...readMode} />
                                        </div>
                                        <div className='row no-margin'>
                                            <Checkbox col={12} label={i18n.supIsolationToVerify} checked={borehole.supIsolationToVerify} disabled {...readMode} />
                                        </div>
                                        <div className='row no-margin'>
                                            <Checkbox col={12} label={i18n.nonIsolatedWaterTables} checked={borehole.nonIsolatedWaterTables} disabled {...readMode} />
                                        </div>
                                        <div className='row no-margin'>
                                            <Checkbox col={12} label={i18n.isolatedWaterTables} checked={borehole.isolatedWaterTables} disabled {...readMode} />
                                        </div>
                                        <div className='row no-margin'>
                                            <Checkbox col={12} label={i18n.isolationToVerify} checked={borehole.isolationToVerify} disabled {...readMode} />
                                        </div>
                                        <div className='row no-margin padding-top-1'>
                                            <Textarea col={12} value={borehole.isolationComment} title={i18n.isolationComment} {...readMode} />
                                        </div>
                                    </WhiteCard>
                                </Grid>
                            )
                        }
                    </Grid>
                </div>
                <div className='col s3'>
                    <StationUpdatePanel station={installation} />
                    {this.getDiagnosticAlertPanel()}
                    <Card round displayIf={some(['dataOrigin', 'arrestCode', 'arrestDate', 'endDate', 'closeComment', 'confidential'], key => hasValue(borehole[key]) || hasValue(installation[key]))}>
                        {this.getOriginIcon(installation)}
                        <div className='row no-margin'>
                            <Input col={12} title={i18n.dataOrigin} value={installation.dataOrigin} {...readMode} />
                        </div>
                        <div className='row no-margin'>
                            <Select col={12} label={[this.getOriginStatusColor(installation), i18n.status]} value={installation.status}
                                options={this.props.status} {...readMode}
                            />
                        </div>
                        <div className='row no-margin'>
                            <DatePicker col={12} value={installation.statusDate} id='statusDate' title={i18n.controlDoneAt} {...readMode} />
                        </div>
                        <div className='row no-margin'>
                            <Select col={12} label={i18n.controlDoneBy} value={installation.statusLogin}
                                options={this.props.users.map(u => ({ code: u.login, name: u.name || u.login }))} {...readMode}
                            />
                        </div>
                        <div className='row no-margin'>
                            <Input col={12} title={i18n.arrestCode} value={borehole.arrestCode} {...readMode} />
                        </div>
                        <div className='row no-margin'>
                            <DatePicker col={12} value={borehole.arrestDate} id='arrestDate' title={i18n.arrestDate} {...readMode} />
                        </div>
                        <div className='row no-margin'>
                            <NumberField col={12} title={i18n.arrestValidityDuration} value={borehole.arrestValidityDuration} floatValue {...readMode} />
                        </div>
                        <div className='row no-margin'>
                            <DatePicker col={12} value={installation.endDate} id='deliberationDate' title={i18n.deliberationDate} {...readMode} />
                        </div>
                        <div className='row no-margin'>
                            <Input col={12} value={installation.closeComment} title={i18n.become} {...readMode} />
                        </div>
                        <div className='row no-margin'>
                            <Checkbox componentClassName='padding-bottom-1' col={12} label={i18n.confidential} {...readMode}
                                checked={hasValue(installation.confidential) ? installation.confidential == '1' : null} disabled
                            />
                        </div>
                    </Card>
                    {!!associatedSites.length && (
                        <Row className='padding-top-1'>
                            <ThresholdIndicators />
                        </Row>
                    )}
                    <Row className='padding-top-1'>
                        <ContactCard
                            title={i18n.contact}
                            contactCode={installation.ownerCode}
                            onChange={v => this.onChangeInstallation({ ownerCode: v })}
                            {...readMode}
                        />
                    </Row>
                    <div className='padding-top-1' />
                    <Card round>
                        <div className='row no-margin padding-top-1'>
                            <Input col={12} title={i18n.address} value={installation.address} {...readMode} />
                        </div>
                        <div className='row no-margin'>
                            <Select col={12} value={installation.townCode} label={i18n.city} options={this.props.cities} keyValue='id' displayWithCode {...readMode} />
                        </div>
                        <div className='row no-margin'>
                            <NumberField col={6} title='X' value={installation.x} floatValue {...readMode} />
                            <NumberField col={6} title='Y' value={installation.y} floatValue {...readMode} />
                        </div>
                        <div className='row no-margin'>
                            <NumberField col={6} title='Z' value={installation.altitude} floatValue {...readMode} />
                            <Select col={6} label={i18n.projection} value={installation.projection}
                                options={getSandreList(this.props.sandreCodes, SANDRE.PROJECTION)} {...readMode}
                            />
                        </div>
                        <div className='row no-margin'>
                            <Input col={6} title={i18n.section} value={cartographyData.parcel} {...readMode} />
                            <Input col={6} title={i18n.parcel} value={cartographyData.section} {...readMode} />
                        </div>
                        <div className='row no-margin'>
                            <Input col={6} title={i18n.ignMap} value={cartographyData.ignMap} {...readMode} />
                            <Input col={6} title={i18n.geologicalMap} value={cartographyData.geologicalMap} {...readMode} />
                        </div>
                    </Card>
                    <div className='padding-top-1' />
                    <HabilitationRequired habilitation={H_MAT_MODULE}>
                        <div>
                            <MaterielAssigned stationId={this.props.installation.id} type={this.props.stationType} />
                            <div className='padding-top-1' />
                        </div>
                    </HabilitationRequired>
                    <LastEventPanel id={this.props.installation.id} events={this.props.installationEvents} stationId={this.props.installation.id}
                        stationType='installation'
                    />
                    <StationMapDashboardPanel noMarkerTooltip station={installation} type={'installation'} />
                    <div className='padding-top-1' />
                    <LinkedStationsPanel
                        onReMount={ this.props.onReMount }
                        dashboardMode
                        checkable
                        changeUncheckedStations={ tab => this.setState({ uncheckedStations: tab })}
                        station={this.props.installation}
                        withColors
                        hideTitle
                    />
                    <div className='padding-top-1' />
                </div>
            </div>
        )
    }
}

BoreholeDashboardPanel.propTypes = getPropTypes(storeProps, {
    setDataLoaded: PropTypes.func,
    onRemount: PropTypes.func,
    stationType: PropTypes.string,
})

const mapStateToProps = (store) => getMapStateToProps(storeProps, {
    associatedSites: store.StationReducer.associatedSites,
})

export default connect(mapStateToProps)(BoreholeDashboardPanel)