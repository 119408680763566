module.exports = {
    WINDOW: 'window',
    POINT: 'point',
    MOVING: 'moving',
    NEWPOINT: 'newPoint',
    VALIDATION: 'validation',
    HISTORIC: 'historic',
    PURGE: 'purge',
    IMPORT: 'import',
    COMPENSATION: 'compensation',
    CORRECTION: 'correction',
    OPTIONS: 'options',
    THRESHOLDS: 'thresholds',
    EVENT: 'event',
    TABLE: 'table',
    GRAPH: 'graph',
}