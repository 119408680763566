import AppStore from 'store/AppStore'
import DtoSandreCode from '../../referencial/dto/DtoSandreCode'
import { arrayOf, instanceOf } from '../../utils/StoreUtils'
import CmsAction from '../actions/CmsAction'
import EventsAction from '../actions/EventsAction'
import { RECEIVE_CMS_EVENTS, RECEIVE_CMS_MESSAGES } from '../constants/CMSEventConstants'
import {
    RECEIVE_ALL_PIEZOMETER_ACTIONS,
    RECEIVE_CMS_CATEGORIES,
    RECEIVE_CMS_DOCUMENT,
    RECEIVE_CMS_EVENT, RECEIVE_CMS_SURVEY,
    RECEIVE_CMS_SURVEY_ID, RECEIVE_DIAGNOSTICS_AND_SOLUTIONS,
    RECEIVE_CMS_MODEL,
    RECEIVE_ALL_CMS_MODEL,
    RECEIVE_DOCUMENTS_STATS,
    RECEIVE_DOCUMENTS_STATS_BY_MODULE,
    RECEIVE_DOCUMENTS_STATS_BY_USER,
    RECEIVE_EVENT,
    RECEIVE_EVENT_REPLACEMENT_ACTIONS, RECEIVE_EXPLOITATIONS_ALL_EVENTS, RECEIVE_HYDROMETRY_STATION_ALL_EVENTS,
    RECEIVE_INSTALLATION_ALL_EVENTS,
    RECEIVE_MONITORED_STATIONS_EVENTS,
    RECEIVE_PIEZOMETER_ACTIONS,
    RECEIVE_PIEZOMETER_DIAGNOSTICS,
    RECEIVE_PIEZOMETER_DIAGNOSTICS_LINK_EVENT_TYPES,
    RECEIVE_PIEZOMETER_DIAGNOSTICS_LINK_MATERIALS, RECEIVE_PIEZOMETER_SOLUTIONS, RECEIVE_PIEZOMETER_SOLUTION_LINK_PROBLEMS, RECEIVE_PIEZOMETRY_ALL_EVENTS,
    RECEIVE_QUALITOMETRY_ALL_EVENTS,
    RECEIVE_STATIONS_EVENTS,
    RECEIVE_STATION_EVENTS,
    RECEIVE_TYPES_CATEGORIES,
    RESET_CMS_EVENT,
    RESET_EVENT,
    RESET_STATION_EVENTS,
    UPDATE_CMS_CATEGORIES,
    RECEIVE_CMS_MODEL_STATIONS,
    RECEIVE_CMS_MODEL_OBSTACLES,
    RECEIVE_CMS_MODEL_TIDE_GAUGES,
    RECEIVE_CAUSE_INTERVENTION,
} from '../constants/EventsConstants'
import CMSCategoryDto from '../dto/CMSCategoryDto'
import CMSEventDto from '../dto/CMSEventDto'
import DtoCMSSurvey from '../dto/DtoCMSSurvey'
import DtoDocumentStatistic from '../dto/DtoDocumentStatistic'
import DtoEvent from '../dto/DtoEvent'
import DtoEventExploitation from '../dto/DtoEventExploitation'
import DtoEventHydrological from '../dto/DtoEventHydrological'
import DtoEventInstallation from '../dto/DtoEventInstallation'
import DtoEventPiezometer from '../dto/DtoEventPiezometer'
import DtoEventQualitometer from '../dto/DtoEventQualitometer'
import MonitoredStationEventDto from '../dto/MonitoredStationEventDto'
import DiagnosticActionDto from '../dto/piezometryDiagnostic/DiagnosticActionDto'
import DiagnosticDto from '../dto/piezometryDiagnostic/DiagnosticDto'
import DiagnosticLinkEventTypeDto from '../dto/piezometryDiagnostic/DiagnosticLinkEventTypeDto'
import DiagnosticLinkMaterialDto from '../dto/piezometryDiagnostic/DiagnosticLinkMaterialDto'
import ReplacementActionDto from '../dto/piezometryDiagnostic/ReplacementActionDto'
import SolutionDto from '../dto/piezometryDiagnostic/SolutionDto'
import SolutionLinkProblemDto from '../dto/piezometryDiagnostic/SolutionLinkProblemDto'
import { hasValue } from '../../utils/NumberUtil'
import DtoCmsModel from 'events/dto/DtoCmsModel'
import DtoCmsModelStation from 'events/dto/DtoCmsModelStation'
import DtoCmsModelObstacle from 'events/dto/DtoCmsModelObstacle'
import DtoCmsModelTideGauge from 'events/dto/DtoCmsModelTideGauge'
import DtoCMSMessage from 'events/dto/DtoCmsMessage'
import DtoCauseIntervention from 'events/dto/DtoCauseIntervention'

export function EventsReducer(state = {}, action) {
    switch (action.type) {
        case RECEIVE_PIEZOMETRY_ALL_EVENTS:
            return {
                ...state,
                piezometryAllEvents: action.piezometryAllEvents.map(e => new DtoEventPiezometer(e)),
            }
        case RECEIVE_TYPES_CATEGORIES:
            return {
                ...state,
                typesCategories: action.typesCategories.map(e => new DtoSandreCode(e)),
            }
        case RECEIVE_QUALITOMETRY_ALL_EVENTS:
            return {
                ...state,
                qualitometryAllEvents: action.qualitometryAllEvents.map(e => new DtoEventQualitometer(e)),
            }
        case RECEIVE_HYDROMETRY_STATION_ALL_EVENTS:
            return {
                ...state,
                hydrometryStationAllEvents: action.hydrometryStationAllEvents.map(e => new DtoEventHydrological(e)),
            }
        case RECEIVE_INSTALLATION_ALL_EVENTS:
            return {
                ...state,
                installationAllEvents: action.installationAllEvents.map(e => new DtoEventInstallation(e)),
            }
        case RECEIVE_EXPLOITATIONS_ALL_EVENTS:
            return {
                ...state,
                exploitationsAllEvents: action.exploitationsAllEvents.map(e => new DtoEventExploitation(e)),
            }
        case RECEIVE_CMS_EVENTS:
            return {
                ...state,
                cmsEvents: action.cmsEvents.map(n => new CMSEventDto(n)),
            }
        case RECEIVE_CMS_EVENT:
            return {
                ...state,
                cmsEvent: new CMSEventDto(action.cmsEvent),
            }
        case RECEIVE_CMS_DOCUMENT:
            return {
                ...state,
                document: action.document,
            }
        case RECEIVE_CMS_CATEGORIES:
            return {
                ...state,
                cmsCategories: action.categories.map(n => new CMSCategoryDto(n)),
            }
        case RECEIVE_CMS_MODEL:
            return {
                ...state,
                cmsModel: new DtoCmsModel(action.data),
            }
        case RECEIVE_ALL_CMS_MODEL:
            return {
                ...state,
                cmsModels: action.data.map(d => new DtoCmsModel(d)),
            }
        case RECEIVE_CMS_MODEL_STATIONS: {
            return {
                ...state,
                cmsModelStations: action.data.map(d => new DtoCmsModelStation(d)),
            }
        }
        case RECEIVE_CMS_MODEL_OBSTACLES: {
            return {
                ...state,
                cmsModelObstacles: action.data.map(d => new DtoCmsModelObstacle(d)),
            }
        }
        case RECEIVE_CMS_MODEL_TIDE_GAUGES: {
            return {
                ...state,
                cmsModelTideGauges: action.data.map(d => new DtoCmsModelTideGauge(d)),
            }
        }
        case UPDATE_CMS_CATEGORIES:
            return {
                ...state,
                cmsCategories: action.categories.map(n => new CMSCategoryDto(n)),
            }
        case RECEIVE_EVENT:
            return {
                ...state,
                event: new DtoEvent(action.event),
            }
        case RESET_EVENT:
            return {
                ...state,
                event: {},
            }
        case RESET_CMS_EVENT:
            return {
                ...state,
                cmsEvent: {},
            }
        case RECEIVE_STATION_EVENTS:
            return {
                ...state,
                stationEvents: action.events.filter(e => hasValue(e.date)).map(e => new DtoEvent(e)),
            }
        case RECEIVE_STATIONS_EVENTS:
            return {
                ...state,
                stationsEvents: action.payload.filter(e => hasValue(e.date)).map(e => new DtoEvent(e)),
            }
        case RECEIVE_MONITORED_STATIONS_EVENTS:
            return {
                ...state,
                monitoredStations: action.stationEvents.map(s => new MonitoredStationEventDto(s)),
            }
        case RESET_STATION_EVENTS:
            return {
                ...state,
                stationEvents: [],
            }
        case RECEIVE_PIEZOMETER_DIAGNOSTICS:
            return {
                ...state,
                eventPiezometerDiagnostics: action.piezometerDiagnostics.map(el => new DiagnosticDto(el)),
            }
        case RECEIVE_PIEZOMETER_DIAGNOSTICS_LINK_MATERIALS:
            return {
                ...state,
                eventPiezometerDiagnosticsLinkMaterials: action.piezometerDiagnosticsLinkMaterials.map(el => new DiagnosticLinkMaterialDto(el)),
            }
        case RECEIVE_PIEZOMETER_DIAGNOSTICS_LINK_EVENT_TYPES:
            return {
                ...state,
                eventPiezometerDiagnosticsLinkEventTypes: action.piezometerDiagnosticsLinkEventTypes.map(el => new DiagnosticLinkEventTypeDto(el)),
            }
        case RECEIVE_PIEZOMETER_SOLUTIONS:
            return {
                ...state,
                eventPiezometerSolutions: action.piezometerSolutions.map(el => new SolutionDto(el)),
            }
        case RECEIVE_PIEZOMETER_SOLUTION_LINK_PROBLEMS:
            return {
                ...state,
                eventPiezometerSolutionsLinkProblems: action.piezometerSolutionsLinkProblems.map(el => new SolutionLinkProblemDto(el)),
            }
        case RECEIVE_PIEZOMETER_ACTIONS:
            return {
                ...state,
                eventPiezometerActions: action.piezometerActions.map(el => new DiagnosticActionDto(el)),
            }
        case RECEIVE_ALL_PIEZOMETER_ACTIONS:
            return {
                ...state,
                eventAllPiezometerActions: action.piezometerActions.map(el => new DiagnosticActionDto(el)),
            }
        case RECEIVE_EVENT_REPLACEMENT_ACTIONS:
            return {
                ...state,
                eventPiezometerReplacementAction: action.eventPiezometerReplacementAction.map(el => new ReplacementActionDto(el)),
            }
        case RECEIVE_DIAGNOSTICS_AND_SOLUTIONS:
            return {
                ...state,
                eventPiezometerDiagnostics: action.piezometerDiagnostics.map(el => new DiagnosticDto(el)),
                eventPiezometerSolutions: action.piezometerSolutions.map(el => new SolutionDto(el)),
                eventPiezometerSolutionsLinkProblems: action.piezometerSolutionsLinkProblems.map(el => new SolutionLinkProblemDto(el)),
                eventPiezometerDiagnosticsLinkEventTypes: action.piezometerDiagnosticsLinkEventTypes.map(el => new DiagnosticLinkEventTypeDto(el)),
                eventPiezometerDiagnosticsLinkMaterials: action.piezometerDiagnosticsLinkMaterials.map(el => new DiagnosticLinkMaterialDto(el)),
            }
        case RECEIVE_DOCUMENTS_STATS:
            return {
                ...state,
                documentsStatistics: action.data.map((d) => new DtoDocumentStatistic(d)),
            }
        case RECEIVE_DOCUMENTS_STATS_BY_MODULE:
            return {
                ...state,
                moduleDocumentsStatistics: action.data.map((d) => new DtoDocumentStatistic(d)),
            }
        case RECEIVE_DOCUMENTS_STATS_BY_USER:
            return {
                ...state,
                userDocumentsStatistics: action.data.map((d) => new DtoDocumentStatistic(d)),
            }
        case RECEIVE_CMS_SURVEY:
            return {
                ...state,
                cmsSurvey: new DtoCMSSurvey(action.data),
            }
        case RECEIVE_CMS_SURVEY_ID:
            return {
                ...state,
                cmsSurveyId: action.data.idSurvey,
            }
        case RECEIVE_CMS_MESSAGES:
            return {
                ...state,
                cmsMessages: action.data.map(d => new DtoCMSMessage(d)),
            }
        case RECEIVE_CAUSE_INTERVENTION:
            return {
                ...state,
                causeInterventions: action.payload.map(ci => new DtoCauseIntervention(ci)),
            }
        default:
            return state
    }
}

export const store = {
    stationEvents: [],
    stationsEvents: [],
    cmsEvents: [],
    cmsEvent: {},
    cmsCategories: [],
    cmsModel: {},
    cmsModels: [],
    cmsModelStations: [],
    cmsModelObstacles: [],
    cmsModelTideGauges: [],
    event: {},
    document: {},
    typesCategories: [],
    monitoredStations: [],
    piezometryAllEvents: [],
    qualitometryAllEvents: [],
    installationAllEvents: [],
    hydrometryStationAllEvents: [],
    exploitationsAllEvents: [],
    eventPiezometerDiagnostics: [],
    eventPiezometerDiagnosticsLinkMaterials: [],
    eventPiezometerDiagnosticsLinkEventTypes: [],
    eventPiezometerSolutions: [],
    eventPiezometerSolutionsLinkProblems: [],
    eventPiezometerActions: [],
    eventPiezometerReplacementAction: [],
    eventAllPiezometerActions: [],
    causeInterventions: [],
    documentsStatistics: [],
    moduleDocumentsStatistics: [],
    userDocumentsStatistics: [],
    cmsSurvey: new DtoCMSSurvey({}),
    cmsSurveyId: null,
    cmsMessages: [],
}

export const EventsStoreInfos = () => {
    const appStore = AppStore.getState()
    return {
        stationEvents: {
            fetch: EventsAction.fetchStationEvents,
            reset: EventsAction.resetStationEvents,
            store: appStore.EventsReducer.stationEvents,
            propType: arrayOf(DtoEvent),
        },
        cmsEvent: {
            fetch: CmsAction.fetchCMSEvent,
            store: appStore.EventsReducer.cmsEvent,
            propType: arrayOf(CMSEventDto),
        },
        cmsEvents: {
            fetch: CmsAction.fetchCMSEvents,
            store: appStore.EventsReducer.cmsEvents,
            update: CmsAction.updateCMSEvent,
            delete: CmsAction.deleteCMSEvent,
            propType: arrayOf(CMSEventDto),
        },
        cmsCategories: {
            fetch: CmsAction.fetchCMSCategories,
            store: appStore.EventsReducer.cmsCategories,
            propType: arrayOf(CMSCategoryDto),
            update: CmsAction.updateCMSCategory,
            delete: CmsAction.deleteCMSCategory,
        },
        event: {
            fetch: EventsAction.fetchEvent,
            store: appStore.EventsReducer.event,
            add: EventsAction.addEvent,
            reset: EventsAction.resetEvent,
            propType: instanceOf(DtoEvent),
            update: EventsAction.updateEvent,
        },
        monitoredStations: {
            fetch: EventsAction.fetchMonitoredStations,
            store: appStore.EventsReducer.monitoredStations,
            propType: arrayOf(MonitoredStationEventDto),
        },
    }
}
