import { round } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import Checkbox from '../../../../components/forms/Checkbox'
import Input from '../../../../components/forms/Input'
import NumberField from '../../../../components/forms/NumberField'
import Select from '../../../../components/forms/Select'
import Textarea from '../../../../components/forms/Textarea'
import { getDefaultValuesChoiceModal, getMatTypes } from '../../../../utils/MaterielUtils'
import GenericReferencialDto from '../../../dto/GenericReferencialDto'
import PowerSupplyTypeDto from '../../powerSupply/dto/PowerSupplyTypeDto'
import SensorDto from '../dto/SensorDto'

const SensorEquipmentPanel = ({
    active = false,
    disabled = true,
    sensor = {},
    displaySupply = true,
    sensorTypes = [],
    powerSupplyTypes = [],
    onChange = () => { },
}) => (
    <div className='col s12 margin-bottom-1'>
        <div className='row no-margin' style={{ paddingTop: '3px' }}>
            <Select
                options={getMatTypes(sensorTypes, sensor.sensorType)}
                label={i18n.type}
                col={4}
                onChange={value => {
                    onChange({ sensorType: value })
                    getDefaultValuesChoiceModal(sensorTypes.find(c => c.materielType == value), onChange)
                }}
                value={sensor.sensorType}
                nullLabel='&nbsp;'
                active={active}
                disabled={disabled}
            />
        </div>
        <div className='row no-margin' style={{ paddingTop: '3px' }}>
            <Input
                col={6}
                title={i18n.scaleInBar}
                value={sensor.range}
                onChange={value => onChange({ range: value })}
                active={active}
                disabled={disabled}
            />
            <NumberField
                col={6}
                title={i18n.lengthInMetter}
                value={sensor.length}
                onChange={value => onChange({ length: round(value, 2) })}
                active={active}
                disabled={disabled}
                floatValue
            />
        </div>
        <div className={`row no-margin ${displaySupply && 'valign-wrapper' || ''}`} style={{ paddingTop: '3px' }}>
            {
                displaySupply && (
                    <div className='col s9'>
                        <Select
                            options={getMatTypes(powerSupplyTypes, sensor.powerSupplyType)}
                            label={i18n.powerSupplyType}
                            col={4}
                            onChange={value => onChange({ powerSupplyType: value })}
                            value={sensor.powerSupplyType}
                            nullLabel='&nbsp;'
                            active={active}
                            disabled={disabled}
                        />
                        <NumberField
                            col={4}
                            title={i18n.batteryCount}
                            value={sensor.pileNumber}
                            onChange={value => onChange({ pileNumber: value })}
                            active={active}
                            disabled={disabled}
                        />
                        <NumberField
                            col={4}
                            title={i18n.supplyTime}
                            value={sensor.supplyTime}
                            onChange={value => onChange({ supplyTime: value })}
                            active={active}
                            disabled={disabled}
                        />
                    </div>
                )
            }
            <Checkbox
                col={3}
                label={i18n.desiccant}
                checked={sensor.desiccant === '1'}
                onChange={e => onChange({ desiccant: e ? '1' : '0' })}
                active={active}
                disabled={disabled}
            />
        </div>
        <div className='row no-margin' style={{ paddingTop: '8px' }}>
            <Textarea
                col={12}
                title={i18n.comment}
                value={sensor.comment}
                onChange={value => onChange({ comment: value })}
                active={active}
                disabled={disabled}
            />
        </div>
    </div>
)

SensorEquipmentPanel.propTypes = {
    disabled: PropTypes.bool,
    active: PropTypes.bool,
    onChange: PropTypes.func,
    displaySupply: PropTypes.func,
    sensor: PropTypes.instanceOf(SensorDto),
    sensorTypes: PropTypes.instanceOf(GenericReferencialDto),
    powerSupplyTypes: PropTypes.arrayOf(PropTypes.instanceOf(PowerSupplyTypeDto)),
}

const mapStateToProps = store => ({
    sensorTypes: store.SensorReducer.sensorTypes,
    powerSupplyTypes: store.PowerSupplyReducer.powerSupplyTypes,
})

export default connect(mapStateToProps)(SensorEquipmentPanel)
