import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import DtoQualityThreshold from 'quality/dto/QualityThreshold/DtoQualityThreshold'
import { groupBy, isUndefined, mean, orderBy, range, round, uniqBy } from 'lodash'
import { shallowEqual, useSelector } from 'react-redux'
import useListIndexed from 'utils/customHook/useListIndexed'
import i18n from 'simple-react-i18n'
import { THRESHOLD_TYPE, getRemarks, getTabColorsBySize, getThresholdType } from 'utils/AnalyseUtils'
import { DEFAULT_NB_POINT_MOVING_AVERAGE } from 'quality/constants/ChartConstant'
import QualityChart from 'components/echart/QualityChart'

const getColorTab = (paramThreshold, thresholdType) => {
    if (!paramThreshold) {
        return []
    }
    if (thresholdType === THRESHOLD_TYPE.PH || thresholdType === THRESHOLD_TYPE.CONDUCTIVITY) {
        return ['red', 'blue', 'red']
    }
    const allColorTab = getTabColorsBySize()
    const nbThresholds = [1, 2, 3, 4].filter(nb => !isUndefined(paramThreshold[`threshold${nb}`])).length + 1
    return allColorTab[nbThresholds]
}

const MultiParameterGraph = ({
    analysis = [],
    thresholds = [],
    graphOptions = {},

    dataZoomPosition = { bottom: 40 },
    legendPosition = { bottom: 10, right: 20, top: undefined },
    toolboxPosition = { top: 5 },
}) => {
    const {
        qualitometers,
        parameters,
        units,
    } = useSelector(store => ({
        qualitometers: store.QualityReducer.qualitometersLight,
        parameters: store.ParameterReducer.parameters,
        units: store.UnitReducer.units,
    }), shallowEqual)

    const {
        // regroupAxis = false,
        graphicTitle,

        displayAverages = false,
        nbPointMovingAverage = DEFAULT_NB_POINT_MOVING_AVERAGE,
        // displayHype = false,

        minY,
        maxY,
        maxXAxisSpace,
        showXSplitLines,
        showYSplitLines,

        parameters: orderedParameters,
        stationsOptions,
    } = graphOptions

    const unitsIndex = useListIndexed(units, 'code')
    const parametersIndex = useListIndexed(parameters, 'code')
    const qualitometersIndex = useListIndexed(qualitometers, 'id')

    const grids = orderedParameters.map((param, index) => ({
        top: index === 0 ? 10 : 50,
        height: 200,
        gridId: param,
    }))

    const xAxis = orderedParameters.map(param => ({
        splitLine: { show: showXSplitLines },
        axisLabel: { rotate: 0 },

        gridId: param,
        xAxisId: param,
    }))

    const yAxis = orderedParameters.map(param => {
        const parameterLabel = parametersIndex[param]?.displayName
        const uniqUnits = uniqBy(analysis.filter(a => a.parameter === param), 'unit').map(a => a.unit)
        const unitsLabel = uniqUnits.map(unit => unitsIndex[unit]?.symbol).filter(u => !!u).join(', ')

        return {
            name: `${parameterLabel}\n(${unitsLabel})`,
            // nameGap: 60,
            splitLine: { show: showYSplitLines },

            min: minY,
            max: maxY,

            gridId: param,
            yAxisId: param,
        }
    })

    const analysisGroup = groupBy(analysis, 'parameter')

    const data = orderedParameters.flatMap(param => {
        const analysisFiltered = analysisGroup[param] || []
        const groupByStation = groupBy(analysisFiltered, 'qualitometer')

        return Object.keys(groupByStation).map(key => {
            const qualitoAnalysis = groupByStation[key]
            const stationId = qualitoAnalysis[0].qualitometer

            const qualitometer = qualitometersIndex[stationId]

            const stationOptions = stationsOptions.find(o => o.id === stationId)

            return {
                name: qualitometer?.code,
                color: stationOptions?.color || undefined,
                dataList: qualitoAnalysis.map(a => ({
                    date: a.sampleDate,
                    value: a.result,
                    unit: unitsIndex[a.unit]?.symbol || i18n.unknownUnit,
                    marker: getRemarks(a.remark)?.plot,
                    color: a.color === 'white' ? 'black' : a.color,
                })),
                xAxisId: param,
                yAxisId: param,
            }
        })
    })

    const thresholdFormatted = useMemo(() => {
        const allColorTab = getTabColorsBySize()

        return orderedParameters.flatMap(param => {
            const paramThreshold = thresholds.find(t => t.parameterCode === param)
            if (isUndefined(paramThreshold)) {
                return []
            }

            const thresholdType = getThresholdType(paramThreshold)
            if (thresholdType === THRESHOLD_TYPE.PH || thresholdType === THRESHOLD_TYPE.CONDUCTIVITY) {
                return [{
                    xAxisId: param,
                    yAxisId: param,
                    dataList: [
                        { value: paramThreshold.threshold1, color: 'red', labelColor: 'black' },
                        { value: paramThreshold.threshold2, color: 'red', labelColor: 'black' },
                    ],
                    colorList: getColorTab(paramThreshold, thresholdType),
                }]
            }

            const nbThresholds = [1, 2, 3, 4].filter(nb => !isUndefined(paramThreshold[`threshold${nb}`])).length + 1
            const colorTab = allColorTab[nbThresholds]

            return [{
                xAxisId: param,
                yAxisId: param,
                dataList: [1, 2, 3, 4].map(nb => ({
                    value: paramThreshold[`threshold${nb}`],
                    color: colorTab[nb],
                    labelColor: 'black',
                })).filter(t => !!t.value),
                colorList: getColorTab(paramThreshold, thresholdType),
            }]
        })
    }, [thresholds, orderedParameters])

    const movingAverage = useMemo(() => {
        if (!displayAverages) {
            return []
        }
        return orderedParameters.flatMap(param => {
            const analysisFiltered = analysisGroup[param] || []
            const groupByStation = groupBy(analysisFiltered, 'qualitometer')

            return Object.keys(groupByStation).map(key => {
                const orderedAnalysis = orderBy(groupByStation[key], 'sampleDate')
                const { qualitometer: stationId } = groupByStation[key][0]
                const qualitometer = qualitometersIndex[stationId]

                const dataList = orderedAnalysis.flatMap((a, i) => {
                    if (i < nbPointMovingAverage || i >= (orderedAnalysis.length - nbPointMovingAverage)) {
                        return []
                    }
                    const rangeList = range(i - nbPointMovingAverage, i + nbPointMovingAverage + 1)
                    const average = mean(rangeList.map(idx => orderedAnalysis[idx].result))
                    return { date: a.sampleDate, value: round(average, 3) }
                })

                const stationOptions = stationsOptions.find(o => o.id === stationId)

                return {
                    name: qualitometer?.code,
                    color: stationOptions?.color,
                    lineStyle: { type: 'dotted' },
                    dataList,
                    xAxisId: param,
                    yAxisId: param,
                }
            })
        })
    }, [analysisGroup, displayAverages, nbPointMovingAverage, qualitometersIndex, stationsOptions, orderedParameters])

    return (
        <QualityChart
            title={graphicTitle}
            exportName={graphicTitle || i18n.follow}
            grids={grids}
            xAxis={xAxis}
            yAxis={yAxis}
            series={[...data, ...movingAverage]}
            thresholds={thresholdFormatted}

            headerHeight={0}
            footerHeight={120}
            // xAxisSpace={xAxisSpace}
            maxXAxisSpace={maxXAxisSpace}

            roundValue={3}

            withToolTypeLine
            withToolTypeBar
            // withToolTypeStack
            withToolLog
            withToolThreshold
            withToolMarker
            withToolLine
            withDataZoom
            withToolLegend
            withArea

            dataZoomPosition={dataZoomPosition}
            legendPosition={legendPosition}
            toolboxPosition={toolboxPosition}
        />
    )
}

MultiParameterGraph.propTypes = {
    analysis: PropTypes.arrayOf(PropTypes.shape({/* ...DtoAnalysis, ...calculateThresholdResult */ })),

    thresholds: PropTypes.arrayOf(PropTypes.instanceOf(DtoQualityThreshold)),

    graphOptions: PropTypes.shape({
        regroupAxis: PropTypes.bool,
        displayStatistics: PropTypes.bool,
        displayAverages: PropTypes.bool,
        nbPointMovingAverage: PropTypes.number,
        displayHype: PropTypes.bool,
    }),

    toolboxPosition: PropTypes.shape({}),
    dataZoomPosition: PropTypes.shape({}),
    legendPosition: PropTypes.shape({}),
}

export default MultiParameterGraph