export default class DtoExportPollutantsEmissions {
    constructor(obj) {
        this.categoryActivity = obj[0]
        this.dataSource = obj[1]
        this.activityCode = obj[2]
        this.nbInstitutions = obj[3]
        this.parameterCode = obj[4]
        this.name = obj[5]
        this.flowKgDayCompany = obj[6]
        this.flowKgDayEmployee = obj[7]
        this.nbEmployees = obj[8]
        this.totalFlowsByEmployee = obj[9]
        this.totalFlowsByCompanies = obj[10]
        this.totalEmissionsByEstablishments = obj[11]
    }
}