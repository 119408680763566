import React from 'react'
import PropTypes from 'prop-types'
import SimpleTextArea from 'components/forms/SimpleTextArea'
import { shallowEqual, useSelector } from 'react-redux'
import DtoPicture from 'station/dto/DtoPicture'
import { Card, Grid, Typography } from '@mui/material'
import Icon from 'components/icon/Icon'
import i18n from 'simple-react-i18n'
import { getDocumentTypeIcon, getFileExtension, getFileName } from 'utils/FileUtils'
import { mainBlue } from 'utils/constants/ColorTheme'
import { SANDRE } from 'referencial/constants/ReferencialConstants'
import Fancybox from 'components/fancybox/Fancybox'
import DtoFile from 'components/file/dto/DtoFile'
import { PICTURE } from 'home/constants/RouteConstants'
import { DOCUMENT } from 'components/file/constant/FileConstants'
import DtoVisitActionDoc from '../dto/DtoVisitActionDoc'

const VisitCardFile = ({
    doc = {},
    file = {},
    fileType = PICTURE,
    onClickDelete = () => { },
    onChangeDocument = () => { },
    readMode = true,
}) => {
    const {
        installation,
    } = useSelector(store => ({
        installation: store.InstallationReducer.installation,
    }), shallowEqual)
    const fileExtension = getFileExtension(file.name)
    const FILE_TYPE = {
        picture: {
            sandreCode: SANDRE.TYPE_PHOTO,
            fileTypeName: i18n.pictureType,
            name: PICTURE,
            content: <img src={file.url} style={{ objectFit: 'cover', maxWidth: '100%', height: '100%', width: '400px' }} />,
        },
        document: {
            sandreCode: SANDRE.TYPE_DOCUMENT,
            fileTypeName: i18n.documentType,
            name: DOCUMENT,
            content: <img src={getDocumentTypeIcon(fileExtension)} style={{ maxWidth: '50%', height: 'auto', textAlign: 'center' }} />,
        },
    }
    const fileTypeConstant = FILE_TYPE[fileType]
    const displayName = file.isAllDataName && installation?.code === file.stationCode ? file.shortName : getFileName(file.name)

    return (
        <Grid style={{ position: 'relative' }}>
            {!readMode && (
                <div style={{ position: 'absolute', top: 5, right: 6 }}>
                    <Icon
                        size='small'
                        style={{ color: 'black' }}
                        icon='cancel'
                        onClick={() => onClickDelete({ name: file.name, fileType: fileTypeConstant.name, stationType: 'installation' })}
                        tooltip={i18n.delete}
                    />
                </div>
            )}
            <Card sx={{ height: '130px', border: `solid 2px ${mainBlue}` }}>
                <Grid container sx={{ height: '100%' }} >
                    <Grid item xs={4}>
                        <Fancybox>
                            <a href={file.url} target='_blank' data-fancybox={fileType == PICTURE ? 'images' : undefined} data-caption={displayName} style={{ width: '100%' }}>
                                <div style={{ display: 'flex', alignItems: 'center', height: '100%', justifyContent: 'center' }}>
                                    {fileTypeConstant.content}
                                </div>
                            </a>
                        </Fancybox>
                    </Grid>
                    <Grid item xs={8} sx={{ padding: '5 10px' }}>
                        <Typography
                            style={{
                                fontWeight: 'bold',
                                paddingTop: '5px',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                            }}
                            variant='subtitle1'
                            color='text.secondary'
                            component='div'
                        >
                            {displayName}
                        </Typography>
                        <Typography variant='subtitle1' color='text.secondary' component='div'>
                            {i18n.type} : {fileExtension}
                        </Typography>
                        <Typography variant='subtitle1' color='text.secondary' component='div' sx={{ marginTop: !readMode && '-10px' || 0 }}>
                            <SimpleTextArea
                                value={doc.description}
                                disabled={readMode}
                                onChange={onChangeDocument}
                                readMode={readMode}
                            />
                        </Typography>
                    </Grid>
                </Grid>
            </Card>
        </Grid>
    )
}

VisitCardFile.propTypes = {
    doc: PropTypes.instanceOf(DtoVisitActionDoc),
    file: PropTypes.oneOfType([
        PropTypes.instanceOf(DtoFile),
        PropTypes.instanceOf(DtoPicture),
    ]),
    fileType: PropTypes.string,
    onClickDelete: PropTypes.func,
    onChangeDocument: PropTypes.func,
    readMode: PropTypes.bool,
}

const VisitPicture = ({
    doc = {},
    onDeleteDocument = () => { },
    onChangeDocument = () => { },
    readMode = false,
    smallPicture = false,
}) => {
    const {
        files,
        pictures,
        installation,
    } = useSelector(store => ({
        pictures: store.StationReducer.pictures,
        files: store.StationReducer.files,
        installation: store.InstallationReducer.installation,
    }), shallowEqual)

    const extension = getFileExtension(doc.docName)
    const stationFiles = [...files, ...pictures]
    const file = stationFiles.find(sf => sf.name === doc.docName)
    const formattedFile = file || (extension === 'pdf' ?
        new DtoFile({
            name: doc.docName,
            stationCode: installation.code,
        }) :
        new DtoPicture({
            name: doc.docName,
            stationCode: installation.code,
        }))

    return (
        <Grid item xs={smallPicture ? 3 : 6}>
            <VisitCardFile
                file={formattedFile}
                fileType={formattedFile.fileType}
                onClickDelete={onDeleteDocument}
                onChangeDocument={onChangeDocument}
                doc={doc}
                readMode={readMode}
            />
        </Grid>
    )
}

VisitPicture.propTypes = {
    doc: PropTypes.instanceOf(DtoVisitActionDoc),
    onDeleteDocument: PropTypes.func,
    onChangeDocument: PropTypes.func,
    readMode: PropTypes.bool,
    col: PropTypes.number,
    smallPicture: PropTypes.bool,
}

export default VisitPicture
