import DtoMaterielType from '../../../dto/DtoMaterielType'
import { MAT_SIM } from '../../../constants/MaterielConstants'
import i18n from 'simple-react-i18n'

export default class DtoSimType extends DtoMaterielType {
    constructor(obj) {
        super(obj)
        this.materielCategory = MAT_SIM
        this.materielLabel = i18n.sim
    }
}
