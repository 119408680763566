export default class CompanyOwnerDto {
    constructor(obj) {
        this.id = obj.id // Int,
        this.contributorCode = obj.contributorCode // Option[Int],
        this.name = obj.name // Option[String],
        this.comment = obj.comment // Option[String],
        this.address = obj.address // Option[String],
        this.additionalAddress = obj.additionalAddress // Option[String],
        this.postalCode = obj.postalCode // Option[String],
        this.cityCode = obj.cityCode // Option[String],
        this.usualCityName = obj.usualCityName // Option[String],
        this.propertyType = obj.propertyType // Option[Int],
        this.siret = obj.siret // Option[String]
    }
}