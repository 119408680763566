import React, { useEffect, useMemo, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import { mapValues, orderBy } from 'lodash'
import Row from '../../../../components/react/Row'
import Card from '../../../../components/card/Card'
import { getFullDateMini } from '../../../../utils/DateUtil'
import { formatMilliers, getI18nTitleData, getSandreLabel, searchAllCharacters } from '../../../../utils/StringUtil'
import useTitle from 'utils/customHook/useTitle'
import ReferencialAction from 'referencial/action/ReferencialAction'
import { Button } from '@mui/material'
import CultureAction from '../actions/CultureAction'
import Input from '../../../../components/forms/Input'
import NumberField from '../../../../components/forms/NumberField'
import VirtualizedTable from '../../../../components/datatable/virtualizedTable/VirtualizedTable'
import useActions from 'utils/customHook/useActions'
import ImportFileContent from 'import/components/content/ImportFileContent'
import { getDataWithHeaders } from 'utils/ExportDataUtil'
import ProgressCard from 'components/card/ProgressCard'
import JobAction from 'import/actions/JobAction'
import FileUploadInput from 'import/dto/FileUploadInput'
import ToastrAction from 'toastr/actions/ToastrAction'
import moment from 'moment'

const headers = ['idParcel', 'idIGN', 'year', 'department', 'cultureRPG', 'typeSuccession', 'area', 'user', 'date']
const headersCsv = ['ID RPG Explorer*', 'ID Parcelle RPG*', 'Année*', 'Département*', 'Culture*', 'Type de succession*', 'Surface*']

const RPGApp = () => {
    const {
        sandreCodes,
        rpg,
    } = useSelector(store => ({
        sandreCodes: store.ReferencialReducer.sandreCodes,
        rpg: store.CultureReducer.rpg,
    }), shallowEqual)

    const [loaded, setLoaded] = useState(false)
    const [filter, setFilter] = useState({ year: moment().year() })
    const [filterTmp, setFilterTmp] = useState({ year: moment().year() })
    const [fileContent, setFileContent] = useState('')

    const dispatch = useDispatch()

    const onImportFile = (e) => {
        const reader = new FileReader()
        const file = e.target.files[0]
        reader.onload = upload => setFileContent(upload.target.result)
        reader.readAsDataURL(file)
    }

    const getFileContent = (title, subtitle, extension) => (
        <ImportFileContent
            title={title}
            subtitle={subtitle}
            extension={extension}
            headersCsv={headersCsv}
            onImportFile={f => onImportFile(f)}
        />
    )

    const dataRPG = useMemo(() => orderBy(rpg, ['year', 'department', 'idParcel']).map(d => ({
        ...d,
        cultureRPG: d.codeRPG,
        typeSuccession: getSandreLabel(sandreCodes, 'AGRI_RPG.TYPE_SUCCESSION', d.typeSuccession),
        user: d.usermaj,
        date: getFullDateMini(d.datemaj),
    })), [rpg, sandreCodes])

    const data = useMemo(() => {
        return dataRPG
            .filter((obj) => searchAllCharacters(headers.map((key) => obj[key]).join())
                .includes(searchAllCharacters(filter.searchValue || '')))
    }, [dataRPG, filter])

    const colorData = useMemo(() => data.map(u => ({ ...mapValues(u, o => ({ value: o })), fieldLink: u.field, id: u.code })), [data])

    useActions(() => {
        return {
            referencialActions: {
                actions: {
                    export: {
                        data: getDataWithHeaders(colorData, headers),
                        titleFile: 'RPG',
                    },
                    sync: {
                        onImportCsv: () => dispatch(JobAction.uploadFile(new FileUploadInput({
                            stationType: 0,
                            stationId: 0,
                            path: 'DATA/',
                            name: 'importDataRPG.csv',
                            content: fileContent,
                        }), true)).then(() => JobAction.promiseExecuteJob(-15)).then(() => dispatch(ToastrAction.info(i18n.jobDetailsMessage, i18n.jobProgressMessage))),
                        csvContent: getFileContent(i18n.csvSyncChoice, i18n.importCsvZanderReference, '.csv'),
                        fileContentIsValid: !!fileContent,
                    },
                },
            },
        }
    }, [colorData, fileContent])

    useTitle(() => [{
        title: i18n.referencials,
        href: '/referencial',
    }, {
        title: 'RPG Explorer',
        href: '/referencial/rpg',
    }], [])

    useEffect(() => {
        setLoaded(false)
        dispatch(ReferencialAction.fetchSandreCodes())
        dispatch(CultureAction.fetchRPG(filter.year)).then(() => setLoaded(true))
    }, [filter])

    return (
        <>
            <Card noMargin={false} className='margin-left-1 margin-right-1' title={i18n.criterias}>
                <Row className='padding-top-1 padding-bottom-5-px'>
                    <Input
                        col={3}
                        title={i18n.search}
                        value={filterTmp.searchValue}
                        onChange={v => setFilterTmp({ ...filterTmp, searchValue: v })}
                        onEnterKeyPress={() => setFilter({ ...filterTmp })}
                    />
                    <NumberField
                        col={3}
                        title={i18n.year}
                        value={filterTmp.year}
                        onChange={v => setFilterTmp({ ...filterTmp, year: v })}
                        onEnterKeyPress={() => setFilter({ ...filterTmp })}
                    />
                    <div className='col s6 padding-top-1'>
                        <Button className='right' onClick={() => setFilter({ ...filterTmp })} variant='contained' color='primary'>
                            {i18n.search}
                        </Button>
                    </div>
                </Row>
            </Card>
            <div className='margin-1 padding-top-5-px referencial-table'>
                {loaded ? (
                    <Card title={`RPG Explorer (${formatMilliers(colorData.length)} ${getI18nTitleData(i18n.element, i18n.elements, colorData)})` }>
                        <VirtualizedTable
                            data={colorData}
                            headers={headers}
                        />
                    </Card>
                ) : <ProgressCard indeterminate withMessage />}
            </div>
        </>
    )
}

export default RPGApp