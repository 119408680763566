/* eslint-disable camelcase */
export default class DtoIntervenantDeclaration {
    constructor(object) {
        this.surveyId = object.surveyId // Long
        this.idExploitation = object.idExploitation // Option[Long] // @deprecated("now in link_contributorTypes" "1.2.223") TO DELETE
        this.idContributor = object.idContributor || object.id // Long
        this.contributorType = object.contributorType // Option[Long] // @deprecated("now in link_contributorTypes" "1.2.223") TO DELETE
        this.name = object.name // Option[String]
        this.postalBox = object.postalBox // Option[String]
        this.road = object.road || object.address // Option[String]
        this.address = object.address || object.road // Option[String]
        this.addressComplement = object.addressComplement // Option[String]
        this.road = object.road // Option[String]
        this.postalCode = object.postalCode || object.postalBox // Option[String]
        this.statePlace = object.statePlace // Option[String]
        this.email = object.email // Option[String]
        this.phoneTel = object.phoneTel // Option[String]
        this.phoneTelSecond = object.phoneTelSecond // Option[String]
        this.fax = object.fax // Option[String]
        this.mobile = object.mobile // Option[String]
        this.cityCode = object.cityCode // Option[String]
        this.activityStartDate = object.activityStartDate // Option[DateTime]
        this.activityEndDate = object.activityEndDate // Option[DateTime]
        this.mode = object.mode // Option[String] // @deprecated("now in link_contributorTypes" "1.2.223") TO DELETE
        this.siret = object.siret // Option[String]
        this.pacage = object.pacage // Option[String]
        this.idContributorTemp = object.idContributorTemp // Option[Long]
        this.contactType = object.contactType // Option[Long]
        this.idInstallation = object.idInstallation // Option[Long] // @deprecated("now in link_installations" "1.2.223") TO DELETE

        this.link_contributorTypes = object.link_contributorTypes // Option[Seq[UpdatedContributorType]]
        this.link_installations = object.link_installations // Option[Seq[UpdatedContributorInstallation]]
    }
}
