module.exports = {
    LOGIN_FAIL: 'LOGIN_FAIL',
    ADD_TOKEN: 'ADD_TOKEN',
    REMOVE_TOKEN: 'REMOVE_TOKEN',
    SIEAU_TOKEN: 'sieau-token',
    SIEAU_LOGIN: 'SIEAU_LOGIN',
    MAP_STATIONS_VISIBLE: 'MAP_STATIONS_VISIBLE',
    DISCONNECTED: 'DISCONNECTED',
    TITLE: 'TITLE',
    BACK_PATH: 'BACK_PATH',
    NAVBAR_LINKS: 'NAVBAR_LINKS',
    ACTIONS: 'ACTIONS',
    RECEIVE_NOTIFICATIONS: 'RECEIVE_NOTIFICATIONS',
    READ_NOTIFICATION: 'READ_NOTIFICATION',
    ADD_NOTIFICATION: 'ADD_NOTIFICATION',
    RECEIVE_THEME_LAYERS: 'RECEIVE_THEME_LAYERS',
    RECEIVE_LAST_DOCUMENTS: 'RECEIVE_LAST_DOCUMENTS',
    SHOW_WELCOME_POPUP: 'SHOW_WELCOME_POPUP',
    UPDATE_GLOBAL_RESEARCH: 'UPDATE_GLOBAL_RESEARCH',
    SET_HELP_LINK: 'SET_HELP_LINK',
    SET_FORMATION_PATH: 'SET_FORMATION_PATH',
    URL_WANTED: 'URL_WANTED',
}
