import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import Input from '../../../../components/forms/Input'
import Select from '../../../../components/forms/Select'
import Textarea from '../../../../components/forms/Textarea'
import Row from '../../../../components/react/Row'
import { SANDRE } from '../../../../referencial/constants/ReferencialConstants'
import DtoSandreCode from '../../../../referencial/dto/DtoSandreCode'
import { getHour } from '../../../../utils/DateUtil'
import { onChangeHour } from '../../../../utils/FormUtils'
import { getSandreList } from '../../../../utils/StoreUtils'
import DtoUsage from '../../../dto/DtoUsage'
import SimpleDatePicker from 'components/forms/SimpleDatePicker'

class UsageModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            usage: { ...props.usage, idStation: props.id },
        }
    }

    componentDidUpdate(prevProps) {
        if (!prevProps.popupIsOpen && this.props.popupIsOpen) {
            $('#descriptionModal').on('click', this.onSave)
        }
    }

    onSave = () => {
        this.props.saveResult(new DtoUsage(this.state.usage))
        $('.modal').modal('close')
    }

    onChangeElement = (changes) => this.setState({ usage: { ...this.state.usage, ...changes } })

    render() {
        const { usage } = this.state
        return (
            <div>
                <Row>
                    <SimpleDatePicker
                        col={6}
                        value={usage.startDate}
                        label={i18n.startDate}
                        onChange={v => this.onChangeElement({ startDate: v })}
                        max={usage.endDate}
                    />
                    <Input col={ 6 } title={ i18n.startHour } value={ getHour(usage.startDate) }
                        onChange={ v => onChangeHour(v, v2 => this.onChangeElement({ startDate: v2 }), { max: usage.endDate }, usage.startDate) }
                    />
                </Row>
                <Row>
                    <SimpleDatePicker
                        col={6}
                        value={usage.endDate}
                        label={i18n.endDate}
                        onChange={v => this.onChangeElement({ endDate: v })}
                        min={usage.startDate}
                    />
                    <Input col={ 6 } title={ i18n.endHour } value={ getHour(usage.endDate) }
                        onChange={ v => onChangeHour(v, v2 => this.onChangeElement({ endDate: v2 }), { min: usage.startDate }, usage.endDate) }
                    />
                </Row>
                <div className='row no-margin padding-top-1'>
                    <Select col={ 6 } options={ getSandreList(this.props.sandreCodes, SANDRE.USAGES_GENERAL) } label={ i18n.usage }
                        value={ usage.usageCode } onChange={ v => this.onChangeElement({ usageCode: v }) }
                    />
                </div>
                <div className='row no-margin padding-top-1'>
                    <Textarea col={ 12 } title={ i18n.description } value={ usage.descriptive } onChange={ v => this.onChangeElement({ descriptive: v }) }/>
                </div>
            </div>
        )
    }
}

UsageModal.propTypes = {
    usage: PropTypes.instanceOf(DtoUsage),
    id: PropTypes.number,
    sandreCodes: PropTypes.arrayOf(PropTypes.instanceOf(DtoSandreCode)),
    popupIsOpen: PropTypes.bool,
    saveResult: PropTypes.func,
}


const mapStateToProps = store => ({
    sandreCodes: store.ReferencialReducer.sandreCodes,
    popupIsOpen: store.SieauReducer.popupIsOpen,
})

UsageModal.defaultProps = {
    usage: {},
}

export default connect(mapStateToProps)(UsageModal)
