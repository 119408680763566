export default class DtoConventionAnalysis {
    constructor(obj) {
        this.siret = obj.siret // siret: Option[String]
        this.name = obj.name // name: Option[String]
        this.analysisDate = obj.analysisDate // analysisDate: Option[DateTime]
        this.result = obj.result // result: Option[Float]
        this.parameterCode = obj.parameterCode // parameterCode: Option[String]
        this.label = obj.label // label: Option[String]
        this.unitCode = obj.unitCode // unitCode: Option[String]
        this.analysisComment = obj.analysisComment // analysisComment: Option[String]
        this.stationId = obj.stationId // stationId: Option[String]
        this.stepName = obj.stepName // stepName: Option[String]
    }
}