import { genericPromise } from 'utils/ActionUtils'
import ApplicationConf from '../../../../conf/ApplicationConf'
import AppStore from '../../../../store/AppStore'
import ToastrAction from '../../../../toastr/actions/ToastrAction'

const SuperAdminAction = {
    execSelect: (request, code) => genericPromise(ApplicationConf.superAdmin.select(), 'POST', { token: code.toString(), request }, {
        401: () => AppStore.dispatch(ToastrAction.warning('Token invalide'))
    }),
    execInsertOrUpdate: (request, code) => genericPromise(ApplicationConf.superAdmin.exec(), 'POST', { token: code.toString(), request }, {
        401: () => AppStore.dispatch(ToastrAction.warning('Token invalide'))
    }),

    promiseLogs(filters = {}) {
        return genericPromise(ApplicationConf.superAdmin.getLogs(), 'POST', filters)
    },

    getRequest() {
        return genericPromise(ApplicationConf.superAdmin.get(), 'GET')
    },
}

export default SuperAdminAction
