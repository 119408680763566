import React, { useEffect, useMemo } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import CartographyPanel from '../../../../components/map/CartographyPanel'
import QualityAction from 'quality/actions/QualityAction'
import PiezometryAction from 'piezometry/actions/PiezometryAction'
import InstallationAction from 'installation/actions/InstallationAction'
import ProductionUnitAction from 'productionUnit/actions/ProductionUnitAction'
import PluviometryAction from 'pluviometry/actions/PluviometryAction'
import HydrometryAction from 'hydrometry/actions/HydrometryAction'
import ContactAction from 'referencial/components/contact/actions/ContactAction'
import ContributorAction from 'referencial/components/contributor/actions/ContributorAction'
import { PATH_REFERENCIAL, PATH_REFERENCIAL_WATERSHED_ITEM, PATH_REFERENCIAL_WATERSHED_ITEM_LINK } from 'home/constants/RouteConstants'
import useTitle from 'utils/customHook/useTitle'
import { useParams } from 'react-router'

const WatershedMapApp = () => {
    const dispatch = useDispatch()
    const { code } = useParams()

    const {
        qualitometersLight,
        piezometersLight,
        installationsLight,
        productionUnits,
        pluviometers,
        hydrometricStations,
        contacts,
        contributors,
        watershed,
    } = useSelector(store => ({
        watershed: store.WatershedReducer.watershed,
        qualitometersLight: store.QualityReducer.qualitometersLight,
        piezometersLight: store.PiezometryReducer.piezometersLight,
        installationsLight: store.InstallationReducer.installationsLight,
        productionUnits: store.ProductionUnitReducer.productionUnits,
        pluviometers: store.PluviometryReducer.pluviometers,
        hydrometricStations: store.HydrometryReducer.hydrometricStations,
        contacts: store.ContactReducer.contacts,
        contributors: store.ContributorReducer.contributors,
    }), shallowEqual)

    useTitle(() => [{
        title: i18n.referencials,
        href: PATH_REFERENCIAL,
    },
    {
        title: i18n.watershed,
        href: PATH_REFERENCIAL_WATERSHED_ITEM,
    },
    {
        title: code + (watershed.name ? ` - ${watershed.name}` : ''),
        href: PATH_REFERENCIAL_WATERSHED_ITEM_LINK + code,
    }], [watershed])

    useEffect(() => {
        if (!qualitometersLight.length) {
            dispatch(QualityAction.fetchQualitometersLight())
        }
        if (!piezometersLight.length) {
            dispatch(PiezometryAction.fetchPiezometersLight())
        }
        if (!installationsLight.length) {
            dispatch(InstallationAction.fetchInstallationsLight())
        }
        if (!productionUnits.length) {
            dispatch(ProductionUnitAction.fetchProductionUnits())
        }
        if (!pluviometers.length) {
            dispatch(PluviometryAction.fetchPluviometers())
        }
        if (!hydrometricStations.length) {
            dispatch(HydrometryAction.fetchHydrometricStations())
        }
        if (!contacts.length) {
            dispatch(ContactAction.fetchContacts())
        }
        if (!contributors.length) {
            dispatch(ContributorAction.fetchContributors())
        }
    }, [])

    const getFormattedCode = (list) => list.map(c => ({ ...c, code: c.code?.toString() || '' }))

    const stations = useMemo(() => {
        return [
            ...qualitometersLight,
            ...piezometersLight,
            ...installationsLight,
            ...productionUnits,
            ...pluviometers,
            ...hydrometricStations,
            ...getFormattedCode(contacts),
            ...getFormattedCode(contributors),
        ].filter(s => s.townCode === code)
    }, [contacts, contributors, hydrometricStations, installationsLight, code, piezometersLight, pluviometers, productionUnits, qualitometersLight])

    return (
        <CartographyPanel
            layers={ ['TOWN', 'WATERMASS', 'STATIONS_POINTS'] }
            componentType={ 'referencials' }
            town={ code }
            stationsPoints={ stations }
            stationsPanelTitle={ i18n.stations }
        />
    )
}

export default WatershedMapApp
