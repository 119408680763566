import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import Card from 'components/card/Card'
import Select from 'components/forms/Select'
import Row from 'components/react/Row'
import { arrayOf } from 'utils/StoreUtils'
import NumberField from 'components/forms/NumberField'
import Input from 'components/forms/Input'
import DtoUsageAgri from 'referencial/components/usage/dto/DtoUsageAgri'
import { Button, Grid } from '@mui/material'

const emptyFilter = {
    searchValue: null,
    year: null,
    usage: null,
}

const FormFilterVolumes = ({
    onChange = () => { },
    usages = [],
}) => {
    const [tmpFilter, setTmpFilter] = useState(emptyFilter)

    const onReset = () => {
        onChange(emptyFilter)
        setTmpFilter(emptyFilter)
    }

    return (
        <Row>
            <Card round>
                <Row className='padding-top-1'>
                    <Row>
                        <Input
                            col={3}
                            title={i18n.search}
                            value={tmpFilter.searchValue}
                            onChange={v => setTmpFilter({ ...tmpFilter, searchValue: v })}
                            onEnterKeyPress={() => onChange(tmpFilter)}
                        />
                        <NumberField
                            col={3}
                            title={i18n.year}
                            value={tmpFilter.year}
                            onChange={v => setTmpFilter({ ...tmpFilter, year: v })}
                            min={0}
                        />
                        <Select
                            col={3}
                            label={i18n.usage}
                            options={usages}
                            value={tmpFilter.usage}
                            onChange={v => setTmpFilter({ ...tmpFilter, usage: v })}
                            integerValue
                            keyValue='id'
                            keyLabel='description'
                            nullLabel='&nbsp;'
                        />
                    </Row>
                    <Grid container className='padding-1' justifyContent='flex-end'>
                        <Grid item>
                            <Button variant='outlined' onClick={onReset}>
                                {i18n.reinit}
                            </Button>
                        </Grid>
                        <Grid item className='padding-left-1'>
                            <Button variant='contained' onClick={() => onChange(tmpFilter)}>
                                {i18n.search}
                            </Button>
                        </Grid>
                    </Grid>
                </Row>
            </Card>
        </Row>
    )
}

FormFilterVolumes.propTypes = {
    onChange: PropTypes.func,
    usages: arrayOf(DtoUsageAgri),
}

const mapStateToProps = store => ({
    usages: store.UsageReducer.usages,
})

export default connect(mapStateToProps)(FormFilterVolumes)