import { Button, Grid } from '@mui/material'
import { LOCAL_DECLARATIONS_FILTERS } from 'agriAdministration/constants/AgriConstants'
import Card from 'components/card/Card'
import { sortBy } from 'lodash'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import CityDto from 'referencial/components/city/dto/CityDto'
import DtoManagementUnit from 'referencial/components/managementUnit/dto/DtoManagementUnit'
import WatershedDto from 'referencial/components/watershed/dto/WatershedDto'
import i18n from 'simple-react-i18n'
import { getLocalStorageJson } from 'utils/FormUtils'
import Checkbox from '../../../components/forms/Checkbox'
import Input from '../../../components/forms/Input'
import Select from '../../../components/forms/Select'
import Row from '../../../components/react/Row'

const emptyFilter = {
    adjourned: true,
    shut: true,
}

const DeclarationsFilterPanel = ({ onValidate = () => {} }) => {
    const [filter, setFilter] = useState(getLocalStorageJson(LOCAL_DECLARATIONS_FILTERS) || emptyFilter)

    const {
        cities,
        managementUnits,
        watersheds,
        sandreCodes,
    } = useSelector(store => ({
        cities: store.CityReducer.cities,
        managementUnits: store.ManagementUnitReducer.managementUnits,
        watersheds: store.WatershedReducer.watersheds,
        sandreCodes: store.ReferencialReducer.sandreCodes,
    }), shallowEqual)

    const resetFilters = () => {
        setFilter(emptyFilter)
        onValidate(emptyFilter)
    }

    return (
        <Card title={i18n.research} round noMargin={false} className='margin-bottom-1 margin-top-0'>
            <Grid container alignItems='center' justifyContent='flex-start' className='padding-top-1'>
                <Grid item xs={6}>
                    <Input
                        col={12}
                        title={i18n.search}
                        value={filter.declarationFilter}
                        onChange={v => setFilter({ ...filter, declarationFilter: v })}
                        onEnterKeyPress={() => onValidate(filter)}
                    />
                </Grid>
                <Checkbox
                    col
                    id='myFolders'
                    label={i18n.myFolders}
                    checked={filter.myFolders || false}
                    onChange={v => setFilter({ ...filter, myFolders: v })}
                />
                <Checkbox
                    col
                    id='adjourned'
                    label={i18n.adjourned}
                    checked={filter.adjourned || false}
                    onChange={(v) => setFilter({ ...filter, adjourned: v })}
                />
                <Checkbox
                    col
                    id='shut'
                    label={i18n.shut}
                    checked={filter.shut || false}
                    onChange={(v) => setFilter({ ...filter, shut: v })}
                />
            </Grid>
            <Row className='padding-bottom-1 padding-top-1'>
                <Select
                    col={2}
                    label={i18n.city}
                    options={cities}
                    value={filter.city}
                    keyLabel='labelWithCode'
                    onChange={(v) => setFilter({ ...filter, city: v })}
                    clearFunction
                />
                <Select
                    col={2}
                    label={i18n.managementUnit}
                    options={sortBy(managementUnits.filter((u) => !u.parent), 'id')}
                    value={filter.managementUnit}
                    keyValue='managementCode'
                    keyLabel='labelWithCode'
                    onChange={(v) => setFilter({ ...filter, managementUnit: v, subManagementUnit: null })}
                    clearFunction
                />
                <Select
                    col={2}
                    label={i18n.subManagementUnit}
                    options={ filter.managementUnit ? sortBy(managementUnits.filter((u) => u.parent === parseInt(filter.managementUnit)), 'id') : []}
                    value={filter.subManagementUnit}
                    keyValue='managementCode'
                    keyLabel='labelWithCode'
                    onChange={(v) => setFilter({ ...filter, subManagementUnit: v })}
                    clearFunction
                />
                <Select
                    col={2}
                    label={i18n.watershed}
                    options={sortBy(watersheds, [e => e?.name?.toLowerCase()])}
                    value={filter.watershed}
                    keyValue='id'
                    keyLabel='labelWithCode'
                    onChange={(v) => setFilter({ ...filter, watershed: v })}
                    integerValue
                    clearFunction
                />
                <Select
                    col={2}
                    label={i18n.procedure}
                    options={sandreCodes.filter((c) => c.field === 'DOSSIERS.TYPE_PROCEDURE')}
                    value={filter.procedureType}
                    keyvalue='code'
                    onChange={(v) => setFilter({ ...filter, procedureType: v })}
                    integerValue
                    clearFunction
                />
            </Row>
            <Row className='padding-bottom-1 padding-top-1'>
                <Checkbox
                    col
                    id='notStarted'
                    label={i18n.notStarted}
                    checked={ filter.step0 || false}
                    onChange={v => setFilter({ ...filter, step0: v }) }
                />
                <Checkbox
                    col
                    id='step1'
                    label={`${i18n.step} 1`}
                    checked={ filter.step1 || false}
                    onChange={v => setFilter({ ...filter, step1: v }) }
                />
                <Checkbox
                    col
                    id='step2'
                    label={`${i18n.step} 2`}
                    checked={ filter.step2 || false}
                    onChange={v => setFilter({ ...filter, step2: v }) }
                />
                <Checkbox
                    col
                    id='step3'
                    label={`${i18n.step} 3`}
                    checked={ filter.step3 || false}
                    onChange={v => setFilter({ ...filter, step3: v }) }
                />
                <Checkbox
                    col
                    id='step4'
                    label={`${i18n.step} 4`}
                    checked={ filter.step4 || false}
                    onChange={v => setFilter({ ...filter, step4: v }) }
                />
                <Checkbox
                    col
                    id='validated'
                    label={i18n.validated}
                    checked={ filter.validated || false}
                    onChange={v => setFilter({ ...filter, validated: v }) }
                />
                <Checkbox
                    col
                    id='validatedByAdmin'
                    label={i18n.validatedByAdmin}
                    checked={ filter.validatedByAdmin || false}
                    onChange={v => setFilter({ ...filter, validatedByAdmin: v }) }
                />
            </Row>
            <Grid container className='padding-1' justifyContent='flex-end'>
                <Grid item>
                    <Button variant='outlined' onClick={resetFilters}>
                        {i18n.reinit}
                    </Button>
                </Grid>
                <Grid item className='padding-left-1'>
                    <Button variant='contained' onClick={() => onValidate(filter)}>
                        {i18n.search}
                    </Button>
                </Grid>
            </Grid>
        </Card>
    )
}

DeclarationsFilterPanel.propTypes = {
    cities: PropTypes.arrayOf(PropTypes.instanceOf(CityDto)),
    onValidate: PropTypes.func.isRequired,
    managementUnits: PropTypes.arrayOf(PropTypes.instanceOf(DtoManagementUnit)),
    watersheds: PropTypes.arrayOf(PropTypes.instanceOf(WatershedDto)),
}

export default DeclarationsFilterPanel
