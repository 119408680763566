import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { v4 as uuidv4 } from 'uuid'
import { hasValue } from '../../utils/NumberUtil'
import DisplayedValue from './DisplayedValue'
import { isEqualBy, sieauTooltip } from '../../utils/FormUtils'
import i18n from 'simple-react-i18n'
import { searchAllCharacters } from '../../utils/StringUtil'

const $ = window.$

class NumberField extends Component {
    constructor(props) {
        super(props)
        this.state = {
            id: props.id || searchAllCharacters(props.title + uuidv4()).replaceAll(' ', '_').replace('numero', '').replace('nom', 'libelle'),
            editing: false,
        }
    }

    checkValue = (value, cb) => {
        if (hasValue(value)) {
            if (hasValue(this.props.max) && value > this.props.max) {
                $(`#${this.state.id}`).val(this.props.max)
                cb(this.props.max)
            } else if (hasValue(this.props.min) && value < this.props.min) {
                $(`#${this.state.id}`).val(this.props.min)
                cb(this.props.min)
            } else {
                cb(value)
            }
        } else {
            this.callOnChange()
        }
    }

    onChangeValue = event => {
        if ((this.props.onChange || this.props.changeObj) && (event.key === 'Enter')) {
            if (this.props.onEnterKeyPress) {
                this.props.onEnterKeyPress(hasValue(event.target.value) ? parseFloat(event.target.value) : undefined)
            }
            const val = this.props.floatValue ? parseFloat(event.target.value) : parseInt(event.target.value)
            this.checkValue(val, res => {
                this.callOnChange(res)
                if (this.state.editing) {
                    this.setState({ editing: false })
                }
            })
        }
    }

    onBlur = event => {
        if ((this.props.onChange || this.props.changeObj) && this.props.value !== event.target.value) {
            const val = this.props.floatValue ? parseFloat(event.target.value) : parseInt(event.target.value)
            this.checkValue(val, res => this.callOnChange(res))
        }
    }

    callOnChange = (val) => {
        if (this.props.keyObj && this.props.changeObj) {
            this.props.changeObj({ [this.props.keyObj]: val, LAST_FORM: `NUMBER-${uuidv4()}` })
        }
        if (this.props.onChange) {
            this.props.onChange(val)
        }
    }

    componentWillReceiveProps = (nextProps) => {
        $(`#${this.state.id}`).val(nextProps.value)
    }

    onTableEditing = () => {
        this.setState({ editing: true }, () => {
            $(`#${this.state.id}`).focus()
            $(`#${this.state.id}`).blur(() => this.setState({ editing: false }))
        })
    }

    getInput = () => {
        if (this.props.readMode || (this.props.tableEditable && !this.state.editing)) {
            return <DisplayedValue label={this.props.title} obligatory={this.props.obligatory} value={this.props.value} hideNull={this.props.hideNull} onClick={this.onTableEditing} tableEditable={this.props.tableEditable} />
        }
        const disabled = { readOnly: this.props.disabled }
        const divClass = this.props.noInputFieldClass ? '' : `input-field ${this.props.noLabel ? 'no-label' : ''}`
        const defaultValue = this.props.value || this.props.value === 0 ? this.props.value : ' '
        const tooltip = this.props.tooltip ? sieauTooltip(this.props.tooltip) : ''
        return (
            <div className={divClass}>
                <input
                    id={this.state.id}
                    type='number'
                    step={1}
                    min={this.props.min}
                    max={this.props.max}
                    className={`sieau-input form-control input-sm ${this.props.className}`}
                    data-mode={this.state.id}
                    {...tooltip}
                    defaultValue={defaultValue}
                    onChange={e => this.onChangeValue(e)}
                    onBlur={e => this.onBlur(e)}
                    onClick={v => this.onBlur(v)}
                    onKeyUp={this.props.onEnterKeyPress ? e => this.onChangeValue(e) : undefined}
                    style={{ backgroundColor: 'white', color: 'black' }}
                    placeholder={ this.props.placeholder }
                    data-cy={this.props['data-cy']}
                    {...disabled}
                />
                {
                    !this.props.noLabel && (
                        <label htmlFor={this.state.id} >
                            {this.props.title}
                            {this.props.obligatory && <span className='primary-color-text'>{i18n.obligatoryField}</span>}
                        </label>
                    )
                }
            </div>
        )
    }

    render() {
        return this.props.col ? (
            <div className={`col s${this.props.col}`} >
                {this.getInput()}
            </div>
        ) : this.getInput()
    }

    shouldComponentUpdate(nextProps) {
        if (this.props.freezeOpti && isEqualBy(this.props, nextProps, ['value', 'col', 'disabled', 'readMode'])) {
            return false
        }
        return true
    }
}

NumberField.propTypes = {
    title: PropTypes.string,
    className: PropTypes.string,
    onEnterKeyPress: PropTypes.func,
    onChange: PropTypes.func,
    dispatchOnNull: PropTypes.bool,
    tooltip: PropTypes.string,
    value: PropTypes.number,
    col: PropTypes.number,
    disabled: PropTypes.bool,
    floatValue: PropTypes.bool,
    noLabel: PropTypes.bool,
    min: PropTypes.number,
    max: PropTypes.number,
    readMode: PropTypes.bool,
    hideNull: PropTypes.bool,
    tableEditable: PropTypes.bool,
    noInputFieldClass: PropTypes.bool,
    keyObj: PropTypes.string,
    changeObj: PropTypes.func,
    freezeOpti: PropTypes.bool,
    obligatory: PropTypes.bool,
    id: PropTypes.string,
    placeholder: PropTypes.string,

    'data-cy': PropTypes.string,
}

NumberField.defaultProps = {
    noInputFieldClass: false,
    dispatchOnNull: true,
    className: '',
    title: '',
}

export default NumberField
