export default class DtoIndustrialSite {
    constructor(obj) {
        this.id = obj.id
        this.familyCode = obj.familyCode
        this.sevesoCode = obj.sevesoCode
        this.activityCode = obj.activityCode
        this.refurbishedSite = obj.refurbishedSite
        this.redevelopedSite = obj.redevelopedSite
        this.activityStatus = obj.activityStatus
        this.activityCode2 = obj.activityCode2
        this.activityCode3 = obj.activityCode3
        this.activityCode4 = obj.activityCode4
        this.staff = obj.staff
        this.effectiveTranche = obj.effectiveTranche
        this.siret = obj.siret
        this.categoryActivity = obj.categoryActivity
        this.icpe = obj.icpe
        this.dischargeAgreement = obj.dischargeAgreement
        this.agreementStartDate = obj.agreementStartDate
        this.agreementEndDate = obj.agreementEndDate
    }
}