/* eslint-disable camelcase */
import { Grid } from '@mui/material'
import AgriAction from 'agriAdministration/actions/AgriAction'
import ActionComponent from 'components/ActionComponent'
import Card from 'components/card/Card'
import Checkbox from 'components/forms/Checkbox'
import SimpleSelect from 'components/forms/SimpleSelect'
import ProgressCard from 'components/card/ProgressCard'
import { StyledFieldSet, StyledLegend } from 'components/StyledElements'
import HomeAction from 'home/actions/HomeAction'
import { isEmpty, orderBy, xor } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import UsageAction from 'referencial/components/usage/actions/UsageAction'
import DtoUsageAgri from 'referencial/components/usage/dto/DtoUsageAgri'
import i18n from 'simple-react-i18n'
import { getSetting } from 'utils/SettingUtils'

class SurveySettingsApp extends ActionComponent {
    state = {
        dataLoaded: false,
        readMode: true,
        step1: '1',
        step2: '1',
        step3: '1',
        consos: '1',
        usagesReal: '1',
        usagesPrevi: '1',
        usagesInNotification: [],
        volumesInNotification: 'authorized',
        defaultModelDeclaration: undefined,
        defaultModelSynthese: undefined,
        defaultModelNotification: undefined,
    }

    componentDidMount() {
        this.initState()
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.survey.idSurvey !== this.props.survey.idSurvey) {
            this.setState({ survey: this.props.survey })
        }
        this.setTitle()
    }

    setTitle = () => {
        const { params: { id } } = this.props
        const { survey } = this.props
        this.props.setTitle([{
            title: i18n.planning,
            href: 'planning',
        }, {
            title: i18n.surveys,
            href: 'planning/surveys',
        }, {
            title: `${survey.name || ''} [${survey.year || ''}]`,
            href: `survey/${id}`,
        }, {
            title: i18n.settings,
            href: `survey/${id}/settings`,
        }])
    }

    setReadMode = () => {
        this.setState({ readMode: true })
        this.setActions({ edit: () => this.setEditMode() })
    }

    setEditMode = () => {
        this.setState({ readMode: false })
        this.setActions({
            save: () => this.onSave(),
            cancel: () => this.onCancel(),
        })
    }

    isValidLength = (length) => {
        return length && length > 0 && length !== '0' && length !== 'null' && length !== 'undefined'
    }

    initState = () => {
        const { survey: { link_params = [] } } = this.props
        this.setReadMode()
        this.props.fetchUsages().then(() => {
            this.setState({
                dataLoaded: true,
                step1: getSetting(link_params, 'step1') || '1',
                step2: getSetting(link_params, 'step2') || '1',
                step3: getSetting(link_params, 'step3') || '1',
                consos: getSetting(link_params, 'consos') || '1',
                usagesReal: getSetting(link_params, 'usagesReal') || '1',
                usagesPrevi: getSetting(link_params, 'usagesPrevi') || '1',
                usagesInNotification: (getSetting(link_params, 'usagesInNotification') || '').split(' ').filter((uId) => uId !== ''),
                volumesInNotification: getSetting(link_params, 'volumesInNotification') || 'authorized',
                defaultModelDeclaration: getSetting(link_params, 'defaultModelDeclaration'),
                defaultModelSynthese: getSetting(link_params, 'defaultModelSynthese'),
                defaultModelNotification: getSetting(link_params, 'defaultModelNotification'),
            })
        })
    }

    onSave = () => {
        const { survey } = this.props
        const {
            step1, step2, step3, consos, usagesReal, usagesPrevi, usagesInNotification, volumesInNotification,
            defaultModelDeclaration, defaultModelSynthese, defaultModelNotification,
        } = this.state
        const settings = [
            { idSurvey: survey.idSurvey, parameter: 'usagesInNotification', value: usagesInNotification.reduce((acc, i) => `${acc} ${i}`, '') },
            { idSurvey: survey.idSurvey, parameter: 'step1', value: step1 },
            { idSurvey: survey.idSurvey, parameter: 'step2', value: step2 },
            { idSurvey: survey.idSurvey, parameter: 'step3', value: step3 },
            { idSurvey: survey.idSurvey, parameter: 'consos', value: consos },
            { idSurvey: survey.idSurvey, parameter: 'usagesReal', value: usagesReal },
            { idSurvey: survey.idSurvey, parameter: 'usagesPrevi', value: usagesPrevi },
            { idSurvey: survey.idSurvey, parameter: 'volumesInNotification', value: volumesInNotification },
            { idSurvey: survey.idSurvey, parameter: 'defaultModelDeclaration', value: defaultModelDeclaration },
            { idSurvey: survey.idSurvey, parameter: 'defaultModelSynthese', value: defaultModelSynthese },
            { idSurvey: survey.idSurvey, parameter: 'defaultModelNotification', value: defaultModelNotification },
        ]
        this.setState({ dataLoaded: false })
        this.props.updateSurveyParams(survey.idSurvey, settings).then(() => {
            this.props.fetchSurvey(survey.idSurvey).then(() => {
                this.initState()
            })
        })
    }

    onChangeUsages = (id) => {
        const { usagesInNotification } = this.state
        const { usages } = this.props

        if (!usagesInNotification.includes('all')) {
            if (id === 'all') {
                this.setState({ usagesInNotification: ['all'] })
            } else if (usagesInNotification.includes(id)) {
                this.setState({ usagesInNotification: usagesInNotification.filter((uId) => uId !== id) })
            } else if (isEmpty(xor(usages.map((u) => `${u.id}`), [...usagesInNotification, id]))) {
                this.setState({ usagesInNotification: ['all'] })
            } else {
                this.setState({ usagesInNotification: [...usagesInNotification, id] })
            }
        } else {
            this.setState({ usagesInNotification: [...usages.map((u) => `${u.id}`), ...usagesInNotification].filter((uId) => uId !== id).filter((uId) => uId !== 'all') })
        }
    }

    onCancel = () => {
        this.initState()
    }

    isTrue = (state) => {
        return ['True', '1', 'OUI', true].includes(state)
    }

    render() {
        const { usages, allModelsByType } = this.props
        const { readMode, dataLoaded, step1, step2, step3, consos, usagesReal,
            usagesPrevi, usagesInNotification, volumesInNotification, defaultModelDeclaration,
            defaultModelSynthese, defaultModelNotification } = this.state
        const modelsFormatted = allModelsByType.map((m) => ({
            ...m,
            nameFormatted: m.name.split('.')[0],
        }))

        return (
            <div className='padding-1'>
                {dataLoaded ? (
                    <Card title={i18n.settings}>
                        <div className='padding-bottom-1 padding-left-1 padding-right-1'>
                            <StyledFieldSet className='margin-1'>
                                <StyledLegend>Sélection des étapes</StyledLegend>
                                <Grid className='padding-1' container direction='column' spacing={1}>
                                    <Grid item>
                                        <Checkbox
                                            label={`${i18n.step} 1 : ${i18n.sampler}`}
                                            checked={this.isTrue(step1)}
                                            onChange={(v) => this.setState({ step1: v ? '1' : '0' })}
                                            disabled={readMode}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <Checkbox
                                            label={`${i18n.step} 2 : ${i18n.contacts}`}
                                            checked={this.isTrue(step2)}
                                            onChange={(v) => this.setState({ step2: v ? '1' : '0' })}
                                            disabled={readMode}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <StyledFieldSet>
                                            <StyledLegend>
                                                <Checkbox
                                                    label={`${i18n.step} 3 : ${i18n.catchmentPoints}`}
                                                    checked={this.isTrue(step3)}
                                                    onChange={(v) => this.setState({ step3: v ? '1' : '0' })}
                                                    disabled={readMode}
                                                />
                                            </StyledLegend>
                                            <Grid className='padding-1' container spacing={1}>
                                                <Checkbox
                                                    col
                                                    label={i18n.consos}
                                                    checked={this.isTrue(consos)}
                                                    onChange={(v) => this.setState({ consos: v ? '1' : '0' })}
                                                    disabled={readMode}
                                                />
                                                <Checkbox
                                                    col
                                                    label={i18n.usagesReal}
                                                    checked={this.isTrue(usagesReal)}
                                                    onChange={(v) => this.setState({ usagesReal: v ? '1' : '0' })}
                                                    disabled={readMode}
                                                />
                                                <Checkbox
                                                    col
                                                    label={i18n.usagesPrevi}
                                                    checked={this.isTrue(usagesPrevi)}
                                                    onChange={(v) => this.setState({ usagesPrevi: v ? '1' : '0' })}
                                                    disabled={readMode}
                                                />
                                            </Grid>
                                        </StyledFieldSet>
                                    </Grid>
                                </Grid>
                            </StyledFieldSet>
                            <StyledFieldSet className='margin-1'>
                                <StyledLegend>{i18n.usagesShowInNotif}</StyledLegend>
                                <Grid container spacing={1} className='padding-1'>
                                    <Checkbox
                                        col
                                        label={i18n.everybody}
                                        checked={ (usagesInNotification || []).includes('all') }
                                        onChange={() => this.onChangeUsages('all')}
                                        disabled={readMode}
                                    />
                                </Grid>
                                <Grid container spacing={1} className='padding-1'>
                                    {orderBy(usages, 'description').map((u) => (
                                        <Checkbox
                                            col
                                            label={u.description}
                                            checked={ (usagesInNotification || []).some(r=> [`${u.id}`, 'all'].includes(r)) }
                                            onChange={() => this.onChangeUsages(`${u.id}`)}
                                            disabled={readMode}
                                            key={u.id}
                                        />
                                    ))}
                                </Grid>
                            </StyledFieldSet>
                            <StyledFieldSet className='margin-1'>
                                <StyledLegend>{i18n.exportsParameters}</StyledLegend>
                                <Grid container className='padding-top-1'>
                                    <Grid item xs={4}>
                                        <SimpleSelect
                                            col={12}
                                            label={i18n.defaultModelDeclaration}
                                            options={modelsFormatted.filter((m) => m.stationType === 'agriDeclaration')}
                                            value={defaultModelDeclaration}
                                            onChange={(v) => this.setState({ defaultModelDeclaration: v })}
                                            keyName='nameFormatted'
                                            keyValue='name'
                                            disabled={readMode}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <SimpleSelect
                                            col={12}
                                            label={i18n.defaultModelSynthese}
                                            options={modelsFormatted.filter((m) => m.stationType === 'agriSynthese')}
                                            value={defaultModelSynthese}
                                            onChange={(v) => this.setState({ defaultModelSynthese: v })}
                                            keyName='nameFormatted'
                                            keyValue='name'
                                            disabled={readMode}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <SimpleSelect
                                            col={12}
                                            label={i18n.defaultModelNotification}
                                            options={modelsFormatted.filter((m) => m.stationType === 'agriNotification')}
                                            value={defaultModelNotification}
                                            onChange={(v) => this.setState({ defaultModelNotification: v })}
                                            keyName='nameFormatted'
                                            keyValue='name'
                                            disabled={readMode}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <SimpleSelect
                                            col={12}
                                            label={i18n.volumesShowInNotif}
                                            options={[{
                                                value: 'requested',
                                                label: i18n.requestedVolumes,
                                            }, {
                                                value: 'allocated',
                                                label: i18n.allocatedVolumes,
                                            }, {
                                                value: 'authorized',
                                                label: i18n.authorizedVolumes,
                                            }]}
                                            value={volumesInNotification}
                                            onChange={(v) => this.setState({ volumesInNotification: v })}
                                            noNullValue
                                            disabled={readMode}
                                        />
                                    </Grid>
                                </Grid>
                            </StyledFieldSet>
                        </div>
                    </Card>
                ) : <ProgressCard indeterminate round />}
            </div>
        )
    }
}

SurveySettingsApp.propTypes = {
    params: PropTypes.shape({
        id: PropTypes.string,
    }),
    usages: PropTypes.arrayOf(PropTypes.instanceOf(DtoUsageAgri)),
}

const mapStateToProps = (store) => {
    return {
        survey: store.AgriReducer.survey,
        usages: store.UsageReducer.usages,
        allModelsByType: store.AdministrationReducer.allModelsByType,
    }
}

const mapDispatchToProps = {
    updateSurveyParams: AgriAction.updateSurveyParams,
    fetchSurvey: AgriAction.fetchSurvey,
    setTitle: HomeAction.setTitle,
    fetchUsages: UsageAction.fetchUsages,
}

export default connect(mapStateToProps, mapDispatchToProps)(SurveySettingsApp)