import { Grid } from '@mui/material'
import Card from 'components/card/Card'
import Select from 'components/forms/Select'
import SimpleTextArea from 'components/forms/SimpleTextArea'
import { push } from '@lagunovsky/redux-react-router'
import HomeAction from 'home/actions/HomeAction'
import React, { useEffect, useMemo, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import ReferencialAction from 'referencial/action/ReferencialAction'
import { SANDRE } from 'referencial/constants/ReferencialConstants'
import i18n from 'simple-react-i18n'
import useActions from 'utils/customHook/useActions'
import useSandreList from 'utils/customHook/useSandreList'
import useTitle from 'utils/customHook/useTitle'
import Input from '../../../../components/forms/Input'
import NumberField from '../../../../components/forms/NumberField'
import {
    PATH_REFERENCIAL,
    PATH_REFERENCIAL_SECTOR,
    PATH_REFERENCIAL_SECTOR_LINK,
    PATH_REFERENCIAL_SECTOR_NEW,
} from '../../../../home/constants/RouteConstants'
import { getUser } from '../../../../utils/SettingUtils'
import SectorAction from '../actions/SectorAction'

const SectorApp = () => {
    const dispatch = useDispatch()
    const { code } = useParams()

    const {
        sectorProps,
        contributors,
    } = useSelector(store => ({
        sectorProps: store.SectorReducer.sector,
        contributors: store.ContributorReducer.contributors,
    }), shallowEqual)

    const [sector, setSector] = useState({})
    const [isEditMode, setIsEditMode] = useState(code === 'new')

    useEffect(() => {
        if (code !== 'new') {
            dispatch(SectorAction.fetchSector(code))
        }
        dispatch(HomeAction.setHelpLink('', ''))
    }, [code, dispatch])

    useEffect(() => setSector(sectorProps), [sectorProps])

    useEffect(() => {
        dispatch(SectorAction.reset())
        dispatch(ReferencialAction.fetchSandreCodes())
    }, [])

    const isConsultant = useMemo(() => getUser().consultant === '1', [])

    useActions(() => {
        if (isConsultant) {
            return {}
        }
        if (code === 'new') {
            return {
                save: () => {
                    dispatch(SectorAction.createSector(sector))
                    setIsEditMode(false)
                },
                cancel: () => {
                    dispatch(push(PATH_REFERENCIAL_SECTOR))
                    setIsEditMode(false)
                },
            }
        }
        if (isEditMode) {
            return {
                save: () => {
                    dispatch(SectorAction.updateSector(sector, sector.id))
                    setIsEditMode(false)
                },
                cancel: () => {
                    setSector(sectorProps)
                    setIsEditMode(false)
                },
            }
        }
        return {
            edit: () => {
                setIsEditMode(true)
            },
            deleteCheck: () => dispatch(SectorAction.deleteSector(sector.id)),
        }
    }, [isConsultant, code, sectorProps, sector, isEditMode])

    useTitle(() => {
        return [
            {
                title: i18n.referencials,
                href: PATH_REFERENCIAL,
            },
            {
                title: i18n.sectors,
                href: PATH_REFERENCIAL_SECTOR,
            },
            code === 'new' ?
                {
                    title: i18n.new,
                    href: PATH_REFERENCIAL_SECTOR_NEW,
                } : {
                    title: code + (sectorProps.name ? ` - ${sectorProps.name}` : ''),
                    href: PATH_REFERENCIAL_SECTOR_LINK + code,
                },
        ]
    }, [code, sectorProps])

    const projections = useSandreList(SANDRE.PROJECTION)
    const managementModes = useSandreList(SANDRE.MODE_GESTION)

    return (
        <Grid style={{ margin: '25 10 0 15' }}>
            <Card>
                <Grid container style={{ padding: '0 10 10 10' }} spacing={2}>
                    <Grid item xs={3}>
                        <Input
                            title={i18n.name}
                            value={sector.name}
                            onChange={name => setSector({ ...sector, name })}
                            maxLength={255}
                            active={isEditMode}
                            data-cy='name_sector'
                            disabled={!isEditMode}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <NumberField
                            title={i18n.x}
                            value={sector.x}
                            onChange={value => setSector({ ...sector, x: value })}
                            disabled={!isEditMode}
                            floatValue
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <NumberField
                            title={i18n.y}
                            value={sector.y}
                            onChange={value => setSector({ ...sector, y: value })}
                            disabled={!isEditMode}
                            floatValue
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <Select
                            value={sector.typeprojection}
                            label={i18n.projection}
                            options={projections}
                            onChange={value => setSector({ ...sector, typeprojection: value })}
                            keyvalue='code'
                            disabled={!isEditMode}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <Select
                            value={sector.administratorCode}
                            label={i18n.administrator}
                            options={contributors}
                            onChange={value => setSector({ ...sector, administratorCode: value })}
                            keyvalue='code'
                            disabled={!isEditMode}
                            displayWithCode
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <Select
                            value={sector.managementMode}
                            label={i18n.managementMode}
                            options={managementModes}
                            onChange={value => setSector({ ...sector, managementMode: value })}
                            keyvalue='code'
                            disabled={!isEditMode}
                        />
                    </Grid>
                    <SimpleTextArea
                        col={12}
                        title={i18n.comment}
                        value={sector.comment}
                        onChange={value => setSector({ ...sector, comment: value })}
                        disabled={!isEditMode}
                        data-cy='comment_sector'
                    />
                </Grid>
            </Card>
        </Grid>
    )
}

export default SectorApp
