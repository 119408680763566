/* eslint-disable max-nested-callbacks */
import { Button, Dialog, DialogActions, DialogContent } from '@mui/material'
import IconCard from 'components/card/IconCard'
import { push } from '@lagunovsky/redux-react-router'
import { flatten, isEqual, max, orderBy, round, uniqBy, upperFirst } from 'lodash'
import moment from 'moment'
import PropTypes from 'prop-types'
import queryString from 'query-string'
import React from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import { v4 as uuidv4 } from 'uuid'
import ActionComponent from '../../../components/ActionComponent'
import Card from '../../../components/card/Card'
import ColorfulCard from '../../../components/card/ColorfulCard'
import ElementCard from '../../../components/card/ElementCard'
import Checkbox from '../../../components/forms/Checkbox'
import Input from '../../../components/forms/Input'
import NumberField from '../../../components/forms/NumberField'
import RadioButtons from '../../../components/forms/RadioButtons'
import Select from '../../../components/forms/Select'
import Textarea from '../../../components/forms/Textarea'
import Icon from '../../../components/icon/Icon'
import SelectEmail from '../../../components/modal/SelectEmail'
import ProgressBar from '../../../components/progress/ProgressBar'
import SieauAction from '../../../components/sieau/SieauAction'
import EventsAction from '../../../events/actions/EventsAction'
import { EVENT_TYPES, EVENT_TYPES_CODES } from '../../../events/constants/EventsConstants'
import DtoEvent from '../../../events/dto/DtoEvent'
import DiagnosticActionDto from '../../../events/dto/piezometryDiagnostic/DiagnosticActionDto'
import DiagnosticDto from '../../../events/dto/piezometryDiagnostic/DiagnosticDto'
import ReplacementActionDto from '../../../events/dto/piezometryDiagnostic/ReplacementActionDto'
import SolutionDto from '../../../events/dto/piezometryDiagnostic/SolutionDto'
import MaterielAction from '../../../materiel/actions/MaterielAction'
import CentralDto from '../../../materiel/components/central/dto/CentralDto'
import CentralTypeDto from '../../../materiel/components/central/dto/CentralTypeDto'
import DtoCentralSituation from '../../../materiel/components/central/dto/DtoCentralSituation'
import EquipmentAction from '../../../materiel/components/equipment/actions/EquipmentAction'
import DtoEquipmentSituation from '../../../materiel/components/equipment/dto/DtoEquipmentSituation'
import EquipmentDto from '../../../materiel/components/equipment/dto/EquipmentDto'
import EquipmentTypeDto from '../../../materiel/components/equipment/dto/EquipmentTypeDto'
import EquipmentWithMaterielsDto from '../../../materiel/components/equipment/dto/EquipmentWithMaterielsDto'
import DtoPowerSupplySituation from '../../../materiel/components/powerSupply/dto/DtoPowerSupplySituation'
import PowerSupplyDto from '../../../materiel/components/powerSupply/dto/PowerSupplyDto'
import PowerSupplyTypeDto from '../../../materiel/components/powerSupply/dto/PowerSupplyTypeDto'
import DtoSensorSituation from '../../../materiel/components/sensor/dto/DtoSensorSituation'
import SensorTypeDto from '../../../materiel/components/sensor/dto/DtoSensorType'
import SensorDto from '../../../materiel/components/sensor/dto/SensorDto'
import DtoSimSituation from '../../../materiel/components/sim/dto/DtoSimSituation'
import SimTypeDto from '../../../materiel/components/sim/dto/DtoSimType'
import SimDto from '../../../materiel/components/sim/dto/SimDto'
import SubscriptionAction from '../../../materiel/components/subscription/actions/SubscriptionAction'
import SubscriptionDto from '../../../materiel/components/subscription/dto/SubscriptionDto'
import SubscriptionSituationDto from '../../../materiel/components/subscription/dto/SubscriptionSituationDto'
import SubscriptionTypeDto from '../../../materiel/components/subscription/dto/SubscriptionTypeDto'
import DtoTelecomSituation from '../../../materiel/components/telecom/dto/DtoTelecomSituation'
import TelecomDto from '../../../materiel/components/telecom/dto/TelecomDto'
import TelecomTypeDto from '../../../materiel/components/telecom/dto/TelecomTypeDto'
import DtoVariousMaterielSituation from '../../../materiel/components/variousMateriel/dto/DtoVariousMaterielSituation'
import VariousMaterielTypeDto from '../../../materiel/components/variousMateriel/dto/DtoVariousMaterielType'
import VariousMaterielDto from '../../../materiel/components/variousMateriel/dto/VariousMaterielDto'
import DtoMaterielState from '../../../materiel/dto/DtoMaterielState'
import MaterielTypeDto from '../../../materiel/dto/MaterielTypeDto'
import ContactDto from '../../../referencial/components/contact/dto/ContactDto'
import ContributorItem from '../../../referencial/components/contributor/dto/ContributorItem'
import StationAction from '../../../station/actions/StationAction'
import StationAltimetrySystemsPanel from '../../../station/components/link/StationAltimetrySystemsPanel'
import StationLandmarkPanel from '../../../station/components/link/StationLandmarkPanel'
import DtoStationCentralAssignment from '../../../station/dto/materiel/DtoStationCentralAssignment'
import DtoStationEquipmentAssignment from '../../../station/dto/materiel/DtoStationEquipmentAssignment'
import DtoStationPowerSupplyAssignment from '../../../station/dto/materiel/DtoStationPowerSupplyAssignment'
import DtoStationSensorAssignment from '../../../station/dto/materiel/DtoStationSensorAssignment'
import DtoStationSimAssignment from '../../../station/dto/materiel/DtoStationSimAssignment'
import DtoStationSubscriptionAssignment from '../../../station/dto/materiel/DtoStationSubscriptionAssignment'
import DtoStationTelecomAssignment from '../../../station/dto/materiel/DtoStationTelecomAssignment'
import DtoStationVariousMaterielAssignment from '../../../station/dto/materiel/DtoStationVariousMaterielAssignment'
import DtoSubscription from '../../../station/dto/materiel/DtoSubscription'
import { getCampaignType } from '../../../utils/CampaignUtil'
import { getDate, getDateWithHour, getFullDate, getHour } from '../../../utils/DateUtil'
import { onChangeDate, onChangeHour } from '../../../utils/FormUtils'
import { getMaterialStateIcon, getMaterielIconValue, getMaterielPlural } from '../../../utils/MaterielUtils'
import { hasBooleanValue, hasValue } from '../../../utils/NumberUtil'
import { getUser } from '../../../utils/SettingUtils'
import { getSandreList, removeNullKeys } from '../../../utils/StoreUtils'
import { i18nize } from '../../../utils/StringUtil'
import PiezometryAction from '../../actions/PiezometryAction'
import { EVENT_ACTION, STEPS } from '../../constants/PiezometryEventConstants'
import DtoPiezometer from '../../dto/DtoPiezometer'
import { getActionMaterielType, getYesOrNo, isLastEvent, isUpdatableEvent } from './EventFormUtils'
import EventCentralCard from './eventMaterielCard/EventCentralCard'
import EventEquipmentCard from './eventMaterielCard/EventEquipmentCard'
import EventSensorCard from './eventMaterielCard/EventSensorCard'
import EventTelecomCard from './eventMaterielCard/EventTelecomCard'
import MaterielCard from './eventMaterielCard/MaterielCard'
import ActionChoicePanel from './modalPanel/ActionChoicePanel'
import DiagnosticChoicePanel from './modalPanel/DiagnosticChoicePanel'
import NewSituationPanel from './modalPanel/NewSituationPanel'
import Other from 'components/actions/Other'
import SuperMultiAutocomplete from 'components/forms/SuperMultiAutocomplete'
import ToastrAction from 'toastr/actions/ToastrAction'
import { STATION_TYPE_NAME } from '../../../station/constants/StationConstants'



class PiezometerEventForm extends ActionComponent {
    constructor(props) {
        super(props)
        const { date: dateParsed, campaignCode: campaignCodeParsed, campaignType } = queryString.parse(props.location.search)
        const date = dateParsed ? parseInt(dateParsed) : 0
        const campaignCode = campaignCodeParsed ? parseInt(campaignCodeParsed) : undefined
        const contactCode = props.params.code === '0' ? getUser().contactCode : undefined
        this.state = {
            isEditMode: false,
            event: {
                date: date || moment().valueOf(),
                eventType: campaignType || EVENT_TYPES_CODES.CURATIVE,
                graph: '1',
                bankExport: '0',
                eventHour: moment().valueOf(),
                campaignCode,
                contactCode,
            },
            step: 0,
            diagnostics: [],
            causeInterventions: [],
            materielCategory: '',
            actions: [],
            selectedType: null,
            newSituations: [],
            currentMaterielEventSituation: [],
            eventIndex: 0,
            interventionIndex: 0,
            formActions: [],
            testSignal: [],
            tmpState: {
                diagnostics: [],
                actions: [],
                newSituations: [],
                formActions: [],
                centrals: [],
                sensors: [],
                telecoms: [],
                sims: [],
                variousMateriels: [],
                powerSupplies: [],
                equipments: [],
                subscriptions: [],
                piezometer: {},
            },

            centrals: [],
            sensors: [],
            telecoms: [],
            sims: [],
            variousMateriels: [],
            powerSupplies: [],
            equipments: [],
            subscriptions: [],

            piezometer: { ...props.piezometer },

            dataLoaded: false,
            progress: 0,
            openPopup: false,

            mailContacts: [],
            mailContributors: [],
            mailCities: [],
            mailReceivers: [],
            isPopupEmailOpen: false,
            situationContributor: undefined,
        }
    }

    componentWillUnmount = () => {
        this.props.resetEvent()
    }

    fetchMaterielsByEvent = (id, date) =>
        this.props.fetchMaterielsByEvent(id, date).then(json => this.setState({
            centrals: json.centrals.map(el => new CentralDto(el)),
            powerSupplies: json.powerSupplies.map(el => new PowerSupplyDto(el)),
            sensors: json.sensors.map(el => new SensorDto(el)),
            sims: json.sims.map(el => new SimDto(el)),
            telecoms: json.telecoms.map(el => new TelecomDto(el)),
            variousMateriels: json.variousMateriels.map(el => new VariousMaterielDto(el)),
            equipments: json.equipments.map(el => new EquipmentWithMaterielsDto(el)),
            subscriptions: json.subscriptions.map(el => new SubscriptionDto(el)),
        }))

    fetchDiagnosticsAndSolutionsByEvent = (id, date) => {
        this.props.fetchDiagnosticsAndSolutions(id, date).then(() => {
            if (this.state.event.code && this.state.event.number) {
                this.props.fetchPiezometerActions(this.state.event.code, this.state.event.number).then(() => {
                    const obj = this.props.eventPiezometerActions.reduce((acc, intervention) => {
                        const diagnostic = this.props.eventPiezometerDiagnostics.find(d => d.codeDiagnostic === intervention.diagnosticCode)
                        const solution = this.props.eventPiezometerSolutions.find(s => s.codeSolution === intervention.solutionCode)
                        if (diagnostic && solution) {
                            if (!acc.diagnostics.find(d => d.codeDiagnostic === diagnostic.codeDiagnostic)) {
                                acc.diagnostics.push(diagnostic)
                            }
                            acc.actions.push({ ...solution, diagnosticId: diagnostic.codeDiagnostic })
                        } else if (solution && !diagnostic) {
                            acc.formActions.push({ ...solution, value: intervention.value, materielId: intervention.materielId })
                        }
                        return acc
                    }, { diagnostics: [], actions: [], formActions: [] })
                    this.setState(obj)
                })
            }
        })
    }

    componentDidMount = () => {
        $('.tooltipped').tooltip()
        this.setTitle()
        const {
            code,
            id,
        } = this.props.params
        if (code !== '0') {
            this.props.fetchEvent(STATION_TYPE_NAME.piezometry, id, code).then(() => {
                const date = getDateWithHour(this.state.event.date, this.state.event.eventHour).valueOf()
                this.fetchMaterielsByEvent(id, date)
                this.fetchDiagnosticsAndSolutionsByEvent(id, date)
            })
            this.props.fetchCauseIntervention(id, code).then(() => this.setState({ causeInterventions: this.props.causeInterventions.map(ci => ci.interventionId) }))
            this.props.fetchStationEvents(STATION_TYPE_NAME.piezometry, this.props.piezometer.id)
            this.props.fetchAllSituationByEvent(code, STATION_TYPE_NAME.piezometry, id).then(jsonTab => {
                this.setState({
                    currentMaterielEventSituation: flatten([
                        jsonTab[0].map(e => ({ ...e, materielId: e.idEquipment, materielCategory: 'equipment' })),
                        jsonTab[1].map(c => ({ ...c, materielId: c.idCentral, materielCategory: 'central' })),
                        jsonTab[2].map(s => ({ ...s, materielId: s.idSensor, materielCategory: 'sensor' })),
                        jsonTab[3].map(ps => ({ ...ps, materielId: ps.idPowerSupply, materielCategory: 'powerSupply' })),
                        jsonTab[4].map(s => ({ ...s, materielId: s.idSim, materielCategory: 'sim' })),
                        jsonTab[5].map(t => ({ ...t, materielId: t.idTelecom, materielCategory: 'telecom' })),
                        jsonTab[6].map(vm => ({ ...vm, materielId: vm.idVarious, materielCategory: 'variousMateriel' })),
                        jsonTab[7].map(sub => ({ ...sub, materielId: sub.idSubscription, materielCategory: 'subscription' })),
                    ]),
                })
            })
        } else {
            const date = getDateWithHour(this.state.event.date, this.state.event.eventHour).valueOf()
            this.fetchMaterielsByEvent(id, date)
            this.fetchDiagnosticsAndSolutionsByEvent(id, date)
            this.setState({ isEditMode: true })
            this.setActions({
                save: this.onSave,
            })
        }

        this.props.fetchSimSubscriptionsLastSituations()

        this.props.loadEventPanel(p => this.setState({ progress: p }))
            .then(() => this.setState({ dataLoaded: true }))
    }

    setEditMode = () => {
        if (getUser().consultant === '1') {
            this.setActions({})
        } else {
            this.setActions({
                delete: this.onDelete,
                save: () => {
                    this.setState({ isEditMode: false })
                    this.onSave()
                },
                cancel: () => {
                    this.setState({ isEditMode: false })
                    this.setReadMode()
                },
            })
        }
    }

    setReadMode = () => {
        const isLast = isLastEvent(this.props.stationEvents, this.props.event)
        const isUpdatable = isUpdatableEvent(this.props.event, this.state.currentMaterielEventSituation)
        if (getUser().consultant === '1') {
            this.setActions({})
        } else if (isLast || isUpdatable) {
            this.setActions({
                edit: () => {
                    this.setState({ isEditMode: true })
                    this.setEditMode()
                },
                delete: this.onDelete,
                share: this.getSharedTypes(),
                other: !this.props.event.closeDate ? {
                    other: (
                        <Other
                            className='clickable'
                            tooltip={i18n.closeEvent}
                            icon='lock'
                            onClick={() => {
                                const { event } = this.state
                                this.props.updateEvent(STATION_TYPE_NAME.piezometry, event.stationId, event.number, { ...event, closeDate: moment().valueOf() })
                            }}
                        />
                    ),
                } : undefined,
            })
        } else {
            this.setActions({
                share: this.getSharedTypes(),
            })
        }
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (this.state.step !== prevState.step) {
            $('.tooltipped').tooltip('remove')
        }
        if (this.props.event.id !== prevProps.event.id) {
            const event = { ...this.state.event, ...this.props.event }
            if (parseInt(this.props.params.code) !== 0) {
                this.setState({ isEditMode: false })
                this.setReadMode()
            }
            this.setState({
                event,
            })
        }
        if (!isEqual(prevState.currentMaterielEventSituation, this.state.currentMaterielEventSituation)) {
            if (parseInt(this.props.params.code) !== 0) {
                this.setState({ isEditMode: false })
                this.setReadMode()
            }
        }

        if (!isEqual(prevProps.piezometer, this.props.piezometer)) {
            this.setTitle()
            this.setState({ piezometer: { ...this.props.piezometer } })
        }
        if (!isEqual(prevProps.event, this.props.event)) {
            this.setTitle()
        }
    }

    getSharedTypes = () => {
        const types = [
            {
                id: 'email',
                onClick: this.handleShare,
                icon: 'email',
                label: i18n.email,
            },
        ]
        const idContributors = (this.props.piezometer.contributors || []).map(c => c.idContributor)
        const fbContributor = this.props.contributors.find(c => idContributors.includes(c.id) && c.name.toLowerCase() === 'facebook')
        if (fbContributor) {
            return [
                {
                    id: 'facebook',
                    onClick: this.handleShare,
                    img: 'assets/pictures/socialNetworks/logo_facebook.png',
                    label: 'Facebook',
                },
                ...types,
            ]
        }
        return types
    }

    handleShare = socialNetwork => {
        switch (socialNetwork.id) {
            case 'facebook':
                const popup = {
                    id: uuidv4(),
                    header: i18n.sharingConfirmation,
                    actions: (
                        <div>
                            <a className='waves-effect waves-teal btn-flat modal-close'>{i18n.close}</a>
                            <a
                                className='waves-effect waves-teal btn-flat modal-close btn font-white'
                                onClick={() => {
                                    const { code, id } = this.props.event
                                    this.props.postFacebook(STATION_TYPE_NAME.piezometry, code, id)
                                }}
                            >
                                {i18n.validate}
                            </a>
                        </div>
                    ),
                }
                this.props.setPopup(popup)
                break
            case 'email':
                this.setState({ isPopupEmailOpen: true })
                break
            default:
        }
    }

    setTitle = () => {
        const {
            piezometer,
            event,
            params,
        } = this.props
        const {
            id,
            code,
        } = params
        this.props.forceFetch('title', [{
            title: i18n[STATION_TYPE_NAME.piezometry],
            href: STATION_TYPE_NAME.piezometry,
        }, {
            title: `${piezometer.code} - ${piezometer.name}`,
            href: `station/${STATION_TYPE_NAME.piezometry}/${id}`,
        }, {
            title: i18n.journal,
            href: `station/${STATION_TYPE_NAME.piezometry}/${id}/event`,
        }, {
            title: code === '0' ? i18n.new : `${i18n.event} ${i18n.fromDate} ${getDate(event.date)}`,
            href: `station/${STATION_TYPE_NAME.piezometry}/${id}/event/${code}`,
        }])
    }

    foundAction = constant => this.state.formActions.some(({ managementRule, value }) => managementRule === constant && value)

    onSave = () => {
        const {
            code,
        } = this.props.params
        const {
            id,
        } = this.props.piezometer
        const event = this.state.event.eventType == EVENT_TYPES_CODES.TO_MONITOR || this.state.event.eventType === EVENT_TYPES_CODES.TO_PLAN ? {
            ...this.state.event,
            eventDate: this.state.event.date,
            bankExport: '0',
        } : {
            ...this.state.event,
            eventDate: this.state.event.date,
        }
        const eventWithDiagnostic = {
            event: {
                ...event,
                code: id,
                downloadData: this.foundAction(EVENT_ACTION.RECUPERATION_DES_DONNEES),
                transmissionTest: this.foundAction(EVENT_ACTION.TEST_TRANSMISSION),
                replaceDesiccant: this.foundAction(EVENT_ACTION.DESSICANT_REMPLACE),
                razstation: this.foundAction(EVENT_ACTION.REINITIALISATION_STATION) ? '1' : '0',
                firmwareUpdate: this.foundAction(EVENT_ACTION.FIRMWARE_MIS_A_JOUR),
                replaceORings: this.foundAction(EVENT_ACTION.JOINTS_TORIQUES_GRAISSES),
            },
            diagnostics: [
                ...this.state.actions.map(a => ({
                    piezometerCode: id,
                    diagnosticCode: this.state.diagnostics.find(d => d.codeDiagnostic === a.diagnosticId).codeDiagnostic,
                    solutionCode: a.code,
                })),
                ...this.state.formActions.map(a => ({
                    ...a,
                    piezometerCode: id,
                    solutionCode: a.code,
                })),
            ],
        }

        const interventionType = event.eventType === EVENT_TYPES_CODES.CURATIVE && 'CURATIF' || event.eventType === EVENT_TYPES_CODES.PREVENTIVE && 'PREVENTIF' || 'AUTRE'

        if (code === '0') {
            this.props.createPiezometryEventWithDiagnostic(id, eventWithDiagnostic)
                .then(eventCode => {
                    if (eventCode) {
                        const causeInterventions = this.state.causeInterventions.map(ci => ({
                            interventionId: ci,
                            interventionType,
                            eventId: eventCode,
                            stationId: id,
                        }))
                        this.props.createCauseIntervention(id, eventCode, causeInterventions)

                        const situations = this.state.newSituations.map(s => ({ ...s, eventCode, siteCode: id, siteType: 1 }))
                        this.props.addAllMaterielSituation(situations).then(() => {
                            this.props.push(`/station/${STATION_TYPE_NAME.piezometry}/${id}/event/${eventCode}`)
                        })
                        this.props.updatePiezometer(this.state.piezometer)
                    }
                })
        } else {
            this.props.updatePiezometryEventWithDiagnostic(id, code, eventWithDiagnostic)
                .then(eventCode => {
                    if (eventCode) {
                        const causeInterventions = this.state.causeInterventions.map(ci => ({
                            interventionId: ci,
                            interventionType,
                            eventId: eventCode,
                            stationId: id,
                        }))
                        this.props.createCauseIntervention(id, eventCode, causeInterventions)

                        const situations = this.state.newSituations.map(s => ({ ...s, eventCode, siteCode: id, siteType: 1 }))
                        this.props.addAllMaterielSituation(situations).then(() => this.setReadMode())
                        this.props.updatePiezometer(this.state.piezometer)
                    }
                })
        }
    }

    onDelete = () => {
        const {
            id,
            code,
        } = this.props.params
        this.props.deleteEvent(STATION_TYPE_NAME.piezometry, id, code)
            .then(() => this.props.push(`/station/${STATION_TYPE_NAME.piezometry}/${id}/event`))
    }

    getBooleanChoiceWithAction = (label, actionConstant, materielId, disabled) => {
        const action = this.props.eventPiezometerSolutions.find(s => s.managementRule === actionConstant)
        const predicate = materielId ? a => a.managementRule === actionConstant && a.materielId === materielId : a => a.managementRule === actionConstant
        const formAction = this.state.formActions.find(predicate)
        const setFormAction = (value => {
            if (hasValue(value)) {
                this.setState({ formActions: uniqBy([{ ...action, materielId, value }, ...this.state.formActions], a => a.managementRule + a.materielId) })
            } else {
                const predicate2 = materielId ? a => a.managementRule !== actionConstant || a.materielId !== materielId : a => a.managementRule !== actionConstant
                this.setState({ formActions: this.state.formActions.filter(predicate2) })
            }
        })
        return (
            <tr>
                <td>{label}</td>
                <td className='padding-left-2'>
                    <Checkbox
                        checked={formAction && formAction.value === 1}
                        onChange={v => setFormAction(v ? 1 : null)}
                        {...disabled}
                    />
                </td>
                <td className='padding-left-2'>
                    <Checkbox
                        checked={formAction && formAction.value === 0}
                        onChange={v => setFormAction(v ? 0 : null)}
                        {...disabled}
                    />
                </td>
            </tr>
        )
    }

    getOldBooleanChoice = (label, key, disabled) => (
        <tr>
            <td>{label}</td>
            <td className='padding-left-2'>
                <Checkbox
                    checked={this.state.event[key] === '1'}
                    onChange={v => this.onChangeEvent({ [key]: v ? '1' : null })}
                    {...disabled}
                    data-cy={label}
                />
            </td>
            <td className='padding-left-2'>
                <Checkbox
                    checked={this.state.event[key] === '0'}
                    onChange={v => this.onChangeEvent({ [key]: v ? '0' : null })}
                    {...disabled}
                    data-cy={'falseCheckBox'}
                />
            </td>
        </tr>
    )

    getBooleanChoice = (label, key, disabled) => (
        <tr>
            <td>{label}</td>
            <td className='padding-left-2'>
                <Checkbox
                    checked={this.state.event[key]}
                    onChange={v => this.onChangeEvent({ [key]: v ? true : null })}
                    {...disabled}
                />
            </td>
            <td className='padding-left-2'>
                <Checkbox
                    checked={hasBooleanValue(this.state.event[key]) && !this.state.event[key]}
                    onChange={v => this.onChangeEvent({ [key]: v ? false : null })}
                    {...disabled}
                />
            </td>
        </tr>
    )

    setFormAction = (actionConstant, value, materielId, toSet = true) => {
        if (toSet) {
            const action = this.props.eventPiezometerSolutions.find(s => s.managementRule === actionConstant)
            if (action) {
                const withValue = removeNullKeys({ ...action, value, materielId })
                this.setState({ formActions: uniqBy([withValue, ...this.state.formActions], a => a.managementRule + a.materielId) })
            }
        } else {
            const predicate = materielId ? a => a.managementRule !== actionConstant || a.materielId !== materielId : a => a.managementRule !== actionConstant
            this.setState({ formActions: this.state.formActions.filter(predicate) })
        }
    }

    getActionValue = (actionConstant, materielId) => {
        const predicate = materielId ? a => a.managementRule === actionConstant && a.materielId === materielId : a => a.managementRule === actionConstant
        const action = this.state.formActions.find(predicate)
        if (action) {
            return action.value
        }
        return null
    }

    getLastReplacementDate = (managementRule, materielId) => {
        const rules = this.props.eventPiezometerReplacementAction.filter(a => a.action === managementRule && a.materielId === materielId)
        if (rules.length) {
            return getFullDate(max(rules.map(a => getDateWithHour(a.date, a.hour).valueOf())))
        }
        return i18n.never
    }

    getLastDepthMeasure = () => {
        const rules = this.props.eventPiezometerReplacementAction.filter(a => a.action === EVENT_ACTION.MESURE_PROFONDEUR_STATION)
        if (rules.length) {
            const eventDate = getDateWithHour(this.state.event.date, this.state.event.eventHour)
            const measureDate = max(rules.map(a => getDateWithHour(a.date, a.hour).valueOf()).filter(date => date < eventDate))
            const event = this.props.stationEvents.find(({ date, eventHour }) => getDateWithHour(date, eventHour).valueOf() === measureDate)
            if (event) {
                const action = this.props.eventAllPiezometerActions.filter(({ solutionCode }) => solutionCode === -109).find(({ eventCode }) => eventCode === event.id)
                if (action) {
                    return `${action.value}m ${i18n.onDate} ${getFullDate(measureDate)}`
                }
            }
        }
        return i18n.never
    }

    createActionNewLevel = (nc, newNc) => {
        this.onChangeEvent({ newNc })
        this.setFormAction(EVENT_ACTION.REMPLACEMENT_MESURE_NIVEAU, newNc, null, hasValue(newNc) && hasValue(nc) && newNc !== nc)
    }

    deleteDiagnostic = diagnostic => {
        const diagnosticsByIntervention = this.state.diagnostics.filter(d => d.codeDiagnostic !== diagnostic.codeDiagnostic)
        const actionsByDiagnostic = this.state.actions.filter(a => a.diagnosticId !== diagnostic.codeDiagnostic)
        this.setState({
            diagnostics: diagnosticsByIntervention,
            actions: actionsByDiagnostic,
            tmpState: {
                ...this.state.tmpState,
                diagnostics: diagnosticsByIntervention,
                actions: actionsByDiagnostic,
            },
        })
    }

    deleteAction = action => {
        const actionsByIntervention = this.state.actions.filter(a => a.code !== action.code && a.diagnosticId === action.diagnosticId)
        const actionsRest = this.state.actions.filter(a => a.diagnosticId !== action.diagnosticId)
        const diagnosticsByIntervention = this.state.diagnostics.filter(d => d.codeDiagnostic !== action.diagnosticId)
        if (actionsByIntervention.length) {
            this.setState({
                actions: [...actionsByIntervention, ...actionsRest],
                tmpState: {
                    ...this.state.tmpState,
                    actions: [...actionsByIntervention, ...actionsRest],
                },
            })
        } else {
            this.setState({
                actions: [...actionsByIntervention, ...actionsRest],
                diagnostics: diagnosticsByIntervention,
                tmpState: {
                    ...this.state.tmpState,
                    actions: [...actionsByIntervention, ...actionsRest],
                    diagnostics: diagnosticsByIntervention,
                },
            })
        }
    }

    openDiagnosticPopin = step => {
        const filteredEvents = this.props.stationEvents.filter(e => e.eventType === EVENT_TYPES_CODES.PREVENTIVE || e.eventType === EVENT_TYPES_CODES.CURATIVE)
        const lastEvent = isLastEvent(filteredEvents, this.state.event)
        if (!lastEvent) {
            this.props.toastrWarning(i18n.functionnalityBlockedEventTooOld)
            return
        }
        this.setState(({ tmpState, diagnostics, actions, newSituations, formActions, centrals, sensors, equipments, telecoms, sims, variousMateriels, powerSupplies, piezometer }) => ({
            openPopup: true,
            step,
            tmpState: {
                ...tmpState,
                diagnostics,
                actions,
                formActions,
                centrals,
                sensors,
                equipments,
                telecoms,
                sims,
                variousMateriels,
                powerSupplies,
                newSituations,
                piezometer,
            },
        }))
    }

    getInterventions = () => {
        const {
            isEditMode,
        } = this.state
        const interventions = orderBy(uniqBy(this.state.diagnostics, 'codeDiagnostic'), 'codeDiagnostic').map(d => ({
            diagnostic: d,
            action: this.state.actions.filter(a => a.diagnosticId === d.codeDiagnostic),
        }))
        const actionsList = isEditMode ? [
            {
                iconName: 'add',
                onClick: () => this.openDiagnosticPopin(STEPS.DIAGNOSTIC),
            },
        ] : []
        return (
            <div className='padding-top-1'>
                <Card title={i18n.intervention} actions={actionsList}>
                    {interventions.length ? (
                        <div className='no-padding'>
                            {
                                interventions.map(i => (
                                    <div className='row no-margin'>
                                        <div className='col s12 m12 no-padding'>
                                            {i.diagnostic ? (
                                                <ElementCard
                                                    icon='report_problem'
                                                    color='red'
                                                    {
                                                        ...(isEditMode ?
                                                            {
                                                                className: 'clickable',
                                                                onClick: () => this.openDiagnosticPopin(STEPS.DIAGNOSTIC),
                                                                button: 'delete',
                                                                onClickButton: () => this.deleteDiagnostic(i.diagnostic),
                                                            } : {})
                                                    }
                                                >
                                                    <h6>{i.diagnostic.description}</h6>
                                                </ElementCard>
                                            ) : <div className='col s6' />}
                                        </div>
                                        <div className='col s10 row no-padding no-margin right'>
                                            <div className='col s12 no-padding'>
                                                {i.action ? i.action.map(a => (
                                                    <ElementCard
                                                        icon='build'
                                                        color='green'
                                                        {
                                                            ...(isEditMode ?
                                                                {
                                                                    onClick: () => this.openDiagnosticPopin(STEPS.ACTION),
                                                                    className: 'clickable',
                                                                    button: 'delete',
                                                                    onClickButton: () => this.deleteAction(a),
                                                                } : {})
                                                        }
                                                    >
                                                        <h6>{a.description}</h6>
                                                    </ElementCard>
                                                )) : <div className='col s6' />}
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                            {
                                (this.props.params.code === '0' ?
                                    this.state.newSituations : [
                                        ...this.state.currentMaterielEventSituation,
                                        ...this.state.newSituations,
                                    ]).map(({ materielCategory, materielId, statusCode }) => {
                                    const materiel = this.props[getMaterielPlural(materielCategory)].find(m => m.id === materielId)
                                    const materielType = this.props[`${materielCategory}Types`].find(t => t.materielType === materiel.materielType)
                                    const matState = this.props.materielStates.find(s => s.code === statusCode) || {}
                                    const matStateIcon = getMaterialStateIcon(matState.code)
                                    return (
                                        <ElementCard
                                            icon={matStateIcon.icon}
                                            iconColor={matStateIcon.color}
                                            color='white'
                                            style={{ color: statusCode === 1 ? 'dodgerBlue' : 'red' }}
                                        >
                                            {`${upperFirst(matState.label)}: ${upperFirst(materielCategory)} ${materielType ? `${materielType.name} - ` : ''}${materiel.numLine || materiel.imei || materiel.serialNumber || ''}`}
                                        </ElementCard>

                                    )
                                })
                            }
                        </div>
                    ) : (
                        <div className='margin-top-35'>
                            <div className='col s12 center'>
                                <i className='material-icons medium'>report_problem</i>
                                <i className='material-icons medium'>build</i>
                            </div>
                            <p className='center font-size-20'>{i18n.noneDiagnosticActionSelect}</p>
                        </div>
                    )}
                </Card>
            </div>
        )
    }

    onChangeEventDate = v => onChangeDate(
        v,
        v2 => {
            const newDate = getDateWithHour(v2, this.state.event.eventHour).valueOf()
            const event = this.state.event.eventType == EVENT_TYPES_CODES.COMMENT ? {
                ...this.state.event,
                date: v2,
                endDate: v2,
            } : {
                ...this.state.event,
                date: v2,
            }
            const newSituations = this.state.newSituations.map(s => ({
                ...s,
                situationDate: newDate,
            }))
            this.setState({
                event,
                newSituations,
                tmpState: {
                    ...this.state.tmpState,
                    newSituations,
                },
            })
            this.fetchMaterielsByEvent(this.props.piezometer.id, newDate)
        }
    )

    onChangeEventHour = v => onChangeHour(
        v,
        v2 => {
            const newDate = getDateWithHour(this.state.event.date, v2).valueOf()
            const event = {
                ...this.state.event,
                eventHour: v2,
            }
            const newSituations = this.state.newSituations.map(s => ({
                ...s,
                situationDate: newDate,
            }))
            this.setState({
                event,
                newSituations,
                tmpState: {
                    ...this.state.tmpState,
                    newSituations,
                },
            })
            this.fetchMaterielsByEvent(this.props.piezometer.id, newDate)
        }
    )

    getCampaigns = (type) => {
        const { id: piezoId } = this.props.piezometer
        const campaignsId = this.props.piezometryCampaignStations.filter(({ stationId }) => stationId === piezoId).map(({ campaignId }) => campaignId)
        const campaigns = campaignsId.map(campaignId => this.props.piezometryCampaigns.find(({ id }) => campaignId === id)).filter(c => !!c)
        return campaigns.filter(({ statut }) => statut === 2).filter(({ campaignType }) => type === campaignType)
    }

    getEventDateCard = (disabled, withCampaign = false) => {
        const {
            eventType,
        } = this.state.event
        const type = getCampaignType(eventType)
        const eventTypeLabel = eventType === EVENT_TYPES_CODES.CURATIVE && 'CURATIF' || eventType === EVENT_TYPES_CODES.PREVENTIVE && 'PREVENTIF' || 'AUTRE'
        const causeInterventionList = getSandreList(this.props.sandreCodes, `CAUSE_INTERVENTION.${eventTypeLabel}`)
        return (
            <div>
                <div className='row no-margin padding-top-1'>
                    <div className='col s4'>
                        <Input
                            title={i18n.date}
                            value={getDate(this.state.event.date)}
                            onChange={this.onChangeEventDate}
                            {...disabled}
                            data-cy={'dateEvent'}
                        />
                    </div>
                    <div className='col s4'>
                        <Input
                            title={i18n.hour}
                            value={getHour(this.state.event.eventHour)}
                            onChange={this.onChangeEventHour}
                            {...disabled}
                            data-cy={'hourEvent'}
                        />
                    </div>
                    {withCampaign && (
                        <div className='col s4'>
                            <Select
                                label={i18n.campaign}
                                value={this.state.event.campaignCode}
                                options={this.getCampaigns(type)}
                                onChange={id => this.onChangeEvent({ campaignCode: id })}
                                {...disabled}
                            />
                        </div>
                    )}
                </div>
                <div className='row no-margin padding-top-1'>
                    <div className='col s4'>
                        <Input
                            title={i18n.endDate}
                            value={getDate(this.state.event.endDate)}
                            onChange={v => onChangeDate(
                                v,
                                v2 => this.setState({ event: { ...this.state.event, endDate: v2 } }),
                                { min: this.state.event.date },
                                this.state.event.endDate,
                            )}
                            {...disabled}
                            data-cy={'endDateEvent'}
                        />
                    </div>
                    <div className='col s4'>
                        <Input
                            title={i18n.hour}
                            value={getHour(this.state.event.endDate)}
                            onChange={v => onChangeHour(
                                v,
                                v2 => this.setState({ event: { ...this.state.event, endDate: v2 } }),
                                { min: this.state.event.date },
                                this.state.event.endDate,
                            )}
                            {...disabled}
                            data-cy={'endHourEvent'}
                        />
                    </div>
                </div>
                <div className='row no-margin padding-top-1'>
                    <div className='col s8'>
                        <SuperMultiAutocomplete
                            label={i18n.causeIntervention}
                            options={causeInterventionList}
                            onChange={list => this.setState({ causeInterventions: list })}
                            values={this.state.causeInterventions}
                            {...disabled}
                            multiple
                            limit={4}
                            noSort
                            keyValue='code'
                            keyLabel='name'
                            data-cy='causeIntervention'
                        />
                    </div>
                </div>
            </div>

        )
    }

    getPreventiveCurativeForm = (preventive, disabled) => {
        const {
            event,
            formActions,
            centrals,
            sensors,
            telecoms,
            equipments,
            currentMaterielEventSituation,
            newSituations,
            testSignal,
            piezometer,
        } = this.state
        const situation = [
            ...currentMaterielEventSituation,
            ...newSituations,
        ]
        const hasSomeMateriel = !![...centrals, ...sensors, ...telecoms, ...equipments].length
        const { piezometer: piezometerActual } = this.props
        return (
            <Card title={i18n.event} maxWidth={1200}>
                <div className='row no-margin'>
                    <div className='row no-margin no-padding col s12'>
                        {this.getEventDateCard(disabled, true)}
                        {
                            !preventive && this.getInterventions()
                        }
                        <div className='row no-margin'>
                            <div className='col s12 m6'>
                                <table>
                                    {getYesOrNo()}
                                    {this.getBooleanChoiceWithAction(i18n.downloadData, 'RECUPERATION_DES_DONNEES', undefined, disabled)}
                                </table>
                            </div>
                        </div>
                        {
                            (!!telecoms.length || !hasSomeMateriel) && (
                                <div className='row no-margin'>
                                    {
                                        (telecoms.length ? telecoms : [{}]).map(mat => (
                                            <EventTelecomCard
                                                telecom={mat}
                                                hasSignalTestAction={testSignal.includes(mat.id)}
                                                disabled={disabled}
                                                formActions={formActions}
                                                lastReplacementDate={this.getLastReplacementDate(EVENT_ACTION.REMPLACEMENT_PILES_TELETRANSMISSION, mat.id)}
                                                setFormAction={this.setFormAction}
                                                onChangeTestSignal={v => this.setState(v ? { testSignal: [...testSignal, mat.id] } : { testSignal: testSignal.filter(id => id !== mat.id), formActions: formActions.filter(a => !a.managementRule.includes('TEST_SIGNAL_')) })}
                                                getBooleanChoiceWithAction={this.getBooleanChoiceWithAction}
                                            />
                                        ))
                                    }
                                </div>
                            )
                        }
                        {
                            (!!sensors.length || !hasSomeMateriel) && (
                                <div className='row no-margin'>
                                    {
                                        (sensors.length ? sensors : [{}]).map(mat => (
                                            <EventSensorCard
                                                sensor={mat}
                                                formActions={formActions}
                                                disabled={disabled}
                                                lastReplacementDate={this.getLastReplacementDate(EVENT_ACTION.REMPLACEMENT_PILES_CAPTEUR, mat.id)}
                                                setFormAction={this.setFormAction}
                                                getBooleanChoiceWithAction={this.getBooleanChoiceWithAction}
                                            />
                                        ))
                                    }
                                </div>
                            )
                        }
                        {
                            (!!centrals.length || !hasSomeMateriel) && (
                                <div className='row no-margin'>
                                    {
                                        (centrals.length ? centrals : [{}]).map(mat => (
                                            <EventCentralCard
                                                central={mat}
                                                formActions={formActions}
                                                disabled={disabled}
                                                lastReplacementDate={this.getLastReplacementDate(EVENT_ACTION.REMPLACEMENT_PILES_CENTRALE, mat.id)}
                                                setFormAction={this.setFormAction}
                                                getBooleanChoiceWithAction={this.getBooleanChoiceWithAction}
                                            />
                                        ))
                                    }
                                </div>
                            )
                        }
                        {
                            (!!equipments.length || !hasSomeMateriel) && (
                                <div className='row no-margin'>
                                    {
                                        (equipments.length ? equipments : [{}]).map(mat => mat.equipment ? (
                                            <EventEquipmentCard
                                                materiels={mat}
                                                testSignal={testSignal}
                                                disabled={disabled}
                                                formActions={formActions}
                                                getLastReplacementDate={this.getLastReplacementDate}
                                                setFormAction={this.setFormAction}
                                                onChangeTestSignal={(v, telecomId) => this.setState(v ? { testSignal: [...testSignal, telecomId] } : { testSignal: testSignal.filter(id => id !== telecomId), formActions: formActions.filter(a => !a.managementRule.includes('TEST_SIGNAL_')) })}
                                                getBooleanChoiceWithAction={this.getBooleanChoiceWithAction}
                                            />
                                        ) : null)
                                    }
                                </div>
                            )
                        }
                        <div className='card margin-top-1'>
                            <Card title={i18n.station}>
                                <div className='card-content margin-top-1'>
                                    <div className='row no-margin'>
                                        <div className='col s8 m8'>
                                            <div className='row no-margin'>
                                                <NumberField
                                                    value={event.ns}
                                                    title={i18n.staticLevelMeasure}
                                                    floatValue
                                                    onChange={v => this.onChangeEvent({ ns: v })}
                                                    {...disabled}
                                                />
                                            </div>
                                            <div className='row no-margin'>
                                                <NumberField
                                                    value={event.nc}
                                                    title={i18n.sensorInstantLevel}
                                                    floatValue
                                                    onChange={v => this.onChangeEvent({ nc: v })}
                                                    {...disabled}
                                                />
                                            </div>
                                            <div className='row no-margin'>
                                                {hasValue(event.ns) && hasValue(event.nc) ? `Différence : ${round(event.ns - event.nc, 10)}` : ''}
                                            </div>
                                        </div>
                                        <div className='col s3 offset-s1'>
                                            <Checkbox
                                                label={i18n.calibrationUpdate}
                                                checked={event.calibrationUpdate}
                                                onChange={e => this.onChangeEvent({ calibrationUpdate: e })}
                                                active={disabled.active}
                                                disabled={disabled.disabled || (hasValue(event.ns) && hasValue(event.nc) ? round(event.ns - event.nc, 10) === 0 : true)}
                                            />
                                            <div className='row no-margin'>
                                                <h6>{`${i18n.lastLevelMeasure} : ${this.getLastDepthMeasure()}`}</h6>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row no-margin'>
                                        <div className='col s8 m8'>
                                            <div className='row no-margin'>
                                                <NumberField
                                                    value={this.getActionValue(EVENT_ACTION.MESURE_PROFONDEUR_STATION)}
                                                    title={i18n.totalDepth}
                                                    floatValue
                                                    onChange={v => {
                                                        this.setFormAction(EVENT_ACTION.MESURE_PROFONDEUR_STATION, v, null, hasValue(v))
                                                        this.setState({ piezometer: {
                                                            ...piezometer,
                                                            link_work: [{
                                                                ...piezometer.link_work[0] || {},
                                                                idStation: piezometer.id,
                                                                workDepth: v,
                                                            }],
                                                        } })
                                                    }}
                                                    {...disabled}
                                                />
                                            </div>
                                            <div className='row no-margin'>
                                                <h6>{`${i18n.workDepth} : ${(piezometerActual.link_work.length && piezometerActual.link_work[0].workDepth)
                                                    ? `${piezometerActual.link_work[0].workDepth}m` : i18n.never}`}</h6>
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        !!situation.find(s => s.materielCategory === 'sensor' && s.statusCode === 1) && (
                                            <div className='row no-margin'>
                                                {
                                                    <div>
                                                        <div className='row no-margin padding-top-2'>
                                                            <p>{i18n.afterSensorChange}</p>
                                                        </div>
                                                        <fieldset>
                                                            <div className='row no-margin'>
                                                                <NumberField
                                                                    value={event.newNc}
                                                                    title={i18n.newSensorInstantLevel}
                                                                    floatValue
                                                                    onChange={v => this.createActionNewLevel(event.nc, v)}
                                                                    {...disabled}
                                                                />
                                                            </div>
                                                        </fieldset>
                                                    </div>
                                                }
                                            </div>
                                        )
                                    }
                                    <div className='row no-margin'>
                                        <div className='col s12 m6'>
                                            <table>
                                                {getYesOrNo()}
                                                {this.getBooleanChoiceWithAction(i18n.replaceORings, 'JOINTS_TORIQUES_GRAISSES', undefined, disabled)}
                                                {this.getBooleanChoiceWithAction(i18n.calibrateDateAndHour, 'DATE_HEURE_RECALIBREES', undefined, disabled)}
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </Card>
                        </div>
                        <div className='card margin-top-1'>
                            <Card title={i18n.comment}>
                                <div className='row no-margin card-content'>
                                    <div className='col s12 m12'>
                                        <Textarea col={12} value={event.comment} onChange={v => this.onChangeEvent({ comment: v })} {...disabled} />
                                    </div>
                                </div>
                            </Card>
                        </div>
                        <div className='card margin-top-1'>
                            <Card title={i18n.various}>
                                <div className={`row card-content${!preventive ? ' no-margin' : ''}`}>
                                    <div className='col s12'>
                                        <Select
                                            col={12}
                                            value={event.contactCode}
                                            label={i18n.author}
                                            options={this.props.contacts}
                                            keyValue='id'
                                            displayWithCode
                                            onChange={v => this.onChangeEvent({ contactCode: v })}
                                            {...disabled}
                                        />
                                    </div>
                                    <div className='row no-margin col s12'>
                                        <div className='col s6'>
                                            <table>
                                                {getYesOrNo()}
                                                {this.getOldBooleanChoice(i18n.bankExport, 'bankExport', disabled)}
                                            </table>
                                        </div>
                                        <div className='col s6'>
                                            <table>
                                                {getYesOrNo()}
                                                {this.getOldBooleanChoice(i18n.displayGraph, 'graph', disabled)}
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </Card>
                        </div>
                        {
                            preventive && this.getInterventions()
                        }
                    </div>
                </div>
            </Card>
        )
    }

    getUpdateInfo = () => (this.props.event.updateDate && this.props.event.updateLogin) && (
        <div style={{ paddingBottom: '5px' }}>
            <Card maxWidth={1200} round>
                <div className='row no-margin padding-left-5'>
                    {`${i18n.lastUpdateOn} ${getDate(this.props.event.updateDate)} ${i18n.atHour} ${getHour(this.props.event.updateDate)} ${i18n.by} ${this.props.event.updateLogin}`}
                </div>
            </Card>
        </div>
    )

    onChangeEventType = type => {
        this.setState({
            event: {
                ...this.state.event,
                eventType: type,
                eventDate: moment().valueOf(),
                endDate: moment().valueOf(),
            },
            causeInterventions: [],
            diagnosticSolutions: type === this.props.event.eventType ? this.props.eventPiezometerActions : [],
        })
    }

    onChangeEvent = obj => this.setState(({ event }) => ({ event: { ...event, ...obj } }))

    getToPlanToMonitorCommentForm = (comment, disabled) => {
        const {
            event,
            // currentMaterielEventSituation
        } = this.state
        return (
            <Card title={i18n.event} maxWidth={1200}>
                <div className='row no-margin'>
                    <div className='row no-margin no-padding col s12'>
                        {this.getEventDateCard(disabled)}
                        <div className='padding-top-1' />
                        {/* {
                            !!currentMaterielEventSituation.length && currentMaterielEventSituation.map(({ materielCategory, materielId, statusCode }) => {
                                const materiel = this.props[getMaterielPlural(materielCategory)].find(m => m.id === materielId)
                                const materielType = this.props[materielCategory + 'Types'].find(t => t.materielType === materiel.materielType)
                                const matState = this.props.materielStates.find(s => s.code === statusCode) || {}
                                const matStateIcon = getMaterialStateIcon(matState.code)
                                return (
                                    <ElementCard
                                        icon={matStateIcon.icon}
                                        iconColor={matStateIcon.color}
                                        color='white'
                                        style={{ color: statusCode === 1 ? 'dodgerBlue' : 'red' }}
                                    >
                                        {`${upperFirst(matState.label)}: ${upperFirst(materielCategory)} ${materielType ? `${materielType.name} - ` : ''}${materiel.imei || materiel.serialNumber || ''}`}
                                    </ElementCard>

                                )
                            })
                        } */}
                        <div className='card margin-top-1'>
                            <Card title={i18n.comment}>
                                <div className='row card-content'>
                                    <div className='col s12'>
                                        <Textarea
                                            col={12}
                                            value={event.comment}
                                            onChange={v => this.onChangeEvent({ comment: v })}
                                            {...disabled}
                                            data-cy='comment'
                                        />
                                    </div>
                                </div>
                            </Card>
                        </div>
                        <div className='card margin-top-1'>
                            <Card title={i18n.various}>
                                <div className='row card-content no-margin'>
                                    <div className='col s12'>
                                        <Select
                                            col={12}
                                            value={event.contactCode}
                                            label={i18n.author}
                                            options={this.props.contacts}
                                            keyValue='id'
                                            displayWithCode
                                            onChange={v => this.onChangeEvent({ contactCode: v })}
                                            {...disabled}
                                            data-cy='contactCode'
                                        />
                                    </div>
                                    {comment && (
                                        <div className='col s6'>
                                            <table>
                                                {getYesOrNo()}
                                                {this.getOldBooleanChoice(i18n.bankExport, 'bankExport', disabled)}
                                            </table>
                                        </div>
                                    )}
                                    <div className='col s6'>
                                        <table>
                                            {getYesOrNo()}
                                            {this.getOldBooleanChoice(i18n.displayGraph, 'graph', disabled)}
                                        </table>
                                    </div>
                                </div>
                            </Card>
                        </div>
                    </div>
                </div>
            </Card>
        )
    }

    onChangeDiagnostic = diagnostic => {
        if (this.state.tmpState.diagnostics.find(a => a.codeDiagnostic === diagnostic.codeDiagnostic)) {
            this.setState({
                tmpState: {
                    ...this.state.tmpState,
                    diagnostics: this.state.tmpState.diagnostics.filter(sa => sa.codeDiagnostic != diagnostic.codeDiagnostic),
                    actions: this.state.tmpState.actions.filter(a => a.diagnosticId !== diagnostic.codeDiagnostic),
                },
            })
        } else {
            this.setState({
                tmpState: {
                    ...this.state.tmpState,
                    diagnostics: [...this.state.tmpState.diagnostics, diagnostic],
                },
            })
        }
    }

    getDiagnosticChoice = () => (
        <DiagnosticChoicePanel
            event={this.state.event}
            diagnostics={this.state.tmpState.diagnostics}
            onChangeDiagnostic={this.onChangeDiagnostic}
        />
    )

    indexDiagnosticChange = direction => {
        switch (direction) {
            case 'UP': return { interventionIndex: this.state.interventionIndex + 1 }
            case 'DOWN': return { interventionIndex: this.state.interventionIndex - 1 }
            default: return { interventionIndex: 0 }
        }
    }

    getNewStep = ({ materialChange }) => {
        if (materialChange === '1') {
            return STEPS.MATERIEL_ADD
        }
        if (materialChange === '2') {
            return STEPS.LANDMARK_ADD
        }
        return STEPS.ACTION
    }

    onChangeAction = action => {
        const diagnostic = this.state.tmpState.diagnostics[this.state.interventionIndex]
        const actionInState = this.state.tmpState.actions.find(a => a.code === action.code && a.diagnosticId === diagnostic.codeDiagnostic)

        const myState = (() => {
            if (actionInState) {
                const actionByIntervention = this.state.tmpState.actions.filter(sa => sa.code !== action.code && sa.diagnosticId === diagnostic.codeDiagnostic)
                const actionRest = this.state.tmpState.actions.filter(sr => sr.diagnosticId !== diagnostic.codeDiagnostic)
                const actionUpdate = [...actionByIntervention, ...actionRest]
                return {
                    tmpState: {
                        ...this.state.tmpState,
                        actions: actionUpdate,
                    },
                }
            }
            return {
                step: this.getNewStep(action),
                tmpState: {
                    ...this.state.tmpState,
                    actions: [action, ...this.state.tmpState.actions],
                },
            }
        })()
        this.setState(myState)
    }

    getActionChoice = () => (
        <ActionChoicePanel
            diagnostics={this.state.tmpState.diagnostics}
            actions={this.state.tmpState.actions}
            interventionIndex={this.state.interventionIndex}
            onChangeAction={this.onChangeAction}
            goToSelectDiagnostic={() => this.setState({ step: STEPS.DIAGNOSTIC })}
            onChangeDiagnosticIndex={val => this.setState(this.indexDiagnosticChange(val))}
        />
    )

    undoMateriel = (materielCategory, sitCurrentMat = {}, sitNextMat = {}) => {
        const materiels = (() => {
            switch (materielCategory) {
                case 'equipment':
                    return {
                        equipments: [
                            ...this.state.equipments.filter(m => m.equipment.id !== sitNextMat.materielId),
                            this.props.stationEquipment.find(e => e.equipment.id === sitCurrentMat.materielId),
                        ].filter(e => !!e),
                    }
                default:
                    return {
                        [getMaterielPlural(materielCategory)]: [
                            ...this.state[getMaterielPlural(materielCategory)].filter(m => m.id !== sitNextMat.materielId),
                            this.props[getMaterielPlural(materielCategory)].find(m => m.id === sitCurrentMat.materielId),
                        ].filter(e => !!e),
                    }
            }
        })()
        this.setState({
            tmpState: {
                ...this.state.tmpState,
                ...materiels,
                formActions: this.state.tmpState.formActions.filter(a => (a.materielId !== sitCurrentMat.materielId && a.materielId !== sitNextMat.materielId) || materielCategory !== getActionMaterielType(a.managementRule)),
                newSituations: this.state.tmpState.newSituations.filter(s => (s.materielId !== sitCurrentMat.materielId && s.materielId !== sitNextMat.materielId) || materielCategory !== s.materielCategory),
            },
        })
    }

    getMaterielCategoryChoice = () => {
        const diagnostic = this.state.tmpState.diagnostics[this.state.interventionIndex]
        const action = this.state.tmpState.actions.find(d => d.diagnosticId === diagnostic.codeDiagnostic)
        const materiels = this.props.materielTypes.filter(({ link }) => link !== 'sim').map(({ link }) => {
            const assignments = this.props[`station${upperFirst(link)}Assignments`]
            const lastAssignments = assignments.length ? assignments.filter(a => !a.situationEndDate) : []
            const mat = lastAssignments.map(lastAssignment => {
                const materiel = this.getStationMateriels(lastAssignment, link)
                const materielType = materiel && materiel.materielType ? this.props[`${link}Types`].find(t => t.materielType === materiel.materielType) : {}
                return (
                    <MaterielCard
                        newSituations={this.state.tmpState.newSituations}
                        materielCategory={link}
                        materielTypeName={materielType.name || ''}
                        materiel={materiel}
                        situationDate={getDate(lastAssignment.situationDate)}
                        undoMateriel={this.undoMateriel}
                        actions={{
                            remove: () => this.setState({
                                step: STEPS.MATERIEL_SITUATION_OF_LAST,
                                selectedMateriel: materiel.id,
                                materielCategory: link,
                                selectedType: null,
                                tmpState: {
                                    ...this.state.tmpState,
                                    ...this.materielAfterRemove(link, materiel.id),
                                    formActions: this.state.tmpState.formActions.filter(a => a.materielId !== materiel.id || link !== getActionMaterielType(a.managementRule)),
                                },
                            }),
                            replace: () => this.setState({
                                step: STEPS.MATERIEL_CHOICE,
                                materielCategory: link,
                                selectedType: null,
                                selectedMateriel: materiel.id,
                                tmpState: {
                                    ...this.state.tmpState,
                                    formActions: this.state.tmpState.formActions.filter(a => a.materielId !== materiel.id || link !== getActionMaterielType(a.managementRule)),
                                },
                            }),
                        }}
                    />
                )
            })

            const newMat = this.state.tmpState.newSituations.filter(s => s.materielCategory === link && s.statusCode === 1 && !s.previousMateriel).map(sit => {
                const materiel = this.getCurrentMateriels(sit)
                const materielType = materiel && materiel.materielType ? this.props[`${link}Types`].find(t => t.materielType === materiel.materielType) : {}
                return (
                    <MaterielCard
                        newSituations={this.state.tmpState.newSituations}
                        materielCategory={link}
                        materielTypeName={materielType.name}
                        materiel={materiel}
                        situationDate={getDate(sit.situationDate)}
                        undoMateriel={this.undoMateriel}
                        actions={{
                            cancel: () => this.setState({
                                tmpState: {
                                    ...this.state.tmpState,
                                    ...this.materielAfterRemove(link, materiel.id),
                                    formActions: this.state.tmpState.formActions.filter(a => a.materielId !== materiel.id || link !== getActionMaterielType(a.managementRule)),
                                    newSituations: this.state.tmpState.newSituations.filter(s => s.materielId !== materiel.id),
                                },
                            }),
                        }}
                    />
                )
            })
            return [
                ...mat,
                ...newMat,
            ]
        })

        return (
            <div>
                <div className='row no-margin'>
                    <div className='col s6'>
                        <ElementCard
                            icon='report_problem'
                            color='red'
                            className='clickable'
                            onClick={() => this.setState({ step: STEPS.DIAGNOSTIC })}
                        >
                            <h6>{diagnostic.description}</h6>
                        </ElementCard>
                    </div>
                    <div className='col s6'>
                        <ElementCard
                            icon='build'
                            color='green'
                            className='clickable'
                            onClick={() => this.setState({ step: STEPS.ACTION })}
                        >
                            <h6>{action.description}</h6>
                        </ElementCard>
                    </div>
                </div>
                <div className='row no-margin valign-wrapper padding-left-1 padding-top-1'>
                    <Icon icon='trending_flat' />
                    <h6 className='padding-left-1'>{`${i18n.allStationMaterial} ${i18n.choiceNewMaterial}`}</h6>
                </div>
                <div className='scrollable-card' style={{ maxHeight: '65%' }}>
                    {flatten(materiels)}
                    <ColorfulCard className='center-align' color={'WHITE'}>
                        <div
                            className='col s4 m4 no-margin clickable padding-1'
                            onClick={() => this.setState({ step: STEPS.MATERIEL_CATEGORY_CHOICE })}
                        >
                            <i className=' material-icons center-align get-center-items mat-icon block'>note_add</i>
                            <p className='center-align'>{i18n.add}</p>
                        </div>
                    </ColorfulCard>
                </div>
            </div>
        )
    }

    materielAfterRemove = (materielCategory, id) => {
        switch (materielCategory) {
            case 'equipment':
                return {
                    equipments: this.state.equipments.filter(m => m.equipment.id !== id),
                }
            default:
                return {
                    [getMaterielPlural(materielCategory)]: this.state[getMaterielPlural(materielCategory)].filter(m => m.id !== id),
                }
        }
    }

    getMaterielChoice = () => {
        const {
            interventionIndex,
            materielCategory,
            selectedType,
            selectedMateriel,
            equipments,
            tmpState,
        } = this.state

        const {
            diagnostics,
            actions,
            newSituations,
        } = tmpState

        const diagnostic = diagnostics[interventionIndex]
        const action = actions.find(d => d.diagnosticId === diagnostic.codeDiagnostic)

        const inStockLastSituations = this.props[`${getMaterielPlural(materielCategory)}LastSituations`].filter(s => s.statusCode === 2)
        const materiels = this.props[getMaterielPlural(materielCategory)]
        const inStockMateriels = inStockLastSituations.map(s => materiels.find(m => m.id === s.materielId)).filter(m => !!m)
        const materielTypes = this.props[`${materielCategory}Types`].map(t => ({
            ...t,
            code: t.materielType,
            materielCount: inStockMateriels.filter(m => m.materielType === t.materielType).length,
        })).filter(t => t.materielCount > 0)
        const materielTypesWithCount = materielTypes.map(t => ({
            ...t,
            name: `${t.name} (${t.materielCount} ${i18n[getMaterielPlural(materielCategory)]})`,
        }))
        const type = selectedType || (materielTypes.length ? materielTypes[0].materielType : null)

        const assignments = this.props[`station${upperFirst(materielCategory)}Assignments`]
        const lastAssignment = assignments.length ? assignments.find(a => !a.situationEndDate && a.materielId === selectedMateriel) : null
        const materiel = this.getStationMateriels(lastAssignment, materielCategory)

        const onSelectNewMateriel = mat => {
            const newMat = (() => {
                switch (materielCategory) {
                    case 'equipment':
                        this.props.fetchEquipment(mat.equipmentId).then(json => {
                            this.setState({
                                tmpState: {
                                    ...this.state.tmpState,
                                    equipments: [
                                        ...equipments.filter(m => !lastAssignment || (lastAssignment && m.equipment.equipmentId !== lastAssignment.materielId)),
                                        new EquipmentWithMaterielsDto(json),
                                    ],
                                },
                            })
                        })
                        return {}
                    default:
                        return {
                            [getMaterielPlural(materielCategory)]: [
                                ...this.state[getMaterielPlural(materielCategory)].filter(m => !lastAssignment || (lastAssignment && m.id !== lastAssignment.materielId)),
                                mat,
                            ],
                        }
                }
            })()
            this.setState({
                step: materiel ? STEPS.MATERIEL_SITUATION_OF_LAST : STEPS.MATERIEL_ADD,
                tmpState: {
                    ...this.state.tmpState,
                    ...newMat,
                    newSituations: uniqBy([
                        {
                            materielCategory,
                            materielId: mat.id,
                            [`id${upperFirst(materielCategory)}`]: mat.id,
                            hash: materielCategory + mat.id,
                            siteCode: this.props.piezometer.id,
                            siteName: `[${this.props.piezometer.code}] ${this.props.piezometer.name}`,
                            siteType: 1,
                            situationDate: getDateWithHour(this.state.event.date, this.state.event.eventHour).valueOf(),
                            statusCode: 1,
                            previousMateriel: selectedMateriel,
                        },
                        ...newSituations,
                    ], 'hash'),
                },
            })
        }

        const materielsCard = (() => {
            if (inStockMateriels.length) {
                const materielCards = inStockMateriels.filter(m => m.materielType === type).map(m => (
                    <MaterielCard
                        materielCategory={materielCategory}
                        materielTypeName={(materielTypes.find(t => t.code === m.materielType) || {}).name}
                        materiel={m}
                        actions={{
                            [this.state.selectedMateriel ? 'replace' : 'add']: () => onSelectNewMateriel(m),
                        }}
                    />
                ))
                return (
                    <div>
                        <Card>
                            <div className='padding-top-1' />
                            <Select
                                options={materielTypesWithCount}
                                value={selectedType}
                                label={i18n.type}
                                onChange={v => this.setState({ selectedType: v ? parseInt(v) : null })}
                            />
                        </Card>
                        <div className='scrollable-card padding-top-1' style={{ maxHeight: '60%' }}>
                            {materielCards}
                        </div>
                    </div>
                )
            }
            return (
                <Card>
                    <div className='center' onClick={() => this.setState({ step: STEPS.MATERIEL_ADD })}>
                        <h6 className='col m5'>{i18n.noMaterial}</h6>
                        <i className='material-icons clickable center-align mat-icon block' style={{ alignItem: 'center' }}>arrow_back</i>
                        <p>{i18n.returnPreviousStep}</p>
                    </div>
                </Card>
            )
        })()
        return (
            <div>
                <div className='row no-margin'>
                    <div className='col s6'>
                        <ElementCard
                            icon='report_problem'
                            color='red'
                            className='clickable'
                            onClick={() => this.setState({ step: STEPS.DIAGNOSTIC })}
                        >
                            <h6>{diagnostic.description}</h6>
                        </ElementCard>
                    </div>
                    <div className='col s6'>
                        <ElementCard
                            icon='build'
                            color='green'
                            className='clickable'
                            onClick={() => this.setState({ step: STEPS.ACTION })}
                        >
                            <h6>{action.description}</h6>
                        </ElementCard>
                    </div>
                </div>
                <div className='row no-margin valign-wrapper padding-left-1 padding-top-1'>
                    <Icon icon='trending_flat' />
                    <h6 className='padding-left-1'>
                        {`${i18n.materialTypeInStock} ${i18n.selectMaterialTypeAndMaterial}`}
                    </h6>
                </div>
                {materielsCard}
            </div>
        )
    }

    getStationMateriels = (lastAssignment, materielCategory) => {
        if (lastAssignment) {
            switch (materielCategory) {
                case 'equipment':
                    return (this.props.stationEquipment.find(m => m.equipment.id === lastAssignment.materielId) || {}).equipment
                default:
                    return this.props[`station${upperFirst(materielCategory)}`].find(m => m.id === lastAssignment.materielId)
            }
        }
        return null
    }

    getCurrentMateriels = ({ materielCategory, materielId }) => {
        switch (materielCategory) {
            case 'equipment':
                return (this.state.tmpState.equipments.find(m => m.equipment.id === materielId) || {}).equipment
            default:
                return this.state.tmpState[getMaterielPlural(materielCategory)].find(m => m.id === materielId)
        }
    }

    getMaterielSituationOfLast = () => {
        const {
            interventionIndex,
            selectedMateriel,
            materielCategory,
            tmpState,
            situationContributor,
        } = this.state
        const {
            diagnostics,
            actions,
            newSituations,
        } = tmpState

        const diagnostic = diagnostics[interventionIndex]
        const action = actions.find(d => d.diagnosticId === diagnostic.codeDiagnostic)

        const assignments = this.props[`station${upperFirst(materielCategory)}Assignments`]
        const lastAssignment = assignments.length ? assignments.find(a => a.materielId === selectedMateriel && !a.situationEndDate) : null
        const materiel = this.getStationMateriels(lastAssignment, materielCategory)
        const materielType = materiel && materiel.materielType ? this.props[`${materielCategory}Types`].find(t => t.materielType === materiel.materielType) : {}

        const onSelectNewState = statusCode => this.setState({
            step: STEPS.MATERIEL_ADD,
            tmpState: {
                ...this.state.tmpState,
                newSituations: uniqBy([{
                    materielCategory,
                    materielId: materiel.id,
                    [`id${upperFirst(materielCategory)}`]: materiel.id,
                    hash: materielCategory + materiel.id,
                    administratorCode: situationContributor,
                    situationDate: getDateWithHour(this.state.event.date, this.state.event.eventHour).valueOf(),
                    statusCode,
                }, ...newSituations], 'hash'),
            },
        })
        return (
            <NewSituationPanel
                action={action}
                diagnostic={diagnostic}
                materiel={materiel}
                materielCategory={materielCategory}
                materielTypeName={materielType.name}
                onChangeStep={step => this.setState({ step })}
                onSelectNewState={onSelectNewState}
                situationContributor={situationContributor}
                onChangeAdministratior={administrator => this.setState({ situationContributor: administrator })}
            />
        )
    }

    getMaterielCategoryToAdd = () => {
        return (
            <div className='padding-top-2 row col s12'>
                {this.props.materielTypes.filter(({ link }) => link !== 'sim').map(({ link, label }) => {
                    return (
                        <div
                            className='col s12 m12 valign-wrapper chip clickable'
                            onClick={() => this.setState({
                                step: STEPS.MATERIEL_CHOICE,
                                materielCategory: link,
                                selectedType: null,
                                selectedMateriel: null,
                            })}
                        >
                            <h5 className='no-margin font-size-17 valign-wrapper justify-center padding-top-06'>
                                {label}
                                <i className='padding-left-06 material-icons small'>{getMaterielIconValue(link)}</i>
                            </h5>
                        </div>
                    )
                })}
            </div>
        )
    }

    onChangePiezo = obj => this.setState({
        tmpState: {
            ...this.state.tmpState,
            piezometer: {
                ...this.state.tmpState.piezometer,
                ...obj,
            },
        },
    })

    getPopupPanelStep = step => {
        switch (step) {
            case STEPS.DIAGNOSTIC:
                return this.getDiagnosticChoice()
            case STEPS.ACTION:
                return this.getActionChoice()
            case STEPS.MATERIEL_ADD:
                return this.getMaterielCategoryChoice()
            case STEPS.MATERIEL_CHOICE:
                return this.getMaterielChoice()
            case STEPS.MATERIEL_SITUATION_OF_LAST:
                return this.getMaterielSituationOfLast()
            case STEPS.MATERIEL_CATEGORY_CHOICE:
                return this.getMaterielCategoryToAdd()
            case STEPS.LANDMARK_ADD:
                return (
                    <div>
                        <div className='row no-margin padding-1 no-padding-bottom'>
                            <StationAltimetrySystemsPanel
                                station={this.state.tmpState.piezometer}
                                onChange={this.onChangePiezo}
                                readMode={false}
                                createOnly
                            />
                        </div>
                        <div className='row no-margin padding-1 no-padding-bottom'>
                            <StationLandmarkPanel
                                station={this.state.tmpState.piezometer}
                                onChange={this.onChangePiezo}
                                readMode={false}
                                createOnly
                            />
                        </div>
                    </div>
                )
            default:
                return null
        }
    }

    getValidatePopupButton = () => {
        if (this.state.step !== STEPS.ACTION) {
            return (
                <Button
                    onClick={() => this.setState({ step: STEPS.ACTION })}
                    variant='contained'
                    color='primary'
                    disabled={!this.state.tmpState.diagnostics.length}
                >
                    {i18n.next}
                </Button>
            )
        }
        return (
            <Button
                onClick={() => {
                    const {
                        diagnostics,
                        actions,
                        newSituations,
                        formActions,
                        centrals,
                        sensors,
                        equipments,
                        telecoms,
                        sims,
                        variousMateriels,
                        powerSupplies,
                        piezometer,
                    } = this.state.tmpState
                    this.setState({
                        openPopup: false,
                        interventionIndex: 0,
                        step: STEPS.DEFAULT,
                        diagnostics,
                        actions,
                        newSituations,
                        formActions,
                        centrals,
                        sensors,
                        equipments,
                        telecoms,
                        sims,
                        variousMateriels,
                        powerSupplies,
                        piezometer,
                    })
                }}
                variant='contained'
                color='primary'
            >
                {i18n.validate}
            </Button>
        )
    }

    getTechnicalForm = () => {
        const {
            event,
            currentMaterielEventSituation,
        } = this.state
        const disabled = { active: false, disabled: true }
        return (
            <Card title={i18n.event} maxWidth={1200}>
                <div className='row no-margin'>
                    <div className='row no-margin no-padding col s12'>
                        {this.getEventDateCard(disabled)}
                        <div className='padding-top-1' />
                        {
                            !!currentMaterielEventSituation.length && currentMaterielEventSituation.map(({ materielCategory, materielId, statusCode }) => {
                                const materiel = this.props[getMaterielPlural(materielCategory)].find(m => m.id === materielId)
                                const materielType = this.props[`${materielCategory}Types`].find(t => t.materielType === materiel.materielType)
                                const matState = this.props.materielStates.find(s => s.code === statusCode) || {}
                                const matStateIcon = getMaterialStateIcon(matState.code)
                                return (
                                    <ElementCard
                                        icon={matStateIcon.icon}
                                        iconColor={matStateIcon.color}
                                        color='white'
                                        style={{ color: statusCode === 1 ? 'dodgerBlue' : 'red' }}
                                    >
                                        {`${upperFirst(matState.label)}: ${upperFirst(materielCategory)} ${materielType ? `${materielType.name} - ` : ''}${materiel.numLine || materiel.imei || materiel.serialNumber || ''}`}
                                    </ElementCard>

                                )
                            })
                        }
                        <div className='card margin-top-1'>
                            <Card title={i18n.comment}>
                                <div className='row card-content no-margin'>
                                    <div className='col s12'>
                                        <Textarea col={12} value={event.comment} onChange={v => this.onChangeEvent({ comment: v })} {...disabled} />
                                    </div>
                                </div>
                            </Card>
                        </div>
                    </div>
                </div>
            </Card>
        )
    }

    render = () => {
        const {
            isEditMode,
            dataLoaded,
            progress,
            step,
            openPopup,
            mailContacts,
            mailContributors,
            mailCities,
            mailReceivers,
            isPopupEmailOpen,
            currentMaterielEventSituation,
        } = this.state
        if (dataLoaded) {
            const disabled = { active: isEditMode, disabled: !isEditMode }
            const {
                eventType,
            } = this.state.event
            const {
                event,
                stationEvents,
            } = this.props

            const isLast = isLastEvent(stationEvents, event)
            const isUpdatable = isUpdatableEvent(event, currentMaterielEventSituation)

            return (
                <div style={{ paddingTop: '5px' }}>
                    {
                        !isLast && !isUpdatable && (
                            <IconCard icon='warning' color='orange' style={{ maxWidth: 1200, marginLeft: 'auto', marginRight: 'auto', marginBottom: '5px' }}>
                                <div className='row no-margin valign-wrapper'>
                                    {i18n.cantUpdateEventBecause}
                                </div>
                            </IconCard>
                        )
                    }
                    {this.getUpdateInfo()}
                    {
                        eventType !== EVENT_TYPES_CODES.TECHNIQUE && (
                            <Card title={i18n.interventionType} maxWidth={1200}>
                                <div className='row no-margin padding-top-1 padding-bottom-1' >
                                    <RadioButtons
                                        elements={i18nize(EVENT_TYPES.filter((e) => e.code !== EVENT_TYPES_CODES.TECHNIQUE))}
                                        col={12}
                                        title={i18n.type}
                                        selected={eventType}
                                        onChange={type => this.onChangeEventType(type)}
                                        {...disabled}
                                    />
                                </div>
                            </Card>
                        )
                    }
                    {eventType === EVENT_TYPES_CODES.PREVENTIVE && this.getPreventiveCurativeForm(true, disabled)}
                    {eventType === EVENT_TYPES_CODES.CURATIVE && this.getPreventiveCurativeForm(false, disabled)}
                    {eventType === EVENT_TYPES_CODES.TO_PLAN && this.getToPlanToMonitorCommentForm(false, disabled)}
                    {eventType === EVENT_TYPES_CODES.TO_MONITOR && this.getToPlanToMonitorCommentForm(false, disabled)}
                    {eventType === EVENT_TYPES_CODES.COMMENT && this.getToPlanToMonitorCommentForm(true, disabled)}
                    {eventType === EVENT_TYPES_CODES.TECHNIQUE && this.getTechnicalForm(false, disabled)}
                    <div className='padding-bottom-10' />
                    <Dialog
                        fullWidth
                        maxWidth='md'
                        open={openPopup}
                    >
                        <DialogContent>
                            {this.getPopupPanelStep(step)}
                        </DialogContent>
                        <DialogActions>
                            <Button
                                onClick={() =>
                                    this.setState({
                                        openPopup: false,
                                        step: STEPS.DEFAULT,
                                    })}
                                variant='outlined'
                            >
                                {i18n.close}
                            </Button>
                            {this.getValidatePopupButton()}
                        </DialogActions>
                    </Dialog>
                    {/* <Dialog
                        autoScrollBodyContent={true}
                    > */}
                    <Dialog
                        onClose={() => this.setState({ isPopupEmailOpen: false })}
                        fullWidth
                        maxWidth='md'
                        open={isPopupEmailOpen}
                    >
                        <DialogContent>
                            <SelectEmail
                                mailContacts={mailContacts}
                                mailContributors={mailContributors}
                                mailCities={mailCities}
                                mailReceivers={mailReceivers}
                                onChangeEmails={mails => this.setState(mails)}
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => this.setState({ isPopupEmailOpen: false })} variant='outlined' >
                                {i18n.cancel}
                            </Button>
                            <Button
                                onClick={() => {
                                    const {
                                        type,
                                        id, // stationId
                                        code, // eventId
                                    } = this.props.params
                                    this.props.sendEventByMail(type, id, code, [...mailContacts, ...mailContributors, ...mailReceivers, ...mailCities])
                                    this.setState({ isPopupEmailOpen: false })
                                }}
                                variant='contained'
                                color='primary'
                            >
                                {i18n.send}
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>
            )
        }
        return (
            <Card>
                <div className='padding-top-2 padding-left-2 padding-right-2 padding-bottom-2'>
                    <ProgressBar progress={progress} withMessage />
                </div>
            </Card>
        )
    }
}

PiezometerEventForm.propTypes = {
    params: PropTypes.shape({
        id: PropTypes.string,
        type: PropTypes.string,
        code: PropTypes.string,
    }).isRequired,
    location: PropTypes.shape({
        search: PropTypes.string,
    }).isRequired,
    piezometer: PropTypes.instanceOf(DtoPiezometer),
    event: PropTypes.instanceOf(DtoEvent),
    stationEvents: PropTypes.arrayOf(PropTypes.instanceOf(DtoEvent)),
    contacts: PropTypes.arrayOf(PropTypes.instanceOf(ContactDto)),
    contributors: PropTypes.arrayOf(PropTypes.instanceOf(ContributorItem)),
    centrals: PropTypes.arrayOf(PropTypes.instanceOf(CentralDto)),
    centralTypes: PropTypes.arrayOf(PropTypes.instanceOf(CentralTypeDto)),
    centralsLastSituations: PropTypes.arrayOf(PropTypes.instanceOf(DtoCentralSituation)),
    powerSupplies: PropTypes.arrayOf(PropTypes.instanceOf(PowerSupplyDto)),
    powerSupplyTypes: PropTypes.arrayOf(PropTypes.instanceOf(PowerSupplyTypeDto)),
    powerSuppliesLastSituations: PropTypes.arrayOf(PropTypes.instanceOf(DtoPowerSupplySituation)),
    sensors: PropTypes.arrayOf(PropTypes.instanceOf(SensorDto)),
    sensorTypes: PropTypes.arrayOf(PropTypes.instanceOf(SensorTypeDto)),
    sensorsLastSituations: PropTypes.arrayOf(PropTypes.instanceOf(DtoSensorSituation)),
    sims: PropTypes.arrayOf(PropTypes.instanceOf(SimDto)),
    simTypes: PropTypes.arrayOf(PropTypes.instanceOf(SimTypeDto)),
    simsLastSituations: PropTypes.arrayOf(PropTypes.instanceOf(DtoSimSituation)),
    telecoms: PropTypes.arrayOf(PropTypes.instanceOf(TelecomDto)),
    telecomTypes: PropTypes.arrayOf(PropTypes.instanceOf(TelecomTypeDto)),
    telecomsLastSituations: PropTypes.arrayOf(PropTypes.instanceOf(DtoTelecomSituation)),
    variousMateriels: PropTypes.arrayOf(PropTypes.instanceOf(VariousMaterielDto)),
    variousMaterielTypes: PropTypes.arrayOf(PropTypes.instanceOf(VariousMaterielTypeDto)),
    variousMaterielsLastSituations: PropTypes.arrayOf(PropTypes.instanceOf(DtoVariousMaterielSituation)),
    equipments: PropTypes.arrayOf(PropTypes.instanceOf(EquipmentDto)),
    equipmentTypes: PropTypes.arrayOf(PropTypes.instanceOf(EquipmentTypeDto)),
    equipmentsLastSituations: PropTypes.arrayOf(PropTypes.instanceOf(DtoEquipmentSituation)),
    eventPiezometerReplacementAction: PropTypes.arrayOf(PropTypes.instanceOf(ReplacementActionDto)),
    subscriptions: PropTypes.arrayOf(PropTypes.instanceOf(SubscriptionDto)),
    subscriptionTypes: PropTypes.arrayOf(PropTypes.instanceOf(SubscriptionTypeDto)),
    subscriptionsLastSituations: PropTypes.arrayOf(PropTypes.instanceOf(SubscriptionSituationDto)),
    eventPiezometerDiagnostics: PropTypes.arrayOf(PropTypes.instanceOf(DiagnosticDto)),
    eventPiezometerSolutions: PropTypes.arrayOf(PropTypes.instanceOf(SolutionDto)),
    eventPiezometerActions: PropTypes.arrayOf(PropTypes.instanceOf(DiagnosticActionDto)),
    materielStates: PropTypes.arrayOf(PropTypes.instanceOf(DtoMaterielState)),
    stationCentralAssignments: PropTypes.arrayOf(PropTypes.instanceOf(DtoStationCentralAssignment)),
    stationPowerSupplyAssignments: PropTypes.arrayOf(PropTypes.instanceOf(DtoStationPowerSupplyAssignment)),
    stationSensorAssignments: PropTypes.arrayOf(PropTypes.instanceOf(DtoStationSensorAssignment)),
    stationSimAssignments: PropTypes.arrayOf(PropTypes.instanceOf(DtoStationSimAssignment)),
    stationTelecomAssignments: PropTypes.arrayOf(PropTypes.instanceOf(DtoStationTelecomAssignment)),
    stationVariousMaterielAssignments: PropTypes.arrayOf(PropTypes.instanceOf(DtoStationVariousMaterielAssignment)),
    stationEquipmentAssignments: PropTypes.arrayOf(PropTypes.instanceOf(DtoStationEquipmentAssignment)),
    stationSubscriptionAssignments: PropTypes.arrayOf(PropTypes.instanceOf(DtoStationSubscriptionAssignment)),
    stationCentral: PropTypes.arrayOf(PropTypes.instanceOf(CentralDto)),
    stationPowerSupply: PropTypes.arrayOf(PropTypes.instanceOf(PowerSupplyDto)),
    stationSensor: PropTypes.arrayOf(PropTypes.instanceOf(SensorDto)),
    stationSim: PropTypes.arrayOf(PropTypes.instanceOf(SimDto)),
    stationTelecom: PropTypes.arrayOf(PropTypes.instanceOf(TelecomDto)),
    stationVariousMateriel: PropTypes.arrayOf(PropTypes.instanceOf(VariousMaterielDto)),
    stationEquipment: PropTypes.arrayOf(PropTypes.instanceOf(EquipmentWithMaterielsDto)),
    stationSubscription: PropTypes.arrayOf(PropTypes.instanceOf(DtoSubscription)),
    eventAllPiezometerActions: PropTypes.arrayOf(PropTypes.instanceOf(DiagnosticActionDto)),
    materielTypes: PropTypes.arrayOf(PropTypes.instanceOf(MaterielTypeDto)),
}

const mapStateToProps = store => ({
    piezometer: store.StationReducer.piezometer,
    event: store.EventsReducer.event,
    stationEvents: store.EventsReducer.stationEvents,
    contacts: store.ContactReducer.contacts,
    contributors: store.ContributorReducer.contributors,
    centrals: store.CentralReducer.centrals,
    centralTypes: store.CentralReducer.centralTypes,
    centralsLastSituations: store.CentralReducer.centralsLastSituations,
    powerSupplies: store.PowerSupplyReducer.powerSupplies,
    powerSupplyTypes: store.PowerSupplyReducer.powerSupplyTypes,
    powerSuppliesLastSituations: store.PowerSupplyReducer.powerSuppliesLastSituations,
    sensors: store.SensorReducer.sensors,
    sensorTypes: store.SensorReducer.sensorTypes,
    sensorsLastSituations: store.SensorReducer.sensorsLastSituations,
    sims: store.SimReducer.sims,
    simTypes: store.SimReducer.simTypes,
    simsLastSituations: store.SimReducer.simsLastSituations,
    telecoms: store.TelecomReducer.telecoms,
    telecomTypes: store.TelecomReducer.telecomTypes,
    telecomsLastSituations: store.TelecomReducer.telecomsLastSituations,
    variousMateriels: store.VariousMaterielReducer.variousMateriels,
    variousMaterielTypes: store.VariousMaterielReducer.variousMaterielTypes,
    variousMaterielsLastSituations: store.VariousMaterielReducer.variousMaterielsLastSituations,
    equipments: store.EquipmentReducer.equipments,
    equipmentTypes: store.EquipmentReducer.equipmentTypes,
    equipmentsLastSituations: store.EquipmentReducer.equipmentsLastSituations,
    eventPiezometerReplacementAction: store.EventsReducer.eventPiezometerReplacementAction,
    subscriptions: store.SubscriptionReducer.subscriptions,
    subscriptionTypes: store.SubscriptionReducer.subscriptionTypes,
    subscriptionsLastSituations: store.SubscriptionReducer.subscriptionsLastSituations,
    eventPiezometerDiagnostics: store.EventsReducer.eventPiezometerDiagnostics,
    eventPiezometerSolutions: store.EventsReducer.eventPiezometerSolutions,
    eventPiezometerActions: store.EventsReducer.eventPiezometerActions,
    materielStates: store.MaterielReducer.materielStates,
    stationCentralAssignments: store.StationReducer.stationCentralAssignments,
    stationPowerSupplyAssignments: store.StationReducer.stationPowerSupplyAssignments,
    stationSensorAssignments: store.StationReducer.stationSensorAssignments,
    stationSimAssignments: store.StationReducer.stationSimAssignments,
    stationTelecomAssignments: store.StationReducer.stationTelecomAssignments,
    stationVariousMaterielAssignments: store.StationReducer.stationVariousMaterielAssignments,
    stationEquipmentAssignments: store.StationReducer.stationEquipmentAssignments,
    stationSubscriptionAssignments: store.StationReducer.stationSubscriptionAssignments,
    stationCentral: store.StationReducer.stationCentral,
    stationPowerSupply: store.StationReducer.stationPowerSupply,
    stationSensor: store.StationReducer.stationSensor,
    stationSim: store.StationReducer.stationSim,
    stationTelecom: store.StationReducer.stationTelecom,
    stationVariousMateriel: store.StationReducer.stationVariousMateriel,
    stationEquipment: store.StationReducer.stationEquipment,
    stationSubscription: store.StationReducer.stationSubscription,
    piezometryCampaigns: store.PiezometryReducer.piezometryCampaigns,
    piezometryCampaignStations: store.PiezometryReducer.piezometryCampaignStations,
    eventAllPiezometerActions: store.EventsReducer.eventAllPiezometerActions,
    materielTypes: store.MaterielReducer.materielTypes,
    sandreCodes: store.ReferencialReducer.sandreCodes,
    causeInterventions: store.EventsReducer.causeInterventions,
})

const mapDispatchToProps = {
    push,
    forceFetch: SieauAction.forceFetch,
    fetchEvent: EventsAction.fetchEvent,
    fetchStationEvents: EventsAction.fetchStationEvents,
    fetchAllSituationByEvent: MaterielAction.fetchAllSituationByEvent,
    fetchMaterielsByEvent: MaterielAction.fetchMaterielsByEvent,
    fetchDiagnosticsAndSolutions: EventsAction.fetchDiagnosticsAndSolutions,
    fetchPiezometerActions: EventsAction.fetchPiezometerActions,
    fetchEquipment: EquipmentAction.fetchEquipment,
    createPiezometryEventWithDiagnostic: EventsAction.createPiezometryEventWithDiagnostic,
    updatePiezometryEventWithDiagnostic: EventsAction.updatePiezometryEventWithDiagnostic,
    deleteEvent: EventsAction.deleteEvent,
    addAllMaterielSituation: StationAction.addAllMaterielSituation,
    loadEventPanel: PiezometryAction.loadEventPanel,
    setPopup: SieauAction.setPopup,
    postFacebook: EventsAction.postFacebook,
    sendEventByMail: EventsAction.sendEventByMail,
    resetEvent: EventsAction.resetEvent,
    updatePiezometer: StationAction.updatePiezometer,
    fetchSimSubscriptionsLastSituations: SubscriptionAction.fetchSimSubscriptionsLastSituations,
    updateEvent: EventsAction.updateEvent,
    fetchCauseIntervention: EventsAction.fetchCauseIntervention,
    createCauseIntervention: EventsAction.createCauseIntervention,
    toastrWarning: ToastrAction.warning,
}

export default connect(mapStateToProps, mapDispatchToProps)(PiezometerEventForm)