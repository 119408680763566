import React from 'react'
import ActionComponent from '../../../../components/ActionComponent'
import PropTypes from 'prop-types'
import ApplicationConf from 'conf/ApplicationConf'
import { connect } from 'react-redux'
import SieauAction from '../../../../components/sieau/SieauAction'
import AppStore from 'store/AppStore'
import MethodAction from '../actions/MethodAction'
import ReferencialAction from '../../../action/ReferencialAction'
import DtoReferencial from '../../../dto/DtoReferencial'
import i18n from 'simple-react-i18n'
import MethodDto from '../dto/MethodDto'
import Input from '../../../../components/forms/Input'
import Select from '../../../../components/forms/Select'
import Checkbox from '../../../../components/forms/Checkbox'
import Textarea from '../../../../components/forms/Textarea'
import { updateMaterialize } from '../../../../utils/MaterializeUtils'
import { isEqual, omit } from 'lodash'
import { push } from '@lagunovsky/redux-react-router'
import {
    PATH_REFERENCIAL,
    PATH_REFERENCIAL_METHOD,
    PATH_REFERENCIAL_METHOD_LINK,
    PATH_REFERENCIAL_METHOD_NEW,
} from '../../../../home/constants/RouteConstants'
import { v4 as uuidv4 } from 'uuid'
import ReplaceModal from '../../ReplaceModal'
import ToastrAction from 'toastr/actions/ToastrAction'
import DeleteModal from '../../DeleteModal'
import { sandreMethodLink } from '../../../../conf/SieauConstants'
import { getUser } from '../../../../utils/SettingUtils'
import HomeAction from 'home/actions/HomeAction'
import logoSandre from 'assets/pictures/logo_sandre.png'
import SimpleDatePicker from 'components/forms/SimpleDatePicker'

class MethodApp extends ActionComponent {
    constructor(props) {
        super(props)
        this.state = {
            method: new MethodDto({}),
            isEditMode: false,
        }
    }

    changeEditMode = (bool) => {
        this.setState({ isEditMode: bool })
    }

    componentWillUnmount() {
        super.componentWillUnmount()
        AppStore.dispatch(MethodAction.reset())
    }

    componentWillMount() {
        if (this.props.params.code !== 'new') {
            AppStore.dispatch(MethodAction.fetchMethod(this.props.params.code))
            AppStore.dispatch(ReferencialAction.fetchReferencialStatus())
            this.changeEditMode(false)
        } else {
            this.changeEditMode(true)
        }
        AppStore.dispatch(HomeAction.setHelpLink('', ''))
        this.toNavbarMethod(this.props)
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.params.code !== this.props.params.code) {
            AppStore.dispatch(MethodAction.fetchMethod(nextProps.params.code))
        } else if (nextProps.params.code === 'new') {
            AppStore.dispatch(SieauAction.forceFetch('title', [
                {
                    title: i18n.referencials,
                    href: PATH_REFERENCIAL,
                },
                {
                    title: i18n.methods,
                    href: PATH_REFERENCIAL_METHOD,
                },
                {
                    title: i18n.new,
                    href: PATH_REFERENCIAL_METHOD_NEW,
                },
            ]))
        } else {
            if (!isEqual(nextProps.method, this.props.method)) {
                AppStore.dispatch(SieauAction.forceFetch('title', [
                    {
                        title: i18n.referencials,
                        href: PATH_REFERENCIAL,
                    },
                    {
                        title: i18n.methods,
                        href: PATH_REFERENCIAL_METHOD,
                    },
                    {
                        title: this.props.params.code + (nextProps.method.name ? ` - ${nextProps.method.name}` : ''),
                        href: PATH_REFERENCIAL_METHOD_LINK + this.props.params.code,
                    },
                ]))
                this.setState({ method: nextProps.method })
            }
            if (!isEqual(this.props.isEditMode, nextProps.isEditMode) || !isEqual(this.props.method.code, nextProps.method.code) || !isEqual(this.props.methods, nextProps.methods)) {
                this.toNavbarMethod(nextProps)
            }
        }
    }

    changeCode = (value) => {
        this.changeStateAttribute('code', value)
    }

    changeStatus = (value) => {
        this.changeStateAttribute('status', value.toString())
    }

    changeName = (value) => {
        this.changeStateAttribute('name', value)
    }

    changeInternationalName = (value) => {
        this.changeStateAttribute('internationalName', value)
    }

    changeAuthor = (value) => {
        this.changeStateAttribute('author', value)
    }

    changeLongLabel = (value) => {
        this.changeStateAttribute('longLabel', value)
    }

    changeReference = (value) => {
        this.changeStateAttribute('reference', value)
    }

    changeComment = (value) => {
        this.changeStateAttribute('comment', value)
    }

    changeStateAttribute = (attr, value) => {
        const method = Object.assign({}, this.state.method)
        method[attr] = value
        this.setState({ method })
    }

    getWatermassLinks = () => {
        return [{
            href: sandreMethodLink + this.props.method.code,
            img: logoSandre,
            label: i18n.sandre.toUpperCase(),
        }]
    }

    onReplace = newCode => {
        AppStore.dispatch(MethodAction.replaceMethod(this.state.method.code, newCode.code))
    }

    getSandreCodeDisabled() {
        if (this.props.params.code !== 'new') {
            return (
                <Input
                    col={ 3 }
                    title={ i18n.sandreCode }
                    value={ this.state.method.code }
                    onChange={ this.changeCode }
                    maxLength={ 5 }
                    disabled
                />
            )
        }
        return (
            <Input
                col={ 3 }
                title={ i18n.sandreCode }
                value={ this.state.method.code }
                onChange={ this.changeCode }
                maxLength={ 5 }
            />
        )
    }

    getRequiredFunction = (actions) => {
        AppStore.dispatch(actions)
        this.changeEditMode(false)
    }

    toNavbarMethod(props = this.props) {
        const actions = (() => {
            if (props.params.code === 'new' && (!props.method || !props.method.code)) {
                return {
                    save: () => {
                        const existCode = this.props.methods.find(m => m.code === this.state.method.code)
                        if (existCode) {
                            AppStore.dispatch(ToastrAction.error(i18n.codeAlreadyExists))
                        } else if (!this.state.method.code) {
                            AppStore.dispatch(ToastrAction.error(i18n.thanksToEnterCode))
                        } else {
                            this.getRequiredFunction(MethodAction.createMethod(this.state.method))
                        }
                    },
                    cancel: () => {
                        AppStore.dispatch(push(PATH_REFERENCIAL_METHOD))
                        this.changeEditMode(false)
                    },
                }
            }
            if (this.state.isEditMode) {
                return {
                    save: () => {
                        this.getRequiredFunction(MethodAction.updateMethod(this.state.method, this.state.method.code))
                        this.changeEditMode(false)
                    },
                    cancel: () => {
                        this.setState({ method: props.method })
                        this.changeEditMode(false)
                    },
                }
            }
            return {
                edit: () => {
                    this.changeEditMode(true)
                },
                deleteCheck: () => {
                    AppStore.dispatch(ReferencialAction.fetchCheckDelete(ApplicationConf.referencial.methodCountDelete(this.props.params.code)))
                    const id = uuidv4()
                    const popup = {
                        id,
                        content: <DeleteModal
                            onDelete={ () => AppStore.dispatch(MethodAction.deleteMethod(this.state.method.code)) }
                        />,
                        dismissible: false,
                    }
                    AppStore.dispatch(SieauAction.setPopup(popup))
                },
                replace: () => {
                    AppStore.dispatch(ReferencialAction.fetchCheckDelete(ApplicationConf.referencial.methodCountDelete(this.props.params.code)))
                    const id = uuidv4()
                    const popup = {
                        id,
                        content: <ReplaceModal
                            elements={ props.methods }
                            title={ `${i18n.method} [${this.state.method.code}]` }
                            label={ i18n.method }
                            onReplace={ this.onReplace }
                        />,
                        dismissible: false,
                    }
                    AppStore.dispatch(SieauAction.setPopup(popup))
                },
                links: this.getWatermassLinks(),
            }
        })()
        if (getUser().consultant === '1') {
            this.setActions(omit(actions, ['save', 'edit', 'deleteCheck', 'replace']))
        } else {
            this.setActions(actions)
        }
        updateMaterialize()
    }

    componentDidUpdate() {
        this.toNavbarMethod()
    }

    componentDidMount() {
        this.toNavbarMethod()
    }

    render() {
        const disabled = {
            active: this.state.isEditMode,
            disabled: !this.state.isEditMode,
        }

        const activeIcon = this.props.method.status === '3' ? <i className='material-icons'>lock</i> : null

        return (
            <div className='row no-margin'>
                <div className='col s12'>
                    <div className='card margin-top-0-75-rem'>
                        <div className='card-content'>
                            <div className='row no-margin'>
                                <div className='col s12 m3 l3' />
                                <div className='col s12 m6 l6'>
                                    <div className='row no-margin padding-top-3-px'>
                                        { this.getSandreCodeDisabled() }
                                        <Select
                                            className='offset-s3'
                                            options={ this.props.referencialStatus }
                                            label={ i18n.status }
                                            col={ 3 }
                                            onChange={ this.changeStatus }
                                            value={ parseInt(this.state.method.status) }
                                            nullLabel='&nbsp;'
                                            { ...disabled }
                                        />
                                        <div className='col s1 padding-top-10-px'>
                                            { activeIcon }
                                        </div>
                                        <div className='center padding-top-10-px'>
                                            <Checkbox
                                                col={ 2 }
                                                label={ i18n.active }
                                                checked={ this.state.method.active === '1' }
                                                onChange={ (e) => this.changeStateAttribute('active', e ? '1' : '0') }
                                                { ...disabled }
                                            />
                                        </div>
                                    </div>
                                    <div className='row no-margin padding-top-3-px'>
                                        <Textarea
                                            col={ 12 }
                                            id='method_name'
                                            title={ i18n.name }
                                            value={ this.state.method.name }
                                            onChange={ this.changeName }
                                            maxLength={ 255 }
                                            { ...disabled }
                                        />
                                    </div>
                                    <div className='row no-margin padding-top-7-px'>
                                        <SimpleDatePicker
                                            onChange={ creationDate => this.setState({ method: { ...this.state.method, creationDate } }) }
                                            id='creationDate'
                                            label={ i18n.creationDate }
                                            value={ this.state.method.creationDate }
                                            col={ 4 }
                                            { ...disabled }
                                            endDate={ this.state.method.updateDate }
                                        />
                                        <SimpleDatePicker
                                            onChange={ updateDate => this.setState({ method: { ...this.state.method, updateDate } }) }
                                            id='updateDate'
                                            label={ i18n.modificationDate }
                                            value={ this.state.method.updateDate }
                                            col={ 4 }
                                            { ...disabled }
                                            startDate={ this.state.method.creationDate }
                                        />
                                    </div>
                                    <div className='row no-margin padding-top-7-px'>
                                        <Input
                                            col={ 6 }
                                            title={ i18n.internationalName }
                                            value={ this.state.method.internationalName }
                                            onChange={ this.changeInternationalName }
                                            maxLength={ 255 }
                                            { ...disabled }
                                        />
                                        <Input
                                            col={ 6 }
                                            title={ i18n.author }
                                            value={ this.state.method.author }
                                            onChange={ this.changeAuthor }
                                            maxLength={ 50 }
                                            { ...disabled }
                                        />
                                    </div>
                                    <div className='row no-margin padding-top-3-px'>
                                        <Input
                                            col={ 12 }
                                            title={ i18n.extendedCaption }
                                            value={ this.state.method.longLabel }
                                            onChange={ this.changeLongLabel }
                                            maxLength={ 25 }
                                            { ...disabled }
                                        />
                                    </div>
                                    <div className='row no-margin padding-top-3-px'>
                                        <Input
                                            col={ 12 }
                                            title={ i18n.reference }
                                            value={ this.state.method.reference }
                                            onChange={ this.changeReference }
                                            { ...disabled }
                                        />
                                    </div>
                                    <div className='row no-margin padding-top-3-px'>
                                        <Textarea
                                            col={ 12 }
                                            title={ i18n.comment }
                                            value={ this.state.method.comment }
                                            onChange={ this.changeComment }
                                            { ...disabled }
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

MethodApp.propTypes = {
    params: PropTypes.shape({
        code: PropTypes.string,
    }),
    method: PropTypes.instanceOf(MethodDto),
    referencialStatus: PropTypes.instanceOf(DtoReferencial),
    getLink: PropTypes.func,
    isEnableEdit: PropTypes.bool,
    changeEditMode: PropTypes.func,
    isEditMode: PropTypes.bool,
}

const mapStateToProps = store => {
    return {
        method: store.MethodReducer.method,
        methods: store.MethodReducer.methods,
        referencialStatus: store.ReferencialReducer.referencialStatus,
    }
}

export default connect(mapStateToProps)(MethodApp)
