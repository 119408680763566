import VariousMaterielDto from '../dto/VariousMaterielDto'
import {
    RECEIVE_ALL_VARIOUS_MATERIEL_SITUATIONS,
    RECEIVE_ALL_VARIOUS_MATERIELS,
    RECEIVE_MAT_EVENTS_EXPLOITATION,
    RECEIVE_MAT_EVENTS_TYPE,
    RECEIVE_VARIOUS_MATERIEL,
    RECEIVE_VARIOUS_MATERIEL_FILES,
    RECEIVE_VARIOUS_MATERIEL_PICTURES,
    RECEIVE_VARIOUS_MATERIEL_TYPES,
    RECEIVE_VARIOUS_MATERIELS_LAST_SITUATIONS,
    RESET_VARIOUS_MATERIEL,
} from '../constants/VariousMaterielConstants'
import DtoVariousMaterielType from '../dto/DtoVariousMaterielType'
import DtoVariousMaterielSituation from '../dto/DtoVariousMaterielSituation'
import DtoPicture from '../../../../station/dto/DtoPicture'
import DtoFile from '../../../../components/file/dto/DtoFile'
import { RESET_EQUIPMENT } from '../../equipment/constants/EquipmentConstants'
import { RESET_ALL_MATERIELS } from '../../../constants/MaterielConstants'
import DtoMatEvent from '../dto/DtoMatEvent'
import DtoMatEventsType from '../dto/DtoMatEventsType'


export const store = {
    variousMateriels: [],
    variousMateriel: {},
    variousMaterielTypes: [],
    variousMaterielSituations: [],
    variousMaterielsLastSituations: [],
    variousMaterielPictures: [],
    variousMaterielFiles: [],
    matEventsExploitation: [],
    matEventsTypes: [],
}

export function VariousMaterielReducer(state = {}, action) {
    switch (action.type) {
        case RECEIVE_ALL_VARIOUS_MATERIELS:
            return {
                ...state,
                variousMateriels: action.variousMateriels.map(variousMateriel => new VariousMaterielDto(variousMateriel)),
            }
        case RECEIVE_VARIOUS_MATERIEL:
            return {
                ...state,
                variousMateriel: new VariousMaterielDto(action.variousMateriel),
            }
        case RECEIVE_VARIOUS_MATERIEL_TYPES:
            return {
                ...state,
                variousMaterielTypes: action.variousMaterielTypes.map(t => new DtoVariousMaterielType(t)),
            }
        case RECEIVE_ALL_VARIOUS_MATERIEL_SITUATIONS:
            return {
                ...state,
                variousMaterielSituations: action.variousMaterielSituations.map(t => new DtoVariousMaterielSituation(t)),
            }
        case RECEIVE_VARIOUS_MATERIELS_LAST_SITUATIONS:
            return {
                ...state,
                variousMaterielsLastSituations: action.variousMaterielsLastSituations.map(s => new DtoVariousMaterielSituation(s)),
            }
        case RECEIVE_VARIOUS_MATERIEL_PICTURES:
            return {
                ...state,
                variousMaterielPictures: action.variousMaterielPictures.map(el => new DtoPicture(el)),
            }
        case RECEIVE_VARIOUS_MATERIEL_FILES:
            return {
                ...state,
                variousMaterielFiles: action.variousMaterielFiles.map(el => new DtoFile(el)),
            }
        case RECEIVE_MAT_EVENTS_EXPLOITATION:
            return {
                ...state,
                matEventsExploitation: action.matEventsExploitation.map((e) => new DtoMatEvent(e)),
            }
        case RECEIVE_MAT_EVENTS_TYPE:
            return {
                ...state,
                matEventsTypes: action.matEventsTypes.map((t) => new DtoMatEventsType(t)),
            }
        case RESET_VARIOUS_MATERIEL:
            return {
                ...state,
                variousMateriel: {},
                variousMaterielSituations: [],
                variousMaterielPictures: [],
                variousMaterielFiles: [],
                matEventsExploitation: [],
            }
        case RESET_EQUIPMENT:
            return {
                ...state,
                variousMaterielTypes: [],
            }
        case RESET_ALL_MATERIELS:
            return {
                ...state,
                ...store,
            }
        default:
            return state
    }
}