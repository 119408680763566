import { Grid } from '@mui/material'
import ProgressBar from 'components/progress/ProgressBar'
import { orderBy } from 'lodash'
import React, { useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import StationAction from 'station/actions/StationAction'
import { STATION_TYPE_CONSTANT, STATION_TYPE_NAME } from 'station/constants/StationConstants'
import { getTextColorByBackground } from 'utils/ColorUtil'
import { darkBlue } from 'utils/constants/ColorTheme'
import useProgressDispatch from 'utils/customHook/useProgressDispatch'

const ThresholdIndicators = () => {
    const {
        associatedSites,
    } = useSelector(store => ({
        associatedSites: store.StationReducer.associatedSites,
    }), shallowEqual)

    const [indicators, setIndicators] = useState([])

    const dispatch = useDispatch()

    const { isLoaded, progress } = useProgressDispatch(() => {
        const associatedPiezos = associatedSites.filter(as => as.stationLinkedType === STATION_TYPE_CONSTANT.piezometer).map(({ stationLinkedId }) => stationLinkedId)
        const associatedHydros = associatedSites.filter(as => as.stationLinkedType === STATION_TYPE_CONSTANT.hydrometry).map(({ stationLinkedId }) => stationLinkedId)
        return [
            dispatch(StationAction.fetchSpecificObservatoryFollowResult(associatedPiezos, STATION_TYPE_NAME.piezometer)).then(newIndicators => setIndicators(prev => [...prev, ...newIndicators.flatMap(i => i.data)])),
            dispatch(StationAction.fetchSpecificObservatoryFollowResult(associatedHydros, STATION_TYPE_NAME.hydrologicalStation)).then(newIndicators => setIndicators(prev => [...prev, ...newIndicators.flatMap(i => i.data)])),
        ]
    }, [associatedSites])

    const orderedIndicators = orderBy(indicators, ({ color }) => {
        switch (color) {
            case 'white':
            case '#ffffff': return 0
            case 'gray': return 1
            default: return -1
        }
    })

    return isLoaded ? (
        <Grid container>
            {!!orderedIndicators.length && orderedIndicators.map(ofd => (
                <Grid
                    item
                    xs={12}
                    sx={{
                        backgroundColor: ofd.color,
                        padding: '3px',
                        marginBottom: '3px',
                        borderRadius: '3px',
                        textAlign: 'center',
                        color: getTextColorByBackground(ofd.color),
                        border: (ofd.color === 'white' || ofd.color === '#ffffff') && `solid 1px ${darkBlue}`,
                        fontSize: 10.5,
                        lineHeight: 1.5,
                        height: 'fit-content',
                    }}
                >
                    <span>{ofd.value}</span>
                </Grid>
            ))}
        </Grid>
    ) : (
        <ProgressBar progress={progress} withMessage />
    )
}

export default ThresholdIndicators