export default class DtoHydrometerChartOptions {
    constructor(object) {
        this.code = object.code
        this.minYear = object.minYear
        this.maxYear = object.maxYear
        this.dataType = object.dataType
        this.intervalYear = object.intervalYear
        this.displayXIntervalYear = object.displayXIntervalYear || '0'
        this.displayYIntervalYear = object.displayYIntervalYear || '0'
        this.displayData = object.displayData || false
        this.statsCalculationStartDate = object.statsCalculationStartDate
        this.statsCalculationEndDate = object.statsCalculationEndDate
    }
}