import ApplicationConf from 'conf/ApplicationConf'
import fetch from 'isomorphic-fetch'
import { chunk, flatten } from 'lodash'
import i18n from 'simple-react-i18n'
import ToastrAction from 'toastr/actions/ToastrAction'
import { checkAuth, genericPromise, getJson } from 'utils/ActionUtils'
import LogAction from '../../../../log/actions/LogAction'
import { checkError, getAuthorization, promiseAllProgress } from '../../../../utils/ActionUtils'
import {
    RECEIVE_ALL_ANALYSE,
    RECEIVE_ALL_ANALYSE_ERROR,
    RECEIVE_ALL_OPERATION,
    RESET_INTEGRATION_OVERVIEW,
    UPDATE_ANALYSIS_CONFORMITY,
} from '../constants/IntegrationOverviewConstants'

const QualityIntegrationOverviewAction = {
    resetIntegrationOverview: () => dispatch => dispatch({ type: RESET_INTEGRATION_OVERVIEW }),

    receiveOperations: operations => ({ type: RECEIVE_ALL_OPERATION, operations }),

    promiseOperations: () => fetch(ApplicationConf.operation.operations(), {
        method: 'GET',
        headers: getAuthorization(),
    })
        .then(checkAuth)
        .then(getJson)
        .then(checkError),

    fetchOperations: (callback = () => { }) => dispatch => QualityIntegrationOverviewAction.promiseOperations()
        .then(json => {
            checkError(json)
            dispatch(QualityIntegrationOverviewAction.receiveOperations(json))
            callback()
        }).catch(err => {
            dispatch(ToastrAction.error(i18n.fetchError + i18n.operations))
            dispatch(LogAction.logError(`${i18n.fetchError + i18n.operations} : ${err}`))
        }),

    promiseOperationsWithIds: (ids) => fetch(ApplicationConf.operation.operations(ids), {
        method: 'GET',
        headers: getAuthorization(),
    })
        .then(checkAuth)
        .then(getJson)
        .then(checkError),

    fetchOperationsWithIds: (ids, progressCallback = () => { }) => dispatch => {
        const operationPromise = (() => {
            if (ids) {
                const group = Math.min(Math.floor(ids.length / 20), 150)
                return chunk(ids, group > 0 ? group : 1).map(idsGroup => QualityIntegrationOverviewAction.promiseOperationsWithIds(idsGroup))
            }
            return [QualityIntegrationOverviewAction.promiseOperations()]
        })()
        return promiseAllProgress(operationPromise, progressCallback).then(jsonTab => {
            dispatch(QualityIntegrationOverviewAction.receiveOperations(flatten(jsonTab)))
        }).catch(err => {
            dispatch(ToastrAction.error(i18n.fetchError + i18n.operations))
            dispatch(LogAction.logError(`${i18n.fetchError + i18n.operations} : ${err}`))
        })
    },

    fetchQualitometersByJob: (filter) => dispatch => fetch(ApplicationConf.qualitometer.getByJob(filter), {
        method: 'POST',
        headers: getAuthorization(),
        body: JSON.stringify(filter),
    })
        .then(checkAuth)
        .then(getJson)
        .then(checkError)
        .catch(err => {
            dispatch(ToastrAction.error(i18n.fetchError + i18n.qualitometer))
            dispatch(LogAction.logError(`${i18n.fetchError + i18n.qualitometer} : ${err}`))
        }),

    receiveAnalysis: analysis => ({ type: RECEIVE_ALL_ANALYSE, analysis }),

    promiseAnalysis: filter => fetch(ApplicationConf.analysis.conformity(), {
        method: 'POST',
        headers: getAuthorization(),
        body: JSON.stringify(filter),
    })
        .then(checkAuth)
        .then(getJson)
        .then(checkError),

    fetchAnalysis: (filter) => dispatch => { // , ids, onProgress = () => {}
        // const groupSize = codeStations.length / 20 > 5 ? 20 : ceil(codeStations.length / 5)
        // const listCode = chunk(codeStations, groupSize)
        // const promises = listCode.map(stations => QualityIntegrationOverviewAction.promiseAnalysis({ ...filter, stations: codeStations }))
        return QualityIntegrationOverviewAction.promiseAnalysis({ ...filter }).then(jsonTab => {
            dispatch(QualityIntegrationOverviewAction.receiveAnalysis(flatten(jsonTab)))
        }).catch(err => {
            dispatch(ToastrAction.error(i18n.fetchError + i18n.analysisConformity))
            dispatch(LogAction.logError(`${i18n.fetchError + i18n.analysisConformity} : ${err}`))
        })
    },

    updateAnalysis: analysis => dispatch => fetch(ApplicationConf.analysis.conformity(), {
        method: 'PUT',
        headers: getAuthorization(),
        body: JSON.stringify(analysis),
    })
        .then(checkAuth)
        .then(getJson)
        .then(checkError)
        .then(json => {
            if (json.success) {
                dispatch({ type: UPDATE_ANALYSIS_CONFORMITY, payload: analysis })
                dispatch(ToastrAction.success(i18n.elementUpdateSuccess))
            } else {
                dispatch(ToastrAction.info(i18n.noUpdate))
            }
        })
        .catch(err => {
            dispatch(ToastrAction.error(i18n.updateError + i18n.analysisConformity))
            dispatch(LogAction.logError(`${i18n.updateError + i18n.analysisConformity} : ${err}`))
        }),

    receiveAnalyseErrorTypes: analyseErrorTypes => ({ type: RECEIVE_ALL_ANALYSE_ERROR, analyseErrorTypes }),

    promiseAnalyseErrorTypes: () => fetch(ApplicationConf.analysis.errorType(), {
        method: 'GET',
        headers: getAuthorization(),
    })
        .then(checkAuth)
        .then(getJson)
        .then(checkError),

    fetchAnalyseErrorTypes: () => dispatch => QualityIntegrationOverviewAction.promiseAnalyseErrorTypes()
        .then(json => {
            dispatch(QualityIntegrationOverviewAction.receiveAnalyseErrorTypes(json))
        }).catch(err => {
            dispatch(ToastrAction.error(i18n.fetchError + i18n.type))
            dispatch(LogAction.logError(`${i18n.fetchError + i18n.type} : ${err}`))
        }),

    promiseQualityIntegrationOverview: (criterias) => genericPromise(ApplicationConf.qualitometer.qualitoIntegrationOverview(), 'POST', criterias),

    loadQualityIntegrationOverview: (criterias, progressCallback = () => { }, cb = () => {}) => dispatch => {
        const groupsSize = criterias.stations.length / 50 > 20 ? criterias.stations.length / 20 : 50
        // const promises = take(chunk(criterias.stations, groupsSize), 2).map(stations => QualityIntegrationOverviewAction.promiseQualityIntegrationOverview({ ...criterias, stations }))
        const promises = chunk(criterias.stations, groupsSize).map(stations => QualityIntegrationOverviewAction.promiseQualityIntegrationOverview({ ...criterias, stations }))
        return promiseAllProgress([
            ...promises,
        ], progressCallback).then(jsonTab => {
            cb([jsonTab.flatMap(j => j.overview), jsonTab.flatMap(j => j.stats)])
        }).catch((err) => {
            dispatch(LogAction.logError(`${i18n.loadError} : ${err}`))
            dispatch(ToastrAction.error(i18n.loadError))
        })
    },
}

export default QualityIntegrationOverviewAction
