import { sum } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import { ACTION_COLOR } from 'station/constants/CampaignConstants'
import { mediumGrey } from 'utils/constants/ColorTheme'
import Axis from '../../../../components/echart/Axis'
import EChart from '../../../../components/echart/EChart'
import Bar from '../../../../components/echart/series/Bar'
import { getPercentage } from '../../../../utils/NumberUtil'

const ProgressHorizontalChart = ({
    data = [],
}) => {
    const total = sum(data.map(d => d.value))

    const series = data.map(d => {
        return Bar({
            data: [getPercentage(d.value, total)],
            stack: 'total',
            name: d.tooltip,
            itemStyle: {
                normal: {
                    color: ACTION_COLOR[d.colorCode] || mediumGrey,
                },
            },
            tooltip: {
                trigger: 'item',
                formatter: `${getPercentage(d.value, total)}% : {a}`,
            },
        })
    })

    const options = {
        series,
        xAxis: [Axis({
            type: 'value',
            min: 0,
            max: 100,
            show: false,
        })],
        yAxis: [Axis({
            type: 'category',
            show: false,
        })],
        height: 30,
        gridHeight: 50,
    }

    return (
        <div className='row no-margin'>
            <EChart options={ options } />
        </div>
    )
}

ProgressHorizontalChart.propTypes = {
    data: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.number,
        tooltip: PropTypes.string,
    })),
}

export default ProgressHorizontalChart
