import { useEffect, useState } from 'react'
import useApplicationSetting from './useApplicationSetting'

const useStateAppSetting = (field, parser = setting => setting, defaultValue) => {
    const appSetting = useApplicationSetting(field, parser)
    const [setting, setSetting] = useState(appSetting ?? defaultValue)

    useEffect(() => {
        setSetting(appSetting ?? defaultValue)
    }, [appSetting])

    return [setting, setSetting]
}

export default useStateAppSetting