import PropTypes from 'prop-types'
import React, { Component } from 'react'
import UserDto from '../../../administration/components/user/dto/UserDto'
import Table from '../../../components/datatable/Table'
import { nbPerPageLabel } from '../../../referencial/constants/ReferencialConstants'
import ProfileCard from '../../card/ProfileCard'
import { AccordionDetailsMUI, AccordionMUI, AccordionSummaryMUI } from '../../styled/Accordions'

class ProfileCardList extends Component {
    render() {
        const { users, title, open } = this.props
        const content = (() => {
            if (users.length <= 25) {
                return users.map((card, i) => (
                    <ProfileCard key={i} picture={card.picture} onClick={card.onClick} >
                        {card.content}
                    </ProfileCard>)
                )
            }
            return (
                <Table
                    showTitle={false}
                    data={users}
                    color={false}
                    nbPerPageLabel={nbPerPageLabel}
                    type={new UserDto({})}
                    condensed
                    sortable
                    paging
                    active={false}
                    onClick={(user) => this.props.onClickTable(user.login)}
                    round
                />
            )
        })()
        return (
            <AccordionMUI round className='margin-bottom-1' defaultExpanded={open}>
                <AccordionSummaryMUI round>{title}</AccordionSummaryMUI>
                <AccordionDetailsMUI style={{ padding: 0 }}>
                    {content}
                </AccordionDetailsMUI>
            </AccordionMUI>
        )
        // return this.getSuperComponent(tab)
    }
}

ProfileCardList.propTypes = {
    title: PropTypes.string.isRequired,
    users: PropTypes.arrayOf(PropTypes.instanceOf(UserDto)),
    onClickTable: PropTypes.func,
    open: PropTypes.bool,
}

export default ProfileCardList
