import { Button, ButtonGroup, Grid2, Icon as IconMui, Popover } from '@mui/material'
import moment from 'moment'
import PropTypes from 'prop-types'
import queryString from 'query-string'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import { MEASURE_COTE } from '../../piezometry/constants/PiezometryConstants'
import DtoMeasureStats from '../../station/dto/piezometer/DtoMeasureStats'
import { getDate } from '../../utils/DateUtil'
import { getLocalStorageJson, onChangeDate, setConfirmationModal, setLocalStorageJson } from '../../utils/FormUtils'
import { arrayOf, getComponentWithId, removeNullKeys } from '../../utils/StoreUtils'
import Checkbox from '../forms/Checkbox'
import Input from '../forms/Input'
import DtoIAEauModel from '../../iaeau/dto/DtoIAEauModel'
import { ButtonMUI } from 'components/styled/Buttons'
import { maxBy, orderBy, uniqBy, groupBy } from 'lodash'
import { H_24, H_48, J30, J365, J7, J90 } from 'alerting/constants/ChartFollowContants'
import IAEauAction from '../../iaeau/IAEauAction'
import Row from '../react/Row'
import AppStore from '../../store/AppStore'
import AlertAction from '../../alerting/actions/AlertAction'
import { execByType } from '../../utils/StationUtils'
import SimpleChartTabsPiezo from './SimpleChartTabsPiezo'
import { STATION_TYPE_NAME } from '../../station/constants/StationConstants'
import { PLUVIO_TAB_DISPLAY_MODES } from '../../pluviometry/constants/PluvioOptionConstant'
import { getSubstractTime } from './EChartUtils'

const CHART_TABS = 'CHART_TABS'
const HISTO = 'HISTO'
const OTHER = 'OTHER'
const DEFAULT_PERIOD = 30
class ChartTabs extends Component {
    constructor(props) {
        super()
        if (props.displayCote) {
            setLocalStorageJson('DISPLAY_COTE', props.displayCote)
        }
        const previousSelectedNoFilterHisto = props.default || getLocalStorageJson(CHART_TABS) || {}
        const previousSelected = previousSelectedNoFilterHisto.active === HISTO ? { active: 365 } : props.default || getLocalStorageJson(CHART_TABS) || {}
        const chartTab = props.location ? queryString.parse(props.location.search).dataType : 0
        this.otherTabPopoverRef = React.createRef()
        this.modelCalendarPopupRef = React.createRef()
        this.modelListPopupRef = React.createRef()
        const tmpDisplayCote = props.NGFMode ? MEASURE_COTE.NGF : (props.depthMode ? MEASURE_COTE.DEPTH : (getLocalStorageJson('DISPLAY_COTE') || MEASURE_COTE.DEPTH))
        this.state = {
            active: chartTab || previousSelected.active || DEFAULT_PERIOD,
            otherTabSelected: false,
            settingsTabSelected: false,
            settingsTabRef: undefined,
            maxDate: previousSelected.maxDate || moment().valueOf(),
            minDate: previousSelected.minDate,
            otherMaxDate: previousSelected.maxDate || moment().valueOf(),
            otherMinDate: previousSelected.minDate,
            displayCote: tmpDisplayCote === MEASURE_COTE.GROUND && props.noSol ? MEASURE_COTE.NGF : tmpDisplayCote,
            open: false,
            initialOptions: [],
            displayModes: getLocalStorageJson(props.stationType === STATION_TYPE_NAME.pluviometry ? PLUVIO_TAB_DISPLAY_MODES : 'PIEZO_TAB_DISPLAY_MODES') ?? {
                auto: true,
                brute: false,
                min: false,
                max: false,
                average: false,
            },
            predStats: [],
            selectedPred: {},
            modelListPopupOpen: false,
            // predDisplayMode: 'default', // mode d'affichage des données prédites ('default', 'multiple' ou 'horizon')
            // predDisplayHorizon: 1,
            // tmpPredMultipleDates: [],
        }
        const minDate = this.state.active === HISTO || this.state.active === OTHER ? null : moment().subtract(previousSelected.active, 'days').valueOf()
        const customMinDate = (() => {
            if (previousSelected.minDate) {
                return previousSelected.minDate
            }
            if (minDate) {
                if (!!parseInt(this.state.active) && parseInt(this.state.active) > 2) {
                    return moment(minDate).startOf('day').valueOf()
                }
                return minDate
            }
            return null
        })()
        props.onChangeDate({ minDate: previousSelected.active === OTHER ? customMinDate : minDate, maxDate: previousSelected.maxDate, chartTab: chartTab || previousSelected?.active }, true)
    }

    getInitialDisplayMode = () => ({
        auto: true,
        brute: false,
        min: false,
        max: false,
        average: false,
    })

    changeDisplayMode = (changes) => {
        const newDisplay = { ...this.state.displayModes, ...changes }
        if (!newDisplay.brute && !newDisplay.min && !newDisplay.max && !newDisplay.average) {
            newDisplay.auto = true
        } else {
            newDisplay.auto = false
        }
        if (this.props.displayModesUniqChoise) {
            const defaultDisplay = { max: false, min: false, average: false, brute: false }
            const newChanges = { ...defaultDisplay, ...changes }
            setLocalStorageJson(this.props.stationType === STATION_TYPE_NAME.pluviometry ? PLUVIO_TAB_DISPLAY_MODES : 'PIEZO_TAB_DISPLAY_MODES', newChanges)
            this.setState({ displayModes: newChanges })
        } else {
            setLocalStorageJson(this.props.stationType === STATION_TYPE_NAME.pluviometry ? PLUVIO_TAB_DISPLAY_MODES : 'PIEZO_TAB_DISPLAY_MODES', newDisplay)
            this.setState({ displayModes: newDisplay })
        }
    }

    resetChartZoom = () => {
        const chart = getComponentWithId('.sieauChart')
        if (chart) {
            chart.resetZoom()
        }
    }

    onChangeTimeScale = (scale) => {
        if (scale !== this.state.active) {
            this.resetChartZoom()
            setLocalStorageJson(CHART_TABS, { active: scale })
            this.setState({ active: scale, otherTabSelected: false })
            const minDate = getSubstractTime(scale)
            this.props.onChangeDate({ minDate, maxDate: undefined, chartTab: scale })
        }
    }

    onSelectOtherTab = () => {
        const changes = { otherTabSelected: !this.state.otherTabSelected, settingsTabSelected: false }
        if (this.state.minDate) {
            if (this.state.maxDate) {
                const newChanges = Object.assign({}, changes, { minDate: this.state.minDate, maxDate: this.state.maxDate, active: OTHER })
                this.setState(newChanges)
                setLocalStorageJson(CHART_TABS, newChanges)
                this.props.onChangeDate({ minDate: newChanges.minDate, maxDate: newChanges.maxDate })
            } else {
                const newChanges = Object.assign({}, changes, { minDate: this.state.minDate, active: OTHER })
                this.setState(newChanges)
                setLocalStorageJson(CHART_TABS, newChanges)
                this.props.onChangeDate({ minDate: newChanges.minDate, maxDate: moment().valueOf() })
            }
        } else {
            this.setState(changes)
        }
    }

    onSubmitOtherDates = () => {
        this.resetChartZoom()
        const changes = { minDate: this.state.otherMinDate, maxDate: this.state.otherMaxDate }
        const customChanges = removeNullKeys(Object.assign({}, {
            minDate: changes.minDate ? moment(changes.minDate).startOf('day').valueOf() : null,
            maxDate: changes.maxDate ? moment(changes.maxDate).endOf('day').valueOf() : null,
        }))
        const newChanges = Object.assign({}, this.state, customChanges, { otherTabSelected: false })
        if (newChanges.minDate && newChanges.maxDate) {
            const allChanges = Object.assign({}, newChanges, { active: OTHER })
            this.setState(allChanges)
            this.props.onChangeDate({ minDate: allChanges.minDate, maxDate: allChanges.maxDate, chartTab: OTHER })
        } else {
            this.setState(newChanges)
        }
    }

    setHisto = () => {
        this.checkConfirmation(true, () => {
            this.resetChartZoom()
            const obj = { active: HISTO, minDate: null }
            this.setState(obj)
            setLocalStorageJson(CHART_TABS, obj)
            this.props.onChangeDate({ minDate: null, maxDate: moment().valueOf(), chartTab: HISTO })
        })
    }

    onSubmitCote = () => {
        this.checkConfirmation(this.state.displayModes.brute, () => {
            const displayCote = this.state.displayCote
            setLocalStorageJson('DISPLAY_COTE', displayCote)
            this.setState({ settingsTabSelected: false })
            this.props.changeParent({ displayCote, displayModes: this.state.displayModes })
        })
    }

    setModal = (cb) => setConfirmationModal(cb, i18n.tooManyDataConfirmationMessage)

    checkConfirmation = (toCheck, cb) => {
        if (this.props.piezometerStatistics.length) {
            const statBrute = this.props.piezometerStatistics.find(stat => stat.typeId === -1)
            if (toCheck && statBrute && statBrute.countTotal > 10000) {
                this.setModal(cb)
            } else {
                cb()
            }
        } else if (this.props.hydroStatistics.length) {
            if (maxBy(this.props.hydroStatistics, c => c.countTotal).countTotal > 10000) {
                this.setModal(cb)
            } else {
                cb()
            }
        } else if (this.props.pluviometerStatistics.length) {
            if (maxBy(this.props.pluviometerStatistics, c => c.countTotal).countTotal > 10000) {
                this.setModal(cb)
            } else {
                cb()
            }
        } else {
            cb()
        }
    }

    getDisplayCote = () => {
        const displayCote = this.props.NGFMode ? MEASURE_COTE.NGF : (this.props.depthMode ? MEASURE_COTE.DEPTH : (getLocalStorageJson('DISPLAY_COTE') || MEASURE_COTE.DEPTH))
        const cote = (() => {
            if (this.props.simpleMode) {
                return { text: 'Valeur du capteur', className: 'blueAquasysActivated' }
            }
            switch (displayCote) {
                case MEASURE_COTE.DEPTH:
                    return { text: 'Profondeur / Dernier repère', className: 'blue' }
                case MEASURE_COTE.NGF:
                    return { text: 'NGF', className: 'indigo darken-3' }
                case MEASURE_COTE.GROUND:
                    return { text: 'Sol', className: 'orange' }
                case MEASURE_COTE.LANDMARK:
                    return { text: 'Historique des repères', className: 'brown darken-1' }
                default:
                    return null
            }
        })()
        return (
            <span
                onClick={e => {
                    e.preventDefault()
                    if (this.props.NGFMode || this.props.depthMode) {
                        return
                    }
                    this.setState({ settingsTabSelected: !this.state.settingsTabSelected, otherTabSelected: false, settingsTabRef: e.currentTarget })
                }}
                className={`margin-left-1 arrests-level-panel ${this.props.NGFMode || this.props.depthMode ? '' : 'cote-medal'} white-text ${cote.className}`}
                style={{ cursor: this.props.NGFMode || this.props.depthMode ? 'default' : 'pointer' }}
            >
                {cote.text}
            </span>
        )
    }

    componentDidMount() {
        this.fetchModels()
    }

    getStationStatistics = () => execByType(this.props.stationType, {
        piezometry: () => this.props.piezometerStatistics,
        hydrometry: () => this.props.hydroStatistics,
        pluviometry: () => this.props.pluviometerStatistics,
    })

    fetchModels = () => {
        if (this.props.withModels) {
            IAEauAction.promisePredStats(this.props.stationType, parseInt(this.props.id)).then(predStats => {
                const usedPredStats = this.props.useFromSituation ? predStats.filter(s => s.model?.displaySituation) : predStats
                const selecteds = uniqBy(orderBy(usedPredStats.filter(p => p.maxSimulationDate), 'maxSimulationDate', 'desc'), 'typeId')
                if (selecteds.length) {
                    // const lastSimulationDate = selected.maxSimulationDate
                    Promise.all(selecteds.map(s => IAEauAction.promiseModelMeasures(this.props.stationType, parseInt(this.props.id), s.idModel, s.source, s.maxSimulationDate))).then(resultsTab => {
                        const stateObj = resultsTab.reduce((acc, r, idx) => {
                            acc.selectedPred[selecteds[idx].typeId] = selecteds[idx]
                            acc.predMeasures[selecteds[idx].typeId] = r
                            return acc
                        }, { selectedPred: {}, predStats: usedPredStats, predMeasures: {} })
                        this.props.changeParentSimple(stateObj)
                        this.setState(stateObj)
                        if (this.props.useFromSituation) {
                            AppStore.dispatch(AlertAction.setThresholdPreviListData({
                                predMeasures: resultsTab[0],
                                thresholds: execByType(this.props.stationType, {
                                    piezometry: () => AppStore.getState().PiezometerStationReducer.piezometerThresholds.map(t => ({ ...t, value: selecteds[0].typeId === -1 ? t.NGF : t.value })),
                                    hydrometry: () => AppStore.getState().HydrometryReducer.hydrometryThresholds,
                                }),
                            }))
                        }
                    })
                } else {
                    this.props.changeParentSimple({ selectedPred: {}, predStats: usedPredStats })
                    this.setState({ selectedPred: {}, predStats: usedPredStats })
                }
            })
        }
    }

    changeModel = (pred, v) => {
        const newSelectedPred = { ...this.state.selectedPred }
        newSelectedPred[pred.typeId] = v ? pred : undefined
        this.setState({ selectedPred: newSelectedPred }, () => this.loadPredMeasures(pred))
    }

    loadPredMeasures = (pred) => {
        if (pred.maxSimulationDate) {
            IAEauAction.promiseModelMeasures(this.props.stationType, parseInt(this.props.id), pred.idModel, pred.source, pred.maxSimulationDate).then(results => {
                const { selectedPred, predMeasures } = this.state
                predMeasures[pred.typeId] = results
                this.props.changeParentSimple({ predMeasures, selectedPred, predStats: this.state.predStats })
            })
        }
    }

    // changePredDisplayMode = (changes) => {
    //     const { selectedPred } = this.state
    //     const newProps = { ...pick(this.props, ['predDisplayMode', 'predDisplayHorizon', 'predMultipleDates']), ...changes }
    //     if (newProps.predDisplayMode === 'default') {
    //         this.props.changeParentSimple(newProps)
    //         this.changePredDate(this.state.predDate)
    //     } else if (newProps.predDisplayMode === 'multiple') {
    //         Promise.all(newProps.predMultipleDates.map(d => IAEauAction.promiseModelMeasures('piezometry', parseInt(this.props.id), selectedPred.idModel, selectedPred.source, d)))
    //             .then(res => this.props.changeParentSimple({ ...newProps, predMultipleResults: res }))
    //     } else {
    //         IAEauAction.promiseModelMeasuresWithHorizon('piezometry', parseInt(this.props.id), selectedPred.idModel, selectedPred.source, newProps.predDisplayHorizon)
    //             .then(results => this.props.changeParentSimple({ ...newProps, predHorizonResults: results }))
    //     }
    // }

    // reloadModelResultsDates = () => {
    //     const { selectedMonth, selectedYear, selectedPred } = this.state
    //     IAEauAction.promiseModelResultDates('piezometry', parseInt(this.props.id), selectedPred.idModel, selectedPred.source, moment(selectedYear).month(moment(selectedMonth).month()).valueOf())
    //         .then(predResultDates => this.setState({ predResultDates }))
    // }

    getModelPart = () => {
        const { selectedPred, predStats } = this.state
        if (!predStats.length) {
            return null
        }
        const predStatsByTypeId = groupBy(orderBy(predStats, 'typeId'), 'typeId')
        return (
            <div className='padding-left-1'>
                <ButtonGroup>
                    <ButtonMUI
                        variant={'outlined'}
                        onClick={() => this.setState({ modelListPopupOpen: !this.state.modelListPopupOpen })}
                        ref={this.modelListPopupRef}
                        style={{ border: 'solid rgba(53, 96, 159, 1)', borderWidth: 2, fontWeight: 600 }}
                    ><IconMui style={{ fontSize: 22, color: 'rgba(53, 96, 159, 1)' }}>extension</IconMui></ButtonMUI>
                    {/* <ButtonMUI*/}
                    {/*    variant={'outlined'}*/}
                    {/*    onClick={() => this.changePredDate(this.state.predPrevResult)}*/}
                    {/*    style={{ border: predPrevResult ? 'solid rgba(53, 96, 159, 1)' : 'solid grey', borderWidth: 2, fontWeight: 600 }}*/}
                    {/* ><IconMui style={{ fontSize: 22, color: predPrevResult ? 'rgba(53, 96, 159, 1)' : 'grey' }}>keyboard_double_arrow_left</IconMui></ButtonMUI>*/}
                    {/* <ButtonMUI*/}
                    {/*    variant={'outlined'}*/}
                    {/*    onClick={() => this.setState({ modelCalendarPopupOpen: !this.state.modelCalendarPopupOpen })}*/}
                    {/*    ref={this.modelCalendarPopupRef}*/}
                    {/*    style={{ border: 'solid rgba(53, 96, 159, 1)', borderWidth: 2, fontWeight: 600 }}*/}
                    {/* ><IconMui style={{ fontSize: 22, color: 'rgba(53, 96, 159, 1)' }}>edit_calendar</IconMui></ButtonMUI>*/}
                    {/* <ButtonMUI*/}
                    {/*    variant={'outlined'}*/}
                    {/*    onClick={() => this.changePredDate(this.state.predSuccResult)}*/}
                    {/*    style={{ border: predSuccResult ? 'solid rgba(53, 96, 159, 1)' : 'solid grey', borderWidth: 2, fontWeight: 600 }}*/}
                    {/* ><IconMui style={{ fontSize: 22, color: predSuccResult ? 'rgba(53, 96, 159, 1)' : 'grey' }}>keyboard_double_arrow_right</IconMui></ButtonMUI>*/}
                </ButtonGroup>
                {/* <p style={{ fontSize: 17, color: '#000000d4', paddingLeft: 10 }}>{selectedPred.source} ({selectedPred.horizon} {i18n[selectedPred.horizonMode || 'days']}) - {getDate(predDate)}</p>*/}
                <Popover
                    open={this.state.modelListPopupOpen}
                    anchorEl={this.modelListPopupRef.current}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    onClose={() => this.setState({ modelListPopupOpen: false })}
                >
                    <div style={ { minWidth: 300, overflow: 'hidden' }}>
                        <Row className='padding-top-1 padding-left-1'>
                            {predStats.length} Série{predStats.length > 1 ? 's' : ''} de prévison disponible{predStats.length > 1 ? 's' : ''}
                        </Row>
                        {
                            Object.keys(predStatsByTypeId).map(key => {
                                const preds = predStatsByTypeId[key].map(pred => (
                                    <Row className='padding-top-1 valign-wrapper'>
                                        <div className='col s1'><Checkbox checked={selectedPred[key]?.source === pred.source} onChange={ (v) => this.changeModel(pred, v) } /></div>
                                        <div className='col s11'>
                                            <span className='arrests-level-panel' style={{ color: pred.color || 'black', backgroundColor: pred.color || 'black' }}>OO</span>
                                            {pred.source} ({pred.horizon} {i18n[pred.horizonMode || 'days']}) du {getDate(pred.maxSimulationDate)}
                                        </div>
                                    </Row>
                                ))
                                return (
                                    <>
                                        <Row className='padding-top-1 padding-left-1 valign-wrapper'>
                                            { this.getStationStatistics().find(stat => stat.typeId === parseInt(key))?.label ?? 'TYPE INCONNU' }
                                        </Row>
                                        {preds}
                                    </>
                                )
                            })
                        }
                    </div>
                </Popover>
            </div>
        )
    }

    render() {
        const buttons = [
            { title: i18n.h24, value: H_24, borderWidth: '2px 0px 2px 2px' },
            { title: i18n.h48, value: H_48, borderWidth: 2 },
            { title: i18n.j7, value: J7, borderWidth: '2px 1px' },
            { title: i18n.j30, value: J30, borderWidth: 2 },
            { title: i18n.j90, value: J90, borderWidth: '2px 1px' },
            { title: i18n.j365, value: J365, borderWidth: 2 },
            { title: i18n.histo, value: HISTO, borderWidth: '2px 2px 2px 1px' },
        ].map((o) => (
            <ButtonMUI
                variant={this.state.active === o.value ? 'contained' : 'outlined'}
                onClick={() => o.value === HISTO ? this.setHisto() : this.onChangeTimeScale(o.value)}
                style={{ border: 'solid rgba(53, 96, 159, 1)', borderWidth: o.borderWidth, fontWeight: 600 }}
            >{o.title}</ButtonMUI>
        )).concat(
            <span onClick={this.onSelectOtherTab}>
                <ButtonMUI
                    variant={this.state.active === OTHER ? 'contained' : 'outlined'}
                    style={{ marginLeft: '0.75rem', borderWidth: 2, borderColor: 'inherit', padding: '6px 15px', fontWeight: 600 }}
                    onClick={this.onSelectOtherTab}
                    ref={this.otherTabPopoverRef}
                >
                    {i18n.other}
                </ButtonMUI>
            </span>,
        )

        const {
            displayModes,
            displayCote,
            otherTabSelected,
            otherMinDate,
            otherMaxDate,
        } = this.state
        const {
            noAbsolute,
            stationType,
            withInitialDisplay,
            displayModesUniqChoise,
            style,
            withoutButtons,
            changeParent,
            hideCustom,
            hideAccuracy,
            noSol,
        } = this.props

        return (
            <Grid2
                container
                sx={{
                    position: noAbsolute ? 'relative' : 'absolute',
                    zIndex: 10,
                    ...style,
                }}
            >
                <Grid2
                    item
                    container
                    alignItems='center'
                    justifyContent='flex-start'
                    spacing={2}
                >
                    <ButtonGroup>
                        { withoutButtons ? null : buttons }
                    </ButtonGroup>
                    { this.getModelPart() }
                    <Popover
                        open={otherTabSelected}
                        anchorEl={this.otherTabPopoverRef.current}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        onClose={() => this.setState({ otherTabSelected: false })}
                    >
                        <Grid2 padding={1} style={{ width: '200px' }}>
                            <Grid2 size={12}>
                                <Input
                                    title={ i18n.startDate }
                                    value={ getDate(otherMinDate) }
                                    onChange={ v => onChangeDate(v, v2 => this.setState({ otherMinDate: v2 })) }
                                />
                            </Grid2>
                            <Grid2 size={12}>
                                <Input
                                    title={ i18n.endDate }
                                    value={ getDate(otherMaxDate) }
                                    onChange={ v => onChangeDate(v, v2 => this.setState({ otherMaxDate: v2 })) }
                                />
                            </Grid2>
                            <Grid2 size={12} paddingTop={1}>
                                <Button
                                    onClick={ this.onSubmitOtherDates }
                                    variant='contained'
                                    fullWidth
                                >
                                    { i18n.toLoad }
                                </Button>
                            </Grid2>
                        </Grid2>
                    </Popover>
                    {
                        (STATION_TYPE_NAME.piezometry === stationType) && (
                            <SimpleChartTabsPiezo
                                displayModes={displayModes}
                                displayCote={displayCote}
                                changeParent={changeParent}
                                withInitialDisplay={withInitialDisplay}
                                displayModesUniqChoise={displayModesUniqChoise}
                                hideCustom={hideCustom}
                                hideAccuracy={hideAccuracy}
                                noSol={noSol}
                            />
                        )
                    }
                </Grid2>
            </Grid2>
        )
    }
}

ChartTabs.propTypes = {
    onChangeDate: PropTypes.func,
    withPiezoCote: PropTypes.bool,
    changeParent: PropTypes.func,
    changeDisplayModes: PropTypes.func,
    changeDisplayCote: PropTypes.func,
    changeAccuracy: PropTypes.func,
    loadData: PropTypes.func,
    default: PropTypes.object,
    location: PropTypes.shape({
        pathname: PropTypes.string,
        search: PropTypes.object,
    }),
    displayCote: PropTypes.number,
    withInitialDisplay: PropTypes.bool,
    piezometerStatistics: arrayOf(DtoMeasureStats),
    hydroStatistics: arrayOf(DtoMeasureStats),
    pluviometerStatistics: arrayOf(DtoMeasureStats),
    simpleMode: PropTypes.bool,
    withoutButtons: PropTypes.bool,
    NGFMode: PropTypes.bool,
    depthMode: PropTypes.bool,
    style: PropTypes.object,
    predMeasures: arrayOf(DtoIAEauModel),
    withModels: PropTypes.bool,
    changeParentSimple: PropTypes.func,
    id: PropTypes.string,
    noAbsolute: PropTypes.bool,
    stationType: PropTypes.string,
    useFromSituation: PropTypes.bool,
    displayModesUniqChoise: PropTypes.bool,
    hideCustom: PropTypes.bool,
    hideAccuracy: PropTypes.bool,
    noSol: PropTypes.bool,
}

ChartTabs.defaultProps = {
    onChangeDate: () => {},
    style: {},
}

const mapStateToProps = store => {
    return {
        piezometerStatistics: store.PiezometerStationReducer.piezometerStatistics,
        hydroStatistics: store.HydrometryReducer.hydroStatistics,
        pluviometerStatistics: store.PluviometryReducer.pluviometerStatistics,

    }
}

export default connect(mapStateToProps)(ChartTabs)