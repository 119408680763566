import { RECEIVE_QUALITOMETER_BY_TAXON, RECEIVE_TAXON, RECEIVE_TAXONS, RESET_TAXON } from '../constants/TaxonConstant'
import TaxonDto from '../dto/TaxonDto'
import QualitometerListDto from '../dto/QualitometerListDto'

export function TaxonReducer(state = {}, action) {
    switch (action.type) {
        case RECEIVE_TAXONS:
            return {
                ...state,
                taxons: action.taxons.map(t => new TaxonDto(t)),
            }
        case RECEIVE_TAXON:
            return {
                ...state,
                taxon: new TaxonDto(action.taxon),
            }
        case RECEIVE_QUALITOMETER_BY_TAXON:
            return {
                ...state,
                qualitometers: action.qualitometers.map(q => new QualitometerListDto(q)),
            }
        case RESET_TAXON:
            return {
                ...state,
                taxon: {},
            }
        default:
            return state
    }
}

export const store = {
    taxon: {},
    taxons: [],
    taxonsIndex: {},
    qualitometers: [],
}