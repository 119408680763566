import { flatten, uniq } from 'lodash'
import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import ParameterAction from '../../../../../referencial/components/parameter/actions/ParameterAction'
import SelectSelection from '../SelectSelection'
import UpdateParameter from '../UpdateParameter'
import StepperDialog from 'components/modal/StepperDialog'
import { ButtonMUI } from 'components/styled/Buttons'
import { StepperSelection } from 'administration/components/qualitometer/selection/ComponentsOptionSelection'
import useBoolean from 'utils/customHook/useBoolean'
import useListIndexed from 'utils/customHook/useListIndexed'

const STEP_LIST = 0
const STEP_UPDATE_PARAM = 1

const DEFAULT_SELECTION = { listType: 0, users: [], administrators: [] }
const DEFAULT_QUALITY_SELECTION = { selections: [] }

const SelectionStepper = ({
    isOpen = false,
    onClose = () => {},
    onValidate = () => {},

    selectedSelections = [],
    selectedParameters = [],
    onChange = () => {},
}) => {
    const dispatch = useDispatch()

    const {
        parameters,
        selections,
    } = useSelector(store => ({
        parameters: store.ParameterReducer.parameters,
        selections: store.ParameterReducer.selectionsWithParam,
    }), shallowEqual)

    const indexedParameters = useListIndexed(parameters, 'code')

    const {
        value: isCreateSelectionOpen,
        setFalse: closeSelectionCreate,
        setTrue: openSelectionCreate,
    } = useBoolean(false)

    const onChangeSelections = (list = []) => {
        const selectionList = list.map(sCode => selections.find(({ code }) => sCode === code)).filter(s => !!s)
        const newSelectedParameters = uniq(flatten(selectionList.map(s => s.parameters))).map(paramCode => {
            const {
                code: parameterCode,
                unitReference1: unitCode,
            } = indexedParameters[paramCode] ?? {}
            return { parameterCode, unitCode }
        }).filter(({ parameterCode }) => !!parameterCode)
        onChange({ selectedSelections: list, selectedParameters: newSelectedParameters })
    }

    useEffect(() => {
        const selectionList = selectedSelections.map(sCode => selections.find(({ code }) => sCode === code)).filter(s => !!s)
        const newSelectedParameters = uniq(flatten(selectionList.map(s => s.parameters))).map(paramCode => {
            const {
                code: parameterCode,
                unitReference1: unitCode,
            } = indexedParameters[paramCode] ?? {}
            return { parameterCode, unitCode }
        }).filter(({ parameterCode }) => !!parameterCode)
        onChange({ selectedParameters: newSelectedParameters })
    }, [indexedParameters, selections])

    return (
        <>
            <StepperDialog
                defaultStep={STEP_LIST}
                steps={[
                    {
                        label: i18n.parametersSelectionsSelection,
                        constant: STEP_LIST,
                        nextAvailable: selectedParameters.length > 0,
                    },
                    {
                        label: i18n.parametersModification,
                        constant: STEP_UPDATE_PARAM,
                    },
                ]}
                open={isOpen}
                title={i18n.updateIndicator}
                closeDialog={onClose}
                renderSaveButton={step => (step === STEP_UPDATE_PARAM) && (
                    <ButtonMUI
                        onClick={onValidate}
                        variant='contained'
                    >
                        {i18n.validate}
                    </ButtonMUI>
                )}
                contentStyle={{ padding: '0 12px' }}
                sx={{
                    minWidth: '80vw',
                    minHeight: '95vh',
                }}
            >
                {
                    step => (
                        <>
                            {
                                step === STEP_LIST && (
                                    <SelectSelection
                                        selectedSelections={selectedSelections}
                                        onChange={onChangeSelections}
                                        onCreate={openSelectionCreate}
                                    />
                                )
                            }
                            {
                                step === STEP_UPDATE_PARAM && (
                                    <UpdateParameter
                                        selectedParameters={selectedParameters}
                                        onChange={onChange}
                                    />
                                )
                            }
                        </>
                    )
                }
            </StepperDialog>
            <StepperSelection
                isOpen={isCreateSelectionOpen}
                onClose={closeSelectionCreate}
                onSave={(selection, qualitySelection) => {
                    const newSelection = { ...selection, parameters: qualitySelection.selections.map(qs => qs.parameterCode) }
                    dispatch(ParameterAction.createSelection(newSelection)).then(() => {
                        onChange({ selectedSelections: [...selectedSelections, selection.code] })
                        dispatch(ParameterAction.fetchSelectionsWithParam()).finally(closeSelectionCreate)
                    })
                }}
                selectedSelection={DEFAULT_SELECTION}
                selectedQualitySelection={DEFAULT_QUALITY_SELECTION}
            />
        </>
    )
}

SelectionStepper.propTypes = {
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
    onValidate: PropTypes.func,
    onChange: PropTypes.func,

    selectedSelections: PropTypes.arrayOf(PropTypes.string),
    selectedParameters: PropTypes.arrayOf(PropTypes.shape({
        parameterCode: PropTypes.string,
        fractionCode: PropTypes.string,
        supportCode: PropTypes.string,
        unitCode: PropTypes.string,
        placeCode: PropTypes.string,
    })),
}

export default SelectionStepper
