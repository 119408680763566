import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import Card from 'components/card/Card'
import Row from 'components/react/Row'
import { Button, Grid } from '@mui/material'
import Input from 'components/forms/Input'
import Checkbox from 'components/forms/Checkbox'
import MultiContributorsAutocomplete from 'referencial/components/contributor/components/MultiContributorsAutocomplete'
import SuperMultiAutocomplete from 'components/forms/SuperMultiAutocomplete'

const emptyFilter = {
    startDate: null,
    endDate: null,
    status: null,
}

const FormFilterUsers = ({
    onValidate = () => { },
}) => {
    const [tmpFilter, setTmpFilter] = useState(emptyFilter)

    const {
        cities,
        contributors,
    } = useSelector(store => ({
        cities: store.CityReducer.cities,
        contributors: store.ContributorReducer.contributors,
    }), shallowEqual)

    const onReset = () => {
        onValidate(emptyFilter)
        setTmpFilter(emptyFilter)
    }

    return (
        <Row>
            <Card className='row' data-cy='usersFormFilter' round>
                <Row>
                    <div className='col s4 margin-top-1'>
                        <Input
                            title={i18n.search}
                            value={tmpFilter.searchValue}
                            onChange={v => setTmpFilter({ ...tmpFilter, searchValue: v })}
                            onEnterKeyPress={() => onValidate(tmpFilter)}
                        />
                    </div>
                    <div className='col s4 margin-top-1'>
                        <MultiContributorsAutocomplete
                            col={12}
                            multiple
                            options={contributors}
                            onChange={values => setTmpFilter({ ...tmpFilter, contributors: values })}
                        />
                    </div>
                    <div className='col s4 margin-top-1'>
                        <SuperMultiAutocomplete
                            col={12}
                            label={i18n.city}
                            options={cities}
                            keyLabel='labelWithCode'
                            onChange={values => setTmpFilter({ ...tmpFilter, cities: values })}
                            values={tmpFilter.cities}
                            multiple
                        />
                    </div>
                </Row>
                <Row>
                    <Checkbox
                        col={2}
                        label={i18n.admin}
                        checked={tmpFilter.isAdmin || false}
                        onChange={ v => setTmpFilter({ ...tmpFilter, isAdmin: v }) }
                    />
                    <Checkbox
                        col={2}
                        label={i18n.administrator}
                        checked={tmpFilter.metadata || false}
                        onChange={ v => setTmpFilter({ ...tmpFilter, metadata: v }) }
                    />
                    <Checkbox
                        col={2}
                        label={i18n.technicien}
                        checked={tmpFilter.labo || false}
                        onChange={ v => setTmpFilter({ ...tmpFilter, labo: v }) }
                    />
                    <Checkbox
                        col={2}
                        label={i18n.consultant}
                        checked={tmpFilter.consultant || false}
                        onChange={ v => setTmpFilter({ ...tmpFilter, consultant: v }) }
                    />
                    <Checkbox
                        col={3}
                        label={i18n.usersApplication}
                        checked={tmpFilter.applicativeUsers || false}
                        onChange={ v => setTmpFilter({ ...tmpFilter, applicativeUsers: v }) }
                    />
                </Row>
                <Grid container className='padding-1' justifyContent='flex-end'>
                    <Grid item>
                        <Button variant='outlined' onClick={onReset}>
                            {i18n.reinit}
                        </Button>
                    </Grid>
                    <Grid item className='padding-left-1'>
                        <Button variant='contained' onClick={() => onValidate(tmpFilter)}>
                            {i18n.search}
                        </Button>
                    </Grid>
                </Grid>
            </Card>
        </Row>
    )
}

FormFilterUsers.propTypes = {
    onValidate: PropTypes.func,
}

export default FormFilterUsers