import React, { Component, createRef } from 'react'
import PropTypes from 'prop-types'
import { v4 as uuidv4 } from 'uuid'
import { searchAllCharacters } from '../../utils/StringUtil'
import { isEqualBy, sieauTooltip } from '../../utils/FormUtils'
import { Chip } from '@mui/material'
import Icon from 'components/icon/Icon'
import { hasValue } from 'utils/NumberUtil'
import { groupBy } from 'lodash'
import i18n from 'simple-react-i18n'

class MultiAutocomplete extends Component {
    constructor(props) {
        super(props)
        this.input = createRef()
        this.inputId = props.id || searchAllCharacters(props.label + uuidv4()).replaceAll(' ', '_').replace('numero', '').replace('nom', 'libelle')
        this.state = {
            searchValue: '',
            focus: false,
        }
    }

    onClick(event) {
        if (!this.props.disabled && !event.currentTarget.contains(event.relatedTarget)) {
            this.setFocus()
        }

        if (!this.props.disabled && !this.state.focus) {
            this.setState({ focus: true }, this.setFocus)
        }
    }

    setFocus() {
        this.input.current.focus()
    }

    onBlur(event) {
        if (!this.props.disabled && !event.currentTarget.contains(event.relatedTarget)) {
            this.setState({ focus: false })
        }
    }

    onChangeValue = (event) => {
        this.setState({ searchValue: event.target.value })
    }

    onKeyDown = (event) => {
        if (event.key === 'Escape') {
            this.input.current.blur()
        } else if (event.key === 'Enter') {
            const searchElements = this.getSearchElements()
            if (searchElements && searchElements.length) {
                this.addNewValue(this.getId(searchElements[0]))
            }
        }
    }

    callOnChange(values) {
        this.setFocus()
        if (this.props.onChange) {
            this.props.onChange(values)
            this.setState({ searchValue: '' })
        }
    }

    clearInput() {
        if (!this.props.disabled) {
            this.setState({ searchValue: '' }, () => this.callOnChange([]))
        }
    }

    addNewValue(id) {
        if (!this.props.values.includes(id)) {
            this.callOnChange([...this.props.values, id])
        }
    }

    toggleValue(id) {
        if (!this.props.values.includes(id)) {
            this.callOnChange([...this.props.values, id])
        } else {
            this.callOnChange(this.props.values.filter(value => value !== id))
        }
    }

    deleteValue(id) {
        const { values } = this.props
        if (values.includes(id)) {
            this.callOnChange(values.filter(value => value !== id))
        }
    }

    getSearchElements() {
        const { elements } = this.props
        if (elements?.length) {
            return elements.filter(element => searchAllCharacters(this.getName(element)).includes(searchAllCharacters(this.state.searchValue)))
        }
        return []
    }

    getId(element) {
        const { keyId } = this.props
        if (hasValue(element?.[keyId])) {
            return element?.[keyId]
        } else if (hasValue(element.code)) {
            return element.code
        } else if (hasValue(element.id)) {
            return element.id
        }
        return null
    }

    getName(element) {
        const { keyName, displayWithCode } = this.props
        const code = displayWithCode ? this.getId(element) : null
        const codeShown = hasValue(code) && code !== '' ? ` [${code}]` : ''
        return (element?.[keyName] || element?.mnemonique || element?.name || element?.label || '') + codeShown
    }

    getElementFromId(id) {
        return this.props.elements.find(element => this.getId(element) === id)
    }

    getDropdownElement(element) {
        return (
            <li
                className={`mAutocomplete__dropdown_element ${this.props.colorizeSelectedValue && this.props.values.includes(this.getId(element)) ? 'selected_value' : ''}`}
                key={this.getId(element)}
                onClick={() => this.toggleValue(this.getId(element))}
            >
                {this.getName(element)}
            </li>
        )
    }

    getDropdown() {
        const { group } = this.props
        const searchElements = this.getSearchElements()
        const dropdownElements = (() => {
            if (group) {
                const searchGroupedElements = groupBy(searchElements, 'type')
                return Object.keys(searchGroupedElements).flatMap(key => {
                    const groupName = <li style={{ fontWeight: 'bolder', fontStyle: 'italic', padding: '5px 10px' }} key={key}>{key !== 'undefined' ? key : <>&nbsp;</>}</li>
                    const options = searchGroupedElements[key].map(element => this.getDropdownElement(element))
                    return [groupName, ...options]
                })
            }
            return searchElements.map(element => this.getDropdownElement(element))
        })()
        return (
            <ul className='mAutocomplete__dropdown'>
                {dropdownElements}
            </ul>
        )
    }

    getChips() {
        const {
            values,
            maxChips,
            disabled,
        } = this.props
        if (values.length <= maxChips) {
            return values.map(value => {
                const element = this.getElementFromId(value)
                return element && (
                    <Chip className='mAutocomplete__chip' size='small' label={this.getName(element)} key={this.getId(element)} variant='outlined' onDelete={!disabled ? () => this.deleteValue(this.getId(element)) : null} />
                )
            })
        }
        const chips = values.slice(0, maxChips).map(value => {
            const element = this.getElementFromId(value)
            return element && (
                <Chip className='mAutocomplete__chip' size='small' label={this.getName(element)} key={this.getId(element)} variant='outlined' onDelete={!disabled ? () => this.deleteValue(this.getId(element)) : null} />
            )
        })
        const maxTooltip = maxChips + 10
        const contentToolTip = (
            <ul className='no-margin'>
                {values.slice(maxChips, maxTooltip).map(value => {
                    const element = this.getElementFromId(value)
                    return <li key={this.getId(element)}>{this.getName(element)}</li>
                })}
                {values.length >= maxTooltip && <li>...</li>}
            </ul>
        )
        const lastChip = (
            <Chip className='mAutocomplete__chip' size='small' label={`+${this.props.values.length - this.props.maxChips}`} {...sieauTooltip(() => contentToolTip, null, 'bottom')} />
        )

        return [...chips, lastChip]
    }

    getAutocomplete() {
        const {
            focus,
            searchValue,
        } = this.state
        const {
            label,
            tooltip,
            className,
            disabled,
            clearFunction,
            obligatory,
        } = this.props
        const tooltipObj = tooltip ? sieauTooltip(() => tooltip) : null
        return (
            <div className={`input-field ${className}`} tabIndex='0'
                onClick={event => this.onClick(event)}
                onBlur={event => this.onBlur(event)}
            >
                <div
                    className={`mAutocomplete ${disabled ? 'disabled' : ''}`}
                    style={{
                        ...(focus ? { borderColor: '#53A1FF', boxShadow: '0 1px 0 0 #53A1FF' } : {}),
                    }}
                    {...tooltipObj}
                >
                    <div
                        className='mAutocomplete__chip_container'
                        style={(clearFunction ? { marginRight: '25px' } : {})}
                    >
                        {this.getChips()}
                        <input
                            className='mAutocomplete__input'
                            type='text'
                            disabled={disabled}
                            id={this.inputId}
                            autoComplete='off'
                            value={searchValue}
                            onChange={this.onChangeValue}
                            onKeyDown={this.onKeyDown}
                            ref={this.input}
                        />
                    </div>
                    {clearFunction &&
                        <div className='mAutocomplete__clear_container'>
                            <Icon icon='close' className='mAutocomplete__clear_icon' onClick={() => this.clearInput()} />
                        </div>
                    }
                    {focus && !disabled && this.getDropdown()}
                </div>
                {label && <label style={{ color: (focus ? '#53A1FF' : (disabled ? 'rgba(0, 0, 0, 0.42)' : '#161832')) }}>{label}{obligatory && i18n.obligatoryField}</label>}
            </div>
        )
    }

    render() {
        if (this.props.col) {
            return (
                <div className={`col s${this.props.col}`}>
                    {this.getAutocomplete()}
                </div>
            )
        }
        return this.getAutocomplete()
    }

    shouldComponentUpdate(nextProps) {
        if (this.props.freezeOpti && isEqualBy(this.props, nextProps, ['values', 'col', 'disabled'])) {
            return false
        }
        return true
    }
}

MultiAutocomplete.propTypes = {
    group: PropTypes.bool,
    elements: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.any,
        name: PropTypes.string,
    })).isRequired,
    displayWithCode: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    values: PropTypes.arrayOf(PropTypes.any),
    className: PropTypes.string,
    col: PropTypes.number,
    id: PropTypes.string,
    disabled: PropTypes.bool,
    label: PropTypes.string,
    tooltip: PropTypes.element,
    keyName: PropTypes.string,
    keyId: PropTypes.string,
    maxChips: PropTypes.number,
    colorizeSelectedValue: PropTypes.bool,
    clearFunction: PropTypes.bool,
    freezeOpti: PropTypes.bool,
    obligatory: PropTypes.bool,
}

MultiAutocomplete.defaultProps = {
    group: false,
    values: [],
    maxChips: 5,
    className: '',
    disabled: false,
    colorizeSelectedValue: true,
    label: '',
}

export default MultiAutocomplete