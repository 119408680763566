import PropTypes from 'prop-types'
import React, { Component } from 'react'
import i18n from 'simple-react-i18n'
import Checkbox from '../../../../components/forms/Checkbox'
import Select from '../../../../components/forms/Select'
import { setModal } from '../../../../utils/FormUtils'
import Job from '../../../dto/DtoJob'
import ImportFileModal from './ImportFileModal'
import { integrationModeHelpIcon } from '../../../utils/ImportUtils'
import { connect } from 'react-redux'
import CampaignAction from '../../../../campaign/actions/CampaignAction'
import CampaignDto from '../../../../campaign/dto/CampaignDto'
import { getIntegrationModes } from '../../../../utils/JobUtils'

class ImportQuesuPanel extends Component {
    constructor(props) {
        super(props)
        const parameters = props.job.parameters.parameters
        this.state = {
            directory: undefined,
            filter: undefined,
            type: undefined,
            rename: false,

            ...(parameters.length ? JSON.parse(parameters[0]) : {}),
        }
    }
    componentDidMount = () => {
        this.props.fetchQualityCampaigns()
    }

    onChangeParameter = obj => {
        const newParameter = {
            ...this.state,
            ...obj,
        }
        this.setState(newParameter)
        this.props.handleChangeParameters([JSON.stringify(newParameter)])
    }

    getDataTypes = type => {
        const { parameters } = this.props.job
        if (parameters.dataTypes && parameters.dataTypes.length) {
            return parameters.dataTypes.find(d => d.includes(type)) || ''
        }
        return ''
    }

    setAdvancedFtpModal = () => {
        setModal({
            title: i18n.importParam,
            content: <ImportFileModal url={this.state.directory} onChange={name => this.setState({ filter: name })} />,
        })
    }

    handleChangeDataIntegration = integration => {
        const { parameters } = this.props.job
        const newDataTypes = (() => {
            const data = parameters.dataTypes.filter(d => !d.includes('DATA'))
            if (integration) {
                return [...data, integration]
            }
            return data
        })().filter(s => !s.includes('data'))
        this.props.onChangeDataTypes(newDataTypes)
    }

    handleChangeOpenDataIntegration = integration => {
        const { parameters } = this.props.job
        const data = parameters.dataTypes
        const newDataTypes = integration ? [...data, 'openDataIntegration'] : data.filter(d => d !== 'openDataIntegration')
        this.props.onChangeDataTypes(newDataTypes)
    }

    handleChangeStationIntegration = integration => {
        const { parameters } = this.props.job
        const newDataTypes = (() => {
            const data = parameters.dataTypes.filter(d => !d.includes('station'))
            if (integration) {
                return [...data, 'stationIntegration']
            }
            return data
        })()
        this.props.onChangeDataTypes(newDataTypes)
    }

    render() {
        const disabled = { disabled: !this.props.isEditMode }

        return (
            <div>
                <br />
                <div className='row no-margin valign-wrapper'>
                    <Select
                        value={this.getDataTypes('DATA')}
                        options={getIntegrationModes()}
                        label={i18n.dataIntegration}
                        labelSpan={integrationModeHelpIcon()}
                        col={4}
                        onChange={this.handleChangeDataIntegration}
                        {...disabled}
                    />
                    <Select
                        value={this.state.campaignId}
                        options={this.props.qualityCampaigns.filter(cs => cs.campaignType === 3)}
                        label={i18n.campaign}
                        col={4}
                        onChange={v => this.onChangeParameter({ campaignId: v })}
                        {...disabled}
                    />
                    <div className='col s4' />
                </div>
                <div className='row no-margin valign-wrapper'>
                    <div className='col s4'>
                        <Checkbox
                            checked={this.getDataTypes('openDataIntegration') === 'openDataIntegration'}
                            label={i18n.useAqua6BO}
                            onChange={this.handleChangeOpenDataIntegration}
                            {...disabled}
                        />
                    </div>
                    <div className='col s4'>
                        <Checkbox
                            checked={this.state.rename}
                            label={i18n.fileRenaming}
                            onChange={v => this.onChangeParameter({ rename: v })}
                            {...disabled}
                        />
                    </div>
                    <div className='col s4'>
                        <Checkbox
                            checked={this.state.validation}
                            label={i18n.formatValidationxsd}
                            onChange={v => this.onChangeParameter({ validation: v })}
                            {...disabled}
                        />
                    </div>
                </div>
                <div className='row no-margin valign-wrapper'>
                    <div className=' input-field col s3'>
                        <label>{i18n.defineDirectory}</label>
                    </div>
                    <div className='input-field col s9'>
                        <input
                            id='txtDirDef'
                            type='text'
                            value={this.state.directory}
                            onChange={e => this.props.isEditMode && this.onChangeParameter({ directory: e.target.value })}
                            placeholder={i18n.selectDirectory}
                        />
                        <a className='btn btn-small secondary-color-back' onClick={() => this.setAdvancedFtpModal()} ><span title={i18n.importLabelFtp}> <i className='material-icons'>input </i> </span></a>
                    </div>
                </div>
                <div className='row no-margin valign-wrapper'>
                    <div className='input-field col s3'>
                        <label>{i18n.filter}</label>
                    </div>
                    <div className='input-field col s9'>
                        <input
                            id='txtFilter'
                            type='text'
                            value={this.state.filter}
                            onChange={e => this.props.isEditMode && this.onChangeParameter({ filter: e.target.value })}
                            placeholder={i18n.filterExampleQuesu}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

ImportQuesuPanel.propTypes = {
    job: PropTypes.instanceOf(Job).isRequired,
    isEditMode: PropTypes.bool,
    handleChangeParameters: PropTypes.func.isRequired,
    onChangeDataTypes: PropTypes.func.isRequired,
    fetchQualityCampaigns: PropTypes.func,
    qualityCampaigns: PropTypes.arrayOf(PropTypes.instanceOf(CampaignDto)),
}

const mapStateToProps = store => ({
    qualityCampaigns: store.QualityReducer.qualityCampaigns,
})

const mapDispatchToProps = {
    fetchQualityCampaigns: CampaignAction.fetchQualityCampaigns,
}

export default connect(mapStateToProps, mapDispatchToProps)(ImportQuesuPanel)
