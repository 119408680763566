import { round, uniq } from 'lodash'
import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import MultiContributorsAutocomplete from 'referencial/components/contributor/components/MultiContributorsAutocomplete'
import i18n from 'simple-react-i18n'
import useSessionStorage from 'utils/customHook/useSessionStorage'
import { sieauTooltip } from 'utils/FormUtils'
import Checkbox from '../../../components/forms/Checkbox'
import Input from '../../../components/forms/Input'
import NumberField from '../../../components/forms/NumberField'
import Select from '../../../components/forms/Select'
import CampaignSelect from '../../../components/forms/specific/CampaignSelect'
import { LIST_PANEL, MAP_PANEL } from '../../constants/MaterielConstants'

const SensorFilterForm = ({
    filter = {
        searchValue: '',
        administrator: undefined,
        state: undefined,
        displayOutOfService: false,
        manufacturer: undefined,
        network: undefined,
        matType: undefined,
        length: undefined,
        range: undefined,
        displayEquipment: false,
    },
    view = LIST_PANEL,
    materiels = [],
    typeList = [],
    changeView = () => { },
    onValidate = () => { },
}) => {
    const {
        materielStates,
        contributors,
        networks,
    } = useSelector(store => ({
        contributors: store.ContributorReducer.contributors,
        materielStates: store.MaterielReducer.materielStates,
        networks: store.NetworkReducer.networks,
    }), shallowEqual)

    const [searchValue, setSearchValue] = useSessionStorage('SENSOR_SEARCH_VALUE', filter.searchValue)
    const [administrator, setAdministrator] = useSessionStorage('SENSOR_ADMINISTRATOR', filter.administrator)
    const [state, setState] = useSessionStorage('SENSOR_STATE', filter.state)
    const [displayOutOfService, setDisplayOutOfService] = useSessionStorage('SENSOR_DISPLAY_OUT_OF_SERVICE', filter.displayOutOfService)
    const [manufacturer, setManufacturer] = useSessionStorage('SENSOR_MANUFACTURER', filter.manufacturer)
    const [network, setNetwork] = useSessionStorage('SENSOR_NETWORK', filter.network)
    const [matType, setMatType] = useSessionStorage('SENSOR_TYPE', filter.matType, true)
    const [length, setLength] = useSessionStorage('SENSOR_LENGTH', filter.length)
    const [range, setRange] = useSessionStorage('SENSOR_RANGE', filter.range)
    const [displayEquipment, setDisplayEquipment] = useSessionStorage('SENSOR_DISPLAY_EQUIPMENT', filter.displayEquipment)

    useEffect(() => {
        onValidate({
            searchValue,
            administrator,
            state,
            manufacturer,
            network,
            matType,
            length,
            range,
            displayEquipment,
            displayOutOfService,
        })
    }, [])

    const stateCodes = uniq(materiels.map(m => m.statusCode)).filter(code => displayOutOfService || (code !== 0 && code !== 5 && code !== 6))
    const filteredStates = stateCodes.map(code => materielStates.find(c => c.code === code)).filter(s => !!s)

    const manufacturerIds = uniq(materiels.map(m => m.manufacturerId))
    const filteredManufacturers = manufacturerIds.map(id => contributors.find(c => c.id === id)).filter(c => !!c)

    const administratorIds = uniq(materiels.map(m => m.administratorId))
    const filteredAdministrators = administratorIds.map(id => contributors.find(c => c.id === id)).filter(c => !!c)

    const networkIds = uniq(materiels.map(m => m.networkCode))
    const filteredNetworks = networkIds.map(id => networks.find(c => c.id === id)).filter(n => !!n)

    return (
        <div className='col s12 card'>
            <div className='row no-margin padding-top-2 padding-bottom-1'>
                <div className='col s6'>
                    <Input
                        col={8}
                        title={i18n.search}
                        value={searchValue}
                        onChange={setSearchValue}
                    />
                    <MultiContributorsAutocomplete
                        col={8}
                        label={i18n.administrator}
                        options={filteredAdministrators}
                        values={administrator}
                        onChange={setAdministrator}
                        keyLabel='labelDisplay'
                        keyValue='id'
                    />
                    <Input
                        col={8}
                        title={i18n.scaleInBar}
                        value={range}
                        onChange={setRange}
                    />
                    <div className='col s12 no-padding valign-wrapper'>
                        <Select
                            col={8}
                            label={i18n.state}
                            options={filteredStates}
                            value={state}
                            onChange={setState}
                            keyLabel='label'
                            keyValue='code'
                            integerValue
                            returnNull
                        />
                        <Checkbox
                            col={4}
                            label={i18n.displayOutOfService}
                            tooltip={i18n.displayOutOfService}
                            checked={displayOutOfService}
                            onChange={v => {
                                setDisplayOutOfService(v)
                                if (!v && (state === 0 || state === 5 || state === 6)) {
                                    setState(undefined)
                                }
                            }}
                        />
                    </div>
                    <CampaignSelect
                        col={8}
                        changeFilter={() => { }}
                    />
                </div>
                <div className='col s6'>
                    <MultiContributorsAutocomplete
                        col={12}
                        label={i18n.manufacturer}
                        options={filteredManufacturers}
                        values={manufacturer}
                        onChange={setManufacturer}
                        keyLabel='labelDisplay'
                        keyValue='id'
                    />
                    <Select
                        col={12}
                        label={i18n.network}
                        options={filteredNetworks}
                        value={network}
                        onChange={setNetwork}
                        keyLabel='name'
                        keyValue='id'
                        integerValue
                        returnNull
                    />
                    <NumberField
                        col={12}
                        title={i18n.lengthInMetter}
                        value={length}
                        onChange={v => setLength(round(v, 2))}
                        floatValue
                    />
                    <Select
                        col={12}
                        label={i18n.materielTypes}
                        options={typeList}
                        value={matType}
                        onChange={setMatType}
                        keyLabel='label'
                        keyValue='id'
                        integerValue
                        returnNull
                    />
                    <div className='col s12 row no-margin'>
                        <Checkbox
                            col={7}
                            label={i18n.displayEquipments}
                            checked={displayEquipment}
                            onChange={setDisplayEquipment}
                        />
                        <a
                            className={'col s3 btn'}
                            onClick={() => onValidate({
                                searchValue,
                                administrator,
                                state,
                                manufacturer,
                                network,
                                matType,
                                length,
                                range,
                                displayEquipment,
                                displayOutOfService,
                            })}
                        >
                            {i18n.search}
                        </a>
                        <div className='col s2'>
                            {
                                view === MAP_PANEL && (
                                    <i className='material-icons right blue-text clickable map-right-icon' {...sieauTooltip(i18n.table)}
                                        onClick={() => changeView(LIST_PANEL)}
                                    >
                                        list
                                    </i>
                                )
                            }
                            {
                                view === LIST_PANEL && (
                                    <i className='material-icons right blue-text clickable map-right-icon' {...sieauTooltip(i18n.map)}
                                        onClick={() => changeView(MAP_PANEL)}
                                    >
                                        map
                                    </i>
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

SensorFilterForm.propTypes = {
    onValidate: PropTypes.func,
    changeView: PropTypes.func,
    materiels: PropTypes.arrayOf(PropTypes.shape({
        statusCode: PropTypes.number,
        manufacturerId: PropTypes.number,
        administratorId: PropTypes.number,
        networkCode: PropTypes.number,
    })),
    typeList: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        label: PropTypes.string,
    })),
    filter: PropTypes.shape({
        searchValue: PropTypes.string,
        network: PropTypes.string,
        state: PropTypes.string,
        administrator: PropTypes.number,
        manufacturer: PropTypes.number,
        matType: PropTypes.number,
        displayOutOfService: PropTypes.bool,
    }),
    view: PropTypes.string,
}

export default SensorFilterForm