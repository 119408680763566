export default class DtojobTable {
    constructor(json) {
        this.jobId = json[0]
        this.jobExecutionId = json[1]
        this.jobType = json[2]
        this.jobName = json[3]
        this.status = json[4]
        this.scheduled = json[5]
        this.cron = json[6]
        this.date = json[7]
        this.login = json[8]
        this.jobNextId = json[9]
        this.jobPreviousId = json[10]
        this.managers = json[11]
    }
}