import React, { useMemo } from 'react'
import { connect, shallowEqual, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import SubscriptionDto from '../dto/SubscriptionDto'
import ContributorDto from '../../../../referencial/components/contributor/dto/ContributorDto'
import Select from '../../../../components/forms/Select'
import Input from '../../../../components/forms/Input'
import Textarea from '../../../../components/forms/Textarea'
import { getMatTypes } from '../../../../utils/MaterielUtils'
import NetworkDto from '../../../../referencial/components/network/dto/NetworkDto'
import SubscriptionTypeDto from '../dto/SubscriptionTypeDto'
import { authorizeContributors, authorizeFilter } from 'utils/HabilitationUtil'
import User from 'account/dto/User'
import MultiContributorsAutocomplete from 'referencial/components/contributor/components/MultiContributorsAutocomplete'
import { SUBSCRIPTION_ID } from 'materiel/constants/MaterielConstants'
import { DATE_SERVICE_OBLIGATOIRE, NUMLINE_OBLIGATOIRE } from 'materiel/constants/MaterielSettingRuleConstant'
import { isFieldRequired } from 'materiel/utils/MaterielUtils'
import SimpleDatePicker from 'components/forms/SimpleDatePicker'

const SubscriptionsPanel = ({
    subscription = {},
    subscriptionTypes = [],
    active = false,
    disabled = true,
    contributors = [],
    accountUser = {},
    networks = [],
    onChange = () => { },
}) => {
    const {
        materielSettingRules,
    } = useSelector(store => ({
        materielSettingRules: store.MaterielReducer.materielSettingRules,
    }), shallowEqual)

    const rules = useMemo(() => {
        return materielSettingRules.filter(({ idMaterielType }) => idMaterielType === SUBSCRIPTION_ID)
    }, [materielSettingRules])

    return (
        <div className='col s12 margin-bottom-1' >
            <div className='row no-margin' style={{ paddingTop: '3px' }}>
                <Select
                    options={getMatTypes(subscriptionTypes, subscription.subscriptionType)}
                    label={i18n.subscriptionType}
                    col={4}
                    onChange={v => onChange({ subscriptionType: v })}
                    value={subscription.subscriptionType}
                    nullLabel='&nbsp;'
                    active={active}
                    disabled={disabled}
                    obligatory
                />
                <MultiContributorsAutocomplete
                    options={authorizeContributors(contributors, accountUser, subscription.operatorCode)}
                    label={i18n.provider}
                    col={4}
                    onChange={ope => onChange({ operatorCode: ope })}
                    values={subscription.operatorCode}
                    disabled={disabled}
                    keyLabel='labelDisplay'
                />
                <Input
                    col={4}
                    title={i18n.lineNumber}
                    value={subscription.numLine}
                    onChange={value => onChange({ numLine: value })}
                    active={active}
                    disabled={disabled}
                    obligatory={isFieldRequired(rules, NUMLINE_OBLIGATOIRE)}
                />
            </div>
            <div className='row no-margin' style={{ paddingTop: '3px' }}>
                <Select
                    options={authorizeFilter(networks, accountUser, subscription.networkCode)}
                    label={i18n.network}
                    col={12}
                    onChange={value => onChange({ networkCode: value })}
                    value={subscription.networkCode}
                    nullLabel='&nbsp;'
                    active={active}
                    disabled={disabled}
                />
            </div>
            <div className='row no-margin' style={{ paddingTop: '3px' }}>
                <SimpleDatePicker
                    id='openingDate'
                    label={i18n.openDate}
                    value={subscription.openingDate}
                    onChange={v => onChange({ openingDate: v })}
                    col={3}
                    max={subscription.closingDate}
                    disabled={disabled}
                    obligatory={isFieldRequired(rules, DATE_SERVICE_OBLIGATOIRE)}
                />
                <SimpleDatePicker
                    id='closingDate'
                    label={i18n.closeDateLine}
                    value={subscription.closingDate}
                    onChange={v => onChange({ closingDate: v })}
                    col={3}
                    min={subscription.openingDate}
                    disabled={disabled}
                />
                <Input
                    col={3}
                    title={i18n.pathNumber}
                    value={subscription.numPath}
                    onChange={v => onChange({ numPath: v })}
                    active={active}
                    disabled={disabled}
                />
            </div>
            <div className='row no-margin' style={{ paddingTop: '8px' }}>
                <Textarea
                    col={12}
                    title={i18n.comment}
                    value={subscription.comment}
                    onChange={value => onChange({ comment: value })}
                    active={active}
                    disabled={disabled}
                />
            </div>
        </div >
    )
}

SubscriptionsPanel.propTypes = {
    disabled: PropTypes.bool,
    active: PropTypes.bool,
    onChange: PropTypes.func,
    subscription: PropTypes.instanceOf(SubscriptionDto),
    subscriptionTypes: PropTypes.arrayOf(PropTypes.instanceOf(SubscriptionTypeDto)),
    contributors: PropTypes.arrayOf(PropTypes.instanceOf(ContributorDto)),
    networks: PropTypes.arrayOf(PropTypes.instanceOf(NetworkDto)),
    accountUser: PropTypes.instanceOf(User),
}

const mapStateToProps = store => ({
    contributors: store.ContributorReducer.contributors,
    subscriptionTypes: store.SubscriptionReducer.subscriptionTypes,
    networks: store.NetworkReducer.networks,
    accountUser: store.AccountReducer.accountUser,
})

export default connect(mapStateToProps)(SubscriptionsPanel)
