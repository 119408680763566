import HomeAction from 'home/actions/HomeAction'
import BoundaryError from 'log/components/BoundaryError'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Outlet } from 'react-router-dom'
import i18n from 'simple-react-i18n'
import AppStore from 'store/AppStore'
import {
    H_QUALITO_CAMPAIGN,
    H_QUALITO_CONFORMITY,
    H_QUALITO_DASHBOARD,
    H_QUALITO_EXPORT,
    H_QUALITO_GRAPHIC,
    H_QUALITO_IMPORT,
    H_QUALITO_INTEGRATION,
    H_QUALITO_OPTIONS,
    H_QUALITO_RESEARCH,
} from '../../../account/constants/AccessRulesConstants'
import User from '../../../account/dto/User'
import FixedSlideNav from '../../../components/navbar/FixedSlideNav'
import { componentHasHabilitations } from '../../../utils/HabilitationUtil'
import { links } from '../../constants/StationsConstants'

class QualitometersApp extends Component {
    componentWillMount() {
        AppStore.dispatch(HomeAction.setHelpLink('qualite', '7'))
    }

    getLink = (linkName) => {
        const link = links[linkName]
        return {
            ...link,
            href: link.href || `/quality/${link.hrefName || linkName}`,
            name: i18n[link.i18n],
        }
    }

    getLinksHabilitations = () => {
        return [
            { linkName: 'dashboard', habilitation: H_QUALITO_DASHBOARD },
            { linkName: 'import', habilitation: H_QUALITO_IMPORT },
            { linkName: 'export', habilitation: H_QUALITO_EXPORT },
            { linkName: 'integrationOverview', habilitation: H_QUALITO_INTEGRATION },
            { linkName: 'graphic', habilitation: H_QUALITO_GRAPHIC },
            { linkName: 'research', habilitation: H_QUALITO_RESEARCH },
            { linkName: 'campaign', habilitation: H_QUALITO_CAMPAIGN },
            { linkName: 'conformityOverview', habilitation: H_QUALITO_CONFORMITY },
            { linkName: 'qualityOptions', habilitation: H_QUALITO_OPTIONS },
        ].filter(l => componentHasHabilitations(l.habilitation))
    }

    render() {
        const baseLinks = this.getLinksHabilitations()
        const navBarLinks = baseLinks.map(a => this.getLink(a.linkName))
        return (
            <div className='row no-margin'>
                <FixedSlideNav links={navBarLinks}/>
                <main>
                    <div className='row no-margin padding-left-1'>
                        <div className='content-page no-padding fullscreen'>
                            <BoundaryError>
                                <Outlet />
                            </BoundaryError>
                        </div>
                    </div>
                </main>
            </div>
        )
    }
}

QualitometersApp.propTypes = {
    children: PropTypes.element,
    getLink: PropTypes.func,
    accountUser: PropTypes.instanceOf(User),
}

const mapStateToProps = store => ({
    accountUser: store.AccountReducer.accountUser,
})

export default connect(mapStateToProps)(QualitometersApp)
