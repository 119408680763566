import { push } from '@lagunovsky/redux-react-router'
import HomeAction from 'home/actions/HomeAction'
import BoundaryError from 'log/components/BoundaryError'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Outlet } from 'react-router-dom'
import i18n from 'simple-react-i18n'
import AppStore from 'store/AppStore'
import { H_QUALITO_OPTIONS, H_QUALITO_OPTIONS_INDICATOR, H_QUALITO_OPTIONS_SELECTION, H_QUALITO_OPTIONS_THRESHOLD } from '../../../account/constants/AccessRulesConstants'
import FixedSlideNav from '../../../components/navbar/FixedSlideNav'
import { componentHasHabilitations } from '../../../utils/HabilitationUtil'

class QualityOptionsApp extends Component {
    componentDidMount() {
        if (!componentHasHabilitations(H_QUALITO_OPTIONS)) { // A modifier quand react-router sera à jour
            this.props.push('/unauthorized')
            return
        }
        AppStore.dispatch(HomeAction.setHelpLink('qualite', '7'))
    }

    render() {
        const base = '/quality/qualityOptions/'
        const links = {
            generalOptions: {
                href: `${base}generalOptions`,
                icons: 'dashboard',
                name: i18n.generalOptions,
            },
            qualityIndicators: {
                href: `${base}indicators`,
                icons: 'spellcheck',
                name: i18n.qualityIndicators,
            },
            selections: {
                href: `${base}selection`,
                icons: 'group_work',
                name: i18n.selections,
            },
            thresholds: {
                href: `${base}threshold`,
                icons: 'more',
                name: i18n.thresholds,
            },
        }
        const navBarLinks = [
            { habilitation: H_QUALITO_OPTIONS, link: 'generalOptions' },
            { habilitation: H_QUALITO_OPTIONS_INDICATOR, link: 'qualityIndicators' },
            { habilitation: H_QUALITO_OPTIONS_SELECTION, link: 'selections' },
            { habilitation: H_QUALITO_OPTIONS_THRESHOLD, link: 'thresholds' },
        ]
            .filter(l => componentHasHabilitations(l.habilitation))
            .map(l => links[l.link])
            .filter(l => !!l)

        return (
            <div className='row no-margin'>
                <FixedSlideNav links={navBarLinks}/>
                <main>
                    <div className='row no-margin'>
                        <div className='content-page no-padding fullscreen'>
                            <BoundaryError>
                                <Outlet />
                            </BoundaryError>
                        </div>
                    </div>
                </main>
            </div>
        )
    }
}

QualityOptionsApp.defaultProps = {
    getLink: ('', ''),
}

QualityOptionsApp.propTypes = {
    children: PropTypes.element,
    getLink: PropTypes.func,
    popup: PropTypes.element,
    push: PropTypes.func,
}

const mapDispatchToProps = {
    push,
}

export default connect(null, mapDispatchToProps)(QualityOptionsApp)
