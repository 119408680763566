import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import Input from '../../../../../components/forms/Input'
import Select from '../../../../../components/forms/Select'
import SimpleDatePicker from '../../../../../components/forms/SimpleDatePicker'
import Row from '../../../../../components/react/Row'
import { SANDRE } from '../../../../../referencial/constants/ReferencialConstants'
import { getSandreList } from '../../../../../utils/StoreUtils'
import DtoCodificationContributor from '../../../../dto/DtoCodificationContributor'
import DtoSandreCode from '../../../../dto/DtoSandreCode'

class CodificationModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            codification: { ...props.codification, idContributor: props.id },
        }
    }

    componentDidUpdate(prevProps) {
        if (!prevProps.popupIsOpen && this.props.popupIsOpen) {
            $('#descriptionModal').on('click', this.onSave)
        }
    }
    onSave = () => {
        this.props.saveResult(new DtoCodificationContributor(this.state.codification))
        $('.modal').modal('close')
    }

    onChangeElement = (changes) => this.setState({ codification: { ...this.state.codification, ...changes } })

    render() {
        const { codification } = this.state
        return (
            <div>
                <Row>
                    <SimpleDatePicker
                        col={6}
                        id='startDate'
                        label={i18n.startDate}
                        value={codification.startDate}
                        onChange={(v) => this.onChangeElement({ startDate: v })}
                    />
                    <SimpleDatePicker
                        col={6}
                        id='endDate'
                        label={i18n.endDate}
                        value={codification.endDate}
                        onChange={(v) => this.onChangeElement({ endDate: v })}
                    />
                </Row>
                <Row className='padding-top-1'>
                    <Select
                        col={ 6 }
                        label={ i18n.codificationType }
                        value={ codification.codificationType }
                        options={ getSandreList(this.props.sandreCodes, SANDRE.INTERVENANTS_TYPE_CODIFICATION)}
                        keyvalue='code'
                        integerValue
                        onChange={ v => this.onChangeElement({ codificationType: v }) }
                    />
                    <Input col={ 6 } title={ i18n.codification } value={ codification.codification } onChange={ v => this.onChangeElement({ codification: v }) } />
                </Row>
            </div>
        )
    }
}

CodificationModal.propTypes = {
    codification: PropTypes.instanceOf(DtoCodificationContributor),
    id: PropTypes.number,
    sandreCodes: PropTypes.arrayOf(PropTypes.instanceOf(DtoSandreCode)),
    popupIsOpen: PropTypes.bool,
    saveResult: PropTypes.func,
}


const mapStateToProps = store => ({
    sandreCodes: store.ReferencialReducer.sandreCodes,
    popupIsOpen: store.SieauReducer.popupIsOpen,
})

CodificationModal.defaultProps = {
    contributor: {},
}

export default connect(mapStateToProps)(CodificationModal)
