import moment from 'moment'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import ToastrAction from 'toastr/actions/ToastrAction'
import Table from '../../../components/datatable/Table'
import DatePicker from '../../../components/forms/DatePicker'
import Input from '../../../components/forms/Input'
import AdesStationDto from '../../../import/dto/AdesStationDto'
import Job from '../../../import/dto/DtoJob'
import { nbPerPageLabelTiny } from '../../../referencial/constants/ReferencialConstants'
import { Buffer } from 'buffer'

class ExportJob extends Component {
    constructor(props) {
        super(props)
        this.state = {
            filters: this.getJsonFilters(props.job.parameters.filters),
        }
    }

    getJsonFilters = filters => {
        return filters.length !== 0 ? JSON.parse(filters[0]) : {}
    }

    onDeleteBssCode = ({ bsscode }) => {
        if (this.props.isEditMode) {
            const codes = this.props.job.parameters.parameters.filter(p => p != bsscode)
            this.props.addBssCode(codes)
        }
    }

    addBssCode = () => {
        if (this.props.isEditMode) {
            const code = this.refs.txtAddBssCode.value
            const exist = this.props.job.parameters.parameters.find(p => p.toLowerCase() == code.toLowerCase())
            if (!code) {
                this.props.toastError(i18n.youMustEnterAtLeastOneBssCode)
            } else if (exist) {
                this.props.toastError(i18n.youHaveAlreadyEnteredThisBssCode)
            } else {
                this.props.addBssCode([code, ...this.props.job.parameters.parameters ])
                this.refs.txtAddBssCode.value = ''
            }
        }
    }

    onChangeBssFile = e => {
        if (this.props.isEditMode) {
            const reader = new FileReader()
            const file = e.target.files[0]
            reader.onload = upload => {
                const split = upload.target.result.split(',')
                if (split[0].indexOf('text/csv') > 0 || split[0].indexOf('vnd.ms-excel') > 0) {
                    const buf = Buffer.from(split[1], 'base64').toString()
                    const codes = buf
                        .replace(/;/g, '')
                        .split('\n')
                        .map(b => b.trim())
                    const codesToAdd = [...this.props.job.parameters.parameters, ...codes ]
                    this.props.addBssCode(codesToAdd)
                } else {
                    this.props.toastError(i18n.theSelectedFileMustBeInCsvFormat)
                }
                this.refs.importFile.value = ''
            }
            reader.readAsDataURL(file)
        }
    }

    getStations = () => {
        return this.props.job.parameters.parameters.map(bsscode => new AdesStationDto({ bsscode }))
    }

    handleChangefilters = (key, value) => {
        this.setState({ filters: { ...this.state.filters, [key]: value } })
        this.props.onChangeFilters([JSON.stringify({ ...this.state.filters, [key]: value }) ])
    }

    handleChangeEmails = value => {
        const emails = value ? value.split(';') : []
        this.props.onChangeEmails(emails)
    }

    getEmails = () => {
        return this.props.job.parameters.emails ? this.props.job.parameters.emails.join(';') : ''
    }

    getFilter = key => this.state.filters[key] || ''

    render() {
        const disabled = { disabled: !this.props.isEditMode }
        return (
            <div>
                <div className='row no-margin'>
                    <div className='col s12 no-padding padding-bottom-1'>
                        <DatePicker
                            col={3}
                            title={i18n.startDate}
                            onChange={(id, v) => this.handleChangefilters(id, moment(v).valueOf())}
                            id='startDate'
                            value={this.getFilter('startDate')}
                            endDate={this.getFilter('endDate')}
                            {...disabled}
                        />
                        <DatePicker
                            col={3}
                            title={i18n.endDate}
                            onChange={(id, v) => this.handleChangefilters(id, moment(v).valueOf())}
                            id='endDate'
                            value={this.getFilter('endDate')}
                            startDate={this.getFilter('startDate')}
                            {...disabled}
                        />
                        <Input
                            col={6}
                            title={i18n.siretNumber}
                            value={this.getFilter('siretNumber')}
                            onChange={v => this.handleChangefilters('siretNumber', v)}
                            {...disabled}
                        />
                    </div>
                    <div className='col s12 no-padding padding-bottom-1'>
                        <Input
                            col={6}
                            title={i18n.version}
                            value={this.getFilter('version')}
                            defqultValue={1}
                            onChange={v => this.handleChangefilters('version', v)}
                            {...disabled}
                        />
                        <Input
                            col={6}
                            title={i18n.exportDirectory}
                            value={this.getFilter('exportDirectory')}
                            onChange={v => this.handleChangefilters('exportDirectory', v)}
                            {...disabled}
                        />
                    </div>
                    <Input
                        col={12}
                        title={i18n.emailSelection}
                        value={this.getEmails()}
                        onChange={this.handleChangeEmails}
                        {...disabled}
                    />
                </div>
                <div className='row no-margin valign-wrapper'>
                    <div className='col s3'>
                        <label>{i18n.importFile}</label>
                    </div>
                    <div className='file-field col s9 no-padding input-field'>
                        <div className='col s3 no-padding btn'>
                            <span>{i18n.importLabel}</span>
                            <input
                                type='file'
                                ref='importFile'
                                accept='.csv'
                                onChange={this.onChangeBssFile}
                                disabled={!this.props.isEditMode}
                            />
                        </div>
                        <div className='file-path-wrapper col s9'>
                            <input
                                className='file-path'
                                ref='fileInput'
                                type='text'
                                placeholder={i18n.selectFile}
                                disabled={!this.props.isEditMode}
                            />
                        </div>
                    </div>
                </div>
                <div className='row valign-wrapper'>
                    <div className='col s3'>
                        <label>{i18n.addCredential}</label>
                    </div>
                    <div className='input-field col s9 no-padding'>
                        <div className='col s8'>
                            <input
                                id='txtAddBssCode'
                                type='text'
                                placeholder={i18n.BSSExample}
                                ref='txtAddBssCode'
                            />
                            <label className='tinyLabel'>
                                {i18n.theCodeMustBePresentInTheRepositoryAndDefinedByTheCode}
                            </label>
                        </div>
                        <a className='waves-effect waves-light btn col offset-s1 s3' onClick={this.addBssCode}>
                            {i18n.add}
                        </a>
                    </div>
                </div>
                <div className='row valign-wrapper'>
                    <div className='col s12'>
                        <Table
                            type={new AdesStationDto()}
                            data={this.getStations()}
                            title={i18n.credentials}
                            nbPerPageLabel={nbPerPageLabelTiny}
                            onDelete={this.onDeleteBssCode}
                            deletable={this.props.isEditMode}
                            showNbElements
                            paging
                            exportButtonOnHeader
                        />
                    </div>
                </div>
            </div>
        )
    }
}

ExportJob.propTypes = {
    job: PropTypes.instanceOf(Job).isRequired,
    addBssCode: PropTypes.func.isRequired,
    isEditMode: PropTypes.bool,
    onChangeDataTypes: PropTypes.func,
    onChangeFilters: PropTypes.func,
    onChangeEmails: PropTypes.func,
    toastError: PropTypes.func,
}

const mapDispatchToProps = {
    toastError: ToastrAction.error,
}

export default connect(
    null,
    mapDispatchToProps
)(ExportJob)
