export default class DtoHydrometricBruteMeasure {
    constructor(obj = {}) {
        this.creationDate = obj.creationDate
        this.date = obj.creationDate || obj.date
        this.hour = obj.hour
        this.value = obj.value
        this.flow = obj.flow
        this.speed = obj.speed
        this.pcAnalysis = obj.pcAnalysis
        this.hbAnalysis = obj.hbAnalysis
        this.continuity = obj.continuity
        this.obtainingMode = obj.obtainingMode
        this.precision = obj.precision
        this.validity = obj.validity
        this.qualification = obj.qualification
        this.nature = obj.nature
    }
}