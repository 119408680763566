'use strict'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { setCartographyBackground } from './mapTreatments/AddingLayers'
import i18n from 'simple-react-i18n'
import { homeSelectedBackground } from 'carto/constants/CartoConstants'
import { connect } from 'react-redux'
import AdministrationAction from '../../administration/actions/AdministrationAction'
import SettingDto from '../../administration/dto/SettingDto'

class CartographyBackgroundPanel extends Component {
    state = {
        dataLoaded: false,
        backgroundType: 'OSM',
    }

    getSelectedBackgroundParameter = () => {
        const { settings } = this.props
        const selectedBackgroundParameter = settings.find(s => s.parameter === homeSelectedBackground)?.value
        return selectedBackgroundParameter
    }

    getCartographyBackground = () => {
        const selectedBackgroundParameter = this.getSelectedBackgroundParameter()
        if (selectedBackgroundParameter) {
            return selectedBackgroundParameter
        }
        return 'OSM'
    }

    saveCartographyBackground = (background) => {
        this.props.updateSetting(homeSelectedBackground, background)
    }

    getCheckBox = (label, layerName) => {
        return (
            <div className='switch row'>
                <label>
                    <div className='row no-margin valign-wrapper' onClick={() => {
                        setCartographyBackground(layerName)
                        this.saveCartographyBackground(layerName)
                        this.setState({ backgroundType: layerName })
                    }}
                    >
                        <div className='col s10 bold font-size-16 black-text' >
                            { label }
                        </div>
                        <div className='col s2'>
                            <input className='sieau-input' type='radio' id={layerName} checked={this.state.backgroundType === layerName} />
                            <label htmlFor={layerName} />
                        </div>
                    </div>
                </label>
            </div>
        )
    }

    setBackgroundState = () => {
        const back = this.getCartographyBackground()
        setCartographyBackground(back)
        this.setState({ backgroundType: back, dataLoaded: true })
    }

    componentDidMount = () => {
        const { settings } = this.props
        if (!settings.length) {
            this.props.fetchSettings().then(() => {
                this.setBackgroundState()
            })
        } else {
            this.setBackgroundState()
        }
    }

    render() {
        const { dataLoaded } = this.state
        if (dataLoaded) {
            return (
                <div onClick={(e) => e.stopPropagation()}>
                    <div className='card'>
                        <div className='card-title activator'>
                            { i18n.backgroundLayers }
                        </div>
                        <div className='card-content padding-left-0 padding-top-1'>
                            <div>
                                { this.getCheckBox('Open Street Map', 'OSM') }
                            </div>
                            <div>
                                { this.getCheckBox('Carte Routière', 'ROADMAP') }
                            </div>
                            <div>
                                { this.getCheckBox('Satellite', 'SATELLITE') }
                            </div>
                            <div>
                                { this.getCheckBox('Relief', 'RELIEF') }
                            </div>
                            <div>
                                { this.getCheckBox('IGN', 'IGN') }
                            </div>
                            <div>
                                { this.getCheckBox('Fond blanc', 'WHITE') }
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        return null
    }
}

CartographyBackgroundPanel.propTypes = {
    settings: PropTypes.arrayOf(PropTypes.instanceOf(SettingDto)),
    fetchSettings: PropTypes.func,
    updateSetting: PropTypes.func,
}

const mapStateToProps = (store) => ({
    settings: store.AdministrationReducer.settings,
})

const mapDispatchToProps = {
    fetchSettings: AdministrationAction.fetchSettings,
    updateSetting: AdministrationAction.updateSetting,
}

export default connect(mapStateToProps, mapDispatchToProps)(CartographyBackgroundPanel)