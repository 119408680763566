module.exports = {
    RECEIVE_ALL_SENSORS: 'RECEIVE_ALL_SENSORS',
    RECEIVE_SENSOR: 'RECEIVE_SENSOR',
    RESET_SENSOR: 'RESET_SENSOR',
    RECEIVE_SENSORS_LAST_SITUATIONS: 'RECEIVE_SENSORS_LAST_SITUATIONS',
    RECEIVE_ALL_SENSOR_SITUATIONS: 'RECEIVE_ALL_SENSOR_SITUATIONS',
    RECEIVE_SENSOR_TYPES: 'RECEIVE_SENSOR_TYPES',
    RECEIVE_SENSOR_PICTURES: 'RECEIVE_SENSOR_PICTURES',
    RECEIVE_SENSOR_FILES: 'RECEIVE_SENSOR_FILES',
}
