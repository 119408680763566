module.exports = {
    MATERIEL_STATE_HS_ID: 0,
    MATERIEL_STATE_ON_SITE_ID: 1,
    MATERIEL_STATE_IN_STOCK_ID: 2,
    MATERIEL_STATE_MAINTENANCE_ID: 3,
    MATERIEL_STATE_PROVISIONAL_ID: 4,
    MATERIEL_STATE_REFORMED_ID: 5,
    MATERIEL_STATE_TRANSFERT_ID: 6,
    MATERIEL_STATE_WARRANTY_ID: 7,
    MATERIEL_STATE_WAITING_MAINTENANCE_ID: 8,
}