export default class DiagnosticActionDto {
    constructor(obj) {
        this.piezometerCode = obj.piezometerCode
        this.eventCode = obj.eventCode
        this.actionId = obj.actionId
        this.diagnosticCode = obj.diagnosticCode
        this.solutionCode = obj.solutionCode
        this.value = obj.value
        this.materielId = obj.materielId
    }
}
