import ReferencialDto from '../../../dto/ReferencialDto'

export default class HydrogeologicalEntityDto extends ReferencialDto {
    constructor(object) {
        super(object)
        this.id = object.code
        this.creationDate = object.creationDate
        this.updateDate = object.updateDate
        this.author = object.author
        this.nature = object.nature
        this.state = object.state
        this.structure = object.structure
        this.generality = object.generality
        this.denomination = object.denomination
        this.dominantType = object.dominantType
        this.thicknessWetAverage = object.thicknessWetAverage
        this.thicknessWetMin = object.thicknessWetMin
        this.thicknessWetMax = object.thicknessWetMax
        this.accessDepthAverage = object.accessDepthAverage
        this.accessDepthMin = object.accessDepthMin
        this.accessDepthMax = object.accessDepthMax
        this.transmissivityAverage = object.transmissivityAverage
        this.transmissivityMin = object.transmissivityMin
        this.transmissivityMax = object.transmissivityMax
        this.permeabilityAverage = object.permeabilityAverage
        this.permeabilityMin = object.permeabilityMin
        this.permeabilityMax = object.permeabilityMax
        this.usefulPorositeAverage = object.usefulPorositeAverage
        this.usefulPorositeMin = object.usefulPorositeMin
        this.usefulPorositeMax = object.usefulPorositeMax
        this.superpipeStorageCoefficientAverage = object.superpipeStorageCoefficientAverage
        this.superpipeStorageCoefficientMin = object.superpipeStorageCoefficientMin
        this.superpipeStorageCoefficientMax = object.superpipeStorageCoefficientMax
        this.area = object.area
        this.comment = object.comment
        this.stratiLocalName = object.stratiLocalName
        this.poolType = object.poolType
        this.sandreCode = object.sandreCode
        this.headers = ['code', 'name', 'denomination']
    }
}