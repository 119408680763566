import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Box, Button, Card, CardContent, Dialog, DialogActions, DialogContent, Grid, Typography } from '@mui/material'
import Icon from 'components/icon/Icon'
import { getDate } from 'utils/DateUtil'
import i18n from 'simple-react-i18n'
import { formatedFileName, getDocumentTypeIcon, getFileExtension, getFileName, getFileNatureLabel, getFormatedFileName } from 'utils/FileUtils'
import { PICTURE, DOCUMENT } from './constant/FileConstants'
import Checkbox from 'components/forms/Checkbox'
import { mainBlue } from 'utils/constants/ColorTheme'
import Input from 'components/forms/Input'
import { SANDRE } from 'referencial/constants/ReferencialConstants'
import Select from 'components/forms/Select'
import useSandreList from 'utils/customHook/useSandreList'
import { orderBy } from 'lodash'
import { shallowEqual, useSelector } from 'react-redux'
import { getStation } from 'utils/StationUtils'
import DtoFile from './dto/DtoFile'
import SimpleDatePicker from 'components/forms/SimpleDatePicker'
import { DialogActionsMUI, DialogContentMUI, DialogTitleMUI } from 'components/styled/Dialog'
import Fancybox from 'components/fancybox/Fancybox'
import { STATION_NAME_ASSOCIATION } from 'station/constants/StationConstants'

const CardFile = ({
    stationTypes = [],
    file = {},
    onClickDelete = () => {},
    onUpdate = () => {},
    editable = false,
    fileType = PICTURE,
}) => {
    const {
        qualitometer,
        piezometer,
        pluviometer,
        hydrometricStation,
        installation,
        exploitation,
        productionUnit,
        distributionUnit,
    } = useSelector(store => ({
        qualitometer: store.QualityReducer.qualitometer,
        piezometer: store.StationReducer.piezometer,
        pluviometer: store.PluviometryReducer.pluviometer,
        hydrometricStation: store.HydrometryReducer.hydrometricStation,
        installation: store.InstallationReducer.installation,
        exploitation: store.AgriReducer.exploitation,
        productionUnit: store.ProductionUnitReducer.productionUnit,
        distributionUnit: store.DistributionUnitReducer.distributionUnit,
    }), shallowEqual)
    const stationProps = {
        qualitometer,
        piezometer,
        pluviometer,
        hydrometricStation,
        installation,
        exploitation,
        productionUnit,
        distributionUnit,
    }
    const station = getStation(stationProps, stationTypes[0])
    const fileExtension = getFileExtension(file.name)
    const FILE_TYPE = {
        picture: {
            sandreCode: SANDRE.TYPE_PHOTO,
            fileTypeName: i18n.pictureType,
            name: PICTURE,
            content: <img src={ file.url } style={{ objectFit: 'cover', maxWidth: '100%', height: '100%', width: '400px' }} />,
            textAlign: 'left',
        },
        document: {
            sandreCode: SANDRE.TYPE_DOCUMENT,
            fileTypeName: i18n.documentType,
            name: DOCUMENT,
            content: <img src={ getDocumentTypeIcon(fileExtension) } style={{ maxWidth: '40%', height: 'auto' }} />,
            textAlign: 'center',
        },
    }
    const fileTypeConstant = FILE_TYPE[fileType]
    const fileNatures = useSandreList(fileTypeConstant.sandreCode)
    const displayName = file.isAllDataName && station?.code === file.stationCode ? file.shortName : getFileName(file.name)
    const fileNature = getFileNatureLabel(file.name, fileNatures)
    const [openPopupDelete, setPopupDelete] = useState(false)
    const [openPopupUpdate, setPopupUpdate] = useState(false)
    const [newName, setNewName] = useState(displayName)
    const [newActive, setNewActive] = useState(file.active)
    const [newType, setNewType] = useState(fileNatures.find(s => s.mnemonique === fileNature || formatedFileName(s.name) === formatedFileName(fileNature))?.code)
    const [newDate, setNewDate] = useState(file.date)

    const updateFile = () => {
        const fileTypeFind = fileNatures.find(s => s.code === newType)
        const fileTypeName = fileTypeFind.mnemonique || fileTypeFind.name
        const newFormatedName = getFormatedFileName(file.stationCode.toUpperCase(), newActive, newName, fileExtension, fileTypeName, newDate, stationTypes[0], file.shared)
        setPopupUpdate(false)
        onUpdate({ ...file, fileType: fileTypeConstant.name, newName: newFormatedName, stationType: STATION_NAME_ASSOCIATION[stationTypes[0]], fileExtension, newType, fileTypeName, newDate, displayName })
    }

    const deleteFile = () => {
        setPopupDelete(false)
        onClickDelete({ name: file.name, fileType: fileTypeConstant.name, stationType: stationTypes[0] })
    }
    const cardComponent = (
        <Card className='row valign-wrapper' style={{ height: '130px', border: `solid 2px ${mainBlue}` }}>
            <Box
                className='no-padding col s4'
                style={{ textAlign: fileTypeConstant.textAlign }}
            >
                {fileTypeConstant.content}
            </Box>
            <Box className='col s8 no-padding'>
                <CardContent className='padding-top-2 padding-left-1 col s12' sx={{ flex: '1 0 auto' }}>
                    <Typography
                        style={{
                            fontWeight: 'bold',
                            paddingTop: '5px',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                        }}
                        variant='subtitle1'
                        color='text.secondary'
                        component='div'
                    >
                        {displayName}
                    </Typography>
                    <Typography variant='subtitle1' color='text.secondary' component='div'>
                        {i18n.type} : {fileExtension}
                    </Typography>
                    <Typography variant='subtitle1' color='text.secondary' component='div'>
                        {i18n.nature} : {fileNature}
                    </Typography>
                    <Typography variant='subtitle1' color='text.secondary' component='div'>
                        {i18n.date} : {getDate(parseInt(file.date))}
                    </Typography>
                    <Grid container justifyContent='space-between'>
                        <Typography className='valign-wrapper ' variant='subtitle1' color='text.secondary' component='div'>
                            {i18n.active} :
                            <Checkbox
                                componentClassName='padding-left-1'
                                checked={file.active === '1'}
                                disabled={true}
                            />
                        </Typography>
                        <Typography className='valign-wrapper ' variant='subtitle1' color='text.secondary' component='div'>
                            {i18n.shared} :
                            <Checkbox
                                componentClassName='padding-left-1'
                                checked={file.shared}
                                disabled
                            />
                        </Typography>
                    </Grid>
                </CardContent>
            </Box>
        </Card>
    )

    return (
        <div className='valign-wrapper' style={{ position: 'relative' }}>
            { editable && (
                <div style={{ position: 'absolute', top: 5, right: 6 }}>
                    <Icon
                        size='small'
                        style={{ color: 'black' }}
                        icon='edit'
                        onClick={() => setPopupUpdate(true)}
                        tooltip={i18n.edit}
                    />
                    <Icon
                        size='small'
                        style={{ color: 'black' }}
                        icon='cancel'
                        onClick={() => setPopupDelete(true)}
                        tooltip={i18n.delete}
                    />
                </div>
            )
            }
            <Fancybox>
                <a href={ file.url } target='_blank' data-fancybox={ fileType == PICTURE ? 'images' : undefined} data-caption={ displayName } style={{ width: '100%' }}>
                    {cardComponent}
                </a>
            </Fancybox>
            {openPopupDelete && (
                <Dialog
                    open={openPopupDelete}
                >
                    <DialogContent>
                        <Typography className='center padding-1' variant='subtitle1' color='text.primary' component='div'>
                            {i18n.deletingConfirmation}
                        </Typography>
                        <Typography
                            style={{ fontWeight: 'bold' }}
                            className='center padding-1' component='div'
                        >
                            {file.name}
                        </Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setPopupDelete(false)} variant='outlined' >
                            {i18n.close}
                        </Button>
                        <Button onClick={deleteFile} variant='contained' color='primary'>
                            {i18n.validate}
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
            {openPopupUpdate && (
                <Dialog
                    fullWidth
                    maxWidth='lg'
                    open={openPopupUpdate}
                >
                    <DialogTitleMUI style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        {i18n.update} : <span className='bold'>{file.name}</span>
                        <Icon style={{ color: 'white' }} size='small' icon='close' onClick={() => setPopupUpdate(false)} />
                    </DialogTitleMUI>
                    <DialogContentMUI>
                        <div className='row no-margin padding-top-2 padding-bottom-2'>
                            <div className='col s12 padding-top-1'>
                                <Input
                                    containerClassName='padding-bottom-1'
                                    col={ 12 }
                                    title={ i18n.name }
                                    value={ newName }
                                    onChange={ v => setNewName(v) }
                                />
                                <Select
                                    className='padding-bottom-2'
                                    noNullValue
                                    options={ orderBy(fileNatures, 'code') }
                                    col={ 12 }
                                    noSort={true}
                                    label={ fileTypeConstant.fileTypeName }
                                    value={ newType }
                                    onChange={ v => setNewType(v) }
                                    nullLabel='&nbsp;'
                                    keyLabel='name'
                                />
                                <SimpleDatePicker
                                    className='padding-top-1'
                                    col={3}
                                    label={i18n.date}
                                    value={newDate}
                                    onChange={ v => setNewDate(v) }
                                    calendarPosition='right-end'
                                />
                                <Checkbox
                                    label={ i18n.active }
                                    col={ 3 }
                                    componentClassName='padding-1'
                                    onChange={ v => setNewActive(v ? '1' : '0')}
                                    checked={newActive === '1'}
                                />
                                <Checkbox
                                    label={i18n.shared}
                                    col={3}
                                    componentClassName='padding-1'
                                    checked={file.shared}
                                    disabled
                                />
                            </div>
                        </div>
                    </DialogContentMUI>
                    <DialogActionsMUI>
                        <Button
                            onClick={updateFile}
                            variant='contained' color='primary'
                        >
                            {i18n.validate}
                        </Button>
                    </DialogActionsMUI>
                </Dialog>
            )}
        </div>
    )
}

CardFile.propTypes = {
    file: PropTypes.instanceOf(DtoFile),
    stationTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
    name: PropTypes.string,
    content: PropTypes.string,
    onClickDelete: PropTypes.func,
    onUpdate: PropTypes.func,
    editable: PropTypes.bool,
    fileType: PropTypes.string,
}

export default CardFile
