import DtoJobLogLight from 'import/dto/DtoJobLogLight'
import { groupBy, isEqual, maxBy, orderBy, some, uniqBy } from 'lodash'
import moment from 'moment'
import PropTypes from 'prop-types'
import queryString from 'query-string'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import WaitAction from 'wait/WaitAction'
import Card from '../../../components/card/Card'
import ColorfulCard from '../../../components/card/ColorfulCard'
import Table from '../../../components/datatable/Table'
import Input from '../../../components/forms/Input'
import RadioButtons from '../../../components/forms/RadioButtons'
import Icon from '../../../components/icon/Icon'
import ListComponent from '../../../components/list/tableList/ListComponent'
import Row from '../../../components/react/Row'
import { nbPerPageLabel } from '../../../referencial/constants/ReferencialConstants'
import { shortenHumanize } from '../../../utils/DateUtil'
import { exportFile, getDataWithHeaders } from '../../../utils/ExportDataUtil'
import { setConfirmationModal, sieauTooltip } from '../../../utils/FormUtils'
import { getExecStatusColor, getExecStatusIcon, getExecStatusLabel } from '../../../utils/JobUtils'
import { hasValue } from '../../../utils/NumberUtil'
import JobAction from '../../actions/JobAction'
import DtoJobLog from '../../dto/DtoJobLog'
import Job from '../../dto/Job'
import JobExecution from '../../dto/JobExecution'
import JobLogTable from '../../dto/JobLogTable'
import Checkbox from '../../../components/forms/Checkbox'

const defaultLimit = 50000
const headersLogsExport = ['station', 'date', 'value', 'status', 'fileLabel']

class ImportLogs extends Component {
    constructor(props) {
        super(props)
        this.state = {
            selected: props.jobExecutions.length ? maxBy(props.jobExecutions, 'date').id : null,
            searchValue: '',
            filterColor: null,
            selectedStation: null,
            searchMode: 'station',
            searchLogs: '',
            limitLogs: defaultLimit,
            showInfo: true,
            showWarn: true,
            showErr: true,
            showSucc: true,
        }
    }

    getSelectedExecutionLogs = (data) => {
        if (data.selectedExecutionId) {
            const datas = orderBy(data.logs.map(l => new JobLogTable(l)), 'timestamp', 'desc')
            const cantLoadMore = datas.length === this.props.jobLogsCount
            return (
                <Card
                    title={`${datas.length} / ${this.props.jobLogsCount || 0} ${this.props.jobLogsCount > 0 ? i18n.elements : i18n.element}`}
                    actions={[
                        {
                            iconName: 'file_download',
                            tooltip: i18n.export,
                            onClick: () => {
                                const datasToExport = datas.map(d => ({
                                    ...d,
                                    station: { value: d.station, cellType: 'string' },
                                    date: { value: d.date, cellType: 'string' },
                                }))
                                exportFile(this.onExport(getDataWithHeaders(datasToExport, headersLogsExport), i18n.exportName))
                            },
                        },
                        {
                            iconName: 'more_horiz',
                            color: `${cantLoadMore ? '#9e9e9e' : 'white'}`,
                            tooltip: i18n.displayMore,
                            onClick: cantLoadMore ? () => {} : () => this.setState({ limitLogs: this.state.limitLogs*2 }, () => this.fetchJobLogs(this.props.job.id, this.state.selected)),
                        },
                    ]}
                >
                    <Table
                        showTitle={false}
                        className='padding-left-1'
                        condensed
                        sortable
                        data={datas}
                        paging
                        nbPerPageLabel={nbPerPageLabel}
                        type={new JobLogTable()}
                        inverseDisplay
                        customHeaders={ { station: i18n.elementLabel, value: i18n.valueLabel } }
                        exportButtonOnHeader
                        maxHeight='62vh'
                        overflow='auto'
                    />
                </Card>
            )
        }
        return <h6>{i18n.jobHasNoExecutions}</h6>
    }

    handleCLickColor = (color) => this.setState({ filterColor: !this.state.filterColor || this.state.filterColor !== color ? color : null, selectedStation: null })

    getClassColor = (color) => !this.state.filterColor || this.state.filterColor === color ? color : 'grey'

    getStationList = (data) => {
        const stationList = data.stations
        const count = stationList.map(s => s.code).includes(i18n.unknown) ? stationList.length-1 : stationList.length
        return (
            <div>
                <Row className='logColorsRecap'>
                    <Card>
                        <Row className='center-align bold'>
                            <p>{ `${count} ${this.state.searchMode === 'station' ? i18n.elementsLabel : i18n.files}` }</p>
                        </Row>
                        <Row>
                            <div className='col s4'>
                                <span className={ `${this.getClassColor('green')} arrests-level-panel white-text bold clickable` } style={{ 'font-weight': 'bold' }} onClick={ () => this.handleCLickColor('green') }>
                                    { stationList.filter(s => s.color === 'green').length }
                                </span>
                            </div>
                            <div className='col s4'>
                                <span className={ `${this.getClassColor('red')} arrests-level-panel white-text bold clickable` } style={{ 'font-weight': 'bold' }} onClick={ () => this.handleCLickColor('red') }>
                                    { stationList.filter(s => s.color === 'red').length }
                                </span>
                            </div>
                            <div className='col s4'>
                                <span className={ `${this.getClassColor('orange')} arrests-level-panel white-text bold clickable` } style={{ 'font-weight': 'bold' }} onClick={ () => this.handleCLickColor('orange') }>
                                    { stationList.filter(s => s.color === 'orange').length }
                                </span>
                            </div>
                        </Row>

                    </Card>
                </Row>
                <Row>
                    <Card maxHeight={ 549 }>
                        { data.stationsFiltered.map(s => (
                            <Row className={ `${s.classColor} clickable ${this.state.selectedStation === s.code ? 'selectedStationJobLog' : 'stationJobLog'}` }
                                onClick={ () => this.setState({ selectedStation: s.code === this.state.selectedStation ? null : s.code }) }
                            >
                                { s.code }
                            </Row>))
                        }
                    </Card>
                </Row>
            </div>
        )
    }

    fetchJobLogs = (id, execId) => {
        const { limitLogs } = this.state
        this.props.waitStart()
        this.props.fetchJobLogs(id, execId, limitLogs).then(() => {
            this.props.waitStop()
        })
    }

    onClickExecution = id => this.setState({ selected: id, limitLogs: defaultLimit }, () => this.fetchJobLogs(this.props.job.id, id))

    getTables = () => {
        return this.props.jobExecutions.map(e => {
            const date = moment(e.date)
            const execution = {
                date: date.format('DD/MM/YYYY'),
                time: date.format('HH:mm'),
                status: getExecStatusIcon(e, 50),
            }
            const duration = hasValue(e.duration) ? shortenHumanize(e.duration * 1000) : e.status !== 7 ? shortenHumanize(moment().valueOf() - e.date) : 0
            const tooltipContent = () => (<div className='row no-margin valign-wrapper'>{getExecStatusIcon(e, 20)}<div className='padding-left-1'/>{getExecStatusLabel(e)}</div>)
            return {
                id: e.id,
                title: (
                    <ColorfulCard color={getExecStatusColor(e)} className='no-margin' objectId={e.id} onClick={ () => this.onClickExecution(e.id) }>
                        <div className='row no-margin valign-wrapper padding-left-15 border-bottom-1' style={ this.state.selected === e.id ? { 'background-color': '#d1dbff' } : {} }>
                            <div className='col s2 no-padding'>
                                <div className='row no-margin' {...sieauTooltip(tooltipContent)}>{execution.status}</div>
                            </div>
                            <div className='col s4 no-padding'>
                                <div className='row no-margin'>{execution.date}</div>
                                <div className='row no-margin'>{execution.time}</div>
                            </div>
                            <div className='col s4 no-padding'>
                                <div className='row no-margin'>{duration}</div>
                            </div>
                            <div className='col s1 no-padding'>
                                { this.getActionIcon(e) }
                            </div>
                        </div>
                    </ColorfulCard>
                ),
                component: null,
            }
        })
    }

    getActionIcon = (execution) => {
        if (!hasValue(execution.status) && !hasValue(execution.duration)) {
            return (<Icon icon='pan_tool' style={{ color: 'orange' }} onClick={ () =>
                setConfirmationModal(() =>
                    this.props.abortExecution(this.props.job.id, execution)
                        .then(() => this.props.reloadExecutions()), i18n.abortExecutionMessage)
            } tooltip={ i18n.stopExecution }
            />)
        }
        return null
    }

    componentDidMount() {
        this.beginAutoReload()
        const { jobExecutionId } = queryString.parse(this.props.location.search)
        if (hasValue(jobExecutionId)) {
            this.onClickExecution(parseInt(jobExecutionId))
        } else if (this.state.selected) {
            this.fetchJobLogs(this.props.job.id, this.state.selected)
        }
    }

    componentDidUpdate(prevProps) {
        const { jobExecutions } = this.props
        if (!isEqual(prevProps.jobExecutions, jobExecutions)) {
            this.setState({ selected: jobExecutions.length ? maxBy(jobExecutions, 'date').id : null })
        }
    }

    beginAutoReload = () => {
        const lastExecution = this.props.jobExecutions.length ? maxBy(this.props.jobExecutions, 'date') : null
        if (lastExecution) {
            this.setState({ selected: lastExecution.id })
            this.reloadLastLogs()
        }
    }

    reloadLastLogs = () => {
        this.reloadTimeout = setTimeout(() => {
            const { selected } = this.state
            const selectedExecution = this.props.jobExecutions.length ? this.props.jobExecutions.find(j => j.id === selected) : null
            if (selectedExecution) {
                if (!hasValue(selectedExecution.status) && moment().subtract(10, 'minutes').valueOf() < selectedExecution.date) {
                    this.props.reloadJobLogs(this.props.job.id, selectedExecution.id, this.state.limitLogs).then(() => this.reloadLastLogs())
                }
            } else {
                const lastExecution = this.props.jobExecutions.length ? maxBy(this.props.jobExecutions, 'date') : null
                if (lastExecution) {
                    if (!hasValue(lastExecution.status) && moment().subtract(10, 'minutes').valueOf() < lastExecution.date) {
                        this.props.reloadJobLogs(this.props.job.id, lastExecution.id, this.state.limitLogs).then(() => this.reloadLastLogs())
                    }
                }
            }
        }, 5000)
    }

    componentWillUnmount() {
        clearTimeout(this.reloadTimeout)
    }

    getLogsAndStations = () => {
        const selectedExecution = this.state.selected && this.props.jobExecutions.find(e => e.id === this.state.selected)
        if (selectedExecution) {
            const executionLogs = this.props.jobLogs.filter(l => l.jobId === this.props.job.id && !l.status.startsWith('file') && (!this.state.searchLogs || (l.value || '').toLowerCase().includes(this.state.searchLogs.toLowerCase())))
            const lowered = this.state.searchValue.toLowerCase()
            const filtered = hasValue(this.state.searchValue) ? executionLogs.filter(l => (l[this.state.searchMode] || '').toLowerCase().includes(lowered)) : executionLogs

            const logLevelsToRemove = ['showInfo', 'showWarn', 'showErr', 'showSucc'].filter(key => this.state[key] === false)
            const filtered2 = logLevelsToRemove.length ? logLevelsToRemove.reduce((acc, key) => {
                if (key === 'showInfo') {
                    return acc.filter(l => ['warning', 'error', 'success'].includes(l.status))
                }
                if (key === 'showWarn') {
                    return acc.filter(l => l.status !== 'warning')
                }
                if (key === 'showErr') {
                    return acc.filter(l => l.status !== 'error')
                }
                return acc.filter(l => l.status !== 'success')
            }, filtered) : filtered

            const groups = groupBy(filtered2, e => e[this.state.searchMode]?.trim() || i18n.unknown)
            const stationList = hasValue(this.state.searchValue) || hasValue(this.state.searchLogs) || logLevelsToRemove.length || this.state.searchMode === 'fileName' ? Object.keys(groups).map(stationKey => {
                const logs = uniqBy(groups[stationKey], 'status')
                const color = (() => {
                    if (some(logs, l => l.status === 'error')) {
                        return { color: 'red', classColor: 'red-200' }
                    }
                    if (some(logs, l => l.status === 'warning')) {
                        return { color: 'orange', classColor: 'yellow-200' }
                    }
                    if (some(logs, l => l.status === 'success')) {
                        return { color: 'green', classColor: 'green-200' }
                    }
                    return {}
                })()
                return { code: stationKey, ...color, logs: groups[stationKey] }
            }) : this.props.jobLogsStations.map(stationLogs => {
                const key = hasValue(stationLogs[0].trim()) ? stationLogs[0] : i18n.unknown
                const color = (() => {
                    if (stationLogs[1] === 3) {
                        return { color: 'red', classColor: 'red-200' }
                    }
                    if (stationLogs[1] === 2) {
                        return { color: 'orange', classColor: 'yellow-200' }
                    }
                    if (stationLogs[1] === 1) {
                        return { color: 'green', classColor: 'green-200' }
                    }
                    return {}
                })()
                return { code: key, ...color, logs: groups[key] || [] }
            })
            const stationsOrdred = orderBy(stationList, s => s.logs.length ? s.logs[0].date : 0, 'desc')
            const filterByColor = this.state.filterColor ? stationsOrdred.filter(s => s.color === this.state.filterColor) : stationsOrdred
            const filterByStation = this.state.selectedStation ? filterByColor.filter(s => s.code === this.state.selectedStation) : filterByColor
            return { stations: stationsOrdred, stationsFiltered: filterByColor, logs: filterByStation.flatMap(s => s.logs), selectedExecutionId: selectedExecution.id }
        }
        return { stations: [], logs: [] }
    }

    onExport = (datas, title) => ({
        data: datas,
        exportType: 'xlsx',
        titleFile: title || `${i18n.job}_n${this.props.job.id}_${i18n.executions}`,
    })


    exportButton = (datas) => {
        const nbExecution =`${i18n.execution} (${this.props.job.nbExecutions})`

        return (
            <div className={ 'card-title activator no-margin' }>
                <div className='row no-margin'>
                    <div className='col s12 no-padding'>
                        <div className='left nbElements'id='table_title_latest_executions'>
                            { nbExecution }
                        </div>
                        <div className='right'
                            onClick={ this.props.loadMore }
                            {...sieauTooltip(i18n.displayMore, null, 'bottom')}
                        >
                            <i className='material-icons' id='logo_three_dot'>
                                    more_horiz
                            </i>
                        </div>
                        <div>
                            <i className='material-icons right no-margin'
                                {...sieauTooltip(i18n.export, null, 'bottom')}
                                onClick={ () => exportFile(this.onExport(datas.map(d => ({
                                    ...d,
                                    id: { value: d.id, cellType: 'string' },
                                    date: { value: d.date, cellType: 'string' },
                                })))) } id='export_logo'
                            >file_download</i>
                        </div>
                        <div className='right'
                            {...sieauTooltip(i18n.displayEmptyExecutions, null, 'bottom')}
                        >
                            <Icon icon='free_breakfast' style={{ color: this.props.excludeEmpty ? '#f44336' : '#008000' }} onClick={ this.props.onExcludeEmpty }/>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    getLogHeader = (selectedExecution) => {
        return (
            `${i18n.execution} ${selectedExecution.id} - ${moment(selectedExecution.date).format('DD/MM/YYYY-HH:mm')} (${shortenHumanize(selectedExecution.duration * 1000)})`
        )
    }

    render() {
        const data = this.getLogsAndStations()
        const headerExport = this.exportButton(this.props.jobExecutions)
        const selectedExecution = this.props.jobExecutions.find(e => e.id === this.state.selected)
        return (
            <div id='importJobLogs' className='card no-margin padding-top-1'>
                <div className='row no-margin'>
                    {this.props.jobExecutions && this.props.jobExecutions.length ? (
                        <div className='col s12 no-padding'>
                            <div className='col s3 padding-left-1'>
                                { headerExport }
                                <ListComponent tables={this.getTables()} paging={10} collapsible={false} id='list_job_execution'/>
                            </div>
                            <div className='col s9 padding-left-1'>
                                { selectedExecution && (
                                    <div className='row no-margin'>
                                        <div className='col s12 no-padding'>
                                            <div className={ 'card-title activator no-margin'}>
                                                <div className='row no-margin'>
                                                    <div className='col s12 no-padding'>
                                                        {this.getLogHeader(selectedExecution)}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col s2'>
                                                {this.getStationList(data)}
                                            </div>
                                            <div className='col s10 no-padding'>
                                                <Row className='padding-top-1 valign-wrapper'>
                                                    <Input col={ 2 } title={ this.state.searchMode === 'station' ? i18n.elementLabel : i18n.fileLabel } value={ this.state.searchValue } onChange={ v => this.setState({ searchValue: v, selectedStation: null, filterColor: null }) }/>
                                                    <RadioButtons elements={ [{ code: 'station', name: i18n.elementsLabel }, { code: 'fileName', name: i18n.fileLabel }] } selected={ this.state.searchMode }
                                                        col={ 3 } onChange={ v => this.setState({ searchMode: v, searchValue: '', selectedStation: null, filterColor: null })} title={ i18n.researchType }
                                                    />
                                                    <Input col={ 2 } title={ i18n.searchInLogs } value={ this.state.searchLogs } onChange={ v => this.setState({ searchLogs: v, selectedStation: null, filterColor: null }) }/>
                                                    <Checkbox col={1} label='INFO' checked={ this.state.showInfo } onChange={ v => this.setState({ showInfo: v })}/>
                                                    <Checkbox col={1} label={<span className='green arrests-level-panel green-text'>__</span>} checked={ this.state.showSucc } onChange={ v => this.setState({ showSucc: v })}/>
                                                    <Checkbox col={1} label={<span className='yellow darken-1 arrests-level-panel yellow-text'>__</span>} checked={ this.state.showWarn } onChange={ v => this.setState({ showWarn: v })}/>
                                                    <Checkbox col={1} label={<span className='red arrests-level-panel red-text'>__</span>} checked={ this.state.showErr } onChange={ v => this.setState({ showErr: v })}/>
                                                </Row>
                                                <div className='no-padding' id='logsTable'>
                                                    { this.getSelectedExecutionLogs(data) }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ) }
                            </div>
                        </div>
                    ) : (
                        <h6>{i18n.jobHasNoExecutions}</h6>
                    )}
                </div>
            </div>
        )
    }
}

ImportLogs.propTypes = {
    params: PropTypes.shape({
        id: PropTypes.number,
    }),
    jobExecutions: PropTypes.arrayOf(PropTypes.instanceOf(JobExecution)),
    jobLogs: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.instanceOf(DtoJobLog)),
        PropTypes.arrayOf(PropTypes.instanceOf(DtoJobLogLight)),
    ]),
    job: PropTypes.instanceOf(Job).isRequired,
    reloadJobLogs: PropTypes.func,
    abortExecution: PropTypes.func,
    reloadExecutions: PropTypes.func,
    loadMore: PropTypes.func,
    fetchJobLogs: PropTypes.func,
    waitStart: PropTypes.func,
    waitStop: PropTypes.func,
    excludeEmpty: PropTypes.bool,
    onExcludeEmpty: PropTypes.func,
    location: PropTypes.object,
    jobLogsCount: PropTypes.number,
    jobLogsStations: PropTypes.arrayOf(PropTypes.object),
    setTimeout: PropTypes.func,
}

ImportLogs.defaultProps = {
    jobExecutions: [],
    job: {},
}

const mapStateToProps = store => ({
    jobLogs: store.JobReducer.jobLogs,
    jobLogsCount: store.JobReducer.jobLogsCount,
    jobLogsStations: store.JobReducer.jobLogsStations,
})

const mapDispatchToProps = {
    reloadJobLogs: JobAction.reloadJobLogs,
    abortExecution: JobAction.abortExecution,
    fetchJobLogs: JobAction.fetchJobLogs,
    waitStart: WaitAction.waitStart,
    waitStop: WaitAction.waitStop,
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ImportLogs)
