'use strict'
import PropTypes from 'prop-types'
import {
    RECEIVE_ALL_DATA_TYPE,
    RECEIVE_ALL_MATERIEL_TYPES,
    RECEIVE_CHECK_DELETE,
    RECEIVE_REFERENCIAL_DOMAIN,
    RECEIVE_REFERENCIAL_SANDRE_CODES,
    RECEIVE_REFERENCIAL_STATUS,
    RECEIVE_REFERENCIAL_SUPPORT,
    RECEIVE_SANDRE_CODES,
    RECEIVE_STATION_TYPES,
    RESET_CHECK_DELETE,
} from '../constants/ReferencialConstants'
import DtoReferencial from '../dto/DtoReferencial'
import DtoReferencialItem from '../dto/DtoReferencialItem'
import DtoStation from '../../station/dto/DtoStation'
import SupportDto from '../components/support/dto/SupportDto'
import MethodDto from '../components/methods/dto/MethodDto'
import UnitDto from '../components/unit/dto/UnitDto'
import ContributorItem from '../components/contributor/dto/ContributorItem'
import ContactDto from '../components/contact/dto/ContactDto'
import CityDto from '../components/city/dto/CityDto'
import ParameterDto from '../components/parameter/dto/ParameterDto'
import FractionDto from '../components/fraction/dto/FractionDto'
import DtoStationType from '../dto/DtoStationType'
import ProducerDto from '../components/contributor/dto/ProducerDto'
import NetworkDto from '../components/network/dto/NetworkDto'
import SupportAction from '../components/support/actions/SupportAction'
import ReferencialAction from '../action/ReferencialAction'
import ReferentialAction from '../action/ReferencialAction'
import MethodAction from '../components/methods/actions/MethodAction'
import UnitAction from '../components/unit/actions/UnitAction'
import ContributorAction from '../components/contributor/actions/ContributorAction'
import ContactAction from '../components/contact/actions/ContactAction'
import FractionAction from '../components/fraction/actions/FractionAction'
import CityAction from '../components/city/actions/CityAction'
import ParameterAction from '../components/parameter/actions/ParameterAction'
import NetworkAction from '../components/network/actions/NetworkAction'
import { arrayOf, createIndex, instanceOf } from '../../utils/StoreUtils'
import AppStore from 'store/AppStore'
import WatermassAction from '../components/watermass/actions/WatermassAction'
import WatermassDto from '../components/watermass/dto/WatermassDto'
import WatershedAction from '../components/watershed/actions/WatershedAction'
import WatershedDto from '../components/watershed/dto/WatershedDto'
import ContributorTypeDto from '../components/contributor/dto/ContributorTypeDto'
import DtoSelection from '../components/parameter/dto/DtoSelection'
import hydrogeologicalEntityAction from '../components/hydrogeologicalEntity/actions/HydrogeologicalEntityAction'
import HydrogeologicalEntityDto from '../components/hydrogeologicalEntity/dto/HydrogeologicalEntityDto'
import HydrogeologicalEntityItemDto from '../components/hydrogeologicalEntity/dto/HydrogeologicalEntityItemDto'
import StationWithWatermassDto from '../components/watermass/dto/StationWithWatermassDto'
import StationWithWatershedDto from '../components/watershed/dto/StationWithWatershedDto'
import DtoSandreCode from '../dto/DtoSandreCode'
import ReferencialSandreCodeDto from '../dto/ReferencialSandreCodeDto'
import AquiferAction from '../components/aquifers/actions/AquiferAction'
import DtoAquifer from '../components/aquifers/dto/DtoAquifer'
import DtoReferencialInt from '../dto/DtoReferencialInt'
import DtoDataType from '../dto/DtoDataType'
import DtoMaterielType from '../dto/DtoMaterielType'
import { groupBy } from 'lodash'
import ContributorDto from '../components/contributor/dto/ContributorDto'
import FractionItem from 'referencial/components/fraction/dto/FractionItem'
import SupportItem from 'referencial/components/support/dto/SupportItem'

export function ReferencialReducer(state = {}, action) {
    switch (action.type) {
        case RECEIVE_REFERENCIAL_DOMAIN:
            return {
                ...state,
                referencialDomain: action.referencialDomain.map(referencialDomain => new DtoReferencial(referencialDomain)),
            }
        case RECEIVE_REFERENCIAL_STATUS:
            return {
                ...state,
                referencialStatus: action.referencialStatus.map(referencialStatus => new DtoReferencialInt(referencialStatus)),
            }
        case RECEIVE_REFERENCIAL_SUPPORT:
            return {
                ...state,
                referencialSupport: action.supports.map(referencialSupport => new DtoReferencialItem(referencialSupport)),
            }
        case RECEIVE_STATION_TYPES:
            return {
                ...state,
                stationTypes: action.types.map(type => new DtoStationType(type)),
            }
        case RECEIVE_ALL_MATERIEL_TYPES:
            return {
                ...state,
                materielTypes: action.materielTypes.map(o => new DtoMaterielType(o)),
            }
        case RECEIVE_SANDRE_CODES:
            const sandreCodes = action.codes.map(type => new DtoSandreCode(type))
            const sandreIndexGroup = groupBy(sandreCodes, 'field')
            const sandreIndex = {}
            Object.keys(sandreIndexGroup).forEach(key => {
                sandreIndex[key] = createIndex(sandreIndexGroup[key], 'code')
            })
            return {
                ...state,
                sandreCodes,
                sandreIndex, // Double index pour les codes sandres --> sandreIndex[field][code]
            }
        case RECEIVE_REFERENCIAL_SANDRE_CODES:
            return {
                ...state,
                referencialSandreCodes: action.referencialSandreCodes.map(referencialSandreCode => new ReferencialSandreCodeDto(referencialSandreCode)),
            }
        case RECEIVE_CHECK_DELETE:
            return {
                ...state,
                checkDelete: action.checkDelete,
            }
        case RESET_CHECK_DELETE:
            return {
                ...state,
                checkDelete: null,
            }
        case RECEIVE_ALL_DATA_TYPE:
            return {
                ...state,
                dataTypes: action.dataTypes.map(o => new DtoDataType(o)),
            }
        default:
            return state
    }
}

export const store = {
    referencialStatus: [],
    referencialDomain: [],
    referencialSupport: [],
    stationTypes: [],
    materielTypes: [],
    sandreCodes: [],
    sandreIndex: {},
    referencialSandreCodes: [],
    checkDelete: null,
    dataTypes: [],
}

export const ReferencialStoreInfos = () => {
    const appStore = AppStore.getState()
    return {
        referencial: {
            reset: ReferencialAction.resetReferencial,
        },
        cities: {
            fetch: CityAction.fetchCities,
            store: appStore.CityReducer.cities,
            propType: arrayOf(CityDto),
        },
        city: {
            fetch: CityAction.fetchCity,
            store: appStore.CityReducer.city,
            propType: instanceOf(CityDto),
        },
        parameter: {
            fetch: ParameterAction.fetchParameter,
            store: appStore.ParameterReducer.parameter,
            propType: instanceOf(ParameterDto),
        },
        parameters: {
            fetch: ParameterAction.fetchParameters,
            store: appStore.ParameterReducer.parameters,
            propType: arrayOf(ParameterDto),
        },
        selections: {
            fetch: ParameterAction.fetchSelections,
            store: appStore.ParameterReducer.selections,
            propType: arrayOf(DtoSelection),
        },
        selectionResults: {
            fetch: ParameterAction.fetchSelectionParameter,
            store: appStore.ParameterReducer.selectionResults,
            propType: arrayOf(PropTypes.string),
        },
        qualitometersWithParameter: {
            fetch: ParameterAction.fetchQualitometers,
            store: appStore.ParameterReducer.qualitometersWithParameter,
            propType: arrayOf(DtoStation),
        },
        stationTypes: {
            fetch: ReferencialAction.fetchStationTypes,
            store: appStore.ReferencialReducer.stationTypes,
            propType: arrayOf(DtoStationType),
        },
        support: {
            fetch: SupportAction.fetchSupport,
            store: appStore.SupportReducer.support,
            propType: instanceOf(SupportDto),
        },
        supports: {
            fetch: SupportAction.fetchSupports,
            store: appStore.SupportReducer.supports,
            propType: arrayOf(SupportItem),
        },
        method: {
            fetch: MethodAction.fetchMethod,
            store: appStore.MethodReducer.method,
            propType: instanceOf(MethodDto),
        },
        methods: {
            fetch: MethodAction.fetchMethods,
            store: appStore.MethodReducer.methods,
            propType: arrayOf(MethodDto),
        },
        unit: {
            fetch: UnitAction.fetchUnit,
            store: appStore.UnitReducer.unit,
            propType: instanceOf(UnitDto),
        },
        units: {
            fetch: UnitAction.fetchUnits,
            store: appStore.UnitReducer.units,
            propType: arrayOf(UnitDto),
        },
        contributor: {
            fetch: ContributorAction.fetchContributor,
            store: appStore.ContributorReducer.contributor,
            propType: instanceOf(ContributorDto),
        },
        contributors: {
            fetch: ContributorAction.fetchContributors,
            store: appStore.ContributorReducer.contributors,
            propType: arrayOf(ContributorItem),
        },
        contributorTypes: {
            fetch: ContributorAction.fetchContributorTypes,
            store: appStore.ContributorReducer.contributorTypes,
            propType: arrayOf(ContributorTypeDto),
        },
        producers: {
            fetch: ContributorAction.fetchProducers,
            store: appStore.ContributorReducer.producers,
            propType: arrayOf(ProducerDto),
        },
        contact: {
            fetch: ContactAction.fetchContact,
            store: appStore.ContactReducer.contact,
            propType: instanceOf(ContactDto),
        },
        contacts: {
            fetch: ContactAction.fetchContacts,
            store: appStore.ContactReducer.contacts,
            propType: arrayOf(ContactDto),
        },
        fraction: {
            fetch: FractionAction.fetchFraction,
            store: appStore.FractionReducer.fraction,
            propType: instanceOf(FractionDto),
        },
        fractions: {
            fetch: FractionAction.fetchFractions,
            store: appStore.FractionReducer.fractions,
            propType: arrayOf(FractionItem),
        },
        watermass: {
            fetch: WatermassAction.fetchWatermass,
            store: appStore.WatermassReducer.watermass,
            propType: instanceOf(WatermassDto),
        },
        watermasses: {
            fetch: WatermassAction.fetchWatermasses,
            store: appStore.WatermassReducer.watermasses,
            propType: arrayOf(WatermassDto),
        },
        stationsWithWatermass: {
            fetch: WatermassAction.fetchStationsWithWatermass,
            reset: WatermassAction.resetWatermassStations,
            store: appStore.WatermassReducer.stationsWithWatermass,
            propType: arrayOf(StationWithWatermassDto),
        },
        network: {
            fetch: NetworkAction.fetchNetwork,
            store: appStore.NetworkReducer.network,
            propType: instanceOf(NetworkDto),
        },
        networks: {
            fetch: NetworkAction.fetchNetworks,
            store: appStore.NetworkReducer.networks,
            propType: arrayOf(NetworkDto),
        },
        watershed: {
            fetch: WatershedAction.fetchWatershed,
            store: appStore.WatershedReducer.watershed,
            propType: instanceOf(WatershedDto),
        },
        watersheds: {
            fetch: WatershedAction.fetchWatersheds,
            store: appStore.WatershedReducer.watersheds,
            propType: arrayOf(WatershedDto),
        },
        stationsWithWatershed: {
            fetch: WatershedAction.fetchStationsWithWatershed,
            reset: WatershedAction.resetStationsWithWatershed,
            store: appStore.WatershedReducer.stationsWithWatershed,
            propType: arrayOf(StationWithWatershedDto),
        },
        hydrogeologicalEntity: {
            fetch: hydrogeologicalEntityAction.fetchHydrogeologicalEntity,
            store: appStore.HydrogeologicalEntityReducer.hydrogeologicalEntity,
            propType: instanceOf(HydrogeologicalEntityDto),
        },
        hydrogeologicalEntities: {
            fetch: hydrogeologicalEntityAction.fetchHydrogeologicalEntities,
            store: appStore.HydrogeologicalEntityReducer.hydrogeologicalEntities,
            propType: arrayOf(HydrogeologicalEntityItemDto),
        },
        sandreCodes: {
            fetch: ReferentialAction.fetchSandreCodes,
            store: appStore.ReferencialReducer.sandreCodes,
            propType: arrayOf(DtoSandreCode),
        },
        aquifers: {
            fetch: AquiferAction.fetchAquifers,
            store: appStore.AquiferReducer.aquifers,
            propType: arrayOf(DtoAquifer),
        },
    }
}
