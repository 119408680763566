module.exports = {
    RECEIVE_ALL_PRODUCTION_UNITS: 'RECEIVE_ALL_PRODUCTION_UNITS',
    RECEIVE_PRODUCTION_UNIT: 'RECEIVE_PRODUCTION_UNIT',
    RESET_PRODUCTION_UNIT: 'RESET_PRODUCTION_UNIT',
    RECEIVE_PRODUCTION_UNIT_ASSOCIATIONS: 'RECEIVE_PRODUCTION_UNIT_ASSOCIATIONS',
    RECEIVE_PRODUCTION_UNIT_ASSOCIATIONS_MEASURES: 'RECEIVE_PRODUCTION_UNIT_ASSOCIATIONS_MEASURES',
    RECEIVE_PRODUCTION_UNIT_ASSOCIATIONS_SAMPLES: 'RECEIVE_PRODUCTION_UNIT_ASSOCIATIONS_SAMPLES',
    RECEIVE_PRODUCTION_UNIT_ASSOCIATIONS_ADDITIONAL_MEASURES: 'RECEIVE_PRODUCTION_UNIT_ASSOCIATIONS_ADDITIONAL_MEASURES',
    RECEIVE_PRODUCTION_UNIT_ASSOCIATIONS_RAW_MEASURES: 'RECEIVE_PRODUCTION_UNIT_ASSOCIATIONS_RAW_MEASURES',
    CREATE_PRODUCTION_UNIT: 'CREATE_PRODUCTION_UNIT',
    DELETE_PRODUCTION_UNIT: 'DELETE_PRODUCTION_UNIT',
    RECEIVE_PRODUCTION_UNIT_LINKS: 'RECEIVE_PRODUCTION_UNIT_LINKS',

    PRODUCTION_UNIT_LINK_TYPES: {
        RESOURCES: 1,
        MONITORING: 2,
        PRODUCTION: 3,
        JOB: 4,
        DISTRIBUTION: 5,
        UDI: 6,
        OPERATOR: 7,
    },

    LINKS: {
        1: 'resources',
        2: 'monitoring',
        3: 'production',
        4: 'job',
        5: 'distributionUnit',
        6: 'distributionUnitName',
        7: 'operator',
    },

}
