import React, { useEffect, useMemo, useState } from 'react'
import i18n from 'simple-react-i18n'
import { getLocalStorageJson, sieauTooltip } from '../../../utils/FormUtils'
import Card from '../../../components/card/Card'
import Row from '../../../components/react/Row'
import Select from '../../../components/forms/Select'
import Icon from '../../../components/icon/Icon'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import validateImg from '../../../assets/pictures/pictos/validate.png'
import purgeImg from '../../../assets/pictures/pictos/purge.png'
import importImg from '../../../assets/pictures/pictos/import.png'
import compensationImg from '../../../assets/pictures/pictos/compensation.png'
import correctionImg from '../../../assets/pictures/pictos/correction.png'
import optionsImg from '../../../assets/pictures/pictos/options.png'
import thresholdImg from '../../../assets/pictures/pictos/threshold.png'
import eventImg from '../../../assets/pictures/pictos/event.png'
import { isNull, keys, maxBy, min, minBy, orderBy, uniqBy } from 'lodash'
import { getStationTitle } from '../../../utils/StationUtils'
import HydrometryAction from '../../actions/HydrometryAction'
import ValidationHydroToolPanel2 from './tools/ValidationHydroToolPanel2'
import TableViewValidationHydroMode2 from './displayMode/TableViewValidationHydroMode2'
import GraphicValidationHydroMode from './displayMode/GraphicValidationHydroMode'
import useTitle from '../../../utils/customHook/useTitle'
import { EVENT, IMPORT } from '../../../home/constants/RouteConstants'
import { THRESHOLDS } from '../../../alerting/constants/AlertConstants'
import MessageCard from '../../../components/card/MessageCard'
import PropTypes from 'prop-types'
import SimpleOptionsToolPanel from 'piezometry/components/validation/tools/SimpleOptionsToolPanel'
import moment from 'moment'
import DeleteHydroToolPanel from './tools/DeleteHydroToolPanel'
import EventsToolPanelHydro from './tools/EventsToolPanelHydro'
import {
    WINDOW,
    COMPENSATION,
    CORRECTION,
    OPTIONS,
    PURGE,
    VALIDATION,
    RESET,
    DELETE,
} from '../../constants/HydrometryConstants'
import { getFullDate } from '../../../utils/DateUtil'
import EventsAction from '../../../events/actions/EventsAction'
import CompensationHydroToolPanel from './tools/CompensationHydroToolPanel'
import CorrectionHydroToolPanel from './tools/CorrectionHydroToolPanel'
import ToastrAction from '../../../toastr/actions/ToastrAction'
import ThresholdsTab from '../../../alerting/components/dashboard/tabPopin/ThresholdsTab'
import Checkbox from '../../../components/forms/Checkbox'
import AdministrationAction from '../../../administration/actions/AdministrationAction'
import ContributorAction from '../../../referencial/components/contributor/actions/ContributorAction'
import ImportHydroToolPanel from './tools/ImportHydroToolPanel'
import { componentHasHabilitations } from '../../../utils/HabilitationUtil'
import { H_STATION_HYDRO_VALIDATION } from '../../../account/constants/AccessRulesConstants'
import { push } from '@lagunovsky/redux-react-router'
import { getStationArrowNav } from '../../../utils/ActionUtils'
import useUpdateEffect from '../../../utils/customHook/useUpdateEffect'
import { Grid } from '@mui/material'
import HydrometerStationAction from 'station/actions/HydrometerStationAction'
import { hasValue } from 'utils/NumberUtil'
import {
    AUTO_Y_SCALE,
    CHART_SELECTED_TIME,
    CHART_SELECTED_TIME_OTHER,
    MANUAL_Y_SCALE, OTHER,
} from 'quality/constants/ChartConstant'
import ProgressCard from 'components/card/ProgressCard'
import MUIChartTabs from 'components/echart/MUIChartTabs'
import useAccountSetting from 'utils/customHook/useAccountSetting'
import { HISTO, J90 } from 'alerting/constants/ChartFollowContants'
import useActions from 'utils/customHook/useActions'
import useProgressDispatch from 'utils/customHook/useProgressDispatch'
import { STATION_TYPE_NAME } from 'station/constants/StationConstants'
import useLocalStorage from '../../../utils/customHook/useLocalStorage'
import SimpleChartTabsHydro from '../../../components/echart/SimpleChartTabsHydro'
import { getChartDate, getSubstractTime } from 'components/echart/EChartUtils'

const PERSONALIZED_GROUPING = 'personalizedGrouping'

const IconTool = ({
    icon,
    toolIcon,
    height,
    width,
    tooltip,
    setTool,
    iconSelected,
    isPreview,
}) => {
    const dispatch = useDispatch()
    return (
        <li style={{ display: 'inline-block' }}>
            <img
                className={`validationIcon clickable no-margin ${toolIcon === iconSelected && 'activated'}`}
                style={{ height, width }}
                onClick={() => isPreview ? dispatch(ToastrAction.warning(i18n.pleaseSaveModifs)) : setTool(toolIcon)}
                src={icon} {...sieauTooltip(tooltip)}
            />
        </li>
    )
}
IconTool.propTypes = {
    icon: PropTypes.string,
    toolIcon: PropTypes.string,
    height: PropTypes.number,
    width: PropTypes.number,
    tooltip: PropTypes.string,
    setTool: PropTypes.func,
    iconSelected: PropTypes.string,
    isPreview: PropTypes.bool,
}

const listView = [VALIDATION, PURGE, EVENT, THRESHOLDS]

const HydrometryValidationApp = ({}) => {
    const {
        hydrometricStation,
        hydrometricStations,
        hydroMeasures,
        hydrometryDataTypes,
        hydroStatistics,
        hydrometryThresholds,
        applicationSettings,
        contributors,
        lastMeasureHydro,
        hydrometerChartOptions,
    } = useSelector(store => ({
        hydrometricStation: store.HydrometryReducer.hydrometricStation,
        hydrometricStations: store.HydrometryReducer.hydrometricStations,
        hydroMeasures: store.HydrometryReducer.hydroMeasures,
        hydrometryDataTypes: store.HydrometryReducer.hydrometryDataTypes,
        hydroStatistics: store.HydrometryReducer.hydroStatistics,
        hydrometryThresholds: store.HydrometryReducer.hydrometryThresholds,
        applicationSettings: store.AdministrationReducer.applicationSettings,
        contributors: store.ContributorReducer.contributors,
        lastMeasureHydro: store.HydrometryReducer.hydroLastMeasures,
        hydrometerChartOptions: store.HydrometerStationReducer.chartOptions,
    }), shallowEqual)

    const lastSelectedTime = useAccountSetting(CHART_SELECTED_TIME, v => [HISTO, OTHER].includes(v) ? v : (v ? parseInt(v) : J90))
    const lastSelectedTimeOther = getLocalStorageJson(CHART_SELECTED_TIME_OTHER)
    const getDefaultMinDate = () => {
        if (lastSelectedTime === OTHER) {
            return lastSelectedTimeOther.minDate
        }
        return lastSelectedTime === HISTO ? undefined : getSubstractTime(lastSelectedTime)
    }
    const getDefaultMaxDate = () => {
        if (lastSelectedTime === OTHER) {
            return lastSelectedTimeOther.maxDate
        }
        return lastSelectedTime === HISTO ? moment().valueOf() : undefined
    }

    const [time, setTime] = useState(lastSelectedTime || J90)
    const [dataType, setDataType] = useState(null)
    const [selectedCodePoint, setSelectedCodePoint] = useState(null)
    const [tableView, setTableView] = useState(false)
    const [tool, setTool] = useState(VALIDATION)
    const [selection, setSelection] = useState(WINDOW)
    const [selectionData, setSelectionData] = useState()
    const [dataLoaded, setDataLoaded] = useState(false)
    const [startDate, setStartDate] = useState(undefined)
    const [endDate, setEndDate] = useState(undefined)
    const [minDate, setMinDate] = useState(getDefaultMinDate())
    const [maxDate, setMaxDate] = useState(getDefaultMaxDate())
    const [value, setValue] = useState()
    const [initialPoint, setInitialPoint] = useState()
    const [isChange, setIsChange] = useState(false)
    const [isPreview, setIsPreview] = useState(false)
    const [isThresholShow, setIsThresholShow] = useState(true)
    const [serieLinePrevious, setSerieLinePrevious] = useState([])
    const [graphicOptions, setGraphicOptions] = useState({})
    const [valueCorrection, setValueCorrection] = useState(1)
    const [measuresChangesValidation, setMeasuresChangesValidation] = useState([])
    const [validationFilterFullDay, setValidationFilterFullDay] = useLocalStorage('VALIDATE_FULL_DATE', false)

    const [displayModes, setDisplayModes] = useState({
        auto: true,
        max: false,
        min: false,
        average: false,
        brute: false,
        initialBrute: false,
    })

    const dispatch = useDispatch()

    useTitle(() => [{
        title: i18n.hydrometry,
        href: 'hydrometry',
    }, {
        title: getStationTitle(hydrometricStation),
        href: `station/hydrometry/${hydrometricStation.id}`,
    }, {
        title: i18n.validation,
        href: `station/hydrometry/${hydrometricStation.id}/validationHydro`,
    }], [hydrometricStation.id])

    useActions(() => ({
        arrowNav: getStationArrowNav('hydrometry', hydrometricStations, hydrometricStation.id, s => dispatch(push(`/station/hydrometry/${s.id}/validationHydro`))),
    }), [hydrometricStation.id, hydrometricStations])

    useEffect(() => {
        if (!componentHasHabilitations(H_STATION_HYDRO_VALIDATION)) {
            dispatch(push('/unauthorized'))
        }
    }, [])

    const { isLoaded, progress } = useProgressDispatch(() => {
        const promises = !hydrometricStations.length ? [HydrometryAction.fetchHydrometricStations] : []
        const promisesDataType = !hydrometryDataTypes.length ? [...promises, HydrometryAction.fetchHydrometryDataTypes] : promises
        const promisesThresholds = !hydrometryThresholds.length ? [...promisesDataType, HydrometryAction.fetchHydrometricThresholds] : promisesDataType
        const promisesSettings = !applicationSettings.length ? [...promisesThresholds, AdministrationAction.fetchApplicationSettings] : promisesThresholds
        const promisesContribs = !contributors.length ? [...promisesSettings, ContributorAction.fetchContributors] : promisesSettings
        return promisesContribs.map(p => dispatch(p()))
    }, [])

    const setGraphicOptionsTypeId = (chartOptions, typeId) => {
        const prevGraphicOptions = chartOptions.find(co => co.dataType === `${typeId}`) || {}
        setGraphicOptions({
            minY: prevGraphicOptions.minYear,
            maxY: prevGraphicOptions.maxYear,
            yChoice: (hasValue(prevGraphicOptions.minYear) || hasValue(prevGraphicOptions.maxYear)) ? MANUAL_Y_SCALE : AUTO_Y_SCALE,
            intervalNb: prevGraphicOptions.intervalYear,
            showXSplitLines: prevGraphicOptions.displayXIntervalYear,
            showYSplitLines: prevGraphicOptions.displayYIntervalYear,
        })
    }

    useEffect(() => {
        dispatch(HydrometryAction.fetchHydroLastMeasures([hydrometricStation.id]))
        dispatch(HydrometryAction.fetchHydroStatistics(hydrometricStation.id)).then(stats => {
            const newDataType = hasValue(stats[0]?.typeId) ? stats[0]?.typeId : undefined
            setDataType(newDataType)
            setSelectedCodePoint(hydrometricStation.link_pointPrels.filter(p => p.typeId === newDataType)[0]?.point)
            dispatch(HydrometerStationAction.fetchHydrometerChartOptions(hydrometricStation.id, chartOptions => setGraphicOptionsTypeId(chartOptions, newDataType)))
        })
    }, [hydrometricStation.id])

    const fetchThresholds = () => {
        dispatch(HydrometryAction.fetchHydrometricThresholds())
    }

    useUpdateEffect(() => {
        if (startDate > endDate && startDate && tableView) {
            setStartDate(endDate)
            setEndDate(startDate)
        }
    }, [startDate, endDate])

    useEffect(() => {
        if (isLoaded && dataType) {
            setDataLoaded(false)
            setGraphicOptionsTypeId(hydrometerChartOptions, dataType)
            const groupModeFunc = keys(displayModes).find(mode => displayModes[mode])
            const defaultGroupMode = [90, 365, 'HISTO', 'OTHER'].includes(time) ? 'MAX' : 'all'
            const groupFunc = groupModeFunc === 'auto' ? defaultGroupMode : groupModeFunc
            dispatch(HydrometryAction.loadHydroChronicMeasures([{
                stationId: hydrometricStation.id,
                dataType,
                groupFunc: groupModeFunc === PERSONALIZED_GROUPING ? displayModes.personalizedGroupingValue : groupFunc.toUpperCase(),
                startDate: minDate,
                endDate: maxDate,
                codepoint: selectedCodePoint,
            }])).then(() => {
                setDataLoaded(true)
            })
        }
    }, [isLoaded, dataType, time, isChange, displayModes, selectedCodePoint])

    const hydroMeasuresFormat = useMemo(() => hydroMeasures[0]?.measures || [], [hydroMeasures])

    const hydroDataTypes = useMemo(() => {
        const allHydroDataTypes = hydrometryDataTypes.map(v => ({
            id: v.id,
            label: v.label,
        }))
        return uniqBy([
            ...allHydroDataTypes,
            { id: 4, label: i18n.height, unit: 'm' },
            { id: 5, label: i18n.flow, unit: 'm3' },
        ], 'id')
    }, [hydrometryDataTypes])

    const createTechnicalEvent = (toolState) => {
        const getDates = () => `${i18n.fromDate} ${getFullDate(startDate)} ${i18n.to} ${getFullDate(endDate)}`
        const eventProps = (() => {
            switch (toolState.mode) {
                case PURGE:
                    return {
                        comment: `${i18n.purge} ${getDates()}\n`,
                        problem: JSON.stringify({
                            tool,
                        }),
                    }
                case RESET:
                    return {
                        comment: `${i18n.reseting} ${getDates()}\n`,
                        problem: JSON.stringify({
                            tool,
                        }),
                    }
                case DELETE:
                    return {
                        comment: `${i18n.deleteAction} ${getDates()}\n`,
                        problem: JSON.stringify({
                            tool,
                        }),
                    }
                case VALIDATION:
                    return {
                        comment: `${i18n.validation} ${getDates()}\n`,
                        problem: JSON.stringify({
                            tool,
                        }),
                    }
                case COMPENSATION:
                    return {
                        comment: `${i18n.compensation} ${getDates()}\n`,
                        problem: JSON.stringify({
                            tool,
                        }),
                    }
                case CORRECTION:
                    return {
                        comment: `${i18n.appliedCorrections} ${getDates()}\n`,
                        problem: JSON.stringify({
                            tool,
                        }),
                    }
                case IMPORT:
                    return {
                        comment: `${i18n.importName} ${getDates()}\n`,
                        problem: JSON.stringify({
                            tool,
                        }),
                    }
                default:
                    return { comment: '' }
            }
        })()

        const newEvent = {
            eventType: 'T',
            date: moment().valueOf(),
            eventHour: moment().valueOf(),
            startDate,
            endDate,
            graph: '1',
            ...eventProps,
        }
        dispatch(EventsAction.addEvent('hydrometry', hydrometricStation.id, newEvent))
    }

    const measureBetweenStartEndDates = useMemo(() => {
        return hydroMeasuresFormat.filter(p => p.date >= startDate && p.date <= endDate) || []
    }, [hydroMeasuresFormat, startDate, endDate])

    const nextMeasure = useMemo(() => {
        return hydroMeasuresFormat.find(p => p.date > endDate)
    }, [hydroMeasuresFormat, endDate])

    const statsUsed = uniqBy(orderBy(hydroStatistics, ['order', 'typeId'], 'asc').map(h => ({
        id: h.typeId,
        label: h.label,
    })), 'id')

    const minDateFilterPreview = (date) => {
        const allFirstMeasures = hydroMeasures.flatMap(obj => obj.measures.length ? [obj.measures[0].date] : [])
        return date ?? min(allFirstMeasures) ?? minBy(hydroStatistics, 'startDate')?.startDate ?? moment().subtract(1, 'years').valueOf()
    }
    const maxDateFilterPreview = (date) => date || maxBy([...hydroStatistics, ...hydroMeasures], 'endDate')?.endDate
    const { minDate: formattedMinDate, maxDate: formattedMaxDate } = getChartDate(minDateFilterPreview(minDate), maxDateFilterPreview(maxDate), time)

    return (!isLoaded || isNull(dataType)) ? (
        <Grid container sx={{ padding: '1rem' }}>
            <Grid item xs={12}>
                <ProgressCard progress={progress * 0.9} round />
            </Grid>
        </Grid>
    ) : !hydroStatistics.length ? (
        <MessageCard>{i18n.noDataToDisplay}</MessageCard>
    ) : (
        <div className='row no-margin validationPiezoApp padding-left-2 padding-top-1'>
            <div className='col s9 no-padding'>
                <div>
                    <Card>
                        <Grid container alignItems='center' justifyContent='flex-start'>
                            <Grid item sx={{ padding: '1rem 0 0 50px' }}>
                                <MUIChartTabs
                                    time={time}
                                    onChangeTime={(newTime) => {
                                        setMinDate(newTime.minDate)
                                        setMaxDate(newTime.maxDate)
                                        setTime(newTime.time)
                                    }}
                                    stationType={STATION_TYPE_NAME.hydrometry}
                                    statistics={hydroStatistics}
                                />
                            </Grid>
                            <Grid item paddingTop={1.5} paddingLeft={2}>
                                <SimpleChartTabsHydro
                                    displayModes={displayModes}
                                    changeDisplayModes={v => setDisplayModes(v)}
                                    displayModesUniqChoise
                                />
                            </Grid>
                        </Grid>
                        {!dataLoaded ? (
                            <Grid container sx={{ padding: '1rem' }}>
                                <Grid item xs={12}>
                                    <ProgressCard indeterminate round />
                                </Grid>
                            </Grid>
                        ) : !hydroMeasuresFormat.length ? (
                            <div style={{ paddingTop: 50 }}>
                                <MessageCard>{i18n.notExistDataPeriod}</MessageCard>
                            </div>
                        ) : tableView ? (
                            <TableViewValidationHydroMode2
                                selection={selection}
                                selectionData={selectionData}
                                setSelectionData={setSelectionData}
                                measuresData={hydroMeasures}
                                startDate={startDate}
                                endDate={endDate}
                                setStartDate={setStartDate}
                                setEndDate={setEndDate}
                                minDate={formattedMinDate}
                                maxDate={formattedMaxDate}
                                setValue={setValue}
                                setInitialPoint={setInitialPoint}
                            />
                        ) : (
                            <GraphicValidationHydroMode
                                tool={tool}
                                dataLoaded={dataLoaded}
                                selection={selection}
                                setStartDate={setStartDate}
                                setEndDate={setEndDate}
                                hydroMeasuresFormat={hydroMeasuresFormat}
                                measureToUpdate={measureBetweenStartEndDates}
                                graphicOptions={graphicOptions}
                                startDate={startDate}
                                endDate={endDate}
                                dataType={dataType}
                                hydroDataTypes={hydroDataTypes}
                                minDate={formattedMinDate}
                                maxDate={formattedMaxDate}
                                addLinePrevious={isPreview}
                                setAddLinePrevious={setIsPreview}
                                isPreview={isPreview}
                                isThresholShow={isThresholShow}
                                serieLinePrevious={serieLinePrevious}
                                setSerieLinePrevious={setSerieLinePrevious}
                                hydrometryThresholds={hydrometryThresholds}
                                contributors={contributors}
                                setValueCorrection={setValueCorrection}
                                measuresChangesValidation={measuresChangesValidation}
                                filterFullDay={{ validationFilterFullDay, setValidationFilterFullDay }}
                            />
                        )}
                    </Card>
                </div>
            </div>
            <div className='col s3'>
                <Card>
                    <Row className='padding-top-1 valign-wrapper'>
                        <Select
                            col={ 10 }
                            value={ dataType }
                            options={ statsUsed }
                            label={ i18n.dataType }
                            onChange={ v => {
                                setSelectedCodePoint(hydrometricStation.link_pointPrels.find(s => s.typeId === v)?.point)
                                setDataType(v)
                            } }
                            noNullValue
                            noSort
                        />
                        <div className='col s2'>
                            <Icon
                                icon={ tableView ? 'list' : 'multiline_chart' }
                                onClick={ () => listView.includes(tool) && setTableView(!tableView) }
                                style={{
                                    fontSize: '2.5rem',
                                    color: !listView.includes(tool) ? 'grey' : '#2196F3',
                                }}
                                tooltip={tableView ? i18n.graphicMode : i18n.tableMode}
                            />
                        </div>
                    </Row>
                    <Row className='padding-top-1 valign-wrapper'>
                        <Select
                            col={ 10 }
                            value={ selectedCodePoint }
                            options={ hydrometricStation.link_pointPrels.filter(s => s.typeId === dataType) }
                            label={ i18n.pointPrelevement }
                            onChange={ setSelectedCodePoint }
                            noNullValue
                            noSort
                            keyValue='point'
                        />
                        <div className='col s2' />
                    </Row>
                </Card>
                <Card>
                    <ul style={{ textAlign: 'center', 'margin-top': 5, 'margin-bottom': 5, 'padding-top': 5, 'padding-bottom': 5 }}>
                        <IconTool
                            icon={validateImg}
                            toolIcon={VALIDATION}
                            height={35} width={35}
                            tooltip={i18n.validation}
                            setTool={setTool}
                            iconSelected={tool}
                            isPreview={isPreview}
                        />
                        <IconTool
                            icon={purgeImg}
                            toolIcon={PURGE}
                            height={35}
                            width={30}
                            tooltip={i18n.deleting}
                            setTool={setTool}
                            iconSelected={tool}
                            isPreview={isPreview}
                        />
                        {!tableView && (
                            <>
                                <IconTool
                                    icon={importImg}
                                    toolIcon={IMPORT}
                                    height={35}
                                    width={30}
                                    tooltip={i18n.manualImport}
                                    setTool={setTool}
                                    iconSelected={tool}
                                    isPreview={isPreview}
                                />
                                <IconTool
                                    icon={compensationImg}
                                    toolIcon={COMPENSATION}
                                    height={35} width={50}
                                    tooltip={i18n.compensation}
                                    setTool={setTool}
                                    iconSelected={tool}
                                    isPreview={isPreview}
                                />
                                <IconTool
                                    icon={correctionImg}
                                    toolIcon={CORRECTION}
                                    height={35}
                                    width={45}
                                    tooltip={i18n.driftingCorrection}
                                    setTool={setTool}
                                    iconSelected={tool}
                                    isPreview={isPreview}
                                />
                                <IconTool
                                    icon={optionsImg}
                                    toolIcon={OPTIONS}
                                    height={35}
                                    width={35}
                                    tooltip={i18n.chartOptions}
                                    setTool={setTool}
                                    iconSelected={tool}
                                    isPreview={isPreview}
                                />
                            </>
                        )}
                        <IconTool
                            icon={thresholdImg}
                            toolIcon={THRESHOLDS}
                            setTool={setTool}
                            height={35}
                            width={35}
                            tooltip={i18n.thresholds}
                            iconSelected={tool}
                            isPreview={isPreview}
                        />
                        <IconTool
                            icon={eventImg}
                            toolIcon={EVENT}
                            height={35}
                            width={35}
                            tooltip={i18n.events}
                            setTool={setTool}
                            iconSelected={tool}
                            isPreview={isPreview}
                        />
                    </ul>
                </Card>
                <div className='padding-top-1' />
                {tool === VALIDATION && (
                    <ValidationHydroToolPanel2
                        selection={selection}
                        setSelection={setSelection}
                        setSelectionData={setSelectionData}
                        dates={{ startDate, endDate }}
                        value={value}
                        setValue={setValue}
                        initialPoint={initialPoint}
                        setInitialPoint={setInitialPoint}
                        setStartDate={setStartDate}
                        setEndDate={setEndDate}
                        setIsChange={setIsChange}
                        isChange={isChange}
                        createTechnicalEvent={createTechnicalEvent}
                        setIsPreview={setIsPreview}
                        hydroMeasuresFormat={hydroMeasuresFormat}
                        setMeasuresChangesValidation={setMeasuresChangesValidation}
                        filterFullDay={{ validationFilterFullDay, setValidationFilterFullDay }}
                        displayModes={displayModes}
                    />
                )}
                {tool === PURGE && (
                    <DeleteHydroToolPanel
                        id={hydrometricStation.id}
                        dataType={dataType}
                        selectedCodePoint={selectedCodePoint}
                        startDate={startDate}
                        setStartDate={setStartDate}
                        endDate={endDate}
                        setEndDate={setEndDate}
                        setIsChange={setIsChange}
                        isChange={isChange}
                        isPreview={isPreview}
                        setIsPreview={setIsPreview}
                        dataToReset={measureBetweenStartEndDates}
                        createTechnicalEvent={createTechnicalEvent}
                        displayModes={displayModes}
                        nextMeasure={nextMeasure}
                    />
                )}
                {tool === COMPENSATION && (
                    <CompensationHydroToolPanel
                        startDate={startDate}
                        setStartDate={setStartDate}
                        endDate={endDate}
                        setEndDate={setEndDate}
                        setIsChange={setIsChange}
                        isChange={isChange}
                        createTechnicalEvent={createTechnicalEvent}
                        measures={measureBetweenStartEndDates}
                        serieLinePrevious={serieLinePrevious}
                        setSerieLinePrevious={setSerieLinePrevious}
                        setIsPreview={setIsPreview}
                        measuresData={hydroMeasures}
                    />
                )}
                {tool === CORRECTION && (
                    <CorrectionHydroToolPanel
                        startDate={startDate}
                        setStartDate={setStartDate}
                        endDate={endDate}
                        setEndDate={setEndDate}
                        setIsChange={setIsChange}
                        isChange={isChange}
                        createTechnicalEvent={createTechnicalEvent}
                        measures={measureBetweenStartEndDates}
                        setSerieLinePrevious={setSerieLinePrevious}
                        isPreview={isPreview}
                        setIsPreview={setIsPreview}
                        valueCorrection={valueCorrection}
                        setValueCorrection={setValueCorrection}
                        measuresData={hydroMeasures}
                        serieLinePrevious={serieLinePrevious}
                    />
                )}
                {tool === EVENT && (
                    <EventsToolPanelHydro
                        hydrometricStation={hydrometricStation}
                    />
                )}
                {tool === THRESHOLDS && (
                    <>
                        <ThresholdsTab
                            thresholds={hydrometryThresholds.filter(f => parseInt(f.dataType) == dataType && parseInt(f.stationId) == hydrometricStation.id)}
                            station={hydrometricStation}
                            onReload={() => fetchThresholds()}
                            whiteCard
                            withoutDataTypeAnnotation
                            selectedDataType={dataType.toString()}
                        />
                        <div style={{ marginTop: 10, backgroundColor: 'white', padding: 5 }}>
                            <Checkbox
                                label={ i18n.displayThresholds }
                                checked={isThresholShow}
                                onChange={() => setIsThresholShow(!isThresholShow)}
                            />
                        </div>
                    </>
                )}
                {tool === IMPORT && (
                    <ImportHydroToolPanel
                        startDate={ startDate }
                        setStartDate={ setStartDate }
                        endDate={ endDate }
                        setEndDate={ setEndDate }
                        applicationSettings={ applicationSettings }
                        selectedDataType={ dataType }
                        id={ hydrometricStation.id }
                        hydrometricStation={ hydrometricStation }
                        hydroMeasuresFormat={ hydroMeasuresFormat }
                        isChange={ isChange }
                        setIsChange={ setIsChange }
                        hydroLastMeasures={ lastMeasureHydro[0] }
                        createTechnicalEvent={createTechnicalEvent}
                    />
                )}
                {tool === OPTIONS && (
                    <SimpleOptionsToolPanel
                        showTitle={false}
                        defaultOptions={graphicOptions}
                        updateState={newGraphicOptions => {
                            dispatch(HydrometerStationAction.updateHydrometerChartOptions(hydrometricStation.id, [{
                                code: hydrometricStation.code,
                                minYear: newGraphicOptions.minY,
                                maxYear: newGraphicOptions.maxY,
                                intervalYear: newGraphicOptions.intervalNb,
                                displayXIntervalYear: newGraphicOptions.showXSplitLines ? '1' : '0',
                                displayYIntervalYear: newGraphicOptions.showYSplitLines ? '1' : '0',
                                dataType: `${dataType}`,
                            }])).then(() => setGraphicOptions(prev => ({ ...prev, ...newGraphicOptions })))
                        }}
                    />
                )}
            </div>
        </div>
    )
}

export default HydrometryValidationApp