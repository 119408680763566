/* eslint-disable camelcase */
import User from 'account/dto/User'
import SettingDto from 'administration/dto/SettingDto'
import SimpleDatePicker from 'components/forms/SimpleDatePicker'
import { orderBy } from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import MultiContributorsAutocomplete from 'referencial/components/contributor/components/MultiContributorsAutocomplete'
import ContributorItem from 'referencial/components/contributor/dto/ContributorItem'
import { nbPerPageLabelTiny } from 'referencial/constants/ReferencialConstants'
import { authorizeContributors } from 'utils/HabilitationUtil'
import DtoSandreCode from 'referencial/dto/DtoSandreCode'
import i18n from 'simple-react-i18n'
import { getDate } from 'utils/DateUtil'
import { getSetting } from 'utils/SettingUtils'
import { getSandreLabel } from 'utils/StringUtil'
import Checkbox from '../../../components/forms/Checkbox'
import Input from '../../../components/forms/Input'
import NumberField from '../../../components/forms/NumberField'
import Select from '../../../components/forms/Select'
import Textarea from '../../../components/forms/Textarea'
import DtoVariousMaterielType from '../../../materiel/components/variousMateriel/dto/DtoVariousMaterielType'
import VariousMaterielDto from '../../../materiel/components/variousMateriel/dto/VariousMaterielDto'
import { hasValue } from '../../../utils/NumberUtil'
import { Grid2 } from '@mui/material'
import { CardTable } from 'components/datatable/NewTable'
import { darkBlue } from 'utils/constants/ColorTheme'

const styleLabelSelect = {
    fontSize: '13px',
}

const COUNTERS_TYPES_CAT = 2

class CounterPanel extends Component {
    constructor(props) {
        super(props)
        this.state = {
            counter: props.counter,
            newCodificationId: -1,
        }
    }

    onChangeValue = (obj) => {
        const { counter } = this.props
        this.props.onChange({
            ...counter,
            ...obj,
        })
    }

    onChangeCodification = (obj, id) => {
        const { counter } = this.props
        const codificationUpdated = {
            ...(counter.link_codifications || []).find((c) => c.id === id),
            ...obj,
        }
        this.props.onChange({
            ...counter,
            link_codifications: [
                ...(counter.link_codifications || []).filter((c) => c.id !== id),
                codificationUpdated,
            ],
        })
    }

    addCodification = () => {
        const { counter } = this.props
        const { newCodificationId } = this.state
        this.props.onChange({
            ...counter,
            link_codifications: [
                ...(counter.link_codifications || []),
                {
                    id: newCodificationId,
                    materielType: 5,
                    materielId: counter.id || -1,
                    codificationType: null,
                    internalRef: null,
                    startDate: null,
                    endDate: null,
                },
            ],
        })
        this.setState({ newCodificationId: newCodificationId - 1 })
    }

    onDeleteCodification = (codification) => {
        const { counter } = this.props
        this.props.onChange({
            ...counter,
            link_codifications: [...(counter.link_codifications || []).filter((c) => c.id !== codification.id)],
        })
    }

    getFormattedCodifications = (codifications) => {
        const { disabled, sandreCodes } = this.props
        if (disabled) {
            return (codifications || []).map((c) => {
                return {
                    ...c,
                    codificationType: getSandreLabel(sandreCodes, 'MAT_DIVERS.CODIFICATIONS', c.codificationType),
                    startDate: getDate(c.startDate),
                    endDate: getDate(c.endDate),
                }
            })
        }
        return (codifications || []).map((c) => {
            return {
                ...c,
                codificationType: (
                    <Select
                        id={`${c.id}_codificationType_counter`}
                        value={c.codificationType}
                        options={sandreCodes.filter((s) => s.field === 'MAT_DIVERS.CODIFICATIONS')}
                        onChange={(v) => this.onChangeCodification({ codificationType: v }, c.id)}
                    />
                ),
                internalRef: (
                    <Input
                        id={`${c.id}_internalRef_counter`}
                        value={c.internalRef}
                        onChange={(v) => this.onChangeCodification({ internalRef: v }, c.id)}
                    />
                ),
                startDate: (
                    <SimpleDatePicker
                        id={`${c.id}_startDate_counter`}
                        value={c.startDate}
                        onChange={(v) => this.onChangeCodification({ startDate: v }, c.id)}
                        dateClassName='tableDatePicker'
                    />
                ),
                endDate: (
                    <SimpleDatePicker
                        id={`${c.id}_endDate_counter`}
                        value={c.endDate}
                        onChange={(v) => this.onChangeCodification({ endDate: v }, c.id)}
                        dateClassName='tableDatePicker'
                    />
                ),
            }
        })
    }

    render = () => {
        const { counter, disabled, variousMaterielTypes, applicationSettings, contributors, accountUser, sandreCodes } = this.props
        const counterInfos = counter.counter || {}
        const materielReadingCoeffEntry = getSetting(applicationSettings, 'materielReadingCoeffEntry')
        return (
            <div>
                <Grid2 container sx={{ minHeight: '25vh', padding: '10px' }} spacing={1}>
                    <Grid2 size={4}>
                        <Input
                            title={i18n.identifier}
                            value={counter.reference}
                            onChange={value => this.onChangeValue({ reference: value })}
                            disabled={hasValue(counter.id) || disabled}
                        />
                    </Grid2>
                    <Grid2 size={4}>
                        <Input
                            title={i18n.name}
                            value={counter.name}
                            onChange={value => this.onChangeValue({ name: value })}
                            disabled={disabled}
                        />
                    </Grid2>
                    <Grid2 size={4}>
                        <MultiContributorsAutocomplete
                            options={authorizeContributors(contributors, accountUser, counter.administrator)}
                            label={i18n.administrator}
                            onChange={value => this.onChangeValue({ administrator: value })}
                            values={counter.administrator}
                            disabled={disabled}
                            keyLabel='mnemonique'
                        />
                    </Grid2>
                    <Grid2 size={4}>
                        <Select
                            label={i18n.type}
                            value={counter.materielType}
                            options={variousMaterielTypes.filter(({ category }) => category === COUNTERS_TYPES_CAT)}
                            onChange={value => this.onChangeValue({ materielType: value })}
                            keyValue='id'
                            disabled={disabled}
                            styleLabel={styleLabelSelect}
                        />
                    </Grid2>
                    <Grid2 size={4}>
                        <Select
                            label={i18n.countingType}
                            value={counterInfos.counterType}
                            options={sandreCodes.filter((s) => s.field === 'COMPTAGES.TYPE')}
                            onChange={value => this.onChangeValue({ counter: { ...counterInfos, counterType: value } })}
                            keyValue='id'
                            disabled={disabled}
                            styleLabel={styleLabelSelect}
                        />
                    </Grid2>
                    <Grid2 size={2}>
                        {materielReadingCoeffEntry === '1' ? (
                            <Select
                                label={i18n.readingCoefficient}
                                value={counterInfos.readingCoefficient}
                                options={[{
                                    name: 1,
                                    value: 1,
                                }, {
                                    name: 10,
                                    value: 10,
                                }]}
                                onChange={value => this.onChangeValue({ counter: { ...counterInfos, readingCoefficient: value } })}
                                disabled={disabled}
                                styleLabel={styleLabelSelect}
                            />
                        ) : (
                            <NumberField
                                title={i18n.readingCoefficient}
                                value={counterInfos.readingCoefficient}
                                onChange={value => this.onChangeValue({ counter: { ...counterInfos, readingCoefficient: value } })}
                                disabled={disabled}
                                floatValue
                            />
                        )}
                    </Grid2>
                    <Grid2 size={2} container alignItems='center'>
                        <Checkbox
                            checked={counterInfos.informative}
                            label={i18n.informative}
                            onChange={value => this.onChangeValue({ counter: { ...counterInfos, informative: value } })}
                            disabled={disabled}
                        />
                    </Grid2>
                    <Grid2 size={4}>
                        <Input
                            title={i18n.brand}
                            value={counterInfos.brand}
                            onChange={value => this.onChangeValue({ counter: { ...counterInfos, brand: value } })}
                            disabled={disabled}
                        />
                    </Grid2>
                    <Grid2 size={4}>
                        <Input
                            title={i18n.serialNumber}
                            value={counter.serialNumber}
                            onChange={value => this.onChangeValue({ serialNumber: value })}
                            disabled={disabled}
                        />
                    </Grid2>
                    <Grid2 size={2} container alignItems='center'>
                        <Checkbox
                            checked={counter.isShared}
                            label={i18n.shared}
                            onChange={value => this.onChangeValue({ isShared: value })}
                            disabled={disabled}
                        />
                    </Grid2>
                    <Grid2 size={2} container alignItems='center'>
                        <Checkbox
                            checked={counter.rent}
                            label={i18n.forRent}
                            onChange={value => this.onChangeValue({ rent: value })}
                            disabled={disabled}
                        />
                    </Grid2>
                    <Grid2 size={4}>
                        <Select
                            label={i18n.mobility}
                            value={counter.mobilityCode}
                            options={sandreCodes.filter((c) => c.field === 'MAT.MOBILITE')}
                            onChange={value => this.onChangeValue({ mobilityCode: value })}
                            keyValue='code'
                            disabled={disabled}
                            styleLabel={styleLabelSelect}
                        />
                    </Grid2>
                    <Grid2 size={4}>
                        <SimpleDatePicker
                            id={`${counter.id}_installationDate_counter`}
                            label={i18n.installationDate}
                            value={counterInfos.installationDate}
                            onChange={value => this.onChangeValue({ counter: { ...counterInfos, installationDate: value } })}
                            disabled={disabled}
                        />
                    </Grid2>
                    <Grid2 size={2}>
                        <SimpleDatePicker
                            id={`${counter.id}_revisionDate_counter`}
                            label={i18n.revisionDate}
                            value={counterInfos.revisionDate}
                            onChange={value => this.onChangeValue({ counter: { ...counterInfos, revisionDate: value } })}
                            disabled={disabled}
                        />
                    </Grid2>
                    <Grid2 size={2}>
                        <SimpleDatePicker
                            id={`${counter.id}_agencyDecisionDate_counter`}
                            label={i18n.agencyDecisionDate}
                            value={counterInfos.agencyDecisionDate}
                            onChange={value => this.onChangeValue({ counter: { ...counterInfos, agencyDecisionDate: value } })}
                            disabled={disabled}
                        />
                    </Grid2>
                    <Grid2 size={2} container alignItems='center'>
                        <Checkbox
                            checked={counterInfos.provenImpossibility}
                            label={'IAM'}
                            onChange={value => this.onChangeValue({ counter: { ...counterInfos, provenImpossibility: value } })}
                            disabled={disabled}
                        />
                    </Grid2>
                    <Grid2 size={12}>
                        <Textarea
                            title={i18n.comment}
                            noMargin={false}
                            value={counter.comment}
                            onChange={value => this.onChangeValue({ comment: value })}
                            disabled={disabled}
                        />
                    </Grid2>
                </Grid2>
                <CardTable
                    title={i18n.codifications}
                    actions={[{
                        tooltip: i18n.new,
                        icon: 'note_add',
                        onClick: this.addCodification,
                        color: 'white',
                    }]}
                    rows={orderBy(this.getFormattedCodifications((counter.link_codifications || [])), 'id', 'desc')}
                    lineActions={!disabled ? [{
                        icon: 'delete',
                        displayed: !disabled,
                        onClick: this.onDeleteCodification,
                    }] : []}
                    rowsPerPageOptions={ nbPerPageLabelTiny }
                    headers={[
                        { key: 'codificationType', value: i18n.type },
                        { key: 'internalRef', value: i18n.value },
                        { key: 'startDate', value: i18n.begin },
                        { key: 'endDate', value: i18n.end },
                    ]}
                    color={darkBlue}
                />
            </div>
        )
    }
}

CounterPanel.propTypes = {
    disabled: PropTypes.bool,
    title: PropTypes.string,
    counter: PropTypes.instanceOf(VariousMaterielDto),
    variousMaterielTypes: PropTypes.arrayOf(PropTypes.instanceOf(DtoVariousMaterielType)),
    applicationSettings: PropTypes.arrayOf(PropTypes.instanceOf(SettingDto)),
    sandreCodes: PropTypes.arrayOf(PropTypes.instanceOf(DtoSandreCode)),
    onChange: PropTypes.func,
    contributors: PropTypes.arrayOf(PropTypes.instanceOf(ContributorItem)),
    accountUser: PropTypes.instanceOf(User),
}

const mapStateToProps = (store) => {
    return {
        variousMaterielTypes: store.VariousMaterielReducer.variousMaterielTypes,
        applicationSettings: store.AdministrationReducer.applicationSettings,
        sandreCodes: store.ReferencialReducer.sandreCodes,
        contributors: store.ContributorReducer.contributors,
        accountUser: store.AccountReducer.accountUser,
    }
}

export default connect(mapStateToProps)(CounterPanel)