import { template } from 'lodash'
import BoundaryError from 'log/components/BoundaryError'
import React, { useEffect } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import ToastrAction from 'toastr/actions/ToastrAction'
import AccountAction from '../../account/actions/AccountAction'
import SelectDropdown from '../../components/forms/SelectDropdown'
import AppBar from '../../components/navbar/AppBar'
import SlideNav from '../../components/navbar/SlideNav'
import SieauTooltip from '../../components/tooltip/SieauTooltip'
import SSEActions from '../../sse/actions/SSEActions'
import { FACEBOOK_EVENT_FAILED, FACEBOOK_EVENT_SUCCEEDED, JOB_COMPLETED } from '../../sse/constants/EventsConstants'
import QualityAction from 'quality/actions/QualityAction'
import AdministrationAction from 'administration/actions/AdministrationAction'
import HomeAction from 'home/actions/HomeAction'
import ReferencialAction from 'referencial/action/ReferencialAction'
import { getLogin } from 'utils/SettingUtils'
import { TIMEZONE_INTEGRATION, TIMEZONE_LOCAL } from 'utils/DateUtil'
import moment from 'moment-timezone'
import useAccountSetting from 'utils/customHook/useAccountSetting'
import { Outlet } from 'react-router'

// eslint-disable-next-line no-unused-vars
const SSE = () => {
    const dispatch = useDispatch()

    const handleJobCompleted = event => {
        const { job } = JSON.parse(event.data)
        const jobCompleted = template(i18n.jobIDCompleted)({ jobId: job.name })
        dispatch(ToastrAction.success(jobCompleted))
    }

    const handleFacebookPublication = event => {
        switch (event.type) {
            case FACEBOOK_EVENT_FAILED:
                dispatch(ToastrAction.error(i18n.fbPublishError))
                break
            case FACEBOOK_EVENT_SUCCEEDED:
                dispatch(ToastrAction.success(i18n.fbPublisherSuccess))
                break
            default:
                break
        }
    }

    useEffect(() => {
        dispatch(SSEActions.connect())
        dispatch(SSEActions.subscribe(JOB_COMPLETED, handleJobCompleted))
        dispatch(SSEActions.subscribe(FACEBOOK_EVENT_FAILED, handleFacebookPublication))
        dispatch(SSEActions.subscribe(FACEBOOK_EVENT_SUCCEEDED, handleFacebookPublication))
        return () => {
            dispatch(SSEActions.stop())
        }
    }, [])

    return null
}

const SieauApp = () => {
    const dispatch = useDispatch()

    const {
        stationTypes,
        settings,
        accountHabilitations,
    } = useSelector(store => ({
        stationTypes: store.ReferencialReducer.stationTypes,
        settings: store.AdministrationReducer.settings,
        accountHabilitations: store.AccountReducer.accountHabilitations,
    }), shallowEqual)

    const timezone = useAccountSetting('usedTimezone')

    const login = getLogin()

    useEffect(() => {
        dispatch(QualityAction.fetchQualityIndicators())
        dispatch(AdministrationAction.fetchPictos())
        dispatch(HomeAction.fetchThemeLayers())
        dispatch(AdministrationAction.fetchSettings())
        dispatch(AdministrationAction.fetchCartographyThemes())
        dispatch(ReferencialAction.fetchStationTypes())
    }, [])

    useEffect(() => {
        switch (timezone) {
            case TIMEZONE_INTEGRATION: moment.tz.setDefault('Europe/London'); break
            case TIMEZONE_LOCAL: moment.tz.setDefault(); break
            default: moment.tz.setDefault()
        }
    }, [timezone])

    useEffect(() => {
        dispatch(AccountAction.fetchUser(login))
        dispatch(AccountAction.fetchAccountStatistics(login))
    }, [login])

    return !!stationTypes.length && !!settings.length && !!accountHabilitations.length && (
        <div className='body-blue'>
            {/* <SSE /> */}
            <SelectDropdown />
            <div id='sieauApp' style={{ minHeight: '100vh' }}>
                <AppBar />
                <SlideNav />
                <BoundaryError>
                    <div style={{ backgroundColor: '#e7eaef' }}>
                        <Outlet />
                    </div>
                </BoundaryError>
                <SieauTooltip />
            </div>
        </div>
    )
}

export default SieauApp
