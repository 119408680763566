import React from 'react'
import { sieauTooltip } from './FormUtils'
import { getQualifications, getShortStatuses, UNQUALIFIED } from './QualityUtils'
import { getLabel } from './StoreUtils'
import { maxBy, sum } from 'lodash'
import { getColorCircleElement, getRGBColor } from './ColorUtil'
import { lightGrey } from './constants/ColorTheme'
import i18n from 'simple-react-i18n'
import Pie from '../components/echart/series/Pie'
import EChart from '../components/echart/EChart'
import { Grid, Icon } from '@mui/material'
import { getQualificationColor, getStatusColor } from './AnalyseUtils'
import * as math from './NumberUtil'
import {
    AVERAGE,
    BRUTE, CUMUL_PERSO_AVERAGE,
    CUMUL_PERSO_MAX,
    CUMUL_PERSO_MIN, CUMUL_PERSO_SUM,
    MAX,
    MIN,
    PERSONALIZED_GROUPING,
} from './constants/MeasureConstants'

const square = ([color, text, center, textColor], size, tooltip) => {
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width={size}
            height={size}
            viewBox='0 0 62 62'
            version='1.1'
            id='svg4572'
            style={{ background: 'none', maxWidth: 'none' }}
            {...tooltip}
        >
            <defs id='defs4566'/>
            <g
                id='layer1'
                transform='translate(0,-235)'
            >
                <rect
                    style={{
                        fill: color,
                        fillOpacity: 1,
                        stroke: '#000000',
                        strokeWidth: 0.5,
                        strokeMiterlimit: 4,
                        strokeDasharray: 'none',
                        strokeDashoffset: 0,
                        strokeOpacity: 1,
                    }}
                    id='rect4560'
                    width='60'
                    height='60'
                    x='1'
                    y='236'
                />
                <text
                    style={{
                        fontStyle: 'normal',
                        fontWeight: 'normal',
                        fontSize: 35,
                        lineHeight: 1.25,
                        fontFamily: 'sans-serif',
                        letterSpacing: 0,
                        wordSpacing: 0,
                        fill: textColor,
                        fillOpacity: 1,
                        stroke: 'none',
                        strokeWidth: 0.26458332,
                    }}
                    x='13.5375'
                    y='275.26456'
                    id='text818'
                >
                    <tspan
                        id='tspan816'
                        x={center ? 21 : 10}
                        y='275.26456'
                        style={{ strokeWidth: 0.26458332 }}
                    >{text}
                    </tspan>
                </text>
            </g>
        </svg>
    )
}

const round = ([color, text, center, textColor], size, tooltip) => {
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width={size}
            height={size}
            viewBox='0 0 62 62'
            version='1.1'
            id='svg4572'
            style={{ background: 'none', maxWidth: 'none' }}
            {...tooltip}
        >
            <defs
                id='defs4566'
            />
            <g
                id='layer1'
                fill='none'
                transform='translate(0,-235)'
            >
                <circle
                    style={{
                        fill: color,
                        fillOpacity: 1,
                        stroke: '#000000',
                        strokeWidth: 0.5,
                        strokeMiterlimit: 4,
                        strokeDasharray: 'none',
                        strokeDashoffset: 0,
                        strokeOpacity: 1,
                    }}
                    id='path4564'
                    cx='31'
                    cy='266'
                    r='30.000002'
                />
                <text
                    style={{
                        fontStyle: 'normal',
                        fontWeight: 'normal',
                        fontSize: 35,
                        lineHeight: 1.25,
                        fontFamily: 'sans-serif',
                        letterSpacing: 0,
                        wordSpacing: 0,
                        fill: textColor,
                        fillOpacity: 1,
                        stroke: 'none',
                        strokeWidth: 0.26458332,
                    }}
                    x='13.5375'
                    y='275.26456'
                    id='text818'
                >
                    <tspan
                        id='tspan816'
                        x={center ? 21 : 10}
                        y='275.26456'
                        style={{
                            fontSize: 25.39999962,
                            strokeWidth: 0.26458332,
                        }}
                    >{text}
                    </tspan>
                </text>
            </g>
        </svg>
    )
}

const diamond = ([color, text, center, textColor], size, tooltip) => {
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width={size}
            height={size}
            viewBox='0 0 62 62'
            version='1.1'
            style={{ background: 'none', maxWidth: 'none' }}
            {...tooltip}
            id='svg4572'
        >
            <defs
                id='defs4566'
            />
            <g
                id='layer1'
                transform='translate(0,-235)'
            >
                <rect
                    style={{
                        fill: color,
                        fillOpacity: 1,
                        stroke: '#000000',
                        strokeWidth: 0.3506324,
                        strokeMiterlimit: 4,
                        strokeDasharray: 'none',
                        strokeDashoffset: 0,
                        strokeOpacity: 1,
                    }}
                    id='rect4560'
                    width='42.075893'
                    height='42.075893'
                    x='188.58186'
                    y='145.62498'
                    transform='rotate(44.865417)'
                />
                <text
                    style={{
                        fontStyle: 'normal',
                        fontWeight: 'normal',
                        fontSize: 25.39999962,
                        lineHeight: 1.25,
                        fontFamily: 'sans-serif',
                        letterSpacing: 0,
                        wordSpacing: 0,
                        fill: textColor,
                        fillOpacity: 1,
                        stroke: 'none',
                        strokeWidth: 0.26458332,
                    }}
                    x='13.5375'
                    y='275.26456'
                    id='text818'
                >
                    <tspan
                        id='tspan816'
                        x={center ? 21 : 14}
                        y='275.26456'
                        style={{ strokeWidth: 0.26458332, fontSize: 25.39999962 }}
                    >{text}
                    </tspan>
                </text>
            </g>
        </svg>
    )
}

const triangle = ([color, text, center, textColor], size, tooltip) => {
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width={size}
            height={size}
            viewBox='0 0 62 62'
            version='1.1'
            id='svg4572'
            style={{ background: 'none', maxWidth: 'none' }}
            {...tooltip}
        >
            <defs
                id='defs4566'
            />
            <g
                id='layer1'
                transform='translate(0,-235)'
            >
                <path
                    style={{
                        fill: color,
                        fillOpacity: 1,
                        stroke: '#000000',
                        strokeWidth: 0.87967676,
                        strokeMiterlimit: 4,
                        strokeDasharray: 'none',
                        strokeDashoffset: 0,
                        strokeOpacity: 1,
                    }}
                    id='path4528'
                    d='M 130.37649,89.49107 -27.15724027,89.546058 51.562002,-0.14427486 Z'
                    transform='matrix(0.57877575,0,0,0.66004723,1.1294661,236.64032)'
                />
                <text
                    style={{
                        fontStyle: 'normal',
                        fontWeight: 'normal',
                        fontSize: 35,
                        lineHeight: 1.25,
                        fontFamily: 'sans-serif',
                        letterSpacing: 0,
                        wordSpacing: 0,
                        fill: textColor,
                        fillOpacity: 1,
                        stroke: 'none',
                        strokeWidth: 0.26458332,
                    }}
                    x='13.5375'
                    y='289.56348'
                    id='text818'
                >
                    <tspan
                        id='tspan816'
                        x={ center ? 21 : 10 }
                        y='289.56348'
                        style={{ strokeWidth: 0.26458332 }}
                    >{text}
                    </tspan>
                </text>
            </g>
        </svg>
    )
}

const getColor = (status) => {
    switch (status) {
        case 2:
            return ['#54cb59', 'N1', false, '#000000']
        case 3:
            return ['#06801a', 'N2', false, '#ffffff']
        case 4:
            return ['#397eef', <>&nbsp;&nbsp;i</>, false, '#000000']
        default:
            return ['#c3c3c3', 'B', true, '#000000']
    }
}

const getShape = (qualification) => {
    switch (qualification) {
        case 1:
            return square
        case 2:
            return triangle
        case 3:
            return diamond
        default:
            return round
    }
}

const getStatusSelectOptions = () => [
    { id: '1', code: 1, name: 'Brute', icon: getShape(1)(['#c3c3c3', 'B', true, '#000000'], 20) },
    { id: '2', code: 2, name: 'Contrôlée niveau 1', icon: getShape(1)(['#54cb59', 'N1', false, '#000000'], 20) },
    { id: '3', code: 3, name: 'Contrôlée niveau 2', icon: getShape(1)(['#06801a', 'N2', false, '#ffffff'], 20) },
    { id: '4', code: 4, name: 'Interprétée', icon: getShape(1)(['#397eef', <>&nbsp;&nbsp;i</>, false, '#000000'], 20) },
]

const getQualificationSelectOptions = () => [
    { id: '4', code: 4, name: i18n.unqualified, icon: getShape(4)(['#000000', '', true, '#000000'], 20) },
    { id: '2', code: 2, name: i18n.incorrect, icon: getShape(2)(['#000000', '', true, '#000000'], 20) },
    { id: '3', code: 3, name: i18n.uncertain, icon: getShape(3)(['#000000', '', true, '#000000'], 20) },
    { id: '1', code: 1, name: i18n.correct, icon: getShape(1)(['#000000', '', true, '#000000'], 20) },
]

const getOptionCumul = () => [
    { value: MAX, label: i18n.max },
    { value: MIN, label: i18n.min },
    { value: AVERAGE, label: i18n.average },
    { value: PERSONALIZED_GROUPING, label: i18n.personalizedGrouping },
    { value: BRUTE, label: i18n.brute },
]
const getOptionCumulPerso = () => [
    { value: CUMUL_PERSO_MAX, label: i18n.max },
    { value: CUMUL_PERSO_MIN, label: i18n.min },
    { value: CUMUL_PERSO_SUM, label: i18n.sum },
    { value: CUMUL_PERSO_AVERAGE, label: i18n.average },
]

const statusLabel = (obj) => {
    return `${getLabel(getShortStatuses(), parseInt(obj.status || 1))} ${getLabel(getQualifications(), parseInt(obj.qualification || UNQUALIFIED))}`
}

const statusIcon = (obj, size = 25, withTooltip = false) => {
    const tooltip = withTooltip ? sieauTooltip(() => statusLabel(obj)) : {}
    return getShape(parseInt(obj.qualification || 4))(getColor(parseInt(obj.status || 1)), size, tooltip)
}

const getStatusOrQualificationPie = (statusOrQualification, values) => {
    const propList = statusOrQualification === 'status' ? getStatusSelectOptions() : getQualificationSelectOptions()
    const colorFunction = statusOrQualification === 'status' ? getStatusColor : getQualificationColor

    const total = sum(values.map(s => s.value))

    const data = values.map(qualification => {
        const percentage = `${math.round(qualification.value * 100 / total)}%`
        const color = qualification.id !== 'undefined' ? getRGBColor(colorFunction(qualification.id)) : lightGrey
        return {
            titleName: percentage,
            value: qualification.value,
            itemStyle: {
                normal: {
                    color: color === 'white' ? 'lightgrey' : color,
                },
            },
            tooltip: {
                formatter: (params) => {
                    return `${i18n[statusOrQualification]} (${percentage})</br>
                        ${getColorCircleElement(params.color)}${getLabel(propList, qualification.id) || i18n.notDefined}: ${params.value} ${i18n.of} ${total} ${i18n.operations}`
                },
            },
        }
    })

    const options = {
        series: [Pie({
            data,
            name: i18n[statusOrQualification],
            radius: ['65%', '90%'],
        })],
        tooltip: {
            trigger: 'item',
            // position: getExploitationAEP(this.props.settings) ? null : ['100%', '0%'],
            position: ['100%', '0%'],
        },
        customTitle: {
            text: maxBy(data, 'value')?.titleName,
            left: 'center',
            top: '48px',
            textStyle: {
                fontSize: 12,
                color: maxBy(data, 'value')?.itemStyle?.normal?.color,
            },
        },
        height: 120,
    }
    // return null
    return (
        <div>
            <div className='row no-margin'>
                <h6 className='center-align' style={{ fontWeight: 'bold' }}>{i18n[statusOrQualification]}</h6>
            </div>
            <div className='row no-margin'>
                {data.length && <EChart options={options} /> || <Grid className='text-align-center'><Icon fontSize='large'>cloud_off</Icon></Grid>}
            </div>
        </div>
    )
}

export { statusIcon, statusLabel, getStatusSelectOptions, getQualificationSelectOptions, getStatusOrQualificationPie, getOptionCumul, getOptionCumulPerso }
