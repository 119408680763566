import piezometerIcon from 'assets/pictures/markers/map_piezo.png'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import Table from '../../../components/datatable/Table'
import NumberField from '../../../components/forms/NumberField'
import ListComponent from '../../../components/list/tableList/ListComponent'
import CartographyPanel from '../../../components/map/CartographyPanel'
import DtoHydrometricStation from '../../../hydrometry/dto/DtoHydrometricStation'
import DtoInstallation from '../../../installation/dto/installation/DtoInstallation'
import DtoPiezometer from '../../../piezometry/dto/DtoPiezometer'
import PiezometerAccessibilitieDto from '../../../piezometry/dto/PiezometerAccessibilitieDto'
import PluviometerDto from '../../../pluviometry/dto/PluviometerDto'
import DtoQualitometerLight from '../../../quality/dto/DtoQualitometerLight'
import ContactDto from '../../../referencial/components/contact/dto/ContactDto'
import { nbPerPageLabelShort } from '../../../referencial/constants/ReferencialConstants'
import { hasValue } from '../../../utils/NumberUtil'
import DtoStationCampaign from '../../dto/DtoStationCampaign'


const MapCampaignStation = ({
    stations = [],
    accessibilities = [],
    contacts = [],
    stationType = '',
    editMode = false,
    onChangeOrder = () => { },
}) => {
    const stationsFormat = stations.map(station => {
        switch (stationType) {
            case 'quality':
                return {
                    ...station,
                    markerIcon: 'pictures/markers/map_qualite.png',
                    scale: 1,
                }
            case 'pluviometry':
                return {
                    ...station,
                    markerIcon: 'pictures/markers/map_pluvio.png',
                    order: editMode ? <NumberField value={station.order} onChange={v => onChangeOrder(station.id, v)} /> : station.order,
                    scale: 1,
                }
            case 'hydrometry':
                return {
                    ...station,
                    markerIcon: 'pictures/markers/map_hydro.png',
                    order: editMode ? <NumberField value={station.order} onChange={v => onChangeOrder(station.id, v)} /> : station.order,
                    scale: 1,
                }
            case 'installation':
                return {
                    ...station,
                    markerIcon: 'pictures/markers/map_installations.png',
                    order: editMode ? <NumberField value={station.order} onChange={v => onChangeOrder(station.id, v)} /> : station.order,
                    scale: 1,
                }
            default:
                const stationAccess = accessibilities.filter(access => access.id === station.id)
                const accessibility = stationAccess.find(a => hasValue(a.description)) ? '_access' : ''
                const contact = stationAccess.find(a => hasValue(a.contactCode)) || station.contactCode ? '_contact' : ''
                const stationContacts = [
                    ...stationAccess.filter(a => hasValue(a.contactCode)).map(a => a.contactCode),
                    ...(station.contactCode ? [station.contactCode] : []),
                ].map(c => contacts.find(cont => cont.id === c) || {})
                const phone = stationContacts.find(c => c.phoneTel || c.mobile) ? '_phone' : ''
                return {
                    ...station,
                    markerIcon: `pictures/markers/map_piezo_white${contact}${phone}${accessibility}.png`,
                    markerText: station.order ? station.order.toString() : '',
                    order: editMode ? <NumberField value={station.order} onChange={v => onChangeOrder(station.id, v)} /> : station.order,
                    scale: 1,
                }
        }
    })

    const stationList = {
        title: (
            <div className='col s12' >
                <div className='row no-margin valign-wrapper padding-top-1 padding-bottom-1'>
                    <div className='col s2 center-align'>
                        <div className='iconeTypeResearchWrapper'>
                            <img src={piezometerIcon} className='responsive-img iconeTypeResearch' />
                        </div>
                    </div>
                    <div className='col s9 no-padding'>
                        <h6 className='bold font-size-16'>
                            {`${i18n[stationType]}(${stationsFormat.length})`}
                        </h6>
                    </div>
                </div>
            </div>
        ),
        component: (
            <Table
                data={stationsFormat}
                sortable
                paging
                nbPerPageLabel={nbPerPageLabelShort}
                showTitle={false}
                type={{ headers: stationType === 'quality' ? ['code', 'name', 'city'] : ['code', 'name', 'city', 'order'] }}
                condensed
            />
        ),
    }

    return (
        <CartographyPanel
            layers={['STATIONS_POINTS']}
            componentType={stationType}
            stationsPoints={stationsFormat}
            stationsPanelTitle={i18n.stations}
            colorMode
            noStationPanel
            height={window.innerHeight - $('.nav-wrapper').height()}
            panels={[{
                icon: 'list',
                title: i18n.stations,
                content: (
                    <div className='row no-margin'>
                        <ListComponent tables={[stationList]} accordion />
                    </div>
                ),
            }]}
        />
    )
}

MapCampaignStation.propTypes = {
    selectedStations: PropTypes.arrayOf(PropTypes.instanceOf(DtoStationCampaign)),
    stations: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.instanceOf(PluviometerDto)),
        PropTypes.arrayOf(PropTypes.instanceOf(DtoPiezometer)),
        PropTypes.arrayOf(PropTypes.instanceOf(DtoQualitometerLight)),
        PropTypes.arrayOf(PropTypes.instanceOf(DtoHydrometricStation)),
        PropTypes.arrayOf(PropTypes.instanceOf(DtoInstallation)),
    ]),
    accessibilities: PropTypes.arrayOf(PropTypes.oneOfType([
        PropTypes.instanceOf(PiezometerAccessibilitieDto),
    ])),
    stationType: PropTypes.oneOf(['piezometry', 'pluviometry', 'hydrometry', 'quality', 'installation']),
    editMode: PropTypes.bool,
    onChangeOrder: PropTypes.func,

    contacts: PropTypes.arrayOf(PropTypes.instanceOf(ContactDto)),
}

const mapStateToProps = store => ({
    contacts: store.ContactReducer.contacts,
})

export default connect(mapStateToProps)(MapCampaignStation)