import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { v4 as uuidv4 } from 'uuid'

const $ = window.$

class CollapsibleCard extends Component {
    constructor(props) {
        super(props)
        this.state = {
            open: false,
            collapsible: uuidv4(),
        }
        this.toggleOpen = this.toggleOpen.bind(this)
    }

    toggleOpen = () => {
        this.setState(({ open }) => ({ open: !open }))
    }

    componentDidMount() {
        $(`.${this.state.collapsible}`).collapsible()
        this.setState({ open: this.props.active })
    }

    render() {
        return (
            <ul className={ `no-margin ${this.state.collapsible}` } data-collapsible='expandable'>
                <li className='row no-margin simple-section'>
                    <div className={ `col s12 collapsible-header no-margin ${this.props.noPadding ? 'no-padding' : ''} ${this.props.color || ''} ${this.props.className || ''}`} style={{ ...this.props.style }} onClick={ this.toggleOpen }>
                        <div className='col s11'>{ this.props.header }</div>
                        <i className='col s1 right-align small material-icons no-margin expand-style-thin font-white align-self-center'>{ this.state.open ? 'expand_less ' : 'expand_more' }</i>
                    </div>
                    <div className={ `col s12 content no-padding collapsible-body white border-${this.props.color}` }>
                        { this.props.children }
                    </div>
                </li>
            </ul>
        )
    }
}

CollapsibleCard.propTypes = {
    onClick: PropTypes.func,
    collapsible: PropTypes.bool,
    header: PropTypes.element.isRequired,
    color: PropTypes.string.isRequired,
    children: PropTypes.element.isRequired,
    className: PropTypes.string,
    noPadding: PropTypes.string,
    style: PropTypes.object,
    active: PropTypes.bool,
}

CollapsibleCard.defaultProps = {
    collapsible: false,
    noPadding: true,
    active: false,
}

export default CollapsibleCard
