/* eslint-disable camelcase */
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import Table from '../../../components/datatable/Table'
import SieauAction from '../../../components/sieau/SieauAction'
import DtoDistributionUnit from '../../../distributionUnit/dto/DtoDistributionUnit'
import DtoHydrometricStation from '../../../hydrometry/dto/DtoHydrometricStation'
import DtoInstallation from '../../../installation/dto/installation/DtoInstallation'
import DtoPiezometer from '../../../piezometry/dto/DtoPiezometer'
import DtoProductionUnit from '../../../productionUnit/dto/DtoProductionUnit'
import DtoQualitometer from '../../../quality/dto/DtoQualitometer'
import { SANDRE } from '../../../referencial/constants/ReferencialConstants'
import DtoSandreCode from '../../../referencial/dto/DtoSandreCode'
import { getExport, setModal } from '../../../utils/linkUtils'
import { arrayOf } from '../../../utils/StoreUtils'
import { getSandreLabel } from '../../../utils/StringUtil'
import LocationModal from './modals/LocationModal'

const StationLocationPanel = ({
    station = {},
    sandreCodes = [],
    readMode = false,
    onChange = () => {},
    setPopup = () => {},
}) => {
    const stationLocations = station.link_locations.map((c, index) => ({
        ...c,
        index,
    }))
    const tableData = stationLocations.map(c => ({
        ...c,
        projection: getSandreLabel(sandreCodes, SANDRE.PROJECTION, c.projection),
    }))
    const headers = ['projection', 'x', 'y', 'z', 'precision']
    const exportAction = getExport(tableData, i18n.otherLocations, headers)
    const actions = !readMode ? [{
        iconName: 'note_add',
        tooltip: i18n.add,
        onClick: () => setModal(i18n.newLocation, <LocationModal id={ station.id } saveResult={ r => onChange({ link_locations: [...stationLocations, r] }) }/>, setPopup),
    }, exportAction] : [exportAction]

    return (
        <Table
            title={ i18n.otherLocations }
            condensed
            data={ tableData }
            type={{ headers }}
            sortable={ !!tableData.length }
            actions={ actions }
            alterable={ !readMode }
            onAlter={
                (element) => setModal(i18n.editLocation,
                    <LocationModal
                        id={ station.id }
                        location={ stationLocations.find(c => element.index === c.index) }
                        saveResult={ r => onChange({
                            link_locations: [
                                ...stationLocations.filter(lc => lc.index !== element.index),
                                { ...r, index: element.index },
                            ],
                        })}
                    />
                    , setPopup)
            }
            deletable={ !readMode }
            onDelete={ (element) => onChange({ link_locations: stationLocations.filter(lc => lc.index !== element.index) }) }
        />
    )
}

StationLocationPanel.propTypes = {
    station: PropTypes.oneOfType([
        PropTypes.instanceOf(DtoProductionUnit),
        PropTypes.instanceOf(DtoDistributionUnit),
        PropTypes.instanceOf(DtoQualitometer),
        PropTypes.instanceOf(DtoPiezometer),
        PropTypes.instanceOf(DtoHydrometricStation),
        PropTypes.instanceOf(DtoInstallation),
    ]),
    sandreCodes: arrayOf(DtoSandreCode),
    readMode: PropTypes.bool,
    onChange: PropTypes.func,
    setPopup: PropTypes.func,
}

const mapStateToProps = store => ({
    sandreCodes: store.ReferencialReducer.sandreCodes,
})

const mapDispatchToProps = {
    setPopup: SieauAction.setPopup,
}

export default connect(mapStateToProps, mapDispatchToProps)(StationLocationPanel)