import React, { useEffect, useMemo } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import CartographyPanel from '../../../../components/map/CartographyPanel'
import { PATH_REFERENCIAL, PATH_REFERENCIAL_WATERMASS, PATH_REFERENCIAL_WATERMASS_ITEM_LINK } from 'home/constants/RouteConstants'
import WatermassAction from '../actions/WatermassAction'
import useTitle from 'utils/customHook/useTitle'
import useActions from 'utils/customHook/useActions'
import { findStationType } from 'utils/StationUtils'
import { useParams } from 'react-router'

const WatermassMapApp = () => {
    const dispatch = useDispatch()
    const { code } = useParams()

    const {
        watermass,
        stationsWithWatermass,
    } = useSelector(store => ({
        watermass: store.WatermassReducer.watermass,
        stationsWithWatermass: store.WatermassReducer.stationsWithWatermass,
    }), shallowEqual)

    useTitle(() => [{
        title: i18n.referencials,
        href: PATH_REFERENCIAL,
    },
    {
        title: i18n.watermasses,
        href: PATH_REFERENCIAL_WATERMASS,
    },
    {
        title: code + (watermass.label ? ` - ${watermass.label}` : ''),
        href: PATH_REFERENCIAL_WATERMASS_ITEM_LINK + code,
    }], [code, watermass])

    useActions(() => ({}), [])

    useEffect(() => {
        dispatch(WatermassAction.fetchStationsWithWatermass(code))
    }, [code])

    const stationsWatermass = useMemo(() => stationsWithWatermass.filter(s => s.x && s.y && s.projection).map(s => ({
        ...s,
        typeName: findStationType(s.type)?.type || '',
    })), [stationsWithWatermass])

    return (
        <CartographyPanel
            layers={ ['STATIONS_POINTS'] }
            stationsPoints={stationsWatermass}
            componentType={ 'referencials' }
            watermass={ code }
            stationsPanelTitle={ i18n.stations }
        />
    )
}

export default WatermassMapApp
