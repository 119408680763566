import React, { useEffect, useState } from 'react'
import i18n from 'simple-react-i18n'
import PropTypes from 'prop-types'
import { Button, Grid2, Icon, IconButton, InputAdornment, TextField } from '@mui/material'
import { orderBy } from 'lodash'
import { getFullDateMini } from '../../utils/DateUtil'
import { lightGrey, mainBlue } from 'utils/constants/ColorTheme'
import CMSEventDto from 'events/dto/CMSEventDto'
import DtoCMSMessage from 'events/dto/DtoCmsMessage'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import CmsAction from 'events/actions/CmsAction'
import { getLogin } from 'utils/SettingUtils'
import moment from 'moment'
import ProgressBar from 'components/progress/ProgressBar'
import { AccordionDetailsMUI, AccordionMUI, AccordionSummaryMUI } from 'components/styled/Accordions'

const CmsMessageDisplay = ({
    cmsMessage = {},
    login = '',
}) => {
    const dispatch = useDispatch()

    const [focus, setFocus] = useState(false)

    const archiveMessage = () => {
        dispatch(CmsAction.archiveMessage(cmsMessage.id)).then(() => {
            dispatch(CmsAction.fetchCmsMessages(cmsMessage.cmsId))
        })
    }

    return (
        <Grid2 container justifyContent={login === cmsMessage.author ? 'flex-end' : 'flex-start'}>
            <Grid2
                container size={10}
                sx={{
                    background: cmsMessage.archive ? lightGrey : 'white',
                    border: 'solid grey 1px',
                    padding: '1rem',
                    marginBottom: '1rem',
                    borderRadius: '5px',
                }}
                onMouseEnter={() => setFocus(true)}
                onMouseLeave={() => setFocus(false)}
            >
                <Grid2 size={6}>
                    {`${cmsMessage.author} - ${getFullDateMini(cmsMessage.dateMessage)}`}
                </Grid2>
                <Grid2 container size={6} justifyContent='flex-end' sx={{ height: '32px' }}>
                    {!focus && cmsMessage.archive && (
                        <Grid2 size={6}>{`${i18n.archiveOn}${getFullDateMini(cmsMessage.dateArchive)}`}</Grid2>
                    )}
                    {!!focus && ((
                        <Button
                            variant='outlined'
                            onClick={archiveMessage}
                            sx={{
                                color: !focus && (cmsMessage.archive ? lightGrey : 'white'),
                                borderColor: !focus && (cmsMessage.archive ? lightGrey : 'white'),
                            }}
                        >
                            {cmsMessage.archive ? i18n.unarchive : i18n.archive}
                        </Button>
                    ))}
                </Grid2>
                <Grid2 size={12}>
                    <span style={{ whiteSpace: 'pre-wrap' }}>
                        {cmsMessage.message}
                    </span>
                </Grid2>
            </Grid2>
        </Grid2>
    )
}

CmsMessageDisplay.propTypes = {
    cmsMessage: PropTypes.instanceOf(DtoCMSMessage),
    login: PropTypes.string,
}

const CmsMessages = ({
    cms = {},
}) => {
    const {
        cmsMessages,
    } = useSelector(store => ({
        cmsMessages: store.EventsReducer.cmsMessages,
    }), shallowEqual)

    const [tempMessage, setTempMessage] = useState('')
    const [loaded, setLoaded] = useState(false)

    const dispatch = useDispatch()

    useEffect(() => {
        if (cms.id) {
            dispatch(CmsAction.fetchCmsMessages(cms.id)).then(() => setLoaded(true))
        } else {
            setLoaded(true)
        }
    }, [cms.id])

    const login = getLogin()

    const addMessage = () => {
        const newMessages = [{
            cmsId: cms.id && cms.id,
            message: tempMessage,
            author: login,
            dateMessage: moment().valueOf(),
        }]
        dispatch(CmsAction.addCmsMessages(newMessages)).then(() => {
            setTempMessage('')
            setLoaded(false)
            dispatch(CmsAction.fetchCmsMessages(cms.id)).then(() => setLoaded(true))
        })
    }

    const sortedMessages = orderBy(cmsMessages, 'dateMessage', 'desc')

    return !!cms.id && (
        <AccordionMUI defaultExpanded>
            <AccordionSummaryMUI sx={{ padding: '1rem', borderRadius: '5px' }}>
                <h4 className='no-margin' style={{ fontSize: '24px' }}>{i18n.comments}</h4>
            </AccordionSummaryMUI>
            <AccordionDetailsMUI sx={{ padding: 0, background: 'transparent', maxHeight: '60vh', overflowY: 'auto' }}>
                <Grid2 container sx={{ padding: '1rem' }}>
                    {!loaded ? <ProgressBar indeterminate /> : (
                        <>
                            <Grid2 container size={12} alignItems='center'>
                                <Grid2 container size={12} justifyContent='space-between' alignItems='center'>
                                    <Grid2 size={12}>
                                        <TextField
                                            fullWidth
                                            multiline
                                            rows={4}
                                            label={i18n.yourMessage}
                                            value={tempMessage}
                                            onChange={e => setTempMessage(e.target.value)}
                                            sx={{
                                                '& .MuiInputLabel-outlined': {
                                                    fontSize: '16px',
                                                },
                                                '& legend': {
                                                    fontSize: '0.90em',
                                                },
                                            }}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position='end'>
                                                        <IconButton
                                                            onClick={addMessage}
                                                            sx={{ color: mainBlue, '& .material-icons': { fontSize: '2.8rem' }, padding: '1rem', marginRight: '-5px' }}
                                                            onMouseDown={e => e.preventDefault()}
                                                            edge='end'
                                                        >
                                                            <Icon>message</Icon>
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </Grid2>
                                </Grid2>
                                <Grid2 size={12} sx={{ marginTop: '1rem' }}>
                                    {sortedMessages.map((cmsMessage) => <CmsMessageDisplay key={cmsMessage.id} cmsMessage={cmsMessage} login={login} />)}
                                </Grid2>
                            </Grid2>
                        </>
                    )}
                </Grid2>
            </AccordionDetailsMUI>
        </AccordionMUI>
    )
}

CmsMessages.propTypes = {
    cms: PropTypes.instanceOf(CMSEventDto),
    onChange: PropTypes.func,
}

export default CmsMessages