import mapHydro from 'assets/pictures/markers/map_hydro.png'
import mapInstallations from 'assets/pictures/markers/map_installations.png'
import mapPiezoGreen from 'assets/pictures/markers/map_piezo_green.png'
import mapPiezoOrange from 'assets/pictures/markers/map_piezo_orange.png'
import mapPiezoRed from 'assets/pictures/markers/map_piezo_red.png'
import mapPiezoWhite from 'assets/pictures/markers/map_piezo_white.png'
import mapPluvio from 'assets/pictures/markers/map_pluvio.png'
import mapQualite from 'assets/pictures/markers/map_qualite.png'
import laboPicture from 'assets/pictures/pictos/labo_40px.png'
import { uniqBy } from 'lodash'
import moment from 'moment'
import React from 'react'
import i18n from 'simple-react-i18n'
import ProgressBar from '../../components/progress/ProgressBar'
import { getParametersNature, getParametersType } from '../../referencial/util/ReferencialUtils'
import { getStatusInformation } from '../../referencial/util/StatusUtils'
import { sieauTooltip } from '../../utils/FormUtils'
import { getI18nTitleDataLength, searchAllCharacters } from '../../utils/StringUtil'

const getCampaignIcon = (type, size) => {
    if (type === 1 || type == 'G') {
        return <i className={`material-icons ${size}`} {...sieauTooltip(i18n.preventive)}>directions_run</i>
    }
    if (type === 2 || type == 'M') {
        return <i className={`material-icons ${size}`} {...sieauTooltip(i18n.curative)}>build</i>
    }
    return <i className={`material-icons ${size}`} {...sieauTooltip(i18n.other)}>more_horiz</i>
}

const getPixelSize = (size) => {
    switch (size) {
        case 'tiny': return '1rem'
        case 'small': return '2rem'
        case 'medium': return '4rem'
        case 'large': return '6rem'
        default: return '2rem'
    }
}

const getQualityCampaignIcon = (type, size) => {
    if (type === 2) {
        return <i className={`material-icons ${size}`} {...sieauTooltip(i18n.hydrobio)}>bug_report</i>
    }
    if (type === 3) {
        const sizeFormat = getPixelSize(size)
        return (
            <div>
                <img src={laboPicture} style={{ height: sizeFormat, width: sizeFormat }} {...sieauTooltip(i18n.chemicalPhysics)} />
            </div>
        )
    }
    return <i className={`material-icons ${size}`} {...sieauTooltip(i18n.other)}>more_horiz</i>
}

const getNbStationValidated = (piezometerCampaignStations, idCampaign) => {
    if (piezometerCampaignStations) {
        return piezometerCampaignStations.filter(p => p.campaignId === idCampaign && p.visitValidated === true).length
    }
    return 0
}

const getNbStation = (piezometerCampaignStations, idCampaign) => {
    if (piezometerCampaignStations) {
        return piezometerCampaignStations.filter(p => p.campaignId === idCampaign).length
    }
    return 0
}

const getCampaignProgress = (nbStationValidated, nbStation) => {
    if (nbStation) {
        return (nbStationValidated / nbStation) * 100
    }
    return 0
}

const getContact = (contacts, code) => {
    if (code && contacts) {
        return contacts.find(o => o.code == code)
    }
    return null
}

const getProgressBar = (eventCount, stations, progress, labelSing = i18n.stationOn, labelPlur = i18n.stationsOn) => {
    const tooltip = `${eventCount} ${getI18nTitleDataLength(labelSing, labelPlur, eventCount)} ${stations} (${Math.round(progress)}%)`
    return (
        <div style={{ width: '100px' }}>
            <ProgressBar progress={progress} tooltip={tooltip} />
        </div>
    )
}

const getContactCode = (contactCode, contacts) => {
    const contact = getContact(contacts, contactCode)
    return contact ? contact.code : null
}

const getContactName = (contactCode, contacts) => {
    const contact = getContact(contacts, contactCode)
    return contact ? contact.name : i18n.unknown
}

const formatStationSelectedPopup = (stations, campaignEvents) => {
    const date = +moment()
    const monitoringCampaignsEvents = campaignEvents.filter(event => event.eventType === 'S' && (!event.closeDate || event.closeDate > date))
    const toPlanEvents = campaignEvents.filter(event => event.eventType === 'P' && (!event.closeDate || event.closeDate > date))
    return stations.map(({ id, code = '', name = '', townCode, installationType, visitStatus, idVisit }) => ({
        labelSearch: searchAllCharacters(`${code}${name}`),
        townCode,
        id,
        code,
        name,
        monitoring: monitoringCampaignsEvents.filter(e => e.stationId === id).length,
        toPlanEvents: toPlanEvents.filter(e => e.stationId === id).length,
        installationType,
        visitStatus,
        idVisit,
    }))
}

const formatParameterSelectedPopup = parameters => {
    const types = getParametersType()
    const natures = getParametersNature()
    return parameters.map(({ code, name, shortLabelWithCode, typeParam, nature, status, unitReference1 }) => ({
        labelSearch: searchAllCharacters(`${code}${name}`),
        code,
        id: code,
        name: shortLabelWithCode,
        type: types[typeParam],
        nature: natures[nature],
        status: status ? getStatusInformation(status) : i18n.validated,
        unitCode: unitReference1,
    }))
}

const getMarkerIcon = (stationType, color) => {
    switch (stationType) {
        case 'piezometry': {
            switch (color) {
                case 'green': return mapPiezoGreen
                case 'orange': return mapPiezoOrange
                case 'red': return mapPiezoRed
                default: return mapPiezoWhite
            }
        }
        case 'pluviometry': return mapPluvio
        case 'hydrometry': return mapHydro
        case 'quality': return mapQualite
        case 'installation': return mapInstallations
        default: return undefined
    }
}

const getAnalysisColorFollowing = (analysis, campaignParameters) => {
    const nbAnalysis = uniqBy(analysis, 'parameter').length
    if (nbAnalysis >= campaignParameters.length) {
        return 'green'
    } else if (nbAnalysis < campaignParameters.length && nbAnalysis !== 0) {
        return 'orange'
    } else if (nbAnalysis === 0) {
        return 'red'
    } return 'blue'
}

export {
    getCampaignIcon,
    getQualityCampaignIcon,
    formatStationSelectedPopup,
    formatParameterSelectedPopup,
    getNbStationValidated,
    getContact,
    getNbStation,
    getCampaignProgress,
    getProgressBar,
    getContactCode,
    getContactName,
    getMarkerIcon,
    getAnalysisColorFollowing,
}
