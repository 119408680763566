import HomeAction from 'home/actions/HomeAction'
import { differenceBy, intersectionBy, isUndefined, orderBy, uniqBy } from 'lodash'
import PropTypes from 'prop-types'
import React, { useEffect, useMemo, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import ProgressCard from '../../../../components/card/ProgressCard'
import QualityAction from '../../../../quality/actions/QualityAction'
import { DELETE, INSERT, UPDATE } from '../../../../utils/constants/ActionConstants'
import { getLabel } from '../../../../utils/StoreUtils'
import { SELECTION_FULL } from '../../../constants/AdministrationConstants'
import useBoolean from 'utils/customHook/useBoolean'
import useTitle from 'utils/customHook/useTitle'
import { Grid } from '@mui/material'
import Card from 'components/card/Card'
import { PopinImportSelections, Selection, StepperSelection } from './ComponentsOptionSelection'
import ParameterAction from 'referencial/components/parameter/actions/ParameterAction'
import UnitAction from 'referencial/components/unit/actions/UnitAction'
import useActions from 'utils/customHook/useActions'
import ConfirmModal from 'components/modal/ConfirmModal'
import WaitAction from 'wait/WaitAction'
import TaxonAction from 'referencial/components/taxon/actions/TaxonAction'
import UserAction from 'administration/components/user/actions/UserAction'

const ListSelections = ({
    selections = [],
    setSelections = () => { },
    qualitySelections = [],
    setQualitySelections = () => { },
    isEditMode = false,
}) => {
    const {
        value: isOpen,
        setTrue: open,
        setFalse: close,
    } = useBoolean(false)
    const {
        value: isConfirmOpen,
        setTrue: openConfirm,
        setFalse: closeConfirm,
    } = useBoolean(false)

    const [selectedCode, setSelectedCode] = useState()

    const selectionsFormatted = useMemo(() => {
        const parentsSelections = selections.filter(selection => isUndefined(selection.parentCode))
        const formatSelection = selection => {
            const qualitySelection = qualitySelections.find(qs => qs.code === selection.code)
            const childrens = selections.filter(child => child.parentCode === selection.code)
            return {
                selection,
                childrens: childrens.map(formatSelection),
                qualitySelection,
            }
        }
        return parentsSelections.map(formatSelection)
    }, [qualitySelections, selections])

    const selectedSelection = useMemo(() => {
        return selections.find(s => s.code === selectedCode)
    }, [selectedCode, selections])

    const selectedQualitySelection = useMemo(() => {
        return qualitySelections.find(qs => qs.code === selectedCode)
    }, [qualitySelections, selectedCode])

    return (
        <>
            {
                selectionsFormatted.map(({ selection, childrens, qualitySelection }) => {
                    return (
                        <Selection
                            selection={selection}
                            qualitySelection={qualitySelection}
                            childrenSelections={childrens}
                            isEditMode={isEditMode}

                            onUpdate={code => {
                                setSelectedCode(code)
                                open()
                            }}
                            onDelete={code => {
                                setSelectedCode(code)
                                openConfirm()
                            }}
                            onDeleteParameter={({ code, selectionCode }) => {
                                setSelections(prev => prev.map(s => s.code === selectionCode ? { ...s, isUpdated: true } : s))
                                setQualitySelections(prev => prev.map(qs => {
                                    if (qs.code === selectionCode) {
                                        return {
                                            ...qs,
                                            selections: qs.selections.filter(t => t.parameterCode !== code),
                                        }
                                    }
                                    return qs
                                }))
                            }}

                            key={selection.code}
                        />
                    )
                })
            }
            <StepperSelection
                isOpen={isOpen}
                onClose={close}
                onSave={(selection, qualitySelection) => {
                    setSelections(prev => prev.map(s => s.code === selection.code ? { ...selection, isUpdated: true } : s))
                    setQualitySelections(prev => prev.map(qs => qs.code === selection.code ? qualitySelection : qs))
                    close()
                }}

                selectedSelection={selectedSelection}
                selectedQualitySelection={selectedQualitySelection}
            />
            <ConfirmModal
                isOpen={isConfirmOpen}
                title={i18n.deletingConfirmation}
                onValidate={() => {
                    setQualitySelections(prev => prev.filter(qs => qs.code !== selectedCode))
                    setSelections(prev => prev.filter(s => s.code !== selectedCode))
                    closeConfirm()
                }}
                onClose={closeConfirm}
            />
        </>
    )
}

ListSelections.propTypes = {
    selections: PropTypes.arrayOf(PropTypes.shape({
        code: PropTypes.string,
        name: PropTypes.string,
        parentCode: PropTypes.string,
        listType: PropTypes.number,
    })),
    setSelections: PropTypes.func,
    qualitySelections: PropTypes.arrayOf(PropTypes.shape({
        code: PropTypes.string,
        selections: PropTypes.arrayOf(PropTypes.shape({
            parameterCode: PropTypes.string,
        })),
    })),
    setQualitySelections: PropTypes.func,
    isEditMode: PropTypes.bool,
}

const QualitySelectionApp = ({

}) => {
    const dispatch = useDispatch()
    const {
        selectionsProps,
        qualitySelectionsProps,
        parameters,
    } = useSelector(store => ({
        selectionsProps: store.ParameterReducer.selections,
        qualitySelectionsProps: store.QualityReducer.qualitySelections,
        parameters: store.ParameterReducer.parameters,
    }), shallowEqual)

    useTitle(() => [{
        title: i18n.quality,
        href: '/quality',
    }, {
        title: i18n.qualityOptions,
        href: '/quality/qualityOptions',
    }, {
        title: i18n.selections,
        href: '/quality/qualityOptions/selection',
    }], [])

    const {
        value: isEditMode,
        setTrue: editMode,
        setFalse: readMode,
    } = useBoolean(false)
    const {
        value: isOpen,
        setTrue: open,
        setFalse: close,
    } = useBoolean(false)
    const {
        value: isImportOpen,
        setTrue: openImport,
        setFalse: closeImport,
    } = useBoolean(false)

    const [selections, setSelections] = useState([])
    const [qualitySelections, setQualitySelections] = useState([])

    const [progress, setProgress] = useState(0)
    const {
        value: isLoaded,
        setTrue: loaded,
    } = useBoolean(false)

    useEffect(() => {
        dispatch(HomeAction.setHelpLink('qualite', '60'))
        dispatch(ParameterAction.fetchParameters())
        dispatch(TaxonAction.fetchTaxons())
        dispatch(UnitAction.fetchUnits())
        dispatch(QualityAction.fetchAllSelections(setProgress)).then(result => {
            setSelections(result.selections)
            setQualitySelections(result.qualitySelections)
            loaded()
        })
        dispatch(UserAction.fetchApplicativeUsers())
    }, [])

    const orderedSelections = useMemo(() => {
        return orderBy(selections, 'name')
    }, [selections])

    const onExport = () => {
        const data = orderedSelections.flatMap(selection => {
            const qualitySelection = qualitySelections.find(qs => qs.code === selection.code)
            if (isUndefined(qualitySelection)) {
                return [{
                    code: selection.code,
                    name: selection.name,
                    parent: getLabel(selections, selection.parentCode),
                }]
            }
            return qualitySelection.selections.map(t => ({
                code: selection.code,
                name: selection.name,
                parent: getLabel(selections, selection.parentCode),
                parameter: getLabel(parameters, t.parameterCode, 'labelWithCode'),
            }))
        })
        return {
            data: data.length ? [
                { ...data[0], headers: ['code', 'name', 'parent', 'parameter'] },
                ...data.slice(1),
            ] : data,
            exportType: 'xlsx',
            titleFile: i18n.selections,
        }
    }

    useActions(() => {
        if (isEditMode) {
            return {
                new: open,
                cancel: () => {
                    readMode()
                    setSelections(selectionsProps)
                    setQualitySelections(qualitySelectionsProps)
                },
                import: openImport,
                save: () => {
                    dispatch(WaitAction.waitStart())
                    const selectionsCreated = differenceBy(selections, selectionsProps, 'code').map(selection => {
                        return {
                            dataType: SELECTION_FULL,
                            updateType: INSERT,
                            selectionFullInput: {
                                selection,
                                parameterSelections: qualitySelections.find(qs => qs.code === selection.code)?.selections.map(s => ({ ...s, code: selection.code })),
                            },
                        }
                    })
                    const selectionsDeleted = differenceBy(selectionsProps, selections, 'code').map(selection => {
                        return {
                            dataType: SELECTION_FULL,
                            updateType: DELETE,
                            selectionFullInput: {
                                selection,
                                parameterSelections: [],
                            },
                        }
                    })
                    const selectionsUpdated = intersectionBy(selections, selectionsProps, 'code').filter(s => s.isUpdated).map(selection => {
                        return {
                            dataType: SELECTION_FULL,
                            updateType: UPDATE,
                            selectionFullInput: {
                                selection,
                                parameterSelections: qualitySelections.find(qs => qs.code === selection.code)?.selections.map(s => ({ ...s, code: selection.code })),
                            },
                        }
                    })

                    const updateActions = [...selectionsCreated, ...selectionsDeleted, ...selectionsUpdated]
                    dispatch(QualityAction.updateSelections(updateActions))
                        .then((res = { update: 0 }) => {
                            readMode()
                            if (res.update === 0) {
                                dispatch(WaitAction.waitStop())
                                return
                            }
                            dispatch(QualityAction.fetchAllSelections())
                                .then(result => {
                                    setSelections(result.selections)
                                    setQualitySelections(result.qualitySelections)
                                })
                                .finally(() => dispatch(WaitAction.waitStop()))
                        })
                },
            }
        }
        return {
            edit: editMode,
            export: onExport,
        }
    }, [isEditMode, selections, orderedSelections, qualitySelections, parameters])

    return (
        <div style={{ maxWidth: '1200px', marginLeft: 'auto', marginRight: 'auto', paddingBottom: '100px' }}>
            {
                !isLoaded && (
                    <ProgressCard progress={progress} />
                )
            }
            {
                isLoaded && (
                    <Grid container spacing={1} sx={{ paddingTop: '10px' }}>
                        {
                            !selections.length && (
                                <Grid item xs={12}>
                                    <Card><h5 className='padding-1'>{i18n.noThresholds}</h5></Card>
                                </Grid>
                            )
                        }
                        <ListSelections
                            selections={orderedSelections}
                            setSelections={setSelections}
                            qualitySelections={qualitySelections}
                            setQualitySelections={setQualitySelections}
                            isEditMode={isEditMode}
                        />
                    </Grid>
                )
            }
            <StepperSelection
                isOpen={isOpen}
                onClose={close}
                onSave={(selection, qualitySelection) => {
                    setSelections(prev => [...prev, selection])
                    setQualitySelections(prev => [...prev, qualitySelection])
                    close()
                }}
                selectedSelection={{ listType: 0, users: [], administrators: [] }}
                selectedQualitySelection={{ selections: [] }}
            />
            <PopinImportSelections
                isOpen={isImportOpen}
                close={closeImport}
                onValidate={(newSelections, newQualitySelections) => {
                    setSelections(prev => uniqBy([...newSelections, ...prev], 'code'))
                    setQualitySelections(prev => uniqBy([...newQualitySelections, ...prev], 'code'))
                    closeImport()
                }}
            />
        </div>
    )
}

export default QualitySelectionApp
