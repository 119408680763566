import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { hasValue } from 'utils/NumberUtil'
import CatchmentThunk from 'catchment/actions/CatchmentThunk'
import CatchmentAction from 'catchment/actions/CatchmentAction'
import { execByType } from 'utils/StationUtils'
import QualityAction from 'quality/actions/QualityAction'
import InstallationAction from 'installation/actions/InstallationAction'
import PiezometryAction from 'piezometry/actions/PiezometryAction'
import SuperMultiAutocomplete from '../SuperMultiAutocomplete'

const AssociatedCatchmentSelect = ({
    col,
    stationTypeReturn,
    value,
    onChange = () => { },
    disabled = false,
}) => {
    const dispatch = useDispatch()

    const {
        catchments,
        qualitometers,
        installations,
        piezometers,
    } = useSelector(store => ({
        catchments: store.CatchmentReducer.catchments,
        qualitometers: store.QualityReducer.qualitometersLight,
        installations: store.InstallationReducer.installationsLight,
        piezometers: store.PiezometryReducer.piezometersLight,
    }), shallowEqual)

    const [station, setStation] = useState(value)
    const [associatedStation, setAssociatedStation] = useState([])

    useEffect(() => {
        dispatch(CatchmentThunk.fetchCatchments())
        if (!qualitometers.length) {
            dispatch(QualityAction.fetchQualitometersLight())
        }
        if (!installations.length) {
            dispatch(InstallationAction.fetchInstallationsLight())
        }
        if (!piezometers.length) {
            dispatch(PiezometryAction.fetchPiezometersLight())
        }
    }, [])

    useEffect(() => {
        if (station) {
            dispatch(CatchmentAction.getCatchment(station)).then(c => {
                const stations = execByType(stationTypeReturn, {
                    quality: () => qualitometers,
                    installation: () => installations,
                    piezometry: () => piezometers,
                    default: () => [],
                })
                const ids = c.catchmentPoints.map(p => stations.find(s => s.code === p.code)?.id).filter(s => hasValue(s))
                setAssociatedStation(ids)
            })
        } else {
            setAssociatedStation([])
        }
    }, [dispatch, installations, piezometers, qualitometers, station, stationTypeReturn])

    useEffect(() => {
        onChange(associatedStation, station)
    }, [associatedStation])

    const fullLabel = useMemo(() => {
        if (!hasValue(station)) {
            return i18n.catchment
        }
        if (associatedStation.length <= 1) {
            return `${i18n.catchment} (${associatedStation.length} ${i18n.station})`
        }
        return `${i18n.catchment} (${associatedStation.length} ${i18n.stations})`
    }, [associatedStation.length, station])

    return (
        <SuperMultiAutocomplete
            col={col}
            options={catchments}
            values={station}
            label={fullLabel}
            keyLabel={'name'}
            keyValue={'id'}
            onChange={setStation}
            disabled={disabled}
            displayWithCode
        />
    )
}

AssociatedCatchmentSelect.propTypes = {
    col: PropTypes.number,
    stationTypeReturn: PropTypes.number,
    value: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
    ]),
    disabled: PropTypes.bool,
    onChange: PropTypes.func, // (list, id) => {}
}

export default AssociatedCatchmentSelect