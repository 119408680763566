import GlobalParametersDto from 'administration/dto/GlobalParameterDto'
import PropTypes from 'prop-types'
import AppStore from 'store/AppStore'
import DtoPublicPicture from '../../station/dto/DtoPublicPicture'
import { arrayOf } from '../../utils/StoreUtils'
import AdministrationAction from '../actions/AdministrationAction'
import {
    ADD_SAVED_RESEARCH_CRITERIAS, RECEIVE_ADMIN_THEMES, RECEIVE_ADMIN_THEME_LAYERS, RECEIVE_ALL_MODELS_BY_TYPE,
    RECEIVE_APPLICATION_SETTINGS,
    RECEIVE_CARTOGRAPHY_THEMES,
    RECEIVE_GLOBAL_PARAMETERS,
    RECEIVE_LOGIN_PICTURE,
    RECEIVE_MENU_PICTURE,
    RECEIVE_PICTOS,
    RECEIVE_PROFILE_LOGIN_PICTURE,
    RECEIVE_PROFILE_PICTURE,
    RECEIVE_SAVED_RESEARCH_CRITERIAS,
    RECEIVE_SELECTED_SEARCH_VALUES,
    RECEIVE_SETTINGS,
    RECEIVE_THEME,
    RELOAD_SELECTED_SEARCH_VALUES, RESET_ADMINISTRATION,
    RESET_MAP_CHECBOXES,
    RESET_PROFILE_LOGIN_PICTURE,
    RESET_PROFILE_PICTURE,
    RESET_THEME,
    RECEIVE_USER_SETTINGS,
    RECEIVE_APPLICATIVE_USER_SETTINGS,
    RESET_USER_SETTINGS,
    RECEIVE_SETTING,
    NAME_MENU_PICTURE,
    NAME_LOGIN_PICTURE,
} from '../constants/AdministrationConstants'
import CartographyThemeDto from '../dto/CartographyThemeDto'
import DtoSavedResearchCriterias from '../dto/DtoSavedResearchCriterias'
import LayerThemeDto from '../dto/LayerThemeDto'
import PictoDto from '../dto/PictoDto'
import SettingDto from '../dto/SettingDto'
import SieauParameterDto from '../dto/SieauParameterDto'
import ThemeDto from '../dto/ThemeDto'

export function AdministrationReducer(state = {}, action) {
    switch (action.type) {
        case RECEIVE_SAVED_RESEARCH_CRITERIAS:
            return {
                ...state,
                savedResearchCriterias: action.criterias.map(obj => new DtoSavedResearchCriterias(obj)),
            }
        case ADD_SAVED_RESEARCH_CRITERIAS:
            return {
                ...state,
                savedResearchCriterias: state.savedResearchCriterias.concat([new DtoSavedResearchCriterias(action.criteria)]),
            }
        case RECEIVE_SETTINGS:
            return {
                ...state,
                settings: action.settings.map(setting => new SettingDto(setting)),
            }
        case RECEIVE_SETTING:
            return {
                ...state,
                setting: new SettingDto(action.setting),
            }
        case RECEIVE_USER_SETTINGS:
            return {
                ...state,
                userSettings: action.payload.map(userSetting => new SettingDto(userSetting)),
            }
        case RESET_USER_SETTINGS:
            return {
                ...state,
                userSettings: [],
            }
        case RECEIVE_APPLICATIVE_USER_SETTINGS:
            return {
                ...state,
                applicativeUserSettings: action.payload.map(applicativeUserSetting => new SettingDto(applicativeUserSetting)),
            }
        case RECEIVE_CARTOGRAPHY_THEMES:
            return {
                ...state,
                cartographyThemes: action.themes.map(theme => new CartographyThemeDto(theme)),
            }
        case RECEIVE_ADMIN_THEMES:
            return {
                ...state,
                adminThemes: action.themes.map(theme => new CartographyThemeDto(theme)),
            }
        case RECEIVE_THEME:
            return {
                ...state,
                theme: new ThemeDto(action.theme),
            }
        case RESET_THEME:
            return {
                ...state,
                theme: new ThemeDto(),
                layers: [],
            }
        case RECEIVE_ADMIN_THEME_LAYERS:
            return {
                ...state,
                layers: action.layers.map(l => new LayerThemeDto(l)),
            }
        case RECEIVE_PICTOS:
            return {
                ...state,
                pictos: action.pictos.map(picto => new PictoDto(picto)),
            }
        case RECEIVE_SELECTED_SEARCH_VALUES:
            const allValues = state.selectedSearchValues
            const searchValues = Object.assign({}, allValues[action.stationType] || {}, action.object)
            const stationTypeObj = {
                [action.stationType]: searchValues,
            }
            const values = { ...allValues, ...stationTypeObj }
            return {
                ...state,
                selectedSearchValues: values,
            }
        case RELOAD_SELECTED_SEARCH_VALUES:
            return {
                ...state,
                selectedSearchValues: {},
            }
        case RESET_MAP_CHECBOXES:
            return {
                ...state,
                selectedSearchValues: { ...state.selectedSearchValues, mapCheckboxes: {} },
            }
        case RESET_ADMINISTRATION:
            return {
                ...state,
                settings: [],
                savedResearchCriterias: [],
            }
        case RECEIVE_APPLICATION_SETTINGS:
            return {
                ...state,
                applicationSettings: action.settings.map(s => new SieauParameterDto(s)),
            }
        case RECEIVE_MENU_PICTURE:
            return {
                ...state,
                menuPicture: new DtoPublicPicture({ ...action.menuPicture, stationType: NAME_MENU_PICTURE }),
            }
        case RECEIVE_LOGIN_PICTURE:
            return {
                ...state,
                loginPicture: new DtoPublicPicture({ ...action.loginPicture, stationType: NAME_LOGIN_PICTURE }),
            }
        case RECEIVE_ALL_MODELS_BY_TYPE:
            return {
                ...state,
                allModelsByType: action.allModelsByType.map(model => new DtoPublicPicture(model)),
            }
        case RECEIVE_PROFILE_PICTURE:
            return {
                ...state,
                profilePicture: new DtoPublicPicture(action.profilePicture),
            }
        case RECEIVE_PROFILE_LOGIN_PICTURE:
            return {
                ...state,
                profileLoginPicture: new DtoPublicPicture(action.profileLoginPicture),
            }
        case RESET_PROFILE_PICTURE:
            return {
                ...state,
                profilePicture: {},
            }
        case RESET_PROFILE_LOGIN_PICTURE:
            return {
                ...state,
                profileLoginPicture: new DtoPublicPicture({}),
            }
        case RECEIVE_GLOBAL_PARAMETERS:
            return {
                ...state,
                globalParameters: action.data.map(p => new GlobalParametersDto(p)),
            }
        default:
            return state
    }
}

export const store = () => ({
    savedResearchCriterias: [],
    selectedSearchValues: {},
    settings: [],
    userSettings: [],
    applicativeUserSettings: [],
    cartographyThemes: [],
    adminThemes: [],
    theme: new ThemeDto(),
    layers: [],
    pictos: [],
    applicationSettings: [],
    menuPicture: new DtoPublicPicture({}),
    loginPicture: {},
    modelsFiles: [],
    allModelsByType: [],
    profilePicture: {},
    profileLoginPicture: new DtoPublicPicture({}),
    globalParameters: [],
    setting: {},
})

export const AdministrationStoreInfos = () => {
    const appStore = AppStore.getState()
    return {
        savedResearchCriterias: {
            fetch: null,
            store: appStore.AdministrationReducer.savedResearchCriterias,
            propType: arrayOf(DtoSavedResearchCriterias),
            update: AdministrationAction.addSavedResearchCriterias,
            delete: AdministrationAction.deleteSavedResearchCriterias,
        },
        cartographyThemes: {
            fetch: AdministrationAction.fetchCartographyThemes,
            store: appStore.AdministrationReducer.cartographyThemes,
            propType: arrayOf(CartographyThemeDto),
            update: AdministrationAction.updateCartographyTheme,
        },
        selectedSearchValues: {
            update: AdministrationAction.setSelectedSearchValues,
            store: appStore.AdministrationReducer.selectedSearchValues,
            propType: PropTypes.shape({
                quality: PropTypes.shape({
                    searchValue: PropTypes.string,
                    filter: PropTypes.number,
                }),
                piezometry: PropTypes.shape({
                    searchValue: PropTypes.string,
                    filter: PropTypes.number,
                }),
            }),
        },
        setting: {
            update: AdministrationAction.updateSetting,
        },
        settings: {
            fetch: AdministrationAction.fetchSettings,
            update: AdministrationAction.updateSettings,
            store: appStore.AdministrationReducer.settings,
            propType: arrayOf(SettingDto),
        },
        userSettings: {
            fetch: AdministrationAction.fetchUserSettings,
            store: AdministrationReducer.userSettings,
            propType: arrayOf(SettingDto),
        },
        pictos: {
            fetch: AdministrationAction.fetchPictos,
            store: appStore.AdministrationReducer.pictos,
            propType: arrayOf(PictoDto),
        },
        administration: {
            reset: AdministrationAction.reset,
        },
        applicationSettings: {
            fetch: AdministrationAction.fetchApplicationSettings,
            store: appStore.AdministrationReducer.applicationSettings,
            propType: PropTypes.arrayOf(SieauParameterDto),
        },
    }
}
