import { Button, Grid, Step, StepLabel, Stepper } from '@mui/material'
import { makeStyles } from '@mui/styles'
import Icon from 'components/icon/Icon'
import { DialogActionsMUI, DialogContentMUI, DialogMUI, DialogTitleMUI } from 'components/styled/Dialog'
import { StyledFieldSet } from 'components/StyledElements'
import { push } from '@lagunovsky/redux-react-router'
import CmsAction from 'events/actions/CmsAction'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import i18n from 'simple-react-i18n'
import useTitle from 'utils/customHook/useTitle'
import { getExportAction } from 'utils/VisitUtils'
import { H_CAMPAIGN_INSTALLATION_VALIDATION } from '../../../account/constants/AccessRulesConstants'
import CampaignAction from '../../../campaign/actions/CampaignAction'
import DtoCampaignVisit from '../../../campaign/dto/DtoCampaignVisit'
import ProgressCard from '../../../components/card/ProgressCard'
import Table from '../../../components/datatable/Table'
import Row from '../../../components/react/Row'
import ReferencialAction from '../../../referencial/action/ReferencialAction'
import { nbPerPageLabel, SANDRE } from '../../../referencial/constants/ReferencialConstants'
import { STEP } from '../../../station/constants/CampaignConstants'
import { isInstallationType } from '../../../utils/CampaignUtil'
import { getDate } from '../../../utils/DateUtil'
import { downloadURI, exportFile } from '../../../utils/ExportDataUtil'
import { setModal } from '../../../utils/FormUtils'
import { componentHasHabilitations } from '../../../utils/HabilitationUtil'
import { hasValue } from '../../../utils/NumberUtil'
import { arrayOf, getLabel } from '../../../utils/StoreUtils'
import { getSandreLabel } from '../../../utils/StringUtil'
import InstallationAction from '../../actions/InstallationAction'
import InstVisitInfos from './validation/InstVisitInfos'
import InstVisitModifications from './validation/InstVisitModifications'
import InstVisitActions from './validation/InstVisitActions'
import InstVisitConclusion from './validation/InstVisitConclusion'
import InstVisitDanger from './validation/InstVisitDanger'
import InstVisitDiagnostic from './validation/InstVisitDiagnostic'

const headers = ['nullvalue2', 'installationType', 'code', 'city', 'name', 'contact', 'mainTechnician', 'visitDate', 'nbActions', 'status', 'nullvalue3', 'nullvalue']

const useStyles = makeStyles(() => ({
    root: {
        fontSize: '15px',
        fontWeight: 'bold',
    },
    label: {
        '&.MuiStepLabel-alternativeLabel': {
            marginTop: '3px !important',
        },
    },
}))

const InstallationCampaignValidationApp = () => {
    const dispatch = useDispatch()
    const { id } = useParams()
    const classes = useStyles()

    const [dataLoaded, setDataLoaded] = useState(false)
    const [progress, setProgress] = useState(0)
    const [open, setOpen] = useState(false)
    const [step, setStep] = useState(STEP.INFORMATIONS)

    const {
        campaign,
        cities,
        contacts,
        contributors,
        sandreCodes,
        visit,
        installation,
        installationsTypes,
        campaignVisits,
    } = useSelector(store => ({
        campaign: store.CampaignReducer.campaign,
        cities: store.CityReducer.cities,
        contacts: store.ContactReducer.contacts,
        contributors: store.ContributorReducer.contributors,
        sandreCodes: store.ReferencialReducer.sandreCodes,
        visit: store.InstallationReducer.visit,
        installation: store.InstallationReducer.installation,
        installationsTypes: store.InstallationReducer.installationsTypes,
        campaignVisits: store.CampaignReducer.campaignVisits,
    }), shallowEqual)

    useEffect(() => {
        if (!componentHasHabilitations(H_CAMPAIGN_INSTALLATION_VALIDATION)) { // A modifier quand react-router sera à jour
            dispatch(push('/unauthorized'))
            return
        }

        if (!contacts.length || !contributors.length) {
            dispatch(CampaignAction.loadCampaignDashboard(p => setProgress(p))).then(() => setDataLoaded(true))
        } else {
            setDataLoaded(true)
        }

        if (!sandreCodes.length) {
            dispatch(ReferencialAction.fetchSandreCodes())
        }

        if (!installationsTypes.length) {
            dispatch(InstallationAction.fetchInstallationTypes())
        }

        dispatch(CampaignAction.fetchCampaignVisits(id))
        $('.material-tooltip').remove()
    }, [])

    useTitle(() => {
        return [
            {
                title: i18n.installation,
                href: '/installation',
            },
            {
                title: i18n.campaigns,
                href: '/installation/campaign',
            },
            {
                title: campaign.name || id,
                href: `/installation/campaign/${id}/dashboard`,
            },
            {
                title: i18n.validation,
                href: `/installation/campaign/${id}/validation`,
            },
        ]
    }, [campaign])

    const onNextStep = () => {
        if (step === STEP.INFORMATIONS && !hasValue(visit.idSurvey)) {
            setStep(STEP.MODIFICATIONS)
        } else {
            setStep(step + 1)
        }
    }

    const onPrevStep = () => {
        if (step === STEP.MODIFICATIONS && !hasValue(visit.idSurvey)) {
            setStep(STEP.INFORMATIONS)
        } else {
            setStep(step - 1)
        }
    }

    const getDialogContent = () => {
        const equipmentType = isInstallationType(installation.installationType)
        const code = installation.code ? `[${installation.code}]` : ''
        const name = installation.name ? `${installation.name}` : ''
        const type = installationsTypes.find(i => i.id === installation.installationType)?.name
        const campaignType = campaign?.campaignType
            ? getSandreLabel(sandreCodes, SANDRE.CAMPAGNES_TYPE, campaign?.campaignType)
            : i18n.notDefined

        const actions = [{
            icon: 'edit',
            tooltip: i18n.modifVisit,
            onClick: () => dispatch(push(`/installation/campaign/${visit.idCampaign}/visit/${visit.idInstallation}/${step}`)),
        }, step === STEP.ACTIONS && {
            icon: 'file_download',
            tooltip: i18n.export,
            onClick: () => {
                setOpen(false)
                exportFile({
                    data: [
                        {
                            ...getExportAction(sandreCodes, equipmentType, visit.link_actions[0]),
                            headers: ['risk', 'localisation', 'equipmentType', 'responsible', 'category', 'deadline', 'interventionType', 'comment', 'correctionDate', 'commentCorrection'],
                        },
                        ...visit.link_actions.slice(1).map(d => getExportAction(sandreCodes, equipmentType, d)),
                    ],
                    titleFile: `${i18n.actions}_${installation.name || installation.code}`,
                })
            },
        }].filter(d => !!d)

        return (
            <>
                <StyledFieldSet style={{ position: 'sticky', top: '10', zIndex: '1' }}>
                    <Grid container className='padding-left-1' style={{ fontSize: '14.3px' }}>
                        <Grid item xs={6}>
                            <Grid>
                                {`${code} ${name} - ${type} `}
                            </Grid>
                            <Grid>
                                {`${i18n.campaignType}: ${campaignType || i18n.notDefined}`}
                            </Grid>
                        </Grid>
                        <Grid container item xs={6} justifyContent='flex-end' alignItems='center'>
                            {actions.map(action => <Icon {...action} />)}
                        </Grid>
                    </Grid>
                </StyledFieldSet>
                {step === STEP.INFORMATIONS && (
                    <StyledFieldSet>
                        <InstVisitInfos visit={visit} cmsSurveyId={visit.idSurvey} />
                    </StyledFieldSet>
                )}
                {step === STEP.DIAGNOSTIC && (
                    <StyledFieldSet>
                        <InstVisitDiagnostic visit={visit} />
                    </StyledFieldSet>
                )}
                {step === STEP.DANGERS && (
                    <StyledFieldSet>
                        <InstVisitDanger visit={visit} />
                    </StyledFieldSet>
                )}
                {step === STEP.MODIFICATIONS && (
                    <StyledFieldSet>
                        <InstVisitModifications visit={visit} />
                    </StyledFieldSet>
                )}
                {step === STEP.ACTIONS && <InstVisitActions visit={visit} />}
                {step === STEP.CONCLUSION && (
                    <StyledFieldSet>
                        <InstVisitConclusion
                            visit={visit}
                            launchSave={() => {
                                dispatch(InstallationAction.updateVisit(visit.idVisit, { ...visit, statusVisit: 2 })).then(() => dispatch(CampaignAction.fetchCampaignVisits(visit.idCampaign)))
                            }}
                        />
                    </StyledFieldSet>
                )}
            </>
        )
    }

    const getActiveStep = () => {
        if (step === STEP.INFORMATIONS) {
            return 0
        }
        return hasValue(visit.idSurvey) ? step - 1 : step - 3
    }

    const createAndDownloadEdition = (campaignCode, idInstallation) => {
        dispatch(InstallationAction.getEditionInstallation(campaignCode, idInstallation)).then(json => {
            downloadURI(json.targetPath)
        })
    }

    useEffect(() => {
        if (visit.idSurvey && installation.id) {
            dispatch(CmsAction.fetchCMSSurveyRestricted(visit.idSurvey, installation.id))
        }
    }, [visit.idSurvey, installation.id])

    const deleteVisit = (idCampaign, idInstallation) => {
        setModal({
            title: i18n.delete,
            content: (
                <div>
                    <Row>
                        <h5>{ i18n.sureDeleteVisit }</h5>
                    </Row>
                    <Row>
                        { i18n.chooseDeleteVisit }
                    </Row>
                    <Row>
                        <button
                            className='waves-effect waves-teal btn right modal-close'
                            onClick={() => {
                                dispatch(CampaignAction.deleteVisit(idCampaign, idInstallation)).then(() => dispatch(CampaignAction.fetchCampaignVisits(idCampaign)))
                            }}
                        >{i18n.delete}</button>
                        <button className='waves-effect waves-teal btn right modal-close red'>{ i18n.cancel }</button>
                    </Row>
                </div>
            ),
        })
    }

    const getDataWithOnclick = (displayValue, campaignVisit) => {
        return {
            value: displayValue,
            onClick: () => {
                if (campaignVisit.visitStatus === 2) {
                    dispatch(push(`/installation/campaign/${id}/visit/summary/${campaignVisit.idInstallation}`))
                } else {
                    dispatch(InstallationAction.fetchInstallation(campaignVisit.idInstallation)).then(() => {
                        dispatch(InstallationAction.fetchVisit(campaignVisit.idInstallation, id)).then(() => {
                            setOpen(true)
                        })
                    })
                }
            },

        }
    }

    if (dataLoaded) {
        const stations = campaignVisits.map(cv => {
            const contact = contacts.find(c => c.id === cv.ownerCode) || {}
            return {
                cv,
                installationType: getDataWithOnclick((installationsTypes.find(i => i.id === cv.installationType) || {}).name, cv),
                code: getDataWithOnclick(cv.code, cv),
                city: getDataWithOnclick(cv.townCode ? `${getLabel(cities, cv.townCode)} [${cv.townCode}]` : null, cv),
                name: getDataWithOnclick(cv.name, cv),
                contact: getDataWithOnclick(contact.name, cv),
                mainTechnician: getDataWithOnclick(cv.technicianLogin, cv),
                visitDate: getDataWithOnclick(getDate(cv.visitDate), cv),
                status: getDataWithOnclick(
                    getSandreLabel(
                        sandreCodes,
                        SANDRE.VISITES_STATUT,
                        hasValue(cv.visitStatus) ? cv.visitStatus : -1,
                    ), cv,
                ),
                nbActions: getDataWithOnclick(cv.nbActions, cv),
                nullvalue: { value: (
                    <i
                        className='material-icons tooltipped clickable'
                        onClick={() => createAndDownloadEdition(campaign.id, cv.idInstallation)}
                        data-tooltip={ i18n.export }
                    >
                        file_download
                    </i>
                ) },
                nullvalue2: { value: cv.visitStatus !== 2 ? (
                    <i
                        className='material-icons tooltipped clickable'
                        onClick={() => deleteVisit(campaign.id, cv.idInstallation)}
                        data-tooltip={ i18n.delete }
                    >
                        close
                    </i>
                ) : null },
                nullvalue3: cv.nbActions > 0 ? {
                    value: (
                        <i
                            className='material-icons tooltipped clickable'
                            onClick={() => dispatch(push(`/installation/campaign/${campaign.id}/visit/${cv.idInstallation}/operatorFeedback`))}
                            data-tooltip={i18n.operatorFeedback}
                        >
                            edit
                        </i>
                    ),
                } : null,
            }
        })

        return (
            <Grid className='padding-1 padding-left-2'>
                <Table
                    showTitle={false}
                    data={stations}
                    type={{ headers }}
                    customHeaders={{ nullvalue: ' ', nullvalue2: ' ', nullvalue3: ' ' }}
                    sortable
                    condensed
                    paging
                    color
                    nbPerPageLabel={nbPerPageLabel}
                />
                <DialogMUI open={open}>
                    <DialogTitleMUI style={{ margin: '0', padding: '0' }}>
                        <Grid container justifyContent='space-between' alignItems='center' style={{ padding: '10 20' }}>
                            <Grid item >
                                {i18n.visit}
                            </Grid>
                            <Grid item>
                                <Icon style={{ color: 'white' }} size='small' icon={'close'} onClick={() => setOpen(false)} />
                            </Grid>
                        </Grid>
                        <Grid container justifyContent='center' style={{ backgroundColor: 'white', paddingTop: '5px' }}>
                            <Grid item xs={12}>
                                <Stepper
                                    alternativeLabel
                                    style={{ zoom: '2' }}
                                    activeStep={getActiveStep()}
                                >
                                    <Step
                                        className='clickable'
                                        onClick={() => setStep(STEP.INFORMATIONS)}
                                    >
                                        <StepLabel classes={classes}>{i18n.visit}</StepLabel>
                                    </Step>
                                    {hasValue(visit.idSurvey) && (
                                        <Step
                                            className='clickable'
                                            onClick={() => setStep(STEP.DIAGNOSTIC)}
                                        >
                                            <StepLabel classes={classes}>{i18n.diagnostic}</StepLabel>
                                        </Step>
                                    )}
                                    {hasValue(visit.idSurvey) && (
                                        <Step
                                            className='clickable'
                                            onClick={() => setStep(STEP.DANGERS)}
                                        >
                                            <StepLabel classes={classes}><div style={{ whiteSpace: 'pre-line' }}>{i18n.dangerousSituations}</div></StepLabel>
                                        </Step>
                                    )}
                                    <Step
                                        className='clickable'
                                        onClick={() => setStep(STEP.MODIFICATIONS)}
                                    >
                                        <StepLabel classes={classes}>{i18n.modifications}</StepLabel>
                                    </Step>
                                    <Step
                                        className='clickable'
                                        onClick={() => setStep(STEP.ACTIONS)}
                                    >
                                        <StepLabel classes={classes}>{i18n.actions}</StepLabel>
                                    </Step>
                                    <Step
                                        className='clickable'
                                        onClick={() => setStep(STEP.CONCLUSION)}
                                    >
                                        <StepLabel classes={classes}>{i18n.conclusion}</StepLabel>
                                    </Step>
                                </Stepper>
                            </Grid>
                        </Grid>
                    </DialogTitleMUI>
                    <DialogContentMUI>
                        { getDialogContent() }
                    </DialogContentMUI>
                    <DialogActionsMUI>
                        <Grid container justifyContent='space-around'>
                            <Button onClick={onPrevStep} color='primary' variant='contained' disabled={step === STEP.INFORMATIONS}>
                                {i18n.previous}
                            </Button>
                            <Button onClick={onNextStep} color='primary' variant='contained' disabled={step === STEP.CONCLUSION}>
                                {i18n.next}
                            </Button>
                        </Grid>
                    </DialogActionsMUI>
                </DialogMUI>
            </Grid>
        )
    }
    return <ProgressCard className='padding-1 padding-left-2' progress={progress} />
}

InstallationCampaignValidationApp.propTypes = {
    params: PropTypes.shape({
        id: PropTypes.string,
    }),
    campaignVisits: arrayOf(DtoCampaignVisit),
    push: PropTypes.func,
    loadCampaignDashboard: PropTypes.func,
}

export default InstallationCampaignValidationApp
