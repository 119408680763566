'use strict'
import { RECEIVE_ALL_NETWORKS, RECEIVE_NETWORK, RESET_NETWORK } from '../constants/NetworkConstants'
import NetworkDto from '../dto/NetworkDto'

export function NetworkReducer(state = {}, action) {
    switch (action.type) {
        case RECEIVE_ALL_NETWORKS:
            return {
                ...state,
                networks: action.networks.map(network => new NetworkDto(network)),
            }
        case RECEIVE_NETWORK:
            return {
                ...state,
                network: new NetworkDto(action.network),
            }
        case RESET_NETWORK:
            return {
                ...state,
                network: {},
            }
        default:
            return state
    }
}

export const store = {
    networks: [],
    network: {},
}