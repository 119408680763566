import ReferencialDto from '../../../dto/ReferencialDto'

export default class ContributorGroupDto extends ReferencialDto {
    constructor(object) {
        super(object)
        this.code = object.groupCode
        this.name = object.name
        this.siren = object.siren
        this.numberOfMembers = object.count
        this.contributors = object.contributors
    }
}