import React, { Component } from 'react'
import { connect } from 'react-redux'
import { BLUE, COLOR_TAB, GREEN, ORANGE, RED, YELLOW } from '../constants/ColorConstant'
import { getLabel } from '../../utils/StoreUtils'
import PropTypes from 'prop-types'
import { THRESHOLD_TYPE, getIndiceValue, getThresholdType } from '../../utils/AnalyseUtils'
import { sieauTooltip } from '../../utils/FormUtils'
import DtoThreshold from 'quality/dto/DtoThreshold'
import DtoQualityThreshold from 'quality/dto/QualityThreshold/DtoQualityThreshold'
import { isUndefined } from 'lodash'


class SliderPanel extends Component {
    constructor(props) {
        super(props)
        this.handleClick = this.handleClick.bind(this)
    }

    componentDidUpdate() {
        $('.tooltipped').tooltip({ delay: 50, html: true })
    }

    componentDidMount() {
        $('.tooltipped').tooltip({ delay: 50, html: true })
    }

    getColumn = (color, value) => {
        const className = `center-align ${color}`
        const tooltip = value.label ? sieauTooltip(value.label, null, 'bottom') : null
        return (
            <td className={className} {...tooltip} key={`${color}${value.label}`}>
                {this.props.withThresholdLevels ? value.label : ''}
                <span className='display-hidden'>val</span>
            </td>
        )
    }

    getLine = (values) => {
        switch (values.color) {
            case BLUE:
                return this.getColumn('blue', values)
            case GREEN:
                return this.getColumn('green', values)
            case YELLOW:
                return this.getColumn('yellow', values)
            case ORANGE:
                return this.getColumn('orange', values)
            case RED:
                return this.getColumn('red', values)
            default:
                return null
        }
    }

    getLabel = (thresholdLabel, firstValue, secondValue) => {
        if ((firstValue || firstValue === 0) && (secondValue || secondValue === 0)) {
            return `${thresholdLabel} >= ${firstValue} et < ${secondValue}`
        } else if (secondValue || secondValue === 0) {
            return `${thresholdLabel} >= ${secondValue}`
        }
        return `${thresholdLabel} < ${firstValue}`
    }

    getColorTab = (length) => {
        if (this.props.overrideColor) {
            return this.props.overrideColor
        }
        return COLOR_TAB[length]
    }

    getColor = (thresholdValues) => {
        const {
            threshold,
        } = this.props.data
        const thresholdType = getThresholdType(threshold)
        const thresholdLabel = getLabel(this.props.thresholds, threshold.thresholdType)
        const tabThreshold = thresholdType === THRESHOLD_TYPE.DECREASING ? thresholdValues.slice().reverse() : thresholdValues

        if (thresholdType === THRESHOLD_TYPE.PH || thresholdType === THRESHOLD_TYPE.CONDUCTIVITY) {
            return [
                this.getLine({ color: RED, label: `${thresholdLabel} <= ${tabThreshold[0]}` }),
                this.getLine({ color: BLUE, label: `${thresholdLabel} > ${tabThreshold[0]} et < ${tabThreshold[1]}` }),
                this.getLine({ color: RED, label: `${thresholdLabel} >= ${tabThreshold[1]}` }),
            ]
        }

        const tab = this.getColorTab(thresholdValues.length).slice()
        const colorTab = thresholdType === THRESHOLD_TYPE.DECREASING ? tab.reverse() : tab
        return colorTab.map((color, index) => {
            const label = (() => {
                if (index === 0) {
                    return this.getLabel(thresholdLabel, tabThreshold[0])
                } else if (index === colorTab.length - 1) {
                    return this.getLabel(thresholdLabel, null, tabThreshold[index - 1])
                }
                return this.getLabel(thresholdLabel, tabThreshold[index - 1], tabThreshold[index])
            })()
            return this.getLine({
                color,
                label,
            })
        })
    }


    getData = () => {
        if (!this.props.data.threshold) {
            return null
        }
        // const inverse = isInverseThreshold(this.props.data.threshold)
        const indices = this.props.data.indices ?? getIndiceValue(this.props.data.value, this.props.data.threshold)
        if (isUndefined(indices) || indices.length !== 2) {
            return null
        }
        if (isUndefined(this.props.data.threshold)) {
            return null
        }

        const placement = []
        let i = 1
        for (i; i <= indices[0]; i++) {
            if (i === indices[1]) {
                placement.push(<td className='center-align selected-slider'key={i}>
                    <i className='material-icons' style={{ color: 'black' }}>network_wifi</i>
                </td>)
            } else {
                placement.push(<td className='center-align' key={i}/>)
            }
        }

        const tabThreshold = []
        if (!isUndefined(this.props.data.threshold.threshold1)) {
            tabThreshold.push(this.props.data.threshold.threshold1)
        }
        if (!isUndefined(this.props.data.threshold.threshold2)) {
            tabThreshold.push(this.props.data.threshold.threshold2)
        }
        if (!isUndefined(this.props.data.threshold.threshold3)) {
            tabThreshold.push(this.props.data.threshold.threshold3)
        }
        if (!isUndefined(this.props.data.threshold.threshold4)) {
            tabThreshold.push(this.props.data.threshold.threshold4)
        }
        const thresholdType = getThresholdType(this.props.data.threshold)
        if (thresholdType === THRESHOLD_TYPE.PH || thresholdType === THRESHOLD_TYPE.CONDUCTIVITY) {
            tabThreshold.push(5000000)
        }
        return [placement, this.getColor(tabThreshold)]
    }


    getTab = (data) => {
        if (data && data.length > 1) {
            return (
                <div>
                    <table className='table-slider'>
                        <tbody>
                            <tr>
                                {data[0]}
                            </tr>
                            <tr>
                                {data[1]}
                            </tr>
                        </tbody>
                    </table>
                </div>
            )
        }
        return null
    }

    handleClick() {
        if (this.props.data.threshold) {
            this.props.onOpen(this.props.data.threshold.parameterCode ?? this.props.data.threshold.parameter)
        }
    }

    render() {
        const data = this.getData()
        const clickable = (data && data.length > 1) ? 'clickable' : ''
        return (
            <div className={`row no-margin ${this.props.className}`} style={this.props.style}>
                <div className={`col s12 no-padding ${clickable}`} onClick={this.handleClick}>
                    <div className={`row no-margin ${isUndefined(this.props.data.threshold) ? 'grey' : 'black'} white-text`}>
                        <div className='col s12'>
                            <span className='left'>{this.props.data.title}</span>&nbsp;<span className='right'>{this.props.data.subtitle}</span>
                        </div>
                    </div>
                    {this.getTab(data)}
                    <div className='divider divider-table-slider'/>
                </div>
            </div>
        )
    }
}

SliderPanel.propTypes = {
    data: PropTypes.shape({
        title: PropTypes.string.isRequired,
        value: PropTypes.string,
        subtitle: PropTypes.string,
        indices: PropTypes.arrayOf(PropTypes.number),
        threshold: PropTypes.oneOfType([
            PropTypes.instanceOf(DtoQualityThreshold),
            PropTypes.shape({
                parameterCode: PropTypes.string,
                threshold1: PropTypes.number,
                threshold2: PropTypes.number,
            }),
        ]),
    }),
    withThresholdLevels: PropTypes.bool,
    onOpen: PropTypes.func,
    className: PropTypes.string,
    overrideColor: PropTypes.arrayOf(PropTypes.string),
    thresholds: PropTypes.arrayOf(PropTypes.instanceOf(DtoThreshold)),
    // eslint-disable-next-line react/forbid-prop-types
    style: PropTypes.object,
}

SliderPanel.defaultProps = {
    withThresholdLevels: false,
    onOpen: () => { },
    className: '',
}

const mapStateToProps = store => ({
    thresholds: store.QualityReducer.thresholds,
})

export default connect(mapStateToProps)(SliderPanel)
