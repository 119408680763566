import React from 'react'
import i18n from 'simple-react-i18n'
import Icon from '../../components/icon/Icon'

const getEventTypeBalise = (icon, label) => {
    return <h6 className='valign-wrapper'><Icon size='small' icon={icon} /> {label}</h6>
}

const getEventType = ({ eventType }) => {
    switch (eventType) {
        case 'M':
            return getEventTypeBalise('build', i18n.curative)
        case 'G':
            return getEventTypeBalise('directions_run', i18n.preventive)
        case 'P':
            return getEventTypeBalise('event_note', i18n.toPlan)
        case 'S':
            return getEventTypeBalise('visibility', i18n.toMonitor)
        case 'C':
            return getEventTypeBalise('comment', i18n.comment)
        case 'T':
            return getEventTypeBalise('build', i18n.technique)
        default:
            return getEventTypeBalise('more_horiz', i18n.undefinedIntervention)
    }
}

const getEventTypeInfo = () => {
    return {
        M: { icon: 'build', label: i18n.curative },
        G: { icon: 'directions_run', label: i18n.preventive },
        P: { icon: 'event_note', label: i18n.toPlan },
        S: { icon: 'visibility', label: i18n.toMonitor },
        C: { icon: 'comment', label: i18n.comment },
        T: { icon: 'build', label: i18n.technique },
        // default: { icon: 'more_horiz', label: i18n.undefinedIntervention }
    }
}

export { getEventType, getEventTypeInfo }

