import DtoCMSQuestion from './DtoCMSQuestion'

export default class DtoCMSParagraph {
    constructor(obj) {
        this.idSurvey = obj.idSurvey // Option[Long],
        this.idParagraph = obj.idParagraph // Option[Long],
        this.description = obj.description // Option[String],
        this.order = obj.order // Option[Int],

        this.link_questions = obj.link_questions ? obj.link_questions.map(u => new DtoCMSQuestion(u)) : [] // Option[Seq[CMSQuestionWithLinks]]
    }
}
