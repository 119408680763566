/* eslint-disable camelcase */
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import Table from '../../../components/datatable/Table'
import SieauAction from '../../../components/sieau/SieauAction'
import DtoDistributionUnit from '../../../distributionUnit/dto/DtoDistributionUnit'
import DtoHydrometricStation from '../../../hydrometry/dto/DtoHydrometricStation'
import DtoInstallation from '../../../installation/dto/installation/DtoInstallation'
import DtoPiezometer from '../../../piezometry/dto/DtoPiezometer'
import DtoPiezometerContributorLink from '../../../piezometry/dto/DtoPiezometerContributorLink'
import DtoProductionUnit from '../../../productionUnit/dto/DtoProductionUnit'
import DtoQualitometer from '../../../quality/dto/DtoQualitometer'
import { SANDRE } from '../../../referencial/constants/ReferencialConstants'
import DtoSandreCode from '../../../referencial/dto/DtoSandreCode'
import { getFullDate } from '../../../utils/DateUtil'
import { getExport, setModal } from '../../../utils/linkUtils'
import { arrayOf, getLabel } from '../../../utils/StoreUtils'
import { getSandreLabel } from '../../../utils/StringUtil'
import DtoHydrogeologicalEntity from '../../dto/DtoHydrogeologicalEntity'
import HydrologicalEntityModal from './modals/HydrologicalEntityModal'

const StationHydrogeologicalEntityPanel = ({
    station = {},
    hydrogeologicalEntities = [],
    sandreCodes = [],
    contributors = [],
    readMode = false,
    className = '',
    onChange = () => {},
    setPopup = () => {},
}) => {
    const stationHydroEntities = station.link_hydroEntity.map((c, index) => ({
        ...c,
        index,
    }))
    const tableData = stationHydroEntities.map(c => ({
        ...c,
        startDate: getFullDate(c.startDate),
        endDate: getFullDate(c.endDate),
        contributor: getLabel(contributors, c.contributor),
        hydrologicalEntity: getLabel(hydrogeologicalEntities, c.hydrologicalEntity),
        qualityAssociation: getSandreLabel(sandreCodes, SANDRE.QUALITE_POINT_RESSOURCE, c.qualityAssociation),
    }))
    const headers = ['startDate', 'endDate', 'hydrologicalEntity', 'contributor', 'qualityAssociation', 'comment']
    const exportAction = getExport(tableData, i18n.hydrogeologicalEntities, headers)
    const actions = !readMode ? [{
        iconName: 'note_add',
        tooltip: i18n.add,
        onClick: () => setModal(i18n.hydrogeologicalEntities, <HydrologicalEntityModal id={ station.id } saveResult={ r => onChange({ link_hydroEntity: [...stationHydroEntities, r] }) }/>, setPopup),
    }, exportAction] : [exportAction]

    return (
        <Table
            title={ i18n.hydrogeologicalEntities}
            className={ className }
            condensed
            data={ tableData }
            type={{ headers }}
            sortable={ !!tableData.length }
            actions={ actions }
            alterable={ !readMode }
            onAlter={
                (element) => setModal(i18n.hydrogeologicalEntities,
                    <HydrologicalEntityModal
                        id={ station.id }
                        hydrologicalEntity={ stationHydroEntities.find(c => element.index === c.index) }
                        saveResult={ r => onChange({
                            link_hydroEntity: [
                                ...stationHydroEntities.filter(lc => lc.index !== element.index),
                                { ...r, index: element.index },
                            ],
                        })}
                    />
                    , setPopup)
            }
            deletable={ !readMode }
            onDelete={ (element) => onChange({ link_hydroEntity: stationHydroEntities.filter(lc => lc.index !== element.index) }) }
        />
    )
}

StationHydrogeologicalEntityPanel.propTypes = {
    station: PropTypes.oneOfType([
        PropTypes.instanceOf(DtoProductionUnit),
        PropTypes.instanceOf(DtoDistributionUnit),
        PropTypes.instanceOf(DtoQualitometer),
        PropTypes.instanceOf(DtoPiezometer),
        PropTypes.instanceOf(DtoHydrometricStation),
        PropTypes.instanceOf(DtoInstallation),
    ]),
    contributors: arrayOf(DtoPiezometerContributorLink),
    hydrogeologicalEntities: arrayOf(DtoHydrogeologicalEntity),
    sandreCodes: arrayOf(DtoSandreCode),
    readMode: PropTypes.bool,
    onChange: PropTypes.func,
    setPopup: PropTypes.func,
    className: PropTypes.string,
}

const mapStateToProps = store => ({
    contributors: store.ContributorReducer.contributors,
    hydrogeologicalEntities: store.StationReducer.hydrogeologicalEntities,
    sandreCodes: store.ReferencialReducer.sandreCodes,
})

const mapDispatchToProps = {
    setPopup: SieauAction.setPopup,
}

export default connect(mapStateToProps, mapDispatchToProps)(StationHydrogeologicalEntityPanel)