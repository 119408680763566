import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import ActionComponent from '../../../components/ActionComponent'
import { arrayOf, getLabel, getObjectLabel, getSandreList } from '../../../utils/StoreUtils'
import Card from '../../../components/card/Card'
import StationMapDashboardPanel from '../../../station/components/dashboard/component/map/StationMapDashboardPanel'
import Input from '../../../components/forms/Input'
import DatePicker from '../../../components/forms/DatePicker'
import NumberField from '../../../components/forms/NumberField'
import Select from '../../../components/forms/Select'
import Textarea from '../../../components/forms/Textarea'
import Checkbox from '../../../components/forms/Checkbox'
import { nbPerPageLabel, SANDRE } from '../../../referencial/constants/ReferencialConstants'
import { hasValue } from '../../../utils/NumberUtil'
import LastEventPanel from '../../../station/components/event/LastEventPanel'
import ContactCard from '../../../referencial/components/contact/components/ContactCard'
import StationUpdatePanel from '../../../station/components/update/StationUpdatePanel'
import { groupBy, orderBy, some, uniqBy } from 'lodash'
import { getControlColor } from '../../../utils/InstallationUtils'
import { getColorCircleElement, getRGBColor } from '../../../utils/ColorUtil'
import DtoInstallation from '../../dto/installation/DtoInstallation'
import CityDto from '../../../referencial/components/city/dto/CityDto'
import DtoSandreCode from '../../../referencial/dto/DtoSandreCode'
import ContributorDto from '../../../referencial/components/contributor/dto/ContributorDto'
import UserDto from '../../../administration/components/user/dto/UserDto'
import DtoStatus from '../../../quality/dto/DtoStatus'
import DtoInstallationEvent from '../../dto/installation/DtoInstallationEvent'
import DtoIndustrialSite from './dto/DtoIndustrialSite'
import DtoInstallationActivity from '../../dto/industrialSite/DtoInstallationActivity'
import InstallationAction from '../../actions/InstallationAction'
import Row from '../../../components/react/Row'
import AlertAction from '../../../alerting/actions/AlertAction'
import AppStore from '../../../store/AppStore'
import PollutionSourceParameterDto from '../../../alerting/dto/pollution/PollutionSourceParameterDto'
import ParameterDto from '../../../referencial/components/parameter/dto/ParameterDto'
import ParameterAction from '../../../referencial/components/parameter/actions/ParameterAction'
import { POLLUTION_LEVEL_COLOR } from '../../../alerting/constants/PollutionConstants'
import Table from '../../../components/datatable/Table'
import PanelList from '../../../components/list/panelList/PanelList'
import { push } from '@lagunovsky/redux-react-router'
import LinkedStationsPanel from '../../../station/components/associatedStation/LinkedStationsPanel'
import MaterielAssigned from '../../../station/components/materiel/MaterielAssigned'

import InstallationInfosDashboard from '../installations/components/InstallationInfosDashboard'
import UnitChartPanel from '../../../productionUnit/components/UnitChartPanel'
import DtoTerritoryEmissionsActivity from '../../dto/installation/DtoTerritoryEmissionsActivity'
import DtoInstallationIndustrialSite from '../../dto/industrialSite/DtoInstallationIndustrialSite'
import IndustrialSiteActivityPanel from './IndustrialSiteActivityPanel'
import DtoTerritoryEmissionsDeclaration from '../../dto/installation/DtoTerritoryEmissionsDeclaration'
import { H_MAT_MODULE } from '../../../account/constants/AccessRulesConstants'
import HabilitationRequired from '../../../utils/components/HabilitationRequired'
import { WhiteCard } from 'components/styled/Card'
import InstallationCounterPanel from '../installationDefault/InstallationCounterPanel'


class IndustrialSiteDashboardPanel extends ActionComponent {
    constructor(props) {
        super(props)
        this.state = {
            uncheckedStations: [],
            emissionsActivity: [],
            emissionsDeclarations: [],
            installationIndustrialSite: {},
        }
    }

    componentDidMount() {
        this.props.fetchIndustrialSite(this.props.installation.id).then(() => {
            const activities = ['', '2', '3', '4'].map(x => this.props.industrialSite[`activityCode${x}`]).filter(c => !!c)
            if (activities.length) {
                AppStore.dispatch(AlertAction.fetchPollutionSourceParametersWithActivities(activities))
                this.getEmissionsActivities(activities)
            }
            if (!this.props.installationIndustrialSites) {
                this.props.fetchInstallationIndustrialSites().then(() => {
                    this.getIndustrialSite()
                })
            } else {
                this.getIndustrialSite()
            }
        })
        if (!this.props.activities.length) {
            this.props.fetchActivities()
        }
        this.props.fetchTerritoryEmissionsDeclarations(this.props.installation.id).then(() => {
            const { emissionsDeclarations } = this.props
            this.setState({
                emissionsDeclarations,
            })
        })
        AppStore.dispatch(ParameterAction.fetchParameters())
    }

    getIndustrialSite = () => {
        this.props.installationIndustrialSites.map((instal) => {
            if (instal.code === this.props.installation.code) {
                this.setState({
                    installationIndustrialSite: instal,
                })
            }
        })
    }

    getEmissionsActivities = (activities) => {
        activities.map((activityCode) => {
            const categoryActivity = this.state.installationIndustrialSite.categoryActivity
            const obj = categoryActivity ? { activityCode, categoryActivity } : { activityCode }
            this.props.fetchTerritoryEmissionsActivity(obj).then(() => {
                const { emissionsActivity } = this.props
                const newEmissionsActivity = [...this.state.emissionsActivity, ...emissionsActivity]
                this.setState({
                    emissionsActivity: newEmissionsActivity,
                })
            })
        })
    }

    componentWillUnmount() {
        AppStore.dispatch(AlertAction.resetPollutionSourceParameters())
    }

    getOriginIcon = (installation) => {
        return hasValue(installation.jobExecutionId) ? (
            <div className='row no-margin padding-left-1 padding-top-1'>
                <h6 className='valign-wrapper'>
                    <i className='material-icons rem-3-size padding-right-1'>wifi</i>
                    {i18n.automaticUpdate}
                </h6>
            </div>
        ) : null
    }

    getOriginStatusColor = (installation) => {
        if (hasValue(installation.status)) {
            const color = getControlColor(installation.status)
            return getColorCircleElement(getRGBColor(color), true)
        }
        return null
    }

    pushToParameter = (id) => {
        AppStore.dispatch(push(`/referencial/parameter/${id}`))
    }

    getPollutionSources = () => {
        const groups = groupBy(this.props.pollutionSourceParameters, 'rate')
        const levels = orderBy(Object.keys(groups), v => parseInt(v), ['desc']).map(rate => {
            const parameters = uniqBy(groups[rate], 'parameter')
            const tableData = parameters.map(p => ({ id: p.parameter, name: getObjectLabel(this.props.parametersIndex[p.parameter], 'labelWithCode'), activityCode: `[${p.activityCode}] ${getLabel(this.props.activities, p.activityCode)}` }))
            return (
                <PanelList title={`${i18n.level} ${rate} (${parameters.length} ${i18n.parameters})`} titleColor={(POLLUTION_LEVEL_COLOR.find(p => p.rate == rate) || {}).html}>
                    {parameters.length ? <Table condensed data={tableData} type={{ headers: ['name', 'activityCode'] }} showTitle={false} onClick={v => this.pushToParameter(v.id)} /> : i18n.noParameter}
                </PanelList>
            )
        })
        return (
            <WhiteCard title={i18n.potentialPollutants}>
                <Card maxHeight={800} height={800}>
                    <Row className='padding-left-1 padding-right-1'>
                        {levels}
                    </Row>
                </Card>
            </WhiteCard>
        )
    }

    render() {
        const installation = this.props.installation
        const industrialSite = this.props.industrialSite
        const readMode = { readMode: true, editMode: false, hideNull: true }

        const headersEmissionsActivity = ['dataSource', 'categoryActivity', 'parameterCode', 'name', 'companyFlowKgDay', 'employeeFlowKgDay']
        const emissionsActivityToExport = this.state.emissionsActivity.map((em, i) => {
            return i === 0 ? { headers: headersEmissionsActivity, ...em } : em
        })

        const headersEmissionsDeclarations = ['year', 'environment', 'pollutant', 'quantity', 'unit']
        const emissionsDeclarationsToExport = this.state.emissionsDeclarations.map((em, i) => {
            return i === 0 ? { headers: headersEmissionsDeclarations, ...em } : em
        })

        return (
            <div className='row no-margin padding-top-1 padding-bottom-7'>
                <div className='col s9'>
                    <Card noMargin={false} className='margin-bottom-1'>
                        <Row>
                            <InstallationInfosDashboard />
                        </Row>
                    </Card>
                    <WhiteCard
                        title={i18n.characteristics}
                        smallCard
                        displayIf={some(['exploitationCode', 'comments'], key => hasValue(installation[key]))}
                        noMargin={false}
                        className='margin-bottom-1'
                    >
                        <div className='row no-margin padding-top-1'>
                            <div className='col s6' >
                                <div className='row no-margin'>
                                    <Checkbox componentClassName='padding-bottom-1' col={12} checked={hasValue(installation.exploitationCode) ? installation.exploitationCode == 1 : null}
                                        label={i18n.exploitation} disabled {...readMode}
                                    />
                                </div>
                            </div>
                            <div className='col s6' >
                                <div className='row no-margin'>
                                    <Textarea col={12} value={installation.comments} title={i18n.comment} {...readMode} />
                                </div>
                            </div>
                        </div>
                    </WhiteCard>
                    <InstallationCounterPanel
                        stationId={installation.id}
                        showTitle
                        showKeyFigures
                        inPopup
                    />
                    <Row>
                        <UnitChartPanel unit={this.props.installation} uncheckedStations={this.state.uncheckedStations} setDataLoaded={this.props.setDataLoaded} fromStationType='INST' />
                    </Row>
                    <div className='margin-bottom-1'>
                        <IndustrialSiteActivityPanel
                            industrialSite={industrialSite}
                            readMode={readMode}
                        />
                    </div>
                    {this.getPollutionSources()}
                    {this.state.emissionsActivity && (
                        <div className='margin-bottom-1'>
                            <Table
                                title={i18n.pollutantsEmissions}
                                condensed sortable paging
                                nbPerPageLabel={nbPerPageLabel}
                                data={this.state.emissionsActivity}
                                type={{ headers: headersEmissionsActivity }}
                                exportButtonOnHeader
                                exportData={emissionsActivityToExport}
                                exportName={i18n.territoryPollutantsEmissions}
                                invertedHeaderStyle
                            />
                        </div>
                    )}
                    {this.state.emissionsDeclarations && (
                        <div className='margin-bottom-1'>
                            <Table
                                title={i18n.emissionsDeclarations}
                                condensed sortable paging
                                nbPerPageLabel={nbPerPageLabel}
                                data={this.state.emissionsDeclarations}
                                type={{ headers: headersEmissionsDeclarations }}
                                exportButtonOnHeader exportData={ emissionsDeclarationsToExport }
                                exportName={i18n.emissionsDeclarations}
                                invertedHeaderStyle
                            />
                        </div>
                    )}
                    <LinkedStationsPanel
                        title={i18n.installations}
                        onReMount={ this.props.onReMount }
                        dashboardMode
                        checkable
                        filteredStations={ ['installation'] }
                        changeUncheckedStations={ tab => this.setState({ uncheckedStations: tab })}
                        station={this.props.installation}
                        withColors
                        hideTitle
                    />
                </div>
                <div className='col s3'>
                    <StationUpdatePanel station={installation} />
                    <Card displayIf={some(['dataOrigin', 'closeComment', 'status', 'statusDate', 'deliberationDate', 'confidential'], key => hasValue(installation[key]))}>
                        {this.getOriginIcon(installation)}
                        <div className='row no-margin'>
                            <Input col={12} title={i18n.dataOrigin} value={installation.dataOrigin} {...readMode} />
                        </div>
                        <div className='row no-margin'>
                            <Select col={12} label={[this.getOriginStatusColor(installation), i18n.status]} value={installation.status}
                                options={this.props.status} {...readMode}
                            />
                        </div>
                        <div className='row no-margin'>
                            <DatePicker col={12} value={installation.statusDate} id='statusDate' title={i18n.controlDoneAt} {...readMode} />
                        </div>
                        <div className='row no-margin'>
                            <Select col={12} label={i18n.controlDoneBy} value={installation.statusLogin}
                                options={this.props.users.map(u => ({ code: u.login, name: u.name || u.login }))} {...readMode}
                            />
                        </div>
                        <div className='row no-margin'>
                            <DatePicker col={12} value={installation.endDate} id='deliberationDate' title={i18n.deliberationDate} {...readMode} />
                        </div>
                        <div className='row no-margin'>
                            <Input col={12} value={installation.closeComment} title={i18n.become} {...readMode} />
                        </div>
                        <div className='row no-margin'>
                            <Checkbox componentClassName='padding-bottom-1' col={12} label={i18n.confidential} {...readMode}
                                checked={hasValue(installation.confidential) ? installation.confidential == '1' : null} disabled
                            />
                        </div>
                    </Card>
                    <Row className='padding-top-1'>
                        <ContactCard
                            title={i18n.contact}
                            contactCode={installation.ownerCode}
                            {...readMode}
                        />
                    </Row>
                    <div className='padding-top-1' />
                    <Card>
                        <div className='row no-margin padding-top-1'>
                            <Input col={12} title={i18n.address} value={installation.address} {...readMode} />
                        </div>
                        <div className='row no-margin'>
                            <Select col={12} value={installation.townCode} label={i18n.city} options={this.props.cities} keyValue='id' displayWithCode {...readMode} />
                        </div>
                        <div className='row no-margin'>
                            <NumberField col={6} title='X' value={installation.x} floatValue {...readMode} />
                            <NumberField col={6} title='Y' value={installation.y} floatValue {...readMode} />
                        </div>
                        <div className='row no-margin'>
                            <NumberField col={6} title='Z' value={installation.altitude} floatValue {...readMode} />
                            <Select col={6} label={i18n.projection} value={installation.projection}
                                options={getSandreList(this.props.sandreCodes, SANDRE.PROJECTION)} {...readMode}
                            />
                        </div>
                    </Card>
                    <div className='padding-top-1' />
                    <HabilitationRequired habilitation={H_MAT_MODULE}>
                        <div>
                            <MaterielAssigned stationId={this.props.installation.id} type={this.props.stationType} />
                            <div className='padding-top-1' />
                        </div>
                    </HabilitationRequired>
                    <LastEventPanel id={this.props.installation.id} events={this.props.installationEvents} stationId={this.props.installation.id}
                        stationType='installation'
                    />
                    <div className='padding-top-1' />
                    <StationMapDashboardPanel noMarkerTooltip station={installation} type={'installation'} />
                    <div className='padding-top-1' />
                    <LinkedStationsPanel
                        onReMount={ this.props.onReMount }
                        dashboardMode
                        checkable
                        filteredStations={ ['quality', 'piezometry', 'hydrometry', 'pluviometry', 'productionUnit'] }
                        changeUncheckedStations={ tab => this.setState({ uncheckedStations: tab })}
                        station={ this.props.installation } withColors
                    />
                </div>
            </div>
        )
    }
}

IndustrialSiteDashboardPanel.propTypes = {
    setDataLoaded: PropTypes.func,
    onRemount: PropTypes.func,
    stationType: PropTypes.string,
    installation: PropTypes.instanceOf(DtoInstallation),
    cities: PropTypes.arrayOf(PropTypes.instanceOf(CityDto)),
    sandreCodes: PropTypes.arrayOf(PropTypes.instanceOf(DtoSandreCode)),
    contributors: PropTypes.arrayOf(PropTypes.instanceOf(ContributorDto)),
    users: PropTypes.arrayOf(PropTypes.instanceOf(UserDto)),
    status: PropTypes.arrayOf(PropTypes.instanceOf(DtoStatus)),
    installationEvents: PropTypes.arrayOf(PropTypes.instanceOf(DtoInstallationEvent)),
    industrialSite: PropTypes.instanceOf(DtoIndustrialSite),
    activities: PropTypes.arrayOf(PropTypes.instanceOf(DtoInstallationActivity)),
    pollutionSourceParameters: PropTypes.arrayOf(PropTypes.instanceOf(PollutionSourceParameterDto)),
    parametersIndex: PropTypes.objectOf(PropTypes.instanceOf(ParameterDto)),
    emissionsActivity: PropTypes.arrayOf(PropTypes.instanceOf(DtoTerritoryEmissionsActivity)),
    fetchTerritoryEmissionsActivity: PropTypes.func,
    installationIndustrialSites: arrayOf(DtoInstallationIndustrialSite),
    fetchInstallationIndustrialSites: PropTypes.func,
    fetchTerritoryEmissionsDeclarations: PropTypes.func,
    emissionsDeclarations: PropTypes.arrayOf(PropTypes.instanceOf(DtoTerritoryEmissionsDeclaration)),
}

const mapStateToProps = store => ({
    installation: store.InstallationReducer.installation,
    cities: store.CityReducer.cities,
    sandreCodes: store.ReferencialReducer.sandreCodes,
    contributors: store.ContributorReducer.contributors,
    users: store.UserReducer.users,
    status: store.QualityReducer.status,
    installationEvents: store.InstallationReducer.installationEvents,
    industrialSite: store.InstallationReducer.industrialSite,
    activities: store.InstallationReducer.activities,
    pollutionSourceParameters: store.AlertReducer.pollutionSourceParameters,
    parametersIndex: store.ParameterReducer.parametersIndex,
    emissionsActivity: store.InstallationReducer.emissionsActivity,
    installationIndustrialSites: store.InstallationReducer.installationIndustrialSites,
    emissionsDeclarations: store.InstallationReducer.emissionsDeclarations,
})

const mapDispatchToProps = {
    fetchActivities: InstallationAction.fetchActivities,
    fetchIndustrialSite: InstallationAction.fetchIndustrialSite,
    fetchTerritoryEmissionsActivity: InstallationAction.fetchTerritoryEmissionsActivity,
    fetchInstallationIndustrialSites: InstallationAction.fetchInstallationIndustrialSites,
    fetchTerritoryEmissionsDeclarations: InstallationAction.fetchTerritoryEmissionsDeclarations,
}

export default connect(mapStateToProps, mapDispatchToProps)(IndustrialSiteDashboardPanel)