import React, { useMemo, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { v4 as uuidv4 } from 'uuid'
import { sieauTooltip } from '../../utils/FormUtils'
import { searchAllCharacters } from '../../utils/StringUtil'
import useDebounce from 'utils/customHook/useDebounce'

const SimpleColorPicker = ({
    color,
    onChange,
    style = {},
}) => {
    const [localColor, setlocalColor] = useState(color)
    useDebounce(() => onChange(localColor), 500, [localColor])

    return (
        <input
            type='color'
            data-attribute='color'
            style={{
                backgroundColor: localColor,
                transition: 'none',
                border: '1px solid #7a7a7a',
                borderRadius: '5px',
                padding: '0 5',
                outline: 'none',
                boxShadow: 'none',
                ...style,
            }}
            value={localColor}
            onChange={e => setlocalColor(e.target.value)}
        />
    )
}

SimpleColorPicker.propTypes = {
    color: PropTypes.string,
    onChange: PropTypes.func,
    style: PropTypes.shape({}),
}

const ColorPicker = ({
    col,
    className = '',
    componentClassName = '',
    label = '',
    onChange = () => {},
    value,
    tooltip,
    disabled = false,
    id,
    style = {},
    componentStyle = {},
}) => {
    const [color, setColor] = useState(value)

    const selectId = useMemo(() => id || searchAllCharacters(label + uuidv4()).replaceAll(' ', '_').replace('numero', '').replace('nom', 'libelle'), [])

    useDebounce(() => onChange(color), 200, [color])
    useEffect(() => {
        if (value !== color) {
            setColor(value)
        }
    }, [value])

    return (
        <div className={`${col ? `col s${col}` : ''}`} >
            <div className={`${componentClassName} input-field`} style={componentStyle} {...(tooltip ? sieauTooltip(tooltip) : {})}>
                <input
                    type='color'
                    className={`sieau-input form-control input-sm filled-in ${className}`}
                    data-attribute='color'
                    style={{ backgroundColor: color, transition: 'none', ...style }}
                    id={ selectId }
                    value={ color }
                    onChange={ e => setColor(e.target.value) }
                    disabled={disabled}
                />
                {!!label && (
                    <label htmlFor={ selectId } className='truncate' style={{ display: 'inherit' }}>{ label }</label>
                )}
            </div>
        </div>
    )
}

ColorPicker.propTypes = {
    className: PropTypes.string,
    componentClassName: PropTypes.string,
    onChange: PropTypes.func,
    label: PropTypes.string,
    value: PropTypes.string,
    disabled: PropTypes.bool,
    col: PropTypes.number,
    changeObj: PropTypes.func,
    tooltip: PropTypes.string,
    id: PropTypes.string,
    style: PropTypes.shape({}),
    componentStyle: PropTypes.shape({}),
}

export default ColorPicker
export {
    SimpleColorPicker,
}