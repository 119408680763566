export default class DtoInstallationAnalysisMeasures {
    constructor(obj) {
        this.stationId = obj.stationId
        this.dataType = obj.dataType
        this.startDate = obj.startDate
        this.endDate = obj.endDate
        this.chartMode = obj.chartMode
        this.measures = obj.measures
        this.parameterCode = obj.parameterCode
    }
}
