export default class DtoIAEauModel {
    constructor(obj) {
        this.idStation = obj.idStation
        this.idModel = obj.idModel
        this.typeModel = obj.typeModel
        this.typeId = obj.typeId
        this.name = obj.name
        this.statusCode = obj.statusCode
        this.creationDate = obj.creationDate
        this.updateDate = obj.updateDate
        this.updateLogin = obj.updateLogin
        this.params = obj.params
        this.objective = obj.objective
        this.horizon = obj.horizon
        this.confidenceIndicator = obj.confidenceIndicator
        this.calculationDate = obj.calculationDate
        this.color = obj.color
        this.default = obj.default
        this.idScenario = obj.idScenario
        this.yearScenario = obj.yearScenario
        this.displayDashboard = obj.displayDashboard
        this.displaySituation = obj.displaySituation
        this.displayAlert = obj.displayAlert
        this.displaySuivi = obj.displaySuivi
        this.jobExecutionId = obj.jobExecutionId
        this.chainCode = obj.chainCode
        this.lineType = obj.lineType
        this.lineWidth = obj.lineWidth
        this.lineOpacity = obj.lineOpacity
        this.horizonMode = obj.horizonMode
        this.calculationMode = obj.calculationMode
        this.simulationDate = obj.simulationDate
        this.simulationEndDate = obj.simulationEndDate
        this.measures = obj.measures
        this.recurPeriod = obj.recurPeriod
        this.stationType = obj.stationType
    }
}