export default class DtoExportJob {
    constructor(obj) {
        this.id = obj.id // Int
        this.exportType = obj.exportType // String
        this.fileType = obj.fileType // Option[String]
        this.jobExecutionId = obj.jobExecutionId // Option[Long]
        this.login = obj.login // String
        this.creationDate = obj.creationDate // DateTime
        this.updateDate = obj.updateDate // Option[DateTime]
        this.status = obj.status // Int // 1 -> En attente 2 -> En cours 3 -> Fini 4 -> Erreur
        this.progress = obj.progress // Option[Double]
        this.filePath = obj.filePath // Option[String]
        this.params = obj.params // Option[String]
        this.fileSize = obj.fileSize // Option[String]
        this.scheduledOnJob = obj.scheduledOnJob
        this.name = obj.name
    }
}