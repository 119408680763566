export default class DtoManagementUnitStationLink {
    constructor(obj) {
        this.managementCode = obj.managementCode // Long,
        this.stationType = obj.stationType // Int,
        this.stationId = obj.stationId // Long,
        this.real = obj.real // Option[Boolean],
        this.coeffReal = obj.coeffReal // Option[Double],
        this.previ = obj.previ // Option[Boolean],
        this.coeffPrevi = obj.coeffPrevi // Option[Double]
    }
}