/* eslint-disable camelcase */
import React from 'react'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import { connect } from 'react-redux'
import DtoInstallation from '../../../dto/installation/DtoInstallation'
import Textarea from '../../../../components/forms/Textarea'
import { arrayOf, getSandreList } from '../../../../utils/StoreUtils'
import Row from '../../../../components/react/Row'
import Checkbox from '../../../../components/forms/Checkbox'
import Input from '../../../../components/forms/Input'
import NumberField from '../../../../components/forms/NumberField'
import DtoSandreCode from '../../../../referencial/dto/DtoSandreCode'
import { SANDRE } from '../../../../referencial/constants/ReferencialConstants'
import { getVisitCheckBoxChange, getVisitSandreSelectChange } from '../../../../utils/VisitUtils'
import Select from '../../../../components/forms/Select'

const InstallationComElecForm = ({
    onChange = () => { },
    onChangeVisit = () => { },
    station = {},
    readMode = false,
    sandreCodes = [],
}) => {
    const onChangeComElec = (value) => {
        const comElec = station.link_elecComs[0] || {}
        onChange({
            link_elecComs: [{
                idStation: station.id,
                ...comElec,
                ...value,
            }],
        })
    }

    const comElec = station.link_elecComs[0] || {}

    return (
        <div className='row no-margin padding-top-1 padding-bottom-1'>
            <Row>
                <Checkbox
                    col={ 6 }
                    checked={ comElec.remoteManagement }
                    label={ i18n.remoteManagement }
                    onChange={ v => {
                        onChangeComElec({ remoteManagement: v })
                        onChangeVisit(getVisitCheckBoxChange(i18n.remoteManagement, comElec.remoteManagement, v))
                    } }
                    disabled={ readMode }
                />
                <Checkbox
                    col={ 6 }
                    checked={ comElec.supervision }
                    label={ i18n.supervision }
                    onChange={ v => {
                        onChangeComElec({ supervision: v })
                        onChangeVisit(getVisitCheckBoxChange(i18n.supervision, comElec.supervision, v))
                    } }
                    disabled={ readMode }
                />
            </Row>
            <Row className={ `${!readMode ? 'padding-bottom-1 padding-top-1' : ''}` }>
                <Textarea
                    col={ 6 }
                    value={ comElec.remoteDescription }
                    title={ i18n.remoteDescription }
                    onChange={ v => {
                        onChangeComElec({ remoteDescription: v })
                        onChangeVisit({ previousValue: comElec.remoteDescription, newValue: v, field: i18n.remoteDescription })
                    } }
                    readMode={ readMode }
                />
                <Textarea
                    col={ 6 }
                    value={ comElec.supervisionDescription }
                    title={ i18n.supervisionDescription }
                    onChange={ v => {
                        onChangeComElec({ supervisionDescription: v })
                        onChangeVisit({ previousValue: comElec.supervisionDescription, newValue: v, field: i18n.supervisionDescription })
                    } }
                    readMode={ readMode }
                />
            </Row>
            <hr />
            <Row className={ `${!readMode ? 'padding-bottom-1' : ''}` }>
                <Select
                    col={ 6 }
                    value={ comElec.communicationMode }
                    label={ i18n.communicationMode }
                    keyvalue='code'
                    integerValue
                    options={ getSandreList(sandreCodes, SANDRE.INSTALLATION_MODE_TELECOM) }
                    onChange={ v => {
                        onChangeComElec({ communicationMode: v })
                        onChangeVisit(getVisitSandreSelectChange(sandreCodes, SANDRE.INSTALLATION_MODE_TELECOM, i18n.communicationMode, comElec.communicationMode, v))
                    } }
                    readMode={ readMode }
                />
                <Select
                    col={ 6 }
                    value={ comElec.transformer }
                    label={ i18n.transformer }
                    keyvalue='code'
                    integerValue
                    options={ getSandreList(sandreCodes, SANDRE.INSTALLATIONS_TRANSFORMATEUR) }
                    onChange={ v => {
                        onChangeComElec({ transformer: v })
                        onChangeVisit(getVisitSandreSelectChange(sandreCodes, SANDRE.INSTALLATIONS_TRANSFORMATEUR, i18n.transformer, comElec.transformer, v))
                    } }
                    readMode={ readMode }
                />
            </Row>
            <Row>
                <Select
                    col={ 12 }
                    value={ comElec.rescueDevice }
                    label={ i18n.rescueDevice }
                    keyvalue='code'
                    integerValue
                    options={ getSandreList(sandreCodes, SANDRE.INSTALLATIONS_SECOURS) }
                    onChange={ v => {
                        onChangeComElec({ rescueDevice: v })
                        onChangeVisit(getVisitSandreSelectChange(sandreCodes, SANDRE.INSTALLATIONS_SECOURS, i18n.rescueDevice, comElec.rescueDevice, v))
                    } }
                    readMode={ readMode }
                />
            </Row>
            <Row className={ `${!readMode ? 'padding-bottom-1' : ''}` }>
                <Input
                    col={ 12 }
                    title={ i18n.alimentationWorking }
                    readMode
                />
                <Checkbox
                    col={ 6 }
                    checked={ comElec.normalMode }
                    label={ i18n.normalMode }
                    onChange={ v => {
                        onChangeComElec({ normalMode: v })
                        onChangeVisit(getVisitCheckBoxChange(i18n.normalMode, comElec.normalMode, v))
                    } }
                    disabled={ readMode }
                />
                <Checkbox
                    col={ 6 }
                    checked={ comElec.degradedMode }
                    label={ i18n.degradedMode }
                    onChange={ v => {
                        onChangeComElec({ degradedMode: v })
                        onChangeVisit(getVisitCheckBoxChange(i18n.degradedMode, comElec.degradedMode, v))
                    } }
                    disabled={ readMode }
                />
            </Row>
            <Row className={ `${!readMode ? 'padding-bottom-1' : ''}` }>
                <Checkbox
                    col={ 6 }
                    checked={ comElec.prerequisiteInstallation }
                    label={ i18n.prerequisiteInstallation }
                    onChange={ v => {
                        onChangeComElec({ prerequisiteInstallation: v })
                        onChangeVisit(getVisitCheckBoxChange(i18n.prerequisiteInstallation, comElec.prerequisiteInstallation, v))
                    } }
                    disabled={ readMode }
                />
                <Checkbox
                    col={ 6 }
                    checked={ comElec.availableSpace }
                    label={ i18n.availableSpace }
                    onChange={ v => {
                        onChangeComElec({ availableSpace: v })
                        onChangeVisit(getVisitCheckBoxChange(i18n.availableSpace, comElec.availableSpace, v))
                    } }
                    disabled={ readMode }
                />
            </Row>
            <Row className={ `${!readMode ? 'padding-bottom-1' : ''}` }>
                <NumberField
                    col={ 6 }
                    value={ comElec.cableWitdh }
                    title={ i18n.cableWitdh }
                    onChange={ v => {
                        onChangeComElec({ cableWitdh: v })
                        onChangeVisit({ previousValue: comElec.cableWitdh, newValue: v, field: i18n.cableWitdh })
                    } }
                    readMode={ readMode }
                />
            </Row>
            <Row className={ `${!readMode ? 'padding-bottom-1' : ''}` }>
                <Checkbox
                    col={ 6 }
                    checked={ comElec.dimmer }
                    label={ i18n.dimmers }
                    onChange={ v => {
                        onChangeComElec({ dimmer: v })
                        onChangeVisit(getVisitCheckBoxChange(i18n.dimmers, comElec.dimmer, v))
                    } }
                    disabled={ readMode }
                />
                <Checkbox
                    col={ 6 }
                    checked={ comElec.starter }
                    label={ i18n.starters }
                    onChange={ v => {
                        onChangeComElec({ starter: v })
                        onChangeVisit(getVisitCheckBoxChange(i18n.starters, comElec.starters, v))
                    } }
                    disabled={ readMode }
                />
            </Row>
        </div>
    )
}

InstallationComElecForm.propTypes = {
    station: PropTypes.instanceOf(DtoInstallation),
    sandreCodes: arrayOf(DtoSandreCode),
    readMode: PropTypes.bool,
    onChange: PropTypes.func,
    onChangeVisit: PropTypes.func,
    setPopup: PropTypes.func,
    changeBssCode: PropTypes.func,
}

const mapStateToProps = store => ({
    sandreCodes: store.ReferencialReducer.sandreCodes,
})

export default connect(mapStateToProps)(InstallationComElecForm)
