import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import Row from '../../../../../components/react/Row'
import Textarea from '../../../../../components/forms/Textarea'
import Select from '../../../../../components/forms/Select'
import { arrayOf, getSandreList } from '../../../../../utils/StoreUtils'
import DtoSandreCode from '../../../../../referencial/dto/DtoSandreCode'
import DtoTankZone from '../../dto/DtoTankZone'
import DtoTankStation from '../../dto/DtoTankStation'
import { SANDRE } from '../../../../../referencial/constants/ReferencialConstants'

class TankSecurityModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            tank: { ...props.tank, idStation: props.id },
        }
    }

    componentDidUpdate(prevProps) {
        if (!prevProps.popupIsOpen && this.props.popupIsOpen) {
            $('#descriptionModal').on('click', this.onSave)
        }
    }
    onSave = () => {
        this.props.saveResult(new DtoTankZone(this.state.tank))
        $('.modal').modal('close')
    }

    onChangeElement = (changes) => this.setState({ tank: { ...this.state.tank, ...changes } })

    render() {
        const { tank } = this.state
        const { sandreCodes } = this.props
        return (
            <div>
                <Row>
                    <Select
                        col={6}
                        value={tank.stateCode}
                        label={i18n.state}
                        options={getSandreList(sandreCodes, SANDRE.RESERVOIRS_ZONES_ETATS)}
                        onChange={v => this.onChangeElement({ stateCode: v })}
                        integerValue
                    />
                </Row>
                <Row className='padding-top-1'>
                    <Textarea
                        col={ 12 }
                        title={ i18n.comment }
                        value={ tank.comment }
                        onChange={ v => this.onChangeElement({ comment: v }) }
                    />
                </Row>
            </div>
        )
    }
}

TankSecurityModal.propTypes = {
    tank: PropTypes.instanceOf(DtoTankStation),
    sandreCodes: arrayOf(DtoSandreCode),
    id: PropTypes.number,
    popupIsOpen: PropTypes.bool,
    saveResult: PropTypes.func,
}

const mapStateToProps = store => ({
    popupIsOpen: store.SieauReducer.popupIsOpen,
    sandreCodes: store.ReferencialReducer.sandreCodes,
})

export default connect(mapStateToProps)(TankSecurityModal)
