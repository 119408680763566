/* eslint-disable camelcase */
import PropTypes from 'prop-types'
import React, { useEffect, useMemo } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import WatermassAction from 'referencial/components/watermass/actions/WatermassAction'
import i18n from 'simple-react-i18n'
import Table from '../../../components/datatable/Table'
import SieauAction from '../../../components/sieau/SieauAction'
import DtoDistributionUnit from '../../../distributionUnit/dto/DtoDistributionUnit'
import DtoHydrometricStation from '../../../hydrometry/dto/DtoHydrometricStation'
import DtoInstallation from '../../../installation/dto/installation/DtoInstallation'
import DtoPiezometer from '../../../piezometry/dto/DtoPiezometer'
import DtoProductionUnit from '../../../productionUnit/dto/DtoProductionUnit'
import DtoQualitometer from '../../../quality/dto/DtoQualitometer'
import { getExport, setModal } from '../../../utils/linkUtils'
import WatermassModal from './modals/WatermassModal'

const headers = ['watermass']

const StationWatermassPanel = ({
    station = {},
    readMode = false,
    onChange = () => {},
    className = '',
    limit,
}) => {
    const {
        watermasses,
    } = useSelector(store => ({
        watermasses: store.WatermassReducer.watermasses,
    }), shallowEqual)

    const dispatch = useDispatch()

    useEffect(() => {
        if (!watermasses.length) {
            dispatch(WatermassAction.fetchWatermasses())
        }
    }, [])

    const tableData = useMemo(() => {
        const stationWatermasses = station.link_watermasses || []
        return stationWatermasses.map((w, index) => {
            const watermass = watermasses.find(ws => ws.code === w.watermassCode)
            return {
                ...w,
                watermass: `${watermass?.name || ''} [${watermass?.code || ''}]`,
                index,
            }
        })
    }, [station.link_watermasses, watermasses])

    const setPopup = (value) => dispatch(SieauAction.setPopup(value))

    const actions = useMemo(() => {
        const exportAction = getExport(tableData, i18n.watermasses, headers)
        return !readMode && (!limit || tableData?.length < limit) ? [{
            iconName: 'note_add',
            tooltip: i18n.add,
            onClick: () => setModal(i18n.newWatermass, <WatermassModal id={ station.id } saveResult={ r => onChange({ link_watermasses: [...tableData, r] }) }/>, setPopup),
        }, exportAction] : [exportAction]
    }, [limit, readMode, station.id, tableData])

    return (
        <Table
            title={ i18n.watermasses }
            className={className}
            condensed
            data={ tableData }
            type={{ headers }}
            sortable={ !!tableData.length }
            actions={ actions }
            alterable={ !readMode }
            onAlter={
                (element) => setModal(i18n.editWatermass,
                    <WatermassModal
                        id={ station.id }
                        watermass={ element }
                        saveResult={ r => onChange({
                            link_watermasses: [
                                ...tableData.filter(w => w.index !== element.index),
                                { ...r, index: element.index },
                            ],
                        })}
                    />
                    , setPopup)
            }
            deletable={ !readMode }
            onDelete={ (element) => onChange({ link_watermasses: tableData.filter(w => w.index !== element.index) }) }
        />
    )
}

StationWatermassPanel.propTypes = {
    station: PropTypes.oneOfType([
        PropTypes.instanceOf(DtoProductionUnit),
        PropTypes.instanceOf(DtoDistributionUnit),
        PropTypes.instanceOf(DtoQualitometer),
        PropTypes.instanceOf(DtoPiezometer),
        PropTypes.instanceOf(DtoHydrometricStation),
        PropTypes.instanceOf(DtoInstallation),
    ]),
    readMode: PropTypes.bool,
    onChange: PropTypes.func,
    className: PropTypes.string,
    limit: PropTypes.number,
}

export default StationWatermassPanel