import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { Grid } from '@mui/material'
import i18n from 'simple-react-i18n'
import { mainBlue } from 'utils/constants/ColorTheme'
import { sieauTooltip } from 'utils/FormUtils'
import { v4 as uuidv4 } from 'uuid'

const TabList = ({
    style = {},
    defaultTab,
    tabs = [],
    onChangeTab = () => {},
    className,
    children,
}) => {
    const [id] = useState(uuidv4())
    const [value, setValue] = useState(defaultTab || tabs[0].value)

    const onChange = (v) => {
        setValue(v)
        onChangeTab(v)
    }

    return (
        <div id={id} className={className}>
            <Grid container className='no-padding' style={{ justifyContent: 'flex-end' }}>
                <Grid item className='no-padding margin-top-1' style={{ marginRight: 4 }}>
                    <Grid container className='tabs filter round-tab' style={{ borderRadius: '5px 5px 0 0' }}>
                        {tabs.map((t, i) => (
                            <Grid
                                item
                                className={`tab filter ${tabs.length - 1 !== i ? 'hash-tab' : ''}`}
                                id={t.value}
                                onClick={() => onChange(t.value)}
                                {...sieauTooltip(t.label)}
                                key={t.label}
                                data-cy={`tab-${t.value}`}
                            >
                                <a className={`${t.value === value ? 'active' : ''} filter`} style={{ color: t.value === value ? 'white' : 'black', backgroundColor: t.value !== value && 'white' }}>
                                    <i className='material-icons inherited-line-height'>{t.icon}</i>
                                </a>
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            </Grid>
            <div
                className='row'
                style={{
                    border: `2px solid ${mainBlue}`,
                    backgroundColor: 'white',
                    borderRadius: 5,
                    ...style,
                }}
            >
                { children || i18n.noDataToDisplay}
            </div>
        </div>
    )
}

TabList.propTypes = {
    style: PropTypes.shape({}),
    className: PropTypes.string,
    defaultTab: PropTypes.string,
    onChangeTab: PropTypes.func.isRequired,
    children: PropTypes.element.isRequired,
    tabs: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string,
        icon: PropTypes.string,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })).isRequired,
}

export default TabList
