import PropTypes from 'prop-types'
import React from 'react'
import i18n from 'simple-react-i18n'
import { secondaryBlue } from 'utils/constants/ColorTheme'

const VolumesSurveyTab = ({
    volumes,
}) => {
    return (
        <div className='row margin-top-1'>
            <div className='row no-margin' style={{ borderBottom: `solid 1px ${secondaryBlue}`, fontWeight: 'bold' }}>
                <div className='col s6'>
                    {i18n.type}
                </div>
                <div className='col s6'>
                    {i18n.requestedVolumes}
                </div>
            </div>
            {volumes.map((v) => (
                <div className='row no-margin'>
                    <div className='col s6'>
                        {v.label}
                    </div>
                    <div className='col s3' style={{ textAlign: 'end' }}>
                        {v.volume}
                    </div>
                </div>
            ))}
        </div>
    ) || null
}

VolumesSurveyTab.propTypes = {
    volumes: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string,
        volume: PropTypes.string,
    })),
}

export default VolumesSurveyTab
