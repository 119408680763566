import React from 'react'
import { QUALITO_IMPORT_TYPE } from '../AddQualitoConstants'
import i18n from 'simple-react-i18n'
import PropTypes from 'prop-types'
import InDevelopmentBackground from '../../../../../inDevelop/components/InDevelopmentBackground'
import { Grid } from '@mui/material'
import AddQualitoStepOptionsAdes from './AddQualitoStepOptionsAdes'
import AddQualitoStepOptionsNaiades from './AddQualitoStepOptionsNaiades'
import AddQualitoStepOptionsEmpty from './AddQualitoStepOptionsEmpty'
import AddQualitoStepOptionsOrobnat from './AddQualitoStepOptionsOrobnat'

const AddQualitoStepOptions = (props) => {
    const getOptionsPanel = () => {
        switch (props.station.stationType) {
            case QUALITO_IMPORT_TYPE.ADES_QUALITO:
                return <AddQualitoStepOptionsAdes {...props}/>
            case QUALITO_IMPORT_TYPE.NAIADES:
                return <AddQualitoStepOptionsNaiades {...props} />
            case QUALITO_IMPORT_TYPE.OROBNAT:
                return <AddQualitoStepOptionsOrobnat {...props} />
            case QUALITO_IMPORT_TYPE.EMPTY:
                return <AddQualitoStepOptionsEmpty {...props}/>
            default:
                return <InDevelopmentBackground />
        }
    }
    return (
        <div className='padding-top-2'>
            <Grid container justifyContent='center' alignItems='center' spacing={3}>
                <h4>{i18n.newStationStep2}</h4>
            </Grid>
            { getOptionsPanel() }
        </div>
    )
}

AddQualitoStepOptions.propTypes = {
    station: PropTypes.shape({
        stationType: PropTypes.string,
    }),
}

export default AddQualitoStepOptions