import React from 'react'
import { Grid } from '@mui/material'
import { StyledFieldSet, StyledLegend, StyledSpan } from 'components/StyledElements'
import i18n from 'simple-react-i18n'
import { PropTypes } from 'prop-types'
import Input from 'components/forms/Input'

const SummaryStep = ({
    filterData = {},
    setFilterData = () => { },
    children = null,
}) => {
    return (
        <Grid container>
            <Grid item xs={12} className='padding-bottom-1'>
                <StyledFieldSet>
                    <StyledLegend>
                        <StyledSpan>{i18n.name}</StyledSpan>
                    </StyledLegend>
                    <Input
                        value={filterData.nameList}
                        onChange={v => setFilterData({ ...filterData, nameList: v })}
                    />
                </StyledFieldSet>
                <StyledFieldSet>
                    <StyledLegend>
                        <StyledSpan>{i18n.synthesis}</StyledSpan>
                    </StyledLegend>
                    <Grid container justifyContent='space-evenly'>
                        {children}
                    </Grid>
                </StyledFieldSet>
            </Grid >
        </Grid >
    )
}

SummaryStep.propTypes = {
    filterData: PropTypes.shape({}), // DtoFilter
    setFilterData: PropTypes.func,
    children: PropTypes.arrayOf(PropTypes.element),
}

export default SummaryStep