import { createAsyncThunk } from '@reduxjs/toolkit'
import { H_PLUVIO_MODULE } from 'account/constants/AccessRulesConstants'
import ApplicationConf from 'conf/ApplicationConf'
import LogAction from 'log/actions/LogAction'
import i18n from 'simple-react-i18n'
import { REJECTED, IDLE } from 'store/DataManagerConstants'
import ToastrAction from 'toastr/actions/ToastrAction'
import { genericPromise } from 'utils/ActionUtils'
import { isAuthorized } from 'utils/HabilitationUtil'

const PluviometryThunk = {
    fetchPluviometers: createAsyncThunk(
        'fetchPluviometers',
        async (_, { rejectWithValue, dispatch }) => {
            try {
                const response = await genericPromise(ApplicationConf.pluviometry.pluviometers(), 'GET')
                return response
            } catch (err) {
                dispatch(LogAction.logError(`${i18n.fetchError} ${i18n.pluviometers} : ${err}`))
                dispatch(ToastrAction.error(`${i18n.fetchError} ${i18n.pluviometers}`))
                return rejectWithValue(err)
            }
        },
        {
            condition: (arg = { forceFetch: false }, { getState }) => {
                const state = getState()
                if (!isAuthorized(H_PLUVIO_MODULE, state)) {
                    return false
                }
                const { pluviometersStatus } = state.DataManagerReducer.pluviometry
                if (arg.forceFetch) {
                    return pluviometersStatus !== REJECTED
                }
                return pluviometersStatus === IDLE
            },
            dispatchConditionRejection: true,
        },
    ),
}

export default PluviometryThunk