import Icon from 'components/icon/Icon'
import HomeAction from 'home/actions/HomeAction'
import { lowerCase, orderBy } from 'lodash'
import CountUp from 'react-countup'
import React, { useEffect, useMemo } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import ReferencialAction from 'referencial/action/ReferencialAction'
import ManagementUnitAction from 'referencial/components/managementUnit/actions/ManagementUnitAction'
import i18n from 'simple-react-i18n'
import useActions from 'utils/customHook/useActions'
import useTitle from 'utils/customHook/useTitle'
import { getFullDate } from 'utils/DateUtil'
import { getDataWithHeaders } from 'utils/ExportDataUtil'
import { componentHasHabilitations } from 'utils/HabilitationUtil'
import { H_REFERENCIAL_DASHBOARD, H_REFERENCIAL_LEXICONS, H_REFERENCIAL_MAT_TYPES, H_REFERENCIAL_SANDRE_CODES, H_REFERENCIAL_UNIT_MANAGMENT } from '../../../../account/constants/AccessRulesConstants'
import { PATH_REFERENCIAL_LINK, REFERENCIAL } from '../../../../home/constants/RouteConstants'
import HabilitationRequired from '../../../../utils/components/HabilitationRequired'
import DashboardAction from '../actions/DashboardAction'
import { push } from '@lagunovsky/redux-react-router'
import { Grid2 } from '@mui/material'

const headers = ['referencialName', 'updateDate', 'referencialVersion', 'updateStatus', 'updateUser', 'recordingNumber']

const ReferencialDashboardApp = () => {
    const {
        sandreCodes,
        materielTypes,
        managementUnits,
        referencialSandreCodes,
        city,
        contact,
        network,
        fraction,
        contributor,
        watermass,
        method,
        parameter,
        support,
        unit,
        hydrogeologicalEntity,
        watershed,
        taxon,
        aquifer,
        culture,
        activity,
        country,
        rpg,
        sector,
    } = useSelector(store => ({
        referencialSandreCodes: store.ReferencialReducer.referencialSandreCodes,
        sandreCodes: store.ReferencialReducer.sandreCodes,
        materielTypes: store.ReferencialReducer.materielTypes,
        managementUnits: store.ManagementUnitReducer.managementUnits,
        city: store.DashboardReducer.city,
        contact: store.DashboardReducer.contact,
        network: store.DashboardReducer.network,
        fraction: store.DashboardReducer.fraction,
        contributor: store.DashboardReducer.contributor,
        watermass: store.DashboardReducer.watermass,
        method: store.DashboardReducer.method,
        parameter: store.DashboardReducer.parameter,
        support: store.DashboardReducer.support,
        unit: store.DashboardReducer.unit,
        hydrogeologicalEntity: store.DashboardReducer.hydrogeologicalEntity,
        watershed: store.DashboardReducer.watershed,
        taxon: store.DashboardReducer.taxon,
        aquifer: store.DashboardReducer.aquifer,
        culture: store.DashboardReducer.culture,
        activity: store.DashboardReducer.activity,
        country: store.DashboardReducer.country,
        rpg: store.DashboardReducer.rpg,
        sector: store.DashboardReducer.sector,
    }), shallowEqual)

    const referencials = useMemo(() => [
        city,
        contact,
        network,
        fraction,
        contributor,
        watermass,
        method,
        parameter,
        support,
        unit,
        hydrogeologicalEntity,
        watershed,
        taxon,
        aquifer,
        culture,
        activity,
        country,
        rpg,
        sector,
    ], [activity, aquifer, city, contact, contributor, country, culture, fraction, hydrogeologicalEntity, method, network, parameter, support, taxon, unit, watermass, watershed, rpg, sector])

    const dispatch = useDispatch()

    useEffect(() => {
        if (!sandreCodes.length) {
            dispatch(ReferencialAction.fetchSandreCodes())
        }

        dispatch(DashboardAction.fetchEveryCount())
        dispatch(ReferencialAction.fetchReferencialSandreCodes())
        dispatch(ReferencialAction.fetchMaterielTypes())
        dispatch(ManagementUnitAction.fetchManagementUnits())

        dispatch(HomeAction.setHelpLink('', ''))
    }, [])

    useTitle(() => [{
        title: i18n.referencials,
        href: REFERENCIAL,
    }, {
        title: i18n.dashboard,
        href: REFERENCIAL,
    }], [])

    const getDate = fieldReferencial => {
        const findReferencialUpdateDate = referencialSandreCodes.find(o => o.field === fieldReferencial)
        return (findReferencialUpdateDate && findReferencialUpdateDate.updateDate) && i18n.updatedOn + getFullDate(findReferencialUpdateDate.updateDate)
    }

    const getIconUpdateSandre = updateSandre => {
        const findUpdateSandre = referencialSandreCodes.find(o => o.field === updateSandre)
        return (findUpdateSandre && findUpdateSandre.updateSandre === '1') && 'wifi'
    }

    const getUserName = userName => {
        const findUserName = referencialSandreCodes.find(o => o.field === userName)
        return (findUserName && findUserName.updateUser) ? findUserName.updateUser : i18n.user.toLowerCase()
    }

    const getReferentialNumber = referentialNumber => {
        const findReferentialNumber = referencialSandreCodes.find(o => o.field === referentialNumber)
        return (findReferentialNumber && findReferentialNumber.referentialNumber) ? `v ${findReferentialNumber.referentialNumber}` : ''
    }

    const referencialData = useMemo(() => orderBy(referencials, 'name', 'asc').map(referencial => ({
        referencialName: referencial.name,
        updateDate: getDate(referencial.id),
        updateStatus: getIconUpdateSandre(referencial.id) === '1' ? 'automatique' : 'manuelle',
        updateUser: getUserName(referencial.id).toString(),
        recordingNumber: parseInt(referencial.number.toLocaleString()),
        referencialVersion: getReferentialNumber(referencial.id),
    })), [referencials, referencialSandreCodes])

    useActions(() => {
        return {
            referencialActions: {
                actions: {
                    export: {
                        data: getDataWithHeaders(referencialData, headers),
                        titleFile: i18n.referencials,
                    },
                },
            },
        }
    }, [referencialData])

    const refNumber = useMemo(() => sandreCodes.filter(sandreCode => referencialSandreCodes.some(rsc => rsc.code && rsc.field === sandreCode.field)).length, [referencialSandreCodes, sandreCodes])

    const ref = useMemo(() => ({
        ...referencials,
        sandreCodes: {
            id: 'SANDRE_CODES',
            icon: 'list',
            url: 'sandreCodes',
            name: i18n.sandreNomenclature,
            date: '',
            number: refNumber,
            habilitation: H_REFERENCIAL_SANDRE_CODES,
        },
        lexicons: {
            id: 'LEXICONS',
            icon: 'list',
            url: 'lexicons',
            name: i18n.lexicons,
            date: '',
            number: sandreCodes.length - refNumber,
            habilitation: H_REFERENCIAL_LEXICONS,
        },
        matTypes: {
            id: 'MAT_TYPES',
            icon: 'router',
            url: 'materielTypes',
            name: i18n.materielTypes,
            date: '',
            number: materielTypes.length,
            habilitation: H_REFERENCIAL_MAT_TYPES,
        },
        managementUnits: {
            id: 'MANAGEMENT_UNITS',
            icon: 'device_hub',
            url: 'managementUnit',
            name: i18n.managementUnits,
            date: '',
            number: managementUnits.length,
            habilitation: H_REFERENCIAL_UNIT_MANAGMENT,
        },
    }), [managementUnits.length, materielTypes.length, refNumber, referencials, sandreCodes.length])

    const referencialFiltered = useMemo(() => orderBy(ref, 'name', 'asc').filter(r => componentHasHabilitations(r.habilitation)), [ref])

    return (
        <HabilitationRequired habilitation={H_REFERENCIAL_DASHBOARD}>
            <div className='padding-left-1 padding-right-1'>
                <Grid2 container spacing={1}>
                    {referencialFiltered.map(referencial => (
                        <Grid2 className='clickable' size={{ xs: 12, md: 6, lg: 4 }} onClick={() => dispatch(push(PATH_REFERENCIAL_LINK + referencial.url))} data-cy={`referencial_${referencial.name}`}>
                            <div className='card event-card'>
                                <div className='row no-margin no-padding valign-wrapper' id={lowerCase(referencial.id).replaceAll(' ', '_')} >
                                    <div className='col s9 no-padding'>
                                        <div className='no-margin padding-top-4-px event-content valign-wrapper'>
                                            <div className='col s3 center'>
                                                <Icon size='medium' icon={ referencial.icon } />
                                            </div>
                                            <div className='col s9 clickable'>
                                                <span className='black-text font-size-large bold'>{ referencial.name }</span>
                                            </div>
                                        </div>
                                        <div className='no-margin padding-top-1-px event-content valign-wrapper'>
                                            <div className='col s3 no-margin center'>
                                                <div className='col s12'>
                                                    <Icon size='small' icon='account_circle' />
                                                </div>
                                                <div className='col s12 center no-margin'>
                                                    <span className='font-size-x-small'>
                                                        { getUserName(referencial.id) }
                                                    </span>
                                                </div>
                                            </div>
                                            <div className='col s9 clickable'>
                                                <span className='font-size-x-small'>
                                                    <i>{ getDate(referencial.id) }</i>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col s3'>
                                        <div className='row no-margin no-padding event-content valign-wrapper'>
                                            <div className='col s12 right-align'>
                                                <h4 className='event-title bold'>
                                                    <CountUp end={ referencial.number } duration={ 2 } />
                                                </h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='row no-margin event-footer'>
                                    <div className='no-padding col s12' style={ { minHeight: '15px' } }>
                                        <div className='no-padding offset-s10 col s1 right-align'>
                                            <span className='black-text font-size-x-small'>
                                                <i>{ getReferentialNumber(referencial.id) }</i>
                                            </span>
                                        </div>
                                        <div className='no-padding col s1 center-align'>
                                            <span>
                                                <Icon size='tiny' icon={ getIconUpdateSandre(referencial.id) } />
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Grid2>
                    ))}
                </Grid2>
            </div>
        </HabilitationRequired>
    )
}

export default ReferencialDashboardApp
