import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import Table from '../../../../components/datatable/Table'
import DtoSelection from '../../../../referencial/components/parameter/dto/DtoSelection'
import { nbPerPageLabel } from '../../../../referencial/constants/ReferencialConstants'
import { getDate } from '../../../../utils/DateUtil'
// import i18n from 'simple-react-i18n'
import CampaignPlanningDto from '../../../dto/CampaignPlanningDto'

const PlanningsPanel = ({
    plannings = [],
    selections = [],
    editMode = false,
    onDelete = () => { },
    onUpdate = () => { },
    onDuplicate = () => { },
}) => {
    const planningsFormated = plannings.map(({ startDate, endDate, stationsPlan, parametersPlan, selection }, index) => {
        const {
            name = '',
        } = selections.find(({ code }) => code === selection) || {}
        return {
            startDate: getDate(startDate),
            endDate: getDate(endDate),
            nbStations: `${stationsPlan.length}`,
            nbParam: `${parametersPlan.length}`,
            selection: name,
            index,
        }
    })
    return (
        <div>
            <Table
                showTitle={false}
                data={planningsFormated}
                type={{ headers: ['startDate', 'endDate', 'nbStations', 'selection', 'nbParam'] }}
                sortable
                deletable={editMode}
                onDelete={({ index }) => onDelete(index)}
                alterable={editMode}
                onAlter={({ index }) => onUpdate(plannings[index], index)}
                duplicable={editMode}
                onDuplicate={({ index }) => onDuplicate(plannings[index])}
                condensed
                paging
                nbPerPageLabel={nbPerPageLabel}
            />
        </div>
    )
}

PlanningsPanel.propTypes = {
    editMode: PropTypes.bool,
    plannings: PropTypes.arrayOf(PropTypes.instanceOf(CampaignPlanningDto)),
    selections: PropTypes.arrayOf(PropTypes.instanceOf(DtoSelection)),
    onDelete: PropTypes.func,
    onUpdate: PropTypes.func,
    onDuplicate: PropTypes.func,
}

const mapStateToProps = store => ({
    selections: store.ParameterReducer.selectionsWithParam,
})

export default connect(mapStateToProps)(PlanningsPanel)