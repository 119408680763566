import { Accordion, AccordionDetails, AccordionSummary, Button, Card, CardContent, Dialog, DialogActions, DialogContent, Grid2, IconButton, Typography } from '@mui/material'
import Input from 'components/forms/Input'
import Select from 'components/forms/Select'
import React, { useEffect, useMemo, useState } from 'react'
import { SANDRE } from 'referencial/constants/ReferencialConstants'
import i18n from 'simple-react-i18n'
import useSandreList from 'utils/customHook/useSandreList'
import { orderBy, uniqBy, isNil, isNaN, keys, groupBy } from 'lodash'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import CatchmentAction from 'catchment/actions/CatchmentAction'
import { getDocumentTypeIcon, getFileExtension, getFileName, getFileNatureLabel, getFileType, getFormatedFileName } from 'utils/FileUtils'
import { getDate, getYear, getYearOrString } from 'utils/DateUtil'
import useBoolean from 'utils/customHook/useBoolean'
import ProgressBar from 'components/progress/ProgressBar'
import SimpleTabList from 'components/list/SimpleTabList'
import useTitle from 'utils/customHook/useTitle'
import { PICTURE, PICTURE_TYPES } from 'components/file/constant/FileConstants'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import EditIcon from '@mui/icons-material/Edit'
import CancelIcon from '@mui/icons-material/Cancel'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import Fancybox from 'components/fancybox/Fancybox'
import { getI18nTitleDataLength, searchAllCharacters } from 'utils/StringUtil'
import useActions from 'utils/customHook/useActions'
import { getStationArrowNav } from 'utils/ActionUtils'
import { push } from '@lagunovsky/redux-react-router'
import { DefaultDialogTitle } from 'components/styled/Dialog'
import Checkbox from 'components/forms/Checkbox'
import SimpleDatePicker from 'components/forms/SimpleDatePicker'
import moment from 'moment'
import StationAction from 'station/actions/StationAction'
import ToastrAction from 'toastr/actions/ToastrAction'
import ConfirmModal from 'components/modal/ConfirmModal'
import WaitAction from 'wait/WaitAction'
import PropTypes from 'prop-types'

const DATE = 'date'
const FILE = 'file'
const ACTIVE = 'active'

const getFileInfo = (file = {}) => {
    if (file.fileType === PICTURE) {
        return {
            sandreConstant: SANDRE.TYPE_PHOTO,
            fileTypeName: i18n.pictureType,
            image: <img src={file.url} style={{ maxWidth: '100%', maxHeight: '100%', borderRadius: '4px' }} />,
            textAlign: 'left',
        }
    }
    const ext = getFileExtension(file.name)
    return {
        sandreConstant: SANDRE.TYPE_DOCUMENT,
        fileTypeName: i18n.documentType,
        image: <img src={getDocumentTypeIcon(ext)} style={{ width: '40%' }} />,
        textAlign: 'center',
    }
}

const FilterFields = ({
    defaultFilter = {},
    onValidate = () => {},
}) => {
    const {
        files,
    } = useSelector(store => ({
        files: store.CatchmentReducer.files,
    }), shallowEqual)

    const typePictures = useSandreList(SANDRE.TYPE_PHOTO)
    const typeDocuments = useSandreList(SANDRE.TYPE_DOCUMENT)

    const [searchValue, setSearchValue] = useState(defaultFilter.searchValue)
    const [extension, setExtension] = useState(defaultFilter.extension)
    const [naturePicture, setNaturePicture] = useState(defaultFilter.naturePicture)
    const [natureDocument, setNatureDocument] = useState(defaultFilter.natureDocument)
    const [year, setYear] = useState(defaultFilter.year)
    const [active, setActive] = useState(defaultFilter.active)

    const activeOptions = useMemo(() => [
        { name: i18n.active, code: '1' },
        { name: i18n.inactive, code: '0' },
    ], [])

    const orderedTypePictures = useMemo(() => orderBy(typePictures, 'code'), [typePictures])
    const orderedTypeDocuments = useMemo(() => orderBy(typeDocuments, 'code'), [typeDocuments])

    const onSearch = () => {
        onValidate({
            searchValue,
            extension,
            naturePicture,
            natureDocument,
            year,
            active,
        })
    }

    const onReset = () => {
        setSearchValue('')
        setExtension()
        setNaturePicture()
        setNatureDocument()
        setYear()
        setActive()

        onValidate({ searchValue: '' })
    }

    const extensions = useMemo(() => uniqBy(files.map(f => {
        const ext = getFileExtension(f.name)
        return { id: ext, label: ext }
    }), 'id'), [files])

    const years = useMemo(() => {
        const formattedYears = files.map(f => {
            if (isNil(f.date) || isNaN(f.date)) return { id: -1, label: i18n.unknownDate }
            const y = getYear(f.date)
            return { id: y, label: `${y}` }
        })
        return orderBy(uniqBy(formattedYears, 'id'), 'id')
    }, [files])

    return (
        <Card>
            <CardContent>
                <Grid2 container spacing={1}>
                    <Grid2 size={2}>
                        <Input
                            title={i18n.search}
                            value={searchValue}
                            onChange={setSearchValue}
                            onEnterKeyPress={onSearch}
                        />
                    </Grid2>
                    <Grid2 size={2}>
                        <Select
                            label={i18n.extensions}
                            options={extensions}
                            value={extension}
                            onChange={setExtension}
                            keyValue='name'
                            keyLabel='name'
                            nullLabel='&nbsp;'
                            noSort
                        />
                    </Grid2>
                    <Grid2 size={2}>
                        <Select
                            label={i18n.pictureType}
                            options={orderedTypePictures}
                            value={naturePicture}
                            onChange={setNaturePicture}
                            keyValue='name'
                            keyLabel='name'
                            nullLabel='&nbsp;'
                            noSort
                        />
                    </Grid2>
                    <Grid2 size={2}>
                        <Select
                            label={i18n.documentType}
                            options={orderedTypeDocuments}
                            value={natureDocument}
                            onChange={setNatureDocument}
                            keyValue='name'
                            keyLabel='name'
                            nullLabel='&nbsp;'
                            noSort
                        />
                    </Grid2>
                    <Grid2 size={2}>
                        <Select
                            label={i18n.year}
                            options={years}
                            value={year}
                            onChange={setYear}
                            nullLabel='&nbsp;'
                            noSort
                        />
                    </Grid2>
                    <Grid2 size={2}>
                        <Select
                            label={i18n.active}
                            options={activeOptions}
                            value={active}
                            onChange={setActive}
                            nullLabel='&nbsp;'
                        />
                    </Grid2>
                    <Grid2 size={1} offset={10}>
                        <Button
                            onClick={onReset}
                            variant='outlined'
                            color='primary'
                            fullWidth
                        >
                            {i18n.reinit}
                        </Button>
                    </Grid2>
                    <Grid2 size={1}>
                        <Button
                            onClick={onSearch}
                            variant='contained'
                            color='primary'
                            fullWidth
                        >
                            {i18n.search}
                        </Button>
                    </Grid2>
                </Grid2>
            </CardContent>
        </Card>
    )
}

FilterFields.propTypes = {
    defaultFilter: PropTypes.shape({}),
    onValidate: PropTypes.func,
}

const ImportDialog = ({
    isOpen = false,
    onClose = () => {},
}) => {
    const dispatch = useDispatch()
    const {
        catchment,
        files,
    } = useSelector(store => ({
        catchment: store.CatchmentReducer.catchment,
        files: store.CatchmentReducer.files,
    }), shallowEqual)

    const [name, setName] = useState()
    const [content, setContent] = useState()

    const [fileType, setFileType] = useState(0)
    const [date, setDate] = useState()
    const [active, setActive] = useState()

    useEffect(() => {
        if (isOpen) return

        setName()
        setContent()
        setFileType(0)
        setDate()
        setActive()
    }, [isOpen])

    const onImportFile = (e) => {
        const reader = new FileReader()
        const file = e.target.files[0]
        setName(file.name)
        reader.onload = upload => setContent(upload.target.result)
        reader.readAsDataURL(file)
    }

    const { sandreCode, title } = getFileType(content)
    const fileTypes = useSandreList(sandreCode)

    const saveFile = () => {
        const now = moment().valueOf()
        const activeInt = active ? 1 : 0
        const ft = fileTypes.find(t => t.code === fileType)
        const formattedName = getFormatedFileName(catchment.code, activeInt, getFileName(name), getFileExtension(name), ft.mnemonique || ft.name, date ?? now, 'catchment')
        if (files.some(f => f.name === formattedName)) {
            dispatch(ToastrAction.warning(i18n.nameAlreadyExist))
            return
        }

        const formattedFile = {
            stationType: 11, // catchment
            stationId: catchment.id,
            path: '',
            content,
            fileType,
            active: activeInt,
            date: date ?? now,
            name: formattedName,
        }

        const type = content.includes(PICTURE_TYPES.IMAGE) ? 'picture' : 'doc'

        dispatch(WaitAction.waitStart())
        dispatch(StationAction.addStationFile(formattedFile, type)).then(() => {
            dispatch(CatchmentAction.fetchFiles(catchment.code)).finally(() => {
                dispatch(WaitAction.waitStop())
            })
        }).finally(onClose)
    }

    return (
        <Dialog open={isOpen} onClose={onClose}>
            <DefaultDialogTitle
                title={i18n.importFile}
                onClose={onClose}
            />
            <DialogContent>
                <Card>
                    <CardContent>
                        <Grid2 container rowSpacing={0.5} columnSpacing={1} alignItems='center'>
                            <Grid2 size='auto'>
                                <Button
                                    variant='contained'
                                    color='primary'
                                    component='label'
                                    fullWidth
                                >
                                    <span>{i18n.chooseFile}</span>
                                    <input
                                        type='file'
                                        hidden
                                        onChange={onImportFile}
                                    />
                                </Button>
                            </Grid2>
                            <Grid2 size='grow'>
                                <Input value={name ?? i18n.pleaseSelectFile} style={{ marginTop: '-0.5rem' }} disabled/>
                            </Grid2>
                            {!isNil(name) && (
                                <>
                                    <Grid2 size={12}>
                                        <Select
                                            label={title}
                                            options={fileTypes}
                                            value={fileType}
                                            onChange={setFileType}
                                            noNullValue
                                            noSort
                                            keyLabel='name'
                                        />
                                    </Grid2>
                                    <Grid2 size={6}>
                                        <SimpleDatePicker
                                            label={i18n.date}
                                            value={date}
                                            onChange={setDate}
                                        />
                                    </Grid2>
                                    <Grid2 size={6}>
                                        <Checkbox
                                            onChange={setActive}
                                            checked={active}
                                            label={i18n.active}
                                        />
                                    </Grid2>
                                </>
                            )}
                        </Grid2>
                    </CardContent>
                </Card>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={saveFile}
                    variant='contained'
                    color='primary'
                    disabled={isNil(content) || isNil(name) || isNil(fileType)}
                >
                    {i18n.validate}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

ImportDialog.propTypes = {
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
}

const UpdateFileDialog = ({
    isOpen = false,
    onClose = () => {},

    fileName,
}) => {
    const dispatch = useDispatch()
    const {
        catchment,
        files,
    } = useSelector(store => ({
        catchment: store.CatchmentReducer.catchment,
        files: store.CatchmentReducer.files,
    }), shallowEqual)

    const [name, setName] = useState()
    const [fileType, setFileType] = useState(0)
    const [date, setDate] = useState()
    const [active, setActive] = useState()

    const file = useMemo(() => files.find(f => f.name === fileName), [files, fileName])

    const {
        sandreConstant,
        fileTypeName,
    } = getFileInfo(file)

    const fileTypes = useSandreList(sandreConstant)

    useEffect(() => {
        if (isNil(file)) return

        const ft = fileTypes.find(t => t.mnemonique === file.type || t.name === file.type)?.code

        setName(file.shortName)
        setFileType(ft)
        setDate(file.date)
        setActive(file.active === '1')
    }, [file])

    const saveFile = () => {
        const now = moment().valueOf()
        const activeInt = active ? 1 : 0
        const ft = fileTypes.find(t => t.code === fileType)
        const formattedName = getFormatedFileName(catchment.code, activeInt, name, getFileExtension(file.name), ft.mnemonique || ft.name, date ?? now, 'catchment')
        if (file.name !== formattedName && files.some(f => f.name === formattedName)) {
            dispatch(ToastrAction.warning(i18n.nameAlreadyExist))
            return
        }

        const formattedFile = {
            ...file,
            stationType: 'CATCHMENT',
            newName: formattedName,
            fileExtension: getFileExtension(file.name),
            newType: fileType,
            fileTypeName: ft.mnemonique || ft.name,
            newDate: date ?? now,
            displayName: file.isAllDataName && catchment?.code === file.stationCode ? file.shortName : getFileName(file.name),
        }

        dispatch(WaitAction.waitStart())
        dispatch(StationAction.updateFile(formattedFile)).then(() => {
            dispatch(CatchmentAction.fetchFiles(catchment.code)).finally(() => {
                dispatch(WaitAction.waitStop())
            })
        }).finally(onClose)
    }

    return (
        <Dialog open={isOpen} onClose={onClose}>
            <DefaultDialogTitle
                title={`${i18n.update}: ${file?.shortName ?? i18n.unknown}.${getFileExtension(file?.name)}`}
                onClose={onClose}
            />
            <DialogContent>
                <Card>
                    <CardContent>
                        <Grid2 container rowSpacing={0.5} columnSpacing={1} alignItems='center'>
                            <Grid2 size={12}>
                                <Input
                                    title={i18n.name}
                                    value={name}
                                    onChange={setName}
                                />
                            </Grid2>
                            <Grid2 size={12}>
                                <Select
                                    label={fileTypeName}
                                    options={fileTypes}
                                    value={fileType}
                                    onChange={setFileType}
                                    noNullValue
                                    noSort
                                    keyLabel='name'
                                />
                            </Grid2>
                            <Grid2 size={6}>
                                <SimpleDatePicker
                                    label={i18n.date}
                                    value={date}
                                    onChange={setDate}
                                />
                            </Grid2>
                            <Grid2 size={6}>
                                <Checkbox
                                    onChange={setActive}
                                    checked={active}
                                    label={i18n.active}
                                />
                            </Grid2>
                        </Grid2>
                    </CardContent>
                </Card>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={saveFile}
                    variant='contained'
                    color='primary'
                    disabled={isNil(name) || isNil(fileType)}
                >
                    {i18n.validate}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

UpdateFileDialog.propTypes = {
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
    fileName: PropTypes.string,
}

const FileCard = ({
    file = {},
    isEditMode = false,

    onUpdate,
    onDelete,
}) => {
    const {
        catchment,
    } = useSelector(store => ({
        catchment: store.CatchmentReducer.catchment,
    }), shallowEqual)

    const displayName = file.isAllDataName && catchment?.code === file.stationCode ? file.shortName : getFileName(file.name)

    const {
        sandreConstant,
        image,
        textAlign,
    } = getFileInfo(file)

    const fileNatures = useSandreList(sandreConstant)

    return (
        <Fancybox>
            <a href={isEditMode ? undefined : file.url} target='_blank' data-fancybox={file.fileType == PICTURE && !isEditMode ? 'images' : undefined} data-caption={displayName}>
                <Card>
                    <CardContent>
                        <Grid2 container spacing={2} alignItems='center'>
                            <Grid2 size={3} textAlign={textAlign}>
                                {image}
                            </Grid2>
                            <Grid2 container size={9} rowSpacing={0} columnSpacing={0.2}>
                                <Grid2 size='grow'>
                                    <Typography
                                        style={{
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                        }}
                                        fontWeight='bold'
                                        variant='subtitle1'
                                        color='text.secondary'
                                        component='div'
                                    >
                                        {displayName}
                                    </Typography>
                                </Grid2>
                                {isEditMode && (
                                    <>
                                        <Grid2 size='auto'>
                                            <IconButton
                                                color='primary'
                                                size='small'
                                                onClick={() => onUpdate(file.name)}
                                            >
                                                <EditIcon />
                                            </IconButton>
                                        </Grid2>
                                        <Grid2 size='auto'>
                                            <IconButton
                                                color='primary'
                                                size='small'
                                                onClick={() => onDelete(file.name)}
                                            >
                                                <CancelIcon />
                                            </IconButton>
                                        </Grid2>
                                    </>
                                )}
                                <Grid2 size={12}>
                                    <Typography variant='subtitle1' color='text.secondary' component='div'>
                                        {i18n.type} : {getFileExtension(file.name)}
                                    </Typography>
                                </Grid2>
                                <Grid2 size={12}>
                                    <Typography variant='subtitle1' color='text.secondary' component='div'>
                                        {i18n.nature} : {getFileNatureLabel(file.name, fileNatures)}
                                    </Typography>
                                </Grid2>
                                <Grid2 size={12}>
                                    <Typography variant='subtitle1' color='text.secondary' component='div'>
                                        {i18n.date} : {getDate(file.date)}
                                    </Typography>
                                </Grid2>
                                <Grid2 size={6} display='flex' alignItems='center'>
                                    <Typography variant='subtitle1' color='text.secondary' component='div'>
                                        {i18n.active} :
                                    </Typography>
                                    {file.active === '1' ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />}
                                </Grid2>
                            </Grid2>
                        </Grid2>
                    </CardContent>
                </Card>
            </a>
        </Fancybox>
    )
}

FileCard.propTypes = {
    file: PropTypes.shape({}),
    isEditMode: PropTypes.bool,
    onUpdate: PropTypes.func,
    onDelete: PropTypes.func,
}

const groupFiles = (files, group, typePictures, typeDocuments) => {
    switch (group) {
        case FILE: return groupBy(files, f => {
            const listTypes = f.fileType === PICTURE ? typePictures : typeDocuments
            return listTypes.find(t => t.mnemonique === f.type || t.name === f.type)?.name
        })
        case ACTIVE: return groupBy(files, file => file.active === '1' ? i18n.active : i18n.inactive)
        case DATE:
        default: return groupBy(files, file => getYearOrString(file.date, i18n.thisYear, ''))
    }
}

const CatchmentDocumentApp = ({

}) => {
    const dispatch = useDispatch()

    const {
        files,
        catchment,
        catchments,
    } = useSelector(store => ({
        files: store.CatchmentReducer.files,
        catchment: store.CatchmentReducer.catchment,
        catchments: store.CatchmentReducer.catchments,
    }), shallowEqual)

    const typePictures = useSandreList(SANDRE.TYPE_PHOTO)
    const typeDocuments = useSandreList(SANDRE.TYPE_DOCUMENT)

    const [filter, setFilter] = useState({ searchValue: '' })

    const [selectedFile, setSelectedFile] = useState()

    const {
        value: isLoaded,
        setTrue: onLoaded,
    } = useBoolean(false)
    const {
        value: isEditMode,
        setTrue: onEdit,
        setFalse: onRead,
    } = useBoolean(false)
    const {
        value: isNewFileOpen,
        setTrue: onNewFileOpen,
        setFalse: onNewFileClose,
    } = useBoolean(false)
    const {
        value: isUpdateFileOpen,
        setTrue: onUpdateFileOpen,
        setFalse: onUpdateFileClose,
    } = useBoolean(false)
    const {
        value: isConfirmOpen,
        setTrue: onConfirmOpen,
        setFalse: onConfirmClose,
    } = useBoolean(false)

    useEffect(() => {
        if (!catchment.code) return
        dispatch(CatchmentAction.fetchFiles(catchment.code))
            .finally(onLoaded)
    }, [catchment.code, dispatch])

    useTitle(() => [{
        title: i18n.catchments,
        href: 'catchment',
    }, {
        title: catchment.code || i18n.unknown,
        href: `station/catchment/${catchment.id}`,
    }, {
        title: i18n.picturesAndDocuments,
        href: `station/catchment/${catchment.id}/document`,
    }], [catchment])

    useActions(() => {
        const baseActions = {
            arrowNav: getStationArrowNav('catchment', catchments, catchment.id, s => dispatch(push(`/station/catchment/${s.id}/description`))),
        }
        if (isEditMode) {
            return {
                cancel: onRead,
                new: onNewFileOpen,
                ...baseActions,
            }
        }
        return {
            edit: onEdit,
            ...baseActions,
        }
    }, [isEditMode, catchments, catchment])

    const filteredFiles = useMemo(() => {
        const {
            searchValue,
            extension,
            naturePicture,
            natureDocument,
            year,
            active,
        } = filter

        const search = searchAllCharacters(searchValue)
        const typePicture = typePictures.find(t => t.mnemonique === naturePicture || t.name === naturePicture)
        const typeDocument = typeDocuments.find(t => t.mnemonique === natureDocument || t.name === natureDocument)

        const filterByName = search ? files.filter(f => searchAllCharacters(f.shortName).includes(search)) : files
        const filterByActive = active ? filterByName.filter(f => f.active === active) : filterByName
        const filterByYear = year ? filterByActive.filter(f => getYear(f.date) === year) : filterByActive
        const filterByExtension = extension ? filterByYear.filter(f => getFileExtension(f.name) === extension) : filterByYear
        const filterByNaturePicture = naturePicture ? filterByExtension.filter(f => {
            return f.fileType !== PICTURE || typePicture.mnemonique === f.type || typePicture.name === f.type
        }) : filterByExtension
        const filterByNatureDocument = natureDocument ? filterByNaturePicture.filter(f => {
            return f.fileType === PICTURE || typeDocument.mnemonique === f.type || typeDocument.name === f.type
        }) : filterByNaturePicture
        return filterByNatureDocument
    }, [filter, files, typePictures, typeDocuments])

    return (
        <Grid2 container style={{ padding: '5 5 100 5' }} spacing={1} direction='column'>
            <Grid2>
                <FilterFields
                    defaultFilter={filter}
                    onValidate={setFilter}
                />
            </Grid2>
            <Grid2>
                <SimpleTabList
                    defaultTab={DATE}
                    tabs={[
                        {
                            constant: DATE,
                            label: i18n.date,
                            icon: 'insert_invitation',
                        },
                        {
                            constant: FILE,
                            label: i18n.files,
                            icon: 'insert_drive_file',
                        },
                        {
                            constant: ACTIVE,
                            label: i18n.active,
                            icon: 'remove_red_eye',
                        },
                    ]}
                >
                    {
                        tab => {
                            if (!isLoaded) {
                                return (
                                    <div style={{ padding: '10px' }}>
                                        <ProgressBar indeterminate withMessage />
                                    </div>
                                )
                            }
                            if (filteredFiles.length === 0) {
                                return (
                                    <div className='center font-size-20'>{i18n.noResults}</div>
                                )
                            }
                            const groups = groupFiles(filteredFiles, tab, typePictures, typeDocuments)
                            return (
                                <Grid2 container spacing={1} padding='10px'>
                                    {keys(groups).reverse().map((key, i) => (
                                        <Grid2 key={key} size={12}>
                                            <Accordion defaultExpanded={i === 0}>
                                                <AccordionSummary>
                                                    {`${key} (${groups[key].length} ${getI18nTitleDataLength(i18n.element, i18n.elements, groups[key].length)})`}
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <Grid2 container spacing={1}>
                                                        {groups[key].map(f => (
                                                            <Grid2 key={f.name} size={3}>
                                                                <FileCard
                                                                    file={f}
                                                                    isEditMode={isEditMode}
                                                                    onUpdate={name => {
                                                                        setSelectedFile(name)
                                                                        onUpdateFileOpen()
                                                                    }}
                                                                    onDelete={name => {
                                                                        setSelectedFile(name)
                                                                        onConfirmOpen()
                                                                    }}
                                                                />
                                                            </Grid2>
                                                        ))}
                                                    </Grid2>
                                                </AccordionDetails>
                                            </Accordion>
                                        </Grid2>
                                    ))}
                                </Grid2>
                            )
                        }
                    }
                </SimpleTabList>
                <ImportDialog isOpen={isNewFileOpen} onClose={onNewFileClose}/>
                <UpdateFileDialog isOpen={isUpdateFileOpen} onClose={onUpdateFileClose} fileName={selectedFile}/>
                <ConfirmModal
                    isOpen={isConfirmOpen}
                    title={i18n.deletingConfirmation}
                    subTitle={selectedFile}
                    onClose={onConfirmClose}
                    onValidate={() => {
                        const file = files.find(f => f.name === selectedFile)
                        dispatch(WaitAction.waitStart())
                        dispatch(StationAction.deleteFile(file)).then(() => {
                            dispatch(CatchmentAction.fetchFiles(catchment.code)).finally(() => {
                                dispatch(WaitAction.waitStop())
                            })
                        }).finally(onConfirmClose)
                    }}
                />
            </Grid2>
        </Grid2>
    )
}

export default CatchmentDocumentApp