import React from 'react'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import { Button, Dialog, Grid } from '@mui/material'
import NumberField from '../../../../components/forms/NumberField'
import Select from 'components/forms/Select'
import Checkbox from 'components/forms/Checkbox'
import DtoManagementUnitStationLink from '../dto/DtoManagementUnitStationLink'
import { DialogActionsMUI, DialogContentMUI, DialogTitleMUI } from 'components/styled/Dialog'
import { shallowEqual, useSelector } from 'react-redux'
import Icon from 'components/icon/Icon'

const VolumesManagementUnitDialog = ({
    linkStationToUpdate,
    openDialog,
    onChange = () => { },
    validUpdate = () => { },
    closeDialog = () => { },
}) => {
    const { stationType, stationId, real, coeffReal, previ, coeffPrevi } = linkStationToUpdate
    const {
        piezometersLight,
        hydrometricStations,
        pluviometers,
    } = useSelector(store => ({
        piezometersLight: store.PiezometryReducer.piezometersLight,
        hydrometricStations: store.HydrometryReducer.hydrometricStations,
        pluviometers: store.PluviometryReducer.pluviometers,
    }), shallowEqual)

    const getListStations = () => {
        switch (stationType) {
            case 1:
                return piezometersLight.map((s) => ({ ...s, nameWithCode: `[${s.code || ''}] ${s.name || ''}` }))
            case 2:
                return hydrometricStations.map((s) => ({ ...s, nameWithCode: `[${s.code || ''}] ${s.name || ''}` }))
            case 4:
                return pluviometers.map((s) => ({ ...s, nameWithCode: `[${s.code || ''}] ${s.name || ''}` }))
            default:
                return []
        }
    }

    return (
        <Dialog
            fullWidth
            maxWidth='md'
            onClose={closeDialog}
            open={openDialog}
        >
            <DialogTitleMUI style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                {i18n.editAssociatedStations}
                <Icon style={{ color: 'white' }} size='small' icon='close' onClick={closeDialog} />
            </DialogTitleMUI>
            <DialogContentMUI>
                <Grid container className='padding-top-1' spacing={1} alignItems='center' justifyContent='flex-start'>
                    <Grid item xs={6}>
                        <Select
                            col={12}
                            label={i18n.type}
                            options={[{
                                value: 1,
                                label: i18n.piezometry,
                            }, {
                                value: 2,
                                label: i18n.hydrometry,
                            }, {
                                value: 4,
                                label: i18n.pluviometry,
                            }]}
                            value={stationType}
                            onChange={(value) => onChange({
                                stationType: value,
                                stationId: null,
                                ...(value !== 4 ? {
                                    real: false,
                                    coeffReal: null,
                                    previ: false,
                                    coeffPrevi: null,
                                } : {}),
                            })}
                            noNullValue
                            obligatory
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Select
                            col={12}
                            label={i18n.station}
                            options={getListStations()}
                            value={stationId}
                            keyValue='id'
                            keyLabel='nameWithCode'
                            onChange={(value) => onChange({ stationId: value })}
                            noNullValue
                            obligatory
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={1} alignItems='center' justifyContent='flex-start'>
                    <Grid item xs={2}>
                        <Checkbox
                            col={12}
                            label={i18n.historic}
                            checked={real}
                            onChange={(value) => onChange({ real: value })}
                            disabled={stationType !== 4}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <NumberField
                            col={12}
                            title={i18n.coeffHisto}
                            value={coeffReal}
                            onChange={(value) => onChange({ coeffReal: value })}
                            disabled={!real || stationType !== 4}
                            floatValue
                            min={0}
                            max={1}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={1} alignItems='center' justifyContent='flex-start'>
                    <Grid item xs={2}>
                        <Checkbox
                            col={12}
                            label={i18n.scenario}
                            checked={previ}
                            onChange={(value) => onChange({ previ: value })}
                            disabled={stationType !== 4}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <NumberField
                            col={12}
                            title={i18n.coeffScena}
                            value={coeffPrevi}
                            onChange={(value) => onChange({ coeffPrevi: value })}
                            disabled={!previ || stationType !== 4}
                            floatValue
                            min={0}
                            max={1}
                        />
                    </Grid>
                </Grid>
            </DialogContentMUI>
            <DialogActionsMUI>
                <Button onClick={validUpdate} variant='contained' color='primary'>
                    {i18n.validate}
                </Button>
            </DialogActionsMUI>
        </Dialog>
    )
}

VolumesManagementUnitDialog.propTypes = {
    linkStationToUpdate: PropTypes.instanceOf(DtoManagementUnitStationLink),
    openDialog: PropTypes.bool,
    onChange: PropTypes.func,
    validUpdate: PropTypes.func,
    closeDialog: PropTypes.func,
}

export default VolumesManagementUnitDialog