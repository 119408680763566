import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import { getSandreLabel } from '../../../../utils/StringUtil'
import { getYear } from '../../../../utils/DateUtil'
import { SANDRE } from '../../../../referencial/constants/ReferencialConstants'
import Row from '../../../../components/react/Row'
import Input from '../../../../components/forms/Input'
import DatePicker from '../../../../components/forms/DatePicker'
import Select from '../../../../components/forms/Select'
import Textarea from '../../../../components/forms/Textarea'
import DtoInstallation from '../../../dto/installation/DtoInstallation'
import { arrayOf, getSandreList } from '../../../../utils/StoreUtils'
import DtoInstallationType from '../../../dto/installation/DtoInstallationType'
import DtoSandreCode from '../../../../referencial/dto/DtoSandreCode'
import InstallationAction from '../../../actions/InstallationAction'
import ContributorDto from '../../../../referencial/components/contributor/dto/ContributorDto'
import DtoIndustrialSite from '../../industrialSite/dto/DtoIndustrialSite'
import { max, orderBy } from 'lodash'


class InstallationInfosDashboard extends Component {
    componentDidMount() {
        if (!this.props.installationsTypes.length) {
            this.props.fetchInstallationTypes()
        }
    }

    getContributors = (installation) => {
        const { link_contributors: stationContributors } = installation
        if (stationContributors?.length) {
            const maxStartDate = (max(stationContributors.map(sc => sc.startDate)))
            const space = <span className='padding-left-3' />
            const data = orderBy(stationContributors, 'startDate').map(c => {
                const contributor = this.props.contributors.find(cs => cs.id === c.idContributor)
                const type = getSandreLabel(this.props.sandreCodes, SANDRE.CONTRIBUTOR_TYPES, c.contributorType)
                return contributor && (
                    <h6>{getYear(c.startDate || maxStartDate)} {space} {contributor.mnemonique || contributor.name} {space} {type} {space} {c.internalReference || null}</h6>
                ) || <h6>{c.startDate && getYear(c.startDate) || ''} {c.startDate && space} {c.contributorType && type || ''} {c.contributorType && space} {c.internalReference || ''}</h6>
            })
            return (
                <Row>
                    <h6 className='bold'>{`${i18n.contributors}:`}</h6>
                    {data}
                </Row>
            )
        }
        return null
    }

    render() {
        const { installation, industrialSite } = this.props
        const readMode = { readMode: true, editMode: false, hideNull: true }

        return (
            <Row>
                <Row>
                    <div className='col s12'>
                        <Select
                            col={12}
                            value={installation.installationType}
                            label={i18n.installationType}
                            options={this.props.installationsTypes}
                            {...readMode}
                        />
                    </div>
                    <div className='col s4'>
                        <Input
                            col={12}
                            value={installation.code}
                            title={i18n.code}
                            disabled
                            {...readMode}
                        />
                        <Input
                            col={12}
                            value={installation.name}
                            title={i18n.name}
                            disabled
                            {...readMode}
                        />
                        <Input
                            col={12}
                            value={industrialSite.siret}
                            title={i18n.siret}
                            disabled
                            {...readMode}
                        />
                        <DatePicker
                            col={12}
                            value={installation.creationDate}
                            id='creationDate'
                            title={i18n.creationDate}
                            endDate={installation.endDate}
                            {...readMode}
                        />
                        <Input
                            col={12}
                            value={installation.declarationNumber}
                            title={i18n.declarationNumber}
                            {...readMode}
                        />
                    </div>
                    <div className='col s4'>
                        <Input
                            col={12}
                            value={installation.id}
                            title={i18n.internalIdentifier}
                            {...readMode}
                        />
                        <Input
                            col={12}
                            value={installation.mnemonic}
                            title={i18n.mnemonic}
                            {...readMode}
                        />
                        <Select
                            col={12}
                            value={installation.stateCode}
                            label={i18n.state}
                            options={getSandreList(this.props.sandreCodes, SANDRE.CODE_ETAT)}
                            {...readMode}
                        />
                    </div>
                    <div className='col s4'>
                        {this.getContributors(installation)}
                    </div>
                </Row>
                <Row>
                    <div className='col s12'>
                        <Textarea
                            col={12}
                            value={installation.descriptive}
                            title={i18n.descriptive}
                            {...readMode}
                        />
                    </div>
                </Row>
            </Row>
        )
    }
}

InstallationInfosDashboard.propTypes = {
    installationsTypes: arrayOf(DtoInstallationType),
    installation: PropTypes.instanceOf(DtoInstallation),
    sandreCodes: arrayOf(DtoSandreCode),
    contributors: arrayOf(ContributorDto),
    fetchInstallationTypes: PropTypes.func,
    industrialSite: PropTypes.instanceOf(DtoIndustrialSite),
}

const mapStateToProps = store => ({
    installation: store.InstallationReducer.installation,
    sandreCodes: store.ReferencialReducer.sandreCodes,
    installationsTypes: store.InstallationReducer.installationsTypes,
    contributors: store.ContributorReducer.contributors,
    industrialSite: store.InstallationReducer.industrialSite,
})

const mapDispatchToProps = {
    fetchInstallationTypes: InstallationAction.fetchInstallationTypes,
}

export default connect(mapStateToProps, mapDispatchToProps)(InstallationInfosDashboard)