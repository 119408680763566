export default class PiezometerAccessibilitieDto {
    constructor(obj = {}) {
        this.id = obj.idStation
        this.idPiezo = obj.idStation
        this.startDate = obj.startDate
        this.endDate = obj.endDate
        this.description = obj.description
        this.codeCondition = obj.codeCondition
        this.closingCode = obj.closingCode
        this.contactCode = obj.contactCode
        this.order = obj.order
    }
}
