import DtoElecmecEquipment from 'installation/dto/installation/DtoElecmecEquipment'

export default class DtoTreatmentStation {
    constructor(obj) {
        this.idStation = obj.idStation
        this.monitoringProgram = obj.monitoringProgram
        this.exemptionOrder = obj.exemptionOrder
        this.nominalCapacity = obj.nominalCapacity
        this.nominalHourlyCapacity = obj.nominalHourlyCapacity
        this.treatedParams = obj.treatedParams
        this.processingSector = obj.processingSector
        this.autorisationDecree = obj.autorisationDecree
        this.autorisationDecreeDate = obj.autorisationDecreeDate
        this.numDecree = obj.numDecree
        this.decreeDuration = obj.decreeDuration
        this.rejectAutorisation = obj.rejectAutorisation
        this.rejectAutorisationDate = obj.rejectAutorisationDate

        this.link_elecmecEquipments = obj.link_elecmecEquipments ? obj.link_elecmecEquipments.map(ee => new DtoElecmecEquipment(ee)) : []
    }
}