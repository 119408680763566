import { orderBy } from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import ToastrAction from 'toastr/actions/ToastrAction'
import DatePicker from '../../../../../components/forms/DatePicker'
import Select from '../../../../../components/forms/Select'
import Textarea from '../../../../../components/forms/Textarea'
import Row from '../../../../../components/react/Row'
import DtoSandreCode from '../../../../../referencial/dto/DtoSandreCode'
import { hasValue } from '../../../../../utils/NumberUtil'
import { arrayOf } from '../../../../../utils/StoreUtils'
import { searchAllCharacters } from '../../../../../utils/StringUtil'
import DtoInstallationEquipment from '../../../../dto/installation/DtoInstallationEquipment'

class InstallationEquipmentsModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            equipment: { ...props.equipment, idStation: props.id },
        }
    }

    componentDidUpdate(prevProps) {
        if (!prevProps.popupIsOpen && this.props.popupIsOpen) {
            $('#descriptionModal').on('click', this.onSave)
        }
    }

    onSave = () => {
        const { equipment } = this.state
        if (!hasValue(equipment.equipmentCode)) {
            this.props.error(i18n.undefinedEquipment)
            return
        }

        this.props.saveResult(new DtoInstallationEquipment({ ...equipment }))
        $('.modal').modal('close')
    }

    onChangeElement = (changes) => this.setState({ equipment: { ...this.state.equipment, ...changes } })

    render() {
        const { equipment } = this.state
        return (
            <div>
                <Row displayIf={!!this.props.isNew}>
                    <Select
                        col={6}
                        value={equipment.equipmentCode}
                        label={i18n.name}
                        options={ orderBy(this.props.availableEquipments, sandreCode => searchAllCharacters(sandreCode.name)) }
                        onChange={v => this.onChangeElement({ equipmentCode: v })}
                        integerValue
                    />
                </Row>
                <Row>
                    <DatePicker
                        col={6}
                        value={equipment.startDate}
                        id='startDate'
                        title={i18n.installation}
                        onChange={(_, v) => this.onChangeElement({ startDate: v })}
                    />
                    <DatePicker
                        col={6}
                        value={equipment.endDate}
                        id='endDate'
                        title={i18n.withdrawal}
                        onChange={(_, v) => this.onChangeElement({ endDate: v })}
                    />
                </Row>
                <Row noMargin={false} className='margin-top-1'>
                    <Textarea
                        col={12}
                        title={i18n.comment}
                        value={equipment.comment}
                        onChange={v => this.onChangeElement({ comment: v })}
                    />
                </Row>
            </div>
        )
    }
}

InstallationEquipmentsModal.propTypes = {
    equipment: PropTypes.instanceOf(DtoInstallationEquipment),
    availableEquipments: arrayOf(DtoSandreCode),
    id: PropTypes.number,
    popupIsOpen: PropTypes.bool,
    saveResult: PropTypes.func,
    error: PropTypes.func,
    isNew: PropTypes.bool,
}

const mapStateToProps = store => ({
    popupIsOpen: store.SieauReducer.popupIsOpen,
})

const mapDispatchToProps = {
    error: ToastrAction.error,
}

export default connect(mapStateToProps, mapDispatchToProps)(InstallationEquipmentsModal)
