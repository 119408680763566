import React, { Component } from 'react'
import i18n from 'simple-react-i18n'
import PropTypes from 'prop-types'
import Card from '../../../../components/card/Card'
import Row from '../../../../components/react/Row'
import Input from '../../../../components/forms/Input'
import { onChangeDate, onChangeHour, setConfirmationModal, setModal } from '../../../../utils/FormUtils'
import Button from '../../../../components/forms/Button'
import { getDate, getHour } from '../../../../utils/DateUtil'
import { connect } from 'react-redux'
import { arrayOf, getLabel, instanceOf } from '../../../../utils/StoreUtils'
import AppStore from '../../../../store/AppStore'
import PiezometerStationAction from '../../../../station/actions/PiezometerStationAction'
import DtoPiezometer from '../../../dto/DtoPiezometer'
import DtoPiezometryStationMeasure from '../../../../station/dto/piezometer/DtoPiezometryStationMeasure'
import SelectedMeasurePanel from './SelectedMeasurePanel'
import { isNil, minBy, partition, pick } from 'lodash'
import RadioButtons from '../../../../components/forms/RadioButtons'
import { getUser } from '../../../../utils/SettingUtils'
import DtoEvent from '../../../../events/dto/DtoEvent'
import TechniqueEventCard from '../../../../station/components/event/TechniqueEventCard'
import PiezometryAction from '../../../actions/PiezometryAction'
import { WhiteCard } from '../../../../components/styled/Card'
import { StyledFieldSet, StyledLegend } from '../../../../components/StyledElements'
import { Collapse } from '@mui/material'
import Select from '../../../../components/forms/Select'
import { getQualificationSelectOptions, getStatusSelectOptions } from '../../../../utils/StatusUtil'
import NumberField from '../../../../components/forms/NumberField'
import MultiContributorsAutocomplete
    from '../../../../referencial/components/contributor/components/MultiContributorsAutocomplete'
import { hasValue } from '../../../../utils/NumberUtil'
import { getMeasureValue } from '../../../../utils/PiezometryUtils'
import DtoContributor from '../../../../station/dto/DtoContributor'

class DeleteToolPanel extends Component {
    constructor() {
        super()
        this.state = {
            validateActive: false,
            updatedMeasures: [],
            deletedMeasures: [],
            deleteMode: 'reset',
            seeMoreCriterias: false,
            filterStatus: undefined,
            filterQualification: undefined,
            filterSuperior: undefined,
            filterInferior: undefined,
            filterProducer: undefined,
            filterManager: undefined,
            filterValidator: undefined,
        }
    }

    onValidate = () => {
        const start = this.props.dates.startDate
        const end = this.props.dates.endDate

        switch (this.state.deleteMode) {
            case 'purge':
                const notPurgedMeasures = this.props.measures.filter(m => !this.filterMeasure(m, start, end))
                if (notPurgedMeasures.length !== this.props.measures.length) {
                    this.setState({ validateActive: true })
                    this.props.changeParent({ measures: notPurgedMeasures })
                }
                break
            case 'delete':
                const [deletedMeasures, measures] = partition(this.props.measures, m => this.filterMeasure(m, start, end))
                const measureRuptureIdx = measures.findIndex(m => m.date > end)
                if (measureRuptureIdx !== -1) {
                    measures[measureRuptureIdx].initialPoint = 1
                    measures[measureRuptureIdx].updated = true
                }
                if (measures.length !== this.props.measures.length) {
                    this.setState({ validateActive: true, deletedMeasures, updatedMeasures: measureRuptureIdx !== -1 ? [measures[measureRuptureIdx]] : [] })
                    this.props.changeParent({ measures })
                }
                break
            case 'reset':
                const resetMeasures = this.props.measures.map(m => {
                    if (this.filterMeasure(m, start, end)) {
                        return { ...m, updated: true, value: m.initialValue, NGF: m.initialNGF, status: 1, qualification: 4 }
                    }
                    return m
                })
                this.setState({ validateActive: true, updatedMeasures: resetMeasures.filter(m => m.updated) })
                this.props.changeParent({ measures: resetMeasures })
                break
            default:
                break
        }
    }

    getSelectFilters = () => {
        return {
            ...pick(this.state, ['filterStatus', 'filterQualification', 'filterSuperior', 'filterInferior', 'filterProducer', 'filterManager', 'filterValidator']),
            displayCote: this.props.displayCote,
            landmarkValue: this.props.lastLandmark,
        }
    }

    onResetMeasures = () => {
        AppStore.dispatch(PiezometerStationAction.resetPiezometerMeasures(this.props.piezometer.id, this.props.dates.startDate, this.props.dates.endDate, this.props.selectedDataType, this.getSelectFilters(), this.props.selectedCodePoint, () => {
            this.props.reloadData()
            this.setState({ validateActive: false })
            this.props.createTechnicalEvent(this.props.measures, this.state)
            AppStore.dispatch(PiezometryAction.refreshOnePiezometerSituation(this.props.piezometer.id, this.props.selectedDataType))
        }))
    }

    onSave = () => {
        switch (this.state.deleteMode) {
            case 'purge':
                setConfirmationModal(() => AppStore.dispatch(PiezometerStationAction.purgePiezometerRawMeasures(
                    this.props.piezometer.id, this.props.dates, this.props.selectedDataType, this.getSelectFilters(), this.props.selectedCodePoint, () => {
                        const newMeasures = this.props.measures.map(m => ({ ...m, updated: false }))
                        this.setState({ validateActive: false })
                        this.props.createTechnicalEvent(this.props.measures, this.state)
                        this.props.changeParent({ measures: newMeasures, initialMeasures: newMeasures })
                        AppStore.dispatch(PiezometryAction.refreshOnePiezometerSituation(this.props.piezometer.id, this.props.selectedDataType))
                    })), i18n.purgeRawMeasuresConfirmation)
                break
            case 'delete':
                setConfirmationModal(() => AppStore.dispatch(PiezometerStationAction.deletePiezometerTypeMeasures(
                    this.props.piezometer.id, this.state.deletedMeasures, this.state.updatedMeasures, this.props.selectedDataType, () => {
                        const newMeasures = this.props.measures.map(m => ({ ...m, updated: false }))
                        this.setState({ validateActive: false })
                        this.props.createTechnicalEvent(this.props.measures, this.state)
                        this.props.changeParent({ measures: newMeasures, initialMeasures: newMeasures })
                        AppStore.dispatch(PiezometryAction.refreshOnePiezometerSituation(this.props.piezometer.id, this.props.selectedDataType))
                    })), i18n.deleteMeasuresConfirmation)
                break
            case 'reset':
                const start = this.props.dates.startDate
                const end = this.props.dates.endDate
                const deletedEvents = this.props.stationEvents.filter(e => e.eventType === 'T' && e.problem && e.problem.includes('tool') && e.startDate && e.endDate
                    && ((e.startDate >= start && e.startDate <= end) || (e.endDate >= start && e.endDate <= end)))
                setModal({
                    title: i18n.resetConfirmation,
                    content: (
                        <div>
                            <Row>
                                <h5>{ this.state.updatedMeasures.length } mesures seront remises à leur valeur initiale et à l'état brut non qualifié.</h5>
                            </Row>
                            <Row>
                                <h5>{ deletedEvents.length } opérations sur les données seront retirées de l'historique.</h5>
                            </Row>
                            <Card title={ `${deletedEvents.length} ${i18n.operations}` } maxHeight={ 400 }>
                                { deletedEvents.map(e => (<TechniqueEventCard
                                    event={ e }
                                    shortDate
                                />))
                                }
                            </Card>
                            <Row className='padding-top-1'>
                                <Button className='right modal-close' title={ i18n.confirm } onClick={ this.onResetMeasures }/>
                                <div className='padding-left-1'/>
                                <Button className='right red modal-close' title={ i18n.cancel }/>
                            </Row>
                        </div>
                    ),
                })
                break
            default:
                break
        }
    }

    withStatus = (m) => hasValue(this.state.filterStatus) ? m.status === this.state.filterStatus : true
    withQualification = (m) => hasValue(this.state.filterQualification) ? m.qualification === this.state.filterQualification : true
    withSuperior = (m) => {
        if (hasValue(this.state.filterSuperior)) {
            return isNil(m.NGF) ? m.value > this.state.filterSuperior : getMeasureValue({ NGF: m.NGF }, this.props.displayCote, this.props.lastLandmark, this.props.groundRefAlti) > this.state.filterSuperior
        } return true
    }
    withInferior = (m) => {
        if (hasValue(this.state.filterInferior)) {
            return isNil(m.NGF) ? m.value < this.state.filterInferior : getMeasureValue({ NGF: m.NGF }, this.props.displayCote, this.props.lastLandmark, this.props.groundRefAlti) < this.state.filterInferior
        } return true
    }
    withProducer = (m) => hasValue(this.state.filterProducer) ? m.producer === this.state.filterProducer : true
    withManager = (m) => hasValue(this.state.filterManager) ? m.manager === this.state.filterManager : true
    withValidator = (m) => hasValue(this.state.filterValidator) ? m.validator === this.state.filterValidator : true
    withSuperiorAndInferior = (m) => {
        if (hasValue(this.state.filterInferior) && hasValue(this.state.filterSuperior)) {
            const value = isNil(m.NGF) ? m.value : getMeasureValue({ NGF: m.NGF }, this.props.displayCote, this.props.lastLandmark, this.props.groundRefAlti)
            return value < this.state.filterInferior || value > this.state.filterSuperior
        }
        return (this.withInferior(m) && this.withSuperior(m))
    }
    filterMeasure = (m, start, end) => (m.date >= start) && (m.date <= end) && this.withStatus(m) && this.withQualification(m) && this.withSuperiorAndInferior(m) && this.withProducer(m) && this.withManager(m) && this.withValidator(m)

    onCancel = () => {
        this.props.onCancel()
        this.setState({ validateActive: false, updatedMeasures: [], deletedMeasures: [] })
    }

    getSelectedPanel = () => {
        const { dates } = this.props
        if (dates.startDate && dates.endDate) {
            const measures = this.props.measures.filter(m => m.date >= dates.startDate && m.date <= dates.endDate)
            if (measures.length) {
                return <SelectedMeasurePanel measure={ minBy(measures, 'date') } message={ i18n.firstMeasureDetails } displayCote={ this.props.displayCote } lastLandmark={ this.props.lastLandmark } groundRefAlti={ this.props.groundRefAlti }/>
            }
        }
        return null
    }

    render() {
        const { changeParent, dates } = this.props
        const deleteModes = [
            { code: 'reset', name: i18n.reseting },
            { code: 'delete', name: i18n.deleting },
            getUser().isAdmin === '1' ? { code: 'purge', name: i18n.purge } : null,
        ].filter(m => !!m)
        const view = this.props.tableView ? 'tableau' : 'graphique'

        const startDateTime = dates.startDate
        const endDateTime = dates.endDate
        const selectedMeasures = this.props.measures.filter(m => this.filterMeasure(m, startDateTime, endDateTime)).length

        return (
            <div>
                <WhiteCard title={ i18n.deleteMode } round>
                    <Row>
                        <RadioButtons col={ 12 } elements={ deleteModes } onChange={ v => this.setState({ deleteMode: v }) } selected={ this.state.deleteMode } disabled={ this.state.validateActive }/>
                    </Row>
                </WhiteCard>
                <div className='padding-top-1'/>
                <WhiteCard title={ getLabel(deleteModes, this.state.deleteMode) } round>
                    <StyledFieldSet style={{ margin: 10, backgroundColor: '#d9dfe4' }}>
                        <StyledLegend>{i18n.selectionCriteria}</StyledLegend>
                        <Row className='padding-bottom-1 valign-wrapper'>
                            <Button tooltip='Sélectionner une plage' icon='settings_ethernet' className='margin-left-1 validToolBtn' disabled={ this.state.validateActive }
                                onClick={ () => this.props.changeParent({ selectionMode: 'start', startDate: null, endDate: null }) }
                            />
                            <h6 className='padding-left-1'>{
                                this.props.selectionMode === 'start' ? `Sélectionnez la date de début sur le ${view}.` : (
                                    this.props.selectionMode === 'end' ? `Sélectionnez la date de fin sur le ${view}.` : (
                                        startDateTime && endDateTime && selectedMeasures > 0 && (`${selectedMeasures} ${selectedMeasures > 1 ? i18n.selectedMeasures : i18n.selectedMeasure}`)
                                    )
                                )
                            }</h6>
                        </Row>
                        <Row>
                            <Input col={ 6 } title={ i18n.startDate } value={ getDate(dates.startDate) } obligatory
                                onChange={ v => onChangeDate(v, v2 => changeParent({ startDate: v2 }), { max: dates.endDate }, dates.startDate) }
                            />
                            <Input col={ 6 } title={ i18n.startHour } value={ getHour(dates.startDate) } obligatory
                                onChange={ v => onChangeHour(v, v2 => changeParent({ startDate: v2 }), { max: dates.endDate }, dates.startDate) }
                            />
                        </Row>
                        <Row>
                            <Input col={ 6 } title={ i18n.endDate } value={ getDate(dates.endDate) } obligatory
                                onChange={ v => onChangeDate(v, v2 => changeParent({ endDate: v2 }), { min: dates.startDate }, dates.endDate) }
                            />
                            <Input col={ 6 } title={ i18n.endHour } value={ getHour(dates.endDate) } obligatory
                                onChange={ v => onChangeHour(v, v2 => changeParent({ endDate: v2 }), { min: dates.startDate }, dates.endDate) }
                            />
                        </Row>
                        <Collapse in={this.state.seeMoreCriterias}>
                            <Row>
                                <Select col={ 6 } label={ i18n.status } value={ this.state.filterStatus } noSort
                                    onChange={ v => this.setState({ filterStatus: v }, this.saveValidationFilters) } options={ getStatusSelectOptions() }
                                />
                                <Select col={ 6 } label={ i18n.qualification } value={ this.state.filterQualification } noSort
                                    onChange={ v => this.setState({ filterQualification: v }, this.saveValidationFilters) } options={ getQualificationSelectOptions() }
                                />
                            </Row>
                            <Row>
                                <NumberField col={ 6 } title={i18n.valeurSup} value={ this.state.filterSuperior } noSort
                                    onChange={ v => this.setState({ filterSuperior: v }, this.saveValidationFilters) } floatValue
                                />
                                <NumberField col={ 6 } title={i18n.valeurInf} value={ this.state.filterInferior } noSort
                                    onChange={ v => this.setState({ filterInferior: v }, this.saveValidationFilters) } floatValue
                                />
                            </Row>
                            <Row>
                                <MultiContributorsAutocomplete
                                    col={12}
                                    label={i18n.producer}
                                    onChange={v => this.setState({ filterProducer: v }, this.saveValidationFilters)}
                                    values={this.state.filterProducer}
                                    options={this.props.contributors}
                                />
                            </Row>
                            <Row>
                                <MultiContributorsAutocomplete
                                    col={6}
                                    label={i18n.administrator}
                                    onChange={v => this.setState({ filterManager: v }, this.saveValidationFilters)}
                                    values={this.state.filterManager}
                                    options={this.props.contributors}
                                />
                                <MultiContributorsAutocomplete
                                    col={6}
                                    label={i18n.validator}
                                    values={this.state.filterValidator}
                                    onChange={v => this.setState({ filterValidator: v }, this.saveValidationFilters)}
                                    options={this.props.contributors}
                                />
                            </Row>
                        </Collapse>
                        <div style={{ height: 0 }}>
                            <i className='material-icons clickable' style={{
                                border: '1px solid #000',
                                borderRadius: '20px',
                                top: -13,
                                position: 'relative',
                                left: -14,
                                backgroundColor: '#d9dfe4',
                            }} onClick={() => this.setState({ seeMoreCriterias: !this.state.seeMoreCriterias }) }
                            >
                                {this.state.seeMoreCriterias ? 'expand_less' : 'expand_more'}
                            </i>
                        </div>
                    </StyledFieldSet>
                    { this.getSelectedPanel() }
                    <Row className='padding-bottom-1 padding-top-1 center-align'>
                        <Button tooltip={ i18n.apply } onClick={ this.onValidate } icon='border_color' className='btn-floating btn-large'/>
                        <Button tooltip={ i18n.cancel } onClick={ this.onCancel } icon='cancel' className='red btn-floating btn-large margin-left-2 margin-right-2' disabled={ !this.state.validateActive }/>
                        <Button tooltip={ i18n.register } onClick={ this.onSave } icon='save' disabled={ !this.state.validateActive } className={ `btn-floating btn-large ${this.state.validateActive ? 'pulse' : ''}` }/>
                    </Row>
                </WhiteCard>
            </div>

        )
    }
}

DeleteToolPanel.propTypes = {
    changeParent: PropTypes.func,
    piezometer: instanceOf(DtoPiezometer),
    dates: PropTypes.objectOf(PropTypes.number),
    selectionMode: PropTypes.string,
    measures: arrayOf(DtoPiezometryStationMeasure),
    onCancel: PropTypes.func,
    createTechnicalEvent: PropTypes.func,
    stationEvents: PropTypes.arrayOf(DtoEvent),
    reloadData: PropTypes.func,
    piezoMode: PropTypes.bool,
    selectedDataType: PropTypes.number,
    tableView: PropTypes.bool,
    displayCote: PropTypes.number,
    lastLandmark: PropTypes.number,
    groundRefAlti: PropTypes.number,
    contributors: arrayOf(DtoContributor),
    selectedCodePoint: PropTypes.number,
}

const mapStateToProps = store => ({
    piezometer: store.StationReducer.piezometer,
    stationEvents: store.EventsReducer.stationEvents,
    contributors: store.ContributorReducer.contributors,
})

export default connect(mapStateToProps)(DeleteToolPanel)