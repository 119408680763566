import { shallowEqual, useSelector } from 'react-redux'
import React from 'react'
import { partition, orderBy } from 'lodash'
import { hasValue } from '../../../../../utils/NumberUtil'
import ThresholdPreviCard from './ThresholdPreviCard'
import { Grid } from '@mui/material'
import moment from 'moment'
import { getThresholdColorHtml } from '../../../../../utils/ColorUtil'

const ThresholdPreviList = ({}) => {
    const {
        thresholdPreviListData,
    } = useSelector(store => ({
        thresholdPreviListData: store.AlertReducer.thresholdPreviListData,
    }), shallowEqual)

    if (!thresholdPreviListData || !thresholdPreviListData?.thresholds?.length) {
        return null
    }

    const {
        predMeasures,
        thresholds,
    } = thresholdPreviListData


    const [overrun, basic] = partition(orderBy(thresholds, 'value'), t => t.isOverrunThreshold === '1')

    const getSitu = (v, predIdx) => {
        const basicFound = basic.map((t, idx) => [t, idx]).find(([t, _]) => v <= (t?.NGF ?? t?.value))
        if (basicFound) {
            return { situ: basicFound[1], threshold: basicFound[0], value: v, trend: hasValue((predIdx)) ? (predIdx < basicFound[1] ? 'up' : 'down') : 'stable' }
        }
        const overFromRight = overrun.map((t, idx) => [t, idx]).reverse()
        const overFound = overFromRight.find(([t, _]) => v >= (t?.NGF ?? t?.value))
        if (overFound) {
            return { situ: overFound[1] + 1000, threshold: overFound[0], value: v, trend: hasValue((predIdx)) ? (predIdx < (overFound[1] + 1000) ? 'up' : 'down') : 'stable' }
        }
        return { situ: basic.length, threshold: undefined, value: v, trend: hasValue((predIdx)) ? (predIdx < basic.length ? 'up' : 'down') : 'stable' }
    }

    const situs = predMeasures.filter(m => hasValue(m.value)).reduce((acc, m) => {
        const predIdx = acc[acc.length - 1]?.situ
        const s = getSitu(m.value, predIdx)
        if (s.situ === predIdx) {
            return acc
        }
        const diffDay = Math.trunc(moment.duration(moment(m.date).diff(moment())).as('days'))
        return [...acc, {
            ...s,
            date: m.date,
            upperRightTxt: acc.length ? (diffDay > 0 ? `Dans ${diffDay} jours` : `Il y a ${-diffDay} jours`) : '',
            text: s?.threshold?.name ?? 'Situation normale',
            color: s?.threshold ? getThresholdColorHtml(s?.threshold) : 'green',
        }]
    }, [])

    return (
        <Grid container className='white' sx={{ borderRadius: '2px', lineHeight: '1.5rem' }}>
            {situs.map(s => <ThresholdPreviCard date={s.date} upperRightTxt={s.upperRightTxt} text={s.text} color={s.color} trend={s.trend} smallCard/>)}
        </Grid>
    )
}

ThresholdPreviList.propTypes = {
}

export default ThresholdPreviList