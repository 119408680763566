import { isEqual, orderBy, reduce, take } from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import { getI18nOrLabel } from 'utils/StringUtil'
import DtoUserUsageStatistic from '../../../../administration/components/user/dto/DtoUserUsageStatistic'
import DtoUserUsageStatisticItem from '../../../../administration/components/user/dto/DtoUserUsageStatisticItem'
import Table from '../../../../components/datatable/Table'
import { getFullDateMini } from '../../../../utils/DateUtil'
import User from '../../../dto/User'

class AccountUsageStatisticsPanel extends Component {
    constructor(props) {
        super(props)
    }

    shouldComponentUpdate(nextProps) {
        return !isEqual(nextProps.accountUsageStatistics, this.props.accountUsageStatistics)
            || !isEqual(nextProps.accountUser, this.props.accountUser)
    }

    getReduceStatistics = () => {
        return reduce(this.props.accountUsageStatistics, (a, b) => {
            if (!a[`${b.module}_${b.value}`]) {
                a[`${b.module}_${b.value}`] = {
                    module: b.module,
                    value: b.value,
                    datas: [],
                }
            }
            a[`${b.module}_${b.value}`].datas.push(b)
            return a
        }, {})
    }

    getDatas = () => {
        const reduceValues = this.getReduceStatistics()
        return orderBy(Object.keys(reduceValues).map((o) => {
            const statisticObject = reduceValues[o]
            const listOfStatistics = statisticObject.datas
            const lastVisit = orderBy(listOfStatistics, 'statisticDate', 'desc')[0]
            return new DtoUserUsageStatisticItem({
                stationType: getI18nOrLabel(statisticObject.module.toLowerCase()),
                functionnality: getI18nOrLabel(statisticObject.value),
                lastAccess: getFullDateMini(lastVisit.statisticDate),
                nbAccess: listOfStatistics.length,
            })
        }), 'nbAccess', 'desc')
    }

    render() {
        if (this.props.accountUsageStatistics.length) {
            const data = this.getDatas()
            return (
                <div className='row no-margin card'>
                    <div className='col s12 no-padding'>
                        <Table title={ `10 ${i18n.userUsageStatistics}` }
                            type={ new DtoUserUsageStatisticItem({}) } data={ take(data, 10) }
                            exportButtonOnHeader exportData={ data }
                            showNbElements={ false }
                            activeHeader
                            exportName={ i18n.userUsageStatisticsExport + this.props.accountUser.login }
                            headerClass={ 'active' }
                        />
                    </div>
                </div>
            )
        }
        return null
    }
}

AccountUsageStatisticsPanel.propTypes = {
    accountUser: PropTypes.instanceOf(User),
    accountUsageStatistics: PropTypes.arrayOf(PropTypes.instanceOf(DtoUserUsageStatistic)),
}
const mapStateToProps = (store) => {
    return {
        accountUser: store.AccountReducer.accountUser,
        accountUsageStatistics: store.AccountReducer.accountUsageStatistics,
    }
}
export default connect(mapStateToProps)(AccountUsageStatisticsPanel)