import { pick } from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import WaitAction from 'wait/WaitAction'
import Card from '../../../../components/card/Card'
import ExportAction from '../../../../export/actions/ExportAction'
import StationsEdilaboExportPanel from '../../../../station/components/StationsEdilaboExportPanel'
import Job from '../../../dto/DtoJob'

class ExportEdilaboPanelRai extends Component {
    constructor(props) {
        super(props)
        const parameter = props.job.parameters.parameters.length ? JSON.parse(props.job.parameters.parameters[0]) : {}
        this.state = {
            listStation: [],
            selectedGroupeParam: null,
            selectedParameters: [],
            producer: parameter.producer,
            labo: parameter.labo,
            destinataire: parameter.destinataire,
            transmitter: parameter.transmitter,
            campaign: parameter.campaign,
            startDate: parameter.startDate,
            endDate: parameter.endDate,
            schemeAgencyID: parameter.schemeAgencyID,
            ...parameter,
        }
    }

    onChange = (value) => {
        this.setState(value)
        const newParameter = {
            ...pick(this.state, ['campaign', 'labo', 'producer', 'startDate', 'endDate', 'transmitter', 'receiver', 'requestName', 'exportPath', 'selectedParameters', 'listStation', 'schemeAgencyID']),
            ...value,
        }
        this.props.handleChangeParameters([JSON.stringify(newParameter)])
    }

    onApplyExport = () => {
        const { waitStart, waitStop } = this.props
        waitStart()
        const newParameter = pick(this.state, ['campaign', 'labo', 'producer', 'startDate', 'endDate', 'transmitter', 'receiver', 'requestName', 'exportPath', 'selectedParameters', 'listStation', 'schemeAgencyID'])
        this.props.handleChangeParameters([JSON.stringify(newParameter)])
        this.props.exportEDILABORai(newParameter)
        waitStop()
    }

    render() {
        return (
            <StationsEdilaboExportPanel
                defaultFilter={{
                    campaign: this.state.campaign,
                    startDate: this.state.startDate,
                    endDate: this.state.endDate,
                    producer: this.state.producer,
                    labo: this.state.labo,
                    transmitter: this.state.transmitter,
                    receiver: this.state.receiver,
                    exportPath: this.state.exportPath,
                    requestName: this.state.requestName,
                    selectedParameters: this.state.selectedParameters,
                    listStation: this.state.listStation,
                    schemeAgencyID: this.state.schemeAgencyID,
                }}
                onChange={this.onChange}
                isEditMode={ this.props.isEditMode }
            />
        )
    }
}

ExportEdilaboPanelRai.propTypes = ({
    job: PropTypes.instanceOf(Job).isRequired,
    onChangeParameters: PropTypes.func,
    handleChangeParameters: PropTypes.func.isRequired,
    loadStationMeasures: PropTypes.func,
    waitStart: PropTypes.func,
    waitStop: PropTypes.func,
    exportEDILABORai: PropTypes.func,
    isEditMode: PropTypes.bool,
})


const mapDispatchToProps = {
    waitStart: WaitAction.waitStart,
    waitStop: WaitAction.waitStop,
    exportEDILABORai: ExportAction.exportEDILABORai,
}


export default connect(null, mapDispatchToProps)(ExportEdilaboPanelRai)
