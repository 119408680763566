import { DistributionUnitActionConstant } from 'distributionUnit/reducers/DistributionUnitReducer'
import { drop, flatten, flattenDeep, isNil, take, uniq } from 'lodash'
import { PiezometryActionConstant } from 'piezometry/reducers/PiezometryReducer'
import { QualityActionConstant } from 'quality/reducers/QualityReducer'
import i18n from 'simple-react-i18n'
import { STATION_TYPE } from 'station/constants/StationConstants'
import ToastrAction from 'toastr/actions/ToastrAction'
import { checkAuth, genericPromise, getAuthorization, getJson, promiseAllProgress } from 'utils/ActionUtils'
import { hasValue } from 'utils/NumberUtil'
import WaitAction from 'wait/WaitAction'
import ApplicationConf from '../../conf/ApplicationConf'
import LogAction from '../../log/actions/LogAction'
import PiezometryAction from '../../piezometry/actions/PiezometryAction'
import QualityAction from '../../quality/actions/QualityAction'
import StationAction from '../../station/actions/StationAction'
import AppStore from '../../store/AppStore'
import { checkError } from '../../utils/ActionUtils'

const DistributionUnitAction = {
    createDistributionUnit(newDistributionUnit, callback) {
        return function (dispatch) {
            dispatch(WaitAction.waitStart())
            return fetch(ApplicationConf.distributionUnit.distributionUnitPath(), {
                method: 'POST',
                headers: getAuthorization(),
                body: JSON.stringify(newDistributionUnit),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(json => {
                    if (json.insert === 1 && json.id) {
                        dispatch(WaitAction.waitStop())
                        dispatch(DistributionUnitActionConstant.createDistributionUnit({ ...newDistributionUnit, id: json.id }))
                        dispatch(ToastrAction.success(i18n.elementCreateSuccess))
                        callback(json.id)
                    } else {
                        throw new Error(json)
                    }
                })
                .catch((err) => {
                    dispatch(WaitAction.waitStop())
                    dispatch(LogAction.logError(`${i18n.createError + i18n.distributionUnit} : ${err}`))
                    dispatch(ToastrAction.error(i18n.createError + i18n.distributionUnit))
                })
        }
    },
    updateDistributionUnit: distributionUnit => dispatch => {
        return fetch(ApplicationConf.distributionUnit.getAll(), {
            method: 'PUT',
            headers: getAuthorization(),
            body: JSON.stringify(distributionUnit),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
            .then(() => {
                dispatch(DistributionUnitAction.fetchDistributionUnit(distributionUnit.id))
                dispatch(ToastrAction.success(i18n.elementUpdateSuccess))
            })
            .catch(err => {
                dispatch(LogAction.logError(`${i18n.updateError + i18n.distributionUnit} : ${err}`))
                dispatch(ToastrAction.error(i18n.updateError + i18n.distributionUnit))
            })
    },
    deleteDistributionUnit(id, callback) {
        return function (dispatch) {
            dispatch(WaitAction.waitStart())
            return fetch(ApplicationConf.distributionUnit.distributionUnitPath(), {
                method: 'DELETE',
                headers: getAuthorization(),
                body: JSON.stringify({ id }),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(json => {
                    if (json.delete === 1) {
                        dispatch(WaitAction.waitStop())
                        dispatch(DistributionUnitActionConstant.deleteDistributionUnit(id))
                        dispatch(ToastrAction.success(i18n.elementDeleteSuccess))
                        callback()
                    } else {
                        throw new Error(json)
                    }
                })
                .catch((err) => {
                    dispatch(WaitAction.waitStop())
                    dispatch(LogAction.logError(`${i18n.deleteError + i18n.distributionUnit} : ${err}`))
                    dispatch(ToastrAction.error(i18n.deleteError + i18n.distributionUnit))
                })
        }
    },

    promiseDistributionUnits() {
        return fetch(ApplicationConf.distributionUnit.getAll(), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
    },
    fetchDistributionUnits() {
        return (dispatch) => {
            return DistributionUnitAction.promiseDistributionUnits()
                .then(json => {
                    dispatch(DistributionUnitActionConstant.receiveAllDistributionUnits(json))
                })
        }
    },

    fetchDistributionUnit(id) {
        return (dispatch) => {
            dispatch(WaitAction.waitStart())
            return fetch(ApplicationConf.distributionUnit.get(id), {
                method: 'GET',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then(e => checkError(e, {
                    404: () => AppStore.dispatch(ToastrAction.error(i18n.unreachableStation)),
                }))
                .then(json => {
                    dispatch(WaitAction.waitStop())
                    dispatch(DistributionUnitActionConstant.receiveDistributionUnit(json))
                })
                .catch(err => {
                    dispatch(WaitAction.waitStop())
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.distributionUnit} : ${err}`))
                })
        }
    },

    fetchDistributionUnitLink(id) {
        return (dispatch) => {
            return fetch(ApplicationConf.distributionUnit.getLink(id), {
                method: 'GET',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then(e => checkError(e, {
                    404: () => {},
                }))
                .then(link => dispatch(DistributionUnitActionConstant.receiveDistributionUnitLink(link)))
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.distributionUnit} : ${err}`))
                })
        }
    },

    fetchDistributionUnitsAssociations(code, callback = () => {}) {
        return (dispatch) => {
            return StationAction.promiseAssociatedSites(code, 6)
                .then((json = []) => {
                    dispatch(DistributionUnitActionConstant.receiveDistributionUnitAssociations(json))
                    callback()
                })
        }
    },

    fetchDistributionUnitSectors: () => dispatch => {
        return fetch(ApplicationConf.distributionUnit.getSectors(), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then(json => {
                dispatch(DistributionUnitActionConstant.receiveDistributionUnitSectors(json))
            })
    },
    loadDistributionUnitAssociations(id) {
        return (dispatch) => {
            return Promise.all([
                StationAction.promiseAssociatedSites(id, 6),
                QualityAction.promiseQualitometersLight(),
                PiezometryAction.promisePiezometersLight(),
            ]).then(jsonResults => {
                dispatch(QualityActionConstant.receiveQualitometersLight(jsonResults[1]))
                dispatch(PiezometryActionConstant.receiveAllPiezometersLight(jsonResults[2]))
                const resultsWithIds = jsonResults[0].map(assoc => {
                    if (assoc.stationLinkedType === 1) {
                        const foundPiezo = jsonResults[2].find(p => p.code === assoc.stationLinkedCode)
                        if (foundPiezo) {
                            return Object.assign({}, assoc, { childrenStationId: foundPiezo.id })
                        }
                    }
                    if (assoc.stationLinkedType === 3) {
                        const foundQualito = jsonResults[1].find(p => p.code === assoc.stationLinkedCode)
                        if (foundQualito) {
                            return Object.assign({}, assoc, { childrenStationId: foundQualito.id })
                        }
                    }
                    return assoc
                })
                dispatch(DistributionUnitActionConstant.receiveDistributionUnitAssociations(resultsWithIds))
                return jsonResults
            })
        }
    },

    fetchCaptureDownstreamDistribUnits: () => dispatch => {
        return fetch(ApplicationConf.distributionUnit.getAllCaptureDownstreamDistribUnits(), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then(json => {
                dispatch(DistributionUnitActionConstant.receiveCaptureDownstreamDistribUnit(json))
            })
    },

    fetchDistributionCounterTypes() {
        return (dispatch) => {
            return genericPromise(ApplicationConf.distributionUnit.counterTypes())
                .then(json => {
                    dispatch(DistributionUnitActionConstant.receiveDistributionCounterTypes(json))
                })
        }
    },

    fetchCounters(data) {
        return (dispatch) => {
            return genericPromise(ApplicationConf.distributionUnit.counters(), 'POST', data)
                .then(json => {
                    dispatch(DistributionUnitActionConstant.receiveDistributionCounters(json))
                })
        }
    },

    loadDistributionUnitQualityAssociations(id, allAssociations, qualitometers, referenceThreshold, setProgress = () => {}, setDataLoaded = () => {}) {
        return (dispatch) => {
            return QualityAction.promiseQualityIndicators().then(result1 => {
                const qualityIndicators = !isNil(referenceThreshold) ? [
                    ...result1.filter(r => r.stationType !== STATION_TYPE.EAU_DISTRIBUEE),
                    {
                        stationType: STATION_TYPE.EAU_DISTRIBUEE,
                        indicators: referenceThreshold.thresholds?.map(t => ({ id: parseInt(t.parameterCode), threshold: parseInt(referenceThreshold.thresholdCode) })),
                    },
                ] : result1
                dispatch(QualityActionConstant.receiveQualityIndicators(qualityIndicators))

                const associations = allAssociations.filter(a => a.stationLinkedType === 3 && hasValue(a.stationLinkedCode))
                const promisesResults = associations.reduce((acc, assoc) => {
                    const found = qualitometers.find(q => q.code === assoc.stationLinkedCode)
                    if (found && hasValue(found.stationType)) {
                        const parametersIndicators = result1.find(ind => ind.stationType == found.stationType)?.indicators.map(i => i.id)
                        const thresholdParameters = found.stationType === `${STATION_TYPE.EAU_DISTRIBUEE}` ? (referenceThreshold?.thresholds?.map(t => parseInt(t.parameterCode)) || parametersIndicators) : parametersIndicators
                        if (thresholdParameters?.length) {
                            acc.push(QualityAction.promiseQualityIndicatorsResults(found.id, thresholdParameters))
                        }
                    }
                    return acc
                }, [])

                const thresholds = uniq(flattenDeep(result1.map(indicator => indicator.indicators.map(i => i.threshold))))
                const allThresholds = referenceThreshold?.thresholdCode ? uniq([...thresholds, parseInt(referenceThreshold.thresholdCode)]) : thresholds
                const promisesThresholds = allThresholds.map(thresholdId => QualityAction.promiseQualityThreshold({ code: thresholdId, thresholdType: '0' }))

                promiseAllProgress(promisesResults.concat(promisesThresholds), progress => setProgress(progress)).then(jsonResults => {
                    dispatch(QualityActionConstant.receiveQualityIndicatorsResults(flatten(take(jsonResults, promisesResults.length))))
                    const thresholdResults = drop(jsonResults, promisesResults.length).map((result, index) => ({
                        thresholdCode: allThresholds[index],
                        thresholds: result,
                    }))
                    dispatch(QualityActionConstant.receiveStationQualityThreshold(thresholdResults))
                    setDataLoaded()
                })
            })
        }
    },
}

export default DistributionUnitAction
