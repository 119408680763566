import PropTypes from 'prop-types'
import React, { Component } from 'react'
import i18n from 'simple-react-i18n'
import ProgressCard from '../../../../components/card/ProgressCard'
import Button from '../../../../components/forms/Button'
import Input from '../../../../components/forms/Input'
import Icon from '../../../../components/icon/Icon'
import Row from '../../../../components/react/Row'
import Job from '../../../dto/DtoJob'

class VeoliaPortalPanel extends Component {
    constructor(props) {
        super(props)
        this.state = {
            dataLoaded: true,
            progress: 0,
            fileName: '',
        }
    }

    onChangeFilters = (changes) => {
        const parameters = {
            ...this.props.job.parameters,
            filters: [JSON.stringify({
                ...this.getFilters(),
                ...changes,
            })],
        }
        this.props.onChangeJob({ parameters })
    }

    getFilters = () => this.props.job.parameters.filters.length ? JSON.parse(this.props.job.parameters.filters[0]) : {}

    render() {
        if (this.state.dataLoaded) {
            const disabled = !this.props.isEditMode
            const filters = this.getFilters()
            const files = filters.fileNames || []
            return (
                <div>
                    <div className='row no-margin valign-wrapper'>
                        <fieldset className='width-100'>
                            <legend>&nbsp;{ 'Identifiants portail Veolia' }&nbsp;</legend>
                            <Input col={ 4 } title={ i18n.login } onChange={ v => this.onChangeFilters({ portalUser: v }) } disabled={ disabled } value={ filters.portalUser } />
                            <Input col={ 4 } title={ i18n.password } onChange={ v => this.onChangeFilters({ portalPassword: v }) } disabled={ disabled } value={ filters.portalPassword } passwordType/>
                            <Input col={ 5 } title={ 'Répertoire FTP' } onChange={ v => this.onChangeFilters({ directory: v }) } disabled={ disabled } value={ filters.directory } />
                        </fieldset>
                    </div>
                    <div className='row no-margin padding-top-1'>
                        <fieldset className='width-100'>
                            <legend>&nbsp;{ 'Choix des fichiers à récupérer' }&nbsp;</legend>
                            <Row><Input col={ 5 } title={ i18n.fileName } onChange={ v => this.setState({ fileName: v }) } disabled={ disabled } value={ this.state.fileName } />
                                <Button icon='note_add' col={ 2 } title={ i18n.add } disabled={ disabled } onClick={ () => {
                                    if (this.state.fileName && this.state.fileName.length) {
                                        this.onChangeFilters({ fileNames: [...files, this.state.fileName] })
                                        this.setState({ fileName: '' })
                                    }
                                } }
                                />
                                <div className='col s5'/>
                            </Row>

                        </fieldset>
                    </div>
                    <div className='row no-margin padding-top-1'>
                        {
                            files.map(f => (
                                <Row>
                                    <div className='col s1'><Icon icon='clear' onClick={ () => this.onChangeFilters({ fileNames: files.filter(f2 => f2 !== f) }) } /></div>
                                    <div className='col s11'><h6>{ f }</h6></div>
                                </Row>
                            ))
                        }
                    </div>
                </div>
            )
        }
        return <ProgressCard progress={ this.state.progress }/>
    }
}

VeoliaPortalPanel.propTypes = {
    job: PropTypes.instanceOf(Job).isRequired,
    onChangeJob: PropTypes.func.isRequired,
    isEditMode: PropTypes.bool,
}

export default VeoliaPortalPanel