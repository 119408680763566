/* eslint-disable camelcase */
import DtoLocation from '../../../station/dto/DtoLocation'
import DtoInstallationCartographyData from './DtoInstallationCartographyData'
import DtoPiezometerContributorLink from '../../../piezometry/dto/DtoPiezometerContributorLink'
import DtoUsage from '../../../station/dto/DtoUsage'
import DtoArrangement from '../../../station/dto/DtoArrangement'
import DtoKeyPossessor from './DtoKeyPossessor'
import DtoStaffSafety from './DtoStaffSafety'
import DtoProtection from './DtoProtection'
import DtoPrevention from './DtoPrevention'
import DtoAccessType from './DtoAccessType'
import DtoComElec from './DtoComElec'
import DtoAccessCasing from './DtoAccessCasing'
import DtoAccessSite from './DtoAccessSite'
import DtoInstallationEquipment from './DtoInstallationEquipment'
import DtoSampleCharacteristics from './DtoSampleCharacteristics'
import DtoInstallationCasing from './DtoInstallationCasing'
import DtoInstallationTank from './DtoInstallationTank'
import DtoBuilding from './DtoBuilding'
import DtoInstallationExploitation from './DtoInstallationExploitation'

export default class DtoInstallation {
    constructor(obj) {
        this.id = obj.id
        this.number = obj.id
        this.code = obj.code
        this.designation = obj.designation
        this.name = obj.name
        this.typeName = 'installation'
        this.installationType = obj.installationType
        this.descriptive = obj.descriptive
        this.creationDate = obj.creationDate
        this.endDate = obj.endDate
        this.localisation = new DtoLocation(obj)
        this.location = obj.location
        this.x = obj.x
        this.y = obj.y
        this.altitude = obj.altitude
        this.projection = obj.projection
        this.mnemonic = obj.mnemonic
        this.townCode = obj.townCode
        this.declarationTownCode = obj.declarationTownCode
        this.previsionalVisitNumber = obj.previsionalVisitNumber
        this.address = obj.address
        this.comments = obj.comments
        this.declarationDate = obj.declarationDate
        this.declarationNumber = obj.declarationNumber
        this.closeComment = obj.closeComment
        this.updateLogin = obj.updateLogin
        this.updateDate = obj.updateDate
        this.confidential = obj.confidential
        this.executionModeCode = obj.executionModeCode
        this.stateCode = obj.stateCode
        this.ownerCode = obj.ownerCode
        this.dataOrigin = obj.dataOrigin
        this.exploitationCode = obj.exploitationCode
        this.jobExecutionId = obj.jobExecutionId
        this.status = obj.status
        this.statusLogin = obj.statusLogin
        this.statusDate = obj.statusDate
        this.countryCode = obj.countryCode
        this.floodZone = obj.floodZone
        this.sectorId = obj.sectorId

        this.city = obj.city

        this.operatorName = obj.operatorName
        this.operator = obj.operatorName
        this.operatorId = obj.operatorId
        this.operatorType = obj.operatorType
        this.nameOperatorType = obj.nameOperatorType

        this.buildingOwnerName = obj.administratorName
        this.administratorName = obj.administratorName
        this.administrator = obj.administrator
        this.administratorId = obj.administratorId
        this.administratorType = obj.administratorType
        this.nameAdministratorType = obj.nameAdministratorType

        this.effectiveReceiptDate = obj.effectiveReceiptDate
        this.prodDate = obj.prodDate

        this.managementCode = obj.managementCode
        this.subManagementCode = obj.subManagementCode

        this.link_elecComs = obj.link_elecComs ? obj.link_elecComs.map(u => new DtoComElec(u)) : []
        this.link_accessSites = obj.link_accessSites ? obj.link_accessSites.map(u => new DtoAccessSite(u)) : []
        this.link_accessCasings = obj.link_accessCasings ? obj.link_accessCasings.map(u => new DtoAccessCasing(u)) : []

        this.link_accessTypes = obj.link_accessTypes ? obj.link_accessTypes.map(u => new DtoAccessType(u)) : []
        this.link_arrangements = obj.link_arrangements ? obj.link_arrangements.map(a => new DtoArrangement(a)) : []
        this.link_contributors = obj.link_contributors ? obj.link_contributors.map(c => new DtoPiezometerContributorLink(c)) : []
        this.link_equipments = obj.link_equipments ? obj.link_equipments.map(c => new DtoInstallationEquipment(c)) : []
        this.link_geo = obj.link_geo && obj.link_geo.length ? obj.link_geo.map(c => new DtoInstallationCartographyData(c)) : []
        this.link_keyPossessors = obj.link_keyPossessors ? obj.link_keyPossessors.map(u => new DtoKeyPossessor(u)) : []
        this.link_staffSafeties = obj.link_staffSafeties ? obj.link_staffSafeties.map(u => new DtoStaffSafety(u)) : []
        this.link_preventions = obj.link_preventions ? obj.link_preventions.map(p => new DtoPrevention(p)) : []
        this.link_protections = obj.link_protections ? obj.link_protections.map(u => new DtoProtection(u)) : []
        this.link_usages = obj.link_usages ? obj.link_usages.map(u => new DtoUsage(u)) : []
        this.link_sampleCharacteristics = obj.link_sampleCharacteristics ? obj.link_sampleCharacteristics.map(c => new DtoSampleCharacteristics(c)) : []
        this.link_sampleCasing = obj.link_sampleCasing ? obj.link_sampleCasing.map(c => new DtoInstallationCasing(c)) : []
        this.link_sampleTanks = obj.link_sampleTanks ? obj.link_sampleTanks.map(c => new DtoInstallationTank(c)) : []
        this.link_buildings = obj.link_buildings ? obj.link_buildings.map(b => new DtoBuilding(b)) : []
        this.link_exploitationsAgri = obj.link_exploitationsAgri ? obj.link_exploitationsAgri.map(l => new DtoInstallationExploitation(l)) : []
    }
}