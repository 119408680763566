const {
    H_DISTRIBUTION_MODULE, H_HYDRO_MODULE, H_INSTALLATION_MODULE,
    H_MAT_MODULE, H_PIEZO_MODULE, H_PLUVIO_MODULE, H_PRODUCTION_MODULE, H_QUALITO_MODULE,
    H_SITUATION_MODULE,
} = require('../../../../account/constants/AccessRulesConstants')

module.exports = {
    RECEIVE_USERS: 'RECEIVE_USERS',
    RECEIVE_USERS_THEMES_ASSIGNMENT: 'RECEIVE_USERS_THEMES_ASSIGNMENT',
    RECEIVE_USER_THEMES_ASSIGNMENT: 'RECEIVE_USER_THEMES_ASSIGNMENT',
    RECEIVE_USER: 'RECEIVE_USER',
    RECEIVE_USER_STATISTIC: 'RECEIVE_USER_STATISTIC',
    RECEIVE_ALL_USER_STATISTIC: 'RECEIVE_ALL_USER_STATISTIC',
    RECEIVE_ALL_MOBILE_NOTIFICATIONS: 'RECEIVE_ALL_MOBILE_NOTIFICATIONS',
    RECEIVE_USER_KEYFIGURES: 'RECEIVE_USER_KEYFIGURES',
    RECEIVE_USER_STATION_STATISTICS: 'RECEIVE_USER_STATION_STATISTICS',
    RECEIVE_ALL_USER_STATION_STATISTICS: 'RECEIVE_ALL_USER_STATION_STATISTICS',
    RECEIVE_USER_USAGE_STATISTICS: 'RECEIVE_USER_USAGE_STATISTICS',
    RECEIVE_ALL_USER_USAGE_STATISTICS: 'RECEIVE_ALL_USER_USAGE_STATISTICS',
    RECEIVE_USER_CMS_STATISTICS: 'RECEIVE_USER_CMS_STATISTICS',
    RECEIVE_ALL_USER_CMS_STATISTICS: 'RECEIVE_ALL_USER_CMS_STATISTICS',
    RECEIVE_USER_KEYFIGURES_ALL: 'RECEIVE_USER_KEYFIGURES_ALL',
    RECEIVE_USER_LOGIN_HABILITATIONS: 'RECEIVE_USER_LOGIN_HABILITATIONS',
    RECEIVE_USER_HABILITATIONS: 'RECEIVE_USER_HABILITATIONS',
    RECEIVE_USER_STATIONS: 'RECEIVE_USER_STATIONS',
    RESET_USER_ATTEMPTS: 'RESET_USER_ATTEMPTS',
    RECEIVE_FILTER_USERS: 'RECEIVE_FILTER_USERS',
    RECEIVE_APPLICATIVE_USERS: 'RECEIVE_APPLICATIVE_USERS',
    SIEAU_TOKEN: 'sieau-token',
    RESET_USER: 'RESET_USER',
    RECEIVE_ALL_BOOKMARKS: 'RECEIVE_ALL_BOOKMARKS',
    PANEL_FILE: 'PANEL_FILE',
    PANEL_MODULES: 'PANEL_MODULES',
    PANEL_ACCESS: 'PANEL_ACCESS',
    PANEL_STATISTICS: 'PANEL_STATISTICS',
    PANEL_MAP: 'PANEL_MAP',
    PANEL_HOME: 'PANEL_HOME',
    PANEL_OPTION: 'PANEL_OPTION',
    KEY_ADMIN: 'isAdmin',
    KEY_LABO: 'labo',
    KEY_METADATA: 'metadata',
    KEY_CONSULTANT: 'consultant',
    KEY_CONTACT_CODE: 'contactCode',
    RECEIVE_USER_VIEW: 'RECEIVE_USER_VIEW',
    RECEIVE_GENERIC_USER_VIEW: 'RECEIVE_GENERIC_USER_VIEW',
    USER_MODULES_LINKS: [
        { habilitation: H_QUALITO_MODULE, link: '/quality' },
        { habilitation: H_PIEZO_MODULE, link: '/piezometry' },
        { habilitation: H_HYDRO_MODULE, link: '/hydrometry' },
        { habilitation: H_PLUVIO_MODULE, link: '/pluviometry' },
        { habilitation: H_INSTALLATION_MODULE, link: '/installation' },
        { habilitation: H_PRODUCTION_MODULE, link: '/productionUnit' },
        { habilitation: H_DISTRIBUTION_MODULE, link: '/distributionUnit' },
        { habilitation: H_MAT_MODULE, link: '/materiel' },
        { habilitation: H_SITUATION_MODULE, link: '/alert' },
    ],
    BASIC_HOMEPAGE_VIEWS: [
        {
            panel: 'quality',
            visible: true,
        },
        {
            panel: 'piezometry',
            visible: true,
        },
        {
            panel: 'hydrometry',
            visible: true,
        },
        {
            panel: 'pluviometry',
            visible: true,
        },
        {
            panel: 'map',
            visible: true,
        },
        {
            panel: 'keyFigures',
            visible: true,
        },
        {
            panel: 'arrests',
            visible: true,
        },
        {
            panel: 'vigilances',
            visible: true,
        },
        {
            panel: 'tweets',
            visible: true,
        },
        {
            panel: 'productionUnit',
            visible: true,
        },
        {
            panel: 'agriculture',
            visible: true,
        },
        {
            panel: 'perimeters',
            visible: true,
        },
    ],
    DEFAULT_CONTRIBUTOR_TYPE: {
        OPERATOR: 4,
        ADMINISTRATOR: 0,
    },
    FILTER_TYPE: {
        BOOKMARK: -5,
        NOFILTER: -1,
    },
}
