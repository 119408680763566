import DtoMaterielType from '../../../dto/DtoMaterielType'
import { MAT_EQUIPMENT } from '../../../constants/MaterielConstants'
import i18n from 'simple-react-i18n'

export default class EquipmentTypeDto extends DtoMaterielType {
    constructor(obj) {
        super(obj)
        this.materielCategory = MAT_EQUIPMENT
        this.materielLabel = i18n.equipment
        // this.centralIntegrated = obj.centralIntegrated
        // this.sensorIntegrated = obj.sensorIntegrated
        // this.telecomIntegrated = obj.telecomIntegrated
        // this.powerSupplyIntegrated = obj.powerSupplyIntegrated
        // this.variousMaterielIntegrated = obj.variousMaterielIntegrated
        // this.simIntegrated = obj.simIntegrated
        this.centralType = obj.centralType || []
        this.sensorType = obj.sensorType || []
        this.simType = obj.simType || []
        this.powerSupplyType = obj.powerSupplyType || []
        this.telecomType = obj.telecomType || []
        this.variousMaterielType = obj.variousMaterielType || []
    }
}
