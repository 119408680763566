/* eslint-disable camelcase */
import React from 'react'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import { connect } from 'react-redux'
import SieauAction from '../../../../components/sieau/SieauAction'
import Row from '../../../../components/react/Row'
import NumberField from '../../../../components/forms/NumberField'
import Checkbox from '../../../../components/forms/Checkbox'
import DtoSTEP from '../dto/DtoSTEP'
import { getVisitCheckBoxChange } from '../../../../utils/VisitUtils'

const STEPCivilEngPanel = ({
    onChange = () => { },
    onChangeVisit = () => { },
    STEP = {},
    readMode = false,
}) => {
    const onChangeSTEPCivilEng = (value) => {
        onChange({
            link_civilEngs: [
                {
                    idStation: STEP.idStation,
                    ...STEP.link_civilEngs[0],
                    ...value,
                },
            ],
        })
    }

    const civilEng = STEP.link_civilEngs[0] || {}

    return (
        <Row className='padding-top-1 padding-bottom-1'>
            <Row>
                <Checkbox
                    col={ 6 }
                    label={ i18n.bufferTank }
                    checked={ civilEng.bufferTank }
                    onChange={ (v) => {
                        onChangeSTEPCivilEng({ bufferTank: v })
                        onChangeVisit(getVisitCheckBoxChange(i18n.bufferTank, civilEng.bufferTank, v))
                    } }
                    readMode={ readMode }
                    disabled={ readMode }
                />

                <NumberField
                    col={ 6 }
                    value={ civilEng.bufferTankVolume }
                    title={ i18n.bufferTankVolume }
                    onChange={ (v) => {
                        onChangeSTEPCivilEng({ bufferTankVolume: v })
                        onChangeVisit({ previousValue: civilEng.bufferTankVolume, newValue: v, field: i18n.bufferTankVolume })
                    } }
                    readMode={ readMode }
                />
            </Row>
        </Row>
    )
}

STEPCivilEngPanel.propTypes = {
    STEP: PropTypes.instanceOf(DtoSTEP),
    readMode: PropTypes.bool,
    onChange: PropTypes.func,
    onChangeVisit: PropTypes.func,
    setPopup: PropTypes.func,
}


const mapDispatchTopProps = {
    setPopup: SieauAction.setPopup,
}

export default connect(null, mapDispatchTopProps)(STEPCivilEngPanel)
