import React, { Component } from 'react'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import { getDate } from '../../../../utils/DateUtil'
import AppStore from '../../../../store/AppStore'
import { push } from '@lagunovsky/redux-react-router'

class AssigmentPanel extends Component {
    constructor(props) {
        super(props)
    }

    createRow = (value) => {
        if (value) {
            return (
                <div className='row no-margin'>
                    <div className='col s12 no-padding'>
                        <span className='font-size-16'>{ value }</span>
                    </div>
                </div>
            )
        }
        return null
    }

    render() {
        return (
            <div className='card no-margin'>
                <div className='card-content'>
                    <div className='row no-margin clickable' onClick={ () => AppStore.dispatch(push(this.props.link)) }>
                        <div className='col s7 no-padding'>
                            <div className='row no-margin'>
                                <div className='col s1 no-padding'>
                                    { this.props.statusIcon || null }
                                </div>
                                <div className='col s11 no-padding'>
                                    <div className='row no-margin'>
                                        { this.createRow(this.props.title) }
                                    </div>
                                </div>
                            </div>
                            { this.createRow(this.props.secondLineFirstValue) }
                            { this.createRow(this.props.thirdLineFirstValue) }
                            { this.createRow(this.props.fourthLineFirstValue) }
                        </div>
                        <div className='col s5 no-padding right-align'>
                            { this.createRow(`${i18n.installedSince} ${getDate(this.props.installationDate)}`) }
                            { this.createRow(this.props.secondLineSecondValue) }
                            { this.createRow(this.props.thirdLineSecondValue) }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

AssigmentPanel.propTypes = {
    title: PropTypes.string,
    link: PropTypes.string,
    statusIcon: PropTypes.element,
    installationDate: PropTypes.string,
    secondLineFirstValue: PropTypes.string,
    secondLineSecondValue: PropTypes.string,
    thirdLineFirstValue: PropTypes.string,
    thirdLineSecondValue: PropTypes.string,
    fourthLineFirstValue: PropTypes.string,
}

export default AssigmentPanel
