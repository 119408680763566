import DtoMaterielSituation from '../../../dto/DtoMaterielSituation'

export default class DtoSensorSituation extends DtoMaterielSituation {
    constructor(obj) {
        super(obj)
        this.id = obj.idSensorSituation
        this.idSensor = obj.idSensor
        this.materielId = obj.idSensor
    }
}
