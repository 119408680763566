import DtoMaterielAssignment from './DtoMaterielAssignment'

export default class DtoStationSensorAssignment extends DtoMaterielAssignment {
    constructor(obj) {
        super(obj)
        this.id = obj.idSensorSituation
        this.idSensorSituation = obj.idSensorSituation
        this.idSensor = obj.idSensor
        this.materielId = obj.idSensor
    }
}
