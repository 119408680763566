import React from 'react'
import { push } from '@lagunovsky/redux-react-router'
import { sortBy } from 'lodash'
import { useEffect, useMemo, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import useActions from 'utils/customHook/useActions'
import useTitle from 'utils/customHook/useTitle'
import { getDataWithHeaders } from 'utils/ExportDataUtil'
import HomeAction from '../../../../home/actions/HomeAction'
import {
    PATH_REFERENCIAL,
    PATH_REFERENCIAL_CULTURE,
    PATH_REFERENCIAL_CULTURES_RPG_NEW,
} from '../../../../home/constants/RouteConstants'
import SearchTable from 'referencial/components/SearchTable'
import { getExportReferencialData } from 'referencial/util/ReferencialUtils'
import { REFERENCIAL_TYPE_NAME } from 'referencial/constants/ReferencialConstants'
import CultureAction from '../actions/CultureAction'

const headers = ['code', 'name']

const CulturesRPGApp = () => {
    const {
        culturesRPG,
    } = useSelector(store => ({
        culturesRPG: store.CultureReducer.culturesRPG,
    }), shallowEqual)

    const [exportData, setExportData] = useState([])
    const [culturesRPGFormatted, setCulturesRPGFormatted] = useState([])

    const dispatch = useDispatch()

    useEffect(() => setCulturesRPGFormatted(sortBy(culturesRPG, 'code').map((c) => ({ ...c, headers }))), [culturesRPG])

    useEffect(() => {
        dispatch(CultureAction.fetchCulturesRPG())
        dispatch(HomeAction.setHelpLink('', ''))
    }, [])

    const data = useMemo(() => getExportReferencialData(culturesRPGFormatted), [culturesRPGFormatted])

    useActions(() => {
        return {
            new: () => dispatch(push(`${PATH_REFERENCIAL_CULTURES_RPG_NEW}/dashboard`)),
            referencialActions: {
                actions: {
                    export: {
                        data: getDataWithHeaders(exportData, headers),
                        titleFile: i18n.culturesRPG,
                    },
                },
            },
        }
    }, [exportData])

    useTitle(() => [{
        title: i18n.referencials,
        href: PATH_REFERENCIAL,
    }, {
        title: i18n.culturesRPG,
        href: PATH_REFERENCIAL_CULTURE,
    }], [])

    return (
        <SearchTable
            title={i18n.culturesRPG}
            sessionKey={REFERENCIAL_TYPE_NAME.culturesRPG}
            data={data}
            setExportData={setExportData}
            headers={headers}
            onClick={cultureRPG => dispatch(push(`/referencial/cultureRPG/${cultureRPG.code}/dashboard`))}
        />
    )
}

export default CulturesRPGApp
