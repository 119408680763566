const getText = texts => {
    if (texts.length === 0) {
        return ''
    }
    const fr = texts.filter(t => t.lang === 'fr-FR')
    if (fr.length) {
        return fr[0].text
    }
    const en = texts.filter(t => t.lang === 'en-GB')
    if (en.length) {
        return en[0].text
    }
    return texts[0].text
}

export { getText }
