/* eslint-disable react/prop-types */
import React, { forwardRef } from 'react'
import { Button, Grid, Icon, IconButton } from '@mui/material'
import { styled } from '@mui/styles'
import { sieauTooltip } from 'utils/FormUtils'
import { disabledColor, mainBlue, mainWhite } from 'utils/constants/ColorTheme'
import { getHoverColor } from 'utils/ColorUtil'

export const ButtonMUI = styled(forwardRef((props, ref) => (
    <Button
        {...props}
        variant={props.variant || 'contained'}
        sx={{
            '&:focus': { backgroundColor: (theme) => `${props.variant === 'outlined' ? 'white' : theme.palette.primary.main} !important` },
            ...props.sx,
        }}
        ref={ref}
    />)))({
})

export const IconButtonMUI = styled((props) => {
    const color = props.color || mainBlue
    const bgColor = !props.disabled ? color : disabledColor
    return (
        <Grid
            item
            {...props}
            sx={{
                padding: '1.355rem',
                borderRadius: '50%',
                backgroundColor: bgColor,
                cursor: !props.disabled ? 'pointer' : 'cursor',
                '&:hover': {
                    backgroundColor: !props.disabled && getHoverColor(bgColor),
                },
                '&:focus': {
                    backgroundColor: !props.disabled && bgColor,
                },
                ...props.sx,
            }}
            onClick={!props.disabled && props?.onClick}
            {...(props?.tooltip && sieauTooltip(props.tooltip))}
        >
            <Icon sx={{ color: mainWhite, fontSize: '1.6rem' }}>{props?.icon}</Icon>
        </Grid>)
})({
})

// Just set position: 'relative' on the parent component
export const FileIconMUI = styled((props) => (
    <IconButton
        aria-label='change picture'
        component='label'
        {...(props?.tooltip && sieauTooltip(props.tooltip))}
        sx={{
            position: 'absolute',
            top: '0px',
            right: '25%',
            backgroundColor: '#35609f',
            color: 'white',
            borderRadius: '50%',
            fontSize: '1.5rem',
            width: '3rem',
            height: '3rem',
            padding: '0.75rem',
            '&:hover': {
                backgroundColor: '#6dafff',
            },
            '&:focus': {
                backgroundColor: '#35609f',
            },
        }}
        {...props}
    >
        <input hidden accept='image/*' type='file' multiple />
        <Icon>{props?.icon || 'edit'}</Icon>
    </IconButton>))({
})
