import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { push } from '@lagunovsky/redux-react-router'
import { isEqual, omit } from 'lodash'
import i18n from 'simple-react-i18n'
import moment from 'moment'
import 'moment/locale/fr'
import ToastrAction from 'toastr/actions/ToastrAction'
import ActionComponent from 'components/ActionComponent'
import SieauAction from 'components/sieau/SieauAction'
import Input from 'components/forms/Input'
import { getUser } from 'utils/SettingUtils'
import NumberField from 'components/forms/NumberField'
import Card from 'components/card/Card'
import Checkbox from 'components/forms/Checkbox'
import HomeAction from 'home/actions/HomeAction'
import AgriAction from 'agriAdministration/actions/AgriAction'
import DtoAgriTanksType from 'exploitations/dto/DtoAgriTanksType'
import ProgressBar from 'components/progress/ProgressBar'
import { AgriActionConstant } from 'agriAdministration/reducers/AgriReducer'

moment.locale('fr')

class TerritoryTanksTypeApp extends ActionComponent {
    constructor(props) {
        super(props)
        this.state = {
            isEditMode: props.isEditMode,
            agriTanksType: {},
            dataLoaded: false,
        }
    }

    componentDidMount() {
        const { params } = this.props
        if (params.id !== 'new') {
            this.changeEditMode(false)
            this.props.fetchTanksType(params.id).then(() => this.setState({ dataLoaded: true }))
        } else {
            this.changeEditMode(true)
            this.setState({ dataLoaded: true })
        }
        this.props.setHelpLink('', '')
        this.toNavbarTanksType()
    }

    changeStateAttribute = (obj) => {
        this.setState(({ agriTanksType }) => ({
            agriTanksType: {
                ...agriTanksType,
                ...obj,
            },
        }))
    }

    changeEditMode = (bool) => {
        this.setState({ isEditMode: bool })
    }

    setReadMode = () => {
        return {
            edit: () => {
                this.changeEditMode(true)
            },
            delete: () => {
                this.props.deleteTanksType(this.props.agriTanksType.id).then(() => this.props.push('/territory/settings/tanks'))
            },
        }
    }

    toNavbarTanksType() {
        const { params } = this.props
        const { isEditMode } = this.state
        const actions = (() => {
            if (!isEditMode) {
                return this.setReadMode()
            }
            if (params.id === 'new' && (!this.props.agriTanksType || !this.props.agriTanksType.id)) {
                return {
                    save: () => {
                        const { agriTanksType } = this.state
                        this.props.createTanksType(agriTanksType).then((id) => {
                            if (id) {
                                this.props.push(`/territory/settings/tank/${id}`)
                            } else {
                                this.props.push('/territory/settings/tanks')
                            }
                        })
                        this.changeEditMode(false)
                    },
                    cancel: () => {
                        this.props.push('/territory/settings/tanks')
                        this.changeEditMode(false)
                    },
                }
            }
            if (isEditMode) {
                return {
                    save: () => {
                        const { agriTanksType } = this.state
                        this.props.updateTanksType(agriTanksType.id, agriTanksType)
                        this.changeEditMode(false)
                    },
                    cancel: () => {
                        this.setState({ agriTanksType: this.props.agriTanksType })
                        this.changeEditMode(false)
                    },
                }
            }
            return this.setReadMode()
        })()
        if (getUser().consultant === '1') {
            this.setActions(omit(actions, ['save', 'edit', 'deleteCheck', 'replace']))
        } else {
            this.setActions(actions)
        }
    }

    componentDidUpdate = prevProps => {
        const { agriTanksType } = this.props
        const { params } = this.props
        const { isEditMode } = this.state
        if (!isEqual(prevProps.agriTanksType, agriTanksType)) {
            this.setState({ agriTanksType })
        }
        if (!isEqual(isEditMode, prevProps.isEditMode)) {
            this.toNavbarTanksType()
        }
        if (prevProps.params.id !== params.id) {
            this.props.fetchTanksType(params.id)
        } else if (params.id === 'new') {
            this.props.setTitle([{
                title: i18n.steering,
                href: 'territory',
            },
            {
                title: i18n.settings,
                href: 'territory/settings',
            },
            {
                title: i18n.detentions,
                href: 'territory/settings/tanks',
            },
            {
                title: i18n.new,
                href: 'territory/settings/tank/new',
            }])
        } else if (!isEqual(prevProps.agriTanksType, agriTanksType)) {
            this.props.setTitle([{
                title: i18n.steering,
                href: 'territory',
            },
            {
                title: i18n.settings,
                href: 'territory/settings',
            },
            {
                title: i18n.detentions,
                href: 'territory/settings/tanks',
            },
            {
                title: params.id + (agriTanksType.name ? ` - ${agriTanksType.name}` : ''),
                href: `territory/settings/tank/${params.id}`,
            }])
            this.setState({ agriTanksType })
            this.toNavbarTanksType()
        }
    }

    componentWillUnmount = () => this.props.resetAgriTanksType()

    render() {
        const { isEditMode, agriTanksType, dataLoaded } = this.state
        const disabled = {
            active: isEditMode,
            disabled: !isEditMode,
        }

        return (
            <div className='row no-margin padding-1'>
                {dataLoaded ? (
                    <Card title={i18n.description} maxWidth={1000} round>
                        <div className='padding-1'>
                            <div className='row no-margin padding-top-1'>
                                <Input
                                    col={6}
                                    title={i18n.name}
                                    value={agriTanksType.name}
                                    onChange={value => this.changeStateAttribute({ name: value })}
                                    maxLength={255}
                                    {...disabled}
                                />
                                <NumberField
                                    col={3}
                                    title={i18n.id}
                                    value={agriTanksType.id}
                                    onChange={value => this.changeStateAttribute({ id: value })}
                                    disabled
                                />
                            </div>
                            <div className='row no-margin padding-top-1' style={{ display: 'flex', alignItems: 'center' }}>
                                <NumberField
                                    col={6}
                                    title={i18n.percent}
                                    value={agriTanksType.percent}
                                    onChange={value => this.changeStateAttribute({ percent: value })}
                                    min={0}
                                    max={100}
                                    {...disabled}
                                />
                                <Checkbox
                                    col={6}
                                    label={i18n.eligibility}
                                    checked={agriTanksType.eligibility}
                                    onChange={v => this.changeStateAttribute({ eligibility: v })}
                                    {...disabled}
                                />
                            </div>
                        </div>
                    </Card>
                ) : <ProgressBar indeterminate withMessage />}
            </div>
        )
    }
}

TerritoryTanksTypeApp.propTypes = {
    params: PropTypes.shape({
        id: PropTypes.string,
    }),
    agriTanksType: PropTypes.instanceOf(DtoAgriTanksType),
}

const mapStateToProps = store => ({
    agriTanksType: store.AgriReducer.agriTanksType,
})

const mapDispatchToProps = {
    push,
    toastrError: ToastrAction.error,
    setPopup: SieauAction.setPopup,
    deleteTanksType: AgriAction.deleteTanksType,
    createTanksType: AgriAction.createTanksType,
    updateTanksType: AgriAction.updateTanksType,
    fetchTanksType: AgriAction.fetchTanksType,
    resetAgriTanksType: AgriActionConstant.resetTanksTypes,
    setTitle: HomeAction.setTitle,
    setHelpLink: HomeAction.setHelpLink,
}


export default connect(mapStateToProps, mapDispatchToProps)(TerritoryTanksTypeApp)
