import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import Card from 'components/card/Card'
import Select from 'components/forms/Select'
import Row from 'components/react/Row'
import { getSandreList } from 'utils/StoreUtils'
import { Button, Grid } from '@mui/material'
import Input from 'components/forms/Input'
import NumberField from 'components/forms/NumberField'

const emptyFilter = {
    searchValue: undefined,
    year: undefined,
    status: undefined,
}

const FormFilterPARs = ({ onChange = () => { } }) => {
    const [tmpFilter, setTmpFilter] = useState(emptyFilter)

    const {
        sandreCodes,
    } = useSelector(store => ({
        sandreCodes: store.ReferencialReducer.sandreCodes,
    }), shallowEqual)

    const onReset = () => {
        onChange(emptyFilter)
        setTmpFilter(emptyFilter)
    }

    return (
        <Row>
            <Card round>
                <Row className='padding-top-1'>
                    <Row>
                        <Input
                            col={4}
                            containerClassName='no-margin'
                            title={ i18n.search }
                            value={ tmpFilter.searchValue }
                            onChange={(v) => setTmpFilter({ ...tmpFilter, searchValue: v })}
                            onEnterKeyPress={() => onChange(tmpFilter)}
                        />
                        <NumberField
                            col={4}
                            title={i18n.year}
                            value={tmpFilter.year}
                            onChange={v => setTmpFilter({ ...tmpFilter, year: v })}
                        />
                        <Select
                            col={4}
                            label={i18n.status}
                            options={getSandreList(sandreCodes, 'AGRI_PAR.STATUT')}
                            value={tmpFilter.status}
                            onChange={v => setTmpFilter({ ...tmpFilter, status: v })}
                            integerValue
                            keyValue='code'
                            nullLabel='&nbsp;'
                        />
                    </Row>
                    <Grid container className='padding-1' justifyContent='flex-end'>
                        <Grid item>
                            <Button variant='outlined' onClick={onReset}>
                                {i18n.reinit}
                            </Button>
                        </Grid>
                        <Grid item className='padding-left-1'>
                            <Button variant='contained' onClick={() => onChange(tmpFilter)}>
                                {i18n.search}
                            </Button>
                        </Grid>
                    </Grid>
                </Row>
            </Card>
        </Row>
    )
}

FormFilterPARs.propTypes = {
    onChange: PropTypes.func,
}

export default FormFilterPARs