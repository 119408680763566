module.exports = {
    RECEIVE_STATION_EVENTS: 'RECEIVE_STATION_EVENTS',
    RECEIVE_STATIONS_EVENTS: 'RECEIVE_STATIONS_EVENTS',
    RECEIVE_EVENT: 'RECEIVE_EVENT',
    RESET_EVENT: 'RESET_EVENT',
    RESET_STATION_EVENTS: 'RESET_STATION_EVENTS',
    RECEIVE_CMS_CATEGORIES: 'RECEIVE_CMS_CATEGORIES',
    RECEIVE_DOCUMENTS_STATS: 'RECEIVE_DOCUMENTS_STATS',
    RECEIVE_DOCUMENTS_STATS_BY_USER: 'RECEIVE_DOCUMENTS_STATS_BY_USER',
    RECEIVE_DOCUMENTS_STATS_BY_MODULE: 'RECEIVE_DOCUMENTS_STATS_BY_MODULE',
    RECEIVE_MONITORED_STATIONS_EVENTS: 'RECEIVE_MONITORED_STATIONS_EVENTS',
    UPDATE_CMS_CATEGORIES: 'UPDATE_CMS_CATEGORIES',
    RECEIVE_CMS_EVENT: 'RECEIVE_CMS_EVENT',
    RESET_CMS_EVENT: 'RESET_CMS_EVENT',
    RECEIVE_CMS_DOCUMENT: 'RECEIVE_CMS_DOCUMENT',
    RECEIVE_PIEZOMETRY_ALL_EVENTS: 'RECEIVE_PIEZOMETRY_ALL_EVENTS',
    RECEIVE_QUALITOMETRY_ALL_EVENTS: 'RECEIVE_QUALITOMETRY_ALL_EVENTS',
    RECEIVE_HYDROMETRY_STATION_ALL_EVENTS: 'RECEIVE_HYDROMETRY_STATION_ALL_EVENTS',
    RECEIVE_INSTALLATION_ALL_EVENTS: 'RECEIVE_INSTALLATION_ALL_EVENTS',
    RECEIVE_EXPLOITATIONS_ALL_EVENTS: 'RECEIVE_EXPLOITATIONS_ALL_EVENTS',
    RECEIVE_PIEZOMETER_DIAGNOSTICS: 'RECEIVE_PIEZOMETER_DIAGNOSTICS',
    RECEIVE_PIEZOMETER_DIAGNOSTICS_LINK_MATERIALS: 'RECEIVE_PIEZOMETER_DIAGNOSTICS_LINK_MATERIALS',
    RECEIVE_PIEZOMETER_DIAGNOSTICS_LINK_EVENT_TYPES: 'RECEIVE_PIEZOMETER_DIAGNOSTICS_LINK_EVENT_TYPES',
    RECEIVE_PIEZOMETER_SOLUTIONS: 'RECEIVE_PIEZOMETER_SOLUTIONS',
    RECEIVE_PIEZOMETER_SOLUTION_LINK_PROBLEMS: 'RECEIVE_PIEZOMETER_SOLUTION_LINK_PROBLEMS',
    RECEIVE_PIEZOMETER_ACTIONS: 'RECEIVE_PIEZOMETER_ACTIONS',
    RECEIVE_ALL_PIEZOMETER_ACTIONS: 'RECEIVE_ALL_PIEZOMETER_ACTIONS',
    RECEIVE_EVENT_REPLACEMENT_ACTIONS: 'RECEIVE_EVENT_REPLACEMENT_ACTIONS',
    RECEIVE_DIAGNOSTICS_AND_SOLUTIONS: 'RECEIVE_DIAGNOSTICS_AND_SOLUTIONS',
    RECEIVE_TYPES_CATEGORIES: 'RECEIVE_TYPES_CATEGORIES',
    RECEIVE_CMS_SURVEY: 'RECEIVE_CMS_SURVEY',
    RECEIVE_CMS_SURVEY_ID: 'RECEIVE_CMS_SURVEY_ID',
    RECEIVE_CMS_MODEL: 'RECEIVE_CMS_MODEL',
    RECEIVE_ALL_CMS_MODEL: 'RECEIVE_ALL_CMS_MODEL',
    RECEIVE_CMS_MODEL_STATIONS: 'RECEIVE_CMS_MODEL_STATIONS',
    RECEIVE_CMS_MODEL_OBSTACLES: 'RECEIVE_CMS_MODEL_OBSTACLES',
    RECEIVE_CMS_MODEL_TIDE_GAUGES: 'RECEIVE_CMS_MODEL_TIDE_GAUGES',
    RECEIVE_CAUSE_INTERVENTION: 'RECEIVE_CAUSE_INTERVENTION',
    EVENT_TYPES: [
        { code: 'T', id: 0, label: 'technique', color: 'BROWN', icon: 'build' },
        { code: 'G', id: 1, label: 'preventive', color: 'BLUE', icon: 'directions_run' },
        { code: 'M', id: 2, label: 'curative', color: 'RED', icon: 'build' },
        { code: 'P', id: 3, label: 'toPlan', color: 'YELLOW', icon: 'assignment' },
        { code: 'S', id: 4, label: 'toMonitor', color: 'ORANGE', icon: 'notifications' },
        { code: 'C', id: 5, label: 'comment', color: 'DARKGREEN', icon: 'insert_comment' },
    ],
    EVENT_TYPES_CODES: {
        PREVENTIVE: 'G',
        CURATIVE: 'M',
        TO_PLAN: 'P',
        TO_MONITOR: 'S',
        COMMENT: 'C',
        TECHNIQUE: 'T',
    },
}
