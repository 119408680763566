import { StyledFieldSet, StyledLegend, StyledSpan } from '../../../../../components/StyledElements'
import i18n from 'simple-react-i18n'
import Row from '../../../../../components/react/Row'
import Input from '../../../../../components/forms/Input'
import { hasValue } from '../../../../../utils/NumberUtil'
import StationImportCodeMessage from '../../../../../import/components/utils/StationImportCodeMessage'
import React from 'react'
import Select from '../../../../../components/forms/Select'
import { shallowEqual, useSelector } from 'react-redux'


const AddQualitoStepOptionsEmpty = ({
    station,
    changeStation,
}) => {
    const {
        cities,
    } = useSelector(store => ({
        cities: store.CityReducer.cities,
    }), shallowEqual)

    return (
        <StyledFieldSet>
            <StyledLegend>
                <StyledSpan>{i18n.options}</StyledSpan>
            </StyledLegend>
            <div>
                <Row className='padding-top-2 '>
                    <Input col={3} value={station.code} onChange={v => changeStation({ code: v })} title={i18n.code} noSpace/>
                    <Input col={5} value={station.name} onChange={v => changeStation({ name: v })} title={i18n.name}/>
                    <Select col={4} value={station.townCode} options={cities} onChange={v => changeStation({ townCode: v })} label={i18n.city}/>
                </Row>
                <Row>
                    { hasValue(station.code) && <StationImportCodeMessage code={station.code} stationType='quality'/> }
                </Row>
            </div>
        </StyledFieldSet>
    )
}

AddQualitoStepOptionsEmpty.propTypes = {
}

export default AddQualitoStepOptionsEmpty