import { push } from '@lagunovsky/redux-react-router'
import PropTypes from 'prop-types'
import React from 'react'
import { useDispatch } from 'react-redux'
import i18n from 'simple-react-i18n'
import { getI18nTitleDataLength } from 'utils/StringUtil'
import DtoCampaignInstallationWithStats from '../../installation/components/campaign/dto/DtoCampaignInstallationWithStats'
import ContactDto from '../../referencial/components/contact/dto/ContactDto'
import { getCampaignControlledLabel, getColorCampaign, getStationCampaignLabel } from '../../utils/CampaignUtil'
import CampaignDto from '../dto/CampaignDto'
import DtoCampaignProgress from '../dto/DtoCampaignProgress'
import { getCampaignIcon, getCampaignProgress } from '../utils/CampaignUtils'
import { Box, Grid } from '@mui/material'
import Icon from 'components/icon/Icon'
import SimpleCircularProgressWithLabel from 'components/progress/SimpleCircularProgressWithLabel'

const MUICampaignCard = ({
    progress = {},
    campaign = {},
    stationType = '',
}) => {
    const dispatch = useDispatch()

    const {
        id,
        idCampaign,
        name,
        statut,
        isArchived,
        campaignType,
    } = campaign

    const {
        nbStation = 0,
        nbStationValidated = 0,
        progress: progressValidated,
        progressTotal,
    } = progress

    const progressValue = getCampaignProgress(progressValidated, progressTotal)
    const labels = getCampaignControlledLabel(stationType, nbStationValidated, false)

    return (
        <Grid
            container item xs={12}
            alignItems='center'
            columnSpacing={1.5}
            onClick={() => dispatch(push(`/${stationType}/campaign/${id || idCampaign}`))}
            className='clickable'
        >
            <Grid item>
                <Box sx={{ backgroundColor: getColorCampaign(isArchived, progressValue, statut), width: '0.75rem', height: '0.75rem', borderRadius: '50rem' }} />
            </Grid>
            <Grid container item xs alignItems='center'>
                <Grid item xs={12} sx={{ '& h6': { whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', fontWeight: 500 } }}>
                    <h6 className='station-title no-margin no-padding'>{name}</h6>
                </Grid>
                <Grid item xs={12} sx={{ '& p': { whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' } }}>
                    <p className='station-code' style={{ margin: 0, paddingTop: '0.25rem' }}>
                        {`${nbStationValidated}
                            ${getStationCampaignLabel(stationType, nbStationValidated)}
                            ${labels.done} (${getI18nTitleDataLength(i18n.planned, i18n.planneds, nbStation)} : ${nbStation})`}
                    </p>
                </Grid>
            </Grid>
            <Grid item>
                {getCampaignIcon(campaignType, 'small')}
            </Grid>
            <Grid item>
                {(statut === 1) ? (
                    <Icon sx={{ cursor: 'default' }}>create</Icon>
                ) : (
                    <SimpleCircularProgressWithLabel value={progressValue} />
                )}
            </Grid>
        </Grid>
    )
}

MUICampaignCard.propTypes = {
    campaign: PropTypes.oneOfType([
        PropTypes.instanceOf(CampaignDto),
        PropTypes.instanceOf(DtoCampaignInstallationWithStats),
    ]),
    progress: PropTypes.instanceOf(DtoCampaignProgress),
    contact: PropTypes.instanceOf(ContactDto),
    stationType: PropTypes.string,
}

export default MUICampaignCard
