export default class DtoSTEPFollowPoint {
    constructor(obj) {
        this.idInstallation = obj.idInstallation // Option[Long],
        this.sandreCode = obj.sandreCode // Option[Long],
        this.internalCode = obj.internalCode // Option[String],
        this.comment = obj.comment // Option[String],
        this.x = obj.x // Option[Double],
        this.y = obj.y // Option[Double],
        this.projection = obj.projection // Option[Double]
    }
}