import PropTypes from 'prop-types'
import React, { useState } from 'react'
import i18n from 'simple-react-i18n'
import Input from '../../../components/forms/Input'
import { Button, Grid, Icon } from '@mui/material'
import { DialogActionsMUI, DialogContentMUI, DialogMUI, DialogTitleMUI } from 'components/styled/Dialog'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import useApplicationSetting from 'utils/customHook/useApplicationSetting'
import { getFullDate } from 'utils/DateUtil'
import moment from 'moment'
import HomeAction from 'home/actions/HomeAction'
import { StyledFieldSet } from 'components/StyledElements'
import SimpleTextArea from 'components/forms/SimpleTextArea'

const ContactMailDialog = ({
    isOpen = false,
    setIsOpen = () => { },
    email = '',
    setEmail = () => {},
}) => {
    const dispatch = useDispatch()
    const [contentEmail, setContentEmail] = useState('')

    const supportMailAddress = useApplicationSetting('supportMailAddress')

    const {
        helpLink,
    } = useSelector(store => ({
        helpLink: store.HomeReducer.helpLink,
    }), shallowEqual)

    const onSendEmail = () => {
        const mail = {
            to: supportMailAddress,
            from: email,
            subject: `${i18n.support} - ${helpLink.module ? helpLink.module : location.pathname === '/' ? 'dashboard' : location.pathname.split('/')[1]}`,
            message: contentEmail.replace(/(\r\n|\n|\r)/gm, '<br />'),
            date: getFullDate(moment().valueOf()),
            url: window.location.href,
            navigator: navigator.userAgent,
            screen: `Screen: ${screen.width} x ${screen.height}`,
        }
        dispatch(HomeAction.sendMailToSupport(mail)).then(() => {
            setContentEmail('')
            setIsOpen(false)
        })
    }

    return (
        <DialogMUI
            open={isOpen}
            maxWidth='md'
            PaperProps={{
                sx: {
                    minHeight: '60vh',
                    maxHeight: '60vh',
                },
            }}
        >
            <DialogTitleMUI style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                {i18n.contactSupport}
                <Icon style={{ color: 'white' }} size='small' onClick={() => setIsOpen(false)}>close</Icon>
            </DialogTitleMUI>
            <DialogContentMUI>
                <StyledFieldSet>
                    <Grid container justifyContent='space-between' spacing={2}>
                        <Grid item xs={12}>
                            <Input
                                title={i18n.email}
                                value={email}
                                onChange={setEmail}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <SimpleTextArea
                                title={i18n.content}
                                value={contentEmail}
                                onChange={setContentEmail}
                                rows={10}
                            />
                        </Grid>
                    </Grid>
                </StyledFieldSet>
            </DialogContentMUI>
            <DialogActionsMUI>
                <Button
                    onClick={onSendEmail}
                    variant='contained'
                    color='primary'
                >
                    {i18n.validate}
                </Button>
            </DialogActionsMUI>
        </DialogMUI>
    )
}

ContactMailDialog.propTypes = {
    isOpen: PropTypes.bool,
    setIsOpen: PropTypes.func,
    email: PropTypes.string,
    setEmail: PropTypes.func,
}

export default ContactMailDialog
