/* eslint-disable camelcase */
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { arrayOf } from '../../../../utils/StoreUtils'
import i18n from 'simple-react-i18n'
import DtoSandreCode from '../../../../referencial/dto/DtoSandreCode'
import Table from '../../../../components/datatable/Table'
import SieauAction from '../../../../components/sieau/SieauAction'
import { getExport, setModal } from '../../../../utils/linkUtils'
import ElecmecEquipmentModal from './modal/ElecmecEquipmentModal'
import DtoInstallation from 'installation/dto/installation/DtoInstallation'

const ElecmecEquipmentPanel = ({
    station = {},
    readMode = false,
    onChange = () => { },
    setPopup = () => { },
}) => {
    const stationelecmecEquipments = station.link_elecmecEquipments.map((c, index) => ({
        ...c,
        index,
    }))
    const tableData = stationelecmecEquipments.map(c => ({
        ...c,
        waterHammer: c.waterHammer ? <i className='material-icons tiny table-checkbox'>check_box</i> : null,
    }))
    const headers = ['numGroup', 'nameGroup', 'nominalFlow', 'hmt', 'numberPumps', 'waterHammer']
    const exportAction = getExport(tableData, i18n.elecmecEquipments, headers)
    const actions = !readMode ? [{
        iconName: 'note_add',
        tooltip: i18n.add,
        onClick: () => setModal(i18n.newElecmecEquipments, <ElecmecEquipmentModal id={station.idStation} saveResult={r => onChange({ link_elecmecEquipments: [...stationelecmecEquipments, r] })} />, setPopup),
    }, exportAction] : [exportAction]

    return (
        <Table
            title={i18n.elecmecEquipments}
            condensed
            className='blue'
            data={tableData}
            type={{ headers }}
            sortable={!!tableData.length}
            actions={actions}
            alterable={!readMode}
            onAlter={
                (element) => setModal(i18n.editElecmecEquipments,
                    <ElecmecEquipmentModal
                        id={station.idStation}
                        elecmecEquipment={stationelecmecEquipments.find(c => element.index === c.index)}
                        saveResult={r => onChange({
                            link_elecmecEquipments: [
                                ...stationelecmecEquipments.filter(lc => lc.index !== element.index),
                                { ...r, index: element.index },
                            ],
                        })}
                    />
                    , setPopup)
            }
            deletable={!readMode}
            onDelete={(element) => onChange({ link_elecmecEquipments: stationelecmecEquipments.filter(lc => lc.index !== element.index) })}
        />
    )
}

ElecmecEquipmentPanel.propTypes = {
    station: PropTypes.instanceOf(DtoInstallation),
    sandreCodes: arrayOf(DtoSandreCode),
    readMode: PropTypes.bool,
    onChange: PropTypes.func,
    setPopup: PropTypes.func,
}

const mapStateToProps = store => ({
    sandreCodes: store.ReferencialReducer.sandreCodes,
})

const mapDispatchToProps = {
    setPopup: SieauAction.setPopup,
}

export default connect(mapStateToProps, mapDispatchToProps)(ElecmecEquipmentPanel)