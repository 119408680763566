import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import { isEqual, omit } from 'lodash'
import WaitAction from 'wait/WaitAction'
import DtoStationSimAssignment from '../../../../dto/materiel/DtoStationSimAssignment'
import DtoMaterielState from '../../../../../materiel/dto/DtoMaterielState'
import MaterielAction from '../../../../../materiel/actions/MaterielAction'
import SimDto from '../../../../../materiel/components/sim/dto/SimDto'
import SimAction from '../../../../../materiel/components/sim/actions/SimAction'
import DtoSimType from '../../../../../materiel/components/sim/dto/DtoSimType'
import StationAction from '../../../../actions/StationAction'
import SimPanel from '../../../../../materiel/components/sim/components/SimPanel'
import SituationDetailPanel from '../SituationDetailPanel'
import DtoStation from '../../../../dto/DtoStation'
import SubscriptionAction from '../../../../../materiel/components/subscription/actions/SubscriptionAction'
import DtoSubscription from '../../../../dto/materiel/DtoSubscription'
import SubscriptionTypeDto from '../../../../../materiel/components/subscription/dto/SubscriptionTypeDto'
import Select from '../../../../../components/forms/Select'
import SimSubscriptionDto from '../../../../../materiel/components/sim/dto/SimSubscriptionDto'
import Checkbox from '../../../../../components/forms/Checkbox'
import DtoSimSituation from '../../../../../materiel/components/sim/dto/DtoSimSituation'

class SimSituationDetail extends Component {
    state = {
        sim: {},
        simDefaultParam: {},
        associatedSubscriptionId: -1,
        keepLastSubscription: false,
    }

    componentDidMount() {
        const {
            materielStates,
            sims,
            simTypes,
            subscriptions,
            subscriptionTypes,
        } = this.props
        if (!materielStates.length) {
            this.props.fetchMaterielStates()
        }
        if (!sims.length) {
            this.props.fetchSims()
        }
        if (!simTypes.length) {
            this.props.fetchSimTypes()
        }
        if (!subscriptions.length) {
            this.props.fetchSubscriptions()
        }
        if (!subscriptionTypes.length) {
            this.props.fetchSubscriptionTypes()
        }
        if (!this.props.simSubscriptionsLastSituations.length) {
            this.props.fetchSimSubscriptionsLastSituations()
        }
    }

    getSimTitle = ({ serialNumber, reference }, { label }) => {
        const code = serialNumber || reference || ''
        return label ? `${label} - ${code}` : code || i18n.unknownMaterial
    }

    setSimTypeParam = () => {
        const { simType } = this.state.sim
        const type = this.props.simTypes.find(t => t.id === simType) || {}
        this.setState({
            sim: {
                ...this.state.sim,
                ...omit(type, ['id', 'code', 'label', 'name', 'comment', 'updateDate', 'updateLogin', 'startDate', 'endDate', 'icon', 'headers']),
            },
        })
    }

    getSimPanel = isEditMode => {
        const { sim } = this.state
        if (sim.id) {
            const simType = this.props.simTypes.find(t => t.id === sim.simType) || {}
            return (
                <div>
                    <div className='col s8 offset-s2'>
                        <div className='col s4 offset-s2'>
                            <a className='col s12 btn' onClick={ () => this.setState({
                                sim: {
                                    ...this.state.simDefaultParam,
                                },
                            }) }
                            >
                                { i18n.defaultSettings }
                            </a>
                        </div>
                        <div className='col s4'>
                            <a className='col s12 btn' onClick={ this.setSimTypeParam }>
                                { i18n.typeSettings }
                            </a>
                        </div>
                    </div>
                    <div className='row col s10 offset-s1'>
                        <div className='card no-padding'>
                            <div className='col s12 card-title active'>
                                { this.getSimTitle(sim, simType) }
                            </div>
                            <div className={ 'card-content margin-top-1' }>
                                <div className='row no-margin'>
                                    <SimPanel id={ sim.id }
                                        sim={ sim }
                                        onChange={ changes => this.setState({ sim: { ...this.state.sim, ...changes } }) }
                                        disabled={ !isEditMode }
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        return null
    }

    fetchSim = id => {
        this.props.waitStart()
        this.props.fetchSim(id).then(json => {
            const associatedSubscriptionId = this.props.simSubscriptionsLastSituations.find(sit => sit.idSim === id) || {}
            this.setState({
                sim: json,
                simDefaultParam: json,
                associatedSubscriptionId: associatedSubscriptionId.idSubscription,
            })
            this.props.waitStop()
        })
    }

    updateSim = () => {
        if (!isEqual(this.state.sim, this.state.simDefaultParam)) {
            this.props.updateSim(this.state.sim)
        }
    }

    resetSim = () => {
        this.props.resetSim()
        this.setState({ sim: {}, simDefaultParam: {} })
    }

    getMaterielNumber = ({ imei, serialNumber, reference, numLine, id }) => imei || serialNumber || reference || numLine || id || ''

    getSubscriptionsData = () => {
        const { subscriptions, subscriptionTypes } = this.props
        return subscriptions.map(o => {
            const label = (() => {
                const result = this.getMaterielNumber(o)
                if (o.subscriptionType) {
                    const type = subscriptionTypes.find(b => b.id === o.subscriptionType)
                    if (type) {
                        return `${type.label} - ${result}`
                    }
                }
                return result
            })()
            return {
                code: o.id,
                label,
            }
        })
    }

    additionalStep = ({ shouldReplace, replaceMat }) => {
        const {
            associatedSubscriptionId,
            keepLastSubscription,
        } = this.state
        const prevSub = this.props.simSubscriptionsLastSituations.find(sit => sit.idSim === replaceMat) || {}
        const subscriptions = this.getSubscriptionsData()
        return (
            <div className='row no-margin'>
                <div className='col s12 center'>
                    <h5>{i18n.associatedSimSubscription}</h5>
                </div>
                {
                    shouldReplace && (
                        <div className='col s12'>
                            <Checkbox
                                col={12}
                                label={i18n.keepLastSubscription}
                                checked={keepLastSubscription}
                                onChange={v => this.setState({ keepLastSubscription: v, associatedSubscriptionId: v ? prevSub.idSubscription : associatedSubscriptionId })}
                            />
                        </div>
                    )
                }
                <div className='col s12 padding-top-2'>
                    <Select
                        col={12}
                        options={subscriptions}
                        label={i18n.subscriptions}
                        nullLabel='&nbsp;'
                        onChange={v => this.setState({ associatedSubscriptionId: v })}
                        value={associatedSubscriptionId}
                    />
                </div>
            </div>
        )
    }

    addSituation = (situation, stationId, stationType) => {
        if (situation.idSim === this.state.sim.id) {
            const prevSub = this.props.simSubscriptionsLastSituations.find(sit => sit.idSim === situation.idSim) || {}
            if (prevSub.idSubscription !== this.state.associatedSubscriptionId) {
                const simSubscription = {
                    idSubscription: this.state.associatedSubscriptionId,
                    idSim: situation.idSim,
                    startDate: situation.situationDate,
                }
                return this.props.createSimSubscription(simSubscription).then(() => {
                    this.props.fetchSimSubscriptionsLastSituations()
                    this.props.fetchSimsLink()
                    this.props.addSimSituation(situation, stationId, stationType)
                })
            }
        }
        return this.props.addSimSituation(situation, stationId, stationType)
    }

    render = () => {
        const {
            station,
            sims,
            simsLastSituations,
            simTypes,
            stationSimAssignments,
            saveSimSituation,
            deleteSimSituation,
            params,
            type,
        } = this.props
        return (
            <SituationDetailPanel
                params={params}
                type={type}
                getMaterielPanel={isEditMode => this.getSimPanel(isEditMode)}
                station={station}
                materiels={sims}
                lastSituations={simsLastSituations}
                materielTypes={simTypes}
                stationMaterielAssignments={stationSimAssignments}
                addSituation={this.addSituation}
                saveSituation={saveSimSituation}
                deleteSituation={deleteSimSituation}
                fetchMateriel={this.fetchSim}
                updateMateriel={this.updateSim}
                resetMateriel={this.resetSim}
                keyMaterielType='simType'
                keyMaterielId='idSim'
                additionalStep={additionnalProps => this.additionalStep(additionnalProps)}
            />
        )
    }
}

SimSituationDetail.propTypes = {
    params: PropTypes.shape({
        id: PropTypes.string,
        materialType: PropTypes.string,
    }),
    type: PropTypes.string,
    station: PropTypes.instanceOf(DtoStation),

    stationSimAssignments: PropTypes.arrayOf(PropTypes.instanceOf(DtoStationSimAssignment)),
    materielStates: PropTypes.arrayOf(PropTypes.instanceOf(DtoMaterielState)),
    sim: PropTypes.instanceOf(SimDto),
    sims: PropTypes.arrayOf(PropTypes.instanceOf(SimDto)),
    simsLastSituations: PropTypes.arrayOf(PropTypes.instanceOf(DtoSimSituation)),
    simTypes: PropTypes.arrayOf(PropTypes.instanceOf(DtoSimType)),
    subscriptions: PropTypes.arrayOf(PropTypes.instanceOf(DtoSubscription)),
    subscriptionTypes: PropTypes.arrayOf(PropTypes.instanceOf(SubscriptionTypeDto)),
    simSubscriptionsLastSituations: PropTypes.arrayOf(PropTypes.instanceOf(SimSubscriptionDto)),

    resetSim: PropTypes.func,
    updateSim: PropTypes.func,
    fetchSim: PropTypes.func,
    fetchSims: PropTypes.func,
    fetchMaterielStates: PropTypes.func,
    fetchSimTypes: PropTypes.func,
    saveSimSituation: PropTypes.func,
    addSimSituation: PropTypes.func,
    deleteSimSituation: PropTypes.func,
    fetchSubscriptions: PropTypes.func,
    fetchSubscriptionTypes: PropTypes.func,
    waitStart: PropTypes.func,
    waitStop: PropTypes.func,
    fetchSimSubscriptionsLastSituations: PropTypes.func,
    fetchSimsLink: PropTypes.func,
    createSimSubscription: PropTypes.func,
}

const mapStateToProps = store => ({
    stationSimAssignments: store.StationReducer.stationSimAssignments,
    simSubscriptionsLastSituations: store.SubscriptionReducer.simSubscriptionsLastSituations,
    materielStates: store.MaterielReducer.materielStates,
    sim: store.SimReducer.sim,
    sims: store.SimReducer.sims,
    simsLastSituations: store.SimReducer.simsLastSituations,
    simTypes: store.SimReducer.simTypes,
    subscriptions: store.SubscriptionReducer.subscriptions,
    subscriptionTypes: store.SubscriptionReducer.subscriptionTypes,
})

const mapDispatchToProps = {
    resetSim: SimAction.resetSim,
    updateSim: SimAction.updateSim,
    fetchSim: SimAction.fetchSim,
    fetchSims: SimAction.fetchSims,
    fetchMaterielStates: MaterielAction.fetchMaterielStates,
    fetchSimTypes: SimAction.fetchSimTypes,
    saveSimSituation: StationAction.saveSimSituation,
    addSimSituation: StationAction.addSimSituation,
    deleteSimSituation: StationAction.deleteSimSituation,
    fetchSubscriptions: SubscriptionAction.fetchSubscriptions,
    fetchSubscriptionTypes: SubscriptionAction.fetchSubscriptionTypes,
    waitStart: WaitAction.waitStart,
    waitStop: WaitAction.waitStop,
    fetchSimSubscriptionsLastSituations: SubscriptionAction.fetchSimSubscriptionsLastSituations,
    fetchSimsLink: SubscriptionAction.fetchSimsLink,
    createSimSubscription: SimAction.createSimSubscription,
}

export default connect(mapStateToProps, mapDispatchToProps)(SimSituationDetail)