import { maxBy, range } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import Checkbox from '../../../../components/forms/Checkbox'
import DatePicker from '../../../../components/forms/DatePicker'
import NumberField from '../../../../components/forms/NumberField'
import Select from '../../../../components/forms/Select'
import Textarea from '../../../../components/forms/Textarea'
import { SANDRE } from '../../../../referencial/constants/ReferencialConstants'
import DtoSandreCode from '../../../../referencial/dto/DtoSandreCode'
import { getDefaultValuesChoiceModal, getMatTypes } from '../../../../utils/MaterielUtils'
import { getSandreList } from '../../../../utils/StoreUtils'
import { MATERIEL_STATE_REFORMED_ID } from '../../../constants/MaterielStateConstants'
import GenericReferencialDto from '../../../dto/GenericReferencialDto'
import PowerSupplyTypeDto from '../../powerSupply/dto/PowerSupplyTypeDto'
import CentralDto from '../dto/CentralDto'
import DtoCentralSituation from '../dto/DtoCentralSituation'


const CentralEquipmentPanel = ({
    central = {},
    centralTypes = [],
    active = false,
    disabled = true,
    centralSituations = [],
    powerSupplyTypes = [],
    telecomTypes = [],
    sandreCodes = [],
    displayDesiccant = true,
    onChange = () => { },
}) => {
    const lastSituation = maxBy(centralSituations, 'situationDate')

    return (
        <div className='col s12 margin-bottom-1'>
            <div className='row no-margin valign-wrapper' style={{ paddingTop: '3px' }}>
                <Select
                    options={getMatTypes(centralTypes, central.centralType)}
                    label={i18n.type}
                    col={4}
                    onChange={value => {
                        onChange({ centralType: value })
                        getDefaultValuesChoiceModal(centralTypes.find(c => c.materielType == value), onChange)
                    }}
                    value={central.centralType}
                    nullLabel='&nbsp;'
                    active={active}
                    disabled={disabled}
                />
                <Select
                    options={getMatTypes(telecomTypes, central.telecomType)}
                    label={i18n.typeTelecom}
                    col={4}
                    onChange={value => onChange({ telecomType: value })}
                    value={central.telecomType}
                    nullLabel='&nbsp;'
                    active={active}
                    disabled={disabled}
                />
                <Select
                    options={getMatTypes(powerSupplyTypes, central.powerSupplyType)}
                    label={i18n.powerSupplyType}
                    col={4}
                    onChange={value => onChange({ powerSupplyType: value })}
                    value={central.powerSupplyType}
                    nullLabel='&nbsp;'
                    active={active}
                    disabled={disabled}
                />
            </div>
            <div className={`row no-margin ${displayDesiccant && 'valign-wrapper' || ''}`} style={{ paddingTop: '3px' }}>
                <NumberField
                    col={4}
                    title={i18n.batteryCount}
                    value={central.pileNumber}
                    onChange={value => onChange({ pileNumber: value })}
                    active={active}
                    disabled={disabled}
                />
                <NumberField
                    col={4}
                    title={i18n.supplyTime}
                    value={central.supplyTime}
                    onChange={value => onChange({ supplyTime: value })}
                    active={active}
                    disabled={disabled}
                />
                {
                    displayDesiccant && (
                        <Checkbox
                            col={4}
                            label={i18n.desiccant}
                            checked={central.desiccant === '1'}
                            onChange={value => onChange({ desiccant: value ? '1' : '0' })}
                            active={active}
                            disabled={disabled}
                        />
                    )
                }
            </div>
            <div className='row no-margin' style={{ paddingTop: '8px' }}>
                <Textarea
                    col={12}
                    title={i18n.comment}
                    value={central.comment}
                    onChange={value => onChange({ comment: value })}
                    active={active}
                    disabled={disabled}
                />
            </div>
            <div className='row no-margin' style={{ paddingTop: '8px' }}>
                <NumberField
                    title={i18n.transmissionVolume}
                    col={4}
                    onChange={value => onChange({ transmissionVolume: parseInt(value) })}
                    value={parseInt(central.transmissionVolume)}
                    active={active}
                    disabled={disabled}
                />
                <Select
                    options={getSandreList(sandreCodes, SANDRE.CENTRALE_FREQUENCE_TRANSMISSION)}
                    label={i18n.sendingFrequency}
                    col={4}
                    onChange={value => onChange({ callFrequency: parseInt(value) })}
                    value={parseInt(central.callFrequency)}
                    nullLabel='&nbsp;'
                    active={active}
                    disabled={disabled}
                />
                <Select
                    options={range(0, 24).map(h => ({ code: h, name: `${h}h` }))}
                    label={i18n.sendingTime}
                    col={4}
                    onChange={value => onChange({ callHour: parseInt(value) })}
                    value={parseInt(central.callHour)}
                    nullLabel='&nbsp;'
                    active={active}
                    disabled={disabled}
                />
            </div>
            {
                !!lastSituation && lastSituation.statusCode === MATERIEL_STATE_REFORMED_ID && (
                    <div className='row no-margin padding-top-8-px'>
                        <DatePicker
                            onChange={(_, v) => onChange({ withdrawalDate: v })}
                            id='withdrawalDate'
                            title={i18n.withdrawalDate}
                            value={central.withdrawalDate}
                            col={4}
                            active={active}
                            disabled={disabled}
                            startDate={central.purchaseDate}
                        />
                    </div>
                )
            }
        </div>
    )
}

CentralEquipmentPanel.propTypes = {
    disabled: PropTypes.bool,
    active: PropTypes.bool,
    isEquipment: PropTypes.bool,
    central: PropTypes.instanceOf(CentralDto),
    onChange: PropTypes.func,
    centralTypes: PropTypes.instanceOf(GenericReferencialDto),
    powerSupplyTypes: PropTypes.arrayOf(PropTypes.instanceOf(PowerSupplyTypeDto)),
    sandreCodes: PropTypes.arrayOf(PropTypes.instanceOf(DtoSandreCode)),
    centralSituations: PropTypes.arrayOf(PropTypes.instanceOf(DtoCentralSituation)),
    displayDesiccant: PropTypes.bool,
    telecomTypes: PropTypes.arrayOf(GenericReferencialDto),
}

const mapStateToProps = store => ({
    centralTypes: store.CentralReducer.centralTypes,
    powerSupplyTypes: store.PowerSupplyReducer.powerSupplyTypes,
    sandreCodes: store.ReferencialReducer.sandreCodes,
    centralSituations: store.CentralReducer.centralSituations,
    telecomTypes: store.TelecomReducer.telecomTypes,
})

export default connect(mapStateToProps)(CentralEquipmentPanel)
