import DtoFile from '../../../../components/file/dto/DtoFile'
import DtoPicture from '../../../../station/dto/DtoPicture'
import { RESET_ALL_MATERIELS } from '../../../constants/MaterielConstants'
import { RESET_EQUIPMENT } from '../../equipment/constants/EquipmentConstants'
import {
    RECEIVE_ALL_POWER_SUPPLIES,
    RECEIVE_ALL_POWER_SUPPLY_SITUATIONS,
    RECEIVE_POWER_SUPPLIES_LAST_SITUATIONS,
    RECEIVE_POWER_SUPPLY,
    RECEIVE_POWER_SUPPLY_FILES,
    RECEIVE_POWER_SUPPLY_PICTURES,
    RECEIVE_POWER_SUPPLY_TYPES,
    RESET_POWER_SUPPLY,
} from '../constants/PowerSupplyConstants'
import DtoPowerSupplySituation from '../dto/DtoPowerSupplySituation'
import PowerSupplyDto from '../dto/PowerSupplyDto'
import PowerSupplyTypeDto from '../dto/PowerSupplyTypeDto'

export const store = {
    powerSupplies: [],
    powerSupply: {},
    powerSupplyTypes: [],
    powerSupplySituations: [],
    powerSuppliesLastSituations: [],
    powerSupplyPictures: [],
    powerSupplyFiles: [],
}

export function PowerSupplyReducer(state = {}, action) {
    switch (action.type) {
        case RECEIVE_ALL_POWER_SUPPLIES:
            return {
                ...state,
                powerSupplies: action.powerSupplies.map(powerSupply => new PowerSupplyDto(powerSupply)),
            }
        case RECEIVE_POWER_SUPPLY:
            return {
                ...state,
                powerSupply: new PowerSupplyDto(action.powerSupply),
            }
        case RECEIVE_POWER_SUPPLY_TYPES:
            return {
                ...state,
                powerSupplyTypes: action.powerSupplyTypes.map(t => new PowerSupplyTypeDto(t)),
            }
        case RECEIVE_ALL_POWER_SUPPLY_SITUATIONS:
            return {
                ...state,
                powerSupplySituations: action.powerSupplySituations.map(t => new DtoPowerSupplySituation(t)),
            }
        case RESET_POWER_SUPPLY:
            return {
                ...state,
                powerSupply: {},
                powerSupplySituations: [],
                powerSupplyPictures: [],
                powerSupplyFiles: [],
            }
        case RESET_EQUIPMENT:
            return {
                ...state,
                powerSupplyTypes: [],
            }
        case RECEIVE_POWER_SUPPLIES_LAST_SITUATIONS:
            return {
                ...state,
                powerSuppliesLastSituations: action.powerSuppliesLastSituations.map(s => new DtoPowerSupplySituation(s)),
            }
        case RECEIVE_POWER_SUPPLY_PICTURES:
            return {
                ...state,
                powerSupplyPictures: action.powerSupplyPictures.map(el => new DtoPicture(el)),
            }
        case RECEIVE_POWER_SUPPLY_FILES:
            return {
                ...state,
                powerSupplyFiles: action.powerSupplyFiles.map(el => new DtoFile(el)),
            }
        case RESET_ALL_MATERIELS:
            return {
                ...state,
                ...store,
            }
        default:
            return state
    }
}