import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import IAction from './IAction'

class Save extends IAction {
    fn = () => {
        return this.props.onSave
    }
    icon = () => {
        return 'save'
    }
    label = () => {
        return i18n.save
    }

    id = () =>{
        return 'save_action_navbar'
    }
}

Save.propTypes = {
    onSave: PropTypes.func.isRequired,
}

export default Save
