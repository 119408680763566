import { push } from '@lagunovsky/redux-react-router'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import i18n from 'simple-react-i18n'
import ColorfulCard from '../../../components/card/ColorfulCard'
import { getEventType } from '../../../events/utils/EventUtils'
import AppStore from '../../../store/AppStore'
import { getEventColor } from '../../../utils/ColorUtil'
import { getDate, getHour } from '../../../utils/DateUtil'
import { findStationTypeUrl } from '../../../utils/StationUtils'

class GlobalEventsCard extends Component {
    redirect = path => () => {
        AppStore.dispatch(push(path))
    }

    getUpdateInfo = (event) => {
        return event.updateDate && event.updateLogin ? (
            <div className='row no-margin padding-left-5 grey lighten-4 font-size-10'>
                { `${i18n.lastUpdateOn} ${getDate(event.updateDate)} ${i18n.atHour} ${getHour(event.updateDate)} ${i18n.by} ${event.updateLogin}` }
            </div>
        ) : null
    }

    render() {
        const event = this.props.data
        return (
            <div className='card-list'>
                <ColorfulCard color={ getEventColor(event.eventType) } onClick={ this.redirect(findStationTypeUrl(event.stationType, event.code, event.number)) }>
                    <div className='row no-margin valign-wrapper padding-left-1'>
                        <div className='col s2'>
                            { getDate(event.date) }
                        </div>
                        <div className='col s8'>
                            <h6 className='event-title'>{ event.comment }</h6>
                        </div>
                        <div className='col s2'>
                            { getEventType(event) }
                        </div>
                    </div>
                    { this.getUpdateInfo(event) }
                </ColorfulCard>
            </div>
        )
    }
}

GlobalEventsCard.propTypes = {
    data: PropTypes.arrayOf(PropTypes.object),
}

export default GlobalEventsCard
