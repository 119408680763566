import React, { useState } from 'react'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import Select from '../../../../../components/forms/Select'
import { Button, Grid } from '@mui/material'
import Checkbox from 'components/forms/Checkbox'
import NumberField from 'components/forms/NumberField'
import { DialogActionsMUI, DialogContentMUI, DialogMUI, DialogTitleMUI } from 'components/styled/Dialog'
import Icon from 'components/icon/Icon'
import { hasValue } from 'utils/NumberUtil'
import { StyledFieldSet } from 'components/StyledElements'
import SimpleDatePicker from 'components/forms/SimpleDatePicker'
import Input from 'components/forms/Input'
import SimpleTextArea from 'components/forms/SimpleTextArea'
import DtoSTEPRejectPoint from '../../dto/DtoSTEPRejectPoint'
import useSandreList from 'utils/customHook/useSandreList'
import { SANDRE } from 'referencial/constants/ReferencialConstants'

const STEPRejectPointModal = ({
    idStation,
    rejectPoint: rejectPointProps,
    isOpen = false,
    setIsOpen = () => {},
    onSave = () => {},
}) => {
    const [rejectPoint, setRejectPoint] = useState(rejectPointProps)
    const onChangeElement = (values) => setRejectPoint(t => ({ ...t, ...values }))

    const projections = useSandreList(SANDRE.PROJECTION)
    const referencials = useSandreList(SANDRE.STEP_POINTS_REFERENTIELS)
    const recEnv = useSandreList(SANDRE.MILIEU_RECEPTEUR)

    return (
        <DialogMUI open={isOpen}>
            <DialogTitleMUI style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                {hasValue(rejectPoint.index) ? i18n.editRejectPoint : i18n.newRejectPoint}
                <Icon style={{ color: 'white' }} size='small' icon='close' onClick={() => setIsOpen(false)} />
            </DialogTitleMUI>
            <DialogContentMUI>
                <StyledFieldSet>
                    <Grid container item xs={12} justifyContent='space-between' alignItems='center'>
                        <Grid item xs={5.8}>
                            <Select
                                value={rejectPoint.idPoint}
                                label={i18n.referentielsPoint}
                                options={referencials}
                                displayWithCode
                                onChange={(v) => onChangeElement({ idPoint: v })}
                            />
                        </Grid>
                        <Grid item xs={5.8}>
                            <Input
                                value={rejectPoint.pointCode}
                                title={i18n.internalCodeSing}
                                onChange={v => onChangeElement({ pointCode: v })}
                            />
                        </Grid>
                    </Grid>
                    <Grid container item xs={12} justifyContent='space-between' alignItems='center'>
                        <Grid item xs={5.8}>
                            <Input
                                value={rejectPoint.envName}
                                title={i18n.name}
                                onChange={v => onChangeElement({ envName: v })}
                            />
                        </Grid>
                        <Grid item xs={5.8}>
                            <Select
                                value={rejectPoint.envType}
                                label={i18n.envType}
                                options={recEnv}
                                displayWithCode
                                onChange={(v) => onChangeElement({ envType: v })}
                            />
                        </Grid>
                    </Grid>
                    <Grid container item xs={12} justifyContent='space-between' alignItems='center'>
                        <Grid item xs={12}>
                            <SimpleTextArea
                                value={rejectPoint.comment}
                                title={i18n.comment}
                                onChange={v => onChangeElement({ comment: v })}
                            />
                        </Grid>
                    </Grid>
                    <Grid container item xs={12} justifyContent='space-between' alignItems='center' sx={{ paddingTop: '5px' }}>
                        <Grid item xs={5.8}>
                            <NumberField
                                value={rejectPoint.x}
                                title={i18n.x}
                                onChange={v => onChangeElement({ x: v })}
                                floatValue
                            />
                        </Grid>
                        <Grid item xs={5.8}>
                            <NumberField
                                value={rejectPoint.y}
                                title={i18n.y}
                                onChange={v => onChangeElement({ y: v })}
                                floatValue
                            />
                        </Grid>
                    </Grid>
                    <Grid container item xs={12} justifyContent='space-between' alignItems='center'>
                        <Grid item xs={5.8}>
                            <Select
                                value={rejectPoint.projection}
                                label={i18n.projection}
                                options={projections}
                                displayWithCode
                                onChange={(v) => onChangeElement({ projection: v })}
                            />
                        </Grid>
                    </Grid>
                    <Grid container item xs={12} justifyContent='space-between' alignItems='center'>
                        <Grid item xs={5.8}>
                            <Checkbox
                                label={i18n.rejectOver1500m}
                                checked={rejectPoint.rejectOver1500m}
                                onChange={v => onChangeElement({ rejectOver1500m: v })}
                            />
                        </Grid>
                        <Grid item xs={5.8}>
                            <Input
                                value={rejectPoint.sensitiveAreaName}
                                title={i18n.sensitiveAreaName}
                                onChange={v => onChangeElement({ sensitiveAreaName: v })}
                            />
                        </Grid>

                    </Grid>
                    <Grid container item xs={12} justifyContent='space-between' alignItems='center'>
                        <Grid item xs={5.8}>
                            <Checkbox
                                label={i18n.nitrogenSensitivity}
                                checked={rejectPoint.nitrogenSensitivity}
                                onChange={v => onChangeElement({ nitrogenSensitivity: v })}
                            />
                        </Grid>
                        <Grid item xs={5.8}>
                            <SimpleDatePicker
                                label={i18n.nitrogenSensitivityOrderDate}
                                value={rejectPoint.nitrogenSensitivityOrderDate}
                                onChange={v => onChangeElement({ nitrogenSensitivityOrderDate: v })}
                            />
                        </Grid>
                    </Grid>
                    <Grid container item xs={12} justifyContent='space-between' alignItems='center'>
                        <Grid item xs={5.8}>
                            <Checkbox
                                label={i18n.sensitivityPhosphorus}
                                checked={rejectPoint.sensitivityPhosphorus}
                                onChange={v => onChangeElement({ sensitivityPhosphorus: v })}
                            />
                        </Grid>
                        <Grid item xs={5.8}>
                            <SimpleDatePicker
                                label={i18n.sensitivityPhosphorusOrderDate}
                                value={rejectPoint.sensitivityPhosphorusOrderDate}
                                onChange={v => onChangeElement({ sensitivityPhosphorusOrderDate: v })}
                            />
                        </Grid>
                    </Grid>
                </StyledFieldSet>
            </DialogContentMUI>
            <DialogActionsMUI>
                <Grid container justifyContent='center'>
                    <Button
                        onClick={() => {
                            setIsOpen(false)
                            onSave(new DtoSTEPRejectPoint({ ...rejectPoint, idInstallation: idStation }))
                        }}
                        variant='contained'
                        color='primary'
                        className='center'
                    >
                        {i18n.register}
                    </Button>
                </Grid>
            </DialogActionsMUI>
        </DialogMUI>
    )
}

STEPRejectPointModal.propTypes = {
    idStation: PropTypes.number,
    rejectPoint: PropTypes.instanceOf(DtoSTEPRejectPoint),
    onSave: PropTypes.func,
    isOpen: PropTypes.bool,
    setIsOpen: PropTypes.func,
}

export default STEPRejectPointModal
