import i18n from 'simple-react-i18n'
import React from 'react'
import { hasValue } from '../../../../../utils/NumberUtil'
import { StyledFieldSet, StyledLegend, StyledSpan } from '../../../../../components/StyledElements'
import Row from '../../../../../components/react/Row'
import Input from '../../../../../components/forms/Input'
import StationImportCodeMessage from '../../../../../import/components/utils/StationImportCodeMessage'
import Select from '../../../../../components/forms/Select'
import { integrationModeHelpIcon } from '../../../../../import/utils/ImportUtils'

const measureOptions = () => [
    {
        value: '',
        label: i18n.noDataIntegration,
    },
    {
        value: 'DATA_ADD_NEW_DATA',
        label: i18n.lastDataIntegration,
    },
    {
        value: 'DATA_REPLACE_HISTO',
        label: i18n.fullDataIntegration,
    },
]

const handleChangeDataIntegration = (integration, jobDataTypes, setJobDataTypes) => {
    const dataTypes = jobDataTypes.filter(d => !d.includes('DATA'))
    if (integration) {
        dataTypes.push(integration)
    }
    setJobDataTypes(dataTypes)
}

const getDataTypes = (type, jobDataTypes) => {
    if (jobDataTypes && jobDataTypes.length) {
        return jobDataTypes.find(d => d.includes(type)) || ''
    }
    return ''
}


const AddQualitoStepOptionsNaiades = ({
    station,
    changeStation,
    filters,
    changeFilters,
    jobDataTypes,
    setJobDataTypes,
}) => {
    return (
        <StyledFieldSet>
            <StyledLegend>
                <StyledSpan>{i18n.options}</StyledSpan>
            </StyledLegend>
            <div>
                <Row className='padding-top-2 '>
                    <Input col={3} value={station.code} onChange={v => changeStation({ code: v })} title={i18n.code} noSpace/>
                    <Select col={ 5 } options={ measureOptions() } obligatory={true} label={ i18n.dataIntegration } value={ getDataTypes('DATA', jobDataTypes) } labelSpan={ integrationModeHelpIcon() }
                        onChange={ v => handleChangeDataIntegration(v, jobDataTypes, setJobDataTypes) }
                    />
                    <div className='col s4' />
                </Row>
                <Row className='padding-top-2  valign-wrapper'>
                    { hasValue(station.code) && <StationImportCodeMessage code={station.code} stationType='quality'/> }
                </Row>
            </div>
        </StyledFieldSet>
    )
}

AddQualitoStepOptionsNaiades.propTypes = {
}

export default AddQualitoStepOptionsNaiades