import React, { useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'

import Row from '../../../components/react/Row'
import InstVisitInfos from './validation/InstVisitInfos'
import InstVisitConclusion from './validation/InstVisitConclusion'
import InstVisitActions from './validation/InstVisitActions'
import InstVisitModifications from './validation/InstVisitModifications'
import Card from '../../../components/card/Card'
import { Step, StepLabel, Stepper } from '@mui/material'
import InstallationAction from '../../actions/InstallationAction'
import DtoVisit from './dto/DtoVisit'
import ReferencialAction from '../../../referencial/action/ReferencialAction'
import { getComponentWithId } from '../../../utils/StoreUtils'
import { STEP } from '../../../station/constants/CampaignConstants'
import { INSTALLATION_DESCRIPTION } from '../../constants/InstallationConstants'
import ManagementUnitAction from '../../../referencial/components/managementUnit/actions/ManagementUnitAction'
import { push } from '@lagunovsky/redux-react-router'
import { isEqual, minBy, flatten } from 'lodash'
import DtoVisitAction from './dto/DtoVisitAction'
import { getSandreLabel } from '../../../utils/StringUtil'
import { SANDRE } from '../../../referencial/constants/ReferencialConstants'
import moment from 'moment'
import { hasValue } from '../../../utils/NumberUtil'
import { exportFile } from '../../../utils/ExportDataUtil'
import { isInstallationType } from '../../../utils/CampaignUtil'
import { componentHasHabilitations } from '../../../utils/HabilitationUtil'
import { H_CAMPAIGN_INSTALLATION_VISIT } from '../../../account/constants/AccessRulesConstants'
import { setModal } from 'utils/FormUtils'
import InstVisitDanger from './validation/InstVisitDanger'
import InstVisitDiagnostic from './validation/InstVisitDiagnostic'
import ToastrAction from 'toastr/actions/ToastrAction'
import CmsAction from 'events/actions/CmsAction'
import { getExportAction } from 'utils/VisitUtils'
import { greyBlue } from 'utils/constants/ColorTheme'
import { useParams } from 'react-router-dom'
import useTitle from 'utils/customHook/useTitle'
import StationAction from 'station/actions/StationAction'

const InstallationVisitApp = () => {
    const dispatch = useDispatch()

    const [step, setStep] = useState(STEP.INFORMATIONS)
    const [stateVisit, setStateVisit] = useState(new DtoVisit({}))

    const {
        visit,
        campaign,
        sandreCodes,
        managementUnits,
        installation,
        installationsTypes,
        cmsSurveyId,
        cmsSurvey,
    } = useSelector(store => ({
        visit: store.InstallationReducer.visit,
        campaign: store.CampaignReducer.campaign,
        sandreCodes: store.ReferencialReducer.sandreCodes,
        managementUnits: store.ManagementUnitReducer.managementUnits,
        installation: store.InstallationReducer.installation,
        installationsTypes: store.InstallationReducer.installationsTypes,
        cmsSurveyId: store.EventsReducer.cmsSurveyId,
        cmsSurvey: store.EventsReducer.cmsSurvey,
    }), shallowEqual)

    const { step: urlStep, idInstallation, id } = useParams()

    useEffect(() => {
        dispatch(StationAction.fetchPictures(installation.code, 7))
        dispatch(StationAction.fetchFiles(installation.code, 7))
    }, [installation])

    useEffect(() => {
        if (!componentHasHabilitations(H_CAMPAIGN_INSTALLATION_VISIT)) { // A modifier quand react-router sera à jour
            dispatch(push('/unauthorized'))
            return
        }

        dispatch(InstallationAction.fetchInstallation(idInstallation))
        dispatch(InstallationAction.fetchVisit(idInstallation, id))
        setStep(parseInt(urlStep))

        if (!sandreCodes.length) {
            dispatch(ReferencialAction.fetchSandreCodes())
        }

        if (!managementUnits.length) {
            dispatch(ManagementUnitAction.fetchManagementUnits())
        }

        if (!installationsTypes.length) {
            dispatch(InstallationAction.fetchInstallationTypes())
        }

        $('.material-tooltip').remove()
    }, [])

    useTitle(() => [
        {
            title: i18n.installation,
            href: '/installation',
        },
        {
            title: i18n.campaigns,
            href: '/installation/campaign',
        },
        {
            title: campaign.name || id,
            href: `/installation/campaign/${id}/dashboard`,
        },
    ], [campaign, id])

    useEffect(() => {
        if (cmsSurveyId && installation.id) {
            dispatch(CmsAction.fetchCMSSurveyRestricted(cmsSurveyId, installation.id))
        }
    }, [cmsSurveyId, installation.id])

    useEffect(() => {
        if (stateVisit.visitDate) {
            dispatch(CmsAction.fetchCMSSurveyId(stateVisit.visitDate))
        }
    }, [stateVisit.visitDate])

    useEffect(() => {
        if (step === STEP.MODIFICATIONS) {
            dispatch(InstallationAction.fetchInstallation(idInstallation))
        }
    }, [step])

    useEffect(() => setStateVisit(visit), [visit])

    const onChangeVisit = value => setStateVisit({ ...stateVisit, ...value })

    const addNewChange = newObject => {
        const newChange = {
            ...newObject,
            previous: `${newObject.previousValue || ''}`,
            newValue: `${newObject.newValue || ''}`,
        }
        const notNew = stateVisit.link_modifications.some(({ field }) => field === newChange.field)
        if (notNew) {
            setStateVisit({
                ...stateVisit,
                link_modifications: stateVisit.link_modifications.map((link) => link.field === newChange.field ? { ...link, newValue: newChange.newValue } : link).filter(({ newValue = '', previousValue = '' }) => newValue !== previousValue),
            })
        } else {
            const newChangeWithIds = {
                ...newChange,
                idInstallation: visit.idInstallation,
                idCampaign: visit.idCampaign,
                idVisit: visit.idVisit,
            }
            setStateVisit({
                ...stateVisit,
                link_modifications: [
                    ...stateVisit.link_modifications,
                    newChangeWithIds,
                ],
            })
        }
    }

    const getTitle = () => {
        const code = installation.code ? `[${installation.code}]` : ''
        const name = installation.name ? `${installation.name}` : ''
        const type = (installationsTypes.find(i => i.id === installation.installationType) || {}).name
        const campaignType = campaign?.campaignType
            ? getSandreLabel(sandreCodes, SANDRE.CAMPAGNES_TYPE, campaign?.campaignType)
            : i18n.notDefined

        return (
            <span>
                <span className='clickable' onClick={() => dispatch(push(`/station/installation/${idInstallation}`))}>
                    { `${code} ${name} - ${type} ` }
                </span>
                <span className='padding-left-10'>
                    {`(${i18n.campaignType}: ${campaignType || i18n.notDefined})`}
                </span>
            </span>
        )
    }

    const getMin = (actions) => {
        const min = minBy(actions, 'idAction') || {}
        return min.idAction >= 0 ? -1 : (min.idAction || 0) - 1
    }

    const addNewAction = () => {
        onChangeVisit({ link_actions: [
            ...stateVisit.link_actions,
            new DtoVisitAction({
                idInstallation: stateVisit.idInstallation,
                idCampaign: stateVisit.idCampaign,
                idVisit: stateVisit.idVisit,
                idAction: getMin(stateVisit.link_actions),
            }),
        ] })
    }

    const getDiagnosticData = (paragraph, question) => {
        const answer = stateVisit.link_answers.find(la => la.idQuestion === question.idQuestion) || {}
        return {
            ...installation,
            paragraph: paragraph.description,
            question: question.description,
            answer: answer.answerMultiChoice,
            state: question.sandreField ? getSandreLabel(sandreCodes, question.sandreField, answer.answerLong) : '',
            isStateProblem: answer.answerBool,
            comment: answer.answerText,
        }
    }

    const getDiagnosticExportData = () => {
        const data = flatten(cmsSurvey.link_paragraphs.map(paragraph => {
            return paragraph.link_questions.map(question => {
                return getDiagnosticData(paragraph, question)
            })
        }))
        return [{ ...data[0], headers: ['code', 'name', 'paragraph', 'question', 'answer', 'state', 'isStateProblem', 'comment'] }, ...data.slice(1, data.length)]
    }

    const getDangerExportData = () => {
        const data = stateVisit.link_dangers.map(danger => {
            const paragraph = danger.idLinkedParagraph >= 0 ? cmsSurvey.link_paragraphs.find(la => la.idParagraph === danger.idLinkedParagraph) : {}
            const question = danger.idLinkedQuestion >= 0 && paragraph.link_questions ? paragraph.link_questions.find(lq => lq.idQuestion === danger.idLinkedQuestion) : {}
            const answer = stateVisit.link_answers.find(la => la.idQuestion === danger.idLinkedQuestion) || {}
            return {
                ...installation,
                ...danger,
                paragraph: paragraph.description,
                question: question.description,
                answer: answer.answerMultiChoice,
                state: question.sandreField ? getSandreLabel(sandreCodes, question.sandreField, answer.answerLong) : '',
                isStateProblem: answer.answerBool,
                comment: answer.answerText,
                dangerousSituation: getSandreLabel(sandreCodes, SANDRE.INSTALLATIONS_DANGERS, danger.dangerousSituation),
                dangerComment: danger.comment,
            }
        })

        return [{ ...data[0], headers: ['code', 'name', 'paragraph', 'question', 'answer', 'state', 'isStateProblem', 'comment', 'dangerousSituation', 'dangerComment'] }, ...data.slice(1, data.length)]
    }

    const afterSaveCb = () => {
        dispatch(InstallationAction.fetchVisit(idInstallation, id))
    }

    const launchSave = (finalizVisit = false) => {
        (() => {
            if (!hasValue(stateVisit.statusVisit) && stateVisit.visitDate) {
                dispatch(InstallationAction.addInstallationEvent(
                    id,
                    {
                        code: installation.id,
                        eventDate: moment().valueOf(),
                        eventType: '',
                        comment: i18n.newVisit,
                        campaignCode: parseInt(id),
                    }))
                return dispatch(InstallationAction.updateVisit(stateVisit.idVisit, { ...stateVisit, statusVisit: 0 }))
            } else if (finalizVisit) {
                return dispatch(InstallationAction.updateVisit(stateVisit.idVisit, { ...stateVisit, statusVisit: 2 }))
            } else if (step === STEP.MODIFICATIONS) {
                const installationComponent = getComponentWithId(INSTALLATION_DESCRIPTION.find(description => description.code === installation.installationType)?.description)
                const descriptionComponent = hasValue(installation.installationType) && installationComponent ? installationComponent : getComponentWithId('#InstallationDescriptionPanel')
                return descriptionComponent.onSave(stateVisit)
            }
            return dispatch(InstallationAction.updateVisit(stateVisit.idVisit, stateVisit))
        })().then(afterSaveCb)
    }

    const getStepDisplay = () => {
        const params = {
            readMode: false,
            visit: stateVisit,
            onChange: onChangeVisit,
            addNewChange,
            launchSave,
            cmsSurveyId,
        }

        const equipmentType = isInstallationType(installation.installationType)

        return (
            <>
                <div style={{ position: 'sticky', top: '63px', zIndex: '1' }}>
                    <Card
                        title={getTitle()}
                        headerStyle={{ backgroundColor: greyBlue }}
                        actions={[
                            step === STEP.ACTIONS && {
                                iconName: 'note_add',
                                tooltip: i18n.addNewAction,
                                onClick: addNewAction,
                            },
                            step === STEP.ACTIONS && {
                                iconName: 'file_download',
                                tooltip: i18n.export,
                                onClick: () => exportFile({
                                    data: [
                                        {
                                            ...getExportAction(sandreCodes, equipmentType, visit.link_actions[0]),
                                            headers: ['risk', 'localisation', 'equipmentType', 'responsible', 'category', 'deadline', 'interventionType', 'comment', 'correctionDate', 'commentCorrection'],
                                        },
                                        ...visit.link_actions.slice(1).map(d => getExportAction(sandreCodes, equipmentType, d)),
                                    ],
                                    titleFile: `${i18n.actions}_${installation.name || installation.code}`,
                                }),
                            },
                            step === STEP.DIAGNOSTIC && {
                                iconName: 'file_download',
                                tooltip: i18n.export,
                                onClick: () => exportFile({
                                    data: getDiagnosticExportData(),
                                    titleFile: `${i18n.diagnostic}_${installation.name || installation.code}`,
                                }),
                            },
                            step === STEP.DANGERS && {
                                iconName: 'file_download',
                                tooltip: i18n.export,
                                onClick: () => exportFile({
                                    data: getDangerExportData(),
                                    titleFile: `${i18n.dangers}_${installation.name || installation.code}`,
                                }),
                            },
                        ]}
                    />
                </div>
                <Card>
                    <Row className='padding-top-1 padding-bottom-1'>
                        { step === STEP.INFORMATIONS && <InstVisitInfos { ...params }/>}
                        { step === STEP.DIAGNOSTIC && <InstVisitDiagnostic { ...params }/>}
                        { step === STEP.DANGERS && <InstVisitDanger { ...params }/>}
                        { step === STEP.MODIFICATIONS && <InstVisitModifications { ...params }/>}
                        { step === STEP.ACTIONS && <InstVisitActions { ...params }/>}
                        { step === STEP.CONCLUSION && <InstVisitConclusion { ...params }/>}
                    </Row>
                </Card>
            </>
        )
    }

    const onNextStep = () => {
        if (visit.visitDate) {
            launchSave(false)

            if (step === STEP.INFORMATIONS && !hasValue(stateVisit.idSurvey)) {
                setStep(STEP.MODIFICATIONS)
            } else {
                setStep(step + 1)
            }
        } else {
            dispatch(ToastrAction.warning(i18n.saveBefore))
        }
    }

    const onPrevStep = () => {
        if (visit.visitDate) {
            launchSave(false)

            if (step === STEP.MODIFICATIONS && !hasValue(stateVisit.idSurvey)) {
                setStep(STEP.MODIFICATIONS)
            } else {
                setStep(step - 1)
            }
        } else {
            dispatch(ToastrAction.warning(i18n.saveBefore))
        }
    }


    const getButtons = () => {
        return (
            <div style={{ position: 'fixed', width: '100%', bottom: '0', zIndex: '150', background: '#e7eaef', padding: '5px' }}>
                <div className='col s1 offset-s2 center'>
                    <button className={ `waves-effect waves-teal btn-flat secondary-button ${step === STEP.INFORMATIONS ? 'disabled' : ''}` } onClick={ onPrevStep }>{ i18n.previous }</button>
                </div>
                <div className='col s1 offset-s2 center'>
                    <button className='waves-effect waves-teal btn-flat primary-button' onClick={() => launchSave(false)}>{i18n.save}</button>
                </div>
                <div className='col s1 offset-s2 center'>
                    <button className={`waves-effect waves-teal btn-flat primary-button ${step === STEP.CONCLUSION ? 'disabled' : ''}`} onClick={ onNextStep }>{ i18n.next }</button>
                </div>
            </div>
        )
    }

    const onStepClick = (newStep) => {
        const { link_modifications: stateModifs, idSurvey: stateIdSurvey } = stateVisit
        const { link_modifications, idSurvey: propsidSurvey } = visit
        if (!visit.visitDate) {
            dispatch(ToastrAction.warning(i18n.saveBefore))
        } else if (step === STEP.MODIFICATIONS
                && newStep !== STEP.MODIFICATIONS
                && !isEqual(stateModifs, link_modifications)) {
            setModal({
                title: i18n.quit,
                content: (
                    <div>
                        <Row>
                            <h5>{ i18n.sureQuitStepModifications }</h5>
                        </Row>
                        <Row>
                            { i18n.chooseQuitStepModifications }
                        </Row>
                        <Row>
                            <button
                                className='waves-effect waves-teal btn right modal-close'
                                onClick={() => {
                                    setStep(newStep)
                                    setStateVisit(visit)
                                }}
                            >
                                {i18n.quit}
                            </button>
                            <button className='waves-effect waves-teal btn right modal-close red'>{ i18n.cancel }</button>
                        </Row>
                    </div>
                ),
            })
        } else if (step === STEP.INFORMATIONS && hasValue(stateIdSurvey) && !hasValue(propsidSurvey)) {
            dispatch(ToastrAction.warning(i18n.saveBeforeSurvey))
        } else {
            setStep(newStep)
        }
    }

    const getActiveStep = () => {
        if (step === STEP.INFORMATIONS) {
            return 0
        }
        return hasValue(stateVisit.idSurvey) ? step - 1 : step - 3
    }

    return (
        <div className='padding-top-1 margin-left-2 margin-right-1'>
            <Card className='padding-bottom-1'>
                <Row>
                    <Row className='padding-right-1 padding-left-1'>
                        <Stepper
                            activeStep={getActiveStep()}
                            style={{ zoom: '1.5', height: '30px' }}
                        >
                            <Step
                                className='clickable'
                                onClick={() => onStepClick(STEP.INFORMATIONS)}
                            >
                                <StepLabel>{ i18n.visit }</StepLabel>
                            </Step>
                            {hasValue(stateVisit.idSurvey) && (
                                <Step
                                    className='clickable'
                                    onClick={() => onStepClick(STEP.DIAGNOSTIC)}
                                >
                                    <StepLabel>{ i18n.diagnostic }</StepLabel>
                                </Step>
                            )}
                            {hasValue(stateVisit.idSurvey) && (
                                <Step
                                    className='clickable'
                                    onClick={() => onStepClick(STEP.DANGERS)}
                                >
                                    <StepLabel><div style={{ whiteSpace: 'pre-line' }}>{i18n.dangerousSituations}</div></StepLabel>
                                </Step>
                            )}
                            <Step
                                className='clickable'
                                onClick={() => onStepClick(STEP.MODIFICATIONS)}
                            >
                                <StepLabel>{ i18n.modifications }</StepLabel>
                            </Step>
                            <Step
                                className='clickable'
                                onClick={() => onStepClick(STEP.ACTIONS)}
                            >
                                <StepLabel>{ i18n.actions }</StepLabel>
                            </Step>
                            <Step
                                className='clickable'
                                onClick={() => onStepClick(STEP.CONCLUSION)}
                            >
                                <StepLabel>{ i18n.conclusion }</StepLabel>
                            </Step>
                        </Stepper>
                    </Row>
                    <Row noMargin={false} className='margin-left-1 margin-right-1'>
                        {getStepDisplay()}
                    </Row>
                    <Row>
                        {getButtons()}
                    </Row>
                </Row>
            </Card>
        </div>
    )
}

InstallationVisitApp.propTypes = {
}

export default InstallationVisitApp