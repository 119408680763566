import React from 'react'
import { push } from '@lagunovsky/redux-react-router'
import { sortBy } from 'lodash'
import { useEffect, useMemo, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import useActions from 'utils/customHook/useActions'
import useTitle from 'utils/customHook/useTitle'
import { getDataWithHeaders } from 'utils/ExportDataUtil'
import HomeAction from '../../../../home/actions/HomeAction'
import {
    PATH_REFERENCIAL,
    PATH_REFERENCIAL_CULTURE,
    PATH_REFERENCIAL_CULTURES_FAMILY_NEW,
} from '../../../../home/constants/RouteConstants'
import CulturesFamilyAction from '../actions/CulturesFamilyAction'
import SearchTable from 'referencial/components/SearchTable'
import { getExportReferencialData } from 'referencial/util/ReferencialUtils'
import { REFERENCIAL_TYPE_NAME } from 'referencial/constants/ReferencialConstants'

const headers = ['id', 'name']

const CulturesFamiliesApp = () => {
    const {
        culturesFamilies,
    } = useSelector(store => ({
        culturesFamilies: store.CulturesFamilyReducer.culturesFamilies,
    }), shallowEqual)

    const [exportData, setExportData] = useState([])
    const [culturesFamiliesFormatted, setCulturesFamiliesFormatted] = useState([])

    const dispatch = useDispatch()

    useEffect(() => setCulturesFamiliesFormatted(sortBy(culturesFamilies, 'id').map((c) => ({ ...c, headers }))), [culturesFamilies])

    useEffect(() => {
        dispatch(CulturesFamilyAction.fetchCulturesFamilies())
        dispatch(HomeAction.setHelpLink('', ''))
    }, [])

    const data = useMemo(() => getExportReferencialData(culturesFamiliesFormatted), [culturesFamiliesFormatted])

    useActions(() => {
        return {
            new: () => dispatch(push(PATH_REFERENCIAL_CULTURES_FAMILY_NEW)),
            referencialActions: {
                actions: {
                    export: {
                        data: getDataWithHeaders(exportData, headers),
                        titleFile: i18n.culturesFamilies,
                    },
                },
            },
        }
    }, [exportData])

    useTitle(() => [{
        title: i18n.referencials,
        href: PATH_REFERENCIAL,
    }, {
        title: i18n.culturesFamilies,
        href: PATH_REFERENCIAL_CULTURE,
    }], [])

    return (
        <SearchTable
            title={i18n.culturesFamilies}
            sessionKey={REFERENCIAL_TYPE_NAME.culturesFamily}
            data={data}
            setExportData={setExportData}
            headers={headers}
            onClick={culturesFamily => dispatch(push(`/referencial/culturesFamily/${culturesFamily.id}/dashboard`))}
        />
    )
}

export default CulturesFamiliesApp
