import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { filterStationCoordinates, getDefaultCoordinates } from 'utils/mapUtils/CoordinateUtils'
import { transform } from 'ol/proj'
import { PropTypes } from 'prop-types'
import CityDto from 'referencial/components/city/dto/CityDto'
import { objectOf } from 'utils/StoreUtils'
import OlMap from 'utils/mapUtils/Map'
import OSMSatellite from 'utils/mapUtils/layers/OSMSatellite'
import SingleStationPoint from 'utils/mapUtils/layers/SingleStationPoint'
import { execByType } from 'utils/StationUtils'
import { getSiteType } from 'utils/mapUtils/SiteTypes'
import DtoStation from 'station/dto/DtoStation'


const MoveMapPanel = ({
    station,
    handleChangeValue = () => {},
    className,
    citiesIndex,
    type,
    editMap,
    code,
}) => {
    const stationCoordinates = filterStationCoordinates(station, citiesIndex)
    const [x, setX] = useState()
    const [y, setY] = useState()

    useEffect(() => {
        if (!editMap) {
            setX()
            setY()
        }
    }, [editMap])

    const getLocation = () => {
        const stationWithCoordinates = (station.x && station.y && station.projection) ? station : stationCoordinates
        if (stationWithCoordinates) {
            return [stationWithCoordinates.x, stationWithCoordinates.y]
        }
        if (editMap) {
            return getDefaultCoordinates()
        }
        return null
    }

    const coordinates = getLocation()
    const osmSatellite = OSMSatellite()

    const getSiteCoordinates = () => {
        const location = { ...station, x: coordinates[0], y: coordinates[1], projection: 16 }
        if (editMap && code !== 'new') {
            if (x && y) {
                return { ...station, x, y, projection: 16 }
            }
            return location
        }
        return location
    }

    const getLayersGroup = () => {
        return [osmSatellite,
            SingleStationPoint({
                site: getSiteCoordinates(),
                type: execByType(type, {
                    contact: () => getSiteType('CONTACT', 1),
                    quality: () => getSiteType('QUALITOMETER', 1),
                    piezometry: () => getSiteType('PIEZOMETER', 1),
                    pluviometry: () => getSiteType('PLUVIOMETER', 1),
                    hydrometry: () => getSiteType('HYDROMETRIC_STATION', 1),
                    productionUnit: () => getSiteType('UNIT', 1),
                    distributionUnit: () => getSiteType('UNIT', 1),
                    installation: () => {
                        return (() => {
                            switch (station.installationType) {
                                case 0:
                                    return getSiteType('INSTALLATION_BOREHOLE', 1)
                                case 1:
                                    return getSiteType('INSTALLATION_INDUSTRIAL_SITE', 1)
                                case 2:
                                    return getSiteType('INSTALLATION_STEP', 1)
                                case 3:
                                    return getSiteType('INSTALLATION_FLOW_OBSTRUCTION', 1)
                                case 4:
                                    return getSiteType('INSTALLATION_POLLUTED_SOIL', 1)
                                case 5:
                                    return getSiteType('INSTALLATION_FISHING_SPOT', 1)
                                case 6:
                                    return getSiteType('INSTALLATION_CAVITY', 1)
                                case 7:
                                    return getSiteType('INSTALLATION_SAMPLE_WORK', 1)
                                case 8:
                                    return getSiteType('INSTALLATION_CAPTURE', 1)
                                case 9:
                                    return getSiteType('INSTALLATION_LIFTING_STATION', 1)
                                case 11:
                                    return getSiteType('INSTALLATION_TREATMENT', 1)
                                case 12:
                                    return getSiteType('INSTALLATION_TANK', 1)
                                default:
                                    return getSiteType('INSTALLATION', 1)
                            }
                        })()
                    },
                }),
            })]
    }

    const changeMap = (value) => {
        const point = transform(value.getView().getCenter(), 'EPSG:3857', 'EPSG:4326')
        const [olX, olY] = point
        setX(olX)
        setY(olY)
        handleChangeValue({ ...station, x: olX, y: olY, projection: 16 })
    }

    if (coordinates) {
        return (
            <div className={`row card no-padding ${className}`}>
                <div className='col l12 s12 m12 no-padding card-content'>
                    <OlMap
                        mapConf={{
                            center: coordinates,
                            zoom: 14,
                            size: 300,
                        }}
                        layers={ getLayersGroup() }
                        onMoveEnd={ editMap && code !== 'new' ? changeMap : () => {} }
                        onClickMap={() => {}}
                    />
                </div>
            </div>
        )
    }
    return null
}

MoveMapPanel.propTypes = {
    station: PropTypes.instanceOf(DtoStation),
    handleChangeValue: PropTypes.func,
    className: PropTypes.string,
    citiesIndex: objectOf(CityDto),
    type: PropTypes.string,
    editMap: PropTypes.bool,
    code: PropTypes.string,
}

const mapStateToProps = store => {
    return {
        citiesIndex: store.CityReducer.citiesIndex,
    }
}

export default connect(mapStateToProps)(MoveMapPanel)