export default class DtoInstallationType {
    constructor(obj) {
        this.id = obj.id
        this.code = obj.id
        this.name = obj.name
        this.sandreCode = obj.sandreCode
        this.display = obj.display || false

        this.isChanged = obj.isChanged
    }
}