import React, { useEffect, useMemo } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import PropTypes from 'prop-types'
import {
    H_STATION_RESOURCE_SUIVIPC,
    H_STATION_RESOURCE_DASHBOARD,
    H_STATION_RESOURCE_DESCRIPTION,
    H_STATION_RESOURCE_DOCUMENTS,
} from 'account/constants/AccessRulesConstants'
import { componentHasHabilitations } from 'utils/HabilitationUtil'
import FixedSlideNav from 'components/navbar/FixedSlideNav'
import BoundaryError from 'log/components/BoundaryError'
import { Outlet, useParams } from 'react-router-dom'
import ResourceAction from 'resource/actions/ResourceAction'
import StationAction from 'station/actions/StationAction'
import QualityAction from 'quality/actions/QualityAction'
import PiezometryAction from 'piezometry/actions/PiezometryAction'
import ParameterAction from 'referencial/components/parameter/actions/ParameterAction'
import AdministrationAction from 'administration/actions/AdministrationAction'
import PluviometryAction from 'pluviometry/actions/PluviometryAction'

const ResourceApp = () => {
    const dispatch = useDispatch()
    const { id } = useParams()

    const {
        associatedSites,
        resource,
    } = useSelector(store => ({
        associatedSites: store.StationReducer.associatedSites,
        resource: store.ResourceReducer.resource,
    }), shallowEqual)

    const navLinks = useMemo(() => {
        const hasQualito = associatedSites.some(site => site.stationLinkedType === 3)
        const qualityFeatures = hasQualito ? [{
            icons: 'assignment_turned_in',
            name: i18n.followUpPC,
            href: `/station/resource/${id}/pcMonitoring`,
            habilitation: H_STATION_RESOURCE_SUIVIPC,
        }] : []
        return [{
            icons: 'dashboard',
            name: i18n.dashboard,
            href: `/station/resource/${id}/dashboard`,
            habilitation: H_STATION_RESOURCE_DASHBOARD,
        }, {
            icons: 'compare_arrows',
            name: i18n.descriptive,
            href: `/station/resource/${id}/description`,
            habilitation: H_STATION_RESOURCE_DESCRIPTION,
        }, {
            icons: 'perm_media',
            name: i18n.picturesAndDocuments,
            href: `/station/resource/${id}/picture`,
            habilitation: H_STATION_RESOURCE_DOCUMENTS,
        },
        ...qualityFeatures].filter(l => componentHasHabilitations(l.habilitation))
    }, [associatedSites, id])

    useEffect(() => {
        dispatch(ResourceAction.fetchResource(id))
    }, [id])

    useEffect(() => {
        dispatch(QualityAction.fetchQualitometersLight())
        dispatch(PiezometryAction.fetchPiezometersLight())
        dispatch(PluviometryAction.fetchPluviometers())
        dispatch(ParameterAction.fetchParameters())
        dispatch(AdministrationAction.fetchSettings())
        return dispatch(StationAction.resetAssociatedSites())
    }, [])

    useEffect(() => {
        if (resource?.code && resource?.code?.replace(' ', '') !== '') {
            dispatch(StationAction.fetchAssociatedSites(resource.code, 9))
            dispatch(StationAction.fetchPictures(resource.code, 9))
            dispatch(StationAction.fetchFiles(resource.code, 9))
        }
    }, [resource])

    useEffect(() => {
        dispatch(ResourceAction.fetchResources())
        return () => {
            dispatch(ResourceAction.reset())
        }
    }, [])

    return (
        <div className='row no-margin'>
            <FixedSlideNav links={navLinks} />
            <main>
                <div className='row no-margin padding-left-1'>
                    <div className='content-page no-padding fullscreen'>
                        <BoundaryError>
                            <Outlet />
                        </BoundaryError>
                    </div>
                </div>
            </main>
        </div>
    )
}

ResourceApp.propTypes = {
    params: PropTypes.shape({
        id: PropTypes.string,
    }),
}

export default ResourceApp