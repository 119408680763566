// Choisir une des deux usedUrl ci dessous

// A utiliser pour le serveur
const myUrl = (window.location.href || document.URL).split('#')[0]
const urlNice = 'https://dev.sieau.fr/'


const usedUrl = myUrl.includes('localhost') ? urlNice : myUrl

// ////////////////////////////////////////////////////////////
const path = `${usedUrl}${usedUrl.endsWith('/') ? '' : '/'}api/`
const contentsPath = `${usedUrl}${usedUrl.endsWith('/') ? '' : '/'}contents/`

export { path, contentsPath, myUrl, usedUrl }
