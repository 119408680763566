import { asString } from 'ol/color'
import Fill from 'ol/style/Fill'
import Stroke from 'ol/style/Stroke'
import Style from 'ol/style/Style'

const fillBlack = (() => {
    const fill = new Fill({
        color: asString('rgba(255,255,255,0.5)'),
    })
    const stroke = new Stroke({
        color: asString('#000000'),
        width: 2,
    })
    return [
        new Style({
            fill,
            stroke,
        }),
    ]
})

const fillYellow = (() => {
    const fill = new Fill({
        color: asString('rgba(255,255,255,0.5)'),
    })
    const stroke = new Stroke({
        color: asString('#FFFF00'),
        width: 2,
    })
    return [
        new Style({
            fill,
            stroke,
        }),
    ]
})

const fillOrange = (() => {
    const fill = new Fill({
        color: asString('rgba(255,255,255,0.5)'),
    })
    const stroke = new Stroke({
        color: asString('#FF5500'),
        width: 2,
    })
    return [
        new Style({
            fill,
            stroke,
        }),
    ]
})

const fillRed = (() => {
    const fill = new Fill({
        color: asString('rgba(255,255,255,0.5)'),
    })
    const stroke = new Stroke({
        color: 'rgba(255, 0, 0, 1.0)',
        width: 2,
    })
    return [
        new Style({
            fill,
            stroke,
        }),
    ]
})

const fillBlue = (() => {
    const fill = new Fill({
        color: asString('rgba(255,255,255,0.3)'),
    })
    const stroke = new Stroke({
        color: asString('#0000FF'),
        width: 2,
    })
    return [
        new Style({
            fill,
            stroke,
        }),
    ]
})


export default {
    FILL_RED: fillRed(),
    FILL_BLACK: fillBlack(),
    FILL_BLUE: fillBlue(),
    FILL_YELLOW: fillYellow(),
    FILL_ORANGE: fillOrange(),
}
