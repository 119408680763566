import { isEmpty, isEqual, range, xorWith } from 'lodash'

const numberList = max => range(max).map((_, i) => ({ value: `${i}`, label: i > 9 ? i : `0${i}` }))

const repeatListRec = (listBase, curr, nb) => nb <= 1 ? curr : repeatListRec(listBase, [...curr, ...listBase], nb-1)

const repeatList = (list, nb) => repeatListRec(list, list, nb)

const isArrayEqual = (array, arrayToCompare) => isEmpty(xorWith(array, arrayToCompare, isEqual))

export { numberList, repeatList, isArrayEqual }
