export default class DtoPAR {
    constructor(obj) {
        this.id = obj.id // Long,
        this.name = obj.name // Option[String],
        this.year = obj.year // Int,
        this.statusCode = obj.statusCode // Option[Int],
        this.description = obj.description // Option[String],
        this.tanks = obj.tanks // Boolean,
        this.periode = obj.periode // Int,
        this.updateDate = obj.updateDate // Option[DateTime],
        this.updateLogin = obj.updateLogin // Option[String]
        this.organism = obj.organism // Option[Long]
        this.onlyDeclarationsCompleted = obj.onlyDeclarationsCompleted // Option[Boolean]

        this.linkSurveys = obj.linkSurveys || [] // Option[Seq[LinkPARSurvey]]
        this.linkScenarios = obj.linkScenarios || [] // Option[Seq[PARScenario]]
        this.linkVolumesUGE = obj.linkVolumesUGE || [] // Option[Seq[PARVolumeManagementUnit]]
        this.linkScenariosVolumesAllocated = obj.linkScenariosVolumesAllocated || [] // Option[Seq[PARScenarioVolumes]]

        this.volume = obj.volume // Option[Double],
        this.volumeLowWater = obj.volumeLowWater // Option[Double],
        this.volumeNotLowWater = obj.volumeNotLowWater // Option[Double],
        this.nbUG = obj.nbUG // Option[Long],
        this.nbUGValidated = obj.nbUGValidated // Option[Long]
        this.volumeTotalUG = obj.volumeTotalUG // Option[Double],
        this.volumeReserved = obj.volumeReserved // Option[Double]
    }
}