export default class DtoBoreholeCasing {
    constructor(obj) {
        this.idStation = obj.idStation
        this.order = obj.order
        this.startDepth = obj.startDepth
        this.endDepth = obj.endDepth
        this.aquiferCode = obj.aquiferCode
        this.aquiferRoof = obj.aquiferRoof
        this.aquiferFloor = obj.aquiferFloor
        this.internalCasingDiameter = obj.internalCasingDiameter
        this.externalCasingDiameter = obj.externalCasingDiameter
        this.strainers = obj.strainers
        this.materialCode = obj.materialCode
        this.cementing = obj.cementing
        this.column = obj.column
        this.casingMaterial = obj.casingMaterial
        this.headers = ['order', 'startDepth', 'endDepth', 'aquifer', 'aquiferRoof', 'aquiferFloor', 'internalCasingDiameter', 'externalCasingDiameter', 'strainers', 'casingMaterial', 'cementing', 'column']
    }
}