import { Button, CircularProgress } from '@mui/material'
import React from 'react'
import PropTypes from 'prop-types'

// dans l'attente que celui de mui ne soit plus en dévelopement
const LoadingButton = ({
    loading = false,
    disabled = false,
    ...props
}) => {
    return (
        <Button
            {...props}
            disabled={disabled || loading}
            startIcon={loading ? <CircularProgress thickness={5} size={18} sx={{ '& .MuiCircularProgress-svg': { backgroundColor: 'inherit' }, color: 'inherit' }} /> : undefined}
        />
    )
}

LoadingButton.propTypes = {
    loading: PropTypes.bool,
    disabled: PropTypes.bool,
    // props from Mui Button
}

export default LoadingButton