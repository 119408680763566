/* eslint-disable camelcase */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import DtoSandreCode from '../../../referencial/dto/DtoSandreCode'
import Table from '../../../components/datatable/Table'
import SieauAction from '../../../components/sieau/SieauAction'
import DtoProductionUnit from '../../../productionUnit/dto/DtoProductionUnit'
import DtoDistributionUnit from '../../../distributionUnit/dto/DtoDistributionUnit'
import DtoQualitometer from '../../../quality/dto/DtoQualitometer'
import DtoPiezometer from '../../../piezometry/dto/DtoPiezometer'
import DtoHydrometricStation from '../../../hydrometry/dto/DtoHydrometricStation'
import DtoInstallation from '../../../installation/dto/installation/DtoInstallation'
import { getExport } from '../../../utils/linkUtils'
import DtoInstallationTank from '../../../installation/dto/installation/DtoInstallationTank'
import TankModal from './modals/TankModal'
import SelectTanksModal from './modals/SelectTanksModal'
import InstallationAction from '../../../installation/actions/InstallationAction'
import { sortBy } from 'lodash'
import DtoAgriTanksType from 'exploitations/dto/DtoAgriTanksType'
import { hasValue } from 'utils/NumberUtil'

const headers = ['id', 'type', 'capacity', 'recoveryFlow', 'fillingModeLabel', 'fillingTypeLabel', 'fillingPeriodLabel', 'sharedRetention', 'usedPercentage']

class StationTanksPanel extends Component {
    constructor(props) {
        super(props)
        this.state = {
            tank: null,
            openModalTank: false,
            openModalSelectTanks: false,
        }
    }

    getModalTank = () => {
        const { tank, openModalTank } = this.state
        return (
            <TankModal
                title={!tank.id && i18n.createNewTank}
                tank={tank}
                open={openModalTank}
                onCancel={() => this.setState({ openModalTank: false, tank: null }) }
                onValidate={this.onValidateTank}
            />
        )
    }

    onValidateTank = (tank) => {
        const { station } = this.props
        const newTank = {
            ...tank,
            idInstallation: station.id,
        }
        if (tank.idTank) {
            const link_sampleTanks = [
                ...station.link_sampleTanks.filter((t) => t.idTank !== tank.idTank).map((t) => new DtoInstallationTank(t)),
                new DtoInstallationTank(newTank),
            ]
            this.setState({ openModalTank: false, tank: null })
            this.props.onChange({ link_sampleTanks })
        } else {
            this.props.createAgriTank(newTank, (id) => {
                this.props.fetchInstallationAgriTanks().then(() => {
                    const link_sampleTanks = [
                        ...station.link_sampleTanks.filter((t) => t.idTank !== tank.idTank).map((t) => new DtoInstallationTank(t)),
                        new DtoInstallationTank({
                            ...newTank,
                            idTank: id,
                        }),
                    ]
                    this.setState({ openModalTank: false, tank: null })
                    this.props.onChange({ link_sampleTanks })
                })
            })
        }
    }

    getSelectTanksModal = () => {
        const { openModalSelectTanks } = this.state
        const { station } = this.props
        return openModalSelectTanks && (
            <SelectTanksModal
                installation={station}
                open={ openModalSelectTanks }
                onCancel={() => this.setState({ openModalSelectTanks: false }) }
                onValidate={this.onValidateSelectTanks}
            />
        )
    }

    onValidateSelectTanks = (link_sampleTanks) => {
        this.setState({ openModalSelectTanks: false })
        this.props.onChange({ link_sampleTanks: link_sampleTanks.map((t) => new DtoInstallationTank(t)) })
    }

    changeState = (obj) => {
        this.setState({ ...obj })
    }

    getSelectedPeriod = (idPeriode, tankPeriodes) => {
        return !!tankPeriodes.find((p) => p.idNbPeriode === idPeriode)
    }

    formatTankData = (tank) => {
        const { sandreCodes, agriTanksTypes } = this.props

        const tankType = agriTanksTypes.find((t) => t.id === tank.tankType) || {}
        const fillingMode = sandreCodes.find((c) => c.field === 'PREL_AGRI.MODE_REMPLISSAGE' && c.code === tank.fillingMode) || {}
        const fillingType = sandreCodes.find((c) => c.field === 'PREL_AGRI.TYPE_REMPLISSAGE' && c.code === tank.fillingType) || {}
        const fillingPeriodes = sandreCodes.filter((c) => c.field === 'PREL_AGRI.PERIODE_REMPLISSAGE')
        const tanksPeriodesDetails = sandreCodes.filter((code) => code.field === 'USAGES.PERIODES_DETAILS')
        const tankSaisonnal = (tank.link_periodes || []).find((p) => p.idPeriode === 2)
        const tankAnnuel = (tank.link_periodes || []).find((p) => p.idPeriode === 1)
        const tankUnknown = (tank.link_periodes || []).find((p) => p.idPeriode === 0)
        const fillingPeriod = tankSaisonnal ? 2 : (tankAnnuel ? 1 : (hasValue(tankUnknown) ? 0 : null))
        const fillingPeriode = fillingPeriodes.find((c) => c.code === fillingPeriod) || {}

        let saisons = ''
        if (tankSaisonnal) {
            tanksPeriodesDetails.map((p) => {
                if (this.getSelectedPeriod(p.code, tank.link_periodes)) {
                    saisons = `${saisons}${p.name}, `
                }
            })
        }

        return {
            ...tank,
            type: tankType.name,
            fillingModeLabel: fillingMode.name,
            fillingTypeLabel: fillingType.name,
            fillingPeriodLabel: `${fillingPeriode ? fillingPeriode.name : ''}${saisons.length ? ` (${saisons.slice(0, -2)})` : ''}`,
            headers,
        }
    }

    render() {
        const { station, readMode } = this.props
        const { openModalTank } = this.state
        const stationTanks = sortBy(station.link_sampleTanks.map((t) => this.formatTankData(t)), 'idTank')
        const exportAction = getExport(stationTanks, i18n.detentions, headers)

        const actions =
            !readMode ? [{
                tooltip: i18n.add,
                iconName: 'note_add',
                onClick: () => this.changeState({ openModalTank: true, tank: {} }),
            }, {
                tooltip: i18n.change,
                iconName: 'edit',
                onClick: () => this.changeState({ openModalSelectTanks: true }),
            }] : [exportAction]
        return (
            <div>
                <Table
                    title={ stationTanks.length > 1 ? i18n.detentions : i18n.detention }
                    condensed
                    data={ stationTanks }
                    type={{ headers }}
                    customHeaders={{
                        id: i18n.login,
                        capacity: i18n.capability,
                        fillingTypeLabel: i18n.fillingType,
                        fillingModeLabel: i18n.fillingMode,
                        fillingPeriodLabel: i18n.fillingPeriod,
                    }}
                    sortable={ !!stationTanks.length }
                    actions={ actions }
                    alterable={!readMode}
                    onAlter={(e) => this.changeState({ tank: e, openModalTank: true })}
                />
                {openModalTank && this.getModalTank()}
                {this.getSelectTanksModal()}
            </div>
        )
    }
}

StationTanksPanel.propTypes = {
    station: PropTypes.oneOfType([
        PropTypes.instanceOf(DtoProductionUnit),
        PropTypes.instanceOf(DtoDistributionUnit),
        PropTypes.instanceOf(DtoQualitometer),
        PropTypes.instanceOf(DtoPiezometer),
        PropTypes.instanceOf(DtoHydrometricStation),
        PropTypes.instanceOf(DtoInstallation),
    ]),
    sandreCodes: PropTypes.arrayOf(PropTypes.instanceOf(DtoSandreCode)),
    readMode: PropTypes.bool,
    onChange: PropTypes.func,
    setPopup: PropTypes.func,
    createAgriTank: PropTypes.func,
    fetchInstallationAgriTanks: PropTypes.func,
    installationAgriTanks: PropTypes.arrayOf(PropTypes.instanceOf(DtoInstallationTank)),
    agriTanksTypes: PropTypes.arrayOf(PropTypes.instanceOf(DtoAgriTanksType)),
}

const mapStateToProps = store => ({
    sandreCodes: store.ReferencialReducer.sandreCodes,
    installationAgriTanks: store.InstallationReducer.installationAgriTanks,
    agriTanksTypes: store.AgriReducer.agriTanksTypes,
})

const mapDispatchToProps = {
    setPopup: SieauAction.setPopup,
    createAgriTank: InstallationAction.createAgriTank,
    fetchInstallationAgriTanks: InstallationAction.fetchInstallationAgriTanks,
}

export default connect(mapStateToProps, mapDispatchToProps)(StationTanksPanel)