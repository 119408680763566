/* eslint-disable camelcase */
import { Grid2 } from '@mui/material'
import SieauParameterDto from 'administration/dto/SieauParameterDto'
import Checkbox from 'components/forms/Checkbox'
import Input from 'components/forms/Input'
import NumberField from 'components/forms/NumberField'
import Select from 'components/forms/Select'
import Textarea from 'components/forms/Textarea'
import DtoAgriTanksType from 'exploitations/dto/DtoAgriTanksType'
import DtoInstallationTank from 'installation/dto/installation/DtoInstallationTank'
import { orderBy } from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import CityDto from 'referencial/components/city/dto/CityDto'
import DtoSandreCode from 'referencial/dto/DtoSandreCode'
import i18n from 'simple-react-i18n'
import { hasValue } from 'utils/NumberUtil'
import { getSetting, getSettingInt } from 'utils/SettingUtils'
import { formatMilliers } from 'utils/StringUtil'

class StationTankPanel extends Component {
    constructor(props) {
        super(props)
        this.state = {
            tank: props.tank,
        }
    }

    componentDidMount() {
        const { applicationSettings, installationAgriTanks, tank, disabled } = this.props
        if (!tank?.idTank && !disabled) {
            const defaultTankType = getSettingInt(applicationSettings, 'defaultTankType')
            if (['True', '1', 'OUI', true].includes(getSetting(applicationSettings, 'useAutomaticCodificationTank') || false)) {
                const prefixCodificationTank = getSetting(applicationSettings, 'prefixCodificationTank') || ''
                const suffixCodificationTank = getSetting(applicationSettings, 'suffixCodificationTank') || ''
                const minLengthCodificationTank = getSettingInt(applicationSettings, 'minLengthCodificationTank') || 0
                const maxRefTank = Math.max(...installationAgriTanks.map((p) => {
                    const str = p?.id?.replace(prefixCodificationTank, '') || ''
                    return parseInt(str.slice(0, str.length - suffixCodificationTank.length)) || 0
                })) + 1
                const newId = maxRefTank.toString().length >= minLengthCodificationTank ? maxRefTank : `${'0'.repeat(minLengthCodificationTank - maxRefTank.toString().length)}${maxRefTank}`
                this.onChangeValue({ id: `${prefixCodificationTank}${newId}${suffixCodificationTank}`, tankType: defaultTankType })
            } else {
                this.onChangeValue({ tankType: defaultTankType })
            }
        }
    }

    onChangeValue = (obj) => {
        const { tank } = this.props
        this.props.onChange({
            ...tank,
            ...obj,
        })
    }

    getStyle = (disabled) => {
        return {
            fontSize: '1rem',
            color: disabled ? '#9e9e9e' : '#161832',
        }
    }

    getSelectedPeriod = (idPeriode, tankPeriodes) => {
        return !!tankPeriodes.find((p) => p.idNbPeriode === idPeriode)
    }

    handleChangePeriode = (select, code, tank) => {
        let newTankPeriodes = []
        if (select) {
            newTankPeriodes.push({
                idTank: tank.idTank || -1,
                idPeriode: code,
                idNbPeriode: null,
            })
        } else {
            if (tank.link_periodes.find((p) => p.idNbPeriode === code)) {
                newTankPeriodes = [
                    ...tank.link_periodes,
                ]
                newTankPeriodes = newTankPeriodes.filter((p) => p.idNbPeriode !== null && p.idNbPeriode !== code)
            } else {
                newTankPeriodes = [
                    ...tank.link_periodes,
                    {
                        idTank: tank.idTank || -1,
                        idPeriode: 2,
                        idNbPeriode: code,
                    },
                ]
                newTankPeriodes = newTankPeriodes.filter((p) => p.idNbPeriode !== null)
            }
            if (!newTankPeriodes.length) {
                newTankPeriodes.push({
                    idTank: tank.idTank || -1,
                    idPeriode: 2,
                    idNbPeriode: null,
                })
            }
        }
        this.onChangeValue({ link_periodes: newTankPeriodes })
    }

    render = () => {
        const { tank, sandreCodes, disabled, readMode, lockId, agriTanksTypes } = this.props
        const periodes = sandreCodes.filter((code) => code.field === 'USAGES.PERIODES_DETAILS')
        const saisonnal = (tank.link_periodes || []).find((p) => p.idPeriode === 2)
        const annuel = (tank.link_periodes || []).find((p) => p.idPeriode === 1)
        const unknown = (tank.link_periodes || []).find((p) => p.idPeriode === 0)

        return (
            <Grid2 container spacing={1}>
                <Grid2 size={6}>
                    <Input
                        title={i18n.login}
                        value={tank.id}
                        onChange={value => this.onChangeValue({ id: value })}
                        disabled={disabled || lockId}
                        readMode={readMode}
                    />
                </Grid2>
                <Grid2 size={6}>
                    <Select
                        label={i18n.detentionType}
                        value={tank.tankType}
                        options={agriTanksTypes}
                        keyValue='id'
                        keyMabel='name'
                        styleLabel={this.getStyle(disabled)}
                        onChange={value => this.onChangeValue({ tankType: hasValue(value) ? value : null })}
                        disabled={disabled}
                        readMode={readMode}
                        integerValue
                    />
                </Grid2>
                <Grid2 size={6}>
                    {disabled ? (
                        <Input title={i18n.capability} value={formatMilliers(tank.capacity)} disabled />
                    ) : (
                        <NumberField
                            title={i18n.capability}
                            value={tank.capacity}
                            onChange={value => this.onChangeValue({ capacity: value })}
                            disabled={disabled}
                            readMode={readMode}
                        />
                    )}
                </Grid2>
                <Grid2 size={6}>
                    <NumberField
                        title={i18n.recoveryFlow}
                        value={tank.recoveryFlow}
                        onChange={value => this.onChangeValue({ recoveryFlow: value })}
                        floatValue
                        disabled={disabled}
                        readMode={readMode}
                    />
                </Grid2>
                <Grid2 size={6}>
                    <Select
                        label={i18n.fillingMode}
                        value={tank.fillingMode}
                        options={orderBy(sandreCodes.filter((c) => c.field === 'PREL_AGRI.MODE_REMPLISSAGE'), ['name'])}
                        keyValue='code'
                        styleLabel={this.getStyle(disabled)}
                        onChange={value => this.onChangeValue({ fillingMode: hasValue(value) ? value : null })}
                        disabled={disabled}
                        readMode={readMode}
                        integerValue
                        noSort
                    />
                </Grid2>
                <Grid2 size={6}>
                    <NumberField
                        title={i18n.reserveFlow}
                        value={tank.reserveFlow}
                        onChange={value => this.onChangeValue({ reserveFlow: value })}
                        floatValue
                        disabled={disabled}
                        readMode={readMode}
                    />
                </Grid2>
                <Grid2 size={6}>
                    <Select
                        label={i18n.fillingPeriod}
                        value={saisonnal ? 2 : (annuel ? 1 : (hasValue(unknown) ? 0 : null))}
                        options={sandreCodes.filter((c) => c.field === 'PREL_AGRI.PERIODE_REMPLISSAGE')}
                        keyvalue='code'
                        styleLabel={this.getStyle(disabled)}
                        onChange={(value) => this.handleChangePeriode(true, value, tank)}
                        disabled={disabled}
                        readMode={readMode}
                    />
                </Grid2>
                <Grid2 size={6}>
                    <NumberField
                        title={i18n.usedPercentage}
                        value={tank.usedPercentage}
                        onChange={value => this.onChangeValue({ usedPercentage: value })}
                        disabled={disabled}
                        readMode={readMode}
                    />
                </Grid2>
                <Grid2 size={6}>
                    <div className='row no-margin col s6'>
                        {saisonnal && periodes.map((p) => (
                            <Checkbox
                                key={p.code}
                                col
                                label={p.name}
                                checked={this.getSelectedPeriod(p.code, tank.link_periodes)}
                                onChange={() => this.handleChangePeriode(false, parseInt(p.code), tank)}
                                disabled={readMode || disabled}
                            />
                        ))}
                    </div>
                </Grid2>
                <Grid2 size={6}>
                    <Checkbox
                        checked={tank.sharedRetention}
                        label={i18n.sharedRetention}
                        onChange={v => this.onChangeValue({ sharedRetention: v })}
                        disabled={readMode || disabled}
                    />
                </Grid2>
                <Grid2 size={12}>
                    <Textarea
                        title={i18n.comment}
                        value={tank.comment}
                        onChange={value => this.onChangeValue({ comment: value })}
                        disabled={disabled}
                        readMode={readMode}
                    />
                </Grid2>
            </Grid2>
        )
    }
}

StationTankPanel.propTypes = {
    tank: PropTypes.instanceOf(DtoInstallationTank),
    onChange: PropTypes.func.isRequired,
    cities: PropTypes.arrayOf(PropTypes.instanceOf(CityDto)),
    sandreCodes: PropTypes.arrayOf(PropTypes.instanceOf(DtoSandreCode)),
    disabled: PropTypes.bool,
    readMode: PropTypes.bool,
    lockId: PropTypes.bool,
    applicationSettings: PropTypes.arrayOf(PropTypes.instanceOf(SieauParameterDto)),
    installationAgriTanks: PropTypes.arrayOf(PropTypes.instanceOf(DtoInstallationTank)),
    agriTanksTypes: PropTypes.arrayOf(PropTypes.instanceOf(DtoAgriTanksType)),
}

const mapStateToProps = store => ({
    cities: store.CityReducer.cities,
    sandreCodes: store.ReferencialReducer.sandreCodes,
    applicationSettings: store.AdministrationReducer.applicationSettings,
    installationAgriTanks: store.InstallationReducer.installationAgriTanks,
    agriTanksTypes: store.AgriReducer.agriTanksTypes,
})

export default connect(mapStateToProps)(StationTankPanel)