/* eslint-disable camelcase */
import { Dialog, DialogContent } from '@mui/material'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import SelectionTable from '../../../../components/datatable/SelectionTable'
import Input from '../../../../components/forms/Input'
import DtoInstallation from '../../../../installation/dto/installation/DtoInstallation'
import DtoInstallationTank from '../../../../installation/dto/installation/DtoInstallationTank'
import DtoSandreCode from '../../../../referencial/dto/DtoSandreCode'
import { searchAllCharacters } from '../../../../utils/StringUtil'

const headers = ['id', 'capacity', 'recoveryFlow']

class SelectTanksModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            link_sampleTanks: [ ...props.installation.link_sampleTanks ],
            searchValue: '',
        }
    }

    addTank = (tank) => {
        const { link_sampleTanks } = this.state
        const { installation } = this.props
        this.setState({ link_sampleTanks: [
            ...link_sampleTanks,
            {
                ...tank,
                idInstallation: installation.id,
            }],
        })
    }

    removeTank = (tank) => {
        const { link_sampleTanks } = this.state
        const newLinks = link_sampleTanks.filter((u) => u.idTank !== tank.idTank)
        this.setState({ link_sampleTanks: newLinks })
    }

    getFilteredData = (data) => {
        const { searchValue } = this.state
        const includesValue = searchAllCharacters(searchValue || '')
        return data.filter(i => this.getHash(i).includes(includesValue))
    }

    getHash = (tank) => {
        return searchAllCharacters(headers.map(key => tank[key]))
    }

    getTanks = () => {
        const { installationAgriTanks } = this.props
        const { link_sampleTanks } = this.state
        const removeDuplicate = installationAgriTanks.filter((v, i, arr) => arr.findIndex((t) => (t.idTank === v.idTank)) === i) // TO ADPAT WHEN CHANGE DATABASE
        const filteredTanks = this.getFilteredData(removeDuplicate.filter((t) => !link_sampleTanks.find((iTank) => t.idTank === iTank.idTank)))
        return [filteredTanks, headers]
    }

    getSelectedTanks = () => {
        const { installationAgriTanks } = this.props
        const { link_sampleTanks } = this.state
        const selectedTanks = link_sampleTanks.map((iTank) => installationAgriTanks.find((t) => t.idTank === iTank.idTank) || {})
        const filteredTanks = this.getFilteredData(selectedTanks)
        return [filteredTanks, headers]
    }

    onCancel = () => {
        const { installation } = this.props
        this.setState({ link_sampleTanks: installation.link_sampleTanks, searchValue: '' })
        this.props.onCancel()
    }

    onValidate = () => {
        const { link_sampleTanks } = this.state
        this.setState({ searchValue: '' })
        this.props.onValidate(link_sampleTanks)
    }

    render() {
        const { open } = this.props
        const { searchValue } = this.state

        const [listData, listHeaders] = this.getTanks()
        const [selectedData, selectedHeaders] = this.getSelectedTanks()
        const customHeaders = {
            id: i18n.login,
            capacity: i18n.capability,
        }

        return (
            <Dialog
                open={open}
                maxWidth='lg'
                fullWidth
            >
                <DialogContent>
                    <div className='row padding-top-1 no-margin'>
                        <div className='col s4'>
                            <Input title={ i18n.search } value={ searchValue }
                                onChange={ (value) => this.setState({ searchValue: value }) }
                            />
                        </div>
                    </div>
                    <SelectionTable
                        listData={listData}
                        listHeaders={listHeaders}
                        listCustomHeaders={customHeaders}
                        listTitle={listData.length > 1 ? i18n.detentions : i18n.detention}
                        selectedData={selectedData}
                        selectedHeaders={selectedHeaders}
                        selectedCustomHeaders={customHeaders}
                        selectedTitle={selectedData.length > 1 ? i18n.selectedDetentions : i18n.selectedDetention}
                        onAdd={this.addTank}
                        onDelete={this.removeTank}
                        maxHeight='35vh'
                    />
                    <div
                        className='row no-margin padding-top-1'
                        style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                        }}
                    >
                        <a className='waves-effect waves-teal btn-flat' onClick={ this.onCancel }>
                            { i18n.cancel }
                        </a>
                        <a className='btn' onClick={ this.onValidate }>
                            { i18n.validate }
                        </a>
                    </div>
                </DialogContent>
            </Dialog>
        )
    }
}

SelectTanksModal.propTypes = {
    installationAgriTanks: PropTypes.arrayOf(PropTypes.instanceOf(DtoSandreCode)),
    sandreCodes: PropTypes.arrayOf(PropTypes.instanceOf(DtoInstallationTank)),
    installation: PropTypes.instanceOf(DtoInstallation),
    onValidate: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
}

const mapStateToProps = (store) => {
    return {
        installationAgriTanks: store.InstallationReducer.installationAgriTanks,
        sandreCodes: store.ReferencialReducer.sandreCodes,
    }
}

export default connect(mapStateToProps)(SelectTanksModal)
