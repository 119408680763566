import { Button, Grid } from '@mui/material'
import Card from 'components/card/Card'
import Select from 'components/forms/Select'
import { countBy, differenceWith, isEqual, isUndefined, omit, unionWith } from 'lodash'
import PropTypes from 'prop-types'
import React, { useEffect, useMemo, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import { mainBlue } from 'utils/constants/ColorTheme'
import useActions from 'utils/customHook/useActions'
import useBoolean from 'utils/customHook/useBoolean'
import { exportFile } from 'utils/ExportDataUtil'
import MessageCard from '../../../../components/card/MessageCard'
import Table from '../../../../components/datatable/Table'
import Checkbox from '../../../../components/forms/Checkbox'
import { calculateThresholdResult, getQualificationIcon, getStatusIcon } from '../../../../utils/AnalyseUtils'
import { getDate } from '../../../../utils/DateUtil'
import { hasValue } from '../../../../utils/NumberUtil'
import { getLabel, getLabelTruncate, getSandreList } from '../../../../utils/StoreUtils'
import { searchAllCharacters, substringText } from '../../../../utils/StringUtil'
import QualityIntegrationOverviewAction from '../actions/QualityIntegrationOverviewAction'
import { DELETE_ANALYSIS_CONFORMITY, UPDATE_ANALYSIS_CONFORMITY, headerConformTable, headerNonConformTable, nbPerPageLabelConform, nbPerPageLabelNonConform } from '../constants/IntegrationOverviewConstants'
import FractionAction from '../../../../referencial/components/fraction/actions/FractionAction'
import { SANDRE } from '../../../../referencial/constants/ReferencialConstants'
import SupportAction from '../../../../referencial/components/support/actions/SupportAction'
import ReferencialAction from '../../../../referencial/action/ReferencialAction'
import ContributorAction from '../../../../referencial/components/contributor/actions/ContributorAction'
import { getQualificationSelectOptions, getStatusSelectOptions } from '../../../../utils/StatusUtil'
import AnalysisPopin from 'quality/components/operation/components/analysis/AnalysisPopin'
import ConfirmModal from 'components/modal/ConfirmModal'
import OperationAction from 'quality/components/operation/actions/OperationAction'
import ToastrAction from 'toastr/actions/ToastrAction'

const CardUpdateAnalysis = ({
    selectedAnalysis = [],
    setSelectedAnalysis = () => { },
}) => {
    const dispatch = useDispatch()
    const [status, setStatus] = useState()
    const [qualification, setQualification] = useState()

    const onValidate = () => {
        const updatedAnalysis = selectedAnalysis.map(a => ({
            ...a,
            status: hasValue(status) ? `${status}` : undefined,
            qualification: hasValue(qualification) ? `${qualification}` : undefined,
        }))
        dispatch(QualityIntegrationOverviewAction.updateAnalysis(updatedAnalysis)).then(() => {
            setSelectedAnalysis([])
        })
    }

    return (
        <Card>
            <div style={{ padding: 10 }}>
                <i>{i18n.selectAnalysisToValidate}</i>
            </div>
            <Grid container spacing={2} sx={{ padding: '0 10' }}>
                <Grid item xs={3}>
                    <Select
                        options={getStatusSelectOptions()}
                        label={i18n.status}
                        nullLabel='&nbsp;'
                        value={status}
                        onChange={setStatus} // toString
                    />
                </Grid>
                <Grid item xs={3}>
                    <Select
                        options={getQualificationSelectOptions()}
                        label={i18n.qualification}
                        nullLabel='&nbsp;'
                        value={qualification}
                        onChange={setQualification} // toString
                    />
                </Grid>
                <Grid item xs={4} />
                <Grid item xs={2}>
                    <Button
                        onClick={onValidate}
                        fullWidth
                        variant='contained'
                    >
                        {i18n.validate}
                    </Button>
                </Grid>
            </Grid>
        </Card>
    )
}

CardUpdateAnalysis.propTypes = {
    selectedAnalysis: PropTypes.arrayOf(PropTypes.shape({
        stationId: PropTypes.number,
        numAnalyse: PropTypes.number,
        numSample: PropTypes.number,
        code: PropTypes.string,
    })),
    setSelectedAnalysis: PropTypes.func,
}

const MultiLineLabel = ({
    label = '',
    listLabel = [],
}) => listLabel.length ? (
    <div>
        {label}
        <br />
        {listLabel.map(lbl => (
            <div style={{ paddingTop: '5px' }} className={lbl.length > 80 ? 'tooltipped' : ''} data-tooltip={lbl}>
                {substringText(lbl, 80)}
                <br />
            </div>
        ))}
    </div>
) : label

MultiLineLabel.propTypes = {
    label: PropTypes.string,
    listLabel: PropTypes.arrayOf(PropTypes.string),
}

const formatTitle = (label, nbElement) => `${label} (${nbElement} ${nbElement > 1 ? i18n.elements : i18n.element})`

const CardTable = ({
    title = '',
    analysis = [],
    headers = [],
    nbPerPageLabel = [],

    setSelectedAnalysis = () => {},
    onAlter = () => {},
    onDelete = () => {},
    isEditMode = false,
}) => {
    const {
        parameters,
        units,
        supports,
        contributorsIndex,
        fractions,
        analyseErrorTypes,
        status,
        qualifications,
        qualitosIndex,
        sandreCodes,
    } = useSelector(store => ({
        parameters: store.ParameterReducer.parameters,
        units: store.UnitReducer.units,
        sandreCodes: store.ReferencialReducer.sandreCodes,
        supports: store.SupportReducer.supports,
        contributorsIndex: store.ContributorReducer.contributorsIndex,
        fractions: store.FractionReducer.fractions,
        analyseErrorTypes: store.QualityIntegrationOverviewReducer.analyseErrorTypes,
        status: store.QualityReducer.status,
        qualifications: store.QualityReducer.qualifications,
        qualitosIndex: store.QualityReducer.qualitosIndex,
    }), shallowEqual)

    const dispatch = useDispatch()
    useEffect(() => {
        if (!analyseErrorTypes.length) {
            dispatch(QualityIntegrationOverviewAction.fetchAnalyseErrorTypes())
        }
    }, [])

    const remarks = getSandreList(sandreCodes, SANDRE.REMARK_CODE)

    const errorTypes = useMemo(() => [
        ...analyseErrorTypes,
        {
            code: i18n.improbability.toUpperCase(),
            label: i18n.improbabilityThresholdExceeded,
        },
        {
            code: i18n.impossibility.toUpperCase(),
            label: i18n.impossibilityThresholdExceeded,
        }
    ], [analyseErrorTypes])

    const isAllCheck = analysis.every(a => a.isCheck)

    const checkAll = () => {
        const newAnalysis = analysis.map(a => omit(a, ['isCheck']))
        if (isAllCheck) {
            setSelectedAnalysis(prev => differenceWith(prev, newAnalysis, isEqual))
        } else {
            setSelectedAnalysis(prev => unionWith(prev, newAnalysis, isEqual))
        }
    }

    const checkAnalyse = analyse => {
        if (analyse.isCheck) {
            setSelectedAnalysis(prev => prev.filter(a => a.stationId !== analyse.stationId || a.numAnalyse !== analyse.numAnalyse || a.numSample !== analyse.numSample || a.code !== analyse.code))
        } else {
            setSelectedAnalysis(prev => [
                ...prev,
                omit(analyse, ['isCheck']),
            ])
        }
    }

    const formatedData = analysis.map(a => {
        const station = qualitosIndex[a.stationId]
        const errors = a.errors.map(err => errorTypes.find(({ code }) => code === err) || {})
        const labelCode = errors.map(({ label }) => label || '')

        const parameter = parameters.find(({ code }) => code === a.parameter)
        const fraction = getLabel(fractions, a.fraction)
        const fractionTooltip = fraction.length > 20 ? {
            className: 'tooltipped',
            tooltip: fraction,
        } : {}
        const support = getLabel(supports, a.support)
        const supportTooltip = support.length > 20 ? {
            className: 'tooltipped',
            tooltip: support,
        } : {}
        const unit = units.find(({ code }) => code == a.unit)

        const labo = hasValue(a.laboratory) && contributorsIndex[a.laboratory]
        const laboLabel = labo && (labo.mnemonique || labo.name) || ''

        const qualification = getLabel(qualifications, a.qualification)
        const statusLabel = getLabel(status, a.status)

        const height = a.errors.length ? a.errors.length * 20 + 15 : undefined

        const errorGroup = countBy(errors, 'controlType')

        return {
            code: {
                value: (
                    <MultiLineLabel label={station?.code} listLabel={a.errors} />
                ),
                sortValue: station?.code,
            },
            name: {
                value: (
                    <MultiLineLabel label={station?.name} listLabel={labelCode} />
                ),
                sortValue: station?.name,
            },
            exportCode: `${station?.code} ${a.errors}`,
            exportName: `${station?.name} ${labelCode}`,
            sample: { value: getDate(a.sampleDate), height },
            labo: { value: laboLabel, height },
            parameter: { value: parameter && getLabelTruncate(parameter.shortLabel || parameter.name || '', parameter.code, 20) || '', height },
            result: {
                value: (
                    <div className='valign-wrapper'>
                        {getStatusIcon(a.status, status, {}, false)}
                        {
                            a.qualification !== '1' && (
                                <i className='material-icons tiny right no-margin'>{getQualificationIcon(a.qualification)}</i>
                            )
                        }
                        <span style={{ marginLeft: a.qualification !== '1' ? '5px' : '0px' }}>{a.result}</span>
                    </div>
                ),
                setTooltip: () => (
                    <div>
                        <span>
                            {`${i18n.status}: ${statusLabel}`}
                            <br />
                        </span>
                        <span>
                            {`${i18n.qualification}: ${qualification}`}
                        </span>
                    </div>
                ),
                sortValue: a.result,
                height,
            },
            unit: { value: unit && `${unit.symbol} [${unit.code}]` || '', height },
            support: {
                value: getLabelTruncate(support, a.support, 20),
                height,
                ...supportTooltip,
            },
            fraction: {
                value: getLabelTruncate(fraction, a.fraction, 20),
                height,
                ...fractionTooltip,
            },
            remark: { value: getLabel(remarks, a.remark), height },
            conformity: { value: `${errorGroup[1] || 0}`, height, positionCell: 'right' },
            threshold: { value: `${errorGroup[3] || 0}`, height, positionCell: 'right' },
            outrageous: { value: `${errorGroup[2] || 0}`, height, positionCell: 'right' },
            nullValue: isEditMode ? {
                value: (
                    <Checkbox
                        col={6}
                        checked={a.isCheck}
                        onChange={() => checkAnalyse(a)}
                    />
                ),
                height,
            } : undefined,
            isCheck: a.isCheck,
            analysis: a,
        }
    })

    const exportAction = {
        iconName: 'file_download',
        onClick: () => {
            const exportData = formatedData.map(d => ({
                ...d,
                result: { value: d.result.sortValue },
                code: d.exportCode,
                name: d.exportName,
            }))
            exportFile({
                data: exportData.length ? [
                    { ...exportData[0], headers: headerNonConformTable },
                    ...exportData.slice(1),
                ] : [],
                titleFile: title,
            })
        },
    }

    const actions = isEditMode ? [
        exportAction,
        {
            iconName: isAllCheck ? 'check_box_outline_blank' : 'check_box',
            onClick: checkAll,
        },
    ] : [exportAction]

    return (
        <Card title={formatTitle(title, formatedData.length)} headerStyle={{ backgroundColor: mainBlue }} actions={actions}>
            <Table
                showTitle={false}
                data={formatedData}
                sortable
                type={{ headers: isEditMode ? [...headers, 'nullValue'] : headers }}
                condensed
                color
                alterable={isEditMode}
                onAlter={a => onAlter(a.analysis)}
                deletable={isEditMode}
                onDelete={a => onDelete(a.analysis)}
                nbPerPageLabel={nbPerPageLabel}
                paging
            />
        </Card>
    )
}

CardTable.propTypes = {
    title: PropTypes.string,
    analysis: PropTypes.arrayOf(PropTypes.shape({

    })),
    headers: PropTypes.arrayOf(PropTypes.string),
    nbPerPageLabel: PropTypes.arrayOf(PropTypes.shape({

    })),

    setSelectedAnalysis: PropTypes.func,
    onAlter: PropTypes.func,
    onDelete: PropTypes.func,
    isEditMode: PropTypes.bool,
}

const analysisToComformity = analysis => {
    const status = parseInt(analysis.status)
    const qualification = parseInt(analysis.qualification)
    return {
        numAnalyse: analysis.id,
        stationId: analysis.qualitometer,
        operationId: analysis.operation,
        numSample: analysis.sample,
        sampleDate: analysis.date ?? analysis.analysisDate,
        laboratory: analysis.labo,
        parameter: analysis.parameter,
        status: isNaN(status) ? undefined : status,
        result: analysis.result,
        unit: analysis.unit,
        support: isUndefined(analysis.support) ? undefined : `${analysis.support}`,
        fraction: analysis.fraction,
        remark: analysis.remark,
        errors: analysis.errors,
        qualification: isNaN(qualification) ? undefined : qualification,
    }
}

const AnalysisTab = ({
    search,
    noAnalysisMessage = i18n.noJobAvailableForTheseCriteria,
    className = '',

    filters,

    improbabilityThresholdId,
    impossibilityThresholdId,
}) => {
    const dispatch = useDispatch()
    const {
        analysis,
        qualityThresholds,
        supports,
        fractions,
        sandreCodes,
        contributors,
    } = useSelector(store => ({
        contributors: store.ContributorReducer.contributors,
        sandreCodes: store.ReferencialReducer.sandreCodes,
        supports: store.SupportReducer.supports,
        fractions: store.FractionReducer.fractions,
        analysis: store.QualityIntegrationOverviewReducer.analysis,
        qualityThresholds: store.QualityReducer.qualityThresholds,
    }), shallowEqual)

    useEffect(() => {
        if (!supports.length) {
            dispatch(SupportAction.fetchSupports())
        }
        if (!fractions.length) {
            dispatch(FractionAction.fetchFractions())
        }
        if (!sandreCodes.length) {
            dispatch(ReferencialAction.fetchSandreCodes())
        }
        if (!contributors.length) {
            dispatch(ContributorAction.fetchContributors())
        }
    }, [])

    const {
        value: isOpenAnalysisPopin,
        setFalse: closeAnalysisPopin,
        setTrue: openAnalysisPopin,
    } = useBoolean(false)
    const {
        value: isConfirmOpen,
        setFalse: closeConfirm,
        setTrue: openConfirm,
    } = useBoolean(false)
    const [selectedAnalyse, setSelectedAnalyse] = useState({})

    const {
        value: isEditMode,
        setFalse: readMode,
        setTrue: editMode,
    } = useBoolean(false)
    const [selectedAnalysis, setSelectedAnalysis] = useState([])

    useActions(() => {
        if (isEditMode) {
            return {
                cancel: () => {
                    setSelectedAnalysis([])
                    readMode()
                },
            }
        }
        return {
            edit: editMode,
        }
    }, [isEditMode])

    const filteredAnalysis = useMemo(() => {
        const searchFormated = searchAllCharacters(search)
        const searchValueFilter = searchFormated ? analysis.filter(a => searchAllCharacters(`${a.code}${a.name}`).includes(searchFormated)) : analysis
        return filters?.parameters?.length > 0 ? searchValueFilter.filter(a => filters?.parameters.includes(a.parameter)) : searchValueFilter
    }, [analysis, search, filters])

    const formatedAnalysis = useMemo(() => {
        const improbabilityThreshold = qualityThresholds.find(t => t.thresholdCode === `${improbabilityThresholdId}`)
        const impossibilityThreshold = qualityThresholds.find(t => t.thresholdCode === `${impossibilityThresholdId}`)
        return filteredAnalysis.map(a => {
            const { thresholdIndice: resultImprobability } = calculateThresholdResult(a, improbabilityThreshold?.thresholds)
            const { thresholdIndice: resultImpossibility } = calculateThresholdResult(a, impossibilityThreshold?.thresholds)

            const improbabilityError = resultImprobability >= 1 ? [i18n.improbability.toUpperCase()] : []
            const impossibilityError = resultImpossibility >= 1 ? [i18n.impossibility.toUpperCase()] : []

            const isCheck = selectedAnalysis.some(s => a.stationId === s.stationId && a.numAnalyse === s.numAnalyse && a.numSample === s.numSample)

            return {
                ...a,
                errors: [...a.errors, ...improbabilityError, ...impossibilityError],
                isCheck,
            }
        })
    }, [filteredAnalysis, impossibilityThresholdId, improbabilityThresholdId, qualityThresholds, selectedAnalysis])

    const undefinedConformity = useMemo(() => formatedAnalysis.filter(a => a.errors.includes('UNDEFINED_CONFORMITY')), [formatedAnalysis])
    const conformData = useMemo(() => formatedAnalysis.filter(a => !a.errors.length), [formatedAnalysis])
    const nonConformData = useMemo(() => formatedAnalysis.filter(a => a.errors.length && !a.errors.includes('UNDEFINED_CONFORMITY')), [formatedAnalysis])

    if (undefinedConformity.length || nonConformData.length || conformData.length) {
        return (
            <div className={`${className || 'col s12 no-padding margin-bottom-9'}`}>
                {
                    isEditMode && (
                        <CardUpdateAnalysis
                            selectedAnalysis={selectedAnalysis}
                            setSelectedAnalysis={setSelectedAnalysis}
                        />
                    )
                }
                <CardTable
                    title={i18n.nonConform}
                    analysis={nonConformData}
                    headers={headerNonConformTable}
                    nbPerPageLabel={nbPerPageLabelNonConform}

                    setSelectedAnalysis={setSelectedAnalysis}
                    onAlter={a => {
                        setSelectedAnalyse(a)
                        openAnalysisPopin()
                    }}
                    onDelete={a => {
                        setSelectedAnalyse(a)
                        openConfirm()
                    }}
                    isEditMode={isEditMode}
                />
                <CardTable
                    title={i18n.conforms}
                    analysis={conformData}
                    headers={headerConformTable}
                    nbPerPageLabel={nbPerPageLabelConform}

                    setSelectedAnalysis={setSelectedAnalysis}
                    onAlter={a => {
                        setSelectedAnalyse(a)
                        openAnalysisPopin()
                    }}
                    onDelete={a => {
                        setSelectedAnalyse(a)
                        openConfirm()
                    }}
                    isEditMode={isEditMode}
                />
                {
                    !!undefinedConformity.length && (
                        <>
                            <CardTable
                                title={i18n.undefinedConformity}
                                analysis={undefinedConformity}
                                headers={headerConformTable}
                                nbPerPageLabel={nbPerPageLabelConform}

                                setSelectedAnalysis={setSelectedAnalysis}
                                onAlter={a => {
                                    setSelectedAnalyse(a)
                                    openAnalysisPopin()
                                }}
                                onDelete={a => {
                                    setSelectedAnalyse(a)
                                    openConfirm()
                                }}
                                isEditMode={isEditMode}
                            />
                        </>
                    )
                }

                <AnalysisPopin
                    isOpen={isOpenAnalysisPopin}
                    onClose={closeAnalysisPopin}
                    onValidate={(res = {}, newAnalysis) => {
                        if (res.update > 0) {
                            closeAnalysisPopin()
                            const newConformityAnalysis = analysisToComformity(newAnalysis)
                            dispatch({
                                type: UPDATE_ANALYSIS_CONFORMITY,
                                payload: [{ ...newConformityAnalysis, errors: selectedAnalyse.errors }],
                            })
                        }
                    }}

                    analysisId={selectedAnalyse.numAnalyse}
                    stationId={selectedAnalyse.stationId}
                    sampleId={selectedAnalyse.numSample}
                    operationId={selectedAnalyse.operationId}
                />
                <ConfirmModal
                    isOpen={isConfirmOpen}
                    title={i18n.sureDeleteAnalysis}
                    onValidate={() => {
                        const toDeleteAnalyse = {
                            id: selectedAnalyse.numAnalyse,
                            qualitometer: selectedAnalyse.stationId,
                            sample: selectedAnalyse.numSample,
                            operation: selectedAnalyse.operationId,
                        }
                        OperationAction.deleteAnalysis(toDeleteAnalyse)
                            .then(res => {
                                if (res.delete > 0) {
                                    dispatch(ToastrAction.success(i18n.elementDeleteSuccess))
                                    dispatch({
                                        type: DELETE_ANALYSIS_CONFORMITY,
                                        payload: selectedAnalyse,
                                    })
                                    closeConfirm()
                                } else {
                                    dispatch(ToastrAction.error(i18n.deleteError + i18n.analysis))
                                }
                            })
                    }}
                    onClose={closeConfirm}
                />
            </div>
        )
    }
    return <MessageCard>{noAnalysisMessage}</MessageCard>
}

AnalysisTab.propTypes = {
    search: PropTypes.string,
    noAnalysisMessage: PropTypes.string,
    className: PropTypes.string,

    filters: PropTypes.shape({
        parameters: PropTypes.arrayOf(PropTypes.string),
    }),

    improbabilityThresholdId: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    impossibilityThresholdId: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
}


export default AnalysisTab