import { Button, Grid } from '@mui/material'
import SimpleDatePicker from 'components/forms/SimpleDatePicker'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import i18n from 'simple-react-i18n'
import useSandreList from 'utils/customHook/useSandreList'
import Card from '../../components/card/Card'
import Input from '../../components/forms/Input'
import Select from '../../components/forms/Select'
import { SANDRE } from '../../referencial/constants/ReferencialConstants'
import { sieauTooltip } from '../../utils/FormUtils'
import { i18nize } from '../../utils/StringUtil'
import { QUALITY_CAMPAIGN_TYPES } from '../constants/CampaignConstants'


const FormFilterCampaigns = ({
    filter = {},
    sortBy,
    onChange = () => { },
    onValidate = () => { },
    onReset = () => { },
    stationType,
}) => {
    const campaignStatus = useSandreList('CAMPAGNES.STATUT')
    const campaignTypes = useSandreList(SANDRE.CAMPAGNES_TYPE)

    const [searchValue, setSearchValue] = useState(filter.searchValue)
    const [startDate, setStartDate] = useState(filter.startDate)
    const [endDate, setEndDate] = useState(filter.endDate)
    const [type, setType] = useState(filter.type)
    const [statut, setStatut] = useState(filter.statut)

    const onResetFilter = () => {
        onReset()
        setSearchValue(undefined)
        setStartDate(undefined)
        setEndDate(undefined)
        setType(undefined)
        setStatut(undefined)
    }

    const onValidateFilter = () => {
        onValidate({
            searchValue,
            startDate,
            endDate,
            type,
            statut,
        })
    }

    return (
        <div className='row no-margin'>
            <div className='col s12 no-padding'>
                <Card>
                    <div className='row no-margin padding-top-1'>
                        <Input
                            col={3}
                            title={i18n.search}
                            value={searchValue}
                            onChange={setSearchValue}
                        />
                        <SimpleDatePicker
                            label={i18n.startDate}
                            value={startDate}
                            onChange={setStartDate}
                            col={3}
                        />

                        <SimpleDatePicker
                            label={i18n.endDate}
                            value={endDate}
                            onChange={setEndDate}
                            col={3}
                        />
                        <Select
                            col={3}
                            label={i18n.type}
                            value={type}
                            options={stationType === 'quality' ? i18nize(QUALITY_CAMPAIGN_TYPES) : campaignTypes}
                            onChange={setType}
                            keyValue={'code'}
                            nullLabel='&nbsp;'
                        />
                        <Select
                            col={3}
                            className={'clear-left'}
                            label={i18n.status}
                            options={campaignStatus}
                            value={statut}
                            onChange={setStatut}
                            keyValue={'code'}
                            nullLabel='&nbsp;'
                        />
                        <Grid container className='col s9 padding-1' justifyContent='flex-end' spacing={1}>
                            <Grid item>
                                <Button variant='outlined' onClick={onResetFilter}>
                                    {i18n.reinit}
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button variant='contained' onClick={onValidateFilter}>
                                    {i18n.search}
                                </Button>
                            </Grid>
                        </Grid>
                    </div>
                </Card>
                <div className='row no-margin'>
                    <div className='col s4 offset-s8 no-padding margin-top-1'>
                        <ul className='tabs filter round-tab'>
                            <li className='tab filter col s3 hash-tab' id='date' onClick={() => onChange({ sortBy: 'statut' })} {...sieauTooltip(i18n.byStatus, null, 'bottom')}>
                                <a className={`${sortBy === 'statut' && 'active' || ''} filter`}>
                                    <i className='material-icons inherited-line-height'>edit</i>
                                </a>
                            </li>
                            <li className='tab filter col s3 hash-tab' id='date' onClick={() => onChange({ sortBy: 'date' })} {...sieauTooltip(i18n.byDate, null, 'bottom')}>
                                <a className={`${sortBy === 'date' && 'active' || ''} filter`}>
                                    <i className='material-icons inherited-line-height'>insert_invitation</i>
                                </a>
                            </li>
                            <li className='tab filter col s3 hash-tab' id='referent' onClick={() => onChange({ sortBy: 'referent' })} {...sieauTooltip(i18n.byReferent, null, 'bottom')}>
                                <a className={`${sortBy === 'referent' && 'active' || ''} filter`}>
                                    <i className='material-icons inherited-line-height'>person</i>
                                </a>
                            </li>
                            <li className='tab filter col s3' id='type' onClick={() => onChange({ sortBy: 'type' })} {...sieauTooltip(i18n.byCampaignType, null, 'bottom')}>
                                <a className={`${sortBy === 'type' && 'active' || ''} filter`}>
                                    <i className='material-icons inherited-line-height'>business_center</i>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

FormFilterCampaigns.propTypes = {
    filter: PropTypes.exact({
        searchValue: PropTypes.string,
        startDate: PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.string,
        ]),
        endDate: PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.string,
        ]),
        type: PropTypes.number,
        statut: PropTypes.number,
    }),
    sortBy: PropTypes.number,
    onChange: PropTypes.func,
    onValidate: PropTypes.func,
    onReset: PropTypes.func,
    stationType: PropTypes.string,
}

export default FormFilterCampaigns