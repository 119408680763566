module.exports = {
    LIST: 'LIST',
    MAP: 'MAP',
    DASHBOARD: 'DASHBOARD',
    NITRATES_CODE: '1340',

    AVERAGE_SUBSTANCE: 'AVERAGE_SUBSTANCE',
    MAX_SUBSTANCE: 'MAX_SUBSTANCE',
    AVERAGE_SUM: 'AVERAGE_SUM',
    MAX_SUM: 'MAX_SUM',

    MISSING_DATA: 'missingData',
    NITRATES_PESTICIDES: 'nitratesAndPesticides',
    NITRATES: 'nitrates',
    PESTICIDES: 'pesticides',
    NO_CONTAMINATION: 'noContaminationHigherThanThreshold',
    EXCEEDING: 'EXCEEDING',

    LAST_VALUE: 0,
    MIN: 1,
    MAX: 2,
    AVERAGE: 3,
    P90: 4,
}