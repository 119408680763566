import { Button, Dialog, DialogActions, DialogContent } from '@mui/material'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import i18n from 'simple-react-i18n'
import DtoInstallationTank from '../../../../installation/dto/installation/DtoInstallationTank'
import StationTankPanel from '../StationTankPanel'
import { DefaultDialogTitle } from 'components/styled/Dialog'

const TankModal = ({
    tank,
    open,
    title,
    lockId,
    onValidate = () => {},
    onCancel = () => {},
}) => {
    const [tankState, setTank] = useState(tank)

    return (
        <Dialog
            open={open}
            maxWidth='md'
            fullWidth
        >
            <DefaultDialogTitle
                title={title || i18n.updateTank}
                onClose={() => {
                    setTank()
                    onCancel()
                }}
            />
            <DialogContent>
                <StationTankPanel
                    tank={tankState}
                    onChange={(obj) => setTank(prev => ({ ...prev, ...obj }))}
                    lockId={lockId}
                />
            </DialogContent>
            <DialogActions>
                <Button variant='contained' color='primary' onClick={onValidate}>
                    {i18n.validate}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

TankModal.propTypes = {
    open: PropTypes.bool,
    lockId: PropTypes.bool,
    tank: PropTypes.instanceOf(DtoInstallationTank),
    onCancel: PropTypes.func,
    onValidate: PropTypes.func,
    title: PropTypes.string,
}

export default TankModal