/* eslint-disable camelcase */
/* eslint-disable indent */
import {
    Button,
    Checkbox,
    DialogContent,
    FormControlLabel,
    FormGroup,
    FormHelperText,
    Grid,
    Radio,
    RadioGroup,
} from '@mui/material'
import { withStyles } from '@mui/styles'
import SieauParameterDto from 'administration/dto/SieauParameterDto'
import AgriAction from 'agriAdministration/actions/AgriAction'
import { StyledFieldSet, StyledLegend } from 'components/StyledElements'
import Input from 'components/forms/Input'
import NumberField from 'components/forms/NumberField'
import Select from 'components/forms/Select'
import Textarea from 'components/forms/Textarea'
import Row from 'components/react/Row'
import { DialogActionsMUI, DialogMUI, DialogTitleMUI } from 'components/styled/Dialog'
import DtoExploitation from 'exploitations/dto/DtoExploitation'
import DtoInstallationWithGeoItem from 'installation/components/installations/dto/DtoInstallationWithGeoItem'
import DtoInstallation from 'installation/dto/installation/DtoInstallation'
import { orderBy, sortBy } from 'lodash'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import DtoCulture from 'referencial/components/cultures/dto/DtoCulture'
import DtoCulturesFamily from 'referencial/components/culturesFamilies/dto/DtoCulturesFamily'
import DtoManagementUnit from 'referencial/components/managementUnit/dto/DtoManagementUnit'
import DtoUsageAgri from 'referencial/components/usage/dto/DtoUsageAgri'
import DtoSandreCode from 'referencial/dto/DtoSandreCode'
import i18n from 'simple-react-i18n'
import DtoDeclaration from 'survey/dto/DtoDeclaration'
import DtoSamplingPointDecla from 'survey/dto/DtoSamplingPointDecla'
import DtoSurvey from 'survey/dto/DtoSurvey'
import DtoUsageDeclaration from 'survey/dto/DtoUsageDeclaration'
import ToastrAction from 'toastr/actions/ToastrAction'
import { hasValue, truncateDecimals } from 'utils/NumberUtil'
import { getSettingInt } from 'utils/SettingUtils'
import { formatMilliers } from 'utils/StringUtil'

const styles = (theme) => ({
    paper: {
        backgroundColor: theme.palette.background,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        margin: '10px auto',
        bottom: 'inherit',
        height: '90vh',
        width: '90vw',
        overflowY: 'auto',
        zIndex: '1100',
    },
})

const RESUME_STEP = 0
const USAGE_STEP = 1
const CULTURE_VOLUME_STEP = 2
const IRRIG_PERIOD_STEP = 3
const GROUND_COMMENT_STEP = 4
const REQUEST_STEP = 5
const ADJUST_STEP = 6

class ModalUsage extends Component {
    constructor(props) {
        super(props)
        const volumesMonths = props?.usage?.volumesMonths?.split(';') || []
        this.state = {
            step: props.usage ? RESUME_STEP : USAGE_STEP,
            newUsage: !props.usage,
            errors: [],
            usage: props.usage ? {
                ...new DtoUsageDeclaration(props.usage),
                month01: volumesMonths[0],
                month02: volumesMonths[1],
                month03: volumesMonths[2],
                month04: volumesMonths[3],
                month05: volumesMonths[4],
                month06: volumesMonths[5],
                month07: volumesMonths[6],
                month08: volumesMonths[7],
                month09: volumesMonths[8],
                month10: volumesMonths[9],
                month11: volumesMonths[10],
                month12: volumesMonths[11],
            } : new DtoUsageDeclaration({
                idUsage: 7,
                comment: '',
                typeIrrigation: null,
            }),
            usagesDeclaration: props.usagesDeclaration || [],
            ajustEstim: false,
        }
    }

    previousStep = () => {
        const {
            usages,
            currentYear,
        } = this.props
        const { step, usage } = this.state
        const usagesCategory = usages.find(
            (usageCat) => usageCat.idUsage === usage.idUsage,
        )
        if (currentYear && !usagesCategory.isCulture && step === 3 && !this.showVolumeInput(usagesCategory)) {
            this.setState({ step: step - 2 })
        } else {
            this.setState({ step: step - 1 })
        }
    }

    showVolumeInput = (usageCat) => {
        const { countersId } = this.props
        if (usageCat.volumeRealEntry) {
            if (!usageCat.showVolumeIfCounter || (usageCat.showVolumeIfCounter && countersId.length)) {
                return true
            }
            return false
        }
        return false
    }

    nextStep = () => {
        const {
            usages,
            installation,
            currentYear,
            survey,
            usagesDeclaration,
            usage: usageInitial,
        } = this.props
        const { step, usage, newUsage } = this.state
        const usagesCategory = usages.find(
            (usageCat) => usageCat.idUsage === usage.idUsage,
        )
        if (this.checkStep(step, usage, usagesCategory)) {
            if ((!newUsage && usageInitial.idUsage === usage.idUsage)
                || (usagesCategory.multipleEntry || usagesDeclaration.filter(({ idUsage }) => idUsage === usagesCategory.idUsage).length < 1)) {
                if (currentYear && !usagesCategory.isCulture && step === 1 && !this.showVolumeInput(usagesCategory)) {
                    this.setState({ step: step + 2 })
                } else if (step === 5 && !currentYear && usagesCategory.calculAuto) {
                    this.props
                        .calculVolumeUsage({
                            usage: {
                                ...usage,
                                area: truncateDecimals(usage.area, 4),
                                idInstallation: installation.id,
                                requestedYear: survey.year,
                            },
                            uge: installation.subManagementCode || installation.managementCode,
                        })
                        .then((result) => {
                            const volume = isNaN(parseInt(result)) ? 0 : result
                            if (hasValue(usage.requestedYearVolume)) {
                                if (!usage.adjustedVolume && usage.requestedYearVolume === volume) {
                                    this.handleChange('requestedYearVolume', volume)
                                }
                                this.handleChange('adjustedVolume', usage.requestedYearVolume !== volume)
                            } else {
                                this.handleChange('requestedYearVolume', volume)
                                this.handleChange('adjustedVolume', false)
                            }
                            this.setState({ step: step + 1, volume, ajustEstim: false })
                        })
                } else {
                    this.setState({ step: step + 1 })
                }
                this.setState({ errors: [] })
            } else {
                this.props.warning('Catégorie limitée à une saisie')
            }
        } else {
            this.props.error(i18n.pleaseCompleteAllField)
        }
    }

    checkStep = (step, usage, usagesCategory) => {
        const { cultures } = this.props
        const culture = cultures.find((c) => c.id === usage.idCulture)
        const idFamille = culture ? culture.idFamille : usage.idFamille
        switch (step) {
            case USAGE_STEP:
                return true
            case CULTURE_VOLUME_STEP:
                if (usagesCategory?.isCulture || usagesCategory?.simplifiedCulture) {
                    if (!hasValue(idFamille) ||
                        !hasValue(usage.idCulture)) {
                        return false
                    }
                    return true
                }
                if (!hasValue(usage.requestedYearVolume) ||
                    usage.requestedYearVolume <= 0) {
                    return false
                }
                return true
            case IRRIG_PERIOD_STEP:
                if (usagesCategory?.simplifiedCulture) {
                    return true
                }
                if (usagesCategory?.isCulture) {
                    if (!hasValue(usage.irrigationType) ||
                        !hasValue(usage.irrigationMode)) {
                        return false
                    }
                    return true
                }
                if (!usage.link_periodes.length) {
                    return false
                } else if (usage.link_periodes.find((p) => p.idPeriode === 2 && !p.idNbPeriode)) {
                    return false
                }
                return true
            case GROUND_COMMENT_STEP:
                if ((!hasValue(usage.area) || usage.area < 0) ||
                    (!hasValue(usage.groundType)) ||
                    (!hasValue(usage.groundDepth)) ||
                    (!hasValue(usage.nbRotation))) {
                    return false
                }
                return true
            case REQUEST_STEP:
                if (!hasValue(usage.requestPrelevement)) {
                    return false
                }
                return true
            default:
                return true
        }
    }

    handleChange = (key, value) => {
        const { usages, point, declaration } = this.props
        const { usage } = this.state
        const usagesCategory = usages.find(
            (usageCat) => usageCat.idUsage === usage.idUsage,
        )
        const usageReset = usagesCategory.calculAuto ? {
            requestedYearVolume: null,
            lowWaterVolume: null,
            adjustedVolume: false,
        } : {}
        switch (key) {
            case 'requestedYearVolume': case 'adjustedVolume': case 'comment':
                this.setState({
                    usage: {
                        ...usage,
                        [key]: value,
                    },
                    ajustEstim: false,
                })
                break
            case 'idUsage':
                const link_periodes = value === 3 && !usage?.link_periodes?.length ? [{
                    idInstallation: point.idInstallation,
                    idSurvey: declaration.idSurvey,
                    idUsage: usage.idProvisionalUsage || -1,
                    idPeriode: 2,
                    idNbPeriode: 1,
                }, {
                    idInstallation: point.idInstallation,
                    idSurvey: declaration.idSurvey,
                    idUsage: usage.idProvisionalUsage || -1,
                    idPeriode: 2,
                    idNbPeriode: 2,
                }] : usage.link_periodes
                this.setState({
                    usage: {
                        ...new DtoUsageDeclaration({}),
                        idProvisionalUsage: usage.idProvisionalUsage,
                        ...usageReset,
                        [key]: value,
                        link_periodes,
                    },
                    ajustEstim: false,
                })
                break
            case 'idFamille':
                this.setState({
                    usage: {
                        ...usage,
                        [key]: value,
                        idCulture: null,
                        ...usageReset,
                        nbRotation: 1,
                    },
                    ajustEstim: false,
                })
                break
            case 'idCulture':
                this.setState({
                    usage: {
                        ...usage,
                        [key]: value,
                        ...usageReset,
                        nbRotation: 1,
                    },
                    ajustEstim: false,
                })
                break
            case 'irrigationType':
                this.setState({
                    usage: {
                        ...usage,
                        [key]: value,
                        irrigationMode: null,
                        ...usageReset,
                    },
                    ajustEstim: false,
                })
                break
            default:
                this.setState({
                    usage: {
                        ...usage,
                        [key]: value,
                        ...usageReset,
                    },
                    ajustEstim: false,
                })
                break
        }
    }

    handleChangePeriode = (select, code, usage) => {
        const { point, declaration } = this.props
        let newUsagePeriodes = []
        if (select) {
            newUsagePeriodes.push({
                idInstallation: point.idInstallation,
                idSurvey: declaration.idSurvey,
                idUsage: usage.idProvisionalUsage || -1,
                idPeriode: code,
                idNbPeriode: null,
            })
        } else {
            if (usage.link_periodes.find((p) => p.idNbPeriode === code)) {
                newUsagePeriodes = [
                    ...usage.link_periodes,
                ]
                newUsagePeriodes = newUsagePeriodes.filter((p) => p.idNbPeriode !== null && p.idNbPeriode !== code)
            } else {
                newUsagePeriodes = [
                    ...usage.link_periodes,
                    {
                        idInstallation: point.idInstallation,
                        idSurvey: declaration.idSurvey,
                        idUsage: usage.idProvisionalUsage || -1,
                        idPeriode: 2,
                        idNbPeriode: code,
                    },
                ]
                newUsagePeriodes = newUsagePeriodes.filter((p) => p.idNbPeriode !== null)
            }
            if (!newUsagePeriodes.length) {
                newUsagePeriodes.push({
                    idInstallation: point.idInstallation,
                    idSurvey: declaration.idSurvey,
                    idUsage: usage.idProvisionalUsage || -1,
                    idPeriode: 2,
                    idNbPeriode: null,
                })
            }
        }
        this.handleChange('link_periodes', newUsagePeriodes)
    }

    getHeader = (usagesCategory, usage) => {
        const { culturesFamilies, cultures, sandreCodes, modesIrrigations, currentYear } = this.props
        const { step } = this.state

        const typesIrrig = sandreCodes.filter((code) => code.field === 'IRRIGATION.CATEGORIES')
        const typesSols = sandreCodes.filter((code) => code.field === 'USAGES.TYPES_SOLS')
        const profondeursSol = sandreCodes.filter((code) => code.field === 'USAGES.PROFONDEUR_SOLS')
        const requestsPrelevement = sandreCodes.filter((code) => code.field === 'USAGES.TYPES')

        const culture = cultures.find((c) => c.id === usage.idCulture)
        const culturesFamily = culturesFamilies.find((cF) => cF.id === culture?.idFamille)
        const irrigationMode = modesIrrigations.find((iM) => iM.id === usage.irrigationMode)
        const irrigationType = typesIrrig.find((iT) => iT.code === usage.irrigationType)
        const groundType = typesSols.find((gT) => gT.code === usage.groundType)
        const groundDepth = profondeursSol.find((gD) => gD.code === usage.groundDepth)
        const prelevementRequest = requestsPrelevement.find((pR) => pR.code === usage.requestPrelevement)

        return (
            <span className='bold'>
                <span>{`${usagesCategory && usagesCategory.category === 1 ? i18n.agri : i18n.nonAgri} / ${usagesCategory.description}`}</span>
                <br />
                {culturesFamily && culture && (
                    <>
                        <span>
                            {culturesFamily.name} / {culture?.name}
                        </span>
                        <br />
                    </>
                )}
                {irrigationType && irrigationMode && (
                    <>
                        <span>
                            {irrigationType.name} / {irrigationMode.name}
                        </span>
                        <br />
                    </>
                )}
                {hasValue(usage.area) && groundType && groundDepth && hasValue(usage.nbRotation) && (
                    <>
                        <span>
                            {`${usage.area} ha / ${groundType.name} / ${groundDepth.name} / ${usage.nbRotation} RC`}
                        </span>
                        <br />
                    </>
                )}
                {step === 5 && prelevementRequest && (
                    <>
                        <span>{prelevementRequest.name}</span>
                        <br />
                    </>
                )}
                {!currentYear && step === 0 && usage.requestedYearVolume && (
                    <p>
                        {`${i18n.indicativeSimu} : ${formatMilliers(usage.requestedYearVolume) || 0} m3`}
                    </p>
                )}
            </span>
        )
    }

    getSelectedPeriod = (idPeriode, usagePeriodes) => {
        return !!usagePeriodes.find((p) => p.idNbPeriode === idPeriode)
    }

    getTotal = (usage) => {
        const getValue = (value) => {
            return hasValue(value) ? parseFloat(value) : 0
        }
        return getValue(usage.month01) + getValue(usage.month02) + getValue(usage.month03) + getValue(usage.month04) +
        getValue(usage.month05) + getValue(usage.month06) + getValue(usage.month07) + getValue(usage.month08) +
        getValue(usage.month09) + getValue(usage.month10) + getValue(usage.month11) + getValue(usage.month12)
    }

    onChangeLowWater = (usage, value, firstMonth, lastMonth) => {
        if (usage.month01 || usage.month02 || usage.month03 || usage.month04 || usage.month05 || usage.month06 || usage.month07 || usage.month08 || usage.month09 || usage.month10 || usage.month11 || usage.month12) {
            this.handleChange(
                'lowWaterVolume',
                parseInt(value),
            )
            return
        }
        const nbMonth = (lastMonth - firstMonth) + 1
        const valueByMonth = value / nbMonth
        const usageUpdated = {
            ...usage,
            lowWaterVolume: value,
            month01: (firstMonth <= 1 && lastMonth >= 1) ? valueByMonth : undefined,
            month02: (firstMonth <= 2 && lastMonth >= 2) ? valueByMonth : undefined,
            month03: (firstMonth <= 3 && lastMonth >= 3) ? valueByMonth : undefined,
            month04: (firstMonth <= 4 && lastMonth >= 4) ? valueByMonth : undefined,
            month05: (firstMonth <= 5 && lastMonth >= 5) ? valueByMonth : undefined,
            month06: (firstMonth <= 6 && lastMonth >= 6) ? valueByMonth : undefined,
            month07: (firstMonth <= 7 && lastMonth >= 7) ? valueByMonth : undefined,
            month08: (firstMonth <= 8 && lastMonth >= 8) ? valueByMonth : undefined,
            month09: (firstMonth <= 9 && lastMonth >= 9) ? valueByMonth : undefined,
            month10: (firstMonth <= 10 && lastMonth >= 10) ? valueByMonth : undefined,
            month11: (firstMonth <= 11 && lastMonth >= 11) ? valueByMonth : undefined,
            month12: (firstMonth <= 12 && lastMonth >= 12) ? valueByMonth : undefined,
        }
        this.setState({ usage: usageUpdated, adjustEstim: false })
    }

    getResumeStep = (usagesCategory, usage) => {
        const { currentYear } = this.props
        return (
            <>
                <p className='bold'>
                    {`${usagesCategory && usagesCategory.category === 1 ? i18n.agri : i18n.nonAgri} / ${usagesCategory.description}`}
                </p>
                {(!currentYear || this.showVolumeInput(usagesCategory)) &&
                    <p className='bold'>
                        {`${i18n.askedLabel} : ${formatMilliers(usage.requestedYearVolume) || 0} m3 (${i18n.lowWater} : ${hasValue(usage.lowWaterVolume) ? usage.lowWaterVolume : i18n.unknown})`}
                    </p>
                }
            </>
        )
    }

    getUsagesListStep = (usage) => {
        const { usages } = this.props
        const agriUsagesCat = sortBy(usages.filter((uCat) => uCat.category === 1), 'order')
        const nonAgriUsagesCat = sortBy(usages.filter((uCat) => uCat.category === 2), 'order')
        return (
            <RadioGroup
                name='idUsage'
                value={usage.idUsage}
                onChange={(event) =>
                    this.handleChange('idUsage', parseInt(event.target.value))
                }
            >
                <div>
                    {i18n.agri}
                </div>
                {agriUsagesCat.map((uCat) => (
                    <FormControlLabel
                        value={uCat.idUsage}
                        control={<Radio />}
                        label={uCat.description}
                    />
                ))}
                <div>
                    {i18n.nonAgri}
                </div>
                {nonAgriUsagesCat.map((uCat) => (
                    <FormControlLabel
                        value={uCat.idUsage}
                        control={<Radio />}
                        label={uCat.description}
                    />
                ))}
            </RadioGroup>
        )
    }

    getCultureStep = (usagesCategory, usage) => {
        const { culturesFamilies, cultures } = this.props
        const culture = cultures.find((c) => c.id === usage.idCulture)
        const idFamille = culture ? culture.idFamille : usage.idFamille
        return (
            <>
                {this.getHeader(usagesCategory, usage)}
                <div style={{ margin: '30px 0' }}>
                    <Select
                        label={i18n.culturesFamily}
                        value={idFamille}
                        onChange={(value) =>
                            this.handleChange(
                                'idFamille',
                                parseInt(value),
                            )
                        }
                        options={culturesFamilies}
                        keyValue='id'
                        noNullValue
                        obligatory
                    />
                    <Select
                        label={i18n.culture}
                        value={usage.idCulture}
                        onChange={(value) =>
                            this.handleChange(
                                'idCulture',
                                parseInt(value),
                            )
                        }
                        options={cultures.filter((c) => c.idFamille === idFamille && (c.status !== 2 || (usage.idCulture && usage.idCulture === c.id)))}
                        keyValue='id'
                        noNullValue
                        obligatory
                    />
                </div>
            </>
        )
    }

    getIrrigationCultureStep = (usagesCategory, usage, required) => {
        const { modesIrrigations, sandreCodes } = this.props
        const typesIrrig = sandreCodes.filter((code) => code.field === 'IRRIGATION.CATEGORIES')
        return (
            <>
                {this.getHeader(usagesCategory, usage)}
                <div style={{ margin: '30px 0' }}>
                    <Select
                        label={i18n.mainIrrigationType}
                        value={usage.irrigationType}
                        onChange={(value) =>
                            this.handleChange(
                                'irrigationType',
                                parseInt(value),
                            )
                        }
                        options={typesIrrig}
                        keyValue='code'
                        noNullValue
                        obligatory={required}
                    />
                    <Select
                        label={i18n.modeIrrigation}
                        value={usage.irrigationMode}
                        onChange={(value) =>
                            this.handleChange(
                                'irrigationMode',
                                parseInt(value),
                            )
                        }
                        options={modesIrrigations.filter((mode) => mode.categoryId === usage.irrigationType)}
                        keyValue='id'
                        noNullValue
                        obligatory={required}
                    />
                </div>
            </>
        )
    }

    getContent = (step, usage) => {
        const {
            cultures,
            sandreCodes,
            usages,
            currentYear,
            applicationSettings,
            managementUnits,
            installation,
        } = this.props
        const { errors, ajustEstim, volume } = this.state
        const usagesCategory = usages.find((usageCat) => usageCat.idUsage === usage.idUsage)
        const culture = cultures.find((c) => c.id === usage.idCulture)

        if (usagesCategory?.simplifiedCulture) {
            switch (step) {
                case RESUME_STEP:
                    return this.getResumeStep(usagesCategory, usage)
                default: case USAGE_STEP:
                    return this.getUsagesListStep(usage)
                case CULTURE_VOLUME_STEP:
                    return this.getCultureStep(usagesCategory, usage)
                case IRRIG_PERIOD_STEP:
                    return this.getIrrigationCultureStep(usagesCategory, usage, false)
                case GROUND_COMMENT_STEP:
                    return (
                        <>
                            {this.getHeader(usagesCategory, usage)}
                            <Row className='padding-top-1'>
                                <NumberField
                                    col={6}
                                    id='area'
                                    title={i18n.surface}
                                    min={0}
                                    value={usage.area}
                                    onChange={(value) => this.handleChange('area', truncateDecimals(value, 4))}
                                    floatValue
                                    obligatory
                                />
                                <NumberField
                                    id='requestedYearVolume'
                                    col={6}
                                    title={i18n.volume}
                                    value={usage.requestedYearVolume}
                                    min={0}
                                    onChange={(value) =>
                                        this.handleChange(
                                            'requestedYearVolume',
                                            parseInt(value),
                                        )
                                    }
                                    obligatory
                                />
                            </Row>
                        </>
                    )
            }
        }

        if (usagesCategory?.isCulture) {
            switch (step) {
                case RESUME_STEP:
                    return this.getResumeStep(usagesCategory, usage)
                default: case USAGE_STEP:
                    return this.getUsagesListStep(usage)
                case CULTURE_VOLUME_STEP:
                    return this.getCultureStep(usagesCategory, usage)
                case IRRIG_PERIOD_STEP:
                    return this.getIrrigationCultureStep(usagesCategory, usage, true)
                case GROUND_COMMENT_STEP:
                    const nbRotationsCultMax = getSettingInt(applicationSettings, 'nbRotationsCultMax') || 6
                    const optionRotationsCult = []
                    for (let i = 1; i <= nbRotationsCultMax; i++) {
                      optionRotationsCult.push({ value: String(i) })
                    }
                    const typesSols = sandreCodes.filter((code) => code.field === 'USAGES.TYPES_SOLS')
                    const profondeursSol = sandreCodes.filter((code) => code.field === 'USAGES.PROFONDEUR_SOLS')
                    return (
                        <>
                            {this.getHeader(usagesCategory, usage)}
                            <div style={{ margin: '30px 0' }}>
                                <NumberField
                                    id='area'
                                    title={i18n.surface}
                                    min={0}
                                    value={usage.area}
                                    onChange={(value) => this.handleChange('area', truncateDecimals(value, 4))}
                                    floatValue
                                    obligatory
                                />
                                <Select
                                    id='groundType'
                                    label={i18n.groundType}
                                    value={usage.groundType}
                                    onChange={(value) => this.handleChange('groundType', value)}
                                    options={typesSols}
                                    keyValue='code'
                                    noNullValue
                                    obligatory
                                />
                                <Select
                                    id='groundDepth'
                                    label={i18n.groundDepth}
                                    value={usage.groundDepth}
                                    onChange={(value) => this.handleChange('groundDepth', value)}
                                    options={profondeursSol}
                                    keyValue='code'
                                    noNullValue
                                    obligatory
                                />
                                <Select
                                    id='nbRotation'
                                    label={i18n.nbRotationsCult}
                                    value={usage.nbRotation}
                                    onChange={(value) => this.handleChange('nbRotation', parseInt(value))}
                                    options={optionRotationsCult}
                                    keyLabel='value'
                                    noSort
                                    disabled={culture.limitCropRotations}
                                    noNullValue
                                    obligatory
                                />
                            </div>
                        </>
                    )
                case REQUEST_STEP:
                    const requestsPrelevement = sandreCodes.filter((code) => code.field === 'USAGES.TYPES')
                    return (
                        <>
                            {this.getHeader(usagesCategory, usage)}
                            <RadioGroup
                                name='requestPrelevement'
                                value={usage.requestPrelevement}
                                onChange={(event) =>
                                    this.handleChange(
                                        'requestPrelevement',
                                        parseInt(event.target.value),
                                    )
                                }
                                style={{ margin: '30px 0' }}
                                error={errors.find((e) => e === 'requestPrelevement')}
                            >
                                <div>
                                    {i18n.requestPrelevement}
                                </div>
                                {orderBy(requestsPrelevement, 'code').map((type) => {
                                    return (
                                        <FormControlLabel
                                            value={type.code}
                                            control={<Radio />}
                                            label={type.name}
                                        />
                                    )
                                })}
                            </RadioGroup>
                        </>
                    )
                case ADJUST_STEP:
                    return (
                        <>
                            <div style={{ height: 'fit-content' }}>
                                {this.getHeader(usagesCategory, usage)}
                                {usagesCategory.calculAuto ? (
                                    <>
                                        <p>
                                            {!currentYear ? (
                                                <>
                                                    <span className='bold'>{`${i18n.indicativeSimu} : ${formatMilliers((ajustEstim || usage.adjustedVolume) ? volume : usage.requestedYearVolume) || 0} m3`}</span><br />
                                                    {!(ajustEstim || usage.adjustedVolume) ? (
                                                        <Button
                                                            variant='contained'
                                                            color='primary'
                                                            onClick={() => this.setState({ ajustEstim: true })}
                                                            margintop
                                                        >
                                                            {i18n.adjustEstim}
                                                        </Button>
                                                    ) : (
                                                        <>
                                                            <Grid container spacing={1}>
                                                                <Grid item>
                                                                    <Button
                                                                        variant='contained'
                                                                        color='primary'
                                                                        onClick={() => {
                                                                            this.setState({
                                                                                usage:
                                                                                {
                                                                                    ...usage,
                                                                                    requestedYearVolume: ((usage.requestedYearVolume || 0) - Math.trunc(volume / 10)),
                                                                                    adjustedVolume: ((usage.requestedYearVolume || 0) - Math.trunc(volume / 10)) !== (usage.requestedYearVolume || 0),
                                                                                },
                                                                            })
                                                                        }}
                                                                        disabled={((usage.requestedYearVolume || 0) - Math.trunc(volume / 10)) < 0}
                                                                        margintop
                                                                    >
                                                                        {i18n.remove} 10 %
                                                                    </Button>
                                                                </Grid>
                                                                <Grid item>
                                                                    <Button
                                                                        variant='contained'
                                                                        color='primary'
                                                                        onClick={() => {
                                                                            this.setState({
                                                                                usage:
                                                                                {
                                                                                    ...usage,
                                                                                    requestedYearVolume: ((usage.requestedYearVolume || 0) + Math.trunc(volume / 10)),
                                                                                    adjustedVolume: ((usage.requestedYearVolume || 0) + Math.trunc(volume / 10)) !== (usage.requestedYearVolume || 0),
                                                                                },
                                                                            })
                                                                        }}
                                                                        margintop
                                                                    >
                                                                        {i18n.add} 10 %
                                                                    </Button>
                                                                </Grid>
                                                            </Grid>
                                                            <p className='bold' style={{ color: 'red' }}>
                                                                {`${i18n.adjustedSimu} : ${formatMilliers(usage.requestedYearVolume) || 0} m3`}
                                                            </p>
                                                        </>
                                                    )}
                                                </>
                                            ) : ''}
                                        </p>
                                    </>
                                ) : (
                                    <Row className='padding-top-1'>
                                        <NumberField
                                            id='requestedYearVolume'
                                            col={6}
                                            title={i18n.volume}
                                            value={usage.requestedYearVolume}
                                            min={0}
                                            onChange={(value) =>
                                                this.handleChange(
                                                    'requestedYearVolume',
                                                    parseInt(value),
                                                    )
                                                }
                                                obligatory
                                        />
                                        <NumberField
                                            id='lowWaterVolume'
                                            col={6}
                                            title={i18n.volumeLowWater}
                                            value={usage.lowWaterVolume}
                                            min={0}
                                            onChange={(value) =>
                                                this.handleChange(
                                                    'lowWaterVolume',
                                                    parseInt(value),
                                                    )
                                                }
                                        />
                                    </Row>
                                )}
                            </div>
                            <div className='padding-top-1'>
                                <Textarea
                                    id='comment'
                                    title={i18n.comment}
                                    value={usage.comment}
                                    onChange={(value) => this.handleChange('comment', value)}
                                    rows={10}
                                />
                            </div>
                        </>
                    )
            }
        }

        switch (step) {
            case RESUME_STEP:
                return this.getResumeStep(usagesCategory, usage)
            default: case USAGE_STEP:
                return this.getUsagesListStep(usage)
            case CULTURE_VOLUME_STEP:
                const uge = managementUnits.find((m) => m.managementCode === (installation.subManagementCode || installation.managementCode)) || {}
                const firstMonth = uge.lowWaterStartDate ? moment(uge.lowWaterStartDate).month() + 1 : 1
                const lastMonth = uge.lowWaterEndDate ? moment(uge.lowWaterEndDate).month() + 1 : 12
                const total = this.getTotal(usage) || 0
                return (
                    <>
                        <Row className='padding-top-1'>
                            <p className='bold'>
                                {`${usagesCategory && usagesCategory.category === 1 ? i18n.agri : i18n.nonAgri} / ${usagesCategory.description}`}
                            </p>
                            <NumberField
                                id='requestedYearVolume'
                                col={6}
                                title={i18n.volume}
                                value={usage.requestedYearVolume}
                                min={0}
                                onChange={(value) =>
                                    this.handleChange(
                                        'requestedYearVolume',
                                        parseInt(value),
                                    )
                                }
                                obligatory
                            />
                            <NumberField
                                id='lowWaterVolume'
                                col={6}
                                className={(usage.lowWaterVolume || 0) !== total ? 'red' : ''}
                                title={i18n.volumeLowWater}
                                value={usage.lowWaterVolume}
                                min={0}
                                onChange={(value) =>
                                    this.onChangeLowWater(usage, parseInt(value), firstMonth, lastMonth)
                                }
                            />
                        </Row>
                        {!currentYear && (
                            <Row>
                                <StyledFieldSet className='margin-1'>
                                    <StyledLegend>{i18n.volumeByMonth}</StyledLegend>
                                    {firstMonth <= 1 && lastMonth >= 1 && (
                                        <NumberField
                                            col={1}
                                            title={i18n.januaryShort}
                                            value={usage.month01}
                                            floatValue
                                            onChange={value => this.handleChange('month01', parseFloat(value))}
                                            min={0}
                                        />
                                    )}
                                    {firstMonth <= 2 && lastMonth >= 2 && (
                                        <NumberField
                                            col={1}
                                            title={i18n.februaryShort}
                                            value={usage.month02}
                                            floatValue
                                            onChange={value => this.handleChange('month02', parseFloat(value))}
                                            min={0}
                                        />
                                    )}
                                    {firstMonth <= 3 && lastMonth >= 3 && (
                                        <NumberField
                                            col={1}
                                            title={i18n.marchShort}
                                            value={usage.month03}
                                            floatValue
                                            onChange={value => this.handleChange('month03', parseFloat(value))}
                                            min={0}
                                        />
                                    )}
                                    {firstMonth <= 4 && lastMonth >= 4 && (
                                        <NumberField
                                            col={1}
                                            title={i18n.aprilShort}
                                            value={usage.month04}
                                            floatValue
                                            onChange={value => this.handleChange('month04', parseFloat(value))}
                                            min={0}
                                        />
                                    )}
                                    {firstMonth <= 5 && lastMonth >= 5 && (
                                        <NumberField
                                            col={1}
                                            title={i18n.mayShort}
                                            value={usage.month05}
                                            floatValue
                                            onChange={value => this.handleChange('month05', parseFloat(value))}
                                            min={0}
                                        />
                                    )}
                                    {firstMonth <= 6 && lastMonth >= 6 && (
                                        <NumberField
                                            col={1}
                                            title={i18n.juneShort}
                                            value={usage.month06}
                                            floatValue
                                            onChange={value => this.handleChange('month06', parseFloat(value))}
                                            min={0}
                                        />
                                    )}
                                    {firstMonth <= 7 && lastMonth >= 7 && (
                                        <NumberField
                                            col={1}
                                            title={i18n.julyShort}
                                            value={usage.month07}
                                            floatValue
                                            onChange={value => this.handleChange('month07', parseFloat(value))}
                                            min={0}
                                        />
                                    )}
                                    {firstMonth <= 8 && lastMonth >= 8 && (
                                        <NumberField
                                            col={1}
                                            title={i18n.augustShort}
                                            value={usage.month08}
                                            floatValue
                                            onChange={value => this.handleChange('month08', parseFloat(value))}
                                            min={0}
                                        />
                                    )}
                                    {firstMonth <= 9 && lastMonth >= 9 && (
                                        <NumberField
                                            col={1}
                                            title={i18n.septemberShort}
                                            value={usage.month09}
                                            floatValue
                                            onChange={value => this.handleChange('month09', parseFloat(value))}
                                            min={0}
                                        />
                                    )}
                                    {firstMonth <= 10 && lastMonth >= 10 && (
                                        <NumberField
                                            col={1}
                                            title={i18n.octoberShort}
                                            value={usage.month10}
                                            floatValue
                                            onChange={value => this.handleChange('month10', parseFloat(value))}
                                            min={0}
                                        />
                                    )}
                                    {firstMonth <= 11 && lastMonth >= 11 && (
                                        <NumberField
                                            col={1}
                                            title={i18n.novemberShort}
                                            value={usage.month11}
                                            floatValue
                                            onChange={value => this.handleChange('month11', parseFloat(value))}
                                            min={0}
                                        />
                                    )}
                                    {firstMonth <= 12 && lastMonth >= 12 && (
                                        <NumberField
                                            col={1}
                                            title={i18n.decemberShort}
                                            value={usage.month12}
                                            floatValue
                                            onChange={value => this.handleChange('month12', parseFloat(value))}
                                            min={0}
                                        />
                                    )}
                                    <Input
                                        col={1}
                                        title={i18n.total}
                                        className={(usage.lowWaterVolume || 0) !== total ? 'red' : ''}
                                        containerClassName='right bold'
                                        value={total}
                                        disabled
                                    />
                                </StyledFieldSet>
                            </Row>
                        )}
                    </>
                )
            case IRRIG_PERIOD_STEP:
                const usagesPeriodes = sandreCodes.filter((code) => code.field === 'USAGES.PERIODES')
                const usagesPeriodesDetails = sandreCodes.filter((code) => code.field === 'USAGES.PERIODES_DETAILS')
                const usageSaisonnal = usage.link_periodes.find((p) => p.idPeriode === 2)
                const usageAnnuel = usage.link_periodes.find((p) => p.idPeriode === 1)
                return (
                    <>
                        <p className='bold'>
                            {`${usagesCategory && usagesCategory.category === 1 ? i18n.agri : i18n.nonAgri} / ${usagesCategory.description}`}
                        </p>
                        {!currentYear &&
                            <p className='bold'>
                                {`${i18n.askedLabel} : ${formatMilliers(usage.requestedYearVolume) || 0} m3`}
                            </p>
                        }
                        <div className='padding-top-1'>
                            <Select
                                label={i18n.usePeriod}
                                value={usageSaisonnal ? 2 : usageAnnuel ? 1 : null}
                                onChange={(value) => this.handleChangePeriode(true, value, usage)}
                                options={usagesPeriodes}
                                keyValue='code'
                                noNullValue
                                obligatory
                            />
                        </div>
                        <FormGroup>
                            {usageSaisonnal && usagesPeriodesDetails.map((p) => (
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            value={p.code}
                                            checked={this.getSelectedPeriod(p.code, usage.link_periodes)}
                                            onChange={(e) => this.handleChangePeriode(false, parseInt(e.target.value), usage)}
                                        />
                                    }
                                    label={p.name}
                                />
                            ))}
                            {errors.find((e) => e === 'link_periodes_checkboxes') && (
                                <FormHelperText style={{ color: 'red' }}>{i18n.noneOptionSelected}</FormHelperText>
                            )}
                        </FormGroup>
                    </>
                )
            case GROUND_COMMENT_STEP:
                return (
                    <>
                        <div style={{ height: 'fit-content' }}>
                            <p className='bold'>
                                {`${usagesCategory && usagesCategory.category === 1 ? i18n.agri : i18n.nonAgri} / ${usagesCategory.description}`}
                            </p>
                            {!currentYear &&
                                <p className='bold'>
                                    {`${i18n.askedLabel} : ${formatMilliers(usage.requestedYearVolume) || 0} m3`}
                                </p>
                            }
                        </div>
                        <Textarea
                            id='comment'
                            title={i18n.comment}
                            value={usage.comment}
                            onChange={(value) => this.handleChange('comment', value)}
                            rows={10}
                        />
                    </>
                )
        }
    }

    onDelete = () => {
        const { usagesDeclaration, usage } = this.state
        const newUsages = usagesDeclaration.filter((u) => u.idProvisionalUsage !== usage.idProvisionalUsage)
        this.props.onDelete(newUsages)
    }

    onSave = () => {
        const { usagesDeclaration, usage } = this.state
        const { installation, exploitation, declaration, currentYear, survey } = this.props
        const formattedUsage = {
            ...usage,
            area: usage.idCulture ? truncateDecimals(usage.area, 4) : null,
            nbRotation: usage.idCulture ? usage.nbRotation : null,
            requestPrelevement: usage.idCulture ? usage.requestPrelevement : null,
            currentYear: usage.currentYear || currentYear,
            idExploitation: exploitation.idExploitation,
            idInstallation: installation.id,
            idSurvey: declaration.idSurvey,
            idProvisionalUsage: usage.idProvisionalUsage || null,
            requestedYear: survey.year,
            volumesMonths: `${usage.month01 || ''};${usage.month02 || ''};${usage.month03 || ''};${usage.month04 || ''};${usage.month05 || ''};${usage.month06 || ''};${usage.month07 || ''};${usage.month08 || ''};${usage.month09 || ''};${usage.month10 || ''};${usage.month11 || ''};${usage.month12 || ''}`,
        }
        if (usage.idProvisionalUsage) {
            const newUsages = usagesDeclaration.filter(
                (u) => u.idProvisionalUsage !== usage.idProvisionalUsage,
            )
            this.props.onSave([
                ...newUsages,
                formattedUsage,
            ])
        } else {
            this.props.onSave([
                ...usagesDeclaration,
                formattedUsage,
            ])
        }
    }

    onClose = () => {
        this.props.onCancel()
    }

    render() {
        const { step, usage } = this.state
        const { open, usages } = this.props
        const usageCat = usages.find((uCat) => uCat.idUsage === usage.idUsage)

        return (
            <DialogMUI open={open}>
                <DialogTitleMUI>{i18n.usage}</DialogTitleMUI>
                <DialogContent>
                    <Grid
                        container
                        className='overflowY'
                        direction='row'
                        alignItems='flex-start'
                        style={{
                            height: '-webkit-fill-available',
                            padding: '0',
                            display: 'block',
                        }}
                    >
                        <Grid item xs={12} style={{ padding: '10px 16px' }}>
                            {this.getContent(step, usage)}
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActionsMUI style={{ justifyContent: 'space-between' }}>
                {!step ? (
                    <>
                        <Button
                            variant='outlined'
                            color='primary'
                            onClick={this.onClose}
                        >
                            {i18n.cancel}
                        </Button>
                        <Button
                            variant='contained'
                            className='red'
                            style={{ color: 'white' }}
                            onClick={this.onDelete}
                        >
                            {i18n.deleteUsage}
                        </Button>
                        <Button
                            variant='contained'
                            color='primary'
                            onClick={this.nextStep}
                        >
                            {i18n.changeUsage}
                        </Button>
                    </>
                ) : (
                    <>
                        <Button
                            variant='outlined'
                            color='primary'
                            onClick={this.onClose}
                            marginTop
                        >
                            {i18n.cancel}
                        </Button>
                        <Button
                            variant='contained'
                            color='primary'
                            onClick={this.previousStep}
                            disabled={step === 1}
                        >
                            {i18n.previous}
                        </Button>
                        <Button
                            variant='contained'
                            color='primary'
                            onClick={this.nextStep}
                            disabled={!((usageCat.isCulture && step !== 6) || (!usageCat.isCulture && step !== 4))}
                        >
                            {i18n.next}
                        </Button>
                        <Button
                            variant='contained'
                            color='primary'
                            onClick={this.onSave}
                            disabled={((usageCat.isCulture && step !== 6) || (!usageCat.isCulture && step !== 4))}
                        >
                            {i18n.validate}
                        </Button>
                    </>
                )}
                </DialogActionsMUI>
            </DialogMUI>
        )
    }
}

ModalUsage.propTypes = {
    classes: PropTypes.instanceOf(PropTypes.object),
    usage: PropTypes.instanceOf(PropTypes.object),
    point: PropTypes.instanceOf(DtoSamplingPointDecla),
    usagesDeclaration: PropTypes.arrayOf(PropTypes.instanceOf(DtoUsageDeclaration)),
    onSave: PropTypes.func,
    onDelete: PropTypes.func,
    onCancel: PropTypes.func,
    warning: PropTypes.func,
    error: PropTypes.func,
    calculVolumeUsage: PropTypes.func,
    open: PropTypes.bool,
    installation: PropTypes.instanceOf(DtoInstallation),
    installationsWithGeo: PropTypes.arrayOf(PropTypes.instanceOf(DtoInstallationWithGeoItem)),
    sandreCodes: PropTypes.arrayOf(PropTypes.instanceOf(DtoSandreCode)),
    cultures: PropTypes.arrayOf(PropTypes.instanceOf(DtoCulture)),
    culturesFamilies: PropTypes.arrayOf(PropTypes.instanceOf(DtoCulturesFamily)),
    modesIrrigations: PropTypes.arrayOf(PropTypes.instanceOf({})),
    usages: PropTypes.arrayOf(PropTypes.instanceOf(DtoUsageAgri)),
    exploitation: PropTypes.instanceOf(DtoExploitation),
    declaration: PropTypes.instanceOf(DtoDeclaration),
    currentYear: PropTypes.bool,
    survey: PropTypes.instanceOf(DtoSurvey),
    countersId: PropTypes.arrayOf(PropTypes.number),
    applicationSettings: PropTypes.arrayOf(PropTypes.instanceOf(SieauParameterDto)),
    managementUnits: PropTypes.arrayOf(PropTypes.instanceOf(DtoManagementUnit)),
}

const mapStateToProps = (store) => {
    return {
        exploitation: store.AgriReducer.exploitation,
        declaration: store.AgriReducer.declaration,
        sandreCodes: store.ReferencialReducer.sandreCodes,
        cultures: store.CultureReducer.cultures,
        culturesFamilies: store.CulturesFamilyReducer.culturesFamilies,
        usages: store.UsageReducer.usages,
        modesIrrigations: store.AgriReducer.modesIrrigations,
        installation: store.InstallationReducer.installation,
        installationsWithGeo: store.InstallationReducer.installationsWithGeo,
        survey: store.AgriReducer.survey,
        applicationSettings: store.AdministrationReducer.applicationSettings,
        managementUnits: store.ManagementUnitReducer.managementUnits,
    }
}

const mapDispatchToProps = {
    calculVolumeUsage: AgriAction.calculVolumeUsage,
    warning: ToastrAction.warning,
    error: ToastrAction.error,
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ModalUsage))
