export default class DtoDangerWithType {
    constructor(obj) {
        this.name = obj.name // Option[String],
        this.intValue = obj.intValue // Option[Int],
        this.code = obj.code // Option[String],
        this.siret = obj.siret // Option[String],
        this.siteType = obj.siteType // Option[Long],
        this.siteCode = obj.siteCode // Option[Long],
        this.comment = obj.comment // Option[String],
        this.definitionDate = obj.definitionDate // Option[DateTime],
        this.resolutionDate = obj.resolutionDate // Option[DateTime],
        this.dangerousSituation = obj.dangerousSituation // Option[Long]
        this.idDanger = obj.idDanger // Option[Long]
        this.idCampaign = obj.idCampaign // Option[Long]
    }
}
