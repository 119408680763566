export default class PrivateOwnerDto {
    constructor(obj) {
        this.id = obj.id // : Int,
        this.contactCode = obj.contactCode // : Option[Int],
        this.name = obj.name // : Option[String],
        this.firstname = obj.firstname // : Option[String],
        this.comment = obj.comment // : Option[String],
        this.dateOfBirth = obj.dateOfBirth // : Option[DateTime],
        this.placeOfBirth = obj.placeOfBirth // : Option[String],
        this.address = obj.address // : Option[String],
        this.additionalAddress = obj.additionalAddress // : Option[String],
        this.postalCode = obj.postalCode // : Option[String],
        this.cityCode = obj.cityCode // : Option[String],
        this.usualCityName = obj.usualCityName // : Option[String],
        this.propertyType = obj.propertyType // : Option[Int]
    }
}