import ActionComponent from 'components/ActionComponent'
import { groupBy } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import EChart from '../../../components/echart/EChart'
import Pie from '../../../components/echart/series/Pie'
import { EVENT_TYPES } from '../../../events/constants/EventsConstants'
import { getColorCircleElement, getEventColor, getRGBColor } from '../../../utils/ColorUtil'
import { getLabel, getMapStateToProps, getPropType, getPropTypes } from '../../../utils/StoreUtils'
import { i18nize } from '../../../utils/StringUtil'

const storeProps = {
}

class EventPieChart extends ActionComponent {
    render() {
        const { events, eventsTypes, title } = this.props
        const eventByTypes = groupBy(events, e => ((eventsTypes && getLabel(eventsTypes, e.eventType)) || getLabel(i18nize(EVENT_TYPES), e.eventType)) || i18n.unknownType)
        const data = Object.keys(eventByTypes).map(type => {
            const color = (eventsTypes && (eventsTypes.find((eT) => eT.code === eventByTypes[type][0].eventType) || {}).color) || getEventColor(eventByTypes[type][0].eventType)
            return {
                name: type,
                value: eventByTypes[type].length,
                itemStyle: {
                    normal: {
                        color,
                    },
                    emphasis: {
                        color,
                    },
                },
                tooltip: {
                    formatter: o => {
                        return `${getColorCircleElement(getRGBColor(color))}${type} : ${o.value} (${o.percent}%)`
                    },
                },
                label: {
                    normal: {
                        show: false,
                        position: 'center',
                    },
                    emphasis: {
                        show: !title,
                        textStyle: {
                            fontSize: '20',
                            fontWeight: 'bold',
                        },
                        formatter: o => `${o.percent}%`,
                    },
                },
            }
        })
        const options = {
            series: [Pie({
                data,
                name: 'statistics',
                radius: ['70%', '95%'],
            })],
            tooltip: {
                trigger: 'item',
            },
            height: 150,
            customTitle: {
                text: title,
                left: 'center',
                top: 'middle',
            },

        }
        return (
            <div className='row no-margin'>
                <EChart options={ options } />
            </div>
        )
    }
}

EventPieChart.propTypes = getPropTypes(storeProps, {
    events: getPropType('stationEvents'),
    title: PropTypes.string,
    withoutLegend: PropTypes.bool,
    eventsTypes: PropTypes.arrayOf(PropTypes.shape({
        code: PropTypes.string,
        id: PropTypes.number,
        label: PropTypes.string,
        color: PropTypes.string,
        icon: PropTypes.string,
    })),
})

const mapStateToProps = () => getMapStateToProps(storeProps, {
})

export default connect(mapStateToProps)(EventPieChart)
