'use strict'
import { RECEIVE_METHOD, RECEIVE_METHODS, RESET_METHOD } from '../constants/MethodConstants'
import MethodItem from '../dto/MethodItem'
import MethodDto from '../dto/MethodDto'

export function MethodReducer(state = {}, action) {
    switch (action.type) {
        case RECEIVE_METHODS:
            return Object.assign({}, state, {
                methods: action.methods.map(method => new MethodItem(method)),
            })
        case RECEIVE_METHOD:
            return Object.assign({}, state, {
                method: new MethodDto(action.method),
            })
        case RESET_METHOD:
            return Object.assign({}, state, {
                method: {},
            })
        default:
            return state
    }
}

export const store = {
    methods: [],
    method: {},
}
