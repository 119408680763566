import ProgressBar from 'components/progress/ProgressBar'
import { push } from '@lagunovsky/redux-react-router'
import PropTypes from 'prop-types'
import React, { useMemo } from 'react'
import { connect, shallowEqual, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import { getI18nTitleDataLength } from 'utils/StringUtil'
import DtoCampaignProgress from '../../../campaign/dto/DtoCampaignProgress'
import { getCampaignProgress } from '../../../campaign/utils/CampaignUtils'
import Card from '../../../components/card/Card'
import { getDate } from '../../../utils/DateUtil'
import DtoSurvey from '../../dto/DtoSurvey'
import { Grid, Icon } from '@mui/material'
import { sieauTooltip } from 'utils/FormUtils'
import { SURVEY_TYPES } from 'agriAdministration/constants/AgriConstants'

const SurveyCard = ({
    survey = {},
    progress = {},
    redirect = () => { },
}) => {
    const {
        contributors,
    } = useSelector(store => ({
        contributors: store.ContributorReducer.contributors,
    }), shallowEqual)

    const {
        idSurvey,
        name,
        statusCode,
        startDate,
        endDate,
        updateDate,
        updateLogin,
        year,
        contributorId,
        surveyType,
    } = survey
    const {
        nbDeclarations = 0,
        nbDeclarationsValidated = 0,
    } = progress
    const progressValue = getCampaignProgress(nbDeclarationsValidated, nbDeclarations)

    const getStatusIcon = () => {
        switch (statusCode) {
            case 1:
                const tooltip = `${nbDeclarationsValidated} ${getI18nTitleDataLength(i18n.declaration, i18n.declarations, nbDeclarationsValidated)} ${i18n.of} ${nbDeclarations} (${Math.round(progressValue)}%)`
                return (
                    <div style={{ width: '100px' }}>
                        <ProgressBar progress={progressValue} tooltip={tooltip} />
                    </div>
                )
            case 3:
                return <i className='material-icons medium'>folder</i>
            default:
                return <i className='material-icons medium'>create</i>
        }
    }

    const contributor = useMemo(() => contributors.find((c) => c.id === contributorId), [contributors, contributorId])

    return (
        <Card>
            <Grid container alignItems='center' className='clickable padding-left-2' onClick={() => redirect(`/survey/${idSurvey}`)}>
                <Grid item xs={4}>
                    <p className='collection-title' style={ { fontWeight: 'bold' } }>{ `${year} - ${name || ''}` }</p>
                    <div className='collection-content'>{updateDate && updateLogin ? `${i18n.updatedAt} ${getDate(updateDate)} ${i18n.by} ${updateLogin}` : ''}</div>
                </Grid>
                <Grid item xs={2}>
                    <span style={{ padding: '0 15px', borderRadius: '10px', border: '1px solid' }}>
                        {surveyType === SURVEY_TYPES.ANNUAL ? i18n.annual : i18n.intermediate}
                    </span>
                </Grid>
                <Grid item xs={2}>
                    <div className='collection-content no-margin'>{ `${i18n.periode}: ${getDate(startDate)} - ${getDate(endDate)}`}</div>
                    <div className='collection-content no-margin'>{ `${i18n.finalizedDeclarations}: ${nbDeclarationsValidated} / ${nbDeclarations}` }</div>
                </Grid>
                <Grid item xs={3} container justifyContent='center'>
                    {getStatusIcon()}
                </Grid>
                <Grid item xs={1}>
                    {contributorId ? <Icon {...sieauTooltip(contributor?.name, null, 'left')} style={{ fontSize: 52 }}>account_circle</Icon> : <div style={{ height: 52 }} />}
                </Grid>
            </Grid>
        </Card>
    )
}

SurveyCard.propTypes = {
    survey: PropTypes.instanceOf(DtoSurvey),
    progress: PropTypes.instanceOf(DtoCampaignProgress),
    redirect: PropTypes.func,
}

const mapDispatchToProps = {
    redirect: push,
}

export default connect(null, mapDispatchToProps)(SurveyCard)
