import { orderBy } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import ActionComponent from '../../../../../components/ActionComponent'
import Table from '../../../../../components/datatable/Table'
import { getMapStateToProps, getPropTypes } from '../../../../../utils/StoreUtils'
import DtoBoreholeCasingItem from '../dto/DtoBoreholeCasingItem'

const storeProps = {
}

class BoreholeCasingDashboard extends ActionComponent {
    getSummary = (casings) => {
        if (casings && casings.filter(c => c.startDepth || c.endDepth).length) {
            const data = orderBy(casings, 'order').map(c => new DtoBoreholeCasingItem(Object.assign({}, c, {
                cementing: c.cementing ? <i className='material-icons tiny table-checkbox'>check_box</i> : null,
                diameterSymbol: c.externalCasingDiameter ? c.externalCasingDiameter + i18n.milimetersSymbol : null,
                depth: (c.startDepth && c.endDepth) ? `${c.startDepth}m - ${c.endDepth}${i18n.metersSymbol}` : ((c.startDepth || c.endDepth) + i18n.metersSymbol),
            })))
            return (
                <Table
                    data={data}
                    sortable
                    title={i18n.casings}
                    condensed
                    className='smallCard padding-top-1'
                    showNbElements={false}
                    type={new DtoBoreholeCasingItem({})}
                />
            )
        }
        return null
    }

    render() {
        return this.getSummary(this.props.station.link_casings)
    }
}

BoreholeCasingDashboard.propTypes = getPropTypes(storeProps, {
    editMode: PropTypes.bool,
    station: PropTypes.object,
})

const mapStateToProps = () => getMapStateToProps(storeProps)

export default connect(mapStateToProps)(BoreholeCasingDashboard)
