import { formatSiret } from 'utils/StringUtil'

export default class DtoServiceSituation {
    constructor(obj) {
        this.codification = obj.codification // Option[String],
        this.idExploitation = obj.idExploitation // Option[Long],
        this.idService = obj.idService // Option[Long],
        this.designation = obj.designation // Option[String],
        this.technicalId = obj.technicalId // Option[String],
        this.productCode = obj.productCode // Option[String],
        this.calculationDate = obj.calculationDate // Option[DateTime],
        this.occurency = obj.occurency // Option[String],
        this.unity = obj.unity // Option[String],
        this.quantity = obj.quantity // Option[Long],
        this.siret = obj.siret ? formatSiret(obj.siret) : '' // Option[String],
        this.codifOCTAGRI = obj.codifOCTAGRI // Option[String],
        this.exploitationName = obj.exploitationName // Option[String],
        this.cityCode = obj.cityCode // Option[String],
        this.city = obj.city // Option[String]

        // front
        this.folder = obj.exploitationName
    }
}