import CatchmentPointDto from './CatchmentPointDto'
import SDAGEDto from './SDAGEDto'

export default class CatchmentWithLinkDto {
    constructor(obj) {
        this.id = obj.id // Int
        this.code = obj.code // Option[String]
        this.name = obj.name // Option[String]
        this.pond = obj.pond // Option[Int]
        this.department = obj.department // Option[Int]
        this.bnpeCode = obj.bnpeCode // Option[String]
        this.sampleType = obj.sampleType // Option[Int]
        this.sdageWork = obj.sdageWork // Option[Boolean]
        this.grenelleWork = obj.grenelleWork // Option[Boolean]
        this.meCode = obj.meCode // Option[String]
        this.aacNum = obj.aacNum // Option[String]
        this.stateStudy = obj.stateStudy // Option[Int]
        this.stateDiagnosticPressure = obj.stateDiagnosticPressure // Option[Int]
        this.stateActionPlan = obj.stateActionPlan // Option[Int]
        this.problematic = obj.problematic // Option[String]
        this.aacArea = obj.aacArea // Option[Double]
        this.abandonedCatchment = obj.abandonedCatchment // Option[Boolean]
        this.causeAbandonment = obj.causeAbandonment // Option[String]
        this.derogationConformity = obj.derogationConformity // Option[Boolean]
        this.agriOrientation = obj.agriOrientation // Option[String]

        this.catchmentPoints = obj.catchmentPoints?.map(catchmentPoint => new CatchmentPointDto(catchmentPoint)) || [] // Seq[CatchmentPoint]
        this.sdages = obj.sdages?.map(sdage => new SDAGEDto(sdage)) || [] // Seq[SDAGE]
    }
}