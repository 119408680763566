/* eslint-disable max-nested-callbacks */
import { countBy, groupBy, isUndefined, round, uniqBy } from 'lodash'
import PropTypes from 'prop-types'
import React, { useMemo, useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import Card from '../../../components/card/Card'
import { calculateAverage, searchMaxValue, searchMinValue, searchP90Value } from '../../../utils/AnalyseUtils'
import { hasValue } from '../../../utils/NumberUtil'
import { getObjectLabel } from '../../../utils/StoreUtils'
import MultiGridTable from 'components/datatable/virtualizedTable/MultiGridTable'
import { exportFile } from 'utils/ExportDataUtil'
import { ParameterGraphModal } from '../qualityComponents/ParameterGraph'
import useBoolean from 'utils/customHook/useBoolean'
import DtoQualityThreshold from 'quality/dto/QualityThreshold/DtoQualityThreshold'

const AEPAnalysisTable = ({
    title = '',
    analysis = [],
    thresholdList = [],
    displayAllParameters = false,
    height = '40%',
}) => {
    const {
        parametersIndex,
        unitsIndex,
        settings,
        producers,
    } = useSelector(store => ({
        parametersIndex: store.ParameterReducer.parametersIndex,
        unitsIndex: store.UnitReducer.unitsIndex,
        settings: store.AdministrationReducer.settings,
        producers: store.ContributorReducer.producers,
    }), shallowEqual)

    const {
        value: isOpen,
        setFalse: onClose,
        setTrue: onOpen,
    } = useBoolean(false)
    const [selectedParameter, setSelectedParameter] = useState()

    const producerIds = useMemo(() => uniqBy(analysis, 'producer').map(a => a.producer), [analysis])

    const headersAnalysis = useMemo(() => {
        return [
            'parameter',
            'min',
            'average',
            'percentile90',
            'max',
            'noncompliances',
            'nbAnalysis',
            ...producerIds.map(id => `${id}`),
        ]
    }, [producerIds])

    const formatedAnalysis = useMemo(() => {
        const groupedAnalysis = groupBy(analysis, 'parameter')
        const data = Object.keys(groupedAnalysis).map(parameter => {
            const { [parameter]: listAnalysis = [] } = groupedAnalysis
            const listAnalysisResult = listAnalysis.filter(a => a.color === 'red')

            const countTotalProducer = countBy(listAnalysis, 'producer')
            const countResultProducer = countBy(listAnalysisResult, 'producer')

            const producersData = producerIds.reduce((acc, id) => {
                const result = countResultProducer[id] || 0
                const total = countTotalProducer[id] || 0

                acc[id] = {
                    value: (
                        <>
                            <span className={result ? 'red' : ''} style={{ padding: '0px 10px', width: '50%', display: 'inline-block' }}>
                                {result || 0}
                            </span>
                            <span style={{ padding: '0px 10px', width: '50%', display: 'inline-block' }}>
                                {total || 0}
                            </span>
                        </>
                    ),
                    style: {
                        padding: '0px',
                    },
                    tooltip: () => `${i18n.noncompliances}: ${total ? round(result*100 / total, 2) : '0'} %`,
                }
                return acc
            }, {})

            const analysisWithValues = listAnalysis.filter(a => hasValue(a.result) && hasValue(a.value) && (a.status == 2 || a.status == 3))
            const value = listAnalysisResult.length

            return {
                parameter: { value: getObjectLabel(parametersIndex[parameter], 'shortLabelWithCode') },
                min: { value: searchMinValue(analysisWithValues) },
                average: { value: calculateAverage(analysisWithValues, settings) },
                percentile90: { value: searchP90Value(analysisWithValues) },
                max: { value: searchMaxValue(analysisWithValues) },
                nbAnalysis: { value: listAnalysis.length },
                noncompliances: {
                    sortValue: value,
                    value,
                    classNameColor: value ? 'red' : undefined,
                },
                ...producersData,
                parameterCode: parameter,
            }
        })
        return data
    }, [analysis, parametersIndex, producerIds, settings])

    const filteredAnalysis = useMemo(() => {
        if (displayAllParameters) {
            return formatedAnalysis
        }
        return formatedAnalysis.filter(obj => obj.noncompliances.sortValue !== 0)
    }, [displayAllParameters, formatedAnalysis])

    const customHeaders = useMemo(() => {
        const firstLine = producerIds.reduce((acc, id) => {
            const producer = producers.find(p => p.id === id)
            const label = producer?.mnemonique || producer?.name || i18n.unknownProducer
            acc[id] = {
                value: label,
                tooltip: producer?.name || i18n.unknownProducer,
            }
            return acc
        }, {})

        const secondLine = producerIds.reduce((acc, id) => {
            acc[id] = {
                value: `${i18n.nc} / ${i18n.analysis}`,
            }
            return acc
        }, {})
        return [
            firstLine,
            secondLine,
        ]
    }, [producerIds, producers])

    const actions = [{
        iconName: 'file_download',
        tooltip: i18n.export,
        onClick: () => {
            const groupedAnalysis = groupBy(analysis, 'parameter')
            const dataOrdered = Object.keys(groupedAnalysis).map(param => {
                const { [param]: listAnalysis = [] } = groupedAnalysis
                const listAnalysisResult = listAnalysis.filter(a => a.color === 'red')
                const analysisWithValues = listAnalysis.filter(a => hasValue(a.result) && hasValue(a.value) && (a.status == 2 || a.status == 3))

                const countTotalProducer = countBy(listAnalysis, 'producer')
                const countResultProducer = countBy(listAnalysisResult, 'producer')

                const producersData = producerIds.reduce((acc, id) => {
                    const result = countResultProducer[id] || 0
                    const total = countTotalProducer[id] || 0

                    const producer = producers.find(p => p.id === id)
                    const label = producer?.mnemonique || producer?.name || i18n.unknownProducer

                    acc[`${label} ${i18n.nc}`] = { value: result, format: '0', cellType: 'number' }
                    acc[`${label} ${i18n.analysis}`] = { value: total, format: '0', cellType: 'number' }
                    return acc
                }, {})

                return {
                    parameterCode: { value: param, cellType: 'right' },
                    parameter: parametersIndex[param].shortLabel || parametersIndex[param].name,
                    codeUnit: { value: listAnalysis[0].unit, cellType: 'right' },
                    unit: unitsIndex[listAnalysis[0].unit]?.symbol,
                    min: { value: searchMinValue(analysisWithValues), cellType: 'right' },
                    average: { value: calculateAverage(analysisWithValues, settings), cellType: 'right' },
                    percentile90: { value: searchP90Value(analysisWithValues), cellType: 'right' },
                    max: { value: searchMaxValue(analysisWithValues), cellType: 'right' },
                    noncompliances: { value: listAnalysisResult.length, format: '0', cellType: 'number' },
                    nbAnalysis: { value: listAnalysis.length, format: '0', cellType: 'number' },
                    ...producersData,
                }
            })
            const filteredData = displayAllParameters ? dataOrdered : dataOrdered.filter(obj => obj.noncompliances.value !== 0)
            const data = dataOrdered.length ? [{ ...filteredData[0], headers: Object.keys(filteredData[0]) }, ...filteredData.slice(1)] : []
            exportFile({
                data,
                exportType: 'xlsx',
                titleFile: `${i18n.AEPoverview} ${title}`,
            })
        },
    }]

    const titleWithElements = useMemo(() => {
        if (filteredAnalysis.length > 1) {
            return `${title} (${filteredAnalysis.length} ${i18n.elements})`
        }
        return `${title} (${filteredAnalysis.length} ${i18n.element})`
    }, [filteredAnalysis.length, title])

    const selectedAnalysis = useMemo(() => {
        if (isUndefined(selectedParameter)) {
            return []
        }
        return analysis.filter(a => a.parameter === selectedParameter)
    }, [analysis, selectedParameter])

    return !!filteredAnalysis.length && (
        <Card title={titleWithElements} actions={actions}>
            <div style={{ height }}>
                <MultiGridTable
                    data={filteredAnalysis}
                    headers={headersAnalysis}
                    customHeaders={customHeaders}
                    fixedColumnCount={1}
                    fixedRowCount={2}
                    onClick={row => {
                        onOpen()
                        setSelectedParameter(row.parameterCode)
                    }}
                />
            </div>
            <ParameterGraphModal
                isOpen={isOpen}
                closeGraph={onClose}
                analysis={selectedAnalysis}
                thresholds={thresholdList}
            />
        </Card>
    )
}

AEPAnalysisTable.propTypes = {
    title: PropTypes.string,
    displayAllParameters: PropTypes.bool,
    height: PropTypes.string,
    analysis: PropTypes.arrayOf(PropTypes.shape({
        // DtoAnalysisLight
        // calculateThresholdResult
        producer: PropTypes.number,
    })),
    thresholdList: PropTypes.arrayOf(PropTypes.instanceOf(DtoQualityThreshold)),
}

export default AEPAnalysisTable