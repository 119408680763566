import Card from 'components/card/Card'
import ProgressBar from 'components/progress/ProgressBar'
import { push } from '@lagunovsky/redux-react-router'
import React, { useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import useTitle from 'utils/customHook/useTitle'
import { H_CAMPAIGN_QUALITO_TRACKING } from '../../../account/constants/AccessRulesConstants'
import CampaignAction from '../../../campaign/actions/CampaignAction'
import CampaignTrackingOperation from '../../../campaign/components/campaignTracking/CampaignTrackingOperation'
import { componentHasHabilitations } from '../../../utils/HabilitationUtil'
import { useParams } from 'react-router'
import useBoolean from 'utils/customHook/useBoolean'
import { promiseAllProgress } from 'utils/ActionUtils'

const QualityCampaignTrackingApp = () => {
    const dispatch = useDispatch()
    const { id } = useParams()

    const {
        campaign,
        campaignPlannings,
    } = useSelector(store => ({
        campaign: store.CampaignReducer.campaign,
        campaignPlannings: store.CampaignReducer.campaignPlannings,
    }), shallowEqual)

    useEffect(() => {
        if (!componentHasHabilitations(H_CAMPAIGN_QUALITO_TRACKING)) {
            dispatch(push('/unauthorized'))
        }
    }, [])

    const [planningsProgress, setPlanningsProgress] = useState([])

    useTitle(() => [
        {
            title: i18n.quality,
            href: '/quality',
        },
        {
            title: i18n.campaigns,
            href: '/quality/campaign',
        },
        {
            title: campaign.id + (campaign.name && ` - ${campaign.name}` || ''),
            href: `/quality/campaign/${campaign.id}/dashboard`,
        },
        {
            title: i18n.tracking,
            href: `/quality/campaign/${campaign.id}/tracking`,
        },
    ], [campaign])

    const {
        value: isLoading,
        setTrue: onStartLoading,
        setFalse: onEndLoading,
    } = useBoolean(true)
    const [progress, setProgress] = useState(0)

    useEffect(() => {
        onStartLoading()
        setProgress(0)
        setPlanningsProgress([])
        dispatch(CampaignAction.fetchCampaignPlannings(id)).then((plannings) => {
            const promises = plannings.length === 0 ? [CampaignAction.getQualityFollowUp(id).then((pps = []) => setPlanningsProgress(p => [...p, pps]))]
                : plannings.map(cp => CampaignAction.getPlanningFollowUp(id, cp.id).then((pps = []) => setPlanningsProgress(p => [...p, pps])))
            promiseAllProgress(promises, setProgress).finally(onEndLoading)
        })
    }, [id])

    return (
        <>
            {isLoading && (
                <div style={{ margin: '10' }}>
                    <Card cardStyle={{ padding: '10' }}>
                        <ProgressBar withMessage indeterminate={campaignPlannings.length <= 1} progress={progress} />
                    </Card>
                </div>
            )}
            {!isLoading && (<CampaignTrackingOperation planningsProgress={planningsProgress}/>)}
        </>
    )
}

export default QualityCampaignTrackingApp
