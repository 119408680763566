export default class DtoPiezoSituationDayMeasure {
    constructor(obj) {
        this.day = obj.day
        this.dayStr = obj.dayStr
        this.label = obj.label
        this.rgCode = obj.rgCode
        this.color = obj.color
        this.warningCode = obj.warningCode
        this.measures = obj.measures
    }
}