import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
    environmentModels: [],
    typeEnvironmentModels: [],
    exportedFiles: [],
}

const store = createSlice({
    name: 'export',
    initialState,
    reducers: {
        receiveEnvironmentModels: (state, action) => {
            state.environmentModels = action.payload
        },
        receiveTypeEnvironmentModels: (state, action) => {
            state.typeEnvironmentModels = action.payload
        },
        receiveExportedFiles: (state, action) => {
            state.exportedFiles = action.payload.map((ef, i) => ({ ...ef, id: i }))
        },
        addExportedFile: (state, action) => {
            state.exportedFiles.push(action.payload)
        },
        updateExportedFile: (state, action) => {
            const index = state.exportedFiles.findIndex(file => file.id === action.payload.id)
            state.exportedFiles[index] = { ...state.exportedFiles[index], ...action.payload }
        },
    },
})

export const ExportActionConstant = store.actions
export default store.reducer
