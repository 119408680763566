import React, { Component } from 'react'
import Row from '../../../../components/react/Row'
import Input from '../../../../components/forms/Input'
import i18n from 'simple-react-i18n'
import Select from '../../../../components/forms/Select'
import { getSandreList } from '../../../../utils/StoreUtils'
import { SANDRE } from '../../../../referencial/constants/ReferencialConstants'
import Textarea from '../../../../components/forms/Textarea'
import PropTypes from 'prop-types'
import DtoSandreCode from '../../../../referencial/dto/DtoSandreCode'
import { connect } from 'react-redux'
import DtoSamplePoint from '../../../dto/DtoSamplePoint'
import SimpleDatePicker from '../../../../components/forms/SimpleDatePicker'
import NumberField from '../../../../components/forms/NumberField'
import SupportDto from '../../../../referencial/components/support/dto/SupportDto'
import SuperMultiAutocomplete from '../../../../components/forms/SuperMultiAutocomplete'
import AppStore from '../../../../store/AppStore'
import SupportAction from '../../../../referencial/components/support/actions/SupportAction'
import { isUndefined } from 'lodash'

class StationSamplePointModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            point: { ...props.point, idQualitometer: props.id },
        }
    }

    componentDidUpdate(prevProps) {
        if (!prevProps.popupIsOpen && this.props.popupIsOpen) {
            $('#descriptionModal').on('click', this.onSave)
        }
    }

    componentDidMount() {
        if (!this.props.supports.length) {
            AppStore.dispatch(SupportAction.fetchSupports())
        }
    }

    onSave = () => {
        this.props.saveResult(new DtoSamplePoint(this.state.point))
        $('.modal').modal('close')
    }

    onChangeElement = (changes) => this.setState({ point: { ...this.state.point, ...changes } })

    render() {
        const { point } = this.state
        return (
            <div>
                <Row>
                    <SimpleDatePicker col={6} value={point.startDate} label={i18n.startDate} onChange={ v => this.onChangeElement({ startDate: v }) } />
                    <SimpleDatePicker col={6} value={point.endDate} label={i18n.endDate} onChange={ v => this.onChangeElement({ endDate: v }) } />
                </Row>
                <Row>
                    <Input col={3} title={i18n.id} value={point.identifiant} onChange={ v => this.onChangeElement({ identifiant: v }) } />
                    <Input col={3} title={i18n.code} value={point.code} onChange={ v => this.onChangeElement({ code: v }) } />
                    <Input col={6} title={i18n.name} value={point.name} onChange={ v => this.onChangeElement({ name: v }) } />
                </Row>
                <Row>
                    <NumberField col={3} value={point.x} title={'X'} onChange={ v => this.onChangeElement({ x: v }) } floatValue/>
                    <NumberField col={3} value={point.y} title={'Y'} onChange={ v => this.onChangeElement({ y: v }) } floatValue/>
                    <Select col={6} options={ getSandreList(this.props.sandreCodes, SANDRE.PROJECTION) } label={ i18n.projection }
                        value={ point.projection } onChange={ v => this.onChangeElement({ projection: v }) }
                    />
                </Row>
                <Row>
                    <NumberField col={3} value={point.xUpstream} title={i18n.xUpstream} onChange={v => this.onChangeElement({ xUpstream: v })} floatValue />
                    <NumberField col={3} value={point.yUpstream} title={i18n.yUpstream} onChange={v => this.onChangeElement({ yUpstream: v })} floatValue />
                    <NumberField col={3} value={point.xDownstream} title={i18n.xDownstream} onChange={v => this.onChangeElement({ xDownstream: v })} floatValue />
                    <NumberField col={3} value={point.yDownstream} title={i18n.yDownstream} onChange={v => this.onChangeElement({ yDownstream: v })} floatValue />
                </Row>
                <Row>
                    <SuperMultiAutocomplete
                        options={this.props.supports}
                        onChange={(value) => this.onChangeElement({ codeSupport: !isUndefined(value) ? parseInt(value) : undefined })}
                        col={3}
                        keyValue='id'
                        label={i18n.support}
                        values={`${point.codeSupport}`}
                    />
                    <NumberField col={3} value={point.startDepth} title={i18n.startDepth} onChange={ v => this.onChangeElement({ startDepth: v }) } floatValue/>
                    <NumberField col={3} value={point.endDepth} title={i18n.endDepth} onChange={ v => this.onChangeElement({ endDepth: v }) } floatValue/>
                    <NumberField col={3} value={point.advisedDepth} title={i18n.advisedDepth} onChange={ v => this.onChangeElement({ advisedDepth: v }) } floatValue/>
                </Row>
                <Row>
                    <NumberField col={3} value={point.totalLength} title={i18n.totalLength} onChange={v => this.onChangeElement({ totalLength: v })} floatValue />
                    <NumberField col={3} value={point.flowWidth} title={i18n.flowWidth} onChange={v => this.onChangeElement({ flowWidth: v })} floatValue />
                    <Input col={6} title={i18n.mainObject} value={point.mainObject} onChange={v => this.onChangeElement({ mainObject: v })} />
                </Row>
                <Row>
                    <Input col={6} title={`${i18n.photo} 1`} value={point.photo1} onChange={v => this.onChangeElement({ photo1: v })} />
                    <Input col={6} title={`${i18n.photo} 2`} value={point.photo2} onChange={v => this.onChangeElement({ photo2: v })} />
                </Row>
                <Row>
                    <Input col={6} title={`${i18n.photo} 3`} value={point.photo3} onChange={v => this.onChangeElement({ photo3: v })} />
                    <Input col={6} title={`${i18n.photo} 4`} value={point.photo4} onChange={v => this.onChangeElement({ photo4: v })} />
                </Row>
                <Row>
                    <Input col={6} title={`${i18n.photo} 5`} value={point.photo5} onChange={v => this.onChangeElement({ photo5: v })} />
                    <Input col={6} title={`${i18n.photo} 6`} value={point.photo6} onChange={v => this.onChangeElement({ photo6: v })} />
                </Row>
                <div className='row no-margin padding-top-1'>
                    <Textarea col={6} title={i18n.comment} value={point.comment} onChange={v => this.onChangeElement({ comment: v })} />
                    <Textarea col={6} title={i18n.advise} value={point.advise} onChange={v => this.onChangeElement({ advise: v })} />
                </div>
            </div>
        )
    }
}

StationSamplePointModal.propTypes = {
    point: PropTypes.instanceOf(DtoSamplePoint),
    id: PropTypes.number,
    sandreCodes: PropTypes.arrayOf(PropTypes.instanceOf(DtoSandreCode)),
    popupIsOpen: PropTypes.bool,
    saveResult: PropTypes.func,
    supports: PropTypes.instanceOf(SupportDto),
}


const mapStateToProps = store => ({
    sandreCodes: store.ReferencialReducer.sandreCodes,
    popupIsOpen: store.SieauReducer.popupIsOpen,
    supports: store.SupportReducer.supports,
})

StationSamplePointModal.defaultProps = {
    point: {},
}

export default connect(mapStateToProps)(StationSamplePointModal)
