import fetch from 'isomorphic-fetch'
import {
    RECEIVE_ALL_MATERIEL_TYPES,
    RECEIVE_CHECK_DELETE,
    RECEIVE_REFERENCIAL_SANDRE_CODES,
    RECEIVE_REFERENCIAL_STATUS,
    RECEIVE_REFERENCIAL_SUPPORT,
    RECEIVE_SANDRE_CODES,
    RECEIVE_STATION_TYPES,
    RESET_CHECK_DELETE,
} from '../constants/ReferencialConstants'
import { checkAuth, checkError, getAuthorization, getJson } from '../../utils/ActionUtils'
import ToastrAction from 'toastr/actions/ToastrAction'
import AppStore from 'store/AppStore'
import i18n from 'simple-react-i18n'
import { RESET_CITY } from '../components/city/constants/CityConstants'
import { RESET_CONTACT } from '../components/contact/constants/ContactConstants'
import { RESET_CONTRIBUTOR } from '../components/contributor/constants/ContributorConstants'
import { RESET_FRACTION } from '../components/fraction/constants/FractionConstants'
import { RESET_HYDROGEOLOGICAL_ENTITY } from '../components/hydrogeologicalEntity/constants/HydrogeologicalEntityConstants'
import { RESET_METHOD } from '../components/methods/constants/MethodConstants'
import { RESET_NETWORK } from '../components/network/constants/NetworkConstants'
import { RESET_PARAMETER } from '../components/parameter/constants/ParameterConstants'
import { RESET_SUPPORT } from '../components/support/constants/SupportConstants'
import { RESET_UNIT } from '../components/unit/constants/UnitConstants'
import { RESET_WATERMASS } from '../components/watermass/constants/WatermassConstants'
import { RESET_WATERSHED } from '../components/watershed/constants/WatershedConstants'
import { RESET_AQUIFER } from '../components/aquifers/constants/AquiferConstants'
import ApplicationConf from '../../conf/ApplicationConf'
import LogAction from '../../log/actions/LogAction'
import DtoLocation from 'home/dto/DtoLocation'

const ReferencialAction = {

    fetchIfNeeded(prop, fetchFunc, param1) {
        return function (dispatch) {
            const elem = AppStore.getState().ReferencialReducer[prop]
            if (elem.length) {
                if (elem.length !== 0) {
                    return { type: '' }
                }
            } else if (Object.keys(elem).length !== 0) {
                return { type: '' }
            }
            return dispatch(fetchFunc(param1))
        }
    },

    receiveReferencialStatus(referencialStatus) {
        return { type: RECEIVE_REFERENCIAL_STATUS, referencialStatus }
    },

    receiveReferencialSandreCodes(methods) {
        return { type: RECEIVE_REFERENCIAL_SANDRE_CODES, referencialSandreCodes: methods }
    },

    fetchReferencialStatus() {
        return (dispatch) => {
            return fetch(ApplicationConf.referencial.contributorStatut(), {
                method: 'GET',
                headers: getAuthorization(),
            })
                .then(getJson)
                .then((json = []) => {
                    dispatch(ReferencialAction.receiveReferencialStatus(json))
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.status} ${i18n.contributor} : ${err}`))
                    dispatch(ToastrAction.error(`${i18n.fetchError + i18n.status} ${i18n.contributor}`))
                })
        }
    },

    receiveReferencialSupport(supports) {
        return { type: RECEIVE_REFERENCIAL_SUPPORT, supports }
    },

    fetchReferencialSupport() {
        return (dispatch) => {
            return fetch(ApplicationConf.referencial.supports(), {
                method: 'GET',
                headers: getAuthorization(),
            })
                .then(getJson)
                .then((json = []) => {
                    dispatch(ReferencialAction.receiveReferencialSupport(json))
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.supports} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError + i18n.supports))
                })
        }
    },

    receiveStationTypes(types) {
        return { type: RECEIVE_STATION_TYPES, types }
    },
    promiseStationTypes() {
        return fetch(ApplicationConf.referencial.stationTypes(), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(getJson)
    },
    fetchStationTypes() {
        return (dispatch) => {
            ReferencialAction.promiseStationTypes()
                .then((json = []) => {
                    dispatch(ReferencialAction.receiveStationTypes(json))
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.stationTypes} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError + i18n.stationTypes))
                })
        }
    },

    receiveSandreCodes(codes) {
        return { type: RECEIVE_SANDRE_CODES, codes }
    },

    promiseSandreCodes() {
        return fetch(ApplicationConf.referencial.sandreCodes(), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
    },
    fetchSandreCodes(forceLoad = false) {
        return (dispatch, getState) => {
            const { sandreCodes } = getState().ReferencialReducer
            if (sandreCodes.length && !forceLoad) {
                return Promise.resolve()
            }
            return ReferencialAction.promiseSandreCodes()
                .then((json = []) => {
                    dispatch(ReferencialAction.receiveSandreCodes(json))
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.sandreCode} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError + i18n.sandreCode))
                })
        }
    },

    promiseReferencialSandreCodes() {
        return fetch(ApplicationConf.referencial.referencialSandreCodes(), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
    },

    fetchReferencialSandreCodes() {
        return (dispatch) => {
            return ReferencialAction.promiseReferencialSandreCodes()
                .then((json = []) => {
                    dispatch(ReferencialAction.receiveReferencialSandreCodes(json))
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.referencialUpdate} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError + i18n.referencialUpdate))
                })
        }
    },

    fetchReferencialSandreCode(field) {
        return (dispatch) => {
            return fetch(ApplicationConf.referencial.referencialSandreCode(field), {
                method: 'GET',
                headers: getAuthorization(),

            })
                .then(checkAuth)
                .then(getJson)
                .then((json = []) => {
                    dispatch(ReferencialAction.receiveReferencialSandreCodes(json))
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.referencialUpdate} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError + i18n.referencialUpdate))
                })
        }
    },

    receiveCheckDelete(checkDelete) {
        return { type: RECEIVE_CHECK_DELETE, checkDelete }
    },

    fetchCheckDelete(applicationConfDelete) {
        return (dispatch) => {
            return fetch(applicationConfDelete, {
                method: 'GET',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then((json) => {
                    dispatch(ReferencialAction.receiveCheckDelete(json.value))
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.checkDelete} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError + i18n.checkDelete))
                })
        }
    },

    fetchCheckPurgeReferencialIsRunning(referencial) {
        return (dispatch) => {
            return fetch(ApplicationConf.referencial.checkPurgeReferencialIsRunning(referencial), {
                method: 'GET',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.verificationErrorPurgeInProgress} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError + i18n.verificationErrorPurgeInProgress))
                })
        }
    },

    addSandreCode(sandreCode) {
        return dispatch => {
            return fetch(ApplicationConf.referencial.updateSandreCode(), {
                method: 'POST',
                headers: getAuthorization(),
                body: JSON.stringify(sandreCode),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(() => {
                    dispatch(ToastrAction.success(i18n.elementUpdateSuccess))
                })
                .catch((err) => {
                    dispatch(LogAction.logError(`${i18n.updateError + i18n.lexicons} : ${err}`))
                    dispatch(ToastrAction.error(i18n.updateError + i18n.lexicons))
                })
        }
    },

    updateSandreCode(sandreCode) {
        return dispatch => {
            return fetch(ApplicationConf.referencial.updateSandreCode(), {
                method: 'PUT',
                headers: getAuthorization(),
                body: JSON.stringify(sandreCode),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(() => {
                    dispatch(ToastrAction.success(i18n.elementUpdateSuccess))
                })
                .catch((err) => {
                    dispatch(LogAction.logError(`${i18n.updateError + i18n.lexicons} : ${err}`))
                    dispatch(ToastrAction.error(i18n.updateError + i18n.lexicons))
                })
        }
    },

    addReferencialSandreCode(sandreCode) {
        return dispatch => {
            return fetch(ApplicationConf.referencial.updateReferencialSandreCode(), {
                method: 'POST',
                headers: getAuthorization(),
                body: JSON.stringify(sandreCode),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(() => {
                    dispatch(ToastrAction.success(i18n.elementUpdateSuccess))
                })
                .catch((err) => {
                    dispatch(LogAction.logError(`${i18n.updateError + i18n.lexicons} : ${err}`))
                    dispatch(ToastrAction.error(i18n.updateError + i18n.lexicons))
                })
        }
    },

    updateReferencialSandreCode(sandreCode) {
        return dispatch => {
            return fetch(ApplicationConf.referencial.updateReferencialSandreCode(), {
                method: 'PUT',
                headers: getAuthorization(),
                body: JSON.stringify(sandreCode),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(() => {
                    dispatch(ToastrAction.success(i18n.elementUpdateSuccess))
                })
                .catch((err) => {
                    dispatch(LogAction.logError(`${i18n.updateError + i18n.lexicons} : ${err}`))
                    dispatch(ToastrAction.error(i18n.updateError + i18n.lexicons))
                })
        }
    },

    receiveMaterielTypes: materielTypes => ({ type: RECEIVE_ALL_MATERIEL_TYPES, materielTypes }),

    fetchMaterielTypes: () => dispatch => fetch(ApplicationConf.materiel.materielType(), {
        method: 'GET',
        headers: getAuthorization(),
    })
        .then(checkAuth)
        .then(getJson)
        .then(checkError)
        .then(json => dispatch(ReferencialAction.receiveMaterielTypes(json)))
        .catch(err => {
            dispatch(LogAction.logError(`${i18n.fetchError + i18n.materiel} : ${err}`))
            dispatch(ToastrAction.error(i18n.fetchError + i18n.materiel))
        }),

    addMaterielType(materielType, field) {
        return dispatch => {
            return fetch(ApplicationConf.materiel.addMaterielType(field), {
                method: 'POST',
                headers: getAuthorization(),
                body: JSON.stringify(materielType),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(() => {
                    dispatch(ToastrAction.success(i18n.elementUpdateSuccess))
                    return true
                })
                .catch((err) => {
                    dispatch(LogAction.logError(`${i18n.updateError + i18n.materielTypes} : ${err}`))
                    dispatch(ToastrAction.error(i18n.updateError + i18n.materielTypes))
                })
        }
    },

    updateMaterielType(materielType) {
        return dispatch => {
            return fetch(ApplicationConf.materiel.type(), {
                method: 'PUT',
                headers: getAuthorization(),
                body: JSON.stringify(materielType),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(() => {
                    dispatch(ToastrAction.success(i18n.elementUpdateSuccess))
                    return true
                })
                .catch((err) => {
                    dispatch(LogAction.logError(`${i18n.updateError + i18n.materielTypes} : ${err}`))
                    dispatch(ToastrAction.error(i18n.updateError + i18n.materielTypes))
                })
        }
    },

    fetchLocation(location) {
        return (dispatch) => {
            return fetch(ApplicationConf.referencial.location(location), {
                method: 'POST',
                headers: getAuthorization(),
                body: JSON.stringify(location),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then((json = {}) => {
                    return new DtoLocation(json)
                })
                .catch((err) => {
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.location} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError + i18n.location))
                })
        }
    },

    resetReferencial() {
        return (dispatch) => {
            dispatch({ type: RESET_CITY })
            dispatch({ type: RESET_CONTACT })
            dispatch({ type: RESET_CONTRIBUTOR })
            dispatch({ type: RESET_FRACTION })
            dispatch({ type: RESET_HYDROGEOLOGICAL_ENTITY })
            dispatch({ type: RESET_METHOD })
            dispatch({ type: RESET_NETWORK })
            dispatch({ type: RESET_PARAMETER })
            dispatch({ type: RESET_SUPPORT })
            dispatch({ type: RESET_UNIT })
            dispatch({ type: RESET_WATERMASS })
            dispatch({ type: RESET_WATERSHED })
            dispatch({ type: RESET_AQUIFER })
        }
    },

    resetCheckDelete() {
        return { type: RESET_CHECK_DELETE }
    },
}

export default ReferencialAction
