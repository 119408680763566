/* eslint-disable camelcase */
import { Grid, Button } from '@mui/material'
import PropTypes from 'prop-types'
import Input from 'components/forms/Input'
import Select from 'components/forms/Select'
import React, { useEffect, useMemo, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import ToastrAction from 'toastr/actions/ToastrAction'
import { searchAllCharacters } from 'utils/StringUtil'
import { nbPerPageLabelShorty } from '../../../referencial/constants/ReferencialConstants'
import { lowerCase, sortBy, uniq } from 'lodash'
import Checkbox from 'components/forms/Checkbox'
import Table from 'components/datatable/Table'
import { DialogActionsMUI, DialogContentMUI, DialogMUI, DialogTitleMUI } from 'components/styled/Dialog'
import Icon from 'components/icon/Icon'
import { StyledFieldSet, StyledLegend } from 'components/StyledElements'
import RadioButtons from 'components/forms/RadioButtons'
import moment from 'moment'
import { getYear } from 'utils/DateUtil'
import { hasValue } from 'utils/NumberUtil'
import { getTextSlot } from 'exploitations/utils/AgriUtils'

const headers = ['codification', 'city', 'sampler', 'structureType', 'nullValue']

const AddWaterTurnsDialog = ({
    openAdd = false,
    setOpenAdd = () => { },
    addWaterTurns = () => { },
}) => {
    const dispatch = useDispatch()
    const {
        exploitationsExportFullData,
        cities,
        managementUnits,
        watersheds,
        waterTurnsSlots,
    } = useSelector(store => ({
        exploitationsExportFullData: store.AgriReducer.exploitationsExportFullData,
        cities: store.CityReducer.cities,
        managementUnits: store.ManagementUnitReducer.managementUnits,
        watersheds: store.WatershedReducer.watersheds,
        waterTurnsSlots: store.AgriReducer.waterTurnsSlots,
    }), shallowEqual)

    const [searchFilter, setSearchFilter] = useState('')
    const [city, setCity] = useState()
    const [managementUnit, setManagementUnit] = useState()
    const [subManagementUnit, setSubManagementUnit] = useState()
    const [watershed, setWatershed] = useState()
    const [exploitationsToAdd, setExploitationsToAdd] = useState([])
    const [selectedSlot, setSelectedSlot] = useState()

    const nowYear = getYear(moment.now())

    const radioSlots = useMemo(() => {
        return waterTurnsSlots.filter(d => d.year === nowYear).map(d => ({ ...d, name: getTextSlot(d) }))
    }, [waterTurnsSlots])


    useEffect(() => {
        if (openAdd) {
            setSelectedSlot(radioSlots[0]?.id)
        }
    }, [openAdd, radioSlots])

    const displayExploitations = exploitationsExportFullData.map(e => {
        const selected = exploitationsToAdd.includes(e.idExploitation)
        return {
            ...e,
            sampler: e.name,
            city: e.inseeCode ? `${e.city} [${e.inseeCode}]` : '',
            structureType: e.structureType,
            nullValue: (
                <Checkbox
                    checked={!!selected}
                    onChange={value => {
                        if (value) {
                            setExploitationsToAdd([...exploitationsToAdd, e.idExploitation])
                        } else {
                            setExploitationsToAdd(exploitationsToAdd.filter(exp => exp !== e.idExploitation))
                        }
                    }}
                />
            ),
        }
    })

    const getHashMateriel = (exploitation) => {
        return lowerCase((exploitation.link_points || []).reduce((acc, p) => `${acc ? `${acc}, ` : ''}${p.code ? `${p.code}, ` : ''}${p.materiels || ''}`, ''))
    }

    const getHash = (exploitation) => {
        return searchAllCharacters(headers.map(key => exploitation[key] && exploitation[key].value ? exploitation[key].value : exploitation[key]))
    }

    const includesValue = searchAllCharacters(searchFilter || '')

    const getFilteredData = (data) => {
        return data.filter(i => getHashMateriel(i).includes(lowerCase(searchFilter)) || getHash(i).includes(includesValue))
    }

    const cityFilteredData = city ? displayExploitations.filter((e) => (city === e.inseeCode) || e.link_points.find((p) => p.cityCode === city)) : displayExploitations
    const ugeFilteredData = managementUnit ? cityFilteredData.filter(({ link_points }) => link_points && link_points.find((l) => l.managementCode === parseInt(managementUnit))) : cityFilteredData
    const subUgeFilteredData = subManagementUnit ? ugeFilteredData.filter(({ link_points }) => link_points && link_points.find((l) => l.subManagementCode === parseInt(subManagementUnit))) : ugeFilteredData
    const watershedFilteredData = watershed ? subUgeFilteredData.filter(({ link_points }) => link_points && link_points.find((l) => l.bvCode === parseInt(watershed))) : subUgeFilteredData
    const tableData = getFilteredData(watershedFilteredData)

    return (
        <DialogMUI maxWidth='lg' fullWidth open={openAdd}>
            <DialogTitleMUI>
                <Grid container justifyContent='space-between' alignItems='center'>
                    <Grid item >
                        {i18n.addWaterTurns}
                    </Grid>
                    <Grid item>
                        <Icon
                            style={{ color: 'white' }}
                            size='small'
                            icon={'close'}
                            onClick={() => {
                                setExploitationsToAdd([])
                                setOpenAdd(false)
                            }}
                        />
                    </Grid>
                </Grid>
            </DialogTitleMUI>
            <DialogContentMUI>
                <StyledFieldSet>
                    <StyledLegend className='padding-left-1 padding-right-1'>{i18n.slots}</StyledLegend>
                    <RadioButtons
                        col={12}
                        className='no-padding no-margin'
                        elements={radioSlots}
                        selected={selectedSlot}
                        onChange={setSelectedSlot}
                    />
                </StyledFieldSet>
                <StyledFieldSet style={{ marginBottom: 10 }}>
                    <StyledLegend>{i18n.filters}</StyledLegend>
                    <Grid container justifyContent='space-between' style={{ paddingTop: '5px' }}>
                        <Input
                            title={i18n.search}
                            value={searchFilter}
                            onEnterKeyPress={setSearchFilter}
                            data-cy='add_search_dialog'
                        />
                        <Select
                            label={i18n.city}
                            options={cities}
                            value={city}
                            keyLabel='labelWithCode'
                            onChange={setCity}
                            clearFunction
                        />
                        <Select
                            label={i18n.managementUnit}
                            options={sortBy(managementUnits.filter((u) => !u.parent), 'id')}
                            value={managementUnit}
                            keyValue='managementCode'
                            keyLabel='labelWithCode'
                            onChange={setManagementUnit}
                            clearFunction
                        />
                        <Select
                            label={i18n.subManagementUnit}
                            options={managementUnit ? sortBy(managementUnits.filter((u) => u.parent === parseInt(managementUnit)), 'id') : []}
                            value={subManagementUnit}
                            keyValue='managementCode'
                            keyLabel='labelWithCode'
                            onChange={setSubManagementUnit}
                            clearFunction
                        />
                        <Select
                            label={i18n.watershed}
                            options={sortBy(watersheds, [e => e.name?.toLowerCase()])}
                            value={watershed}
                            keyValue='id'
                            keyLabel='labelWithCode'
                            onChange={setWatershed}
                            integerValue
                            clearFunction
                        />
                    </Grid>
                </StyledFieldSet>
                <Table
                    title={i18n.folders}
                    data={tableData}
                    type={{ headers }}
                    sortable
                    condensed
                    paging
                    round
                    nbPerPageLabel={nbPerPageLabelShorty}
                    customHeaders={{
                        nullValue: (<Checkbox
                            checked={!tableData.filter(td => !exploitationsToAdd.includes(td.idExploitation)).length}
                            onChange={value => {
                                const tableDataIds = tableData.map(d => d.idExploitation)
                                if (value) {
                                    setExploitationsToAdd(uniq([...exploitationsToAdd, ...tableDataIds]))
                                } else {
                                    setExploitationsToAdd(exploitationsToAdd.filter(e => !tableDataIds.includes(e)))
                                }
                            }}
                            data-cy='check_all_exploitations'
                        />),
                    }}
                />
            </DialogContentMUI>
            <DialogActionsMUI>
                <Grid xs={12} container justifyContent='flex-end' alignItems='flex-end'>
                    <Grid className='padding-left-1'>
                        <Button
                            variant='contained'
                            onClick={() => {
                                if (exploitationsToAdd.length && hasValue(selectedSlot)) {
                                    addWaterTurns(exploitationsToAdd, selectedSlot)
                                    setExploitationsToAdd([])
                                } else {
                                    dispatch(ToastrAction.warning(i18n.nothingToDoOrProcess))
                                }
                            }}
                            data-cy='add_water_turns_button'
                        >
                            {i18n.add}
                        </Button>
                    </Grid>
                </Grid>
            </DialogActionsMUI>
        </DialogMUI>
    )
}

AddWaterTurnsDialog.propTypes = {
    openAdd: PropTypes.bool,
    setOpenAdd: PropTypes.func,
    addWaterTurns: PropTypes.func,
}

export default AddWaterTurnsDialog