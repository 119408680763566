import { orderBy } from 'lodash'
import moment from 'moment'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import {
    H_HYDRO_MODULE,
    H_INSTALLATION_MODULE,
    H_PIEZO_MODULE,
    H_PLUVIO_MODULE,
    H_QUALITO_MODULE,
} from '../../../../account/constants/AccessRulesConstants'
import Checkbox from '../../../../components/forms/Checkbox'
import Input from '../../../../components/forms/Input'
import Select from '../../../../components/forms/Select'
import SimpleDatePicker from '../../../../components/forms/SimpleDatePicker'
import Textarea from '../../../../components/forms/Textarea'
import CityDto from '../../../../referencial/components/city/dto/CityDto'
import ContributorDto from '../../../../referencial/components/contributor/dto/ContributorDto'
import { componentHasHabilitations } from '../../../../utils/HabilitationUtil'
import { getObjectLabel } from '../../../../utils/StoreUtils'
import DtoMaterielState from '../../../dto/DtoMaterielState'

const SituationForm = ({
    situation = {},
    situationMaterialReplace = {},
    onChange = () => { },
    serialNumber = '',
    showCheckbox = false,
    shouldReplace = false,
    shouldUsePreviousParam = false,
    materialOnSite = [],
    stations = [],
    isCentral = false,

    materialState = [],
    citiesIndex = {},
    contributors = [],
}) => {
    const stationInput = (() => {
        switch (situation.statusCode) {
            case 1:
            case 4:
                const stationFormat = stations.map(s => {
                    const labelTownCode = getObjectLabel(citiesIndex[s.townCode])
                    return {
                        ...s,
                        label: `${labelTownCode ? `${labelTownCode}${s.name || s.code ? ' - ' : ''}` : ''}${s.name || ''} ${s.code ? `[${s.code}]` : ''}`,
                    }
                })
                return (
                    <div className='row no-margin'>
                        <div className='col s12'>
                            <Select
                                col={12}
                                value={situation.siteCode}
                                label={i18n.assignment}
                                options={stationFormat}
                                keyValue='id'
                                keyLabel='label'
                                onChange={(_, v) => onChange({
                                    shouldReplace: false,
                                    shouldUsePreviousParam: false,
                                    situationMaterialReplace: {},
                                    situation: {
                                        ...situation,
                                        siteCode: v && v.id,
                                        siteName: v ? `${v.code ? `[${v.code}${v.designation ? `/${v.designation}` : ''}] - ` : ''}${v.name || ''}` : '',
                                    },
                                })}
                            />
                        </div>
                    </div>
                )
            case 7:
                return (
                    <div className='row col s5'>
                        <Input
                            col={12}
                            value={serialNumber}
                            title={i18n.serialNumber}
                        />
                    </div>
                )
            default:
                return null
        }
    })()

    const stationTypes = [{
        code: 1,
        label: i18n.piezometry,
        habilitation: H_PIEZO_MODULE,
    }, {
        code: 2,
        label: i18n.pluviometry,
        habilitation: H_PLUVIO_MODULE,
    }, {
        code: 3,
        label: i18n.quality,
        habilitation: H_QUALITO_MODULE,
    }, {
        code: 4,
        label: i18n.hydrometry,
        habilitation: H_HYDRO_MODULE,
    }, {
        code: 7,
        label: i18n.installation,
        habilitation: H_INSTALLATION_MODULE,
    }].filter(t => componentHasHabilitations(t.habilitation))

    return (
        <div className='col s12 padding-top-1 padding-bottom-1'>
            <div className='row no-margin'>
                <div className='col s5'>
                    <Select
                        options={orderBy(materialState, 'code', 'asc')}
                        label={i18n.state}
                        col={12}
                        onChange={value => {
                            if (value == 7) {
                                onChange({
                                    shouldReplace: false,
                                    shouldUsePreviousParam: false,
                                    situationMaterialReplace: {},
                                    situation: {
                                        ...situation,
                                        comment: serialNumber,
                                        statusCode: parseInt(value),
                                        siteType: undefined,
                                        siteCode: undefined,
                                        siteName: undefined,
                                    },
                                })
                            } else {
                                onChange({ situation: { ...situation, statusCode: parseInt(value) } })
                            }
                        }}
                        value={situation.statusCode}
                        nullLabel='&nbsp;'
                    />
                </div>
                {
                    (situation.statusCode === 1 || situation.statusCode === 4) && (
                        <div className='col s7'>
                            <Select
                                options={stationTypes}
                                label={i18n.assignmentType}
                                col={12}
                                onChange={siteType => onChange({
                                    shouldReplace: false,
                                    shouldUsePreviousParam: false,
                                    situationMaterialReplace: {},
                                    situation: {
                                        ...situation,
                                        siteType,
                                        siteCode: undefined,
                                        siteName: undefined,
                                    },
                                })}
                                value={situation.siteType}
                                nullLabel='&nbsp;'
                            />
                        </div>
                    )
                }
            </div>
            {!!situation.siteType && stationInput}
            <div className='row no-margin padding-top-1 valign-wrapper'>
                <div className='col s3 no-margin'>
                    <SimpleDatePicker
                        col={12}
                        id='situationDate'
                        value={situation.situationDate}
                        label={i18n.startDate}
                        onChange={value => onChange({ situation: { ...situation, situationDate: moment(value).valueOf() } })}
                    />
                </div>
                <div className='col s9 no-margin'>
                    <Select
                        col={12}
                        value={situation.administratorCode}
                        label={i18n.administrator}
                        options={contributors}
                        keyValue='id'
                        displayWithCode
                        onChange={v => onChange({ situation: { ...situation, administratorCode: v } })}
                    />
                </div>
            </div>
            {
                (situation.statusCode === 1 || situation.statusCode === 4) && showCheckbox && (
                    <div className='row no-margin padding-top-1'>
                        <div className='col s12'>
                            <Checkbox
                                col={4}
                                label={i18n.replaceMaterial}
                                checked={shouldReplace}
                                onChange={v => onChange({ shouldReplace: v, situationMaterialReplace: {} })}
                            />
                            {
                                shouldReplace && (
                                    <Select
                                        options={materialOnSite}
                                        label={i18n.materialToReplace}
                                        col={4}
                                        onChange={value => onChange({ situationMaterialReplace: { materielId: value } })}
                                        value={situationMaterialReplace.materielId}
                                        nullLabel='&nbsp;'
                                    />
                                )
                            }
                            {
                                !!situationMaterialReplace.materielId && (
                                    <Select
                                        options={materialState.filter(s => s.code !== 1 && s.code !== 4)}
                                        label={i18n.newSituation}
                                        col={4}
                                        onChange={value => onChange({ situationMaterialReplace: { ...situationMaterialReplace, statusCode: value } })}
                                        value={situationMaterialReplace.statusCode}
                                        nullLabel='&nbsp;'
                                    />
                                )
                            }
                        </div>
                    </div>
                )
            }
            {
                (situation.statusCode === 1 || situation.statusCode === 4) && showCheckbox && shouldReplace && isCentral && !!situationMaterialReplace.materielId && (
                    <div className='row no-margin padding-top-1'>
                        <div className='col s8'>
                            <Checkbox
                                col={12}
                                label={i18n.usePreviousParam}
                                checked={shouldUsePreviousParam}
                                onChange={v => onChange({ shouldUsePreviousParam: v })}
                            />
                        </div>
                    </div>
                )
            }
            <div className='row no-margin padding-top-15-px'>
                <div className='col s12'>
                    <Textarea
                        col={12}
                        title={i18n.comment}
                        value={situation.comment}
                        onChange={value => onChange({ situation: { ...situation, comment: value } })}
                    />
                </div>
            </div>
        </div>
    )
}

SituationForm.propTypes = {
    situation: PropTypes.object,
    situationMaterialReplace: PropTypes.object,
    onChange: PropTypes.func,
    serialNumber: PropTypes.string,
    showCheckbox: PropTypes.bool,
    shouldReplace: PropTypes.bool,
    shouldUsePreviousParam: PropTypes.bool,
    materialOnSite: PropTypes.array,
    stations: PropTypes.array,
    isCentral: PropTypes.bool,

    materialState: PropTypes.arrayOf(PropTypes.instanceOf(DtoMaterielState)),
    citiesIndex: PropTypes.objectOf(CityDto),
    contributors: PropTypes.arrayOf(PropTypes.instanceOf(ContributorDto)),
}

const mapStateToProps = store => ({
    materialState: store.MaterielReducer.materielStates,
    citiesIndex: store.CityReducer.citiesIndex,
    contributors: store.ContributorReducer.contributors,
})

export default connect(mapStateToProps)(SituationForm)
