import { INSTALLATION_TYPE } from '../../constants/InstallationConstants'

export default class DtoBoreholeTableLine {
    constructor(obj) {
        this.id = obj[0] // Int,
        this.code = obj[1] // Option[String],
        this.name = obj[2] // Option[String],
        this.townCode = obj[3] // Option[String],
        this.townName = obj[4] // Option[String],
        this.x = obj[5] // Option[Double],
        this.y = obj[6] // Option[Double],
        this.projection = obj[7] // Option[Int],
        this.projectionLabel = obj[8] // Option[String],
        this.depth = obj[9] // Option[Double],
        this.fieldMode = obj[10] // Option[String],
        this.aquifer = obj[11] // Option[String],
        this.jobExecutionId = obj[12] // Option[Long],
        this.internalReferences = obj[13] // Option[String],
        this.status = obj[14] // Option[Int],
        this.statusLabel = obj[15] // Option[String],
        this.administratorId = obj[16] // Option[Int], // maitre d'ouvrage
        this.buildingOwnerName = obj[17] // same as admin name
        this.operatorId = obj[18] // Option[Int], // exploitant
        this.operatorName = obj[19] // Option[String],
        this.dataOrigin = obj[20] // Option[String],
        this.sectorId = obj[21] // Option[Boolean],
        this.sectorLabel = obj[22] // Option[Boolean]
        this.usages = obj[23] // Option[String]
        this.typeName = 'installation'
        this.installationType = INSTALLATION_TYPE.BOREHOLE
    }
}