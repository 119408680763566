import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { hasBooleanValue } from '../../../utils/NumberUtil'
import SubCardList from './SubCardList'


class CardList extends Component {
    getContent = (subLists) => {
        if (this.props.collapsible) {
            return (
                <ul className='sieau-collapsible expandable' data-collapsible='expandable'>
                    { subLists }
                </ul>
            )
        }
        return subLists
    }

    render() {
        const subLists = this.props.data.map((subList, i) => (
            <SubCardList
                key={i}
                title={subList.title}
                titleColor={subList.titleColor}
                cards={subList.cards}
                collapsible={ hasBooleanValue(subList.collapsible) ? subList.collapsible : this.props.collapsible }
                dontOpenFirst={ this.props.dontOpenFirst }
                smallTitle={ this.props.smallTitle }
                loadOnClick={this.props.loadOnClick}
            />
        ))
        return (
            <div>
                { this.getContent(subLists) }
            </div>
        )
    }
}

CardList.propTypes = {
    data: PropTypes.arrayOf(PropTypes.shape({
        title: PropTypes.string.isRequired,
        titleColor: PropTypes.string.isRequired,
        collapsible: PropTypes.bool,
        cards: PropTypes.arrayOf(PropTypes.shape({
            color: PropTypes.string,
            onClick: PropTypes.func,
            content: PropTypes.element.isRequired,
        })).isRequired,
    })).isRequired,
    collapsible: PropTypes.bool,
    dontOpenFirst: PropTypes.bool,
    smallTitle: PropTypes.bool,
    loadOnClick: PropTypes.bool,
}

export default CardList
