import { hasValue } from '../../utils/NumberUtil'

export default class DtoJobExecution {
    constructor(obj) {
        this.id = obj.id
        this.date = obj.date
        this.duration = obj.duration
        this.status = hasValue(obj.statusCode) ? obj.statusCode : obj.status
        this.statusCode = obj.statusCode
        this.jobId = obj.jobId
        this.headers = ['id', 'date', 'duration', 'status']
    }
}
