import React, { useMemo } from 'react'
import Card from 'components/card/Card'
import Input from 'components/forms/Input'
import Textarea from 'components/forms/Textarea'
import DtoAgriTanksType from 'exploitations/dto/DtoAgriTanksType'
import PropTypes from 'prop-types'
import { shallowEqual, useSelector } from 'react-redux'
import DtoSandreCode from 'referencial/dto/DtoSandreCode'
import i18n from 'simple-react-i18n'
import DtoCasingDeclaration from 'survey/dto/DtoCasingDeclaration'
import { secondaryBlue } from 'utils/constants/ColorTheme'
import { hasValue } from 'utils/NumberUtil'
import { formatMilliers, getSandreLabel } from 'utils/StringUtil'
import useSandreList from 'utils/customHook/useSandreList'

const RetenueCard = ({
    title,
    actions,
    round,
    retenue,
    className,
    style = {},
}) => {
    const {
        sandreCodes,
        agriTanksTypes,
    } = useSelector(store => ({
        sandreCodes: store.ReferencialReducer.sandreCodes,
        agriTanksTypes: store.AgriReducer.agriTanksTypes,
    }), shallowEqual)

    const getSelectedPeriod = (idPeriode, tankPeriodes) => {
        return !!tankPeriodes.find((p) => p.idNbPeriode === idPeriode)
    }

    const tankType = useMemo(() => agriTanksTypes.find((t) => t.id === retenue.tankType) || {}, [agriTanksTypes, retenue])
    const periodesDetails = useSandreList(sandreCodes, 'USAGES.PERIODES_DETAILS')
    const {
        saisons,
        fillingPeriod,
    } = useMemo(() => {
        const saisonnal = (retenue.link_periodes || []).find((p) => p.idPeriode === 2)
        const yearly = (retenue.link_periodes || []).find((p) => p.idPeriode === 1)
        let res = ''
        if (saisonnal) {
            periodesDetails.map((p) => {
                if (getSelectedPeriod(p.code, retenue.link_periodes)) {
                    res = `${res}${p.name}, `
                }
            })
        }
        const unknown = (retenue.link_periodes || []).find((p) => p.idPeriode === 0)
        return {
            saisons: res,
            fillingPeriod: saisonnal ? 2 : (yearly ? 1 : (hasValue(unknown) ? 0 : null)),
        }
    }, [retenue, periodesDetails])

    return (
        <Card
            title={title}
            headerStyle={{ backgroundColor: secondaryBlue, ...style }}
            actions={actions}
            round={round}
            className={className}
        >
            <div className='row no-margin padding-bottom-1' style={{
                border: `1px solid ${secondaryBlue}`,
                borderWidth: '0 2 2 2',
            }}
            >
                <Input
                    col={12}
                    className='padding-left-1 padding-top-1'
                    title={i18n.detentionType}
                    value={tankType.name}
                    readMode
                />
                <Input
                    col={12}
                    className='padding-left-1 padding-top-1'
                    title={i18n.capability}
                    value={`${formatMilliers(retenue.capacity) || 0} m3` || ''}
                    readMode
                />
                <Input
                    col={12}
                    className='padding-left-1 padding-top-1'
                    title={i18n.recoveryFlow}
                    value={retenue.recoveryFlow}
                    readMode
                />
                <Input
                    col={12}
                    className='padding-left-1 padding-top-1'
                    title={i18n.reserveFlow}
                    value={retenue.reserveFlow}
                    readMode
                />
                <Input
                    col={12}
                    className='padding-left-1 padding-top-1'
                    title={i18n.fillingMode}
                    value={getSandreLabel(sandreCodes, 'PREL_AGRI.MODE_REMPLISSAGE', retenue.fillingMode)}
                    readMode
                />
                <Input
                    col={12}
                    className='padding-left-1 padding-top-1'
                    title={i18n.fillingPeriod}
                    value={`${getSandreLabel(sandreCodes, 'PREL_AGRI.PERIODE_REMPLISSAGE', fillingPeriod) || ''}${saisons.length ? ` (${saisons.slice(0, -2)})` : ''}`}
                    readMode
                />
                <Input
                    col={12}
                    className='padding-left-1 padding-top-1'
                    title={i18n.shared}
                    value={retenue.sharedRetention ? i18n.yes : i18n.no}
                    readMode
                />
                <Input
                    col={12}
                    className='padding-left-1 padding-top-1'
                    title={i18n.usedPercentage}
                    value={retenue.usedPercentage}
                    readMode
                />
                <Textarea
                    col={12}
                    className='padding-left-1 padding-top-1'
                    title={i18n.comment}
                    value={retenue.comment}
                    readMode
                />
            </div>
        </Card>
    )
}

RetenueCard.propTypes = {
    title: PropTypes.string.isRequired,
    retenue: PropTypes.instanceOf(DtoCasingDeclaration).isRequired,
    actions: PropTypes.arrayOf(PropTypes.shape({
        onClick: PropTypes.func,
        iconName: PropTypes.string,
    })),
    sandreCodes: PropTypes.arrayOf(PropTypes.instanceOf(DtoSandreCode)),
    agriTanksTypes: PropTypes.arrayOf(PropTypes.instanceOf(DtoAgriTanksType)),
    round: PropTypes.bool,
    style: PropTypes.shape({}),
    className: PropTypes.string,
}

RetenueCard.defaultProps = {
    editable: false,
    retenue: {},
}

export default RetenueCard