export default class DtoGenericMeasure {
    constructor(obj) {
        this.objectType = obj.objectType
        this.objectId = obj.objectId
        this.measureType = obj.measureType
        this.date = obj.date
        this.value = obj.value
        this.valueStr = obj.valueStr
        this.initialPoint = obj.initialPoint
        this.updateDate = obj.updateDate
        this.updateLogin = obj.updateLogin
        this.value2 = obj.value2
        this.valueStr2 = obj.valueStr2
    }
}