import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import Checkbox from '../../../../../components/forms/Checkbox'
import NumberField from '../../../../../components/forms/NumberField'
import Select from '../../../../../components/forms/Select'
import DtoAquifer from '../../../../../referencial/components/aquifers/dto/DtoAquifer'
import { SANDRE } from '../../../../../referencial/constants/ReferencialConstants'
import DtoSandreCode from '../../../../../referencial/dto/DtoSandreCode'
import { arrayOf, getSandreList } from '../../../../../utils/StoreUtils'
import DtoBoreholeCasing from '../dto/DtoBoreholeCasing'

class BoreholeCasingModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            boreholeCasing: { ...props.boreholeCasing, idStation: props.id },
        }
    }

    componentDidUpdate(prevProps) {
        if (!prevProps.popupIsOpen && this.props.popupIsOpen) {
            $('#descriptionModal').on('click', this.onSave)
        }
    }
    onSave = () => {
        this.props.saveResult(new DtoBoreholeCasing(this.state.boreholeCasing))
        $('.modal').modal('close')
    }

    onChangeElement = (changes) => this.setState({ boreholeCasing: { ...this.state.boreholeCasing, ...changes } })

    render() {
        const { boreholeCasing } = this.state
        return (
            <div>
                <div className='row no-margin padding-top-1'>
                    <NumberField col={ 4 } value={ boreholeCasing.order } title={ i18n.order } onChange={ v => this.onChangeElement({ order: v }) } />
                    <NumberField col={ 4 } value={ boreholeCasing.startDepth } title={ i18n.startDepth } onChange={ v => this.onChangeElement({ startDepth: v }) } floatValue/>
                    <NumberField col={ 4 } value={ boreholeCasing.endDepth } title={ i18n.endDepth } onChange={ v => this.onChangeElement({ endDepth: v }) } floatValue/>
                </div>
                <div className='row no-margin'>
                    <Select col={ 6 } value={ boreholeCasing.aquiferCode } label={ i18n.aquifer } options={ this.props.aquifers }
                        onChange={ v => this.onChangeElement({ aquiferCode: v }) }
                    />
                    <NumberField col={ 3 } value={ boreholeCasing.aquiferRoof } title={ i18n.aquiferRoof } onChange={ v => this.onChangeElement({ aquiferRoof: v }) } floatValue/>
                    <NumberField col={ 3 } value={ boreholeCasing.aquiferFloor } title={ i18n.aquiferFloor } onChange={ v => this.onChangeElement({ aquiferFloor: v }) } floatValue/>
                </div>
                <div className='row no-margin'>
                    <NumberField col={ 3 } value={ boreholeCasing.internalCasingDiameter } title={ i18n.internalCasingDiameter } onChange={ v => this.onChangeElement({ internalCasingDiameter: v }) } floatValue/>
                    <NumberField col={ 3 } value={ boreholeCasing.externalCasingDiameter } title={ i18n.externalCasingDiameter } onChange={ v => this.onChangeElement({ externalCasingDiameter: v }) } floatValue/>
                    <Select col={ 3 } value={ boreholeCasing.materialCode } label={ i18n.casingMaterial } options={ getSandreList(this.props.sandreCodes, SANDRE.CASING_MATERIAL) }
                        integerValue onChange={ v => this.onChangeElement({ materialCode: v }) }
                    />
                    <Checkbox componentClassName='padding-top-1' col={ 3 } checked={ boreholeCasing.strainers } label={ i18n.strainers } onChange={ v => this.onChangeElement({ strainers: v }) } />
                </div>
                <div className='row no-margin'>
                    <NumberField col={ 3 } value={ boreholeCasing.column } title={ i18n.column } onChange={ v => this.onChangeElement({ column: v }) } />
                    <Checkbox componentClassName='padding-top-1' col={ 3 } checked={ boreholeCasing.cementing } label={ i18n.cementing } onChange={ v => this.onChangeElement({ cementing: v }) } />
                </div>
            </div>
        )
    }
}

BoreholeCasingModal.propTypes = {
    boreholeCasing: PropTypes.instanceOf(DtoBoreholeCasing),
    id: PropTypes.number,
    sandreCodes: PropTypes.arrayOf(PropTypes.instanceOf(DtoSandreCode)),
    popupIsOpen: PropTypes.bool,
    saveResult: PropTypes.func,
    aquifers: arrayOf(DtoAquifer),
}


const mapStateToProps = store => ({
    sandreCodes: store.ReferencialReducer.sandreCodes,
    popupIsOpen: store.SieauReducer.popupIsOpen,
    aquifers: store.AquiferReducer.aquifers,
})

BoreholeCasingModal.defaultProps = {
    boreholeCasing: {},
}

export default connect(mapStateToProps)(BoreholeCasingModal)