/* eslint-disable max-nested-callbacks */
import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import { shallowEqual, useSelector } from 'react-redux'
import QualityChart from 'components/echart/QualityChart'
import { getRemarks } from 'utils/AnalyseUtils'
import { RemarkLegend } from 'quality/components/qualityComponents/Legend'
import { isUndefined } from 'lodash'

const HydrobioMonitoringOverlayGraph = ({
    hydrobioLists = [],
    selectedTaxons = [],
}) => {
    const {
        taxons,
    } = useSelector(store => ({
        taxons: store.TaxonReducer.taxons,
    }), shallowEqual)

    const listsFiltered = useMemo(() => {
        if (!selectedTaxons.length) {
            return []
        }
        return hydrobioLists.filter(l => selectedTaxons.includes(l.taxon))
    }, [hydrobioLists, selectedTaxons])

    const ListsFormated = useMemo(() => {
        return selectedTaxons.flatMap(code => {
            const lists = listsFiltered.filter(l => code === l.taxon)
            const taxon = taxons.find(t => t.code === code)
            const taxonLabel = taxon?.latinName || taxon?.code || ''
            return {
                name: taxonLabel,
                dataList: lists.map(l => ({
                    date: l.date,
                    value: l.total,
                    marker: getRemarks(l.remark)?.plot,
                })).filter(a => !isUndefined(a.date) && !isUndefined(a.value)),
            }
        })
    }, [listsFiltered, selectedTaxons, taxons])

    return (
        <>
            <QualityChart
                title={i18n.parameterOverlay}
                grids={[{
                    height: 'auto',
                    bottom: 120,
                }]}
                yAxis={[{
                    name: i18n.enumerations,
                }]}
                series={ListsFormated}

                headerHeight={0}
                footerHeight={120}
                componentHeight={600}

                withToolTypeLine
                withToolTypeBar
                withToolTypeStack
                // withToolThreshold
                withToolMarker
                withToolLine
                withDataZoom

                dataZoomPosition={{ bottom: 40 }}
                legendPosition={{ bottom: 10, right: 20, top: undefined }}
                toolboxPosition={{ top: 10, right: 60 }}
            />
            <div style={{ padding: '0px 20px' }}>
                <RemarkLegend list={listsFiltered} col={2}/>
            </div>
        </>
    )
}

HydrobioMonitoringOverlayGraph.propTypes = {
    hydrobioLists: PropTypes.arrayOf(PropTypes.shape({
        // DtoHydrobioList
        date: PropTypes.number,
        support: PropTypes.string,
        total: PropTypes.number,
    })),
    selectedTaxons: PropTypes.arrayOf(PropTypes.string),
}

export default HydrobioMonitoringOverlayGraph