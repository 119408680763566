export default class DtoHypeRupture {
    constructor(obj) {
        this.statisticsType = obj.statisticsType
        this.preValue = obj.preValue
        this.preCoefficient = obj.preCoefficient
        this.breakDate = obj.breakDate
        this.postValue = obj.postValue
        this.postCoefficient = obj.postCoefficient
        this.stationCode = obj.stationCode
        this.parameter = obj.parameter
        this.unit = obj.unit
    }
}