import PropTypes from 'prop-types'
import React from 'react'
import i18n from 'simple-react-i18n'
import { sieauTooltip } from '../../utils/FormUtils'

const Import = ({
    onClick = () => { },
}) => (
    <div className='action-wrapper'>
        <a
            className={'right waves-effect nav-actions blue-arrow'}
            {...sieauTooltip(i18n.importFile, 'import_action_navbar', 'bottom')}
            onClick={onClick}
        >
            <i className='material-icons left no-margin'>file_upload</i>
        </a>
    </div>
)

Import.propTypes = {
    onClick: PropTypes.func,
}

export default Import