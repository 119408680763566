import React, { useEffect, useMemo, useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import Card from '../../../components/card/Card'
import i18n from 'simple-react-i18n'
import ProgressCard from '../../../components/card/ProgressCard'
import Select from '../../../components/forms/Select'
import SimpleDatePicker from '../../../components/forms/SimpleDatePicker'
import { Button, Grid } from '@mui/material'
import MultiAutocomplete from 'components/forms/MultiAutocomplete'

const InstallationCounterCriteriaPanel = ({
    searchData = () => { },
    searching = false,
    filter = {},
}) => {
    const {
        installationsTypes,
        parameters,
        installationAnalysisParameters,
    } = useSelector(store => ({
        installationsTypes: store.InstallationReducer.installationsTypes,
        parameters: store.ParameterReducer.parameters,
        installationAnalysisParameters: store.InstallationReducer.installationAnalysisParameters,
    }), shallowEqual)

    const [startDate, setStartDate] = useState()
    const [endDate, setEndDate] = useState()
    const [installationType, setInstallationType] = useState()
    const [selectedParameters, setSelectedParameters] = useState()

    useEffect(() => {
        setStartDate(filter.startDate)
        setEndDate(filter.endDate)
        setInstallationType(filter.installationType)
        setSelectedParameters(filter.parameters)
    }, [filter])

    const searchCounter = () => {
        searchData({ installationType, startDate, endDate, parameters: selectedParameters })
    }

    const filteredParameters = useMemo(() => {
        return parameters.filter(p => installationAnalysisParameters.some(iap => iap === p.code))
    }, [installationAnalysisParameters, parameters])

    return (
        <div>
            <div className='row no-margin'>
                <Card className='padding-1'>
                    <Grid container >
                        <Select
                            col={6}
                            options={installationsTypes.filter(it => it.display)}
                            noNullValue
                            onChange={setInstallationType}
                            label={i18n.installationType}
                            value={installationType}
                            keyLabel='name'
                            keyValue='code'
                        />
                        <MultiAutocomplete
                            col={6}
                            elements={filteredParameters}
                            label={i18n.parameters}
                            values={selectedParameters}
                            onChange={setSelectedParameters}
                            nullLabel='&nbsp;'
                            displayWithCode
                        />
                        <SimpleDatePicker
                            col={6}
                            label={i18n.startDate}
                            onChange={setStartDate}
                            id='startDate'
                            value={startDate}
                            max={endDate}
                        />
                        <SimpleDatePicker
                            col={6}
                            label={i18n.endDate}
                            onChange={setEndDate}
                            id='endDate'
                            value={endDate}
                            min={startDate}
                        />
                        <Grid container justifyContent='flex-end' className='padding-1'>
                            <Button variant='contained' onClick={searchCounter}>
                                {i18n.search}
                            </Button>
                        </Grid>
                    </Grid>
                </Card>
            </div>
            <div className='row no-margin'>
                {searching && <ProgressCard indeterminate />}
            </div>
        </div>
    )
}

InstallationCounterCriteriaPanel.propTypes = {
    searchData: PropTypes.func,
    searching: PropTypes.bool,
    filter: PropTypes.object,
}

export default InstallationCounterCriteriaPanel