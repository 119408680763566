import { createAsyncThunk } from '@reduxjs/toolkit'
import ApplicationConf from 'conf/ApplicationConf'
import LogAction from 'log/actions/LogAction'
import { genericPromise } from 'utils/ActionUtils'
import i18n from 'simple-react-i18n'
import ToastrAction from 'toastr/actions/ToastrAction'

const MaterielThunk = {
    fetchSettingRules: createAsyncThunk(
        'fetchSettingRules',
        async (_, { rejectWithValue, dispatch }) => {
            try {
                const response = await genericPromise(ApplicationConf.materiel.settingRule(), 'GET')
                return response
            } catch (err) {
                dispatch(LogAction.logError(`${i18n.fetchError} ${i18n.saveRule} : ${err}`))
                dispatch(ToastrAction.error(`${i18n.fetchError} ${i18n.saveRule}`))
                return rejectWithValue(err)
            }
        },
        {
            condition: (arg = { forceFetch: false }, { getState }) => {
                if (arg.forceFetch) {
                    return true
                }
                const state = getState()
                const { settingRulesStatus } = state.DataManagerReducer.materiel
                return settingRulesStatus === 'idle'
            },
        },
    ),
    saveSettingRules: createAsyncThunk(
        'saveSettingRules',
        async (settingRules, { rejectWithValue, dispatch }) => {
            try {
                const result = await genericPromise(ApplicationConf.materiel.settingRule(), 'POST', settingRules)
                if (result.insert > 0) {
                    dispatch(ToastrAction.success(i18n.saveSuccess))
                    dispatch(MaterielThunk.fetchSettingRules({ forceFetch: true }))
                }
                return result
            } catch (err) {
                dispatch(LogAction.logError(`${i18n.saveError} ${i18n.saveRule} : ${err}`))
                dispatch(ToastrAction.error(`${i18n.saveError} ${i18n.saveRule}`))
                return rejectWithValue(err)
            }
        },
    ),
}

export default MaterielThunk