import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { hasValue } from 'utils/NumberUtil'
import SuperMultiAutocomplete from '../SuperMultiAutocomplete'
import SectorAction from 'referencial/components/sector/actions/SectorAction'

const AssociatedSectorSelect = ({
    col,
    stationTypeReturn,
    value,
    onChange = () => { },
    disabled = false,
}) => {
    const dispatch = useDispatch()

    const {
        sectors,
        qualitometersAssociations,
    } = useSelector(store => ({
        sectors: store.SectorReducer.sectors,
        qualitometersAssociations: store.SectorReducer.qualitometersAssociations,
    }), shallowEqual)

    const [station, setStation] = useState(value)
    const [associatedStation, setAssociatedStation] = useState([])

    useEffect(() => {
        dispatch(SectorAction.fetchSectors())
    }, [])

    useEffect(() => {
        if (station) {
            dispatch(SectorAction.fetchQualitometersAssociations(station))
        } else {
            setAssociatedStation([])
        }
    }, [dispatch, station, stationTypeReturn])

    useEffect(() => {
        setAssociatedStation(qualitometersAssociations)
    }, [qualitometersAssociations])

    useEffect(() => {
        onChange(associatedStation, station)
    }, [associatedStation])

    const fullLabel = useMemo(() => {
        if (!hasValue(station) || !associatedStation) {
            return i18n.sector
        }
        if (associatedStation.length <= 1) {
            return `${i18n.sector} (${associatedStation.length} ${i18n.station})`
        }
        return `${i18n.sector} (${associatedStation.length} ${i18n.stations})`
    }, [associatedStation?.length, station])

    return (
        <SuperMultiAutocomplete
            col={col}
            options={sectors}
            values={station}
            label={fullLabel}
            onChange={setStation}
            disabled={disabled}
            keyValue='id'
            displayWithCode
        />
    )
}

AssociatedSectorSelect.propTypes = {
    col: PropTypes.number,
    stationTypeReturn: PropTypes.number,
    value: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
    ]),
    disabled: PropTypes.bool,
    onChange: PropTypes.func, // (list, id) => {}
}

export default AssociatedSectorSelect