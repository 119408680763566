import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Grid } from '@mui/material'
import ProdUnitLinkType from './ProdUnitLinkType'
import { shallowEqual, useSelector } from 'react-redux'
import { groupBy, orderBy } from 'lodash'
import { PRODUCTION_UNIT_LINK_TYPES } from 'productionUnit/constants/ProductionUnitConstants'
import { BASE64_IMAGE } from 'station/constants/StationConstants'
import DtoProductionUnitLink from 'productionUnit/dto/DroProductionUnitLink'

const ProductionUnitLinks = ({
    readMode = false,
    stateLinks = [],
    dispatchStateLinks = [],
}) => {
    const {
        prodUnitLinks,
    } = useSelector(store => ({
        prodUnitLinks: store.ProductionUnitReducer.prodUnitLinks,
    }), shallowEqual)

    const data = useMemo(() => {
        const updatedDataLinks = [
            ...prodUnitLinks.filter((link) => !stateLinks.deletedLinks.some(dl => dl.siteCode === link.siteCode && dl.linkType === link.linkType)),
            ...stateLinks.addedLinks,
        ]
        return groupBy(orderBy(updatedDataLinks.map(d => ({
            ...d,
            nullLabel: (<img src={BASE64_IMAGE[d.siteType]?.replace('image://', '')}
                style={{ maxHeight: '20px' }}
            />),
        })), 'siteType'), 'linkType')
    }, [prodUnitLinks, stateLinks])

    const resourcesData = useMemo(() => {
        return data[PRODUCTION_UNIT_LINK_TYPES.RESOURCES] || []
    }, [data])

    const monitoringData = useMemo(() => {
        return data[PRODUCTION_UNIT_LINK_TYPES.MONITORING] || []
    }, [data])

    const productionData = useMemo(() => {
        return data[PRODUCTION_UNIT_LINK_TYPES.PRODUCTION] || []
    }, [data])

    const jobData = useMemo(() => {
        return data[PRODUCTION_UNIT_LINK_TYPES.JOB] || []
    }, [data])

    const distributionData = useMemo(() => {
        return data[PRODUCTION_UNIT_LINK_TYPES.DISTRIBUTION] || []
    }, [data])

    const udiData = useMemo(() => {
        return data[PRODUCTION_UNIT_LINK_TYPES.UDI] || []
    }, [data])

    const params = {
        readMode,
        dispatchStateLinks,
    }

    return (
        <>
            <Grid className='margin-top-1'>
                <ProdUnitLinkType data={resourcesData} linkType={PRODUCTION_UNIT_LINK_TYPES.RESOURCES} {...params}/>
            </Grid>
            <Grid className='margin-top-1'>
                <ProdUnitLinkType data={monitoringData} linkType={PRODUCTION_UNIT_LINK_TYPES.MONITORING} {...params} />
            </Grid>
            <Grid className='margin-top-1'>
                <ProdUnitLinkType data={productionData} linkType={PRODUCTION_UNIT_LINK_TYPES.PRODUCTION} {...params} />
            </Grid>
            <Grid className='margin-top-1'>
                <ProdUnitLinkType data={jobData} linkType={PRODUCTION_UNIT_LINK_TYPES.JOB} {...params}/>
            </Grid>
            <Grid className='margin-top-1'>
                <ProdUnitLinkType data={distributionData} linkType={PRODUCTION_UNIT_LINK_TYPES.DISTRIBUTION} {...params}/>
            </Grid>
            <Grid className='margin-top-1'>
                <ProdUnitLinkType data={udiData} linkType={PRODUCTION_UNIT_LINK_TYPES.UDI} {...params}/>
            </Grid>
        </>
    )
}

ProductionUnitLinks.propTypes = {
    readMode: PropTypes.bool,
    stateLinks: PropTypes.arrayOf(PropTypes.instanceOf(DtoProductionUnitLink)),
    dispatchStateLinks: PropTypes.func,
}

export default ProductionUnitLinks