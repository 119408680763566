import React, { Component } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { sieauTooltip } from '../../utils/FormUtils'

export default class IAction extends Component {
    notifs() {
        return ''
    }

    content() {
        return ''
    }

    name() {
        return ''
    }
    id() {
        return uuidv4()
    }


    render() {
        const dropDown = this.content() ? 'dropdown-button' : ''
        const tooltip = this.label() ? sieauTooltip(this.label(), this.id(), 'bottom') : null
        return (
            <div className='action-wrapper'>
                <a
                    className={`right waves-effect nav-actions blue-arrow ${dropDown}`}
                    data-activates={this.name()} { ...tooltip }
                    onClick={ this.fn() }
                    id={this.id()}
                    data-cy={this.id()}
                >
                    <i className='material-icons left no-margin' id={this.icon()} >{ this.icon() }</i>
                    { this.notifs() }
                </a>
                { this.content() }
            </div>
        )
    }
}
