import React from 'react'
import PropTypes from 'prop-types'
import { shallowEqual, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import DtoInstallation from '../../../dto/installation/DtoInstallation'
import { arrayOf, getSandreList } from '../../../../utils/StoreUtils'
import Row from '../../../../components/react/Row'
import InstallationProtectionsPanel from './InstallationProtectionsPanel'
import InstallationAccessTypePanel from './InstallationAccessTypePanel'
import Checkbox from '../../../../components/forms/Checkbox'
import DtoSandreCode from '../../../../referencial/dto/DtoSandreCode'
import { SANDRE } from '../../../../referencial/constants/ReferencialConstants'
import Textarea from '../../../../components/forms/Textarea'
import Input from '../../../../components/forms/Input'
import Card from '../../../../components/card/Card'
import { getVisitCheckBoxChange, getVisitSandreSelectChange } from '../../../../utils/VisitUtils'
import Select from '../../../../components/forms/Select'
import { greyBlue } from 'utils/constants/ColorTheme'

const InstallationAccessCasingPanel = ({
    onChange = () => { },
    onChangeVisit = () => { },
    station = {},
    readMode = false,
}) => {
    const {
        sandreCodes,
    } = useSelector(store => ({
        sandreCodes: store.ReferencialReducer.sandreCodes,
    }), shallowEqual)

    const onChangeAccessCasing = (value) => {
        const accessCasing = station.link_accessCasings[0] || {}
        onChange({
            link_accessCasings: [{
                idStation: station.id,
                ...accessCasing,
                ...value,
            }],
        })
    }

    const onChangePrevention = (value) => {
        const preventions = station.link_preventions || {}

        const newPreventions = preventions && preventions.filter(p => p.code === value).length
            ? preventions.filter(p => p.code !== value)
            : [
                ...preventions,
                {
                    idStation: station.id,
                    code: value,
                },
            ]

        onChange({
            link_preventions: newPreventions,
        })
    }

    const getPreventions = () => {
        const list = getSandreList(sandreCodes, SANDRE.INSTALLATIONS_AUTREPROTECTION)

        return list.map(sandre => {
            const prevention = station.link_preventions.filter(d => d.code === sandre.code).length || false
            return (
                <Checkbox
                    col={ 6 }
                    checked={ prevention }
                    label={ sandre.name }
                    onChange={ () => {
                        onChangePrevention(sandre.code)
                        onChangeVisit(getVisitCheckBoxChange(sandre.name, prevention, !prevention))
                    } }
                    disabled={ readMode }
                />
            )
        })
    }

    const accessCasing = station.link_accessCasings[0] || {}

    return (
        <div className='row no-margin padding-top-1'>
            <Row className={ 'padding-1' }>
                <InstallationAccessTypePanel onChange={ onChange } station={ station } readMode={ readMode } />
            </Row>
            <Row className={ `${!readMode ? 'padding-bottom-1' : ''}` }>
                <Checkbox
                    col={ 6 }
                    checked={ accessCasing.lockedAccess }
                    label={ i18n.lockedAccess }
                    onChange={ v => {
                        onChangeAccessCasing({ lockedAccess: v })
                        onChangeVisit(getVisitCheckBoxChange(i18n.lockedAccess, accessCasing.lockedAccess, v))
                    } }
                    disabled={ readMode }
                />
                <Checkbox
                    col={ 6 }
                    checked={ accessCasing.differentsKeysAccess }
                    label={ i18n.differentsKeysAccess }
                    onChange={ v => {
                        onChangeAccessCasing({ differentsKeysAccess: v })
                        onChangeVisit(getVisitCheckBoxChange(i18n.differentsKeysAccess, accessCasing.differentsKeysAccess, v))
                    } }
                    disabled={ readMode }
                />
            </Row>
            <Row>
                <Select
                    col={ 6 }
                    value={ accessCasing.closeType }
                    label={ i18n.closeType }
                    keyvalue='code'
                    integerValue
                    onChange={ v => {
                        onChangeAccessCasing({ closeType: v })
                        onChangeVisit(getVisitSandreSelectChange(sandreCodes, SANDRE.INSTALLATIONS_TYPEVERROUILLAGE, i18n.closeType, accessCasing.closeType, v))
                    } }
                    options={ getSandreList(sandreCodes, SANDRE.INSTALLATIONS_TYPEVERROUILLAGE) }
                    readMode={ readMode }
                />
            </Row>
            <Row>
                <Input
                    col={ 6 }
                    title={ i18n.closingBrand }
                    value={ accessCasing.closingBrand }
                    onChange={ v => {
                        onChangeAccessCasing({ closingBrand: v })
                        onChangeVisit({ previousValue: accessCasing.closingBrand, newValue: v, field: `${i18n.casingAccess} - ${i18n.closingBrand}` })
                    } }
                    readMode={ readMode }
                />
                <Input
                    col={ 6 }
                    title={ i18n.closingModel }
                    value={ accessCasing.closingModel }
                    onChange={ v => {
                        onChangeAccessCasing({ closingModel: v })
                        onChangeVisit({ previousValue: accessCasing.closingModel, newValue: v, field: `${i18n.casingAccess} - ${i18n.closingModel}` })
                    } }
                    readMode={ readMode }
                />
            </Row>
            <Row>
                <Textarea
                    col={ 12 }
                    title={ i18n.description }
                    value={ accessCasing.description }
                    onChange={ v => {
                        onChangeAccessCasing({ description: v })
                        onChangeVisit({ previousValue: accessCasing.description, newValue: v, field: i18n.description })
                    } }
                    readMode={ readMode }
                />
            </Row>
            <Row>
                <Checkbox
                    col={6}
                    checked={accessCasing.antiintrusion}
                    label={i18n.antiintrusion}
                    onChange={v => {
                        onChangeAccessCasing({ antiintrusion: v })
                        onChangeVisit(getVisitCheckBoxChange(i18n.antiintrusion, accessCasing.antiintrusion, v))
                    }}
                    disabled={readMode}
                />
                <Select
                    col={6}
                    label={i18n.deconnexion}
                    options={getSandreList(sandreCodes, SANDRE.INSTALLATIONS_DECONNEXION)}
                    value={accessCasing.deconnexion}
                    onChange={v => {
                        onChangeAccessCasing({ deconnexion: v })
                        onChangeVisit(getVisitSandreSelectChange(sandreCodes, SANDRE.INSTALLATIONS_DECONNEXION, i18n.deconnexion, accessCasing.deconnexion, v))
                    }}
                    readMode={readMode}
                />
            </Row>
            <Row className={ 'padding-1 no-padding-bottom' }>
                <InstallationProtectionsPanel onChange={ onChange } station={ station } readMode={ readMode } />
            </Row>
            <Card title={i18n.otherSystem} headerStyle={{ backgroundColor: greyBlue }} className='padding-1'>
                <Row className={ `${!readMode ? 'padding-bottom-1' : ''}` }>
                    { getPreventions() }
                </Row>
            </Card>
        </div>
    )
}

InstallationAccessCasingPanel.propTypes = {
    station: PropTypes.instanceOf(DtoInstallation),
    sandreCodes: arrayOf(DtoSandreCode),
    readMode: PropTypes.bool,
    onChange: PropTypes.func,
    onChangeVisit: PropTypes.func,
    setPopup: PropTypes.func,
    changeBssCode: PropTypes.func,
}


export default InstallationAccessCasingPanel
