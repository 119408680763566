import DtoLocation from '../../station/dto/DtoLocation'

export default class DtoPiezometerWithCampaignsAndEvents {
    constructor(obj = {}) {
        this.id = obj.id
        this.code = obj.code
        this.name = obj.name
        this.typeName = 'piezometry'
        this.natureCode = obj.natureCode
        this.creationDate = obj.creationDate
        this.closeDate = obj.closeDate
        this.x = obj.xCoordinate
        this.y = obj.yCoordinate
        this.projection = obj.projectionType
        this.altitude = obj.altitude
        this.cote = obj.cote
        this.fieldMode = obj.fieldMode
        this.comment = obj.comment
        this.townCode = obj.townCode
        this.contactCode = obj.contactCode
        this.designation = obj.designation
        this.waterSourceType = obj.waterSourceType
        this.watershedCode = obj.watershedCode
        this.address = obj.address
        this.localisation = obj.localisation
        this.countryCode = obj.countryCode
        this.internQualitoCode = obj.internQualitoCode
        this.stateCode = obj.stateCode
        this.jobExecutionId = obj.jobExecutionId
        this.updateLogin = obj.updateLogin
        this.localisation = new DtoLocation({
            x: obj.xCoordinate,
            y: obj.yCoordinate,
            projection: obj.projectionType,
        }, obj.altitude)
        this.updateDate = obj.updateDate
        this.previsionalVisitNumber = obj.previsionalVisitNumber
        this.declarationTownCode = obj.declarationTownCode
        this.currentCampaign = obj.currentCampaign
        this.currentComments = obj.currentComments
        this.currentMonitoring = obj.currentMonitoring
    }
}
