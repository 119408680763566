import DtoDistributionUnit from 'distributionUnit/dto/DtoDistributionUnit'
import DtoInstallation from 'installation/dto/installation/DtoInstallation'
import { pick, sortBy } from 'lodash'
import DtoProductionUnit from 'productionUnit/dto/DtoProductionUnit'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import ResourceDto from 'resource/dto/ResourceDto'
import i18n from 'simple-react-i18n'
import AdministrationAction from '../../../administration/actions/AdministrationAction'
import UserAction from '../../../administration/components/user/actions/UserAction'
import PiezometryAction from '../../../piezometry/actions/PiezometryAction'
import DtoPiezometerLight from '../../../piezometry/dto/DtoPiezometerLight'
import PluviometerDto from '../../../pluviometry/dto/PluviometerDto'
import QualityAction from '../../../quality/actions/QualityAction'
import DtoQualitometerLight from '../../../quality/dto/DtoQualitometerLight'
import DtoQualitometerListSpecific from '../../../quality/dto/DtoQualitometerListSpecific'
import StationAction from '../../../station/actions/StationAction'
import AppStore from '../../../store/AppStore'
import { hasValue } from '../../../utils/NumberUtil'
import { execByType, getStationFilterByModule } from '../../../utils/StationUtils'
import { arrayOf, getMapStateToProps, getPropTypes } from '../../../utils/StoreUtils'
import { getUserBookmarksByStationType } from '../../../utils/UserUtil'
import Select from '../Select'

const propsToFetch = {
    filters: false,
    filterResults: false,
    hydrometricStations: false,
}

// !depracated
class FilterSelect extends Component {
    constructor(props) {
        super(props)
        const cache = pick(AdministrationAction.getCache(props.stationType), ['filter'])
        cache.filter = props.value || cache.filter || -1
        this.state = { filter: -1, filterResults: props.filterResults || [], ...cache }
    }

    componentDidMount() {
        this.props.fetchBookmarks()
        this.props.fetchFilters()
        if (this.props.stationType === 'piezometry' && !this.props.piezometers.length) {
            AppStore.dispatch(PiezometryAction.fetchPiezometersLight())
        }
        if (this.props.stationType === 'quality' && !this.props.qualitometers.length) {
            AppStore.dispatch(QualityAction.fetchQualitometersLight())
        }
        if (hasValue(this.props.value)) {
            this.onChangeFilter(this.props.value)
        }
    }

    setStationsResults = () => {
        const results = this.getStationResults()
        this.setState({ filterResults: results })
        AppStore.dispatch(AdministrationAction.setCache(this.props.stationType, { filter: this.state.filter }))
        this.props.onChange(results, this.state.filter)
    }

    onChangeFilter = (value) => {
        const usedValue = hasValue(value) ? value : -1

        AppStore.dispatch(AdministrationAction.setCache(this.props.stationType, { filter: usedValue }))
        this.setState({ filter: usedValue }, () => {
            if (usedValue < 0) { // favoris
                this.setStationsResults()
            } else { // autre filtres
                const filters = getStationFilterByModule(this.props.filters, this.props.stationType)
                const foundFilter = filters.find(f => f.code == usedValue)
                if (foundFilter) {
                    this.props.fetchFilterResults(filters.find(f => f.code == usedValue)).then(() => this.setStationsResults())
                }
            }
        })
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.value !== nextProps.value && nextProps.value !== this.state.filter) {
            this.onChangeFilter(nextProps.value)
        }
    }

    getStationResults = () => {
        const { filterResults } = this.props
        const filterResultsId = filterResults.map(fr => fr.id)
        if (this.state.filter === -1) { // pas de filtre
            return []
        }
        if (this.state.filter === -5) { // filtre "favoris"
            const codes = getUserBookmarksByStationType(this.props.userBookmarks, this.props.stationType).map(c => c.toUpperCase())
            return execByType(this.props.stationType, {
                quality: () => {
                    const qualitos = this.props.listMode ? this.props.qualitometersList : this.props.qualitometers
                    return codes.map(b => qualitos.find(q => q.code.toUpperCase() === b))
                },
                piezometry: () => codes.map(b => this.props.piezometers.find(q => q.code.toUpperCase() === b)),
                pluviometry: () => codes.map(b => this.props.pluviometers.find(q => q.code.toUpperCase() === b)),
                hydrometry: () => codes.map(b => this.props.hydrometricStations.find(q => q.code.toUpperCase() === b)),
                productionUnit: () => codes.map(b => this.props.productionUnits.find(q => q.code.toUpperCase() === b)),
                distributionUnit: () => codes.map(b => this.props.distributionUnits.find(q => q.code.toUpperCase() === b)),
                installation: () => codes.map(b => this.props.installations.find(q => q.code.toUpperCase() === b)),
                resource: () => codes.map(b => this.props.resources.find(q => q.code.toUpperCase() === b)),
                default: () => [],
            }).filter(s => !!s)
        }
        return execByType(this.props.stationType, { // autres filtres
            quality: () => {
                const qualitos = this.props.listMode ? this.props.qualitometersList : this.props.qualitometers
                return filterResultsId.map(b => qualitos.find(q => q.id === b))
            },
            piezometry: () => filterResultsId.map(b => this.props.piezometers.find(q => q.id === b)),
            pluviometry: () => filterResultsId.map(b => this.props.pluviometers.find(q => q.id === b)),
            hydrometry: () => filterResultsId.map(b => this.props.hydrometricStations.find(q => q.id === b)),
            productionUnit: () => filterResultsId.map(b => this.props.productionUnits.find(q => q.id === b)),
            distributionUnit: () => filterResultsId.map(b => this.props.distributionUnits.find(q => q.id === b)),
            installation: () => filterResultsId.map(b => this.props.installations.find(q => q.id === b)),
            resource: () => filterResultsId.map(b => this.props.resources.find(q => q.id === b)),
            default: () => [],
        }).filter(s => !!s)
    }

    render() {
        const {
            withBookmarks,
            stationType,
            filters,
            col,
            title,
            disabled,
            nullLabel,
        } = this.props

        const {
            filter,
            filterResults,
        } = this.state

        const baseFilterOptions = withBookmarks ? [
            { value: -5, label: i18n.bookmarks },
        ] : []

        const filterOptions = sortBy(getStationFilterByModule(filters, stationType).map(f => ({ value: f.code, label: f.name })), 'label')

        return (
            <Select
                col={col}
                options={[...baseFilterOptions, ...filterOptions]}
                value={filter}
                label={title || `${i18n.filter}${hasValue(filter) && filter != -1 ? `   (${filterResults.length} ${i18n.stations})` : ''}`}
                keyLabel='label'
                keyValue='value'
                integerValue
                returnNull
                nullLabel={nullLabel}
                onChange={f => this.onChangeFilter(f)}
                disabled={disabled}
            />
        )
    }
}


FilterSelect.propTypes = getPropTypes(propsToFetch, {
    pluviometers: PropTypes.arrayOf(PropTypes.instanceOf(PluviometerDto)),
    className: PropTypes.string,
    title: PropTypes.string,
    value: PropTypes.string,
    col: PropTypes.number,
    disabled: PropTypes.bool,
    withBookmarks: PropTypes.bool,
    stationType: PropTypes.string,
    onChange: PropTypes.func,
    piezometers: arrayOf(DtoPiezometerLight),
    qualitometers: arrayOf(DtoQualitometerLight),
    qualitometersList: arrayOf(DtoQualitometerListSpecific),
    installations: PropTypes.arrayOf(PropTypes.instanceOf(DtoInstallation)),
    distributionUnits: PropTypes.arrayOf(PropTypes.instanceOf(DtoDistributionUnit)),
    productionUnits: PropTypes.arrayOf(PropTypes.instanceOf(DtoProductionUnit)),
    resources: PropTypes.arrayOf(PropTypes.instanceOf(ResourceDto)),
    listMode: PropTypes.bool,
    nullLabel: PropTypes.string,
})

FilterSelect.defaultProps = {
    label: '',
    className: '',
    stationType: '',
    withBookmarks: true,
    nullLabel: '',
}

const mapStateToProps = (store) => getMapStateToProps(propsToFetch, {
    pluviometers: store.PluviometryReducer.pluviometers,
    piezometers: store.PiezometryReducer.piezometersLight,
    userBookmarks: store.UserReducer.userBookmarks,
    qualitometers: store.QualityReducer.qualitometersLight,
    qualitometersList: store.QualityReducer.qualitometersListSpecific,
    productionUnits: store.ProductionUnitReducer.productionUnits,
    installations: store.InstallationReducer.installations,
    distributionUnits: store.DistributionUnitReducer.distributionUnits,
    resources: store.ResourceReducer.resources,
})

const mapDispatchToProps = {
    fetchBookmarks: UserAction.fetchBookmarks,
    fetchFilters: StationAction.fetchFilters,
    fetchFilterResults: StationAction.fetchFilterResults,
}

export default connect(mapStateToProps, mapDispatchToProps)(FilterSelect)
