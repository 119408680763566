import { getOptions } from './EChartUtils'
import { round } from '../../utils/NumberUtil'

class _Axis {
    constructor(obj) {
        this.obj = obj
    }

    getDefaultOptions = () => {
        return {
            nameLocation: 'middle',
            data: [],
            nameGap: 30,
            splitLine: {
                show: false,
            },
        }
    }

    updateObj = (changes) => {
        this.obj = { ...this.obj, ...changes }
    }

    getJson = (bandCorrection) => {
        const options = getOptions(this.obj, this.getDefaultOptions(), {
            name: () => ({ name: this.obj.name }),
            type: () => ({ type: this.obj.type }),
            nameLocation: () => ({ nameLocation: this.obj.nameLocation }),
            nameGap: () => ({ nameGap: this.obj.nameGap }),
            data: () => ({ data: this.obj.data }),
            min: () => ({ min: this.obj.min }),
            max: () => ({ max: this.obj.max }),
            showSplitLine: () => ({ splitLine: {
                show: this.obj.showSplitLine,
            } }),
        })
        if (bandCorrection && this.obj.isPiezo && !this.obj.dontUseBandCorrection) {
            options.min = options.min + 1000
            options.max = options.max + 1000
            options.axisLabel = { ...(options.axisLabel || {}), formatter: value => round(value - 1000) }
        }
        return options
    }
}

export default function Axis(args) {
    return new _Axis(args)
}


