export default class DtoUserStationStatisticItem {
    constructor(obj) {
        this.id = obj.id
        this.stationType = obj.stationType
        this.code = obj.code
        this.city = obj.city
        this.nbAccess = obj.nbAccess
        this.lastAccess = obj.lastAccess
        this.headers = ['stationType', 'code', 'city', 'nbAccess', 'lastAccess']
    }
}