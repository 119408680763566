/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { AutoSizer, MultiGrid, CellMeasurerCache, CellMeasurer } from 'react-virtualized'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import { sieauTooltip } from 'utils/FormUtils'
import { orderBy } from 'lodash'

const ASC = 'asc'
const DESC = 'desc'
const NONE = 'none'

const STYLE = {
    border: '1px solid #ddd',
}
const STYLE_BOTTOM_LEFT_GRID = {
    borderRight: '1px solid #000',
    backgroundColor: '#fff',
}
const STYLE_TOP_LEFT_GRID = {
    backgroundColor: '#fff',
    borderBottom: '1px solid #000',
    borderRight: '1px solid #000',
    fontWeight: 'bold',
    fontSize: '16px',
}
const STYLE_TOP_RIGHT_GRID = {
    backgroundColor: '#fff',
    borderBottom: '1px solid #000',
    fontWeight: 'bold',
    fontSize: '16px',
}

const DEFAULT_CELL_STYLE = {
    display: 'flex',
    alignItems: 'center',
    borderRight: '1px solid #eee',
    backgroundColor: '#fff',
    whiteSpace: 'nowrap',
    padding: '0px 10px',
}

const getHeaderLabel = (keyLabel, value, rowIndex) => {
    if (value) {
        return value
    }
    if (rowIndex !== 0) {
        return ''
    }
    if (!/^\d+$/.test(keyLabel) && /^[0-9A-Za-z]+$/.test(keyLabel)) {
        try {
            return i18n[keyLabel]
        } catch (e) {
            return keyLabel
        }
    }
    return keyLabel
}

const MultiGridTable = ({
    data = [],
    customHeaders = {},
    headers = [],

    fixedColumnCount = 0,
    fixedRowCount = 1,

    onClick = () => { },
}) => {
    const [sort, setSort] = useState(() => ({ sortColumn: [], sortDirection: {} }))

    let cellMeasurerCache = useRef(new CellMeasurerCache({
        defaultHeight: 21,
        fixedHeight: true,
    })).current

    let tableRef = useRef().current

    const sortedData = useMemo(() => {
        const {
            sortColumn,
            sortDirection,
        } = sort
        return orderBy(data, sortColumn.map(c => elem => elem[c]?.sortValue ?? elem[c]?.value), sortColumn.map(c => sortDirection[c]))
    }, [data, sort])

    useEffect(() => {
        tableRef?.forceUpdateGrids()
    }, [sortedData])

    return (
        <AutoSizer>
            {({ width, height }) => {
                return !!sortedData.length && (
                    <MultiGrid
                        fixedColumnCount={fixedColumnCount}
                        fixedRowCount={fixedRowCount}

                        cellRenderer={({ columnIndex, key, parent, rowIndex, style }) => {
                            const keyLabel = headers[columnIndex]

                            if (rowIndex < fixedRowCount) {
                                const {
                                    value,
                                    tooltip,
                                    style: customStyle = {},
                                } = customHeaders[rowIndex]?.[keyLabel] || {}
                                const tooltipObj = tooltip ? sieauTooltip(tooltip, null, 'top') : {}
                                return (
                                    <CellMeasurer
                                        cache={cellMeasurerCache}
                                        columnIndex={columnIndex}
                                        key={key}
                                        parent={parent}
                                        rowIndex={rowIndex}
                                    >
                                        <div
                                            className={'white clickable'}
                                            style={{ ...DEFAULT_CELL_STYLE, ...style, justifyContent: 'center', ...customStyle }}
                                            {...tooltipObj}
                                            onClick={() => {
                                                setSort(({ sortDirection, sortColumn }) => {
                                                    switch (sortDirection[keyLabel]) {
                                                        case ASC: return { sortDirection: { ...sortDirection, [keyLabel]: DESC }, sortColumn }
                                                        case DESC: return { sortDirection: { ...sortDirection, [keyLabel]: NONE }, sortColumn: sortColumn.filter(c => c !== keyLabel) }
                                                        default: return { sortDirection: { ...sortDirection, [keyLabel]: ASC }, sortColumn: [...sortColumn, keyLabel] }
                                                    }
                                                })
                                            }}
                                        >
                                            {getHeaderLabel(keyLabel, value, rowIndex)}
                                            {
                                                rowIndex === 0 && sort.sortDirection[keyLabel] === ASC && <i className='material-icons' style={{ fontSize: 18 }}>arrow_drop_up</i>
                                            }
                                            {
                                                rowIndex === 0 && sort.sortDirection[keyLabel] === DESC && <i className='material-icons' style={{ fontSize: 18 }}>arrow_drop_down</i>
                                            }
                                        </div>
                                    </CellMeasurer>
                                )
                            }
                            const row = sortedData[rowIndex - fixedRowCount] // remove headers's row
                            const {
                                [keyLabel]: cell = {},
                            } = row

                            const {
                                value = '',
                                tooltip,
                                justifyContent = 'left',
                                classNameColor = 'white',
                                rightIcon,
                                rightIconSize = '1rem',
                                rightIconColor = 'grey',
                                style: customStyle = {},
                                onClick: onClickCell = onClick,
                            } = cell

                            const tooltipObj = tooltip ? sieauTooltip(tooltip, null, 'top') : {}
                            const styleOveride = {
                                ...style,
                                ...DEFAULT_CELL_STYLE,
                                ...customStyle,
                                justifyContent,
                                borderBottom: '1px solid #eee',
                            }

                            return (
                                <CellMeasurer
                                    cache={cellMeasurerCache}
                                    columnIndex={columnIndex}
                                    key={key}
                                    parent={parent}
                                    rowIndex={rowIndex}
                                >
                                    <div className={`${classNameColor} clickable`} style={styleOveride} onClick={() => onClickCell(row)} {...tooltipObj}>
                                        {value}
                                        {!!rightIcon && <i className='material-icons' style={{ fontSize: rightIconSize, color: rightIconColor }}>{rightIcon}</i>}
                                    </div>
                                </CellMeasurer>
                            )
                        }}

                        height={height}
                        width={width}
                        deferredMeasurementCache={cellMeasurerCache}
                        columnWidth={cellMeasurerCache.columnWidth}
                        columnCount={headers.length}
                        rowHeight={cellMeasurerCache.rowHeight}
                        rowCount={sortedData.length + fixedRowCount} // data + headers

                        enableFixedColumnScroll
                        enableFixedRowScroll

                        style={STYLE}
                        styleBottomLeftGrid={STYLE_BOTTOM_LEFT_GRID}
                        styleTopLeftGrid={STYLE_TOP_LEFT_GRID}
                        styleTopRightGrid={STYLE_TOP_RIGHT_GRID}

                        hideTopRightGridScrollbar
                        hideBottomLeftGridScrollbar

                        ref={ref => {
                            tableRef = ref
                        }}
                    />
                )
            }}
        </AutoSizer>
    )
}

MultiGridTable.propTypes = {
    data: PropTypes.array.isRequired,
    // data: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.shape({
    //     value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.element]),
    //     sortValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    //     classNameColor: PropTypes.string,
    //     tooltip: PropTypes.oneOfType([PropTypes.func, PropTypes.string, PropTypes.element]),
    //     justifyContent: PropTypes.string,
    //     rightIcon: PropTypes.string,
    //     rightIconSize: PropTypes.string,
    //     rightIconColor: PropTypes.string,
    //     style: PropTypes.object,
    // }))).isRequired,
    headers: PropTypes.arrayOf(PropTypes.string).isRequired,
    customHeaders: PropTypes.array,
    // data: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.shape({
    //     value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.element]),
    //     tooltip: PropTypes.oneOfType([PropTypes.func, PropTypes.string, PropTypes.element]),
    //     style: PropTypes.object,
    // }))).isRequired,

    fixedColumnCount: PropTypes.number,
    fixedRowCount: PropTypes.number,
    onClick: PropTypes.func,
}

export default MultiGridTable