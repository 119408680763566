export default class DtoTankCivilEng {
    constructor(obj) {
        this.idStation = obj.idStation // Long,
        this.tankType = obj.tankType // Option[Long],
        this.numberTanks = obj.numberTanks // Option[Int],
        this.natureTanks = obj.natureTanks // Option[Long],
        this.coatingTanks = obj.coatingTanks // Option[Long],
        this.usefulVolume = obj.usefulVolume // Option[Double],
        this.dedicatedVolume = obj.dedicatedVolume // Option[Double],
        this.altiLevelTN = obj.altiLevelTN // Option[Double],
        this.radierTankLevel = obj.radierTankLevel // Option[Double],
        this.totalHeightTour = obj.totalHeightTour // Option[Double],
        this.fillingDevice = obj.fillingDevice // Option[Long],
        this.fillingMode = obj.fillingMode // Option[Long],
        this.fillingType = obj.fillingType // Option[Long],
        this.recoveryFlow = obj.recoveryFlow // Option[Double],
        this.storageType = obj.storageType // Option[Long],
        this.overflowRating = obj.overflowRating // Option[Double],
        this.area = obj.area // Option[Double],
        this.idUsage = obj.idUsage // Option[Long],
        this.strainerRate = obj.strainerRate // Option[Double],
        this.interiorRenovationYear = obj.interiorRenovationYear // Option[Int],
        this.exteriorRenovationYear = obj.exteriorRenovationYear // Option[Int],
        this.tarpType = obj.tarpType // Option[Long]
    }
}