import ExportFileModal from 'components/modal/ExportFileModal'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import i18n from 'simple-react-i18n'
import { sieauTooltip } from 'utils/FormUtils'

class ExportChoice extends Component {
    state = {
        openModal: false,
    }

    componentDidMount() {
        $('.dropdown-button').dropdown()
    }

    componentDidUpdate() {
        $('.dropdown-button').dropdown()
    }

    getModal = (data = {}) => {
        const { openModal } = this.state
        if (openModal) {
            return (
                <ExportFileModal
                    open={openModal}
                    onClose={() => this.setState({ openModal: false })}
                    data={data.exportChoices}
                    closeOnExport
                    groups={data.groups}
                />
            )
        }
        return null
    }

    render() {
        const { data } = this.props
        return (
            <div className='action-wrapper'>
                <a className='right waves-effect nav-actions blue-arrow' {...sieauTooltip(i18n.export, 'export_choice_action_navbar', 'bottom')} onClick={() => this.setState({ openModal: true })}>
                    <i className='material-icons left no-margin'>file_download</i>
                </a>
                { this.getModal(data) }
            </div>
        )
    }
}

ExportChoice.propTypes = {
    data: PropTypes.shape({
        exportChoices: PropTypes.arrayOf(PropTypes.shape({
            name: PropTypes.string,
            group: PropTypes.number,
            settingsContent: PropTypes.element,
            formats: PropTypes.arrayOf(PropTypes.shape({
                type: PropTypes.string,
                callback: PropTypes.func,
            })),
        })).isRequired,
        grouped: PropTypes.bool,
        groups: PropTypes.arrayOf(PropTypes.shape({
            value: PropTypes.number,
            name: PropTypes.string,
        })),
    }).isRequired,
}

export default ExportChoice