'use strict'
import AdministrationAction from 'administration/actions/AdministrationAction'
import { FileIconMUI } from 'components/styled/Buttons'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import DtoPublicPicture from 'station/dto/DtoPublicPicture'
import AppStore from 'store/AppStore'
import ToastrAction from 'toastr/actions/ToastrAction'
import ActionComponent from '../../../components/ActionComponent'
import Card from '../../../components/card/Card'
import Input from '../../../components/forms/Input'
import Select from '../../../components/forms/Select'
import SieauAction from '../../../components/sieau/SieauAction'
import HomeAction from '../../../home/actions/HomeAction'
import CityDto from '../../../referencial/components/city/dto/CityDto'
import ContactDto from '../../../referencial/components/contact/dto/ContactDto'
import { getLogin } from '../../../utils/SettingUtils'
import User from '../../dto/User'

class UpdateUserForm extends ActionComponent {
    constructor(props) {
        super(props)
        this.state = {
            pictureUrl: '',
        }
    }

    componentWillMount() {
        AppStore.dispatch(SieauAction.forceFetch('title', [{
            title: i18n.myAccount,
            href: 'account',
        }]))
    }

    componentDidMount() {
        this.setPictureUrl()
    }

    componentDidUpdate(prevProps) {
        const { profileLoginPicture, profilePicture } = this.props
        if (prevProps.profileLoginPicture.updateDate !== profileLoginPicture.updateDate || prevProps.profilePicture !== profilePicture) {
            this.setPictureUrl()
        }
    }

    setPictureUrl = () => {
        const { profileLoginPicture, profilePicture } = this.props
        if (profilePicture && profilePicture.content) {
            this.setState({ pictureUrl: profilePicture.content })
        } else if (profileLoginPicture.name) {
            this.props.fetchPictureWithoutCache(profileLoginPicture.name).then((url) => {
                this.setState({ pictureUrl: url })
            })
        }
    }

    resetPassword = () => {
        if (!this.props.contact || !this.props.contact.email) {
            AppStore.dispatch(ToastrAction.error(i18n.noEmailWithLogin))
        } else {
            AppStore.dispatch(HomeAction.resetPassword(this.props.accountUser.login, false))
        }
    }

    changeCity = (value) => {
        this.props.changeContactValue('cityCode', value)
    }

    onUploadFile = (event) => {
        // Read File
        if (event.target.files && event.target.files[0]) {
            let reader = new FileReader()
            const file = event.target.files[0]
            const name = `${getLogin()}${file.name.substring(file.name.lastIndexOf('.'), file.name.length)}`
            reader.onload = (e) => {
                this.props.changePictureToUpdate({
                    profilePicture:
                    {
                        name,
                        content: e.target.result,
                    },
                })
            }
            reader.readAsDataURL(event.target.files[0])
        }
    }

    getPicture = () => {
        const { pictureUrl } = this.state
        if (pictureUrl.length > 0) {
            return (
                <img
                    id='profile_picture'
                    src={pictureUrl}
                    className='circle'
                    style={{ height: '15em', width: '15em', objectFit: 'cover' }}
                />
            )
        }
        return (
            <i className='material-icons hugeProfilePicture responsive-img row'>account_circle</i>
        )
    }

    render() {
        if (this.props.contact) {
            return (
                <div className='padding-top-1'>
                    <Card>
                        <div className='row no-margin padding-top-10-px'>
                            <div className='col s9'>
                                <div className='row no-margin'>
                                    <Input col={ 6 } title={ i18n.name } value={ this.props.contact.name }
                                        onChange={ (e) => this.props.changeContactValue('name', e) }
                                    />
                                    <Input col={ 6 } title={ i18n.email } value={ this.props.contact.email }
                                        onChange={ (e) => this.props.changeContactValue('email', e) }
                                    />
                                </div>
                                <div className='row no-margin'>
                                    <Input col={ 12 } title={ i18n.address } value={ this.props.contact.address }
                                        onChange={ (e) => this.props.changeContactValue('address', e) }
                                    />
                                </div>
                                <div className='row no-margin'>
                                    <Input col={ 6 } title={ i18n.postcode } value={ this.props.contact.postalBox } maxLength={ 5 }
                                        onChange={ (e) => this.props.changeContactValue('postalBox', e) }
                                    />
                                    <Select options={ this.props.cities }
                                        label={ i18n.city }
                                        displayWithCode
                                        col={ 6 } onChange={ this.changeCity }
                                        value={ this.props.contact.cityCode }
                                    />
                                </div>
                                <div className='row no-margin'>
                                    <Input col={ 6 } title={ i18n.mobile } value={ this.props.contact.mobile }
                                        onChange={ (e) => this.props.changeContactValue('mobile', e) }
                                    />
                                    <Input col={ 6 } title={ i18n.phoneTel } value={ this.props.contact.phoneTel }
                                        onChange={ (e) => this.props.changeContactValue('phoneTel', e) }
                                    />
                                </div>
                                <div className='row no-margin'>
                                    <Input col={ 6 } title={ i18n.desktopTel } value={ this.props.contact.desktopTel }
                                        onChange={ (e) => this.props.changeContactValue('desktopTel', e) }
                                    />
                                </div>
                            </div>
                            <div className='col s3'>
                                <div className='row no-margin' style={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    {this.getPicture()}
                                    <FileIconMUI
                                        tooltip={i18n.changePicture}
                                        onChange={this.onUploadFile}
                                    />
                                </div>
                            </div>
                        </div>
                    </Card>
                    <div className='padding-top-1' />
                    <Card>
                        <div className='row no-margin padding-bottom-1'>
                            <div className='col s8 padding-top-1 padding-left-2'>
                                <h6 className='right-align'>{ i18n.willReceiveConfirmationEmail }</h6>
                            </div>
                            <div className='col s4 padding-right-1'>
                                <a className='waves-effect waves-light btn margin-top-1 margin-left-1 right'
                                    onClick={ () => this.resetPassword() }
                                >
                                    { i18n.passwordReset }
                                </a>
                            </div>
                        </div>
                    </Card>
                </div>
            )
        }
        return null
    }
}

UpdateUserForm.propTypes = {
    contact: PropTypes.instanceOf(ContactDto),
    contacts: PropTypes.arrayOf(PropTypes.instanceOf(ContactDto)),
    cities: PropTypes.arrayOf(PropTypes.instanceOf(CityDto)),
    changeContactValue: PropTypes.func,
    accountUser: PropTypes.instanceOf(User),
    profileLoginPicture: PropTypes.instanceOf(DtoPublicPicture),
    profilePicture: PropTypes.shape({
        name: PropTypes.string,
        content: PropTypes.string,
    }),
    changePictureToUpdate: PropTypes.func,
    fetchPictureWithoutCache: PropTypes.func,
}
const mapStateToProps = (store) => {
    return {
        contacts: store.ContactReducer.contacts,
        cities: store.CityReducer.cities,
        accountUser: store.AccountReducer.accountUser,
        profileLoginPicture: store.AdministrationReducer.profileLoginPicture,
    }
}

const mapDispatchToProps = {
    fetchPictureWithoutCache: AdministrationAction.fetchPictureWithoutCache,
}

export default connect(mapStateToProps, mapDispatchToProps)(UpdateUserForm)
