import { range } from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import i18n from 'simple-react-i18n'
import { setModal, sieauTooltip } from '../../utils/FormUtils'
import { hasValue } from '../../utils/NumberUtil'

class TablePagination extends Component {
    onClick = (value) => {
        this.props.onChangePage(value)
    }

    componentWillUpdate() {
        $('.tooltipped').tooltip('remove')
        $('.material-tooltip').remove()
        setTimeout(() => $('.tooltipped').tooltip({ html: true }), 1)
    }

    getRange = (nbPages) => {
        if (nbPages < 5) {
            return range(1, nbPages + 1)
        } else if (this.props.index + (this.props.displayPage - 1) >= nbPages) {
            return range(nbPages - (this.props.displayPage - 1), nbPages + 1)
        } else if (this.props.index > (this.props.displayPage)) {
            return range(this.props.index - (this.props.displayPage - 3), this.props.index + 1)
        }
        return range(1, this.props.displayPage + 1)
    }

    render() {
        const style = { marginRight: '0.5rem' }
        const nbPages = Math.ceil(this.props.nbElement / this.props.nbElementPerPage)
        if (nbPages != 1) {
            const pagination = (() => {
                if (this.props.nbElementPerPage !== -1) {
                    return this.getRange(nbPages).map((idx) => {
                        if (this.props.index === idx) {
                            return (<a className='btn waves-effect waves-light disabled pageActive' style={ style }
                                aria-controls='tableStationQualito' disabled='disabled' key={ idx }
                            >{ idx }</a>)
                        }
                        if (this.props.smallPaging) {
                            return null
                        }
                        return (<a className='btn waves-effect waves-light page-item' style={ style }
                            aria-controls='tableStationQualito' onClick={ () => this.onClick(idx) }
                            key={ idx }
                        >{ idx }</a>)
                    })
                }
                return [<a className='btn waves-effect waves-light disabled' style={ style }
                    aria-controls='tableStationQualito' disabled='disabled' key={ 1 }
                >1</a>]
            })()

            const previous = (() => {
                if (nbPages < this.props.displayPage + 1 && !this.props.smallPaging) {
                    return null
                }
                if (this.props.index === 1) {
                    return (<a className='btn previous disabled' style={ style } id='tableStationQualito_previous'
                        aria-controls='tableStationQualito' disabled
                    >&lt;</a>)
                }
                return (<a className='btn previous' style={ style } id='tableStationQualito_previous'
                    aria-controls='tableStationQualito'
                    onClick={ () => this.onClick(this.props.index - 1) }
                >&lt;</a>)
            })()
            const next = (() => {
                if (nbPages < this.props.displayPage + 1 && !this.props.smallPaging) {
                    return null
                }
                if (this.props.index === nbPages) {
                    return (<a className='btn next disabled' style={ style }
                        aria-controls='tableStationQualito' disabled
                    >&gt;</a>)
                }
                return (<a className='btn next' style={ style } id='table_btn_next'
                    aria-controls='tableStationQualito'
                    onClick={ () => this.onClick(this.props.index + 1) }
                >&gt;</a>)
            })()
            const threeDotsNext = (() => {
                if (nbPages != 1 && nbPages > this.props.displayPage && this.props.index !== nbPages && this.props.index + this.props.displayPage - 1 < nbPages) {
                    return [<a className='btn btn-flat no-hover' style={ style } key={'threeDotsNext1'}>...</a>,
                        <a className='btn waves-effect waves-light' style={ style } aria-controls='tableStationQualito'
                            onClick={ () => this.onClick(nbPages) } key={'threeDotsNext2'}
                        >{ nbPages }</a>]
                }
                return null
            })()

            const threeDotsPrevious = (() => {
                if (nbPages != 1 && this.props.index > this.props.displayPage) {
                    return [<a className='btn waves-effect waves-light' style={ style }
                        aria-controls='tableStationQualito' key={'threeDotsPrevious1'} onClick={ () => this.onClick(1) }
                    >{ 1 }</a>,
                    <a className='btn btn-flat no-hover' key={'threeDotsPrevious2'} style={ style }>...</a>]
                }
                return null
            })()

            return (
                <div className='row no-margin valign-wrapper'>
                    <div className='input-field dataTables_paginate paging_simple_numbers no-padding-top margin-bottom-1'>
                        <div className='pagination'>
                            { previous }
                            { this.props.smallPaging ? null : threeDotsPrevious }
                            { pagination }
                            { this.props.smallPaging ? null : threeDotsNext }
                            { next }
                        </div>
                    </div>
                    <div>
                        { this.props.onDisplayMore && (
                            <a className='waves-effect waves-light btn' aria-controls='tableStationQualito' onClick={ () => this.props.onDisplayMore() } style={ {
                                'padding-left': 12,
                                'padding-right': 12,
                                'margin-top': 4,
                            } } {...sieauTooltip(i18n.displayMore)}
                            >
                                <i className='material-icons left no-margin'>add_circle</i>
                            </a>
                        )}
                    </div>
                    <div>
                        { this.props.legendPanel && (
                            <a className='waves-effect waves-light btn dropdown-button' aria-controls='tableStationQualito' style={ {
                                'padding-left': 12,
                                'padding-right': 12,
                                'margin-top': 4,
                            } }
                            data-position='up' data-activates='tableLegend'
                            >
                                <i className='material-icons left no-margin'>info_outline</i>
                            </a>
                        )}
                    </div>
                    <div className='dropdown-content blue-arrow row no-margin' id='tableLegend'>
                        { this.props.legendPanel }
                    </div>
                    <div>
                        { hasValue(this.props.count) && this.props.count !== this.props.countDisplayed && (
                            <div className='row no-margin valign-wrapper'>
                                <a className='waves-effect waves-light btn' aria-controls='tableStationQualito' style={ {
                                    'padding-left': 12,
                                    'padding-right': 12,
                                    'margin-top': 4,
                                    'background-color': 'orange',
                                    'margin-left': 8,
                                } } {...sieauTooltip(() => `Cliquez pour afficher les ${this.props.count} élements`) }
                                onClick={ this.setCountModal }
                                >
                                    <i className='material-icons left no-margin' id='triangle' style={ { color: 'white' } }>warning</i>
                                </a>
                                <p className='margin-left-1'>{ `${this.props.countDisplayed} éléments affichés sur ${this.props.count}` }</p>
                            </div>
                        )}
                    </div>
                </div>

            )
        }
        return null
    }

    setCountModal = () => {
        setModal({
            title: i18n.payAttention,
            content: (
                <div>
                    <h6>Vous essayez d'afficher un grand nombre d'éléments, ce qui peut ralentir votre ordinateur.</h6>
                    <h6>Souhaitez vous continuer ?</h6>
                    <div className=''>
                        <a className='waves-effect waves-teal center primary-button btn-flat right margin-left-1 modal-close' onClick={ this.props.forceLoad }>{ i18n.confirm }</a>
                        <a className='waves-effect waves-teal btn-flat center secondary-button margin-right-1 modal-close right margin-bottom-1'>{ i18n.close }</a>
                    </div>
                </div>
            ),
        })
    }
}

TablePagination.propTypes = {
    index: PropTypes.number.isRequired,
    nbElement: PropTypes.number.isRequired,
    nbElementPerPage: PropTypes.number.isRequired,
    onChangePage: PropTypes.func.isRequired,
    displayPage: PropTypes.number,
    smallPaging: PropTypes.bool,
    legendPanel: PropTypes.element,
    onDisplayMore: PropTypes.func,
    count: PropTypes.number,
    countDisplayed: PropTypes.number,
    forceLoad: PropTypes.func,
}

TablePagination.defaultProps = {
    displayPage: 5,
}

export default TablePagination
