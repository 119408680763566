module.exports = {
    RECEIVE_OPERATIONS: 'RECEIVE_OPERATIONS',
    ADD_ANALYSIS: 'ADD_ANALYSIS',
    RECEIVE_ALL_THRESHOLD: 'RECEIVE_ALL_THRESHOLD',
    RECEIVE_QUALITY_THRESHOLD: 'RECEIVE_QUALITY_THRESHOLD',
    RECEIVE_ALL_PC_SELECTION: 'RECEIVE_ALL_PC_SELECTION',
    RESET_SUIVI_PC: 'RESET_SUIVI_PC',
    RECEIVE_ASSOCIATED_STATIONS_OPERATIONS: 'RECEIVE_ASSOCIATED_STATIONS_OPERATIONS',
    RECEIVE_ASSOCIATED_STATIONS_SAMPLES: 'RECEIVE_ASSOCIATED_STATIONS_SAMPLES',
    RECEIVE_ASSOCIATED_STATIONS_ANALYSIS: 'RECEIVE_ASSOCIATED_STATIONS_ANALYSIS',
    RECEIVE_ASSOCIATED_STATIONS_POINTS: 'RECEIVE_ASSOCIATED_STATIONS_POINTS',
}
