import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { instanceOf } from '../../../../utils/StoreUtils'
import DtoIAEauModel from '../../../../iaeau/dto/DtoIAEauModel'
import ImageCard from '../../../../components/card/ImageCard'
import { MODEL_OBJECTIVES } from '../../../../iaeau/constants/IAEauConstants'
import { Grid } from '@mui/material'
import HydrometryAction from '../../../../hydrometry/actions/HydrometryAction'
import { execByType } from '../../../../utils/StationUtils'
import { orderBy } from 'lodash'
import PiezometerStationAction from '../../../actions/PiezometerStationAction'

const ModelStepObjective = ({
    stationType,
    selectedModel,
    changeModel,
}) => {
    const [stats, setStats] = useState([])

    useEffect(() => {
        execByType(stationType, {
            piezometry: () => PiezometerStationAction.promisePiezoMeasuresStats(selectedModel.idStation).then(json => {
                setStats(json)
                if (json.length) {
                    changeModel({ typeId: json[0].typeId })
                }
            }),
            hydrometry: () => HydrometryAction.promiseHydroStats(selectedModel.idStation).then(json => {
                setStats(json)
                if (json.length) {
                    changeModel({ typeId: json[0].typeId })
                }
            }),
        })
    }, [])

    const dts = orderBy(stats, 'typeId').map(dt => (
        <Grid item xs={3}>
            <ImageCard
                title={dt.label}
                maxWidth={400}
                active={selectedModel.typeId === dt.typeId}
                onClick={() => changeModel({ typeId: dt.typeId })}
            />
        </Grid>
    ))

    return (
        <div className='padding-top-2'>
            {
                !stats.length ? (
                    <Grid container justifyContent='center' alignItems='center' spacing={ 3 }>
                        <h5>Chargement en cours ...</h5>
                    </Grid>
                ) : (
                    <>
                        <Grid container justifyContent='center' alignItems='center' spacing={3}>
                            <h5>Quel est l'objectif de prévision de ce modèle ?</h5>
                        </Grid>
                        <Grid container justifyContent='center' alignItems='center' spacing={3}>
                            <Grid item xs={4}>
                                <ImageCard
                                    img='pictures/iaeauModels/crue.jpg'
                                    alt='Crue'
                                    title='Crue'
                                    description='Ces modèles sont plus adaptés pour détecter les inondations.'
                                    maxWidth={345}
                                    imgHeight={140}
                                    active={selectedModel.objective === MODEL_OBJECTIVES.CRUE}
                                    onClick={() => changeModel({ objective: MODEL_OBJECTIVES.CRUE })}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <ImageCard
                                    img='pictures/iaeauModels/etiage.jpg'
                                    alt='Etiage'
                                    title='Etiage'
                                    description='Ces modèles seront plus performant pour prévoir les périodes de sécheresse.'
                                    maxWidth={345}
                                    imgHeight={140}
                                    active={selectedModel.objective === MODEL_OBJECTIVES.ETIAGE}
                                    onClick={() => changeModel({ objective: MODEL_OBJECTIVES.ETIAGE })}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <ImageCard
                                    img='pictures/iaeauModels/global.jpeg'
                                    alt='Global'
                                    title='Global'
                                    description='Ces modèles sont polyvalents et adaptés à toutes les situations.'
                                    maxWidth={345}
                                    imgHeight={140}
                                    active={selectedModel.objective === MODEL_OBJECTIVES.GLOBAL}
                                    onClick={() => changeModel({ objective: MODEL_OBJECTIVES.GLOBAL })}
                                />
                            </Grid>
                        </Grid>
                        <div className='padding-top-3'/>
                        <Grid container justifyContent='center' alignItems='center' spacing={3}>
                            <h5>Quel est le type de chronique à prédire ?</h5>
                        </Grid>
                        <Grid container justifyContent='center' alignItems='center' spacing={3}>
                            {dts}
                        </Grid>
                    </>
                )
            }
        </div>
    )
}

ModelStepObjective.propTypes = {
    stationType: PropTypes.string,
    id: PropTypes.string,
    selectedModel: instanceOf(DtoIAEauModel),
    setSelectedModel: PropTypes.func,
    changeModel: PropTypes.func,
}

export default ModelStepObjective