/* eslint-disable camelcase */
import { Button, Divider, Grid } from '@mui/material'
// import { flatten, groupBy } from 'lodash'
import { setActions } from 'components/ActionUtil'
import Checkbox from 'components/forms/Checkbox'
import SimpleDatePicker from 'components/forms/SimpleDatePicker'
import SectionList from 'components/list/section/SectionList'
import CartographyPanel from 'components/map/CartographyPanel'
import InstallationAction from 'installation/actions/InstallationAction'
import { DANGER_ELEMENT_TYPE, INSTALLATION_TYPES, PGSSE_ENTITIES_TYPE } from 'installation/constants/InstallationConstants'
import { groupBy } from 'lodash'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { SANDRE } from 'referencial/constants/ReferencialConstants'
import i18n from 'simple-react-i18n'
import { getImgInstallation } from 'utils/mapUtils/SiteTypes'
import { getSandreList } from 'utils/StoreUtils'
import { getI18nTitleDataLength } from 'utils/StringUtil'
import InstallationDangerModal from '../Dangers/components/InstallationDangerModal'
import OtherEntityModal from '../Dangers/components/OtherEntityModal'

const PGSSEDashboard = ({ }) => {
    const dispatch = useDispatch()

    const {
        entitiesWithLocation,
        sandreCodes,
    } = useSelector(store => ({
        entitiesWithLocation: store.InstallationReducer.entitiesWithLocation,
        sandreCodes: store.ReferencialReducer.sandreCodes,
    }), shallowEqual)

    const [situationDate, setSituationDate] = useState(moment.now())
    const [entityFilter, setEntityFilter] = useState([])
    const [dangSitFilter, setDangSitFilter] = useState([])
    const [open, setOpen] = useState(false)
    const [openDiag, setOpenDiag] = useState(false)

    const getMarkerIcon = danger => {
        if (danger.hasDiagnostic) {
            if (danger.link_dangers.length > 3) {
                return 'pictures/markers/map_installation_red.png'
            } else if (danger.link_dangers.length >= 1) {
                return 'pictures/markers/map_installation_orange.png'
            }
            return 'pictures/markers/map_installation_green.png'
        }
        return 'pictures/markers/map_installation_grey.png'
    }

    const getColor = (diag, number, type) => {
        if (type === DANGER_ELEMENT_TYPE.INSTALLATION && !diag) {
            return 'grey'
        } else if (number > 3) {
            return 'red'
        } else if (number >= 1) {
            return 'orange'
        }
        return 'green'
    }

    const filteredTypeData = entityFilter.length ? entitiesWithLocation.filter(ewl => entityFilter.includes(ewl.siteType)) : entitiesWithLocation
    const filteredDangSitData = dangSitFilter.length ? entitiesWithLocation.filter(ewl => ewl.link_dangers.some(ld => dangSitFilter.includes(ld.dangerousSituation))) : filteredTypeData
    const data = filteredDangSitData.map(d => ({
        ...d,
        id: d.siteCode,
        typeName: d.siteType === DANGER_ELEMENT_TYPE.INSTALLATION ? 'installation' : 'contributor',
        installationType: d.siteType === DANGER_ELEMENT_TYPE.INSTALLATION ? d.intValue : null,
        markerIcon: getMarkerIcon(d),
        dangerousSituation: d.link_dangers.length,
        color: getColor(d.hasDiagnostic, d.link_dangers.length, d.siteType),
    }))

    const getExportData = () => {
        const headers = ['siteCode', 'siteType', 'x', 'y', 'dangerousSituation']
        const formattedData = data.map(d => ({
            ...d,
            siteType: d.siteType === DANGER_ELEMENT_TYPE.INSTALLATION ? i18n.installation : i18n.contributor,
        }))
        const exportData = formattedData.length ? [
            { ...formattedData[0], headers },
            ...formattedData.slice(1, formattedData.length),
        ] : [{ headers }]
        return {
            data: exportData,
            titleFile: i18n.pgsse,
        }
    }

    useEffect(() => {
        setActions({
            export: getExportData,
        })
    }, [data])

    useEffect(() => {
        dispatch(InstallationAction.fetchDangersWithLocation(situationDate))
    }, [situationDate])

    const filterEntity = (check, type) => {
        const newEntityFilter = check ? [...entityFilter, type.code] : [...entityFilter.filter(d => d !== type.code)]
        setEntityFilter(newEntityFilter)
    }

    const filterDangerousSituation = (check, sandreCode) => {
        const newDangSitFilter = check ? [...dangSitFilter, sandreCode.code] : [...dangSitFilter.filter(d => d !== sandreCode.code)]
        setDangSitFilter(newDangSitFilter)
    }

    const situationPanel = {
        id: 'dangerousSituations',
        icon: 'warning',
        title: i18n.dangerousSituations,
        content: (
            <Grid container style={{ paddingTop: '20px' }}>
                <Grid item xs={12}>
                    <SimpleDatePicker
                        col={12}
                        value={situationDate}
                        id='situationDate'
                        label={i18n.situationDateDangers}
                        onChange={v => setSituationDate(v)}
                    />
                </Grid>
                <fieldset style={{ width: '100%', margin: '0 10px' }}>
                    <legend>{i18n.type}</legend>
                    <Grid container >
                        {PGSSE_ENTITIES_TYPE.map(type => {
                            return (
                                <Grid item xs={12} onClick={() => filterEntity(!entityFilter.includes(type.code), type)}>
                                    <Checkbox
                                        col={12}
                                        label={i18n[type.i18n]}
                                        checked={entityFilter.includes(type.code)}
                                    />
                                </Grid>
                            )
                        })}
                    </Grid>
                </fieldset>
                <fieldset style={{ width: '100%', margin: '0 10px' }}>
                    <legend>{i18n.dangerousSituation}</legend>
                    <Grid container >
                        {getSandreList(sandreCodes, SANDRE.INSTALLATIONS_DANGERS).map(sandreCode => {
                            return (
                                <Grid item xs={6} onClick={() => filterDangerousSituation(!dangSitFilter.includes(sandreCode.code), sandreCode)}>
                                    <Checkbox
                                        col={12}
                                        label={sandreCode.name}
                                        checked={dangSitFilter.includes(sandreCode.code)}
                                    />
                                </Grid>
                            )
                        })}
                    </Grid>
                </fieldset>
                <Grid container item justifyContent='flex-end' className='padding-1'>
                    <Button
                        variant='contained'
                        color='primary'
                        onClick={() => {
                            setDangSitFilter([])
                            setEntityFilter([])
                            setSituationDate(moment.now())
                        }}
                    >
                        {i18n.reinit}
                    </Button>
                </Grid>
            </Grid>
        ),
    }

    const getTitleFromColor = (color, number) => {
        const title = (() => {
            switch (color) {
                case 'red':
                    return i18n.toControl
                case 'orange':
                    return i18n.toMonitor
                case 'green':
                    return i18n.compliant
                case 'grey':
                default:
                    return i18n.noDiagnostic
            }
        })()
        return `${title} (${number} ${getI18nTitleDataLength(i18n.element, i18n.elements, number)})`
    }

    const getName = (danger) => {
        switch (danger.siteType) {
            case DANGER_ELEMENT_TYPE.INSTALLATION:
                const imgObj = getImgInstallation(danger.intValue)
                const icon = <img className='circle' style={{ height: '30px' }} src={imgObj.url} />
                const instType = INSTALLATION_TYPES.find(it => it.code === danger.intValue) || {}
                const text = ` ${instType?.name && `${i18n[instType.label]} - ` || ''} ${danger.name} [${danger.code || danger.siteCode}]`
                return { icon, text }
            case DANGER_ELEMENT_TYPE.CONTRIBUTOR:
                const icon2 = <i className='material-icons'>business</i>
                const text2 = `${danger?.intValue && `${danger?.intValue} - ` || ''} ${danger.name} [${danger.siret || danger.code}]`
                return { icon: icon2, text: text2 }
            case DANGER_ELEMENT_TYPE.SECTOR:
                const icon3 = <i className='material-icons right blue-text clickable map-right-icon'>map</i>
                const text3 = `${danger.name} [${danger.siteCode}]`
                return { icon: icon3, text: text3 }
            default:
                return danger.name
        }
    }

    const getSection = (color, entities = []) => {
        return (
            <SectionList
                title={getTitleFromColor(color, entities.length)}
                openFirst={color === 'red'}
                color={color}
                titleStyle={{ padding: '0 10px', fontSize: '18px' }}
                condensed
            >
                <Grid container>
                    {entities.map((e, index) => {
                        const { icon, text } = getName(e)
                        return (
                            <Grid
                                container
                                className={`${e.hasDiagnostic && 'clickable'}`}
                                onClick={() => {
                                    if (e.link_dangers.length) {
                                        dispatch(InstallationAction.fetchDanger(e.link_dangers[0].idDanger))
                                    } else if (e.hasDiagnostic) {
                                        dispatch(InstallationAction.fetchVisit(e.siteCode, e.idCampaign))
                                    }
                                }}
                            >
                                <Grid container item xs={12} style={{ padding: '5px 10px' }}>
                                    <Grid item style={{ paddingTop: '8px' }}>{icon}</Grid>
                                    <Grid item alignItems='center' className='padding-left-1' style={{ fontSize: '16px' }}>
                                        <Grid item xs={12} className='bold'>
                                            {text}
                                        </Grid>
                                        <Grid item xs={12}>
                                            {`${e.link_dangers.length} ${getI18nTitleDataLength(i18n.dangerousSituation, i18n.dangerousSituations, e.link_dangers.length)}`}
                                        </Grid>
                                    </Grid>
                                </Grid>
                                {index + 1 !== entities.length && (
                                    <Grid item xs={12}>
                                        <Divider />
                                    </Grid>
                                )}
                            </Grid>
                        )
                    })}
                </Grid>
            </SectionList>
        )
    }

    const groupedData = groupBy(data, 'color')

    const entityPanel = {
        id: 'entities',
        icon: 'view_day',
        title: i18n.entities,
        content: (
            <Grid container className='padding-top-1'>
                <Grid item xs={12}>
                    {getSection('red', groupedData.red)}
                </Grid>
                <Grid item xs={12}>
                    {getSection('orange', groupedData.orange)}
                </Grid>
                <Grid item xs={12}>
                    {getSection('green', groupedData.green)}
                </Grid>
                <Grid item xs={12}>
                    {getSection('grey', groupedData.grey)}
                </Grid>
            </Grid>
        ),
    }

    const panels = [
        situationPanel,
        entityPanel,
    ]

    return (
        <Grid
            className='dropable'
            style={{
                minWidth: 'calc(100vw - 75px)',
                minHeight: 'calc(100vh - 75px)',
                position: 'relative',
                marginLeft: '4px',
            }}
        >
            <CartographyPanel
                layers={['STATIONS_POINTS']}
                stationsPoints={data}
                componentType='pgsse'
                panels={panels}
                fullScreenable={false}
                tabsOpenByDefault
                tabsLoadOnClick
                noStationsPanelTitle
            />
            <OtherEntityModal open={open} setOpen={setOpen} readMode />
            <InstallationDangerModal open={openDiag} setOpen={setOpenDiag} />
        </Grid>
    )
}

PGSSEDashboard.PropTypes = {
}

export default PGSSEDashboard