'use strict'
import {
    RECEIVE_WATERMASS_STATES,
    RESET_WATERMASS,
    RESET_WATERMASS_STATIONS,
    STATIONS_WITH_WATERMASS,
    WATERMASS,
    WATERMASSES,
} from '../constants/WatermassConstants'
import WatermassItemDto from '../dto/WatermassItemDto'
import WatermassDto from '../dto/WatermassDto'
import StationWithWatermassDto from '../dto/StationWithWatermassDto'
import WatermassStateDto from '../dto/WatermassStateDto'

export function WatermassReducer(state = {}, action) {
    switch (action.type) {
        case WATERMASSES:
            return Object.assign({}, state, {
                watermasses: action.watermasses.map(watermassItem => new WatermassItemDto(watermassItem)),
            })
        case WATERMASS:
            return Object.assign({}, state, {
                watermass: new WatermassDto(action.watermass),
            })
        case STATIONS_WITH_WATERMASS:
            return {
                ...state,
                stationsWithWatermass: action.stations.map(s => new StationWithWatermassDto(s)),
            }
        case RESET_WATERMASS_STATIONS:
            return Object.assign({}, state, {
                stationsWithWatermass: [],
            })
        case RESET_WATERMASS:
            return Object.assign({}, state, {
                watermass: {},
            })
        case RECEIVE_WATERMASS_STATES:
            return Object.assign({}, state, {
                watermassStates: action.data.map(el => new WatermassStateDto(el)),
            })
        default:
            return state
    }
}

export const store = {
    watermasses: [],
    watermass: {},
    stationsWithWatermass: [],
    watermassStates: [],
}