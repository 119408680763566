import PropTypes from 'prop-types'
import React, { Component } from 'react'
import i18n from 'simple-react-i18n'
import ProgressCard from '../../../../components/card/ProgressCard'
import Input from '../../../../components/forms/Input'
import Job from '../../../dto/DtoJob'

class MailImportPanel extends Component {
    constructor(props) {
        super(props)
        this.state = {
            dataLoaded: true,
            progress: 0,
        }
    }

    onChangeFilters = (changes) => {
        const parameters = {
            ...this.props.job.parameters,
            filters: [JSON.stringify({
                ...this.getFilters(),
                ...changes,
            })],
        }
        this.props.onChangeJob({ parameters })
    }

    getFilters = () => this.props.job.parameters.filters.length ? JSON.parse(this.props.job.parameters.filters[0]) : {}

    render() {
        if (this.state.dataLoaded) {
            const disabled = !this.props.isEditMode
            const filters = this.getFilters()
            return (
                <div>
                    <div className='row no-margin valign-wrapper'>
                        <fieldset className='width-100'>
                            <legend>&nbsp;{ 'Paramétrages mail' }&nbsp;</legend>
                            <Input col={ 4 } title={ 'serveur mail' } onChange={ v => this.onChangeFilters({ mailHost: v }) } disabled={ disabled } value={ filters.mailHost } />
                            <Input col={ 4 } title={ i18n.email } onChange={ v => this.onChangeFilters({ mailUser: v }) } disabled={ disabled } value={ filters.mailUser } />
                            <Input col={ 4 } title={ i18n.password } onChange={ v => this.onChangeFilters({ mailPassword: v }) } disabled={ disabled } value={ filters.mailPassword } passwordType/>
                        </fieldset>
                    </div>
                    <div className='row no-margin padding-top-1'>
                        <Input col={ 5 } title={ 'Filtre du nom de fichier' } onChange={ v => this.onChangeFilters({ fileNameFilter: v }) } disabled={ disabled } value={ filters.fileNameFilter } />
                        <Input col={ 5 } title={ 'Répertoire FTP' } onChange={ v => this.onChangeFilters({ ftpPath: v }) } disabled={ disabled } value={ filters.ftpPath } />
                    </div>
                </div>
            )
        }
        return <ProgressCard progress={ this.state.progress }/>
    }
}

MailImportPanel.propTypes = {
    job: PropTypes.instanceOf(Job).isRequired,
    onChangeJob: PropTypes.func.isRequired,
    isEditMode: PropTypes.bool,
}

export default MailImportPanel