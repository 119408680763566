/* eslint-disable camelcase */
import React from 'react'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import { connect } from 'react-redux'
import { arrayOf, getSandreList } from '../../../../utils/StoreUtils'
import DtoSandreCode from '../../../../referencial/dto/DtoSandreCode'
import { SANDRE } from '../../../../referencial/constants/ReferencialConstants'
import SieauAction from '../../../../components/sieau/SieauAction'
import Row from '../../../../components/react/Row'
import DtoTankStation from '../dto/DtoTankStation'
import Select from '../../../../components/forms/Select'
import { getVisitSandreSelectChange } from '../../../../utils/VisitUtils'

const TankCivilEngPanel = ({
    onChange = () => { },
    onChangeVisit = () => { },
    tank = {},
    readMode = false,
    sandreCodes = [],
}) => {
    const onChangeCivilEng = (value) => {
        onChange({
            link_civilEngs: [
                {
                    idStation: tank.idStation,
                    ...tank.link_civilEngs[0],
                    ...value,
                },
            ],
        })
    }

    const civilEng = tank.link_civilEngs[0] || {}

    return (
        <Row className='padding-top-1 padding-bottom-1'>
            <Row>
                <Select
                    col={ 6 }
                    value={ civilEng.fillingMode }
                    label={ i18n.fillingMode }
                    keyValue='code'
                    options={ getSandreList(sandreCodes, SANDRE.INSTALLATIONS_MODEREMPLISSAGE) }
                    onChange={ (v) => {
                        onChangeCivilEng({ fillingMode: v })
                        onChangeVisit(getVisitSandreSelectChange(sandreCodes, SANDRE.INSTALLATIONS_MODEREMPLISSAGE, i18n.fillingMode, civilEng.fillingMode, v))
                    } }
                    integerValue
                    readMode={ readMode }
                />
                <Select
                    col={ 6 }
                    value={ civilEng.fillingType }
                    label={ i18n.fillingType }
                    keyValue='code'
                    options={ getSandreList(sandreCodes, SANDRE.INSTALLATIONS_TYPEREMPLISSAGE) }
                    onChange={ (v) => {
                        onChangeCivilEng({ fillingType: v })
                        onChangeVisit(getVisitSandreSelectChange(sandreCodes, SANDRE.INSTALLATIONS_TYPEREMPLISSAGE, i18n.fillingType, civilEng.fillingType, v))
                    } }
                    integerValue
                    readMode={ readMode }
                />
            </Row>
        </Row>
    )
}

TankCivilEngPanel.propTypes = {
    tank: PropTypes.instanceOf(DtoTankStation),
    sandreCodes: arrayOf(DtoSandreCode),
    readMode: PropTypes.bool,
    onChange: PropTypes.func,
    onChangeVisit: PropTypes.func,
    setPopup: PropTypes.func,
}

const mapStateToProps = (store) => ({
    sandreCodes: store.ReferencialReducer.sandreCodes,
})

const mapDispatchTopProps = {
    setPopup: SieauAction.setPopup,
}

export default connect(mapStateToProps, mapDispatchTopProps)(TankCivilEngPanel)
