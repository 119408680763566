import React from 'react'
import PropTypes from 'prop-types'
import { maxBy } from 'lodash'
import ReactECharts from 'echarts-for-react'
import echarts from 'echarts/lib/echarts'

const SmallPie = ({
    name = '',
    data = [],
}) => {
    const maxValue = maxBy(data, 'value')

    const options = {
        series: [
            {
                name,
                type: 'pie',
                radius: ['65%', '90%'],
                hoverOffset: 3,
                avoidLabelOverlap: false,
                label: {
                    normal: {
                        show: false,
                        position: 'center',
                    },
                    emphasis: {
                        show: false,
                        textStyle: {
                            fontSize: '10',
                            fontWeight: 'bold',
                        },
                    },
                },
                labelLine: {
                    show: false,
                },
                itemStyle: {
                    emphasis: {
                        shadowBlur: 10,
                        shadowOffsetX: 0,
                        shadowColor: 'rgba(0, 0, 0, 0.5)',
                    },
                },
                data,
            }],
        tooltip: {
            trigger: 'item',
        },
        title: {
            text: `${maxValue?.value}%`,
            textStyle: {
                fontSize: 12,
                color: maxValue?.itemStyle?.normal?.color,
            },
            left: 'center',
            top: 'center',
        },
    }

    return (
        <>
            <h6 style={{ fontWeight: 'bold', textAlign: 'center', marginTop: 5 }}>{name}</h6>
            <ReactECharts
                echarts={echarts}
                option={options}
                notMerge={true}
                lazyUpdate={true}
                style={{ height: 120 }}
            />
        </>
    )
}

SmallPie.propTypes = {
    name: PropTypes.string,
    data: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.number,
        name: PropTypes.string,
        itemStyle: PropTypes.shape({
            normal: PropTypes.shape({
                color: PropTypes.string,
            }),
        }),
        tooltip: PropTypes.shape({
            formatter: PropTypes.func,
        }),
    })),
}

export default SmallPie