import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Input from '../../../components/forms/Input'
import SimpleDatePicker from '../../../components/forms/SimpleDatePicker'
import moment from 'moment'
import i18n from 'simple-react-i18n'
import { getExecStatusIcon, getJobStatus } from '../../../utils/JobUtils'
import Button from '../../../components/forms/Button'
import Checkbox from '../../../components/forms/Checkbox'
import RadioButtons from '../../../components/forms/RadioButtons'
import AppStore from '../../../store/AppStore'
import SuperMultiAutocomplete from 'components/forms/SuperMultiAutocomplete'
import MultiContributorsAutocomplete
    from '../../../referencial/components/contributor/components/MultiContributorsAutocomplete'
import { arrayOf } from '../../../utils/StoreUtils'
import ContributorDto from '../../../referencial/components/contributor/dto/ContributorDto'

class JobTableExecutionsForm extends Component {
    constructor(props) {
        super(props)
        this.state = AppStore.getState().JobReducer.jobTableCache?.filters || {
            searchValue: undefined,
            startDate: null,
            scheduled: undefined,
            status: [],
            endDate: null,
            file: undefined,
            displayMode: 'job',
        }
    }

    onChangeFilter(filterName, value, cb) {
        this.setState({ ...this.state, [filterName]: value }, cb)
    }

    toggleCheck(status, isChecked) {
        if (isChecked) {
            this.setState({ status: [...this.state.status, status] })
        } else {
            this.setState({ status: this.state.status.filter(el => el !== status) })
        }
    }

    submit = () => {
        this.props.onSubmit({
            ...this.state,
            scheduled: this.state.scheduled && (this.state.scheduled === 1 || !(this.state.scheduled === 2)),
        })
    }

    render() {
        const {
            searchValue,
            file,
            station,
            startDate,
            endDate,
            scheduled,
            status,
            displayMode,
            manager,
        } = this.state
        const statusCheckbox = getJobStatus().map((s, i) => {
            return {
                value: i,
                label: s.label,
                tooltipContent: <div className='padding-left-1 padding-right-1 valign-wrapper'>{s.label}</div>,
            }
        })
        const scheduledOptions = [
            {
                value: 1,
                name: i18n.yes,
            },
            {
                value: 2,
                name: i18n.no,
            },
        ]
        const displayModes = [
            {
                code: 'job',
                name: i18n.job,
            },
            {
                code: 'executions',
                name: i18n.executions,
            },
        ]
        return (
            <div className='row no-margin padding-1'>
                <div className='col s12 row no-margin'>
                    <Input
                        title={i18n.search}
                        col={3}
                        tooltip={ i18n.searchThenEnter }
                        clearFunction
                        value={searchValue}
                        onChange={(value) => this.onChangeFilter('searchValue', value || undefined)}
                        onEnterKeyPress={(value) => this.onChangeFilter('searchValue', value || undefined, this.submit)}
                    />
                    <Input
                        title={i18n.fileLabel}
                        col={2}
                        clearFunction
                        value={file}
                        onChange={(value) => this.onChangeFilter('file', value || undefined)}
                    />
                    <Input
                        title={i18n.station}
                        col={2}
                        clearFunction
                        value={station}
                        onChange={(value) => this.onChangeFilter('station', value || undefined)}
                    />
                    <SimpleDatePicker
                        onChange={(value) => this.onChangeFilter('startDate', value)}
                        col={1}
                        value={startDate}
                        label={i18n.startDate}
                        max={moment().endOf('day').valueOf()}
                    />
                    <SimpleDatePicker
                        onChange={(value) => this.onChangeFilter('endDate', value)}
                        col={1}
                        label={i18n.endDate}
                        value={endDate}
                        max={moment().endOf('day').valueOf()}
                    />
                    <MultiContributorsAutocomplete
                        label={i18n.administrator}
                        disablePortal={false}
                        options={this.props.contributors}
                        values={manager}
                        onChange={(value) => this.onChangeFilter('manager', value)}
                        col={2}
                    />
                    <SuperMultiAutocomplete
                        options={scheduledOptions}
                        onChange={(value) => this.onChangeFilter('scheduled', value)}
                        col={1}
                        keyValue='value'
                        label={i18n.scheduled}
                        values={scheduled}
                    />
                </div>
                <div className='row col s12 no-margin'>
                    {statusCheckbox.map(cb => (
                        <Checkbox
                            label={getExecStatusIcon({ status: cb.value }, 25, cb.tooltipContent)}
                            checked={status.includes(cb.value)}
                            col={1}
                            onChange={isChecked => this.toggleCheck(cb.value, isChecked)}
                        />
                    ))}
                    <RadioButtons col={ 2 } onChange={(value) => this.onChangeFilter('displayMode', value) } elements={ displayModes } title={i18n.displayMode} selected={displayMode}/>
                    <Button
                        title={i18n.search}
                        col={1}
                        onClick={this.submit}
                    />
                </div>
            </div>
        )
    }
}

JobTableExecutionsForm.propTypes = {
    onSubmit: PropTypes.func,
    contributors: arrayOf(ContributorDto),
}

JobTableExecutionsForm.defaultProps = {
    onSubmit: () => {},
}

export default JobTableExecutionsForm