import EquipmentDto from './EquipmentDto'
import CentralDto from '../../central/dto/CentralDto'
import SensorDto from '../../sensor/dto/SensorDto'
import PowerSupplyDto from '../../powerSupply/dto/PowerSupplyDto'
import TelecomDto from '../../telecom/dto/TelecomDto'
import SimDto from '../../sim/dto/SimDto'
import VariousMaterielDto from '../../variousMateriel/dto/VariousMaterielDto'

export default class EquipmentWithMaterielsDto {
    constructor({ equipment, central, sensor, powerSupply, telecom, sim, variousMateriel }) {
        this.equipment = equipment && new EquipmentDto(equipment)
        this.central = central.map(c => new CentralDto(c))
        this.sensor = sensor.map(s => new SensorDto(s))
        this.powerSupply = powerSupply.map(p => new PowerSupplyDto(p))
        this.telecom = telecom.map(t => new TelecomDto(t))
        this.sim = sim.map(s => new SimDto(s))
        this.variousMateriel = variousMateriel.map(v => new VariousMaterielDto(v))
    }
}
