'use strict'
import { every, findIndex, groupBy, minBy, range, sortBy, uniq } from 'lodash'
import moment from 'moment'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import Axis from '../../../components/echart/Axis'
import EChart from '../../../components/echart/EChart'
import HeatMap from '../../../components/echart/series/HeatMap'
import Spinner from '../../../components/progress/Spinner'
import QualityIntegrationOverviewAction from '../../../quality/components/integrationOverview/actions/QualityIntegrationOverviewAction'
import AppStore from '../../../store/AppStore'
import { getColorCircleElement, getColorFromPalette } from '../../../utils/ColorUtil'
import { getDate, getYear } from '../../../utils/DateUtil'
import { getLabel, getMapStateToProps, getPropType, getPropTypes } from '../../../utils/StoreUtils'

const propsToFetch = {
    situationOperations: false,
    contributors: false,
}

class QualityHeatMap extends Component {
    constructor(props) {
        super(props)
        this.state = {
            dataLoaded: false,
        }
    }

    componentWillMount() {
        if (!this.props.situationOperations.length) {
            AppStore.dispatch(QualityIntegrationOverviewAction.fetchOperations(() => this.setState({ dataLoaded: true })))
        } else {
            this.setState({ dataLoaded: true })
        }
    }

    getProducer = (operationList) => {
        if (every(operationList, o => o.producer === operationList[0].producer)) {
            return operationList[0].producer
        }
        return -1
    }

    render() {
        if (this.state.dataLoaded) {
            const allOperations = this.props.situationOperations.filter(o => this.props.qualitometers.find(q => q.id === o.codeQualitometer))
            if (allOperations.length) {
                const stations = sortBy(this.props.qualitometers, 'id')
                const stationsName = stations.map(s => s.name)
                const firstDate = moment(minBy(allOperations, 'dateBegin').dateBegin)
                const yearRange = range(firstDate.year(), moment().year())
                const dateRange = range(moment().month(0).date(1).valueOf(), moment().valueOf(), moment().valueOf() - moment().subtract(1, 'days').valueOf()).map(v => getDate(v, 'DD/MM'))
                const dateSerie = [].concat(yearRange, dateRange)

                const groupedPastYearsOperations = groupBy(allOperations.filter(o => getYear(o.dateBegin) != moment().year()), o => `${getYear(o.dateBegin)},${o.codeQualitometer}`)

                const pastYearsData = Object.keys(groupedPastYearsOperations).map(key => {
                    const year = key.split(',')[0]
                    const stationId = key.split(',')[1]
                    return [findIndex(dateSerie, y => y == year), findIndex(stations, s => s.id == stationId), groupedPastYearsOperations[key].length, this.getProducer(groupedPastYearsOperations[key])]
                })

                const currentYearData = allOperations.filter(o => getYear(o.dateBegin) == moment().year()).map(o => {
                    return [findIndex(dateSerie, y => y == getDate(o.dateBegin, 'DD/MM')), findIndex(stations, s => s.id == o.codeQualitometer), 1, o.producer]
                })

                const data = [].concat(pastYearsData, currentYearData)

                const producers = uniq(data.map(values => values[3]))

                const options = {
                    series: [HeatMap({
                        data,
                        label: {
                            normal: {
                                show: true,
                                formatter: (params) => {
                                    return params.value[2]
                                },
                            },
                        },
                    })],
                    xAxis: [Axis({
                        type: 'category',
                        data: dateSerie,
                        splitArea: {
                            show: true,
                        },
                    })],
                    yAxis: [Axis({
                        type: 'category',
                        data: stationsName,
                        splitArea: {
                            show: true,
                        },
                    })],
                    height: stationsName.length * 50,
                    grid: {
                        top: '8%',
                        left: '3%',
                        right: '4%',
                        bottom: '80',
                        containLabel: true,
                        height: stationsName.length * 50,
                    },
                    dataZoom: [{
                        type: 'inside',
                        startValue: dateSerie.length - 30,
                        endValue: dateSerie.length,
                        bottom: 40,
                    }, {
                        startValue: dateSerie.length - 30,
                        endValue: dateSerie.length,
                        handleSize: '80%',
                        handleStyle: {
                            color: '#fff',
                            shadowBlur: 3,
                            shadowColor: 'rgba(0, 0, 0, 0.6)',
                            shadowOffsetX: 2,
                            shadowOffsetY: 2,
                        },
                    }],
                    tooltip: {
                        formatter: (params) => {
                            return `${dateSerie[params.data[0]]}</br>${
                                getColorCircleElement(params.color)} ${stationsName[params.data[1]]} : ${params.data[2]} ${i18n.operations
                            }<br/>` + `producteur : ${params.data[3] == -1 ? 'plusieurs' : getLabel(this.props.contributors, params.data[3], 'mnemonique')}`
                        },
                    },
                    visualMap: {
                        type: 'piecewise',
                        dimension: '3',
                        categories: producers,
                        inRange: {
                            color: producers.map((v, i) => getColorFromPalette(i)),
                        },
                        formatter: (value) => {
                            return (value == -1 ? 'plusieurs' : getLabel(this.props.contributors, value, 'mnemonique'))
                        },
                    },
                }
                return (
                    <EChart options={ options }/>
                )
            }
        }
        return <Spinner />
    }
}

QualityHeatMap.propTypes = getPropTypes(propsToFetch, {
    qualitometers: getPropType('qualitometers'),
})

const mapStateToProps = () => getMapStateToProps(propsToFetch)

export default connect(mapStateToProps)(QualityHeatMap)