import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { arrayOf, getSandreList } from '../../../utils/StoreUtils'
import i18n from 'simple-react-i18n'
import DtoSandreCode from '../../../referencial/dto/DtoSandreCode'
import { getFullDate } from '../../../utils/DateUtil'
import { getSandreLabel } from '../../../utils/StringUtil'
import { SANDRE } from '../../../referencial/constants/ReferencialConstants'
import Table from '../../../components/datatable/Table'
import { checkMandatoryFields } from '../../../utils/FormUtils'
import DtoProductionUnit from '../../../productionUnit/dto/DtoProductionUnit'
import DtoDistributionUnit from '../../../distributionUnit/dto/DtoDistributionUnit'
import DtoQualitometer from '../../../quality/dto/DtoQualitometer'
import DtoPiezometer from '../../../piezometry/dto/DtoPiezometer'
import DtoHydrometricStation from '../../../hydrometry/dto/DtoHydrometricStation'
import DtoInstallation from '../../../installation/dto/installation/DtoInstallation'
import AltimetrySystemModal from './modals/AltimetrySystemModal'
import { getExport } from '../../../utils/linkUtils'
import { getUpdatedLandmarkDate } from '../../../utils/PiezometryUtils'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import DtoAltimetrySystem from '../../../piezometry/dto/DtoAltimetrySystem'

const headers = ['startDate', 'endDate', 'nature', 'altitude', 'comment', 'altimetrySystems', 'productionMode']

class StationAltimetrySystemsPanel extends Component {
    state = {
        isOpen: false,
        isNew: false,
        altimetrySystem: {},
    }

    render = () => {
        const {
            station = {},
            readMode = false,
            onChange = () => { },
            sandreCodes = [],
            className = '',
            createOnly = false,
        } = this.props
        const {
            isOpen,
            isNew,
            altimetrySystem,
        } = this.state
        const stationAltimetrySystems = station.link_altimetrySystems.map((as, index) => ({
            ...as,
            index,
        }))
        const tableData = stationAltimetrySystems.map(as => ({
            ...as,
            startDate: getFullDate(as.startDate),
            endDate: getFullDate(as.endDate),
            nature: getSandreLabel(sandreCodes, SANDRE.ALTIMETRIC_SYSTEM_NATURE, as.natureCode),
            altimetrySystems: getSandreLabel(sandreCodes, SANDRE.ALTIMETRIC_SYSTEM, as.altimetrySystem),
            productionMode: getSandreLabel(sandreCodes, SANDRE.ALTIMETRIC_SYSTEM_OBTENTION, as.productionMode),
        }))
        const exportAction = getExport(tableData, i18n.altimetrySystems, headers)
        const actions = !readMode ? [{
            iconName: 'note_add',
            tooltip: i18n.add,
            onClick: () => this.setState({ isOpen: true, isNew: true, altimetrySystem: { idStation: station.id } }),
        }, exportAction] : [exportAction]

        return (
            <div id='altimetrySystems'>
                <Table
                    title={i18n.altimetrySystems}
                    condensed
                    data={tableData}
                    className={className}
                    type={{ headers }}
                    sortable={!!tableData.length}
                    actions={actions}
                    alterable={!readMode && !createOnly}
                    onAlter={elem => {
                        const as = stationAltimetrySystems.find(({ index }) => index === elem.index) || {}
                        this.setState({ isOpen: true, isNew: false, altimetrySystem: { ...as, idStation: station.id } })
                    }}
                    deletable={!readMode && !createOnly}
                    onDelete={elem => onChange({ link_altimetrySystems: stationAltimetrySystems.filter(as => as.index !== elem.index), ...getUpdatedLandmarkDate(station, elem) })}
                />
                <Dialog
                    onClose={() => this.setState({ isOpen: false })}
                    fullWidth
                    maxWidth='md'
                    open={isOpen}
                >
                    <DialogTitle>{isNew ? i18n.newAltimetrySystem : i18n.editAltimetrySystem}</DialogTitle>
                    <DialogContent>
                        <AltimetrySystemModal
                            altimetrySystem={altimetrySystem}
                            onChange={obj => this.setState({ altimetrySystem: { ...altimetrySystem, ...obj } })}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.setState({ isOpen: false })} variant='outlined' color='secondary'>
                            {i18n.cancel}
                        </Button>
                        <Button onClick={() => {
                            const mandatoryFields = [{ field: 'altitude' }, { field: 'startDate' }, { field: 'natureCode', i18n: 'nature' }]
                            checkMandatoryFields(mandatoryFields, this.state.altimetrySystem, () => {
                                onChange({
                                    link_altimetrySystems: [
                                        ...stationAltimetrySystems.filter(as => as.index !== altimetrySystem.index),
                                        new DtoAltimetrySystem(altimetrySystem),
                                    ],
                                    ...getUpdatedLandmarkDate(station, altimetrySystem),
                                })
                                this.setState({ isOpen: false })
                            })
                        }} variant='contained' color='primary'
                        >
                            {i18n.register}
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        )
    }
}

StationAltimetrySystemsPanel.propTypes = {
    station: PropTypes.oneOfType([
        PropTypes.instanceOf(DtoProductionUnit),
        PropTypes.instanceOf(DtoDistributionUnit),
        PropTypes.instanceOf(DtoQualitometer),
        PropTypes.instanceOf(DtoPiezometer),
        PropTypes.instanceOf(DtoHydrometricStation),
        PropTypes.instanceOf(DtoInstallation),
    ]),
    sandreCodes: arrayOf(DtoSandreCode),
    readMode: PropTypes.bool,
    onChange: PropTypes.func,
    className: PropTypes.string,
    createOnly: PropTypes.bool,
}

const mapStateToProps = store => ({
    sandreCodes: store.ReferencialReducer.sandreCodes,
})

export default connect(mapStateToProps)(StationAltimetrySystemsPanel)