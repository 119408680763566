import DtoCMSMessage from './DtoCmsMessage'

export default class CMSEventDto {
    constructor(obj) {
        this.id = obj.id
        this.title = obj.title
        this.subtitle = obj.subtitle
        this.idCategory = obj.idCategory
        this.comment = obj.comment
        this.author = obj.author
        this.footer = obj.footer
        this.footerContent = obj.footerContent
        this.link = obj.link
        this.authorization = obj.authorization
        this.status = obj.status
        this.updateDate = obj.updateDate
        this.dateDebut = obj.dateDebut
        this.dateFin = obj.dateFin
        this.order = obj.order
        this.levelContent = obj.levelContent
        this.login = obj.login
        this.loginMaj = obj.loginMaj
        this.x = obj.x
        this.y = obj.y
        this.acquittementDate = obj.acquittementDate
        this.acquittementLogin = obj.acquittementLogin
        this.projection = obj.projection
        this.categoryWPName = obj.categoryWPName
        this.cmsDocument = obj.cmsDocument.map(doc => ({
            content: '',
            ...doc,
        }))
        this.categoryIcon = obj.categoryIcon
        this.modelId = obj.modelId
        this.hasTrad = obj.hasTrad
        this.cmsMessages = obj.cmsMessages ? obj.cmsMessages.map(e => new DtoCMSMessage(e)) : []
        this.cmsFollowers = obj.cmsFollowers
        this.cmsTrads = obj.cmsTrads || []
        this.cmsLinked = obj.cmsLinked || []
    }
}
