export default class DtoProductionUnitEvent {
    constructor(obj) {
        this.id = obj.number
        this.code = obj.code
        this.number = obj.number
        this.eventType = obj.eventType
        this.date = obj.date
        this.eventDate = obj.date
        this.volt = obj.volt
        this.comment = obj.comment
        this.chgtpiles = obj.chgtpiles
        this.graph = obj.graph
        this.bankExport = obj.bankExport
        this.eventType = obj.eventType
        this.problem = obj.problem
        this.solution = obj.solution
        this.eventHour = obj.eventHour
        this.eventMinutes = obj.eventMinutes
        this.razstation = obj.razstation
        this.valeurcalee = obj.valeurcalee
        this.sondeTemperature = obj.sondeTemperature
        this.centralTemperature = obj.centralTemperature
        this.contactCode = obj.contactCode
        this.ns = obj.ns
        this.nc = obj.nc
        this.action = obj.action
        this.campaignsCode = obj.campaignsCode
        this.diagnostic = obj.diagnostic
        this.diagnosticDeadline = obj.diagnosticDeadline
        this.updateLogin = obj.updateLogin
        this.updateDate = obj.updateDate
        this.stationType = obj.stationType
        this.stationCode = obj.stationCode
        this.stationId = obj.stationId
    }
}