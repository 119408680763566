import { Grid } from '@mui/material'
import { groupBy, isNil, isUndefined, orderBy, round, uniqBy } from 'lodash'
import { ParameterGraphModal } from 'quality/components/qualityComponents/ParameterGraph'
import DtoOperation from 'quality/dto/operation/DtoOperation'
import React, { useMemo, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import useBoolean from 'utils/customHook/useBoolean'
import { getDate } from 'utils/DateUtil'
import i18n from 'simple-react-i18n'
import PropTypes from 'prop-types'
import DtoQualityThreshold from 'quality/dto/QualityThreshold/DtoQualityThreshold'
import { statusIcon } from 'utils/StatusUtil'
import Icon from 'components/icon/Icon'
import laboPictos from 'assets/pictures/pictos/labo.png'
import MultiGridTableV2 from 'components/datatable/virtualizedTable/MultiGridTableV2'
import TooltipAnalyse from 'quality/components/qualityComponents/TooltipAnalyse'
import { calculateAverage, calculateGeometricAverage, calculateRangeInterquartile, calculateStandardDeviation, filterDetection, filterQuantification, filterResult, filterValid, getQualificationIcon, searchMaxValue, searchMinValue, searchP90Value } from 'utils/AnalyseUtils'
import useListIndexed from 'utils/customHook/useListIndexed'
import { getLabelTruncate } from 'utils/StoreUtils'
import { substringText } from 'utils/StringUtil'
import { getHardPiezoDataTypes } from 'utils/PiezometryUtils'
import DtoQualityThresholds from 'quality/dto/QualityThreshold/DtoQualityThresholds'
import PcMonitoringExportModal from 'quality/components/pcMonitoring/export/PcMonitoringExportModal'
import { exportModelFile, getModelFileType } from 'utils/ExportDataUtil'
import { usePiezoDatas } from '../hook/usePiezoDatas'
import { useHydroDatas } from '../hook/useHydroDatas'
import { usePluvioDatas } from '../hook/usePluvioDatas'
import { TmpActionConstant } from 'store/TmpReducer'
import moment from 'moment'
import { HYDRO, PIEZO, PLUVIO } from 'quality/constants/ChartConstant'
import { getHardHydroDataTypes } from 'utils/HydroUtils'

const GraphModal = ({
    isGraphOpen = false,
    closeGraph = () => { },
    analysis = [],
    thresholds,
    hydroDatas = [],
    piezoDatas = [],
    pluvioDatas = [],
    graphArgument = {},
    groupEquivalences = false,
}) => {
    const {
        piezometers,
        piezometryDataTypes,
        hydrometricStations,
        hydrometryDataTypes,
        pluviometers,
        pluviometryDataTypes,
    } = useSelector(store => ({
        piezometers: store.PiezometryReducer.piezometersLight,
        piezometryDataTypes: store.PiezometryReducer.piezometryDataTypes,
        hydrometricStations: store.HydrometryReducer.hydrometricStations,
        hydrometryDataTypes: store.HydrometryReducer.hydrometryDataTypes,
        pluviometers: store.PluviometryReducer.pluviometers,
        pluviometryDataTypes: store.PluviometryReducer.pluviometryDataTypes,
    }), shallowEqual)

    const piezometersIndex = useListIndexed(piezometers, 'id')
    const hydrometricStationsIndex = useListIndexed(hydrometricStations, 'id')
    const pluviometersIndex = useListIndexed(pluviometers, 'id')

    const filteredAnalysis = useMemo(() => {
        const {
            parameter,
            units = [],
            supports = [],
            fractions = [],
        } = graphArgument
        if (!parameter) {
            return []
        }
        return analysis
            .filter(a => a.parameter === parameter)
            .filter(a => !units.length ? isUndefined(a.unit) : units.includes(a.unit))
            .filter(a => !supports.length ? isUndefined(a.support) : supports.includes(a.support))
            .filter(a => !fractions.length ? isUndefined(a.fraction) : fractions.includes(a.fraction))
    }, [analysis, graphArgument])


    const dataTypesPiezo = useMemo(() => uniqBy([
        ...piezometryDataTypes,
        { id: 'piezometryChronic', label: i18n.chronic },
        ...getHardPiezoDataTypes(),
    ], 'id'), [piezometryDataTypes])

    const piezoDatasFormatted = useMemo(() => {
        return piezoDatas.map(piezoData => {
            const piezo = piezometersIndex[piezoData.id]
            if (!piezo) {
                return null
            }
            const { label, unit: unitPiezo } = dataTypesPiezo.find(t => t.id === piezoData.type) ?? {}
            return {
                name: piezo.name,
                unit: `${label ?? ''} ${unitPiezo ? `[${unitPiezo}]` : ''}`,
                showSymbol: false,
                dataType: PIEZO,
                dataList: piezoData.measures.map(m => ({
                    date: m.date,
                    value: m.NGF ?? m.value,
                })),
            }
        }).filter(p => p?.dataList.length)
    }, [dataTypesPiezo, piezoDatas, piezometersIndex])

    const dataTypesHydro = useMemo(() => uniqBy([...hydrometryDataTypes, ...getHardHydroDataTypes()], 'id'), [hydrometryDataTypes])

    const hydroDatasFormatted = useMemo(() => {
        return hydroDatas.map(hydroData => {
            const hydro = hydrometricStationsIndex[hydroData.id]
            if (!hydro) {
                return null
            }
            const { label, unit: unitHydro } = dataTypesHydro.find(t => t.id === hydroData.type) ?? {}
            return {
                name: hydro.name,
                unit: `${label ?? ''} ${unitHydro ? `[${unitHydro}]` : ''}`,
                showSymbol: false,
                dataType: HYDRO,
                dataList: hydroData.measures,
            }
        }).filter(h => h?.dataList.length)
    }, [dataTypesHydro, hydroDatas, hydrometricStationsIndex])

    const pluvioDatasFormatted = useMemo(() => {
        return pluvioDatas.map(pluvioData => {
            const pluvio = pluviometersIndex[pluvioData.id]
            if (!pluvio) {
                return null
            }
            const { label, unit: unitPluvio } = pluviometryDataTypes.find(t => t.id === pluvioData.type) ?? {}
            return {
                name: pluvio.name,
                unit: `${label ?? ''} ${unitPluvio ? `[${unitPluvio}]` : ''}`,
                dataType: PLUVIO,
                dataList: pluvioData.measures.map(m => ({ value: m.value, date: moment(m.date).add(pluvioData.offset ?? 0, 'day').valueOf() })),
                type: 'bar',
            }
        }).filter(p => p?.dataList.length)
    }, [pluvioDatas, pluviometersIndex, pluviometryDataTypes])

    return (
        <ParameterGraphModal
            isOpen={isGraphOpen}
            closeGraph={closeGraph}
            analysis={filteredAnalysis}
            thresholds={thresholds}
            additionalData={[...piezoDatasFormatted, ...hydroDatasFormatted, ...pluvioDatasFormatted]}

            graphOptions={{
                regroupAxis: groupEquivalences,
            }}
        />
    )
}

GraphModal.propTypes = {
    isGraphOpen: PropTypes.bool,
    closeGraph: PropTypes.func,
    analysis: PropTypes.arrayOf(PropTypes.shape({/* ...DtoAnalysisLight, ...calculateThresholdResult */ })),
    thresholds: PropTypes.arrayOf(PropTypes.instanceOf(DtoQualityThreshold)),
    hydroDatas: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        type: PropTypes.string,
        calculateFlux: PropTypes.bool,
        coeffFlux: PropTypes.number,
        measures: PropTypes.arrayOf(PropTypes.shape({})),
    })),
    piezoDatas: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        type: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
        ]),
        measures: PropTypes.arrayOf(PropTypes.shape({})),
    })),
    pluvioDatas: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        type: PropTypes.number,
        offset: PropTypes.number,
        cumul: PropTypes.number,
        measures: PropTypes.arrayOf(PropTypes.shape({})),
    })),
    groupEquivalences: PropTypes.bool,
    graphArgument: PropTypes.shape({
        parameter: PropTypes.string,
        units: PropTypes.arrayOf(PropTypes.string),
        supports: PropTypes.arrayOf(PropTypes.number),
        fractions: PropTypes.arrayOf(PropTypes.string),
    }),
}

const HeaderCellTootip = ({
    operation = {},
    analysis = [],
}) => {
    const {
        qualitometers,
        contributorsIndex,
        status,
        qualifications,
        points,
        associatedStationsPoints,
    } = useSelector(store => ({
        qualitometers: store.QualityReducer.qualitometersLight,
        contributorsIndex: store.ContributorReducer.contributorsIndex,
        status: store.QualityReducer.status,
        qualifications: store.QualityReducer.qualifications,
        points: store.QualityReducer.points,
        associatedStationsPoints: store.SuivipcReducer.associatedStationsPoints,
    }), shallowEqual)

    const qualito = qualitometers.find(({ id }) => id === operation.qualitometer)

    const producer = contributorsIndex[operation.producer]
    const producerLabel = producer && (producer.mnemonique || producer.name) || ''

    const laboIds = uniqBy(analysis, 'labo').map(a => a.labo).filter(id => !isUndefined(id))
    const laboLabels = laboIds.map(id => {
        const labo = contributorsIndex[id]
        return labo && (labo.mnemonique || labo.name) || ''
    })

    const allPoints = useMemo(() => [...points, ...associatedStationsPoints], [associatedStationsPoints, points])
    const point = allPoints.find(p => p.idPoint === operation.point && p.idQualitometer === operation.qualitometer)

    return (
        <Grid container direction='column' justifyContent='center' alignItems='flex-start'>
            <Grid item>
                {`[${qualito?.code || ''}] ${qualito?.name || ''}`}
            </Grid>
            <Grid item>
                {`${point?.identifiant ? `[${point?.identifiant}] ` : ''}${point?.name || ''}`}
            </Grid>
            <Grid item>
                {`${i18n.date}: ${getDate(operation.date)}`}
            </Grid>
            <Grid item>
                {`${i18n.status}: ${status.find(s => s.code === operation.status)?.name ?? ''}`}
            </Grid>
            <Grid item>
                {`${i18n.qualification}: ${qualifications.find(q => q.code === operation.qualification)?.name ?? ''}`}
            </Grid>
            <Grid item>
                {`${i18n.producer}: ${producerLabel}`}
            </Grid>
            <Grid item>
                {`${laboLabels.length > 1 ? i18n.laboratories : i18n.laboratory}: ${laboLabels.join(', ')}`}
            </Grid>
        </Grid>
    )
}

HeaderCellTootip.propTypes = {
    operation: PropTypes.instanceOf(DtoOperation),
    analysis: PropTypes.arrayOf(PropTypes.shape({/* ...DtoAnalysisLight, ...calculateThresholdResult */ })),
}

const HeaderCell = ({
    operation = {},
    analysis = [],
}) => {
    const dispatch = useDispatch()

    const {
        contributorsIndex,
        qualitometers,
        points,
        associatedStationsPoints,

        isEditMode = false,
        selectedOperations = {},
    } = useSelector(store => ({
        contributorsIndex: store.ContributorReducer.contributorsIndex,
        qualitometers: store.QualityReducer.qualitometersLight,
        points: store.QualityReducer.points,
        associatedStationsPoints: store.SuivipcReducer.associatedStationsPoints,

        isEditMode: store.TmpReducer.isEditMode,
        selectedOperations: store.TmpReducer.selectedOperations,
    }), shallowEqual)

    const qualito = qualitometers.find(({ id }) => id === operation.qualitometer)

    const producer = contributorsIndex[operation.producer]
    const producerLabel = producer && (producer.mnemonique || producer.name) || ''

    const laboIds = uniqBy(analysis, 'labo').map(a => a.labo).filter(id => !isUndefined(id))
    const laboLabels = laboIds.map(id => {
        const labo = contributorsIndex[id]
        return labo && (labo.mnemonique || labo.name) || ''
    })

    const allPoints = useMemo(() => [...points, ...associatedStationsPoints], [associatedStationsPoints, points])
    const point = allPoints.find(p => p.idPoint === operation.point && p.idQualitometer === operation.qualitometer)

    const codeOperation = `${operation.qualitometer}#${operation.id}`
    const isSelected = selectedOperations?.[codeOperation]

    return (
        <Grid
            container
            direction='column'
            justifyContent='center'
            alignItems='flex-start'
            onClick={e => {
                if (!isEditMode) {
                    return
                }
                e.stopPropagation()
                dispatch(TmpActionConstant.selectedOperation(codeOperation))
            }}
        >
            <Grid item sx={{ height: '18px' }}>
                <div className='valign-wrapper'>
                    {
                        isEditMode && (
                            <Icon style={{ fontSize: '16px' }} icon={isSelected ? 'check_box' : 'check_box_outline_blank'} />
                        )
                    }
                    <span style={{ fontSize: '12px' }}>{`[${qualito?.code || ''}] ${qualito?.name || ''}`}</span>
                </div>
            </Grid>
            <Grid item sx={{ height: '18px' }}>
                <span style={{ fontSize: '12px' }}>{`${point?.identifiant ? `[${point?.identifiant}] ` : ''}${point?.name || ''}`}</span>
            </Grid>
            <Grid item sx={{ height: '18px' }}>
                <div className='valign-wrapper'>
                    {statusIcon(operation, 15, false)}
                    <span style={{ paddingLeft: 5, fontSize: '12px' }}>{getDate(operation.date)}</span>
                </div>
            </Grid>
            <Grid item sx={{ height: '18px' }}>
                <div className='valign-wrapper'>
                    <Icon icon='widgets' style={{ fontSize: '15px' }} />
                    <span style={{ paddingLeft: 5, fontSize: '12px' }}>{producerLabel}</span>
                </div>
            </Grid>
            <Grid item sx={{ height: '18px' }}>
                <div className='valign-wrapper'>
                    <img className='clickable' src={laboPictos} style={{ height: '15px', width: '15px' }} />
                    <span style={{ paddingLeft: 5, fontSize: '12px' }}>{laboLabels.length > 1 ? `${laboLabels.length} ${i18n.labo}` : laboLabels[0]}</span>
                </div>
            </Grid>
        </Grid>
    )
}

HeaderCell.propTypes = {
    operation: PropTypes.instanceOf(DtoOperation),
    analysis: PropTypes.arrayOf(PropTypes.shape({/* ...DtoAnalysisLight, ...calculateThresholdResult */ })),
}

const ResultTable = ({
    analysis = [],
    operations = [],
    threshold,
    groupEquivalences = false,
    displayAdvancedStatistics = false,
    hydroDatas = [],
    piezoDatas = [],
    pluvioDatas = [],
    openGraph = () => {},
}) => {
    const {
        parameters,
        units,
        fractions,
        supports,
        settings,
        parameterGroupUsage,
        thresholds,
    } = useSelector(store => ({
        parameters: store.ParameterReducer.parameters,
        units: store.UnitReducer.units,
        fractions: store.FractionReducer.fractions,
        supports: store.SupportReducer.supports,
        settings: store.AdministrationReducer.settings,
        parameterGroupUsage: store.ParameterReducer.parameterGroupUsage,
        thresholds: store.QualityReducer.thresholds,
    }), shallowEqual)

    const parametersIndex = useListIndexed(parameters, 'code')
    const unitsIndex = useListIndexed(units, 'code')
    const fractionsIndex = useListIndexed(fractions, 'id')
    const supportsIndex = useListIndexed(supports, 'id')

    const uniqOperations = useMemo(() => {
        return uniqBy(analysis, a => `${a.operation}:${a.qualitometer}`)
    }, [analysis])

    const operationsFound = useMemo(() => {
        return uniqOperations.map(a => operations.find(op => op.id === a.operation && op.qualitometer === a.qualitometer)).filter(o => !!o)
    }, [uniqOperations, operations])

    const operationsDatesSort = useMemo(() => {
        return orderBy(operationsFound, ['date', 'qualitometer'], ['desc', 'asc']).map(op => `${getDate(op.date)}#:#${op.qualitometer}#:#${op.id}`)
    }, [operationsFound])

    const shouldCalculateFlow = hydroDatas.some(({ calculateFlux }) => calculateFlux)

    const piezoDatasFormatted = usePiezoDatas(piezoDatas, operationsDatesSort)
    const hydroDatasFormatted = useHydroDatas(hydroDatas, operationsDatesSort)
    const pluvioDatasFormatted = usePluvioDatas(pluvioDatas, operationsDatesSort)

    const formatUnitLabel = unit => {
        if (!unit) {
            return ''
        }
        if (shouldCalculateFlow) {
            return `${unit.symbol ?? ''} * m3/s`
        }
        return `${unit.symbol ?? ''} [${unit.code}]`
    }

    const data = useMemo(() => {
        const analysisGroupByParameter = groupBy(analysis, ({ parameter, unit, fraction, support }) => groupEquivalences ? parameter : `${parameter}#:#${unit}#:#${fraction}#:#${support}`)
        return Object.keys(analysisGroupByParameter).map(paramKey => {
            const analysisGroup = analysisGroupByParameter[paramKey].map(analyse => {
                const key = `${getDate(analyse.sampleDate)}#:#${analyse.qualitometer}#:#${analyse.operation}`
                const hydroValue = hydroDatasFormatted.reduce((res, { [key]: { shouldCalculate, resultWithFlux } }) => shouldCalculate ? res + resultWithFlux : res, 0)
                const valueFlux = shouldCalculateFlow ? `${round(parseFloat(analyse.value.replace('<', '').replace('>', '')) * hydroValue, 5)}` : analyse.value
                return {
                    ...analyse,
                    valueFlux,
                }
            })

            const [parameterCode] = paramKey.split('#:#')
            const parameter = parametersIndex[parameterCode]
            const parameterLabel = parameter?.shortLabel || parameter?.name

            const valuesObj = analysisGroup.reduce((acc, analyse) => {
                const icon = getQualificationIcon(analyse.qualification)

                const key = `${getDate(analyse.sampleDate)}#:#${analyse.qualitometer}#:#${analyse.operation}`
                acc[key] = {
                    value: analyse.qualification !== 1 ? (
                        <div>
                            <i className='material-icons tiny right no-margin'>{icon}</i>
                            <span className='right' style={{ marginRight: '5px' }}>{analyse.valueFlux}</span>
                        </div>
                    ) : analyse.valueFlux,
                    positionCell: 'right',
                    sortValue: analyse.result,
                    classNameColor: analyse.color === 'white' && isUndefined(analyse.result) ? 'grey' : analyse.color,
                    tooltip: () => (
                        <TooltipAnalyse
                            title={parameter?.shortLabelWithCode || ''}
                            analyse={analyse}
                            threshold={analyse.threshold}
                        />
                    ),
                    result: analyse.value, // used for substance
                    parameter: analyse.parameter, // used for substance
                    thresholdValue: analyse.thresholdValue, // used for substance
                }
                return acc
            }, {})

            const unitCodes = uniqBy(analysisGroup, 'unit').map(a => a.unit).filter(u => !isUndefined(u))
            const labelUnits = unitCodes.map(code => formatUnitLabel(unitsIndex[code]) || `<${code}>`).join(', ')

            const fractionCodes = uniqBy(analysisGroup, 'fraction').map(a => a.fraction).filter(u => !isUndefined(u))
            const labelFractions = fractionCodes.map(code => fractionsIndex[code]?.labelWithCode || `<${code}>`).join(', ')
            const labelTruncateFractions = fractionCodes.map(code => {
                const fration = fractionsIndex[code]
                return getLabelTruncate(fration?.name, fration?.code, 15)
            }).join(', ')

            const supportCodes = uniqBy(analysisGroup, 'support').map(a => a.support).filter(u => !isUndefined(u))
            const labelSupports = supportCodes.map(code => supportsIndex[code]?.labelWithCode || `<${code}>`).join(', ')
            const labelTruncateSupports = supportCodes.map(code => {
                const support = supportsIndex[code]
                return getLabelTruncate(support?.name, support?.code, 15)
            }).join(', ')

            const group = parameterGroupUsage.find(p => p.parameter === parameterCode)

            const validAnalysis = filterValid(filterResult(analysisGroup))

            const p90 = searchP90Value(validAnalysis)
            const min = searchMinValue(validAnalysis)
            const max = searchMaxValue(validAnalysis)
            const average = calculateAverage(validAnalysis, settings)
            const geometricAverage = calculateGeometricAverage(validAnalysis)
            const rangeInterquartile = calculateRangeInterquartile(validAnalysis)
            const standardDeviation = calculateStandardDeviation(validAnalysis)

            const nbAnalysis = validAnalysis.length
            const nbQuantification = filterQuantification(validAnalysis).length
            const nbDetection = filterDetection(validAnalysis).length
            const nbOverrun = validAnalysis.filter(a => !['blue', 'white'].includes(a.color)).length

            const overrunTx = nbAnalysis ? `${round(nbOverrun * 100 / nbAnalysis, 2)} %` : '0%'
            const quantTx = nbAnalysis ? `${round(nbQuantification * 100 / nbAnalysis, 3)} %` : '0%'
            const detectTx = nbAnalysis ? `${round(nbDetection * 100 / nbAnalysis, 3)} %` : '0%'

            return {
                ...valuesObj,
                parameter: {
                    value: shouldCalculateFlow ? `${i18n.fluxOf} ${parameterLabel}` : getLabelTruncate(parameterLabel, parameterCode, 20),
                    code: parameterCode,
                    tooltip: shouldCalculateFlow ? `${i18n.fluxOf} ${parameter?.name || ''}` : parameter?.name,
                },
                group: {
                    value: getLabelTruncate(group?.groupName, group?.groupCode, 15),
                    tooltip: group?.labelWithCode,
                },
                unit: {
                    value: substringText(labelUnits, 26),
                    codes: unitCodes,
                    tooltip: labelUnits,
                },
                fraction: {
                    value: substringText(labelTruncateFractions, 26),
                    codes: fractionCodes,
                    tooltip: labelFractions,
                },
                support: {
                    value: substringText(labelTruncateSupports, 26),
                    codes: supportCodes,
                    tooltip: labelSupports,
                },
                min: { value: min, classNameColor: 'grey', positionCell: 'right' },
                averageShort: { value: average, classNameColor: 'grey', positionCell: 'right' },
                max: { value: max, classNameColor: 'grey', positionCell: 'right' },
                percentile90: { value: p90, classNameColor: 'grey', positionCell: 'right' },
                geometricAverageShort: { value: geometricAverage, classNameColor: 'grey', positionCell: 'right' },
                rangeInterquartile: { value: rangeInterquartile, classNameColor: 'grey', positionCell: 'right' },
                standardDeviation: { value: standardDeviation, classNameColor: 'grey', positionCell: 'right' },
                overrunNb: { value: nbOverrun, classNameColor: 'grey', positionCell: 'right' },
                overrunTx: { value: overrunTx, classNameColor: 'grey', positionCell: 'right' },
                nbAnalysis: { value: nbAnalysis, classNameColor: 'grey', positionCell: 'right' },
                nbQuant: { value: nbQuantification, classNameColor: 'grey', positionCell: 'right' },
                nbDetect: { value: nbDetection, classNameColor: 'grey', positionCell: 'right' },
                quantTx: { value: quantTx, classNameColor: 'grey', positionCell: 'right' },
                detectTx: { value: detectTx, classNameColor: 'grey', positionCell: 'right' },
            }
        })
    }, [analysis, groupEquivalences, parametersIndex, settings, parameterGroupUsage, unitsIndex, fractionsIndex, supportsIndex, hydroDatasFormatted, shouldCalculateFlow])

    const dataOrder = useMemo(() => {
        return orderBy(data, d => d.parameter.code)
    }, [data])

    const substances = useMemo(() => {
        if (!threshold) {
            return []
        }
        const thresholdFound = thresholds.find(t => `${t.code}` === threshold.thresholdCode)
        const analysisValues = operationsDatesSort.reduce((acc, key) => {
            const keyData = dataOrder.map(d => d[key]).filter(d => d?.classNameColor && !['white', 'blue', 'grey'].includes(d.classNameColor))
            const [date] = key.split('#:#')
            const overrunedParameters = keyData.map(d => {
                return (
                    <span>
                        {`\u2022 ${parametersIndex[d.parameter]?.displayName}: ${d.result} - ${i18n.threshold}(${d.thresholdValue})`}
                        <br />
                    </span>
                )
            })
            acc[key] = {
                value: keyData.length,
                date,
                positionCell: 'right',
                classNameColor: overrunedParameters.length > 0 ? 'red' : 'grey',
                tooltip: (
                    <div>
                        <span>
                            {
                                overrunedParameters.length > 0 ? (
                                    <>
                                        {`${i18n.overrunedParameters}: `}
                                        <br />
                                        {overrunedParameters}
                                    </>
                                ) : (
                                    <>
                                        {i18n.noOverrun}
                                        <br />
                                    </>
                                )
                            }
                        </span>
                    </div>
                ),
            }
            return acc
        }, {})
        const values = operationsDatesSort.map(key => analysisValues[key].value)
        const overrunNb = values.filter(v => v !== 0).length

        const overrunTx = values.length ? `${round(overrunNb * 100 / values.length, 2)} %` : '0%'
        return [{
            parameter: { value: thresholdFound?.name, classNameColor: 'grey' },
            group: { value: '', classNameColor: 'grey' },
            unit: { value: '', classNameColor: 'grey' },
            support: { value: '', classNameColor: 'grey' },
            fraction: { value: '', classNameColor: 'grey' },
            ...analysisValues,
            overrunTx: {
                value: overrunTx,
                classNameColor: 'grey',
                positionCell: 'right',
            },
            overrunNb: {
                value: overrunNb,
                classNameColor: 'grey',
                positionCell: 'right',
            },
        }]
    }, [threshold, thresholds, operationsDatesSort, dataOrder, parametersIndex])

    const customHeaders = useMemo(() => {
        const groupAnalysis = groupBy(analysis, a => `${a.qualitometer}#${a.operation}`)
        const operationHeaders = operationsFound.reduce((acc, op) => {
            const filteredAnalysis = groupAnalysis[`${op.qualitometer}#${op.id}`]
            acc[`${getDate(op.date)}#:#${op.qualitometer}#:#${op.id}`] = {
                value: (
                    <HeaderCell
                        operation={op}
                        analysis={filteredAnalysis}
                    />
                ),
                tooltip: (<HeaderCellTootip operation={op} analysis={filteredAnalysis} />),
            }
            return acc
        }, {})

        const statHeaders = {
            nbCounting: {
                style: {
                    whiteSpace: 'pre-wrap',
                },
            },
        }
        return {
            ...operationHeaders,
            ...statHeaders,
        }
    }, [analysis, operationsFound])

    const advancedStatisticsHeaders = displayAdvancedStatistics ? ['rangeInterquartile', 'standardDeviation', 'overrunNb', 'overrunTx'] : []
    const statsHeaders = ['min', 'averageShort', 'max', 'percentile90', 'geometricAverageShort', ...advancedStatisticsHeaders, 'nbDetect', 'nbQuant', 'nbAnalysis', 'detectTx', 'quantTx']
    const headers = ['group', 'parameter', 'unit', 'support', 'fraction', ...operationsDatesSort, ...statsHeaders]

    return (
        <MultiGridTableV2
            data={[
                ...substances,
                ...piezoDatasFormatted,
                ...pluvioDatasFormatted,
                ...hydroDatasFormatted,
                ...dataOrder,
            ]}
            customHeaders={customHeaders}
            headers={headers}
            fixedColumnCount={5}
            headerHeight={95}
            bodyHeight={'57%'}
            onClick={({ parameter, unit, support, fraction }) => {
                if (isUndefined(parameter.code)) {
                    return
                }
                openGraph({
                    parameter: parameter.code,
                    units: unit.codes,
                    supports: support.codes,
                    fractions: fraction.codes,
                })
            }}
        />
    )
}

ResultTable.propTypes = {
    analysis: PropTypes.arrayOf(PropTypes.shape({/* DtoAnalysisLight + calculateThresholdResult */ })),
    operations: PropTypes.arrayOf(PropTypes.instanceOf(DtoOperation)),
    threshold: PropTypes.instanceOf(DtoQualityThresholds),
    groupEquivalences: PropTypes.bool,
    displayAdvancedStatistics: PropTypes.bool,
    hydroDatas: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        type: PropTypes.string,
        calculateFlux: PropTypes.bool,
        coeffFlux: PropTypes.number,
        measures: PropTypes.arrayOf(PropTypes.shape({})),
    })),
    piezoDatas: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        type: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
        ]),
        measures: PropTypes.arrayOf(PropTypes.shape({})),
    })),
    pluvioDatas: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        type: PropTypes.number,
        offset: PropTypes.number,
        cumul: PropTypes.number,
        measures: PropTypes.arrayOf(PropTypes.shape({})),
    })),
    openGraph: PropTypes.func,
}

const PcMonitoringTable = ({
    analysis = [],
    operations = [],
    threshold,
    groupEquivalences = false,
    displayAdvancedStatistics = false,
    hydroDatas = [],
    piezoDatas = [],
    pluvioDatas = [],

    isExportModalOpen = false,
    closeExportModal = () => { },

    filter = {},
}) => {
    const dispatch = useDispatch()
    const {
        qualitometer,
        typeEnvironmentModels,
    } = useSelector(store => ({
        qualitometer: store.QualityReducer.qualitometer,
        typeEnvironmentModels: store.ExportReducer.typeEnvironmentModels,
    }), shallowEqual)

    const {
        value: isGraphOpen,
        setTrue: openGraph,
        setFalse: closeGraph,
    } = useBoolean(false)
    const [graphArgument, setGraphArgument] = useState({})

    const exportModel = useMemo(() => typeEnvironmentModels.map((model) => {
        const fileNameSplit = model.split('.')
        const name = fileNameSplit.slice(0, -1).join('')
        const ext = fileNameSplit[fileNameSplit.length - 1]
        return {
            name,
            formats: [{
                type: getModelFileType(ext),
                callback: () => exportModelFile({
                    stationId: qualitometer.id.toString(),
                    stationCode: qualitometer.code,
                    stationType: qualitometer.typeName,
                    environmentModels: typeEnvironmentModels,
                    filenameModelExport: model,
                    qualityFilter: {
                        stations: [qualitometer.id],
                        startDate: filter.startDate,
                        endDate: filter.endDate,
                        parameters: filter.parameters,
                        selectionCode: filter.selection === '-1' ? undefined : filter.selection,
                        groupCode: filter.group,
                        threshold: filter.threshold,

                        producers: filter.producers,
                        laboratories: filter.laboratories,
                        point: filter.point,
                        campaign: filter.campaign,
                        support: isNil(filter.support) ? undefined : `${filter.support}`,
                        fraction: filter.fraction,
                        status: filter.status,
                        qualification: filter.qualification,

                        quantificationControl: filter.quantificationControl,
                        detectionControl: filter.detectionControl,
                    },
                }),
            }],
        }
    }), [dispatch, qualitometer.code, qualitometer.id, qualitometer.typeName, typeEnvironmentModels, filter])

    return (
        <div style={{ paddingBottom: '100px' }}>
            <ResultTable
                analysis={analysis}
                operations={operations}
                threshold={threshold}

                hydroDatas={hydroDatas}
                piezoDatas={piezoDatas}
                pluvioDatas={pluvioDatas}

                groupEquivalences={groupEquivalences}
                displayAdvancedStatistics={displayAdvancedStatistics}

                openGraph={argument => {
                    setGraphArgument(argument)
                    openGraph()
                }}
            />
            <GraphModal
                isGraphOpen={isGraphOpen}
                closeGraph={closeGraph}

                analysis={analysis}
                thresholds={threshold?.thresholds}

                hydroDatas={hydroDatas}
                piezoDatas={piezoDatas}
                pluvioDatas={pluvioDatas}

                groupEquivalences={groupEquivalences}
                graphArgument={graphArgument}
            />
            <PcMonitoringExportModal
                analysis={analysis}
                operations={operations}
                threshold={threshold}

                hydroDatas={hydroDatas}
                piezoDatas={piezoDatas}
                pluvioDatas={pluvioDatas}

                groupEquivalences={groupEquivalences}
                exportModel={exportModel}

                isExportModalOpen={isExportModalOpen}
                closeExportModal={closeExportModal}
            />
        </div>
    )
}

PcMonitoringTable.propTypes = {
    analysis: PropTypes.arrayOf(PropTypes.shape({/* DtoAnalysisLight + calculateThresholdResult */ })),
    operations: PropTypes.arrayOf(PropTypes.instanceOf(DtoOperation)),
    threshold: PropTypes.instanceOf(DtoQualityThresholds),
    groupEquivalences: PropTypes.bool,
    displayAdvancedStatistics: PropTypes.bool,
    hydroDatas: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        type: PropTypes.string,
        calculateFlux: PropTypes.bool,
        coeffFlux: PropTypes.number,
        measures: PropTypes.arrayOf(PropTypes.shape({})),
    })),
    piezoDatas: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        type: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
        ]),
        measures: PropTypes.arrayOf(PropTypes.shape({})),
    })),
    pluvioDatas: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        type: PropTypes.number,
        offset: PropTypes.number,
        cumul: PropTypes.number,
        measures: PropTypes.arrayOf(PropTypes.shape({})),
    })),

    isExportModalOpen: PropTypes.bool,
    closeExportModal: PropTypes.func,

    filter: PropTypes.shape({}),
}

export default PcMonitoringTable