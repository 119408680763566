import React, { Component } from 'react'

class SieauTooltip extends Component {
    constructor() {
        super()
        this.state = {
            content: null,
            visible: false,
            hidenFirst: false,
            top: 0,
            left: 0,
            position: 'top',
        }
    }

    setTooltip = (id, content, position='top') => {
        if (!id) {
            this.setState({ visible: false, hidenFirst: true, top: 0, left: 0 })
        } else {
            const coords = $(`#${id}`).offset() || {}
            this.setState({
                id,
                visible: true,
                content,
                top: coords.top,
                left: coords.left,
                position,
            })
        }
    }

    render() {
        if (this.state.visible) {
            if (this.state.hidenFirst) {
                const id = this.state.id
                setTimeout(() => {
                    if (id === this.state.id) {
                        this.setState({ hidenFirst: false })
                    }
                }, 400)
            }
            return (
                <div
                    id='sieautooltip'
                    style={{
                        position: 'absolute',
                        backgroundColor: '#292c4e',
                        color: 'white',
                        borderRadius: '4px',
                        padding: 10,
                        pointerEvents: 'none',
                        WebkitAnimationDuration: '500ms',
                        animationDuration: '500ms',
                        WebkitAnimationFillMode: 'both',
                        animationFillMode: 'both',
                        zIndex: 1000,
                        display: this.state.hidenFirst ? 'none' : null,
                        whiteSpace: 'pre-wrap',
                    }}
                    className='fadeIn'
                >
                    { this.state.content }
                </div>
            )
        }
        return <div id='sieautooltip' style={ { pointerEvents: 'none' } }/>
    }

    getTop(pos = null) {
        const position = pos || this.state.position
        const tooltip = $('#sieautooltip')
        const tooltipHeight = tooltip.outerHeight()
        const bodyHeight = $('body').innerHeight() > window.innerHeight ? $('body').innerHeight() : window.innerHeight
        switch (position) {
            case 'top':
                return this.state.top - tooltipHeight - 10
            case 'bottom':
                const targetHeight = $(`#${this.state.id}`).outerHeight()
                return this.state.top + targetHeight + 10
            default:
                const topValue = this.state.top + ($(`#${this.state.id}`).outerHeight() / 2) - (tooltip.outerHeight() / 2)
                if (topValue + tooltipHeight >= bodyHeight) {
                    return bodyHeight - tooltipHeight - 5
                } else if (topValue < 0) {
                    return 5
                }
                return topValue
        }
    }

    getLeft(pos = null) {
        const position = pos || this.state.position
        const tooltip = $('#sieautooltip')
        const tooltipWidth = tooltip.outerWidth()
        const targetWidth = $(`#${this.state.id}`).outerWidth()
        const bodyWidth = $('body').innerWidth()
        switch (position) {
            case 'left':
                return this.state.left - tooltipWidth - 10
            case 'right':
                return this.state.left + targetWidth + 10
            default:
                const leftValue = this.state.left + ($(`#${this.state.id}`).outerWidth() / 2) - (tooltip.outerWidth() / 2)
                if (leftValue + tooltipWidth >= bodyWidth) {
                    return bodyWidth - tooltipWidth - 5
                } else if (leftValue < 0) {
                    return 5
                }
                return leftValue
        }
    }

    getTooltipCoords() {
        const t = this.getTop()
        const l = this.getLeft()
        const bodyWidth = $('body').innerWidth()
        if ((this.state.position === 'right' && (l + $('#sieautooltip').outerWidth()) >= bodyWidth)
                || (this.state.position === 'left' && l < 0)) {
            return {
                top: this.getTop('top'),
                left: this.getLeft('top'),
            }
        } else if (this.state.position === 'top' && t < 0) {
            return {
                top: this.getTop('bottom'),
                left: this.getLeft('bottom'),
            }
        } else if (this.state.position === 'bottom' && (t + $('#sieautooltip').outerHeight()) > $('body').innerHeight()) {
            return {
                top: this.getTop('top'),
                left: this.getLeft('top'),
            }
        }
        return { top: t, left: l }
    }

    componentDidUpdate(prevProps, prevState) {
        if ((!prevState.visible && this.state.visible) || (prevState.hidenFirst && !this.state.hidenFirst)) {
            const tooltip = $('#sieautooltip')
            const { top, left } = this.getTooltipCoords()

            tooltip.css({ top: `${top}px`, left: `${left}px`, 'z-index': 99999999 })
        }
    }
}

SieauTooltip.propTypes = {
}

export default SieauTooltip
//