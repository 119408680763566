/* eslint-disable max-nested-callbacks */
/* eslint-disable consistent-return */
import { Accordion, Card, DialogContent, Dialog, Grid2, CardContent, Icon } from '@mui/material'
import SimpleTabList from 'components/list/SimpleTabList'
import { DefaultDialogTitle } from 'components/styled/Dialog'
import { push } from '@lagunovsky/redux-react-router'
import { flattenDeep, groupBy, isNil, keys, orderBy } from 'lodash'
import Moment from 'moment'
import { extendMoment } from 'moment-range'
import 'moment/locale/fr'
import PropTypes from 'prop-types'
import React, { useEffect, useMemo, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import useActions from 'utils/customHook/useActions'
import useBoolean from 'utils/customHook/useBoolean'
import { exportFile, formatData } from 'utils/ExportDataUtil'
import { searchAllCharacters } from 'utils/StringUtil'
import CampaignAction from '../../../campaign/actions/CampaignAction'
import Checkbox from '../../../components/forms/Checkbox'
import Input from '../../../components/forms/Input'
import CartographyPanel from '../../../components/map/CartographyPanel'
import ImportXmlQualityPopup from '../../../import/components/popup/ImportXmlQualityPopup'
import { getDate } from '../../../utils/DateUtil'
import { hasValue } from '../../../utils/NumberUtil'
import { getLabel } from '../../../utils/StoreUtils'
import { getMarkerIcon } from '../../utils/CampaignUtils'
import DtoOperation from 'quality/dto/operation/DtoOperation'
import DtoAnalysisLight from 'quality/dto/analyse/DtoAnalysisLight'
import ExportFileModal from 'components/modal/ExportFileModal'
import ExportAction from 'export/actions/ExportAction'
import { getLocalizationLabel, getLocalizationPicto } from 'utils/AnalyseUtils'
import { AccordionTitle } from 'components/styled/Accordions'
import { PASTEL_COLOR } from 'utils/constants/ColorTheme'
import DtoEnvironmentalConditionLight from 'quality/dto/DtoEnvironmentalConditionLight'
import { ALIGN, NewTable, SORT, WrapperAccordionDetails } from 'components/datatable/NewTable'
import useListIndexed from 'utils/customHook/useListIndexed'
import { nbPerPageLabel } from 'referencial/constants/ReferencialConstants'
import AnalysisAction from 'quality/actions/AnalysisAction'
import OperationAction from 'quality/actions/OperationAction'
import QualityAction from 'quality/actions/QualityAction'
import useAbortController from 'utils/customHook/useAbortController'
import ProgressBar from 'components/progress/ProgressBar'
import MessageCard from 'components/card/MessageCard'

const moment = extendMoment(Moment)
moment.locale('fr')

const LIST = 'LIST'
const CARTO = 'CARTO'

const formatTooltip = (analyse, neededAnalyse) => {
    if (isNil(analyse)) return i18n.AnalysisNotMade

    const support = isNil(neededAnalyse.supportCode) || `${analyse.support}` === neededAnalyse.supportCode ? '' : i18n.incorrectSupport
    const fraction = isNil(neededAnalyse.fractionCode) || analyse.fraction === neededAnalyse.fractionCode ? '' : i18n.incorrectFraction
    const unit = isNil(neededAnalyse.unitCode) || analyse.unit === neededAnalyse.unitCode ? '' : i18n.incorrectUnit
    const localization = (neededAnalyse.placeCode ?? '0') === '0' || analyse.localization === neededAnalyse.placeCode ? '' : i18n.incorrectPlace

    return (
        <span style={{ whiteSpace: 'pre-wrap' }}>
            {[unit, support, fraction, localization].filter(v => !!v).join('\n')}
        </span>
    )
}

const getInfo = (isMissing, isPresent) => {
    if (isMissing && isPresent) return { value: (<Icon sx={{ color: 'orange' }}>error_outlined</Icon>), sortValue: 1, label: i18n.incomplete }
    if (!isPresent || isMissing) return { value: (<Icon sx={{ color: 'red' }}>close</Icon>), sortValue: 0, label: i18n.no }
    return { value: (<Icon sx={{ color: 'green' }}>check</Icon>), sortValue: 2, label: i18n.yes }
}

const OperationAccordion = ({
    neededAnalysis = [],
    missingAnalysis = [],
    operation = {},
    analysis = [],
    searchValue = '',
}) => {
    const dispatch = useDispatch()

    const {
        parameters,
        supports,
        units,
        fractions,
        qualitometers,
    } = useSelector(store => ({
        parameters: store.ParameterReducer.parameters,
        supports: store.SupportReducer.supports,
        units: store.UnitReducer.units,
        fractions: store.FractionReducer.fractions,
        qualitometers: store.QualityReducer.qualitometersLight,
    }), shallowEqual)

    const indexedParameters = useListIndexed(parameters, 'code')
    const indexedSupports = useListIndexed(supports)
    const indexedFractions = useListIndexed(fractions)
    const indexedUnits = useListIndexed(units)

    const accordionColor = useMemo(() => {
        const nbAnalysis = analysis.length
        if (nbAnalysis >= neededAnalysis.length) {
            return PASTEL_COLOR.green
        } else if (nbAnalysis < neededAnalysis.length && nbAnalysis !== 0) {
            return PASTEL_COLOR.orange
        } else if (nbAnalysis === 0) {
            return PASTEL_COLOR.red
        }
        return PASTEL_COLOR.blue
    }, [analysis.length, neededAnalysis.length])

    const formattedNeededAnalysis = orderBy(neededAnalysis, 'parameterCode').map(na => {
        const {
            parameterCode,
            supportCode,
            unitCode,
            fractionCode,
            placeCode,
        } = na
        const analyse = analysis.find(a => a.parameter === parameterCode)
        const isPresent = !isNil(analyse)
        const isMissing = missingAnalysis.some(ma => ma.parameter === parameterCode && ma.support === supportCode && ma.fraction === fractionCode && ma.unit === unitCode && ma.localization === placeCode)

        const labelParameter = indexedParameters[parameterCode]?.labelWithCode
        const labelUnit = indexedUnits[unitCode]?.symbolWithCode
        const labelSupport = indexedSupports[supportCode]?.labelWithCode
        const labelFraction = indexedFractions[fractionCode]?.labelWithCode

        const {
            value,
            sortValue,
        } = getInfo(isMissing, isPresent)

        return {
            validatedIcon: {
                value,
                sortValue,
                tooltip: isMissing ? formatTooltip(analyse, na) : undefined,
            },
            parameter: labelParameter,
            unit: labelUnit,
            support: labelSupport,
            fraction: labelFraction,
            localization: getLocalizationPicto(placeCode),

            searchValue: [labelParameter, labelUnit, labelSupport, labelFraction, getLocalizationLabel(placeCode)].map((s = '') => searchAllCharacters(s)).join('#'),
        }
    })

    const formattedSearchValue = searchAllCharacters(searchValue)
    const filteredNeededAnalysis = formattedNeededAnalysis.filter(na => na.searchValue.includes(formattedSearchValue))

    const nbValidated = formattedNeededAnalysis.reduce((nb, na) => na.validatedIcon.sortValue === 2 ? nb + 1 : nb, 0)

    return (
        <Accordion defaultExpanded={false}>
            <AccordionTitle
                title={getDate(operation.date)}
                subTitle={`${nbValidated}/${neededAnalysis.length}`}
                color={accordionColor}
                actions={[{
                    icon: 'launch',
                    color: 'white',
                    tooltip: i18n.accessToOperation,
                    onClick: () => dispatch(push(`/station/quality/${operation.qualitometer}/operation/${operation.id}`)),
                }, {
                    icon: 'file_download',
                    tooltip: i18n.export,
                    color: 'white',
                    onClick: () => {
                        const data = neededAnalysis.map(na => {
                            const {
                                parameterCode,
                                supportCode,
                                unitCode,
                                fractionCode,
                                placeCode,
                            } = na

                            const isPresent = analysis.some(a => a.parameter === parameterCode)
                            const isMissing = missingAnalysis.some(ma => ma.parameter === parameterCode && ma.support === supportCode && ma.fraction === fractionCode && ma.unit === unitCode && ma.localization === placeCode)

                            const labelParameter = indexedParameters[parameterCode]?.name
                            const labelUnit = indexedUnits[unitCode]?.symbol
                            const labelSupport = indexedSupports[supportCode]?.name
                            const labelFraction = indexedFractions[fractionCode]?.name

                            const {
                                label,
                            } = getInfo(isMissing, isPresent)

                            return {
                                parameterCode,
                                parameter: labelParameter,
                                codeUnit: unitCode,
                                unit: labelUnit,
                                supportCode,
                                support: labelSupport,
                                fractionCode,
                                fraction: labelFraction,
                                localization: getLocalizationLabel(placeCode),
                                analyzedParameters: label,
                            }
                        })
                        const qualitometer = qualitometers.find(q => q.id === operation.qualitometer)
                        exportFile({
                            data: data.length ? [
                                { ...data[0], headers: keys(data[0]) },
                                ...data.slice(1),
                            ] : [],
                            exportType: 'xlsx',
                            titleFile: `${qualitometer?.code || operation.qualitometer}_${i18n.operation}_${i18n.fromDate}_${getDate(operation.date)}`,
                        })
                    },
                }]}
            />
            <NewTable
                rows={filteredNeededAnalysis}
                headers={[{ key: 'validatedIcon', value: '' }, 'parameter', 'unit', 'support', 'fraction', 'localization']}
                defaultSort={{ column: 'validatedIcon', direction: SORT.ASC }}
                WrapperComponent={WrapperAccordionDetails}
            />
        </Accordion>
    )
}

OperationAccordion.propTypes = {
    neededAnalysis: PropTypes.arrayOf(PropTypes.shape({})),
    missingAnalysis: PropTypes.arrayOf(PropTypes.shape({})),
    operation: PropTypes.instanceOf(DtoOperation),
    analysis: PropTypes.arrayOf(PropTypes.instanceOf(DtoAnalysisLight)),
    searchValue: PropTypes.string,
}

const PopinOperation = ({
    isOpen = false,
    closePopin = () => {},
    plannings = [],
    planningsProgress = [],
    stationId,
    planningId,
}) => {
    const {
        controllerRef,
        initController,
    } = useAbortController()

    const {
        campaign,
        qualitometers,
    } = useSelector(store => ({
        campaign: store.CampaignReducer.campaign,
        qualitometers: store.QualityReducer.qualitometersLight,
    }), shallowEqual)

    const [searchValue, setSearchValue] = useState('')
    const {
        value: isLoading,
        setTrue: onLoadStart,
        setFalse: onLoadEnd,
    } = useBoolean(true)

    const [analysis, setAnalysis] = useState([])
    const [operations, setOperations] = useState([])

    const qualitometer = qualitometers.find(q => q.id === stationId)
    const {
        startDate,
        endDate,
        parametersPlan = [],
    } = plannings.find(cp => cp.id === planningId) ?? {}

    const {
        missingAnalysis = [],
    } = planningsProgress.find(pp => pp.id === planningId)?.stationsProgress.find(sp => sp.id === stationId) ?? {}

    useEffect(() => {
        setAnalysis([])
        setOperations([])

        if (!isOpen) return

        initController()
        onLoadStart()

        Promise.all([
            OperationAction.getOperations(stationId, controllerRef.current.signal)
                .then((list = []) => setOperations(list)),
            AnalysisAction.getAnalysis({ stations: [stationId], campaign: campaign.id, startDate, endDate, lightMode: true }, controllerRef.current.signal)
                .then((list = []) => setAnalysis(p => [...p, ...list.map(a => new DtoAnalysisLight(a))])),
            QualityAction.getEnvironmentalCondition({ stations: [stationId], campaign: campaign.id, startDate, endDate }, controllerRef.current.signal)
                .then((list = []) => setAnalysis(p => [...p, ...list.map(e => new DtoEnvironmentalConditionLight(e))])),
        ]).finally(onLoadEnd)

        // eslint-disable-next-line consistent-return
        return () => {
            controllerRef.current.abort()
        }
    }, [isOpen])

    const orderedOperations = orderBy(operations.filter(o => o.campaign === campaign.id && startDate <= o.date && o.date < endDate), 'date')

    const groupAnalysis = groupBy(analysis, 'operation')

    return (
        <Dialog
            maxWidth='lg'
            fullWidth
            open={isOpen}
            PaperProps={{
                sx: {
                    minHeight: '70vh',
                    maxHeight: '70vh',
                },
            }}
        >
            <DefaultDialogTitle
                title={`${getDate(startDate)} - ${getDate(endDate)} : [${qualitometer?.code ?? ''}] ${qualitometer?.name ?? ''}`}
                onClose={closePopin}
            />
            <DialogContent style={{ paddingTop: 10 }}>
                <Grid2 container spacing={0.5}>
                    <Grid2 size={6}>
                        <Input
                            title={i18n.search}
                            value={searchValue}
                            onChange={setSearchValue}
                        />
                    </Grid2>
                    {isLoading && (
                        <Grid2 size={12}>
                            <ProgressBar indeterminate />
                        </Grid2>
                    )}
                    {!isLoading && orderedOperations.map(operation => (
                        <Grid2 key={operation.id} size={12}>
                            <OperationAccordion
                                neededAnalysis={parametersPlan}
                                missingAnalysis={missingAnalysis}
                                operation={operation}
                                analysis={groupAnalysis[operation.id]}
                                searchValue={searchValue}
                            />
                        </Grid2>
                    ))}
                    {!isLoading && orderedOperations.length === 0 && (
                        <Grid2 size={12}>
                            <MessageCard>{i18n.noOperationsDuringThisPeriod}</MessageCard>
                        </Grid2>
                    )}
                </Grid2>
            </DialogContent>
        </Dialog>
    )
}

PopinOperation.propTypes = {
    isOpen: PropTypes.bool,
    closePopin: PropTypes.func,
    plannings: PropTypes.arrayOf(PropTypes.shape({})),
    planningsProgress: PropTypes.arrayOf(PropTypes.shape({})),
    stationId: PropTypes.number,
    planningId: PropTypes.number,
}

const TableStation = ({
    displayOnlyData = false,
    startDate,
    endDate,
    plannings = [],
    planningsProgress = [],
}) => {
    const dispatch = useDispatch()

    const {
        campaign,
        campaignStations,
        qualitometers,
        cities,
    } = useSelector(store => ({
        campaign: store.CampaignReducer.campaign,
        campaignStations: store.CampaignReducer.campaignStations,
        qualitometers: store.QualityReducer.qualitometersLight,
        cities: store.CityReducer.cities,
    }), shallowEqual)

    const indexedQualitometers = useListIndexed(qualitometers, 'id')
    const indexedCities = useListIndexed(cities, 'id')

    const {
        value: isOpen,
        setTrue: openPopin,
        setFalse: closePopin,
    } = useBoolean(false)
    const [selectedCell, setSelectedCell] = useState() // { stationId, planningId }

    const listStations = campaignStations.filter(cs => indexedQualitometers[cs.stationId]).map(cs => cs.stationId)

    const rows = plannings.map(cp => {
        const { stationsProgress = [] } = planningsProgress.find(pp => pp.id === cp.id) ?? {}
        const nbNeededParameters = cp.parametersPlan.length

        const completedStation = stationsProgress.reduce((nb, sp) => sp.missingAnalysis.length === 0 ? nb + 1 : nb, 0)

        return {
            planning: {
                value: `${getDate(cp.startDate)} - ${getDate(cp.endDate)}`,
                sortValue: cp.startDate,
            },
            executed: `${completedStation}/${cp.stationsPlan.length}`,
            ...listStations.reduce((acc, id) => {
                const sp = stationsProgress.find(s => s.id === id)
                acc[id] = {
                    value: isNil(sp) ? '' : `${nbNeededParameters - sp.missingAnalysis.length}/${nbNeededParameters}`,
                    color: isNil(sp) && PASTEL_COLOR.white || sp.missingAnalysis.length === 0 && PASTEL_COLOR.green || sp.missingAnalysis.length === nbNeededParameters && PASTEL_COLOR.red || PASTEL_COLOR.orange,
                    onClick: isNil(sp) ? undefined : () => {
                        setSelectedCell({ stationId: id, planningId: cp.id })
                        openPopin()
                    },
                }
                return acc
            }, {}),
        }
    })

    const headers = [
        { key: 'planning', width: 155, sticky: ALIGN.LEFT },
        { key: 'executed', sticky: ALIGN.LEFT },
        ...listStations.filter(id => !displayOnlyData || plannings.some(p => p.stationsPlan.includes(id))).map(id => {
            const qualito = indexedQualitometers[id]
            return {
                key: `${id}`,
                value: (
                    <div style={{ display: 'grid' }}>
                        {qualito?.code ?? ''}
                        <span style={{ fontWeight: 'normal', fontSize: 12 }}>{qualito?.name ?? ''}</span>
                    </div>
                ),
                tooltip: indexedCities[qualito.townCode]?.labelWithCode,
                onClick: () => dispatch(push(`/station/quality/${id}/suiviPC?campaign=${campaign.id}&startDate=${startDate}${endDate ? `&endDate=${endDate}` : ''}`)),
            }
        }),
    ]

    return (
        <>
            <NewTable
                rows={rows}
                headers={headers}

                defaultSort={{ column: 'planning', direction: SORT.ASC }}
                rowsPerPageOptions={nbPerPageLabel}
            />
            <PopinOperation
                isOpen={isOpen}
                closePopin={closePopin}
                plannings={plannings}
                planningsProgress={planningsProgress}
                stationId={selectedCell?.stationId}
                planningId={selectedCell?.planningId}
            />
        </>
    )
}

TableStation.propTypes = {
    displayOnlyData: PropTypes.bool,
    startDate: PropTypes.number,
    endDate: PropTypes.number,
    plannings: PropTypes.arrayOf(PropTypes.shape({})),
    planningsProgress: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        stationsProgress: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number,
            missingAnalysis: PropTypes.arrayOf(PropTypes.shape({})),
        })),
    })),
}

const CartoStation = ({

}) => {
    const {
        campaignStations,
        qualitometers,
    } = useSelector(store => ({
        campaignStations: store.CampaignReducer.campaignStations,
        qualitometers: store.QualityReducer.qualitometersLight,
    }), shallowEqual)

    const stations = campaignStations.map(cs => {
        const qualitometer = qualitometers.find(q => q.id == cs.stationId)
        if (!qualitometer) {
            return
        }
        return {
            ...qualitometer,
            markerIcon: getMarkerIcon('quality'),
        }
    }).filter(s => !!s)

    return (
        <CartographyPanel
            layers={['STATIONS_POINTS']}
            componentType={'quality'}
            stationsPoints={stations}
            stationsPanelTitle={i18n.quality}
            height={750}
        />
    )
}

const ExportModal = ({
    isOpen = false,
    close = () => {},

    plannings = [],
    planningsProgress = [],
}) => {
    const dispatch = useDispatch()

    const {
        campaignStations,
        qualitometers,
        supports,
        fractions,
        units,
        parameters,
    } = useSelector(store => ({
        campaignStations: store.CampaignReducer.campaignStations,
        qualitometers: store.QualityReducer.qualitometersLight,
        supports: store.SupportReducer.supports,
        fractions: store.FractionReducer.fractions,
        units: store.UnitReducer.units,
        parameters: store.ParameterReducer.parameters,
    }), shallowEqual)

    const indexedQualitometers = useListIndexed(qualitometers, 'id')

    const onExportTable = (exportFormat) => {
        const listStations = campaignStations.filter(cs => indexedQualitometers[cs.stationId]).map(cs => cs.stationId)

        const rows = plannings.map(cp => {
            const { stationsProgress = [] } = planningsProgress.find(pp => pp.id === cp.id) ?? {}
            const nbNeededParameters = cp.parametersPlan.length

            const completedStation = stationsProgress.reduce((nb, sp) => sp.missingAnalysis.length === 0 ? nb + 1 : nb, 0)

            return {
                planning: `${getDate(cp.startDate)} - ${getDate(cp.endDate)}`,
                executed: `${completedStation}/${cp.stationsPlan.length}`,
                ...listStations.reduce((acc, id) => {
                    const sp = stationsProgress.find(s => s.id === id)
                    acc[id] = {
                        value: isNil(sp) ? '' : `${nbNeededParameters - sp.missingAnalysis.length}/${nbNeededParameters}`,
                        color: isNil(sp) && 'white' || sp.missingAnalysis.length === 0 && 'green' || sp.missingAnalysis.length === nbNeededParameters && 'red' || 'orange',
                    }
                    return acc
                }, {}),
            }
        })

        const headers = [
            'planning',
            'executed',
            ...listStations.map(id => `${id}`),
        ]

        const customHeaders = [
            'planning',
            'executed',
            ...listStations.map(id => {
                const qualito = indexedQualitometers[id]
                return `[${qualito?.code ?? ''}] ${qualito?.name ?? ''}`
            }),
        ]

        const dataWithHeaders = rows.length ? [
            { ...rows[0], headers, customHeaders },
            ...rows.slice(1),
        ] : []

        dispatch(ExportAction.export(formatData(dataWithHeaders), exportFormat, i18n.tracking))
    }

    const onExportResultsMissing = (exportFormat) => {
        const rows = plannings.map(cp => {
            const { stationsProgress = [] } = planningsProgress.find(pp => pp.id === cp.id) ?? {}

            return stationsProgress.map(sp => sp.missingAnalysis.map(ma => ({
                stationCode: indexedQualitometers[sp.id]?.code,
                stationName: indexedQualitometers[sp.id]?.name,
                startDate: getDate(cp.startDate),
                endDate: getDate(cp.endDate),
                parameterCode: ma.parameter,
                parameter: getLabel(parameters, ma.parameter),
                codeUnit: ma.unit,
                unit: getLabel(units, ma.unit),
                supportCode: ma.support,
                support: getLabel(supports, ma.support),
                fractionCode: ma.fraction,
                fraction: getLabel(fractions, ma.fraction),
                place: getLocalizationLabel(ma.localization),
            })))
        })

        const flattenRows = flattenDeep(rows)

        const dataWithHeaders = flattenRows.length ? [
            { ...flattenRows[0], headers: keys(flattenRows[0]) },
            ...flattenRows.slice(1),
        ] : []

        dispatch(ExportAction.export(formatData(dataWithHeaders), exportFormat, i18n.tracking))
    }

    return (
        <ExportFileModal
            open={isOpen}
            onClose={close}
            data={[
                {
                    name: i18n.resultsTable,
                    formats: [{
                        type: i18n.excelFile,
                        callback: () => onExportTable('xlsx'),
                    }, {
                        type: i18n.csvFile,
                        callback: () => onExportTable('csv'),
                    }],
                },
                {
                    name: 'Résultat manquant',
                    formats: [{
                        type: i18n.excelFile,
                        callback: () => onExportResultsMissing('xlsx'),
                    }, {
                        type: i18n.csvFile,
                        callback: () => onExportResultsMissing('csv'),
                    }],
                },
            ]}
        />
    )
}

ExportModal.propTypes = {
    isOpen: PropTypes.bool,
    close: PropTypes.func,
    plannings: PropTypes.arrayOf(PropTypes.shape({})),
    planningsProgress: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        stationsProgress: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number,
            missingAnalysis: PropTypes.arrayOf(PropTypes.shape({})),
        })),
    })),
}

const CampaignTrackingOperation = ({
    planningsProgress = [],
}) => {
    const dispatch = useDispatch()

    const {
        campaign,
        campaignStations,
        qualitometers,
        campaignParameters,
        campaignPlannings,
    } = useSelector(store => ({
        campaign: store.CampaignReducer.campaign,
        campaignStations: store.CampaignReducer.campaignStations,
        qualitometers: store.QualityReducer.qualitometersLight,
        campaignParameters: store.CampaignReducer.campaignParameters,
        campaignPlannings: store.CampaignReducer.campaignPlannings,
    }), shallowEqual)

    const {
        value: isOpen,
        setTrue: openImport,
        setFalse: closePopin,
    } = useBoolean(false)

    const {
        value: isExportOpen,
        setFalse: closeExport,
        setTrue: openExport,
    } = useBoolean(false)

    const [displayOnlyData, setDisplayOnlyData] = useState(false)

    const startDate = moment(campaign.beginningApplication, 'DD/MM/YYYY').valueOf()
    const endDate = hasValue(campaign.endingApplication) ? moment(campaign.endingApplication, 'DD/MM/YYYY').valueOf() : undefined

    const plannings = useMemo(() => {
        if (campaignPlannings.length > 0) return campaignPlannings
        return [{
            campaignId: campaign.id,
            id: -1,
            startDate,
            endDate,
            parametersPlan: campaignParameters,
            stationsPlan: campaignStations.map(cs => cs.stationId),
        }]
    }, [campaign.id, campaignParameters, campaignPlannings, campaignStations, endDate, startDate])

    useActions(() => {
        return {
            import: openImport,
            exportList: [{
                onClick: openExport,
                label: i18n.excel,
            }],
        }
    }, [])

    return (
        <Grid2 container sx={{ padding: '5 10' }} spacing={2}>
            <Grid2 size={12}>
                <SimpleTabList
                    defaultTab={LIST}
                    tabs={[
                        {
                            constant: LIST,
                            label: i18n.table,
                            icon: 'list',
                        },
                        {
                            constant: CARTO,
                            label: i18n.map,
                            icon: 'map',
                        },
                    ]}
                    headerComponent={(
                        <Card>
                            <CardContent>
                                <Grid2 container alignItems='center'>
                                    <Grid2 size='auto'>
                                        <Checkbox
                                            checked={displayOnlyData}
                                            onChange={setDisplayOnlyData}
                                            label={i18n.showOnlyStationPlan}
                                        />
                                    </Grid2>
                                </Grid2>
                            </CardContent>
                        </Card>
                    )}
                >
                    {
                        tab => (
                            <>
                                {tab === LIST && (
                                    <TableStation
                                        displayOnlyData={displayOnlyData}

                                        startDate={startDate}
                                        endDate={endDate}
                                        plannings={plannings}
                                        planningsProgress={planningsProgress}
                                    />
                                )}
                                {tab === CARTO && (<CartoStation />)}
                            </>
                        )
                    }
                </SimpleTabList>
                <ImportXmlQualityPopup
                    campaignId={campaign.id}
                    callbackImport={() => dispatch(CampaignAction.fetchQualityCampaignOperation(campaign.id))}
                    stations={campaignStations.map(({ stationId }) => qualitometers.find(({ id }) => id === stationId)?.code).filter(c => !!c)}
                    isOpen={isOpen}
                    onClose={closePopin}
                />
                <ExportModal
                    isOpen={isExportOpen}
                    close={closeExport}

                    plannings={plannings}
                    planningsProgress={planningsProgress}
                />
            </Grid2>
        </Grid2>
    )
}

CampaignTrackingOperation.propTypes = {
    planningsProgress: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        stationsProgress: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number,
            missingAnalysis: PropTypes.arrayOf(PropTypes.shape({})),
        })),
    })),
}

export default CampaignTrackingOperation
