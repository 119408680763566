import PropTypes from 'prop-types'
import React, { useState } from 'react'
import i18n from 'simple-react-i18n'
import Card from 'components/card/Card'
import SimpleDatePicker from 'components/forms/SimpleDatePicker'
import Row from 'components/react/Row'
import { shallowEqual, useSelector } from 'react-redux'
import UserDto from 'administration/components/user/dto/UserDto'
import { Button, Grid } from '@mui/material'
import MultiContributorsAutocomplete from 'referencial/components/contributor/components/MultiContributorsAutocomplete'
import SuperMultiAutocomplete from 'components/forms/SuperMultiAutocomplete'

const emptyFilter = {
    startDate: null,
    endDate: null,
    users: [],
    contributors: [],
}

const StatsFilterPanel = ({
    onChange = () => { },
}) => {
    const [tmpFilter, setTmpFilter] = useState(emptyFilter)

    const {
        users,
        contributors,
    } = useSelector(store => ({
        users: store.UserReducer.users,
        contributors: store.ContributorReducer.contributors,
    }), shallowEqual)

    const onReset = () => {
        onChange(emptyFilter)
        setTmpFilter(emptyFilter)
    }

    return (
        <Row>
            <Card round>
                <Row className='padding-top-1'>
                    <Row>
                        <SimpleDatePicker
                            col={3}
                            value={tmpFilter.startDate}
                            label={i18n.startDate}
                            onChange={v => setTmpFilter({ ...tmpFilter, startDate: v })}
                            max={tmpFilter.endDate}
                        />
                        <SimpleDatePicker
                            col={3}
                            value={tmpFilter.endDate}
                            label={i18n.endDate}
                            onChange={v => setTmpFilter({ ...tmpFilter, endDate: v })}
                            min={tmpFilter.startDate}
                        />
                        <SuperMultiAutocomplete
                            col={3}
                            label={i18n.user}
                            options={users}
                            keyLabel='login'
                            keyValue='login'
                            onChange={values => setTmpFilter({ ...tmpFilter, users: values })}
                            values={tmpFilter.users}
                            multiple
                        />
                        <MultiContributorsAutocomplete
                            col={3}
                            multiple
                            options={contributors}
                            onChange={values => setTmpFilter({ ...tmpFilter, contributors: values })}
                        />
                    </Row>
                    <Grid container className='padding-1' justifyContent='flex-end'>
                        <Grid item>
                            <Button variant='outlined' onClick={onReset}>
                                {i18n.reinit}
                            </Button>
                        </Grid>
                        <Grid item className='padding-left-1'>
                            <Button variant='contained' onClick={() => onChange(tmpFilter)}>
                                {i18n.search}
                            </Button>
                        </Grid>
                    </Grid>
                </Row>
            </Card>
        </Row>
    )
}
StatsFilterPanel.propTypes = {
    onChange: PropTypes.func,
    users: PropTypes.arrayOf(PropTypes.instanceOf(UserDto)),
}

export default StatsFilterPanel