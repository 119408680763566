/* eslint-disable camelcase */
import { Button, Dialog, DialogActions, DialogContent, Grid2, Step, StepLabel, Stepper, Card as CardMUI } from '@mui/material'
import SieauParameterDto from 'administration/dto/SieauParameterDto'
import { push } from '@lagunovsky/redux-react-router'
import InstallationInfosPanel from 'installation/components/installations/components/InstallationInfosPanel'
import InstallationOuvragePanel from 'installation/components/installations/components/InstallationOuvragePanel'
import InstallationSampleCharacteristicsPanel from 'installation/components/installations/components/InstallationSampleCharacteristicsPanel'
import LocalisationMapPanel from 'installation/components/installations/components/LocalisationMapPanel'
import DtoInstallationWithGeoItem from 'installation/components/installations/dto/DtoInstallationWithGeoItem'
import { DEPARTEMENT_TAG } from 'installation/constants/InstallationConstants'
import DtoInstallation from 'installation/dto/installation/DtoInstallation'
import DtoInstallationTank from 'installation/dto/installation/DtoInstallationTank'
import { orderBy } from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import ContactDto from 'referencial/components/contact/dto/ContactDto'
import i18n from 'simple-react-i18n'
import StationTankPanel from 'station/components/link/StationTankPanel'
import StationLocationInfoPanel from 'station/components/location/StationLocationInfoPanel'
import { getSetting } from 'utils/SettingUtils'
import { hasValue } from 'utils/NumberUtil'
import WaitAction from 'wait/WaitAction'
import Card from '../../../components/card/Card'
import Select from '../../../components/forms/Select'
import ContactAction from '../../../referencial/components/contact/actions/ContactAction'
import ContactLightPanel from '../panels/ContactLightPanel'
import GeoApiAction from 'geoApi/action/GeoApiAction'
import DtoGeoApiTown from 'geoApi/DtoGeoApiTown'
import { DefaultDialogTitle } from 'components/styled/Dialog'

const STEP_MAP = 1
const STEP_LOCATION = 2
const STEP_DESCRIPTION = 3
const STEP_OWNER = 4
const STEP_CHARACTERISTICS = 5
const STEP_WORK = 6
const STEP_DETENTION = 7

class InstallationStepperModal extends Component {
    constructor(props) {
        super(props)
        const owner = props.contacts.find((c) => c.id === props.point.ownerCode) || {}
        this.state = {
            point: { typeName: 'installation', installationType: 18, ...props.point },
            maxIdTank: null,
            owner,
            step: STEP_MAP,
        }
    }

    componentDidMount() {
        const { point } = this.state
        const { applicationSettings, installationAgriTanks } = this.props
        const useAutomaticCodificationInstallation = ['True', '1', 'OUI', true].includes(getSetting(applicationSettings, 'useAutomaticCodificationInstallation') || false)
        const defaultDepartmentCodificationInstallation = getSetting(applicationSettings, 'defaultDepartmentCodificationInstallation') || ''
        if (!point.id && useAutomaticCodificationInstallation) {
            const code = this.getCodificationInst(defaultDepartmentCodificationInstallation)
            this.setState({
                point: {
                    ...point,
                    code,
                },
            })
        }
        this.setState({ maxIdTank: Math.max(...installationAgriTanks.map((p) => p.idTank)) + 1 })
    }

    getCodificationInst = (numDep) => {
        const { applicationSettings, installationsWithGeo } = this.props
        const prefixCodificationInstallation = getSetting(applicationSettings, 'prefixCodificationInstallation') || ''
        const prefixFormatted = prefixCodificationInstallation.includes(DEPARTEMENT_TAG) ? prefixCodificationInstallation.replace(DEPARTEMENT_TAG, numDep) : prefixCodificationInstallation

        const suffixCodificationInstallation = getSetting(applicationSettings, 'suffixCodificationInstallation') || ''
        const suffixFormatted = suffixCodificationInstallation.includes(DEPARTEMENT_TAG) ? suffixCodificationInstallation.replace(DEPARTEMENT_TAG, numDep) : suffixCodificationInstallation

        const minLengthCodificationInstallation = parseInt(getSetting(applicationSettings, 'minLengthCodificationInstallation') || 0)

        const maxId = Math.max(...installationsWithGeo.map(({ code = '' }) => {
            if (code.startsWith(prefixFormatted) && code.endsWith(suffixFormatted)) {
                const str = code.replace(prefixFormatted, '') || ''
                return parseInt(str.slice(0, str.length - suffixFormatted.length)) || 0
            }
            return 0
        })) + 1
        const newId = maxId.toString().length >= minLengthCodificationInstallation ? maxId : `${'0'.repeat(minLengthCodificationInstallation - maxId.toString().length)}${maxId}`
        return `${prefixFormatted}${newId}${suffixFormatted}`
    }

    onChangeValue = (obj) => {
        this.setState(({ point }) => ({
            point: {
                ...point,
                ...obj,
            },
        }))
    }

    onCancel = () => {
        this.resetState()
        this.props.onCancel()
    }

    formatPoint = (point) => {
        const { maxIdTank } = this.state
        return {
            ...point,
            link_geo: point.link_geo && point.link_geo.map((c) => {
                return {
                    ...c,
                    idStation: point.id || -1,
                }
            }),
            link_sampleCharacteristics: point.link_sampleCharacteristics && point.link_sampleCharacteristics.map((c) => {
                return {
                    ...c,
                    idInstallation: point.id || -1,
                }
            }),
            link_sampleCasing: point.link_sampleCasing && point.link_sampleCasing.map((c) => {
                return {
                    ...c,
                    idInstallation: point.id || -1,
                }
            }),
            link_sampleTanks: point.link_sampleTanks && point.link_sampleTanks.filter((t) => t.idTank || (!t.idTank && t.id && Object.keys(t).filter((key) => t[key]).length > 1)).map((t) => {
                return {
                    ...t,
                    idTank: t.idTank || maxIdTank,
                    idInstallation: point.id || -1,
                }
            }),
        }
    }

    savePoint = (point) => {
        this.props.onValidate(this.formatPoint(point))
    }

    onValidate = () => {
        const { point, owner } = this.state
        this.props.waitStart()
        if (owner.name) {
            if (!owner.id) {
                this.props.createContact(owner, 'noRedirection').then((ownerCode) => {
                    this.props.fetchContacts().then(() => {
                        this.savePoint({ ...point, ownerCode })
                    })
                })
            } else {
                this.props.updateContact(owner).then(() => {
                    this.props.fetchContacts().then(() => {
                        this.savePoint(point)
                    })
                })
            }
        } else {
            this.savePoint(point)
        }
    }

    onChangeInstallation = (updatedPoint) => {
        this.setState(({ point }) => ({ point: { ...point, ...updatedPoint } }))
    }

    onChangeLocation = (newGeoData) => {
        const { point } = this.state
        const geoData = point.link_geo && point.link_geo[0] || {}
        if (newGeoData) {
            this.setState({
                point: {
                    ...point,
                    link_geo: [{
                        ...geoData,
                        idStation: point.id,
                        parcel: newGeoData.parcel || null,
                        section: newGeoData.section || null,
                    }],
                    projection: newGeoData.projection,
                    x: newGeoData.x,
                    y: newGeoData.y,
                },
            })
        }
    }

    onChangeCity = (townCode) => {
        this.setState(({ point }) => ({
            point: {
                ...point,
                townCode,
                code: point.id ? point.code : this.getCodificationInst(townCode.slice(0, 2)),
            },
        }))
    }

    onSelectOwner = (owner) => {
        this.setState({ owner })
        this.onChangeInstallation({ ownerCode: owner.id })
    }

    onChangeOwner = (updatedOwner) => {
        this.setState(({ owner }) => ({ owner: { ...owner, ...updatedOwner } }))
    }

    onChangeInstallationLocation = (updatedInfo) => {
        const { point } = this.state
        const updatedPoint = { ...point, ...updatedInfo }
        if (updatedPoint.projection === 16 && hasValue(updatedInfo.x) && hasValue(updatedInfo.y) &&
            ((point.x !== updatedInfo.x) || (point.y !== updatedInfo.y))) {
            this.props.fetchTownsByCoordinate(updatedPoint.x, updatedPoint.y).then(() => {
                if (this.props.towns[0]) {
                    const code = point.id ? point.code : this.getCodificationInst(this.props.towns[0].code.slice(0, 2))
                    this.onChangeInstallation({ ...updatedPoint, code, townCode: this.props.towns[0].code })
                } else {
                    this.onChangeInstallation(updatedPoint)
                }
            })
        } else if (updatedInfo.townCode) {
            this.onChangeCity(updatedInfo.townCode)
        } else {
            this.onChangeInstallation(updatedPoint)
        }
    }

    getStep = () => {
        const { step, point, owner } = this.state
        const { contacts, citiesIndex, disabled } = this.props
        const params = {
            station: point,
            onChange: this.onChangeInstallation,
            onChangeVisit: () => { },
            disabled,
        }
        switch (step) {
            case STEP_MAP:
                return (
                    <div className='padding-top-1'>
                        <LocalisationMapPanel
                            installation={point}
                            onChangeLocation={this.onChangeLocation}
                            onChangeCity={this.onChangeCity}
                            disabled={disabled}
                            mapHeight='450px'
                        />
                    </div>
                )
            case STEP_LOCATION:
                return <CardMUI sx={{ marginTop: '10px' }}><StationLocationInfoPanel station={point} onChange={this.onChangeInstallationLocation} disabled={disabled} onChangeVisit={() => {}}/></CardMUI>
            case STEP_DESCRIPTION:
                return <CardMUI sx={{ marginTop: '10px' }}><InstallationInfosPanel {...params} newPoint={!point.id} noEditCode /></CardMUI>
            case STEP_OWNER:
                const contactsFormatted = contacts.map((c) => {
                    const cityFound = citiesIndex[c.cityCode]
                    return {
                        ...c,
                        labelAutocomplete: `${c.name}${cityFound ? ` - ${c.postalCode || ''} ${cityFound.name}` : ''}`,
                    }
                })
                return (
                    <Grid2 container className='padding-1'>
                        <Grid2 size={6}>
                            <Select
                                label={i18n.owner}
                                value={point.ownerCode}
                                options={contactsFormatted}
                                keyValue='id'
                                keyLabel='labelAutocomplete'
                                onChange={(_, obj) => this.onSelectOwner(obj || {})}
                                disabled={disabled}
                                clearFunction
                            />
                        </Grid2>
                        <Card title={owner.id ? i18n.change : i18n.create} className='margin-top-1' noMargin={false}>
                            <ContactLightPanel
                                contact={owner}
                                onChange={this.onChangeOwner}
                                disabled={disabled}
                            />
                        </Card>
                    </Grid2>
                )
            case STEP_CHARACTERISTICS:
                return <CardMUI sx={{ marginTop: '10px' }}><InstallationSampleCharacteristicsPanel {...params} /></CardMUI>
            case STEP_WORK:
                return <CardMUI sx={{ marginTop: '10px' }}><InstallationOuvragePanel {...params} /></CardMUI>
            case STEP_DETENTION:
                return (
                    <CardMUI sx={{ marginTop: '10px', padding: '10px' }}>
                        <StationTankPanel tank={point?.link_sampleTanks?.length ? orderBy(point.link_sampleTanks, 'idTank')[0] : {}} disabled={disabled} onChange={this.onChangeTank} />
                        <Grid2 container justifyContent='space-between'>
                            <p className='bold'>{`${i18n.nbTanks} : ${point?.link_sampleTanks?.length}`}</p>
                            {!disabled && point?.link_sampleTanks?.length ? (
                                <Button
                                    variant='contained'
                                    color='primary'
                                    onClick={() => {
                                        this.setState({
                                            point: {
                                                ...point,
                                                link_sampleTanks: orderBy(point.link_sampleTanks, 'idTank').slice(1),
                                            },
                                        })
                                    }}
                                >
                                    {i18n.removeTank}
                                </Button>
                            ) : null}
                            <Grid2 size={12}>
                                <span className='italic-label'>{i18n.onlyFirstTankShow}</span>
                            </Grid2>
                        </Grid2>
                    </CardMUI>
                )
            default:
                return null
        }
    }

    onChangeTank = (obj) => {
        const { point } = this.state
        const oldTank = point?.link_sampleTanks?.length ? point?.link_sampleTanks?.length[0] : {}
        const updateTank = {
            ...oldTank,
            ...obj,
        }
        const updatedTanks = point?.link_sampleTanks?.length ? point?.link_sampleTanks.slice(1) : []
        this.setState({
            point: {
                ...point,
                link_sampleTanks: [ updateTank, ...updatedTanks ],
            },
        })
    }

    onPrevStep = () => {
        this.setState(({ step }) => ({ step: step - 1 }))
    }

    onNextStep = () => {
        this.setState(({ step }) => ({ step: step + 1 }))
    }

    resetState = () => {
        this.setState({ point: null, owner: null })
    }

    onConsult = () => {
        const { point } = this.state
        this.resetState()
        this.props.push(`/station/installation/${point.id}/dashboard`)
    }

    render = () => {
        const { open, title, disabled } = this.props
        const { point, step } = this.state

        return (
            <Dialog
                fullWidth
                maxWidth='lg'
                open={open}
            >
                <DefaultDialogTitle
                    title={title || i18n.updatePointPrel}
                    onClose={this.onCancel}
                />
                <DialogContent>
                    <div className='row no-margin' style={{ height: '70vh', overflowY: 'auto' }}>
                        <Stepper
                            activeStep={step - 1}
                            style={{ zoom: '1.2', height: 30 }}
                        >
                            <Step
                                className='clickable'
                                onClick={() => {
                                    this.setState({ step: STEP_MAP })
                                }}
                            >
                                <StepLabel>{i18n.map}</StepLabel>
                            </Step>
                            <Step
                                className='clickable'
                                onClick={() => {
                                    this.setState({ step: STEP_LOCATION })
                                }}
                            >
                                <StepLabel>{i18n.localisation}</StepLabel>
                            </Step>
                            <Step
                                className='clickable'
                                onClick={() => {
                                    this.setState({ step: STEP_DESCRIPTION })
                                }}
                            >
                                <StepLabel>{i18n.descriptive}</StepLabel>
                            </Step>
                            <Step
                                className='clickable'
                                onClick={() => {
                                    this.setState({ step: STEP_OWNER })
                                }}
                            >
                                <StepLabel>{i18n.owner}</StepLabel>
                            </Step>
                            <Step
                                className='clickable'
                                onClick={() => {
                                    this.setState({ step: STEP_CHARACTERISTICS })
                                }}
                            >
                                <StepLabel>{i18n.characteristics}</StepLabel>
                            </Step>
                            <Step
                                className='clickable'
                                onClick={() => {
                                    this.setState({ step: STEP_WORK })
                                }}
                            >
                                <StepLabel>{i18n.work}</StepLabel>
                            </Step>
                            <Step
                                className='clickable'
                                onClick={() => {
                                    this.setState({ step: STEP_DETENTION })
                                }}
                            >
                                <StepLabel>{i18n.detention}</StepLabel>
                            </Step>
                        </Stepper>
                        {this.getStep()}
                    </div>
                </DialogContent>
                <DialogActions sx={{ justifyContent: 'space-between' }}>
                    <Button onClick={this.onCancel} color='primary' variant='outlined' >
                        {disabled ? i18n.close : i18n.cancel}
                    </Button>
                    <Button onClick={this.onPrevStep} color='primary' variant='outlined' disabled={step === STEP_MAP}>
                        {i18n.previous}
                    </Button>
                    <Button onClick={this.onNextStep} color='primary' variant='outlined' disabled={step === STEP_DETENTION}>
                        {i18n.next}
                    </Button>
                    {disabled ? (
                        <Button onClick={this.onConsult} variant='contained' color='primary'>
                            {i18n.consult}
                        </Button>
                    ) : (
                        <Button onClick={this.onValidate} variant='contained' color='primary' disabled={!point.code || disabled}>
                            {i18n.validate}
                        </Button>
                    )}
                </DialogActions>
            </Dialog>
        )
    }
}

InstallationStepperModal.propTypes = {
    open: PropTypes.bool,
    disabled: PropTypes.bool,
    point: PropTypes.instanceOf(DtoInstallation),
    onCancel: PropTypes.func,
    onValidate: PropTypes.func,
    citiesIndex: PropTypes.shape({}),
    contacts: PropTypes.arrayOf(PropTypes.instanceOf(ContactDto)),
    title: PropTypes.string,
    fetchContacts: PropTypes.func,
    createContact: PropTypes.func,
    updateContact: PropTypes.func,
    waitStart: PropTypes.func,
    fetchTownsByCoordinate: PropTypes.func,
    push: PropTypes.func,
    applicationSettings: PropTypes.arrayOf(PropTypes.instanceOf(SieauParameterDto)),
    installationsWithGeo: PropTypes.arrayOf(PropTypes.instanceOf(DtoInstallationWithGeoItem)),
    installationAgriTanks: PropTypes.arrayOf(PropTypes.instanceOf(DtoInstallationTank)),
    towns: PropTypes.arrayOf(PropTypes.instanceOf(DtoGeoApiTown)),
}

const mapStateToProps = store => ({
    citiesIndex: store.CityReducer.citiesIndex,
    contacts: store.ContactReducer.contacts,
    applicationSettings: store.AdministrationReducer.applicationSettings,
    installationsWithGeo: store.InstallationReducer.installationsWithGeo,
    installationAgriTanks: store.InstallationReducer.installationAgriTanks,
    towns: store.GeoApiReducer.towns,
})

const mapDispatchToProps = {
    fetchTownsByCoordinate: GeoApiAction.fetchTownsByCoordinate,
    fetchContacts: ContactAction.fetchContacts,
    createContact: ContactAction.createContact,
    updateContact: ContactAction.updateContact,
    waitStart: WaitAction.waitStart,
    push,
}

export default connect(mapStateToProps, mapDispatchToProps)(InstallationStepperModal)
