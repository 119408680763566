import DtoPrettyAnalysisLight from './DtoPrettyAnalysisLight'

export default class DtoQualitoDashboardStationsData {
    constructor(obj) {
        this.stationId = obj.stationId
        this.stationCode = obj.stationCode
        this.stationName = obj.stationName
        this.operations = obj.operations.map(op => ({
            year: op[0],
            nb: op[1],
            status: op[2],
            qualification: op[3]
        }))
        this.analysis = obj.analysis.map(a => new DtoPrettyAnalysisLight(a))
    }
}