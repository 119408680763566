import { INSTALLATION_TYPE } from '../../constants/InstallationConstants'

export default class DtoIndustrialSiteTableLine {
    constructor(obj) {
        this.id = obj[0] // Int,
        this.code = obj[1] // Option[String],
        this.siret = obj[2] // Option[String],
        this.name = obj[3] // Option[String],
        this.townCode = obj[4] // Option[String],
        this.townName = obj[5] // Option[String],
        this.staff = obj[6] // Option[Int],
        this.effectiveTranche = obj[7] // Option[Int],
        this.icpe = obj[8] // Option[Boolean],
        this.dischargeAgreement = obj[9] // Option[Boolean],
        this.x = obj[10] // Option[Double],
        this.y = obj[11] // Option[Double],
        this.projection = obj[12] // Option[Int],
        this.projectionLabel = obj[13] // Option[String],
        this.jobExecutionId = obj[14] // Option[Long],
        this.internalReferences = obj[15] // Option[String],
        this.status = obj[16] // Option[Int],
        this.statusLabel = obj[17] // Option[String],
        this.activityCode = obj[18] // Option[String],
        this.activityLibelle = obj[19] // Option[String],
        this.activityCode2 = obj[20] // Option[String],
        this.activityLibelle2 = obj[21] // Option[String],
        this.activityCode3 = obj[22] // Option[String],
        this.activityLibelle3 = obj[23] // Option[String],
        this.activityCode4 = obj[24] // Option[String],
        this.activityLibelle4 = obj[25] // Option[String]
        this.typeName = 'installation'
        this.installationType = INSTALLATION_TYPE.INDUSTRIAL_SITE

        this.headers = ['siret', 'name', 'city', 'activityLibelle', 'staff', 'effectiveTranche', 'icpeLabel', 'dischargeAgreementLabel']
        this.exportHeaders = [
            'siret', 'name', 'city', 'x', 'y', 'projectionLabel', 'activityCode', 'activityLibelle', 'activityCode2', 'activityLibelle2', 'activityCode3', 'activityLibelle3',
            'activityCode4', 'activityLibelle4', 'staff', 'effectiveTranche',
        ]
    }
}
