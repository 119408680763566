// new Select

import React, { useCallback, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import { flatten, isNil, orderBy, take } from 'lodash'
import { v4 as uuidv4 } from 'uuid'
import { Grid, Icon, Popper, TextField } from '@mui/material'
import { makeStyles, styled } from '@mui/styles'
import { hasValue } from 'utils/NumberUtil'
import { searchAllCharacters } from 'utils/StringUtil'
import { default as MuiAutocomplete, createFilterOptions } from '@mui/material/Autocomplete'
import DisplayedValue from './DisplayedValue'

const Listbox = styled('ul')({
    position: 'absolute',
    backgroundColor: 'white',
    width: 'fit-content',
    margin: '0',
    padding: '0 !important',
    border: '1px solid #53a1ff',
    maxHeight: '250px',
    overflowY: 'auto',
    minWidth: '100%',
    display: 'block',
    '& li': {
        cursor: 'pointer',
        height: '20px',
        fontWeight: 'normal',
        display: 'block',
        whiteSpace: 'nowrap',
        minHeight: '1.2em',
        padding: '0px 4px 1px',
        '&:hover': {
            background: '#e0e0e0 !important',
        },
        '&.selected_value': {
            background: '',
        },
    },
})

const StyledPopper = styled(Popper)({
    zIndex: '1605 !important',
})

const useStyles = makeStyles(() => ({
    root: {
        top: '-5px',
        position: 'relative',
        height: '46px',
        minWidth: '217px',

        '& .MuiInputLabel-outlined': {
            top: '-15px',
            color: ({ disabled }) => disabled && '#9e9e9e' || '#161832',
            fontWeight: 'bold',
            left: '2px',
            fontSize: '1rem',
        },
        '& .MuiInputLabel-outlined.Mui-focused': {
            color: '#53a1ff',
        },
    },
    inputRoot: {
        padding: '0 !important',
        top: '19px',
        backgroundColor: 'white',

        '& .MuiOutlinedInput-notchedOutline': {
            border: 'none !important',
        },

        '&.Mui-focused .MuiOutlinedInput-input': {
            borderColor: '#53a1ff !important',
        },
    },
    input: {
        height: '2rem !important',
        margin: '0 !important',
        borderRadius: '5px',
        paddingLeft: '5px',
        paddingRight: '5px',
        minHeight: '2rem',
        position: 'relative',
        width: '100%',
        '-webkit-text-fill-color': 'rgba(0,0,0,0.87) !important',
    },
    clearIndicator: {
        padding: '5 0 4 0 !important',
    },
    popupIndicator: {
        padding: '5 0 4 0 !important',
    },
}))

const Field = ({
    id = '',
    value = -1,
    label = '',
    options = [],
    onChange = () => { },
    disabled = false,
    displayWithCode = false,
    keyFilter = [],
    keyLabel = 'label',
    keyValue = 'id',
    nullLabel = '',
    obligatory = false,
    noNullValue,
    readMode = false,
    integerValue,
    stringValue,
    hideNull,
    keyObj,
    changeObj,
    limit = 200,
    noLimit = false,
    noSort = null,
    labelSpan = null,
    icons = [],
    onBlur,
    labelInfo = () => { },
    controlOpen,
    setControlOpen,
    'data-cy': dataCy,
}) => {
    const classes = useStyles({ disabled })
    const [autocompleteId] = useState(id || searchAllCharacters(label + uuidv4()).replaceAll(' ', '_').replace('numero', '').replace('nom', 'libelle'))
    const [isOpen, setIsOpen] = useState(false)
    const [isFocus, setIsFocus] = useState(false)

    if (isNil(controlOpen) + isNil(setControlOpen) === 1) { // one is undefined and the other is defined
        // eslint-disable-next-line no-console
        console.error('controlOpen and setControlOpen must both be defined if you want to control the behavior')
    }

    const currentIsOpen = controlOpen ?? isOpen
    const setCurrentIsOpen = setControlOpen ?? setIsOpen

    const optionsOrdered = useMemo(() => {
        const order = noSort ? options : orderBy(options, opt => searchAllCharacters(opt[keyLabel] || opt.mnemonique || opt.name || opt.label || nullLabel))
        const limited = noLimit ? order : take(order, limit)
        return noNullValue ?
            limited :
            [
                { [keyLabel]: ' ' },
                ...limited,
            ]
    }, [noSort, options, noLimit, limit, noNullValue, keyLabel, nullLabel])

    const getId = useCallback((element) => {
        if (isNil(element)) return undefined

        if (!isNil(element[keyValue])) return element[keyValue]
        if (!isNil(element.code)) return element.code
        if (!isNil(element.id)) return element.id
        if (!isNil(element.value) || element.value === '') return element.value

        return undefined
    }, [keyValue])

    const getOptionLabel = useCallback((element) => {
        if (!element) {
            return ''
        }
        const code = displayWithCode ? getId(element) : undefined
        const codeShown = !isNil(code) ? ` [${code}]` : ''
        return (element?.[keyLabel] || element?.mnemonique || element?.name || element?.label || nullLabel || '') + codeShown
    }, [displayWithCode, getId, keyLabel, nullLabel])

    const onChangeValue = useCallback((obj) => {
        const elementId = getId(obj)
        const elementValue = (() => {
            if (integerValue && hasValue(elementId)) {
                return parseInt(elementId)
            } else if (stringValue && hasValue(elementId)) {
                return elementId.toString()
            }
            return elementId
        })()

        if (onChange) {
            onChange(elementValue, obj)
        }

        if (keyObj && changeObj) {
            changeObj({ [keyObj]: elementValue, LAST_FORM: 'SELECT' })
        }

        setCurrentIsOpen(false)
    }, [changeObj, getId, integerValue, keyObj, onChange, setCurrentIsOpen, stringValue])

    const getElementFromId = useCallback((idElement) => {
        if (isNil(idElement)) return undefined
        return options.find(element => getId(element) == idElement)
    }, [getId, options])

    const iconsWithExpand = flatten([
        currentIsOpen && isFocus && icons,
        <Icon
            onClick={() => setCurrentIsOpen(state => !state)}
            sx={{
                color: 'black',
                fontSize: '20px',
            }}
        >
            expand_more
        </Icon>,
    ]).map((icon, i) => <Grid key={`icon${i}`}>{icon}</Grid>)

    if (readMode) {
        const element = getElementFromId(value)
        return (
            <DisplayedValue
                label={`${label} ${obligatory ? '*' : ''}`}
                value={element ? getOptionLabel(element) : ''}
                hideNull={hideNull}
                obligatory={obligatory}
            />
        )
    }

    const defaultOnBlur = () => {
        setCurrentIsOpen(false)
        setIsFocus(false)
    }

    return (
        <>
            <MuiAutocomplete
                id={autocompleteId}
                open={currentIsOpen}
                value={value}
                onFocus={() => setIsFocus(true)}
                onBlur={onBlur ?? defaultOnBlur}
                PopperComponent={StyledPopper}
                disableListWrap
                getOptionLabel={optionValue => {
                    const option = getElementFromId(optionValue)
                    return getOptionLabel(option)
                }}
                disabled={disabled}
                options={optionsOrdered}
                ListboxComponent={Listbox}
                clearIcon={<Icon sx={{ color: 'black', fontSize: '20px' }}>close</Icon>}
                popupIcon={currentIsOpen ? iconsWithExpand.reverse() : iconsWithExpand}
                renderInput={(params) => {
                    return (
                        <TextField
                            {...params}
                            label={`${label} ${obligatory ? '*' : ''}`}
                            InputProps={{
                                ...params.InputProps,
                            }}
                            inputProps={{
                                ...params.inputProps,
                                style: {
                                    border: `1px solid ${disabled && '#ddd' || '#7a7a7a'}`,
                                },
                                onClick: () => setCurrentIsOpen(state => !state),
                            }}
                            InputLabelProps={{
                                ...params.InputLabelProps,
                                shrink: false,
                            }}
                        />
                    )
                }}
                renderOption={(props, option, { inputValue }) => {
                    const optionLabel = getOptionLabel(option)
                    return (
                        <li {...props} key={optionLabel} style={{ paddingLeft: 8, backgroundColor: optionLabel === inputValue ? '#CCC' : '#FFF' }} data-cy={`select-${optionLabel}`}>
                            <Grid container justifyContent='space-between' alignItems='center'>
                                <Grid item>
                                    {optionLabel}
                                </Grid>
                                {
                                    optionLabel !== ' ' && labelInfo(option)
                                }
                            </Grid>
                        </li>
                    )
                }}
                onChange={(_, obj) => onChangeValue(obj)}
                filterOptions={createFilterOptions({
                    stringify: option => keyFilter.map(key => option[key] || '').join('#') || getOptionLabel(option),
                })}
                clearText={i18n.clearLabel}
                closeText={i18n.close}
                openText={i18n.open}
                noOptionsText={i18n.noOption}
                classes={classes}
                data-cy={dataCy}
            />
            {labelSpan}
        </>
    )
}

Field.propTypes = {
    id: PropTypes.string,
    value: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
    ]),
    label: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.shape({
        // [keyValue]
        // [keyLabel]
        // ...rest
    })),
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    displayWithCode: PropTypes.bool,
    keyFilter: PropTypes.arrayOf(PropTypes.string),
    keyLabel: PropTypes.string,
    keyValue: PropTypes.string,
    nullLabel: PropTypes.string,
    obligatory: PropTypes.bool,
    noNullValue: PropTypes.bool,
    readMode: PropTypes.bool,
    integerValue: PropTypes.bool,
    stringValue: PropTypes.bool,
    hideNull: PropTypes.bool,
    keyObj: PropTypes.bool,
    changeObj: PropTypes.bool,
    limit: PropTypes.number,
    noLimit: PropTypes.bool,
    noSort: PropTypes.bool,
    labelSpan: PropTypes.bool,
    icons: PropTypes.arrayOf(PropTypes.element),
    onBlur: PropTypes.func,
    labelInfo: PropTypes.func,
    controlOpen: PropTypes.bool,
    setControlOpen: PropTypes.func,
    'data-cy': PropTypes.string,
}

const NewSelect = ({
    col,
    style,
    ...props
}) => {
    if (col) {
        return (
            <div className={`col s${col}`} style={style}>
                <Field {...props} />
            </div>
        )
    }

    return <Field {...props} />
}

NewSelect.propTypes = {
    id: PropTypes.string,
    value: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
    ]),
    col: PropTypes.number,
    label: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.shape({
        // [keyValue]
        // [keyLabel]
        // ...rest
    })),
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    displayWithCode: PropTypes.bool,
    keyFilter: PropTypes.arrayOf(PropTypes.string),
    keyLabel: PropTypes.string,
    keyValue: PropTypes.string,
    nullLabel: PropTypes.string,
    obligatory: PropTypes.bool,
    noNullValue: PropTypes.bool,
    readMode: PropTypes.bool,
    integerValue: PropTypes.bool,
    stringValue: PropTypes.bool,
    hideNull: PropTypes.bool,
    keyObj: PropTypes.bool,
    changeObj: PropTypes.bool,
    limit: PropTypes.number,
    noLimit: PropTypes.bool,
    noSort: PropTypes.bool,
    style: PropTypes.shape({}),
    labelSpan: PropTypes.bool,
    icons: PropTypes.arrayOf(PropTypes.element),
    onBlur: PropTypes.func,
    labelInfo: PropTypes.func,
    'data-cy': PropTypes.string,
}

export default NewSelect