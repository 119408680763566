export default class DtoRuleScenarios {
    constructor(obj) {
        this.id = obj.id // Long,
        this.ruleType = obj.ruleType // String,
        this.label = obj.label // String,
        this.cause = obj.cause // Option[String],
        this.consequences = obj.consequences // Option[String],
        this.description = obj.description // Option[String],
        this.parameters = JSON.parse(obj.parameters || '{}') // Option[String],
        this.updateDate = obj.updateDate // Option[DateTime],
        this.updateLogin = obj.updateLogin // Option[String]
    }
}