module.exports = {
    MONTHS: ['january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december'],
    STATISTICS: 'statistics',
    PREVIOUSYEARS: 'previousYears',
    LINKEDSTATIONS: 'linkedStations',
    CHARTOPTIONS: 'chartOptions',
    THRESHOLDS: 'thresholds',
    MODELS: 'models',
    EVENTS: 'events',
}
