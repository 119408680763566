import { groupBy } from 'lodash'
import React, { Component } from 'react'
import i18n from 'simple-react-i18n'
import Axis from '../../../components/echart/Axis'
import EChart from '../../../components/echart/EChart'
import Bar from '../../../components/echart/series/Bar'
import { getNumberFormat } from '../../../utils/NumberUtil'
import { getLabel, getPropType } from '../../../utils/StoreUtils'

class EventsStationTypeBarChart extends Component {
    render() {
        const groupByType = groupBy(this.props.events, 'stationType')
        const stationsTypes = [
            { code: 1, label: i18n.piezometer, color: '#143682' },
            { code: 4, label: i18n.hydrometricStation, color: '#ff9804' },
            { code: 3, label: i18n.qualitometer, color: '#f68e1f' },
            { code: 2, label: i18n.pluviometer, color: '#863a95' },
            { code: 5, label: i18n.productionUnit, color: '#4b8f42' },
            { code: 6, label: i18n.distributionUnit, color: '#4b8f42' },
            { code: 7, label: i18n.installation, color: '#9f0505' },
        ]
        const types = Object.keys(groupByType)
        const data = types.map(key => {
            const stationType = stationsTypes.find(e => e.code == key) || { label: 'unknownType', color: 'grey' }
            return {
                name: stationType.label,
                value: groupByType[key].length,
                itemStyle: {
                    normal: {
                        color: stationType.color,
                    },
                },
            }
        })


        const options = {
            series: [Bar({
                data,
                name: i18n.events,
            })],
            tooltip: {
                trigger: 'axis',
            },
            xAxis: [Axis({
                type: 'value',
                axisLabel: {
                    formatter: getNumberFormat,
                },
            })],
            yAxis: [Axis({
                type: 'category',
                data: types.map(d => getLabel(stationsTypes, d) || i18n.unknownType),
                axisLabel: {
                    fontSize: 10,
                },
            })],
            height: 200,
            gridHeight: 180,
        }
        return (
            <div className='row no-margin'>
                <EChart options={ options } />
            </div>
        )
    }
}

EventsStationTypeBarChart.propTypes = {
    events: getPropType('stationEvents'),
}

export default EventsStationTypeBarChart
