import User from 'account/dto/User'
import ConfirmModal from 'components/modal/ConfirmModal'
import { maxBy, omit, range } from 'lodash'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { useMemo, useState } from 'react'
import { connect, shallowEqual, useSelector } from 'react-redux'
import MultiContributorsAutocomplete from 'referencial/components/contributor/components/MultiContributorsAutocomplete'
import i18n from 'simple-react-i18n'
import { authorizeContributors, authorizeFilter } from 'utils/HabilitationUtil'
import Checkbox from '../../../../components/forms/Checkbox'
import Input from '../../../../components/forms/Input'
import NumberField from '../../../../components/forms/NumberField'
import Select from '../../../../components/forms/Select'
import Textarea from '../../../../components/forms/Textarea'
import ContributorDto from '../../../../referencial/components/contributor/dto/ContributorDto'
import NetworkDto from '../../../../referencial/components/network/dto/NetworkDto'
import { SANDRE } from '../../../../referencial/constants/ReferencialConstants'
import DtoSandreCode from '../../../../referencial/dto/DtoSandreCode'
import { getMatTypes } from '../../../../utils/MaterielUtils'
import { getSandreList, removeNullKeys } from '../../../../utils/StoreUtils'
import { MATERIEL_STATE_REFORMED_ID } from '../../../constants/MaterielStateConstants'
import GenericReferencialDto from '../../../dto/GenericReferencialDto'
import PowerSupplyTypeDto from '../../powerSupply/dto/PowerSupplyTypeDto'
import CentralDto from '../dto/CentralDto'
import DtoCentralSituation from '../dto/DtoCentralSituation'
import { CENTRAL_ID } from 'materiel/constants/MaterielConstants'
import { isFieldRequired } from 'materiel/utils/MaterielUtils'
import { DATEACHAT_OBLIGATOIRE, NUMSERIE_OBLIGATOIRE } from 'materiel/constants/MaterielSettingRuleConstant'
import SimpleDatePicker from 'components/forms/SimpleDatePicker'


const CentralPanel = ({
    central = {},
    centralTypes = [],
    active = false,
    disabled = true,
    centralSituations = [],
    contributors = [],
    accountUser = {},
    networks = [],
    powerSupplyTypes = [],
    telecomTypes = [],
    sandreCodes = [],
    onChange = () => { },
}) => {
    const {
        materielSettingRules,
    } = useSelector(store => ({
        materielSettingRules: store.MaterielReducer.materielSettingRules,
    }), shallowEqual)

    const [isOpen, setOpen] = useState(false)
    const [selectedType, setSelectedType] = useState()

    const rules = useMemo(() => {
        return materielSettingRules.filter(({ idMaterielType }) => idMaterielType === CENTRAL_ID)
    }, [materielSettingRules])

    const lastSituation = maxBy(centralSituations, 'situationDate')

    return (
        <div className='col s12 margin-bottom-1'>
            <div className='row no-margin valign-wrapper' style={{ paddingTop: '3px' }}>
                <Select
                    options={getMatTypes(centralTypes, central.centralType)}
                    label={i18n.type}
                    col={4}
                    onChange={v => {
                        const type = centralTypes.find(({ id }) => v === id)
                        if (type) {
                            setOpen(true)
                            setSelectedType(type)
                        } else {
                            onChange({ centralType: v })
                        }
                    }}
                    value={central.centralType}
                    nullLabel='&nbsp;'
                    active={active}
                    disabled={disabled}
                    obligatory
                />
                <Input
                    col={4}
                    title={i18n.serialNumber}
                    value={central.serialNumber}
                    onChange={value => onChange({ serialNumber: value })}
                    active={active}
                    disabled={central.isEquipment || disabled}
                    obligatory={isFieldRequired(rules, NUMSERIE_OBLIGATOIRE)}
                />
                <Input
                    col={4}
                    title={i18n.immoNumber}
                    value={central.reference}
                    onChange={value => onChange({ reference: value })}
                    active={active}
                    disabled={central.isEquipment || disabled}
                />
            </div>
            <div className='row no-margin' style={{ paddingTop: '3px' }}>
                <MultiContributorsAutocomplete
                    options={authorizeContributors(contributors, accountUser, central.manufacturerId)}
                    label={i18n.manufacturer}
                    col={6}
                    onChange={(code) => onChange({ manufacturerId: code })}
                    values={central.isEquipment || central.manufacturerId}
                    keyLabel='labelDisplay'
                    disabled={disabled}
                />
                <MultiContributorsAutocomplete
                    options={authorizeContributors(contributors, accountUser, central.providerId)}
                    label={i18n.provider}
                    col={6}
                    onChange={(code) => onChange({ providerId: code })}
                    values={central.isEquipment || central.providerId}
                    keyLabel='labelDisplay'
                    disabled={disabled}
                />
            </div>
            <div className='row no-margin' style={{ paddingTop: '3px' }}>
                <Select
                    options={authorizeFilter(networks, accountUser, central.networkCode)}
                    label={i18n.network}
                    col={12}
                    onChange={value => onChange({ networkCode: value })}
                    value={central.networkCode}
                    nullLabel='&nbsp;'
                    active={active}
                    disabled={central.isEquipment || disabled}
                />
            </div>
            <div className='row no-margin' style={{ paddingTop: '3px' }}>
                <Select
                    options={getMatTypes(powerSupplyTypes, central.powerSupplyType)}
                    label={i18n.powerSupplyType}
                    col={3}
                    onChange={value => onChange({ powerSupplyType: value })}
                    value={central.powerSupplyType}
                    nullLabel='&nbsp;'
                    active={active}
                    disabled={disabled}
                />
                <NumberField
                    col={3}
                    title={i18n.batteryCount}
                    value={central.pileNumber}
                    onChange={value => onChange({ pileNumber: value })}
                    active={active}
                    disabled={disabled}
                />
                <NumberField
                    col={3}
                    title={i18n.supplyTime}
                    value={central.supplyTime}
                    onChange={value => onChange({ supplyTime: value })}
                    active={active}
                    disabled={disabled}
                />
                <Select
                    options={getMatTypes(telecomTypes, central.telecomType)}
                    label={i18n.typeTelecom}
                    col={3}
                    onChange={value => onChange({ telecomType: value })}
                    value={central.telecomType}
                    nullLabel='&nbsp;'
                    active={active}
                    disabled={disabled}
                />
            </div>
            <div className='row no-margin' style={{ paddingTop: '3px' }}>
                <SimpleDatePicker
                    id='manufactureDate'
                    label={i18n.manufactureDate}
                    value={central.manufactureDate}
                    onChange={v => onChange({ manufactureDate: v })}
                    col={4}
                    disabled={central.isEquipment || disabled}
                    max={central.purchaseDate}
                />
                <SimpleDatePicker
                    id='purchaseDate'
                    label={i18n.purchaseDate}
                    value={central.purchaseDate}
                    onChange={v => onChange({ purchaseDate: v })}
                    col={4}
                    disabled={central.isEquipment || disabled}
                    min={central.manufactureDate}
                    obligatory={isFieldRequired(rules, DATEACHAT_OBLIGATOIRE)}
                />
            </div>
            <div className='row no-margin valign-wrapper' style={{ paddingTop: '3px' }}>
                <SimpleDatePicker
                    id='replacementDate'
                    label={i18n.replaceDate}
                    value={central.replacementDate}
                    onChange={v => onChange({ replacementDate: v })}
                    col={4}
                    disabled={central.isEquipment || disabled}
                    min={central.purchaseDate}
                />
                <SimpleDatePicker
                    label={i18n.warrantyExpirationDate}
                    value={central.warrantyTime && moment(central.purchaseDate).add(central.warrantyTime, 'months').valueOf()}
                    col={4}
                    disabled
                />
                <Checkbox
                    col={4}
                    label={i18n.desiccant}
                    checked={central.desiccant === '1'}
                    onChange={value => onChange({ desiccant: value ? '1' : '0' })}
                    active={active}
                    disabled={disabled}
                />
            </div>
            <div className='row no-margin' style={{ paddingTop: '8px' }}>
                <Textarea
                    col={12}
                    title={i18n.comment}
                    value={central.comment}
                    onChange={value => onChange({ comment: value })}
                    active={active}
                    disabled={disabled}
                />
            </div>
            <div className='row no-margin' style={{ paddingTop: '8px' }}>
                <NumberField
                    title={i18n.transmissionVolume}
                    col={4}
                    onChange={value => onChange({ transmissionVolume: parseInt(value) })}
                    value={parseInt(central.transmissionVolume)}
                    active={active}
                    disabled={disabled}
                />
                <Select
                    options={getSandreList(sandreCodes, SANDRE.CENTRALE_FREQUENCE_TRANSMISSION)}
                    label={i18n.sendingFrequency}
                    col={4}
                    onChange={value => onChange({ callFrequency: parseInt(value) })}
                    value={parseInt(central.callFrequency)}
                    nullLabel='&nbsp;'
                    active={active}
                    disabled={disabled}
                />
                <Select
                    options={range(0, 24).map(h => ({ code: h, name: `${h}h` }))}
                    label={i18n.sendingTime}
                    col={4}
                    onChange={value => onChange({ callHour: parseInt(value) })}
                    value={parseInt(central.callHour)}
                    nullLabel='&nbsp;'
                    active={active}
                    disabled={disabled}
                />
            </div>
            {
                !!lastSituation && lastSituation.statusCode === MATERIEL_STATE_REFORMED_ID && (
                    <div className='row no-margin padding-top-8-px'>
                        <SimpleDatePicker
                            id='withdrawalDate'
                            label={i18n.withdrawalDate}
                            value={central.withdrawalDate}
                            onChange={v => onChange({ withdrawalDate: v })}
                            col={4}
                            disabled={central.isEquipment || disabled}
                            min={central.purchaseDate}
                        />
                    </div>
                )
            }
            <ConfirmModal
                isOpen={isOpen}
                title={i18n.materielDefaultValuesMessage}
                onValidate={() => {
                    setOpen(false)
                    onChange(removeNullKeys({
                        centralType: selectedType.id,
                        ...omit(selectedType, ['id', 'code', 'label', 'name', 'comment', 'updateDate', 'updateLogin', 'startDate', 'endDate', 'icon']),
                    }))
                }}
                onReject={() => {
                    setOpen(false)
                    onChange({ centralType: selectedType.id })
                }}
                onClose={() => setOpen(false)}
            />
        </div>
    )
}

CentralPanel.propTypes = {
    disabled: PropTypes.bool,
    active: PropTypes.bool,
    central: PropTypes.instanceOf(CentralDto),
    onChange: PropTypes.func,
    centralTypes: PropTypes.instanceOf(GenericReferencialDto),
    contributors: PropTypes.arrayOf(PropTypes.instanceOf(ContributorDto)),
    powerSupplyTypes: PropTypes.arrayOf(PropTypes.instanceOf(PowerSupplyTypeDto)),
    sandreCodes: PropTypes.arrayOf(PropTypes.instanceOf(DtoSandreCode)),
    centralSituations: PropTypes.arrayOf(PropTypes.instanceOf(DtoCentralSituation)),
    telecomTypes: PropTypes.arrayOf(GenericReferencialDto),
    networks: PropTypes.arrayOf(PropTypes.instanceOf(NetworkDto)),
    accountUser: PropTypes.instanceOf(User),
}

const mapStateToProps = store => ({
    centralTypes: store.CentralReducer.centralTypes,
    contributors: store.ContributorReducer.contributors,
    powerSupplyTypes: store.PowerSupplyReducer.powerSupplyTypes,
    sandreCodes: store.ReferencialReducer.sandreCodes,
    centralSituations: store.CentralReducer.centralSituations,
    telecomTypes: store.TelecomReducer.telecomTypes,
    networks: store.NetworkReducer.networks,
    accountUser: store.AccountReducer.accountUser,
})

export default connect(mapStateToProps)(CentralPanel)
