import { groupBy, inRange, orderBy, range, round, sumBy } from 'lodash'
import moment from 'moment'
import { getDate, getFullDate, getMonthYear, getYearDate } from './DateUtil'
import {
    AUTO,
    SUM_DAY,
    SUM_DECADE,
    SUM_HOUR,
    SUM_MONTH,
    SUM_WEEK,
    SUM_YEAR,
} from '../pluviometry/constants/PluvioOptionConstant'
import { getComponentWithId } from './StoreUtils'

const DATATYPE_RAIN = 1

const formatPluvioMeasures = listAllMeasure => {
    if (listAllMeasure && listAllMeasure.length) {
        const measures = listAllMeasure.map(m => ({ value: [m.date || m.measureDate, m.value] }))
        return orderBy(measures, m => m.value[0])
    }
    return []
}


const offsetAndCumulPluvio = (measures, offset, cumul) => {
    const offseted = offset ? measures.map(m => ({ ...m, date: moment(m[0]).add('days', offset).valueOf() })) : measures
    if (!cumul) {
        return offseted
    }
    const grouped = groupBy(offseted, d => getDate(d[0]))
    return Object.keys(grouped).map(dateStr => {
        const date = grouped[dateStr][0][0]
        const cumulRain = range(0, cumul+1).reduce((acc, n) => {
            const nStr = getDate(moment(date).subtract('days', n))
            return acc + sumBy(grouped[nStr], m => m[1])
        }, 0)
        return { ...grouped[dateStr][0], rain: cumulRain }
    })
}

const tooltipDatePluvio = (dateToFormat, sumType) => {
    switch (sumType) {
        case 'SUM_HOUR' :
        case 'SUM_DECADE' :
            return getFullDate(dateToFormat)
        case 'SUM_WEEK' :
        case 'SUM_DAY' :
            return getDate(dateToFormat)
        case 'SUM_MONTH' :
            return getMonthYear(dateToFormat)
        case 'SUM_YEAR' :
            return getYearDate(dateToFormat)
        default :
            return getFullDate(dateToFormat)
    }
}

const getAutoCmumul = (minDate, maxDate) => {
    const diffInMs = maxDate - minDate
    const diffInDays = round(diffInMs / (1000 * 60 * 60 * 24)) - 1
    if (diffInDays < 2) {
        return SUM_HOUR
    } else if (inRange(diffInDays, 2, 90)) {
        return SUM_DAY
    } else if (inRange(diffInDays, 365, 1825)) {
        return SUM_MONTH
    } else if (diffInDays > 1825) {
        return SUM_YEAR
    }
    return SUM_WEEK
}

const getGroupFunc = (label, displayModes) => {
    switch (label) {
        case AUTO: return AUTO
        case 'sumHour': return SUM_HOUR
        case 'sumDay': return SUM_DAY
        case 'sumWeek': return SUM_WEEK
        case 'sumDecade': return SUM_DECADE
        case 'sumMonth': return SUM_MONTH
        case 'sumYear': return SUM_YEAR
        case 'personalizedGrouping': return displayModes.personalizedGroupingValue
        case 'all': default: return label
    }
}

const getGroupFuncFrequencyStat = label => {
    switch (label) {
        case AUTO: return SUM_WEEK
        case 'sumHour': return SUM_WEEK
        case 'sumDay': return SUM_WEEK
        case 'sumWeek': return SUM_WEEK
        case 'sumDecade': return SUM_DECADE
        case 'sumMonth': return SUM_MONTH
        case 'sumYear': return SUM_YEAR
        case 'personalizedGrouping': return SUM_WEEK
        case 'all': default: return SUM_WEEK
    }
}

const checkBarPatch = (minDateMoment, value) => {
    // if (minDateMoment.valueOf() > value) {
    //     const component = getComponentWithId('.sieauChart')
    //     if (component) {
    //         const instance = component.getInstance()
    //         if (instance) {
    //             const options = instance.getOption()
    //             const xAxis = options.xAxis.map((axis, idx) => ({
    //                 ...axis,
    //                 // ...(idx === 0 ? { min: minDateMoment.valueOf() + (minDateMoment.valueOf() - value) } : {}),
    //                 ...(idx === 0 ? { min: minDateMoment.valueOf() + (minDateMoment.valueOf() - value) } : {}),
    //             }))
    //             setTimeout(() => {
    //                 instance.setOption({ xAxis })
    //             }, 500)
    //         }
    //     }
    // }
}

const getPluvioAxisLabelInterval = (maxDate, minDate, barWidth, cumulPluvio) => {
    const duration = moment.duration(maxDate.diff(minDate))
    if (duration.years() >= 10) {
        const aYear = 3600 * 24 * 1000 * 365.5

        return {
            interval: (() => {
                if (duration.years() >= 30) {
                    return 4 * aYear
                }
                if (duration.years() >= 20) {
                    return 2 * aYear
                }
                return aYear
            })(),
            formatter: barWidth ? ((value) => {
                checkBarPatch(minDate, value)
                return moment(value).year()
            }) : (value => {
                if (moment(value).valueOf() > moment(value).month(11).date(10).valueOf()) { // arrondi à l'année proche
                    return moment(value).add(1, 'month').year()
                }
                return moment(value).year()
            }),
        }
    }
    if (duration.years() >= 2) {
        return {
            interval: 3600 * 24 * 1000 * 365.5,
            formatter: (value) => {
                if (barWidth) {
                    checkBarPatch(minDate, value)
                }
                if (moment(value).isSame(minDate, 'day') || moment(value).isSame(maxDate, 'day')) {
                    return `${moment(value).format('DD/MM')}\n${moment(value).format('YYYY')}`
                }
                if (moment(value).valueOf() > moment(value).month(11).date(10).valueOf()) { // arrondi à l'année proche
                    return moment(value).add(1, 'month').year()
                }
                return moment(value).year()
            },
        }
    }
    if (duration.years() >= 1 || duration.months() >= 1) {
        return {
            interval: 3600 * 24 * 1000 * 31,
            formatter: (value) => {
                if (barWidth) {
                    checkBarPatch(minDate, value)
                }
                if (moment(value).isSame(minDate, 'day') || moment(value).isSame(maxDate, 'day')) {
                    return `${moment(value).format('DD/MM')}`
                }
                if (moment(value).valueOf() > moment(value).hour(22).valueOf()) { // arrondi au jour proche
                    return moment(value).add(1, 'day').format('DD/MM')
                }
                return moment(value).format('DD/MM')
            },
        }
    }
    if (duration.days() >= 20) {
        return {
            interval: 3600 * 24 * 1000 * 2,
            formatter: (value) => {
                if (barWidth) {
                    checkBarPatch(minDate, value)
                }
                return `${moment(value).format('DD/MM')}\n${moment(value).format('HH:mm')}`
            },
        }
    }
    if (duration.days() >= 5) {
        return {
            interval: 3600 * 24 * 1000,
            formatter: (value) => {
                if (barWidth) {
                    checkBarPatch(minDate, value)
                }
                return `${moment(value).format('DD/MM')}\n${moment(value).format('HH:mm')}`
            },
        }
    }
    if (duration.days() > 1) {
        return {
            interval: 3600 * 12 * 1000,
            formatter: (value) => {
                if (barWidth) {
                    checkBarPatch(minDate, value)
                }
                return `${moment(value).format('DD/MM')}\n${moment(value).format('HH:mm')}`
            },
        }
    }
    return {
        interval: 3600 * 2 * 1000,
        formatter: (value) => {
            if (barWidth) {
                checkBarPatch(minDate, value)
            }
            return moment(value).format('HH:mm')
        },
    }
}

export { formatPluvioMeasures, offsetAndCumulPluvio, tooltipDatePluvio, getAutoCmumul, getGroupFunc, getGroupFuncFrequencyStat, DATATYPE_RAIN, getPluvioAxisLabelInterval }
