import { Grid } from '@mui/material'
import Card from 'components/card/Card'
import Input from 'components/forms/Input'
import SimpleDatePicker from 'components/forms/SimpleDatePicker'
import Textarea from 'components/forms/Textarea'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import i18n from 'simple-react-i18n'
import DtoCasingDeclaration from 'survey/dto/DtoCasingDeclaration'
import { secondaryBlue } from 'utils/constants/ColorTheme'

class OuvrageCard extends Component {
    constructor(props) {
        super(props)
        this.state = {
            ouvrage: props.ouvrage,
        }
    }

    render() {
        const { title, actions, style, round } = this.props
        const { ouvrage } = this.state

        return (
            <Card
                title={title}
                headerStyle={{ backgroundColor: secondaryBlue }}
                actions={actions}
                className='row no-margin padding-bottom-1'
                cardStyle={{
                    border: `1px solid ${secondaryBlue}`,
                    borderWidth: '0 2 2 2',
                    ...style,
                }}
                round={round}
            >
                {ouvrage ? (
                    <Grid container spacing={1}>
                        <Grid item xs={12} md={6}>
                            <Input
                                col={12}
                                className='padding-left-1 padding-top-1'
                                title={i18n.depth}
                                value={ouvrage.depth}
                                readMode
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <SimpleDatePicker
                                col={12}
                                className='padding-left-1 padding-top-1'
                                label={i18n.completionDate}
                                value={ouvrage.completionDate}
                                readMode
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <SimpleDatePicker
                                col={12}
                                className='padding-left-1 padding-top-1'
                                label={i18n.declarationDate}
                                value={ouvrage.declarationDate}
                                readMode
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Input
                                col={12}
                                className='padding-left-1 padding-top-1'
                                title={i18n.receiptNumber}
                                value={ouvrage.receiptNumber}
                                readMode
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <SimpleDatePicker
                                col={12}
                                className='padding-left-1 padding-top-1'
                                label={i18n.receiptDate}
                                value={ouvrage.receiptDate}
                                readMode
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Input
                                col={12}
                                className='padding-left-1 padding-top-1'
                                title={i18n.cementing}
                                value={ouvrage.cementing ? i18n.yes : i18n.no}
                                readMode
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Input
                                col={12}
                                className='padding-left-1 padding-top-1'
                                title={i18n.concreteCoping}
                                value={ouvrage.concreteCoping ? i18n.yes : i18n.no}
                                readMode
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Input
                                col={12}
                                className='padding-left-1 padding-top-1'
                                title={i18n.protectionHead}
                                value={ouvrage.protectionHead ? i18n.yes : i18n.no}
                                readMode
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Input
                                col={12}
                                className='padding-left-1 padding-top-1'
                                title={i18n.copingHeight}
                                value={ouvrage.copingHeight}
                                readMode
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Input
                                col={12}
                                className='padding-left-1 padding-top-1'
                                title={i18n.reserveFlow}
                                value={ouvrage.reserveFlow}
                                readMode
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Textarea
                                col={12}
                                className='padding-left-1 padding-top-1'
                                title={i18n.comment}
                                value={ouvrage.comment}
                                readMode
                            />
                        </Grid>
                    </Grid>
                ) : (
                    <p className='padding-left-1 padding-top-1'>{i18n.noData}</p>
                )}
            </Card>
        )
    }
}

OuvrageCard.propTypes = {
    title: PropTypes.string.isRequired,
    ouvrage: PropTypes.instanceOf(DtoCasingDeclaration).isRequired,
    actions: PropTypes.arrayOf(PropTypes.shape({
        onClick: PropTypes.func,
        iconName: PropTypes.string,
    })),
    style: PropTypes.shape({}),
    round: PropTypes.bool,
}

export default OuvrageCard
