import React, { useMemo } from 'react'
import i18n from 'simple-react-i18n'
import { Button, Grid } from '@mui/material'
import Input from 'components/forms/Input'
import PropTypes from 'prop-types'
import { getDayDisplay, toolFunctions } from 'exploitations/utils/AgriUtils'
import Select from 'components/forms/Select'
import DtoWaterTurnsSlot from 'exploitations/dto/DtoWaterTurnsSlot'
import { StyledFieldSet, StyledLegend } from 'components/StyledElements'
import { range } from 'lodash'

const EditToolDisplay = ({
    colorInput = '',
    setColorInput = '',
    slot = [],
    colors = [],
    selectionTool = {},
    setSelectionTool = () => { },
    onApply = () => { },
}) => {
    const { nbSlots, nbSlotsDisplay } = useMemo(() => {
        setColorInput('X'.repeat(slot.nbSlots))
        return {
            nbSlots: slot.nbSlots,
            nbSlotsDisplay: range(slot.nbSlots),
        }
    }, [slot])

    return (
        <StyledFieldSet>
            <StyledLegend>{i18n.tools}</StyledLegend>
            <Grid container item xs={12} justifyContent='space-between' alignItems='center'>
                <Grid item>
                    <Input
                        title={i18n.waterTurns}
                        value={colorInput}
                        onChange={setColorInput}
                        maxLength={nbSlots}
                        data-cy='color_input'
                    />
                </Grid>
                <Grid item>
                    <Grid container justifyContent='center'>
                        <Grid style={{ padding: '15 0' }} item>
                            {getDayDisplay(colorInput, nbSlotsDisplay, colors)}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item >
                    <Select
                        col={12}
                        value={selectionTool}
                        label={i18n.selection}
                        noNullValue
                        options={toolFunctions}
                        onChange={setSelectionTool}
                        keyLabel='name'
                        keyValue='code'
                        noSort
                    />
                </Grid>
                <Grid item xs={2} container justifyContent='flex-end' alignItems='flex-end'>
                    <Button
                        variant='contained'
                        onClick={onApply}
                        data-cy='tool_apply'
                    >
                        {i18n.apply}
                    </Button>
                </Grid>
            </Grid>
        </StyledFieldSet>
    )
}

EditToolDisplay.propTypes = {
    colorInput: PropTypes.string,
    setColorInput: PropTypes.string,
    slot: PropTypes.instanceOf(DtoWaterTurnsSlot),
    colors: PropTypes.shape({}),
    selectionTool: PropTypes.number,
    setSelectionTool: PropTypes.func,
    onApply: PropTypes.func,
}

export default EditToolDisplay