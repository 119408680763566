import React from 'react'
import ActionComponent from 'components/ActionComponent'
import { nbPerPageLabel } from '../constants/ReferencialConstants'
import Table from '../../components/datatable/Table'
import { getFullDate } from '../../utils/DateUtil'
import AppStore from 'store/AppStore'
import { omit, orderBy } from 'lodash'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import { getUser } from '../../utils/SettingUtils'

const Materialize = window.Materialize

class ReferencialAppList extends ActionComponent {
    getActions = (props) => {
        const actions = (() => {
            const result = {}
            if (props.showRefencialActionsButton) {
                result.referencialActions = {
                    actions: this.props.actions,
                }
            }
            if (props.showUpdateButton) {
                result.update = {
                    onUpdate: () => this.props.updateAction(),
                    onUpdateBis: () => this.props.updateActionBis(),
                    onImportCsv: this.props.onImportCsv,
                    onImportXml: this.props.onImportXml,
                    actifAuto: this.props.actifAuto,
                    importOrUpdate: this.props.importOrUpdate,
                    typeGroup: this.props.typeGroup,
                    textValue1: this.props.textValue1,
                    textValue2: this.props.textValue2,
                    biAction: this.props.biAction,
                    importCsv: this.props.importCsv,
                    importXml: this.props.importXml,
                    fileContent: this.props.fileContent,
                }
            }
            if (props.showNewButton) {
                result.new = () => props.newAction()
            }
            if (props.showPurgeButton) {
                if (props.purgeIsRunning) {
                    result.loading = [{
                        label: i18n.purge.toUpperCase(),
                        value: this.props.purgeProgress,
                    }]
                } else {
                    result.purge = () => props.purgeAction()
                }
            }
            if (props.showExportButton) {
                result.export = () => {
                    return {
                        data: this.getExportData(props),
                        exportType: 'xlsx',
                        titleFile: props.title,
                    }
                }
            }
            if (props.links && props.links.length) {
                result.links = props.links
            }
            return result
        })()
        if (getUser().consultant === '1') {
            this.setActions(omit(actions, ['new', 'purge']))
        } else {
            this.setActions(actions)
        }
    }

    getExportData = (props) => {
        const data = (() => {
            if (props.color) {
                return (() => {
                    if (props.exportData.length) {
                        return props.exportData
                    }
                    return props.referenceData.length ? props.referenceData : []
                })()
            }
            return (() => {
                if (props.exportData.length) {
                    return props.exportData
                }
                return props.data
            })()
        })()
        if (data.length) {
            const sortedData = (() => {
                if (this.props.order) {
                    return data.sort((a, b) => {
                        return parseInt(a.code) - parseInt(b.code)
                    })
                }
                return data
            })()
            return this.props.title === i18n.cityList ? sortedData.map((d, i) => {
                if (i === 0) {
                    return {
                        ...d,
                        headers: ['code', 'name', 'situation', 'departmentNumber', 'area', 'countryCode', 'x', 'y', 'projection', 'email', 'phone', 'population'],
                    }
                }
                return d
            }) : sortedData
        }
        return []
    }

    componentDidUpdate() {
        this.getActions(this.props)
        Materialize.updateTextFields()
    }

    componentDidMount() {
        this.getActions(this.props)
        if (this.props.action) {
            AppStore.dispatch(this.props.action())
        }
        if (this.props.setTitleAction) {
            AppStore.dispatch(this.props.setTitleAction)
        }
        $('.modal').modal()
        $('ul.tabs').tabs()
    }


    render() {
        const {
            customHeaders,
            data,
            order,
            lastUpdate,
            title,
            color,
            link,
            type,
            active,
            filter,
            round,
        } = this.props
        const dataOrdered = order ? orderBy(data, ['code'], ['asc']) : data
        return (
            <div className='row no-margin'>
                <div className='col s12'>
                    {
                        lastUpdate && lastUpdate.updateUser && lastUpdate.updateDate && (
                            <div className='row no-margin-bottom'>
                                <div className='col s4 center offset-s8 card'>
                                    <div className='col s12'>
                                        { i18n.lastUpdateOn } { getFullDate(lastUpdate.updateDate) } { i18n.by } { lastUpdate.updateUser }
                                    </div>
                                </div>
                            </div>
                        )
                    }
                    <div className='padding-top-5-px referencial-table'>
                        <Table
                            title={title}
                            data={dataOrdered}
                            color={color}
                            nbPerPageLabel={nbPerPageLabel}
                            link={link}
                            store={AppStore}
                            type={type}
                            condensed
                            sortable
                            paging
                            searchable={this.props.searchable}
                            customHeaders={customHeaders}
                            active={active}
                            onClick={this.props.onClick}
                            filter={filter}
                            round={round}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

ReferencialAppList.propTypes = {
    title: PropTypes.string,
    data: PropTypes.arrayOf(PropTypes.object),
    exportData: PropTypes.arrayOf(PropTypes.object),
    referenceData: PropTypes.arrayOf(PropTypes.object),
    type: PropTypes.object,
    link: PropTypes.string,
    action: PropTypes.func.isRequired,
    updateAction: PropTypes.func,
    updateActionBis: PropTypes.func,
    links: PropTypes.arrayOf(PropTypes.object),
    setTitleAction: PropTypes.func.isRequired,
    color: PropTypes.bool,
    active: PropTypes.bool,
    onClick: PropTypes.func,
    newAction: PropTypes.func,
    purgeAction: PropTypes.func,
    lastUpdate: PropTypes.object,
    order: PropTypes.bool,
    showNewButton: PropTypes.bool,
    showPurgeButton: PropTypes.bool,
    purgeIsRunning: PropTypes.bool,
    showExportButton: PropTypes.bool,
    showUpdateButton: PropTypes.bool,
    round: PropTypes.bool,
    filter: PropTypes.string,
    searchable: PropTypes.bool,
    textValue1: PropTypes.string,
    textValue2: PropTypes.string,
    biAction: PropTypes.bool,
    importCsv: PropTypes.bool,
    importXml: PropTypes.bool,
    onImportCsv: PropTypes.func,
    onImportXml: PropTypes.func,
    importOrUpdate: PropTypes.func,
    typeGroup: PropTypes.func,
    actifAuto: PropTypes.func,
    fileContent: PropTypes.func,
    actions: PropTypes.shape({
        export: PropTypes.shape({
            data: PropTypes.arrayOf(PropTypes.object),
            titleFile: PropTypes.string,
            exportContent: PropTypes.element,
        }),
        sync: PropTypes.shape({
            updateAction: PropTypes.func,
            onImportXml: PropTypes.func,
            onImportCsv: PropTypes.func,
            xmlContent: PropTypes.element,
            csvContent: PropTypes.element,
            fileContentIsValid: PropTypes.bool,
        }),
        purge: PropTypes.shape({
            onPurge: PropTypes.func,
            onMakeInactive: PropTypes.func,
        }),
    }),
}

ReferencialAppList.defaultProps = {
    title: '',
    data: [],
    exportData: [],
    referenceData: [],
    type: [],
    link: '',
    onClick: () => { },
    informations: [],
    color: false,
    newAction: () => { },
    purgeAction: () => { },
    updateAction: () => { },
    updateActionBis: () => { },
    actions: {},
    lastUpdate: {
        updateDate: null,
        updateUser: null,
    },
    order: true,
    active: false,
    running: true,
    showNewButton: true,
    showRefencialActionsButton: true,
    showPurgeButton: true,
    purgeIsRunning: false,
    purgeProgress: null,
    showExportButton: true,
    showUpdateButton: true,
    filter: '',
    searchable: true,
}

export default ReferencialAppList
