import { Grid } from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'

const PerimeterStatus = ({
    color = 'white',
    label,
    noBorder = false,
    sx = {},
}) => (
    <Grid container item xs='auto' alignItems='center'>
        <Grid
            item
            sx={{
                padding: '0.5rem',
                backgroundColor: color,
                borderRadius: '50rem',
                border: !noBorder && 'solid 1px rgba(0, 0, 0, 0.4)',
                ...sx,
            }}
        />
        {!!label && (
            <Grid item sx={{ paddingLeft: label && '0.5rem' }}>
                {label}
            </Grid>
        )}
    </Grid>
)

PerimeterStatus.propTypes = {
    color: PropTypes.string,
    label: PropTypes.string,
    noBorder: PropTypes.bool,
    sx: PropTypes.shape({}),
}

export default PerimeterStatus