import DtoMaterielSituation from '../../../dto/DtoMaterielSituation'

export default class DtoCentralSituation extends DtoMaterielSituation {
    constructor(obj) {
        super(obj)
        this.id = obj.idCentralSituation
        this.idCentral = obj.idCentral
        this.materielId = obj.idCentral
    }
}
