import PropTypes from 'prop-types'
import React from 'react'
import i18n from 'simple-react-i18n'
import AppStore from 'store/AppStore'
import { v4 as uuidv4 } from 'uuid'
import SieauAction from '../sieau/SieauAction'
import IAction from './IAction'

class Purge extends IAction {
    fn = () => {
        return () => {
            const id = uuidv4()
            const content = (
                <h6>Cette fonctionnalité permet de purger les éléments non utilisés dans la base de
                   données. Vous pouvez soit, purger
                   tous les éléments non utilisés, ils seront alors supprimés de la base, soit les
                   rendre inactifs, ils ne seront
                   plus disponibles en saisie et recherche dans les fonctionnalités de
                   SIEau.</h6>
            )
            const actions = (
                <div>
                    <a className='waves-effect waves-teal btn-flat modal-close'>{ i18n.close }</a>
                    <a className='waves-effect waves-teal btn-flat modal-close' onClick={ this.props.onPurge }>{ i18n.purgeEveryItems }</a>
                    <a className='waves-effect waves-teal btn-flat modal-close' onClick={ this.props.onPurge }>{ i18n.makeInactiveelemnets }</a>
                </div>
            )
            const popup = {
                id,
                header: i18n.purge,
                actions,
                content,
                dismissible: false,
            }
            AppStore.dispatch(SieauAction.setPopup(popup))
        }
    }
    icon = () => {
        return 'delete_forever'
    }
    label = () => {
        return i18n.purge
    }
    id = () => {
        return 'purge_action_navbar'
    }
}

Purge.propTypes = {
    onPurge: PropTypes.func.isRequired,
}

export default Purge
