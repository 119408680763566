/* eslint-disable max-nested-callbacks */
import { Grid } from '@mui/material'
import MultiGridTableV2 from 'components/datatable/virtualizedTable/MultiGridTableV2'
import Icon from 'components/icon/Icon'
import { groupBy, maxBy, meanBy, minBy, orderBy, round, uniqBy } from 'lodash'
import React, { useMemo } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { calculateGeometricAverage, calculatePercentile90 } from 'utils/AnalyseUtils'
import { getDate } from 'utils/DateUtil'
import { hasValue } from 'utils/NumberUtil'
import { statusIcon } from 'utils/StatusUtil'
import { getLabel } from 'utils/StoreUtils'
import PropTypes from 'prop-types'
import DtoOperation from 'quality/components/operation/dto/DtoOperation'
import i18n from 'simple-react-i18n'
import { SPECIFIC_SUPPORT_CODES } from 'quality/constants/HydrobioConstant'
import useActions from 'utils/customHook/useActions'

const STATS_HEADERS = ['min', 'averageShort', 'max', 'percentile90', 'geometricAverageShort', 'nbQuant', 'nbCounting', 'quantTx']

const HeaderCellTootip = ({
    operationId,
}) => {
    const {
        hydrobioOperations,
        contributorsIndex,
        status,
        qualifications,
    } = useSelector(store => ({
        hydrobioOperations: store.OperationReducer.hydrobioOperations,
        contributorsIndex: store.ContributorReducer.contributorsIndex,
        status: store.QualityReducer.status,
        qualifications: store.QualityReducer.qualifications,
    }), shallowEqual)

    const operation = hydrobioOperations.find(({ id }) => `${id}` === operationId)
    const producer = contributorsIndex[operation.producer]
    const producerLabel = producer && (producer.mnemonique || producer.name) || ''

    const determiner = contributorsIndex[operation.determiner]
    const determinerLabel = determiner && (determiner.mnemonique || determiner.name) || ''

    return (
        <Grid container direction='column' justifyContent='center' alignItems='flex-start'>
            <Grid item>
                {`${i18n.date}: ${getDate(operation.date)}`}
            </Grid>
            <Grid item>
                {`${i18n.status}: ${getLabel(status, operation.status)}`}
            </Grid>
            <Grid item>
                {`${i18n.qualification}: ${getLabel(qualifications, operation.qualification)}`}
            </Grid>
            <Grid item>
                {`${i18n.producer}: ${producerLabel}`}
            </Grid>
            <Grid item>
                {`${i18n.determiner}: ${determinerLabel}`}
            </Grid>
        </Grid>
    )
}

HeaderCellTootip.propTypes = {
    operationId: PropTypes.string,
}

const HeaderCell = ({
    operationId,
}) => {
    const {
        hydrobioOperations,
        contributorsIndex,
    } = useSelector(store => ({
        hydrobioOperations: store.OperationReducer.hydrobioOperations,
        contributorsIndex: store.ContributorReducer.contributorsIndex,
    }), shallowEqual)

    const operation = hydrobioOperations.find(({ id }) => `${id}` === operationId)
    const producer = contributorsIndex[operation.producer]
    const producerLabel = producer && (producer.mnemonique || producer.name) || ''

    const determiner = contributorsIndex[operation.determiner]
    const determinerLabel = determiner && (determiner.mnemonique || determiner.name) || ''

    return (
        <Grid container direction='column' justifyContent='center' alignItems='flex-start'>
            <Grid item>
                <div className='valign-wrapper'>
                    {statusIcon(operation, 15, false)}
                    <span style={{ paddingLeft: 5, fontSize: '12px' }}> {getDate(operation.date)}</span>
                </div>
            </Grid>
            <Grid item>
                <div className='valign-wrapper'>
                    <Icon icon='widgets' style={{ fontSize: '15px' }} />
                    <span style={{ paddingLeft: 5, fontSize: '12px' }}>{producerLabel}</span>
                </div>
            </Grid>
            <Grid item>
                <div className='valign-wrapper'>
                    <Icon icon='face' style={{ fontSize: '15px' }} />
                    <span style={{ paddingLeft: 5, fontSize: '12px' }}>{determinerLabel}</span>
                </div>
            </Grid>
        </Grid>
    )
}

HeaderCell.propTypes = {
    operationId: PropTypes.string,
}

const TooltipTaxon = ({
    list = {},
}) => {
    const {
        remarks,
    } = useSelector(store => ({
        remarks: store.OperationReducer.remarks,
    }), shallowEqual)

    const {
        enumerationA = 0,
        enumerationB = 0,
        enumerationC = 0,
        enumerationC2 = 0,
        total,
        remark,
        support,
    } = list

    const isSpecific = SPECIFIC_SUPPORT_CODES.includes(support)

    return (
        <Grid container direction='column' justifyContent='center' alignItems='flex-start'>
            <Grid item>
                {`${i18n.remark}: ${getLabel(remarks, remark)}`}
            </Grid>
            {
                !isSpecific && (
                    <>
                        <Grid item>
                            {`${i18n.phaseA}: ${enumerationA}`}
                        </Grid>
                        <Grid item>
                            {`${i18n.phaseB}: ${enumerationB}`}
                        </Grid>
                        <Grid item>
                            {`${i18n.phaseC}: ${enumerationC}`}
                        </Grid>
                        <Grid item>
                            {`${i18n.phaseCbis}: ${enumerationC2}`}
                        </Grid>
                    </>
                )
            }
            <Grid item>
                {`${i18n.total}: ${total}`}
            </Grid>
        </Grid>
    )
}

TooltipTaxon.propTypes = {
    list: PropTypes.shape({
        enumerationA: PropTypes.number,
        enumerationB: PropTypes.number,
        enumerationC: PropTypes.number,
        enumerationC2: PropTypes.number,
        total: PropTypes.number,
        remarkCode: PropTypes.number,
    }),
}

const HydrobioMonitoringTable = ({
    hydrobioLists = [],
    hydrobioOperations = [],
}) => {
    const {
        taxons,
        supports,
    } = useSelector(store => ({
        taxons: store.TaxonReducer.taxons,
        supports: store.SupportReducer.supports,
    }), shallowEqual)

    // todo use id qualito as well as idOperation
    const uniqOperations = useMemo(() => {
        return uniqBy(hydrobioLists, ({ operation }) => operation)
    }, [hydrobioLists])

    const operationsFound = useMemo(() => {
        return uniqOperations.map(({ operation }) => hydrobioOperations.find(op => op.id === operation))
    }, [uniqOperations, hydrobioOperations])

    const operationsDatesSort = useMemo(() => {
        return orderBy(operationsFound, ['date', 'qualitometer'], ['desc', 'asc']).map(op => `${op.id}`)
    }, [operationsFound])

    const data = useMemo(() => {
        const listGroupByTaxon = groupBy(hydrobioLists, ({ taxon }) => taxon)
        return Object.keys(listGroupByTaxon).map(taxonCode => {
            const { [taxonCode]: listGroup = [] } = listGroupByTaxon

            const valuesObj = listGroup.reduce((acc, list) => {
                const key = `${list.operation}`
                acc[key] = {
                    justifyContent: 'right',
                    value: list.total,
                    sortValue: list.total,
                    tooltip: (<TooltipTaxon list={list}/>),
                }
                return acc
            }, {})

            const taxon = taxons.find(t => t.code === taxonCode)
            const allSupport = uniqBy(listGroup, 'support').map(({ support }) => supports.find(u => u.code == support)?.labelWithCode).filter(u => !!u).join(', ')

            const listTotal = listGroup.map(l => l.total)
            const minValue = minBy(listGroup, 'total')
            const maxValue = maxBy(listGroup, 'total')
            const averageValue = meanBy(listGroup, 'total')
            const sortListGroup = orderBy(listGroup, 'total')
            const percentileValue = calculatePercentile90(sortListGroup)
            const geoAverage = calculateGeometricAverage(listGroup.map(l => l.total))
            const nbCounting = listTotal.length
            const nbQuant = listGroup.filter(l => hasValue(l.remark) && !([0, 6, 8, 9].includes(l.remark))).length
            const quantTx = listGroup.length ? `${round(nbQuant * 100 / nbCounting, 2)} %` : '0%'

            return {
                ...valuesObj,
                taxon: {
                    value: taxon?.labelWithCode,
                    tooltip: taxon?.labelWithCode || undefined,
                },
                support: {
                    value: allSupport,
                    tooltip: allSupport || undefined,
                },
                min: {
                    value: minValue?.total,
                    classNameColor: 'grey',
                    justifyContent: 'right',
                    tooltip: (<TooltipTaxon list={minValue} />),
                },
                max: {
                    value: maxValue?.total,
                    classNameColor: 'grey',
                    justifyContent: 'right',
                    tooltip: (<TooltipTaxon list={maxValue} />),
                },
                averageShort: {
                    value: round(averageValue, 3),
                    classNameColor: 'grey',
                    justifyContent: 'right',
                },
                geometricAverageShort: {
                    value: round(geoAverage, 3),
                    classNameColor: 'grey',
                    justifyContent: 'right',
                },
                percentile90: {
                    value: percentileValue?.total,
                    classNameColor: 'grey',
                    justifyContent: 'right',
                    tooltip: (<TooltipTaxon list={percentileValue} />),
                },
                nbCounting: {
                    value: nbCounting,
                    classNameColor: 'grey',
                    justifyContent: 'right',
                },
                nbQuant: {
                    value: nbQuant,
                    classNameColor: 'grey',
                    justifyContent: 'right',
                },
                quantTx: {
                    value: quantTx,
                    classNameColor: 'grey',
                    justifyContent: 'right',
                },
            }
        })
    }, [hydrobioLists, supports, taxons])

    const customHeaders = useMemo(() => {
        const operationHeaders = operationsDatesSort.reduce((acc, id) => {
            acc[id] = {
                value: (<HeaderCell operationId={id} />),
                tooltip: (<HeaderCellTootip operationId={id} />),
            }
            return acc
        }, {})

        const statHeaders = {
            nbCounting: {
                style: {
                    whiteSpace: 'pre-wrap',
                },
            },
        }
        return {
            ...operationHeaders,
            ...statHeaders,
        }
    }, [operationsDatesSort])

    useActions(() => {
        return {
            export: () => {
                const listGroupByTaxon = groupBy(hydrobioLists, ({ taxon }) => taxon)
                const exportData = Object.keys(listGroupByTaxon).map(taxonCode => {
                    const { [taxonCode]: listGroup = [] } = listGroupByTaxon

                    const valuesObj = listGroup.reduce((acc, list) => {
                        const key = `${list.operation}`
                        acc[key] = {
                            positionCell: 'right',
                            value: list.total,
                        }
                        return acc
                    }, {})

                    const taxon = taxons.find(t => t.code === taxonCode)
                    const supportCodes = uniqBy(listGroup, 'support')

                    const listTotal = listGroup.map(l => l.total)
                    const minValue = minBy(listGroup, 'total')
                    const maxValue = maxBy(listGroup, 'total')
                    const averageValue = meanBy(listGroup, 'total')
                    const sortListGroup = orderBy(listGroup, 'total')
                    const percentileValue = calculatePercentile90(sortListGroup)
                    const geoAverage = calculateGeometricAverage(listGroup.map(l => l.total))
                    const nbCounting = listTotal.length
                    const nbQuant = listGroup.filter(l => hasValue(l.remark) && !([0, 6, 8, 9].includes(l.remark))).length
                    const quantTx = listGroup.length ? `${round(nbQuant * 100 / nbCounting, 2)} %` : '0%'

                    return {
                        taxonCode,
                        taxon: taxon?.latinName ?? '',
                        supportCode: supportCodes.join(', '),
                        support: supportCodes.map(({ support }) => supports.find(u => u.code == support)?.name).filter(u => !!u).join(', '),
                        ...valuesObj,
                        min: {
                            value: minValue?.total,
                            color: 'gray',
                            positionCell: 'right',
                        },
                        max: {
                            value: maxValue?.total,
                            color: 'gray',
                            positionCell: 'right',
                        },
                        averageShort: {
                            value: round(averageValue, 3),
                            color: 'gray',
                            positionCell: 'right',
                        },
                        geometricAverageShort: {
                            value: round(geoAverage, 3),
                            color: 'gray',
                            positionCell: 'right',
                        },
                        percentile90: {
                            value: percentileValue?.total,
                            color: 'gray',
                            positionCell: 'right',
                        },
                        nbCounting: {
                            value: nbCounting,
                            color: 'gray',
                            positionCell: 'right',
                        },
                        nbQuant: {
                            value: nbQuant,
                            color: 'gray',
                            positionCell: 'right',
                        },
                        quantTx: {
                            value: quantTx,
                            color: 'gray',
                            positionCell: 'right',
                        },
                    }
                })
                const exportHeaders = ['taxonCode', 'taxon', 'supportCode', 'support', ...operationsDatesSort, 'min', 'max', 'averageShort', 'geometricAverageShort', 'percentile90', 'nbCounting', 'nbQuant', 'quantTx']
                const exportCustomHeaders = ['taxonCode', 'taxon', 'supportCode', 'support', ...orderBy(operationsFound, ['date', 'qualitometer'], ['desc', 'asc']).map(o => getDate(o.date)), 'min', 'max', 'averageShort', 'geometricAverageShort', 'percentile90', 'nbCounting', 'nbQuant', 'quantTx']
                return {
                    data: exportData.length ? [{ ...exportData[0], headers: exportHeaders, customHeaders: exportCustomHeaders }, ...exportData.slice(1)] : [],
                    exportType: 'xlsx',
                    titleFile: i18n.hydrobioMonitoring,
                }
            },
        }
    }, [operationsFound, hydrobioLists, taxons, supports])

    return (
        <MultiGridTableV2
            data={data}
            customHeaders={customHeaders}
            headers={['taxon', 'support', ...operationsDatesSort, ...STATS_HEADERS]}
            fixedColumnCount={2}
            headerHeight={60}
        />
    )
}

HydrobioMonitoringTable.propTypes = {
    hydrobioLists: PropTypes.arrayOf(PropTypes.shape({
        // DtoHydrobioList
        date: PropTypes.number,
        support: PropTypes.string,
        total: PropTypes.number,
    })),
    hydrobioOperations: PropTypes.arrayOf(PropTypes.instanceOf(DtoOperation)),
}

export default HydrobioMonitoringTable