import { orderBy } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import SieauParameterDto from '../../../administration/dto/SieauParameterDto'
import ActionComponent from '../../../components/ActionComponent'
import GlobalCardList from '../../../components/list/cardList/GlobalCardList'
import SieauAction from '../../../components/sieau/SieauAction'
import { vigilancesPath } from '../../../conf/SieauConstants'
import { getVigilanceColor } from '../../../utils/ColorUtil'
import { getMapStateToProps, getPropTypes } from '../../../utils/StoreUtils'
import { getText } from '../../../utils/VigilanceUtil'

const propsToFetch = {
    vigilances: false,
}

class VigilancesApp extends ActionComponent {
    componentDidMount() {
        this.props.forceFetch('title', [{
            title: i18n.homepage,
            href: '',
        },
        {
            title: i18n.vigilances,
            href: 'vigilances',
        }])
        const actions = {
            links: [
                {
                    href: vigilancesPath,
                    img: 'assets/pictures/logos/meteoalarm.png',
                    label: 'Meteo Alarm',
                },
            ],
        }
        this.setActions(actions)
    }

    getVigilances = vigilances => orderBy(
        vigilances,
        [v => v.start],
        ['desc']
    ).reduce((acc, v) => {
        return {
            ...acc,
            [v.locationCode]: {
                title: `${v.locationCode} : ${v.locationName}`,
                cards: [
                    ...([v.locationCode].cards || []),
                    {
                        content: this.getVigilanceRender(v),
                        color: 'null',
                    },
                ],
            },
        }
    }, [])

    getVigilanceRender = vigilance => (
        <a href={ vigilancesPath } target='_new'>
            <div
                key={vigilance.locationCode + vigilance.start}
                className={ `collection-item row no-margin valign-wrapper border-bottom-light ${getVigilanceColor(vigilance.vigilance)}` }
            >
                <div className='col s2'>
                    <p className='center-align no-margin bold'>
                        { [
                            vigilance.info,
                            <br/>,
                        ] }
                    </p>
                </div>
                <div className='col s10 white'>
                    <h4 className='clickable bold' >{ vigilance.info }</h4>
                    <h6 className='clickable' >Du { vigilance.start } au { vigilance.end }</h6>
                    <div>
                        <h6 className='clickable'>{ getText(vigilance.texts) }</h6>
                    </div>
                </div>
            </div>
        </a>
    )

    render() {
        if (this.props.vigilances.length) {
            return (
                <div className='row no-margin'>
                    <div className='col s8 offset-s2'>
                        <GlobalCardList
                            data={ this.getVigilances(this.props.vigilances) }
                            title={ i18n.vigilances }
                            collapsible
                        />
                    </div>
                </div>
            )
        }
        return <h4 className='center-align'>{ i18n.noVigilancesToDisplay }</h4>
    }
}

VigilancesApp.propTypes = getPropTypes(propsToFetch, {
    applicationSettings: PropTypes.arrayOf(PropTypes.instanceOf(SieauParameterDto)),
})

const mapStateToProps = store => getMapStateToProps(propsToFetch, {
    applicationSettings: store.AdministrationReducer.applicationSettings,
})

const mapDispatchToProps = {
    fetch: SieauAction.fetch,
    forceFetch: SieauAction.forceFetch,
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(VigilancesApp)