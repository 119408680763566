import React, { useEffect, useMemo } from 'react'
import { Button, Dialog } from '@mui/material'
import Input from 'components/forms/Input'
import { DialogActionsMUI, DialogContentMUI, DialogTitleMUI } from 'components/styled/Dialog'
import PropTypes from 'prop-types'
import { useState } from 'react'
import i18n from 'simple-react-i18n'
import { getUrlScreen } from 'utils/UrlUtils'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import AdministrationAction from 'administration/actions/AdministrationAction'
import { getSetting } from 'utils/SettingUtils'
import moment from 'moment'
import AccountAction from 'account/actions/AccountAction'
import Icon from 'components/icon/Icon'

const homeScreenParameter = 'PageApresConnexion'

const UserHomePageModal = ({
    login,
    open = false,
    setOpen = () => {},
}) => {
    const {
        userSettings,
    } = useSelector(store => ({
        userSettings: store.AdministrationReducer.userSettings,
    }), shallowEqual)

    const [homeScreenUrl, setHomeScreenUrl] = useState('')

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(AdministrationAction.fetchUserSettings(login))
    }, [login])

    const userHomeScreenUrl = useMemo(() => getSetting(userSettings, homeScreenParameter), [userSettings])

    useEffect(() => {
        setHomeScreenUrl(userHomeScreenUrl)
    }, [userHomeScreenUrl])

    const screenUrl = useMemo(() => getUrlScreen(), [])

    const validate = () => {
        if (homeScreenUrl?.length > 0 && homeScreenUrl[0] === '/') {
            const filterSettings = userSettings.filter(o => o.parameter !== homeScreenParameter)
            const settings = [...filterSettings, {
                login,
                parameter: homeScreenParameter,
                updateDate: moment().valueOf(),
                value: homeScreenUrl,
            }]
            dispatch(AccountAction.updateSettings(login, settings))
            setOpen(false)
        }
    }

    return (
        <Dialog
            onClose={() => setOpen(false)}
            fullWidth
            maxWidth='lg'
            open={open}
        >
            <DialogTitleMUI style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                {i18n.defineYourHomepage}
                <Icon style={{ color: 'white' }} size='small' icon='close' onClick={() => setOpen(false)} />
            </DialogTitleMUI>
            <DialogContentMUI className='padding-top-1 padding-bottom-0'>
                <Input
                    col={ 12 }
                    title={ i18n.homePage }
                    placeholder={ screenUrl }
                    value={ homeScreenUrl }
                    onChange={ setHomeScreenUrl }
                />
            </DialogContentMUI>
            <DialogActionsMUI>
                <Button
                    onClick={validate}
                    variant='contained' color='primary'
                >
                    {i18n.validate}
                </Button>
            </DialogActionsMUI>
        </Dialog>
    )
}

UserHomePageModal.propTypes = {
    login: PropTypes.string,
    open: PropTypes.bool,
    setOpen: PropTypes.func,
}

export default UserHomePageModal