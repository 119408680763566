import PropTypes from 'prop-types'
import React, { useEffect, useMemo, useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import Table from '../../../components/datatable/Table'
import { PARAMETER_NATURES_DATA } from '../../../referencial/components/parameter/constants/ParameterConstants'
import { nbPerPageLabel } from '../../../referencial/constants/ReferencialConstants'
import { getLocalizationLabel, getLocalizationPicto, getStatusAndResult } from '../../../utils/AnalyseUtils'
import { getDate } from '../../../utils/DateUtil'
import { getLabel, getObjectLabel } from '../../../utils/StoreUtils'
import TooltipAnalyse from 'quality/components/qualityComponents/TooltipAnalyse'
import Card from 'components/card/Card'
import Checkbox from 'components/forms/Checkbox'
import SelectionSelect from 'components/forms/specific/SelectionSelect'
import useActions from 'utils/customHook/useActions'
import DtoOperation from 'quality/dto/operation/DtoOperation'
import useApplicationSetting from 'utils/customHook/useApplicationSetting'
import useAccountSetting from 'utils/customHook/useAccountSetting'
import useListIndexed from 'utils/customHook/useListIndexed'
import { SELECTION } from 'quality/constants/QualityConstants'
import { ParameterGraphModal } from '../qualityComponents/ParameterGraph'
import { isUndefined } from 'lodash'
import useBoolean from 'utils/customHook/useBoolean'
import DtoQualityThreshold from 'quality/dto/QualityThreshold/DtoQualityThreshold'

const DEFAULT_SELECTION = '-1'

const AEPoverviewPanel = ({
    filter = {},
    onExport = () => { },
    analysis = [],
    operations = [],
    thresholdList = [],
}) => {
    const {
        qualitometers,
        producers,
        qualifications,
        status,
        parametersIndex,
        unitsIndex,
    } = useSelector(store => ({
        qualitometers: store.QualityReducer.qualitometersLight,
        producers: store.ContributorReducer.producers,
        qualifications: store.QualityReducer.qualifications,
        status: store.QualityReducer.status,
        parametersIndex: store.ParameterReducer.parametersIndex,
        unitsIndex: store.UnitReducer.unitsIndex,
    }), shallowEqual)

    const settingSelection = useApplicationSetting(`SELECTION_FILTERS_${SELECTION.PC}`)
    const accountSettingSelection = useAccountSetting(`SELECTION_FILTERS_${SELECTION.PC}`)

    const {
        value: isOpen,
        setFalse: onClose,
        setTrue: onOpen,
    } = useBoolean(false)
    const [selectedParameter, setSelectedParameter] = useState()

    const [selection, setSelection] = useState(DEFAULT_SELECTION)
    const [selectionResults, setSelectionResults] = useState([])
    const [displayAllParameters, setDisplayAllParameters] = useState(false)

    useEffect(() => {
        setSelection(accountSettingSelection ?? settingSelection ?? DEFAULT_SELECTION)
    }, [settingSelection, accountSettingSelection])

    const producersIndexed = useListIndexed(producers, 'id')
    const qualitometersIndexed = useListIndexed(qualitometers, 'id')
    const operationsIndexed = useListIndexed(operations, o => `${o.id}#${o.qualitometer}`)

    const filteredAnalysis = useMemo(() => {
        const selectionFilter = selection !== DEFAULT_SELECTION ? analysis.filter(({ parameter }) => selectionResults.includes(parameter)) : analysis
        return displayAllParameters ? selectionFilter : selectionFilter.filter(a => a.color === 'red' && !!operationsIndexed[`${a.operation}#${a.qualitometer}`])
    }, [analysis, displayAllParameters, operationsIndexed, selection, selectionResults])

    const formatedAnalysis = useMemo(() => {
        return filteredAnalysis.map(a => {
            const parameter = parametersIndex[a.parameter]

            const { [a.unit]: { symbol } = {} } = unitsIndex

            const paramLabel = `${getObjectLabel(parameter, 'name')} ${parameter?.code ? `[${parameter.code}]` : ''}`

            const producer = producersIndexed[a.producer]
            const producerLabel = producer?.mnemonique ?? producer?.name ?? i18n.unknownProducer

            const qualitometer = qualitometersIndexed[a.qualitometer]

            return {
                statusAndResult: {
                    value: getStatusAndResult(a, a.value, a.color),
                    setTooltip: () => (
                        <TooltipAnalyse
                            title={paramLabel}
                            analyse={a}
                            threshold={a.threshold}
                        />
                    ),
                },
                stationCode: { value: qualitometer?.code },
                station: { value: qualitometer?.name },
                producer: { value: producerLabel },
                date: { value: getDate(a.analysisDate) },
                parameter: { value: paramLabel },
                unit: { value: symbol },
                threshold: { value: a.thresholdValue },
                nullValue: { value: getLocalizationPicto(a.localization) },
                parameterCode: a.parameter,
            }
        })
    }, [filteredAnalysis, parametersIndex, producersIndexed, qualitometersIndexed, unitsIndex])

    useActions(() => ({
        exportList: [{
            onClick: () => {
                const data = filteredAnalysis.map(a => {
                    const parameter = parametersIndex[a.parameter]
                    const producer = producersIndexed[a.producer]
                    const producerLabel = producer?.mnemonique || producer?.name || i18n.unknownProducer

                    const qualitometer = qualitometersIndexed[a.qualitometer]

                    return {
                        year: { value: filter.year, format: '0', cellType: 'number' },
                        stationCode: { value: qualitometer?.code, cellType: 'right' },
                        station: { value: qualitometer?.name },
                        date: { value: getDate(a.analysisDate), cellType: 'date' },
                        parameterCode: { value: a.parameter, cellType: 'right' },
                        parameter: parametersIndex[a.parameter].shortLabel || parametersIndex[a.parameter].name,
                        codeUnit: { value: a.unit, cellType: 'right' },
                        unit: unitsIndex[a.unit]?.symbol,
                        result: { value: a.value, color: a.color, cellType: 'right' },
                        qualification: getLabel(qualifications, a.qualification),
                        status: getLabel(status, a.status),
                        producer: producerLabel,
                        nature: getLabel(PARAMETER_NATURES_DATA, parameter.typeParam),
                        threshold: { value: a.thresholdValue, cellType: 'number' },
                        localisation: { value: getLocalizationLabel(a.localization) },
                    }
                })
                onExport({
                    data: data.length ? [{ ...data[0], headers: Object.keys(data[0]) }, ...data.slice(1)] : [],
                    exportType: 'xlsx',
                    titleFile: i18n.noncompliances,
                })
            },
            label: i18n.excel,
        }],
    }), [formatedAnalysis, qualitometersIndexed, producersIndexed, parametersIndex])

    const selectedAnalysis = useMemo(() => {
        if (isUndefined(selectedParameter)) {
            return []
        }
        return analysis.filter(a => a.parameter === selectedParameter)
    }, [analysis, selectedParameter])

    return (
        <div>
            <Card>
                <div className='row no-margin padding-top-1 padding-bottom-1 valign-wrapper'>
                    <SelectionSelect
                        col={8}
                        value={selection}
                        onChange={(res, s) => {
                            setSelection(s)
                            setSelectionResults(res)
                        }}
                    />
                    <div className='col s4' >
                        <Checkbox
                            label={i18n.displayAllParameters}
                            checked={displayAllParameters}
                            onChange={setDisplayAllParameters}
                        />
                    </div>
                </div>
            </Card>
            <Table
                title={i18n.noncompliances}
                data={formatedAnalysis}
                sortable
                color
                type={{ headers: ['stationCode', 'station', 'producer', 'date', 'parameter', 'statusAndResult', 'unit', 'threshold', 'nullValue'] }}
                condensed
                paging
                onClick={row => {
                    onOpen()
                    setSelectedParameter(row.parameterCode)
                }}
                nbPerPageLabel={nbPerPageLabel}
            />
            <ParameterGraphModal
                isOpen={isOpen}
                closeGraph={onClose}
                analysis={selectedAnalysis}
                thresholds={thresholdList}
            />
        </div>
    )
}

AEPoverviewPanel.propTypes = {
    filter: PropTypes.shape({
        referenceThreshold: PropTypes.number,
        limitThreshold: PropTypes.number,
        year: PropTypes.number,
    }),
    onExport: PropTypes.func,
    operations: PropTypes.arrayOf(PropTypes.instanceOf(DtoOperation)),
    analysis: PropTypes.arrayOf(PropTypes.shape({
        // DtoAnalysisLight
        // calculateThresholdResult
        producer: PropTypes.number,
    })),
    thresholdList: PropTypes.arrayOf(PropTypes.instanceOf(DtoQualityThreshold)),
}

export default AEPoverviewPanel