import React, { useState } from 'react'
import i18n from 'simple-react-i18n'
import PropTypes from 'prop-types'
import { Button, Dialog, Grid } from '@mui/material'
import { DialogActionsMUI, DialogContentMUI, DialogTitleMUI } from 'components/styled/Dialog'
import Icon from 'components/icon/Icon'
import { useDispatch } from 'react-redux'
import { BOOLEAN, DATE, FLOAT, NUMBER, SANDRE, STRING, SUBREQUEST } from 'utils/UserUtil'
import Input from 'components/forms/Input'
import NumberField from 'components/forms/NumberField'
import MultiAutocomplete from 'components/forms/MultiAutocomplete'
import useSandreList from 'utils/customHook/useSandreList'
import { useEffect } from 'react'
import UserAction from 'administration/components/user/actions/UserAction'
import SimpleDatePicker from 'components/forms/SimpleDatePicker'
import DtoFilterParam from 'station/dto/DtoFilterParam'
import { StyledFieldSet, StyledLegend } from 'components/StyledElements'
import ToastrAction from 'toastr/actions/ToastrAction'
import RadioButtons from 'components/forms/RadioButtons'
import FilterAltIcon from '@mui/icons-material/FilterAlt'
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff'

const validRadioElements = [
    { code: 'true', name: 'yes' },
    { code: 'not true', name: 'no' },
]

const FilterParam = ({
    param = {},
    setParam = () => {},
}) => {
    const sandreList = useSandreList(param.sandreCode || '')
    const dispatch = useDispatch()
    const [list, setList] = useState([])

    useEffect(() => {
        if (param.subRequest) {
            dispatch(UserAction.getFilterParamList(param.subRequest)).then(json => setList(json || []))
        }
    }, [])

    return (
        <Grid>
            <StyledFieldSet>
                <StyledLegend className='clickable' onClick={() => setParam({ filterResult: !param.filterResult })}>
                    <Grid
                        container
                        alignItems='center'
                    >
                        { param.filterResult ? <FilterAltIcon /> : <FilterAltOffIcon /> }
                        {param.parameterName}
                    </Grid>
                </StyledLegend>
                <Grid container justifyContent='space-around' alignItems='center'>
                    <Grid item xs={11}>
                        {param.filterType === NUMBER && (
                            <NumberField
                                value={parseInt(param?.value)}
                                onChange={v => setParam({ value: `${v}` })}
                                disabled={!param.filterResult}
                            />
                        )}
                        {param.filterType === FLOAT && (
                            <NumberField
                                value={parseFloat(param?.value)}
                                onChange={v => setParam({ value: `${v}` })}
                                floatValue
                                disabled={!param.filterResult}
                            />
                        )}
                        {param.filterType === SANDRE && (
                            <MultiAutocomplete
                                elements={sandreList}
                                clearFunction
                                values={param.value?.split(',').flatMap(d => parseInt(d))}
                                onChange={(values) => {
                                    setParam({ value: values.length && values.join(',') || undefined })
                                }}
                                disabled={!param.filterResult}
                            />
                        )}
                        {param.filterType === BOOLEAN && (
                            <>
                                <RadioButtons
                                    col={ 12 }
                                    elements={validRadioElements.map(d => ({ ...d, name: i18n[d.name] }))}
                                    selected={ param.value }
                                    onChange={value => setParam({ value })}
                                    disabled={!param.filterResult }
                                />
                            </>
                        )}
                        {param.filterType === STRING && (
                            <Input
                                value={param.value}
                                onChange={value => setParam({ value })}
                                disabled={!param.filterResult}
                            />
                        )}
                        {param.filterType === SUBREQUEST && (
                            <MultiAutocomplete
                                elements={list}
                                clearFunction
                                values={param.value?.split(',')}
                                onChange={values => setParam({ value: values.length && values.join(',') || undefined })}
                                disabled={!param.filterResult}
                            />
                        )}
                        {param.filterType === DATE && (
                            <SimpleDatePicker
                                label={' '}
                                value={parseInt(param?.value)}
                                onChange={v => setParam({ value: `${v}` })}
                                disabled={!param.filterResult}
                            />
                        )}
                    </Grid>
                </Grid>
            </StyledFieldSet>
        </Grid>
    )
}

FilterParam.propTypes = {
    param: PropTypes.instanceOf(DtoFilterParam),
    setParam: PropTypes.func,
}

const FilterDialog = ({
    parameters = [],
    isOpen = () => { },
    setIsOpen = () => { },
    launchSearch = () => { },
}) => {
    const dispatch = useDispatch()
    const [stateParameters, setStateParameters] = useState(parameters)

    const setParam = (param) => {
        setStateParameters(params => params.map(lp => lp.order === param.order ? param : lp))
    }

    const checkLaunch = () => {
        if (stateParameters.every(p => !p.filterResult || (p.filterResult && p.value))) {
            setIsOpen(false)
            launchSearch(stateParameters)
        } else {
            dispatch(ToastrAction.warning(i18n.pleaseCompleteAllRequiredField))
        }
    }

    return (
        <Dialog open={isOpen} fullWidth maxWidth='md'>
            <DialogTitleMUI style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                {i18n.criteriaFilter}
                <Icon
                    style={{ color: 'white' }}
                    size='small'
                    icon='close'
                    onClick={() => setIsOpen(false)}
                />
            </DialogTitleMUI>
            <DialogContentMUI sx={{ paddingBottom: '200px' }}>
                {
                    stateParameters.map(param => {
                        return (
                            <Grid key={param.parameterName}>
                                <FilterParam param={param} setParam={value => setParam(new DtoFilterParam({ ...param, ...value }))} />
                            </Grid>
                        )
                    })
                }
            </DialogContentMUI>
            <DialogActionsMUI>
                <Grid container justifyContent='center'>
                    <Button
                        variant='contained'
                        onClick={checkLaunch}
                    >
                        {i18n.apply}
                    </Button>
                </Grid>
            </DialogActionsMUI>
        </Dialog>
    )
}

FilterDialog.propTypes = {
    parameters: PropTypes.arrayOf(PropTypes.instanceOf(DtoFilterParam)),
    isOpen: PropTypes.bool,
    setIsOpen: PropTypes.func,
    launchSearch: PropTypes.func,
}

export default FilterDialog