import { push } from '@lagunovsky/redux-react-router'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import ColorfulCard from '../../components/card/ColorfulCard'
import Button from '../../components/forms/Button'
import DtoEvent from '../../events/dto/DtoEvent'
import EventCard from '../../station/components/event/EventCard'
import { getEventColor } from '../../utils/ColorUtil'

const CampaignEventsModal = ({
    events = [],
    stationType = '',
    campaignCode = '',
    campaignType = 2,
    date,
    stationId,
    redirect = () => { },
}) => (
    <div>
        <div className='row no-margin padding-bottom-1'>
            <Button
                col={4}
                className='offset-s4'
                title={i18n.new}
                onClick={() => {
                    redirect(`/station/${stationType}/${stationId}/event/0?date=${date}&campaignCode=${campaignCode}&campaignType=${campaignType === 1 ? 'G' : 'M'}`)
                    $('.modal').modal('close')
                }}
            />
        </div>
        <div className='row no-margin' onClick={() => $('.modal').modal('close')}>
            {
                events.map(e => (
                    <ColorfulCard color={getEventColor(e.eventType)}>
                        <div className='row no-margin'>
                            <EventCard
                                event={e}
                                small
                                link={`station/${stationType}/${stationId}/event/`}
                                showStationInfo={['productionUnit', 'distributionUnit', 'installation'].includes(stationType)}
                                stationType={stationType}
                            />
                        </div>
                    </ColorfulCard>
                ))
            }
        </div>
    </div>
)

CampaignEventsModal.propTypes = {
    events: PropTypes.arrayOf(PropTypes.instanceOf(DtoEvent)),
    stationType: PropTypes.string,
    date: PropTypes.number,
    stationId: PropTypes.string,
    campaignCode: PropTypes.number,
    campaignType: PropTypes.number,

    redirect: PropTypes.func,
}

const mapDispatchToProps = {
    redirect: push,
}

export default connect(null, mapDispatchToProps)(CampaignEventsModal)