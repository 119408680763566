import React, { Component, useEffect, useRef, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import PropTypes from 'prop-types'
import DisplayedValue from './DisplayedValue'
import { isEqualBy, sieauTooltip } from '../../utils/FormUtils'
import i18n from 'simple-react-i18n'
import { searchAllCharacters } from '../../utils/StringUtil'
import useDebounce from 'utils/customHook/useDebounce'
import { camelCase, isNil, valuesIn } from 'lodash'
import { FormControl, Icon, IconButton, InputAdornment, InputLabel, OutlinedInput, Tooltip } from '@mui/material'
import { darkBlue } from 'utils/constants/ColorTheme'

class Input extends Component {
    constructor(props) {
        super(props)
        this.state = { id: props.id || searchAllCharacters(props.title + uuidv4()).replaceAll(' ', '_').replace('numero', '').replace('nom', 'libelle'), editing: false }
    }

    onChangeValue = (event) => {
        if (this.props.onEnterKeyPress && (event.key === 'Enter')) {
            this.props.onEnterKeyPress(event.target.value)
            if (this.state.editing) {
                this.setState({ editing: false })
            }
        }
    }

    onBlur = () => {
        if (this.props.tableEditable && this.state.editing) {
            this.props.onEnterKeyPress($(`#${this.state.id}`).val())
        }
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.value && !nextProps.value) {
            $(`#${this.state.id}`).val('')
        }
    }

    onTableEditing = () => {
        this.setState({ editing: true }, () => {
            $(`#${this.state.id}`).focus()
            $(`#${this.state.id}`).blur(() => this.setState({ editing: false }))
        })
    }

    getClearFunction = () => {
        if (this.props.clearFunction) {
            return (
                <i data-clear-input
                    className={`material-icons clickable right ${this.props.disabled ? 'grey-text' : ''}`}
                    onClick={() => this.onClear()}
                >
                    clear
                </i>
            )
        }
        return null
    }

    onClear = () => {
        if (!this.props.disabled) {
            if (this.props.onEnterKeyPress) {
                this.props.onEnterKeyPress('')
            } else if (this.props.keyObj && this.props.changeObj) {
                this.props.changeObj({ [this.props.keyObj]: '', LAST_FORM: `INPUT-${uuidv4()}` })
            } else if (this.props.onChange) {
                this.props.onChange('')
            }
            $(`#${this.state.id}`).val('')
        }
    }

    callOnChange = (value) => {
        const val = this.props.noSpace ? value.replaceAll(' ', '') : value
        $(`#${this.state.id}`).val(val)

        if (this.props.keyObj && this.props.changeObj) {
            this.props.changeObj({ [this.props.keyObj]: val, LAST_FORM: `INPUT-${uuidv4()}` })
        }
        if (this.props.onChange) {
            this.props.onChange(val)
        }
    }

    getInput = () => {
        if (this.props.readMode || (this.props.tableEditable && !this.state.editing)) {
            return <DisplayedValue label={ this.props.title } obligatory={this.props.obligatory} value={ this.props.value } hideNull={ this.props.hideNull } onClick={ this.onTableEditing } tableEditable={ this.props.tableEditable }/>
        }
        const disabled = { readOnly: this.props.disabled }
        const dropDown = this.props.dropDownId ? { 'data-position': 'bottom', 'data-activates': this.props.dropDownId } : {}
        return (
            <div className={ `${(this.props.noInputFieldClass ? '' : 'input-field') + (this.props.clearFunction ? ' clearable-input' : '')} ${this.props.containerClassName}` } style={this.props.containerStyle} >
                { this.getClearFunction() }
                <input
                    style={ this.props.style }
                    type={ this.props.passwordType ? 'password' : 'text' }
                    className={ `sieau-input form-control input-sm ${this.props.className}` }
                    data-mode={this.state.id}
                    id={this.state.id}
                    onKeyPress={ e => this.onChangeValue(e) }
                    onChange={ e => this.callOnChange(e.target.value) }
                    placeholder={ this.props.placeholder }
                    { ...(this.props.tooltip ? sieauTooltip(this.props.tooltip, this.state.id, 'bottom') : null) }
                    maxLength={ this.props.maxLength }
                    onBlur={ this.onBlur }
                    {...this.props.otherInputProps}
                    {...dropDown}
                    {...disabled}
                    data-cy={this.props['data-cy']}
                />
                <label htmlFor={this.state.id} >
                    {this.props.title}
                    { this.props.obligatory && <span className='primary-color-text'>{ i18n.obligatoryField }</span> }
                </label>
            </div>
        )
    }

    render() {
        return this.props.col ? (
            <div className={ `col s${this.props.col} ${this.props.containerClassName}` } >
                { this.getInput() }
            </div>
        ) : this.getInput()
    }

    componentDidMount() {
        if (this.props.value) {
            $(`#${this.state.id}`).val(this.props.value)
        }
    }

    componentDidUpdate() {
        if (this.props.value) {
            $(`#${this.state.id}`).val(this.props.value)
        }
    }

    shouldComponentUpdate(nextProps) {
        if (this.props.freezeOpti && isEqualBy(this.props, nextProps, ['value', 'col', 'disabled', 'readMode'])) {
            return false
        }
        return true
    }
}

Input.propTypes = {
    title: PropTypes.string,
    className: PropTypes.string,
    containerClassName: PropTypes.string,
    onEnterKeyPress: PropTypes.func,
    onChange: PropTypes.func,
    tooltip: PropTypes.string,
    value: PropTypes.string,
    col: PropTypes.number,
    disabled: PropTypes.bool,
    defaultValue: PropTypes.string,
    passwordType: PropTypes.bool,
    noInputFieldClass: PropTypes.bool,
    dropDownId: PropTypes.string,
    placeholder: PropTypes.string,
    otherInputProps: PropTypes.object,
    style: PropTypes.object,
    containerStyle: PropTypes.object,
    readMode: PropTypes.bool,
    hideNull: PropTypes.bool,
    tableEditable: PropTypes.bool,
    maxLength: PropTypes.number,
    clearFunction: PropTypes.bool,
    id: PropTypes.string,
    keyObj: PropTypes.string,
    changeObj: PropTypes.func,
    freezeOpti: PropTypes.bool,
    obligatory: PropTypes.bool,
    noSpace: PropTypes.bool,

    'data-cy': PropTypes.string,
}

Input.defaultProps = {
    onChange: () => {},
    style: {},
    otherInputProps: {},
    readMode: false,
    clearFunction: false,
    className: '',
    title: '',
}

export default Input

const TYPES = {
    text: 'text',
    password: 'password',
    int: 'int',
    float: 'float',
}

const NewInput = ({
    id,
    value = '',
    placeholder,
    onEnterKeyPress = () => { },
    onChange = () => { },
    label,
    disabled = false,
    type = TYPES.text, // text || int || float
    debounceDelay = 500,

    multiline = false,

    min,
    max,

    startButton,
    endButton,

    tooltipProps = {},
}) => {
    const [stateValue, setStateValue] = useState(`${value ?? ''}`)
    const shouldDebounce = useRef(true)

    useEffect(() => {
        setStateValue(`${value ?? ''}`)
        shouldDebounce.current = false
    }, [value])

    const getParseValue = v => {
        switch (type) {
            case TYPES.int: return parseInt(v)
            case TYPES.float: return parseFloat(v)
            default: return v
        }
    }

    useDebounce(() => {
        if (!shouldDebounce.current) {
            return
        }
        if ((type === TYPES.int || type === TYPES.float) && stateValue === '-') {
            return
        }
        if (type === TYPES.float && stateValue.endsWith('.')) {
            return
        }
        onChange(stateValue === '' ? undefined : getParseValue(stateValue))
    }, debounceDelay, [stateValue])

    const formatMinMax = v => {
        if (v === '-') {
            return v
        }
        if (v.endsWith('.')) {
            return v
        }
        const parseV = type === TYPES.int ? parseInt(v) : parseFloat(v)
        const vMin = isNil(min) ? parseV : Math.max(parseV, min)
        const vMax = isNil(max) ? vMin : Math.min(vMin, max)
        return `${vMax}`
    }

    const handleChange = e => {
        shouldDebounce.current = true
        if (e.target.value === '') {
            setStateValue('')
            return
        }
        if (type === TYPES.int) {
            const regex = /^-?\d*$/
            if (regex.test(e.target.value)) {
                setStateValue(formatMinMax(e.target.value))
            }
            return
        }
        if (type === TYPES.float) {
            const regex = /^-?\d*(\.\d*)?$/
            if (regex.test(e.target.value)) {
                setStateValue(formatMinMax(e.target.value))
            }
            return
        }
        setStateValue(e.target.value)
    }

    const inputId = id ?? `${camelCase(label)}-input`

    const input = (
        <FormControl variant='outlined' fullWidth>
            {!!label && (<InputLabel htmlFor={inputId}>{label}</InputLabel>)}
            <OutlinedInput
                id={inputId}
                type={type}
                value={stateValue}
                onChange={handleChange}
                placeholder={placeholder}
                onKeyUp={(event) => {
                    if (event.key == 'Enter') {
                        onEnterKeyPress()
                    }
                }}
                startAdornment={startButton}
                endAdornment={endButton}
                label={label}
                disabled={disabled}
                inputProps={{ // A supprimer après notre css
                    style: {
                        border: 'none',
                        boxShadow: 'none',
                    },
                }}
                multiline={multiline}
            />
        </FormControl>
    )

    return tooltipProps.title ? (<Tooltip {...tooltipProps}>{input}</Tooltip>) : input
}

NewInput.propTypes = {
    id: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    placeholder: PropTypes.string,
    onEnterKeyPress: PropTypes.func,
    onChange: PropTypes.func,
    label: PropTypes.string,
    disabled: PropTypes.bool,
    type: PropTypes.oneOf(valuesIn(TYPES)),
    debounceDelay: PropTypes.number,

    multiline: PropTypes.bool,

    min: PropTypes.number,
    max: PropTypes.number,

    startButton: PropTypes.element,
    endButton: PropTypes.element,

    tooltipProps: PropTypes.shape({
        tooltip: PropTypes.string,
    }),
}

const SearchAdornment = ({
    onClick = () => { },
}) => (
    <InputAdornment position='end'>
        <IconButton
            aria-label='search'
            size='actionInputEnd'
            onClick={onClick}
        >
            <Icon fontSize='inherit' sx={{ color: darkBlue }}>search</Icon>
        </IconButton>
    </InputAdornment>
)

SearchAdornment.propTypes = {
    onClick: PropTypes.func,
}

export { NewInput, SearchAdornment }
