import { template, orderBy } from 'lodash'
import i18n from 'simple-react-i18n'
import { enumerateBetweenDates } from '../../../utils/DateUtil'
import moment from 'moment'
import echarts from 'echarts/lib/echarts'
import ReactEchartsCore from 'echarts-for-react/lib/core'
import React from 'react'

const getIndicatorComment = (c) => {
    if (c.startsWith('ind_')) {
        if (c.startsWith('ind_NotEnoughData')) {
            const variable = c.replace('ind_NotEnoughData', '')
            return template(i18n.ind_NotEnoughData)({ variable })
        }
        if (c.startsWith('ind_LastMeasureBefore')) {
            const variable = c.replace('ind_LastMeasureBefore', '')
            return template(i18n.ind_LastMeasureBefore)({ variable })
        }
        if (c.startsWith('ind_NotEnoughYearsForStat')) {
            const variable = c.replace('ind_NotEnoughYearsForStat', '')
            return template(i18n.ind_NotEnoughYearsForStat)({ variable })
        }
        return i18n[c]
        // ind_NoMeasures
        // ind_NotEnoughDataXXX
        // ind_NoMeasureFoundBefore
        // ind_LastMeasureBeforeXXX
        // ind_NotEnoughYearsForStatXXX
        // ind_UnexpectedErrorDuringCalculation
        // ind_UnableToCalculateRefValue
    }
    return ''
}

const IPSColorIndex = (color) => {
    return {
        '#808080': 0, // gray
        '#4400F6': 1, // bluedark
        '#3E66ED': 2, // purple
        '#34FFFB': 3, // blue
        '#48FF24': 4, // green
        '#F7FF28': 5, // yellow
        '#F6BD1B': 6, // orange
        '#F21007': 7, // red
    }[color] || 0
}

const getMapSituationCalendar = (year, month, existingDates, selected, changeDate, multiple) => { // multiple --> selected is an array, changeDate returns an array
    const dates = enumerateBetweenDates(moment(year).month(moment(month).month()).startOf('month').valueOf(), moment(year).month(moment(month).month()).endOf('month').valueOf())
    const heatmapData = multiple ? dates.map(d => [d.format('YYYY-M-DD'), selected.some(d2 => d.isSame(moment(d2), 'day')) ? 2 : (existingDates.some(d2 => d.isSame(moment(d2), 'day')) ? 1 : 0)])
        : dates.map(d => [d.format('YYYY-M-DD'), d.isSame(moment(selected), 'day') ? 2 : (existingDates.some(d2 => d.isSame(moment(d2), 'day')) ? 1 : 0)])

    const options = {
        visualMap: {
            show: false,
            calculable: true,
            seriesIndex: [1],
            min: 0,
            max: 2,
            orient: 'horizontal',
            left: 'center',
            bottom: 0,
            inRange: {
                color: ['#ffffff', '#00881d', '#f6a542'],
                opacity: 0.5,
            },
            controller: {
                inRange: {
                    opacity: 0.5,
                },
            },
        },
        calendar: [{
            left: 'center',
            top: 'middle',
            cellSize: [20, 20],
            yearLabel: { show: false },
            orient: 'vertical',
            dayLabel: {
                firstDay: 1,
                nameMap: ['dim', 'lun', 'mar', 'mer', 'jeu', 'ven', 'sam'],
                fontSize: 10,
            },
            monthLabel: {
                show: false,
            },
            range: `${moment(year).year()}-${moment(month).month() + 1}`,
        }],
        series: [{
            type: 'scatter',
            coordinateSystem: 'calendar',
            symbolSize: 1,
            label: {
                show: true,
                formatter (params) {
                    let d = echarts.number.parseDate(params.value[0])
                    return d.getDate()
                },
                color: '#000',
            },
            data: heatmapData,
        }, {
            name: '',
            type: 'heatmap',
            coordinateSystem: 'calendar',
            data: heatmapData,
        }],
    }
    return (
        <ReactEchartsCore
            echarts={ echarts }
            option={ options }
            notMerge={ true }
            lazyUpdate={ true }
            className={ 'row no-margin' }
            onEvents={{ click: (r) => {
                const newDate = moment(r.data[0], 'YYYY-M-DD').valueOf()
                if (multiple) {
                    if (r.data[1] === 2) {
                        changeDate(selected.filter(d => !moment(d).isSame(moment(newDate), 'day')))
                    } else {
                        changeDate(orderBy([ ...selected, newDate], v => v))
                    }
                } else {
                    changeDate(newDate)
                }
            } } }
            style={ { height: 160 } }
        />
    )
}

export { getIndicatorComment, IPSColorIndex, getMapSituationCalendar }