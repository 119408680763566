import React, { useEffect, useMemo, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { isNaN, isNumber, keyBy, pick, sortBy } from 'lodash'
import UserAction from '../../../administration/components/user/actions/UserAction'
import StationAction from '../../../station/actions/StationAction'
import { getStationFilterByModule } from '../../../utils/StationUtils'
import { getUserBookmarksByStationType } from '../../../utils/UserUtil'
import AdministrationAction from 'administration/actions/AdministrationAction'
import { STATION_TYPE_NAME } from 'station/constants/StationConstants'
import SuperMultiAutocomplete from '../SuperMultiAutocomplete'
import { hasValue } from '../../../utils/NumberUtil'
import FilterDialog from 'installation/components/installations/components/modal/FilterDialog'

const SimpleFilterSelect = ({
    col,
    onChange = () => { },
    stationType = '',
    disabled = false,
    title,
    stations = [],
    value,
    hidden,
    isValidateOpen = false,
    setIsOpenValidate = () => { },
    launchSearch = () => { },
    'data-cy': datacy,
    ignoreCache = false,
}) => {
    const dispatch = useDispatch()

    const {
        filters,
        userBookmarks,
    } = useSelector(store => ({
        filters: store.StationReducer.filters,
        userBookmarks: store.UserReducer.userBookmarks,
    }), shallowEqual)

    const [filter, setFilter] = useState(() => {
        if (ignoreCache) {
            return isNumber(value) ? value : -1
        }
        return (isNumber(value) ? value : pick(AdministrationAction.getCache(stationType), ['filter']).filter) ?? -1
    })
    const [filterResults, setFilterResults] = useState()
    const [parameters, setParameters] = useState(() => {
        if (ignoreCache) {
            return []
        }
        return pick(AdministrationAction.getCache(stationType), ['parameters']).parameters || []
    })
    const isFirstRender = useRef(true)

    const options = useMemo(() => {
        const filterOptions = sortBy(getStationFilterByModule(filters, stationType).map(f => ({ value: f.code, label: f.name })), 'label')
        return [{ value: -5, label: i18n.bookmarks }, ...filterOptions]
    }, [filters, stationType])

    const getStationResults = (resultsFilter = []) => {
        if (filter === -1) { // pas de filtre
            return []
        }
        if (filter === -5) { // filtre "favoris"
            return resultsFilter.map(b => stations.find(q => q.code?.toUpperCase() === b)).filter(s => !!s)
        }
        return stations.filter(s => resultsFilter.includes(s.id))
    }

    const onChangeFilter = (newValue) => {
        const parsedValue = parseInt(newValue)
        if (!isNaN(parsedValue)) {
            const filtersByModules = getStationFilterByModule(filters, stationType)
            const newFilter = filtersByModules.find(f => f.code == newValue)
            setParameters(newFilter?.link_parameters)
            setFilter(!isNaN(parsedValue) ? parsedValue : -1)
        } else {
            setParameters([])
            setFilter(-1)
        }
    }

    useEffect(() => {
        if (isFirstRender.current && filterResults?.length) {
            isFirstRender.current = false
            launchSearch(getStationResults(filterResults), filter, parameters)
        }
    }, [filterResults, filter, parameters])

    useEffect(() => {
        dispatch(UserAction.fetchBookmarks())
        dispatch(StationAction.fetchFilters())
    }, [])

    const foundFilter = useMemo(() => {
        const filtersByModules = getStationFilterByModule(filters, stationType)
        return filtersByModules.find(f => f.code == filter)
    }, [filter, filters, stationType])

    useEffect(() => {
        if (!ignoreCache) {
            dispatch(AdministrationAction.setCache(stationType, { filter, parameters }))
        }
        if (filter === -1) {
            setFilterResults([])
        } else if (filter === -5) {
            const codes = getUserBookmarksByStationType(userBookmarks, stationType).map(c => c.toUpperCase())
            setFilterResults(codes)
        } else if (foundFilter) {
            dispatch(StationAction.fetchFilterResults({ ...foundFilter, parameters }))
                .then((resultsFilter = []) => {
                    setFilterResults(resultsFilter.map(res => res.id))
                })
        } else {
            setFilterResults([])
        }
    }, [filter, filters, foundFilter, parameters])

    useEffect(() => {
        if (filterResults) {
            const results = getStationResults(filterResults)
            onChange(results, filter, parameters)
        }
    }, [userBookmarks, stationType, filterResults, stations.length]) // needed .length cause on some component the list change every rerender so this useEffect is reexecute and it update the parent rerender

    useEffect(() => {
        launchSearch(getStationResults(filterResults), filter, parameters)
    }, [])

    const nbStations = useMemo(() => {
        if (filter === -1) { // pas de filtre
            return 0
        }
        if (filter === -5) { // filtre "favoris"
            const keyIndexStations = keyBy(stations, s => s.code?.toUpperCase())
            return (filterResults || []).map(code => keyIndexStations[code]).filter(s => !!s).length
        }
        const keyIndexStations = keyBy(stations, 'id')
        return (filterResults || []).map(id => keyIndexStations[id]).filter(s => !!s).length
    }, [filterResults, stations.length])

    if (hidden) {
        return null
    }

    return (
        <>
            <SuperMultiAutocomplete
                col={col}
                options={options}
                values={filter}
                label={`${title || i18n.filter} ${hasValue(filter) && filter !== -1 ? `(${nbStations} ${nbStations > 1 ? i18n.stations : i18n.station})` : ''}`}
                keyLabel='label'
                keyValue='value'
                onChange={onChangeFilter}
                disabled={disabled}
                data-cy={datacy}
            />
            {isValidateOpen && (
                <FilterDialog
                    parameters={parameters}
                    isOpen={isValidateOpen}
                    setIsOpen={setIsOpenValidate}
                    launchSearch={newParameters => {
                        dispatch(StationAction.fetchFilterResults({ ...foundFilter, parameters: newParameters }))
                            .then((resultsFilter = []) => {
                                const results = resultsFilter.map(res => res.id)
                                setFilterResults(results)
                                setParameters(newParameters)
                                setIsOpenValidate(false)
                                if (!ignoreCache) {
                                    dispatch(AdministrationAction.setCache(stationType, { parameters: newParameters }))
                                }
                                launchSearch(getStationResults(results), filter, newParameters)
                            })
                    }}
                />
            )}
        </>
    )
}

SimpleFilterSelect.propTypes = {
    col: PropTypes.number,
    onChange: PropTypes.func,
    stationType: PropTypes.oneOf([
        STATION_TYPE_NAME.quality,
        STATION_TYPE_NAME.piezometry,
        STATION_TYPE_NAME.pluviometry,
        STATION_TYPE_NAME.hydrometry,
        STATION_TYPE_NAME.productionUnit,
        STATION_TYPE_NAME.distributionUnit,
        STATION_TYPE_NAME.installation,
        STATION_TYPE_NAME.resource,
        STATION_TYPE_NAME.catchment,
    ]),
    disabled: PropTypes.bool,
    title: PropTypes.string,
    stations: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
    })),
    value: PropTypes.number,
    hidden: PropTypes.bool,
    isValidateOpen: PropTypes.func,
    setIsOpenValidate: PropTypes.func,
    launchSearch: PropTypes.func,
    'data-cy': PropTypes.string,
    ignoreCache: PropTypes.bool,
}

export default SimpleFilterSelect