module.exports = {
    RECEIVE_ALL_APPLICATION_HABILITATION: 'RECEIVE_ALL_APPLICATION_HABILITATION',
    QUALITO: 'QUALITO',
    PGSSE: 'PGSSE',
    PIEZO: 'PIEZO',
    PLUVIO: 'PLUVIO',
    HYDRO: 'HYDRO',
    PRODUCTION: 'PRODUCTION',
    DISTRIBUTION: 'DISTRIBUTION',
    INSTALLATION: 'INSTALLATION',
    PERIMETERS: 'PERIMETERS',
    CATCHMENT: 'CATCHMENT',
    MAT: 'MAT',
    CARTO: 'CARTO',
    SITUATION: 'SITUATION',
    ALL: 'ALL',
    SITUATION_THEME: 'SITUATION_THEME',
    MESSAGES: 'MESSAGES',
    RESOURCE: 'RESOURCE',
    APPLICATIVE_USERS: 'APPLICATIVE_USERS',
}
