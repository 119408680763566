import { round } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import Checkbox from '../../../../components/forms/Checkbox'
import Input from '../../../../components/forms/Input'
import NumberField from '../../../../components/forms/NumberField'
import Select from '../../../../components/forms/Select'
import ContributorDto from '../../../../referencial/components/contributor/dto/ContributorDto'
import PowerSupplyTypeDto from '../../powerSupply/dto/PowerSupplyTypeDto'
import DtoSensorType from '../dto/DtoSensorType'

const SensorTypePanel = ({
    materielType = {},
    onChange = () => {},
    showContributorField = true,
    disabled = false,
    contributors = [],
    powerSupplyTypes = [],
}) => (
    <div>
        {
            showContributorField && (
                <div className='row no-margin'>
                    <Select
                        col={6}
                        options={contributors}
                        label={i18n.provider}
                        value={materielType.provider}
                        onChange={v => onChange({ provider: v })}
                        keyValue='id'
                        keyLabel='mnemonique'
                        disabled={disabled}
                    />
                    <Select
                        col={6}
                        options={contributors}
                        label={i18n.manufacturer}
                        value={materielType.manufacturerId}
                        onChange={v => onChange({ manufacturerId: v })}
                        keyValue='id'
                        keyLabel='mnemonique'
                        disabled={disabled}
                    />
                </div>
            )
        }
        <div className='row no-margin valign-wrapper'>
            <Input
                col={4}
                title={i18n.scale}
                value={materielType.range}
                onChange={v => onChange({ range: v })}
                disabled={disabled}
            />
            <NumberField
                col={4}
                title={i18n.lengthInMetter}
                value={materielType.length}
                onChange={v => onChange({ length: round(v, 2) })}
                disabled={disabled}
                floatValue
            />
            <Checkbox
                col={4}
                label={i18n.desiccant}
                checked={materielType.desiccant === '1'}
                onChange={v => onChange({ desiccant: v ? '1' : '0' })}
                disabled={disabled}
            />
        </div>
        <div className='row no-margin'>
            <Select
                col={4}
                label={i18n.powerSupplyType}
                options={powerSupplyTypes}
                value={materielType.powerSupplyType}
                onChange={v => onChange({ powerSupplyType: v })}
                disabled={disabled}
            />
            <NumberField
                col={4}
                title={i18n.batteryCount}
                value={materielType.pileNumber}
                onChange={value => onChange({ pileNumber: value })}
                disabled={disabled}
            />
            <NumberField
                col={4}
                title={i18n.supplyTime}
                value={materielType.supplyTime}
                onChange={value => onChange({ supplyTime: value })}
                disabled={disabled}
            />
        </div>
    </div>
)

SensorTypePanel.propTypes = {
    materielType: PropTypes.instanceOf(DtoSensorType),
    onChange: PropTypes.func,
    showContributorField: PropTypes.bool,
    disabled: PropTypes.bool,
    contributors: PropTypes.arrayOf(PropTypes.instanceOf(ContributorDto)),
    powerSupplyTypes: PropTypes.arrayOf(PropTypes.instanceOf(PowerSupplyTypeDto)),
}

const mapStateToProps = store => ({
    contributors: store.ContributorReducer.contributors,
    powerSupplyTypes: store.PowerSupplyReducer.powerSupplyTypes,
})

export default connect(mapStateToProps)(SensorTypePanel)