export default class DtoUserLayerAssignment {
    constructor(obj) {
        this.themeId = obj.themeId
        this.layerId = obj.layerId
        this.visibility = obj.visibility
        this.quality = obj.quality
        this.piezometry = obj.piezometry
        this.hydrometry = obj.hydrometry
        this.pluviometry = obj.pluviometry
        this.homepage = obj.homePage
        this.referencials = obj.referencials
        this.productionUnit = obj.productionUnit
        this.distributionUnit = obj.distributionUnit
        this.installation = obj.installation
        this.catchment = obj.catchment
        this.resource = obj.resource
        this.login = obj.login
    }
}
