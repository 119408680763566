import { Button, Grid } from '@mui/material'
import AgriAction from 'agriAdministration/actions/AgriAction'
import DtoPAR from 'agriAdministration/dto/DtoPAR'
import Card from 'components/card/Card'
import ColorfulCard from 'components/card/ColorfulCard'
import Axis from 'components/echart/Axis'
import EChart from 'components/echart/EChart'
import Bar from 'components/echart/series/Bar'
import StackedSeries from 'components/echart/series/StackedSeries'
import Input from 'components/forms/Input'
import TabList from 'components/list/TabList'
import { AccordionDetailsMUI, AccordionMUI, AccordionSummaryMUI } from 'components/styled/Accordions'
import DtoPAREvent from 'exploitations/dto/DtoPAREvent'
import { groupBy, orderBy, uniq } from 'lodash'
import PropTypes from 'prop-types'
import React, { useEffect, useMemo, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import DtoSandreCode from 'referencial/dto/DtoSandreCode'
import i18n from 'simple-react-i18n'
import { getFullDate, getFullMonthYear } from 'utils/DateUtil'
import { getNumberFormat } from 'utils/NumberUtil'
import { getLabel } from 'utils/StoreUtils'
import { searchAllCharacters } from 'utils/StringUtil'
import useTitle from 'utils/customHook/useTitle'

const PAREventsApp = () => {
    const { id } = useParams()

    const [filter, setFilter] = useState({})
    const [tmpFilter, setTmpFilter] = useState({})
    const [sortBy, setSortBy] = useState('eventDate')

    const {
        PAR,
        parEvents,
    } = useSelector(store => ({
        PAR: store.AgriReducer.PAR,
        parEvents: store.AgriReducer.parEvents,
    }), shallowEqual)

    useTitle(() => [{
        title: i18n.planning,
        href: 'planning/dashboard',
    }, {
        title: 'PARs',
        href: 'planning/par',
    }, {
        title: PAR.name,
        href: `par/${id}`,
    }, {
        title: i18n.journal,
        href: `par/${id}/events`,
    }], [PAR.name, id])

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(AgriAction.fetchPAREvents(id))
    }, [id])

    const getColor = (eventTypeId) => {
        switch (eventTypeId) {
            case 1: case 'c': case 'installation':
                return 'GREEN'
            case 2: case 'u':
                return 'ORANGE'
            case 3: case 'v': case 'declaration': case 'exploitation':
                return 'BLUE'
            case 4: case 'd': case 'materiel':
                return 'RED'
            default:
                return 'GREY'
        }
    }

    const eventsTypes = useMemo(() => {
        return [{
            code: 'c',
            id: 1,
            label: i18n.added,
            color: getColor('c'),
            icon: 'add_box',
        }, {
            code: 'u',
            id: 2,
            label: i18n.modification,
            color: getColor('u'),
            icon: 'edit',
        }, {
            code: 'd',
            id: 3,
            label: i18n.deleting,
            color: getColor('d'),
            icon: 'delete',
        }, {
            code: 'v',
            id: 4,
            label: i18n.validation,
            color: getColor('v'),
            icon: 'delete',
        }]
    }, [])

    const getGroupMethod = () => {
        switch (sortBy) {
            case 'referent':
                return e => e.updateLogin ?? i18n.unknownReferent
            case 'eventType':
                return e => getLabel(eventsTypes, e.eventType) || i18n.unknownType
            case 'date': default:
                return e => getFullMonthYear(e.eventDate)
        }
    }

    const getData = (events) => {
        const groups = groupBy(events, getGroupMethod())
        return orderBy(Object.keys(groups), key => key, sortBy === 'date' ? 'desc' : 'asc').map((key, i) => {
            const groupEvents = groups[key]
            const eventsGroup = groupBy(groupEvents, filter.type === 'exploitation' ? 'associateType' : 'objectId')
            const eventsCard = Object.keys(eventsGroup).map(objectId => {
                const orderedEvents = orderBy(eventsGroup[objectId], 'eventDate', 'desc')
                return (
                    <div className='row no-margin'>
                        {orderedEvents.map(e => {
                            const type = (eventsTypes.find((t) => t.code === e.eventType))?.label
                            return (
                                <ColorfulCard color={getColor(e.eventType)}>
                                    <Grid container className='padding-1'>
                                        <Grid item xs={2}>
                                            <p className='collection-title' style={{ fontWeight: 'bold' }}>{type ?? ''}</p>
                                        </Grid>
                                        <Grid item xs={1}>
                                            {e?.associateType && i18n[e.associateType]}
                                        </Grid>
                                        <Grid item xs={6}>
                                            <p className='collection-content'>{e.comment}</p>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <div className='collection-content'>{e.eventDate ? `${getFullDate(e.eventDate)} ${i18n.by} ${e.updateLogin || i18n.unknown}` : ''}</div>
                                        </Grid>
                                    </Grid>
                                </ColorfulCard>
                            )
                        })}
                    </div>
                )
            })
            return (
                <div className='padding-1'>
                    <AccordionMUI defaultExpanded={i === 0} round>
                        <AccordionSummaryMUI round>
                            {`${key} (${groupEvents.length} ${i18n[`event${groupEvents.length >= 2 ? 's' : ''}`]})`}
                        </AccordionSummaryMUI>
                        <AccordionDetailsMUI nopadding>
                            {eventsCard}
                        </AccordionDetailsMUI>
                    </AccordionMUI>
                </div>
            )
        })
    }

    const options = useMemo(() => {
        const orderedEvents = orderBy(parEvents, 'eventDate')
        const range = uniq(orderedEvents.map((e) => getFullMonthYear(e.eventDate)))
        const groupByType = groupBy(orderedEvents, 'eventType')
        const series = Object.keys(groupByType).map(keyType => {
            const eventType = eventsTypes.find(e => e.code === keyType) || { label: i18n.unknownType, color: 'GREY' }
            const groupedEvents = groupBy(groupByType[keyType], e => getFullMonthYear(e.eventDate))

            const data = range.map(monthYear => {
                const value = groupedEvents[monthYear] ? groupedEvents[monthYear].length : 0
                return {
                    name: monthYear,
                    value,
                }
            })

            return Bar({
                data,
                name: eventType.label,
                color: eventType.color,
            })
        })
        return {
            series: [StackedSeries(series)],
            tooltip: {
                trigger: 'axis',
            },
            yAxis: [Axis({
                type: 'value',
                axisLabel: {
                    formatter: getNumberFormat,
                },
            })],
            xAxis: [Axis({
                type: 'category',
                data: range,
                axisLabel: {
                    fontSize: 10,
                },
            })],
            height: 200,
            gridHeight: 180,
        }
    }, [parEvents, eventsTypes])

    const filteredEvents = useMemo(() => {
        return parEvents.filter(e => searchAllCharacters(e.comment).includes(searchAllCharacters(filter.searchValue)))
    }, [parEvents, filter.searchValue])

    return (
        <Grid container spacing={1} className='padding-top-1 padding-right-1' direction='column' justifyContent='flex-start' alignItems='stretch'>
            <Grid item>
                <Card round>
                    <EChart options={ options } />
                    <Card round>
                        <Grid container className='padding-1' justifyContent='space-between'>
                            <Grid item xs={4}>
                                <Input
                                    title={i18n.search}
                                    value={tmpFilter.searchValue}
                                    onChange={(v) => setTmpFilter({ searchValue: v })}
                                    onEnterKeyPress={() => setFilter(tmpFilter)}
                                />
                            </Grid>
                            <Grid item xs={4} container justifyContent='flex-end' alignItems='flex-end'>
                                <Grid item>
                                    <Button
                                        variant='outlined'
                                        onClick={() => {
                                            setFilter({})
                                            setTmpFilter({})
                                        }}
                                    >
                                        {i18n.reinit}
                                    </Button>
                                </Grid>
                                <Grid item className='padding-left-1'>
                                    <Button variant='contained' onClick={() => setFilter(tmpFilter)}>
                                        {i18n.search}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Card>
                </Card>
            </Grid>
            <Grid item>
                <TabList
                    onChangeTab={(v) => setSortBy(v)}
                    tabs={[
                        {
                            value: 'date',
                            label: i18n.perMonth,
                            icon: 'insert_invitation',
                        },
                        {
                            value: 'referent',
                            label: i18n.byReferent,
                            icon: 'person',
                        },
                        {
                            value: 'eventType',
                            label: i18n.byEventType,
                            icon: 'donut_small',
                        },
                    ]}
                >
                    {parEvents.length ? getData(filteredEvents) : <h5 className='margin-left-1'>{i18n.noDataToDisplay}</h5>}
                </TabList>
            </Grid>
        </Grid>
    )
}

PAREventsApp.propTypes = {
    params: PropTypes.shape({
        id: PropTypes.string,
    }),
    PAR: PropTypes.instanceOf(DtoPAR),
    parEvents: PropTypes.arrayOf(PropTypes.instanceOf(DtoPAREvent)),
    sandreCodes: PropTypes.arrayOf(PropTypes.instanceOf(DtoSandreCode)),
}

export default PAREventsApp