/* eslint-disable camelcase */

export default class DtoContributorTableLine {
    constructor(obj = []) {
        this.id = obj[0] // Int,
        this.name = obj[1] // Option[String],
        this.siret = obj[2] // Option[String],
        this.city = obj[3] // Option[String],
        this.postalCode = obj[4] // Option[String],
        this.phoneTel = obj[5] // Option[String],
        this.domain = obj[6] // Option[String],
        this.mnemonique = obj[7] // Option[String],
        this.sandre = obj[8] // Option[Int],
        this.status = obj[9] // Option[String],
        this.countryCode = obj[10] // Option [String]
    }
}