import { isNull } from 'lodash'

export default class DtoSearchHydrobioUltraLight {
    constructor(obj) {
        const tax = obj.map(e => isNull(e) ? undefined : e)
        this.qualitometer = tax[0] // Double,
        this.operation = tax[1] // Double,
        this.index = tax[3] // Option[Double] = None,
        this.operationDate = tax[4] // Option[DateTime] = None,
        this.taxon = tax[5] // Option[String] = None,
        this.enumerationA = tax[6] // Option [Double] = None,
        this.enumerationB = tax[8] // Option [Double] = None,
        this.enumerationC = tax[10] // Option [Double] = None,
        this.enumerationC2 = tax[14] // Option [Double] = None,
    }
}