import { INSTALLATION_TYPE } from 'installation/constants/InstallationConstants'

export default class DtoNetworkTableLine {
    constructor(obj) {
        this.id = obj[0] // Int,
        this.code = obj[1] // Option[String],
        this.name = obj[2] // Option[String],
        this.distributionUnits = obj[3] // Option[String],
        this.matter = obj[4] // Option[String],
        this.nature = obj[5] // Option[String],
        this.section = obj[6] // Option[Double],
        this.length = obj[7] // Option[Double],
        this.administratorId = obj[8] // Option[Int], // maitre d'ouvrage
        this.administratorName = obj[9] // Option[String],
        this.operatorId = obj[10] // Option[Int], // exploitant
        this.operatorName = obj[11] // Option[String]

        this.typeName = 'installation'
        this.installationType = INSTALLATION_TYPE.NETWORK
    }
}
