import DtoMaterielType from '../../../dto/DtoMaterielType'
import { MAT_POWER_SUPPLY } from '../../../constants/MaterielConstants'
import i18n from 'simple-react-i18n'

export default class PowerSupplyTypeDto extends DtoMaterielType {
    constructor(obj) {
        super(obj)
        this.materielCategory = MAT_POWER_SUPPLY
        this.materielLabel = i18n.powerSupply
        this.lifeTime = obj.lifeTime
        this.voltage = obj.voltage
        this.isNotReplaceable = obj.isNotReplaceable
    }
}
