export default class DtoMaterielAssignment {
    constructor(obj) {
        this.siteCode = obj.siteCode
        this.siteType = obj.siteType
        this.siteName = obj.siteName
        this.comment = obj.comment
        this.situationDate = obj.situationDate
        this.situationEndDate = obj.situationEndDate
        this.expectedRenewalDate = obj.expectedRenewalDate
        this.statusCode = obj.statusCode
        this.administratorCode = obj.administratorCode
    }
}