import React from 'react'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import { Button, Card, CardContent, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material'

const ConfirmModal = ({
    isOpen = false,
    title = '',
    subTitle = '',
    onValidate = () => {},
    onReject = () => {},
    onClose = () => { },
}) => {
    return (
        <Dialog
            fullWidth
            maxWidth='sm'
            open={isOpen}
            onClose={onClose}
        >
            {!!title && (
                <DialogTitle sx={{ borderBottom: subTitle ? 'solid 1px grey' : 'none' }}>
                    {title}
                </DialogTitle>
            )}
            {!!subTitle && (
                <DialogContent>
                    <Card>
                        <CardContent>
                            <Typography variant='subtitle1' color='text.secondary' component='div'>
                                {subTitle}
                            </Typography>
                        </CardContent>
                    </Card>
                </DialogContent>
            )}
            <DialogActions>
                <Button
                    onClick={() => {
                        onReject()
                        onClose()
                    }}
                    variant='outlined'
                >
                    {i18n.no}
                </Button>
                <Button onClick={onValidate} variant='contained' color='primary' data-cy='confirm_button'>
                    {i18n.yes}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

ConfirmModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    title: PropTypes.string,
    subTitle: PropTypes.string,
    onValidate: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    onReject: PropTypes.func,
}

export default ConfirmModal