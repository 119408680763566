import { deburr, lowerCase } from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { sieauTooltip } from '../../utils/FormUtils'

class Other extends Component {
    render() {
        const { tooltip, children, icon, className } = this.props
        return (
            <div className='action-wrapper'>
                <a
                    {...this.props}
                    className={`right waves-effect nav-actions ${className}`}
                    {...(tooltip ? sieauTooltip(tooltip, null, 'bottom') : null)}
                >
                    <i id={deburr(lowerCase(this.props.tooltip))} className='material-icons left no-margin'>{ icon }</i>
                </a>
                { children || null }
            </div>
        )
    }
}

Other.propTypes = {
    children: PropTypes.element,
    icon: PropTypes.string.isRequired,
    tooltip: PropTypes.string,
    className: PropTypes.string,
}

export default Other

