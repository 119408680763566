import { Card } from '@mui/material'
import ReactEchartsCore from 'echarts-for-react/lib/core'
import 'echarts/lib/chart/bar'
import echarts from 'echarts/lib/echarts'
import { filter, groupBy } from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import { mainWhite } from 'utils/constants/ColorTheme'
import { exportExcelIcon, exportPictureIcon } from '../../../../../../components/echart/EChartUtils'
import { getFullDate } from '../../../../../../utils/DateUtil'
import { exportFile } from '../../../../../../utils/ExportDataUtil'
import { getPlatformByModule, getPlatformColor } from '../../../../../../utils/StatisticUtil'
import DtoUserStatistic from '../../../dto/DtoUserStatistic'

class PlatformApplicationChartPanel extends Component {
    constructor(props) {
        super(props)
    }

    getExportData = () => {
        const data = this.props.userStatistics.map((d, i) => {
            const stat = {
                login: d.login,
                statisticDate: getFullDate(d.statisticDate),
                module: d.module,
                value: d.value,
            }
            if (i === 0) {
                return { ...stat, headers: ['login', 'statisticDate', 'module', 'value'] }
            }
            return stat
        })
        return data
    }

    getOptions = (filterStatistics, showToolbox) => {
        const reduceDatas = groupBy(filterStatistics.map(s => ({ ...s, value: getPlatformByModule(s.module) })), 'value')
        const dataSeries = Object.keys(reduceDatas).map((o) => {
            return {
                value: reduceDatas[o].length,
                name: o && o !== 'undefined' ? o.toUpperCase() : i18n.unknown,
                color: getPlatformColor(o),
            }
        })
        return {
            color: dataSeries.map(o => o.color),
            title: {
                text: i18n.usedPlatforms,
                x: showToolbox ? 'left' : 'center',
            },
            tooltip: {
                trigger: 'item',
                formatter: '{b} : {c} ({d}%)',
            },
            legend: {
                top: 'bottom',
                left: 'center',
                type: 'scroll',
                width: '90%',
                itemGap: 20,
            },
            series: [
                {
                    type: 'pie',
                    data: dataSeries,
                    avoidLabelOverlap: false,
                    radius: ['50%', '70%'],
                    label: {
                        normal: {
                            show: false,
                            position: 'center',
                        },
                        emphasis: {
                            show: true,
                            textStyle: {
                                fontSize: '20',
                                fontWeight: 'bold',
                            },
                        },
                    },
                    labelLine: {
                        normal: {
                            show: false,
                        },
                    },
                },
            ],
            toolbox: {
                show: showToolbox,
                feature: {
                    restore: { title: i18n.restore },
                    saveAsImage: { title: i18n.export, icon: exportPictureIcon },
                    myToolExport: {
                        show: true,
                        title: i18n.excelExport,
                        icon: exportExcelIcon,
                        onclick: () => {
                            exportFile({
                                data: this.getExportData(),
                                exportType: 'xlsx',
                                titleFile: i18n.userConnexionStatistic,
                            })
                        },
                    },
                },
            },
        }
    }

    render() {
        const { showTools, userStatistics } = this.props
        const filterStatistics = filter(userStatistics, o => o.value)
        if (filterStatistics.length) {
            return (
                <Card
                    sx={{
                        borderRadius: '4px',
                        boxShadow: '0px 4px 7px rgba(0, 0, 0, 0.1)',
                        padding: '12px',
                        backgroundColor: mainWhite,
                        marginBottom: '12px',
                    }}
                >
                    <ReactEchartsCore
                        echarts={ echarts }
                        option={ this.getOptions(filterStatistics, showTools) }
                        notMerge={ true }
                        lazyUpdate={ true }
                        className={ 'row no-margin' }
                        style={ { height: 200 } }
                    />
                </Card>
            )
        }
        return null
    }
}

PlatformApplicationChartPanel.propTypes = {
    showTools: PropTypes.bool,
    userStatistics: PropTypes.arrayOf(PropTypes.instanceOf(DtoUserStatistic)),
}

PlatformApplicationChartPanel.defaultProps = {
    showTools: true,
}

export default connect()(PlatformApplicationChartPanel)