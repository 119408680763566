import { Button, Grid } from '@mui/material'
import { StyledFieldSet, StyledLegend } from 'components/StyledElements'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import i18n from 'simple-react-i18n'
import Table from '../../../../../components/datatable/Table'
import SimpleDatePicker from '../../../../../components/forms/SimpleDatePicker'
import { nbPerPageLabel } from '../../../../../referencial/constants/ReferencialConstants'
import { getDate } from '../../../../../utils/DateUtil'
import { hasValue } from '../../../../../utils/NumberUtil'


class PlanningPopupExportEDILABO extends Component {
    state = {
        startDate: undefined,
        endDate: undefined,
        index: undefined,
    }

    render = () => {
        const {
            plannings = [],
            onChange = () => { },
            onDeletePlanning = () => { },
        } = this.props
        const {
            startDate,
            endDate,
            index,
        } = this.state
        const planningsFormat = plannings.map(({ startDate: start, endDate: end }, i) => ({
            index: i,
            startDate: getDate(start),
            endDate: getDate(end),
            start,
            end,
        }))
        return (
            <>
                <StyledFieldSet style={{ padding: '15px 10px' }}>
                    <StyledLegend>{i18n.sample}</StyledLegend>
                    <Grid container alignItems='center' justifyContent='left' spacing={2}>
                        <Grid item xs={2}>
                            <SimpleDatePicker
                                id='startDate'
                                label={i18n.startDate}
                                value={startDate}
                                onChange={date => this.setState({ startDate: date })}
                                max={endDate}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <SimpleDatePicker
                                id='endDate'
                                label={i18n.endDate}
                                value={endDate}
                                onChange={date => this.setState({ endDate: date })}
                                min={startDate}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <Button
                                variant='contained'
                                onClick={() => {
                                    if (startDate && endDate) {
                                        onChange({ startDate, endDate }, index)
                                        this.setState({ startDate: undefined, endDate: undefined, index: undefined })
                                    }
                                }}
                                sx={{
                                    '&:focus': {
                                        outline: 'none',
                                        backgroundColor: '#35609F',
                                    },
                                    '&:hover': {
                                        backgroundColor: 'rgb(37, 67, 111)',
                                        boxShadow: '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)',
                                    },
                                }}
                            >
                                {hasValue(index) ? i18n.change : i18n.add}
                            </Button>
                        </Grid>
                    </Grid>
                </StyledFieldSet>
                <div className='padding-top-1'>
                    <Table
                        title={i18n.planning}
                        data={planningsFormat}
                        type={{ headers: ['startDate', 'endDate'] }}
                        sortable
                        deletable={true}
                        onDelete={({ index: i }) => onDeletePlanning(i)}
                        alterable={true}
                        onAlter={({ start, end, index: i }) => this.setState({ startDate: start, endDate: end, index: i })}
                        condensed
                        paging
                        maxHeight='70%'
                        overflow='hidden'
                        nbPerPageLabel={nbPerPageLabel}
                    />
                </div>
            </>
        )
    }
}

PlanningPopupExportEDILABO.propTypes = {
    plannings: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        startDate: PropTypes.number,
        endDate: PropTypes.number,
    })),
    onChange: PropTypes.func,
    onDeletePlanning: PropTypes.func,
}

export default PlanningPopupExportEDILABO