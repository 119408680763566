import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import AppStore from '../../../store/AppStore'
import DtoCentral from '../../dto/materiel/DtoCentral'
import DtoPowerSupply from '../../dto/materiel/DtoPowerSupply'
import CentralTypeDto from '../../../materiel/components/central/dto/CentralTypeDto'
import PowerSupplyTypeDto from '../../../materiel/components/powerSupply/dto/PowerSupplyTypeDto'
import CentralAction from '../../../materiel/components/central/actions/CentralAction'
import PowerSupplyAction from '../../../materiel/components/powerSupply/actions/PowerSupplyAction'
import { filter, find, flatten } from 'lodash'
import { push } from '@lagunovsky/redux-react-router'
import {
    PATH_MATERIEL,
    PATH_MATERIEL_CENTRAL,
    PATH_MATERIEL_POWER_SUPPLY,
    PATH_MATERIEL_SENSOR,
    PATH_MATERIEL_SIM,
    PATH_MATERIEL_SUBSCRIPTION,
    PATH_MATERIEL_TELECOM,
    PATH_MATERIEL_VARIOUS_MATERIEL,
    PATH_STATION,
    PATH_STATION_MATERIEL,
} from '../../../home/constants/RouteConstants'
import DtoStationCentralAssignment from '../../dto/materiel/DtoStationCentralAssignment'
import DtoCentralRange from '../../../materiel/components/central/dto/DtoCentralRange'
import DtoStationPowerSupplyAssignment from '../../dto/materiel/DtoStationPowerSupplyAssignment'
import DtoSensor from '../../dto/materiel/DtoSensor'
import DtoStationSensorAssignment from '../../dto/materiel/DtoStationSensorAssignment'
import DtoSensorType from '../../../materiel/components/sensor/dto/DtoSensorType'
import DtoSim from '../../dto/materiel/DtoSim'
import DtoStationSimAssignment from '../../dto/materiel/DtoStationSimAssignment'
import DtoSimType from '../../../materiel/components/sim/dto/DtoSimType'
import TelecomTypeDto from '../../../materiel/components/telecom/dto/TelecomTypeDto'
import DtoStationTelecomAssignment from '../../dto/materiel/DtoStationTelecomAssignment'
import DtoTelecom from '../../dto/materiel/DtoTelecom'
import VariousMaterielDto from '../../../materiel/components/variousMateriel/dto/VariousMaterielDto'
import DtoStationVariousMaterielAssignment from '../../dto/materiel/DtoStationVariousMaterielAssignment'
import DtoVariousMateriel from '../../dto/materiel/DtoVariousMateriel'
import VariousMaterielAction from '../../../materiel/components/variousMateriel/actions/VariousMaterielAction'
import SimAction from '../../../materiel/components/sim/actions/SimAction'
import TelecomAction from '../../../materiel/components/telecom/actions/TelecomAction'
import SensorAction from '../../../materiel/components/sensor/actions/SensorAction'
import { getDay } from '../../../utils/DateUtil'
import ContributorDto from '../../../referencial/components/contributor/dto/ContributorDto'
import { getLabel } from '../../../utils/StoreUtils'
import Card from '../../../components/card/Card'
import MaterielTypeDto from '../../../materiel/dto/MaterielTypeDto'
import DtoSubscription from '../../dto/materiel/DtoSubscription'
import DtoStationSubscriptionAssignment from '../../dto/materiel/DtoStationSubscriptionAssignment'
import SubscriptionTypeDto from '../../../materiel/components/subscription/dto/SubscriptionTypeDto'
import SubscriptionAction from '../../../materiel/components/subscription/actions/SubscriptionAction'
import SimSubscriptionDto from '../../../materiel/components/sim/dto/SimSubscriptionDto'
import EquipmentDto from '../../../materiel/components/equipment/dto/EquipmentDto'
import EquipmentTypeDto from '../../../materiel/components/equipment/dto/EquipmentTypeDto'
import EquipmentAction from '../../../materiel/components/equipment/actions/EquipmentAction'
import moment from 'moment'
import DtoStationEquipmentAssignment from 'station/dto/materiel/DtoStationEquipmentAssignment'

class MaterielAssigned extends Component {
    constructor(props) {
        super(props)
    }

    componentWillMount() {
        if (!this.props.centralTypes.length) {
            this.props.fetchCentralTypes()
        }
        if (!this.props.powerSupplyTypes.length) {
            this.props.fetchPowerSupplyTypes()
        }
        if (!this.props.sensorTypes.length) {
            this.props.fetchSensorTypes()
        }
        if (!this.props.simTypes.length) {
            this.props.fetchSimTypes()
        }
        if (!this.props.telecomTypes.length) {
            this.props.fetchTelecomTypes()
        }
        if (!this.props.variousMaterielTypes.length) {
            this.props.fetchVariousMaterielTypes()
        }
        if (!this.props.equipmentTypes.length) {
            this.props.fetchEquipmentTypes()
        }
        if (!this.props.subscriptionTypes.length) {
            this.props.fetchSubscriptionTypes()
        }
        if (!this.props.simSubscriptionsLastSituations.length) {
            this.props.fetchSimSubscriptionsLastSituations()
        }
    }

    createDescriptionPanel = (label = '', reference) => {
        const finalLabel = (() => {
            if (reference) {
                return `${label} - ${reference}`
            }
            return label
        })()
        return (
            <div className='col s12 no-padding clickable'
                onClick={() => AppStore.dispatch(push(`${PATH_STATION}/${this.props.type}/${this.props.stationId}/${PATH_STATION_MATERIEL}`))}
            >
                <span>{finalLabel}</span>
            </div>
        )
    }

    createPanel = (title, dataComponent) => {
        return (
            <div className='row no-margin'>
                <div className='col s4 no-padding right-align'>
                    <h6 className='bold'>{title} :</h6>
                </div>
                <div className='col s8' style={{ paddingTop: '5px' }}>
                    <div className='row no-margin'>
                        {dataComponent}
                    </div>
                </div>
            </div>
        )
    }

    getFinaleValues = (assigments, listOfStationMateriel, key) => {
        return flatten(filter(assigments.map((o) => {
            return find(listOfStationMateriel, (b) => b.id === o[key])
        }), o => o))
    }

    getType = (listOfTypes, typeCode) => {
        return find(listOfTypes, (b) => {
            return b.id == typeCode
        })
    }

    getDescription = (materielList, assignmentList, materielRender, title, typeList, linkPath, idKey, typeCodeKey) => {
        const assigments = assignmentList.filter(({ situationEndDate }) => !situationEndDate || situationEndDate > moment().valueOf())
        const finalValues = this.getFinaleValues(assigments, materielList, idKey)
        const result = finalValues.map(materiel => {
            const type = this.getType(typeList, materiel[typeCodeKey])
            const link = linkPath + materiel.id
            return materielRender(materiel, link, type)
        })
        return this.createPanel(title, result)
    }

    getCentralDescription = () => {
        const materielList = this.props.stationCentral
        if (materielList.length) {
            const assignmentList = this.props.stationCentralAssignments
            const typeList = this.props.centralTypes
            const linkPath = `${PATH_MATERIEL}/${PATH_MATERIEL_CENTRAL}/`
            const materielRender = (materiel, link, type) => {
                const ranges = this.props.stationCentralRanges.map(r => {
                    const day = r.day ? getDay(r.day) : ''
                    return (<div>{day} { r.range}</div>)
                })
                const label = (() => {
                    if (type) {
                        const serialNumber = materiel.serialNumber ? `[${materiel.serialNumber}]` : ''
                        return (
                            <div>
                                { `${type.label} ${materiel.callFrequency || ''} ${materiel.callHour || ''} ${serialNumber}`}
                                { ranges}
                            </div>
                        )
                    }
                    return null
                })()
                return this.createDescriptionPanel(label, '', link)
            }
            return this.getDescription(materielList, assignmentList, materielRender, i18n.central, typeList, linkPath, 'idCentral', 'centralType')
        }
        return null
    }

    getPowerSupplyDescription = () => {
        const materielList = this.props.stationPowerSupply
        if (materielList.length) {
            const assignmentList = this.props.stationPowerSupplyAssignments
            const typeList = this.props.powerSupplyTypes
            const linkPath = `${PATH_MATERIEL}/${PATH_MATERIEL_POWER_SUPPLY}/`
            const materielRender = (materiel, link, type) => {
                if (type) {
                    const serialNumber = materiel.serialNumber ? ` [${materiel.serialNumber}]` : ''
                    return this.createDescriptionPanel(type.label + serialNumber, '', link)
                }
                return null
            }
            return this.getDescription(materielList, assignmentList, materielRender, i18n.powerSupply, typeList, linkPath, 'idPowerSupply', 'powerSupplyType')
        }
        return null
    }

    getSensorDescription = () => {
        const materielList = this.props.stationSensor
        if (materielList.length) {
            const assignmentList = this.props.stationSensorAssignments
            const typeList = this.props.sensorTypes
            const linkPath = `${PATH_MATERIEL}/${PATH_MATERIEL_SENSOR}/`
            const materielRender = (materiel, link, type) => {
                if (type) {
                    const serialNumber = materiel.serialNumber ? ` [${materiel.serialNumber}]` : ''
                    return this.createDescriptionPanel(type.label + serialNumber, '', link)
                }
                return null
            }
            return this.getDescription(materielList, assignmentList, materielRender, i18n.sensor, typeList, linkPath, 'idSensor', 'sensorType')
        }
        return null
    }

    getSimDescription = () => {
        const materielList = this.props.stationSim
        if (materielList.length) {
            const assignmentList = this.props.stationSimAssignments
            const typeList = this.props.simTypes
            const linkPath = `${PATH_MATERIEL}/${PATH_MATERIEL_SIM}/`
            const materielRender = (materiel, link) => {
                const simSubscription = this.props.simSubscriptionsLastSituations.find(simSubLast => simSubLast.idSim === materiel.id)
                const subscriptionAssociated = simSubscription ? (this.props.stationSubscription.find(sub => sub.id === simSubscription.idSubscription) || {}) : {}
                const subType = this.getType(this.props.subscriptionTypes, subscriptionAssociated.subscriptionType)
                const subscriptionAssociatedLabel = subType ? subType.label : subscriptionAssociated.numLine
                const label = subscriptionAssociatedLabel && `[${subscriptionAssociatedLabel}]` || ''
                return this.createDescriptionPanel(materiel.code, label, link)
            }
            return this.getDescription(materielList, assignmentList, materielRender, i18n.sim, typeList, linkPath, 'idSim', 'simType')
        }
        return null
    }

    getTelecomDescription = () => {
        const {
            stationTelecom,
            stationTelecomAssignments,
            telecomTypes,
        } = this.props
        if (!stationTelecom.length) {
            return undefined
        }
        const linkPath = `${PATH_MATERIEL}/${PATH_MATERIEL_TELECOM}/`
        const materielRender = (materiel, link, type) => {
            if (!type) {
                return undefined
            }
            const imei = materiel.imei ? ` [${materiel.imei}]` : ''
            const label = (
                <div>
                    <div>{type.label + imei}</div>
                    {materiel.providerId ?
                        <div>{getLabel(this.props.contributors, materiel.providerId, 'mnemonique')}</div> : null}
                </div>
            )
            return this.createDescriptionPanel(label, '', link)
        }
        return this.getDescription(stationTelecom, stationTelecomAssignments, materielRender, i18n.telecom, telecomTypes, linkPath, 'idTelecom', 'telecomType')
    }

    getVariousMaterielDescription = () => {
        const materielList = this.props.stationVariousMateriel
        if (materielList.length) {
            const assignmentList = this.props.stationVariousMaterielAssignments
            const typeList = this.props.variousMaterielTypes
            const linkPath = `${PATH_MATERIEL}/${PATH_MATERIEL_VARIOUS_MATERIEL}/`
            const materielRender = (materiel, link, type) => {
                if (type) {
                    const serialNumber = materiel.serialNumber ? ` [${materiel.serialNumber}]` : ''
                    return this.createDescriptionPanel(type.label + serialNumber, '', link)
                }
                return null
            }
            return this.getDescription(materielList, assignmentList, materielRender, i18n.variousMateriel, typeList, linkPath, 'idVarious', 'materielType')
        }
        return null
    }

    getEquipmentDescription = () => {
        const {
            stationEquipment,
            equipmentTypes,
            stationEquipmentAssignments,
        } = this.props
        const assignements = stationEquipmentAssignments.filter(({ situationEndDate }) => !situationEndDate || situationEndDate > moment().valueOf())
        const equipments = assignements.map(ass => {
            const equip = stationEquipment.find(({ equipment }) => equipment.id === ass.idEquipment)
            if (!equip) {
                return undefined
            }
            const type = this.getType(equipmentTypes, equip.equipment.materielType)
            if (!type) {
                return undefined
            }
            const link = `materiel/equipment/${equip.equipment.id}`
            const serialNumber = equip.equipment.serialNumber ? ` [${equip.equipment.serialNumber}]` : ''
            return this.createDescriptionPanel(type.label + serialNumber, '', link)
        }).filter(equip => equip)
        return this.createPanel(i18n.equipment, equipments)
    }

    getSubscriptionDescription = () => {
        const materielList = this.props.stationSubscription
        if (materielList.length) {
            const assignmentList = this.props.stationSubscriptionAssignments
            const typeList = this.props.subscriptionTypes
            const linkPath = `${PATH_MATERIEL}/${PATH_MATERIEL_SUBSCRIPTION}/`
            const materielRender = (materiel, link, type) => {
                if (type) {
                    const simSubscription = this.props.simSubscriptionsLastSituations.find(simSubLast => simSubLast.idSubscription === materiel.id)
                    const simAssociated = simSubscription ? (this.props.stationSim.find(sim => sim.id === simSubscription.idSim) || {}) : {}
                    const simType = this.getType(this.props.simTypes, simAssociated.simType)
                    const simAssociatedLabel = simType ? simType.label : simAssociated.serialNumber
                    const label = materiel.numLine ? `: ${materiel.numLine} - ${simAssociatedLabel ? `[${simAssociatedLabel}]` : ''}` : ''
                    return this.createDescriptionPanel(type.label + label, '', link)
                }
                return null
            }
            return this.getDescription(materielList, assignmentList, materielRender, i18n.subscription, typeList, linkPath, 'idSubscription', 'subscriptionType')
        }
        return null
    }

    haveMateriel = () => {
        return this.props.stationCentral.length
            || this.props.stationPowerSupply.length
            || this.props.stationSensor.length
            || this.props.stationSim.length
            || this.props.stationTelecom.length
            || this.props.stationVariousMateriel.length
            || this.props.stationEquipment.length
            || this.props.stationSubscriptionAssignments.length
    }

    render() {
        const {
            materielTypes,
        } = this.props
        return !!this.haveMateriel() && (
            <div className='row margin-bottom-1 no-margin-top'>
                <Card round>
                    {!!materielTypes.find(({ id }) => id === 1) && this.getCentralDescription()}
                    {!!materielTypes.find(({ id }) => id === 2) && this.getSensorDescription()}
                    {!!materielTypes.find(({ id }) => id === 3) && this.getPowerSupplyDescription()}
                    {!!materielTypes.find(({ id }) => id === 6) && this.getTelecomDescription()}
                    {!!materielTypes.find(({ id }) => id === 4) && this.getSimDescription()}
                    {!!materielTypes.find(({ id }) => id === 5) && this.getVariousMaterielDescription()}
                    {!!materielTypes.find(({ id }) => id === 7) && this.getEquipmentDescription()}
                    {!!materielTypes.find(({ id }) => id === 8) && this.getSubscriptionDescription()}
                </Card>
            </div>
        )
    }
}

MaterielAssigned.propTypes = {
    stationId: PropTypes.num,
    type: PropTypes.string,
    centralTypes: PropTypes.arrayOf(PropTypes.instanceOf(CentralTypeDto)),
    stationCentral: PropTypes.arrayOf(PropTypes.instanceOf(DtoCentral)),
    stationCentralAssignments: PropTypes.arrayOf(PropTypes.instanceOf(DtoStationCentralAssignment)),
    stationCentralRanges: PropTypes.arrayOf(PropTypes.instanceOf(DtoCentralRange)),
    stationPowerSupply: PropTypes.arrayOf(PropTypes.instanceOf(DtoPowerSupply)),
    stationPowerSupplyAssignments: PropTypes.arrayOf(PropTypes.instanceOf(DtoStationPowerSupplyAssignment)),
    powerSupplyTypes: PropTypes.arrayOf(PropTypes.instanceOf(PowerSupplyTypeDto)),
    stationSensor: PropTypes.arrayOf(PropTypes.instanceOf(DtoSensor)),
    stationSensorAssignments: PropTypes.arrayOf(PropTypes.instanceOf(DtoStationSensorAssignment)),
    sensorTypes: PropTypes.arrayOf(PropTypes.instanceOf(DtoSensorType)),
    stationSim: PropTypes.arrayOf(PropTypes.instanceOf(DtoSim)),
    stationSimAssignments: PropTypes.arrayOf(PropTypes.instanceOf(DtoStationSimAssignment)),
    simTypes: PropTypes.arrayOf(PropTypes.instanceOf(DtoSimType)),
    stationTelecom: PropTypes.arrayOf(PropTypes.instanceOf(DtoTelecom)),
    stationTelecomAssignments: PropTypes.arrayOf(PropTypes.instanceOf(DtoStationTelecomAssignment)),
    telecomTypes: PropTypes.arrayOf(PropTypes.instanceOf(TelecomTypeDto)),
    stationVariousMateriel: PropTypes.arrayOf(PropTypes.instanceOf(DtoVariousMateriel)),
    stationVariousMaterielAssignments: PropTypes.arrayOf(PropTypes.instanceOf(DtoStationVariousMaterielAssignment)),
    variousMaterielTypes: PropTypes.arrayOf(PropTypes.instanceOf(VariousMaterielDto)),
    stationEquipment: PropTypes.arrayOf(PropTypes.instanceOf(EquipmentDto)),
    stationEquipmentAssignments: PropTypes.arrayOf(PropTypes.instanceOf(DtoStationEquipmentAssignment)),
    equipmentTypes: PropTypes.arrayOf(PropTypes.instanceOf(EquipmentTypeDto)),
    stationSubscription: PropTypes.arrayOf(PropTypes.instanceOf(DtoSubscription)),
    stationSubscriptionAssignments: PropTypes.arrayOf(PropTypes.instanceOf(DtoStationSubscriptionAssignment)),
    subscriptionTypes: PropTypes.arrayOf(PropTypes.instanceOf(SubscriptionTypeDto)),
    simSubscriptionsLastSituations: PropTypes.arrayOf(PropTypes.instanceOf(SimSubscriptionDto)),
    contributors: PropTypes.arrayOf(PropTypes.instanceOf(ContributorDto)),
    materielTypes: PropTypes.arrayOf(PropTypes.instanceOf(MaterielTypeDto)),
    fetchCentralTypes: PropTypes.func,
    fetchPowerSupplyTypes: PropTypes.func,
    fetchSensorTypes: PropTypes.func,
    fetchSimTypes: PropTypes.func,
    fetchTelecomTypes: PropTypes.func,
    fetchVariousMaterielTypes: PropTypes.func,
    fetchEquipmentTypes: PropTypes.func,
    fetchSubscriptionTypes: PropTypes.func,
    fetchSimSubscriptionsLastSituations: PropTypes.func,

}

const mapStateToProps = store => ({
    centralTypes: store.CentralReducer.centralTypes,
    stationCentral: store.StationReducer.stationCentral,
    stationCentralAssignments: store.StationReducer.stationCentralAssignments,
    stationCentralRanges: store.StationReducer.stationCentralRanges,
    powerSupplyTypes: store.PowerSupplyReducer.powerSupplyTypes,
    stationPowerSupply: store.StationReducer.stationPowerSupply,
    stationPowerSupplyAssignments: store.StationReducer.stationPowerSupplyAssignments,
    sensorTypes: store.SensorReducer.sensorTypes,
    stationSensor: store.StationReducer.stationSensor,
    stationSensorAssignments: store.StationReducer.stationSensorAssignments,
    simTypes: store.SimReducer.simTypes,
    stationSim: store.StationReducer.stationSim,
    stationSimAssignments: store.StationReducer.stationSimAssignments,
    telecomTypes: store.TelecomReducer.telecomTypes,
    stationTelecom: store.StationReducer.stationTelecom,
    stationTelecomAssignments: store.StationReducer.stationTelecomAssignments,
    variousMaterielTypes: store.VariousMaterielReducer.variousMaterielTypes,
    stationVariousMateriel: store.StationReducer.stationVariousMateriel,
    stationVariousMaterielAssignments: store.StationReducer.stationVariousMaterielAssignments,
    equipmentTypes: store.EquipmentReducer.equipmentTypes,
    stationEquipment: store.StationReducer.stationEquipment,
    stationEquipmentAssignments: store.StationReducer.stationEquipmentAssignments,
    stationSubscription: store.StationReducer.stationSubscription,
    stationSubscriptionAssignments: store.StationReducer.stationSubscriptionAssignments,
    subscriptionTypes: store.SubscriptionReducer.subscriptionTypes,
    simSubscriptionsLastSituations: store.SubscriptionReducer.simSubscriptionsLastSituations,
    contributors: store.ContributorReducer.contributors,
    materielTypes: store.MaterielReducer.materielTypes,
})

const mapDispatchToProps = {
    fetchCentralTypes: CentralAction.fetchCentralTypes,
    fetchPowerSupplyTypes: PowerSupplyAction.fetchPowerSupplyTypes,
    fetchSensorTypes: SensorAction.fetchSensorTypes,
    fetchSimTypes: SimAction.fetchSimTypes,
    fetchTelecomTypes: TelecomAction.fetchTelecomTypes,
    fetchVariousMaterielTypes: VariousMaterielAction.fetchVariousMaterielTypes,
    fetchEquipmentTypes: EquipmentAction.fetchEquipmentTypes,
    fetchSubscriptionTypes: SubscriptionAction.fetchSubscriptionTypes,
    fetchSimSubscriptionsLastSituations: SubscriptionAction.fetchSimSubscriptionsLastSituations,
}

export default connect(mapStateToProps, mapDispatchToProps)(MaterielAssigned)
