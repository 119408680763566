import { push } from '@lagunovsky/redux-react-router'
import HomeAction from 'home/actions/HomeAction'
import { isEqual, isUndefined, last } from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import { STATION_NAME_ASSOCIATION, STATION_TYPE_CONSTANT } from 'station/constants/StationConstants'
import AppStore from 'store/AppStore'
import ToastrAction from 'toastr/actions/ToastrAction'
import { getFormatedFileName } from 'utils/FileUtils'
import { H_STATION_QUALITO_OPERATIONS } from '../../../../account/constants/AccessRulesConstants'
import UserDto from '../../../../administration/components/user/dto/UserDto'
import SieauParameterDto from '../../../../administration/dto/SieauParameterDto'
import Card from '../../../../components/card/Card'
import ProgressCard from '../../../../components/card/ProgressCard'
import Button from '../../../../components/forms/Button'
import Icon from '../../../../components/icon/Icon'
import Row from '../../../../components/react/Row'
import SieauAction from '../../../../components/sieau/SieauAction'
import SupportDto from '../../../../referencial/components/support/dto/SupportDto'
import StationAction from '../../../../station/actions/StationAction'
import DtoStation from '../../../../station/dto/DtoStation'
import { getDate } from '../../../../utils/DateUtil'
import { setModal } from '../../../../utils/FormUtils'
import { componentHasHabilitations } from '../../../../utils/HabilitationUtil'
import { getSetting, getUser } from '../../../../utils/SettingUtils'
import { getStationTitle } from '../../../../utils/StationUtils'
import { arrayOf, getLabel } from '../../../../utils/StoreUtils'
import DtoQualityThresholds from '../../../dto/QualityThreshold/DtoQualityThresholds'
import OperationAction from '../actions/OperationAction'
import { ANALYSIS, BILAN, DESCRIPTION, ENVIRONMENT, INDICES } from '../constants/OperationDescriptionConstants'
import DtoDetailOperation from '../dto/DtoDetailOperation'
import DtoEnvironment from '../dto/DtoEnvironmentalCondition'
import DtoIndice from '../dto/DtoIndice'
import DtoRemark from '../dto/DtoRemark'
import OperationAnalysisPanel from './analysis/OperationAnalysisPanel'
import OperationOverviewPanel from './chart/OperationOverviewPanel'
import OperationDescriptionPanel from './description/OperationDescriptionPanel'
import EnvironmentalConditionsPanel from './environmentalCondition/EnvironmentalConditionsPanel'
import OperationIndicesPanel from './indices/OperationIndicesPanel'
import DtoQualityIndicators from '../../../dto/QualityIndicator/DtoQualityIndicators'
import DtoPrettyAnalysisLight from '../../dashboard/dto/DtoPrettyAnalysisLight'
import DtoSample from '../../../dto/DtoSample'
import queryString from 'query-string'

class StationOperationApp extends Component {
    constructor(props) {
        super(props)
        const thersholdInSettings = (() => {
            try {
                const test = parseInt(getSetting(props.applicationSettings, 'SEUILS'))
                return isNaN(test) ? undefined : test
            } catch (e) {
                return undefined
            }
        })()
        const foundFirstIndicatorThreshold = (props.qualityIndicators.find(q => q.stationType == props.qualitometer.stationType)?.indicators || [])?.[0]?.threshold
        const { tab } = queryString.parse(this.props.location.search)
        this.state = {
            panel: tab ?? (props.params.code !== 'new' ? BILAN : DESCRIPTION),
            height: 250,
            dataLoaded: false,
            progress: 0,
            threshold: thersholdInSettings ?? foundFirstIndicatorThreshold,
            defaultThreshold: thersholdInSettings ?? foundFirstIndicatorThreshold,
            sample: undefined, // sample of the operation (prelevements)
            samplings: [], // samplings of operation (prelevements_echantillons)
            networks: [], // networks of operation (qualitometres_points_operations_reseaux)
        }
    }

    setTitle = (station, operation, support) => {
        const {
            id,
            code,
        } = this.props.params
        this.props.forceFetch('title', [{
            title: i18n.quality,
            href: 'quality',
        }, {
            title: getStationTitle(station),
            href: `station/quality/${id}`,
        }, {
            title: i18n.operations,
            href: `station/quality/${id}/operation`,
        }, {
            title: code === 'new' ? i18n.newOperation : `${i18n.operation} : ${getDate(operation.date)} - ${support}`,
            href: `station/quality/${id}/operation/${code}`,
        }])
    }

    componentDidMount() {
        if (!componentHasHabilitations(H_STATION_QUALITO_OPERATIONS)) { // A modifier quand react-router sera à jour
            this.props.push('/unauthorized')
            return
        }
        $('ul.tabs').tabs()
        this.setState({
            height: document.documentElement.clientHeight,
        })
        if (this.props.qualitometer) {
            this.setTitle(this.props.qualitometer, this.props.operation, getLabel(this.props.supports, this.props.operation.support))
        }
        this.props.loadOperationApp(this.props.params.code, this.props.params.id, this.state.threshold, progress => this.setState({ progress })).then(sample => {
            OperationAction.promiseSampleSamplings(parseInt(this.props.params.id), sample.id).then(samplings => {
                OperationAction.promiseOperationNetworks(this.props.params.id, this.props.params.code).then(networks => {
                    this.setState({ dataLoaded: true, sample: new DtoSample(sample), samplings, networks })
                })
            })
        })
        const { tab } = queryString.parse(this.props.location.search)
        if (this.props.params.code !== 'new') {
            this.props.fetchFiles(this.props.params.id, this.props.params.code)
            if (this.props.users.length) {
                this.setState({ panel: tab ?? getUser().labo === '1' ? ANALYSIS : BILAN }, () => $('ul.tabs').tabs())
            }
        }
        if (!isUndefined(tab)) {
            this.setState({ panel: tab }, () => $('ul.tabs').tabs())
        }
        AppStore.dispatch(HomeAction.setHelpLink('qualite', '29'))
    }

    reloadOperation = () => {
        this.props.fetchOperation(this.props.params.code, this.props.params.id)
        OperationAction.promiseSample(this.props.params.id, this.props.params.code).then(s => this.setState({ sample: s }))
        this.props.fetchAnalysis(parseInt(this.props.params.code), parseInt(this.props.params.id), this.state.threshold)
    }

    componentDidUpdate = prevProps => {
        $('ul.tabs').tabs()
        if (!isEqual(this.props.qualitometer, prevProps.qualitometer) || !isEqual(this.props.operation, prevProps.operation) || !isEqual(this.props.supports, prevProps.supports)) {
            this.setTitle(this.props.qualitometer, this.props.operation, getLabel(this.props.supports, this.props.operation.support))
        }
        if (this.props.params.code !== 'new' && !isEqual(this.props.users, prevProps.users)) {
            const { tab } = queryString.parse(this.props.location.search)
            this.setState({ panel: tab ?? (getUser().labo === '1' ? ANALYSIS : BILAN) }, () => $('ul.tabs').tabs())
        }
        if (!isEqual(this.props.applicationSettings, prevProps.applicationSettings)) {
            this.setState({
                threshold: getSetting(this.props.applicationSettings, 'SEUILS') || undefined,
            })
        }
    }

    componentWillUnmount() {
        this.props.fetchResetSingle()
    }

    getOperationFiles = () => {
        setModal({
            title: i18n.analysisDocuments,
            content: (
                <div>
                    {
                        this.props.files.map(fUrl => {
                            const name = last(fUrl.split('/'))
                            return { name, url: fUrl }
                        }).map(f => {
                            return (
                                <Row>
                                    <a href={ f.url } target='_blank' className='modal-close flex-row valign-wrapper'><Icon icon='attach_file'/><h5>{ f.name }</h5></a>
                                </Row>
                            )
                        })
                    }
                    <Row>
                        <Button title={ i18n.close } className='left red modal-close'/>
                    </Row>
                </div>
            ),
        })
    }

    uploadFile = e => {
        const [file] = e.target.files
        const reader = new FileReader()
        reader.onload = upload => {
            const {
                code = '',
                id: stationId,
            } = this.props.qualitometer
            const {
                analysisNumber = '',
                refAnalysis = '',
                id: operationId,
                date,
            } = this.props.operation
            const fileNameSplit = file.name.split('.')
            const ext = fileNameSplit.slice(fileNameSplit.length - 1)
            const objFile = {
                content: upload.target.result,
                name: getFormatedFileName(code, undefined, `${analysisNumber}_${refAnalysis}`, ext, undefined, date, 'quality', false),
                stationType: STATION_TYPE_CONSTANT[STATION_NAME_ASSOCIATION.quality],
                stationId,
                path: '',
            }
            this.props.addStationFile(objFile, 'doc').then(() => this.props.fetchFiles(stationId, operationId))
        }
        reader.onerror = () => {
            this.props.toastrError(i18n.importError)
        }
        reader.readAsDataURL(file)
    }

    getPanel = () => {
        const files = this.props.files
        switch (this.state.panel) {
            case ENVIRONMENT:
                return (
                    <Card><EnvironmentalConditionsPanel
                        uploadFile={this.uploadFile}
                        files={files}
                        getOperationFiles={this.getOperationFiles}
                        operation={this.props.operation}
                    /></Card>
                )
            case BILAN:
                return (
                    <OperationOverviewPanel
                        id={this.props.params.id}
                        height={this.state.height}
                        threshold={this.state.threshold}
                        files={files}
                        getOperationFiles={() => this.getOperationFiles()}
                        uploadFile={this.uploadFile}
                        sample={this.state.sample}
                        onChangeThreshold={threshold => this.setState({ threshold })}
                    />
                )
            case DESCRIPTION:
                return (
                    <Card><OperationDescriptionPanel
                        id={this.props.params.id}
                        code={this.props.params.code}
                        uploadFile={this.uploadFile}
                        files={files}
                        getOperationFiles={() => this.getOperationFiles()}
                        sample={this.state.sample}
                        reloadOperation={this.reloadOperation}
                        changeParent={changes => this.setState(changes)}
                        samplings={this.state.samplings}
                        networks={this.state.networks}
                    /></Card>
                )
            case ANALYSIS:
                return (
                    <Card><OperationAnalysisPanel
                        id={this.props.params.id}
                        code={this.props.params.code}
                        operation={this.props.operation}
                        analysis={this.props.operationAnalysis}
                        remarks={this.props.remarks}
                        threshold={this.state.threshold}
                        onChangeThreshold={threshold => this.setState({ threshold })}
                        uploadFile={this.uploadFile}
                        files={files}
                        getOperationFiles={() => this.getOperationFiles()}
                        sampleId={this.state.sample?.id}
                        defaultThreshold={this.state.defaultThreshold}
                        samplings={this.state.samplings}
                    /></Card>
                )
            case INDICES:
                return (
                    <Card><OperationIndicesPanel
                        qualitometerId={this.props.operation.qualitometer}
                        operationId={this.props.operation.id}
                        uploadFile={this.uploadFile}
                        files={files}
                        getOperationFiles={() => this.getOperationFiles()}
                    /></Card>
                )
            default:
                return null
        }
    }

    changeTab = panel => this.props.params.code !== 'new' && this.setState({ panel })

    render() {
        const {
            dataLoaded,
            progress,
            panel,
        } = this.state
        if (dataLoaded) {
            const {
                operationAnalysis,
                environmentalConditions,
                indices,
            } = this.props
            const { code } = this.props.params
            const tabs = [
                code !== 'new' && <li className='tab'><a className={panel === BILAN ? 'active' : ''} onClick={() => this.changeTab(BILAN)}>{i18n.overview}</a></li>,
                <li className='tab'><a className={panel === DESCRIPTION ? 'active' : ''} onClick={() => this.changeTab(DESCRIPTION)}>{i18n.descriptive}</a></li>,
                <li className={`tab ${code === 'new' ? 'disabled' : ''}`}><a className={panel === ANALYSIS ? 'active' : ''} onClick={() => this.changeTab(ANALYSIS)}>{i18n.analysis} ({operationAnalysis.length})</a></li>,
                <li className={`tab ${code === 'new' ? 'disabled' : ''}`}><a className={panel === INDICES ? 'active' : ''} onClick={() => this.changeTab(INDICES)}>{i18n.indices} ({indices.length})</a></li>,
                <li className={`tab ${code === 'new' ? 'disabled' : ''}`}><a className={panel === ENVIRONMENT ? 'active' : ''} onClick={() => this.changeTab(ENVIRONMENT)}>{i18n.environmentalConditions} ({ environmentalConditions.length}) </a></li>,
            ]

            const orderedTabs = getUser().labo === '1' ? tabs.reverse() : tabs

            return (
                <div className='row no-margin'>
                    <div className='col s12'>
                        <ul className='tabs tabs-fixed-width'>
                            {orderedTabs}
                        </ul>
                    </div>
                    <div className='col s12'>
                        {this.getPanel()}
                    </div>
                </div>
            )
        }
        return <ProgressCard progress={progress} />
    }
}

StationOperationApp.propTypes = {
    params: PropTypes.shape({
        code: PropTypes.string,
        id: PropTypes.string,
    }),
    location: PropTypes.shape({
        search: PropTypes.string,
    }),
    qualitometer: PropTypes.instanceOf(DtoStation),
    operation: PropTypes.instanceOf(DtoDetailOperation),
    operationAnalysis: arrayOf(DtoPrettyAnalysisLight),
    environmentalConditions: PropTypes.arrayOf(PropTypes.instanceOf(DtoEnvironment)),
    remarks: PropTypes.arrayOf(PropTypes.instanceOf(DtoRemark)),
    indices: PropTypes.arrayOf(PropTypes.instanceOf(DtoIndice)),
    supports: PropTypes.arrayOf(PropTypes.instanceOf(SupportDto)),
    qualityThresholds: PropTypes.arrayOf(PropTypes.instanceOf(DtoQualityThresholds)),
    users: PropTypes.arrayOf(PropTypes.instanceOf(UserDto)),
    applicationSettings: PropTypes.arrayOf(PropTypes.instanceOf(SieauParameterDto)),
    files: PropTypes.arrayOf(PropTypes.string),

    forceFetch: PropTypes.func,
    fetchResetSingle: PropTypes.func,
    loadOperationApp: PropTypes.func,
    toastrError: PropTypes.func,
    addStationFile: PropTypes.func,
    fetchFiles: PropTypes.func,
    push: PropTypes.func,
    fetchOperation: PropTypes.func,
    fetchAnalysis: PropTypes.func,
    qualityIndicators: arrayOf(DtoQualityIndicators),
}

const mapStateToProps = store => ({
    qualitometer: store.QualityReducer.qualitometer,
    operations: store.OperationReducer.operations,
    operation: store.OperationReducer.operation,
    operationAnalysis: store.OperationReducer.operationAnalysis,
    remarks: store.OperationReducer.remarks,
    supports: store.SupportReducer.supports,
    indices: store.OperationReducer.operationIndices,
    qualityThresholds: store.QualityReducer.qualityThresholds,
    users: store.UserReducer.users,
    applicationSettings: store.AdministrationReducer.applicationSettings,
    files: store.OperationReducer.files,
    environmentalConditions: store.OperationReducer.environmentalConditions,
    qualityIndicators: store.QualityReducer.qualityIndicators,
})

const mapDispatchToProps = {
    forceFetch: SieauAction.forceFetch,
    fetchResetSingle: OperationAction.fetchResetSingle,
    loadOperationApp: OperationAction.loadOperationApp,
    toastrError: ToastrAction.error,
    addStationFile: StationAction.addStationFile,
    fetchFiles: OperationAction.fetchFiles,
    fetchOperation: OperationAction.fetchOperation,
    fetchAnalysis: OperationAction.fetchAnalysis,
    push,
}

export default connect(mapStateToProps, mapDispatchToProps)(StationOperationApp)
