import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import Row from '../../../../../components/react/Row'
import Input from '../../../../../components/forms/Input'
import Textarea from '../../../../../components/forms/Textarea'
import DtoSandreCode from '../../../../../referencial/dto/DtoSandreCode'
import DtoTankOperatorEquipment from '../../dto/DtoTankOperatorEquipment'
import Checkbox from '../../../../../components/forms/Checkbox'

class TankOperatorEquipmentModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            operatorEquipment: { ...props.operatorEquipment, idStation: props.id },
        }
    }

    componentDidUpdate(prevProps) {
        if (!prevProps.popupIsOpen && this.props.popupIsOpen) {
            $('#descriptionModal').on('click', this.onSave)
        }
    }
    onSave = () => {
        this.props.saveResult(new DtoTankOperatorEquipment(this.state.operatorEquipment))
        $('.modal').modal('close')
    }

    onChangeElement = (changes) => this.setState({ operatorEquipment: { ...this.state.operatorEquipment, ...changes } })

    render() {
        const { operatorEquipment } = this.state
        return (
            <div>
                <Row>
                    <Input
                        col={ 6 }
                        title={ i18n.equipment }
                        value={ operatorEquipment.equipment }
                        onChange={ v => this.onChangeElement({ equipment: v }) }
                    />
                </Row>
                <Row>
                    <Checkbox
                        col={6}
                        checked={operatorEquipment.fixingState}
                        label={i18n.fixingState}
                        onChange={v => this.onChangeElement({ fixingState: v })}
                    />
                    <Checkbox
                        col={6}
                        checked={operatorEquipment.sealingState}
                        label={i18n.sealingState}
                        onChange={v => this.onChangeElement({ sealingState: v })}
                    />
                </Row>
                <Row>
                    <Checkbox
                        col={6}
                        checked={operatorEquipment.ductPassageState}
                        label={i18n.ductPassageState}
                        onChange={v => this.onChangeElement({ ductPassageState: v })}
                    />
                    <Checkbox
                        col={6}
                        checked={operatorEquipment.implantationState}
                        label={i18n.implantationState}
                        onChange={v => this.onChangeElement({ implantationState: v })}
                    />
                </Row>
                <Row noMargin={false} className='margin-top-1'>
                    <Textarea
                        col={ 12 }
                        title={ i18n.comment }
                        value={ operatorEquipment.comment }
                        onChange={ v => this.onChangeElement({ comment: v }) }
                    />
                </Row>
            </div>
        )
    }
}

TankOperatorEquipmentModal.propTypes = {
    operatorEquipment: PropTypes.instanceOf(DtoTankOperatorEquipment),
    id: PropTypes.number,
    sandreCodes: PropTypes.arrayOf(PropTypes.instanceOf(DtoSandreCode)),
    popupIsOpen: PropTypes.bool,
    saveResult: PropTypes.func,
}


const mapStateToProps = store => ({
    sandreCodes: store.ReferencialReducer.sandreCodes,
    popupIsOpen: store.SieauReducer.popupIsOpen,
})

TankOperatorEquipmentModal.defaultProps = {
    contributor: {},
}

export default connect(mapStateToProps)(TankOperatorEquipmentModal)
