import React, { Component } from 'react'
import { connect } from 'react-redux'
import AppStore from 'store/AppStore'
import PropTypes from 'prop-types'
import DtoPicture from 'station/dto/DtoPicture'
import DtoPiezometer from '../../../piezometry/dto/DtoPiezometer'
import PluviometerDto from '../../../pluviometry/dto/PluviometerDto'
import DtoHydrometricStation from '../../../hydrometry/dto/DtoHydrometricStation'
import DtoProductionUnit from '../../../productionUnit/dto/DtoProductionUnit'
import DtoFile from '../../../components/file/dto/DtoFile'
import PictureStationPanel from './PictureStationPanel'
import HomeAction from '../../../home/actions/HomeAction'
import DtoStation from '../../dto/DtoStation'
import StationAction from '../../actions/StationAction'
import { execByType, execByTypes, getLinks, getStation, getStationTitle, getStationTypeCodeFromType } from '../../../utils/StationUtils'
import i18n from 'simple-react-i18n'
import SieauAction from '../../../components/sieau/SieauAction'
import ProgressBar from '../../../components/progress/ProgressBar'
import { getStationArrowNav } from '../../../utils/ActionUtils'
import { push } from '@lagunovsky/redux-react-router'
import { flatten, isEqual, isNaN, orderBy, sortBy, uniqBy } from 'lodash'
import { componentHasHabilitations } from '../../../utils/HabilitationUtil'
import {
    H_STATION_HYDRO_DOCUMENTS,
    H_STATION_INSTALLATION_DOCUMENTS,
    H_STATION_PIEZO_DOCUMENTS,
    H_STATION_PLUVIO_DOCUMENTS,
    H_STATION_QUALITO_DOCUMENTS,
    H_STATION_RESOURCE_DOCUMENTS,
    H_UNITS_DOCUMENTS,
} from '../../../account/constants/AccessRulesConstants'
import DtoInstallationTableLine from 'installation/dto/installation/DtoInstallationTableLine'
import { arrayOf, getSandreList } from 'utils/StoreUtils'
import ResourceDto from 'resource/dto/ResourceDto'
import ToastrAction from 'toastr/actions/ToastrAction'
import Select from '../../../components/forms/Select'
import DtoSandreCode from 'referencial/dto/DtoSandreCode'
import { getFileExtension, getFileName, getFileNatureLabel, getFileType, getFormatedFileName, getTypeSandreCode } from 'utils/FileUtils'
import Checkbox from 'components/forms/Checkbox'
import { DOCUMENT, PICTURE } from 'components/file/constant/FileConstants'
import { getYear } from 'utils/DateUtil'
import FileFilter from 'components/file/FileFilter'
import { Button, Dialog, Grid } from '@mui/material'
import TabList from 'components/list/TabList'
import Icon from 'components/icon/Icon'
import { hasValue } from 'utils/NumberUtil'
import { DialogActionsMUI, DialogContentMUI, DialogTitleMUI } from 'components/styled/Dialog'
import SimpleDatePicker from 'components/forms/SimpleDatePicker'
import moment from 'moment'
import Input from 'components/forms/Input'
import { STATION_NAME_ASSOCIATION } from 'station/constants/StationConstants'
import DtoAssociatedStation from 'station/dto/DtoAssociatedStation'
import Table from 'components/datatable/Table'
import { getSiteUrl } from 'utils/mapUtils/SiteTypes'
import { setActions } from '../../../components/ActionUtil'
import PresentationCard from 'components/card/PresentationCard'
import UserDto from '../../../administration/components/user/dto/UserDto'
import { getUser } from '../../../utils/SettingUtils'

const ACTION_TYPE = {
    REPLACE: 'replace',
    RENAME: 'rename',
}

class PictureStationApp extends Component {
    constructor(props) {
        super(props)
        this.state = {
            pictures: props.pictures,
            files: props.files,
            station: {},
            picturesLoaded: false,
            documentsLoaded: false,
            isEditMode: false,
            openPopup: false,
            tabGroupBy: 'date',
            actionType: '',
            openPopupExistFile: false,
        }
    }

    toggleEditMode = () => {
        this.setState({ isEditMode: !this.state.isEditMode })
    }

    onClosePopupAddFile = () => {
        this.setState({ openPopup: false, file: null, newName: null, openPopupExistFile: false, actionType: null })
    }

    onOpenPopupAddFile = () => {
        const station = getStation(this.props, this.props.stationTypes[0])
        if (station.code) {
            this.setState({ openPopup: true })
        } else {
            this.props.toastrInfo(i18n.pleaseFillCodeBeforeNewFile)
        }
    }

    setTitle = ({ code = '', id, name = '', designation = '' }) => {
        this.setState({ stationId: id, stationCode: code })
        const stationType = this.props.stationTypes[0]
        this.props.forceFetch('title', [{
            title: i18n[stationType],
            href: stationType,
        }, {
            title: getStationTitle({ id, code, name, designation }),
            href: `station/${stationType}/${id}`,
        }, {
            title: i18n.picturesAndDocuments,
            href: `station/${stationType}/${id}/picture`,
        }])
    }

    getArrowAndLinksNavAction = () => {
        const station = getStation(this.props, this.props.stationTypes[0])
        const stations = sortBy(flatten(execByTypes(this.props.stationTypes, {
            quality: () => this.props.qualitometers,
            piezometry: () => this.props.piezometers,
            hydrometry: () => this.props.hydrometricStations,
            pluviometry: () => this.props.pluviometers,
            productionUnit: () => this.props.productionUnits,
            distributionUnit: () => this.props.distributionUnits,
            installation: () => this.props.installationTable,
            resource: () => this.props.resources,
            default: () => [],
        })), o => o.name ? o.name.toUpperCase() : '}')
        return {
            links: getLinks(station, this.props),
            arrowNav: getStationArrowNav(this.props.stationTypes[0], stations, station.id, s => this.props.push(`/station/${this.props.stationTypes[0]}/${s.id}/picture`)),
        }
    }

    addActions = (editMode) => {
        if (getUser().consultant === '1') {
            setActions({
                ...this.getArrowAndLinksNavAction(),
            })
        } else if (editMode) {
            setActions({
                new: this.onOpenPopupAddFile,
                cancel: this.toggleEditMode,
                ...this.getArrowAndLinksNavAction(),
            })
        } else {
            setActions({
                edit: this.toggleEditMode,
                ...this.getArrowAndLinksNavAction(),
            })
        }
    }

    componentDidMount() {
        const stationType = this.props.stationTypes[0]
        const isAuthorized = execByType(stationType, {
            quality: () => componentHasHabilitations(H_STATION_QUALITO_DOCUMENTS),
            piezometry: () => componentHasHabilitations(H_STATION_PIEZO_DOCUMENTS),
            pluviometry: () => componentHasHabilitations(H_STATION_PLUVIO_DOCUMENTS),
            hydrometry: () => componentHasHabilitations(H_STATION_HYDRO_DOCUMENTS),
            installation: () => componentHasHabilitations(H_STATION_INSTALLATION_DOCUMENTS),
            units: () => componentHasHabilitations(H_UNITS_DOCUMENTS),
            resource: () => componentHasHabilitations(H_STATION_RESOURCE_DOCUMENTS),
        })
        if (!isAuthorized) { // A modifier quand react-router sera à jour
            this.props.push('/unauthorized')
            return
        }
        const station = getStation(this.props, stationType)
        if (!this.props.associatedSites) {
            this.props.fetchAssociatedSites(station.code, stationType)
        }
        this.setState({ station })
        if (station.id) {
            this.setTitle(station)
            this.loadPictures(station.code)
            this.loadDocuments(station.code)
        }
        AppStore.dispatch(HomeAction.setBackPath(`/${stationType}`))
        this.addActions(this.state.isEditMode)
    }

    loadPictures = (code) => {
        if (code?.replaceAll(' ', '') !== '') {
            this.props.fetchPictures(code, this.props.stationTypes[0]?.toUpperCase()).then(() => {
                this.setState({ picturesLoaded: true })
            })
        } else {
            this.props.warning(i18n.noFileOrPictureWithoutCode)
            this.setState({ picturesLoaded: true })
        }
    }

    loadDocuments = (code) => {
        if (code?.replaceAll(' ', '') !== '') {
            this.props.fetchFiles(code, this.props.stationTypes[0]?.toUpperCase()).then(() => {
                this.setState({ documentsLoaded: true })
            })
        } else {
            this.setState({ documentsLoaded: true })
        }
    }

    componentDidUpdate(prevProps) {
        if (!isEqual(prevProps.pictures, this.props.pictures)) {
            const { pictures } = this.props
            this.setState({ pictures })
        }
        if (!isEqual(prevProps.files, this.props.files)) {
            const { files } = this.props
            this.setState({ files })
        }
        this.addActions(this.state.isEditMode)
    }

    onFileDelete = (file) => {
        this.setState({ documentsLoaded: false, picturesLoaded: false })
        this.props.deleteFile(file).then(() => {
            this.loadPictures(this.state.station.code)
            this.loadDocuments(this.state.station.code)
        })
    }

    onFileUpdate = (file, replace = false) => {
        const { files, pictures } = this.state
        if (replace || ![...files, ...pictures].some(({ name }) => name === file.newName)) {
            this.setState({ documentsLoaded: false, picturesLoaded: false })
            this.props.updateFile(file).then(() => {
                this.loadPictures(this.state.station.code)
                this.loadDocuments(this.state.station.code)
                this.onClosePopupAddFile()
            })
        } else {
            this.setState({ openPopup: false, openPopupExistFile: true, file })
            this.props.warning(i18n.nameAlreadyExist)
        }
    }

    componentWillReceiveProps(nextProps) {
        const station = getStation(this.props, this.props.stationTypes[0])
        const nextStation = getStation(nextProps, this.props.stationTypes[0])
        if (!station.id && nextStation.id || (station.id !== nextStation.id)) {
            this.setState({ documentsLoaded: false, picturesLoaded: false })
            this.setTitle(nextStation)
            this.loadPictures(nextStation.code)
            this.loadDocuments(nextStation.code)
        }
    }

    onUploadFile = (event) => {
        // Read File
        const { stationId } = this.state
        if (event.target.files && event.target.files[0]) {
            let reader = new FileReader()
            const file = event.target.files[0]
            const { sandreCodes } = this.props
            reader.onload = (e) => {
                const { sandreCode } = getFileType(e.target.result)
                const fileTypes = orderBy(getSandreList(sandreCodes, sandreCode), 'code')
                this.setState({
                    file:
                    {
                        stationType: 1,
                        stationId,
                        path: '',
                        name: file.name,
                        content: e.target.result,
                        fileType: fileTypes[0]?.code,
                        active: 1,
                    },
                })
            }
            reader.readAsDataURL(event.target.files[0])
        }
    }

    saveFile = (replace = false) => {
        const { file, station, stationCode, files, pictures, newName } = this.state
        const { stationTypes, sandreCodes } = this.props
        const fileName = newName ? `${newName}.${getFileExtension(file.name)}` : file.name
        const date = moment().valueOf()

        if (file && file?.content && getFileType(file.content) && hasValue(file.fileType)) {
            const { sandreCode } = getFileType(file.content)
            const fileType = getSandreList(sandreCodes, sandreCode).find(type => type.code === file.fileType)
            const fileTypeLabel = fileType.mnemonique || fileType.name
            const fileFormated = {
                ...file,
                date: file.date || date,
                name: getFormatedFileName(stationCode, file.active, getFileName(fileName), getFileExtension(file.name), fileTypeLabel, file.date || date, stationTypes[0], file.shared),
                stationType: getStationTypeCodeFromType(stationTypes[0]),
            }
            if (file.content.includes('data:image/')) {
                if (!replace && pictures.find(({ name }) => name === fileFormated.name)) {
                    this.setState({ openPopup: false, openPopupExistFile: true })
                    this.props.warning(i18n.nameAlreadyExist)
                } else {
                    this.setState({ picturesLoaded: false })
                    this.props.addStationFile(fileFormated, 'picture').then(() => {
                        this.loadPictures(station.code)
                        this.setState({ file: undefined })
                    })
                    this.onClosePopupAddFile()
                }
            } else if (!replace && files.find(({ name }) => name === fileFormated.name)) {
                this.setState({ openPopup: false, openPopupExistFile: true })
                this.props.warning(i18n.nameAlreadyExist)
            } else {
                this.setState({ documentsLoaded: false })
                this.props.addStationFile(fileFormated, 'doc').then(() => {
                    this.loadDocuments(station.code)
                    this.setState({ file: undefined })
                })
                this.onClosePopupAddFile()
            }
        } else if (!file?.content) {
            this.onFileUpdate({
                ...file,
                newName: replace ? file.newName : getFormatedFileName(file.stationCode.toUpperCase(), file.active, newName, file.fileExtension, file.fileTypeName, file.newDate, stationTypes[0], file.shared),
            }, replace)
        }
    }

    getModalExistFile = () => {
        const { openPopupExistFile, newName, actionType } = this.state
        return (
            <Dialog
                fullWidth
                maxWidth='lg'
                open={openPopupExistFile}
            >
                <DialogTitleMUI style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    {i18n.nameAlreadyExist}
                    <Icon style={{ color: 'white' }} size='small' icon='close' onClick={this.onClosePopupAddFile} />
                </DialogTitleMUI>
                <DialogContentMUI>
                    <div className='row no-margin padding-1 padding-top-2'>
                        Un fichier avec un nom identique existe déjà. Voulez-vous remplacer l'ancien fichier ou renommer le fichier actuel ?
                    </div>
                    <Grid container justifyContent='space-around' alignItems='center' style={{ padding: '20 0' }}>
                        <Grid item xs={4}>
                            <PresentationCard
                                iconComponent={(<Icon icon='compare_arrows' style={{ fontSize: '42px', paddingLeft: 15 }} />)}
                                title={i18n.replace}
                                isFocused={actionType === ACTION_TYPE.REPLACE}
                                onClick={() => this.setState({ actionType: ACTION_TYPE.REPLACE })}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <PresentationCard
                                iconComponent={(<Icon icon='edit' style={{ fontSize: '42px', paddingLeft: 15 }} />)}
                                title={i18n.rename}
                                isFocused={actionType === ACTION_TYPE.RENAME}
                                onClick={() => this.setState({ actionType: ACTION_TYPE.RENAME }) }
                            />
                        </Grid>
                    </Grid>
                    <Input
                        containerClassName='padding-bottom-1'
                        col={6}
                        title={i18n.newName}
                        value={newName}
                        onChange={v => this.setState({ newName: v })}
                        disabled={actionType !== ACTION_TYPE.RENAME}
                    />
                </DialogContentMUI>
                <DialogActionsMUI>
                    <Button
                        onClick={() => {
                            if (actionType === ACTION_TYPE.RENAME) {
                                if (!newName) {
                                    this.props.warning(i18n.pleaseCompleteAllField)
                                } else {
                                    this.saveFile(false)
                                }
                            } else if (actionType === ACTION_TYPE.REPLACE) {
                                this.saveFile(true)
                            }
                        }}
                        variant='outlined'
                        color='primary'
                        disabled={!actionType}
                    >
                        {i18n.validate}
                    </Button>
                </DialogActionsMUI>
            </Dialog>
        )
    }

    onValidateFilter = (fileFilter) => {
        const { pictures, files } = this.props
        this.setState({
            pictures: this.getFilesFiltered(pictures, fileFilter, PICTURE),
            files: this.getFilesFiltered(files, fileFilter, DOCUMENT),
        })
    }

    getFilesFiltered = (files = [], fileFilter, fileType) => {
        if (!fileFilter) {
            return files
        }
        const { sandreCodes } = this.props
        const sandreCodesNatures = getSandreList(sandreCodes, getTypeSandreCode(fileType))
        const filterByName = fileFilter.searchValue ? files.filter(f => f.shortName?.toLowerCase().includes(fileFilter.searchValue.toLowerCase())) : files
        const filterByNatures = fileFilter.natures?.length ? filterByName.filter(f =>
            fileFilter.natures.includes(`${getFileNatureLabel(f.name, sandreCodesNatures)}-${fileType}`)) : filterByName
        const filterByActive = fileFilter.active ? filterByNatures.filter(f => f.active?.includes(fileFilter.active)) : filterByNatures
        const filterByYear = fileFilter.year ? filterByActive.filter(f => getYear(f.date).toString()?.includes(fileFilter.year)) : filterByActive
        const filterByExtension = (fileFilter.extension && fileFilter.extension.length) ? filterByYear.filter(f => fileFilter.extension.includes(getFileExtension(f.name))) : filterByYear
        return filterByExtension
    }

    getModalAddFile = () => {
        const { file, openPopup } = this.state
        const { sandreCodes } = this.props
        const { sandreCode, title } = getFileType(file?.content)
        const sandreCodeNature = getSandreList(sandreCodes, sandreCode)
        const { associatedSites } = this.props
        return (
            <Dialog
                fullWidth
                maxWidth='lg'
                open={openPopup}
            >
                <DialogTitleMUI style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    {i18n.importFile}
                    <Icon style={{ color: 'white' }} size='small' icon='close' onClick={this.onClosePopupAddFile} />
                </DialogTitleMUI>
                <DialogContentMUI>
                    <div className='row no-margin padding-1 padding-top-2'>
                        <div className='col s12 file-field input-field padding-bottom-2'>
                            <div className='btn'>
                                <span>{i18n.importLabel}</span>
                                <input
                                    accept='*'
                                    id='uploadFile'
                                    type='file'
                                    multiple
                                    onChange={this.onUploadFile}
                                />
                            </div>
                            <div className='file-path-wrapper'>
                                <input className='file-path validate' type='text' />
                            </div>
                        </div>
                        {file &&
                            <>
                                <Select
                                    noNullValue
                                    className='padding-bottom-2'
                                    col={12}
                                    noSort={true}
                                    options={orderBy(sandreCodeNature, 'code')}
                                    label={title}
                                    value={file.fileType}
                                    nullLabel='&nbsp;'
                                    onChange={v => this.setState({ file: { ...file, fileType: v } })}
                                    keyLabel='name'
                                />
                                <SimpleDatePicker
                                    col={3}
                                    label={i18n.date}
                                    value={file.date}
                                    onChange={v => this.setState({ file: { ...file, date: v } })}
                                    calendarPosition='right-end'
                                />
                                <Checkbox
                                    col={2}
                                    componentClassName='padding-1'
                                    onChange={v => this.setState({ file: { ...file, active: v ? 1 : 0 } })}
                                    checked={file.active}
                                    label={i18n.active}
                                />
                                <Grid container>
                                    <Grid item xs={8}>
                                        <Table
                                            title={i18n.sharedWithOtherStations}
                                            data={associatedSites.filter(as => as.code === as.stationLinkedCode).map(d => ({
                                                ...d,
                                                stationType: <img src={getSiteUrl(STATION_NAME_ASSOCIATION[d.typeName])}
                                                    style={{ maxHeight: '30px' }}
                                                />,
                                                name: d.stationLinkedName,
                                            }))}
                                            condensed sortable
                                            type={{ headers: ['stationType', 'code', 'name'] }}
                                            col={12}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Checkbox
                                            col={12}
                                            componentClassName='padding-1'
                                            onChange={v => this.setState({ file: { ...file, shared: v } })}
                                            checked={file.shared}
                                            label={i18n.sharedWithOtherStations}
                                        />
                                    </Grid>
                                </Grid>
                            </>
                        }
                    </div>
                </DialogContentMUI>
                <DialogActionsMUI>
                    <Button onClick={() => this.saveFile(false)} variant='contained' color='primary'>
                        {i18n.validate}
                    </Button>
                </DialogActionsMUI>
            </Dialog>
        )
    }

    render() {
        const { isEditMode, picturesLoaded, documentsLoaded,
            files, pictures, openPopup, tabGroupBy, openPopupExistFile } = this.state
        const { sandreCodes, stationTypes } = this.props
        const station = getStation(this.props, this.props.stationTypes[0])
        const years = orderBy(uniqBy([...this.props.files, ...this.props.pictures].map(f => {
            const year = getYear(f.date)
            return { name: isNaN(year) ? i18n.unknownDate : year.toString(), code: year.toString() }
        }), 'code'), y => parseInt(y.code), 'desc')
        const extensions = uniqBy([...this.props.files, ...this.props.pictures].map(f => {
            return { name: getFileExtension(f.name), code: getFileExtension(f.name) }
        }), 'code')
        const data = [...pictures, ...files].map(fp => {
            return { ...fp, type: getFileNatureLabel(fp.name, getSandreList(sandreCodes, getTypeSandreCode(fp.fileType))) }
        })
        return (
            <div className='row no-margin padding-left-2 padding-top-1 padding-right-1'>
                <div className='padding-bottom-1'>
                    <FileFilter
                        years={years}
                        extensions={extensions}
                        onValidateFilter={fileFilter => this.onValidateFilter(fileFilter)}
                    />
                </div>
                <div className='col m12 s12' style={{ padding: '0px 4px 0px 0px' }}>
                    <TabList
                        onChangeTab={(v) => this.setState({ tabGroupBy: v })}
                        tabs={[
                            {
                                value: 'date',
                                label: i18n.date,
                                icon: 'insert_invitation',
                            },
                            {
                                value: 'file',
                                label: i18n.files,
                                icon: 'insert_drive_file',
                            },
                            {
                                value: 'active',
                                label: i18n.active,
                                icon: 'remove_red_eye',
                            },
                        ]}
                    >
                        {(documentsLoaded && picturesLoaded) ? (
                            <>
                                {data.length ?
                                    <PictureStationPanel
                                        onGroupBy={tabGroupBy}
                                        files={data}
                                        editable={isEditMode}
                                        deletePicture={this.onFileDelete}
                                        updateFile={this.onFileUpdate}
                                        stationTypes={stationTypes}
                                    />
                                    :
                                    <div style={{ padding: '5%' }}>
                                        <div className='col s12 text-align-center'>
                                            <Icon size='Medium' style={{ color: 'black' }} icon='insert_drive_file' />
                                        </div>
                                        <div className='center font-size-20'>{i18n.noResults}</div>
                                    </div>
                                }
                            </>
                        ) : <>
                            {station.code && (
                                <div className='padding-top-2 padding-left-2 padding-right-2 padding-bottom-2'>
                                    <ProgressBar indeterminate withMessage />
                                </div>
                            ) || (
                                <div style={{ padding: '5%' }}>
                                    <div className='col s12 text-align-center'>
                                        <Icon
                                            size='Medium'
                                            style={{ color: 'black' }}
                                            icon='do_not_disturb'
                                            tooltip={i18n.defineStationCode}
                                        />
                                    </div>
                                    <div className='center font-size-20'>{i18n.noStationCode}</div>
                                </div>
                            )}
                        </>
                        }
                    </TabList>
                </div>
                {openPopup && this.getModalAddFile()}
                {openPopupExistFile && this.getModalExistFile()}
            </div>
        )
    }
}

PictureStationApp.propTypes = {
    user: PropTypes.instanceOf(UserDto),
    id: PropTypes.string.isRequired,
    sandreCodes: PropTypes.arrayOf(DtoSandreCode),
    stationTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
    qualitometer: PropTypes.instanceOf(DtoStation),
    piezometer: PropTypes.instanceOf(DtoPiezometer),
    pluviometer: PropTypes.instanceOf(PluviometerDto),
    hydrometricStation: PropTypes.instanceOf(DtoHydrometricStation),
    distributionUnit: PropTypes.instanceOf(DtoProductionUnit),
    productionUnit: PropTypes.instanceOf(DtoProductionUnit),
    files: PropTypes.arrayOf(PropTypes.instanceOf(DtoFile)),
    pictures: PropTypes.arrayOf(PropTypes.instanceOf(DtoPicture)),
    push: PropTypes.func,
    installationTable: arrayOf(DtoInstallationTableLine),
    resources: PropTypes.arrayOf(PropTypes.instanceOf(ResourceDto)),
    resource: PropTypes.instanceOf(ResourceDto),
    associatedSites: arrayOf(DtoAssociatedStation),
    typeEnvironmentModels: PropTypes.arrayOf(PropTypes.string),
}

const mapStateToProps = store => ({
    user: store.UserReducer.user,
    sandreCodes: store.ReferencialReducer.sandreCodes,
    productionUnit: store.ProductionUnitReducer.productionUnit,
    distributionUnit: store.DistributionUnitReducer.distributionUnit,
    installation: store.InstallationReducer.installation,
    qualitometer: store.QualityReducer.qualitometer,
    piezometer: store.StationReducer.piezometer,
    pluviometer: store.PluviometryReducer.pluviometer,
    hydrometricStation: store.HydrometryReducer.hydrometricStation,
    pictures: store.StationReducer.pictures,
    files: store.StationReducer.files,
    pluviometers: store.PluviometryReducer.pluviometers,
    piezometers: store.PiezometryReducer.piezometersLight,
    qualitometers: store.QualityReducer.qualitometersLight,
    productionUnits: store.ProductionUnitReducer.productionUnits,
    distributionUnits: store.DistributionUnitReducer.distributionUnits,
    installations: store.InstallationReducer.installations,
    hydrometricStations: store.HydrometryReducer.hydrometricStations,
    stationWatermass: store.StationReducer.stationWatermass,
    installationTable: store.InstallationReducer.installationTable,
    resources: store.ResourceReducer.resources,
    resource: store.ResourceReducer.resource,
    associatedSites: store.StationReducer.associatedSites,
    typeEnvironmentModels: store.ExportReducer.typeEnvironmentModels,
})

const mapDispatchToProps = {
    forceFetch: SieauAction.forceFetch,
    fetchPictures: StationAction.fetchPictures,
    fetchFiles: StationAction.fetchFiles,
    addStationFile: StationAction.addStationFile,
    deleteFile: StationAction.deleteFile,
    updateFile: StationAction.updateFile,
    toastrInfo: ToastrAction.info,
    push,
    warning: ToastrAction.warning,
    fetchAssociatedSites: StationAction.fetchAssociatedSites,
}

export default connect(mapStateToProps, mapDispatchToProps)(PictureStationApp)
