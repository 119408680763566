import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import SimpleDatePicker from '../../components/forms/SimpleDatePicker'
import DtoStation from '../dto/DtoStation'
import DtoParametrageDataType from '../../piezometry/dto/DtoParametrageDataType'
import Select from '../../components/forms/Select'
import { getMeasureCoteList } from '../../utils/JobUtils'
import RadioButtons from '../../components/forms/RadioButtons'
import {
    getOptionCumul,
    getOptionCumulPerso,
    getQualificationSelectOptions,
    getStatusSelectOptions,
} from '../../utils/StatusUtil'
import {
    PERSONALIZED_GROUPING,
} from '../../utils/constants/MeasureConstants'
import { isNil } from 'lodash'
import NumberField from '../../components/forms/NumberField'
import { STATION_TYPE_NAME } from '../constants/StationConstants'
import { Grid2 } from '@mui/material'
import Checkbox from '../../components/forms/Checkbox'
import Input from '../../components/forms/Input'
import { ButtonMUI } from '../../components/styled/Buttons'
import MultiContributorsAutocomplete
    from '../../referencial/components/contributor/components/MultiContributorsAutocomplete'
import ThresholdSelect from '../../components/forms/specific/ThresholdSelect'
import { SELECTION, THRESHOLD } from '../../quality/constants/QualityConstants'
import SelectionSelect from '../../components/forms/specific/SelectionSelect'
import SuperMultiAutocomplete from '../../components/forms/SuperMultiAutocomplete'
import { useDispatch, useSelector } from 'react-redux'
import ContributorAction from '../../referencial/components/contributor/actions/ContributorAction'
import CampaignAction from '../../campaign/actions/CampaignAction'

const StationsExcelExportPanel = ({
    onChange = () => {},
    onChangeFilter = () => {},
    onApplyExport = () => {},
    selectedStations = [],
    tmpFilter = {},
    dataTypes = [],
    selectedDataType = 0,
    displayCote,
    stationType = '',
    producers = [],
    threshold = undefined,
    selectionCode = undefined,
    campaign = undefined,
}) => {
    const [isPersonalizedGrouping, setIsPersonalizedGrouping] = useState(false)
    const [optionCumulSelected, setOptionCumulSelected] = useState(undefined)
    const [nbDays, setNbDays] = useState(undefined)

    const dispatch = useDispatch()

    const { producersList, qualityCampaigns } = useSelector(store => ({
        producersList: store.ContributorReducer.producers,
        qualityCampaigns: store.QualityReducer.qualityCampaigns,
    }))

    useEffect(() => {
        dispatch(ContributorAction.fetchProducers())
        dispatch(CampaignAction.fetchQualityCampaigns())
    }, [])

    const onChangeFilterGroup = (v) => {
        setOptionCumulSelected(undefined)
        setNbDays(undefined)
        setIsPersonalizedGrouping(v === PERSONALIZED_GROUPING)
        onChangeFilter({ group: v })
    }

    const updateFilter = (group, days) => {
        setOptionCumulSelected(group)
        setNbDays(days)
        if (!isNil(group) && !isNil(days)) {
            onChangeFilter({ group: `${group}_${days * 24}` }) // back prend des heures donc x24 pour 1j
        }
    }

    const optionsCumul = useMemo(getOptionCumul, [])
    const optionsCumulPerso = useMemo(getOptionCumulPerso, [])

    return (
        <fieldset>
            <legend className='italic-label padding-right-1 padding-left-1'>{ i18n.exportCriterias }</legend>
            <Grid2 container spacing={1} alignItems='center'>
                <Grid2 size={3}>
                    <Select
                        label={ i18n.dataTypes }
                        options={ dataTypes }
                        onChange={ v => onChange({ dataType: v }) }
                        value={ selectedDataType }
                        keyValue='id'
                        keyLabel='label'
                        nullLabel={ i18n.allData }
                    />
                </Grid2>
                <Grid2 size={2}>
                    <Input
                        title={ i18n.defineDirectory }
                        value={ tmpFilter.exportDir }
                        onChange={ v => onChangeFilter({ exportDir: v }) }
                    />
                </Grid2>
                <Grid2 size={2}>
                    <SimpleDatePicker
                        value={ tmpFilter.startDate }
                        label={ i18n.startDate }
                        onChange={ v => onChangeFilter({ startDate: v }) }
                    />
                </Grid2>
                <Grid2 size={2}>
                    <SimpleDatePicker
                        value={ tmpFilter.endDate }
                        label={ i18n.endDate }
                        onChange={ v => onChangeFilter({ endDate: v }) }
                    />
                </Grid2>
                <Grid2 size={3}>
                    <ButtonMUI
                        style={{ width: '100%' }}
                        onClick={ () => onChange({ selectStationIsOpen: true }) }
                    >{ `${i18n.selectStations} (${selectedStations.length})` }</ButtonMUI>
                </Grid2>
                {
                    stationType === STATION_TYPE_NAME.piezometry && (
                        <Grid2 size={2}>
                            <RadioButtons
                                elements={ getMeasureCoteList() }
                                selected={ displayCote }
                                onChange={ v => onChange({ displayCote: v }) }
                                title={ i18n.ratingExpression }
                                disabled={ selectedDataType !== -1 }
                            />
                        </Grid2>

                    )
                }
                {
                    ([STATION_TYPE_NAME.piezometry, STATION_TYPE_NAME.hydrometry, STATION_TYPE_NAME.pluviometry].includes(stationType)) && (
                        <Grid2 size={2}>
                            <Select
                                label={ i18n.modeGrouping }
                                options={ optionsCumul }
                                value={ tmpFilter?.group?.startsWith('CUMUL') ? PERSONALIZED_GROUPING : tmpFilter.group }
                                onChange={ v => onChangeFilterGroup(v) }
                            />
                        </Grid2>
                    )
                }
                {
                    isPersonalizedGrouping && (
                        <>
                            <Grid2 size={2}>
                                <Select
                                    label={ i18n.regrouping }
                                    options={ optionsCumulPerso }
                                    value={ optionCumulSelected }
                                    onChange={ v => updateFilter(v, nbDays) }
                                    obligatory
                                />
                            </Grid2>
                            <Grid2 size={1}>
                                <NumberField
                                    min={ 1 }
                                    title={ i18n.nbDays }
                                    value={ nbDays }
                                    onChange={ v => updateFilter(optionCumulSelected, v) }
                                    obligatory
                                />
                            </Grid2>
                        </>
                    )
                }
                {
                    ([STATION_TYPE_NAME.piezometry, STATION_TYPE_NAME.hydrometry, STATION_TYPE_NAME.pluviometry].includes(stationType)) && (
                        <>
                            <Grid2 size={2}>
                                <Select
                                    label={ i18n.status }
                                    options={ getStatusSelectOptions() }
                                    value={ tmpFilter.status }
                                    onChange={ v => onChangeFilter({ status: isNil(v) ? undefined : [v] }) }
                                />
                            </Grid2>
                            <Grid2 size={2}>
                                <Select
                                    label={ i18n.qualification }
                                    options={ getQualificationSelectOptions() }
                                    value={ tmpFilter.qualifications }
                                    onChange={ v => onChangeFilter({ qualifications: isNil(v) ? undefined : [v] }) }
                                />
                            </Grid2>
                            <Grid2 size={2}>
                                <Checkbox checked={ tmpFilter.withStatusQualificationCodes }
                                    onChange={ v => onChangeFilter({ withStatusQualificationCodes: v }) }
                                    label='Exporter les codes statut/Qualification'
                                />
                            </Grid2>
                        </>
                    )
                }
                { stationType !== STATION_TYPE_NAME.quality ? undefined : (
                    <>
                        <Grid2 size={3}>
                            <MultiContributorsAutocomplete
                                label={i18n.producer}
                                options={producersList}
                                onChange={v => onChange({ producers: v })}
                                values={producers}
                                multiple
                            />
                        </Grid2>
                        <Grid2 size={2}>
                            <ThresholdSelect
                                selected={threshold}
                                onChange={v => onChange({ threshold: v })}
                                thresholdType={THRESHOLD.PC}
                            />
                        </Grid2>
                        <Grid2 size={2}>
                            <SelectionSelect
                                value={selectionCode}
                                onChange={(res, s) => {
                                    onChange({ selectionCode: s, parameters: res })
                                }}
                                type={SELECTION.PC}
                            />
                        </Grid2>
                        <Grid2 size={2}>
                            <SuperMultiAutocomplete
                                label={i18n.campaign}
                                options={qualityCampaigns}
                                values={campaign}
                                onChange={v => {
                                    onChange({ campaign: v })}
                                }
                            />
                        </Grid2>
                    </>
                ) }
                <Grid2 size={stationType === STATION_TYPE_NAME.quality ? 3 : 2}>
                    <ButtonMUI
                        fullWidth
                        onClick={ onApplyExport }
                        disabled={ isPersonalizedGrouping ? isNil(optionCumulSelected) || isNil(nbDays) : false }
                    >{ i18n.apply }</ButtonMUI>
                </Grid2>
            </Grid2>
        </fieldset>
    )
}


StationsExcelExportPanel.propTypes = {
    onChange: PropTypes.func,
    onChangeFilter: PropTypes.func,
    selectedStations: PropTypes.arrayOf(PropTypes.instanceOf(DtoStation)),
    tmpFilter: PropTypes.shape({
        startDate: PropTypes.number,
        endDate: PropTypes.number,
    }),
    onApplyExport: PropTypes.func,
    dataTypes: PropTypes.arrayOf(PropTypes.instanceOf(DtoParametrageDataType)),
    selectedDataType: PropTypes.number,
    displayCote: PropTypes.number,
    stationType: PropTypes.string,
    producers: PropTypes.arrayOf(PropTypes.number),
    threshold: PropTypes.number,
    selectionCode: PropTypes.string,
    campaign: PropTypes.number,
}

export default StationsExcelExportPanel
