import { createAsyncThunk } from '@reduxjs/toolkit'
import { H_CATCHMENT_MODULE } from 'account/constants/AccessRulesConstants'
import ApplicationConf from 'conf/ApplicationConf'
import { chunk, flatten } from 'lodash'
import LogAction from 'log/actions/LogAction'
import i18n from 'simple-react-i18n'
import { IDLE, REJECTED } from 'store/DataManagerConstants'
import ToastrAction from 'toastr/actions/ToastrAction'
import { genericPromise2 } from 'utils/ActionUtils'
import { isAuthorized } from 'utils/HabilitationUtil'

const CatchmentThunk = {
    fetchCatchments: createAsyncThunk(
        'fetchCatchments',
        async (_, { rejectWithValue, dispatch, signal }) => {
            try {
                const response = await genericPromise2(ApplicationConf.catchment.catchments(), { signal })
                return response
            } catch (err) {
                dispatch(LogAction.logError(`${i18n.fetchError} ${i18n.catchments} : ${err}`))
                dispatch(ToastrAction.error(`${i18n.fetchError} ${i18n.catchments}`))
                return rejectWithValue(err)
            }
        },
        {
            condition: (arg = { forceFetch: false }, { getState }) => {
                const state = getState()
                if (!isAuthorized(H_CATCHMENT_MODULE, state)) {
                    return false
                }
                const { catchmentsStatus } = state.DataManagerReducer.catchment
                if (arg.forceFetch) {
                    return catchmentsStatus !== REJECTED
                }
                return catchmentsStatus === IDLE
            },
            dispatchConditionRejection: true,
        },
    ),
    fetchCatchmentPoints: createAsyncThunk(
        'fetchCatchmentPoints',
        async (_, { rejectWithValue, dispatch }) => {
            try {
                const response = await genericPromise2(ApplicationConf.catchment.points(), { method: 'GET' })
                return response
            } catch (err) {
                dispatch(LogAction.logError(`${i18n.fetchError} ${i18n.catchmentPoints} : ${err}`))
                dispatch(ToastrAction.error(`${i18n.fetchError} ${i18n.catchmentPoints}`))
                return rejectWithValue(err)
            }
        },
        {
            condition: (arg = { forceFetch: false }, { getState }) => {
                const state = getState()
                if (!isAuthorized(H_CATCHMENT_MODULE, state)) {
                    return false
                }
                const { catchmentPointsStatus } = state.DataManagerReducer.catchment
                if (arg.forceFetch) {
                    return catchmentPointsStatus !== REJECTED
                }
                return catchmentPointsStatus === IDLE
            },
            dispatchConditionRejection: true,
        },
    ),

    fetchCatchmentsAnalysis: createAsyncThunk(
        'fetchCatchmentsAnalysis',
        async ({ filter = {}, onProgress = () => {}, signal }, { rejectWithValue, fulfillWithValue, dispatch }) => {
            try {
                const {
                    stations,
                    ...searchFilter
                } = filter

                if (!stations.length) {
                    return fulfillWithValue([])
                }
                const groupSize = Math.min(Math.floor(stations.length / 10), 10) || 1
                const chunkGroup = chunk(stations, groupSize)
                const nbGroup = chunkGroup.length

                const promises = chunkGroup.map(stationsGroup => genericPromise2(ApplicationConf.analysis.default(), { method: 'POST', body: { ...searchFilter, stations: stationsGroup }, signal })
                    .catch(() => [])
                    .finally(() => onProgress(100 / nbGroup))
                )

                const response = await Promise.all(promises)
                return flatten(response)
            } catch (err) {
                dispatch(LogAction.logError(`${i18n.fetchError} ${i18n.analysis} : ${err}`))
                dispatch(ToastrAction.error(`${i18n.fetchError} ${i18n.analysis}`))
                return rejectWithValue(err)
            }
        },
        {
            condition: (arg = { forceFetch: false }, { getState }) => {
                const state = getState()
                const { catchmentsAnalysisStatus } = state.DataManagerReducer.catchment
                if (arg.forceFetch) {
                    return catchmentsAnalysisStatus !== REJECTED
                }
                return catchmentsAnalysisStatus === IDLE
            },
        },
    ),
}

export default CatchmentThunk