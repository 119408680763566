/* eslint-disable camelcase */
import React from 'react'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import Input from '../../../../components/forms/Input'
import { connect } from 'react-redux'
import { arrayOf } from '../../../../utils/StoreUtils'
import CityDto from '../../../../referencial/components/city/dto/CityDto'
import DtoSandreCode from '../../../../referencial/dto/DtoSandreCode'
import SieauAction from '../../../../components/sieau/SieauAction'
import InstallationAction from '../../../actions/InstallationAction'
import NumberField from '../../../../components/forms/NumberField'
import SieauParameterDto from '../../../../administration/dto/SieauParameterDto'
import DtoInstallation from '../../../dto/installation/DtoInstallation'
import Checkbox from '../../../../components/forms/Checkbox'
import DtoInstallationCasing from '../../../dto/installation/DtoInstallationCasing'
import DtoManagementUnit from '../../../../referencial/components/managementUnit/dto/DtoManagementUnit'
import SimpleDatePicker from '../../../../components/forms/SimpleDatePicker'
import Textarea from 'components/forms/Textarea'
import { Grid } from '@mui/material'

const InstallationOuvragePanel = ({
    onChange = () => { },
    station = {},
    disabled = false,
    readMode = false,
    work,
    surveyMode,
}) => {
    const ouvrage = work || (station.link_sampleCasing && station.link_sampleCasing.length ? station.link_sampleCasing[0] : new DtoInstallationCasing({ idInstallation: station.id }))

    const onChangeCasing = (value) => {
        if (work) {
            onChange({
                ...ouvrage,
                ...value,
            })
        } else {
            onChange({
                link_sampleCasing: [{
                    ...ouvrage,
                    ...value,
                }],
            })
        }
    }

    return (
        <Grid
            container
            className='padding-top-1 padding-bottom-1'
            spacing={1}
        >
            <Grid item xs={12} md={6}>
                <SimpleDatePicker
                    col={12}
                    id='completionDate'
                    label={i18n.completionDate}
                    value={ouvrage.completionDate}
                    onChange={(v) => onChangeCasing({ completionDate: v })}
                    disabled={surveyMode || disabled}
                    readMode={readMode}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <SimpleDatePicker
                    col={12}
                    id='declarationDate'
                    label={i18n.declarationDate}
                    value={ouvrage.declarationDate}
                    onChange={(v) => onChangeCasing({ declarationDate: v })}
                    disabled={surveyMode || disabled}
                    readMode={readMode}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <SimpleDatePicker
                    col={12}
                    id='receiptDate'
                    label={i18n.receiptDate}
                    value={ouvrage.receiptDate}
                    onChange={(v) => onChangeCasing({ receiptDate: v })}
                    disabled={surveyMode || disabled}
                    readMode={readMode}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <Input
                    col={12}
                    value={ouvrage.receiptNumber}
                    title={i18n.receiptNumber}
                    onChange={v => onChangeCasing({ receiptNumber: v })}
                    disabled={surveyMode || disabled}
                    readMode={readMode}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <NumberField
                    col={12}
                    title={i18n.depth}
                    value={ouvrage.depth}
                    min={0}
                    onChange={v => onChangeCasing({ depth: v })}
                    disabled={surveyMode || disabled}
                    readMode={readMode}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <NumberField
                    col={12}
                    title={i18n.copingHeight}
                    value={ouvrage.copingHeight}
                    min={0}
                    onChange={v => onChangeCasing({ copingHeight: v })}
                    disabled={disabled}
                    readMode={readMode}
                    floatValue
                />
            </Grid>
            <Grid item xs={3} md={6}>
                <Checkbox
                    col={12}
                    checked={ouvrage.cementing}
                    label={i18n.cementing}
                    onChange={v => onChangeCasing({ cementing: v })}
                    disabled={readMode || disabled}
                />
            </Grid>
            <Grid item xs={3} md={6}>
                <Checkbox
                    col={12}
                    checked={ouvrage.concreteCoping}
                    label={i18n.concreteCoping}
                    onChange={v => onChangeCasing({ concreteCoping: v })}
                    disabled={readMode || disabled}
                />
            </Grid>
            <Grid item xs={3} md={6}>
                <Checkbox
                    col={12}
                    checked={ouvrage.protectionHead}
                    label={i18n.protectionHead}
                    onChange={v => onChangeCasing({ protectionHead: v })}
                    disabled={readMode || disabled}
                />
            </Grid>
            <Grid item xs={3} md={6}>
                <Checkbox
                    col={12}
                    checked={ouvrage.modernizedPerimeterASP}
                    label={i18n.modernizedPerimeterASP}
                    onChange={v => onChangeCasing({ modernizedPerimeterASP: v })}
                    disabled={readMode || disabled}
                />
            </Grid>
            <Grid item xs={3} md={6}>
                <Checkbox
                    col={12}
                    checked={ouvrage.specificPrescriptions}
                    label={i18n.specificPrescriptions}
                    onChange={v => onChangeCasing({ specificPrescriptions: v })}
                    disabled={readMode || disabled}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <NumberField
                    col={12}
                    title={i18n.reserveFlow}
                    value={ouvrage.reserveFlow}
                    min={0}
                    onChange={v => onChangeCasing({ reserveFlow: v })}
                    disabled={surveyMode || disabled}
                    readMode={readMode}
                    floatValue
                />
            </Grid>
            <Grid item xs={12} md={12}>
                <Textarea
                    col={12}
                    title={i18n.comment}
                    value={ouvrage.comment}
                    onChange={value => onChangeCasing({ comment: value })}
                    disabled={disabled}
                    readMode={readMode}
                />
            </Grid>
        </Grid>
    )
}

InstallationOuvragePanel.propTypes = {
    station: PropTypes.instanceOf(DtoInstallation),
    work: PropTypes.instanceOf({}),
    cities: arrayOf(CityDto),
    sandreCodes: arrayOf(DtoSandreCode),
    disabled: PropTypes.bool,
    readMode: PropTypes.bool,
    surveyMode: PropTypes.bool,
    onChange: PropTypes.func,
    onChangeVisit: PropTypes.func,
    setPopup: PropTypes.func,
    changeBssCode: PropTypes.func,
    managementUnits: arrayOf(DtoManagementUnit),
    applicationSettings: arrayOf(SieauParameterDto),
}

const mapStateToProps = store => ({
    cities: store.CityReducer.cities,
    sandreCodes: store.ReferencialReducer.sandreCodes,
    managementUnits: store.ManagementUnitReducer.managementUnits,
    applicationSettings: store.AdministrationReducer.applicationSettings,
})

const mapDispatchTopProps = {
    setPopup: SieauAction.setPopup,
    changeBssCode: InstallationAction.changeBssCode,
}

export default connect(mapStateToProps, mapDispatchTopProps)(InstallationOuvragePanel)
