import React, { useState } from 'react'
import i18n from 'simple-react-i18n'
import PropTypes from 'prop-types'
import { Button, Grid } from '@mui/material'
import CampaignAction from 'campaign/actions/CampaignAction'
import SimpleDatePicker from 'components/forms/SimpleDatePicker'
import { WhiteCard } from 'components/styled/Card'
import { StyledFieldSet, StyledLegend } from 'components/StyledElements'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { SORT_BY } from 'station/constants/CampaignConstants'
import { CAMPAIGN_STATUT_FOR_FILTER } from '../../../../campaign/constants/CampaignConstants'
import Input from '../../../../components/forms/Input'
import Select from '../../../../components/forms/Select'
import { SANDRE } from '../../../../referencial/constants/ReferencialConstants'
import { getDate } from '../../../../utils/DateUtil'
import { getSandreList } from '../../../../utils/StoreUtils'
import { i18nize } from '../../../../utils/StringUtil'

const FormInstallationFilterCampaigns = ({
    sortBy,
}) => {
    const dispatch = useDispatch()

    const {
        sandreCodes,
        cities,
        installationsTypes,
    } = useSelector(store => ({
        sandreCodes: store.ReferencialReducer.sandreCodes,
        cities: store.CityReducer.cities,
        installationsTypes: store.InstallationReducer.installationsTypes,
    }), shallowEqual)

    const [search, setSearch] = useState('')
    const [startDate, setStartDate] = useState()
    const [endDate, setEndDate] = useState()
    const [campaignType, setCampaignType] = useState()
    const [campaignStatus, setCampaignStatus] = useState()
    const [installationType, setInstallationType] = useState()
    const [city, setCity] = useState('')
    const [category, setCategory] = useState()
    const [deadline, setDeadline] = useState()
    const [interventionType, setInterventionType] = useState()
    const [responsible, setResponsible] = useState()
    const [actionsStatus, setActionsStatus] = useState(0)

    const onValidate = () => {
        const url = [
            search && `search=${search}`,
            startDate && `startDate=${getDate(startDate)}`,
            endDate && `endDate=${getDate(endDate)}`,
            campaignType && `campaignType=${campaignType}`,
            campaignStatus && `campaignStatus=${campaignStatus}`,
            installationType && `installationType=${installationType}`,
            city && `city=${city}`,
            category && `category=${category}`,
            deadline && `deadline=${deadline}`,
            interventionType && `interventionType=${interventionType}`,
            responsible && `responsible=${responsible}`,
            actionsStatus && `actionsStatus=${actionsStatus}`,
        ].filter(d => !!d)
        const urlJoined = `?${url.join('&')}`

        dispatch(CampaignAction.fecthCampaignsActions(urlJoined))
        dispatch(CampaignAction.fetchCampaignsInstallationWithStats(urlJoined))
    }

    const onReset = () => {
        setSearch()
        setStartDate()
        setEndDate()
        setCampaignType()
        setCampaignStatus()
        setInstallationType()
        setCity()
        setCategory()
        setDeadline()
        setInterventionType()
        setResponsible()
        setActionsStatus()
    }

    return (
        <WhiteCard>
            <Grid style={{ padding: '5 10 10' }}>
                <StyledFieldSet style={{ marginTop: 5, padding: '10 20' }}>
                    <StyledLegend style={{ marginLeft: -10 }}>{i18n.campaigns}</StyledLegend>
                    <Grid container spacing={2}>
                        <Grid item xs={3}>
                            <Input
                                title={i18n.search}
                                value={search}
                                onChange={setSearch}
                                onEnter
                                onEnterKeyPress={onValidate}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <SimpleDatePicker
                                col={12}
                                value={startDate}
                                id='startDate'
                                label={i18n.startDate}
                                onChange={setStartDate}
                                style={{ padding: 0 }}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <SimpleDatePicker
                                col={12}
                                value={endDate}
                                id='startDate'
                                label={i18n.endDate}
                                onChange={setEndDate}
                                style={{ padding: 0 }}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <Select
                                label={i18n.status}
                                options={i18nize(CAMPAIGN_STATUT_FOR_FILTER)}
                                value={campaignStatus}
                                onChange={setCampaignStatus}
                                keyLabel='label'
                                nullLabel='&nbsp;'
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={3}>
                            <Select
                                className={'clear-left'}
                                label={i18n.campaign}
                                value={campaignType}
                                options={getSandreList(sandreCodes, SANDRE.CAMPAGNES_TYPE)}
                                onChange={setCampaignType}
                                keyLabel='name'
                                nullLabel='&nbsp;'
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Select
                                options={cities}
                                label={i18n.city}
                                displayWithCode
                                onChange={setCity}
                                value={city}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <Select
                                label={i18n.installationType}
                                value={installationType}
                                options={installationsTypes.filter(it => it.display)}
                                onChange={setInstallationType}
                                keyLabel='name'
                                nullLabel='&nbsp;'
                            />
                        </Grid>
                    </Grid>
                </StyledFieldSet>
                <StyledFieldSet style={{ marginTop: 10, padding: '10 20' }}>
                    <StyledLegend style={{ marginLeft: -10 }}>{i18n.actions}</StyledLegend>
                    <Grid container spacing={2}>
                        <Grid item xs={3}>
                            <Select
                                className={'clear-left'}
                                label={i18n.timeLimit}
                                value={deadline}
                                options={getSandreList(sandreCodes, SANDRE.VISITES_ACTIONS_ECHEANCE)}
                                onChange={setDeadline}
                                keyLabel='name'
                                nullLabel='&nbsp;'
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <Select
                                label={i18n.interventionType}
                                value={interventionType}
                                options={getSandreList(sandreCodes, SANDRE.VISITES_ACTIONS_TYPE_INTERVENTION)}
                                onChange={setInterventionType}
                                keyLabel='name'
                                nullLabel='&nbsp;'
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <Select
                                label={i18n.responsible}
                                value={responsible}
                                options={getSandreList(sandreCodes, SANDRE.VISITES_ACTIONS_RESPONSABILITE)}
                                onChange={setResponsible}
                                keyLabel='name'
                                nullLabel='&nbsp;'
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <Select
                                label={i18n.category}
                                value={category}
                                options={getSandreList(sandreCodes, SANDRE.VISITES_ACTIONS_CATEGORIE)}
                                onChange={setCategory}
                                keyLabel='name'
                                nullLabel='&nbsp;'
                            />
                        </Grid>
                        {
                            sortBy === SORT_BY.ACTIONS && <Grid item xs={3} className='no-padding-top'>
                                <Select
                                    label={i18n.actionsStatus}
                                    value={actionsStatus}
                                    options={getSandreList(sandreCodes, SANDRE.ACTIONS_STATUT)}
                                    onChange={setActionsStatus}
                                    noNullValue
                                />
                            </Grid>
                        }
                    </Grid>
                </StyledFieldSet>
                <Grid container item xs={12} justifyContent='flex-end' spacing={2} alignItems='center' style={{ marginTop: 0 }}>
                    <Grid item>
                        <Button onClick={onReset} variant='outlined'>
                            {i18n.reinit}
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button onClick={onValidate} variant='contained' color='primary'>
                            {i18n.search}
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </WhiteCard>
    )
}

FormInstallationFilterCampaigns.propTypes = {
    sortBy: PropTypes.number,
}

export default FormInstallationFilterCampaigns