import { Button, Dialog, DialogActions, DialogContent } from '@mui/material'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import ToastrAction from 'toastr/actions/ToastrAction'
import JobAction from '../../../../../import/actions/JobAction'
import DtoQualitometerLight from '../../../../../quality/dto/DtoQualitometerLight'
import ParameterDto from '../../../../../referencial/components/parameter/dto/ParameterDto'
import CampaignPlanningDto from '../../../../dto/CampaignPlanningDto'
import PopupEDILABO from './PopupEDILABO'
import PopupPlanningEDILABO from './PopupPlanningEDILABO'


class SteperPopupEDILABO extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isSelectOpen: props.isOpen,
            isPlanning: false,
            isPopinOpen: false,
        }
    }

    componentDidUpdate = prevProps => {
        if (this.props.isOpen !== prevProps.isOpen) {
            this.setState({ isSelectOpen: this.props.isOpen, isPlanning: false, isPopinOpen: false })
        }
    }

    onClose = () => {
        this.setState({ isSelectOpen: false, isPopinOpen: false })
        this.props.onClose()
    }

    render = () => {
        const {
            isOpen,
            campaign,
            selectedStations,
            stations,
            selectedParameters,
            plannings,
        } = this.props

        const {
            isSelectOpen,
            isPlanning,
            isPopinOpen,
        } = this.state

        return (
            <div>
                <Dialog
                    onClose={this.onClose}
                    fullWidth
                    maxWidth='md'
                    open={isSelectOpen}
                >
                    <DialogContent>
                        <div style={{ minHeight: '15vh' }}>
                            <h3 className='center'>{i18n.wouldYouLikeToMakeAPlannedEDILABORequest}</h3>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.setState({ isPlanning: false, isSelectOpen: false, isPopinOpen: true })} variant='outlined' >
                            {i18n.no}
                        </Button>
                        <Button
                            onClick={() => {
                                this.setState({ isPlanning: true, isSelectOpen: false, isPopinOpen: true })
                                this.props.setEditMode()
                            }}
                            variant='contained'
                            color='primary'
                        >
                            {i18n.yes}
                        </Button>
                    </DialogActions>
                </Dialog>
                {
                    isPopinOpen && isPlanning && (
                        <PopupPlanningEDILABO
                            isOpen={isOpen}
                            onClose={this.onClose}
                            campaign={campaign}
                            plannings={plannings}
                            selectedStations={selectedStations.map(({ id }) => id)}
                            stations={stations}
                            selectedParameters={selectedParameters}
                            onChange={this.props.onChange}
                        />
                    )
                }
                {
                    isPopinOpen && !isPlanning && (
                        <PopupEDILABO
                            isOpen={isOpen}
                            onClose={this.onClose}
                            campaign={campaign}
                            selectedStations={selectedStations.map(({ id }) => id)}
                            stations={stations}
                            selectedParameters={selectedParameters}
                        />
                    )
                }
            </div>
        )
    }
}

SteperPopupEDILABO.propTypes = {
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
    campaign: PropTypes.shape({
        id: PropTypes.number,
        recipientCode: PropTypes.string,
        contributorCode: PropTypes.string,
        samplerCode: PropTypes.string,
        requestType: PropTypes.string,
        parameterListCode: PropTypes.string,
    }),
    selectedStations: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
    })),
    stations: PropTypes.arrayOf(PropTypes.instanceOf(DtoQualitometerLight)),
    selectedParameters: PropTypes.arrayOf(PropTypes.shape({
        parameterCode: PropTypes.string,
        fractionCode: PropTypes.string,
        supportCode: PropTypes.string,
        unitCode: PropTypes.string,
        placeCode: PropTypes.string,
    })),
    parameters: PropTypes.arrayOf(PropTypes.instanceOf(ParameterDto)),
    plannings: PropTypes.arrayOf(PropTypes.instanceOf(CampaignPlanningDto)),
    onChange: PropTypes.func,
    setEditMode: PropTypes.func,

    toastrError: PropTypes.func,
    addJob: PropTypes.func,
    executeJob: PropTypes.func,
}

const mapStateToProps = store => ({
    parameters: store.ParameterReducer.parameters,
})

const mapDispatchToProps = {
    toastrError: ToastrAction.error,
    addJob: JobAction.addJob,
    executeJob: JobAction.executeJob,
}

export default connect(mapStateToProps, mapDispatchToProps)(SteperPopupEDILABO)
