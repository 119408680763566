import React, { useMemo, useState } from 'react'
import societe from 'assets/pictures/logos/societe.png'
import logoSandre from 'assets/pictures/logo_sandre.png'
import Button from 'components/forms/Button'
import { push } from '@lagunovsky/redux-react-router'
import moment from 'moment'
import { useEffect } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import MoveMapPanel from 'referencial/components/map/MoveMapPanel'
import i18n from 'simple-react-i18n'
import ToastrAction from 'toastr/actions/ToastrAction'
import useActions from 'utils/customHook/useActions'
import { canFetchLocation, getAddressFormatted } from 'utils/mapUtils/CoordinateUtils'
import { v4 as uuidv4 } from 'uuid'
import Card from '../../../../components/card/Card'
import Checkbox from '../../../../components/forms/Checkbox'
import Input from '../../../../components/forms/Input'
import NumberField from '../../../../components/forms/NumberField'
import Select from '../../../../components/forms/Select'
import Textarea from '../../../../components/forms/Textarea'
import Row from '../../../../components/react/Row'
import SieauAction from '../../../../components/sieau/SieauAction'
import ApplicationConf from '../../../../conf/ApplicationConf'
import { sandreContributorLink, societeUrl } from '../../../../conf/SieauConstants'
import HomeAction from '../../../../home/actions/HomeAction'
import {
    PATH_REFERENCIAL,
    PATH_REFERENCIAL_CONTRIBUTOR,
    PATH_REFERENCIAL_CONTRIBUTOR_LINK,
    PATH_REFERENCIAL_CONTRIBUTOR_NEW,
} from '../../../../home/constants/RouteConstants'
import { SANDRE } from '../../../../referencial/constants/ReferencialConstants'
import { hasValue } from '../../../../utils/NumberUtil'
import { getLogin, getUser } from '../../../../utils/SettingUtils'
import ReferencialAction from '../../../action/ReferencialAction'
import { getReferencialComponent } from '../../../util/ReferencialUtils'
import CityAction from '../../city/actions/CityAction'
import CountryAction from '../../country/actions/CountryAction'
import DeleteModal from '../../DeleteModal'
import ReplaceModal from '../../ReplaceModal'
import ContributorAction from '../actions/ContributorAction'
import ContributorDto from '../dto/ContributorDto'
import ReferencialCodificationPanel from './ReferencialCodificationPanel'
import useTitle from 'utils/customHook/useTitle'
import useSandreList from 'utils/customHook/useSandreList'
import SimpleDatePicker from 'components/forms/SimpleDatePicker'
import { ContributorActionConstant } from '../reducers/ContributorReducer'
import { useParams } from 'react-router'

const ContributorApp = () => {
    const { code } = useParams()

    const {
        contributorProps,
        contributorDomain,
        referencialStatus,
        cities,
        citiesHisto,
        contacts,
        contributors,
        sandreCodes,
        countries,
    } = useSelector(store => ({
        contributorProps: store.ContributorReducer.contributor,
        contributorDomain: store.ContributorReducer.contributorDomain,
        referencialStatus: store.ReferencialReducer.referencialStatus,
        cities: store.CityReducer.cities,
        citiesHisto: store.CityReducer.citiesHisto,
        contacts: store.ContactReducer.contacts,
        contributors: store.ContributorReducer.contributors,
        sandreCodes: store.ReferencialReducer.sandreCodes,
        countries: store.CountryReducer.countries,
    }), shallowEqual)

    const [contributor, setContributor] = useState(new ContributorDto({}))
    const [isEditMode, setIsEditMode] = useState(false)
    const [editMap, setEditMap] = useState(false)

    const dispatch = useDispatch()

    useEffect(() => () => dispatch(ContributorActionConstant.resetContributor()), [])

    useEffect(() => setContributor(contributorProps), [contributorProps])

    useEffect(() => {
        if (code !== 'new') {
            dispatch(ContributorAction.fetchContributor(code))
            setIsEditMode(false)
        } else {
            setIsEditMode(true)
        }

        dispatch(ContributorAction.fetchContributorDomain())
        dispatch(ReferencialAction.fetchReferencialStatus())
        dispatch(CityAction.fetchCityCedex())

        if (!cities.length) {
            dispatch(CityAction.fetchCities())
        }

        if (!citiesHisto.length) {
            dispatch(CityAction.fetchCitiesHisto())
        }

        dispatch(HomeAction.setHelpLink('', ''))

        if (!sandreCodes.length) {
            dispatch(ReferencialAction.fetchSandreCodes())
        }

        if (!countries.length) {
            dispatch(CountryAction.fetchCountries())
        }

        if (!contributors.length) {
            dispatch(ContributorAction.fetchContributors())
        }
    }, [code])

    useTitle(() => {
        if (code === 'new') {
            return [
                {
                    title: i18n.referencials,
                    href: PATH_REFERENCIAL,
                },
                {
                    title: i18n.contributors,
                    href: PATH_REFERENCIAL_CONTRIBUTOR,
                },
                {
                    title: i18n.new,
                    href: PATH_REFERENCIAL_CONTRIBUTOR_NEW,
                },
            ]
        }
        return [
            {
                title: i18n.referencials,
                href: PATH_REFERENCIAL,
            },
            {
                title: i18n.contributors,
                href: PATH_REFERENCIAL_CONTRIBUTOR,
            },
            {
                title: code + (contributorProps.name ? ` - ${contributorProps.name}` : ''),
                href: PATH_REFERENCIAL_CONTRIBUTOR_LINK + code,
            },
        ]
    }, [code, contributorProps])

    const getContributorLinks = () => {
        const links = [
            !!contributorProps.siret && {
                href: societeUrl + contributorProps.siret,
                img: societe,
                label: i18n.societe.toUpperCase(),
            },
            !!(contributorProps.sandre || contributorProps.siret) && {
                href: sandreContributorLink + (contributorProps.sandre ? contributorProps.sandre : contributorProps.siret),
                img: logoSandre,
                label: i18n.sandre.toUpperCase(),
            },
        ].filter(l => l)
        return links
    }

    const isConsultant = useMemo(() => getUser().consultant === '1', [])

    useActions(() => {
        if (isConsultant) {
            return {}
        }
        const links = getContributorLinks()
        const isNewActions = code === 'new' && (!contributorProps || !contributorProps.code)
        const actions = (() => {
            if (isNewActions) {
                return {
                    save: () => {
                        dispatch(ContributorAction.createContributor(contributor))
                        setIsEditMode(false)
                    },
                    cancel: () => {
                        dispatch(push(PATH_REFERENCIAL_CONTRIBUTOR))
                        setIsEditMode(false)
                    },
                }
            }
            if (isEditMode) {
                return {
                    save: () => {
                        dispatch(ContributorAction.updateContributor(contributor, contributor.code))
                        setIsEditMode(false)
                    },
                    cancel: () => {
                        setContributor(contributorProps)
                        setIsEditMode(false)
                    },
                }
            }
            return {
                edit: () => {
                    setIsEditMode(true)
                },
                deleteCheck: () => {
                    dispatch(ReferencialAction.fetchCheckDelete(ApplicationConf.referencial.contributorCountDelete(code)))
                    const id = uuidv4()
                    const popup = {
                        id,
                        content: <DeleteModal
                            onDelete={ () => dispatch(ContributorAction.deleteContributor(contributor.code)) }
                        />,
                        dismissible: false,
                    }
                    dispatch(SieauAction.setPopup(popup))
                },
                replace: () => {
                    dispatch(ReferencialAction.fetchCheckDelete(ApplicationConf.referencial.contributorCountDelete(code)))
                    const id = uuidv4()
                    const popup = {
                        id,
                        content: <ReplaceModal
                            elements={ contributors }
                            title={ `${i18n.contributor} [${contributor.code}]` }
                            label={ i18n.contributor }
                            onReplace={ newCode => dispatch(ContributorAction.replaceContributor(contributor.code, newCode.code)) }
                        />,
                        dismissible: false,
                    }
                    dispatch(SieauAction.setPopup(popup))
                },
            }
        })()
        return (!isNewActions && !isEditMode && links.length > 0) ? { ...actions, links } : actions
    }, [isConsultant, code, contributorProps, contributor, isEditMode])

    const onChangeNewsLetter = (value) => {
        if (value) {
            setContributor({
                ...contributor,
                noNewsLetterDate: moment().valueOf(),
                noNewsLetterLogin: getLogin(),
            })
        } else {
            setContributor({
                ...contributor,
                noNewsLetterDate: null,
                noNewsLetterLogin: '',
            })
        }
    }

    const geolocateMe = () => {
        if (!contributor.road) {
            const city = cities.find(c => c.id === contributor.cityCode)
            setContributor({ ...contributor, x: city.x, y: city.y, projection: city.projection })
        } else {
            const addressFormatted = getAddressFormatted(contributor, 'road')
            if (canFetchLocation(addressFormatted.address)) {
                dispatch(ToastrAction.error(i18n.addressFormatNotRespected))
            } else {
                dispatch(ReferencialAction.fetchLocation(addressFormatted)).then((location) => {
                    setContributor({ ...contributor, x: location.x, y: location.y, projection: 16 })
                })
            }
        }
    }

    const disabled = useMemo(() => ({
        active: isEditMode,
        disabled: !isEditMode,
    }), [isEditMode])

    const isEditMap = useMemo(() => isEditMode && editMap, [])

    const activeIcon = useMemo(() => contributor.status === '3' ? <i className='material-icons'>lock</i> : null, [contributor])

    const sandreStructureTypes = useSandreList(SANDRE.INTERVENANTS_TYPE_STRUCTURE)

    return (
        <div className='row no-margin'>
            <div id='file' className='col s12'>
                <Card className='padding-bottom-1'>
                    <Row>
                        <Row className='col m12 l6'>
                            <Card title={i18n.description} className='margin-top-1 padding-bottom-1' noMargin={false}>
                                <Row className='padding-top-1'>
                                    <Input
                                        col={ 9 }
                                        title={ i18n.corporateName }
                                        value={ contributor.name }
                                        onChange={ name => setContributor({ ...contributor, name }) }
                                        maxLength={ 115 }
                                        { ...disabled }
                                    />
                                    <Input
                                        col={ 3 }
                                        title={ i18n.mnemonic }
                                        value={ contributor.mnemonique }
                                        onChange={ mnemonique => setContributor({ ...contributor, mnemonique }) }
                                        maxLength={ 35 }
                                        { ...disabled }
                                    />
                                </Row>
                                <Row className='padding-top-1'>
                                    <SimpleDatePicker
                                        id='activityStartDate'
                                        col={6}
                                        label={i18n.activityStartDate}
                                        value={contributor.activityStartDate}
                                        onChange={ activityStartDate => setContributor({ ...contributor, activityStartDate }) }
                                        {...disabled}
                                    />
                                    <SimpleDatePicker
                                        id='activityEndDate'
                                        col={6}
                                        label={i18n.activityEndDate}
                                        value={contributor.activityEndDate}
                                        onChange={ activityEndDate => setContributor({ ...contributor, activityEndDate }) }
                                        {...disabled}
                                    />
                                </Row>
                                <Row className='padding-top-1'>
                                    <Select
                                        col={ 6 }
                                        label={ i18n.structureType }
                                        value={ contributor.structureType }
                                        options={ sandreStructureTypes }
                                        onChange={ structureType => setContributor({ ...contributor, structureType }) }
                                        nullLabel='&nbsp;'
                                        { ...disabled }
                                    />
                                    <Select
                                        options={ getReferencialComponent(contributorDomain) }
                                        label={ i18n.domain }
                                        col={ 6 }
                                        onChange={ domain => setContributor({ ...contributor, domain: parseInt(domain) }) }
                                        value={ parseInt(contributor.domain) }
                                        nullLabel='&nbsp;'
                                        { ...disabled }
                                    />
                                </Row>
                                <Row className='padding-top-1'>
                                    <Select
                                        col={ 6 }
                                        label={ i18n.legalRepresentative }
                                        value={ parseInt(contributor.legalRepresentative) }
                                        options={ contacts }
                                        onChange={ legalRepresentative => setContributor({ ...contributor, legalRepresentative }) }
                                        { ...disabled }
                                    />
                                    <Input
                                        col={ 6 }
                                        title={ i18n.department }
                                        value={ contributor.department }
                                        onChange={ department => setContributor({ ...contributor, department }) }
                                        maxLength={ 35 }
                                        { ...disabled }
                                    />
                                </Row>
                                <Row className='padding-top-1'>
                                    <Textarea
                                        col={ 12 }
                                        title={ i18n.comment }
                                        value={ contributor.comments }
                                        onChange={ comments => setContributor({ ...contributor, comments }) }
                                        { ...disabled }
                                    />
                                </Row>
                                <Row className='padding-top-1'>
                                    <Checkbox
                                        col={hasValue(contributor.noNewsLetterLogin) ? 4 : 12}
                                        label={i18n.noNewsLetter}
                                        checked={hasValue(contributor.noNewsLetterLogin)}
                                        onChange={onChangeNewsLetter}
                                        { ...disabled }
                                    />
                                    <Row displayIf={hasValue(contributor.noNewsLetterLogin)}>
                                        <Input
                                            col={ 4 }
                                            title={ i18n.login }
                                            value={ contributor.noNewsLetterLogin }
                                            maxLength={ 50 }
                                            readMode={true}
                                            disabled={true}
                                        />
                                        <SimpleDatePicker
                                            id='activityStartDate'
                                            col={4}
                                            label={i18n.noNewsLetterDate}
                                            value={contributor.noNewsLetterDate}
                                            disabled
                                        />
                                    </Row>
                                </Row>
                            </Card>
                            <Card title={i18n.localisation} className='margin-top-1 padding-bottom-1' noMargin={false}>
                                <Row className='padding-top-1'>
                                    <Textarea
                                        col={ 12 }
                                        title={ i18n.address }
                                        value={ contributor.road }
                                        placeholder={ i18n.exampleAddressFormat }
                                        onChange={ road => setContributor({ ...contributor, road }) }
                                        maxLength={ 35 }
                                        { ...disabled }
                                    />
                                </Row>
                                <Row className='padding-top-1'>
                                    <Textarea
                                        col={ 12 }
                                        title={ i18n.additionnalAddress }
                                        value={ contributor.addressComplement }
                                        onChange={ addressComplement => setContributor({ ...contributor, addressComplement }) }
                                        maxLength={ 35 }
                                        { ...disabled }
                                    />
                                </Row>
                                <Row className='padding-top-1'>
                                    <Input
                                        col={ 3 }
                                        title={ i18n.postcode }
                                        value={ contributor.postalCode }
                                        onChange={ postalCode => setContributor({ ...contributor, postalCode }) }
                                        maxLength={ 35 }
                                        { ...disabled }
                                    />
                                    <Select
                                        options={ getReferencialComponent(cities) }
                                        label={ i18n.city }
                                        col={ 9 }
                                        onChange={ cityCode => setContributor({ ...contributor, cityCode: cityCode?.toString(), cityCodeDelegate: undefined }) }
                                        value={ contributor.cityCode }
                                        { ...disabled }
                                        clearFunction
                                    />
                                </Row>
                                <Row className='padding-top-1'>
                                    <Input
                                        col={ 3 }
                                        title={ i18n.postalCodeDelegateShort }
                                        value={ contributor.postalCodeDelegate }
                                        onChange={ postalCodeDelegate => setContributor({ ...contributor, postalCodeDelegate }) }
                                        maxLength={ 35 }
                                        { ...disabled }
                                    />
                                    <Select
                                        options={ getReferencialComponent(citiesHisto.filter(c => c.cityAttachmentCode === contributor.cityCode)) }
                                        label={ i18n.cityDelegate }
                                        col={ 9 }
                                        onChange={ cityCodeDelegate => setContributor({ ...contributor, cityCodeDelegate: cityCodeDelegate?.toString() }) }
                                        value={ contributor.cityCodeDelegate }
                                        { ...disabled }
                                        clearFunction
                                        keyValue='cityCode'
                                    />
                                </Row>
                                <Row className='padding-top-1'>
                                    <Input
                                        col={ 12 }
                                        title={ 'Autre nom de commune' }
                                        value={ contributor.city }
                                        onChange={ city => setContributor({ ...contributor, city }) }
                                        maxLength={ 35 }
                                        { ...disabled }
                                    />
                                </Row>
                                <Row className='padding-top-1'>
                                    <Input
                                        col={ 4 }
                                        title={ i18n.cedexCode }
                                        value={ contributor.cedex }
                                        onChange={ cedex => setContributor({ ...contributor, cedex }) }
                                        maxLength={ 35 }
                                        { ...disabled }
                                    />
                                    <Input
                                        col={ 4 }
                                        title={ i18n.postalBox }
                                        value={ contributor.postalBox }
                                        onChange={ postalBox => setContributor({ ...contributor, postalBox }) }
                                        maxLength={ 35 }
                                        { ...disabled }
                                    />
                                    <Select
                                        col={ 4 }
                                        label={ i18n.country }
                                        value={ contributor.countryCode }
                                        options={ countries }
                                        onChange={ countryCode => setContributor({ ...contributor, countryCode }) }
                                        maxLength={ 30 }
                                        keyValue='countryCode'
                                        keyLabel='name'
                                        { ...disabled }
                                    />
                                </Row>
                                <Row className='padding-top-1'>
                                    <NumberField
                                        col={ 4 }
                                        title={ i18n.x }
                                        type='number'
                                        value={ contributor.x }
                                        onChange={ x => setContributor({ ...contributor, x }) }
                                        floatValue= { true }
                                        { ...disabled }
                                    />
                                    <NumberField
                                        col={ 4 }
                                        title={ i18n.y }
                                        type='number'
                                        value={ contributor.y }
                                        onChange={ y => setContributor({ ...contributor, y }) }
                                        floatValue= { true }
                                        { ...disabled }
                                    />
                                    <Button
                                        col={4}
                                        className='margin-top-1'
                                        style={{ width: 'calc(33.33333% - 0.75em)' }}
                                        title={i18n.estimateLocation}
                                        onClick={ geolocateMe }
                                        disabled={!(isEditMode && contributor.cityCode)}
                                    />
                                </Row>
                            </Card>

                        </Row>
                        <Row className='col m12 l6'>
                            <Card title={i18n.coordinates} className='margin-top-1 padding-bottom-1' noMargin={false}>
                                <Row className='padding-top-1'>
                                    <Input
                                        col={ 4 }
                                        title={ i18n.phoneTel }
                                        value={ contributor.phoneTel }
                                        onChange={ phoneTel => setContributor({ ...contributor, phoneTel }) }
                                        maxLength={ 50 }
                                        { ...disabled }
                                    />
                                    <Input
                                        col={ 4 }
                                        title={ i18n.phoneTelSecond }
                                        value={ contributor.phoneTelSecond }
                                        onChange={ phoneTelSecond => setContributor({ ...contributor, phoneTelSecond }) }
                                        maxLength={ 50 }
                                        { ...disabled }
                                    />
                                    <Input
                                        col={ 4 }
                                        title={ i18n.mobile }
                                        value={ contributor.mobile }
                                        onChange={ mobile => setContributor({ ...contributor, mobile }) }
                                        maxLength={ 50 }
                                        { ...disabled }
                                    />
                                </Row>
                                <Row className='padding-top-1'>
                                    <Input
                                        col={ 6 }
                                        title={ i18n.email }
                                        value={ contributor.email }
                                        onChange={ email => setContributor({ ...contributor, email }) }
                                        maxLength={ 100 }
                                        { ...disabled }
                                    />
                                    <Input
                                        col={ 6 }
                                        title={ i18n.fax }
                                        value={ contributor.fax }
                                        onChange={ fax => setContributor({ ...contributor, fax }) }
                                        maxLength={ 50 }
                                        { ...disabled }
                                    />
                                </Row>
                                <Row className='padding-top-1'>
                                    <Textarea
                                        col={6}
                                        title={i18n.schedule}
                                        value={contributor.schedule}
                                        onChange={ schedule => setContributor({ ...contributor, schedule }) }
                                        maxLength={100}
                                        {...disabled}
                                    />
                                    <Textarea
                                        col={6}
                                        title={i18n.publicSchedule}
                                        value={contributor.publicSchedule}
                                        onChange={ publicSchedule => setContributor({ ...contributor, publicSchedule }) }
                                        maxLength={50}
                                        {...disabled}
                                    />
                                </Row>
                            </Card>
                            <Card title={i18n.update} className='margin-top-1 padding-bottom-1' noMargin={false}>
                                <Row className='padding-top-1'>
                                    <Input
                                        col={ 6 }
                                        title={ i18n.author }
                                        value={ contributor.auteur }
                                        onChange={ auteur => setContributor({ ...contributor, auteur }) }
                                        maxLength={ 50 }
                                        { ...disabled }
                                    />
                                    <Select
                                        options={ referencialStatus }
                                        label={ i18n.status }
                                        col={ 5 }
                                        onChange={ status => setContributor({ ...contributor, status: `${status}` }) }
                                        value={ parseInt(contributor.status) }
                                        nullLabel='&nbsp;'
                                        { ...disabled }
                                    />
                                    <div className='col s1 padding-top-10-px'>
                                        { activeIcon }
                                    </div>
                                </Row>
                                <Row className='padding-top-1'>
                                    <SimpleDatePicker
                                        id='creationDate'
                                        col={6}
                                        label={i18n.creationDate}
                                        value={contributor.creationDate}
                                        onChange={ creationDate => setContributor({ ...contributor, creationDate }) }
                                        {...disabled}
                                    />
                                    <SimpleDatePicker
                                        id='updateDate'
                                        col={6}
                                        label={i18n.modification}
                                        value={contributor.updateDate}
                                        onChange={ updateDate => setContributor({ ...contributor, updateDate }) }
                                        {...disabled}
                                    />
                                </Row>
                            </Card>
                            <Card title={i18n.codifications} className='margin-top-1 padding-bottom-1' noMargin={false}>
                                <Row className='padding-top-1'>
                                    <Input
                                        col={ 6 }
                                        title={ i18n.siret }
                                        value={ contributor.siret }
                                        onChange={ siret => setContributor({ ...contributor, siret }) }
                                        maxLength={ 14 }
                                        { ...disabled }
                                    />
                                    <Input
                                        col={ 6 }
                                        title={ i18n.sandreCode }
                                        value={ contributor.sandre }
                                        onChange={ sandre => setContributor({ ...contributor, sandre }) }
                                        maxLength={ 17 }
                                        { ...disabled }
                                    />
                                </Row>
                                <Row className='padding-top-1'>
                                    <Input
                                        col={ 6 }
                                        title={ i18n.otherReference }
                                        value={ contributor.otherReference }
                                        onChange={ otherReference => setContributor({ ...contributor, otherReference }) }
                                        maxLength={ 35 }
                                        { ...disabled }
                                    />
                                    <Input
                                        col={ 6 }
                                        title={ i18n.internalIdentifier }
                                        value={ contributor.internalIdentifier }
                                        onChange={ internalIdentifier => setContributor({ ...contributor, internalIdentifier }) }
                                        maxLength={ 35 }
                                        { ...disabled }
                                    />
                                </Row>
                                <Row className='padding-1 no-padding-bottom'>
                                    <ReferencialCodificationPanel
                                        contributor={contributor}
                                        onChange={ (newContributor) => setContributor(newContributor) }
                                        readMode={disabled.disabled}
                                    />
                                </Row>
                            </Card>
                            <Card
                                title={i18n.map}
                                className='margin-top-1 padding-bottom-1'
                                noMargin={false}
                                actions={ isEditMode ? [{ iconName: !editMap ? 'edit' : 'cancel', onClick: () => setEditMap(!editMap) }] : [] }
                            >
                                <Row className='padding-1 no-padding-bottom'>
                                    <MoveMapPanel
                                        station={ contributor }
                                        type={ 'contact' }
                                        editMap={ isEditMap }
                                        code={code}
                                        handleChangeValue={ (newContributor) => setContributor(newContributor) }
                                    />
                                </Row>
                            </Card>
                        </Row>
                    </Row>
                </Card>
            </div>
        </div>
    )
}

export default ContributorApp
