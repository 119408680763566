import { chunk } from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'

const $ = window.$

class ListComponentCheckbox extends Component {
    constructor(props) {
        super(props)
        this.state = { display: {} }
    }

    componentWillMount() {
        if (this.props.paging) {
            this.setState({ currentPage: 0 })
        }
    }

    componentDidMount() {
        $('.tooltipped').tooltip('close')
        if (this.props.collapsible) {
            $('.collapsible').collapsible()
        }
    }

    componentDidUpdate() {
        $('.tooltipped').tooltip('close')
        if (this.props.collapsible) {
            $('.collapsible').collapsible()
        }
    }

    displayData = data => {
        if (this.props.displayOnClick && this.props.collapsible) {
            const obj = {}
            obj[data.id] = true
            this.setState({ display: Object.assign({}, this.state.display, obj) })
        }
    }

    getData = () => {
        return this.props.tables.map((data) => {
            const icon = (() => {
                if (data.icon) {
                    return (
                        <div>
                            <img className='iconeTypeResearch' src={ data.icon }/>
                            &nbsp;
                        </div>
                    )
                }
                return null
            })()
            return (
                <li>
                    <div className={ `${this.props.collapsible && 'collapsible-header'} no-padding col s11 ${this.props.displayBlock ? 'block' : ''}` } onClick={ () => this.displayData(data) }>
                        { icon }{ data.title }
                        {
                            !this.props.collapsible && data.component && (
                                <div>
                                    { data.component }
                                </div>
                            )
                        }
                    </div>
                    <div className='col s1 no-padding'>
                        { data.checkbox }
                    </div>
                    {
                        this.props.collapsible && (
                            <div className='collapsible-body no-padding no-margin hidden col s12'>
                                { this.props.displayOnClick ? (this.state.display[data.id] ? data.component : null) : data.component }
                            </div>
                        )
                    }
                </li>
            )
        })
    }

    getPages = (lastPageIndex) => {
        if (this.props.paging) {
            const page = this.state.currentPage
            const firstPage = page !== 0 ? (
                <a className='btn waves-effect waves-light' onClick={ () => this.setState({ currentPage: 0 }) }>
                    { 1 }
                </a>
            ) : null
            const dotsPrevious = page - 2 > 0 ? (
                <a className='btn waves-effect waves-light'>
                    ...
                </a>
            ) : null
            const previousPage = page - 1 > 0 ? (
                <a className='btn waves-effect waves-light' onClick={ () => this.setState({ currentPage: page - 1 }) }>
                    { page }
                </a>
            ) : null
            const currentPage = (
                <a className='btn waves-effect waves-light disabled' disabled>
                    { page + 1 }
                </a>
            )
            const nextPage = page + 1 < lastPageIndex ? (
                <a className='btn waves-effect waves-light' onClick={ () => this.setState({ currentPage: page + 1 }) }>
                    { page + 2 }
                </a>
            ) : null
            const dotsNext = page + 2 < lastPageIndex ? (
                <a className='btn waves-effect waves-light'>
                    ...
                </a>
            ) : null
            const lastPage = page !== lastPageIndex ? (
                <a className='btn waves-effect waves-light' onClick={ () => this.setState({ currentPage: lastPageIndex }) }>
                    { lastPageIndex + 1 }
                </a>
            ) : null
            return (
                <div className='paging_simple_numbers padding-top-1 padding-left-1 padding-bottom-1' >
                    <div className='pagination'>
                        { firstPage }
                        { dotsPrevious }
                        { previousPage }
                        { currentPage }
                        { nextPage }
                        { dotsNext }
                        { lastPage }
                    </div>
                </div>
            )
        }
        return null
    }

    render() {
        const data = this.getData()
        const displayedData = this.props.paging ? chunk(data, this.props.paging)[this.state.currentPage] : data
        const pages = this.getPages(this.props.paging ? chunk(data, this.props.paging).length - 1 : null)
        return (
            <div>
                <ul
                    className={ `${this.props.collapsible && 'collapsible'} no-margin` }
                    data-collapsible={ this.props.accordion ? 'accordion' : 'expandable' }
                    style={{ maxHeight: `${this.props.maxHeight}px`, overflow: 'auto' }}
                >
                    { displayedData }
                    { pages }
                </ul>
            </div>
        )
    }
}

ListComponentCheckbox.propTypes = {
    tables: PropTypes.arrayOf(PropTypes.shape({
        title: PropTypes.string,
        component: PropTypes.object,
    })),
    collapsible: PropTypes.bool,
    maxHeight: PropTypes.number,
    accordion: PropTypes.bool,
    paging: PropTypes.number,
    displayBlock: PropTypes.bool,
    displayOnClick: PropTypes.bool,
}

ListComponentCheckbox.defaultProps = {
    tables: [],
    collapsible: true,
    maxWidth: 500,
    accordion: false,
    displayBlock: false,
}

export default ListComponentCheckbox
