import React, { useState } from 'react'
import i18n from 'simple-react-i18n'
import PropTypes from 'prop-types'
import SimpleFilterSelect from 'components/forms/specific/SimpleFilterSelect'
import { Button, Card, CardContent, Grid2, Icon } from '@mui/material'
import { getTypeModule } from 'utils/StationUtils'
import Input from 'components/forms/Input'
import { hasValue } from 'utils/NumberUtil'
import FilterInsertModal from 'components/modal/FilterInsertModal'
import { FILTER_TYPE } from 'administration/components/user/constants/UserConstants'

const ResourceFilterPanel = ({
    stationType,
    data = [],

    onChangeSearchValue = () => { },
    setFilterResults = () => { },
    setFilter = () => { },

    defaultSearchValue,
    defaultFilter,
    defaultFilterResults,
}) => {
    const [open, setOpen] = useState(false)
    const [search, setSearch] = useState(defaultSearchValue)
    const [tmpFilter, setTmpFilter] = useState(defaultFilter || FILTER_TYPE.NOFILTER)
    const [tmpFilterResults, setTmpFilterResults] = useState(defaultFilterResults || [])

    const typeModule = getTypeModule(stationType)

    const onValidate = (newSearch) => {
        onChangeSearchValue(newSearch || search)
        setFilterResults(tmpFilterResults)
        setFilter(tmpFilter)
    }

    return (
        <Card>
            <CardContent>
                <Grid2 container size={12} spacing={1.5}>
                    <Grid2 size={3}>
                        <Input
                            tooltip={i18n.searchThenEnter}
                            title={i18n.search}
                            value={search}
                            onChange={setSearch}
                            onEnterKeyPress={(value) => {
                                setSearch(value)
                                onValidate(value)
                            }}
                            clearFunction
                            data-cy='search'
                        />
                    </Grid2>
                    <Grid2 size={3}>
                        <SimpleFilterSelect
                            stationType={stationType}
                            onChange={(filterStations, newFilter) => {
                                setTmpFilterResults(filterStations)
                                setTmpFilter(newFilter)
                            }}
                            nullLabel={i18n.allStations}
                            stations={data}
                        />
                    </Grid2>
                    <Grid2 container size={0.5} alignItems='center'>
                        <Icon sx={{ cursor: 'pointer' }} onClick={() => setOpen(true)} data-cy='open-filter' fontSize='md'>
                            {hasValue(tmpFilter) && tmpFilter !== FILTER_TYPE.BOOKMARK && tmpFilter !== FILTER_TYPE.NOFILTER ? 'edit' : 'note_add'}
                        </Icon>
                    </Grid2>
                    <FilterInsertModal
                        setOpen={setOpen}
                        open={open}
                        selectedFilter={tmpFilter}
                        module={typeModule}
                        isNew={!hasValue(tmpFilter) || tmpFilter === FILTER_TYPE.BOOKMARK || tmpFilter === FILTER_TYPE.NOFILTER}
                        onChange={setFilter}
                    />
                </Grid2>
                <Grid2 container size={12} justifyContent='flex-end'>
                    <Grid2 size='auto'>
                        <Button
                            variant='contained'
                            onClick={() => onValidate()}
                            data-cy='searchBtn'
                        >
                            {i18n.search}
                        </Button>
                    </Grid2>
                </Grid2>
            </CardContent>
        </Card>
    )
}

ResourceFilterPanel.propTypes = {
    stationType: PropTypes.string,
    onChangeSearchValue: PropTypes.func,
    data: PropTypes.arrayOf(PropTypes.shape({})),
    setFilterResults: PropTypes.func,
    setFilter: PropTypes.func,
    filter: PropTypes.number,
    defaultSearchValue: PropTypes.string,
    defaultFilter: PropTypes.number,
    defaultFilterResults: PropTypes.arrayOf(PropTypes.number),
    defaultSector: PropTypes.number,
    defaultCity: PropTypes.number,
}


export default ResourceFilterPanel