import ApplicationConf from 'conf/ApplicationConf'
import { genericPromise2 } from 'utils/ActionUtils'
import i18n from 'simple-react-i18n'
import LogAction from 'log/actions/LogAction'
import ToastrAction from 'toastr/actions/ToastrAction'

const HydrobioAction = {
    createHydrobioList: (qualitometer, operation, hydrobioList) => (dispatch) => genericPromise2(ApplicationConf.qualitometer.hydrobioLists(qualitometer, operation), { method: 'POST', body: hydrobioList })
        .catch((err) => {
            dispatch(LogAction.logError(`${i18n.updateError + i18n.operations} : ${err}`))
            dispatch(ToastrAction.error(i18n.updateError + i18n.operations))
        }),
    createAllHydrobioList: (qualitometer, operation, hydrobioLists) => (dispatch) => genericPromise2(ApplicationConf.qualitometer.allHydrobioList(qualitometer, operation), { method: 'POST', body: hydrobioLists })
        .catch((err) => {
            dispatch(LogAction.logError(`${i18n.updateError + i18n.operations} : ${err}`))
            dispatch(ToastrAction.error(i18n.updateError + i18n.operations))
        }),
    updateHydrobioList: (qualitometer, operation, list, hydrobioList) => (dispatch) => genericPromise2(ApplicationConf.qualitometer.hydrobioList(qualitometer, operation, list), { method: 'PUT', body: hydrobioList })
        .catch((err) => {
            dispatch(LogAction.logError(`${i18n.updateError + i18n.operations} : ${err}`))
            dispatch(ToastrAction.error(i18n.updateError + i18n.operations))
        }),
    deleteHydrobioList: (qualitometer, operation, list) => (dispatch) => genericPromise2(ApplicationConf.qualitometer.hydrobioList(qualitometer, operation, list), { method: 'DELETE' })
        .catch((err) => {
            dispatch(LogAction.logError(`${i18n.updateError + i18n.operations} : ${err}`))
            dispatch(ToastrAction.error(i18n.updateError + i18n.operations))
        }),
}

export default HydrobioAction