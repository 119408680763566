export default class DtoPiezometerListSpecific {
    constructor(obj = []) {
        this.id = obj[0]
        this.code = obj[1]
        this.name = obj[2]
        this.x = obj[3]
        this.y = obj[4]
        this.projection = obj[5]
        this.townCode = obj[6]
        this.creationDate = obj[7]
        this.closeDate = obj[8]
        this.nature = obj[9]
        this.jobExecutionId = obj[10]
        this.internalReferences = obj[11]
        this.typeName = 'piezometry'
    }
}
