import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import ToastrAction from 'toastr/actions/ToastrAction'
import Table from '../../../../components/datatable/Table'
import Checkbox from '../../../../components/forms/Checkbox'
import Select from '../../../../components/forms/Select'
import { nbPerPageLabelTiny } from '../../../../referencial/constants/ReferencialConstants'
import Job from '../../../dto/DtoJob'
import LowWaterStationDto from '../../../dto/LowWaterStationDto'
import { integrationModeHelpIcon } from '../../../utils/ImportUtils'

class ImportLowWaterPanel extends Component {
    constructor(props) {
        super(props)
        this.state = {
            obsOptions: [
                {
                    value: '',
                    label: i18n.noDataIntegration,
                },
                {
                    value: 'dataIntegration',
                    label: i18n.lastDataIntegration,
                },
                {
                    value: 'dataFullIntegration',
                    label: i18n.fullDataIntegration,
                },
            ],
        }
    }

    getFilters() {
        const { parameters } = this.props.job
        const filters = (parameters.filters && parameters.filters.length) ? parameters.filters : ['{\"codes\":[],\"stationIntegration\":false,\"dataIntegration\":\"\"}']
        return JSON.parse(filters[0])
    }

    stationIntegration() {
        const { parameters } = this.props.job
        if (parameters.filters && parameters.filters.length) {
            return JSON.parse(parameters.filters).stationIntegration || false
        }
        return false
    }

    dataIntegration() {
        const { parameters } = this.props.job
        if (parameters.filters && parameters.filters.length) {
            return JSON.parse(parameters.filters).dataIntegration || ''
        }
        return ''
    }

    handleChangeStationIntegration = integration => {
        const filters = this.getFilters()
        const newFilters = { ...filters, stationIntegration: integration }
        this.props.onChangeFilters([JSON.stringify(newFilters)])
    }

    handleChangeDataIntegration = integration => {
        const filters = this.getFilters()
        const newFilters = { ...filters, dataIntegration: integration }
        this.props.onChangeFilters([JSON.stringify(newFilters)])
    }

    addCode = () => {
        if (this.props.isEditMode) {
            const code = this.refs.txtAddCode.value
            const filters = this.getFilters()
            const exist = filters.codes.find(p => p.toLowerCase() === code.toLowerCase())
            if (!code) {
                this.props.toastrError(i18n.youMustEnterAtLeastOneCode)
            } else if (exist) {
                this.props.toastrError(i18n.youHaveAlreadyEnteredThisCode)
            } else {
                const newFilters = { ...filters, codes: [ ...filters.codes, code ] }
                this.props.onChangeFilters([JSON.stringify(newFilters)])
                this.refs.txtAddCode.value = ''
            }
        }
    }

    getStations = () => {
        const filters = this.getFilters()
        return filters.codes.map(code => new LowWaterStationDto({ code }))
    }

    onDeleteCode = ({ code }) => {
        const filters = this.getFilters()
        const newFilters = { ...filters, codes: filters.codes.filter(p => p !== code) }
        this.props.onChangeFilters([JSON.stringify(newFilters)])
    }

    render() {
        const disabled = { disabled: !this.props.isEditMode }
        return (
            <div>
                <div className='row no-margin valign-wrapper padding-bottom-2'>
                    <div className='col s3 no-margin'>
                        <Checkbox
                            checked={ this.stationIntegration() }
                            label={ i18n.integrateStationInfo }
                            onChange={ this.handleChangeStationIntegration }
                            { ...disabled }
                        />
                    </div>
                    <div className='col s4 no-margin'>
                        <Select
                            value={ this.dataIntegration() }
                            options={ this.state.obsOptions }
                            label={ i18n.dataIntegration }
                            labelSpan={ integrationModeHelpIcon() }
                            col={ 12 }
                            onChange={ this.handleChangeDataIntegration }
                            { ...disabled }
                        />
                    </div>
                </div>
                <div className='row valign-wrapper'>
                    <div className='col s3'><label>{ i18n.addCredential }</label></div>
                    <div className='input-field col s9 no-padding'>
                        <div className='col s8'>
                            <input id='txtAddCode' type='text' placeholder={ i18n.lowWaterExample } ref='txtAddCode'/>
                            <label className='tinyLabel'>{ i18n.lowWaterCode }</label>
                        </div>
                        <a className='waves-effect waves-light btn col offset-s1 s3' onClick={ this.addCode }>{ i18n.add }</a>
                    </div>
                </div>
                <div className='row valign-wrapper'>
                    <div className='col s12'>
                        <Table
                            type={ new LowWaterStationDto() }
                            data={ this.getStations() }
                            title={ i18n.credentials }
                            nbPerPageLabel={ nbPerPageLabelTiny }
                            onDelete={ this.onDeleteCode }
                            deletable={ this.props.isEditMode }
                            showNbElements
                            paging
                            exportButtonOnHeader
                        />
                    </div>
                </div>
            </div>
        )
    }
}

ImportLowWaterPanel.propTypes = {
    job: PropTypes.instanceOf(Job).isRequired,
    isEditMode: PropTypes.bool,
    handleChangeParameters: PropTypes.func.isRequired,
    onChangeFilters: PropTypes.func.isRequired,
    toastrError: PropTypes.func,
}

const mapDispatchToProps = {
    toastrError: ToastrAction.error,
}

export default connect(null, mapDispatchToProps)(ImportLowWaterPanel)
