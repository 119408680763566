import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import React, { useEffect, useState } from 'react'
import { push } from '@lagunovsky/redux-react-router'
import JobAction from 'import/actions/JobAction'
import { hasValue } from 'utils/NumberUtil'
import { getManualJobId } from 'utils/JobUtils'
import { getLogin } from 'utils/SettingUtils'
import ToastrAction from 'toastr/actions/ToastrAction'
import StepperDialog from 'components/modal/StepperDialog'
import AddPluvioStepStation from './steps/AddPluvioStepStation'
import PluviometryAction from 'pluviometry/actions/PluviometryAction'
import AddPluvioStepOptions from './steps/AddPluvioStepOptions'
import AddStepExecution from 'station/components/addSteps/AddStepExecution'
import AddStepJob from 'station/components/addSteps/AddStepJob'
import { PLUVIO_IMPORT_TYPE } from 'pluviometry/constants/PluviometryConstant'

const STEP = {
    STATION_TYPE: 0,
    OPTIONS: 1,
    EXECUTION: 2,
    JOB: 3,
}

const callExecuteJob = (id, cb) => JobAction.promiseExecuteJob(id).then(json => {
    if (hasValue(json.execution)) {
        cb(json.execution)
    } else {
        setTimeout(() => JobAction.promiseExecuteJob(id).then(json2 => {
            if (hasValue(json2.execution)) {
                cb(json2.execution)
            } else {
                throw new Error('Impossible to launch Job')
            }
        }), 5000)
    }
})

const AddPluvioStepper = ({
    isOpen,
    setIsOpen,
}) => {
    const dispatch = useDispatch()
    const {
        pluviometers,
        userStation,
    } = useSelector(store => ({
        pluviometers: store.PluviometryReducer.pluviometers,
        userStation: store.UserReducer.userStation,
    }), shallowEqual)

    const [station, setStation] = useState({})
    const [filters, setFilters] = useState({})
    const [jobDataTypes, setJobDataTypes] = useState([])

    useEffect(() => {
        dispatch(PluviometryAction.fetchPluviometryDataTypes())
    }, [])

    const changeStation = (changes) => setStation({ ...station, ...changes })
    const changeFilters = changes => setFilters({ ...filters, ...changes })

    const createStation = () => {
        dispatch(PluviometryAction.createPluviometer({ ...station, stationType: null }, id => {
            setIsOpen(false)
            dispatch(push(`/station/pluviometry/${id}/description`))
        }))
    }

    const executeJob = (nextStep) => {
        JobAction.promiseJob(getManualJobId(station.stationType)).then(job => {
            const oldParams = JSON.parse(job.parameters)
            const newJob = {
                ...job, parameters: {
                    jobType: oldParams.jobType,
                    routingKey: oldParams.routingKey,
                    filters: filters ? [JSON.stringify(filters)] : [],
                    dataTypes: jobDataTypes,
                    alertTypes: [],
                }, login: getLogin(),
            }
            JobAction.promiseUpdateJob(newJob).then(res => {
                if (res.status !== 200) {
                    dispatch(ToastrAction.error(i18n.updateError + i18n.jobs))
                } else {
                    // changeStation({ jobExecutionId: 2877126, job: newJob }) // TODO: pour le test, à enlever
                    // nextStep()
                    callExecuteJob(newJob.id, jobExecutionId => {
                        changeStation({ jobExecutionId, job: newJob })
                        nextStep()
                    }).then(() => { })
                }
            })
        })
    }

    const nextAvailableStep2 = () => {
        if (station.stationType === PLUVIO_IMPORT_TYPE.EMPTY) {
            return !!station.code && userStation.hasRights
        }
        return !!((filters?.stationCodes?.length && userStation.hasRights) || filters?.cityCodes?.length || filters?.departmentCodes?.length)
    }

    return (
        <StepperDialog
            steps={[
                {
                    label: i18n.station,
                    constant: STEP.STATION_TYPE,
                    nextAvailable: !!station.stationType,
                },
                {
                    label: i18n.options,
                    constant: STEP.OPTIONS,
                    nextAvailable: nextAvailableStep2(),
                    nextLabel: station.stationType === PLUVIO_IMPORT_TYPE.EMPTY ? i18n.add : i18n.execute,
                    onNext: station.stationType === PLUVIO_IMPORT_TYPE.EMPTY ? createStation : executeJob,
                },
                {
                    label: i18n.execution,
                    constant: STEP.EXECUTION,
                    nextAvailable: (station?.jobExecution?.statusCode === 1 || station?.jobExecution?.statusCode === 2) && (pluviometers?.filter(p => station?.ids?.includes(p.id))?.length === station.ids?.length),
                },
                {
                    label: i18n.programmation,
                    constant: STEP.JOB,
                },
            ]}
            open={isOpen}
            title={i18n.addOrUpdateStation}
            closeDialog={() => setIsOpen(false)}
        >
            {(step, nextStep, resetStep) => {
                const fullReset = () => {
                    resetStep()
                    setStation({})
                    setFilters({})
                    setJobDataTypes([])
                }
                const params = { setIsOpen, station, changeStation, filters, changeFilters, setFilters, nextStep, jobDataTypes, setJobDataTypes }
                switch (step) {
                    case STEP.STATION_TYPE:
                        return <AddPluvioStepStation {...params} />
                    case STEP.OPTIONS:
                        return <AddPluvioStepOptions {...params} />
                    case STEP.EXECUTION:
                        return <AddStepExecution {...params} fetchStations={PluviometryAction.fetchPluviometers}/>
                    case STEP.JOB:
                    default:
                        return (
                            <AddStepJob
                                {...params}
                                fullReset={fullReset}
                                setIsOpen={setIsOpen}
                                stationType='pluviometry'
                                allStations={pluviometers}
                            />
                        )
                }
            }}
        </StepperDialog>
    )
}

AddPluvioStepper.propTypes = {
    isOpen: PropTypes.bool,
    setIsOpen: PropTypes.func,
}

export default AddPluvioStepper