import { hasValue } from '../../../utils/NumberUtil'

export default class DtoPiezometryStationMeasure {
    constructor(obj) {
        this.piezoId = obj.piezoId
        this.dataType = obj.dataType
        this.id = obj.creationDate
        this.date = obj.date || obj.creationDate
        this.initialCreationDate = obj.initialCreationDate || obj.importDate || obj.date || obj.creationDate
        this.initialHour = obj.initialHour || obj.hour
        this.value = hasValue(obj.value) ? obj.value : null
        this.continuity = obj.continuity
        this.obtainingMode = obj.obtainingMode
        this.precision = obj.precision
        this.validity = obj.status || obj.validity
        this.status = obj.status
        this.qualification = obj.qualification
        this.piezometerId = obj.piezometerId
        this.nature = obj.nature
        this.initialValue = obj.initialValue
        this.initialNGF = obj.initialNGF
        this.cote = obj.cote
        this.NGF = obj.NGF
        this.measureMode = obj.measureMode
        this.initialPoint = obj.initialPoint
        this.refAlti = obj.refAlti
        this.landmark = obj.landmark
        this.validator = obj.validator
        this.manager = obj.manager
        this.producer = obj.producer
        this.jobExecutionId = obj.jobExecutionId
        this.importDate = obj.importDate
        this.updateDate = obj.updateDate
        this.updateLogin = obj.updateLogin
        this.codepoint = obj.codepoint

        this.deletion = obj.deletion
        this.isModified = obj.isModified
    }
}
