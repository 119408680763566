import React, { useEffect } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import ResourceAction from 'resource/actions/ResourceAction'
import {
    H_CATCHMENT_MODULE,
    H_DISTRIBUTION_MODULE,
    H_HYDRO_MODULE,
    H_INSTALLATION_MODULE,
    H_PIEZO_MODULE,
    H_PLUVIO_MODULE,
    H_PRODUCTION_MODULE,
    H_QUALITO_MODULE,
    H_RESOURCE_MODULE,
} from '../../../../../account/constants/AccessRulesConstants'
import DistributionUnitAction from '../../../../../distributionUnit/actions/DistributionUnitAction'
import HydrometryAction from '../../../../../hydrometry/actions/HydrometryAction'
import InstallationAction from '../../../../../installation/actions/InstallationAction'
import PiezometryAction from '../../../../../piezometry/actions/PiezometryAction'
import PluviometryAction from '../../../../../pluviometry/actions/PluviometryAction'
import ProductionUnitAction from '../../../../../productionUnit/actions/ProductionUnitAction'
import QualityAction from '../../../../../quality/actions/QualityAction'
import { componentHasHabilitations } from '../../../../../utils/HabilitationUtil'
import {
    CATCHMENT,
    DISTRIBUTION,
    HYDRO,
    INSTALLATION,
    PIEZO,
    PLUVIO,
    PRODUCTION,
    QUALITO,
    RESOURCE,
} from '../../constants/ApplicationHabilitationConstants'
import i18n from 'simple-react-i18n'
import NetworkAction from 'referencial/components/network/actions/NetworkAction'
import ContributorAction from 'referencial/components/contributor/actions/ContributorAction'
import { BasicHabilitationAccordion, HabilitationAccordion } from './access/HabilitationAccordion'
import CatchmentThunk from 'catchment/actions/CatchmentThunk'

const UserAccessPanel = ({

}) => {
    const dispatch = useDispatch()
    const {
        qualitometers,
        piezometers,
        hydrometricStations,
        installations,
        distributionUnits,
        productionUnits,
        pluviometers,
        resources,
        catchments,
    } = useSelector(store => ({
        qualitometers: store.QualityReducer.qualitometersLight,
        piezometers: store.PiezometryReducer.piezometersLight,
        hydrometricStations: store.HydrometryReducer.hydrometricStations,
        installations: store.InstallationReducer.installations,
        distributionUnits: store.DistributionUnitReducer.distributionUnits,
        productionUnits: store.ProductionUnitReducer.productionUnits,
        pluviometers: store.PluviometryReducer.pluviometers,
        resources: store.ResourceReducer.resources,
        catchments: store.CatchmentReducer.catchments,
    }), shallowEqual)

    useEffect(() => {
        dispatch(NetworkAction.fetchNetworks())
        dispatch(ContributorAction.fetchContributors())
        if (qualitometers.length === 0) {
            dispatch(QualityAction.fetchQualitometersLight())
        }
        if (piezometers.length === 0) {
            dispatch(PiezometryAction.fetchPiezometersLight())
        }
        if (pluviometers.length === 0) {
            dispatch(PluviometryAction.fetchPluviometers())
        }
        if (hydrometricStations.length === 0) {
            dispatch(HydrometryAction.fetchHydrometricStations())
        }
        if (installations.length === 0) {
            dispatch(InstallationAction.fetchInstallations())
        }
        if (distributionUnits.length === 0) {
            dispatch(DistributionUnitAction.fetchDistributionUnits())
        }
        if (productionUnits.length === 0) {
            dispatch(ProductionUnitAction.fetchProductionUnits())
        }
        if (resources.length === 0) {
            dispatch(ResourceAction.fetchResources())
        }
        if (catchments.length === 0) {
            dispatch(CatchmentThunk.fetchCatchments())
        }
    }, [dispatch])

    return (
        <div className='row no-margin'>
            {
                componentHasHabilitations(H_QUALITO_MODULE) && (
                    <HabilitationAccordion
                        stationType={QUALITO}
                        title={i18n.quality}
                        stations={qualitometers}
                    />
                )
            }
            {
                componentHasHabilitations(H_PIEZO_MODULE) && (
                    <HabilitationAccordion
                        stationType={PIEZO}
                        title={i18n.piezometry}
                        stations={piezometers}
                    />
                )
            }
            {
                componentHasHabilitations(H_HYDRO_MODULE) && (
                    <HabilitationAccordion
                        stationType={HYDRO}
                        title={i18n.hydrometry}
                        stations={hydrometricStations}
                    />
                )
            }
            {
                componentHasHabilitations(H_PLUVIO_MODULE) && (
                    <HabilitationAccordion
                        stationType={PLUVIO}
                        title={i18n.pluviometry}
                        stations={pluviometers}
                    />
                )
            }
            {
                componentHasHabilitations(H_INSTALLATION_MODULE) && (
                    <HabilitationAccordion
                        stationType={INSTALLATION}
                        title={i18n.installation}
                        stations={installations}
                        noNetworkDisplay
                    />
                )
            }
            {
                componentHasHabilitations(H_PRODUCTION_MODULE) && (
                    <BasicHabilitationAccordion
                        stationType={PRODUCTION}
                        title={i18n.productionUnit}
                        stations={productionUnits}
                    />
                )
            }
            {
                componentHasHabilitations(H_DISTRIBUTION_MODULE) && (
                    <BasicHabilitationAccordion
                        stationType={DISTRIBUTION}
                        title={i18n.distributionUnit}
                        stations={distributionUnits}
                    />
                )
            }
            {
                componentHasHabilitations(H_RESOURCE_MODULE) && (
                    <BasicHabilitationAccordion
                        stationType={RESOURCE}
                        title={i18n.resource}
                        stations={resources}
                    />
                )
            }
            {
                componentHasHabilitations(H_CATCHMENT_MODULE) && (
                    <BasicHabilitationAccordion
                        stationType={CATCHMENT}
                        title={i18n.catchment}
                        stations={catchments}
                    />
                )
            }
        </div>
    )
}

UserAccessPanel.propTypes = {
}

export default UserAccessPanel