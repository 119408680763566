
export default class DtoVisitActionDoc {
    constructor(obj, docIndex) {
        this.idInstallation = obj.idInstallation // Long,
        this.idCampaign = obj.idCampaign // Long,
        this.idVisit = obj.idVisit // Long,
        this.idAction = obj.idAction // Long,
        this.docName = obj.docName // Option[String],
        this.description = obj.description // Option[String]

        // used for front
        this.index = docIndex
    }
}
