module.exports = {
    RECEIVE_ALL_OPERATION: 'RECEIVE_ALL_OPERATION',
    RECEIVE_ALL_ANALYSE: 'RECEIVE_ALL_ANALYSE',
    RECEIVE_ALL_ANALYSE_ERROR: 'RECEIVE_ALL_ANALYSE_ERROR',
    UPDATE_ANALYSIS_CONFORMITY: 'UPDATE_ANALYSIS_CONFORMITY',
    DELETE_ANALYSIS_CONFORMITY: 'DELETE_ANALYSIS_CONFORMITY',
    QUALITY_SITUATION: 'QUALITY_SITUATION',

    RESET_INTEGRATION_OVERVIEW: 'RESET_INTEGRATION_OVERVIEW',

    STATIONS_TAB: 'STATIONS_TAB',
    ANALYSIS_TAB: 'ANALYSIS_TAB',
    JOB_TAB: 'JOB_TAB',

    nbPerPageLabelNonConform: [
        { label: '10', value: 10 },
        { label: '20', value: 20 },
        { label: '100', value: 100 },
    ],

    nbPerPageLabelConform: [
        { label: '20', value: 20 },
        { label: '50', value: 50 },
        { label: '100', value: 100 },
    ],


    nbPerPageLabelStation: [
        { label: '50', value: 50 },
        { label: '100', value: 100 },
        { label: '1000', value: 1000 },
        { label: 'Tout', value: -1 },
    ],

    headerConformTable: ['code', 'name', 'sample', 'labo', 'parameter', 'result', 'unit', 'support', 'fraction', 'remark'],
    headerNonConformTable: ['code', 'name', 'sample', 'labo', 'parameter', 'result', 'unit', 'support', 'fraction', 'remark', 'conformity', 'threshold', 'outrageous'],
}