import Input from 'components/forms/Input'
import CMSEventDto from 'events/dto/CMSEventDto'
import PropTypes from 'prop-types'
import React from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import ContributorDto from 'referencial/components/contributor/dto/ContributorDto'
import i18n from 'simple-react-i18n'
import NumberField from '../../../components/forms/NumberField'
import Select from '../../../components/forms/Select'
import SimpleDatePicker from '../../../components/forms/SimpleDatePicker'
import Textarea from '../../../components/forms/Textarea'
import Row from '../../../components/react/Row'
import { SANDRE } from '../../../referencial/constants/ReferencialConstants'
import DtoSandreCode from '../../../referencial/dto/DtoSandreCode'
import { arrayOf, getSandreList } from '../../../utils/StoreUtils'
import DtoSurvey from '../../dto/DtoSurvey'
import Checkbox from 'components/forms/Checkbox'
import { SURVEY_TYPES } from 'agriAdministration/constants/AgriConstants'

const FormSurvey = ({
    survey = {},
    onChangeSurvey = () => { },
    readMode = false,
}) => {
    const {
        sandreCodes,
        contributors,
        cmsEvents,
    } = useSelector(store => ({
        sandreCodes: store.ReferencialReducer.sandreCodes,
        contributors: store.ContributorReducer.contributors,
        cmsEvents: store.EventsReducer.cmsEvents,
    }), shallowEqual)

    const { year, statusCode, startDate, comment, endDate, adminEndDate, cmsId, name, contributorId,
        ddtLimitDate, agencyLimitDate, surveyType, authorizationDate, exportNotifications,
    } = survey
    const mode = { disabled: readMode }
    return (
        <Row>
            <Row className='padding-top-1'>
                <Input
                    col={3}
                    title={i18n.name}
                    value={name}
                    onChange={value => onChangeSurvey({ name: value })}
                    {...mode}
                />
                <NumberField
                    col={3}
                    title={i18n.year}
                    value={year}
                    onChange={value => onChangeSurvey({ year: value })}
                    {...mode}
                />
                <SimpleDatePicker
                    col={3}
                    value={startDate}
                    label={i18n.startDate}
                    onChange={v => onChangeSurvey({ startDate: v })}
                    max={endDate}
                    {...mode}
                />
                <SimpleDatePicker
                    col={3}
                    value={endDate}
                    label={i18n.endDate}
                    onChange={v => onChangeSurvey({ endDate: v })}
                    min={startDate}
                    {...mode}
                />
            </Row>
            <Row className='padding-top-3-px'>
                <Select
                    col={3}
                    label={i18n.status}
                    options={getSandreList(sandreCodes, SANDRE.CMS_STATUT)}
                    value={statusCode}
                    integerValue
                    keyValue='code'
                    onChange={value => onChangeSurvey({ statusCode: value })}
                    {...mode}
                />
                <Select
                    col={3}
                    label={i18n.type}
                    options={[{
                        value: SURVEY_TYPES.ANNUAL,
                        label: i18n.annual,
                    }, {
                        value: SURVEY_TYPES.INTERMEDIATE,
                        label: i18n.intermediate,
                    }]}
                    value={surveyType}
                    integerValue
                    onChange={value => onChangeSurvey({ surveyType: value, exportNotifications: value === SURVEY_TYPES.INTERMEDIATE ? false : exportNotifications })}
                    noNullValue
                    {...mode}
                />
                <SimpleDatePicker
                    col={3}
                    value={adminEndDate}
                    label={i18n.adminEndDate}
                    onChange={v => onChangeSurvey({ adminEndDate: v })}
                    min={startDate}
                    {...mode}
                />
                <SimpleDatePicker
                    col={3}
                    value={authorizationDate}
                    label={i18n.authorisationDate}
                    onChange={v => onChangeSurvey({ authorizationDate: v })}
                    {...mode}
                />
            </Row>
            <Row>
                <Select
                    col={3}
                    label='CMS Logos'
                    value={cmsId}
                    onChange={value => onChangeSurvey({ cmsId: value })}
                    options={cmsEvents}
                    keyLabel='title'
                    keyValue='id'
                    {...mode}
                />
                <Select
                    col={3}
                    label={i18n.organism}
                    options={contributors}
                    value={contributorId}
                    integerValue
                    keyValue='code'
                    onChange={value => onChangeSurvey({ contributorId: value })}
                    {...mode}
                />
                <SimpleDatePicker
                    col={3}
                    value={ddtLimitDate}
                    label={i18n.ddtLimitDate}
                    onChange={v => onChangeSurvey({ ddtLimitDate: v })}
                    {...mode}
                />
                <SimpleDatePicker
                    col={3}
                    value={agencyLimitDate}
                    label={i18n.agencyLimitDate}
                    onChange={v => onChangeSurvey({ agencyLimitDate: v })}
                    {...mode}
                />
            </Row>
            <Row>
                <Checkbox
                    col
                    id='exportNotifications'
                    label={i18n.authorizedNotifsExport}
                    checked={exportNotifications || false}
                    onChange={v => onChangeSurvey({ exportNotifications: v })}
                    {...mode}
                    disabled={readMode || surveyType === SURVEY_TYPES.INTERMEDIATE}
                    tooltip={surveyType === SURVEY_TYPES.INTERMEDIATE ? i18n.notificationsOnlyForAnnualSurvey : null}
                />
            </Row>
            <Row className='padding-top-1 padding-bottom-1'>
                <Textarea
                    col={12}
                    title={i18n.comment}
                    value={comment}
                    onChange={value => onChangeSurvey({ comment: value })}
                    {...mode}
                />
            </Row>
        </Row>
    )
}

FormSurvey.propTypes = {
    survey: PropTypes.instanceOf(DtoSurvey),
    readMode: PropTypes.bool,
    sandreCodes: arrayOf(DtoSandreCode),
    contributors: arrayOf(ContributorDto),
    cmsEvents: arrayOf(CMSEventDto),
    onChangeSurvey: PropTypes.func,
}

export default FormSurvey