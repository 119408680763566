import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { push } from '@lagunovsky/redux-react-router'
import AppStore from 'store/AppStore'
import NotifsGlobalResearchComponent from '../../home/components/globalResearch/NotifsGlobalResearchComponent'

const $ = window.$

class AppBar extends Component {
    constructor(props) {
        super(props)
        this.state = {
            title: [],
            links: [],
        }
    }

    redirect = (href) => {
        if (href[0] === '/') {
            AppStore.dispatch(push(href))
        } else {
            AppStore.dispatch(push(`/${href}`))
        }
    }

    setBurgerEvent = () => {
        $('.button-collapse').sideNav('destroy')
        $('.tooltipped').tooltip({ delay: 30, html: true })
        $('.button-collapse').sideNav({
            closeOnClick: true,
        })
    }

    componentDidMount() {
        this.setBurgerEvent()
    }

    componentDidUpdate() {
        this.setBurgerEvent()
    }

    getBreadcrumbs = () => {
        return this.props.title.map((o) => {
            if (o && o.title) {
                const title = o.title.length > 100 ? `${o.title.substring(0, 100)}...` : o.title
                return (<a key={ o.title } className='breadcrumb clickable' data-cy={`title-${title}`}
                    onClick={ () => this.redirect(o.href) }
                >{ title }</a>)
            }
            return null
        })
    }

    getColor= (branche)=>{
        switch (branche) {
            case 'develop' : return 'orange600'
            case 'quali' : return ''
            default : return ''
        }
    }

    render() {
        const rows = this.props.actions.map((action, i) => {
            return <li key={i}>{ action }</li>
        })

        const links = (() => {
            if (this.state.links.length > 0) {
                return this.state.links.map((link, i) => {
                    if (link.src && link.href) {
                        return (
                            <li key={i}>
                                <a href={ link.href } target='_blank' className='right no-padding'>
                                    <img src={ link.src } className='navbarIcon'/>
                                </a>
                            </li>
                        )
                    }
                    return null
                })
            }
            return null
        })()

        return (
            <div>
                <div className='navbar-fixed'>
                    <nav>
                        <div className='nav-wrapper flex' id='nav_wrapper'style={ { justifyContent: 'space-between' } }>
                            <a className='button-collapse brand-logo' data-activates='slide-out' onClick={ this.showSide }>
                                <i className={`material-icons ${this.getColor(process.env.REACT_APP_BRANCHE)}`} data-cy='burger_menu'>menu</i>
                            </a>
                            <div className='left truncate margin-left-7' id='breadcrumbs'>
                                { this.getBreadcrumbs()}
                            </div>
                            <ul className='right hide-on-med-and-down inline-flex'>
                                <li><NotifsGlobalResearchComponent/></li>
                                {/* <li><StatusExport/></li> */}
                                { rows }
                                { links }
                            </ul>
                        </div>
                    </nav>
                </div>
            </div>
        )
    }
}

AppBar.propTypes = {
    actions: PropTypes.arrayOf(PropTypes.element),
    popup: PropTypes.shape({
        header: PropTypes.string,
        actions: PropTypes.string,
        id: PropTypes.string,
        idPopup: PropTypes.string,
        fixedFooter: PropTypes.bool,
        bottomSheet: PropTypes.bool,
        content: PropTypes.element,
        className: PropTypes.string,
        size: PropTypes.string,
        onClose: PropTypes.func,
        dismissible: PropTypes.bool,
        callback: PropTypes.func,
        dontReset: PropTypes.bool,
    }),
    title: PropTypes.arrayOf(PropTypes.shape({
        title: PropTypes.string,
        href: PropTypes.string,
    })),
    backPath: PropTypes.string.isRequired,
}

const mapStateToProps = store => {
    return {
        title: store.HomeReducer.title,
        backPath: store.HomeReducer.backPath,
        actions: store.HomeReducer.actions,
        popup: store.SieauReducer.popup,
    }
}

export default connect(mapStateToProps)(AppBar)
