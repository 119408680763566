import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import CampaignAction from '../../../../campaign/actions/CampaignAction'
import CampaignDto from '../../../../campaign/dto/CampaignDto'
import Checkbox from '../../../../components/forms/Checkbox'
import RadioButtons from '../../../../components/forms/RadioButtons'
import Input from '../../../../components/forms/Input'
import Select from '../../../../components/forms/Select'
import Row from '../../../../components/react/Row'
import QualityAction from '../../../../quality/actions/QualityAction'
import DtoQualification from '../../../../quality/dto/DtoQualification'
import DtoStatus from '../../../../quality/dto/DtoStatus'
import ContributorAction from '../../../../referencial/components/contributor/actions/ContributorAction'
import ContributorDto from '../../../../referencial/components/contributor/dto/ContributorDto'
import FractionAction from '../../../../referencial/components/fraction/actions/FractionAction'
import FractionDto from '../../../../referencial/components/fraction/dto/FractionDto'
import SupportAction from '../../../../referencial/components/support/actions/SupportAction'
import SupportDto from '../../../../referencial/components/support/dto/SupportDto'
import { setModal } from '../../../../utils/FormUtils'
import Job from '../../../dto/DtoJob'
import ImportQualityDefaultValue from '../ImportQualityDefaultValue'
import ImportFileModal from './ImportFileModal'
import { integrationModeHelpIcon } from '../../../utils/ImportUtils'
import { getIntegrationModes, getImportMode } from '../../../../utils/JobUtils'
import MultiContributorsAutocomplete from '../../../../referencial/components/contributor/components/MultiContributorsAutocomplete'

class ImportDdassDistriPanel extends Component {
    constructor(props) {
        super(props)
        const parameter = props.job.parameters.parameters.length ? JSON.parse(props.job.parameters.parameters[0]) : {}
        this.state = {
            directory: undefined,
            filter: undefined,
            rename: false,
            withPoint: true,
            ...parameter,
        }
    }

    componentDidMount = () => {
        if (!this.props.contributors.length) {
            this.props.fetchContributors()
        }
        if (!this.props.qualifications.length) {
            this.props.fetchQualifications()
        }
        if (!this.props.status.length) {
            this.props.fetchStatus()
        }
        if (!this.props.fractions.length) {
            this.props.fetchFractions()
        }
        if (!this.props.supports.length) {
            this.props.fetchSupports()
        }
        this.props.fetchQualityCampaigns()
    }

    setAdvancedFtpModal =() => {
        setModal({
            title: i18n.importParam,
            content: <ImportFileModal url={this.state.directory} onChange={name =>{
                this.onChangeParameter({ filter: name })
                this.setState({ filter: name })
            } }
            />,
        })
    }

    onChangeParameter = obj => {
        const newParameter = {
            ...this.state,
            ...obj,
        }
        this.setState(obj)
        this.props.handleChangeParameters([JSON.stringify(newParameter)])
    }

    onChangeFilters = (changes) => {
        const parameters = {
            ...this.props.job.parameters,
            filters: [JSON.stringify({
                ...this.getFilters(),
                ...changes,
            })],
        }
        this.props.onChangeJob({ parameters })
    }
    getFilters = () => {
        return this.props.job.parameters.filters.length ? JSON.parse(this.props.job.parameters.filters[0]) : {}
    }

    getDataTypes = type => {
        const { parameters } = this.props.job
        if (parameters.dataTypes && parameters.dataTypes.length) {
            return parameters.dataTypes.find(d => d.includes(type)) || parameters.dataTypes
        }
        return ''
    }

    handleChangeDataIntegration = integration => {
        const { parameters } = this.props.job
        const newDataTypes = (() => {
            const data = parameters.dataTypes.filter(d => !d.includes('DATA'))
            if (integration) {
                return [...data, integration]
            }
            return data
        })()
        this.props.onChangeDataTypes(newDataTypes)
    }

    render() {
        const {
            rename,
            campaignId,
            directory,
            filter,
            create,
            validation,
            withPoint,
            integrationHisto,
            ...defaultValue
        } = this.state
        const disabled = { disabled: !this.props.isEditMode }
        const filters= this.getFilters()
        const getWithPointeList = [
            { code: true, name: i18n.StationPrelevement },
            { code: false, name: i18n.LocalPrelevement },
        ]
        return (
            <div>
                <br />
                <div className='row no-margin valign-wrapper'>
                    <div className='col s4'>
                        <Select
                            value={getImportMode(this.getDataTypes('DATA'))}
                            options={getIntegrationModes()}
                            label={ i18n.dataIntegration }
                            labelSpan={ integrationModeHelpIcon() }
                            onChange={this.handleChangeDataIntegration}
                            {...disabled}
                        />
                    </div>
                    <div className='col s4'>
                        <Select
                            value={campaignId}
                            keyValue='id'
                            options={this.props.qualityCampaigns.filter(cs => cs.campaignType === 3)}
                            label={i18n.campaign}
                            onChange={v => this.onChangeParameter({ campaignId: v })}
                            {...disabled}
                        />
                    </div>
                    <MultiContributorsAutocomplete
                        col={4}
                        multiple
                        options={this.props.contributors}
                        values={filters.excludedProducer}
                        label={i18n.producersExcluded}
                        onChange={values => {
                            this.onChangeFilters({ excludedProducers: values })
                        }}
                        {...disabled}
                    />
                </div>
                <div className='row no-margin valign-wrapper'>
                    <div className='col s3'>
                        <Checkbox
                            checked={rename}
                            label={i18n.fileRenaming}
                            onChange={v => this.onChangeParameter({ rename: v })}
                            {...disabled}
                        />
                    </div>
                    <div className='col s3'>
                        <Checkbox
                            checked={create}
                            label={i18n.noStationCreate}
                            onChange={v => this.onChangeParameter({ create: v })}
                            {...disabled}
                        />
                    </div>
                    <div className='col s3'>
                        <Checkbox
                            checked={validation}
                            label={`${i18n.formatValidationxsd} : ddass_distr.xsd` }
                            onChange={v => this.onChangeParameter({ validation: v })}
                            {...disabled}
                        />
                    </div>
                    <div className='col s3'>
                        <Checkbox
                            checked={integrationHisto}
                            label={i18n.useAqua6BO}
                            onChange={v => this.onChangeParameter({ integrationHisto: v })}
                            {...disabled}
                        />
                    </div>
                </div>
                <br />
                <Row className='valign-wrapper'>
                    <Input col={ 4 } title={i18n.selectDirectory} value={directory} obligatory onChange={e => this.onChangeParameter({ directory: e })} {...disabled}/>
                    <div className='col s2'>
                        <a className='btn btn-small secondary-color-back ' onClick={ () => this.setAdvancedFtpModal()} ><span title={ i18n.importLabelFtp }> <i className='material-icons'>input </i> </span></a>
                    </div>
                    <Input col={ 4 } title={i18n.filter} value={filter} obligatory onChange={e => this.onChangeParameter({ filter: e })} {...disabled}/>

                    <RadioButtons col={4} elements={getWithPointeList} selected={withPoint}
                        onChange={v => this.onChangeParameter({ withPoint: v })}
                        title={i18n.withPoint} {...disabled}
                    />
                </Row>

                <ImportQualityDefaultValue
                    defaultValue={defaultValue}
                    onChange={obj => this.onChangeParameter(obj)}
                    isEditMode={this.props.isEditMode}
                />
            </div>
        )
    }
}

ImportDdassDistriPanel.propTypes = {
    job: PropTypes.instanceOf(Job).isRequired,
    isEditMode: PropTypes.bool,
    handleChangeParameters: PropTypes.func.isRequired,
    onChangeDataTypes: PropTypes.func.isRequired,
    contributors: PropTypes.arrayOf(PropTypes.instanceOf(ContributorDto)),
    qualifications: PropTypes.arrayOf(PropTypes.instanceOf(DtoQualification)),
    status: PropTypes.arrayOf(PropTypes.instanceOf(DtoStatus)),
    fractions: PropTypes.arrayOf(PropTypes.instanceOf(FractionDto)),
    supports: PropTypes.arrayOf(PropTypes.instanceOf(SupportDto)),
    qualityCampaigns: PropTypes.arrayOf(PropTypes.instanceOf(CampaignDto)),
    fetchContributors: PropTypes.func,
    fetchQualifications: PropTypes.func,
    fetchStatus: PropTypes.func,
    fetchFractions: PropTypes.func,
    fetchSupports: PropTypes.func,
    fetchQualityCampaigns: PropTypes.func,
    onChangeJob: PropTypes.func,
}

const mapStateToProps = store => ({
    contributors: store.ContributorReducer.contributors,
    qualifications: store.QualityReducer.qualifications,
    status: store.QualityReducer.status,
    fractions: store.FractionReducer.fractions,
    supports: store.SupportReducer.supports,
    qualityCampaigns: store.QualityReducer.qualityCampaigns,
})

const mapDispatchToProps = {
    fetchContributors: ContributorAction.fetchContributors,
    fetchQualifications: QualityAction.fetchQualifications,
    fetchStatus: QualityAction.fetchStatus,
    fetchFractions: FractionAction.fetchFractions,
    fetchSupports: SupportAction.fetchSupports,
    fetchQualityCampaigns: CampaignAction.fetchQualityCampaigns,
}

export default connect(mapStateToProps, mapDispatchToProps)(ImportDdassDistriPanel)
