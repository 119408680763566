import { Grid, Button } from '@mui/material'
import PropTypes from 'prop-types'
import { getApplyDays, getDayDisplay } from 'exploitations/utils/AgriUtils'
import React, { useEffect, useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import Textarea from 'components/forms/Textarea'
import RestictionsDisplay from './RestrictionsDisplay'
import DtoWaterTurnsSlot from 'exploitations/dto/DtoWaterTurnsSlot'
import EditToolDisplay from 'exploitations/components/waterTurns/EditToolDisplay'
import { DialogActionsMUI, DialogContentMUI, DialogMUI, DialogTitleMUI } from 'components/styled/Dialog'
import { StyledFieldSet, StyledLegend } from 'components/StyledElements'
import Icon from 'components/icon/Icon'
import { hasValue } from 'utils/NumberUtil'
import { range } from 'lodash'

const UpdateWaterTurnsDialog = ({
    selectedWaterTurn = {},
    updateWaterTurns = () => { },
    colors = [],
    title = '',
    open = false,
    setOpen = () => { },
    slot = {},
}) => {
    const [colorInput, setColorInput] = useState('0'.repeat(slot.nbSlots))
    const [selectionTool, setSelectionTool] = useState(0)
    const [waterTurn, setWaterTurn] = useState(selectedWaterTurn)

    useEffect(() => {
        if (hasValue(selectedWaterTurn.id) || hasValue(selectedWaterTurn.index)) {
            setWaterTurn(selectedWaterTurn)
        }
    }, [selectedWaterTurn])

    const {
        restrictions,
    } = useSelector(store => ({
        restrictions: store.AgriReducer.restrictions,
    }), shallowEqual)

    const onApply = () => {
        const days = getApplyDays(selectionTool, colorInput)
        const newData = {
            ...waterTurn,
            ...days,
        }
        setWaterTurn(newData)
    }

    const getModalDayDisplay = (day, value) => {
        const slotsDisplay = range(slot.nbSlots)
        return (
            <Grid item justifyContent='center' key={`day_${day}`}>
                <Grid style={{ padding: '15 0' }} item>
                    {i18n[day]} : {getDayDisplay(value, slotsDisplay, colors)}
                </Grid>
            </Grid>
        )
    }

    return (
        <DialogMUI maxWidth='lg' fullWidth open={open}>
            <DialogTitleMUI>
                <Grid container justifyContent='space-between' alignItems='center'>
                    <Grid item >
                        {title}
                    </Grid>
                    <Grid item>
                        <Icon style={{ color: 'white' }} size='small' icon={'close'} onClick={() => setOpen(false)} />
                    </Grid>
                </Grid>
            </DialogTitleMUI>
            <DialogContentMUI>
                <Grid item xs={12} className='padding-top-1'>
                    <StyledFieldSet>
                        <StyledLegend>{i18n.restrictions}</StyledLegend>
                        <RestictionsDisplay restrictions={restrictions} />
                    </StyledFieldSet>
                    <Grid item xs={12}>
                        <EditToolDisplay
                            colorInput={colorInput}
                            setColorInput={setColorInput}
                            slot={slot}
                            colors={colors}
                            selectionTool={selectionTool}
                            setSelectionTool={setSelectionTool}
                            onApply={onApply}
                        />
                    </Grid>
                    <StyledFieldSet>
                        <StyledLegend>{i18n.waterTurns}</StyledLegend>
                        <Grid container justifyContent='space-evenly'>
                            {getModalDayDisplay('monday', waterTurn?.day1)}
                            {getModalDayDisplay('tuesday', waterTurn?.day2)}
                            {getModalDayDisplay('wednesday', waterTurn?.day3)}
                            {getModalDayDisplay('thursday', waterTurn?.day4)}
                            {getModalDayDisplay('friday', waterTurn?.day5)}
                            {getModalDayDisplay('saturday', waterTurn?.day6)}
                            {getModalDayDisplay('sunday', waterTurn?.day7)}
                        </Grid>
                        <Textarea
                            title={i18n.comment}
                            value={waterTurn.comment}
                            onChange={(v) => {
                                setWaterTurn({
                                    ...waterTurn,
                                    comment: v,
                                })
                            }}
                        />
                    </StyledFieldSet>
                </Grid>
            </DialogContentMUI>
            <DialogActionsMUI>
                <Button
                    variant='contained'
                    onClick={() => {
                        updateWaterTurns(waterTurn)
                        setOpen(false)
                    }}
                >
                    {i18n.validate}
                </Button>
            </DialogActionsMUI>
        </DialogMUI>
    )
}

UpdateWaterTurnsDialog.propTypes = {
    colors: PropTypes.shape({}),
    selectedWaterTurn: PropTypes.shape({}),
    title: PropTypes.string,
    slot: PropTypes.instanceOf(DtoWaterTurnsSlot),
    open: PropTypes.bool,
    updateWaterTurns: PropTypes.func,
    setOpen: PropTypes.func,
}

export default UpdateWaterTurnsDialog