import { getFullDateMini } from '../../utils/DateUtil'

export default class DtoVigilance {
    constructor(obj = {}) {
        this.country = obj.country
        this.locationName = obj.locationName
        this.locationCode = obj.locationCode
        this.locationCodeME = obj.locationCodeME
        this.start = getFullDateMini(obj.start)
        this.end = getFullDateMini(obj.end)
        this.info = obj.info
        this.vigilance = obj.vigilance
        this.typeId = obj.typeId
        this.url = obj.url
        this.texts = obj.texts
    }
}