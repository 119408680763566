import { Button, Dialog } from '@mui/material'
import SieauParameterDto from 'administration/dto/SieauParameterDto'
import Checkbox from 'components/forms/Checkbox'
import Input from 'components/forms/Input'
import Icon from 'components/icon/Icon'
import { DialogActionsMUI, DialogContentMUI, DialogTitleMUI } from 'components/styled/Dialog'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import ToastrAction from 'toastr/actions/ToastrAction'
import { getSetting } from 'utils/SettingUtils'
import { arrayOf } from 'utils/StoreUtils'
import DtoCreateUser from '../dto/DtoCreateUser'

class AddUserModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            user: new DtoCreateUser({}),
        }
    }

    onAddUser = () => {
        const { user } = this.state
        if (!user.name) {
            this.props.warning(i18n.thanksToEnterName)
        }
        if (!user.email) {
            this.props.warning(i18n.thanksToEnterEmail)
        }
        if (!user.login) {
            this.props.warning(i18n.thanksToEnterLogin)
        } else {
            this.props.addUser(user)
        }
    }

    changeUserState = (key, value) => {
        this.setState(({ user }) => ({ user: { ...user, [key]: value } }))
    }

    render() {
        const { applicationSettings, dontSendMail } = this.props
        const { user } = this.state
        const required = { required: 'required' }
        const sendMailSetting = getSetting(applicationSettings, 'dontSendMailOnCreateUser')
        return (
            <Dialog
                onClose={this.props.onClose}
                fullWidth
                maxWidth='lg'
                open={this.props.isOpen}
            >
                <DialogTitleMUI style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    {i18n.addUser}
                    <Icon style={{ color: 'white' }} size='small' icon='close' onClick={this.props.onClose} />
                </DialogTitleMUI>
                <DialogContentMUI>
                    <div>
                        <div className='row no-margin padding-top-3'>
                            <div className='col s4 offset-s4'>
                                <Input
                                    title={i18n.name}
                                    value={user.name}
                                    onChange={(v) => this.changeUserState('name', v)}
                                    otherInputProps={required}
                                />
                            </div>
                        </div>
                        <div className='row no-margin padding-top-3'>
                            <div className='col s4 offset-s4'>
                                <Input
                                    title={i18n.login}
                                    value={user.login}
                                    onChange={(v) => this.changeUserState('login', v.normalize('NFD').replace(/[\u0300-\u036f]/g, ''))}
                                    otherInputProps={required}
                                />
                            </div>
                        </div>
                        <div className='row no-margin padding-top-3'>
                            <div className='col s4 offset-s4'>
                                <Input
                                    title={i18n.email}
                                    value={user.email}
                                    onChange={(v) => this.changeUserState('email', v)}
                                    otherInputProps={required}
                                />
                            </div>
                        </div>
                        {(!sendMailSetting || sendMailSetting === 'NON') && (
                            <div className='row no-margin padding-top-3'>
                                <div className='col s6 offset-s4'>
                                    <Checkbox
                                        col={12}
                                        label={i18n.notSendPasswordResetLink}
                                        checked={dontSendMail}
                                        onChange={(e) => this.props.onChangeSendMail(e)}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                </DialogContentMUI>
                <DialogActionsMUI>
                    <Button onClick={this.onAddUser} variant='contained' color='primary'>
                        {i18n.validate}
                    </Button>
                </DialogActionsMUI>
            </Dialog>
        )
    }
}

AddUserModal.propTypes = {
    warning: PropTypes.func,
    onClose: PropTypes.func,
    addUser: PropTypes.func,
    isOpen: PropTypes.bool,
    dontSendMail: PropTypes.bool,
    isDuplicate: PropTypes.bool,
    onChangeSendMail: PropTypes.func,
    applicationSettings: arrayOf(SieauParameterDto),
}

const mapDispatchToProps = {
    warning: ToastrAction.warning,
}

const mapStateToProps = store => ({
    applicationSettings: store.AdministrationReducer.applicationSettings,
})

export default connect(mapStateToProps, mapDispatchToProps)(AddUserModal)