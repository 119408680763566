/* eslint-disable camelcase */
import { Button, Grid2, Icon } from '@mui/material'
import { styled } from '@mui/styles'
import AgriAction from 'agriAdministration/actions/AgriAction'
import Input from 'components/forms/Input'
import ProgressCard from 'components/card/ProgressCard'
import { AccordionDetailsMUI, AccordionMUI, AccordionSummaryMUI } from 'components/styled/Accordions'
import { push } from '@lagunovsky/redux-react-router'
import DtoExploitation from 'exploitations/dto/DtoExploitation'
import InstallationAction from 'installation/actions/InstallationAction'
import DtoInstallationWithGeoItem from 'installation/components/installations/dto/DtoInstallationWithGeoItem'
import { compact, orderBy } from 'lodash'
import VariousMaterielDto from 'materiel/components/variousMateriel/dto/VariousMaterielDto'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import DtoDeclaration from 'survey/dto/DtoDeclaration'
import ToastrAction from 'toastr/actions/ToastrAction'
import { formatMilliers, getI18nTitleData, searchAllCharacters } from 'utils/StringUtil'
import ModalAddPoint from '../modals/ModalAddPoint'

const ButtonStep = styled(Button)({
    width: '100%',
})

class DeclarationPointsStep extends Component {
    constructor(props) {
        super(props)
        this.state = {
            editMode: false,
            openModal: false,
            selectedPoints: [],
            points: [],
            pointsLoaded: false,
            searchValue: null,
        }
    }

    componentDidMount() {
        this.props.fetchExploitationUser().then(() => {
            const { installationsWithGeo } = this.props
            if (!installationsWithGeo.length) {
                this.props.fetchInstallationsWithGeo().then(() => this.setInstallations())
            } else {
                this.setInstallations()
            }
        })
    }

    setInstallations = () => {
        const { installationsWithGeo, declaration, citiesIndex } = this.props
        const exploitPoints = declaration.link_exploitationInstallation.map((linkPoint) => {
            const point = installationsWithGeo.find((i) => i.id === linkPoint.idInstallation)
            if (point) {
                const city = citiesIndex[point.townCode] || {}
                return {
                    ...point,
                    cityName: city.name,
                }
            }
            return null
        })
        const orderedPoints = orderBy(compact(exploitPoints), ['townCode', 'cityName', 'parcel', 'section', 'code'])
        const selectedPoints = declaration.link_declarationInstallation || []
        this.setState({ points: orderedPoints, selectedPoints, pointsLoaded: true })
    }

    getPointCard = (installation) => {
        const { params } = this.props
        if (installation) {
            let total = 0
            installation.link_usages.forEach((u) => {
                total = total + (isNaN(parseInt(u.requestedYearVolume)) ? 0 : u.requestedYearVolume)
            })
            return (
                <Grid2
                    key={installation.id}
                    container
                    className='clickable'
                    direction='row'
                    justifyContent='flex-start'
                    sx={{ padding: '10px 20px', borderBottom: '1px solid lightgrey' }}
                    onClick={() => this.props.push(`/survey/${params.id}/declaration/${params.idDeclaration}/point/${installation.id}`)}
                >
                    <Grid2 size={11} className='bold' sx={{ marginBottom: '5px' }}>
                        {installation.cityName || ''}
                    </Grid2>
                    <Grid2 size={1}>
                        <Icon>edit</Icon>
                    </Grid2>
                    <Grid2 size={11}>
                        {installation.location || ''}
                    </Grid2>
                    <Grid2 size={11} sx={{ marginBottom: '5px' }}>
                        {`${installation.code || ''} ${`- ${installation.parcel || ''} ${installation.section || ''}`}${installation.name ? ` - ${installation.name}` : ''}`}
                    </Grid2>
                    <Grid2 size={11}>
                        {`${i18n.requestedVolume} : ${formatMilliers(total) || 0} m3`}
                    </Grid2>
                    {installation.stateCode === 2 &&
                        <Grid2 size={1}>
                            <Icon>delete</Icon>
                        </Grid2>
                    }
                </Grid2>
            )
        }
        this.props.warning('Une erreur est survenue lors de la récupération des points de prélèvement. Veuillez contactez l\'administrateur.')
        return ''
    }

    getFormattedPoint = (point) => {
        const { installationsWithGeo, citiesIndex, declaration, variousMateriels } = this.props
        const installation = installationsWithGeo.find((i) => i.id === point.idInstallation)
        if (installation) {
            const city = citiesIndex[installation.townCode] || {}
            const link_pumps = declaration.link_exploitationMateriel.filter((m) => m.siteType === 7 && m.siteCode === installation.id)
            const link_counters = declaration.link_exploitationMateriel.filter((m) => m.siteType === 8 && link_pumps.find((lp) => lp.idVarious === m.siteCode))
            const matsName = [
                ...link_counters.map((lc) => lc.siteName),
                ...link_counters.map((lc) => (variousMateriels.find((m) => m.id === lc.idVarious) || {}).reference),
            ].reduce((acc, code) => `${acc ? `${acc}, ` : ''}${code || ''}`, '')
            let total = 0
            point.link_usages.forEach((u) => {
                total = total + (isNaN(parseInt(u.requestedYearVolume)) ? 0 : u.requestedYearVolume)
            })
            return {
                ...point,
                ...installation,
                stateCode: point.stateCode,
                link_usages: point.link_usages,
                cityName: city.name,
                matsName,
            }
        }
        this.props.warning('Une erreur est survenue lors de la récupération des points de prélèvement. Veuillez contactez l\'administrateur.')
        return null
    }

    getListPoints = (points, singular, plurial, open) => {
        const formattedPoints = compact(this.getFilteredData(points.map((p) => this.getFormattedPoint(p))))
        const orderedPoints = orderBy(compact(formattedPoints), ['townCode', 'cityName', 'parcel', 'section', 'code'])
        return (
            <Grid2 size={12} className='margin-bottom-1 padding-1'>
                <AccordionMUI defaultExpanded={open}>
                    <AccordionSummaryMUI>
                        <span style={{ fontSize: '1.5rem' }}>{points.length} {`${getI18nTitleData(singular, plurial, points)}`}</span>
                    </AccordionSummaryMUI>
                    <AccordionDetailsMUI nopadding>
                        {orderedPoints.map((point) => this.getPointCard(point))}
                    </AccordionDetailsMUI>
                </AccordionMUI>
            </Grid2>
        )
    }

    addPoint = (selectedPoints) => {
        this.setState({ selectedPoints })
        this.props.onChangeDeclaration('link_declarationInstallation', selectedPoints)
    }

    toggleModal = () => {
        const { openModal } = this.state
        this.setState({ openModal: !openModal })
    }

    getFilteredData = (data) => {
        const { searchValue } = this.state
        const includesValue = searchAllCharacters(searchValue || '')
        return data.filter(i => this.getHash(i).includes(includesValue))
    }

    getHash = (point) => {
        return searchAllCharacters(['townCode', 'location', 'name', 'cityName', 'parcel', 'section', 'code', 'matsName'].map(key => point[key]))
    }

    render() {
        const { openModal, selectedPoints, points, pointsLoaded, searchValue } = this.state
        const { declaration, exploitation, dataLoaded, readMode } = this.props
        const usedPoints = selectedPoints.filter((p) => p.stateCode !== 2 && p.stateCode !== 3)
        const toDeletePoints = selectedPoints.filter((p) => p.stateCode === 2)
        const toAdjournPoints = selectedPoints.filter((p) => p.stateCode === 3)

        return (
            <Grid2
                container
                direction='column'
                justifyContent='space-between'
                alignItems='stretch'
                sx={{ paddingBottom: '60px' }}
                size={12}
            >
                <Grid2
                    container
                    justifyContent='center'
                    className='padding-top-1'
                    sx={{ overflowY: 'auto', paddingBottom: '60px' }}
                    size={12}
                >
                    {pointsLoaded && dataLoaded ? (
                        <>
                            <Grid2 size={4}>
                                <Input
                                    col={12}
                                    title={ i18n.search }
                                    value={ searchValue }
                                    onChange={(v) => this.setState({ searchValue: v })}
                                />
                            </Grid2>
                            {this.getListPoints(usedPoints, i18n.usedPoint, i18n.usedPoints, true)}
                            {this.getListPoints(toAdjournPoints, i18n.adjournPoint, i18n.adjournPoints)}
                            {this.getListPoints(toDeletePoints, i18n.deletePoint, i18n.deletePoints)}
                        </>
                    ) : (
                        <Grid2 size={12} className='padding-1'>
                            <ProgressCard indeterminate withMessage round />
                        </Grid2>
                    )}
                </Grid2>
                {openModal && (
                    <ModalAddPoint
                        open={openModal}
                        toggleModal={this.toggleModal}
                        addPoint={this.addPoint}
                        selectedPoints={selectedPoints}
                        installationPoints={points}
                        declaration={declaration}
                        exploitation={exploitation}
                    />
                )}
                <Grid2
                    container
                    direction='row'
                    justifyContent='space-around'
                    alignItems='center'
                    sx={{
                        position: 'fixed',
                        bottom: 0,
                        height: '60px',
                        backgroundColor: 'white',
                        borderTop: '1px solid grey',
                        width: 'calc(100% - 60px)',
                    }}
                >
                    <Grid2 size={2}>
                        <ButtonStep variant='contained' color='primary' onClick={this.props.previousStep}>
                            <Icon>keyboard_arrow_left</Icon>
                        </ButtonStep>
                    </Grid2>
                    <Grid2 size={2}>
                        <ButtonStep
                            variant='contained'
                            color='primary'
                            onClick={this.toggleModal}
                            disabled={readMode}
                        >
                            {i18n.addNewSamplingPoint}
                        </ButtonStep>
                    </Grid2>
                    <Grid2 size={2}>
                        <ButtonStep variant='contained' color='primary' onClick={this.props.nextStep}>
                            <Icon>keyboard_arrow_right</Icon>
                        </ButtonStep>
                    </Grid2>
                </Grid2>
            </Grid2>
        )
    }
}

DeclarationPointsStep.propTypes = {
    params: PropTypes.shape({
        id: PropTypes.string,
        idDeclaration: PropTypes.string,
    }),
    declaration: PropTypes.instanceOf(DtoDeclaration).isRequired,
    onChangeDeclaration: PropTypes.func.isRequired,
    exploitation: PropTypes.instanceOf(DtoExploitation),
    installationsWithGeo: PropTypes.arrayOf(PropTypes.instanceOf(DtoInstallationWithGeoItem)),
    variousMateriels: PropTypes.arrayOf(PropTypes.instanceOf(VariousMaterielDto)),
    citiesIndex: PropTypes.arrayOf(PropTypes.object),
    fetchExploitationUser: PropTypes.func,
    fetchInstallationsWithGeo: PropTypes.func,
    previousStep: PropTypes.func.isRequired,
    nextStep: PropTypes.func.isRequired,
    dataLoaded: PropTypes.bool,
    readMode: PropTypes.bool,
    warning: PropTypes.func,
    push: PropTypes.func,
}

const mapStateToProps = (store) => {
    return {
        exploitation: store.AgriReducer.exploitation,
        declaration: store.AgriReducer.declaration,
        installationsWithGeo: store.InstallationReducer.installationsWithGeo,
        citiesIndex: store.CityReducer.citiesIndex,
        variousMateriels: store.VariousMaterielReducer.variousMateriels,
    }
}

const mapDispatchToProps = {
    fetchExploitationUser: AgriAction.fetchExploitationUser,
    fetchInstallationsWithGeo: InstallationAction.fetchInstallationsWithGeo,
    warning: ToastrAction.warning,
    push,
}

export default connect(mapStateToProps, mapDispatchToProps)(DeclarationPointsStep)
