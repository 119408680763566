import { range } from 'lodash'
import moment from 'moment'

const getHydroMeasureLabel = type => {
    if (type === 'height') {
        return 'value'
    }
    return type
}

const findApproximateValue = (date = '', groupedMeasures) => {
    if (date.includes('/')) {
        return range(1, 11).reduce((acc, delta) => {
            if (acc.length) {
                return acc
            }
            const deltaDate = moment(date, 'DD/MM/YYYY').subtract(delta, 'days').format('DD/MM/YYYY')
            if (groupedMeasures[deltaDate]) {
                return [deltaDate, delta < 3 ? 'grey' : (delta < 6 ? 'orange' : 'red'), groupedMeasures[deltaDate]]
            }
            return acc
        }, [])
    }
    return []
}

export {
    findApproximateValue,
    getHydroMeasureLabel,
}