/* eslint-disable camelcase */
import React from 'react'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import { connect } from 'react-redux'
import { arrayOf, getSandreList } from '../../../../utils/StoreUtils'
import DtoSandreCode from '../../../../referencial/dto/DtoSandreCode'
import { SANDRE } from '../../../../referencial/constants/ReferencialConstants'
import SieauAction from '../../../../components/sieau/SieauAction'
import Row from '../../../../components/react/Row'
import NumberField from '../../../../components/forms/NumberField'
import DtoTankStation from '../dto/DtoTankStation'
import { getSetting } from '../../../../utils/SettingUtils'
import SieauParameterDto from '../../../../administration/dto/SieauParameterDto'
import Select from '../../../../components/forms/Select'
import { getVisitSandreSelectChange } from '../../../../utils/VisitUtils'

const TankCivilEngPanel = ({
    onChange = () => { },
    onChangeVisit = () => { },
    tank = {},
    readMode = false,
    sandreCodes = [],
    applicationSettings = [],
}) => {
    const onChangeCivilEng = (value) => {
        onChange({
            link_civilEngs: [
                {
                    idStation: tank.idStation,
                    ...tank.link_civilEngs[0],
                    ...value,
                },
            ],
        })
    }

    const civilEng = tank.link_civilEngs[0] || {}


    const isDisplayed = (panel) => {
        return getSetting(applicationSettings, `PANEL_12_${panel}`) !== 'hidden'
    }

    return (
        <Row className='padding-top-1 padding-bottom-1'>
            <Row>
                <Select
                    col={ 6 }
                    value={ civilEng.tankType }
                    label={ i18n.tankType }
                    keyValue='code'
                    options={ getSandreList(sandreCodes, SANDRE.INSTALLATIONS_TYPERESERVOIR) }
                    onChange={ (v) => {
                        onChangeCivilEng({ tankType: v })
                        onChangeVisit(getVisitSandreSelectChange(sandreCodes, SANDRE.INSTALLATIONS_TYPERESERVOIR, i18n.tankType, civilEng.tankType, v))
                    } }
                    integerValue
                    readMode={ readMode }
                />
                <NumberField
                    col={ 6 }
                    value={ civilEng.numberTanks }
                    title={ i18n.numberTanks }
                    onChange={ (v) => {
                        onChangeCivilEng({ numberTanks: v })
                        onChangeVisit({ previousValue: civilEng.numberTanks, newValue: v, field: i18n.numberTanks })
                    } }
                    readMode={ readMode }
                />
            </Row>
            <Row>
                <Select
                    col={ 6 }
                    value={ civilEng.coatingTanks }
                    label={ i18n.coatingTanks }
                    keyValue='code'
                    options={ getSandreList(sandreCodes, SANDRE.INSTALLATIONS_REVETEMENTCUVE) }
                    onChange={ (v) => {
                        onChangeCivilEng({ coatingTanks: v })
                        onChangeVisit(getVisitSandreSelectChange(sandreCodes, SANDRE.INSTALLATIONS_REVETEMENTCUVE, i18n.coatingTanks, civilEng.coatingTanks, v))
                    } }
                    integerValue
                    readMode={ readMode }
                />
                <NumberField col={6} title={i18n.overflowRating} value={civilEng.overflowRating}
                    onChange={v => {
                        onChangeCivilEng({ overflowRating: v })
                        onChangeVisit({ previousValue: civilEng.overflowRating, newValue: v, field: i18n.overflowRating })
                    }}
                    floatValue
                    readMode={readMode}
                />
            </Row>
            <Row>
                <NumberField
                    col={ 6 }
                    value={ civilEng.usefulVolume }
                    title={ i18n.usefulVolume }
                    onChange={ (v) => {
                        onChangeCivilEng({ usefulVolume: v })
                        onChangeVisit({ previousValue: civilEng.usefulVolume, newValue: v, field: i18n.usefulVolume })
                    } }
                    readMode={ readMode }
                />
                <NumberField
                    col={ 6 }
                    value={ civilEng.dedicatedVolume }
                    title={ i18n.dedicatedVolume }
                    onChange={ (v) => {
                        onChangeCivilEng({ dedicatedVolume: v })
                        onChangeVisit({ previousValue: civilEng.dedicatedVolume, newValue: v, field: i18n.dedicatedVolume })
                    } }
                    readMode={ readMode }
                />
            </Row>
            <Row>
                <NumberField
                    col={ 6 }
                    value={ civilEng.altiLevelTN }
                    title={ i18n.altiLevelTN }
                    onChange={ (v) => {
                        onChangeCivilEng({ altiLevelTN: v })
                        onChangeVisit({ previousValue: civilEng.altiLevelTN, newValue: v, field: i18n.altiLevelTN })
                    } }
                    readMode={ readMode }
                />
                <NumberField
                    col={ 6 }
                    value={ civilEng.radierTankLevel }
                    title={ i18n.radierTankLevel }
                    onChange={ (v) => {
                        onChangeCivilEng({ radierTankLevel: v })
                        onChangeVisit({ previousValue: civilEng.radierTankLevel, newValue: v, field: i18n.radierTankLevel })
                    } }
                    readMode={ readMode }
                />
            </Row>
            <Row>
                <NumberField
                    col={ 6 }
                    value={ civilEng.totalHeightTour }
                    title={ i18n.totalHeightTour }
                    onChange={ (v) => {
                        onChangeCivilEng({ totalHeightTour: v })
                        onChangeVisit({ previousValue: civilEng.totalHeightTour, newValue: v, field: i18n.totalHeightTour })
                    } }
                    readMode={ readMode }
                />
                <Select
                    col={ 6 }
                    value={ civilEng.fillingDevice }
                    label={ i18n.fillingDevice }
                    keyValue='code'
                    options={ getSandreList(sandreCodes, SANDRE.INSTALLATIONS_DISPOSITIFREMPLISSAGE) }
                    onChange={ (v) => {
                        onChangeCivilEng({ fillingDevice: v })
                        onChangeVisit(getVisitSandreSelectChange(sandreCodes, SANDRE.INSTALLATIONS_DISPOSITIFREMPLISSAGE, i18n.fillingDevice, civilEng.fillingDevice, v))
                    } }
                    integerValue
                    readMode={ readMode }
                />
            </Row>
            <Row>
                <NumberField
                    col={6}
                    value={civilEng.area}
                    title={i18n.aream2}
                    onChange={v => {
                        onChangeCivilEng({ area: v })
                        onChangeVisit({ previousValue: civilEng.area, newValue: v, field: i18n.surface })
                    }}
                    floatValue
                    readMode={readMode}
                />
                <Select
                    col={6}
                    value={civilEng.idUsage}
                    keyValue='code'
                    label={i18n.usage}
                    options={getSandreList(sandreCodes, SANDRE.RESERVOIRS_USAGES)}
                    onChange={(v) => {
                        onChangeCivilEng({ idUsage: v })
                        onChangeVisit(getVisitSandreSelectChange(sandreCodes, SANDRE.RESERVOIRS_USAGES, i18n.usage, civilEng.idUsage, v))
                    }}
                    integerValue
                    readMode={readMode}
                />
            </Row>
            <Row displayIf={isDisplayed('INFOS_COMPL')}>
                <Select
                    col={6}
                    value={civilEng.natureTanks}
                    keyValue='code'
                    label={i18n.natureTanks}
                    options={getSandreList(sandreCodes, SANDRE.INSTALLATIONS_NATUREEQUIPEMENT)}
                    onChange={(v) => {
                        onChangeCivilEng({ natureTanks: v })
                        onChangeVisit(getVisitSandreSelectChange(sandreCodes, SANDRE.INSTALLATIONS_NATUREEQUIPEMENT, i18n.natureTanks, civilEng.natureTanks, v))
                    }}
                    integerValue
                    readMode={readMode}
                />
            </Row>

            <Row>
                <NumberField
                    col={6}
                    value={civilEng.strainerRate}
                    title={i18n.strainerRate}
                    onChange={v => {
                        onChangeCivilEng({ strainerRate: v })
                        onChangeVisit({ previousValue: civilEng.strainerRate, newValue: v, field: i18n.strainerRate })
                    }}
                    floatValue
                    readMode={readMode}
                />
                <Select
                    col={6}
                    value={civilEng.tarpType}
                    keyValue='code'
                    label={i18n.tarpType}
                    options={getSandreList(sandreCodes, SANDRE.BACHE_TYPE)}
                    onChange={(v) => {
                        onChangeCivilEng({ tarpType: v })
                        onChangeVisit(getVisitSandreSelectChange(sandreCodes, SANDRE.BACHE_TYPE, i18n.tarpType, civilEng.tarpType, v))
                    }}
                    integerValue
                    readMode={readMode}
                />
            </Row>
            <Row>
                <NumberField
                    col={6}
                    value={civilEng.interiorRenovationYear}
                    title={i18n.interiorRenovationYear}
                    onChange={v => {
                        onChangeCivilEng({ interiorRenovationYear: v })
                        onChangeVisit({ previousValue: civilEng.interiorRenovationYear, newValue: v, field: i18n.interiorRenovationYear })
                    }}
                    readMode={readMode}
                />
                <NumberField
                    col={6}
                    value={civilEng.exteriorRenovationYear}
                    title={i18n.exteriorRenovationYear}
                    onChange={v => {
                        onChangeCivilEng({ exteriorRenovationYear: v })
                        onChangeVisit({ previousValue: civilEng.exteriorRenovationYear, newValue: v, field: i18n.exteriorRenovationYear })
                    }}
                    readMode={readMode}
                />
            </Row>
        </Row>
    )
}

TankCivilEngPanel.propTypes = {
    tank: PropTypes.instanceOf(DtoTankStation),
    sandreCodes: arrayOf(DtoSandreCode),
    readMode: PropTypes.bool,
    onChange: PropTypes.func,
    onChangeVisit: PropTypes.func,
    setPopup: PropTypes.func,
    applicationSettings: arrayOf(SieauParameterDto),
}

const mapStateToProps = (store) => ({
    sandreCodes: store.ReferencialReducer.sandreCodes,
    applicationSettings: store.AdministrationReducer.applicationSettings,
})

const mapDispatchTopProps = {
    setPopup: SieauAction.setPopup,
}

export default connect(mapStateToProps, mapDispatchTopProps)(TankCivilEngPanel)
