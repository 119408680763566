export default class ParameterGroupLinkDto {
    constructor(obj) {
        const {
            parameterCode,
            classGroupCode,
            usageGroupCode,
            usageGroupCode2,
            usageGroupCode3,
            usageGroupCode4,
            usageGroupCode5,
        } = obj

        this.parameterCode = parameterCode
        this.classGroupCode = classGroupCode
        this.usageGroupCode = usageGroupCode
        this.usageGroupCode2 = usageGroupCode2
        this.usageGroupCode3 = usageGroupCode3
        this.usageGroupCode4 = usageGroupCode4
        this.usageGroupCode5 = usageGroupCode5
    }
}