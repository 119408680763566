module.exports = {
    EXCLUDED_CRITERIAS: [
        'displayAdvancedStatistics',
        'displayAssociatedStations',
        'threshold',
        'thresholds',
        'selectionResults',
        'horizon',
        'regrouping',
        'piezometers',
        'hydrometers',
        'pluviometers',
        'calculateFlux',
        'displayInListMode',
        'groupEquivalences',
    ],
    HORIZON_LIST: [{ code: 'operations', label: 'byOperations' }, { code: 'year', label: 'perYear' }, { code: 'historic', label: 'perHistoric' }],
    REGROUPING_LIST: [{ code: 'max', label: 'max' }, { code: 'moyenne', label: 'average' }, { code: 'percentile', label: 'percentile90' }],
}
