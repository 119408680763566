import React, { Component } from 'react'
import PropTypes from 'prop-types'

class InputLogin extends Component {
    getIconInput = () => {
        if (this.props.iconInput) {
            return (
                <i
                    data-view-input
                    className={ 'material-icons clickable right' }
                    onClick={ () => this.props.onClickIcon() }
                >
                    { this.props.iconInput }
                </i>
            )
        }
        return null
    }

    render() {
        const iconsTag = ((icon) => {
            if (icon) {
                return <i className='padding-glyph material-icons'>{ this.props.icon }</i>
            }
            return ''
        })(this.props.icon)

        const required = ((r) => {
            if (r) {
                return 'required'
            }
            return ''
        })(this.props.icon)

        return (
            <div>
                <div className='row no-margin no-padding'>
                    <label htmlFor={ this.props.refChild } className='center-align'>
                        { iconsTag }
                        { this.props.children }
                    </label>
                </div>
                <div className={ `row no-margin no-padding${this.props.onClickIcon ? ' visibility-input' : ''}` }>
                    { this.getIconInput() }
                    <input
                        id={ this.props.refChild }
                        name={ this.props.refChild }
                        ref={ this.props.refChild }
                        data-cy={this.props.refChild}
                        type={ this.props.type }
                        maxLength={this.props.maxLength}
                        className='required input-field col s12 no-padding'
                        required={ required }
                    />
                </div>
            </div>
        )
    }
}

InputLogin.propTypes = {
    iconInput: PropTypes.string,
    icon: PropTypes.string,
    type: PropTypes.string.isRequired,
    children: PropTypes.string.isRequired,
    refChild: PropTypes.string.isRequired,
    required: PropTypes.bool,
    maxLength: PropTypes.number,
    onClickIcon: PropTypes.func,
}

export default InputLogin