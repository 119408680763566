import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import ToastrAction from 'toastr/actions/ToastrAction'
import { sieauTooltip } from '../../utils/FormUtils'

const ExportList = ({
    exportList = [],
    toastrError = () => { },
}) => {
    if (exportList.length > 1) {
        const exportComponents = exportList.map(({ label, onClick }) => (
            <li key={label}>
                <a onClick={onClick}>
                    <div className='row no-margin valign-wrapper dropdownLink collection-item'>
                        <div className='col s12'>
                            <b>{label || ''}</b>
                        </div>
                    </div>
                </a>
            </li>
        ))
        return (
            <div className='action-wrapper'>
                <ul id='dropdownExports' className='dropdown-content dropdownLinks' style={{ overflowY: 'hidden' }}>
                    {exportComponents}
                </ul>
                <a
                    className='right waves-effect nav-actions blue-arrow dropdown-button'
                    data-activates='dropdownExports'
                    {...sieauTooltip(i18n.export, 'export_list_action_navbar', 'bottom')}
                >
                    <i className='material-icons left no-margin'>file_download</i>
                </a>
            </div>
        )
    }
    return (
        <div className='action-wrapper'>
            <a
                className={'right waves-effect nav-actions blue-arrow'}
                {...sieauTooltip(i18n.export, 'export_list_action_navbar', 'bottom')}
                onClick={exportList.length ? exportList[0].onClick : () => toastrError(i18n.noData)}
            >
                <i className='material-icons left no-margin'>file_download</i>
            </a>
        </div>
    )
}

ExportList.propTypes = {
    exportList: PropTypes.arrayOf(PropTypes.shape({
        onClick: PropTypes.func,
        label: PropTypes.string,
    })).isRequired,
    toastrError: PropTypes.func,
}

const mapDispatchToProps = {
    toastrError: ToastrAction.error,
}

export default connect(null, mapDispatchToProps)(ExportList)
