export default class DtoUserLight {
    constructor(obj) {
        this.login = obj[0]
        this.isAdmin = obj[1]
        this.contributorCode = obj[2]
        this.metadata = obj[3]
        this.contactCode = obj[4]
        this.labo = obj[5]
        this.startDate = obj[6]
        this.endDate = obj[7]
        this.updateDate = obj[8]
        this.loginMaj = obj[9]
        this.consultant = obj[10]
        this.isApplicative = obj[11]
    }
}