module.exports = {
    PARAMETER: 'parameter',
    CLASS_GROUP: 'ClassGroup',
    USAGE_GROUP: 'UsageGroup',
    RECEIVE_PARAMETER: 'PARAMETER',
    RECEIVE_PARAMETERS: 'RECEIVE_PARAMETERS',
    RECEIVE_PARAMETERS_QUALITOMETERS: 'PARAMETERS_QUALITOMETERS',
    RESET_PARAMETER: 'RESET_PARAMETER',
    RECEIVE_SELECTIONS: 'RECEIVE_SELECTIONS',
    RECEIVE_SELECTIONS_WITH_PARAM: 'RECEIVE_SELECTIONS_WITH_PARAM',
    RECEIVE_SELECTION_RESULTS: 'RECEIVE_SELECTION_RESULTS',
    RECEIVE_PARAMETER_GROUPS: 'RECEIVE_PARAMETER_GROUPS',
    RECEIVE_PARAMETER_GROUP_LINKS: 'RECEIVE_PARAMETER_GROUP_LINKS',
    RECEIVE_PARAMETER_GROUP_USAGE: 'RECEIVE_PARAMETER_GROUP_USAGE',
    PARAMETER_NATURES: {
        CHEMICAL: 0,
        MICROBIOLOGICAL: 1,
        PHYSICAL: 2,
    },
    PARAMETER_NATURES_DATA: [
        {
            code: 0,
            name: 'Chimique',
        },
        {
            code: 1,
            name: 'Microbiologique',
        },
        {
            code: 2,
            name: 'Physique',
        },
    ],
    PANEL_DESCRIPTION: 'PANEL_DESCRIPTION',
    PANEL_MAP: 'PANEL_MAP',
    PANEL_SOURCE: 'PANEL_SOURCE',
}
