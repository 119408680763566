import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import Checkbox from '../../../../components/forms/Checkbox'
import Input from '../../../../components/forms/Input'
import NumberField from '../../../../components/forms/NumberField'
import Select from '../../../../components/forms/Select'
import Row from '../../../../components/react/Row'
import SieauAction from '../../../../components/sieau/SieauAction'
import { SANDRE } from '../../../../referencial/constants/ReferencialConstants'
import DtoSandreCode from '../../../../referencial/dto/DtoSandreCode'
import { getDate } from '../../../../utils/DateUtil'
import { onChangeDate } from '../../../../utils/FormUtils'
import { arrayOf, getSandreList } from '../../../../utils/StoreUtils'
import {
    getVisitCheckBoxChange,
    getVisitDatePickerChange,
    getVisitSandreSelectChange,
} from '../../../../utils/VisitUtils'
import DtoInstallationBorehole from '../../../dto/borehole/DtoInstallationBorehole'

const BoreholeManagementPanel = ({
    onChange = () => { },
    onChangeVisit = () => { },
    borehole = {},
    readMode = false,
    sandreCodes = [],
}) => {
    const onChangeBorehole = (value) => {
        onChange({
            link_managements: [
                {
                    idStation: borehole.id,
                    ...borehole.link_managements[0],
                    ...value,
                },
            ],
        })
    }

    const management = borehole.link_managements[0] || {}

    return (
        <div className='row no-margin padding-top-1 padding-bottom-1'>
            <Row>
                <Checkbox
                    col={ 12 }
                    checked={ management.monitoringProgram }
                    label={ i18n.monitoringProgram }
                    onChange={ (v) => {
                        onChangeBorehole({ monitoringProgram: v })
                        onChangeVisit(getVisitCheckBoxChange(i18n.monitoringProgram, management.monitoringProgram, v))
                    } }
                    disabled={ readMode }
                />
            </Row>
            <Row>
                <NumberField
                    col={ 12 }
                    value={ management.lowFlowReduction }
                    title={ i18n.lowFlowReduction }
                    onChange={ (v) => {
                        onChangeBorehole({ lowFlowReduction: v })
                        onChangeVisit({ previousValue: management.lowFlowReduction, newValue: v, field: i18n.lowFlowReduction })
                    } }
                    readMode={ readMode }
                />
            </Row>
            <Row>
                <Input
                    col={ 6 }
                    value={ getDate(management.hydroNoticeDate) }
                    title={ i18n.hydroNoticeDate }
                    onChange={ v => {
                        onChangeDate(v, v2 => onChangeBorehole({ hydroNoticeDate: v2 }))
                        onChangeVisit(getVisitDatePickerChange(i18n.hydroNoticeDate, management.hydroNoticeDate, v))
                    } }
                    readMode={ readMode }
                />
                <Input
                    col={ 6 }
                    value={ getDate(management.CODERSTDate) }
                    title={ i18n.CODERSTDate }
                    onChange={ v => {
                        onChangeDate(v, v2 => onChangeBorehole({ CODERSTDate: v2 }))
                        onChangeVisit(getVisitDatePickerChange(i18n.CODERSTDate, management.CODERSTDate, v))
                    } }
                    readMode={ readMode }
                />
            </Row>
            <Row>
                <Input
                    col={ 6 }
                    value={ getDate(management.DUPdecreeDate) }
                    title={ i18n.DUPdecreeDate }
                    onChange={ v => {
                        onChangeDate(v, v2 => onChangeBorehole({ DUPdecreeDate: v2 }))
                        onChangeVisit(getVisitDatePickerChange(i18n.DUPdecreeDate, management.DUPdecreeDate, v))
                    } }
                    readMode={ readMode }
                />
            </Row>
            <Row>
                <Select
                    col={ 12 }
                    value={ management.advancementProc }
                    label={ i18n.advancementProc }
                    keyValue='code'
                    options={ getSandreList(sandreCodes, SANDRE.INSTALLATIONS_AVANCEMENTPROCEDURE) }
                    onChange={ (v) => {
                        onChangeBorehole({ advancementProc: v })
                        onChangeVisit(getVisitSandreSelectChange(sandreCodes, SANDRE.INSTALLATIONS_AVANCEMENTPROCEDURE, i18n.advancementProc, management.advancementProc, v))
                    } }
                    integerValue
                    readMode={ readMode }
                />
            </Row>
            <Row>
                <Checkbox
                    col={ 6 }
                    checked={ management.prescriptionFollowUp }
                    label={ i18n.prescriptionFollowUp }
                    onChange={ (v) => {
                        onChangeBorehole({ prescriptionFollowUp: v })
                        onChangeVisit(getVisitCheckBoxChange(i18n.prescriptionFollowUp, management.prescriptionFollowUp, v))
                    } }
                    disabled={ readMode }
                />
                <Checkbox
                    col={ 6 }
                    checked={ management.needRevision }
                    label={ i18n.needRevision }
                    onChange={ (v) => {
                        onChangeBorehole({ needRevision: v })
                        onChangeVisit(getVisitCheckBoxChange(i18n.needRevision, management.needRevision, v))
                    } }
                    disabled={ readMode }
                />
            </Row>
            <Row>
                <Select
                    col={ 6 }
                    value={ management.vulnerability }
                    label={ i18n.vulnerability }
                    keyValue='code'
                    options={ getSandreList(sandreCodes, SANDRE.INSTALLATIONS_VULNERABILITE) }
                    onChange={ (v) => {
                        onChangeBorehole({ vulnerability: v })
                        onChangeVisit(getVisitSandreSelectChange(sandreCodes, SANDRE.INSTALLATIONS_VULNERABILITE, i18n.vulnerability, management.vulnerability, v))
                    } }
                    integerValue
                    readMode={ readMode }
                />
            </Row>
        </div>
    )
}

BoreholeManagementPanel.propTypes = {
    borehole: PropTypes.instanceOf(DtoInstallationBorehole),
    sandreCodes: arrayOf(DtoSandreCode),
    readMode: PropTypes.bool,
    onChange: PropTypes.func,
    onChangeVisit: PropTypes.func,
    setPopup: PropTypes.func,
}

const mapStateToProps = (store) => ({
    sandreCodes: store.ReferencialReducer.sandreCodes,
})

const mapDispatchTopProps = {
    setPopup: SieauAction.setPopup,
}

export default connect(mapStateToProps, mapDispatchTopProps)(BoreholeManagementPanel)
