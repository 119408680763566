export default class DtoDetailOperation {
    constructor(obj) {
        //TODO : datefin
        this.qualitometer = obj.qualitometer
        this.id = obj.id
        this.date = obj.date
        this.length = obj.length
        this.mediumWidth = obj.mediumWidth
        this.interpretation = obj.interpretation
        this.qualification = obj.qualification
        this.status = obj.status
        this.comment = obj.comment
        this.methodOfPrincipalConservation = obj.methodOfPrincipalConservation
        this.methodOfSecondConservation = obj.methodOfSecondConservation
        this.wetArea = obj.wetArea
        this.particularSituation = obj.particularSituation
        this.point = obj.point
        this.determiner = obj.determiner
        this.producer = obj.producer
        this.sampler = obj.sampler
        this.support = obj.support
        this.method = obj.method
        this.speed = obj.speed
        this.shady = obj.shady
        this.continu = obj.continu
        this.resultComment = obj.resultComment
        this.microSampling = obj.microSampling
        this.seasonality = obj.seasonality
        this.localization = obj.localization
        this.producerContact = obj.producerContact
        this.samplerContact = obj.samplerContact
        this.determinerContact = obj.determinerContact
        this.jobExecutionId = obj.jobExecutionId
        this.analysisMethod = obj.analysisMethod
        this.accreditation = obj.accreditation
        this.authorizationBanking = obj.authorizationBanking
        this.campaign = obj.campaign
        this.updateDate = obj.updateDate
        this.updateLogin = obj.updateLogin
    }
}