import Tile from 'ol/layer/Tile'
import XYZ from 'ol/source/XYZ'
import Layers from 'utils/mapUtils/ILayers'

class _OSMRelief extends Layers {
    constructor() {
        super()
        this.layer = this.createLayer()
    }


    createLayer() {
        return new Tile({
            source: new XYZ({
                url: 'http://mt0.google.com/vt/lyrs=p&hl=en&x={x}&y={y}&z={z}',
            }),
        })
    }

    getLayer() {
        return this.layer
    }
}

export default function OSMRelief(...args) {
    return new _OSMRelief(...args)
}
