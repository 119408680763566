import { push } from '@lagunovsky/redux-react-router'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import ToastrAction from 'toastr/actions/ToastrAction'
import Checkbox from '../../../components/forms/Checkbox'
import Input from '../../../components/forms/Input'
import NumberField from '../../../components/forms/NumberField'
import Row from '../../../components/react/Row'
import HomeAction from '../../../home/actions/HomeAction'
import StationAction from '../../../station/actions/StationAction'
import DtoDataType from '../../../station/dto/DtoDataType'
import ColorPicker from '../../../components/forms/ColorPicker'
import Button from '../../../components/forms/Button'
import { genericPromise } from '../../../utils/ActionUtils'
import AppStore from '../../../store/AppStore'
import { setChoiceModal, setConfirmationModal } from '../../../utils/FormUtils'
import ApplicationConf from '../../../conf/ApplicationConf'
import WaitAction from '../../../wait/WaitAction'
import Select from '../../../components/forms/Select'
import { hasValue } from '../../../utils/NumberUtil'
import ParameterDto from '../../../referencial/components/parameter/dto/ParameterDto'
import ParameterAction from '../../../referencial/components/parameter/actions/ParameterAction'

class DataTypeApp extends Component {
    constructor(props) {
        super(props)
        this.state = {
            readMode: false,
        }
    }

    componentDidMount() {
        if (!this.props.parameters.length) {
            this.props.fetchParameters()
        }
    }

    onChange = (value) => {
        this.props.onChange(value)
    }

    recalculateNGF = (typeId) => {
        setConfirmationModal(() => {
            AppStore.dispatch(WaitAction.waitStart())
            genericPromise(ApplicationConf.piezometer.recalculateNGF(), 'POST', { id: typeId }).then(() => {
                AppStore.dispatch(WaitAction.waitStop())
                AppStore.dispatch(ToastrAction.success(i18n.parameterSuccessUpdated))
            })
        }, i18n.recalculateNGFMessage)
    }

    recalculateLandmarksAndRefAltis = (typeId) => {
        AppStore.dispatch(WaitAction.waitStart())
        genericPromise(ApplicationConf.piezometer.recalculateNGF(), 'POST', { id: typeId, recalculateLandmarksOnly: true }).then(() => {
            AppStore.dispatch(WaitAction.waitStop())
            AppStore.dispatch(ToastrAction.success(i18n.parameterSuccessUpdated))
        })
    }

    recalculateVolumes = (typeId) => {
        setConfirmationModal(() => {
            AppStore.dispatch(WaitAction.waitStart())
            genericPromise(ApplicationConf.piezometer.recalculateVolumes(), 'POST', { id: typeId }).then(() => {
                AppStore.dispatch(WaitAction.waitStop())
                AppStore.dispatch(ToastrAction.success(i18n.parameterSuccessUpdated))
            })
        }, i18n.recalculateVolumesMessage)
    }

    render() {
        const { dataType, parameters } = this.props
        const { readMode } = this.state

        const editable = !readMode && dataType.id >= 4
        return (
            <div className='padding-top-1'>
                <div className='col s10 offset-s1'>
                    <Row>
                        <Input
                            col={6}
                            title={i18n.name}
                            value={dataType.name}
                            onChange={v => this.onChange({ name: v })}
                            maxLength={35}
                            obligatory
                            disabled={!editable}
                        />
                        <NumberField
                            col={6}
                            title={i18n.code}
                            value={dataType.id}
                            onChange={v => this.onChange({ code: v })}
                            disabled
                        />
                    </Row>
                    <Row className='padding-top-1'>
                        <NumberField
                            col={6}
                            title={i18n.version}
                            value={dataType.version}
                            onChange={v => this.onChange({ version: v })}
                            disabled={readMode}
                        />
                        <Input
                            col={6}
                            title={i18n.project}
                            value={dataType.project}
                            onChange={v => this.onChange({ project: v })}
                            maxLength={10}
                            disabled
                        />
                    </Row>
                    <Row className='padding-top-1'>
                        <Checkbox
                            col={6}
                            label={i18n.showDataDashboard}
                            checked={dataType.showData}
                            onChange={check => this.onChange({ showData: check })}
                            disabled={readMode}
                        />
                        <ColorPicker
                            col={6}
                            label={i18n.color}
                            value={dataType.colorValue}
                            onChange={v => this.onChange({ color: v, colorValue: v })}
                            disabled={readMode}
                        />
                    </Row>
                    <Row className='padding-top-1'>
                        <Select
                            value={ dataType.lineType || 'solid' }
                            options={ [{ value: 'solid', label: 'Plein' }, { value: 'dashed', label: 'Traits pointillés' }, { value: 'dotted', label: 'Pointillés' }] }
                            label={ i18n.lineType }
                            col={ 2 }
                            onChange={v => this.onChange({ lineType: v })}
                            noNullValue
                            disabled={readMode}
                        />
                        <NumberField
                            col={2}
                            title={i18n.lineWidth}
                            value={dataType.lineWidth || 2 }
                            onChange={v => this.onChange({ lineWidth: v })}
                            disabled={readMode}
                            min={1}
                        />
                        <NumberField
                            col={2}
                            title={i18n.lineOpacity}
                            value={hasValue(dataType.lineOpacity) ? dataType.lineOpacity : 1 }
                            onChange={v => this.onChange({ lineOpacity: v })}
                            disabled={readMode}
                            min={0}
                            max={1}
                            floatValue
                        />
                    </Row>
                    <Row className='padding-top-1'>
                        <NumberField
                            col={6}
                            title={i18n.warningTimeLimit}
                            value={dataType.warningLimit}
                            onChange={v => this.onChange({ warningLimit: v })}
                            disabled={readMode}
                        />
                        <NumberField
                            col={6}
                            title={i18n.alertsTimeLimit}
                            value={dataType.alertLimit}
                            onChange={v => this.onChange({ alertLimit: v })}
                            disabled={readMode}
                        />
                    </Row>
                    <Row className='padding-top-1'>
                        <Input
                            col={6}
                            title={i18n.unit}
                            value={dataType.unit}
                            onChange={v => this.onChange({ unit: v })}
                            maxLength={35}
                            disabled={readMode}
                        />
                        <NumberField
                            col={6}
                            title={i18n.order}
                            value={dataType.order}
                            onChange={v => this.onChange({ order: v })}
                            disabled={readMode}
                        />
                    </Row>
                    <Row className='padding-top-1 valign-wrapper'>
                        <Checkbox
                            col={4}
                            label={i18n.calculMin}
                            checked={dataType.calculMin}
                            onChange={check => this.onChange({ calculMin: check })}
                            disabled={readMode}
                        />
                        <Checkbox
                            col={4}
                            label={i18n.calculMoy}
                            checked={dataType.calculMoy}
                            onChange={check => this.onChange({ calculMoy: check })}
                            disabled={readMode}
                        />
                        <Checkbox
                            col={4}
                            label={i18n.calculMax}
                            checked={dataType.calculMax}
                            onChange={check => this.onChange({ calculMax: check })}
                            disabled={readMode}
                        />
                    </Row>
                    <Row className='padding-top-1 valign-wrapper'>
                        <Checkbox
                            col={3}
                            label={i18n.indexMode}
                            checked={dataType.isIncrement}
                            onChange={check => this.onChange({ isIncrement: check })}
                            disabled={readMode}
                        />
                        <NumberField
                            col={3}
                            title={i18n.razThreshold}
                            value={dataType.razThreshold}
                            onChange={check => this.onChange({ razThreshold: check })}
                            disabled={readMode}
                        />
                        <div className='col s3'/>
                        <Button
                            col={3}
                            title={i18n.recalculateVolumes}
                            disabled={!dataType.isIncrement}
                            onClick={() => this.recalculateVolumes(dataType.id)}
                        />
                    </Row>
                    <Row className='padding-top-1 valign-wrapper'>
                        <Select
                            col={6}
                            label={i18n.codeParameter}
                            options={parameters}
                            keyLabel='labelWithCode'
                            value={dataType.codeParameter}
                            onChange={v => this.onChange({ codeParameter: v })}
                            disabled={readMode}
                        />
                        <Checkbox
                            col={3}
                            label={i18n.monitoringChronicle}
                            checked={dataType.monitoringChronicle}
                            onChange={check => this.onChange({ monitoringChronicle: check })}
                            disabled={readMode}
                        />
                        <Button col={3} title={i18n.recalculateNGF} disabled={!dataType.monitoringChronicle} onClick={() =>
                            setChoiceModal('Souhaitez vous recalculer les NGF + les repères et systèmes altimétriques des mesures (OUI) ou seulement les repères et systèmes altimétriques (NON) ?',
                                {
                                    yes: () => setTimeout(() => this.recalculateNGF(dataType.id), 500),
                                    no: () => this.recalculateLandmarksAndRefAltis(dataType.id),
                                })}
                        />
                    </Row>
                    <Row className='padding-top-1'>
                        <Input
                            col={3}
                            title={i18n.codeUnit}
                            value={dataType.codeUnit}
                            onChange={v => this.onChange({ codeUnit: v })}
                            maxLength={5}
                            disabled={readMode}
                        />
                        <NumberField
                            col={3}
                            title={i18n.numberDecimal}
                            value={dataType.numberDecimal}
                            onChange={v => this.onChange({ numberDecimal: v })}
                            disabled={readMode}
                        />
                        <Input
                            col={6}
                            title={i18n.comment}
                            value={dataType.comment}
                            onChange={v => this.onChange({ comment: v })}
                            disabled={readMode}
                        />
                    </Row>
                </div>
            </div>
        )
    }
}

DataTypeApp.propTypes = {
    dataType: PropTypes.instanceOf(DtoDataType),
    onChange: PropTypes.func.isRequired,
    parameters: PropTypes.arrayOf(PropTypes.instanceOf(ParameterDto)),
    fetchParameters: PropTypes.func,
}

const mapStateToProps = (store) => ({
    parameters: store.ParameterReducer.parameters,
})

const mapDispatchToProps = {
    fetchDataTypesByProject: StationAction.fetchDataTypesByProject,
    updateDataTypesByProject: StationAction.updateDataTypesByProject,
    fetchParameters: ParameterAction.fetchParameters,
    addToTitle: HomeAction.addToTitle,
    error: ToastrAction.error,
    push,
}

export default connect(mapStateToProps, mapDispatchToProps)(DataTypeApp)
