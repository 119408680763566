import DtoMaterielSituation from '../../../dto/DtoMaterielSituation'

export default class DtoEquipmentSituation extends DtoMaterielSituation {
    constructor(obj) {
        super(obj)
        this.id = obj.idEquipmentSituation
        this.idEquipment = obj.idEquipment
        this.materielId = obj.idEquipment
    }
}
