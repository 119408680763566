import React from 'react'
import PropTypes from 'prop-types'
import Table from '../../../components/datatable/Table'
import i18n from 'simple-react-i18n'
import { geti18n } from '../../../utils/StringUtil'
import SieauParameterDto from '../../../administration/dto/SieauParameterDto'
import { arrayOf } from '../../../utils/StoreUtils'
import CardList from '../../../components/list/cardList/CardList'
import Checkbox from '../../../components/forms/Checkbox'
import Row from '../../../components/react/Row'
import DtoInstallationType from '../../dto/installation/DtoInstallationType'
import Switch from '../../../components/forms/Switch'

const InstallationsListPanelsOptions = ({
    panels = [],
    onChange = () => {},
    applicationSettings = [],
    installationsTypes = [],
    readMode = true,
}) => {
    const onChangeOption = (option) => {
        if (!readMode) {
            onChange({
                applicationSettings: [
                    ...applicationSettings.filter(as => as.parameter !== option.parameter),
                    new SieauParameterDto({ ...option, value: !option.hide ? 'hidden' : '', isChanged: true }),
                ],
            })
        }
    }

    const onChangePanel = (panel) => {
        onChange({
            installationsTypes: [
                ...installationsTypes.filter(it => it.code !== panel.code),
                new DtoInstallationType({ ...panel, display: !panel.display, isChanged: true }),
            ],
        })
    }

    const getContent = (panel) => {
        if (!panel.display) {
            return []
        }

        const headers = readMode ? ['name'] : ['name', 'display']
        const tableData = panel.components.map(c => ({
            ...c,
            name: geti18n(c.label),
            color: c.hide ? 'grey lighten-1' : 'white',
            display: (
                <Switch
                    checked={ !c.hide }
                    disabled={ readMode }
                    onChange={ () => onChangeOption(c) }
                    freezeOpti={ true }
                />
            ),
        }))
        return (
            <Table
                condensed
                showTitle={ false }
                data={ tableData }
                type={{ headers }}
                sortable={ !!tableData.length }
            />
        )
    }

    const getTitle = (panel) => {
        return (
            <Row className={`valign-wrapper ${!panel.display && 'padding-left-1' || ''}`}>
                {
                    !readMode && (
                        <Checkbox
                            checked={ panel.display }
                            onChange={ () => onChangePanel(panel) }
                            className='white-checkbox'
                            readMode={ readMode }
                        />
                    )
                }
                <div className='col s12'>
                    <h6>
                        { panel.name }
                        <span className='nbElementsActivated'>
                            { ` (${panel.components.filter(c => !c.hide).length} / ${panel.components.length} ${i18n.elements})` }
                        </span>
                    </h6>

                </div>
            </Row>
        )
    }

    const data = panels.map(panel => ({
        title: getTitle(panel),
        titleColor: !panel.display ? 'grey' : '',
        collapsible: panel.display,
        cards: [{
            content: getContent(panel),
        }],
    }))

    return (
        <CardList
            data = { data }
            smallTitle
            collapsible={true}
        />
    )
}

InstallationsListPanelsOptions.propTypes = {
    panels: PropTypes.array,
    onChange: PropTypes.func,
    applicationSettings: arrayOf(SieauParameterDto),
    installationsTypes: arrayOf(DtoInstallationType),
    readMode: PropTypes.bool,
}

export default InstallationsListPanelsOptions
