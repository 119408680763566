import React from 'react'
import { styled } from '@mui/styles'
import Card from 'components/card/Card'
import { lightGrey } from 'utils/constants/ColorTheme'

export const WhiteCard = styled(({ ...other }) => (
    <Card
        {...other}
        headerStyle={{ color: 'black', backgroundColor: 'white', borderBottom: `solid 1px ${lightGrey}`, ...other.headerStyle }}
    />
))({})
