import React, { useEffect, useMemo, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'

import HomeAction from '../../../../home/actions/HomeAction'
import { getReferencialData } from '../../../util/ReferencialUtils'
import {
    PATH_REFERENCIAL,
    PATH_REFERENCIAL_TAXON,
    PATH_REFERENCIAL_TAXON_NEW,
} from '../../../../home/constants/RouteConstants'
import TaxonAction from '../actions/TaxonAction'
import ReferencialAction from '../../../action/ReferencialAction'
import useSandreList from 'utils/customHook/useSandreList'
import useTitle from 'utils/customHook/useTitle'
import useActions from 'utils/customHook/useActions'
import { push } from '@lagunovsky/redux-react-router'
import { getDataWithHeaders } from 'utils/ExportDataUtil'
import { nbPerPageLabel, REFERENCIAL_TYPE_NAME, SANDRE } from 'referencial/constants/ReferencialConstants'
import { getFullDate } from 'utils/DateUtil'
import { hasValue } from 'utils/NumberUtil'
import { searchAllCharacters } from 'utils/StringUtil'
import Table from 'components/datatable/Table'
import Card from 'components/card/Card'
import Row from 'components/react/Row'
import Input from 'components/forms/Input'
import Select from 'components/forms/Select'
import { Button } from '@mui/material'
import useSessionStorage from 'utils/customHook/useSessionStorage'
import ImportFileContent from 'import/components/content/ImportFileContent'
import ProgressCard from 'components/card/ProgressCard'

const headers = ['code', 'theme', 'level', 'latinName', 'status']
const headersCsv = [
    'Code Taxon SANDRE*',
    'Nom latin de l\'appellation du taxon*',
    'Statut de l\'appellation du taxon',
    'Date de création de l\'appellation du taxon',
    'Date de la dernière mise-à-jour de l\'appellation du taxon',
    'Rédacteur de la fiche de l\'appellation du taxon',
    'Références bibliographiques de l\'appellation du taxon',
    'Commentaires sur l\'appellation du taxon',
    'Auteur de l\'appellation du taxon',
    'Code de l\'appellation du taxon (Faire le lien avec le champ "Elément de")',
    'Code du Niveau',
    'Niveau taxonomique',
    'Code du thème*',
    'Libellé du thème des taxons',
    'Code OMNIDIA (teraomnidia)',
    'Synonyme OMNIDIA (pas visible sur l\'écran du référentiel d\'un taxon)',
    'TAXREF (champ "refmuseum")',
    'PHYTOBS (codemacrophytes)',
]

const TaxonsApp = () => {
    const {
        taxons,
        sandreCodes,
        referencialSandreCodes,
        referencialStatus,
    } = useSelector(store => ({
        taxons: store.TaxonReducer.taxons,
        sandreCodes: store.ReferencialReducer.sandreCodes,
        referencialSandreCodes: store.ReferencialReducer.referencialSandreCodes,
        referencialStatus: store.ReferencialReducer.referencialStatus,
    }), shallowEqual)

    const dispatch = useDispatch()

    const [filter, setFilter] = useSessionStorage(`referencial_${REFERENCIAL_TYPE_NAME.taxon}`, {})
    const [loading, setLoading] = useState(true)
    const [filterTmp, setFilterTmp] = useState(filter)
    const [fileContent, setFileContent] = useState('')

    const sandreThemes = useSandreList(SANDRE.TAXONS_THEME)
    const sandreLevels = useSandreList(SANDRE.TAXONS_NIVEAUX)

    useEffect(() => {
        if (!sandreCodes.length) {
            dispatch(ReferencialAction.fetchSandreCodes())
        }
    }, [dispatch, sandreCodes])

    useEffect(() => {
        if (!taxons.length) {
            dispatch(TaxonAction.fetchTaxons()).then(() => {
                setLoading(false)
            })
        } else {
            setLoading(false)
        }

        if (!referencialSandreCodes.length) {
            dispatch(ReferencialAction.fetchReferencialSandreCodes())
        }

        if (!referencialStatus.length) {
            dispatch(ReferencialAction.fetchReferencialStatus())
        }
        dispatch(HomeAction.setHelpLink('', ''))
    }, [])

    useTitle(() => [{
        title: i18n.referencials,
        href: PATH_REFERENCIAL,
    }, {
        title: i18n.taxons,
        href: PATH_REFERENCIAL_TAXON,
    }], [])

    const updateTaxon = useMemo(() => referencialSandreCodes.find(o => o.field === 'TAXONS') || {}, [referencialSandreCodes])

    const taxonsByStatus = useMemo(() => hasValue(filter.status) ? taxons.filter(h => parseInt(h.status) === filter.status) : taxons, [filter.status, taxons])
    const taxonsByTheme = useMemo(() => hasValue(filter.theme) ? taxonsByStatus.filter(h => parseInt(h.theme) === filter.theme) : taxonsByStatus, [filter.theme, taxonsByStatus])
    const referencialData = useMemo(() => getReferencialData(taxonsByTheme), [taxonsByTheme])
    const data = useMemo(() => {
        return referencialData
            .filter((obj) => searchAllCharacters(headers.map((key) => obj[key]).join())
                .includes(searchAllCharacters(filter.searchValue || '')))
    }, [referencialData, filter.searchValue])

    const colorData = useMemo(() => data.map(t => ({
        latinName: t.latinName,
        theme: sandreThemes.find(s => s.code === t.theme)?.name,
        level: sandreLevels.find(s => s.code === t.level)?.name,
        code: t.code,
        active: t.active,
        status: t.status,
    })).map(t => {
        const color = t.active === '1' ? 'white' : 'grey lighten-1'
        const obj = Object.keys(t).reduce((a, key) => ({
            ...a,
            [key]: {
                value: t[key],
            },
        }), {})
        return {
            ...obj,
            id: t.code,
            lineColor: color,
        }
    }).sort((a, b) => parseInt(a.code.value) - parseInt(b.code.value)), [data, sandreLevels, sandreThemes])

    const onImportFile = (e) => {
        const reader = new FileReader()
        const file = e.target.files[0]
        reader.onload = upload => setFileContent(upload.target.result)
        reader.readAsDataURL(file)
    }

    const getFileContent = (title, subtitle, extension) => (
        <ImportFileContent
            title={title}
            subtitle={subtitle}
            extension={extension}
            headersCsv={headersCsv}
            onImportFile={f => onImportFile(f)}
        />
    )

    useActions(() => {
        return {
            new: () => dispatch(push(PATH_REFERENCIAL_TAXON_NEW)),
            referencialActions: {
                actions: {
                    export: {
                        data: getDataWithHeaders(data, headers),
                        titleFile: i18n.taxonList,
                    },
                    sync: {
                        updateAction: () => dispatch(TaxonAction.fetchSynchroSandreTaxon()),
                        onImportXml: () => dispatch(TaxonAction.fetchImportTaxonXML(fileContent)),
                        onImportCsv: () => dispatch(TaxonAction.fetchImportTaxonCSV(fileContent)),
                        xmlContent: getFileContent(i18n.xmlSyncChoice, i18n.importXmlFileToZanderReference, '.xml'),
                        csvContent: getFileContent(i18n.csvSyncChoice, i18n.importCsvZanderReference, '.csv'),
                        fileContentIsValid: !!fileContent,
                    },
                },
            },
        }
    }, [data, fileContent])

    return (
        <>
            <Card noMargin={false} className='margin-left-1 margin-right-1' title={i18n.criterias}>
                <Row className='padding-top-1 padding-bottom-5-px'>
                    <Input
                        col={3}
                        title={i18n.search}
                        value={filterTmp.searchValue}
                        onChange={v => setFilterTmp({ ...filterTmp, searchValue: v })}
                        onEnterKeyPress={() => setFilter({ ...filterTmp })}
                        data-cy='taxonSearch'
                    />
                    <Select
                        options={referencialStatus}
                        label={i18n.status}
                        col={3}
                        onChange={v => setFilterTmp({ ...filterTmp, status: v })}
                        value={filterTmp.status}
                        nullLabel='&nbsp;'
                        data-cy='taxonStatus'
                    />
                    <Select
                        options={sandreThemes}
                        label={i18n.theme}
                        col={3}
                        onChange={v => setFilterTmp({ ...filterTmp, theme: v })}
                        value={filterTmp.theme}
                        nullLabel='&nbsp;'
                        data-cy='taxonTheme'
                    />
                    <div className='col s3 padding-top-1'>
                        <Button
                            className='right'
                            onClick={() => setFilter({ ...filterTmp })}
                            variant='contained'
                            color='primary'
                            data-cy='taxonSearchButton'
                        >
                            {i18n.search}
                        </Button>
                    </div>
                </Row>
            </Card>
            {updateTaxon?.updateUse && updateTaxon?.updateDate && (
                <div className='row no-margin-bottom'>
                    <div className='col s4 center offset-s8 card'>
                        <div className='col s12'>
                            { i18n.lastUpdateOn } { getFullDate(updateTaxon.updateDate) } { i18n.by } { updateTaxon.updateUser }
                        </div>
                    </div>
                </div>
            )}
            <div className='margin-1 padding-top-5-px referencial-table'>
                {loading && <ProgressCard indeterminate={true} /> || (
                    <Table
                        id='taxonsTable'
                        title={i18n.taxonList}
                        condensed paging
                        nbPerPageLabel={nbPerPageLabel}
                        data={colorData}
                        color
                        type={{ headers }}
                        sortable={!!colorData.length}
                        onClick={taxon => dispatch(push(`/referencial/taxon/${taxon.id}/dashboard`))}
                    />
                )}
            </div>
        </>
    )
}

export default TaxonsApp
