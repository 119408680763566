import { useEffect, useState } from 'react'
import useAbortController from './useAbortController'
import useBoolean from './useBoolean'
import { chunk, isNil } from 'lodash'
import AnalysisAction from 'quality/actions/AnalysisAction'
import DtoAnalysisLight from 'quality/dto/analyse/DtoAnalysisLight'

const useFetchAnalysis = (filters) => {
    const {
        controllerRef,
        initController,
    } = useAbortController()

    const {
        value: isLoaded,
        setFalse: setNotLoaded,
        setTrue: setLoaded,
    } = useBoolean(false)
    const [progress, setProgress] = useState(0)

    const [analysis, setAnalysis] = useState([])

    useEffect(() => {
        if (filters.stations.length === 0 || isNil(filters.startDate) || isNil(filters.endDate)) return

        setNotLoaded()
        setProgress(0)
        initController()
        setAnalysis([])

        const groupSize = Math.min(Math.floor(filters.stations.length / 10), 10) || 1
        const chunkGroup = chunk(filters.stations, groupSize)

        const promises = chunkGroup.map(stations => {
            return AnalysisAction.getAnalysis({ ...filters, stations }, controllerRef.current.signal)
                .then(list => setAnalysis(p => [...p, ...list.map(a => new DtoAnalysisLight(a))]))
        })

        Promise.all(promises.map(promise => promise.then(() => setProgress(p => p + 100 / promises.length)).catch(() => []))).finally(() => !controllerRef.current.signal.aborted && setLoaded())

        // eslint-disable-next-line consistent-return
        return () => {
            controllerRef.current.abort()
        }
    }, [filters])

    return {
        analysis,
        isLoaded,
        progress,
    }
}

export default useFetchAnalysis