import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import SieauAction from '../../../../components/sieau/SieauAction'
import { MAP, STATION_LIST, STATION_TYPE_CONSTANT, STATION_TYPE_NAME } from '../../../../station/constants/StationConstants'
import { hasLocalisation, hasLocalisationStation } from '../../../../utils/StationUtils'
import CartographyPanel from '../../../../components/map/CartographyPanel'
import { nbPerPageLabel, nbPerPageLabelMedium, nbPerPageLabelShorty, SANDRE } from '../../../../referencial/constants/ReferencialConstants'
import Table from '../../../../components/datatable/Table'
import { push } from '@lagunovsky/redux-react-router'
import { keys, orderBy, sortBy, uniqBy } from 'lodash'
import { INSTALLATION_TYPE, INSTALLATION_TYPE_CRITERIA } from '../../../constants/InstallationConstants'
import InstallationAction from '../../../actions/InstallationAction'
import ActionComponent from '../../../../components/ActionComponent'
import { getLocalStorageJson, setLocalStorageJson } from '../../../../utils/FormUtils'
import { getControlColor, getControlObject, isAepInstallation } from '../../../../utils/InstallationUtils'
import { hasValue } from '../../../../utils/NumberUtil'
import InstallationsFilterForm from './InstallationsFilterForm'
import HomeAction from '../../../../home/actions/HomeAction'
import DtoInstallationType from '../../../dto/installation/DtoInstallationType'
import CityDto from '../../../../referencial/components/city/dto/CityDto'
import { getColorCircleElement, getRGBColor } from '../../../../utils/ColorUtil'
import PiezometryAction from '../../../../piezometry/actions/PiezometryAction'
import DtoInstallationActivity from '../../../dto/industrialSite/DtoInstallationActivity'
import ReferencialAction from '../../../../referencial/action/ReferencialAction'
import DtoSandreCode from '../../../../referencial/dto/DtoSandreCode'
import { getStatuses } from '../../../../utils/QualityUtils'
import DtoInstallationTableLine from '../../../dto/installation/DtoInstallationTableLine'
import DtoBoreholeTableLine from '../../../dto/borehole/DtoBoreholeTableLine'
import DtoIndustrialSiteTableLine from '../../../dto/industrialSite/DtoIndustrialSiteTableLine'
import CityAction from '../../../../referencial/components/city/actions/CityAction'
import ProgressCard from '../../../../components/card/ProgressCard'
import {
    arrayOf,
    getLabel,
    getMapStateToProps,
    getObjectLabel,
    getPropTypes,
    getSandreList,
    objectOf,
} from '../../../../utils/StoreUtils'
import { componentHasHabilitations } from '../../../../utils/HabilitationUtil'
import { H_INSTALLATION_DASHBOARD } from '../../../../account/constants/AccessRulesConstants'
import ExportAction from 'export/actions/ExportAction'
import { formatData } from 'utils/ExportDataUtil'
import User from 'account/dto/User'
import { getSetting, getUser } from 'utils/SettingUtils'
import ToastrAction from 'toastr/actions/ToastrAction'
import AdministrationAction from 'administration/actions/AdministrationAction'
import StationAction from 'station/actions/StationAction'
import { getUserBookmarksByStationType } from 'utils/UserUtil'
import JobAction from 'import/actions/JobAction'
import ImportDescDialog from './ImportDescDialog'
import TabList from 'components/list/TabList'
import FilterDialog from './modal/FilterDialog'
import DtoFlowObstruction from 'installation/components/flowObstruction/dto/DtoFlowObstruction'
import NewInstallationDialog from './NewInstallationPopup'
import DtoContributorLink from 'station/dto/DtoContributorLink'
import ContributorItem from 'referencial/components/contributor/dto/ContributorItem'
import DtoNetworkLink from 'piezometry/dto/DtoNetworkLink'
import NetworkAction from 'referencial/components/network/actions/NetworkAction'
import NetworkDto from 'referencial/components/network/dto/NetworkDto'
import SieauParameterDto from 'administration/dto/SieauParameterDto'
import DtoNetworkTableLine from 'installation/components/network/dto/DtoNetworkTableLine'
import { DEFAULT_CONTRIBUTOR_TYPE } from 'administration/components/user/constants/UserConstants'

const storeProps = {
    aquifers: false,
}

class InstallationsDashboardApp extends ActionComponent {
    constructor(props) {
        super(props)
        const localFilter = getLocalStorageJson(INSTALLATION_TYPE_CRITERIA)?.filter
        this.state = {
            panel: STATION_LIST,
            dataLoaded: false,
            piezometryMapType: 0,
            piezometrySPIReceived: false,
            hideNoValuePiezo: false,
            filterResults: [],
            selectedFilter: {},
            tmpFilter: {},
            filter: {
                searchValue: props.globalResearch,
                forceLoad: false,
                installationType: null,
                selectedFilter: -1,
                module: 'INST',
                referentIds: [],
                operatorIds: [],
                ...localFilter,
            },
            isOpenAdd: false,
            referentType: parseInt(getSetting(this.props.settings, 'contributorTypeAdministrator')) || DEFAULT_CONTRIBUTOR_TYPE.ADMINISTRATOR,
            operatorType: parseInt(getSetting(this.props.settings, 'contributorTypeOperator')) || DEFAULT_CONTRIBUTOR_TYPE.OPERATOR,
        }
    }

    setFilterIds = (tmpFilter, filterResults) => {
        const { filter } = this.state
        this.setState({ filter: { ...filter, ...tmpFilter }, filterResults, dataLoaded: false }, () => this.loadData())
    }

    updateFilterIds = (tmpFilter, parameters = null) => {
        const { userBookmarks, filters } = this.props
        const tableData = this.getDataList()

        if (tmpFilter.selectedFilter === -1) {
            this.setFilterIds(tmpFilter, null)
        } else if (tmpFilter.selectedFilter === -5) {
            const bookmarks = getUserBookmarksByStationType(userBookmarks, 'installation')
            const results = bookmarks.map(code => tableData.find(it => it.code === code)).filter(p => !!p)
            this.setFilterIds(tmpFilter, results)
        } else {
            const filterData = filters.find(f => f.module === 'INST' && f.code === tmpFilter.selectedFilter)
            if (filterData) {
                this.props.fetchFilterResults({ ...filterData, parameters }).then(json => this.setFilterIds(tmpFilter, json))
            } else {
                this.setFilterIds({ selectedFilter: -1 }, [])
            }
        }
    }

    getHeaders = (isExport = false) => {
        const { filter, filterResults } = this.state
        const headers = (() => {
            switch (filter.installationType) {
                case null:
                    return ['nullValue', 'nullValue2', 'id', 'code', 'internalReferences', 'installationTypeLabel', 'name', 'townCode', 'cityLabel', 'codeParcelle', 'operatorName', 'buildingOwnerName', 'dataOrigin']
                case INSTALLATION_TYPE.BOREHOLE:
                    const usages = orderBy(getSandreList(this.props.sandreCodes, SANDRE.USAGES), 'code')
                    return ['nullValue', 'nullValue2', 'id', 'code', 'internalReferences', 'name', 'townCode', 'cityLabel', 'depth', 'aquifer', 'fieldMode', 'operatorName', 'buildingOwnerName', 'dataOrigin', ...usages.map(u => isExport ? `usageExport${u.code}` : `usage${u.code}`)]
                case INSTALLATION_TYPE.INDUSTRIAL_SITE:
                    return ['nullValue', 'nullValue2', 'siret', 'internalReferences', 'name', 'townCode', 'cityLabel', 'activityCode', 'activityLibelle', 'staff', 'effectiveTranche', 'icpeLabel', 'dischargeAgreementLabel']
                case INSTALLATION_TYPE.STEP:
                    return ['id', 'code', 'name', 'townCode', 'townName', 'rejectLabel', 'nbBasins', 'operatorName']
                case INSTALLATION_TYPE.TANK:
                    return ['id', 'code', 'name', 'townCode', 'cityLabel', 'tankTypeLabel', 'numberTanks', 'operatorName']
                case INSTALLATION_TYPE.FLOW_OBSTRUCTION:
                    return ['id', 'name', 'bief', 'townName', 'nature', 'remoteManaged', 'inUse']
                case INSTALLATION_TYPE.NETWORK:
                    return ['id', 'code', 'name', 'distributionUnits', 'matter', 'nature', 'section', 'length', 'operatorName', 'buildingOwnerName']
                default:
                    const opeAdm = isAepInstallation(filter.installationType) ? ['operatorName', 'buildingOwnerName'] : []
                    return ['nullValue', 'nullValue2', 'id', 'code', 'internalReferences', 'name', 'townCode', 'cityLabel', 'codeParcelle', ...opeAdm, 'dataOrigin']
            }
        })()
        return [...headers, ...keys(filterResults?.[0] || {}).filter(d => d !== 'id') ]
    }

    getCompleteExportHeaders = (installationType) => {
        const { filterResults } = this.state
        const headers = (() => {
            switch (installationType) {
                case null:
                    return ['id', 'code', 'internalReferences', 'installationTypeLabel', 'type', 'name', 'townCode', 'cityLabel', 'codeParcelle', 'x', 'y', 'projectionLabel', 'statusLabel', 'operatorName', 'buildingOwnerName', 'dataOrigin']
                case INSTALLATION_TYPE.BOREHOLE:
                    const usages = orderBy(getSandreList(this.props.sandreCodes, SANDRE.USAGES), 'code')
                    return ['id', 'code', 'internalReferences', 'name', 'townCode', 'cityLabel', 'x', 'y', 'projectionLabel', 'depth', 'aquifer', 'fieldMode', 'operatorName', 'buildingOwnerName', 'statusLabel', 'dataOrigin', 'sectorId', 'sectorLabel', ...usages.map(u => `usageExport${u.code}`)]
                case INSTALLATION_TYPE.INDUSTRIAL_SITE:
                    return ['siret', 'internalReferences', 'name', 'townCode', 'cityLabel', 'x', 'y', 'projectionLabel', 'statusLabel', 'activityCode', 'activityLibelle', 'activityCode2', 'activityLibelle2', 'activityCode3', 'activityLibelle3', 'activityCode4', 'activityLibelle4', 'staff', 'effectiveTranche', 'icpe', 'dischargeAgreement']
                case INSTALLATION_TYPE.STEP:
                    return ['id', 'code', 'name', 'townCode', 'cityLabel', 'rejectTypeLabel', 'nbBasins', 'operatorName', 'x', 'y', 'projectionLabel', 'sectorId', 'sectorLabel']
                case INSTALLATION_TYPE.TANK:
                    return ['id', 'code', 'name', 'townCode', 'cityLabel', 'tankTypeLabel', 'nbTanks', 'operatorName', 'x', 'y', 'projectionLabel', 'sectorId', 'sectorLabel']
                case INSTALLATION_TYPE.CAPTURE:
                case INSTALLATION_TYPE.LIFTING_STATION:
                case INSTALLATION_TYPE.PUMPING:
                case INSTALLATION_TYPE.DOM_SUB:
                case INSTALLATION_TYPE.TREATMENT:
                case INSTALLATION_TYPE.AEP_PRODUCTION:
                case INSTALLATION_TYPE.AEP_DISINFECTION:
                case INSTALLATION_TYPE.AEP_OVERPRESSURE:
                case INSTALLATION_TYPE.SECTOR_COUNT:
                    return ['id', 'code', 'internalReferences', 'type', 'name', 'townCode', 'cityLabel', 'codeParcelle', 'x', 'y', 'projectionLabel', 'statusLabel', 'operatorName', 'buildingOwnerName', 'dataOrigin', 'sectorId', 'sectorLabel']
                default:
                    return ['id', 'code', 'internalReferences', 'type', 'name', 'townCode', 'cityLabel', 'codeParcelle', 'x', 'y', 'projectionLabel', 'statusLabel', 'operatorName', 'buildingOwnerName', 'dataOrigin']
            }
        })()
        return [...headers, ...keys(filterResults?.[0] || {}).filter(d => d !== 'id')]
    }

    getIndusSiteCustomHeaders = (complete) => {
        const usages = orderBy(getSandreList(this.props.sandreCodes, SANDRE.USAGES), 'code')

        return complete ?
            ['id', 'code', 'internalReferences', 'name', 'townCode', 'cityLabel', 'x', 'y', 'projectionLabel', 'depth', 'aquifer', 'fieldMode', 'operatorName', 'buildingOwnerName', 'statusLabel', 'dataOrigin', 'sectorId', 'sectorLabel', ...usages.map(u => u.name)]
            : ['nullValue', 'nullValue2', 'id', 'code', 'internalReferences', 'name', 'townCode', 'cityLabel', 'depth', 'aquifer', 'fieldMode', 'operatorName', 'buildingOwnerName', 'dataOrigin', ...usages.map(u => u.name)]
    }

    getExportData = (completeExport) => {
        const { filter } = this.state
        const data = this.getData()
        if (data.length) {
            const customHeaders = filter.installationType === INSTALLATION_TYPE.BOREHOLE && this.getIndusSiteCustomHeaders(completeExport)
            return [
                {
                    ...data[0],
                    headers: completeExport ? this.getCompleteExportHeaders(filter.installationType) : this.getHeaders(true),
                    customHeaders,
                },
                ...data.slice(1),
            ]
        }
        return null
    }

    getExport = (exportType, completeExport = false) => {
        const { filter } = this.state
        const { installationsTypes } = this.props
        const exportedData = this.getExportData(completeExport)
        if (exportedData) {
            const dataFormatted = formatData(exportedData)
            const title = filter.installationType && getLabel(installationsTypes, filter.installationType).replace(' ', '_') || i18n.installations
            this.props.export(dataFormatted, exportType, title)
        } else {
            this.props.warning(i18n.noDataToExport)
        }
    }

    completeExport = (exportType) => {
        const { filter } = this.state
        const { accountUser } = this.props

        const exportedData = this.getExportData(true)
        if (exportedData) {
            if (filter.installationType === INSTALLATION_TYPE.AGRI_PREL) {
                const filterFormatted = { ...filter, user: accountUser.login, ids: exportedData.map(ed => ed.id), type: exportType }
                this.props.exportInstallationsFull(exportType, `${i18n.exportFull}_${i18n.installations}_`, filterFormatted)
            } else {
                this.getExport(exportType, true)
            }
        } else {
            this.props.warning(i18n.noDataToExport)
        }
    }

    getExportChoice = () => {
        return {
            exportChoices: [{
                name: i18n.syntheticExport,
                formats: [{
                    type: i18n.csv,
                    callback: () => this.getExport('csv'),
                },
                {
                    type: i18n.exportXLSX,
                    callback: () => this.getExport('xlsx'),
                }],
            }, {
                name: i18n.exportFull,
                formats: [{
                    type: i18n.csv,
                    callback: () => this.completeExport('csv'),
                },
                {
                    type: i18n.exportXLSX,
                    callback: () => this.completeExport('xlsx'),
                }],
            }],
        }
    }

    initData = () => {
        const { filter } = this.state
        if (hasValue(filter.selectedFilter) && filter.module) {
            this.updateFilterIds(filter)
        } else {
            this.loadData()
        }
    }

    componentDidMount() {
        if (!componentHasHabilitations(H_INSTALLATION_DASHBOARD)) { // A modifier quand react-router sera à jour
            this.props.push('/unauthorized')
            return
        }
        const { piezometers, activities, sandreCodes, filters } = this.props
        this.props.setTitle([{
            title: i18n.installation,
            href: 'installation',
        }])

        if (!filters.length) {
            this.props.fetchFilters().then(() => this.initData())
        } else {
            this.initData()
        }
        this.props.fetchInstallationTypes()
        const actionNew = getUser()?.consultant === '1' ? {} : {
            new: () => this.setState({ isOpenAdd: true }),
        }
        const actionSync = getUser()?.admin === '1' || getUser()?.isAdmin === '1' ? {
            referencialActions: {
                actions: {
                    sync: {
                        updateAction: () => JobAction.promiseExecuteJob(-501),
                        webServiceDescription: i18n.codesParcellesUpdateByIGN,
                    },
                },
            },
        } : {}
        this.setActions({
            ...actionNew,
            ...actionSync,
            exportChoice: this.getExportChoice(),
            import: v => this.setState({ isOpen: v }),
        })
        this.props.fetchAllContributors(STATION_TYPE_CONSTANT.INSTALLATION)
        // this.props.fetchNetworks()
        // this.props.fetchInstallNetworkLinks()
        if (!piezometers.length) {
            this.props.fetchPiezometersLight()
        }
        if (!activities.length) {
            this.props.fetchActivities()
        }
        if (!sandreCodes.length) {
            this.props.fetchSandreCodes()
        }
        if (!this.props.cities.length) {
            this.props.fetchCities()
        }
        this.props.updateGlobalResearch('')
    }

    onValidateAdd = (newInstallation) => {
        this.props.createInstallation(newInstallation, id => {
            if (newInstallation.installationType === INSTALLATION_TYPE.FLOW_OBSTRUCTION) {
                this.props.updateFlowObstruction(new DtoFlowObstruction({ id }))
            }
            this.setState({ isOpenAdd: false })
            this.props.push(`/station/installation/${id}/description`)
        })
    }

    loadData = () => {
        const { filter, filterResults } = this.state
        const promiseFunc = (() => {
            switch (filter.installationType) {
                case INSTALLATION_TYPE.BOREHOLE:
                    return this.props.fetchBoreholeTable
                case INSTALLATION_TYPE.INDUSTRIAL_SITE:
                    return this.props.fetchIndustrialSiteTable
                case INSTALLATION_TYPE.STEP:
                    return this.props.fetchSTEPTable
                case INSTALLATION_TYPE.TANK:
                    return this.props.fetchTankTable
                case INSTALLATION_TYPE.FLOW_OBSTRUCTION:
                    return this.props.fetchFlowObstructionTable
                case INSTALLATION_TYPE.NETWORK:
                    return this.props.fetchNetworkTable
                default:
                    return this.props.fetchInstallationTable
            }
        })()

        const filterIdsAllStations = filter.selectedFilter === -1 ? null : filterResults.map(fd => parseInt(fd.id))

        return promiseFunc({ ...filter, filterIds: filterIdsAllStations }).then(() => this.setState({ dataLoaded: true }))
    }

    // setSelectedSearchValueStations = (props, column, sort) => {
    //     const data = this.getData(props)
    //     if (data.length) {
    //         const sortedStations = this.getSortedStations(props, data, column, sort)
    //         const searchValues = props.selectedSearchValues.installation || {}
    //         if (!searchValues.stations || searchValues.column !== column || searchValues.sort !== sort || searchValues.searchValue !== this.state.filter.searchValue) {
    //             this.props.update('selectedSearchValues', 'installation',
    //                 { searchValue: this.state.searchValue, stations: sortedStations, column: column || searchValues.column, sort: sort || searchValues.sort })
    //         }
    //     }
    // }

    // getSortedStations = (props, stations, columnTableName, sort) => {
    //     const searchValues = props.selectedSearchValues.installation
    //     if (stations.length && searchValues) {
    //         const column = columnTableName || searchValues.column
    //         const sortOrder = sort || searchValues.sort
    //         if (columnTableName) {
    //             const dateRegex = new RegExp('\\d\\d\\/\\d\\d\\/\\d\\d\\d\\d')
    //             if (some(stations, obj => obj[column] && dateRegex.test(obj[column]))) {
    //                 return orderBy(stations, [obj => moment(obj[column], 'DD/MM/YYYY').valueOf()], sortOrder)
    //             }
    //             return orderBy(stations, column, sortOrder)
    //         }
    //     }
    //     return stations
    // }

    // onTableSort = (column, sort) => {
    //     this.setState({ column: column, sort: sort })
    //     this.setSelectedSearchValueStations(this.props, column, sort)
    // }

    getOriginIcon = (installation) => {
        return hasValue(installation.jobExecutionId) ?
            <i className='material-icons little'>wifi</i> : null
    }

    getLegendPanel = () => (
        <div className='padding-bottom-4 padding-top-4 padding-left-1 padding-right-1'>
            <div className='row no-margin valign-wrapper'>
                <i className='material-icons '>wifi</i>
                { i18n.automaticUpdate }
            </div>
            <div className='row no-margin valign-wrapper'>
                <h5>{ i18n.control }</h5>
            </div>
            {
                [1, 2, 3, 4].map(controlId => (
                    <div className='row no-margin valign-wrapper'>
                        { getColorCircleElement(getRGBColor(getControlColor(controlId)), true) }
                        <h6>{ getLabel(getStatuses(), controlId) }</h6>
                    </div>
                ))
            }
        </div>
    )

    getDataList = () => {
        const { filter, filterResults } = this.state
        const dataList = (() => {
            switch (filter.installationType) {
                case INSTALLATION_TYPE.BOREHOLE:
                    if (this.props.boreholeTable.length) {
                        const usages = orderBy(getSandreList(this.props.sandreCodes, SANDRE.USAGES), 'code')
                        return this.props.boreholeTable.map(b => {
                            const splittedUsages = b.usages?.split('')
                            const usagesObj = usages.reduce((acc, usage, index) => ({
                                ...acc,
                                [`usage${usage.code}`]: splittedUsages[index] === 't'
                                    ? <i className='material-icons tiny table-checkbox'>check_box</i> : null,
                                [`usageExport${usage.code}`]: splittedUsages[index] === 't'
                                    ? i18n.yes : i18n.no,
                            }), {})
                            return {
                                ...b,
                                nullValue: getControlObject(b.status),
                                nullValue2: this.getOriginIcon(b),
                                cityLabel: getObjectLabel(this.props.citiesIndex[b.townCode], 'name'),
                                ...usagesObj,
                            }
                        })
                    }
                    return []
                case INSTALLATION_TYPE.INDUSTRIAL_SITE:
                    if (this.props.industrialSiteTable.length) {
                        return this.props.industrialSiteTable.map(installation => {
                            return {
                                ...installation,
                                nullValue: getControlObject(installation.status),
                                nullValue2: this.getOriginIcon(installation),
                                icpe: installation.icpe ? i18n.yes : i18n.no,
                                dischargeAgreement: installation.dischargeAgreement ? i18n.yes : i18n.no,
                                icpeLabel: installation.icpe ? <i className='material-icons tiny table-checkbox'>check_box</i> : null,
                                dischargeAgreementLabel: installation.dischargeAgreement ? <i className='material-icons tiny table-checkbox'>check_box</i> : null,
                                cityLabel: getObjectLabel(this.props.citiesIndex[installation.townCode], 'name'),
                            }
                        })
                    }
                    return []
                case INSTALLATION_TYPE.STEP:
                    if (this.props.STEPTable.length) {
                        return this.props.STEPTable.map(step => ({
                            ...step,
                            cityLabel: getObjectLabel(this.props.citiesIndex[step.townCode], 'name'),
                        }))
                    }
                    return []
                case INSTALLATION_TYPE.TANK:
                    if (this.props.tankTable.length) {
                        return this.props.tankTable.map(tank => ({
                            ...tank,
                            cityLabel: getObjectLabel(this.props.citiesIndex[tank.townCode], 'name'),
                            numberTanks: tank.nbTanks,
                        }))
                    }
                    return []
                case INSTALLATION_TYPE.FLOW_OBSTRUCTION:
                    if (this.props.flowObstructionTable.length) {
                        return this.props.flowObstructionTable.map(fo => ({
                            ...fo,
                            remoteManaged: fo.remoteManaged ? <i className='material-icons tiny table-checkbox'>check_box</i> : null,
                            inUse: fo.inUse ? <i className='material-icons tiny table-checkbox'>check_box</i> : null,
                        }))
                    }
                    return []
                case INSTALLATION_TYPE.NETWORK: return this.props.networkTable
                default:
                    if (this.props.installationTable.length) {
                        return this.props.installationTable.map(installation => ({
                            ...installation,
                            nullValue: getControlObject(installation.status),
                            nullValue2: this.getOriginIcon(installation),
                            type: installation.installationTypeLabel,
                            cityLabel: getObjectLabel(this.props.citiesIndex[installation.townCode], 'name'),
                        }))
                    }
                    return []
            }
        })()

        return filterResults?.length ? dataList.flatMap(dl => {
            return filterResults.filter(fr => fr.id === dl.id).map(filterValues => ({
                ...dl,
                ...filterValues,
            }))
        }) : dataList
    }

    getData = () => {
        const dataList = this.getDataList()
        return sortBy(dataList, o => o.name?.toUpperCase() || '}')
    }

    launchSearch = (tmpFilter, parameters = null) => {
        const { filter } = this.state

        setLocalStorageJson(INSTALLATION_TYPE_CRITERIA, { filter: { ...filter, ...tmpFilter } })
        if (tmpFilter.selectedFilter !== -1 && tmpFilter.selectedFilter !== -5) {
            this.updateFilterIds(tmpFilter, parameters)
        } else {
            this.setState({ filter: tmpFilter, dataLoaded: false, filterIds: [], filterResults: [] }, () => {
                this.loadData()
            })
        }
    }

    onValidate = (tmpFilter) => {
        const { filters } = this.props
        const selectedFilter = filters.find(f => f.id === tmpFilter.selectedFilter && f.module === 'INST') || {}
        if (selectedFilter?.link_parameters?.length) {
            this.setState({ isValidateOpen: true, tmpFilter, selectedFilter })
        } else {
            this.launchSearch(tmpFilter)
        }
    }

    onChangePanel = (panelName) => {
        this.setState({ panel: panelName })
    }

    getTable = (data) => {
        const { filter } = this.state
        const { installationsTypes } = this.props
        const headers = this.getHeaders()
        const { installationTableCount } = this.props
        const { innerHeight } = window
        const nbPerPage = innerHeight < 800 ? nbPerPageLabelShorty : innerHeight < 925 ? nbPerPageLabelMedium : nbPerPageLabel
        switch (filter.installationType) {
            case INSTALLATION_TYPE.BOREHOLE:
                if (this.props.sandreCodes.length) {
                    const usages = orderBy(getSandreList(this.props.sandreCodes, SANDRE.USAGES), 'code')
                    const customHeaders = usages.reduce((obj, usage) => {
                        obj[`usage${usage.code}`] = usage.name
                        return obj
                    }, {})
                    return (
                        <Table
                            title={ i18n.boreholes }
                            data={ data }
                            paging
                            // initialSort={ this.props.selectedSearchValues.installation }
                            legendPanel={ this.getLegendPanel() }
                            nbPerPageLabel={nbPerPage}
                            // onSort={ (value, sort) => this.onTableSort(value, sort) }
                            type={{ headers }}
                            onClick={s => this.props.push(`/station/installation/${s.id}`)}
                            condensed
                            count={installationTableCount}
                            countDisplayed={ uniqBy(data, 'id').length }
                            forceLoad={() => this.setState({ dataLoaded: false, filter: { ...filter, forceLoad: true } }, () => this.loadData())}
                            customHeaders={ {
                                ...customHeaders,
                                id: i18n.number,
                                operatorName: i18n.operator,
                            } }
                            sortable
                            invertedHeaderStyle
                        />
                    )
                }
                return null
            case INSTALLATION_TYPE.INDUSTRIAL_SITE:
                const tableName = getLabel(installationsTypes, INSTALLATION_TYPE.INDUSTRIAL_SITE) || i18n.industrialSites
                return (
                    <Table
                        title={ tableName }
                        data={ data }
                        // paging initialSort={ this.props.selectedSearchValues.installation }
                        legendPanel={ this.getLegendPanel() }
                        paging
                        nbPerPageLabel={nbPerPage}
                        // onSort={ (value, sort) => this.onTableSort(value, sort) }
                        type={{ headers }}
                        onClick={s => this.props.push(`/station/installation/${s.id}`)}
                        condensed
                        sortable
                        count={installationTableCount}
                        countDisplayed={ uniqBy(data, 'id').length }
                        forceLoad={() => this.setState({ dataLoaded: false, filter: { ...filter, forceLoad: true } }, () => this.loadData())}
                        customHeaders={ {
                            activityLibelle: i18n.activity,
                        } }
                        invertedHeaderStyle
                    />
                )
            case INSTALLATION_TYPE.STEP:
                return (
                    <Table
                        title={i18n.STEP}
                        data={data}
                        paging
                        // initialSort={ this.props.selectedSearchValues.installation }
                        legendPanel={this.getLegendPanel()}
                        nbPerPageLabel={nbPerPage}
                        // onSort={ (value, sort) => this.onTableSort(value, sort) }
                        type={{ headers }}
                        onClick={s => this.props.push(`/station/installation/${s.id}`)}
                        condensed
                        count={installationTableCount}
                        countDisplayed={uniqBy(data, 'id').length}
                        forceLoad={() => this.setState({ dataLoaded: false, filter: { ...filter, forceLoad: true } }, () => this.loadData())}
                        sortable
                        customHeaders={{
                            operatorName: i18n.operator,
                            id: i18n.number,
                            rejectLabel: i18n.rejectType,
                        }}
                        invertedHeaderStyle
                    />
                )
            case INSTALLATION_TYPE.TANK:
                return (
                    <Table
                        title={i18n.tanks}
                        data={data}
                        paging
                        // initialSort={ this.props.selectedSearchValues.installation }
                        legendPanel={this.getLegendPanel()}
                        nbPerPageLabel={nbPerPage}
                        // onSort={ (value, sort) => this.onTableSort(value, sort) }
                        type={{ headers }}
                        onClick={s => this.props.push(`/station/installation/${s.id}`)}
                        condensed
                        count={installationTableCount}
                        countDisplayed={uniqBy(data, 'id').length}
                        forceLoad={() => this.setState({ dataLoaded: false, filter: { ...filter, forceLoad: true } }, () => this.loadData())}
                        sortable
                        customHeaders={{
                            operatorName: i18n.operator,
                            id: i18n.number,
                            tankTypeLabel: i18n.tankType,
                        }}
                        invertedHeaderStyle
                    />
                )
            default: // all installation types
                return (
                    <Table
                        title={ filter.installationType && getLabel(installationsTypes, filter.installationType) || i18n.installations }
                        data={ data }
                        paging
                        // initialSort={ this.props.selectedSearchValues.installation }
                        legendPanel={ this.getLegendPanel() }
                        nbPerPageLabel={nbPerPage}
                        // onSort={ (value, sort) => this.onTableSort(value, sort) }
                        type={{ headers }}
                        onClick={s => this.props.push(`/station/installation/${s.id}`)}
                        condensed
                        count={installationTableCount}
                        countDisplayed={ uniqBy(data, 'id').length }
                        forceLoad={() => this.setState({ dataLoaded: false, filter: { ...filter, forceLoad: true } }, () => this.loadData())}
                        sortable
                        customHeaders={ {
                            installationTypeLabel: i18n.type,
                            operatorName: i18n.operator,
                            id: i18n.number,
                        } }
                        invertedHeaderStyle
                    />
                )
        }
    }

    getPanel = () => {
        const data = this.getData()
        switch (this.state.panel) {
            case MAP:
                const stationsWithCoordinates = data.filter(s => s && (hasLocalisation(s) || hasLocalisationStation(s) || (s.townCode)))
                return (
                    <CartographyPanel layers={['STATIONS_POINTS']}
                        componentType={STATION_TYPE_NAME.installation}
                        stationsPoints={stationsWithCoordinates}
                        stationsPanelTitle={i18n.stations}
                        heightToSubstract={450}
                    />
                )
            default:
                return this.getTable(data)
        }
    }

    render() {
        // const installNetworks = uniq(this.props.networkLinks.map(l => l.idNetwork)).map(id => this.props.networks.find(n => id === n.id)).filter(c => !isUndefined(c))
        const { filter, isOpen, dataLoaded, panel, isValidateOpen, selectedFilter, tmpFilter, isOpenAdd } = this.state
        return (
            <div className='padding-top-1 padding-right-1 padding-left-1'>
                <InstallationsFilterForm
                    defaultFilter={filter}
                    panel={ panel }
                    onValidate={ this.onValidate }
                    onChangePanel={ this.onChangePanel }
                    contributorLinks={ this.props.contributorLinks }
                    // networklist={installNetworks}
                />
                <div className='col s12 no-padding' style={{ marginTop: -50, marginBottom: panel === STATION_LIST ? 0 : 60 }}>
                    <TabList
                        onChangeTab={(v) => this.setState(prev => ({ ...prev, panel: v }))}
                        tabs={
                            [
                                {
                                    value: STATION_LIST,
                                    label: i18n.table,
                                    icon: 'list',
                                },
                                {
                                    value: MAP,
                                    label: i18n.map,
                                    icon: 'map',
                                },
                            ]}
                    >
                        {dataLoaded ? this.getPanel() : <ProgressCard indeterminate={true} />}
                    </TabList>
                </div>
                {!!isOpen && (
                    <ImportDescDialog isOpen={isOpen} setIsOpen={v => this.setState({ isOpen: v })} />
                )}
                {!!isValidateOpen && (
                    <FilterDialog
                        parameters={selectedFilter?.link_parameters}
                        isOpen={isValidateOpen}
                        setIsOpen={v => this.setState({ isValidateOpen: v })}
                        launchSearch={(parameters) => this.launchSearch(tmpFilter, parameters)}
                    />
                )}
                {!!isOpenAdd && (
                    <NewInstallationDialog
                        open={isOpenAdd}
                        closeDialog={() => this.setState({ isOpenAdd: false })}
                        title={i18n.newInstallation}
                        onValidate={this.onValidateAdd}
                    />
                )}
            </div>
        )
    }
}

InstallationsDashboardApp.propTypes = getPropTypes(storeProps, {
    installationTable: arrayOf(DtoInstallationTableLine),
    industrialSiteTable: arrayOf(DtoIndustrialSiteTableLine),
    boreholeTable: arrayOf(DtoBoreholeTableLine),
    globalResearch: PropTypes.string,
    installationsTypes: arrayOf(DtoInstallationType),
    cities: arrayOf(CityDto),
    sandreIndex: objectOf(objectOf(DtoSandreCode)),
    sandreCodes: arrayOf(DtoSandreCode),
    push: PropTypes.func,
    activities: arrayOf(DtoInstallationActivity),
    activitiesIndex: objectOf(DtoInstallationActivity),
    installationTableCount: PropTypes.number,
    accountUser: PropTypes.instanceOf(User),
    citiesIndex: objectOf(CityDto),
    contributorLinks: PropTypes.arrayOf(PropTypes.instanceOf(DtoContributorLink)),
    contributorsIndex: PropTypes.arrayOf(PropTypes.instanceOf(ContributorItem)),
    networkLinks: PropTypes.arrayOf(PropTypes.instanceOf(DtoNetworkLink)),
    network: PropTypes.objectOf(PropTypes.instanceOf(NetworkDto)),
    settings: PropTypes.objectOf(PropTypes.instanceOf(SieauParameterDto)),
    networkTable: arrayOf(DtoNetworkTableLine),
})

const mapStateToProps = (store) => getMapStateToProps(storeProps, {
    installationTable: store.InstallationReducer.installationTable,
    industrialSiteTable: store.InstallationReducer.industrialSiteTable,
    boreholeTable: store.InstallationReducer.boreholeTable,
    installationTableCount: store.InstallationReducer.installationTableCount,
    globalResearch: store.HomeReducer.globalResearch,
    installationsTypes: store.InstallationReducer.installationsTypes,
    cities: store.CityReducer.cities,
    citiesIndex: store.CityReducer.citiesIndex,
    contributorLinks: store.StationReducer.contributorLinks,
    contributorsIndex: store.ContributorReducer.contributorsIndex,
    piezometers: store.PiezometryReducer.piezometersLight,
    activities: store.InstallationReducer.activities,
    activitiesIndex: store.InstallationReducer.activitiesIndex,
    sandreCodes: store.ReferencialReducer.sandreCodes,
    sandreIndex: store.ReferencialReducer.sandreIndex,
    accountUser: store.AccountReducer.accountUser,
    STEPTable: store.InstallationReducer.STEPTable,
    tankTable: store.InstallationReducer.tankTable,
    flowObstructionTable: store.InstallationReducer.flowObstructionTable,
    filters: store.StationReducer.filters,
    networkLinks: store.InstallationReducer.installationNetwork,
    networks: store.NetworkReducer.networks,
    settings: store.AdministrationReducer.applicationSettings,
    networkTable: store.InstallationReducer.networkTable,
})

const mapDispatchToProps = {
    fetchBoreholeTable: InstallationAction.fetchBoreholeTable,
    fetchInstallationTable: InstallationAction.fetchInstallationTable,
    fetchIndustrialSiteTable: InstallationAction.fetchIndustrialSiteTable,
    fetchSTEPTable: InstallationAction.fetchSTEPTable,
    fetchTankTable: InstallationAction.fetchTankTable,
    fetchFlowObstructionTable: InstallationAction.fetchFlowObstructionTable,
    fetchCities: CityAction.fetchCities,
    setPopup: SieauAction.setPopup,
    update: SieauAction.update,
    push,
    fetchAllContributors: StationAction.fetchAllContributors,
    updateGlobalResearch: HomeAction.updateGlobalResearch,
    setTitle: HomeAction.setTitle,
    fetchInstallationTypes: InstallationAction.fetchInstallationTypes,
    fetchPiezometersLight: PiezometryAction.fetchPiezometersLight,
    fetchActivities: InstallationAction.fetchActivities,
    fetchSandreCodes: ReferencialAction.fetchSandreCodes,
    exportInstallationsFull: ExportAction.exportInstallationsFull,
    export: ExportAction.export,
    warning: ToastrAction.warning,
    fetchFilterResults: StationAction.fetchFilterResults,
    setCache: AdministrationAction.setCache,
    fetchFilters: StationAction.fetchFilters,
    createInstallation: InstallationAction.createInstallation,
    fetchInstallNetworkLinks: InstallationAction.fetchInstallationNetwork,
    fetchNetworks: NetworkAction.fetchNetworks,
    fetchNetworkTable: InstallationAction.fetchNetworkTable,
}

export default connect(mapStateToProps, mapDispatchToProps)(InstallationsDashboardApp)
