import React from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import PropTypes from 'prop-types'
import Input from '../../../../components/forms/Input'
import { getSetting } from 'utils/SettingUtils'

const UserPasswordPanel = ({
    changePassword = () => {},
    changePasswordAgain = () => {},
}) => {
    const {
        applicationSettings,
    } = useSelector(store => ({
        applicationSettings: store.AdministrationReducer.applicationSettings,
    }), shallowEqual)

    return (
        <div className='row'>
            <div className='col s12'>
                <p>
                    { getSetting(applicationSettings, 'securityPasswordDescription') }
                </p>
                <div className='row no-margin'>
                    <Input
                        col={6}
                        title={i18n.newPassword}
                        passwordType={true}
                        value={''}
                        maxLength={128}
                        onChange={(password) => changePassword(password)}
                    />
                    <Input
                        col={6}
                        title={i18n.newPasswordAgain}
                        passwordType={true}
                        value={''}
                        maxLength={128}
                        onChange={(passwordAgain) => changePasswordAgain(passwordAgain)}
                    />
                </div>
            </div>
        </div>
    )
}

UserPasswordPanel.propTypes = {
    changePassword: PropTypes.func,
    changePasswordAgain: PropTypes.func,
}

export default UserPasswordPanel
