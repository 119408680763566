import { push } from '@lagunovsky/redux-react-router'
import HomeAction from 'home/actions/HomeAction'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import AppStore from 'store/AppStore'
import { H_HYDRO_CAMPAIGN } from '../../../account/constants/AccessRulesConstants'
import CampaignAction from '../../../campaign/actions/CampaignAction'
import DashboardCampaigns from '../../../campaign/components/DashboardCampaigns'
import CampaignDto from '../../../campaign/dto/CampaignDto'
import DtoCampaignProgress from '../../../campaign/dto/DtoCampaignProgress'
import ProgressCard from '../../../components/card/ProgressCard'
import SieauAction from '../../../components/sieau/SieauAction'
import { PATH_HYDROMETRY, PATH_HYDROMETRY_CAMPAIGN } from '../../../home/constants/RouteConstants'
import { componentHasHabilitations } from '../../../utils/HabilitationUtil'
import DtoHydrometricStation from '../../dto/DtoHydrometricStation'


class HydrometryCampaigns extends Component {
    state = {
        progress: 0,
        dataLoaded: false,
    }

    componentDidMount = () => {
        if (!componentHasHabilitations(H_HYDRO_CAMPAIGN)) { // A modifier quand react-router sera à jour
            this.props.push('/unauthorized')
            return
        }
        AppStore.dispatch(HomeAction.setHelpLink('hydrometry', ''))
        this.props.loadHydrometryCampaigns(p => this.setState({ progress: p }))
            .then(() => this.setState({ dataLoaded: true }))
        this.props.forceFetch('title', [{
            title: i18n.hydrometry,
            href: PATH_HYDROMETRY,
        }, {
            title: i18n.campaigns,
            href: PATH_HYDROMETRY_CAMPAIGN,
        }])
    }

    render = () => {
        const {
            progress,
            dataLoaded,
        } = this.state
        const {
            hydrometricStations,
            hydrometryCampaigns,
            hydrometryCampaignsProgress,
        } = this.props
        return dataLoaded ? (
            <DashboardCampaigns
                stations={hydrometricStations}
                campaigns={hydrometryCampaigns}
                campaignsProgress={hydrometryCampaignsProgress}
                stationType={'hydrometry'}
            />
        ) : (
            <ProgressCard progress={progress} />
        )
    }
}

HydrometryCampaigns.propTypes = {
    getLink: PropTypes.func,
    hydrometricStations: PropTypes.arrayOf(PropTypes.instanceOf(DtoHydrometricStation)),
    hydrometryCampaigns: PropTypes.arrayOf(PropTypes.instanceOf(CampaignDto)),
    hydrometryCampaignsProgress: PropTypes.arrayOf(PropTypes.instanceOf(DtoCampaignProgress)),

    forceFetch: PropTypes.func,
    loadHydrometryCampaigns: PropTypes.func,
    push: PropTypes.func,
}

const mapStateToProps = store => ({
    hydrometricStations: store.HydrometryReducer.hydrometricStations,
    hydrometryCampaigns: store.HydrometryReducer.hydrometryCampaigns,
    hydrometryCampaignsProgress: store.HydrometryReducer.hydrometryCampaignsProgress,
})

const mapDispatchToPorops = {
    forceFetch: SieauAction.forceFetch,
    loadHydrometryCampaigns: CampaignAction.loadHydrometryCampaigns,
    push,
}

export default connect(mapStateToProps, mapDispatchToPorops)(HydrometryCampaigns)
