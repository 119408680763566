import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import { isEqual, maxBy, omit } from 'lodash'
import WaitAction from 'wait/WaitAction'
import { getPowerSupplySetting } from '../../../../../materiel/utils/MaterielUtils'
import DtoStationPowerSupplyAssignment from '../../../../dto/materiel/DtoStationPowerSupplyAssignment'
import DtoMaterielState from '../../../../../materiel/dto/DtoMaterielState'
import MaterielAction from '../../../../../materiel/actions/MaterielAction'
import PowerSupplyDto from '../../../../../materiel/components/powerSupply/dto/PowerSupplyDto'
import PowerSupplyAction from '../../../../../materiel/components/powerSupply/actions/PowerSupplyAction'
import PowerSupplyTypeDto from '../../../../../materiel/components/powerSupply/dto/PowerSupplyTypeDto'
import StationAction from '../../../../actions/StationAction'
import PowerSupplyPanel from '../../../../../materiel/components/powerSupply/components/PowerSupplyPanel'
import SituationDetailPanel from '../SituationDetailPanel'
import DtoStation from '../../../../dto/DtoStation'
import DtoPowerSupplySituation from '../../../../../materiel/components/powerSupply/dto/DtoPowerSupplySituation'

class PowerSupplySituationDetail extends Component {
    state = {
        powerSupply: {},
        powerSupplyDefaultParam: {},
        previousPowerSupplySetting: {},
    }

    componentDidMount() {
        const {
            stationPowerSupplyAssignments,
            materielStates,
            powerSupplies,
            powerSupplyTypes,
        } = this.props
        if (!materielStates.length) {
            this.props.fetchMaterielStates()
        }
        if (!powerSupplies.length) {
            this.props.fetchPowerSupplies()
        }
        if (!powerSupplyTypes.length) {
            this.props.fetchPowerSupplyTypes()
        }
        this.fetchPreviousPowerSupplySetting(stationPowerSupplyAssignments)
    }

    componentDidUpdate(prevProps) {
        const { stationPowerSupplyAssignments } = this.props
        if (stationPowerSupplyAssignments.length !== prevProps.stationPowerSupplyAssignments.length) {
            this.fetchPreviousPowerSupplySetting(stationPowerSupplyAssignments)
        }
    }

    fetchPreviousPowerSupplySetting = stationPowerSupplyAssignments => {
        const lastSituation = maxBy(stationPowerSupplyAssignments, 'situationDate')
        if (lastSituation) {
            const lastMaterielId = lastSituation.idPowerSupply
            this.props.fetchPowerSupply(lastMaterielId).then(json =>
                this.setState({ previousPowerSupplySetting: getPowerSupplySetting(json) }))
        }
    }

    getPowerSupplyTitle = ({ serialNumber, reference }, { label }) => {
        const code = serialNumber || reference || ''
        return label ? `${label} - ${code}` : code || i18n.unknownMaterial
    }

    setPowerSupplyTypeParam = () => {
        const { powerSupplyType } = this.state.powerSupply
        const type = this.props.powerSupplyTypes.find(t => t.id === powerSupplyType) || {}
        this.setState({
            powerSupply: {
                ...this.state.powerSupply,
                ...omit(type, ['id', 'code', 'label', 'name', 'comment', 'updateDate', 'updateLogin', 'startDate', 'endDate', 'icon', 'headers']),
            },
        })
    }

    getPowerSupplyPanel = isEditMode => {
        const { powerSupply } = this.state
        if (powerSupply.id) {
            const powerSupplyType = this.props.powerSupplyTypes.find(t => t.id === powerSupply.powerSupplyType) || {}
            return (
                <div>
                    <div className='col s8 offset-s2'>
                        <div className='col s4'>
                            <a className='col s12 btn' onClick={() => this.setState({
                                powerSupply: {
                                    ...this.state.powerSupplyDefaultParam,
                                },
                            })}
                            >
                                {i18n.defaultSettings}
                            </a>
                        </div>
                        <div className='col s4'>
                            <a className='col s12 btn' onClick={this.setPowerSupplyTypeParam}>
                                {i18n.typeSettings}
                            </a>
                        </div>
                        <div className='col s4'>
                            <a className={`col s12 btn ${this.props.stationPowerSupplyAssignments.length ? '' : 'disabled'}`} onClick={() => this.setState({
                                powerSupply: {
                                    ...this.state.powerSupply,
                                    ...this.state.previousPowerSupplySetting,
                                },
                            })}
                            >
                                {i18n.previousSettings}
                            </a>
                        </div>
                    </div>
                    <div className='row col s10 offset-s1'>
                        <div className='card no-padding'>
                            <div className='col s12 card-title active'>
                                {this.getPowerSupplyTitle(powerSupply, powerSupplyType)}
                            </div>
                            <div className={'card-content margin-top-1'}>
                                <div className='row no-margin'>
                                    <PowerSupplyPanel id={powerSupply.id}
                                        powerSupply={powerSupply}
                                        onChange={changes => this.setState({ powerSupply: { ...this.state.powerSupply, ...changes } })}
                                        disabled={!isEditMode}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        return null
    }

    fetchPowerSupply = id => {
        this.props.waitStart()
        this.props.fetchPowerSupply(id).then(json => {
            this.setState({ powerSupply: json, powerSupplyDefaultParam: json })
            this.props.waitStop()
        })
    }

    updatePowerSupply = () => {
        if (!isEqual(this.state.powerSupply, this.state.powerSupplyDefaultParam)) {
            this.props.updatePowerSupply(this.state.powerSupply)
        }
    }

    resetPowerSupply = () => {
        this.props.resetPowerSupply()
        this.setState({ powerSupply: {}, powerSupplyDefaultParam: {} })
    }

    render = () => {
        const {
            station,
            powerSupplies,
            powerSuppliesLastSituations,
            powerSupplyTypes,
            stationPowerSupplyAssignments,
            addPowerSupplySituation,
            savePowerSupplySituation,
            deletePowerSupplySituation,
            params,
            type,
        } = this.props
        return (
            <SituationDetailPanel
                params={params}
                type={type}
                getMaterielPanel={isEditMode => this.getPowerSupplyPanel(isEditMode)}
                station={station}
                materiels={powerSupplies}
                lastSituations={powerSuppliesLastSituations}
                materielTypes={powerSupplyTypes}
                stationMaterielAssignments={stationPowerSupplyAssignments}
                addSituation={addPowerSupplySituation}
                saveSituation={savePowerSupplySituation}
                deleteSituation={deletePowerSupplySituation}
                fetchMateriel={this.fetchPowerSupply}
                updateMateriel={this.updatePowerSupply}
                resetMateriel={this.resetPowerSupply}
                keyMaterielType='powerSupplyType'
                keyMaterielId='idPowerSupply'
            />
        )
    }
}

PowerSupplySituationDetail.propTypes = {
    params: PropTypes.shape({
        id: PropTypes.string,
        materialType: PropTypes.string,
    }),
    type: PropTypes.string,
    station: PropTypes.instanceOf(DtoStation),

    stationPowerSupplyAssignments: PropTypes.arrayOf(PropTypes.instanceOf(DtoStationPowerSupplyAssignment)),
    materielStates: PropTypes.arrayOf(PropTypes.instanceOf(DtoMaterielState)),
    powerSupply: PropTypes.instanceOf(PowerSupplyDto),
    powerSupplies: PropTypes.arrayOf(PropTypes.instanceOf(PowerSupplyDto)),
    powerSuppliesLastSituations: PropTypes.arrayOf(PropTypes.instanceOf(DtoPowerSupplySituation)),
    powerSupplyTypes: PropTypes.arrayOf(PropTypes.instanceOf(PowerSupplyTypeDto)),

    resetPowerSupply: PropTypes.func,
    updatePowerSupply: PropTypes.func,
    fetchPowerSupply: PropTypes.func,
    fetchPowerSupplies: PropTypes.func,
    fetchMaterielStates: PropTypes.func,
    fetchPowerSupplyTypes: PropTypes.func,
    savePowerSupplySituation: PropTypes.func,
    addPowerSupplySituation: PropTypes.func,
    deletePowerSupplySituation: PropTypes.func,
    waitStart: PropTypes.func,
    waitStop: PropTypes.func,
}

const mapStateToProps = store => ({
    stationPowerSupplyAssignments: store.StationReducer.stationPowerSupplyAssignments,
    materielStates: store.MaterielReducer.materielStates,
    powerSupply: store.PowerSupplyReducer.powerSupply,
    powerSupplies: store.PowerSupplyReducer.powerSupplies,
    powerSuppliesLastSituations: store.PowerSupplyReducer.powerSuppliesLastSituations,
    powerSupplyTypes: store.PowerSupplyReducer.powerSupplyTypes,
})

const mapDispatchToProps = {
    resetPowerSupply: PowerSupplyAction.resetPowerSupply,
    updatePowerSupply: PowerSupplyAction.updatePowerSupply,
    fetchPowerSupply: PowerSupplyAction.fetchPowerSupply,
    fetchPowerSupplies: PowerSupplyAction.fetchPowerSupplies,
    fetchMaterielStates: MaterielAction.fetchMaterielStates,
    fetchPowerSupplyTypes: PowerSupplyAction.fetchPowerSupplyTypes,
    savePowerSupplySituation: StationAction.savePowerSupplySituation,
    addPowerSupplySituation: StationAction.addPowerSupplySituation,
    deletePowerSupplySituation: StationAction.deletePowerSupplySituation,
    waitStart: WaitAction.waitStart,
    waitStop: WaitAction.waitStop,
}

export default connect(mapStateToProps, mapDispatchToProps)(PowerSupplySituationDetail)