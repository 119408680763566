import Select from 'components/forms/Select'
import PropTypes from 'prop-types'
import { setIntegrationModesPopup } from 'import/utils/ImportUtils'
import React, { useEffect, useMemo } from 'react'
import { getIntegrationModes } from 'utils/JobUtils'
import i18n from 'simple-react-i18n'
import { Button, Grid } from '@mui/material'
import Checkbox from 'components/forms/Checkbox'
import useParseJson from 'utils/customHook/useParseJson'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import Icon from 'components/icon/Icon'
import CampaignAction from 'campaign/actions/CampaignAction'
import Job from 'import/dto/Job'
import ImportQualityDefaultValue from '../ImportQualityDefaultValue'
import ContributorAction from 'referencial/components/contributor/actions/ContributorAction'
import QualityAction from 'quality/actions/QualityAction'
import FractionAction from 'referencial/components/fraction/actions/FractionAction'
import SupportAction from 'referencial/components/support/actions/SupportAction'
import Input from 'components/forms/Input'
import InputIcon from '@mui/icons-material/Input'
import { setModal } from 'utils/FormUtils'
import ImportFileModal from './ImportFileModal'

const ImportOmnidiaPanel = ({
    job = {},
    onChangeJob = () => {},
    onChangeDataTypes = () => {},
    isEditMode = false,
}) => {
    const dispatch = useDispatch()
    const {
        qualityCampaigns,
        contributors,
        qualifications,
        status,
        fractions,
        supports,
    } = useSelector(store => ({
        qualityCampaigns: store.QualityReducer.qualityCampaigns,
        contributors: store.ContributorReducer.contributors,
        qualifications: store.QualityReducer.qualifications,
        status: store.QualityReducer.status,
        fractions: store.FractionReducer.fractions,
        supports: store.SupportReducer.supports,
    }), shallowEqual)

    useEffect(() => {
        if (!qualityCampaigns.length) {
            dispatch(CampaignAction.fetchQualityCampaigns())
        }
        if (!contributors.length) {
            dispatch(ContributorAction.fetchContributors())
        }
        if (!qualifications.length) {
            dispatch(QualityAction.fetchQualifications())
        }
        if (!status.length) {
            dispatch(QualityAction.fetchStatus())
        }
        if (!fractions.length) {
            dispatch(FractionAction.fetchFractions())
        }
        if (!supports.length) {
            dispatch(SupportAction.fetchSupports())
        }
    }, [dispatch])

    const dataType = job.parameters.dataTypes.find(d => d.includes('DATA')) ?? ''

    const {
        json: parameters,
    } = useParseJson(job.parameters.parameters[0] ?? '{}')

    const integrationModes = useMemo(getIntegrationModes, [])

    const helpIcon = (
        <Icon
            icon='help'
            style={{
                paddingLeft: 5,
                fontSize: 14,
                position: 'relative',
                top: '-49px',
                left: '214px',
                height: 0,
            }}
            tooltip={'Description des options'}
            onClick={setIntegrationModesPopup}
        />
    )

    const hydrobioCampaigns = useMemo(() => {
        return qualityCampaigns.filter(cs => cs.campaignType === 2) // hydrobio
    }, [qualityCampaigns])

    const onChangeDataIntegration = integration => {
        onChangeDataTypes(integration ? [integration] : [])
    }

    const onChange = (change) => {
        onChangeJob({
            parameters: {
                ...job.parameters,
                parameters: [JSON.stringify({ ...parameters, ...change })],
            },
        })
    }

    return (
        <Grid container spacing={1} alignItems='center'>
            <Grid item xs={4}>
                <Select
                    label={i18n.dataIntegration}
                    options={integrationModes}
                    value={dataType}
                    labelSpan={helpIcon}
                    obligatory
                    onChange={onChangeDataIntegration}
                    disabled={!isEditMode}
                />
            </Grid>
            <Grid item xs={4}>
                <Select
                    label={i18n.campaign}
                    options={hydrobioCampaigns}
                    value={parameters.campaignId}
                    onChange={v => onChange({ campaignId: v })}
                    disabled={!isEditMode}
                />
            </Grid>
            <Grid item xs={4}>
                <Checkbox
                    label={i18n.dontIntegrateUnknowTaxons}
                    checked={parameters.dontIntegrateTaxons}
                    onChange={v => onChange({ dontIntegrateTaxons: v }) }
                    disabled={!isEditMode}
                />
            </Grid>
            <Grid item xs={3.5}>
                <Input
                    title={i18n.selectDirectory}
                    value={parameters.directory}
                    obligatory
                    onChange={v => onChange({ directory: v })}
                    disabled={!isEditMode}
                />
            </Grid>
            <Grid item xs={0.5}>
                <Button
                    variant='contained'
                    color='primary'
                    fullWidth
                    onClick={() => {
                        setModal({
                            title: i18n.importParam,
                            content: <ImportFileModal url={parameters.directory} onChange={v => onChange({ filter: v })} />,
                        })
                    }}
                    disabled={!isEditMode}
                >
                    <InputIcon />
                </Button>
            </Grid>
            <Grid item xs={2}>
                <Input
                    title={i18n.filter}
                    value={parameters.filter}
                    obligatory
                    onChange={v => onChange({ filter: v })}
                    disabled={!isEditMode}
                />
            </Grid>
            <Grid item xs={2}>
                <Checkbox
                    checked={parameters.rename}
                    label={i18n.fileRenaming}
                    onChange={v => onChange({ rename: v })}
                    disabled={!isEditMode}
                />
            </Grid>
            <Grid item xs={4}>
                <Checkbox
                    checked={parameters.dontProcessFilesInLogs}
                    label={i18n.dontProcessFilesInLogs}
                    onChange={v => onChange({ dontProcessFilesInLogs: v })}
                    disabled={!isEditMode}
                />
            </Grid>
            <Grid item xs={12}>
                <ImportQualityDefaultValue
                    defaultValue={parameters}
                    onChange={onChange}
                    isEditMode={isEditMode}
                    hydrobio
                />
            </Grid>
        </Grid>
    )
}

ImportOmnidiaPanel.propTypes = {
    job: PropTypes.instanceOf(Job).isRequired,
    isEditMode: PropTypes.bool,
    onChangeDataTypes: PropTypes.func.isRequired,
    onChangeJob: PropTypes.func,
}

export default ImportOmnidiaPanel