import { useEffect, useState } from 'react'
import { chunk, isNil } from 'lodash'
import useAbortController from 'utils/customHook/useAbortController'
import useBoolean from 'utils/customHook/useBoolean'
import CatchmentAction from 'catchment/actions/CatchmentAction'
import DtoSumAnalysisLight from 'catchment/dto/DtoSumAnalysisLight'

const useFetchSumAnalysis = (filters) => {
    const {
        controllerRef,
        initController,
    } = useAbortController()

    const {
        value: isLoaded,
        setFalse: setNotLoaded,
        setTrue: setLoaded,
    } = useBoolean(false)
    const [progress, setProgress] = useState(0)

    const [sumAnalysis, setSumAnalysis] = useState([])

    useEffect(() => {
        if (filters.stations.length === 0 || isNil(filters.startDate) || isNil(filters.endDate) || filters.parameters.length === 0) return

        setNotLoaded()
        setProgress(0)
        initController()
        setSumAnalysis([])

        const groupSize = Math.min(Math.floor(filters.stations.length / 10), 10) || 1
        const chunkGroup = chunk(filters.stations, groupSize)

        const promises = chunkGroup.map(stations => {
            return CatchmentAction.getSumAnalysis({ ...filters, stations }, controllerRef.current.signal)
                .then(list => setSumAnalysis(p => [...p, ...list.map(a => new DtoSumAnalysisLight(a))]))
        })

        Promise.all(promises.map(promise => promise.then(() => setProgress(p => p + 100 / promises.length)).catch(() => []))).finally(() => !controllerRef.current.signal.aborted && setLoaded())

        // eslint-disable-next-line consistent-return
        return () => {
            controllerRef.current.abort()
        }
    }, [filters])

    return {
        sumAnalysis,
        isLoaded,
        progress,
    }
}

export default useFetchSumAnalysis