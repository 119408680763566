import { ceil, isEqual, orderBy, slice, some } from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import i18n from 'simple-react-i18n'
import AppStore from 'store/AppStore'
import { lightGrey } from 'utils/constants/ColorTheme'
import ExportAction from '../../export/actions/ExportAction'
import { formatData } from '../../utils/ExportDataUtil'
import { sieauTooltip } from '../../utils/FormUtils'
import { hasValue } from '../../utils/NumberUtil'
import { searchAllCharacters } from '../../utils/StringUtil'
import MoreVert from '../buttons/MoreVert'
import Input from '../forms/Input'
import Select from '../forms/Select'
import TableBody from './TableBody'
import TableBodyColor from './TableBodyColor'
import TableFilter from './TableFilter'
import TableHeader from './TableHeader'
import TablePagination from './TablePagination'
import { getSortedTableData } from './TableUtils'
import { LinearProgress } from '@mui/material'

// eslint-disable-next-line valid-jsdoc
/**
 * @deprecated Use NewTable or CardTable component instead.
 */
class Table extends Component {
    constructor(props) {
        super(props)
        this.state = {
            sortColumn: props.initialSort?.column ? props.initialSort : { column: '', sort: '' },
            filter: this.props.filter,
            page: props.initialPage || 1,
            nbPerPage: -1,
            checked: false,
            data: [],
        }
    }

    componentWillMount() {
        const newState = {
            datatable: this.props.data,
            checked: this.props.checked,
        }
        if (this.props.nbPerPageLabel && this.props.nbPerPageLabel.length > 0) {
            newState.nbPerPage = this.props.nbPerPageLabel[0].value
        }
        this.setState(newState)
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.data.length !== this.props.data.length) {
            const {
                page,
                nbPerPage,
            } = this.state
            const lastPage = nbPerPage !== -1 ? ceil(nextProps.data.length / nbPerPage) : 1
            if (page > lastPage) {
                this.changePage(lastPage)
            } else if (page === 0) {
                this.changePage(1)
            }
        }
    }

    onSort = value => {
        if (this.state.data.length && !some(this.state.data, obj => obj[value] && obj[value].type)) {
            let sort = 'asc'
            if (this.state.sortColumn.sort === sort && this.state.sortColumn.column === value) {
                sort = 'desc'
            }
            this.props.onSort(value, sort)
            this.setState({ sortColumn: { column: value, sort } })
        }
    }

    onFilter = (e) => {
        this.setState({ filter: e.target.value })
    }

    changePage = (page) => {
        this.setState({ page })
        if (this.props.onChangePage) {
            this.props.onChangePage(page)
        }
    }

    changeNbPerPage = (nbPerPage) => {
        const {
            page,
        } = this.state
        const lastPage = nbPerPage !== -1 ? ceil(this.props.data.length / nbPerPage) : 1
        this.setState({ nbPerPage, page: page > lastPage ? lastPage : page })
    }

    export = (body) => {
        return [].concat(this.props.type, body).map((element) => {
            return Object.keys(element).map((key) => element[key])
        })
    }

    onChangeValue = (value) => {
        this.setState({
            filter: value.toString(),
            page: 1,
        })
    }

    getKeyResearch = () => {
        if (this.props.type.headers) {
            return this.props.type.headers
        }
        return Object.key(this.props.type).map((o) => {
            return o
        })
    }

    getDataHash = (d) => {
        return this.getKeyResearch().map(key => {
            if (hasValue(d[key]) && hasValue(d[key].value)) {
                return d[key].value.toString()
            }
            if (hasValue(d[key])) {
                return d[key].toString()
            }
            return ''
        }).join('   ')
    }

    searchableFunction = () => {
        if (this.state.filter) {
            return orderBy(
                this.props.data.filter(d => searchAllCharacters(this.getDataHash(d)).includes(searchAllCharacters(this.state.filter))),
                [d => hasValue(d.name) && hasValue(d.name.value) ? d.name.value.length : (hasValue(d.name) ? d.name.length : 0)])
        }
        return this.props.data
    }

    getValues = (object) => {
        return object.map(key => object[key])
    }

    submitExport = (store, action, exportType, body, titleFile) => {
        store.dispatch(action(this.export(body), exportType, titleFile))
    }

    exportData = (functionElement, data) => {
        if (functionElement.transformFn) {
            const headers = this.getHeaders().map(h => {
                return { value: i18n[h] }
            })
            const exportData = data.map(d => functionElement.transformFn(d))
            exportData.splice(0, 0, headers)
            return exportData
        }
        return data
    }

    onChange = (value) => {
        this.setState({
            checked: value,
        })
    }

    onSubmit = () => {
        const exportData = []
        for (let idx = 0; idx < this.dataCheck.length; idx++) {
            const dataLine = this.dataCheck[idx]
            if (this.refs[idx].getValue()) {
                exportData.push(dataLine)
            }
        }
        return exportData
    }

    getActions = (props = []) => {
        const properties = [...props]
        if (this.props.checkable) {
            return [].concat(['check'], properties)
        }
        if (this.props.duplicable) {
            properties.unshift('dupl')
        }
        if (this.props.alterable) {
            properties.unshift('alt')
        }
        if (this.props.deletable) {
            properties.unshift('del')
        }
        return properties
    }

    getHeaders = () => {
        const properties = (() => {
            if (this.props.type.headers) {
                return this.props.type.headers
            }
            return Reflect.ownKeys(this.props.type)
        })()
        return this.getActions(properties)
    }

    getTablePagination = (data) => {
        if (this.props.paging && this.state.nbPerPage != -1) {
            return (<TablePagination nbElement={ data.length } index={ this.state.page } legendPanel={ this.props.data.length ? this.props.legendPanel : null }
                nbElementPerPage={ this.state.nbPerPage } smallPaging={ this.props.smallPaging }
                onDisplayMore={ this.props.onDisplayMore }
                count={ this.props.count } countDisplayed={ this.props.countDisplayed } forceLoad={ this.props.forceLoad }
                onChangePage={ this.changePage }
            />)
        }
        return null
    }

    componentDidMount() {
        this.setState({ data: this.searchableFunction() })
        if (this.props.legendPanel) {
            $('.dropdown-button').dropdown({ belowOrigin: true, constrainWidth: false })
        }
        if (this.props.fixed) {
            $(`#${this.props.id}`).tableHeadFixer(
                {
                    head: true,
                    left: this.props.fixed,
                    zIndex: 999,
                },
            )
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.filter !== prevState.filter || !isEqual(prevProps.data, this.props.data)) {
            this.setState({ data: this.searchableFunction() })
        }
        $('.tooltipped').tooltip('remove')
        $('.material-tooltip').remove()
        $('.tooltipped').tooltip({ delay: 50, html: true })
        if (this.props.fixed && this.props.data.length > 0) {
            $(`#${this.props.id}`).tableHeadFixer(
                {
                    head: true,
                    left: this.props.fixed,
                    zIndex: 999,
                },
            )
        }
        if (this.props.legendPanel) {
            $('.dropdown-button').dropdown({ belowOrigin: true, constrainWidth: false })
        }
    }

    getExportDatas = () => {
        AppStore.dispatch(ExportAction.export(formatData(this.props?.exportData?.length > 0 ? this.props.exportData : this.props.data), 'xlsx', this.props.exportName))
    }

    render() {
        // Searchable
        let data = getSortedTableData(this.state.data, this.state.sortColumn, this.props.color)
        const nbElement = this.props.showNbElements ? (() => {
            const num = hasValue(this.props.count) ? this.props.count : data.length
            const label = `${num} ${num > 1 ? i18n.elements : i18n.element}`
            return <i className='nbElements' id='nb_elements' style={{ color: `${this.props.invertedHeaderStyle ? 'black' : 'white'}` }}>{`(${label})`}</i>
        })() : null

        const headers = this.props.showIcon ? [this.props.headerIcon, ...this.getHeaders()] : this.getHeaders()
        const head = ((sortable) => {
            if (sortable) {
                return (<thead>
                    <TableHeader headers={headers} customWidthHeaders={this.props.customWidthHeaders} onSort={this.onSort}
                        condensed={ this.props.condensed } checked={ this.state.checked }
                        checkable={ this.props.checkable } customHeaders={ this.props.customHeaders }
                        headersOnClick={ this.props.headersOnClick }
                        onChange={ this.onChange }
                        centerNumberHeaders={ this.props.centerNumberHeaders }
                        exportButtonOnHeader={ this.props.exportButtonOnHeader }
                        headersTip={ this.props.headersTip }
                        headersIcon={ this.props.headersIcon }
                    />
                </thead>)
            }
            return ''
        })(this.props.sortable)

        // Paging
        const tablePagination = (() => {
            if (this.props.paging && !this.props.smallPaging && data.length > this.props.nbPerPageLabel[0].value) {
                return (
                    <Select
                        options={ this.props.nbPerPageLabel }
                        onChange={this.changeNbPerPage}
                        noSort
                        value={ this.state.nbPerPage }
                        keyLabel='label'
                        integerValue
                        noNullValue
                        id={'input_nb_per_page'}
                    />
                )
            }
            return null
        })()

        const pagination = this.getTablePagination(data)
        if (this.state.nbPerPage != -1) {
            data = slice(data, (this.state.page - 1) * this.state.nbPerPage, (this.state.page * this.state.nbPerPage))
        }

        // Body
        const body = (this.props.inverseDisplay ? data.reverse() : data).map((d, id) => {
            if (d.displayLoadingRow) {
                return <tr><td colSpan={headers.length}><LinearProgress sx={{ margin: '10px' }} /></td></tr>
            }
            if (this.props.color) {
                return (
                    <TableBodyColor
                        data={ d }
                        key={ id }
                        ref={id}
                        index={id}
                        editable={ this.props.editable }
                        link={ this.props.link }
                        subLink={ this.props.subLink }
                        condensed={ this.props.condensed }
                        bodyId={ id }
                        checked={ this.state.checked }
                        checkable={ this.props.checkable }
                        headers={ headers }
                        onClick={this.props.onClick}
                        deletable={this.props.deletable}
                        onDelete={this.props.onDelete}
                        deleteTooltip={this.props.deleteTooltip}
                        // duplicable={this.props.duplicable}
                        // onDuplicate={this.props.onDuplicate}
                        alterable={this.props.alterable}
                        onAlter={this.props.onAlter}
                        onLineOver={ this.props.onLineOver }
                        onLineOut={ this.props.onLineOut }
                    />
                )
            }
            return (
                <TableBody
                    data={ d }
                    key={ id }
                    ref={ id }
                    index={ id }
                    editable={ this.props.editable }
                    deletable={ this.props.deletable }
                    onDelete={ this.props.onDelete }
                    deleteTooltip={this.props.deleteTooltip}
                    duplicable={ this.props.duplicable }
                    onDuplicate={ this.props.onDuplicate }
                    alterable={ this.props.alterable }
                    onAlter={ this.props.onAlter }
                    link={ this.props.link }
                    subLink={ this.props.subLink }
                    condensed={ this.props.condensed }
                    onLineOver={ this.props.onLineOver }
                    checked={ this.state.checked }
                    checkable={ this.props.checkable }
                    onLineOut={ this.props.onLineOut }
                    onClick={ this.props.onClick }
                    headers={ headers }
                    active={ this.props.active }
                    coloredLine={this.props.coloredLine}
                    noFlexCell={this.props.noFlexCell}
                />
            )
        })

        this.dataCheck = data
        // Collapsable
        const icon = (() => {
            return ''
        })()

        const moreVert = (() => {
            if (this.props.actions.length > 0) {
                return (<MoreVert links={ this.props.actions }/>)
            }
            return ''
        })()

        const collapseTable = (() => {
            if (icon) {
                let tableFilter = ''
                if (this.props.searchable) {
                    tableFilter = <TableFilter onFilter={ this.onFilter }/>
                }
                return (<div className='card collapse collapseTable'>
                    <div className='well'>
                        { tableFilter }
                    </div>
                </div>)
            }
            return ''
        })()

        const exports = (() => {
            if (this.props.exportFunction && this.props.exportFunction.length > 0 && data.length > 0) {
                return this.props.exportFunction.map((functionElement) => {
                    const iconMaterial = functionElement.iconName || 'insert_chart'
                    const className = functionElement.color ? `${functionElement.color} btn-floating` : 'red btn-floating'
                    const titleFile = functionElement.titleFile || 'export'

                    const exportData = this.exportData(functionElement, functionElement.full ? this.props.data : data)
                    return (
                        <span className='icon-table-toolbar'>
                            <a
                                className={className}
                                onClick={ () => this.submitExport(functionElement.store, functionElement.action, functionElement.exportType, exportData, titleFile) }
                            >
                                <i className='material-icons'>{iconMaterial}</i>
                            </a>
                        </span>
                    )
                })
            }
            return ''
        })()
        const paginationMenu = (() => {
            if (this.props.paging && data.length) {
                return (
                    <div className='row no-margin'>
                        <div className='col s12 no-padding'>
                            <div className='row no-margin valign-wrapper'>
                                <div className={ this.props.smallPaging ? '' : 'col s2' }>
                                    { tablePagination }
                                </div>
                                <div className={ this.props.smallPaging ? 'col s12' : 'col s10' }>
                                    { pagination }
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
            return null
        })()

        const exportButtonOnHeader = (() => {
            if (this.props.exportButtonOnHeader) {
                const tooltip = sieauTooltip(i18n.export, null, 'bottom')
                return (
                    <i className='material-icons right'
                        {...tooltip}
                        id='button_export_table'
                        onClick={() => this.getExportDatas()}
                        style={{ color: `${this.props.invertedHeaderStyle ? 'black' : 'white'}` }}
                    >
                        file_download
                    </i>
                )
            }
            return null
        })()

        const style = this.props.invertedHeaderStyle ? { color: 'black', backgroundColor: 'white', borderBottom: `solid 1px ${lightGrey}`, borderTop: `solid 1px ${lightGrey}` } : {}
        const styleHeader = this.props.round ? { borderRadius: '5px 5px 0 0', ...style, ...this.props.styleHeader } : { ...style, ...this.props.styleHeader }

        const headerTitle = (() => {
            const activeClass = this.props.activeHeader ? ' active' : ''
            if (this.props.searchable) {
                return (
                    <div className={`card-title activator col s12${activeClass}`} style={styleHeader}>
                        {moreVert ? (
                            <div>
                                <div className='col s4'>
                                    { icon }{ this.props.title } { nbElement }
                                </div>
                                <Input title={ i18n.search } value={ this.state.filter } col={ 6 } className='icon-color-black'
                                    onEnterKeyPress={(value) => {
                                        this.onChangeValue(value)
                                        this.props.onChangeFilter(value)
                                    }}
                                />
                                <div className='col s2'>{ moreVert }</div>
                            </div>
                        ) : (
                            <div>
                                <div className='col s6'>
                                    { icon }{ this.props.title } { nbElement }
                                </div>
                                <Input title={ i18n.search } value={ this.state.filter } col={ 6 } className='icon-color-black'
                                    onEnterKeyPress={(value) => {
                                        this.onChangeValue(value)
                                        this.props.onChangeFilter(value)
                                    }}
                                />
                            </div>
                        )}
                    </div>
                )
            }
            return (
                <div className={`card-title activator no-margin${activeClass}`} style={styleHeader}>
                    <div className='row no-margin'>
                        <div className='col s12 no-padding'>
                            { icon }{ this.props.title } { nbElement }
                            { moreVert } { exportButtonOnHeader }
                        </div>
                    </div>
                </div>
            )
        })()
        const { showTitle, noHightlight, fixed, className, styleContainer, maxHeight, height, overflow, tableClassName, id } = this.props
        const header = (() => {
            if (showTitle) {
                return headerTitle
            }
            return null
        })()
        const highlight = noHightlight ? '' : 'highlight'
        const fixedStyle = fixed ? { paddingLeft: '0', marginLeft: '0.75rem' } : {}
        return (
            <div className={`${this.props.withCard ? 'card' : ''} no-margin ${className}`} style={this.props.round ? { borderRadius: 5, ...styleContainer } : styleContainer }>
                { header }
                <div className='right text-center no-margin'>{ exports }</div>
                <div className='card-content no-padding' style={this.props.round ? { borderRadius: !this.props.showTitle ? 5 : '0 0 5px 5px', ...styleContainer } : styleContainer}>
                    <div className='dataTables_wrapper dt-material no-footer'>
                        { collapseTable }
                        <div className={`row no-margin ${maxHeight ? 'scrollable-card' : ''}`} style={maxHeight ? { maxHeight } : {}}>
                            <div className='col s12' style={{ height: `${height}px`, overflow: overflow || 'auto', ...fixedStyle }}>
                                <table
                                    className={`${highlight} table row-border order-column datatable no-footer responsive-table no-padding ${tableClassName}`}
                                    id={ id }
                                >
                                    { head }
                                    <tbody id={`${id}_body`} data-cy={`${id}_body`}>
                                        { body }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        { paginationMenu }
                    </div>
                </div>
            </div>
        )
    }
}

Table.propTypes = {
    icon: PropTypes.string,
    data: PropTypes.arrayOf(PropTypes.object),
    type: PropTypes.object,
    title: PropTypes.string,
    sortable: PropTypes.bool,
    searchable: PropTypes.bool,
    editable: PropTypes.bool,
    duplicable: PropTypes.bool,
    onDuplicate: PropTypes.func,
    alterable: PropTypes.bool,
    onAlter: PropTypes.func,
    deletable: PropTypes.bool,
    deleteTooltip: PropTypes.string,
    onDelete: PropTypes.func,
    onSort: PropTypes.func,
    initialSort: PropTypes.object,
    className: PropTypes.string,
    customHeaders: PropTypes.object,
    headersOnClick: PropTypes.object,
    paging: PropTypes.bool,
    smallPaging: PropTypes.bool,
    nbPerPageLabel: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.number,
    })),
    initialPage: PropTypes.number,
    onChangePage: PropTypes.func,
    link: PropTypes.string,
    subLink: PropTypes.string,
    condensed: PropTypes.bool,
    showNbElements: PropTypes.bool,
    showTitle: PropTypes.bool,
    actions: PropTypes.arrayOf(PropTypes.shape({
        href: PropTypes.string,
        onClick: PropTypes.func,
        color: PropTypes.string,
        iconName: PropTypes.string.isRequired,
        tooltip: PropTypes.string,
        tooltipPosition: PropTypes.string,
    })),
    onClick: PropTypes.func,
    onLineOver: PropTypes.func,
    onLineOut: PropTypes.func,
    onChangeFilter: PropTypes.func,
    exportFunction: PropTypes.arrayOf(PropTypes.shape({
        store: PropTypes.object.isRequired,
        action: PropTypes.func.isRequired,
        exportType: PropTypes.string.isRequired,
        titleFile: PropTypes.string,
        iconName: PropTypes.string,
        color: PropTypes.string,
    })),
    color: PropTypes.bool,
    id: PropTypes.string,
    height: PropTypes.number,
    checked: PropTypes.bool,
    checkable: PropTypes.bool,
    fixed: PropTypes.number,
    exportButtonOnHeader: PropTypes.bool,
    exportName: PropTypes.string,
    exportData: PropTypes.arrayOf(PropTypes.element),
    activeHeader: PropTypes.bool,
    active: PropTypes.bool,
    tableClassName: PropTypes.string,
    filter: PropTypes.string,
    overflow: PropTypes.string,
    legendPanel: PropTypes.element,
    coloredLine: PropTypes.bool,
    maxHeight: PropTypes.string,
    inverseDisplay: PropTypes.bool,
    onDisplayMore: PropTypes.func,
    showIcon: PropTypes.bool,
    centerNumberHeaders: PropTypes.bool,
    headerIcon: PropTypes.string,
    headersTip: PropTypes.objectOf(PropTypes.string), // permet de mettre un tooltip sur les headers
    headersIcon: PropTypes.objectOf(PropTypes.shape({ // permet de mettre une icone sur les headers
        icon: PropTypes.string,
        color: PropTypes.string,
    })),
    noHightlight: PropTypes.bool,
    noFlexCell: PropTypes.bool,
    count: PropTypes.number,
    countDisplayed: PropTypes.number,
    withCard: PropTypes.bool,
    forceLoad: PropTypes.func, // utilisée lorsque l'utilisateur clique sur le bouton pour forcer le chargement intégral (ex: liste des installations)
    styleContainer: PropTypes.shape({}),
    styleHeader: PropTypes.shape({}),
    customWidthHeaders: PropTypes.shape({}), /* permet de mettre une taille (en %) à toutes les colonnes du tableau
                                               attention 1 - la propriété ne set pas le maxWidth de la colonne => si le label dépasse la taille n'est plus respectée
                                               attention 2 - toutes les colonnes doivent être set dans l'objet pour que le code soit mis en application
                                               exemple de d'objet attendu : {
                                                    type: '20%',
                                                    usage: '40%',
                                                    requestedYearVolumeDisplay: '20%',
                                                    attributedVolumeDisplay: '20%',
                                                } => headers du tableau set avec headers: ['type', 'usage', 'requestedYearVolumeDisplay', 'attributedVolumeDisplay'] */
    invertedHeaderStyle: PropTypes.bool,
    round: PropTypes.bool,
}

Table.defaultProps = {
    data: [],
    sortable: false,
    searchable: false,
    editable: false,
    deletable: false,
    duplicable: false,
    alterable: false,
    paging: false,
    nbPerPageLabel: [],
    actions: [],
    customHeaders: {},
    headersTip: {},
    headersIcon: {},
    headersOnClick: {},
    color: false,
    condensed: false,
    id: 'dataTableComponent',
    showNbElements: true,
    showIcon: false,
    showTitle: true,
    exportButtonOnHeader: false,
    exportName: 'Export',
    exportData: [],
    tableClassName: '',
    onLineOver: () => {},
    onLineOut: () => {},
    onChangeFilter: () => { },
    onSort: () => {},
    onClick: undefined,
    onDuplicate: () => {},
    onAlter: () => {},
    onChangePage: () => {},
    className: '',
    activeHeader: false,
    active: true,
    filter: '',
    overflow: '',
    coloredLine: false,
    customWidthHeaders: {},
    withCard: true,
}

export default Table
