/* eslint-disable consistent-return */
import { isNil } from 'lodash'
import { useCallback, useEffect, useState } from 'react'

const getStorageValue = (key) => {
    const storeValue = window.localStorage.getItem(key)
    if (isNil(storeValue)) return
    try {
        return JSON.parse(decodeURIComponent(window.atob(storeValue)))
    } catch (_) {
        return decodeURIComponent(window.atob(storeValue))
    }
}

/**
 * Hook to store data in the local storage
 * @param {string} key - The default value of the list
 * @param {*} defaultValue - The default value of the list
 * @param {function} overrideValue - A function to override the store value
 * @return {[*, function, function]} The value, the setter and a function to remove the property from the storage
 */
const useLocalStorage = (key, defaultValue, overrideValue) => {
    const [value, setValue] = useState(() => {
        const storedValue = getStorageValue(key)
        if (isNil(storedValue)) {
            if (typeof defaultValue === 'function') {
                return defaultValue()
            }
            return defaultValue
        }
        if (typeof overrideValue === 'function') {
            return overrideValue(storedValue)
        }
        return storedValue
    })

    useEffect(() => {
        if (value === undefined) {
            return window.localStorage.removeItem(key)
        }
        window.localStorage.setItem(key, window.btoa(encodeURIComponent(JSON.stringify(value))))
    }, [key, value])

    const remove = useCallback(() => {
        setValue(undefined)
    }, [])

    return [value, setValue, remove]
}

export default useLocalStorage