import PowerSupplyTypeDto from 'materiel/components/powerSupply/dto/PowerSupplyTypeDto'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import Checkbox from '../../../../components/forms/Checkbox'
import NumberField from '../../../../components/forms/NumberField'
import SensorTypeDto from '../../../../materiel/components/sensor/dto/DtoSensorType'
import { hasValue } from '../../../../utils/NumberUtil'
import { EVENT_ACTION } from '../../../constants/PiezometryEventConstants'
import { getTitleCard, getYesOrNo } from '../EventFormUtils'

const EventSensorCard = ({
    sensor = {},
    formActions = [],
    disabled = {},
    lastReplacementDate = '',
    setFormAction = () => { },
    getBooleanChoiceWithAction = () => { },
    sensorTypes = [],
    displaySupply = true,
    powerSupplyTypes = [],
    hasPowerSupply = true,
}) => {
    const powerSupplyType = powerSupplyTypes.find(t => t.id === sensor.powerSupplyType)
    return (
        <div className='card margin-top-1'>
            {getTitleCard(i18n.sensor, 'sensor', sensor, sensorTypes)}
            <div className='card-content margin-top-1'>
                {
                    hasPowerSupply && (
                        <div className='row no-margin'>
                            <div className='col s8'>
                                {
                                    displaySupply && (
                                        <div className='row no-margin'>
                                            <NumberField
                                                value={(formActions.find(a => a.managementRule === EVENT_ACTION.TENSION_PILES_CAPTEUR && a.materielId === sensor.id) || {}).value}
                                                title={i18n.sensorVoltage}
                                                floatValue
                                                onChange={v => setFormAction(EVENT_ACTION.TENSION_PILES_CAPTEUR, v, sensor.id, hasValue(v))}
                                                {...disabled}
                                            />
                                        </div>
                                    )
                                }
                                <div className='row no-margin'>
                                    <h6>
                                        {`${i18n.lastReplacement} : ${lastReplacementDate}`}
                                    </h6>
                                </div>
                            </div>
                            <div className='col s3 offset-s1'>
                                <Checkbox
                                    label={i18n.replacement}
                                    checked={formActions.find(a => a.managementRule === EVENT_ACTION.REMPLACEMENT_PILES_CAPTEUR && a.materielId === sensor.id)}
                                    onChange={v => setFormAction(EVENT_ACTION.REMPLACEMENT_PILES_CAPTEUR, null, sensor.id, v)}
                                    active={disabled.active}
                                    disabled={powerSupplyType?.isNotReplaceable || disabled.disabled}
                                />
                            </div>
                        </div>
                    )
                }
                <div className='row no-margin'>
                    <div className='col s8'>
                        <table>
                            {getYesOrNo()}
                            {getBooleanChoiceWithAction(i18n.replaceDesiccant, EVENT_ACTION.DESSICANT_REMPLACE, sensor.id, disabled)}
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}

EventSensorCard.propTypes = {
    sensor: PropTypes.object,
    formActions: PropTypes.arrayOf(PropTypes.object),
    disabled: PropTypes.object,
    lastReplacementDate: PropTypes.string,
    setFormAction: PropTypes.func,
    getBooleanChoiceWithAction: PropTypes.func,
    sensorTypes: PropTypes.arrayOf(PropTypes.instanceOf(SensorTypeDto)),
    displaySupply: PropTypes.bool,
    powerSupplyTypes: PropTypes.arrayOf(PropTypes.instanceOf(PowerSupplyTypeDto)),
    hasPowerSupply: PropTypes.bool,
}

const mapStateToProps = store => ({
    sensorTypes: store.SensorReducer.sensorTypes,
    powerSupplyTypes: store.PowerSupplyReducer.powerSupplyTypes,
})

export default connect(mapStateToProps)(EventSensorCard)