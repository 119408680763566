import React, { useCallback, useEffect, useMemo, useState } from 'react'
import i18n from 'simple-react-i18n'
import { push } from '@lagunovsky/redux-react-router'
import { flatten, some, sortBy } from 'lodash'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import CityAction from 'referencial/components/city/actions/CityAction'
import useTitle from 'utils/customHook/useTitle'
import useActions from 'utils/customHook/useActions'
import useProgressDispatch from 'utils/customHook/useProgressDispatch'
import ProgressCard from 'components/card/ProgressCard'
import DashboardAddStationDialog from 'station/components/dashboard/component/DashboardAddStationDialog'
import DashboardMapPanel from 'station/components/dashboard/component/DashboardMapPanel'
import DashboardStationsPanel from 'station/components/dashboard/component/DashboardStationsPanel'
import { MAP, STATION_LIST, STATION_TYPE_NAME } from 'station/constants/StationConstants'
import HomeAction from 'home/actions/HomeAction'
import AdministrationAction from 'administration/actions/AdministrationAction'
import { getUser } from 'utils/SettingUtils'
import { getLabel } from 'utils/StoreUtils'
import { getUserBookmarksByStationType } from 'utils/UserUtil'
import { findStationType, getBookmarks } from 'utils/StationUtils'
import { getSandreLabel, getSandreMnemonique, searchAllCharacters } from 'utils/StringUtil'
import { SANDRE } from 'referencial/constants/ReferencialConstants'
import { hasValue } from 'utils/NumberUtil'
import DistributionUnitAction from 'distributionUnit/actions/DistributionUnitAction'
import { componentHasHabilitations } from 'utils/HabilitationUtil'
import { H_DISTRIBUTION_DASHBOARD } from 'account/constants/AccessRulesConstants'
import UserAction from 'administration/components/user/actions/UserAction'
import StationAction from 'station/actions/StationAction'
import ContributorAction from 'referencial/components/contributor/actions/ContributorAction'
import QualityAction from 'quality/actions/QualityAction'
import ReferencialAction from 'referencial/action/ReferencialAction'
import ManagementUnitAction from 'referencial/components/managementUnit/actions/ManagementUnitAction'
import useApplicationSetting from 'utils/customHook/useApplicationSetting'
import { DEFAULT_CONTRIBUTOR_TYPE, FILTER_TYPE } from 'administration/components/user/constants/UserConstants'
import SimpleTabList from 'components/list/SimpleTabList'
import DistributionUnitFilterPanel from './DistributionUnitFilterPanel'


const SEARCH_VALUE_HEADERS = ['number', 'code', 'name', 'city', 'status', 'SISEeaucode', 'creationDate', 'stationTypeLabel', 'operator', 'administrator', 'referent', 'department']
const DISTRIBUTION_UNITS_HEADERS = ['code', 'name', 'sector', 'status', 'waterNature', 'operator', 'collectivity', 'exploitationModeCode', 'subscriberNumber', 'productionQuantity', 'distributedQuantity']
const stationType = STATION_TYPE_NAME.distributionUnit

const DistributionUnitsPanel = ({
}) => {
    const dispatch = useDispatch()

    const {
        userBookmarks,
        contributorLinks,
        contributors,
        sandreCodes,
        distributionUnits,
        distributionUnitSectors,
        selectedSearchValues,
        globalResearch,
    } = useSelector(store => ({
        userBookmarks: store.UserReducer.userBookmarks,
        contributorLinks: store.StationReducer.contributorLinks,
        contributors: store.ContributorReducer.contributors,
        sandreCodes: store.ReferencialReducer.sandreCodes,
        distributionUnits: store.DistributionUnitReducer.distributionUnits,
        distributionUnitSectors: store.DistributionUnitReducer.distributionUnitSectors,
        selectedSearchValues: store.AdministrationReducer.selectedSearchValues,
        globalResearch: store.HomeReducer.globalResearch,
    }), shallowEqual)

    const [open, setOpen] = useState(false)
    const [filter, setFilter] = useState(selectedSearchValues[stationType]?.filter ?? FILTER_TYPE.NOFILTER)
    const [filterResults, setFilterResults] = useState(selectedSearchValues[stationType]?.filterResults || [])
    const [searchValue, setSearchValue] = useState(globalResearch || selectedSearchValues[stationType]?.searchValue || '')
    const [city, setCity] = useState()
    const [sector, setSector] = useState()

    useEffect(() => {
        if (globalResearch) {
            dispatch(HomeAction.updateGlobalResearch(''))
        }
    }, [])

    const onChangeSearchValue = (value) => {
        setSearchValue(value)
        dispatch(AdministrationAction.setSelectedSearchValues(stationType, { searchValue: value }))
    }

    const getContributor = useCallback((up, ref) => {
        const contributorLink = contributorLinks.find(cl => up.id === cl.idStation && cl.contributorType === ref && !cl.endDate)
        const contributor = contributorLink && contributors.find(c => contributorLink.idContributor === c.id)
        return contributor && (contributor.mnemonique || contributor.name) || ''
    }, [contributorLinks, contributors])

    const operator = useApplicationSetting('contributorTypeOperator', d => parseInt(d)) || DEFAULT_CONTRIBUTOR_TYPE.OPERATOR
    const administrator = useApplicationSetting('contributorTypeAdministrator', d => parseInt(d)) || DEFAULT_CONTRIBUTOR_TYPE.ADMINISTRATOR

    const distributionUnitsFormatted = useMemo(() => {
        if (distributionUnits.length) {
            return sortBy(distributionUnits, o => o.name ? o.name.toUpperCase() : '}')
                .map(udi => ({
                    ...udi,
                    sector: getLabel(distributionUnitSectors, udi.id, 'name', 'distributionUnitId'),
                    sectorObj: distributionUnitSectors.find(s => s.distributionUnitId === udi.id),
                    nullValue: getBookmarks(udi.code, getUserBookmarksByStationType(userBookmarks, 'distributionUnit', udi.code)),
                    waterNature: getSandreLabel(sandreCodes, SANDRE.NATURE_EAU, udi.waterNature),
                    operator: getContributor(udi, operator),
                    collectivity: getContributor(udi, administrator),
                    exploitationModeCode: getSandreMnemonique(sandreCodes, SANDRE.MODE_EXPLOITATION, udi.exploitationModeCode),
                    status: getSandreLabel(sandreCodes, SANDRE.CODE_ETAT, udi.state),
                }))
        }
        return []
    }, [distributionUnits, distributionUnitSectors, userBookmarks, sandreCodes, getContributor, operator, administrator])

    const distributionUnitsFiltered = useMemo(() => {
        const udiBySector = (sector && sector !== FILTER_TYPE.NOFILTER) ? distributionUnitsFormatted.filter(udi => udi.sectorObj?.code === sector) : distributionUnitsFormatted
        return city ? udiBySector.filter(udi => udi.geographicSector?.split(',')?.includes(city)) : udiBySector
    }, [city, distributionUnitsFormatted, sector])

    const containsSearchValue = useCallback((station) =>
        some(SEARCH_VALUE_HEADERS, prop => station[prop]
            ? searchAllCharacters(station[prop].toString()).includes(searchAllCharacters(searchValue))
            : false,
        ), [searchValue])

    const data = useMemo(() => {
        const searchFiltered = searchValue ? distributionUnitsFiltered.filter(s => containsSearchValue(s)) : distributionUnitsFiltered

        const defaultResult = {
            title: i18n.distributionUnits,
            type: { headers: ['nullValue', ...DISTRIBUTION_UNITS_HEADERS] },
        }

        if (!hasValue(filter) || `${filter}` === `${FILTER_TYPE.NOFILTER}`) {
            return { ...defaultResult, stations: searchFiltered }
        } else if (`${filter}` === `${FILTER_TYPE.BOOKMARK}`) {
            const bookmarks = getUserBookmarksByStationType(userBookmarks, stationType)
            return { ...defaultResult, stations: flatten(bookmarks.map(code => searchFiltered.find(s => s.code.includes(code)) || [])) }
        }
        if (filterResults.length) {
            return { ...defaultResult, stations: flatten(filterResults.map(fr => searchFiltered.find(station => station.id === parseInt(fr.id)) || [])) }
        }
        return { ...defaultResult, stations: [] }
    }, [searchValue, distributionUnitsFiltered, filter, filterResults, containsSearchValue, userBookmarks])

    useActions(() => {
        const defaultActions = {
            export: () => {
                return {
                    data: data.stations,
                    exportType: 'xlsx',
                    titleFile: data.title,
                }
            },
        }
        const currentUser = getUser()
        const newActions = (currentUser.admin === '1' || currentUser.metadata === '1') ? {
            ...defaultActions,
            new: () => setOpen(true),
        } : defaultActions
        return newActions
    }, [data])

    const closeDialog = () => setOpen(false)

    const onValidate = (newElement) => {
        dispatch(DistributionUnitAction.createDistributionUnit(newElement, id => {
            closeDialog()
            dispatch(push(`/station/${stationType}/${id}/description`))
        }))
    }

    return (
        <div style={{ marginTop: '10px', marginRight: '10px' }}>
            <SimpleTabList
                defaultTab={STATION_LIST}
                tabs={[
                    {
                        constant: STATION_LIST,
                        label: i18n.table,
                        icon: 'list',
                    },
                    {
                        constant: MAP,
                        label: i18n.map,
                        icon: 'map',
                    },
                ]}
                noPadding
                headerComponent={
                    <DistributionUnitFilterPanel
                        stationType={stationType}
                        data={distributionUnitsFormatted}
                        onChangeSearchValue={onChangeSearchValue}
                        setFilterResults={setFilterResults}
                        setFilter={setFilter}
                        setSector={setSector}
                        setCity={setCity}
                        defaultSearchValue={searchValue}
                        defaultFilter={filter}
                        defaultFilterResults={filterResults}
                        defaultSector={sector}
                        defaultCity={city}
                    />
                }
            >
                {
                    tab => (
                        <>
                            {tab === STATION_LIST && <DashboardStationsPanel stationType={stationType} data={data} />}
                            {tab === MAP && <DashboardMapPanel stationType={stationType} data={data} />}
                        </>
                    )
                }
            </SimpleTabList>
            <DashboardAddStationDialog
                open={open}
                closeDialog={closeDialog}
                stations={distributionUnits}
                title={i18n.newDistributionUnit}
                onValidate={onValidate}
            />
        </div>
    )
}

DistributionUnitsPanel.propTypes = {
}

const DistributionUnitsDashboard = ({
}) => {
    const {
        cities,
        userBookmarks,
        contributorLinks,
        contributors,
        sandreCodes,
        status,
        distributionUnits,
    } = useSelector(store => ({
        cities: store.CityReducer.cities,
        userBookmarks: store.UserReducer.userBookmarks,
        contributorLinks: store.StationReducer.contributorLinks,
        contributors: store.ContributorReducer.contributors,
        sandreCodes: store.ReferencialReducer.sandreCodes,
        status: store.QualityReducer.status,
        distributionUnits: store.DistributionUnitReducer.distributionUnits,
    }), shallowEqual)

    const dispatch = useDispatch()

    useEffect(() => {
        if (!componentHasHabilitations(H_DISTRIBUTION_DASHBOARD)) {
            dispatch(push('/unauthorized'))
        }
    }, [dispatch])

    const { isLoaded, progress } = useProgressDispatch(() => {
        const promises = !userBookmarks.length ? [UserAction.fetchBookmarks] : []
        const findedStationType = findStationType(stationType)
        const promisesContributorsLinks = (!contributorLinks.length && findedStationType.code != 9) ? [...promises, () => StationAction.fetchAllContributors(findedStationType.code)] : promises
        const promisesContributors = !contributors.length ? [...promisesContributorsLinks, ContributorAction.fetchContributors] : promisesContributorsLinks
        const promisesStatus = !status.length ? [...promisesContributors, QualityAction.fetchStatus] : promisesContributors
        const promisesSandreCodes = !sandreCodes.length ? [...promisesStatus, ReferencialAction.fetchSandreCodes] : promisesStatus
        const promisesCities = !cities.length ? [...promisesSandreCodes, CityAction.fetchCities] : promisesSandreCodes
        const promisesDistributionUnit = [
            ...promisesCities,
            () => DistributionUnitAction.fetchDistributionUnitSectors(distributionUnits),
            ManagementUnitAction.fetchManagementUnits,
        ]
        return promisesDistributionUnit.map(p => dispatch(p()))
    }, [])

    useTitle(() => [{
        title: i18n[stationType],
        href: stationType,
    }, {
        title: i18n.dashboard,
        href: stationType,
    }], [stationType])

    return isLoaded ? (
        <DistributionUnitsPanel />
    ) : (
        <div style={{ marginTop: '10px', marginRight: '10px' }}>
            <ProgressCard progress={progress} withMessage />
        </div>
    )
}

DistributionUnitsDashboard.propTypes = {
}

export default DistributionUnitsDashboard
