import User from 'account/dto/User'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import { authorizeFilter } from 'utils/HabilitationUtil'
import { Button, Dialog, Grid } from '@mui/material'
import { DialogActionsMUI, DialogContentMUI, DialogTitleMUI } from 'components/styled/Dialog'
import Input from '../../../../components/forms/Input'
import NumberField from '../../../../components/forms/NumberField'
import Select from '../../../../components/forms/Select'
import Textarea from '../../../../components/forms/Textarea'
import Row from '../../../../components/react/Row'
import ContactDto from '../../../../referencial/components/contact/dto/ContactDto'
import { SANDRE } from '../../../../referencial/constants/ReferencialConstants'
import DtoSandreCode from '../../../../referencial/dto/DtoSandreCode'
import { getHour } from '../../../../utils/DateUtil'
import { onChangeHour } from '../../../../utils/FormUtils'
import { getSandreList } from '../../../../utils/StoreUtils'
import DtoAccessibility from '../../../dto/DtoAccessibility'
import Icon from 'components/icon/Icon'
import SimpleDatePicker from 'components/forms/SimpleDatePicker'

class AccessibilityModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            accessibility: {
                startDate: moment().valueOf(),
                ...props.accessibility,
                idStation: props.id,
            },
            initialOrder: props.accessibility.order,
        }
    }

    componentDidUpdate(prevProps) {
        if (!prevProps.popupIsOpen && this.props.popupIsOpen) {
            $('#descriptionModal').on('click', this.onSave)
        }
    }

    onSave = () => {
        this.props.saveResult(new DtoAccessibility({ ...this.state.accessibility }), this.state.initialOrder)
        $('.modal').modal('close')
    }

    onChangeElement = changes => this.setState({
        accessibility: {
            ...this.state.accessibility,
            ...changes,
        },
    })

    render() {
        const {
            accountUser,
            contacts,
            sandreCodes,
        } = this.props
        const {
            startDate,
            endDate,
            contactCode,
            order,
            codeCondition,
            closingCode,
            description,
        } = this.state.accessibility
        return (
            <Dialog
                fullWidth
                maxWidth='lg'
                open={open}
            >
                <DialogTitleMUI style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    {i18n.newAccessibility}
                    <Icon style={{ color: 'white' }} size='small' icon='close' onClick={this.props.onCancel} />
                </DialogTitleMUI>
                <DialogContentMUI className='padding-top-1'>
                    <Row>
                        <SimpleDatePicker
                            col={6}
                            value={startDate}
                            label={i18n.startDate}
                            onChange={v => this.onChangeElement({ startDate: v })}
                            max={endDate}
                        />
                        <Input col={ 6 } title={ i18n.startHour } value={ getHour(startDate) }
                            onChange={ v => onChangeHour(v, v2 => this.onChangeElement({ startDate: v2 }), { max: endDate }, startDate) }
                        />
                    </Row>
                    <Row>
                        <SimpleDatePicker
                            col={6}
                            value={endDate}
                            label={i18n.endDate}
                            onChange={v => this.onChangeElement({ endDate: v })}
                            min={startDate}
                        />
                        <Input col={ 6 } title={ i18n.endHour } value={ getHour(endDate) }
                            onChange={ v => onChangeHour(v, v2 => this.onChangeElement({ endDate: v2 }), { min: startDate }, endDate) }
                        />
                    </Row>
                    <div className='row no-margin padding-top-1'>
                        <Select
                            col={ 6 }
                            label={ i18n.contact }
                            options={authorizeFilter(contacts, accountUser, contactCode, 'contributor')}
                            keyValue='code'
                            keyLabel='name'
                            keyFilter={['name', 'socialReason']}
                            onChange={ v => this.onChangeElement({ contactCode: v }) }
                            value={ contactCode }
                            displayWithCode
                        />
                        <NumberField
                            col={ 2 }
                            value={ order }
                            min={ 1 }
                            title={ i18n.order }
                            disabled={!!endDate}
                            onChange={ v => this.onChangeElement({ order: v }) }
                        />
                    </div>
                    <div className='row no-margin padding-top-1'>
                        <Select
                            col={ 6 }
                            label={ i18n.conditionType }
                            options={ getSandreList(sandreCodes, SANDRE.ACCESSIBILITIES_TYPE_CONDITION) }
                            onChange={ v => this.onChangeElement({ codeCondition: v }) }
                            value={ codeCondition }
                            nullLabel='&nbsp;'
                        />
                        <Select
                            col={ 6 }
                            options={ getSandreList(sandreCodes, SANDRE.ACCESSIBILITIES_TYPE_FERMETURE) }
                            label={ i18n.closingType }
                            onChange={ v => this.onChangeElement({ closingCode: v }) }
                            value={ closingCode }
                            nullLabel='&nbsp;'
                        />
                    </div>
                    <div className='row no-margin padding-top-1'>
                        <Textarea
                            col={ 12 }
                            title={ i18n.description }
                            value={ description }
                            onChange={ v => this.onChangeElement({ description: v }) }
                        />
                    </div>
                </DialogContentMUI>
                <DialogActionsMUI>
                    <Grid xs={12} container justifyContent='flex-end' alignItems='flex-end'>
                        <Grid className='padding-left-1'>
                            <Button
                                variant='contained'
                                onClick={this.onSave}
                            >
                                {i18n.save}
                            </Button>
                        </Grid>
                    </Grid>
                </DialogActionsMUI>
            </Dialog>
        )
    }
}

AccessibilityModal.propTypes = {
    accessibility: PropTypes.instanceOf(DtoAccessibility),
    id: PropTypes.number,
    popupIsOpen: PropTypes.bool,
    saveResult: PropTypes.func,
    onCancel: PropTypes.func,
    contacts: PropTypes.arrayOf(PropTypes.instanceOf(ContactDto)),
    sandreCodes: PropTypes.arrayOf(PropTypes.instanceOf(DtoSandreCode)),
    accountUser: PropTypes.instanceOf(User),
}


const mapStateToProps = store => ({
    popupIsOpen: store.SieauReducer.popupIsOpen,
    contacts: store.ContactReducer.contacts,
    sandreCodes: store.ReferencialReducer.sandreCodes,
    accountUser: store.AccountReducer.accountUser,
})

AccessibilityModal.defaultProps = {
    accessibility: {},
}

export default connect(mapStateToProps)(AccessibilityModal)
