import { getOptions } from '../EChartUtils'

class _CustomEventDuration {
    constructor(obj) {
        this.obj = obj
    }

    getDefaultOptions = () => {
        return {
            name: 'custom chart',
            type: 'custom',
            data: [],
            areaStyle: {
                normal: {},
            },
            renderItem: (_, api) => {
                const start = api.coord([api.value(0), 0])
                const end = api.coord([api.value(1), 0])
                const height = api.size([0, 1])[1]
                return {
                    type: 'rect',
                    shape: {
                        x: start[0],
                        y: start[1] - height/2,
                        width: end[0] - start[0],
                        height,
                    },
                    style: api.style(),
                }
            },
            itemStyle: {
                normal: {
                    color: 'blue',
                },
            },
        }
    }

    getJson = () => {
        return getOptions(this.obj, this.getDefaultOptions(), {
            color: () => (
                {
                    areaStyle: {
                        normal: {
                            color: this.obj.color,
                        },
                    },
                    itemStyle: {
                        normal: {
                            color: this.obj.color,
                        },
                    },
                }),
        })
    }
}

export default function CustomEventDuration(args) {
    return new _CustomEventDuration(args)
}
