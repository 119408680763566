import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { push } from '@lagunovsky/redux-react-router'
import { flatten, isUndefined, some, sortBy, uniq } from 'lodash'
import PropTypes from 'prop-types'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import CityAction from 'referencial/components/city/actions/CityAction'
import i18n from 'simple-react-i18n'
import TabList from 'components/list/TabList'
import { Grid } from '@mui/material'
import useTitle from 'utils/customHook/useTitle'
import useActions from 'utils/customHook/useActions'
import useProgressDispatch from 'utils/customHook/useProgressDispatch'
import ProgressCard from 'components/card/ProgressCard'
import DashboardMapPanel from 'station/components/dashboard/component/DashboardMapPanel'
import DashboardStationsPanel from 'station/components/dashboard/component/DashboardStationsPanel'
import { MAP, STATION_LIST, STATION_TYPE_NAME } from 'station/constants/StationConstants'
import HomeAction from 'home/actions/HomeAction'
import AdministrationAction from 'administration/actions/AdministrationAction'
import { getUserBookmarksByStationType } from 'utils/UserUtil'
import { findStationType, getBookmarks, getStationType } from 'utils/StationUtils'
import { getObjectLabel } from 'utils/StoreUtils'
import { getDate } from 'utils/DateUtil'
import { searchAllCharacters } from 'utils/StringUtil'
import { hasValue } from 'utils/NumberUtil'
import { getUser } from 'utils/SettingUtils'
import PluviometryAction from 'pluviometry/actions/PluviometryAction'
import StationStatisticPanel from 'station/components/dashboard/component/keyfigure/StationStatisticPanel'
import { componentHasHabilitations } from 'utils/HabilitationUtil'
import { H_PLUVIO_DASHBOARD } from 'account/constants/AccessRulesConstants'
import UserAction from 'administration/components/user/actions/UserAction'
import StationAction from 'station/actions/StationAction'
import ContributorAction from 'referencial/components/contributor/actions/ContributorAction'
import QualityAction from 'quality/actions/QualityAction'
import ReferencialAction from 'referencial/action/ReferencialAction'
import StationFilterFields from 'station/components/search/StationFilterFields'
import NetworkAction from 'referencial/components/network/actions/NetworkAction'
import useApplicationSetting, { intParser } from 'utils/customHook/useApplicationSetting'
import { DEFAULT_CONTRIBUTOR_TYPE } from 'administration/components/user/constants/UserConstants'
import AddPluvioStepper from '../addPluvio/AddPluvioStepper'

const DEFAULT_FILTER = -1
const SEARCH_VALUE_HEADERS = ['number', 'code', 'name', 'city', 'SISEeaucode', 'creationDate', 'stationTypeLabel', 'operator', 'administrator', 'referent', 'department']
const PLUVIOMETRY_HEADERS = ['code', 'cityCode', 'cityLabel', 'name', 'creationDate', 'closeDate', 'administrator']
const stationType = STATION_TYPE_NAME.pluviometry

const PluviometryPanel = () => {
    const {
        citiesIndex,
        pluviometers,
        userBookmarks,
        selectedSearchValues,
        globalResearch,
        contributorLinks,
        networkLinks,
        networks,
    } = useSelector(store => ({
        citiesIndex: store.CityReducer.citiesIndex,
        pluviometers: store.PluviometryReducer.pluviometers,
        userBookmarks: store.UserReducer.userBookmarks,
        selectedSearchValues: store.AdministrationReducer.selectedSearchValues,
        globalResearch: store.HomeReducer.globalResearch,
        contributorLinks: store.StationReducer.contributorLinks,
        networkLinks: store.PluviometryReducer.networkPluvioLink,
        networks: store.NetworkReducer.networks,
    }), shallowEqual)

    const operatorType = useApplicationSetting('contributorTypeOperator', intParser) || DEFAULT_CONTRIBUTOR_TYPE.OPERATOR
    const referentType = useApplicationSetting('contributorTypeAdministrator', intParser) || DEFAULT_CONTRIBUTOR_TYPE.ADMINISTRATOR

    const [isOpen, setIsOpen] = useState(false)
    const [panel, setPanel] = useState(STATION_LIST)
    const [filters, setFilters] = useState(() => {
        const {
            filter: filterCode = -1,
            searchValue = '',
            network = undefined,
            filterResults = [],
            referentIds = [],
            operatorIds = [],
        } = selectedSearchValues.pluviometry || {}
        return {
            filter: filterCode,
            filterResults,
            referentIds,
            operatorIds,
            network,
            searchValue: globalResearch || searchValue,
        }
    })
    const dispatch = useDispatch()

    useEffect(() => {
        if (globalResearch) {
            dispatch(HomeAction.updateGlobalResearch(''))
        }
    }, [])

    const pluviometersFormatted = useMemo(() => {
        if (pluviometers.length) {
            return sortBy(pluviometers, o => o.name ? o.name.toUpperCase() : '}').map(s => ({
                ...s,
                nullValue: getBookmarks(s.code, getUserBookmarksByStationType(userBookmarks, 'pluviometry', s.code)),
                city: { value: getObjectLabel(citiesIndex[s.townCode], 'labelWithCode') },
                name: s.name || '',
                cityCode: s.townCode,
                cityLabel: getObjectLabel(citiesIndex[s.townCode], 'name'),
                creationDate: getDate(s.creationDate),
                closeDate: getDate(s.closeDate),
                stationType: s.stationType ? getStationType(parseInt(s.stationType)).libelle : '',
                headers: PLUVIOMETRY_HEADERS,
            }))
        }
        return []
    }, [citiesIndex, pluviometers, userBookmarks])

    const containsSearchValue = useCallback((station) =>
        some(SEARCH_VALUE_HEADERS, prop => station[prop] ?
            searchAllCharacters(station[prop].toString()).includes(searchAllCharacters(filters.searchValue))
            : false)
    , [filters.searchValue])

    const data = useMemo(() => {
        dispatch(AdministrationAction.setSelectedSearchValues(stationType, { searchValue: filters.searchValue, filter: filters.filter, filterResults: filters.filterResults, operatorIds: filters.operatorIds, referentIds: filters.referentIds, network: filters.network }))
        const searchFiltered = pluviometersFormatted.filter(s => containsSearchValue(s))

        const defaultResult = {
            title: i18n.watchpoints,
            type: { headers: ['nullValue', ...PLUVIOMETRY_HEADERS] },
        }

        const operatorFiltered = (() => {
            if (!filters.operatorIds?.length) return searchFiltered

            const filteredLinks = contributorLinks
                .filter(l => l.contributorType === operatorType && filters.operatorIds.includes(l.idContributor))

            return searchFiltered.filter(s => filteredLinks.some(l => l.idStation === s.id))
        })()

        const referentFiltered = (() => {
            if (!filters.referentIds?.length) return operatorFiltered

            const filteredLinks = contributorLinks
                .filter(l => l.contributorType === referentType && filters.referentIds.includes(l.idContributor))

            return operatorFiltered.filter(s => filteredLinks.some(l => l.idStation === s.id))
        })()

        const networkFiltered = !isUndefined(filters.network) ? networkLinks
            .filter(l => filters.network === l.idNetwork)
            .map(l => referentFiltered.find(s => s.id === l.idPluviometer))
            .filter(s => !!s) : referentFiltered

        if (hasValue(filters.filter) && filters.filter !== DEFAULT_FILTER && !!filters.filterResults.length) {
            return { ...defaultResult, stations: flatten(filters.filterResults.map(stationResult => networkFiltered.find(station => station.id === stationResult.id) || [])) }
        }

        return { ...defaultResult, stations: networkFiltered }
    }, [pluviometersFormatted, filters.filter, filters.filterResults, filters.referentIds, filters.operatorIds, filters.network, containsSearchValue, userBookmarks])

    useActions(() => {
        const defaultActions = {
            export: () => {
                return {
                    data: data.stations,
                    exportType: 'xlsx',
                    titleFile: data.title,
                }
            },
        }
        const currentUser = getUser()
        const newActions = (currentUser.admin === '1' || currentUser.metadata === '1') ? {
            ...defaultActions,
            new: () => setIsOpen(true),
        } : defaultActions
        return newActions
    }, [data])

    const pluvioNetworks = uniq(networkLinks.map(l => l.idNetwork)).map(id => networks.find(n => id === n.id)).filter(c => !isUndefined(c))

    return (
        <Grid container spacing={2} className='padding-right-1'>
            <Grid container item xs={12} justifyContent='flex-end' sx={{ paddingBottom: '1rem' }}>
                <Grid item xs={3}>
                    <StationStatisticPanel stationType={stationType} round />
                </Grid>
            </Grid>
            <Grid item xs={10}>
                <StationFilterFields
                    defaultFilter={filters}
                    onValidate={setFilters}
                    stationType={stationType}
                    stations={pluviometersFormatted}
                    contributorLinks={contributorLinks}
                    networklist={pluvioNetworks}
                />
            </Grid>
            <Grid item xs={12} style={{ marginTop: -60 }}>
                <TabList
                    onChangeTab={setPanel}
                    tabs={[
                        {
                            value: STATION_LIST,
                            label: i18n.table,
                            icon: 'list',
                        },
                        {
                            value: MAP,
                            label: i18n.map,
                            icon: 'map',
                        },
                    ]}
                >
                    {panel === STATION_LIST && <DashboardStationsPanel stationType={stationType} data={data} />}
                    {panel === MAP && <DashboardMapPanel stationType={stationType} data={data} />}
                </TabList>
            </Grid>
            <AddPluvioStepper
                isOpen={isOpen}
                setIsOpen={setIsOpen}
            />
        </Grid>
    )
}

PluviometryPanel.propTypes = {
    stationType: PropTypes.string,
}

const PluviometersDashboard = ({
}) => {
    const {
        cities,
        userBookmarks,
        sandreCodes,
        status,
    } = useSelector(store => ({
        cities: store.CityReducer.cities,
        userBookmarks: store.UserReducer.userBookmarks,
        sandreCodes: store.ReferencialReducer.sandreCodes,
        status: store.QualityReducer.status,
    }), shallowEqual)

    const dispatch = useDispatch()

    useEffect(() => {
        if (!componentHasHabilitations(H_PLUVIO_DASHBOARD)) {
            dispatch(push('/unauthorized'))
        }
    }, [dispatch])

    const { isLoaded, progress } = useProgressDispatch(() => {
        const promises = !userBookmarks.length ? [UserAction.fetchBookmarks] : []
        const findedStationType = findStationType(stationType)
        const promisesContributorsLinks = [...promises, () => StationAction.fetchAllContributors(findedStationType.code)]
        const promisesContributors = [...promisesContributorsLinks, ContributorAction.fetchContributors]
        const promisesStatus = !status.length ? [...promisesContributors, QualityAction.fetchStatus] : promisesContributors
        const promisesSandreCodes = !sandreCodes.length ? [...promisesStatus, ReferencialAction.fetchSandreCodes] : promisesStatus
        const promisesCities = !cities.length ? [...promisesSandreCodes, CityAction.fetchCities] : promisesSandreCodes
        const promisesNetworkLinks = [...promisesCities, PluviometryAction.fetchNetworkPluvioLink]
        const promisesNetworks = [...promisesNetworkLinks, NetworkAction.fetchNetworks]
        return promisesNetworks.map(p => dispatch(p()))
    }, [])

    useTitle(() => [{
        title: i18n[stationType],
        href: stationType,
    }, {
        title: i18n.dashboard,
        href: stationType,
    }], [stationType])

    return isLoaded ? (
        <PluviometryPanel />
    ) : (
        <Grid container sx={{ padding: '1rem' }}>
            <Grid item xs={12}>
                <ProgressCard progress={progress} withMessage />
            </Grid>
        </Grid>
    )
}

export default PluviometersDashboard
