import ColorConstant from 'components/constants/ColorConstant'
import moment from 'moment'
import React from 'react'
import i18n from 'simple-react-i18n'
import ColorfulCard from '../components/card/ColorfulCard'
import Icon from '../components/icon/Icon'
import SieauAction from '../components/sieau/SieauAction'
import EventsAction from '../events/actions/EventsAction'
import { INSTALLATION_TYPE } from '../installation/constants/InstallationConstants'
import EventCard from '../station/components/event/EventCard'
import AppStore from '../store/AppStore'
import { getEventColor } from './ColorUtil'
import { getDayDiff } from './DateUtil'
import { capitalizeFirstLetter, getI18nTitleDataLength } from './StringUtil'
import { STATION_TYPE_CONSTANT } from 'station/constants/StationConstants'

const getColorQualityCampaign = (ending, nbIntegrated, nbAll) => {
    const eDate = new Date(ending)
    const now = new Date()
    if (eDate < now) {
        return ColorConstant.GREY
    } else if (nbIntegrated === nbAll) {
        return ColorConstant.GREEN
    }
    return (getDayDiff(eDate, now) < 31) ? ColorConstant.RED : ColorConstant.ORANGE
}

const getCurrentCampaign = (campaignsProgress, campaigns) => campaigns.reduce((acc, c) => {
    const {
        nbStation = 0,
        nbStationValidated = 0,
    } = campaignsProgress.find(cp => cp.id === c.id) || {}
    if (c.statut === 2 && nbStation !== 0 && nbStation !== nbStationValidated) {
        return [
            ...acc,
            c,
        ]
    }
    return acc
}, [])

const getColorCampaign = (isArchived, progress, statut) => {
    if (isArchived === true) {
        return ColorConstant.GREY
    } else if (statut == 2 && progress === 100) {
        return ColorConstant.GREEN
    } else if (statut == 1 || !statut) {
        return ColorConstant.LIGHTGREY
    } else if (statut == 2 && progress <= 100) {
        return ColorConstant.ORANGE
    }
    return ColorConstant.WHITE
}

const getPiezometeryCampaignCompletion = (stationsObj) => {
    if (!stationsObj || !stationsObj.stations.length) {
        return 0
    }
    return stationsObj.stations.reduce((acc, station) => {
        const threshold = station.previsionalVisitNumber
        if (station.campaignEvents.length >= threshold) {
            return acc + 1
        }
        return acc + (station.campaignEvents.length / threshold)
    }, 0) / stationsObj.stations.length
}

const getValidPiezometerCampaignStations = (piezometerCampaignStations, piezometers) => {
    return piezometerCampaignStations.reduce((acc, station) => {
        const existingStation = piezometers.find(p => p.id === station.stationId)
        if (existingStation) {
            return [
                ...acc,
                station,
            ]
        }
        return acc
    }, [])
}

const getCampaignType = (letter) =>{
    switch (letter) {
        case 'M':
            return 2
        case 'G':
            return 1
        default:
            return letter
    }
}

const getConvertRequestType = campaignType => {
    switch (campaignType) {
        case 1: return 'G'
        case 2: return 'M'
        default: return ''
    }
}

const getEventsClosureModal = (events, title = '', callback = () => {}) => {
    const onCloseEvent = e => {
        AppStore.dispatch(EventsAction.updateEvent('piezometry', e.stationId, e.number, { ...e, closeDate: moment().valueOf() }, () => {
            $('.modal').modal('close')
            callback()
        }))
    }
    const onDeleteEvent = e => {
        AppStore.dispatch(EventsAction.deleteEvent('piezometry', e.stationId, e.number, () => {
            $('.modal').modal('close')
            callback()
        }))
    }
    const actions = (
        <div>
            <a className='waves-effect waves-teal btn-flat modal-close'>{ i18n.close }</a>
        </div>
    )
    const popup = {
        id: 'closureEventsModal',
        header: title,
        actions,
        content: events.map(e => (
            <div className='row no-margin valign-wrapper'>
                <div className='col s11'>
                    <ColorfulCard color={ getEventColor(e.eventType) }>
                        <div className='row no-margin modal-close'>
                            <EventCard event={ e } small link={ `/station/piezometry/${e.stationId}/event/` } stationType={'piezometry'} />
                        </div>
                    </ColorfulCard>
                </div>
                <div className='col s1 center-align'>
                    <Icon icon='lock' size='medium' className='blue-text' tooltip={i18n.closeEvent} onClick={() => onCloseEvent(e)} />
                    <Icon icon='delete' size='medium' className='blue-text' tooltip={i18n.deleteEvent} onClick={() => onDeleteEvent(e)} />
                </div>
            </div>
        )),
    }
    AppStore.dispatch(SieauAction.setPopup(popup))
}

const isInstallationType = (installationType) => {
    return installationType === INSTALLATION_TYPE.BOREHOLE
        || installationType === INSTALLATION_TYPE.PUMPING
        || installationType === INSTALLATION_TYPE.TREATMENT
        || installationType === INSTALLATION_TYPE.TANK
        || installationType === INSTALLATION_TYPE.OTHERS
        || installationType === INSTALLATION_TYPE.AEP_PRODUCTION
        || installationType === INSTALLATION_TYPE.AEP_DISINFECTION
        || installationType === INSTALLATION_TYPE.AEP_OVERPRESSURE
        || installationType === INSTALLATION_TYPE.SECTOR_COUNT
}

const getCampaignControlledLabel = (stationType, stationValidatedLength, uppercase = true) => {
    const controlledLabels = getI18nTitleDataLength(i18n.controlled, i18n.controlleds, stationValidatedLength)
    const sampledLabels = getI18nTitleDataLength(i18n.sampled, i18n.sampleds, stationValidatedLength)
    switch (stationType) {
        case STATION_TYPE_CONSTANT.quality:
        case STATION_TYPE_CONSTANT.qualitometer:
            return { notDone: i18n.toSample,
                done: uppercase ? capitalizeFirstLetter(sampledLabels) : sampledLabels }
        default:
            return { notDone: i18n.toControl,
                done: uppercase ? capitalizeFirstLetter(controlledLabels) : controlledLabels }
    }
}

const getStationCampaignLabel = (stationType, stationValidatedLength) => {
    switch (stationType) {
        case STATION_TYPE_CONSTANT.installation:
            return getI18nTitleDataLength(i18n.installation, i18n.installations, stationValidatedLength)
        default:
            return getI18nTitleDataLength(i18n.station, i18n.stations, stationValidatedLength)
    }
}

export {
    getColorCampaign,
    getColorQualityCampaign,
    getPiezometeryCampaignCompletion,
    getCampaignType,
    getValidPiezometerCampaignStations,
    getEventsClosureModal,
    getConvertRequestType,
    getCurrentCampaign,
    isInstallationType,
    getCampaignControlledLabel,
    getStationCampaignLabel,
}
