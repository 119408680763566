export default class DtoPointPrel {
    constructor(obj) {
        this.idStation = obj.idStation // Int
        this.point = obj.point // Int
        this.typeId = obj.typeId // Int
        this.code = obj.code // String
        this.name = obj.name // String
        this.startDate = obj.startDate // Option[DateTime] = None
        this.endDate = obj.endDate // Option [DateTime] = None
        this.x = obj.x // Option[Double] = None
        this.y = obj.y // Option[Double] = None
        this.z = obj.z // Option[Double] = None
        this.projection = obj.projection // Option[Int] = None
        this.comment = obj.comment // Option[String] = None
        this.typePoint = obj.typePoint // Int
    }
}