export default class DtoElecmecEquipment {
    constructor(obj) {
        this.idStation = obj.idStation
        this.numGroup = obj.numGroup
        this.nameGroup = obj.nameGroup
        this.nominalFlow = obj.nominalFlow
        this.hmt = obj.hmt
        this.numberPumps = obj.numberPumps
        this.waterHammer = obj.waterHammer
    }
}