import { every, flatten, maxBy, minBy, range } from 'lodash'
import moment from 'moment'
import i18n from 'simple-react-i18n'
import Line from '../components/echart/series/Line'
import { HYPE_TRENDS_CONSTANTS } from '../quality/constants/QualityConstants'
import { hasValue } from '../utils/NumberUtil'

const getTrendStyle = (type) => {
    switch (type) {
        case HYPE_TRENDS_CONSTANTS.MANN_KENDALL:
            return { color: 'red', name: i18n.mannKendallTrend }
        case HYPE_TRENDS_CONSTANTS.LINEAR_REGRESSION:
            return { color: 'blue', name: i18n.linearRegressionTrend }
        case HYPE_TRENDS_CONSTANTS.AVERAGE_RUPTURE:
            return { color: 'green', name: i18n.averageTrend }
        case HYPE_TRENDS_CONSTANTS.TREND_RUPTURE:
            return { color: 'orange', name: i18n.trendRupture }
        default:
            return { color: 'red', name: i18n.mannKendallTrend }
    }
}

const getYearData = (a, b, y) => {
    const totalDays = moment(`31/12/${y}`, 'DD/MM/YYYY').dayOfYear()
    return range(1, totalDays + 1).map(day => ([
        moment().year(y).dayOfYear(day).valueOf(),
        a * (y - 1970 + ((day - 1) / totalDays)) + b,
    ])).filter((_, index) => (index % 5) === 0)
}

const getTendanceLine = (trend, chartMinYear, bandCorrection = false) => {
    if (trend.coefficient && trend.value) {
        const years = range(chartMinYear, moment().year() + 1).map(y => getYearData(trend.coefficient, trend.value, y))
        const { name, color } = getTrendStyle(trend.statisticsType)
        return Line({
            data: flatten(years),
            name,
            connectNulls: true,
            showAllSymbol: false,
            showSymbol: false,
            symbolSize: 7,
            color,
            bandCorrection,
        })
    }
    return null
}

const getRuptureLine = (rupture, maxValue, usedMaxValue, minValue = 0) => {
    if (hasValue(maxValue) && hasValue(rupture.breakDate)) {
        const usedValue = hasValue(usedMaxValue) ? usedMaxValue : (110 / 100 * maxValue)
        // const momentDate = moment(rupture.breakDate)
        const data = [[rupture.breakDate, usedValue, true], [rupture.breakDate, minValue, true]]
        const { name, color } = getTrendStyle(rupture.statisticsType)
        return Line({
            data,
            name,
            connectNulls: true,
            showAllSymbol: true,
            symbolSize: 7,
            color,
            lineStyle: {
                type: 'dashed',
            },
        })
    }
    return null
}

const getAverageLine = (rupture, minDate, maxDate, bandCorrection = false) => {
    const data = [
        [minDate, rupture.preValue],
        [rupture.breakDate, rupture.preValue],
        [rupture.breakDate, null],
        [rupture.breakDate, rupture.postValue],
        [maxDate, rupture.postValue],
    ]
    const { name, color } = getTrendStyle(rupture.statisticsType)
    return Line({
        data,
        name,
        connectNulls: false,
        showAllSymbol: true,
        symbolSize: 7,
        color,
        bandCorrection,
    })
}

const getRuptureTendanceLine = (rupture, minDate, maxDate, bandCorrection = false) => {
    const mMinDate = moment(minDate)
    const mMaxDate = moment(maxDate)
    const mbreakDate = moment(rupture.breakDate)
    const tendances = []
    if (every(['preValue', 'preCoefficient', 'breakDate'], key => hasValue(rupture[key]))) {
        const tendance1 = flatten(range(1970, moment().year() + 1)
            .filter(y => y >= mMinDate.year() && y <= mbreakDate.year())
            .map(y => getYearData(rupture.preCoefficient, rupture.preValue, y)))
            .filter(d => d[0] >= minDate && d[0] <= rupture.breakDate)
        tendances.push(tendance1)
    }
    tendances.push([[rupture.breakDate, null]])
    if (every(['postValue', 'postCoefficient', 'breakDate'], key => hasValue(rupture[key]))) {
        const tendance2 = flatten(range(1970, moment().year() + 1)
            .filter(y => y >= mbreakDate.year() && y <= mMaxDate.year())
            .map(y => getYearData(rupture.postCoefficient, rupture.postValue, y)))
            .filter(d => d[0] >= rupture.breakDate && d[0] <= maxDate)
        tendances.push(tendance2)
    }
    const { name, color } = getTrendStyle(rupture.statisticsType)
    return Line({
        data: flatten(tendances),
        name,
        connectNulls: false,
        showAllSymbol: false,
        showSymbol: false,
        symbolSize: 7,
        color,
        bandCorrection,
    })
}

const getRuptureLineAndTrend = (rupture, minDate, maxDate, maxValue, usedMaxValue, minValue, bandCorrection = false) => {
    const ruptureLine = getRuptureLine(rupture, maxValue, usedMaxValue, minValue)
    if (rupture.statisticsType === HYPE_TRENDS_CONSTANTS.AVERAGE_RUPTURE
        && every(['preValue', 'postValue', 'breakDate'], key => hasValue(rupture[key]))) {
        // average line
        const line = getAverageLine(rupture, minDate, maxDate, bandCorrection)
        return [ruptureLine, line]
    } else if (rupture.statisticsType === HYPE_TRENDS_CONSTANTS.TREND_RUPTURE && (
        every(['preValue', 'preCoefficient', 'breakDate'], key => hasValue(rupture[key]))
        || every(['postValue', 'postCoefficient', 'breakDate'], key => hasValue(rupture[key]))
    )) {
        // trend inversion line
        const line = getRuptureTendanceLine(rupture, minDate, maxDate, bandCorrection)
        return [ruptureLine, line]
    }
    return null
}

const getHypeTrendsEchartsLines = (values, hypeTrends, hypeRuptures, chartMinYear) => {
    const valuesWithData = values.filter(v => hasValue(v.measure))
    if (valuesWithData.length) {
        const minDate = minBy(valuesWithData, 'measureDate').measureDate
        const maxDate = maxBy(valuesWithData, 'measureDate').measureDate
        const maxValue = maxBy(valuesWithData, 'measure').measure
        const lines = hypeTrends.map(t => getTendanceLine(t, chartMinYear))
        const ruptures = flatten(hypeRuptures.map(t => getRuptureLineAndTrend(t, minDate, maxDate, maxValue)))

        return [...lines, ...ruptures]
    }
    return []
}

const getHypeTrends = (hypeTrends, hypeRuptures, chartMinDate, chartMaxDate, minValue, maxValue, bandCorrection = false) => {
    const lines = hypeTrends.map(t => getTendanceLine(t, moment(chartMinDate).year(), bandCorrection))
    const ruptures = flatten(hypeRuptures.map(t => getRuptureLineAndTrend(t, chartMinDate, chartMaxDate, maxValue, maxValue, minValue, bandCorrection)))
    return [...lines, ...ruptures]
}

const getTrendName = type => {
    switch (type) {
        case HYPE_TRENDS_CONSTANTS.MANN_KENDALL:
            return i18n.mannKendallTrend
        case HYPE_TRENDS_CONSTANTS.LINEAR_REGRESSION:
            return i18n.linearRegressionTrend
        case HYPE_TRENDS_CONSTANTS.AVERAGE_RUPTURE:
            return i18n.averageTrend
        case HYPE_TRENDS_CONSTANTS.TREND_RUPTURE:
            return i18n.trendRupture
        default:
            return i18n.mannKendallTrend
    }
}

const formatHypeTrend = (coefficient, value, minDate, maxDate) => {
    const minYear = moment(minDate).year()
    const maxYear = moment(maxDate).year()
    return range(minYear, maxYear + 1).flatMap(y => {
        const totalDays = moment(`31/12/${y}`, 'DD/MM/YYYY').dayOfYear()
        return range(1, totalDays + 1).map(day => ({
            date: moment().year(y).dayOfYear(day).valueOf(),
            value: coefficient * (y - 1970 + ((day - 1) / totalDays)) + value,
        })).filter((_, index) => (index % 5) === 0)
    })
}

const formatHypeTrendRupture = (rupture, minDate, maxDate) => {
    const preTrend = ['preValue', 'preCoefficient', 'breakDate'].every(k => hasValue(rupture[k])) ? formatHypeTrend(rupture.preCoefficient, rupture.preValue, minDate, rupture.breakDate) : []
    const postTred = ['postValue', 'postCoefficient', 'breakDate'].every(k => hasValue(rupture[k])) ? formatHypeTrend(rupture.postCoefficient, rupture.postValue, rupture.breakDate, maxDate) : []
    return [
        ...preTrend.filter(t => t.date <= rupture.breakDate),
        { date: rupture.breakDate, value: null },
        ...postTred.filter(t => t.date >= rupture.breakDate),
    ]
}

export { getHypeTrendsEchartsLines, getHypeTrends, getTrendName, formatHypeTrend, formatHypeTrendRupture }
