import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import IAction from './IAction'

class Edit extends IAction {
    fn = () => {
        return this.props.onEdit
    }
    icon = () => {
        return 'edit'
    }
    label = () => {
        return i18n.edit
    }

    id = () =>{
        return 'edit_action_navbar'
    }
}

Edit.propTypes = {
    onEdit: PropTypes.func.isRequired,
}

export default Edit
