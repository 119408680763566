import PropTypes from 'prop-types'
import React, { Component } from 'react'
import i18n from 'simple-react-i18n'
import Card from '../../../components/card/Card'
import DtoHydrometricStation from '../../../hydrometry/dto/DtoHydrometricStation'
import DtoInstallation from '../../../installation/dto/installation/DtoInstallation'
import DtoPiezometer from '../../../piezometry/dto/DtoPiezometer'
import PiezometerAccessibilitieDto from '../../../piezometry/dto/PiezometerAccessibilitieDto'
import PluviometerDto from '../../../pluviometry/dto/PluviometerDto'
import { getI18nTitleDataLength } from '../../../utils/StringUtil'
import { LIST_PANEL, MAP_PANEL } from '../../constants/CampaignConstants'
import ListStationCampaign from './ListStationCampaign'
import MapCampaignStation from './MapCampaignStation'


class CampaignStationPanel extends Component {
    state = {
        panelSelected: LIST_PANEL,
    }

    render = () => {
        const {
            panelSelected,
        } = this.state
        const {
            campaignId,
            editMode,
            selectedStations,
            onDeleteStation,
            accessibilities,
            stationType,
            stations,
            onChangeOrder,
            onOpenPopup,
        } = this.props

        const actionMapOrTable = panelSelected === MAP_PANEL ? {
            tooltip: i18n.table,
            iconName: 'list',
            onClick: () => this.setState({ panelSelected: LIST_PANEL }),
        } : {
            tooltip: i18n.map,
            iconName: 'map',
            onClick: () => this.setState({ panelSelected: MAP_PANEL }),
        }
        const actions = [
            actionMapOrTable,
            editMode ? {
                tooltip: i18n.addStation,
                iconName: 'note_add',
                onClick: onOpenPopup,
            } : {},
        ]
        const titleLabel = stationType === 'installation' ? i18n.work : i18n.station
        const titleLabelPlural = stationType === 'installation' ? i18n.works: i18n.stations
        const title = `${getI18nTitleDataLength(titleLabel, titleLabelPlural, selectedStations.length)} (${selectedStations.length} ${getI18nTitleDataLength(i18n.selectedElement, i18n.selectedElements, selectedStations.length)})`

        const stationsSelected = selectedStations.map(s => ({
            ...(stations.find(p => p.id === s.id) || {}),
            order: s.order,
        })).filter(p => p.id)

        return (
            <Card title={title} actions={actions} >
                {
                    panelSelected === LIST_PANEL && (
                        <ListStationCampaign
                            campaignId={campaignId}
                            editMode={editMode}
                            onDeleteStation={onDeleteStation}
                            accessibilities={accessibilities}
                            stationType={stationType}
                            stations={stationsSelected}
                            onChangeOrder={onChangeOrder}
                        />
                    )
                }
                {
                    panelSelected === MAP_PANEL && (
                        <MapCampaignStation
                            selectedStations={selectedStations}
                            stations={stationsSelected}
                            stationType={stationType}
                            accessibilities={accessibilities}
                            editMode={editMode}
                            onChangeOrder={onChangeOrder}
                        />
                    )
                }
            </Card>
        )
    }
}

CampaignStationPanel.propTypes = {
    campaignId: PropTypes.number,
    editMode: PropTypes.bool,
    selectedStations: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        order: PropTypes.number,
    })),
    onDeleteStation: PropTypes.func,
    accessibilities: PropTypes.arrayOf(PropTypes.oneOfType([
        PropTypes.instanceOf(PiezometerAccessibilitieDto),
    ])),
    stationType: PropTypes.oneOf(['piezometry', 'pluviometry', 'hydrometry', 'installation']),
    stations: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.instanceOf(PluviometerDto)),
        PropTypes.arrayOf(PropTypes.instanceOf(DtoPiezometer)),
        PropTypes.arrayOf(PropTypes.instanceOf(DtoHydrometricStation)),
        PropTypes.arrayOf(PropTypes.instanceOf(DtoInstallation)),
    ]),
    onChangeOrder: PropTypes.func,
    onOpenPopup: PropTypes.func,
}

export default CampaignStationPanel