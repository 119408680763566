import React, { useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import Table from '../../../components/datatable/Table'
import { nbPerPageLabel } from '../../../referencial/constants/ReferencialConstants'
import PropTypes from 'prop-types'
import { Box, Button, DialogActions, Grid } from '@mui/material'
import Input from '../../../components/forms/Input'
import i18n from 'simple-react-i18n'
import { getDate } from '../../../utils/DateUtil'
import useTitle from '../../../utils/customHook/useTitle'
import useActions from '../../../utils/customHook/useActions'
import { DialogContentMUI, DialogMUI, DialogTitleMUI } from '../../../components/styled/Dialog'
import Icon from '../../../components/icon/Icon'
import { StyledFieldSet } from '../../../components/StyledElements'
import PerimetersThunk from '../../action/PerimetersThunk'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import Textarea from '../../../components/forms/Textarea'
import SimpleDatePicker from '../../../components/forms/SimpleDatePicker'
import PerimetersOwnersTable from './components/PerimetersOwnersTable'
import { ButtonMUI } from '../../../components/styled/Buttons'
import ToastrAction from 'toastr/actions/ToastrAction'
import ConfirmModal from '../../../components/modal/ConfirmModal'
import AdministrationAction from '../../../administration/actions/AdministrationAction'
import { searchAllCharacters } from '../../../utils/StringUtil'
import SimpleSearchPanel from 'referencial/components/SimpleSearchPanel'
import useProgressDispatch from 'utils/customHook/useProgressDispatch'
import ProgressCard from 'components/card/ProgressCard'
import { isNil, keys } from 'lodash'
import PerimetersAction from '../../action/PerimetersAction'
import ContactAction from '../../../referencial/components/contact/actions/ContactAction'
import ContributorAction from '../../../referencial/components/contributor/actions/ContributorAction'
import DeedDto from 'perimeters/dto/DeedDto'

const DEEDS_HEADER = ['number', 'comment', 'date', 'publicationDate', 'owner']

const DeedDialog = ({
    open = false,
    deed = {},
    setDeed = () => {},
    setDeedActionIsOpen = () => {},
    readMode = true,
}) => {
    const [deedVolume, setDeedVolume] = useState()
    const [deedDate, setDeedDate] = useState()
    const [deedPublishDate, setDeedPublishDate] = useState()
    const [deedComment, setDeedComment] = useState()
    const [deedPublicationNumber, setDeedPublicationNumber] = useState()
    const [deedRecoveryId, setDeedRecoveryId] = useState()

    const [deedOwners, setDeedOwners] = useState([])

    const dispatch = useDispatch()

    useEffect(() => {
        if (deed.hypotheticalId) {
            setDeedVolume(deed.volume)
            setDeedDate(deed.deedDate)
            setDeedPublishDate(deed.publishDate)
            setDeedComment(deed.comment)
            setDeedPublicationNumber(deed.publicationNumber)
            setDeedRecoveryId(deed.recoveryId)

            dispatch(PerimetersAction.getDeedOwners(deed.hypotheticalId)).then(ownersOfDeed => {
                setDeedOwners(ownersOfDeed)
            })
        }

        return () => {
            setDeedVolume(undefined)
            setDeedDate(undefined)
            setDeedPublishDate(undefined)
            setDeedComment(undefined)
            setDeedPublicationNumber(undefined)
            setDeedRecoveryId(undefined)
        }
    }, [deed])

    const canBeCreated = deedVolume || !isNil(deedDate) || !isNil(deedPublishDate) || deedComment || deedPublicationNumber || deedRecoveryId

    return (
        <DialogMUI
            fullWidth
            maxWidth='lg'
            open={open}
        >
            <DialogTitleMUI>
                <Grid container justifyContent='space-between' alignItems='center' style={{ padding: '0 0.75rem' }}>
                    <Grid item>
                        {deed?.hypotheticalId ? i18n.updateDeed : i18n.createDeed}
                    </Grid>
                    <Grid item>
                        <Icon
                            style={{ color: 'white' }}
                            size='small'
                            icon='close'
                            onClick={() => {
                                setDeed({})
                                setDeedActionIsOpen(false)
                            }}
                        />
                    </Grid>
                </Grid>
            </DialogTitleMUI>
            <DialogContentMUI>
                <StyledFieldSet style={{ padding: '1.5rem' }}>
                    <Grid container item xs={12} spacing={2}>
                        {!!deed?.hypotheticalId && (
                            <Grid item xs={6}>
                                <Input
                                    title={i18n.number}
                                    value={deed?.hypotheticalId ?? ''}
                                    disabled
                                />
                            </Grid>
                        )}
                        <Grid item xs={deed?.hypotheticalId ? 6 : 12}>
                            <Input
                                title={i18n.volume}
                                value={deedVolume ?? ''}
                                onChange={setDeedVolume}
                                clearFunction
                                disabled={readMode}
                            />
                        </Grid>
                    </Grid>
                    <Grid container item xs={12} spacing={2}>
                        <Grid item xs={6}>
                            <SimpleDatePicker
                                label={i18n.date}
                                value={deedDate}
                                onChange={setDeedDate}
                                disabled={readMode}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <SimpleDatePicker
                                label={i18n.publicationDate}
                                value={deedPublishDate}
                                onChange={setDeedPublishDate}
                                disabled={readMode}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Textarea
                            title={i18n.comment}
                            value={deedComment}
                            onChange={setDeedComment}
                            disabled={readMode}
                        />
                    </Grid>
                    <Grid container item xs={12} spacing={2} sx={{ paddingTop: '0.5rem' }}>
                        <Grid item xs={6}>
                            <Input
                                title={i18n.publicationNumber}
                                value={deedPublicationNumber ?? ''}
                                onChange={setDeedPublicationNumber}
                                clearFunction
                                disabled={readMode}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Input
                                title={i18n.recoveryId}
                                value={deedRecoveryId ?? ''}
                                onChange={setDeedRecoveryId}
                                clearFunction
                                disabled={readMode}
                            />
                        </Grid>
                    </Grid>
                </StyledFieldSet>
                {!!deed?.hypotheticalId && (
                    <Grid container item xs={12} sx={{ paddingTop: '1rem' }} alignItems='center' justifyContent='center'>
                        {keys(deedOwners).length ? (
                            <PerimetersOwnersTable
                                privateOwners={deedOwners?.privateOwners}
                                companyOwners={deedOwners?.companyOwners}
                                readMode={readMode}
                                onValidate={owners => owners.forEach(owner => dispatch(PerimetersThunk.addDeedOwner(owner)))}
                            />
                        ) : (
                            <Grid item>
                                <ProgressCard indeterminate />
                            </Grid>
                        )}
                    </Grid>
                )}
            </DialogContentMUI>
            <DialogActions>
                <Button
                    onClick={() => {
                        setDeed({})
                        setDeedActionIsOpen(false)
                    }}
                    variant='outlined'
                >
                    {i18n.cancel}
                </Button>
                <ButtonMUI
                    onClick={() => {
                        if (deed?.hypotheticalId) {
                            dispatch(PerimetersAction.updateMortgage({
                                id: deed.hypotheticalId,
                                date: deedDate,
                                comment: deedComment,
                                publishDate: deedPublishDate,
                                volume: deedVolume,
                                publicationNumber: deedPublicationNumber,
                                recoveryId: deedRecoveryId,
                            }))
                        } else if (canBeCreated) {
                            dispatch(PerimetersAction.createMortgage({
                                date: deedDate,
                                comment: deedComment,
                                publishDate: deedPublishDate,
                                volume: deedVolume,
                                publicationNumber: deedPublicationNumber,
                                recoveryId: deedRecoveryId,
                            }))
                        } else {
                            dispatch(ToastrAction.error(i18n.pleaseEnterAtLeastOneParameter))
                        }

                        setDeedActionIsOpen(false)
                    }}
                    disabled={readMode}
                    variant='contained'
                    color='primary'
                >
                    {i18n.save}
                </ButtonMUI>
            </DialogActions>
        </DialogMUI>
    )
}

DeedDialog.propTypes = {
    open: PropTypes.bool,
    deed: PropTypes.instanceOf(DeedDto),
    setDeed: PropTypes.func,
    setDeedActionIsOpen: PropTypes.func,
    readMode: PropTypes.bool,
}

const DeedsTable = ({
    deeds = [],
    readMode,
    setDeed,
    setDeedActionIsOpen,
}) => {
    const dispatch = useDispatch()

    const {
        selectedSearchValues,
    } = useSelector(store => ({
        selectedSearchValues: store.AdministrationReducer.selectedSearchValues,
    }), shallowEqual)

    const [deletedDeed, setDeletedDeed] = useState()
    const [deletedIsOpen, setDeletedIsOpen] = useState()

    const { perimetersDeeds: searchValues } = selectedSearchValues
    const {
        searchValue,
    } = searchValues ?? {}

    const searchValueCharacters = searchAllCharacters(searchValue)

    const deedsFormated = deeds
        .filter(deed => (!searchValue || (deed.hypotheticalId?.toString().includes(searchValue)) || (searchAllCharacters(deed.comment).includes(searchValueCharacters))))
        .map(deed => {
            return {
                ...deed,
                number: deed.hypotheticalId,
                date: getDate(deed.deedDate),
                publicationDate: getDate(deed.publishDate),
                owner: deed.owners > 0 ? (<AccountCircleIcon/>) : '',
            }
        })

    return (
        <>
            <ConfirmModal
                isOpen={deletedIsOpen}
                title={i18n.sureDeleteDeed}
                onValidate={() => {
                    dispatch(PerimetersAction.deleteMortgage(deletedDeed)).then(res => {
                        if (res.type !== 'deleteDeed/rejected') {
                            setDeletedDeed(undefined)
                            setDeletedIsOpen(false)
                        }
                    })
                }}
                onClose={() => {
                    setDeletedDeed(undefined)
                    setDeletedIsOpen(false)
                }}
            />
            <Grid item sx={{ border: 'solid 1px black', borderRadius: '5px' }}>
                <Table
                    title={i18n.deeds}
                    data={deedsFormated}
                    paging
                    nbPerPageLabel={nbPerPageLabel}
                    deletable={!readMode}
                    onDelete={d => {
                        setDeletedDeed(d?.hypotheticalId)
                        setDeletedIsOpen(true)
                    }}
                    type={{ headers: DEEDS_HEADER }}
                    onClick={d => {
                        setDeed(d)
                        setDeedActionIsOpen(true)
                    }}
                    condensed
                    sortable
                    invertedHeaderStyle
                    round
                />
            </Grid>
        </>
    )
}

DeedsTable.propTypes = {
    deeds: PropTypes.arrayOf(DeedDto),
    readMode: PropTypes.bool,
    setDeed: PropTypes.func,
    setDeedActionIsOpen: PropTypes.func,
}

const PerimetersDeeds = () => {
    const dispatch = useDispatch()

    const {
        selectedSearchValues,
        deeds,
    } = useSelector(store => ({
        selectedSearchValues: store.AdministrationReducer.selectedSearchValues,
        deeds: store.PerimetersReducer.deeds,
    }), shallowEqual)

    const [searchValue, setSearchValue] = useState(selectedSearchValues.perimetersFolder?.searchValue ?? '')

    const [readMode, setReadMode] = useState(true)
    const [deed, setDeed] = useState({})
    const [deedActionsIsOpen, setDeedActionIsOpen] = useState(false)

    const onLaunchSearch = () => {
        dispatch(AdministrationAction.setCache('perimetersDeeds', { searchValue }))
        dispatch(AdministrationAction.setSelectedSearchValues('perimetersDeeds', { searchValue }))
    }

    useTitle(() => [{
        title: i18n.perimeters,
        href: 'perimeter',
    }, {
        title: i18n.deeds,
        href: 'deeds',
    }], [])

    const { isLoaded, progress } = useProgressDispatch(() => [
        dispatch(PerimetersThunk.getMortgages()),
        dispatch(ContactAction.fetchContacts()),
        dispatch(ContributorAction.fetchContributors()),
    ], [])

    useEffect(() => {
        onLaunchSearch()
    }, [searchValue])

    useActions(() => !readMode ? ({
        cancel: () => setReadMode(true),
        new: () => {
            setDeed({})
            setDeedActionIsOpen(true)
        },
    }) : ({
        export: () => {
            return {
                data: deeds.map(d => ({
                    number: { value: d.hypotheticalId },
                    comment: { value: d.comment },
                    date: { value: getDate(d.deedDate) },
                    publicationDate: { value: getDate(d.publishDate) },
                    owner: { value: d.owners.toString() },
                    headers: DEEDS_HEADER,
                })),
                exportType: 'xlsx',
                titleFile: i18n.deeds,
            }
        },
        edit: () => setReadMode(false),
    }), [readMode, deeds])

    return (
        <Box sx={{ margin: '1rem 1rem 1rem 0.5rem' }}>
            <DeedDialog
                open={deedActionsIsOpen}
                deed={deed}
                setDeed={setDeed}
                setDeedActionIsOpen={setDeedActionIsOpen}
                readMode={readMode}
            />
            <SimpleSearchPanel
                defaultSearchValue={searchValue}
                setSearchValue={setSearchValue}
            />
            <Box sx={{ paddingTop: '1rem' }}>
                {isLoaded ? (
                    <DeedsTable
                        readMode={readMode}
                        deeds={deeds}
                        setDeed={setDeed}
                        setDeedActionIsOpen={setDeedActionIsOpen}
                    />
                ) : <ProgressCard progress={progress} />}
            </Box>
        </Box>
    )
}

export default PerimetersDeeds