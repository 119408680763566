export default class DtoDroughtSectorRestriction {
    constructor(obj) {
        this.idSector = obj.idSector // Long,
        this.idRestriction = obj.idRestriction // Option[Long],
        this.exemption = obj.exemption // Option[String],
        this.updateDate = obj.updateDate // Option[DateTime],
        this.updateLogin = obj.updateLogin // Option[String],
        this.resourceType = obj.resourceType // Option[Int]
        this.entryFrequency = obj.entryFrequency // Option[Int],
        this.entryFrequencyDay = obj.entryFrequencyDay // Option[Int],
    }
}