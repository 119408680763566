import React, { useEffect, useMemo, useState } from 'react'
import { contactUrl } from '../../../conf/SieauConstants'
import { push } from '@lagunovsky/redux-react-router'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { find } from 'lodash'
import { getIconsUser, getSetting } from '../../../utils/SettingUtils'
import AdministrationAction from 'administration/actions/AdministrationAction'

const AccountPanel = () => {
    const {
        accountUser,
        contacts,
        contributors,
        applicationSettings,
        menuPicture,
        profileLoginPicture,
    } = useSelector(store => ({
        accountUser: store.AccountReducer.accountUser,
        contacts: store.ContactReducer.contacts,
        contributors: store.ContributorReducer.contributors,
        applicationSettings: store.AdministrationReducer.applicationSettings,
        menuPicture: store.AdministrationReducer.menuPicture,
        profileLoginPicture: store.AdministrationReducer.profileLoginPicture,
    }), shallowEqual)

    const [pictureUrl, setPictureUrl] = useState('')

    const dispatch = useDispatch()

    const login = useMemo(() => accountUser?.login, [accountUser])

    useEffect(() => {
        dispatch(AdministrationAction.fetchProfilePicture(login))
    }, [])

    const updatePictureUrl = () => {
        const extension = profileLoginPicture?.name?.substring(profileLoginPicture?.name?.lastIndexOf('.'), profileLoginPicture?.name.length)
        if (extension?.length > 0) {
            const name = `${login}${extension}`
            dispatch(AdministrationAction.fetchPictureWithoutCache(name)).then((url) => setPictureUrl(url))
        }
    }

    useEffect(() => {
        updatePictureUrl()
    }, [profileLoginPicture])

    const organization = useMemo(() => contributors.find(t => t.code === accountUser.contributorCode)?.name, [accountUser.contributorCode, contributors])

    const values = useMemo(() => {
        const defaultValues = { name: accountUser.login, email: contactUrl, organization }
        if (accountUser.contactCode) {
            const contact = find(contacts, o => o.id === accountUser.contactCode)
            if (contact) {
                return { name: contact.name, email: contact.email, organization }
            }
        }
        return defaultValues
    }, [accountUser.contactCode, accountUser.login, contacts, organization])

    const getPicture = () => {
        if (pictureUrl?.length > 0) {
            return (<img src={`${pictureUrl}?${new Date().getTime()}`} style={{ height: '100%', width: '100%', objectFit: 'cover', backgroundColor: 'white' }} />)
        }
        return (<i className='material-icons person'>person</i>)
    }

    const icons = useMemo(() => getIconsUser(accountUser, getSetting(applicationSettings, 'maximumNumberTry') || 5), [accountUser, applicationSettings])
    const styleBackground = useMemo(() => menuPicture.url && { backgroundImage: `url('${menuPicture.url}')` }, [menuPicture.url])

    return (
        <div className='userView padding-left-1 background' style={styleBackground}>
            <div className='row no-margin'>
                <div className='col s6'>
                    <a href='/#/account'>
                        <span className='circle btn-floating background-aquasys margin-left-2'>
                            {getPicture()}
                        </span>
                    </a>
                </div>
                <div className='col s6 margin-top-1 clickable' onClick={() => dispatch(push('/account'))}>
                    { icons }
                </div>
            </div>
            <div className='row no-margin'>
                <div className='col s12'>
                    <a href='/#/account'><span
                        className='white-text name text-shadow no-margin'
                    ><b>{ values.name }</b></span></a>
                    <a href='/#/account'><span
                        className='white-text email text-shadow no-padding'
                    >{ values.email }</span></a>
                    <a href='/#/account'><span
                        className='white-text email text-shadow'
                    >{ values.organization }</span></a>
                </div>
            </div>
        </div>
    )
}

export default AccountPanel
