export default class PerimeterDto {
    constructor(obj) {
        this.folderId = obj.folderId // Int,
        this.perimeterId = obj.perimeterId // Int,
        this.perimeterType = obj.perimeterType // Option[Int],
        this.updateDate = obj.updateDate // Option[DateTime],
        this.updateLogin = obj.updateLogin // Option[String],
        this.name = obj.name // Option[String],
        this.zoning = obj.zoning // Option[String],

        this.cities = obj.cities?.length ? obj.cities.split(',') : [] // Option[String],
        this.parcels = obj.parcels // Option[Int],
        this.ownersNumber = obj.ownersNumber // Option[Int],
        this.status = obj.status // Option[Int]
    }
}