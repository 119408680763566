import { SANDRE } from 'referencial/constants/ReferencialConstants'
import i18n from 'simple-react-i18n'
import { getDate } from './DateUtil'
import { getSandreLabel } from './StringUtil'

const getVisitCheckBoxChange = (field, previousValue, newValue) => {
    return {
        previousValue: previousValue ? i18n.present : i18n.absent,
        newValue: newValue ? i18n.present : i18n.absent,
        field,
    }
}

const getVisitDatePickerChange = (field, previousValue, newValue) => {
    return {
        previousValue: getDate(previousValue),
        newValue,
        field,
    }
}

const getVisitSimpleDatePickerChange = (field, previousValue, newValue) => {
    return {
        previousValue: getDate(previousValue),
        newValue: getDate(newValue),
        field,
    }
}

const getVisitSelectChange = (elements, target, field, previousValue, newValue) => {
    const previousElement = elements.find(e => parseInt(e[target]) === previousValue) || {}
    const newElement = elements.find(e => parseInt(e[target]) === newValue) || {}
    return {
        previousValue: previousElement.name || previousElement.label || previousElement.code || previousElement.title || '',
        newValue: newElement.name || newElement.label || newElement.code || '',
        field,
    }
}

const getVisitSandreSelectChange = (sandreCodes, sandreCode, field, previousValue, newValue) => {
    return {
        previousValue: getSandreLabel(sandreCodes, sandreCode, previousValue, true),
        newValue: getSandreLabel(sandreCodes, sandreCode, newValue, true),
        field,
    }
}

const getExportAction = (sandreCodes, equipmentType, action) => {
    return {
        ...action,
        risk: getSandreLabel(sandreCodes, SANDRE.VISITES_ACTIONS_RISQUE, action.risk),
        localisation: getSandreLabel(sandreCodes, SANDRE.VISITES_ACTIONS_SECTEUR, action.localisation),
        equipmentType: getSandreLabel(sandreCodes, equipmentType && SANDRE.EQUIPEMENTS_TYPE_AEP || SANDRE.EQUIPEMENTS_TYPE_ASST, action.equipmentType),
        responsible: getSandreLabel(sandreCodes, SANDRE.VISITES_ACTIONS_RESPONSABILITE, action.responsible),
        category: getSandreLabel(sandreCodes, SANDRE.VISITES_ACTIONS_CATEGORIE, action.category),
        deadline: getSandreLabel(sandreCodes, SANDRE.VISITES_ACTIONS_ECHEANCE, action.deadline),
        interventionType: getSandreLabel(sandreCodes, SANDRE.VISITES_ACTIONS_TYPE_INTERVENTION, action.interventionType),
        correctionDate: getDate(action.correctionDate),
        commentCorrection: action.commentCorrection,
    }
}

const getInstallationName = (installationsTypes, campaignAction) => {
    const installationTypeName = (installationsTypes.find(it => it.code === campaignAction.installationType) || {}).name
    return `${campaignAction.code ? `[${campaignAction.code}]` : ''} ${campaignAction.installationName} - ${installationTypeName}`
}

export {
    getVisitCheckBoxChange, getVisitDatePickerChange, getVisitSelectChange, getVisitSandreSelectChange, getVisitSimpleDatePickerChange, getExportAction, getInstallationName,
}
