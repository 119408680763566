import PropTypes from 'prop-types'
import SubList from '../simpleList/SubList'

const $ = window.$

class PanelList extends SubList {
    render() {
        return this.getSuperComponent(this.props.children, this.props.actions, this.props.icon)
    }

    componentDidUpdate() {
        $('.collapsible').collapsible()
    }
}

PanelList.propTypes = {
    children: PropTypes.element.isRequired,
    actions: PropTypes.arrayOf(PropTypes.shape({
        icon: PropTypes.string,
        label: PropTypes.string,
        onClick: PropTypes.func,
    })),
    title: PropTypes.object,
    icon: PropTypes.string,
    onClick: PropTypes.object,
}

export default PanelList
