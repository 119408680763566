import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import { getCurrentCampaign } from 'utils/CampaignUtil'
import CampaignDto from '../../../campaign/dto/CampaignDto'
import DtoCampaignProgress from '../../../campaign/dto/DtoCampaignProgress'
import MaterielAction from '../../../materiel/actions/MaterielAction'
import DtoMaterielCampaign from '../../../materiel/dto/DtoMaterielCampaign'
import AppStore from '../../../store/AppStore'
import SuperMultiAutocomplete from '../SuperMultiAutocomplete'

class CampaignSelect extends Component {
    state = {
        materielCampaign: {},
    }

    onChangeFilter = (value) => {
        const newState = { materielCampaign: value }
        if (value) {
            AppStore.dispatch(MaterielAction.fetchMaterielCampaign(newState.materielCampaign, () => {
                this.props.changeFilter('materielCampaign', this.props.materielCampaign)
            }))
        } else {
            this.props.changeFilter('materielCampaign', null)
        }
        this.setState(newState)
    }

    render() {
        const {
            piezometryCampaigns,
            piezometryCampaignsProgress,
            col,
        } = this.props
        return (
            <SuperMultiAutocomplete
                col={col}
                label={i18n.currentCampaign}
                options={getCurrentCampaign(piezometryCampaignsProgress, piezometryCampaigns)}
                values={this.state.materielCampaign}
                onChange={v => this.onChangeFilter(v)}
                keyLabel='name'
                keyvalue='id'
            />
        )
    }
}

CampaignSelect.propTypes = {
    piezometryCampaigns: PropTypes.arrayOf(PropTypes.instanceOf(CampaignDto)),
    piezometryCampaignsProgress: PropTypes.arrayOf(PropTypes.instanceOf(DtoCampaignProgress)),
    materielCampaign: PropTypes.arrayOf(PropTypes.instanceOf(DtoMaterielCampaign)),
    changeFilter: PropTypes.func,
    className: PropTypes.string,
    title: PropTypes.string,
    col: PropTypes.number,
    nullLabel: PropTypes.string,
    disabled: PropTypes.bool,
    withBookmarks: PropTypes.bool,
    onChange: PropTypes.func,
    filter: PropTypes.shape({
        filter: PropTypes.string,
        referent: PropTypes.string,
        provider: PropTypes.string,
        network: PropTypes.string,
        state: PropTypes.string,
    }),
}

CampaignSelect.defaultProps = {
    label: '',
    className: '',
    stationType: '',
}

const mapStateToProps = store => ({
    piezometryCampaigns: store.PiezometryReducer.piezometryCampaigns,
    piezometryCampaignsProgress: store.PiezometryReducer.piezometryCampaignsProgress,
    materielCampaign: store.MaterielReducer.materielCampaign,
})

export default connect(mapStateToProps)(CampaignSelect)