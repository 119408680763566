import { groupBy, keys } from 'lodash'
import { WEB, MOBILE, STATISTIC_MODULES, STATISTIC_TYPE_NAME } from '../administration/components/user/constants/StatisticConstants'

const getValidStatsByModules = (stats) => stats.filter(s => {
    const { LOGIN, LOGOUT, CGU } = STATISTIC_TYPE_NAME
    if (s.eventType === LOGIN || s.eventType === LOGOUT || s.eventType === CGU) {
        return keys(STATISTIC_MODULES).includes(s.value)
    }
    return keys(STATISTIC_MODULES).includes(s.module)
})

const checkStatisticValue = (value) => {
    const { SIEAU } = STATISTIC_MODULES
    if (value.includes('6')) {
        return STATISTIC_MODULES.V6
    }
    if (value === WEB || value === MOBILE) {
        return SIEAU
    }
    return value
}

const getReduceStatistics = (statistics) => {
    const { LOGIN, LOGOUT, CGU } = STATISTIC_TYPE_NAME
    return groupBy(statistics.filter(f => f && f.value?.length > 0 && f.module?.length > 0), b => {
        if (b.eventType === LOGIN || b.eventType === LOGOUT || b.eventType === CGU) {
            return checkStatisticValue(b.value?.toUpperCase())
        }
        return checkStatisticValue(b.module?.toUpperCase())
    })
}

const getModuleColor = (value) => {
    const { SIEAU, OBSERVATOIRE, MEDEAU, QAPTYS, CCE, V6 } = STATISTIC_MODULES
    switch (value) {
        case SIEAU:
            return 'blue'
        case OBSERVATOIRE:
            return 'dodgerblue'
        case MEDEAU:
            return 'orange'
        case QAPTYS:
            return 'red'
        case CCE:
            return 'green'
        case V6:
            return 'lightsalmon'
        default:
            return 'yellow'
    }
}

const getPlatformByModule = (module) => {
    const { SIEAU, OBSERVATOIRE, MEDEAU, QAPTYS, CCE, V6 } = STATISTIC_MODULES
    switch (module) {
        case SIEAU:
        case OBSERVATOIRE:
        case CCE:
        case V6:
            return WEB
        case MEDEAU:
        case QAPTYS:
            return MOBILE
        default:
            return WEB
    }
}

const getPlatformColor = (value) => {
    switch (value) {
        case MOBILE:
            return 'orange'
        case WEB:
            return 'blue'
        default:
            return 'yellow'
    }
}

export {
    getModuleColor,
    getPlatformColor,
    getValidStatsByModules,
    getPlatformByModule,
    getReduceStatistics,
}
