import { round } from '../../../utils/NumberUtil'
import { getOptions } from '../EChartUtils'

class _Line {
    constructor(obj) {
        this.obj = obj
    }

    getDefaultOptions = () => {
        return {
            name: 'line chart',
            type: 'line',
            connectNulls: true,
            data: [],
            lineStyle: {
                normal: {
                    color: 'black',
                },
            },
            itemStyle: {
                normal: {
                    color: 'black',
                },
            },
        }
    }

    getValues = () => this.obj.data.map(v => v.value ? v.value[1] : v[1])

    updateObj = (changes) => {
        this.obj = { ...this.obj, ...changes }
    }

    getJson = (bandCorrection) => {
        const options = getOptions(this.obj, this.getDefaultOptions(), {
            color: () => (
                {
                    lineStyle: {
                        normal: {
                            color: this.obj.color,
                            opacity: this.obj.lineStyle?.opacity ?? 1,
                        },
                    },
                    itemStyle: {
                        normal: {
                            color: this.obj.color,
                        },
                    },
                }),
            area: () => (
                {
                    areaStyle: {
                        normal: {
                            color: this.obj.area.color || 'grey',
                            opacity: this.obj.area.opacity || 0.5,
                        },
                    },
                }
            ),
        })
        if (bandCorrection && this.obj.isPiezo && !this.obj.dontUseBandCorrection) { // #tpa: utilisé sur suivi piézo
            options.data = options.data.map(d => {
                if (d.value) {
                    return { ...d, value: [d.value[0], d.value[1] !== null ? d.value[1] + 1000 : null, d.value[2], d.value[3]], realValue: d.value[1] !== null ? d.value[1] : null }
                }
                return [d[0], d[1] !== null ? round(d[1] + 1000) : null, d[2], d[3]]
            })
        }
        return options
    }
}

export default function Line(args) {
    return new _Line(args)
}
