import React, { useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import Select from '../../../../../components/forms/Select'
import DtoSTEPAssociatedTown from '../../dto/DtoSTEPAssociatedTown'
import { Button, Grid } from '@mui/material'
import Checkbox from 'components/forms/Checkbox'
import NumberField from 'components/forms/NumberField'
import { DialogActionsMUI, DialogContentMUI, DialogMUI, DialogTitleMUI } from 'components/styled/Dialog'
import Icon from 'components/icon/Icon'
import { hasValue } from 'utils/NumberUtil'
import { StyledFieldSet } from 'components/StyledElements'

const STEPAssociatedTownModal = ({
    idStation,
    town: townProps,
    isOpen = false,
    setIsOpen = () => {},
    onSave = () => {},
}) => {
    const {
        cities,
    } = useSelector(store => ({
        cities: store.CityReducer.cities,
    }), shallowEqual)
    const [town, setTown] = useState(townProps)

    const onChangeElement = (values) => setTown(t => ({ ...t, ...values }))

    return (
        <DialogMUI open={isOpen}>
            <DialogTitleMUI style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                {hasValue(town.index) ? i18n.editCity : i18n.newCity}
                <Icon style={{ color: 'white' }} size='small' icon='close' onClick={() => setIsOpen(false)} />
            </DialogTitleMUI>
            <DialogContentMUI>
                <StyledFieldSet>
                    <Grid item xs={12}>
                        <Select
                            value={town.code}
                            label={i18n.city}
                            options={cities}
                            keyValue='id'
                            displayWithCode
                            onChange={(v) => onChangeElement({ code: v })}
                        />
                    </Grid>
                    <Grid container item xs={12}>
                        <Grid item xs={6}>
                            <Checkbox
                                label={i18n.totalSevice}
                                checked={town.totalSevice}
                                onChange={v => onChangeElement({ totalSevice: v })}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <NumberField
                                value={town.nbFollow}
                                title={i18n.nbFollow}
                                onChange={v => onChangeElement({ nbFollow: v })}
                            />
                        </Grid>
                    </Grid>
                </StyledFieldSet>
            </DialogContentMUI>
            <DialogActionsMUI>
                <Grid container justifyContent='center'>
                    <Button
                        onClick={() => {
                            setIsOpen(false)
                            onSave(new DtoSTEPAssociatedTown({ ...town, idStation }))
                        }}
                        variant='contained'
                        color='primary'
                        className='center'
                    >
                        {i18n.register}
                    </Button>
                </Grid>
            </DialogActionsMUI>
        </DialogMUI>
    )
}

STEPAssociatedTownModal.propTypes = {
    idStation: PropTypes.number,
    town: PropTypes.instanceOf(DtoSTEPAssociatedTown),
    onSave: PropTypes.func,
    isOpen: PropTypes.bool,
    setIsOpen: PropTypes.func,
}

export default STEPAssociatedTownModal
