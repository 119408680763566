
module.exports = {
    RECEIVE_CMS_EVENTS: 'RECEIVE_CMS_EVENTS',
    RECEIVE_CMS_MESSAGES: 'RECEIVE_CMS_MESSAGES',
    cmsLevelColor: ['white', 'blue', 'green', 'orange', 'red'],
    TYPE_SELECT: 0,
    DESCRIPTION: 1,
    LOCATION_SELECT: 2,
    MAIL_SEND: 3,
    MANDATORY_FIELDS: [
        { field: 'dateDebut', i18n: 'startDate' },
        { field: 'title', i18n: 'title' },
    ],
    STATUS: {
        PUBLISHED: 1,
        DRAFTED: 2,
        ARCHIVED: 3,
    },
}
