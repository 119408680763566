import ReferencialDto from '../../../dto/ReferencialDto'

export default class MethodDto extends ReferencialDto {
    constructor(object) {
        super(object)
        this.id = object.code
        this.reference = object.reference
        this.author = object.author
        this.comment = object.comment
        this.longLabel = object.longLabel
        this.internationalName = object.internationalName
        this.creationDate = object.creationDate
        this.updateDate = object.updateDate
        this.active = object.active
    }
}