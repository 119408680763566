export default class DtoSearchIndices {
    constructor(obj) {
        this.idOperation = obj.idOperation
        this.stationId = obj.stationId
        this.stationCode = obj.stationCode
        this.stationName = obj.stationName
        this.sampleDate = obj.sampleDate
        this.parameter = obj.parameter
        this.result = obj.result
        this.unit = obj.unit
        this.status = obj.status
        this.qualification = obj.qualification
        this.x = obj.x
        this.y = obj.y
        this.projection = obj.projection
        this.color = obj.color
        this.textColor = obj.textColor
        this.sampleProducer = obj.producer
        this.producer = obj.producer
        this.remarkCode = obj.remarkCode
        this.accreditation = obj.accreditation
        this.approval = obj.approval
        this.startDate = obj.startDate
        this.hour = obj.hour
        this.endDate = obj.endDate
        this.support = obj.support
        this.method = obj.method
    }
}