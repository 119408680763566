import { push } from '@lagunovsky/redux-react-router'
import HomeAction from 'home/actions/HomeAction'
import BoundaryError from 'log/components/BoundaryError'
import MaterielThunk from 'materiel/actions/MaterielThunk'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Outlet } from 'react-router-dom'
import i18n from 'simple-react-i18n'
import ToastrAction from 'toastr/actions/ToastrAction'
import { hasValue } from 'utils/NumberUtil'
import {
    H_MAT_DASHBOARD,
    H_MAT_INVENTORY,
    H_MAT_MODULE,
    H_MAT_OPTIONS,
} from '../../account/constants/AccessRulesConstants'
import DtoAccountHabilitation from '../../account/dto/DtoAccountHabilitation'
import User from '../../account/dto/User'
import FixedSlideNav from '../../components/navbar/FixedSlideNav'
import { ADMINISTRATION, PATH_INVENTORY, PATH_MATERIEL } from '../../home/constants/RouteConstants'
import HydrometryAction from '../../hydrometry/actions/HydrometryAction'
import DtoHydrometricStation from '../../hydrometry/dto/DtoHydrometricStation'
import InstallationAction from '../../installation/actions/InstallationAction'
import DtoInstallation from '../../installation/dto/installation/DtoInstallation'
import PiezometryAction from '../../piezometry/actions/PiezometryAction'
import DtoPiezometerLight from '../../piezometry/dto/DtoPiezometerLight'
import PluviometryAction from '../../pluviometry/actions/PluviometryAction'
import PluviometerDto from '../../pluviometry/dto/PluviometerDto'
import QualityAction from '../../quality/actions/QualityAction'
import DtoQualitometerLight from '../../quality/dto/DtoQualitometerLight'
import ReferencialAction from '../../referencial/action/ReferencialAction'
import CityAction from '../../referencial/components/city/actions/CityAction'
import NetworkAction from '../../referencial/components/network/actions/NetworkAction'
import { componentHasHabilitations } from '../../utils/HabilitationUtil'
import { getMaterielHabilitationValue, getMaterielIconValue } from '../../utils/MaterielUtils'
import MaterielAction from '../actions/MaterielAction'
import MaterielTypeDto from '../dto/MaterielTypeDto'

class MaterielApp extends Component {
    componentDidMount() {
        this.props.setTitle([{
            title: i18n.materiel,
            href: 'materiel/dashboard',
        }, {
            title: i18n.dashboard,
            href: 'materiel/dashboard',
        }])
        const { piezometers, qualitometers, hydrometricStations, pluviometers, installations } = this.props
        this.props.setHelpLink('materiel', '')
        this.checkAbillitation(this.props.accountUser)
        this.props.fetchCities()
        this.props.fetchSandreCodes()
        this.props.fetchNetworks()
        this.props.fetchMaterielTypes()
        this.props.fetchMaterielStates()
        this.props.fetchSettingRules()
        if (!piezometers.length) {
            this.props.fetchPiezometers()
        }
        if (!qualitometers.length) {
            this.props.fetchQualitometers()
        }
        if (!hydrometricStations.length) {
            this.props.fetchHydrometricStations()
        }
        if (!pluviometers.length) {
            this.props.fetchPluviometers()
        }
        if (!installations.length) {
            this.props.fetchInstallations()
        }
    }

    componentWillReceiveProps(nextProps) {
        this.checkAbillitation(nextProps.accountUser)
    }

    checkAbillitation = (accountUser) => {
        if (hasValue(accountUser.login)) {
            if (!componentHasHabilitations(H_MAT_MODULE)) {
                this.props.error(i18n.AccessRightDeny)
                this.props.push('/')
            }
        }
    }

    componentWillUnmount = () => {
        this.props.resetAllMateriels()
    }

    getLinks = () => {
        const linkMateriels = this.props.materielTypes.map(({ link, label, code }) => ({
            href: `/materiel/${link}`,
            icons: getMaterielIconValue(code),
            name: label,
            habilitation: getMaterielHabilitationValue(code),
        }))
        return [
            {
                href: '/materiel/dashboard',
                icons: getMaterielIconValue(),
                name: i18n.dashboard,
                habilitation: H_MAT_DASHBOARD,
            },
            ...linkMateriels,
            {
                href: `${PATH_MATERIEL}/${PATH_INVENTORY}`,
                icons: 'storage',
                name: i18n.inventory,
                habilitation: H_MAT_INVENTORY,
            },
            {
                href: `${PATH_MATERIEL}/${ADMINISTRATION}`,
                icons: 'settings',
                name: i18n.administration,
                className: 'bottom-slide-nav-link',
                habilitation: H_MAT_OPTIONS,
            },
        ].filter(r => componentHasHabilitations(r.habilitation))
    }

    render() {
        const links = this.getLinks()
        return (
            <div className='row no-margin'>
                <FixedSlideNav links={ links } />
                <main>
                    <div className='row no-margin'>
                        <div className='content-page no-padding fullscreen'>
                            <BoundaryError>
                                <Outlet />
                            </BoundaryError>
                        </div>
                    </div>
                </main>
            </div>
        )
    }
}

MaterielApp.propTypes = {
    children: PropTypes.element,
    getLink: PropTypes.func,
    accountUser: PropTypes.instanceOf(User),
    habilitations: PropTypes.arrayOf(PropTypes.instanceOf(DtoAccountHabilitation)),
    materielTypes: PropTypes.arrayOf(PropTypes.instanceOf(MaterielTypeDto)),
    piezometers: PropTypes.arrayOf(PropTypes.instanceOf(DtoPiezometerLight)),
    qualitometers: PropTypes.arrayOf(PropTypes.instanceOf(DtoQualitometerLight)),
    pluviometers: PropTypes.arrayOf(PropTypes.instanceOf(PluviometerDto)),
    hydrometricStations: PropTypes.arrayOf(PropTypes.instanceOf(DtoHydrometricStation)),
    installations: PropTypes.arrayOf(PropTypes.instanceOf(DtoInstallation)),
    fetchPiezometers: PropTypes.func,
    fetchQualitometers: PropTypes.func,
    fetchHydrometricStations: PropTypes.func,
    fetchInstallations: PropTypes.func,
    fetchPluviometers: PropTypes.func,
    fetchMaterielStates: PropTypes.func,
    fetchSettingRules: PropTypes.func,
    resetAllMateriels: PropTypes.func,
    setHelpLink: PropTypes.func,
    setTitle: PropTypes.func,
    push: PropTypes.func,
}

const mapStateToProps = store => ({
    accountUser: store.AccountReducer.accountUser,
    materielTypes: store.MaterielReducer.materielTypes,
    piezometers: store.PiezometryReducer.piezometersLight,
    qualitometers: store.QualityReducer.qualitometersLight,
    pluviometers: store.PluviometryReducer.pluviometers,
    hydrometricStations: store.HydrometryReducer.hydrometricStations,
    installations: store.InstallationReducer.installations,
})

const mapDispatchToProps = {
    fetchSandreCodes: ReferencialAction.fetchSandreCodes,
    fetchNetworks: NetworkAction.fetchNetworks,
    fetchMaterielTypes: MaterielAction.fetchMaterielTypes,
    fetchCities: CityAction.fetchCities,
    fetchPiezometers: PiezometryAction.fetchPiezometersLight,
    fetchQualitometers: QualityAction.fetchQualitometersLight,
    fetchHydrometricStations: HydrometryAction.fetchHydrometricStations,
    fetchInstallations: InstallationAction.fetchInstallations,
    fetchPluviometers: PluviometryAction.fetchPluviometers,
    fetchMaterielStates: MaterielAction.fetchMaterielStates,
    fetchSettingRules: MaterielThunk.fetchSettingRules,
    resetAllMateriels: MaterielAction.resetAllMateriels,
    setHelpLink: HomeAction.setHelpLink,
    setTitle: HomeAction.setTitle,
    error: ToastrAction.error,
    push,
}

export default connect(mapStateToProps, mapDispatchToProps)(MaterielApp)
