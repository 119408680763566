import loading from 'assets/pictures/loading.gif'
import { push } from '@lagunovsky/redux-react-router'
import React, { useEffect, useMemo, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import { sortBy } from 'lodash'
import Card from '../../components/card/Card'
import Table from '../../components/datatable/Table'
import CampaignPortletPanel from '../../campaign/components/CampaignPortletPanel'
import MonitoredMaterielPortletPanel from '../../materiel/components/dashboard/MonitoredMaterielPortletPanel'
import ProgressCard from '../../components/card/ProgressCard'
import { getUserBookmarksByStationType } from '../../utils/UserUtil'
import { componentHasHabilitations } from '../../utils/HabilitationUtil'
import {
    H_MAT_MODULE,
    H_PRODUCTION_MODULE,
} from '../../account/constants/AccessRulesConstants'
import HabilitationRequired from '../../utils/components/HabilitationRequired'
import { STATION_TYPE_CONSTANT, STATION_TYPE_NAME } from 'station/constants/StationConstants'
import UserThunk from 'administration/components/user/actions/UserThunk'
import useStateProgress from 'utils/customHook/useStateProgress'
import ProductionUnitThunk from 'productionUnit/actions/ProductionUnitThunk'
import ProductionUnitStats from './alerts/ProductionUnitIndicators'
import { Grid2 } from '@mui/material'

const panelTable = 'table'
const panelGraph = 'graph'

const ProductionUnitCard = () => {
    const {
        userBookmarks,
        productionUnits,
        monitoredMateriels,
        citiesIndex,
        userBookmarksStatus,
        productionUnitsStatus,
    } = useSelector(store => ({
        userBookmarks: store.UserReducer.userBookmarks,
        productionUnits: store.ProductionUnitReducer.productionUnits,
        monitoredMateriels: store.MaterielReducer.monitoredMateriels,
        citiesIndex: store.CityReducer.citiesIndex,
        userBookmarksStatus: store.DataManagerReducer.user.userBookmarksStatus,
        productionUnitsStatus: store.DataManagerReducer.productionUnit.productionUnitsStatus,
    }), shallowEqual)

    const { progress: progressBar, isLoaded: dataLoaded } = useStateProgress([userBookmarksStatus, productionUnitsStatus])

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(UserThunk.fetchBookmarks())
        dispatch(ProductionUnitThunk.fetchProductionUnits())
    }, [])

    const [panelType, setPanelType] = useState(panelTable)

    const bookmarks = useMemo(() => getUserBookmarksByStationType(userBookmarks, STATION_TYPE_NAME.productionUnit), [userBookmarks])

    const foundBookmarks = useMemo(() => bookmarks.reduce((acc, code) => {
        const station = productionUnits.find(s => s.code === code)
        if (station) {
            return [...acc, station]
        }
        return acc
    }, []), [productionUnits, bookmarks])

    const data = useMemo(() => ({
        stations: productionUnits,
        bookmarks,
        icon: 'location_city',
        campaignPanel: null,
        campaigns: [],
        stationType: STATION_TYPE_CONSTANT.PRODUCTION,
        habilitation: componentHasHabilitations(H_PRODUCTION_MODULE),
        monitoredMateriels: monitoredMateriels.filter(m => m.typeSite === STATION_TYPE_CONSTANT.PRODUCTION),
    }), [bookmarks, monitoredMateriels, productionUnits])

    const bookmarksStationPanel = useMemo(() => {
        if (!foundBookmarks.length) {
            return null
        }
        const stationResult = sortBy(foundBookmarks.map(station => {
            const cityFound = citiesIndex[station.townCode]
            return {
                ...station,
                city: cityFound ? `${cityFound.name} [${cityFound.code}]` : station.townCode,
                headers: ['code', 'city', 'name', 'operator', 'buildingOwner'],
            }
        }), 'name')
        if (panelType === panelTable) {
            return (
                <Table
                    showNbElements={false}
                    showTitle={false}
                    showIcon={true}
                    headerIcon={(
                        <a>
                            <i className='waves-effect material-icons'>grade</i>
                        </a>
                    )}
                    data={stationResult}
                    actions={[{
                        iconName: 'insert_chart',
                        onClick: () => setPanelType(panelGraph),
                    }]}
                    type={stationResult[0]}
                    link={`station/${STATION_TYPE_NAME.productionUnit}`}
                    condensed
                    sortable
                />
            )
        }
        return (
            <Card
                title={(
                    <a>
                        <i className='waves-effect material-icons'>grade</i>
                        {i18n.myFavourites} ({foundBookmarks.length} {i18n.elements})
                    </a>
                )}
                actions={[{
                    iconName: 'list',
                    onClick: () => setPanelType(panelTable),
                }]}
            >
                {null}
            </Card>
        )
    }, [citiesIndex, foundBookmarks, panelType])

    const bookmarksPanel = useMemo(() => {
        if (data?.bookmarks?.length > 0 && data?.stations?.length > 0) {
            return (
                <div className='row no-margin'>
                    <div className='section no-padding'>
                        <div className='row no-margin'>
                            {bookmarksStationPanel}
                        </div>
                    </div>
                </div>
            )
        }
        return null
    }, [bookmarksStationPanel, data?.bookmarks?.length, data?.stations?.length])

    const campaignsPanel = useMemo(() => {
        if (data.campaigns && data.campaigns.length > 0 && data.campaignHeader) {
            return (
                <div className='row no-margin'>
                    <div className='section no-padding'>
                        <div className='row no-margin'>
                            <CampaignPortletPanel
                                noHeader={true}
                                campaigns={data.campaigns}
                                stationType={STATION_TYPE_NAME.productionUnit}
                                tableHeader={data.campaignHeader}
                            />
                        </div>
                    </div>
                </div>
            )
        }
        return null
    }, [data.campaignHeader, data.campaigns])

    if (dataLoaded) {
        if (!(data.habilitation && (
            data.stations?.length
            || data.bookmarks.length > 0
            || data.campaigns.length > 0
            || data.monitoredMateriels.length > 0
            || data?.exploitations?.length > 0
            || bookmarksPanel
            || campaignsPanel
        ))) {
            return null
        }
        return (
            <div className='row no-margin padding-top-1'>
                <Card
                    title={(
                        <a className='waves-effect' onClick={() => dispatch(push(`/${data.redirect || STATION_TYPE_NAME.productionUnit}`))}>
                            <i className='material-icons '>{data.icon}</i>
                            {i18n[STATION_TYPE_NAME.productionUnit]}
                        </a>
                    )}
                >
                    <Grid2 container spacing={2} sx={{ padding: '10px' }}>
                        <Grid2 size={12}>
                            <ProductionUnitStats />
                        </Grid2>
                        {bookmarksPanel && (
                            <Grid2 size={12}>
                                {bookmarksPanel}
                            </Grid2>
                        )}
                        {campaignsPanel && (
                            <Grid2 size={12}>
                                {campaignsPanel}
                            </Grid2>
                        )}
                        {
                            data.monitoredMateriels.length > 0 && (
                                <Grid2 size={12}>
                                    <HabilitationRequired habilitation={H_MAT_MODULE}>
                                        <MonitoredMaterielPortletPanel noHeader={true} stationType={data.stationType} />
                                    </HabilitationRequired>
                                </Grid2>
                            )
                        }

                    </Grid2>
                </Card>
            </div>
        )
    }
    return (
        <div className='padding-top-1'>
            <Card>
                <div className='row no-margin center-align padding-top-5'>
                    <img src={loading} />
                    <ProgressCard withoutCard message={i18n.productionUnitCardInProgress} progress={progressBar} />
                </div>
            </Card>
        </div>
    )
}

export default ProductionUnitCard