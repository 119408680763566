import { isNull } from 'lodash'

export default class DtoSearchHydrobioLight {
    constructor(obj) {
        const tax = obj.map(e => isNull(e) ? undefined : e)
        this.qualitometer = tax[0] // Double,
        this.operation = tax[1] // Double,
        this.list = tax[2] // Option[Double] = None,
        this.index = tax[3] // Option[Double] = None,
        this.operationDate = tax[4] // Option[DateTime] = None,
        this.taxon = tax[5] // Option[String] = None,
        this.enumerationA = tax[6] // Option [Double] = None,
        this.percentageA = tax[7] // Option [Double] = None,
        this.enumerationB = tax[8] // Option [Double] = None,
        this.percentageB = tax[9] // Option [Double] = None,
        this.enumerationC = tax[10] // Option [Double] = None,
        this.percentageC = tax[11] // Option [Double] = None,
        this.unit = tax[12] // Option [Double] = None,
        this.remark = tax[13]?.toString() || 1 // Option [Double] = None,
        this.enumerationC2 = tax[14] // Option [Double] = None,
        this.qualification = tax[15] // Option [Double] = None,
        this.status = tax[16]?.toString() // Option [Double] = None,
        this.labo = tax[17] // Option [Double] = None,
        this.support = tax[18] // Option [Double] = None,
        this.determinator = tax[19] // Option [Double] = None
    }
}