import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import IAction from './IAction'

class Clear extends IAction {
    fn = () => {
        return this.props.onClear
    }
    icon = () => {
        return 'cancel'
    }
    label = () => {
        return i18n.clean
    }
    id = () => {
        return 'clear_action_navbar'
    }
}

Clear.propTypes = {
    onClear: PropTypes.func.isRequired,
}

export default Clear
