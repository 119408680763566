import {
    RECEIVE_RESOURCES,
    RECEIVE_RESOURCE,
    RESET_RESSOURCE,
} from '../constants/ResourceConstants'
import ResourceDto from '../dto/ResourceDto'

export const store = {
    resources: [],
    resource: {},
}

export function ResourceReducer(state = {}, action) {
    switch (action.type) {
        case RECEIVE_RESOURCE:
            return {
                ...state,
                resource: new ResourceDto(action.payload),
            }
        case RECEIVE_RESOURCES:
            return {
                ...state,
                resources: action.payload.map(resource => new ResourceDto(resource)),
            }
        case RESET_RESSOURCE:
            return {
                ...state,
                resource: {},
                resources: [],
            }
        default:
            return state
    }
}
