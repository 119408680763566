export default class DtoThreshold {
    constructor(object) {
        this.id = object.code
        this.code = object.code
        this.name = object.name
        this.comment = object.comment
        this.type = object.type
        this.default = object.default
        this.referenceCode = object.referenceCode
        this.status = object.status
        this.isNew = object.isNew
        this.thresholdType = object.thresholdType
        this.users = object.users || []
        this.administrators = object.administrators || []
    }
}