import { uniqBy } from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import AppStore from 'store/AppStore'
import SieauParameterDto from '../../../../administration/dto/SieauParameterDto'
import ProgressCard from '../../../../components/card/ProgressCard'
import Table from '../../../../components/datatable/Table'
import Checkbox from '../../../../components/forms/Checkbox'
import Input from '../../../../components/forms/Input'
import NumberField from '../../../../components/forms/NumberField'
import RadioButtons from '../../../../components/forms/RadioButtons'
import Select from '../../../../components/forms/Select'
import Icon from '../../../../components/icon/Icon'
import Row from '../../../../components/react/Row'
import PiezometryAction from '../../../../piezometry/actions/PiezometryAction'
import DtoParametrageDataType from '../../../../piezometry/dto/DtoParametrageDataType'
import DtoPiezometerLight from '../../../../piezometry/dto/DtoPiezometerLight'
import QualityAction from '../../../../quality/actions/QualityAction'
import DtoQualification from '../../../../quality/dto/DtoQualification'
import DtoStatus from '../../../../quality/dto/DtoStatus'
import ReferencialAction from '../../../../referencial/action/ReferencialAction'
import ContributorAction from '../../../../referencial/components/contributor/actions/ContributorAction'
import { nbPerPageLabelShort, SANDRE } from '../../../../referencial/constants/ReferencialConstants'
import DtoSandreCode from '../../../../referencial/dto/DtoSandreCode'
import DtoContributor from '../../../../station/dto/DtoContributor'
import { setModal } from '../../../../utils/FormUtils'
import { getIntegrationModes, getMeasureCoteList } from '../../../../utils/JobUtils'
import { hasValue } from '../../../../utils/NumberUtil'
import { getHardPiezoDataTypes } from '../../../../utils/PiezometryUtils'
import { getSettingInt } from '../../../../utils/SettingUtils'
import { getStations } from '../../../../utils/StationUtils'
import { getQualificationSelectOptions, getStatusSelectOptions } from '../../../../utils/StatusUtil'
import { arrayOf, getSandreList } from '../../../../utils/StoreUtils'
import Job from '../../../dto/DtoJob'
import ImportFileModal from './ImportFileModal'
import { integrationModeHelpIcon } from '../../../utils/ImportUtils'
import MultiContributorsAutocomplete from 'referencial/components/contributor/components/MultiContributorsAutocomplete'

class SebaPanel extends Component {
    constructor(props) {
        super(props)
        this.state = {
            dataLoaded: true,
            progress: 0,
        }
    }

    setAdvancedFtpModal =() => {
        setModal({
            title: i18n.importParam,
            content: <ImportFileModal url={this.props.job.parameters.path} />,
        })
    }

    componentDidMount() {
        if (!this.props.piezometers.length) {
            AppStore.dispatch(PiezometryAction.fetchPiezometersLight())
        }
        if (!this.props.piezometryDataTypes.length) {
            AppStore.dispatch(PiezometryAction.fetchPiezometryDataTypes())
        }
        if (!this.props.status.length) {
            AppStore.dispatch(QualityAction.fetchStatus())
        }
        if (!this.props.qualifications.length) {
            AppStore.dispatch(QualityAction.fetchQualifications())
        }
        if (!this.props.sandreCodes.length) {
            AppStore.dispatch(ReferencialAction.fetchSandreCodes())
        }
        if (!this.props.contributors.length) {
            AppStore.dispatch(ContributorAction.fetchContributors())
        }
    }

    onChangeJobParameter = (changes) => {
        const jobParameters = {
            ...this.props.job.parameters,
            ...changes,
        }
        this.props.onChangeJob({ parameters: jobParameters })
    }

    onChangeParameters = (currentParameter, index, changes) => {
        const jobParameters = {
            ...this.props.job.parameters,
            parameters: Object.assign([], this.props.job.parameters.parameters, {
                [index]: JSON.stringify({ ...currentParameter, ...changes }),
            }),
        }
        this.props.onChangeJob({ parameters: jobParameters })
    }

    onChangeFilters = (changes) => {
        const parameters = {
            ...this.props.job.parameters,
            filters: [JSON.stringify({
                ...this.getFilters(),
                ...changes,
            })],
        }
        this.props.onChangeJob({ parameters })
    }

    onChangeColumns = (parameter, index, id, channels) => {
        const actualData = parameter.data || []
        const newData = (() => {
            if (!hasValue(channels)) {
                return actualData.filter(d => d.id !== id)
            }
            return uniqBy([ { id, channels }, ...actualData], 'id')
        })()
        this.onChangeParameters(parameter, index, { data: newData })
    }

    findData = (parameter, id) => {
        const found = (parameter.data || []).find(d => d.id === id)
        return found ? found.channels : null
    }

    getFilters = () => {
        const newFilters = this.props.job.parameters.filters.length ? JSON.parse(this.props.job.parameters.filters[0]) : {}
        return {
            measureCote: 2,
            producer: getSettingInt(this.props.applicationSettings, 'validationDefaultProducer'),
            manager: getSettingInt(this.props.applicationSettings, 'validationDefaultManager'),
            validator: getSettingInt(this.props.applicationSettings, 'validationDefaultValidator'),
            ...newFilters,
        }
    }

    getParametersNew = () => this.props.job.parameters.parameters.length ? this.props.job.parameters.parameters.map(p => JSON.parse(p)) : []

    onDeleteParameter = (index) => {
        if (this.props.isEditMode) {
            const jobParameters = {
                ...this.props.job.parameters,
                parameters: this.props.job.parameters.parameters.filter((_, i) => i !== index),
            }
            this.props.onChangeJob({ parameters: jobParameters })
        }
    }

    onDuplicateParameter = (index) => {
        if (this.props.isEditMode) {
            const jobParameters = {
                ...this.props.job.parameters,
                parameters: [ ...this.props.job.parameters.parameters, this.props.job.parameters.parameters[index] ],
            }
            this.props.onChangeJob({ parameters: jobParameters })
        }
    }

    getParametersData = (filters, parameters) => {
        const disabled = { disabled: !this.props.isEditMode }
        return parameters.map((p, index) => {
            const columnFields = this.getDataTypes().reduce((acc, val) => ({
                ...acc,
                [`data${val.id}`]: <Input value={ this.findData(p, val.id) } onChange={ v => this.onChangeColumns(p, index, val.id, v) } {...disabled }/>,
            }), {})
            return {
                nullValue: <Icon icon='delete' tooltip={ i18n.delete } onClick={ () => this.onDeleteParameter(index) } />,
                nullValue2: <Icon icon='add_to_photos' tooltip={ i18n.duplicate } onClick={ () => this.onDuplicateParameter(index) } />,
                directory: <Input value={ p.directory } onChange={ v => this.onChangeParameters(p, index, { directory: v }) } {...disabled }/>,
                filter: <Input value={ p.filter } onChange={ v => this.onChangeParameters(p, index, { filter: v }) } {...disabled }/>,
                coeff: <NumberField value={ p.factor } onChange={ v => this.onChangeParameters(p, index, { factor: v }) } floatValue {...disabled }/>,
                offset: <NumberField value={ p.offset } onChange={ v => this.onChangeParameters(p, index, { offset: v }) } floatValue {...disabled }/>,
                station: (<Select options={ getStations(this.props, 'piezometry').map(s => ({ ...s, name: s.code ? `[${s.code}] ${s.name}` : s.name })) }
                    onChange={ (_, v) => v && v.id ? this.onChangeParameters(p, index, { stationCode: v.code, stationId: v.id }) : null }
                    value={ p.stationId } keyValue='id' {...disabled }
                />),
                ...columnFields,
            }
        })
    }

    addParameterNew = () => {
        if (this.props.isEditMode) {
            const jobParameters = {
                ...this.props.job.parameters,
                parameters: [ ...this.props.job.parameters.parameters, '{}'],
            }
            this.props.onChangeJob({ parameters: jobParameters })
        }
    }

    getDataTypes = () => [ ...getHardPiezoDataTypes(), ...this.props.piezometryDataTypes ]

    getPiezometryDataLabel = () => {
        const dataType = this.getDataTypes()
        const header = ['directory', 'filter', 'station', 'coeff', 'offset', ...dataType.map(h => h.label)]
        return header
    }

    filtreDatatype = (data, value) => data.filter(d => d.id == value ? d.channels : '')
        .map(v => v.channels)

    exportData = (parameters) => {
        const data = parameters.map(p => {
            const param = JSON.parse(p)
            const columnFields = this.getDataTypes().reduce((acc, val) => ({
                ...acc,
                [val.label]: param.data ? this.filtreDatatype(param.data, val.id) : '',
            }), {})
            const line = {
                directory: param.directory,
                filter: param.filter,
                station: param.stationCode,
                excelTab: param.sheet,
                conditionColumn: param.conditionColumn,
                requiredValue: param.requiredValue,
                coeff: param.factor,
                offset: param.offset,
                ...columnFields,
                headers: this.getPiezometryDataLabel(),
            }
            return line
        })
        return data
    }

    render() {
        if (this.state.dataLoaded) {
            const disabled = !this.props.isEditMode
            const filters = this.getFilters()
            const parameters = this.getParametersData(filters, this.getParametersNew())
            const baseHeaders = ['nullValue', 'nullValue2', 'directory', 'filter', 'station', 'coeff', 'offset']
            const columnHeaders = this.getDataTypes().map(type => `data${type.id}`)
            const customHeaders = this.getDataTypes().reduce((acc, val) => ({ ...acc, [`data${val.id}`]: val.label }), {})
            const data = this.exportData(this.props.job.parameters.parameters)
            const addDataAction = this.props.isEditMode ? [{
                onClick: () => this.addParameterNew(),
                iconName: 'add_box',
                tooltip: `${i18n.add}`,
            }] : []
            return (
                <div>
                    <div className='row no-margin padding-top-1'>
                        <fieldset>
                            <legend>&nbsp;{i18n.defaultValue}&nbsp;</legend>
                            <div className='row no-margin'>
                                <Row className='padding-top-1'>
                                    <Select col={ 3 } label={ i18n.status } value={ filters.status } onChange={ v => this.onChangeFilters({ status: v }) } options={ getStatusSelectOptions() } nullLabel='&nbsp;' disabled={ disabled }/>
                                    <Select col={ 3 } label={ i18n.qualification } value={ filters.qualification } onChange={ v => this.onChangeFilters({ qualification: v }) } options={ getQualificationSelectOptions() } nullLabel='&nbsp;' disabled={ disabled }/>
                                    <MultiContributorsAutocomplete col={ 6 } label={ i18n.producer } values={ filters.producer } onChange={ v => this.onChangeFilters({ producer: v }) } options={ this.props.contributors } disabled={ disabled }/>
                                </Row>
                                <Row>
                                    <Select col={ 2 } label={ i18n.measureNature } value={ filters.nature } onChange={ v => this.onChangeFilters({ nature: v }) } options={ getSandreList(this.props.sandreCodes, SANDRE.PIEZOMETER_MEASURE_NATURE, true) } nullLabel='&nbsp;' disabled={ disabled }/>
                                    <Select col={ 2 } label={ i18n.obtainningMode } value={ filters.obtainingMode } onChange={ v => this.onChangeFilters({ obtainingMode: v }) } options={ getSandreList(this.props.sandreCodes, SANDRE.OBTAINING_MODE) } nullLabel='&nbsp;' disabled={ disabled }/>
                                    <MultiContributorsAutocomplete col={ 4 } label={ i18n.administrator } values={ filters.manager } onChange={ v => this.onChangeFilters({ manager: v }) } options={ this.props.contributors } disabled={ disabled }/>
                                    <MultiContributorsAutocomplete col={ 4 } label={ i18n.validator } values={ filters.validator } onChange={ v => this.onChangeFilters({ validator: v }) } options={ this.props.contributors } disabled={ disabled }/>
                                </Row>
                            </div>
                        </fieldset>
                    </div>
                    <div className='row no-margin valign-wrapper'>
                        <Checkbox col={ 3 } label={ 'Renommer les fichiers en .DONE' } onChange={ v => this.onChangeFilters({ renameProcessedFiles: v }) } disabled={ disabled } checked={ filters.renameProcessedFiles } />
                        <Checkbox col={ 4 } label={ 'Ne pas traiter les fichiers présents dans le journal' } onChange={ v => this.onChangeFilters({ dontProcessFilesInLogs: v }) } disabled={ disabled } checked={ filters.dontProcessFilesInLogs } />
                        <RadioButtons col={ 3 } elements={ getMeasureCoteList() } selected={ filters.measureCote } onChange={ v => this.onChangeFilters({ measureCote: v }) } title={ i18n.ratingExpression } disabled={ disabled }/>
                        <div className='col s2'/>
                    </div>
                    <div className='row no-margin valign-wrapper padding-top-1'>
                        <Select col={ 4 } options={ getIntegrationModes() } label={ i18n.dataIntegration } value={ filters.importMode } labelSpan={ integrationModeHelpIcon() }
                            onChange={ v => this.onChangeFilters({ importMode: v }) } disabled={ disabled }
                        />
                        <Input col={ 4 } title={ i18n.directory } value={ this.props.job.parameters.path } onChange={ v => this.onChangeJobParameter({ path: v }) } disabled={ disabled } />
                        <a className='btn btn-small secondary-color-back' onClick={ () => this.setAdvancedFtpModal()} ><span title={ i18n.importLabelFtp }> <i className='material-icons'>input </i> </span></a>
                        <NumberField col={ 4 } title={ i18n.excludedValue } value={ filters.excludedValue } onChange={ v => this.onChangeFilters({ excludedValue: v }) } disabled={ disabled } floatValue />
                    </div>
                    <div className='job-parameter-table'>
                        <Table
                            data={ parameters }
                            nbPerPageLabel={ nbPerPageLabelShort }
                            type={ { headers: [ ...baseHeaders, ...columnHeaders ] } }
                            sortable
                            condensed
                            paging
                            customHeaders={ customHeaders }
                            activeHeader
                            exportButtonOnHeader
                            exportData={ data }
                            actions={ addDataAction }
                        />
                    </div>
                </div>
            )
        }
        return <ProgressCard progress={ this.state.progress }/>
    }
}

SebaPanel.propTypes = {
    job: PropTypes.instanceOf(Job).isRequired,
    onChangeJob: PropTypes.func.isRequired,
    piezometryDataTypes: arrayOf(DtoParametrageDataType),
    piezometers: arrayOf(DtoPiezometerLight),
    status: arrayOf(DtoStatus),
    qualifications: arrayOf(DtoQualification),
    sandreCodes: arrayOf(DtoSandreCode),
    contributors: arrayOf(DtoContributor),
    isEditMode: PropTypes.bool,
    applicationSettings: arrayOf(SieauParameterDto),
}

const mapStateToProps = store => ({
    piezometryDataTypes: store.PiezometryReducer.piezometryDataTypes,
    piezometers: store.PiezometryReducer.piezometersLight,
    status: store.QualityReducer.status,
    qualifications: store.QualityReducer.qualifications,
    sandreCodes: store.ReferencialReducer.sandreCodes,
    contributors: store.ContributorReducer.contributors,
    applicationSettings: store.AdministrationReducer.applicationSettings,
})

export default connect(mapStateToProps)(SebaPanel)