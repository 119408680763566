import { flatten, max, min, orderBy } from 'lodash'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import Axis from '../../../components/echart/Axis'
import EChart from '../../../components/echart/EChart'
import {
    exportExcelIcon,
    exportPictureIcon, getAxisIntervalFormatter,
    toEchartsData,
    yAutomaticScaleValues,
} from '../../../components/echart/EChartUtils'
import Bar from '../../../components/echart/series/Bar'
import Line from '../../../components/echart/series/Line'
import OperationAction from '../../../quality/components/operation/actions/OperationAction'
import DtoRemark from '../../../quality/components/operation/dto/DtoRemark'
import ParameterDto from '../../../referencial/components/parameter/dto/ParameterDto'
import UnitDto from '../../../referencial/components/unit/dto/UnitDto'
import DtoFilter from '../../../station/dto/DtoFilter'
import DtoPiezometerAdditionalMeasures from '../../../station/dto/piezometer/DtoPiezometerAdditionalMeasures'
import { getColorFromPalette } from '../../../utils/ColorUtil'
import { getFullDate } from '../../../utils/DateUtil'
import { exportFile } from '../../../utils/ExportDataUtil'
import { hasValue, round } from '../../../utils/NumberUtil'
import DtoPiezometerLight from '../../dto/DtoPiezometerLight'
import ReactDOMServer from 'react-dom/server'
import { statusIcon } from '../../../utils/StatusUtil'
import { CUSTOM, ONE_MONTH, ONE_WEEK, ONE_YEAR } from '../../../alerting/constants/ChartFollowContants'
import { arrayOf } from '../../../utils/StoreUtils'
import DtoParametrageDataType from '../../dto/DtoParametrageDataType'

class PiezometryGraphicChart extends Component {
    componentDidMount() {
        if (this.props.remarks.length === 0) {
            this.props.fetchRemarks()
        }
    }

    getGraphFormat(values, actualStation, idx) {
        const station = actualStation.stationCode
        const data = {
            data: values,
            name: station ? `${station.code ? station.code : ''}${station.name ? ` - ${station.name}` : ''}` : '',
            connectNulls: false,
            showSymbol: false,
            color: getColorFromPalette(idx),
        }
        if (this.props.filter.graphicType === 1) {
            return Line(data)
        } else if (this.props.filter.graphicType === 2) {
            return Bar(data)
        }
        return {}
    }

    getExportData = (measures) => {
        return measures.filter(d => hasValue(d.value[2]) && hasValue(d.value[3])).map(d => ({
            stationCode: { value: d.value[3].stationCode.code },
            stationName: { value: d.value[3].stationCode.name },
            date: { value: getFullDate(d.value[0]), format: 'dd/MM/yyyy HH:mm:ss', cellType: 'date' },
            value: { value: d.value[1], format: '0.00', cellType: 'number' },
            type: { value: i18n.depth },
            headers: ['stationCode', 'stationName', 'date', 'value', 'type'],
        }))
    }

    getInitXAxis = (startDate, endDate) => {
        const { graphicOptions } = this.props
        const axisLabelObj = getAxisIntervalFormatter(moment(endDate), moment(startDate))
        const usedInterval = (() => {
            if (graphicOptions?.intervalChoice === CUSTOM) {
                return (endDate - startDate) / (graphicOptions?.intervalNb || 5)
            }
            if (graphicOptions?.intervalChoice === ONE_WEEK) {
                return 3600*1000*24*7
            }
            if (graphicOptions?.intervalChoice === ONE_MONTH) {
                return 3600*1000*24*30.5
            }
            if (graphicOptions?.intervalChoice === ONE_YEAR) {
                return 3600*1000*24*365.5
            }
            return axisLabelObj?.interval
        })()
        return {
            type: 'time',
            splitLine: {
                show: hasValue(graphicOptions.showXSplitLines) ? graphicOptions.showXSplitLines : true,
            },
            min: startDate,
            max: endDate,
            interval: usedInterval,
            axisLabel: { show: true, formatter: axisLabelObj.formatter },
            axisLine: { show: true },
            axisTick: { show: true },
        }
    }

    getMeasuresWithDisplayMode = (allMeasures) => {
        switch (this.props.filter.displayMode) {
            case 'relative':
                return allMeasures.map(stationMeasures => {
                    const diff = stationMeasures.find(m => hasValue(m.value[1]))?.value[1]
                    return hasValue(diff) ? stationMeasures.map(v => v.value[1] === null ? v : ({ ...v, value: [v.value[0], round(v.value[1] - diff), v.value[2], v.value[3]] })) : stationMeasures
                })
            case 'percent':
                return allMeasures.map(stationMeasures => {
                    if (!stationMeasures.length) {
                        return stationMeasures
                    }
                    const values = stationMeasures.map(v => v.value[1]).filter(v => hasValue(v))
                    const maxValue = max(values)
                    const minValue = min(values)
                    return stationMeasures.map(v => ({ ...v, value: v.value[1] === null ? v.value : [v.value[0], round((v.value[1] - minValue)*100/(maxValue-minValue)), v.value[2], v.value[3]] }))
                })
            default:
                return allMeasures
        }
    }

    getTooltip = (unit) => {
        return {
            trigger: 'axis',
            formatter: params => {
                const testDate = params[0] && params[0].value[2] && params[0].value[2].date ? params[0].value[2].date : ''
                const date = getFullDate(moment(testDate))
                const paramsOrder = orderBy(params, 'seriesIndex', 'asc').filter(o => hasValue(o.value[2]) && o.seriesName !== i18n.events).map(o => ({
                    marker: o.marker,
                    seriesName: o.seriesName,
                    value: o.value[1],
                    unit,
                    status: ReactDOMServer.renderToString(statusIcon(o.value[2], 20)),
                }))
                const result = paramsOrder.map(o => (`<span style="display:flex;align-items:center;">${o.marker} ${o.seriesName} : ${o.value} ${o.unit} <span style="padding-left:5px">${o.status}</span> </span>`)).join('')
                return `${date} <br /> ${result} `
            },
        }
    }

    render() {
        const { multiplePiezometersAdditionalMeasures, filter, selectedStations, graphicOptions, dataType } = this.props
        const echartMeasures = multiplePiezometersAdditionalMeasures.map((stationMeasures, idx) => toEchartsData(stationMeasures.measures, selectedStations[idx]))

        const measures = this.getMeasuresWithDisplayMode(echartMeasures)

        const series = measures ? measures.reduce((acc, stationMeasures, idx) => {
            if (stationMeasures.length && stationMeasures[0].value[3]) {
                return [
                    ...acc,
                    this.getGraphFormat(stationMeasures, stationMeasures[0].value[3], idx),
                ]
            }
            return acc
        }, []) : {}

        // calculate yAxis max
        const allValues = flatten(Object.keys(selectedStations).map(key => selectedStations[key])).map(a => a.result).filter(res => hasValue(res))
        const yScale = yAutomaticScaleValues(allValues)
        const yScaleFormatted = { ...yScale, min: graphicOptions.minY || yScale.min, max: graphicOptions.maxY || yScale.max }
        this.yScale = yScaleFormatted
        const exportData = this.getExportData(measures.flat())

        const title = graphicOptions.graphicTitle || i18n.overlayGraphic
        const labelDataType = dataType?.label ?? ''
        const unit = dataType?.unit

        const options = {
            title,
            legend: {
                bottom: 50,
                show: true,
            },
            tooltip: this.getTooltip(unit ?? ''),
            grid: {
                top: '5%',
                right: '10%',
                left: '10%',
                height: 320,
                bottom: 250,
            },
            toolbox: {
                show: true,
                feature: {
                    restore: { title: i18n.restore },
                    saveAsImage: {
                        title: i18n.pictureExport,
                        icon: exportPictureIcon,
                    },
                    myToolExport: {
                        show: true,
                        title: i18n.excelExport,
                        icon: exportExcelIcon,
                        onclick: () => {
                            exportFile({
                                data: exportData,
                                exportType: 'xlsx',
                                titleFile: title,
                            })
                        },
                    },
                },
                right: 50,
                top: 10,
            },
            xAxis: [Axis(this.getInitXAxis(filter.startDate, filter.endDate))],
            yAxis: [Axis({
                type: 'value',
                splitLine: {
                    interval: 10,
                    show: hasValue(graphicOptions.showYSplitLines) ? graphicOptions.showYSplitLines : true,
                },
                inverse: filter.displayMode === 'percent' ? false : graphicOptions.displayCote === 2,
                nameLocation: 'middle',
                nameGap: 40,
                scale: true,
                name: `${labelDataType} ${filter.displayMode === 'percent' ? '%' : (unit ?? '')}`,
                ...yScaleFormatted,
            })],
            series,
            dataZoom: [
                {
                    bottom: 0,
                    type: 'slider',
                    start: 0,
                    end: 100,
                },
                {
                    type: 'inside',
                    start: 0,
                    end: 100,
                },
            ],
            height: 660,
            gridHeight: 590,
        }
        return (
            <div>
                <div id='chart' className='row no-margin'>
                    <EChart options={ options }/>
                </div>
            </div>
        )
    }
}

PiezometryGraphicChart.propTypes = {
    filter: PropTypes.instanceOf(DtoFilter),
    dataType: PropTypes.instanceOf(DtoParametrageDataType),
    selectedStations: PropTypes.shape({}),
    exportedData: PropTypes.shape({}),
    colors: PropTypes.shape({
        // [codeStation]: [color hexa]
    }),
    remarks: PropTypes.arrayOf(PropTypes.instanceOf(DtoRemark)),
    fetchRemarks: PropTypes.func,
    piezometersLight: PropTypes.arrayOf(PropTypes.instanceOf(DtoPiezometerLight)),
    parameters: PropTypes.arrayOf(PropTypes.instanceOf(ParameterDto)),
    units: PropTypes.arrayOf(PropTypes.instanceOf(UnitDto)),
    multiplePiezometersAdditionalMeasures: PropTypes.arrayOf(PropTypes.instanceOf(DtoPiezometerAdditionalMeasures)),
    startDate: PropTypes.number,
    endDate: PropTypes.number,
    graphicOptions: PropTypes.shape({
        graphicTitle: PropTypes.string,
        minY: PropTypes.number,
        maxY: PropTypes.number,
        intervalChoice: PropTypes.string,
        showXSplitLines: PropTypes.bool,
        showYSplitLines: PropTypes.bool,
        displayCote: PropTypes.number,
    }),
    piezometryDataTypes: arrayOf(DtoParametrageDataType),
}

const mapStateToProps = store => ({
    remarks: store.OperationReducer.remarks,
    piezometersLight: store.PiezometryReducer.piezometersLight,
    parameters: store.ParameterReducer.parameters,
    units: store.UnitReducer.units,
    multiplePiezometersAdditionalMeasures: store.PiezometerStationReducer.multiplePiezometersAdditionalMeasures,
    piezometryDataTypes: store.PiezometryReducer.piezometryDataTypes,
})

const mapDispatchToProps = {
    fetchRemarks: OperationAction.fetchRemarks,
}

export default connect(mapStateToProps, mapDispatchToProps)(PiezometryGraphicChart)
