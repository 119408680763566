export default class DtoFlowObstruction {
    constructor(obj) {
        this.id = obj.id
        this.validityDate = obj.validityDate
        this.usageCode = obj.usageCode
        this.typeCode = obj.typeCode
        this.statusCode = obj.statusCode
        this.fallHeightCode = obj.fallHeightCode
        this.validityCode = obj.validityCode
        this.flowDeviceCode = obj.flowDeviceCode
        this.obstructionSubType = obj.obstructionSubType
        this.navigationCrossingCode = obj.navigationCrossingCode
        this.fishCrossingCode = obj.fishCrossingCode
        this.biefCode = obj.biefCode
        this.heightCode = obj.heightCode
        this.watershed1 = obj.watershed1
        this.watershed2 = obj.watershed2
        this.watershed3 = obj.watershed3
        this.watershed4 = obj.watershed4
        this.river = obj.river
        this.hydroCode = obj.hydroCode
        this.watermassCode = obj.watermassCode
        this.referenceWork = obj.referenceWork
        this.codeMobileElements = obj.codeMobileElements
        this.crossingNotes = obj.crossingNotes
    }
}