import { range } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import Checkbox from '../../../../components/forms/Checkbox'
import NumberField from '../../../../components/forms/NumberField'
import Select from '../../../../components/forms/Select'
import ContributorDto from '../../../../referencial/components/contributor/dto/ContributorDto'
import { SANDRE } from '../../../../referencial/constants/ReferencialConstants'
import DtoSandreCode from '../../../../referencial/dto/DtoSandreCode'
import { getSandreList } from '../../../../utils/StoreUtils'
import PowerSupplyTypeDto from '../../powerSupply/dto/PowerSupplyTypeDto'
import TelecomTypeDto from '../../telecom/dto/TelecomTypeDto'
import CentralTypeDto from '../dto/CentralTypeDto'

const CentralTypePanel = ({
    materielType = {},
    onChange = () => {},
    disabled = false,
    showContributorField = true,
    contributors = [],
    sandreCodes = [],
    powerSupplyTypes = [],
    telecomTypes = [],
}) => (
    <div>
        {
            showContributorField && (
                <div className='row no-margin'>
                    <Select
                        col={6}
                        options={contributors}
                        label={i18n.provider}
                        value={materielType.provider}
                        onChange={v => onChange({ provider: v })}
                        keyValue='id'
                        keyLabel='mnemonique'
                        disabled={disabled}
                    />
                    <Select
                        col={6}
                        options={contributors}
                        label={i18n.manufacturer}
                        value={materielType.manufacturerId}
                        onChange={v => onChange({ manufacturerId: v })}
                        keyValue='id'
                        keyLabel='mnemonique'
                        disabled={disabled}
                    />
                </div>
            )
        }
        <div className='row no-margin'>
            <Select
                col={4}
                label={i18n.powerSupplyType}
                options={powerSupplyTypes}
                value={materielType.powerSupplyType}
                onChange={v => onChange({ powerSupplyType: v })}
                disabled={disabled}
            />
            <NumberField
                col={4}
                title={i18n.batteryCount}
                value={materielType.pileNumber}
                onChange={v => onChange({ pileNumber: v })}
                disabled={disabled}
            />
            <NumberField
                col={4}
                title={i18n.supplyTime}
                value={materielType.supplyTime}
                onChange={value => onChange({ supplyTime: value })}
                disabled={disabled}
            />
        </div>
        <div className='row no-margin valign-wrapper'>
            <Select
                col={4}
                label={i18n.telecomType}
                options={telecomTypes}
                value={materielType.telecomType}
                onChange={v => onChange({ telecomType: v })}
                disabled={disabled}
            />
            <Checkbox
                col={8}
                label={i18n.desiccant}
                checked={materielType.desiccant === '1'}
                onChange={v => onChange({ desiccant: v ? '1' : '0' })}
                disabled={disabled}
            />
        </div>
        <div className='row no-margin valign-wrapper'>
            <Select
                col={4}
                options={getSandreList(sandreCodes, SANDRE.CENTRALE_FREQUENCE_TRANSMISSION)}
                label={i18n.sendingFrequency}
                onChange={v => onChange({ callFrequency: v })}
                value={materielType.callFrequency}
                nullLabel='&nbsp;'
                disabled={disabled}
            />
            <Select
                col={4}
                options={range(0, 24).map(h => ({ code: h, name: `${h}h` }))}
                label={i18n.sendingTime}
                onChange={v => onChange({ callHour: v })}
                value={materielType.callHour}
                nullLabel='&nbsp;'
                disabled={disabled}
            />
            <Checkbox
                col={4}
                label={i18n.thresholdOverrun}
                checked={materielType.thresholdOverrun === '1'}
                onChange={v => onChange({ thresholdOverrun: v ? '1' : '0' })}
                disabled={disabled}
            />
        </div>
    </div>
)

CentralTypePanel.propTypes = {
    materielType: PropTypes.instanceOf(CentralTypeDto),
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    showContributorField: PropTypes.bool,
    contributors: PropTypes.arrayOf(PropTypes.instanceOf(ContributorDto)),
    sandreCodes: PropTypes.arrayOf(PropTypes.instanceOf(DtoSandreCode)),
    powerSupplyTypes: PropTypes.arrayOf(PropTypes.instanceOf(PowerSupplyTypeDto)),
    telecomTypes: PropTypes.arrayOf(PropTypes.instanceOf(TelecomTypeDto)),
}

const mapStateToProps = store => ({
    contributors: store.ContributorReducer.contributors,
    sandreCodes: store.ReferencialReducer.sandreCodes,
    powerSupplyTypes: store.PowerSupplyReducer.powerSupplyTypes,
    telecomTypes: store.TelecomReducer.telecomTypes,
})

export default connect(mapStateToProps)(CentralTypePanel)