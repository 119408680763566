import { H_AGRI_SURVEYS_DASHBOARD, H_AGRI_SURVEYS_OPTIONS } from 'account/constants/AccessRulesConstants'
import AgriAction from 'agriAdministration/actions/AgriAction'
import FixedSlideNav from 'components/navbar/FixedSlideNav'
import ProgressCard from 'components/card/ProgressCard'
import Row from 'components/react/Row'
import { push } from '@lagunovsky/redux-react-router'
import CmsAction from 'events/actions/CmsAction'
import CMSEventDto from 'events/dto/CMSEventDto'
import DtoAgriTanksType from 'exploitations/dto/DtoAgriTanksType'
import DtoExploitation from 'exploitations/dto/DtoExploitation'
import DtoExploitationExportFull from 'exploitations/dto/DtoExploitationExportFull'
import HomeAction from 'home/actions/HomeAction'
import BoundaryError from 'log/components/BoundaryError'
import VariousMaterielAction from 'materiel/components/variousMateriel/actions/VariousMaterielAction'
import DtoMatEventsType from 'materiel/components/variousMateriel/dto/DtoMatEventsType'
import DtoVariousMaterielSituation from 'materiel/components/variousMateriel/dto/DtoVariousMaterielSituation'
import DtoVariousMaterielType from 'materiel/components/variousMateriel/dto/DtoVariousMaterielType'
import VariousMaterielDto from 'materiel/components/variousMateriel/dto/VariousMaterielDto'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Outlet } from 'react-router-dom'
import ReferencialAction from 'referencial/action/ReferencialAction'
import CityAction from 'referencial/components/city/actions/CityAction'
import CityDto from 'referencial/components/city/dto/CityDto'
import ContributorAction from 'referencial/components/contributor/actions/ContributorAction'
import ContributorDto from 'referencial/components/contributor/dto/ContributorDto'
import ManagementUnitAction from 'referencial/components/managementUnit/actions/ManagementUnitAction'
import DtoManagementUnit from 'referencial/components/managementUnit/dto/DtoManagementUnit'
import WatershedAction from 'referencial/components/watershed/actions/WatershedAction'
import WatershedDto from 'referencial/components/watershed/dto/WatershedDto'
import DtoSandreCode from 'referencial/dto/DtoSandreCode'
import i18n from 'simple-react-i18n'
import DtoSurvey from 'survey/dto/DtoSurvey'
import { componentHasHabilitations } from 'utils/HabilitationUtil'
import { getUser } from 'utils/SettingUtils'
import { arrayOf } from 'utils/StoreUtils'
import AdministrationAction from 'administration/actions/AdministrationAction'
import { MODELS_TYPES } from 'administration/components/models/ModelConstants'

class SurveyApp extends Component {
    state = {
        surveyLoaded: false,
        exploitationsLoaded: false,
    }

    componentDidMount() {
        if (!componentHasHabilitations(H_AGRI_SURVEYS_DASHBOARD)) { // A modifier quand react-router sera à jour
            this.props.push('/unauthorized')
            return
        }
        this.props.setFormationPath('remplir-une-enquete')
        this.props.setHelpLink('enquete-usagers/gerer-une-enquete', '')
        const {
            params,
            exploitations,
            sandreCodes,
            contributors,
            cities,
            exploitationsExportFullData,
            variousMateriels,
            variousMaterielsLastSituations,
            variousMaterielTypes,
            cmsEvents,
            watersheds,
            managementUnits,
            matEventsTypes,
            agriTanksTypes,
        } = this.props
        this.props.fetchSurvey(params.id).then(() => {
            this.setState({ surveyLoaded: true })
        })
        if (!exploitations.length) {
            this.props.fetchExploitations()
        }
        if (!sandreCodes.length) {
            this.props.fetchSandreCodes()
        }
        if (!contributors.length) {
            this.props.fetchContributors()
        }
        if (!cities.length) {
            this.props.fetchCities()
        }
        if (!exploitationsExportFullData.length) {
            this.props.fetchExploitationsExportFullData().then(() => {
                this.setState({ exploitationsLoaded: true })
            })
        } else {
            this.setState({ exploitationsLoaded: true })
        }
        if (!variousMateriels.length) {
            this.props.fetchVariousMateriels()
        }
        if (!variousMaterielsLastSituations.length) {
            this.props.fetchVariousMaterielsLastSituations()
        }
        if (!variousMaterielTypes.length) {
            this.props.fetchVariousMaterielTypes()
        }
        if (!cmsEvents.length) {
            this.props.fetchCMSEvents()
        }
        if (!watersheds.length) {
            this.props.fetchWatersheds()
        }
        if (!managementUnits.length) {
            this.props.fetchManagementUnits()
        }
        if (!matEventsTypes.length) {
            this.props.fetchMatEventsTypes()
        }
        if (!agriTanksTypes.length) {
            this.props.fetchTanksTypes()
        }
        this.props.fetchAllModelsByType(MODELS_TYPES.map(model => model.stationType))
    }

    componentWillUnmount() {
        this.props.setFormationPath('')
        this.props.setHelpLink('', '')
    }

    getLink = () => {
        const { params: { id } } = this.props
        return [
            {
                href: `/survey/${id}`,
                icons: 'dashboard',
                name: i18n.dashboard,
            },
            {
                href: `/survey/${id}/stats`,
                icons: 'data_usage',
                name: i18n.statistics,
            },
            {
                href: `/survey/${id}/validation`,
                icons: 'border_color',
                name: i18n.validation,
            },
            {
                href: `/survey/${id}/export`,
                icons: 'archive',
                name: i18n.exportName,
            }, {
                href: `/survey/${id}/settings`,
                icons: 'settings',
                name: i18n.settings,
                className: 'bottom-slide-nav-link',
                habilitation: H_AGRI_SURVEYS_OPTIONS,
            },
        ]
    }

    render() {
        const { surveyLoaded, exploitationsLoaded } = this.state
        const { survey } = this.props
        const readMode = survey?.statusCode === 3 || getUser()?.consultant === '1'
        return (
            <div className='row no-margin'>
                <FixedSlideNav links={this.getLink()}/>
                <main>
                    <div className='row no-margin padding-left-1'>
                        <div className='content-page no-padding fullscreen'>
                            <BoundaryError>
                                {surveyLoaded && exploitationsLoaded ? (
                                    <Outlet />
                                ) : (
                                    <Row className='padding-1'>
                                        <ProgressCard indeterminate round />
                                    </Row>
                                )}
                            </BoundaryError>
                        </div>
                    </div>
                </main>
            </div>
        )
    }
}

SurveyApp.defaultProps = {
    getLink: ('', ''),
}

SurveyApp.propTypes = {
    params: PropTypes.shape({
        id: PropTypes.string,
    }),
    children: PropTypes.element,

    survey: PropTypes.instanceOf(DtoSurvey),
    exploitations: arrayOf(DtoExploitation),
    sandreCodes: arrayOf(DtoSandreCode),
    contributors: arrayOf(ContributorDto),
    cmsEvents: arrayOf(CMSEventDto),
    cities: arrayOf(CityDto),
    exploitationsExportFullData: arrayOf(DtoExploitationExportFull),
    variousMateriels: arrayOf(VariousMaterielDto),
    variousMaterielsLastSituations: arrayOf(DtoVariousMaterielSituation),
    variousMaterielTypes: arrayOf(DtoVariousMaterielType),
    managementUnits: PropTypes.arrayOf(PropTypes.instanceOf(DtoManagementUnit)),
    watersheds: PropTypes.arrayOf(PropTypes.instanceOf(WatershedDto)),
    matEventsTypes: PropTypes.arrayOf(PropTypes.instanceOf(DtoMatEventsType)),
    agriTanksTypes: PropTypes.arrayOf(PropTypes.instanceOf(DtoAgriTanksType)),

    getLink: PropTypes.func,
    fetchSurvey: PropTypes.func,
    fetchExploitationsExportFullData: PropTypes.func,
    fetchExploitations: PropTypes.func,
    fetchSandreCodes: PropTypes.func,
    fetchContributors: PropTypes.func,
    fetchCities: PropTypes.func,
    fetchCMSEvents: PropTypes.func,
    setFormationPath: PropTypes.func,
    setHelpLink: PropTypes.func,
    fetchVariousMateriels: PropTypes.func,
    fetchVariousMaterielsLastSituations: PropTypes.func,
    fetchVariousMaterielTypes: PropTypes.func,
    fetchWatersheds: PropTypes.func,
    fetchManagementUnits: PropTypes.func,
    fetchMatEventsTypes: PropTypes.func,
    fetchTanksTypes: PropTypes.func,
    fetchAllModelsByType: PropTypes.func,
    push: PropTypes.func,
}

const mapStateToProps = store => ({
    exploitationsExportFullData: store.AgriReducer.exploitationsExportFullData,
    survey: store.AgriReducer.survey,
    exploitations: store.AgriReducer.exploitations,
    sandreCodes: store.ReferencialReducer.sandreCodes,
    contributors: store.ContributorReducer.contributors,
    cmsEvents: store.EventsReducer.cmsEvents,
    cities: store.CityReducer.cities,
    variousMateriels: store.VariousMaterielReducer.variousMateriels,
    variousMaterielsLastSituations: store.VariousMaterielReducer.variousMaterielsLastSituations,
    variousMaterielTypes: store.VariousMaterielReducer.variousMaterielTypes,
    watersheds: store.WatershedReducer.watersheds,
    managementUnits: store.ManagementUnitReducer.managementUnits,
    matEventsTypes: store.VariousMaterielReducer.matEventsTypes,
    agriTanksTypes: store.AgriReducer.agriTanksTypes,
})

const mapDispatchToProps = {
    fetchVariousMateriels: VariousMaterielAction.fetchVariousMateriels,
    fetchVariousMaterielsLastSituations: VariousMaterielAction.fetchVariousMaterielsLastSituations,
    fetchMatEventsTypes: VariousMaterielAction.fetchMatEventsTypes,
    fetchVariousMaterielTypes: VariousMaterielAction.fetchVariousMaterielTypes,
    fetchSurvey: AgriAction.fetchSurvey,
    fetchExploitationsExportFullData: AgriAction.fetchExploitationsExportFullData,
    fetchExploitations: AgriAction.fetchExploitations,
    fetchSandreCodes: ReferencialAction.fetchSandreCodes,
    fetchContributors: ContributorAction.fetchContributors,
    fetchCMSEvents: CmsAction.fetchCMSEvents,
    fetchCities: CityAction.fetchCities,
    setFormationPath: HomeAction.setFormationPath,
    setHelpLink: HomeAction.setHelpLink,
    fetchWatersheds: WatershedAction.fetchWatersheds,
    fetchManagementUnits: ManagementUnitAction.fetchManagementUnits,
    fetchTanksTypes: AgriAction.fetchTanksTypes,
    fetchAllModelsByType: AdministrationAction.fetchAllModelsByType,
    push,
}

export default connect(mapStateToProps, mapDispatchToProps)(SurveyApp)
