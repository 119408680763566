export default class DtoSolution {
    constructor(obj) {
        this.id = obj.id
        this.name = obj.name || obj.label
        this.label = obj.label
        this.active = obj.active
        this.updateDate = obj.updateDate
        this.updateLogin = obj.updateLogin
        this.materielChanged = obj.materielChanged
        this.damageSustained = obj.damageSustained
        this.endDate = obj.endDate
    }
}