import React, { useEffect, useMemo, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import useSandreList from 'utils/customHook/useSandreList'
import { SANDRE } from 'referencial/constants/ReferencialConstants'
import Card from 'components/card/Card'
import SimpleDatePicker from 'components/forms/SimpleDatePicker'
import Input from 'components/forms/Input'
import { getDate, getHour } from 'utils/DateUtil'
import { onChangeHour } from 'utils/FormUtils'
import Select from 'components/forms/Select'
import Checkbox from 'components/forms/Checkbox'
import NumberField from 'components/forms/NumberField'
import Textarea from 'components/forms/Textarea'
import { Grid2 } from '@mui/material'
import { StyledFieldSet, StyledLegend } from 'components/StyledElements'
import PropTypes from 'prop-types'
import useActions from 'utils/customHook/useActions'
import { push } from '@lagunovsky/redux-react-router'
import { isNil, isUndefined, uniq } from 'lodash'
import useBoolean from 'utils/customHook/useBoolean'
import OperationAction from 'quality/components/operation/actions/OperationAction'
import ToastrAction from 'toastr/actions/ToastrAction'

const HydrobioOperationDescription = ({
    idStation,
    idOperation,
}) => {
    const dispatch = useDispatch()

    const {
        hydrobioOperation,
        qualitometer,
        qualityCampaignStations,
        qualityCampaigns,
        statusList,
        supports,
        qualifications,
        methods,
        contacts,
        accountUser,
        contributors,
    } = useSelector(store => ({
        hydrobioOperation: store.OperationReducer.hydrobioOperation,
        operationAnalysis: store.OperationReducer.operationAnalysis,
        qualitometer: store.QualityReducer.qualitometer,
        qualityCampaignStations: store.QualityReducer.qualityCampaignStations,
        qualityCampaigns: store.QualityReducer.qualityCampaigns,
        statusList: store.QualityReducer.status,
        supports: store.SupportReducer.supports,
        qualifications: store.QualityReducer.qualifications,
        methods: store.MethodReducer.methods,
        contacts: store.ContactReducer.contacts,
        accountUser: store.AccountReducer.accountUser,
        contributors: store.ContributorReducer.contributors,
    }), shallowEqual)

    const sandreConservationMode = useSandreList(SANDRE.CONSERVATION_MODE)
    const sandreParticularSituation = useSandreList(SANDRE.PARTICULAR_SITUATION)
    const sandreSampleSpeed = useSandreList(SANDRE.SAMPLE_SPEED)

    const {
        value: isEditMode,
        setFalse: onReadMode,
        setTrue: onEditMode,
    } = useBoolean(idOperation === 'new')

    const [operation, setOperation] = useState(hydrobioOperation)

    useEffect(() => {
        setOperation(hydrobioOperation)
    }, [hydrobioOperation])

    useActions(() => {
        if (isEditMode) {
            return {
                save: () => {
                    if (isUndefined(operation.point)) {
                        dispatch(ToastrAction.warning('Veillez sélection un point de prélévement'))
                        return
                    }
                    if (idOperation === 'new') {
                        OperationAction.createOperation(idStation, operation).then(json => {
                            dispatch(push(`/station/quality/${idStation}/hydrobioOperation/${json.id}`))
                        })
                    } else {
                        OperationAction.updateOperation(operation).then(() => {
                            dispatch(OperationAction.fetchQualitometerHydrobioOperation(idStation, idOperation))
                            onReadMode()
                        })
                    }
                },
                cancel: () => {
                    if (idOperation === 'new') {
                        dispatch(push(`/station/quality/${idStation}/hydrobioOperation`))
                    } else {
                        setOperation(hydrobioOperation)
                        onReadMode()
                    }
                },
                clear: () => {
                    setOperation({
                        qualitometer: operation.qualitometer,
                        id: operation.id,
                    })
                },
            }
        }
        const editAction = accountUser.consultant === '1' ? {} : {
            edit: onEditMode,
        }
        return {
            ...editAction,
            delete: () => {
                OperationAction.deleteOperation(idStation, operation).then(() => dispatch(push(`/station/quality/${idStation}/hydrobioOperation`)))
            },
        }
    }, [isEditMode, operation])

    const campaigns = useMemo(() => {
        const stationId = parseInt(idStation)
        const qualityCampaignsFiltered = qualityCampaigns.filter(cs => cs.campaignType === 2)
        const campaignCodes = qualityCampaignStations.filter(cs => cs.stationId === stationId).map(cs => cs.campaignId)
        return uniq(campaignCodes).map(cCode => qualityCampaignsFiltered.find(c => c.id === cCode)).filter(c => !!c)
    }, [idStation, qualityCampaignStations, qualityCampaigns])

    const filteredPoints = useMemo(() => {
        return qualitometer.link_samplePoints
            .filter(p => isNil(operation.support) || isNil(p.codeSupport) || p.codeSupport === operation.support)
            .filter(p => isNil(p.startDate) || p.startDate <= operation.date)
            .filter(p => isNil(p.endtDate) || p.endtDate >= operation.date)
    }, [operation.date, operation.support, qualitometer.link_samplePoints])

    const formattedPoints = useMemo(() => {
        return filteredPoints.map(p => {
            const supportObj = supports.find(s => s.code === `${p.codeSupport}`)
            return {
                name: `${p.name || (supportObj?.name ?? 'Eau')} [${p.identifiant || (supportObj?.code ?? '031')}] ${p.code ?? ''} ${p.startDepth || p.endDepth ? `${p.startDepth ?? '(...)'}m -> ${p.endDepth ?? '(...)'}m` : ''}`,
                id: p.idPoint,
            }
        })
    }, [filteredPoints, supports])

    const onChange = (obj) => setOperation(prevOp => ({ ...prevOp, ...obj }))

    return (
        <Card className='no-box-shadow'>
            <div style={{ padding: '10 0 20 0', width: 1200, marginLeft: 'auto', marginRight: 'auto' }}>
                <Grid2 container columnSpacing={2} rowSpacing={0} alignItems='center'>
                    <Grid2 size={3}>
                        <SimpleDatePicker
                            value={operation.date}
                            id='startDate'
                            label={i18n.beginDate}
                            onChange={v => onChange({ date: v })}
                            disabled={!isEditMode}
                            active={isEditMode}
                        />
                    </Grid2>
                    <Grid2 size={3}>
                        <Input
                            title={i18n.startHour}
                            value={getHour(operation.hourStart)}
                            onChange={v => onChangeHour(v, v2 => onChange({ hourStart: v2 }))}
                            disabled={!isEditMode}
                            active={isEditMode}
                        />
                    </Grid2>
                    <Grid2 size={6}>
                        {
                            !!hydrobioOperation.majDate && (
                                <Card cardStyle={{ padding: '5' }}>
                                    <span>
                                        {`${i18n.lastUpdateOn} ${getDate(hydrobioOperation.majDate, 'DD/MM/YYYY  à HH:mm')} ${hydrobioOperation.majLogin ? `${i18n.by} ${hydrobioOperation.majLogin}` : ''}`}
                                    </span>
                                </Card>
                            )
                        }
                    </Grid2>
                    <Grid2 size={3}>
                        <SimpleDatePicker
                            value={operation.dateEnd}
                            id='endDate'
                            label={i18n.endDate}
                            onChange={v => onChange({ dateEnd: v })}
                            disabled={!isEditMode}
                            active={isEditMode}
                        />
                    </Grid2>
                    <Grid2 size={3}>
                        <Input
                            title={i18n.endHour}
                            value={getHour(operation.hourEnd)}
                            onChange={v => onChangeHour(v, v2 => onChange({ hourEnd: v2 }))}
                            disabled={!isEditMode}
                            active={isEditMode}
                        />
                    </Grid2>
                    <Grid2 size={6}>
                        <Select
                            options={campaigns}
                            label={i18n.campaign}
                            value={operation.campaignCode}
                            nullLabel='&nbsp;'
                            onChange={v => onChange({ campaignCode: v })}
                            disabled={!isEditMode}
                            active={isEditMode}
                        />
                    </Grid2>
                    <Grid2 size={3}>
                        <Select
                            options={statusList}
                            label={i18n.status}
                            value={operation.status}
                            nullLabel='&nbsp;'
                            onChange={v => onChange({ status: v })}
                            disabled={!isEditMode}
                            active={isEditMode}
                        />
                    </Grid2>
                    <Grid2 size={3}>
                        <Select
                            options={qualifications}
                            label={i18n.qualification}
                            value={operation.qualification}
                            nullLabel='&nbsp;'
                            onChange={v => onChange({ qualification: v })}
                            disabled={!isEditMode}
                            active={isEditMode}
                        />
                    </Grid2>
                    <Grid2 size={3}>
                        <Checkbox
                            label={i18n.accreditation}
                            checked={operation.accreditation === '1'}
                            onChange={v => onChange({ accreditation: v ? '1' : '0' })}
                            disabled={!isEditMode}
                            active={isEditMode}
                        />
                    </Grid2>
                    <Grid2 size={3}>
                        <Checkbox
                            label={i18n.authorizedBanking}
                            checked={operation.bankingAuthorization === '1'}
                            onChange={v => onChange({ bankingAuthorization: v ? '1' : '0' })}
                            disabled={!isEditMode}
                            active={isEditMode}
                        />
                    </Grid2>
                    <Grid2 size={3}>
                        <Select
                            options={formattedPoints}
                            label={i18n.point}
                            value={operation.point}
                            nullLabel='&nbsp;'
                            keyValue='idPoint'
                            onChange={v => onChange({ point: v })}
                            disabled={!isEditMode}
                            active={isEditMode}
                        />
                    </Grid2>
                    <Grid2 size={3}>
                        <Select
                            label={i18n.support}
                            options={supports}
                            value={operation.support}
                            onChange={v => onChange({ support: v && parseInt(v) })}
                            disabled={!isEditMode}
                            active={isEditMode}
                        />
                    </Grid2>
                    <Grid2 size={6}>
                        <Input
                            title={i18n.location}
                            value={operation.localization}
                            onChange={v => onChange({ localization: v })}
                            disabled={!isEditMode}
                            active={isEditMode}
                        />
                    </Grid2>
                    <Grid2 size={3}>
                        <NumberField
                            title={`${i18n.length} (m)`}
                            value={operation.length}
                            floatValue
                            onChange={v => onChange({ length: v })}
                            disabled={!isEditMode}
                            active={isEditMode}
                        />
                    </Grid2>
                    <Grid2 size={3}>
                        <NumberField
                            title={i18n.averageWidth}
                            value={operation.averageWidth}
                            floatValue
                            onChange={v => onChange({ averageWidth: v })}
                            disabled={!isEditMode}
                            active={isEditMode}
                        />
                    </Grid2>
                    <Grid2 size={3}>
                        <Select
                            options={sandreConservationMode}
                            label={i18n.principalConservationMode}
                            value={operation.principalConservationMode}
                            nullLabel='&nbsp;'
                            onChange={v => onChange({ principalConservationMode: v })}
                            disabled={!isEditMode}
                            active={isEditMode}
                        />
                    </Grid2>
                    <Grid2 size={3}>
                        <Select
                            options={sandreConservationMode}
                            label={i18n.secondConservationMode}
                            value={operation.secondConservationMode}
                            nullLabel='&nbsp;'
                            onChange={v => onChange({ secondConservationMode: v })}
                            disabled={!isEditMode}
                            active={isEditMode}
                        />
                    </Grid2>
                    <Grid2 size={6} style={{ paddingBottom: 10 }}>
                        <Textarea
                            title={i18n.comment}
                            value={operation.comment}
                            onChange={v => onChange({ comment: v })}
                            disabled={!isEditMode}
                            active={isEditMode}
                        />
                    </Grid2>
                    <Grid2 size={6} style={{ paddingBottom: 10 }}>
                        <Textarea
                            title={i18n.interpretation}
                            value={operation.interpretation}
                            onChange={v => onChange({ interpretation: v })}
                            disabled={!isEditMode}
                            active={isEditMode}
                        />
                    </Grid2>
                    <Grid2 size={3}>
                        <Select
                            options={sandreParticularSituation}
                            label={i18n.particularSituation}
                            value={operation.particularSituation}
                            nullLabel='&nbsp;'
                            onChange={v => onChange({ particularSituation: v })}
                            disabled={!isEditMode}
                            active={isEditMode}
                        />
                    </Grid2>
                    <Grid2 size={3}>
                        <NumberField
                            title={`${i18n.wetArea} (m2)`}
                            value={operation.wetArea}
                            floatValue
                            onChange={v => onChange({ wetArea: v })}
                            disabled={!isEditMode}
                            active={isEditMode}
                        />
                    </Grid2>
                    <Grid2 size={3}>
                        <Select
                            options={sandreSampleSpeed}
                            label={i18n.speed}
                            value={operation.speed}
                            nullLabel='&nbsp;'
                            onChange={v => onChange({ speed: v })}
                            disabled={!isEditMode}
                            active={isEditMode}
                        />
                    </Grid2>
                    <Grid2 size={3}>
                        <Checkbox
                            label={i18n.continue}
                            checked={operation.continueOperation === '1'}
                            onChange={v => onChange({ continueOperation: v ? '1' : '0' })}
                            disabled={!isEditMode}
                            active={isEditMode}
                        />
                    </Grid2>
                    <Grid2 size={12}>
                        <StyledFieldSet style={{ padding: '10px 5px' }}>
                            <StyledLegend>{i18n.producer}</StyledLegend>
                            <Grid2 container columnSpacing={2}>
                                <Grid2 size={8}>
                                    <Select
                                        options={contributors}
                                        label={i18n.producer}
                                        value={operation.producer}
                                        nullLabel='&nbsp;'
                                        keyLabel='labelDisplay'
                                        displayWithCode
                                        onChange={v => onChange({ producer: v })}

                                        disabled={!isEditMode}
                                        active={isEditMode}
                                    />
                                </Grid2>
                                <Grid2 size={4}>
                                    <Select
                                        options={contacts}
                                        label={i18n.contact}
                                        value={operation.producerContact}
                                        nullLabel='&nbsp;'
                                        displayWithCode
                                        onChange={v => onChange({ producerContact: v })}
                                        disabled={!isEditMode}
                                        active={isEditMode}
                                    />
                                </Grid2>
                            </Grid2>
                        </StyledFieldSet>
                    </Grid2>
                    <Grid2 size={12}>
                        <StyledFieldSet style={{ padding: '10px 5px' }}>
                            <StyledLegend>{i18n.sampler}</StyledLegend>
                            <Grid2 container columnSpacing={2}>
                                <Grid2 size={8}>
                                    <Select
                                        options={contributors}
                                        label={i18n.sampler}
                                        value={operation.sampler}
                                        nullLabel='&nbsp;'
                                        displayWithCode
                                        keyLabel='labelDisplay'
                                        onChange={v => onChange({ sampler: v })}

                                        disabled={!isEditMode}
                                        active={isEditMode}
                                    />
                                </Grid2>
                                <Grid2 size={4}>
                                    <Select
                                        options={contacts}
                                        label={i18n.contact}
                                        value={operation.samplerContact}
                                        nullLabel='&nbsp;'
                                        displayWithCode
                                        onChange={v => onChange({ samplerContact: v })}

                                        disabled={!isEditMode}
                                        active={isEditMode}
                                    />
                                </Grid2>
                            </Grid2>
                        </StyledFieldSet>
                    </Grid2>
                    <Grid2 size={12} style={{ paddingBottom: 10 }}>
                        <StyledFieldSet style={{ padding: '10px 5px' }}>
                            <StyledLegend>{i18n.determiner}</StyledLegend>
                            <Grid2 container columnSpacing={2}>
                                <Grid2 size={8}>
                                    <Select
                                        options={contributors}
                                        label={i18n.determiner}
                                        value={operation.determiner}
                                        nullLabel='&nbsp;'
                                        keyLabel='labelDisplay'
                                        displayWithCode
                                        onChange={v => onChange({ determiner: v })}

                                        disabled={!isEditMode}
                                        active={isEditMode}
                                    />
                                </Grid2>
                                <Grid2 size={4}>
                                    <Select
                                        options={contacts}
                                        label={i18n.contact}
                                        value={operation.determinerContact}
                                        nullLabel='&nbsp;'
                                        displayWithCode
                                        onChange={v => onChange({ determinerContact: v })}

                                        disabled={!isEditMode}
                                        active={isEditMode}
                                    />
                                </Grid2>
                            </Grid2>
                        </StyledFieldSet>
                    </Grid2>
                    <Grid2 size={6}>
                        <Select
                            options={methods}
                            label={i18n.protocol}
                            value={operation.biologicalProtocol && `${operation.biologicalProtocol}`}
                            nullLabel='&nbsp;'
                            onChange={v => onChange({ datbiologicalProtocole: parseInt(v) || undefined })}
                            disabled={!isEditMode}
                            active={isEditMode}
                        />
                    </Grid2>
                    <Grid2 size={6}>
                        <Select
                            label={i18n.method}
                            options={methods}
                            value={operation.analysisMethod && `${operation.analysisMethod}`}
                            onChange={v => onChange({ analysisMethod: parseInt(v) || undefined })}
                            disabled={!isEditMode}
                            active={isEditMode}
                        />
                    </Grid2>
                    <Grid2 size={6}>
                        <Textarea
                            title={i18n.comment}
                            value={operation.resultComment}
                            onChange={v => onChange({ resultComment: v })}
                            disabled={!isEditMode}
                            active={isEditMode}
                        />
                    </Grid2>
                    <Grid2 size={6}>
                        <Checkbox
                            label={i18n.microSampling}
                            checked={operation.microSampling === '1'}
                            onChange={v => onChange({ microSampling: v ? '1' : '0' })}
                            disabled={!isEditMode}
                            active={isEditMode}
                        />
                    </Grid2>
                </Grid2>
            </div>
        </Card>
    )
}

HydrobioOperationDescription.propTypes = {
    idStation: PropTypes.string,
    idOperation: PropTypes.string,
}

export default HydrobioOperationDescription