import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import ReferencialAppList from '../../../../referencial/components/ReferencialAppList'
import SieauAction from '../../../../components/sieau/SieauAction'
import { getLabel } from '../../../../utils/StoreUtils'
import { push } from '@lagunovsky/redux-react-router'
import { LIST_PANEL, MAP_PANEL } from '../../../constants/MaterielConstants'
import TelecomAction from '../../telecom/actions/TelecomAction'
import { isUndefined, orderBy } from 'lodash'
import MaterielsMapComponent from '../../map/MaterielsMapComponent'
import { PATH_MATERIEL_TELECOM } from '../../../../home/constants/RouteConstants'
import { createIconMaterialAssignment, createIconMaterialState } from '../../../../utils/MaterielUtils'
import MaterielFilterForm from '../../filterPanel/MaterielFilterForm'
import CityDto from '../../../../referencial/components/city/dto/CityDto'
import DtoMaterielState from '../../../dto/DtoMaterielState'
import DtoHydrometricStation from '../../../../hydrometry/dto/DtoHydrometricStation'
import DtoTelecomSituation from '../dto/DtoTelecomSituation'
import GenericReferencialDto from '../../../dto/GenericReferencialDto'
import ContributorDto from '../../../../referencial/components/contributor/dto/ContributorDto'
import DtoPiezometerLight from '../../../../piezometry/dto/DtoPiezometerLight'
import DtoQualitometerLight from '../../../../quality/dto/DtoQualitometerLight'
import PluviometerDto from '../../../../pluviometry/dto/PluviometerDto'
import DtoInstallation from '../../../../installation/dto/installation/DtoInstallation'
import { getDate } from '../../../../utils/DateUtil'
import TelecomDto from '../dto/TelecomDto'
import queryString from 'query-string'
import HomeAction from 'home/actions/HomeAction'
import { componentHasHabilitations } from '../../../../utils/HabilitationUtil'
import { H_MAT_TELECOM } from '../../../../account/constants/AccessRulesConstants'
import { searchAllCharacters } from '../../../../utils/StringUtil'
import { hasValue } from 'utils/NumberUtil'

const HEADERS = ['type', 'manufacturer', 'imeiAndSerialNumber', 'immoNum', 'purchaseDate', 'state', 'stateDate', 'assignment', 'administrator']

class TelecomsApp extends Component {
    constructor(props) {
        super(props)
        const { idType } = queryString.parse(this.props.location.search)
        this.state = {
            view: LIST_PANEL,
            filter: {
                matType: isUndefined(idType) ? undefined : parseInt(idType),
            },
        }
    }

    componentDidMount() {
        if (!componentHasHabilitations(H_MAT_TELECOM)) { // A modifier quand react-router sera à jour
            this.props.push('/unauthorized')
            return
        }
        this.props.setHelpLink('materiel', '')
        if (!this.props.telecomTypes.length) {
            this.props.fetchTelecomTypes()
        }
        if (!this.props.telecoms.length) {
            this.props.fetchTelecoms(true)
        }
    }

    getHash = mat => searchAllCharacters(HEADERS.map(key => mat[key]).join(''))

    getFilterMateriels = materiels => {
        const {
            // filter,
            network,
            state,
            administrator,
            manufacturer,
            matType,
            displayEquipment,
            searchValue,
            displayOutOfService,
        } = this.state.filter
        const filterEquipment = !displayEquipment ? materiels.filter(({ isEquipment }) => !isEquipment) : materiels
        const filterAdministrator = administrator ? filterEquipment.filter(({ administratorId }) => administrator === administratorId) : filterEquipment
        const filterManufacturer = manufacturer ? filterAdministrator.filter(({ manufacturerId }) => manufacturer === manufacturerId) : filterAdministrator
        const filterStatusCode = hasValue(state) ? filterManufacturer.filter(({ statusCode }) => statusCode === state) : filterManufacturer
        const filterType = matType ? filterStatusCode.filter(({ materielType }) => materielType === matType) : filterStatusCode
        const filterNetwork = network ? filterType.filter(({ networkCode }) => network === networkCode) : filterType
        const filterOutOfService = displayOutOfService ? filterNetwork : filterNetwork.filter(({ statusCode }) => statusCode !== 0 && statusCode !== 5 && statusCode !== 6)
        const searchValueFormated = searchAllCharacters(searchValue)
        return searchValue ? filterOutOfService.filter(mat => this.getHash(mat).includes(searchValueFormated)) : filterOutOfService
    }

    getExportData = (materiels) => {
        if (!materiels.length) {
            return []
        }
        const [head, ...tail] = materiels
        return [{ ...head, headers: HEADERS }, ...tail]
    }

    getPanel = telecoms => {
        if (this.state.view === MAP_PANEL) {
            return (
                <MaterielsMapComponent
                    materielLink={PATH_MATERIEL_TELECOM}
                    materiels={telecoms}
                />
            )
        }
        const formattedTelecoms = telecoms.map(mat => ({
            ...mat,
            state: createIconMaterialState(mat.state, mat.statusCode),
            assignment: mat.statusCode === 1 && createIconMaterialAssignment(mat.assignment, mat.siteType) || '',
            nullValue: mat.isEquipment && <i className='material-icons' style={{ fontSize: '1.5rem' }}>devices_other</i>,
        }))
        return (
            <ReferencialAppList
                title={ i18n.telecoms }
                data={ formattedTelecoms }
                exportData={this.getExportData(telecoms)}
                type={{ headers: ['nullValue', ...HEADERS] }}
                newAction={ () => this.props.push('/materiel/telecom/new') }
                showNewButton={ true }
                showPurgeButton={ false }
                setTitleAction={ SieauAction.forceFetch('title', [{
                    title: i18n.materiel,
                    href: 'materiel',
                }, {
                    title: i18n.telecoms,
                    href: 'materiel/telecom',
                }]) }
                onClick={({ materielId }) => this.props.push(`/materiel/telecom/${materielId}`)}
                lastUpdate={null}
                searchable={false}
            />
        )
    }

    onChangeView = view => {
        this.setState({ view })
    }

    onValidate = filter => {
        this.setState({ filter })
    }

    getCorrespondingSite = ({ siteType, siteCode }) => {
        switch (siteType) {
            case 1:
                return this.props.piezometers.find(({ id }) => id === siteCode)
            case 2:
                return this.props.pluviometers.find(({ id }) => id === siteCode)
            case 3:
                return this.props.qualitometers.find(({ id }) => id === siteCode)
            case 4:
                return this.props.hydrometricStations.find(({ id }) => id === siteCode)
            case 7:
                return this.props.installations.find(({ id }) => id === siteCode)
            default:
                return {}
        }
    }

    getTelecomType = () => this.props.telecomTypes.filter(({ id, label }) => !!this.props.telecoms.find(({ telecomType }) => telecomType === id) && label).map(t => ({
        id: t.id,
        label: t.label,
    }))

    render() {
        const materiels = this.props.telecoms.map(mat => {
            const lastSituation = this.props.telecomsLastSituations.find(s => s.idTelecom === mat.id)
            const labelState = lastSituation ? getLabel(this.props.materielStates, lastSituation.statusCode) : ''
            const site = lastSituation && lastSituation.statusCode === 1 && lastSituation.siteType && lastSituation.siteCode && this.getCorrespondingSite(lastSituation) || {}
            const labelAssignment = (lastSituation && lastSituation.statusCode === 1 && lastSituation.siteName) || site.code || ''
            const city = site.townCode && this.props.cities.find((o) => o.code === site.townCode) || undefined
            const cityLabel = city ? `${city.name} - [${city.code}]` : (site.townCode || '')
            const departmentLabel = city && city.departmentNumber ? city.departmentNumber : ''
            return {
                materielId: mat.id,
                type: getLabel(this.props.telecomTypes, mat.materielType),
                manufacturer: getLabel(this.props.contributors, mat.manufacturerId, 'labelDisplay', 'id'),
                state: labelState,
                statusCode: lastSituation && lastSituation.statusCode,
                stateDate: lastSituation && getDate(lastSituation.situationDate),
                city: cityLabel,
                administratorId: mat.administrator,
                assignment: labelAssignment,
                siteType: lastSituation && lastSituation.siteType,
                departmentShort: departmentLabel,
                purchaseDate: getDate(mat.purchaseDate),
                manufacturerId: mat.manufacturerId,
                networkCode: mat.networkCode,
                materielType: mat.materielType,
                isEquipment: mat.isEquipment,
                siteCode: lastSituation && lastSituation.siteCode,
                administrator: getLabel(this.props.contributors, mat.administrator, 'labelDisplay', 'code'),
                imeiAndSerialNumber: mat.imei,
                immoNum: mat.reference,
            }
        })
        const filteredMateriels = this.getFilterMateriels(materiels)
        return (
            <div className='col no-padding s12'>
                <div className='row no-margin'>
                    <div className='col s12'>
                        <div className='row no-margin-bottom'>
                            <MaterielFilterForm
                                view={this.state.view}
                                filter={this.state.filter}
                                changeView={this.onChangeView}
                                materiels={materiels}
                                typeList={orderBy(this.getTelecomType(), 'label')}
                                onValidate={this.onValidate}
                                materielType='TELECOM'
                            />
                        </div>
                    </div>
                    {this.getPanel(filteredMateriels)}
                </div>
            </div>
        )
    }
}

TelecomsApp.propTypes = {
    location: PropTypes.shape({
        pathname: PropTypes.string,
        search: PropTypes.object,
    }),
    allowModifications: PropTypes.bool,
    getLink: PropTypes.func,
    telecoms: PropTypes.arrayOf(PropTypes.instanceOf(TelecomDto)),
    telecomsLastSituations: PropTypes.arrayOf(PropTypes.instanceOf(DtoTelecomSituation)),
    telecomTypes: PropTypes.arrayOf(GenericReferencialDto),
    cities: PropTypes.arrayOf(PropTypes.instanceOf(CityDto)),
    materielStates: PropTypes.arrayOf(PropTypes.instanceOf(DtoMaterielState)),
    piezometers: PropTypes.arrayOf(PropTypes.instanceOf(DtoPiezometerLight)),
    pluviometers: PropTypes.arrayOf(PropTypes.instanceOf(PluviometerDto)),
    qualitometers: PropTypes.arrayOf(PropTypes.instanceOf(DtoQualitometerLight)),
    hydrometricStations: PropTypes.arrayOf(PropTypes.instanceOf(DtoHydrometricStation)),
    installations: PropTypes.arrayOf(PropTypes.instanceOf(DtoInstallation)),
    contributors: PropTypes.arrayOf(PropTypes.instanceOf(ContributorDto)),
    push: PropTypes.func,
    fetchTelecomTypes: PropTypes.func,
    fetchTelecoms: PropTypes.func,
    setHelpLink: PropTypes.func,
}

const mapStateToProps = store => ({
    telecoms: store.TelecomReducer.telecoms,
    telecomsLastSituations: store.TelecomReducer.telecomsLastSituations,
    telecomTypes: store.TelecomReducer.telecomTypes,
    piezometers: store.PiezometryReducer.piezometersLight,
    pluviometers: store.PluviometryReducer.pluviometers,
    qualitometers: store.QualityReducer.qualitometersLight,
    hydrometricStations: store.HydrometryReducer.hydrometricStations,
    installations: store.InstallationReducer.installations,
    materielStates: store.MaterielReducer.materielStates,
    cities: store.CityReducer.cities,
    contributors: store.ContributorReducer.contributors,
})

const mapDispatchToProps = {
    push,
    fetchTelecomTypes: TelecomAction.fetchTelecomTypes,
    fetchTelecoms: TelecomAction.fetchTelecoms,
    setHelpLink: HomeAction.setHelpLink,
}

export default connect(mapStateToProps, mapDispatchToProps)(TelecomsApp)
