export default class DtoCampaignFile {
    constructor(obj) {
        this.jobId = obj.jobId // Long,
        this.jobExecutionId = obj.jobExecutionId // Long,
        this.nameFile = obj.nameFile // String,
        this.date = obj.date // Option[DateTime],
        this.statusCode = obj.statusCode // Int,
        this.qualitometers = obj.qualitometers // Seq[String],
        this.nbStation = obj.nbStation // Int,
        this.nbOperation = obj.nbOperation // Int,
        this.nbAnalyse = obj.nbAnalyse // Int,
        this.nbParameter = obj.nbParameter // Int
    }
}