export default class DtoHydroSituation {
    constructor(obj = {}) {
        this.hydroId = obj.hydroId
        this.startDate = obj.startDate
        this.endDate = obj.endDate
        this.min = obj.min
        this.average = obj.average
        this.max = obj.max
        this.value = obj.value
        this.measuresCount = obj.measuresCount
        this.threshold = obj.threshold
        this.thresholdColor = obj.thresholdColor
    }
}
