import { Button, Grid } from '@mui/material'
import Input from 'components/forms/Input'
import Select from 'components/forms/Select'
import React, { useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { SANDRE } from 'referencial/constants/ReferencialConstants'
import i18n from 'simple-react-i18n'
import { getSandreList } from 'utils/StoreUtils'
import PropTypes from 'prop-types'
import { sortBy } from 'lodash'
import Checkbox from 'components/forms/Checkbox'

const FormFilterValidation = ({
    setFilters = () => { },
}) => {
    const [search, setSearch] = useState()
    const [structure, setStructure] = useState()
    const [city, setCity] = useState()
    const [managementUnit, setManagementUnit] = useState()
    const [subManagementUnit, setSubManagementUnit] = useState()
    const [watershed, setWatershed] = useState()
    const [tanks, setTanks] = useState()
    const [pointsShared, setPointsShared] = useState()
    const [materielsShared, setMaterielsShared] = useState()

    const {
        sandreCodes,
        cities,
        managementUnits,
        watersheds,
    } = useSelector(store => ({
        sandreCodes: store.ReferencialReducer.sandreCodes,
        cities: store.CityReducer.cities,
        managementUnits: store.ManagementUnitReducer.managementUnits,
        watersheds: store.WatershedReducer.watersheds,
    }), shallowEqual)

    return (
        <Grid>
            <Grid item xs={12}>
                <Input
                    col={2}
                    title={i18n.search}
                    value={search}
                    onChange={setSearch}
                    onEnterKeyPress={() => setFilters({
                        search,
                        structure,
                        city,
                        managementUnit,
                        subManagementUnit,
                        watershed,
                    })}
                />
                <Select
                    col={2}
                    label={i18n.structure}
                    value={structure}
                    onChange={setStructure}
                    keyvalue='code'
                    options={getSandreList(sandreCodes, SANDRE.INTERVENANTS_TYPE_STRUCTURE)}
                />
                <Select
                    col={2}
                    label={i18n.city}
                    options={cities}
                    value={city}
                    displayWithCode
                    onChange={setCity}
                    clearFunction
                />
                <Select
                    col={2}
                    label={i18n.managementUnit}
                    options={sortBy(managementUnits.filter((u) => !u.parent), 'id')}
                    value={managementUnit}
                    keyValue='managementCode'
                    keyLabel='labelWithCode'
                    onChange={(value) => {
                        setManagementUnit(value)
                        setSubManagementUnit(null)
                    }}
                />
                <Select
                    col={2}
                    label={i18n.subManagementUnit}
                    options={managementUnit ? sortBy(managementUnits.filter((u) => u.parent === parseInt(managementUnit)), 'id') : []}
                    value={subManagementUnit}
                    keyValue='managementCode'
                    keyLabel='labelWithCode'
                    onChange={setSubManagementUnit}
                />
                <Select
                    col={2}
                    label={i18n.watershed}
                    options={sortBy(watersheds, [e => e?.name?.toLowerCase()])}
                    value={watershed}
                    keyValue='id'
                    keyLabel='labelWithCode'
                    onChange={setWatershed}
                    integerValue
                />
                <Checkbox
                    col={2}
                    label={i18n.detentionsEligibles}
                    checked={tanks}
                    onChange={setTanks}
                />
                <Checkbox
                    col={2}
                    label={i18n.sharedPoints}
                    checked={pointsShared}
                    onChange={setPointsShared}
                />
                <Checkbox
                    col={2}
                    label={i18n.sharedMaterials}
                    checked={materielsShared}
                    onChange={setMaterielsShared}
                />
            </Grid>
            <Grid container item xs={12} justifyContent='flex-end' spacing='10'>
                <Grid item >
                    <Button
                        variant='outlined'
                        color='primary'
                        onClick={() => {
                            setSearch()
                            setStructure()
                            setCity()
                            setManagementUnit()
                            setSubManagementUnit()
                            setWatershed()
                            setTanks()
                            setPointsShared()
                            setMaterielsShared()
                            setFilters({})
                        }}
                    >
                        {i18n.reinit}
                    </Button>
                </Grid>
                <Grid item className='padding-right-1'>
                    <Button
                        variant='contained'
                        color='primary'
                        onClick={() => setFilters({
                            search,
                            structure,
                            city,
                            managementUnit,
                            subManagementUnit,
                            watershed,
                            tanks,
                            pointsShared,
                            materielsShared,
                        })}
                    >
                        {i18n.search}
                    </Button>
                </Grid>
            </Grid>
        </Grid >
    )
}

FormFilterValidation.propTypes = {
    setFilters: PropTypes.func,
}

export default FormFilterValidation
