import PropTypes from 'prop-types'
import React, { Component } from 'react'
import LoadingPieChart from '../chart/LoadingPieChart'
import Other from './Other'

class Loading extends Component {
    componentDidMount() {
        $('.dropdown-button').dropdown()
    }
    componentDidUpdate() {
        $('.dropdown-button').dropdown()
    }

    render() {
        if (this.props.loads && this.props.loads.length > 0) {
            const loads = this.props.loads.map(loading => {
                return (
                    <li>
                        <a target='_blank'>
                            <div className='row no-margin valign-wrapper dropdownLoading collection-item'>
                                <div className='no-margin no-padding col s3 center-align'>
                                    <LoadingPieChart value={ loading.value } />
                                </div>
                                <div className='col s9'>
                                    <b>{ loading.label ? `${loading.label} : ${loading.value}%` : `${loading.value}%` }</b>
                                </div>
                            </div>
                        </a>
                    </li>
                )
            })
            return (
                <div className='action-wrapper'>
                    <ul id='dropdownLoadings' className='dropdown-content dropdownLoadings'>
                        { loads }
                    </ul>
                    <Other
                        className='dropdown-button rotate anim-hover nav-actions'
                        icon='sync'
                        data-activates='dropdownLoadings'
                    />
                </div>
            )
        }
        return null
    }
}

Loading.propTypes = {
    loads: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.number,
    })).isRequired,
}

export default Loading