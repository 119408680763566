import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import DtoPiezometerLight from '../../../piezometry/dto/DtoPiezometerLight'
import WatermassAction from '../../../referencial/components/watermass/actions/WatermassAction'
import { hasValue } from '../../../utils/NumberUtil'
import { execByType } from '../../../utils/StationUtils'
import { arrayOf, getLabel, getMapStateToProps, getPropTypes } from '../../../utils/StoreUtils'
import Select from '../Select'

const propsToFetch = {
    watermasses: true,
    stationTypes: true,
    stationsWithWatermass: false,
    qualitometers: false,
    hydrometricStations: false,
}

class WatermassSelect extends Component {
    constructor(props) {
        super(props)
        this.state = {
            watermass: -1,
            stations: [],
        }
    }

    componentDidMount() {
        // AppStore.dispatch(SieauAction.fetch(propsToFetch))
    }

    onChangeFilter = (value) => {
        if (hasValue(value)) {
            this.setState({ watermass: value })
            this.props.fetchStationsWithWatermass(value).then(this.onChangeStations)
        } else {
            this.props.onChange([], -1)
            this.setState({ watermass: value, stations: [] })
        }
    }

    onChangeStations = () => {
        const stations = execByType(this.props.stationType, {
            quality: () => this.props.stationsWithWatermass.filter(s => getLabel(this.props.stationTypes, s.type) === 'QUALITOMETER').map(s => this.props.qualitometers.find(q => q.id == s.id)),
            piezometry: () => this.props.stationsWithWatermass.filter(s => getLabel(this.props.stationTypes, s.type) === 'PIEZOMETER').map(s => this.props.piezometers.find(q => q.id == s.id)),
            hydrometry: () => this.props.stationsWithWatermass.filter(s => getLabel(this.props.stationTypes, s.type) === 'HYDRO').map(s => this.props.hydrometricStations.find(q => q.id == s.id)),
            default: () => this.props.stationsWithWatermass.map(s => {
                switch (getLabel(this.props.stationTypes, s)) {
                    case 'QUALITOMETER':
                        return this.props.qualitometers.find(q => q.id === s.id)
                    case 'PIEZOMETER':
                        return this.props.piezometers.find(q => q.id === s.id)
                    case 'HYDRO':
                        return this.props.hydrometricStations.find(q => q.id === s.id)
                    default:
                        return {}
                }
            }),
        })
        this.setState({ stations: stations.length })
        this.props.onChange(stations, this.state.watermass)
    }

    render() {
        return (
            <Select
                col={this.props.col}
                label={this.props.title || i18n.watermass}
                options={this.props.watermasses}
                onChange={(v) => this.onChangeFilter(v)}
                value={this.state.watermass}
                nullLabel={this.props.nullLabel || i18n.none}
            />
        )
    }
}


WatermassSelect.propTypes = getPropTypes(propsToFetch, {
    className: PropTypes.string,
    title: PropTypes.string,
    col: PropTypes.number,
    nullLabel: PropTypes.string,
    disabled: PropTypes.bool,
    withBookmarks: PropTypes.bool,
    stationType: PropTypes.stationType,
    onChange: PropTypes.func,
    piezometers: arrayOf(DtoPiezometerLight),
})

WatermassSelect.defaultProps = {
    label: '',
    className: '',
    stationType: '',
}

const mapStateToProps = store => getMapStateToProps(propsToFetch, {
    piezometers: store.PiezometryReducer.piezometersLight,
})

const mapDispatchToProps = {
    fetchStationsWithWatermass: WatermassAction.fetchStationsWithWatermass,
}

export default connect(mapStateToProps, mapDispatchToProps)(WatermassSelect)