import React from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import Table from '../../../components/datatable/Table'
import i18n from 'simple-react-i18n'
import PropTypes from 'prop-types'
import { nbPerPageLabel } from '../../../referencial/constants/ReferencialConstants'

const headers = ['installationId', 'installationName', 'date', 'parameter', 'result', 'unitSymbolWithCode', 'comment', 'producer', 'city']

const InstallationCounterResultDetailPanel = ({
    data = [],
    forceLoad = () => {},
}) => {
    const {
        installationAnalysisTableCount,
    } = useSelector(store => ({
        installationAnalysisTableCount: store.InstallationReducer.installationAnalysisTableCount,
    }), shallowEqual)

    return (
        <div>
            <Table
                title={i18n.results}
                data={data}
                sortable
                color
                type={{ headers }}
                customHeaders={{ unitSymbolWithCode: i18n.unit }}
                condensed
                paging
                nbPerPageLabel={nbPerPageLabel}
                count={installationAnalysisTableCount}
                countDisplayed={data.length}
                forceLoad={forceLoad}
            />
        </div>
    )
}

InstallationCounterResultDetailPanel.propTypes ={
    data: PropTypes.array,
    forceLoad: PropTypes.func,
}

export default InstallationCounterResultDetailPanel