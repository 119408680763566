import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import AppStore from 'store/AppStore'
import CampaignDto from '../../../../../campaign/dto/CampaignDto'
import KeyFigurePanel from '../../../../../components/keyFigures/KeyFigurePanel'
import SieauAction from '../../../../../components/sieau/SieauAction'
import DtoHydrometricStation from '../../../../../hydrometry/dto/DtoHydrometricStation'
import DtoPiezometerLight from '../../../../../piezometry/dto/DtoPiezometerLight'
import PluviometerDto from '../../../../../pluviometry/dto/PluviometerDto'
import DtoQualityStation from '../../../../../quality/dto/DtoQualityStation'
import { execByType } from '../../../../../utils/StationUtils'
import DtoKeyFigure from '../../../../dto/DtoKeyFigure'
import HydrometryAction from '../../../../../hydrometry/actions/HydrometryAction'

class StationStatisticPanel extends Component {
    componentWillMount() {
        this.fetchKeyFigure(this.props.stationType)
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.stationType !== this.props.stationType) {
            this.fetchKeyFigure(nextProps.stationType)
        }
    }

    fetchKeyFigure = (type) => {
        execByType(type, {
            quality: () => AppStore.dispatch(SieauAction.fetch('qualityKeyfigures')),
            piezometry: () => AppStore.dispatch(SieauAction.fetch('piezometryKeyfigures')),
            // pluviometry: () => AppStore.dispatch(SieauAction.fetch('pluviometryKeyfigures')),
            pluviometry: () => AppStore.dispatch(SieauAction.fetch('piezometryKeyfigures')),
            hydrometry: () => {
                AppStore.dispatch(SieauAction.fetch('hydrometryKeyfigures'))
                AppStore.dispatch(HydrometryAction.fetchCountObservations())
            }
        })
    }

    getData = () => {
        const typedData = execByType(this.props.stationType, {
            quality: () => ({
                nbStations: this.props.qualitometers.length.toString(),
                keyFigures: this.props.qualitoKeyfigures,
            }),
            piezometry: () => ({
                nbStations: this.props.piezometers.length.toString(),
                keyFigures: this.props.piezoKeyfigures,
                nbCampaign: this.props.piezometryCampaigns.length.toString(),
            }),
            pluviometry: () => ({
                nbStations: this.props.pluviometers.length.toString(),
                keyFigures: this.props.pluviometryKeyfigures,
            }),
            hydrometry: () => ({
                nbStations: this.props.hydrometricStations.length.toString(),
                keyFigures: this.props.hydroKeyfigures,
                hydroCountObservations: this.props.hydroCountObservations,
            }),
        })
        const stationKeyFigure = new DtoKeyFigure({
            title: i18n.stations,
            value: typedData.nbStations,
        })
        const campaignKeyFigure = new DtoKeyFigure({
            title: i18n.campaign,
            value: typedData.nbCampaign,
        })
        const datas = typedData.keyFigures.length !== 0 ? typedData.keyFigures.slice() : []
        const datasCountObservations = !typedData.hydroCountObservations ? [] : typedData.hydroCountObservations.length !== 0 ? typedData.hydroCountObservations.slice() : []
        if (!typedData.hydroCountObservations) {
            return [ ...datas,
                stationKeyFigure,
                campaignKeyFigure,
            ]
        } else {
            const datasCountObservations = typedData.hydroCountObservations ?? []
            return [ ...datas,
                ...datasCountObservations,
                stationKeyFigure,
                campaignKeyFigure,
            ]
        }

    }

    render() {
        return (
            <KeyFigurePanel data={ this.getData() } title={ 'Statistiques' } icon={ 'donut_large' } round={this.props.round} />
        )
    }
}

StationStatisticPanel.propTypes = {
    stationType: PropTypes.string,
    qualitoKeyfigures: PropTypes.arrayOf(PropTypes.instanceOf(DtoKeyFigure)),
    piezoKeyfigures: PropTypes.arrayOf(PropTypes.instanceOf(DtoKeyFigure)),
    pluviometryKeyfigures: PropTypes.arrayOf(PropTypes.instanceOf(DtoKeyFigure)),
    hydroKeyfigures: PropTypes.arrayOf(PropTypes.instanceOf(DtoKeyFigure)),
    hydroCountObservations: PropTypes.arrayOf(PropTypes.instanceOf(DtoKeyFigure)),
    qualitometers: PropTypes.arrayOf(PropTypes.instanceOf(DtoQualityStation)),
    piezometers: PropTypes.arrayOf(PropTypes.instanceOf(DtoPiezometerLight)),
    pluviometers: PropTypes.arrayOf(PropTypes.instanceOf(PluviometerDto)),
    hydrometricStations: PropTypes.arrayOf(PropTypes.instanceOf(DtoHydrometricStation)),
    piezometryCampaigns: PropTypes.arrayOf(PropTypes.instanceOf(CampaignDto)),
    round: PropTypes.bool,
}

const mapStateToProps = store => ({
    qualitoKeyfigures: store.QualityReducer.qualityKeyfigures,
    piezoKeyfigures: store.PiezometryReducer.piezometryKeyfigures,
    pluviometryKeyfigures: store.PluviometryReducer.pluviometryKeyfigures,
    hydroKeyfigures: store.HydrometryReducer.hydrometryKeyfigures,
    hydroCountObservations: store.HydrometryReducer.hydroCountObservations,
    qualitometers: store.QualityReducer.qualitometersListSpecific,
    piezometers: store.PiezometryReducer.piezometerListSpecific,
    pluviometers: store.PluviometryReducer.pluviometers,
    hydrometricStations: store.HydrometryReducer.hydrometricStations,
    piezometryCampaigns: store.PiezometryReducer.piezometryCampaigns,
})

export default connect(mapStateToProps)(StationStatisticPanel)
