/* eslint-disable camelcase */
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid2 } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { POINT_STATUS_DECLARATION } from 'agriAdministration/constants/AgriConstants'
import Checkbox from 'components/forms/Checkbox'
import { AccordionDetailsMUI, AccordionMUI, AccordionSummaryMUI } from 'components/styled/Accordions'
import DtoExploitation from 'exploitations/dto/DtoExploitation'
import DtoInstallation from 'installation/dto/installation/DtoInstallation'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import DtoDeclaration from 'survey/dto/DtoDeclaration'
import { getI18nTitleData } from 'utils/StringUtil'

const useStyles = makeStyles((theme) => ({
    modal: {
        backgroundColor: theme.palette.background,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        margin: '10px auto',
        bottom: 'inherit',
        height: '90vh',
        width: '90vw',
        overflowY: 'auto',
        zIndex: '10000',
    },
    collapse: {
        width: '100%',
    },
    gridItem: {
        borderBottom: '1px solid lightgrey',
        padding: '5px 10px',
    },
}))

const ModalAddPoint = ({
    open,
    toggleModal,
    addPoint,
    selectedPoints,
    installationPoints,
    declaration,
    citiesIndex,
}) => {
    const classes = useStyles()
    const [listPoints, setListPoints] = useState(selectedPoints)
    const noUsePoints = installationPoints.filter((p) => p.status === POINT_STATUS_DECLARATION.REMOVED || !p.status)
    // const deletePoints = installationPoints.filter((p) => p.status === 3)

    const handleAddPoint = (p) => {
        const pointsFiltered = listPoints.filter((point) => point.idInstallation !== p.id)
        if (listPoints.find((point) => point.idInstallation === p.id && point.stateCode === 1)) {
            setListPoints(pointsFiltered)
        } else {
            const linkPoint = listPoints.find((point) => point.idInstallation === p.id) || {}
            const newPoints = [
                ...pointsFiltered,
                {
                    ...linkPoint,
                    idSurvey: declaration.idSurvey,
                    idExploitation: declaration.idExploitation,
                    idInstallation: p.id,
                    stateCode: 1,
                    link_usages: linkPoint.link_usages || [],
                },
            ]
            setListPoints(newPoints)
        }
    }

    const cancelAndClose = () => {
        setListPoints(selectedPoints)
        addPoint(selectedPoints)
        toggleModal()
    }

    const saveAndClose = () => {
        addPoint(listPoints)
        toggleModal()
    }

    const isSelected = (point) => {
        return listPoints.find((p) => p.idInstallation === point.id && p.stateCode === POINT_STATUS_DECLARATION.USED)
    }

    const getFilteredPoints = (points) => {
        return points.filter((p) => !selectedPoints.find((point) => point.idInstallation === p.id))
    }

    const item = (city, point) => (
        <Grid2 size={10}>
            <span className='bold'>{city ? city.name : ''}</span><br />
            <span>{point.location || ''}</span><br />
            <span>{`${point.code || ''} ${`- ${point.parcel || ''} ${point.section || ''}${point.name ? ` - ${point.name}` : ''}`}`}</span><br />
        </Grid2>
    )

    const pointsUsed = selectedPoints.filter((point) => point.stateCode !== POINT_STATUS_DECLARATION.REMOVED && point.stateCode !== POINT_STATUS_DECLARATION.ADJOURNED)
    const pointsNotUsed = getFilteredPoints(noUsePoints)
    const pointsAbandonned = selectedPoints.filter((point) => point.stateCode === POINT_STATUS_DECLARATION.REMOVED)
    const pointsAdjourned = selectedPoints.filter((point) => point.stateCode === POINT_STATUS_DECLARATION.ADJOURNED)

    return (
        <Dialog
            fullWidth
            maxWidth='md'
            open={open}
        >
            <DialogTitle>
                {i18n.select}
            </DialogTitle>
            <DialogContent>
                <AccordionMUI>
                    <AccordionSummaryMUI>
                        <span style={{ fontSize: '1.5rem' }}>{pointsUsed.length} {`${getI18nTitleData(i18n.usedPoint, i18n.usedPoints, pointsUsed)}`}</span>
                    </AccordionSummaryMUI>
                    <AccordionDetailsMUI nopadding>
                        {installationPoints.map((p) => {
                            if (pointsUsed.find((point) => point.idInstallation === p.id)) {
                                const city = citiesIndex[p.townCode]
                                return (
                                    <Grid2 key={p.code} container className={classes.gridItem}>
                                        {item(city, p)}
                                    </Grid2>
                                )
                            }
                            return ''
                        })}
                    </AccordionDetailsMUI>
                </AccordionMUI>
                <br />
                <AccordionMUI>
                    <AccordionSummaryMUI>
                        <span style={{ fontSize: '1.5rem' }}>{pointsAdjourned.length} {`${getI18nTitleData(i18n.adjournPoint, i18n.adjournPoints, pointsAdjourned)}`}</span>
                    </AccordionSummaryMUI>
                    <AccordionDetailsMUI nopadding>
                        {installationPoints.map((p) => {
                            if (pointsAdjourned.find((point) => point.idInstallation === p.id)) {
                                const city = citiesIndex[p.townCode]
                                return (
                                    <Grid2 key={p.code} container className={classes.gridItem}>
                                        {item(city, p)}
                                        <Grid2 size={2}>
                                            <Checkbox
                                                checked={isSelected(p)}
                                                onChange={() => handleAddPoint(p)}
                                            />
                                        </Grid2>
                                    </Grid2>
                                )
                            }
                            return ''
                        })}
                    </AccordionDetailsMUI>
                </AccordionMUI>
                <br />
                <AccordionMUI defaultExpanded>
                    <AccordionSummaryMUI>
                        <span style={{ fontSize: '1.5rem' }}>{pointsNotUsed.length} {`${getI18nTitleData(i18n.noUsedPoint, i18n.noUsedPoints, pointsNotUsed)}`}</span>
                    </AccordionSummaryMUI>
                    <AccordionDetailsMUI nopadding>
                        {pointsNotUsed.map((p) => {
                            const city = citiesIndex[p.townCode]
                            return (
                                <Grid2 key={p.code} container alignItems='center' className={classes.gridItem}>
                                    {item(city, p)}
                                    <Grid2 size={2}>
                                        <Checkbox
                                            checked={isSelected(p)}
                                            onChange={() => handleAddPoint(p)}
                                        />
                                    </Grid2>
                                </Grid2>
                            )
                        })}
                    </AccordionDetailsMUI>
                </AccordionMUI>
                <br />
                <AccordionMUI>
                    <AccordionSummaryMUI>
                        <span style={{ fontSize: '1.5rem' }}>{pointsAbandonned.length} {`${getI18nTitleData(i18n.abandonnedPoint, i18n.abandonnedPoints, pointsAbandonned)}`}</span>
                    </AccordionSummaryMUI>
                    <AccordionDetailsMUI nopadding>
                        {pointsAbandonned.map((p) => {
                            if (selectedPoints.filter((point) => point.stateCode === 2).find((point) => point.idInstallation === p.id)) {
                                const city = citiesIndex[p.townCode]
                                return (
                                    <Grid2 key={p.code} container className={classes.gridItem}>
                                        {item(city, p)}
                                    </Grid2>
                                )
                            }
                            return ''
                        })}
                    </AccordionDetailsMUI>
                </AccordionMUI>
            </DialogContent>
            <DialogActions style={{ justifyContent: 'space-between' }}>
                <Button variant='outlined' color='primary' onClick={cancelAndClose}>
                    {i18n.cancel}
                </Button>
                <Button variant='contained' color='primary' onClick={saveAndClose}>
                    {i18n.validate}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

ModalAddPoint.propTypes = {
    open: PropTypes.bool.isRequired,
    toggleModal: PropTypes.func.isRequired,
    addPoint: PropTypes.func.isRequired,
    declaration: PropTypes.instanceOf(DtoDeclaration).isRequired,
    exploitation: PropTypes.instanceOf(DtoExploitation).isRequired,
    installationPoints: PropTypes.arrayOf(PropTypes.instanceOf(DtoInstallation)).isRequired,
    selectedPoints: PropTypes.arrayOf(PropTypes.instanceOf(DtoInstallation)).isRequired,
    citiesIndex: PropTypes.instanceOf(PropTypes.shape({})),
}

const mapStateToProps = (store) => {
    return {
        citiesIndex: store.CityReducer.citiesIndex,
    }
}

export default connect(mapStateToProps)(ModalAddPoint)