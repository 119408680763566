export default class DtoCmsStation {
    constructor(obj) {
        this.cmsId = obj.cmsId
        this.stationId = obj.stationId
        this.siteType = obj.siteType
        this.siteCode = obj.siteCode
        this.dataTypeId = obj.dataTypeId
        this.valueDate = obj.valueDate
        this.value = obj.value
        this.dateMaj = obj.dateMaj
        this.loginMaj = obj.loginMaj
        this.installationCode = obj.installationCode
    }
}
