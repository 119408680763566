module.exports = {
    TAXON: 'taxon',
    RESET_TAXON: 'RESET_TAXON',
    RECEIVE_TAXONS: 'RECEIVE_TAXONS',
    RECEIVE_TAXON: 'RECEIVE_TAXON',
    RECEIVE_QUALITOMETER_BY_TAXON: 'RECEIVE_QUALITOMETER_BY_TAXON',

    PANEL_DESCRIPTION: 'PANEL_DESCRIPTION',
    PANEL_MAP: 'PANEL_MAP',
}
