export default class PerimetersFolderDto {
    constructor(obj) {
        this.folderId = obj.folderId // Int,
        this.name = obj.name // String,
        this.identifier = obj.identifier // Option[Int],
        this.collectivity = obj.collectivity // Option[Int],
        this.status = obj.status // Option[Int],
        this.state = obj.state // Option[Int],
        this.sau = obj.sau // Option[Float],
        this.perimeterSau = obj.perimeterSau // Option[Float],
        this.updateDate = obj.updateDate // Option[DateTime],
        this.updateLogin = obj.updateLogin // Option[String],
        this.startDate = obj.startDate // Option[DateTime],
        this.cityName = obj.cityName // Option[String],

        this.cities = obj.cities?.length ? obj.cities.split(',') : [] // Option[String],
        this.perimeters = obj.perimeters // Int
    }
}