export default class CampaignPlanningDto {
    constructor(obj) {
        this.id = obj.id // Long,
        this.campaignId = obj.campaignId // Long,
        this.startDate = obj.startDate // DateTime,
        this.endDate = obj.endDate // DateTime,
        this.selection = obj.selection // Option[String],
        this.stationsPlan = obj.stationsPlan // Seq[CampaignStationPlan],
        this.parametersPlan = obj.parametersPlan // Seq[CampaignParameterPlan]
    }
}