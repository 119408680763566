import React, { useState } from 'react'
import i18n from 'simple-react-i18n'
import Card from '../../../../components/card/Card'
import Row from '../../../../components/react/Row'
import Button from '../../../../components/forms/Button'
import Select from '../../../../components/forms/Select'
import { getQualificationSelectOptions, getStatusSelectOptions } from '../../../../utils/StatusUtil'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import NumberField from '../../../../components/forms/NumberField'
import Checkbox from '../../../../components/forms/Checkbox'
import { hasValue } from '../../../../utils/NumberUtil'
import Input from '../../../../components/forms/Input'
import PropTypes from 'prop-types'
import { getSandreList } from '../../../../utils/StoreUtils'
import { getDate, getHour } from '../../../../utils/DateUtil'
import { SANDRE } from '../../../../referencial/constants/ReferencialConstants'
import { last } from 'lodash'
import {
    HISTORIC, MOVING, NEWPOINT,
    POINT,
    VALIDATION,
    WINDOW,
} from '../constants/PluvioValidationConstants'
import PluviometryAction from '../../../actions/PluviometryAction'


const ValidationPluvioToolPanel = ({
    selection,
    setSelection,
    setSelectionData,
    objetPluvioStation,
    dates,
    value,
    setValue,
    initialPoint,
    setInitialPoint,
    status,
    setStatus,
    qualification,
    setQualification,
    nature,
    measureMode, // TODO
    producer,
    manager,
    validator,
    setNature,
    setMeasureMode,
    setProducer,
    setManager,
    setValidator,
    setStartDate,
    setEndDate,
    setIsChange,
    isChange,
    measureToUpdate,
}) => {
    const dispatch = useDispatch()
    const [subTool, setSubTool] = useState(VALIDATION)
    const [isValidateActive, setIsValidateActive] = useState(false)
    const [withCancel, setWithCancel] = useState(null)
    const {
        sandreCodes,
        contributors,
    } = useSelector(store => ({
        sandreCodes: store.ReferencialReducer.sandreCodes,
        contributors: store.ContributorReducer.contributors,
    }), shallowEqual)

    const onCancel = () => {
        setIsValidateActive(false)
        setWithCancel(null)
    }
    const onSave = () => {
        setIsValidateActive(false)
        dispatch(PluviometryAction.updateMeasures(measureToUpdate)).then(() => setIsChange(!isChange))
    }
    const onCheckStatusQualification = () => {
        setIsValidateActive(!isValidateActive)
    }

    const selectHisto = () => {
        const m = objetPluvioStation[0].measures
        setStartDate(m[0].date)
        setEndDate(last(m).date)
    }

    const point = selection === POINT || selection === MOVING && dates.startDate ? objetPluvioStation.find(m => m.date === dates.startDate) : null
    const disabled = { disabled: selection !== POINT && isValidateActive }
    const disabledDates = { disabled: disabled.disabled || ![WINDOW, NEWPOINT].includes(selection) }
    const resetToolValues = () => {
        setInitialPoint(null)
        setValue(null)
        setStatus(null)
        setQualification(null)
        setNature(null)
        setStartDate(null)
        setEndDate(null)
        setSelectionData(null)
    }
    return (
        <Card title={ i18n.piezoValidation }>
            <div className='padding-top-1' id='validationToolPanel'/>
            <Row className='padding-bottom-1 center-align'>
                <Button tooltip={ i18n.selectRange } icon='settings_ethernet'
                    className={ `validToolBtn margin-right-1 ${subTool === VALIDATION && selection === WINDOW ? 'activated' : ''}` }
                    onClick={ () => {
                        // this.props.changeParent({ selectionMode: 'start', startDate: null, endDate: null, draggable: false, selectedPoint: null })
                        setSubTool(VALIDATION)
                        setSelection(WINDOW)
                        resetToolValues()
                        setSelectionData('end')
                    } } {...disabled}
                />
                <Button tooltip={ i18n.selectHistory }
                    className={ `validToolBtn margin-right-1 ${subTool === VALIDATION && selection === HISTORIC ? 'activated' : ''}` }
                    onClick={ () => {
                        // his.props.changeParent({ draggable: false, selectionMode: null, selectedPoint: null })
                        setSubTool(VALIDATION)
                        setSelection((HISTORIC))
                        resetToolValues()
                        selectHisto()
                    } } icon='insert_chart' {...disabled}
                />
                <Button tooltip={ i18n.selectMeasure } icon='edit_location'
                    className={ `validToolBtn margin-right-1 ${subTool === VALIDATION && selection === POINT ? 'activated' : ''}` }
                    onClick={ () => {
                        // this.props.changeParent({ selectionMode: 'point', startDate: null, endDate: null, draggable: false, selectedPoint: null })
                        setSubTool(VALIDATION)
                        setSelection(POINT)
                        resetToolValues()
                    }}
                />
                {/* <Button tooltip={ i18n.addMeasure } onClick={ () => {
                    // const lastMeasure = last(this.props.measures)
                    // const newDate = lastMeasure ? moment(lastMeasure.date).add(1, 'day').valueOf() : null
                    // this.props.changeParent({ selectionMode: null, startDate: newDate, endDate: newDate, draggable: false, selectedPoint: null })
                    setSubTool('newPoint')
                    setSelection('newPoint')
                    resetToolValues()
                } } icon='add' className={ `validToolBtn margin-right-1 ${subTool === 'newPoint' ? 'activated' : ''}` }
                />*/}
            </Row>
            <div>
                <Row>
                    <Input col={ 6 } title={ [WINDOW, HISTORIC].includes(selection) ? i18n.startDate : i18n.date } value={ getDate(dates.startDate) } {...disabledDates}/>
                    <Input col={ 6 } title={ [WINDOW, HISTORIC].includes(selection) ? i18n.startHour : i18n.hour } value={ getHour(dates.startDate) } {...disabledDates}/>
                </Row>
                {
                    [WINDOW, HISTORIC].includes(selection) && (
                        <Row >
                            <Input col={ 6 } title={ i18n.endDate } value={ getDate(dates.endDate) } {...disabledDates}/>
                            <Input col={ 6 } title={ i18n.endHour } value={ getHour(dates.endDate) } {...disabledDates}/>
                        </Row>
                    )
                }
                <Row className='padding-top-1'>
                    <Select col={ 6 } label={ i18n.status } value={ status } noSort
                        onChange={ setStatus } options={ getStatusSelectOptions() } nullLabel='&nbsp;'
                    />
                    <Select col={ 6 } label={ i18n.qualification } value={ qualification } noSort
                        onChange={ setQualification } options={ getQualificationSelectOptions() } nullLabel='&nbsp;'
                    />
                </Row>
                <Row>
                    <Select col={ 6 } label={ i18n.measureNature } value={ nature }
                        onChange={ setNature } options={ getSandreList(sandreCodes, SANDRE.PIEZOMETER_MEASURE_NATURE, true) } nullLabel='&nbsp;'
                    />
                    {/* <Select col={ 6 } label={ i18n.obtainningMode } value={ measureMode }
                            onChange={ setMeasureMode }  énullLabel='&nbsp;'
                    /> */}
                </Row>
                <Row>
                    <Select col={ 12 } label={ i18n.producer } value={ producer }
                        onChange={ setProducer } options={ contributors }
                    />
                </Row>
                <Row>
                    <Select col={ 6 } label={ i18n.administrator } value={ manager }
                        onChange={ setManager } options={ contributors }
                    />
                    <Select col={ 6 } label={ i18n.validator } value={ validator }
                        onChange={ setValidator } options={ contributors }
                    />
                </Row>
                { [POINT, MOVING, NEWPOINT].includes(selection) && (
                    <>
                        <Row className='valign-wrapper'>
                            <NumberField col={ 6 } title={ i18n.valueLabel } value={value} onChange={
                                setValue
                            } floatValue
                            />
                            <div className='col s6 padding-top-1'>
                                <Checkbox label={ i18n.initialPoint } checked={ hasValue(initialPoint) ? initialPoint === 1 : (point && point.initialPoint === 1) }
                                    onChange={ v => setInitialPoint(v ? 1 : 2) } {...disabled}
                                />
                            </div>
                        </Row>
                    </>
                ) }
            </div>
            { /* this.getSelectedMeasurePanel()*/ }
            <Row className='padding-bottom-1 padding-top-1 center-align'>
                <Button tooltip={ i18n.apply } onClick={ onCheckStatusQualification } icon='border_color' className='btn-floating btn-large' disabled={isValidateActive} />
                <Button tooltip={ i18n.cancel } onClick={ onCancel } icon='cancel' className='red btn-floating btn-large margin-left-2 margin-right-2' disabled={ !isValidateActive }/>
                <Button tooltip={ i18n.register } onClick={ onSave } icon='save' disabled={ !isValidateActive } className={ `btn-floating btn-large ${isValidateActive ? 'pulse' : ''}` }/>
            </Row>
        </Card>
    )
}
ValidationPluvioToolPanel.propTypes = {
    selection: PropTypes.string,
    setSelection: PropTypes.func,
    setSelectionData: PropTypes.func,
    selectHisto: PropTypes.func,
    dates: PropTypes.objectOf(PropTypes.number),
    value: PropTypes.number,
    setValue: PropTypes.func,
    initialPoint: PropTypes.number,
    setInitialPoint: PropTypes.func,
    status: PropTypes.string,
    setStatus: PropTypes.func,
    qualification: PropTypes.string,
    setQualification: PropTypes.func,
    setStartDate: PropTypes.func,
    setEndDate: PropTypes.func,
}
export default ValidationPluvioToolPanel