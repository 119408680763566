import { filter, last, orderBy } from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import AlertPanel from '../../../../components/alert/AlertPanel'
import DtoPiezometryStationMeasure from '../../../../station/dto/piezometer/DtoPiezometryStationMeasure'
import DtoPiezoThreshold from '../../../../station/dto/piezometer/DtoPiezoThreshold'
import DtoPiezometer from '../../../dto/DtoPiezometer'

class ThresholdAlertPanel extends Component {
    constructor(props) {
        super(props)
    }

    getValue = (minThreshold, maxThreshold, measures) => {
        const measureValue = measures
        if (!minThreshold && maxThreshold) {
            return measureValue > maxThreshold.value
        } else if (minThreshold && maxThreshold) {
            return measureValue <= minThreshold.value && measureValue > maxThreshold.value
        } else if (minThreshold && !maxThreshold) {
            return measureValue <= minThreshold.value
        }
        return 0
    }

    getThresholdsDataAlerts = (lastMeasure, thresholds) => {
        const finalDatas = (() => {
            if (thresholds.length === 1) {
                if (lastMeasure.value < thresholds[0].value) {
                    return [{
                        threshold: thresholds[0],
                        nbValues: 1,
                    }]
                }
            }
            return thresholds.map((threshold, index) => {
                const minThreshold = (() => {
                    if (!index) {
                        return threshold
                    } else if (index === thresholds.length - 1) {
                        return threshold
                    }
                    return thresholds[index - 1]
                })()
                const maxThreshold = (() => {
                    if (index === thresholds.length - 1) {
                        return null
                    }
                    return thresholds[index + 1]
                })()
                return {
                    threshold,
                    nbValues: this.getValue(minThreshold, maxThreshold, lastMeasure),
                }
            })
        })()
        const filterDatas = filter(finalDatas, j => j.nbValues)
        if (filterDatas.length) {
            const threshold = filterDatas[0].threshold
            return (
                <AlertPanel comment={ `[${this.props.piezometer.code}] - ${this.props.piezometer.name}` }
                    title={ threshold.title }
                    icon={ 'warning' } color={ 'orange' }
                />
            )
        }
        return null
    }
    getMeasures = () => {
        if (this.props.piezometerThresholds.length) {
            const lastMeasure = last(this.props.measures)
            const altitude = this.props.piezometer.altitude ? this.props.piezometer.altitude : 0
            const thresholds = filter(orderBy(this.props.piezometerThresholds.map(o => {
                return Object.assign({}, o, {
                    value: o.value - altitude,
                })
            }), ['value'], ['desc']), j => j.value)
            if (!lastMeasure || lastMeasure && lastMeasure.value > thresholds[0].value) {
                return null
            }
            return this.getThresholdsDataAlerts(lastMeasure, thresholds)
        }
        return null
    }

    render() {
        const measure = this.getMeasures()
        if (measure) {
            return (
                <div className='margin-bottom-1'>
                    { measure }
                </div>
            )
        }
        return null
    }
}

ThresholdAlertPanel.propTypes = {
    measures: PropTypes.arrayOf(PropTypes.instanceOf(DtoPiezometryStationMeasure)),
    piezometer: PropTypes.instanceOf(DtoPiezometer),
    piezometerThresholds: PropTypes.arrayOf(PropTypes.instanceOf(DtoPiezoThreshold)),
}
export default ThresholdAlertPanel
