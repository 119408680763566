import { minBy, orderBy, range } from 'lodash'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { useMemo, useState, useEffect } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import Button from 'components/forms/Button'
import Select from 'components/forms/Select'
import ThresholdSelect from 'components/forms/specific/ThresholdSelect'
import CollapseTopBar from 'components/topBar/CollapseToBar'
import DtoOperation from 'quality/dto/operation/DtoOperation'
import SupportAction from '../../../referencial/components/support/actions/SupportAction'

const SEUILS_REF = 'SEUILS_REFERENCE'
const SEUILS_LIM = 'SEUILS_LIMITE'

const AEPCriterias = ({
    defaultFilter = {},
    onValidate = () => { },
    operations = [],
}) => {
    const {
        producers,
        supports,
    } = useSelector(store => ({
        producers: store.ContributorReducer.producers,
        supports: store.SupportReducer.supports,
    }), shallowEqual)

    const dispatch = useDispatch()

    const [producer, setProducer] = useState(defaultFilter.producer)
    const [year, setYear] = useState(defaultFilter.year)
    const [referenceThreshold, setReferenceThreshold] = useState(defaultFilter.referenceThreshold)
    const [limitThreshold, setLimitThreshold] = useState(defaultFilter.limitThreshold)
    const [support, setSupport] = useState(defaultFilter.support)

    useEffect(() => {
        if (!supports.length) {
            dispatch(SupportAction.fetchSupports())
        }
    }, [])

    const yearList = useMemo(() => {
        const minDate = minBy(operations, 'date')
        const list = minDate ? range(moment().year(), moment(minDate).year(), -1).map(y => ({ code: y, name: y })) : []
        return orderBy(list, 'code', 'desc')
    }, [operations])

    return (
        <CollapseTopBar>
            <div className='card-content grey-background padding-1'>
                <div className='row no-margin'>
                    <Select
                        options={producers}
                        label={i18n.producers}
                        col={9}
                        value={producer}
                        nullLabel='&nbsp;'
                        onChange={setProducer}
                    />
                    <Select
                        options={yearList}
                        label={i18n.year}
                        col={3}
                        value={year}
                        onChange={setYear}
                        nullLabel='&nbsp;'
                        noSort
                    />
                </div>
                <div className='row no-margin valign-wrapper'>
                    <ThresholdSelect
                        col={3}
                        title={i18n.referenceThreshold}
                        selected={referenceThreshold}
                        onChange={setReferenceThreshold}
                        fieldRef={SEUILS_REF}
                    />
                    <ThresholdSelect
                        col={3}
                        title={i18n.limitThreshold}
                        selected={limitThreshold}
                        onChange={setLimitThreshold}
                        fieldRef={SEUILS_LIM}
                    />
                    <Select
                        col={3}
                        label={i18n.support}
                        options={supports}
                        onChange={setSupport}
                        value={support}
                        keyValue={'id'}
                        displayWithCode
                    />
                    <Button
                        col={3}
                        title={i18n.search}
                        onClick={() => onValidate({
                            producer,
                            year,
                            referenceThreshold,
                            limitThreshold,
                            support,
                        })}
                    />
                </div>
            </div>
        </CollapseTopBar>
    )
}

AEPCriterias.propTypes = {
    onValidate: PropTypes.func,
    defaultFilter: PropTypes.shape({
        producer: PropTypes.number,
        year: PropTypes.number,
        threshold: PropTypes.number,
        support: PropTypes.string,
        filter: PropTypes.number,
        filterResults: PropTypes.arrayOf(PropTypes.number),
    }),
    operations: PropTypes.arrayOf(PropTypes.instanceOf(DtoOperation)),
}

export default AEPCriterias