import { createAsyncThunk } from '@reduxjs/toolkit'
import { H_HYDRO_MODULE } from 'account/constants/AccessRulesConstants'
import ApplicationConf from 'conf/ApplicationConf'
import { flatten } from 'lodash'
import LogAction from 'log/actions/LogAction'
import i18n from 'simple-react-i18n'
import { REJECTED, IDLE } from 'store/DataManagerConstants'
import ToastrAction from 'toastr/actions/ToastrAction'
import { genericPromise } from 'utils/ActionUtils'
import { isAuthorized } from 'utils/HabilitationUtil'
import HydrometryAction from './HydrometryAction'

const HydrometryThunk = {
    fetchHydrometricStations: createAsyncThunk(
        'fetchHydrometricStations',
        async (_, { rejectWithValue, dispatch }) => {
            try {
                const response = await genericPromise(ApplicationConf.hydrometricStation.getAll(), 'GET')
                return response
            } catch (err) {
                dispatch(LogAction.logError(`${i18n.fetchError} ${i18n.hydrometricStations} : ${err}`))
                dispatch(ToastrAction.error(`${i18n.fetchError} ${i18n.hydrometricStations}`))
                return rejectWithValue(err)
            }
        },
        {
            condition: (arg = { forceFetch: false }, { getState }) => {
                const state = getState()
                if (!isAuthorized(H_HYDRO_MODULE, state)) {
                    return false
                }
                const { hydrometricStationsStatus } = state.DataManagerReducer.hydrometry
                if (arg.forceFetch) {
                    return hydrometricStationsStatus !== REJECTED
                }
                return hydrometricStationsStatus === IDLE
            },
            dispatchConditionRejection: true,
        },
    ),

    fetchHydroBookmarksStats: createAsyncThunk(
        'fetchHydroBookmarksStats',
        async (ids, { rejectWithValue, dispatch }) => {
            try {
                if (!ids.length) {
                    return []
                }
                const promises = ids.map(id =>
                    HydrometryAction.promiseHydroBookmarksStats(id).then(json => json.map(stat => ({ ...stat, hydroId: id })))
                )
                const response = await Promise.all(promises)
                return flatten(response)
            } catch (err) {
                dispatch(LogAction.logError(`${i18n.dataError} : ${err}`))
                dispatch(ToastrAction.error(`${i18n.dataError}`))
                return rejectWithValue(err)
            }
        },
        {
            condition: (_, { getState }) => {
                const state = getState()
                return isAuthorized(H_HYDRO_MODULE, state)
            },
            dispatchConditionRejection: true,
        },
    ),
}

export default HydrometryThunk