import React, { Component } from 'react'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import { round } from '../../utils/NumberUtil'
import { sieauTooltip } from '../../utils/FormUtils'

class ProgressBar extends Component {
    render() {
        if (this.props.withMessage) {
            if (this.props.indeterminate) {
                return (
                    <div>
                        <div>
                            <h6 className='center-align'>{ this.props.message || i18n.progressLoading }</h6>
                        </div>
                        <div className={`progress ${this.props.className}` } {...(this.props.tooltip ? sieauTooltip(this.props.tooltip, null, 'bottom') : null)} >
                            <div className={ `indeterminate ${this.props.color}` }/>
                        </div>
                    </div>
                )
            }
            return (
                <div>
                    <div>
                        <h6 className='center-align'>{ this.props.message || i18n.progressLoading } ({ round(this.props.progress, 0) } %)</h6>
                    </div>
                    <div className={`progress ${this.props.className}` } {...(this.props.tooltip ? sieauTooltip(this.props.tooltip, null, 'bottom') : null)} >
                        <div className={ `determinate ${this.props.color}` } style={ { width: `${this.props.progress}%` } }/>
                    </div>
                </div>
            )
        }
        if (this.props.indeterminate) {
            return (
                <div className={`progress ${this.props.className}` } {...(this.props.tooltip ? sieauTooltip(this.props.tooltip, null, 'bottom') : null)} >
                    <div className={ `indeterminate ${this.props.color}` }/>
                </div>
            )
        }
        return (
            <div className={`progress ${this.props.className}`} {...(this.props.tooltip ? sieauTooltip(this.props.tooltip, null, 'bottom') : null)} style={{ height: this.props.height }} >
                <div className={ `determinate ${this.props.color}` } style={ { width: `${this.props.progress}%` } }/>
            </div>
        )
    }
}

ProgressBar.propTypes = {
    progress: PropTypes.number,
    className: PropTypes.string,
    tooltip: PropTypes.string,
    color: PropTypes.string,
    withMessage: PropTypes.bool,
    indeterminate: PropTypes.bool,
    message: PropTypes.string,
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}

ProgressBar.defaultProps = {
    className: '',
    tooltip: '',
    color: '',
    height: '7px',
}

export default ProgressBar
