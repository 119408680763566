import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import IAction from './IAction'

class Cancel extends IAction {
    fn = () => {
        return this.props.onCancel
    }
    icon = () => {
        return 'undo'
    }
    label = () => {
        return i18n.cancel
    }
    id = () => {
        return 'cancel_action_navbar'
    }
}

Cancel.propTypes = {
    onCancel: PropTypes.func.isRequired,
}

export default Cancel
