import i18n from 'simple-react-i18n'
import React from 'react'
import PropTypes from 'prop-types'
import { Grid } from '@mui/material'
import StationImportCodeMessage from 'import/components/utils/StationImportCodeMessage'
import { hasValue } from 'utils/NumberUtil'
import { StyledFieldSet, StyledLegend, StyledSpan } from 'components/StyledElements'
import Input from 'components/forms/Input'


const AddStepOptionsEmpty = ({
    station,
    changeStation,
    stationType,
}) => {
    return (
        <StyledFieldSet>
            <StyledLegend>
                <StyledSpan>{i18n.options}</StyledSpan>
            </StyledLegend>
            <Grid container justifyContent='space-between' alignItems='center'>
                <Grid item xs={4}>
                    <Input
                        value={station.code}
                        onChange={v => changeStation({ code: v })}
                        title={i18n.code}
                        noSpace
                    />
                </Grid>
                <Grid item xs={7}>
                    <Input
                        value={station.name}
                        onChange={v => changeStation({ name: v })}
                        title={i18n.name}
                    />
                </Grid>
                <Grid item xs={12}>
                    {hasValue(station.code) && <StationImportCodeMessage code={station.code} stationType={stationType} />}
                </Grid>
            </Grid>
        </StyledFieldSet>
    )
}

AddStepOptionsEmpty.propTypes = {
    station: PropTypes.shape({
        code: PropTypes.string,
        stationType: PropTypes.string,
        name: PropTypes.string,
        jobExecutionId: PropTypes.number,
        job: PropTypes.shape({
            id: PropTypes.number,
        }),
        jobExecution: PropTypes.shape({
            statusCode: PropTypes.number,
        }),
        ids: PropTypes.arrayOf(PropTypes.number),
        nature: PropTypes.string,
    }),
    changeStation: PropTypes.func,
    stationType: PropTypes.string,
}

export default AddStepOptionsEmpty