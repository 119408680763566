import React from 'react'
import PropTypes from 'prop-types'
import Icon from './Icon'

// To center the icon vertically, just add {{ display: 'table' }} to the parent component.
const CenterIcon = ({
    icon = '',
    color = 'black',
    additionalStyle = {},
}) => {
    return (
        <Icon
            size='medium'
            icon={icon}
            style={{
                color,
                width: '100%',
                textAlign: 'center',
                display: 'table-cell',
                verticalAlign: 'middle',
                ...additionalStyle,
            }}
        />
    )
}

CenterIcon.propTypes = {
    icon: PropTypes.string.isRequired,
    color: PropTypes.string,
    additionalStyle: PropTypes.object,
}

export default CenterIcon
