import AdministrationAction from 'administration/actions/AdministrationAction'
import UserDto from 'administration/components/user/dto/UserDto'
import GlobalParametersDto from 'administration/dto/GlobalParameterDto'
import HomeAction from 'home/actions/HomeAction'
import BoundaryError from 'log/components/BoundaryError'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Outlet } from 'react-router-dom'
import i18n from 'simple-react-i18n'
import AppStore from 'store/AppStore'
import {
    H_PIEZO_CAMPAIGN,
    H_PIEZO_DASHBOARD,
    H_PIEZO_DIAGNOSTICS,
    H_PIEZO_EXPORT,
    H_PIEZO_EXPORT_OVERVIEW,
    H_PIEZO_GRAPHIC,
    H_PIEZO_IMPORT,
    H_PIEZO_INTEGRATION,
    H_PIEZO_MAP_SITUATION,
    H_PIEZO_OPTIONS,
} from '../../../account/constants/AccessRulesConstants'
import User from '../../../account/dto/User'
import FixedSlideNav from '../../../components/navbar/FixedSlideNav'
import { links } from '../../../quality/constants/StationsConstants'
import { componentHasHabilitations } from '../../../utils/HabilitationUtil'

class PiezometersApp extends Component {
    componentWillMount() {
        AppStore.dispatch(HomeAction.setHelpLink('', ''))
    }

    getLink = (linkName) => {
        const link = links[linkName]
        return {
            ...link,
            href: link.href || `/piezometry/${link.hrefName || linkName}`,
            name: i18n[link.i18n],
        }
    }

    render() {
        const navBarLinks = [
            ...[
                { habilitation: H_PIEZO_DASHBOARD, link: 'dashboard' },
                { habilitation: H_PIEZO_IMPORT, link: 'import' },
                { habilitation: H_PIEZO_EXPORT_OVERVIEW, link: 'exportOverview' },
                { habilitation: H_PIEZO_EXPORT, link: 'export' },
                { habilitation: H_PIEZO_INTEGRATION, link: 'integrationOverview' },
                { habilitation: H_PIEZO_CAMPAIGN, link: 'campaign' },
                { habilitation: H_PIEZO_GRAPHIC, link: 'graphic' },
                { habilitation: H_PIEZO_DIAGNOSTICS, link: 'diagnostics' },
                { habilitation: H_PIEZO_OPTIONS, link: 'piezometryOptions' },
            ].filter(l => componentHasHabilitations(l.habilitation)),
            { habilitation: H_PIEZO_MAP_SITUATION, link: 'mapSituations' },
        ].filter(l => l).map(l => this.getLink(l.link))

        return (
            <div className='row no-margin'>
                <FixedSlideNav links={navBarLinks}/>
                <main>
                    <div className='row no-margin padding-left-1'>
                        <div className='content-page no-padding fullscreen'>
                            <BoundaryError>
                                <Outlet />
                            </BoundaryError>
                        </div>
                    </div>
                </main>
            </div>
        )
    }
}


PiezometersApp.propTypes = {
    children: PropTypes.element,
    getLink: PropTypes.func,
    accountUser: PropTypes.instanceOf(User),
    globalParameters: PropTypes.arrayOf(PropTypes.instanceOf(GlobalParametersDto)),
    users: PropTypes.arrayOf(PropTypes.instanceOf(UserDto)),
    fetchGlobalParameters: PropTypes.func,
}

const mapStateToProps = store => ({
    accountUser: store.AccountReducer.accountUser,
    globalParameters: store.AdministrationReducer.globalParameters,
    users: store.UserReducer.users,
})

const mapDispatchToProps = {
    fetchGlobalParameters: AdministrationAction.fetchGlobalParameters,
}

export default connect(mapStateToProps, mapDispatchToProps)(PiezometersApp)
