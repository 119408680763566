import DtoQualitoDashboardStats from './DtoQualitoDashboardStats'
import DtoQualitoDashboardStationsData from './DtoQualitoDashboardStationsData'
import DtoQuantifications from './DtoQuantifications'

export default class DtoQualitoDashboard {
    constructor(obj) {
        this.stats = new DtoQualitoDashboardStats(obj.stats)
        this.stationsData = obj.stationsData.map(s => new DtoQualitoDashboardStationsData(s))
        this.quantifications = obj.quantifications.map(q => new DtoQuantifications(q))
    }
}