import { push } from '@lagunovsky/redux-react-router'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import AppStore from '../../store/AppStore'
import { sieauTooltip } from '../../utils/FormUtils'

const $ = window.$

class MoreVert extends Component {
    constructor(props) {
        super(props)
    }

    onClick = (href) => {
        AppStore.dispatch(push(`/${href}`))
    }

    getLink = (link) => {
        if (this.props.active) {
            return link.href ? this.onClick(link.href) : link.onClick?.()
        }
        return null
    }


    render() {
        const links = this.props.links.map((link) => {
            const tooltip = link.tooltip ? sieauTooltip(link.tooltip, null, link.tooltipPosition || 'bottom') : null
            return link.component ? link.component : (
                <i
                    key={link.iconName}
                    onClick={() => this.getLink(link)}
                    className={'material-icons clickable'}
                    {...tooltip}
                    style={{ color: link.color }}
                    data-cy={link['data-cy']}
                >
                    {link.iconName}
                </i>
            )
        })
        return (
            <div className='right fixed-action-btn horizontal btn-tab-action click-to-toggle'>
                { links }
            </div>
        )
    }

    componentDidMount() {
        $('.tooltipped').tooltip()
    }
}

MoreVert.propTypes = {
    links: PropTypes.arrayOf(PropTypes.shape({
        href: PropTypes.string,
        onClick: PropTypes.func,
        color: PropTypes.string,
        iconName: PropTypes.string.isRequired,
        tooltip: PropTypes.string,
        tooltipPosition: PropTypes.string,
        'data-cy': PropTypes.string,
    })).isRequired,
    active: PropTypes.bool,
}
MoreVert.defaultProps = {
    active: true,
}
export default MoreVert