import PropTypes from 'prop-types'
import React, { useMemo, useState } from 'react'
import { connect, shallowEqual, useDispatch, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import { hasValue } from 'utils/NumberUtil'
import Card from '../../../components/card/Card'
import Input from '../../../components/forms/Input'
import Row from '../../../components/react/Row'
import DtoSandreCode from '../../../referencial/dto/DtoSandreCode'
import DtoDeclaration from '../../../survey/dto/DtoDeclaration'
import { round } from '../../../utils/NumberUtil'
import { formatMilliers } from '../../../utils/StringUtil'
import { push } from '@lagunovsky/redux-react-router'
import AgriAction from 'agriAdministration/actions/AgriAction'
import ExportFileModal from 'components/modal/ExportFileModal'
import { compact } from 'lodash'
import { SURVEY_TYPES } from 'agriAdministration/constants/AgriConstants'
import ToastrAction from 'toastr/actions/ToastrAction'
import { downloadURI } from 'utils/ExportDataUtil'
import Select from 'components/forms/Select'

const DeclarationStatsCard = ({
    declaration,
    title,
    className,
    sandreCodes = [],
    key,
}) => {
    const [modalOpen, setModalOpen] = useState(false)
    const [modelSynthese, setModelSynthese] = useState()
    const [modelDeclaration, setModelDeclaration] = useState()
    const [modelNotification, setModelNotification] = useState()

    const {
        surveys,
        allModelsByType,
    } = useSelector(store => ({
        surveys: store.AgriReducer.surveys,
        allModelsByType: store.AdministrationReducer.allModelsByType,
    }), shallowEqual)

    const status = sandreCodes.find((c) => c.field === 'DECLARATIONS.STATUT' && c.code === declaration.statusCode) || {}
    const volumes = declaration.link_volumes.length ? declaration.link_volumes : []
    const allocatedVolumeFiltered = volumes.filter((v) => hasValue(v.attributedVolume)).map((v) => v.attributedVolume)
    const allocatedVolume = allocatedVolumeFiltered.length ? Math.round(allocatedVolumeFiltered.reduce((a, b) => a + b)) : null
    const authorizedVolumeFiltered = volumes.filter((v) => hasValue(v.authorizedVolume)).map((v) => v.authorizedVolume)
    const authorizedVolume = authorizedVolumeFiltered.length ? Math.round(authorizedVolumeFiltered.reduce((a, b) => a + b)) : null
    const advancement = (declaration.lastStep * 100) / 4

    const dispatch = useDispatch()

    const goToDeclaration = () => {
        dispatch(AgriAction.changeExploitationAdministrator(declaration.idExploitation)).then(() => {
            dispatch(push(`/survey/${declaration.idSurvey}/declaration/${declaration.idDeclaration}/stepper/0`))
        })
    }

    const createAndDownloadSynthesisEdition = (id) => {
        dispatch(ToastrAction.info(i18n.loadingDocument))
        dispatch(AgriAction.getSynthesisDeclaration(id, modelSynthese)).then((json) => downloadURI(json?.targetPath))
    }

    const createAndDownloadEdition = (id) => {
        dispatch(ToastrAction.info(i18n.loadingDocument))
        dispatch(AgriAction.getEditionDeclaration(id, modelDeclaration)).then((json) => downloadURI(json?.targetPath))
    }

    const getNotification = (idExploitation, year) => {
        dispatch(ToastrAction.info(i18n.loadingDocument))
        dispatch(AgriAction.getNotifEdition(idExploitation, year, modelNotification)).then((json) => downloadURI(json?.targetPath))
    }

    const modelsFormatted = useMemo(() => (
        allModelsByType.map((m) => ({
            ...m,
            nameFormatted: m.name.split('.')[0],
        }))
    ), [allModelsByType])

    const exportNotif = surveys.find((s) => s.idSurvey === declaration.idSurvey).surveyType === SURVEY_TYPES.ANNUAL ? {
        name: i18n.notification,
        formats: [{
            type: 'pdf',
            callback: () => getNotification(declaration.idExploitation, declaration.year),
        }],
        settingsContent: (
            <Select
                col={6}
                label={i18n.model}
                options={modelsFormatted.filter((m) => m.stationType === 'agriNotification')}
                value={modelNotification}
                onChange={(v) => setModelNotification(v)}
                keyLabel='nameFormatted'
                keyValue='name'
                noNullValue
                obligatory
            />
        ),
    } : null

    return (
        <>
            <Card
                key={key}
                title={title || i18n.lastDeclaration}
                className={className}
                noMargin={false}
                actions={[{
                    onClick: () => goToDeclaration(),
                    iconName: 'description',
                    tooltip: i18n.consult,
                }, {
                    onClick: () => setModalOpen(true),
                    iconName: 'file_download',
                    tooltip: i18n.export,
                }]}
                round
            >
                <Row className='padding-top-1'>
                    <Input
                        col={12}
                        title={i18n.survey}
                        value={declaration.surveyName}
                        readMode
                    />
                    <Input
                        col={6}
                        title={i18n.status}
                        value={status.name}
                        readMode
                    />
                    <Input
                        col={6}
                        title={i18n.progression}
                        value={`${declaration.lastStep && (advancement > 100 ? 100 : advancement) || 0}%`}
                        readMode
                    />
                    <Input
                        col={12}
                        title={i18n.nbPtsDeclared}
                        value={declaration.nbPts}
                        readMode
                    />
                    <Input
                        col={12}
                        title={i18n.realConsumption}
                        value={`${formatMilliers(declaration.realVolume) || 0} m3`}
                        readMode
                    />
                    <Input
                        col={12}
                        title={i18n.requestedVolume}
                        value={`${formatMilliers(declaration.prevVolume) || 0} m3`}
                        readMode
                    />
                    <Input
                        col={12}
                        title={i18n.detentionsEligibles}
                        value={hasValue(declaration.eligibleTankVolume) ? `${formatMilliers(round(declaration.eligibleTankVolume, 0))} m3` : i18n.unknown}
                        readMode
                    />
                    <Input
                        col={12}
                        title={i18n.allocatedVolume}
                        value={hasValue(allocatedVolume) ? `${formatMilliers(allocatedVolume)} m3` : i18n.unknown}
                        readMode
                    />
                    <Input
                        col={12}
                        title={i18n.authorizedVolume}
                        value={hasValue(authorizedVolume) ? `${formatMilliers(authorizedVolume)} m3` : i18n.unknown}
                        readMode
                    />
                </Row>
            </Card>
            {modalOpen && (
                <ExportFileModal
                    open={modalOpen}
                    onClose={() => setModalOpen(false)}
                    data={compact([{
                        name: i18n.synthesis,
                        formats: [{
                            type: 'pdf',
                            callback: () => createAndDownloadSynthesisEdition(declaration.idDeclaration),
                        }],
                        settingsContent: (
                            <Select
                                col={6}
                                label={i18n.model}
                                options={modelsFormatted.filter((m) => m.stationType === 'agriSynthese')}
                                value={modelSynthese}
                                onChange={(v) => setModelSynthese(v)}
                                keyLabel='nameFormatted'
                                keyValue='name'
                                noNullValue
                                obligatory
                            />
                        ),
                    }, {
                        name: i18n.exportEmpty,
                        formats: [{
                            type: 'pdf',
                            callback: () => createAndDownloadEdition(declaration.idDeclaration),
                        }],
                        settingsContent: (
                            <Select
                                col={6}
                                label={i18n.model}
                                options={modelsFormatted.filter((m) => m.stationType === 'agriDeclaration')}
                                value={modelDeclaration}
                                onChange={(v) => setModelDeclaration(v)}
                                keyLabel='nameFormatted'
                                keyValue='name'
                                noNullValue
                                obligatory
                            />
                        ),
                    }, exportNotif])}
                />
            )}
        </>
    )
}

DeclarationStatsCard.propTypes = {
    title: PropTypes.string,
    className: PropTypes.string,
    key: PropTypes.number,
    actions: PropTypes.arrayOf(PropTypes.shape({
        onClick: PropTypes.func,
        iconName: PropTypes.string,
    })),
    declaration: PropTypes.instanceOf(DtoDeclaration).isRequired,
    sandreCodes: PropTypes.arrayOf(PropTypes.instanceOf(DtoSandreCode)),
}

const mapStateToProps = (store) => {
    return {
        sandreCodes: store.ReferencialReducer.sandreCodes,
    }
}

export default connect(mapStateToProps)(DeclarationStatsCard)
