import HomeAction from 'home/actions/HomeAction'
import React from 'react'
import AppStore from 'store/AppStore'
import Calculate from './actions/Calculate'
import Cancel from './actions/Cancel'
import Clear from './actions/Clear'
import Delete from './actions/Delete'
import DeleteCheck from './actions/DeleteCheck'
import Duplicate from './actions/Duplicate'
import Edit from './actions/Edit'
import Email from './actions/Email'
import Export from './actions/Export'
import ExportChoice from './actions/ExportChoice'
import ExportList from './actions/ExportList'
import ExportModel from './actions/ExportModel'
import FastArrowNav from './actions/FastArrowNav'
import Import from './actions/Import'
import ImportFile from './actions/ImportFile'
import Links from './actions/Links'
import Loading from './actions/Loading'
import New from './actions/New'
import Notif from './actions/Notif'
import PdfList from './actions/PdfList'
import Purge from './actions/Purge'
import Replace from './actions/Replace'
import Save from './actions/Save'
import Shared from './actions/Shared'
import Update from './actions/Update'
import UpdateReferencial from './actions/UpdateReferencial'
import Validate from './actions/Validate'
import VisitLinks from './actions/VisitLinks'
import LanguagesList from './actions/LanguagesList'
import Dropdown from './actions/Dropdown'

const onClickAction = (func) => {
    $('.tooltipped').tooltip('remove')
    func()
}

const addAction = (key, arrayActions, actions) => {
    switch (key) {
        case 'languages':
            arrayActions[28] = (
                <LanguagesList
                    onClick={(e) => {
                        $('.tooltipped').tooltip('remove')
                        actions[key](e)
                    }}
                />
            )
            break
        case 'email':
            arrayActions[27] = <Email onSend={() => onClickAction(actions[key])} />
            break
        case 'dropdown':
            arrayActions[26] = <Dropdown {...actions[key]} />
            break
        case 'referencialActions':
            arrayActions[25] = (
                <UpdateReferencial
                    actions={actions[key].actions}
                />
            )
            break
        case 'visitLinks':
            arrayActions[24] = <VisitLinks links={actions[key]} />
            break
        case 'pdf':
            arrayActions[23] = <PdfList pdfList={actions[key]} />
            break
        case 'exportList':
            arrayActions[22] = <ExportList exportList={actions[key]} />
            break
        case 'deleteCheck':
            arrayActions[21] = <DeleteCheck onDelete={() => onClickAction(actions[key])} />
            break
        case 'duplicate':
            arrayActions[20] = <Duplicate onDuplicate={() => onClickAction(actions[key])} />
            break
        case 'arrowNav':
            arrayActions[19] = <FastArrowNav nav={actions[key]} />
            break
        case 'edit':
            arrayActions[18] = <Edit onEdit={() => onClickAction(actions[key])} />
            break
        case 'clear':
            arrayActions[17] = <Clear onClear={() => onClickAction(actions[key])} />
            break
        case 'cancel':
            arrayActions[16] = <Cancel onCancel={() => onClickAction(actions[key])} />
            break
        case 'validate':
            arrayActions[15] = <Validate onValidate={() => onClickAction(actions[key])} />
            break
        case 'save':
            arrayActions[14] = <Save onSave={() => onClickAction(actions[key])} />
            break
        case 'delete':
            arrayActions[13] = <Delete onDelete={() => onClickAction(actions[key])} />
            break
        case 'new':
            arrayActions[12] = <New onNew={() => onClickAction(actions[key])} />
            break
        case 'replace':
            arrayActions[11] = <Replace onReplace={() => onClickAction(actions[key])} />
            break
        case 'update':
            arrayActions[10] = <Update onUpdate={() => onClickAction(actions[key])} />
            break
        case 'loading':
            arrayActions[9] = <Loading loads={actions[key]} />
            break
        case 'purge':
            arrayActions[8] = <Purge onPurge={() => onClickAction(actions[key])} />
            break
        case 'links':
            arrayActions[7] = <Links links={actions[key]} />
            break
        case 'exportChoice':
            arrayActions[6] = <ExportChoice data={actions[key]} />
            break
        case 'exportmodel':
            arrayActions[5] = <ExportModel onExport={actions[key]} />
            break
        case 'export':
            arrayActions[4] = <Export onExport={actions[key]} />
            break
        case 'exportAsync':
            arrayActions[4] = <Export onExportAsync={actions[key]} />
            break
        case 'import':
            arrayActions[3] = <Import onClick={actions[key]} />
            break
        case 'importFile':
            arrayActions[2] = <ImportFile {...actions[key]} />
            break
        case 'share':
            arrayActions[1] = <Shared links={actions[key]} />
            break
        case 'calculate':
            arrayActions[0] = <Calculate operationIds={actions[key]}/>
            break
        default:
            arrayActions.unshift(actions[key])
            break
    }
}

const setNotifActions = actions => {
    AppStore.dispatch(HomeAction.setActions(actions.map(o => {
        return <Notif onClick={o.func} icon={o.icon} label={o.label} name={o.name} color={o.color} content={o.content} onClickTitle={o.onClickTitle} />
    })))
}

const setActions = (actions) => {
    const arrayActions = new Array(29)
    const keys = Object.keys(actions)
    keys.filter(k => k !== 'other').forEach((key) => {
        addAction(key, arrayActions, actions)
    })
    if (actions.other) {
        Object.keys(actions.other).forEach((key) => {
            addAction(key, arrayActions, actions.other)
        })
    }
    AppStore.dispatch(HomeAction.setActions(arrayActions))
}

export {
    setNotifActions,
    setActions,
    onClickAction,
    addAction,
}
