import React from 'react'
import { push } from '@lagunovsky/redux-react-router'
import i18n from 'simple-react-i18n'
import { useDispatch } from 'react-redux'
import { Button } from '@mui/material'

const UnauthorizedApp = () => {
    const dispatch = useDispatch()
    return (
        <div className='row '>
            <div className='col s12 '>
                <div className='card '>
                    <div className='card-title activator active'>
                        <i className='material-icons no-display'>warning</i>
                        {i18n.AccessRightDeny}
                    </div>
                </div>
            </div>
            <div className='col margin-top-1'>
                <Button variant='contained' color='primary' onClick={() => dispatch(push('/'))}>{i18n.homepage}</Button>
            </div>
        </div>
    )
}

export default UnauthorizedApp
