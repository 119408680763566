import { hasValue } from 'utils/NumberUtil'
import { formatMilliers } from 'utils/StringUtil'

export default class DtoSurveyBreakdown {
    constructor(obj) {
        this.survey = obj.survey
        this.idUge = obj.idUge
        this.nameUge = obj.nameUge
        this.idSubUge = obj.idSubUge
        this.nameSubUge = obj.nameSubUge
        this.samplesType = obj.samplesType
        this.requestedVolume = hasValue(obj.requestedVolume) ? `${formatMilliers(obj.requestedVolume)} m3` : ''
        this.capacity = hasValue(obj.capacity) ? `${formatMilliers(obj.capacity)} m3` : ''
    }
}