import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import { ButtonGroup, Grid } from '@mui/material'
import ProgressCard from 'components/card/ProgressCard'
import Table from 'components/datatable/Table'
import { groupBy, range, toLower, uniqBy } from 'lodash'
import InstallationAction from 'installation/actions/InstallationAction'
import { getDate, getMonthYear, getWeekYear, getYear } from 'utils/DateUtil'
import Select from 'components/forms/Select'
import { getI18nTitleDataLength, geti18n } from 'utils/StringUtil'
import { nbPerPageLabelShort } from 'referencial/constants/ReferencialConstants'
import { ButtonMUI } from 'components/styled/Buttons'
import useApplicationSetting from 'utils/customHook/useApplicationSetting'
import { parseJsonObj } from 'utils/JsonUtils'
import { AccordionDetailsMUI, AccordionMUI, AccordionSummaryMUI } from 'components/styled/Accordions'
import Icon from 'components/icon/Icon'
import { exportFile } from 'utils/ExportDataUtil'
import HydrometryAction from 'hydrometry/actions/HydrometryAction'
import { STATION_TYPE_CONSTANT } from 'station/constants/StationConstants'
import { hasValue } from 'utils/NumberUtil'

const OCCURENCE_TYPE = {
    DAY: { value: 'DAY', defaultValue: 7, nbValue: 30 },
    WEEK: { value: 'WEEK', defaultValue: 8, nbValue: 52 },
    MONTH: { value: 'MONTH', defaultValue: 12, nbValue: 24 },
    YEAR: { value: 'YEAR', defaultValue: 5, nbValue: 20 },
}

const DEPTH = -1

const LiftingLinkedProduction = () => {
    const dispatch = useDispatch()
    const [dataLoaded, setDataLoaded] = useState(false)
    const [occurence, setOccurence] = useState(OCCURENCE_TYPE.DAY)
    const [nbOccurence, setNbOccurence] = useState(OCCURENCE_TYPE.DAY.defaultValue)

    const buttonsOccurence = [
        { value: 'DAY', title: i18n.day, borderWidth: '2px 0px 2px 2px' },
        { value: 'WEEK', title: i18n.week, borderWidth: 2 },
        { value: 'MONTH', title: i18n.month, borderWidth: '2px 1px' },
        { value: 'YEAR', title: i18n.year, borderWidth: '2px 2px 2px 1px' },
    ]

    const formatDate = useCallback(date => {
        switch (occurence.value) {
            case 'WEEK':
                return getWeekYear(date)
            case 'MONTH':
                return getMonthYear(date)
            case 'YEAR':
                return getYear(date)
            default:
                return getDate(date)
        }
    }, [occurence])

    const {
        linkedHydroMeasures,
        hydrometryDataTypes,
        associatedSites,
    } = useSelector(store => ({
        linkedHydroMeasures: store.InstallationReducer.linkedHydroMeasures,
        hydrometryDataTypes: store.HydrometryReducer.hydrometryDataTypes,
        associatedSites: store.StationReducer.associatedSites,
    }), shallowEqual)

    const showDataTypesRules = useApplicationSetting('showDataTypesRules', setting => setting ? parseJsonObj(setting) : undefined)?.INST?.HYDRO || {}

    useEffect(() => {
        if (!hydrometryDataTypes.length) {
            dispatch(HydrometryAction.fetchHydrometryDataTypes())
        }
    }, [])

    const hydroId = useMemo(() => {
        return associatedSites.find(as => as.stationLinkedType === STATION_TYPE_CONSTANT.HYDROMETRY)?.stationLinkedId
    }, [associatedSites])

    useEffect(() => {
        if (hydroId && occurence && nbOccurence) {
            setDataLoaded(false)
            dispatch(InstallationAction.fetchLinkedHydroMeasures(hydroId, occurence.value, nbOccurence)).then(() => setDataLoaded(true))
        }
    }, [hydroId, occurence, nbOccurence, dispatch])

    const { headers, customHeaders } = useMemo(() => {
        const addedData = uniqBy(linkedHydroMeasures, 'dataType').filter(d => {
            return d.dataType !== DEPTH && showDataTypesRules[d.dataType] !== false
        }).reduce((acc, dt) => {
            const dataType = hydrometryDataTypes.find(pdt => pdt.id === dt.dataType)
            const label = dataType?.label || i18n.notdefined
            if (dataType) {
                return {
                    headers: [
                        ...acc.headers,
                        label,
                    ],
                    customHeaders: {
                        ...acc.customHeaders,
                        [label]: (<span style={{ whiteSpace: 'pre-wrap' }}>{`${dataType.label}${dt.unit && `\n${dt.unit}` || ''}`}</span>),
                    },
                }
            }
            return acc
        }, { headers: [], customHeaders: {} })
        return { headers: ['date', 'levelHigh', 'levelLow', ...addedData.headers], customHeaders: addedData.customHeaders }
    }, [linkedHydroMeasures, hydrometryDataTypes, showDataTypesRules])

    const data = useMemo(() => {
        const grouped = groupBy(linkedHydroMeasures, 'measureDate')
        return Object.keys(grouped).map((date, index) => {
            const line = grouped[date]
            const levelLow = line.find(l => l.group === 'MIN' && l.dataType === DEPTH)
            const levelHigh = line.find(l => l.group === 'MAX' && l.dataType === DEPTH)
            const others = line.filter(l => l.dataType !== DEPTH).reduce((acc, l) => {
                const label = hydrometryDataTypes.find(pdt => pdt.id === l.dataType)?.label || i18n.notdefined
                return { ...acc, [label]: l.value }
            }, {})
            return {
                date: formatDate(line[0].measureDate),
                levelLow: levelLow?.value,
                levelHigh: levelHigh?.value,
                ...others,
                headers: index === 0 ? headers : [],
            }
        })
    }, [linkedHydroMeasures, formatDate, hydrometryDataTypes, headers])

    const selectValues = useMemo(() => {
        const labelKey = toLower(occurence.value)
        return range(occurence.nbValue).map(index => ({ label: `${index + 1} ${getI18nTitleDataLength(geti18n(labelKey), geti18n(`${labelKey}s`), index + 1)}`, id: index + 1 }))
    }, [occurence])

    if (!hasValue(hydroId)) {
        return null
    }

    return (

        <AccordionMUI round style={{ marginTop: 10 }}>
            <AccordionSummaryMUI iconColor='black' style={{ background: 'white', color: 'black' }}>
                <Grid container justifyContent='space-between'>
                    <Grid item >{i18n.production}</Grid>
                    <Grid item ><Icon icon='file_download' color='black' onClick={() => {
                        exportFile({
                            data,
                            exportType: 'xlsx',
                            titleFile: i18n.production,
                        })
                    }}
                    /></Grid>
                </Grid>
            </AccordionSummaryMUI>
            <AccordionDetailsMUI>
                <Grid container className='padding-1'>
                    <Grid container item xs={12}>
                        <Grid item xs={6}>
                            <ButtonGroup>
                                {
                                    buttonsOccurence.map((o) => (
                                        <ButtonMUI
                                            variant={occurence.value === o.value ? 'contained' : 'outlined'}
                                            onClick={() => {
                                                setOccurence(OCCURENCE_TYPE[o.value])
                                                setNbOccurence(OCCURENCE_TYPE[o.value].defaultValue)
                                            }}
                                            style={{ border: 'solid rgba(53, 96, 159, 1)', borderWidth: o.borderWidth, fontWeight: 600 }}
                                        >{o.title}</ButtonMUI>
                                    ))
                                }
                            </ButtonGroup>
                        </Grid>
                        <Grid item xs={6}>
                            <Select
                                value={nbOccurence}
                                label={i18n.occurency}
                                options={selectValues}
                                noSort
                                onChange={setNbOccurence}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        {
                            dataLoaded ?
                                (
                                    <Table
                                        col={12}
                                        data={data}
                                        type={{ headers }}
                                        sortable condensed paging
                                        showTitle={false}
                                        nbPerPageLabel={nbPerPageLabelShort}
                                        noSort
                                        customHeaders={customHeaders}
                                    />
                                ) : <ProgressCard indeterminate className='padding-left-2 padding-right-1 padding-top-1' />
                        }
                    </Grid>
                </Grid>
            </AccordionDetailsMUI>
        </AccordionMUI >
    )
}

LiftingLinkedProduction.propTypes = {
}

export default LiftingLinkedProduction