const getData = event => {
    try {
        return { ...JSON.parse(event.data) }
    } catch (e) {
        return {}
    }
}

export {
    getData,
}

