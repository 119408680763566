import Feature from 'ol/Feature'
import Point from 'ol/geom/Point'
import VectorLayer from 'ol/layer/Vector'
import * as olProj from 'ol/proj'
import VectorSource from 'ol/source/Vector'
import React from 'react'
import MapPopupStation from '../../../components/map/popup/MapPopupStation'
import { INSTALLATION_TYPE } from '../../../installation/constants/InstallationConstants'
import { STATION_TYPE } from '../../../station/constants/StationConstants'
import { hasValue } from '../../NumberUtil'
import { EPSG3857, EPSG4326, getWGS84Coordinate } from '../CoordinateUtils'
import Layers from '../ILayers'
import { createIconStyle, getMapPointsScale, getSiteType } from '../SiteTypes'
import { isNil } from 'lodash'

class _SitesPoint extends Layers {
    constructor(obj) {
        super()
        this.typeParams = obj.typeParams
        this.objPoints = obj
        this.popup = obj.popup
        this.visible = obj.visible
        this.layer = this.createLayer()
    }

    getStationTypeKey = (point) => {
        switch (point.typeName) {
            case 'quality':
                const qualityType = Object.keys(STATION_TYPE).find(t => STATION_TYPE[t] == point.stationType)
                return qualityType ? `QUALITOMETER_${qualityType}` : null
            case 'installation':
                const installationType = Object.keys(INSTALLATION_TYPE).find(t => INSTALLATION_TYPE[t] == point.installationType)
                return installationType ? `INSTALLATION_${installationType}` : null
            default:
                return null
        }
    }

    createLayer() {
        const features = this.objPoints.site.map(point => {
            const typeImg = hasValue(point.stationType) || hasValue(point.installationType) ? this.getStationTypeKey(point) : null
            const img = point.markerIcon ?
                createIconStyle(point.markerIcon, point.scale ?
                    point.scale :
                    getMapPointsScale(this.objPoints.typeParams[1]), point.markerText) :
                getSiteType(typeImg ? typeImg : this.typeParams[0], this.typeParams[1], point.markerText, point.scale).img
            const xValue = isNil(point.x) ? point.xCoordinate : point.x
            const yValue = isNil(point.y) ? point.yCoordinate : point.y
            return this.createFeature(
                xValue,
                yValue,
                point.projection,
                img,
                point.name,
                point
            )
        })

        const vectorSource = new VectorSource({
            features,
        })

        return new VectorLayer({
            source: vectorSource,
            visible: this.visible,
        })
    }

    getLayer() {
        return this.layer
    }

    createFeature(x, y, projection, style, name, site) {
        const center = getWGS84Coordinate({
            x,
            y,
            projection,
        })
        const f = new Feature({
            geometry: new Point(olProj.transform(center, EPSG4326, EPSG3857)),
            name,
            hover: true,
        })
        f.setId(this.registerFeature(site))
        f.setStyle(style)
        return f
    }

    getPopup(setStation, layer, feature, click, currentStation) {
        return <MapPopupStation setStation={setStation} station={ layer.getUuid().find((el) => el.uuid === feature.id).obj } click={ click } currentStation={ currentStation }/>
    }

    getStation(layer, feature) {
        return layer.getUuid().find((el) => el.uuid === feature.id).obj
    }
}

export default function SitesPoint(...args) {
    return new _SitesPoint(...args)
}
