export default class ResourceDto {
    constructor(obj) {
        this.id = obj.id // Long,
        this.code = obj.code // Option[String],
        this.updateDate = obj.dateMaj // Option[DateTime],
        this.updateLogin = obj.loginMaj // Option[String],
        this.name = obj.name // Option[String],
        this.nature = obj.nature // Option[String],
        this.resourceType = obj.resourceType // Option[String],
        this.descriptif = obj.descriptif // Option[String],
        this.exploitationComment = obj.exploitationComment // Option[String],
        this.layerUrl = obj.layerUrl // Option[String],
        this.layerCode = obj.layerCode // Option[String],
        this.layerId = obj.layerId // Option[Long]
        this.typeName = 'resource'
    }
}