/* eslint-disable max-nested-callbacks */
/* eslint-disable consistent-return */
import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import CatchmentDto from 'catchment/dto/CatchmentDto'
import { nbPerPageLabel } from 'referencial/constants/ReferencialConstants'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { getLabel } from 'utils/StoreUtils'
import { calculateAverage, searchMaxAnalysis, searchMinAnalysis, searchP90Analysis } from 'utils/AnalyseUtils'
import useApplicationSetting, { listStringParser } from 'utils/customHook/useApplicationSetting'
import { hasValue } from 'utils/NumberUtil'
import { NITRATES_CODE } from 'catchment/constants/CatchmentConstant'
import i18n from 'simple-react-i18n'
import { GREEN, RED } from 'components/constants/ColorConstant'
import { groupBy, isUndefined, keyBy, max, maxBy, mean, round, sumBy, uniqBy } from 'lodash'
import CatchmentPointDto from 'catchment/dto/CatchmentPointDto'
import { substringText } from 'utils/StringUtil'
import { push } from '@lagunovsky/redux-react-router'
import DtoAnalysisUltraLight from 'quality/dto/analyse/DtoAnalysisUltraLight'
import { NewTable } from 'components/datatable/NewTable'
import TooltipAnalyse from 'quality/components/qualityComponents/TooltipAnalyse'

const getNitratesThresholdColor = (value, threshold) => {
    if (!hasValue(threshold) || !hasValue(value)) {
        return
    }
    return value >= threshold ? '#F44336' : '#4CAF50'
}

const getSpecificThresholdColor = (listValue, th1, th2, thresholdsIndexed) => {
    if (!listValue.length) return

    const listColor = listValue.map(({ parameter, result }) => {
        const threshold1 = thresholdsIndexed[parameter]?.threshold1 ?? th1
        const threshold2 = thresholdsIndexed[parameter]?.threshold2 ?? th2
        if (!hasValue(threshold1) && !hasValue(threshold2) || !hasValue(result)) {
            return
        }
        if (hasValue(threshold2) && result >= threshold2) {
            return 'red'
        }
        if (hasValue(threshold1) && result >= threshold1) {
            return 'orange'
        }
        return 'green'
    })

    if (listColor.includes('red')) return '#F44336'
    if (listColor.includes('orange')) return '#ff9800'
    return '#4CAF50'
}

const getPesticidesThresholdColor = (value, threshold1, threshold2) => {
    if (!hasValue(threshold1) && !hasValue(threshold2) || !hasValue(value)) {
        return
    }
    if (hasValue(threshold2) && value >= threshold2) {
        return '#F44336'
    }
    if (hasValue(threshold1) && value >= threshold1) {
        return '#ff9800'
    }
    return '#4CAF50'
}

const TooltipNitrates = ({
    analyse = {},
    threshold1,
}) => {
    return (
        <TooltipAnalyse
            title={`${i18n.nitrates} [${NITRATES_CODE}]`}
            analyse={analyse}
            threshold={{
                parameterCode: analyse.parameter,
                threshold1,
            }}
            overrideColor={[GREEN, RED]}
        />
    )
}

TooltipNitrates.propTypes = {
    analyse: PropTypes.instanceOf(DtoAnalysisUltraLight),
    threshold1: PropTypes.number,
}

const ContaminationTableSummary = ({
    catchments = [],
    catchmentPoints = [],
    analysis = [],
}) => {
    const dispatch = useDispatch()

    const {
        qualitometers,
        parameters,
        cities,
        qualityThresholds,
    } = useSelector(store => ({
        qualitometers: store.QualityReducer.qualitometersLight,
        parameters: store.ParameterReducer.parameters,
        cities: store.CityReducer.cities,
        qualityThresholds: store.QualityReducer.qualityThresholds,
    }), shallowEqual)

    const thresholdNitrates = useApplicationSetting('CATCHMENT_NITRATES_THRESHOLD', setting => setting ? parseFloat(setting) : 50)
    const thresholdPesticides1 = useApplicationSetting('CATCHMENT_PESTICIDE_THRESHOLD_1', setting => setting ? parseFloat(setting) : 0.1)
    const thresholdPesticides2 = useApplicationSetting('CATCHMENT_PESTICIDE_THRESHOLD_2', setting => setting ? parseFloat(setting) : 0.5)
    const specificThreshold = useApplicationSetting('CATCHMENT_SPECIFIC_THRESHOLD')

    const threshold = qualityThresholds.find(t => t.thresholdCode === specificThreshold)
    const thresholdsIndexed = keyBy(threshold?.thresholds ?? [], 'parameterCode')

    const listSumPesticides = useApplicationSetting('CATCHMENT_LIST_SUM_PESTICIDES', listStringParser)

    const nitratesAnalysisGroup = useMemo(() => {
        const nitratesAnalysis = analysis.filter(({ parameter }) => parameter === NITRATES_CODE)
        return groupBy(nitratesAnalysis, 'qualitometer')
    }, [analysis])

    const pesticidesAnalysisGroup = useMemo(() => {
        const pesticidesAnalysis = analysis.filter(({ parameter }) => parameter !== NITRATES_CODE)
        return groupBy(pesticidesAnalysis, 'qualitometer')
    }, [analysis])

    const data = useMemo(() => {
        return catchmentPoints.map(point => {
            const catchment = catchments.find(c => c.id === point.id)
            const qualito = qualitometers.find(q => q.code === point.codeWithoutDesignation)

            const nitratesAnalysis = nitratesAnalysisGroup[qualito?.id] || []
            const minNitrates = searchMinAnalysis(nitratesAnalysis)
            const averageNitrates = calculateAverage(nitratesAnalysis)
            const maxNitrates = searchMaxAnalysis(nitratesAnalysis)
            const p90Nitrates = searchP90Analysis(nitratesAnalysis)

            const pesticidesAnalysis = pesticidesAnalysisGroup[qualito?.id] || []
            const quatifiedPesticidesAnalysis = pesticidesAnalysis.filter(a => a.remark === '1')
            const listSumPesticideAnalysis = listSumPesticides.length ? quatifiedPesticidesAnalysis.filter(a => listSumPesticides.includes(a.parameter)) : quatifiedPesticidesAnalysis

            const paramGroup = groupBy(quatifiedPesticidesAnalysis, a => a.parameter)

            const allAveragePesticides = Object.keys(paramGroup).map(key => ({ parameter: key, result: sumBy(paramGroup[key], 'result') / uniqBy(paramGroup[key], a => `${a.qualitometer}#${a.operation}`).length }))
            const averagePesticide = maxBy(allAveragePesticides, 'result')?.result

            const allMaxPesticides = Object.keys(paramGroup).map(key => ({ parameter: key, result: maxBy(paramGroup[key], 'result')?.result }))
            const maxPesticide = maxBy(quatifiedPesticidesAnalysis, 'result')?.result


            const groupOperation = groupBy(listSumPesticideAnalysis, 'operation')
            const allSumPesticide = Object.keys(groupOperation).map(op => sumBy(groupOperation[op], 'result'))
            const maxSumPesticide = max(allSumPesticide)
            const averageSumPesticides = allSumPesticide.length > 0 ? mean(allSumPesticide) : undefined

            const { paramAverage, paramMax } = Object.keys(paramGroup).reduce((acc, param) => {
                const th = thresholdsIndexed[param]?.threshold1 ?? thresholdPesticides1
                const maxParam = maxBy(paramGroup[param], 'result')?.result
                // const averageParam = meanBy(paramGroup[param], 'result')
                const averageParam = sumBy(paramGroup[param], 'result') / uniqBy(paramGroup[param], a => `${a.qualitometer}#${a.operation}`).length
                const label = getLabel(parameters, param, 'name')
                if (maxParam > th) {
                    acc.paramMax.push(`${label} (${round(maxParam, 4)})`)
                }
                if (averageParam > th) {
                    acc.paramAverage.push(`${label} (${round(averageParam, 4)})`)
                }
                return acc
            }, { paramAverage: [], paramMax: [] })

            return {
                id: catchment.id,
                code: { value: catchment.code },
                name: { value: catchment.name },
                stationCode: { value: point.code },
                mainPointLabel: { value: point.mainPoint ? i18n.yes : i18n.no },
                city: { value: qualito?.townCode && getLabel(cities, qualito.townCode, 'labelWithCode') },
                nbAnalysisNitrates: { value: nitratesAnalysis.length, align: 'right' },
                min: {
                    value: minNitrates?.value,
                    align: 'right',
                    color: getNitratesThresholdColor(minNitrates?.result, thresholdNitrates),
                    tooltip: isUndefined(minNitrates) ? undefined : (<TooltipNitrates analyse={minNitrates} threshold1={thresholdNitrates} />),
                },
                averageShort: {
                    value: averageNitrates,
                    align: 'right',
                    color: getNitratesThresholdColor(averageNitrates, thresholdNitrates),
                    tooltip: isUndefined(averageNitrates) ? undefined : (<TooltipNitrates analyse={{ value: `${averageNitrates}` }} threshold1={thresholdNitrates} />),
                },
                max: {
                    value: maxNitrates?.value,
                    align: 'right',
                    color: getNitratesThresholdColor(maxNitrates?.result, thresholdNitrates),
                    tooltip: isUndefined(maxNitrates) ? undefined : (<TooltipNitrates analyse={maxNitrates} threshold1={thresholdNitrates} />),
                },
                percentile90: {
                    value: p90Nitrates?.value,
                    align: 'right',
                    color: getNitratesThresholdColor(p90Nitrates?.result, thresholdNitrates),
                    tooltip: isUndefined(p90Nitrates) ? undefined : (<TooltipNitrates analyse={p90Nitrates} threshold1={thresholdNitrates} />),
                },
                nbAnalysisPesticides: { value: pesticidesAnalysis.length, align: 'right' },
                nbQuantifications: { value: quatifiedPesticidesAnalysis.length, align: 'right' },
                pesticidesAverageMax: {
                    value: isUndefined(averagePesticide) ? undefined : round(averagePesticide, 4),
                    align: 'right',
                    color: getSpecificThresholdColor(allAveragePesticides, thresholdPesticides1, thresholdPesticides2, thresholdsIndexed),
                },
                paramConcernedByAverageAboveThreshold: {
                    value: substringText(paramAverage.join(', ')),
                    tooltip: paramAverage.length ? (<>{paramAverage.map(p => (<>{p}<br/></>))}</>) : undefined,
                },
                maxMaxSubstance: {
                    value: isUndefined(maxPesticide) ? undefined : round(maxPesticide, 4),
                    align: 'right',
                    color: getSpecificThresholdColor(allMaxPesticides, thresholdPesticides1, thresholdPesticides2, thresholdsIndexed),
                },
                paramConcernedByMaxAboveThreshold: {
                    value: substringText(paramMax.join(', ')),
                    tooltip: paramMax.length ? (<>{paramMax.map(p => (<>{p}<br/></>))}</>) : undefined,
                },
                maxSumPesticides: {
                    value: isUndefined(maxSumPesticide) ? undefined : round(maxSumPesticide, 4),
                    align: 'right',
                    color: getPesticidesThresholdColor(maxSumPesticide, thresholdPesticides1, thresholdPesticides2),
                },
                averageSumPesticides: {
                    value: isUndefined(averageSumPesticides) ? undefined : round(averageSumPesticides, 4),
                    align: 'right',
                    color: getPesticidesThresholdColor(averageSumPesticides, thresholdPesticides1, thresholdPesticides2),
                },
            }
        })
    }, [catchmentPoints, catchments, cities, nitratesAnalysisGroup, parameters, pesticidesAnalysisGroup, qualitometers, thresholdNitrates, thresholdPesticides1, thresholdPesticides2, listSumPesticides])

    return (
        <>
            <NewTable
                rows={data}
                headers={['code', 'name', 'stationCode', 'mainPointLabel', 'city', 'nbAnalysisNitrates', 'min', 'averageShort', 'max', 'percentile90', 'nbAnalysisPesticides', 'nbQuantifications', 'pesticidesAverageMax', 'paramConcernedByAverageAboveThreshold', 'maxMaxSubstance', 'paramConcernedByMaxAboveThreshold', 'maxSumPesticides', 'averageSumPesticides']}
                headersLabel={{
                    mainPointLabel: (<span style={{ whiteSpace: 'pre-wrap' }}>{i18n.mainPointLabel}</span>),
                    nbAnalysisNitrates: (<span style={{ whiteSpace: 'pre-wrap' }}>{i18n.nbAnalysisNitrates}</span>),
                    nbAnalysisPesticides: (<span style={{ whiteSpace: 'pre-wrap' }}>{i18n.nbAnalysisPesticides}</span>),
                    nbQuantifications: (<span style={{ whiteSpace: 'pre-wrap' }}>{i18n.nbQuantifications}</span>),
                    pesticidesAverageMax: (<span style={{ whiteSpace: 'pre-wrap' }}>{i18n.pesticidesAverageMax}</span>),
                    paramConcernedByAverageAboveThreshold: (<span style={{ whiteSpace: 'pre-wrap' }}>{i18n.paramConcernedByAnnualAverageAboveThreshold}</span>),
                    maxMaxSubstance: (<span style={{ whiteSpace: 'pre-wrap' }}>{i18n.maxMaxSubstance}</span>),
                    paramConcernedByMaxAboveThreshold: (<span style={{ whiteSpace: 'pre-wrap' }}>{i18n.paramConcernedByMaxAboveThreshold}</span>),
                    maxSumPesticides: (<span style={{ whiteSpace: 'pre-wrap' }}>{i18n.maxSumPesticides}</span>),
                    averageSumPesticides: (<span style={{ whiteSpace: 'pre-wrap' }}>{i18n.averageSumPesticides}</span>),
                }}

                onClickRow={s => dispatch(push(`/station/catchment/${s.id}`))}
                rowsPerPageOptions={nbPerPageLabel}
            />
        </>
    )
}

ContaminationTableSummary.propTypes = {
    catchments: PropTypes.arrayOf(PropTypes.instanceOf(CatchmentDto)),
    catchmentPoints: PropTypes.arrayOf(PropTypes.instanceOf(CatchmentPointDto)),
    analysis: PropTypes.arrayOf(PropTypes.instanceOf(DtoAnalysisUltraLight)),
    useP90: PropTypes.bool,
}

export default ContaminationTableSummary