import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import Textarea from '../../../../components/forms/Textarea'
import Select from '../../../../components/forms/Select'
import GenericReferencialDto from '../../../dto/GenericReferencialDto'
import VariousMaterielDto from '../dto/VariousMaterielDto'
import { getDefaultValuesChoiceModal, getMatTypes } from '../../../../utils/MaterielUtils'
import NumberField from '../../../../components/forms/NumberField'

const VariousMaterielEquipmentPanel = ({
    active = false,
    disabled = true,
    variousMateriel = {},
    variousMaterielTypes = [],
    onChange = () => { },
}) => (
    <div className='col s12 margin-bottom-1'>
        <div className='row no-margin' style={{ paddingTop: '3px' }}>
            <Select
                options={getMatTypes(variousMaterielTypes, variousMateriel.materielType)}
                label={i18n.type}
                col={4}
                onChange={value => {
                    onChange({ materielType: value })
                    getDefaultValuesChoiceModal(variousMaterielTypes.find(c => c.materielType == value), onChange)
                }}
                value={variousMateriel.materielType}
                nullLabel='&nbsp;'
                active={active}
                disabled={disabled}
            />
            <NumberField
                col={4}
                title={i18n.supplyTime}
                value={variousMateriel.supplyTime}
                onChange={value => onChange({ supplyTime: value })}
                active={active}
                disabled={disabled}
            />
        </div>
        <div className='row no-margin' style={{ paddingTop: '8px' }}>
            <Textarea
                col={12}
                title={i18n.comment}
                value={variousMateriel.comment}
                onChange={value => onChange({ comment: value })}
                active={active}
                disabled={disabled}
            />
        </div>
    </div>
)

VariousMaterielEquipmentPanel.propTypes = {
    disabled: PropTypes.bool,
    active: PropTypes.bool,
    onChange: PropTypes.func,
    variousMateriel: PropTypes.instanceOf(VariousMaterielDto),
    variousMaterielTypes: PropTypes.instanceOf(GenericReferencialDto),
}

const mapStateToProps = store => ({
    variousMaterielTypes: store.VariousMaterielReducer.variousMaterielTypes,
})

export default connect(mapStateToProps)(VariousMaterielEquipmentPanel)
