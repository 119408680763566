import ReferencialActionModal from 'components/modal/ReferencialActionModal'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { sieauTooltip } from '../../utils/FormUtils'
import i18n from 'simple-react-i18n'
import { useDispatch } from 'react-redux'
import ToastrAction from 'toastr/actions/ToastrAction'

const UpdateReferencial = ({
    actions = {},
}) => {
    const [open, setOpen] = useState(false)

    const dispatch = useDispatch()

    const id = 'tune_action_navbar'
    const icon = 'tune'

    const tooltip = sieauTooltip(i18n.actions, id, 'bottom')

    return (
        <>
            <ReferencialActionModal
                open={open}
                setOpen={setOpen}
                actions={actions}
            />
            <div className='action-wrapper'>
                <a
                    className={'right waves-effect nav-actions blue-arrow dropdown-button'}
                    { ...tooltip }
                    onClick={ () => (actions && Object.keys(actions).length) ? setOpen(true) : dispatch(ToastrAction.error(i18n.noData)) }
                    id={id}
                    data-cy={id}
                >
                    <i className='material-icons left no-margin' id={icon} >{icon}</i>
                </a>
            </div>
        </>
    )
}

UpdateReferencial.propTypes = {
    actions: PropTypes.shape({
        export: PropTypes.shape({
            data: PropTypes.arrayOf(PropTypes.object),
            titleFile: PropTypes.string,
            exportContent: PropTypes.element,
        }),
        sync: PropTypes.shape({
            updateAction: PropTypes.func,
            onImportXml: PropTypes.func,
            onImportCsv: PropTypes.func,
            xmlContent: PropTypes.element,
            csvContent: PropTypes.element,
            fileContentIsValid: PropTypes.bool,
        }),
        purge: PropTypes.shape({
            onPurge: PropTypes.func,
            onMakeInactive: PropTypes.func,
        }),
    }),
}

export default UpdateReferencial