import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import ContributorDto from '../../../../referencial/components/contributor/dto/ContributorDto'
import TelecomTypeDto from '../dto/TelecomTypeDto'
import Select from '../../../../components/forms/Select'
import NumberField from '../../../../components/forms/NumberField'
import Checkbox from '../../../../components/forms/Checkbox'
import PowerSupplyTypeDto from '../../powerSupply/dto/PowerSupplyTypeDto'

const TelecomTypePanel = ({
    materielType = {},
    onChange = () => { },
    showContributorField = true,
    disabled = false,
    contributors = [],
    powerSupplyTypes = [],
    // telecomTypes = [],
}) => (
    <div>
        {
            showContributorField && (
                <div className='row no-margin'>
                    <Select
                        col={6}
                        options={contributors}
                        label={i18n.provider}
                        value={materielType.provider}
                        onChange={v => onChange({ provider: v })}
                        keyValue='id'
                        keyLabel='mnemonique'
                        disabled={disabled}
                    />
                    <Select
                        col={6}
                        options={contributors}
                        label={i18n.manufacturer}
                        value={materielType.manufacturerId}
                        onChange={v => onChange({ manufacturerId: v })}
                        keyValue='id'
                        keyLabel='mnemonique'
                        disabled={disabled}
                    />
                </div>
            )
        }
        <div className='row no-margin'>
            <Select
                col={4}
                label={i18n.powerSupplyType}
                options={powerSupplyTypes}
                value={materielType.powerSupplyType}
                onChange={v => onChange({ powerSupplyType: v })}
                disabled={disabled}
            />
            <NumberField
                col={4}
                title={i18n.batteryCount}
                value={materielType.pileNumber}
                onChange={v => onChange({ pileNumber: v })}
                disabled={disabled}
            />
            <NumberField
                col={4}
                title={i18n.supplyTime}
                value={materielType.supplyTime}
                onChange={value => onChange({ supplyTime: value })}
                disabled={disabled}
            />
        </div>
        <div className='row no-margin valign-wrapper'>
            <NumberField
                col={4}
                title={i18n.transmissionVolume}
                value={materielType.transmissionVolume}
                onChange={v => onChange({ transmissionVolume: v })}
                disabled={disabled}
            />
            {/* <Select
                col={4}
                label={i18n.telecomType}
                options={telecomTypes}
                value={materielType.telecomMode}
                onChange={v => onChange({ telecomMode: v })}
                disabled={disabled}
            /> */}
            <Checkbox
                col={4}
                label={i18n.desiccant}
                checked={materielType.desiccant === '1'}
                onChange={v => onChange({ desiccant: v ? '1' : '0' })}
                disabled={disabled}
            />
            <Checkbox
                col={4}
                label={i18n.digitalLineOrRTC}
                checked={materielType.digitalLine}
                onChange={value => onChange({ digitalLine: value })}
                disabled={disabled}
            />
        </div>
    </div>
)

TelecomTypePanel.propTypes = {
    materielType: PropTypes.instanceOf(TelecomTypeDto),
    onChange: PropTypes.func,
    showContributorField: PropTypes.bool,
    disabled: PropTypes.bool,
    contributors: PropTypes.arrayOf(PropTypes.instanceOf(ContributorDto)),
    powerSupplyTypes: PropTypes.arrayOf(PropTypes.instanceOf(PowerSupplyTypeDto)),
    // telecomTypes: PropTypes.arrayOf(PropTypes.instanceOf(TelecomTypeDto)),
}

const mapStateToProps = store => ({
    contributors: store.ContributorReducer.contributors,
    powerSupplyTypes: store.PowerSupplyReducer.powerSupplyTypes,
    // telecomTypes: store.TelecomReducer.telecomTypes,
})

export default connect(mapStateToProps)(TelecomTypePanel)