import User from 'account/dto/User'
import { template } from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import ToastrAction from 'toastr/actions/ToastrAction'
import { authorizeFilter } from 'utils/HabilitationUtil'
import { hasValue } from 'utils/NumberUtil'
import Input from '../../../../components/forms/Input'
import Select from '../../../../components/forms/Select'
import Row from '../../../../components/react/Row'
import DtoNetworkLink from '../../../../piezometry/dto/DtoNetworkLink'
import NetworkDto from '../../../../referencial/components/network/dto/NetworkDto'
import { getHour } from '../../../../utils/DateUtil'
import { onChangeHour } from '../../../../utils/FormUtils'
import { arrayOf } from '../../../../utils/StoreUtils'
import SimpleDatePicker from 'components/forms/SimpleDatePicker'

class NetworkModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            network: { ...props.network, idStation: props.id },
        }
    }

    componentDidUpdate(prevProps) {
        if (!prevProps.popupIsOpen && this.props.popupIsOpen) {
            $('#descriptionModal').on('click', this.onSave)
        }
    }

    onSave = () => {
        const { network } = this.state
        if (network.startDate && hasValue(network.idNetwork)) {
            this.props.saveResult(new DtoNetworkLink(this.state.network))
            $('.modal').modal('close')
        } else {
            if (!network.startDate) {
                this.props.warning(template(i18n.fieldNotSet)({ field: i18n.startDate }))
            }
            if (!network.idNetwork) {
                this.props.warning(template(i18n.fieldNotSet)({ field: i18n.network }))
            }
        }
    }

    onChangeElement = (changes) => this.setState({ network: { ...this.state.network, ...changes } })

    render() {
        const { network } = this.state
        const { networks, accountUser } = this.props
        return (
            <div>
                <Row>
                    <SimpleDatePicker
                        col={6}
                        value={network.startDate}
                        label={i18n.startDate}
                        onChange={v => this.onChangeElement({ startDate: v })}
                        max={network.endDate}
                    />
                    <Input col={ 6 } title={ i18n.startHour } value={ getHour(network.startDate) }
                        onChange={ v => onChangeHour(v, v2 => this.onChangeElement({ startDate: v2 }), { max: network.endDate }, network.startDate) }
                    />
                </Row>
                <Row>
                    <SimpleDatePicker
                        col={6}
                        value={network.endDate}
                        label={i18n.endDate}
                        onChange={v => this.onChangeElement({ endDate: v })}
                        min={network.startDate}
                    />
                    <Input col={ 6 } title={ i18n.endHour } value={ getHour(network.endDate) }
                        onChange={ v => onChangeHour(v, v2 => this.onChangeElement({ endDate: v2 }), { min: network.startDate }, network.endDate) }
                    />
                </Row>
                <div className='row no-margin padding-top-1'>
                    <Select
                        col={ 12 }
                        value={ network.idNetwork }
                        label={ i18n.network }
                        options={ authorizeFilter(networks, accountUser, network.idNetwork) }
                        keyValue='id'
                        onChange={v => this.onChangeElement({ idNetwork: v })}
                        obligatory
                    />
                </div>
            </div>
        )
    }
}

NetworkModal.propTypes = {
    network: PropTypes.instanceOf(DtoNetworkLink),
    id: PropTypes.number,
    popupIsOpen: PropTypes.bool,
    saveResult: PropTypes.func,
    networks: arrayOf(NetworkDto),
    accountUser: PropTypes.instanceOf(User),
    warning: PropTypes.func,
}


const mapStateToProps = store => ({
    popupIsOpen: store.SieauReducer.popupIsOpen,
    networks: store.NetworkReducer.networks,
    accountUser: store.AccountReducer.accountUser,
})

const mapDispatchToProps = {
    warning: ToastrAction.warning,
}

NetworkModal.defaultProps = {
    network: {},
}

export default connect(mapStateToProps, mapDispatchToProps)(NetworkModal)
