import React, { useCallback, useMemo, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import { Autocomplete, TextField, Icon, ListSubheader, Chip, createFilterOptions } from '@mui/material'
import { differenceBy, flatten, intersection, orderBy, uniq } from 'lodash'
import { hasValue } from 'utils/NumberUtil'
import { sieauTooltip } from 'utils/FormUtils'
import { selectedColor } from 'utils/constants/ColorTheme'

const seeMoreId = 'seeMoreId'
const emptyGroupId = 'emptyGroupId'

const SuperMultiAutocomplete = ({
    id = 'superMultiAutocomplete',
    col,
    options = [],
    optionsGroups = [],
    values = [],
    keyLabel = 'name',
    keyValue = 'id',
    label = '',
    placeholder = '',
    disabled = false,
    fullWidth = true,
    onChange = () => {},
    className = '',
    clearIcon = true,
    multiple = false,
    groupIcon,
    additionalKeysFilter = [],
    limit = 1,
    groupsCanBeHidden = true,
    groupsCanBeSelected = false,
    groupsAreValues = false,
    noSort = false,
    displayWithCode = false,
    multiGroups = false,
    tooltip,
    obligatory,
    disablePortal = true,
    inputWidth,
    'data-cy': datacy,
}) => {
    const [maxOptions, setMaxOptions] = useState(99)
    const [hiddenKeys, setHiddenKeys] = useState([])
    const [selectedGroups, setSelectedGroups] = useState([])
    const input = useRef()

    const emptyGroup = useMemo(() => ({ id: emptyGroupId, code: emptyGroupId, name: i18n.withoutGroup.toUpperCase() }), [])

    const filterOptions = createFilterOptions({
        stringify: (option) => option && option[keyLabel] + (additionalKeysFilter.map(akf => option[akf]) || '') || '',
        limit: maxOptions,
        trim: true,
    })

    const takeParents = (childrens, children, index, firstName) => {
        if (index === 0 || children?.dephValue === 1) {
            return [firstName]
        }
        return [ ...childrens.slice(index - (children?.dephValue - 1), index).reverse().map(c => c?.name), firstName ]
    }

    const formatMultiGroups = useCallback((parentsFiltered, dephValue, orderedChildrens) => {
        return flatten(parentsFiltered.map(({ code, name }) => {
            const parents = orderedChildrens.filter(({ parentCode }) => parentCode === code)
            const childrens = formatMultiGroups(parents, dephValue + 1, differenceBy(orderedChildrens, parents, 'code'))
            return [
                {
                    key: name,
                    code,
                    name,
                    isGroup: childrens?.length > 0 || dephValue === 0,
                    dephValue,
                    nbChildrens: childrens?.length,
                },
                ...childrens.map((c, i) => ({
                    ...c,
                    parents: takeParents(childrens, c, i, name),
                })),
            ]
        }))
    }, [])

    const getId = useCallback((element) => {
        if (element) {
            if (hasValue(element[keyValue])) {
                return element[keyValue]
            } else if (hasValue(element.code)) {
                return element.code
            } else if (hasValue(element.id)) {
                return element.id
            } else if (hasValue(element.value)) {
                return element.value
            }
        }
        return null
    }, [keyValue])

    const getName = useCallback((element) => {
        const code = displayWithCode ? getId(element) : null
        const codeShown = hasValue(code) && code !== '' ? ` [${code}]` : ''
        return (element[keyLabel] || element.mnemonique || element.name || element.label || '') + codeShown
    }, [displayWithCode, getId, keyLabel])

    const optionsGroupsIndex = useMemo(() => optionsGroups.map(og => ({ ...og, name: getName(og), elements: (og.elements || []).map(c => c?.[keyValue]) })), [getName, keyValue, optionsGroups])

    const optionsOrdered = useMemo(() => {
        const ordered = !noSort ? orderBy(options, [ o => optionsGroupsIndex.find(og => og.elements.includes(o?.[keyValue]))?.[keyLabel], keyLabel ], 'asc') : options
        return uniq(ordered.map(o => ({
            ...o,
            name: getName(o),
            groupTitle: optionsGroupsIndex.find(og => og.elements.includes(o?.[keyValue]))?.name,
        })))
    }, [getName, keyLabel, keyValue, noSort, options, optionsGroupsIndex])

    const optionsFiltered = useMemo(() => optionsOrdered.filter(o => o), [optionsOrdered])

    const optionsFormatted = useMemo(() => {
        if (multiGroups) {
            const parents = [ ...options.filter(({ parentCode }) => !hasValue(parentCode)), emptyGroup ]
            const childrens = differenceBy(options, parents, 'code')
            return formatMultiGroups(parents, 0, childrens)
        }
        return optionsFiltered
    }, [emptyGroup, formatMultiGroups, multiGroups, options, optionsFiltered])

    const dataOptions = useMemo(() => {
        if (!hasValue(values) || (typeof values !== 'number' && typeof values !== 'string' && !Array.isArray(values))) {
            return []
        } else if (typeof values === 'number' || typeof values === 'string') {
            return optionsFiltered.find(o => values === o?.[keyValue])
        }
        return optionsFiltered.filter(o => values.includes(o?.[keyValue]))
    }, [keyValue, optionsFiltered, values])

    const groupBy = (option) => optionsGroupsIndex.find(og => og.elements.includes(option?.[keyValue]))?.name?.toUpperCase() || i18n.withoutGroup.toUpperCase()

    const handleHidden = (params) => hiddenKeys.includes(params.key) ? setHiddenKeys([ ...hiddenKeys.filter(h => h !== params.key) ]) : setHiddenKeys([ ...hiddenKeys, params.key ])

    const handleChange = (value, removeGroups) => {
        if (removeGroups) {
            setSelectedGroups([])
        }
        if (!value?.group) {
            onChange(Array.isArray(value) ? value?.map(v => v?.[keyValue]) : value?.[keyValue])
        } else if (selectedGroups.includes(value.group)) {
            const newValues = values.filter(v => !value.children.includes(`${v}`))
            onChange(newValues)
        } else {
            const newValues = [ ...values.filter(v => !value.children.includes(v)), ...value.children.map(v => optionsOrdered.find(co => `${co?.[keyValue]}` === v)?.[keyValue]) ].filter(nv => nv)
            onChange(newValues)
        }
    }

    const handleSelectedGroups = (params) => {
        if (groupsAreValues) {
            const nameToCheck = multiGroups ? params?.name : params?.group
            const value = optionsOrdered.find(o => o?.name === nameToCheck) || optionsGroups.find(o => o?.name?.toUpperCase() === nameToCheck)
            handleChange(value)
            setSelectedGroups([ nameToCheck ])
        } else {
            const newSelectedGroups = selectedGroups.includes(params.group) ? [ ...selectedGroups.filter(h => h !== params.group) ] : [ ...selectedGroups, params.group ]
            setSelectedGroups(newSelectedGroups)
            handleChange({
                ...params,
                children: params.children.map(c => c?.key),
            })
        }
    }

    const dataOptionsFormatted = useMemo(() => {
        if ((Array.isArray(dataOptions) && !dataOptions?.length) || !hasValue(dataOptions)) {
            return []
        }
        return multiple ? dataOptions : [dataOptions]
    }, [dataOptions, multiple])

    const showClearIcon = clearIcon && (Array.isArray(values) ? values.length > 0 : hasValue(values))

    return (
        <div className={`${col ? `col s${col}` : ''} ${className}`}>
            <Autocomplete
                multiple={multiple}
                id={id}
                groupBy={optionsGroups?.length > 0 ? groupBy : undefined}
                options={optionsFormatted}
                getOptionLabel={(option) => option?.[keyLabel] || ''}
                value={dataOptions || null}
                disableCloseOnSelect={multiple}
                disabled={disabled}
                noOptionsText={i18n.noOption}
                data-cy={datacy}
                clearIcon={showClearIcon && (
                    <Icon
                        fontSize='small'
                        style={{
                            color: 'black',
                            visibility: 'visible',
                        }}
                    >
                        clear
                    </Icon>
                )}
                popupIcon={(
                    <Icon
                        fontSize='small'
                        style={{
                            color: `${!disabled ? 'black' : 'rgba(0, 0, 0, 0.38)'}`,
                            fontWeight: 'bold',
                            visibility: 'visible',
                        }}
                    >
                        expand_more
                    </Icon>
                )}
                fullWidth={fullWidth}
                freeSolo
                disablePortal={disablePortal}
                forcePopupIcon
                filterOptions={(optionsFilter, params) => {
                    const filtered = filterOptions(optionsFilter, params)
                    const filteredFormatted = filtered?.length === maxOptions ? [...filtered, {
                        [keyValue]: seeMoreId,
                        [keyLabel]: i18n.seeMore,
                    }] : filtered
                    const differencedOptions = differenceBy(filteredFormatted, dataOptionsFormatted, keyValue)
                    return [...dataOptionsFormatted, ...differencedOptions]
                }}
                onChange={(_, value) => (multiGroups && value?.isGroup && groupsCanBeSelected) ? handleSelectedGroups(value) : handleChange(value, true)}
                isOptionEqualToValue={(option, value) => option?.[keyValue] === value?.[keyValue]}
                size={'50px'}
                sx={{
                    margin: '14px 0 6px',
                    padding: 0,
                    height: '2rem',
                    position: 'relative',
                    backgroundColor: 'white',
                    borderRadius: '4px',
                    '& input': {
                        border: 'none !important',
                        height: '2rem !important',
                        margin: '0 !important',
                        width: inputWidth ? `${inputWidth} !important` : '',
                        '&:focus': {
                            boxShadow: 'none !important',
                        },
                    },
                    '& .MuiOutlinedInput-root': {
                        padding: '0px 50px 0px 5px !important',
                        '&:hover': {
                            outline: 'none !important',
                        },
                        '&:focus-visible': {
                            outline: 'none !important',
                        },
                    },
                    '& .MuiChip-filled': {
                        height: 18,
                        margin: '0px 3px !important',
                        backgroundColor: 'transparent',
                        '& span': {
                            height: 18,
                            display: 'flex',
                            alignItems: 'center',
                            marginTop: '2px !important',
                        },
                    },
                    '& .MuiAutocomplete-tag': {
                        height: 18,
                        margin: '0px 3px !important',
                    },
                    '& .MuiChip-deleteIcon': {
                        fontSize: '13px !important',
                        color: 'black !important',
                    },
                    '& fieldset': {
                        border: '1px solid !important',
                        borderColor: !disabled ? '#7a7a7a !important' : 'rgba(0, 0, 0, 0.26) !important',
                        '&:focus-visible': {
                            outline: 'none !important',
                        },
                    },
                    '& .MuiFormControl-root': {
                        '&:focus-visible': {
                            outline: 'none !important',
                        },
                    },
                    '& .MuiAutocomplete-endAdornment': {
                        marginTop: '2px !important',
                    },
                }}
                renderInput={(params) => (
                    <>
                        {label && (
                            <div
                                style={{
                                    position: 'absolute',
                                    transform: 'translateY(-18px)',
                                    left: '1.2rem',
                                }}
                            >
                                <label
                                    style={{
                                        color: (document.activeElement === input.current ? '#53A1FF' : (disabled ? 'rgba(0, 0, 0, 0.42)' : '#161832')),
                                        fontWeight: 'bold',
                                        fontSize: '1rem',
                                    }}
                                >
                                    {label}
                                </label>
                                {obligatory && (
                                    <span
                                        style={{
                                            color: (document.activeElement === input.current ? '#35609f' : (disabled ? 'rgba(0, 0, 0, 0.42)' : '#161832')),
                                            fontSize: '1rem',
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        {i18n.obligatoryField}
                                    </span>
                                )}
                                {(tooltip && (document.activeElement === input.current)) && (
                                    <Icon
                                        fontSize='small'
                                        style={{
                                            position: 'inherit',
                                            marginLeft: 5,
                                            marginTop: 1,
                                            color: 'rgb(53, 96, 159)',
                                        }}
                                        {...sieauTooltip(tooltip)}
                                    >
                                        info
                                    </Icon>
                                )}
                            </div>

                        )}
                        <TextField
                            {...params}
                            inputRef={input}
                            placeholder={placeholder}
                        />
                    </>

                )}
                renderTags={(value, getTagProps) => {
                    const numTags = value.length
                    return (
                        <>
                            {value.slice(0, limit).map((option, index) => (
                                <Chip
                                    {...getTagProps({ index })}
                                    key={index}
                                    label={option.name}
                                    style={{
                                        backgroundColor: selectedColor,
                                        border: '1px solid rgb(189, 189, 189)',
                                        maxWidth: '70%',
                                    }}
                                />
                            ))}
                            {numTags > limit && (
                                <Chip
                                    style={{
                                        margin: '0px 3px',
                                        backgroundColor: '#35609f',
                                        color: 'white',
                                        border: 'solid 1px rgb(53, 96, 159)',
                                    }}
                                    sx={{
                                        '& span': {
                                            padding: '5px !important',
                                        },
                                    }}
                                    label={` +${numTags - limit}`}
                                />
                            )}
                        </>
                    )
                }}
                ListboxProps={{
                    style: {
                        padding: 0,
                    },
                }}
                renderGroup={(params) => {
                    if (params?.children?.[0]?.key?.includes(i18n.seeMore)) {
                        return (
                            <div style={{ padding: '0px 1rem', position: 'relative' }}>
                                {params.children}
                            </div>
                        )
                    }
                    return (
                        <>
                            <ListSubheader
                                key={params.key}
                                sx={{
                                    cursor: 'pointer',
                                    position: 'relative',
                                    lineHeight: 'inherit',
                                    padding: '6px 16px',
                                    backgroundColor: `${selectedGroups.includes(params.group) ? '#e7e7e7' : '#fff'}`,
                                    '&:hover': {
                                        backgroundColor: `${groupsCanBeSelected ? (selectedGroups.includes(params.group) ? '#e7e7e7aa' : '#eeeeeeaa') : '#fff'}`,
                                    },
                                }}
                            >
                                {groupIcon && (
                                    <Icon
                                        fontSize='small'
                                        style={{
                                            position: 'absolute',
                                            marginTop: 1,
                                        }}
                                    >
                                        {groupIcon}
                                    </Icon>
                                )}
                                <span
                                    style={{
                                        marginLeft: `${groupIcon && '1.5rem'}`,
                                        fontWeight: 'bold',
                                        fontSize: '14px',
                                        width: '80%',
                                        padding: '0px !important',
                                    }}
                                    onClick={() => groupsCanBeSelected ? handleSelectedGroups(params) : {}}
                                >
                                    {`${params.group} (${params?.children?.length})`}
                                </span>
                                {groupsCanBeHidden && (
                                    <Icon
                                        fontSize='small'
                                        style={{
                                            position: 'absolute',
                                            marginTop: 1,
                                            right: '1rem',
                                        }}
                                        onClick={() => handleHidden(params)}
                                    >
                                        {hiddenKeys.includes(params.key) ? 'visibility' : 'visibility_off'}
                                    </Icon>
                                )}
                            </ListSubheader>
                            <div style={{ padding: '0px 1rem', position: 'relative' }}>
                                {!hiddenKeys.includes(params.key) && params.children}
                            </div>
                        </>
                    )
                }}
                renderOption={(propsOption, option, { selected }) => {
                    if (option?.[keyValue] === seeMoreId) {
                        return (
                            <div
                                {...propsOption}
                                key={option?.[keyValue]}
                                onClick={() => setMaxOptions(maxOptions + 100)}
                                style={{
                                    position: 'relative',
                                    padding: '3px 6px',
                                    marginTop: '-3px',
                                }}
                                data-cy={`autocomplete-${option[keyLabel]}`}
                            >
                                <span style={{ float: 'right' }}>{option[keyLabel]} {'>'}</span>
                            </div>
                        )
                    }
                    if (!multiGroups) {
                        if (option?.[keyLabel]) {
                            return (
                                <li
                                    {...propsOption}
                                    key={option?.[keyValue]}
                                    style={{
                                        position: 'relative',
                                        marginLeft: `${optionsGroups?.length > 0 ? '0.5rem' : '0.1rem'}`,
                                        padding: `${optionsGroups?.length > 0 ? '3px 16px' : '3px 6px'}`,
                                        marginTop: `${propsOption['data-option-index'] !== 0 ? '-3px' : '3px'}`,
                                        marginBottom: '3px',
                                        backgroundColor: `${selected ? selectedColor : 'white'}`,
                                    }}
                                    data-cy={`autocomplete-${option[keyLabel]}`}
                                >
                                    <>
                                        {option.icon || ''}
                                        <span style={{ paddingLeft: option.icon ? '3px' : '0px', paddingRight: option.endIcon ? '3px' : '0px' }}>
                                            {option[keyLabel] || ''}
                                        </span>
                                        {option.endIcon || ''}
                                    </>
                                </li>
                            )
                        }
                        return null
                    }
                    if (intersection(hiddenKeys, option?.parents)?.length > 0) {
                        return null
                    }
                    if (!option?.isGroup) {
                        return (
                            <li
                                {...propsOption}
                                key={option?.[keyValue]}
                                style={{
                                    position: 'relative',
                                    margin: 'auto',
                                    marginTop: '-3px',
                                    marginBottom: '3px',
                                    lineHeight: 'inherit',
                                    padding: `0.188 ${(option?.dephValue || 0) / 0.5}rem`,
                                    width: 'calc(100% - 2rem)',
                                    backgroundColor: `${selected ? selectedColor : 'white'}`,
                                }}
                                data-cy={`autocomplete-${option[keyLabel]}`}
                            >
                                {option?.[keyLabel] || ''}
                            </li>
                        )
                    }
                    return (
                        <ListSubheader
                            key={option?.key}
                            sx={{
                                cursor: 'pointer',
                                position: 'relative',
                                lineHeight: 'inherit',
                                padding: `${option?.dephValue === 0 ? '6px 16px' : 0}`,
                                backgroundColor: `${selectedGroups.includes(option?.name) ? '#e7e7e7' : '#fff'}`,
                                '&:hover': {
                                    backgroundColor: `${groupsCanBeSelected ? (selectedGroups.includes(option?.name) ? '#e7e7e7aa' : '#eeeeeeaa') : '#fff'}`,
                                },
                            }}
                        >
                            {groupIcon && (
                                <Icon
                                    fontSize='small'
                                    style={{
                                        position: 'absolute',
                                        marginTop: 1,
                                        marginLeft: `${((option?.dephValue || 0) / 0.5) - 0.25}rem`,
                                    }}
                                >
                                    {groupIcon}
                                </Icon>
                            )}
                            <span
                                style={{
                                    marginLeft: `${((option?.dephValue || 0) / 0.5) + (groupIcon ? 1.5 : 0)}rem`,
                                    fontWeight: `${option?.dephValue === 0 ? 'bold' : 'normal'}`,
                                    fontSize: `${option?.dephValue === 0 ? '14px' : '1rem'}`,
                                    color: `${option?.dephValue > 0 && '#000000DE'}`,
                                    width: '80%',
                                    padding: '0px !important',
                                }}
                                onClick={() => groupsCanBeSelected ? handleSelectedGroups(option) : {}}
                                data-cy={`autocomplete-${option[keyLabel]}`}
                            >
                                {`${option.name} (${option?.nbChildrens})`}
                            </span>
                            {groupsCanBeHidden && (
                                <Icon
                                    fontSize='small'
                                    style={{
                                        position: 'absolute',
                                        marginTop: 1,
                                        right: '1rem',
                                    }}
                                    onClick={() => handleHidden(option)}
                                >
                                    {hiddenKeys.includes(option?.key) ? 'visibility' : 'visibility_off'}
                                </Icon>
                            )}
                        </ListSubheader>
                    )
                }}
            />
        </div>
    )
}

SuperMultiAutocomplete.propTypes = {
    id: PropTypes.string,
    col: PropTypes.number,
    options: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        name: PropTypes.string,
    })).isRequired,
    optionsGroups: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        name: PropTypes.string,
        elements: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        })),
    })),
    values: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.number),
        PropTypes.arrayOf(PropTypes.string),
        PropTypes.number,
        PropTypes.string,
        PropTypes.object, // type for an empty array
    ]),
    keyLabel: PropTypes.string,
    keyValue: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    fullWidth: PropTypes.bool,
    onChange: PropTypes.func,
    className: PropTypes.string,
    clearIcon: PropTypes.bool,
    multiple: PropTypes.bool,
    groupIcon: PropTypes.string,
    additionalKeysFilter: PropTypes.arrayOf(PropTypes.string),
    limit: PropTypes.number,
    groupsCanBeHidden: PropTypes.bool,
    groupsCanBeSelected: PropTypes.bool,
    groupsAreValues: PropTypes.bool,
    noSort: PropTypes.bool,
    displayWithCode: PropTypes.bool,
    multiGroups: PropTypes.bool,
    tooltip: PropTypes.string,
    obligatory: PropTypes.bool,
    disablePortal: PropTypes.bool,
    inputWidth: PropTypes.string,
    'data-cy': PropTypes.string,
}

export default SuperMultiAutocomplete