import React from 'react'
import { Grid } from '@mui/material'
import i18n from 'simple-react-i18n'
import { getMapSituationStats } from '../../../../piezometry/components/piezometers/SPIUtils'
import SelectStationComponent from '../../../../components/modal/SelectStationComponent'
import Row from '../../../../components/react/Row'
import Checkbox from '../../../../components/forms/Checkbox'
import RadioButtons from '../../../../components/forms/RadioButtons'
import NumberField from '../../../../components/forms/NumberField'
import Input from '../../../../components/forms/Input'
import SimpleDatePicker from '../../../../components/forms/SimpleDatePicker'
import { hasBooleanValue } from '../../../../utils/NumberUtil'
import { groupBy, orderBy, take } from 'lodash'
import { MAP_SITUATION_CLASS } from '../../../constants/piezo/PiezometerStationConstants'
import { getColorCircleElement } from '../../../../utils/ColorUtil'
import ReactEchartsCore from 'echarts-for-react/lib/core'
import echarts from 'echarts/lib/echarts'
import Select from '../../../../components/forms/Select'
import MultiContributorsAutocomplete from 'referencial/components/contributor/components/MultiContributorsAutocomplete'
import StepperDialog from 'components/modal/StepperDialog'
import { ButtonMUI } from 'components/styled/Buttons'
import SuperMultiAutocomplete from 'components/forms/SuperMultiAutocomplete'
import { STATION_TYPE_NAME } from 'station/constants/StationConstants'
import { StyledFieldSet, StyledLegend } from 'components/StyledElements'
import { getArrowDown, getArrowRight, getArrowUp } from 'utils/SvgUtil'

const STEP = {
    STATIONS: 0,
    STATS: 1,
    TREND: 2,
    CALCULATE: 3,
}

const getMapSituationRefUsedList = (st) => {
    return st === STATION_TYPE_NAME.hydrometry ? [
        { code: 'lastMeasure', name: i18n.lastMeasure },
        { code: 'QMNA5', name: 'QMNA5' },
        { code: 'VCN3_Month', name: `VCN3 ${i18n.month}` },
        { code: 'VCN3_Year', name: `VCN3 ${i18n.year}` },
        { code: 'VCN3_Histo', name: `VCN3 ${i18n.historic}` },
        { code: 'VCN3_Custom', name: `VCN3 ${i18n.personalized}` },
        { code: 'OTHER', name: i18n.otherCalculation },
    ] : [
        { code: 'lastMeasure', name: i18n.lastMeasure },
        { code: 'OTHER', name: i18n.otherCalculation },
    ]
}

const getFrequencyStatPie = (results, date) => {
    if (results.length) {
        const group = groupBy(results, 'trendColor')
        const chartData = orderBy(Object.keys(group).map(key => {
            return {
                value: group[key].length,
                name: key === 'undefined' ? 'Pas de classe' : group[key][0].thresholdName,
                color: key === 'undefined' ? 'gray' : group[key][0].trendColor,
                order: MAP_SITUATION_CLASS.find(c => c.imgColor === group[key]?.[0]?.trendColor)?.order || 20,
            }
        }), 'order', 'desc')

        const options = {
            color: chartData.map(o => o.color),
            tooltip: {
                trigger: 'item',
                formatter: '{b} : {c} ({d}%)',
            },
            series: [
                {
                    type: 'pie',
                    data: chartData,
                    avoidLabelOverlap: false,
                    radius: ['50%', '70%'],
                    label: {
                        normal: {
                            show: false,
                            position: 'center',
                        },
                    },
                    labelLine: {
                        normal: {
                            show: false,
                        },
                    },
                },
            ],
        }

        return (
            <ReactEchartsCore
                echarts={ echarts }
                option={ options }
                notMerge={ true }
                lazyUpdate={ true }
                className={ 'row no-margin' }
                style={ { height: 200 } }
            />
        )
    }
    return null
}


const getFrequencyStatLegend = (mapSituation) => {
    if (mapSituation) {
        const params = JSON.parse(mapSituation.params)
        const stats = orderBy(params.stats, 'classUsed', 'desc')
        const statsColors = orderBy(take(MAP_SITUATION_CLASS, (params.stats || []).length + 1), 'order', 'desc')
        const statsLegend = [
            (
                <li>
                    <span dangerouslySetInnerHTML={{ __html: getColorCircleElement(statsColors[0].imgColor) }} />{`> ${stats[0]?.name || ''}` }
                </li>
            ),
            ...stats.slice(1).map((s, idx) => (
                <li>
                    <span dangerouslySetInnerHTML={{ __html: getColorCircleElement(statsColors[idx + 1].imgColor) }} />{`Entre la ${stats[idx]?.name || ''} et la ${stats[idx + 1]?.name || ''}` }
                </li>
            )),
            (
                <li>
                    <span dangerouslySetInnerHTML={{ __html: getColorCircleElement(statsColors[statsColors.length - 1].imgColor) }} />{ `< ${stats[stats.length-1]?.name || ''}` }
                </li>
            ),
        ]

        return (
            <div>
                <Row className='padding-left-1'>
                    <ul>
                        <li> <span dangerouslySetInnerHTML={{ __html: getColorCircleElement('gray') }} />{ 'Pas de classe' }</li>
                    </ul>
                    <ul>
                        { statsLegend }
                    </ul>
                    <ul>
                        <li className='valign-wrapper'>
                            <img style={{ width: '20px', height: '20px', left: 'auto', top: 'auto', margin: '2 5' }} src={`data:image/svg+xml;utf8,${getArrowUp('gray')}`} />
                            { i18n.improvement }
                        </li>
                        <li className='valign-wrapper'>
                            <img style={{ width: '20px', height: '15px', left: 'auto', top: 'auto', margin: '2 5' }} src={`data:image/svg+xml;utf8,${getArrowRight('gray')}`} />
                            { i18n.stagnation }
                        </li>
                        <li className='valign-wrapper'>
                            <img style={{ width: '20px', height: '20px', left: 'auto', top: 'auto', margin: '2 5' }} src={`data:image/svg+xml;utf8,${getArrowDown('gray')}`} />
                            { i18n.degradation }
                        </li>
                    </ul>
                </Row>
            </div>
        )
    }
    return null
}

const getFrequencyStatDefaultParams = () => ({
    allStations: true,
    stationIds: [],
    stats: [],
    validOnly: true,
    isMonthTrend: true,
    isAverageTrend: false,
    monthTrendDays: 0,
    monthTrendMode: 1,
    nbYearsHistoRequired: 3,
    nbDaysWithoutMeasuresRequired: 5,
    nbDaysWithoutMeasuresMax: 7,
    stabilityPercent: 10,
    refUsed: 'lastMeasure',
    customVCN3: 10,
    customCalculMethod: 'AVERAGE',
    customCalculNbDays: 3,
    customCalculDailyGroup: 'MIN',
})

const getFrequencyStatsDialog = (state, changeState, onSave, stations, dataTypes, st, contributors = []) => {
    const { msSelected, paramsDialogIsOpen } = state

    const allStats = getMapSituationStats()

    const params = JSON.parse(msSelected.params)

    const change = changes => changeState({ msSelected: { ...msSelected, ...changes } })

    const changeParams = (changes) => {
        changeState({ msSelected: { ...msSelected, params: JSON.stringify({ ...params, ...changes }) } })
    }

    const calculMethodOptions = [
        { code: 'AVERAGE', name: i18n.average },
        { code: 'MEDIAN', name: i18n.median },
        { code: 'MIN', name: 'Min' },
        { code: 'MAX', name: 'Max' },
    ]

    const groupOptions = [
        { code: 'AVERAGE', name: i18n.average },
        { code: 'MIN', name: 'Min' },
        { code: 'MAX', name: 'Max' },
    ]

    return (
        <StepperDialog
            steps={[
                {
                    label: i18n.stations,
                    constant: STEP.STATIONS,
                },
                {
                    label: i18n.statistics,
                    constant: STEP.STATS,
                    nextAvailable: params.stats.length > 0,
                },
                {
                    label: i18n.trend,
                    constant: STEP.TREND,
                },
                {
                    label: i18n.calcul,
                    constant: STEP.CALCULATE,
                },
            ]}
            open={paramsDialogIsOpen}
            title={i18n.calculationParams}
            sx={{ minHeight: 0 }}
            closeDialog={() => changeState({ paramsDialogIsOpen: false })}
            renderSaveButton={(step, resetStep) => (step === STEP.CALCULATE) && (
                <Grid container justifyContent='space-between'>
                    <Grid item xs={6}>
                        <ButtonMUI
                            onClick={() => {
                                resetStep()
                                onSave()
                            }}
                            variant='contained'
                            color='primary'
                        >
                            {i18n.save}
                        </ButtonMUI>
                    </Grid>
                    <Grid item xs={6}>
                        <ButtonMUI
                            onClick={() => {
                                resetStep()
                                onSave(true)
                            }}
                            variant='contained'
                            color='primary'
                        >
                            {i18n.saveAndCalculate}
                        </ButtonMUI>
                    </Grid>
                </Grid>
            )}
        >
            {(step) => {
                switch (step) {
                    case STEP.STATIONS:
                        const selectedStations = stations.filter(p => params.stationIds.includes(p.id))
                        return (
                            <Grid container spacing={1}>
                                <Grid item xs={6}>
                                    <RadioButtons
                                        elements={ [{ value: true, label: i18n.allStations }, { value: false, label: i18n.chooseStations }] }
                                        selected={ hasBooleanValue(params.allStations) ? params.allStations : false }
                                        onChange={ v => changeParams({ allStations: v }) }
                                        title={ '' }
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    {!(hasBooleanValue(params.allStations) ? params.allStations : false) && (
                                        <SelectStationComponent
                                            selectedStations={selectedStations}
                                            campaignEvents={[]}
                                            stationType={st}
                                            onChangeSelectedStation={tmpStations => changeParams({ stationIds: tmpStations.map(p => p.id) })}
                                            networkLinks={state.networkLinks}
                                        />
                                    )}
                                </Grid>
                            </Grid>
                        )
                    case STEP.STATS:
                        const paramsStats = (params.stats || [])
                        const statsChecks = allStats.reduce((acc, value) => {
                            const [otherCheck, currentClass] = acc
                            if (paramsStats.find(s => s.stat === value.stat)) {
                                const check = (
                                    <Grid container item xs={12} spacing={2}>
                                        <Grid item xs={12}>
                                            <h6>{ `${i18n.classe} ${currentClass+1}` }</h6>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <Checkbox
                                                label={ value.name }
                                                checked={ true }
                                                onChange={
                                                    () => changeParams({ stats: paramsStats.filter(s => s.stat !== value.stat).map((s, idx) => ({ ...s, classUsed: idx+2 })) })
                                                }
                                            />
                                        </Grid>
                                    </Grid>
                                )
                                return [ [ ...otherCheck, check ], currentClass+1]
                            }
                            const checkFalse = (
                                <Grid container item xs={12} spacing={2}>
                                    <Grid item xs={12}>
                                        <h6>{ `${i18n.classe} ${currentClass}` }</h6>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Checkbox
                                            col={ 8 }
                                            label={ value.name }
                                            checked={ false }
                                            onChange={
                                                () => {
                                                    const statsUsed = allStats.filter(s => paramsStats.find(s2 => s2.stat === s.stat) || s.stat === value.stat)
                                                    changeParams({ stats: statsUsed.map((s, idx) => ({ ...s, classUsed: idx+2 })) })
                                                }
                                            }
                                        />
                                    </Grid>
                                </Grid>
                            )
                            return [ [ ...otherCheck, checkFalse ], currentClass]
                        }, [[], 1])
                        return (
                            <StyledFieldSet>
                                <StyledLegend>{i18n.statistics}</StyledLegend>
                                <Grid container sx={{ padding: '1rem' }} spacing={1}>
                                    { statsChecks[0].slice().reverse() }
                                    <Grid item xs={12}>
                                        <h6>{ `${i18n.classe} ${1}` }</h6>
                                    </Grid>
                                </Grid>
                            </StyledFieldSet>
                        )
                    case STEP.TREND:
                        const monthTrendOptions = [
                            { code: 1, name: i18n.calculatedRelation1stMonth },
                            { code: 2, name: i18n.calculatedAtJ },
                        ]
                        return (
                            <div>
                                <StyledFieldSet>
                                    <StyledLegend>{i18n.trend}</StyledLegend>
                                    <Grid container sx={{ padding: '1rem' }} spacing={1}>
                                        <Grid item xs={8}>
                                            <Checkbox
                                                label={ i18n.trendOfTheMonth }
                                                checked={ true }
                                                onChange={ () => {} }
                                                disabled
                                            />
                                        </Grid>
                                        <Grid container item xs={12} spacing={2}>
                                            <Grid item xs={6}>
                                                <RadioButtons
                                                    title={ i18n.modeOfCalculation }
                                                    selected={ params.monthTrendMode }
                                                    elements={ monthTrendOptions }
                                                    onChange={ v => changeParams({ monthTrendMode: v, monthTrendDays: v === 1 ? 0 : 7 }) }
                                                />
                                            </Grid>
                                            <Grid item xs={2}>
                                                <NumberField
                                                    title=''
                                                    value={ params.monthTrendDays }
                                                    onChange={ v => changeParams({ monthTrendDays: v }) }
                                                    disabled={ params.monthTrendMode !== 2 || !params.isMonthTrend }
                                                    min={ 1 }
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid container item xs={12} spacing={2}>
                                            <Grid item xs={8}>
                                                <Checkbox
                                                    label={ i18n.averagedTrend }
                                                    checked={ false }
                                                    onChange={ () => {} }
                                                    disabled
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid container item xs={12} spacing={2}>
                                            <Grid item xs={3}>
                                                <Input
                                                    title={ i18n.margin }
                                                    value={ '' }
                                                    onChange={ () => {} }
                                                    disabled
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </StyledFieldSet>
                                <StyledFieldSet>
                                    <StyledLegend>{i18n.option}</StyledLegend>
                                    <Grid container sx={{ padding: '1rem' }} spacing={1}>
                                        <Grid item xs={8}>
                                            <NumberField
                                                floatValue
                                                title={ i18n.percentageStability }
                                                value={ params.stabilityPercent }
                                                onChange={ v => changeParams({ stabilityPercent: v }) }
                                                min={ 0 }
                                            />
                                        </Grid>
                                    </Grid>
                                </StyledFieldSet>
                            </div>
                        )
                    case STEP.CALCULATE:
                        const refList = getMapSituationRefUsedList(st)
                        const calculType = msSelected.calculType || 'now'
                        return (
                            <div>
                                <StyledFieldSet>
                                    <StyledLegend>{i18n.calcul}</StyledLegend>
                                    <Grid container sx={{ padding: '1rem' }} spacing={1}>
                                        <Grid container item xs={12} spacing={2}>
                                            <Grid item xs={6}>
                                                <Input
                                                    title={i18n.name}
                                                    onChange={v => change({ name: v })}
                                                    value={msSelected.name}
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <MultiContributorsAutocomplete
                                                    label={i18n.administrator}
                                                    disablePortal={false}
                                                    options={contributors}
                                                    values={msSelected.contributorCode}
                                                    onChange={contributorCode => change({ contributorCode })}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid container item xs={12} spacing={2}>
                                            <Grid item xs={5}>
                                                <SuperMultiAutocomplete
                                                    label={i18n.chronicType}
                                                    options={orderBy(dataTypes, 'id')}
                                                    values={msSelected.typeId}
                                                    onChange={v => change({ typeId: v })}
                                                    noSort
                                                />
                                            </Grid>
                                            <Grid item xs={7}>
                                                <RadioButtons
                                                    elements={[
                                                        { code: 'daily', name: i18n.allDays },
                                                        { code: 'now', name: i18n.today },
                                                        { code: 'date', name: i18n.chooseDate },
                                                        { code: 'period', name: i18n.periode },
                                                    ]}
                                                    selected={calculType}
                                                    onChange={v => change({ calculType: v, scheduled: v === 'daily' })}
                                                    title={i18n.calculType}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid container item xs={12} spacing={2}>
                                            {calculType === 'date' && (
                                                <Grid item xs={5}>
                                                    <SimpleDatePicker
                                                        label={i18n.date}
                                                        value={msSelected.startPeriod}
                                                        onChange={date => change({ startPeriod: date })}
                                                    />
                                                </Grid>
                                            )}
                                        </Grid>
                                        <Grid container item xs={12} spacing={2}>
                                            {calculType === 'period' && (
                                                <>
                                                    <Grid container item xs={5} spacing={2}>
                                                        <Grid item xs={6}>
                                                            <SimpleDatePicker
                                                                label={i18n.startDate}
                                                                value={msSelected.startPeriod}
                                                                onChange={date => change({ startPeriod: date })}
                                                                max={msSelected.endPeriod}
                                                                disabled={calculType !== 'period'}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <SimpleDatePicker
                                                                label={i18n.endDate}
                                                                value={msSelected.endPeriod}
                                                                onChange={date => change({ endPeriod: date })}
                                                                min={msSelected.startPeriod}
                                                                disabled={calculType !== 'period'}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={7}>
                                                        <RadioButtons
                                                            elements={[
                                                                { code: 'day', name: i18n.day },
                                                                { code: 'week', name: i18n.week },
                                                                { code: 'month', name: i18n.month },
                                                            ]}
                                                            selected={msSelected.recurrence}
                                                            onChange={v => change({ recurrence: v })}
                                                            title={i18n.recurrence}
                                                            disabled={calculType !== 'period'}
                                                        />
                                                    </Grid>
                                                </>
                                            )}
                                        </Grid>
                                        <Grid container item xs={12} spacing={2}>
                                            <Grid item xs={4}>
                                                <RadioButtons
                                                    elements={ refList }
                                                    selected={ params.refUsed }
                                                    onChange={ v => changeParams({ refUsed: v }) }
                                                    title={ i18n.benchmarkIndicator }
                                                    activeOneBelowOther
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid container item xs={12} spacing={2}>
                                            {st === STATION_TYPE_NAME.hydrometry && (
                                                <Grid container item xs={12}>
                                                    <Grid item xs={6}>
                                                        <NumberField
                                                            title={`${i18n.nbDays} VCN3`}
                                                            value={params.customVCN3}
                                                            disabled={(params.refUsed || '') !== 'VCN3_Custom'}
                                                            min={3}
                                                            onChange={v => changeParams({ customVCN3: v })}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            )}
                                            <Grid container item xs={12} spacing={2}>
                                                <Grid item xs={6}>
                                                    <Select
                                                        label={i18n.dailyRegroup}
                                                        options={groupOptions}
                                                        keyValue='code'
                                                        keyLabel='name'
                                                        value={params.customCalculDailyGroup}
                                                        disabled={(params.refUsed || '') !== 'OTHER'}
                                                        onChange={v => changeParams({ customCalculDailyGroup: v })}
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <NumberField
                                                        title={i18n.nbOfDaysCalculation}
                                                        value={params.customCalculNbDays}
                                                        disabled={(params.refUsed || '') !== 'OTHER'}
                                                        min={1}
                                                        onChange={v => changeParams({ customCalculNbDays: v })}
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Select
                                                        label={i18n.calculationMethod}
                                                        options={calculMethodOptions}
                                                        keyValue='code'
                                                        keyLabel='name'
                                                        value={params.customCalculMethod}
                                                        disabled={(params.refUsed || '') !== 'OTHER'}
                                                        onChange={v => changeParams({ customCalculMethod: v })}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </StyledFieldSet>
                                <StyledFieldSet>
                                    <StyledLegend>{i18n.advancedOptions}</StyledLegend>
                                    <Grid container sx={{ padding: '1rem' }} spacing={1}>
                                        <Grid container item xs={12} spacing={2}>
                                            <Grid item xs={5}>
                                                <RadioButtons
                                                    elements={[
                                                        { code: true, name: i18n.yes },
                                                        { code: false, name: i18n.no },
                                                    ]}
                                                    selected={params.validOnly}
                                                    onChange={v => changeParams({ validOnly: v })}
                                                    title={i18n.calcValidDataOnly}
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <Checkbox
                                                    checked={msSelected.usePredict}
                                                    label={i18n.usePredictData}
                                                    onChange={v => change({ usePredict: v })}
                                                />
                                            </Grid>
                                            <Grid item xs={3}>
                                                <Checkbox
                                                    checked={msSelected.exportCSV}
                                                    label={i18n.exportToCSV}
                                                    onChange={v => change({ exportCSV: v })}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid container item xs={12} spacing={2}>
                                            <Grid item xs={6}>
                                                <NumberField
                                                    title={i18n.numberOfYearsHistoryRequired}
                                                    value={params.nbYearsHistoRequired}
                                                    onChange={v => changeParams({ nbYearsHistoRequired: v })}
                                                    min={0}
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <NumberField
                                                    title={i18n.nbDaysWithoutAcceptableData}
                                                    value={params.nbDaysWithoutMeasuresMax}
                                                    onChange={v => changeParams({ nbDaysWithoutMeasuresMax: v })}
                                                    min={1}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </StyledFieldSet>
                            </div>
                        )
                    default:
                        return null
                }
            }}
        </StepperDialog>
    )
}

export { getFrequencyStatsDialog, getFrequencyStatDefaultParams, getFrequencyStatLegend, getMapSituationRefUsedList, getFrequencyStatPie }