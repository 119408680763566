import { Grid, Popover } from '@mui/material'
import Icon from 'components/icon/Icon'
import { isUndefined } from 'lodash'
import PropTypes from 'prop-types'
import React, { useMemo, useState } from 'react'
import useBoolean from 'utils/customHook/useBoolean'
import { sieauTooltip } from '../../utils/FormUtils'

const Dropdown = ({
    icon,
    tooltip = '',
    listItem = [],
}) => {
    const {
        value: isOpen,
        setTrue: open,
        setFalse: close,
    } = useBoolean(false)

    const [anchorEl, setAnchorEl] = useState()

    const iconAction = useMemo(() => {
        if (isUndefined(icon)) {
            return isOpen ? 'arrow_drop_up' : 'arrow_drop_down'
        }
        return icon
    }, [icon, isOpen])

    const id = open ? 'simple-popper' : undefined

    return (
        <div className='action-wrapper'>
            <a
                className={'right waves-effect nav-actions blue-arrow'}
                {...sieauTooltip(tooltip, 'dropdown_navbar', 'bottom')}
                onClick={e => {
                    open()
                    setAnchorEl(e.currentTarget)
                }}
            >
                <Icon id={id} icon={iconAction} ref={anchorEl}/>
            </a>
            <Popover
                open={isOpen}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                onClose={close}
            >
                {
                    listItem.map((item, i) => (
                        <Grid
                            container
                            sx={{
                                width: 400,
                                padding: '10',
                                cursor: 'pointer', '&:hover': {
                                    backgroundColor: '#eee',
                                },
                                borderBottom: i < listItem.length - 1 ? '1px solid #eee' : undefined,
                            }}
                            onClick={item.onClick}
                        >
                            <Grid item xs={1}>
                                <Icon icon={item.icon} />
                            </Grid>
                            <Grid item xs={11}>
                                <h6>{item.label}</h6>
                            </Grid>
                        </Grid>
                    ))
                }
            </Popover>
        </div>
    )
}

Dropdown.propTypes = {
    icon: PropTypes.string,
    tooltip: PropTypes.string,
    listItem: PropTypes.arrayOf(PropTypes.shape({
        onClick: PropTypes.func,
        icon: PropTypes.string,
        label: PropTypes.string,
    })),
}

export default Dropdown
