import React, { isValidElement } from 'react'
import PropTypes from 'prop-types'
import { CardHeader, Grid2 } from '@mui/material'
import { isUndefined } from 'lodash'
import Action from 'components/action/Action'

const Title = ({
    title = '',
    subTitle = '',
    subtitleSize = 2,
    actions = [],
}) => {
    const filteredActions = actions.filter(a => a.displayed || isUndefined(a.displayed))
    return (
        <Grid2 container spacing={2} alignItems='center'>
            <Grid2 size='grow'>
                {isValidElement(title) ? title : (<span style={{ fontSize: '1.4rem', fontWeight: 'bold' }}>{title}</span>)}
            </Grid2>
            {!!subTitle && (
                <Grid2 size={subTitle ? subtitleSize : 'auto'}>
                    {isValidElement(subTitle) ? subTitle : (<span style={{ fontSize: '1.4rem' }}>{subTitle}</span>)}
                </Grid2>
            )}
            {filteredActions.length > 0 && (
                <Grid2 container size='auto' spacing={0.5}>
                    {filteredActions.map(action => (
                        <Grid2 key={actions.indexOf(action)} size='auto'>
                            {isValidElement(action) ? action : (<Action {...action} />)}
                        </Grid2>
                    ))}
                </Grid2>
            )}
        </Grid2>
    )
}

Title.propTypes = {
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    subTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    subtitleSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    actions: PropTypes.arrayOf(PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.shape({
            icon: PropTypes.string,
            tooltip: PropTypes.string,
            onClick: PropTypes.func,
            color: PropTypes.string,
            displayed: PropTypes.bool,
            'data-cy': PropTypes.string,
        }),
    ])),
}

const CardTitle = ({
    title = '',
    subTitle = '',
    subtitleSize = 2,
    actions = [],
    color = '#FFF',
}) => (
    <CardHeader
        title={<Title title={title} subTitle={subTitle} actions={actions} subtitleSize={subtitleSize} />}
        color={color}
        disableTypography
    />
)

CardTitle.propTypes = {
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    subTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    subtitleSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    actions: PropTypes.arrayOf(PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.shape({
            icon: PropTypes.string,
            tooltip: PropTypes.string,
            onClick: PropTypes.func,
            color: PropTypes.string,
            displayed: PropTypes.bool,
            'data-cy': PropTypes.string,
        }),
    ])),
    color: PropTypes.string,
}

export { CardTitle }