import { shallowEqual, useSelector } from 'react-redux'
import React, { useEffect } from 'react'
import { Grid } from '@mui/material'
import { QUALITO_IMPORT_TYPE } from '../AddQualitoConstants'
import i18n from 'simple-react-i18n'
import PresentationCard from '../../../../../components/card/PresentationCard'
import PropTypes from 'prop-types'
import { getSettingInt } from '../../../../../utils/SettingUtils'
import { hasValue } from '../../../../../utils/NumberUtil'

const getDefaultParams = (stationType, applicationSettings) => {
    switch (stationType) {
        case QUALITO_IMPORT_TYPE.ADES_QUALITO:
            return {
                filters: {
                    descriptionImportMode: 'all',
                    selectStationsMode: 'codesMode',
                    syncEvents: true,
                    syncNetworks: true,
                    dontCheckInternalReferences: false,
                    addCodesToADESNetwork: hasValue(getSettingInt(applicationSettings, 'adesQualitoSynchroNetwork')),
                    selectedNetwork: getSettingInt(applicationSettings, 'adesQualitoSynchroNetwork'),
                },
                dataTypes: ['stationIntegration', 'DATA_ADD_NEW_DATA'],
            }
        case QUALITO_IMPORT_TYPE.NAIADES:
            return {
                filters: null,
                dataTypes: ['stationIntegration', 'DATA_ADD_NEW_DATA'],
            }
        case QUALITO_IMPORT_TYPE.OROBNAT:
            return {
                filters: {
                    stationTypes: ['CAP', 'TTP', 'UDI'],
                },
                dataTypes: ['stationIntegration', 'DATA_ADD_NEW_DATA'],
            }
        default:
            return { filters: {}, dataTypes: [] }
    }
}

const updateParams = (stationType, applicationSettings, setFilters, setJobDataTypes) => {
    const params = getDefaultParams(stationType, applicationSettings)
    setFilters(params.filters)
    setJobDataTypes(params.dataTypes)
}


const AddQualitoStepStation = ({
    station,
    changeStation,
    nextStep,
    setFilters,
    setJobDataTypes,
}) => {
    // const [isOpen, setIsOpen] = useState(false)

    const {
        applicationSettings,
    } = useSelector(store => ({
        applicationSettings: store.AdministrationReducer.applicationSettings,
    }), shallowEqual)

    useEffect(() => {

    }, [])

    const centerStyle = {
        'align-items': 'center',
        display: 'flex',
        justifyContent: 'center',
    }

    return (
        <div className='padding-top-2'>
            <Grid container justifyContent='center' alignItems='center' spacing={3}>
                <h4>{i18n.newStationStep1}</h4>
            </Grid>
            <Grid container justifyContent='center' alignItems='center' spacing={3}>
                <Grid item xs={6} >
                    <PresentationCard
                        iconComponent={<img className='responsive-img' src={'pictures/manualImports/ades.png'} height={70} width={70} style={{
                            textAlign: 'center',
                            display: 'table-cell',
                            verticalAlign: 'middle',
                        }}
                        />}
                        iconContainerStyle={centerStyle}
                        title='ADES'
                        onClick={() => {
                            changeStation({ stationType: QUALITO_IMPORT_TYPE.ADES_QUALITO })
                            updateParams(QUALITO_IMPORT_TYPE.ADES_QUALITO, applicationSettings, setFilters, setJobDataTypes)
                            nextStep()
                        }}
                        isFocused={station.stationType === QUALITO_IMPORT_TYPE.ADES_QUALITO}
                        description={i18n.adesDescription}
                    />
                </Grid>
                <Grid item xs={6}>
                    <PresentationCard
                        iconComponent={<img className='responsive-img padding-right-1' src={'pictures/manualImports/naiades.png'} height={50} width={100}/>}
                        iconContainerStyle={centerStyle}
                        title='Naïades'
                        onClick={() => {
                            changeStation({ stationType: QUALITO_IMPORT_TYPE.NAIADES })
                            updateParams(QUALITO_IMPORT_TYPE.NAIADES, applicationSettings, setFilters, setJobDataTypes)
                            nextStep()
                        }}
                        description={i18n.naiadesDescription}
                        isFocused={station.stationType === QUALITO_IMPORT_TYPE.NAIADES}
                    />
                </Grid>
            </Grid>
            <Grid container justifyContent='center' alignItems='center' spacing={3} >
                <Grid item xs={6}>
                    <PresentationCard
                        iconComponent={<img className='responsive-img padding-right-1' src={'pictures/manualImports/orobnat.png'} width={110} />}
                        iconContainerStyle={centerStyle}
                        title='Orobnat'
                        onClick={() => {
                            changeStation({ stationType: QUALITO_IMPORT_TYPE.OROBNAT })
                            updateParams(QUALITO_IMPORT_TYPE.OROBNAT, applicationSettings, setFilters, setJobDataTypes)
                            nextStep()
                        }}
                        description={i18n.OrobnatDescription}
                        isFocused={station.stationType === QUALITO_IMPORT_TYPE.OROBNAT}
                    />
                </Grid>
                <Grid item xs={6}>
                    <PresentationCard
                        iconComponent={<img className='responsive-img' src={'pictures/markers/map_qualite.png'} height={50} width={50} />}
                        iconContainerStyle={centerStyle}
                        title='Point vide'
                        onClick={() => {
                            changeStation({ stationType: QUALITO_IMPORT_TYPE.EMPTY })
                            updateParams(QUALITO_IMPORT_TYPE.EMPTY, applicationSettings, setFilters, setJobDataTypes)
                            nextStep()
                        }}
                        description={i18n.newQualitoDescription}
                        isFocused={station.stationType === QUALITO_IMPORT_TYPE.EMPTY}
                    />
                </Grid>
            </Grid>
        </div>
    )
}

AddQualitoStepStation.propTypes = {
    station: PropTypes.shape({
        stationType: PropTypes.string,
    }),
    setFilters: PropTypes.func,
    setJobDataTypes: PropTypes.func,
    nextStep: PropTypes.func,
    changeStation: PropTypes.func,
}

export default AddQualitoStepStation