import { Button, Grid } from '@mui/material'
import PresentationCard from 'components/card/PresentationCard'
import TooltipButton from 'components/forms/TooltipButton'
import CenterIcon from 'components/icon/CenterIcon'
import ExportAction from 'export/actions/ExportAction'
import PropTypes from 'prop-types'
import React, { useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import i18n from 'simple-react-i18n'
import ToastrAction from 'toastr/actions/ToastrAction'
import { formatData } from 'utils/ExportDataUtil'
import ProgressBar from '../../components/progress/ProgressBar'
import StepperDialog from '../modal/StepperDialog'
import { isUndefined, keys, mapValues, some, truncate } from 'lodash'
import MUIAlert from 'referencial/components/MUIAlert'
import { LIMIT_CELL_LENGTH } from 'export/constants/ExportConstants'
import { excelIcon, xmlIcon } from 'utils/SvgUtil'

const STEP = {
    ACTION: 0,
    DETAIL: 1,
    SYNC: 2,
}

const ACTIONS = {
    export: 'EXPORT',
    sync: 'SYNC',
    purge: 'PURGE',
}

const PURGE_OPTIONS = {
    purge: 0,
    makeInactive: 1,
}

const SYNC_OPTIONS = {
    webService: 0,
    xml: 1,
    csv: 2,
}

const EXPORTS_FORMATS = {
    csv: 'csv',
    xlsx: 'xlsx',
}

const getExportImg = (icon) => (
    <img
        id='color-change-svg'
        src={`data:image/svg+xml;utf8,${icon}`}
        style={{ height: '4rem', width: '100%', paddingRight: 5 }}
    />
)

const getCardIcon = (icon) => (
    <CenterIcon
        icon={icon}
        color='#4f88b5'
        additionalStyle={{ paddingRight: 5 }}
    />
)

const StepperExport = ({
    step = 1,
    exportType = '',
    exportContent,
    onClickCsv = () => { },
    onClickExcel = () => { },
    tooManyCharactersExport = false,
}) => step === STEP.DETAIL && (
    <Grid container justifyContent='center' rowSpacing={3}>
        <Grid item xs={12} style={{ textAlign: 'center', marginBottom: '2rem' }}>
            <h4>{i18n.chooseExportFormat}</h4>
        </Grid>
        <Grid item xs={10}>
            <Grid container justifyContent='space-between' alignItems='center'>
                <Grid item xs={5}>
                    <PresentationCard
                        iconComponent={getExportImg(excelIcon)}
                        title={i18n.exportCSV}
                        isFocused={exportType === EXPORTS_FORMATS.csv}
                        onClick={onClickCsv}
                        data-cy='tune_action_export_csv'
                    />
                </Grid>
                <Grid item xs={5}>
                    <PresentationCard
                        iconComponent={getExportImg(excelIcon)}
                        title={i18n.exportXLSX}
                        isFocused={exportType === EXPORTS_FORMATS.xlsx}
                        onClick={onClickExcel}
                        data-cy='tune_action_export_xlsx'
                    />
                </Grid>
                {exportContent && (
                    <Grid style={{ marginTop: '3rem' }} item xs={12}>
                        {exportContent}
                    </Grid>
                )}
            </Grid>
        </Grid>
        {!!tooManyCharactersExport && (
            <Grid item xs={10}>
                <MUIAlert severity='warning'>{i18n.tooManyCharactersExportMessage}</MUIAlert>
            </Grid>
        )}
    </Grid>
)

StepperExport.propTypes = {
    step: PropTypes.number,
    exportType: PropTypes.string,
    exportContent: PropTypes.element,
    onClickCsv: PropTypes.func,
    onClickExcel: PropTypes.func,
    tooManyCharactersExport: PropTypes.bool,
}

const StepperSync = ({
    step = 1,
    syncOption = 0,
    syncStarted = false,
    onClickWebService = () => { },
    onClickXml = () => { },
    onClickCsv = () => { },
    webServiceContent,
    xmlContent,
    csvContent,
    syncActions = {},
}) => {
    switch (step) {
        case STEP.DETAIL:
            return (
                <Grid container>
                    <Grid item xs={12} style={{ textAlign: 'center', marginBottom: '2rem' }}>
                        <h4>{i18n.chooseSyncOption}</h4>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container justifyContent='center' alignItems='center'>
                            <Grid item xs={7}>
                                {syncActions.updateAction && (
                                    <PresentationCard
                                        iconComponent={
                                            <CenterIcon
                                                icon={'language'}
                                                color='#4f88b5'
                                                additionalStyle={{ paddingRight: 5 }}
                                            />
                                        }
                                        title={i18n.ws}
                                        onClick={onClickWebService}
                                        description={syncActions.webServiceDescription}
                                        isFocused={syncOption === SYNC_OPTIONS.webService}
                                    />
                                )}
                            </Grid>
                            <Grid item xs={7}>
                                {syncActions.onImportXml && (
                                    <PresentationCard
                                        iconComponent={getExportImg(xmlIcon)}
                                        title={i18n.xml}
                                        onClick={onClickXml}
                                        isFocused={syncOption === SYNC_OPTIONS.xml}
                                    />
                                )}
                            </Grid>
                            <Grid item xs={7}>
                                {syncActions.onImportCsv && (
                                    <PresentationCard
                                        iconComponent={getExportImg(excelIcon)}
                                        title={i18n.csv}
                                        onClick={onClickCsv}
                                        isFocused={syncOption === SYNC_OPTIONS.csv}
                                    />
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            )
        case STEP.SYNC:
            switch (syncOption) {
                case SYNC_OPTIONS.webService:
                    return (
                        <Grid container justifyContent={'center'} alignItems={'center'} style={{ textAlign: 'center' }}>
                            <Grid item xs={10} style={{ marginBottom: '5rem' }}>
                                <h4>{i18n.webServiceSyncChoice}</h4>
                            </Grid>
                            <Grid item xs={10} style={{ marginBottom: webServiceContent ? '5rem' : '' }}>
                                {!syncStarted ? (
                                    <h5>{i18n.startSync}</h5>
                                ) : (
                                    <ProgressBar indeterminate />
                                )}
                            </Grid>
                            {!!webServiceContent && (
                                <Grid item xs={10}>
                                    {webServiceContent}
                                </Grid>
                            )}
                        </Grid>
                    )
                case SYNC_OPTIONS.xml:
                    return xmlContent
                case SYNC_OPTIONS.csv:
                    return csvContent
                default:
                    return null
            }
        default:
            return null
    }
}

StepperSync.propTypes = {
    step: PropTypes.number,
    syncOption: PropTypes.number,
    syncStarted: PropTypes.bool,
    onClickWebService: PropTypes.func,
    onClickXml: PropTypes.func,
    onClickCsv: PropTypes.func,
    webServiceContent: PropTypes.element,
    xmlContent: PropTypes.element.isRequired,
    csvContent: PropTypes.element.isRequired,
    syncActions: PropTypes.shape({
        updateAction: PropTypes.func,
        onImportXml: PropTypes.func,
        onImportCsv: PropTypes.func,
        xmlContent: PropTypes.element,
        csvContent: PropTypes.element,
        fileContentIsValid: PropTypes.bool,
        webServiceDescription: PropTypes.string,
    }),
}

const StepperPurge = ({
    step = 1,
    onPurge,
    onMakeInactive,
    purgeOption = -1,
    setPurgeOption = () => { },
}) => step === STEP.DETAIL && (
    <Grid container>
        <Grid item xs={12} style={{ textAlign: 'center', marginBottom: '2rem' }}>
            <h4>{i18n.choosePurgeOption}</h4>
        </Grid>
        <Grid item xs={12}>
            <Grid container justifyContent='space-around' alignItems='center'>
                {onPurge && (
                    <Grid item xs={5}>
                        <PresentationCard
                            iconComponent={
                                <CenterIcon
                                    icon={'delete_forever'}
                                    color='#4f88b5'
                                    additionalStyle={{ paddingRight: 5 }}
                                />
                            }
                            title={i18n.purgeUnusedElements}
                            isFocused={purgeOption === PURGE_OPTIONS.purge}
                            onClick={() => setPurgeOption(PURGE_OPTIONS.purge)}
                        />
                    </Grid>
                )}
                {onMakeInactive && (
                    <Grid item xs={5}>
                        <PresentationCard
                            iconComponent={
                                <CenterIcon
                                    icon={'do_disturb'}
                                    color='#4f88b5'
                                    additionalStyle={{ paddingRight: 5 }}
                                />
                            }
                            title={i18n.makeInactiveelemnets}
                            isFocused={purgeOption === PURGE_OPTIONS.makeInactive}
                            onClick={() => setPurgeOption(PURGE_OPTIONS.makeInactive)}
                        />
                    </Grid>
                )}
            </Grid>
        </Grid>
    </Grid>
)

StepperPurge.propTypes = {
    step: PropTypes.number,
    onPurge: PropTypes.func,
    onMakeInactive: PropTypes.func,
    purgeOption: PropTypes.number,
    setPurgeOption: PropTypes.func,
}

const ReferencialActionModal = ({
    setOpen = () => { },
    open = false,
    actions = {},
}) => {
    const dispatch = useDispatch()

    const [action, setAction] = useState()
    const [exportType, setExportType] = useState('')
    const [purgeOption, setPurgeOption] = useState(-1)
    const [syncOption, setSyncOption] = useState(0)
    const [syncStarted, setSyncStarted] = useState(false)

    const closeDialog = (resetStep = () => { }) => {
        resetStep()
        setAction(ACTIONS.export)
        setExportType('')
        setPurgeOption(-1)
        setSyncOption(0)
        setOpen(false)
        setSyncStarted(false)
    }

    const purge = (resetStep = () => { }) => {
        closeDialog(resetStep)
        if (purgeOption === PURGE_OPTIONS.purge) {
            actions.purge.onPurge()
        } else if (purgeOption === PURGE_OPTIONS.makeInactive) {
            actions.purge.onMakeInactive()
        }
    }

    const tooManyCharactersExport = some(actions.export?.data ?? [], d => some(keys(d), k => `${d[k]}`.length > LIMIT_CELL_LENGTH))

    const exportData = (resetStep = () => { }) => {
        if (actions.export.data?.length) {
            closeDialog(resetStep)
            const formatDatas = tooManyCharactersExport ? actions.export.data.map(data => mapValues(data, value => {
                if (`${value}`.length > LIMIT_CELL_LENGTH) {
                    return truncate(value, { length: (LIMIT_CELL_LENGTH - 1) })
                }
                return value
            })) : actions.export.data
            dispatch(ExportAction.export(formatData(formatDatas), exportType, actions.export.titleFile || i18n.exportName))
        } else {
            dispatch(ToastrAction.error(i18n.noData))
        }
    }

    const sync = (resetStep = () => { }) => {
        if (syncOption === SYNC_OPTIONS.webService) {
            setSyncStarted(true)
            actions.sync.updateAction().then(() => {
                closeDialog(resetStep)
            })
        } else if (syncOption === SYNC_OPTIONS.xml) {
            actions.sync.onImportXml().then(() => {
                closeDialog(resetStep)
            })
        } else if (syncOption === SYNC_OPTIONS.csv) {
            actions.sync.onImportCsv().then(() => {
                closeDialog(resetStep)
            })
        }
    }

    const steps = useMemo(() => {
        const actionStep = {
            label: i18n.action,
            constant: STEP.ACTION,
            nextAvailable: !isUndefined(action),
        }
        if (action === ACTIONS.sync) {
            return [
                actionStep,
                { label: i18n.syncOptions, constant: STEP.DETAIL },
                { label: i18n.synchronize, constant: STEP.SYNC },
            ]
        }
        if (action === ACTIONS.purge) {
            return [
                actionStep,
                { label: i18n.purge, constant: STEP.DETAIL },
            ]
        }
        if (action === ACTIONS.export) {
            return [
                actionStep,
                { label: i18n.export, constant: STEP.DETAIL },
            ]
        }
        return [
            actionStep,
            ...(actions[action]?.steps ?? []),
        ]
    }, [action, actions])

    const {
        export: exportAction,
        sync: syncAction,
        purge: purgeAction,
        ...otherActions
    } = actions

    const getSaveButton = (step, resetStep = () => { }) => (
        <>
            {action === ACTIONS.export && (
                <Button
                    onClick={() => exportData(resetStep)}
                    disabled={step !== 1 || exportType === ''}
                    variant='contained'
                    data-cy='tune_action_validate_export'
                >
                    {i18n.export}
                </Button>
            )}
            {action === ACTIONS.sync && (
                <Button
                    onClick={() => sync(resetStep)}
                    disabled={step !== 2 || syncOption === -1 || (!actions.sync.fileContentIsValid && syncOption !== 0)}
                    variant='contained'
                >
                    {i18n.synchronize}
                </Button>
            )}
            {action === ACTIONS.purge && (
                <TooltipButton
                    tooltip={{
                        question: purgeOption === 0 ? i18n.sureDeleteElements : purgeOption === 1 ? i18n.sureMakeInactiveElements : '',
                        onClickYes: () => purge(resetStep),
                    }}
                    disabled={step !== 1 || purgeOption === -1}
                    className={step === 1 && purgeOption !== -1 ? 'red' : ''}
                >
                    {i18n.delete}
                </TooltipButton>
            )}
            {keys(otherActions).map(key => action === key && otherActions[key]?.validateButton?.())}
        </>
    )

    return (
        <StepperDialog
            steps={steps}
            open={open}
            title={i18n.actions}
            closeDialog={closeDialog}
            renderSaveButton={getSaveButton}
        >
            {(step, nextStep = () => { }) => {
                return (
                    <>
                        {step === STEP.ACTION && (
                            <Grid container justifyContent='center'>
                                <Grid item xs={12} style={{ textAlign: 'center', marginBottom: '2rem' }}>
                                    <h4>{i18n.whatActionWouldYouLikeToTake}</h4>
                                </Grid>
                                <Grid item xs={9}>
                                    <Grid container direction='column' justifyContent='space-between' alignItems='center'>
                                        {!!exportAction && (
                                            <PresentationCard
                                                iconComponent={getCardIcon('file_download')}
                                                title={i18n.exportName}
                                                description={i18n.exportDescription}
                                                onClick={() => {
                                                    setAction(ACTIONS.export)
                                                    nextStep()
                                                }}
                                                isFocused={action === ACTIONS.export}
                                                data-cy='tune_action_export'
                                            />
                                        )}
                                        {!!syncAction && (
                                            <PresentationCard
                                                iconComponent={getCardIcon('sync')}
                                                title={i18n.synchronizeName}
                                                description={i18n.syncDescription}
                                                onClick={() => {
                                                    setAction(ACTIONS.sync)
                                                    nextStep()
                                                }}
                                                isFocused={action === ACTIONS.sync}
                                            />
                                        )}
                                        {(!!purgeAction && (purgeAction.onPurge || purgeAction.onMakeInactive)) && (
                                            <PresentationCard
                                                iconComponent={getCardIcon('delete_forever')}
                                                title={i18n.purge}
                                                description={i18n.purgeDescription}
                                                onClick={() => {
                                                    setAction(ACTIONS.purge)
                                                    nextStep()
                                                }}
                                                isFocused={action === ACTIONS.purge}
                                            />
                                        )}
                                        {keys(otherActions).map(key => {
                                            const otherAction = otherActions[key]
                                            return (
                                                <PresentationCard
                                                    key={key}
                                                    iconComponent={getCardIcon(otherAction.icon)}
                                                    title={otherAction.title}
                                                    description={otherAction.description}
                                                    onClick={() => {
                                                        setAction(key)
                                                        nextStep()
                                                    }}
                                                    isFocused={action === key}
                                                />
                                            )
                                        })}
                                    </Grid>
                                </Grid>
                            </Grid>
                        )}
                        {action === ACTIONS.export && (
                            <StepperExport
                                step={step}
                                exportType={exportType}
                                exportContent={actions.export?.exportContent}
                                onClickCsv={() => setExportType(EXPORTS_FORMATS.csv)}
                                onClickExcel={() => setExportType(EXPORTS_FORMATS.xlsx)}
                                tooManyCharactersExport={tooManyCharactersExport}
                            />
                        )}
                        {action === ACTIONS.sync && (
                            <StepperSync
                                step={step}
                                syncOption={syncOption}
                                syncStarted={syncStarted}
                                onClickWebService={() => {
                                    setSyncOption(SYNC_OPTIONS.webService)
                                    nextStep()
                                }}
                                onClickXml={() => {
                                    setSyncOption(SYNC_OPTIONS.xml)
                                    nextStep()
                                }}
                                onClickCsv={() => {
                                    setSyncOption(SYNC_OPTIONS.csv)
                                    nextStep()
                                }}
                                webServiceContent={actions.sync.webServiceContent}
                                xmlContent={actions.sync.xmlContent}
                                csvContent={actions.sync.csvContent}
                                syncActions={actions.sync}
                            />
                        )}
                        {action === ACTIONS.purge && (
                            <StepperPurge
                                step={step}
                                onPurge={actions.purge.onPurge}
                                onMakeInactive={actions.purge.onMakeInactive}
                                purgeOption={purgeOption}
                                setPurgeOption={setPurgeOption}
                            />
                        )}
                        {keys(otherActions).map(key => action === key && (
                            <div key={key}>{otherActions[key][`componentStep${step}`]}</div>
                        ))}
                    </>
                )
            }}
        </StepperDialog>
    )
}

ReferencialActionModal.propTypes = {
    open: PropTypes.bool,
    setOpen: PropTypes.func,
    actions: PropTypes.shape({
        export: PropTypes.shape({
            exportContent: PropTypes.element,
            onSubmit: PropTypes.func,
        }),
        sync: PropTypes.shape({
            updateAction: PropTypes.func,
            onImportXml: PropTypes.func,
            onImportCsv: PropTypes.func,
            webServiceContent: PropTypes.element,
            xmlContent: PropTypes.element,
            csvContent: PropTypes.element,
            fileContentIsValid: PropTypes.bool,
            webServiceDescription: PropTypes.string,
        }),
        purge: PropTypes.shape({
            onPurge: PropTypes.func,
            onMakeInactive: PropTypes.func,
        }),
    }),
}


export default ReferencialActionModal
