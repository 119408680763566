import { find, isEqual, reduce, remove } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import ActionComponent from '../../../../../components/ActionComponent'
import ContactDto from '../../../../../referencial/components/contact/dto/ContactDto'
import { PANEL_ACCESS, PANEL_MAP, PANEL_MODULES, PANEL_STATISTICS } from '../../constants/UserConstants'
import DtoUser from '../../dto/DtoUser'
import DtoUserHabilitation from '../../dto/DtoUserHabilitation'
import DtoUserStation from '../../dto/DtoUserStation'
import UserAccessPanel from './UserAccessPanel'
import UserDetailForm from './UserDetailForm'
import UserModulesPanel from './UserModulesPanel'
import UserStatisticPanel from './UserStatisticPanel'
import MapAccountUserPanel from '../../../../../account/components/panel/MapAccountUserPanel'
import { getUser } from 'utils/SettingUtils'
import SettingDto from 'administration/dto/SettingDto'
import AccountAction from 'account/actions/AccountAction'
import Card from 'components/card/Card'
import { Button, Dialog, DialogActions, DialogTitle } from '@mui/material'
import { MAP_X_COORDINATE, MAP_Y_COORDINATE, MAP_ZOOM } from 'account/constants/AccountSettingsConstants'
import ProgressBar from 'components/progress/ProgressBar'
import AdministrationAction from 'administration/actions/AdministrationAction'

class UserDetailPanel extends ActionComponent {
    constructor(props) {
        super(props)
        this.state = {
            settings: [],
            openModalCheck: false,
        }
    }

    saveFunction = () => {
        if (!isEqual(this.props.userSettings, this.state.settings)) {
            const result = this.state.settings.map(o => ({
                ...o,
                value: o.value ? o.value.toString() : '',
            }))
            this.props.resetUserSettings()
            this.props.saveSettings(this.props.user.login, result, true)
        }
    }

    createActions = () => {
        if (!(getUser().consultant === '1')) {
            const actions = {
                save: () => {
                    this.saveFunction()
                },
            }
            this.setActions(actions)
        }
    }

    changeState = (view) => {
        this.setState({
            view,
        }, () => {
            if (this.state.view === PANEL_MAP) {
                this.createActions()
            }
        })
    }

    getListOfHabilitations = () => {
        const result = reduce(this.props.userHabilitations, (a, b) => {
            (a[b.habilitation.split('_')[0]] || (a[b.habilitation.split('_')[0]] = [])).push(b)
            return a
        }, {})
        return Object.keys(result).map((o) => {
            return {
                module: o,
                values: result[o],
            }
        })
    }

    getUserSettings = () => {
        if (this.state.settings.length) {
            return this.state.settings
        }
        return this.props.userSettings
    }

    changeSettings = (sttngs) => {
        const tmp = [...this.getUserSettings()]
        const filterSettings = remove(tmp, o => {
            return !find(sttngs, sett => o.parameter === sett.parameter)
        })
        const settings = [...filterSettings, ...sttngs].map(o => (new SettingDto({
            ...o,
            login: this.props.user.login,
        })))
        this.setState({ settings })
    }

    removeCoordinates = () => {
        const tmp = [...this.getUserSettings()]
        const settings = remove(tmp, o => {
            return !(o.parameter === MAP_X_COORDINATE || o.parameter === MAP_Y_COORDINATE || o.parameter === MAP_ZOOM)
        })
        this.setState({ settings, openModalCheck: false }, this.saveFunction)
    }

    getPanel = () => {
        const { openModalCheck } = this.state
        const { userSettings, view } = this.props
        const listOfHabilitations = this.getListOfHabilitations()
        switch (view) {
            case PANEL_MODULES:
                return <UserModulesPanel listOfHabilitations={ listOfHabilitations }/>
            case PANEL_ACCESS:
                return <UserAccessPanel/>
            case PANEL_STATISTICS:
                return <UserStatisticPanel/>
            case PANEL_MAP:
                return userSettings.length > 0 ? (
                    <Card>
                        <div className='row no-margin'>
                            <div className='col s12 padding-top-1'>
                                <MapAccountUserPanel
                                    accountUser={this.props.user}
                                    changeSettings={ this.changeSettings }
                                    accountUserSettings={ this.getUserSettings() }
                                />
                                <div className='row padding-right-2 padding-top-2'>
                                    <div className='col xl8 l10 m10 s10 right'>
                                        <a
                                            className='waves-effect waves-light btn right'
                                            onClick={() => this.setState({ openModalCheck: !openModalCheck })}
                                        >
                                            {i18n.clearCoordinatesToUseDefault}
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Dialog
                            onClose={() => this.setState({ openModalCheck: false })}
                            open={openModalCheck}
                        >
                            <DialogTitle>{i18n.sureDeleteCoordinates}</DialogTitle>
                            <DialogActions>
                                <Button onClick={() => this.setState({ openModalCheck: false })} variant='outlined' >
                                    {i18n.close}
                                </Button>
                                <Button onClick={() => this.removeCoordinates()} variant='contained' color='primary'>
                                    {i18n.validate}
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </Card>
                ) : (
                    <ProgressBar />
                )
            default:
                return <UserDetailForm userLogin={this.props.userLogin} />
        }
    }

    render() {
        return this.getPanel()
    }
}

UserDetailPanel.propTypes = {
    user: PropTypes.instanceOf(DtoUser),
    userSettings: PropTypes.arrayOf(PropTypes.instanceOf(SettingDto)),
    userStations: PropTypes.arrayOf(PropTypes.instanceOf(DtoUserStation)),
    userHabilitations: PropTypes.arrayOf(PropTypes.instanceOf(DtoUserHabilitation)),
    contacts: PropTypes.arrayOf(PropTypes.instanceOf(ContactDto)),
    saveSettings: PropTypes.func,
    resetUserSettings: PropTypes.func,
    view: PropTypes.string,
    userLogin: PropTypes.string,
}

const mapStateToProps = (store) => {
    return {
        user: store.UserReducer.user,
        userStations: store.UserReducer.userStations,
        userHabilitations: store.UserReducer.userHabilitations,
        contacts: store.ContactReducer.contacts,
        userSettings: store.AdministrationReducer.userSettings,
    }
}

const mapDispatchToProps = {
    saveSettings: AccountAction.updateSettings,
    resetUserSettings: AdministrationAction.resetUserSettings,
}

export default connect(mapStateToProps, mapDispatchToProps)(UserDetailPanel)