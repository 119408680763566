'use strict'

import KeyFigureDto from '../../administration/components/user/dto/KeyFigureDto'
import {
    RECEIVE_ACCOUNT,
    RECEIVE_ACCOUNT_HABILITATION, RECEIVE_ACCOUNT_STATIONS, RECEIVE_ACCOUNT_STATION_STATISTICS, RECEIVE_ACCOUNT_STATISTICS,
    RECEIVE_ACCOUNT_USAGE_STATISTICS,
    RECEIVE_ACCOUNT_USER_KEYFIGURES,
    RECEIVE_ACCOUNT_USER_SETTINGS,
    SAVED_USER,
} from '../constants/AccountConstants'
import DtoAccountHabilitation from '../dto/DtoAccountHabilitation'
import DtoAccountSettings from '../dto/DtoAccountSettings'
import DtoAccountStation from '../dto/DtoAccountStation'
import DtoAccountStationStatistic from '../dto/DtoAccountStationStatistic'
import DtoAccountStatistic from '../dto/DtoAccountStatistic'
import DtoAccountUsageStatistic from '../dto/DtoAccountUsageStatistic'
import User from '../dto/User'

export const store = {
    accountUser: new User({}),
    accountHabilitations: [],
    accountUserKeyFigures: [],
    accountUserSettings: [],
    accountStatistics: [],
    accountStationStatistics: [],
    accountUsageStatistics: [],
    accountStations: [],
}

export function AccountReducer(state = {}, action) {
    switch (action.type) {
        case RECEIVE_ACCOUNT:
            return {
                ...state,
                accountUser: new User(action.accountUser),
            }
        case RECEIVE_ACCOUNT_HABILITATION:
            return {
                ...state,
                accountHabilitations: action.accountHabilitations.map(el => new DtoAccountHabilitation(el)),
            }
        case RECEIVE_ACCOUNT_USER_KEYFIGURES:
            return {
                ...state,
                accountUserKeyFigures: action.accountUserKeyFigures.map(el => new KeyFigureDto(el)),
            }
        case RECEIVE_ACCOUNT_USER_SETTINGS:
            return {
                ...state,
                accountUserSettings: action.accountUserSettings.map(el => new DtoAccountSettings(el)),
            }
        case RECEIVE_ACCOUNT_STATISTICS:
            return {
                ...state,
                accountStatistics: action.accountStatistics.map(el => new DtoAccountStatistic(el)),
            }
        case RECEIVE_ACCOUNT_STATION_STATISTICS:
            return {
                ...state,
                accountStationStatistics: action.accountStationStatistics.map(el => new DtoAccountStationStatistic(el)),
            }
        case RECEIVE_ACCOUNT_USAGE_STATISTICS:
            return {
                ...state,
                accountUsageStatistics: action.accountUsageStatistics.map(el => new DtoAccountUsageStatistic(el)),
            }
        case RECEIVE_ACCOUNT_STATIONS:
            return {
                ...state,
                accountStations: action.accountStations.map(el => new DtoAccountStation(el)),
            }
        case SAVED_USER:
            return {
                ...state,
                user: action.user,
            }
        default:
            return state
    }
}
