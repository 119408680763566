import React, { useMemo, useState } from 'react'
import logoSandre from 'assets/pictures/logo_sandre.png'
import SimpleDatePicker from 'components/forms/SimpleDatePicker'
import ApplicationConf from 'conf/ApplicationConf'
import { push } from '@lagunovsky/redux-react-router'
import HomeAction from 'home/actions/HomeAction'
import 'moment/locale/fr'
import { useEffect } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import ToastrAction from 'toastr/actions/ToastrAction'
import useActions from 'utils/customHook/useActions'
import { v4 as uuidv4 } from 'uuid'
import Input from '../../../../components/forms/Input'
import NumberField from '../../../../components/forms/NumberField'
import Select from '../../../../components/forms/Select'
import Textarea from '../../../../components/forms/Textarea'
import SieauAction from '../../../../components/sieau/SieauAction'
import {
    bdlisaHydrogeologicalEntityLink,
    bdlisaHydrogeologicalUnitLink,
    sandreHydrogeologicalEntityDefaultLink,
    sandreHydrogeologicalEntityLink,
} from '../../../../conf/SieauConstants'
import {
    PATH_REFERENCIAL,
    PATH_REFERENCIAL_HYDROGEOLOGICAL_ENTITY,
    PATH_REFERENCIAL_HYDROGEOLOGICAL_ENTITY_LINK,
    PATH_REFERENCIAL_HYDROGEOLOGICAL_ENTITY_NEW,
} from '../../../../home/constants/RouteConstants'
import { getUser } from '../../../../utils/SettingUtils'
import ReferencialAction from '../../../action/ReferencialAction'
import { getReferencialComponent } from '../../../util/ReferencialUtils'
import DeleteModal from '../../DeleteModal'
import ReplaceModal from '../../ReplaceModal'
import HydrogeologicalEntityAction from '../actions/HydrogeologicalEntityAction'
import HydrogeologicalEntityDto from '../dto/HydrogeologicalEntityDto'
import useTitle from 'utils/customHook/useTitle'
import useSandreList from 'utils/customHook/useSandreList'
import { SANDRE } from 'referencial/constants/ReferencialConstants'
import { useParams } from 'react-router'

const HydrogeologicalEntityApp = () => {
    const { code } = useParams()

    const {
        hydrogeologicalEntityProps,
        hydrogeologicalEntityState,
        hydrogeologicalEntityStructure,
        hydrogeologicalEntityType,
        referencialStatus,
        hydrogeologicalEntities,
        sandreCodes,
    } = useSelector(store => ({
        hydrogeologicalEntityProps: store.HydrogeologicalEntityReducer.hydrogeologicalEntity,
        hydrogeologicalEntityState: store.HydrogeologicalEntityReducer.hydrogeologicalEntityState,
        hydrogeologicalEntityStructure: store.HydrogeologicalEntityReducer.hydrogeologicalEntityStructure,
        hydrogeologicalEntityType: store.HydrogeologicalEntityReducer.hydrogeologicalEntityType,
        referencialStatus: store.ReferencialReducer.referencialStatus,
        hydrogeologicalEntities: store.HydrogeologicalEntityReducer.hydrogeologicalEntities,
        sandreCodes: store.ReferencialReducer.sandreCodes,
    }), shallowEqual)

    const [hydrogeologicalEntity, setHydrogeologicalEntity] = useState(new HydrogeologicalEntityDto({}))
    const [isEditMode, setIsEditMode] = useState(false)

    const dispatch = useDispatch()

    useEffect(() => () => dispatch(HydrogeologicalEntityAction.reset()), [])

    useEffect(() => setHydrogeologicalEntity(hydrogeologicalEntityProps), [hydrogeologicalEntityProps])

    useEffect(() => {
        if (code !== 'new') {
            dispatch(HydrogeologicalEntityAction.fetchHydrogeologicalEntity(code))
            setIsEditMode(false)
        } else {
            setIsEditMode(true)
        }
        if (!hydrogeologicalEntityStructure.length) {
            dispatch(HydrogeologicalEntityAction.fetchHydrogeologicalEntityStructure())
        }
        if (!hydrogeologicalEntityState.length) {
            dispatch(HydrogeologicalEntityAction.fetchHydrogeologicalEntityState())
        }
        if (!hydrogeologicalEntityType.length) {
            dispatch(HydrogeologicalEntityAction.fetchHydrogeologicalEntityType())
        }
        if (!referencialStatus.length) {
            dispatch(ReferencialAction.fetchReferencialStatus())
        }
        if (!sandreCodes.length) {
            dispatch(ReferencialAction.fetchSandreCodes())
        }
        dispatch(HomeAction.setHelpLink('', ''))
    }, [code])

    const getHydrogeologicalEntityLinks = () => {
        return [
            {
                href: hydrogeologicalEntityProps.sandreCode ? sandreHydrogeologicalEntityLink + hydrogeologicalEntityProps.sandreCode : sandreHydrogeologicalEntityDefaultLink,
                img: logoSandre,
                label: i18n.sandre.toUpperCase(),
            },
            {
                href: hydrogeologicalEntityProps.sandreCode ? bdlisaHydrogeologicalEntityLink + hydrogeologicalEntityProps.sandreCode : sandreHydrogeologicalEntityDefaultLink,
                img: logoSandre,
                label: 'CARTO BDLISA',
            },
            {
                href: hydrogeologicalEntityProps.sandreCode ? bdlisaHydrogeologicalUnitLink + hydrogeologicalEntityProps.sandreCode : sandreHydrogeologicalEntityDefaultLink,
                img: logoSandre,
                label: 'BDLISA',
            },
        ]
    }

    const isConsultant = useMemo(() => getUser().consultant === '1', [])

    useActions(() => {
        if (isConsultant) {
            return {}
        }
        if (code === 'new' && (!hydrogeologicalEntityProps || !hydrogeologicalEntityProps.code)) {
            return {
                save: () => {
                    const existCode = hydrogeologicalEntities.find(p => p.code === hydrogeologicalEntity.code)
                    if (existCode) {
                        dispatch(ToastrAction.error(i18n.codeAlreadyExists))
                    } else if (!hydrogeologicalEntity.code) {
                        dispatch(ToastrAction.error(i18n.thanksToEnterCode))
                    } else {
                        dispatch(HydrogeologicalEntityAction.createHydrogeologicalEntity(hydrogeologicalEntity))
                        setIsEditMode(false)
                    }
                },
                cancel: () => {
                    dispatch(push(PATH_REFERENCIAL_HYDROGEOLOGICAL_ENTITY))
                    setIsEditMode(false)
                },
            }
        }
        if (isEditMode) {
            return {
                save: () => {
                    dispatch(HydrogeologicalEntityAction.updateHydrogeologicalEntity(hydrogeologicalEntity, hydrogeologicalEntity.code))
                    setIsEditMode(false)
                },
                cancel: () => {
                    setHydrogeologicalEntity(hydrogeologicalEntityProps)
                    setIsEditMode(false)
                },
            }
        }
        return {
            edit: () => {
                setIsEditMode(true)
            },
            deleteCheck: () => {
                dispatch(ReferencialAction.fetchCheckDelete(ApplicationConf.referencial.hydrogeologicalEntityCountDelete(code)))
                const id = uuidv4()
                const popup = {
                    id,
                    content: <DeleteModal
                        onDelete={ () => dispatch(HydrogeologicalEntityAction.deleteHydrogeologicalEntity(hydrogeologicalEntity.code)) }
                    />,
                    dismissible: false,
                }
                dispatch(SieauAction.setPopup(popup))
            },
            replace: () => {
                dispatch(ReferencialAction.fetchCheckDelete(ApplicationConf.referencial.hydrogeologicalEntityCountDelete(code)))
                const id = uuidv4()
                const popup = {
                    id,
                    content: <ReplaceModal
                        elements={ hydrogeologicalEntities }
                        title={ `${i18n.hydrogeologicalEntity} [${hydrogeologicalEntity.code}]` }
                        label={ i18n.hydrogeologicalEntity }
                        onReplace={ newCode => dispatch(HydrogeologicalEntityAction.replaceHydrogeologicalEntity(hydrogeologicalEntity.code, newCode.code)) }
                    />,
                    dismissible: false,
                }
                dispatch(SieauAction.setPopup(popup))
            },
            links: getHydrogeologicalEntityLinks(),
        }
    }, [isConsultant, code, hydrogeologicalEntityProps, hydrogeologicalEntity, isEditMode])

    useTitle(() => {
        if (code === 'new') {
            return [
                {
                    title: i18n.referencials,
                    href: PATH_REFERENCIAL,
                },
                {
                    title: i18n.hydrogeologicalEntities,
                    href: PATH_REFERENCIAL_HYDROGEOLOGICAL_ENTITY,
                },
                {
                    title: i18n.new,
                    href: PATH_REFERENCIAL_HYDROGEOLOGICAL_ENTITY_NEW,
                },
            ]
        }
        return [
            {
                title: i18n.referencials,
                href: PATH_REFERENCIAL,
            },
            {
                title: i18n.hydrogeologicalEntities,
                href: PATH_REFERENCIAL_HYDROGEOLOGICAL_ENTITY,
            },
            {
                title: code + (hydrogeologicalEntityProps.name ? ` - ${hydrogeologicalEntityProps.name}` : ''),
                href: PATH_REFERENCIAL_HYDROGEOLOGICAL_ENTITY_LINK + code,
            },
        ]
    }, [code, hydrogeologicalEntityProps])

    const natures = useSandreList(SANDRE.HYDROGEOLOGICAL_ENTITY_NATURE)

    const disabled = useMemo(() => ({
        active: isEditMode,
        disabled: !isEditMode,
    }), [isEditMode])

    const activeIcon = useMemo(() => hydrogeologicalEntityProps.status === 3 ? <i className='material-icons'>lock</i> : null, [hydrogeologicalEntityProps])

    return (
        <div className='row no-margin'>
            <div className='col s12'>
                <div className='card margin-top-0-75-rem'>
                    <div className='card-content'>
                        <div className='row no-margin padding-top-3-px'>
                            <div className='row no-margin'>
                                <div className='col s12 m2 l2' />
                                <div className='col s12 m8 l8'>
                                    <Input
                                        col={ 2 }
                                        title={ i18n.code }
                                        value={ hydrogeologicalEntity.code }
                                        onChange={ newCode => setHydrogeologicalEntity({ ...hydrogeologicalEntity, code: parseInt(newCode) }) }
                                        disabled={code !== 'new'}
                                        obligatory
                                    />
                                    <Input
                                        col={ 8 }
                                        title={ i18n.name }
                                        value={ hydrogeologicalEntity.name }
                                        onChange={ name => setHydrogeologicalEntity({ ...hydrogeologicalEntity, name }) }
                                        maxLength={ 200 }
                                        { ...disabled }
                                        id='hydrogeologicalEntity_name'
                                    />
                                    <Input
                                        col={ 2 }
                                        title={ i18n.sandreCode }
                                        value={ hydrogeologicalEntity.sandreCode }
                                        onChange={ sandreCode => setHydrogeologicalEntity({ ...hydrogeologicalEntity, sandreCode }) }
                                        maxLength={ 17 }
                                        { ...disabled }
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='row no-margin'>
                            <div className='col s12 m2 l2' />
                            <div className='col s12 m8 l8'>
                                <div className='row no-margin padding-top-3-px'>
                                    <SimpleDatePicker
                                        onChange={ creationDate => setHydrogeologicalEntity({ ...hydrogeologicalEntity, creationDate }) }
                                        id='creationDate'
                                        title={ i18n.creationDate }
                                        value={ hydrogeologicalEntity.creationDate }
                                        col={ 3 }
                                        { ...disabled }
                                        endDate={ hydrogeologicalEntity.updateDate }
                                    />
                                    <SimpleDatePicker
                                        onChange={ updateDate => setHydrogeologicalEntity({ ...hydrogeologicalEntity, updateDate }) }
                                        id='updateDate'
                                        title={ i18n.modification }
                                        value={ hydrogeologicalEntity.updateDate }
                                        col={ 3 }
                                        { ...disabled }
                                        startDate={ hydrogeologicalEntity.creationDate }
                                    />
                                    <Select
                                        options={ referencialStatus }
                                        label={ i18n.status }
                                        col={ 6 }
                                        onChange={ status => setHydrogeologicalEntity({ ...hydrogeologicalEntity, status }) }
                                        value={ `${hydrogeologicalEntity.status}` }
                                        nullLabel='&nbsp;'
                                        { ...disabled }
                                    />
                                    <div className='col s1 padding-top-10-px'>
                                        { activeIcon }
                                    </div>
                                </div>
                                <div className='row no-margin padding-top-3-px'>
                                    <Input
                                        col={ 12 }
                                        title={ i18n.author }
                                        value={ hydrogeologicalEntity.author }
                                        onChange={ author => setHydrogeologicalEntity({ ...hydrogeologicalEntity, author }) }
                                        maxLength={ 50 }
                                        { ...disabled }
                                    />
                                </div>
                                <div className='row no-margin padding-top-3-px'>
                                    <Select
                                        options={ natures }
                                        label={ i18n.nature }
                                        col={ 6 }
                                        onChange={ newNature => setHydrogeologicalEntity({ ...hydrogeologicalEntity, nature: newNature }) }
                                        value={ hydrogeologicalEntity.nature }
                                        nullLabel='&nbsp;'
                                        keyLabel='name'
                                        keyValue='code'
                                        { ...disabled }
                                    />
                                    <Select
                                        options={ getReferencialComponent(hydrogeologicalEntityState) }
                                        label={ i18n.state }
                                        col={ 6 }
                                        onChange={ state => setHydrogeologicalEntity({ ...hydrogeologicalEntity, state }) }
                                        value={ hydrogeologicalEntity.state }
                                        nullLabel='&nbsp;'
                                        { ...disabled }
                                    />
                                </div>
                                <div className='row no-margin padding-top-3-px'>
                                    <Select
                                        options={ getReferencialComponent(hydrogeologicalEntityStructure) }
                                        label={ i18n.structure }
                                        col={ 6 }
                                        onChange={ structure => setHydrogeologicalEntity({ ...hydrogeologicalEntity, structure }) }
                                        value={ hydrogeologicalEntity.structure }
                                        nullLabel='&nbsp;'
                                        { ...disabled }
                                    />
                                    <Select
                                        options={ getReferencialComponent(hydrogeologicalEntityType) }
                                        label={ i18n.type }
                                        col={ 6 }
                                        onChange={ dominantType => setHydrogeologicalEntity({ ...hydrogeologicalEntity, dominantType }) }
                                        value={ hydrogeologicalEntity.dominantType }
                                        nullLabel='&nbsp;'
                                        { ...disabled }
                                    />
                                </div>
                                <div className='row no-margin padding-top-3-px'>
                                    <Textarea
                                        col={ 12 }
                                        title={ i18n.generalities }
                                        value={ hydrogeologicalEntity.generality }
                                        onChange={ generality => setHydrogeologicalEntity({ ...hydrogeologicalEntity, generality }) }
                                        maxLength={ 255 }
                                        { ...disabled }
                                    />
                                </div>
                                <div className='row no-margin padding-top-7-px'>
                                    <Input
                                        col={ 6 }
                                        title={ i18n.denomination }
                                        value={ hydrogeologicalEntity.denomination }
                                        onChange={ denomination => setHydrogeologicalEntity({ ...hydrogeologicalEntity, denomination }) }
                                        maxLength={ 40 }
                                        { ...disabled }
                                    />
                                    <Input
                                        col={ 6 }
                                        title={ i18n.stratiLocalName }
                                        value={ hydrogeologicalEntity.stratiLocalName }
                                        onChange={ stratiLocalName => setHydrogeologicalEntity({ ...hydrogeologicalEntity, stratiLocalName }) }
                                        maxLength={ 50 }
                                        { ...disabled }
                                    />
                                </div>
                                <div className='row no-margin padding-top-3-px'>
                                    <NumberField
                                        col={ 6 }
                                        title={ i18n.poolType }
                                        value={ hydrogeologicalEntity.poolType }
                                        onChange={ poolType => setHydrogeologicalEntity({ ...hydrogeologicalEntity, poolType }) }
                                        { ...disabled }
                                        floatValue
                                    />
                                    <NumberField
                                        col={ 6 }
                                        title={ i18n.area }
                                        value={ hydrogeologicalEntity.area }
                                        onChange={ area => setHydrogeologicalEntity({ ...hydrogeologicalEntity, area }) }
                                        { ...disabled }
                                        floatValue
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='row no-margin'>
                            < div className='col s12 m2 l2' />
                            <div className='col s12 m8 l8'>
                                <div className='row no-margin padding-top-3-px'>
                                    <Textarea
                                        col={ 12 }
                                        title={ i18n.comment }
                                        value={ hydrogeologicalEntity.comment }
                                        onChange={ comment => setHydrogeologicalEntity({ ...hydrogeologicalEntity, comment }) }
                                        { ...disabled }
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HydrogeologicalEntityApp
