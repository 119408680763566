export default class DtoSandreCode {
    constructor(object) {
        this.field = object.field
        this.code = object.code
        this.name = object.name
        this.mnemonique = object.mnemonique
        this.reference = object.reference
        this.comment = object.comment
        this.status = object.status
        this.creation = object.creation
        this.updateDate = object.updateDate
        this.author = object.author
        this.labelWithReference = `${object.name} [${object.reference || ''}]`
        this.referenceWithLabel = `[${object.reference || ''}] ${object.name} `
    }
}