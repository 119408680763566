import React, { useState } from 'react'
import { Button, Divider, Grid } from '@mui/material'
import PropTypes from 'prop-types'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import Card from '../../../components/card/Card'
import Input from '../../../components/forms/Input'
import Select from '../../../components/forms/Select'
import Row from '../../../components/react/Row'
import DtoSandreCode from '../../../referencial/dto/DtoSandreCode'
import { getSandreList } from 'utils/StoreUtils'
import AdministrationAction from 'administration/actions/AdministrationAction'
import useApplicationSetting from 'utils/customHook/useApplicationSetting'

const emptyFilter = {
    searchValue: '',
    resourceType: undefined,
}

const RestrictionsFilterPanel = ({ onValidate = () => {} }) => {
    const [tmpFilter, setTmpFilter] = useState(emptyFilter)

    const {
        sandreCodes,
    } = useSelector(store => ({
        sandreCodes: store.ReferencialReducer.sandreCodes,
    }), shallowEqual)

    const agriTypeRestriction = useApplicationSetting('agriTypeRestriction', setting => setting ? parseInt(setting) : 1)

    const dispatch = useDispatch()

    const resetFilters = () => {
        setTmpFilter(emptyFilter)
        onValidate(emptyFilter)
    }
    const { searchValue, resourceType } = tmpFilter

    return (
        <Card round>
            <Row className='padding-top-1'>
                <Select
                    col={3}
                    label={i18n.division}
                    value={agriTypeRestriction}
                    options={[{
                        value: 1,
                        label: i18n.droughtSectors,
                    }, {
                        value: 2,
                        label: i18n.managementUnits,
                    }]}
                    onChange={(v) => dispatch(AdministrationAction.updateSieauParameters([{ parameter: 'agriTypeRestriction', value: `${v}` }]))}
                    noNullValue
                />
            </Row>
            <Divider />
            <Row className='padding-top-1'>
                <Input
                    col={3}
                    containerClassName='no-margin'
                    title={ i18n.search }
                    value={ searchValue }
                    onChange={(v) => setTmpFilter({ ...tmpFilter, searchValue: v })}
                    onEnterKeyPress={() => onValidate(tmpFilter)}
                />
                <Select
                    col={3}
                    label={i18n.type}
                    value={resourceType}
                    options={getSandreList(sandreCodes, 'PREL_AGRI.TYPE_PRELEVEMENT')}
                    keyLabel='labelWithCode'
                    onChange={(v) => setTmpFilter({ ...tmpFilter, resourceType: v })}
                    clearFunction
                />
            </Row>
            <Grid container className='padding-1 padding-top-0' justifyContent='flex-end'>
                <Grid item>
                    <Button variant='outlined' onClick={resetFilters}>
                        {i18n.reinit}
                    </Button>
                </Grid>
                <Grid item className='padding-left-1'>
                    <Button variant='contained' onClick={() => onValidate(tmpFilter)}>
                        {i18n.search}
                    </Button>
                </Grid>
            </Grid>
        </Card>
    )
}

RestrictionsFilterPanel.propTypes = {
    onValidate: PropTypes.func.isRequired,
    sandreCodes: PropTypes.arrayOf(PropTypes.instanceOf(DtoSandreCode)),
}

export default RestrictionsFilterPanel