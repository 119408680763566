import PropTypes from 'prop-types'
import React, { Component } from 'react'
import i18n from 'simple-react-i18n'
import Icon from '../../../components/icon/Icon'
import SectionListPaginate from '../../../components/list/section/SectionListPaginate'
import typeList from '../../../utils/mapUtils/SiteTypes'
import { hasValue } from '../../../utils/NumberUtil'
import { DESCRIPTION } from '../../constants/AlertConstants'
import { ALL_THEME } from '../../constants/ThemeConstants'

class ThemesMapTab extends Component {
    componentDidUpdate = () => {
        $('.tooltipped').tooltip()
    }

    getStationContent(station) {
        const {
            onHover = () => { },
        } = this.props
        return (
            <div className='row' style={ { padding: '1%', marginBottom: '0px' } }
                onMouseOver={ () => onHover(station, true) } onMouseOut={ () => onHover(station, false) }
            >
                <div className='row no-margin' style={ { maxHeight: '50px !important' } }>
                    <h6 className='col s11'>{ station.code } - { station.name }</h6>
                </div>
                <div className='event-card'>
                    <div className='row no-margin no-padding event-footer'>
                        <div className='left margin-left-1'>
                            {
                                hasValue(station.jobExecutionId) && (
                                    <Icon
                                        size='small'
                                        icon='wifi'
                                        tooltip={ 'Open Data' }
                                    />
                                )
                            }
                        </div>
                        <div className='right'>
                            <Icon
                                size='small'
                                icon='info'
                                onClick={ () => this.props.onClick(station, DESCRIPTION) }
                                tooltip={ i18n.description }
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    getUnderList(themeValue) {
        const size = themeValue.data.length
        const cards = themeValue.data.map(e => ({
            content: this.getStationContent(e),
        }))
        return (
            <div>
                {
                    size > 0 && (
                        <SectionListPaginate
                            title={
                                <div className='valign-wrapper'>
                                    <img src={ typeList[`${themeValue.station.typeName}`].img } style={ { height: '40px' } } />
                                    <h6 style={ { paddingLeft: '10px', color: 'black' } }>{ i18n[`${themeValue.station.label}`] }</h6>
                                </div> }
                            number={ size }
                            textColor={ 'black' }
                            color={ 'white' }
                            data={ cards }
                            loadOnClick
                        />
                    )
                }
            </div>
        )
    }

    getGroupByTheme() {
        const allData = this.props.allStation
        return allData.map(d => {
            const allLength = d.dataList.map(dl => {
                return dl.data.length
            })
            const reducer = (accumulator, currentValue) => accumulator + currentValue
            const size = d.dataList.length
            const content = d.dataList.map(dl => ({
                content: this.getUnderList(dl),
            }))
            return (
                <div>
                    { allLength.reduce(reducer) > 0 && (
                        <div>
                            <SectionListPaginate
                                title={
                                    <div className='valign-wrapper'>
                                        <h6 style={ { paddingLeft: '10px', color: 'white' } }>{ i18n[`${ALL_THEME[`${d.name}`].label}`] }</h6>
                                    </div> }
                                textColor={ 'white' }
                                number={ size }
                                color={ ALL_THEME[`${d.name}`].color }
                                data={ content }
                                onCheck={ this.props.onCheck }
                                checked={ this.props.selectedThemes.indexOf(d.name) !== -1 }
                                themeName={ d.name }
                                loadOnClick
                            />
                        </div>
                    )
                    }
                </div>
            )
        })
    }

    render = () => {
        const data = this.getGroupByTheme()
        return (
            <div>
                { data }
            </div>
        )
    }
}

ThemesMapTab.propTypes = {
    allStation: PropTypes.array,
    selectedThemes: PropTypes.array,
    onHover: PropTypes.func,
    onClick: PropTypes.func,
    onCheck: PropTypes.func,
}

export default ThemesMapTab