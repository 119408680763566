import DtoQualityThreshold from './DtoQualityThreshold'

export default class DtoQualityThresholds {
    constructor(obj) {
        this.thresholdCode = obj.thresholdCode
        this.thresholdType = obj.thresholdType
        this.thresholds = obj.thresholds.map(t => new DtoQualityThreshold(t))
        this.error = obj.error
        this.errorMessage = obj.errorMessage
    }
}

