import { groupBy, minBy, range } from 'lodash'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { i18nExist } from 'utils/StringUtil'
import Axis from '../../../components/echart/Axis'
import EChart from '../../../components/echart/EChart'
import Bar from '../../../components/echart/series/Bar'
import StackedSeries from '../../../components/echart/series/StackedSeries'
import { getColor } from '../../../utils/ColorUtil'
import { getYear } from '../../../utils/DateUtil'
import { getNumberFormat } from '../../../utils/NumberUtil'
import { getPropType } from '../../../utils/StoreUtils'
import { EVENT_TYPES } from '../../constants/EventsConstants'

class EventsTypeVerticalBarChart extends Component {
    render() {
        const { events, eventsTypes, intType } = this.props
        const minDate = minBy(events, 'date')
        const minYear = minDate && (getYear(minDate.date) < getYear(moment()) - 10) ? getYear(minDate.date) : getYear(moment()) - 10
        const yearRange = range(minYear, getYear(moment()) + 1)

        const groupByType = groupBy(events, 'eventType')

        const series = Object.keys(groupByType).map(keyType => {
            const key = intType ? parseInt(keyType) : keyType
            const eventType = (eventsTypes || EVENT_TYPES).find(e => e.code === key) || { label: 'unknownType', color: 'GREY' }
            const groupedEvents = groupBy(groupByType[key], e => getYear(e.date))

            const data = yearRange.map(year => {
                const value = groupedEvents[year] ? groupedEvents[year].length : 0
                return {
                    name: year,
                    value,
                }
            })

            return Bar({
                data,
                name: i18nExist(eventType.label) || eventType.label,
                color: getColor(eventType.color) || eventType.color,
            })
        })


        const options = {
            series: [StackedSeries(series)],
            tooltip: {
                trigger: 'axis',
            },
            yAxis: [Axis({
                type: 'value',
                axisLabel: {
                    formatter: getNumberFormat,
                },
            })],
            xAxis: [Axis({
                type: 'category',
                data: yearRange,
                axisLabel: {
                    fontSize: 10,
                },
            })],
            height: 200,
            gridHeight: 180,
        }
        return (
            <div className='row no-margin'>
                <EChart options={ options } />
            </div>
        )
    }
}

EventsTypeVerticalBarChart.propTypes = {
    events: getPropType('stationEvents'),
    eventsTypes: PropTypes.arrayOf(PropTypes.shape({
        code: PropTypes.string,
        id: PropTypes.number,
        label: PropTypes.string,
        color: PropTypes.string,
        icon: PropTypes.string,
    })),
    intType: PropTypes.bool,
}

export default EventsTypeVerticalBarChart
