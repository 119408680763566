import PropTypes from 'prop-types'
import React from 'react'
import Icon from '../icon/Icon'

const IconCard = ({
    children = undefined,
    children2 = undefined,
    color = 'white',
    className = '',
    icon = '',
    icon2 = '',
    iconColor = 'white',
    iconClassName = '',
    button = '',
    buttonColor = 'white',
    style = {},
    onClick = () => { },
    onClickButton = () => { },
}) => (
    <div className={`${color} ${className || ''}`} style={{ padding: '5px', margin: '2px', borderRadius: '10px', ...style }}>
        <div className='row no-margin'>
            <div onClick={onClick}>
                {
                    icon ? (
                        <div>
                            <div className={`col ${children2 ? 's11' : 's12'} valign-wrapper no-padding`}>
                                <div className='col s1'>
                                    <Icon
                                        icon={icon}
                                        className={iconClassName}
                                        style={{ color: iconColor }}
                                    />
                                </div>
                                <div className={`${color !== 'white' ? 'white-text' : ''} col ${icon2 ? 's10' : 's11'} no-padding`}>
                                    {children}
                                </div>
                                {
                                    icon2 && (
                                        <div className='col s1'>
                                            <Icon
                                                icon={icon2}
                                                className={iconClassName}
                                                style={{ color: iconColor }}
                                            />
                                        </div>
                                    )
                                }
                            </div>
                            { children2 && <div className='col s1'>{ children2 }</div> }
                        </div>
                    ) : children
                }
            </div>
            {
                !!button && (
                    <div onClick={onClickButton}>
                        <div className='right'>
                            <Icon icon={button} style={{ color: buttonColor }} />
                        </div>
                    </div>
                )
            }
        </div>
    </div>
)

IconCard.propTypes = {
    children: PropTypes.element.isRequired,
    children2: PropTypes.element,
    icon: PropTypes.string,
    iconColor: PropTypes.string,
    icon2: PropTypes.string,
    iconClassName: PropTypes.string,
    button: PropTypes.string,
    buttonColor: PropTypes.string,
    onClick: PropTypes.func,
    onClickButton: PropTypes.func,
    color: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.object,
}

export default IconCard
