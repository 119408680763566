import { getOptions } from '../EChartUtils'

class _Sunburst {
    constructor(obj) {
        this.obj = obj
    }

    getDefaultOptions = () => {
        return {
            name: 'sunburst chart',
            type: 'sunburst',
            data: [],
            radius: [0, '100%'],
        }
    }

    getJson = () => {
        return getOptions(this.obj, this.getDefaultOptions(), {
            color: () => (
                {
                    itemStyle: {
                        normal: {
                            color: this.obj.color,
                        },
                    },
                }),
        })
    }
}

export default function Sunburst(args) {
    return new _Sunburst(args)
}


