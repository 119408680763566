import Checkbox from 'components/forms/Checkbox'
import Input from 'components/forms/Input'
import NumberField from 'components/forms/NumberField'
import { sortBy } from 'lodash'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import DtoCulture from 'referencial/components/cultures/dto/DtoCulture'
import DtoCultureVentilation from 'referencial/components/cultures/dto/DtoCultureVentilation'
import DtoCulturesFamily from 'referencial/components/culturesFamilies/dto/DtoCulturesFamily'
import DtoManagementUnit from 'referencial/components/managementUnit/dto/DtoManagementUnit'
import DtoSandreCode from 'referencial/dto/DtoSandreCode'
import i18n from 'simple-react-i18n'
import { hasValue } from 'utils/NumberUtil'
import Select from '../../../components/forms/Select'
import { getSandreList } from 'utils/StoreUtils'

const CultureVentilationPanel = ({ cultureVentilation = {}, disabled, onChange = () => {} }) => {
    const {
        managementUnits,
        culturesFamilies,
        cultures,
        sandreCodes,
    } = useSelector(store => ({
        cultures: store.CultureReducer.cultures,
        culturesFamilies: store.CulturesFamilyReducer.culturesFamilies,
        managementUnits: store.ManagementUnitReducer.managementUnits,
        sandreCodes: store.ReferencialReducer.sandreCodes,
    }), shallowEqual)

    const initalCultureFamily = cultureVentilation.cultureId ? (cultures.find(c => c.id === cultureVentilation.cultureId) || {}).idFamille : null
    const initialUGE = cultureVentilation.managementCode ? (managementUnits.find(mu => mu.managementCode === cultureVentilation.managementCode) || {}).parent : null
    const [cultureFamily, setCultureFamily] = useState(initalCultureFamily)
    const [managementUnit, setManagementUnit] = useState(initialUGE)

    const getValue = (value) => {
        return hasValue(value) ? value : 0
    }

    const getTotal = (cultureVent) => {
        return getValue(cultureVent.month01) + getValue(cultureVent.month02) + getValue(cultureVent.month03) + getValue(cultureVent.month04) +
        getValue(cultureVent.month05) + getValue(cultureVent.month06) + getValue(cultureVent.month07) + getValue(cultureVent.month08) +
        getValue(cultureVent.month09) + getValue(cultureVent.month10) + getValue(cultureVent.month11) + getValue(cultureVent.month12)
    }

    const onChangeValue = (obj) => {
        onChange({
            ...cultureVentilation,
            ...obj,
            total: getTotal({ ...cultureVentilation, ...obj }),
        })
    }

    const total = getTotal(cultureVentilation)

    return (
        <div className='row no-margin padding-top-1'>
            <div className='row no-margin'>
                <Select
                    col={4}
                    label={i18n.culturesFamily}
                    value={cultureFamily}
                    options={culturesFamilies}
                    onChange={value => setCultureFamily(value)}
                    keyValue='id'
                    obligatory
                    clearFunction
                />
                <Select
                    col={4}
                    label={i18n.culture}
                    value={cultureVentilation.cultureId}
                    options={cultures.filter((c) => c.idFamille === cultureFamily)}
                    onChange={value => onChangeValue({ cultureId: value })}
                    keyValue='id'
                    obligatory
                    clearFunction
                />
                <NumberField
                    col={2}
                    title={i18n.year}
                    value={cultureVentilation.year}
                    onChange={value => onChangeValue({ year: value })}
                    disabled={disabled}
                    min={0}
                    obligatory
                />
                <Select
                    col={2}
                    label={i18n.status}
                    value={cultureVentilation.status}
                    options={getSandreList(sandreCodes, 'USAGES.STATUT')}
                    onChange={value => onChangeValue({ status: value })}
                    keyValue='code'
                    clearFunction
                />
            </div>
            <div className='row no-margin padding-top-1'>
                <Select
                    col={4}
                    label={i18n.managementUnit}
                    value={managementUnit}
                    options={sortBy(managementUnits.filter((u) => !u.parent), 'id')}
                    onChange={value => setManagementUnit(value)}
                    keyValue='managementCode'
                    clearFunction
                />
                <Select
                    col={4}
                    label={i18n.subManagementUnit}
                    value={cultureVentilation.managementCode}
                    options={sortBy(managementUnits.filter((u) => u.parent && u.parent === managementUnit), 'id')}
                    onChange={value => onChangeValue({ managementCode: value })}
                    keyValue='managementCode'
                    clearFunction
                />
                <Select
                    id='climateSector'
                    col={4}
                    label={i18n.climateSector}
                    value={cultureVentilation.climateSector}
                    options={getSandreList(sandreCodes, 'PREL_AGRI.SECTEUR_CLIMATIQUE')}
                    onChange={value => onChangeValue({ climateSector: value })}
                    keyValue='code'
                    keyLabel='referenceWithLabel'
                    clearFunction
                />
            </div>
            <div className='row no-margin padding-top-1' style={{ display: 'flex', alignItems: 'center' }}>
                <Select
                    col={4}
                    label={i18n.mainIrrigationType}
                    value={cultureVentilation.mainIrrigationMode}
                    options={getSandreList(sandreCodes, 'IRRIGATION.CATEGORIES')}
                    onChange={value => onChangeValue({ mainIrrigationMode: value })}
                    keyValue='code'
                    clearFunction
                />
                <Select
                    col={4}
                    label={i18n.groundType}
                    value={cultureVentilation.groundType}
                    options={getSandreList(sandreCodes, 'USAGES.TYPES_SOLS')}
                    onChange={value => onChangeValue({ groundType: value })}
                    keyValue='code'
                    clearFunction
                />
                <Checkbox
                    col={4}
                    label={i18n.groundCriterias}
                    checked={cultureVentilation.groundCriterias}
                    onChange={value => onChangeValue({ groundCriterias: value })}
                    disabled={disabled}
                />
            </div>
            <div className='row no-margin padding-top-1'>
                <NumberField
                    col={4}
                    title={i18n.maxGlobalNeed}
                    value={cultureVentilation.requirement}
                    onChange={value => onChangeValue({ requirement: value })}
                    disabled={disabled}
                    min={0}
                />
                <NumberField
                    col={4}
                    title={i18n.lowWaterNeed}
                    value={cultureVentilation.irrigationNeed}
                    onChange={value => onChangeValue({ irrigationNeed: value })}
                    disabled={disabled}
                    min={0}
                />
            </div>
            <div className='row no-margin padding-top-1'>
                <NumberField
                    col={1}
                    title={i18n.januaryShort}
                    value={cultureVentilation.month01}
                    onChange={value => onChangeValue({ month01: value })}
                    disabled={disabled}
                    min={0}
                />
                <NumberField
                    col={1}
                    title={i18n.februaryShort}
                    value={cultureVentilation.month02}
                    onChange={value => onChangeValue({ month02: value })}
                    disabled={disabled}
                    min={0}
                />
                <NumberField
                    col={1}
                    title={i18n.marchShort}
                    value={cultureVentilation.month03}
                    onChange={value => onChangeValue({ month03: value })}
                    disabled={disabled}
                    min={0}
                />
                <NumberField
                    col={1}
                    title={i18n.aprilShort}
                    value={cultureVentilation.month04}
                    onChange={value => onChangeValue({ month04: value })}
                    disabled={disabled}
                    min={0}
                />
                <NumberField
                    col={1}
                    title={i18n.mayShort}
                    value={cultureVentilation.month05}
                    onChange={value => onChangeValue({ month05: value })}
                    disabled={disabled}
                    min={0}
                />
                <NumberField
                    col={1}
                    title={i18n.juneShort}
                    value={cultureVentilation.month06}
                    onChange={value => onChangeValue({ month06: value })}
                    disabled={disabled}
                    min={0}
                />
                <NumberField
                    col={1}
                    title={i18n.julyShort}
                    value={cultureVentilation.month07}
                    onChange={value => onChangeValue({ month07: value })}
                    disabled={disabled}
                    min={0}
                />
                <NumberField
                    col={1}
                    title={i18n.augustShort}
                    value={cultureVentilation.month08}
                    onChange={value => onChangeValue({ month08: value })}
                    disabled={disabled}
                    min={0}
                />
                <NumberField
                    col={1}
                    title={i18n.septemberShort}
                    value={cultureVentilation.month09}
                    onChange={value => onChangeValue({ month09: value })}
                    disabled={disabled}
                    min={0}
                />
                <NumberField
                    col={1}
                    title={i18n.octoberShort}
                    value={cultureVentilation.month10}
                    onChange={value => onChangeValue({ month10: value })}
                    disabled={disabled}
                    min={0}
                />
                <NumberField
                    col={1}
                    title={i18n.novemberShort}
                    value={cultureVentilation.month11}
                    onChange={value => onChangeValue({ month11: value })}
                    disabled={disabled}
                    min={0}
                />
                <NumberField
                    col={1}
                    title={i18n.decemberShort}
                    value={cultureVentilation.month12}
                    onChange={value => onChangeValue({ month12: value })}
                    disabled={disabled}
                    min={0}
                />
                <Input
                    col={1}
                    title={i18n.total}
                    className={total > 100 ? 'red' : ''}
                    containerClassName='right bold'
                    onChange={value => onChangeValue({ total: value })}
                    value={`${total} %`}
                    disabled
                />
            </div>
        </div>
    )
}

CultureVentilationPanel.propTypes = {
    disabled: PropTypes.bool,
    cultureVentilation: PropTypes.instanceOf(DtoCultureVentilation),
    onChange: PropTypes.func,
    cultures: PropTypes.arrayOf(PropTypes.instanceOf(DtoCulture)),
    culturesFamilies: PropTypes.arrayOf(PropTypes.instanceOf(DtoCulturesFamily)),
    managementUnits: PropTypes.arrayOf(PropTypes.instanceOf(DtoManagementUnit)),
    sandreCodes: PropTypes.arrayOf(PropTypes.instanceOf(DtoSandreCode)),
}

export default CultureVentilationPanel