
export default class DtoCampaignAction {
    constructor(obj) {
        this.idInstallation = obj.idInstallation // Long,
        this.idCampaign = obj.idCampaign // Long,
        this.responsible = obj.responsible // Option[Long],
        this.comment = obj.comment // Option[String],
        this.deadline = obj.deadline // Option[Int],
        this.localisation = obj.localisation // Option[Long]
        this.category = obj.category // Option[Long]
        this.risk = obj.risk // Option[Long]
        this.interventionType = obj.interventionType // Option[Long]
        this.equipmentType = obj.equipmentType // Option[Long]
        this.correctionDate = obj.correctionDate // Option[DateTime],
        this.commentCorrection = obj.commentCorrection // Option[String],

        this.code = obj.code // Option[String]
        this.installationName = obj.installationName // Option[String]
        this.installationType = obj.installationType // Option[Int]
        this.cityCode = obj.cityCode // Option[String],
        this.city = obj.city // Option[String],
        this.campaignName = obj.campaignName // Option[String]
        this.campaignStartDate = obj.campaignStartDate // Option[String]
        this.campaignStatus = 0
    }
}
