export default class SubscriptionDto {
    constructor(obj) {
        this.id = obj.idSubscription // Int
        this.code = obj.idSubscription // Int
        this.managerCode = obj.managerCode // Option[Int]
        this.administrator = obj.managerCode
        this.networkCode = obj.networkCode // Option[Int]
        this.numLine = obj.numLine // Option[String]
        this.openingDate = obj.openingDate //  Option[DateTime]
        this.closingDate = obj.closingDate // Option[DateTime]
        this.numPath = obj.numPath // Option[String]
        this.operatorCode = obj.operatorCode // Option[Int]
        this.providerId = obj.operatorCode
        this.subscriptionType = obj.subscriptionType // Option[Int]
        this.materielType = obj.subscriptionType
        this.comment = obj.comment // Option[String]
        this.updateDate = obj.updateDate // Option[DateTime]
        this.updateLogin = obj.updateLogin // Option[String]
        this.jobExecutionId = obj.jobExecutionId
    }
}
