'use strict'
import { createSlice } from '@reduxjs/toolkit'
import CampaignThunk from 'campaign/actions/CampaignThunk'
import { uniqBy } from 'lodash'
import QualityThunk from 'quality/actions/QualityThunk'
import DtoAnalysisLight from 'quality/dto/analyse/DtoAnalysisLight'
import AppStore from 'store/AppStore'
import CampaignDto from '../../campaign/dto/CampaignDto'
import DtoCampaignProgress from '../../campaign/dto/DtoCampaignProgress'
import DtoStationCampaign from '../../campaign/dto/DtoStationCampaign'
import NetworkLinkDto from '../../referencial/components/network/dto/NetworkLinkDto'
import DtoSelectionParameter from '../../station/components/suivipc/qualitometer/dto/DtoSelectionParameter'
import DtoKeyFigure from '../../station/dto/DtoKeyFigure'
import DtoPublicPicture from '../../station/dto/DtoPublicPicture'
import { arrayOf, createIndex, instanceOf } from '../../utils/StoreUtils'
import QualityAction from '../actions/QualityAction'
import DtoEsu from '../dto/DtoEsu'
import DtoPoint from '../dto/DtoPoint'
import DtoQualification from '../dto/DtoQualification'
import DtoQualitometer from '../dto/DtoQualitometer'
import DtoQualitometerLight from '../dto/DtoQualitometerLight'
import DtoQualitometerListSpecific from '../dto/DtoQualitometerListSpecific'
import DtoQualityProducer from '../dto/DtoQualityProducer'
import DtoSample from '../dto/DtoSample'
import DtoSearchAnalysis from '../dto/DtoSearchAnalysis'
import DtoSearchIndices from '../dto/DtoSearchIndices'
import { default as DtoStatus, default as Status } from '../dto/DtoStatus'
import DtoThreshold from '../dto/DtoThreshold'
import FilterStation from '../dto/FilterStation'
import DtoHypeLineTrend from '../dto/HypeTrends/DtoHypeLineTrend'
import DtoHypeRupture from '../dto/HypeTrends/DtoHypeRupture'
import DtoQualityIndicators from '../dto/QualityIndicator/DtoQualityIndicators'
import DtoQualityIndicatorsResults from '../dto/QualityIndicator/DtoQualityIndicatorsResults'
import DtoQualityThresholds from '../dto/QualityThreshold/DtoQualityThresholds'
import DtoQualitometerState from '../dto/states/DtoQualitometerState'
import DtoQualityState from '../dto/states/DtoQualityState'
import DtoSearchHydrobio from 'quality/dto/taxons/DtoSearchHydrobio'
import DtoSearchHydrobioLight from 'quality/dto/taxons/DtoSearchHydrobioLight'
import DtoSearchHydrobioUltraLight from 'quality/dto/taxons/DtoSearchHydrobioUltraLight'

export const initialState = {
    qualitometer: {},
    thresholds: [],
    status: [],
    qualifications: [],
    filterStations: [],
    qualitometers: [],
    qualitosIndex: {},
    qualitoDashboard: {},
    qualitosIndexCode: {},
    qualitometersLight: [],
    qualitometersListSpecific: [],
    situationStations: [],
    sample: {},
    qualityKeyfigures: [],
    qualityThresholds: [],
    qualitySelections: [],
    qualityIndicators: [],
    analysis: [],
    hypeTrends: [],
    hypeRuptures: [],
    qualityIndicatorsResults: [],
    searchIndices: [],
    qualitometerStates: [],
    searchHydrobios: [],
    qualityStates: [],
    qualityProducers: [],
    networkLinks: [],
    points: [],
    stationsEsu: [],
    qualityCampaigns: [],
    qualityCampaignsProgress: [],
    qualityCampaignStations: [],
    modelsFilesQuality: [],
}

const store = createSlice({
    name: 'quality',
    initialState,
    reducers: {
        receiveQualitometer: (state, action) => {
            state.qualitometer = new DtoQualitometer(action.payload)
        },
        updateQualitometer: (state, action) => {
            const stations = state.qualitometers.filter(q => q.id !== action.payload.id)
            const newQualitometer = new DtoQualitometer(action.payload)
            state.qualitometer = newQualitometer
            state.qualitometers = stations.concat([newQualitometer])
        },
        createQualitometer: (state, action) => {
            state.qualitometers = [...state.qualitometers, new DtoQualitometer(action.payload)]
        },
        deleteQualitometer: (state, action) => {
            state.qualitometer = {}
            state.qualitometers = state.qualitometers.filter(q => q.id !== action.payload)
        },
        resetQualitometer: (state) => {
            state.qualitometer = {}
        },
        updateQualitometerContributors: (state, action) => {
            const withContributors = { ...state.qualitometer, contributors: action.payload }
            const newQualito = new DtoQualitometer(withContributors)
            const qualitometers = state.qualitometers.filter(q => q.id !== newQualito.id)
            state.qualitometer = newQualito
            state.qualitometers = qualitometers.concat([newQualito])
        },
        receiveQualitySelection: (state, action) => {
            const newSelections = uniqBy(action.payload.concat(state.qualitySelections), 'code')
            state.qualitySelections = newSelections.map(selection => ({
                code: selection.code,
                selections: selection.selections.map(o => new DtoSelectionParameter(o)),
            }))
        },
        receiveStationQualityThreshold: (state, action) => {
            state.qualityThresholds = action.payload.map(threshold => new DtoQualityThresholds(threshold))
        },
        resetStationQualityThreshold: (state) => {
            state.qualityThresholds = []
        },
        receiveThresholds: (state, action) => {
            state.thresholds = action.payload.map(threshold => new DtoThreshold(threshold))
        },
        receiveQualitoDashboard: (state, action) => {
            state.qualitoDashboard = action.payload
        },
        receiveStatus: (state, action) => {
            state.status = action.payload.map(status => new Status(status))
        },
        receiveQualifications: (state, action) => {
            state.qualifications = action.payload.map(qualification => new DtoQualification(qualification))
        },
        receiveFilterStations: (state, action) => {
            state.filterStations = action.payload.map(filter => new FilterStation(filter))
        },
        receiveEsuLight: (state, action) => {
            state.stationsEsu = action.payload.map(el => new DtoEsu(el))
        },
        receiveAllQualitometers: (state, action) => {
            const qualitos = action.payload.map(el => new DtoQualitometer(el))
            state.qualitometers = qualitos
            state.qualitosIndex = createIndex(qualitos)
            state.qualitosIndexCode = createIndex(qualitos, 'code')
        },
        receiveQualitometersLight: (state, action) => {
            const qualitos2 = action.payload.map(el => new DtoQualitometerLight(el))
            state.qualitometersLight = qualitos2
            state.qualitosIndex = createIndex(qualitos2)
            state.qualitosIndexCode = createIndex(qualitos2, 'code')
        },
        receiveQualitometersListSpecific: (state, action) => {
            state.qualitometersListSpecific = action.payload.map(el => new DtoQualitometerListSpecific(el))
        },
        updateSearchAnalysis: (state, action) => {
            const { id, operation, qualitometer } = action.payload
            state.analysis = state.analysis.map(analyse => {
                if (analyse.id === id && analyse.qualitometer === qualitometer && analyse.operation === operation) {
                    return action.payload
                }
                return analyse
            })
        },
        receiveSearchAnalysis: (state, action) => {
            state.analysis = action.payload.map(a => new DtoAnalysisLight(a))
        },
        receiveSearchIndices: (state, action) => {
            state.searchIndices = action.payload.map(el => new DtoSearchIndices(el))
        },
        receiveSample: (state, action) => {
            state.sample = new DtoSample(action.payload)
        },
        receiveKeyFigures: (state, action) => {
            state.qualityKeyfigures = action.payload.map(el => new DtoKeyFigure(el))
        },
        receiveQualityIndicators: (state, action) => {
            state.qualityIndicators = action.payload.map(el => new DtoQualityIndicators(el))
        },
        resetQualityIndicators: (state) => {
            state.qualityIndicators = []
        },
        updateQualityIndicators: (state, action) => {
            state.qualityIndicators = action.payload
        },
        receiveQualityIndicatorsResults: (state, action) => {
            state.qualityIndicatorsResults = action.payload.map(el => new DtoQualityIndicatorsResults(el))
        },
        resetQualityIndicatorsResults: (state) => {
            state.qualityIndicatorsResults = []
        },
        receiveHypeTrends: (state, action) => {
            state.hypeTrends = state.hypeTrends.concat(action.payload.map(el => new DtoHypeLineTrend(el)))
        },
        receiveHypeRuptures: (state, action) => {
            state.hypeRuptures = state.hypeRuptures.concat(action.payload.map(el => new DtoHypeRupture(el)))
        },
        receiveQualitometerStates: (state, action) => {
            state.qualitometerStates = action.payload.map(el => new DtoQualitometerState(el))
        },
        receiveSearchHydrobios: (state, action) => {
            state.searchHydrobios = action.payload.map(el => new DtoSearchHydrobio(el))
        },
        receiveSearchHydrobiosLight: (state, action) => {
            state.searchHydrobios = action.payload.map(el => new DtoSearchHydrobioLight(el))
        },
        receiveSearchHydrobiosUltraLight: (state, action) => {
            state.searchHydrobios = action.payload.map(el => new DtoSearchHydrobioUltraLight(el))
        },
        receiveAllQualityStates: (state, action) => {
            state.qualityStates = action.payload.map(el => new DtoQualityState(el))
        },
        resetHypeTrends: (state) => {
            state.hypeTrends = []
        },
        resetHypeRuptures: (state) => {
            state.hypeRuptures = []
        },
        resetQuality: (state) => {
            state.filterStations = []
        },
        resetQualitySample: (state) => {
            state.sample = {}
        },
        resetQualityAnalysis: (state) => {
            state.analysis = []
        },
        resetQualityThresholds: (state) => {
            state.qualityThresholds = []
        },
        resetQualityHydrobios: (state) => {
            state.searchHydrobios = []
        },
        resetStation: (state) => {
            state.qualitometerStates = []
            state.qualityProducers = []
        },
        receiveQualityNetworkLinks: (state, action) => {
            state.networkLinks = action.payload.map(e => new NetworkLinkDto(e))
        },
        receiveQualityCampaigns: (state, action) => {
            state.qualityCampaigns = action.payload.map(e => new CampaignDto(e))
        },
        receiveQualityCampaignsProgress: (state, action) => {
            state.qualityCampaignsProgress = action.payload.map(e => new DtoCampaignProgress(e))
        },
        receiveQualityCampaignStations: (state, action) => {
            state.qualityCampaignStations = action.payload.map(e => new DtoStationCampaign(e))
        },
        receiveQualityProducers: (state, action) => {
            state.qualityProducers = action.payload.map(e => new DtoQualityProducer(e))
        },
        receiveQualitometerPoints: (state, action) => {
            state.points = action.payload.map(p => new DtoPoint(p))
        },
        receiveUniqQualitometerPoints: (state, action) => {
            state.uniqPoints = action.payload.map(p => new DtoPoint(p))
        },
        receiveModelsFiles: (state, action) => {
            state.modelsFilesQuality = action.payload.map(m => new DtoPublicPicture(m))
        },
        cacheQualitySituation: (state, action) => {
            state.cacheQualitySituation = action.payload
        },
    },
    extraReducers: {
        [QualityThunk.fetchQualitometersListSpecific.fulfilled]: (state, action) => {
            state.qualitometersListSpecific = action.payload.map(el => new DtoQualitometerListSpecific(el))
        },
        [CampaignThunk.fetchQualityCampaigns.fulfilled]: (state, action) => {
            state.qualityCampaigns = action.payload.map(e => new CampaignDto(e))
        },
        [CampaignThunk.fetchQualityCampaignsProgress.fulfilled]: (state, action) => {
            state.qualityCampaignsProgress = action.payload.map(e => new DtoCampaignProgress(e))
        },
    },
})

export const QualityActionConstant = store.actions
export default store.reducer

export const QualityStoreInfos = () => {
    const appStore = AppStore.getState()
    return {
        qualitometers: {
            fetch: QualityAction.fetchQualitometersLight,
            store: appStore.QualityReducer.qualitometersLight,
            propType: arrayOf(DtoQualitometerLight),
        },
        qualitometer: {
            fetch: QualityAction.fetchQualitometer,
            store: appStore.QualityReducer.qualitometer,
            propType: instanceOf(DtoQualitometer),
            reset: QualityActionConstant.resetQualitometer,
            update: QualityAction.updateQualitometer,
            delete: QualityAction.deleteQualitometer,
        },
        status: {
            fetch: QualityAction.fetchStatus,
            store: appStore.QualityReducer.status,
            propType: arrayOf(DtoStatus),
        },
        qualifications: {
            fetch: QualityAction.fetchQualifications,
            store: appStore.QualityReducer.qualifications,
            propType: arrayOf(DtoQualification),
        },
        qualityThresholds: {
            fetch: QualityAction.fetchQualityThresholds,
            store: appStore.QualityReducer.qualityThresholds,
            propType: arrayOf(DtoQualityThresholds),
        },
        qualitySelections: {
            fetch: QualityAction.fetchQualitySelections,
            store: appStore.QualityReducer.qualitySelections,
            propTypes: arrayOf(DtoSelectionParameter),
        },
        qualityIndicators: {
            fetch: QualityAction.fetchQualityIndicators,
            store: appStore.QualityReducer.qualityIndicators,
            propType: arrayOf(DtoQualityIndicators),
        },
        thresholds: {
            fetch: QualityAction.fetchThresholds,
            store: appStore.QualityReducer.thresholds,
            propType: arrayOf(DtoThreshold),
            update: QualityAction.updateThreshold,
        },
        qualityAnalysis: {
            fetch: QualityAction.fetchSearchAnalysis,
            store: appStore.QualityReducer.analysis,
            propType: arrayOf(DtoSearchAnalysis),
            reset: QualityActionConstant.resetQualityAnalysis,
        },
        filterStations: {
            fetch: QualityAction.fetchFilterStations,
            store: appStore.QualityReducer.filterStations,
            propType: arrayOf(FilterStation),
        },
        sample: {
            fetch: QualityAction.fetchSample,
            reset: QualityActionConstant.resetQualitySample,
            store: appStore.QualityReducer.sample,
            propType: instanceOf(DtoSample),
        },
        qualityKeyfigures: {
            fetch: QualityAction.fetchKeyFigures,
            store: appStore.QualityReducer.qualityKeyfigures,
            propType: instanceOf(DtoKeyFigure),
        },
        hypeTrends: {
            fetch: QualityAction.fetchHypeTrends,
            store: appStore.QualityReducer.hypeTrends,
            propType: arrayOf(DtoHypeLineTrend),
            reset: QualityActionConstant.resetHypeTrends,
        },
        hypeRuptures: {
            fetch: QualityAction.fetchHypeTrends,
            store: appStore.QualityReducer.hypeRuptures,
            propType: arrayOf(DtoHypeRupture),
            reset: QualityActionConstant.resetHypeRuptures,
        },
        stationsEsu: {
            fetch: QualityAction.fetchEsuLight,
            store: appStore.QualityReducer.stationsEsu,
            propType: arrayOf(DtoEsu),
        },
    }
}
