import React, { useEffect } from 'react'
import i18n from 'simple-react-i18n'
import { sieauTooltip } from 'utils/FormUtils'
import useBoolean from 'utils/customHook/useBoolean'
import { DialogActionsMUI, DialogContentMUI, DialogMUI, DialogTitleMUI } from 'components/styled/Dialog'
import { Button, Grid } from '@mui/material'
import Icon from 'components/icon/Icon'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import Table from 'components/datatable/Table'
import OperationAction from 'quality/components/operation/actions/OperationAction'
import { StyledFieldSet } from 'components/StyledElements'
import { isNil } from 'lodash'
import PropTypes from 'prop-types'

const Calculate = ({
    operationIds = [],
}) => {
    const dispatch = useDispatch()
    const {
        qualitometer,
        calculateParameters,
    } = useSelector(store => ({
        qualitometer: store.QualityReducer.qualitometer,
        calculateParameters: store.OperationReducer.calculateParameters,
    }), shallowEqual)

    const {
        value: isOpen,
        setTrue: open,
        setFalse: close,
    } = useBoolean(false)

    useEffect(() => {
        dispatch(OperationAction.fetchCalculateParameters())
    }, [dispatch])

    const formattedParams = calculateParameters.filter(o => o.computedParameterCode).map(p => ({
        code: p.code,
        name: p.name ?? p.code,
        nbParameters: p.computedParameterCode.split(/;|,/).length,
    }))

    const onCalculate = () => {
        if (operationIds.length === 0 || isNil(qualitometer.id)) return
        dispatch(OperationAction.calculateParametersOperation(qualitometer.id, operationIds))
    }

    return (
        <div className='action-wrapper'>
            <a
                className='right waves-effect nav-actions blue-arrow'
                { ...sieauTooltip(i18n.update, 'update_action_navbar', 'bottom') }
                id='update_action_navbar'
                onClick={open}
            >
                <i className='material-icons left no-margin'>sync</i>
            </a>
            <DialogMUI
                maxWidth='md'
                fullWidth
                open={isOpen}
                PaperProps={{
                    sx: {
                        minHeight: undefined,
                        maxHeight: undefined,
                    },
                }}
            >
                <DialogTitleMUI>
                    <Grid container justifyContent='space-between' alignItems='center'>
                        <Grid item >
                            {i18n.update}
                        </Grid>
                        <Grid item>
                            <Icon style={{ color: 'white' }} size='small' icon={'close'} onClick={close} />
                        </Grid>
                    </Grid>
                </DialogTitleMUI>
                <DialogContentMUI sx={{ padding: '5' }}>
                    <StyledFieldSet style={{ minWidth: '0' }}>
                        {formattedParams.length === 0 && (<div className='center'> { i18n.noCalculateParameters }</div>)}
                        {formattedParams.length > 0 && (
                            <Table
                                showTitle={false}
                                withCard={false}
                                data={formattedParams}
                                type={{ headers: ['code', 'name', 'nbParameters'] }}
                                condensed
                                maxHeight={275}
                                sortable
                            />
                        )}
                    </StyledFieldSet>
                </DialogContentMUI>
                <DialogActionsMUI>
                    <Button onClick={onCalculate} variant='contained' color='primary' disabled={formattedParams.length === 0}>
                        {i18n.calculate}
                    </Button>
                </DialogActionsMUI>
            </DialogMUI>
        </div>
    )
}

Calculate.propTypes = {
    operationIds: PropTypes.arrayOf(PropTypes.number),
}

export default Calculate