import { MAT_SUBSCRIPTION } from '../../../constants/MaterielConstants'
import i18n from 'simple-react-i18n'

export default class SubscriptionTypeDto {
    constructor(obj) {
        this.materielCategory = MAT_SUBSCRIPTION
        this.materielLabel = i18n.subscription
        this.id = obj.materielType
        this.code = obj.materielType
        this.materielType = obj.materielType
        this.name = obj.name
        this.label = obj.name
        this.startDate = obj.startDate
        this.endDate = obj.endDate
        this.updateDate = obj.updateDate
        this.updateLogin = obj.updateLogin
    }
}