import ApplicationConf from 'conf/ApplicationConf'
import fetch from 'isomorphic-fetch'
import i18n from 'simple-react-i18n'
import ToastrAction from 'toastr/actions/ToastrAction'
import LogAction from '../../log/actions/LogAction'
import { RECEIVE_PARCEL_AND_SECTION } from '../../station/constants/StationConstants'
import { checkError, getAuthorization, getJson } from '../../utils/ActionUtils'

const ApiCartoAction = {
    receiveParcelAndSectionByCoordinate(parcelAndSection) {
        return { type: RECEIVE_PARCEL_AND_SECTION, parcelAndSection }
    },
    fetchParcelAndSectionByCoordinate(x, y) {
        return (dispatch) => {
            return fetch(ApplicationConf.parcel.getParcelAndSectionByCoordinate(x, y), {
                method: 'GET',
                headers: getAuthorization(),
            })
                .then(getJson)
                .then(checkError)
                .then((json = []) => {
                    dispatch(ApiCartoAction.receiveParcelAndSectionByCoordinate(json))
                })
                .catch((err) => {
                    dispatch(LogAction.logError(`${i18n.error} ${i18n.parcel} : ${err}`))
                    dispatch(ToastrAction.error(`${i18n.error} - ${i18n.downloadData} : ${i18n.parcel}`))
                })
        }
    },
}

export default ApiCartoAction
