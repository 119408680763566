import {
    RECEIVE_ALL_SUBSCRIPTION_SITUATIONS,
    RECEIVE_ALL_SUBSCRIPTIONS,
    RECEIVE_SIM_SUBSCRIPTIONS_LAST_SITUATIONS,
    RECEIVE_SUBSCRIPTION,
    RECEIVE_SUBSCRIPTION_SIM_LINK,
    RECEIVE_SUBSCRIPTION_TYPES,
    RECEIVE_SUBSCRIPTIONS_LAST_SITUATIONS,
    RESET_SUBSCRIPTION,
} from '../constants/SubscriptionConstant'
import SubscriptionSituationDto from '../dto/SubscriptionSituationDto'
import SubscriptionDto from '../dto/SubscriptionDto'
import SubscriptionTypeDto from '../dto/SubscriptionTypeDto'
import SimSubscriptionDto from '../../sim/dto/SimSubscriptionDto'
import { RESET_ALL_MATERIELS } from '../../../constants/MaterielConstants'

export const store = {
    subscriptions: [],
    subscription: {},
    subscriptionTypes: [],
    subscriptionSituations: [],
    subscriptionsLastSituations: [],
    simSubscriptionsLastSituations: [],
    simsLink: [],
}

export function SubscriptionReducer(state = {}, action) {
    switch (action.type) {
        case RECEIVE_ALL_SUBSCRIPTIONS:
            return {
                ...state,
                subscriptions: action.subscriptions.map(sub => new SubscriptionDto(sub)),
            }
        case RECEIVE_SUBSCRIPTION:
            return {
                ...state,
                subscription: new SubscriptionDto(action.subscription),
            }
        case RECEIVE_SUBSCRIPTIONS_LAST_SITUATIONS:
            return {
                ...state,
                subscriptionsLastSituations: action.subscriptionsLastSituations.map(sit => new SubscriptionSituationDto(sit)),
            }
        case RECEIVE_SIM_SUBSCRIPTIONS_LAST_SITUATIONS:
            return {
                ...state,
                simSubscriptionsLastSituations: action.simSubscriptionsLastSituations.map(sit => new SimSubscriptionDto(sit)),
            }
        case RECEIVE_ALL_SUBSCRIPTION_SITUATIONS:
            return {
                ...state,
                subscriptionSituations: action.subscriptionSituations.map(sit => new SubscriptionSituationDto(sit)),
            }
        case RECEIVE_SUBSCRIPTION_TYPES:
            return {
                ...state,
                subscriptionTypes: action.subscriptionTypes.map(type => new SubscriptionTypeDto(type)),
            }
        case RECEIVE_SUBSCRIPTION_SIM_LINK:
            return {
                ...state,
                simsLink: action.simsLink.map(link => new SimSubscriptionDto(link)),
            }

        case RESET_ALL_MATERIELS:
            return {
                ...state,
                ...store,
            }
        case RESET_SUBSCRIPTION:
            return {
                ...state,
                subscription: {},
                subscriptionSituations: [],
            }
        default:
            return state
    }
}