export default class DtoBoreholeManagement {
    constructor(obj) {
        this.idStation = obj.idStation

        this.monitoringProgram = obj.monitoringProgram
        this.lowFlowReduction = obj.lowFlowReduction
        this.hydroNoticeDate = obj.hydroNoticeDate
        this.CODERSTDate = obj.CODERSTDate
        this.DUPdecreeDate = obj.DUPdecreeDate
        this.advancementProc = obj.advancementProc
        this.prescriptionFollowUp = obj.prescriptionFollowUp
        this.needRevision = obj.needRevision
        this.vulnerability = obj.vulnerability
    }
}