export default class DtoExportTerritoryActivitie {
    constructor(obj) {
        this.category = obj[0]
        this.city = obj[1]
        this.establishment = obj[2]
        this.id = obj[3]
        this.siret = obj[4]
        this.cityCode = obj[5]
        this.activityCode = obj[6]
        this.activity = obj[7]
        this.dischargeAgreementLabel = obj[8]
        this.effectiveTranche = obj[9]
        this.icpe = obj[10]
        this.x = obj[11]
        this.y = obj[12]
        this.idStation = obj[13]
        this.step = obj[14]
    }
}