import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import DtoAccountStatistic from '../../dto/DtoAccountStatistic'
import AccountAccessChartPanel from './charts/AccountAccessChartPanel'
import AccountStationStatisticsPanel from './statistic/AccountStationStatisticsPanel'
import AccountStatisticsPanel from './statistic/AccountStatisticsPanel'
import AccountUsageStatisticsPanel from './statistic/AccountUsageStatisticsPanel'

class StatisticAccountPanel extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        if (this.props.accountStatistics.length) {
            return (
                <div className='row no-margin'>
                    <div className='col s12 no-padding'>
                        <div className='col s12'>
                            <div className='row no-margin'>
                                <div className='col s8 no-padding'>
                                    <AccountAccessChartPanel/>
                                </div>
                                <div className='col s4 no-padding'>
                                    <AccountStatisticsPanel/>
                                </div>
                            </div>
                        </div>
                        <div className='col s12 margin-top-1'>
                            <AccountUsageStatisticsPanel/>
                        </div>
                        <div className='col s12 margin-top-1'>
                            <AccountStationStatisticsPanel/>
                        </div>
                    </div>
                </div>
            )
        }
        return null
    }
}

StatisticAccountPanel.propTypes = {
    accountStatistics: PropTypes.arrayOf(PropTypes.instanceOf(DtoAccountStatistic)),
}

const mapStateToProps = (store) => {
    return {
        accountStatistics: store.AccountReducer.accountStatistics,
    }
}
export default connect(mapStateToProps)(StatisticAccountPanel)
