import AdministrationAction from 'administration/actions/AdministrationAction'
import { formatParameterSelectedPopup } from 'campaign/utils/CampaignUtils'
import Input from 'components/forms/Input'
import NumberField from 'components/forms/NumberField'
import PropTypes from 'prop-types'
import Select from 'components/forms/Select'
import SelectionSelect from 'components/forms/specific/SelectionSelect'
import SelectionTableModal from 'components/modal/SelectionTableModal'
import { intersectionWith, isNil } from 'lodash'
import QualityAction from 'quality/actions/QualityAction'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import ParameterAction from 'referencial/components/parameter/actions/ParameterAction'
import { nbPerPageLabel } from 'referencial/constants/ReferencialConstants'
import i18n from 'simple-react-i18n'
import useActions from 'utils/customHook/useActions'
import useTitle from 'utils/customHook/useTitle'
import { getSetting } from 'utils/SettingUtils'
import { searchAllCharacters } from 'utils/StringUtil'
import RadioButtons from 'components/forms/RadioButtons'
import { Card, CardContent, Grid2 } from '@mui/material'
import useBoolean from 'utils/customHook/useBoolean'
import { AVERAGE_SUBSTANCE, AVERAGE_SUM, MAX_SUBSTANCE, MAX_SUM } from 'catchment/constants/CatchmentConstant'
import { floatParser, intParser } from 'utils/customHook/useApplicationSetting'
import useStateAppSetting from 'utils/customHook/useStateAppSetting'
import SuperMultiAutocomplete from 'components/forms/SuperMultiAutocomplete'
import { CardTable } from 'components/datatable/NewTable'
import { SimpleFilterField, SimpleFilterFunction } from 'components/datatable/SimpleSelectionTable'

const parameterFilterFunction = (list, { selection = '-1', listParam, searchValue }) => {
    const filterSelection = selection !== '-1' ? intersectionWith(list, listParam, (elem, id) => elem.id === id) : list
    const searchValueFormat = searchAllCharacters(searchValue)
    return searchValue ? filterSelection.filter(p => p.labelSearch.includes(searchValueFormat)) : filterSelection
}

const ParameterFilterField = ({
    filter,
    setFilter,
}) => (
    <Grid2 container spacing={1} style={{ paddingTop: '5px' }}>
        <Grid2 size={6}>
            <Input
                title={i18n.search}
                value={filter.searchValue}
                onChange={searchValue => setFilter(prev => ({ ...prev, searchValue }))}
            />
        </Grid2>
        <Grid2 size={6}>
            <SelectionSelect
                value={filter.selection}
                onChange={(listParam, selection) => setFilter(prev => ({ ...prev, selection, listParam }))}
            />
        </Grid2>
    </Grid2>
)

ParameterFilterField.propTypes = {
    filter: PropTypes.shape({
        searchValue: PropTypes.strung,
        selection: PropTypes.strung,
        listParam: PropTypes.arrayOf(PropTypes.string),
    }),
    setFilter: PropTypes.func,
}

const Label = ({ children, isReadMode }) => (
    <label
        style={{
            color: isReadMode ? '#9e9e9e' : '#161832',
            fontWeight: 'bold',
            fontSize: '1rem',
            marginLeft: '1.2rem',
        }}
    >
        {children}
    </label>
)

Label.propTypes = {
    children: PropTypes.string,
    isReadMode: PropTypes.bool,
}

const CatchmentsOptionApp = () => {
    const {
        thresholds,
        parameters,
        applicationSettings,
        contributors,
    } = useSelector(store => ({
        thresholds: store.QualityReducer.thresholds,
        parameters: store.ParameterReducer.parameters,
        applicationSettings: store.AdministrationReducer.applicationSettings,
        contributors: store.ContributorReducer.contributors,
    }), shallowEqual)

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(QualityAction.fetchThresholds())
        dispatch(ParameterAction.fetchParameters())
    }, [])

    const parseSetting = constant => {
        const setting = getSetting(applicationSettings, constant)
        return setting ? parseFloat(setting) : undefined
    }

    const {
        value: isReadMode,
        setTrue: setReadMode,
        setFalse: setEditMode,
    } = useBoolean(true)
    const {
        value: isPesticidePopinOpen,
        setTrue: openPesticidesPopin,
        setFalse: closePesticidesPopin,
    } = useBoolean(false)
    const {
        value: isListSumPopinOpen,
        setTrue: openListSumPopin,
        setFalse: closeListSumPopin,
    } = useBoolean(false)
    const {
        value: isProducerPopinOpen,
        setTrue: openProducerPopinOpen,
        setFalse: closeProducerPopinOpen,
    } = useBoolean(false)

    const [startPeriod, setStartPeriod] = useState(() => parseSetting('CATCHMENT_START_PERIOD'))
    const [endPeriod, setEndPeriod] = useState(() => parseSetting('CATCHMENT_END_PERIOD'))
    const [nitratesThreshold, setNitratesThreshold] = useState(() => parseSetting('CATCHMENT_NITRATES_THRESHOLD'))
    const [pesticideThreshold1, setPesticideThreshold1] = useState(() => parseSetting('CATCHMENT_PESTICIDE_THRESHOLD_1'))
    const [pesticideThreshold2, setPesticideThreshold2] = useState(() => parseSetting('CATCHMENT_PESTICIDE_THRESHOLD_2'))
    const [specificThreshold, setSpecificThreshold] = useState(() => parseSetting('CATCHMENT_SPECIFIC_THRESHOLD'))
    const [pesticides, setPesticides] = useState(() => getSetting(applicationSettings, 'CATCHMENT_PESTICIDES')?.split(',').filter(c => !!c) || [])
    const [listSumPesticides, setListSumPesticides] = useState(() => getSetting(applicationSettings, 'CATCHMENT_LIST_SUM_PESTICIDES')?.split(',').filter(c => !!c) || [])
    const [producers, setProducers] = useState(() => getSetting(applicationSettings, 'CATCHMENT_PRODUCERS_EXCLUDED')?.split(',').filter(c => !!c).map(id => parseInt(id)) || [])
    const [regrouping, setRegrouping] = useState(() => parseSetting('CATCHMENT_REGROUPING'))
    const [overviewCalculationMethod, setOverviewCalculationMethod] = useState(() => getSetting(applicationSettings, 'CATCHMENT_OVERVIEW_CALCULATION_METHOD')?.split(',').filter(c => !!c) ?? [MAX_SUBSTANCE, AVERAGE_SUM])

    const [nbSample, setNbSample] = useStateAppSetting('CATCHMENT_NB_ANNUAL_SAMPLE', intParser)
    const [nbParameter, setNbParameter] = useStateAppSetting('CATCHMENT_NB_PARAMETER', intParser)

    const [nitratesDistributionThreshold1, setNitratesDistributionThreshold1] = useStateAppSetting('CATCHMENT_NITRATES_DISTRIBUTION_THRESHOLD_1', floatParser, 2)
    const [nitratesDistributionThreshold2, setNitratesDistributionThreshold2] = useStateAppSetting('CATCHMENT_NITRATES_DISTRIBUTION_THRESHOLD_2', floatParser, 10)
    const [nitratesDistributionThreshold3, setNitratesDistributionThreshold3] = useStateAppSetting('CATCHMENT_NITRATES_DISTRIBUTION_THRESHOLD_3', floatParser, 25)
    const [nitratesDistributionThreshold4, setNitratesDistributionThreshold4] = useStateAppSetting('CATCHMENT_NITRATES_DISTRIBUTION_THRESHOLD_4', floatParser, 50)

    const [pesticidesDistributionThreshold1, setPesticidesDistributionThreshold1] = useStateAppSetting('CATCHMENT_PESTICIDES_DISTRIBUTION_THRESHOLD_1', floatParser, 0.1)
    const [pesticidesDistributionThreshold2, setPesticidesDistributionThreshold2] = useStateAppSetting('CATCHMENT_PESTICIDES_DISTRIBUTION_THRESHOLD_2', floatParser, 2)

    const [sumPesticidesDistributionThreshold1, setSumPesticidesDistributionThreshold1] = useStateAppSetting('CATCHMENT_SUM_PESTICIDES_DISTRIBUTION_THRESHOLD_1', floatParser, 0.5)
    const [sumPesticidesDistributionThreshold2, setSumPesticidesDistributionThreshold2] = useStateAppSetting('CATCHMENT_SUM_PESTICIDES_DISTRIBUTION_THRESHOLD_2', floatParser, 5)

    const setAllSettings = useCallback(() => {
        setStartPeriod(parseSetting('CATCHMENT_START_PERIOD'))
        setEndPeriod(parseSetting('CATCHMENT_END_PERIOD'))
        setNitratesThreshold(parseSetting('CATCHMENT_NITRATES_THRESHOLD'))
        setPesticideThreshold1(parseSetting('CATCHMENT_PESTICIDE_THRESHOLD_1'))
        setPesticideThreshold2(parseSetting('CATCHMENT_PESTICIDE_THRESHOLD_2'))
        setSpecificThreshold(parseSetting('CATCHMENT_SPECIFIC_THRESHOLD'))
        setPesticides(getSetting(applicationSettings, 'CATCHMENT_PESTICIDES')?.split(',').filter(c => !!c) || [])
        setListSumPesticides(getSetting(applicationSettings, 'CATCHMENT_LIST_SUM_PESTICIDES')?.split(',').filter(c => !!c) || [])
        setProducers(getSetting(applicationSettings, 'CATCHMENT_PRODUCERS_EXCLUDED')?.split(',').filter(c => !!c).map(id => parseInt(id)) || [])
        setRegrouping(parseSetting('CATCHMENT_REGROUPING'))
        setOverviewCalculationMethod(getSetting(applicationSettings, 'CATCHMENT_OVERVIEW_CALCULATION_METHOD')?.split(',').filter(c => !!c) ?? [MAX_SUBSTANCE, AVERAGE_SUM])
    }, [applicationSettings])

    useEffect(() => {
        setAllSettings()
    }, [setAllSettings])

    useTitle(() => {
        return [{
            title: i18n.catchments,
            href: 'catchment',
        }, {
            title: i18n.settings,
            href: 'catchment/option',
        }]
    }, [])

    useActions(() => {
        if (isReadMode) {
            return {
                edit: () => setEditMode(),
            }
        }
        return {
            cancel: () => {
                setAllSettings()
                setReadMode()
            },
            save: () => {
                const settings = [
                    { parameter: 'CATCHMENT_START_PERIOD', value: startPeriod },
                    { parameter: 'CATCHMENT_END_PERIOD', value: endPeriod },
                    { parameter: 'CATCHMENT_NITRATES_THRESHOLD', value: nitratesThreshold },
                    { parameter: 'CATCHMENT_PESTICIDE_THRESHOLD_1', value: pesticideThreshold1 },
                    { parameter: 'CATCHMENT_PESTICIDE_THRESHOLD_2', value: pesticideThreshold2 },
                    { parameter: 'CATCHMENT_SPECIFIC_THRESHOLD', value: specificThreshold },
                    { parameter: 'CATCHMENT_PESTICIDES', value: pesticides.join(',') },
                    { parameter: 'CATCHMENT_LIST_SUM_PESTICIDES', value: listSumPesticides.join(',') },
                    { parameter: 'CATCHMENT_PRODUCERS_EXCLUDED', value: producers.join(',') },
                    { parameter: 'CATCHMENT_REGROUPING', value: regrouping },
                    { parameter: 'CATCHMENT_OVERVIEW_CALCULATION_METHOD', value: overviewCalculationMethod.join(',') },
                    { parameter: 'CATCHMENT_NITRATES_DISTRIBUTION_THRESHOLD_1', value: nitratesDistributionThreshold1 },
                    { parameter: 'CATCHMENT_NITRATES_DISTRIBUTION_THRESHOLD_2', value: nitratesDistributionThreshold2 },
                    { parameter: 'CATCHMENT_NITRATES_DISTRIBUTION_THRESHOLD_3', value: nitratesDistributionThreshold3 },
                    { parameter: 'CATCHMENT_NITRATES_DISTRIBUTION_THRESHOLD_4', value: nitratesDistributionThreshold4 },
                    { parameter: 'CATCHMENT_PESTICIDES_DISTRIBUTION_THRESHOLD_1', value: pesticidesDistributionThreshold1 },
                    { parameter: 'CATCHMENT_PESTICIDES_DISTRIBUTION_THRESHOLD_2', value: pesticidesDistributionThreshold2 },
                    { parameter: 'CATCHMENT_SUM_PESTICIDES_DISTRIBUTION_THRESHOLD_1', value: sumPesticidesDistributionThreshold1 },
                    { parameter: 'CATCHMENT_SUM_PESTICIDES_DISTRIBUTION_THRESHOLD_2', value: sumPesticidesDistributionThreshold2 },
                    { parameter: 'CATCHMENT_NB_ANNUAL_SAMPLE', value: nbSample },
                    { parameter: 'CATCHMENT_NB_PARAMETER', value: nbParameter },
                ].map(p => isNil(p.value) ? p : { ...p, value: `${p.value}` }).filter(p => getSetting(applicationSettings, p.parameter) !== p.value)
                if (settings.length) {
                    dispatch(AdministrationAction.updateSieauParameters(settings)).then(() => setReadMode())
                } else {
                    setReadMode()
                }
            },
        }
    }, [isReadMode, setAllSettings, startPeriod, endPeriod, nitratesThreshold, pesticideThreshold1, pesticideThreshold2, specificThreshold, pesticides, listSumPesticides, producers, regrouping, overviewCalculationMethod, nitratesDistributionThreshold1, nitratesDistributionThreshold2, nitratesDistributionThreshold3, nitratesDistributionThreshold4, pesticidesDistributionThreshold1, pesticidesDistributionThreshold2, sumPesticidesDistributionThreshold1, sumPesticidesDistributionThreshold2, nbSample, nbParameter])

    const pesticidesFormatted = useMemo(() => pesticides.map(code => ({
        code: { value: code },
        name: { value: parameters.find(p => p.code === code)?.name },
    })), [parameters, pesticides])
    const listSumFormatted = useMemo(() => listSumPesticides.map(code => ({
        code: { value: code },
        name: { value: parameters.find(p => p.code === code)?.name },
    })), [parameters, listSumPesticides])

    const producersFormatted = useMemo(() => producers.map(id => ({
        id,
        name: { value: contributors.find(c => c.id === id)?.name },
    })), [contributors, producers])

    const yearGroup = useMemo(() => {
        return [
            { value: 1, label: `1 ${i18n.year}` },
            { value: 2, label: `2 ${i18n.years}` },
            { value: 3, label: `3 ${i18n.years}` },
            { value: 6, label: `6 ${i18n.years}` },
        ]
    }, [])

    const overviewCalculationMethods = useMemo(() => {
        return [
            { value: AVERAGE_SUBSTANCE, label: i18n.averageByPeriodBySubstance },
            { value: MAX_SUBSTANCE, label: i18n.maxByPeriodBySubstance },
            { value: AVERAGE_SUM, label: i18n.averageSumByPeriodBySubstance },
            { value: MAX_SUM, label: i18n.maxSumByPeriodBySubstance },
        ]
    }, [])

    const activeParameters = useMemo(() => formatParameterSelectedPopup(parameters.filter(p => p.active)), [parameters])
    const formattedContributor = useMemo(() => contributors.map(c => ({ ...c, labelSearch: searchAllCharacters(c.name) })), [contributors])

    return (
        <>
            <Card sx={{ margin: '10px 10px 100px 0px' }}>
                <CardContent>
                    <Grid2 container rowSpacing={0.5} columnSpacing={1} alignItems='flex-start'>
                        <Grid2 container size={6}>
                            <Grid2 size={6}>
                                <NumberField
                                    title={i18n.referencePeriod}
                                    value={startPeriod}
                                    onChange={setStartPeriod}
                                    disabled={isReadMode}
                                />
                            </Grid2>
                            <Grid2 size={6}>
                                <NumberField
                                    value={endPeriod}
                                    onChange={setEndPeriod}
                                    disabled={isReadMode}
                                />
                            </Grid2>
                            <Grid2 size={12}>
                                <RadioButtons
                                    selected={regrouping}
                                    elements={yearGroup}
                                    title={i18n.regrouping}
                                    onChange={setRegrouping}
                                    disabled={isReadMode}
                                />
                            </Grid2>
                            <Grid2 size={6}>
                                <NumberField
                                    title={'Nombre de prélèvements annuels'}
                                    value={nbSample}
                                    onChange={setNbSample}
                                    disabled={isReadMode}
                                />
                            </Grid2>
                            <Grid2 size={6}>
                                <NumberField
                                    title={'Nombre de molécules différentes recherchées'}
                                    value={nbParameter}
                                    onChange={setNbParameter}
                                    disabled={isReadMode}
                                />
                            </Grid2>
                            <Grid2 size={12}>
                                <SuperMultiAutocomplete
                                    label={i18n.overviewCalculationMethod}
                                    options={overviewCalculationMethods}
                                    onChange={setOverviewCalculationMethod}
                                    values={overviewCalculationMethod}
                                    disabled={isReadMode}
                                    multiple
                                    limit={2}
                                    noSort
                                    keyValue='value'
                                    keyLabel='label'
                                />
                            </Grid2>
                            <Grid2 size={6}>
                                <NumberField
                                    title={`${i18n.nitratesThreshold} (mg/L)`}
                                    floatValue
                                    value={nitratesThreshold}
                                    onChange={setNitratesThreshold}
                                    disabled={isReadMode}
                                />
                            </Grid2>
                            <Grid2 xs={6} />
                            <Grid2 size={6}>
                                <NumberField
                                    title={`${i18n.pesticideThreshold} (mg/L)`}
                                    floatValue
                                    value={pesticideThreshold1}
                                    onChange={setPesticideThreshold1}
                                    disabled={isReadMode}
                                />
                            </Grid2>
                            <Grid2 size={6}>
                                <NumberField
                                    floatValue
                                    value={pesticideThreshold2}
                                    onChange={setPesticideThreshold2}
                                    disabled={isReadMode}
                                />
                            </Grid2>
                            <Grid2 size={12}>
                                <Select
                                    label={i18n.specificThresholdToBeUsed}
                                    onChange={setSpecificThreshold}
                                    value={specificThreshold}
                                    options={thresholds}
                                    nullLabel='&nbsp;'
                                    keyLabel='name'
                                    keyValue='code'
                                    disabled={isReadMode}
                                />
                            </Grid2>

                            <Grid2 size={12} style={{ paddingTop: '0' }}>
                                <Label isReadMode={isReadMode}>{i18n.nitratesDistributionThreshold}</Label>
                            </Grid2>
                            <Grid2 size={3} style={{ paddingTop: '0' }}>
                                <NumberField
                                    value={nitratesDistributionThreshold1}
                                    onChange={setNitratesDistributionThreshold1}
                                    disabled={isReadMode}
                                    floatValue
                                    noLabel
                                />
                            </Grid2>
                            <Grid2 size={3} style={{ paddingTop: '0' }}>
                                <NumberField
                                    value={nitratesDistributionThreshold2}
                                    onChange={setNitratesDistributionThreshold2}
                                    disabled={isReadMode}
                                    floatValue
                                    noLabel
                                />
                            </Grid2>
                            <Grid2 size={3} style={{ paddingTop: '0' }}>
                                <NumberField
                                    value={nitratesDistributionThreshold3}
                                    onChange={setNitratesDistributionThreshold3}
                                    disabled={isReadMode}
                                    floatValue
                                    noLabel
                                />
                            </Grid2>
                            <Grid2 size={3} style={{ paddingTop: '0' }}>
                                <NumberField
                                    value={nitratesDistributionThreshold4}
                                    onChange={setNitratesDistributionThreshold4}
                                    disabled={isReadMode}
                                    floatValue
                                    noLabel
                                />
                            </Grid2>

                            <Grid2 size={12} style={{ paddingTop: '0' }}>
                                <Label isReadMode={isReadMode}>{i18n.pesticidesDistributionThreshold}</Label>
                            </Grid2>
                            <Grid2 size={3} style={{ paddingTop: '0' }}>
                                <NumberField
                                    value={pesticidesDistributionThreshold1}
                                    onChange={setPesticidesDistributionThreshold1}
                                    disabled={isReadMode}
                                    floatValue
                                    noLabel
                                />
                            </Grid2>
                            <Grid2 size={3} style={{ paddingTop: '0' }}>
                                <NumberField
                                    value={pesticidesDistributionThreshold2}
                                    onChange={setPesticidesDistributionThreshold2}
                                    disabled={isReadMode}
                                    floatValue
                                    noLabel
                                />
                            </Grid2>
                            <Grid2 size={6} />

                            <Grid2 size={12} style={{ paddingTop: '0' }}>
                                <Label isReadMode={isReadMode}>{i18n.sumPesticidesDistributionThreshold}</Label>
                            </Grid2>
                            <Grid2 size={3} style={{ paddingTop: '0' }}>
                                <NumberField
                                    value={sumPesticidesDistributionThreshold1}
                                    onChange={setSumPesticidesDistributionThreshold1}
                                    disabled={isReadMode}
                                    floatValue
                                    noLabel
                                />
                            </Grid2>
                            <Grid2 size={3} style={{ paddingTop: '0' }}>
                                <NumberField
                                    value={sumPesticidesDistributionThreshold2}
                                    onChange={setSumPesticidesDistributionThreshold2}
                                    disabled={isReadMode}
                                    floatValue
                                    noLabel
                                />
                            </Grid2>
                            <Grid2 size={6} />

                            <Grid2 size={12}>
                                <CardTable
                                    title={i18n.producersExcluded}
                                    actions={[{
                                        icon: 'edit',
                                        tooltip: i18n.edit,
                                        onClick: openProducerPopinOpen,
                                        displayed: !isReadMode,
                                    }]}

                                    rows={producersFormatted}
                                    headers={['name']}

                                    rowsPerPageOptions={nbPerPageLabel}
                                />
                            </Grid2>
                        </Grid2>
                        <Grid2 container size={6}>
                            <Grid2 size={12}>
                                <CardTable
                                    title={i18n.pesticides}
                                    actions={[{
                                        icon: 'edit',
                                        tooltip: i18n.edit,
                                        onClick: openPesticidesPopin,
                                        displayed: !isReadMode,
                                    }]}

                                    rows={pesticidesFormatted}
                                    headers={['code', 'name']}
                                    maxHeight='25vh'
                                    rowsPerPageOptions={nbPerPageLabel}
                                />
                            </Grid2>
                            <Grid2 size={12}>
                                <CardTable
                                    title={i18n.listSumPesticides}
                                    actions={[{
                                        icon: 'edit',
                                        tooltip: i18n.edit,
                                        onClick: openListSumPopin,
                                        displayed: !isReadMode,
                                    }]}

                                    rows={listSumFormatted}
                                    headers={['code', 'name']}
                                    maxHeight='25vh'
                                    rowsPerPageOptions={nbPerPageLabel}
                                />
                            </Grid2>
                        </Grid2>
                    </Grid2>
                </CardContent>
            </Card>
            <SelectionTableModal
                isOpen={isProducerPopinOpen}
                onClose={closeProducerPopinOpen}
                onValidate={list => {
                    closeProducerPopinOpen()
                    setProducers(list)
                }}
                title={i18n.producersExcludedSelection}

                listData={formattedContributor}
                defaultSelectionList={producers}

                listHeaders={['name']}
                listTitle={i18n.nonSelectedProducers}
                selectionListTitle={i18n.selectedProducers}
                maxHeightTable={'53vh'}

                filterField={SimpleFilterField}
                filterFunction={SimpleFilterFunction}
            />
            <SelectionTableModal
                isOpen={isPesticidePopinOpen}
                onClose={closePesticidesPopin}
                onValidate={list => {
                    closePesticidesPopin()
                    setPesticides(list)
                }}
                title={i18n.pesticidesSelection}

                listData={activeParameters}
                defaultSelectionList={pesticides}

                listHeaders={['name', 'type', 'nature', 'status']}
                listTitle={i18n.nonSelectedParameters}
                selectionListTitle={i18n.selectedParameters}
                maxHeightTable={'53vh'}

                filterField={ParameterFilterField}
                filterFunction={parameterFilterFunction}
            />
            <SelectionTableModal
                isOpen={isListSumPopinOpen}
                onClose={closeListSumPopin}
                onValidate={list => {
                    closeListSumPopin()
                    setListSumPesticides(list)
                }}
                title={i18n.listSumPesticidesSelection}

                listData={activeParameters}
                defaultSelectionList={listSumPesticides}

                listHeaders={['name', 'type', 'nature', 'status']}
                listTitle={i18n.nonSelectedParameters}
                selectionListTitle={i18n.selectedParameters}
                maxHeightTable={'53vh'}

                filterField={ParameterFilterField}
                filterFunction={parameterFilterFunction}
            />
        </>
    )
}

export default CatchmentsOptionApp