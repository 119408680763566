import React, { useEffect, useState } from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import { ceil, chunk, groupBy, last, range } from 'lodash'
import Checkbox from '../../../../../components/forms/Checkbox'
import { StyledFieldSet, StyledLegend } from '../../../../../components/StyledElements'
import i18n from 'simple-react-i18n'
import { MEASURE_COTE } from '../../../../constants/PiezometryConstants'
import Line from '../../../../../components/echart/series/Line'
import { getColorFromPalette } from '../../../../../utils/ColorUtil'
import { PREVIOUSYEARS } from '../../constants/PiezometerSuiviConstants'
import { arrayOf } from '../../../../../utils/StoreUtils'
import DtoPiezoMeasureLight from '../../../../dto/chart/DtoPiezoMeasureLight'
import { Grid2 } from '@mui/material'

const PiezoSuiviPastYearsTab2 = ({
    displayCote,
    landmarkValue, // sers à caluler la profondeur : depth = landmarkValue - NGF
    changeParent, // met à jour les state du parent (dont les séries liées à cette tab)
    measures,
    time, // tab sélectionné en haut à gauche
    minDate,
    maxDate,
    tab,
    typeId,
    isPiezo,
}) => {
    const [activeYears, setActiveYears] = useState([])
    const [groupedCivil, setGroupedCivil] = useState({})
    const [groupedHydro, setGroupedHydro] = useState({})

    useEffect(() => {
        setGroupedCivil(groupBy(measures, m => moment(m.date).year()))
        setGroupedHydro(groupBy(measures, m => moment(m.date).add(3, 'month').year()))
        setActiveYears([])
    }, [measures, typeId])

    useEffect(() => {
        const toEchartMeasure = (m) => {
            return ({ value: [
                m.date,
                isPiezo ? (displayCote === MEASURE_COTE.NGF ? m.NGF : landmarkValue - m.NGF) : m.value,
                { NGF: m.NGF, depth: landmarkValue - m.NGF },
            ], isPiezo })
        }
        const group = time === 'CIVIL_YEAR' || time === 'OTHER' ? groupedCivil : (time === 'HYDRO_YEAR' ? groupedHydro : null)
        if (!group) {
            changeParent({ yearSeries: [] })
        } else {
            const yearSeries = activeYears.flatMap((y, idx) => {
                const yearMeasures = (() => {
                    if (time === 'CIVIL_YEAR' || time === 'OTHER') {
                        const nowYear = time === 'OTHER' && (minDate ?? maxDate) ? moment(minDate ?? maxDate).year() : moment().year()
                        return (group[y] || []).map(m => toEchartMeasure({ ...m, date: moment(m.date).year(nowYear).valueOf() }))
                    }
                    return (group[y] || []).map(m => toEchartMeasure({ ...m, date: moment(m.date).add(3, 'month').year(moment().year()).subtract(3, 'month').valueOf() }))
                })()
                if (!yearMeasures.length) {
                    return []
                }
                return [Line({
                    data: yearMeasures,
                    name: y.toString(),
                    isPiezo,
                    connectNulls: false,
                    showSymbol: false,
                    color: getColorFromPalette(idx),
                })]
            })
            changeParent({ yearSeries })
        }
    }, [activeYears, time, minDate, maxDate, isPiezo]) // recalculé lorsqu'on change les checkbox, ou la tab en haut à gauche

    const changeYear = (y, v) => setActiveYears(v ? [...activeYears, y] : activeYears.filter(y2 => y2 !== y))

    if (!measures.length || tab !== PREVIOUSYEARS) {
        return null
    }
    const yearRange = range(moment(measures[0].date).year(), moment(last(measures).date).year() + 1)
    const yearCheckboxes = yearRange.map(y => (
        <div className='row no-margin'>
            <Checkbox checked={ activeYears.includes(y) } label={ y } onChange={ v => changeYear(y, v) } />
        </div>
    ))
    const groups = chunk(yearCheckboxes, ceil(yearCheckboxes.length / 4) || 1)
    return (
        <div>
            <StyledFieldSet>
                <StyledLegend>{ i18n.previousYears }</StyledLegend>
                <Grid2 container spacing={1} >
                    <Grid2 size={3}>
                        { groups[0] || [] }
                    </Grid2>
                    <Grid2 size={3}>
                        { groups[1] || [] }
                    </Grid2>
                    <Grid2 size={3}>
                        { groups[2] || [] }
                    </Grid2>
                    <Grid2 size={3}>
                        { groups[3] || [] }
                    </Grid2>
                </Grid2>
            </StyledFieldSet>
        </div>
    )
}

PiezoSuiviPastYearsTab2.propTypes = {
    tab: PropTypes.string,
    displayCote: PropTypes.number,
    id: PropTypes.number,
    minDate: PropTypes.number,
    maxDate: PropTypes.number,
    typeId: PropTypes.number,
    isPiezo: PropTypes.bool,
    landmarkValue: PropTypes.number,
    changeParent: PropTypes.func,
    measures: arrayOf(DtoPiezoMeasureLight),
    time: PropTypes.oneOf([
        PropTypes.number,
        PropTypes.string,
    ])
}

export default PiezoSuiviPastYearsTab2