import React, { useState } from 'react'
import { Button, Grid, Tooltip } from '@mui/material'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'

const TooltipButton = ({
    tooltip = {},
    disabled = false,
    children = '',
    className = '',
    color = 'primary',
}) => {
    const [openTooltip, setOpenTooltip] = useState(false)

    return (
        <Tooltip
            placement='top-start'
            arrow
            PopperProps={{
                disablePortal: true,
            }}
            open={openTooltip}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            title={
                <Grid style={{ padding: 5 }}>
                    <span style={{ fontSize: '14px' }}>{tooltip.question}</span>
                    <Grid container justifyContent='flex-end' className='padding-top-1'>
                        <Button variant='outlined' color='primary' className='white margin-right-1' onClick={() => setOpenTooltip(false)}>{i18n.no}</Button>
                        <Button onClick={() => {
                            tooltip.onClickYes()
                            setOpenTooltip(false)
                        }} variant='contained'
                        >{i18n.yes}</Button>
                    </Grid>
                </Grid>
            }
        >
            <Button
                onClick={() => setOpenTooltip(true)}
                className={className}
                color={color}
                disabled={disabled}
                variant='contained'
            >
                {children}
            </Button>
        </Tooltip>
    )
}

TooltipButton.propTypes = {
    tooltip: PropTypes.shape({
        question: PropTypes.string.isRequired,
        onClickYes: PropTypes.func.isRequired,
    }).isRequired,
    disabled: PropTypes.bool,
    children: PropTypes.string,
    className: PropTypes.string,
    color: PropTypes.string,
}

export default TooltipButton
