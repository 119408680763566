'use strict'
import { createSlice } from '@reduxjs/toolkit'
import { createIndex } from '../../../../utils/StoreUtils'
import ContributorThunk from '../actions/ContributorThunk'
import ContributorDomain from '../dto/ContributorDomain'
import ContributorDto from '../dto/ContributorDto'
import ContributorGroupDto from '../dto/ContributorGroupDto'
import ContributorItem from '../dto/ContributorItem'
import ContributorTypeDto from '../dto/ContributorTypeDto'
import DtoContributorTableLine from '../dto/DtoContributorTableLine'
import ProducerDto from '../dto/ProducerDto'

export const initialState = {
    contributors: [],
    contributorsIndex: {},
    producers: [],
    laboratories: [],
    contributor: {},
    contributorDomain: [],
    contributorTypes: [],
    contributorsTableCount: 0,
    contributorsTable: [],
    contributorsGroups: [],
    contributorsGroup: {},
}

const store = createSlice({
    name: 'contributor',
    initialState,
    reducers: {
        receiveContributorsTable: (state, action) => {
            state.contributorsTableCount = action.payload.count
            state.contributorsTable = action.payload.data.map(d => new DtoContributorTableLine(d))
        },
        receiveAllContributors: (state, action) => {
            const contributors = action.payload.map(contributor => new ContributorItem(contributor))
            state.contributors = contributors
            state.contributorsIndex = createIndex(contributors)
        },
        receiveContributor: (state, action) => {
            state.contributor = new ContributorDto(action.payload)
        },
        receiveContributorDomain: (state, action) => {
            state.contributorDomain = action.payload.map(contributorDomain => new ContributorDomain(contributorDomain))
        },
        resetContributor: (state) => {
            state.contributor = {}
        },
        receiveProducers: (state, action) => {
            state.producers = action.payload.map(producer => new ProducerDto(producer))
        },
        receiveLaboratories: (state, action) => {
            state.laboratories = action.payload.map(producer => new ProducerDto(producer))
        },
        receiveDeterminators: (state, action) => {
            state.determinators = action.payload.map(producer => new ProducerDto(producer))
        },
        receiveContributorTypes: (state, action) => {
            state.contributorTypes = action.payload.map(producer => new ContributorTypeDto(producer))
        },
        receiveAllContributorsGroups: (state, action) => {
            state.contributorsGroups = action.payload.map(producer => new ContributorGroupDto(producer))
        },
        receiveContributorsGroup: (state, action) => {
            state.contributorsGroup = new ContributorGroupDto(action.payload)
        },
    },
    extraReducers: {
        [ContributorThunk.fetchContributors.fulfilled]: (state, action) => {
            state.contributors = action.payload.map(contributor => new ContributorItem(contributor))
        },
    },
})

export const ContributorActionConstant = store.actions
export default store.reducer
