import { orderBy, uniq } from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import MultiContributorsAutocomplete from 'referencial/components/contributor/components/MultiContributorsAutocomplete'
import i18n from 'simple-react-i18n'
import ToastrAction from 'toastr/actions/ToastrAction'
import { hasValue } from 'utils/NumberUtil'
import Table from '../../../components/datatable/Table'
import DatePicker from '../../../components/forms/DatePicker'
import Input from '../../../components/forms/Input'
import RadioButtons from '../../../components/forms/RadioButtons'
import Select from '../../../components/forms/Select'
import AdesStationDto from '../../../import/dto/AdesStationDto'
import Job from '../../../import/dto/DtoJob'
import StationAction from '../../../quality/actions/QualityAction'
import DtoEsu from '../../../quality/dto/DtoEsu'
import ContributorAction from '../../../referencial/components/contributor/actions/ContributorAction'
import ContributorDto from '../../../referencial/components/contributor/dto/ContributorDto'
import NetworkAction from '../../../referencial/components/network/actions/NetworkAction'
import NetworkDto from '../../../referencial/components/network/dto/NetworkDto'
import { nbPerPageLabelTiny } from '../../../referencial/constants/ReferencialConstants'
import { arrayOf } from '../../../utils/StoreUtils'
import { Buffer } from 'buffer'

class ExportQuesu extends Component {
    constructor(props) {
        super(props)
        this.state = {
            filters: this.getJsonFilters(props.job.parameters.filters),
            codeBss: '',
            codeNetwork: '',
        }
    }

    componentDidMount() {
        if (!this.props.networks.length) {
            this.props.fetchNetworks()
        }
        if (!this.props.contributors.length) {
            this.props.fetchContributor()
        }
        if (!this.props.stationsEsu.length) {
            this.props.fetchEsuLight()
        }
    }

    getJsonFilters = filters => {
        return filters.length !== 0 ? JSON.parse(filters[0]) : {}
    }

    onDeleteBssCode = ({ bsscode }) => {
        if (this.props.isEditMode) {
            const codes = this.props.job.parameters.parameters.filter(p => p != bsscode)
            this.props.addBssCode(codes)
        }
    }

    addCodeBss = () => {
        if (this.props.isEditMode) {
            const { codeBss } = this.state
            const exist = this.props.job.parameters.parameters.find(p => p.toLowerCase() == codeBss.toLowerCase())
            const esu = this.props.stationsEsu.find(s => s.codebss.toLowerCase() == codeBss.toLowerCase())
            if (!codeBss) {
                this.props.toastError(i18n.youMustEnterAtLeastOneBssCode)
            } else if (exist) {
                this.props.toastError(i18n.youHaveAlreadyEnteredThisBssCode)
            } else if (!esu) {
                this.props.toastError(`${i18n.itsNotaStationEsu} ${codeBss}`)
            } else {
                this.props.addBssCode([codeBss, ...this.props.job.parameters.parameters])
                this.setState({ codeBss: '' })
            }
        }
    }

    addNetworkCode = () => {
        const { codeNetwork } = this.state
        const stationFiltered = this.props.stationsEsu.filter(s => s.codereseau == codeNetwork)
        const newCodes = stationFiltered.map(s => s.codebss)
        this.props.addBssCode(uniq([...this.props.job.parameters.parameters, ...newCodes]))
        this.setState({ codeNetwork: '' })
    }

    onChangeBssFile = e => {
        if (this.props.isEditMode) {
            const reader = new FileReader()
            const file = e.target.files[0]
            reader.onload = upload => {
                const split = upload.target.result.split(',')
                if (split[0].indexOf('text/csv') > 0 || split[0].indexOf('vnd.ms-excel') > 0) {
                    const buf = Buffer.from(split[1], 'base64').toString()
                    const codes = buf
                        .replace(/;/g, '')
                        .split('\n')
                        .map(b => b.trim())
                    codes.map(code => {
                        if (code.trim().length > 1) {
                            const exist = this.props.job.parameters.parameters.find(p => p.toLowerCase() == code.toLowerCase())
                            const esu = this.props.stationsEsu.find(s => s.codebss.toLowerCase() == code.toLowerCase())
                            if (!esu) {
                                this.props.toastError(`${i18n.itsNotaStationEsu} ${code}`)
                            } else if (!exist) {
                                this.props.addBssCode([code, ...this.props.job.parameters.parameters])
                            }
                        }
                    })
                } else {
                    this.props.toastError(i18n.theSelectedFileMustBeInCsvFormat)
                }
                this.refs.importFile.value = ''
            }
            reader.readAsDataURL(file)
        }
    }

    getStations = () => {
        return this.props.job.parameters.parameters.map(bsscode => new AdesStationDto({ bsscode }))
    }


    handleChangefilters = (key, value, key2, value2) => {
        //  this.setState({ filters: { ...this.state.filters, [key]: value, [key2]: value2 } })
        this.props.onChangeFilters([JSON.stringify({ ...this.state.filters, [key]: value, [key2]: value2 })])
    }

    handleChangeEmails = value => {
        const emails = value ? value.split(';') : []
        this.props.onChangeEmails(emails)
    }

    getEmails = () => {
        return this.props.job.parameters.emails ? this.props.job.parameters.emails.join(';') : ''
    }

    onChangeJobParameter = (changes) => {
        const jobParameters = {
            ...this.props.job.parameters,
            ...changes,
        }
        this.props.onChangeJob({ parameters: jobParameters })
    }

    onChangeFilters = (changes) => {
        const parameters = {
            ...this.props.job.parameters,
            filters: [JSON.stringify({
                ...this.getFilters(),
                ...changes,
            })],
        }
        this.props.onChangeJob({ parameters })
    }

    getFilters = () => this.props.job.parameters.filters.length ? JSON.parse(this.props.job.parameters.filters[0]) : {}

    getFilter = key => hasValue(this.state.filters[key]) ? this.state.filters[key] : ''

    getProducer = () =>[this.getFilter('idProducteur')].flatMap(p => p)

    render() {
        const disabled = { disabled: !this.props.isEditMode }
        const contributorsFiltered = this.props.contributors.filter(r => r.siret).map((c) => {
            return {
                ...c,
                labelAutocomplete: `${c.name} - ${c.siret}`,
            }
        })
        const contributorsFormatted = orderBy(contributorsFiltered, 'labelAutocomplete')
        const exportModes = [
            { code: 'ALL', name: 'Exporter tout l\'historique' },
            { code: 'CUSTOM', name: `${i18n.choiseDate}` },
        ]
        const selectStationsMode = [
            { code: 'IMPORT', name: 'Choisir par import' },
            { code: 'STATION', name: 'Choisir une station' },
            { code: 'RESEAU', name: 'Choisir un reseau' },
        ]
        const typeExportQuesu = [
            { code: 'STA', name: 'Station et point (Prochainement)' },
            { code: 'PHY', name: 'Physico-chimie' },
            { code: 'BIO', name: 'Biologie (Prochainement)' },
        ]
        const filters = this.getFilters()

        return (
            <div>
                <div className='row no-margin'>
                    <div className='row no-margin padding-top-1 valign-wrapper'>
                        <Input col={4} title={i18n.exportDirectory} value={this.props.job.parameters.path} obligatory
                            onChange={v => this.onChangeJobParameter({ path: v })} {...disabled}
                        />
                        <Select col={4} options={exportModes} label={i18n.exportMode}
                            onChange={v => this.onChangeFilters({ mode: v })} obligatory value={filters.mode} {...disabled}
                        />
                        {
                            filters.mode === 'CUSTOM' ? [
                                <DatePicker col={2} title={i18n.startDate} value={filters.startDate} onChange={(_, v) => this.onChangeFilters({ startDate: v })} {...disabled} />,
                                <DatePicker col={2} title={i18n.endDate} value={filters.endDate} onChange={(_, v) => this.onChangeFilters({ endDate: v })} {...disabled} />,
                            ] : <div className='col s4' />
                        }
                        <Input col={4} title={i18n.emailWithData} value={filters.email} onChange={v => this.onChangeFilters({ email: v })} {...disabled} />
                    </div>
                    <div className='col s12 no-padding padding-bottom-1'>
                        <Select
                            col={6}
                            label={`${i18n.sender} - ${i18n.siretNumber}`}
                            value={filters.idNumberEmetteur}
                            options={contributorsFormatted}
                            keyValue='id'
                            obligatory
                            keyLabel='labelAutocomplete'
                            onChange={(id, contributor) => this.onChangeFilters({ idNumberEmetteur: id, siretNumberEmetteur: contributor.siret })}
                            {...disabled}
                        />
                        <MultiContributorsAutocomplete
                            col={6}
                            multiple
                            options={contributorsFormatted}
                            values={filters.idProducteur?.length ? filters.idProducteur : []}
                            onChange={values => {
                                this.onChangeFilters({ idProducteur: values })
                            }}
                            label={`${i18n.filter} - ${i18n.byProducer}`}
                            {...disabled}
                        />
                    </div>
                    <div className='col s12 no-padding padding-bottom-1'>
                        <MultiContributorsAutocomplete
                            col={6}
                            label={`${i18n.receiver} - ${i18n.siretNumber}`}
                            values={this.getFilter('idNumberDestinataire')}
                            options={contributorsFormatted}
                            keyValue='id'
                            obligatory
                            keyLabel='labelAutocomplete'
                            onChange={idContributor => {
                                const { contributors } = this.props
                                const siret = contributors.find(c => c.id === idContributor)?.siret
                                return this.onChangeFilters({ idNumberDestinataire: idContributor, siretNumberDestinataire: siret })
                            }}
                            {...disabled}
                        />
                    </div>
                </div>
                <div className='row no-margin valign-wrapper'>
                    <div className='col s6 no-padding padding-bottom-1'>
                        <fieldset className='width-50' >
                            <legend >&nbsp;{i18n.selectStations}&nbsp;</legend>
                            <RadioButtons elements={selectStationsMode} selected={filters.selectStationsMode || 'FILTERS'} onChange={v => this.onChangeFilters({ selectStationsMode: v })} {...disabled} />
                        </fieldset>
                    </div>
                    <div className='col s6 no-padding padding-bottom-1'>
                        <fieldset className='width-50'>
                            <legend>&nbsp;{i18n.type}&nbsp;</legend>
                            <RadioButtons elements={typeExportQuesu} selected={filters.typeExportQuesu || 'PHY'} onChange={v => this.onChangeFilters({ typeExportQuesu: v })} disabled='true' />
                        </fieldset>
                    </div>
                </div>
                <div className='col s12 no-padding padding-bottom-1'>
                    <div className='row no-margin'>
                        {
                            filters.selectStationsMode === 'IMPORT' ? (
                                <div className='col s12 no-padding padding-bottom-1'>
                                    <div className='col s3'>
                                        <label>{i18n.importFile}</label>
                                    </div>
                                    <div className='file-field col s9 no-padding input-field'>
                                        <div className='col s3 no-padding btn'
                                            {...disabled}
                                        >
                                            <span>{i18n.importStations}</span>
                                            <input type='file' ref='importFile' accept='.csv' onChange={this.onChangeBssFile}{...disabled} />
                                        </div>
                                        <div className='file-path-wrapper col s9'>
                                            <input className='file-path' ref='fileInput' type='text' placeholder={i18n.selectFile}{...disabled} />
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                !filters.selectStationsMode || filters.selectStationsMode === 'STATION' ? (
                                    <div className='input-field col s6 no-padding'>
                                        <div className='col s4'>
                                            <Input
                                                id='txtAddBssCode'
                                                type='text'
                                                title={i18n.stationCode}
                                                onChange={v => this.setState({ codeBss: v })}
                                                value={this.state.codeBss}
                                                ref='txtAddBssCode'
                                                {...disabled}
                                            />
                                        </div>
                                        <a className='waves-effect waves-light btn col offset-s1 s4' onClick={this.addCodeBss}
                                            {...disabled}
                                        >
                                            {i18n.add}
                                        </a>
                                    </div>
                                ) : (
                                    <div className='input-field col s6 no-padding'>
                                        <div className='col s6'>
                                            <Select
                                                options={orderBy(this.props.networks, 'name')}
                                                label={i18n.importStationByNetwork}
                                                value={this.state.codeNetwork}
                                                onChange={r => this.setState({ codeNetwork: r })}
                                                {...disabled}
                                            />
                                        </div>
                                        <a className='waves-effect waves-light btn col offset-s1 s4' onClick={this.addNetworkCode}
                                            {...disabled}
                                        >
                                            {i18n.add}
                                        </a>
                                    </div>
                                )
                            )
                        }
                    </div>
                </div>
                <div className='row no-margin'>
                    <div className='col s12 no-padding padding-bottom-1'>
                        <div className='col s12'>
                            <Table
                                type={new AdesStationDto()}
                                data={this.getStations()}
                                title={i18n.credentials}
                                nbPerPageLabel={nbPerPageLabelTiny}
                                onDelete={this.onDeleteBssCode}
                                deletable={this.props.isEditMode}
                                showNbElements
                                paging
                                exportButtonOnHeader
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

ExportQuesu.propTypes = {
    job: PropTypes.instanceOf(Job).isRequired,
    networks: arrayOf(NetworkDto),
    isEditMode: PropTypes.bool,
    addBssCode: PropTypes.func.isRequired,
    onChangeDataTypes: PropTypes.func,
    onChangeFilters: PropTypes.func,
    onChangeJob: PropTypes.func.isRequired,
    onChangeEmails: PropTypes.func,
    toastError: PropTypes.func,
    contributors: arrayOf(PropTypes.instanceOf(ContributorDto)),
    stationsEsu: arrayOf(PropTypes.instanceOf(DtoEsu)),
}

const mapDispatchToProps = {
    toastError: ToastrAction.error,
    fetchNetworks: NetworkAction.fetchNetworks,
    fetchContributor: ContributorAction.fetchContributors,
    fetchEsuLight: StationAction.fetchEsuLight,
}

const mapStateToProps = store => ({
    contributors: store.ContributorReducer.contributors,
    networks: store.NetworkReducer.networks,
    stationsEsu: store.QualityReducer.stationsEsu,
})

export default connect(mapStateToProps, mapDispatchToProps)(ExportQuesu)
