import Feature from 'ol/Feature'
import Point from 'ol/geom/Point'
import VectorLayer from 'ol/layer/Vector'
import * as olProj from 'ol/proj'
import VectorSource from 'ol/source/Vector'
import React from 'react'
import MapPopupStation from '../../../components/map/popup/MapPopupStation'
import { EPSG3857, EPSG4326, getWGS84Coordinate } from '../CoordinateUtils'
import ILayers from '../ILayers'

class _SingleStationPoint extends ILayers {
    constructor(obj) {
        super()
        this.type = obj.type
        this.objPoints = obj
        this.layer = this.createLayer()
        this.popup = obj.popup
    }

    createLayer() {
        const { site } = this.objPoints
        const feature = this.createFeature(
            site.x || site.localisation?.x,
            site.y || site.localisation?.y,
            site.projection || site.localisation?.projection,
            this.objPoints.type.img,
            site.name,
            site
        )
        const vectorSource = new VectorSource({
            features: [feature],
        })

        return new VectorLayer({
            source: vectorSource,
        })
    }

    getLayer() {
        return this.layer
    }

    createFeature(x, y, projection, style, name, site) {
        const center = getWGS84Coordinate({
            x,
            y,
            projection,
        })
        const f = new Feature({
            geometry: new Point(olProj.transform(center, EPSG4326, EPSG3857)),
            name,
            hover: true,
        })
        f.setId(this.registerFeature(site))
        f.setStyle(style)
        return f
    }

    getPopup(setStation, layer, feature, click, currentStation) {
        return <MapPopupStation setStation={ setStation } station={ layer.getUuid().find((el) => el.uuid === feature.id).obj } click={ click } currentStation={ currentStation }/>
    }

    getStation(layer, feature) {
        return layer.getUuid().find((el) => el.uuid === feature.id).obj
    }
}

export default function SingleStationPoint(...args) {
    return new _SingleStationPoint(...args)
}
