import { useMemo } from 'react'

const useParseJson = (jsonString) => {
    return useMemo(() => {
        try {
            return { json: JSON.parse(jsonString), error: undefined }
        } catch (e) {
            return { json: {}, error: e.message }
        }
    }, [jsonString])
}

export default useParseJson