import SimpleFilterSelect from 'components/forms/specific/SimpleFilterSelect'
import { intersectionWith, isUndefined } from 'lodash'
import PropTypes from 'prop-types'
import React, { useMemo, useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import { authorizeFilter } from 'utils/HabilitationUtil'
import Input from '../../../components/forms/Input'
import Select from '../../../components/forms/Select'
import DtoEvent from '../../../events/dto/DtoEvent'
import DtoHydrometricStation from '../../../hydrometry/dto/DtoHydrometricStation'
import DtoInstallation from '../../../installation/dto/installation/DtoInstallation'
import DtoPiezometer from '../../../piezometry/dto/DtoPiezometer'
import PluviometerDto from '../../../pluviometry/dto/PluviometerDto'
import DtoQualitometerLight from '../../../quality/dto/DtoQualitometerLight'
import NetworkLinkDto from '../../../referencial/components/network/dto/NetworkLinkDto'
import { searchAllCharacters } from '../../../utils/StringUtil'
import useListIndexed from 'utils/customHook/useListIndexed'
import SimpleSelectionTable from 'components/datatable/SimpleSelectionTable'
import { Grid } from '@mui/material'
import useDebounce from 'utils/customHook/useDebounce'
import { STATION_TYPE_NAME } from 'station/constants/StationConstants'
import moment from 'moment'
import { EVENT_TYPES_CODES } from 'events/constants/EventsConstants'

const stationFilterFunction = (list, filters) => {
    const {
        searchValue,
        filter,
        resultFilter,
        city,
        network,
    } = filters

    const availableStations = filter !== -1 ? intersectionWith(list, resultFilter, (elem, res) => elem.id === res.id) : list
    const searchValueFormat = searchAllCharacters(searchValue)
    const filterSearchValue = searchValue ? availableStations.filter(s => s.labelSearch.includes(searchValueFormat)) : availableStations
    const filterNetwork = !isUndefined(network) ? filterSearchValue.filter(s => s.networks.some(n => n.idNetwork === network)) : filterSearchValue
    return city ? filterNetwork.filter(p => p.townCode === city) : filterNetwork
}

const StationFilterField = ({
    filter = {},
    setFilter = () => { },

    stations = [],
    stationType,
}) => {
    const {
        cities,
        networks,
        accountUser,
    } = useSelector(store => ({
        cities: store.CityReducer.cities,
        networks: store.NetworkReducer.networks,
        accountUser: store.AccountReducer.accountUser,
    }), shallowEqual)

    const [stateFilter, setStateFilter] = useState(filter)
    /*
        searchValue
        filter
        resultFilter
        city
        network
    */

    useDebounce(() => {
        setFilter(stateFilter)
    }, 500, [stateFilter])

    return (
        <Grid container columnSpacing={2} rowSpacing={1} style={{ paddingTop: '5px' }}>
            <Grid item xs={6}>
                <Input
                    title={i18n.search}
                    value={stateFilter.searchValue}
                    onChange={searchValue => setStateFilter(prev => ({ ...prev, searchValue }))}
                />
            </Grid>
            <Grid item xs={6}>
                <SimpleFilterSelect
                    stationType={stationType}
                    onChange={(resultFilter, newFilter) => {
                        setStateFilter(prev => ({ ...prev, resultFilter, filter: newFilter }))
                    }}
                    stations={stations}
                />
            </Grid>
            <Grid item xs={6}>
                <Select
                    label={i18n.city}
                    value={stateFilter.city}
                    options={cities}
                    onChange={city => setStateFilter(prev => ({ ...prev, city }))}
                    keyLabel='labelWithCode'
                />
            </Grid>
            {
                stationType === STATION_TYPE_NAME.quality && (
                    <Grid item xs={6}>
                        <Select
                            label={i18n.network}
                            options={authorizeFilter(networks, accountUser, stateFilter.network)}
                            value={stateFilter.network}
                            onChange={network => setStateFilter(prev => ({ ...prev, network }))}
                            clearFunction
                        />
                    </Grid>
                )
            }
        </Grid>
    )
}

StationFilterField.propTypes = {
    filter: PropTypes.shape({
        searchValue: PropTypes.string,
        resultFilter: PropTypes.arrayOf(PropTypes.number),
        filter: PropTypes.number,
        city: PropTypes.string,
    }),
    setFilter: PropTypes.func,

    stations: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.instanceOf(PluviometerDto)),
        PropTypes.arrayOf(PropTypes.instanceOf(DtoPiezometer)),
        PropTypes.arrayOf(PropTypes.instanceOf(DtoQualitometerLight)),
        PropTypes.arrayOf(PropTypes.instanceOf(DtoHydrometricStation)),
        PropTypes.arrayOf(PropTypes.instanceOf(DtoInstallation)),
    ]),
    stationType: PropTypes.oneOf(['piezometry', 'pluviometry', 'hydrometry', 'quality', 'installation']),
}

const SelectStation = ({
    stations = [],
    networkLinks = [],
    selectedStations = [],
    campaignEvents = [],
    stationType,
    onChangeSelectedStation = () => {},
}) => {
    const {
        cities,
    } = useSelector(store => ({
        cities: store.CityReducer.cities,
    }), shallowEqual)

    const indexedCities = useListIndexed(cities, 'id')

    const formattedStations = useMemo(() => {
        const date = moment().valueOf()
        const monitoringCampaignsEvents = campaignEvents.filter(event => event.eventType === EVENT_TYPES_CODES.TO_MONITOR && (!event.closeDate || event.closeDate > date))
        const toPlanCampaignsEvents = campaignEvents.filter(event => event.eventType === EVENT_TYPES_CODES.TO_PLAN && (!event.closeDate || event.closeDate > date))

        return stations.map(s => {
            const city = indexedCities[s.townCode]
            const monitoring = monitoringCampaignsEvents.filter(e => e.stationId === s.id).length
            const toPlanEvents = toPlanCampaignsEvents.filter(e => e.stationId === s.id).length
            return {
                id: s.id,
                name: `${s.code ? `[${s.code}] ` : ''}${s.name}`,
                city: city?.labelWithCode || '',
                nullValue: [
                    monitoring !== 0 && <i className={'material-icons'} >notifications</i>,
                    toPlanEvents !== 0 && <i className={'material-icons'} >assignment</i>,
                ],
                townCode: s.townCode,
                networks: networkLinks.filter(n => n.idStation === s.id),
                installationType: s.installationType,
                labelSearch: searchAllCharacters([s.code, s.name, s.townCode, city?.name].filter(l => !!l).join(',')),
            }
        })
    }, [campaignEvents, stations, indexedCities, networkLinks])

    const onChange = (newList) => {
        onChangeSelectedStation(newList.map(id => ({ id })))
    }

    return (
        <SimpleSelectionTable
            onChange={onChange}

            listData={formattedStations}
            selectedList={selectedStations.map(s => s.id)}

            listHeaders={['name', 'city', 'nullValue']}
            listTitle={i18n.nonSelectedStations}

            selectedListTitle={i18n.selectedStations}
            maxHeightTable='43vh'

            filterField={({ filter, setFilter }) => <StationFilterField filter={filter} setFilter={setFilter} stations={stations} stationType={stationType}/>}
            filterFunction={stationFilterFunction}
        />
    )
}

SelectStation.propTypes = {
    stations: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.instanceOf(PluviometerDto)),
        PropTypes.arrayOf(PropTypes.instanceOf(DtoPiezometer)),
        PropTypes.arrayOf(PropTypes.instanceOf(DtoQualitometerLight)),
        PropTypes.arrayOf(PropTypes.instanceOf(DtoHydrometricStation)),
        PropTypes.arrayOf(PropTypes.instanceOf(DtoInstallation)),
    ]),
    selectedStations: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
    })),
    campaignEvents: PropTypes.arrayOf(PropTypes.instanceOf(DtoEvent)),
    onChangeSelectedStation: PropTypes.func,
    stationType: PropTypes.oneOf(['piezometry', 'pluviometry', 'hydrometry', 'quality', 'installation']),
    networkLinks: PropTypes.arrayOf(PropTypes.instanceOf(NetworkLinkDto)),
}

export default SelectStation
