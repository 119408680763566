import { find, groupBy, max, maxBy, meanBy, min, minBy, orderBy, round, some, sumBy } from 'lodash'
import moment from 'moment'
import React from 'react'
import i18n from 'simple-react-i18n'
import { crossChartSymbol } from '../components/echart/EChartUtils'
import Band from '../components/echart/series/Band'
import Bar from '../components/echart/series/Bar'
import CustomEventDuration from '../components/echart/series/CustomEventDuration'
import Line from '../components/echart/series/Line'
import Scatter from '../components/echart/series/Scatter'
import { MEASURE_COTE } from '../piezometry/constants/PiezometryConstants'
import PiezometerStationAction from '../station/actions/PiezometerStationAction'
import { getColorCircleElement, getEventColor, getRGBColor, getThresholdColor } from './ColorUtil'
import { getDate, getDateWithHour, getDateWithHourString } from './DateUtil'
import { hasValue } from './NumberUtil'

// get the measure value to the desired "cote" system, all systems are calculated from the measure NGF (NGF key)
const getMeasureValue = (measure, cote, lastLandmark, groundAltSystem, initialMode = false, roundValue = 3) => {
    const NGF = initialMode ? 'initialNGF' : 'NGF'
    const value = initialMode ? 'initialValue' : 'value'
    const res = (() => {
        switch (cote) {
            case MEASURE_COTE.NGF:
                return round(hasValue(measure[NGF]) ? measure[NGF] : (measure.cote === MEASURE_COTE.NGF ? measure[value] : (
                    measure.cote === MEASURE_COTE.DEPTH ? lastLandmark - measure[value] : null
                )), roundValue)
            case MEASURE_COTE.DEPTH:
                if (hasValue(lastLandmark) && hasValue(measure[NGF])) {
                    return round(lastLandmark - measure[NGF], roundValue)
                } else if (hasValue(measure.landmark) && hasValue(measure.refAlti) && hasValue(measure[NGF])) {
                    return round((measure.landmark + measure.refAlti) - measure[NGF], roundValue)
                } else if (measure.cote === MEASURE_COTE.DEPTH) {
                    return round(measure[value], roundValue)
                } else if (measure.cote === MEASURE_COTE.NGF && hasValue(lastLandmark) && hasValue(measure[NGF])) {
                    return round(lastLandmark - measure[NGF], roundValue)
                }
                return null
            case MEASURE_COTE.GROUND:
                if (hasValue(groundAltSystem) && hasValue(measure[NGF])) {
                    return round(groundAltSystem - measure[NGF], roundValue)
                }
                return null
            case MEASURE_COTE.LANDMARK:
                if (hasValue(measure[NGF]) && hasValue(measure.landmark) && hasValue(measure.refAlti)) {
                    return round(measure.refAlti + measure.landmark - measure[NGF], roundValue)
                }
                return getMeasureValue(measure, MEASURE_COTE.DEPTH, lastLandmark, groundAltSystem, initialMode, roundValue)
            default:
                return round(measure[value], roundValue)
        }
    })()
    return round(res, roundValue)
}

// get the NGF value from a measure displayed with "cote" system
const getNGFValue = (measure, cote, lastLandmark, groundAltSystem, roundValue = 3) => {
    switch (cote) {
        case MEASURE_COTE.NGF:
            return round(hasValue(measure.NGF) ? measure.NGF : measure.value, roundValue)
        case MEASURE_COTE.DEPTH:
            if (hasValue(lastLandmark) && hasValue(measure.value)) {
                return round(lastLandmark - measure.value, roundValue)
            } else if (hasValue(measure.landmark) && hasValue(measure.refAlti) && hasValue(measure.value)) {
                return round((measure.landmark + measure.refAlti) - measure.value, roundValue)
            } else if (measure.cote === MEASURE_COTE.NGF) {
                return round(measure.value, roundValue)
            }
            return null
        case MEASURE_COTE.GROUND:
            if (hasValue(groundAltSystem) && hasValue(measure.value)) {
                return round(groundAltSystem - measure.value, roundValue)
            }
            return null
        case MEASURE_COTE.LANDMARK:
            if (hasValue(measure.value) && hasValue(measure.landmark) && hasValue(measure.refAlti)) {
                return round(measure.refAlti + measure.landmark - measure.value, roundValue)
            }
            if (hasValue(lastLandmark) && hasValue(measure.value)) {
                return round(lastLandmark - measure.value, roundValue)
            }
            return null
        default:
            return round(measure.value, roundValue)
    }
}

// get the measure value to the desired "cote" system, all systems are calculated from the measure depth (value key)
const getMeasureValueFromDepth = (measure, cote, lastLandmark, groundAltSystem) => {
    const NGF = getNGFValue(measure, MEASURE_COTE.DEPTH, lastLandmark, groundAltSystem)
    const newMeasure = { ...measure, NGF }
    return getMeasureValue(newMeasure, cote, lastLandmark, groundAltSystem)
}

const setMeasureLandmarkAndRefAlti = (measure, piezo) => {
    const foundLandmark = maxBy(piezo.link_landmarks.filter(l => l.startDate < measure.date && !(l.endDate || l.endDate > measure.date)), 'startDate')
    if (foundLandmark) {
        const foundAlti = piezo.link_altimetrySystems.find(alt => alt.natureCode === foundLandmark.altimetrySystemNature && alt.startDate === foundLandmark.altimetrySystemDate)
        if (foundAlti) {
            return { ...measure, refAlti: foundAlti.altitude, landmark: foundLandmark.height }
        }
    }
    return measure
}

const getEventMarkPoint = (events = [], measures = []) => {
    const data = events.map(t => {
        const xAxis = moment(t.date).format('YYYY/MM/DD')
        const measure = find(measures, m => m.value[0] === t.date)
        const itemStyle = { color: getEventColor(t.eventType) }
        if (measure && measure.value[1]) {
            return { xAxis, yAxis: measure.value[1], itemStyle }
        }
        return { xAxis, yAxis: 0, symbolRotate: 180, itemStyle }
    })
    return { symbolSize: 30, data }
}

const getEventGraph = (events, overrideOptions = {}) => {
    const data = events.map(e => {
        const startDate = e.startDate || getDateWithHour(e.date, e.eventHour).valueOf()
        const endDate = e.endDate
        return {
            value: [startDate, endDate, e],
            itemStyle: { color: getRGBColor(getEventColor(e.eventType)) },
        }
    })
    return CustomEventDuration({
        data,
        name: i18n.events,
        serieId: 'events',
        ...overrideOptions,
    })
}

const getEventsBar = (events, overrideOptions = {}) => {
    const data = events.map(e => ({
        value: [e.startDate || getDateWithHour(e.date, e.eventHour).valueOf(), 1, e],
        itemStyle: { color: getRGBColor(getEventColor(e.eventType)) },
        tooltip: overrideOptions.tooltip,
    }))
    return Bar({
        data,
        name: i18n.events,
        barWidth: '10px',
        serieId: 'events',
        ...overrideOptions,
    })
}

const getThresholdsMarkLine = (piezometer = {}, thresholds = [], piezoMode, other = []) => {
    const lines = thresholds.map(t => {
        const calculated = piezoMode ? getMeasureValue({ NGF: t.NGF, landmark: t.lastLandmark, refAlti: 0 }, t.displayCote, t.lastLandmark, t.groundRefAlti) || 0 : t.value
        return {
            yAxis: calculated + (t.bandCorrection ? 1000 : 0),
            symbol: 'none',
            label: {
                show: true,
                position: 'middle',
                formatter: () => t.name ? `${t.name} : ${round(calculated, 2)} ${t.unit ?? ''}` : '',
            },
            lineStyle: {
                normal: {
                    color: t.htmlColor || getThresholdColor(t.color),
                    type: 'dashed',
                },
            },
        }
    })
    return { silent: false, data: [ ...other, ...lines ], symbol: ['none', 'none'] }
}

const getPiezometerMeasures = (measures, piezometer = null, gridIndex = 0, thresholds = [], events = [], overrideOptions = {}, withEvents = false) => {
    return Line({
        data: measures,
        name: i18n.chronic,
        markLine: getThresholdsMarkLine(piezometer, thresholds), // thresholds
        markPoint: getEventMarkPoint(events, measures), // events
        connectNulls: false,
        showSymbol: false,
        yAxisIndex: withEvents ? gridIndex + 1 : gridIndex,
        xAxisIndex: gridIndex,
        color: '#3d72d2',
        serieId: 'measures',
        ...overrideOptions,
    })
}

const getDryingTrendSpecificOptions = (indexTrend) => {
    switch (indexTrend) {
        case 4:
            return { name: 'Prédiction', color: '#33cb00', lineStyle: { type: 'dashed' } }
        case 5:
            return { name: 'Prédiction moyenne', color: '#870000', lineStyle: { type: 'dashed' } }
        case 6:
            return { name: 'Prédiction (0,05 quantile)', color: 'black', lineStyle: { type: 'dashed' } }
        case 7:
            return { name: 'Prédiction (0,95 quantile)', color: 'black', lineStyle: { type: 'dashed' } }
        default:
            return {}
    }
}

const getCorrectedMeasures = (measures, gridIndex = 0, overrideOptions = {}, withEvents = false) => {
    return Line({
        data: measures,
        name: i18n.corrected,
        connectNulls: false,
        showSymbol: false,
        yAxisIndex: withEvents ? gridIndex + 1 : gridIndex,
        xAxisIndex: gridIndex,
        color: '#397eef',
        serieId: 'correctedSerie',
        ...overrideOptions,
    })
}

const getPiezometerMinMeasures = (measures, piezometer = null, gridIndex = 0, thresholds = [], events = [], overrideOptions = {}, withEvents = false) => {
    return Line({
        data: measures,
        name: i18n.minimum,
        markLine: getThresholdsMarkLine(piezometer, thresholds), // thresholds
        markPoint: getEventMarkPoint(events, measures), // events
        connectNulls: false,
        showSymbol: false,
        color: '#f74b4b',
        yAxisIndex: withEvents ? gridIndex + 1 : gridIndex,
        xAxisIndex: gridIndex,
        serieId: 'minMeasures',
        ...overrideOptions,
    })
}

const getPiezometerAdditionalMeasures = (measures, label, color, type, gridIndex = 0, thresholds = [], overrideOptions = {}, withEvents = false, isBarGraph = false) => {
    const input = {
        data: measures,
        name: label,
        connectNulls: false,
        showSymbol: false,
        color,
        yAxisIndex: withEvents ? gridIndex + 1 : gridIndex,
        xAxisIndex: gridIndex,
        serieId: `additional${type}`,
        markLine: getThresholdsMarkLine(undefined, thresholds), // thresholds
        ...overrideOptions,
    }
    return isBarGraph ? Bar(input) : Line(input)
}

const getPiezometerSamples = (samples, gridIndex = 0, thresholds = [], overrideOptions = {}, withEvents = false, color = '#1922fa') => {
    return Bar({
        data: samples,
        name: i18n.nbSample,
        itemStyle: {
            color,
        },
        lineStyle: {
            color,
        },
        markLine: getThresholdsMarkLine(undefined, thresholds), // thresholds
        barMaxWidth: '5px',
        yAxisIndex: withEvents ? gridIndex + 1 : gridIndex,
        xAxisIndex: gridIndex,
        serieId: 'samples',
        showSymbol: false,
        area: { color, opacity: 1 },
        ...overrideOptions,
    })
}

const getPiezometerMinMax = (measures, minMeasures, piezometer = null, gridIndex = 0, thresholds = [], events = [], overrideOptions = {}, withEvents = false) => {
    return Band({
        color: 'rgba(83, 161, 255, 0.75)',
        showSymbol: false,
        markLine: getThresholdsMarkLine(piezometer, thresholds), // thresholds
        markPoint: getEventMarkPoint(events, measures), // events
        connectNulls: false,
        yAxisIndex: withEvents ? gridIndex + 1 : gridIndex,
        xAxisIndex: gridIndex,
        min: {
            name: i18n.minimum,
            color: '#f74b4b',
            values: minMeasures,
            lineStyle: {
                normal: {
                    opacity: 1,
                },
            },
            serieId: 'minMeasures',
        },
        max: {
            name: i18n.maximum,
            values: measures,
            serieId: 'measures',
        },
        ...overrideOptions,
    })
}

const getPiezometerRawMeasures = (rawMeasures, piezometer = null, gridIndex = 0, thresholds = [], events = [], overrideOptions = {}, withEvents = false, bar = false, otherMarkLines = []) => {
    const input = {
        data: rawMeasures,
        name: i18n.chronic,
        yAxisIndex: withEvents ? gridIndex + 1 : gridIndex,
        xAxisIndex: gridIndex,
        markLine: getThresholdsMarkLine(piezometer, thresholds, true, otherMarkLines), // thresholds
        markPoint: getEventMarkPoint(events, rawMeasures), // events
        connectNulls: false,
        showSymbol: true,
        color: '#222',
        serieId: 'bruteMeasures',
        ...overrideOptions,
    }
    return bar ? Bar(input) : Line(input)
}

const getStaticDepthEvents = (events, gridIndex = 0, overrideOptions = {}, withEvents = false) => {
    return Scatter({
        data: events,
        name: i18n.chronic,
        yAxisIndex: withEvents ? gridIndex + 1 : gridIndex,
        xAxisIndex: gridIndex,
        connectNulls: false,
        showSymbol: true,
        color: '#222',
        serieId: 'staticLevel',
        symbol: crossChartSymbol,
        ...overrideOptions,
    })
}

const checkStatusQualificationConsistency = (status, qualification) => {
    if (!hasValue(status) || !hasValue(qualification)) {
        return null
    }
    if (status === 1) {
        if ([0, 4].includes(qualification)) {
            return null
        }
        return i18n.statusQualificationConsistency1
    }
    if (status === 2 || status === 3 || status === 4) {
        if ([1, 2, 3].includes(qualification)) {
            return null
        }
        return i18n.statusQualificationConsistency2
    }
    return null
}

const measureHasDate = (m) => hasValue(m.date) || hasValue(m.sampleDate)

const getPiezometrySeriesParams = () => [
    { code: 'measures', label: 'measures', dateKey: 'date', hourKey: 'hour', serieId: 'measures', groupFunc: max, unit: 'm',
        updateFunc: PiezometerStationAction.updatePiezometerMeasures, deleteFunc: PiezometerStationAction.deletePiezometerMeasures },
    { code: 'piezometerMeasureBrute', label: 'bruteMeasures', dateKey: 'date', hourKey: 'hour', serieId: 'bruteMeasures', groupFunc: max, unit: 'm',
        updateFunc: PiezometerStationAction.updatePiezometerRawMeasures, deleteFunc: PiezometerStationAction.deletePiezometerRawMeasures },
    { code: 'piezometerMeasureMin', label: 'minimum', dateKey: 'date', hourKey: 'hour', serieId: 'minMeasures', groupFunc: min, unit: 'm',
        updateFunc: PiezometerStationAction.updatePiezometerMinMeasures, deleteFunc: PiezometerStationAction.deletePiezometerMinMeasures },
]

const getValidPiezometrySeriesParams = () => [{
    code: 'measures',
    label: 'measures',
    dateKey: 'date',
    hourKey: 'hour',
    serieId: 'measures',
    groupFunc: max,
    unit: 'm',
    updateFunc: PiezometerStationAction.updatePiezometerMeasures,
    deleteFunc: PiezometerStationAction.deletePiezometerMeasures,
}]

const getHardPiezoDataTypes = () => [
    { id: -1, label: i18n.depth, unit: 'm' },
    { id: -2, label: i18n.sample, unit: 'm' },
]

const getControlAlert = (controlError) => (
    <div className='row no-margin'>
        <span className='padding-left-1'>{ getColorCircleElement('red', true) }</span>
        <span className='bold'>{ getDateWithHourString(controlError.date) }</span>
        <span className='padding-left-1'>{ i18n[`piezoControl${controlError.id}`] }</span>
    </div>
)

const getMeasureStatusColor = (status) => {
    switch (status) {
        case '3':
        case 3:
            return 'green'
        case '2':
        case 2:
            return 'lightgreen'
        case '4':
        case 4:
            return 'blue'
        default:
            return 'grey'
    }
}

const getMeasureStatusColorTable = (status) => {
    switch (status) {
        case '3':
        case 3:
            return '#00800080'
        case '2':
        case 2:
            return '#90ee9085'
        case '4':
        case 4:
            return '#0000ff78'
        default:
            return '#8080807a'
    }
}

const getDisplayCote = (displayCote) => {
    return (() => {
        switch (displayCote) {
            case MEASURE_COTE.DEPTH:
                return 'Profondeur'
            case MEASURE_COTE.NGF:
                return 'NGF'
            case MEASURE_COTE.GROUND:
                return 'Sol'
            case MEASURE_COTE.LANDMARK:
                return 'Historique des repères'
            default:
                return null
        }
    })()
}

const getMeasureModePeriodicityIcon = (measureMode) => {
    switch (measureMode) {
        case 1:
            return { icon: 'directions_run', color: 'black' }
        case 2:
            return { icon: 'multiline_chart', color: 'purple' }
        case 3:
            return { icon: 'playlist_play', color: 'green' }
        case 4:
            return { icon: 'router', color: 'blue' }
        default:
            return { icon: 'help', color: 'grey' }
    }
}

const getUpdatedLandmarkDate = (piezo, updatedLandmarkOrAlti) => {
    if (piezo.typeName !== 'piezometry') {
        return {}
    }
    const updatedMinLandmark = min([piezo.updatedMinLandmark, updatedLandmarkOrAlti.startDate].filter(l => !!l))
    const list = [piezo.updatedMaxLandmark, updatedLandmarkOrAlti.endDate].filter(l => !!l)
    const updatedMaxLandmark = list.length ? max(list) : moment().valueOf()
    return { updatedMinLandmark, updatedMaxLandmark }
}

const getGroupedMeasures = (measures, chartMinDate, chartMaxDate, groupFunc = 'max', forceBrute = false, forceDaily = false, isPiezo = false) => {
    if (forceBrute) {
        return measures
    }
    const duration = moment.duration(moment(chartMaxDate).diff(chartMinDate))
    if (duration.months() > 3 || duration.years() >= 1 || forceDaily) {
        const measuresObj = groupBy(measures, m => getDate(m.date))
        return Object.keys(measuresObj).map(date => {
            if (measuresObj[date].length === 1) {
                return measuresObj[date][0]
            }
            const key = (() => {
                if (isPiezo) {
                    return some(measures, m => hasValue(m.NGF)) ? 'NGF' : 'value'
                }
                return 'value'
            })()
            if (groupFunc === 'max') {
                const found = maxBy(measuresObj[date], key)
                return { ...found, initialPoint: some(measuresObj[date], m => m.initialPoint === 1) ? 1 : 0 }
            }
            if (groupFunc === 'min') {
                const found = minBy(measuresObj[date], key)
                return { ...found, initialPoint: some(measuresObj[date], m => m.initialPoint === 1) ? 1 : 0 }
            }
            if (groupFunc === 'sum') {
                const found = sumBy(measuresObj[date], key)
                return { ...found, initialPoint: some(measuresObj[date], m => m.initialPoint === 1) ? 1 : 0 }
            }
            if (groupFunc === 'mean') {
                return {
                    ...measuresObj[date][0],
                    initialPoint: some(measuresObj[date], m => m.initialPoint === 1) ? 1 : 0,
                    NGF: meanBy(measuresObj[date], 'NGF'),
                    value: meanBy(measuresObj[date], 'value'),
                    date: moment(measuresObj[date][0].date).hour(12).valueOf(),
                }
            }
            return measuresObj[date][0]
        })
    }
    return measures
}

const getSerieData = (params) => {
    const defaultParams = {
        isPiezo: false,
        groupFunc: 'max',
        forceBrute: false,
        forceDaily: false,
    }
    const {
        measures, // mesures récupérées par les API
        chartMinDate, // date min du graphique
        chartMaxDate, // date max du graphique
        isPiezo, // [opt] les données sont de la piézo (calcul des valeurs par NGF/Profondeur/Sol, ...)
        displayCote, // [à fournir si piézo] cote d'affichage voulue (Pronfondeur, NGF, Sol, ...)
        lastLandmark, // [à fournir si piézo] profondeur du repère, permet d'afficher en mode Profondeur
        groundRefAlti, // [à fournir si piézo] altitude du sysAlti de nature sol, permet d'afficher en mode Sol
        groupFunc, // [opt] fonction de calcul par jour. Valeurs: 'min', 'max' ou 'mean' (moyenne)
        forceBrute, // [opt] forcer l'affichage brut (pas de calcul journalier)
        forceDaily, // [opt] forcer le calcul journalier
    } = { ...defaultParams, ...params }
    const results = getGroupedMeasures(measures, chartMinDate, chartMaxDate, groupFunc, forceBrute, forceDaily, isPiezo).flatMap(obj => {
        const calculated = isPiezo ? getMeasureValue(obj, displayCote, lastLandmark, groundRefAlti) : obj.value
        if (obj.initialPoint === 1) {
            return [
                { value: [moment(obj.date).subtract(1, 'second').valueOf(), null, { ...obj, calculated }] },
                { value: [obj.date, calculated, { ...obj, calculated }], symbolSize: 5 },
            ]
        }
        return [{ value: [obj.date, calculated, { ...obj, calculated }], symbolSize: 0 }]
    })
    return orderBy(results, m => m.value[0])
}

const piezoMeasureIsValid = (measure) => measure.qualification === 1 && [2, 3].includes(measure.status)

const DEPTH = -1

export {
    getPiezometerMeasures, getPiezometerSamples, getPiezometerMinMax, getPiezometerMinMeasures, getPiezometerRawMeasures, getHardPiezoDataTypes, getMeasureStatusColor, getMeasureValueFromDepth,
    getPiezometerAdditionalMeasures, measureHasDate, getPiezometrySeriesParams, getValidPiezometrySeriesParams, getEventsBar, getEventGraph, getCorrectedMeasures, getStaticDepthEvents, getMeasureValue, getThresholdsMarkLine,
    getNGFValue, getDisplayCote, getMeasureModePeriodicityIcon, setMeasureLandmarkAndRefAlti, piezoMeasureIsValid, getControlAlert,
    getUpdatedLandmarkDate, getDryingTrendSpecificOptions, getSerieData, checkStatusQualificationConsistency, getMeasureStatusColorTable,
    DEPTH,
}
