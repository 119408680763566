import React, { useState } from 'react'
import { makeStyles } from '@mui/styles'
import { useDispatch } from 'react-redux'
import { DialogActionsMUI, DialogContentMUI, DialogMUI, DialogTitleMUI } from '../../../../components/styled/Dialog'
import i18n from 'simple-react-i18n'
import { StyledFieldSet } from '../../../../components/StyledElements'
import { Grid, Button, Stepper, Step, StepLabel, Table } from '@mui/material'
import InstallationAction from '../../../../installation/actions/InstallationAction'
import PropTypes from 'prop-types'
import Loading from '../../../../components/actions/Loading'
import { nbPerPageLabelMedium } from '../../../../referencial/constants/ReferencialConstants'
import Icon from '../../../../components/icon/Icon'

const STEP = {
    SETTINGS: 1,
    STATUS: 2,
    SUCCESS: 3,
}

const useStyles = makeStyles(() => ({
    label: {
        '&.MuiStepLabel-alternativeLabel': {
            marginTop: '3px !important',
        },
    },
}))

const PerimetersImportStepper = ({
    open = false,
    setOpen = () => { },
}) => {
    const dispatch = useDispatch()
    const classes = useStyles()

    const [step, setStep] = useState(STEP.SETTINGS)
    const [fileName, setFileName] = useState()
    const [fileContent, setFileContent] = useState()
    const [dataloaded, setDataloaded] = useState(false)
    const [errors, setErrors] = useState([])

    const onUploadFile = (e) => {
        const reader = new FileReader()
        const file = e.target.files[0]
        if (file) {
            setFileName(file.name)
            reader.onloadend = (event) => {
                const split = event.target.result.split(',')
                setFileContent(split[1])
            }
            reader.readAsDataURL(file)
        }
    }

    return (
        <DialogMUI open={open}>
            <DialogTitleMUI style={{ margin: '0', padding: '0' }}>
                <Grid container justifyContent='space-between' alignItems='center' style={{ padding: '10 20' }}>
                    <Grid item >
                        {i18n.importLabel}
                    </Grid>
                    <Grid item>
                        <Icon
                            style={{ color: 'white' }}
                            size='small'
                            icon={'close'}
                            onClick={() => setOpen(false)}
                        />
                    </Grid>
                </Grid>
                <Grid container justifyContent='center' style={{ backgroundColor: 'white', paddingTop: '5px' }}>
                    <Grid item xs={6}>
                        <Stepper
                            alternativeLabel
                            activeStep={step - 1}
                            style={{ zoom: '2' }}
                        >
                            <Step>
                                <StepLabel classes={classes}>{i18n.settings}</StepLabel>
                            </Step>
                            <Step>
                                <StepLabel classes={classes}>{i18n.status}</StepLabel>
                            </Step>
                            <Step>
                                <StepLabel classes={classes}>{i18n.success}</StepLabel>
                            </Step>
                        </Stepper>
                    </Grid>
                </Grid>
            </DialogTitleMUI>
            <DialogContentMUI>
                {
                    step === STEP.SETTINGS && (
                        <StyledFieldSet>
                            <Grid container style={{ padding: '10 10 0' }}>
                                <Grid item xs={7}>
                                    <div className='file-field input-field'>
                                        <div className='btn'>
                                            <span>{i18n.importLabel}</span>
                                            <input
                                                accept='.xls, .xlsx'
                                                type='file'
                                                onChange={onUploadFile}
                                            />
                                        </div>
                                        <div className='file-path-wrapper'>
                                            <input className='file-path validate' type='text' />
                                        </div>
                                    </div>
                                </Grid>
                            </Grid>
                        </StyledFieldSet>
                    ) || null
                }
                {
                    step === STEP.STATUS && (dataloaded ? (
                        <Grid container justifyContent='center' alignItems='center' spacing={3} className='padding-top-2'>
                            <Grid item xs={11}>
                                <Table
                                    title={i18n.errors}
                                    data={errors}
                                    type={{ headers: ['code', 'error'] }}
                                    condensed
                                    sortable
                                    paging
                                    nbPerPageLabel={nbPerPageLabelMedium}
                                />
                            </Grid>
                        </Grid>
                    ) : (
                        <StyledFieldSet style={{ marginTop: '20px', padding: '20 0' }}>
                            <Grid container justifyContent='center' alignItems='center' spacing={3}>
                                <h4>{i18n.jobProgressMessage}</h4>
                            </Grid>
                            <Grid container justifyContent='center' alignItems='center' spacing={3} className='padding-top-2'>
                                <h5>{i18n.jobProgressDescription}</h5>
                            </Grid>
                            <Grid container justifyContent='center' alignItems='center' spacing={3} className='padding-top-4'>
                                <img src={Loading} />
                            </Grid>
                        </StyledFieldSet>
                    )) || null
                }
                {
                    step === STEP.SUCCESS && (
                        <StyledFieldSet style={{ marginTop: '20px', padding: '20 0' }}>
                            <Grid container justifyContent='center' alignItems='center' spacing={3}>
                                <h4>{i18n.jobSuccessMessage}</h4>
                            </Grid>
                            <Grid container justifyContent='center' alignItems='center' spacing={3} className='padding-top-2'>
                                <h5>{i18n.jobSuccessDescription}</h5>
                            </Grid>
                            <Grid container justifyContent='center' alignItems='center' spacing={3} className='padding-top-4'>
                                <Icon icon='check_circle' style={{ color: 'green' }} size='large' />
                            </Grid>
                        </StyledFieldSet>
                    )
                }
            </DialogContentMUI>
            <DialogActionsMUI>
                <Grid container justifyContent='space-evenly'>
                    <Grid item>
                        <Button
                            variant='contained'
                            disabled={step === STEP.SETTINGS}
                            onClick={() => {
                                setStep(STEP.SETTINGS)
                                setDataloaded(false)
                                setFileContent()
                                setFileName()
                                setErrors()
                            }}
                        >
                            {i18n.previous}
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button
                            variant='contained'
                            disabled={!(step === STEP.SETTINGS && fileContent)}
                            onClick={() => {
                                setDataloaded(false)
                                setStep(STEP.STATUS)
                                dispatch(InstallationAction.importInstallationDescription({
                                    fileName,
                                    fileContent,
                                })).then(data => {
                                    if (data?.length) {
                                        setDataloaded(true)
                                        setErrors(data)
                                    } else if (!data) {
                                        setStep(STEP.SETTINGS)
                                    } else {
                                        setStep(STEP.SUCCESS)
                                    }
                                })
                            }}
                        >
                            {i18n.importLabel}
                        </Button>
                    </Grid>
                </Grid>
            </DialogActionsMUI>
        </DialogMUI>
    )
}

PerimetersImportStepper.propTypes = {
    open: PropTypes.bool,
    setOpen: PropTypes.func,
}

export default PerimetersImportStepper