import { INSTALLATION_TYPE } from 'installation/constants/InstallationConstants'
import React from 'react'

const colorObject = {
    gray: '#808080',
    darkblue: '#00008b',
    purple: '#800080',
    blue: '#0000ff',
    green: '#008000',
    yellow: '#ffff00',
    orange: '#ffa500',
    red: '#ff0000',
    black: '#000000',
    white: '#ffffff',
}

const excelIcon = '<svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17.69 23.65" width="382" height="512"><path d="m3.08 0h7.91l6.7 6.99v13.58c0 1.7-1.38 3.08-3.08 3.08h-11.53c-1.7 0-3.08-1.38-3.08-3.08v-17.49c0-1.7 1.38-3.08 3.08-3.08z" fill="%2300733B"/><polygon points="10.98 0 10.98 6.93 17.69 6.93" fill="%234D9D76"/><path d="m5.21 17.35h3.14v1.19h-3.14v-1.19zm4.12-6.08h3.15v1.18h-3.15v-1.18zm-4.12 0h3.14v1.18h-3.14v-1.18zm4.12 2h3.15v1.19h-3.15v-1.19zm-4.12 0h3.14v1.19h-3.14v-1.19zm4.12 2.08h3.15v1.19h-3.15v-1.19zm-4.12 0h3.14v1.19h-3.14v-1.19zm4.12 2h3.15v1.19h-3.15v-1.19z" fill="%23fff"/></svg>'
const xmlIcon = '<svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 56 56"><g><path fill="%23E9E9E0" d="M36.985 0H7.963C7.155 0 6.5 0.655 6.5 1.926V55c0 0.345 0.655 1 1.463 1h40.074 c0.808 0 1.463-0.655 1.463-1V12.978c0-0.696-0.093-0.92-0.257-1.085L37.607 0.257C37.442 0.093 37.218 0 36.985 0z"/><polygon fill="%23D9D7CA" points="37.5 0.151 37.5 12 49.349 12"/><path fill="%23F29C1F" d="M48.037 56H7.963C7.155 56 6.5 55.345 6.5 54.537V39h43v15.537C49.5 55.345 48.845 56 48.037 56z"/><g><path fill="%23fff" d="M19.379 48.105L21.936 53h-1.9l-1.6-3.801h-0.137L16.576 53h-1.9l2.557-4.895l-2.721-5.182h1.873 l1.777 4.102h0.137l1.928-4.102H22.1L19.379 48.105z"/><path fill="%23fff" d="M31.998 42.924h1.668V53h-1.668v-6.932l-2.256 5.605h-1.449l-2.27-5.605V53h-1.668V42.924h1.668 l2.994 6.891L31.998 42.924z"/><path fill="%23fff" d="M37.863 42.924v8.832h4.635V53h-6.303V42.924H37.863z"/></g><path fill="%23F29C1F" d="M15.5 24c-0.256 0-0.512-0.098-0.707-0.293c-0.391-0.391-0.391-1.023 0-1.414l6-6 c0.391-0.391 1.023-0.391 1.414 0s0.391 1.023 0 1.414l-6 6C16.012 23.902 15.756 24 15.5 24z"/><path fill="%23F29C1F" d="M21.5 30c-0.256 0-0.512-0.098-0.707-0.293l-6-6c-0.391-0.391-0.391-1.023 0-1.414 s1.023-0.391 1.414 0l6 6c0.391 0.391 0.391 1.023 0 1.414C22.012 29.902 21.756 30 21.5 30z"/><path fill="%23F29C1F" d="M33.5 30c-0.256 0-0.512-0.098-0.707-0.293c-0.391-0.391-0.391-1.023 0-1.414l6-6 c0.391-0.391 1.023-0.391 1.414 0s0.391 1.023 0 1.414l-6 6C34.012 29.902 33.756 30 33.5 30z"/><path fill="%23F29C1F" d="M39.5 24c-0.256 0-0.512-0.098-0.707-0.293l-6-6c-0.391-0.391-0.391-1.023 0-1.414 s1.023-0.391 1.414 0l6 6c0.391 0.391 0.391 1.023 0 1.414C40.012 23.902 39.756 24 39.5 24z"/><path fill="%23F29C1F" d="M24.5 32c-0.11 0-0.223-0.019-0.333-0.058c-0.521-0.184-0.794-0.755-0.61-1.276l6-17 c0.185-0.521 0.753-0.795 1.276-0.61c0.521 0.184 0.794 0.755 0.61 1.276l-6 17C25.298 31.744 24.912 32 24.5 32z"/></g></svg>'

const formatColor = (color = 'white') => {
    if (color.startsWith('#')) {
        return color.replace('#', '%23')
    }
    const rgbColor = colorObject[color] ?? color
    return rgbColor.replace('#', '%23')
}

const getPiezoMarker = (color, withStroke = false) => {
    const colorFormatted = formatColor(color)
    return `<svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 62 84" width="36" height="53"><title>map_piezo-svg</title><g id="layer1"><path id="path630" fill="white" d="m30.9 57.8c-12.9 0-23.4-10.8-23.4-24.1 0-13.4 10.5-24.2 23.4-24.2 12.9 0 23.4 10.8 23.4 24.2 0 13.3-10.5 24.1-23.4 24.1z"/><g id="g585"><path id="path587" fill-rule="evenodd" fill="${colorFormatted}" stroke-width="${withStroke ? 2 : 1}" ${withStroke ? 'stroke="white"' : ''} d="m29.2 77.3c-0.4-0.2-1.4-1.4-2.1-2.6-1.9-3.1-4-5.6-10.1-12.1-6.1-6.5-8.5-9.6-10.6-14.1-2.4-4.9-3-7.8-3-14.1 0-6 0.5-8.2 2.6-12.9 3.6-7.5 10-12.8 18-15 3.3-0.9 9.5-1 12.7-0.2 14.2 3.4 23.2 16.7 21.5 31.9-1 8.8-4.2 14.6-13.8 24.6-5.1 5.3-8.3 9.2-9.9 12-1.7 2.8-3.3 3.6-5.3 2.5zm6.9-24.1c7-1.8 12.7-7.5 14.6-14.7 0.8-2.7 0.8-7.7 0-10.4-1.9-7.2-7.7-12.9-15.1-14.8-2.8-0.7-8.6-0.4-11.3 0.5-6.2 2.1-11 6.9-13.2 13.2-1 2.7-1.2 8-0.5 11 2 8.1 8.8 14.4 17.1 15.7 2.1 0.4 5.9 0.1 8.4-0.5zm-14.2-26.1h-8.5v-2.5h11.1v5.4c0 2.9 0.1 5.4 0.3 5.4 0.1 0 0.4-0.3 0.6-0.5 0.4-0.4 0.5-0.4 0.9 0.1 0.6 0.9 1.8 0.9 2.4 0.1l0.4-0.7 0.6 0.7q1.1 1.2 2.2 0l0.6-0.7 0.5 0.7c0.5 0.8 1.8 0.8 2.4-0.1 0.4-0.5 0.5-0.5 0.8-0.1 0.3 0.2 0.6 0.5 0.7 0.5 0.2 0 0.3-2.5 0.3-5.4v-5.4h11.1v2.5h-8.6v22.8h-17.8z"/></g></g></svg>`
}
const getCardPiezoMarker = color => {
    const colorFormatted = formatColor(color)
    return `<svg viewBox="0 0 50 50" xmlns="http://www.w3.org/2000/svg" width="36" height="53"><g id="layer1" transform="matrix(1, 0, 0, 0.709537, -13.350261, -151.634186)"><g id="g27" transform="translate(-79.826874,104.30828)"><g transform="matrix(1.698573, 0, 0, 2.340009, -185.001083, -498.397736)" id="g585-4"><path id="path587-4" d="m 171.12872,276.2113 v -9.525 h -3.57187 -3.57188 v -1.05833 -1.05834 h 4.63021 4.63021 v 4.49792 c 0,2.47385 0.10448,4.49792 0.23219,4.49792 0.1277,0 0.38256,-0.1812 0.56635,-0.40265 0.29515,-0.35563 0.37793,-0.34019 0.70886,0.13229 0.47981,0.68502 1.53041,0.68797 2.00834,0.006 l 0.37074,-0.52931 0.47902,0.52931 c 0.609,0.67294 1.24333,0.67294 1.85234,0 l 0.47901,-0.52931 0.37075,0.52931 c 0.47792,0.68234 1.52852,0.67939 2.00833,-0.006 0.33094,-0.47248 0.41371,-0.48792 0.70886,-0.13229 0.18379,0.22145 0.43865,0.40265 0.56635,0.40265 0.12771,0 0.23219,-2.02407 0.23219,-4.49792 v -4.49792 h 4.63021 4.63021 v 1.05834 1.05833 h -3.57188 -3.57187 v 9.525 9.525 h -7.40833 -7.40834 z" fill="${colorFormatted}"></path></g></g></g></svg>`
}
const getHydroMarker = color => {
    const colorFormatted = formatColor(color)
    return `<svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 62 84" width="36" height="53"><title>map_hydro-svg</title><g id="g-1"><g id="g25"><path id="ellipse-1" fill="white" d="m30.9 57.8c-12.9 0-23.4-10.8-23.4-24.1 0-13.4 10.5-24.2 23.4-24.2 12.9 0 23.4 10.8 23.4 24.2 0 13.3-10.5 24.1-23.4 24.1z"/><g id="g458"><path id="path466" fill-rule="evenodd" fill="${colorFormatted}" d="m15.4 40.9c0-3.1 0-5.7 0.1-5.7 0 0 0.8 0.3 1.6 0.7 0.8 0.3 2.5 0.7 3.8 0.8l2.4 0.2v-18.2h13.3v15.7h3.3c1.8 0 3.7 0.1 4.1 0.3l0.9 0.2v11.7h-29.5zm14.6-5.1c1.8-0.3 3.6-0.8 4-0.9l0.7-0.3v-6.9l-0.1-6.9h-9.2v0.8c0 0.7 0.1 0.8 1.3 0.9 1.3 0.1 1.4 0.1 1.3 0.9-0.1 0.8-0.2 0.9-1.4 1-1.2 0.1-1.4 0.2-1.4 0.8 0 0.8 0.1 0.8 2.5 0.8h2.6v1.9l-2.5 0.1c-2.3 0.1-2.4 0.1-2.5 0.8-0.1 0.6 0 0.7 1.3 0.7 1.4 0 1.5 0 1.5 0.9 0 0.9-0.1 1-1.4 1.1-1.2 0.1-1.3 0.1-1.3 0.9 0 0.7 0.1 0.8 1.3 0.8 1.3 0.1 1.4 0.2 1.3 1-0.1 0.8-0.2 0.9-1.4 0.9-1.1 0.1-1.4 0.3-1.4 0.7 0 0.7 0.2 0.9 0.9 0.8 0.3-0.1 2-0.4 3.9-0.8z"/></g><g id="g-2"><path id="path-6" fill-rule="evenodd" fill="${colorFormatted}" d="m29.2 77.3c-0.4-0.2-1.4-1.4-2.1-2.6-1.9-3.1-4-5.6-10.1-12.1-6.1-6.5-8.5-9.6-10.6-14.1-2.4-4.9-3-7.8-3-14.1 0-6 0.5-8.2 2.6-12.9 3.6-7.5 10-12.8 18-15 3.3-0.9 9.5-1 12.7-0.2 14.2 3.4 23.2 16.7 21.5 31.9-1 8.8-4.2 14.6-13.8 24.6-5.1 5.3-8.3 9.2-9.9 12-1.7 2.8-3.3 3.6-5.3 2.5zm6.9-24.1c7-1.8 12.7-7.5 14.6-14.7 0.8-2.7 0.8-7.7 0-10.4-1.9-7.2-7.7-12.9-15.1-14.8-2.8-0.7-8.6-0.4-11.3 0.5-6.2 2.1-11 6.9-13.2 13.2-1 2.7-1.2 8-0.5 11 2 8.1 8.8 14.4 17.1 15.7 2.1 0.4 5.9 0.1 8.4-0.5z"/></g></g></g></svg>`
}
const getCardHydroMarker = color => {
    const colorFormatted = formatColor(color)
    return `<svg viewBox="0 0 50 50" xmlns="http://www.w3.org/2000/svg" width="36" height="53"><g id="g-1" transform="matrix(1.534056, 0, 0, 1.540835, -20.078098, -357.239624)"><g id="g25" transform="translate(-71.013724,106.07418)"><g transform="matrix(1.201118,0,0,1.1966959,36.283235,-60.077425)" id="g458"><path id="path466" d="m 40.964117,175.58447 c 0,-2.61937 0.0444,-4.7625 0.09866,-4.7625 0.05426,0 0.659419,0.24805 1.344793,0.55123 0.703622,0.31124 2.10052,0.61584 3.208633,0.69965 l 1.962499,0.14842 v -7.57882 -7.57881 h 5.55625 5.55625 v 6.54844 6.54844 h 2.711979 c 1.491589,0 3.039401,0.0916 3.439583,0.20347 l 0.727605,0.20348 v 4.88975 4.88975 H 53.267242 40.964117 Z m 12.170833,-4.23546 c 1.527969,-0.30573 3.048341,-0.66695 3.378608,-0.80272 l 0.600479,-0.24685 -0.07131,-5.758 -0.07132,-5.75801 H 53.13495 49.298492 v 0.66146 c 0,0.59055 0.122259,0.67026 1.14051,0.7436 1.065384,0.0767 1.135097,0.12902 1.058333,0.79375 -0.07304,0.6325 -0.20718,0.72065 -1.206656,0.79297 -0.99379,0.0719 -1.124479,0.15646 -1.124479,0.7276 0,0.63142 0.04856,0.64625 2.116667,0.64625 h 2.116666 v 0.78262 0.78262 l -2.05052,0.0773 c -1.897653,0.0715 -2.056824,0.12166 -2.135069,0.67259 -0.0771,0.54285 0.02201,0.59531 1.124479,0.59531 1.161725,0 1.209027,0.0305 1.209027,0.77854 0,0.72678 -0.07476,0.78395 -1.124479,0.8599 -1.00136,0.0725 -1.124479,0.15378 -1.124479,0.74281 0,0.59056 0.122259,0.67027 1.14051,0.7436 1.065384,0.0767 1.135097,0.12902 1.058333,0.79375 -0.07304,0.63251 -0.20718,0.72066 -1.206656,0.79297 -0.870232,0.063 -1.124479,0.1876 -1.124479,0.55122 0,0.61125 0.192642,0.78598 0.742925,0.67384 0.246235,-0.0502 1.697856,-0.34137 3.225825,-0.64709 z" fill="${colorFormatted}"/></g></g></g></svg>`
}
const getHydroTideMarker = (color, withStroke = false) => {
    const colorFormatted = formatColor(color)
    return `<svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 52" width="40" height="52"><title>map_hydro-svg</title><g id="g-1"><g id="g25"><path id="ellipse-1" fill="white" d="m20 37.9c-9.2 0-16.6-7.7-16.6-17.2 0-9.6 7.4-17.3 16.6-17.3 9.3 0 16.7 7.7 16.7 17.3 0 9.5-7.4 17.2-16.7 17.2z"/><g id="Layer"><path id="Layer" fill-rule="evenodd" fill="${colorFormatted}" d="m17.3 10.2h5.7v14h4.3c3.7-0.2 4.1 0 2.7 0.7-1.1 0.6-2.1 0.6-2.9 0.2-0.7-0.4-1.8-0.4-2.9 0-1 0.4-2 0.5-2.2 0.3-0.7-0.7-7.7-0.7-9-0.2-0.7 0.3-1.8 0.2-2.7-0.3-1.5-0.7-1.1-0.9 2.7-0.7h4.3zm2.8 0.8h-2.1v13.1h2.1c2.3 0 2.9-1.5 0.9-2-1.2-0.2-1.2-0.3 0-0.4 0.7 0 1.3-0.3 1.3-0.7 0-0.4-0.6-0.9-1.3-1-1.2-0.3-1.2-0.4 0-0.4 0.7 0 1.3-0.4 1.3-0.7 0-0.4-0.6-0.9-1.3-1-1.2-0.3-1.2-0.4 0-0.5 0.7 0 1.3-0.4 1.3-0.7 0-0.5-0.6-0.8-1.3-0.8-1.2 0-1.2-0.1 0-0.9 1-0.7 1-0.8 0-1.7-1.2-0.8-1.2-0.9 0-0.9 0.7 0 1.3-0.4 1.3-0.7 0-0.5-1-0.7-2.2-0.7z"/><path id="Layer" fill-rule="evenodd" fill="${colorFormatted}" d="m13 13.1c1 0 2.8 3.4 2.1 3.9-0.4 0.2-0.7 1.7-0.7 3.2 0 2.3-0.2 2.8-1.4 2.8-1.2 0-1.5-0.5-1.5-2.8 0-1.5-0.2-3-0.7-3.2-0.4-0.3-0.3-1.2 0.5-2.2 0.6-1 1.4-1.7 1.7-1.7zm0 1.4c-0.4 0-0.7 1.8-0.7 4 0 2.4 0.2 3.7 0.7 3.5 0.4-0.3 0.7-2 0.7-4 0-2-0.3-3.5-0.7-3.5z"/>
    <path id="Layer" fill-rule="evenodd" fill="${colorFormatted}" d="m27.3 13.1c1.2 0 1.4 0.5 1.4 2.8 0 1.6 0.3 2.9 0.8 2.9 0.3 0 0.1 0.9-0.6 2.1-0.6 1.2-1.4 2.1-1.6 2.1-0.3 0-1-1-1.7-2.1-0.7-1.2-0.9-2.1-0.6-2.1 0.5 0 0.8-1.3 0.8-2.9 0-2.3 0.2-2.8 1.5-2.8zm0.7 4.6c0-2.2-0.4-3.9-0.7-3.9-0.4 0-0.8 1.7-0.8 3.9 0 2.2 0.4 3.9 0.8 3.9 0.3 0 0.7-1.7 0.7-3.9z"/><path id="Layer" fill="${colorFormatted}" d="m9.9 27.2c0.7 0.5 1.9 0.7 2.7 0.5 0.8-0.3 1.9-0.5 2.4-0.4 0.4 0 1.5 0 2.3 0 0.7 0 2 0 2.8 0 0.8 0 2.1 0 2.9 0 0.8 0 1.8 0 2.3 0 0.5-0.1 1.5 0.1 2.3 0.4 0.8 0.2 2 0 2.7-0.5 1-0.8-0.6-1-10.2-1-9.6 0-11.2 0.2-10.2 1z"/><path id="Layer" fill="${colorFormatted}" d="m9.2 28.7c1.3 1.5 19.9 1.5 21.6 0.2 1-0.9 1-1-0.3-0.5-0.8 0.3-1.9 0.5-2.3 0.4-1.2-0.1-14.9-0.1-16.1 0-0.5 0.1-1.6-0.1-2.3-0.4-1.2-0.5-1.3-0.5-0.6 0.3z"/></g><g id="g-2"><path id="path-6" fill-rule="evenodd" fill="${colorFormatted}" stroke-width="${withStroke ? 2 : 1}" ${withStroke ? 'stroke="white"' : ''} d="m18.8 51.8c-0.3-0.2-1-1-1.5-1.9-1.3-2.2-2.8-4-7.2-8.6-4.4-4.7-6.1-6.9-7.6-10.1-1.7-3.5-2.1-5.5-2.1-10 0-4.3 0.3-5.9 1.9-9.2 2.5-5.4 7.1-9.2 12.8-10.7 2.4-0.6 6.8-0.7 9.1-0.1 10.1 2.4 16.5 11.8 15.3 22.7-0.7 6.3-3 10.4-9.8 17.5-3.6 3.8-5.9 6.6-7.1 8.6-1.2 2-2.3 2.5-3.8 1.8zm4.9-17.2c5.1-1.3 9.1-5.3 10.5-10.5 0.5-1.9 0.5-5.5 0-7.4-1.4-5.2-5.5-9.2-10.8-10.5-2-0.5-6.1-0.4-8.1 0.3-4.4 1.5-7.8 4.9-9.4 9.4-0.7 2-0.8 5.7-0.3 7.8 1.4 5.8 6.2 10.3 12.1 11.2 1.6 0.3 4.3 0.1 6-0.3z"/></g></g></g></svg>`
}
const getPluvioMarker = color => {
    const colorFormatted = formatColor(color)
    return `<svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 62 84" width="36" height="53"><title>map_pluvio-svg</title><g id="g-1"><path id="ellipse-1" fill="white" opacity="{6565651}" d="m30.9 57.2c-12.9 0-23.4-10.8-23.4-24.1 0-13.4 10.5-24.2 23.4-24.2 12.9 0 23.4 10.8 23.4 24.2 0 13.3-10.5 24.1-23.4 24.1z"/><g id="g428"><path id="path430" fill="${colorFormatted}" d="m35.2 48.2c-0.8-0.8-0.7-2.3 0.2-3.5 0.8-1.3 1.3-1.4 2.1-0.3 1 1.2 1.2 3 0.5 3.8-0.8 0.8-2.2 0.8-2.8 0zm-11.8-5c-1-1.2-0.5-3.9 1-5.3 0.6-0.5 0.7-0.5 1.5 0.4 1.4 1.5 1.7 3.8 0.6 5-0.7 0.6-2.5 0.6-3.1-0.1zm5.2-7.3c-8.5-0.2-8.8-0.3-9.8-1q-2-1.4-2-3.8c0-2.3 1.8-4.5 4-5 0.9-0.1 1.1-0.3 1.3-1.2 0.7-3.1 4.1-5.1 7-4 0.4 0.2 1 0 1.9-0.7 1.1-0.7 1.8-1 3.3-1.1 4.5-0.3 7.6 2.6 7.7 7.1 0 1.1 0.1 1.3 1.3 1.9 3.1 1.5 3.2 5.7 0.2 7.3-1.2 0.6-4 0.7-14.9 0.5z"/></g><g id="g-2"><path id="path-6" fill-rule="evenodd" fill="${colorFormatted}" d="m29.2 76.7c-0.4-0.2-1.4-1.4-2.1-2.6-1.9-3.1-4-5.6-10.1-12-6.1-6.6-8.5-9.7-10.6-14.2-2.4-4.9-3-7.8-3-14.1 0-6 0.5-8.2 2.6-12.9 3.6-7.5 10-12.8 18-15 3.3-0.9 9.5-1 12.7-0.2 14.2 3.4 23.2 16.7 21.5 31.9-1 8.8-4.2 14.6-13.8 24.7-5.1 5.2-8.3 9.1-9.9 11.9-1.7 2.8-3.3 3.6-5.3 2.5zm6.9-24.1c7-1.8 12.7-7.5 14.6-14.7 0.8-2.7 0.8-7.7 0-10.4-1.9-7.2-7.7-12.9-15.1-14.7-2.8-0.8-8.6-0.5-11.3 0.4-6.2 2.1-11 6.9-13.2 13.3-1 2.7-1.2 7.9-0.5 10.9 2 8.1 8.8 14.4 17.1 15.7 2.1 0.4 5.9 0.2 8.4-0.5z"/></g></g></svg>`
}
const getCardPluvioMarker = color => {
    const colorFormatted = formatColor(color)
    return `<svg viewBox="0 0 50 50" xmlns="http://www.w3.org/2000/svg" width="36" height="53"><g id="layer1" transform="matrix(1, 0, 0, 1, -2.050543, -231.39856)"><g id="g35" transform="translate(-73.779254,99.772558)"><g transform="matrix(1.622076, 0, 0, 1.600513, 31.563339, -96.083557)" id="g428"><path id="path430" d="M 46.74 172.491 C 45.975 171.662 46.063 170.139 46.95 168.847 C 47.847 167.537 48.38 167.47 49.212 168.564 C 50.155 169.805 50.362 171.664 49.643 172.444 C 48.906 173.246 47.456 173.269 46.74 172.491 Z M 34.564 167.319 C 33.497 166.161 34.038 163.377 35.599 161.989 C 36.239 161.419 36.282 161.429 37.12 162.313 C 38.649 163.925 38.941 166.262 37.759 167.424 C 37.07 168.1 35.229 168.041 34.564 167.319 Z M 39.944 159.861 C 31.152 159.672 30.863 159.645 29.82 158.914 C 28.445 157.952 27.767 156.652 27.767 154.979 C 27.767 152.618 29.605 150.366 31.872 149.949 C 32.799 149.777 33.033 149.564 33.214 148.723 C 33.911 145.484 37.448 143.461 40.422 144.604 C 40.883 144.781 41.465 144.573 42.475 143.875 C 43.564 143.12 44.339 142.865 45.874 142.756 C 50.468 142.431 53.717 145.394 53.777 149.967 C 53.793 151.154 53.906 151.316 55.141 151.911 C 58.339 153.454 58.483 157.781 55.389 159.354 C 54.098 160.01 51.218 160.105 39.944 159.861 Z" fill="${colorFormatted}"/></g></g></g></svg>`
}
const getInstMarker = (color, withStroke = false) => {
    const colorFormatted = formatColor(color)
    return `<svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 62 84" width="36" height="53"><title>map_installation-svg</title><g id="g-1"><path id="ellipse-1" fill="white" opacity="{6565651}" d="m30.9 57.2c-12.9 0-23.4-10.8-23.4-24.1 0-13.4 10.5-24.2 23.4-24.2 12.9 0 23.4 10.8 23.4 24.2 0 13.3-10.5 24.1-23.4 24.1z"/><g id="g428"><path id="Layer" fill="${colorFormatted}" d="m19 22.2c0 0.8 2 1.5 4.4 1.8l4.3 0.4 0.4 9 0.4 9.1h-4.8c-2.5 0-4.7 0.5-4.7 1.3 0 0.9 5 1.4 12.1 1.4 7.1 0 12.1-0.5 12.1-1.4 0-0.8-1.8-1.3-4-1.3h-4.1v-9.4-9.4h4.1c2.2 0 4-0.7 4-1.4 0-0.8-5-1.3-12.1-1.3-6.7 0-12.1 0.5-12.1 1.2z"/></g><g id="g-2"><path id="path-6" fillRule="evenodd" fill="${colorFormatted}" stroke-width="${withStroke ? 2 : 1}" ${withStroke ? 'stroke="white"' : ''} d="m29.2 76.7c-0.4-0.2-1.4-1.4-2.1-2.6-1.9-3.1-4-5.6-10.1-12-6.1-6.6-8.5-9.7-10.6-14.2-2.4-4.9-3-7.8-3-14.1 0-6 0.5-8.2 2.6-12.9 3.6-7.5 10-12.8 18-15 3.3-0.9 9.5-1 12.7-0.2 14.2 3.4 23.2 16.7 21.5 31.9-1 8.8-4.2 14.6-13.8 24.7-5.1 5.2-8.3 9.1-9.9 11.9-1.7 2.8-3.3 3.6-5.3 2.5zm6.9-24.1c7-1.8 12.7-7.5 14.6-14.7 0.8-2.7 0.8-7.7 0-10.4-1.9-7.2-7.7-12.9-15.1-14.7-2.8-0.8-8.6-0.5-11.3 0.4-6.2 2.1-11 6.9-13.2 13.3-1 2.7-1.2 7.9-0.5 10.9 2 8.1 8.8 14.4 17.1 15.7 2.1 0.4 5.9 0.2 8.4-0.5z"/></g></g></svg>`
}

const getInstBoreholeMarker = color => {
    const colorFormatted = formatColor(color)
    return `<svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 63 81" width="36" height="53"><title>map_installation_forage-svg</title><g id="Layer"><path id="Layer" fill="${colorFormatted}" d="m26.5 2.1q39.8 2.4 32.2 41.2-11.2 19.3-27.9 34.1-12.8-13-25.1-27-15.9-35.9 20.8-48.3z"/></g><g id="Layer"><path id="Layer" fill="white" d="m49.4 22q-6.4 0-12.9 0 0.5 5.9-1.4 11.4-5.7 0-11.5 0 0-5.7 0-11.4-5.7 0-11.4 0 9.8-16.9 28.6-9.2 6.2 2.9 8.6 9.2z"/></g><g id="Layer"><path id="Layer" fill="${colorFormatted}" d="m12.2 22q5.7 0 11.4 0 0 5.7 0 11.4 5.8 0 11.5 0 3.9-3.8 2.8-10 5.8 0 11.5 0-4.5 2.1-10 1.5 0 11.3 0 22.7-9.3 0-18.6 0 0-11.4 0-22.7-5.6 1-8.6-2.9z"/></g><g id="Layer"><path id="Layer" fill="white" d="m49.4 22q1.4 0 1.4 1.4-0.7 0-1.4 0-5.7 0-11.5 0 1.1 6.2-2.8 10 1.9-5.5 1.4-11.4 6.5 0 12.9 0z"/></g><g id="Layer"><path id="Layer" fill="white" d="m12.2 22q3 3.9 8.6 2.9 0 11.3 0 22.7 9.3 0 18.6 0 0-11.4 0-22.7 5.5 0.6 10-1.5 0.7 0 1.4 0 4.4 30.9-27.2 27.8-19.5-9.7-11.4-29.2z"/></g></svg>`
}

const getInstIndustrialSiteMarker = color => {
    const colorFormatted = formatColor(color)
    return `<svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 63 81" width="36" height="53"><title>map_installation_site_industriel-svg</title><g id="Layer"><path id="Layer" fill="${colorFormatted}" d="m26.5 2.1q39.8 2.4 32.2 41.2-11.2 19.3-27.9 34.1-12.8-13-25.1-27-15.9-35.9 20.8-48.3z"/></g><g id="Layer"><path id="Layer" fill="white" d="m49.4 41.9q-0.7 0-1.4 0 0.1-12.5-2.9-24.1-3.6 0-7.2 0-0.7 19.9-1.4 0-3.6 0-7.1 0 0.3 4.3-0.8 8.5-3.2-0.8-7.1 0-1.1-2-0.7-4.3-2.2 0-4.3 0-0.8 10.2-2.9 19.9-0.7 0-1.4 0-9.5-26 17.9-31.2 19.6 0.6 22.2 19.9-0.2 6-2.9 11.3z"/></g><g id="Layer"><path id="Layer" fill="${colorFormatted}" d="m48 41.9q-17.2 0-34.4 0 2.1-9.7 2.9-19.9 2.1 0 4.3 0-0.4 2.3 0.7 4.3 3.9-0.8 7.1 0 1.1-4.2 0.8-8.5 3.5 0 7.1 0 0.7 19.9 1.4 0 3.6 0 7.2 0 3 11.6 2.9 24.1z"/></g><g id="Layer"><path id="Layer" fill="white" d="m20.8 32q2.3 0.2 2.8 2.8-0.7 1.4-1.4 2.9-2-2.6-1.4-5.7z"/></g><g id="Layer"><path id="Layer" fill="white" d="m27.9 32q2.4 0.2 2.9 2.8-0.7 1.4-1.4 2.9-2-2.6-1.5-5.7z"/></g><g id="Layer"><path id="Layer" fill="white" d="m12.2 41.9q0.7 0 1.4 0 17.2 0 34.4 0 0.7 0 1.4 0-0.7 2.2-2.9 2.9-16-2.2-32.9-1.5-1.4 0-1.4-1.4z"/></g><g id="Layer"><path id="Layer" fill="white" d="m13.6 43.3q16.9-0.7 32.9 1.5-6.3 7.4-16.4 7.1-10.6-0.1-16.5-8.6z"/></g></svg>`
}

const getInstStepMarker = color => {
    const colorFormatted = formatColor(color)
    return `<svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 63 81" width="36" height="53"><title>map_installation_assainissement-svg</title><g id="Layer"><path id="Layer" fill="${colorFormatted}" d="m26.5 2.1q39.8 2.4 32.2 41.2-11.2 19.3-27.9 34.1-12.8-13-25.1-27-15.9-35.9 20.8-48.3z"/></g><g id="Layer"><path id="Layer" fill="white" d="m23.6 10.7q33-1.8 26.5 29.8-12.5 17.6-32.2 7.8-13.9-12.6-5-29.1 4.5-5.6 10.7-8.5z"/></g><g id="Layer"><path id="Layer" fill="${colorFormatted}" d="m42.2 24.9q-1.7 8.2 4.3 14.9-0.2 2.7-2.8 3.5-7.8-4-16.5-1.4-15.7 2.2-11.5-12.8 10.7-4.1 20.8-9.2 3.5-1.1 7.2-0.7 0.5 3.4-1.5 5.7z"/></g><g id="Layer"><path id="Layer" fill="white" d="m20.8 27.7q4.3-0.3 8.6 0.7-5 2.9 0 3.6 4-1.1 7.8-2.9 2.8 0.3 3.6 2.9-9.1 6.1-20 3.5-8.3-3.8 0-7.8z"/></g><g id="Layer"><path id="Layer" fill="white" d="m42.2 24.9q-3.8 2.6-8.6 2.8 0.7-7.5 8.6-7.1 0 2.1 0 4.3z"/></g><g id="Layer"><path id="Layer" fill="white" d="m37.9 36.2q5.6 0.1 4.3 4.3-3.6-0.8-4.3-4.3z"/></g></svg>`
}

const getInstFlowObstructionMarker = (color, withStroke = false) => {
    const colorFormatted = formatColor(color)
    return `<svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 63 81" width="36" height="53"><title>map_installation_obstacle-svg</title><g id="Layer"><path id="Layer" fill="white" d="m22.2 7.8q36.7-2.3 29.3 32.7-12.6 19.3-33.6 9.2-16.9-15.3-5-34.8 5-3.3 9.3-7.1z"/></g><g id="Layer"><path id="Layer" fill="${colorFormatted}" stroke-width="${withStroke ? 2 : 1}" ${withStroke ? 'stroke="white"' : ''} d="m33 0.4c17.8 0.9 28.5 12 29.5 30 0.9 13.5-1.9 20-14.3 33.8-5.5 6.1-10.8 12.6-11.6 14.3-2.2 4.9-7.3 4.2-11.3-1.4-1.7-2.4-6.6-8.3-10.8-12.8-11.4-12.5-14-18.4-14-32.1 0-9.5 0.6-12.5 3.4-17.5 3.6-6.4 9.6-11.5 15.8-13.5 2-0.8 8-1.1 13.3-0.8zm-23.3 36.6c4 14.8 21.9 20.7 35 11.6l5.2-3.4-35.8-0.9v-4.2c0-4.3 0-4.3 8.2-4.3 4.4 0 7.6-0.4 7.1-0.8-0.4-0.6-4.1-1-8-1-6.4 0-7-0.2-5.4-2.6 1.3-2.1 2-2.2 3.5-1 1.5 1.3 2.3 1.1 3.5-0.5 1-1.6 1.6-1.6 2.1-0.3 1 2.6 4.8 2.3 4.8-0.3 0-2.3-1.6-2.5-10.4-2.1-5.8 0.3-6.5-0.6-3.4-3.6 1.4-1.4 2.3-1.6 3.4-0.4 1.2 1.1 2.2 1.1 3.9-0.3 1.4-1.2 2.2-1.3 2.2-0.2 0 0.9 1 1.6 2.1 1.6 1.2 0 2.2-0.6 2.2-1.3 0-1.7-4.5-2.7-10.6-2.3-5.8 0.6-6.2 0.2-2.9-3.1 1.9-2.1 2.4-2.1 3.6-0.3 1.1 1.7 1.5 1.7 2.8-0.2 1.2-1.6 1.8-1.7 2.3-0.4 0.9 2.1 4.8 2.4 4.8 0.4 0-0.8 2.5-1.4 5.7-1.4h5.6l0.4 11.1c0.3 6.7 1 11.2 2 11.2 0.9 0 2.7 1.1 4.1 2.7 2.6 2.6 2.8 2.6 4.1-0.7 1.8-5.2 0.2-16.8-2.9-21.2-1.4-2-4.3-4.8-6.3-6.1-15.3-11.3-37.9 5.4-32.9 24.3z"/></g></svg>`
}

const getInstPollutedSoilMarker = color => {
    const colorFormatted = formatColor(color)
    return `<svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 63 81" width="36" height="53"><title>map_installation_sols-svg</title><g id="Layer"><path id="Layer" fill="${colorFormatted}" d="m22.2-0.7q7.9 0 15.7 0 17.7 5.5 24.4 22.7 0 10 0 19.9-3.9 9.1-10 17.1-1.5 0-1.5 1.4-9.9 8.4-15.7 19.9-3.6 0-7.2 0-7.6-12.9-19.3-22.7-5.9-8.9-9.3-18.5 0-7.8 0-15.7 2.7-8.1 7.9-15.6 6.6-6.3 15-8.5z"/></g><g id="Layer"><path id="Layer" fill="white" d="m23.6 7.8q19.7-2.8 29.4 14.2 4.3 33.4-29.4 30.6-22.7-11.7-12.1-34.8 5.2-6.3 12.1-10z"/></g><g id="Layer"><path id="Layer" fill="${colorFormatted}" d="m46.5 26.3q-4.3 0.3-8.6-0.7 2.2-2.3 0.8-5-4.2 2.8-7.9 0 0.2-1.3 1.4-2.1 7.2-1.4 14.3 0 5.7 4.2 0 7.8z"/></g><g id="Layer"><path id="Layer" fill="${colorFormatted}" d="m30.8 20.6q3.7 2.8 7.9 0 1.4 2.7-0.8 5 4.3 1 8.6 0.7-5 2.8-10 0-2.4-5-7.1-1.4-0.6-2.8 1.4-4.3z"/></g><g id="Layer"><path id="Layer" fill="${colorFormatted}" d="m36.5 26.3q-1.9-0.3-2.9 1.4 5.8 3.6 11.5 7.1 2.7 7.8-5.7 10.7-14.4 5.4-26.5-3.6-1.4-3.5 0-7.1 8.2-5.2 16.5-9.9 4.7-3.6 7.1 1.4z"/></g><g id="Layer"><path id="Layer" fill="white" d="m26.5 34.8q5.3-0.9 8.6 2.9-6.1 5.2-12.9 0 2.4-1.5 4.3-2.9z"/></g><g id="Layer"><path id="Layer" fill="${colorFormatted}" d="m52.3 59q2.8 1.4 0 0z"/></g><g id="Layer"><path id="Layer" fill="${colorFormatted}" d="m50.8 60.4q0.7 0 1.5 0-0.4 1.4-1.5 0z"/></g></svg>`
}

const getInstFishingSpotMarker = color => {
    const colorFormatted = formatColor(color)
    return `<svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 63 81" width="36" height="53"><title>map_installation_fishing_spot-svg</title><g id="Layer"><path id="Layer" fill="${colorFormatted}" d="m19.3-0.7q11.5 0 22.9 0 14.5 6.6 20.1 21.3 0 12.1 0 24.2-8.9 14.9-22.2 27-3.1 4-5 8.5-4.3 0-8.6 0-13.7-17.1-27.2-34.1 0-13.5 0-27 6.5-13.6 20-19.9z"/></g><g id="Layer"><path id="Layer" fill="white" d="m22.2 7.8q36.7-2.3 29.3 32.7-12.6 19.3-33.6 9.2-16.9-15.3-5-34.8 5-3.3 9.3-7.1z"/></g><g id="Layer"><path id="Layer" fill="${colorFormatted}" d="m25.1 19.2q0 1.4 0 2.8-3.3-1.2-6.5-2.8-1.5 12.1-3.6 24.1-1.1-13.4 1.5-27 5.4-0.9 8.6 2.9z"/></g><g id="Layer"><path id="Layer" fill="${colorFormatted}" d="m25.1 19.2q9 2.6 12.8 11.4 15.5-6.7 5.8 7.1 1.7 3.3 1.4 7.1-9-2.3-2.9-9.3-7.1-0.9-9.3-7.8-3-4.1-7.8-5.7 0-1.4 0-2.8z"/></g><g id="Layer"><path id="Layer" fill="white" d="m42.2 29.1q4 1.4 1.5 4.3-1.9-1.8-1.5-4.3z"/></g></svg>`
}

const getInstCavityMarker = color => {
    const colorFormatted = formatColor(color)
    return `<svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 63 81" width="36" height="53"><title>map_installation_cavity-svg</title><g id="Layer"><path id="Layer" fill="${colorFormatted}" d="m19.3-0.7q11.5 0 22.9 0 14.5 6.6 20.1 21.3 0 12.1 0 24.2-8.9 14.9-22.2 27-3.1 4-5 8.5-4.3 0-8.6 0-13.7-17.1-27.2-34.1 0-13.5 0-27 6.5-13.6 20-19.9z"/></g><g id="Layer"><path id="Layer" fill="white" d="m22.2 7.8q36.7-2.3 29.3 32.7-12.6 19.3-33.6 9.2-16.9-15.3-5-34.8 5-3.3 9.3-7.1z"/></g><g id="Layer"><path id="Layer" fill="${colorFormatted}" d="m29.4 14.9q3.6 1.5 4.2 5.7 3.2 1.6 6.5 2.8 4.2 10.3 5 21.4-4.5 0.6-8.6-1.5-5.7-11.3-11.4 0-4.1 2.1-8.6 1.5 0.3-10.3 5.7-18.5 1.4 2.1 2.9 4.3 2.4-7.8 4.3-15.7z"/></g></svg>`
}

const getInstSampleWorkMarker = color => {
    const colorFormatted = formatColor(color)
    return `<svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 63 81" width="36" height="53"><title>map_installation_prelevements-svg</title><g id="Layer"><path id="Layer" fill="${colorFormatted}" d="m19.3-0.7q10.8 0 21.5 0 15.7 5.6 21.5 21.3 0 12.1 0 24.2-12.7 18.3-27.2 35.5-4.3 0-8.6 0-2-4.5-5-8.5-14.1-11.5-22.2-27 0-12.1 0-24.2 5.6-14.8 20-21.3z"/></g><g id="Layer"><path id="Layer" fill="white" d="m22.2 7.8q39.9-1.7 27.9 35.5-20.8 20.6-40.1-1.4-8.9-22.7 12.2-34.1z"/></g><g id="Layer"><path id="Layer" fill="${colorFormatted}" d="m35.1 20.6q5.7 0 11.4 0 0 2.1 0 4.3-5.7 0-11.4 0 4.6-1.8 10-2.2-5.4-0.4-10-2.1z"/></g><g id="Layer"><path id="Layer" fill="${colorFormatted}" d="m15 23.4q5.8 0 11.5 0 0 12.1 0 24.2-1.4-6.8-8.6-8.5-2.2 1.4-4.3 2.8-0.7-0.7-1.4-1.4 2.5-2.9 6.4-2.8 3.3 0.9 6.5 0.7-4.3-3.8-10.1-4.3 4.5-1.5 8.6 0.7 2.1-4.8 1.5-9.9-5.5 0.6-10.1-1.5z"/></g><g id="Layer"><path id="Layer" fill="${colorFormatted}" d="m35.1 20.6q4.6 1.7 10 2.1-5.4 0.4-10 2.2 0 4.9 0 9.9 5-1.4 10-0.7-19.5 5.8 1.4 4.3 2.1 1.5 0.7 3.5-5.2-5.4-10.7 0-2 2.6-1.4 5.7-4.3 0-8.6 0 0-12.1 0-24.2-5.7 0-11.5 0 3.9-3.9 10.1-2.8 0-2.8 0-5.7 5 0 10 0 0 2.9 0 5.7z"/></g><g id="Layer"><path id="Layer" fill="white" d="m29.4 39.1q2.3 0.2 2.8 2.8-0.7 1.4-1.4 2.9-2-2.6-1.4-5.7z"/></g><g id="Layer"><path id="Layer" fill="white" d="m29.4 17.8q2.7 2.9 2.8 7.8-0.7 3.9-1.4 7.8-2.1-7.7-1.4-15.6z"/></g></svg>`
}

const getInstCaptureMarker = color => {
    const colorFormatted = formatColor(color)
    return `<svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 63 81" width="36" height="53"><title>map_installation_captages-svg</title><g id="Layer"><path id="Layer" fill="${colorFormatted}" d="m19.3-0.7q10.8 0 21.5 0 15.7 5.6 21.5 21.3 0 12.1 0 24.2-12.7 18.3-27.2 35.5-4.3 0-8.6 0-2-4.5-5-8.5-14.1-11.5-22.2-27 0-12.1 0-24.2 5.6-14.8 20-21.3z"/></g><g id="Layer"><path id="Layer" fill="white" d="m22.2 7.8q14.4-2.2 25.8 6.4 4.6 4.6 5 10.7-6.6-2.1-13.6-1.5 0 2.9 0 5.7 7.1 0 14.3 0-4.9 32.3-35.8 20.6-16-14-6.4-33.4 5.3-4.5 10.7-8.5z"/></g><g id="Layer"><path id="Layer" fill="${colorFormatted}" d="m29.4 13.5q8.5 0.9 2.8 6.4 8.2 2.3 5 10.7-1.5 1.1-2.1 2.8 4.6 0.4 10 0.7-19.5 5.8 1.4 4.3 2.1 1.5 0.8 3.5-5.3-5.4-10.8 0-2 2.6-1.4 5.7-5 0-10 0 0.3-7.3-7.2-8.5-2.1 1.4-4.3 2.8-0.7-0.7-1.4-1.4 2.5-2.9 6.4-2.8 3.3 0.9 6.5 0.7-4.3-3.8-10.1-4.3 4.5-1.5 8.6 0.7 0.5-6.1 0.7-12.8 5.1-1.7 2.2-5.7 1.6-1.4 2.9-2.8z"/></g><g id="Layer"><path id="Layer" fill="white" d="m29.4 39.1q2.3 0.2 2.8 2.8-0.7 1.4-1.4 2.9-2-2.6-1.4-5.7z"/></g></svg>`
}

const getInstLiftingStationMarker = color => {
    const colorFormatted = formatColor(color)
    return `<svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 63 81" width="36" height="53"><title>map_installation_poste_relevage-svg</title><g id="Layer"><path id="Layer" fill="${colorFormatted}" d="m19.3-0.7q10.8 0 21.5 0 15.7 5.6 21.5 21.3 0 12.1 0 24.2-12.7 18.3-27.2 35.5-4.3 0-8.6 0-2-4.5-5-8.5-14.1-11.5-22.2-27 0-12.1 0-24.2 5.6-14.8 20-21.3z"/></g><g id="Layer"><path id="Layer" fill="white" d="m22.2 7.8q40.6-2 27.9 35.5-13.6 15.6-32.2 6.4-16.9-15.3-5-34.8 5-3.3 9.3-7.1z"/></g><g id="Layer"><path id="Layer" fill="${colorFormatted}" d="m13.6 17.8q9.3-1.4 16.5 4.2 0.9 12.6-12.2 10 0 3.5 0 7.1-2.1 0-4.3 0 0-10.7 0-21.3z"/></g><g id="Layer"><path id="Layer" fill="${colorFormatted}" d="m33.6 17.8q9.4-1.4 16.5 4.2 1.9 7.3-5 10.7 1.6 3.2 4.3 5.7-7.8 1.7-7.2-6.4-2.1 0-4.3 0 0 3.5 0 7.1-2.1 0-4.3 0 0-10.7 0-21.3z"/></g><g id="Layer"><path id="Layer" fill="white" d="m19.3 20.6q8 0.1 5.8 7.1-3.4 2-7.2 1.4-0.4-4.5 1.4-8.5z"/></g><g id="Layer"><path id="Layer" fill="white" d="m39.4 20.6q8 0.1 5.7 7.1-3.3 2-7.2 1.4-0.3-4.5 1.5-8.5z"/></g></svg>`
}

const getInstPumpingMarker = color => {
    const colorFormatted = formatColor(color)
    return `<svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 63 81" width="36" height="53"><title>map_installation_prelevements-svg</title><g id="Layer"><path id="Layer" fill="${colorFormatted}" d="m19.3-0.7q10.8 0 21.5 0 15.7 5.6 21.5 21.3 0 12.1 0 24.2-12.7 18.3-27.2 35.5-4.3 0-8.6 0-2-4.5-5-8.5-14.1-11.5-22.2-27 0-12.1 0-24.2 5.6-14.8 20-21.3z"/></g><g id="Layer"><path id="Layer" fill="white" d="m22.2 7.8q39.9-1.7 27.9 35.5-20.8 20.6-40.1-1.4-8.9-22.7 12.2-34.1z"/></g><g id="Layer"><path id="Layer" fill="${colorFormatted}" d="m35.1 20.6q5.7 0 11.4 0 0 2.1 0 4.3-5.7 0-11.4 0 4.6-1.8 10-2.2-5.4-0.4-10-2.1z"/></g><g id="Layer"><path id="Layer" fill="${colorFormatted}" d="m15 23.4q5.8 0 11.5 0 0 12.1 0 24.2-1.4-6.8-8.6-8.5-2.2 1.4-4.3 2.8-0.7-0.7-1.4-1.4 2.5-2.9 6.4-2.8 3.3 0.9 6.5 0.7-4.3-3.8-10.1-4.3 4.5-1.5 8.6 0.7 2.1-4.8 1.5-9.9-5.5 0.6-10.1-1.5z"/></g><g id="Layer"><path id="Layer" fill="${colorFormatted}" d="m35.1 20.6q4.6 1.7 10 2.1-5.4 0.4-10 2.2 0 4.9 0 9.9 5-1.4 10-0.7-19.5 5.8 1.4 4.3 2.1 1.5 0.7 3.5-5.2-5.4-10.7 0-2 2.6-1.4 5.7-4.3 0-8.6 0 0-12.1 0-24.2-5.7 0-11.5 0 3.9-3.9 10.1-2.8 0-2.8 0-5.7 5 0 10 0 0 2.9 0 5.7z"/></g><g id="Layer"><path id="Layer" fill="white" d="m29.4 39.1q2.3 0.2 2.8 2.8-0.7 1.4-1.4 2.9-2-2.6-1.4-5.7z"/></g><g id="Layer"><path id="Layer" fill="white" d="m29.4 17.8q2.7 2.9 2.8 7.8-0.7 3.9-1.4 7.8-2.1-7.7-1.4-15.6z"/></g></svg>`
}

const getInstTreatmentMarker = color => {
    const colorFormatted = formatColor(color)
    return `<svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 63 81" width="36" height="53"><title>map_installation_poste_traitement-svg</title><g id="Layer"><path id="Layer" fill="${colorFormatted}" d="m19.3-0.7q10.8 0 21.5 0 15.7 5.6 21.5 21.3 0 12.1 0 24.2-12.7 18.3-27.2 35.5-4.3 0-8.6 0-2-4.5-5-8.5-14.1-11.5-22.2-27 0-12.1 0-24.2 5.6-14.8 20-21.3z"/></g><g id="Layer"><path id="Layer" fill="white" d="m22.2 7.8q40.6-2 27.9 35.5-13.6 15.6-32.2 6.4-16.9-15.3-5-34.8 5-3.3 9.3-7.1z"/></g><g id="Layer"><path id="Layer" fill="${colorFormatted}" d="m13.6 17.8q9.3-1.4 16.5 4.2 0.9 12.6-12.2 10 0 3.5 0 7.1-2.1 0-4.3 0 0-10.7 0-21.3z"/></g><g id="Layer"><path id="Layer" fill="${colorFormatted}" d="m33.6 17.8q6.6-0.7 12.9 1.4-1.6 1.7-3.5 2.8-1.1 8.5-0.8 17.1-2.1 0-4.3 0 0-9.3 0-18.5-3.6 0.5-4.3-2.8z"/></g><g id="Layer"><path id="Layer" fill="white" d="m19.3 20.6q8 0.1 5.8 7.1-3.4 2-7.2 1.4-0.4-4.5 1.4-8.5z"/></g></svg>`
}

const getInstTankMarker = color => {
    const colorFormatted = formatColor(color)
    return `<svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 63 81" width="36" height="53"><title>map_installation_reservoir-svg</title><g id="Layer"><path id="Layer" fill="${colorFormatted}" d="m22.2-0.7q7.9 0 15.7 0 17.7 5.5 24.4 22.7 0 10 0 19.9-3.9 9.1-10 17.1-1.5 0-1.5 1.4-9.9 8.4-15.7 19.9-3.6 0-7.2 0-7.6-12.9-19.3-22.7-5.9-8.9-9.3-18.5 0-7.8 0-15.7 2.7-8.1 7.9-15.6 6.6-6.3 15-8.5z"/></g><g id="Layer"><path id="Layer" fill="white" d="m23.6 7.8q32.7-2 29.4 29.9-11.5 23.2-35.1 12-15.7-13.5-6.4-31.9 5.2-6.3 12.1-10z"/></g><g id="Layer"><path id="Layer" fill="${colorFormatted}" d="m39.4 41.9q0 2.9 0 5.7-8.6 0-17.2 0 2.4-8.1 0-15.6-1.3-6.1-2.9-12.1 14.8-16.5 21.5 4.3-0.4 6.7-2.9 12.7 0.1 3 1.5 5z"/></g><g id="Layer"><path id="Layer" fill="${colorFormatted}" d="m22.2 32q2.4 7.5 0 15.6 8.6 0 17.2 0 0-2.8 0-5.7 2 3 1.4 7.1-10 0-20 0 1.1-8.5 1.4-17z"/></g><g id="Layer"><path id="Layer" fill="${colorFormatted}" d="m52.3 59q2.8 1.4 0 0z"/></g><g id="Layer"><path id="Layer" fill="${colorFormatted}" d="m50.8 60.4q0.7 0 1.5 0-0.4 1.4-1.5 0z"/></g></svg>`
}

const getInstAepProductionMarker = color => {
    const colorFormatted = formatColor(color)
    return `<svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 63 81" width="36" height="53"><title>map_installation_production_aep-svg</title><g id="Layer"><path id="Layer" fill="${colorFormatted}" d="m19.3-0.7q10.8 0 21.5 0 15.7 5.6 21.5 21.3 0 12.1 0 24.2-12.7 18.3-27.2 35.5-4.3 0-8.6 0-2-4.5-5-8.5-14.1-11.5-22.2-27 0-12.1 0-24.2 5.6-14.8 20-21.3z"/></g><g id="Layer"><path id="Layer" fill="white" d="m22.2 7.8q39.9-1.7 27.9 35.5-15.7 17.1-35.1 4.3-12-14.4-3.5-31.3 5.3-4.5 10.7-8.5z"/></g><g id="Layer"><path id="Layer" fill="${colorFormatted}" d="m25.1 13.5q8.2 0.3 16.4 0 7.2 2.8 0 5.7-3.5-2.9-6.4 0.7 8.1 2.3 5 10.7-5.9 2.3-12.2 0.7-1.3-1.6-2.1-3.6-2.3 2.4-5 4.3 0.4 1.2 1.4 2.1-11.8 5.3-6.5-6.4 4.9-3.8 10.1-2.8 1.3-3.9 5-5.7-3.5-1.6-7.2-0.7-1-0.9-1.4-2.2 1.6-1.4 2.9-2.8z"/></g><g id="Layer"><path id="Layer" fill="${colorFormatted}" d="m42.2 23.4q3.6 0 7.2 0 0.2 7.3-7.2 5.7 0-2.8 0-5.7z"/></g><g id="Layer"><path id="Layer" fill="${colorFormatted}" d="m16.5 37.7q9.1 6.4-1.5 8.5 0.8-4.3 1.5-8.5z"/></g></svg>`
}

const getInstAepDisinfectionMarker = color => {
    const colorFormatted = formatColor(color)
    return `<svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 63 81" width="36" height="53"><title>map_installation_production_aep-svg</title><g id="Layer"><path id="Layer" fill="${colorFormatted}" d="m19.3-0.7q10.8 0 21.5 0 15.7 5.6 21.5 21.3 0 12.1 0 24.2-12.7 18.3-27.2 35.5-4.3 0-8.6 0-2-4.5-5-8.5-14.1-11.5-22.2-27 0-12.1 0-24.2 5.6-14.8 20-21.3z"/></g><g id="Layer"><path id="Layer" fill="white" d="m22.2 7.8q39.9-1.7 27.9 35.5-15.7 17.1-35.1 4.3-12-14.4-3.5-31.3 5.3-4.5 10.7-8.5z"/></g><g id="Layer"><path id="Layer" fill="${colorFormatted}" d="m25.1 13.5q8.2 0.3 16.4 0 7.2 2.8 0 5.7-3.5-2.9-6.4 0.7 8.1 2.3 5 10.7-5.9 2.3-12.2 0.7-1.3-1.6-2.1-3.6-2.3 2.4-5 4.3 0.4 1.2 1.4 2.1-11.8 5.3-6.5-6.4 4.9-3.8 10.1-2.8 1.3-3.9 5-5.7-3.5-1.6-7.2-0.7-1-0.9-1.4-2.2 1.6-1.4 2.9-2.8z"/></g><g id="Layer"><path id="Layer" fill="${colorFormatted}" d="m42.2 23.4q3.6 0 7.2 0 0.2 7.3-7.2 5.7 0-2.8 0-5.7z"/></g><g id="Layer"><path id="Layer" fill="${colorFormatted}" d="m16.5 37.7q9.1 6.4-1.5 8.5 0.8-4.3 1.5-8.5z"/></g></svg>`
}

const getInstAepOverpressureMarker = color => {
    const colorFormatted = formatColor(color)
    return `<svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 63 81" width="36" height="53"><title>map_installation_production_aep-svg</title><g id="Layer"><path id="Layer" fill="${colorFormatted}" d="m19.3-0.7q10.8 0 21.5 0 15.7 5.6 21.5 21.3 0 12.1 0 24.2-12.7 18.3-27.2 35.5-4.3 0-8.6 0-2-4.5-5-8.5-14.1-11.5-22.2-27 0-12.1 0-24.2 5.6-14.8 20-21.3z"/></g><g id="Layer"><path id="Layer" fill="white" d="m22.2 7.8q39.9-1.7 27.9 35.5-15.7 17.1-35.1 4.3-12-14.4-3.5-31.3 5.3-4.5 10.7-8.5z"/></g><g id="Layer"><path id="Layer" fill="${colorFormatted}" d="m25.1 13.5q8.2 0.3 16.4 0 7.2 2.8 0 5.7-3.5-2.9-6.4 0.7 8.1 2.3 5 10.7-5.9 2.3-12.2 0.7-1.3-1.6-2.1-3.6-2.3 2.4-5 4.3 0.4 1.2 1.4 2.1-11.8 5.3-6.5-6.4 4.9-3.8 10.1-2.8 1.3-3.9 5-5.7-3.5-1.6-7.2-0.7-1-0.9-1.4-2.2 1.6-1.4 2.9-2.8z"/></g><g id="Layer"><path id="Layer" fill="${colorFormatted}" d="m42.2 23.4q3.6 0 7.2 0 0.2 7.3-7.2 5.7 0-2.8 0-5.7z"/></g><g id="Layer"><path id="Layer" fill="${colorFormatted}" d="m16.5 37.7q9.1 6.4-1.5 8.5 0.8-4.3 1.5-8.5z"/></g></svg>`
}

const getInstAgriPrelMarker = color => {
    const colorFormatted = formatColor(color)
    return `<svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 63 81" width="36" height="53"><title>map_installation_prelevements-svg</title><g id="Layer"><path id="Layer" fill="${colorFormatted}" d="m19.3-0.7q10.8 0 21.5 0 15.7 5.6 21.5 21.3 0 12.1 0 24.2-12.7 18.3-27.2 35.5-4.3 0-8.6 0-2-4.5-5-8.5-14.1-11.5-22.2-27 0-12.1 0-24.2 5.6-14.8 20-21.3z"/></g><g id="Layer"><path id="Layer" fill="white" d="m22.2 7.8q39.9-1.7 27.9 35.5-20.8 20.6-40.1-1.4-8.9-22.7 12.2-34.1z"/></g><g id="Layer"><path id="Layer" fill="${colorFormatted}" d="m35.1 20.6q5.7 0 11.4 0 0 2.1 0 4.3-5.7 0-11.4 0 4.6-1.8 10-2.2-5.4-0.4-10-2.1z"/></g><g id="Layer"><path id="Layer" fill="${colorFormatted}" d="m15 23.4q5.8 0 11.5 0 0 12.1 0 24.2-1.4-6.8-8.6-8.5-2.2 1.4-4.3 2.8-0.7-0.7-1.4-1.4 2.5-2.9 6.4-2.8 3.3 0.9 6.5 0.7-4.3-3.8-10.1-4.3 4.5-1.5 8.6 0.7 2.1-4.8 1.5-9.9-5.5 0.6-10.1-1.5z"/></g><g id="Layer"><path id="Layer" fill="${colorFormatted}" d="m35.1 20.6q4.6 1.7 10 2.1-5.4 0.4-10 2.2 0 4.9 0 9.9 5-1.4 10-0.7-19.5 5.8 1.4 4.3 2.1 1.5 0.7 3.5-5.2-5.4-10.7 0-2 2.6-1.4 5.7-4.3 0-8.6 0 0-12.1 0-24.2-5.7 0-11.5 0 3.9-3.9 10.1-2.8 0-2.8 0-5.7 5 0 10 0 0 2.9 0 5.7z"/></g><g id="Layer"><path id="Layer" fill="white" d="m29.4 39.1q2.3 0.2 2.8 2.8-0.7 1.4-1.4 2.9-2-2.6-1.4-5.7z"/></g><g id="Layer"><path id="Layer" fill="white" d="m29.4 17.8q2.7 2.9 2.8 7.8-0.7 3.9-1.4 7.8-2.1-7.7-1.4-15.6z"/></g></svg>`
}

const getInstMarkerByType = (instType, color, withStroke = false) => {
    switch (instType) {
        case INSTALLATION_TYPE.BOREHOLE:
            return getInstBoreholeMarker(color, withStroke)
        case INSTALLATION_TYPE.INDUSTRIAL_SITE:
            return getInstIndustrialSiteMarker(color, withStroke)
        case INSTALLATION_TYPE.STEP:
            return getInstStepMarker(color, withStroke)
        case INSTALLATION_TYPE.FLOW_OBSTRUCTION:
            return getInstFlowObstructionMarker(color, withStroke)
        case INSTALLATION_TYPE.POLLUTED_SOIL:
            return getInstPollutedSoilMarker(color, withStroke)
        case INSTALLATION_TYPE.FISHING_SPOT:
            return getInstFishingSpotMarker(color, withStroke)
        case INSTALLATION_TYPE.CAVITY:
            return getInstCavityMarker(color, withStroke)
        case INSTALLATION_TYPE.SAMPLE_WORK:
            return getInstSampleWorkMarker(color, withStroke)
        case INSTALLATION_TYPE.CAPTURE:
            return getInstCaptureMarker(color, withStroke)
        case INSTALLATION_TYPE.LIFTING_STATION:
            return getInstLiftingStationMarker(color, withStroke)
        case INSTALLATION_TYPE.PUMPING:
            return getInstPumpingMarker(color, withStroke)
        case INSTALLATION_TYPE.TREATMENT:
            return getInstTreatmentMarker(color, withStroke)
        case INSTALLATION_TYPE.TANK:
            return getInstTankMarker(color, withStroke)
        case INSTALLATION_TYPE.OTHERS:
            return getInstMarker(color, withStroke)
        case INSTALLATION_TYPE.AEP_PRODUCTION:
            return getInstAepProductionMarker(color, withStroke)
        case INSTALLATION_TYPE.AEP_DISINFECTION:
            return getInstAepDisinfectionMarker(color, withStroke)
        case INSTALLATION_TYPE.AEP_OVERPRESSURE:
            return getInstAepOverpressureMarker(color, withStroke)
        case INSTALLATION_TYPE.AGRI_PREL:
            return getInstAgriPrelMarker(color, withStroke)
        case INSTALLATION_TYPE.INDUS_PREL:
            return getInstMarker(color, withStroke)
        case INSTALLATION_TYPE.SECTOR_COUNT:
            return getInstMarker(color, withStroke)
        case INSTALLATION_TYPE.DOM_SUB:
            return getInstMarker(color, withStroke)
        case INSTALLATION_TYPE.ELEC_COUNTER:
            return getInstMarker(color, withStroke)
        case INSTALLATION_TYPE.NETWORK:
            return getInstMarker(color, withStroke)
        case INSTALLATION_TYPE.STRAIT:
            return getInstMarker(color, withStroke)
        case INSTALLATION_TYPE.BRIDGE:
            return getInstMarker(color, withStroke)
        case INSTALLATION_TYPE.SLUICE:
            return getInstMarker(color, withStroke)
        default:
            return getInstMarker(color, withStroke)
    }
}

const getUnitProdMarker = color => {
    const colorFormatted = formatColor(color)
    return `<svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 65" width="50" height="65"><title>map_unit_prod-svg</title><g id="g-3"><path id="ellipse-2" fill="white" d="m25.4 47.1c-11.6 0-20.9-9.6-20.9-21.5 0-11.9 9.3-21.5 20.9-21.5 11.5 0 20.8 9.6 20.8 21.5 0 11.9-9.3 21.5-20.8 21.5z"/><path id="path-7" fill="${colorFormatted}" d=""/><path id="path-8" fill="${colorFormatted}" d=""/><path id="path-9" fill="${colorFormatted}" d=""/><path id="path-10" fill="${colorFormatted}" d=""/><path id="path-11" fill="${colorFormatted}" d=""/><g id="g-4"><path id="path-12" fill-rule="evenodd" fill="${colorFormatted}" d="m23.8 64.5c-0.4-0.2-1.2-1.3-1.9-2.3-1.6-2.8-3.5-5-8.9-10.8-5.5-5.8-7.6-8.6-9.6-12.6-2.1-4.4-2.6-6.9-2.6-12.5 0-5.4 0.4-7.4 2.4-11.5 3.1-6.7 8.8-11.5 16-13.4 3-0.8 8.4-0.8 11.4-0.1 12.6 3 20.6 14.8 19.1 28.3-0.9 7.9-3.7 13-12.3 22-4.5 4.7-7.4 8.1-8.9 10.7-1.4 2.5-2.9 3.1-4.7 2.2zm6.2-21.5c6.3-1.6 11.3-6.6 13.1-13.1 0.6-2.4 0.6-6.8 0-9.2-1.8-6.5-7-11.5-13.5-13.2-2.6-0.6-7.7-0.4-10.2 0.4-5.4 1.9-9.7 6.2-11.7 11.8-0.9 2.4-1.1 7.1-0.4 9.7 1.7 7.3 7.8 12.9 15.2 14.1 1.9 0.3 5.3 0.1 7.5-0.5z"/></g><path id="path97" fill="${colorFormatted}" d="m10.4 28.6v-4.6l3.1-2.8 2.5-2.3v1.3l-0.1 3.4 1.5-2.2 1.6-2.4v1.1l0.1 3.3h1.9l1.5-0.1c0.7-3.3 1.3-6.6 1.8-10h2.4 2.4v9.1h1.9c0.2-3.2 0.4-6 0.5-9.2h2.4 2.7q0.4 4.3 0.7 8.6l1 0.9 0.9 1v5.7 5.8h-14.4-14.4v-5.6z"/></g></svg>`
}

const getArrowUp = (color, jsx, width) => {
    const colorFormatted = formatColor(color)
    if (jsx) {
        return <svg version='1.2' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 62 84' width={ width ?? '36' } height='53'><title>Hausse</title><g id='layer1'><path id='path630' fill={color} stroke='black' strokeLinejoin='round' d='M 31,0 0,84 31,66 62,84 Z'/></g></svg>
    }
    return `<svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 62 84" width="36" height="53"><title>arrow_up-svg</title><g id="layer1"><path id="path630" fill="${colorFormatted}" stroke="black" stroke-linejoin="round" d="M 31,0 0,84 31,66 62,84 Z"/></g></svg>`
}

const getArrowDown = (color, jsx, width) => {
    const colorFormatted = formatColor(color)
    if (jsx) {
        return <svg version='1.2' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 62 84' width={ width ?? '36' } height='53'><title>Baisse</title><g id='layer1'><path id='path630' fill={color} stroke='black' strokeLinejoin='round' d='M 31,84 0,0 31,18 62,0 Z'/></g></svg>
    }
    return `<svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 62 84" width="36" height="53"><title>map_arrow_down-svg</title><g id="layer1"><path id="path630" fill="${colorFormatted}" stroke="black" stroke-linejoin="round" d="M 31,84 0,0 31,18 62,0 Z"/></g></svg>`
}

const getArrowRight = (color, jsx, width) => { // yes it's a square
    const colorFormatted = formatColor(color)
    if (jsx) {
        return <svg version='1.2' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 50 50' width={ width ?? '35' } height='35'><title>Stable</title><g id='layer1'><rect id='path630' fill={color} stroke='black' width='50' height='50' x='0' y='0' rx='10' ry='10'/></g></svg>
    }
    return `<svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" width="35" height="35"><title>square-svg</title><g id="layer1"><rect id="path630" fill="${colorFormatted}" stroke="black" width="50" height="50" x="0" y="0" rx="10" ry="10"/></g></svg>`
}

const convertSvgToBase64 = svg => `image://data:image/svg+xml;base64,${window.btoa(svg.replaceAll('"%23', '"#'))}`

export {
    excelIcon,
    xmlIcon,
    getPiezoMarker,
    getCardPiezoMarker,
    getHydroMarker,
    getCardHydroMarker,
    getPluvioMarker,
    getCardPluvioMarker,
    getInstMarker,
    getInstMarkerByType,
    getArrowUp,
    getArrowDown,
    getArrowRight,
    getHydroTideMarker,
    getUnitProdMarker,
    convertSvgToBase64,
}
