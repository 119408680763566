import React, { Component } from 'react'
import PropTypes from 'prop-types'
import DtoStation from 'station/dto/DtoStation'
import i18n from 'simple-react-i18n'
import { getDate } from '../../../utils/DateUtil'

class StationUpdatePanel extends Component {
    render() {
        if (this.props.station && this.props.station.updateDate && this.props.station.updateLogin) {
            return (
                <div className={`card margin-top-0 margin-bottom-1 ${this.props.className}`}>
                    <div className='card-content'>
                        <div className='row no-margin'>
                            <div className='col l12 no-padding left-align'>
                                { `${i18n.updatedAt} ${getDate(this.props.station.updateDate)} ${i18n.by} ${this.props.station.updateLogin}` }
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        return (
            <div className={`card margin-top-0 margin-bottom-1 ${this.props.className}`}>
                <div className='card-content'>
                    <div className='row no-margin'>
                        <div className='col l12 no-padding left-align'>
                            { i18n.noUserSet }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

StationUpdatePanel.propTypes = {
    station: PropTypes.instanceOf(DtoStation),
    className: PropTypes.string,
}

export default StationUpdatePanel
