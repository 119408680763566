import { orderBy } from 'lodash'
import React, { useMemo, useState } from 'react'
import { AutoSizer, Grid, ScrollSync } from 'react-virtualized'
import { sieauTooltip } from 'utils/FormUtils'
import i18n from 'simple-react-i18n'
import PropTypes from 'prop-types'

const ASC = 'asc'
const DESC = 'desc'
const NONE = 'none'

const SCROLL_SIZE = 20

const DEFAULT_CELL_STYLE = {
    display: 'flex',
    alignItems: 'center',
    borderRight: '1px solid #eee',
    backgroundColor: '#fff',
    whiteSpace: 'nowrap',
    padding: '0px 5px',
}

const getHeaderLabel = (keyLabel, value, rowIndex) => {
    if (value) {
        return value
    }
    if (rowIndex !== 0) {
        return ''
    }
    if (!/^\d+$/.test(keyLabel) && /^[0-9A-Za-z]+$/.test(keyLabel)) {
        try {
            return i18n[keyLabel]
        } catch (e) {
            return (
                <i className='waves-effect material-icons'>grade</i>
            )
        }
    }
    return keyLabel
}

const VirtualizedTable = ({
    data = [],
    headers = [],
    onClick = () => { },

    tableHeight = '70%',
    headerHeight = 29,
    rowHeight = 19,
    columnWidth = 200,
    overscanColumnCount = 0,
    overscanRowCount = 5,
}) => {
    const [sort, setSort] = useState(() => ({ sortColumn: [], sortDirection: {} }))

    const sortedData = useMemo(() => {
        const {
            sortColumn,
            sortDirection,
        } = sort
        return orderBy(data, sortColumn.map(c => elem => elem[c]?.sortValue ?? elem[c]?.value), sortColumn.map(c => sortDirection[c]))
    }, [data, sort])

    return (
        <div style={{ height: tableHeight }}>
            <ScrollSync>
                {
                    ({
                        onScroll,
                        scrollLeft,
                    }) => (
                        <AutoSizer>
                            {
                                ({ width, height }) => (
                                    <div>
                                        <div
                                            style={{
                                                height: headerHeight,
                                                width: width - SCROLL_SIZE,
                                            }}
                                        >
                                            <Grid
                                                columnWidth={columnWidth}
                                                columnCount={headers.length}
                                                height={headerHeight}
                                                className='no-overflow'
                                                style={{ overflow: 'hidden' }}
                                                overscanColumnCount={overscanColumnCount}
                                                cellRenderer={({ columnIndex, key, rowIndex, style }) => {
                                                    const keyLabel = headers[columnIndex]

                                                    const headerStyle = {
                                                        ...DEFAULT_CELL_STYLE,
                                                        ...style,
                                                        borderBottom: '1px solid #000',
                                                        justifyContent: 'center',
                                                        fontWeight: 'bold',
                                                        fontSize: '16px',
                                                    }

                                                    return (
                                                        <div
                                                            className={'white clickable'}
                                                            style={headerStyle}
                                                            key={key}
                                                            onClick={() => {
                                                                setSort(({ sortDirection, sortColumn }) => {
                                                                    switch (sortDirection[keyLabel]) {
                                                                        case ASC: return { sortDirection: { ...sortDirection, [keyLabel]: DESC }, sortColumn }
                                                                        case DESC: return { sortDirection: { ...sortDirection, [keyLabel]: NONE }, sortColumn: sortColumn.filter(c => c !== keyLabel) }
                                                                        default: return { sortDirection: { ...sortDirection, [keyLabel]: ASC }, sortColumn: [...sortColumn, keyLabel] }
                                                                    }
                                                                })
                                                            }}
                                                        >
                                                            {getHeaderLabel(keyLabel, '', rowIndex)}
                                                            {
                                                                rowIndex === 0 && sort.sortDirection[keyLabel] === ASC && <i className='material-icons' style={{ fontSize: 18 }}>arrow_drop_up</i>
                                                            }
                                                            {
                                                                rowIndex === 0 && sort.sortDirection[keyLabel] === DESC && <i className='material-icons' style={{ fontSize: 18 }}>arrow_drop_down</i>
                                                            }
                                                        </div>
                                                    )
                                                }}
                                                rowHeight={headerHeight}
                                                rowCount={1}
                                                scrollLeft={scrollLeft}
                                                width={width - SCROLL_SIZE}
                                            />
                                        </div>
                                        <div
                                            style={{
                                                height: (height - headerHeight),
                                                width,
                                                backgroundColor: 'white',
                                            }}
                                        >
                                            <Grid
                                                columnWidth={columnWidth}
                                                columnCount={headers.length}
                                                height={(height - headerHeight)}
                                                onScroll={onScroll}
                                                overscanColumnCount={overscanColumnCount}
                                                overscanRowCount={overscanRowCount}

                                                cellRenderer={({ columnIndex, key, rowIndex, style }) => {
                                                    const keyLabel = headers[columnIndex]
                                                    const row = sortedData[rowIndex]
                                                    const {
                                                        [keyLabel]: cell = {},
                                                    } = row

                                                    const {
                                                        value = '',
                                                        tooltip,
                                                        justifyContent = 'left',
                                                        classNameColor = 'white',
                                                        color = 'white',
                                                        rightIcon,
                                                        rightIconSize = '1rem',
                                                        rightIconColor = 'grey',
                                                    } = cell

                                                    const tooltipObj = tooltip ? sieauTooltip(tooltip, null, 'top') : {}
                                                    const cellStyle = {
                                                        ...style,
                                                        ...DEFAULT_CELL_STYLE,
                                                        justifyContent,
                                                        borderBottom: '1px solid #eee',
                                                    }

                                                    return (
                                                        <div className={`${!color ? classNameColor : ''} clickable`} key={key} style={{ ...cellStyle, backgroundColor: color }} onClick={() => onClick(row)} {...tooltipObj}>
                                                            {value}
                                                            {!!rightIcon && <i className='material-icons' style={{ fontSize: rightIconSize, color: rightIconColor }}>{rightIcon}</i>}
                                                        </div>
                                                    )
                                                }}
                                                rowHeight={rowHeight}
                                                rowCount={sortedData.length}
                                                width={width}
                                            />
                                        </div>
                                    </div>
                                )
                            }
                        </AutoSizer>
                    )
                }
            </ScrollSync>
        </div>
    )
}

VirtualizedTable.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    data: PropTypes.array.isRequired,
    // data: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.shape({
    //     value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.element]),
    //     sortValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    //     classNameColor: PropTypes.string,
    //     tooltip: PropTypes.oneOfType([PropTypes.func, PropTypes.string, PropTypes.element]),
    //     justifyContent: PropTypes.string,
    //     rightIcon: PropTypes.string,
    //     rightIconSize: PropTypes.string,
    //     rightIconColor: PropTypes.string,
    // }))).isRequired,
    headers: PropTypes.arrayOf(PropTypes.string).isRequired,
    onClick: PropTypes.func,

    tableHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    headerHeight: PropTypes.number,
    rowHeight: PropTypes.number,
    columnWidth: PropTypes.number,
    overscanColumnCount: PropTypes.number,
    overscanRowCount: PropTypes.number,
}

export default VirtualizedTable