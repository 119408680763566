import { push } from '@lagunovsky/redux-react-router'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import Icon from '../../../components/icon/Icon'
import DtoPowerSupplySituation from '../../../materiel/components/powerSupply/dto/DtoPowerSupplySituation'
import PowerSupplyDto from '../../../materiel/components/powerSupply/dto/PowerSupplyDto'
import PowerSupplyTypeDto from '../../../materiel/components/powerSupply/dto/PowerSupplyTypeDto'
import { getMaterialsInStock } from '../../../materiel/utils/MaterielUtils'
import { getDate } from '../../../utils/DateUtil'
import { getLabel } from '../../../utils/StoreUtils'

const PowerSupplyEquivalentCard = ({
    campaignMaterials = [],
    powerSupplies = [],
    powerSupplyTypes = [],
    powerSuppliesLastSituations = [],
    redirect = () => { },
}) => (
    <div>
        {
            campaignMaterials.map(mat => {
                const nbMaterialInStock = getMaterialsInStock(powerSupplies, powerSuppliesLastSituations, 'idPowerSupply').filter(m => m.powerSupplyType === mat.powerSupplyType).length
                const materialtooltip = nbMaterialInStock > 1 ? i18n.materialsStock : i18n.materialStock
                const labelType = getLabel(powerSupplyTypes, mat.powerSupplyType)
                return (
                    <div className='row col s12 valign-wrapper'>
                        <div className='col s1'>
                            <Icon icon={'battery_charging_full'} size={'medium'} tooltip={i18n.powerSupply} />
                        </div>
                        <div className='col s11 valign-wrapper'>
                            <div className='col s5'>
                                <div className='col s12'>
                                    {`${labelType} - ${mat.serialNumber || ''}`}
                                </div>
                                <div className='col s12'>
                                    {`${i18n.onSiteSince} ${getDate(mat.situationDate)}`}
                                </div>
                            </div>
                            <div className='col s1'>
                                <Icon icon='arrow_forward' size={'medium'} tooltip={i18n.equivalentMaterialInStock} />
                            </div>
                            <div className='col s6'>
                                <div className='collection padding-left-1'>
                                    <a className='collection-item no-padding' onClick={() => redirect(`/materiel/powerSupply/${mat.powerSupplyType ? `?idType=${mat.powerSupplyType}` : ''}`)}>
                                        {`${labelType}`}
                                        <span className={`badge ${nbMaterialInStock > 1 ? '' : 'red'} tooltipped`} data-tooltip={`${nbMaterialInStock} ${materialtooltip}`}>
                                            {nbMaterialInStock}
                                        </span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            })
        }
    </div>
)

PowerSupplyEquivalentCard.propTypes = {
    campaignMaterials: PropTypes.arrayOf(PropTypes.object),
    powerSupplies: PropTypes.arrayOf(PropTypes.instanceOf(PowerSupplyDto)),
    powerSupplyTypes: PropTypes.arrayOf(PropTypes.instanceOf(PowerSupplyTypeDto)),
    powerSuppliesLastSituations: PropTypes.arrayOf(PropTypes.instanceOf(DtoPowerSupplySituation)),
    redirect: PropTypes.func,
}

const mapStateToProps = store => ({
    powerSupplies: store.PowerSupplyReducer.powerSupplies,
    powerSupplyTypes: store.PowerSupplyReducer.powerSupplyTypes,
    powerSuppliesLastSituations: store.PowerSupplyReducer.powerSuppliesLastSituations,
})

const mapDispatchToProps = {
    redirect: push,
}

export default connect(mapStateToProps, mapDispatchToProps)(PowerSupplyEquivalentCard)
