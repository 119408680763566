import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import Card from '../../../../../components/card/Card'
import Table from '../../../../../components/datatable/Table'
import Icon from '../../../../../components/icon/Icon'
import SieauAction from '../../../../../components/sieau/SieauAction'
import PiezometryAction from '../../../../../piezometry/actions/PiezometryAction'
import DtoParametrageDataType from '../../../../../piezometry/dto/DtoParametrageDataType'
import AppStore from '../../../../../store/AppStore'
import { setConfirmationModal } from '../../../../../utils/FormUtils'
import { getLabel } from '../../../../../utils/StoreUtils'
import { getI18nTitleDataLength } from '../../../../../utils/StringUtil'
import CentralAction from '../../actions/CentralAction'
import CentralDto from '../../dto/CentralDto'
import DtoCentralChannel from '../../dto/DtoCentralChannel'
import DtoCentralChannelItem from '../../dto/DtoCentralChannelItem'
import ChannelModal from './ChannelModal'

class ChannelPanel extends Component {
    componentDidMount = () => {
        if (!this.props.piezometryDataTypes.length) {
            this.props.fetchPiezometryDataTypes()
        }
    }

    getRemoteTranmissionIcon = (teletransmission) => {
        switch (teletransmission) {
            case '1':
                return 'wifi_tethering'
            default:
                return 'portable_wifi_off'
        }
    }

    getAcquisitionIcon = (register) => {
        if (register === '1') {
            return <Icon icon='check_box' />
        }
        return null
    }

    getDatas = () => {
        if (this.props.centralChannels.length) {
            const dataTypes = [{ id: 0, label: i18n.depth }, ...this.props.piezometryDataTypes]
            return this.props.centralChannels.map(o => {
                const reasignValue = {
                    ...o,
                    remoteTransmission: this.getRemoteTranmissionIcon(o.remoteTransmission),
                    acquisition: this.getAcquisitionIcon(o.remoteTransmission),
                    dataType: getLabel(dataTypes, o.dataType, null, 'id'),
                    nullValue: this.props.active ? [
                        <Icon icon='delete'
                            onClick={ () => setConfirmationModal(() => AppStore.dispatch(CentralAction.updateCentralChannel(this.props.central.id, o, 'DELETE', () => this.props.refreshDataFunction()))) }
                        />,
                        <Icon icon='edit' onClick={ () => this.setChannelModal(o, false) } />,
                    ] : null,
                }
                return new DtoCentralChannelItem(reasignValue)
            })
        }
        return []
    }

    setChannelModal = (channel = {}, isNew = true) => {
        const buttons = (
            <div>
                <a className='waves-effect waves-teal btn-flat modal-close'>{ i18n.cancel }</a>
                <a id='saveChannel' className='waves-effect waves-teal btn-flat'>{ i18n.register }</a>
            </div>
        )
        const popup = {
            id: 'channelModal',
            header: i18n.channel,
            actions: buttons,
            fixedFooter: true,
            dismissible: false,
            className: 'hugeModal hightHeightModal',
            content: <ChannelModal central={ this.props.central } channel={ channel } isNew={ isNew } refreshDataFunction={ this.props.refreshDataFunction } />,
        }
        AppStore.dispatch(SieauAction.setPopup(popup))
    }

    render() {
        const datas = this.getDatas()
        const actions = [
            {
                iconName: 'note_add',
                onClick: () => this.setChannelModal({}, true),
            },
        ]
        return (
            < div className='row margin-top-2' >
                <div className='col s12'>
                    <Card title={ getI18nTitleDataLength(i18n.path, i18n.paths, this.props.centralChannels.length) }
                        active={ this.props.active } actions={ actions }
                    >
                        <Table showTitle={ false } data={ datas }
                            type={ new DtoCentralChannelItem({}) } condensed sortable
                        />
                    </Card>
                </div>
            </div >
        )
    }
}

ChannelPanel.propTypes = {
    central: PropTypes.instanceOf(CentralDto),
    centralChannels: PropTypes.arrayOf(PropTypes.instanceOf(DtoCentralChannel)),
    active: PropTypes.bool,
    refreshDataFunction: PropTypes.func,
    piezometryDataTypes: PropTypes.arrayOf(PropTypes.instanceOf(DtoParametrageDataType)),
    fetchPiezometryDataTypes: PropTypes.func,
}

const mapStateToProps = store => ({
    piezometryDataTypes: store.PiezometryReducer.piezometryDataTypes,
})

const mapDispatchToProps = {
    fetchPiezometryDataTypes: PiezometryAction.fetchPiezometryDataTypes,
}

export default connect(mapStateToProps, mapDispatchToProps)(ChannelPanel)