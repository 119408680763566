'use strict'
import { RECEIVE_MANAGEMENT_UNIT, RECEIVE_MANAGEMENT_UNITS, RECEIVE_MANAGEMENT_UNITS_RESTRICTIONS } from '../constants/ManagementUnitConstants'
import DtoManagementUnit from '../dto/DtoManagementUnit'
import DtoManagementUnitRestriction from '../dto/DtoManagementUnitRestriction'

export function ManagementUnitReducer(state = {}, action) {
    switch (action.type) {
        case RECEIVE_MANAGEMENT_UNIT:
            return {
                ...state,
                managementUnit: new DtoManagementUnit(action.managementUnit),
            }
        case RECEIVE_MANAGEMENT_UNITS:
            return {
                ...state,
                managementUnits: action.managementUnits.map(managementUnit => new DtoManagementUnit(managementUnit)),
            }
        case RECEIVE_MANAGEMENT_UNITS_RESTRICTIONS:
            return {
                ...state,
                managementUnitsRestrictions: action.managementUnitsRestrictions.map((d) => new DtoManagementUnitRestriction(d)),
            }
        default:
            return state
    }
}

export const store = {
    managementUnit: {},
    managementUnits: [],
    managementUnitsRestrictions: [],
}