import { Grid } from '@mui/material'
import Checkbox from 'components/forms/Checkbox'
import SuperMultiAutocomplete from 'components/forms/SuperMultiAutocomplete'
import { StyledFieldSet, StyledLegend } from 'components/StyledElements'
import { push } from '@lagunovsky/redux-react-router'
import { isEqual, omit, template } from 'lodash'
import PropTypes from 'prop-types'
import React, { useEffect, useMemo, useState } from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import ToastrAction from 'toastr/actions/ToastrAction'
import { hasValue } from 'utils/NumberUtil'
import UserAction from '../../../administration/components/user/actions/UserAction'
import ActionComponent from '../../../components/ActionComponent'
import Card from '../../../components/card/Card'
import DatePicker from '../../../components/forms/DatePicker'
import Input from '../../../components/forms/Input'
import NumberField from '../../../components/forms/NumberField'
import Select from '../../../components/forms/Select'
import Textarea from '../../../components/forms/Textarea'
import Row from '../../../components/react/Row'
import SieauAction from '../../../components/sieau/SieauAction'
import AquiferAction from '../../../referencial/components/aquifers/actions/AquiferAction'
import DtoAquifer from '../../../referencial/components/aquifers/dto/DtoAquifer'
import ContactCard from '../../../referencial/components/contact/components/ContactCard'
import NetworkAction from '../../../referencial/components/network/actions/NetworkAction'
import WatermassAction from '../../../referencial/components/watermass/actions/WatermassAction'
import WatermassDto from '../../../referencial/components/watermass/dto/WatermassDto'
import WatershedAction from '../../../referencial/components/watershed/actions/WatershedAction'
import WatershedDto from '../../../referencial/components/watershed/dto/WatershedDto'
import { SANDRE } from '../../../referencial/constants/ReferencialConstants'
import LinkedStationsPanel from '../../../station/components/associatedStation/LinkedStationsPanel'
import StationMapDashboardPanel from '../../../station/components/dashboard/component/map/StationMapDashboardPanel'
import StationAccessibilitiesPanel from '../../../station/components/link/StationAccessibilitiesPanel'
import StationArrangementPanel from '../../../station/components/link/StationArrangementPanel'
import StationCasingPanel from '../../../station/components/link/StationCasingPanel'
import StationContactsPanel from '../../../station/components/link/StationContactsPanel'
import StationFunctionPanel from '../../../station/components/link/StationFunctionPanel'
import StationHydrogeologicalEntityPanel from '../../../station/components/link/StationHydrogeologicalEntityPanel'
import StationLithologyPanel from '../../../station/components/link/StationLithologyPanel'
import StationMeasureMethodPanel from '../../../station/components/link/StationMeasureMethodPanel'
import StationNetworkPanel from '../../../station/components/link/StationNetworkPanel'
import StationPiezoContributorPanel from '../../../station/components/link/StationPiezoContributorPanel'
import StationSamplePointsPanel from '../../../station/components/link/StationSamplePointsPanel'
import StationUsagePanel from '../../../station/components/link/StationUsagePanel'
import StationWatermassPanel from '../../../station/components/link/StationWatermassPanel'
import StationUpdatePanel from '../../../station/components/update/StationUpdatePanel'
import { NEW, STATION_QUALITOMETER_NAMES } from '../../../station/constants/StationConstants'
import { getStationArrowNav } from '../../../utils/ActionUtils'
import { getLogin, getUser } from '../../../utils/SettingUtils'
import { getBookmarks, getLinks, isUndergroundWater } from '../../../utils/StationUtils'
import { arrayOf, getMapStateToProps, getPropTypes, getSandreList } from '../../../utils/StoreUtils'
import { getUserBookmarksByStationType } from '../../../utils/UserUtil'
import QualityAction from '../../actions/QualityAction'
import DtoQualitometer from '../../dto/DtoQualitometer'

const QualitySyncOptions = ({
    setSynchronizeOption = () => {},
}) => {
    const [description, setDescription] = useState(false)
    const [descriptionOption, setDescriptionOption] = useState(0)
    const [networks, setNetworks] = useState(false)
    const [networksOption, setNetworksOption] = useState(0)
    const [samplePoints, setSamplePoints] = useState(false)
    const [samplePointsOption, setSamplePointsOption] = useState(0)

    const selectOptions = useMemo(() => [{
        id: 0,
        name: i18n.complete,
    }, {
        id: 1,
        name: i18n.replace,
    }], [])

    useEffect(() => {
        setSynchronizeOption({
            description,
            descriptionOption,
            networks,
            networksOption,
            samplePoints,
            samplePointsOption,
        })
    }, [description, descriptionOption, networks, networksOption, samplePoints, samplePointsOption])

    return (
        <div style={{ textAlign: 'left' }}>
            <StyledFieldSet>
                <StyledLegend>{i18n.synchronizeName}</StyledLegend>
                <Grid container justifyContent='center'>
                    <Grid container item xs={11}>
                        <Grid item xs={6} sx={{ padding: '11px 0 10px' }}>
                            <Checkbox
                                label={i18n.description}
                                checked={description}
                                onChange={setDescription}
                            />
                        </Grid>
                        {description && (
                            <Grid item xs={6}>
                                <SuperMultiAutocomplete
                                    options={selectOptions}
                                    values={descriptionOption}
                                    onChange={setDescriptionOption}
                                    clearIcon={false}
                                />
                            </Grid>
                        )}
                    </Grid>
                    <Grid container item xs={11}>
                        <Grid item xs={6} sx={{ padding: '11px 0 10px' }}>
                            <Checkbox
                                label={i18n.networks}
                                checked={networks}
                                onChange={setNetworks}
                            />
                        </Grid>
                        {networks && (
                            <Grid item xs={6}>
                                <SuperMultiAutocomplete
                                    options={selectOptions}
                                    values={networksOption}
                                    onChange={setNetworksOption}
                                    clearIcon={false}
                                />
                            </Grid>
                        )}
                    </Grid>
                    <Grid container item xs={11}>
                        <Grid item xs={6} sx={{ padding: '11px 0 10px' }}>
                            <Checkbox
                                label={i18n.catchmentPoints}
                                checked={samplePoints}
                                onChange={setSamplePoints}
                            />
                        </Grid>
                        {samplePoints && (
                            <Grid item xs={6}>
                                <SuperMultiAutocomplete
                                    options={selectOptions}
                                    values={samplePointsOption}
                                    onChange={setSamplePointsOption}
                                    clearIcon={false}
                                />
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </StyledFieldSet>
        </div>
    )
}

QualitySyncOptions.propTypes = {
    setSynchronizeOption: PropTypes.func,
}

const storeProps = {
    qualitometer: false,
    qualitometers: false,
    cities: false,
    contacts: false,
    natures: true,
    qualities: true,
    fieldModes: true,
    qualitometerTypes: true,
    qualitometerRepresentiveness: true,
    waterSourceTypes: true,
    stationStates: true,
    watersheds: true,
}

class QualitometerDescriptionPanel extends ActionComponent {
    constructor(props) {
        super(props)
        this.state = {
            qualitometer: { ...props.qualitometer },
            readMode: true,
            synchronizeOption: {},
        }
    }

    componentDidMount() {
        this.props.fetch(storeProps)
        this.props.fetchBookmarks()
        if (this.props.qualitometer.id) {
            this.setState({ qualitometer: this.props.qualitometer })
        }
        if (this.props.id === NEW) {
            this.setEditMode()
        } else {
            this.setReadOnlyMode()
        }
        if (!this.props.aquifers.length) {
            this.props.fetchAquifers()
        }
        if (!this.props.watermasses.length) {
            this.props.fetchWatermasses()
        }
        if (!this.props.networks.length) {
            this.props.fetchNetworks()
        }
        if (!this.props.watersheds.length) {
            this.props.fetchWatersheds()
        }
    }

    componentDidUpdate = prevProps => {
        if (!isEqual(this.props.qualitometer, prevProps.qualitometer)) {
            this.setState({ qualitometer: this.props.qualitometer })
            this.setReadOnlyMode()
        }
        if (!isEqual(this.props.qualitometers, prevProps.qualitometers)) {
            this.setReadOnlyMode()
        }
    }

    onSave = () => {
        if (!this.state.qualitometer.code) {
            this.props.toastrError(template(i18n.fieldNotSet)({ field: i18n.code }))
        } else if (this.props.id === NEW && this.props.qualitometers.find(q => q.code === this.state.qualitometer.code)) {
            this.props.toastrError(i18n.bssCodeAlreadyExists)
        } else {
            this.props.update('qualitometer', this.state.qualitometer, this.setReadOnlyMode)
            this.setReadOnlyMode()
        }
    }

    setEditMode = () => {
        this.setState({ readMode: false })

        const deleteAction = { delete: () => this.props.deleteQualitometer(this.props.qualitometer.id).then(() => this.props.push('/quality')) }
        const otherActions = {
            exportmodel: () => ({
                stationId: this.props.qualitometer.id.toString(),
                stationCode: this.props.qualitometer.code,
                stationType: this.props.qualitometer.typeName,
                environmentModels: this.props.typeEnvironmentModels,
            }),
            save: () => this.onSave(),
            cancel: () => {
                if (this.props.id === NEW) {
                    this.props.push('/quality')
                } else {
                    this.setState({
                        qualitometer: this.props.qualitometer,
                    })
                    this.setReadOnlyMode()
                }
            },
            links: getLinks(this.props.qualitometer, this.props),
            arrowNav: getStationArrowNav('quality', this.props.qualitometers, this.props.qualitometer.id, s => this.props.push(`/station/quality/${s.id}/description`)),
        }
        const currentUser = getUser()
        const actions = currentUser.admin === '1' && currentUser.metadata === '1' ? otherActions : { ...otherActions, ...deleteAction }
        if (getUser().consultant === '1') {
            this.setActions(omit(actions, ['save', 'delete']))
        } else {
            this.setActions(actions)
        }
    }

    onSynchronize = () => this.props.synchronizeDescription(this.props.qualitometer.id, this.state.synchronizeOption)

    setReadOnlyMode = () => {
        this.setState({ readMode: true })
        const actions = {
            exportmodel: () => ({
                stationId: this.props.qualitometer.id.toString(),
                stationCode: this.props.qualitometer.code,
                stationType: this.props.qualitometer.typeName,
                environmentModels: this.props.typeEnvironmentModels,
            }),
            links: getLinks(this.props.qualitometer, this.props),
            arrowNav: getStationArrowNav('quality', this.props.qualitometers, this.props.qualitometer.id, s => this.props.push(`/station/quality/${s.id}/description`)),
            referencialActions: {
                actions: {
                    sync: {
                        updateAction: this.onSynchronize,
                        webServiceContent: (
                            <QualitySyncOptions
                                setSynchronizeOption={obj => this.setState(p => ({ ...p, synchronizeOption: { ...p.synchronizeOption, ...obj } }))}
                            />
                        ),
                    },
                },
            },
        }
        if (!(getUser().consultant === '1')) {
            this.setActions({
                ...actions,
                edit: () => this.setEditMode(),
                delete: () => this.props.deleteQualitometer(this.props.qualitometer.id).then(() => this.props.push('/quality')),
            })
        } else {
            this.setActions(actions)
        }
    }

    onChangeQualitometer = (value) => this.setState({ qualitometer: { ...this.state.qualitometer, ...value } })

    onChangeWork = (value) => {
        const { qualitometer } = this.state
        const qualitometerWork = qualitometer.link_work[0] || {}
        this.onChangeQualitometer({
            link_work: [{
                ...qualitometerWork,
                idStation: qualitometer.id,
                ...value,
            }],
            LAST_FORM: value.LAST_FORM,
        })
    }

    onChangeGeoData = (value) => {
        const { qualitometer } = this.state
        const linkGeo = qualitometer.link_geo && qualitometer.link_geo[0] || {}
        this.onChangeQualitometer({
            link_geo: [{
                ...linkGeo,
                idStation: qualitometer.id,
                ...value,
            }],
            LAST_FORM: value.LAST_FORM,
        })
    }

    setBookmark = (deleteBookmark = false) => {
        const { qualitometer } = this.state
        if (deleteBookmark) {
            this.props.deleteBookmark({ identifiant: qualitometer.code, login: getLogin(), stationType: 3 })
        } else {
            this.props.createBookmark({ identifiant: qualitometer.code, login: getLogin(), stationType: 3 })
        }
    }

    render() {
        const { aquifers, sandreCodes, watersheds } = this.props
        const { readMode, qualitometer } = this.state
        const mode = { readMode, editMode: !readMode, freezeOpti: true }
        const params = {
            station: qualitometer,
            onChange: this.onChangeQualitometer,
            readMode,
        }
        const geoData = qualitometer.link_geo[0] || {}
        const work = qualitometer.link_work[0] || {}
        const qualitoType = parseInt(qualitometer?.stationType)
        return (
            <div className='row margin-left-2 padding-top-1'>
                <div className='col s9' style={{ marginBottom: '100px' }}>
                    <Card title={i18n.description} className={`${!readMode && 'padding-bottom-1' || ''}`} id='description_card'>
                        <Row className='padding-top-1'>
                            {
                                !isUndergroundWater(qualitometer) && (
                                    <Input
                                        col={6}
                                        value={qualitometer.code}
                                        title={i18n.code}
                                        disabled
                                        keyObj='designation'
                                        changeObj={this.onChangeQualitometer}
                                        {...mode}
                                    />
                                )
                            }
                            {
                                isUndergroundWater(qualitometer) && (
                                    <>
                                        <Input
                                            col={readMode ? 6 : 3}
                                            value={`${qualitometer.code}${(readMode && `/${qualitometer.designation || ''}`) || ''}`}
                                            title={i18n.code}
                                            disabled
                                            keyObj='designation'
                                            changeObj={this.onChangeQualitometer}
                                            {...mode}
                                        />
                                        <Row displayIf={!readMode} className='col s1 padding-top-2 center'>
                                            /
                                        </Row>
                                        <Row displayIf={!readMode} className='col s2'>
                                            <Input
                                                value={qualitometer.designation}
                                                title={i18n.designation}
                                                keyObj='designation'
                                                changeObj={this.onChangeQualitometer}
                                                onChange={v => this.onChangeQualitometer({ designation: v })}
                                                {...mode}
                                            />
                                        </Row>
                                    </>
                                )
                            }
                            <Input
                                col={5}
                                value={qualitometer.name}
                                title={i18n.name}
                                keyObj='designation'
                                changeObj={this.onChangeQualitometer}
                                onChange={v => this.onChangeQualitometer({ name: v })}
                                {...mode}
                            />
                            <div className='col s1'>
                                {getBookmarks(qualitometer.code, getUserBookmarksByStationType(this.props.userBookmarks, 3), true, this.setBookmark)}
                            </div>
                        </Row>
                        <Row>
                            <DatePicker
                                col={6}
                                value={qualitometer.creation}
                                id='creation'
                                title={i18n.creationDate}
                                endDate={qualitometer.close}
                                onChange={(_, v) => this.onChangeQualitometer({ creation: v })}
                                {...mode}
                            />
                            <DatePicker
                                col={6}
                                value={qualitometer.close}
                                id='close'
                                title={i18n.closeDate}
                                startDate={qualitometer.creation}
                                onChange={(_, v) => this.onChangeQualitometer({ close: v })}
                                {...mode}
                            />
                        </Row>
                        <Row className={!readMode && 'padding-top-1' || ''}>
                            <Select
                                col={6}
                                value={qualitoType}
                                label={i18n.stationTypeLabel}
                                options={STATION_QUALITOMETER_NAMES}
                                onChange={v => {
                                    const frType = v !== 1 && v !== 2 ? {
                                        frType: null,
                                        her1: null,
                                        her2: null,
                                        strahlerRank: null,
                                    } : {}
                                    this.onChangeQualitometer({ stationType: hasValue(v) && v.toString() || '', ...frType })
                                }}
                                {...mode}
                            />
                            <Select
                                col={6}
                                value={qualitometer.qualitometerType}
                                label={i18n.qualitometerType}
                                options={this.props.qualitometerTypes}
                                onChange={v => this.onChangeQualitometer({ qualitometerType: v })}
                                integerValue
                                {...mode}
                            />
                        </Row>
                        <Row>
                            <Select
                                col={6}
                                value={qualitometer.representativenessCode}
                                label={i18n.representiveness}
                                options={getSandreList(sandreCodes, SANDRE.REPRESENTATIVNESS_QUALITY)}
                                onChange={v => this.onChangeQualitometer({ representativenessCode: v })}
                                integerValue
                                {...mode}
                            />
                            <Select
                                col={6}
                                value={qualitometer.waterSourceType}
                                label={i18n.type}
                                options={this.props.waterSourceTypes}
                                onChange={v => this.onChangeQualitometer({ waterSourceType: v })}
                                {...mode}
                            />
                        </Row>
                        <Row>
                            <Select
                                col={6}
                                value={qualitometer.qualityCode}
                                label={i18n.qualityCode}
                                options={this.props.qualities}
                                onChange={v => this.onChangeQualitometer({ qualityCode: v })}
                                integerValue
                                {...mode}
                            />
                            <Select
                                col={6}
                                value={qualitometer.stateCode}
                                label={i18n.state}
                                options={this.props.stationStates}
                                onChange={v => this.onChangeQualitometer({ stateCode: v })}
                                integerValue
                                {...mode}
                            />
                        </Row>
                        <Row displayIf={isUndergroundWater(qualitometer)}>
                            <Select
                                col={6}
                                value={qualitometer.nature}
                                label={i18n.nature}
                                options={this.props.natures}
                                integerValue
                                onChange={v => this.onChangeQualitometer({ nature: v })}
                                {...mode}
                            />
                            <Select
                                col={6}
                                value={qualitometer.fieldMode}
                                label={i18n.fieldMode}
                                options={this.props.fieldModes}
                                integerValue
                                onChange={v => this.onChangeQualitometer({ fieldMode: v })}
                                {...mode}
                            />
                        </Row>
                        <Row>
                            <Input
                                col={12}
                                value={qualitometer.finality}
                                title={i18n.finality}
                                keyObj='designation'
                                changeObj={this.onChangeQualitometer}
                                onChange={v => this.onChangeQualitometer({ finality: v })}
                                {...mode}
                            />
                        </Row>
                        <Row>
                            <Textarea
                                col={12}
                                value={qualitometer.comment}
                                title={i18n.comment}
                                keyObj='comment'
                                changeObj={this.onChangeQualitometer}
                                {...mode}
                            />
                        </Row>
                    </Card>
                    <Row className='padding-top-1'>
                        <Card title={i18n.context} id='context_card'>
                            <Row className='padding-bottom-1'>
                                <Row className={`${!readMode && 'padding-top-1 ' || ''}`} style={{ paddingLeft: '5px' }}>
                                    <Row className='padding-1'>
                                        <StationWatermassPanel {...params} className='blue' />
                                    </Row>
                                    <Row displayIf={isUndergroundWater(qualitometer)}>
                                        <Select
                                            col={12}
                                            value={qualitometer.link_aquifers[0] && qualitometer.link_aquifers[0].aquiferCode}
                                            label={i18n.aquifer}
                                            options={aquifers}
                                            displayWithCode
                                            onChange={v => this.onChangeQualitometer({ link_aquifers: [{ idStation: qualitometer.id, aquiferCode: v }] })}
                                            readMode={readMode}
                                        />
                                    </Row>
                                    <Row>
                                        <Select
                                            col={6}
                                            value={qualitometer.watershed}
                                            label={i18n.watershed}
                                            options={watersheds}
                                            keyValue='id'
                                            onChange={v => this.onChangeQualitometer({ watershed: v })}
                                            readMode={readMode}
                                        />
                                        <Select
                                            col={6}
                                            value={qualitometer.watershed2}
                                            label={i18n.watershed2}
                                            options={watersheds}
                                            keyValue='id'
                                            onChange={v => this.onChangeQualitometer({ watershed2: v })}
                                            readMode={readMode}
                                        />
                                    </Row>
                                    <Row>
                                        <Select
                                            col={6}
                                            value={qualitometer.watershed3}
                                            label={i18n.watershed3}
                                            options={watersheds}
                                            keyValue='id'
                                            onChange={v => this.onChangeQualitometer({ watershed3: v })}
                                            readMode={readMode}
                                        />
                                        <Select
                                            col={6}
                                            value={qualitometer.watershed4}
                                            label={i18n.watershed4}
                                            options={watersheds}
                                            keyValue='id'
                                            onChange={v => this.onChangeQualitometer({ watershed4: v })}
                                            readMode={readMode}
                                        />
                                    </Row>
                                    {
                                        (qualitoType === 1 || qualitoType === 2) && (
                                            <>

                                                <Row>
                                                    <Select
                                                        col={6}
                                                        value={qualitometer.her1}
                                                        label={i18n.her}
                                                        options={getSandreList(sandreCodes, SANDRE.QUALITOMETRES_HER1)}
                                                        keyValue='id'
                                                        onChange={v => this.onChangeQualitometer({ her1: v })}
                                                        readMode={readMode}
                                                    />
                                                    <Select
                                                        col={6}
                                                        value={qualitometer.her2}
                                                        label={i18n.her2}
                                                        options={getSandreList(sandreCodes, SANDRE.QUALITOMETRES_HER2)}
                                                        keyValue='id'
                                                        onChange={v => this.onChangeQualitometer({ her2: v })}
                                                        readMode={readMode}
                                                    />
                                                </Row>
                                                <Row>
                                                    <Select
                                                        col={6}
                                                        value={qualitometer.strahlerRank}
                                                        label={i18n.strahlerRank}
                                                        options={getSandreList(sandreCodes, SANDRE.QUALITOMETRES_RANG_STRAHLER)}
                                                        keyValue='id'
                                                        onChange={v => this.onChangeQualitometer({ strahlerRank: v })}
                                                        readMode={readMode}
                                                    />
                                                    {
                                                        qualitoType === 1 && (
                                                            <Select
                                                                col={6}
                                                                value={qualitometer.frType}
                                                                label={i18n.frType}
                                                                options={getSandreList(sandreCodes, SANDRE.QUALITOMETRES_TYPE_FR_RIVIERE)}
                                                                keyValue='id'
                                                                onChange={v => this.onChangeQualitometer({ frType: v })}
                                                                readMode={readMode}
                                                            />
                                                        )
                                                    }
                                                    {
                                                        qualitoType === 2 && (
                                                            <Select
                                                                col={6}
                                                                value={qualitometer.frType}
                                                                label={i18n.frType}
                                                                options={getSandreList(sandreCodes, SANDRE.QUALITOMETRES_TYPE_FR_PLAN)}
                                                                keyValue='id'
                                                                onChange={v => this.onChangeQualitometer({ frType: v })}
                                                                readMode={readMode}
                                                            />
                                                        )
                                                    }
                                                    {
                                                        [1, 3].includes(qualitoType) && (
                                                            <Input
                                                                col={6}
                                                                value={qualitometer.river}
                                                                title={i18n.river}
                                                                onChange={v => this.onChangeQualitometer({ river: v })}
                                                                readMode={readMode}
                                                            />
                                                        )
                                                    }
                                                </Row>
                                            </>
                                        )
                                    }
                                    <Row displayIf={isUndergroundWater(qualitometer)}>
                                        <Select
                                            col={12}
                                            value={qualitometer.link_lithologicType[0] && qualitometer.link_lithologicType[0].lithologyType}
                                            label={i18n.lithologyType}
                                            options={getSandreList(sandreCodes, SANDRE.LITHOLOGY_TYPE)}
                                            nullLabel='&nbsp;'
                                            onChange={v => this.onChangeQualitometer({ link_lithologicType: [{ idStation: qualitometer.id, lithologyType: v }] })}
                                            readMode={readMode}
                                        />
                                    </Row>
                                </Row>
                                <Row className='padding-left-1 padding-right-1 margin-top-1 no-margin-bottom' noMargin={false} displayIf={isUndergroundWater(qualitometer)}>
                                    <StationLithologyPanel className='blue' {...params} />
                                </Row>
                                <Row className='padding-left-1 padding-right-1 margin-top-1 no-margin-bottom' noMargin={false} displayIf={isUndergroundWater(qualitometer)}>
                                    <StationHydrogeologicalEntityPanel className='blue' {...params} />
                                </Row>
                            </Row>
                        </Card>
                    </Row>
                    <Row className='padding-top-1'>
                        <StationPiezoContributorPanel {...params} />
                    </Row>
                    {/* <Row className='padding-top-1'>*/}
                    {/*    <StationAltimetrySystemsPanel { ...params }/>*/}
                    {/* </Row>*/}
                    {/* <Row className='padding-top-1'>*/}
                    {/*    <StationLandmarkPanel { ...params }/>*/}
                    {/* </Row>*/}
                    <Row className='padding-top-1'>
                        <StationSamplePointsPanel {...params} />
                    </Row>
                    <Row className='padding-top-1'>
                        <StationNetworkPanel {...params} />
                    </Row>
                    <Row className='padding-top-1'>
                        <StationMeasureMethodPanel {...params} />
                    </Row>
                    <Row className='padding-top-1'>
                        <Card title={i18n.technicalCharacteristics} id='technical_Characteristics' >
                            <Row className={`${!readMode && 'padding-top-1' || ''}`} style={{ paddingLeft: '5px' }}>
                                <Row>
                                    <Input
                                        col={6}
                                        title={i18n.stationType}
                                        value={work.stationType}
                                        keyObj='stationType'
                                        changeObj={this.onChangeWork}
                                        readMode={readMode}
                                    />
                                    <Input
                                        col={6}
                                        title={i18n.phone}
                                        value={work.phone}
                                        keyObj='phone'
                                        changeObj={this.onChangeWork}
                                        readMode={readMode}
                                    />
                                </Row>
                                <Row>
                                    <Input
                                        col={6}
                                        title={i18n.sensorType}
                                        value={work.sensorType}
                                        keyObj='sensorType'
                                        changeObj={this.onChangeWork}
                                        readMode={readMode}
                                    />
                                    <Input
                                        col={6}
                                        title={i18n.serialNumber}
                                        value={work.serialNumber}
                                        keyObj='serialNumber'
                                        changeObj={this.onChangeWork}
                                        readMode={readMode}
                                    />
                                </Row>
                                <Row>
                                    <NumberField
                                        col={6}
                                        title={i18n.workDepth}
                                        value={work.workDepth}
                                        onChange={v => this.onChangeWork({ workDepth: v })}
                                        readMode={readMode}
                                        floatValue
                                    />
                                    <NumberField
                                        col={6}
                                        title={i18n.sensorDepth}
                                        value={work.sensorDepth}
                                        onChange={v => this.onChangeWork({ sensorDepth: v })}
                                        readMode={readMode}
                                    />
                                </Row>
                                <Row>
                                    <Input
                                        col={6}
                                        title={i18n.internalDiameter}
                                        value={work.internalDiameter}
                                        keyObj='internalDiameter'
                                        changeObj={this.onChangeWork}
                                        readMode={readMode}
                                    />
                                    <Input
                                        col={6}
                                        title={i18n.externalDiameter}
                                        value={work.externalDiameter}
                                        keyObj='externalDiameter'
                                        changeObj={this.onChangeWork}
                                        readMode={readMode}
                                    />
                                </Row>
                                <Row>
                                    <Textarea
                                        col={12}
                                        title={i18n.comment}
                                        value={work.comment}
                                        keyObj='comment'
                                        changeObj={this.onChangeWork}
                                        readMode={readMode}
                                    />
                                </Row>
                                <Row className='padding-1' displayIf={isUndergroundWater(qualitometer)}>
                                    <StationCasingPanel className='blue' {...params} />
                                </Row>
                            </Row>
                        </Card>
                    </Row>
                    <Row className='padding-top-1'>
                        <StationFunctionPanel {...params} />
                    </Row>
                    <Row className='padding-top-1'>
                        <StationUsagePanel {...params} />
                    </Row>
                    <Row className='padding-top-1'>
                        <StationArrangementPanel {...params} />
                    </Row>
                    <Row className='padding-top-1'>
                        <StationAccessibilitiesPanel {...params} />
                    </Row>
                    <Row className='padding-top-1'>
                        <StationContactsPanel {...params} />
                    </Row>
                </div>
                <div className='col s3' style={{ marginBottom: '100px' }}>
                    <StationUpdatePanel station={qualitometer} />
                    <Row>
                        <ContactCard
                            id='contact_card'
                            title={i18n.contact}
                            contactCode={qualitometer.contactCode}
                            onChange={v => this.onChangeQualitometer({ contactCode: v })}
                            readMode={readMode}
                        />
                    </Row>
                    <StationMapDashboardPanel
                        noMarkerTooltip
                        station={qualitometer}
                        type={'quality'}
                    />
                    <Card title={i18n.location} className='margin-top-1' noMargin={false} id='location_card'>
                        <Row className={`${!readMode && 'padding-top-1' || ''}`}>
                            <Input
                                col={12}
                                title={i18n.address}
                                value={qualitometer.address}
                                keyObj='address'
                                changeObj={this.onChangeQualitometer}
                                {...mode}
                            />
                        </Row>
                        <Row>
                            <Select col={12} value={qualitometer.townCode} label={i18n.city} options={this.props.cities} keyValue='id' displayWithCode
                                onChange={v => this.onChangeQualitometer({ townCode: v })} {...mode}
                            />
                        </Row>
                        <Row displayIf={qualitometer.declarationTownCode !== qualitometer.townCode || !readMode}>
                            <Select col={12} value={qualitometer.declarationTownCode} label={i18n.declarationTownCode} options={this.props.cities} keyValue='id' displayWithCode
                                onChange={v => this.onChangeQualitometer({ declarationTownCode: v })} {...mode}
                            />
                        </Row>
                        <Row>
                            <Input
                                col={12}
                                value={qualitometer.countryCode}
                                title={i18n.countryCode}
                                keyObj='countryCode'
                                changeObj={this.onChangeQualitometer}
                                {...mode}
                            />
                        </Row>
                        <Row>
                            <Textarea
                                col={12}
                                noMargin={false}
                                classname={!readMode && 'margin-bottom-1' || ''}
                                title={i18n.localisation}
                                value={qualitometer.location}
                                keyObj='location'
                                changeObj={this.onChangeQualitometer}
                                {...mode}
                            />
                        </Row>
                        <Row>
                            <NumberField col={6} title='X' value={qualitometer.x} floatValue onChange={v => this.onChangeQualitometer({ x: v })} {...mode} />
                            <NumberField col={6} title='Y' value={qualitometer.y} floatValue onChange={v => this.onChangeQualitometer({ y: v })} {...mode} />
                        </Row>
                        <Row>
                            <Select col={12} label={i18n.projection} value={qualitometer.projection}
                                options={getSandreList(this.props.sandreCodes, SANDRE.PROJECTION)}
                                onChange={v => this.onChangeQualitometer({ projection: v })} {...mode} integerValue
                            />
                        </Row>
                        <Row>
                            <NumberField col={12} value={qualitometer.altitude} title={i18n.altitude} floatValue onChange={v => this.onChangeQualitometer({ altitude: v })} {...mode} />
                        </Row>
                        <Row>
                            <Input
                                col={6}
                                title={i18n.ignMap}
                                value={geoData.ignMap}
                                keyObj='ignMap'
                                changeObj={this.onChangeGeoData}
                                {...mode}
                            />
                            <Input
                                col={6}
                                title={i18n.geologicalMap}
                                value={geoData.geologicalMap}
                                keyObj='geologicalMap'
                                changeObj={this.onChangeGeoData}
                                {...mode}
                            />
                        </Row>
                        <Row>
                            <Input
                                col={6}
                                title={i18n.section}
                                value={geoData.parcel}
                                keyObj='parcel'
                                changeObj={this.onChangeGeoData}
                                {...mode}
                            />
                            <Input
                                col={6}
                                title={i18n.parcel}
                                value={geoData.section}
                                keyObj='section'
                                changeObj={this.onChangeGeoData}
                                {...mode}
                            />
                        </Row>
                    </Card>
                    <LinkedStationsPanel
                        className='margin-top-1'
                        noMargin={false}
                        onReMount={this.props.onReMount}
                        station={this.props.qualitometer}
                        {...mode}
                    />
                </div>
            </div>
        )
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextState.qualitometer.LAST_FORM !== this.state.qualitometer.LAST_FORM && nextState.qualitometer.LAST_FORM && nextState.qualitometer.LAST_FORM.match(/INPUT|TEXT/g)) {
            return false
        }
        return true
    }
}

QualitometerDescriptionPanel.propTypes = getPropTypes(storeProps, {
    onRemount: PropTypes.func,
    id: PropTypes.number,
    qualitometer: PropTypes.instanceOf(DtoQualitometer),
    aquifers: arrayOf(DtoAquifer),
    watermasses: arrayOf(WatermassDto),
    watersheds: arrayOf(WatershedDto),
    typeEnvironmentModels: PropTypes.arrayOf(PropTypes.string),
})

const mapStateToProps = store => ({
    ...getMapStateToProps(storeProps),
    selectedSearchValues: store.AdministrationReducer.selectedSearchValues,
    sandreCodes: store.ReferencialReducer.sandreCodes,
    watermasses: store.WatermassReducer.watermasses,
    aquifers: store.AquiferReducer.aquifers,
    networks: store.NetworkReducer.networks,
    watersheds: store.WatershedReducer.watersheds,
    userBookmarks: store.UserReducer.userBookmarks,
    typeEnvironmentModels: store.ExportReducer.typeEnvironmentModels,
})

const mapDispatchToProps = {
    push,
    add: SieauAction.add,
    deleteQualitometer: QualityAction.deleteQualitometer,
    update: SieauAction.update,
    toastrError: ToastrAction.error,
    fetch: SieauAction.fetch,
    fetchNetworks: NetworkAction.fetchNetworks,
    fetchBookmarks: UserAction.fetchBookmarks,
    fetchAquifers: AquiferAction.fetchAquifers,
    fetchWatermasses: WatermassAction.fetchWatermasses,
    fetchWatersheds: WatershedAction.fetchWatersheds,
    deleteBookmark: UserAction.deleteBookmark,
    createBookmark: UserAction.createBookmark,
    synchronizeDescription: QualityAction.synchronizeDescription,
}

export default connect(mapStateToProps, mapDispatchToProps)(QualitometerDescriptionPanel)
