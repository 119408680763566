import DtoMaterielAssignment from './DtoMaterielAssignment'

export default class DtoStationCentralAssignment extends DtoMaterielAssignment {
    constructor(obj) {
        super(obj)
        this.id = obj.idCentralSituation
        this.idCentralSituation = obj.idCentralSituation
        this.idCentral = obj.idCentral
        this.materielId = obj.idCentral
    }
}