export default class DtoBoreholeCasingItem {
    constructor(obj) {
        this.order = obj.order
        this.startDepth = obj.startDepth
        this.endDepth = obj.endDepth
        this.aquiferCode = obj.aquiferCode
        this.aquiferRoof = obj.aquiferRoof
        this.aquiferFloor = obj.aquiferFloor
        this.internalCasingDiameter = obj.internalCasingDiameter
        this.externalCasingDiameter = obj.externalCasingDiameter
        this.strainers = obj.strainers
        this.materialCode = obj.materialCode
        this.cementing = obj.cementing
        this.column = obj.column
        this.diameterSymbol = obj.diameterSymbol
        this.depth = obj.depth
        this.casingMaterial = obj.casingMaterial
        this.headers = ['column', 'diameterSymbol', 'depth', 'cementing']
    }
}