export default class DtoDistributionCounter {
    constructor(obj) {
        this.idCounter = obj.idCounter // Option[Long],
        this.year = obj.year // Option[Int],
        this.productorCode = obj.productorCode // Option[Long],
        this.reportCode = obj.reportCode // Option[Int],
        this.result = obj.result // Option[Double],
        this.reportComment = obj.reportComment // Option[String],
        this.jobExecutionId = obj.jobExecutionId // Option[Long],
        this.updateDate = obj.updateDate // Option[DateTime],
        this.updateLogin = obj.updateLogin // Option[String]
    }
}