import PropTypes from 'prop-types'
import React, { Component } from 'react'
import i18n from 'simple-react-i18n'
import DtoCity from '../../../../../home/dto/DtoCity'

class CityInformationPanel extends Component {
    constructor(props) {
        super(props)
    }

    putInformation = (title, value) => {
        const finalValue = value ? value : ''
        return (
            <div className='row no-margin'>
                <div className='col l3 right-align'>
                    <h6 className='bold'>{title}</h6>
                </div>
                <div className='col l9'>
                    <h6>{finalValue}</h6>
                </div>
            </div>
        )
    }

    getData = () => {
        const situationsOptions = [
            { value: 0, label: '' },
            { value: 1, label: i18n.rural },
            { value: 2, label: i18n.urban },
        ]

        const citySituation = situationsOptions.map((e) => {
            if (e.value == this.props.city.situation) {
                return e.label
            }
            return ''
        })

        return (
            <div>
                {this.putInformation(i18n.inseeCode, this.props.city.code)}
                {this.putInformation(i18n.name, this.props.city.name)}
                {this.putInformation(i18n.area, this.props.city.area)}
                {this.putInformation(i18n.situation, citySituation)}
                {this.putInformation(i18n.country, 'France')}
            </div>
        )
    }

    render() {
        if (this.props.city && this.props.city.code) {
            return (
                <div className='col s9'>
                    <div className='row no-margin'>
                        <div className='col s12 no-padding'>
                            {this.getData()}
                        </div>
                    </div>
                </div>
            )
        }
        return null
    }
}
CityInformationPanel.propTypes = {
    city: PropTypes.instanceOf(DtoCity).isRequired,
}
CityInformationPanel.defaultProps = {
    city: {},
}
export default CityInformationPanel
