import {
    RECEIVE_ALL_TELECOM_SITUATIONS,
    RECEIVE_ALL_TELECOMS,
    RECEIVE_TELECOM,
    RECEIVE_TELECOM_FILES,
    RECEIVE_TELECOM_PICTURES,
    RECEIVE_TELECOMS_LAST_SITUATIONS,
    RECEIVE_TELECOMS_TYPES,
    RESET_TELECOM,
} from '../constants/TelecomConstants'
import TelecomDto from '../dto/TelecomDto'
import TelecomTypeDto from '../dto/TelecomTypeDto'
import DtoTelecomSituation from '../dto/DtoTelecomSituation'
import DtoPicture from '../../../../station/dto/DtoPicture'
import DtoFile from '../../../../components/file/dto/DtoFile'
import { RESET_EQUIPMENT } from '../../equipment/constants/EquipmentConstants'
import { RESET_ALL_MATERIELS } from '../../../constants/MaterielConstants'

export const store = {
    telecoms: [],
    telecom: {},
    telecomTypes: [],
    telecomSituations: [],
    telecomsLastSituations: [],
    telecomPictures: [],
    telecomFiles: [],
}

export function TelecomReducer(state = {}, action) {
    switch (action.type) {
        case RECEIVE_ALL_TELECOMS:
            return {
                ...state,
                telecoms: action.telecoms.map(telecom => new TelecomDto(telecom)),
            }
        case RECEIVE_TELECOM:
            return {
                ...state,
                telecom: new TelecomDto(action.telecom),
            }
        case RECEIVE_TELECOMS_TYPES:
            return {
                ...state,
                telecomTypes: action.telecomTypes.map(t => new TelecomTypeDto(t)),
            }
        case RECEIVE_ALL_TELECOM_SITUATIONS:
            return {
                ...state,
                telecomSituations: action.telecomSituations.map(t => new DtoTelecomSituation(t)),
            }
        case RECEIVE_TELECOMS_LAST_SITUATIONS:
            return {
                ...state,
                telecomsLastSituations: action.telecomsLastSituations.map(s => new DtoTelecomSituation(s)),
            }
        case RECEIVE_TELECOM_PICTURES:
            return {
                ...state,
                telecomPictures: action.telecomPictures.map(el => new DtoPicture(el)),
            }
        case RECEIVE_TELECOM_FILES:
            return {
                ...state,
                telecomFiles: action.telecomFiles.map(el => new DtoFile(el)),
            }
        case RESET_ALL_MATERIELS:
            return {
                ...state,
                ...store,
            }
        case RESET_TELECOM:
            return {
                ...state,
                telecom: {},
                telecomSituations: [],
                telecomPictures: [],
                telecomFiles: [],
            }
        case RESET_EQUIPMENT:
            return {
                ...state,
                telecomTypes: [],
            }
        default:
            return state
    }
}
