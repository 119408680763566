import PropTypes from 'prop-types'
import React from 'react'
import i18n from 'simple-react-i18n'
import { secondaryBlue } from 'utils/constants/ColorTheme'
import { getPercentage } from '../../../utils/NumberUtil'
import DtoSurvey from '../../dto/DtoSurvey'

const ProgressSurveyTab = ({
    survey,
}) => {
    const { link_declarations: declarations = [] } = survey

    return declarations.length && (
        <div className='row no-margin'>
            <div className='row no-margin' style={{ borderBottom: `solid 1px ${secondaryBlue}`, fontWeight: 'bold' }}>
                <div className='col s6'>
                    {i18n.status}
                </div>
                <div className='col s6'>
                    {i18n.progression} (%)
                </div>
            </div>
            <div className='row no-margin'>
                <div className='col s6'>
                    {i18n.finalize}
                </div>
                <div className='col s6'>
                    {getPercentage(declarations.filter(dec => dec.statusCode === 3).length, declarations.length)}
                </div>
            </div>
            <div className='row no-margin'>
                <div className='col s6'>
                    {i18n.inProgress}
                </div>
                <div className='col s6'>
                    {getPercentage(declarations.filter(dec => dec.statusCode !== 3 && dec.statusCode !== 0).length, declarations.length)}
                </div>
            </div>
            <div className='row no-margin'>
                <div className='col s6'>
                    {i18n.drafts}
                </div>
                <div className='col s6'>
                    {getPercentage(declarations.filter(dec => dec.statusCode === 0).length, declarations.length)}
                </div>
            </div>
        </div>
    ) || null
}

ProgressSurveyTab.propTypes = {
    survey: PropTypes.instanceOf(DtoSurvey),
}

export default ProgressSurveyTab
