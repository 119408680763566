import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import i18n from 'simple-react-i18n'
import ToastrAction from 'toastr/actions/ToastrAction'
import { connect } from 'react-redux'
import FileUploadInput from '../../../import/dto/FileUploadInput'
import ParameterDto from '../../../referencial/components/parameter/dto/ParameterDto'
import ContributorDto from '../../../referencial/components/contributor/dto/ContributorDto'
import CityDto from '../../../referencial/components/city/dto/CityDto'
import UnitDto from '../../../referencial/components/unit/dto/UnitDto'

import IntegrationAction from '../../../import/actions/IntegrationAction'
import Select from '../../../components/forms/Select'
// import Checkbox from '../../../components/forms/Checkbox'

class ImportXmlQualityPopup extends Component {
    state = {
        fileContent: '',
        file: '',
        validation: false,
        setState: false,
        importType: 1,
        importTypes: [
            {
                label: i18n.ddassdistri,
                id: 2,
            },
            {
                label: i18n.EDILABO,
                id: 1,
            },
            {
                label: i18n.importQuesu,
                id: 3,
            },
            {
                label: i18n.importQesoutQuality,
                id: 4,
            },
        ],
    }

    openFile = e => {
        const reader = new FileReader()
        const file = e.target.files[0]
        reader.onload = upload => {
            const split = upload.target.result.split(',')
            if (split[0].includes('text/xml')) {
                this.setState({ fileContent: upload.target.result, file: file.name })
            } else {
                this.props.toastError(i18n.theSelectedFileMustBeInCsvFormat)
            }
        }
        reader.readAsDataURL(file)
    }

    onClose = () => {
        this.setState({
            fileContent: '',
            file: '',
            importType: 1,
        })
        this.props.onClose()
    }

    getFormat = () => {
        switch (this.state.importType) {
            case 1: return 'edilabo.xsd'
            case 2: return 'DDASS_DISTR.xsd'
            case 3: return 'quesu.xsd'
            case 4: return 'quesout.xsd'
            default: return ''
        }
    }


    onImport = () => {
        if (this.state.file) {
            const param = {
                campaignId: this.props.campaignId,
                stations: this.props.stations,
                filter: this.state.file,
                directory: 'DATA/',
            }
            const parameters = new FileUploadInput({
                stationType: 0,
                stationId: 0,
                path: 'DATA/',
                name: this.state.file,
                content: this.state.fileContent,
            })

            const paramValidation = {
                directory: 'DATA/',
                name: this.state.file,
                format: this.getFormat(),

            }
            const { validation } = this.state
            switch (this.state.importType) {
                case 1:
                    this.props.integration(-7, param, parameters, validation, paramValidation)
                    break
                case 2:
                    this.props.integration(-8, param, parameters, validation, paramValidation)
                    break
                case 3:
                    this.props.integration(-9, param, parameters, validation, paramValidation)
                    break
                case 4:
                    this.props.integration(-10, param, parameters, validation, paramValidation)
                    break
                default:
            }
            this.onClose()
        } else {
            this.props.toastInfo(i18n.pleaseSelectFile)
        }
    }

    render = () => {
        const {
            isOpen,
        } = this.props
        const {
            file,
            // validation,
            importTypes,
            importType,
        } = this.state
        return (
            <Dialog
                onClose={this.onClose}
                fullWidth
                maxWidth='md'
                open={isOpen}
            >
                <DialogTitle>{i18n.manualImport}</DialogTitle>
                <DialogContent>
                    <div style={{ minHeight: '30vh' }}>
                        <div className='row no-margin valign-wrapper'>
                            <div className='col s4 no-padding btn file-field'>
                                <span>{i18n.importFile}</span>
                                <input
                                    type='file'
                                    accept='.xml, .XML'
                                    onChange={this.openFile}
                                />
                            </div>
                            <div className='col s8'>
                                <label style={{ fontSize: '1em' }}>{file}</label>
                            </div>
                        </div>
                        <div className='row no-margin padding-top-1'>
                            <Select
                                col={12}
                                options={importTypes}
                                label={i18n.importName}
                                onChange={v => this.setState({ importType: v })}
                                value={importType}
                            />
                        </div>
                        {/* <div>
                            <Checkbox
                                checked={validation}
                                label={i18n.formatValidationxsd}
                                onChange={v => this.setState({ validation: v })}
                            />
                        </div> */}
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.onClose} variant='outlined' >
                        {i18n.close}
                    </Button>
                    <Button onClick={this.onImport} variant='contained' color='primary'>
                        {i18n.importLabel}
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}

ImportXmlQualityPopup.propTypes = {
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
    callbackImport: PropTypes.func,

    campaignId: PropTypes.number,
    stations: PropTypes.arrayOf(PropTypes.string),
    parameters: PropTypes.arrayOf(PropTypes.instanceOf(ParameterDto)),
    cities: PropTypes.arrayOf(PropTypes.instanceOf(CityDto)),
    units: PropTypes.arrayOf(PropTypes.instanceOf(UnitDto)),
    contributors: PropTypes.arrayOf(PropTypes.instanceOf(ContributorDto)),


    integration: PropTypes.func,
    toastError: PropTypes.func,
    toastInfo: PropTypes.func,
}

ImportXmlQualityPopup.defaultProps = {
    isOpen: false,
    onClose: () => { },
    callbackImport: () => { },
    campaignId: undefined,
    stations: [],
}

const mapStateToProps = store => ({
    parameters: store.ParameterReducer.parameters,
    cities: store.CityReducer.cities,
    units: store.UnitReducer.units,
    contributors: store.ContributorReducer.contributors,
})

const mapDispatchToProps = {
    integration: IntegrationAction.integration,
    toastError: ToastrAction.error,
    toastInfo: ToastrAction.info,
}

export default connect(mapStateToProps, mapDispatchToProps)(ImportXmlQualityPopup)
