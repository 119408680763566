import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import DtoSandreCode from '../../../../../referencial/dto/DtoSandreCode'
import Select from '../../../../../components/forms/Select'
import { getSandreList } from '../../../../../utils/StoreUtils'
import { SANDRE } from '../../../../../referencial/constants/ReferencialConstants'
import DtoTankSecurity from '../../dto/DtoTankSecurity'
import Row from '../../../../../components/react/Row'
import Textarea from '../../../../../components/forms/Textarea'
import DatePicker from '../../../../../components/forms/DatePicker'

class TankOperatorEquipmentModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            security: { ...props.security, idStation: props.id },
        }
    }

    componentDidUpdate(prevProps) {
        if (!prevProps.popupIsOpen && this.props.popupIsOpen) {
            $('#descriptionModal').on('click', this.onSave)
        }
    }
    onSave = () => {
        this.props.saveResult(new DtoTankSecurity(this.state.security))
        $('.modal').modal('close')
    }

    onChangeElement = (changes) => this.setState({ security: { ...this.state.security, ...changes } })

    render() {
        const { security } = this.state
        return (
            <div>
                <Row>
                    <Select
                        col={ 6 }
                        options={ getSandreList(this.props.sandreCodes, SANDRE.RADIO_SECURITE) }
                        label={ i18n.equipment }
                        value={ security.equipment }
                        onChange={ v => this.onChangeElement({ equipment: v }) }
                    />
                    <DatePicker
                        col={ 6 }
                        value={ security.lastControleDate }
                        id='startDate' title={ i18n.lastControleDate }
                        onChange={ (_, v) => this.onChangeElement({ lastControleDate: v }) }
                    />
                </Row>
                <Row className='padding-bottom-1'>
                    <Select
                        col={6}
                        options={getSandreList(this.props.sandreCodes, SANDRE.RESERVOIRS_ZONES_ETATS)}
                        label={i18n.state}
                        value={security.equipmentStateCode}
                        onChange={v => this.onChangeElement({ equipmentStateCode: v })}
                    />
                </Row>
                <Row>
                    <Textarea col={ 12 } title={ i18n.comment } value={ security.comment } onChange={ v => this.onChangeElement({ comment: v }) }/>
                </Row>
            </div>
        )
    }
}

TankOperatorEquipmentModal.propTypes = {
    security: PropTypes.instanceOf(DtoTankSecurity),
    id: PropTypes.number,
    sandreCodes: PropTypes.arrayOf(PropTypes.instanceOf(DtoSandreCode)),
    popupIsOpen: PropTypes.bool,
    saveResult: PropTypes.func,
}


const mapStateToProps = store => ({
    sandreCodes: store.ReferencialReducer.sandreCodes,
    popupIsOpen: store.SieauReducer.popupIsOpen,
})

TankOperatorEquipmentModal.defaultProps = {
    contributor: {},
}

export default connect(mapStateToProps)(TankOperatorEquipmentModal)
