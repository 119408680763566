import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import React, { useEffect } from 'react'
import { Grid } from '@mui/material'
import i18n from 'simple-react-i18n'
import PresentationCard from '../../../../../components/card/PresentationCard'
import PropTypes from 'prop-types'
import { getSettingInt } from '../../../../../utils/SettingUtils'
import { hasValue } from '../../../../../utils/NumberUtil'
import { PIEZO_IMPORT_TYPE } from '../AddPiezoConstants'

const getDefaultParams = (stationType, applicationSettings) => {
    switch (stationType) {
        case PIEZO_IMPORT_TYPE.ADES_PIEZO:
            return {
                filters: {
                    descriptionImportMode: 'all',
                    selectStationsMode: 'codesMode',
                    syncEvents: true,
                    syncNetworks: true,
                    dontCheckInternalReferences: false,
                    addCodesToADESNetwork: hasValue(getSettingInt(applicationSettings, 'adesPiezoSynchroNetwork')),
                    selectedNetwork: getSettingInt(applicationSettings, 'adesPiezoSynchroNetwork'),
                },
                dataTypes: ['stationIntegration', 'DATA_ADD_NEW_DATA'],
            }
        default:
            return { filters: {}, dataTypes: [] }
    }
}

const updateParams = (stationType, applicationSettings, setFilters, setJobDataTypes) => {
    const params = getDefaultParams(stationType, applicationSettings)
    setFilters(params.filters)
    setJobDataTypes(params.dataTypes)
}


const AddPiezoStepStation = ({
    station,
    changeStation,
    nextStep,
    setFilters,
    setJobDataTypes,
}) => {
    const dispatch = useDispatch()

    // const [isOpen, setIsOpen] = useState(false)

    const {
        applicationSettings,
    } = useSelector(store => ({
        applicationSettings: store.AdministrationReducer.applicationSettings,
    }), shallowEqual)

    const centerStyle = {
        'align-items': 'center',
        display: 'flex',
        justifyContent: 'center',
    }

    return (
        <div className='padding-top-2'>
            <Grid container justifyContent='center' alignItems='center' spacing={3}>
                <h4>{i18n.newStationStep1}</h4>
            </Grid>
            <Grid container justifyContent='center' alignItems='center' spacing={3}>
                <Grid item xs={6} >
                    <PresentationCard
                        iconComponent={<img className='responsive-img' src={'pictures/manualImports/ades.png'} height={70} width={70} style={{
                            textAlign: 'center',
                            display: 'table-cell',
                            verticalAlign: 'middle',
                        }}
                        />}
                        iconContainerStyle={centerStyle}
                        title='ADES'
                        onClick={() => {
                            changeStation({ stationType: PIEZO_IMPORT_TYPE.ADES_PIEZO })
                            updateParams(PIEZO_IMPORT_TYPE.ADES_PIEZO, applicationSettings, setFilters, setJobDataTypes)
                            nextStep()
                        }}
                        isFocused={station.stationType === PIEZO_IMPORT_TYPE.ADES_PIEZO}
                        description={i18n.adesDescription}
                    />
                </Grid>
                <Grid item xs={6}>
                    <PresentationCard
                        iconComponent={<img className='responsive-img' src={'pictures/markers/map_piezo.png'} height={50} width={50} />}
                        iconContainerStyle={centerStyle}
                        title='Point vide'
                        onClick={() => {
                            changeStation({ stationType: PIEZO_IMPORT_TYPE.EMPTY })
                            updateParams(PIEZO_IMPORT_TYPE.EMPTY, applicationSettings, setFilters, setJobDataTypes)
                            nextStep()
                        }}
                        description={i18n.newPiezoDescription}
                        isFocused={station.stationType === PIEZO_IMPORT_TYPE.EMPTY}
                    />
                </Grid>
            </Grid>
        </div>
    )
}

AddPiezoStepStation.propTypes = {
    nextStep: PropTypes.func,
    changeStation: PropTypes.func,
}

export default AddPiezoStepStation