import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import Select from '../../components/forms/Select'
import ToastrAction from 'toastr/actions/ToastrAction'
import ReferencialDto from '../dto/ReferencialDto'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import ReferencialAction from '../action/ReferencialAction'
import { DialogActionsMUI, DialogContentMUI, DialogMUI, DialogTitleMUI } from 'components/styled/Dialog'
import { Button, Grid } from '@mui/material'
import Icon from 'components/icon/Icon'
import ProgressCard from 'components/card/ProgressCard'
import { hasValue } from 'utils/NumberUtil'

const MUIReplaceDialog = ({
    title = '',
    label = '',
    elements = [],

    open = false,
    onClose = () => {},
    onValidate = () => {},

    keyLabel,
    keyValue,
}) => {
    const {
        checkDelete,
    } = useSelector(store => ({
        checkDelete: store.ReferencialReducer.checkDelete,
    }), shallowEqual)

    const [selected, setSelected] = useState()

    const dispatch = useDispatch()

    useEffect(() => () => {
        dispatch(ReferencialAction.resetCheckDelete())
    }, [])

    const onReplace = () => {
        if (selected) {
            onValidate(selected)
            onClose()
        } else {
            dispatch(ToastrAction.error(i18n.chooseExistingElement))
        }
    }

    return (
        <DialogMUI
            maxWidth='md'
            open={open}
            PaperProps={{
                sx: {
                    minHeight: '35vh',
                    maxHeight: '35vh',
                },
            }}
        >
            <DialogTitleMUI style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                {i18n.replacement}
                <Icon style={{ color: 'white' }} size='small' icon='close' onClick={onClose} />
            </DialogTitleMUI>
            <DialogContentMUI style={{ paddingBottom: 0, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Grid container rowSpacing={5}>
                    <Grid item xs={12} sx={{ '& h4': { margin: 0 } }}>
                        <h4>{`${i18n.replacement} ${title} ${i18n.by} :`}</h4>
                    </Grid>
                    <Grid container item xs={12} rowSpacing={2}>
                        {!hasValue(checkDelete) ? (
                            <Grid item xs={12}>
                                <ProgressCard indeterminate withMessage />
                            </Grid>
                        ) : (
                            <>
                                <Grid item xs={12} sx={{ '& h5': { margin: 0 } }}>
                                    <h5>{`${i18n.numberOfUse}${checkDelete}`}</h5>
                                </Grid>
                                <Grid item xs={12}>
                                    <Select
                                        options={elements}
                                        label={`${label} :`}
                                        onChange={(_, newSelected) => setSelected(newSelected)}
                                        clearFunction
                                        displayWithCode
                                        keyLabel={keyLabel}
                                        keyValue={keyValue}
                                    />
                                </Grid>
                            </>
                        )}
                    </Grid>
                </Grid>
            </DialogContentMUI>
            <DialogActionsMUI>
                <Button onClick={onReplace} variant='contained' color='primary'>
                    {i18n.replace}
                </Button>
            </DialogActionsMUI>
        </DialogMUI>
    )
}

MUIReplaceDialog.propTypes = {
    title: PropTypes.string,
    label: PropTypes.string,
    elements: PropTypes.instanceOf(ReferencialDto),
    onValidate: PropTypes.func,
    keyLabel: PropTypes.string,
    keyValue: PropTypes.string,
    open: PropTypes.bool,
    onClose: PropTypes.func,
}

export default MUIReplaceDialog
