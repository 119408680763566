import React, { useEffect, useState } from 'react'
import i18n from 'simple-react-i18n'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import DistributionUnitAction from 'distributionUnit/actions/DistributionUnitAction'
import DistributionUnitCounterTable from './DistributionUnitCounterTable'
import { Grid } from '@mui/material'
import useTitle from 'utils/customHook/useTitle'
import ProgressCard from 'components/card/ProgressCard'

const DistributionUnitsCounterManager = ({
}) => {
    const dispatch = useDispatch()
    const {
        distributionCounterTypes,
        distributionCounters,
    } = useSelector(store => ({
        distributionCounterTypes: store.DistributionUnitReducer.distributionCounterTypes,
        distributionCounters: store.DistributionUnitReducer.distributionCounters,
    }), shallowEqual)

    const [dataLoaded, setDataLoaded] = useState(false)

    useEffect(() => {
        if (!distributionCounterTypes.length) {
            dispatch(DistributionUnitAction.fetchDistributionCounterTypes())
        }
        dispatch(DistributionUnitAction.fetchCounters({ ids: null })).then(() => setDataLoaded(true))
    }, [])

    useTitle(() => {
        return [{
            title: i18n.distributionUnit,
            href: 'distributionUnit',
        }, {
            title: i18n.counters,
            href: 'station/distributionUnit/counters',
        }]
    }, [distributionCounters])

    return (
        <Grid style={{ padding: '10 10 100 20' }}>
            {dataLoaded && (
                <DistributionUnitCounterTable data={distributionCounters} />
            ) || <ProgressCard indeterminate />
            }
        </Grid>
    )
}

DistributionUnitsCounterManager.propTypes = {
}

export default DistributionUnitsCounterManager