export default class DtoAccessSite {
    constructor(obj) {
        this.idStation = obj.idStation // Long,
        this.fence = obj.fence // Option[Boolean],
        this.fenceType = obj.fenceType // Option[Long],
        this.fenceHeight = obj.fenceHeight // Option[Float],
        this.fenceState = obj.fenceState // Option[Long],
        this.portal = obj.portal // Option[Boolean],
        this.portalType = obj.portalType // Option[Long],
        this.portalState = obj.portalState // Option[Long],
        this.keyBox = obj.keyBox // Option[Boolean],
        this.typekeys = obj.typekeys // Option[Long],
        this.keyBoxState = obj.keyBoxState // Option[Long],
        this.panelAccessRestriction = obj.panelAccessRestriction // Option[Boolean],
        this.panelState = obj.panelState // Option[Long],
        this.accessType = obj.accessType // Option[Long],
        this.closingBrand = obj.closingBrand // Option[String],
        this.closingModel = obj.closingModel // Option[String],
        this.descriptionKeyPossessors = obj.descriptionKeyPossessors // Option[String]
    }
}