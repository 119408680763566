import React from 'react'
import PropTypes from 'prop-types'
import Fancybox from 'components/fancybox/Fancybox'

const SmallPictureLine = ({
    pictures = [],
    editable = false,
    deletePicture,
}) => {
    const pictureDelete = (picture) => {
        const fileToDelete = {
            name: picture.url.split('/PHOTOS/')[1],
            fileType: 'picture',
        }
        deletePicture(fileToDelete)
    }
    const createPicture = picture => (
        <div className='col'>
            { editable ? (
                <span onClick={ () => pictureDelete(picture) } style={ {
                    marginRight: '10px',
                    color: 'red',
                    fontWeight: 'bold',
                    cursor: 'pointer',
                } }
                >X</span>
            ) : '' }
            <Fancybox>
                <a href={ picture.url } data-fancybox='images'
                    data-caption={ picture.name }
                >
                    <img src={ picture.url } className='small-picture' />
                </a>
            </Fancybox>
        </div>
    )

    return (
        <div className='row no-margin'>
            { pictures.map(picture => createPicture(picture)) }
        </div>
    )
}

SmallPictureLine.propTypes = {
    deletePicture: PropTypes.func,
    editable: PropTypes.bool,
    pictures: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        url: PropTypes.string,
    })),
}


export default SmallPictureLine