import { createSlice } from '@reduxjs/toolkit'
import CatchmentThunk from 'catchment/actions/CatchmentThunk'
import CatchmentDto from 'catchment/dto/CatchmentDto'
import CatchmentPointDto from 'catchment/dto/CatchmentPointDto'
import CatchmentWithLinkDto from 'catchment/dto/CatchmentWithLinkDto'
import { DOCUMENT, PICTURE } from 'components/file/constant/FileConstants'
import DtoFile from 'components/file/dto/DtoFile'
import DtoAnalysisUltraLight from 'quality/dto/analyse/DtoAnalysisUltraLight'

// https://redux.js.org/tutorials/fundamentals/part-8-modern-redux#using-createslice

export const initialState = {
    catchments: [],
    catchmentPoints: [],
    catchmentsAnalysis: [],

    catchment: {},
    files: [],
    catchmentAnalysis: [],
}

const store = createSlice({
    name: 'catchment',
    initialState,
    reducers: {
        catchment: (state, action) => {
            state.catchment = new CatchmentWithLinkDto(action.payload)
        },
        files: (state, action) => {
            state.files = [
                ...action.payload.files.map(f => new DtoFile(f, DOCUMENT)),
                ...action.payload.pictures.map(f => new DtoFile(f, PICTURE)),
            ]
        },
        reset: (state) => {
            state.catchment = {}
            state.catchmentAnalysis = []
        },
    },
    extraReducers: {
        [CatchmentThunk.fetchCatchments.fulfilled]: (state, action) => {
            state.catchments = action.payload.map(catchment => new CatchmentDto(catchment))
        },
        [CatchmentThunk.fetchCatchmentPoints.fulfilled]: (state, action) => {
            state.catchmentPoints = action.payload.map(point => new CatchmentPointDto(point))
        },
        [CatchmentThunk.fetchCatchmentsAnalysis.fulfilled]: (state, action) => {
            state.catchmentsAnalysis = action.payload.map(a => new DtoAnalysisUltraLight(a))
        },
    },
})

export const CatchmentActionConstant = store.actions
export default store.reducer