import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { isEqual } from 'lodash'
import { connect } from 'react-redux'
import { push } from '@lagunovsky/redux-react-router'
import i18n from 'simple-react-i18n'
import WaitAction from 'wait/WaitAction'
import ApiCartoAction from '../../../../carto/action/ApiCartoAction'
import Input from '../../../../components/forms/Input'
import DtoParcelAndSection from '../../../../carto/DtoParcelAndSection'
import CartographyPanel from '../../../../components/map/CartographyPanel'
import Row from '../../../../components/react/Row'
import NumberField from '../../../../components/forms/NumberField'
import InstallationAction from '../../../actions/InstallationAction'
import StationAction from '../../../../station/actions/StationAction'
import AquiferAction from '../../../../referencial/components/aquifers/actions/AquiferAction'
import WatershedAction from '../../../../referencial/components/watershed/actions/WatershedAction'
import ContactAction from '../../../../referencial/components/contact/actions/ContactAction'
import QualityAction from '../../../../quality/actions/QualityAction'
import SieauAction from '../../../../components/sieau/SieauAction'
import DtoInstallation from '../../../dto/installation/DtoInstallation'
import { arrayOf } from '../../../../utils/StoreUtils'
import DtoSandreCode from '../../../../referencial/dto/DtoSandreCode'
import Card from '../../../../components/card/Card'
import CityDto from '../../../../referencial/components/city/dto/CityDto'
import { convertCoordinates } from '../../../../utils/mapUtils/CoordinateUtils'

class LocalisationMapPanel extends Component {
    constructor(props) {
        super(props)
        const town = props.cities.find((c) => c.code === props.installation.townCode) || {}
        this.state = {
            readMode: true,
            projection: props.installation.projection,
            tmpCoords: {
                x: props.installation.x,
                y: props.installation.y,
            },
            wgsCoords: {},
            tmpTown: {
                townCode: props.installation.townCode,
                town,
            },
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const { tmpCoords, wgsCoords, tmpTown, projection } = this.state
        if (!isEqual(prevState.tmpCoords, tmpCoords)) {
            const newGeoData = {
                x: tmpCoords.x,
                y: tmpCoords.y,
                projection: projection || 16, // default proj is WGS84
            }
            if (prevProps.dontFetchParcel) {
                this.props.onChangeLocation({ ...newGeoData })
            } else {
                this.props.waitStart()
                this.props.fetchParcelAndSectionByCoordinate(wgsCoords.x, wgsCoords.y).then(() => {
                    const { parcelAndSection } = this.props
                    if (parcelAndSection && parcelAndSection.numero && parcelAndSection.section) {
                        const sectionAndParcel = { // Invert fields because our database is invert
                            parcel: parcelAndSection.section.replace(/^0+/, ''), // Remove 0 at beginning of string
                            section: parcelAndSection.numero.replace(/^0+/, ''),
                        }
                        this.props.onChangeLocation({ ...newGeoData, ...sectionAndParcel })
                    } else {
                        this.props.onChangeLocation({ ...newGeoData })
                    }
                    this.props.waitStop()
                })
            }
        }
        if (!isEqual(prevState.tmpTown, tmpTown)) {
            this.props.onChangeCity(tmpTown.townCode)
        }
    }

    getGeoLink = (installation) => {
        if (installation && installation.link_geo && installation.link_geo.length) {
            return installation.link_geo[0]
        }
        return null
    }

    onClickMap = (coords) => {
        const { projection } = this.state
        const convertedCoordinates = projection && projection !== 16 ? convertCoordinates(16, projection, coords) : coords
        return this.setState({ tmpCoords: { x: convertedCoordinates[0], y: convertedCoordinates[1] }, wgsCoords: { x: coords[0], y: coords[1] } })
    }

    render() {
        const { installation, readMode, disabled, mapHeight, vertical, noPanels } = this.props
        const { tmpCoords, tmpTown } = this.state
        const geoLink = this.getGeoLink(installation)
        if (readMode) {
            return null
        }
        return (
            <Row>
                <div className={vertical ? 'clickable col s6' : 'clickable'} style={{ height: mapHeight || window.innerHeight * 0.55 }}>
                    <CartographyPanel
                        height={{ height: mapHeight || window.innerHeight * 0.55 }}
                        layers={['STATIONS_POINTS']}
                        componentType='showAll'
                        fullScreenable
                        noStationPanel={true}
                        stationsPoints={[installation, { ...installation }]}
                        onClickMap={coords => !disabled && this.onClickMap(coords)}
                        getTownObjects={ t => !disabled ? t ?
                            this.setState({ tmpTown: { townCode: t.code, town: t } }) :
                            this.setState({ tmpTown: { townCode: null, town: null } }) : () => {}}
                        noMarkerTooltip
                        defaultZoom={9}
                        forceZoom={true}
                        noPanels={noPanels}
                    />
                </div>
                <div className={vertical ? 'col s6' : ''}>
                    <Card title={ i18n.localisation } noMargin={ false } className='margin-top-1 no-margin-bottom'>
                        <Row>
                            <Row>
                                <Input
                                    col={ 6 }
                                    title={ i18n.city }
                                    value={ tmpTown.town ? `${tmpTown.town.code ? `[${tmpTown.town.code}]` : ''} ${tmpTown.town.name || ''} ` : '' }
                                    keyObj='town'
                                    readMode={ true }
                                />
                            </Row>
                            <Row>
                                <NumberField
                                    col={ 6 }
                                    title='X'
                                    value={ tmpCoords.x }
                                    floatValue
                                    readMode={ true }
                                />
                                <NumberField
                                    col={ 6 } title='Y'
                                    value={ tmpCoords.y }
                                    floatValue
                                    readMode={ true }
                                />
                            </Row>
                            <Row>
                                <Input
                                    col={ 6 }
                                    title={ i18n.section }
                                    value={ geoLink ? geoLink.parcel : '' }
                                    keyObj='parcel'
                                    readMode={ true }
                                />
                                <Input
                                    col={ 6 }
                                    title={ i18n.parcel }
                                    value={ geoLink ? geoLink.section : '' }
                                    keyObj='section'
                                    readMode={ true }
                                />
                            </Row>
                        </Row>
                    </Card>
                </div>
            </Row>
        )
    }
}

LocalisationMapPanel.propTypes = {
    onRemount: PropTypes.func,
    onChangeVisit: PropTypes.func, // used for changes on visit mode only
    parcelAndSection: PropTypes.instanceOf(DtoParcelAndSection),
    waitStart: PropTypes.func,
    waitStop: PropTypes.func,
    onChangeInstallation: PropTypes.func,
    onClose: PropTypes.func,
    onChangeLocation: PropTypes.func.isRequired,
    onChangeCity: PropTypes.func.isRequired,
    installation: PropTypes.instanceOf(DtoInstallation),
    fetchParcelAndSectionByCoordinate: PropTypes.func,
    disabled: PropTypes.bool,
    readMode: PropTypes.bool,
    sandreCodes: arrayOf(DtoSandreCode),
    cities: arrayOf(CityDto),
    vertical: PropTypes.bool,
    mapHeight: PropTypes.string,
    dontFetchParcel: PropTypes.bool,
    noPanels: PropTypes.bool,
}

const mapStateToProps = (store) => ({
    applicationSettings: store.AdministrationReducer.applicationSettings,
    contacts: store.ContactReducer.contacts,
    installationEvents: store.InstallationReducer.installationEvents,
    users: store.UserReducer.users,
    status: store.QualityReducer.status,
    installationBorehole: store.InstallationReducer.installationBorehole,
    watersheds: store.WatershedReducer.watersheds,
    natures: store.StationReducer.natures,
    aquifers: store.AquiferReducer.aquifers,
    fieldModes: store.StationReducer.fieldModes,
    installationVisits: store.InstallationReducer.installationVisits,
    parcelAndSection: store.ApiCartoReducer.parcelAndSection,
    sandreCodes: store.ReferencialReducer.sandreCodes,
    cities: store.CityReducer.cities,
})

const mapDispatchToProps = {
    waitStart: WaitAction.waitStart,
    waitStop: WaitAction.waitStop,
    push,
    delete: SieauAction.delete,
    setPopup: SieauAction.setPopup,
    changeBssCode: InstallationAction.changeBssCode,
    fetchStatus: QualityAction.fetchStatus,
    fetchInstallationEvents: InstallationAction.fetchInstallationEvents,
    fetchContacts: ContactAction.fetchContacts,
    fetchWatersheds: WatershedAction.fetchWatersheds,
    fetchNatures: StationAction.fetchNatures,
    fetchAquifers: AquiferAction.fetchAquifers,
    fetchFieldModes: StationAction.fetchFieldModes,
    deleteInstallation: InstallationAction.deleteInstallation,
    updateInstallationBorehole: InstallationAction.updateInstallationBorehole,
    getEditionInstallation: InstallationAction.getEditionInstallation,
    fetchInstallationVisits: InstallationAction.fetchInstallationVisits,
    fetchParcelAndSectionByCoordinate: ApiCartoAction.fetchParcelAndSectionByCoordinate,
}

export default connect(mapStateToProps, mapDispatchToProps)(LocalisationMapPanel)
