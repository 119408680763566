import DtoHydrometryObservation from 'hydrometry/dto/DtoHydrometryObservation'
import { compact, orderBy } from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import Card from '../../../components/card/Card'
import { getDate } from '../../../utils/DateUtil'
import { arrayOf } from '../../../utils/StoreUtils'
import HydrometryAction from '../../actions/HydrometryAction'
import DtoHydroStats from '../../dto/chronicMeasures/DtoHydroStats'
import { Grid } from '@mui/material'
import TideGaugeDetails from './TideGaugeDetails'
import Icon from '../../../components/icon/Icon'
import DtoHydrometricStation from '../../dto/DtoHydrometricStation'

class HydoStatisticsPanel extends Component {
    componentDidMount() {
        // this.props.fetchHydroStatistics(parseInt(this.props.id)) --> call djà fait dans le HydroChartPanel
    }

    isMultiPoint = () => this.props.hydroStatistics.some(stat => this.props.hydroStatistics.some(stat2 => stat.typeId === stat2.typeId && stat.codepoint !== stat2.codepoint))

    getMeasureFormat = () => {
        const { hydroStatistics, hydrometryObservations } = this.props
        const stats = orderBy(hydroStatistics, ['order', 'typeId'], 'asc').map(p => {
            const comment = p?.comment ? (<Icon
                icon='info'
                style={{ fontSize: 18, color: 'grey' } }
                tooltip={ p.comment }
            />) : ''
            return [
                <>{p.label} {comment}</>,
                this.isMultiPoint() ? p.namePoint : undefined,
                getDate(p.startDate),
                getDate(p.endDate),
                p.countTotal,
                getDate(p.control),
                getDate(p.validation),
            ].filter(v => !!v)
        })
        if (hydrometryObservations.length) {
            const allHydroObsDate = compact(hydrometryObservations.map(o => o.observationDate))
            const statsObs = [
                i18n.observations,
                getDate(Math.min(...allHydroObsDate)),
                getDate(Math.max(...allHydroObsDate)),
                hydrometryObservations.length,
            ]
            return [...stats, statsObs]
        }
        return stats
    }

    getHeader = () => {
        const headers = [ i18n.statistics, this.isMultiPoint() ? i18n.pointPrelevement : null, i18n.begin, i18n.end, i18n.nbMeasures, i18n.control, i18n.validationDate ].filter(s => !!s)
        const result = headers.map((o, i) => {
            return (<th className={`${i === 0 ? 'left' : 'center'}`} style={{ lineHeight: '25px', padding: '5px 0 5px 25px', fontSize: i === 0 ? '18px' : '16px' }}>{o}</th>)
        })
        return (
            <thead>
                <tr>{result}</tr>
            </thead>
        )
    }

    getBody = () => {
        const statistics = this.getMeasureFormat()
        return statistics.map(stat => {
            const result = stat.map((o, i) => {
                return (<td className={`${i === 0 ? 'right right-align bold' : 'center'}`}> {o}</td>)
            })
            return (
                <tr style={{ fontSize: '14.3px', lineHeight: '20px' }}>{result}</tr>
            )
        })
    }

    render() {
        const { id } = this.props
        const content = (
            <div className='row no-margin'>
                <div className='col s12 no-padding'>
                    <table className='table condensed'>
                        {this.getHeader()}
                        <tbody>
                            {this.getBody()}
                        </tbody>
                    </table>
                </div>
            </div>
        )
        return (
            <Grid container>
                <Grid container item xs={12} justifyContent='center'>
                    <Card round noBoxShadow cardStyle={{ border: '1px solid grey', borderRadius: '5px', width: '80%' }}>{ content }</Card>
                </Grid>
                {
                    this.props.hydrometricStation.stationType === 4 && (
                        <Grid item xs={12} className='margin-top-1'>
                            <TideGaugeDetails stationId={parseInt(id)}/>
                        </Grid>
                    )
                }
            </Grid>
        )
    }
}

HydoStatisticsPanel.propTypes = {
    hydrometricStation: PropTypes.instanceOf(DtoHydrometricStation),
    hydroStatistics: arrayOf(DtoHydroStats),
    hydrometryObservations: arrayOf(DtoHydrometryObservation),
    fetchHydroStatistics: PropTypes.func,
    id: PropTypes.string.isRequired,
}

const mapStateToProps = store => ({
    hydrometricStation: store.HydrometryReducer.hydrometricStation,
    hydroStatistics: store.HydrometryReducer.hydroStatistics,
    hydrometryObservations: store.HydrometryReducer.hydrometryObservations,
})

const mapDispatchToProps = {
    fetchHydroStatistics: HydrometryAction.fetchHydroStatistics,
}

export default connect(mapStateToProps, mapDispatchToProps)(HydoStatisticsPanel)