import React from 'react'
import PropTypes from 'prop-types'
import { filter, orderBy, tail, take } from 'lodash'
import noPicture from 'assets/pictures/noPicture.png'
import i18n from 'simple-react-i18n'
import DtoPicture from '../../station/dto/DtoPicture'
import { contentsPath } from '../../conf/basepath'
import Card from 'components/card/Card'
import Fancybox from 'components/fancybox/Fancybox'
import { Grid } from '@mui/material'

const SmallPicturePanel = ({
    element = '',
    pictures = [],
    showTitle = false,
}) => {
    const createPicture = (picture, isFirst = false) => {
        const url = picture.url.startsWith('http') ? picture.url : `${contentsPath}${picture.url}`
        if (isFirst) {
            return (
                <Fancybox>
                    <a href={url} data-fancybox='images'
                        data-caption={picture.name}
                    >
                        <img src={url} className='first-picture' />
                    </a>
                </Fancybox>
            )
        }
        return (
            <Grid item>
                <Fancybox>
                    <a href={url} data-fancybox='images'
                        data-caption={picture.name}
                    >
                        <img src={url} className='small-picture' />
                    </a>
                </Fancybox>
            </Grid>
        )
    }

    const getPictures = () => take(tail(orderBy(pictures, 'date', 'desc')), 3).map(picture => createPicture(picture))

    const getFirstPicture = () => {
        const picture = {
            name: i18n.noPicture,
            url: noPicture,
        }
        if (pictures.length > 0) {
            const firstPicture = filter(pictures, o => o.name.includes(`_${element}`) || o.name.includes(` ${element}`))
            if (firstPicture.length > 0) {
                return createPicture(orderBy(firstPicture, 'date', 'desc')[0], true)
            }
            return createPicture(orderBy(pictures, 'date', 'desc')[0], true)
        }
        return createPicture(picture, true)
    }

    return pictures.length > 0 && (
        <Card
            className='card margin-bottom-1'
            noMargin={false}
            round
            title={
                showTitle && (
                    <div className='card-title'>
                        <b>{i18n.pictures}</b>
                    </div>
                )
            }
        >
            <Grid container direction='column' alignItems='center' justifyContent='center'>
                <Grid item>
                    {getFirstPicture()}
                </Grid>
                <Grid className='padding-top-1 padding-bottom-1' item container alignItems='center' justifyContent='space-evenly' spacing={1}>
                    {getPictures()}
                </Grid>
            </Grid>
        </Card>
    )
}

SmallPicturePanel.propTypes = {
    element: PropTypes.string,
    pictures: PropTypes.arrayOf(PropTypes.instanceOf(DtoPicture)),
    showTitle: PropTypes.bool,
}

export default SmallPicturePanel