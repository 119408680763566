'use strict'
import ApplicationConf from 'conf/ApplicationConf'
import fetch from 'isomorphic-fetch'
import { RECEIVE_TOWNS_INFO } from '../../administration/constants/AdministrationConstants'
import { getJson } from '../../utils/ActionUtils'

const GeoApiAction = {
    receiveTownsByCoordinate(towns) {
        return { type: RECEIVE_TOWNS_INFO, towns }
    },
    fetchTownsByCoordinate(x, y) {
        return (dispatch) => {
            return fetch(ApplicationConf.towns.getByCoordinate(x, y), {
                method: 'GET',
            })
                .then(getJson)
                .then((json = []) => {
                    dispatch(GeoApiAction.receiveTownsByCoordinate(json))
                })
        }
    },
}

export default GeoApiAction