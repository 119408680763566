import DevelopmentApp from 'inDevelop/components/DevelopmentApp'
import React, { Component } from 'react'
// import i18n from 'simple-react-i18n'

class TerritoryScenarioPanel extends Component {
    constructor(props) {
        super(props)
        this.state = {
            dataLoaded: false,
        }
    }

    render() {
        return (
            <div className='row no-margin padding-1'>
                <DevelopmentApp />
            </div>
        )
    }
}

export default TerritoryScenarioPanel