export default class PerimeterParcelDto {
    constructor(obj) {
        this.folderId = obj.folderId // Int,
        this.perimeterId = obj.perimeterId // Int,
        this.parcelId = obj.parcelId // Int,
        this.parcel = obj.parcel // Option[String],
        this.section = obj.section // Option[String],
        this.area = obj.area // Option[Float],
        this.ownerIndemnity = obj.ownerIndemnity // Option[Boolean],
        this.operatorIndemnity = obj.operatorIndemnity // Option[Boolean],
        this.updateDate = obj.updateDate // Option[DateTime],
        this.updateLogin = obj.updateLogin // Option[String],
        this.subdivision = obj.subdivision // Option[String],
        this.classOfDirt = obj.classOfDirt // Option[String],
        this.groupId = obj.groupId // Option[Int],
        this.cityCode = obj.cityCode // Option[String],
        this.address = obj.address // Option[String],
        this.hypothecary = obj.hypothecary // Option[Int],

        this.privateOwners = obj.privateOwners // Option[Seq[Int]],
        this.companyOwners = obj.companyOwners // Option[Seq[Int]]
    }
}