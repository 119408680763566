/* eslint-disable camelcase */
import { Grid } from '@mui/material'
import { setActions } from 'components/ActionUtil'
import Card from 'components/card/Card'
import InstallationAction from 'installation/actions/InstallationAction'
import React, { useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import ContributorAction from 'referencial/components/contributor/actions/ContributorAction'
import SectorAction from 'referencial/components/sector/actions/SectorAction'
import DangersManagerForm from './components/DangersManagerForm'
import DangersManagerTable from './components/DangersManagerTable'
import InstallationDangerModal from './components/InstallationDangerModal'
import OtherEntityModal from './components/OtherEntityModal'

const DangersManager = ({
}) => {
    const dispatch = useDispatch()

    const [isNew, setIsNew] = useState(false)
    const [open, setOpen] = useState(false)
    const [openDiag, setOpenDiag] = useState(false)

    const {
        dangersWithType,
    } = useSelector(store => ({
        dangersWithType: store.InstallationReducer.dangersWithType,
    }), shallowEqual)

    useEffect(() => {
        dispatch(ContributorAction.fetchContributors())
        dispatch(SectorAction.fetchSectors())
        dispatch(InstallationAction.fetchDangersWithType({}))
        dispatch(InstallationAction.fetchInstallationsLight())

        setActions({
            new: () => {
                setIsNew(true)
                setOpen(true)
            },
        })
    }, [dispatch])

    return (
        <Grid className='margin-left-2 margin-right-1 padding-top-1'>
            <Card className='padding-top-1'>
                <Grid container>
                    <Grid item xs={12}>
                        <DangersManagerForm />
                    </Grid>
                    <Grid item xs={12}>
                        <DangersManagerTable dangers={dangersWithType} />
                    </Grid>
                </Grid>
                <OtherEntityModal isNew={isNew} open={open} setOpen={setOpen} setIsNew={setIsNew} />
                <InstallationDangerModal open={openDiag} setOpen={setOpenDiag} />
            </Card>
        </Grid>
    )
}

DangersManager.propTypes = {
}

export default DangersManager
