import { getResultFormat } from 'utils/AnalyseUtils'

export default class DtoAnalysisUltraLight {
    constructor(obj) {
        this.qualitometer = obj[0] // Double
        this.id = obj[1] // Double
        this.operation = obj[2] // Double
        this.sampleDate = obj[3] // Option[DateTime]
        this.analysisDate = obj[4] || obj[3] // Option[DateTime]
        this.result = obj[5] // Option[Double]
        this.parameter = obj[6] // Option[String]
        this.unit = obj[7] // Option[String]
        this.remark = obj[8] // Option[String]
        this.lq = obj[9] // Option[Double]
        this.ld = obj[10] // Option[Double]
        this.saturationThreshold = obj[11] // Option[Double]

        this.value = getResultFormat({ result: obj[5], remark: obj[8] })
    }
}