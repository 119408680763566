import { Button, Grid2 } from '@mui/material'
import Card from 'components/card/Card'
import Input from 'components/forms/Input'
import React, { useState } from 'react'
import i18n from 'simple-react-i18n'
import PropTypes from 'prop-types'
import MultiAutocomplete from 'components/forms/MultiAutocomplete'
import RestictionsDisplay from 'exploitations/components/waterTurns/RestrictionsDisplay'

const WaterTurnsFilters = ({
    restrictions = [],
    equipments = [],
    data = [],
    onFilter = () => { },
}) => {
    const [search, setSearch] = useState()
    const [equipmentsFilter, setEquipmentsFilter] = useState()

    return (
        <Grid2 size={12}>
            <Card className='padding-1' round>
                <Grid2 container size={12}>
                    <Grid2 size={6} className='padding-right-1'>
                        <Input
                            title={i18n.search}
                            value={search}
                            onChange={setSearch}
                            onEnterKeyPress={() => onFilter({ search, equipmentsFilter })}
                            data-cy='search_water_turns'
                        />
                    </Grid2>
                    <Grid2 size={6}>
                        <MultiAutocomplete
                            col={12}
                            displayWithCode
                            label={i18n.pumps}
                            elements={equipments.filter(e => data.map(d => d.material).includes(e.reference))}
                            clearFunction
                            keyName='reference'
                            values={equipmentsFilter}
                            onChange={setEquipmentsFilter}
                        />
                    </Grid2>
                    <Grid2 size={8} className='padding-top-1'>
                        <RestictionsDisplay restrictions={restrictions} />
                    </Grid2>
                    <Grid2 container size={4} justifyContent='flex-end'>
                        <Grid2>
                            <Button
                                variant='outlined'
                                onClick={() => {
                                    setSearch()
                                    setEquipmentsFilter()
                                    onFilter({ search: null, equipmentsFilter: null })
                                }}
                            >
                                {i18n.reinit}
                            </Button>
                        </Grid2>
                        <Grid2 className='padding-left-1'>
                            <Button
                                variant='contained'
                                onClick={() => onFilter({ search, equipmentsFilter })}
                            >
                                {i18n.search}
                            </Button>
                        </Grid2>
                    </Grid2>
                </Grid2>
            </Card >
        </Grid2 >
    )
}

WaterTurnsFilters.propTypes = {
    restrictions: PropTypes.arrayOf(PropTypes.shape({})),
    equipments: PropTypes.arrayOf(PropTypes.shape({})),
    data: PropTypes.arrayOf(PropTypes.shape({})),
    onFilter: PropTypes.func,
}

export default WaterTurnsFilters