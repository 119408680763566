import { maxBy, minBy } from 'lodash'
import moment from 'moment'
import React from 'react'
import i18n from 'simple-react-i18n'
import { getDateWithHour, getFullDate } from '../../../utils/DateUtil'
import { round } from '../../../utils/NumberUtil'
import { getMeasureValue } from '../../../utils/PiezometryUtils'
import { chunkWithWords } from '../../../utils/StringUtil'
import { MEASURE_COTE } from '../../constants/PiezometryConstants'

const getMaintenance1 = (events, startDate, componentState) => {
    const measure = componentState.measures.find(m => m.date === startDate)
    if (startDate) {
        const minBound = moment(startDate).subtract(3, 'days').valueOf()
        const founds = events.filter(e => {
            if (e.date && e.ns) {
                const eventDate = getDateWithHour(e.date, e.eventHour).valueOf()
                return eventDate >= minBound && eventDate <= startDate
            }
            return false
        })
        if (founds.length) {
            const found = maxBy(founds, e => getDateWithHour(e.date, e.eventHour).valueOf())
            const depthValue = round(getMeasureValue(measure, MEASURE_COTE.DEPTH, componentState.lastLandmark, componentState.groundRefAlti, true))
            const delta = round(Math.abs(found.ns > depthValue ? found.ns - depthValue : depthValue - found.ns))
            return `Maintenance 1 :\nMesure manuelle : ${found.ns}m\nMesure instantanée capteur : ${depthValue}m\nDate : ${getFullDate(measure.date)}\nDelta : ${delta}m`
        }
    }
    return null
}

const getMaintenance2 = (events, endDate, componentState) => {
    const measure = componentState.measures.find(m => m.date === endDate)
    if (endDate) {
        const maxBound = moment(endDate).add(3, 'days').valueOf()
        const founds = events.filter(e => {
            if (e.date && e.ns) {
                const eventDate = getDateWithHour(e.date, e.eventHour).valueOf()
                return eventDate <= maxBound && eventDate >= endDate
            }
            return false
        })
        if (founds.length) {
            const found = minBy(founds, e => getDateWithHour(e.date, e.eventHour).valueOf())
            const depthValue = round(getMeasureValue(measure, MEASURE_COTE.DEPTH, componentState.lastLandmark, componentState.groundRefAlti, true))
            const delta = round(Math.abs(found.ns > depthValue ? found.ns - depthValue : depthValue - found.ns))
            return `Maintenance 2 :\nMesure manuelle : ${found.ns}m\nMesure instantanée capteur : ${depthValue}m\nDate : ${getFullDate(measure.date)}\nDelta : ${delta}m`
        }
    }
    return null
}

const getLisibleComment = (comment) => {
    if (comment) {
        return chunkWithWords(comment, 40).replaceAll('\n', '<br />')
    }
    return i18n.event
}

const getEventComment = (comment) => {
    if (comment) {
        return comment.split('\n').map(c => <p>{ c }</p>)
    }
    return i18n.event
}

export { getMaintenance1, getMaintenance2, getLisibleComment, getEventComment }
