import i18n from 'simple-react-i18n'
import Icon from '../../../components/icon/Icon'
import Checkbox from '../../../components/forms/Checkbox'
import { StyledFieldSet, StyledLegend } from '../../../components/StyledElements'
import { Grid } from '@mui/material'
import RadioButtons from '../../../components/forms/RadioButtons'
import Select from '../../../components/forms/Select'
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import NumberField from '../../../components/forms/NumberField'
import Button from '../../../components/forms/Button'
import { isNil } from 'lodash'

const DAY = 'DAY'
const HOUR = 'HOUR'

const CUMUL_MAX = 'CUMUL_PERSO_MAX'
const CUMUL_MIN = 'CUMUL_PERSO_MIN'
const CUMUL_SUM = 'CUMUL_PERSO_SUM'
const CUMUL_AVERAGE = 'CUMUL_PERSO_AVERAGE'

// deprecated use HydroOption instead
const GroupedHydroPanel = ({
    hydroGrouped,
    setHydroGrouped,
    left = 460,
}) => {
    const [isOpen, setIsOpen] = useState(false)
    const [isPersonalizedGrouping, setIsPersonalizedGrouping] = useState(false)
    const [optionDayHourSelected, setOptionDayHourSelected] = useState(HOUR)
    const [hoursCumul, setHoursCumul] = useState(1)
    const [optionCumulSelected, setOptionCumulSelected] = useState(CUMUL_MAX)

    const onClosePopinCumul = () => {
        const day = `${optionCumulSelected}_${hoursCumul * 24}`
        const hour = `${optionCumulSelected}_${hoursCumul}`
        setHydroGrouped(optionDayHourSelected === DAY ? day : hour)
        setIsPersonalizedGrouping(false)
    }


    const changeGroupedDate = (group) => {
        setHydroGrouped(group)
        setIsOpen(false)
    }

    const optionDayHour = [
        { value: HOUR, label: i18n.hour },
        { value: DAY, label: i18n.day },
    ]
    const optionCumul = [
        { value: CUMUL_MAX, label: i18n.max },
        { value: CUMUL_MIN, label: i18n.min },
        { value: CUMUL_SUM, label: i18n.sum },
        { value: CUMUL_AVERAGE, label: i18n.average },
    ]
    const isCumul= hydroGrouped.slice(0, 5) === 'CUMUL'
    return (
        <div style={{ left, position: 'relative', width: '24px' }}>
            <Icon className='relative' tooltip={ i18n.hydrometricGrouping } style={{ top: '4px', margin: '0 15px' }} icon={'settings'} onClick={() => setIsOpen(!isOpen)} />
            <div className={isOpen ? 'chartDates-dropdown z-index-10' : 'hidden'} style={{ left: '35px' }}>
                <div style={{ height: '10px' }} />
                <div className='sieau-dropdown-panel padding-top-1 padding-bottom-1  margin-top-1' style={{ width: '450px' }}>
                    <h5 className='center'>{ i18n.regrouping }</h5>
                    <div className='collection-item row no-margin valign-wrapper padding-left-1' >
                        <Checkbox checked={ hydroGrouped === 'AUTO' && !isPersonalizedGrouping } label={ i18n.auto } onChange={() => changeGroupedDate('AUTO') } />
                        <div style={ { 'padding-left': 5 } }/>
                        <Icon icon='info' style={{ fontSize: 18, color: 'grey' } }
                            tooltip={ i18n.descriptionCumulAuto }
                        />
                    </div>
                    <div className='collection-item row no-margin valign-wrapper padding-left-1' >
                        <Checkbox checked={ hydroGrouped === 'all' && !isPersonalizedGrouping } label={`${i18n.allMeasures}`} onChange={() => changeGroupedDate('all') } />
                    </div>
                    <div className='collection-item row no-margin valign-wrapper padding-left-1' >
                        <Checkbox checked={ isPersonalizedGrouping || isCumul } label={ i18n.personalizedGrouping } onChange={() => setIsPersonalizedGrouping(prev => !prev) } />
                    </div>
                    <div style={{ padding: '15px 15px' }}>
                        <StyledFieldSet>
                            <StyledLegend>{ i18n.personalizedGrouping }</StyledLegend>
                            <Grid container justifyContent='space-evenly' style={{ padding: '10px 10px' }} >
                                <Grid item>
                                    <RadioButtons
                                        colOption={6}
                                        label={ i18n.modeDaysHours }
                                        elements={optionDayHour}
                                        selected={optionDayHourSelected}
                                        onChange={setOptionDayHourSelected}
                                        disabled={ !isPersonalizedGrouping }
                                    />
                                </Grid>
                                <Grid container justifyContent='space-evenly' style={{ padding: '10px 10px' }} >
                                    <Select
                                        label={ i18n.modeGrouping }
                                        options={optionCumul}
                                        value={optionCumulSelected}
                                        onChange={setOptionCumulSelected}
                                        disabled={ !isPersonalizedGrouping }
                                    />
                                    <NumberField
                                        title={ i18n.numberHoursDays }
                                        value={ hoursCumul }
                                        onChange={ setHoursCumul }
                                        min={ 1 }
                                        disabled={ !isPersonalizedGrouping }
                                    />
                                </Grid>
                                <Button title={ i18n.calculate } onClick={ onClosePopinCumul } variant='outlined' disabled={ !isPersonalizedGrouping || isNil(hoursCumul) } />
                            </Grid>
                        </StyledFieldSet>

                    </div>
                </div>
            </div>
        </div>
    )
}
GroupedHydroPanel.propTypes = {
    hydroGrouped: PropTypes.string,
    setHydroGrouped: PropTypes.func,
    left: PropTypes.number,
}

export default GroupedHydroPanel