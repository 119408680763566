import React from 'react'
import ActionComponent from '../../../../components/ActionComponent'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import Input from '../../../../components/forms/Input'
import Checkbox from '../../../../components/forms/Checkbox'
import { push } from '@lagunovsky/redux-react-router'
import ManagementUnitAction from '../actions/ManagementUnitAction'
import SieauAction from '../../../../components/sieau/SieauAction'
import Table from '../../../../components/datatable/Table'
import { Button, Dialog } from '@mui/material'
import { orderBy } from 'lodash'
import { getDate } from '../../../../utils/DateUtil'
import { exportFile } from '../../../../utils/ExportDataUtil'
import DtoManagementUnit from '../dto/DtoManagementUnit'
import TableManagementUnitVolumes from './TableManagementUnitVolumes'
import SimpleDatePicker from '../../../../components/forms/SimpleDatePicker'
import { hasValue } from 'utils/NumberUtil'
import Row from 'components/react/Row'
import Select from 'components/forms/Select'
import Card from 'components/card/Card'
import TableManagementUnitStations from './TableManagementUnitStations'
import PiezometryAction from 'piezometry/actions/PiezometryAction'
import PluviometryAction from 'pluviometry/actions/PluviometryAction'
import HydrometryAction from 'hydrometry/actions/HydrometryAction'
import DtoHydrometricStation from 'hydrometry/dto/DtoHydrometricStation'
import DtoPiezometerLight from 'piezometry/dto/DtoPiezometerLight'
import PluviometerDto from 'pluviometry/dto/PluviometerDto'
import { DialogActionsMUI, DialogContentMUI, DialogTitleMUI } from 'components/styled/Dialog'
import Icon from 'components/icon/Icon'

const headers = ['nullValue', 'id', 'name', 'lowWaterStartDateDisplay', 'lowWaterEndDateDisplay']

class ManagementUnitApp extends ActionComponent {
    constructor(props) {
        super(props)
        this.state = {
            managementUnit: {},
            managementUnitToUpdate: {},
            openDialog: false,
            isCreate: false,
            editMode: false,
        }
    }

    componentDidMount() {
        const { params, hydrometricStations, pluviometers, piezometersLight } = this.props
        if (params.code !== 'new') {
            this.props.fetchManagementUnit(params.code).then(() => {
                this.props.fetchManagementUnits().then(() => {
                    const { managementUnit } = this.props
                    this.setState({ managementUnit })
                })
                this.setTitle()
            })
        } else {
            this.setState({ editMode: true })
            this.setTitle()
        }
        if (!hydrometricStations.length) {
            this.props.fetchHydrometricStations()
        }
        if (!pluviometers.length) {
            this.props.fetchPluviometers()
        }
        if (!piezometersLight.length) {
            this.props.fetchPiezometersLight()
        }
        this.getActions()
    }

    componentDidUpdate(prevProps) {
        const { params } = this.props
        if (params.code !== prevProps.params.code) {
            const { managementUnit } = this.props
            this.setState({ managementUnit })
        }
        this.setTitle()
    }

    setTitle = () => {
        const { params, managementUnit } = this.props
        this.props.forceFetch('title', [{
            title: i18n.referencials,
            href: '/referencial',
        }, {
            title: i18n.managementUnit,
            href: '/referencial/managementUnit/',
        },
        {
            title: params.code !== 'new' ? managementUnit.name : i18n.newManagementUnit,
            href: `/referencial/managementUnit/${params.code}`,
        }])
    }

    getActions = () => {
        if (this.state.editMode) {
            this.setActions({
                save: () => {
                    if (this.props.params.code === 'new') {
                        this.valideCreate(this.state.managementUnit)
                    } else {
                        this.valideUpdate(this.state.managementUnit)
                    }
                    this.setState({ editMode: false })
                },
                cancel: () => {
                    if (this.props.params.code !== 'new') {
                        this.setState({
                            editMode: false,
                            managementUnit: this.props.managementUnit,
                        })
                    } else {
                        this.props.push('/referencial/managementUnit')
                    }
                },
            })
        } else {
            this.setActions({
                edit: () => {
                    this.setState({ editMode: true })
                },
            })
        }
    }

    getFormattedList = (managementUnits) => {
        const { editMode } = this.state
        const managementUnitsOrdered = orderBy(managementUnits, 'id', 'asc')
        return managementUnitsOrdered.map((mu) => ({
            ...mu,
            lowWaterStartDateDisplay: getDate(mu.lowWaterStartDate, 'DD/MM'),
            lowWaterEndDateDisplay: getDate(mu.lowWaterEndDate, 'DD/MM'),
            hiddenCode: mu.managementCode,
            hiddenId: mu.id,
            nullValue: editMode ? (<i className='material-icons no-display right' style={{ cursor: 'pointer' }} onClick={() => this.setState({ openDialog: true, managementUnitToUpdate: mu, isCreate: false })}>edit</i>) : '',
        }))
    }

    getUnderManagementUnits = () => {
        const { managementUnit, managementUnits } = this.props
        const filteredManagementunits = managementUnits.filter((mu) => mu.managementCode !== managementUnit.managementCode && mu.parent === managementUnit.managementCode)
        return this.getFormattedList(filteredManagementunits)
    }

    getFormattedObject = (managementUnitToFormat) => {
        const { managementUnit } = this.state
        return ({
            ...managementUnitToFormat,
            parent: managementUnit.managementCode === managementUnitToFormat.managementCode ? null : managementUnit.managementCode,
        })
    }

    onRemoveUGE = (managementUnit) => {
        this.props.updateManagementUnit(new DtoManagementUnit({ ...managementUnit, parent: null })).then(() => {
            this.props.fetchManagementUnits()
        })
    }

    valideUpdate = (managementUnit) => {
        this.props.updateManagementUnit(managementUnit).then(() => {
            this.props.fetchManagementUnit(this.state.managementUnit.managementCode).then(() => {
                this.setState({ openDialog: false, managementUnit: this.props.managementUnit })
            })
            this.props.fetchManagementUnits()
        })
    }

    valideUpdateSubUnit = (managementUnit) => {
        const managementUnitToUpdate = this.getFormattedObject(managementUnit)
        this.props.updateManagementUnit(managementUnitToUpdate).then(() => {
            this.props.fetchManagementUnit(this.state.managementUnit.managementCode).then(() => {
                this.setState({ openDialog: false, managementUnit: this.props.managementUnit })
            })
            this.props.fetchManagementUnits()
        })
    }

    valideCreate = (managementUnit) => {
        const managementUnitToUpdate = this.getFormattedObject(managementUnit)
        this.props.createManagementUnit(managementUnitToUpdate).then((newId) => {
            this.props.fetchManagementUnits().then(() => {
                this.setState({ openDialog: false })
                if (this.props.params.code === 'new') {
                    this.props.fetchManagementUnit(newId).then(() => {
                        this.props.push(`/referencial/managementUnit/${newId}`)
                    })
                }
            })
        })
    }

    getExportData = (managementUnits) => {
        return managementUnits.map((mu, i) => {
            return i === 0 ? { headers: ['id', 'name', 'lowWaterStartDate', 'lowWaterEndDate'], ...mu } : mu
        })
    }

    getFormManagementUnit = (managementUnit, key) => {
        const { editMode } = this.state
        return (
            <div className='row'>
                <div className='col s8 offset-s2 margin-top-1'>
                    <div className='row no-margin padding-top-3-px'>
                        <Input
                            col={ 6 }
                            title={ i18n.id }
                            value={ managementUnit.id }
                            onChange={ (v) => this.setState({ [key]: { ...managementUnit, id: v } }) }
                            maxLength={ 5 }
                            disabled={!editMode}
                        />
                        {editMode ? (
                            <SimpleDatePicker
                                onChange={v => this.setState({ [key]: { ...managementUnit, lowWaterStartDate: v } })}
                                label={ i18n.lowWaterStartDate }
                                value={ managementUnit.lowWaterStartDate }
                                col={ 6 }
                                max={ managementUnit.lowWaterEndDate }
                            />
                        ) : (
                            <Input
                                col={ 6 }
                                title={ i18n.lowWaterStartDate }
                                value={ getDate(managementUnit.lowWaterStartDate, 'DD/MM') }
                                disabled
                            />
                        )}
                    </div>
                    <div className='row no-margin padding-top-3-px'>
                        <Input
                            col={ 6 }
                            title={ i18n.name }
                            value={ managementUnit.name }
                            onChange={ (v) => this.setState({ [key]: { ...managementUnit, name: v } }) }
                            maxLength={ 255 }
                            disabled={!editMode}
                        />
                        {editMode ? (
                            <SimpleDatePicker
                                onChange={v => this.setState({ [key]: { ...managementUnit, lowWaterEndDate: v } })}
                                label={ i18n.lowWaterEndDate }
                                value={ managementUnit.lowWaterEndDate }
                                col={ 6 }
                                min={ managementUnit.lowWaterStartDate }
                            />
                        ) : (
                            <Input
                                col={ 6 }
                                title={ i18n.lowWaterEndDate }
                                value={ getDate(managementUnit.lowWaterEndDate, 'DD/MM') }
                                disabled
                            />
                        )}
                    </div>
                    <div className='row no-margin padding-top-3-px'>
                        <Checkbox
                            col={ 6 }
                            label={ i18n.annualBreakdown }
                            checked={ managementUnit.annualBreakdown }
                            onChange={ (v) => this.setState({ [key]: { ...managementUnit, annualBreakdown: v } }) }
                            disabled={!editMode}
                        />
                        <Checkbox
                            col={ 6 }
                            label={ i18n.lowWaterVentilation }
                            checked={ managementUnit.lowWaterVentilation }
                            onChange={ (v) => this.setState({ [key]: { ...managementUnit, lowWaterVentilation: v } }) }
                            disabled={!editMode}
                        />
                    </div>
                    <div className='row no-margin padding-top-3-px'>
                        <Checkbox
                            col={ 6 }
                            label={ i18n.monthlyBreakdown }
                            checked={ managementUnit.monthlyBreakdown }
                            onChange={ (v) => this.setState({ [key]: { ...managementUnit, monthlyBreakdown: v } }) }
                            disabled={!editMode}
                        />
                        <Checkbox
                            col={ 6 }
                            label={ i18n.lowWaterOutVentilation }
                            checked={ managementUnit.lowWaterOutVentilation }
                            onChange={ (v) => this.setState({ [key]: { ...managementUnit, lowWaterOutVentilation: v } }) }
                            disabled={!editMode}
                        />
                    </div>
                </div>
            </div>
        )
    }

    getDialog = () => {
        const { openDialog, managementUnitToUpdate, managementUnit, isCreate } = this.state
        const { managementUnits } = this.props
        return (
            <Dialog
                onClose={() => this.setState({ openDialog: false })}
                fullWidth
                maxWidth='md'
                open={openDialog}
            >
                <DialogTitleMUI style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    {isCreate ? i18n.addUnderManagementUnit : i18n.updateUnderManagementUnit}
                    <Icon style={{ color: 'white' }} size='small' icon='close' onClick={() => this.setState({ openDialog: false, managementUnitToUpdate: {} })} />
                </DialogTitleMUI>
                <DialogContentMUI>
                    {isCreate ? (
                        <>
                            <Row className='padding-top-1'>
                                <Select
                                    col={3}
                                    id='managementUnit'
                                    label={i18n.managementUnit}
                                    value={managementUnitToUpdate.managementCode}
                                    options={managementUnits.filter((m) => m.parent !== managementUnit.managementCode && m.managementCode !== managementUnit.managementCode)}
                                    keyValue='managementCode'
                                    keyLabel='labelWithCode'
                                    onChange={(_, v) => this.setState({ managementUnitToUpdate: v || {} })}
                                />
                            </Row>
                            <Card title={managementUnitToUpdate.id ? i18n.change : i18n.create}>
                                {this.getFormManagementUnit(managementUnitToUpdate, 'managementUnitToUpdate')}
                            </Card>
                        </>
                    ) : this.getFormManagementUnit(managementUnitToUpdate, 'managementUnitToUpdate')}
                </DialogContentMUI>
                <DialogActionsMUI>
                    <Button onClick={() => isCreate && !hasValue(managementUnitToUpdate.managementCode) ? this.valideCreate(managementUnitToUpdate) : this.valideUpdateSubUnit(managementUnitToUpdate)} variant='contained' color='primary'>
                        {i18n.validate}
                    </Button>
                </DialogActionsMUI>
            </Dialog>
        )
    }

    render() {
        const { managementUnit, editMode } = this.state
        const { params } = this.props

        this.getActions()
        const managementUnits = this.getUnderManagementUnits()
        const addAction = editMode ? [{
            iconName: 'note_add',
            tooltip: i18n.add,
            onClick: () => this.setState({ openDialog: true, managementUnitToUpdate: {}, isCreate: true }),
        }] : []

        return (
            <div className='row no-margin'>
                <div id='file' className='col s12'>
                    <div className='card margin-top-0-75-rem'>
                        {this.getFormManagementUnit(managementUnit, 'managementUnit')}
                    </div>
                    {params.code !== 'new' && !hasValue(managementUnit.parent) && (
                        <div className='row margin-top-2'>
                            <div className='card row no-margin'>
                                <Table
                                    title={i18n.listSubManagementUnits}
                                    condensed sortable
                                    data={managementUnits}
                                    type={{ headers }}
                                    customHeaders={{
                                        lowWaterStartDateDisplay: i18n.lowWaterStartDate,
                                        lowWaterEndDateDisplay: i18n.lowWaterEndDate,
                                    }}
                                    actions={[{
                                        iconName: 'file_download',
                                        tooltip: i18n.export,
                                        onClick: () => exportFile({
                                            data: this.getExportData(managementUnits),
                                            exportType: 'xlsx',
                                            titleFile: i18n.listSubManagementUnits,
                                        }),
                                    }, ...addAction]}
                                    deletable={editMode}
                                    onDelete={(m) => this.onRemoveUGE(m)}
                                />
                            </div>
                        </div>
                    )}
                    {managementUnit.link_aup && params.code !== 'new' && (
                        <TableManagementUnitVolumes
                            managementUnit={managementUnit}
                            changeManagementUnit={(mu) => this.setState({ managementUnit: mu })}
                            isEdit={editMode}
                        />
                    )}
                    {managementUnit.linkStations && params.id !== 'new' && (
                        <TableManagementUnitStations
                            managementUnit={managementUnit}
                            changeManagementUnit={(mu) => this.setState({ managementUnit: mu })}
                            isEdit={editMode}
                        />
                    )}
                </div>
                {this.getDialog()}
            </div>
        )
    }
}

ManagementUnitApp.propTypes = {
    params: PropTypes.shape({
        code: PropTypes.string,
    }),
    managementUnit: PropTypes.instanceOf(DtoManagementUnit),
    managementUnits: PropTypes.arrayOf(PropTypes.instanceOf(DtoManagementUnit)),
    hydrometricStations: PropTypes.arrayOf(PropTypes.instanceOf(DtoHydrometricStation)),
    pluviometers: PropTypes.arrayOf(PropTypes.instanceOf(PluviometerDto)),
    piezometersLight: PropTypes.arrayOf(PropTypes.instanceOf(DtoPiezometerLight)),
    fetchManagementUnit: PropTypes.func,
    fetchManagementUnits: PropTypes.func,
    forceFetch: PropTypes.func,
    createManagementUnit: PropTypes.func,
    updateManagementUnit: PropTypes.func,
    fetchPiezometersLight: PropTypes.func,
    fetchPluviometers: PropTypes.func,
    fetchHydrometricStations: PropTypes.func,
}

const mapStateToProps = store => {
    return {
        managementUnit: store.ManagementUnitReducer.managementUnit,
        managementUnits: store.ManagementUnitReducer.managementUnits,
        hydrometricStations: store.HydrometryReducer.hydrometricStations,
        pluviometers: store.PluviometryReducer.pluviometers,
        piezometersLight: store.PiezometryReducer.piezometersLight,
    }
}

const mapDispatchToProps = {
    forceFetch: SieauAction.forceFetch,
    fetchManagementUnit: ManagementUnitAction.fetchManagementUnit,
    fetchManagementUnits: ManagementUnitAction.fetchManagementUnits,
    createManagementUnit: ManagementUnitAction.createManagementUnit,
    updateManagementUnit: ManagementUnitAction.updateManagementUnit,
    fetchPiezometersLight: PiezometryAction.fetchPiezometersLight,
    fetchPluviometers: PluviometryAction.fetchPluviometers,
    fetchHydrometricStations: HydrometryAction.fetchHydrometricStations,
    push,
}

export default connect(mapStateToProps, mapDispatchToProps)(ManagementUnitApp)
