import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import { sieauTooltip } from 'utils/FormUtils'
import ElementCard from '../../../../components/card/ElementCard'
import DiagnosticDto from '../../../../events/dto/piezometryDiagnostic/DiagnosticDto'
import SolutionDto from '../../../../events/dto/piezometryDiagnostic/SolutionDto'
import SolutionLinkProblemDto from '../../../../events/dto/piezometryDiagnostic/SolutionLinkProblemDto'
import { SANDRE } from '../../../../referencial/constants/ReferencialConstants'
import DtoSandreCode from '../../../../referencial/dto/DtoSandreCode'
import { getSandreList } from '../../../../utils/StoreUtils'


class ActionChoicePanel extends Component {
    componentDidMount() {
        $('.tooltipped').tooltip()
    }

    getIconTotalAction = (diag) => {
        const nbAction = this.props.actions.filter(sa => sa.diagnosticId === diag.codeDiagnostic).length
        if (nbAction === 0) {
            return ''
        }
        if (nbAction <= 9) {
            return `filter_${nbAction.toString()}`
        }
        return 'filter_9_plus'
    }

    isDisabledDiagnostic = (direction) => {
        switch (direction) {
            case 'UP': return this.props.interventionIndex >= this.props.diagnostics.length - 1 ? 'disabled' : ''
            case 'DOWN': return this.props.interventionIndex <= 0 ? 'disabled' : ''
            default: return 'disabled'
        }
    }

    render = () => {
        const actionConstants = getSandreList(this.props.sandreCodes, SANDRE.EVENEMENTS_ACTION)
        const diagnostic = this.props.diagnostics[this.props.interventionIndex]
        const diagnosticSolutions = this.props.eventPiezometerSolutionsLinkProblems.filter(link => link.codeProblem === diagnostic.codeDiagnostic)
            .map(link => this.props.eventPiezometerSolutions.find(s => s.codeSolution === link.codeSolution)).filter(d => !!d)
        const allActions = diagnosticSolutions.map(a => ({ ...a, diagnosticId: diagnostic.codeDiagnostic }))
        const actionContent = allActions.map(action => {
            const actionInState = this.props.actions.find(a => a.code === action.code && a.diagnosticId === diagnostic.codeDiagnostic)
            const isSelected = actionInState && actionInState.diagnosticId === diagnostic.codeDiagnostic
            const { comment: icon } = actionConstants.find(({ code }) => code == action.materialChange) || {}
            const tooltip = action.comment ? sieauTooltip(action.comment) : null
            return (
                <div {...tooltip}>
                    <ElementCard
                        icon='build'
                        color={isSelected ? 'green' : 'white'}
                        iconColor={isSelected ? 'white' : 'green'}
                        className='clickable'
                        onClick={() => this.props.onChangeAction(action)}
                        icon2={icon}
                    >
                        <h6>
                            {action.description}
                        </h6>
                    </ElementCard>
                </div>
            )
        })
        return (
            <div>
                <div className='row no-margin'>
                    {this.props.diagnostics.map((d, i) => (
                        <div className='col s6' >
                            <ElementCard
                                icon='report_problem'
                                color={i === this.props.interventionIndex ? 'red' : 'grey'}
                                className='clickable'
                                onClick={this.props.goToSelectDiagnostic}
                                icon2={this.getIconTotalAction(d)}
                            >
                                <h6>
                                    {d.description}
                                </h6>
                            </ElementCard>
                        </div>
                    ))}
                </div>
                <div className='col s12 center'>
                    <a className={`btn-floating btn-small waves-effect waves-light ${this.isDisabledDiagnostic('DOWN')}`} onClick={() => this.props.onChangeDiagnosticIndex('DOWN')}>
                        <i className='material-icons small'>keyboard_arrow_left</i>
                    </a>
                    <a className={`btn-floating btn-small waves-effect waves-light ${this.isDisabledDiagnostic('UP')}`} onClick={() => this.props.onChangeDiagnosticIndex('UP')}>
                        <i className='material-icons small'>keyboard_arrow_right</i>
                    </a>
                </div>
                <div className='row no-margin valign-wrapper padding-left-1 padding-top-1'>
                    <i className={'material-icons'}>trending_flat</i>
                    <h6 className='padding-left-1'>{i18n.witchActionAssociated}</h6>
                </div>
                <div className='scrollable-card' style={{ maxHeight: '60%' }}>
                    {actionContent}
                </div>
            </div>
        )
    }
}

ActionChoicePanel.propTypes = {
    diagnostics: PropTypes.arrayOf(PropTypes.instanceOf(DiagnosticDto)),
    actions: PropTypes.arrayOf(PropTypes.instanceOf(SolutionDto)),
    interventionIndex: PropTypes.number,
    onChangeAction: PropTypes.func,
    goToSelectDiagnostic: PropTypes.func,
    onChangeDiagnosticIndex: PropTypes.func,

    eventPiezometerSolutions: PropTypes.arrayOf(PropTypes.instanceOf(SolutionDto)),
    eventPiezometerSolutionsLinkProblems: PropTypes.arrayOf(PropTypes.instanceOf(SolutionLinkProblemDto)),
    sandreCodes: PropTypes.arrayOf(PropTypes.instanceOf(DtoSandreCode)),
}

const mapStateToProps = store => ({
    eventPiezometerSolutions: store.EventsReducer.eventPiezometerSolutions,
    eventPiezometerSolutionsLinkProblems: store.EventsReducer.eventPiezometerSolutionsLinkProblems,
    sandreCodes: store.ReferencialReducer.sandreCodes,
})

export default connect(mapStateToProps)(ActionChoicePanel)