import React from 'react'
import PropTypes from 'prop-types'
import { shallowEqual, useSelector } from 'react-redux'
import { secondaryBlue } from 'utils/constants/ColorTheme'
import Card from '../../../components/card/Card'
import CityDto from '../../../referencial/components/city/dto/CityDto'
import ContributorDto from '../../../referencial/components/contributor/dto/ContributorDto'
import { formatPhone } from '../../../utils/StringUtil'

const iconStyle = {
    width: '24px',
    height: '24px',
}

const IntervenantCard = ({ intervenant, title, actions = [], contributor, style, round }) => {
    const {
        cities,
        citiesHisto,
    } = useSelector(store => ({
        cities: store.CityReducer.cities,
        citiesHisto: store.CityReducer.citiesHisto,
    }), shallowEqual)

    const city = (intervenant.cityCodeDelegate ? citiesHisto.find((c) => c.cityCode === intervenant.cityCodeDelegate) : cities.find((c) => c.id === intervenant.cityCode)) || {}

    const address = (contributor ? intervenant.road : intervenant.address) || ''
    const addressComplement = (contributor ? intervenant.addressComplement : intervenant.additionalAddress) || ''
    const postalCode = (contributor ? (intervenant.postalCodeDelegate || intervenant.postalCode) : intervenant.postalBox) || ''
    return (
        <Card
            title={title}
            headerStyle={{ backgroundColor: secondaryBlue }}
            actions={actions}
            className='row no-margin padding-bottom-1'
            cardStyle={{
                border: `1px solid ${secondaryBlue}`,
                borderWidth: '0 2 2 2',
                ...style,
            }}
            round={round}
        >
            <div className='col s12'>
                <div className='padding-top-1 valign-wrapper'>
                    <i className='material-icons margin-right-1' style={iconStyle}>{contributor ? 'domain' : 'person'}</i>
                    <span>{intervenant.name || ''}</span>
                </div>
                {address && (
                    <div className='padding-top-1 valign-wrapper'>
                        <i className='material-icons margin-right-1' style={iconStyle}>room</i>
                        <span>{address}</span>
                    </div>
                )}
                {addressComplement && (
                    <div className='padding-top-1 valign-wrapper'>
                        <i className='material-icons margin-right-1' style={iconStyle}/>
                        <span>{addressComplement}</span>
                    </div>
                )}
                <div className='padding-top-1 valign-wrapper'>
                    <i className='material-icons margin-right-1' style={iconStyle}/>
                    <span>{(intervenant.cedex ? `${postalCode || ''} ${city.name || ''} ${intervenant.cedex || ''} [${city.code || ''}]` : `${postalCode || ''} ${city.labelWithCode || ''}`) || ''}</span>
                </div>
                <div className='padding-top-1 valign-wrapper'>
                    <i className='material-icons margin-right-1' style={iconStyle}>phone</i>
                    <span>{formatPhone(intervenant.phoneTel) || ''}</span>
                </div>
                <div className='padding-top-1 valign-wrapper'>
                    <i className='material-icons margin-right-1' style={iconStyle}>smartphone</i>
                    <span>{formatPhone(intervenant.mobile) || ''}</span>
                </div>
                <div className='padding-top-1 valign-wrapper'>
                    <i className='material-icons margin-right-1' style={iconStyle}>print</i>
                    <span>{formatPhone(contributor ? intervenant.fax : intervenant.desktopTel) || ''}</span>
                </div>
                <div className='padding-top-1 valign-wrapper'>
                    <i className='material-icons margin-right-1' style={iconStyle}>mail_outlined</i>
                    <span>{intervenant.email || ''}</span>
                </div>
            </div>
        </Card>
    )
}

IntervenantCard.propTypes = {
    title: PropTypes.string.isRequired,
    contributor: PropTypes.bool,
    round: PropTypes.bool,
    actions: PropTypes.arrayOf(PropTypes.shape({
        onClick: PropTypes.func,
        iconName: PropTypes.string,
    })),
    intervenant: PropTypes.oneOfType([
        PropTypes.instanceOf(ContributorDto),
        PropTypes.object,
    ]).isRequired,
    cities: PropTypes.arrayOf(PropTypes.instanceOf(CityDto)),
    style: PropTypes.shape({}),
}

export default IntervenantCard