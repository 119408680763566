import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import IAction from './IAction'

class DeleteCheck extends IAction {
    fn = () => {
        return this.props.onDelete
    }
    icon = () => {
        return 'delete'
    }
    label = () => {
        return i18n.delete
    }
    id = () => {
        return 'delete_check_action_navbar'
    }
}

DeleteCheck.propTypes = {
    onDelete: PropTypes.func.isRequired,
}

export default DeleteCheck