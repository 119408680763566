import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { getFullDate } from 'utils/DateUtil'
import CardList from '../../../../components/list/cardList/CardList'
import AlertSitePanel from '../../map/AlertSitePanel'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import PiezometryAction from '../../../../piezometry/actions/PiezometryAction'
import PluviometryAction from '../../../../pluviometry/actions/PluviometryAction'
import HydrometryAction from '../../../../hydrometry/actions/HydrometryAction'
import CityAction from '../../../../referencial/components/city/actions/CityAction'


const PointPanel = ({
    title = '',
    sites = [],
    onToggle = () => {},
    onClick = () => {},
}) => {
    const dispatch = useDispatch()

    const {
        piezometryDataTypes,
        pluviometryDataTypes,
        hydrometryDataTypes,
        cities,
    } = useSelector(store => ({
        piezometryDataTypes: store.PiezometryReducer.piezometryDataTypes,
        pluviometryDataTypes: store.PluviometryReducer.pluviometryDataTypes,
        hydrometryDataTypes: store.HydrometryReducer.hydrometryDataTypes,
        cities: store.CityReducer.cities,
    }), shallowEqual)

    useEffect(() => {
        if (!piezometryDataTypes.length) {
            dispatch(PiezometryAction.fetchPiezometryDataTypes())
        }

        if (!pluviometryDataTypes.length) {
            dispatch(PluviometryAction.fetchPluviometryDataTypes())
        }

        if (!hydrometryDataTypes.length) {
            dispatch(HydrometryAction.fetchHydrometryDataTypes())
        }

        if (!cities.length) {
            dispatch(CityAction.fetchCities())
        }
    }, [dispatch])

    return (
        <div>
            <CardList
                collapsible
                data={[
                    {
                        title,
                        titleColor: '',
                        cards: sites.map(s => ({
                            content: (
                                <div>
                                    <div className='row no-margin no-padding valign-wrapper'>
                                        <div className={'col s12 no-padding'}>
                                            <AlertSitePanel
                                                onClick={onClick}
                                                divider={false}
                                                site={s}
                                                measureDate={getFullDate(s.measureDate)}
                                                onToggle={() => onToggle(s)}
                                            />
                                        </div>
                                    </div>
                                    <div className='divider'/>
                                </div>
                            ),
                            color: s.headband ? s.headband : 'WHITE',
                        })),
                    },
                ]}
            />
        </div>
    )
}

PointPanel.propTypes = {
    title: PropTypes.string,
    color: PropTypes.string,
    titleColor: PropTypes.string,
    sites: PropTypes.arrayOf(PropTypes.object),
    showSymbol: PropTypes.bool,
    onToggle: PropTypes.func,
    onClick: PropTypes.func,
    maxHeight: PropTypes.string,
}

export default PointPanel
