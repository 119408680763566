module.exports = {
    RECEIVE_CITY_COUNT: 'RECEIVE_CITY_COUNT',
    RECEIVE_CONTACT_COUNT: 'RECEIVE_CONTACT_COUNT',
    RECEIVE_NETWORK_COUNT: 'RECEIVE_NETWORK_COUNT',
    RECEIVE_FRACTION_COUNT: 'RECEIVE_FRACTION_COUNT',
    RECEIVE_CONTRIBUTOR_COUNT: 'RECEIVE_CONTRIBUTOR_COUNT',
    RECEIVE_WATERMASS_COUNT: 'RECEIVE_WATERMASS_COUNT',
    RECEIVE_METHOD_COUNT: 'RECEIVE_METHOD_COUNT',
    RECEIVE_PARAMETER_COUNT: 'RECEIVE_PARAMETER_COUNT',
    RECEIVE_SUPPORT_COUNT: 'RECEIVE_SUPPORT_COUNT',
    RECEIVE_UNIT_COUNT: 'RECEIVE_UNIT_COUNT',
    RECEIVE_HYDROGEOLOGICALENTITY_COUNT: 'RECEIVE_HYDROGEOLOGICALENTITY_COUNT',
    RECEIVE_WATERSHED_COUNT: 'RECEIVE_WATERSHED_COUNT',
    RECEIVE_TAXON_COUNT: 'RECEIVE_TAXON_COUNT',
    RECEIVE_AQUIFER_COUNT: 'RECEIVE_AQUIFER_COUNT',
    RECEIVE_CULTURE_COUNT: 'RECEIVE_CULTURE_COUNT',
    RECEIVE_CULTURES_FAMILY_COUNT: 'RECEIVE_CULTURES_FAMILY_COUNT',
    RECEIVE_ZONE_COUNT: 'RECEIVE_ZONE_COUNT',
    RECEIVE_DRIVE_MODE_COUNT: 'RECEIVE_DRIVE_MODE_COUNT',
    RECEIVE_ACTIVITY_COUNT: 'RECEIVE_ACTIVITY_COUNT',
    RECEIVE_COUNTRY_COUNT: 'RECEIVE_COUNTRY_COUNT',
    RECEIVE_USAGE_COUNT: 'RECEIVE_USAGE_COUNT',
    RECEIVE_RPG_COUNT: 'RECEIVE_RPG_COUNT',
    RECEIVE_SECTOR_COUNT: 'RECEIVE_SECTOR_COUNT',
}
