import { chunk, drop, flatten, flattenDeep, orderBy, take, uniq } from 'lodash'
import { PiezometryActionConstant } from 'piezometry/reducers/PiezometryReducer'
import { ProductionUnitActionConstant } from 'productionUnit/reducers/ProductionUnitReducer'
import { QualityActionConstant } from 'quality/reducers/QualityReducer'
import i18n from 'simple-react-i18n'
import StationAction from 'station/actions/StationAction'
import ToastrAction from 'toastr/actions/ToastrAction'
import WaitAction from 'wait/WaitAction'
import ApplicationConf from '../../conf/ApplicationConf'
import LogAction from '../../log/actions/LogAction'
import PiezometryAction from '../../piezometry/actions/PiezometryAction'
import QualityAction from '../../quality/actions/QualityAction'
import PiezometerStationAction from '../../station/actions/PiezometerStationAction'
import AppStore from '../../store/AppStore'
import { checkAuth, checkError, genericPromise, getAuthorization, getJson, promiseAllProgress } from '../../utils/ActionUtils'
import { hasValue } from '../../utils/NumberUtil'
import { canShowStationTypeData } from '../../utils/SettingUtils'
import { STATION_TYPE_CONSTANT } from 'station/constants/StationConstants'
import DtoAssociatedStation from 'station/dto/DtoAssociatedStation'

const ProductionUnitAction = {
    createProductionUnit(newProductionUnit, callback) {
        return function (dispatch) {
            dispatch(WaitAction.waitStart())
            return fetch(ApplicationConf.productionUnit.productionUnitPath(), {
                method: 'POST',
                headers: getAuthorization(),
                body: JSON.stringify(newProductionUnit),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(json => {
                    if (json.insert === 1 && json.id) {
                        dispatch(WaitAction.waitStop())
                        dispatch(ProductionUnitActionConstant.createProductionUnit({ ...newProductionUnit, id: json.id }))
                        dispatch(ToastrAction.success(i18n.elementCreateSuccess))
                        callback(json.id)
                    } else {
                        throw new Error(json)
                    }
                })
                .catch((err) => {
                    dispatch(WaitAction.waitStop())
                    dispatch(LogAction.logError(`${i18n.createError + i18n.installation} : ${err}`))
                    dispatch(ToastrAction.error(i18n.createError + i18n.installation))
                })
        }
    },
    updateProductionUnit: productionUnit => dispatch => {
        return fetch(ApplicationConf.productionUnit.productionUnitPath(), {
            method: 'PUT',
            headers: getAuthorization(),
            body: JSON.stringify(productionUnit),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
            .then(() => {
                dispatch(ProductionUnitAction.fetchProductionUnit(productionUnit.id))
                dispatch(ToastrAction.success(i18n.elementUpdateSuccess))
            })
            .catch(err => {
                dispatch(LogAction.logError(`${i18n.updateError + i18n.productionUnit} : ${err}`))
                dispatch(ToastrAction.error(i18n.updateError + i18n.productionUnit))
            })
    },
    deleteProductionUnit(id, callback) {
        return function (dispatch) {
            dispatch(WaitAction.waitStart())
            return fetch(ApplicationConf.productionUnit.productionUnitPath(), {
                method: 'DELETE',
                headers: getAuthorization(),
                body: JSON.stringify({ id }),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(json => {
                    if (json.delete === 1) {
                        dispatch(WaitAction.waitStop())
                        dispatch(ProductionUnitActionConstant.deleteProductionUnit(id))
                        dispatch(ToastrAction.success(i18n.elementDeleteSuccess))
                        callback()
                    } else {
                        throw new Error(json)
                    }
                })
                .catch((err) => {
                    dispatch(WaitAction.waitStop())
                    dispatch(LogAction.logError(`${i18n.deleteError + i18n.productionUnit} : ${err}`))
                    dispatch(ToastrAction.error(i18n.deleteError + i18n.productionUnit))
                })
        }
    },

    promiseProductionUnits() {
        return fetch(ApplicationConf.productionUnit.getAll(), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
    },
    fetchProductionUnits() {
        return (dispatch) => {
            return ProductionUnitAction.promiseProductionUnits()
                .then(json => {
                    dispatch(ProductionUnitActionConstant.receiveAllProductionUnits(json))
                })
                .catch((err) => {
                    dispatch(WaitAction.waitStop())
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.productionUnit} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError + i18n.productionUnit))
                })
        }
    },

    fetchProductionUnit(id, cb = () => {}) {
        return (dispatch) => {
            dispatch(WaitAction.waitStart())
            return fetch(ApplicationConf.productionUnit.get(id), {
                method: 'GET',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then(e => checkError(e, {
                    404: () => AppStore.dispatch(ToastrAction.error(i18n.unreachableStation)),
                }))
                .then(json => {
                    dispatch(WaitAction.waitStop())
                    dispatch(ProductionUnitActionConstant.receiveProductionUnit(json))
                    cb()
                })
                .catch((err) => {
                    dispatch(WaitAction.waitStop())
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.productionUnit} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError + i18n.productionUnit))
                })
        }
    },

    promiseProductionUnitAssociations(id) {
        return fetch(ApplicationConf.productionUnit.getAssociations(id), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
    },
    fetchProductionUnitsAssociations(code, callback = () => { }) {
        return (dispatch) => {
            return StationAction.promiseAssociatedSites(code, 5)
                .then((json = []) => {
                    dispatch(ProductionUnitActionConstant.receiveProductionUnitAssociations(json))
                    callback()
                })
        }
    },

    fetchAllProductionUnitsAssociations() {
        return (dispatch, getState) => {
            const unitIds = getState().ProductionUnitReducer.productionUnits?.map(({ id }) => id)
            return StationAction.promiseStationsAssociatedSites(unitIds, STATION_TYPE_CONSTANT.productionUnit)
                .then((json = []) => {
                    dispatch(ProductionUnitActionConstant.receiveProductionUnitsAssociations(json))
                    return json.map(pu => new DtoAssociatedStation(pu))
                })
                .catch((err) => {
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.associatedStations} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError + i18n.associatedStations))
                    return []
                })
        }
    },

    loadProductionUnitPiezometryAssociations(id, dates, allAssociations, progressCallback = () => {}, callback = () => {}, fromStationType = null) {
        return function (dispatch) {
            return PiezometryAction.promisePiezometryDataTypes().then(result1 => {
                dispatch(PiezometryActionConstant.receivePiezometryDataTypes(orderBy(result1, 'order')))
                const piezometers = allAssociations.filter(association => association.stationLinkedType === 1).map(association => parseInt(association.stationLinkedId))
                if (piezometers.length) {
                    const catchError = () => {
                        dispatch(ToastrAction.error(i18n.loadError))
                        return []
                    }
                    const dataTypes = orderBy(result1, 'order').map(t => t.id)
                    const usedDataTypes = !fromStationType ? dataTypes : dataTypes.filter(d => canShowStationTypeData(fromStationType, 'PIEZO', d))
                    const promises1 = piezometers.reduce((acc, piezometerId) => {
                        acc.push(PiezometerStationAction.promisePiezoChartMeasures({ stationId: piezometerId, ...dates, dataType: -1, groupFunc: 'MAX', displayCote: 1 }).catch(catchError))
                        return acc
                    }, [])
                    const promises2 = piezometers.reduce((acc, piezometerId) => {
                        acc.push(PiezometerStationAction.promisePiezoChartMeasures({ stationId: piezometerId, ...dates, dataType: -2, groupFunc: 'SUM' }).catch(catchError))
                        return acc
                    }, promises1)
                    const allPromises = piezometers.reduce((acc, piezometerId) => {
                        usedDataTypes.map(type => {
                            acc.push(PiezometerStationAction.promisePiezoChartMeasures({ stationId: piezometerId, ...dates, dataType: type, groupFunc: 'MAX' }).catch(catchError))
                        })
                        return acc
                    }, promises2)
                    promiseAllProgress(allPromises, progressCallback).then(jsonResults => {
                        const rawMeasuresResults = take(jsonResults, piezometers.length)
                        const samplesResults = take(drop(jsonResults, piezometers.length), piezometers.length)
                        const additionalMeasuresResults = drop(jsonResults, piezometers.length * 2)
                        dispatch(ProductionUnitActionConstant.receiveProductionUnitAssociationsRawMeasures(rawMeasuresResults.map((result, i) => ({
                            piezometerId: piezometers[i],
                            measures: result,
                        }))))
                        dispatch(ProductionUnitActionConstant.receiveProductionUnitAssociationsSamples(samplesResults.map((result, i) => ({
                            piezometerId: piezometers[i],
                            measures: result,
                        }))))
                        const byPiezometerId = chunk(additionalMeasuresResults, usedDataTypes.length)
                        const allResults = byPiezometerId.map((allTypeResults, piezometerIndex) => {
                            const piezometerId = piezometers[piezometerIndex]
                            const typeResults = allTypeResults.map((result, typeIndex) => ({ type: usedDataTypes[typeIndex], measures: result }))
                            return ({ piezometerId, measures: typeResults })
                        })
                        dispatch(ProductionUnitActionConstant.receiveProductionUnitAssociationsAdditionalMeasures(allResults))
                        callback()
                    })
                } else {
                    callback()
                }
            }).catch((err) => {
                dispatch(WaitAction.waitStop())
                dispatch(LogAction.logError(`${i18n.loadError} : ${err}`))
                dispatch(ToastrAction.error(i18n.loadError))
            })
        }
    },
    loadProductionUnitQualityAssociations(id, allAssociations, qualitometers, callback = () => {}) {
        return function (dispatch) {
            return QualityAction.promiseQualityIndicators().then(result1 => {
                dispatch(QualityActionConstant.receiveQualityIndicators(result1))
                const associations = allAssociations.filter(a => a.stationLinkedType === 3 && hasValue(a.stationLinkedCode))
                const promisesResults = associations.reduce((acc, assoc) => {
                    const found = qualitometers.find(q => q.code === assoc.stationLinkedCode)
                    if (found && hasValue(found.stationType)) {
                        const foundIndicator = result1.find(ind => ind.stationType == found.stationType)
                        if (foundIndicator) {
                            acc.push(QualityAction.promiseQualityIndicatorsResults(found.id, foundIndicator.indicators.map(i => i.id.toString())))
                        }
                    }
                    return acc
                }, [])
                const thresholds = uniq(flattenDeep(result1.map(indicator => indicator.indicators.map(i => i.threshold))))
                const promisesThresholds = thresholds.map(thresholdId => QualityAction.promiseQualityThreshold({ code: thresholdId, thresholdType: '0' }))
                promiseAllProgress(promisesResults.concat(promisesThresholds), progress => callback({ progress })).then(jsonResults => {
                    dispatch(QualityActionConstant.receiveQualityIndicatorsResults(flatten(take(jsonResults, promisesResults.length))))
                    const thresholdResults = drop(jsonResults, promisesResults.length).map((result, index) => ({
                        thresholdCode: thresholds[index],
                        thresholds: result,
                    }))
                    dispatch(QualityActionConstant.receiveStationQualityThreshold(thresholdResults))
                    callback({ dataLoaded: true })
                })
            })
        }
    },

    promiseProdUnitLinks: id => genericPromise(ApplicationConf.productionUnit.links(id)),

    fetchProductionUnitLinks: (id) => dispatch =>
        ProductionUnitAction.promiseProdUnitLinks(id)
            .then((json = []) => {
                dispatch(ProductionUnitActionConstant.receiveProductionUnitLinks(json))
            })
            .catch(err => {
                dispatch(LogAction.logError(`${i18n.fetchError} : ${err}`))
                dispatch(ToastrAction.error(i18n.fetchError))
            }),

    updateProductionUnitLinks: (id, data) => () => genericPromise(ApplicationConf.productionUnit.links(id), 'PUT', data),

    fetchProductionUnitEvents(id) {
        return (dispatch) => {
            return fetch(ApplicationConf.productionUnit.events(id), {
                method: 'GET',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(json => {
                    dispatch(ProductionUnitActionConstant.receiveProductionUnitEvents(json))
                })
                .catch(err => {
                    dispatch(WaitAction.waitStop())
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.events} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError + i18n.events))
                })
        }
    },

    fetchProductionUnitEvent(productionUnitId, eventId) {
        return dispatch => {
            return fetch(ApplicationConf.productionUnit.event(productionUnitId, eventId), {
                method: 'GET',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(json => {
                    dispatch(ProductionUnitActionConstant.receiveProductionUnitEvent(json))
                })
                .catch(err => {
                    dispatch(WaitAction.waitStop())
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.event} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError + i18n.event))
                })
        }
    },

    addProductionUnitEvent(stationId, eventData, callback) {
        return dispatch => {
            return fetch(ApplicationConf.productionUnit.events(stationId), {
                method: 'POST',
                headers: getAuthorization(),
                body: JSON.stringify(eventData),
            })
                .then(checkAuth)
                .then(getJson)
                .then(json => {
                    if (json.insert === 1 && json.id) {
                        dispatch(ProductionUnitActionConstant.createProductionUnitEvent({ ...eventData, number: json.id }))
                        callback(json.id)
                        dispatch(ToastrAction.success(i18n.eventAddSuccess))
                    } else {
                        throw new Error()
                    }
                })
                .catch(err => {
                    dispatch(WaitAction.waitStop())
                    dispatch(LogAction.logError(`${i18n.addError + i18n.event} : ${err}`))
                    dispatch(ToastrAction.error(i18n.addError + i18n.event))
                })
        }
    },

    updateProductionUnitEvent(stationId, eventId, eventData, callback = () => { }) {
        return dispatch => {
            dispatch(WaitAction.waitStart())
            return fetch(ApplicationConf.productionUnit.event(stationId, eventId), {
                method: 'PUT',
                headers: getAuthorization(),
                body: JSON.stringify(eventData),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(json => {
                    if (json.update === 1) {
                        dispatch(WaitAction.waitStop())
                        dispatch(ProductionUnitActionConstant.updateProductionUnitEvent(eventData))
                        callback()
                        dispatch(ToastrAction.success(i18n.eventUpdateSuccess))
                    } else {
                        throw new Error()
                    }
                })
                .catch(err => {
                    dispatch(WaitAction.waitStop())
                    dispatch(LogAction.logError(`${i18n.updateError + i18n.event} : ${err}`))
                    dispatch(ToastrAction.error(i18n.updateError + i18n.event))
                })
        }
    },

    deleteProductionUnitEvent(stationId, eventId, callback = () => { }) {
        return dispatch => {
            dispatch(WaitAction.waitStart())
            return fetch(ApplicationConf.productionUnit.event(stationId, eventId), {
                method: 'DELETE',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(json => {
                    if (json.delete === 1) {
                        dispatch(WaitAction.waitStop())
                        dispatch(ProductionUnitActionConstant.deleteProductionUnitEvent(eventId))
                        callback()
                        dispatch(ToastrAction.success(i18n.eventDeleteSuccess))
                    } else {
                        throw new Error()
                    }
                })
                .catch(err => {
                    dispatch(WaitAction.waitStop())
                    dispatch(LogAction.logError(`${i18n.deleteError + i18n.event} : ${err}`))
                    dispatch(ToastrAction.error(i18n.deleteError + i18n.event))
                })
        }
    },

    promiseLinkedPiezoPrel: (data) => genericPromise(ApplicationConf.piezometer.linkedPiezoPrel(), 'POST', data),

    fetchLinkedPiezoPrel(data) {
        return (dispatch) => {
            return ProductionUnitAction.promiseLinkedPiezoPrel(data)
                .then((json = {}) => {
                    dispatch(ProductionUnitActionConstant.receiveProductionUnitLinkedPiezoPrel(json))
                    return json
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.piezometerMesures} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError + i18n.piezometerMesures))
                })
        }
    },

    promiseLinkedPiezosPrel: (ids) => genericPromise(ApplicationConf.piezometer.linkedPiezosPrel(), 'POST', ids),

    fetchLinkedPiezosPrel(ids) {
        return (dispatch) => {
            return ProductionUnitAction.promiseLinkedPiezosPrel(ids)
                .then((json = {}) => {
                    dispatch(ProductionUnitActionConstant.receiveProductionUnitLinkedPiezosPrel(json))
                    return json
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.piezometerMesures} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError + i18n.piezometerMesures))
                })
        }
    },
}

export default ProductionUnitAction
