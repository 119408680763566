import fetch from 'isomorphic-fetch'
import {
    RECEIVE_ALL_WATERSHEDS,
    RECEIVE_STATION_WATERSHED,
    RECEIVE_STATION_WATERSHEDS,
    RECEIVE_WATERSHED,
    RESET_STATIONS_WITH_WATERSHED,
    RESET_WATERSHED,
    STATIONS_WITH_WATERSHED,
} from '../constants/WatershedConstants'
import ApplicationConf from 'conf/ApplicationConf'
import ToastrAction from 'toastr/actions/ToastrAction'
import WaitAction from 'wait/WaitAction'
import { checkAuth, checkError, checkPostgresAuth, getAuthorization, getJson } from '../../../../utils/ActionUtils'
import i18n from 'simple-react-i18n'
import SieauAction from '../../../../components/sieau/SieauAction'
import { push } from '@lagunovsky/redux-react-router'
import {
    PATH_REFERENCIAL_WATERSHED,
    PATH_REFERENCIAL_WATERSHED_ITEM_LINK,
} from '../../../../home/constants/RouteConstants'
import WatershedDto from '../dto/WatershedDto'
import LogAction from '../../../../log/actions/LogAction'

const WatershedAction = {
    receiveWatersheds(watersheds) {
        return { type: RECEIVE_ALL_WATERSHEDS, watersheds }
    },

    receiveWatershed(watershed) {
        return { type: RECEIVE_WATERSHED, watershed }
    },

    promiseWatersheds() {
        return fetch(ApplicationConf.referencial.watersheds(), {
            method: 'GET',
            headers: getAuthorization(),

        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
    },
    promiseWatershed(code) {
        return fetch(ApplicationConf.referencial.watershed(code), {
            method: 'GET',
            headers: getAuthorization(),

        })
            .then(checkAuth)
            .then(getJson)
    },
    fetchWatersheds() {
        return (dispatch) => {
            dispatch(SieauAction.receiveProps('watersheds'))
            return WatershedAction.promiseWatersheds()
                .then((json = []) => {
                    dispatch(WatershedAction.receiveWatersheds(json))
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.watersheds} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError + i18n.watersheds))
                })
        }
    },
    promiseStationWatersheds(station) {
        const w1 = station.watershed ? WatershedAction.promiseWatershed(station.watershed) : null
        const w2 = station.watershed2 ? WatershedAction.promiseWatershed(station.watershed2) : null
        const w3 = station.watershed3 ? WatershedAction.promiseWatershed(station.watershed3) : null
        const w4 = station.watershed4 ? WatershedAction.promiseWatershed(station.watershed4) : null
        return [w1, w2, w3, w4]
    },
    receiveStationWatersheds(w1, w2, w3, w4) {
        return { type: RECEIVE_STATION_WATERSHEDS,
            watershed1: w1 ? w1 : new WatershedDto({}),
            watershed2: w2 ? w2 : new WatershedDto({}),
            watershed3: w3 ? w3 : new WatershedDto({}),
            watershed4: w4 ? w4 : new WatershedDto({}),
        }
    },
    fetchStationWatersheds(station) {
        return (dispatch) => {
            return Promise.all(WatershedAction.promiseStationWatersheds(station))
                .then((jsonTab) => {
                    dispatch(WatershedAction.receiveStationWatersheds(jsonTab[0], jsonTab[1], jsonTab[2], jsonTab[3]))
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.watersheds} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError + i18n.watersheds))
                })
        }
    },
    fetchWatershed(code) {
        return (dispatch) => {
            return fetch(ApplicationConf.referencial.watershed(code), {
                method: 'GET',
                headers: getAuthorization(),

            })
                .then(checkAuth)
                .then(getJson)
                .then((json = []) => {
                    dispatch(WatershedAction.receiveWatershed(json))
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.watershed} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError + i18n.watershed))
                })
        }
    },

    receiveStationsWithWatershed(stations) {
        return { type: STATIONS_WITH_WATERSHED, stations }
    },

    fetchStationsWithWatershed(watershedId) {
        return (dispatch) => {
            return fetch(ApplicationConf.referencial.watershedStations(watershedId), {
                method: 'GET',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then((json = {}) => {
                    dispatch(WatershedAction.receiveStationsWithWatershed(json))
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.watershedStations} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError + i18n.watershedStations))
                })
        }
    },

    promiseStationWatershed(x, y) {
        return fetch(ApplicationConf.layers.getWatershedFromCoordinates(), {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/vnd.pgrst.object+json',
            },
            body: JSON.stringify({
                longitude: x,
                latitude: y,
            }),
        })
            .then(checkPostgresAuth)
            .then(getJson)
            .then(checkError)
    },

    receiveStationWatershed(stationWatershed) {
        return { type: RECEIVE_STATION_WATERSHED, stationWatershed }
    },

    fetchStationWatershed(x, y) {
        return (dispatch) => {
            WatershedAction.promiseStationWatershed(x, y)
                .then(json => {
                    if (json.errors) {
                        dispatch(ToastrAction.error(i18n.fetchError + i18n.watershed))
                        dispatch(LogAction.logError(`${i18n.fetchError + i18n.watershed} : ${json.errors}`))
                    }
                    dispatch(WatershedAction.receiveStationWatershed(json))
                })
                .catch(err => {
                    dispatch(WaitAction.waitStop())
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.watershed} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError + i18n.watershed))
                })
        }
    },

    updateWatershed(watershed, code) {
        return (dispatch) => {
            return fetch(ApplicationConf.referencial.saveWatershed(code), {
                method: 'PUT',
                headers: getAuthorization(),
                body: JSON.stringify(watershed),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(json => {
                    if (json.update === 1) {
                        dispatch(WatershedAction.fetchWatershed(code))
                        dispatch(ToastrAction.success(i18n.watershedSuccessUpdated))
                    } else {
                        throw new Error()
                    }
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.watershed} : ${err}`))
                    dispatch(ToastrAction.error(i18n.updateError + i18n.watershed))
                })
        }
    },

    createWatershed(watershed) {
        return (dispatch) => {
            return fetch(ApplicationConf.referencial.watersheds(), {
                method: 'POST',
                headers: getAuthorization(),
                body: JSON.stringify(watershed),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(json => {
                    if (json.id) {
                        dispatch(push(PATH_REFERENCIAL_WATERSHED_ITEM_LINK + json.id))
                        dispatch(ToastrAction.success(i18n.successfulCreation))
                    }
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.createError + i18n.watershed} : ${err}`))
                    dispatch(ToastrAction.error(i18n.createError + i18n.watershed))
                })
        }
    },

    deleteWatershed(code) {
        return (dispatch) => {
            dispatch(WaitAction.waitStart())
            return fetch(ApplicationConf.referencial.deleteWatershed(code), {
                method: 'DELETE',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then((json) => {
                    if (json.delete) {
                        dispatch(ToastrAction.success(i18n.elementDeleteSuccess))
                        dispatch(push(PATH_REFERENCIAL_WATERSHED))
                    }
                    dispatch(WaitAction.waitStop())
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.deleteError + i18n.watershed} : ${err}`))
                    dispatch(ToastrAction.error(i18n.deleteError + i18n.watershed))
                })
        }
    },

    purgeWatershed() {
        return (dispatch) => {
            dispatch(WaitAction.waitStart())
            return fetch(ApplicationConf.referencial.purgeWatershed(), {
                method: 'DELETE',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then((json) => {
                    if (json.purge || json.purge === 0) {
                        dispatch(ToastrAction.success(`${i18n.elementDeleteSuccess} ${i18n.total} : ${json.purge}`))
                        dispatch(WatershedAction.fetchWatersheds())
                    }
                    dispatch(WaitAction.waitStop())
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.deleteError + i18n.watersheds} : ${err}`))
                    dispatch(ToastrAction.error(i18n.deleteError + i18n.watersheds))
                })
        }
    },

    replaceWatershed(watershed, watershedReplace) {
        return (dispatch) => {
            dispatch(WaitAction.waitStart())
            return fetch(ApplicationConf.referencial.replaceWatershed(), {
                method: 'PUT',
                headers: getAuthorization(),
                body: JSON.stringify({
                    id: watershed,
                    replaceId: watershedReplace,
                }),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(json => {
                    if (json.update) {
                        dispatch(ToastrAction.success(i18n.watershedSuccessUpdated))
                    } else if (json.update == 0) {
                        dispatch(ToastrAction.success(`${json.update} ${i18n.replacement}`))
                    } else {
                        throw new Error()
                    }
                    dispatch(WaitAction.waitStop())
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.replace + i18n.watershed} : ${err}`))
                })
        }
    },

    resetStationsWithWatershed() {
        return { type: RESET_STATIONS_WITH_WATERSHED }
    },

    resetWatershed() {
        return { type: RESET_WATERSHED }
    },

    fetchSynchroSandreWatershed() {
        return (dispatch) => {
            dispatch(WaitAction.waitStart())
            return fetch(ApplicationConf.referencial.updateWatershed(), {
                method: 'GET',
                headers: getAuthorization(),

            })
                .then(checkAuth)
                .then(getJson)
                .then((json = {}) => {
                    if (json.insert > 0 || json.update > 0) {
                        if (json.insert > 0) {
                            dispatch(ToastrAction.success(`${i18n.insertNumber} : ${json.insert}<br/>${i18n.updateNumber} : ${json.update}`))
                        } else {
                            dispatch(ToastrAction.success(`${i18n.updateNumber} : ${json.update}`))
                        }
                        dispatch(this.fetchWatershed())
                    } else {
                        dispatch(ToastrAction.success(i18n.noUpdate))
                    }
                    dispatch(WaitAction.waitStop())
                })
                .catch((err) => {
                    dispatch(LogAction.logError(i18n.updateError + i18n.watershed + err))
                    dispatch(ToastrAction.error(i18n.updateError + i18n.city))
                })
        }
    },

    fetchImportWatershedCSV(fileContent, insertOrUpdate = true) {
        return (dispatch) => {
            return fetch(ApplicationConf.referencial.uploadCsvWatershed(), {
                method: 'POST',
                headers: getAuthorization(),
                body: JSON.stringify({ fileContent, insertOrUpdate }),
            })
                .then(checkAuth)
                .then(checkError)
                .then(getJson)
                .then((json = {}) => {
                    if (json.insertWatersheds > 0 || json.updateWatersheds > 0) {
                        dispatch(ToastrAction.success(`${i18n.watershed}<br/> ${i18n.insertNumber} : ${json.insertWatersheds}<br/>${i18n.updateNumber} : ${json.updateWatersheds}`))
                        dispatch(WatershedAction.fetchWatersheds())
                    } else {
                        dispatch(ToastrAction.success(i18n.noUpdate))
                    }
                    dispatch(WaitAction.waitStop())
                })
                .catch((err) => {
                    dispatch(LogAction.logError(i18n.updateError + i18n.watershed + err))
                    dispatch(WaitAction.waitStop())
                })
        }
    },

    fetchImportWatershedXML(fileContent, insertOrUpdate = true) {
        return (dispatch) => {
            return fetch(ApplicationConf.referencial.uploadXmlWatershed(), {
                method: 'POST',
                headers: getAuthorization(),
                body: JSON.stringify({ fileContent, insertOrUpdate }),
            })
                .then(checkAuth)
                .then(getJson)
                .then((json = {}) => {
                    if (json.insertWatersheds > 0 || json.updateWatersheds > 0) {
                        dispatch(ToastrAction.success(`${i18n.watershed}<br/> ${i18n.insertNumber} : ${json.insertWatersheds}<br/>${i18n.updateNumber} : ${json.updateWatersheds}`))
                        dispatch(WatershedAction.fetchWatersheds())
                    } else {
                        dispatch(ToastrAction.success(i18n.noUpdate))
                    }
                    dispatch(WaitAction.waitStop())
                })
                .catch((err) => {
                    dispatch(LogAction.logError(i18n.updateError + i18n.watershed + err))
                    dispatch(WaitAction.waitStop())
                })
        }
    },
}

export default WatershedAction
