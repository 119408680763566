import { Button, Grid } from '@mui/material'
import { DialogActionsMUI, DialogContentMUI, DialogMUI, DialogTitleMUI } from 'components/styled/Dialog'
import { StyledFieldSet } from 'components/StyledElements'
import PropTypes from 'prop-types'
import React, { useMemo, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import ToastrAction from 'toastr/actions/ToastrAction'
import CampaignAction from '../../../../campaign/actions/CampaignAction'
import DtoStationCampaign from '../../../../campaign/dto/DtoStationCampaign'
import Table from '../../../../components/datatable/Table'
import Select from '../../../../components/forms/Select'
import Icon from '../../../../components/icon/Icon'
import DtoPiezometer from '../../../dto/DtoPiezometer'

const PiezometryAddToCampaignModal = ({
    isOpen = false,
    onClose = () => {},
    onValidate = () => { },
    stations = [],
}) => {
    const dispatch = useDispatch()
    const {
        piezometryCampaigns,
        piezometryCampaignStations,
        citiesIndex,
        piezometers,
    } = useSelector(store => ({
        piezometryCampaigns: store.PiezometryReducer.piezometryCampaigns,
        piezometryCampaignStations: store.PiezometryReducer.piezometryCampaignStations,
        citiesIndex: store.CityReducer.citiesIndex,
        piezometers: store.PiezometryReducer.piezometersLight,
    }), shallowEqual)

    const [campaign, setCampaign] = useState()

    const campaignStations = useMemo(() => {
        return piezometryCampaignStations.filter(s => s.campaignId === campaign).map(s => {
            const station = piezometers.find(p => p.id === s.stationId) || {}
            const selected = stations.some(st => st.id === station.id)
            return {
                ...station,
                selected,
                visitOrder: s.visitOrder ?? 0,
                stationId: station.id,
                city: citiesIndex[station.townCode]?.labelWithCode,
                nullValue: selected ? <Icon icon='check_box' /> : '',
            }
        }).filter(s => !!s.stationId)
    }, [campaign, citiesIndex, piezometers, piezometryCampaignStations, stations])

    const toAddStations = useMemo(() => {
        return stations.filter(s => !campaignStations.some(cs => cs.id === s.id)).map(s => ({
            ...s,
            selected: true,
            visitOrder: 0,
            city: citiesIndex[s.townCode]?.labelWithCode,
            nullValue: (<Icon icon='check_box' />),
        }))
    }, [campaignStations, citiesIndex, stations])

    const onClosePopin = () => {
        setCampaign()
        onClose()
    }

    const onValidatePopin = () => {
        if (!campaign) {
            dispatch(ToastrAction.toastrInfo(i18n.pleaseSelectCampaign))
        } else {
            const newStations = [...campaignStations, ...toAddStations].map(s => new DtoStationCampaign({
                campaignId: campaign,
                stationId: s.id,
                visitOrder: s.visitOrder,
            }))
            dispatch(CampaignAction.updateCampaignStations(newStations, 'piezometry', campaign))
                .then(() => {
                    dispatch(CampaignAction.fetchPiezometryCampaigns())
                    setCampaign()
                    onValidate()
                })
        }
    }

    return (
        <DialogMUI
            fullWidth
            maxWidth='xl'
            open={isOpen}
        >
            <DialogTitleMUI>
                <Grid container justifyContent='space-between' alignItems='center' style={{ padding: '0 20' }}>
                    <Grid item >
                        {i18n.addToCampaign}
                    </Grid>
                    <Grid item>
                        <Icon style={{ color: 'white' }} size='small' icon='close' onClick={onClosePopin} />
                    </Grid>
                </Grid>
            </DialogTitleMUI>
            <DialogContentMUI>
                <div className='row no-margin' style={{ height: '65vh' }}>
                    <StyledFieldSet style={{ padding: '5 8 10 8' }}>
                        <Select
                            label={i18n.pleaseSelectCampaign}
                            options={piezometryCampaigns}
                            value={campaign}
                            onChange={setCampaign}
                            nullLabel='&nbsp;'
                        />
                    </StyledFieldSet>
                    <div className='row no-margin padding-top-1'>
                        <div className='col s6'>
                            <Table
                                data={toAddStations}
                                sortable
                                orderable
                                title={i18n.newStations}
                                maxHeight='100%'
                                type={{ headers: ['code', 'name', 'city', 'nullValue'] }}
                                condensed
                            />
                        </div>
                        <div className='col s6'>
                            <Table
                                data={campaignStations}
                                sortable
                                orderable
                                title={i18n.campaign}
                                maxHeight='100%'
                                type={{ headers: ['code', 'name', 'city', 'nullValue'] }}
                                condensed
                            />
                        </div>
                    </div>
                </div>
            </DialogContentMUI>
            <DialogActionsMUI>
                <Grid item xs={1}>
                    <Button
                        onClick={onValidatePopin}
                        variant='contained'
                    >
                        {i18n.validate}
                    </Button>
                </Grid>
            </DialogActionsMUI>
        </DialogMUI>
    )
}

PiezometryAddToCampaignModal.propTypes = {
    stations: PropTypes.arrayOf(PropTypes.instanceOf(DtoPiezometer)),
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
    onValidate: PropTypes.func,
}

export default PiezometryAddToCampaignModal
