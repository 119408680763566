import { uniq } from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import ToastrAction from 'toastr/actions/ToastrAction'
import Checkbox from '../../../../components/forms/Checkbox'
import RadioButtons from '../../../../components/forms/RadioButtons'
import Select from '../../../../components/forms/Select'
import SimpleDatePicker from '../../../../components/forms/SimpleDatePicker'
import ContributorAction from '../../../../referencial/components/contributor/actions/ContributorAction'
import ContributorDto from '../../../../referencial/components/contributor/dto/ContributorDto'
import AppStore from '../../../../store/AppStore'
import Job from '../../../dto/DtoJob'
import { integrationModeHelpIcon } from '../../../utils/ImportUtils'

import FractionDto from '../../../../referencial/components/fraction/dto/FractionDto'
import FractionAction from '../../../../referencial/components/fraction/actions/FractionAction'
import MultiContributorsAutocomplete from 'referencial/components/contributor/components/MultiContributorsAutocomplete'
import { getIntegrationModes, getImportMode } from '../../../../utils/JobUtils'
import { Buffer } from 'buffer'
import { exportFile } from '../../../../utils/ExportDataUtil'
import { nbPerPageLabelMedium } from '../../../../referencial/constants/ReferencialConstants'
import { CardTable } from '../../../../components/datatable/NewTable'

class TapWaterPanel extends Component {
    componentDidMount() {
        const filters = this.getFilters()
        this.onChangeFilters({ codes: filters.codes })
        if (!this.props.contributors.length) {
            this.props.fetchContributors()
        }
        if (!this.props.fractions.length) {
            this.props.fetchFractions()
        }
    }

    onDeleteCode = (s) => {
        if (this.props.isEditMode) {
            const filters = this.getFilters()
            this.onChangeFilters({ codes: filters.codes.filter(p => p !== s.credentials) })
        }
    }

    addBssCode = () => {
        if (this.props.isEditMode) {
            const filters = this.getFilters()
            const code = this.refs.txtAddBssCode.value
            const exist = filters.codes.find(p => p.toLowerCase() == code.toLowerCase())
            if (!code) {
                AppStore.dispatch(ToastrAction.error(i18n.youMustEnterAtLeastOneBssCode))
            } else if (exist) {
                AppStore.dispatch(ToastrAction.error(i18n.youHaveAlreadyEnteredThisBssCode))
            } else {
                this.onChangeFilters({ codes: [ code, ...filters.codes ] })
                this.refs.txtAddBssCode.value = ''
            }
        }
    }

    handleChangeDataIntegration = integration => {
        const { parameters } = this.props.job
        const newDataTypes = (() => {
            const data = parameters.dataTypes.filter(d => !d.includes('DATA'))
            if (integration) {
                return [...data, integration]
            }
            return data
        })()
        this.props.onChangeDataTypes(newDataTypes)
    }

    getDataTypes = type => {
        const { parameters } = this.props.job
        if (parameters.dataTypes && parameters.dataTypes.length) {
            return parameters.dataTypes.find(d => d.includes(type)) || parameters.dataTypes.find(d => d.includes('data')) || parameters.dataTypes
        }
        return ''
    }


    onChangeParameter = obj => {
        const newParameter = {
            ...this.state,
            ...obj,
        }
        this.setState(obj)
        this.props.handleChangeParameters([JSON.stringify(newParameter)])
    }

    onChangeBssFile = e => {
        if (this.props.isEditMode) {
            const reader = new FileReader()
            const file = e.target.files[0]
            reader.onload = upload => {
                const split = upload.target.result.split(',')
                if (split[0].indexOf('text/csv') > 0 || split[0].indexOf('vnd.ms-excel') > 0) {
                    const buf = Buffer.from(split[1], 'base64').toString()
                    const codes = buf.replace(/;/g, '').split('\n').map(b => b.trim())
                    const filteredCodes = codes.filter((c) => c !== '')
                    const filters = this.getFilters()
                    this.onChangeFilters({ codes: uniq([ ...filters.codes, ...filteredCodes ]) })
                } else {
                    AppStore.dispatch(ToastrAction.error(i18n.theSelectedFileMustBeInCsvFormat))
                }
                this.refs.importFile.value = ''
            }
            reader.readAsDataURL(file)
        }
    }

    getStations = () => {
        return this.getFilters().codes.map((v) => ({ credentials: v }))
    }

    onChangeFilters = (changes) => {
        const parameters = {
            ...this.props.job.parameters,
            filters: [JSON.stringify({
                ...this.getFilters(),
                ...changes,
            })],
        }
        this.props.onChangeJob({ parameters })
    }

    getFilters = () => {
        const newFilters = this.props.job.parameters.filters.length ? JSON.parse(this.props.job.parameters.filters[0]) : {}
        return { codesType: 'stationCodes', stationTypes: ['CAP', 'TTP', 'UDI'], codes: [], beginDate: undefined, endDate: undefined, ...newFilters }
    }

    getHistoPurge = () => {
        const parameter = this.props.job.parameters.parameters.length ? JSON.parse(this.props.job.parameters.parameters[0]) : {}
        return parameter.histoPurge
    }

    render() {
        const disabled = { disabled: !this.props.isEditMode }
        const codesTypes = [
            { code: 'stationCodes', name: i18n.stations },
            { code: 'townCodes', name: i18n.cities },
            { code: 'departmentCodes', name: i18n.departments },
        ]

        const filters = this.getFilters()
        const myData =this.getStations()
        return (
            <div>
                <br />
                <div className='row no-margin valign-wrapper'>
                    <RadioButtons col={ 3 } title={ i18n.codificationType } elements={ codesTypes } onChange={ v => this.onChangeFilters({ codesType: v }) } selected={ filters.codesType } {...disabled}/>
                    <Checkbox col={ 3 } checked={ filters.stationTypes.includes('CAP') } label={ i18n.capture } onChange={ v => this.onChangeFilters({ stationTypes: v ? [...filters.stationTypes, 'CAP'] : filters.stationTypes.filter(c => c !== 'CAP') }) } {...disabled}/>
                    <Checkbox col={ 3 } checked={ filters.stationTypes.includes('TTP') } label={ i18n.treatmentProduction } onChange={ v => this.onChangeFilters({ stationTypes: v ? [...filters.stationTypes, 'TTP'] : filters.stationTypes.filter(c => c !== 'TTP') }) } {...disabled}/>
                    <Checkbox col={ 3 } checked={ filters.stationTypes.includes('UDI') } label={ i18n.distributionUnit } onChange={ v => this.onChangeFilters({ stationTypes: v ? [...filters.stationTypes, 'UDI'] : filters.stationTypes.filter(c => c !== 'UDI') }) } {...disabled}/>
                </div>
                <br />
                <div className='row no-margin valign-wrapper'>
                    <Select
                        value={getImportMode(this.getDataTypes('DATA'))}
                        options={getIntegrationModes()}
                        label={ i18n.dataIntegration }
                        labelSpan={ integrationModeHelpIcon() }
                        col={4}
                        onChange={this.handleChangeDataIntegration}
                        {...disabled}
                    />
                    <Checkbox col={ 4 } checked={ filters.stationIntegration } label={ i18n.integrateStationInfo } onChange={ v => this.onChangeFilters({ stationIntegration: v }) } { ...disabled }/>
                    <MultiContributorsAutocomplete
                        col={4}
                        multiple
                        options={this.props.contributors}
                        values={filters.excludedProducers}
                        label={ i18n.producersExcluded }
                        onChange={values => {
                            this.onChangeFilters({ excludedProducers: values })
                        }}
                        {...disabled}
                    />
                </div>
                <div className='row no-margin valign-wrapper'>
                    <div className='col s3 no-margin'>
                        <SimpleDatePicker
                            onChange={(v) => this.onChangeFilters({ beginDate: v === '' ? undefined : v })}
                            id='beginDate'
                            label={i18n.startDate}
                            obligatory
                            value={filters.beginDate}
                            col={12}
                            {...disabled}
                        />
                    </div>
                    <div className='col s3 no-margin'>
                        <SimpleDatePicker
                            onChange={(v) => this.onChangeFilters({ endDate: v === '' ? undefined : v })}
                            id='endDate'
                            col={12}
                            obligatory
                            label={i18n.endDate}
                            value={filters.endDate}
                            {...disabled}
                        />
                    </div>
                    <div className='col s3 no-margin'>
                        <MultiContributorsAutocomplete
                            options={this.props.contributors}
                            label={i18n.producer}
                            onChange={(id) => this.onChangeFilters({ producer: id === '' ? undefined : id })}
                            values={filters.producer}
                            keyLabel='labelDisplay'
                            displayWithCode
                            {...disabled}
                        />
                    </div>
                    <div className='col s3 no-margin'>
                        <MultiContributorsAutocomplete
                            options={this.props.contributors}
                            label={i18n.laboratory}
                            onChange={(id) =>this.onChangeFilters({ labo: id === '' ? undefined : id })}
                            values={filters.labo}
                            keyLabel='labelDisplay'
                            displayWithCode
                            {...disabled}
                        />
                    </div>
                    <div className='col s3 no-margin'>
                        <Select
                            options={this.props.fractions}
                            label={i18n.fraction}
                            onChange={(id) => this.onChangeFilters({ fraction: id === '' ? undefined : id })}
                            value={filters.fraction}
                            nullLabel='&nbsp;'
                            keyLabel='labelDisplay'
                            displayWithCode
                            {...disabled}
                        />
                    </div>
                </div>
                <div>
                    <div className='row no-margin valign-wrapper'>
                        <div className='col s3'>
                            <label>{ i18n.importFile }</label>
                        </div>
                        <div className='file-field col s9 no-padding input-field'>
                            <div className='col s3 no-padding btn'>
                                <span>{ i18n.importLabel }</span>
                                <input
                                    type='file'
                                    ref='importFile'
                                    accept='.csv'
                                    onChange={ this.onChangeBssFile }
                                    {...disabled}
                                />
                            </div>
                            <div className='file-path-wrapper col s9'>
                                <input
                                    className='file-path'
                                    ref='fileInput'
                                    type='text'
                                    placeholder={ i18n.selectFile }
                                    {...disabled}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='row valign-wrapper'>
                        <div className='col s3'>
                            <label>{ i18n.addCredential }</label>
                        </div>
                        <div className='input-field col s9 no-padding'>
                            <div className='col s8'>
                                <input id='txtAddBssCode' type='text' placeholder={ i18n.BSSExample } ref='txtAddBssCode' {...disabled} />
                                <label className='tinyLabel'>{ i18n.theCodeMustBePresentInTheRepositoryAndDefinedByTheCode }</label>
                            </div>
                            <a
                                className='waves-effect waves-light btn col offset-s1 s3'
                                onClick={ this.addBssCode }
                                {...disabled}
                            >
                                { i18n.add }
                            </a>
                        </div>
                    </div>
                    <div className='row valign-wrapper'>
                        <div className='input-field col s12'>
                            <CardTable
                                rows={ myData }
                                headers={ ['credentials']}
                                title={ i18n.credentials }
                                lineActions={[{
                                    icon: 'delete',
                                    onClick: (s) => this.onDeleteCode(s),
                                    displayed: this.props.isEditMode,
                                }]}
                                rowsPerPageOptions={ nbPerPageLabelMedium }
                                data-cy='TapWater_table_body'
                                actions={[{
                                    icon: 'download',
                                    onClick: () => exportFile({
                                        data: [{ ...(myData[0]), headers: ['credentials'] }, ...(myData.slice(1, myData.length))],
                                        titleFile: i18n.credentials,
                                    }),
                                    tooltip: i18n.download,
                                }]}
                                displayHeaders={false}
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

TapWaterPanel.propTypes = {
    job: PropTypes.instanceOf(Job).isRequired,
    isEditMode: PropTypes.bool,
    onChangeJob: PropTypes.func,
    handleChangeParameters: PropTypes.func.isRequired,
    contributors: PropTypes.arrayOf(PropTypes.instanceOf(ContributorDto)),
    fractions: PropTypes.arrayOf(PropTypes.instanceOf(FractionDto)),
    fetchContributors: PropTypes.func,
    fetchFractions: PropTypes.func,
    onChangeDataTypes: PropTypes.func.isRequired,
}

const mapStateToProps = store => ({
    contributors: store.ContributorReducer.contributors,
    fractions: store.FractionReducer.fractions,
})

const mapDispatchToProps = {
    fetchContributors: ContributorAction.fetchContributors,
    fetchFractions: FractionAction.fetchFractions,
}

export default connect(mapStateToProps, mapDispatchToProps)(TapWaterPanel)
