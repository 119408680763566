import { Grid } from '@mui/material'
import Checkbox from 'components/forms/Checkbox'
import SimpleTextArea from 'components/forms/SimpleTextArea'
import PropTypes from 'prop-types'
import React from 'react'
import i18n from 'simple-react-i18n'
import { hasValue } from 'utils/NumberUtil'
import Input from '../../../../components/forms/Input'
import { getDate } from '../../../../utils/DateUtil'
import { onChangeDate } from '../../../../utils/FormUtils'
import DtoVisit from '../dto/DtoVisit'

const InstVisitInfos = ({
    visit = {},
    readMode = true,
    onChange = () => { },
    cmsSurveyId = null,
}) => {
    return (
        <Grid container className='padding-1'>
            <Grid item xs={12} className='padding-top-1 padding-bottom-1'>
                <Grid item xs={3}>
                    <Input
                        title={i18n.visitDate}
                        value={getDate(visit.visitDate)}
                        onChange={v => onChangeDate(v, v2 => onChange({ visitDate: v2 }))}
                        disabled={readMode}
                    />
                </Grid>
            </Grid>
            <Grid item xs={12} className='padding-top-1 padding-bottom-1'>
                <Grid item xs={6}>
                    <Input
                        title={i18n.mainTechnician}
                        value={visit.technicianLogin}
                        onChange={v => onChange({ technicianLogin: v })}
                        disabled={readMode}
                    />
                </Grid>
            </Grid>
            <Grid item xs={12} className='margin-bottom-1'>
                <SimpleTextArea
                    title={i18n.otherTechnicians}
                    value={visit.otherTechnicians}
                    onChange={v => onChange({ otherTechnicians: v })}
                    disabled={readMode}
                />
            </Grid>
            <Grid item xs={12} className='margin-bottom-1'>
                <SimpleTextArea
                    title={i18n.participants}
                    value={visit.participants}
                    onChange={v => onChange({ participants: v })}
                    disabled={readMode}
                />
            </Grid>
            <Grid item xs={12} className='padding-top-1 padding-bottom-1'>
                {!!(hasValue(cmsSurveyId) && visit.visitDate && cmsSurveyId >= 0) && <Checkbox
                    label={i18n.visitConcernPgsseDiagnosis}
                    checked={hasValue(visit.idSurvey)}
                    onChange={v => {
                        if (v) {
                            onChange({ idSurvey: cmsSurveyId })
                        } else {
                            onChange({ idSurvey: null })
                        }
                    }}
                    disabled={readMode}
                />}
            </Grid>
        </Grid>
    )
}

InstVisitInfos.propTypes = {
    visit: PropTypes.instanceOf(DtoVisit),
    onChange: PropTypes.func,
    readMode: PropTypes.bool,
    cmsSurveyId: PropTypes.number,
}

export default InstVisitInfos