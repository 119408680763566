export default class DtoInstallationExploitation {
    constructor(obj) {
        this.idExploitation = obj.idExploitation // Long,
        this.idInstallation = obj.idInstallation // Option[Long],
        this.startDate = obj.startDate // Option[DateTime],
        this.endDate = obj.endDate // Option[DateTime],
        this.contributorType = obj.contributorType // Option[Int],
        this.contactCode = obj.contactCode // Option[Long],
        this.stateCode = obj.stateCode // Option[Long],
        this.distribution = obj.distribution // Option[Double]
    }
}