import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import IAction from './IAction'

class Duplicate extends IAction {
    fn = () => {
        return this.props.onDuplicate
    }
    icon = () => {
        return 'control_point_duplicate'
    }
    label = () => {
        return i18n.duplicate
    }
    id = () => {
        return 'duplicate_action_navbar'
    }
}

Duplicate.propTypes = {
    onDuplicate: PropTypes.func.isRequired,
}

export default Duplicate
