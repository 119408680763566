import DtoReferencialInt from '../dto/DtoReferencialInt'
import { getStatusInformation, getStatusInformationExport } from './StatusUtils'
import i18n from 'simple-react-i18n'
import { sandreGroundwaterPointLink, sandreMarineEnvironmentLink, sandreWatercourseLink, sandreWatermassLink } from 'conf/SieauConstants'
import { hasValue } from 'utils/NumberUtil'

const createCodeInformation = (name, value) => {
    return `${name} [${value}]`
}

const getReferencialComponent = (list) => {
    return list.map((o) => {
        const nameCode = (() => {
            const result = o.name
            if (o.id) {
                return createCodeInformation(result, o.id)
            }
            return createCodeInformation(result, o.code)
        })()
        return {
            id: o.id,
            code: o.code,
            name: nameCode,
        }
    })
}

const getCedexList = (cityCodeContact, cedexList) => {
    if (cityCodeContact) {
        const existCedex = cedexList.filter(c => c.cityCode === cityCodeContact.toString())
        if (existCedex.length > 1) {
            return existCedex.map(c => {
                return new DtoReferencialInt({
                    code: c.cedex,
                    name: c.cedex,
                })
            })
        }
        return ''
    }
    return ''
}

const getExportReferencialData = (referencial) => {
    return referencial.map(h => {
        return {
            ...h,
            status: h.status ? getStatusInformationExport(h.status.toString()) : i18n.validated,

        }
    })
}

const getReferencialData = (referencial) => {
    return referencial.map(h => {
        return {
            ...h,
            status: h.status ? getStatusInformation(h.status.toString()) : '', // default i18n.validated ?
        }
    })
}


const getParametersType = () => [i18n.quantitative, i18n.qualitative]
const getParametersNature = () => [i18n.chimical, i18n.microbiological, i18n.physical, i18n.hydrobiological, i18n.environmental, i18n.synthesis]

const getWatermassLinkByType = (type) => {
    switch (type) {
        case 0:
            return sandreGroundwaterPointLink
        case 1:
        case 3:
        case 5:
        case 6:
            return sandreWatercourseLink
        case 2:
            return sandreWatermassLink
        case 4:
            return sandreMarineEnvironmentLink
        default:
            return ''
    }
}

// full code or europeanCode without the first two characters
const getWatermassLink = (code, type) => {
    return getWatermassLinkByType(type) + code
}

const checkAbillitation = (user, validCallback = () => {}, errorCallback = () => {}) => {
    if (user && hasValue(user.login)) {
        if (user.metadata === '1' || user.isAdmin === '1') {
            validCallback()
        } else {
            errorCallback()
        }
    }
}

export { getReferencialComponent, getCedexList, getExportReferencialData, getReferencialData, getParametersType, getParametersNature, getWatermassLink, checkAbillitation }