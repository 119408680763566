import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import Input from '../../../../../components/forms/Input'
import NumberField from '../../../../../components/forms/NumberField'
import Select from '../../../../../components/forms/Select'
import DtoAquifer from '../../../../../referencial/components/aquifers/dto/DtoAquifer'
import { arrayOf } from '../../../../../utils/StoreUtils'
import DtoBoreholeAquifer from '../dto/DtoBoreholeAquifer'

class BoreholeAquiferModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            boreholeAquifer: { ...props.boreholeAquifer, idStation: props.id, order: 1 },
        }
    }

    componentDidUpdate(prevProps) {
        if (!prevProps.popupIsOpen && this.props.popupIsOpen) {
            $('#descriptionModal').on('click', this.onSave)
        }
    }
    onSave = () => {
        this.props.saveResult(new DtoBoreholeAquifer(this.state.boreholeAquifer))
        $('.modal').modal('close')
    }

    onChangeElement = (changes) => this.setState({ boreholeAquifer: { ...this.state.boreholeAquifer, ...changes } })

    render() {
        const { boreholeAquifer } = this.state
        return (
            <div>
                <div className='row no-margin padding-top-1'>
                    <NumberField col={ 2 } value={ boreholeAquifer.depth } title={ i18n.depth } onChange={ v => this.onChangeElement({ depth: v }) } floatValue/>
                    <Select col={ 4 } value={ boreholeAquifer.aquiferCode } label={ i18n.aquifer } options={ this.props.aquifers }
                        onChange={ v => this.onChangeElement({ aquiferCode: v }) }
                    />
                    <Input col={ 6 } value={ boreholeAquifer.comment } title={ i18n.comment } onChange={ v => this.onChangeElement({ comment: v }) } />
                </div>
            </div>
        )
    }
}

BoreholeAquiferModal.propTypes = {
    boreholeAquifer: PropTypes.instanceOf(DtoBoreholeAquifer),
    id: PropTypes.number,
    popupIsOpen: PropTypes.bool,
    saveResult: PropTypes.func,
    aquifers: arrayOf(DtoAquifer),
}


const mapStateToProps = store => ({
    popupIsOpen: store.SieauReducer.popupIsOpen,
    aquifers: store.AquiferReducer.aquifers,
})

BoreholeAquiferModal.defaultProps = {
    boreholeAquifer: {},
}

export default connect(mapStateToProps)(BoreholeAquiferModal)