import Input from 'components/forms/Input'
import { StyledFieldSet, StyledLegend } from 'components/StyledElements'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import i18n from 'simple-react-i18n'
import Select from '../../../../components/forms/Select'
import Textarea from '../../../../components/forms/Textarea'
import SuperAdminAction from '../actions/SuperAdminAction'
import DtoResult from '../dto/DtoResult'
import { Button } from '@mui/material'
import DtoPreparedRequest from '../dto/DtoPreparedRequest'
import Row from '../../../../components/react/Row'
import Table from '../../../../components/datatable/Table'
import { exportFile } from '../../../../utils/ExportDataUtil'
import ToastrAction from '../../../../toastr/actions/ToastrAction'
import AppStore from '../../../../store/AppStore'
import { nbPerPageLabel } from '../../../../referencial/constants/ReferencialConstants'
import { uniq } from 'lodash'
import SimpleDatePicker from '../../../../components/forms/SimpleDatePicker'
import NumberField from '../../../../components/forms/NumberField'
import { hasValue } from '../../../../utils/NumberUtil'
import moment from 'moment'

class SuperAdminReqPanel extends Component {
    constructor(props) {
        super(props)
        this.state = {
            request: '',
            token: '',
            preparedRequests: [],
            selectedRequest: null,
            number: '',
            name: '',
            description: '',
            results: [],
            resultsHeaders: [],
            params: {},
        }
    }


    execRequest = () => {
        const { request, token } = this.state
        if (request && token) {
            if (request.toUpperCase().startsWith('SELECT')) {
                SuperAdminAction.execSelect(request, token).then(results => {
                    if (!results.resultat.length) {
                        AppStore.dispatch(ToastrAction.info(i18n.noResults))
                    } else {
                        AppStore.dispatch(ToastrAction.success('Requête exécutée avec succès.'))
                    }
                    this.setState({ results: results.resultat, resultsHeaders: request.toLowerCase().split('select')[1].split('from')[0].split(',').map(c => c.trim()) })
                })
            } else {
                SuperAdminAction.execInsertOrUpdate(request, token).then(results => {
                    AppStore.dispatch(ToastrAction.success('Requête exécutée avec succès.'))
                    this.setState({ resultsHeaders: ['Nb de lignes mises à jour'], results: [[results.result]] })
                })
            }
        } else {
            AppStore.dispatch(ToastrAction.info('Veuillez renseigner le token et une requête'))
        }
    }

    componentDidMount() {
        SuperAdminAction.getRequest().then((request) => this.setState({ preparedRequests: request.map((l) => new DtoPreparedRequest(l)) }))
    }

    onExport = (data) => {
        if (data.length) {
            data[0].headers = this.state.resultsHeaders
            exportFile({
                data,
                exportType: 'xlsx',
                titleFile: 'selectExport',
            })
        }
    }

    render() {
        const { preparedRequests, selectedRequest, resultsHeaders, results, params } = this.state
        const data = results.map(r => resultsHeaders.reduce((acc, v, idx) => ({ ...acc, [v]: { value: r[idx] } }), {}))
        const parameters = selectedRequest?.request ? uniq(selectedRequest?.request.toLowerCase().match(/#[a-z]\$[a-zA-Z]+#/g)).map(param => {
            const [inputType, name] = param.replaceAll('#', '').split('$')
            if (inputType === 'd') {
                return (
                    <SimpleDatePicker
                        col={2}
                        value={params[name]}
                        label={name}
                        onChange={(value) => this.setState({ params: { ...this.state.params, [name]: value } })}
                    />
                )
            }
            if (inputType === 'i' || inputType === 'f') {
                return (
                    <NumberField
                        col={2}
                        title={name}
                        floatValue={inputType === 'f'}
                        value={params[name]}
                        onChange={(value) => this.setState({ params: { ...this.state.params, [name]: value } })}
                    />
                )
            }
            return (
                <Input
                    col={2}
                    title={name}
                    value={params[name]}
                    onChange={(value) => this.setState({ params: { ...this.state.params, [name]: value } })}
                />
            )
        }) : []
        return (
            <div className='row no-margin'>
                <div className='row no-margin'>
                    <div className='row no-margin padding-top-1'>
                        <Select
                            value={this.state.selectedRequest?.id}
                            options={preparedRequests}
                            col={6}
                            label={'Requête préparée'}
                            onChange={(e) => this.setState({ selectedRequest: preparedRequests.find(r => r.id === e) })}
                        />
                        <div className='col s4'>
                            <Button
                                style={{ marginTop: 10 }}
                                onClick={() => {
                                    if (selectedRequest) {
                                        const parameters = uniq(selectedRequest?.request.toLowerCase().match(/#[a-z]\$[a-zA-Z]+#/g))
                                        const updatedRequest = parameters.reduce((acc, value) => {
                                            const [inputType, name] = value.replaceAll('#', '').split('$')
                                            if (hasValue(params[name])) {
                                                if (inputType === 'd') {
                                                    return acc.replaceAll(value, `\'${moment(params[name]).format('YYYY-MM-DD HH:mm:ss')}\'`)
                                                }
                                                return acc.replaceAll(value, params[name])
                                            } return acc
                                        }, selectedRequest.request)

                                        this.setState({ request: updatedRequest })
                                    }
                                }}
                                variant='contained'
                            >
                                {i18n.apply}
                            </Button>
                        </div>
                    </div>
                    <Row className='padding-left-2'>
                        <h6>{ selectedRequest?.description }</h6>
                    </Row>
                    {
                        parameters.length ? (
                            <StyledFieldSet>
                                <StyledLegend>&nbsp;{i18n.parameters}&nbsp;</StyledLegend>
                                {parameters}
                            </StyledFieldSet>
                        ) : null
                    }
                    <Textarea
                        title={i18n.request}
                        value={this.state.request}
                        onChange={v => this.setState({ request: v })} row={12} col={12}
                    />
                    <div className='padding-top-2'/>
                    <Row className='padding-top-2'>
                        <Button
                            style={{ marginTop: 10 }}
                            onClick={this.execRequest}
                            variant='contained'
                        >
                            {i18n.execute}
                        </Button>
                        <Input
                            col={2}
                            title={i18n.token}
                            floatValue={false}
                            obligatory
                            value={this.state.token}
                            onChange={(e) => this.setState({ token: e })}
                        />
                    </Row>
                    <div className='padding-top-1'/>
                    { data.length ? (
                        <Table
                            title={i18n.results}
                            data={data}
                            type={{ headers: resultsHeaders }}
                            color
                            customHeaders={ resultsHeaders.reduce((acc, h) => ({ ...acc, [h]: h }), {}) }
                            actions={[{ iconName: 'file_download', tooltip: i18n.export, onClick: () => this.onExport(data) }]}
                            sortable
                            condensed
                            paging
                            nbPerPageLabel={nbPerPageLabel}
                        />
                    ) : null }
                </div>
            </div>
        )
    }
}

SuperAdminReqPanel.propTypes = {
    result: PropTypes.arrayOf(PropTypes.instanceOf(DtoResult)),
}

export default SuperAdminReqPanel
