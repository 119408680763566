export default class TaxonDto {
    constructor(obj) {
        this.id = obj.code
        this.code = obj.code
        this.latinName = obj.latinName
        this.status = obj.status
        this.creationDate = obj.creationDate
        this.updateDate = obj.updateDate
        this.author = obj.author
        this.writter = obj.writter
        this.name = obj.name
        this.bibliographicReference = obj.bibliographicReference
        this.comment = obj.comment
        this.active = obj.active
        this.elementOf = obj.elementOf
        this.theme = obj.theme
        this.level = obj.level
        this.omnidiaCode = obj.omnidiaCode
        this.calculTaxonCode = obj.calculTaxonCode
        this.refmuseum = obj.refmuseum
        this.basionym = obj.basionym
        this.synonyms = obj.synonyms
        this.characteristics = obj.characteristics
        this.morphology = obj.morphology
        this.ecology = obj.ecology
        this.noteibd = obj.noteibd
        this.noteips = obj.noteips
        this.notes = obj.notes
        this.observations = obj.observations
        this.contributorComment = obj.contributorComment
        this.speciesType = obj.speciesType
        this.speciesStatus = obj.speciesStatus
        this.doNotMixUp = obj.doNotMixUp
        this.omnidiaSynonym = obj.omnidiaSynonym
        this.teraOmnidia = obj.teraOmnidia
        this.populationAspect = obj.populationAspect
        this.flowering = obj.flowering
        this.pictureLegend = obj.pictureLegend
        this.macrophyteCode = obj.macrophyteCode
        this.worms = obj.worms
        this.aspe = obj.aspe
        this.spomnidia = obj.spomnidia

        this.labelWithCode = obj.code ? `${obj.latinName || ''} [${obj.code}]` : (obj.latinName || '')
    }
}