/* eslint-disable camelcase */
import DtoCodificationContributor from '../../../dto/DtoCodificationContributor'
import ReferencialDto from '../../../dto/ReferencialDto'

export default class ContributorDto extends ReferencialDto {
    constructor(object) {
        super(object)
        this.code = object.id
        this.name = object.name
        this.siret = object.siret
        this.mnemonique = object.mnemonique
        this.sandre = object.sandre
        this.domain = object.domain
        this.postalBox = object.postalBox
        this.road = object.road
        this.addressComplement = object.addressComplement
        this.statePlace = object.statePlace
        this.department = object.department
        this.city = object.city
        this.cityCode = object.cityCode
        this.cedexCode = object.cedexCode
        this.comments = object.comments
        this.phoneTel = object.phoneTel
        this.email = object.email
        this.phoneTelSecond = object.phoneTelSecond
        this.fax = object.fax
        this.mobile = object.mobile
        this.creationDate = object.creationDate
        this.updateDate = object.updateDate
        this.auteur = object.auteur
        this.otherReference = object.otherReference
        this.activityStartDate = object.activityStartDate
        this.activityEndDate = object.activityEndDate
        this.legalRepresentative = object.legalRepresentative
        this.noNewsLetterDate = object.noNewsLetterDate
        this.noNewsLetterLogin = object.noNewsLetterLogin
        this.internalIdentifier = object.internalIdentifier
        this.cedex = object.cedex
        this.structureType = object.structureType
        this.postalCode = object.postalCode
        this.countryCode = object.countryCode // Option[String] = None
        this.x = object.x // Option[Double] = None
        this.y = object.y // Option[Double] = None
        this.projection = object.projection // Option[Double] = None
        this.townCode = object.cityCode
        this.schedule = object.schedule
        this.publicSchedule = object.publicSchedule
        this.typeName = 'contact'
        this.cityCodeDelegate = object.cityCodeDelegate
        this.postalCodeDelegate = object.postalCodeDelegate

        this.link_codificationContributors = object.link_codificationContributors ? object.link_codificationContributors.map(cc => new DtoCodificationContributor(cc)) : []

        this.labelDisplay = object.mnemonique || object.name
    }
}