import { push } from '@lagunovsky/redux-react-router'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { getFullDate } from '../../utils/DateUtil'
import DtoMessage from '../dto/DtoMessage'
import Card from 'components/card/Card'
import { Grid } from '@mui/material'


const GlobalMessageCard = ({
    lastMessage = {},
    redirect = () => { },
}) => {
    return (
        <Card className='clickable'>
            <Grid container onClick={() => redirect(`/messages/${lastMessage.login}`)}>
                <Grid item xs={3} style={{ width: '20%', alignItems: 'center' }}>
                    <div className={'valign-wrapper '} style={{ marginLeft: '10px', height: '100%' }}>
                        <i className='material-icons margin-right-1' style={{ fontSize: '3rem' }}>{lastMessage.updateLogin === lastMessage.login ? 'help' : 'message'}</i>
                        <h5 style={{ margin: '0' }}>{lastMessage.login}</h5>
                    </div>
                </Grid>
                <Grid item xs={9} className='no-margin white no-padding'>
                    <div className='col s12'>
                        <h5 className='thin' style={{ margin: '0.82rem 0 0.323rem 0' }}>{getFullDate(lastMessage.updateDate)}</h5>
                        <p style={{ margin: '0.41rem 0 0.656rem 3px', fontSize: '16px', fontWeight: '100' }}>{lastMessage.message}</p>
                    </div>
                </Grid>
            </Grid>
        </Card>
    )
}

GlobalMessageCard.propTypes = {
    lastMessage: PropTypes.instanceOf(DtoMessage),
    redirect: PropTypes.func,
}

const mapDispatchToProps = {
    redirect: push,
}

export default connect(null, mapDispatchToProps)(GlobalMessageCard)