import PropTypes from 'prop-types'
import React, { Component } from 'react'
import i18n from 'simple-react-i18n'
import Card from '../../../../components/card/Card'
import DiagnosticDto from '../../../../events/dto/piezometryDiagnostic/DiagnosticDto'
import SolutionDto from '../../../../events/dto/piezometryDiagnostic/SolutionDto'
import SolutionLinkProblemDto from '../../../../events/dto/piezometryDiagnostic/SolutionLinkProblemDto'

class DiagnosticSolutionModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            diagnostic: props.action.diagnosticCode ? { ...this.props.diagnostics.find(d => d.codeDiagnostic === props.action.diagnosticCode) } : {},
            solution: props.action.solutionCode ? { ...this.props.solutions.find(d => d.codeSolution === props.action.solutionCode) } : {},
        }
    }

    onChange = (obj) => {
        this.setState(obj)
        this.props.addDiagnosticSolution(obj)
    }

    getDiagnosticLabel = (diagnostic) => {
        const codeLabel = diagnostic.codeDiagnostic ? `[${diagnostic.codeDiagnostic}]` : ''
        return (
            <div className={ `row no-margin valign-wrapper clickable ${diagnostic.codeDiagnostic === this.state.diagnostic.codeDiagnostic ? 'grey lighten-1' : ''}` }
                onClick={ () => this.onChange({ diagnostic: { ...diagnostic }, solution: {} }) }
            >
                <div className={ 'padding-top-1px padding-bottom-1px padding-left-1' }>
                    { `${codeLabel} ${diagnostic.description || ''}` }
                </div>
            </div>
        )
    }

    getSolutionLabel = (solution) => {
        const codeLabel = solution.codeSolution ? `[${solution.codeSolution}]` : ''
        return (
            <div className={ `row no-margin valign-wrapper clickable ${solution.codeSolution === this.state.solution.codeSolution ? 'grey lighten-1' : ''}` }
                onClick={ () => this.onChange({ diagnostic: { ...this.state.diagnostic }, solution: { ...solution } }) }
            >
                <div className={ 'padding-top-1px padding-bottom-1px padding-left-1' }>
                    { `${codeLabel} ${solution.description || ''}` }
                </div>
            </div>
        )
    }

    getSolutionFilter = () => {
        return this.props.solutions.filter(s => this.props.linkDiagnosticSolution.find(l => l.codeSolution === s.codeSolution && l.codeProblem === this.state.diagnostic.codeDiagnostic))
    }

    render() {
        const diagnostics = this.props.diagnostics.sort((a, b) => a.codeDiagnostic - b.codeDiagnostic).map(diag => this.getDiagnosticLabel(diag))
        const solutions = this.state.diagnostic.codeDiagnostic ?
            this.getSolutionFilter().map(s => this.getSolutionLabel(s))
            : []

        return (
            <div>
                <div className='row no-margin col s12'>
                    <div className='col s6' >
                        <Card maxHeight={ 400 } title={ i18n.diagnostic } >
                            { diagnostics }
                        </Card>
                    </div>
                    <div className='col s6' >
                        <Card maxHeight={ 400 } title={ i18n.action } >
                            { solutions }
                        </Card>
                    </div>
                </div>
            </div>
        )
    }
}

DiagnosticSolutionModal.propTypes = {
    diagnostics: PropTypes.arrayOf(PropTypes.instanceOf(DiagnosticDto)),
    solutions: PropTypes.arrayOf(PropTypes.instanceOf(SolutionDto)),
    linkDiagnosticSolution: PropTypes.arrayOf(PropTypes.instanceOf(SolutionLinkProblemDto)),
    addDiagnosticSolution: PropTypes.func,
    action: PropTypes.object,
}

export default DiagnosticSolutionModal
