import React from 'react'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import { connect } from 'react-redux'
import { arrayOf, getSandreList } from '../../../../utils/StoreUtils'
import DtoSandreCode from '../../../../referencial/dto/DtoSandreCode'
import { SANDRE } from '../../../../referencial/constants/ReferencialConstants'
import SieauAction from '../../../../components/sieau/SieauAction'
import Row from '../../../../components/react/Row'
import Checkbox from '../../../../components/forms/Checkbox'
import DtoPumpingStation from '../dto/DtoPumpingStation'
import { getVisitCheckBoxChange, getVisitSandreSelectChange } from '../../../../utils/VisitUtils'
import Select from '../../../../components/forms/Select'

const PumpingEquipmentsPanel = ({
    onChange = () => { },
    onChangeVisit = () => { },
    pumping = {},
    readMode = false,
    sandreCodes = [],
}) => {
    const onChangePumpingEquipments = (value) => {
        onChange({
            link_equipments: [
                {
                    idStation: pumping.idStation,
                    ...pumping.link_equipments[0],
                    ...value,
                },
            ],
        })
    }

    const equipments = pumping.link_equipments[0] || {}

    return (
        <Row className='padding-top-1 padding-bottom-1'>
            <Row>
                <Checkbox
                    col={ 6 }
                    checked={ equipments.turbidimeter }
                    label={ i18n.turbidimeter }
                    onChange={ (v) => {
                        onChangePumpingEquipments({ turbidimeter: v })
                        onChangeVisit(getVisitCheckBoxChange(i18n.turbidimeter, equipments.turbidimeter, v))
                    } }
                    disabled={ readMode }
                />
                <Checkbox
                    col={ 6 }
                    checked={ equipments.chlorineResidualAnalyzer }
                    label={ i18n.chlorineResidualAnalyzer }
                    onChange={ (v) => {
                        onChangePumpingEquipments({ chlorineResidualAnalyzer: v })
                        onChangeVisit(getVisitCheckBoxChange(i18n.chlorineResidualAnalyzer, equipments.chlorineResidualAnalyzer, v))
                    } }
                    disabled={ readMode }
                />
            </Row>
            <Row className='padding-top-1'>
                <Select
                    col={ 6 }
                    value={ equipments.flowMeasure }
                    label={ i18n.flowMeasure }
                    keyValue='code'
                    options={ getSandreList(sandreCodes, SANDRE.INSTALLATIONS_MESUREDEBIT) }
                    onChange={ (v) => {
                        onChangePumpingEquipments({ flowMeasure: v })
                        onChangeVisit(getVisitSandreSelectChange(sandreCodes, SANDRE.INSTALLATIONS_MESUREDEBIT, i18n.flowMeasure, equipments.flowMeasure, v))
                    } }
                    integerValue
                    readMode={ readMode }
                />
            </Row>
        </Row>
    )
}

PumpingEquipmentsPanel.propTypes = {
    pumping: PropTypes.instanceOf(DtoPumpingStation),
    sandreCodes: arrayOf(DtoSandreCode),
    readMode: PropTypes.bool,
    onChange: PropTypes.func,
    onChangeVisit: PropTypes.func,
    setPopup: PropTypes.func,
}

const mapStateToProps = (store) => ({
    sandreCodes: store.ReferencialReducer.sandreCodes,
})

const mapDispatchTopProps = {
    setPopup: SieauAction.setPopup,
}

export default connect(mapStateToProps, mapDispatchTopProps)(PumpingEquipmentsPanel)
