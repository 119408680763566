'use strict'
import { RECEIVE_ALL_UNITS, RECEIVE_UNIT, RECEIVE_UNIT_NAMES, RESET_UNIT } from '../constants/UnitConstants'
import UnitDto from '../dto/UnitDto'
import SelectItem from '../../parameter/dto/SelectItem'
import { createIndex } from '../../../../utils/StoreUtils'

function mapToSelectItem(unit) {
    if (unit.code != '') {
        return { value: unit.code, label: `${unit.name} [${unit.code}]` }
    }
    return { value: '', label: '' }
}

export function UnitReducer(state = {}, action) {
    switch (action.type) {
        case RECEIVE_UNIT:
            return Object.assign({}, state, {
                unit: new UnitDto(action.unit),
            })
        case RECEIVE_ALL_UNITS:
            const units = action.units.map(unit => new UnitDto(unit))
            return Object.assign({}, state, {
                units,
                unitsIndex: createIndex(units, 'id'),
            })
        case RECEIVE_UNIT_NAMES:
            return Object.assign({}, state, {
                unitNames: action.names.map(unit => new SelectItem(mapToSelectItem(unit))).sort((a, b) => parseInt(a.value) - parseInt(b.value)),
            })
        case RESET_UNIT:
            return Object.assign({}, state, {
                unit: {},
            })
        default:
            return state
    }
}

export const store = {
    unit: {},
    units: [],
    unitNames: [],
    unitsIndex: {},
}
