import React, { Component } from 'react'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import { CSV, WS, WS2, XML } from './constants/ImportFormat'
import Checkbox from '../forms/Checkbox'
import RadioButtons from '../forms/RadioButtons'

class ImportFileFormat extends Component {
    constructor(props) {
        super(props)
        this.state = { format: WS,
            nouveau: true,
            classGroupe: true,
        }
    }

    onChangeFormat(value) {
        this.setState({ format: value })
    }

    onImportFile(e) {
        const reader = new FileReader()
        const file = e.target.files[0]
        reader.onload = upload => {
            this.props.fileContent(upload.target.result)
        }
        reader.readAsDataURL(file)
    }

    importFileXML() {
        const selectModes = [
            { code: false, name: i18n.onlyUpdate },
            { code: true, name: i18n.importAllParameters },
        ]
        const selectTypeGroup= [
            { code: true, name: i18n.usageParameter },
            { code: false, name: i18n.classParameter },
        ]
        if (this.props.importXml) {
            return (
                <div>
                    <h3/>
                    <p className='caption'>{ i18n.importXmlFileToZanderReference }</p>
                    <form action='#' >
                        <div className='file-field input-field'>
                            <div className='btn'>
                                <span>{ i18n.xml }</span>
                                <input type='file' onChange={f=> this.onImportFile(f) } />
                            </div>
                            <div className='file-path-wrapper'>
                                <input className='file-path validate' type='text' />
                            </div>
                        </div>
                    </form>
                    <form action='#'>
                        <div className='row no-margin valign-wrapper'>
                            <fieldset className='width-100'>
                                <RadioButtons col={ 12 }
                                    colOption={ 12 }
                                    elements={ selectModes }
                                    onChange={ v => {
                                        this.props.importOrUpdate(v), this.setState({ nouveau: v })
                                    } }
                                    selected={ this.state.nouveau }
                                />
                            </fieldset>
                            <fieldset className='width-100'>
                                <RadioButtons col={ 12 }
                                    colOption={ 12 }
                                    elements={ selectTypeGroup }
                                    onChange={ v => {
                                        this.props.typeGroup(v), this.setState({ classGroupe: v })
                                    } }
                                    selected={ this.state.classGroupe }
                                />
                            </fieldset>
                        </div>
                        <div className='row no-margin valign-wrapper'>
                            <Checkbox
                                checked={ false }
                                label={i18n.activateAllSupports}
                                onChange={v => this.props.actifAuto(v) }
                            />
                        </div>
                    </form>
                </div>
            )
        }
        return (
            <div>
                <h3/>
                <h3 className='caption'>{ i18n.onDevelopment }</h3>
            </div>
        )
    }

    importFileCSV() {
        const selectModes = [
            { code: false, name: i18n.onlyUpdate },
            { code: true, name: i18n.importAllParameters },
        ]
        if (this.props.importCsv) {
            return (
                <div>
                    <h3/>
                    <p className='caption'>{ i18n.importCsvZanderReference }</p>
                    <form action='#' >
                        <div className='file-field input-field'>
                            <div className='btn'>
                                <span>{ i18n.csv }</span>
                                <input type='file' onChange={f=> this.onImportFile(f) } />
                            </div>
                            <div className='file-path-wrapper'>
                                <input className='file-path validate' type='text' />
                            </div>
                        </div>
                    </form>
                    <form action='#'>
                        <div className='row no-margin valign-wrapper'>
                            <fieldset className='width-100'>
                                <RadioButtons col={ 12 }
                                    colOption={ 12 }
                                    elements={ selectModes }
                                    onChange={ v => {
                                        this.props.importOrUpdate(v), this.setState({ nouveau: v })
                                    } }
                                    selected={ this.state.nouveau }
                                />
                            </fieldset>
                        </div>
                        <div className='row no-margin valign-wrapper'>
                            <Checkbox
                                checked={ false }
                                label={i18n.activateAllSupports}
                                onChange={v => this.props.actifAuto(v) }
                            />
                        </div>
                    </form>
                </div>
            )
        }
        return (
            <div>
                <h3/>
                <h3 className='caption'>{ i18n.onDevelopment }</h3>
            </div>
        )
    }

    importFileWS() {
        return (
            <div>
                <div className='card-content'>
                    <div className='row no-margin'>
                        <p>
                            <input
                                type='checkbox'
                                className='filled-in'
                                id='doAutomaticSynchronize'
                                disabled
                            />
                            <label htmlFor='doAutomaticSynchronize'>{ i18n.automaticSynchronization }</label>
                        </p>
                    </div>
                </div>
            </div>
        )
    }

    importFileWS2() {
        return (
            <div>
                <div className='card-content'>
                    <div className='row no-margin'>
                        <p>
                            <input
                                type='checkbox'
                                className='filled-in'
                                id='doAutomaticSynchronize2'
                                disabled
                            />
                            <label htmlFor='doAutomaticSynchronize2'>{ i18n.automaticSynchronization }</label>
                        </p>
                    </div>
                </div>
            </div>
        )
    }


    getItems = () => {
        if (this.props.biAction) {
            return [
                { value: WS, label: this.props.textValue1 },
                { value: WS2, label: this.props.textValue2 },
                { value: XML, label: i18n.xml },
                { value: CSV, label: i18n.csv },
            ]
        }
        return [
            { value: WS, label: i18n.ws },
            { value: XML, label: i18n.xml },
            { value: CSV, label: i18n.csv },
        ]
    }

    getChoice = () => {
        switch (this.state.format) {
            case XML:
                return this.importFileXML()
            case CSV:
                return this.importFileCSV()
            case WS:
                return this.importFileWS()
            case WS2:
                return this.importFileWS2()
            default:
                return null
        }
    }

    getUpdateFormat = () => {
        switch (this.state.format) {
            case WS:
                return (
                    <a className='btn btn-small right modal-close'
                        type='submit'
                        onClick={ this.props.onUpdate }
                        name='action'
                    >{ i18n.synchronize }</a>
                )
            case WS2:
                return (
                    <a className='btn btn-small right modal-close'
                        type='submit'
                        onClick={ this.props.onUpdateBis }
                        name='action'
                    >{ i18n.synchronize }</a>
                )
            case CSV:
                return (
                    <a className='btn btn-small right modal-close'
                        type='submit'
                        onClick={ this.props.onImportCsv }
                        name='action'
                    >{ i18n.importLabel }</a>
                )
            case XML:
                return (
                    <a className='btn btn-small right modal-close'
                        type='submit'
                        onClick={ this.props.onImportXml }
                        name='action'
                    >{ i18n.importLabel }</a>
                )
            default:
                return null
        }
    }

    getSidenav = () => {
        return this.getItems().map(item => {
            const className = item.value == this.state.format ? 'collection-item active clickable' : 'collection-item clickable'
            return (
                <a className={ className } onClick={ () => {
                    this.onChangeFormat(item.value)
                } }
                >{ item.label }</a>
            )
        })
    }

    render() {
        const sideNav = this.getSidenav()

        return (
            <div className='row'>
                <div className='col s3'>
                    <div className='collection'>
                        { sideNav }
                    </div>
                </div>
                <div className='col s9'>
                    <div className='card-title activator'>
                        { i18n.synchronisationZanderReference }
                    </div>
                    <div className='row'>
                        { this.getChoice() }
                    </div>
                    <div className='row'>
                        { this.getUpdateFormat() }
                    </div>
                </div>
            </div>
        )
    }
}

ImportFileFormat.propTypes = {
    onUpdate: PropTypes.func.isRequired,
    onUpdateBis: PropTypes.func,
    onImportCsv: PropTypes.func,
    onImportXml: PropTypes.func,
    textValue1: PropTypes.string,
    textValue2: PropTypes.string,
    biAction: PropTypes.bool,
    importCsv: PropTypes.bool,
    importXml: PropTypes.bool,
    fileContent: PropTypes.func,
    importOrUpdate: PropTypes.func,
    typeGroup: PropTypes.func,
    actifAuto: PropTypes.func,
}


export default ImportFileFormat