export default class MonitoredMaterielDto {
    constructor(obj) {
        this.id = obj.idMateriel
        this.type = obj.type
        this.typeMateriel = obj.typeMateriel
        this.serialNumber = obj.serialNumber
        this.idSite = obj.idSite
        this.typeSite = obj.typeSite
        this.eventId = obj.eventId
        this.eventComment = obj.eventComment
        this.eventProblem = obj.eventProblem
    }
}
