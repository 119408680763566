import React from 'react'
import i18n from 'simple-react-i18n'
import InDevelopmentBackground from '../../../../inDevelop/components/InDevelopmentBackground'
import { Grid } from '@mui/material'
import { HYDRO_IMPORT_TYPE } from 'hydrometry/constants/HydrometryConstants'
import DtoHydrometricStation from 'hydrometry/dto/DtoHydrometricStation'
import AddHydroStepOptionsHP from './AddHydroStepOptionsHP'
import PropTypes from 'prop-types'
import AddStepOptionsEmpty from 'station/components/addSteps/AddStepOptionsEmpty'


const AddHydroStepOptions = ({
    station,
    ...props
}) => {
    const getOptionsPanel = () => {
        switch (station.stationType) {
            case HYDRO_IMPORT_TYPE.HYDRO_PORTAIL:
                return <AddHydroStepOptionsHP {...props} station={station} />
            case HYDRO_IMPORT_TYPE.EMPTY:
                return <AddStepOptionsEmpty {...props} station={station} stationType='hydrometry'/>
            default:
                return <InDevelopmentBackground />
        }
    }
    return (
        <div className='padding-top-2'>
            <Grid container justifyContent='center' alignItems='center' spacing={3}>
                <h4>{i18n.newStationStep2}</h4>
            </Grid>
            { getOptionsPanel() }
        </div>
    )
}

AddHydroStepOptions.propTypes = {
    station: PropTypes.instanceOf(DtoHydrometricStation),
}

export default AddHydroStepOptions