import React from 'react'
import i18n from 'simple-react-i18n'
import PropTypes from 'prop-types'
import { StyledFieldSet } from 'components/StyledElements'
import Icon from 'components/icon/Icon'
import { exportFile } from 'utils/ExportDataUtil'

const headers = ['code', 'name', 'basinCode', 'basinLabel', 'siret', 'indusSiteName', 'citySite']

const ImportOptionsStep = ({
    setFileContent = () => { },
    setFileName = () => { },
}) => {
    const onUploadFile = (e) => {
        const reader = new FileReader()
        const file = e.target.files[0]
        if (file) {
            setFileName(file.name)
            reader.onloadend = (event) => {
                const split = event.target.result.split(',')
                setFileContent(split[1])
            }
            reader.readAsDataURL(file)
        }
    }

    return (
        <StyledFieldSet>
            <ul>
                <li>le fichier doit être au type xslx</li>
                <li>le fichier doit contenir les colonnes suivantes dans cet ordre précis
                    <ol>
                        <li>Code STEP (obligatoire)</li>
                        <li>Nom STEP</li>
                        <li>Code bassin (obligtoire)</li>
                        <li>Libellé bassin</li>
                        <li>Siret (obligatoire)</li>
                        <li>Nom établissement</li>
                        <li>Commune établissement</li>
                    </ol>
                </li>
            </ul>
            Télécharger le modèle excel <Icon icon='file_download' onClick={() => exportFile({ data: [{ headers }], exportType: 'xlsx', titleFile: i18n.model }, true)} />
            <div className='file-field input-field'>
                <div className='btn'>
                    <span>{i18n.importLabel}</span>
                    <input
                        accept='.xls, .xlsx'
                        type='file'
                        onChange={onUploadFile}
                    />
                </div>
                <div className='file-path-wrapper'>
                    <input className='file-path validate' type='text' />
                </div>
            </div>
        </StyledFieldSet>
    )
}

ImportOptionsStep.propTypes = {
    setFileContent: PropTypes.func,
    setFileName: PropTypes.func,
}

export default ImportOptionsStep