/* eslint-disable camelcase */
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import Table from '../../../components/datatable/Table'
import SieauAction from '../../../components/sieau/SieauAction'
import DtoDistributionUnit from '../../../distributionUnit/dto/DtoDistributionUnit'
import DtoHydrometricStation from '../../../hydrometry/dto/DtoHydrometricStation'
import DtoInstallation from '../../../installation/dto/installation/DtoInstallation'
import DtoPiezometer from '../../../piezometry/dto/DtoPiezometer'
import DtoProductionUnit from '../../../productionUnit/dto/DtoProductionUnit'
import DtoQualitometer from '../../../quality/dto/DtoQualitometer'
import { SANDRE } from '../../../referencial/constants/ReferencialConstants'
import DtoSandreCode from '../../../referencial/dto/DtoSandreCode'
import { getFullDate } from '../../../utils/DateUtil'
import { getExport, setModal } from '../../../utils/linkUtils'
import { arrayOf } from '../../../utils/StoreUtils'
import { getSandreLabel } from '../../../utils/StringUtil'
import UsageModal from './modals/UsageModal'

const StationUsagePanel = ({
    station = {},
    sandreCodes = [],
    readMode = false,
    onChange = () => {},
    setPopup = () => {},
}) => {
    const stationUsages = station.link_usages.map((u, index) => ({
        ...u,
        index,
    }))
    const tableData = stationUsages.map(u => ({
        ...u,
        startDate: getFullDate(u.startDate),
        endDate: getFullDate(u.endDate),
        usage: getSandreLabel(sandreCodes, SANDRE.USAGES_GENERAL, u.usageCode),
        description: u.descriptive,
    }))
    const headers = ['startDate', 'endDate', 'usage', 'description']
    const exportAction = getExport(tableData, i18n.usages, headers)
    const actions = !readMode ? [{
        iconName: 'note_add',
        tooltip: i18n.add,
        onClick: () => setModal(i18n.newUsageLink, <UsageModal id={ station.id } saveResult={ r => onChange({ link_usages: [...stationUsages, r] }) }/>, setPopup),
    }, exportAction] : [exportAction]

    return (
        <div id='usages'>
            <Table
                title={ i18n.usages }
                condensed
                data={ tableData }
                type={{ headers }}
                sortable={ !!tableData.length }
                actions={ actions }
                alterable={ !readMode }
                onAlter={
                    (element) => setModal(i18n.newUsageLink,
                        <UsageModal
                            id={ station.id }
                            usage={ stationUsages.find(u => element.index === u.index) }
                            saveResult={ r => onChange({
                                link_usages: [
                                    ...stationUsages.filter(u => u.index !== element.index),
                                    { ...r, index: element.index },
                                ],
                            })}
                        />
                        , setPopup)
                }
                deletable={ !readMode }
                onDelete={ (element) => onChange({ link_usages: stationUsages.filter(u => u.index !== element.index) }) }
            />
        </div>
    )
}

StationUsagePanel.propTypes = {
    station: PropTypes.oneOfType([
        PropTypes.instanceOf(DtoProductionUnit),
        PropTypes.instanceOf(DtoDistributionUnit),
        PropTypes.instanceOf(DtoQualitometer),
        PropTypes.instanceOf(DtoPiezometer),
        PropTypes.instanceOf(DtoHydrometricStation),
        PropTypes.instanceOf(DtoInstallation),
    ]),
    sandreCodes: arrayOf(DtoSandreCode),
    readMode: PropTypes.bool,
    onChange: PropTypes.func,
    setPopup: PropTypes.func,
}

const mapStateToProps = store => ({
    sandreCodes: store.ReferencialReducer.sandreCodes,
})

const mapDispatchToProps = {
    setPopup: SieauAction.setPopup,
}

export default connect(mapStateToProps, mapDispatchToProps)(StationUsagePanel)