export default class DiagnosticDto {
    constructor(obj) {
        this.codeDiagnostic = obj.codeDiagnostic
        this.description = obj.description
        this.active = obj.active
        this.updateDate = obj.updateDate
        this.updateLogin = obj.updateLogin
        this.endDate = obj.endDate
    }
}
