import React from 'react'
import PropTypes from 'prop-types'
import { shallowEqual, useSelector } from 'react-redux'
import Pie from 'components/echart/Pie'
import { groupBy, isNil, keys, round, sumBy } from 'lodash'
import useListIndexed from 'utils/customHook/useListIndexed'
import { getI18nOrLabel } from 'utils/StringUtil'
import i18n from 'simple-react-i18n'

const getSmallestLabel = (label1 = '', label2 = '', limit = 50) => {
    if (!label1) return label2
    if (!label2) return label1

    if (label1.length < label2.length) return label1

    if (label1.length > limit) return label2
    return label1
}

const formatExportData = data => {
    const total = sumBy(data, 'value')
    const exportData = data.map(({ name, value }) => ({
        group: name,
        nbQuantifications: value,
        proportion: total ? `${round((value / total) * 100, 2)} %` : '',
    }))
    return exportData.length ? [{ ...exportData[0], headers: Object.keys(exportData[0]) }, ...exportData.slice(1)] : []
}

const GroupUsageGraph = ({
    analysis = [],
}) => {
    const {
        parameterGroupLinks,
        parameterGroups,
    } = useSelector(store => ({
        parameterGroupLinks: store.ParameterReducer.parameterGroupLinks,
        parameterGroups: store.ParameterReducer.parameterGroups,
    }), shallowEqual)

    const parameterGroupLinksIndexed = groupBy(parameterGroupLinks, 'parameterCode')
    const parameterGroupsIndexed = useListIndexed(parameterGroups, 'code')

    const countUsage = analysis.reduce((acc, a) => {
        const links = parameterGroupLinksIndexed[a.parameter]
        if (isNil(links)) {
            acc.notSpecified = (acc.notSpecified ?? 0) + 1
            return acc
        }
        links.forEach(link => {
            [link.usageGroupCode, link.usageGroupCode2, link.usageGroupCode3, link.usageGroupCode4, link.usageGroupCode5].filter(c => !isNil(c)).forEach(code => {
                const group = parameterGroupsIndexed[code]
                if (isNil(group)) {
                    acc.undefined = (acc.undefined ?? 0) + 1
                    return
                }
                const name = getSmallestLabel(group.name, group.description) ?? 'unknown'
                acc[name] = (acc[name] ?? 0) + 1
            })
        })
        return acc
    }, {})

    return (
        <Pie
            title={i18n.usageGroup}
            data={keys(countUsage).map(key => ({ name: getI18nOrLabel(key), value: countUsage[key] }))}
            height={270}
            formatExportData={formatExportData}
            tooltipFormatter={`{b} : {c} ${i18n.quantifications} ({d}%)`}
        />
    )
}

GroupUsageGraph.propTypes = {
    analysis: PropTypes.arrayOf(PropTypes.shape({/* ...DtoAnalysis, ...getObjectFromAnalysis */ })),
}

const GroupClassGraph = ({
    analysis = [],
}) => {
    const {
        parameterGroupLinks,
        parameterGroups,
    } = useSelector(store => ({
        parameterGroupLinks: store.ParameterReducer.parameterGroupLinks,
        parameterGroups: store.ParameterReducer.parameterGroups,
    }), shallowEqual)

    const parameterGroupLinksIndexed = groupBy(parameterGroupLinks, 'parameterCode')
    const parameterGroupsIndexed = useListIndexed(parameterGroups, 'code')

    const countClass = analysis.reduce((acc, a) => {
        const links = parameterGroupLinksIndexed[a.parameter]
        if (isNil(links)) {
            acc.notSpecified = (acc.notSpecified ?? 0) + 1
            return acc
        }
        links.forEach(link => {
            const group = parameterGroupsIndexed[link.classGroupCode]
            if (isNil(group)) {
                acc.undefined = (acc.undefined ?? 0) + 1
                return
            }
            const name = getSmallestLabel(group.name, group.description) ?? 'unknown'
            acc[name] = (acc[name] ?? 0) + 1
        })
        return acc
    }, {})

    return (
        <Pie
            title={i18n.classGroup}
            data={keys(countClass).map(key => ({ name: getI18nOrLabel(key), value: countClass[key] }))}
            height={270}
            formatExportData={formatExportData}
            tooltipFormatter={`{b} : {c} ${i18n.quantifications} ({d}%)`}
        />
    )
}

GroupClassGraph.propTypes = {
    analysis: PropTypes.arrayOf(PropTypes.shape({/* ...DtoAnalysis, ...getObjectFromAnalysis */ })),
}

export {
    GroupUsageGraph,
    GroupClassGraph,
}