import PropTypes from 'prop-types'
import React, { Component } from 'react'
import HomeAction from '../home/actions/HomeAction'
import AppStore from '../store/AppStore'
import Calculate from './actions/Calculate'
import Cancel from './actions/Cancel'
import Clear from './actions/Clear'
import Delete from './actions/Delete'
import DeleteCheck from './actions/DeleteCheck'
import Dropdown from './actions/Dropdown'
import Duplicate from './actions/Duplicate'
import Edit from './actions/Edit'
import Export from './actions/Export'
import ExportChoice from './actions/ExportChoice'
import ExportList from './actions/ExportList'
import ExportModel from './actions/ExportModel'
import FastArrowNav from './actions/FastArrowNav'
import Import from './actions/Import'
import ImportFile from './actions/ImportFile'
import Links from './actions/Links'
import Loading from './actions/Loading'
import New from './actions/New'
import Notif from './actions/Notif'
import PdfList from './actions/PdfList'
import Purge from './actions/Purge'
import Replace from './actions/Replace'
import Save from './actions/Save'
import Shared from './actions/Shared'
import Update from './actions/Update'
import UpdateReferencial from './actions/UpdateReferencial'
import Validate from './actions/Validate'
import VisitLinks from './actions/VisitLinks'
import Email from './actions/Email'
import LanguagesList from './actions/LanguagesList'
import { ControlAnalysisAction } from 'quality/components/control/ControlAnalysis'

class ActionComponent extends Component {
    setActions = (actions) => {
        const arrayActions = new Array(30)
        const keys = Object.keys(actions)
        keys.filter(k => k !== 'other').forEach((key) => {
            this.addAction(key, arrayActions, actions)
        })
        if (actions.other) {
            Object.keys(actions.other).forEach((key) => {
                this.addAction(key, arrayActions, actions.other)
            })
        }
        AppStore.dispatch(HomeAction.setActions(arrayActions))
    }

    onClickAction = (func) => {
        $('.tooltipped').tooltip('remove')
        func()
    }

    addAction = (key, arrayActions, actions) => {
        switch (key) {
            case 'controlAnalysis':
                arrayActions[29] = <ControlAnalysisAction filters={actions[key]}/>
                break
            case 'languages':
                arrayActions[28] = (
                    <LanguagesList
                        onClick={(e) => {
                            $('.tooltipped').tooltip('remove')
                            actions[key](e)
                        }}
                    />
                )
                break
            case 'email':
                arrayActions[27] = <Email onSend={() => this.onClickAction(actions[key])} />
                break
            case 'dropdown':
                arrayActions[26] = <Dropdown {...actions[key]} />
                break
            case 'referencialActions':
                arrayActions[25] = (
                    <UpdateReferencial
                        actions={actions[key].actions}
                    />
                )
                break
            case 'visitLinks':
                arrayActions[24] = <VisitLinks links={actions[key]} />
                break
            case 'pdf':
                arrayActions[23] = <PdfList pdfList={actions[key]} />
                break
            case 'exportList':
                arrayActions[22] = <ExportList exportList={actions[key]} />
                break
            case 'deleteCheck':
                arrayActions[21] = <DeleteCheck onDelete={() => this.onClickAction(actions[key])} />
                break
            case 'duplicate':
                arrayActions[20] = <Duplicate onDuplicate={() => this.onClickAction(actions[key])} />
                break
            case 'arrowNav':
                arrayActions[19] = <FastArrowNav nav={actions[key]} />
                break
            case 'edit':
                arrayActions[18] = <Edit onEdit={() => this.onClickAction(actions[key])} />
                break
            case 'clear':
                arrayActions[17] = <Clear onClear={() => this.onClickAction(actions[key])} />
                break
            case 'cancel':
                arrayActions[16] = <Cancel onCancel={() => this.onClickAction(actions[key])} />
                break
            case 'validate':
                arrayActions[15] = <Validate onValidate={() => this.onClickAction(actions[key])} />
                break
            case 'save':
                arrayActions[14] = <Save onSave={() => this.onClickAction(actions[key])} />
                break
            case 'delete':
                arrayActions[13] = <Delete onDelete={() => this.onClickAction(actions[key])} />
                break
            case 'new':
                arrayActions[12] = <New onNew={() => this.onClickAction(actions[key])} />
                break
            case 'replace':
                arrayActions[11] = <Replace onReplace={() => this.onClickAction(actions[key])} />
                break
            case 'update':
                arrayActions[10] = (<Update onUpdate={() => this.onClickAction(actions[key].onUpdate)}
                    onUpdateBis={() => this.onClickAction(actions[key].onUpdateBis)}
                    importFile={ actions[key].importFile }
                    importOrUpdate={ actions[key].importOrUpdate }
                    typeGroup={ actions[key].typeGroup }
                    actifAuto={ actions[key].actifAuto }
                    onImportCsv={ actions[key].onImportCsv }
                    onImportXml={ actions[key].onImportXml }
                    textValue1={ actions[key].textValue1 }
                    textValue2={ actions[key].textValue2 }
                    biAction={ actions[key].biAction }
                    importCsv={ actions[key].importCsv }
                    importXml={ actions[key].importXml }
                    fileContent={ actions[key].fileContent }
                />)
                break
            case 'loading':
                arrayActions[9] = <Loading loads={actions[key]} />
                break
            case 'purge':
                arrayActions[8] = <Purge onPurge={() => this.onClickAction(actions[key])} />
                break
            case 'links':
                arrayActions[7] = <Links links={actions[key]} />
                break
            case 'exportChoice':
                arrayActions[6] = <ExportChoice data={actions[key]} />
                break
            case 'exportmodel':
                arrayActions[5] = <ExportModel onExport={actions[key]} />
                break
            case 'export':
                arrayActions[4] = <Export onExport={actions[key]} />
                break
            case 'import':
                arrayActions[3] = <Import onClick={actions[key]} />
                break
            case 'importFile':
                arrayActions[2] = <ImportFile {...actions[key]} />
                break
            case 'share':
                arrayActions[1] = <Shared links={actions[key]} />
                break
            case 'calculate':
                arrayActions[0] = <Calculate operationIds={actions[key]}/>
                break
            default:
                arrayActions.unshift(actions[key])
                break
        }
    }

    setNotifActions = actions => {
        AppStore.dispatch(HomeAction.setActions(actions.map(o => {
            return <Notif onClick={o.func} icon={o.icon} label={o.label} name={o.name} color={o.color} content={o.content} onClickTitle={o.onClickTitle} />
        })))
    }

    componentWillUnmount() {
        if (!this.props.persistantActions) {
            AppStore.dispatch(HomeAction.setActions([]))
        }
    }

    render() {
        return null
    }
}

ActionComponent.propTypes = {
    persistantActions: PropTypes.bool,
}

export default ActionComponent
