import ProgressCard from 'components/card/ProgressCard'
import Row from 'components/react/Row'
import { WhiteCard } from 'components/styled/Card'
import { flatten, flattenDeep, groupBy, minBy, orderBy, sum, takeWhile } from 'lodash'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import Axis from '../../components/echart/Axis'
import ChartTabs from '../../components/echart/ChartTabs'
import EChart from '../../components/echart/EChart'
import {
    exportExcelIcon,
    exportPictureIcon,
    fullScreenIcon,
    getAxisIntervalFormatter, toEchartsData,
    yAutomaticScaleValues,
} from '../../components/echart/EChartUtils'
import DtoParametrageDataType from '../../piezometry/dto/DtoParametrageDataType'
import DtoPiezometer from '../../piezometry/dto/DtoPiezometer'
import DtoPiezometerLight from '../../piezometry/dto/DtoPiezometerLight'
import { STATION_TYPE_NAME } from '../../station/constants/StationConstants'
import DtoStationAssociation from '../../station/dto/DtoStationAssociation'
import AppStore from '../../store/AppStore'
import { getColorFromPalette } from '../../utils/ColorUtil'
import { getFullDate, getFullDateMini } from '../../utils/DateUtil'
import { exportFile } from '../../utils/ExportDataUtil'
import { fixedNumber, hasValue, nFormatter } from '../../utils/NumberUtil'
import {
    getPiezometerAdditionalMeasures,
    getPiezometerMeasures,
} from '../../utils/PiezometryUtils'
import { canShowStationTypeData } from '../../utils/SettingUtils'
import { arrayOf, getLabel } from '../../utils/StoreUtils'
import ProductionUnitAction from '../actions/ProductionUnitAction'
import DtoProductionUnit from '../dto/DtoProductionUnit'
import DtoProductionUnitAssociationAdditionalMeasures from '../dto/DtoProductionUnitAssociationAdditionalMeasures'
import DtoProductionUnitAssociationsMeasures from '../dto/DtoProductionUnitAssociationsMeasures'
import { AccordionDetailsMUI, AccordionMUI, AccordionSummaryMUI } from 'components/styled/Accordions'
import MessageCard from 'components/card/MessageCard'

class UnitPiezometryPanel extends Component {
    constructor(props) {
        super(props)
        this.state = { dataLoaded: false, progress: 0 }
    }

    // filter function used to remove stations that are unchecked on LinkedStationsPanel (associated stations)
    filterUncheckedPiezometers = piezometerObj => !this.props.uncheckedStations.find(s => s.id === piezometerObj.piezometerId && s.typeName === STATION_TYPE_NAME.piezometry)

    changeDates = (dates) => {
        if (this.state.minDate !== dates.minDate || this.state.maxDate !== dates.maxDate) {
            const fetchDates = { startDate: dates.minDate ? moment(dates.minDate).subtract(2, 'days').valueOf() : null, endDate: dates.maxDate }
            this.setState(Object.assign({}, dates, { dataLoaded: false, progress: 0 }))
            AppStore.dispatch(ProductionUnitAction.loadProductionUnitPiezometryAssociations(this.props.id, fetchDates, this.props.associations, p => this.setState({ progress: p }), () => {
                this.setState({ dataLoaded: true })
                this.props.setDataLoaded()
            }, this.props.fromStationType))
        }
    }

    getAxisLabel = (axisId) => {
        if (axisId === 0) {
            return i18n.depth
        }
        if (axisId === 1) {
            return i18n.samples
        }
        return orderBy(this.props.piezometryDataTypes, 'id')[axisId - 2].label
    }

    getTooltip() {
        return {
            trigger: 'axis',
            formatter: (params) => {
                const usedParams = takeWhile(params, p => p.axisIndex === params[0].axisIndex)
                const date = getFullDate(moment(usedParams[0].value[0]))
                const group = groupBy(usedParams, 'axisIndex')
                const allResults = orderBy(Object.keys(group), key => key).map(axisId => {
                    const axisLabel = this.getAxisLabel(parseInt(axisId))
                    const paramsOrder = orderBy(group[axisId].map(o => ({
                        marker: o.marker,
                        seriesName: o.seriesName,
                        value: o.value[1],
                        unit: o.data.unit,
                    })), 'value', 'desc')
                    const result = paramsOrder.map(o => `${o.marker} ${o.seriesName} : ${fixedNumber(o.value)} ${o.unit}`)
                    return ['<br/>'].concat([axisLabel], result).join('<br/>')
                })


                return date + allResults
            },
        }
    }

    getExportData = (chartMinDate, chartMaxDate) => {
        const data = flattenDeep([this.getMeasuresExportData(chartMinDate, chartMaxDate), this.getSamplesExportData(chartMinDate, chartMaxDate), this.getAdditionalMeasuresExportData(chartMinDate, chartMaxDate)])
            .map(d => ({ ...d, value: { value: d.value, cellType: 'number' } }))
        if (data.length) {
            data[0].headers = ['stationCode', 'stationName', 'date', 'value', 'type']
        }
        return data
    }

    toExportData = (measure, stationCode, stationName, type) => ({
        date: getFullDateMini(measure.value[0]),
        value: measure.value[1],
        stationCode,
        stationName,
        type,
    })

    getPiezometer = (measuresObj) => this.props.piezometers.find(p => p.id === measuresObj.piezometerId) || {}

    getMeasuresExportData = () => {
        return this.props.rawMeasures.filter(this.filterUncheckedPiezometers).map(piezometerObj => {
            const piezometer = this.getPiezometer(piezometerObj)
            if (piezometerObj.measures.length) {
                return toEchartsData(piezometerObj.measures).map(m => this.toExportData(m, piezometer.code, piezometer.name, i18n.depth))
            }
            return []
        })
    }

    addMeasures = (series, grids, xAxis, yAxis, gridsHeights, chartMinDate, chartMaxDate, axisLabelObj) => {
        const piezosMeasures = this.props.rawMeasures.filter(this.filterUncheckedPiezometers)
        if (piezosMeasures.some(piezometerObj => piezometerObj.measures.length)) {
            grids.push({
                top: 60,
                right: '2%',
                height: 210,
                left: 60,
            })
            piezosMeasures.map((piezometerObj, index) => {
                if (piezometerObj.measures.length) {
                    const echartsMeasures = toEchartsData(piezometerObj.measures)
                    series.push(getPiezometerMeasures(echartsMeasures, null, grids.length - 1, [], [], {
                        name: getLabel(this.props.piezometers, piezometerObj.piezometerId, 'name', 'id'),
                        color: getColorFromPalette(index),
                    }))
                }
            })
            const allValues = series.flatMap(s => s.getValues()).filter(m => hasValue(m))
            const yScale = yAutomaticScaleValues(allValues)
            xAxis.push(Axis({
                type: 'time',
                position: 'bottom',
                min: chartMinDate,
                max: chartMaxDate,
                interval: axisLabelObj.interval,
                axisLabel: { show: false },
                axisLine: { show: false },
                axisTick: { show: false },
                showSplitLine: true,
            }))
            yAxis.push(Axis({
                ...{
                    type: 'value',
                    nameLocation: 'middle',
                    name: `${i18n.depth} (m NGF)`,
                    showSplitLine: true,
                    axisLabel: { formatter: nFormatter },
                    nameGap: 40,
                },
                ...yScale,
            }))
            gridsHeights.push(285)
        } else {
            gridsHeights.push(60)
        }
    }

    getSamplesExportData = () => {
        return this.props.samples.filter(this.filterUncheckedPiezometers).map(piezometerObj => {
            if (piezometerObj.measures.length) {
                const piezometer = this.getPiezometer(piezometerObj)
                return toEchartsData(piezometerObj.measures).map(m => this.toExportData(m, piezometer.code, piezometer.name, i18n.nbSample))
            }
            return []
        })
    }

    getAdditionalMeasuresExportData = () => {
        return this.props.piezometryDataTypes.map(typeObj => {
            return this.props.additionalMeasures.filter(this.filterUncheckedPiezometers).map(piezometerObj => {
                const found = piezometerObj.measures.find(piezometerTypeObj => piezometerTypeObj.type === typeObj.id)
                if (found && found.measures.length) {
                    const piezometer = this.getPiezometer(piezometerObj)
                    return toEchartsData(found.measures).map(m => this.toExportData(m, piezometer.code, piezometer.name, typeObj.label))
                }
                return []
            })
        })
    }

    addAdditionalMeasures = (series, grids, xAxis, yAxis, gridsHeights, chartMinDate, chartMaxDate, axisLabelObj) => {
        this.props.piezometryDataTypes.forEach(typeObj => {
            if (!this.props.fromStationType || canShowStationTypeData(this.props.fromStationType, 'PIEZO', typeObj.id)) {
                const piezoMeasures = this.props.additionalMeasures.filter(this.filterUncheckedPiezometers)
                if (piezoMeasures.some(piezometerObj => {
                    const found = piezometerObj.measures.find(piezometerTypeObj => piezometerTypeObj.type === typeObj.id)
                    return found && found.measures.length
                })) {
                    grids.push({
                        top: sum(gridsHeights),
                        right: '2%',
                        height: 150,
                        left: 80,
                    })
                    const unit = this.props.piezometryDataTypes.find(p => p.id === typeObj.id)?.unit ?? ''
                    const scaleMeasures = piezoMeasures.map((piezometerObj, index) => {
                        const found = piezometerObj.measures.find(piezometerTypeObj => piezometerTypeObj.type === typeObj.id)
                        if (found && found.measures.length) {
                            const echartMeasures = toEchartsData(found.measures, null, null, unit)
                            if (found.type === -2) {
                                series.push(getPiezometerAdditionalMeasures(echartMeasures, getLabel(this.props.piezometers, piezometerObj.piezometerId, 'name', 'id'), getColorFromPalette(index), found.type, grids.length - 1, [], {}, false, true))
                            } else {
                                series.push(getPiezometerAdditionalMeasures(echartMeasures, getLabel(this.props.piezometers, piezometerObj.piezometerId, 'name', 'id'), getColorFromPalette(index), found.type, grids.length - 1))
                            }
                            return found.measures
                        }
                        return []
                    })
                    const yScale = yAutomaticScaleValues(scaleMeasures.flat().map((p) => p.value))
                    xAxis.push(Axis({
                        type: 'time',
                        position: 'bottom',
                        min: chartMinDate,
                        max: chartMaxDate,
                        gridIndex: grids.length - 1,
                        interval: axisLabelObj.interval,
                        axisLabel: { show: false },
                        axisLine: { show: false },
                        axisTick: { show: false },
                        showSplitLine: true,
                    }))
                    yAxis.push(Axis({
                        type: 'value',
                        nameLocation: 'middle',
                        name: `${typeObj.label} ${unit}`,
                        gridIndex: grids.length - 1,
                        showSplitLine: true,
                        axisLabel: { formatter: nFormatter },
                        nameGap: 60,
                        ...yScale,
                    }))
                    gridsHeights.push(165)
                }
            }
        })
    }

    getChartMinDate = () => {
        if (this.state.minDate) {
            return this.state.minDate
        }
        const allAdditionalMeasures = flattenDeep(this.props.additionalMeasures.filter(this.filterUncheckedPiezometers).map(piezometerObj => piezometerObj.measures.map(typeObj => typeObj.measures)))
        const allMeasures = flatten(this.props.rawMeasures.filter(this.filterUncheckedPiezometers).map(m => m.measures))
        const allSamples = flatten(this.props.samples.filter(this.filterUncheckedPiezometers).map(m => m.measures))
        const minAllData = minBy([].concat(allMeasures, allSamples, allAdditionalMeasures), d => d.date || d.sampleDate)
        return minAllData ? minAllData.date || minAllData.sampleDate : moment().subtract(1, 'days').valueOf()
    }

    getChart = () => {
        if (!this.state.dataLoaded) {
            return <ProgressCard className='padding-top-4' progress={this.state.progress} />
        }
        // get minDate of all data
        const chartMinDate = this.getChartMinDate()
        const chartMaxDate = this.state.maxDate || moment().valueOf()
        const series = []
        const grids = []
        const xAxis = []
        const yAxis = []
        const gridsHeights = []

        // construct echart options
        const axisLabelObj = getAxisIntervalFormatter(this.state.maxDate ? moment(this.state.maxDate) : moment(), moment(chartMinDate))
        gridsHeights.push(60)
        this.addAdditionalMeasures(series, grids, xAxis, yAxis, gridsHeights, chartMinDate, chartMaxDate, axisLabelObj)

        if (!series.length) {
            return <div style={{ marginTop: '3.5rem' }} />
        }

        xAxis[xAxis.length - 1].obj = Object.assign({}, xAxis[xAxis.length - 1].obj, {
            axisLabel: { show: true, formatter: axisLabelObj.formatter },
            axisLine: { show: true },
            axisTick: { show: true },
        })
        const options = {
            series,
            tooltip: this.getTooltip(),
            grid: grids,
            xAxis,
            yAxis,
            axisPointer: {
                link: { xAxisIndex: 'all' },
            },
            setDataZoom: true,
            height: sum(gridsHeights),
            toolbox: {
                show: true,
                feature: {
                    myTool2: {
                        show: true,
                        title: i18n.fullScreen,
                        icon: fullScreenIcon,
                        onclick: () => this.setState({ fullScreen: !this.state.fullScreen }),
                    },
                    restore: { title: i18n.restore },
                    saveAsImage: {
                        title: i18n.pictureExport,
                        icon: exportPictureIcon,
                    },
                    myToolExport: {
                        show: true,
                        title: i18n.excelExport,
                        icon: exportExcelIcon,
                        onclick: () => {
                            exportFile({
                                data: this.getExportData(chartMinDate, chartMaxDate),
                                exportType: 'xlsx',
                                titleFile: this.props.productionUnit ? this.props.productionUnit.name : i18n.exportName,
                            })
                        },
                    },
                },
                right: 50,
            },
        }
        return (
            <EChart options={options} id='productionUnitDashboardChart' scrollable={this.state.fullScreen} />
        )
    }

    render() {
        const hasMeasures = this.props.additionalMeasures.filter(this.filterUncheckedPiezometers).some(ad => ad.measures?.some(dm => dm.measures?.length))
        if (this.props.piezometers.length && !this.props.accordionDisplay) {
            return (
                <WhiteCard
                    title={i18n.piezometry}
                    round
                    noMargin={false}
                    className='margin-top-1'
                >
                    <Row className={ this.state.fullScreen ? 'fullscreen-chart' : '' }>
                        <div className='col s12 row no-margin padding-top-1' style={{ paddingLeft: 48 }}>
                            <div>
                                <ChartTabs onChangeDate={ changes => this.changeDates(changes) }/>
                            </div>
                        </div>
                        <div className='col s12 row no-margin'>
                            {hasMeasures && this.getChart()}
                            {!hasMeasures && (
                                <div style={{ marginTop: 45 }}>
                                    <MessageCard>{i18n.noDataToDisplay}</MessageCard>
                                </div>
                            )}
                        </div>
                    </Row>
                </WhiteCard>
            )
        } else if (this.props.piezometers.length) {
            return (
                <AccordionMUI round style={{ marginTop: 10 }} expanded={this.props.expanded} onChange={this.props.setExpanded}>
                    <AccordionSummaryMUI round iconColor='black' style={{ background: 'white', color: 'black' }}>{i18n.piezometry}</AccordionSummaryMUI>
                    <AccordionDetailsMUI>
                        <Row className={this.state.fullScreen ? 'fullscreen-chart' : ''}>
                            <div className='col s12 row no-margin padding-top-1' style={{ paddingLeft: 48 }}>
                                <div>
                                    <ChartTabs onChangeDate={changes => this.changeDates(changes)} />
                                </div>
                            </div>
                            <div className='col s12 row no-margin'>
                                {hasMeasures && this.getChart()}
                                {!hasMeasures && (
                                    <div style={{ marginTop: 45 }}>
                                        <MessageCard>{i18n.noDataToDisplay}</MessageCard>
                                    </div>
                                )}
                            </div>
                        </Row>
                    </AccordionDetailsMUI>
                </AccordionMUI>
            )
        }
        return null
    }
}

UnitPiezometryPanel.propTypes = {
    id: PropTypes.number,
    productionUnit: PropTypes.instanceOf(DtoProductionUnit),
    piezometers: PropTypes.arrayOf(PropTypes.instanceOf(DtoPiezometerLight)),
    measures: PropTypes.arrayOf(PropTypes.instanceOf(DtoProductionUnitAssociationsMeasures)),
    rawMeasures: PropTypes.arrayOf(PropTypes.instanceOf(DtoProductionUnitAssociationsMeasures)),
    samples: PropTypes.arrayOf(PropTypes.instanceOf(DtoProductionUnitAssociationsMeasures)),
    additionalMeasures: PropTypes.arrayOf(PropTypes.instanceOf(DtoProductionUnitAssociationAdditionalMeasures)),
    piezometryDataTypes: PropTypes.arrayOf(PropTypes.instanceOf(DtoParametrageDataType)),
    uncheckedStations: PropTypes.arrayOf(PropTypes.instanceOf(DtoPiezometer)),
    setDataLoaded: PropTypes.func,
    associations: arrayOf(DtoStationAssociation),
    fromStationType: PropTypes.string,
    dontShowEmptyCharts: PropTypes.bool,
    accordionDisplay: PropTypes.bool,
    expanded: PropTypes.bool,
    setExpanded: PropTypes.func,
}

const mapStateToProps = store => {
    return {
        productionUnit: store.ProductionUnitReducer.productionUnit,
        piezometers: store.PiezometryReducer.piezometersLight,
        piezometerMeasureMin: store.PiezometerStationReducer.piezometerMeasureMin,
        measures: store.ProductionUnitReducer.associationsMeasures,
        rawMeasures: store.ProductionUnitReducer.associationsRawMeasures,
        samples: store.ProductionUnitReducer.associationsSamples,
        additionalMeasures: store.ProductionUnitReducer.associationsAdditionalMeasures,
        piezometryDataTypes: store.PiezometryReducer.piezometryDataTypes,
    }
}

export default connect(mapStateToProps)(UnitPiezometryPanel)
