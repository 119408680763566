/* eslint-disable camelcase */
import DtoDangerDocument from './DtoDangerDocument'

export default class DtoDanger {
    constructor(obj, index) {
        this.idDanger = obj.idDanger // Option[Long] = None,
        this.idSurvey = obj.idSurvey // Option[Long] = None,
        this.siteType = obj.siteType // Option[Long] = None,
        this.siteCode = obj.siteCode // Option[Long] = None,
        this.lexiconType = obj.lexiconType // Option[String] = None,
        this.dangerousSituation = obj.dangerousSituation // Option[Long] = None,
        this.comment = obj.comment // Option[String] = None,
        this.idVisit = obj.idVisit // Option[Long] = None,
        this.idCampaign = obj.idCampaign // Option[Long] = None,
        this.idLinkedQuestion = obj.idLinkedQuestion // Option[Long] = None,
        this.idLinkedParagraph = obj.idLinkedParagraph // Option[Long] = None,
        this.definitionDate = obj.definitionDate // Option[DateTime] = None,
        this.resolutionDate = obj.resolutionDate // Option[DateTime] = None,

        this.link_documents = obj.link_documents ? obj.link_documents.map(d => new DtoDangerDocument(d)) : [] // Option[Seq[VisitActionWithLinks]]

        this.index = index
    }
}