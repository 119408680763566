import { push } from '@lagunovsky/redux-react-router'
import HomeAction from 'home/actions/HomeAction'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import AppStore from 'store/AppStore'
import { H_PIEZO_CAMPAIGN } from '../../../account/constants/AccessRulesConstants'
import CampaignAction from '../../../campaign/actions/CampaignAction'
import DashboardCampaigns from '../../../campaign/components/DashboardCampaigns'
import CampaignDto from '../../../campaign/dto/CampaignDto'
import DtoCampaignProgress from '../../../campaign/dto/DtoCampaignProgress'
import ProgressCard from '../../../components/card/ProgressCard'
import SieauAction from '../../../components/sieau/SieauAction'
import { PATH_PIEZOMETRY, PATH_PIEZOMETRY_CAMPAIGN } from '../../../home/constants/RouteConstants'
import { componentHasHabilitations } from '../../../utils/HabilitationUtil'
import DtoPiezometerLight from '../../dto/DtoPiezometerLight'


class PiezometryCampaignsApp extends Component {
    state = {
        dataLoaded: false,
        progress: 0,
    }

    componentDidMount = () => {
        if (!componentHasHabilitations(H_PIEZO_CAMPAIGN)) { // A modifier quand react-router sera à jour
            this.props.push('/unauthorized')
            return
        }
        AppStore.dispatch(HomeAction.setHelpLink('piezometrie', ''))
        this.props.loadPiezometryCampaigns(p => this.setState({ progress: p })).then(() => this.setState({ dataLoaded: true }))
        this.props.forceFetch('title', [{
            title: i18n.piezometry,
            href: PATH_PIEZOMETRY,
        }, {
            title: i18n.campaigns,
            href: PATH_PIEZOMETRY_CAMPAIGN,
        }])
    }

    render() {
        const {
            dataLoaded,
            progress,
        } = this.state
        if (dataLoaded) {
            const {
                piezometers,
                piezometryCampaigns,
                piezometryCampaignsProgress,
            } = this.props
            return (
                <DashboardCampaigns
                    stations={piezometers}
                    campaigns={piezometryCampaigns}
                    campaignsProgress={piezometryCampaignsProgress}
                    stationType={'piezometry'}
                />
            )
        }
        return <ProgressCard progress={ progress }/>
    }
}

PiezometryCampaignsApp.propTypes = {
    getLink: PropTypes.func,

    piezometryCampaigns: PropTypes.arrayOf(PropTypes.instanceOf(CampaignDto)),
    piezometryCampaignsProgress: PropTypes.arrayOf(PropTypes.instanceOf(DtoCampaignProgress)),
    piezometers: PropTypes.arrayOf(PropTypes.instanceOf(DtoPiezometerLight)),

    loadPiezometryCampaigns: PropTypes.func,
    forceFetch: PropTypes.func,
    push: PropTypes.func,
}

const mapStateToProps = store => ({
    piezometryCampaigns: store.PiezometryReducer.piezometryCampaigns,
    piezometryCampaignsProgress: store.PiezometryReducer.piezometryCampaignsProgress,
    piezometers: store.PiezometryReducer.piezometersLight,
})

const mapDispatchToProps = {
    loadPiezometryCampaigns: CampaignAction.loadPiezometryCampaigns,
    forceFetch: SieauAction.forceFetch,
    push,
}

export default connect(mapStateToProps, mapDispatchToProps)(PiezometryCampaignsApp)
