import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import ToastrAction from 'toastr/actions/ToastrAction'
import Checkbox from '../../../../components/forms/Checkbox'
import Job from '../../../dto/DtoJob'

class TrameMateriel extends Component {
    constructor(props) {
        super(props)
        this.state = {
            filter: props.job.parameters.filters.length ? JSON.parse(props.job.parameters.filters[0]) : {},
        }
    }

    onChangeFilters = (changes) => {
        const parameters = {
            ...this.props.job.parameters,
            filters: [JSON.stringify({
                ...this.state.filter,
                ...changes,
            })],
        }
        this.setState({ filter: { ...this.state.filter, ...changes } })
        this.props.onChangeJob({ parameters })
    }

    getLabelCheckBox = () => {
        if (this.state.filter.dataIntegration) {
            return i18n.libImportMateriel
        }
        return i18n.libImportMateriel + i18n.libOnlyCheck
    }


    onImportFile = (e) => {
        if (this.props.isEditMode) {
            const reader = new FileReader()
            const file = e.target.files[0]
            reader.onload = upload => {
                const split = upload.target.result.split(',')
                if (split[0].indexOf('base64') > 0) {
                    this.onChangeFilters({ file: split[1] })
                    this.onChangeFilters({ file: split[1], nameOfFile: file.name })
                } else {
                    this.props.toastrError(i18n.theSelectedFileMustBeInCsvFormat)
                }
            }
            reader.readAsDataURL(file)
        }
    }


    render() {
        const {
            rename,
            dataIntegration,
        } = this.state.filter
        return (
            <div>
                <div className='row no-margin valign-wrapper padding-top-1'>
                    <Checkbox
                        checked={!!rename}
                        label={i18n.fileRenaming}
                        onChange={v => this.onChangeFilters({ rename: v })}
                        disabled={!this.props.isEditMode}
                    />
                </div>
                <div className='row no-margin valign-wrapper padding-top-1'>
                    <Checkbox
                        checked={!!dataIntegration}
                        label={this.getLabelCheckBox()}
                        onChange={v => this.onChangeFilters({ dataIntegration: v })}
                        disabled={!this.props.isEditMode}
                    />
                </div>
                <div className='row no-margin valign-wrapper'>
                    <div className='input-field col s3'>
                        <label>{i18n.importFile}</label>
                    </div>
                    <div className='file-field col s9 no-padding'>
                        <div className='input-field col s3'>
                            <a className='waves-effect waves-light btn col s12'>
                                {i18n.importLabel}
                                <input type='file'
                                    disabled={!this.props.isEditMode}
                                    accept='.xlsx, .XLSX'
                                    onChange={ this.onImportFile }
                                />
                            </a>
                        </div>
                        <div className='input-field col s9 file-path-wrapper'>
                            <input
                                className='file-path validate'
                                type='text'
                                disabled
                                placeholder={i18n.selectFile}
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

TrameMateriel.propTypes = {
    job: PropTypes.instanceOf(Job).isRequired,
    handleChangeParameters: PropTypes.func.isRequired,
    onChangeJob: PropTypes.func.isRequired,
    isEditMode: PropTypes.bool,

    toastrError: PropTypes.func,
}

const mapDispatchToProps = {
    toastrError: ToastrAction.error,
}

export default connect(null, mapDispatchToProps)(TrameMateriel)
