import { isNull } from 'lodash'

export default class DtoPrettyAnalysis {
    constructor(obj) {
        const ana = obj.map(e => isNull(e) ? undefined : e)
        this.qualitometer = ana[0] // Double
        this.id = ana[1] // Double
        this.sample = ana[2] // Double
        this.operation = ana[3] // Double
        this.sampleDate = ana[4] // Option[DateTime]
        this.analysisDate = ana[5] // Option[DateTime]
        this.parameter = ana[6] // Option[String]
        this.unit = ana[7] // Option[String]
        this.result = ana[8] // Option[Double]
        this.remark = ana[9] // Option[String]
        this.support = ana[10] // Option[Double]
        this.localization = ana[11] // Option[String]
        this.lq = ana[12] // Option[Double]
        this.ld = ana[13] // Option[Double]
        this.saturationThreshold = ana[14] // Option[Double]
        this.qualification = ana[15] // Option[String]
        this.status = ana[16] // Option[String]
        this.fraction = ana[17] // Option[String]
        this.labo = ana[18] // Option[Double]
        this.jobexecutionid = ana[19] // Option[Long]
        this.importDate = ana[20] // Option[DateTime]
        this.updateDate = ana[21] // Option[DateTime]
        this.updateLogin = ana[22] // Option[String]
        this.numSampling = ana[23] // Option[Int]
        this.value = ana[24] ?? '' // Option[String] = None,
        this.color = ana[25] ?? 'white' // Option[String] = None,
        this.thresholdState = ana[26] // Option[String] = None,
        this.thresholdValue = ana[27] // Option[Double] = None,
        this.conformity = ana[28] // Option[Boolean] = None,
        this.threshold1 = ana[29] // Option[Double] = None,
        this.threshold2 = ana[30] // Option[Double] = None,
        this.threshold3 = ana[31] // Option[Double] = None,
        this.threshold4 = ana[32] // Option[Double] = None
    }
}