import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import { push } from '@lagunovsky/redux-react-router'
import { max, orderBy } from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import Table from '../../../components/datatable/Table'
import Checkbox from '../../../components/forms/Checkbox'
import SieauAction from '../../../components/sieau/SieauAction'
import { nbPerPageLabel } from '../../../referencial/constants/ReferencialConstants'
import StationAction from '../../../station/actions/StationAction'
import DtoDataType from '../../../station/dto/DtoDataType'
import { arrayOf, instanceOf } from '../../../utils/StoreUtils'
import DataTypeApp from './DataTypeApp'
import ColorOval from '../../../components/icon/ColorOval'

class DataTypesTable extends Component {
    constructor(props) {
        super(props)
        this.state = {
            open: false,
            dataType: new DtoDataType({}),
        }
    }

    setDataType = (dataType) => {
        this.setState({ open: true, dataType })
    }

    getNewMaxId = (project, maxId) => {
        // 6 ids are reserved for hydro
        if (project === 'SIH') {
            return maxId <= 6 ? 7 : maxId + 1
        }
        // 3 ids are reserved for other project
        return maxId <= 3 ? 4 : maxId + 1
    }

    getTable = (data) => {
        const { readMode, dataTypes, project } = this.props
        const maxId = max(dataTypes.map(({ id }) => id))
        const newId = this.getNewMaxId(project, maxId)
        const actions = !readMode ? [{
            iconName: 'note_add',
            tooltip: i18n.add,
            onClick: () => this.setState({ open: true, dataType: new DtoDataType({ id: newId, project }) }),
        }] : []

        const headers = ['name', 'version', 'showDataCheckbox', 'warningLimit', 'alertLimit', 'unit', 'order', 'calculMinCheckbox', 'calculMoyCheckbox', 'calculMaxCheckbox', 'codeParameter', 'monitoringChronicleCheckbox', 'codeUnit', 'color']

        return (
            <Table
                title={ i18n.additionalData }
                data={ data }
                paging
                actions={ actions }
                nbPerPageLabel={ nbPerPageLabel }
                type={{ headers }}
                customHeaders={{
                    showDataCheckbox: i18n.showData,
                    calculMinCheckbox: i18n.calculMin,
                    calculMoyCheckbox: i18n.calculMoy,
                    calculMaxCheckbox: i18n.calculMax,
                    monitoringChronicleCheckbox: i18n.monitoringChronicle,
                    warningLimit: i18n.warningTimeLimit,
                    alertLimit: i18n.alertsTimeLimit,
                }}
                condensed
                sortable
                exportButtonOnHeader
                alterable={!readMode}
                onAlter={ s => this.setDataType(s) }
            />
        )
    }

    getPanel = () => {
        const { dataTypes } = this.props
        const data = orderBy(dataTypes, 'id')
        const dataFormatted = data.map((d) => {
            return {
                ...d,
                showDataCheckbox: d.showData ? (<Checkbox col={ 12 } checked={ d.showData } disabled={ true } />) : '',
                calculMinCheckbox: d.calculMin ? (<Checkbox col={ 12 } checked={ d.calculMin } disabled={ true } />) : '',
                calculMoyCheckbox: d.calculMoy ? (<Checkbox col={ 12 } checked={ d.calculMoy } disabled={ true } />) : '',
                calculMaxCheckbox: d.calculMax ? (<Checkbox col={ 12 } checked={ d.calculMax } disabled={ true } />) : '',
                monitoringChronicleCheckbox: d.monitoringChronicle ? (<Checkbox col={ 12 } checked={ d.monitoringChronicle } disabled={ true } />) : '',
                color: d.color ? (<ColorOval color={d.color}/>) : '',
                colorValue: d.color,
            }
        })
        return this.getTable(dataFormatted)
    }

    onChange = (value) => {
        const { dataType } = this.state
        this.setState({
            dataType: {
                ...dataType,
                ...value,
            },
        })
    }

    onSave = () => {
        const { dataType } = this.state
        const { dataTypes } = this.props
        const updatedDataTypes = [
            ...dataTypes.filter((d) => d.id !== dataType.id),
            { ...dataType, color: dataType.colorValue },
        ]
        const formattedDataTypes = updatedDataTypes.map((d) => new DtoDataType(d))
        this.setState({ open: false })
        this.props.onChange(formattedDataTypes)
    }

    render() {
        const { open, dataType } = this.state
        const { style, project } = this.props
        return (
            <div style={{ margin: 'auto', ...style }}>
                {this.getPanel()}
                <Dialog
                    onClose={() => this.setState({ isOpen: false })}
                    fullWidth
                    maxWidth='lg'
                    open={open}
                >
                    <DialogTitle>Modifier les informations</DialogTitle>
                    <DialogContent>
                        <DataTypeApp project={project} onChange={this.onChange} dataType={dataType} />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.setState({ open: false }) } variant='outlined' >
                            {i18n.close}
                        </Button>
                        <Button onClick={this.onSave} variant='contained' color='primary' disabled={!dataType.name} >
                            {i18n.validate}
                        </Button>
                    </DialogActions>
                </Dialog>

            </div>
        )
    }
}

DataTypesTable.propTypes = {
    fetchDataTypesByProject: PropTypes.func,
    dataTypes: arrayOf(DtoDataType),
    project: PropTypes.oneOf(['SIES', 'SIQ', 'SIH', 'SIP']),
    style: instanceOf(PropTypes.object),
    readMode: PropTypes.bool,
    setPopup: PropTypes.func,
    onChange: PropTypes.func.isRequired,
    push: PropTypes.func,
}

const mapDispatchToProps = {
    fetchDataTypesByProject: StationAction.fetchDataTypesByProject,
    setPopup: SieauAction.setPopup,
    push,
}

export default connect(null, mapDispatchToProps)(DataTypesTable)