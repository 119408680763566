import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import CardFile from 'components/file/CardFile'
import DtoFile from 'components/file/dto/DtoFile'
import { groupBy, uniq, orderBy } from 'lodash'
import { getYearOrString } from 'utils/DateUtil'
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material'
import Icon from 'components/icon/Icon'
import {
    makeStyles,
} from '@mui/styles'
import { getI18nTitleDataLength } from 'utils/StringUtil'

const useStyles = makeStyles(() => ({
    root: {
        fontSize: '15px',
        fontWeight: 'bold',
        minHeight: 40,
        maxHeight: 40,
        '&.Mui-expanded': {
            minHeight: 40,
            maxHeight: 40,
        },
    },
}))

const PictureStationPanel = ({
    stationTypes = [],
    deletePicture = () => {},
    updateFile = () => {},
    editable = false,
    files = [],
    onGroupBy='date',
}) => {
    const classes = useStyles()
    const getActiveLabel = v => v === '1' ? i18n.active : i18n.inactive
    const filesOrderByDate = orderBy(files, (f) => parseInt(f.date), 'desc')
    const dataGroupBy = useMemo(() => {
        switch (onGroupBy) {
            case 'date': return { groups: uniq(filesOrderByDate.map(f => getYearOrString(f.date, i18n.thisYear, '').toString())), dataGroup: groupBy(filesOrderByDate, file => getYearOrString(file.date, i18n.thisYear, '')) }
            case 'file': return { groups: uniq(filesOrderByDate.map(f => f.type)), dataGroup: groupBy(filesOrderByDate, file => file.type) }
            case 'active': return { groups: uniq(filesOrderByDate.map(f => getActiveLabel(f.active))), dataGroup: groupBy(filesOrderByDate, file => getActiveLabel(file.active)) }
            default: return { groups: uniq(filesOrderByDate.map(f => getYearOrString(f.date, i18n.thisYear, '').toString())), dataGroup: groupBy(filesOrderByDate, file => getYearOrString(file.date, i18n.thisYear, '')) }
        }
    }, [filesOrderByDate, onGroupBy])
    return (
        <div className='col l12 no-padding gallery'>
            { dataGroupBy.groups.map((group, i) => {
                const data = dataGroupBy.dataGroup[group]
                return (
                    <div className='padding-1' key={i}>
                        <Accordion
                            style={{
                                borderRadius: '0px',
                                boxShadow: '0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 2px 10px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
                            }}
                            defaultExpanded={i === 0}
                            TransitionProps={{ unmountOnExit: true }}
                        >
                            <AccordionSummary
                                expandIcon={<Icon size='small' icon='expand_more' style={{ color: 'white', borderRadius: '50%', border: 'solid white 2px' }} />}
                                sx={{ color: 'white', fontSize: '18px', background: '#35609f', borderBottom: 'solid 1px grey' }}
                                classes={classes}
                            >
                                {`${group} (${data.length} ${getI18nTitleDataLength(i18n.element, i18n.elements, data.length)})`}
                            </AccordionSummary>
                            <AccordionDetails className='padding-top-2'>
                                {data.map(d => (
                                    <div className='col m3 s12'>
                                        <CardFile
                                            stationTypes={stationTypes}
                                            onClickDelete={ deletePicture }
                                            onUpdate={ updateFile }
                                            editable={ editable }
                                            file={d}
                                            fileType={d.fileType}
                                        />
                                    </div>
                                ))}
                            </AccordionDetails>
                        </Accordion>
                    </div>
                )
            })}
        </div>
    )
}

PictureStationPanel.propTypes = {
    stationTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
    small: PropTypes.bool,
    deletePicture: PropTypes.func,
    updateFile: PropTypes.func,
    onGroupBy: PropTypes.string,
    editable: PropTypes.bool,
    files: PropTypes.arrayOf(PropTypes.instanceOf(DtoFile)),
}

export default PictureStationPanel
