import React from 'react'
import i18n from 'simple-react-i18n'
import ActionComponent from '../../../components/ActionComponent'
import HomeAction from '../../../home/actions/HomeAction'
import AppStore from '../../../store/AppStore'
import ExportOverviewList from './ExportOverviewList'
import ExportOverviewPanel from './ExportOverviewPanel'

class PiezoExportOverviewApp extends ActionComponent {
    constructor(props) {
        super(props)
        this.state = {
            selectedTab: 'OVERVIEW',
        }
    }

    getActive = (panel) => {
        return panel === this.state.selectedTab ? 'active' : ''
    }

    componentDidMount() {
        $('ul.tabs').tabs()
        AppStore.dispatch(HomeAction.setTitle([{
            title: i18n.piezometry,
            href: 'piezometry',
        }, {
            title: i18n.exportOverview,
            href: 'piezometry/exportOverview',
        }]))
    }

    getPanel = () => this.state.selectedTab === 'OVERVIEW' ? <ExportOverviewPanel /> : <ExportOverviewList />

    render() {
        return (
            <>
                <div className='row no-margin'>
                    <ul className='tabs tabs-fixed-width'>
                        <li className='tab' id='OVERVIEW'
                            onClick={() => this.setState({ selectedTab: 'OVERVIEW' })}
                        >
                            <a className={this.getActive('OVERVIEW')}>{ i18n.overview }</a>
                        </li>
                        <li className='tab' id='LIST'
                            onClick={() => this.setState({ selectedTab: 'LIST' })}
                        >
                            <a className={this.getActive('LIST')}>{ i18n.table }</a>
                        </li>
                    </ul>
                </div>
                <div className='row no-margin'>
                    { this.getPanel() }
                </div>
            </>
        )
    }
}


PiezoExportOverviewApp.propTypes = {
}

export default PiezoExportOverviewApp
