export default class DtoCmsModelStation {
    constructor(obj) {
        this.modelId = obj.modelId
        this.stationId = obj.stationId
        this.siteType = obj.siteType
        this.siteCode = obj.siteCode
        this.dataTypeId = obj.dataTypeId
        this.timeAbsence = obj.timeAbsence
        this.installationCode = obj.installationCode
        this.order = obj.order
        this.reference = obj.reference // Option[Boolean]
        this.referenceName = obj.referenceName // Option[String]
        this.regrouping = obj.regrouping // Option[String]
        this.limit = obj.limit // Option[Int]
        this.displayMode = obj.displayMode // Option[Int]
    }
}
