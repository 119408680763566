/* eslint-disable camelcase */
import { Button, Grid, Step, StepLabel, Stepper } from '@mui/material'
import SettingDto from 'administration/dto/SettingDto'
import SimpleTextArea from 'components/forms/SimpleTextArea'
import { DialogActionsMUI, DialogContentMUI, DialogMUI, DialogTitleMUI } from 'components/styled/Dialog'
import DtoAgriTanksType from 'exploitations/dto/DtoAgriTanksType'
import DtoExploitation from 'exploitations/dto/DtoExploitation'
import ExportAction from 'export/actions/ExportAction'
import { compact, groupBy, isEqual, maxBy, round, sumBy, uniqBy } from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import Icon from 'components/icon/Icon'
import DtoCulture from 'referencial/components/cultures/dto/DtoCulture'
import DtoCulturesFamily from 'referencial/components/culturesFamilies/dto/DtoCulturesFamily'
import DtoUsageAgri from 'referencial/components/usage/dto/DtoUsageAgri'
import DtoSandreCode from 'referencial/dto/DtoSandreCode'
import i18n from 'simple-react-i18n'
import DtoSurvey from 'survey/dto/DtoSurvey'
import DtoTankDeclaration from 'survey/dto/DtoTankDeclaration'
import { getDate } from 'utils/DateUtil'
import { formatData } from 'utils/ExportDataUtil'
import { hasValue } from 'utils/NumberUtil'
import { getSettingInt } from 'utils/SettingUtils'
import AgriAction from '../../../agriAdministration/actions/AgriAction'
import Table from '../../../components/datatable/Table'
import NumberField from '../../../components/forms/NumberField'
import Textarea from '../../../components/forms/Textarea'
import Row from '../../../components/react/Row'
import DtoInstallationWithGeoItem from '../../../installation/components/installations/dto/DtoInstallationWithGeoItem'
import CityDto from '../../../referencial/components/city/dto/CityDto'
import { nbPerPageLabelShort, SANDRE } from '../../../referencial/constants/ReferencialConstants'
import { arrayOf } from '../../../utils/StoreUtils'
import { formatMilliers, getI18nTitleData, getI18nTitleDataLength, getSandreLabel } from '../../../utils/StringUtil'
import DtoDeclaration from '../../dto/DtoDeclaration'
import DtoDeclarationVolumes from '../../dto/DtoDeclarationVolumes'

const validationStep = {
    RECAP: 0,
    VOLUMES: 1,
    COMMENT: 2,
}

const usageHeaders = ['type', 'usage', 'requestedVolume', 'askedLowWaterVolume', 'attributedVolumeDisplay', 'attributedLowWaterVolumeDisplay', 'awardComment']
const exportHeaders = ['code', 'name', 'city', 'codeParcelle', 'category', 'description', 'culturesFamily', 'culture', 'typeIrrigation',
    'irrigationMode', 'groundType', 'groundDepth', 'area', 'nbRotations', 'requestPrelevement', 'type', 'usage', 'requestedVolume', 'askedLowWaterVolume', 'allocatedVolume', 'attributedLowWaterVolume']

class DeclarationValidationModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            step: validationStep.RECAP,
            selectedVolumes: props.selectedVolumes || [],
            attributionComment: props.declaration.attributionComment,
            usedPoints: [],
            nbUsagesReal: 0,
            nbUsagesPrevi: 0,
            totalVolumeAsked: 0,
            totalTanks: 0,
            totalTanksSub: 0,
        }
    }

    componentDidMount() {
        const { declaration, agriTanksTypes, applicationSettings } = this.props
        const minVolumeEligibilityTank = getSettingInt(applicationSettings, 'minVolumeEligibilityTank')
        let totalVolumeAsked = 0
        let nbUsagesReal = 0
        let nbUsagesPrevi = 0
        let totalTanks = 0
        let totalTanksSub = 0
        const idsTanks = []
        declaration.link_declarationInstallation.forEach((point) => {
            nbUsagesReal = nbUsagesReal + point.link_usagesCurrent.length
            nbUsagesPrevi = nbUsagesPrevi + point.link_usages.length
            const pointExploit = declaration.link_exploitationInstallation ? (declaration.link_exploitationInstallation.find((p) => p.idInstallation === point.idInstallation) || {}) : {}
            const totalTanksPoint = (pointExploit.link_sampleTanks || []).map((retenue) => {
                if (!idsTanks.includes(retenue.idTank)) {
                    const updatedRetenue = point.link_sampleTanks.find((o) => o.idTank === retenue.idTank)
                    const retenueDecla = updatedRetenue ? {
                        ...retenue,
                        ...new DtoTankDeclaration(updatedRetenue),
                    } : retenue
                    const type = (agriTanksTypes.find((t) => t.id === retenueDecla.tankType) || {})
                    idsTanks.push(retenue.idTank)
                    return {
                        capacity: type.eligibility ? retenueDecla.capacity : 0,
                        capacitySub: type.eligibility && retenueDecla.capacity >= minVolumeEligibilityTank ? (retenueDecla.capacity / 100 * (type.percent || 100)) : 0,
                    }
                }
                return { capacity: 0, capacitySub: 0 }
            })
            const totalTanksPointCapacity = totalTanksPoint.map((c) => c.capacity).reduce((acc, v) => acc + v, 0)
            const totalTanksPointCapacitySub = totalTanksPoint.map((c) => c.capacitySub).reduce((acc, v) => acc + v, 0)
            totalTanks = totalTanks + totalTanksPointCapacity
            totalTanksSub = totalTanksSub + totalTanksPointCapacitySub
            point.link_usages.forEach((usage) => {
                totalVolumeAsked = isNaN(parseInt(usage.requestedYearVolume))
                    ? totalVolumeAsked + 0
                    : totalVolumeAsked + usage.requestedYearVolume
            })
        })
        const usedPoints = declaration.link_declarationInstallation.filter((p) => p.stateCode === 1)
        if (usedPoints.length === 0 && totalVolumeAsked === 0) {
            this.setState({ openModalNothingDeclared: true })
        }
        this.setState({ usedPoints, totalVolumeAsked, nbUsagesReal, nbUsagesPrevi, totalTanks, totalTanksSub })
    }

    componentDidUpdate = (prevProps) => {
        const { selectedVolumes } = this.props

        if (!isEqual(prevProps.selectedVolumes, selectedVolumes)) {
            this.setState({ selectedVolumes })
        }
    }

    onClose = () => {
        this.props.onChange({ open: false })
        this.setState({ step: validationStep.RECAP })
    }

    onValidate = () => {
        const { selectedVolumes, attributionComment } = this.state
        const { declaration } = this.props
        const insts = declaration.link_declarationInstallation || []
        const usages = insts.flatMap(d => d.link_usages || [])
        const selectedVolumesUpdated = usages.map((u) => {
            const volumeFind = selectedVolumes.find((v) => v.idProvisionalUsage === u.idProvisionalUsage)
            if (!volumeFind) {
                return new DtoDeclarationVolumes({
                    idExploitation: u.idExploitation,
                    idInstallation: u.idInstallation,
                    askedYear: u.requestedYear,
                    idSurvey: u.idSurvey,
                    askedVolume: u.requestedYearVolume,
                    idProvisionalUsage: u.idProvisionalUsage,
                    attributedVolume: this.getVolumeRepartition(u, usages),
                })
            }
            return volumeFind
        })
        this.props.validateAttribution({ idExploitation: declaration.idExploitation, attributionComment: attributionComment || ' ' }, selectedVolumesUpdated)
        this.setState({ step: validationStep.RECAP })
        this.props.onChange({ open: false })
    }

    onChangeVolume = (usage, obj) => {
        const { selectedVolumes } = this.state
        const volumeDecla = selectedVolumes.find(sv => sv.idProvisionalUsage === usage.idProvisionalUsage) || {}
        this.setState({
            selectedVolumes: [
                ...selectedVolumes.filter(sv => !(sv.idProvisionalUsage === usage.idProvisionalUsage)),
                new DtoDeclarationVolumes({
                    idExploitation: usage.idExploitation,
                    idInstallation: usage.idInstallation,
                    askedYear: usage.requestedYear,
                    idSurvey: usage.idSurvey,
                    askedVolume: usage.requestedYearVolume,
                    idProvisionalUsage: usage.idProvisionalUsage,
                    ...volumeDecla,
                    attributedVolume: hasValue(volumeDecla.attributedVolume) ? volumeDecla.attributedVolume : usage.requestedYearVolume,
                    ...obj,
                }),
            ].filter(d => !!d),
        })
    }

    getStepper = () => {
        const { step } = this.state
        return (
            <Stepper
                activeStep={step}
                style={{ zoom: '1.2', height: '45px' }}
            >
                <Step
                    className='clickable'
                    onClick={() => {
                        this.setState({ step: validationStep.RECAP })
                    }}
                >
                    <StepLabel>{i18n.exploitation}</StepLabel>
                </Step>
                <Step
                    className='clickable'
                    onClick={() => {
                        this.setState({ step: validationStep.VOLUMES })
                    }}
                >
                    <StepLabel>{i18n.attribution}</StepLabel>
                </Step>
                <Step
                    className='clickable'
                    onClick={() => {
                        this.setState({ step: validationStep.COMMENT })
                    }}
                >
                    <StepLabel>{i18n.comment}</StepLabel>
                </Step>
            </Stepper>
        )
    }

    getType = (usage) => {
        const { usages } = this.props
        const catgoryUsage = usages.find(d => d.idUsage === usage.idUsage)

        return {
            type: < span > {`${catgoryUsage && catgoryUsage.category === 1 ? i18n.agri : i18n.nonAgri} / ${catgoryUsage.description}`}</span>,
            category: catgoryUsage && catgoryUsage.category === 1 ? i18n.agri : i18n.nonAgri,
            description: catgoryUsage.description,
        }
    }

    getUsage = (usage) => {
        const { culturesFamilies, cultures, sandreCodes, modesIrrigations } = this.props

        const typeIrrigation = getSandreLabel(sandreCodes, SANDRE.IRRIGATION_CATEGORIES, usage.irrigationType)
        const groundType = getSandreLabel(sandreCodes, SANDRE.USAGES_TYPES_SOLS, usage.groundType)
        const groundDepth = getSandreLabel(sandreCodes, SANDRE.USAGES_PROFONDEUR_SOLS, usage.groundDepth)
        const requestPrelevement = getSandreLabel(sandreCodes, SANDRE.USAGES_TYPES, usage.requestPrelevement)

        const culture = cultures.find((c) => c.id === usage.idCulture) || {}
        const culturesFamily = culturesFamilies.find((cF) => cF.id === culture.idFamille)
        const irrigationMode = modesIrrigations.find((iM) => iM.id === usage.irrigationMode)

        return {
            usage: (
                <span>
                    {culturesFamily && culture && `${culturesFamily.name} / ${culture.name}`}
                    <br />
                    {typeIrrigation && irrigationMode && `${typeIrrigation} / ${irrigationMode.name}`}
                    <br />
                    {hasValue(usage.area) && groundType && groundDepth && hasValue(usage.nbRotation) &&
                        `${usage.area} ha / ${groundType} / ${groundDepth} / ${usage.nbRotation} RC`
                    }
                    <br />
                    {requestPrelevement}
                </span>
            ),
            culturesFamily: culturesFamily?.name,
            culture: culture?.name,
            typeIrrigation,
            irrigationMode: irrigationMode?.name,
            groundType,
            groundDepth,
            area: usage.area,
            nbRotations: usage?.nbRotation,
            requestPrelevement,
        }
    }

    getLineRepartition = (link) => {
        const { exploitations } = this.props
        const exploitationFound = exploitations.find((d) => d.idExploitation === link.idExploitation) || {}
        return (
            <>
                <span>{exploitationFound.codification} : {hasValue(exploitationFound.lastDeclarationVolumePrevi) ? `${exploitationFound.lastDeclarationVolumePrevi} m3` : i18n.unknown}</span><br />
            </>
        )
    }

    getDialogContent = (usagesData, declaration) => {
        const { step, attributionComment } = this.state
        const { agriTanksTypes, survey, applicationSettings } = this.props

        switch (step) {
            case validationStep.RECAP:
                const { usedPoints, totalVolumeAsked, nbUsagesReal, nbUsagesPrevi, totalTanks, totalTanksSub } = this.state
                const lastIndex = maxBy(declaration.link_chronicles, 'measureDate')
                return (
                    <div>
                        {this.getStepper()}
                        <Row>
                            <Grid className='col s12' container alignItems='center' style={{ overflowY: 'auto' }}>
                                <Grid item xs={12}>
                                    <h4
                                        style={{
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            display: 'flex',
                                        }}
                                    >
                                        {`${i18n.declaration} ${survey.year}`}
                                    </h4>
                                </Grid>
                                <Grid item xs={6} style={{ textAlign: 'right' }}>
                                    <h4>{lastIndex ? getDate(lastIndex.measureDate) : i18n.unknown}</h4>
                                </Grid>
                                <Grid item xs={6} style={{ padding: 5 }}>
                                    <h5>{i18n.lastIndex}</h5>
                                </Grid>
                                <Grid item xs={6} style={{ textAlign: 'right' }}>
                                    <h4>{usedPoints.length}</h4>
                                </Grid>
                                <Grid item xs={6} style={{ padding: 5 }}>
                                    <h5>{getI18nTitleData(i18n.usedPoint, i18n.usedPoints, usedPoints)}</h5>
                                </Grid>
                                <Grid item xs={6} style={{ textAlign: 'right' }}>
                                    <h4>{nbUsagesReal}</h4>
                                </Grid>
                                <Grid item xs={6} style={{ padding: 5 }}>
                                    <h5>{getI18nTitleDataLength(i18n.usageReal, i18n.usagesReal, nbUsagesReal)}</h5>
                                </Grid>
                                <Grid item xs={6} style={{ textAlign: 'right' }}>
                                    <h4>{nbUsagesPrevi}</h4>
                                </Grid>
                                <Grid item xs={6} style={{ padding: 5 }}>
                                    <h5>{getI18nTitleDataLength(i18n.usagePrevi, i18n.usagesPrevi, nbUsagesPrevi)}</h5>
                                </Grid>
                                <Grid item xs={6} style={{ textAlign: 'right' }}>
                                    <h4>{formatMilliers(totalVolumeAsked) || 0}</h4>
                                </Grid>
                                <Grid item xs={6} style={{ padding: 5 }}>
                                    <h5>{' m3 demandés'}</h5>
                                </Grid>
                                <Grid item xs={6} style={{ textAlign: 'right' }}>
                                    <h4>{formatMilliers(round(totalTanks, 0)) || 0}</h4>
                                </Grid>
                                <Grid item xs={6} style={{ padding: 5 }}>
                                    <h5>{' m3 de retenues éligibles'}</h5>
                                </Grid>
                                <Grid item xs={6} style={{ textAlign: 'right' }}>
                                    <h4>{`dont ${formatMilliers(round(totalTanksSub, 0)) || 0}`}</h4>
                                </Grid>
                                <Grid item xs={6} style={{ padding: 5 }}>
                                    <h5>{' m3 de substitution'}</h5>
                                </Grid>
                                <Grid item xs={6} style={{ textAlign: 'right' }}>
                                    <Button variant='contained' color='primary' onClick={() => this.props.changeExploitationAdministrator(declaration.idExploitation, true)}>
                                        {i18n.selfAssign}
                                    </Button>
                                </Grid>
                                <Grid item xs={6} style={{ padding: 5 }}>
                                    <Button variant='contained' color='primary' onClick={() => this.props.exportEditionFull(declaration.idDeclaration)}>
                                        {i18n.synthesis}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Row>
                    </div>
                )
            case validationStep.VOLUMES:
                const totalRequested = formatMilliers(sumBy(usagesData, 'requestedYearVolume'))
                const totalAttributed = formatMilliers(sumBy(usagesData, usage => hasValue(usage.attributedVolume) ? usage.attributedVolume : usage.allocatedVolume))
                const usagesByInstallation = groupBy(usagesData, 'idInstallation')
                const minVolumeEligibilityTank = getSettingInt(applicationSettings, 'minVolumeEligibilityTank')
                return (
                    <div>
                        {this.getStepper()}
                        <Row>
                            <h5 className='col offset-s7 s1 bold'>{'Totaux'}</h5>
                            <h5 className='col'><span className='right'>{`${totalRequested} ${i18n.askedVolume}`}</span></h5>
                            <h5 className='col'><span className='right'>{`${totalAttributed} ${i18n.attributedVolume}`}</span></h5>
                        </Row>
                        {Object.keys(usagesByInstallation).map(key => {
                            const instUsages = usagesByInstallation[key]
                            const inst = instUsages[0]
                            const pointExploit = declaration.link_exploitationInstallation ? declaration.link_exploitationInstallation.find((p) => p.idInstallation === parseInt(key)) : {}
                            const pointDecla = declaration.link_declarationInstallation ? declaration.link_declarationInstallation.find((p) => p.idInstallation === parseInt(key)) : {}
                            const eligibleTanks = compact(pointExploit.link_sampleTanks.map((retenue) => {
                                const updatedRetenue = pointDecla.link_sampleTanks.find((o) => o.idTank === retenue.idTank)
                                const retenueDecla = updatedRetenue ? {
                                    ...retenue,
                                    ...new DtoTankDeclaration(updatedRetenue),
                                } : retenue
                                const type = agriTanksTypes.find((t) => t.id === retenueDecla.tankType) || {}
                                if (type.eligibility && retenueDecla.capacity >= minVolumeEligibilityTank) {
                                    return { id: retenueDecla.id, value: (retenueDecla.capacity / 100) * (type.percent || 100) }
                                }
                                return null
                            }))
                            const totalTanksPoint = eligibleTanks.map((t) => t.value).reduce((acc, v) => acc + v, 0)
                            const tanksValue = eligibleTanks.length ? eligibleTanks.map((t) =>`${t.id || i18n.detention} : ${round(t.value, 0) || 0} m3`): null
                            const pointsShared = (pointExploit.link_shared || []).filter((p) => p.idExploitation !== declaration.idExploitation && p.stateCode === 1)
                            const tanksShared = eligibleTanks.length ? survey.link_declarations.filter((d) => d.idExploitation !== declaration.idExploitation && d.link_points.find((p) => p.stateCode === 1 && p.tanks && eligibleTanks.find((t) => p.tanks.includes(t.id)))) : []
                            const isShared = pointsShared.length || tanksShared.length
                            const tooltip = (
                                <div>
                                    {uniqBy([...pointsShared, ...tanksShared], 'idExploitation').map((p) => this.getLineRepartition(p))}
                                </div>
                            )
                            const data = instUsages.map(u => ({
                                ...u,
                                ...this.getUsage(u),
                                ...this.getType(u),
                            }))
                            return (
                                <Row className='padding-top-1'>
                                    <Table
                                        title={`${inst.name || ''} [${inst.code || ''}] - ${inst.city || ''} - ${inst.codeParcelle || ''} - ${round(totalTanksPoint, 0)} m3 de retenues éligibles ${tanksValue ? `(${tanksValue.toString()})` : ''}`}
                                        id='tablesTofix'
                                        data={data}
                                        nbPerPageLabel={nbPerPageLabelShort}
                                        type={{ headers: usageHeaders }}
                                        customHeaders={{
                                            requestedVolume: <span style={{ whiteSpace: 'pre-wrap' }}>{i18n.requestedVolumeWrap}</span>,
                                            askedLowWaterVolume: <span style={{ whiteSpace: 'pre-wrap' }}>{i18n.askedLowWaterVolumeWrap}</span>,
                                            attributedVolumeDisplay: <span style={{ whiteSpace: 'pre-wrap' }}>{i18n.allocatedVolumeWrap}</span>,
                                            attributedLowWaterVolumeDisplay: <span style={{ whiteSpace: 'pre-wrap' }}>{i18n.attributedLowWaterVolumeWrap}</span>,
                                            awardComment: <span style={{ whiteSpace: 'pre-wrap' }}>{i18n.awardCommentWrap}</span>,
                                        }}
                                        customWidthHeaders={{
                                            type: '10%',
                                            usage: '20%',
                                            requestedVolume: '10%',
                                            askedLowWaterVolume: '10%',
                                            attributedVolumeDisplay: '15%',
                                            attributedLowWaterVolumeDisplay: '10%',
                                            awardComment: '25%',
                                        }}
                                        actions={isShared ? [{
                                            tooltip,
                                            iconName: 'people',
                                            onClick: () => {},
                                            tooltipPosition: 'left',
                                        }] : []}
                                        noFlexCell
                                        condensed
                                        sortable
                                        paging
                                    />
                                </Row>
                            )
                        })}
                        <p className='italic-label'>{i18n.onlyNonSharedTanksAreDeductedFromVolumes}</p>
                    </div>
                )
            case validationStep.COMMENT:
            default:
                return (
                    <div>
                        {this.getStepper()}
                        <Textarea
                            title={i18n.comment}
                            value={attributionComment}
                            onChange={v => this.setState({ attributionComment: v })}
                            rows={25}
                        />
                    </div>
                )
        }
    }

    exportVolumes = (usagesData) => {
        const data = usagesData.map(d => ({
            ...d,
            ...this.getUsage(d),
            ...this.getType(d),
        }))
        this.props.export(formatData([{ ...data[0], headers: exportHeaders }, ...data.slice(1, data.length)]), 'xlsx', `${i18n.attribution}_${this.props.title}`)
    }

    getVolumeRepartition = (usage, usages) => {
        const { declaration, agriTanksTypes, applicationSettings, survey } = this.props

        const minVolumeEligibilityTank = getSettingInt(applicationSettings, 'minVolumeEligibilityTank')
        const pointExploit = declaration.link_exploitationInstallation ? declaration.link_exploitationInstallation.find((p) => p.idInstallation === parseInt(usage.idInstallation)) : {}
        const otherPointsExploit = declaration.link_exploitationInstallation ? declaration.link_exploitationInstallation.filter((p) => p.idInstallation !== parseInt(usage.idInstallation)) : {}
        const pointDecla = declaration.link_declarationInstallation ? declaration.link_declarationInstallation.find((p) => p.idInstallation === parseInt(usage.idInstallation)) : {}
        // const otherPointsDecla = declaration.link_declarationInstallation ? declaration.link_declarationInstallation.filter((p) => p.idInstallation !== parseInt(usage.idInstallation)) : {}
        const eligibleTanks = compact(pointExploit.link_sampleTanks.map((retenue) => {
            const updatedRetenue = pointDecla.link_sampleTanks.find((o) => o.idTank === retenue.idTank)
            const retenueDecla = updatedRetenue ? {
                ...retenue,
                ...new DtoTankDeclaration(updatedRetenue),
            } : retenue
            const type = agriTanksTypes.find((t) => t.id === retenueDecla.tankType) || {}
            const shared = survey.link_declarations.find((d) => d.idExploitation !== declaration.idExploitation && d.link_points.find((p) => p.stateCode === 1 && p.tanks && p.tanks.includes(retenueDecla.id)))
            if (type.eligibility && retenueDecla.capacity >= minVolumeEligibilityTank && !shared) {
                return {
                    idInstallations: otherPointsExploit.filter((p) => p.link_sampleTanks.find((t) => t.idTank === retenueDecla.idTank)).map((p) => p.idInstallation),
                    code: retenueDecla.idTank,
                    id: retenueDecla.id,
                    value: (retenueDecla.capacity / 100) * (type.percent || 100),
                }
            }
            return null
        }))
        const otherInstUsages = usages.filter((u) => u.idInstallation !== usage.idInstallation)
        const pointsShared = (pointExploit.link_shared || []).filter((p) => p.idExploitation !== declaration.idExploitation && p.stateCode === 1)
        const tanksShared = otherInstUsages.filter(u => eligibleTanks.find((t) => t.idInstallations.includes(u.idInstallation)))
        if (pointsShared.length || tanksShared.length) {
            return usage.requestedYearVolume
        }

        const instUsages = usages.filter((u) => u.idInstallation === usage.idInstallation)
        const totalVolumesUsages = instUsages.map((u) => u.requestedYearVolume || 0).reduce((acc, v) => acc + v, 0)
        const totalTanksPoint = eligibleTanks.map((retenue) => retenue.value).reduce((acc, v) => acc + v, 0)
        const value = Math.trunc(usage.requestedYearVolume - (totalTanksPoint * round(usage.requestedYearVolume / totalVolumesUsages, 2)))
        return value < 0 ? 0 : value
    }

    render = () => {
        const { step, selectedVolumes } = this.state
        const { declaration, installationsExploitationGeo, citiesIndex, open, title } = this.props

        const insts = declaration.link_declarationInstallation || []
        const usages = insts.flatMap(d => d.link_usages || [])
        const usagesData = usages.map(usage => {
            const volume = selectedVolumes.find(sv => sv.idProvisionalUsage === usage.idProvisionalUsage) || {}
            const installation = installationsExploitationGeo.find(ieg => ieg.id === usage.idInstallation) || {}
            const allocatedVolume = hasValue(volume.attributedVolume) ? volume.attributedVolume : this.getVolumeRepartition(usage, usages)
            return {
                ...usage,
                ...installation,
                city: (citiesIndex[installation.townCode] || {}).name,
                codeParcelle: `${installation.parcel || ''} ${installation.section || ''}`,
                requestedVolume: formatMilliers(usage.requestedYearVolume),
                askedLowWaterVolume: formatMilliers(usage.lowWaterVolume),
                attributedVolume: volume.attributedVolume,
                allocatedVolume,
                attributedVolumeDisplay: <NumberField col={11} value={allocatedVolume} min={0} onChange={value => this.onChangeVolume(usage, { attributedVolume: value })} />,
                attributedLowWaterVolume: hasValue(volume.attributedLowWaterVolume) ? volume.attributedLowWaterVolume : usage.lowWaterVolume,
                attributedLowWaterVolumeDisplay: <NumberField col={11} value={hasValue(volume.attributedLowWaterVolume) ? volume.attributedLowWaterVolume : usage.lowWaterVolume} min={0} onChange={value => this.onChangeVolume(usage, { attributedLowWaterVolume: value })} />,
                awardComment: <SimpleTextArea col={12} rows={2} value={volume.attributionComment} onChange={value => this.onChangeVolume(usage, { attributionComment: value })} />,
            }
        })

        const titleWithExport = step !== validationStep.VOLUMES ? title : (
            <>
                {title}
                <i className='material-icons no-margin right clickable' onClick={() => this.exportVolumes(usagesData)}>file_download</i>
            </>
        )
        return (
            <DialogMUI
                onClose={this.props.onClose}
                fullWidth
                maxWidth='xl'
                open={open}
                classes='no-padding'
                scroll='paper'
                PaperProps={{
                    sx: {
                        minHeight: '90vh',
                        maxHeight: '90vh',
                    },
                }}
            >
                <DialogTitleMUI style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    {titleWithExport}
                    <Icon style={{ color: 'white' }} size='small' icon='close' onClick={this.props.onClose} />
                </DialogTitleMUI>
                <DialogContentMUI>
                    {this.getDialogContent(usagesData, declaration)}
                </DialogContentMUI>
                <DialogActionsMUI style={{ justifyContent: 'space-between' }}>
                    <Button onClick={this.props.onClose} variant='outlined' >
                        {i18n.close}
                    </Button>
                    <Button onClick={() => this.setState({ step: step - 1 })} variant='outlined' disabled={step === validationStep.RECAP}>
                        {i18n.previous}
                    </Button>
                    <Button onClick={() => this.setState({ step: step + 1 })} variant='outlined' disabled={step === validationStep.COMMENT}>
                        {i18n.next}
                    </Button>
                    <Button onClick={this.onValidate} variant='contained' color='primary'>
                        {i18n.validate}
                    </Button>
                </DialogActionsMUI>
            </DialogMUI>
        )
    }
}

DeclarationValidationModal.propTypes = {
    open: PropTypes.bool,
    title: PropTypes.string,
    idDeclaration: PropTypes.number,
    idExploitation: PropTypes.number,

    onChange: PropTypes.func,
    onClose: PropTypes.func,
    exportEditionFull: PropTypes.func,
    validateAttribution: PropTypes.func,
    changeExploitationAdministrator: PropTypes.func,
    export: PropTypes.func,

    citiesIndex: arrayOf(CityDto),
    selectedVolumes: arrayOf(DtoDeclarationVolumes),
    declaration: PropTypes.instanceOf(DtoDeclaration),
    installationsExploitationGeo: arrayOf(DtoInstallationWithGeoItem),
    exploitations: arrayOf(DtoExploitation),
    culturesFamilies: arrayOf(DtoCulturesFamily),
    sandreCodes: arrayOf(DtoSandreCode),
    modesIrrigations: arrayOf(),
    cultures: arrayOf(DtoCulture),
    usages: arrayOf(DtoUsageAgri),
    agriTanksTypes: arrayOf(DtoAgriTanksType),
    survey: PropTypes.instanceOf(DtoSurvey),
    applicationSettings: arrayOf(SettingDto),
}

const mapStateToProps = store => ({
    citiesIndex: store.CityReducer.citiesIndex,
    declaration: store.AgriReducer.declaration,
    installationsExploitationGeo: store.InstallationReducer.installationsExploitationGeo,
    exploitations: store.AgriReducer.exploitations,
    culturesFamilies: store.CulturesFamilyReducer.culturesFamilies,
    cultures: store.CultureReducer.cultures,
    sandreCodes: store.ReferencialReducer.sandreCodes,
    modesIrrigations: store.AgriReducer.modesIrrigations,
    usages: store.UsageReducer.usages,
    agriTanksTypes: store.AgriReducer.agriTanksTypes,
    survey: store.AgriReducer.survey,
    applicationSettings: store.AdministrationReducer.applicationSettings,
})

const mapDispatchToProps = {
    exportEditionFull: AgriAction.exportEditionFullJson,
    changeExploitationAdministrator: AgriAction.changeExploitationAdministrator,
    export: ExportAction.export,
}

export default connect(mapStateToProps, mapDispatchToProps)(DeclarationValidationModal)
