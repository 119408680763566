import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { StyledFieldSet, StyledLegend } from '../../../../../components/StyledElements'
import i18n from 'simple-react-i18n'
import Checkbox from '../../../../../components/forms/Checkbox'
import { HYPE_TRENDS_CONSTANTS } from '../../../../../quality/constants/QualityConstants'
import NumberField from '../../../../../components/forms/NumberField'
import Button from '../../../../../components/forms/Button'
import Row from '../../../../../components/react/Row'
import { hasValue } from '../../../../../utils/NumberUtil'
import SieauAction from '../../../../../components/sieau/SieauAction'
import { getHypeTrends } from '../../../../../utils/HypeUtils'
import { MEASURE_COTE } from '../../../../constants/PiezometryConstants'
import { ceil, chunk, findLast, last, orderBy, take, groupBy, maxBy, range } from 'lodash'
import moment from 'moment'
import SimpleDatePicker from '../../../../../components/forms/SimpleDatePicker'
import IAEauAction from '../../../../../iaeau/IAEauAction'
import { getColorFromPalette, getColorFromPalette2 } from '../../../../../utils/ColorUtil'
import Line from '../../../../../components/echart/series/Line'
import ElementCard from '../../../../../components/card/ElementCard'
import Switch from '../../../../../components/forms/Switch'
import { getDryingTrendSpecificOptions } from '../../../../../utils/PiezometryUtils'
import AppStore from '../../../../../store/AppStore'
import WaitAction from '../../../../../wait/WaitAction'
import PluviometryAction from '../../../../../pluviometry/actions/PluviometryAction'
import RadioButtons from '../../../../../components/forms/RadioButtons'
import Band from '../../../../../components/echart/series/Band'
import { getDate, getMonthList, getWeekYear } from '../../../../../utils/DateUtil'
import { MODEL_OBJECTIVES, MODEL_TYPES } from '../../../../../iaeau/constants/IAEauConstants'
import DtoPredStat from '../../../../../iaeau/dto/DtoPredStat'
import { onChangeDate } from '../../../../../utils/FormUtils'
import Input from '../../../../../components/forms/Input'
import { getMapSituationCalendar } from '../../../../../station/components/mapSituation/MapSituationUtils'
import Icon from '../../../../../components/icon/Icon'
import Select from '../../../../../components/forms/Select'
import { Icon as IconMui, Popover } from '@mui/material'
import { ButtonMUI } from '../../../../../components/styled/Buttons'
import { createIndex } from '../../../../../utils/StoreUtils'
import DtoPredMeasure from '../../../../../iaeau/dto/DtoPredMeasure'
import { getI18nOrLabel } from '../../../../../utils/StringUtil'
import MultiBand from '../../../../../components/echart/series/MultiBand'
import ToastrAction from '../../../../../toastr/actions/ToastrAction'
import { MODELS } from '../../constants/PiezometerSuiviConstants'

const toEchartMeasure = (m, displayCote, lastLandmark, measure) => ({ value: [moment(m[0]).hour(12).valueOf(), displayCote === MEASURE_COTE.NGF ? m[1] : lastLandmark - m[1], { NGF: m[1], depth: lastLandmark - m[1], measure }, m[2], m[3]], isPiezo: true })

const calculateModelsRec = (cb, models, modelDate, modelResults, measures) => {
    if (!models.length) {
        cb(modelResults)
    } else {
        const [model, ...tail] = models
        const key = `${model.typeModel}:${model.idModel}`
        AppStore.dispatch(WaitAction.waitStart())
        IAEauAction.launchModelSync(model, modelDate[key] || last(measures)?.date || moment().valueOf()).then(results => {
            AppStore.dispatch(WaitAction.waitStop())
            calculateModelsRec(cb, tail, modelDate, { ...modelResults, [key]: results }, measures)
        }).catch(() => {
            AppStore.dispatch(WaitAction.waitStop())
        })
    }
}

const calculateModels = (cb, models, modelDate, modelResults, measures, modelDisplay) => {
    if (Object.keys(modelDisplay).some(key => modelDisplay[key])) {
        const modelsToCalculate = models.filter(m => [MODEL_TYPES.ARIMA, MODEL_TYPES.AUGURES, MODEL_TYPES.PERCEPTRON].includes(m.typeModel)).filter(m => modelDisplay[`${m.typeModel}:${m.idModel}`])
        calculateModelsRec(cb, modelsToCalculate, modelDate, modelResults, measures)
    } else {
        cb()
    }
}

const getDefaultSeries = (pred, results, displayCote, landmarkValue) => {
    const allSeries = groupBy(results, 'serieName')
    const nbLineSeries = Object.keys(allSeries).filter(key => hasValue(allSeries[key][0].value)).length
    return Object.keys(allSeries).flatMap((serieName, idx) => {
        const orderedMeasures = orderBy(allSeries[serieName], 'date')
        if (hasValue(orderedMeasures[0].doubtMin)) {
            const lower = {
                showSymbol: false,
                color: pred?.model?.color || 'grey',
                data: orderedMeasures.map(m => toEchartMeasure([m.date, m.doubtMin], displayCote, landmarkValue, m)),
                name: `${pred.source} - ${getI18nOrLabel(serieName)}`,
                isArea: true,
            }
            const upper = {
                showSymbol: false,
                color: pred?.model?.color || 'grey',
                data: orderedMeasures.map(m => toEchartMeasure([m.date, m.doubtMax], displayCote, landmarkValue, m)),
                name: `${pred.source} - ${getI18nOrLabel(serieName)}`,
                isArea: true,
            }
            return [MultiBand({ bands: displayCote === MEASURE_COTE.NGF ? [lower, upper] : [upper, lower], noSort: true, noGap: false, stack: `${pred.source}${idx}` })]
        }
        return Line({
            data: orderedMeasures.map(m => toEchartMeasure([m.date, m.value], displayCote, landmarkValue, m)),
            name: `${pred.source} - ${getI18nOrLabel(serieName)}`,
            color: nbLineSeries > 1 ? getColorFromPalette(idx) : (pred?.model?.color || 'black'),
            connectNulls: true,
            lineStyle: {
                normal: {
                    color: nbLineSeries > 1 ? getColorFromPalette(idx) : (pred?.model?.color || 'black'),
                    width: pred?.model?.lineWidth || 2,
                    type: pred?.model?.lineType || 'dashed',
                    opacity: pred?.model?.lineOpacity || 1,
                },
            },
            itemStyle: {
                normal: {
                    color: nbLineSeries > 1 ? getColorFromPalette(idx) : (pred?.model?.color || 'black'),
                },
            },
            showSymbol: false,
            isPiezo: true,
        })
    })
}

const getSeries = (pred, results, displayCote, landmarkValue) => {
    switch (pred.model?.typeModel) {
        case MODEL_TYPES.HYPE:
            return []
        default:
            return getDefaultSeries(pred, results, displayCote, landmarkValue)
    }
}

const loadModelResults = ({ predStats, modelCheck, modelDate, iaeauModels, piezometer, displayCote, landmarkValue, cb }) => {
    const preds = createIndex(predStats, 'source')
    const predsToLoad = Object.keys(modelCheck).filter(key => modelCheck[key]).map(key => preds[key])
    const promises = predsToLoad.map(pred => IAEauAction.promiseModelMeasures('piezometry', piezometer.id, pred.idModel, pred.source, modelDate[pred.source]))
    AppStore.dispatch(WaitAction.waitStart())
    Promise.all(promises).then(jsonTab => {
        AppStore.dispatch(WaitAction.waitStop())
        const series = jsonTab.flatMap((json, idx) => {
            const pred = predsToLoad[idx]
            const pred2 = pred.idModel ? { ...pred, model: iaeauModels.find(m => m.idModel === pred.idModel) } : pred
            return getSeries(pred2, json.map(j => new DtoPredMeasure(j)), displayCote, landmarkValue)
        })
        cb(series)
    }).catch(() => AppStore.dispatch(WaitAction.waitStop()))
}

const years = range(moment().year(), 1949, -1).map(y => ({ value: y, label: y }))

const PiezoSuiviModelTab2 = ({
    tab,
    id,
    displayCote,
    histoYears,
    landmarkValue, // sers à caluler la profondeur : depth = landmarkValue - NGF
    changeParent, // met à jour les state du parent (dont les séries liées à cette tab)
    minDate,
    maxDate,
    measures,
    piezometer,
    typeId,
    isPiezo,
}) => {
    const dispatch = useDispatch()

    const { iaeauModels, associatedSites } = useSelector(store => ({
        iaeauModels: store.IAEauReducer.iaeauModels,
        associatedSites: store.StationReducer.associatedSites,
    }), shallowEqual)

    const [modelTitleClick, setModelTitleClick] = useState(0)
    const [predStats, setPredStats] = useState([])
    const [modelCheck, setModelCheck] = useState({})
    const [modelDate, setModelDate] = useState({})
    const [prevSucc, setPrevSucc] = useState({})
    const [modelCalendarDates, setModelCalendarDates] = useState({})
    const [selectedMonth, setSelectedMonth] = useState({})
    const [selectedYear, setSelectedYear] = useState({})
    const [calendarRefs, setCalendarRefs] = useState({})
    const [calendarOpen, setCalendarOpen] = useState({})


    // HYPE
    const [runHype, setRunHype] = useState(0)
    const [displayHypeTrends, setDisplayHypeTrends] = useState(false)
    const [hypeDate, setHypeDate] = useState(last(measures)?.date || moment().valueOf())
    const [hypeType, setHypeType] = useState({
        [HYPE_TRENDS_CONSTANTS.MANN_KENDALL]: true,
        [HYPE_TRENDS_CONSTANTS.AVERAGE_RUPTURE]: false,
        [HYPE_TRENDS_CONSTANTS.LINEAR_REGRESSION]: false,
        [HYPE_TRENDS_CONSTANTS.TREND_RUPTURE]: false,
    })
    const [hypeTrends, setHypeTrends] = useState({})
    const changeHypeType = (changes) => setHypeType({ ...hypeType, ...changes })

    // augures
    const [runAugures, setRunAugures] = useState(0)
    const [displayAugures, setDisplayAugures] = useState(false)
    const [augureDate, setAugureDate] = useState(last(measures)?.date || moment().valueOf())
    const [augureHorizon, setAugureHorizon] = useState(30)
    const [augureAdvanced, setAugureAdvanced] = useState(false)
    const [augureAverage, setAugureAverage] = useState(7)
    const [augures, setAugures] = useState([])
    const [auguresChecked, setAuguresChecked] = useState({})

    // drying
    const [dryingCurves, setDryingCurves] = useState([])
    const [runDryingCurves, setRunDryingCurves] = useState(0)
    const [displayDryingCurves, setDisplayDryingCurves] = useState(false)
    const [dryingDate, setDryingDate] = useState(last(measures)?.date || moment().valueOf())
    const [dryingHorizon, setDryingHorizon] = useState(10)
    const [dryingError, setDryingError] = useState(null)
    const [dryingAdvanced, setDryingAdvanced] = useState(false)
    const [dryingPercent, setDryingPercent] = useState(90)
    const [dryingNbDays, setDryingNbDays] = useState(5)
    const [dryingCote, setDryingCote] = useState(true)
    const [dryingSeason, setDryingSeason] = useState(false)

    // ARIMA
    const [arimaDate, setArimaDate] = useState(last(measures)?.date || moment().valueOf())
    const [arimaRun, setArimaRun] = useState(0)
    const [arimaHorizon, setArimaHorizon] = useState(120)
    const [arimaDisplay, setArimaDisplay] = useState(false)
    const [arimaResults, setArimaResults] = useState([])

    // models
    const [modelRun, setModelRun] = useState({})
    const [modelDisplay, setModelDisplay] = useState({})
    const [modelResults, setModelResults] = useState({})

    // magicModel
    const [pluvioStats, setPluvioStats] = useState({})
    const [magicModelDate, setMagicModelDate] = useState(last(measures)?.date || moment().valueOf())
    const [magicModelDisplay, setMagicModelDisplay] = useState(false)
    const [magicModelResults, setMagicModelResults] = useState([])
    const [magicModelRun, setMagicModelRun] = useState(0)
    const [magicModelScenario, setMagicModelScenario] = useState(1)
    const [magicModelHorizon, setMagicModelHorizon] = useState(30)

    const advancedMode = modelTitleClick >= 5

    useEffect(() => {
        setModelCheck({})
        changeParent({ modelsSeries: [] })
    }, [typeId])

    useEffect(() => {
        AppStore.dispatch(IAEauAction.getModels('piezometry', piezometer.id))
        IAEauAction.promisePredStats('piezometry', piezometer.id).then(pr => {
            const newPred = pr.map(p => new DtoPredStat(p))
            setPredStats(newPred)
            setModelDate(newPred.reduce((acc, v) => ({ ...acc, [v.source]: v.maxSimulationDate }), {}))
            setModelCalendarDates(newPred.reduce((acc, v) => ({ ...acc, [v.source]: v.dates }), {}))
            setSelectedMonth(newPred.reduce((acc, v) => ({ ...acc, [v.source]: v.maxSimulationDate }), {}))
            setSelectedYear(newPred.reduce((acc, v) => ({ ...acc, [v.source]: v.maxSimulationDate }), {}))
            setPrevSucc(newPred.reduce((acc, v) => ({ ...acc, [v.source]: [v.previousDate, undefined] }), {}))
            setCalendarRefs(newPred.reduce((acc, v) => ({ ...acc, [v.source]: React.createRef() }), {}))
        })
    }, [])

    const reloadModelResultsDates = (newSelectedMonth, newSelectedYear, pred) => {
        IAEauAction.promiseModelResultDates('piezometry', piezometer.id, pred.idModel, pred.source, moment(newSelectedYear).month(moment(newSelectedMonth).month()).valueOf())
            .then(predResultDates => {
                setSelectedMonth({ ...selectedMonth, [pred.source]: newSelectedMonth })
                setSelectedYear({ ...selectedYear, [pred.source]: newSelectedYear })
                setModelCalendarDates({ ...modelCalendarDates, [pred.source]: predResultDates })
            })
    }

    const changePrevSucc = (pred, newDate) => {
        if (newDate) {
            setModelDate({ ...modelDate, [pred.source]: newDate })
            IAEauAction.promiseModelResultPrevSuccDates('piezometry', piezometer.id, pred.idModel, pred.source, newDate).then(res => {
                setPrevSucc({ ...prevSucc, [pred.source]: res }) // [previous, next]
            })
        }
    }

    const calculateHype = (cb) => {
        if (!displayHypeTrends) {
            cb([])
        } else {
            const filtered = measures.filter(m => hasValue(m.NGF) && m.date >= minDate && m.date <= hypeDate)
            const grouped = groupBy(filtered, m => getWeekYear(m.date))
            const weekMeasures = orderBy(Object.keys(grouped).map(key => maxBy(grouped[key], 'NGF')), 'date')
            const hypeData = weekMeasures.map((m, index) => ({
                stationCode: '',
                stationTypeCode: '',
                parameterCode: 1,
                analysisNumber: index,
                measureDate: m.date,
                measure: m.NGF,
            }))
            dispatch(SieauAction.fetchHypeTrends(hypeData, hypeResults => {
                cb(hypeResults)
            }))
        }
    }

    useEffect(() => {
        calculateHype(hypeResults => {
            setHypeTrends(hypeResults)
        })
    }, [runHype])

    const calculateAugure = (cb) => {
        if (!displayAugures) {
            cb({})
        } else {
            const dayAugureDate = moment(augureDate).endOf('day').valueOf()
            const auguresData = measures.flatMap(m => m.date <= dayAugureDate ? [{ ...m, value: m.NGF }] : [])
            dispatch(IAEauAction.getAugures(auguresData, augureHorizon, augureAverage, piezometer.code, result => {
                const allResults = result.filter(r => hasValue(r.year))
                const auguresSelected = take(orderBy(allResults, ['proba', 'year'], ['desc', 'desc']), 5).map(a => a.year)
                cb({
                    augures: allResults,
                    auguresChecked: allResults.reduce((acc, val) => ({ ...acc, [val.year]: auguresSelected.includes(val.year) }), {}),
                })
            }))
        }
    }

    useEffect(() => {
        calculateAugure(auguresResults => {
            setAugures(auguresResults.augures || [])
            setAuguresChecked(auguresResults.auguresChecked || {})
        })
    }, [runAugures])

    const calculateArima = (cb) => {
        if (!arimaDisplay) {
            cb([])
        } else {
            const boolshitModel = {
                idStation: piezometer.id,
                idModel: -1,
                typeModel: 'ARIMA',
                typeId: -1,
                name: 'ARIMA-HOT',
                horizon: arimaHorizon,
                horizonMode: 'days',
                params: JSON.stringify({
                    p: 0,
                    q: 0,
                    d: 0,
                    P: 0,
                    Q: 0,
                    D: 0,
                    learningMode: false,
                    validOnly: false,
                    area50: false,
                    area90: false,
                    measureGroupMode: 'MAX',
                }),
            }
            IAEauAction.launchModelHot(boolshitModel, arimaDate).then(result => cb(result))
        }
    }

    useEffect(() => {
        calculateArima(setArimaResults)
    }, [arimaRun])

    const calculateDryingCurves = (cb) => {
        if (!displayDryingCurves) {
            cb()
        } else {
            const dryingData = measures.flatMap(m => m.date <= dryingDate ? [{ ...m, value: m.NGF }] : [])
            IAEauAction.launchDryingUpAprona({
                dryingDate,
                dryingCote,
                dryingNbDays,
                dryingPercent,
                dryingHorizon,
                dryingSeason,
            }, dryingData, piezometer.code).then(result => cb(result))
        }
    }

    useEffect(() => {
        calculateDryingCurves(dryingResult => {
            setDryingError(dryingResult?.error)
            setDryingCurves(dryingResult?.values || [])
        })
    }, [runDryingCurves])

    const calculateMagicModel = (cb) => {
        if (!magicModelDisplay) {
            cb([])
        } else {
            AppStore.dispatch(WaitAction.waitStart())
            IAEauAction.promiseModels('piezometry', -1).then(models => {
                const magicModel = models.find(m => m.idModel === -1)
                if (!magicModel) {
                    throw Error('Magic model does not exists !')
                }
                const newMagicModel = { ...magicModel, params: JSON.stringify({ piezoId: piezometer.id, piezoCode: piezometer.code, scenario: magicModelScenario, horizon: magicModelHorizon }) }
                IAEauAction.launchModelSync(newMagicModel, magicModelDate || last(measures)?.date || moment().valueOf()).then(results => {
                    AppStore.dispatch(WaitAction.waitStop())
                    setMagicModelResults(results)
                }).catch(() => {
                    AppStore.dispatch(WaitAction.waitStop())
                })
            })
        }
    }

    useEffect(() => {
        calculateMagicModel(results => {
            setMagicModelResults(results)
        })
    }, [magicModelRun])


    useEffect(() => {
        calculateModels(results => {
            setModelResults(results || {})
        }, iaeauModels, modelDate, modelResults, measures, modelDisplay)
    }, [modelRun])

    // recalculate series
    useEffect(() => {
        // HYPE
        const hypeSeries = (() => {
            if (!displayHypeTrends) {
                return []
            }
            const trends = (hypeTrends?.trends || []).filter(trend => hypeType[trend.statisticsType])
            const ruptures = (hypeTrends?.breaks || []).filter(trend => hypeType[trend.statisticsType])
            const max = displayCote === MEASURE_COTE.NGF ? 2000 : landmarkValue - 2000
            const min = displayCote === MEASURE_COTE.NGF ? -1000 : landmarkValue - (-1000)

            const series = getHypeTrends(trends, ruptures, minDate, maxDate, min, max)
            series.forEach(s => {
                s.updateObj({ data: s.obj.data.map(m => toEchartMeasure(m, displayCote, landmarkValue)), isPiezo: true, noYScale: true })
            })
            return series
        })()


        // augures
        const auguresSeries = (() => {
            if (displayAugures) {
                const lastMeasure = findLast(measures, m => m.date <= augureDate) || {}
                return Object.keys(auguresChecked).filter(year => auguresChecked[year]).map((year, index) => {
                    return Line({
                        data: [toEchartMeasure([lastMeasure.date, lastMeasure.value], displayCote, landmarkValue), ...augures.find(a => a.year === year).values].map(m => toEchartMeasure(m, displayCote, landmarkValue)),
                        name: `augure ${year}`,
                        color: getColorFromPalette2(index),
                        isPiezo: true,
                        connectNulls: false,
                        showSymbol: false,
                        lineStyle: { type: 'dashed' },
                    })
                })
            }
            return []
        })()

        // ARIMA
        const arimaSeries = (() => {
            if (arimaDisplay && arimaResults.length) {
                // const lastMeasure = findLast(measures, m => m.date <= augureDate) || {}
                return [Line({
                    data: arimaResults.map(m => toEchartMeasure([m.date, m.value], displayCote, landmarkValue)),
                    name: 'ARIMA',
                    color: 'black',
                    isPiezo: true,
                    connectNulls: false,
                    showSymbol: false,
                    lineStyle: { type: 'dashed' },
                })]
            }
            return []
        })()

        // drying
        const dryingSeries = (() => {
            if (displayDryingCurves && dryingCurves.length) {
                return [6, 7, 5, 4].map(idxTrend => {
                    if (dryingCurves[0][idxTrend] !== 'NA') {
                        return Line({
                            data: dryingCurves.map(m => toEchartMeasure([m[0], m[idxTrend]], displayCote, landmarkValue)),
                            name: `courbe colonne ${idxTrend}`,
                            color: getColorFromPalette2(idxTrend),
                            connectNulls: true,
                            ...getDryingTrendSpecificOptions(idxTrend),
                            lineStyle: { type: 'dashed' },
                            showSymbol: false,
                            isPiezo: true,
                        })
                    }
                    return null
                }).filter(m => !!m)
            }
            return []
        })()

        // modèles
        const modelSeries = (() => {
            const modelKeysToDisplay = Object.keys(modelDisplay).filter(key => modelDisplay[key])
            if (modelKeysToDisplay.length) {
                return modelKeysToDisplay.flatMap(key => {
                    const results = modelResults[key]
                    const [typeModel, modelId] = key.split(':')
                    const model = iaeauModels.find(m => m.typeModel === typeModel && m.idModel === parseInt(modelId))
                    if (results && results.length && model) {
                        const ordered = orderBy(results, 'date')
                        const series = []
                        series.push(Line({
                            data: ordered.map(m => toEchartMeasure([m.date, m.value], displayCote, landmarkValue)),
                            name: model.name,
                            color: model.color || 'black',
                            connectNulls: true,
                            lineStyle: { type: 'dashed' },
                            showSymbol: false,
                            isPiezo: true,
                        }))

                        if (ordered[0].doubtMin) {
                            series.push(Line({
                                data: ordered.map((m, idx) => toEchartMeasure([m.date, idx === 0 ? m.value : m.doubtMin], displayCote, landmarkValue)),
                                name: `${model.name} - Min`,
                                color: '#0084ee',
                                connectNulls: true,
                                lineStyle: { type: 'dashed' },
                                showSymbol: false,
                                isPiezo: true,
                            }))
                        }
                        if (ordered[0].doubtMax) {
                            series.push(Line({
                                data: ordered.map((m, idx) => toEchartMeasure([m.date, idx === 0 ? m.value : m.doubtMax], displayCote, landmarkValue)),
                                name: `${model.name} - Max`,
                                color: '#c90000',
                                connectNulls: true,
                                lineStyle: { type: 'dashed' },
                                showSymbol: false,
                                isPiezo: true,
                            }))
                        }
                        return series
                    }
                    return []
                })
            }
            return []
        })()

        // magicModel
        const magicModelSeries = (() => {
            if (magicModelDisplay && magicModelResults.length) {
                const ordered = orderBy(magicModelResults, 'date')
                const series = []
                const lastMeasure = findLast(measures, m => m.date <= magicModelDate) || {}
                series.push(Line({
                    data: [toEchartMeasure([lastMeasure.date, lastMeasure.value], displayCote, landmarkValue), ...ordered.map(m => toEchartMeasure([m.date, m.value], displayCote, landmarkValue))],
                    name: 'Prévision par scénario',
                    color: 'black',
                    connectNulls: true,
                    lineStyle: { type: 'dashed' },
                    showSymbol: false,
                    isPiezo: true,
                }))

                if (ordered[0].doubtMin && ordered[0].doubtMax) {
                    // series.push(Line({
                    //     data: ordered.map((m, idx) => toEchartMeasure([m.date, idx === 0 ? m.value : m.doubtMin], displayCote, landmarkValue)),
                    //     name: 'Prévision par scénario - Min',
                    //     color: '#0084ee',
                    //     connectNulls: true,
                    //     lineStyle: { type: 'dashed' },
                    //     showSymbol: false,
                    //     isPiezo: true,
                    // }))
                    const minNGF = ordered.map((m, idx) => toEchartMeasure([m.date, idx === 0 ? m.value : m.doubtMin], displayCote, landmarkValue))
                    const maxNGF = ordered.map((m, idx) => toEchartMeasure([m.date, idx === 0 ? m.value : m.doubtMax], displayCote, landmarkValue))
                    series.push(Band({
                        color: '#003d6c',
                        isPiezo: true,
                        showSymbol: false,
                        [displayCote === MEASURE_COTE.NGF ? 'min' : 'max']: {
                            name: 'Prévision par scénario - Min',
                            data: minNGF,
                        },
                        [displayCote === MEASURE_COTE.NGF ? 'max' : 'min']: {
                            name: 'Prévision par scénario - Max',
                            data: maxNGF,
                        },
                        serieId: 'magicModel',
                        minNGF,
                        maxNGF,
                    }))
                }
                // if (ordered[0].doubtMax) {
                //     series.push(Line({
                //         data: ordered.map((m, idx) => toEchartMeasure([m.date, idx === 0 ? m.value : m.doubtMax], displayCote, landmarkValue)),
                //         name: 'Prévision par scénario - Max',
                //         color: '#c90000',
                //         connectNulls: true,
                //         lineStyle: { type: 'dashed' },
                //         showSymbol: false,
                //         isPiezo: true,
                //     }))
                // }
                return series
            }
            return []
        })()

        changeParent({ modelsSeries: [...hypeSeries, ...auguresSeries, ...arimaSeries, ...dryingSeries, ...modelSeries, ...magicModelSeries] })
    }, [hypeTrends, hypeType, displayHypeTrends, augures, auguresChecked, displayAugures, arimaDisplay, arimaResults, displayDryingCurves, dryingCurves, modelResults, magicModelDisplay, magicModelResults])

    const auguresCheckboxes = (() => {
        const checks = orderBy(Object.keys(auguresChecked), y => parseInt(y), 'desc').map(year =>
            <Checkbox checked={ auguresChecked[year] } label={ `${year} (prob: ${augures.find(a => a.year == year).proba})` } onChange={ v => setAuguresChecked({ ...auguresChecked, [year]: v }) } disabled={!displayAugures}/>
        )
        const groups = chunk(checks, ceil(checks.length / 3))
        return groups.map(g => <div className='col s4'>{ g }</div>)
    })()

    // const displayDrying = false
    const url = (window.location.href || document.URL).split('#')[0]
    const displayDrying = url.includes('dev')
        || url.includes('demo')
        || url.includes('localhost')
        || url.includes('aprona')

    // magicModel : check if pluvio exists and has correct dataTypes
    useEffect(() => {
        const era5Pluvio = associatedSites.find(ass => ass.typeName === 'pluviometry' && ass.stationLinkedName.includes('ERA5'))
        if (era5Pluvio) {
            PluviometryAction.promisePluviometerMeasuresStats(era5Pluvio.stationLinkedId).then(stats => setPluvioStats(stats))
        }
    }, [associatedSites])

    const showMagicModelPanel = pluvioStats?.length && (url.includes('dev') || url.includes('demo') || url.includes('localhost')) ? (() => {
        const rain = pluvioStats.find(p => p.typeId === 1)
        const etp = pluvioStats.find(p => p.typeId === 3)
        const temp = pluvioStats.find(p => p.label.toLowerCase().replaceAll('é', 'e') === 'temperature')

        return rain && rain?.countTotal > 0 && etp && etp?.countTotal > 0 && temp && temp?.countTotal > 0
    })() : false

    const onValidate = () => {
        if (!Object.keys(modelCheck).filter(key => modelCheck[key]).length) {
            dispatch(ToastrAction.warning(i18n.noModelSelectedMessage))
            changeParent({ modelsSeries: [] })
        } else {
            loadModelResults({ predStats, modelCheck, modelDate, iaeauModels, piezometer, displayCote, landmarkValue,
                cb: series => changeParent({ modelsSeries: series }),
            })
        }
    }

    if (tab !== MODELS) {
        return null
    }

    return (
        <div>
            <StyledFieldSet>
                <StyledLegend onClick={ () => setModelTitleClick(modelTitleClick + 1) }>{ i18n.models }</StyledLegend>
                <div className='padding-left-2 padding-right-1'>
                    { advancedMode ? (<>
                        <div className='row no-margin'>
                            <StyledFieldSet>
                                <StyledLegend>{ i18n.hypeTrends }</StyledLegend>
                                <div className='row no-margin valign-wrapper'>
                                    <SimpleDatePicker col={ 3 } value={ hypeDate } onChange={ v => setHypeDate(v) } label={ i18n.endDate }/>
                                    <div className='col s3' />
                                    <Button col={ 6 } title={i18n.runCalculation} onClick={() => {
                                        setDisplayHypeTrends(true)
                                        setRunHype(runHype+1)
                                    }}
                                    />
                                </div>
                                {
                                    (hypeTrends?.trends || []).length ? (
                                        <>
                                            <div className='row no-margin'>
                                                <Checkbox col={ 6 } checked={ displayHypeTrends } label={ i18n.displayTrends } onChange={ v => setDisplayHypeTrends(v) }/>
                                            </div>
                                            <div className='row no-margin'>
                                                <Checkbox col={ 6 } checked={ hypeType[HYPE_TRENDS_CONSTANTS.MANN_KENDALL] } label={ i18n.mannKendallTrend }
                                                    onChange={ v => changeHypeType({ [HYPE_TRENDS_CONSTANTS.MANN_KENDALL]: v }) }
                                                    disabled={ !displayHypeTrends }
                                                />
                                                <Checkbox col={ 6 } checked={ hypeType[HYPE_TRENDS_CONSTANTS.AVERAGE_RUPTURE] } label={ i18n.averageTrend }
                                                    onChange={ v => changeHypeType({ [HYPE_TRENDS_CONSTANTS.AVERAGE_RUPTURE]: v }) }
                                                    disabled={ !displayHypeTrends }
                                                />
                                            </div>
                                            <div className='row no-margin'>
                                                <Checkbox col={ 6 } checked={ hypeType[HYPE_TRENDS_CONSTANTS.LINEAR_REGRESSION] } label={ i18n.linearRegressionTrend }
                                                    onChange={ v => changeHypeType({ [HYPE_TRENDS_CONSTANTS.LINEAR_REGRESSION]: v }) }
                                                    disabled={ !displayHypeTrends }
                                                />
                                                <Checkbox col={ 6 } checked={ hypeType[HYPE_TRENDS_CONSTANTS.TREND_RUPTURE] } label={ i18n.trendRupture }
                                                    onChange={ v => changeHypeType({ [HYPE_TRENDS_CONSTANTS.TREND_RUPTURE]: v }) }
                                                    disabled={ !displayHypeTrends }
                                                />
                                            </div>
                                        </>
                                    ) : undefined
                                }
                            </StyledFieldSet>
                        </div>
                        <div className='row no-margin padding-bottom-1'>
                            <StyledFieldSet>
                                <StyledLegend>{ i18n.omenTrends }</StyledLegend>
                                <div className='row no-margin valign-wrapper'>
                                    <SimpleDatePicker col={ 3 } value={ augureDate } onChange={ v => setAugureDate(v) } label={ i18n.date }/>
                                    <NumberField col={ 3 } value={ augureHorizon } onChange={ v => setAugureHorizon(v) } title={ i18n.horizonDays } min={ 2 } max={ 168 } />
                                    <Button col={ 6 } title={i18n.runCalculation} onClick={() => {
                                        setDisplayAugures(true)
                                        setRunAugures(runAugures+1)
                                    }}
                                    />
                                </div>
                                {
                                    advancedMode ? (
                                        <Row>
                                            <fieldset className='no-padding'>
                                                <legend className='fieldsetClickable' onClick={ () => setAugureAdvanced(!augureAdvanced) }>&nbsp;{ i18n.advanced }&nbsp;</legend>
                                                {
                                                    augureAdvanced ? (
                                                        <div className='row no-margin valign-wrapper'>
                                                            <NumberField col={ 3 } value={ augureAverage } onChange={ v => setAugureAverage(v) } title={ i18n.mean } min={ 1 } max={ 363 }/>
                                                            <div className='col s9'/>
                                                        </div>
                                                    ) : <Row className='padding-left-1'>···</Row>
                                                }
                                            </fieldset>
                                        </Row>
                                    ) : null
                                }
                                {auguresCheckboxes.length ? (
                                    <Row><Checkbox col={ 4 } checked={ displayAugures } label={ i18n.displayLines } onChange={ v => setDisplayAugures(v) } /></Row>
                                ) : null}
                                {auguresCheckboxes}
                            </StyledFieldSet>
                        </div>
                        <div className='row no-margin padding-bottom-1'>
                            <StyledFieldSet>
                                <StyledLegend>ARIMA</StyledLegend>
                                <div className='row no-margin valign-wrapper'>
                                    <SimpleDatePicker col={ 3 } value={ arimaDate } onChange={ v => setArimaDate(v) } label={ i18n.date }/>
                                    <NumberField col={ 3 } value={ arimaHorizon } onChange={ v => setArimaHorizon(v) } title={ i18n.horizonDays } min={ 2 } />
                                    <Button col={ 6 } title={i18n.runCalculation} onClick={() => {
                                        setArimaDisplay(true)
                                        setArimaRun(runAugures+1)
                                    }}
                                    />
                                </div>
                            </StyledFieldSet>
                        </div>
                        {
                            displayDrying && (<div className='row no-margin padding-bottom-1'>
                                <StyledFieldSet>
                                    <StyledLegend>{ i18n.dryingUpCurve }</StyledLegend>
                                    <Row>
                                        <SimpleDatePicker col={ 3 } value={ dryingDate } onChange={ setDryingDate } label={ i18n.date }/>
                                        <NumberField col={ 3 } value={ dryingHorizon } onChange={ setDryingHorizon } title={ i18n.horizonDays } min={ 2 } max={ 150 } />
                                        <Button col={ 6 } title={i18n.runCalculation} onClick={ () => {
                                            setDisplayDryingCurves(true)
                                            setRunDryingCurves(runDryingCurves+1)
                                        } }
                                        />
                                    </Row>
                                    {
                                        dryingError && (
                                            <Row><ElementCard color='red'><h6>{ dryingError }</h6></ElementCard></Row>
                                        )
                                    }
                                    {
                                        advancedMode ? (
                                            <Row>
                                                <fieldset className='no-padding'>
                                                    <legend className='fieldsetClickable' onClick={ () => setDryingAdvanced(true) }>&nbsp;{ i18n.advanced }&nbsp;</legend>
                                                    {
                                                        dryingAdvanced ? (
                                                            <div>
                                                                <Row>
                                                                    <NumberField col={ 6 } value={ dryingPercent } onChange={ setDryingPercent } title={ `${i18n.dryingPercent} (%)` } min={ 0 } max={ 100 } />
                                                                    <NumberField col={ 6 } value={ dryingNbDays } onChange={ setDryingNbDays } title={ i18n.dryingNbDays } min={ 0 } max={ 100 } />
                                                                </Row>
                                                                <Row>
                                                                    <Switch col={ 12 } checked={ dryingCote } labelOn={ i18n.dryingCoteOption } onChange={ setDryingCote } />
                                                                </Row>
                                                                <Row className='padding-top-1 padding-bottom-1'>
                                                                    <Switch col={ 12 } checked={ dryingSeason } labelOn={ i18n.dryingSeasonOption } onChange={ setDryingSeason } />
                                                                </Row>
                                                            </div>
                                                        ) : <Row className='padding-left-1'>···</Row>
                                                    }
                                                </fieldset>
                                            </Row>
                                        ) : null
                                    }
                                </StyledFieldSet>
                            </div>)
                        }
                        {
                            showMagicModelPanel && (<div className='row no-margin padding-bottom-1'>
                                <StyledFieldSet>
                                    <StyledLegend>{ 'Prévision par scénario' }</StyledLegend>
                                    <Row>
                                        <SimpleDatePicker col={ 3 } value={ magicModelDate } onChange={ setMagicModelDate } label={ i18n.date }/>
                                        <NumberField col={ 3 } value={ magicModelHorizon } onChange={ setMagicModelHorizon } title={ i18n.horizonDays } max={180}/>
                                        <Button col={ 6 } title={i18n.runCalculation} onClick={ () => {
                                            setMagicModelDisplay(true)
                                            setMagicModelRun(magicModelRun+1)
                                        } }
                                        />
                                    </Row>
                                    <Row>
                                        <RadioButtons onChange={ setMagicModelScenario } elements={ [
                                            { value: 1, label: 'Scenario Humide' },
                                            { value: 0, label: 'Scenario Normal' },
                                            { value: -1, label: 'Scenario Sec' },
                                        ] } selected={magicModelScenario} activeOneBelowOther
                                        />
                                    </Row>
                                </StyledFieldSet>
                            </div>)
                        }
                    </>) : null }
                    {
                        predStats.filter(pred => pred.typeId === typeId).map(pred => {
                            const key = pred.source
                            return (
                                <div className='row no-margin padding-bottom-1'>
                                    <StyledFieldSet>
                                        <StyledLegend>{ `${pred.source} (${pred.horizon} ${i18n[pred.horizonMode || 'days']})` }</StyledLegend>
                                        <Row className='valign-wrapper'>
                                            <Checkbox col={ 1 } checked={ modelCheck[key] } onChange={ v => setModelCheck({ ...modelCheck, [key]: v }) } />
                                            <div className='col s1'>
                                                <ButtonMUI
                                                    variant={'outlined'}
                                                    onClick={() => changePrevSucc(pred, prevSucc[key]?.[0]) }
                                                    style={{ border: prevSucc[key]?.[0] ? 'solid rgba(53, 96, 159, 1)' : 'solid grey', borderWidth: 2, fontWeight: 600, paddingLeft: 0, paddingRight: 0, minWidth: 40 }}
                                                ><IconMui style={{ fontSize: 22, color: prevSucc[key]?.[0] ? 'rgba(53, 96, 159, 1)' : 'grey' }}>keyboard_double_arrow_left</IconMui></ButtonMUI>
                                            </div>
                                            <div className='col s3' style={{ paddingLeft: 24 }} ref={ calendarRefs[key] } onClick={ () => setCalendarOpen({ ...calendarOpen, [key]: true }) }>
                                                <Input title={ i18n.date } value={ getDate(modelDate[key]) } onChange={ () => setModelDate({ ...modelDate, [key]: modelDate[key] }) } />
                                            </div>
                                            <div className='col s1'>
                                                <ButtonMUI
                                                    variant={'outlined'}
                                                    onClick={() => changePrevSucc(pred, prevSucc[key]?.[1]) }
                                                    style={{ border: prevSucc[key]?.[1] ? 'solid rgba(53, 96, 159, 1)' : 'solid grey', borderWidth: 2, fontWeight: 600, paddingLeft: 0, paddingRight: 0, minWidth: 40 }}
                                                ><IconMui style={{ fontSize: 22, color: prevSucc[key]?.[1] ? 'rgba(53, 96, 159, 1)' : 'grey' }}>keyboard_double_arrow_right</IconMui></ButtonMUI>
                                            </div>
                                            <div className='col s6'/>
                                            <Popover
                                                open={calendarOpen[key]}
                                                anchorEl={calendarRefs[key]?.current}
                                                // anchorOrigin={{
                                                //     vertical: 'bottom',
                                                //     horizontal: 'left',
                                                // }}
                                                // transformOrigin={{
                                                //     vertical: 'top',
                                                //     horizontal: 'left',
                                                // }}
                                                onClose={() => setCalendarOpen({ ...calendarOpen, [key]: false })}
                                            >
                                                <div style={ { width: 250 }}>
                                                    <Row className='padding-top-1 padding-left-1 padding-right-1'>
                                                        <div className='col s1 no-padding'>
                                                            <Icon style={{ fontSize: 27 }} clickable icon='chevron_left' onClick={ () => {
                                                                if (moment(selectedMonth[key]).month()+1 === 1) {
                                                                    reloadModelResultsDates(moment().month(11).valueOf(), moment(selectedYear[key]).subtract(1, 'year').valueOf(), pred)
                                                                } else {
                                                                    reloadModelResultsDates(moment(selectedMonth[key]).subtract(1, 'month').valueOf(), selectedYear[key], pred)
                                                                }
                                                            } }
                                                            />
                                                        </div>
                                                        <div className='col s5'>
                                                            <Select value={moment(selectedMonth[key]).month()+1} options={getMonthList()} onChange={v => reloadModelResultsDates(moment().month(v-1).valueOf(), selectedYear[key], pred)} noInputFieldClass noSort/>
                                                        </div>
                                                        <div className='col s1 no-padding'>
                                                            <Icon style={{ fontSize: 27 }} clickable icon='chevron_right' onClick={ () => {
                                                                if (moment(selectedMonth[key]).month()+1 === 12) {
                                                                    reloadModelResultsDates(moment().month(0).valueOf(), moment(selectedYear[key]).add(1, 'year').valueOf(), pred)
                                                                } else {
                                                                    reloadModelResultsDates(moment(selectedMonth[key]).add(1, 'month').valueOf(), selectedYear[key], pred)
                                                                }
                                                            } }
                                                            />
                                                        </div>
                                                        <div className='col s5'>
                                                            <Select value={moment(selectedYear[key]).year()} options={years} onChange={v => reloadModelResultsDates(selectedMonth, moment().year(v).valueOf(), pred)} noInputFieldClass noSort/>
                                                        </div>
                                                    </Row>
                                                    <Row>
                                                        {getMapSituationCalendar(
                                                            selectedYear[key],
                                                            selectedMonth[key],
                                                            modelCalendarDates[key] || [],
                                                            modelDate[key],
                                                            v => setModelDate({ ...modelDate, [key]: v })
                                                        )}
                                                    </Row>
                                                </div>
                                            </Popover>
                                        </Row>
                                    </StyledFieldSet>
                                </div>
                            )
                        })
                    }
                    {
                        !predStats.length ? (
                            <h6>{ i18n.noModelAvailable }</h6>
                        ) : null
                    }
                </div>
            </StyledFieldSet>
            <Row className='padding-bottom-1 padding-top-1 center-align'>
                <Button tooltip={ i18n.apply } onClick={ onValidate } icon='border_color' className={'btn-floating btn-large'} disabled={ !predStats.length }/>
            </Row>
        </div>
    )
}

PiezoSuiviModelTab2.propTypes = {
    tab: PropTypes.string,
    displayCote: PropTypes.number,
    id: PropTypes.number,
    typeId: PropTypes.number,
    isPiezo: PropTypes.bool,
}

export default PiezoSuiviModelTab2