import moment from 'moment'
import { hasValue } from 'utils/NumberUtil'
import { DOCUMENT, PICTURE } from '../constant/FileConstants'
import ApplicationConf from 'conf/ApplicationConf'

export default class DtoFile {
    constructor(obj, fileType = DOCUMENT) {
        const pointIndex = obj.name.lastIndexOf('.')
        this.code = obj.code
        this.name = obj.name
        this.url = fileType === PICTURE ? ApplicationConf.picture.pictureCompressPath(obj.name) : obj.url
        this.updateDate = obj.updateDate
        const sliced = obj.name.slice(obj?.stationCode?.length + 1, pointIndex).split('_')
        const [,, shortNameOld, dateOld] = sliced
        const [type, active, date, stationTypeOrShortName, shortName] = sliced
        const [stationCode] = obj.name.slice(0, pointIndex).split('_')
        this.isAllDataName = sliced.length === 4 || sliced.length === 5
        this.stationCode = obj.stationCode || stationCode
        this.type = type
        this.active = active || '0'
        this.shortName = new RegExp('^[0-9]+-[0-9]+$').test(date) ? (shortName || stationTypeOrShortName) : shortNameOld
        this.date = new RegExp('^[0-9]+-[0-9]+$').test(date) ? moment(date, 'YYYYMMDD-HHmmss').valueOf() : parseInt(dateOld)
        this.fileType = fileType
        this.stationType = shortName ? stationTypeOrShortName : null
        this.shared = !hasValue(shortName)
    }
}