import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { TextField } from '@mui/material'
import { makeStyles } from '@mui/styles'
import useDebounce from 'utils/customHook/useDebounce'
import DisplayedValue from './DisplayedValue'

const useStyles = makeStyles(() => ({
    root: {
        paddingTop: '14px !important',
        position: 'relative',

        '& .MuiInputBase-input.MuiOutlinedInput-input': {
            border: 'solid grey 1px',
            height: 26,
            marginBottom: 5,
        },

        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                border: 'none',
                height: '0 !important',
            },
        },

        '& .MuiInputLabel-outlined': {
            top: '-20px',
            color: ({ disabled }) => disabled && '#9e9e9e' || '#161832',
            fontWeight: 'bold',
            left: '2px',
            fontSize: '1rem',
        },

        '& .MuiInputLabel-outlined.Mui-focused': {
            color: '#53a1ff',
        },

        '& .MuiInputLabel-outlined.Mui-disabled': {
            color: 'grey',
        },
    },
}))

const SimpleInput = ({
    value = '',
    onEnterKeyPress = () => { },
    onChange = () => { },
    readMode = false,
    title = '',
    hideNull = false,
    disabled = false,
    className = '',
}) => {
    const classes = useStyles({ disabled })

    const [stateValue, setStateValue] = useState(value)

    useEffect(() => {
        setStateValue(value)
    }, [value])

    useDebounce(() => onChange(stateValue), 500, [stateValue])

    if (readMode) {
        return (
            <DisplayedValue
                label={title}
                value={stateValue}
                hideNull={hideNull}
                displayValueBelow
                className={className}
            // obligatory={obligatory}
            />
        )
    }

    return (
        <TextField
            value={stateValue}
            onChange={e => setStateValue(e.target.value)}
            onKeyUp={(event) => {
                if (event.key == 'Enter') {
                    onEnterKeyPress()
                }
            }}
            label={title}
            fullWidth
            InputLabelProps={{
                shrink: false,
            }}
            disabled={disabled}
            classes={classes}
            className={className}
        />
    )
}

SimpleInput.propTypes = {
    value: PropTypes.string,
    onEnterKeyPress: PropTypes.func,
    onChange: PropTypes.func,
    readMode: PropTypes.bool,
    title: PropTypes.string,
    hideNull: PropTypes.bool,
    disabled: PropTypes.bool,
    className: PropTypes.string,
}

export default SimpleInput
