/* eslint-disable camelcase */
export default class DtoSamplingPointDecla {
    constructor(object) {
        this.idSurvey = object.idSurvey // Long,
        this.idExploitation = object.idExploitation // Long,
        this.idInstallation = object.idInstallation // Long,
        this.stateCode = object.stateCode // Option[Int],

        this.link_usages = object.link_usages || [] // Option[Seq[UsageDeclaration]] = None
        this.link_usagesCurrent = object.link_usagesCurrent || [] // Option[Seq[UsageDeclaration]] = None
        this.link_sampleCasings = object.link_sampleCasings || [] // Option[Seq[SampleCasing]] = None
        this.link_sampleTanks = object.link_sampleTanks || [] // Option[Seq[SampleTank]] = None
        this.link_equipments = object.link_equipments || [] // Option[Seq[DtoUpdatedMaterial]] = None
    }
}
