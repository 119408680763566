import React, { useEffect } from 'react'
import i18n from 'simple-react-i18n'
import PropTypes from 'prop-types'
import { StyledFieldSet, StyledLegend, StyledSpan } from 'components/StyledElements'
import Checkbox from 'components/forms/Checkbox'
import Select from 'components/forms/Select'
import { getIntegrationModes } from 'utils/JobUtils'
import { integrationModeHelpIcon } from 'import/utils/ImportUtils'
import { Grid2 } from '@mui/material'
import DtoHydrometricStation from 'hydrometry/dto/DtoHydrometricStation'
import { hasValue } from 'utils/NumberUtil'
import StationImportCodeMessage from 'import/components/utils/StationImportCodeMessage'
import Input from 'components/forms/Input'
import Icon from 'components/icon/Icon'
import RadioButtons from '../../../../components/forms/RadioButtons'

const AddHydroStepOptionsHP = ({
    station,
    changeStation,
    jobDataTypes,
    setJobDataTypes,
    filters,
    changeFilters,
}) => {
    useEffect(() => {
        if (!filters?.stationsMode) {
            changeFilters({ stationsMode: 'stations' })
        }
    }, [])
    const integrationModes = getIntegrationModes().map(im => im.value)
    const isNotValid = hasValue(station.code) && filters?.stationsMode === 'stations' && ![8, 10, 12].includes(station.code.length)

    const onChangeDataTypes = (dataType) => {
        const newDataTypes = jobDataTypes.includes(dataType) ?
            jobDataTypes.filter(jdt => jdt !== dataType) :
            [...jobDataTypes, dataType]

        setJobDataTypes(newDataTypes)
    }

    const onChangeDataIntegration = (dataType) => {
        const filteredDataTypes = jobDataTypes.filter(jdt => !integrationModes.includes(jdt))
        setJobDataTypes(dataType && [...filteredDataTypes, dataType] || filteredDataTypes)
    }

    const codesTypes = [
        { code: 'stations', name: i18n.stations },
        { code: 'cities', name: i18n.cities },
        { code: 'departments', name: i18n.departments },
    ]

    return (
        <StyledFieldSet >
            <StyledLegend>
                <StyledSpan>{i18n.options}</StyledSpan>
            </StyledLegend>
            <Grid2>
                <Grid2 container justifyContent='space-between' spacing={2} sx={{ paddingTop: '10px', paddingBottom: '10px' }}>
                    <Grid2 size={3}>
                        <Input value={station.code} onChange={v => changeStation({ code: v })} title={i18n.code} noSpace />
                    </Grid2>
                    <Grid2 container size={9}>
                        {(isNotValid) ? (
                            <Grid2 container justifyContent='space-between' alignItems='center' spacing={2}>
                                <Grid2 item size={1}>
                                    <Icon icon='warning' style={{ color: 'orange' }} />
                                </Grid2>
                                <Grid2 item size={11}>
                                    <h6>{i18n.invalidHydroCode}</h6>
                                </Grid2>
                            </Grid2>
                        ) : (hasValue(station.code) && filters?.stationsMode === 'stations' && <StationImportCodeMessage code={station.code} stationType='hydrometry' />)}
                    </Grid2>
                </Grid2>
                <Grid2 container justifyContent='space-between' spacing={2} sx={{ paddingTop: '10px', paddingBottom: '10px' }}>
                    <Grid2 size={4}>
                        <RadioButtons elements={codesTypes} selected={filters.stationsMode}
                            onChange={v => changeFilters({ stationsMode: v })}
                            title={i18n.schemeAgencyID}
                        />
                    </Grid2>
                </Grid2>
                <Grid2 container justifyContent='space-between' alignItems='center' spacing={2} sx={{ paddingBottom: '10px' }}>
                    <Grid2 size={6}>
                        <Select
                            value={jobDataTypes.find(d => integrationModes.includes(d))}
                            options={getIntegrationModes()}
                            label={i18n.dataIntegration}
                            labelSpan={integrationModeHelpIcon()}
                            onChange={onChangeDataIntegration}
                        />
                    </Grid2>
                    <Grid2>
                        <Checkbox
                            checked={jobDataTypes.find(d => d.includes('withPred'))}
                            label={`${i18n.importPrevData} Vigicrues`}
                            onChange={() => onChangeDataTypes('withPred')}
                        />
                    </Grid2>
                </Grid2>
                <Grid2 container justifyContent='space-between' alignItems='center' spacing={2} sx={{ paddingBottom: '10px' }}>
                    <Grid2>
                        <Checkbox
                            checked={jobDataTypes.find(d => d.includes('heightIntegration'))}
                            label={i18n.heightObs}
                            onChange={() => onChangeDataTypes('heightIntegration')}
                        />
                    </Grid2>
                    <Grid2>
                        <Checkbox
                            checked={jobDataTypes.find(d => d.includes('debitIntegration'))}
                            label={i18n.debitObs}
                            onChange={() => onChangeDataTypes('debitIntegration')}
                        />
                    </Grid2>
                    <Grid2>
                        <Checkbox
                            checked={jobDataTypes.find(d => d.includes('withEvents'))}
                            label={i18n.importEvents}
                            onChange={() => onChangeDataTypes('withEvents')}
                        />
                    </Grid2>
                    <Grid2>
                        <Checkbox
                            checked={jobDataTypes.find(d => d.includes('withThresholds'))}
                            label={i18n.importThresholds}
                            onChange={() => onChangeDataTypes('withThresholds')}
                        />
                    </Grid2>
                </Grid2>
            </Grid2>
        </StyledFieldSet>
    )
}

AddHydroStepOptionsHP.propTypes = {
    station: PropTypes.instanceOf(DtoHydrometricStation),
    changeStation: PropTypes.func,
    jobDataTypes: PropTypes.arrayOf(PropTypes.string),
    setJobDataTypes: PropTypes.func,
    filters: PropTypes.object,
    changeFilters: PropTypes.func,
}

export default AddHydroStepOptionsHP