export default class DtoComElec {
    constructor(obj) {
        this.idStation = obj.idStation
        this.remoteManagement = obj.remoteManagement
        this.remoteDescription = obj.remoteDescription
        this.supervision = obj.supervision
        this.supervisionDescription = obj.supervisionDescription
        this.communicationMode = obj.communicationMode
        this.transformer = obj.transformer
        this.rescueDevice = obj.rescueDevice
        this.normalMode = obj.normalMode
        this.degradedMode = obj.degradedMode
        this.prerequisiteInstallation = obj.prerequisiteInstallation
        this.availableSpace = obj.availableSpace
        this.cableWitdh = obj.cableWitdh
        this.starter = obj.starter
        this.dimmer = obj.dimmer
    }
}