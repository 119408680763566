import React from 'react'
import PropTypes from 'prop-types'
import { maxBy, some, take } from 'lodash'
import { PICTURES_EXTENSIONS } from '../../administration/constants/AdministrationConstants'
import Card from '../card/Card'
import i18n from 'simple-react-i18n'
import DtoFile from './dto/DtoFile'
import { capitalizeFirstLetter } from '../../utils/StringUtil'
import { getDocumentTypeIcon, getFileExtension } from 'utils/FileUtils'
import Icon from 'components/icon/Icon'
import Fancybox from 'components/fancybox/Fancybox'
import { sieauTooltip } from 'utils/FormUtils'

const FilePanel = ({
    files = [],
    showLast = false,
    editable = false,
    deleteFile,
    hideTitle = false,
    onShowMore = () => {},
}) => {
    const fileDelete = (file) => {
        const fileToDelete = {
            name: file.url.split('/DOCUMENTS/')[1],
            fileType: 'document',
        }
        deleteFile(fileToDelete)
    }

    const create = file => {
        const nameFormat = file.isAllDataName ? capitalizeFirstLetter(file.shortName) : file.name
        const fileExtension = getFileExtension(file.name)
        return (
            <div className='col s6 padding-bottom-1'>
                { editable ? (
                    <span onClick={ () => fileDelete(file) } style={ {
                        marginRight: '10px',
                        color: 'red',
                        fontWeight: 'bold',
                        cursor: 'pointer',
                    } }
                    >X</span>
                ) : '' }
                <Fancybox>
                    <a style={{ border: 'solid 2px black', borderRadius: '5px', padding: '5px' }} href={ file.url } target='_blank' data-caption={ nameFormat } className='valign-wrapper'>
                        <img src={ getDocumentTypeIcon(fileExtension) } style={{ maxWidth: '15px', height: 'auto' }} />
                        <b className='font-size-10 truncate clickable padding-left-1'>{ nameFormat }</b>
                    </a>
                </Fancybox>
            </div>
        )
    }

    const componentfiles = (() => {
        const filterFiles = files.filter(f => !some(PICTURES_EXTENSIONS, extension => f.name.endsWith(extension)))
        if (showLast) {
            return filterFiles.length ? [create(maxBy(filterFiles, 'updateDate'))] : []
        }
        return filterFiles.map(f => create(f))
    })()

    const title = hideTitle ? {} : { title: i18n.files }

    const filesRemaining = (componentfiles.length - 5) > 9 ? '9_plus' : componentfiles.length - 5

    return componentfiles.length !== 0 && (
        <Card round {...title} className={`row text-align-center margin-bottom-1 padding-top-1 ${componentfiles.length > 5 ? '' : ''}`} noMargin={false}>
            { take(componentfiles, 5) }
            { componentfiles.length > 5 && (
                <div className='col s6' style={{ padding: '5px' }} {...sieauTooltip(i18n.viewMore, null, 'bottom')} >
                    <Icon size='small' icon={`filter_${filesRemaining}`} onClick={onShowMore} />
                </div>
            )}
        </Card>
    )
}

FilePanel.propTypes = {
    code: PropTypes.string,
    files: PropTypes.arrayOf(PropTypes.instanceOf(DtoFile)),
    showLast: PropTypes.bool,
    editable: PropTypes.bool,
    deleteFile: PropTypes.func,
    hideTitle: PropTypes.bool,
    onShowMore: PropTypes.func,
}

export default FilePanel