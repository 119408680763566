/* eslint-disable camelcase */
import React, { useMemo, useState } from 'react'
import { Grid2 } from '@mui/material'
import AdministrationAction from 'administration/actions/AdministrationAction'
import Card from 'components/card/Card'
import NumberField from 'components/forms/NumberField'
import SimpleDatePicker from 'components/forms/SimpleDatePicker'
import ProgressCard from 'components/card/ProgressCard'
import { StyledFieldSet, StyledLegend } from 'components/StyledElements'
import { isEmpty, isNil, orderBy, range, xor } from 'lodash'
import VariousMaterielAction from 'materiel/components/variousMateriel/actions/VariousMaterielAction'
import DtoVariousMaterielType from 'materiel/components/variousMateriel/dto/DtoVariousMaterielType'
import PropTypes from 'prop-types'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import UsageAction from 'referencial/components/usage/actions/UsageAction'
import DtoUsageAgri from 'referencial/components/usage/dto/DtoUsageAgri'
import i18n from 'simple-react-i18n'
import { hasValue } from 'utils/NumberUtil'
import Select from 'components/forms/Select'
import { ENTRY_SETTINGS_FREQUENCY } from 'agriAdministration/constants/AgriConstants'
import useTitle from 'utils/customHook/useTitle'
import useStateAppSetting from 'utils/customHook/useStateAppSetting'
import useActions from 'utils/customHook/useActions'
import useProgressDispatch from 'utils/customHook/useProgressDispatch'
import { getDayList } from 'utils/DateUtil'
import { getEntryFrequencyList } from 'exploitations/utils/AgriUtils'
import SimpleCheckbox from 'components/forms/SimpleCheckbox'
import { booleanParser } from 'utils/customHook/useApplicationSetting'
import SuperMultiAutocomplete from 'components/forms/SuperMultiAutocomplete'

const TerritorySettingsParamsApp = () => {
    const [readMode, setReadMode] = useState(true)

    const {
        usages,
        variousMaterielTypes,
    } = useSelector(store => ({
        usages: store.UsageReducer.usages,
        variousMaterielTypes: store.VariousMaterielReducer.variousMaterielTypes,
    }), shallowEqual)

    const dispatch = useDispatch()

    const { isLoaded, progress } = useProgressDispatch(() => [
        dispatch(VariousMaterielAction.fetchVariousMaterielTypes()),
        dispatch(AdministrationAction.fetchApplicationSettings()),
        dispatch(UsageAction.fetchUsages()),
    ], [])

    const [usagesInTerritoryCalcul, setUsagesInTerritoryCalcul] = useStateAppSetting('usagesInTerritoryCalcul', v => v?.split(' ')?.filter((uId) => uId !== ''), [])
    const [nbRotationsCultMax, setNbRotationsCultMax] = useStateAppSetting('nbRotationsCultMax', v => v, 6)
    const [lowWaterStartDate, setLowWaterStartDate] = useStateAppSetting('lowWaterStartDate', v => Number(v))
    const [lowWaterEndDate, setLowWaterEndDate] = useStateAppSetting('lowWaterEndDate', v => Number(v))
    const [materielsTypeForIndex, setMaterielsTypeForIndex] = useStateAppSetting('materielsTypeForIndex', v => v?.split(',')?.map((id) => Number(id)), [])
    const [entryFrequency, setEntryFrequency] = useStateAppSetting('entryFrequency', v => Number(v))
    const [entryFrequencyDay, setEntryFrequencyDay] = useStateAppSetting('entryFrequencyDay', v => Number(v))
    const [blockConsumptionsDeletion, setBlockConsumptionsDeletion] = useStateAppSetting('blockConsumptionsDeletion', booleanParser)
    const [blockEntryDate, setBlockEntryDate] = useStateAppSetting('blockEntryDate', booleanParser)

    const onCancel = () => {
        dispatch(AdministrationAction.fetchApplicationSettings())
        setReadMode(true)
    }

    useTitle(() => [{
        title: i18n.steering,
        href: 'territory',
    }, {
        title: i18n.settings,
        href: 'territory/settings',
    }, {
        title: i18n.parameters,
        href: 'territory/settings/parameters',
    }], [])

    useActions(() => {
        if (readMode) {
            return { edit: () => setReadMode(false) }
        }
        return {
            cancel: () => onCancel(),
            save: () => {
                const settings = [
                    { parameter: 'usagesInTerritoryCalcul', value: usagesInTerritoryCalcul.reduce((acc, i) => `${acc} ${i}`, '') },
                    { parameter: 'nbRotationsCultMax', value: hasValue(nbRotationsCultMax) ? String(nbRotationsCultMax) : '6' },
                    { parameter: 'lowWaterStartDate', value: hasValue(lowWaterStartDate) ? String(lowWaterStartDate) : null },
                    { parameter: 'lowWaterEndDate', value: hasValue(lowWaterEndDate) ? String(lowWaterEndDate) : null },
                    { parameter: 'materielsTypeForIndex', value: `${materielsTypeForIndex}` },
                    { parameter: 'entryFrequency', value: hasValue(entryFrequency) ? String(entryFrequency) : null },
                    { parameter: 'entryFrequencyDay', value: hasValue(entryFrequencyDay) ? String(entryFrequencyDay) : null },
                    { parameter: 'blockConsumptionsDeletion', value: blockConsumptionsDeletion ? '1' : null },
                    { parameter: 'blockEntryDate', value: blockEntryDate ? '1' : null },
                ]
                dispatch(AdministrationAction.updateSieauParameters(settings)).then(() => {
                    dispatch(AdministrationAction.fetchApplicationSettings()).then(() => {
                        setReadMode(true)
                    })
                })
            },
        }
    }, [readMode, usagesInTerritoryCalcul, nbRotationsCultMax, lowWaterStartDate, lowWaterEndDate, materielsTypeForIndex,
        entryFrequency, entryFrequencyDay, blockConsumptionsDeletion, blockEntryDate])

    const onChangeUsages = (id) => {
        if (!usagesInTerritoryCalcul.includes('all')) {
            if (id === 'all') {
                setUsagesInTerritoryCalcul(['all'])
            } else if (usagesInTerritoryCalcul.includes(id)) {
                setUsagesInTerritoryCalcul(usagesInTerritoryCalcul.filter((uId) => uId !== id))
            } else if (isEmpty(xor(usages.map((u) => `${u.id}`), [...usagesInTerritoryCalcul, id]))) {
                setUsagesInTerritoryCalcul(['all'])
            } else {
                setUsagesInTerritoryCalcul([...usagesInTerritoryCalcul, id])
            }
        } else {
            setUsagesInTerritoryCalcul([...usages.map((u) => `${u.id}`), ...usagesInTerritoryCalcul].filter((uId) => uId !== id).filter((uId) => uId !== 'all'))
        }
    }

    const entryDays = useMemo(() => {
        if (entryFrequency === ENTRY_SETTINGS_FREQUENCY.WEEKLY) {
            return getDayList()
        }
        return range(1, 32).map((d) => ({ value: String(d), label: d }))
    }, [entryFrequency])

    return (
        <div className='padding-1'>
            {isLoaded ? (
                <Card title={i18n.parameters} round>
                    <div className='padding-bottom-1 padding-left-1 padding-right-1'>
                        <StyledFieldSet className='margin-1'>
                            <StyledLegend>{i18n.usagesForTotalVolumesRequested}</StyledLegend>
                            <Grid2 container spacing={1} className='margin-top-1 padding-bottom-1'>
                                <SimpleCheckbox
                                    label={i18n.everybody}
                                    checked={ (usagesInTerritoryCalcul || []).includes('all') }
                                    onToggle={() => onChangeUsages('all')}
                                    disabled={readMode}
                                    data-cy='all'
                                />
                            </Grid2>
                            <Grid2 container spacing={1}>
                                {orderBy(usages, 'description').map((u) => (
                                    <SimpleCheckbox
                                        key={u.id}
                                        label={u.description}
                                        checked={(usagesInTerritoryCalcul || []).some(r=> [`${u.id}`, 'all'].includes(r))}
                                        onToggle={() => onChangeUsages(`${u.id}`)}
                                        disabled={readMode}
                                    />
                                ))}
                            </Grid2>
                        </StyledFieldSet>
                        <StyledFieldSet className='margin-1'>
                            <StyledLegend>{i18n.usages}</StyledLegend>
                            <Grid2 container spacing={1} className='padding-top-1'>
                                <Grid2 size={3}>
                                    <NumberField
                                        col={12}
                                        title={i18n.nbRotationsCultMax}
                                        value={nbRotationsCultMax}
                                        onChange={(v) => setNbRotationsCultMax(v)}
                                        disabled={readMode}
                                        min={1}
                                        data-cy='nbRotationsCultMax'
                                    />
                                </Grid2>
                            </Grid2>
                        </StyledFieldSet>
                        <StyledFieldSet className='margin-1'>
                            <StyledLegend>{i18n.lowWater}</StyledLegend>
                            <Grid2 container spacing={1} className='padding-top-1'>
                                <Grid2 size={3}>
                                    <SimpleDatePicker
                                        col={12}
                                        label={i18n.startDate}
                                        value={lowWaterStartDate}
                                        onChange={(v) => setLowWaterStartDate(v)}
                                        disabled={readMode}
                                        max={lowWaterEndDate}
                                        data-cy='startDate'
                                    />
                                </Grid2>
                                <Grid2 size={3}>
                                    <SimpleDatePicker
                                        col={12}
                                        label={i18n.endDate}
                                        value={lowWaterEndDate}
                                        onChange={(v) => setLowWaterEndDate(v)}
                                        disabled={readMode}
                                        min={lowWaterStartDate}
                                        data-cy='endDate'
                                    />
                                </Grid2>
                            </Grid2>
                        </StyledFieldSet>
                        <StyledFieldSet className='margin-1'>
                            <StyledLegend>{i18n.entry}</StyledLegend>
                            <Grid2 container spacing={1} className='padding-top-1'>
                                <Grid2 size={6}>
                                    <SuperMultiAutocomplete
                                        col={12}
                                        label={i18n.materielsForIndex}
                                        options={orderBy(variousMaterielTypes, ['category', 'name'])}
                                        onChange={values => setMaterielsTypeForIndex(values)}
                                        values={materielsTypeForIndex}
                                        multiple
                                        limit={3}
                                        disabled={readMode}
                                        data-cy='materielsForIndex'
                                    />
                                </Grid2>
                                <Grid2 size={3}>
                                    <Select
                                        col={12}
                                        label={i18n.frequency}
                                        value={entryFrequency}
                                        onChange={v => {
                                            setEntryFrequency(v)
                                            setEntryFrequencyDay()
                                        }}
                                        clearFunction
                                        integerValue
                                        options={getEntryFrequencyList()}
                                        disabled={readMode}
                                        id='frequency'
                                        data-cy='frequency'
                                    />
                                </Grid2>
                                <Grid2 size={3}>
                                    <Select
                                        col={12}
                                        label={i18n.day}
                                        value={entryFrequencyDay}
                                        onChange={v => setEntryFrequencyDay(v)}
                                        clearFunction
                                        integerValue
                                        options={entryDays}
                                        disabled={readMode || isNil(entryFrequency)}
                                        noSort
                                        id='day'
                                        data-cy='day'
                                    />
                                </Grid2>
                                <SimpleCheckbox
                                    label={i18n.blockConsumptionsDeletion}
                                    checked={blockConsumptionsDeletion}
                                    onToggle={() => setBlockConsumptionsDeletion(prev => !prev)}
                                    disabled={readMode}
                                    tooltip={i18n.blockConsumptionsDeletionDescr}
                                    data-cy='blockConsumptionsDeletion'
                                />
                                <SimpleCheckbox
                                    label={i18n.blockEntryDateToTodayDate}
                                    checked={blockEntryDate}
                                    onToggle={() => setBlockEntryDate(prev => !prev)}
                                    disabled={readMode}
                                    tooltip={i18n.blockEntryDateToTodayDateDescr}
                                    data-cy='blockEntryDate'
                                />
                            </Grid2>
                        </StyledFieldSet>
                    </div>
                </Card>
            ) : <ProgressCard progress={progress} withMessage round />}
        </div>
    )
}

TerritorySettingsParamsApp.propTypes = {
    params: PropTypes.shape({
        id: PropTypes.string,
    }),
    usages: PropTypes.arrayOf(PropTypes.instanceOf(DtoUsageAgri)),
    variousMaterielTypes: PropTypes.arrayOf(PropTypes.instanceOf(DtoVariousMaterielType)),
    fetchVariousMaterielTypes: PropTypes.func,
}

export default TerritorySettingsParamsApp