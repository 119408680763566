'use strict'
import { createSlice } from '@reduxjs/toolkit'
import ProductionUnitThunk from 'productionUnit/actions/ProductionUnitThunk'
import DtoProductionUnitLink from 'productionUnit/dto/DroProductionUnitLink'
import DtoAssociatedStation from 'station/dto/DtoAssociatedStation'
import AppStore from 'store/AppStore'
import { arrayOf, instanceOf } from '../../utils/StoreUtils'
import ProductionUnitAction from '../actions/ProductionUnitAction'
import DtoProductionUnit from '../dto/DtoProductionUnit'
import DtoProductionUnitAssociationAdditionalMeasures from '../dto/DtoProductionUnitAssociationAdditionalMeasures'
import DtoProductionUnitAssociationsMeasures from '../dto/DtoProductionUnitAssociationsMeasures'
import DtoProductionUnitEvent from '../dto/DtoProductionUnitEvent'
import DtoProductionUnitLinkedPiezo from 'productionUnit/dto/DtoProductionUnitLinkedPiezo'

export const initialState = {
    productionUnit: {},
    productionUnits: [],
    productionUnitAssociations: [],
    productionUnitsAssociations: [],
    associationsMeasures: [],
    associationsSamples: [],
    associationsRawMeasures: [],
    productionUnitEvent: {},
    productionUnitEvents: [],
    associationsAdditionalMeasures: [],
    prodUnitLinks: [],
    linkedPiezoPrel: [],
    linkedPiezosPrel: [],
}

const store = createSlice({
    name: 'productionUnit',
    initialState,
    reducers: {
        createProductionUnit: (state, action) => {
            state.productionUnits = [...state.productionUnits, new DtoProductionUnit(action.payload)]
        },
        deleteProductionUnit: (state, action) => {
            state.productionUnits = state.productionUnits.filter(e => e.id !== action.payload)
        },
        receiveAllProductionUnits: (state, action) => {
            state.productionUnits = action.payload.map(pu => new DtoProductionUnit(pu))
        },
        receiveProductionUnit: (state, action) => {
            state.productionUnit = new DtoProductionUnit(action.payload)
        },
        receiveProductionUnitAssociations: (state, action) => {
            state.productionUnitAssociations = action.payload.map(pu => new DtoAssociatedStation(pu))
        },
        receiveProductionUnitsAssociations: (state, action) => {
            state.productionUnitsAssociations = action.payload.map(pu => new DtoAssociatedStation(pu))
        },
        receiveProductionUnitAssociationsMeasures: (state, action) => {
            state.associationsMeasures = action.payload.map(pu => new DtoProductionUnitAssociationsMeasures(pu))
        },
        receiveProductionUnitAssociationsSamples: (state, action) => {
            state.associationsSamples = action.payload.map(pu => new DtoProductionUnitAssociationsMeasures(pu))
        },
        receiveProductionUnitAssociationsAdditionalMeasures: (state, action) => {
            state.associationsAdditionalMeasures = action.payload.map(pu => new DtoProductionUnitAssociationAdditionalMeasures(pu))
        },
        receiveProductionUnitAssociationsRawMeasures: (state, action) => {
            state.associationsRawMeasures = action.payload.map(pu => new DtoProductionUnitAssociationsMeasures(pu))
        },
        receiveProductionUnitEvents: (state, action) => {
            state.productionUnitEvents = action.payload.map(pu => new DtoProductionUnitEvent(pu))
        },
        receiveProductionUnitEvent: (state, action) => {
            state.productionUnitEvent = new DtoProductionUnitEvent(action.payload)
        },
        createProductionUnitEvent: (state, action) => {
            state.productionUnitEvent = new DtoProductionUnitEvent(action.payload)
            state.productionUnitEvents = state.productionUnitEvents.concat([action.payload])
        },
        updateProductionUnitEvent: (state, action) => {
            state.productionUnitEvent = new DtoProductionUnitEvent(action.payload)
            state.productionUnitEvents = state.productionUnitEvents.filter(e => e.number !== action.payload.number).concat([action.payload])
        },
        deleteProductionUnitEvent: (state, action) => {
            state.productionUnitEvent = {}
            state.productionUnitEvents = state.productionUnitEvents.filter(e => e.number !== action.payload)
        },
        resetProductionUnitEvent: (state) => {
            state.productionUnitEvent = {}
        },
        resetProductionUnit: (state) => {
            state.productionUnit = {}
            state.productionUnitAssociations = []
            state.productionUnitEvents = []
            state.productionUnitEvent = {}
        },
        receiveProductionUnitLinks: (state, action) => {
            state.prodUnitLinks = action.payload.map(d => new DtoProductionUnitLink(d))
        },
        receiveProductionUnitLinkedPiezoPrel: (state, action) => {
            state.linkedPiezoPrel = action.payload.map(p => new DtoProductionUnitLinkedPiezo(p))
        },
        receiveProductionUnitLinkedPiezosPrel: (state, action) => {
            state.linkedPiezosPrel = action.payload
        },
    },
    extraReducers: {
        [ProductionUnitThunk.fetchProductionUnits.fulfilled]: (state, action) => {
            state.productionUnits = action.payload.map(pu => new DtoProductionUnit(pu))
        },
    },
})

export const ProductionUnitActionConstant = store.actions
export default store.reducer

export const ProductionUnitStoreInfos = () => {
    const appStore = AppStore.getState()
    return {
        productionUnit: {
            fetch: ProductionUnitAction.fetchProductionUnit,
            store: appStore.ProductionUnitReducer.productionUnit,
            propType: instanceOf(DtoProductionUnit),
            reset: ProductionUnitActionConstant.resetProductionUnit,
        },
        productionUnits: {
            fetch: ProductionUnitAction.fetchProductionUnits,
            store: appStore.ProductionUnitReducer.productionUnits,
            propType: arrayOf(DtoProductionUnit),
        },
        productionUnitAssociations: {
            fetch: ProductionUnitAction.fetchProductionUnitsAssociations,
            store: appStore.ProductionUnitReducer.productionUnitAssociations,
            propType: arrayOf(DtoAssociatedStation),
        },
    }
}