export default class MonitoredStationEventDto {
    constructor(obj) {
        this.id = obj.id
        this.code = obj.code
        this.designation = obj.designation
        this.townCode = obj.townCode
        this.name = obj.name
        this.creationDate = obj.creationDate
        this.closeDate = obj.closeDate
        this.x = obj.x
        this.y = obj.y
        this.projection = obj.projection
        this.altitude = obj.altitude
        this.type = obj.stationType
        this.eventId = obj.eventId
        this.eventDate = obj.eventDate
        this.eventHour = obj.eventHour
        this.eventComment = obj.eventComment
        this.eventSolution = obj.eventSolution
        this.eventProblem = obj.eventProblem
    }
}
