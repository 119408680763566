import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import ProgressCard from '../../../../components/card/ProgressCard'
import Table from '../../../../components/datatable/Table'
import Checkbox from '../../../../components/forms/Checkbox'
import Input from '../../../../components/forms/Input'
import NumberField from '../../../../components/forms/NumberField'
import RadioButtons from '../../../../components/forms/RadioButtons'
import Select from '../../../../components/forms/Select'
import Icon from '../../../../components/icon/Icon'
import DtoHydrometricStation from '../../../../hydrometry/dto/DtoHydrometricStation'
import DtoParametrageDataType from '../../../../piezometry/dto/DtoParametrageDataType'
import DtoPiezometerLight from '../../../../piezometry/dto/DtoPiezometerLight'
import PluviometerDto from '../../../../pluviometry/dto/PluviometerDto'
import { nbPerPageLabelShort } from '../../../../referencial/constants/ReferencialConstants'
import AppStore from '../../../../store/AppStore'
import { setModal } from '../../../../utils/FormUtils'
import { getHardHydroDataTypes } from '../../../../utils/HydroUtils'
import { getMeasureCoteList } from '../../../../utils/JobUtils'
import { getHardPiezoDataTypes } from '../../../../utils/PiezometryUtils'
import { execByType } from '../../../../utils/StationUtils'
import { arrayOf } from '../../../../utils/StoreUtils'
import JobAction from '../../../actions/JobAction'
import Job from '../../../dto/DtoJob'
import ImportFileModal from './ImportFileModal'

class ParatronicPanel extends Component {
    constructor(props) {
        super(props)
        this.state = {
            dataLoaded: false,
            progress: 0,
        }
    }

    setAdvancedFtpModal =() => {
        setModal({
            title: i18n.importParam,
            content: <ImportFileModal url={this.props.job.parameters.path} />,
        })
    }

    componentDidMount() {
        AppStore.dispatch(JobAction.loadCSVImportPanel(p => this.setState({ progress: p }), () => this.setState({ dataLoaded: true })))
    }

    onChangeJobParameter = (changes) => {
        const jobParameters = {
            ...this.props.job.parameters,
            ...changes,
        }
        this.props.onChangeJob({ parameters: jobParameters })
    }

    onChangeParametersNew = (currentParameter, index, changes) => {
        const jobParameters = {
            ...this.props.job.parameters,
            parameters: Object.assign([], this.props.job.parameters.parameters, {
                [index]: JSON.stringify({ ...currentParameter, ...changes }),
            }),
        }
        this.props.onChangeJob({ parameters: jobParameters })
    }

    onChangeFiltersNew = (changes) => {
        const parameters = {
            ...this.props.job.parameters,
            filters: [JSON.stringify({
                ...this.getFilters(),
                ...changes,
            })],
        }
        this.props.onChangeJob({ parameters })
    }

    getFilters = () => {
        const newFilters = this.props.job.parameters.filters.length ? JSON.parse(this.props.job.parameters.filters[0]) : {}
        return { measureCote: 2, ...newFilters }
    }

    getParametersNew = () => this.props.job.parameters.parameters.length ? this.props.job.parameters.parameters.map(p => JSON.parse(p)) : []

    onDeleteParameter = (index) => {
        if (this.props.isEditMode) {
            const jobParameters = {
                ...this.props.job.parameters,
                parameters: this.props.job.parameters.parameters.filter((_, i) => i !== index),
            }
            this.props.onChangeJob({ parameters: jobParameters })
        }
    }

    onDuplicateParameter = (index) => {
        if (this.props.isEditMode) {
            const jobParameters = {
                ...this.props.job.parameters,
                parameters: [ ...this.props.job.parameters.parameters, this.props.job.parameters.parameters[index] ],
            }
            this.props.onChangeJob({ parameters: jobParameters })
        }
    }

    getParametersData = (filters, parameters) => {
        const disabled = { disabled: !this.props.isEditMode }
        const stations = [
            ...this.props.piezometers.map(s => ({ ...s, name: `[${i18n.piezometer}] ${s.code ? `[${s.code}] ${s.name}` : s.name}`, stationType: 'piezometry', tmpId: `pi:${s.id}` })),
            ...this.props.pluviometers.map(s => ({ ...s, name: `[${i18n.pluviometer}] ${s.code ? `[${s.code}] ${s.name}` : s.name}`, stationType: 'pluviometry', tmpId: `pl:${s.id}` })),
            ...this.props.hydrometricStations.map(s => ({ ...s, name: `[${i18n.hydrometricStation}] ${s.code ? `[${s.code}] ${s.name}` : s.name}`, stationType: 'hydrometry', tmpId: `hy:${s.id}` })),
        ]
        return parameters.map((p, index) => {
            return {
                nullValue: <Icon icon='delete' tooltip={ i18n.delete } onClick={ () => this.onDeleteParameter(index) } />,
                nullValue2: <Icon icon='add_to_photos' tooltip={ i18n.duplicate } onClick={ () => this.onDuplicateParameter(index) } />,
                directory: <Input value={ p.directory } onChange={ v => this.onChangeParametersNew(p, index, { directory: v }) } {...disabled }/>,
                filter: <Input value={ p.filter } onChange={ v => this.onChangeParametersNew(p, index, { filter: v }) } {...disabled }/>,
                coeff: <NumberField value={ p.factor } onChange={ v => this.onChangeParametersNew(p, index, { factor: v }) } floatValue {...disabled }/>,
                offset: <NumberField value={ p.offset } onChange={ v => this.onChangeParametersNew(p, index, { offset: v }) } floatValue {...disabled }/>,
                station: (<Select options={ stations }
                    onChange={ (_, v) => {
                        return v && v.id ? this.onChangeParametersNew(p, index, { stationCode: v.code, stationId: v.id, stationType: v.stationType, tmpId: v.tmpId }) : null
                    } }
                    value={ p.tmpId } keyValue='tmpId' {...disabled }
                />),
            }
        })
    }

    addParameterNew = () => {
        if (this.props.isEditMode) {
            const jobParameters = {
                ...this.props.job.parameters,
                parameters: [ ...this.props.job.parameters.parameters, '{}'],
            }
            this.props.onChangeJob({ parameters: jobParameters })
        }
    }

    getDataTypes = (filters, category) => execByType(category, {
        pluviometry: () => this.props.pluviometryDataTypes,
        piezometry: () => [ ...getHardPiezoDataTypes(), ...this.props.piezometryDataTypes ],
        hydrometry: () => [ ...getHardHydroDataTypes(), ...this.props.hydrometryDataTypes ],
        default: () => [],
    })

    onChangeHandledData = (data, index, newData) => {
        this.onChangeFiltersNew({ handledData: data.map(d => d.index === index ? newData : d) })
    }

    onDeleteHandledData = (data, index) => {
        this.onChangeFiltersNew({ handledData: data.filter(d => d.index !== index) })
    }

    getHandledData = (filters) => {
        const { isEditMode } = this.props
        const dataTypes = [
            { value: 'pluviometry', label: i18n.pluviometry },
            { value: 'hydrometry', label: i18n.hydrometry },
            { value: 'piezometry', label: i18n.piezometry },
        ]
        const indexedData = (filters.handledData || []).map((d, index) => ({ ...d, index }))
        return indexedData.map((d, i) => {
            return {
                nullValue: <Icon icon='close' tooltip={i18n.delete} onClick={() => this.onDeleteHandledData(indexedData, i)} />,
                category: <Select options={dataTypes} value={d.dataCategory} onChange={v => this.onChangeHandledData(indexedData, i, { ...d, dataCategory: v })} disabled={!isEditMode} />,
                dataType: <Select options={this.getDataTypes(filters, d.dataCategory)} value={d.dataType} onChange={v => this.onChangeHandledData(indexedData, i, { ...d, dataType: v })} disabled={!(isEditMode && d.dataCategory)} />,
                discriminate: <NumberField value={d.discriminate} onChange={v => this.onChangeHandledData(indexedData, i, { ...d, discriminate: v })} disabled={!isEditMode} />,
            }
        })
    }

    exportData = (parameters) => {
        const data = parameters.map(p => {
            const param = JSON.parse(p)
            const line ={
                directory: param.directory,
                filter: param.filter,
                station: param.stationCode,
                coeff: param.factor,
                offset: param.offset,
                headers: ['directory', 'filter', 'station', 'coeff', 'offset'],
            }
            return line
        })
        return data
    }

    render() {
        if (this.state.dataLoaded) {
            const disabled = !this.props.isEditMode
            const filters = this.getFilters()
            const parameters = this.getParametersData(filters, this.getParametersNew())
            const baseHeaders = ['nullValue', 'nullValue2', 'directory', 'filter', 'station', 'coeff', 'offset']
            const zipAlgorithms = [
                { code: 'gzip', name: 'GZIP' },
            ]
            const data = this.exportData(this.props.job.parameters.parameters)
            const addDataAction = this.props.isEditMode ? [{
                onClick: this.addParameterNew,
                iconName: 'add_box',
                tooltip: `${i18n.add}`,
            }] : []
            return (
                <div>
                    <div className='row no-margin padding-top-1'>
                        <div className='col s2'/>
                        <div className='col s8'>
                            <Table data={this.getHandledData(filters)} title={i18n.dataTypes} type={{ headers: ['category', 'dataType', 'discriminate', 'nullValue'] }}
                                activeHeader condensed sortable showNbElements={ false }
                                actions={ [{ iconName: 'note_add', onClick: () => this.props.isEditMode && this.onChangeFiltersNew({ handledData: [...(filters.handledData || []), {}] }) }] }
                            />
                        </div>
                        <div className='col s2'/>
                    </div>
                    <div className='row no-margin padding-top-1 valign-wrapper'>
                        <Checkbox col={ 3 } label={ 'Renommer les fichiers en .DONE' } onChange={ v => this.onChangeFiltersNew({ renameProcessedFiles: v }) } disabled={ disabled } checked={ filters.renameProcessedFiles } />
                        <Checkbox col={ 4 } label={ 'Ne pas traiter les fichiers présents dans le journal' } onChange={ v => this.onChangeFiltersNew({ dontProcessFilesInLogs: v }) } disabled={ disabled } checked={ filters.dontProcessFilesInLogs } />
                        <Select col={ 2 } options={ zipAlgorithms } label={ 'Compression' } onChange={ v => this.onChangeFiltersNew({ zipFile: v }) } value={ filters.zipFile } />
                        <RadioButtons col={ 3 } elements={ getMeasureCoteList() } selected={ filters.measureCote } onChange={ v => this.onChangeFilters({ measureCote: v }) } title={ i18n.ratingExpression } disabled={ disabled }/>
                    </div>
                    <div className='row no-margin padding-top-1'>
                        <Input col={ 4 } title={ i18n.directory } value={ this.props.job.parameters.path } onChange={ v => this.onChangeJobParameter({ path: v }) } disabled={ disabled } />
                        <a className='btn btn-small secondary-color-back' onClick={ () => this.setAdvancedFtpModal()} ><span title={ i18n.importLabelFtp }> <i className='material-icons'>input </i> </span></a>
                        <NumberField col={ 3 } title={ i18n.excludedValue } value={ filters.excludedValue } onChange={ v => this.onChangeFiltersNew({ excludedValue: v }) } disabled={ disabled } floatValue />
                        <div className='col s3'/>
                    </div>
                    <div className='job-parameter-table'>
                        <Table
                            data={ parameters }
                            nbPerPageLabel={ nbPerPageLabelShort }
                            type={ { headers: [ ...baseHeaders ] } }
                            sortable
                            condensed
                            paging
                            exportButtonOnHeader
                            activeHeader
                            actions={ addDataAction }
                            exportData={ data }
                        />
                    </div>
                </div>
            )
        }
        return <ProgressCard progress={ this.state.progress }/>
    }
}


ParatronicPanel.propTypes = {
    job: PropTypes.instanceOf(Job).isRequired,
    onChangeJob: PropTypes.func.isRequired,
    piezometryDataTypes: arrayOf(DtoParametrageDataType),
    piezometers: arrayOf(DtoPiezometerLight),
    pluviometers: arrayOf(PluviometerDto),
    pluviometryDataTypes: arrayOf(DtoParametrageDataType),
    hydrometricStations: arrayOf(DtoHydrometricStation),
    hydrometryDataTypes: arrayOf(DtoParametrageDataType),
    isEditMode: PropTypes.bool,
}

const mapStateToProps = store => ({
    piezometryDataTypes: store.PiezometryReducer.piezometryDataTypes,
    piezometers: store.PiezometryReducer.piezometersLight,
    pluviometers: store.PluviometryReducer.pluviometers,
    pluviometryDataTypes: store.PluviometryReducer.pluviometryDataTypes,
    hydrometricStations: store.HydrometryReducer.hydrometricStations,
    hydrometryDataTypes: store.HydrometryReducer.hydrometryDataTypes,
})

export default connect(mapStateToProps)(ParatronicPanel)