import { uniqBy } from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import { parametresCSV } from '../../../administration/constants/RSEauAdminConstants'
import SieauParameterDto from '../../../administration/dto/SieauParameterDto'
import Card from '../../../components/card/Card'
import Input from '../../../components/forms/Input'
import NumberField from '../../../components/forms/NumberField'
import Row from '../../../components/react/Row'
import { MEASURES_TYPES, OBJECT_TYPES } from '../../../iaeau/constants/IAEauConstants'
import DtoGenericMeasure from '../../../iaeau/dto/DtoGenericMeasure'
import IAEauAction from '../../../iaeau/IAEauAction'
import { getDate } from '../../../utils/DateUtil'
import { onChangeDate } from '../../../utils/FormUtils'
import { hasValue } from '../../../utils/NumberUtil'
import { getLogin, getSetting } from '../../../utils/SettingUtils'
import { arrayOf } from '../../../utils/StoreUtils'

class LandRSEauSettingModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            measures: [],
        }
    }

    componentDidMount() {
        this.props.getMeasures(OBJECT_TYPES.LAND, this.props.landId, MEASURES_TYPES.RSEau_Land_Parameters).then(() => this.setState({ measures: this.props.genericMeasures }))
    }

    getValue = param => {
        const found = this.state.measures.find(m => m.valueStr === param.field)
        if (found) {
            if (param.type === 'float' || param.type === 'int' || param.type === 'date') {
                return found.value
            }
            return found.valueStr2
        }
        return null
    }

    getDefault = (param) => {
        const setting = getSetting(this.props.applicationSettings, `RSEau_parametres_${param.field}`)
        return hasValue(setting) ? setting : param.default
    }

    getNewMeasure = (param, v) => {
        const found = this.state.measures.find(m => m.valueStr === param.field)
        if (found) {
            if (param.type === 'float' || param.type === 'int' || param.type === 'date') {
                return { ...found, value: v }
            }
            return { ...found, valueStr2: v }
        }
        const newOne = {
            objectType: OBJECT_TYPES.LAND,
            objectId: this.props.landId,
            measureType: MEASURES_TYPES.RSEau_Land_Parameters,
            updateLogin: getLogin(),
            valueStr: param.field,
        }
        if (param.type === 'float' || param.type === 'int' || param.type === 'date') {
            return { ...newOne, value: v }
        }
        return { ...newOne, valueStr2: v }
    }

    onSave = () => {
        this.props.setMeasures(OBJECT_TYPES.LAND, this.props.landId, MEASURES_TYPES.RSEau_Land_Parameters, this.state.measures).then(() => $('.modal').modal('close'))
    }

    render = () => {
        const change = (param, v) => {
            if (!hasValue(v)) {
                this.setState({ measures: this.state.measures.filter(m => m.valueStr !== param.field) })
            } else {
                this.setState({ measures: uniqBy([this.getNewMeasure(param, v), ...this.state.measures], 'valueStr') })
            }
        }
        const makeFileds = (fields) => fields.map(param => {
            const value = this.getValue(param)
            if (param.type === 'float') {
                return (<NumberField col={ 3 } title={ `${param.field} (defaut: ${this.getDefault(param)})` }
                    value={ value }
                    onChange={ (v) => change(param, v) } floatValue
                />)
            }
            if (param.type === 'date') {
                return (<Input col={ 3 } title={ `${param.field} (defaut: ${getDate(this.getDefault(param))})` }
                    value={ getDate(value) }
                    onChange={ (v) => onChangeDate(v, v2 => change(param, v2)) }
                />)
            }
            if (param.type === 'text') {
                return (<Input col={ 3 } title={ `${param.field} (defaut: ${this.getDefault(param)})` }
                    value={ value }
                    onChange={ (v) => change(param, v) }
                />)
            }
            return (<NumberField col={ 3 } title={ `${param.field} (defaut: ${this.getDefault(param)})` }
                value={ value }
                onChange={ (v) => change(param, v) }
            />)
        })
        return (
            <div>
                <Row>
                    <Card title={ 'general' }><Row className='padding-top-1'>{ makeFileds(parametresCSV.filter(f => f.file === 'general')) }</Row></Card>
                    <Card title={ 'parametres.csv' }><Row className='padding-top-1'>{ makeFileds(parametresCSV.filter(f => f.file === 'parametres.csv')) }</Row></Card>
                    <Card title={ 'irrigmaker.txt' }><Row className='padding-top-1'>{ makeFileds(parametresCSV.filter(f => f.file === 'irrigmaker.txt')) }</Row></Card>
                    <Card title={ 'param_EE.csv' }><Row className='padding-top-1'>{ makeFileds(parametresCSV.filter(f => f.file === 'param_EE.csv')) }</Row></Card>
                </Row>
                <div className='row no-margin padding-top-1 padding-bottom-1'>
                    <a className='waves-effect waves-teal btn-flat btn font-white primary-button right margin-left-1' onClick={ this.onSave }>{ i18n.register }</a>
                    <a className='waves-effect waves-teal btn-flat modal-close secondary-button right margin-left-1'>{ i18n.close }</a>
                </div>
            </div>
        )
    }
}

LandRSEauSettingModal.propTypes = {
    applicationSettings: arrayOf(SieauParameterDto),
    landId: PropTypes.number,
    genericMeasures: arrayOf(DtoGenericMeasure),
    getMeasures: PropTypes.func,
    setMeasures: PropTypes.func,
}

const mapStateToProps = store => ({
    genericMeasures: store.IAEauReducer.genericMeasures,
    applicationSettings: store.AdministrationReducer.applicationSettings,
})

const mapDispatchToProps = {
    getMeasures: IAEauAction.fetchGenericMeasures,
    setMeasures: IAEauAction.setGenericMeasures,
}

export default connect(mapStateToProps, mapDispatchToProps)(LandRSEauSettingModal)