import React, { Component } from 'react'
import Table from '../../../../components/datatable/Table'
import PropTypes from 'prop-types'
import { push } from '@lagunovsky/redux-react-router'
import SimSubscriptionDto from '../../sim/dto/SimSubscriptionDto'
import Card from '../../../../components/card/Card'
import SubscriptionAction from '../actions/SubscriptionAction'
import { connect } from 'react-redux'
import { exportFile } from '../../../../utils/ExportDataUtil'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import i18n from 'simple-react-i18n'
import DatePicker from '../../../../components/forms/DatePicker'
import Select from '../../../../components/forms/Select'
import Textarea from '../../../../components/forms/Textarea'
import moment from 'moment'

class SimSubscriptionTable extends Component {
    state = {
        isOpen: false,
        newLink: { startDate: moment().valueOf() },
    }

    resetState = () => this.setState({ isOpen: false, newLink: { startDate: moment().valueOf() } })

    render = () => {
        const {
            editMode,
            links,
            onDelete,
            onAdd,
            title,
            headers,
            associatedMateriels,
            idKeyToRedirect,
            redirectTo,
        } = this.props
        const {
            isOpen,
            newLink,
        } = this.state

        const exportData = links.map((link, index) => {
            if (index === 0) {
                return {
                    ...link,
                    headers,
                }
            }
            return link
        })

        const actions = [
            {
                iconName: 'note_add',
                onClick: () => this.setState({ isOpen: true }),
            },
            {
                iconName: 'file_download',
                onClick: () => exportFile({
                    data: exportData,
                    exportType: 'xlsx',
                    titleFile: title,
                }),
            },
        ]
        return (
            <div className='row margin-top-2'>
                <div className='col s12'>
                    <Card title={title} active={editMode} actions={actions}>
                        <Table
                            showTitle={false}
                            data={links}
                            type={{ headers }}
                            sortable
                            onClick={({ [idKeyToRedirect]: id }) => this.props.push(`/materiel/${redirectTo}/${id}`)}
                            deletable={editMode}
                            onDelete={onDelete}
                            condensed
                        />
                    </Card>
                </div>
                <Dialog
                    onClose={this.resetState}
                    fullWidth
                    maxWidth='lg'
                    open={isOpen}
                >
                    <DialogTitle>{i18n.newLink}</DialogTitle>
                    <DialogContent>
                        <div className='row no-margin' style={{ minHeight: '30vh' }}>
                            <div className='col s12 padding-top-1'>
                                <Select
                                    options={associatedMateriels}
                                    label={i18n.materiels}
                                    col={9}
                                    onChange={v => this.setState({ newLink: { ...newLink, materielLink: v } })}
                                    value={newLink.materielLink}
                                    nullLabel='&nbsp;'
                                    clearFunction
                                />
                                <DatePicker
                                    col={3}
                                    title={i18n.startDate}
                                    value={newLink.startDate}
                                    onChange={(_, v) => this.setState({ newLink: { ...newLink, startDate: v } })}
                                />
                            </div>
                            <div className='col s12 padding-top-2'>
                                <Textarea
                                    col={12}
                                    title={i18n.comment}
                                    value={newLink.comment}
                                    onChange={v => this.setState({ newLink: { ...newLink, comment: v } })}
                                />
                            </div>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.resetState} variant='outlined' >
                            {i18n.close}
                        </Button>
                        <Button onClick={() => {
                            this.resetState()
                            onAdd(newLink)
                        }} variant='contained' color='primary'
                        >
                            {i18n.validate}
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        )
    }
}

SimSubscriptionTable.propTypes = {
    editMode: PropTypes.bool,
    links: PropTypes.arrayOf(PropTypes.instanceOf(SimSubscriptionDto)),
    headers: PropTypes.arrayOf(PropTypes.string),
    title: PropTypes.string,
    idKeyToRedirect: PropTypes.string,
    redirectTo: PropTypes.oneOf(['sim', 'subscription']),
    associatedMateriels: PropTypes.arrayOf(PropTypes.shape({
        code: PropTypes.number,
        name: PropTypes.string,
    })),
    onDelete: PropTypes.func,
    onAdd: PropTypes.func,
    push: PropTypes.func,
}

const mapDispatchToProps = {
    push,
    deleteSim: SubscriptionAction.deleteSim,
}

export default connect(null, mapDispatchToProps)(SimSubscriptionTable)