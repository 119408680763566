import { round } from '../../../../utils/NumberUtil'
import { isNil, isUndefined, sum } from 'lodash'
import { StyledFieldSet, StyledLegend } from '../../../../components/StyledElements'
import i18n from 'simple-react-i18n'
import { Grid } from '@mui/material'
import SimpleDatePicker from '../../../../components/forms/SimpleDatePicker'
import { ButtonMUI } from '../../../../components/styled/Buttons'
import DisplayedValue from '../../../../components/forms/DisplayedValue'
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import DtoPluvioMeasure from '../../../dto/measures/DtoPluvioMeasure'


const PanelEfficientRainCalcul = ({
    allMeasures,
}) => {
    const [startDate, setStartDate] = useState()
    const [endDate, setEndDate] = useState()

    const [tmpStart, setTmpStart] = useState()
    const [tmpEnd, setTmpEnd] = useState()

    const [rainTotal, etpTotal, effTotal] = (() => {
        if (!isUndefined(startDate) && !isUndefined(endDate)) {
            const { rain = [], ETP: etp = [], efficientRain = [] } = allMeasures
            return [
                sum(rain.filter(m => m.date >= startDate && m.date <= endDate).map(m => m.value)),
                sum(etp.filter(m => m.date >= startDate && m.date <= endDate).map(m => m.value)),
                sum(efficientRain.filter(m => m.date >= startDate && m.date <= endDate).map(m => m.value))
            ]
        }
        return ['-', '-', '-']
    })()

    return (
        <StyledFieldSet>
            <StyledLegend>{i18n.effectiveRainCalculation}</StyledLegend>
            <div>
                <Grid container justifyContent='center' alignItems='center' spacing={3}>
                    <Grid item xs={3}>
                        <SimpleDatePicker
                            id='startDate'
                            label={i18n.startDate}
                            value={tmpStart}
                            onChange={setTmpStart}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <SimpleDatePicker
                            id='endDate'
                            label={i18n.endDate}
                            value={tmpEnd}
                            onChange={(v) => setTmpEnd(v)}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <ButtonMUI
                            onClick={() => {
                                setStartDate(tmpStart)
                                setEndDate(tmpEnd)
                            } }
                            variant='contained'
                            disabled={ isNil(tmpStart) || isNil(tmpEnd) }
                        >
                            {i18n.calculate}
                        </ButtonMUI>
                    </Grid>
                </Grid>
                <Grid container justifyContent='center' alignItems='center' spacing={3} >
                    <Grid item xs={4}>
                        <DisplayedValue label={ i18n.rain } value={ rainTotal }/>
                    </Grid>
                    <Grid item xs={4}>
                        <DisplayedValue label={ i18n.etpShort } value={ etpTotal }/>
                    </Grid>
                    <Grid item xs={4}>
                        <DisplayedValue label={ i18n.effectiveRain } value={ effTotal }/>
                    </Grid>
                </Grid>
            </div>
        </StyledFieldSet>
    )
}
PanelEfficientRainCalcul.propTypes = {
    allMeasures: PropTypes.shape({
        rain: PropTypes.arrayOf(PropTypes.instanceOf(DtoPluvioMeasure)),
        ETP: PropTypes.arrayOf(PropTypes.instanceOf(DtoPluvioMeasure)),
        efficientRain: PropTypes.arrayOf(PropTypes.instanceOf(DtoPluvioMeasure)),
    }),
}
export default PanelEfficientRainCalcul