export default class DtoPAREvent {
    constructor(object) {
        this.idPAR = object.idPAR // Long,
        this.number = object.number // Option[Long] = None,
        this.eventDate = object.eventDate // Option[DateTime] = None,
        this.comment = object.comment // Option[String] = None,
        this.eventType = object.eventType // Option[String] = None,
        this.associateType = object.associateType // Option[String] = None,
        this.associateId = object.associateId // Option[Long] = None,
        this.closeDate = object.closeDate // Option[DateTime] = None,
        this.updateDate = object.updateDate // Option[DateTime],
        this.updateLogin = object.updateLogin // Option[String],
        this.jobexecutionid = object.jobexecutionid // Option[Long] = None
    }
}