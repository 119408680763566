/* eslint-disable camelcase */
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import Table from '../../../components/datatable/Table'
import SieauAction from '../../../components/sieau/SieauAction'
import DtoDistributionUnit from '../../../distributionUnit/dto/DtoDistributionUnit'
import DtoHydrometricStation from '../../../hydrometry/dto/DtoHydrometricStation'
import DtoInstallation from '../../../installation/dto/installation/DtoInstallation'
import DtoPiezometer from '../../../piezometry/dto/DtoPiezometer'
import DtoProductionUnit from '../../../productionUnit/dto/DtoProductionUnit'
import DtoQualitometer from '../../../quality/dto/DtoQualitometer'
import { SANDRE } from '../../../referencial/constants/ReferencialConstants'
import DtoSandreCode from '../../../referencial/dto/DtoSandreCode'
import { getFullDate } from '../../../utils/DateUtil'
import { getExport, setModal } from '../../../utils/linkUtils'
import { arrayOf } from '../../../utils/StoreUtils'
import { getSandreLabel } from '../../../utils/StringUtil'
import MeasureMethodModal from './modals/MeasureMethodModal'

const StationMeasureMethodPanel = ({
    station = {},
    sandreCodes = [],
    readMode = false,
    onChange = () => {},
    setPopup = () => {},
    className = '',
}) => {
    const stationMeasureMethods = station.link_measureMethod.map((mm, index) => ({
        ...mm,
        index,
    }))
    const tableData = stationMeasureMethods.map(mm => ({
        ...mm,
        startDate: getFullDate(mm.startDate),
        endDate: getFullDate(mm.endDate),
        method: getSandreLabel(sandreCodes, SANDRE.MEASURE_MODE, mm.method),
        periodicity: getSandreLabel(sandreCodes, SANDRE.MEASURE_PERIODICITY, mm.periodicity),
        compensation: mm.measureOffset,
    }))
    const headers = station.typeName === 'piezometry' ?
        ['startDate', 'endDate', 'method', 'periodicity', 'comment', 'depth', 'compensation']
        : ['startDate', 'endDate', 'method', 'periodicity', 'comment', 'compensation']
    const exportAction = getExport(tableData, i18n.measureMethods, headers)
    const actions = !readMode ? [{
        iconName: 'note_add',
        tooltip: i18n.add,
        onClick: () => setModal(i18n.newMeasureMethod, <MeasureMethodModal id={ station.id } typeName={station.typeName} saveResult={ r => onChange({ link_measureMethod: [...stationMeasureMethods, r] }) }/>, setPopup),
    }, exportAction] : [exportAction]

    return (
        <div id='measureMethods'>
            <Table
                title={ i18n.measureMethods }
                condensed
                data={ tableData }
                type={{ headers }}
                className = {className}
                sortable={ !!tableData.length }
                actions={ actions }
                alterable={ !readMode }
                onAlter={
                    (element) => setModal(i18n.editMeasureMethods,
                        <MeasureMethodModal
                            id={ station.id }
                            typeName={station.typeName}
                            measureMethod={ stationMeasureMethods.find(mm => element.index === mm.index) }
                            saveResult={ r => onChange({
                                link_measureMethod: [
                                    ...stationMeasureMethods.filter(sc => sc.index !== element.index),
                                    { ...r, index: element.index },
                                ],
                            })}
                        />
                        , setPopup)
                }
                deletable={ !readMode }
                onDelete={ (element) => onChange({ link_measureMethod: stationMeasureMethods.filter(sc => sc.index !== element.index) }) }
            />
        </div>
    )
}

StationMeasureMethodPanel.propTypes = {
    station: PropTypes.oneOfType([
        PropTypes.instanceOf(DtoProductionUnit),
        PropTypes.instanceOf(DtoDistributionUnit),
        PropTypes.instanceOf(DtoQualitometer),
        PropTypes.instanceOf(DtoPiezometer),
        PropTypes.instanceOf(DtoHydrometricStation),
        PropTypes.instanceOf(DtoInstallation),
    ]),
    sandreCodes: arrayOf(DtoSandreCode),
    readMode: PropTypes.bool,
    onChange: PropTypes.func,
    setPopup: PropTypes.func,
    className: PropTypes.string,
}

const mapStateToProps = store => ({
    sandreCodes: store.ReferencialReducer.sandreCodes,
})

const mapDispatchToProps = {
    setPopup: SieauAction.setPopup,
}

export default connect(mapStateToProps, mapDispatchToProps)(StationMeasureMethodPanel)