import ReactEchartsCore from 'echarts-for-react/lib/core'
import echarts from 'echarts/lib/echarts'
import PropTypes from 'prop-types'
import React, { Component } from 'react'

class LoadingPieChart extends Component {
    getOption = () => {
        return {
            series: [
                {
                    type: 'pie',
                    data: [
                        { value: this.props.value },
                        { value: 100 - this.props.value },
                    ],
                    radius: ['50%', '70%'],
                    emphasis: {
                        show: true,
                        itemStyle: false,
                    },
                    label: {
                        normal: {
                            show: false,
                        },
                        emphasis: {
                            show: false,
                        },
                    },
                    labelLine: {
                        normal: {
                            show: false,
                        },
                    },
                    hoverAnimation: false,
                },
            ],
        }
    }

    render() {
        return (
            <ReactEchartsCore
                echarts={ echarts }
                option={ this.getOption() }
                className={ 'row no-margin' }
                style={ { height: 55 } }
            />
        )
    }
}

LoadingPieChart.propTypes = {
    value: PropTypes.number,
}

export default LoadingPieChart