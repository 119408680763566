import React, { useEffect, useMemo, useState } from 'react'
import logoSandre from 'assets/pictures/logo_sandre.png'
import { push } from '@lagunovsky/redux-react-router'
import { find } from 'lodash'
import i18n from 'simple-react-i18n'
import { getDataWithHeaders } from 'utils/ExportDataUtil'
import { sandreHydrogeologicalEntityDefaultLink } from '../../../../conf/SieauConstants'
import HomeAction from '../../../../home/actions/HomeAction'
import {
    PATH_REFERENCIAL,
    PATH_REFERENCIAL_HYDROGEOLOGICAL_ENTITY,
    PATH_REFERENCIAL_HYDROGEOLOGICAL_ENTITY_NEW,
} from '../../../../home/constants/RouteConstants'
import ReferencialAction from '../../../action/ReferencialAction'
import HydrogeologicalEntityAction from '../actions/HydrogeologicalEntityAction'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import useTitle from 'utils/customHook/useTitle'
import useActions from 'utils/customHook/useActions'
import { nbPerPageLabel, REFERENCIAL_TYPE_NAME } from 'referencial/constants/ReferencialConstants'
import Table from 'components/datatable/Table'
import Card from 'components/card/Card'
import Row from 'components/react/Row'
import Input from 'components/forms/Input'
import Select from 'components/forms/Select'
import { Button } from '@mui/material'
import { getFullDate } from 'utils/DateUtil'
import useSessionStorage from 'utils/customHook/useSessionStorage'
import { searchAllCharacters } from 'utils/StringUtil'
import { hasValue } from 'utils/NumberUtil'
import { getStatusInformation } from 'referencial/util/StatusUtils'

const headers = ['code', 'name', 'denomination', 'status']

const HydrogeologicalEntitiesApp = () => {
    const {
        hydrogeologicalEntities,
        referencialSandreCodes,
        referencialStatus,
    } = useSelector(store => ({
        hydrogeologicalEntities: store.HydrogeologicalEntityReducer.hydrogeologicalEntities,
        referencialSandreCodes: store.ReferencialReducer.referencialSandreCodes,
        referencialStatus: store.ReferencialReducer.referencialStatus,
    }), shallowEqual)

    const [filter, setFilter] = useSessionStorage(`referencial_${REFERENCIAL_TYPE_NAME.hydrogeologicalEntity}`, {})
    const [filterTmp, setFilterTmp] = useState(filter)

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(HydrogeologicalEntityAction.fetchHydrogeologicalEntities())
        dispatch(ReferencialAction.fetchReferencialSandreCodes())
        dispatch(HomeAction.setHelpLink('', ''))
        if (!referencialStatus.length) {
            dispatch(ReferencialAction.fetchReferencialStatus())
        }
    }, [])

    useTitle(() => [{
        title: i18n.referencials,
        href: PATH_REFERENCIAL,
    }, {
        title: i18n.hydrogeologicalEntities,
        href: PATH_REFERENCIAL_HYDROGEOLOGICAL_ENTITY,
    }], [])

    const hydroByStatus = useMemo(() => hasValue(filter.status) ? hydrogeologicalEntities.filter(h => h.status === filter.status) : hydrogeologicalEntities, [filter.status, hydrogeologicalEntities])
    const data = useMemo(() => {
        return hydroByStatus
            .filter((obj) => searchAllCharacters(headers.map((key) => obj[key]).join())
                .includes(searchAllCharacters(filter.searchValue || '')))
    }, [hydroByStatus, filter])

    const dataFormatted = useMemo(() => data.map(s => ({
        ...s,
        code: { value: s.code, sortValue: s.code },
        name: { value: s.name, sortValue: s.name },
        status: { value: s.status ? getStatusInformation(s.status.toString()) : '', sortValue: s.status },
        denomination: { value: s.denomination, sortValue: s.active },
    })), [data])

    useActions(() => {
        return {
            new: () => dispatch(push(PATH_REFERENCIAL_HYDROGEOLOGICAL_ENTITY_NEW)),
            referencialActions: {
                actions: {
                    export: {
                        data: getDataWithHeaders(data, headers),
                        titleFile: i18n.hydrogeologicalEntitiesList,
                    },
                    purge: {
                        onPurge: () => dispatch(HydrogeologicalEntityAction.purgeHydrogeologicalEntity()),
                    },
                },
            },
            links: [{
                href: sandreHydrogeologicalEntityDefaultLink,
                img: logoSandre,
                label: i18n.sandre.toUpperCase(),
            }],
        }
    }, [data])

    const lastUpdate = useMemo(() => find(referencialSandreCodes, o => o.field === 'ENTITES_HYDROGEOLOGIQUES'), [referencialSandreCodes])

    return (
        <>
            <Card noMargin={false} className='margin-left-1 margin-right-1' title={i18n.criterias}>
                <Row className='padding-top-1 padding-bottom-5-px'>
                    <Input
                        col={3}
                        title={i18n.search}
                        value={filterTmp.searchValue}
                        onChange={v => setFilterTmp({ ...filterTmp, searchValue: v })}
                        onEnterKeyPress={() => setFilter({ ...filterTmp })}
                    />
                    <Select
                        options={referencialStatus}
                        label={i18n.status}
                        col={3}
                        onChange={v => setFilterTmp({ ...filterTmp, status: v })}
                        value={filterTmp.status}
                        nullLabel='&nbsp;'
                    />
                    <div className='col s6 padding-top-1'>
                        <Button className='right' onClick={() => setFilter({ ...filterTmp })} variant='contained' color='primary'>
                            {i18n.search}
                        </Button>
                    </div>
                </Row>
            </Card>
            {lastUpdate?.updateUse && lastUpdate?.updateDate && (
                <div className='row no-margin-bottom'>
                    <div className='col s4 center offset-s8 card'>
                        <div className='col s12'>
                            { i18n.lastUpdateOn } { getFullDate(lastUpdate.updateDate) } { i18n.by } { lastUpdate.updateUser }
                        </div>
                    </div>
                </div>
            )}
            <div className='margin-1 padding-top-5-px referencial-table'>
                <Table
                    title={i18n.hydrogeologicalEntitiesList}
                    condensed paging
                    nbPerPageLabel={nbPerPageLabel}
                    data={dataFormatted}
                    color
                    type={{ headers }}
                    sortable={!!data.length}
                    onClick={hydrogeologicalEntity => dispatch(push(`/referencial/hydrogeologicalEntity/${hydrogeologicalEntity.id}/dashboard`))}
                />
            </div>
        </>
    )
}

export default HydrogeologicalEntitiesApp
