import { Icon, IconButton, Tooltip } from '@mui/material'
import React from 'react'
import PropTypes from 'prop-types'
import { isNil } from 'lodash'

const Action = ({
    icon,
    onClick,
    tooltip,
    color = '#161832',
    'data-cy': dataCy,
}) => {
    const button = (
        <IconButton
            sx={{ fontSize: '32px' }}
            onClick={onClick}
            data-cy={dataCy}
        >
            <Icon fontSize='inherit' sx={{ color }}>{icon}</Icon>
        </IconButton>
    )
    if (isNil(tooltip)) return button
    return (
        <Tooltip title={tooltip}>
            {button}
        </Tooltip>
    )
}

Action.propTypes = {
    icon: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    tooltip: PropTypes.string,
    color: PropTypes.string,
    'data-cy': PropTypes.string,
}

export default Action