import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { hasValue } from 'utils/NumberUtil'
import { v4 as uuidv4 } from 'uuid'

const $ = window.$

class SectionList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            open: hasValue(props.openFirst) ? props.openFirst : true,
            collapsible: uuidv4(),
            loaded: false,
        }
    }

    toggleOpen = () => {
        this.setState(({ open }) => ({ open: !open, loaded: !open || this.state.loaded }))
    }

    componentDidMount() {
        const { openFirst } = this.props
        $(`.${this.state.collapsible}`).collapsible('open', openFirst ? 0 : 1).collapsible('open', 1)
        this.setState({ open: openFirst, loaded: openFirst })
    }

    executePropsAction = (e, onClick = () => {}) => {
        e.stopPropagation()
        onClick()
    }

    render() {
        const { color, title, children, titleStyle, actions = [], condensed, className } = this.props
        const { collapsible, open } = this.state
        const condensedTitle = condensed && { paddingLeft: '20px' } || {}
        return (
            <li className={`desactive ${className}`}>
                <ul className={ `no-margin ${collapsible}` } data-collapsible='expandable'>
                    <li className='sub-card-list'>
                        <div className={`card-panel card-list-title margin-top-0 margin-bottom-0 row ${condensed && 'no-padding' || 'padding-1'} collapsible-header valign-wrapper`} onClick={this.toggleOpen} style={{ background: color, borderBottom: '0px', borderRadius: '0px' }}>
                            <h6 className='col s12 white-text valign-wrapper' style={{ textAlign: 'left', ...titleStyle, ...condensedTitle }}>{title}</h6>
                            {actions.map((a) => (
                                <i
                                    className='col s1 right-align medium material-icons no-margin'
                                    onClick={(e) => this.executePropsAction(e, a.onClick)}
                                >
                                    {a.iconName}
                                </i>
                            ))}
                            <i className='col s1 right-align medium material-icons no-margin expand-style-thin'>{ open ? 'expand_less' : 'expand_more' }</i>
                        </div>
                        <div className='col s12 content no-padding collapsible-body' style={ { border: `solid ${color}` } }>
                            { this.props.loadIfOpen ? (this.state.loaded ? children : null) : children }
                        </div>
                    </li>
                </ul>
            </li>

        )
    }
}

SectionList.propTypes = {
    openFirst: PropTypes.bool,
    collapsible: PropTypes.bool,
    title: PropTypes.string.isRequired,
    number: PropTypes.number,
    color: PropTypes.string,
    className: PropTypes.string,
    children: PropTypes.element.isRequired,
    titleStyle: PropTypes.shape({}),
    actions: PropTypes.arrayOf(PropTypes.shape({
        onClick: PropTypes.func.isRequired,
        iconName: PropTypes.string.isRequired,
    })),
    condensed: PropTypes.bool,
    loadIfOpen: PropTypes.bool,
}

SectionList.defaultProps = {
    collapsible: false,
    titleStyle: {},
}

export default SectionList
