import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import Input from '../../../../components/forms/Input'
import Select from '../../../../components/forms/Select'
import Textarea from '../../../../components/forms/Textarea'
import Row from '../../../../components/react/Row'
import { getHour } from '../../../../utils/DateUtil'
import { checkMandatoryFields, onChangeHour } from '../../../../utils/FormUtils'
import { arrayOf, getSandreList } from '../../../../utils/StoreUtils'
import SimpleDatePicker from 'components/forms/SimpleDatePicker'
import DtoPointPrel from '../../../dto/DtoPointPrel'
import DtoParametrageDataType from '../../../../piezometry/dto/DtoParametrageDataType'
import NumberField from '../../../../components/forms/NumberField'
import { SANDRE } from '../../../../referencial/constants/ReferencialConstants'
import DtoSandreCode from '../../../../referencial/dto/DtoSandreCode'

class PointPrelModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            pointprel: { isNew: true, ...props.pointprel, idStation: props.id },
        }
    }

    componentDidUpdate(prevProps) {
        if (!prevProps.popupIsOpen && this.props.popupIsOpen) {
            $('#descriptionModal').on('click', this.onSave)
        }
    }

    onSave = () => {
        checkMandatoryFields([
            { field: 'typeId', i18n: 'dataType' },
            { field: 'code', i18n: 'code' },
            { field: 'name', i18n: 'name' },
            { field: 'typePoint', i18n: 'pointType' },
        ], this.state.pointprel, () => {
            this.props.saveResult(new DtoPointPrel(this.state.pointprel))
            $('.modal').modal('close')
        })
    }

    onChangeElement = (changes) => this.setState({ pointprel: { ...this.state.pointprel, ...changes } })

    render() {
        const { pointprel } = this.state
        const pointTypes = [
            { code: 1, name: i18n.pointPrincipal },
            { code: 2, name: i18n.pointSec },
            { code: 3, name: i18n.pointInternalMonitoring },
        ]
        return (
            <div>
                <Row>
                    <Input col={ 6 } title={ i18n.code } value={ pointprel.code } obligatory
                        onChange={ v => this.onChangeElement({ code: v }) }
                    />
                    <Input col={ 6 } title='ID' value={ pointprel.point } disabled={ true } obligatory/>
                </Row>
                <Row>
                    <Select col={ 6 } options={ this.props.dt } label={ i18n.dataType } disabled={ !pointprel.isNew } obligatory
                        value={ pointprel.typeId } onChange={ v => this.onChangeElement({ typeId: v }) }
                    />
                    <Input col={ 6 } title={ i18n.name } value={ pointprel.name } obligatory
                        onChange={ v => this.onChangeElement({ name: v }) }
                    />
                </Row>
                <Row>
                    <Select col={ 6 } options={ pointTypes } label={ i18n.pointType } obligatory
                        value={ pointprel.typePoint } onChange={ v => this.onChangeElement({ typePoint: v }) }
                    />
                </Row>
                <Row>
                    <SimpleDatePicker
                        col={6}
                        value={pointprel.startDate}
                        label={i18n.startDate}
                        onChange={v => this.onChangeElement({ startDate: v })}
                        max={pointprel.endDate}
                    />
                    <Input col={ 6 } title={ i18n.startHour } value={ getHour(pointprel.startDate) }
                        onChange={ v => onChangeHour(v, v2 => this.onChangeElement({ startDate: v2 }), { max: pointprel.endDate }, pointprel.startDate) }
                    />
                </Row>
                <Row>
                    <SimpleDatePicker
                        col={6}
                        value={pointprel.endDate}
                        label={i18n.endDate}
                        onChange={v => this.onChangeElement({ endDate: v })}
                        min={pointprel.startDate}
                    />
                    <Input col={ 6 } title={ i18n.endHour } value={ getHour(pointprel.endDate) }
                        onChange={ v => onChangeHour(v, v2 => this.onChangeElement({ endDate: v2 }), { min: pointprel.startDate }, pointprel.endDate) }
                    />
                </Row>
                <Row>
                    <NumberField col={ 3 } title={ i18n.x } value={ pointprel.x } floatValue
                        onChange={ v => this.onChangeElement({ x: v }) }
                    />
                    <NumberField col={ 3 } title={ i18n.y } value={ pointprel.y } floatValue
                        onChange={ v => this.onChangeElement({ y: v }) }
                    />
                    <NumberField col={ 3 } title={ i18n.z } value={ pointprel.z } floatValue
                        onChange={ v => this.onChangeElement({ z: v }) }
                    />
                    <Select col={ 3 } label={i18n.projection} value={pointprel.projection}
                        options={getSandreList(this.props.sandreCodes, SANDRE.PROJECTION)}
                        onChange={v => this.onChangeElement({ projection: v })} integerValue
                    />
                </Row>
                <div className='row no-margin padding-top-1'>
                    <Textarea col={ 12 } title={ i18n.comment } value={ pointprel.comment } onChange={ v => this.onChangeElement({ comment: v }) }/>
                </div>
            </div>
        )
    }
}

PointPrelModal.propTypes = {
    pointprel: PropTypes.instanceOf(DtoPointPrel),
    id: PropTypes.number,
    popupIsOpen: PropTypes.bool,
    saveResult: PropTypes.func,
    dt: arrayOf(DtoParametrageDataType),
    sandreCodes: PropTypes.arrayOf(PropTypes.instanceOf(DtoSandreCode)),
}


const mapStateToProps = store => ({
    popupIsOpen: store.SieauReducer.popupIsOpen,
    sandreCodes: store.ReferencialReducer.sandreCodes,
})

PointPrelModal.defaultProps = {
    pointprel: {},
}

export default connect(mapStateToProps)(PointPrelModal)
