import { uniqBy, xor } from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import ProgressCard from '../../../../components/card/ProgressCard'
import Table from '../../../../components/datatable/Table'
import Checkbox from '../../../../components/forms/Checkbox'
import Input from '../../../../components/forms/Input'
import NumberField from '../../../../components/forms/NumberField'
import RadioButtons from '../../../../components/forms/RadioButtons'
import Select from '../../../../components/forms/Select'
import Icon from '../../../../components/icon/Icon'
import DtoHydrometricStation from '../../../../hydrometry/dto/DtoHydrometricStation'
import DtoParametrageDataType from '../../../../piezometry/dto/DtoParametrageDataType'
import DtoPiezometerLight from '../../../../piezometry/dto/DtoPiezometerLight'
import PluviometerDto from '../../../../pluviometry/dto/PluviometerDto'
import { nbPerPageLabelShort } from '../../../../referencial/constants/ReferencialConstants'
import { setModal } from '../../../../utils/FormUtils'
import { getHardHydroDataTypes } from '../../../../utils/HydroUtils'
import { getMeasureCoteList } from '../../../../utils/JobUtils'
import { hasValue } from '../../../../utils/NumberUtil'
import { getHardPiezoDataTypes } from '../../../../utils/PiezometryUtils'
import { execByType, getStations } from '../../../../utils/StationUtils'
import { removeNullKeys } from '../../../../utils/StoreUtils'
import JobAction from '../../../actions/JobAction'
import Job from '../../../dto/DtoJob'
import CSVGenericAdvancedModal from './CSVGenericAdvancedModal'
import ImportFileModal from './ImportFileModal'
import { integrationModeHelpIcon } from '../../../utils/ImportUtils'

class VeoliaPanel extends Component {
    constructor(props) {
        super(props)
        this.state = {
            dataLoaded: false,
            progress: 0,
        }
    }

    componentDidMount() {
        this.props.loadCSVImportPanel(p => this.setState({ progress: p }), () => this.setState({ dataLoaded: true }))
    }

    onChangeJobParameter = (changes) => {
        const jobParameters = {
            ...this.props.job.parameters,
            ...changes,
        }
        this.props.onChangeJob({ parameters: jobParameters })
    }

    onChangeParametersNew = (currentParameter, index, changes) => {
        const jobParameters = {
            ...this.props.job.parameters,
            parameters: Object.assign([], this.props.job.parameters.parameters, {
                [index]: JSON.stringify(removeNullKeys({ ...currentParameter, ...changes })),
            }),
        }
        this.props.onChangeJob({ parameters: jobParameters })
    }

    onChangeFilters = (changes) => {
        const parameters = {
            ...this.props.job.parameters,
            filters: [JSON.stringify({
                ...this.getFilters(),
                ...changes,
            })],
        }
        this.props.onChangeJob({ parameters })
    }

    onChangeColumns = (parameter, index, id, column) => {
        const { data: actualData = [] } = parameter
        const newData = (() => {
            if (!hasValue(column)) {
                return actualData.filter(d => d.id !== id)
            }
            return uniqBy([{ id, column }, ...actualData], 'id')
        })()
        this.onChangeParametersNew(parameter, index, { data: newData })
    }

    findData = (parameter, id) => {
        const found = (parameter.data || []).find(d => d.id === id)
        return found ? found.column : null
    }

    getFilters = () => {
        const newFilters = this.props.job.parameters.filters.length ? JSON.parse(this.props.job.parameters.filters[0]) : {}
        return { measureCote: 2, unwantedColumns: ['conditionColumn', 'requiredValue'], ...newFilters }
    }

    getParametersNew = () => this.props.job.parameters.parameters.length ? this.props.job.parameters.parameters.map(p => JSON.parse(p)) : []

    getDataTypes = (filters) => execByType(filters.dataCategory, {
        pluviometry: () => this.props.pluviometryDataTypes,
        piezometry: () => [ ...getHardPiezoDataTypes(), ...this.props.piezometryDataTypes ],
        hydrometry: () => [ ...getHardHydroDataTypes(), ...this.props.hydrometryDataTypes ],
        default: () => [],
    })

    onDeleteParameter = (index) => {
        if (this.props.isEditMode) {
            const jobParameters = {
                ...this.props.job.parameters,
                parameters: this.props.job.parameters.parameters.filter((_, i) => i !== index),
            }
            this.props.onChangeJob({ parameters: jobParameters })
        }
    }

    onDuplicateParameter = (index) => {
        if (this.props.isEditMode) {
            const jobParameters = {
                ...this.props.job.parameters,
                parameters: [ ...this.props.job.parameters.parameters, this.props.job.parameters.parameters[index] ],
            }
            this.props.onChangeJob({ parameters: jobParameters })
        }
    }

    getParametersData = (filters, parameters) => {
        const disabled = { disabled: !this.props.isEditMode }
        return parameters.map((p, index) => {
            const columnFields = this.getDataTypes(filters).reduce((acc, val) => ({
                ...acc,
                [`data${val.id}`]: <NumberField value={ this.findData(p, val.id) } onChange={ v => this.onChangeColumns(p, index, val.id, v) } {...disabled }/>,
            }), {})
            return {
                nullValue: <Icon icon='delete' tooltip={ i18n.delete } onClick={ () => this.onDeleteParameter(index) } />,
                nullValue2: <Icon icon='add_to_photos' tooltip={ i18n.duplicate } onClick={ () => this.onDuplicateParameter(index) } />,
                directory: <Input value={ p.directory } onChange={ v => this.onChangeParametersNew(p, index, { directory: v }) } {...disabled }/>,
                filter: <Input value={ p.filter } onChange={ v => this.onChangeParametersNew(p, index, { filter: v }) } {...disabled }/>,
                coeff: <NumberField value={ p.factor } onChange={ v => this.onChangeParametersNew(p, index, { factor: v }) } floatValue {...disabled }/>,
                offset: <NumberField value={ p.offset } onChange={ v => this.onChangeParametersNew(p, index, { offset: v }) } floatValue {...disabled }/>,
                date: <NumberField value={ p.dateColumn } onChange={ v => this.onChangeParametersNew(p, index, { dateColumn: v }) } {...disabled }/>,
                hour: <NumberField value={ p.hourColumn } onChange={ v => this.onChangeParametersNew(p, index, { hourColumn: v }) } {...disabled }/>,
                station: (<Select options={ getStations(this.props, filters.dataCategory).map(s => ({ ...s, name: s.code ? `[${s.code}] ${s.name}` : s.name })) }
                    onChange={ (_, v) => v && v.id ? this.onChangeParametersNew(p, index, { stationCode: v.code, stationId: v.id }) : null }
                    value={ p.stationId } keyValue='id' {...disabled }
                />),
                excelTab: <Input value={ p.sheet } onChange={ v => this.onChangeParametersNew(p, index, { sheet: v }) } {...disabled }/>,
                rename: <Checkbox checked={ p.renameFile } onChange={ v => this.onChangeParametersNew(p, index, { renameFile: v }) } {...disabled }/>,
                nbParam: <NumberField value={ p.nbParam } onChange={ v => this.onChangeParametersNew(p, index, { nbParam: v }) } {...disabled }/>,
                conditionColumn: <NumberField value={ p.conditionColumn } onChange={ v => this.onChangeParametersNew(p, index, { conditionColumn: v }) } {...disabled }/>,
                requiredValue: <Input value={ p.requiredValue } onChange={ v => this.onChangeParametersNew(p, index, { requiredValue: v }) } {...disabled }/>,
                ...columnFields,
            }
        })
    }

    addParameterNew = () => {
        if (this.props.isEditMode) {
            const jobParameters = {
                ...this.props.job.parameters,
                parameters: [ ...this.props.job.parameters.parameters, '{}'],
            }
            this.props.onChangeJob({ parameters: jobParameters })
        }
    }

    setAdvancedFtpModal =() => {
        const filters = this.getFilters()
        setModal({
            title: i18n.importParam,
            content: <ImportFileModal url={ filters.path } />,
        })
    }

    setAdvancedModal = (filters) => {
        setModal({
            title: i18n.settingsToDisplay,
            actions: (
                <div>
                    <a className='waves-effect waves-teal btn-flat modal-close'>{ i18n.close }</a>
                </div>
            ),
            content: <CSVGenericAdvancedModal unwantedColumns={ filters.unwantedColumns } onChange={ v => this.onChangeFilters({ unwantedColumns: v }) } dataTypes={ this.getDataTypes(filters) } />,
        })
    }

    filtreDatatype = (data, value) => data.filter(d => d.id == value ? d.channels : '')
        .map(v => v.channels)

    exportData = parameters => parameters.map(p => {
        const param = JSON.parse(p)
        const columnFields = this.getDataTypes(this.getFilters()).reduce((acc, val) => ({
            ...acc,
            [val.label]: param.data ? this.filtreDatatype(param.data, val.id) : '',
        }), {})
        const line = {
            directory: param.directory,
            filter: param.filter,
            station: param.stationCode,
            excelTab: param.sheet,
            conditionColumn: param.conditionColumn,
            requiredValue: param.requiredValue,
            coeff: param.factor,
            offset: param.offset,
            date: param.dateColumn,
            rename: param.renameFile ? `${i18n.yes}` : `${i18n.no}`,
            nbParam: param.nbParam,
            hour: param.hourColumn,
            ...columnFields,
            headers: ['directory', 'filter', 'station', 'excelTab', 'conditionColumn', 'requiredValue', 'coeff', 'offset', 'date', 'rename', 'nbParam', 'hour', ...Object.keys(columnFields)],
        }
        return line
    })

    render() {
        if (this.state.dataLoaded) {
            const disabled = !this.props.isEditMode
            const filters = this.getFilters()
            const measureOptions = [
                {
                    value: 'DATA_ADD_NEW_DATA',
                    label: i18n.addNewDataIntegration,
                },
                {
                    value: 'DATA_ADD_LAST_DATA',
                    label: i18n.lastDataIntegration,
                },
                {
                    value: 'DATA_REPLACE_HISTO',
                    label: i18n.replaceDataIntegration,
                },
            ]
            const parameters = this.getParametersData(filters, this.getParametersNew())
            const dataTypes = [
                { value: 'pluviometry', label: i18n.pluviometry },
                { value: 'piezometry', label: i18n.piezometry },
                { value: 'hydrometry', label: i18n.hydrometry },
            ]
            const baseHeaders = ['nullValue', 'nullValue2', 'directory', 'filter', 'station', 'excelTab', 'conditionColumn', 'requiredValue', 'coeff', 'offset', 'date', 'rename', 'nbParam']
            const hourColumn = filters.hourWithDate ? [] : ['hour']
            const columnHeaders = this.getDataTypes(filters).map(type => `data${type.id}`)
            const customHeaders = this.getDataTypes(filters).reduce((acc, val) => ({ ...acc, [`data${val.id}`]: val.label }), {})
            const data = this.exportData(this.props.job.parameters.parameters)
            const addDataAction = [
                {
                    onClick: this.addParameterNew,
                    iconName: 'add_box',
                    tooltip: `${i18n.add}`,
                }, {
                    onClick: () => this.setAdvancedModal(filters),
                    iconName: 'build',
                    tooltip: `${i18n.advanced}`,
                },
            ]
            return (
                <div>
                    <div className='row no-margin valign-wrapper padding-top-1'>
                        <Checkbox col={ 3 } label={ 'Renommer les fichiers en .DONE' } onChange={ v => this.onChangeFilters({ renameProcessedFiles: v }) } disabled={ disabled } checked={ filters.renameProcessedFiles } />
                        <Checkbox col={ 4 } label={ 'Ne pas traiter les fichiers présents dans le journal' } onChange={ v => this.onChangeFilters({ dontProcessFilesInLogs: v }) } disabled={ disabled } checked={ filters.dontProcessFilesInLogs } />
                        <Checkbox col={ 3 } label={ i18n.hasHeader } onChange={ v => this.onChangeFilters({ hasHeader: v }) } disabled={ disabled } checked={ filters.hasHeader } />
                        <NumberField col={ 2 } title={ 'Nb lignes entête' } value={ filters.nbHeaders } onChange={ v => this.onChangeFilters({ nbHeaders: v }) } disabled={ disabled } floatValue />
                    </div>
                    <Select value={filters.importMode} options={measureOptions} label={ i18n.dataIntegration }
                        labelSpan={ integrationModeHelpIcon() }
                        col={3} onChange={ v => this.onChangeFilters({ importMode: v }) } {...disabled}
                    />
                    <div className='row no-margin valign-wrapper'>
                        <Input col={ 4 } title={ i18n.directory } value={ this.props.job.parameters.path } onChange={ v => this.onChangeJobParameter({ path: v }) } disabled={ disabled } />
                        <a className='btn btn-small secondary-color-back' onClick={ () => this.setAdvancedFtpModal()} ><span title={ i18n.importLabelFtp }> <i className='material-icons'>input </i> </span></a>
                    </div>
                    <div className='row no-margin'>
                        <Select col={ 4 } label={ i18n.dataType } options={ dataTypes } value={ filters.dataCategory } onChange={ v => this.onChangeFilters({ dataCategory: v }) } disabled={ disabled }/>
                        <RadioButtons col={ 3 } elements={ getMeasureCoteList() } selected={ filters.measureCote } onChange={ v => this.onChangeFilters({ measureCote: v }) } title={ i18n.ratingExpression } disabled={ disabled }/>
                    </div>
                    <div className='row no-margin valign-wrapper'>
                        <div className='padding-left-2' />
                        <h6>{ i18n.indexStartsAt0 }</h6>
                    </div>
                    <div className='job-parameter-table'>
                        <Table
                            data={ parameters }
                            nbPerPageLabel={ nbPerPageLabelShort }
                            type={ { headers: xor([ ...baseHeaders, ...hourColumn, ...columnHeaders ], filters.unwantedColumns) } }
                            sortable
                            condensed
                            paging
                            customHeaders={ customHeaders }
                            activeHeader
                            exportButtonOnHeader
                            exportData={ data }
                            actions={ addDataAction }
                        />
                    </div>

                </div>
            )
        }
        return <ProgressCard progress={ this.state.progress }/>
    }
}

VeoliaPanel.propTypes = {
    job: PropTypes.instanceOf(Job).isRequired,
    onChangeJob: PropTypes.func.isRequired,
    piezometryDataTypes: PropTypes.arrayOf(PropTypes.instanceOf(DtoParametrageDataType)),
    piezometers: PropTypes.arrayOf(PropTypes.instanceOf(DtoPiezometerLight)),
    pluviometers: PropTypes.arrayOf(PropTypes.instanceOf(PluviometerDto)),
    pluviometryDataTypes: PropTypes.arrayOf(PropTypes.instanceOf(DtoParametrageDataType)),
    hydrometricStations: PropTypes.arrayOf(PropTypes.instanceOf(DtoHydrometricStation)),
    hydrometryDataTypes: PropTypes.arrayOf(PropTypes.instanceOf(DtoParametrageDataType)),
    isEditMode: PropTypes.bool,
    loadCSVImportPanel: PropTypes.func,
}

const mapStateToProps = store => ({
    piezometryDataTypes: store.PiezometryReducer.piezometryDataTypes,
    piezometers: store.PiezometryReducer.piezometersLight,
    pluviometers: store.PluviometryReducer.pluviometers,
    pluviometryDataTypes: store.PluviometryReducer.pluviometryDataTypes,
    hydrometricStations: store.HydrometryReducer.hydrometricStations,
    hydrometryDataTypes: store.HydrometryReducer.hydrometryDataTypes,
})

const mapDispatchToProps = {
    loadCSVImportPanel: JobAction.loadCSVImportPanel,
}
export default connect(mapStateToProps, mapDispatchToProps)(VeoliaPanel)