import React from 'react'
import i18n from 'simple-react-i18n'
import { Grid } from '@mui/material'
import PropTypes from 'prop-types'
import { PLUVIO_IMPORT_TYPE } from 'pluviometry/constants/PluviometryConstant'
import AddPluvioStepOptionsMF from './AddPluvioStepOptionsMF'
import AddStepOptionsEmpty from 'station/components/addSteps/AddStepOptionsEmpty'
import InDevelopmentBackground from 'inDevelop/components/InDevelopmentBackground'


const AddPluvioStepOptions = ({
    station,
    ...props
}) => {
    const getOptionsPanel = () => {
        switch (station.stationType) {
            case PLUVIO_IMPORT_TYPE.METEO_FRANCE:
                return <AddPluvioStepOptionsMF {...props} station={station} />
            case PLUVIO_IMPORT_TYPE.EMPTY:
                return <AddStepOptionsEmpty {...props} station={station} stationType='pluviometry'/>
            default:
                return <InDevelopmentBackground />
        }
    }
    return (
        <div className='padding-top-2'>
            <Grid container justifyContent='center' alignItems='center' spacing={3}>
                <h4>{i18n.newStationStep2}</h4>
            </Grid>
            { getOptionsPanel() }
        </div>
    )
}

AddPluvioStepOptions.propTypes = {
    station: PropTypes.shape({
        code: PropTypes.string,
        stationType: PropTypes.string,
        name: PropTypes.string,
        jobExecutionId: PropTypes.number,
        job: PropTypes.shape({
            id: PropTypes.number,
        }),
        jobExecution: PropTypes.shape({
            statusCode: PropTypes.number,
        }),
        ids: PropTypes.arrayOf(PropTypes.number),
        nature: PropTypes.string,
    }),
}

export default AddPluvioStepOptions