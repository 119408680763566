import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import AppStore from 'store/AppStore'
import HomeAction from '../../home/actions/HomeAction'
import { getMapStateToProps, getPropTypes } from '../../utils/StoreUtils'
import IAction from './IAction'

const $ = window.$

const propsToFetch = {
    notifications: false,
}

class Notif extends IAction {
    constructor(props) {
        super(props)
        const notifs = this.countNotifs(this.props)
        this.state = { notifs }
    }

    componentDidMount() {
        $('.dropdown-button').dropdown()
    }

    componentWillReceiveProps(nextProps) {
        const notifs = this.countNotifs(nextProps)
        this.setState({ notifs })
    }

    countNotifs(props) {
        return props.notifications[Object.keys(props.notifications).find(k => k === props.name)]
    }

    fn() {
        return this.state.notifs > 0 ? () => {
            AppStore.dispatch(HomeAction.readNotification(this.props.name))
            this.props.onClick()
        } : this.props.onClick
    }

    icon() {
        return this.props.icon
    }

    label() {
        return this.props.label
    }

    notifs() {
        return this.state.notifs > 0 ? (<span className='new badge' style={this.getColor()}>{ this.state.notifs }</span>) : ''
    }
    id = () => {
        return 'notif_action_navbar'
    }

    content() {
        return (
            <div className='dropdown-content row no-margin' id={this.name()}>
                <div className={ `col s12 no-padding title ${this.props.onClickTitle ? 'clickable' : ''}` } onClick={ this.props.onClickTitle }>
                    <h6 className='center-align'>
                        <b>{this.label()}</b>
                    </h6>
                    <p className='no-margin no-padding divider'/>
                </div>
                <div className='col s12 no-padding'>
                    {this.props.content}
                </div>
            </div>
        )
    }

    name() {
        return this.props.name
    }

    getColor() {
        return this.props.color ? { backgroundColor: this.props.color } : {}
    }
}

Notif.propTypes = getPropTypes(propsToFetch, {
    onClick: PropTypes.func,
    icon: PropTypes.string.isRequired,
    label: PropTypes.string,
    name: PropTypes.string,
    color: PropTypes.string,
    content: PropTypes.element,
    onClickTitle: PropTypes.func,
})

Notif.defaultProps = {
    onClick: () => {},
    icon: 'ICON Not specified',
    label: '',
    name: '',
    color: '',
    content: '',
}

const mapStateToProps = () => getMapStateToProps(propsToFetch)

export default connect(mapStateToProps)(Notif)
