import DtoLocation from '../../../../station/dto/DtoLocation'
import ReferencialDto from '../../../dto/ReferencialDto'

export default class ContributorItem extends ReferencialDto {
    constructor(object) {
        super(object)

        this.id = object.id
        this.code = object.id // Int,
        this.name = object.name // Option[String] = None,
        this.siret = object.siret // Option[String] = None,
        this.mnemonique = object.mnemonique // Option[String] = None,
        this.sandre = object.sandre // Option[String] = None,
        this.domain = object.domain // Option[Int] = None,
        this.postalCode = object.postalCode // Option[String] = None,
        this.city = object.city // Option[String] = None,
        this.cityCode = object.cityCode // Option[String] = None,
        this.email = object.email // Option[String] = None,
        this.phoneTel = object.phoneTel // Option[String] = None,
        this.status = object.status // Option[String] = None,
        this.noNewsLetterDate = object.noNewsLetterDate // Option[DateTime] = None,
        this.noNewsLetterLogin = object.noNewsLetterLogin // Option[String] = None,

        this.labelDisplay = object.mnemonique || object.name

        this.creationDate = object.creationDate // Option[DateTime] = None,
        this.updateDate = object.updateDate // Option[DateTime] = None,
        this.auteur = object.auteur // Option[String] = None,
        this.postalBox = object.postalBox // Option[String] = None,
        this.addressComplement = object.addressComplement // Option[String] = None,
        this.road = object.road // Option[String] = None,
        this.statePlace = object.statePlace // Option[String] = None,
        this.department = object.department // Option[String] = None,
        this.comments = object.comments // Option[String] = None,
        this.phoneTelSecond = object.phoneTelSecond // Option[String] = None,
        this.fax = object.fax // Option[String] = None,
        this.mobile = object.mobile // Option[String] = None,
        this.cedexCode = object.cedexCode // Option[Double] = None,
        this.otherReference = object.otherReference // Option[String] = None,
        this.activityStartDate = object.activityStartDate // Option[DateTime] = None,
        this.activityEndDate = object.activityEndDate // Option[DateTime] = None,
        this.legalRepresentative = object.legalRepresentative // Option[Long] = None,
        this.internalIdentifier = object.internalIdentifier // Option[String] = None,
        this.cedex = object.cedex // Option[String] = None,
        this.structureType = object.structureType // Option[Long] = None,
        this.jobExecutionId = object.jobExecutionId // Option[Long] = None,
        this.loginmaj = object.loginmaj // Option[String] = None,
        this.datemaj = object.datemaj // Option[DateTime] = None,
        this.countryCode = object.countryCode // Option[String] = None
        this.headers = ['siret', 'name', 'mnemonique', 'postcode', 'city', 'phoneTel', 'domain', 'sandre', 'status']
        // carto
        this.x = object.x // Option[Double] = None
        this.y = object.y // Option[Double] = None
        this.projection = object.projection // Option[Double] = None
        this.cityCodeDelegate = object.cityCodeDelegate // Option[String] = None,
        this.postalCodeDelegate = object.postalCodeDelegate // Option[String] = None
        this.townCode = object.cityCode
        this.localisation = new DtoLocation(object)
        this.typeName = 'contributor'
        this.contributorCode = object.contributorCode
    }
}