export default class DtoSurveyWithStats {
    constructor(obj) {
        this.idSurvey = obj.idSurvey // Long,
        this.year = obj.year // Option[Int],
        this.statusCode = obj.statusCode // Option[Int],
        this.startDate = obj.startDate // Option[DateTime],
        this.comment = obj.comment // Option[String],
        this.endDate = obj.endDate // Option[DateTime],
        this.updateDate = obj.updateDate // Option[DateTime],
        this.updateLogin = obj.updateLogin // Option[String],
        this.cmsId = obj.cmsId // Option[Int],
        this.nbDeclarationsValidated = obj.nbDeclarationsValidated // Option[Int],
        this.nbDeclarations = obj.nbDeclarations // Option[Int]
        this.name = obj.name // Option[String],
        this.contributorId = obj.contributorId // Option[Long],
        this.ddtLimitDate = obj.ddtLimitDate // Option[DateTime],
        this.agencyLimitDate = obj.agencyLimitDate // Option[DateTime],
        this.exportNotifications = obj.exportNotifications // Option[Boolean],
        this.ddtLastExportDate = obj.ddtLastExportDate // Option[DateTime],
        this.agencyLastExportDate = obj.agencyLastExportDate // Option[DateTime]
        this.requestedVolume = obj.requestedVolume // Option[Double],
        this.allocatedVolume = obj.allocatedVolume // Option[Double],
        this.allowedVolume = obj.allowedVolume // Option[Double]
        this.surveyType = obj.surveyType // Option[Int]

        this.nameWithYear = `[${obj.year}] ${obj.name}`
    }
}