import User from 'account/dto/User'
import ConfirmModal from 'components/modal/ConfirmModal'
import { omit } from 'lodash'
import PropTypes from 'prop-types'
import React, { useMemo, useState } from 'react'
import { connect, shallowEqual, useSelector } from 'react-redux'
import MultiContributorsAutocomplete from 'referencial/components/contributor/components/MultiContributorsAutocomplete'
import i18n from 'simple-react-i18n'
import { authorizeContributors, authorizeFilter } from 'utils/HabilitationUtil'
import { removeNullKeys } from 'utils/StoreUtils'
import Input from '../../../../components/forms/Input'
import Select from '../../../../components/forms/Select'
import Textarea from '../../../../components/forms/Textarea'
import ContributorDto from '../../../../referencial/components/contributor/dto/ContributorDto'
import NetworkDto from '../../../../referencial/components/network/dto/NetworkDto'
import { getMatTypes } from '../../../../utils/MaterielUtils'
import DtoSimType from '../dto/DtoSimType'
import SimDto from '../dto/SimDto'
import { isFieldRequired } from 'materiel/utils/MaterielUtils'
import { SIM_ID } from 'materiel/constants/MaterielConstants'
import { DATEACHAT_OBLIGATOIRE, NUMSERIE_OBLIGATOIRE } from 'materiel/constants/MaterielSettingRuleConstant'
import SimpleDatePicker from 'components/forms/SimpleDatePicker'

const SimPanel = ({
    active = false,
    disabled = true,
    sim = {},
    simTypes = [],
    contributors = [],
    accountUser = {},
    networks = [],
    onChange = () => { },
}) => {
    const {
        materielSettingRules,
    } = useSelector(store => ({
        materielSettingRules: store.MaterielReducer.materielSettingRules,
    }), shallowEqual)

    const [isOpen, setOpen] = useState(false)
    const [selectedType, setSelectedType] = useState()

    const rules = useMemo(() => {
        return materielSettingRules.filter(({ idMaterielType }) => idMaterielType === SIM_ID)
    }, [materielSettingRules])

    return (
        <div className='col s12 margin-bottom-1'>
            <div className='row no-margin' style={{ paddingTop: '3px' }}>
                <Select
                    options={getMatTypes(simTypes, sim.simType)}
                    label={i18n.type}
                    col={4}
                    onChange={v => {
                        const type = simTypes.find(({ id }) => v === id)
                        if (type) {
                            setOpen(true)
                            setSelectedType(type)
                        } else {
                            onChange({ simType: v })
                        }
                    }}
                    value={sim.simType}
                    nullLabel='&nbsp;'
                    active={active}
                    disabled={disabled}
                    obligatory
                />
                <Input
                    col={4}
                    title={i18n.serialNumberIccid}
                    value={sim.serialNumber}
                    onChange={value => onChange({ serialNumber: value })}
                    active={active}
                    disabled={sim.isEquipment || disabled}
                    obligatory={isFieldRequired(rules, NUMSERIE_OBLIGATOIRE)}
                />
            </div>
            <div className='row no-margin' style={{ paddingTop: '3px' }}>
                <MultiContributorsAutocomplete
                    options={authorizeContributors(contributors, accountUser, sim.providerId)}
                    label={i18n.provider}
                    col={6}
                    onChange={(id) => onChange({ providerId: id })}
                    values={sim.providerId}
                    disabled={sim.isEquipment || disabled}
                    keyLabel='labelDisplay'
                />
            </div>
            <div className='row no-margin' style={{ paddingTop: '3px' }}>
                <Select
                    options={authorizeFilter(networks, accountUser, sim.networkCode)}
                    label={i18n.network}
                    col={12}
                    onChange={value => onChange({ networkCode: value })}
                    value={sim.networkCode}
                    nullLabel='&nbsp;'
                    active={active}
                    disabled={sim.isEquipment || disabled}
                />
            </div>
            <div className='row no-margin' style={{ paddingTop: '3px' }}>
                <SimpleDatePicker
                    id='purchaseDate'
                    label={i18n.purchaseDate}
                    value={sim.purchaseDate}
                    onChange={v => onChange({ purchaseDate: v })}
                    col={3}
                    disabled={disabled}
                    max={sim.openDate}
                    obligatory={isFieldRequired(rules, DATEACHAT_OBLIGATOIRE)}
                />
                <SimpleDatePicker
                    id='enableDate'
                    label={i18n.activationDate}
                    value={sim.enableDate}
                    onChange={v => onChange({ enableDate: v })}
                    col={3}
                    disabled={disabled}
                    min={sim.purchaseDate}
                    max={sim.disableDate}
                />
                <SimpleDatePicker
                    id='disableDate'
                    label={i18n.closingLine}
                    value={sim.disableDate}
                    onChange={v => onChange({ disableDate: v })}
                    col={3}
                    disabled={disabled}
                    min={sim.enableDate}
                />
                {
                    sim.disableDate && (
                        <Input
                            col={3}
                            title={i18n.closeCause}
                            value={sim.explanationDisable}
                            onChange={value => onChange({ explanationDisable: value })}
                            active={active}
                            disabled={disabled}
                        />
                    )
                }
            </div>
            <div className='row no-margin' style={{ paddingTop: '8px' }}>
                <Textarea
                    col={12}
                    title={i18n.comment}
                    value={sim.comment}
                    onChange={value => onChange({ comment: value })}
                    active={active}
                    disabled={disabled}
                />
            </div>
            <ConfirmModal
                isOpen={isOpen}
                title={i18n.materielDefaultValuesMessage}
                onValidate={() => {
                    setOpen(false)
                    onChange(removeNullKeys({
                        simType: selectedType.id,
                        ...omit(selectedType, ['id', 'code', 'label', 'name', 'comment', 'updateDate', 'updateLogin', 'startDate', 'endDate', 'icon']),
                    }))
                }}
                onReject={() => {
                    setOpen(false)
                    onChange({ simType: selectedType.id })
                }}
                onClose={() => setOpen(false)}
            />
        </div>
    )
}

SimPanel.propTypes = {
    disabled: PropTypes.bool,
    active: PropTypes.bool,
    onChange: PropTypes.func,
    sim: PropTypes.instanceOf(SimDto),
    simTypes: PropTypes.arrayOf(PropTypes.instanceOf(DtoSimType)),
    contributors: PropTypes.arrayOf(PropTypes.instanceOf(ContributorDto)),
    networks: PropTypes.arrayOf(PropTypes.instanceOf(NetworkDto)),
    accountUser: PropTypes.instanceOf(User),
}

const mapStateToProps = store => ({
    contributors: store.ContributorReducer.contributors,
    simTypes: store.SimReducer.simTypes,
    networks: store.NetworkReducer.networks,
    accountUser: store.AccountReducer.accountUser,
})

export default connect(mapStateToProps)(SimPanel)
