module.exports = {
    RECEIVE_QUALITOMETER_CAMPAIGN_ALL: 'RECEIVE_QUALITOMETER_CAMPAIGN_ALL',
    RECEIVE_ALL_CAMPAIGN: 'RECEIVE_ALL_CAMPAIGN',

    STEP: {
        INFORMATIONS: 1,
        DIAGNOSTIC: 2,
        DANGERS: 3,
        MODIFICATIONS: 4,
        ACTIONS: 5,
        CONCLUSION: 6,
    },

    SORT_BY: {
        STATUS: 0,
        DATE: 1,
        REFERENT: 2,
        TYPE: 3,
        ACTIONS: 4,
    },

    ACTION_COLOR: {
        0: '#365F80',
        1: '#41749B',
        2: '#4F88B5',
        3: '#6B9BC1',
        4: '#86ADCC',
        5: '#a2c0d8',
    },
}
