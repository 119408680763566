import { isEqual } from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import i18n from 'simple-react-i18n'

class Links extends Component {
    state={
        links: [],
    }

    componentDidMount() {
        $('.dropdown-button').dropdown()

        if (this.props.links.then) {
            this.props.links.then(links => {
                this.setState({ links })
            })
        } else if (this.props.links.length) {
            this.setState({ links: this.props.links })
        }
    }
    componentDidUpdate() {
        $('.dropdown-button').dropdown()
        // links is a Promise
        if (this.props.links.then) {
            this.props.links.then(links => {
                if (!isEqual(links, this.state.links)) {
                    this.setState({ links })
                }
            })
        } else if (this.props.links.length) {
            if (!isEqual(this.props.links, this.state.links)) {
                this.setState({ links: this.props.links })
            }
        }
    }

    render() {
        const { links } = this.state
        if (links?.length) {
            const allLinks = links.map((link, idx) => (
                <li key={idx}>
                    <a
                        href={link.disable ? null : link.href}
                        target='_blank'
                        className='no-padding'
                        style={{
                            margin: '3px',
                            backgroundColor: link.disable? 'lightgray' : '',
                            cursor: link.disable ? 'default' : 'pointer',
                        }}
                    >
                        <div className='row no-margin valign-wrapper dropdownLink collection-item'>
                            {
                                link.img ? (
                                    <div className='col s12 no-padding valign-wrapper'>
                                        <img className='w-h-30' src={link.img} style={{ marginRight: '5px' }}/>
                                        <b>{link.label || ''}</b>
                                    </div>
                                ) : (
                                    <div className='col s12'>
                                        <b>{link.label ? link.label : ''}</b>
                                    </div>
                                )
                            }
                        </div>
                    </a>
                </li>
            ))
            return (
                <div className='action-wrapper'>
                    <ul id='dropdownLinks' className='dropdown-content dropdownLinks'>
                        { allLinks }
                    </ul>
                    <a className='dropdown-button anim-hover nav-actions' data-activates='dropdownLinks'>{ i18n.links }</a>
                </div>
            )
        }
        return null
    }
}

Links.propTypes = {
    // getLinks return a Promise with this result
    links: PropTypes.arrayOf(PropTypes.shape({
        href: PropTypes.string,
        img: PropTypes.any,
        label: PropTypes.string,
    })).isRequired,
}

export default Links
