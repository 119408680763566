import { Grid } from '@mui/material'
import { push } from '@lagunovsky/redux-react-router'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { H_CAMPAIGN_INSTALLATION_DASHBOARD } from '../../../account/constants/AccessRulesConstants'
import CampaignAction from '../../../campaign/actions/CampaignAction'
import DashboardCampaign from '../../../campaign/components/campaignDashboard/DashboardCampaign'
import DtoCampaignVisit from '../../../campaign/dto/DtoCampaignVisit'
import ProgressCard from '../../../components/card/ProgressCard'
import { componentHasHabilitations } from '../../../utils/HabilitationUtil'
import DtoInstallationLight from '../../dto/installation/DtoInstallationLight'


class InstallationCampaignDashboard extends Component {
    state = {
        dataLoaded: false,
        progress: 0,
    }

    componentDidMount = () => {
        if (!componentHasHabilitations(H_CAMPAIGN_INSTALLATION_DASHBOARD)) { // A modifier quand react-router sera à jour
            this.props.push('/unauthorized')
            return
        }
        const {
            id,
        } = this.props.params
        this.props.loadCampaignDashboard(p => this.setState({ progress: p }))
            .then(() => this.setState({ dataLoaded: true }))

        if (id !== 'new') {
            this.props.fetchCampaignVisits(id)
        }
    }

    componentDidUpdate = prevProps => {
        const {
            id,
        } = this.props.params
        if (id !== prevProps.params.id && id !== 'new' && id !== 'duplicate') {
            this.props.fetchCampaign('installation', id)
            this.props.fetchCampaignStation('installation', id)
            this.props.fetchCampaignProgress('installation', id)
        }
    }

    render = () => {
        const {
            dataLoaded,
            progress,
        } = this.state
        if (dataLoaded) {
            const {
                installationsLight,
                campaignVisits,
            } = this.props

            const installations = campaignVisits.length ?
                installationsLight.map(i => {
                    const visit = campaignVisits.find(cv => cv.idInstallation === i.id)
                    return {
                        ...i,
                        ...visit,
                    }
                }) : installationsLight
            return (
                <Grid className='padding-left-1'>
                    <DashboardCampaign
                        params={this.props.params}
                        stations={installations}
                        stationType={'installation'}
                    />
                </Grid>
            )
        }
        return <ProgressCard className='padding-1 padding-left-2' progress={progress} />
    }
}

InstallationCampaignDashboard.propTypes = {
    params: PropTypes.shape({
        id: PropTypes.string,
    }),

    installationsLight: PropTypes.arrayOf(PropTypes.instanceOf(DtoInstallationLight)),
    campaignVisits: PropTypes.arrayOf(PropTypes.instanceOf(DtoCampaignVisit)),

    loadCampaignDashboard: PropTypes.func,
    fetchCampaign: PropTypes.func,
    fetchCampaignStation: PropTypes.func,
    fetchCampaignProgress: PropTypes.func,
    fetchCampaignVisits: PropTypes.func,
    push: PropTypes.func,
}

const mapStateToProps = store => ({
    installationsLight: store.InstallationReducer.installationsLight,
    campaignVisits: store.CampaignReducer.campaignVisits,
})

const mapDispatchToProps = {
    loadCampaignDashboard: CampaignAction.loadCampaignDashboard,
    fetchCampaign: CampaignAction.fetchCampaign,
    fetchCampaignStation: CampaignAction.fetchCampaignStation,
    fetchCampaignProgress: CampaignAction.fetchCampaignProgress,
    fetchCampaignVisits: CampaignAction.fetchCampaignVisits,
    push,
}

export default connect(mapStateToProps, mapDispatchToProps)(InstallationCampaignDashboard)
