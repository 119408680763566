import DtoMaterielAssignment from './DtoMaterielAssignment'

export default class DtoStationPowerSupplyAssignment extends DtoMaterielAssignment {
    constructor(obj) {
        super(obj)
        this.id = obj.idPowerSupplySituation
        this.idPowerSupplySituation = obj.idPowerSupplySituation
        this.idPowerSupply = obj.idPowerSupply
        this.materielId = obj.idPowerSupply
    }
}
