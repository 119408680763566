import { orderBy } from 'lodash'
import moment from 'moment'
import { hasValue } from '../../utils/NumberUtil'
import { searchAllCharacters } from '../../utils/StringUtil'

const getSortedTableData = (data, usedSort, colorMode) => {
    const {
        column,
        sort,
    } = usedSort
    if (column && data.length) {
        const dateRegex = new RegExp('\\d\\d\\/\\d\\d\\/\\d\\d\\d\\d')
        const dateAndHourRegex = new RegExp('\\d\\d/\\d\\d/\\d\\d\\d\\d \\d\\d:\\d\\d:\\d\\d')
        const percentageRegex = new RegExp('^[0-9]+%$')
        const percentageBisRegex = new RegExp('^[0-9 ]+%$')
        const volumeRegex = new RegExp('[0-9 ]+m3')
        const numberOnlyStringRegex = new RegExp('^([0-9]|\\s)+$')
        const lastValue = sort === 'asc' ? Number.POSITIVE_INFINITY : Number.NEGATIVE_INFINITY
        const dataWithValue = data.filter(d => colorMode ? d[column] && hasValue(d[column].value) : hasValue(d[column]))
        const dataWithoutValue = data.filter(d => colorMode ? d[column] && !hasValue(d[column].value) || !d[column] : !hasValue(d[column]))

        const sortFunction = obj => {
            const value = colorMode ? obj[column] && (obj[column].sortValue !== undefined && obj[column].sortValue || obj[column].value) : obj[column]
            const type = typeof value
            switch (type) {
                case 'number': case 'boolean':
                    return value
                case 'string':
                    if (dateAndHourRegex.test(value)) {
                        return moment(value, 'DD/MM/YYYY hh:mm:ss').valueOf()
                    } else if (dateRegex.test(value)) {
                        return moment(value, 'DD/MM/YYYY').valueOf()
                    } else if (percentageRegex.test(value)) {
                        const percentage = value.slice(0, -1)
                        return parseInt(percentage)
                    } else if (percentageBisRegex.test(value)) {
                        const percentage = value.slice(0, -2)
                        return parseInt(percentage)
                    } else if (numberOnlyStringRegex.test(value)) {
                        return parseInt(value.trim())
                    } else if (volumeRegex.test(value)) {
                        return parseInt(value.slice(0, -2).replaceAll(' ', ''))
                    }
                    return searchAllCharacters(value)
                default:
                    return lastValue
            }
        }
        const sorted = orderBy(dataWithValue, sortFunction, sort)
        return [...sorted, ...dataWithoutValue]
    }
    return data
}

export { getSortedTableData }
