import React from 'react'
import ActionComponent from 'components/ActionComponent'
import { connect } from 'react-redux'
import AppStore from 'store/AppStore'
import ApplicationConf from 'conf/ApplicationConf'
import PropTypes from 'prop-types'
import SieauAction from '../../../../components/sieau/SieauAction'
import WatershedAction from '../actions/WatershedAction'
import i18n from 'simple-react-i18n'
import WatershedDto from '../dto/WatershedDto'
import moment from 'moment'
import 'moment/locale/fr'
import Select from '../../../../components/forms/Select'
import Input from '../../../../components/forms/Input'
import Textarea from '../../../../components/forms/Textarea'
import { getReferencialComponent } from '../../../util/ReferencialUtils'
import { updateMaterialize } from '../../../../utils/MaterializeUtils'
import { isEqual, omit, orderBy } from 'lodash'
import { v4 as uuidv4 } from 'uuid'
import ReplaceModal from '../../ReplaceModal'
import ToastrAction from 'toastr/actions/ToastrAction'
import {
    PATH_REFERENCIAL,
    PATH_REFERENCIAL_WATERSHED,
    PATH_REFERENCIAL_WATERSHED_ITEM_LINK,
    PATH_REFERENCIAL_WATERSHED_ITEM_NEW,
} from '../../../../home/constants/RouteConstants'
import { push } from '@lagunovsky/redux-react-router'
import DeleteModal from '../../DeleteModal'
import ReferencialAction from '../../../action/ReferencialAction'
import {
    sandreWatershedDefaultLink,
    sandreWatershedLevel1Link,
    sandreWatershedLevel2Link,
    sandreWatershedLevel3Link,
    sandreWatershedLevel4Link,
} from '../../../../conf/SieauConstants'
import { getUser } from '../../../../utils/SettingUtils'
import HomeAction from 'home/actions/HomeAction'
import NumberField from 'components/forms/NumberField'
import logoSandre from '../../../../assets/pictures/logo_sandre.png'
import { getSetting } from '../../../../utils/SettingUtils'
import { getDefaultCoordinates } from '../../../../utils/mapUtils/CoordinateUtils'
import SettingDto from '../../../../administration/dto/SettingDto'

moment.locale('fr')

class WatershedApp extends ActionComponent {
    constructor(props) {
        super(props)
        this.state = {
            watershed: new WatershedDto({}),
            isEditMode: false,
            mapConf: {
                center: [parseFloat(getSetting(this.props.settings, 'defaultMapXCoordinate')) || getDefaultCoordinates()[0],
                    parseFloat(getSetting(this.props.settings, 'defaultMapYCoordinate')) || getDefaultCoordinates()[1]],
                zoom: parseFloat(getSetting(this.props.settings, 'defaultMapZoom')) || 9,
            },
        }
    }

    changeStateAttribute = (attr, value) => {
        const watershed = Object.assign({}, this.state.watershed)
        watershed[attr] = value
        this.setState({ watershed })
    }

    changeEditMode = (bool) => {
        this.setState({ isEditMode: bool })
    }

    componentWillUnmount() {
        super.componentWillUnmount()
        AppStore.dispatch(WatershedAction.resetWatershed())
    }

    componentWillMount() {
        if (this.props.params.code !== 'new') {
            AppStore.dispatch(WatershedAction.fetchWatershed(this.props.params.code))
            this.changeEditMode(false)
        } else {
            this.changeEditMode(true)
        }
        AppStore.dispatch(HomeAction.setHelpLink('', ''))
        this.toNavbarWatershed(this.props)
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.params.code !== this.props.params.code) {
            AppStore.dispatch(WatershedAction.fetchWatershed(nextProps.params.code))
        } else if (nextProps.params.code === 'new') {
            AppStore.dispatch(SieauAction.forceFetch('title', [
                {
                    title: i18n.referencials,
                    href: PATH_REFERENCIAL,
                },
                {
                    title: i18n.watersheds,
                    href: PATH_REFERENCIAL_WATERSHED,
                },
                {
                    title: i18n.new,
                    href: PATH_REFERENCIAL_WATERSHED_ITEM_NEW,
                },
            ]))
        } else {
            if (!isEqual(nextProps.watershed, this.props.watershed)) {
                AppStore.dispatch(SieauAction.forceFetch('title', [
                    {
                        title: i18n.referencials,
                        href: PATH_REFERENCIAL,
                    },
                    {
                        title: i18n.watersheds,
                        href: PATH_REFERENCIAL_WATERSHED,
                    },
                    {
                        title: (nextProps.watershed.code ? nextProps.watershed.code : nextProps.watershed.id) + (nextProps.watershed.name ? ` - ${nextProps.watershed.name}` : ''),
                        href: PATH_REFERENCIAL_WATERSHED_ITEM_LINK + this.props.params.code,
                    },
                ]))
                this.setState({ watershed: nextProps.watershed })
            }
            if (!isEqual(this.props.isEditMode, nextProps.isEditMode) || !isEqual(this.props.watershed.id, nextProps.watershed.id) || !isEqual(this.props.watersheds, nextProps.watersheds)) {
                this.toNavbarWatershed(nextProps)
            }
        }
    }

    onReplace = newCode => {
        AppStore.dispatch(WatershedAction.replaceWatershed(this.state.watershed.id, newCode.id))
    }

    getRequiredFunction = (actions) => {
        AppStore.dispatch(actions)
        this.changeEditMode(false)
    }

    getWatershedLinks = (props) => {
        if (props.watershed.code && props.watershed.code.length) {
            switch (props.watershed.code.length) {
                case 1:
                    return [{
                        href: sandreWatershedLevel1Link + props.watershed.code,
                        img: logoSandre,
                        label: i18n.sandre.toUpperCase(),
                    }]
                case 2:
                    return [{
                        href: sandreWatershedLevel2Link + props.watershed.code,
                        img: logoSandre,
                        label: i18n.sandre.toUpperCase(),
                    }]
                case 3:
                    return [{
                        href: sandreWatershedLevel3Link + props.watershed.code,
                        img: logoSandre,
                        label: i18n.sandre.toUpperCase(),
                    }]
                case 4:
                    return [{
                        href: sandreWatershedLevel4Link + props.watershed.code,
                        img: logoSandre,
                        label: i18n.sandre.toUpperCase(),
                    }]
                default:
                    return [{
                        href: sandreWatershedDefaultLink,
                        img: logoSandre,
                        label: i18n.sandre.toUpperCase(),
                    }]
            }
        }
        return []
    }

    toNavbarWatershed(props = this.props) {
        const actions = (() => {
            if (props.params.code === 'new' && (!props.watershed || !props.watershed.id)) {
                return {
                    save: () => {
                        const existCode = this.props.watersheds.find(w => w.code === this.state.watershed.code)
                        if (existCode) {
                            AppStore.dispatch(ToastrAction.error(i18n.codeAlreadyExists))
                        } else {
                            this.getRequiredFunction(WatershedAction.createWatershed(this.state.watershed))
                        }
                    },
                    cancel: () => {
                        AppStore.dispatch(push(PATH_REFERENCIAL_WATERSHED))
                        this.changeEditMode(false)
                    },
                }
            }
            if (this.state.isEditMode) {
                return {
                    save: () => {
                        this.getRequiredFunction(WatershedAction.updateWatershed(this.state.watershed, this.state.watershed.id))
                    },
                    cancel: () => {
                        this.setState({ watershed: props.watershed })
                        this.changeEditMode(false)
                    },
                }
            }
            return {
                edit: () => {
                    this.changeEditMode(true)
                },
                deleteCheck: () => {
                    AppStore.dispatch(ReferencialAction.fetchCheckDelete(ApplicationConf.referencial.watershedCountDelete(this.props.params.code)))
                    const id = uuidv4()
                    const popup = {
                        id,
                        content: <DeleteModal
                            onDelete={ () => AppStore.dispatch(WatershedAction.deleteWatershed(this.state.watershed.id)) }
                        />,
                        dismissible: false,
                    }
                    AppStore.dispatch(SieauAction.setPopup(popup))
                },
                replace: () => {
                    AppStore.dispatch(ReferencialAction.fetchCheckDelete(ApplicationConf.referencial.watershedCountDelete(this.props.params.code)))
                    const id = uuidv4()
                    const popup = {
                        id,
                        content: <ReplaceModal
                            elements={ props.watersheds }
                            title={ `${i18n.watershed} [${this.state.watershed.id}]` }
                            label={ i18n.watershed }
                            onReplace={ this.onReplace }
                        />,
                        dismissible: false,
                    }
                    AppStore.dispatch(SieauAction.setPopup(popup))
                },
                links: this.getWatershedLinks(props),
            }
        })()
        if (getUser().consultant === '1') {
            this.setActions(omit(actions, ['save', 'edit', 'deleteCheck', 'replace']))
        } else {
            this.setActions(actions)
        }
        updateMaterialize()
    }

    componentDidMount() {
        this.toNavbarWatershed()
    }

    componentDidUpdate() {
        this.toNavbarWatershed()
    }

    render() {
        const { watersheds } = this.props
        const { watershed } = this.state
        const disabled = {
            active: this.state.isEditMode,
            disabled: !this.state.isEditMode,
        }

        const months = [
            { value: 1, name: i18n.january },
            { value: 2, name: i18n.february },
            { value: 3, name: i18n.march },
            { value: 4, name: i18n.april },
            { value: 5, name: i18n.may },
            { value: 6, name: i18n.june },
            { value: 7, name: i18n.july },
            { value: 8, name: i18n.august },
            { value: 9, name: i18n.september },
            { value: 10, name: i18n.october },
            { value: 11, name: i18n.november },
            { value: 12, name: i18n.december },
        ]

        const watershedParents = getReferencialComponent(watersheds).filter(w => w.id !== watershed.id)

        return (
            <div className='row no-margin'>
                <div id='file' className='col s12'>
                    <div className='card margin-top-0-75-rem'>
                        <div className='card-content'>
                            <div className='row no-margin'>
                                <div className='col s12 m8 l3' />
                                <div className='col s12 m8 l6'>
                                    <div className='row no-margin padding-top-3-px'>
                                        <Input
                                            col={ 4 }
                                            title={ i18n.code }
                                            value={ watershed.code }
                                            onChange={(v) => this.changeStateAttribute('code', v) }
                                            maxLength={ 20 }
                                            { ...disabled }
                                        />
                                        <Input
                                            col={ 8 }
                                            title={ i18n.name }
                                            value={ watershed.name }
                                            onChange={(v) => this.changeStateAttribute('name', v) }
                                            { ...disabled }
                                        />
                                    </div>
                                    <div className='row no-margin padding-top-3-px'>
                                        <NumberField
                                            col={ 4 }
                                            title={ i18n.area }
                                            value={ watershed.area }
                                            onChange={(v) => this.changeStateAttribute('area', parseInt(v)) }
                                            { ...disabled }
                                        />
                                        <Select
                                            options={ watershedParents }
                                            label={ i18n.parent }
                                            col={ 8 }
                                            onChange={(v) => this.changeStateAttribute('parent', v) }
                                            value={ watershed.parent }
                                            nullLabel='&nbsp;'
                                            { ...disabled }
                                        />
                                    </div>
                                    <div className='row no-margin padding-top-3-px'>
                                        <Select
                                            col={ 6 }
                                            label={ i18n.startMonthLowWater }
                                            options={ orderBy(months, 'value')}
                                            value={ watershed.startMonthLowWater }
                                            onChange={(v) => this.changeStateAttribute('startMonthLowWater', v) }
                                            nullLabel='&nbsp;'
                                            noSort
                                            { ...disabled }
                                        />
                                        <Select
                                            col={ 6 }
                                            label={ i18n.endMonthLowWater }
                                            options={ orderBy(months, 'value')}
                                            value={ watershed.endMonthLowWater }
                                            onChange={(v) => this.changeStateAttribute('endMonthLowWater', v) }
                                            nullLabel='&nbsp;'
                                            noSort
                                            { ...disabled }
                                        />
                                    </div>
                                    <div className='row no-margin padding-top-3-px'>
                                        <Textarea
                                            col={ 12 }
                                            title={ i18n.comment }
                                            value={ watershed.comment }
                                            onChange={(v) => this.changeStateAttribute('comment', v) }
                                            { ...disabled }
                                        />
                                    </div>
                                    <div className='row no-margin padding-top-3-px'>
                                        <Textarea
                                            col={ 12 }
                                            title={ i18n.km_polygon }
                                            value={ watershed.km_polygon }
                                            onChange={(v) => this.changeStateAttribute('kml_polygon', v) }
                                            { ...disabled }
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

WatershedApp.propTypes = {
    params: PropTypes.shape({
        code: PropTypes.string,
    }),
    watershed: PropTypes.instanceOf(WatershedDto),
    watersheds: PropTypes.instanceOf(WatershedDto),
    getLink: PropTypes.func,
    settings: PropTypes.instanceOf(SettingDto),
    isEnableEdit: PropTypes.bool,
    changeEditMode: PropTypes.func,
    isEditMode: PropTypes.bool,
}

const mapStateToProps = store => {
    return {
        watershed: store.WatershedReducer.watershed,
        settings: store.AdministrationReducer.settings,
        watersheds: store.WatershedReducer.watersheds,
    }
}

export default connect(mapStateToProps)(WatershedApp)
