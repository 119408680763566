import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import React, { useEffect, useState } from 'react'
import PluviometryAction from '../../../../pluviometry/actions/PluviometryAction'
import HydrometryAction from '../../../../hydrometry/actions/HydrometryAction'
import { getSiteUrl } from '../../../../utils/mapUtils/SiteTypes'
import { STATION_NAME_ASSOCIATION } from '../../../constants/StationConstants'
import i18n from 'simple-react-i18n'
import { orderBy } from 'lodash'
import PropTypes from 'prop-types'
import { instanceOf } from '../../../../utils/StoreUtils'
import DtoIAEauModel from '../../../../iaeau/dto/DtoIAEauModel'
import PiezometerStationAction from '../../../actions/PiezometerStationAction'
import { Card, CardContent, Grid } from '@mui/material'
import Checkbox from '../../../../components/forms/Checkbox'
import { getDate } from '../../../../utils/DateUtil'


const ModelStepModel = ({
    stationType,
    id, // station id
    selectedModel,
    changeModel,
    changeParams,
    params,
}) => {
    const dispatch = useDispatch()

    const {
        piezometers,
        pluviometers,
        hydrometricStations,
    } = useSelector(store => ({
        piezometers: store.PiezometryReducer.piezometersLight,
        pluviometers: store.PluviometryReducer.pluviometers,
        hydrometricStations: store.HydrometryReducer.hydrometricStations,
    }), shallowEqual)

    const [stats, setStats] = useState([])
    const piezos = orderBy(params.stations.filter(s => s.typeName === 'piezometry'), 'id')
    const hydros = orderBy(params.stations.filter(s => s.typeName === 'hydrometry'), 'id')
    const pluvios = orderBy(params.stations.filter(s => s.typeName === 'pluviometry'), 'id')

    useEffect(() => {
        const promises = [
            ...piezos.map(s => PiezometerStationAction.promisePiezoMeasuresStats(s.id)),
            ...hydros.map(s => HydrometryAction.promiseHydroStatistics(s.id)),
            ...pluvios.map(s => PluviometryAction.promisePluviometerMeasuresStats(s.id)),
        ]
        Promise.all(promises).then(res => setStats(res))
    }, [])

    if (!stats.length) {
        return null
    }
    const cards = [...piezos, ...hydros, ...pluvios].map((station, idx) => {
        const changeStation = changes => changeParams({ stations: [ ...params.stations.filter(s => s.id !== station.id || s.typeName !== station.typeName), { ...station, ...changes } ] })
        const stationStats = stats[idx]
        const addType = t => changeStation({ dataTypes: [ ...(station.dataTypes || []), t] })
        const removeType = t => changeStation({ dataTypes: (station.dataTypes || []).filter(t2 => t !== t2) })

        return (
            <Grid container justifyContent='center' alignItems='center' spacing={3} className='margin-top-1'>
                <Card sx={{ width: 1000 }} elevation={10}>
                    <CardContent elevation={10}>
                        <Grid container justifyContent='center' alignItems='center' spacing={3}>
                            <Grid item xs={1}>
                                <img src={getSiteUrl(STATION_NAME_ASSOCIATION[station.typeName])} style={{ maxHeight: '30px' } } />
                            </Grid>
                            <Grid item xs={1}>
                                <h6>{i18n[station.typeName]}</h6>
                            </Grid>
                            <Grid item xs={10}>
                                <h6>{`${station.code} - ${station.name}`}</h6>
                            </Grid>
                        </Grid>
                        {
                            stationStats.map(sStat => {
                                return (
                                    <div>
                                        <Grid container justifyContent='center' alignItems='center' spacing={3}>
                                            <Grid item xs={1}>
                                                <Checkbox checked={(station.dataTypes || []).includes(sStat.typeId)} onChange={v => v ? addType(sStat.typeId) : removeType(sStat.typeId) } disabled={selectedModel.idScenario === -1}/>
                                            </Grid>
                                            <Grid item xs={2}>
                                                <h6>{sStat.label}</h6>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <h6>{`${i18n.fromDate} ${getDate(sStat.startDate)} ${i18n.to} ${getDate(sStat.endDate)}`}</h6>
                                            </Grid>
                                            <Grid item xs={5}>
                                                <h6>{`${sStat.countTotal} ${i18n.measures}`}</h6>
                                            </Grid>
                                        </Grid>
                                    </div>
                                )
                            })
                        }
                    </CardContent>
                </Card>
            </Grid>

        )
    })
    return (
        <div className='padding-top-2'>
            <Grid container justifyContent='center' alignItems='center' spacing={3}>
                <h5>Choisissez les données à utiliser en entrée du modèle.</h5>
            </Grid>
            { cards }
        </div>

    )
}

ModelStepModel.propTypes = {
    stationType: PropTypes.string,
    id: PropTypes.string,
    selectedModel: instanceOf(DtoIAEauModel),
    setSelectedModel: PropTypes.func,
    changeParams: PropTypes.func,
    params: PropTypes.shape({}),
}

export default ModelStepModel