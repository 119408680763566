import { xor } from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import ProgressCard from '../../../../components/card/ProgressCard'
import Table from '../../../../components/datatable/Table'
import Checkbox from '../../../../components/forms/Checkbox'
import Input from '../../../../components/forms/Input'
import NumberField from '../../../../components/forms/NumberField'
import RadioButtons from '../../../../components/forms/RadioButtons'
import Select from '../../../../components/forms/Select'
import Icon from '../../../../components/icon/Icon'
import DtoHydrometricStation from '../../../../hydrometry/dto/DtoHydrometricStation'
import DtoParametrageDataType from '../../../../piezometry/dto/DtoParametrageDataType'
import DtoPiezometerLight from '../../../../piezometry/dto/DtoPiezometerLight'
import PluviometerDto from '../../../../pluviometry/dto/PluviometerDto'
import { nbPerPageLabelShort } from '../../../../referencial/constants/ReferencialConstants'
import { setModal } from '../../../../utils/FormUtils'
import { getHardHydroDataTypes } from '../../../../utils/HydroUtils'
import { getMeasureCoteList } from '../../../../utils/JobUtils'
import { getHardPiezoDataTypes } from '../../../../utils/PiezometryUtils'
import { execByType, getStations } from '../../../../utils/StationUtils'
import { removeNullKeys } from '../../../../utils/StoreUtils'
import JobAction from '../../../actions/JobAction'
import Job from '../../../dto/DtoJob'
import CSVGenericAdvancedModal from './CSVGenericAdvancedModal'
import ImportFileModal from './ImportFileModal'
import { integrationModeHelpIcon } from '../../../utils/ImportUtils'

class VeoliaVolumePanel extends Component {
    constructor(props) {
        super(props)
        const parameter = props.job.parameters.parameters.length ? JSON.parse(props.job.parameters.parameters[0]) : {}
        const { data = {} } = parameter
        this.state = {
            dataLoaded: true,
            progress: 100,
            ...parameter,
            ...data,
        }
    }

    componentDidMount() {
        this.props.loadCSVImportPanel(p => this.setState({ progress: p }), () => this.setState({ dataLoaded: true }))
    }

    onChangeJobParameter = (changes) => {
        const jobParameters = {
            ...this.props.job.parameters,
            ...changes,
        }
        this.props.onChangeJob({ parameters: jobParameters })
    }

    onChangeParametersNew = (currentParameter, index, changes) => {
        const jobParameters = {
            ...this.props.job.parameters,
            parameters: Object.assign([], this.props.job.parameters.parameters, {
                [index]: JSON.stringify(removeNullKeys({ ...currentParameter, ...changes })),
            }),
        }
        this.props.onChangeJob({ parameters: jobParameters })
    }

    onChangeFilters = (changes) => {
        const parameters = {
            ...this.props.job.parameters,
            filters: [JSON.stringify({
                ...this.getFilters(),
                ...changes,
            })],
        }
        this.props.onChangeJob({ parameters })
    }

    getFilters = () => {
        const newFilters = this.props.job.parameters.filters.length ? JSON.parse(this.props.job.parameters.filters[0]) : {}
        return { ...newFilters }
    }

    getParametersNew = () => this.props.job.parameters.parameters.length ? this.props.job.parameters.parameters.map(p =>JSON.parse(p)) : []


    getDataTypes = (filters) => execByType(filters.dataCategory, {
        pluviometry: () => this.props.pluviometryDataTypes,
        piezometry: () => [ ...getHardPiezoDataTypes(), ...this.props.piezometryDataTypes ],
        hydrometry: () => [ ...getHardHydroDataTypes(), ...this.props.hydrometryDataTypes ],
        default: () => [],
    })

    onDeleteParameter = (index) => {
        if (this.props.isEditMode) {
            const jobParameters = {
                ...this.props.job.parameters,
                parameters: this.props.job.parameters.parameters.filter((_, i) => i !== index),
            }
            this.props.onChangeJob({ parameters: jobParameters })
        }
    }

    onDuplicateParameter = (index) => {
        if (this.props.isEditMode) {
            const jobParameters = {
                ...this.props.job.parameters,
                parameters: [ ...this.props.job.parameters.parameters, this.props.job.parameters.parameters[index] ],
            }
            this.props.onChangeJob({ parameters: jobParameters })
        }
    }

    getStyle = () => {
        return { fontSize: 18, color: 'grey' }
    }

    getParametersData = (filters, listdata) => {
        const disabled = { disabled: !this.props.isEditMode }
        return listdata.length ?
            listdata.map((pd, index) => {
                return {
                    nullValue: <Icon icon='delete' tooltip={ i18n.delete } style={ this.getStyle() } key={`D${String(index)}`} onClick={ () => this.onDeleteParameter(index) } />,
                    nullValue2: <Icon icon='add_to_photos' tooltip={ i18n.duplicate } key={`A${index}`} onClick={ () => this.onDuplicateParameter(index) } />,
                    station: (<Select options={ getStations(this.props, filters.dataCategory).map(s => ({ ...s, name: s.code ? `[${s.code}] ${s.name}` : s.name })) }
                        onChange={ (_, v) => v && v.id ? this.onChangeParametersNew(pd, index, { stationCode: v.code, stationId: v.id }) : null }
                        value={ String(pd.stationId)} keyValue='id' {...disabled }
                    />),
                    excelTab: <Input value={ pd.sheet } onChange={ v => this.onChangeParametersNew(pd, index, { sheet: v }) } {...disabled }/>,
                    filter: <Input value={ pd.filter } onChange={ v => this.onChangeParametersNew(pd, index, { filter: v }) } {...disabled }/>,
                    firstLine: <NumberField value={ String(pd.firstLine) } onChange={ v => this.onChangeParametersNew(pd, index, { firstLine: v }) } {...disabled }/>,
                    date: <NumberField value={ String(pd.dateColumn) } onChange={ v => this.onChangeParametersNew(pd, index, { dateColumn: v }) } {...disabled }/>,
                    dataColumn: <NumberField value={ String(pd.columnId) } onChange={ v => this.onChangeParametersNew(pd, index, { columnId: v }) } {...disabled }/>,
                }
            })
            : []
    }

    addParameterNew = () => {
        if (this.props.isEditMode) {
            const jobParameters = {
                ...this.props.job.parameters,
                parameters: [ ...this.props.job.parameters.parameters, '{}'],
            }
            this.props.onChangeJob({ parameters: jobParameters })
        }
    }

    setAdvancedFtpModal =() => {
        const filters = this.getFilters()
        setModal({
            title: i18n.importParam,
            content: <ImportFileModal url={ filters.path } />,
        })
    }

    setAdvancedModal = (filters) => {
        setModal({
            title: i18n.settingsToDisplay,
            actions: (
                <div>
                    <a className='waves-effect waves-teal btn-flat modal-close'>{ i18n.close }</a>
                </div>
            ),
            content: <CSVGenericAdvancedModal unwantedColumns={ filters.unwantedColumns } onChange={ v => this.onChangeFilters({ unwantedColumns: v }) } dataTypes={ this.getDataTypes(filters) } />,
        })
    }

    filtreDatatype = (data, value) => data.filter(d => d.stationId == value ? d.channels : '')
        .map(v => v.channels)

    exportData = (listdata) => listdata.map(p => {
        const data = JSON.parse(p)
        const line = {
            filter: data.filter,
            excelTab: data.sheet,
            station: data.stationCode,
            dataColumn: data.dataColumn,
            columnId: data.firstLine,
            date: data.dateColumn,
            headers: [ 'filter', 'excelTab', 'station', 'Colonne des données', 'Première ligne', 'Colonne des jours'],
        }
        return line
    })

    render() {
        if (this.state.dataLoaded) {
            const disabled = !this.props.isEditMode
            const filters = this.getFilters()
            const measureOptions = [
                {
                    value: 'DATA_ADD_NEW_DATA',
                    label: i18n.addNewDataIntegration,
                },
                {
                    value: 'DATA_ADD_LAST_DATA',
                    label: i18n.lastDataIntegration,
                },
                {
                    value: 'DATA_REPLACE_HISTO',
                    label: i18n.replaceDataIntegration,
                },
            ]
            const parameters = this.getParametersNew()

            const datalist = parameters.length ? this.getParametersData(filters, parameters) : []
            const dataTypes = [
                { value: 'pluviometry', label: i18n.pluviometry },
                { value: 'piezometry', label: i18n.piezometry },
                { value: 'hydrometry', label: i18n.hydrometry },
            ]
            const baseHeaders = ['nullValue', 'nullValue2', 'filter', 'excelTab', 'station', 'firstLine', 'dataColumn', 'date']
            const myHeaders = ['nullValue', 'nullValue2', 'filter', 'excelTab', 'Station', 'Première ligne', 'Colonne des données', 'Colonne des jours']
            const addDataAction = [
                {
                    onClick: this.addParameterNew,
                    iconName: 'add_box',
                    tooltip: `${i18n.add}`,
                },
            ]
            return (
                <div>
                    <div className='row no-margin valign-wrapper padding-top-1'>
                        <Checkbox col={ 3 } label={ 'Renommer les fichiers en .DONE' } key={ '1' } onChange={ v => this.onChangeFilters({ renameProcessedFiles: v }) } disabled={ disabled } checked={ filters.renameProcessedFiles } />
                        <Checkbox col={ 4 } label={ 'Ne pas traiter les fichiers présents dans le journal' } key={ '2' } onChange={ v => this.onChangeFilters({ dontProcessFilesInLogs: v }) } disabled={ disabled } checked={ filters.dontProcessFilesInLogs } />
                        <NumberField col={ 2 } title={ 'Numéro ligne année' } value={ filters.yearLine } onChange={ v => this.onChangeFilters({ yearLine: v }) } disabled={ disabled } floatValue />
                        <NumberField col={ 2 } title={ 'Numéro colonne année' } value={filters.yearColumn } onChange={ v => this.onChangeFilters({ yearColumn: v }) } disabled={ disabled } floatValue />
                    </div>
                    <Select value={filters.importMode} options={measureOptions} label={ i18n.dataIntegration } labelSpan={ integrationModeHelpIcon() }
                        col={3} onChange={ v => this.onChangeFilters({ importMode: v }) } disabled={ disabled }
                    />
                    <div className='row no-margin valign-wrapper'>
                        <Input col={ 4 } title={ i18n.directory } value={ filters.path } onChange={ v => this.onChangeFilters({ path: v }) } disabled={ disabled } />
                        <a className='btn btn-small secondary-color-back' onClick={ () => this.setAdvancedFtpModal()} ><span title={ i18n.importLabelFtp }> <i className='material-icons'>input </i> </span></a>
                    </div>
                    <div className='row no-margin'>
                        <Select col={ 4 } label={ i18n.dataType } options={ dataTypes } value={ filters.dataCategory } onChange={ v => this.onChangeFilters({ dataCategory: v }) } disabled={ disabled }/>
                        <RadioButtons col={ 3 } elements={ getMeasureCoteList() } key={ '3' } selected={ filters.measureCote } onChange={ v => this.onChangeFilters({ measureCote: v }) } title={ i18n.ratingExpression } disabled={ disabled }/>
                    </div>
                    <div className='job-parameter-table'>
                        <Table
                            data={ datalist }
                            nbPerPageLabel={ nbPerPageLabelShort }
                            type={ { headers: xor([ ...baseHeaders ], filters.unwantedColumns) } }
                            sortable
                            condensed
                            paging
                            customHeaders={ myHeaders }
                            activeHeader
                            actions={ addDataAction }
                        />
                    </div>
                </div>
            )
        }
        return <ProgressCard progress={ this.state.progress }/>
    }
}

VeoliaVolumePanel.propTypes = {
    job: PropTypes.instanceOf(Job).isRequired,
    onChangeJob: PropTypes.func.isRequired,
    piezometryDataTypes: PropTypes.arrayOf(PropTypes.instanceOf(DtoParametrageDataType)),
    piezometers: PropTypes.arrayOf(PropTypes.instanceOf(DtoPiezometerLight)),
    pluviometers: PropTypes.arrayOf(PropTypes.instanceOf(PluviometerDto)),
    pluviometryDataTypes: PropTypes.arrayOf(PropTypes.instanceOf(DtoParametrageDataType)),
    hydrometricStations: PropTypes.arrayOf(PropTypes.instanceOf(DtoHydrometricStation)),
    hydrometryDataTypes: PropTypes.arrayOf(PropTypes.instanceOf(DtoParametrageDataType)),
    isEditMode: PropTypes.bool,
    loadCSVImportPanel: PropTypes.func,
}

const mapStateToProps = store => ({
    piezometryDataTypes: store.PiezometryReducer.piezometryDataTypes,
    piezometers: store.PiezometryReducer.piezometersLight,
    pluviometers: store.PluviometryReducer.pluviometers,
    pluviometryDataTypes: store.PluviometryReducer.pluviometryDataTypes,
    hydrometricStations: store.HydrometryReducer.hydrometricStations,
    hydrometryDataTypes: store.HydrometryReducer.hydrometryDataTypes,
})

const mapDispatchToProps = {
    loadCSVImportPanel: JobAction.loadCSVImportPanel,
}
export default connect(mapStateToProps, mapDispatchToProps)(VeoliaVolumePanel)