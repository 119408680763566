export default class PluviometerNormalDto {
    constructor(obj = {}) {
        this.id = obj.id
        this.month = obj.month
        this.value = obj.value
        this.title = obj.title
        this.etp = obj.etp
        this.effectiveRain = obj.effectiveRain
    }
}
