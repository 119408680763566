import DtoFile from '../../../../components/file/dto/DtoFile'
import DtoPicture from '../../../../station/dto/DtoPicture'
import { RESET_ALL_MATERIELS } from '../../../constants/MaterielConstants'
import { RESET_EQUIPMENT } from '../../equipment/constants/EquipmentConstants'
import {
    RECEIVE_ALL_CENTRALS, RECEIVE_ALL_CENTRAL_SITUATIONS, RECEIVE_CENTRAL, RECEIVE_CENTRALS_LAST_SITUATIONS, RECEIVE_CENTRAL_CHANNELS,
    RECEIVE_CENTRAL_FILES,
    RECEIVE_CENTRAL_PICTURES,
    RECEIVE_CENTRAL_RANGES,
    RECEIVE_CENTRAL_TYPES, RESET_CENTRAL,
} from '../constants/CentralConstants'
import CentralDto from '../dto/CentralDto'
import CentralTypeDto from '../dto/CentralTypeDto'
import DtoCentralChannel from '../dto/DtoCentralChannel'
import DtoCentralRange from '../dto/DtoCentralRange'
import DtoCentralSituation from '../dto/DtoCentralSituation'

export const store = {
    centrals: [],
    central: {},
    centralTypes: [],
    centralRanges: [],
    centralChannels: [],
    centralSituations: [],
    centralsLastSituations: [],
    centralPictures: [],
    centralFiles: [],
}

export function CentralReducer(state = {}, action) {
    switch (action.type) {
        case RECEIVE_ALL_CENTRALS:
            return {
                ...state,
                centrals: action.centrals.map(central => new CentralDto(central)),
            }
        case RECEIVE_CENTRAL:
            return {
                ...state,
                central: new CentralDto(action.central),
            }
        case RECEIVE_CENTRAL_TYPES:
            return {
                ...state,
                centralTypes: action.centralTypes.map(t => new CentralTypeDto(t)),
            }
        case RECEIVE_CENTRAL_RANGES:
            return {
                ...state,
                centralRanges: action.centralRanges.map(el => new DtoCentralRange(el)),
            }
        case RECEIVE_CENTRAL_CHANNELS:
            return {
                ...state,
                centralChannels: action.centralChannels.map(el => new DtoCentralChannel(el)),
            }
        case RECEIVE_ALL_CENTRAL_SITUATIONS:
            return {
                ...state,
                centralSituations: action.centralSituations.map(el => new DtoCentralSituation(el)),
            }
        case RECEIVE_CENTRAL_PICTURES:
            return {
                ...state,
                centralPictures: action.centralPictures.map(el => new DtoPicture(el)),
            }
        case RECEIVE_CENTRAL_FILES:
            return {
                ...state,
                centralFiles: action.centralFiles.map(el => new DtoFile(el)),
            }
        case RECEIVE_CENTRALS_LAST_SITUATIONS:
            return {
                ...state,
                centralsLastSituations: action.centralsLastSituations.map(s => new DtoCentralSituation(s)),
            }
        case RESET_CENTRAL:
            return {
                ...state,
                central: {},
                centralChannels: [],
                centralRanges: [],
                centralSituations: [],
                centralPictures: [],
                centralFiles: [],
            }
        case RESET_EQUIPMENT:
            return {
                ...state,
                centralTypes: [],
            }
        case RESET_ALL_MATERIELS:
            return {
                ...state,
                ...store,
            }
        default:
            return state
    }
}