import { createAsyncThunk } from '@reduxjs/toolkit'
import ApplicationConf from '../../conf/ApplicationConf'
import { checkAuth, checkError, getAuthorization, getJson } from '../../utils/ActionUtils'
import { IDLE, REJECTED } from '../../store/DataManagerConstants'

const PerimetersThunk = {
    getPerimetersFolders: createAsyncThunk(
        'getPerimetersFolders',
        async(_, { rejectWithValue }) => {
            try {
                return await fetch(ApplicationConf.perimeters.perimetersFolders(), {
                    method: 'GET',
                    headers: getAuthorization() })
                    .then(checkAuth)
                    .then(checkError)
                    .then(getJson)
            } catch (err) {
                return rejectWithValue(err)
            }
        }, {
            condition: (arg = { forceFetch: false }, { getState }) => {
                const state = getState()

                const { perimetersFolderStatus } = state.DataManagerReducer.perimeters
                if (arg.forceFetch) {
                    return perimetersFolderStatus !== REJECTED
                }
                return perimetersFolderStatus === IDLE
            },
            dispatchConditionRejection: true,
        }
    ),
    getPerimeters: createAsyncThunk(
        'getPerimeters',
        async(id, { rejectWithValue }) => {
            try {
                return await fetch(ApplicationConf.perimeters.getPerimeters(id), {
                    method: 'GET',
                    headers: getAuthorization() })
                    .then(checkAuth)
                    .then(checkError)
                    .then(getJson)
            } catch (err) {
                return rejectWithValue(err)
            }
        }, {
            condition: (arg = { forceFetch: false }, { getState }) => {
                const state = getState()

                const { perimetersStatus } = state.DataManagerReducer.perimeters
                if (arg.forceFetch) {
                    return perimetersStatus !== REJECTED
                }
                return perimetersStatus === IDLE
            },
            dispatchConditionRejection: true,
        }
    ),
    getPerimetersFolderPrivateOwners: createAsyncThunk(
        'getPerimetersFolderPrivateOwners',
        async(folderId, { rejectWithValue }) => {
            try {
                return await fetch(ApplicationConf.perimeters.getPerimetersFolderPrivateOwners(folderId), {
                    method: 'GET',
                    headers: getAuthorization(),
                })
                    .then(checkAuth)
                    .then(checkError)
                    .then(getJson)
            } catch (err) {
                return rejectWithValue(err)
            }
        },
        {
            condition: (arg = { forceFetch: false }, { getState }) => {
                const state = getState()

                const { perimetersFolderPrivateOwnersStatus } = state.DataManagerReducer.perimeters
                if (arg.forceFetch) {
                    return perimetersFolderPrivateOwnersStatus !== REJECTED
                }
                return perimetersFolderPrivateOwnersStatus === IDLE
            },
            dispatchConditionRejection: true,
        }
    ),
    getPerimetersFolderCompanyOwners: createAsyncThunk(
        'getPerimetersFolderCompanyOwners',
        async(folderId, { rejectWithValue }) => {
            try {
                return await fetch(ApplicationConf.perimeters.getPerimetersFolderCompanyOwners(folderId), {
                    method: 'GET',
                    headers: getAuthorization(),
                })
                    .then(checkAuth)
                    .then(checkError)
                    .then(getJson)
            } catch (err) {
                return rejectWithValue(err)
            }
        },
        {
            condition: (arg = { forceFetch: false }, { getState }) => {
                const state = getState()

                const { perimetersFolderCompanyOwnersStatus } = state.DataManagerReducer.perimeters
                if (arg.forceFetch) {
                    return perimetersFolderCompanyOwnersStatus !== REJECTED
                }
                return perimetersFolderCompanyOwnersStatus === IDLE
            },
            dispatchConditionRejection: true,
        }
    ),
    getMortgages: createAsyncThunk(
        'getMortgages',
        async(_, { rejectWithValue }) => {
            try {
                return await fetch(ApplicationConf.perimeters.deeds(), {
                    method: 'GET',
                    headers: getAuthorization() })
                    .then(checkAuth)
                    .then(checkError)
                    .then(getJson)
            } catch (err) {
                return rejectWithValue(err)
            }
        }, {
            condition: (arg = { forceFetch: false }, { getState }) => {
                const state = getState()

                const { mortgageStatus } = state.DataManagerReducer.perimeters
                if (arg.forceFetch) {
                    return mortgageStatus !== REJECTED
                }
                return mortgageStatus === IDLE
            },
            dispatchConditionRejection: true,
        }
    ),
    addDeedOwner: createAsyncThunk(
        'addDeedOwner',
        async(data, { rejectWithValue, dispatch }) => {
            try {
                dispatch(WaitAction.waitStart())

                const response = await fetch(ApplicationConf.perimeters.deedOwner(), {
                    method: 'POST',
                    headers: getAuthorization(),
                    body: JSON.stringify(data) })
                    .then(checkAuth)
                    .then(checkError)
                    .then(getJson)
                    .then(res => {
                        dispatch(WaitAction.waitStop())
                        dispatch(ToastrAction.success(i18n.deedCreated))
                        return res
                    })
                    .catch(() => {
                        dispatch(WaitAction.waitStop())
                        dispatch(ToastrAction.error(i18n.somethingWentWrong))
                    })

                dispatch(PerimetersThunk.getMortgages())

                return response
            } catch (err) {
                return rejectWithValue(err)
            }
        },
    ),
    getPerimeterFolderLastEvent: createAsyncThunk(
        'getPerimeterFolderLastEvent',
        async(folderId, { rejectWithValue }) => {
            try {
                return await fetch(ApplicationConf.perimeters.getPerimeterFolderLastEvent(folderId), {
                    method: 'GET',
                    headers: getAuthorization(),
                })
                    .then(checkAuth)
                    .then(checkError)
                    .then(getJson)
            } catch (err) {
                return rejectWithValue(err)
            }
        },
    ),
}

export default PerimetersThunk