module.exports = {
    RECEIVE_ALL_CENTRALS: 'RECEIVE_ALL_CENTRALS',
    RECEIVE_CENTRAL: 'RECEIVE_CENTRAL',
    RESET_CENTRAL: 'RESET_CENTRAL',
    RECEIVE_CENTRAL_TYPES: 'RECEIVE_CENTRAL_TYPES',
    RECEIVE_ALL_CENTRAL_SITUATIONS: 'RECEIVE_ALL_CENTRAL_SITUATIONS',
    RECEIVE_CENTRAL_RANGES: 'RECEIVE_CENTRAL_RANGES',
    RECEIVE_CENTRAL_CHANNELS: 'RECEIVE_CENTRAL_CHANNELS',
    RECEIVE_CENTRALS_LAST_SITUATIONS: 'RECEIVE_CENTRALS_LAST_SITUATIONS',
    RECEIVE_CENTRAL_EMERGENCY_SLOT: 'RECEIVE_CENTRAL_EMERGENCY_SLOT',
    RECEIVE_CENTRAL_LANE_ASSIGNMENT: 'RECEIVE_CENTRAL_LANE_ASSIGNMENT',
    RECEIVE_CENTRAL_PICTURES: 'RECEIVE_CENTRAL_PICTURES',
    RECEIVE_CENTRAL_FILES: 'RECEIVE_CENTRAL_FILES',
}
