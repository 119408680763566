import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import NumberField from '../../../../components/forms/NumberField'
import Select from '../../../../components/forms/Select'
import Textarea from '../../../../components/forms/Textarea'
import { getDefaultValuesChoiceModal, getMatTypes } from '../../../../utils/MaterielUtils'
import PowerSupplyDto from '../dto/PowerSupplyDto'
import PowerSupplyTypeDto from '../dto/PowerSupplyTypeDto'

const PowerSupplyEquipmentPanel = ({
    active = false,
    disabled = true,
    powerSupply = {},
    powerSupplyTypes = [],
    onChange = () => { },
}) => (
    <div className='col s12 margin-bottom-1'>
        <div className='row no-margin' style={{ paddingTop: '3px' }}>
            <Select
                options={getMatTypes(powerSupplyTypes, powerSupply.powerSupplyType)}
                label={i18n.type}
                col={4}
                onChange={value => {
                    onChange({ powerSupplyType: value })
                    getDefaultValuesChoiceModal(powerSupplyTypes.find(c => c.materielType == value), onChange)
                }}
                value={powerSupply.powerSupplyType}
                nullLabel='&nbsp;'
                acive={active}
                disabled={disabled}
            />
            <NumberField
                col={4}
                title={`${i18n.voltage} (V)`}
                value={powerSupply.voltage}
                onChange={v => onChange({ voltage: v })}
                floatValue
                acive={active}
                disabled={disabled}
            />
            <NumberField
                col={4}
                title={`${i18n.lifeTime} (${i18n.month})`}
                value={powerSupply.lifeTime}
                onChange={v => onChange({ lifeTime: v })}
                floatValue
                acive={active}
                disabled={disabled}
            />
        </div>
        <div className='row no-margin' style={{ paddingTop: '8px' }}>
            <Textarea
                col={12}
                title={i18n.comment}
                value={powerSupply.comment}
                onChange={value => onChange({ comment: value })}
                acive={active}
                disabled={disabled}
            />
        </div>
    </div>
)


PowerSupplyEquipmentPanel.propTypes = {
    disabled: PropTypes.bool,
    active: PropTypes.bool,
    onChange: PropTypes.func,
    powerSupply: PropTypes.instanceOf(PowerSupplyDto),
    powerSupplyTypes: PropTypes.arrayOf(PropTypes.instanceOf(PowerSupplyTypeDto)),
}

const mapStateToProps = store => ({
    powerSupplyTypes: store.PowerSupplyReducer.powerSupplyTypes,
})

export default connect(mapStateToProps)(PowerSupplyEquipmentPanel)
