import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import Job from '../../../import/dto/DtoJob'
import PluviometryAction from '../../../pluviometry/actions/PluviometryAction'
import JobAlert from './JobAlert'
import { arrayOf } from '../../../utils/StoreUtils'
import DtoParametrageDataType from '../../../piezometry/dto/DtoParametrageDataType'
import AppStore from '../../../store/AppStore'
import { getThresholdFormat } from '../../utils/MeasureUtil'

const DEFAULT_PLUVIO_DATA_TYPE = '-1'

class AlertPluvio extends Component {
    componentDidMount() {
        this.props.fetchPluviometers()
        this.props.fetchPluviometerAllThresholds()
        if (!this.props.pluviometryDataTypes.length) {
            AppStore.dispatch(PluviometryAction.fetchPluviometryDataTypes())
        }
    }

    onChangeFilters = (changes) => {
        const {
            job,
        } = this.props
        const parameters = {
            ...job.parameters,
            filters: [JSON.stringify({
                ...this.getFilters(),
                ...changes,
            })],
        }
        this.props.onChangeJob({ parameters })
    }

    getFilters = () => {
        const {
            job,
        } = this.props
        const newFilters = job.parameters.filters.length ? JSON.parse(job.parameters.filters[0]) : {}
        return { modeCumulPluvio: 'SUM_PERSO', nbHoursCumulPerso: 24, ...newFilters }
    }

    render() {
        const {
            pluviometryDataTypes,
            pluviometerThresholds,
            job,
            isEditMode,
            pluviometers,
            onChangeEmails,
            onChangeContactsIds,
            onChangeStation,
            onChangeAlertTypes,
        } = this.props
        const formatThresholds = pluviometerThresholds.map(t => {
            const dt = pluviometryDataTypes?.find(d => d.id === parseInt(t.dataType ?? DEFAULT_PLUVIO_DATA_TYPE))
            return dt ? getThresholdFormat(t, dt) : {}
        })
        return (
            <JobAlert
                stationType='pluviometry'
                filters={this.getFilters()}
                dataTypes={pluviometryDataTypes}
                job={job}
                isEditMode={isEditMode}
                stations={pluviometers}
                thresholds={formatThresholds}
                onChangeEmails={onChangeEmails}
                onChangeContactsIds={onChangeContactsIds}
                onChangeStation={onChangeStation}
                onChangeAlertTypes={onChangeAlertTypes}
                onChangeFilters={this.onChangeFilters}
            />
        )
    }
}

AlertPluvio.propTypes = {
    onChangeJob: PropTypes.func,
    job: PropTypes.instanceOf(Job).isRequired,
    isEditMode: PropTypes.bool,
    onChangeEmails: PropTypes.func,
    onChangeContactsIds: PropTypes.func,
    onChangeStation: PropTypes.func,
    onChangeAlertTypes: PropTypes.func,
    pluviometers: PropTypes.arrayOf(PropTypes.object),
    pluviometerThresholds: PropTypes.arrayOf(PropTypes.object),
    fetchPluviometers: PropTypes.func,
    fetchPluviometerAllThresholds: PropTypes.func,
    pluviometryDataTypes: arrayOf(DtoParametrageDataType),
}

const mapStateToProps = store => ({
    pluviometers: store.PluviometryReducer.pluviometers.map(({ id, code, name }) => ({
        id,
        code,
        name: `[${code}] ${name}`,
    })),
    pluviometerThresholds: store.PluviometryReducer.pluviometerAllThresholds,
    pluviometryDataTypes: store.PluviometryReducer.pluviometryDataTypes,
})

const mapDispatchToProps = {
    fetchPluviometers: PluviometryAction.fetchPluviometers,
    fetchPluviometerAllThresholds: PluviometryAction.fetchPluviometerAllThresholds,
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(AlertPluvio)