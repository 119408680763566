import moment from 'moment'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import Card from '../../../components/card/Card'
import UserHomePageModal from '../user/components/UserHomePageModal'
import { BASIC_HOMEPAGE_VIEWS } from '../user/constants/UserConstants'
import DtoUser from '../user/dto/DtoUser'
import UserViewDto from '../user/dto/UserViewDto'

class AdministrationUserHomePage extends Component {
    constructor(props) {
        super(props)
        this.state = {
            openHomeScreen: false,
        }
    }

    getCard = (name, size) => {
        const view = this.props.homepageViews.find(v => v.panel === name) || { ...BASIC_HOMEPAGE_VIEWS.find(v => v.panel === name), visible: true }
        const actions = []
        if (!view || view.visible) {
            if (['quality', 'piezometry', 'hydrometry', 'pluviometry', 'productionUnit'].includes(name)) {
                actions.push({
                    iconName: view.graphicMode ? 'list' : 'insert_chart',
                    onClick: () => {},
                })
            }
            actions.push({
                iconName: 'visibility',
                onClick: () => this.props.onChangeView(view, { visible: false }),
            })
        } else {
            actions.push({
                iconName: 'visibility_off',
                onClick: () => this.props.onChangeView(view, { visible: true }),
            })
        }
        return (
            <Card height={ size } title={ i18n[name] } noMargin={ false } className='margin-bottom-1' contentClassName='valign-wrapper' actions={ actions } cardStyle={!view || view.visible ? {} : { opacity: 0.5 }}>
                { ['quality', 'piezometry', 'hydrometry', 'pluviometry', 'productionUnit'].includes(name) ? <i className='material-icons large absolute left-45' >{ view.displayMode ? 'insert_chart' : 'list' }</i> : null }
            </Card>
        )
    }

    createParameter = (parameter, value) => {
        return {
            login: this.props.accountUser.login,
            parameter,
            updateDate: moment().valueOf(),
            value,
        }
    }

    render() {
        const bookmarks = ['quality', 'piezometry', 'hydrometry', 'pluviometry', 'productionUnit', 'agriculture', 'perimeters'].map(type => this.getCard(type, 70))
        return (
            <div>
                <div className='col s12 row no-margin padding-top-2 padding-bottom-1'>
                    <div className='col s3'>
                        <a
                            className='waves-effect waves-light btn'
                            onClick={() => this.setState({ openHomeScreen: true })}
                        >
                            {i18n.setHomepage}
                        </a>
                    </div>
                    <div className='col s3 offset-s6 padding-left-2'>
                        <a className='col s12 btn' onClick={ this.props.resetView }>
                            { i18n.reinit }
                        </a>
                    </div>
                </div>
                <div className='row'>
                    <div className='col s9 padding-top-1 padding-left-2'>
                        { bookmarks }
                        { this.getCard('map', 400) }
                    </div>
                    <div className='col s3 padding-top-1'>
                        { this.getCard('keyFigures', 100) }
                        { this.getCard('arrests', 150) }
                        { this.getCard('vigilances', 150) }
                        { this.getCard('tweets', 400) }
                    </div>
                </div>
                <UserHomePageModal
                    login={this.props.accountUser?.login}
                    open={this.state.openHomeScreen}
                    setOpen={v => this.setState({ openHomeScreen: v })}
                />
            </div>
        )
    }
}

AdministrationUserHomePage.propTypes = {
    homepageViews: PropTypes.arrayOf(PropTypes.instanceOf(UserViewDto)),
    onChangeView: PropTypes.func,
    resetView: PropTypes.func,
    accountUser: PropTypes.instanceOf(DtoUser),
}

const mapStateToProps = (store) => ({
    accountUser: store.AccountReducer.accountUser,
})

export default connect(mapStateToProps)(AdministrationUserHomePage)
