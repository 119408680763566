import ApplicationConf from 'conf/ApplicationConf'
import { push } from '@lagunovsky/redux-react-router'
import fetch from 'isomorphic-fetch'
import i18n from 'simple-react-i18n'
import ToastrAction from 'toastr/actions/ToastrAction'
import SieauAction from '../../../../components/sieau/SieauAction'
import LogAction from '../../../../log/actions/LogAction'
import {
    checkAuth,
    checkAuthWithoutKicking,
    checkError,
    getAuthorization,
    getJson,
} from '../../../../utils/ActionUtils'
import { setMessageModal } from '../../../../utils/FormUtils'
import { hasValue } from '../../../../utils/NumberUtil'
import {
    RECEIVE_ALL_POWER_SUPPLIES,
    RECEIVE_ALL_POWER_SUPPLY_SITUATIONS,
    RECEIVE_POWER_SUPPLIES_LAST_SITUATIONS,
    RECEIVE_POWER_SUPPLY,
    RECEIVE_POWER_SUPPLY_FILES,
    RECEIVE_POWER_SUPPLY_PICTURES,
    RECEIVE_POWER_SUPPLY_TYPES,
    RESET_POWER_SUPPLY,
} from '../constants/PowerSupplyConstants'

const PowerSupplyAction = {
    receivePowerSupplies(powerSupplies) {
        return { type: RECEIVE_ALL_POWER_SUPPLIES, powerSupplies }
    },

    promisePowerSupplies(withEquipment) {
        return fetch(ApplicationConf.materiel.allPowerSupplies(withEquipment), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
    },
    fetchPowerSupplies(withEquipment) {
        return (dispatch) => {
            return PowerSupplyAction.promisePowerSupplies(withEquipment)
                .then((json = []) => {
                    dispatch(PowerSupplyAction.receivePowerSupplies(json))
                    dispatch(SieauAction.receiveProps('powerSupplies'))
                    if (json.length) {
                        dispatch(PowerSupplyAction.fetchPowerSuppliesLastSituations())
                    }
                })
        }
    },

    receivePowerSupply(powerSupply) {
        return { type: RECEIVE_POWER_SUPPLY, powerSupply }
    },

    promisePowerSupply(id) {
        return fetch(ApplicationConf.materiel.powerSupply(id), {
            headers: getAuthorization(),
        })
            .then(checkAuthWithoutKicking)
            .then(getJson)
            .then(checkError)
    },

    fetchPowerSupply: id => dispatch => {
        return PowerSupplyAction.promisePowerSupply(id)
            .then((json = {}) => {
                dispatch(PowerSupplyAction.receivePowerSupply(json))
                return json
            })
            .catch(e => {
                dispatch(LogAction.logError(`${i18n.fetchError + i18n.powerSupply} : ${e}`))
                dispatch(ToastrAction.error(i18n.fetchError + i18n.powerSupply))
            })
    },

    receivePowerSupplyTypes(powerSupplyTypes) {
        return { type: RECEIVE_POWER_SUPPLY_TYPES, powerSupplyTypes }
    },

    promisePowerSupplyTypes(withEquipmentType) {
        return fetch(ApplicationConf.materiel.powerSupplyAllTypes(withEquipmentType), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
    },

    fetchPowerSupplyTypes(withEquipmentType) {
        return (dispatch) => {
            return PowerSupplyAction.promisePowerSupplyTypes(withEquipmentType)
                .then((json = {}) => {
                    dispatch(PowerSupplyAction.receivePowerSupplyTypes(json))
                })
        }
    },

    updatePowerSupplyTypes(id, materielType) {
        return (dispatch) => {
            return fetch(ApplicationConf.materiel.powerSupplyType(id), {
                method: 'PUT',
                headers: getAuthorization(),
                body: JSON.stringify(materielType),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(() => {
                    dispatch(PowerSupplyAction.fetchPowerSupplyTypes(true))
                    dispatch(ToastrAction.success(i18n.saveSuccess))
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.updateError + i18n.materielType} : ${err}`))
                    dispatch(ToastrAction.error(i18n.updateError + i18n.materielType))
                })
        }
    },
    deletePowerSupplyType(id, callback = () => { }) {
        return (dispatch) => {
            return fetch(ApplicationConf.materiel.powerSupplyType(id), {
                method: 'DELETE',
                headers: getAuthorization(),
            })
                .then(r => checkAuth(r, {
                    206: () => setMessageModal(i18n.deletingUsedMaterielTypeMessage),
                }))
                .then(getJson)
                .then(checkError)
                .then(json => {
                    if (json.delete >= 1) {
                        dispatch(PowerSupplyAction.fetchPowerSupplyTypes(true))
                        dispatch(ToastrAction.success(i18n.elementDeleteSuccess))
                        callback()
                    } else {
                        throw new Error()
                    }
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.deleteError + i18n.materielType} : ${err}`))
                    dispatch(ToastrAction.error(i18n.deleteError + i18n.materielType))
                })
        }
    },
    addPowerSupplyTypes(materielType, callback = () => { }) {
        return (dispatch) => {
            return fetch(ApplicationConf.materiel.powerSupplyTypes(), {
                method: 'POST',
                headers: getAuthorization(),
                body: JSON.stringify(materielType),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(json => {
                    if (json.insert === 1 && json.id) {
                        dispatch(PowerSupplyAction.fetchPowerSupplyTypes(true))
                        dispatch(ToastrAction.success(i18n.addSuccess))
                        callback({ ...materielType, materielType: json.id })
                    } else {
                        throw new Error()
                    }
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.createError + i18n.materielType} : ${err}`))
                    dispatch(ToastrAction.error(i18n.createError + i18n.materielType))
                })
        }
    },

    receivePowerSupplySituations(powerSupplySituations) {
        return { type: RECEIVE_ALL_POWER_SUPPLY_SITUATIONS, powerSupplySituations }
    },

    fetchPowerSupplySituations(id) {
        return (dispatch) => {
            return fetch(ApplicationConf.materiel.powerSupplySituations(id), {
                method: 'GET',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(json => {
                    dispatch(PowerSupplyAction.receivePowerSupplySituations(json))
                })
        }
    },

    savePowerSupplySituation(situation, shouldFetch = true) {
        return (dispatch) => {
            return fetch(ApplicationConf.materiel.addPowerSupplySituation(), {
                method: 'POST',
                headers: getAuthorization(),
                body: JSON.stringify(situation),
            })
                .then(() => {
                    dispatch(PowerSupplyAction.fetchPowerSuppliesLastSituations())
                    dispatch(ToastrAction.success(i18n.situationAddSuccess))
                    if (shouldFetch) {
                        dispatch(PowerSupplyAction.fetchPowerSupplySituations(situation.idPowerSupply))
                    }
                })
        }
    },

    deletePowerSupplySituation(id, idPowerSupply) {
        return (dispatch) => {
            return fetch(ApplicationConf.materiel.deletePowerSupplySituation(id), {
                method: 'DELETE',
                headers: getAuthorization(),
            })
                .then(() => {
                    dispatch(PowerSupplyAction.fetchPowerSuppliesLastSituations())
                    dispatch(ToastrAction.success(i18n.situationDeleteSuccess))
                    dispatch(PowerSupplyAction.fetchPowerSupplySituations(idPowerSupply))
                })
        }
    },
    updatePowerSupplySituation(idSituation, situation, openCampaginModal = undefined) {
        return (dispatch) => {
            return fetch(ApplicationConf.materiel.savePowerSupplySituation(idSituation), {
                method: 'PUT',
                headers: getAuthorization(),
                body: JSON.stringify(situation),
            })
                .then(() => {
                    dispatch(PowerSupplyAction.fetchPowerSuppliesLastSituations())
                    dispatch(ToastrAction.success(i18n.saveSuccess))
                    if (openCampaginModal) {
                        openCampaginModal(situation)
                    }
                })
        }
    },
    createEventAndUpdateSituation(urlEvent, event, situation, openCampaginModal = undefined) {
        return (dispatch) => {
            return fetch(urlEvent, {
                method: 'POST',
                headers: getAuthorization(),
                body: JSON.stringify(event),
            }).then(getJson)
                .then((json) => {
                    if (json.eventId) {
                        const result = Object.assign({}, situation, {
                            eventCode: json.eventId,
                        })
                        dispatch(PowerSupplyAction.updatePowerSupplySituation(result.id, result))
                        if (openCampaginModal) {
                            dispatch(openCampaginModal(result))
                        }
                    } else {
                        dispatch(ToastrAction.error(i18n.saveError))
                    }
                })
        }
    },

    updatePowerSupply(powerSupply) {
        return (dispatch) => {
            return fetch(ApplicationConf.materiel.powerSupply(powerSupply.id), {
                method: 'PUT',
                headers: getAuthorization(),
                body: JSON.stringify(powerSupply),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(json => {
                    if (json.update === 1) {
                        dispatch(PowerSupplyAction.fetchPowerSupplies(true))
                        dispatch(PowerSupplyAction.fetchPowerSupply(powerSupply.id))
                        dispatch(ToastrAction.success(i18n.elementUpdateSuccess))
                        return true
                    }
                    throw new Error(json)
                })
                .catch(err => {
                    const label = i18n.updateError + (err.message === '409 Conflict' ? i18n.immoNumberOrSerialNumberAlreadyUse : i18n.powerSupply)
                    dispatch(LogAction.logError(`${label} ${err}`))
                    dispatch(ToastrAction.error(label))
                })
        }
    },

    createPowerSupply(powerSupply) {
        return (dispatch) => {
            return fetch(ApplicationConf.materiel.powerSupplies(), {
                method: 'POST',
                headers: getAuthorization(),
                body: JSON.stringify(powerSupply),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(json => {
                    if (json.insert === 1 && hasValue(json.id)) {
                        dispatch(PowerSupplyAction.fetchPowerSupplies(true))
                        dispatch(push(`/materiel/powerSupply/${json.id}`))
                        dispatch(ToastrAction.success(i18n.elementCreateSuccess))
                        return json.id
                    }
                    throw new Error(json)
                })
                .catch(err => {
                    const label = i18n.updateError + (err.message === '409 Conflict' ? i18n.immoNumberOrSerialNumberAlreadyUse : i18n.powerSupply)
                    dispatch(LogAction.logError(`${label} ${err}`))
                    dispatch(ToastrAction.error(label))
                })
        }
    },

    deletePowerSupply(id) {
        return (dispatch) => {
            return fetch(ApplicationConf.materiel.powerSupply(id), {
                method: 'DELETE',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(json => {
                    if (json.delete > 0) {
                        dispatch(PowerSupplyAction.fetchPowerSupplies(true))
                        dispatch(push('/materiel/powerSupply'))
                        dispatch(ToastrAction.success(i18n.elementDeleteSuccess))
                    } else {
                        throw new Error(json)
                    }
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.deleteError + i18n.powerSupply} : ${err}`))
                    dispatch(ToastrAction.error(i18n.deleteError + i18n.powerSupply))
                })
        }
    },

    promisePowerSupplySituation(id) {
        return fetch(ApplicationConf.materiel.powerSupplySituations(id), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
    },

    receivePowerSupplyLastSituations(powerSuppliesLastSituations) {
        return { type: RECEIVE_POWER_SUPPLIES_LAST_SITUATIONS, powerSuppliesLastSituations }
    },

    promisePowerSuppliesLastSituation: () => fetch(ApplicationConf.materiel.powerSuppliesLastSituation(), {
        method: 'GET',
        headers: getAuthorization(),
    })
        .then(checkAuth)
        .then(getJson)
        .then(checkError),

    fetchPowerSuppliesLastSituations: () => dispatch => PowerSupplyAction.promisePowerSuppliesLastSituation()
        .then((json = []) => {
            dispatch(PowerSupplyAction.receivePowerSupplyLastSituations(json))
        }),

    receivePowerSupplyPictures(powerSupplyPictures) {
        return {
            type: RECEIVE_POWER_SUPPLY_PICTURES,
            powerSupplyPictures,
        }
    },

    fetchPowerSupplyPictures(id) {
        return (dispatch) => {
            return fetch(ApplicationConf.materiel.powerSupplyPicture(id), {
                method: 'GET',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then((json = []) => {
                    dispatch(PowerSupplyAction.receivePowerSupplyPictures(json))
                })
                .catch((err) => {
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.pictures} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError + i18n.pictures))
                })
        }
    },

    receivePowerSupplyFiles(powerSupplyFiles) {
        return {
            type: RECEIVE_POWER_SUPPLY_FILES,
            powerSupplyFiles,
        }
    },

    fetchPowerSupplyFiles(id) {
        return (dispatch) => {
            return fetch(ApplicationConf.materiel.powerSupplyFile(id), {
                method: 'GET',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then((json = []) => {
                    dispatch(PowerSupplyAction.receivePowerSupplyFiles(json))
                })
                .catch((err) => {
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.files} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError + i18n.files))
                })
        }
    },

    resetPowerSupply() {
        return {
            type: RESET_POWER_SUPPLY,
        }
    },
}

export default PowerSupplyAction
