import { Dialog } from '@mui/material'
import Icon from 'components/icon/Icon'
import { DialogContentMUI, DialogTitleMUI } from 'components/styled/Dialog'
import PropTypes from 'prop-types'
import React from 'react'
import i18n from 'simple-react-i18n'

export default function ModalSynthese({ open, toggleModal, push }) {
    const handleClose = () => {
        push()
        toggleModal()
    }

    return (
        <Dialog
            fullWidth
            maxWidth='md'
            open={open}
        >
            <DialogTitleMUI style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                Déclaration terminée
                <Icon style={{ color: 'white' }} size='small' icon='close' onClick={handleClose} />
            </DialogTitleMUI>
            <DialogContentMUI>
                <p id='modal-text'>
                    La synthèse de votre déclaration est disponible dans "Mes documents".
                </p>
                <p id='modal-description' className='italic-label'>{i18n.contactAdmin}</p>
            </DialogContentMUI>
        </Dialog>
    )
}

ModalSynthese.propTypes = {
    open: PropTypes.bool.isRequired,
    toggleModal: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
}
