import JobAction from 'import/actions/JobAction'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Outlet } from 'react-router-dom'
import i18n from 'simple-react-i18n'
import SieauAction from '../../../components/sieau/SieauAction'
import { arrayOf } from '../../../utils/StoreUtils'
import DtoJob from '../../dto/DtoJob'
import DtoJobExecution from '../../dto/DtoJobExecution'
import DtoJobFile from '../../dto/DtoJobFile'

const incrementFiles = 500

class JobContentApp extends Component {
    componentWillMount() {
        if (parseInt(this.props.params.id) !== 0) {
            this.props.fetchFullJob(this.props.params.id, 10, false, incrementFiles)
        }
    }

    componentDidMount() {
        if (this.props.params.id) {
            this.setTitle(this.props)
        }
    }

    getJobTitle = (id = '0', job = {}) => {
        if (id !== '0') {
            return job.name || `${i18n.job} - ${id}`
        }
        return i18n.newJob
    }

    setTitle = props => {
        this.props.setTitle('title', [
            {
                title: i18n.jobs,
                href: 'job',
            },
            {
                title: this.getJobTitle(props.params.id, props.job),
                href: `import/${props.params.id}`,
            },
        ])
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.params.id !== nextProps.params.id) {
            this.props.fetchFullJob(nextProps.params.id, 10, false, incrementFiles)
        }
        if ((nextProps.params.id && this.props.params.id !== nextProps.params.id) || (this.props.job && nextProps.job && nextProps.job.name !== this.props.job.name)) {
            this.setTitle(nextProps)
        }
    }

    componentWillUnmount() {
        this.props.receiveJob({})
    }

    render() {
        if ((this.props.job && this.props.job.id) || this.props.params.id == '0') {
            return (
                <div className='row no-margin'>
                    <main>
                        <div className='row no-margin'>
                            <Outlet />
                        </div>
                    </main>
                </div>
            )
        }
        return null
    }
}

JobContentApp.propTypes = {
    params: PropTypes.shape({
        id: PropTypes.string,
    }),
    children: PropTypes.element,
    jobExecutions: PropTypes.arrayOf(PropTypes.instanceOf(DtoJobExecution)),
    job: PropTypes.arrayOf(PropTypes.instanceOf(DtoJob)),
    fetchFullJob: PropTypes.func,
    receiveJob: PropTypes.func,
    setTitle: PropTypes.func,
    jobFiles: arrayOf(DtoJobFile),
}

const mapStateToProps = store => ({
    jobExecutions: store.JobReducer.jobExecutions,
    filter: store.JobReducer.filter,
    job: store.JobReducer.job,
    jobFiles: store.JobReducer.jobFiles,
})

const mapDispatchToProps = {
    fetchFullJob: JobAction.fetchFullJob,
    receiveJob: JobAction.receiveJob,
    setTitle: SieauAction.forceFetch,
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(JobContentApp)
