import React, { useMemo, useState } from 'react'
import { Button, Grid } from '@mui/material'
import { LOCAL_EXPLOITATIONS_FILTERS } from 'agriAdministration/constants/AgriConstants'
import MultiAutocomplete from 'components/forms/MultiAutocomplete'
import { compact, sortBy, uniq } from 'lodash'
import PropTypes from 'prop-types'
import { shallowEqual, useSelector } from 'react-redux'
import CityDto from 'referencial/components/city/dto/CityDto'
import DtoManagementUnit from 'referencial/components/managementUnit/dto/DtoManagementUnit'
import WatershedDto from 'referencial/components/watershed/dto/WatershedDto'
import i18n from 'simple-react-i18n'
import { getLocalStorageJson } from 'utils/FormUtils'
import Card from '../../../components/card/Card'
import Checkbox from '../../../components/forms/Checkbox'
import Input from '../../../components/forms/Input'
import Select from '../../../components/forms/Select'
import Row from '../../../components/react/Row'
import DtoSandreCode from '../../../referencial/dto/DtoSandreCode'
import DtoAquifer from 'referencial/components/aquifers/dto/DtoAquifer'

const emptyFilter = {
    searchValue: '',
    structureTypes: [],
    city: undefined,
    managementUnit: undefined,
    subManagementUnit: undefined,
    watershed: undefined,
    filters: undefined,
    domestic: false,
    agri: false,
    nonAgri: false,
    adjourned: false,
    shut: false,
    sampleType: undefined,
    sampleNature: undefined,
    accompanyingAquiferCode: undefined,
    droughtSector: undefined,
    organism: undefined,
}

const ExploitationsFilterPanel = ({
    initialFilters = {},
    className,
    noMargin = true,
    onValidate = () => {},
    useLocalStorage = true,
}) => {
    const [tmpFilter, setTmpFilter] = useState({ ...(useLocalStorage && getLocalStorageJson(LOCAL_EXPLOITATIONS_FILTERS) || emptyFilter), ...initialFilters })

    const {
        sandreCodes,
        cities,
        managementUnits,
        watersheds,
        aquifers,
        exploitationsExportFullData,
        contributors,
    } = useSelector(store => ({
        sandreCodes: store.ReferencialReducer.sandreCodes,
        cities: store.CityReducer.cities,
        managementUnits: store.ManagementUnitReducer.managementUnits,
        watersheds: store.WatershedReducer.watersheds,
        aquifers: store.AquiferReducer.aquifers,
        exploitationsExportFullData: store.AgriReducer.exploitationsExportFullData,
        contributors: store.ContributorReducer.contributors,
    }), shallowEqual)

    const optionsContributors = useMemo(() => {
        const exploitOrganisms = uniq(compact(exploitationsExportFullData.map((e) => e.organism)))
        return contributors.filter((c) => exploitOrganisms.includes(c.id))
    }, [contributors, exploitationsExportFullData])

    const resetFilters = () => {
        setTmpFilter(emptyFilter)
        onValidate(emptyFilter)
    }

    const { searchValue, structureTypes, city, managementUnit, subManagementUnit, watershed, filters, domestic,
        agri, nonAgri, adjourned, shut, sampleType, sampleNature, accompanyingAquiferCode, droughtSector, organism } = tmpFilter

    const natureOptions = useMemo(() => {
        switch (sampleType) {
            case 0: default:
                return sandreCodes.filter((c) => c.field === 'PREL_AGRI.NATURE_PRELEVEMENT')
            case 1:
                return sandreCodes.filter((c) => c.field === 'PREL_AGRI.NATURE_PRELEVEMENT_ESO')
            case 2:
                return sandreCodes.filter((c) => c.field === 'PREL_AGRI.NATURE_PRELEVEMENT_ESU')
        }
    }, [sampleType, sandreCodes])

    const typesStructure = useMemo(() => sandreCodes.filter((c) => c.field === 'INTERVENANTS.TYPE_STRUCTURE'), [sandreCodes])

    return (
        <Card round className={className} noMargin={noMargin}>
            <Row className='padding-top-1 no-margin' style={{ display: 'flex', alignItems: 'center' }}>
                <Input
                    col={4}
                    containerClassName='no-margin'
                    title={ i18n.search }
                    value={ searchValue }
                    onChange={(v) => setTmpFilter({ ...tmpFilter, searchValue: v })}
                    onEnterKeyPress={() => onValidate(tmpFilter)}
                />
                <Select
                    col={2}
                    style={{ margin: 0 }}
                    label={i18n.filter}
                    options={[{ value: 1, label: i18n.bookmarks }]}
                    value={filters}
                    onChange={(v) => setTmpFilter({ ...tmpFilter, filters: v })}
                    clearFunction
                    integerValue
                />
                <Checkbox
                    col
                    label={i18n.agri}
                    checked={agri}
                    onChange={(v) => setTmpFilter({ ...tmpFilter, agri: v })}
                />
                <Checkbox
                    col
                    label={i18n.nonAgri}
                    checked={nonAgri}
                    onChange={(v) => setTmpFilter({ ...tmpFilter, nonAgri: v })}
                />
                <Checkbox
                    col
                    label={i18n.domestic}
                    checked={domestic}
                    onChange={(v) => setTmpFilter({ ...tmpFilter, domestic: v })}
                />
                <Checkbox
                    col
                    label={i18n.adjourned}
                    checked={adjourned}
                    onChange={(v) => setTmpFilter({ ...tmpFilter, adjourned: v })}
                />
                <Checkbox
                    col
                    label={i18n.shut}
                    checked={shut}
                    onChange={(v) => setTmpFilter({ ...tmpFilter, shut: v })}
                />
            </Row>
            <Row className='padding-top-1'>
                <Select
                    col={2}
                    label={i18n.city}
                    options={cities}
                    value={city}
                    keyLabel='labelWithCode'
                    onChange={(v) => setTmpFilter({ ...tmpFilter, city: v })}
                    clearFunction
                />
                <Select
                    col={2}
                    label={i18n.managementUnit}
                    options={sortBy(managementUnits.filter((u) => !u.parent), 'id')}
                    value={managementUnit}
                    keyValue='managementCode'
                    keyLabel='labelWithCode'
                    onChange={(v) => setTmpFilter({ ...tmpFilter, managementUnit: v, subManagementUnit: null })}
                    clearFunction
                />
                <Select
                    col={2}
                    label={i18n.subManagementUnit}
                    options={ managementUnit ? sortBy(managementUnits.filter((u) => u.parent === parseInt(managementUnit)), 'id') : []}
                    value={subManagementUnit}
                    keyValue='managementCode'
                    keyLabel='labelWithCode'
                    onChange={(v) => setTmpFilter({ ...tmpFilter, subManagementUnit: v })}
                    clearFunction
                />
                <Select
                    col={2}
                    label={i18n.watershed}
                    options={sortBy(watersheds, [e => (e.name || '').toLowerCase()])}
                    value={watershed}
                    keyValue='id'
                    keyLabel='labelWithCode'
                    onChange={(v) => setTmpFilter({ ...tmpFilter, watershed: v })}
                    clearFunction
                    integerValue
                />
                <MultiAutocomplete
                    col={2}
                    label={i18n.structureType}
                    elements={typesStructure}
                    clearFunction
                    values={structureTypes}
                    onChange={(values) => setTmpFilter({ ...tmpFilter, structureTypes: values })}
                />
                <Select
                    id='organism'
                    col={2}
                    label={i18n.organism}
                    value={organism}
                    options={optionsContributors}
                    onChange={v => setTmpFilter({ ...tmpFilter, organism: v })}
                    integerValue
                    keyValue='id'
                    clearFunction
                />
            </Row>
            <Row className='padding-bottom-1 padding-top-1' style={{ display: 'flex' }}>
                <Select
                    id='sampleType'
                    label={i18n.sampleType}
                    col={2}
                    value={sampleType}
                    options={sandreCodes.filter((c) => c.field === 'PREL_AGRI.TYPE_PRELEVEMENT')}
                    onChange={v => setTmpFilter({ ...tmpFilter, sampleType: v, sampleNature: undefined })}
                    integerValue
                    keyValue='code'
                    clearFunction
                />
                <Select
                    id='sampleNature'
                    label={i18n.sampleNature}
                    col={2}
                    value={sampleNature}
                    options={natureOptions}
                    onChange={v => setTmpFilter({ ...tmpFilter, sampleNature: v })}
                    integerValue
                    keyValue='code'
                    clearFunction
                />
                <Select
                    id='accompanyingAquiferCode'
                    col={2}
                    label={i18n.aquifer}
                    value={accompanyingAquiferCode}
                    options={sortBy(aquifers, [e => (e.name || '').toLowerCase()])}
                    onChange={v => setTmpFilter({ ...tmpFilter, accompanyingAquiferCode: v })}
                    integerValue
                    keyValue='code'
                    clearFunction
                />
                <Select
                    id='secteurSecheresse'
                    col={2}
                    label={i18n.droughtSector}
                    value={droughtSector}
                    options={sandreCodes.filter((c) => c.field === 'PREL_AGRI.SECTEUR_SECHERESSE')}
                    onChange={v => setTmpFilter({ ...tmpFilter, droughtSector: v })}
                    integerValue
                    keyValue='code'
                    clearFunction
                />
                <Grid className='col s4 padding-1' container justifyContent='flex-end' alignItems='flex-end'>
                    <Grid item>
                        <Button variant='outlined' onClick={resetFilters}>
                            {i18n.reinit}
                        </Button>
                    </Grid>
                    <Grid item className='padding-left-1'>
                        <Button variant='contained' onClick={() => onValidate(tmpFilter)}>
                            {i18n.search}
                        </Button>
                    </Grid>
                </Grid>
            </Row>
        </Card>
    )
}

ExploitationsFilterPanel.propTypes = {
    className: PropTypes.string,
    noMargin: PropTypes.bool,
    useLocalStorage: PropTypes.bool,
    initialFilters: PropTypes.shape({}),
    sandreCodes: PropTypes.arrayOf(PropTypes.instanceOf(DtoSandreCode)),
    cities: PropTypes.arrayOf(PropTypes.instanceOf(CityDto)),
    onValidate: PropTypes.func.isRequired,
    managementUnits: PropTypes.arrayOf(PropTypes.instanceOf(DtoManagementUnit)),
    watersheds: PropTypes.arrayOf(PropTypes.instanceOf(WatershedDto)),
    aquifers: PropTypes.arrayOf(PropTypes.instanceOf(DtoAquifer)),
}


export default ExploitationsFilterPanel
