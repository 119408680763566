import fetch from 'isomorphic-fetch'
import { RECEIVE_METHOD, RECEIVE_METHODS, RESET_METHOD } from '../constants/MethodConstants'
import ToastrAction from 'toastr/actions/ToastrAction'
import WaitAction from 'wait/WaitAction'
import { checkAuth, checkError, getAuthorization, getJson } from '../../../../utils/ActionUtils'
import ApplicationConf from '../../../../conf/ApplicationConf'
import AppStore from 'store/AppStore'
import i18n from 'simple-react-i18n'
import { push } from '@lagunovsky/redux-react-router'
import { PATH_REFERENCIAL_METHOD, PATH_REFERENCIAL_METHOD_LINK } from '../../../../home/constants/RouteConstants'
import LogAction from '../../../../log/actions/LogAction'

const MethodAction = {
    fetchIfNeeded(prop, fetchFunc, param1) {
        return (dispatch) => {
            const elem = AppStore.getState().MethodReducer[prop]
            if (elem.length) {
                if (elem.length !== 0) {
                    return { type: '' }
                }
            } else if (Object.keys(elem).length !== 0) {
                return { type: '' }
            }
            return dispatch(fetchFunc(param1))
        }
    },
    receiveMethods(methods) {
        return { type: RECEIVE_METHODS, methods }
    },
    fetchSynchroSandreMethod() {
        return (dispatch) => {
            dispatch(WaitAction.waitStart())
            return fetch(ApplicationConf.referencial.updateMethod(), {
                method: 'GET',
                headers: getAuthorization(),

            })
                .then(checkAuth)
                .then(getJson)
                .then((json = {}) => {
                    if (json.insert > 0 || json.update > 0) {
                        if (json.insert > 0) {
                            dispatch(ToastrAction.success(`${i18n.insertNumber} : ${json.insert}<br/>${i18n.updateNumber} : ${json.update}`))
                        } else {
                            dispatch(ToastrAction.success(`${i18n.updateNumber} : ${json.update}`))
                        }
                        dispatch(MethodAction.fetchMethods())
                    } else {
                        dispatch(ToastrAction.success(i18n.noUpdate))
                    }
                    dispatch(WaitAction.waitStop())
                })
                .catch((err) => {
                    dispatch(LogAction.logError(i18n.updateError + i18n.method + err))
                    dispatch(ToastrAction.error(i18n.updateError + i18n.method))
                })
        }
    },
    promiseMethods() {
        return fetch(ApplicationConf.referencial.method(), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
    },
    fetchMethods() {
        return (dispatch) => {
            return MethodAction.promiseMethods()
                .then((json = []) => {
                    dispatch(MethodAction.receiveMethods(json))
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.methods} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError + i18n.methods))
                })
        }
    },
    receiveMethod(method) {
        return { type: RECEIVE_METHOD, method }
    },
    fetchMethod(code) {
        return (dispatch) => {
            return fetch(ApplicationConf.referencial.selectedMethod(code), {
                method: 'GET',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then((json = {}) => {
                    dispatch(MethodAction.receiveMethod(json))
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.method} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError + i18n.method))
                })
        }
    },

    createMethod(method) {
        return (dispatch) => {
            return fetch(ApplicationConf.referencial.method(), {
                method: 'POST',
                headers: getAuthorization(),
                body: JSON.stringify(method),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(json => {
                    if (json.insert) {
                        dispatch(push(PATH_REFERENCIAL_METHOD_LINK + method.code))
                        dispatch(ToastrAction.success(i18n.successfulCreation))
                    }
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.createError + i18n.methods} : ${err}`))
                    dispatch(ToastrAction.error(i18n.createError + i18n.methods))
                })
        }
    },

    updateMethod(method, code) {
        return (dispatch) => {
            return fetch(ApplicationConf.referencial.saveMethod(code), {
                method: 'PUT',
                headers: getAuthorization(),
                body: JSON.stringify(method),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(json => {
                    if (json.update === 1) {
                        dispatch(MethodAction.fetchMethod(code))
                        dispatch(ToastrAction.success(i18n.methodSuccessUpdated))
                    } else {
                        throw new Error()
                    }
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.methods} : ${err}`))
                    dispatch(ToastrAction.error(i18n.updateError + i18n.methods))
                })
        }
    },

    deleteMethod(code) {
        return (dispatch) => {
            dispatch(WaitAction.waitStart())
            return fetch(ApplicationConf.referencial.deleteMethod(code), {
                method: 'DELETE',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then((json) => {
                    if (json.delete) {
                        dispatch(ToastrAction.success(i18n.elementDeleteSuccess))
                        dispatch(push(PATH_REFERENCIAL_METHOD))
                    }
                    dispatch(WaitAction.waitStop())
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.deleteError + i18n.methods} : ${err}`))
                    dispatch(ToastrAction.error(i18n.deleteError + i18n.methods))
                })
        }
    },

    purgeMethod() {
        return (dispatch) => {
            dispatch(WaitAction.waitStart())
            return fetch(ApplicationConf.referencial.purgeMethod(), {
                method: 'DELETE',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then((json) => {
                    if (json.purge || json.purge === 0) {
                        dispatch(ToastrAction.success(`${i18n.elementDeleteSuccess} ${i18n.total} : ${json.purge}`))
                        dispatch(MethodAction.fetchMethods())
                    }
                    dispatch(WaitAction.waitStop())
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.deleteError + i18n.methods} : ${err}`))
                    dispatch(ToastrAction.error(i18n.deleteError + i18n.methods))
                })
        }
    },

    replaceMethod(method, methodReplace) {
        return (dispatch) => {
            dispatch(WaitAction.waitStart())
            return fetch(ApplicationConf.referencial.replaceMethod(), {
                method: 'PUT',
                headers: getAuthorization(),
                body: JSON.stringify({
                    code: method.toString(),
                    replaceCode: methodReplace.toString(),
                }),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(json => {
                    if (json.update) {
                        dispatch(ToastrAction.success(i18n.methodSuccessUpdated))
                    } else if (json.update == 0) {
                        dispatch(ToastrAction.success(`${json.update} ${i18n.replacement}`))
                    } else {
                        throw new Error()
                    }
                    dispatch(WaitAction.waitStop())
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.replace + i18n.method} : ${err}`))
                })
        }
    },

    reset() {
        return { type: RESET_METHOD }
    },
}

export default MethodAction
