import { orderBy, uniqBy } from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import Table from '../../../components/datatable/Table'
import Checkbox from '../../../components/forms/Checkbox'
import DtoDataType from '../../../station/dto/DtoDataType'
import { getHardPiezoDataTypes } from '../../../utils/PiezometryUtils'
import { canShowStationTypeData, getSettingJson } from '../../../utils/SettingUtils'
import { execByType } from '../../../utils/StationUtils'
import { arrayOf } from '../../../utils/StoreUtils'
import SieauParameterDto from '../../dto/SieauParameterDto'


class ShowAssociatedDataRuleTable extends Component {
    getDataTypes = () => execByType(this.props.toStationType, {
        quality: () => [],
        piezometry: () => uniqBy(orderBy([...getHardPiezoDataTypes(), ...this.props.piezoDataTypes ], 'id'), 'id'),
        hydrometry: () => orderBy([...this.props.hydroDataTypes ], 'id'),
        pluviometry: () => orderBy(this.props.pluvioDataTypes, 'id'),
    })

    getTitle = () => execByType(this.props.toStationType, {
        quality: () => i18n.quality,
        piezometry: () => i18n.piezometry,
        hydrometry: () => i18n.hydrometry,
        pluviometry: () => i18n.pluviometry,
    })

    changeRules = (id, newValue) => {
        const rules = getSettingJson(this.props.currentSettings, 'showDataTypesRules') || {}
        const from = rules[this.props.fromStationType] || {}
        const to = from[this.props.toStationType] || {}

        const newData = { ...to, [id]: newValue }
        const dtKeys = Object.keys(newData).filter(k => k !== 'all')

        const newTo = (() => {
            if (id === 'all' && newValue) {
                return { all: true }
            } else if (dtKeys.length && dtKeys.length === this.getDataTypes().length && dtKeys.every(k => newData[k] === false)) {
                return { all: false }
            }
            return newData
        })()

        const newFrom = { ...from, [this.props.toStationType]: newTo }
        const newRules = { ...rules, [this.props.fromStationType]: newFrom }

        this.props.onChangeRules(JSON.stringify(newRules))
    }

    render() {
        const rules = getSettingJson(this.props.currentSettings, 'showDataTypesRules') || {}
        const showAll = canShowStationTypeData(this.props.fromStationType, this.props.toStationType, 'all', rules)
        const data = !showAll ? [] : this.getDataTypes().map(t => ({
            show: <Checkbox checked={canShowStationTypeData(this.props.fromStationType, this.props.toStationType, t.id, rules)} onChange={ v => this.changeRules(t.id, v) } disabled={this.props.readMode}/>,
            name: t.name || t.label,
            id: t.id,
        }))

        return (
            <Table
                type={{ headers: ['show', 'id', 'name'] }}
                data={data}
                title={`${i18n.showAdditionalData} : ${this.getTitle()}`}
                condensed
                sortable
                actions={ [{ iconName: showAll ? 'check_box' : 'check_box_outline_blank', onClick: () => this.props.readMode ? null : this.changeRules('all', !showAll) }] }
                className='disabled'
                showNbElements={false}
            />
        )
    }
}

ShowAssociatedDataRuleTable.propTypes = {
    currentSettings: arrayOf(SieauParameterDto),
    fromStationType: PropTypes.string,
    toStationType: PropTypes.string,
    onChangeRules: PropTypes.func,
    piezoDataTypes: arrayOf(DtoDataType),
    hydroDataTypes: arrayOf(DtoDataType),
    pluvioDataTypes: arrayOf(DtoDataType),
    readMode: PropTypes.bool,
}

const mapStateToProps = (store) => ({
    piezoDataTypes: store.StationReducer.piezoDataTypes,
    hydroDataTypes: store.StationReducer.hydroDataTypes,
    pluvioDataTypes: store.StationReducer.pluvioDataTypes,
})

export default connect(mapStateToProps)(ShowAssociatedDataRuleTable)