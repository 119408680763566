import ReferencialDto from '../../../dto/ReferencialDto'

export default class ContactItem extends ReferencialDto {
    constructor(object) {
        super(object)
        this.code = Number(object.id)
        this.cityCode = object.cityCode
        this.city = object.cityCode
        this.phoneTel = object.phoneTel
        this.desktopTel = object.desktopTel
        this.mobile = object.mobile
        this.birthdate = object.birthdate
        this.socialReason = object.socialReason
        this.postalCode = object.postalBox
        this.postalBox = object.postalBox
        this.address = object.address
        this.additionalAddress = object.additionalAddress
        this.email = object.email
        this.nullValue = object.picto
        this.noNewsLetterDate = object.noNewsLetterDate
        this.noNewLetterLogin = object.noNewLetterLogin
        this.x = object.x // Option[Double] = None
        this.y = object.y // Option[Double] = None
        this.projection = object.projection // Option[Double] = None
        this.headers = ['nullValue', 'name', 'postalCode', 'city', 'phoneTel', 'desktopTel', 'mobile', 'email', 'socialReason']
    }
}