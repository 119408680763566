import { isNull } from 'lodash'

export default class DtoEnvironmentalConditionLight {
    constructor(obj) {
        const envCon = obj.map(e => isNull(e) ? undefined : e)
        this.qualitometer = envCon[0] // Double
        this.operation = envCon[1] // Double
        this.id = envCon[2] // Double
        this.sampleDate = envCon[3] // Option[DateTime]
        this.date = envCon[4] // Option[DateTime]
        this.parameter = envCon[5] // Option[String]
        this.unit = envCon[6] // Option[String]
        this.result = envCon[7] // Option[Double]
        this.method = envCon[8] // Option[String]
        this.qualification = envCon[9] // Option[String]
        this.status = envCon[10] // Option[String]
    }
}