export default class DtoTideGaugeData {
    constructor(obj = {}) {
        this.id = obj.id // Long,
        this.date = obj.date // Option[DateTime],
        this.morningPmHour = obj.morningPmHour // Option[DateTime],
        this.morningPmHeight = obj.morningPmHeight // Option[Float],
        this.morningBmHour = obj.morningBmHour // Option[DateTime],
        this.morningBmHeight = obj.morningBmHeight // Option[Float],
        this.eveningPmHour = obj.eveningPmHour // Option[DateTime],
        this.eveningPmHeight = obj.eveningPmHeight // Option[Float],
        this.eveningBmHour = obj.eveningBmHour // Option[DateTime],
        this.eveningBmHeight = obj.eveningBmHeight // Option[Float],
        this.morningCoeff = obj.morningCoeff // Option[Int],
        this.eveningCoeff = obj.eveningCoeff // Option[Int],
        this.jobExecutionId = obj.jobExecutionId // Option[Long]
    }
}