import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import { flatten, isEqual, sortBy } from 'lodash'
import Card from '../../../components/card/Card'
import InstallationsListPanelsOptions from './InstallationsListPanelsOptions'
import InstallationAction from '../../actions/InstallationAction'
import { arrayOf } from '../../../utils/StoreUtils'
import DtoInstallationType from '../../dto/installation/DtoInstallationType'
import Row from '../../../components/react/Row'
import SieauAction from '../../../components/sieau/SieauAction'
import ActionComponent from '../../../components/ActionComponent'
import { DEFAULT_PANEL_OPTION, PANELS_OPTIONS } from '../../constants/InstallationConstants'
import AdministrationAction from '../../../administration/actions/AdministrationAction'
import { componentHasHabilitations } from '../../../utils/HabilitationUtil'
import { H_INSTALLATION_OPTIONS } from '../../../account/constants/AccessRulesConstants'
import { push } from '@lagunovsky/redux-react-router'
import ShowAssociatedDataRuleTable from '../../../administration/components/dataTypes/ShowAssociatedDataRuleTable'
import StationAction from '../../../station/actions/StationAction'
import { getSetting } from '../../../utils/SettingUtils'

class InstallationsOptions extends ActionComponent {
    state = {
        readMode: true,
        installationsTypes: [],
        panels: [],
        applicationSettings: [],
    }

    componentDidMount() {
        if (!componentHasHabilitations(H_INSTALLATION_OPTIONS)) { // A modifier quand react-router sera à jour
            this.props.push('/unauthorized')
            return
        }
        const { installationsTypes } = this.props
        if (!installationsTypes.length) {
            this.props.fetchInstallationTypes()
        }
        this.props.fetchDataTypesByProject('SIES')
        this.setState({ applicationSettings: this.props.applicationSettings })
        this.setTitle()
        this.setReadMode()
        $('.sieau-collapsible').collapsible()
    }

    componentDidUpdate(_, prevState) {
        const { installationsTypes } = this.props
        if (!this.state.installationsTypes.length && installationsTypes.length) {
            this.setState({ installationsTypes })
        }

        if (!isEqual(this.state.installationsTypes, prevState.installationsTypes)
            || !isEqual(this.state.applicationSettings, prevState.applicationSettings)) {
            this.setPanels()
        }
    }

    setPanels = () => {
        const { installationsTypes, applicationSettings } = this.state

        const panels = sortBy(installationsTypes.map(type => {
            const definedType = PANELS_OPTIONS.find(panel => panel.code === type.code) || { components: DEFAULT_PANEL_OPTION }
            return {
                ...type,
                components: definedType.components,
            }
        }), 'code')

        const dataPanels = panels.map(panel => ({
            ...panel,
            components: panel.components.map((component) => {
                const paramCode = `PANEL_${panel.code}_${component.type}`
                const param = applicationSettings.find(as => as.parameter === paramCode) || {}
                return {
                    ...component,
                    parameter: paramCode,
                    hide: panel.display ? param.value === 'hidden' : true,
                }
            }),
        }))

        this.setState({ panels: dataPanels })
    }

    setTitle = () => {
        this.props.forceFetch('title', [{
            title: i18n.installation,
            href: 'installation',
        }, {
            title: i18n.installationsOptions,
            href: 'installation/installationOptions',
        },
        ])
    }

    setReadMode = () => {
        this.setState({ readMode: true })
        this.setActions({
            export: () => this.onExport(),
            edit: this.setEditMode,
        })
    }

    setEditMode = () => {
        this.setState({ readMode: false })
        this.setActions({
            cancel: this.onCancel,
            export: this.onExport,
            save: () => this.onSave(),
        })
    }

    onCancel = () => {
        const { installationsTypes, applicationSettings } = this.props
        this.setState({ installationsTypes, applicationSettings })
        this.setReadMode()
    }

    onExport = () => {
        const data = flatten(this.state.panels.map(panel => {
            return panel.components.map(component => ({
                panelName: panel.name,
                panelVisible: panel.display,
                componentName: component.label,
                componentVisible: !component.hide,
            }))
        }))
        const headers = ['panelName', 'panelVisible', 'componentName', 'componentVisible']

        const exported = [
            { headers },
            ...data,
        ]

        return ({
            data: exported,
            exportType: 'xlsx',
            titleFile: i18n.displayPanelSettings,
        })
    }

    onSave = () => {
        const { installationsTypes } = this.state
        const updateInstallationsTypes = installationsTypes.filter(it => it.isChanged)

        if (updateInstallationsTypes.length) {
            this.props.updateInstallationsTypes(updateInstallationsTypes).then(() => {
                this.props.fetchInstallationTypes()
            })
        }

        const applicationSettings = this.state.applicationSettings.filter(e => e.value !== getSetting(this.props.applicationSettings, e.parameter))
        if (applicationSettings.length) {
            this.props.updateSieauParameters(applicationSettings)
        }

        this.setReadMode()
    }

    onChange = (value) => {
        this.setState({ ...value })
    }

    onChangeSetting = (setting, value) => {
        const stateObj = this.state.applicationSettings.filter(e => e.parameter !== setting)
        this.setState({ applicationSettings: [...stateObj, { parameter: setting, value: value ? value.toString() : value }] })
    }

    render() {
        const { readMode, panels, applicationSettings, installationsTypes } = this.state
        return (
            <Row className='padding-top-1'>
                <Card noMargin={false} className='margin-top-1 flow-root' maxWidth='1000'>
                    <InstallationsListPanelsOptions
                        panels={panels}
                        readMode={readMode}
                        onChange={this.onChange}
                        applicationSettings={applicationSettings}
                        installationsTypes={installationsTypes}
                    />
                </Card>
                <div className='padding-top-1' />
                <ShowAssociatedDataRuleTable
                    currentSettings={applicationSettings}
                    fromStationType='INST'
                    toStationType='PIEZO'
                    onChangeRules={ rules => this.onChangeSetting('showDataTypesRules', rules) }
                    readMode={readMode}
                />
                <div className='padding-top-1' />
                <ShowAssociatedDataRuleTable
                    currentSettings={applicationSettings}
                    fromStationType='INST'
                    toStationType='QUALITO'
                    onChangeRules={ rules => this.onChangeSetting('showDataTypesRules', rules) }
                    readMode={readMode}
                />
                <div className='padding-top-1' />
                <ShowAssociatedDataRuleTable
                    currentSettings={applicationSettings}
                    fromStationType='INST'
                    toStationType='HYDRO'
                    onChangeRules={ rules => this.onChangeSetting('showDataTypesRules', rules) }
                    readMode={readMode}
                />
                <div className='padding-top-1' />
                <ShowAssociatedDataRuleTable
                    currentSettings={applicationSettings}
                    fromStationType='INST'
                    toStationType='PLUVIO'
                    onChangeRules={ rules => this.onChangeSetting('showDataTypesRules', rules) }
                    readMode={readMode}
                />
                <div className='padding-top-9' />
            </Row>

        )
    }
}

InstallationsOptions.propTypes = {
    installationsTypes: arrayOf(DtoInstallationType),
    forceFetch: PropTypes.func,
    fetchInstallationTypes: PropTypes.func,
    updateInstallationsTypes: PropTypes.func,
    updateParameters: PropTypes.func,
    fetchApplicationSettings: PropTypes.func,
    push: PropTypes.func,
    fetchDataTypesByProject: PropTypes.func,
    updateSieauParameters: PropTypes.func,
}

const mapStateToProps = store => ({
    installationsTypes: store.InstallationReducer.installationsTypes,
    applicationSettings: store.AdministrationReducer.applicationSettings,
})

const mapDispatchToProps = {
    forceFetch: SieauAction.forceFetch,
    fetchInstallationTypes: InstallationAction.fetchInstallationTypes,
    updateInstallationsTypes: InstallationAction.updateInstallationsTypes,
    fetchApplicationSettings: AdministrationAction.fetchApplicationSettings,
    updateParameters: AdministrationAction.updateParameters,
    fetchDataTypesByProject: StationAction.fetchDataTypesByProject,
    updateSieauParameters: AdministrationAction.updateSieauParameters,
    push,
}

export default connect(mapStateToProps, mapDispatchToProps)(InstallationsOptions)
