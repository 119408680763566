import { orderBy, template } from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import Card from '../../../../components/card/Card'
import MessageCard from '../../../../components/card/MessageCard'
import Table from '../../../../components/datatable/Table'
import DtoJob from '../../../../import/dto/DtoJob'
import QualityExecutionDto from '../../../../import/dto/QualityExecutionDto'
import { getFullDateMini, getHour } from '../../../../utils/DateUtil'


class JobTab extends Component {
    jobExecutionCard = exec => {
        const job = this.props.qualityJobs.find(({ id }) => id === exec.jobId) || {}
        const formatExec = {
            status: exec.status ? i18n.success : i18n.error,
            begin: getHour(exec.date),
            end: getHour(exec.date + exec.duration),
            stations: `${exec.stations}`,
            operations: `${exec.operations}`,
            analysis: `${exec.analysis}`,
            parameter: `${exec.parameters}`,
            errors: `${exec.errors.length}`,
            warnings: `${exec.warnings.length}`,
            comment: exec.comment || '',
        }
        return (
            <Card title={`${job.name ? `${job.name}: ` : ''}${template(i18n.executionOf)({ date: getFullDateMini(exec.date) })} [${exec.id}]` } className={'padding-bottom-1'}>
                <Table
                    data={[formatExec]}
                    type={{ headers: ['status', 'begin', 'end', 'stations', 'operations', 'analysis', 'parameter', 'errors', 'warnings', 'comment'] }}
                    condensed
                    sortable
                    showTitle={false}
                />
                {/*{*/}
                {/*    !!exec.files.length && (*/}
                {/*        <div>*/}
                {/*            <div style={{ backgroundColor: '#B6E1FE', paddingLeft: '14px' }}>*/}
                {/*                {i18n.files}*/}
                {/*            </div>*/}
                {/*            <Table*/}
                {/*                data={exec.files}*/}
                {/*                type={{ headers: ['value', 'comment'] }}*/}
                {/*                condensed*/}
                {/*                showTitle={false}*/}
                {/*            />*/}
                {/*        </div>*/}
                {/*    )*/}
                {/*}*/}
                {
                    !!exec.errors.length && (
                        <div>
                            <div style={{ backgroundColor: 'red', paddingLeft: '14px', color: 'white' }}>
                                {i18n.errors}
                            </div>
                            <Table
                                data={exec.errors}
                                type={{ headers: ['value', 'comment'] }}
                                condensed
                                showTitle={false}
                            />
                        </div>
                    )
                }
                {
                    !!exec.warnings.length && (
                        <div>
                            <div style={{ backgroundColor: 'orange', paddingLeft: '14px' }}>
                                {i18n.warnings}
                            </div>
                            <Table
                                data={exec.warnings}
                                type={{ headers: ['value', 'comment'] }}
                                condensed
                                showTitle={false}
                            />
                        </div>
                    )
                }
            </Card>
        )
    }

    render = () => {
        const {
            jobId,
            jobStartDate,
            jobEndDate,
            jobExecutionId,
        } = this.props.filter
        if (jobId || jobExecutionId) {
            if (this.props.qualityExecutions.length) {
                const sortExecution = orderBy(this.props.qualityExecutions, 'date', 'desc')
                return (
                    <div>
                        {sortExecution.map(exec => this.jobExecutionCard(exec))}
                    </div>
                )
            }
            return <MessageCard>{i18n.noJobAvailableForTheseCriteria}</MessageCard>
        }
        return <MessageCard>{i18n.pleaseSelectJobAndDate}</MessageCard>
    }
}

JobTab.propTypes = {
    filter: PropTypes.shape({
        job: PropTypes.string,
        jobStartDate: PropTypes.number,
        jobEndDate: PropTypes.number,
        jobExecutionId: PropTypes.number,
    }),
    qualityJobs: PropTypes.arrayOf(PropTypes.instanceOf(DtoJob)),
    qualityExecutions: PropTypes.arrayOf(PropTypes.instanceOf(QualityExecutionDto)),
}

const mapStateToProps = store => ({
    qualityJobs: store.JobReducer.qualityJobs,
    qualityExecutions: store.JobReducer.qualityExecutions,
})

export default connect(mapStateToProps)(JobTab)