import { Button, Grid } from '@mui/material'
import { H_TERRITORY_RSEAU } from 'account/constants/AccessRulesConstants'
import { volumesToShow } from 'agriAdministration/constants/AgriConstants'
import NumberField from 'components/forms/NumberField'
import { StyledFieldSet, StyledLegend } from 'components/StyledElements'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import i18n from 'simple-react-i18n'
import { componentHasHabilitations } from 'utils/HabilitationUtil'
import Select from '../../../components/forms/Select'
import SimpleDatePicker from '../../../components/forms/SimpleDatePicker'
import Row from '../../../components/react/Row'

class TerritoryFilterPanel extends Component {
    constructor(props) {
        super(props)
        this.state = {
            tmpFilter: { ...props.filter },
        }
    }

    onChange = (value) => {
        this.setState(({ tmpFilter }) => ({ tmpFilter: { ...tmpFilter, ...value } }))
    }

    onChangeRadio = (value) => {
        this.onChange({
            showWatersheds: false,
            showManagementUnits: false,
            showCities: false,
            showPtsPrel: false,
            ...value,
        })
    }

    onChangeVolumes = (v) => {
        const { tmpFilter } = this.state
        const volumesShow = v === 'irrigEfficiency' ? {
            showWatersheds: false,
            showManagementUnits: true,
            showCities: false,
            showPtsPrel: false,
        } : {}
        this.onChange({
            volumesShow: v,
            dateShow: tmpFilter.dateShow,
            dateShowEnd: tmpFilter.dateShowEnd,
            ...volumesShow,
        })
    }

    onValidate = () => {
        const { tmpFilter } = this.state
        this.props.onValidate(tmpFilter)
    }

    render() {
        const { tmpFilter: { volumesShow, dateShow, dateShowEnd, showWatersheds, showCities,
            showManagementUnits, showPtsPrel, pointsType, scenarioYear, stepTime } } = this.state
        const disableRadio = volumesShow === 'irrigEfficiency'
        return (
            <div className='row no-margin'>
                <StyledFieldSet>
                    <StyledLegend>{i18n.displayCriterias}</StyledLegend>
                    <Row>
                        <Select
                            col={6}
                            label={i18n.volumesShow}
                            value={volumesShow || 'asked'}
                            options={componentHasHabilitations(H_TERRITORY_RSEAU) ? volumesToShow : volumesToShow.filter((v) => v.value !== 'irrigEfficiency')}
                            onChange={v => this.onChangeVolumes(v)}
                            noNullValue
                        />
                        <SimpleDatePicker
                            col={3}
                            value={dateShow || moment()}
                            label={i18n.startDate}
                            onChange={v => this.onChange({ dateShow: v })}
                            max={dateShowEnd}
                        />
                        <SimpleDatePicker
                            col={3}
                            value={dateShowEnd || moment()}
                            label={i18n.endDate}
                            onChange={v => this.onChange({ dateShowEnd: v })}
                            min={dateShow}
                        />
                    </Row>
                    {volumesShow === 'irrigEfficiency' && (
                        <Row>
                            <NumberField
                                col={6}
                                title={i18n.scenario}
                                value={scenarioYear}
                                onChange={v => this.onChange({ scenarioYear: v })}
                                placeholder={'ex: 2018'}
                                obligatory
                            />
                            <Select
                                col={6}
                                label={i18n.stepTime}
                                options={[{
                                    value: 1,
                                    label: i18n.daily,
                                }, {
                                    value: 2,
                                    label: i18n.weekly,
                                }]}
                                noNullValue
                                onChange={v => this.onChangeRadio({ stepTime: v })}
                                value={stepTime || 2}
                                disabled={disableRadio}
                                integerValue
                            />
                        </Row>
                    )}
                    <Row noMargin={false}>
                        <div
                            className='col'
                            onClick={disableRadio ?
                                () => {} :
                                () => this.onChangeRadio({ showWatersheds: !showWatersheds })
                            }
                        >
                            <input
                                className='sieau-input'
                                type='radio'
                                onChange={() => {}}
                                id='showWatersheds'
                                checked={showWatersheds}
                                disabled={disableRadio}
                            />
                            <label htmlFor='showWatersheds' className='black-text'>{i18n.showWatersheds}</label>
                        </div>
                    </Row>
                    <Row noMargin={false}>
                        <div
                            className='col'
                            onClick={disableRadio ?
                                () => {} :
                                () => this.onChangeRadio({ showManagementUnits: !showManagementUnits })
                            }
                        >
                            <input
                                className='sieau-input'
                                type='radio'
                                onChange={() => {}}
                                id='showManagementUnits'
                                checked={showManagementUnits}
                                disabled={disableRadio}
                            />
                            <label htmlFor='showManagementUnits' className='black-text'>{i18n.showManagementUnits}</label>
                        </div>
                    </Row>
                    <Row noMargin={false}>
                        <div
                            className='col'
                            onClick={disableRadio ?
                                () => {} :
                                () => this.onChangeRadio({ showCities: !showCities })
                            }
                        >
                            <input
                                className='sieau-input'
                                type='radio'
                                onChange={() => {}}
                                id='showCities'
                                checked={showCities}
                                disabled={disableRadio}
                            />
                            <label htmlFor='showCities' className='black-text'>{i18n.showCities}</label>
                        </div>
                    </Row>
                    <Row noMargin={false} style={{ display: 'flex', alignItems: 'center' }}>
                        <div
                            className='col s8'
                            onClick={disableRadio ?
                                () => {} :
                                () => this.onChangeRadio({ showPtsPrel: !showPtsPrel })
                            }
                        >
                            <input
                                className='sieau-input'
                                type='radio'
                                onChange={() => {}}
                                id='showPtsPrel'
                                checked={showPtsPrel}
                                disabled={disableRadio}
                            />
                            <label htmlFor='showPtsPrel' className='black-text'>{i18n.showPtsPrel}</label>
                        </div>
                        <Select
                            col={4}
                            label={i18n.type}
                            options={[{
                                value: 1,
                                label: i18n.all,
                            }, {
                                value: 2,
                                label: i18n.superficial,
                            }, {
                                value: 3,
                                label: i18n.underground,
                            }]}
                            noNullValue
                            onChange={v => this.onChangeRadio({ showPtsPrel: true, pointsType: v })}
                            value={pointsType || 1}
                            disabled={disableRadio}
                            integerValue
                        />
                    </Row>
                    <Grid container justifyContent='flex-end'>
                        <Button variant='contained' onClick={ this.onValidate }>
                            {i18n.refresh}
                        </Button>
                    </Grid>
                </StyledFieldSet>
            </div>
        )
    }
}

TerritoryFilterPanel.propTypes = {
    filter: PropTypes.shape({
        volumesShow: PropTypes.number,
        dateShow: PropTypes.number,
        showWatersheds: PropTypes.bool,
        showManagementUnits: PropTypes.bool,
        showCities: PropTypes.bool,
        showPtsPrel: PropTypes.bool,
        takeWaterTanks: PropTypes.bool,
    }),
    onValidate: PropTypes.func,
}

export default TerritoryFilterPanel