/* eslint-disable camelcase */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import Table from '../../../../components/datatable/Table'
import { exportFile } from '../../../../utils/ExportDataUtil'
import { formatMilliers } from '../../../../utils/StringUtil'
import DtoManagementUnit from '../dto/DtoManagementUnit'
import VolumesManagementUnitDialog from './VolumesManagementUnitDialog'
import ToastrAction from 'toastr/actions/ToastrAction'
import ManagementUnitAction from '../actions/ManagementUnitAction'
import { connect } from 'react-redux'
import { orderBy } from 'lodash'
import { hasValue } from '../../../../utils/NumberUtil'

const headersTab = ['nullValue', 'year', 'yearEnd', 'volume', 'volumePrefectural', 'volumeLowWater', 'volumeExceptLowWater']
const additionalHeadersTab = [ ...headersTab, 'january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december', 'total', 'volumeLowWaterSup', 'volumeLowWaterSout', 'volumeExceptLowWaterSup', 'volumeExceptLowWaterSout']

class TableManagementUnitVolumes extends Component {
    constructor(props) {
        super(props)
        this.state = {
            managementUnit: {},
            linkAUPToUpdate: {},
            linkAUPItem: {},
            openDialogVolumes: false,
        }
    }

    componentDidMount() {
        const { managementUnit } = this.props
        this.setState({ managementUnit, linkAUPToUpdate: managementUnit.link_aup })
    }

    getValue = (value) => {
        return hasValue(value) ? value : 0
    }

    getVolumesYearsTab = () => {
        const { isEdit } = this.props
        const { managementUnit } = this.state
        const linkAUPOrdered = orderBy(managementUnit.link_aup, 'year', 'desc')
        return managementUnit.link_aup ? linkAUPOrdered.map((aup) => ({
            nameUge: `[${managementUnit.id}] ${managementUnit.name}`,
            year: aup.year,
            yearEnd: aup.yearEnd,
            volume: hasValue(aup.aup) ? `${formatMilliers(aup.aup)} m3` : '',
            volumePrefectural: hasValue(aup.aupPrefectural) ? `${formatMilliers(aup.aupPrefectural)} m3` : '',
            volumeLowWater: hasValue(aup.aupLowWater) ? `${formatMilliers(aup.aupLowWater)} m3` : '',
            volumeExceptLowWater: hasValue(aup.aupExceptLowWater) ? `${formatMilliers(aup.aupExceptLowWater)} m3` : '',
            january: hasValue(aup.month01) ? `${formatMilliers(aup.month01)} m3` : '',
            february: hasValue(aup.month02) ? `${formatMilliers(aup.month02)} m3` : '',
            march: hasValue(aup.month03) ? `${formatMilliers(aup.month03)} m3` : '',
            april: hasValue(aup.month04) ? `${formatMilliers(aup.month04)} m3` : '',
            may: hasValue(aup.month05) ? `${formatMilliers(aup.month05)} m3` : '',
            june: hasValue(aup.month06) ? `${formatMilliers(aup.month06)} m3` : '',
            july: hasValue(aup.month07) ? `${formatMilliers(aup.month07)} m3` : '',
            august: hasValue(aup.month08) ? `${formatMilliers(aup.month08)} m3` : '',
            september: hasValue(aup.month09) ? `${formatMilliers(aup.month09)} m3` : '',
            october: hasValue(aup.month10) ? `${formatMilliers(aup.month10)} m3` : '',
            november: hasValue(aup.month11) ? `${formatMilliers(aup.month11)} m3` : '',
            december: hasValue(aup.month12) ? `${formatMilliers(aup.month12)} m3` : '',
            volumeLowWaterSup: hasValue(aup.aupLowWaterSup) ? `${formatMilliers(aup.aupLowWaterSup)} m3` : '',
            volumeLowWaterSout: hasValue(aup.aupLowWaterSout) ? `${formatMilliers(aup.aupLowWaterSout)} m3` : '',
            volumeExceptLowWaterSup: hasValue(aup.aupExceptLowWaterSup) ? `${formatMilliers(aup.aupExceptLowWaterSup)} m3` : '',
            volumeExceptLowWaterSout: hasValue(aup.aupExceptLowWaterSout) ? `${formatMilliers(aup.aupExceptLowWaterSout)} m3` : '',
            total: `${this.getValue(aup.month01) + this.getValue(aup.month02) + this.getValue(aup.month03) + this.getValue(aup.month04) + this.getValue(aup.month05) + this.getValue(aup.month06) + this.getValue(aup.month07) + this.getValue(aup.month08) + this.getValue(aup.month09) + this.getValue(aup.month10) + this.getValue(aup.month11) + this.getValue(aup.month12)} m3`,
            nullValue: isEdit ? (<i className='material-icons no-display right' style={{ cursor: 'pointer' }} onClick={() => this.setState({ openDialogVolumes: true, linkAUPToUpdate: aup, linkAUPItem: aup })}>edit</i>) : '',
        })) : []
    }

    getExportDataVolumes = (volumesYearsTab) => {
        return volumesYearsTab.map((vyt, i) => {
            return i === 0 ? { headers: headersTab, ...vyt } : vyt
        })
    }

    getExportDataVolumesAdditional = (volumesYearsTab) => {
        return volumesYearsTab.map((vyt, i) => {
            return i === 0 ? { headers: additionalHeadersTab, ...vyt } : vyt
        })
    }

    validVolumesUpdate = () => {
        const { managementUnit, linkAUPToUpdate, linkAUPItem } = this.state
        let newMu = {}
        const years = managementUnit.link_aup.map((aup) => aup.year)
        if (years.includes(linkAUPItem.year)) {
            const newLinksAup = managementUnit.link_aup.map((aup) => {
                return aup.year == linkAUPItem.year ? linkAUPToUpdate : aup
            })
            newMu = {
                ...managementUnit,
                link_aup: newLinksAup,
            }
        } else {
            const linkAUP = {
                managementCode: managementUnit.managementCode,
                ...linkAUPToUpdate,
            }
            const newLinksAup = [
                ...managementUnit.link_aup,
                linkAUP,
            ]
            newMu = {
                ...managementUnit,
                link_aup: newLinksAup,
            }
        }

        if (newMu.link_aup) {
            this.props.changeManagementUnit(newMu)
            this.setState({ managementUnit: newMu, openDialogVolumes: false })
        } else {
            this.props.toastrError(i18n.volumeYearError)
        }
    }

    exportVolumes = (volumes) => {
        exportFile(
            {
                data: this.getExportDataVolumes(volumes),
                exportType: 'xlsx',
                titleFile: i18n.volumeByYears,
            },
            null,
            {
                data: this.getExportDataVolumesAdditional(volumes),
                exportType: 'xlsx',
                titleFile: i18n.volumeByYears,
            },
            true
        )
    }

    getDialogVolumes = () => {
        const { openDialogVolumes, linkAUPToUpdate } = this.state
        return openDialogVolumes && (
            <VolumesManagementUnitDialog
                openDialog={openDialogVolumes}
                validUpdate={this.validVolumesUpdate}
                onChange={(v) => this.setState({ linkAUPToUpdate: { ...linkAUPToUpdate, ...v } })}
                linkAUPToUpdate={linkAUPToUpdate}
                closeDialog={() => this.setState({ openDialogVolumes: false })}
            />
        )
    }

    render() {
        const { isEdit } = this.props
        const volumesYearsTab = this.getVolumesYearsTab()
        const addAction = isEdit ? [{
            iconName: 'note_add',
            tooltip: i18n.add,
            onClick: () => this.setState({ openDialogVolumes: true, linkAUPToUpdate: {}, linkAUPItem: {} }),
        }] : []

        return (
            <div className='row margin-top-2'>
                <div className='card row no-margin'>
                    <Table
                        title={i18n.volumeByYears}
                        condensed sortable
                        data={volumesYearsTab}
                        type={{ headers: headersTab }}
                        actions={[{
                            iconName: 'file_download',
                            tooltip: i18n.export,
                            onClick: () => this.exportVolumes(volumesYearsTab),
                        }, ...addAction]}
                    />
                    {this.getDialogVolumes()}
                </div>
            </div>
        )
    }
}

TableManagementUnitVolumes.propTypes = {
    isEdit: PropTypes.bool,
    managementUnit: PropTypes.instanceOf(DtoManagementUnit),
    fetchManagementUnits: PropTypes.func,
    updateManagementUnit: PropTypes.func,
    changeManagementUnit: PropTypes.func,
    toastrError: PropTypes.func,
}

const mapDispatchToProps = {
    fetchManagementUnits: ManagementUnitAction.fetchManagementUnits,
    updateManagementUnit: ManagementUnitAction.updateManagementUnit,
    toastrError: ToastrAction.error,
}

export default connect(null, mapDispatchToProps)(TableManagementUnitVolumes)
