import ApplicationConf from 'conf/ApplicationConf'
import { push } from '@lagunovsky/redux-react-router'
import fetch from 'isomorphic-fetch'
import i18n from 'simple-react-i18n'
import AppStore from 'store/AppStore'
import ToastrAction from 'toastr/actions/ToastrAction'
import WaitAction from 'wait/WaitAction'
import {
    PATH_REFERENCIAL_CONTRIBUTOR,
    PATH_REFERENCIAL_CONTRIBUTOR_LINK,
} from '../../../../home/constants/RouteConstants'
import LogAction from '../../../../log/actions/LogAction'
import {
    checkAuth,
    checkError,
    genericFetch,
    genericPromise,
    getAuthorization,
    getJson,
} from '../../../../utils/ActionUtils'
import {
    DELETE_CONTRIBUTORS_GROUP,
    UPDATE_CONTRIBUTORS_GROUP,
} from '../constants/ContributorConstants'
import { ContributorActionConstant } from '../reducers/ContributorReducer'

const ContributorAction = {
    fetchIfNeeded(prop, fetchFunc, param1) {
        return function (dispatch) {
            const elem = AppStore.getState().ContributorReducer[prop]
            if (elem.length) {
                if (elem.length !== 0) {
                    return { type: '' }
                }
            } else if (Object.keys(elem).length !== 0) {
                return { type: '' }
            }
            return dispatch(fetchFunc(param1))
        }
    },

    fetchContributorsTable(filter = {}) {
        return (dispatch) => {
            return genericPromise(ApplicationConf.referencial.contributorsTable(), 'POST', filter)
                .then((json = []) => {
                    dispatch(ContributorActionConstant.receiveContributorsTable(json))
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.contributors} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError + i18n.contributors))
                })
        }
    },

    updateContributor(contributor, code) {
        return (dispatch) => {
            return fetch(ApplicationConf.referencial.saveContributor(code), {
                method: 'PUT',
                headers: getAuthorization(),
                body: JSON.stringify(contributor),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(json => {
                    if (json.update === 1) {
                        dispatch(ContributorAction.fetchContributor(code))
                        dispatch(ToastrAction.success(i18n.contributorSuccessUpdated))
                    } else {
                        throw new Error()
                    }
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.contributor} : ${err}`))
                })
        }
    },

    deleteContributor(code) {
        return (dispatch) => {
            dispatch(WaitAction.waitStart())
            return fetch(ApplicationConf.referencial.deleteContributor(code), {
                method: 'DELETE',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then((json) => {
                    if (json.delete) {
                        dispatch(ToastrAction.success(i18n.elementDeleteSuccess))
                        dispatch(push(PATH_REFERENCIAL_CONTRIBUTOR))
                    }
                    dispatch(WaitAction.waitStop())
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.deleteError + i18n.contributor} : ${err}`))
                    dispatch(ToastrAction.error(i18n.deleteError + i18n.contributor))
                })
        }
    },

    purgeContributor() {
        return (dispatch) => {
            dispatch(WaitAction.waitStart())
            return fetch(ApplicationConf.referencial.purgeContributor(), {
                method: 'DELETE',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then((json) => {
                    if (json.purge || json.purge === 0) {
                        dispatch(ToastrAction.success(`${i18n.elementDeleteSuccess} ${i18n.total} : ${json.purge}`))
                        dispatch(ContributorAction.fetchContributors(true))
                    }
                    dispatch(WaitAction.waitStop())
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.deleteError + i18n.contributors} : ${err}`))
                    dispatch(ToastrAction.error(i18n.deleteError + i18n.contributors))
                })
        }
    },

    fetchContributor(code) {
        return (dispatch) => {
            return fetch(ApplicationConf.referencial.contributor(code), {
                method: 'GET',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then((json = []) => {
                    dispatch(ContributorActionConstant.receiveContributor(json))
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.contributor} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError + i18n.contributor))
                })
        }
    },

    promiseProducers() {
        return fetch(ApplicationConf.referencial.contributorProducers(), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
    },

    fetchSynchroSandreContributor() {
        return (dispatch) => {
            dispatch(WaitAction.waitStart())
            return fetch(ApplicationConf.referencial.contributorSynchro(), {
                method: 'POST',
                headers: getAuthorization(),

            })
                .then(checkAuth)
                .then(getJson)
                .then((json = {}) => {
                    if (json.insert > 0 || json.update > 0) {
                        dispatch(ToastrAction.success(`${i18n.insertNumber} : ${json.insert}<br/>${i18n.updateNumber} : ${json.update}`))
                        dispatch(ContributorAction.fetchContributors(true))
                    } else {
                        dispatch(ToastrAction.success(i18n.synchroContributorInProgress))
                    }
                    dispatch(WaitAction.waitStop())
                })
                .catch((err) => {
                    dispatch(LogAction.logError(i18n.updateError + i18n.contributor + err))
                    dispatch(ToastrAction.error(i18n.updateError + i18n.contributor))
                })
        }
    },

    fetchProducers() {
        return (dispatch) => {
            return ContributorAction.promiseProducers()
                .then((json = []) => {
                    dispatch(ContributorActionConstant.receiveProducers(json))
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.producers} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError + i18n.producers))
                })
        }
    },

    promiseLaboratories() {
        return fetch(ApplicationConf.referencial.contributorLaboratories(), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
    },

    fetchLaboratories() {
        return (dispatch) => {
            return ContributorAction.promiseLaboratories()
                .then((json = []) => {
                    dispatch(ContributorActionConstant.receiveLaboratories(json))
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.laboratories} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError + i18n.laboratories))
                })
        }
    },

    promiseDeterminators() {
        return fetch(ApplicationConf.referencial.contributorDeterminators(), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
    },

    fetchDeterminators() {
        return (dispatch) => {
            return ContributorAction.promiseDeterminators()
                .then((json = []) => {
                    dispatch(ContributorActionConstant.receiveDeterminators(json))
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.determinators} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError + i18n.determinators))
                })
        }
    },

    fetchContributorDomain() {
        return (dispatch) => {
            return fetch(ApplicationConf.referencial.contributorDomain(), {
                method: 'GET',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then((json = []) => {
                    dispatch(ContributorActionConstant.receiveContributorDomain(json))
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.domain} ${i18n.contributor} : ${err}`))
                    dispatch(ToastrAction.error(`${i18n.fetchError + i18n.domain} ${i18n.contributor}`))
                })
        }
    },

    promiseContributors() {
        return fetch(ApplicationConf.referencial.contributors(), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
    },

    fetchContributors(forceLoad = false) {
        return (dispatch, getState) => {
            const { contributors } = getState().ContributorReducer
            if (contributors.length && !forceLoad) {
                return Promise.resolve()
            }
            return ContributorAction.promiseContributors()
                .then((json = []) => {
                    dispatch(ContributorActionConstant.receiveAllContributors(json))
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.contributors} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError + i18n.contributors))
                })
        }
    },

    fetchContributorTypes() {
        return function (dispatch) {
            return fetch(ApplicationConf.referencial.contributorTypes(), {
                method: 'GET',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then(json => {
                    dispatch(ContributorActionConstant.receiveContributorTypes(json))
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.contributorTypes} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError + i18n.contributorTypes))
                })
        }
    },

    createContributor(contributor, noRedirection) {
        return (dispatch) => {
            return fetch(ApplicationConf.referencial.contributors(), {
                method: 'POST',
                headers: getAuthorization(),
                body: JSON.stringify(contributor),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(json => {
                    if (json.id) {
                        dispatch(ToastrAction.success(i18n.successfulCreation))
                        if (!noRedirection) {
                            dispatch(push(PATH_REFERENCIAL_CONTRIBUTOR_LINK + json.id))
                        }
                        return json.id
                    }
                    return null
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.createError + i18n.contributor} : ${err}`))
                    dispatch(ToastrAction.error(i18n.createError + i18n.contributor))
                })
        }
    },

    replaceContributor(contributor, contributorReplace) {
        return (dispatch) => {
            dispatch(WaitAction.waitStart())
            return fetch(ApplicationConf.referencial.replaceContributor(), {
                method: 'PUT',
                headers: getAuthorization(),
                body: JSON.stringify({
                    id: contributor,
                    replaceId: contributorReplace,
                }),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(json => {
                    if (json.update) {
                        dispatch(ToastrAction.success(i18n.contributorSuccessUpdated))
                    } else if (json.update == 0) {
                        dispatch(ToastrAction.success(`${json.update} ${i18n.replacement}`))
                    } else {
                        throw new Error()
                    }
                    dispatch(WaitAction.waitStop())
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.replace + i18n.contributor} : ${err}`))
                })
        }
    },

    promiseContributorsGroups() {
        return genericPromise(ApplicationConf.referencial.contributorsGroups())
    },
    fetchContributorsGroups() {
        return (dispatch) => {
            return ContributorAction.promiseContributorsGroups()
                .then((json = []) => {
                    dispatch(ContributorActionConstant.receiveAllContributorsGroups(json))
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.contributorsGroups} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError + i18n.contributorsGroups))
                })
        }
    },

    promiseContributorsGroup(id) {
        return genericPromise(ApplicationConf.referencial.contributorsGroup(id))
    },
    fetchContributorsGroup(id) {
        return (dispatch) => {
            return ContributorAction.promiseContributorsGroup(id)
                .then((json = []) => {
                    dispatch(ContributorActionConstant.receiveContributorsGroup(json))
                    return json
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.contributorsGroups} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError + i18n.contributorsGroups))
                })
        }
    },

    promiseCreateContributorsGroup(body) {
        return genericPromise(ApplicationConf.referencial.contributorsGroups(), 'POST', body)
    },

    createContributorsGroup(body) {
        return (dispatch) => {
            return genericPromise(ApplicationConf.referencial.contributorsGroups(), 'POST', body)
                .then(json => {
                    if (json.code) {
                        dispatch(ToastrAction.success(i18n.successfulCreation))
                        dispatch(push(`/referencial/contributorGroup/${json.code}`))
                    }
                    return null
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.createError + i18n.contributorsGroups} : ${err}`))
                    dispatch(ToastrAction.error(i18n.createError + i18n.contributorsGroups))
                })
        }
    },

    promiseSetContributorsGroup(body) {
        return genericPromise(ApplicationConf.referencial.contributorsGroups(), 'PUT', body)
    },
    setContributorsGroup(body) {
        return genericFetch(ContributorAction.promiseSetContributorsGroup(body), UPDATE_CONTRIBUTORS_GROUP)
    },

    promiseDelelteContributorsGroup(id) {
        return genericPromise(ApplicationConf.referencial.contributorsGroup(id), 'DELETE')
    },
    deleteContributorsGroup(id) {
        return genericFetch(ContributorAction.promiseDelelteContributorsGroup(id), DELETE_CONTRIBUTORS_GROUP)
    },

}

export default ContributorAction
