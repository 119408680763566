import React, { useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import InstallationAction from 'installation/actions/InstallationAction'
import { useEffect } from 'react'
import InstVisitActions from './validation/InstVisitActions'
import { useParams } from 'react-router-dom'
import Card from 'components/card/Card'
import { Button, Grid } from '@mui/material'
import useTitle from 'utils/customHook/useTitle'
import i18n from 'simple-react-i18n'
import CampaignAction from 'campaign/actions/CampaignAction'
import { getSandreLabel } from 'utils/StringUtil'
import { SANDRE } from 'referencial/constants/ReferencialConstants'
import ReferencialAction from 'referencial/action/ReferencialAction'
import { isInstallationType } from 'utils/CampaignUtil'
import { exportFile } from 'utils/ExportDataUtil'
import { getExportAction } from 'utils/VisitUtils'
import DtoInstallation from 'installation/dto/installation/DtoInstallation'
import { arrayOf } from 'utils/StoreUtils'
import DtoSandreCode from 'referencial/dto/DtoSandreCode'
import DtoInstallationType from 'installation/dto/installation/DtoInstallationType'
import CampaignDto from 'campaign/dto/CampaignDto'
import { greyBlue } from 'utils/constants/ColorTheme'

const Title = ({
    installation = {},
    sandreCodes = [],
    installationsTypes = [],
    campaign = {},
}) => {
    const code = installation.code ? `[${installation.code}]` : ''
    const name = installation.name ? `${installation.name}` : ''
    const type = (installationsTypes.find(i => i.id === installation.installationType) || {}).name
    const campaignType = campaign?.campaignType
        ? getSandreLabel(sandreCodes, SANDRE.CAMPAGNES_TYPE, campaign?.campaignType)
        : i18n.notDefined

    return (
        <span>
            <span className='clickable' onClick={() => this.props.push(`/station/installation/${installation.id}`)}>
                {`${code} ${name} - ${type} `}
            </span>
            <span className='padding-left-10'>
                {`(${i18n.campaignType}: ${campaignType || i18n.notDefined})`}
            </span>
        </span>
    )
}

Title.propTypes = {
    installation: PropTypes.instanceOf(DtoInstallation),
    campaign: PropTypes.instanceOf(CampaignDto),
    sandreCodes: arrayOf(DtoSandreCode),
    installationsTypes: arrayOf(DtoInstallationType),
}

const InstallationVisitOperatorFeedback = () => {
    const dispatch = useDispatch()

    const {
        idInstallation,
        id: idCampaign,
    } = useParams()

    const [visit, setVisit] = useState({})

    const {
        campaign,
        installation,
        sandreCodes,
        installationsTypes,
    } = useSelector(store => ({
        installation: store.InstallationReducer.installation,
        campaign: store.CampaignReducer.campaign,
        sandreCodes: store.ReferencialReducer.sandreCodes,
        installationsTypes: store.InstallationReducer.installationsTypes,
    }), shallowEqual)

    useTitle(() => [
        {
            title: i18n.installation,
            href: '/installation',
        },
        {
            title: i18n.campaigns,
            href: '/installation/campaign',
        },
        {
            title: campaign.name || idCampaign,
            href: `/installation/campaign/${idCampaign}/dashboard`,
        },
    ], [visit, campaign])

    useEffect(() => {
        dispatch(ReferencialAction.fetchSandreCodes())
        dispatch(InstallationAction.fetchInstallationTypes())
        $('.material-tooltip').remove()
    }, [])

    useEffect(() => {
        if (idInstallation && idCampaign) {
            dispatch(InstallationAction.fetchVisit(idInstallation, idCampaign)).then((json) =>
                setVisit(json)
            )
            dispatch(InstallationAction.fetchInstallation(idInstallation))
            dispatch(CampaignAction.fetchCampaign('installation', idCampaign))
        }
    }, [idInstallation, idCampaign, dispatch])

    const equipmentType = isInstallationType(installation.installationType)

    const actions = [{
        iconName: 'file_download',
        tooltip: i18n.export,
        onClick: () => exportFile({
            data: [
                {
                    ...getExportAction(sandreCodes, equipmentType, visit.link_actions[0]),
                    headers: ['risk', 'localisation', 'equipmentType', 'responsible', 'category', 'deadline', 'interventionType', 'comment', 'correctionDate', 'commentCorrection'],
                },
                ...visit.link_actions.slice(1).map(d => getExportAction(sandreCodes, equipmentType, d)),
            ],
            titleFile: `${i18n.actions}_${installation.name || installation.code}`,
        }),
    }]

    const onChangeVisit = value => setVisit({ ...visit, ...value })

    return (
        <Grid className='padding-left-2 padding-right-1 padding-top-1' style={{ marginBottom: '100' }}>
            <Card
                title={
                    <Title
                        installation={installation}
                        sandreCodes={sandreCodes}
                        installationsTypes={installationsTypes}
                        campaign={campaign}
                    />
                }
                headerStyle={{ backgroundColor: greyBlue }}
                actions={actions}
            >
                <Grid className='padding-1'>
                    <InstVisitActions
                        visit={visit}
                        onChange={onChangeVisit}
                        readMode={false}
                        operatorFeedback
                    />
                </Grid>
            </Card>
            <Grid style={{ position: 'fixed', width: '100%', bottom: '0', zIndex: '150', background: '#e7eaef', padding: '5px' }}>
                <Grid className='center'>
                    <Button
                        variant='contained'
                        className='center'
                        onClick={() => dispatch(InstallationAction.updateVisit(visit.idVisit, visit))}
                    >
                        {i18n.register}
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default InstallationVisitOperatorFeedback