import { hasValue } from 'utils/NumberUtil'
import { formatMilliers } from 'utils/StringUtil'

export default class DtoSurveyForecast {
    constructor(obj) {
        this.survey = obj.survey// Option[String],
        this.year = obj.year // Option[String],
        this.codification = obj.codification// Option[String],
        this.id = obj.id// Option[String],
        this.description = obj.description// Option[String],
        this.culture = obj.culture// Option[String],
        this.familyCulture = obj.familyCulture// Option[String],
        this.surface = obj.surface// Option[Double],
        this.annualDemandVolume = hasValue(obj.annualDemandVolume) ? formatMilliers(obj.annualDemandVolume) : null
        this.annualDemandVolumeExport = obj.annualDemandVolume
        this.username = obj.username// Option[String],
        this.siret = obj.siret// Option[String],
        this.structureType = obj.structureType// Option[String],
        this.municipalityCode = obj.municipalityCode// Option[String],
        this.municipalityName = obj.municipalityName// Option[String],
        this.location = obj.location// Option[String],
        this.cadastralParcel = obj.cadastralParcel// Option[String],
        this.parcel = obj.parcel// Option[String],
        this.section = obj.section// Option[String],
        this.watershed = obj.watershed// Option[String],
        this.idUge = obj.idUge// Option[String],
        this.nameUge = obj.nameUge// Option[String],
        this.idUnderUge = obj.idUnderUge// Option[String],
        this.nameUnderUge = obj.nameUnderUge// Option[String],
        this.aquifer = obj.aquifer// Option[String],
        this.sampleType = obj.sampleType// Option[String],
        this.sampleNature = obj.sampleNature// Option[String],
        this.groundType = obj.groundType// Option[String],
        this.groundDepth = obj.groundDepth// Option[String],
        this.numberRotation = obj.numberRotation// Option[Int],
        this.irrigationMethod = obj.irrigationMethod// Option[String],
        this.pumps = obj.pumps// Option[String],
        this.noserie = obj.noserie// Option[String],
        this.inUseFlow = obj.inUseFlow// Option[String],
        this.maxFlow = obj.maxFlow// Option[String],
        this.sollication = obj.sollication// Option[String],
        this.comment = obj.comment// Option[String],
        this.x = obj.x// Option[Double],
        this.y = obj.y// Option[Double],
        this.projection = obj.projection// Option[String],
        this.volumeDistribution = obj.volumeDistribution// Option[String],
        this.actualStatus = obj.actualStatus// Option[String],
        this.surveyStatus = obj.surveyStatus// Option[String],
        this.period = obj.period// Option[String],
        this.seasons = obj.seasons// Option[String],
        this.capacity = obj.capacity// Option[Double],
        this.detentions = obj.detentions // Option[String],
        this.detentionType = obj.detentionType // Option[String],
        this.allocatedVolume = obj.allocatedVolume // Option[Double],
        this.authorizedVolume = obj.authorizedVolume // Option[Double],
        this.comments = obj.comments // Option[String],
        this.advancement = (obj.advancement || '').replace('125', '100') // Option[String],
        this.idUsagePrevi = obj.idUsagePrevi // Option[Long],
        this.repLegal = obj.repLegal // Option[String],
        this.postalCodeRep = obj.postalCodeRep // Option[String],
        this.cityRep = obj.cityRep // Option[String],
        this.addressRep = obj.addressRep // Option[String],
        this.complAddressRep = obj.complAddressRep // Option[String],
        this.workDepth = obj.workDepth // Option[Double],
        this.PAM = obj.PAM // Option[Boolean],
        this.specificPrescriptions = obj.specificPrescriptions // Option[Boolean]
        this.lowWaterVolume = obj.lowWaterVolume // Option[Double],
        this.volumesMonths = obj.volumesMonths // Option[String]
    }
}