module.exports = {
    RECEIVE_STATION_CENTRAL: 'RECEIVE_STATION_CENTRAL',
    RECEIVE_STATION_CENTRAL_ASSIGNMENT: 'RECEIVE_STATION_CENTRAL_ASSIGNMENT',
    RECEIVE_STATION_CENTRAL_PATH: 'RECEIVE_STATION_CENTRAL_PATH',
    RECEIVE_STATION_CENTRAL_RANGE: 'RECEIVE_STATION_CENTRAL_RANGE',
    RECEIVE_STATION_POWER_SUPPLY: 'RECEIVE_STATION_POWER_SUPPLY',
    RECEIVE_STATION_POWER_SUPPLY_ASSIGNMENT: 'RECEIVE_STATION_POWER_SUPPLY_ASSIGNMENT',
    RECEIVE_STATION_SENSOR: 'RECEIVE_STATION_SENSOR',
    RECEIVE_STATION_SENSOR_ASSIGNMENT: 'RECEIVE_STATION_SENSOR_ASSIGNMENT',
    RECEIVE_STATION_SIM: 'RECEIVE_STATION_SIM',
    RECEIVE_STATION_SIM_ASSIGNMENT: 'RECEIVE_STATION_SIM_ASSIGNMENT',
    RECEIVE_STATION_TELECOM: 'RECEIVE_STATION_TELECOM',
    RECEIVE_STATION_TELECOM_ASSIGNMENT: 'RECEIVE_STATION_TELECOM_ASSIGNMENT',
    RECEIVE_STATION_VARIOUS_MATERIEL: 'RECEIVE_STATION_VARIOUS_MATERIEL',
    RECEIVE_STATION_VARIOUS_MATERIEL_ASSIGNMENT: 'RECEIVE_STATION_VARIOUS_MATERIEL_ASSIGNMENT',
    RECEIVE_STATION_EQUIPMENT: 'RECEIVE_STATION_EQUIPMENT',
    RECEIVE_STATION_EQUIPMENT_ASSIGNMENT: 'RECEIVE_STATION_EQUIPMENT_ASSIGNMENT',
    RECEIVE_STATION_SUBSCRIPTION: 'RECEIVE_STATION_SUBSCRIPTION',
    RECEIVE_STATION_SUBSCRIPTION_ASSIGNMENT: 'RECEIVE_STATION_SUBSCRIPTION_ASSIGNMENT',
}
