export default class DtoInstallationAnalysis {
    constructor(obj) {
        this.code = obj.code
        this.analysisDate = obj.analysisDate
        this.date = obj.analysisDate
        this.producerCode = obj.producerCode
        this.producer = obj.producer
        this.result = obj.result
        this.parameterCode = obj.parameterCode
        this.parameter = obj.parameterCode
        this.unitCode = obj.unitCode
        this.unit = obj.unitCode
        this.remarkCode = obj.remarkCode
        this.analysisRef = obj.analysisRef
        this.reference = obj.analysisRef
        this.analysisComment = obj.analysisComment
        this.comment = obj.comment
    }
}