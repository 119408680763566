import { Card, CardContent, Grid2, Icon } from '@mui/material'
import React, { Component } from 'react'
import i18n from 'simple-react-i18n'

class DevelopmentApp extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <Card>
                <CardContent>
                    <Grid2 container spacing={1} alignItems='center'>
                        <Grid2 size='auto'>
                            <Icon fontSize='large'>sort</Icon>
                        </Grid2>
                        <Grid2 size='auto'>
                            <h6>{i18n.inDeveloppmentFunctionnality}</h6>
                        </Grid2>
                    </Grid2>
                </CardContent>
            </Card>
        )
    }
}

export default DevelopmentApp
