import { TOASTR_ERROR, TOASTR_INFO, TOASTR_SUCCESS, TOASTR_WARNING } from '../constants/ToastrConstants'

const ToastrAction = {
    error: (message, title = '', option = {}) => {
        return {
            type: TOASTR_ERROR,
            option,
            title,
            message,
        }
    },
    success(message, title = '', option = {}) {
        return {
            type: TOASTR_SUCCESS,
            option,
            title,
            message,
        }
    },
    warning(message, title = '', option = {}) {
        return {
            type: TOASTR_WARNING,
            option,
            title,
            message,
        }
    },
    info(message, title = '', option = {}) {
        return {
            type: TOASTR_INFO,
            option,
            title,
            message,
        }
    },
}

export default ToastrAction