import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { push } from '@lagunovsky/redux-react-router'
import { isEqual, omit } from 'lodash'
import i18n from 'simple-react-i18n'
import moment from 'moment'
import 'moment/locale/fr'
import ToastrAction from 'toastr/actions/ToastrAction'
import ReferencialAction from '../../../action/ReferencialAction'
import ActionComponent from '../../../../components/ActionComponent'
import SieauAction from '../../../../components/sieau/SieauAction'
import Input from '../../../../components/forms/Input'
import { updateMaterialize } from '../../../../utils/MaterializeUtils'
import { getUser } from '../../../../utils/SettingUtils'
import DtoUsageAgri from '../dto/DtoUsageAgri'
import UsageAction from '../actions/UsageAction'
import NumberField from '../../../../components/forms/NumberField'
import Card from '../../../../components/card/Card'
import Select from '../../../../components/forms/Select'
import Checkbox from '../../../../components/forms/Checkbox'
import HomeAction from '../../../../home/actions/HomeAction'
import Textarea from '../../../../components/forms/Textarea'
import SimpleDatePicker from '../../../../components/forms/SimpleDatePicker'
import AppStore from 'store/AppStore'
import { StyledFieldSet, StyledLegend } from 'components/StyledElements'
import { Grid2 } from '@mui/material'

moment.locale('fr')

class UsageApp extends ActionComponent {
    constructor(props) {
        super(props)
        this.state = {
            isEditMode: props.isEditMode,
            usage: {},
        }
    }

    componentDidMount() {
        const { params } = this.props
        if (params.id !== 'new') {
            this.changeEditMode(false)
            this.props.fetchUsage(params.id)
        } else {
            this.changeEditMode(true)
        }
        AppStore.dispatch(HomeAction.setHelpLink('', ''))
        if (!this.props.sandreCodes.length) {
            this.props.fetchSandreCodes()
        }
        this.toNavbarUsage()
    }

    changeStateAttribute = (obj) => {
        this.setState(({ usage }) => ({
            usage: {
                ...usage,
                ...obj,
            },
        }))
    }

    componentWillUnmount = () => this.props.resetUsage()

    changeEditMode = (bool) => {
        this.setState({ isEditMode: bool })
    }

    toNavbarUsage() {
        const { params } = this.props
        const { isEditMode } = this.state
        const actions = (() => {
            if (params.id === 'new' && (!this.props.usage || !this.props.usage.id)) {
                return {
                    save: () => {
                        const { usage } = this.state
                        this.props.createUsage(usage)
                        this.changeEditMode(false)
                    },
                    cancel: () => {
                        this.props.push('/territory/settings/usages')
                        this.changeEditMode(false)
                    },
                }
            }
            if (isEditMode) {
                return {
                    save: () => {
                        const { usage } = this.state
                        this.props.updateUsage(usage, usage.id)
                        this.changeEditMode(false)
                    },
                    cancel: () => {
                        this.setState({ usage: this.props.usage })
                        this.changeEditMode(false)
                    },
                }
            }
            return {
                edit: () => {
                    this.changeEditMode(true)
                },
                delete: () => {
                    this.props.deleteUsage(this.props.usage.id)
                },
            }
        })()
        if (getUser().consultant === '1') {
            this.setActions(omit(actions, ['save', 'edit', 'deleteCheck', 'replace']))
        } else {
            this.setActions(actions)
        }
        updateMaterialize()
    }

    componentDidUpdate = prevProps => {
        const { usage } = this.props
        const { params } = this.props
        const { isEditMode } = this.state
        if (!isEqual(prevProps.usage, usage)) {
            this.setState({ usage })
        }
        if (!isEqual(isEditMode, prevProps.isEditMode)) {
            this.toNavbarUsage()
        }
        if (prevProps.params.id !== params.id) {
            this.props.fetchUsage(params.id)
        } else if (params.id === 'new') {
            this.props.setTitle([{
                title: i18n.steering,
                href: 'territory',
            },
            {
                title: i18n.settings,
                href: 'territory/settings',
            },
            {
                title: i18n.usages,
                href: 'territory/settings/usages',
            },
            {
                title: i18n.new,
                href: 'territory/settings/usage/new',
            }])
        } else if (!isEqual(prevProps.usage, usage)) {
            this.props.setTitle([{
                title: i18n.steering,
                href: 'territory',
            },
            {
                title: i18n.settings,
                href: 'territory/settings',
            },
            {
                title: i18n.usages,
                href: 'territory/settings/usages',
            },
            {
                title: params.id + (usage.description ? ` - ${usage.description}` : ''),
                href: `territory/settings/usage/${params.id}`,
            }])
            this.setState({ usage })
            this.toNavbarUsage()
        }
    }

    render() {
        const { sandreCodes } = this.props
        const { isEditMode, usage } = this.state
        const disabled = {
            active: isEditMode,
            disabled: !isEditMode,
        }

        return (
            <div className='padding-1'>
                <Card title={i18n.description} maxWidth={1000} round>
                    <Grid2 container spacing={1} sx={{ padding: '10px' }}>
                        <Grid2 size={6}>
                            <Input
                                title={i18n.name}
                                data-cy='usageName'
                                value={usage.description}
                                onChange={value => this.changeStateAttribute({ description: value })}
                                maxLength={255}
                                {...disabled}
                            />
                        </Grid2>
                        <Grid2 size={3}>
                            <NumberField
                                title={i18n.id}
                                value={usage.idUsage}
                                onChange={value => this.changeStateAttribute({ idUsage: value })}
                                disabled
                            />
                        </Grid2>
                        <Grid2 size={3}>
                            <NumberField
                                title={i18n.order}
                                data-cy='usageOrder'
                                value={usage.order}
                                onChange={value => this.changeStateAttribute({ order: value })}
                                {...disabled}
                            />
                        </Grid2>
                        <Grid2 size={6}>
                            <Select
                                label={i18n.category}
                                data-cy='usageCategory'
                                value={usage.category}
                                options={sandreCodes.filter(({ field }) => field === 'USAGES.CATEGORIE')}
                                onChange={value => this.changeStateAttribute({ category: value })}
                                {...disabled}
                            />
                        </Grid2>
                        <Grid2 size={6}>
                            <Select
                                label={i18n.status}
                                data-cy='usageStatus'
                                value={usage.status}
                                options={sandreCodes.filter(({ field }) => field === 'USAGES.STATUT')}
                                onChange={value => this.changeStateAttribute({ status: value })}
                                {...disabled}
                            />
                        </Grid2>
                        <Grid2 size={6}>
                            <SimpleDatePicker
                                id='startDate'
                                label={i18n.startDate}
                                data-cy='usageStartDate'
                                value={usage.startDate}
                                onChange={(v) => this.changeStateAttribute({ startDate: v })}
                                max={usage.endDate}
                                {...disabled}
                            />
                        </Grid2>
                        <Grid2 size={6}>
                            <SimpleDatePicker
                                id='endDate'
                                label={i18n.endDate}
                                data-cy='usageEndName'
                                value={usage.endDate}
                                onChange={(v) => this.changeStateAttribute({ endDate: v })}
                                min={usage.startDate}
                                {...disabled}
                            />
                        </Grid2>
                        <Grid2 size={4}>
                            <Checkbox
                                label={i18n.eligibility}
                                data-cy='usageEligibility'
                                checked={usage.eligibility}
                                onChange={v => this.changeStateAttribute({ eligibility: v })}
                                {...disabled}
                            />
                        </Grid2>
                        <Grid2 size={12}>
                            <StyledFieldSet>
                                <StyledLegend>{i18n.irrigation}</StyledLegend>
                                <Grid2 container spacing={2} alignItems='center'>
                                    <Checkbox
                                        label={i18n.culture}
                                        data-cy='usageCulture'
                                        checked={usage.isCulture}
                                        onChange={v => this.changeStateAttribute({
                                            isCulture: v,
                                            simplifiedCulture: v ? false : usage.simplifiedCulture,
                                            calculAuto: v ? usage.calculAuto : false,
                                            checkCoherence: v ? usage.checkCoherence : false,
                                        })}
                                        {...disabled}
                                    />
                                    {(i18n.or).toUpperCase()}
                                    <Checkbox
                                        label={i18n.simplifiedCulture}
                                        data-cy='usageSimplifiedCulture'
                                        checked={usage.simplifiedCulture}
                                        onChange={v => this.changeStateAttribute({ simplifiedCulture: v,
                                            isCulture: v ? false : usage.isCulture,
                                            calculAuto: v ? usage.calculAuto : false,
                                            checkCoherence: v ? usage.checkCoherence : false,
                                        })}
                                        {...disabled}
                                    />
                                </Grid2>
                                <Grid2 container spacing={2} sx={{ paddingTop: '10px' }}>
                                    <Checkbox
                                        label={i18n.calculAuto}
                                        data-cy='usageCalculAuto'
                                        checked={usage.calculAuto}
                                        onChange={v => this.changeStateAttribute({
                                            calculAuto: v,
                                            checkCoherence: v ? false : usage.checkCoherence,
                                        })}
                                        disabled={!isEditMode || !(usage.isCulture || usage.simplifiedCulture)}
                                    />
                                    {(i18n.or).toUpperCase()}
                                    <Checkbox
                                        label={i18n.verifyCoherence}
                                        data-cy='usageVerifyCoherence'
                                        checked={usage.checkCoherence}
                                        onChange={v => this.changeStateAttribute({
                                            checkCoherence: v,
                                            calculAuto: v ? false : usage.calculAuto,
                                        })}
                                        disabled={!isEditMode || !(usage.isCulture || usage.simplifiedCulture)}
                                    />
                                </Grid2>
                            </StyledFieldSet>
                        </Grid2>
                        <Grid2 size={12}>
                            <StyledFieldSet>
                                <StyledLegend>{i18n.entry}</StyledLegend>
                                <Grid2 container spacing={2} alignItems='center' justifyContent='space-between'>
                                    <Checkbox
                                        label={i18n.multipleEntry}
                                        data-cy='usageMultipleEntry'
                                        checked={usage.multipleEntry}
                                        onChange={v => this.changeStateAttribute({ multipleEntry: v })}
                                        {...disabled}
                                    />
                                    <Checkbox
                                        label={i18n.volumeRealEntry}
                                        data-cy='usageVolumeRealEntry'
                                        checked={usage.volumeRealEntry}
                                        onChange={v => this.changeStateAttribute({ volumeRealEntry: v })}
                                        {...disabled}
                                    />
                                    <Checkbox
                                        label={i18n.showVolumeIfCounter}
                                        data-cy='usageShowVolumeIfCounter'
                                        checked={usage.showVolumeIfCounter}
                                        onChange={v => this.changeStateAttribute({ showVolumeIfCounter: v })}
                                        {...disabled}
                                    />
                                </Grid2>
                            </StyledFieldSet>
                        </Grid2>
                        <Grid2 size={12} sx={{ marginTop: '10px' }}>
                            <Textarea
                                title={i18n.comment}
                                data-cy='usageComment'
                                value={usage.comment}
                                onChange={v => this.changeStateAttribute({ comment: v })}
                                {...disabled}
                            />
                        </Grid2>
                    </Grid2>
                </Card>
            </div>
        )
    }
}

UsageApp.propTypes = {
    params: PropTypes.shape({
        id: PropTypes.string,
    }),
    usage: PropTypes.instanceOf(DtoUsageAgri),
    getLink: PropTypes.func,
    isEnableEdit: PropTypes.bool,
    changeEditMode: PropTypes.func,
    isEditMode: PropTypes.bool,
}

const mapStateToProps = store => ({
    usage: store.UsageReducer.usage,
    sandreCodes: store.ReferencialReducer.sandreCodes,
})

const mapDispatchToProps = {
    push,
    toastrError: ToastrAction.error,
    setPopup: SieauAction.setPopup,
    resetUsage: UsageAction.reset,
    deleteUsage: UsageAction.deleteUsage,
    createUsage: UsageAction.createUsage,
    updateUsage: UsageAction.updateUsage,
    fetchUsage: UsageAction.fetchUsage,
    fetchSandreCodes: ReferencialAction.fetchSandreCodes,
    setTitle: HomeAction.setTitle,
}


export default connect(mapStateToProps, mapDispatchToProps)(UsageApp)
