export default class DtoSTEPCollectionBasin {
    constructor(obj) {
        this.idInstallation = obj.idInstallation // Option[Long],
        this.idBasin = obj.idBasin // Option[Long],
        this.basinIdentifier = obj.basinIdentifier // Option[String],
        this.basinName = obj.basinName // Option[String],
        this.connectionDate = obj.connectionDate // Option[DateTime],
        this.countLinkEstablishment = obj.countLinkEstablishment // Option[Long],
        this.countLinkSub = obj.countLinkSub // Option[Long]

        // front
        this.basinId = obj.basinIdentifier
    }
}