import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import Table from '../../../components/datatable/Table'
import DtoDistributionUnit from '../../../distributionUnit/dto/DtoDistributionUnit'
import DtoHydrometricStation from '../../../hydrometry/dto/DtoHydrometricStation'
import DtoInstallation from '../../../installation/dto/installation/DtoInstallation'
import DtoLandmark from '../../../piezometry/dto/DtoLandmark'
import DtoPiezometer from '../../../piezometry/dto/DtoPiezometer'
import DtoProductionUnit from '../../../productionUnit/dto/DtoProductionUnit'
import DtoQualitometer from '../../../quality/dto/DtoQualitometer'
import { SANDRE } from '../../../referencial/constants/ReferencialConstants'
import DtoSandreCode from '../../../referencial/dto/DtoSandreCode'
import { getFullDate } from '../../../utils/DateUtil'
import { checkMandatoryFields } from '../../../utils/FormUtils'
import { getExport } from '../../../utils/linkUtils'
import { hasValue, round } from '../../../utils/NumberUtil'
import { getUpdatedLandmarkDate } from '../../../utils/PiezometryUtils'
import { getSandreLabel } from '../../../utils/StringUtil'
import LandmarkModal from './modals/LandmarkModal'

class StationLandmarkPanel extends Component {
    state = {
        isOpen: false,
        isNew: false,
        landmark: {},
    }

    render = () => {
        const {
            station = {},
            readMode = false,
            sandreCodes = [],
            onChange = () => { },
            className = '',
            createOnly = false,
        } = this.props
        const {
            isOpen,
            isNew,
            landmark,
        } = this.state

        const stationLandmarks = station.link_landmarks.map((l, index) => ({
            ...l,
            index,
        }))
        const tableData = stationLandmarks.map((l, idx) => {
            const as = station.link_altimetrySystems && station.link_altimetrySystems.find(a => a.natureCode === l.altimetrySystemNature && a.startDate === l.altimetrySystemDate)
            return {
                index: idx,
                comment: { value: l.comment },
                startDate: { value: getFullDate(l.startDate) },
                endDate: { value: getFullDate(l.endDate) },
                height: { value: hasValue(l.height) ? l.height.toString() : '' },
                rating: { value: hasValue(as) && hasValue(l.height) ? round(as.altitude + l.height) : '' },
                altimetricSystem: hasValue(as) ? { value: `${getSandreLabel(sandreCodes, SANDRE.ALTIMETRIC_SYSTEM_NATURE, as.natureCode)} - ${getFullDate(as.startDate)}` } : { leftIcon: 'warning', leftIconColor: 'red', textColor: 'red', value: i18n.undefinedSysAlti },
                origin: { value: getSandreLabel(sandreCodes, SANDRE.ORIGINE_REPERE, l.origin) },
            }
        })
        const headers = ['startDate', 'endDate', 'altimetricSystem', 'height', 'rating', 'origin', 'comment']
        const exportAction = getExport(tableData, i18n.landmarks, headers)
        const actions = !readMode ? [{
            iconName: 'note_add',
            tooltip: i18n.add,
            onClick: () => this.setState({ isOpen: true, isNew: true, landmark: { idStation: station.id } }),
        }, exportAction] : [exportAction]

        return (
            <div id='landmarks'>
                <Table
                    title={i18n.landmarks}
                    condensed
                    data={tableData}
                    type={{ headers }}
                    className={className}
                    sortable={!!tableData.length}
                    actions={actions}
                    color
                    alterable={!readMode && !createOnly}
                    onAlter={altered => {
                        const lm = stationLandmarks[altered.index]
                        this.setState({ isOpen: true, isNew: false, landmark: { ...lm, idStation: station.id } })
                    }}
                    deletable={!readMode && !createOnly}
                    onDelete={lm => onChange({ link_landmarks: stationLandmarks.filter(l => l.index !== lm.index), ...getUpdatedLandmarkDate(station, lm) })}
                />
                {/* <Dialog
                    actions={[(
                        <a
                            className='waves-effect waves-teal btn-flat center secondary-button margin-right-1 modal-close'
                            onClick={() => this.setState({ isOpen: false })}
                        >
                            {i18n.cancel}
                        </a>
                    ), (
                        <a
                            className='waves-effect waves-teal center primary-button btn-flat'
                            onClick={() => {
                                const mandatoryFields = [{ field: 'height' }, { field: 'startDate' }, { field: 'altimetrySystemNature', i18n: 'altimetricSystem' }, { field: 'altimetrySystemDate', i18n: 'altimetricSystem' }]
                                checkMandatoryFields(mandatoryFields, this.state.landmark, () => {
                                    onChange({
                                        link_landmarks: [
                                            ...stationLandmarks.filter(l => l.index !== landmark.index),
                                            new DtoLandmark(landmark)
                                        ],
                                        ...getUpdatedLandmarkDate(station, landmark)
                                    })
                                    this.setState({ isOpen: false })
                                })
                            }}
                        >
                            {i18n.register}
                        </a>
                    )]}
                > */}
                <Dialog
                    onClose={() => this.setState({ isOpen: false })}
                    fullWidth
                    maxWidth='md'
                    open={isOpen}
                >
                    <DialogTitle>{isNew ? i18n.newLandmark : i18n.editLandmark}</DialogTitle>
                    <DialogContent>
                        <LandmarkModal
                            altimetrySystems={station.link_altimetrySystems}
                            landmark={landmark}
                            onChange={obj => this.setState({ landmark: { ...landmark, ...obj } })}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.setState({ isOpen: false })} variant='outlined' color='secondary'>
                            {i18n.cancel}
                        </Button>
                        <Button onClick={() => {
                            const mandatoryFields = [{ field: 'height' }, { field: 'startDate' }, { field: 'altimetrySystemNature', i18n: 'altimetricSystem' }, { field: 'altimetrySystemDate', i18n: 'altimetricSystem' }]
                            checkMandatoryFields(mandatoryFields, this.state.landmark, () => {
                                onChange({
                                    link_landmarks: [
                                        ...stationLandmarks.filter(l => l.index !== landmark.index),
                                        new DtoLandmark(landmark),
                                    ],
                                    ...getUpdatedLandmarkDate(station, landmark),
                                })
                                this.setState({ isOpen: false })
                            })
                        }} variant='contained' color='primary'
                        >
                            {i18n.register}
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        )
    }
}

StationLandmarkPanel.propTypes = {
    station: PropTypes.oneOfType([
        PropTypes.instanceOf(DtoProductionUnit),
        PropTypes.instanceOf(DtoDistributionUnit),
        PropTypes.instanceOf(DtoQualitometer),
        PropTypes.instanceOf(DtoPiezometer),
        PropTypes.instanceOf(DtoHydrometricStation),
        PropTypes.instanceOf(DtoInstallation),
    ]),
    sandreCodes: PropTypes.arrayOf(PropTypes.instanceOf(DtoSandreCode)),
    readMode: PropTypes.bool,
    onChange: PropTypes.func,
    className: PropTypes.string,
    createOnly: PropTypes.bool,
}

const mapStateToProps = store => ({
    sandreCodes: store.ReferencialReducer.sandreCodes,
})

export default connect(mapStateToProps)(StationLandmarkPanel)