/* eslint-disable camelcase */
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import Table from '../../../components/datatable/Table'
import SieauAction from '../../../components/sieau/SieauAction'
import DtoDistributionUnit from '../../../distributionUnit/dto/DtoDistributionUnit'
import DtoHydrometricStation from '../../../hydrometry/dto/DtoHydrometricStation'
import DtoInstallation from '../../../installation/dto/installation/DtoInstallation'
import DtoPiezometer from '../../../piezometry/dto/DtoPiezometer'
import DtoPiezometerContributorLink from '../../../piezometry/dto/DtoPiezometerContributorLink'
import DtoProductionUnit from '../../../productionUnit/dto/DtoProductionUnit'
import DtoQualitometer from '../../../quality/dto/DtoQualitometer'
import DtoAquifer from '../../../referencial/components/aquifers/dto/DtoAquifer'
import DtoSandreCode from '../../../referencial/dto/DtoSandreCode'
import { getExport, setModal } from '../../../utils/linkUtils'
import { arrayOf, getLabel } from '../../../utils/StoreUtils'
import DtoHydrogeologicalEntity from '../../dto/DtoHydrogeologicalEntity'
import CasingModal from './modals/CasingModal'

const StationCasingPanel = ({
    station = {},
    aquifers = [],
    readMode = false,
    className = '',
    onChange = () => {},
    setPopup = () => {},
}) => {
    const stationCasings = station.link_casings.map((c, index) => ({
        ...c,
        index,
    }))
    const tableData = stationCasings.map(c => ({
        ...c,
        aquifer: getLabel(aquifers, c.aquiferCode),
    }))
    const headers = ['order', 'aquifer', 'startDepth', 'endDepth', 'aquiferRoof', 'aquiferFloor']
    const exportAction = getExport(tableData, i18n.casings, headers)
    const actions = !readMode ? [{
        iconName: 'note_add',
        tooltip: i18n.add,
        onClick: () => setModal(i18n.newCasing, <CasingModal id={ station.id } saveResult={ r => onChange({ link_casings: [...stationCasings, r] }) }/>, setPopup),
    }, exportAction] : [exportAction]

    return (
        <Table
            title={ i18n.casings }
            className={ className }
            condensed
            data={ tableData }
            type={{ headers }}
            sortable={ !!tableData.length }
            actions={ actions }
            alterable={ !readMode }
            onAlter={
                (element) => setModal(i18n.editCasing,
                    <CasingModal
                        id={ station.id }
                        casing={ stationCasings.find(sc => element.index === sc.index) }
                        saveResult={ r => onChange({
                            link_casings: [
                                ...stationCasings.filter(sc => sc.index !== element.index),
                                { ...r, index: element.index },
                            ],
                        })}
                    />
                    , setPopup)
            }
            deletable={ !readMode }
            onDelete={ (element) => onChange({ link_casings: stationCasings.filter(sc => sc.index !== element.index) }) }
        />
    )
}

StationCasingPanel.propTypes = {
    station: PropTypes.oneOfType([
        PropTypes.instanceOf(DtoProductionUnit),
        PropTypes.instanceOf(DtoDistributionUnit),
        PropTypes.instanceOf(DtoQualitometer),
        PropTypes.instanceOf(DtoPiezometer),
        PropTypes.instanceOf(DtoHydrometricStation),
        PropTypes.instanceOf(DtoInstallation),
    ]),
    aquifers: arrayOf(DtoAquifer),
    sandreCodes: arrayOf(DtoSandreCode),
    contributors: arrayOf(DtoPiezometerContributorLink),
    hydrogeologicalEntities: arrayOf(DtoHydrogeologicalEntity),
    readMode: PropTypes.bool,
    onChange: PropTypes.func,
    setPopup: PropTypes.func,
    className: PropTypes.string,
}

const mapStateToProps = store => ({
    aquifers: store.AquiferReducer.aquifers,
})

const mapDispatchToProps = {
    setPopup: SieauAction.setPopup,
}

export default connect(mapStateToProps, mapDispatchToProps)(StationCasingPanel)