import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import Table from '../../../components/datatable/Table'
import DtoQualitometer from '../../../quality/dto/DtoQualitometer'
import DtoSandreCode from '../../../referencial/dto/DtoSandreCode'
import { getDate } from '../../../utils/DateUtil'
import { getExport, setModal } from '../../../utils/linkUtils'
import SieauAction from '../../../components/sieau/SieauAction'
import { max, orderBy } from 'lodash'
import { hasValue } from '../../../utils/NumberUtil'
import StationSamplePointModal from './modals/StationSamplePointModal'
import SupportDto from '../../../referencial/components/support/dto/SupportDto'
import { objectOf } from '../../../utils/StoreUtils'
import AppStore from '../../../store/AppStore'
import SupportAction from '../../../referencial/components/support/actions/SupportAction'

class StationSamplePointsPanel extends Component {
    state = {
        isOpen: false,
        isNew: false,
    }

    saveResults = (newLinks) => {
        if (newLinks.length) {
            const newMaxId = (max(newLinks.map(p => p.idPoint).filter(i => hasValue(i))) || 0) + 1
            this.props.onChange({ link_samplePoints: newLinks.map((point, idx) => {
                if (hasValue(point.idPoint)) {
                    return point
                }
                return { ...point, idPoint: newMaxId + idx }
            }) })
        } else {
            this.props.onChange({ link_samplePoints: [] })
        }
    }

    render = () => {
        const {
            station = {},
            className = '',
            readMode,
            onChange,
            setPopup,
            supportsIndex,
        } = this.props

        if (!this.props.supports.length) {
            AppStore.dispatch(SupportAction.fetchSupports())
        }

        const stationSamplePoints = station.link_samplePoints.map((p, index) => ({ ...p, index }))

        const tableData = stationSamplePoints.map(sp => ({
            ...sp,
            startDate: getDate(sp.startDate),
            endDate: getDate(sp.endDate),
            stationCode: sp.idQualitometer,
            id: sp.identifiant,
            support: supportsIndex[sp.codeSupport]?.labelWithCode,
        }))
        const headers = ['support', 'startDate', 'endDate', 'x', 'y', 'projection', 'id', 'name', 'code', 'startDepth', 'endDepth']
        const exportAction = getExport(tableData, i18n.samplePoint, headers)
        const actions = !readMode ? [{
            iconName: 'note_add',
            tooltip: i18n.add,
            onClick: () => setModal(i18n.newSamplePoint, <StationSamplePointModal id={ station.id } saveResult={ r => this.saveResults([...station.link_samplePoints, r]) }/>, setPopup),
        }, exportAction] : [exportAction]

        return (
            <div id='samplePoint'>
                <Table
                    title={i18n.samplePoint}
                    condensed
                    data={tableData}
                    type={{ headers }}
                    className={className}
                    sortable={!!tableData.length}
                    actions={actions}
                    alterable={ !readMode }
                    onAlter={
                        element => setModal(i18n.pointPrelevement,
                            <StationSamplePointModal
                                id={ station.id }
                                point={ stationSamplePoints.find(sn => element.index === sn.index) }
                                saveResult={ r => onChange({
                                    link_samplePoints: orderBy([
                                        ...stationSamplePoints.filter(sn => sn.index !== element.index),
                                        { ...r, index: element.index },
                                    ], 'index'),
                                })}
                            />
                            , setPopup)
                    }
                    deletable={ !readMode }
                    onDelete={ (element) => onChange({ link_samplePoints: stationSamplePoints.filter(sn => sn.index !== element.index) }) }
                />
            </div>
        )
    }
}

StationSamplePointsPanel.propTypes = {
    station: PropTypes.instanceOf(DtoQualitometer),
    sandreCodes: PropTypes.arrayOf(PropTypes.instanceOf(DtoSandreCode)),
    readMode: PropTypes.bool,
    onChange: PropTypes.func,
    className: PropTypes.string,
    createOnly: PropTypes.bool,
    setPopup: PropTypes.func,
    supports: PropTypes.instanceOf(SupportDto),
    supportsIndex: objectOf(SupportDto)
}

const mapStateToProps = store => ({
    sandreCodes: store.ReferencialReducer.sandreCodes,
    supportsIndex: store.SupportReducer.supportsIndex,
    supports: store.SupportReducer.supports,
})

const mapDispatchToProps = {
    setPopup: SieauAction.setPopup,
}

export default connect(mapStateToProps, mapDispatchToProps)(StationSamplePointsPanel)