export default class DtoMaterielType {
    constructor(obj) {
        this.id = obj.materielType
        this.code = obj.materielType
        this.materielType = obj.materielType
        this.provider = obj.provider
        this.providerId = obj.provider
        this.manufacturerId = obj.manufacturerId
        this.label = obj.name
        this.name = obj.name
        this.comment = obj.comment
        this.updateDate = obj.updateDate
        this.updateLogin = obj.updateLogin
        this.startDate = obj.startDate
        this.endDate = obj.endDate
        this.warrantyTime = obj.warrantyTime
        this.headers = ['materielType', 'label']
    }
}
