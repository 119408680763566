export default class DtoSurveyActualUse {
    constructor(obj) {
        this.survey = obj.survey // String,
        this.year = obj.year // Option[Int],
        this.codification = obj.codification // Option[String],
        this.id = obj.id // Option[String],
        this.description = obj.description // Option[String],
        this.culture = obj.culture // Option[String],
        this.familyCulture = obj.familyCulture // Option[String],
        this.surface = obj.surface // Option[Double],
        this.username = obj.username // Option[String],
        this.siret = obj.siret // Option[String],
        this.userType = obj.userType // Option[String],
        this.municipalityCode = obj.municipalityCode // Option[String],
        this.municipalityName = obj.municipalityName // Option[String],
        this.location = obj.location // Option[String],
        this.cadastralParcel = obj.cadastralParcel // Option[String],
        this.parcel = obj.parcel // Option[String],
        this.section = obj.section // Option[String],
        this.watershed = obj.watershed // Option[String],
        this.idUge = obj.idUge // Option[String],
        this.nameUge = obj.nameUge // Option[String],
        this.idUnderUge = obj.idUnderUge // Option[String],
        this.nameUnderUge = obj.nameUnderUge // Option[String],
        this.aquifer = obj.aquifer // Option[String],
        this.sampleType = obj.sampleType // Option[String],
        this.sampleNature = obj.sampleNature // Option[String],
        this.irrigationMethod = obj.irrigationMethod // Option[String],
        this.pumps = obj.pumps // Option[String],
        this.noserie = obj.noserie // Option[String],
        this.inUseFlow = obj.inUseFlow // Option[String],
        this.maxFlow = obj.maxFlow // Option[String],
        this.sollication = obj.sollication // Option[String],
        this.groundType = obj.groundType // Option[String],
        this.groundDepth = obj.groundDepth // Option[String],
        this.realVolume = obj.realVolume // Option[Double],
        this.comment = obj.comment // Option[String]
        this.capacity = obj.capacity // Option[Double]
        this.detentions = obj.detentions // Option[String],
        this.detentionType = obj.detentionType // Option[String]
        this.workDepth = obj.workDepth // Option[Double],
        this.PAM = obj.PAM // Option[Boolean],
        this.specificPrescriptions = obj.specificPrescriptions // Option[Boolean]
    }
}