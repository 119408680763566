/* eslint-disable camelcase */
import ReferencialDto from '../../../dto/ReferencialDto'

export default class CityDto extends ReferencialDto {
    constructor(object) {
        super(object)
        this.code = object.id
        this.name = object.name
        this.labelWithCode = `${object.name} [${object.id}]`
        this.situation = object.situation
        this.departmentNumber = object.departmentNumber
        this.area = object.area
        this.countryCode = object.countryCode
        this.x = object.x
        this.y = object.y
        this.projection = object.projection
        this.email = object.email
        this.phone = object.phone
        this.population = object.population
        this.kml = object.kml
        this.climateSector = object.climateSector
        this.link_postalCode = object.link_postalCode || []
        this.headers = ['code', 'name', 'email', 'phone']
    }
}