import CatchmentThunk from 'catchment/actions/CatchmentThunk'
import Table from 'components/datatable/Table'
import React, { useEffect, useMemo } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import { getLabel } from 'utils/StoreUtils'
import { nbPerPageLabel } from 'referencial/constants/ReferencialConstants'
import CityAction from 'referencial/components/city/actions/CityAction'
import Icon from 'components/icon/Icon'
import { every } from 'lodash'
import Job from 'import/dto/Job'

const CatchmentTrendJob = ({
    job = {},
    onChangeParameters = () => {},
}) => {
    const dispatch = useDispatch()

    const {
        catchments,
        catchmentPoints,
        contributors,
        departments,
    } = useSelector(store => ({
        catchments: store.CatchmentReducer.catchments,
        catchmentPoints: store.CatchmentReducer.catchmentPoints,
        contributors: store.ContributorReducer.contributors,
        departments: store.CityReducer.departments,
    }), shallowEqual)

    useEffect(() => {
        dispatch(CatchmentThunk.fetchCatchments())
        dispatch(CityAction.fetchDepartment())
        dispatch(CatchmentThunk.fetchCatchmentPoints())
    }, [dispatch])

    const selectedCatchments = JSON.parse(job.parameters?.parameters[0] || '{}')?.catchments ?? []

    const catchmentFormated = useMemo(() => {
        return catchments.map(catchment => {
            const points = catchmentPoints.filter(p => p.id === catchment.id)
            const mainPoint = points.find(p => p.mainPoint)
            const buildingOwner = mainPoint?.owner || (!!points.length && points.every(p => p.owner === points[0].owner) && points[0].owner)
            return {
                nullValue: <Icon icon={selectedCatchments.includes(catchment.id) ? 'check_box' : 'check_box_outline_blank'} />,
                id: catchment.id,
                department: getLabel(departments, catchment.department, 'labelWithCode'),
                code: catchment.code,
                catchment: catchment.name,
                mainCatchmentPoint: mainPoint?.code,
                nbPoint: points.length,
                grenelle: catchment.grenelleWork ? i18n.yes : i18n.no,
                buildingOwner: buildingOwner ? getLabel(contributors, buildingOwner, 'labelDisplay', 'id') : '',
                aac: catchment.aacNum,
            }
        })
    }, [catchmentPoints, catchments, contributors, departments, selectedCatchments])

    const actions = every(catchments, c => selectedCatchments.some(id => id === c.id)) ? [{
        onClick: () => onChangeParameters([JSON.stringify({ catchments: [] })]),
        iconName: 'check_box_outline_blank',
        tooltip: i18n.unselectAll,
    }] : [{
        onClick: () => onChangeParameters([JSON.stringify({ catchments: catchments.map(c => c.id) })]),
        iconName: 'check_box',
        tooltip: i18n.selectAll,
    }]

    return (
        <Table
            title={i18n.catchments}
            data={catchmentFormated}
            paging
            nbPerPageLabel={nbPerPageLabel}
            type={{ headers: ['nullValue', 'department', 'code', 'catchment', 'mainCatchmentPoint', 'nbPoint', 'grenelle', 'buildingOwner', 'aac'] }}
            onClick={c => {
                const newSelected = selectedCatchments.includes(c.id) ? selectedCatchments.filter(id => id !== c.id) : [...selectedCatchments, c.id]
                onChangeParameters([JSON.stringify({ catchments: newSelected })])
            }}
            actions={actions}
            condensed
            sortable
            id='catchments_table'
        />
    )
}

CatchmentTrendJob.propTypes = {
    job: PropTypes.instanceOf(Job).isRequired,
    onChangeParameters: PropTypes.func.isRequired,
}

export default CatchmentTrendJob