import React, { useMemo, useState } from 'react'
import { connect, shallowEqual, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import TelecomDto from '../dto/TelecomDto'
import GenericReferencialDto from '../../../dto/GenericReferencialDto'
import ContributorDto from '../../../../referencial/components/contributor/dto/ContributorDto'
import Select from '../../../../components/forms/Select'
import Input from '../../../../components/forms/Input'
import Textarea from '../../../../components/forms/Textarea'
import { getMatTypes } from '../../../../utils/MaterielUtils'
import NetworkDto from '../../../../referencial/components/network/dto/NetworkDto'
import Checkbox from '../../../../components/forms/Checkbox'
import NumberField from '../../../../components/forms/NumberField'
import PowerSupplyTypeDto from '../../powerSupply/dto/PowerSupplyTypeDto'
import moment from 'moment'
import { authorizeContributors, authorizeFilter } from 'utils/HabilitationUtil'
import User from 'account/dto/User'
import { removeNullKeys } from 'utils/StoreUtils'
import { omit } from 'lodash'
import ConfirmModal from 'components/modal/ConfirmModal'
import MultiContributorsAutocomplete from 'referencial/components/contributor/components/MultiContributorsAutocomplete'
import { TELECOM_ID } from 'materiel/constants/MaterielConstants'
import { isFieldRequired } from 'materiel/utils/MaterielUtils'
import { DATEACHAT_OBLIGATOIRE, NUMIMEI_OBLIGATOIRE } from 'materiel/constants/MaterielSettingRuleConstant'
import SimpleDatePicker from 'components/forms/SimpleDatePicker'

const TelecomPanel = ({
    telecom = {},
    telecomTypes = [],
    active = false,
    disabled = true,
    contributors = [],
    accountUser = {},
    networks = [],
    powerSupplyTypes = [],
    onChange = () => { },
}) => {
    const {
        materielSettingRules,
    } = useSelector(store => ({
        materielSettingRules: store.MaterielReducer.materielSettingRules,
    }), shallowEqual)

    const [isOpen, setOpen] = useState(false)
    const [selectedType, setSelectedType] = useState()

    const rules = useMemo(() => {
        return materielSettingRules.filter(({ idMaterielType }) => idMaterielType === TELECOM_ID)
    }, [materielSettingRules])

    return (
        <div className='col s12 margin-bottom-1' >
            <div className='row no-margin' style={{ paddingTop: '3px' }}>
                <Select
                    options={getMatTypes(telecomTypes, telecom.telecomType)}
                    label={i18n.telecomType}
                    col={4}
                    onChange={v => {
                        const type = telecomTypes.find(({ id }) => v === id)
                        if (type) {
                            setOpen(true)
                            setSelectedType(type)
                        } else {
                            onChange({ telecomType: v })
                        }
                    }}
                    obligatory
                    value={telecom.telecomType}
                    nullLabel='&nbsp;'
                    active={active}
                    disabled={disabled}
                />
                <Input
                    col={4}
                    title={i18n.immoNumber}
                    value={telecom.reference}
                    onChange={value => onChange({ reference: value })}
                    active={active}
                    disabled={telecom.isEquipment || disabled}
                />
            </div>
            <div className='row no-margin valign-wrapper' style={{ paddingTop: '3px' }}>
                <Input
                    col={6}
                    title={i18n.imeiAndSerialNumber}
                    value={telecom.imei}
                    onChange={value => onChange({ imei: value })}
                    active={active}
                    disabled={disabled}
                    obligatory={isFieldRequired(rules, NUMIMEI_OBLIGATOIRE)}
                />
                <Checkbox
                    col={6}
                    label={i18n.digitalLineOrRTC}
                    checked={telecom.digitalLine}
                    onChange={value => onChange({ digitalLine: value })}
                    active={active}
                    disabled={disabled}
                />
            </div>
            <div className='row no-margin' style={{ paddingTop: '3px' }}>
                <MultiContributorsAutocomplete
                    options={authorizeContributors(contributors, accountUser, telecom.manufacturerId)}
                    label={i18n.manufacturer}
                    col={6}
                    onChange={manufacturerId => onChange({ manufacturerId })}
                    values={telecom.manufacturerId}
                    disabled={telecom.isEquipment || disabled}
                    keyLabel='labelDisplay'
                />
                <MultiContributorsAutocomplete
                    options={authorizeContributors(contributors, accountUser, telecom.providerId)}
                    label={i18n.provider}
                    col={6}
                    onChange={providerId => onChange({ providerId })}
                    values={telecom.providerId}
                    disabled={telecom.isEquipment || disabled}
                    keyLabel='labelDisplay'
                />
            </div>
            <div className='row no-margin' style={{ paddingTop: '3px' }}>
                <Select
                    options={authorizeFilter(networks, accountUser, telecom.networkCode)}
                    label={i18n.network}
                    col={12}
                    onChange={value => onChange({ networkCode: value })}
                    value={telecom.networkCode}
                    nullLabel='&nbsp;'
                    active={active}
                    disabled={telecom.isEquipment || disabled}
                />
            </div>
            <div className='row no-margin valign-wrapper' style={{ paddingTop: '3px' }}>
                <Select
                    options={getMatTypes(powerSupplyTypes, telecom.powerSupplyType)}
                    label={i18n.powerSupplyType}
                    col={3}
                    onChange={value => onChange({ powerSupplyType: value })}
                    value={telecom.powerSupplyType}
                    nullLabel='&nbsp;'
                    active={active}
                    disabled={disabled}
                />
                <NumberField
                    col={3}
                    title={i18n.batteryCount}
                    value={telecom.pileNumber}
                    onChange={value => onChange({ pileNumber: value })}
                    active={active}
                    disabled={disabled}
                />
                <NumberField
                    col={3}
                    title={i18n.supplyTime}
                    value={telecom.supplyTime}
                    onChange={value => onChange({ supplyTime: value })}
                    active={active}
                    disabled={disabled}
                />
                <Checkbox
                    col={3}
                    label={i18n.desiccant}
                    checked={telecom.desiccant === '1'}
                    onChange={value => onChange({ desiccant: value ? '1' : '0' })}
                    active={active}
                    disabled={disabled}
                />
            </div>
            <div className='row no-margin' style={{ paddingTop: '3px' }}>
                <SimpleDatePicker
                    id='purchaseDate'
                    label={i18n.purchaseDate}
                    value={telecom.purchaseDate}
                    onChange={v => onChange({ purchaseDate: v })}
                    col={3}
                    disabled={telecom.isEquipment || disabled}
                    max={telecom.openDate}
                    obligatory={isFieldRequired(rules, DATEACHAT_OBLIGATOIRE)}
                />
                <SimpleDatePicker
                    label={i18n.warrantyExpirationDate}
                    value={telecom.warrantyTime && moment(telecom.purchaseDate).add(telecom.warrantyTime, 'months').valueOf()}
                    col={3}
                    disabled
                />
                {/* <DatePicker
                onChange={(_, v) => onChange({ withdrawalDate: v })}
                id='withdrawalDate'
                title={i18n.withdrawalDate}
                value={telecom.withdrawalDate}
                col={3}
                active={active}
                disabled={telecom.isEquipment || disabled}
            />
            <DatePicker
                onChange={(_, v) => onChange({ replacementDate: v })}
                id='replacementDate'
                title={i18n.replaceDate}
                value={telecom.replacementDate}
                col={3}
                active={active}
                disabled={telecom.isEquipment || disabled}
            /> */}
            </div>
            <div className='row no-margin' style={{ paddingTop: '8px' }}>
                <Textarea
                    col={12}
                    title={i18n.comment}
                    value={telecom.comment}
                    onChange={value => onChange({ comment: value })}
                    active={active}
                    disabled={disabled}
                />
            </div>
            <ConfirmModal
                isOpen={isOpen}
                title={i18n.materielDefaultValuesMessage}
                onValidate={() => {
                    setOpen(false)
                    onChange(removeNullKeys({
                        telecomType: selectedType.id,
                        ...omit(selectedType, ['id', 'code', 'label', 'name', 'comment', 'updateDate', 'updateLogin', 'startDate', 'endDate', 'icon']),
                    }))
                }}
                onReject={() => {
                    setOpen(false)
                    onChange({ telecomType: selectedType.id })
                }}
                onClose={() => setOpen(false)}
            />
        </div >
    )
}

TelecomPanel.propTypes = {
    id: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    disabled: PropTypes.bool,
    active: PropTypes.bool,
    onChange: PropTypes.func,
    telecom: PropTypes.instanceOf(TelecomDto),
    telecomTypes: PropTypes.arrayOf(GenericReferencialDto),
    contributors: PropTypes.arrayOf(PropTypes.instanceOf(ContributorDto)),
    powerSupplyTypes: PropTypes.arrayOf(PropTypes.instanceOf(PowerSupplyTypeDto)),
    networks: PropTypes.arrayOf(PropTypes.instanceOf(NetworkDto)),
    accountUser: PropTypes.instanceOf(User),
}

const mapStateToProps = store => ({
    contributors: store.ContributorReducer.contributors,
    telecomTypes: store.TelecomReducer.telecomTypes,
    powerSupplyTypes: store.PowerSupplyReducer.powerSupplyTypes,
    networks: store.NetworkReducer.networks,
    accountUser: store.AccountReducer.accountUser,
})

export default connect(mapStateToProps)(TelecomPanel)
