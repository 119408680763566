export default class DtoSearchHydrobio {
    constructor(obj) {
        this.qualitometer = obj.qualitometer // Double,
        this.operation = obj.operation // Double,
        this.list = obj.list // Option[Double] = None,
        this.index = obj.index // Option[Double] = None,
        this.operationDate = obj.operationDate // Option[DateTime] = None,
        this.taxon = obj.taxon // Option[String] = None,
        this.enumerationA = obj.enumerationA // Option [Double] = None,
        this.percentageA = obj.percentageA // Option [Double] = None,
        this.enumerationB = obj.enumerationB // Option [Double] = None,
        this.percentageB = obj.percentageB // Option [Double] = None,
        this.enumerationC = obj.enumerationC // Option [Double] = None,
        this.percentageC = obj.percentageC // Option [Double] = None,
        this.unit = obj.unit // Option [Double] = None,
        this.remark = obj.remark?.toString() || 1 // Option [Double] = None,
        this.enumerationC2 = obj.enumerationC2 // Option [Double] = None,
        this.enumerationP1 = obj.enumerationP1 // Option [Double] = None,
        this.enumerationP2 = obj.enumerationP2 // Option [Double] = None,
        this.enumerationP3 = obj.enumerationP3 // Option [Double] = None,
        this.enumerationP4 = obj.enumerationP4 // Option [Double] = None,
        this.enumerationP5 = obj.enumerationP5 // Option [Double] = None,
        this.enumerationP6 = obj.enumerationP6 // Option [Double] = None,
        this.enumerationP7 = obj.enumerationP7 // Option [Double] = None,
        this.enumerationP8 = obj.enumerationP8 // Option [Double] = None,
        this.enumerationP9 = obj.enumerationP9 // Option [Double] = None,
        this.enumerationP10 = obj.enumerationP10 // Option [Double] = None,
        this.enumerationP11 = obj.enumerationP11 // Option [Double] = None,
        this.enumerationP12 = obj.enumerationP12 // Option [Double] = None,
        this.qualification = obj.qualification // Option [Double] = None,
        this.status = obj.status?.toString() // Option [Double] = None,
        this.labo = obj.labo // Option [Double] = None,
        this.support = obj.support // Option [Double] = None,
        this.determinator = obj.determinator // Option [Double] = None
    }
}