import PropTypes from 'prop-types'
import React, { Component } from 'react'
import i18n from 'simple-react-i18n'
import ProgressCard from '../../../../components/card/ProgressCard'
import Checkbox from '../../../../components/forms/Checkbox'
import Input from '../../../../components/forms/Input'
import NumberField from '../../../../components/forms/NumberField'
import Select from '../../../../components/forms/Select'
import Row from '../../../../components/react/Row'
import Job from '../../../dto/DtoJob'
import { Grid } from '@mui/material'
import PresentationCard from '../../../../components/card/PresentationCard'
import CenterIcon from '../../../../components/icon/CenterIcon'

class FTPJobPanel extends Component {
    constructor(props) {
        super(props)
        this.state = {
            dataLoaded: true,
            progress: 0,
            fileName: '',
        }
    }

    onChangeFilters = (changes) => {
        const parameters = {
            ...this.props.job.parameters,
            filters: [JSON.stringify({
                ...this.getFilters(),
                ...changes,
            })],
        }
        this.props.onChangeJob({ parameters })
    }

    getFilters = () => this.props.job.parameters.filters.length ? JSON.parse(this.props.job.parameters.filters[0]) : { port: 22, protocol: 'FTP' }

    render() {
        if (this.state.dataLoaded) {
            const disabled = !this.props.isEditMode
            const filters = this.getFilters()
            const protocolList = [
                { code: 'FTP', name: 'FTP/FTPS' },
                { code: 'SFTP', name: 'SFTP' },
            ]
            const centerStyle = {
                'align-items': 'center',
                display: 'flex',
                justifyContent: 'center',
            }
            return (
                <div>
                    <div className='row no-margin valign-wrapper'>
                        <fieldset className='width-100'>
                            <legend>&nbsp;{ i18n.identifiants }&nbsp;</legend>
                            <Input col={ 3 } title={ i18n.host } onChange={ v => this.onChangeFilters({ host: v }) } disabled={ disabled } value={ filters.host } />
                            <Input col={ 3 } title={ i18n.user } onChange={ v => this.onChangeFilters({ user: v }) } disabled={ disabled } value={ filters.user } />
                            <Input col={ 3 } title={ i18n.password } onChange={ v => this.onChangeFilters({ password: v }) } disabled={ disabled } value={ filters.password } passwordType/>
                            <NumberField col={ 1 } title={ i18n.port } onChange={ v => this.onChangeFilters({ port: v }) } disabled={ disabled } value={ filters.port } />
                            <Select col={ 2 } options={ protocolList} label='Protocole' value={ filters.protocol } onChange={ v => this.onChangeFilters({ protocol: v }) } disabled={ disabled }/>
                        </fieldset>
                    </div>
                    <div className='row no-margin valign-wrapper'>
                        <fieldset className='width-100'>
                            <legend>&nbsp;{ 'Mode de transfert' }&nbsp;</legend>
                            <Grid container justifyContent='center' alignItems='center' spacing={3}>
                                <Grid item xs={4} >
                                    <PresentationCard
                                        iconComponent={<CenterIcon
                                            icon='file_download'
                                            color='#4f88b5'
                                            additionalStyle={{ paddingRight: 5 }}
                                        />}
                                        iconContainerStyle={centerStyle}
                                        title='Téléchargement'
                                        onClick={() => this.onChangeFilters({ mode: 'download' })}
                                        isFocused={!filters.mode || filters.mode === 'download'}
                                        description={'Transfert depuis le FTP distant vers le serveur SIEau'}
                                        dashedBorder
                                        disabled={ disabled }
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <PresentationCard
                                        iconComponent={<CenterIcon
                                            icon='file_upload'
                                            color='#4f88b5'
                                            additionalStyle={{ paddingRight: 5 }}
                                        />}
                                        iconContainerStyle={centerStyle}
                                        title='Dépot'
                                        onClick={() => this.onChangeFilters({ mode: 'upload' })}
                                        description={'Transfert depuis le serveur SIEau vers le FTP distant'}
                                        isFocused={filters.mode === 'upload'}
                                        dashedBorder
                                        disabled={ disabled }
                                    />
                                </Grid>
                            </Grid>
                        </fieldset>
                    </div>
                    <div className='row no-margin valign-wrapper'>
                        <fieldset className='width-100'>
                            <legend>&nbsp;{ 'Répertoire' }&nbsp;</legend>
                            <Row className='valign-wrapper'>
                                <Input col={ 3 } title={ `${filters.mode === 'upload' ? 'Destination' : 'Source'} (FTP distant)` } onChange={ v => this.onChangeFilters({ distantDirectory: v }) } disabled={ disabled } value={ filters.distantDirectory } />
                                <Input col={ 3 } title={ `${filters.mode === 'upload' ? 'Source' : 'Destination'} (serveur Aquasys)` } onChange={ v => this.onChangeFilters({ localDirectory: v }) } disabled={ disabled } value={ filters.localDirectory } />
                                <Input col={ 3 } title={ 'Filtre (Optionnel)' } onChange={ v => this.onChangeFilters({ fileFilter: v }) } disabled={ disabled } value={ filters.fileFilter } />
                                <Checkbox col={ 3 } checked={ filters.noManageSubFolders } label={ 'Ne pas regarder les sous-dossiers' } onChange={ v => this.onChangeFilters({ noManageSubFolders: v }) } disabled={ disabled }/>
                            </Row>
                            <Row className='valign-wrapper'>
                                <Checkbox col={ 3 } checked={ filters.dontDownloadTwice } label={ 'Ne pas récupérer les fichiers déjà présents' } onChange={ v => this.onChangeFilters({ dontDownloadTwice: v }) } disabled={ disabled }/>
                                <Checkbox col={ 4 } checked={ filters.dontDownloadFilesInJournal } label={ 'Ne pas récupérer les fichiers déjà téléchargés au moins une fois' } onChange={ v => this.onChangeFilters({ dontDownloadFilesInJournal: v }) } disabled={ disabled }/>
                                <Checkbox col={ 3 } checked={ filters.localPassiveMode } label={ 'Mode passif (requis sur certains FTP)' } onChange={ v => this.onChangeFilters({ localPassiveMode: v }) } disabled={ disabled }/>
                                <Checkbox col={ 2 } checked={ filters.updateQgis } label={ i18n.updateQgis } onChange={ v => this.onChangeFilters({ updateQgis: v }) } disabled={ disabled }/>
                            </Row>
                        </fieldset>
                    </div>
                    <div className='row no-margin valign-wrapper'>
                        <fieldset className='width-100'>
                            <legend>&nbsp;{ 'Fichiers ZIP' }&nbsp;</legend>
                            <Row>
                                <Checkbox col={ 12 } checked={ filters.useZip } label={ 'Décompresser les fichiers ZIP' } onChange={ v => this.onChangeFilters({ useZip: v }) } disabled={ disabled }/>
                            </Row>
                            <Row>
                                <Checkbox col={ 12 } checked={ filters.unzipOverwrite } label={ 'Ecraser les fichiers décompressés' } onChange={ v => this.onChangeFilters({ unzipOverwrite: v }) } disabled={ disabled }/>
                            </Row>
                            <Row>
                                <Checkbox col={ 12 } checked={ filters.deleteUnzipped } label={ 'Supprimer les fichiers ZIP après décompression' } onChange={ v => this.onChangeFilters({ deleteUnzipped: v }) } disabled={ disabled }/>
                            </Row>
                        </fieldset>
                    </div>
                </div>
            )
        }
        return <ProgressCard progress={ this.state.progress }/>
    }
}

FTPJobPanel.propTypes = {
    job: PropTypes.instanceOf(Job).isRequired,
    onChangeJob: PropTypes.func.isRequired,
    isEditMode: PropTypes.bool,
}

export default FTPJobPanel