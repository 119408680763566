import { Icon } from '@mui/material'
import DtoAccountHabilitation from 'account/dto/DtoAccountHabilitation'
import { AGRI } from 'administration/components/user/constants/HabilitationConstants'
import AgriAction from 'agriAdministration/actions/AgriAction'
import ColorfulCard from 'components/card/ColorfulCard'
import TabList from 'components/list/TabList'
import { AccordionDetailsMUI, AccordionMUI, AccordionSummaryMUI } from 'components/styled/Accordions'
import { push } from '@lagunovsky/redux-react-router'
import DtoEventExploitation from 'events/dto/DtoEventExploitation'
import DtoExploitationExportFull from 'exploitations/dto/DtoExploitationExportFull'
import HomeAction from 'home/actions/HomeAction'
import { flattenDeep, groupBy, maxBy, minBy, orderBy } from 'lodash'
import VariousMaterielDto from 'materiel/components/variousMateriel/dto/VariousMaterielDto'
import moment from 'moment'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import DtoSandreCode from 'referencial/dto/DtoSandreCode'
import i18n from 'simple-react-i18n'
import { getDate, getYear } from 'utils/DateUtil'
import { hasValue } from 'utils/NumberUtil'
import { getLabel } from 'utils/StoreUtils'
import { getI18nTitleData, i18nExist, i18nize } from 'utils/StringUtil'
import CampaignAction from '../../../campaign/actions/CampaignAction'
import ActionComponent from '../../../components/ActionComponent'
import Card from '../../../components/card/Card'
import ProgressCard from '../../../components/card/ProgressCard'
import EventsAction from '../../../events/actions/EventsAction'
import EventPieChart from '../../../events/components/chart/EventPieChart'
import EventsStationTypeBarChart from '../../../events/components/chart/EventsStationTypeBarChart'
import EventsTypeLegendPanel from '../../../events/components/chart/EventsTypeLegendPanel'
import EventsTypeVerticalBarChart from '../../../events/components/chart/EventsTypeVerticalBarChart'
import { EVENT_TYPES } from '../../../events/constants/EventsConstants'
import DtoEventHydrological from '../../../events/dto/DtoEventHydrological'
import DtoEventInstallation from '../../../events/dto/DtoEventInstallation'
import DtoEventPiezometer from '../../../events/dto/DtoEventPiezometer'
import DtoEventQualitometer from '../../../events/dto/DtoEventQualitometer'
import { PATH_GLOBALEVENTS } from '../../../home/constants/RouteConstants'
import DtoHydrometricStation from '../../../hydrometry/dto/DtoHydrometricStation'
import DtoInstallation from '../../../installation/dto/installation/DtoInstallation'
import DtoPiezometerLight from '../../../piezometry/dto/DtoPiezometerLight'
import DtoQualitometerLight from '../../../quality/dto/DtoQualitometerLight'
import CityDto from '../../../referencial/components/city/dto/CityDto'
import DtoStationType from '../../../referencial/dto/DtoStationType'
import { STATION_NAME_ASSOCIATION } from '../../../station/constants/StationConstants'
import { getSiteUrl } from '../../../utils/mapUtils/SiteTypes'
import { getStations, getStationTypeNameFromTypeCode } from '../../../utils/StationUtils'
import DtoGlobalEvents from '../../dto/DtoGlobalEvents'
import DtoGlobalStations from '../../dto/DtoGlobalStations'
import FormGlobalEvents from './FormGlobalEvents'
import GlobalEventsCard from './GlobalEventsCard'

class GlobalEventsApp extends ActionComponent {
    constructor(props) {
        super(props)
        this.state = {
            filter: {},
            sortBy: 'date',
            dataLoaded: false,
            progress: 0,
        }
    }

    componentDidMount() {
        const { accountHabilitations } = this.props
        this.props.loadGlobalEventsApp(() => this.setState({ dataLoaded: true }), p => this.setState({ progress: p }))
        if (accountHabilitations.some((h) => h.habilitation === AGRI)) {
            this.props.fetchAllExploitationsEvents()
            this.props.fetchExploitationsExportFullData()
        }
        this.props.fetchAllCampaigns()
        this.setActions({
            export: this.onExport,
        })
    }

    componentWillReceiveProps() {
        this.props.setTitle([
            {
                title: i18n.journal,
                href: PATH_GLOBALEVENTS,
            },
        ])
    }

    getExploitationEventsTypes = () => {
        return [{
            code: 'c',
            id: 1,
            label: i18n.added,
            color: this.getColor('c'),
            icon: 'add_box',
        }, {
            code: 'u',
            id: 2,
            label: i18n.modification,
            color: this.getColor('u'),
            icon: 'edit',
        }, {
            code: 'd',
            id: 3,
            label: i18n.deleting,
            color: this.getColor('d'),
            icon: 'delete',
        }, {
            code: 'v',
            id: 4,
            label: i18n.validation,
            color: this.getColor('v'),
            icon: 'delete',
        }]
    }

    onExport = () => {
        const { exploitationsExportFullData } = this.props
        const eventTypes = i18nize(EVENT_TYPES)
        const events = this.getFilterEvents(this.getAllEvents())
        const groups = groupBy(events, this.getGroupMethod())
        const results = orderBy(Object.keys(groups), key => key, ['desc']).map(key => {
            const stationGroup = groupBy(groups[key], 'code')
            return Object.keys(stationGroup).map(stationId => orderBy(stationGroup[stationId], 'date', 'desc'))
        })
        const eventsTypesExploitation = this.getExploitationEventsTypes()
        const exportData = flattenDeep(results).map(e => {
            if (e.idExploitation) {
                const exploitation = exploitationsExportFullData.find((exp) => exp.idExploitation === e.idExploitation)
                const type = (eventsTypesExploitation.find((t) => t.code === e.eventType) || {}).label
                return {
                    ...e,
                    stationType: i18n.exploitation,
                    code: exploitation.codification,
                    station: exploitation.name,
                    date: getDate(e.eventDate),
                    type: `${type} - ${i18nExist(e.associateType) || ''}`,
                }
            }
            const station = getStations(this.props, e.stationType).find(s => s.id === e.stationId) || { typeName: 'unknownType' }
            return {
                ...e,
                city: getLabel(this.props.cities, station.townCode),
                cityCode: station.townCode,
                stationType: i18n[station.typeName],
                code: station.code,
                station: station.name,
                date: getDate(e.date),
                type: getLabel(eventTypes, e.eventType) || i18n.unknownType,
            }
        })
        exportData[0].headers = ['stationType', 'code', 'cityCode', 'city', 'station', 'date', 'type', 'comment', 'problem', 'solution']
        return {
            data: exportData,
            exportType: 'xlsx',
            titleFile: i18n.journal,
        }
    }

    getActive = (panel) => {
        return panel === this.state.sortBy ? 'active' : ''
    }

    getFilterEvents = allEvents => {
        const {
            type,
            stationType,
            startDate,
            endDate,
            eventType,
            campaign,
        } = this.state.filter
        const stationTypeFilter = hasValue(type) ? allEvents.filter(e => e.stationType === type) : allEvents
        const stationListFilter = stationType ? stationTypeFilter.filter(({ stationCode }) => stationCode === stationType) : stationTypeFilter
        const startDateFilter = hasValue(startDate) ? stationListFilter.filter(({ date }) => date >= startDate) : stationListFilter
        const endDateFilter = hasValue(endDate) ? startDateFilter.filter(({ date }) => date <= endDate) : startDateFilter
        const eventTypeFilter = hasValue(eventType) ? endDateFilter.filter(e => e.eventType === eventType) : endDateFilter
        return hasValue(campaign) ? eventTypeFilter.filter(({ campaignCode }) => campaignCode === campaign) : eventTypeFilter
    }

    getGroupMethod = () => {
        switch (this.state.sortBy) {
            case 'referent':
                return e => hasValue(e.contactCode) ? getLabel(this.props.contacts, e.contactCode) : i18n.unknownReferent
            case 'eventType':
                return e => getLabel(i18nize(EVENT_TYPES), e.eventType) || i18n.unknownType
            case 'stationType':
                return e => getLabel(this.getSelectStationTypes(), e.stationType) || i18n.unknownStation
            default:
                return e => getYear(e.date, i18n.thisYear)
        }
    }

    getObjectInfoExploitationEvent = (associateType, associateId, exportFormat) => {
        const { installations, variousMateriels, contacts, citiesIndex, sandreCodes } = this.props
        switch (associateType) {
            case 'installation':
                const point = installations.find(({ id }) => id === associateId)
                if (point) {
                    const city = point.townCode ? (citiesIndex[point.townCode] || null) : null
                    const boldInfo = `${point.name ? `${point.name}` : ''}${point.code ? ` [${point.code}]` : ''}`
                    const extraInfo = `${city ? `, ${city.labelWithCode}` : ''}${point.parcel && point.section ? `, ${point.parcel} ${point.section}` : ''}`
                    if (exportFormat) {
                        return `${boldInfo}${extraInfo}`
                    }
                    return (
                        <>
                            <span className='bold'>{boldInfo}</span>
                            {extraInfo}
                        </>
                    )
                }
                return i18n.unknownPoint
            case 'contact':
                const contact = contacts.find(({ id }) => id === associateId)
                if (contact) {
                    if (exportFormat) {
                        return contact.name || ''
                    }
                    return <span className='bold'>{contact.name || ''}</span>
                }
                return i18n.unknownContact
            case 'materiel':
                const materiel = variousMateriels.find(({ id }) => id === associateId)
                if (materiel) {
                    if (materiel.pump) {
                        const pumpCategory = sandreCodes.find((c) => c.field === 'MAT.MOBILITE' && c.code === materiel.mobilityCode)
                        const pumpType = materiel.pump.pumpType ? sandreCodes.find((c) => c.field === 'POMPES.TYPE' && c.code === materiel.pump.pumpType) : null
                        const boldInfo = materiel.reference || ''
                        const extraInfo = `${pumpCategory ? `, ${pumpCategory.name}` : ''}${pumpType ? `, ${pumpType.name}` : ''}`
                        if (exportFormat) {
                            return `${boldInfo}${extraInfo}`
                        }
                        return (
                            <>
                                <span className='bold'>{boldInfo}</span>
                                {extraInfo}
                            </>
                        )
                    }
                    if (materiel.counter) {
                        const boldInfo = materiel.reference || ''
                        const extraInfo = `${materiel.counter.brand ? `, ${materiel.counter.brand}` : ''}${materiel.counter.installationDate ? `, ${getDate(materiel.counter.installationDate)}` : ''}`
                        if (exportFormat) {
                            return `${boldInfo}${extraInfo}`
                        }
                        return (
                            <>
                                <span className='bold'>{boldInfo}</span>
                                {extraInfo}
                            </>
                        )
                    }
                    if (exportFormat) {
                        return materiel.reference || ''
                    }
                    return <span className='bold'>{materiel.reference || ''}</span>
                }
                return i18n.unknownMaterial
            case 'declaration':
                if (exportFormat) {
                    return i18n.declaration
                }
                return <span className='bold'>{i18n.declaration}</span>
            default:
                return i18n.unknown
        }
    }

    getIconExploitationEvent = (event) => {
        switch (event.associateType) {
            case 'installation':
                return 'room'
            case 'contact':
                return 'person'
            case 'materiel':
                return 'router'
            case 'declaration':
                return 'description'
            default:
                return 'folder'
        }
    }

    getColor = (eventTypeId) => {
        switch (eventTypeId) {
            case 'c':
                return 'GREEN'
            case 'u':
                return 'ORANGE'
            case 'v':
                return 'BLUE'
            case 'd':
                return 'RED'
            default:
                return 'GREY'
        }
    }

    getCardExploitationEvents = (events, idExploitation) => {
        const { exploitationsExportFullData } = this.props
        const eventsTypesExploitation = this.getExploitationEventsTypes()
        const exploitation = exploitationsExportFullData.find((e) => e.idExploitation === idExploitation)
        return (
            <div className='row no-margin'>
                <Card contentClassName='grey lighten-2'>
                    <div className='row no-margin valign-wrapper padding-top-1 padding-bottom-1'>
                        <div className='col s1'>
                            <div className='iconeTypeResearchWrapper'>
                                <Icon>folder</Icon>
                            </div>
                        </div>
                        <div className='col s11'>
                            {`${exploitation.codification} - ${exploitation.name} (${events.length} ${i18n[`event${events.length >= 2 ? 's' : ''}`]})`}
                        </div>
                    </div>
                </Card>
                {events.map(e => {
                    const type = (eventsTypesExploitation.find((t) => t.code === e.eventType) || {}).label
                    return (
                        <ColorfulCard color={this.getColor(e.eventType)} onClick={() => this.props.push(`dossiers/${idExploitation}/events`)}>
                            <div className='row no-margin valign-wrapper padding-left-1 clickable'>
                                <div className='col s2'>
                                    {e.eventDate ? getDate(e.eventDate) : ''}
                                </div>
                                <div className='col s4'>
                                    {this.getObjectInfoExploitationEvent(e.associateType, e.associateId)}
                                </div>
                                <div className='col s4'>
                                    <p className='collection-content no-margin'>{e.comment}</p>
                                </div>
                                <div className='col s2'>
                                    <h6 className='valign-wrapper'>
                                        <Icon className='margin-left-1 margin-right-1'>{this.getIconExploitationEvent(e)}</Icon>
                                        <div>
                                            <span className='collection-title' style={ { fontWeight: 'bold' } }>{type}</span><br />
                                            <div className='collection-content'>{i18nExist(e.associateType) || ''}</div>
                                        </div>
                                    </h6>
                                </div>
                            </div>
                        </ColorfulCard>
                    )
                })}
            </div>
        )
    }

    getData = events => {
        if (events.length) {
            const groups = groupBy(events, this.getGroupMethod())
            return orderBy(Object.keys(groups), key => key, ['desc']).map(key => {
                const groupEvents = groups[key]
                const stationGroup = groupBy(groupEvents, 'code')
                const stations = Object.keys(stationGroup).map(stationId => {
                    const stationEvents = orderBy(stationGroup[stationId], 'date', 'desc')
                    if (stationId.startsWith(('EXP_#_'))) {
                        return this.getCardExploitationEvents(stationEvents, parseInt(stationId.split('_#_')[1]))
                    }
                    const firstEvent = stationEvents[0]
                    const icon = getSiteUrl(STATION_NAME_ASSOCIATION[getStationTypeNameFromTypeCode(firstEvent.stationType)])
                    const station = getStations(this.props, firstEvent.stationType).find(s => s.id == stationId) || {}
                    const stationCode = station.code ? `[${station.code}] ` : ''
                    const stationName = station.name ? `- ${station.name} ` : ''
                    return (
                        <div className='row no-margin'>
                            <Card contentClassName='grey lighten-2'>
                                <div className='row no-margin valign-wrapper padding-top-1 padding-bottom-1'>
                                    <div className='col s1'>
                                        <div className='iconeTypeResearchWrapper'>
                                            <img src={icon} className='responsive-img iconeTypeResearch' />
                                        </div>
                                    </div>
                                    <div className='col s11'>
                                        {`${stationCode}${getLabel(this.props.cities, station.townCode)} ${stationName}(${stationEvents.length} ${i18n[`event${stationEvents.length >= 2 ? 's' : ''}`]})`}
                                    </div>
                                </div>
                            </Card>
                            {stationEvents.map(e => (
                                <GlobalEventsCard
                                    data={e}
                                />
                            ))}
                        </div>
                    )
                })
                return (
                    <div className='padding-1'>
                        <AccordionMUI>
                            <AccordionSummaryMUI>
                                {`${key} (${groupEvents.length} ${getI18nTitleData(i18n.event, i18n.events, groupEvents)})`}
                            </AccordionSummaryMUI>
                            <AccordionDetailsMUI nopadding>
                                {stations}
                            </AccordionDetailsMUI>
                        </AccordionMUI>
                    </div>
                )
            })
        }
        return <h5 className='margin-left-1'>{i18n.noDataToDisplay}</h5>
    }

    getAllEvents = () => {
        return [
            ...[
                ...this.props.piezometryAllEvents.filter(({ stationId }) => this.props.piezometers.find(({ id }) => id === stationId)),
                ...this.props.qualitometryAllEvents.filter(({ stationId }) => this.props.qualitometers.find(({ id }) => id === stationId)),
                ...this.props.installationAllEvents.filter(({ stationId }) => this.props.installations.find(({ id }) => id === stationId)),
                ...this.props.hydrometryStationAllEvents.filter(({ stationId }) => this.props.hydrometricStations.find(({ id }) => stationId === id)),
            ].map(e => new DtoGlobalEvents(e)),
            ...this.props.exploitationsAllEvents.filter(({ idExploitation }) => this.props.exploitationsExportFullData.find((e) => e.idExploitation === idExploitation)).map(e => ({ ...e, code: `EXP_#_${e.idExploitation}`, date: e.eventDate })),
        ]
    }

    getAllStations = () => [
        ...this.props.piezometers,
        ...this.props.qualitometers,
        ...this.props.installations,
        ...this.props.hydrometricStations,
    ].map(e => new DtoGlobalStations(e))

    getAllStationsComponent = list => list.map(({ id, code, typeName, name }) => ({
        id,
        code,
        typeName,
        name: `[${code}] - ${name || ''}`,
    }))

    getSelectStationTypes = () => this.props.stationTypes.map(s => ({
        code: s.code,
        type: s.name,
        name: i18n[s.i18n],
    }))

    getDateFilteredEvents = (allEvents) => {
        const { filter } = this.state
        if (!filter.startDate && !filter.endDate) {
            return { title: moment().year(), events: orderBy(allEvents.filter(e => moment(e.date).year() === moment().year()), 'date', 'desc') }
        }
        const filter1 = hasValue(filter.startDate) ? allEvents.filter(e => e.date > filter.startDate) : allEvents
        const events = hasValue(filter.endDate) ? filter1.filter(e => e.date < filter.endDate) : filter1
        if (!events.length) {
            return { title: '', events: [] }
        }
        const minYear = getYear(minBy(events, 'date').date)
        const maxYear = getYear(maxBy(events, 'date').date)
        return { title: minYear === maxYear ? minYear : `${minYear} - ${maxYear}`, events: orderBy(events, 'date', 'desc') }
    }

    getPanel = () => {
        const stations = this.getAllStations()
        const events = this.getAllEvents()
        const filteredEventsData = this.getDateFilteredEvents(events)
        return (
            <div id='contents-app'>
                <div className='row no-margin'>
                    <Card maxWidth={1400} className='margin-top-1 transparent no-box-shadow' contentClassName={'transparent'}>
                        <div className='col s9'>
                            <Card>
                                <div className='row no-margin'>
                                    <EventsTypeVerticalBarChart events={events} />
                                </div>
                                <FormGlobalEvents
                                    filter={this.state.filter}
                                    onValidate={filter => this.setState({ filter })}
                                    stations={this.getAllStationsComponent(stations)}
                                />
                            </Card>
                            <TabList
                                onChangeTab={(v) => this.setState({ sortBy: v })}
                                tabs={[
                                    {
                                        value: 'date',
                                        label: i18n.byDate,
                                        icon: 'insert_invitation',
                                    },
                                    {
                                        value: 'referent',
                                        label: i18n.byReferent,
                                        icon: 'person',
                                    },
                                    {
                                        value: 'eventType',
                                        label: i18n.byEventType,
                                        icon: 'donut_small',
                                    },
                                    {
                                        value: 'stationType',
                                        label: i18n.byStationType,
                                        icon: 'location_on',
                                    },
                                ]}
                            >
                                {this.getData(this.getFilterEvents(events))}
                            </TabList>
                            <div className='row no-margin'>
                                <Card className='margin-top-1 transparent no-box-shadow' contentClassName={'transparent'}>
                                    <div>
                                        <ul className='sieau-collapsible' data-collapsible='expandable' />
                                    </div>
                                </Card>
                            </div>
                        </div>
                        <div className='col s3'>
                            <Card className='margin-top-1'>
                                <div className='center-align'>
                                    <EventsTypeLegendPanel />
                                    <h5 className='bold'>{i18n.historic}</h5>
                                    <EventPieChart
                                        events={events}
                                        withoutLegend
                                    />
                                    <EventsStationTypeBarChart events={events} />
                                    <h5 className='bold'>{filteredEventsData.title}</h5>
                                    <EventPieChart
                                        events={filteredEventsData.events}
                                        withoutLegend
                                    />
                                    <EventsStationTypeBarChart events={filteredEventsData.events} />
                                </div>
                            </Card>
                        </div>
                    </Card>
                </div>
            </div>
        )
    }

    render() {
        if (this.state.dataLoaded) {
            return this.getPanel()
        }
        return <ProgressCard progress={this.state.progress} />
    }
}

GlobalEventsApp.propTypes = {
    piezometryAllEvents: PropTypes.arrayOf(PropTypes.instanceOf(DtoEventPiezometer)),
    hydrometryStationAllEvents: PropTypes.arrayOf(PropTypes.instanceOf(DtoEventHydrological)),
    qualitometryAllEvents: PropTypes.arrayOf(PropTypes.instanceOf(DtoEventQualitometer)),
    installationAllEvents: PropTypes.arrayOf(PropTypes.instanceOf(DtoEventInstallation)),
    exploitationsAllEvents: PropTypes.arrayOf(PropTypes.instanceOf(DtoEventExploitation)),
    hydrometricStations: PropTypes.arrayOf(PropTypes.instanceOf(DtoHydrometricStation)),
    piezometers: PropTypes.arrayOf(PropTypes.instanceOf(DtoPiezometerLight)),
    qualitometers: PropTypes.arrayOf(PropTypes.instanceOf(DtoQualitometerLight)),
    installations: PropTypes.arrayOf(PropTypes.instanceOf(DtoInstallation)),
    cities: PropTypes.arrayOf(PropTypes.instanceOf(CityDto)),
    stationTypes: PropTypes.arrayOf(PropTypes.instanceOf(DtoStationType)),
    sandreCodes: PropTypes.arrayOf(PropTypes.instanceOf(DtoSandreCode)),
    exploitationsExportFullData: PropTypes.arrayOf(PropTypes.instanceOf(DtoExploitationExportFull)),
    variousMateriels: PropTypes.arrayOf(PropTypes.instanceOf(VariousMaterielDto)),
    accountHabilitations: PropTypes.arrayOf(PropTypes.instanceOf(DtoAccountHabilitation)),
}

const mapStateToProps = store => ({
    piezometryAllEvents: store.EventsReducer.piezometryAllEvents,
    hydrometryStationAllEvents: store.EventsReducer.hydrometryStationAllEvents,
    qualitometryAllEvents: store.EventsReducer.qualitometryAllEvents,
    installationAllEvents: store.EventsReducer.installationAllEvents,
    exploitationsAllEvents: store.AgriReducer.exploitationsAllEvents,
    stationTypes: store.ReferencialReducer.stationTypes,
    hydrometricStations: store.HydrometryReducer.hydrometricStations,
    piezometers: store.PiezometryReducer.piezometersLight,
    qualitometers: store.QualityReducer.qualitometersLight,
    installations: store.InstallationReducer.installations,
    contacts: store.ContactReducer.contacts,
    cities: store.CityReducer.cities,
    citiesIndex: store.CityReducer.citiesIndex,
    sandreCodes: store.ReferencialReducer.sandreCodes,
    exploitationsExportFullData: store.AgriReducer.exploitationsExportFullData,
    variousMateriels: store.VariousMaterielReducer.variousMateriels,
    accountHabilitations: store.AccountReducer.accountHabilitations,
})

const mapDispatchToProps = {
    fetchAllCampaigns: CampaignAction.fetchAllCampaigns,
    loadGlobalEventsApp: EventsAction.loadGlobalEventsApp,
    fetchAllExploitationsEvents: AgriAction.fetchAllExploitationsEvents,
    fetchExploitationsExportFullData: AgriAction.fetchExploitationsExportFullData,
    setTitle: HomeAction.setTitle,
    push,
}

export default connect(mapStateToProps, mapDispatchToProps)(GlobalEventsApp)