/* eslint-disable camelcase */
import DtoAnswerDocument from './DtoAnswerDocument'

export default class DtoAnswer {
    constructor(obj) {
        this.recipientCode = obj.recipientCode // Option[Long] = None,
        this.recipientType = obj.recipientType // Option[Long] = None,
        this.idQuestion = obj.idQuestion // Option[Long] = None,
        this.answerLong = obj.answerLong // Option[Long] = None,
        this.answerDate = obj.answerDate // Option[DateTime] = None,
        this.answerText = obj.answerText // Option[String] = None,
        this.answerMultiChoice = obj.answerMultiChoice // Option[String] = None,
        this.idSurvey = obj.idSurvey // Option[Long] = None,
        this.answerBool = obj.answerBool // Option[Boolean] = None,
        this.idParagraph = obj.idParagraph // Option[Long] = None,
        this.idVisit = obj.idVisit // Option[Long] = None,
        this.idCampaign = obj.idCampaign // Option[Long] = None,

        this.link_documents = obj.link_documents ? obj.link_documents.map(d => new DtoAnswerDocument(d)) : [] // Option[Seq[CmsAnswerDoc]] = None
    }
}