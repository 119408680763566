import React from 'react'
import ActionComponent from 'components/ActionComponent'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import SieauAction from '../../../../components/sieau/SieauAction'
import VariousMaterielAction from '../actions/VariousMaterielAction'
import i18n from 'simple-react-i18n'
import VariousMaterielDto from '../dto/VariousMaterielDto'
import { updateMaterialize } from '../../../../utils/MaterializeUtils'
import ToastrAction from 'toastr/actions/ToastrAction'
import SituationPanel from '../../situation/SituationPanel'
import DtoVariousMaterielSituation from '../dto/DtoVariousMaterielSituation'
import AssigmentChartPanel from '../../chart/AssigmentChartPanel'
import ContributorItem from '../../../../referencial/components/contributor/dto/ContributorItem'
import { isEqual, omit } from 'lodash'
import UpdatePanel from '../../../../components/updatePanel/UpdatePanel'
import LastSituationMarkerPanel from '../../situation/LastSituationMarkerPanel'
import DtoPicture from '../../../../components/picture/dto/DtoPicture'
import SmallPicturePanel from '../../../../components/picture/SmallPicturePanel'
import FilePanel from '../../../../components/file/FilePanel'
import DtoFile from '../../../../components/file/dto/DtoFile'
import { push } from '@lagunovsky/redux-react-router'
import AdministratorPanel from '../../administrator/AdministratorPanel'
import User from '../../../../account/dto/User'
import { getUser } from '../../../../utils/SettingUtils'
import VariousMaterielPanel from './VariousMaterielPanel'
import DtoVariousMaterielType from '../dto/DtoVariousMaterielType'
import AppStore from 'store/AppStore'
import HomeAction from 'home/actions/HomeAction'
import { isSaveBlocked } from '../../../utils/MaterielUtils'
import DtoMaterielSettingRule from '../../../dto/DtoMaterielSettingRule'
import { componentHasHabilitations } from '../../../../utils/HabilitationUtil'
import { H_MAT_VARIOUS } from '../../../../account/constants/AccessRulesConstants'
import { VARIOUS_ID } from 'materiel/constants/MaterielConstants'

class VariousMaterielApp extends ActionComponent {
    state = {
        variousMateriel: {},
        isEditMode: false,
    }

    changeAdministrator = administrator => this.setState(({ variousMateriel }) => ({ variousMateriel: { ...variousMateriel, administrator } }))

    changeStateAttribute = changes => this.setState({ variousMateriel: { ...this.state.variousMateriel, ...changes } })

    componentWillUnmount() {
        this.props.resetVariousMateriel()
        this.setActions({})
    }

    changeEditMode = (bool) => {
        this.setState({ isEditMode: bool })
    }

    fetchVariousMateriel = id => this.props.fetchVariousMateriel(id).then(json => {
        if (json.id) {
            this.props.fetchVariousMaterielSituations(json.id)
            this.props.fetchVariousMaterielPictures(json.id)
            this.props.fetchVariousMaterielFiles(json.serialNumber)
            this.setState({ variousMateriel: { ...json } })
        }
    })

    componentDidMount = () => {
        if (!componentHasHabilitations(H_MAT_VARIOUS)) { // A modifier quand react-router sera à jour
            this.props.push('/unauthorized')
            return
        }
        if (this.props.params.id !== 'new') {
            this.fetchVariousMateriel(this.props.params.id).then(() => {
            })
        } else {
            this.changeEditMode(true)
        }
        if (!this.props.variousMateriels.length) {
            this.props.fetchVariousMateriels()
        }
        if (!this.props.variousMaterielTypes.length) {
            this.props.fetchVariousMaterielTypes()
        }
        AppStore.dispatch(HomeAction.setHelpLink('materiel', ''))
        this.applyActions()
    }

    defineArianne = (type, title) => {
        this.props.forceFetch('title', [
            {
                title: i18n.materiels,
                href: 'materiel',
            },
            {
                title: i18n.variousMateriels,
                href: 'materiel/variousMateriel',
            },
            {
                title: type,
                href: `materiel/variousMateriel/${this.props.params.id}`,
            },
            {
                title,
                href: `materiel/variousMateriel/${this.props.params.id}`,
            },
        ])
    }

    componentDidUpdate = prevProps => {
        if (prevProps.params.id !== this.props.params.id) {
            this.fetchVariousMateriel(this.props.params.id)
        }
        this.applyActions()
        if (!isEqual(prevProps.variousMateriel, this.props.variousMateriel) || !isEqual(prevProps.variousMaterielTypes, this.props.variousMaterielTypes)) {
            const {
                reference = '',
                serialNumber = '',
                materielType,
                id,
            } = this.props.variousMateriel
            const title = reference || serialNumber || id
            const type = (this.props.variousMaterielTypes.find(t => t.code === parseInt(materielType)) || {}).label || i18n.notDefined
            this.defineArianne(type, title)
        }
    }

    applyActions = () => {
        const actions = (() => {
            if (this.props.params.id === 'new' && !this.props.variousMateriel.id) {
                return {
                    save: () => {
                        const rules = this.props.materielSettingRules.filter(({ idMaterielType }) => idMaterielType === VARIOUS_ID)
                        if (isSaveBlocked(this.props.variousMateriels, this.state.variousMateriel, rules, 'materielType')) return

                        this.props.createVariousMateriel(this.state.variousMateriel).then(id => {
                            if (id) {
                                const situation = {
                                    idVarious: id,
                                    situationDate: this.state.variousMateriel.purchaseDate,
                                    statusCode: 2,
                                }
                                this.props.saveVariousMaterielSituation(situation)
                            }
                        })
                    },
                    cancel: () => {
                        this.props.push('/materiel/variousMateriel')
                    },
                }
            }
            if (this.state.isEditMode) {
                return {
                    save: () => {
                        const rules = this.props.materielSettingRules.filter(({ idMaterielType }) => idMaterielType === VARIOUS_ID)
                        if (isSaveBlocked(this.props.variousMateriels, this.state.variousMateriel, rules, 'materielType')) return

                        this.props.updateVariousMateriel(this.state.variousMateriel).then(success => success && this.changeEditMode(false))
                    },
                    cancel: () => {
                        this.fetchVariousMateriel(this.props.params.id)
                        this.changeEditMode(false)
                    },
                }
            }
            return {
                edit: () => {
                    this.changeEditMode(true)
                },
                delete: () => {
                    this.props.deleteVariousMateriel(this.props.params.id)
                },
                replace: () => {
                    this.props.toastrInfo(i18n.inDeveloppmentFunctionnality)
                },
            }
        })()
        if (getUser().consultant === '1') {
            this.setActions(omit(actions, ['save', 'edit', 'delete', 'replace']))
        } else {
            this.setActions(actions)
        }
        updateMaterialize()
    }

    deleteSituation = id => this.props.deleteVariousMaterielSituation(id, this.props.params.id)

    getMaterielNumber = ({ serialNumber, reference }) => serialNumber || reference || ''

    getVariousMaterielFormat = () => {
        const { variousMateriels, variousMaterielTypes } = this.props
        return variousMateriels.filter(({ isEquipment }) => !isEquipment).map(variousMateriel => {
            const label = (() => {
                const number = this.getMaterielNumber(variousMateriel)
                if (variousMateriel.variousMaterielType) {
                    const type = variousMaterielTypes.find(t => t.id === variousMateriel.variousMaterielType)
                    if (type) {
                        return `${type.label} - ${number}`
                    }
                }
                return number
            })()
            return {
                id: variousMateriel.id,
                label,
            }
        })
    }

    render() {
        const disabled = { active: this.state.isEditMode, disabled: !this.state.isEditMode }
        return (
            <div className='row no-margin'>
                <div id='file' className='col s12'>
                    <div className='card margin-top-0-75-rem'>
                        <div className='card-content'>
                            <div className='row no-margin'>
                                <div className='col s9'>
                                    <div className='col s1'>
                                        <LastSituationMarkerPanel
                                            materielSituation={ this.props.variousMaterielSituations }
                                        />
                                    </div>
                                    <div className='col s11'>
                                        <VariousMaterielPanel
                                            variousMateriel={ this.state.variousMateriel }
                                            onChange={ this.changeStateAttribute }
                                            { ...disabled }
                                        />
                                        {
                                            !this.props.variousMateriel.isEquipment && (
                                                <div className='col s12'>
                                                    <SituationPanel
                                                        materielSituation={this.props.variousMaterielSituations}
                                                        active={this.state.isEditMode}
                                                        idMateriel={this.props.variousMateriel.id}
                                                        serialNumber={this.props.variousMateriel.serialNumber}
                                                        purchaseDate={this.props.variousMateriel.purchaseDate}
                                                        idKey={'idVarious'}
                                                        lastSituations={this.props.variousMaterielsLastSituations}
                                                        materials={this.getVariousMaterielFormat()}
                                                        saveFunction={this.props.saveVariousMaterielSituation}
                                                        deleteFunction={this.deleteSituation}
                                                    />
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                                <div className='col s3'>
                                    <div className='row no-margin'>
                                        <div className='col s12'>
                                            <UpdatePanel
                                                updateDate={this.props.variousMateriel.updateDate}
                                                updateLogin={this.props.variousMateriel.updateLogin}
                                                jobExecId={this.props.variousMateriel.jobExecutionId}
                                            />
                                        </div>
                                        <div className='col s12'>
                                            <div className='row no-margin card'>
                                                <div className='col s12 card-content'>
                                                    <AdministratorPanel
                                                        onChange={this.changeAdministrator}
                                                        selectedValue={this.state.variousMateriel.administrator}
                                                        isActive={!this.props.variousMateriel.isEquipment && this.state.isEditMode}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col s12'>
                                            <AssigmentChartPanel
                                                materielSituation={ this.props.variousMaterielSituations }
                                            />
                                            <SmallPicturePanel element={ 'variousMateriel' }
                                                pictures={ this.props.variousMaterielPictures }
                                            />
                                            <FilePanel files={ this.props.variousMaterielFiles } />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

VariousMaterielApp.propTypes = {
    params: PropTypes.shape({
        id: PropTypes.number,
    }),
    variousMateriel: PropTypes.instanceOf(VariousMaterielDto),
    getLink: PropTypes.func,
    changeEditMode: PropTypes.func,
    isEditMode: PropTypes.bool,
    accountUser: PropTypes.instanceOf(User),
    variousMaterielTypes: PropTypes.arrayOf(PropTypes.instanceOf(DtoVariousMaterielType)),
    variousMaterielSituations: PropTypes.arrayOf(PropTypes.instanceOf(DtoVariousMaterielSituation)),
    contributors: PropTypes.arrayOf(PropTypes.instanceOf(ContributorItem)),
    variousMaterielPictures: PropTypes.arrayOf(PropTypes.instanceOf(DtoPicture)),
    variousMaterielFiles: PropTypes.arrayOf(PropTypes.instanceOf(DtoFile)),
    variousMateriels: PropTypes.arrayOf(PropTypes.instanceOf(VariousMaterielDto)),
    variousMaterielsLastSituations: PropTypes.arrayOf(PropTypes.instanceOf(DtoVariousMaterielSituation)),
    materielSettingRules: PropTypes.arrayOf(PropTypes.instanceOf(DtoMaterielSettingRule)),
    push: PropTypes.func,
}

const mapStateToProps = store => ({
    variousMaterielTypes: store.VariousMaterielReducer.variousMaterielTypes,
    variousMaterielSituations: store.VariousMaterielReducer.variousMaterielSituations,
    variousMateriel: store.VariousMaterielReducer.variousMateriel,
    contributors: store.ContributorReducer.contributors,
    variousMaterielPictures: store.VariousMaterielReducer.variousMaterielPictures,
    variousMaterielFiles: store.VariousMaterielReducer.variousMaterielFiles,
    accountUser: store.AccountReducer.accountUser,
    variousMateriels: store.VariousMaterielReducer.variousMateriels,
    variousMaterielsLastSituations: store.VariousMaterielReducer.variousMaterielsLastSituations,
    materielSettingRules: store.MaterielReducer.materielSettingRules,
})

const mapDispatchToProp = {
    push,
    resetVariousMateriel: VariousMaterielAction.resetVariousMateriel,
    fetchVariousMateriel: VariousMaterielAction.fetchVariousMateriel,
    fetchVariousMateriels: VariousMaterielAction.fetchVariousMateriels,
    fetchVariousMaterielTypes: VariousMaterielAction.fetchVariousMaterielTypes,
    forceFetch: SieauAction.forceFetch,
    createVariousMateriel: VariousMaterielAction.createVariousMateriel,
    updateVariousMateriel: VariousMaterielAction.updateVariousMateriel,
    deleteVariousMateriel: VariousMaterielAction.deleteVariousMateriel,
    toastrInfo: ToastrAction.info,
    deleteVariousMaterielSituation: VariousMaterielAction.deleteVariousMaterielSituation,
    fetchVariousMaterielSituations: VariousMaterielAction.fetchVariousMaterielSituations,
    fetchVariousMaterielPictures: VariousMaterielAction.fetchVariousMaterielPictures,
    fetchVariousMaterielFiles: VariousMaterielAction.fetchVariousMaterielFiles,
    saveVariousMaterielSituation: VariousMaterielAction.saveVariousMaterielSituation,
}

export default connect(mapStateToProps, mapDispatchToProp)(VariousMaterielApp)
