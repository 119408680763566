import HomeAction from 'home/actions/HomeAction'
import { differenceBy, intersectionBy, isUndefined, maxBy, orderBy } from 'lodash'
import PropTypes from 'prop-types'
import React, { useEffect, useMemo, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import Card from '../../../../components/card/Card'
import ProgressCard from '../../../../components/card/ProgressCard'
import Select from '../../../../components/forms/Select'
import QualityAction from '../../../../quality/actions/QualityAction'
import { DELETE, INSERT, UPDATE } from '../../../../utils/constants/ActionConstants'
import { getSetting } from '../../../../utils/SettingUtils'
import { getLabel } from '../../../../utils/StoreUtils'
import AdministrationAction from '../../../actions/AdministrationAction'
import { THRESHOLD_FULL } from '../../../constants/AdministrationConstants'
import { Grid } from '@mui/material'
import useBoolean from 'utils/customHook/useBoolean'
import useTitle from 'utils/customHook/useTitle'
import ParameterAction from 'referencial/components/parameter/actions/ParameterAction'
import UnitAction from 'referencial/components/unit/actions/UnitAction'
import { StyledFieldSet } from 'components/StyledElements'
import useActions from 'utils/customHook/useActions'
import { PopinImportThresholds, StepperThreshold, Threshold } from './ComponentsOptionThreshold'
import ConfirmModal from 'components/modal/ConfirmModal'
import WaitAction from 'wait/WaitAction'
import TaxonAction from 'referencial/components/taxon/actions/TaxonAction'
import UserAction from 'administration/components/user/actions/UserAction'
import ReferencialAction from 'referencial/action/ReferencialAction'
import useStateAppSetting from 'utils/customHook/useStateAppSetting'
import useSandreList from 'utils/customHook/useSandreList'
import { THRESHOLD } from 'quality/constants/QualityConstants'
import FractionAction from 'referencial/components/fraction/actions/FractionAction'

const ListThresholds = ({
    thresholds = [],
    setThresholds = () => {},
    qualityThresholds = [],
    setQualityThresholds = () => {},
    isEditMode = false,
}) => {
    const {
        value: isOpen,
        setTrue: open,
        setFalse: close,
    } = useBoolean(false)
    const {
        value: isConfirmOpen,
        setTrue: openConfirm,
        setFalse: closeConfirm,
    } = useBoolean(false)

    const [selectedCode, setSelectedCode] = useState()

    const thresholdsFormatted = useMemo(() => {
        const parentsThresholds = thresholds.filter(th => isUndefined(th.referenceCode))
        const formatThreshold = th => {
            const qualityThreshold = qualityThresholds.find(qt => qt.thresholdCode === th.code)
            const childrens = thresholds.filter(child => child.referenceCode === `${th.code}`)
            return {
                threshold: th,
                childrens: childrens.map(formatThreshold),
                qualityThreshold,
            }
        }
        return parentsThresholds.map(formatThreshold)
    }, [qualityThresholds, thresholds])

    const selectedThreshold = useMemo(() => {
        return thresholds.find(th => th.code === selectedCode)
    }, [selectedCode, thresholds])

    const selectedQualityThreshold = useMemo(() => {
        return qualityThresholds.find(qt => qt.thresholdCode === selectedCode)
    }, [qualityThresholds, selectedCode])

    return (
        <>
            {
                thresholdsFormatted.map(({ threshold, childrens, qualityThreshold }) => {
                    return (
                        <Threshold
                            threshold={threshold}
                            qualityThreshold={qualityThreshold}
                            childrenThresholds={childrens}
                            isEditMode={isEditMode}

                            toogleActive={code => {
                                setThresholds(prev => prev.map(th => th.code === code ? { ...th, status: th.status === 1 ? 0 : 1, isUpdated: true } : th))
                            }}
                            onUpdate={code => {
                                setSelectedCode(code)
                                open()
                            }}
                            onDelete={code => {
                                setSelectedCode(code)
                                openConfirm()
                            }}
                            setQualityThresholds={(thresholdCode, newQualityThresholds) => {
                                setThresholds(prev => prev.map(th => th.code === thresholdCode ? { ...th, isUpdated: true } : th))
                                setQualityThresholds(prev => {
                                    return prev.map(qt => {
                                        if (qt.thresholdCode === thresholdCode) {
                                            return {
                                                ...qt,
                                                thresholds: newQualityThresholds,
                                            }
                                        }
                                        return qt
                                    })
                                })
                            }}

                            key={threshold.code}
                        />
                    )
                })
            }
            <StepperThreshold
                isOpen={isOpen}
                onClose={close}
                onSave={(threshold, qualityThreshold) => {
                    setThresholds(prev => prev.map(th => th.code === threshold.code ? { ...threshold, isUpdated: true } : th))
                    setQualityThresholds(prev => prev.map(qt => qt.thresholdCode === threshold.code ? qualityThreshold : qt))
                    close()
                }}

                selectedThreshold={selectedThreshold}
                selectedQualityThreshold={selectedQualityThreshold}
            />
            <ConfirmModal
                isOpen={isConfirmOpen}
                title={i18n.deletingConfirmation}
                onValidate={() => {
                    setQualityThresholds(prev => prev.filter(qt => qt.thresholdCode !== selectedCode))
                    setThresholds(prev => prev.filter(th => th.code !== selectedCode))
                    closeConfirm()
                }}
                onClose={closeConfirm}
            />
        </>
    )
}

ListThresholds.propTypes = {
    thresholds: PropTypes.arrayOf(PropTypes.shape({
        code: PropTypes.number,
        name: PropTypes.string,
        comment: PropTypes.string,
        status: PropTypes.number,
    })),
    setThresholds: PropTypes.func,
    qualityThresholds: PropTypes.arrayOf(PropTypes.shape({
        thresholdCode: PropTypes.number,
        thresholdType: PropTypes.string,
        thresholds: PropTypes.arrayOf(PropTypes.shape({
            parameterCode: PropTypes.string,
            unit: PropTypes.string,
            station: PropTypes.number,
            fraction: PropTypes.string,
            threshold1: PropTypes.number,
            threshold2: PropTypes.number,
            threshold3: PropTypes.number,
            threshold4: PropTypes.number,
        })),
    })),
    setQualityThresholds: PropTypes.func,
    isEditMode: PropTypes.bool,
}

const QualityThresholdApp = ({

}) => {
    const dispatch = useDispatch()
    const {
        applicationSettings,
        thresholdsProps,
        qualityThresholdsProps,
        parameters,
        taxons,
        units,
        fractions,
        qualitometers,
    } = useSelector(store => ({
        applicationSettings: store.AdministrationReducer.applicationSettings,
        thresholdsProps: store.QualityReducer.thresholds,
        qualityThresholdsProps: store.QualityReducer.qualityThresholds,
        parameters: store.ParameterReducer.parameters,
        taxons: store.TaxonReducer.taxons,
        units: store.UnitReducer.units,
        fractions: store.FractionReducer.fractions,
        qualitometers: store.QualityReducer.qualitometersLight,
    }), shallowEqual)

    const lexiconCalcium = useSandreList('CALCIUM')
    const lexiconFRType = useSandreList('QUALITOMETRES.TYPE_FR.PLAN')
    const lexiconFishContext = useSandreList('QUALITOMETRES.CONTEXTEPISCICOLE')

    const thresholdTypes = useMemo(() => {
        return [
            { id: THRESHOLD.PC, label: i18n.PCparameters },
            { id: THRESHOLD.TAXON, label: i18n.taxons },
            { id: THRESHOLD.INDICE, label: i18n.indices },
            // { id: SELECTION.ENVIRONMENTAL_PARAMETER, label: i18n.environmentalParameters },
            // { id: SELECTION.STATE, label: i18n.states },
        ]
    }, [])

    const [thresholdPC, setThresholdPC] = useStateAppSetting('SEUILS')
    const [thresholdTaxon, setThresholdTaxon] = useStateAppSetting('SEUILS.TAXONS')
    const [thresholdState, setThresholdState] = useStateAppSetting('SEUILS.ETATS')

    useTitle(() => [{
        title: i18n.quality,
        href: '/quality',
    }, {
        title: i18n.qualityOptions,
        href: '/quality/qualityOptions',
    }, {
        title: i18n.thresholds,
        href: '/quality/qualityOptions/threshold',
    }], [])

    const {
        value: isEditMode,
        setTrue: editMode,
        setFalse: readMode,
    } = useBoolean(false)
    const {
        value: isOpen,
        setTrue: open,
        setFalse: close,
    } = useBoolean(false)
    const {
        value: isImportOpen,
        setTrue: openImport,
        setFalse: closeImport,
    } = useBoolean(false)

    const [thresholds, setThresholds] = useState([])
    const [qualityThresholds, setQualityThresholds] = useState([])

    const [progress, setProgress] = useState(0)
    const {
        value: isLoaded,
        setTrue: loaded,
    } = useBoolean(false)

    useEffect(() => {
        dispatch(HomeAction.setHelpLink('qualite', '62'))
        dispatch(ParameterAction.fetchParameters())
        dispatch(TaxonAction.fetchTaxons())
        dispatch(UnitAction.fetchUnits())
        dispatch(FractionAction.fetchFractions())
        dispatch(ReferencialAction.fetchSandreCodes())
        dispatch(QualityAction.fetchQualitometersLight())
        dispatch(QualityAction.fetchAllThresholds(setProgress)).then(result => {
            setThresholds(result.thresholds)
            setQualityThresholds(result.qualityThresholds)
            loaded()
        })
        dispatch(UserAction.fetchApplicativeUsers())
    }, [])

    const orderedThresholds = useMemo(() => {
        return orderBy(thresholds, 'name')
    }, [thresholds])

    const thresholdPCList = useMemo(() => orderedThresholds.filter(t => t.thresholdType === '0'), [orderedThresholds])
    const thresholdTaxonList = useMemo(() => orderedThresholds.filter(t => t.thresholdType === '1'), [orderedThresholds])
    const thresholdStateList = useMemo(() => orderedThresholds.filter(t => t.thresholdType === '4'), [orderedThresholds])

    const onExport = () => {
        const data = orderedThresholds.flatMap(threshold => {
            const qualityThreshold = qualityThresholds.find(qt => qt.thresholdCode === threshold.code)
            if (isUndefined(qualityThreshold)) {
                return [{
                    code: threshold.code,
                    name: threshold.name,
                    comment: threshold.comment,
                    parent: getLabel(thresholds, threshold.referenceCode),
                }]
            }
            const listReferencial = [THRESHOLD.PC, THRESHOLD.INDICE].includes(threshold.thresholdType) ? parameters : taxons
            const thresholdType = thresholdTypes.find(t => t.id === threshold.thresholdType)?.label
            return qualityThreshold.thresholds.map(t => ({
                thresholdType: thresholdType ?? '',
                code: threshold.code,
                name: threshold.name,
                comment: threshold.comment,
                parent: getLabel(thresholds, threshold.referenceCode),
                parameter: getLabel(listReferencial, t.parameterCode, 'labelWithCode'),
                unit: getLabel(units, t.unit, 'symbolWithName'),
                station: getLabel(qualitometers, t.station, 'displayLabel'),
                fraction: getLabel(fractions, t.fraction, 'labelWithCode'),
                threshold1: t.threshold1,
                threshold2: t.threshold2,
                threshold3: t.threshold3,
                threshold4: t.threshold4,
                hardness: lexiconCalcium.find(l => l.code === threshold.calciumClass)?.name,
                frType: lexiconFRType.find(l => l.code === threshold.frType)?.name,
                fishContext: lexiconFishContext.find(l => l.code === threshold.fishCode)?.name,
            }))
        })
        return {
            data: data.length ? [
                { ...data[0], headers: ['thresholdType', 'code', 'name', 'comment', 'parent', 'parameter', 'unit', 'station', 'fraction', 'threshold1', 'threshold2', 'threshold3', 'threshold4', 'hardness', 'frType', 'fishContext'] },
                ...data.slice(1),
            ] : data,
            exportType: 'xlsx',
            titleFile: i18n.thresholds,
        }
    }

    useActions(() => {
        if (isEditMode) {
            return {
                new: open,
                cancel: () => {
                    readMode()
                    setThresholds(thresholdsProps)
                    setQualityThresholds(qualityThresholdsProps)
                },
                import: openImport,
                save: () => {
                    dispatch(WaitAction.waitStart())
                    const thresholdPCFound = thresholds.find(t => t.code === thresholdPC)
                    const thresholdTaxonFound = thresholds.find(t => t.code === thresholdTaxon)
                    const thresholdStateFound = thresholds.find(t => t.code === thresholdState)
                    const newSettings = [
                        { parameter: 'SEUILS', value: !isUndefined(thresholdPCFound) && thresholdPCFound.status !== 0 ? `${thresholdPC}` : '' },
                        { parameter: 'SEUILS.TAXONS', value: !isUndefined(thresholdTaxonFound) && thresholdTaxonFound.status !== 0 ? `${thresholdTaxon}` : '' },
                        { parameter: 'SEUILS.ETATS', value: !isUndefined(thresholdStateFound) && thresholdStateFound.status !== 0 ? `${thresholdState}` : '' },
                    ].filter(p => getSetting(applicationSettings, p.parameter) !== p.value)
                    if (newSettings.length) {
                        dispatch(AdministrationAction.updateSieauParameters(newSettings))
                    }

                    const thresholdsCreated = differenceBy(thresholds, thresholdsProps, 'code').map(th => {
                        return {
                            dataType: THRESHOLD_FULL,
                            updateType: INSERT,
                            thresholdFullInput: {
                                threshold: th,
                                parameterThresholds: qualityThresholds.find(qt => qt.thresholdCode === th.code)?.thresholds.map(t => ({ ...t, listType: th.thresholdType })),
                            },
                        }
                    })
                    const thresholdsDeleted = differenceBy(thresholdsProps, thresholds, 'code').map(th => {
                        return {
                            dataType: THRESHOLD_FULL,
                            updateType: DELETE,
                            thresholdFullInput: {
                                threshold: th,
                                parameterThresholds: [],
                            },
                        }
                    })
                    const thresholdsUpdated = intersectionBy(thresholds, thresholdsProps, 'code').filter(t => t.isUpdated).map(th => {
                        return {
                            dataType: THRESHOLD_FULL,
                            updateType: UPDATE,
                            thresholdFullInput: {
                                threshold: th,
                                parameterThresholds: qualityThresholds.find(qt => qt.thresholdCode === th.code)?.thresholds.map(t => ({ ...t, thresholdType: th.code, listType: th.thresholdType })),
                            },
                        }
                    })

                    const updateActions = [...thresholdsCreated, ...thresholdsDeleted, ...thresholdsUpdated]

                    dispatch(QualityAction.updateThresholds(updateActions))
                        .then((res = { update: 0 }) => {
                            readMode()
                            if (res.update === 0) {
                                dispatch(WaitAction.waitStop())
                                return
                            }
                            dispatch(QualityAction.fetchAllThresholds())
                                .then(result => {
                                    setThresholds(result.thresholds)
                                    setQualityThresholds(result.qualityThresholds)
                                })
                                .finally(() => dispatch(WaitAction.waitStop()))
                        })
                },
            }
        }
        return {
            edit: editMode,
            export: onExport,
        }
    }, [isEditMode, thresholds, qualityThresholds, qualitometers, thresholdPC, thresholdTaxon, thresholdState, lexiconCalcium, lexiconFRType, lexiconFishContext])

    return (
        <div style={{ maxWidth: '1200px', marginLeft: 'auto', marginRight: 'auto', paddingBottom: '100px' }}>
            {
                !isLoaded && (
                    <ProgressCard progress={progress} />
                )
            }
            {
                isLoaded && (
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <StyledFieldSet>
                                <Grid container spacing={1} sx={{ paddingTop: '5px' }}>
                                    <Grid item xs={12}>
                                        <Select
                                            label={i18n.defaultThresholdPC}
                                            options={thresholdPCList}
                                            onChange={setThresholdPC}
                                            value={thresholdPC}
                                            nullLabel='&nbsp;'
                                            withThresholdGroups
                                            disabled={!isEditMode}
                                            active={isEditMode}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Select
                                            label={i18n.defaultThresholdTaxon}
                                            options={thresholdTaxonList}
                                            onChange={setThresholdTaxon}
                                            value={thresholdTaxon}
                                            nullLabel='&nbsp;'
                                            withThresholdGroups
                                            disabled={!isEditMode}
                                            active={isEditMode}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Select
                                            label={i18n.defaultThresholdState}
                                            options={thresholdStateList}
                                            onChange={setThresholdState}
                                            value={thresholdState}
                                            nullLabel='&nbsp;'
                                            withThresholdGroups
                                            disabled={!isEditMode}
                                            active={isEditMode}
                                        />
                                    </Grid>
                                </Grid>
                            </StyledFieldSet>
                        </Grid>
                        {
                            !thresholds.length && (
                                <Grid item xs={12}>
                                    <Card><h5 className='padding-1'>{i18n.noThresholds}</h5></Card>
                                </Grid>
                            )
                        }
                        <ListThresholds
                            thresholds={orderedThresholds}
                            setThresholds={setThresholds}
                            qualityThresholds={qualityThresholds}
                            setQualityThresholds={setQualityThresholds}
                            isEditMode={isEditMode}
                        />
                    </Grid>
                )
            }
            <StepperThreshold
                isOpen={isOpen}
                onClose={close}
                onSave={(threshold, qualityThreshold) => {
                    const newId = (maxBy(thresholds, 'code')?.code ?? 0) + 1
                    const newThreshold = { ...threshold, code: newId }
                    const newQualityThreshold = { ...qualityThreshold, thresholdCode: newId, thresholdType: threshold.thresholdType }
                    setThresholds(prev => [...prev, newThreshold])
                    setQualityThresholds(prev => [...prev, newQualityThreshold])
                    close()
                }}

                selectedThreshold={{ thresholdType: '0', users: [], administrators: [] }}
                selectedQualityThreshold={{ thresholds: [] }}
            />
            <PopinImportThresholds
                isOpen={isImportOpen}
                close={closeImport}
                newId={(maxBy(thresholds, 'code')?.code ?? 0) + 1}
                onValidate={(newThresholds, newQualityThresholds) => {
                    setThresholds(prev => [...prev, ...newThresholds])
                    setQualityThresholds(prev => [...prev, ...newQualityThresholds])
                    closeImport()
                }}
            />
        </div>
    )
}

export default QualityThresholdApp
