import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import i18n from 'simple-react-i18n'
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import DtoSampling from '../../dto/DtoSampling'
import Row from '../../../../../components/react/Row'
import SimpleDatePicker from '../../../../../components/forms/SimpleDatePicker'
import Input from '../../../../../components/forms/Input'
import Textarea from '../../../../../components/forms/Textarea'

const SamplingModal = ({
    isOpen = false,
    onValidate = () => {},
    onClose = () => { },
    selectedSampling = {},
}) => {
    const [sampling, setSampling] = useState(selectedSampling)
    const change = (changes) => setSampling({ ...sampling, ...changes })
    return (
        <Dialog
            fullWidth
            maxWidth='sm'
            open={isOpen}
            onClose={() => {}}
        >
            <DialogTitle>{i18n.editSampling}</DialogTitle>
            <DialogContent>
                <Row>
                    <SimpleDatePicker col={ 6 } value={ sampling.date } label={ i18n.date } onChange={ v => change({ date: v }) } />
                    <SimpleDatePicker col={ 6 } value={ sampling.arrivalDate } label={ i18n.arrivalDateLabo } onChange={ v => change({ arrivalDate: v }) } />
                </Row>
                <Row>
                    <Input col={ 6 } value={ sampling.ref } title={ i18n.reference } onChange={ v => change({ ref: v }) } />
                    <Input col={ 6 } value={ sampling.refLabo } title={ i18n.refLabo } onChange={ v => change({ refLabo: v }) } />
                </Row>
                <Row>
                    <Textarea value={ sampling.comment } title={ i18n.comment } onChange={ v => change({ comment: v }) } />
                </Row>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} variant='outlined' >
                    {i18n.cancel}
                </Button>
                <Button onClick={() => onValidate(sampling) } variant='contained' color='primary'>
                    {i18n.save}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

SamplingModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onValidate: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    onReject: PropTypes.func,
    selectedSampling: PropTypes.instanceOf(DtoSampling).isRequired,
}

export default SamplingModal