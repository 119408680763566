import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import IAEauAction from '../../../iaeau/IAEauAction'
import { setActions } from '../../../components/ActionUtil'
import IAEauModelStepper from './IAEauModelStepper'
import Table from '../../../components/datatable/Table'
import i18n from 'simple-react-i18n'
import { nbPerPageLabel } from '../../../referencial/constants/ReferencialConstants'
import {
    MODEL_OBJECTIVES_LABEL,
    MODEL_STATUS,
    MODEL_TYPES,
    MODEL_TYPES_LABEL
} from '../../../iaeau/constants/IAEauConstants'
import { getDate } from '../../../utils/DateUtil'
import { getModelStatusIcon, getModelStatusLabel } from '../../../iaeau/utils/IAEauUtils'
import ToastrAction from '../../../toastr/actions/ToastrAction'
import HomeAction from '../../../home/actions/HomeAction'
import { execByType, getStationTitle } from '../../../utils/StationUtils'
import { push } from '@lagunovsky/redux-react-router'
import { setConfirmationModal } from '../../../utils/FormUtils'
import { getI18nOrLabel } from '../../../utils/StringUtil'
import { useParams } from 'react-router'


const IAEauModelsApp = ({
    stationType,
}) => {
    const dispatch = useDispatch()
    const { id } = useParams() // station id

    const {
        iaeauModels,
        piezometer,
        hydrometricStation,
    } = useSelector(store => ({
        iaeauModels: store.IAEauReducer.iaeauModels,
        piezometer: store.StationReducer.piezometer,
        hydrometricStation: store.HydrometryReducer.hydrometricStation,
    }), shallowEqual)

    const [isOpen, setIsOpen] = useState(false)
    const [selectedModel, setSelectedModel] = useState({})

    useEffect(() => {
        const [station, dt] = execByType(stationType, {
            piezometry: () => [piezometer, -1],
            hydrometry: () => [hydrometricStation, 4],
        })
        dispatch(IAEauAction.getModels(stationType, parseInt(id)))
        setActions({
            new: () => {
                setSelectedModel({
                    idStation: parseInt(id),
                    idModel: 0,
                    typeId: dt,
                    horizonMode: 'days',
                    stationType,
                    params: JSON.stringify({ stations: [{ id: parseInt(id), typeName: stationType, code: station.code, name: station.name, dataTypes: [dt] }] }),
                })
                setIsOpen(true)
            },
        })
        dispatch(HomeAction.setTitle([{
            title: i18n[stationType],
            href: `${stationType}`,
        }, {
            title: getStationTitle(station),
            href: `station/${stationType}/${station.id}`,
        }, {
            title: i18n.models,
            href: `station/${stationType}/${station.id}/iaeauModels`,
        }]))
    }, [])

    const launchModel = (model) => {
        IAEauAction.launchModel(stationType, parseInt(id), model).then(() => {
            dispatch(ToastrAction.success(i18n.jobLaunched))
            dispatch(IAEauAction.getModels(stationType, parseInt(id)))
        })
    }

    const tableData = iaeauModels.map(model => {
        const pushtoModel = () => dispatch(push(`/station/${stationType}/${model.idStation}/iaeauModels/${model.idModel}`))
        return {
            nullValue: { value: '', rightIconColor: 'black', rightIconTip: i18n.runCalculation, rightIcon: 'play_circle_outline', rightIconClick: () => launchModel(model) },
            name: { value: model.name, onClick: pushtoModel },
            type: { value: model.typeModel ? getI18nOrLabel(MODEL_TYPES_LABEL[model.typeModel]) : '', onClick: pushtoModel },
            objective: { value: model.objective ? i18n[MODEL_OBJECTIVES_LABEL[model.objective]] : '', onClick: pushtoModel },
            horizon: { value: model.horizon, onClick: pushtoModel },
            confidenceIndicator: { value: '', onClick: pushtoModel },
            creation: { value: getDate(model.creationDate), onClick: pushtoModel },
            status: { value: getModelStatusIcon(model.statusCode, 30), setTooltip: () => getModelStatusLabel(model.statusCode) },
            lastExecution: { value: getDate(model.calculationDate), onClick: pushtoModel },
            dashboard: { value: '', leftIcon: model.displayDashboard ? 'check_box' : null, leftIconColor: 'black' },
            situation: { value: '', leftIcon: model.displaySituation ? 'check_box' : null, leftIconColor: 'black' },
            alert: { value: '', leftIcon: model.displayAlert ? 'check_box' : null, leftIconColor: 'black' },
            nullValue2: [MODEL_TYPES.ARIMA, MODEL_TYPES.AUGURES].includes(model.typeModel) ? { leftIcon: 'clear', leftIconColor: 'black', onClick: () => {
                setConfirmationModal(() => IAEauAction.deleteModel(stationType, parseInt(id), model).then(() => dispatch(IAEauAction.getModels(stationType, parseInt(id)))))
            } } : null,
        }
    })
    if (iaeauModels.some(m => [MODEL_STATUS.PROGRESS, MODEL_STATUS.DEMAND_PROGRESS, MODEL_STATUS.DEMAND_SENT, MODEL_STATUS.WAITING, MODEL_STATUS.LEARNING_PROGRESS].includes(m.statusCode))) {
        setTimeout(() => {
            dispatch(IAEauAction.getModels(stationType, parseInt(id)))
        }, 5000)
    }

    return (
        <div>
            { isOpen && <IAEauModelStepper {...{ stationType, id, isOpen, setIsOpen, selectedModel, setSelectedModel }} /> }
            <div className='padding-top-1 padding-left-1 padding-right-1'>
                <Table
                    data={tableData}
                    sortable
                    orderable
                    color
                    title={ i18n.models }
                    paging
                    nbPerPageLabel={nbPerPageLabel}
                    type={{ headers: ['nullValue2', 'nullValue', 'name', 'type', 'objective', 'horizon', 'confidenceIndicator', 'creation', 'status', 'lastExecution', 'dashboard', 'situation', 'alert', 'color'] }}
                    customHeaders={ { status: ' ' } }
                    condensed
                />
            </div>
        </div>
    )
}

IAEauModelsApp.propTypes = {
    stationType: PropTypes.string,
    id: PropTypes.string,
}

export default IAEauModelsApp