export default class DtoGlobalEvents {
    constructor(obj) {
        this.id = obj.number
        this.stationType = obj.stationType
        this.code = obj.code
        this.number = obj.number
        this.date = obj.date || obj.eventDate
        this.eventType = obj.eventType
        this.updateDate = obj.updateDate
        this.updateLogin = obj.updateLogin
        this.problem = obj.problem
        this.solution = obj.solution
        this.campaignCode = obj.campaignCode || obj.campaignsCode
        this.comment = obj.comment
        this.contactCode = obj.contactCode
        this.stationCode = obj.stationCode
        this.stationId = obj.code
    }
}