import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { exportModelFile, exportJasper } from '../../utils/ExportDataUtil'
import pdf from 'assets/pictures/logos/pdf.svg'
import xls from 'assets/pictures/logos/excel.svg'

class ExportModel extends Component {
    componentDidMount() {
        $('.dropdown-button').dropdown()
    }

    componentDidUpdate() {
        $('.dropdown-button').dropdown()
    }

    render() {
        const models = this.props.onExport().environmentModels
        if (models && models.length) {
            const modelList = models.map(model => {
                return (
                    <li>
                        <a onClick={() => {
                            const obj = this.props.onExport()
                            if ((model || '').includes('.zip')) {
                                exportJasper(parseInt(obj.stationId), obj.stationType, model.replace('.zip', ''), obj.qualityFilter)
                            } else {
                                exportModelFile({
                                    ...obj,
                                    filenameModelExport: model,
                                })
                            }
                        }}
                        >
                            <div className='row no-margin valign-wrapper dropdownLink collection-item'>
                                <div className='col s1'>
                                    <img className='clickable right' src={(model || '').includes('.zip') ? pdf : xls} style={{ height: 23, padding: 0 }} />
                                </div>
                                <div className='col s11'>
                                    <b style={{ fontSize: '0.9em' }}>{(model || '').replace('.zip', '.pdf')}</b>
                                </div>
                            </div>
                        </a>
                    </li>
                )
            })
            return (
                <div className='action-wrapper'>
                    <ul id='dropdownModels' className='dropdown-content dropdownLinks' style={{ overflowY: 'auto', whiteSpace: 'nowrap' }}>
                        {modelList}
                    </ul>
                    <a className='dropdown-button nav-actions' data-activates='dropdownModels'>
                        <i className='material-icons no-margin no-padding' id='export_model_action_navbar'>file_download</i>
                    </a>
                </div>
            )
        }
        return null
    }
}

ExportModel.propTypes = {
    onExport: PropTypes.func.isRequired,
}

export default ExportModel