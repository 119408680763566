import { Button, Dialog, DialogActions, DialogContent } from '@mui/material'
import { push } from '@lagunovsky/redux-react-router'
import { groupBy, orderBy, template } from 'lodash'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import DtoSandreCode from 'referencial/dto/DtoSandreCode'
import i18n from 'simple-react-i18n'
import ToastrAction from 'toastr/actions/ToastrAction'
import Checkbox from '../../../components/forms/Checkbox'
import Select from '../../../components/forms/Select'
import Icon from '../../../components/icon/Icon'
import GlobalCardList from '../../../components/list/cardList/GlobalCardList'
import PanelList from '../../../components/list/panelList/PanelList'
import Section from '../../../components/list/section/Section'
import SmallPictureLine from '../../../components/picture/SmallPictureLine'
import { contentsPath } from '../../../conf/basepath'
import CmsAction from '../../../events/actions/CmsAction'
import CMSCategoryDto from '../../../events/dto/CMSCategoryDto'
import CMSEventDto from '../../../events/dto/CMSEventDto'
import { getCmsIcon } from '../../../utils/CmsUtils'
import { getNewsColor } from '../../../utils/ColorUtil'
import { getDateWithHourString, getFullDate } from '../../../utils/DateUtil'
import { setConfirmationModal } from '../../../utils/FormUtils'
import { hasValue } from '../../../utils/NumberUtil'
import { getLogin, getUser } from '../../../utils/SettingUtils'
import { getLabel } from '../../../utils/StoreUtils'
import AlertCmsModal from './AlertCmsModal'

class PollutionMapTabs extends Component {
    state = {
        visibilityOption: [i18n.archived, i18n.draft],
        isOpen: false,
        openAcquittement: false,
        category: {},
        cms: {},
        statusChange: {},
        levelOptions: [
            { value: 4, label: i18n.crisis },
            { value: 3, label: i18n.warning },
            { value: 2, label: i18n.information },
            { value: 1, label: i18n.information },
            { value: 0, label: i18n.other },
        ],
    }

    componentDidUpdate = () => {
        $('.tooltipped').tooltip()
    }

    getCheckbox = (content, category) => content.acquittementLogin ? (
        <i
            className='material-icons small tooltipped padding-right-1'
            data-tooltip={template(i18n.acquittedAtDateByLogin)({
                date: getDateWithHourString(content.acquittementDate),
                login: content.acquittementLogin,
            })}
        >
            check_box
        </i>
    ) : (
        <i
            className='material-icons small clickable padding-right-1'
            onClick={() => this.setState({ cms: content, openAcquittement: true, category })}
        >
            check_box_outline_blank
        </i>
    )

    getCms = (category = {}, content) => {
        const pictures = this.getPictures(content) || {}
        return (
            <div>
                <div className='row' style={{ marginBottom: '0px' }}>
                    <div className='row no-padding-left padding-top-1 no-margin valign-wrapper'>
                        <div className='col s1'>
                            {getCmsIcon(category.typeCategory)}
                        </div>
                        <div className='col s11'>
                            <div className='valign-wrapper'>
                                <h6 className='bold'>{content.title}</h6>
                                {
                                    content.status == 2 && (
                                        <i className='material-icons small tooltipped' data-tooltip={i18n.draft}>create</i>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                    <div className='col s12 truncate'>
                        {content.comment}
                    </div>
                    <div className='col s12 margin-top-1 no-padding'>
                        {!!pictures.length && <SmallPictureLine pictures={pictures} />}
                    </div>
                </div>
            </div >
        )
    }

    getPictures = (content) => {
        if (content.cmsDocument) {
            return content.cmsDocument.map(({ name }) => ({
                name,
                url: `${contentsPath}CMS/${name}`,
            }))
        }
        return null
    }

    getContentRow = content => {
        const {
            cmsCategories = [],
            onHover = () => { },
        } = this.props
        const color = content.status != 1 ? 'grey lighten-2' : ''
        const category = cmsCategories.find(c => c.id === content.idCategory) || {}
        return (
            <div onMouseOver={() => onHover(content, true)} onMouseOut={() => onHover(content, false)}>
                <div key={content.id} className={`collection-item ${color}`}>
                    {this.getCms(category, content)}
                    <div className='event-card margin-top-1'>
                        <div className='row no-margin event-footer no-padding'>
                            <div className='left row no-margin valign-wrapper'>
                                <div className='col s12'>
                                    <h6 className='italic-label'>{`${i18n.writtenBy} ${content.author} ${i18n.atDate} ${getFullDate(content.updateDate)}`}</h6>
                                </div>
                            </div>
                            <div className='right row no-margin valign-wrapper'>
                                <div className='col s12'>
                                    {this.getCheckbox(content, category)}
                                    <Icon size='small' icon='info' onClick={() => this.setState({ cms: content, isOpen: true })} tooltip={i18n.description} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    closeCms = () =>
        this.props.updateCMSEvent({ ...this.state.cms, status: 3, dateFin: moment().valueOf() }, false).then(() => this.setState({ cms: {}, isOpen: false }))

    validateCms = () =>
        this.props.updateCMSEvent({ ...this.state.cms, status: 1 }, false).then(() => this.setState({ cms: {}, isOpen: false }))

    draftCms = () =>
        this.props.updateCMSEvent({ ...this.state.cms, status: 2 }, false).then(() => this.setState({ cms: {}, isOpen: false }))

    setVisibilityOption = (key) => {
        if (this.state.visibilityOption.find(v => v === key)) {
            const visibilityOption = [...this.state.visibilityOption.filter(v => v !== key)]
            this.setState({ visibilityOption })
            this.props.onSetVisibilityOption(visibilityOption)
        } else {
            const visibilityOption = [...this.state.visibilityOption, key]
            this.setState({ visibilityOption })
            this.props.onSetVisibilityOption(visibilityOption)
        }
    }

    getGroupByLevelOptions(events) {
        const filteredEvents = events.filter(e => e.idCategory === 0)
        const groups = groupBy(filteredEvents, content => content.levelContent || 0)
        return Object.keys(groups).reduce((acc, key) => {
            const size = groups[key].length
            const cards = groups[key].map(e => ({
                content: this.getContentRow(e),
                color: hasValue(e.levelContent) ? getNewsColor(e.levelContent) : 'null',
            }))
            return {
                ...acc,
                [key]: {
                    title: `${getLabel(this.state.levelOptions, key, null, 'value')} (${size})`,
                    cards,
                    titleColor: key === 0 ? 'BLACK' : getNewsColor(groups[key][0].levelContent),
                    level: groups[key][0].levelContent || 0,
                },
            }
        }, {})
    }

    onChangeStatus = () => {
        switch (this.state.statusChange) {
            case 1:
                this.validateCms()
                break
            case 2:
                this.draftCms()
                break
            case 3:
                this.closeCms()
                break
            default:
                this.props.toastrError(i18n.pleaseChooseStatus)
        }
    }

    render = () => {
        const {
            cmsEvents = [],
            sandreCodes,
        } = this.props
        if (!cmsEvents.length) {
            return (
                <h4 className='center-align padding-top-1 padding-bottom-1'>
                    {i18n.noContentToDisplay}
                </h4>
            )
        }
        const orderedEvents = orderBy(cmsEvents, ['levelContent', 'updateDate'], ['desc', 'desc'])
        const now = moment().valueOf()
        const eventPublish = orderedEvents.filter(oe => oe.status === 1 && (!oe.dateFin || oe.dateFin > now) && oe.idCategory === 0)
        const eventDraft = getUser().admin !== '1' ? orderedEvents.filter(oe => oe.status === 2 && oe.author === getUser().login && oe.idCategory === 0)
            : orderedEvents.filter(oe => oe.status === 2 && oe.idCategory === 0)
        const eventArchived = orderedEvents.filter(oe => (oe.status === 3 || oe.dateFin < now) && oe.idCategory === 0)
        const {
            cms,
            isOpen,
            openAcquittement,
            category,
        } = this.state
        const published = this.getGroupByLevelOptions(eventPublish)
        const archived = this.getGroupByLevelOptions(eventArchived)
        const drafted = this.getGroupByLevelOptions(eventDraft)
        const archivedLength = eventArchived.length
        const publishedLength = eventPublish.length
        const draftedLength = eventDraft.length
        const statusChanges = sandreCodes.filter((c) => c.field === 'CMS.STATUS')
        return (
            <div>
                {publishedLength !== 0 &&
                    <Section openFirst={true} color={'#161832'} number={publishedLength} title={<div className='valign-wrapper'>
                        <div onClick={() => this.setVisibilityOption(i18n.published)}>
                            <Checkbox className='white-checkbox' checked={!this.state.visibilityOption.find(v => v === i18n.published)} />
                        </div>
                        <h6>{i18n.published}{publishedLength > 1 && 's'}</h6>
                    </div>}
                    >
                        <GlobalCardList
                            data={published}
                            sortFunction={(a, b) => published[b].level - published[a].level}
                        />
                    </Section>
                }
                {archivedLength !== 0 &&
                    <Section color={'grey'} number={archivedLength} title={<div className='valign-wrapper'>
                        <div onClick={() => this.setVisibilityOption(i18n.archived)}>
                            <Checkbox className='white-checkbox' checked={!this.state.visibilityOption.find(v => v === i18n.archived)} />
                        </div>
                        <h6>{i18n.archived}{archivedLength > 1 && 's'}</h6>
                    </div>}
                    >
                        <GlobalCardList
                            data={archived}
                            sortFunction={(a, b) => archived[b].level - archived[a].level}
                        />
                    </Section>
                }
                {draftedLength !== 0 && getUser().admin === '1' &&
                    <Section color={'#bebebe'} number={draftedLength} title={<div className='valign-wrapper'>
                        <div onClick={() => this.setVisibilityOption(i18n.draft)}>
                            <Checkbox className='white-checkbox' checked={!this.state.visibilityOption.find(v => v === i18n.draft)} />
                        </div>
                        <h6>{i18n.draft}{draftedLength > 1 && 's'}</h6>
                    </div>}
                    >
                        <GlobalCardList
                            data={drafted}
                            sortFunction={(a, b) => drafted[b].level - drafted[a].level}
                        />
                    </Section>
                }
                {draftedLength === 0 && archivedLength === 0 && publishedLength === 0 && getUser().admin === '1' &&
                    <PanelList title={<h5 className='no-margin'>{i18n.noEvents}</h5>}>{null}</PanelList>
                }
                <Dialog
                    onClose={() => this.setState({ cms: {}, isOpen: false })}
                    fullWidth
                    maxWidth='md'
                    open={isOpen}
                >
                    <DialogContent>
                        <AlertCmsModal cms={cms} />
                        <div className='row no-margin valign-wrapper padding-top-2 padding-bottom-1'>
                            {
                                getUser().admin === '1' &&
                                <div>
                                    <div className='col s2'>
                                        <a className='waves-effect waves-teal btn-flat' onClick={() => {
                                            setConfirmationModal(() => this.props.deleteCMSEvent(cms, false))
                                            this.setState({ cms: {}, isOpen: false })
                                        }}
                                        >
                                            {i18n.delete}
                                        </a>
                                    </div>
                                    <div className='col s7 offset-s1'>
                                        <div className='row no-margin valign-wrapper'>
                                            <a className='padding-right-1 padding-left-1 waves-effect waves-light btn' onClick={() => this.onChangeStatus()}>
                                                {i18n.changeStatusInto}
                                            </a>
                                            <div className='padding-1' />
                                            <Select
                                                onChange={statusChange => this.setState({ statusChange })}
                                                options={statusChanges}
                                                value={this.state.statusChange}
                                                keyValue='code'
                                                keyLabel='name'
                                            />
                                        </div>
                                    </div>
                                </div>
                            }
                            <div className='col s3'>
                                <a className='padding-right-1 padding-left-1 waves-effect waves-teal btn-flat btn font-white' onClick={() => this.props.push(`/contents/${cms.idCategory}/${cms.id}`)}>
                                    {i18n.accessToTheFile}
                                </a>
                            </div>
                        </div>
                    </DialogContent>
                </Dialog>
                <Dialog
                    onClose={() => this.setState({ cms: {}, category: {}, openAcquittement: false })}
                    fullWidth
                    maxWidth='md'
                    open={openAcquittement}
                >
                    <DialogContent>
                        <h5 className='no-margin padding-bottom-2'>{i18n.doYouWantToAcquitThisEvent}</h5>
                        {this.getCms(category, cms)}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.setState({ cms: {}, category: {}, openAcquittement: false })} variant='outlined' >
                            {i18n.cancel}
                        </Button>
                        <Button onClick={() => {
                            this.props.updateCMSEvent({ ...this.state.cms, acquittementDate: moment().valueOf(), acquittementLogin: getLogin() }, false)
                                .then(() => this.setState({ cms: {}, category: {}, openAcquittement: false }))
                        }} variant='contained' color='primary'
                        >
                            {i18n.validate}
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        )
    }
}

PollutionMapTabs.propTypes = {
    cmsEvents: PropTypes.arrayOf(PropTypes.instanceOf(CMSEventDto)),
    onHover: PropTypes.func,
    onSetVisibilityOption: PropTypes.func,
    cmsCategories: PropTypes.arrayOf(PropTypes.instanceOf(CMSCategoryDto)),
    push: PropTypes.func,
    deleteCMSEvent: PropTypes.func,
    updateCMSEvent: PropTypes.func,
    toastrError: PropTypes.func,
    onCheck: PropTypes.func,
    selectedThemes: PropTypes.array,
    sandreCodes: PropTypes.arrayOf(PropTypes.instanceOf(DtoSandreCode)),
}

const mapStateToProps = store => ({
    cmsCategories: store.EventsReducer.cmsCategories,
    sandreCodes: store.ReferencialReducer.sandreCodes,
})

const mapDispatchToProps = {
    push,
    deleteCMSEvent: CmsAction.deleteCMSEvent,
    updateCMSEvent: CmsAction.updateCMSEvent,
    toastrError: ToastrAction.error,
}

export default connect(mapStateToProps, mapDispatchToProps)(PollutionMapTabs)
