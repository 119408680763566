import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { sieauTooltip } from '../../../utils/FormUtils'

const $ = window.$

class SubList extends Component {
    constructor(props) {
        super(props)
        this.state = { open: !!props.openOnLoad }
    }

    onClickAction(e, action) {
        const elem = $(e.target).parent().parent().parent().parent().parent()
        const parent = elem.parent()
        const content = parent.find('.collapsible-body')
        elem.addClass('active')
        parent.addClass('active')
        content.addClass('block')
        setTimeout(() => {
            content.removeClass('block')
            content.css('display', 'block')
        }, 1500)
        action.onClick()
        $('.tooltipped').tooltip('remove')
    }

    getTitle = (actions) => {
        const title = <div className={this.props.smallTitle ? 'smallSubListTitle' : ''}>{this.props.title}</div>
        if (this.props.collapsible) {
            return (
                <div className='valign-wrapper'>
                    <div className='col s11'>
                        <h6>{title}</h6>
                    </div>
                    {actions}
                    <div className='col s1'><i
                        className='material-icons right medium'
                    >{!this.state.open ? 'expand_more' : 'expand_less'}</i>
                    </div>
                </div>
            )
        }
        return (
            <div>
                <div className='left'>
                    {title}
                </div>
                <div className='right'>
                    {actions}
                </div>
            </div>
        )
    }

    getSuperComponent(component, actions = [], icon = '') {
        const actionIcons = actions.map(action => {
            return (
                <a className='right waves-effect white-text'
                    {...sieauTooltip(action.label, null, 'bottom')}
                    onClick={(e) => this.onClickAction(e, action)}
                >
                    <i className='small material-icons clickable right-align'>{action.icon}</i>
                </a>
            )
        })
        const actionsComponent = actionIcons.length ? (
            <div className='valign-wrapper'>
                {actionIcons}
            </div>
        ) : null
        const style = this.props.titleColor ? {
            style: {
                'background-color': this.props.titleColor,
            },
        } : {}
        const title = (() => {
            if (icon) {
                return (
                    <div className='col s12 no-padding'>
                        <div className='row no-margin valign-wrapper'>
                            <div className='col s1 no-padding'>
                                <i className='material-icons'>{icon}</i>
                            </div>
                            <div className='col s11 no-padding'>
                                {this.getTitle(actionsComponent)}
                            </div>
                        </div>
                    </div>
                )
            }
            return (
                <div className='col s12 no-padding'>
                    {this.getTitle(actionsComponent)}
                </div>
            )
        })()
        const className = (() => {
            const { cardClassName, collapsible } = this.props
            if (collapsible) {
                const collapsibleClass = `${cardClassName} collapsible-header `
                if (this.state.open) {
                    return `${collapsibleClass} active`
                }
                return collapsibleClass
            }
            return cardClassName
        })()

        return (
            <li className={`sub-card-list ${this.props.titleClassName}`}>
                <div onClick={() => this.setState({ open: !this.state.open })}
                    className={`card-panel card-list-title margin-top-1 margin-bottom-0 row padding-1 ${this.props.smallTitle ? 'no-padding-top no-padding-bottom' : ''} ${className}`} {...style}
                >
                    {title}
                </div>
                <div className={this.props.collapsible ? `col s12 collapsible-body no-padding ${this.props.bodyClassName}` : 'row'}>
                    {!this.props.loadOnClick || (this.props.loadOnClick && this.state.open) ? component : null}
                </div>
            </li>
        )
    }

    render() {
        return null
    }

    componentDidMount() {
        $('.sieau-collapsible').collapsible()
        if (!this.props.dontOpenFirst) {
            $('.sieau-collapsible').collapsible('close', 0)
            $('.sieau-collapsible').collapsible('open', 0)
        }
        $('.tooltipped').tooltip()
    }
}

SubList.propTypes = {
    title: PropTypes.string.isRequired,
    titleColor: PropTypes.string,
    icon: PropTypes.string,
    collapsible: PropTypes.bool,
    titleClassName: PropTypes.string,
    smallTitle: PropTypes.bool,
    dontOpenFirst: PropTypes.bool,
    openOnLoad: PropTypes.bool,
    loadOnClick: PropTypes.bool,
    cardClassName: PropTypes.string,
    bodyClassName: PropTypes.string,
}

SubList.defaultProps = {
    collapsible: false,
    titleClassName: '',
    cardClassName: '',
}

export default SubList
