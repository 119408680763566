export default class DtoTankOperatorEquipment {
    constructor(obj) {
        this.idStation = obj.idStation
        this.equipment = obj.equipment
        this.fixingState = obj.fixingState
        this.sealingState = obj.sealingState
        this.ductPassageState = obj.ductPassageState
        this.implantationState = obj.implantationState
        this.comment = obj.comment
    }
}