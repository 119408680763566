import DtoCMSParagraph from './survey/DtoCMSParagraph'

export default class DtoCMSSurvey {
    constructor(obj) {
        this.idSurvey = obj.idSurvey // Option[Long],
        this.surveyDate = obj.surveyDate // Option[DateTime],
        this.startDate = obj.startDate // Option[DateTime],
        this.endDate = obj.endDate // Option[DateTime],
        this.countryCode = obj.countryCode // Option[Long],
        this.updateDate = obj.updateDate // Option[DateTime],
        this.updateLogin = obj.updateLogin // Option[String],
        this.description = obj.description // Option[String],
        this.status = obj.status // Option[Long],

        this.link_paragraphs = obj.link_paragraphs ? obj.link_paragraphs.map(u => new DtoCMSParagraph(u)) : [] // Option[Seq[CMSParagraphWithLinks]]
    }
}
