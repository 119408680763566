export default class DtoHydrometryThreshold {
    constructor(obj = {}) {
        this.id = obj.id
        this.stationId = obj.code
        this.title = obj.name
        this.value = obj.value
        this.color = obj.color
        this.isOverrunThreshold = obj.isOverrunThreshold
        this.dataType = obj.dataType
        this.name = obj.name
        this.htmlColor = obj.htmlColor
        this.month01 = obj.month01
        this.month02 = obj.month02
        this.month03 = obj.month03
        this.month04 = obj.month04
        this.month05 = obj.month05
        this.month06 = obj.month06
        this.month07 = obj.month07
        this.month08 = obj.month08
        this.month09 = obj.month09
        this.month10 = obj.month10
        this.month11 = obj.month11
        this.month12 = obj.month12
    }
}