import { omit, reduce } from 'lodash'
import moment from 'moment'
import React from 'react'
import i18n from 'simple-react-i18n'
import {
    H_MAT_CENTRAL,
    H_MAT_DASHBOARD,
    H_MAT_EQUIPMENT,
    H_MAT_POWER_SUPPLY,
    H_MAT_SENSOR,
    H_MAT_SIM,
    H_MAT_SUBSCRIPTION,
    H_MAT_TELECOM,
    H_MAT_VARIOUS,
} from '../account/constants/AccessRulesConstants'
import {
    CENTRAL,
    EQUIPMENT,
    MAT_CENTRAL,
    MAT_DIVERS,
    MAT_EQUIPMENT,
    MAT_POWER_SUPPLY,
    MAT_SENSOR,
    MAT_SIM,
    MAT_SUBSCRIPTION,
    MAT_TELECOM,
    POWER_SUPPLY,
    SENSOR,
    SIM,
    SUBSCRIPTION,
    TELECOM,
    VARIOUS,
} from '../materiel/constants/MaterielConstants'
import {
    MATERIEL_STATE_HS_ID,
    MATERIEL_STATE_IN_STOCK_ID,
    MATERIEL_STATE_MAINTENANCE_ID,
    MATERIEL_STATE_ON_SITE_ID,
    MATERIEL_STATE_PROVISIONAL_ID,
    MATERIEL_STATE_REFORMED_ID,
    MATERIEL_STATE_TRANSFERT_ID,
    MATERIEL_STATE_WAITING_MAINTENANCE_ID,
    MATERIEL_STATE_WARRANTY_ID,
} from '../materiel/constants/MaterielStateConstants'
import { setChoiceModal, sieauTooltip } from './FormUtils'
import { removeNullKeys } from './StoreUtils'

const IS_CLOSE = 'IS_CLOSE'
const IS_OPEN = 'IS_OPEN'

const getMaterielsFromType = (props, materielType) => {
    if ([MAT_CENTRAL, CENTRAL].includes(materielType)) {
        return props.centrals
    }
    if ([MAT_POWER_SUPPLY, POWER_SUPPLY].includes(materielType)) {
        return props.powerSupplies
    }
    if ([MAT_SENSOR, SENSOR].includes(materielType)) {
        return props.sensors
    }
    if ([MAT_TELECOM, TELECOM].includes(materielType)) {
        return props.telecoms
    }
    if ([MAT_SIM, SIM].includes(materielType)) {
        return props.sims
    }
    if ([MAT_DIVERS, VARIOUS].includes(materielType)) {
        return props.variousMateriels
    }
    return []
}

const getReduceAssignementsStatus = (listOfAssigments) => {
    return reduce(listOfAssigments, (a, b) => {
        if (b.situationEndDate && moment(b.situationEndDate).isValid()) {
            (a[IS_CLOSE] || (a[IS_CLOSE] = [])).push(b)
        } else {
            (a[IS_OPEN] || (a[IS_OPEN] = [])).push(b)
        }
        return a
    }, {})
}

const getOpenAssignements = (reduceAssignments) => {
    return reduceAssignments[IS_OPEN] ? reduceAssignments[IS_OPEN] : []
}
const getCloseAssignements = (reduceAssignments) => {
    return reduceAssignments[IS_CLOSE] ? reduceAssignments[IS_CLOSE] : []
}

const getMaterielSVG = color => {
    return '<svg version="1.1" width="200" height="200" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200">'
        + `<circle cx="100" cy="100" r="100" fill="${color}"/>`
        + '</svg>'
}

const createIcon = (label, icon, color, fontSize = '1.5rem', showLabel = true, isImg = false) => {
    const iconPanel = (
        <div style={{ color }}>
            {
                isImg ? (
                    <img src={`pictures/markers/${icon}.svg`} className='responsive-img' style={{ width: fontSize, height: fontSize, minWidth: fontSize }} />
                ) :
                    <i className='material-icons' style={{ fontSize }}
                        {...sieauTooltip(label)}
                        data-tooltip={label}
                    >
                        {icon}
                    </i>
            }
        </div>
    )
    if (!showLabel) {
        return iconPanel
    }
    return (
        <div className='row no-margin valign-wrapper'>
            { iconPanel }
            <div className='padding-left-1'>
                { label }
            </div>
        </div>
    )
}
const createIconMaterialState = (label, stateCode, fontSize = '1.5rem', showLabel = true) => {
    switch (stateCode) {
        case MATERIEL_STATE_HS_ID:
            return createIcon(label, 'info', 'red', fontSize, showLabel)
        case MATERIEL_STATE_ON_SITE_ID:
            return createIcon(label, 'place', 'green', fontSize, showLabel)
        case MATERIEL_STATE_IN_STOCK_ID:
            return createIcon(label, 'assignment_turned_in', 'blue', fontSize, showLabel)
        case MATERIEL_STATE_MAINTENANCE_ID:
            return createIcon(label, 'build', 'orange', fontSize, showLabel)
        case MATERIEL_STATE_PROVISIONAL_ID:
            return createIcon(label, 'place', 'orange', fontSize, showLabel)
        case MATERIEL_STATE_REFORMED_ID:
            return createIcon(label, 'folder', 'gray', fontSize, showLabel)
        case MATERIEL_STATE_TRANSFERT_ID:
            return createIcon(label, 'folder', 'brown', fontSize, showLabel)
        case MATERIEL_STATE_WARRANTY_ID:
            return createIcon(label, 'verified_user', 'green', fontSize, showLabel)
        case MATERIEL_STATE_WAITING_MAINTENANCE_ID:
            return createIcon(label, 'access_time', 'orange', fontSize, showLabel)
        default:
            return null
    }
}
const createIconMaterialAssignment = (label, assignmentCode, fontSize = '1.5rem', showLabel = true) => {
    switch (assignmentCode) {
        case 1:
            return createIcon(label, 'map_piezo', 'gray', fontSize, showLabel, true)
        case 2:
            return createIcon(label, 'map_pluvio', 'gray', fontSize, showLabel, true)
        case 3:
            return createIcon(label, 'map_qualito', 'gray', fontSize, showLabel, true)
        case 4:
            return createIcon(label, 'map_hydro', 'gray', fontSize, showLabel, true)
        case 7:
            return createIcon(label, 'map_installation', 'gray', fontSize, showLabel, true)
        case 8:
            return createIcon(label, 'router', 'gray', fontSize, showLabel)
        case 9:
            return createIcon(label, 'contacts', 'gray', fontSize, showLabel)
        default:
            return null
    }
}
const getMaterialStateIcon = stateCode => {
    switch (stateCode) {
        case MATERIEL_STATE_HS_ID:
            return { icon: 'info', color: 'red' }
        case MATERIEL_STATE_ON_SITE_ID:
            return { icon: 'place', color: 'green' }
        case MATERIEL_STATE_IN_STOCK_ID:
            return { icon: 'assignment_turned_in', color: 'blue' }
        case MATERIEL_STATE_MAINTENANCE_ID:
            return { icon: 'build', color: 'orange' }
        case MATERIEL_STATE_PROVISIONAL_ID:
            return { icon: 'place', color: 'orange' }
        case MATERIEL_STATE_REFORMED_ID:
            return { icon: 'folder', color: 'gray' }
        case MATERIEL_STATE_TRANSFERT_ID:
            return { icon: 'folder', color: 'brown' }
        case MATERIEL_STATE_WARRANTY_ID:
            return { icon: 'verified_user', color: 'green' }
        case MATERIEL_STATE_WAITING_MAINTENANCE_ID:
            return { icon: 'access_time', color: 'orange' }
        default:
            return null
    }
}
const getMaterielPlural = mat => {
    switch (mat) {
        case 'central':
            return 'centrals'
        case 'sensor':
            return 'sensors'
        case 'powerSupply':
            return 'powerSupplies'
        case 'telecom':
            return 'telecoms'
        case 'sim':
            return 'sims'
        case 'variousMateriel':
            return 'variousMateriels'
        case 'equipment':
            return 'equipments'
        case 'subscription':
            return 'subscriptions'
        default:
            return 'null'
    }
}
const getMaterielIconValue = (materiel) => {
    if ([MAT_CENTRAL, CENTRAL].includes(materiel)) {
        return 'branding_watermark'
    }
    if ([MAT_POWER_SUPPLY, POWER_SUPPLY].includes(materiel)) {
        return 'battery_charging_full'
    }
    if ([MAT_SENSOR, SENSOR].includes(materiel)) {
        return 'settings_input_hdmi'
    }
    if ([MAT_TELECOM, TELECOM].includes(materiel)) {
        return 'router'
    }
    if ([MAT_SIM, SIM].includes(materiel)) {
        return 'sim_card'
    }
    if ([MAT_DIVERS, VARIOUS].includes(materiel)) {
        return 'more_horiz'
    }
    if ([MAT_EQUIPMENT, EQUIPMENT].includes(materiel)) {
        return 'devices_other'
    }
    if ([MAT_SUBSCRIPTION, SUBSCRIPTION].includes(materiel)) {
        return 'contact_phone'
    }
    return 'dashboard'
}

const getMaterielHabilitationValue = (materiel) => {
    if ([MAT_CENTRAL, CENTRAL].includes(materiel)) {
        return H_MAT_CENTRAL
    }
    if ([MAT_POWER_SUPPLY, POWER_SUPPLY].includes(materiel)) {
        return H_MAT_POWER_SUPPLY
    }
    if ([MAT_SENSOR, SENSOR].includes(materiel)) {
        return H_MAT_SENSOR
    }
    if ([MAT_TELECOM, TELECOM].includes(materiel)) {
        return H_MAT_TELECOM
    }
    if ([MAT_SIM, SIM].includes(materiel)) {
        return H_MAT_SIM
    }
    if ([MAT_DIVERS, VARIOUS].includes(materiel)) {
        return H_MAT_VARIOUS
    }
    if ([MAT_EQUIPMENT, EQUIPMENT].includes(materiel)) {
        return H_MAT_EQUIPMENT
    }
    if ([MAT_SUBSCRIPTION, SUBSCRIPTION].includes(materiel)) {
        return H_MAT_SUBSCRIPTION
    }
    return H_MAT_DASHBOARD
}

const getUrlPicto = color => `data:image/svg+xml,${getMaterielSVG(color)}`

const getMatTypes = (matTypes, selected) => matTypes.filter(m => m.materielType === selected || ((!m.startDate || m.startDate < moment().valueOf()) && (!m.endDate || m.endDate > moment().valueOf())))

const getDefaultValuesChoiceModal = (materielType, onChangeFunc, ifYes = () => {}) => {
    if (materielType && materielType.materielType) {
        setChoiceModal(i18n.materielDefaultValuesMessage, {
            yes: () => {
                onChangeFunc(removeNullKeys({ ...omit(materielType, ['id', 'code', 'label', 'name', 'comment', 'updateDate', 'updateLogin', 'startDate', 'endDate', 'icon']), providerId: materielType.provider }))
                ifYes()
            },
        })
    }
}

export {
    getUrlPicto,
    getMaterielSVG,
    getReduceAssignementsStatus,
    getOpenAssignements,
    getCloseAssignements,
    createIconMaterialAssignment,
    createIconMaterialState,
    getMaterielIconValue,
    getMaterielsFromType,
    getMatTypes,
    getDefaultValuesChoiceModal,
    getMaterielPlural,
    getMaterialStateIcon,
    getMaterielHabilitationValue,
}

