export default class DtoAccessCasing {
    constructor(obj) {
        this.idStation = obj.idStation // Long,
        this.lockedAccess = obj.lockedAccess // Option[Boolean],
        this.differentsKeysAccess = obj.differentsKeysAccess // Option[Boolean],
        this.closeType = obj.closeType // Option[Long],
        this.description = obj.description // Option[String],
        this.closingBrand = obj.closingBrand // Option[String],
        this.closingModel = obj.closingModel // Option[String],
        this.antiintrusion = obj.antiintrusion // Option[Boolean]
        this.deconnexion = obj.deconnexion // Option[Long],
    }
}