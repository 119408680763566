'use strict'
import ParameterDto from '../dto/ParameterDto'
import {
    RECEIVE_PARAMETER,
    RECEIVE_PARAMETER_GROUP_LINKS,
    RECEIVE_PARAMETER_GROUP_USAGE,
    RECEIVE_PARAMETER_GROUPS,
    RECEIVE_PARAMETERS,
    RECEIVE_PARAMETERS_QUALITOMETERS,
    RECEIVE_SELECTION_RESULTS,
    RECEIVE_SELECTIONS,
    RECEIVE_SELECTIONS_WITH_PARAM,
    RESET_PARAMETER,
} from '../constants/ParameterConstants'
import DtoSelection from '../dto/DtoSelection'
import { createIndex } from '../../../../utils/StoreUtils'
import ParameterGroupDto from '../dto/ParameterGroupDto'
import ParameterGroupLinkDto from '../dto/ParameterGroupLinkDto'
import SelectionWithParamDto from '../dto/SelectionWithParamDto'
import DtoParameterGroupUsage from '../dto/DtoParameterGroupUsage'

export function ParameterReducer(state = {}, action) {
    switch (action.type) {
        case RECEIVE_PARAMETER:
            return {
                ...state,
                parameter: new ParameterDto(action.parameter),
            }
        case RECEIVE_PARAMETERS:
            const parameters = action.parameters.map(parameter => new ParameterDto(parameter))
            return {
                ...state,
                parameters,
                parametersIndex: createIndex(parameters, 'code'),
            }
        case RECEIVE_PARAMETERS_QUALITOMETERS:
            return {
                ...state,
                qualitometersWithParameter: action.objects,
            }
        case RESET_PARAMETER:
            return {
                ...state,
                stations: [],
                parameter: {},
            }
        case RECEIVE_SELECTIONS:
            return {
                ...state,
                selections: action.selections.map(selection => new DtoSelection(selection)),
            }
        case RECEIVE_SELECTION_RESULTS:
            return {
                ...state,
                selectionResults: action.selectionResults.map(selection => selection.parameterCode),
            }
        case RECEIVE_PARAMETER_GROUPS:
            return {
                ...state,
                parameterGroups: action.parameterGroups.map(group => new ParameterGroupDto(group)),
            }
        case RECEIVE_PARAMETER_GROUP_LINKS:
            return {
                ...state,
                parameterGroupLinks: action.parameterGroupLinks.map(link => new ParameterGroupLinkDto(link)),
            }
        case RECEIVE_SELECTIONS_WITH_PARAM:
            return {
                ...state,
                selectionsWithParam: action.selectionsWithParam.map(s => new SelectionWithParamDto(s)),
            }
        case RECEIVE_PARAMETER_GROUP_USAGE:
            return {
                ...state,
                parameterGroupUsage: action.parameterGroupUsage.map(o => new DtoParameterGroupUsage(o)),
            }
        default:
            return state
    }
}

export const store = {
    selections: [],
    parameter: {},
    parameters: [],
    parametersIndex: {},
    qualitometersWithParameter: [],
    selectionResults: [],
    parameterGroups: [],
    parameterGroupLinks: [],
    parameterGroupUsage: [],
    selectionsWithParam: [],
}