import { push } from '@lagunovsky/redux-react-router'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { H_CAMPAIGN_HYDRO_TRACKING } from '../../../account/constants/AccessRulesConstants'
import CampaignAction from '../../../campaign/actions/CampaignAction'
import CampaignTracking from '../../../campaign/components/campaignTracking/CampaignTracking'
import CampaignDto from '../../../campaign/dto/CampaignDto'
import { componentHasHabilitations } from '../../../utils/HabilitationUtil'
import DtoHydrometricStation from '../../dto/DtoHydrometricStation'


class HydrometryCampaignTrackingApp extends Component {
    componentDidMount = () => {
        if (!componentHasHabilitations(H_CAMPAIGN_HYDRO_TRACKING)) { // A modifier quand react-router sera à jour
            this.props.push('/unauthorized')
            return
        }
        this.props.fetchHydrometryCampaignEvent(this.props.params.id)
    }

    componentDidUpdate = prevProps => {
        if (this.props.params.id !== prevProps.params.id) {
            this.props.fetchHydrometryCampaignEvent(this.props.params.id)
        }
    }

    render = () => (
        <CampaignTracking
            params={this.props.params}
            stations={this.props.hydrometricStations}
            stationType='hydrometry'
        />
    )
}

HydrometryCampaignTrackingApp.propTypes = {
    params: PropTypes.shape({
        id: PropTypes.string,
    }),
    campaign: PropTypes.instanceOf(CampaignDto),
    hydrometricStations: PropTypes.arrayOf(PropTypes.instanceOf(DtoHydrometricStation)),
    push: PropTypes.func,
    fetchHydrometryCampaignEvent: PropTypes.func,
}

const mapStateToProps = store => ({
    campaign: store.CampaignReducer.campaign,
    hydrometricStations: store.HydrometryReducer.hydrometricStations,
})

const mapDispatchToProps = {
    fetchHydrometryCampaignEvent: CampaignAction.fetchHydrometryCampaignEvent,
    push,
}

export default connect(mapStateToProps, mapDispatchToProps)(HydrometryCampaignTrackingApp)