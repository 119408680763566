import React from 'react'
import i18n from 'simple-react-i18n'
import SelectStationComponent from '../../../../components/modal/SelectStationComponent'
import Row from '../../../../components/react/Row'
import RadioButtons from '../../../../components/forms/RadioButtons'
import NumberField from '../../../../components/forms/NumberField'
import Input from '../../../../components/forms/Input'
import SimpleDatePicker from '../../../../components/forms/SimpleDatePicker'
import { hasBooleanValue } from '../../../../utils/NumberUtil'
import { groupBy, orderBy } from 'lodash'
import { getColorCircleElement } from '../../../../utils/ColorUtil'
import ReactEchartsCore from 'echarts-for-react/lib/core'
import echarts from 'echarts/lib/echarts'
import { IPSColorIndex } from '../MapSituationUtils'
import Checkbox from '../../../../components/forms/Checkbox'
import MultiContributorsAutocomplete from 'referencial/components/contributor/components/MultiContributorsAutocomplete'
import { ButtonMUI } from 'components/styled/Buttons'
import StepperDialog from 'components/modal/StepperDialog'
import { Grid } from '@mui/material'
import SuperMultiAutocomplete from 'components/forms/SuperMultiAutocomplete'
import { StyledFieldSet, StyledLegend } from 'components/StyledElements'
import { getArrowDown, getArrowRight, getArrowUp } from 'utils/SvgUtil'

const STEP = {
    STATIONS: 0,
    CALCULATE: 1,
}

const getIPS_Pie = (results, date) => {
    const group = groupBy(results, 'trendColor')
    const chartData = orderBy(Object.keys(group).map(key => {
        return {
            value: group[key].length,
            name: key === 'undefined' ? 'Pas de classe' : i18n[group[key][0].thresholdName],
            color: key === 'undefined' ? 'gray' : group[key][0].trendColor,
        }
    }), d => IPSColorIndex(d.color))

    const options = {
        color: chartData.map(o => o.color),
        tooltip: {
            trigger: 'item',
            formatter: '{b} : {c} ({d}%)',
        },
        series: [
            {
                type: 'pie',
                data: chartData,
                avoidLabelOverlap: false,
                radius: ['50%', '70%'],
                label: {
                    normal: {
                        show: false,
                        position: 'center',
                    },
                },
                labelLine: {
                    normal: {
                        show: false,
                    },
                },
            },
        ],
    }

    /*
      -> "#808080", // gray
    1 -> "#F21007", // red
    2 -> "#F6BD1B", // orange
    3 -> "#F7FF28", // yellow
    4 -> "#48FF24", // green
    5 -> "#34FFFB", // cyan
    6 -> "#3E66ED", // blue
    7 -> "#4400F6", // darkblue
    */

    return (
        <ReactEchartsCore
            echarts={echarts}
            option={options}
            notMerge={true}
            lazyUpdate={true}
            className={'row no-margin'}
            style={{ height: 200 }}
        />
    )
}

const getIPSLegend = () => {
    return (
        <div>
            <Row className='padding-left-1'>
                <ul>
                    <li> <span dangerouslySetInnerHTML={{ __html: getColorCircleElement('#808080') }} />{'Pas de classe'}</li>
                </ul>
                <ul>
                    <li> <span dangerouslySetInnerHTML={{ __html: getColorCircleElement('#4400F6') }} />{i18n.wet10y}</li>
                    <li> <span dangerouslySetInnerHTML={{ __html: getColorCircleElement('#3E66ED') }} />{i18n.wet5y10}</li>
                    <li> <span dangerouslySetInnerHTML={{ __html: getColorCircleElement('#34FFFB') }} />{i18n.wet25y5}</li>
                    <li> <span dangerouslySetInnerHTML={{ __html: getColorCircleElement('#48FF24') }} />{i18n.dry25yWet25y}</li>
                    <li> <span dangerouslySetInnerHTML={{ __html: getColorCircleElement('#F7FF28') }} />{i18n.dry25y5}</li>
                    <li> <span dangerouslySetInnerHTML={{ __html: getColorCircleElement('#F6BD1B') }} />{i18n.dry5y}</li>
                    <li> <span dangerouslySetInnerHTML={{ __html: getColorCircleElement('#F21007') }} />{i18n.dry10y}</li>
                </ul>
                <ul>
                    <li className='valign-wrapper'>
                        <img style={{ width: '20px', height: '20px', left: 'auto', top: 'auto', margin: '2 5' }} src={`data:image/svg+xml;utf8,${getArrowUp('gray')}`} />
                        {i18n.improvement}
                    </li>
                    <li className='valign-wrapper'>
                        <img style={{ width: '20px', height: '15px', left: 'auto', top: 'auto', margin: '2 5' }} src={`data:image/svg+xml;utf8,${getArrowRight('gray')}`} />
                        {i18n.stagnation}
                    </li>
                    <li className='valign-wrapper'>
                        <img style={{ width: '20px', height: '20px', left: 'auto', top: 'auto', margin: '2 5' }} src={`data:image/svg+xml;utf8,${getArrowDown('gray')}`} />
                        {i18n.degradation}
                    </li>
                </ul>
            </Row>
        </div>
    )
}

const getIPSDefaultParams = () => ({
    allStations: true,
    stationIds: [],
    validOnly: true,
    nbYearsHistoRequired: 10,
    nbDaysWithoutMeasuresMax: 7,
    stats: [1, 2, 3, 4, 5, 6, 7],
})

const getIPSDialog = (state, changeState, onSave, stations, dataTypes, st, contributors = []) => {
    const { msSelected, paramsDialogIsOpen } = state

    const params = JSON.parse(msSelected.params)

    const change = changes => changeState({ msSelected: { ...msSelected, ...changes } })

    const changeParams = (changes) => {
        changeState({ msSelected: { ...msSelected, params: JSON.stringify({ ...params, ...changes }) } })
    }

    return (
        <StepperDialog
            steps={[
                {
                    label: i18n.stations,
                    constant: STEP.STATIONS,
                },
                {
                    label: i18n.calcul,
                    constant: STEP.CALCULATE,
                },
            ]}
            open={paramsDialogIsOpen}
            title={i18n.calculationParams}
            sx={{ minHeight: 0 }}
            closeDialog={() => changeState({ paramsDialogIsOpen: false })}
            renderSaveButton={(step, resetStep) => (step === STEP.CALCULATE) && (
                <Grid container justifyContent='space-between'>
                    <Grid item xs={6}>
                        <ButtonMUI
                            onClick={() => {
                                resetStep()
                                onSave()
                            }}
                            variant='contained'
                            color='primary'
                        >
                            {i18n.save}
                        </ButtonMUI>
                    </Grid>
                    <Grid item xs={6}>
                        <ButtonMUI
                            onClick={() => {
                                resetStep()
                                onSave(true)
                            }}
                            variant='contained'
                            color='primary'
                        >
                            {i18n.saveAndCalculate}
                        </ButtonMUI>
                    </Grid>
                </Grid>
            )}
        >
            {(step) => {
                switch (step) {
                    case STEP.STATIONS:
                        const selectedStations = stations.filter(p => params.stationIds.includes(p.id))
                        return (
                            <Grid container spacing={1}>
                                <Grid item xs={6}>
                                    <RadioButtons
                                        elements={[{ value: true, label: i18n.allStations }, { value: false, label: i18n.chooseStations }]}
                                        selected={hasBooleanValue(params.allStations) ? params.allStations : false}
                                        onChange={v => changeParams({ allStations: v })}
                                        title={''}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    {!(hasBooleanValue(params.allStations) ? params.allStations : false) && (
                                        <SelectStationComponent
                                            selectedStations={selectedStations}
                                            campaignEvents={[]}
                                            stationType={st}
                                            onChangeSelectedStation={tmpStations => changeParams({ stationIds: tmpStations.map(p => p.id) })}
                                            networkLinks={state.networkLinks}
                                        />
                                    )}
                                </Grid>
                            </Grid>
                        )
                    case STEP.CALCULATE:
                        const calculType = msSelected.calculType || 'now'
                        return (
                            <div>
                                <StyledFieldSet>
                                    <StyledLegend>{i18n.calcul}</StyledLegend>
                                    <Grid container sx={{ padding: '1rem' }} spacing={1}>
                                        <Grid container item xs={12} spacing={2}>
                                            <Grid item xs={6}>
                                                <Input
                                                    title={i18n.name}
                                                    onChange={v => change({ name: v })}
                                                    value={msSelected.name}
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <MultiContributorsAutocomplete
                                                    label={i18n.administrator}
                                                    disablePortal={false}
                                                    options={contributors}
                                                    values={msSelected.contributorCode}
                                                    onChange={contributorCode => change({ contributorCode })}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid container item xs={12} spacing={2}>
                                            <Grid item xs={5}>
                                                <SuperMultiAutocomplete
                                                    label={i18n.chronicType}
                                                    options={orderBy(dataTypes, 'id')}
                                                    values={msSelected.typeId}
                                                    onChange={v => change({ typeId: v })}
                                                    noSort
                                                />
                                            </Grid>
                                            <Grid item xs={7}>
                                                <RadioButtons
                                                    elements={[
                                                        { code: 'daily', name: i18n.allDays },
                                                        { code: 'now', name: i18n.today },
                                                        { code: 'date', name: i18n.chooseDate },
                                                        { code: 'period', name: i18n.periode },
                                                    ]}
                                                    selected={calculType}
                                                    onChange={v => change({ calculType: v, scheduled: v === 'daily' })}
                                                    title={i18n.calculType}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid container item xs={12} spacing={2}>
                                            {calculType === 'date' && (
                                                <Grid item xs={5}>
                                                    <SimpleDatePicker
                                                        label={i18n.date}
                                                        value={msSelected.startPeriod}
                                                        onChange={date => change({ startPeriod: date })}
                                                    />
                                                </Grid>
                                            )}
                                        </Grid>
                                        <Grid container item xs={12} spacing={2}>
                                            {calculType === 'period' && (
                                                <>
                                                    <Grid container item xs={5} spacing={2}>
                                                        <Grid item xs={6}>
                                                            <SimpleDatePicker
                                                                label={i18n.startDate}
                                                                value={msSelected.startPeriod}
                                                                onChange={date => change({ startPeriod: date })}
                                                                max={msSelected.endPeriod}
                                                                disabled={calculType !== 'period'}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <SimpleDatePicker
                                                                label={i18n.endDate}
                                                                value={msSelected.endPeriod}
                                                                onChange={date => change({ endPeriod: date })}
                                                                min={msSelected.startPeriod}
                                                                disabled={calculType !== 'period'}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={7}>
                                                        <RadioButtons
                                                            elements={[
                                                                { code: 'day', name: i18n.day },
                                                                { code: 'week', name: i18n.week },
                                                                { code: 'month', name: i18n.month },
                                                            ]}
                                                            selected={msSelected.recurrence}
                                                            onChange={v => change({ recurrence: v })}
                                                            title={i18n.recurrence}
                                                            disabled={calculType !== 'period'}
                                                        />
                                                    </Grid>
                                                </>
                                            )}
                                        </Grid>
                                    </Grid>
                                </StyledFieldSet>
                                <StyledFieldSet>
                                    <StyledLegend>{i18n.advancedOptions}</StyledLegend>
                                    <Grid container sx={{ padding: '1rem' }} spacing={1}>
                                        <Grid container item xs={12} spacing={2}>
                                            <Grid item xs={5}>
                                                <RadioButtons
                                                    elements={[
                                                        { code: true, name: i18n.yes },
                                                        { code: false, name: i18n.no },
                                                    ]}
                                                    selected={params.validOnly}
                                                    onChange={v => changeParams({ validOnly: v })}
                                                    title={i18n.calcValidDataOnly}
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <Checkbox
                                                    checked={msSelected.usePredict}
                                                    label={i18n.usePredictData}
                                                    onChange={v => change({ usePredict: v })}
                                                />
                                            </Grid>
                                            <Grid item xs={3}>
                                                <Checkbox
                                                    checked={msSelected.exportCSV}
                                                    label={i18n.exportToCSV}
                                                    onChange={v => change({ exportCSV: v })}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid container item xs={12} spacing={2}>
                                            <Grid item xs={6}>
                                                <NumberField
                                                    title={i18n.numberOfYearsHistoryRequired}
                                                    value={params.nbYearsHistoRequired}
                                                    onChange={v => changeParams({ nbYearsHistoRequired: v })}
                                                    min={10}
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <NumberField
                                                    title={i18n.nbDaysWithoutAcceptableData}
                                                    value={params.nbDaysWithoutMeasuresMax}
                                                    onChange={v => changeParams({ nbDaysWithoutMeasuresMax: v })}
                                                    min={1}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </StyledFieldSet>
                            </div>
                        )
                    default:
                        return null
                }
            }}
        </StepperDialog>
    )
}

export { getIPSDialog, getIPSDefaultParams, getIPSLegend, getIPS_Pie }