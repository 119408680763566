import { orderBy } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import ActionComponent from '../../../../../components/ActionComponent'
import Table from '../../../../../components/datatable/Table'
import { getMapStateToProps, getPropTypes } from '../../../../../utils/StoreUtils'
import DtoBoreholeCommentItem from '../dto/DtoBoreholeCommentItem'

const storeProps = {
    sandreCodes: false,
}

class BoreholeCommentDashboard extends ActionComponent {
    getSummary = (boreholeComments) => {
        if (boreholeComments && boreholeComments.length) {
            const data = orderBy(boreholeComments, 'depth').map(d => new DtoBoreholeCommentItem(Object.assign({}, d, {
                depth: d.depth + i18n.metersSymbol,
            })))
            return (
                <Table
                    data={data}
                    title={i18n.comments}
                    condensed
                    className='smallCard padding-top-1'
                    showNbElements={false}
                    type={new DtoBoreholeCommentItem({})}
                />
            )
        }
        return null
    }

    render() {
        return this.getSummary(this.props.station.link_boreholeComments)
    }
}

BoreholeCommentDashboard.propTypes = getPropTypes(storeProps, {
    editMode: PropTypes.bool,
    summarized: PropTypes.bool,
    station: PropTypes.object,
})

const mapStateToProps = () => getMapStateToProps(storeProps)

export default connect(mapStateToProps)(BoreholeCommentDashboard)
