
module.exports = {
    PANEL_REQ: 'PANEL_REQ',

    PANEL_JOURNAL: 'PANEL_JOURNAL',
    PANEL_CORRECT_CH_REPERE: 'PANEL_CORRECT_CH_REPERE',
    PANEL_CORRECT_V6: 'PANEL_CORRECT_V6',
    PANEL_EXPORT_ADES: 'PANEL_EXPORT_ADES',
}

