export default class DtoPluviometerStats {
    constructor(obj) {
        this.typeId = obj.typeId // Int,
        this.label = obj.label // Int,
        this.countTotal = obj.countTotal // Option[Int],
        this.startDate = obj.startDate // Option[DateTime],
        this.endDate = obj.endDate // Option[DateTime],
        this.control = obj.control // Option[DateTime],
        this.validation = obj.validation // Option[DateTime]
        this.color = obj.color
        this.warningLimit = obj.warningLimit
        this.alertLimit = obj.alertLimit
        this.unit = obj.unit
        this.codeUnit = obj.codeUnit
        this.showData = obj.showData
        this.order = obj.order
        this.comment = obj.comment
        this.codepoint = obj.codepoint
        this.namePoint = obj.namePoint
    }
}
