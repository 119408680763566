import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Card, CardContent } from '@mui/material'

class MessageCard extends Component {
    render() {
        return (
            <Card>
                <CardContent>
                    <h5>{ this.props.children }</h5>
                </CardContent>
            </Card>
        )
    }
}

MessageCard.propTypes = {
    children: PropTypes.element,
    className: PropTypes.string,
}

export default MessageCard

