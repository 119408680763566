import DtoMaterielAssignment from './DtoMaterielAssignment'

export default class DtoStationEquipmentAssignment extends DtoMaterielAssignment {
    constructor(obj) {
        super(obj)
        this.id = obj.idEquipmentSituation
        this.idEquipmentSituation = obj.idEquipmentSituation
        this.idEquipment = obj.idEquipment
        this.materielId = obj.idEquipment
    }
}
