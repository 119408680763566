export default class DtoSTEPTerritoryEmissions {
    constructor(obj) {
        this.dataOrigin = obj.dataOrigin // Option[String],
        this.activityCode = obj.activityCode // Option[String],
        this.nbEstablishments = obj.nbEstablishments // Option[Long],
        this.parameterCode = obj.parameterCode // Option[String],
        this.name = obj.name // Option[String],
        this.kgFlowEntrepriseDay = obj.kgFlowEntrepriseDay // Option[Double],
        this.kgFlowEmployeeDay = obj.kgFlowEmployeeDay // Option[Double],
        this.nbEmployees = obj.nbEmployees // Option[Long],
        this.totalFlowEmployees = obj.totalFlowEmployees // Option[Double],
        this.totalFlowEntreprises = obj.totalFlowEntreprises // Option[Double],
        this.totalEmissionsEstablishments = obj.totalEmissionsEstablishments // Option[Double]
    }
}