export default class DtoSTEPRejectPoint {
    constructor(obj) {
        this.idInstallation = obj.idInstallation // Option[Long],
        this.idPoint = obj.idPoint // Option[Long],
        this.pointCode = obj.pointCode // Option[String],
        this.envName = obj.envName // Option[String],
        this.comment = obj.comment // Option[String],
        this.x = obj.x // Option[Double],
        this.y = obj.y // Option[Double],
        this.projection = obj.projection // Option[Double],
        this.rejectOver1500m = obj.rejectOver1500m // Option[Boolean],
        this.sensitiveAreaName = obj.sensitiveAreaName // Option[String],
        this.nitrogenSensitivity = obj.nitrogenSensitivity // Option[Boolean],
        this.nitrogenSensitivityOrderDate = obj.nitrogenSensitivityOrderDate // Option[DateTime],
        this.sensitivityPhosphorus = obj.sensitivityPhosphorus // Option[Boolean],
        this.sensitivityPhosphorusOrderDate = obj.sensitivityPhosphorusOrderDate // Option[DateTime],
        this.envType = obj.envType // Option[Long],
    }
}