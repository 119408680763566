import React, { Component } from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import ToastrAction from 'toastr/actions/ToastrAction'
import Card from '../../../components/card/Card'
import Checkbox from '../../../components/forms/Checkbox'
import Input from '../../../components/forms/Input'
import HomeAction from '../../../home/actions/HomeAction'
import StationAction from '../../../station/actions/StationAction'
import { getSetting, getSettingInt, getUser } from '../../../utils/SettingUtils'
import AdministrationAction from '../../actions/AdministrationAction'
import ShowAssociatedDataRuleTable from '../dataTypes/ShowAssociatedDataRuleTable'
import Select from '../../../components/forms/Select'
import NetworkAction from '../../../referencial/components/network/actions/NetworkAction'
import PropTypes from 'prop-types'
import { arrayOf } from '../../../utils/StoreUtils'
import NetworkDto from '../../../referencial/components/network/dto/NetworkDto'
import { setActions } from '../../../components/ActionUtil'
import { hasValue } from '../../../utils/NumberUtil'
import SieauParameterDto from 'administration/dto/SieauParameterDto'
import DtoDataType from 'station/dto/DtoDataType'

class AdminQualitometerApp extends Component {
    constructor(props) {
        super(props)
        this.state = {
            applicationSettings: [],
            readMode: true,
            dataLoaded: false,
            project: 'SIQ',
        }
    }

    componentDidMount() {
        this.props.setTitle([{
            title: i18n.quality,
            href: '/quality',
        }, {
            title: i18n.qualityOptions,
            href: '/quality/qualityOptions/generalOptions',
        }])
        this.setState({ applicationSettings: this.props.applicationSettings })
        this.setDataTypes()
        this.setReadOnlyMode()
        if (!this.props.networks.length) {
            this.props.fetchNetworks()
        }
    }

    setDataTypes = () => {
        const { project } = this.state
        this.props.fetchDataTypesByProject(project).then(() => {
            const { filteredDataTypes } = this.props
            this.setState({ dataLoaded: true, dataTypes: filteredDataTypes })
        })
    }

    onChangeSetting = (setting, value) => {
        const stateObj = this.state.applicationSettings.filter(e => e.parameter !== setting)
        this.setState({ applicationSettings: [...stateObj, { parameter: setting, value }] })
    }

    setEditMode = () => {
        const actions = {
            cancel: () => this.onCancel(),
        }
        if (!(getUser().consultant === '1')) {
            actions.save = () => this.onSave()
        }
        setActions(actions)
    }

    setReadOnlyMode = () => {
        this.setState({ disabled: true })
        if (!(getUser().consultant === '1')) {
            setActions({
                edit: () => {
                    this.setState({ disabled: false })
                    this.setEditMode()
                },
            })
        } else {
            setActions({})
        }
    }

    onCancel = () => {
        const { filteredDataTypes, applicationSettings } = this.props
        this.setState({ dataTypes: filteredDataTypes, applicationSettings })
        this.setReadOnlyMode()
    }

    onSave = () => {
        const { dataTypes, project } = this.state
        const applicationSettings = this.state.applicationSettings.filter(e => e.value !== getSetting(this.props.applicationSettings, e.parameter))
        this.props.updateSieauParameters(applicationSettings)
        this.props.updateDataTypesByProject(project, dataTypes).then(() => {
            this.setDataTypes()
        })
        this.setReadOnlyMode()
    }

    render() {
        const { dataLoaded, applicationSettings } = this.state
        const disabled = { disabled: this.state.disabled, active: !this.state.disabled }

        return (
            <div className='padding-1'>
                <Card title={ i18n.qualityOptions } {...disabled}>
                    <div className='card-content'>
                        <div className='row no-margin padding-top-10-px'>
                            <Checkbox col={ 6 } label={i18n.physicochemicalDataManagement} checked={ ['True', '1', 'OUI'].includes(getSetting(applicationSettings, 'physicochemicalDataManagement')) }
                                onChange={(e) => this.onChangeSetting('physicochemicalDataManagement', e ? '1' : '0')} {...disabled}
                            />
                            <Checkbox col={ 6 } label={i18n.hydrobiologicalDataManagement} checked={ ['True', '1', 'OUI'].includes(getSetting(applicationSettings, 'hydrobiologicalDataManagement')) }
                                onChange={(e) => this.onChangeSetting('hydrobiologicalDataManagement', e ? '1' : '0')} {...disabled}
                            />
                        </div>
                        <div className='row padding-top-1 padding-left-1'>
                            <Select col={ 5 } label={ i18n.synchNetwork } options={ this.props.networks } value={ getSettingInt(applicationSettings, 'adesQualitoSynchroNetwork') }
                                onChange={ v => this.onChangeSetting('adesQualitoSynchroNetwork', hasValue(v) ? v.toString() : null) } keyValue='id' keyLabel='mnemonic' {...disabled}
                            />
                            <div className='s7'/>
                        </div>
                        <div className='row no-margin'>
                            <div className='s12'>
                                <h5>{i18n.labelPictureSite}</h5>
                            </div>
                        </div>
                        <div className='row no-margin padding-top-10-px'>
                            <Input col={ 6 } title={ i18n.pictureTitle1 } value={ getSetting(applicationSettings, 'pictureTitle1') }
                                onChange={ v => this.onChangeSetting('pictureTitle1', v) } {...disabled}
                            />
                            <Input col={ 6 } title={ i18n.pictureTitle2 } value={ getSetting(applicationSettings, 'pictureTitle2') }
                                onChange={ v => this.onChangeSetting('pictureTitle2', v) } {...disabled}
                            />
                        </div>
                        <div className='row no-margin'>
                            <Input col={ 6 } title={ i18n.pictureTitle3 } value={ getSetting(applicationSettings, 'pictureTitle3') }
                                onChange={ v => this.onChangeSetting('pictureTitle3', v) } {...disabled}
                            />
                            <Input col={ 6 } title={ i18n.pictureTitle4 } value={ getSetting(applicationSettings, 'pictureTitle4') }
                                onChange={ v => this.onChangeSetting('pictureTitle4', v) } {...disabled}
                            />
                        </div>
                        <div className='row no-margin'>
                            <Input col={ 6 } title={ i18n.pictureTitle5 } value={ getSetting(applicationSettings, 'pictureTitle5') }
                                onChange={ v => this.onChangeSetting('pictureTitle5', v) } {...disabled}
                            />
                            <Input col={ 6 } title={ i18n.pictureTitle6 } value={ getSetting(applicationSettings, 'pictureTitle6') }
                                onChange={ v => this.onChangeSetting('pictureTitle6', v) } {...disabled}
                            />
                        </div>
                    </div>
                </Card>
                <div className='padding-top-1' />
                {dataLoaded && (
                    <ShowAssociatedDataRuleTable
                        currentSettings={applicationSettings}
                        fromStationType='QUALITO'
                        toStationType='PIEZO'
                        onChangeRules={ rules => this.onChangeSetting('showDataTypesRules', rules) }
                        readMode={this.state.disabled}
                    />
                )}
                <div className='padding-top-1' />
                {dataLoaded && (
                    <ShowAssociatedDataRuleTable
                        currentSettings={applicationSettings}
                        fromStationType='QUALITO'
                        toStationType='HYDRO'
                        onChangeRules={ rules => this.onChangeSetting('showDataTypesRules', rules) }
                        readMode={this.state.disabled}
                    />
                )}
                <div className='padding-top-1' />
                {dataLoaded && (
                    <ShowAssociatedDataRuleTable
                        currentSettings={applicationSettings}
                        fromStationType='QUALITO'
                        toStationType='PLUVIO'
                        onChangeRules={ rules => this.onChangeSetting('showDataTypesRules', rules) }
                        readMode={this.state.disabled}
                    />
                )}
                <div className='padding-top-9' />
            </div>
        )
    }
}

AdminQualitometerApp.propTypes = {
    fetchNetworks: PropTypes.func,
    updateSieauParameters: PropTypes.func,
    updateDataTypesByProject: PropTypes.func,
    setTitle: PropTypes.func,
    fetchDataTypesByProject: PropTypes.func,
    networks: arrayOf(NetworkDto),
    applicationSettings: arrayOf(SieauParameterDto),
    filteredDataTypes: arrayOf(DtoDataType),
}

const mapStateToProps = (store) => ({
    applicationSettings: store.AdministrationReducer.applicationSettings,
    filteredDataTypes: store.StationReducer.filteredDataTypes,
    networks: store.NetworkReducer.networks,
})

const mapDispatchToProps = {
    setTitle: HomeAction.setTitle,
    updateDataTypesByProject: StationAction.updateDataTypesByProject,
    fetchDataTypesByProject: StationAction.fetchDataTypesByProject,
    updateSieauParameters: AdministrationAction.updateSieauParameters,
    info: ToastrAction.info,
    fetchNetworks: NetworkAction.fetchNetworks,
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminQualitometerApp)
