import PropTypes from 'prop-types'
import React, { Component } from 'react'
import i18n from 'simple-react-i18n'
import ToastrAction from 'toastr/actions/ToastrAction'
import Card from '../../../../../components/card/Card'
import Table from '../../../../../components/datatable/Table'
import AppStore from '../../../../../store/AppStore'
import { getDay } from '../../../../../utils/DateUtil'
import DtoCentralRange from '../../dto/DtoCentralRange'
import DtoCentralRangeItem from '../../dto/DtoCentralRangeItem'

class RangePanel extends Component {
    constructor(props) {
        super(props)
    }

    getDatas = () => {
        if (this.props.centralRanges.length) {
            return this.props.centralRanges.map((o) => {
                const reasignValue = Object.assign({}, o, {
                    day: getDay(o.day),
                })
                return new DtoCentralRangeItem(reasignValue)
            })
        }
        return []
    }

    render() {
        const datas = this.getDatas()
        const actions = [
            {
                iconName: 'note_add',
                onClick: () => {
                    AppStore.dispatch(ToastrAction.info(i18n.inDeveloppmentFunctionnality))
                },
            },
        ]
        return (
            <div className='row margin-top-2'>
                <div className='col s12'>
                    <Card title={ i18n.materialRange } active={ this.props.active } actions={ actions }>
                        <Table showTitle={ false } data={ datas }
                            type={ new DtoCentralRangeItem({}) } condensed sortable
                        />
                    </Card>
                </div>
            </div>
        )
    }
}

RangePanel.propTypes = {
    centralRanges: PropTypes.arrayOf(PropTypes.instanceOf(DtoCentralRange)),
    active: PropTypes.bool,
}

RangePanel.defaultProps = {
    centralRanges: [],
}

export default RangePanel
