export default class DtoBoreholeCivilEng {
    constructor(obj) {
        this.idStation = obj.idStation

        this.depthForation = obj.depthForation
        this.interneCasingDiameter = obj.interneCasingDiameter
        this.thicknessCasing = obj.thicknessCasing
        this.natureCasing = obj.natureCasing
        this.depthCasing = obj.depthCasing
        this.crepineWork = obj.crepineWork
        this.depthPump = obj.depthPump
        this.depthProbe = obj.depthProbe
        this.TNLevel = obj.TNLevel
        this.flangeLevel = obj.flangeLevel
        this.landmarkLevel = obj.landmarkLevel
        this.coping = obj.coping
        this.rollover = obj.rollover
        this.waterproofHead = obj.waterproofHead
        this.lastInspection = obj.lastInspection
    }
}