import React, { useState } from 'react'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import Table from '../../../../components/datatable/Table'
import { nbPerPageLabel } from '../../../../referencial/constants/ReferencialConstants'
import CartographyPanel from '../../../../components/map/CartographyPanel'
import { hasLocalisation } from '../../../../utils/StationUtils'
import EChart from '../../../../components/echart/EChart'
import Pie from '../../../../components/echart/series/Pie'
import ExportFileModal from 'components/modal/ExportFileModal'
import { WhiteCard } from 'components/styled/Card'
import MessageCard from 'components/card/MessageCard'
import { Grid } from '@mui/material'

const color = [
    '#5470C6', '#91CC75', '#FAC858', '#EE6666', '#73C0DE',
    '#3BA272', '#FC8452', '#9A60B4', '#EA7CCC', '#66ccff',
    '#EAD17C', '#7CEAD1', '#DF673A', '#DFBA3A', '#DF3A5F',
    '#5FDF3A', '#3A5FDF', '#B2DF3A', '#BA3ADF', '#66EEEE',
    '#EEEE66', '#4F9DEB',
]

const TerritoryTableChartsMap = ({
    displayType = 0,
    title = '',
    tableData = [],
    mapData = [],
    chartData = [],
    idDisplay = '',
    customHeaders = {},
    exportData = [],
    headers = [],
    layers = [],
}) => {
    const [displayTypeState, setDisplayTypeState] = useState(displayType)
    const [openModal, setOpenModal] = useState(false)

    const getActions = () => {
        return [
            exportData.length && {
                iconName: 'file_download',
                tooltip: i18n.export,
                color: 'black',
                onClick: () => setOpenModal(true),
            },
            displayTypeState !== 0 && tableData.length && {
                iconName: 'view_headline',
                tooltip: i18n.table,
                color: 'black',
                onClick: () => setDisplayTypeState(0),
            },
            displayTypeState !== 1 && chartData.length && {
                iconName: 'pie_chart',
                tooltip: i18n.graph,
                color: 'black',
                onClick: () => setDisplayTypeState(1),
            },
            displayTypeState !== 2 && mapData.length && {
                iconName: 'map',
                tooltip: i18n.map,
                color: 'black',
                onClick: () => setDisplayTypeState(2),
            },
        ].filter(d => !!d)
    }

    return (
        <Grid>
            {displayTypeState === 0 && (
                <div id={idDisplay}>
                    <Table
                        actions={getActions()}
                        customHeaders={customHeaders}
                        title={title}
                        condensed
                        sortable
                        paging
                        nbPerPageLabel={nbPerPageLabel}
                        data={tableData}
                        type={{ headers }}
                        invertedHeaderStyle
                    />
                </div>
            ) }
            {displayTypeState === 1 && (
                <WhiteCard
                    title={title}
                    actions={getActions()}
                >
                    {chartData.length && (
                        <div id={idDisplay}>
                            <EChart
                                options={{
                                    series: [
                                        Pie({
                                            data: chartData,
                                            label: {
                                                show: false,
                                            },
                                            labelLine: {
                                                show: false,
                                            },
                                            itemStyle: {
                                                borderRadius: 10,
                                                borderColor: '#fff',
                                                borderWidth: 2,
                                            },
                                            radius: ['40%', '70%'],
                                            tooltip: {
                                                trigger: 'item',
                                                formatter: '{b}: {c}',
                                            },
                                            color,
                                        }),
                                    ],
                                }}
                            />
                        </div>
                    ) || <MessageCard><>{i18n.noDataToDisplay}</></MessageCard>}
                </WhiteCard>
            )}
            {displayTypeState === 2 && (
                <CartographyPanel
                    layers={layers}
                    stationsPoints={mapData.filter(s => hasLocalisation(s) || (s.townCode))}
                    stationsPanelTitle={title}
                    height={550}
                    fullScreen={false}
                    noPanels={true}
                />
            )}
            {openModal && (
                <ExportFileModal
                    open={openModal}
                    onClose={() => setOpenModal(false)}
                    data={exportData}
                />
            )}
        </Grid>
    )
}

TerritoryTableChartsMap.propTypes = {
    title: PropTypes.string,
    displayType: PropTypes.number,
    tableData: PropTypes.arrayOf(PropTypes.object),
    mapData: PropTypes.arrayOf(PropTypes.object),
    chartData: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.number,
        name: PropTypes.string,
    })),
    idDisplay: PropTypes.string,
    customHeaders: PropTypes.object,
    exportData: PropTypes.array,
    headers: PropTypes.arrayOf(PropTypes.string),
    layers: PropTypes.arrayOf(PropTypes.string),
}

TerritoryTableChartsMap.defaultProps = {
    displayType: 0,
    tableData: [],
    mapData: [],
    chartData: [],
    idDisplay: '',
}

export default TerritoryTableChartsMap
