import { Card, Grid } from '@mui/material'
import { makeStyles } from '@mui/styles'
import PropTypes from 'prop-types'
import React from 'react'

const useStyles = makeStyles(() => {
    const classes = {
        card: {
            padding: 5,
            margin: '10px 0',
            boxShadow: '0px 5px 8px rgb(0 0 0 / 8%) !important',
            borderRadius: '10px !important',
            cursor: 'pointer',
            transition: 'transform 0.25s ease-in-out !important',
            '&:hover': {
                transform: 'scale(1.05)',
            },
            '&:focus': {
                boxShadow: '0px 10px 15px rgb(79 136 181 / 10%) !important',
                border: 'solid 2px #4f88b5',
                padding: 3,
                transform: 'scale(1.05)',
            },
        },
        cardFocused: {
            padding: 3,
            margin: '10px 0',
            boxShadow: '0px 10px 15px rgb(79 136 181 / 10%) !important',
            border: 'solid 2px #4f88b5',
            transform: 'scale(1.05)',
            borderRadius: '10px !important',
            cursor: 'pointer',
            transition: 'transform 0.25s ease-in-out !important',
        },
        cardDisabled: {
            backgroundColor: '#efefef !important',
            color: '#a7a7a7 !important',
            cursor: 'default',
            padding: 5,
            margin: '10px 0',
            boxShadow: '0px 5px 8px rgb(0 0 0 / 8%) !important',
            borderRadius: '10px !important',
        },
        cardDisabledFocused: {
            backgroundColor: '#efefef !important',
            color: '#a7a7a7 !important',
            cursor: 'default',
            padding: 5,
            margin: '10px 0',
            border: 'solid 2px #4f88b5',
            boxShadow: '0px 5px 8px rgb(0 0 0 / 8%) !important',
            borderRadius: '10px !important',
        },
    }
    classes.cardDashed = { ...classes.card, border: 'dashed 1px grey' }
    return classes
})

const PresentationCard = ({
    iconComponent,
    title = '',
    description = null,
    onClick = () => {},
    isFocused = false,
    iconContainerStyle = {},
    disabled,
    'data-cy': dataCy,
    dashedBorder,
}) => {
    const classes = useStyles()
    const className = (() => {
        if (disabled) {
            if (isFocused) {
                return classes.cardDisabledFocused
            }
            return classes.cardDisabled
        }
        if (isFocused) {
            return classes.cardFocused
        }
        if (dashedBorder) {
            return classes.cardDashed
        }
        return classes.card
    })()
    return (
        <Card
            className={className}
            onClick={!disabled ? onClick : null}
            data-cy={dataCy}
        >
            <Grid container>
                <Grid item xs={3} style={{ display: 'table', ...iconContainerStyle }}>
                    {iconComponent}
                </Grid>
                <Grid item xs={9}>
                    {description !== null ? (
                        <Grid container>
                            <Grid item xs={12}>
                                <h5>{title}</h5>
                            </Grid>
                            <Grid item xs={12}>
                                <span>{description}</span>
                            </Grid>
                        </Grid>
                    ) : (
                        <h5>{title}</h5>
                    )}
                </Grid>
            </Grid>
        </Card>
    )
}

PresentationCard.propTypes = {
    iconComponent: PropTypes.element.isRequired,
    title: PropTypes.string,
    description: PropTypes.string,
    onClick: PropTypes.func,
    isFocused: PropTypes.bool,
    iconContainerStyle: PropTypes.shape({}),
    disabled: PropTypes.bool,
    'data-cy': PropTypes.string,
    dashedBorder: PropTypes.bool,
}

export default PresentationCard
