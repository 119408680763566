import DtoMaterielSituation from '../../../dto/DtoMaterielSituation'

export default class DtoVariousMaterielSituation extends DtoMaterielSituation {
    constructor(obj) {
        super(obj)
        this.id = obj.idVariousSituation
        this.idVariousSituation = obj.idVariousSituation
        this.idVarious = obj.idVarious
        this.materielId = obj.idVarious
        this.distribution = obj.distribution
    }
}
