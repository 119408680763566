import React from 'react'
import { keys } from 'lodash'
import { push } from '@lagunovsky/redux-react-router'
import createClass from 'create-react-class'
import AppStore from '../../store/AppStore'
import Checkbox from '../forms/Checkbox'
import { v4 as uuidv4 } from 'uuid'
import { hasValue } from '../../utils/NumberUtil'
import PropTypes from 'prop-types'
import { setSieauTooltip, sieauTooltip } from '../../utils/FormUtils'
import Icon from '../icon/Icon'

export default createClass({
    propTypes: {
        index: PropTypes.number,
        data: PropTypes.object.isRequired,
        editable: PropTypes.bool,
        link: PropTypes.string,
        subLink: PropTypes.string,
        condensed: PropTypes.bool,
        checkable: PropTypes.bool,
        checked: PropTypes.bool,
        headers: PropTypes.arrayOf(PropTypes.string),
        onClick: PropTypes.func,
        bodyId: PropTypes.number,
        deletable: PropTypes.bool,
        onDelete: PropTypes.func,
        // duplicable: PropTypes.bool,
        // onDuplicate: PropTypes.func,
        alterable: PropTypes.bool,
        onAlter: PropTypes.func,
        onLineOver: PropTypes.func,
        onLineOut: PropTypes.func,
    },
    getInitialState () {
        return {
            editableColumn: -1,
            width: 0,
        }
    },
    getDefaultProps () {
        return {
            data: {},
            editable: false,
            condensed: false,
        }
    },
    getValue() {
        return this.refs.checkbox.getValue()
    },
    onClick (idx) {
        this.setState({ editableColumn: idx, width: this.refs[`col-${idx}`].offsetWidth })
    },
    onLink(id) {
        if (this.props.subLink) {
            AppStore.dispatch(push(`/${this.props.link}/${id}/${this.props.subLink}`))
        } else {
            AppStore.dispatch(push(`/${this.props.link}/${id}`))
        }
    },
    onBlur () {
        this.props.data[keys(this.props.data)[this.state.editableColumn]] = this.refs.inputRef.value
        this.setState({ editableColumn: -1 })
    },
    getCheck(properties) {
        const props = (() => {
            if (properties.value) {
                return properties.value.split(',')
            }
            return properties
        })()

        if (this.props.checkable) {
            return [].concat(['check'], props)
        }
        return props
    },
    render () {
        const { deleteTooltip } = this.props
        const rows = this.props.headers.map((val, idx) => {
            const idUUid = uuidv4()
            const cell = this.props.data[val]
            if (val === 'check') {
                if (this.props.condensed) {
                    return (
                        <td className='table-body-condensed column-checkbox'>
                            <Checkbox checked={this.props.checked} condensed={this.props.condensed} ref='checkbox' />
                        </td>
                    )
                }
                return (<td className='column-checkbox'>
                    <Checkbox checked={this.props.checked} ref='checkbox' />
                </td>)
            }

            if (val === 'del') {
                const condensed = this.props.condensed ? 'table-body-condensed' : ''
                const tooltip = deleteTooltip ? sieauTooltip(deleteTooltip) : ''
                return (
                    <td className={`${condensed} column-checkbox`} data-cy={`table_del_${this.props.index}`}>
                        <i
                            className={'small material-icons clickable'}
                            onClick={() => this.props.onDelete ? this.props.onDelete(this.props.data, this.props.index) : ''}
                            {...tooltip}
                        >
                            close
                        </i>
                    </td>
                )
            }
            if (val === 'alt') {
                const condensed = this.props.condensed ? 'table-body-condensed' : ''
                return (
                    <td className={`${condensed} column-checkbox`}>
                        <i className={'small material-icons clickable'}
                            onClick={() => this.props.onAlter ? this.props.onAlter(this.props.data, this.props.index) : ''}
                        >
                            create
                        </i>
                    </td>
                )
            }
            // if (val === 'dupl') {
            //     const condensed = this.props.condensed ? 'table-body-condensed' : ''
            //     return (
            //         <td className={`${condensed} column-checkbox`}>
            //             <i className={'small material-icons clickable'}
            //                 onClick={() => this.props.onDuplicate ? this.props.onDuplicate(this.props.data, this.props.index) : ''}>add_to_photos</i>
            //         </td>
            //     )
            // }
            if (idx === this.state.editableColumn) {
                return (
                    <td id={idx} className='card col' style={{ position: 'absolute', width: this.state.width }}>
                        <input autoFocus type='text' ref='inputRef' onBlur={this.onBlur}
                            defaultValue={cell}
                        />
                    </td>)
            }

            const clickFunc = (cell && cell.onClick) ? cell.onClick : (this.props.onClick && (() => this.props.onClick(this.props.data)))
            const clickable = clickFunc ? 'clickable ' : ''

            const addedClass = hasValue(cell) ? cell.className || '' : ''
            const tooltip = hasValue(cell) && hasValue(cell.tooltip) ? sieauTooltip(cell.tooltip) : ''

            const positionCell = hasValue(cell) ? cell.positionCell || '' : ''
            const height = hasValue(cell) ? cell.height || '' : ''
            const heightCell = height ? { height } : {}
            const styleCell = cell && cell.style ? { ...cell.style } : {}
            const leftIcon = hasValue(cell) && cell.leftIcon ? <Icon icon={cell.leftIcon} style={{ color: cell.leftIconColor || 'grey', fontSize: cell.leftIconSize }} tooltip={cell.leftIconTip} onClick={cell.leftIconClick} clickable={cell.leftIconClick} /> : null
            const rightIcon = hasValue(cell) && cell.rightIcon ? <Icon icon={cell.rightIcon} style={{ color: cell.rightIconColor || 'grey', fontSize: cell.rightIconSize }} tooltip={cell.rightIconTip} onClick={cell.rightIconClick} clickable={cell.rightIconClick} /> : null

            const hover = hasValue(cell) && cell.setTooltip ? { onMouseOver: () => setSieauTooltip(idUUid, cell.setTooltip()), onMouseOut: () => setSieauTooltip(null), onMouseDown: () => setSieauTooltip(null) } : {}
            const contentTip = hasValue(cell) && cell.contentTip ? { onMouseOver: () => setSieauTooltip(idUUid, cell.contentTip()), onMouseOut: () => setSieauTooltip(null), onMouseDown: () => setSieauTooltip(null) } : {}
            const condensedClass = this.props.condensed ? 'table-body-condensed' : ''
            const styleColor = cell && cell.color && cell.color !== 'white' ? { backgroundColor: cell.color } : {}
            const classNameColor = cell && cell.classNameColor ? cell.classNameColor : ''
            const textColor = cell && cell.textColor ? `${cell.textColor}-text` : ''
            const fontWeight = cell && cell.fontWeight ? cell.fontWeight : ''

            return (
                <td
                    id={idUUid}
                    ref={`col-${idUUid}`}
                    onClick={clickFunc}
                    {...hover}
                    style={{
                        ...styleColor,
                        ...cell?.cellStyle,
                    }}
                    className={`${textColor} ${classNameColor} ${condensedClass} truncate-body-table ${clickable} ${addedClass}`}
                    {...tooltip}
                >
                    <div className={`flex-row valign-wrapper ${positionCell}`}>
                        { leftIcon }
                        <span className={`${fontWeight} truncate`} style={{ ...heightCell, ...styleCell }} {...contentTip}>{cell && hasValue(cell.value) ? cell.value : ''}</span>
                        { rightIcon }
                    </div>
                </td>
            )
        })

        // Linkable
        const link = (() => {
            if (this.props.link) {
                return () => this.onLink(this.props.data.id)
            // } else if (this.props.onClick) {
            //     return () => this.props.onClick(this.props.data)
            }
            return undefined
        })()

        const className = (() => {
            if (link) {
                return 'bottomMargin10 clickable'
            }
            return 'bottomMargin10'
        })()

        const lineColor = this.props.data && this.props.data.lineColor ? { backgroundColor: this.props.data.lineColor } : {}

        return (
            <tr
                className={className}
                onClick={link}
                style={ lineColor }
                id={this.props.bodyId}
                onMouseOver={() => this.props.onLineOver(this.props.data)}
                onMouseOut={() => this.props.onLineOut(this.props.data)}
            >
                {rows}
            </tr>
        )
    },
})
