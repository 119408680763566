import { isUndefined, maxBy, meanBy, orderBy, round } from 'lodash'
import { calculateAverage, calculatePercentile90, searchMaxAnalysis, searchP90Analysis } from 'utils/AnalyseUtils'
import moment from 'moment'

const getAnalyseByGroup = (analysis, regrouping, settings) => {
    switch (regrouping) {
        case 'moyenne':
            const average = calculateAverage(analysis, settings)
            return {
                value: `${average}`,
                result: average,
                sampleDate: moment(analysis[0].sampleDate).dayOfYear(183).valueOf(),
                unit: analysis.find(a => !isUndefined(a.unit))?.unit,
                parameter: analysis[0]?.parameter,
                qualitometer: analysis[0]?.qualitometer,
            }
        case 'percentile':
            return searchP90Analysis(analysis)
        default:
            return searchMaxAnalysis(analysis)
    }
}

const getFluxByGroup = (analysis, regrouping) => {
    switch (regrouping) {
        case 'moyenne':
            const average = round(meanBy(analysis, 'valueFlux'), 4)
            return {
                valueFlux: average,
                value: average,
                result: average,
                sampleDate: moment(analysis[0].sampleDate).dayOfYear(183).valueOf(),
                unit: analysis.find(a => !isUndefined(a.unit))?.unit,
                parameter: analysis[0]?.parameter,
                qualitometer: analysis[0]?.qualitometer,
            }
        case 'percentile':
            const orderedAnalysis = orderBy(analysis, 'valueFlux')
            return calculatePercentile90(orderedAnalysis)
        default:
            return maxBy(analysis, 'valueFlux')
    }
}

const getMeasureByGroup = (measures = [], keyValue, regrouping) => {
    if (!measures.length) {
        return undefined
    }
    switch (regrouping) {
        case 'moyenne':
            return { [keyValue]: meanBy(measures, keyValue) }
        case 'percentile':
            const sortMeasures = orderBy(measures, keyValue)
            return calculatePercentile90(sortMeasures)
        default:
            return maxBy(measures, keyValue)
    }
}

export {
    getAnalyseByGroup,
    getFluxByGroup,
    getMeasureByGroup,
}