import { pick } from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import Button from '../../../../components/forms/Button'
import Checkbox from '../../../../components/forms/Checkbox'
import Input from '../../../../components/forms/Input'
import SelectStationModal from '../../../../components/modal/SelectStationModal'
import PiezometryAction from '../../../../piezometry/actions/PiezometryAction'
import DtoPiezometerLight from '../../../../piezometry/dto/DtoPiezometerLight'
import CityAction from '../../../../referencial/components/city/actions/CityAction'
import { getDate } from '../../../../utils/DateUtil'
import { onChangeDate } from '../../../../utils/FormUtils'
import { arrayOf } from '../../../../utils/StoreUtils'
import Job from '../../../dto/DtoJob'

class PiezoIndicatorsPanel extends Component {
    constructor(props) {
        super(props)
        const filters = props.job.parameters.filters.length ? JSON.parse(props.job.parameters.filters[0]) : {}
        this.state = {
            selectStationIsOpen: false,
            selectedStations: filters && filters.stationCodes ? filters.stationCodes : [],
        }
    }

    componentDidMount() {
        this.props.fetchPiezometers()
        this.props.fetchCities()
    }

    onChangeFilters = (changes) => {
        const parameters = {
            ...this.props.job.parameters,
            filters: [JSON.stringify({
                ...this.getFilters(),
                ...changes,
            })],
        }
        this.props.onChangeJob({ parameters })
    }

    getFilters = () => {
        const newFilters = this.props.job.parameters.filters.length ? JSON.parse(this.props.job.parameters.filters[0]) : {}
        return { stationCodes: [], calculType: 'lastDate', ...newFilters }
    }

    onCloseModal = () => {
        const stationCodes = this.state.selectedStations.map(s => pick(s, ['id', 'code']))
        this.onChangeFilters({ stationCodes })
        this.setState({ selectStationIsOpen: false })
    }

    render() {
        const filters = this.getFilters()
        const disabled = { disabled: !this.props.isEditMode }
        const periodDisabled = { disabled: disabled.disabled || filters.calculType !== 'period' }

        return (
            <div>
                <div className='row no-margin'>
                    <fieldset>
                        <legend>{ i18n.indicators }</legend>
                        <div className='row no-margin valign-wrapper'>
                            <Checkbox col={ 3 } checked={ filters.spi } onChange={ v => this.onChangeFilters({ spi: v }) } label='IPS' {...disabled}/>
                            <Checkbox col={ 3 } checked={ filters.threshold } onChange={ v => this.onChangeFilters({ threshold: v }) } label={ i18n.threshold } {...disabled}/>
                            <div className='col s6'/>
                        </div>
                    </fieldset>
                </div>
                <div className='row no-margin'>
                    <fieldset>
                        <legend>{ i18n.calculType }</legend>
                        <div className='row no-margin valign-wrapper'>
                            <Checkbox col={ 3 } checked={ filters.calculType === 'lastDate' } onChange={ () => this.onChangeFilters({ calculType: 'lastDate' }) } label={ i18n.lastDate } {...disabled}/>
                            <Checkbox col={ 4 } checked={ filters.calculType === 'period' } onChange={ () => this.onChangeFilters({ calculType: 'period' }) } label={ i18n.previousSimulation } {...disabled}/>
                            <div className='col s5'/>
                        </div>
                        <div className='row no-margin valign-wrapper padding-top-1'>
                            <Input col={ 3 } value={ getDate(filters.startPeriod) } title={ i18n.startDate } onChange={ v => onChangeDate(v, v2 => this.onChangeFilters({ startPeriod: v2 })) } {...periodDisabled}/>
                            <Input col={ 3 } value={ getDate(filters.endPeriod) } title={ i18n.endDate } onChange={ v => onChangeDate(v, v2 => this.onChangeFilters({ endPeriod: v2 })) } {...periodDisabled}/>
                            <Checkbox col={ 2 } checked={ filters.periodRecurrence === 'day' } onChange={ () => this.onChangeFilters({ periodRecurrence: 'day' }) } label={ i18n.daily } {...periodDisabled}/>
                            <Checkbox col={ 2 } checked={ filters.periodRecurrence === 'week' } onChange={ () => this.onChangeFilters({ periodRecurrence: 'week' }) } label={ i18n.weekly } {...periodDisabled}/>
                            <Checkbox col={ 2 } checked={ filters.periodRecurrence === 'month' } onChange={ () => this.onChangeFilters({ periodRecurrence: 'month' }) } label={ i18n.monthly } {...periodDisabled}/>
                        </div>
                    </fieldset>
                </div>
                <div className='row no-margin padding-top-1'>
                    <Button col={ 5 } title={ `${i18n.selectStations} (${filters.stationCodes.length})` } onClick={ () => this.setState({ selectStationIsOpen: true }) } {...disabled}/>
                </div>
                <div className='row'>
                    <SelectStationModal
                        onClose={ this.onCloseModal }
                        selectStationIsOpen={ this.state.selectStationIsOpen }
                        selectedStations={this.state.selectedStations}
                        campaignEvents={[]}
                        stationType='piezometry'
                        onChangeSelectedStation={tmpStations => this.setState({ selectedStations: tmpStations })}
                    />
                </div>
            </div>
        )
    }
}

PiezoIndicatorsPanel.propTypes = {
    job: PropTypes.instanceOf(Job).isRequired,
    isEditMode: PropTypes.bool,
    onChangeJob: PropTypes.func,
    piezometers: arrayOf(DtoPiezometerLight),
    fetchPiezometers: PropTypes.func,
    fetchCities: PropTypes.func,
}

const mapStateToProps = store => ({
    piezometers: store.PiezometryReducer.piezometersLight,
})

const mapDispatchToProps = {
    fetchPiezometers: PiezometryAction.fetchPiezometersLight,
    fetchCities: CityAction.fetchCities,
}

export default connect(mapStateToProps, mapDispatchToProps)(PiezoIndicatorsPanel)