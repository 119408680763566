import { createAsyncThunk } from '@reduxjs/toolkit'
import ApplicationConf from 'conf/ApplicationConf'
import LogAction from 'log/actions/LogAction'
import i18n from 'simple-react-i18n'
import { REJECTED, IDLE } from 'store/DataManagerConstants'
import ToastrAction from 'toastr/actions/ToastrAction'
import { genericPromise } from 'utils/ActionUtils'

const ContactThunk = {
    fetchContacts: createAsyncThunk(
        'fetchContacts',
        async (_, { rejectWithValue, dispatch }) => {
            try {
                const response = await genericPromise(ApplicationConf.referencial.contacts(), 'GET')
                return response
            } catch (err) {
                dispatch(LogAction.logError(`${i18n.fetchError} ${i18n.contacts} : ${err}`))
                dispatch(ToastrAction.error(`${i18n.fetchError} ${i18n.contacts}`))
                return rejectWithValue(err)
            }
        },
        {
            condition: (arg = { forceFetch: false }, { getState }) => {
                const state = getState()
                const { contactsStatus } = state.DataManagerReducer.contact
                if (arg.forceFetch) {
                    return contactsStatus !== REJECTED
                }
                return contactsStatus === IDLE
            },
        },
    ),
}

export default ContactThunk