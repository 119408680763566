import React, { Component } from 'react'
import PropTypes from 'prop-types'
import DtoMaterielAssignment from '../../../dto/materiel/DtoMaterielAssignment'
import i18n from 'simple-react-i18n'
import DtoAssigmentItem from '../../../dto/materiel/DtoAssigmentItem'
import DtoMaterielType from '../../../../materiel/dto/DtoMaterielType'
import { getI18nOrLabel, getI18nTitleDataLength } from '../../../../utils/StringUtil'
import AppStore from '../../../../store/AppStore'
import { push } from '@lagunovsky/redux-react-router'

class CloseAssigmentPanel extends Component {
    componentDidMount = () => {
        $('.collapsible').collapsible()
    }

    getHeader = headers => headers.map(header => (
        <th>{i18n[header]}</th>
    ))

    getMateriel = situation => {
        return this.props.materiels.find(b => {
            return b.id == situation[this.props.stationMaterielIdKey]
        })
    }

    getBody = (headers) => {
        return this.props.listOfAssigments.map(o => {
            const materiel = this.getMateriel(o)
            const link = (() => {
                if (materiel && materiel.id) {
                    return `${this.props.link}/${materiel.id}`
                }
                return this.props.link
            })()
            const column = headers.map(b => {
                return (
                    <td className='clickable font-size-small padding-8-10'>{ o[b] }</td>
                )
            })
            return (
                <tr className='clickable' onClick={ () => AppStore.dispatch(push(link)) }>{ column }</tr>
            )
        })
    }
    getTable = (headers) => {
        return (
            <table className='table responsive-table'>
                <thead>
                    <tr>
                        {headers.map(h => <th scope='col' style={{ padding: '10px 5px' }}>{getI18nOrLabel(h)}</th>)}
                    </tr>
                </thead>
                <tbody>
                    { this.getBody(headers) }
                </tbody>
            </table>
        )
    }

    getTitle = () => {
        const dataLenght = this.props.listOfAssigments.length
        const elementTitle = getI18nTitleDataLength(i18n.element, i18n.elements, dataLenght)
        const title = (() => {
            return (
                <span>{ i18n.history } <span
                    className='italic-label font-weight-normal'
                >({ dataLenght } { elementTitle })</span></span>
            )
        })()
        return (
            <div className='row no-margin'>
                <div className='col s12 no-padding margin-left-25'>
                    { title }
                </div>
            </div>
        )
    }

    render() {
        const headers = new DtoAssigmentItem({}).headers
        return (
            <ul className='collapsible no-margin' data-collapsible='accordion'>
                <li>
                    <div className='collapsible-header no-padding'>
                        <div className='card col s12 no-padding no-margin'>
                            <div className='card-title activator no-margin no-padding'>{ this.getTitle() }</div>
                        </div>
                    </div>
                    <div className='collapsible-body white-background'>{ this.getTable(headers) }</div>
                </li>
            </ul>
        )
    }
}

CloseAssigmentPanel.propTypes = {
    link: PropTypes.string,
    stationMaterielIdKey: PropTypes.string,
    listOfAssigments: PropTypes.arrayOf(PropTypes.instanceOf(DtoMaterielAssignment)),
    materiels: PropTypes.arrayOf(PropTypes.instanceOf(DtoMaterielType)),
}

export default CloseAssigmentPanel
