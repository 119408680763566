import { Grid } from '@mui/material'
import { push } from '@lagunovsky/redux-react-router'
import { take } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import { useDispatch } from 'react-redux'
import i18n from 'simple-react-i18n'
import { greyBlue, lightGrey } from 'utils/constants/ColorTheme'
import { getUser } from 'utils/SettingUtils'
import Table from '../../components/datatable/Table'
import { PATH_DASHBOARD } from '../../home/constants/RouteConstants'
import CampaignDto from '../dto/CampaignDto'

const CampaignPortletPanel = ({
    campaigns = [],
    tableHeader = { headers: [] },
    stationType = '',
    noHeader = false,
    customHeaders = {},
    round = false,
}) => {
    const dispatch = useDispatch()

    const user = getUser()
    const orderedCampaigns = [
        ...campaigns.filter(c => c.contactCode === user.contactCode),
        ...campaigns.filter(c => c.contactCode !== user.contactCode),
    ]

    const max3Campaigns = take(orderedCampaigns, 3)

    const title = (
        <a onClick={() => dispatch(push(`/${stationType}/campaign`))}>
            <i className='waves-effect material-icons'>nature_people</i>
            {i18n.currentCampaign}
        </a>
    )

    const headerIcon = (
        <a onClick={() => dispatch(push(`/${stationType}/campaign`))}>
            <i className='waves-effect material-icons'>nature_people</i>
        </a>
    )

    return (
        <div className='row card' style={round ? { borderRadius: 5 } : {}}>
            <div className='row no-margin' id='campaign'>
                <Table
                    showNbElements={!noHeader}
                    showTitle={!noHeader}
                    title={title}
                    showIcon={noHeader}
                    headerIcon={headerIcon}
                    data={max3Campaigns}
                    type={tableHeader}
                    link={`${stationType}/campaign`}
                    subLink={PATH_DASHBOARD}
                    customHeaders={customHeaders}
                    condensed
                    sortable
                    round={round}
                    className='no-box-shadow'
                    noHightlight
                />
            </div>
            {!campaigns.length && (
                <Grid>
                    <Grid className='text-align-center'>
                        <i className='material-icons medium clickable' onClick={() => dispatch(push(`/${stationType}/campaign`))}>nature_people</i>
                    </Grid>
                    <Grid className='center font-size-20'>{i18n.noCampaign}</Grid>
                </Grid>
            )}
            {orderedCampaigns.length > 2 && (
                <div
                    onClick={() => dispatch(push(`/${stationType}/campaign`))}
                    style={{ fontWeight: 'italic', color: greyBlue, borderTop: `solid 1px ${lightGrey}`, fontStyle: '14.3px' }}
                >
                    <div className='padding-right-1 right clickable'>
                        {i18n.viewMore} {'>'}
                    </div>
                </div>
            ) }
        </div>
    )
}

CampaignPortletPanel.propTypes = {
    campaigns: PropTypes.arrayOf(PropTypes.instanceOf(CampaignDto)),
    tableHeader: PropTypes.shape({
        headers: PropTypes.arrayOf(PropTypes.string),
    }),
    stationType: PropTypes.string.isRequired,
    noHeader: PropTypes.bool,
    customHeaders: PropTypes.arrayOf(PropTypes.object),
    round: PropTypes.bool,
}

export default CampaignPortletPanel