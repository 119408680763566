export default class DtoCultureVentilation {
    constructor(obj) {
        this.id = obj.id // Long
        this.cultureId = obj.cultureId // Int,
        this.managementCode = obj.managementCode // Option[Long],
        this.year = obj.year // Int,
        this.status = obj.status // Option[Int],
        this.requirement = obj.requirement // Option[Double],
        this.month01 = obj.month01 // Option[Double],
        this.month02 = obj.month02 // Option[Double],
        this.month03 = obj.month03 // Option[Double],
        this.month04 = obj.month04 // Option[Double],
        this.month05 = obj.month05 // Option[Double],
        this.month06 = obj.month06 // Option[Double],
        this.month07 = obj.month07 // Option[Double],
        this.month08 = obj.month08 // Option[Double],
        this.month09 = obj.month09 // Option[Double],
        this.month10 = obj.month10 // Option[Double],
        this.month11 = obj.month11 // Option[Double],
        this.month12 = obj.month12 // Option[Double],
        this.datemaj = obj.datemaj // Option[DateTime],
        this.usermaj = obj.usermaj // Option[String],
        this.groundCriterias = obj.groundCriterias // Option[Boolean],
        this.mainIrrigationMode = obj.mainIrrigationMode // Option[Long],
        this.climateSector = obj.climateSector // Option[Int],
        this.irrigationNeed = obj.irrigationNeed // Option[Double],
        this.scenarioId = obj.scenarioId // Option[Long]
        this.groundType = obj.groundType // Option[Int]
    }
}