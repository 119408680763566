import PropTypes from 'prop-types'
import React, { Component } from 'react'
import i18n from 'simple-react-i18n'
import { getColor } from '../../../utils/ColorUtil'
import { i18nize } from '../../../utils/StringUtil'
import { EVENT_TYPES } from '../../constants/EventsConstants'

class EventsTypeLegendPanel extends Component {
    render() {
        const eventTypes = (this.props.eventsTypes || i18nize(EVENT_TYPES)).concat([{ label: i18n.unknownType, color: 'GREY' }]).map(eventType => (
            <div className='col s6'>
                <div className='row no-margin valign-wrapper'>
                    <span
                        className='center-align bold arrests-level-panel padding-left-1 padding-right-1 margin-left-1'
                        style={ {
                            backgroundColor: getColor(eventType.color),
                            color: getColor(eventType.color),
                        } }
                    >
                        O
                    </span>
                    <h6>{ eventType.label }</h6>
                </div>
            </div>
        ))
        return (
            <div className='row no-margin'>
                { eventTypes }
            </div>
        )
    }
}

EventsTypeLegendPanel.propTypes = {
    eventsTypes: PropTypes.arrayOf(PropTypes.shape({
        code: PropTypes.string,
        id: PropTypes.number,
        label: PropTypes.string,
        color: PropTypes.string,
        icon: PropTypes.string,
    })),
}

export default EventsTypeLegendPanel
