import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import EquipmentTypeDto from '../../../../materiel/components/equipment/dto/EquipmentTypeDto'
import { EVENT_ACTION } from '../../../constants/PiezometryEventConstants'
import { getTitleCard } from '../EventFormUtils'
import EventCentralCard from './EventCentralCard'
import EventSensorCard from './EventSensorCard'
import EventTelecomCard from './EventTelecomCard'


const EventEquipmentCard = ({
    materiels = {},
    testSignal = [],
    disabled = {},
    formActions = [],
    getLastReplacementDate = () => {},
    setFormAction = () => {},
    onChangeTestSignal = () => {},
    getBooleanChoiceWithAction = () => {},
    equipmentTypes = [],
}) => {
    const {
        equipment = {},
        central = [],
        sensor = [],
        telecom = [],
        powerSupply = [],
    } = materiels
    return (
        <div className='card margin-top-1'>
            {getTitleCard(i18n.equipment, 'equipment', equipment, equipmentTypes)}
            <div className='card-content'>
                {
                    telecom.map(t => (
                        <div className='row no-margin'>
                            <div className='margin-2'>
                                <EventTelecomCard
                                    telecom={t}
                                    hasSignalTestAction={testSignal.includes(t.id)}
                                    disabled={disabled}
                                    formActions={formActions}
                                    lastReplacementDate={getLastReplacementDate(EVENT_ACTION.REMPLACEMENT_PILES_TELETRANSMISSION, t.id)}
                                    setFormAction={setFormAction}
                                    onChangeTestSignal={v => onChangeTestSignal(v, t.id)}
                                    getBooleanChoiceWithAction={getBooleanChoiceWithAction}
                                    displaySupply={!central.length}
                                    hasPowerSupply={!!powerSupply.length}
                                />
                            </div>
                        </div>

                    ))
                }
                {
                    sensor.map(s => (
                        <div className='row no-margin'>
                            <div className='margin-2'>
                                <EventSensorCard
                                    sensor={s}
                                    formActions={formActions}
                                    disabled={disabled}
                                    lastReplacementDate={getLastReplacementDate(EVENT_ACTION.REMPLACEMENT_PILES_CAPTEUR, s.id)}
                                    setFormAction={setFormAction}
                                    getBooleanChoiceWithAction={getBooleanChoiceWithAction}
                                    displaySupply={!central.length}
                                    hasPowerSupply={!!powerSupply.length}
                                />
                            </div>
                        </div>
                    ))
                }
                {
                    central.map(c => (
                        <div className='row no-margin'>
                            <div className='margin-2'>
                                <EventCentralCard
                                    central={c}
                                    formActions={formActions}
                                    disabled={disabled}
                                    lastReplacementDate={getLastReplacementDate(EVENT_ACTION.REMPLACEMENT_PILES_CENTRALE, c.id)}
                                    setFormAction={setFormAction}
                                    getBooleanChoiceWithAction={getBooleanChoiceWithAction}
                                    hasPowerSupply={!!powerSupply.length}
                                />
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

EventEquipmentCard.propTypes = {
    materiels: PropTypes.object,
    testSignal: PropTypes.arrayOf(PropTypes.number),
    disabled: PropTypes.object,
    formActions: PropTypes.arrayOf(PropTypes.object),
    getLastReplacementDate: PropTypes.func,
    setFormAction: PropTypes.func,
    onChangeTestSignal: PropTypes.func,
    getBooleanChoiceWithAction: PropTypes.func,
    equipmentTypes: PropTypes.arrayOf(PropTypes.instanceOf(EquipmentTypeDto)),
}

const mapStateToProps = store => ({
    equipmentTypes: store.EquipmentReducer.equipmentTypes,
})

export default connect(mapStateToProps)(EventEquipmentCard)