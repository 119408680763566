import { getDate } from '../../utils/DateUtil'
import { getStationType } from '../../utils/StationUtils'

export default class DtoSimpeStation {
    constructor(obj) {
        this.id = obj.id
        this.code = obj.designation ? `${obj.code}/${obj.designation}` : obj.code
        this.name = obj.name
        this.city = obj.city
        this.creationDate = getDate(obj.creationDate)
        this.closeDate = getDate(obj.close)
        this.historic = ''
        this.operations = ''
        this.stationType = obj.stationType ? getStationType(parseInt(obj.stationType)).libelle : ''
        this.headers = ['code', 'city', 'name', 'stationType', 'historic', 'operations']
    }
}