/* eslint-disable consistent-return */
/* eslint-disable max-nested-callbacks */
import ReactECharts from 'echarts-for-react'
import { GREEN, ORANGE, RED } from 'components/constants/ColorConstant'
import QualityChart from 'components/echart/QualityChart'
import { flatten, groupBy, max, maxBy, minBy, orderBy, round, sum, sumBy, uniqBy, countBy, take, keys, mean, isUndefined } from 'lodash'
import PropTypes from 'prop-types'
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { nbPerPageLabel } from 'referencial/constants/ReferencialConstants'
import i18n from 'simple-react-i18n'
import { calculateAverage, calculateThresholdResult, searchMaxAnalysis, THRESHOLD_COLOR } from 'utils/AnalyseUtils'
import { getDate, getYear } from 'utils/DateUtil'
import { hasValue } from 'utils/NumberUtil'
import { getLabel } from 'utils/StoreUtils'
import { getStationName } from 'catchment/utils/CatchmentUtil'
import TooltipAnalyse from 'quality/components/qualityComponents/TooltipAnalyse'
import { Card, CardContent, Grid2 } from '@mui/material'
import IAEauAction from 'iaeau/IAEauAction'
import { HYPE_TRENDS_CONSTANTS } from 'quality/constants/QualityConstants'
import { formatHypeTrend, formatHypeTrendRupture } from 'utils/HypeUtils'
import { selectedColor } from 'utils/constants/ColorTheme'
import { exportExcelIcon, exportPictureIcon } from 'components/echart/EChartUtils'
import echarts from 'echarts/lib/echarts'
import { exportFile } from 'utils/ExportDataUtil'
import MessageCard from 'components/card/MessageCard'
import ProgressCard from 'components/card/ProgressCard'
import Select from 'components/forms/Select'
import useProgressDispatch from 'utils/customHook/useProgressDispatch'
import AnalysisAction from 'quality/actions/AnalysisAction'
import DtoAnalysisLight from 'quality/dto/analyse/DtoAnalysisLight'
import DtoAnalysisUltraLight from 'quality/dto/analyse/DtoAnalysisUltraLight'
import useListIndexed from 'utils/customHook/useListIndexed'
import DtoQualityThreshold from 'quality/dto/QualityThreshold/DtoQualityThreshold'
import { CardTitle } from 'components/card/NewCard'
import { NewTable } from 'components/datatable/NewTable'

const getThresholdColor = (value, threshold1, threshold2) => {
    if (!hasValue(threshold1) && !hasValue(threshold2) || !hasValue(value)) {
        return
    }
    if (hasValue(threshold2) && value >= threshold2) {
        return THRESHOLD_COLOR.red
    }
    if (hasValue(threshold1) && value >= threshold1) {
        return THRESHOLD_COLOR.orange
    }
    return THRESHOLD_COLOR.green
}

const FollowUpPesticidesTable = ({
    pesticidesAnalysis = [],
    listSumPesticides = [],
    threshold1,
    threshold2,
}) => {
    const {
        qualitometers,
        parameters,
        cities,
        settings,
    } = useSelector(store => ({
        qualitometers: store.QualityReducer.qualitometersLight,
        parameters: store.ParameterReducer.parameters,
        cities: store.CityReducer.cities,
        settings: store.AdministrationReducer.settings,
    }), shallowEqual)

    const pesticidesAnalysisGroup = useMemo(() => {
        return groupBy(pesticidesAnalysis, 'qualitometer')
    }, [pesticidesAnalysis])

    const getTooltip = (substance, analyse) => {
        if (!substance) return {}
        return {
            setTooltip: () => (
                <TooltipAnalyse
                    title={substance}
                    analyse={analyse}
                    threshold={{
                        parameterCode: analyse.parameter,
                        threshold1,
                        threshold2,
                    }}
                    overrideColor={[GREEN, ORANGE, RED]}
                />
            ),
        }
    }

    const analysisFormated = useMemo(() => {
        const formated = Object.keys(pesticidesAnalysisGroup).map(key => {
            const qualitometer = qualitometers.find(q => q.id === parseInt(key))
            const city = qualitometer && cities.find(c => c.code === qualitometer.townCode)
            const stationName = getStationName(qualitometer, city)
            const yearGroup = groupBy(pesticidesAnalysisGroup[key], a => getYear(a.sampleDate))
            return Object.keys(yearGroup).map(year => {
                const analysis = yearGroup[year].map(a => ({ ...a, ...calculateThresholdResult(a, []) }))
                const quantifiedAnalysis = analysis.filter(a => !['0', '2', '3', '5', '7', '10'].includes(a.remark))
                const listSumPesticideAnalysis = listSumPesticides.length ? quantifiedAnalysis.filter(a => listSumPesticides.includes(a.parameter)) : quantifiedAnalysis
                const total = sumBy(listSumPesticideAnalysis, 'result')
                const maxAnalyse = searchMaxAnalysis(quantifiedAnalysis) ?? {}
                const {
                    result: resultMax,
                    parameter,
                } = maxAnalyse
                const substance = getLabel(parameters, parameter, 'labelWithCode')
                const nbOperation = uniqBy(analysis, 'operation').length
                const groupQuantifiedAnalysis = groupBy(quantifiedAnalysis, 'parameter')
                const maxAverageTest = max(Object.keys(groupQuantifiedAnalysis).map(paramCode => sumBy(groupQuantifiedAnalysis[paramCode], 'result') / uniqBy(groupQuantifiedAnalysis[paramCode], a => `${a.qualitometer}#${a.operation}`).length))


                const listSumByOperation = groupBy(listSumPesticideAnalysis, 'operation')
                const listSum = keys(listSumByOperation).map(ope => sumBy(listSumByOperation[ope], 'result'))
                const averageSumPesticide = listSum.length > 0 ? mean(listSum) : undefined
                const maxSumPesticides = max(listSum)

                return {
                    catchmentPoint: { value: stationName },
                    year: { value: year },
                    total: { value: isUndefined(total) ? undefined : round(total, 5), align: 'right' },
                    nbOperations: { value: nbOperation, align: 'right' },
                    nbAnalysisPesticides: { value: analysis.length, align: 'right' },
                    nbQuantificationsPesticides: { value: quantifiedAnalysis.length, align: 'right' },
                    max: {
                        value: resultMax,
                        align: 'right',
                        color: getThresholdColor(resultMax, threshold1, threshold2),
                        ...getTooltip(substance, maxAnalyse),
                    },
                    substanceMax: { value: substance },
                    annualAverageTotalPesticides: { value: isUndefined(averageSumPesticide) ? undefined: round(averageSumPesticide, 5), align: 'right' },
                    maxSumPesticides: { value: isUndefined(maxSumPesticides) ? undefined: round(maxSumPesticides, 5), align: 'right' },
                    annualAverageMax: { value: isUndefined(maxAverageTest) ? undefined: round(maxAverageTest, 4), align: 'right' },
                }
            })
        })
        return orderBy(flatten(formated), ['catchmentPoint.value', 'year.value'], ['asc', 'desc'])
    }, [cities, parameters, settings, pesticidesAnalysisGroup, qualitometers, threshold1, threshold2, listSumPesticides])

    return (
        <>
            <NewTable
                rows={analysisFormated}
                headers={['catchmentPoint', 'year', 'total', 'nbOperations', 'nbAnalysisPesticides', 'nbQuantificationsPesticides', 'max', 'substanceMax', 'annualAverageTotalPesticides', 'maxSumPesticides', 'annualAverageMax']}
                headersLabel={{
                    nbOperations: (<span style={{ whiteSpace: 'pre-wrap' }}>{i18n.nbOperationsReturnLine}</span>),
                    nbAnalysisPesticides: (<span style={{ whiteSpace: 'pre-wrap' }}>{i18n.nbAnalysisPesticidesReturnLine}</span>),
                    nbQuantificationsPesticides: (<span style={{ whiteSpace: 'pre-wrap' }}>{i18n.nbQuantificationsPesticidesReturnLine}</span>),
                    substanceMax: (<span style={{ whiteSpace: 'pre-wrap' }}>{i18n.substanceMaxReturnLine}</span>),
                    annualAverageTotalPesticides: (<span style={{ whiteSpace: 'pre-wrap' }}>{i18n.annualAverageTotalPesticidesReturnLine}</span>),
                    maxSumPesticides: (<span style={{ whiteSpace: 'pre-wrap' }}>{i18n.maxSumPesticidesReturnLine}</span>),
                    annualAverageMax: (<span style={{ whiteSpace: 'pre-wrap' }}>{i18n.annualAverageMaxReturnLine}</span>),
                }}

                rowsPerPageOptions={nbPerPageLabel}
                maxHeight={275}
            />
        </>
    )
}

FollowUpPesticidesTable.propTypes = {
    pesticidesAnalysis: PropTypes.arrayOf(PropTypes.instanceOf(DtoAnalysisLight)),
    listSumPesticides: PropTypes.arrayOf(PropTypes.string),
    threshold1: PropTypes.number,
    threshold2: PropTypes.number,
}

const FollowUpPesticidesChart = ({
    pesticidesAnalysis = [],
    station,
    threshold1,
    threshold2,
    startPeriod,
    endPeriod,
}) => {
    const {
        parameters,
        units,
        qualitometers,
    } = useSelector(store => ({
        parameters: store.ParameterReducer.parameters,
        units: store.UnitReducer.units,
        qualitometers: store.QualityReducer.qualitometersLight,
    }), shallowEqual)

    const analysisFormated = useMemo(() => {
        const filteredPesticidesAnalysis = pesticidesAnalysis.filter(a => !['0', '2', '3', '5', '7', '10'].includes(a.remark))
        const groupByQualitometer = groupBy(filteredPesticidesAnalysis, 'qualitometer')
        return Object.keys(groupByQualitometer).flatMap(id => {
            const group = groupBy(groupByQualitometer[id], 'parameter')
            const qualitometer = qualitometers.find(q => q.id === groupByQualitometer[id][0].qualitometer)
            return Object.keys(group).map(param => {
                const name = `${!hasValue(station) ? `${qualitometer?.code} - ` : ''}${getLabel(parameters, param, 'labelWithCode')}`
                return {
                    name,
                    dataList: group[param].map(analyse => ({ date: analyse.sampleDate, value: analyse.result })),
                    unit: getLabel(units, group[param].find(a => a.unit)?.unit, 'symbol'),
                }
            })
        })
    }, [pesticidesAnalysis, qualitometers, station, parameters, units])

    return (
        <QualityChart
            exportName={i18n.pesticidesFollowUp}
            grids={[{ height: 300 }]}
            xAxis={[{ min: startPeriod, max: endPeriod }]}
            yAxis={[{ name: `${i18n.substanceContents} (µg/L)`, min: 0 }]}
            series={analysisFormated}
            thresholds={[{ dataList: [{ value: threshold1 }, { value: threshold2 }].filter(t => !isUndefined(t.value)) }]}

            withToolTypeLine
            withToolTypeBar
            withToolTypeStack
            withToolLog
            withToolThreshold
            withToolMarker
            withToolLine
        />
    )
}

FollowUpPesticidesChart.propTypes = {
    startPeriod: PropTypes.number,
    endPeriod: PropTypes.number,
    pesticidesAnalysis: PropTypes.arrayOf(PropTypes.instanceOf(DtoAnalysisLight)),
    station: PropTypes.number,
    threshold1: PropTypes.number,
    threshold2: PropTypes.number,
}

const SumPesticidesChart = ({
    sumAnalysis = [],
    station,
    trendsMannKendall = [],
    trendsRupture = [],
    threshold1,
    threshold2,
    startPeriod,
    endPeriod,
}) => {
    const {
        qualitometers,
    } = useSelector(store => ({
        qualitometers: store.QualityReducer.qualitometersLight,
    }), shallowEqual)

    const qualitometer = useMemo(() => qualitometers.find(q => q.id === station), [qualitometers, station])

    const trendMannKendall = useMemo(() => {
        return trendsMannKendall.find(t => t.stationCode === qualitometer?.code)
    }, [qualitometer, trendsMannKendall])

    const trendRupture = useMemo(() => {
        return trendsRupture.find(t => t.stationCode === qualitometer?.code)
    }, [qualitometer, trendsRupture])

    const analysisGroup = useMemo(() => groupBy(sumAnalysis, 'qualitometer'), [sumAnalysis])

    const analysisFormated = useMemo(() => Object.keys(analysisGroup).map(key => {
        const { qualitometer: qualitometerId } = analysisGroup[key][0]
        const qualito = qualitometers.find(q => q.id === qualitometerId)
        return {
            idStation: qualitometerId,
            name: getStationName(qualito),
            unit: 'mg/L',
            dataList: analysisGroup[key].map(analyse => ({ date: analyse.sampleDate, value: analyse.result })),
        }
    }), [analysisGroup, qualitometers])

    const hypeFormated = useMemo(() => {
        if (!hasValue(station)) {
            return []
        }

        const minDate = minBy(sumAnalysis, 'sampleDate')?.sampleDate
        const maxDate = maxBy(sumAnalysis, 'sampleDate')?.sampleDate

        const mannKendallTrend = trendMannKendall ? [{
            name: i18n.mannKendallTrend,
            showSymbol: false,
            dataList: formatHypeTrend(trendMannKendall.coefficient, trendMannKendall.value, minDate, maxDate),
        }] : []

        if (trendRupture && (['preValue', 'preCoefficient', 'breakDate'].every(k => hasValue(trendRupture[k])) || ['postValue', 'postCoefficient', 'breakDate'].every(k => hasValue(trendRupture[k])))) {
            const maxValue = max([maxBy(sumAnalysis, 'result')?.result || 0, threshold1, threshold2])
            const ruptureLine = {
                name: i18n.trendRupture,
                dataList: [
                    { date: trendRupture.breakDate, value: 1.1 * maxValue },
                    { date: trendRupture.breakDate, value: 0 },
                ],
                lineStyle: 'dashed',
            }
            const rupture = {
                name: i18n.trendRupture,
                connectNulls: false,
                showSymbol: false,
                dataList: formatHypeTrendRupture(trendRupture, minDate, maxDate),
            }
            return [
                ...mannKendallTrend,
                ruptureLine,
                rupture,
            ]
        }

        return mannKendallTrend
    }, [station, sumAnalysis, threshold1, threshold2, trendMannKendall, trendRupture])

    return (
        <QualityChart
            exportName={i18n.pesticidesFollowUp}
            grids={[{ height: 225 }]}
            xAxis={[{ min: startPeriod, max: endPeriod }]}
            yAxis={[{ name: `${i18n.sumPesticides} (µg/L)`, min: 0 }]}
            series={[...analysisFormated, ...hypeFormated]}
            thresholds={[{ dataList: [{ value: threshold1 }, { value: threshold2 }].filter(t => !isUndefined(t.value)) }]}

            withToolTypeLine
            withToolTypeBar
            withToolLog
            withToolThreshold
            withToolMarker
            withToolLine
        />
    )
}

SumPesticidesChart.propTypes = {
    sumAnalysis: PropTypes.arrayOf(PropTypes.shape({
        result: PropTypes.number,
        sampleDate: PropTypes.number,
        qualitometer: PropTypes.number,
    })),
    station: PropTypes.number,
    trendsMannKendall: PropTypes.arrayOf(PropTypes.shape({})),
    trendsRupture: PropTypes.arrayOf(PropTypes.shape({})),
    threshold1: PropTypes.number,
    threshold2: PropTypes.number,
    startPeriod: PropTypes.number,
    endPeriod: PropTypes.number,
}

const HypeTable = ({
    sumAnalysis = [],
    qualitometersFiltered = [],
    station,
    setStation = () => { },
    trendsMannKendall = [],
    trendsRupture = [],
}) => {
    const {
        qualitometers,
    } = useSelector(store => ({
        qualitometers: store.QualityReducer.qualitometersLight,
    }), shallowEqual)

    const data = useMemo(() => {
        const groupedAnalysis = groupBy(sumAnalysis, 'qualitometer')
        return qualitometersFiltered.map(qualito => {
            const qualitometer = qualitometers.find(q => q.id === qualito.id)
            const { [qualitometer.id]: analysis = [] } = groupedAnalysis
            const trend = trendsMannKendall.find(t => t.stationCode === qualitometer.code)
            const rupture = trendsRupture.find(t => t.stationCode === qualitometer.code)
            return {
                catchmentPoint: { value: qualitometer.name || `[${qualitometer.code}]` },
                nbQuantifications: { value: analysis.length },
                mannKendallTrend: { value: (!hasValue(trend) || trend?.coefficient === 0) && i18n.noTrend || trend.coefficient > 0 && i18n.rise || i18n.decrease },
                ruptureDate: { value: rupture?.breakDate ? getDate(rupture.breakDate) : i18n.noRupture },
                color: station === qualitometer.id ? selectedColor : undefined,
                id: qualitometer.id,
            }
        })
    }, [qualitometers, qualitometersFiltered, station, sumAnalysis, trendsMannKendall, trendsRupture])

    return (
        <NewTable
            rows={data}
            headers={['catchmentPoint', 'nbQuantifications', 'mannKendallTrend', 'ruptureDate']}

            onClickRow={({ id }) => setStation(id === station ? undefined : id)}
            maxHeight={275}
        />
    )
}

HypeTable.propTypes = {
    sumAnalysis: PropTypes.arrayOf(PropTypes.shape({
        result: PropTypes.number,
        sampleDate: PropTypes.number,
        qualitometer: PropTypes.number,
    })),
    qualitometersFiltered: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
    })),
    station: PropTypes.number,
    setStation: PropTypes.func,
    trendsMannKendall: PropTypes.arrayOf(PropTypes.shape({})),
    trendsRupture: PropTypes.arrayOf(PropTypes.shape({})),
}

const HypeCardPesticides = ({
    pesticidesAnalysis = [],
    listSumPesticides,
    qualitometersFiltered = [],
    station,
    setStation = () => { },
    threshold1,
    threshold2,
    startPeriod,
    endPeriod,
}) => {
    const dispatch = useDispatch()

    const {
        qualitometers,
    } = useSelector(store => ({
        qualitometers: store.QualityReducer.qualitometersLight,
    }), shallowEqual)

    const [trendsMannKendall, setTrendsMannKendall] = useState([])
    const [trendsRupture, setTrendsRupture] = useState([])

    const sumAnalysis = useMemo(() => {
        const quantifiedAnalysis = pesticidesAnalysis.filter(a => !['0', '2', '3', '5', '7', '10'].includes(a.remark))
        const filteredAnalysis = listSumPesticides?.length ? quantifiedAnalysis.filter(a => listSumPesticides.includes(a.parameter)) : quantifiedAnalysis
        const groupByOperation = groupBy(filteredAnalysis, a => `${a.qualitometer}#${a.operation}`)
        return Object.keys(groupByOperation).map(key => {
            const { [key]: analysis = [] } = groupByOperation
            return {
                result: sumBy(analysis, 'result'),
                sampleDate: analysis[0].sampleDate,
                qualitometer: analysis[0].qualitometer,
            }
        })
    }, [listSumPesticides, pesticidesAnalysis])

    const filteredSumAnalysis = useMemo(() => {
        if (hasValue(station)) {
            return sumAnalysis.filter(({ qualitometer }) => station === qualitometer)
        }
        return sumAnalysis
    }, [station, sumAnalysis])

    useEffect(() => {
        if (!pesticidesAnalysis.length) {
            return
        }
        const groupedAnalysis = groupBy(sumAnalysis, 'qualitometer')
        qualitometersFiltered.forEach(({ id }) => {
            const qualitometer = qualitometers.find(q => q.id === id)
            if (!qualitometer) {
                return
            }
            const { [id]: analysis = [] } = groupedAnalysis

            const hypeData = analysis.map((a, index) => ({
                stationCode: qualitometer.code,
                stationTypeCode: qualitometer.stationType,
                parameterCode: 0,
                analysisNumber: index,
                measureDate: a.sampleDate,
                measure: a.result,
                unitCode: 0,
                remarkCode: 1,
            }))
            if (hypeData.length) {
                dispatch(IAEauAction.getHypeTrend(hypeData, qualitometer.code)).then(({ trends, breaks }) => {
                    const trendMannKendall = trends.filter(t => t.statisticsType === HYPE_TRENDS_CONSTANTS.MANN_KENDALL)
                    const trendRupture = breaks.filter(t => t.statisticsType === HYPE_TRENDS_CONSTANTS.TREND_RUPTURE)
                    setTrendsMannKendall(p => uniqBy([...trendMannKendall, ...p], 'stationCode'))
                    setTrendsRupture(p => uniqBy([...trendRupture, ...p], 'stationCode'))
                })
            }
        })
    }, [dispatch, pesticidesAnalysis.length, qualitometers, qualitometersFiltered, sumAnalysis])

    return (
        <Grid2 container>
            <Grid2 size={6}>
                <SumPesticidesChart
                    startPeriod={startPeriod}
                    endPeriod={endPeriod}
                    sumAnalysis={filteredSumAnalysis}
                    station={station}
                    trendsMannKendall={trendsMannKendall}
                    trendsRupture={trendsRupture}
                    threshold1={threshold1}
                    threshold2={threshold2}
                />
            </Grid2>
            <Grid2 size={6}>
                <HypeTable
                    sumAnalysis={sumAnalysis}
                    qualitometersFiltered={qualitometersFiltered}
                    station={station}
                    setStation={setStation}
                    trendsMannKendall={trendsMannKendall}
                    trendsRupture={trendsRupture}
                />
            </Grid2>
        </Grid2>
    )
}

HypeCardPesticides.propTypes = {
    pesticidesAnalysis: PropTypes.arrayOf(PropTypes.instanceOf(DtoAnalysisLight)),
    listSumPesticides: PropTypes.arrayOf(PropTypes.string),
    qualitometersFiltered: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
    })),
    station: PropTypes.number,
    setStation: PropTypes.func,
    threshold1: PropTypes.number,
    threshold2: PropTypes.number,
    startPeriod: PropTypes.number,
    endPeriod: PropTypes.number,
}

const FrequencyPesticides = ({
    pesticidesAnalysis = [],
    threshold = 0.5,
    specificThresholds = [],
    pesticides = [],
}) => {
    const {
        parameters,
    } = useSelector(store => ({
        parameters: store.ParameterReducer.parameters,
    }), shallowEqual)

    const thresholdsIndexed = useListIndexed(specificThresholds, 'parameterCode')

    const countParam = countBy(pesticidesAnalysis, 'parameter')

    const createSeries = (list, color, name) => {
        const groupByParameter = groupBy(list, 'parameter')
        const data = Object.keys(groupByParameter).map(param => ({
            value: [param, groupByParameter[param].length * 100 / countParam[param], groupByParameter[param].length],
        }))
        return {
            type: 'bar',
            data,
            name,
            stack: true,
            barMaxWidth: 30,
            itemStyle: {
                color,
            },
        }
    }

    const {
        without = [],
        higher = [],
        below = [],
    } = groupBy(pesticidesAnalysis, ({ result, parameter, remark }) => {
        if (!remark || ['0', '2', '3', '5', '7', '10'].includes(remark)) {
            return 'without'
        }
        const th = thresholdsIndexed[parameter]?.threshold1 ?? threshold
        return result > th ? 'higher' : 'below'
    })

    const serieHigher = createSeries(higher, '#FF6726', `${i18n.quantificationHigher} ${i18n.threshold}`)
    const serieBelow = createSeries(below, '#FFC096', `${i18n.quantificationBelow} ${i18n.threshold}`)
    const serieWithout = createSeries(without, '#DDD', i18n.withoutQuantification)

    const series = [
        serieBelow,
        serieHigher,
        serieWithout,
    ]

    const listParam = pesticides.map(param => {
        const [, percentHigher = 0, nbHigher = 0] = serieHigher.data.find(({ value }) => value[0] === param)?.value || []
        const [, percentBelow = 0, nbBelow = 0] = serieBelow.data.find(({ value }) => value[0] === param)?.value || []
        const [, , nbWithout = 0] = serieWithout.data.find(({ value }) => value[0] === param)?.value || []
        return {
            param,
            percentSum: percentHigher + percentBelow,
            percentHigher,
            nbTotal: nbHigher + nbBelow + nbWithout,
        }
    }).filter(p => p.percentSum)

    const listParamOrdered = orderBy(listParam, ['percentSum', 'percentHigher', 'nbTotal'], ['desc', 'desc', 'desc']).map(p => p.param)

    const options = {
        series,
        xAxis: [{
            type: 'category',
            data: take(listParamOrdered, 50),
            boundaryGap: true,
            showSplitLine: true,
            axisLabel: {
                formatter: v => {
                    const label = getLabel(parameters, v, 'name')
                    return label.length > 33 ? `${label.substring(0, 30)}...` : label
                },
                rotate: 50,
            },
        }],
        yAxis: [{
            type: 'value',
            name: `${i18n.frequency} (%)`,
            position: 'left',
            nameLocation: 'center',
            nameGap: 50,
            boundaryGap: true,
            min: 0,
            max: 100,
        }],
        legend: {
            top: '5%',
            left: '10%',
            right: '20%',
            type: 'scroll',
        },
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'shadow',
                snap: true,
            },
            formatter: params => {
                const paramName = getLabel(parameters, params[0].axisValue, 'labelWithCode')
                return `${paramName}<br />${params.map(({ seriesName, value: [, percent, result] }) => `${round(percent, 2)}% ${seriesName} (${result} ${result > 1 ? i18n.analysis : i18n.oneAnalysis}) `).join('<br />')}`
            },
        },
        grid: {
            top: '15%',
            left: '6%',
            right: '3%',
            bottom: '80',
            containLabel: true,
            height: 400,
        },
        toolbox: {
            right: '3%',
            top: '5%',
            itemSize: 18,
            feature: {
                saveAsImage: {
                    title: i18n.export,
                    icon: exportPictureIcon,
                    name: i18n.frequencyPesticideQuantification,
                },
                myToolExport: {
                    title: i18n.excelExport,
                    icon: exportExcelIcon,
                    onclick: () => {
                        const groupByParam = groupBy(pesticidesAnalysis, 'parameter')
                        const exportData = Object.keys(groupByParam).map(key => {
                            const analysis = groupByParam[key]
                            const {
                                countWithout = 0,
                                countHigher = 0,
                                countBelow = 0,
                            } = countBy(analysis, ({ result, remark }) => {
                                if (!remark || ['0', '2', '3', '5', '7', '10'].includes(remark)) {
                                    return 'countWithout'
                                }
                                return result > threshold ? 'countHigher' : 'countBelow'
                            })
                            const percentHigher = countHigher * 100 / countParam[key]
                            const percentBelow = countBelow * 100 / countParam[key]
                            const percentSum = percentHigher + percentBelow
                            return {
                                parameterCode: { value: key, cellType: 'right' },
                                parameter: getLabel(parameters, key, 'name'),
                                nbAnalysis: { value: countParam[key], format: '0', cellType: 'number' },
                                quantificationBelow: { value: `${round(percentBelow, 2)}%`, cellType: 'right' },
                                quantificationHigher: { value: `${round(percentHigher, 2)}%`, cellType: 'right' },
                                withoutQuantification: { value: `${round(countWithout * 100 / countParam[key], 2)}%`, cellType: 'right' },
                                percentSum,
                                percentHigher,
                            }
                        })
                        const sortedData = orderBy(exportData, ['percentSum', 'percentHigher', 'nbAnalysis.value'], ['desc', 'desc', 'desc'])
                        exportFile({
                            data: exportData.length ? [
                                {
                                    ...sortedData[0],
                                    headers: ['parameterCode', 'parameter', 'nbAnalysis', 'quantificationBelow', 'quantificationHigher', 'withoutQuantification'],
                                    customHeaders: ['parameterCode', 'parameter', 'nbAnalysis', `${i18n.quantificationBelow} ${threshold}`, `${i18n.quantificationHigher} ${threshold}`, 'withoutQuantification'],
                                },
                                ...sortedData.slice(1),
                            ] : [],
                            exportType: 'xlsx',
                            titleFile: i18n.frequencyPesticideQuantification,
                        }, true)
                    },
                },
            },
        },
    }
    return (
        <ReactECharts
            echarts={echarts}
            option={options}
            notMerge={true}
            lazyUpdate={true}
            className={'row no-margin'}
            style={{ height: 500 }}
        />
    )
}

FrequencyPesticides.propTypes = {
    pesticidesAnalysis: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.instanceOf(DtoAnalysisLight)),
        PropTypes.arrayOf(PropTypes.instanceOf(DtoAnalysisUltraLight)),
    ]),
    threshold: PropTypes.number,
    specificThresholds: PropTypes.arrayOf(PropTypes.instanceOf(DtoQualityThreshold)),
    pesticides: PropTypes.arrayOf(PropTypes.string),
}

const CardFollowUpPesticides = ({
    stationIds,
    defaultSelectedStation,
    startPeriod,
    endPeriod,
    producerExcluded,
    threshold1,
    threshold2,
    specificThresholds,
    pesticides,
    listSumPesticides,
}) => {
    const {
        parameters,
        settings,
        qualitometers,
    } = useSelector(store => ({
        parameters: store.ParameterReducer.parameters,
        settings: store.AdministrationReducer.settings,
        qualitometers: store.QualityReducer.qualitometersLight,
    }), shallowEqual)

    const qualitometersFiltered = useMemo(() => {
        const points = stationIds?.map(id => {
            return qualitometers.find(q => q.id === id)
        }).filter(q => !!q) || []
        return points.map(({ code, name = '', id }) => ({
            name: `${name} [${code}]`,
            id,
        }))
    }, [qualitometers, stationIds])

    const [station, setStation] = useState(defaultSelectedStation ?? qualitometersFiltered?.[0]?.id)
    const [analysis, setAnalysis] = useState([])

    useEffect(() => {
        setStation(defaultSelectedStation ?? qualitometersFiltered[0]?.id)
    }, [qualitometersFiltered])

    const { isLoaded } = useProgressDispatch(cancelRef => [
        AnalysisAction.getAnalysis({
            lightMode: true,
            stations: stationIds,
            startDate: startPeriod,
            endDate: endPeriod,
            excludedProducers: producerExcluded,
            parameters: pesticides,
            support: '3', // support 'eau'
        }).then(json => !cancelRef.current && setAnalysis(json?.map(j => new DtoAnalysisLight(j)))),
    ])

    const pesticidesAnalysis = useMemo(() => {
        return analysis.filter(({ sampleDate, result }) => hasValue(sampleDate) && hasValue(result))
    }, [analysis])

    const filteredPesticidesAnalysis = useMemo(() => {
        return hasValue(station) ? pesticidesAnalysis.filter(a => a.qualitometer === station) : pesticidesAnalysis
    }, [pesticidesAnalysis, station])

    const actions = [{
        icon: 'file_download',
        tooltip: i18n.export,
        onClick: () => {
            const pesticidesAnalysisGroup = groupBy(pesticidesAnalysis, 'qualitometer')

            const formated = Object.keys(pesticidesAnalysisGroup).map(key => {
                const qualitometer = qualitometers.find(q => q.id === parseInt(key))
                const yearGroup = groupBy(pesticidesAnalysisGroup[key], a => getYear(a.sampleDate))
                return Object.keys(yearGroup).map(year => {
                    const analysisFormated = yearGroup[year].map(a => ({ ...a, ...calculateThresholdResult(a, []) }))
                    const quantifiedAnalysis = analysisFormated.filter(a => !['0', '2', '3', '5', '7', '10'].includes(a.remark))
                    const listSumPesticideAnalysis = listSumPesticides.length ? quantifiedAnalysis.filter(a => listSumPesticides.includes(a.parameter)) : quantifiedAnalysis
                    const total = round(sumBy(listSumPesticideAnalysis, 'result'), 5)
                    const {
                        result: resultMax,
                        parameter,
                    } = searchMaxAnalysis(quantifiedAnalysis)
                    const nbOperation = uniqBy(quantifiedAnalysis, 'operation').length
                    const averageTotal = total / nbOperation // total / quantifiedAnalysis.length
                    const maxAnalysis = quantifiedAnalysis.reduce((acc, analyse) => {
                        acc[analyse.parameter] = Math.max((acc[analyse.parameter] || 0), analyse.result)
                        return acc
                    }, {})
                    const listMax = Object.keys(maxAnalysis).map(k => maxAnalysis[k])
                    const averageMax = sum(listMax) / listMax.length

                    const groupAnalysis = groupBy(analysisFormated, 'parameter')
                    const averageAnalysis = Object.keys(groupAnalysis).map(paramCode => calculateAverage(groupAnalysis[paramCode], settings))
                    const maxAverage = maxBy(averageAnalysis, a => round(a ?? 0, 4))

                    return {
                        code: { value: qualitometer?.code, cellType: 'string' },
                        catchmentPoint: qualitometer?.name,
                        year: { value: year, format: 'yyyy', cellType: 'date' },
                        total: { value: total, format: '0.0000', cellType: 'number' },
                        nbOperations: { value: nbOperation, format: '0', cellType: 'number' },
                        nbQuantifications: { value: quantifiedAnalysis.length, format: '0', cellType: 'number' },
                        max: { value: resultMax, format: '0.0000', cellType: 'number', color: getThresholdColor(resultMax, threshold1, threshold2) },
                        codeSubstance: { value: parameter, cellType: 'right' },
                        substanceMax: getLabel(parameters, parameter, 'name'),
                        annualAverageTotalPesticides: { value: round(averageTotal, 5), format: '0.00000', cellType: 'number' },
                        annualAverageOfMaxPesticides: { value: round(averageMax, 5), format: '0.00000', cellType: 'number' },
                        annualAverageMax: { value: maxAverage.value, cellType: 'right' },
                    }
                })
            })
            const dataOrdered = orderBy(flatten(formated), ['code.value', 'year.value'], ['asc', 'desc'])
            const data = dataOrdered.length ? [{ ...dataOrdered[0], headers: Object.keys(dataOrdered[0]) }, ...dataOrdered.slice(1)] : []
            exportFile({
                data,
                exportType: 'xlsx',
                titleFile: i18n.pesticidesFollowUp,
            })
        },
    }]

    return (
        <Card data-cy='follow_up_pesticides'>
            <CardTitle title={i18n.pesticidesFollowUp} actions={actions} />
            <CardContent>
                {!isLoaded && (
                    <ProgressCard indeterminate />
                )}
                {isLoaded && (
                    <>
                        {
                            pesticidesAnalysis.length ? (
                                <div style={{ padding: '0 5 10 5' }}>
                                    <div className='row no-margin' style={{ paddingTop: '8px' }}>
                                        <Select
                                            col={4}
                                            label={i18n.catchmentPoint}
                                            value={station}
                                            options={qualitometersFiltered}
                                            onChange={setStation}
                                            keyValue='id'
                                        />
                                    </div>
                                    <FollowUpPesticidesChart
                                        startPeriod={startPeriod}
                                        endPeriod={endPeriod}
                                        pesticidesAnalysis={filteredPesticidesAnalysis}
                                        station={station}
                                        threshold1={threshold1}
                                        threshold2={threshold2}
                                    />
                                    <HypeCardPesticides
                                        pesticidesAnalysis={pesticidesAnalysis}
                                        listSumPesticides={listSumPesticides}
                                        qualitometersFiltered={qualitometersFiltered}
                                        station={station}
                                        setStation={setStation}
                                        threshold1={threshold1}
                                        threshold2={threshold2}
                                        startPeriod={startPeriod}
                                        endPeriod={endPeriod}
                                    />
                                    <div style={{ margin: '0px 2vw 20px 2vw' }}>
                                        <FollowUpPesticidesTable
                                            pesticidesAnalysis={pesticidesAnalysis}
                                            listSumPesticides={listSumPesticides}
                                            threshold1={threshold1}
                                            threshold2={threshold2}
                                        />
                                    </div>
                                    <div style={{ margin: '0px 8vw 0px 8vw' }}>
                                        <FrequencyPesticides
                                            pesticidesAnalysis={filteredPesticidesAnalysis ?? pesticidesAnalysis}
                                            threshold={threshold1}
                                            specificThresholds={specificThresholds}
                                            pesticides={pesticides}
                                        />
                                    </div>
                                </div>
                            ) : (
                                <MessageCard>{i18n.noDataToDisplay}</MessageCard>
                            )
                        }
                    </>
                )}
            </CardContent>
        </Card>
    )
}

CardFollowUpPesticides.propTypes = {
    stationIds: PropTypes.arrayOf(PropTypes.number),
    defaultSelectedStation: PropTypes.number,
    startPeriod: PropTypes.number,
    endPeriod: PropTypes.number,
    producerExcluded: PropTypes.arrayOf(PropTypes.number),
    threshold1: PropTypes.number,
    threshold2: PropTypes.number,
    specificThresholds: PropTypes.arrayOf(PropTypes.instanceOf(DtoQualityThreshold)),
    pesticides: PropTypes.arrayOf(PropTypes.number),
    listSumPesticides: PropTypes.arrayOf(PropTypes.string),
}

export {
    FollowUpPesticidesTable,
    FollowUpPesticidesChart,
    // SumPesticidesChart,
    // HypeTable,
    HypeCardPesticides,
    FrequencyPesticides,
    CardFollowUpPesticides,
}