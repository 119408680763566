/* eslint-disable camelcase */
import DtoLiftingCivilEng from './DtoLiftingCivilEng'
import DtoLiftingEquipments from './DtoLiftingEquipments'

export default class DtoLiftingStation {
    constructor(obj) {
        this.idStation = obj.idStation

        this.link_civilEngs = obj.link_civilEngs ? obj.link_civilEngs.map(ce => new DtoLiftingCivilEng(ce)) : []
        this.link_equipments = obj.link_equipments ? obj.link_equipments.map(e => new DtoLiftingEquipments(e)) : []
    }
}