import Card from 'components/card/Card'
import { PropTypes } from 'prop-types'
import React, { useMemo } from 'react'
import { connect, shallowEqual, useSelector } from 'react-redux'
import CityDto from '../../../../../referencial/components/city/dto/CityDto'
import { filterStationCoordinates, getWGS84Coordinate } from '../../../../../utils/mapUtils/CoordinateUtils'
import OSMSatellite from '../../../../../utils/mapUtils/layers/OSMSatellite'
import SingleStationPoint from '../../../../../utils/mapUtils/layers/SingleStationPoint'
import OlMap from '../../../../../utils/mapUtils/Map'
import { getSiteType } from '../../../../../utils/mapUtils/SiteTypes'
import { execByType } from '../../../../../utils/StationUtils'
import { objectOf } from '../../../../../utils/StoreUtils'
import DtoStation from '../../../../dto/DtoStation'


const StationMapDashboardPanel = ({
    station,
    type,
    className,
    noMarkerTooltip,
    fullScreen,
    actionButtons,
}) => {
    const {
        citiesIndex,
    } = useSelector(store => ({
        citiesIndex: store.CityReducer.citiesIndex,
    }), shallowEqual)

    const data = useMemo(() => {
        const layersGroup = [OSMSatellite()]
        const stationWithCoordinates = filterStationCoordinates(station, citiesIndex)

        if (stationWithCoordinates) {
            layersGroup.push(
                SingleStationPoint({
                    site: stationWithCoordinates,
                    type: execByType(type, {
                        contact: () => getSiteType('CONTACT', 1),
                        quality: () => getSiteType('QUALITOMETER', 1),
                        piezometry: () => getSiteType('PIEZOMETER', 1),
                        pluviometry: () => getSiteType('PLUVIOMETER', 1),
                        hydrometry: () => getSiteType('HYDROMETRIC_STATION', 1),
                        productionUnit: () => getSiteType('UNIT', 1),
                        distributionUnit: () => getSiteType('UNIT', 1),
                        installation: () => {
                            return (() => {
                                switch (station.installationType) {
                                    case 0:
                                        return getSiteType('INSTALLATION_BOREHOLE', 1)
                                    case 1:
                                        return getSiteType('INSTALLATION_INDUSTRIAL_SITE', 1)
                                    case 2:
                                        return getSiteType('INSTALLATION_STEP', 1)
                                    case 3:
                                        return getSiteType('INSTALLATION_FLOW_OBSTRUCTION', 1)
                                    case 4:
                                        return getSiteType('INSTALLATION_POLLUTED_SOIL', 1)
                                    case 5:
                                        return getSiteType('INSTALLATION_FISHING_SPOT', 1)
                                    case 6:
                                        return getSiteType('INSTALLATION_CAVITY', 1)
                                    case 7:
                                        return getSiteType('INSTALLATION_SAMPLE_WORK', 1)
                                    case 8:
                                        return getSiteType('INSTALLATION_CAPTURE', 1)
                                    case 9:
                                        return getSiteType('INSTALLATION_LIFTING_STATION', 1)
                                    case 11:
                                        return getSiteType('INSTALLATION_TREATMENT', 1)
                                    case 12:
                                        return getSiteType('INSTALLATION_TANK', 1)
                                    default:
                                        return getSiteType('INSTALLATION', 1)
                                }
                            })()
                        },
                    }),

                }))
            const center = getWGS84Coordinate(stationWithCoordinates)
            return [layersGroup, {
                size: 300,
                center,
                zoom: 14,
            }]
        }
        return [layersGroup, {
            size: 300,
            center: [47.123010186224576, -1.820669934399723],
            zoom: 14,
        }]
    }, [station, station.x, station.y, station.projection, station.townCode, citiesIndex, type])

    if (data[0].length !== 1) {
        return (
            <Card round className={`${className}`}>
                <OlMap
                    noMarkerTooltip={noMarkerTooltip}
                    mapConf={data[1]}
                    layers={data[0]}
                    fullScreen={fullScreen}
                    actionButtons={actionButtons}
                />
            </Card>
        )
    }
    return null
}

StationMapDashboardPanel.propTypes = {
    station: PropTypes.instanceOf(DtoStation),
    type: PropTypes.string,
    citiesIndex: objectOf(CityDto),
    fullScreen: PropTypes.bool,
    actionButtons: PropTypes.arrayOf(PropTypes.element),
    className: PropTypes.string,
    noMarkerTooltip: PropTypes.bool,
}

const mapStateToProps = store => {
    return {
        citiesIndex: store.CityReducer.citiesIndex,
    }
}

export default connect(mapStateToProps)(StationMapDashboardPanel)