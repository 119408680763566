import moment from 'moment/moment'
import {
    chartLine,
    chartSymbol,
    exportExcelIcon,
    exportPictureIcon,
    fullScreenIcon,
    getAxisIntervalFormatter,
    histogramIcon,
    legendSymbol,
    lineIcon,
    logIcon,
    setLogOptions,
    setYOptions,
    thresholdIcon,
    toEchartsData,
    yAutomaticScaleValues,
} from '../../../components/echart/EChartUtils'
import { getDateWithHour, getFullDate } from '../../../utils/DateUtil'
import { findIndex, groupBy, isNil, isUndefined, keys, orderBy, round, sum, uniqBy } from 'lodash'
import { DEFROUNDVALUE, hasBooleanValue, hasValue } from '../../../utils/NumberUtil'
import {
    generateGradient,
    getColorCircleElement,
    getColorCircleEvent, getColorFromPalette,
    getColorFromPalette2,
    getEventColor,
    getRGBColor, getThresholdColor,
} from '../../../utils/ColorUtil'
import i18n from 'simple-react-i18n'
import { exportFile } from '../../../utils/ExportDataUtil'
import EChart from '../../../components/echart/EChart'
import React, { useEffect, useMemo, useState } from 'react'
import ReactDOMServer from 'react-dom/server'
import { statusIcon } from '../../../utils/StatusUtil'
import { chunkWithWords, getExportFormat, getI18nOrLabel } from '../../../utils/StringUtil'
import { getEventGraph, getEventsBar } from '../../../utils/PiezometryUtils'
import Axis from '../../../components/echart/Axis'
import Line from '../../../components/echart/series/Line'
import PropTypes from 'prop-types'
import HydrometryAction from '../../actions/HydrometryAction'
import EventsAction from '../../../events/actions/EventsAction'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import DtoHydrometricStation from '../../dto/DtoHydrometricStation'
import { arrayOf, getLabel } from '../../../utils/StoreUtils'
import DtoHydrometryThreshold from '../../dto/DtoHydrometryThreshold'
import DtoEvent from '../../../events/dto/DtoEvent'
import DtoHydroMeasures from '../../dto/chronicMeasures/DtoHydroMeasures'
import MessageCard from '../../../components/card/MessageCard'
import StationAction from '../../../station/actions/StationAction'
import { getQualifications, getStatuses } from '../../../utils/QualityUtils'
import Bar from '../../../components/echart/series/Bar'
import DtoPredMeasure from '../../../iaeau/dto/DtoPredMeasure'
import DtoPredStat from '../../../iaeau/dto/DtoPredStat'
import MultiBand from '../../../components/echart/series/MultiBand'
import { getMeasureJplus } from '../../../iaeau/utils/IAEauUtils'
import AnalysisAction from '../../../quality/actions/AnalysisAction'
import DtoAnalysisLight from '../../../quality/dto/analyse/DtoAnalysisLight'
import { calculateValue, getRemarks } from '../../../utils/AnalyseUtils'
import Scatter from '../../../components/echart/series/Scatter'
import { repeatList } from '../../../utils/ListUtil'
import { BAR, LINE } from 'components/echart/QualityChart'

const THRESHOLD = 0
const NOTHING = 2

const ChartReviewHydro = ({
    id,
    hydroStatistics,
    fullScreen,
    setFullScreen,
    hydroMeasures,
    minDate,
    maxDate,
    showLegend = true,
    modelData,
}) => {
    const {
        hydrometricStation,
        hydrometryThresholds,
        stationEvents,
        hydroDataTypes,
        iaeauModels,
        parameters,
        associatedSites,
        units,
        hydrometerChartOptions,
        hydrometryDataTypes,
    } = useSelector(store => ({
        hydrometryThresholds: store.HydrometryReducer.hydrometryThresholds,
        hydrometricStation: store.HydrometryReducer.hydrometricStation,
        stationEvents: store.EventsReducer.stationEvents,
        hydroDataTypes: store.StationReducer.hydroDataTypes,
        iaeauModels: store.IAEauReducer.iaeauModels,
        parameters: store.ParameterReducer.parameters,
        associatedSites: store.StationReducer.associatedSites,
        units: store.UnitReducer.units,
        hydrometerChartOptions: store.HydrometerStationReducer.chartOptions,
        hydrometryDataTypes: store.HydrometryReducer.hydrometryDataTypes,
    }), shallowEqual)

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(HydrometryAction.fetchHydrometricThresholds())
        dispatch(HydrometryAction.fetchHydrometricStation(id))
        dispatch(EventsAction.fetchStationEvents('hydrometry', id))
        dispatch(StationAction.fetchDataTypesByProject('SIH'))
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    /*
    const chartMinDate = chartTab === 'HISTO' ? moment(minDate).startOf('year').valueOf() : minDate
    const chartMaxDate = chartTab === 'HISTO' ? moment(maxDate).add(1, 'year').startOf('year').valueOf() : maxDate
    */

    const [chartType, setChartType] = useState(LINE)
    const [stack, setStack] = useState()
    const [isLogActive, setIsLogActive] = useState(false)
    const [displayLegend, setDisplayLegend] = useState(showLegend)
    const [stateThreshold, setStateThreshold] = useState(THRESHOLD)
    const [displayMarker, setDisplayMarker] = useState(false)
    const [displayLine, setDisplayLine] = useState(true)
    const [qualitoMeasures, setQualitoMeasures] = useState([])

    const [series, grids, xAxis, yAxis, gridsHeights] = [[], [], [], [], [55]]
    const axisLabelObj = getAxisIntervalFormatter(moment(maxDate), moment(minDate))
    const events = stationEvents.filter(e => {
        if (e.eventType === 'T') {
            return false
        }
        if (e.date) {
            const eventDate = getDateWithHour(e.date, e.eventHour).valueOf()
            return eventDate >= minDate && eventDate <= maxDate && e.graph == '1'
        }
        return false
    })

    const hydroMeasuresFiltered = useMemo(() => {
        if (!isLogActive) {
            return hydroMeasures
        }
        return hydroMeasures.map(hm => ({
            ...hm,
            measures: hm.measures.filter(m => m.value > 0),
        }))
    }, [isLogActive])

    const getHydrometerOption = (type) => hydrometerChartOptions.find(opt => type == opt.dataType) || {}

    const getModelSeries = (typeId, gridIndex, xAxisIndex, yAxisIndex) => {
        const { predMeasures, selectedPred } = modelData
        const toEchartMeasure = (v, measure) => ({ value: [measure.horizonMode === 'hours' ? measure.date : moment(measure.date).hour(12).valueOf(), v, { measure }] })
        if (selectedPred && selectedPred[typeId] && predMeasures[typeId]?.length) {
            const pred = selectedPred[typeId].idModel ? { ...selectedPred[typeId], model: iaeauModels.find(m => m.idModel === selectedPred[typeId].idModel) } : selectedPred[typeId]
            const allSeries = groupBy(predMeasures[typeId], 'serieName')
            const nbLineSeries = Object.keys(allSeries).filter(key => hasValue(allSeries[key][0].value)).length
            return Object.keys(allSeries).flatMap((serieName, idx) => {
                const orderedMeasures = orderBy(allSeries[serieName], 'date')
                const band = (() => {
                    if (hasValue(orderedMeasures[0].doubtMin)) {
                        const lower = {
                            showSymbol: false,
                            color: pred?.model?.color || 'grey',
                            data: orderedMeasures.map(m => toEchartMeasure(m.doubtMin, m)),
                            name: `${pred.source} - ${getI18nOrLabel(serieName) && serieName !== 'undefined' ? getI18nOrLabel(serieName) : 'Min'}`,
                            gridIndex,
                            xAxisIndex,
                            yAxisIndex,
                        }
                        const upper = {
                            showSymbol: false,
                            color: pred?.model?.color || 'grey',
                            data: orderedMeasures.map(m => toEchartMeasure(m.doubtMax, m)),
                            name: `${pred.source} - ${getI18nOrLabel(serieName) && serieName !== 'undefined' ? getI18nOrLabel(serieName) : 'Max'}`,
                            gridIndex,
                            xAxisIndex,
                            yAxisIndex,
                        }
                        return [MultiBand({ bands: [lower, upper], noSort: true, noGap: true, stack: `${pred.source}${idx}` })]
                    }
                    return []
                })()
                const line = (() => {
                    if (hasValue(orderedMeasures[0].value)) {
                        return [Line({
                            data: orderedMeasures.map(m => toEchartMeasure(m.value, m)),
                            name: `${pred.source} - ${getI18nOrLabel(serieName) && serieName !== 'undefined' ? getI18nOrLabel(serieName) : 'Prévision'}`,
                            color: nbLineSeries > 1 ? getColorFromPalette(idx) : (pred?.model?.color || 'black'),
                            connectNulls: true,
                            gridIndex,
                            xAxisIndex,
                            yAxisIndex,
                            lineStyle: {
                                normal: {
                                    color: nbLineSeries > 1 ? getColorFromPalette(idx) : (pred?.model?.color || 'black'),
                                    width: pred?.model?.lineWidth || 2,
                                    type: pred?.model?.lineType || 'dashed',
                                    opacity: pred?.model?.lineOpacity || 1,
                                },
                            },
                            itemStyle: {
                                normal: {
                                    color: nbLineSeries > 1 ? getColorFromPalette(idx) : (pred?.model?.color || 'black'),
                                },
                            },
                            showSymbol: false,
                            isPiezo: true,
                        })]
                    }
                    return []
                })()
                return [...band, ...line]
            })
        }
        return []
    }


    const addEvents = (series, grids, xAxis, yAxis, gridsHeights, axisLabelObj, events) => {
        const eventOptions = {
            tooltip: {
                trigger: 'item',
                formatter: (params) => {
                    const startDate = getFullDate(params.value[2].date)
                    const eventsFiltered = events.filter(e => e.date === params.value[2].date)
                    const eventsList = eventsFiltered.filter(e => {
                        const startDate = getDateWithHour(e.date, e.eventHour).valueOf()
                        const endDate = e.endDate && e.endDate - startDate > 20000000 ? e.endDate : startDate + 20000000
                        return startDate >= params.value[0] && endDate <= params.value[1] && e.graph == '1'
                    })
                    const labelEvents = eventsList.reduce((acc, v) => {
                        const comment = v.comment ? (chunkWithWords(v.comment, 40).replaceAll('\n', '<br />')) : i18n.event
                        if (params.value[2].endDate) {
                            const endDate = getFullDate(params.value[2].endDate)
                            return `${acc}<br />${getColorCircleEvent(v.eventType)} ${startDate}-${endDate} : ${comment}`
                        }
                        return `${acc}<br />${getColorCircleEvent(v.eventType)} ${startDate} : ${comment}`
                    }, i18n.events)
                    return labelEvents
                },
            },
            itemStyle: {
                normal: {
                    opacity: 0.5,
                },
            },
            yAxisIndex: 1,
        }
        series.push(getEventGraph(events, eventOptions))
        series.push(getEventsBar(events))
        grids.push({
            top: sum(gridsHeights),
            right: '2%',
            height: 40,
            left: 100,
        })
        xAxis.push(Axis({
            type: 'time',
            position: 'bottom',
            min: minDate,
            max: maxDate,
            interval: axisLabelObj.interval,
            axisLabel: { show: false },
            axisLine: { show: false },
            axisTick: { show: false },
        }))
        yAxis.push(Axis({
            type: 'value',
            data: [i18n.events],
            nameLocation: 'middle',
            minInterval: 1,
            nameGap: 40,
            position: 'right',
            axisLabel: { show: false },
            axisLine: { show: false },
            axisTick: { show: false },
        }))
        yAxis.push(Axis({
            type: 'category',
            data: [i18n.events],
            nameLocation: 'middle',
            minInterval: 1,
            nameGap: 40,
            position: 'left',
        }))
        gridsHeights.push(60)
    }

    useEffect(() => {
        const hydroStatsFiltered = orderBy(hydroStatistics, ['order', 'typeId'], 'asc').filter(stat => !hasBooleanValue(stat.showData) || stat.showData)
        const idsNitrates = hydroStatsFiltered.map(stat => {
            return hydroDataTypes.find(h => h.id === stat.typeId)?.codeParameter
        }).filter(m => !isNil(m)).filter(p => !(p === ''))
        const assIdsQualito = associatedSites.filter(asso => asso.typeName === 'quality').map(att => att.stationLinkedId)
        AnalysisAction.getAnalysis({
            lightMode: true,
            stations: assIdsQualito,
            parameters: idsNitrates,
            support: '3', // support 'eau'
        }).then(json => setQualitoMeasures(groupBy(json.map(a => new DtoAnalysisLight(a)), 'qualitometer')))
    }, [])

    const addGrid = (series, grids, xAxis, yAxis, gridsHeights, axisLabelObj, stat, color, infoDataType) => {
        const isOtherHeight = stat.chronicFollowUp && stat.typeId !== 4
        if (!isOtherHeight) {
            grids.push({
                top: sum(gridsHeights),
                right: '2%',
                height: 150,
                left: 100,
            })
        }

        const measures = toEchartsData((hydroMeasures.find(obj => obj.dataType === stat.typeId)?.measures || []), undefined, undefined, undefined, displayMarker ? 6 : 0)
        const thresholdsFiltered = stateThreshold === THRESHOLD ? hydrometryThresholds.filter(t => !isUndefined(t.value) && t.stationId == id && t.dataType == stat.typeId) : []
        const thresholds = isOtherHeight ? [] : thresholdsFiltered.map(t => ({
            yAxis: t.value,
            symbol: 'none',
            label: {
                show: true,
                position: 'middle',
                formatter: () => t.title ? `${t.title} : ${round(t.value, 10)} ${stat.unit ?? ''}` : '',
            },
            lineStyle: {
                normal: {
                    color: t.htmlColor || getThresholdColor(t.color),
                    type: 'dashed',
                },
            },
        }))

        const allpredMeasures = []
        if (measures.length) {
            getModelSeries(stat.typeId, grids.length - 1, isOtherHeight ? 1 : grids.length - 1, (isOtherHeight ? 1 : grids.length - 1) + 1).forEach(modelSerie => {
                series.push(modelSerie)
                allpredMeasures.push(modelSerie.obj.bands ? modelSerie.obj.bands.flatMap(b => b.data) : modelSerie.obj.data)
            })
        }

        const yScale = yAutomaticScaleValues([ ...measures.map(m => m.value[1]), ...thresholds.map(t => t.yAxis), ...allpredMeasures.flat().map(d => d[1])])

        const measuresHydro = hydroMeasuresFiltered.find(obj => obj.dataType === stat.typeId)?.measures?.map(m => ({
            ...m,
            symbol: displayMarker ? 'circle' : 'none',
            symbolSize: displayMarker ? 6 : 0,
        })) || []
        const baseArgs = {
            overOptions: true,
            connectNulls: false,
            yAxisIndex: (isOtherHeight ? 1 : grids.length - 1) + 1,
            xAxisIndex: isOtherHeight ? 1 : grids.length - 1,
            serieId: `additional${stat.typeId}`,
            lineStyle: {
                type: infoDataType?.lineType ?? 'solid',
                opacity: displayLine ? (infoDataType?.lineOpacity ?? 1) : 0,
                width: infoDataType?.lineWidth ?? 2,
            },
            max: () => {
                if (isLogActive) {
                    return
                }
                return yScale.max
            },
            min: () => {
                if (isLogActive) {
                    return
                }
                if (chartType === BAR || stack) {
                    return 0
                }
                return yScale.min
            },
        }
        const serieFunc = stat.typeId === -2 ? Bar : (chartType === LINE ? Line : Bar)
        if (measuresHydro.length && measuresHydro.find(m => measuresHydro[0].codepoint !== m.codepoint)) { // séries par point de prélèvement
            const colors = repeatList(generateGradient(color, '#FFF', 5), 10)
            const group = groupBy(measuresHydro, 'codepoint')
            Object.keys(group).forEach((key, idx) => {
                const marklines = (idx === 0 && thresholds.length) ? { markLine: { silent: false, symbol: 'none', precision: 10, data: thresholds } } : {}
                series.push(serieFunc({
                    ...baseArgs,
                    data: toEchartsData(group[key], undefined, undefined, undefined, displayMarker ? 6 : 0),
                    name: `${stat.label} - ${hydrometricStation.link_pointPrels.find(p => p.point === parseInt(key))?.name ?? 'Point inconnu'}`,
                    color: colors[idx],
                    ...marklines,
                }))
            })
        } else {
            const marklines = thresholds.length ? { markLine: { silent: false, symbol: 'none', precision: 10, data: thresholds } } : {}
            series.push(serieFunc({
                ...baseArgs,
                data: toEchartsData(measuresHydro, undefined, undefined, undefined, displayMarker ? 6 : 0),
                name: stat.label,
                color,
                ...marklines,
            }))
        }

        const parameter = parameters.find(p => p.code === stat.codeParameter)
        keys(qualitoMeasures).forEach(key => {
            const measure = qualitoMeasures[key]
                .filter(q => q.parameter === stat.codeParameter)
                .filter(m => m.analysisDate > minDate && m.analysisDate < maxDate)
                .sort((a, b) => a.analysisDate - b.analysisDate)
            if (measure.length) {
                const input = {
                    data: toEchartsData(uniqBy(measure, 'analysisDate').map(m => ({
                        date: m.analysisDate,
                        value: m.result,
                        symbol: getRemarks(m.remark)?.plot,
                        status: m.status,
                        qualification: m.qualification,
                        unit: getLabel(units, m.unit, 'symbol'),
                        isAnalysis: true,
                        analysis: m,
                    })), null, null, null, 10),
                    color,
                    showSymbol: true,
                    name: `${i18n.oneAnalysis} ${i18n.station} [${qualitoMeasures[key][0].qualitometer}] ${parameter.shortLabelWithCode ?? parameter.labelWithCode}`,
                    yAxisIndex: (isOtherHeight ? 1 : grids.length - 1) + 1,
                    xAxisIndex: isOtherHeight ? 1 : grids.length - 1,
                    serieId: `${stat.label} ${stat.codeParameter}`,
                }
                series.push(Scatter(input))
            }
        })
        if (!isOtherHeight) {
            xAxis.push(Axis({
                type: 'time',
                position: 'bottom',
                min: minDate,
                max: maxDate,
                interval: axisLabelObj.interval,
                gridIndex: grids.length - 1,
                axisLabel: { show: true, formatter: axisLabelObj.formatter },
                axisLine: { show: true },
                axisTick: { show: true },
                showSplitLine: true,
            }))

            const thresholdsValues = thresholdsFiltered.map(t => t.value)
            const graphicOption = getHydrometerOption(stat.typeId)
            yAxis.push(Axis({
                type: isLogActive ? 'log' : 'value',
                nameLocation: 'middle',
                name: stat.unit ? `${stat.label} [${stat.unit}]` : stat.label,
                gridIndex: grids.length - 1,
                nameGap: 40,
                showSplitLine: hasValue(graphicOption?.displayXIntervalYear) ? graphicOption.displayXIntervalYear === '1' : true,
                ...setYOptions(graphicOption, yScale),
                ...setLogOptions(isLogActive, [...thresholdsValues, ...measuresHydro.map(t => t.value)]),
            }))
            gridsHeights.push(190)
        }
    }


    const getExportData = () => {
        const isMultiPoint = hydroStatistics.some(stat => hydroStatistics.some(stat2 => stat.typeId === stat2.typeId && stat.codepoint !== stat2.codepoint))
        const data = hydroStatistics.flatMap(stat => {
            const measuresData = hydroMeasures.find(obj => obj.dataType === stat.typeId && obj.codepoint === stat.codepoint)?.measures || []
            const roundValue = hydrometryDataTypes.find(hdt => hdt.id === stat.typeId)?.numberDecimal || DEFROUNDVALUE
            return measuresData.map(d => ({
                stationCode: { value: hydrometricStation.code },
                stationName: { value: hydrometricStation.name },
                date: { value: getFullDate(d.date), format: 'dd/MM/yyyy HH:mm:ss', cellType: 'date' },
                value: { value: d.value, format: getExportFormat(roundValue), cellType: 'number' },
                type: { value: isMultiPoint ? `${stat.label} - ${stat.namePoint}` : stat.label },
                status: { value: getLabel(getStatuses(), d.status), cellType: 'string' },
                qualification: { value: getLabel(getQualifications(), d.qualification), cellType: 'string' },
                initialPoint: { value: d.initialPoint === 1 ? i18n.initialPoint : '' },
            }))
        })

        if (data.length) {
            data[0].headers = ['stationCode', 'stationName', 'date', 'value', 'type', 'status', 'qualification', 'initialPoint']
        }
        return data
    }

    addEvents(series, grids, xAxis, yAxis, gridsHeights, axisLabelObj, events)
    const hydroStatsFiltered = uniqBy(orderBy(hydroStatistics, ['order', 'typeId'], 'asc').filter(stat => !hasBooleanValue(stat.showData) || stat.showData), 'typeId')

    if (!hydroStatsFiltered.filter(c => !c.chronicFollowUp || c.typeId == 4).length) {
        return <div style={{ marginTop: 20 }}><MessageCard>{i18n.error} : {i18n.chartDisplay}</MessageCard></div>
    }
    hydroStatsFiltered.forEach((stat, idx) => {
        const infoDataType = hydroDataTypes.find(h => h.id === stat.typeId)
        addGrid(series, grids, xAxis, yAxis, gridsHeights, axisLabelObj, { ...stat, ...infoDataType }, stat.color || getColorFromPalette2(idx), infoDataType)
    })
    const chartInnerHeight = fullScreen ? window.innerHeight / 1.5 : window.innerHeight / 2.3
    const updateHeightGrid = grids.filter(g => g.height === 150).length === 1
    let sumGridsHeight = sum(gridsHeights)
    if (updateHeightGrid) {
        const gridIndex = findIndex(grids, g => g.height === 150)
        sumGridsHeight = sumGridsHeight - grids[gridIndex].height + chartInnerHeight - 40
        grids[gridIndex].height = chartInnerHeight
    }

    // these functions are called in the option object as well as in the setOption in the mouseEvent
    const getLegend = () => ({
        top: 20,
        left: '5%',
        right: `${30 * 8 + 50}px`,
        type: 'scroll',
        show: displayLegend,
    })

    const stationTitle = hydrometricStation.name || hydrometricStation.code || ''

    const getToolbox = () => ({
        top: '25px',
        right: '35px',
        showTitle: false,
        itemSize: 18,
        tooltip: {
            show: true,
            position: 'bottom',
        },
        feature: {
            myToolLine: {
                show: true,
                title: i18n.lines,
                icon: lineIcon,
                onclick: () => {
                    setStack(undefined)
                    setChartType(LINE)
                },
                iconStyle: {
                    borderColor: chartType === LINE ? '#4d93c9' : '#5c5c5c',
                },
            },
            myToolBar: {
                show: true,
                title: i18n.histogram,
                icon: histogramIcon,
                onclick: () => {
                    setStack(undefined)
                    setChartType(BAR)
                    setIsLogActive(false)
                },
                iconStyle: {
                    borderColor: chartType === BAR && !stack ? '#4d93c9' : '#5c5c5c',
                },
            },
            myToolLog: {
                show: true,
                title: i18n.logarithm,
                icon: logIcon,
                onclick: () => {
                    setStack(undefined)
                    setChartType(LINE)
                    setIsLogActive(prevDisplayLog => !prevDisplayLog)
                },
                iconStyle: {
                    borderColor: isLogActive ? '#4d93c9' : '#5c5c5c',
                },
            },
            myToolThreshold: {
                show: !!hydrometryThresholds.length,
                title: i18n.toggleThreshold,
                icon: thresholdIcon,
                onclick: () => setStateThreshold(prevStateThreshold => {
                    return prevStateThreshold === THRESHOLD ? NOTHING : THRESHOLD
                }),
                iconStyle: {
                    borderColor: stateThreshold !== NOTHING ? '#4d93c9' : '#5c5c5c',
                },
            },
            myToolToggleLegend: {
                show: true,
                title: i18n.toggleLegend,
                icon: legendSymbol,
                onclick: () => setDisplayLegend(prevHiddenLegend => !prevHiddenLegend),
                iconStyle: {
                    borderColor: displayLegend ? '#4d93c9' : '#5c5c5c',
                },
            },
            myToolToggleMarker: {
                show: true,
                title: i18n.toggleMarker,
                icon: chartSymbol,
                onclick: () => setDisplayMarker(prevHiddenMarker => !prevHiddenMarker),
                iconStyle: {
                    borderColor: displayMarker ? '#4d93c9' : '#5c5c5c',
                },
            },
            myToolToggleLine: {
                show: true,
                title: i18n.toggleLine,
                icon: chartLine,
                onclick: () => setDisplayLine(prevHiddenLine => !prevHiddenLine),
                iconStyle: {
                    borderColor: displayLine ? '#4d93c9' : '#5c5c5c',
                },
            },
            saveAsImage: {
                show: true,
                title: i18n.pictureExport,
                icon: exportPictureIcon,
                name: `${i18n.graph} ${stationTitle}`,
            },
            myToolExport: {
                show: true,
                title: i18n.excelExport,
                icon: exportExcelIcon,
                onclick: () => {
                    exportFile({
                        data: getExportData(),
                        exportType: 'xlsx',
                        titleFile: `${i18n.overview} ${stationTitle}`,
                    })
                },
            },
            myToolFullScreen: {
                show: true,
                title: i18n.fullScreen,
                icon: fullScreenIcon,
                onclick: () => setFullScreen(!fullScreen),
            },
            restore: {
                show: true,
                title: i18n.restore,
            },
        },
    })

    const options = {
        series,
        tooltip: {
            trigger: 'axis',
            formatter: params => {
                const date = getFullDate(params[0].value[2].date ?? params[0].data.value[0])
                const paramsOrder = orderBy(params.filter(o => o.seriesName !== i18n.events).map(o => {
                    const hydroStats = hydroStatistics.find(h => h.label ===o.seriesName ? h.unit : '')
                    const unit = hydroStats?.unit ?? o.data.unit
                    return ({
                        seriesIndex: o.seriesIndex,
                        marker: o.marker,
                        seriesName: o.seriesName,
                        value: o.data.realValue ?? o.value[2].value ?? o.value[1],
                        // value: o.data.realValue ?? o.value[1],
                        unit: unit ? `[${unit}] ` : '',
                        status: ReactDOMServer.renderToString(statusIcon(o.value[2], 20)),
                        jPlus: getMeasureJplus(o.data?.value[2]?.measure),
                        isAnalysis: o.value[2]?.isAnalysis,
                        analysis: o.value[2]?.analysis,
                    })
                }), 'seriesIndex', 'asc')
                const stationEventsFiltered = stationEvents.filter(o => moment(o.date).isSame(params[0].value[0], 'day') && o.graph == '1' && o.eventType !== 'T')
                const labelEvents = stationEventsFiltered.length ? stationEventsFiltered.reduce((acc, v) => {
                    return `${acc} ${
                        getColorCircleElement(getRGBColor(getEventColor(v.eventType)))}${v.comment ? (chunkWithWords(v.comment, 40).replaceAll('\n', '<br />')) : i18n.event}<br />`
                }, '<br />') : ''
                const result = paramsOrder.map(o => (`<span style="display:flex;align-items:center;">${o.marker} ${o.seriesName} : ${o.isAnalysis ? calculateValue(o.analysis) : o.value} ${o.unit} ${o.jPlus}<span style="padding-left:5px">${o.status}</span> </span>`)).join('')
                return `${date} ${labelEvents} <br /> ${result}`
            },
        },
        grid: grids,
        xAxis,
        yAxis,
        axisPointer: {
            link: { xAxisIndex: 'all' },
        },
        setDataZoom: true,
        height: sumGridsHeight,
        toolbox: getToolbox(),
        legend: getLegend(),
    }
    return !!options.series.length && (
        <EChart
            id='hydroDashboardChart'
            options={options}
            scrollable={fullScreen}
        />
    )
}

ChartReviewHydro.propTypes = {
    hydrometricStation: PropTypes.instanceOf(DtoHydrometricStation),
    hydrometryThresholds: arrayOf(DtoHydrometryThreshold),
    stationEvents: arrayOf(DtoEvent),

    id: PropTypes.number,
    hydroStatistics: PropTypes.arrayOf(PropTypes.shape({
        typeId: PropTypes.number,
        label: PropTypes.string,
    })),
    fullScreen: PropTypes.bool,
    setFullScreen: PropTypes.func,
    hydroMeasures: PropTypes.arrayOf(PropTypes.instanceOf(DtoHydroMeasures)),
    minDate: PropTypes.number,
    maxDate: PropTypes.number,
    showLegend: PropTypes.bool,
    modelData: PropTypes.shape({
        predMeasures: PropTypes.objectOf(arrayOf(DtoPredMeasure)),
        selectedPred: PropTypes.objectOf(PropTypes.instanceOf(DtoPredStat)),
        predStats: arrayOf(DtoPredStat),
    }),
}

export default ChartReviewHydro
