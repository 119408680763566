import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { getDate } from 'utils/DateUtil'
import { DATE } from './KeyFigureConstants'
import i18n from 'simple-react-i18n'
import { getLongNumber, getNumberFormat } from '../../utils/NumberUtil'
import { filter } from 'lodash'
import { sieauTooltip } from '../../utils/FormUtils'
import { deburr, lowerCase } from 'lodash'

const $ = window.$

class KeyFigurePanel extends Component {
    getRows = () => {
        const filterValue = this.props.showAllValues ? this.props.data : filter(this.props.data, (o) => o.value && o.value != '0')
        return filterValue.map((data) => {
            const value = (() => {
                if (data.typeValue) {
                    if (data.typeValue === DATE) {
                        return [getDate(data.value), getDate(data.value)]
                    }
                    return [getLongNumber(data.value), getNumberFormat(data.value)]
                }
                return [data.value, data.value]
            })()
            const title = (() => {
                try {
                    return i18n[data.title]
                } catch (e) {
                    return data.title
                }
            })()
            const tooltip = (() => {
                try {
                    return i18n[data.tooltip]
                } catch (e) {
                    return data.tooltip
                }
            })()
            const tooltipObject = sieauTooltip(`${value[1]} ${tooltip || title}`, null, 'bottom')
            const className = this.props.singleLine ? 'col l12 m12 s12' : 'col'
            return (
                <div className={ className } key={deburr(lowerCase(title))}>
                    <div className='row no-margin'>
                        <span id={deburr(lowerCase(title))}>{ title }</span>
                    </div>
                    <div className='row no-margin right'>
                        <a className='white-text' { ...tooltipObject }>
                            <h4 className='margin-top-0'>{ value[0] }</h4>
                        </a>
                    </div>
                </div>
            )
        })
    }

    componentDidUpdate() {
        $('.keyfigures .tooltipped').tooltip({ delay: 50, html: true })
    }

    componentDidMount() {
        $('.keyfigures .tooltipped').tooltip({ delay: 50, html: true })
    }

    render() {
        const { noMargin, round } = this.props
        return (
            <div className='keyfigures row no-margin'>
                <div className={`card keyfigures-card ${noMargin ? 'no-margin' : ''}`} style={round ? { borderRadius: 5 } : {}}>
                    <div className='card-content right-align no-padding' style={round ? { borderRadius: 5 } : {}}>
                        <div className='row no-margin' id='key_figure_panel'>
                            { this.getRows() }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

KeyFigurePanel.propTypes = {
    data: PropTypes.arrayOf(PropTypes.shape({
        title: PropTypes.string,
        tooltip: PropTypes.string,
        value: PropTypes.number,
        typeValue: PropTypes.string,
    })),
    showAllValues: PropTypes.bool,
    title: PropTypes.string,
    icon: PropTypes.string,
    singleLine: PropTypes.bool,
    noMargin: PropTypes.bool,
    round: PropTypes.bool,
}
KeyFigurePanel.defaultProps = {
    singleLine: false,
}
export default KeyFigurePanel
