import User from 'account/dto/User'
import moment from 'moment'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import MultiContactsAutocomplete from 'referencial/components/contact/components/MultiContactsAutocomplete'
import MultiContributorsAutocomplete from 'referencial/components/contributor/components/MultiContributorsAutocomplete'
import i18n from 'simple-react-i18n'
import useSandreList from 'utils/customHook/useSandreList'
import { authorizeContributors, authorizeFilter } from 'utils/HabilitationUtil'
import Input from '../../../components/forms/Input'
import Select from '../../../components/forms/Select'
import SimpleDatePicker from '../../../components/forms/SimpleDatePicker'
import Textarea from '../../../components/forms/Textarea'
import ContactDto from '../../../referencial/components/contact/dto/ContactDto'
import ContributorDto from '../../../referencial/components/contributor/dto/ContributorDto'
import DtoSandreCode from '../../../referencial/dto/DtoSandreCode'
import { getDate } from '../../../utils/DateUtil'
import { hasValue } from '../../../utils/NumberUtil'
import { SANDRE } from 'referencial/constants/ReferencialConstants'


const FormCampaign = ({
    campaign = {},
    disabled = {},
    size = '',
    onChangeCampaign = () => { },

    contacts = [],
    contributors = [],
    accountUser = {},
}) => {
    const campaignStatus = useSandreList(SANDRE.CAMPAGNES_STATUT)
    const campaignTypes = useSandreList(SANDRE.CAMPAGNES_TYPE)

    const {
        beginningApplication,
        endingApplication,
        name,
        statut,
        contactCode,
        administratorCode,
        contributorCode,
        comment,
        campaignType,
    } = campaign
    const startDate = beginningApplication && moment(beginningApplication, 'DD/MM/YYYY').valueOf()
    const endDate = endingApplication && moment(endingApplication, 'DD/MM/YYYY').valueOf()
    return (
        <div className={size}>
            <div className='row no-margin padding-top-3-px'>
                <Input
                    col={6}
                    title={i18n.name}
                    value={name}
                    onChange={value => onChangeCampaign({ name: value })}
                    {...disabled}
                />
                <SimpleDatePicker
                    id='beginningApplication'
                    label={i18n.startDate}
                    value={startDate}
                    onChange={v => onChangeCampaign({ beginningApplication: v && getDate(v) })}
                    col={3}
                    max={endDate}
                    {...disabled}
                />
                <SimpleDatePicker
                    id='endingApplication'
                    label={i18n.endDate}
                    value={endDate}
                    onChange={v => onChangeCampaign({ endingApplication: v && getDate(v) })}
                    col={3}
                    min={startDate}
                    {...disabled}
                />
            </div>
            <div className='row no-margin padding-top-3-px'>
                <Select
                    col={6}
                    options={campaignTypes}
                    value={campaignType}
                    label={i18n.type}
                    keyLabel='name'
                    keyValue='code'
                    returnNull
                    onChange={e => onChangeCampaign({ campaignType: e })}
                    {...disabled}
                />
                <Select
                    integerValue
                    col={3}
                    options={campaignStatus}
                    value={statut}
                    label={i18n.status}
                    keyLabel='label'
                    keyValue='code'
                    returnNull
                    onChange={value => onChangeCampaign({ statut: value })}
                    {...disabled}
                />
            </div>
            <div className='row no-margin padding-top-3-px'>
                <MultiContactsAutocomplete
                    options={authorizeFilter(contacts, accountUser, contactCode, 'contributor')}
                    label={i18n.referent}
                    col={4}
                    onChange={ id => onChangeCampaign({ contactCode: id })}
                    values={contactCode}
                    {...disabled}
                />
                <MultiContributorsAutocomplete
                    options={authorizeContributors(contributors, accountUser, administratorCode)}
                    label={i18n.administrator}
                    col={4}
                    onChange={ id => onChangeCampaign({ administratorCode: id })}
                    values={administratorCode}
                    {...disabled}
                />
                <MultiContributorsAutocomplete
                    options={authorizeContributors(contributors, accountUser, parseInt(contributorCode))}
                    label={i18n.contributors}
                    col={4}
                    onChange={ id => onChangeCampaign({ contributorCode: hasValue(id) && `${id}` || undefined })}
                    values={parseInt(contributorCode)}
                    {...disabled}
                />
            </div>
            <div className='row no-margin padding-top-3-px'>
                <Textarea
                    col={12}
                    title={i18n.comment}
                    value={comment}
                    onChange={value => onChangeCampaign({ comment: value })}
                    {...disabled}
                />
            </div>
        </div>
    )
}

FormCampaign.propTypes = {
    campaign: PropTypes.shape({
        beginningApplication: PropTypes.string,
        endingApplication: PropTypes.string,
        name: PropTypes.string,
        campaignType: PropTypes.number,
        statut: PropTypes.number,
        contactCode: PropTypes.number,
        administratorCode: PropTypes.number,
        contributorCode: PropTypes.number,
        comment: PropTypes.string,
    }),
    disabled: PropTypes.shape({
        active: PropTypes.bool,
        disabled: PropTypes.bool,
    }),
    onChangeCampaign: PropTypes.func,
    size: PropTypes.string,

    contacts: PropTypes.arrayOf(PropTypes.instanceOf(ContactDto)),
    contributors: PropTypes.arrayOf(PropTypes.instanceOf(ContributorDto)),
    sandreCodes: PropTypes.arrayOf(PropTypes.instanceOf(DtoSandreCode)),
    accountUser: PropTypes.instanceOf(User),
}

const mapStateToProps = store => ({
    contacts: store.ContactReducer.contacts,
    contributors: store.ContributorReducer.contributors,
    sandreCodes: store.ReferencialReducer.sandreCodes,
    accountUser: store.AccountReducer.accountUser,
})

export default connect(mapStateToProps)(FormCampaign)