export default class DtoSampling {
    constructor(obj) {
        this.idSampling = obj.idSampling
        this.idSample = obj.idSample
        this.qualitometer = obj.qualitometer
        this.date = obj.date
        this.ref = obj.ref
        this.comment = obj.comment
        this.refLabo = obj.refLabo
        this.arrivalDate = obj.arrivalDate
    }
}
