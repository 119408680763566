import { orderBy } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import ActionComponent from '../../../../../components/ActionComponent'
import Table from '../../../../../components/datatable/Table'
import { getMapStateToProps, getPropTypes } from '../../../../../utils/StoreUtils'
import DtoBoreholeDrillingItem from '../dto/DtoBoreholeDrillingItem'


const storeProps = {
    sandreCodes: false,
}

class BoreholeDrillingDashboard extends ActionComponent {
    getSummary = (drillings) => {
        if (drillings && drillings.length) {
            const data = orderBy(drillings, 'depth').map(d => new DtoBoreholeDrillingItem(Object.assign({}, d, {
                diameterSymbol: d.diameter + i18n.milimetersSymbol,
                depth: d.depth + i18n.metersSymbol,
            })))
            return (
                <Table
                    data={data}
                    sortable
                    title={i18n.drillings}
                    condensed
                    className='smallCard padding-top-1'
                    showNbElements={false}
                    type={new DtoBoreholeDrillingItem({})}
                />
            )
        }
        return null
    }

    render() {
        return this.getSummary(this.props.station.link_drillings)
    }
}

BoreholeDrillingDashboard.propTypes = getPropTypes(storeProps, {
    station: PropTypes.object,
})

const mapStateToProps = () => getMapStateToProps(storeProps)

export default connect(mapStateToProps)(BoreholeDrillingDashboard)