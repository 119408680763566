import React, { useState } from 'react'
import i18n from 'simple-react-i18n'
import { StyledFieldSet, StyledLegend } from '../../../../components/StyledElements'
import Select from '../../../../components/forms/Select'
import { Grid } from '@mui/material'
import PropTypes from 'prop-types'
import DtoPluviometerStats from '../../../dto/DtoPluviometerStats'
import { chunk, entries, isNil, orderBy } from 'lodash'
import Checkbox from 'components/forms/Checkbox'
import SimpleDatePicker from '../../../../components/forms/SimpleDatePicker'
import { getFrequencyStats } from '../../../../piezometry/components/suivi/utils/SuiviStatsUtils'
import Button from '../../../../components/forms/Button'
import Icon from '../../../../components/icon/Icon'

const GENERIC = 'generic'
const NORMALS_YEARS= 'normalsYears'
const NORMALS_MONTHS= 'normalsMonths'
const MONTHS_STACK= 'monthsStack'
const EFFICIENT_RAIN = 'efficientRain'
const NORMAL_COMPARISON = 'comparisonNormal'

const ChoiseTypeChart = ({
    typeChart,
    setTypeChart,
    pluviometerStatistics,
    stat,
    setStat,
    dateComparedNormal,
    setDateComparedNormal,
    showEnveloppe,
    setShowEnveloppe,
    paramsFrequencyStats,
    setParamsFrequencyStats,
}) => {
    const [newTypeChart, setNewTypeChart] = useState(typeChart)
    const [newStat, setNewStat] = useState(stat)
    const [tmpDateComparedNormal, setTmpDateComparedNormal] = useState(dateComparedNormal)
    const [isValidateActive, setIsValidateActive] = useState(false)

    const typesGraph= [
        { value: NORMALS_MONTHS, label: i18n.normalMonths },
        { value: NORMALS_YEARS, label: i18n.normalYears },
        { value: GENERIC, label: i18n.graphicTracking },
        { value: MONTHS_STACK, label: i18n.monthsStacked },
        { value: EFFICIENT_RAIN, label: i18n.effectiveRain },
        { value: NORMAL_COMPARISON, label: i18n.comparisonNormal },
    ]

    const onSave = () => {
        setTypeChart(newTypeChart)
        setStat(newStat)
        setDateComparedNormal(tmpDateComparedNormal)
        setIsValidateActive(false)
    }


    const [isShowSeriesObjet, setIsShowSeriesObjet] = useState({})

    const onSaveFrequencyStats = () => {
        const activeModes = entries(isShowSeriesObjet)
            .filter(([, isActive]) => isActive)
            .map(([mode]) => mode)
        setParamsFrequencyStats(prev => ({ ...prev, showPreviousYears: activeModes }))
    }

    const change = (subStat, v) => {
        setIsShowSeriesObjet(prev => ({ ...prev, [subStat]: v }))
    }

    const freChecksHeight = chunk(getFrequencyStats(paramsFrequencyStats.histoYears), 2).map(([freS, freH]) => (
        <Grid>
            <Checkbox
                col={ 6 }
                label={ freS.name }
                checked={ isShowSeriesObjet[freS.code] }
                onChange={ v => change(freS.code, v)}
            />
            <Checkbox
                col={ 6 }
                label={ freH.name }
                checked={ isShowSeriesObjet[freH.code] }
                onChange={ v => change(freH.code, v)}
            />
        </Grid>
    ))

    const disabledButton = (!isValidateActive || (newTypeChart === GENERIC && isNil(newStat)))

    return (
        <>
            <StyledFieldSet>
                <StyledLegend>{i18n.chartOptions}</StyledLegend>
                <Select
                    col={ 4 }
                    label={ i18n.graphicType }
                    options={ typesGraph }
                    value={ newTypeChart }
                    onChange={ t => {
                        setNewTypeChart(t)
                        setIsValidateActive(true)
                    } }
                    noNullValue
                />
                { newTypeChart === 'generic' && (
                    <Select
                        col={ 4 }
                        label={ i18n.measureType }
                        options={ orderBy(pluviometerStatistics, ['order', 'typeId'], 'asc').map(m => ({ value: m.typeId, label: m.label })) }
                        value={ newStat }
                        onChange={ t => {
                            setNewStat(t)
                            setIsValidateActive(true)
                        } }
                        noSort
                        noNullValue
                    />
                )}
                { newTypeChart === NORMAL_COMPARISON && (
                    <SimpleDatePicker
                        col={ 4 }
                        value={ tmpDateComparedNormal }
                        onChange={ t => {
                            setTmpDateComparedNormal(t)
                            setIsValidateActive(true)
                        } }
                        noSort
                        noNullValue
                    />
                )}
            </StyledFieldSet>
            <Grid container justifyContent={'center'} alignItems='center' style={{ paddingTop: 8 }}>
                <Button
                    tooltip={ i18n.apply }
                    onClick={ onSave }
                    icon='border_color'
                    disabled={ disabledButton }
                    className={ `btn-floating btn-large ${!disabledButton ? 'pulse' : ''}` }
                />
            </Grid>
            { typeChart === GENERIC && (
                <StyledFieldSet>
                    <StyledLegend>{i18n.advancedOptions}</StyledLegend>
                    <Checkbox
                        col={6}
                        label={i18n.enveloppeMinMax}
                        checked={showEnveloppe}
                        onChange={() => setShowEnveloppe(!showEnveloppe)}
                    />
                </StyledFieldSet>
            )}
            {
                typeChart === GENERIC && (
                    <StyledFieldSet>
                        <StyledLegend>
                            <Grid container alignItems={'center'}>
                                {i18n.frequencyStats}
                                <Icon tooltip={i18n.descriptionCumulFrequencyStat} icon={'info'} style={{ fontSize: 18 }}/>
                            </Grid>
                        </StyledLegend>
                        {freChecksHeight.length ? freChecksHeight : i18n.noDataOverThisPeriod}
                        <Grid className='padding-bottom-1 padding-top-1 center-align'>
                            <Button
                                tooltip={i18n.apply}
                                onClick={onSaveFrequencyStats}
                                icon='border_color'
                                className={'btn-floating btn-large'}
                            />
                        </Grid>
                    </StyledFieldSet>
                )
            }
        </>
    )
}
ChoiseTypeChart.propTypes = {
    typeChart: PropTypes.string,
    setTypeChart: PropTypes.func,
    pluviometerStatistics: PropTypes.arrayOf(PropTypes.instanceOf(DtoPluviometerStats)),
    stat: PropTypes.number,
    setStat: PropTypes.func,
    dateComparedNormal: PropTypes.number,
    setDateComparedNormal: PropTypes.func,
    showEnveloppe: PropTypes.bool,
    setShowEnveloppe: PropTypes.func,
    paramsFrequencyStats: PropTypes.object,
    setParamsFrequencyStats: PropTypes.func,
}

export default ChoiseTypeChart