/* eslint-disable consistent-return */
import { AVERAGE, AVERAGE_SUBSTANCE, AVERAGE_SUM, EXCEEDING, LAST_VALUE, MAX, MAX_SUBSTANCE, MAX_SUM, MIN, MISSING_DATA, NITRATES, NITRATES_PESTICIDES, NO_CONTAMINATION, P90, PESTICIDES } from 'catchment/constants/CatchmentConstant'
import { groupBy, keys, max, maxBy, mean, sumBy, uniqBy } from 'lodash'
import { calculateAverage, calculateThresholdResult, filterQuantification, filterResult, filterValid, searchMaxResult, searchMinResult, searchP90Result } from 'utils/AnalyseUtils'
// import { hasValue } from 'utils/NumberUtil'

const colorGradients = [
    ['#fbee7c', '#f1bf5e', '#e69040', '#dc6022', '#d13104'],
    ['#fbc7dd', '#e996b9', '#d76595', '#c43371', '#b2024d'],
    ['#a9e2b9', '#7fbfac', '#569c9f', '#2c7892', '#025585'],
    ['#cebbf0', '#a98dda', '#845fc5', '#5e31af', '#390399'],
    ['#bbf0c1', '#8dd595', '#5fba6a', '#309f3e', '#028412'],
    ['#d4d4d4', '#acacac', '#848484', '#5b5b5b', '#333333'],
]

const getStationName = (station, city) => {
    const cityLabel = city && ` - ${city.labelWithCode}` || ''
    if (station?.code) {
        return `[${station?.code}] ${station?.name || ''}${cityLabel}`
    }
    return `${station?.name || ''}${cityLabel}`
}

const isExceedNitrates = (nitrates, threshold, useP90) => {
    if (useP90) {
        return searchP90Result(nitrates) > threshold
    }
    return nitrates.some(({ result }) => result > threshold)
}

const getNitratesState = (nitrates, options) => {
    const {
        thresholdNitrates,
        useP90 = false,
    } = options
    if (nitrates.length === 0) return MISSING_DATA

    const quantifiedNitrates = nitrates.filter(a => a.remark === '1')
    if (quantifiedNitrates.length === 0) return NO_CONTAMINATION

    return isExceedNitrates(nitrates, thresholdNitrates, useP90) ? EXCEEDING : NO_CONTAMINATION
}

const calculateAnnualAverageSumPesticides = (pesticides = [], listSumPesticides = []) => {
    if (pesticides.length === 0) {
        return
    }
    const filteredPesticides = pesticides.filter(a => listSumPesticides.includes(a.parameter))
    const groupOperation = groupBy(filteredPesticides, p => `${p.qualitometer}#${p.operation}`)
    const listSum = Object.keys(groupOperation).map(key => sumBy(groupOperation[key], 'result'))
    return mean(listSum) || 0
}

const isExceedAverageSumSubstance = (pesticides, threshold, listSumPesticides = []) => {
    const pesticidesFiltered = listSumPesticides.length ? pesticides.filter(a => listSumPesticides.includes(a.parameter)) : pesticides
    const groupOperation = groupBy(pesticidesFiltered, p => `${p.qualitometer}#${p.operation}`)
    const sumByOperation = Object.keys(groupOperation).map(key => sumBy(groupOperation[key], 'result'))
    return mean(sumByOperation) > threshold
}

const isExceedMaxSumSubstance = (pesticides, threshold, listSumPesticides = []) => {
    const pesticidesFiltered = listSumPesticides.length ? pesticides.filter(a => listSumPesticides.includes(a.parameter)) : pesticides
    const groupOperation = groupBy(pesticidesFiltered, p => `${p.qualitometer}#${p.operation}`)
    const sumByOperation = Object.keys(groupOperation).map(key => sumBy(groupOperation[key], 'result'))
    return max(sumByOperation) > threshold
}

const isExceedAverageSubstance = (pesticides, threshold, specificThreshold = {}) => {
    const groupPesticides = groupBy(pesticides, 'parameter')
    return Object.keys(groupPesticides).some(key => {
        const thresholdUsed = specificThreshold.thresholds?.find(t => t.parameterCode === key)?.threshold1 ?? threshold
        const nbOperation = uniqBy(groupPesticides[key], p => `${p.qualitometer}#${p.operation}`).length
        return sumBy(groupPesticides[key], 'result') / nbOperation > thresholdUsed
    })
}

const isExceedMaxSubstance = (pesticides, threshold, specificThreshold = {}) => {
    const groupPesticides = groupBy(pesticides, 'parameter')
    return Object.keys(groupPesticides).some(key => {
        const thresholdUsed = specificThreshold.thresholds?.find(t => t.parameterCode === key)?.threshold1 ?? threshold
        return searchMaxResult(groupPesticides[key]) > thresholdUsed
    })
}

const isExceedPesticides = (pesticides, options = {}) => {
    const {
        thresholdPesticides1,
        thresholdPesticides2,
        threshold = {},
        listSumPesticides = [],
        calculationMethod = [],
    } = options
    // second test is to catch old save value
    if (calculationMethod.length === 0 || calculationMethod.every(method => ![AVERAGE_SUBSTANCE, MAX_SUBSTANCE, AVERAGE_SUM, MAX_SUM].includes(method))) {
        // default MAX_SUM and AVERAGE_SUBSTANCE
        const exceedSumPesticides = isExceedMaxSumSubstance(pesticides, thresholdPesticides2, listSumPesticides)
        const exceedAveragePesticides = isExceedAverageSubstance(pesticides, thresholdPesticides1, threshold)
        return exceedSumPesticides || exceedAveragePesticides
    }
    const result = calculationMethod.map(method => {
        switch (method) {
            case AVERAGE_SUBSTANCE: return isExceedAverageSubstance(pesticides, thresholdPesticides1, threshold)
            case MAX_SUBSTANCE: return isExceedMaxSubstance(pesticides, thresholdPesticides1, threshold)
            case AVERAGE_SUM: return isExceedAverageSumSubstance(pesticides, thresholdPesticides2, listSumPesticides)
            case MAX_SUM: return isExceedMaxSumSubstance(pesticides, thresholdPesticides2, listSumPesticides)
            default: return false
        }
    })
    return result.some(r => r)
}

const getPesticidesState = (pesticides, options) => {
    if (!pesticides.length) return MISSING_DATA

    const qualitifiedPesticides = pesticides.filter(a => a.remark === '1')
    if (qualitifiedPesticides.length === 0) return NO_CONTAMINATION

    return isExceedPesticides(qualitifiedPesticides, options) ? EXCEEDING : NO_CONTAMINATION
}

const getContaminationState = (nitrates, pesticides, options = {}) => {
    const nitratesState = getNitratesState(nitrates, options)
    const pesticidesState = getPesticidesState(pesticides, options)

    if (nitratesState === MISSING_DATA && pesticidesState === MISSING_DATA) {
        return MISSING_DATA
    }
    if (nitratesState === EXCEEDING && pesticidesState === EXCEEDING) {
        return NITRATES_PESTICIDES
    }
    if (nitratesState === EXCEEDING) {
        return NITRATES
    }
    if (pesticidesState === EXCEEDING) {
        return PESTICIDES
    }
    return NO_CONTAMINATION
}

const getLastYearWithAnalysis = (year, groupAnalysis, stopYear) => {
    if (year < stopYear) {
        return []
    }
    if (groupAnalysis[year]?.length) {
        return groupAnalysis[year]
    }
    return getLastYearWithAnalysis(year - 1, groupAnalysis, stopYear)
}

const getFirstYearWithAnalysis = (year, groupAnalysis, stopYear) => {
    if (year > stopYear) {
        return []
    }
    if (groupAnalysis[year]?.length) {
        return groupAnalysis[year]
    }
    return getFirstYearWithAnalysis(year + 1, groupAnalysis, stopYear)
}

const getUsedValue = (analysis = [], criteria, settings = []) => {
    switch (criteria) {
        case LAST_VALUE: return maxBy(analysis, 'sampleDate').result
        case MAX: return searchMaxResult(analysis)
        case MIN: return searchMinResult(analysis)
        case P90: return searchP90Result(analysis)
        case AVERAGE: return calculateAverage(analysis, settings)
        default: return []
    }
}

const getUsedValues = (analysis = [], criteria, settings = []) => {
    const validAnalysis = filterQuantification(filterValid(filterResult(analysis)))
    if (!validAnalysis.length) {
        return []
    }
    const formattedAnalysis = validAnalysis.map(a => ({ ...a, ...calculateThresholdResult(a) }))
    const groupedAnalysis = groupBy(formattedAnalysis, 'qualitometer')

    return keys(groupedAnalysis).map(key => ({ qualitometer: key, value: getUsedValue(groupedAnalysis[key], criteria, settings) }))
}

export {
    getStationName,
    calculateAnnualAverageSumPesticides,
    getContaminationState,
    getLastYearWithAnalysis,
    getFirstYearWithAnalysis,
    isExceedNitrates,
    isExceedAverageSubstance,
    isExceedMaxSubstance,
    isExceedAverageSumSubstance,
    isExceedMaxSumSubstance,
    isExceedPesticides,
    getUsedValues,
    getUsedValue,
    colorGradients,
}