import { push } from '@lagunovsky/redux-react-router'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { H_CAMPAIGN_PIEZO_DASHBOARD } from '../../../account/constants/AccessRulesConstants'
import CampaignAction from '../../../campaign/actions/CampaignAction'
import DashboardCampaign from '../../../campaign/components/campaignDashboard/DashboardCampaign'
import ProgressCard from '../../../components/card/ProgressCard'
import { componentHasHabilitations } from '../../../utils/HabilitationUtil'
import DtoPiezometerLight from '../../dto/DtoPiezometerLight'
import PiezometerAccessibilitieDto from '../../dto/PiezometerAccessibilitieDto'

class PiezometryCampaignDashboard extends Component {
    state = {
        dataLoaded: false,
        progress: 0,
    }

    componentDidMount = () => {
        if (!componentHasHabilitations(H_CAMPAIGN_PIEZO_DASHBOARD)) { // A modifier quand react-router sera à jour
            this.props.push('/unauthorized')
            return
        }
        this.props.loadPiezometryCampaignDashboard(this.props.params.id, p => this.setState({ progress: p }))
            .then(() => this.setState({ dataLoaded: true }))
    }

    componentDidUpdate = prevProps => {
        const { id } = this.props.params
        if (id !== prevProps.params.id && id !== 'new' && id !== 'duplicate') {
            this.props.fetchCampaign('piezometry', id)
            this.props.fetchCampaignStation('piezometry', id)
            this.props.fetchCampaignProgress('piezometry', id)
            this.props.fetchPiezometryCampaignEvent(id)
        }
    }

    render = () => {
        const {
            dataLoaded,
            progress,
        } = this.state
        if (dataLoaded) {
            const {
                piezometersAccessibilities,
                piezometers,
            } = this.props
            return (
                <DashboardCampaign
                    params={this.props.params}
                    accessibilities={piezometersAccessibilities}
                    stations={piezometers}
                    stationType={'piezometry'}
                />
            )
        }
        return <ProgressCard progress={ progress }/>
    }
}

PiezometryCampaignDashboard.propTypes = {
    params: PropTypes.shape({
        id: PropTypes.string,
    }),
    piezometers: PropTypes.arrayOf(PropTypes.instanceOf(DtoPiezometerLight)),
    piezometersAccessibilities: PropTypes.arrayOf(PropTypes.instanceOf(PiezometerAccessibilitieDto)),

    loadPiezometryCampaignDashboard: PropTypes.func,
    fetchCampaign: PropTypes.func,
    fetchCampaignStation: PropTypes.func,
    fetchCampaignProgress: PropTypes.func,
    fetchPiezometryCampaignEvent: PropTypes.func,
    push: PropTypes.func,
}

const mapStateToProps = store => ({
    piezometers: store.PiezometryReducer.piezometersLight,
    piezometersAccessibilities: store.PiezometryReducer.piezometersAccessibilities,
})

const mapDispatchToProps = {
    loadPiezometryCampaignDashboard: CampaignAction.loadPiezometryCampaignDashboard,
    fetchCampaign: CampaignAction.fetchCampaign,
    fetchCampaignStation: CampaignAction.fetchCampaignStation,
    fetchCampaignProgress: CampaignAction.fetchCampaignProgress,
    fetchPiezometryCampaignEvent: CampaignAction.fetchPiezometryCampaignEvent,
    push,
}

export default connect(mapStateToProps, mapDispatchToProps)(PiezometryCampaignDashboard)
