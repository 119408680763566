/* eslint-disable camelcase */
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import Table from '../../../../../components/datatable/Table'
import SieauAction from '../../../../../components/sieau/SieauAction'
import { getExport, setModal } from '../../../../../utils/linkUtils'
import DtoInstallationBorehole from '../../../../dto/borehole/DtoInstallationBorehole'
import BoreholeCommentModal from './BoreholeCommentModal'

const BoreholeCommentTable = ({
    borehole = {},
    readMode = false,
    onChange = () => {},
    setPopup = () => {},
}) => {
    const boreholeComments = borehole.link_boreholeComments.map((c, index) => ({
        ...c,
        index,
    }))
    const tableData = boreholeComments.map(c => ({
        ...c,
    }))
    const headers = ['order', 'comment', 'depth']
    const exportAction = getExport(tableData, i18n.comments, headers)
    const actions = !readMode ? [{
        iconName: 'note_add',
        tooltip: i18n.add,
        onClick: () => setModal(i18n.new, <BoreholeCommentModal id={ borehole.id } saveResult={ r => onChange({ link_boreholeComments: [...boreholeComments, r] }) }/>, setPopup),
    }, exportAction] : [exportAction]

    return (
        <Table
            title={ i18n.comments }
            condensed
            data={ tableData }
            type={{ headers }}
            sortable={ !!tableData.length }
            actions={ actions }
            alterable={ !readMode }
            onAlter={
                (element) => setModal(i18n.comments,
                    <BoreholeCommentModal
                        id={ borehole.id }
                        boreholeComment={ boreholeComments.find(c => element.index === c.index) }
                        saveResult={ r => onChange({
                            link_boreholeComments: [
                                ...boreholeComments.filter(lc => lc.index !== element.index),
                                { ...r, index: element.index },
                            ],
                        })}
                    />
                    , setPopup)
            }
            deletable={ !readMode }
            onDelete={ (element) => onChange({ link_boreholeComments: boreholeComments.filter(lc => lc.index !== element.index) }) }
        />
    )
}

BoreholeCommentTable.propTypes = {
    borehole: PropTypes.instanceOf(DtoInstallationBorehole),
    readMode: PropTypes.bool,
    onChange: PropTypes.func,
    setPopup: PropTypes.func,
}

const mapStateToProps = () => ({})

const mapDispatchToProps = {
    setPopup: SieauAction.setPopup,
}

export default connect(mapStateToProps, mapDispatchToProps)(BoreholeCommentTable)