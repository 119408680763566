import fetch from 'isomorphic-fetch'
import i18n from 'simple-react-i18n'
import ToastrAction from 'toastr/actions/ToastrAction'
import ApplicationConf from '../../conf/ApplicationConf'
import LogAction from '../../log/actions/LogAction'
import { checkAuth, genericFetch, genericPromise, getAuthorization, getJson } from '../../utils/ActionUtils'
import { RECEIVE_LAST_MESSAGES, RECEIVE_MESSAGES_USER } from '../constants/MessagesConstants'

const MessageAction = {
    receiveMessagesOf(listOfMessagesUser) {
        return { type: RECEIVE_MESSAGES_USER, listOfMessagesUser }
    },

    promiseMessages: login => fetch(ApplicationConf.message.getUserMessages(login), {
        method: 'GET',
        headers: getAuthorization(),
    })
        .then(checkAuth)
        .then(getJson),

    fetchMessages: login => dispatch => MessageAction.promiseMessages(login)
        .then((json) => {
            dispatch(MessageAction.receiveMessagesOf(json))
        })
        .catch((err) => {
            dispatch(LogAction.logError(`${i18n.fetchError} : ${err}`))
            dispatch(ToastrAction.error(i18n.fetchError))
        }),

    promiseLastMessages: () => genericPromise(ApplicationConf.message.lastMessages()),

    fetchLastMessages: () => genericFetch(MessageAction.promiseLastMessages(), RECEIVE_LAST_MESSAGES),

    sendMessage(userMessage) {
        return dispatch => {
            return fetch(ApplicationConf.message.sendMessage(), {
                method: 'POST',
                headers: getAuthorization(),
                body: JSON.stringify(userMessage),
            })
                .then(checkAuth)
                .then((json) => {
                    if (json.id) {
                        return json.id
                    }
                    throw new Error()
                })
                .catch((err) => {
                    dispatch(LogAction.logError(`${i18n.createError} : ${err}`))
                })
        }
    },
}

export default MessageAction
