import React from 'react'
import i18n from 'simple-react-i18n'
import PropTypes from 'prop-types'
import Select from 'components/forms/Select'
import { hasValue } from 'utils/NumberUtil'
import Input from 'components/forms/Input'
import { StyledFieldSet, StyledLegend, StyledSpan } from 'components/StyledElements'
import { Grid } from '@mui/material'
import StationImportCodeMessage from 'import/components/utils/StationImportCodeMessage'
import RadioButtons from 'components/forms/RadioButtons'
import SimpleDatePicker from 'components/forms/SimpleDatePicker'
import { shallowEqual, useSelector } from 'react-redux'
import MultiContributorsAutocomplete from 'referencial/components/contributor/components/MultiContributorsAutocomplete'
import Checkbox from 'components/forms/Checkbox'
import { getIntegrationModes } from 'utils/JobUtils'
import { integrationModeHelpIcon } from 'import/utils/ImportUtils'

const AddQualitoStepOptionsOrobnat = ({
    changeFilters,
    filters = {},
    jobDataTypes,
    setJobDataTypes,
}) => {
    const {
        cities,
        contributors,
        fractions,
    } = useSelector(store => ({
        cities: store.CityReducer.cities,
        contributors: store.ContributorReducer.contributors,
        fractions: store.FractionReducer.fractions,
    }), shallowEqual)

    const integrationModes = getIntegrationModes().map(im => im.value)

    const codesTypes = [
        { code: 'stationCodes', name: i18n.stations },
        { code: 'townCodes', name: i18n.cities },
        { code: 'departmentCodes', name: i18n.departments },
    ]

    const codeToAdd = filters?.codes?.[0]

    const changeStationTypes = (value, type) => {
        changeFilters({ stationTypes: value ? [...filters.stationTypes, type] : filters.stationTypes.filter(c => c !== type) })
    }

    const onChangeDataIntegration = (dataType) => {
        const filteredDataTypes = jobDataTypes.filter(jdt => !integrationModes.includes(jdt))
        setJobDataTypes(dataType && [...filteredDataTypes, dataType] || filteredDataTypes)
    }

    return (
        <>
            <StyledFieldSet>
                <StyledLegend>
                    <StyledSpan>{i18n.options}</StyledSpan>
                </StyledLegend>
                <Grid>
                    <Grid container justifyContent='space-between' spacing={2} sx={{ paddingTop: '10px', paddingBottom: '10px' }}>
                        <Grid item xs={12}>
                            <Grid item xs={6}>
                                <RadioButtons
                                    elements={codesTypes}
                                    selected={filters.codesType}
                                    onChange={v => changeFilters({ codes: [], codesType: v })}
                                />
                            </Grid>
                        </Grid>
                        {
                            filters.codesType === 'stationCodes' && (
                                <>
                                    <Grid item xs={3}>
                                        <Input
                                            value={filters.codes}
                                            onChange={v => changeFilters({ codes: [v] })}
                                            title={i18n.code}
                                            noSpace
                                        />
                                    </Grid>
                                    <Grid container item xs={9}>
                                        {(hasValue(codeToAdd) && <StationImportCodeMessage
                                            code={codeToAdd}
                                            stationType='quality'
                                        />)}
                                    </Grid>
                                </>
                            )
                        }
                        {
                            filters.codesType === 'townCodes' && (
                                <Grid item xs={3}>
                                    <Select
                                        label={i18n.city}
                                        options={cities}
                                        value={codeToAdd}
                                        onChange={v => changeFilters({ codes: [v] })}
                                        keyValue='id'
                                    />
                                </Grid>
                            )
                        }
                        {
                            filters.codesType === 'departmentCodes' && (
                                <Grid item xs={3}>
                                    <Input
                                        title={i18n.code}
                                        value={codeToAdd}
                                        onChange={v => changeFilters({ codes: [v] })}
                                        maxLength={3}
                                    />
                                </Grid>
                            )
                        }

                    </Grid>
                </Grid>
            </StyledFieldSet>
            <StyledFieldSet>
                <StyledLegend>
                    <StyledSpan>{i18n.parameters}</StyledSpan>
                </StyledLegend>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <Select
                            value={jobDataTypes.find(d => integrationModes.includes(d))}
                            options={getIntegrationModes()}
                            label={i18n.dataIntegration}
                            labelSpan={integrationModeHelpIcon()}
                            onChange={onChangeDataIntegration}
                            noNullValue
                            obligatory
                        />
                    </Grid>
                    <Grid container item xs={6} justifyContent='space-between'>
                        <Checkbox
                            checked={filters.stationTypes.includes('CAP')}
                            label={i18n.capture}
                            onChange={v => changeStationTypes(v, 'CAP')}
                        />
                        <Checkbox
                            checked={filters.stationTypes.includes('TTP')}
                            label={i18n.treatmentProduction}
                            onChange={v => changeStationTypes(v, 'TTP')}
                        />
                        <Checkbox
                            checked={filters.stationTypes.includes('UDI')}
                            label={i18n.distributionUnit}
                            onChange={v => changeStationTypes(v, 'UDI')}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <MultiContributorsAutocomplete
                            col={4}
                            multiple
                            options={contributors}
                            values={filters.excludedProducers}
                            label={i18n.producersExcluded}
                            onChange={values => changeFilters({ excludedProducers: values })}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <SimpleDatePicker
                            onChange={(v) => changeFilters({ beginDate: v === '' ? undefined : v })}
                            id='beginDate'
                            label={i18n.startDate}
                            obligatory
                            value={filters.beginDate}
                            col={12}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <SimpleDatePicker
                            onChange={(v) => changeFilters({ endDate: v === '' ? undefined : v })}
                            id='endDate'
                            col={12}
                            obligatory
                            label={i18n.endDate}
                            value={filters.endDate}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <MultiContributorsAutocomplete
                            options={contributors}
                            label={i18n.producer}
                            onChange={(id) => changeFilters({ producer: id === '' ? undefined : id })}
                            values={filters.producer}
                            keyLabel='labelDisplay'
                            displayWithCode
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <MultiContributorsAutocomplete
                            options={contributors}
                            label={i18n.laboratory}
                            onChange={(id) => changeFilters({ labo: id === '' ? undefined : id })}
                            values={filters.labo}
                            keyLabel='labelDisplay'
                            displayWithCode
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <Select
                            options={fractions}
                            label={i18n.fraction}
                            onChange={(id) => changeFilters({ fraction: id === '' ? undefined : id })}
                            value={filters.fraction}
                            nullLabel='&nbsp;'
                            keyLabel='labelDisplay'
                            displayWithCode
                        />
                    </Grid>
                </Grid>
            </StyledFieldSet>
        </>

    )
}

AddQualitoStepOptionsOrobnat.propTypes = {
    changeFilters: PropTypes.func,
    filters: PropTypes.shape({}),
    jobDataTypes: PropTypes.arrayOf(PropTypes.string),
    setJobDataTypes: PropTypes.func,
}

export default AddQualitoStepOptionsOrobnat