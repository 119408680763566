import DtoFilterParam from './DtoFilterParam'

export default class DtoFilter {
    constructor(obj) {
        this.id = parseInt(obj.codeList)
        this.code = parseInt(obj.codeList)
        this.name = obj.nameList
        this.module = obj.module
        this.codeList = obj.codeList
        this.nameList = obj.nameList
        this.filterSelection = obj.filterSelection
        this.order = obj.order
        this.default = obj.default
        this.loginMaj = obj.loginMaj

        this.link_parameters = obj.link_parameters ? obj.link_parameters.map(p => new DtoFilterParam(p)) : []
    }
}