import React from 'react'

export default class DtoCentralChannelItem {
    constructor(obj) {
        this.nullValue = obj.nullValue
        this.centralId = obj.centralId
        this.channel = obj.channelId
        this.pathId = obj.pathId
        this.pathNumber = obj.channelId
        this.assignment = obj.assignment
        this.comment = obj.comment
        this.dataType = obj.dataType
        this.acquisition = obj.acquisition
        this.remoteTransmission = (
            <i className='material-icons'>{ obj.remoteTransmission }</i>
        )
        this.headers = ['nullValue', 'pathNumber', 'dataType', 'assignment', 'comment', 'remoteTransmission', 'acquisition']
    }
}
