import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import DtoEvent from '../../../../events/dto/DtoEvent'
import { getDate, getHour, getYear } from '../../../../utils/DateUtil'
import TechniqueEventCard from '../../../../station/components/event/TechniqueEventCard'
import SmallEventCard from '../../../../station/components/event/SmallEventCard'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import RadioButtons from '../../../../components/forms/RadioButtons'
import { EVENT_TYPES, EVENT_TYPES_CODES } from '../../../../events/constants/EventsConstants'
import Row from '../../../../components/react/Row'
import Input from '../../../../components/forms/Input'
import { onChangeDate, onChangeHour } from '../../../../utils/FormUtils'
import Textarea from '../../../../components/forms/Textarea'
import Checkbox from '../../../../components/forms/Checkbox'
import EventsAction from '../../../../events/actions/EventsAction'
import GlobalCardList from '../../../../components/list/cardList/GlobalCardList'
import { groupBy, omit, orderBy } from 'lodash'
import { getEventColor } from '../../../../utils/ColorUtil'
import Icon from '../../../../components/icon/Icon'
import Select from '../../../../components/forms/Select'
import { i18nize } from '../../../../utils/StringUtil'
import moment from 'moment'
import { getUser } from '../../../../utils/SettingUtils'
import AppStore from '../../../../store/AppStore'
import PiezometryAction from '../../../actions/PiezometryAction'
import { instanceOf } from '../../../../utils/StoreUtils'
import DtoPiezometer from '../../../dto/DtoPiezometer'
import { WhiteCard } from '../../../../components/styled/Card'
import { darkBlue } from '../../../../utils/constants/ColorTheme'

class EventsToolPanel extends Component {
    constructor() {
        super()
        this.state = {
            open: false,
            selected: EVENT_TYPES_CODES.COMMENT,
            showOnGraphic: false,
            eventType: '',
            startDate: moment().valueOf(),
            startHour: moment().valueOf(),
        }
    }

    componentDidMount = () => {
        this.props.fetchStationEvents('piezometry', this.props.id)
    }

    getActions = () => {
        return (
            <div>
                <a className='waves-effect waves-teal btn-flat' onClick={() => this.setState({ open: false })}>{i18n.close}</a>
                <a className='waves-effect waves-teal btn-flat' onClick={this.createEvent}>{i18n.register}</a>
            </div>
        )
    }

    createEvent = () => {
        const { selected, startDate, startHour, comment, showOnGraphic, exportBank } = this.state
        const newEvent = {
            eventType: selected,
            date: startDate,
            eventHour: startHour,
            comment,
            bankExport: selected === EVENT_TYPES_CODES.COMMENT && exportBank ? '1' : '0',
            graph: showOnGraphic ? '1' : '0',
            contactCode: getUser().contactCode,
        }

        this.props.addEvent('piezometry', this.props.id, newEvent)
            .then(() => {
                this.setState({ open: false })
                AppStore.dispatch(PiezometryAction.refreshOnePiezometerSituation(this.props.piezometer.id, -1))
            })
    }

    getNewEventContent = () => {
        const elements = [
            {
                code: EVENT_TYPES_CODES.COMMENT,
                label: i18n.comment,
            },
            {
                code: EVENT_TYPES_CODES.TO_PLAN,
                label: i18n.toPlan,
            },
            {
                code: EVENT_TYPES_CODES.TO_MONITOR,
                label: i18n.toMonitor,
            },
        ]
        const { startDate, startHour, comment, showOnGraphic, exportBank } = this.state
        return (
            <div>
                <Row className='padding-bottom-1'>
                    <RadioButtons selected={this.state.selected} elements={elements} onChange={v => this.setState({ selected: v })} />
                </Row>
                <Row className='padding-bottom-1'>
                    <Input
                        col={6}
                        title={i18n.startDate}
                        value={getDate(startDate)}
                        onChange={v => onChangeDate(v, v2 => this.setState({ startDate: v2 }))}
                    />
                    <Input
                        col={6}
                        title={i18n.startHour}
                        value={getHour(startHour)}
                        onChange={v => onChangeHour(v, v2 => this.setState({ startHour: v2 }))}
                    />
                </Row>
                <Row className='padding-bottom-1'>
                    <Textarea
                        col={12}
                        title={i18n.comment}
                        value={comment}
                        onChange={v => this.setState({ comment: v })}
                    />
                </Row>
                <Row>
                    <Checkbox
                        col={6}
                        label={i18n.displayGraph}
                        display
                        checked={showOnGraphic}
                        onChange={v => this.setState({ showOnGraphic: v })}
                    />
                    {this.state.selected === EVENT_TYPES_CODES.COMMENT && (
                        <Checkbox
                            col={6}
                            label={i18n.bankExport}
                            display
                            checked={exportBank}
                            onChange={v => this.setState({ exportBank: v })}
                        />
                    )}
                </Row>
            </div>
        )
    }

    getTitle = stationEvents => {
        return (
            <div className='valign-wrapper no-margin no-padding'>
                <div className='col s10'>
                    {stationEvents.length ? `${i18n.events} (${stationEvents.length} ${i18n.elements})` : i18n.noEvents}
                </div>
                <div className='col s2'>
                    <Icon
                        size='small'
                        icon='note_add'
                        style={{ color: darkBlue }}
                        label={i18n.add}
                        onClick={() => this.setState({ open: true })}
                    />
                </div>
            </div>
        )
    }

    getYearTitle = (key, events) => (
        <div className='valign-wrapper no-margin no-padding'>
            {`${key} (${events[key].length} ${i18n.elements})`}
        </div>
    )

    getEventCard = event => {
        return event.eventType === 'T' ?
            <TechniqueEventCard
                event={event}
                shortDate
                link={`/station/piezometry/${this.props.id}/event/`}
            />
            : <SmallEventCard
                event={event}
                shortDate
                link={`/station/piezometry/${this.props.id}/event/`}
            />
    }

    render() {
        const { stationEvents } = this.props
        const { eventType, open } = this.state
        const events = eventType ? stationEvents.filter(d => d.eventType === eventType) : stationEvents
        const groupedEvents = groupBy(events, d => getYear(d.date))
        const data = Object.keys(groupedEvents).map(key => ({
            title: this.getYearTitle(key, groupedEvents),
            cards: orderBy(groupedEvents[key], e => e.date || e.eventDate, 'desc').map(e => ({
                color: getEventColor(e.eventType),
                content: this.getEventCard(e),
            })),
        }))
        return (
            <div>
                <div className='row margin-bottom-1'>
                    <Select className='no-margin no-padding' col={12} nullLabel='&nbsp;' value={eventType} options={i18nize(EVENT_TYPES).map(d => omit(d, 'icon')).concat([{ code: 'T', label: 'Technique' }])} label={i18n.eventTypes} onChange={v => this.setState({ eventType: v })} />
                </div>
                <WhiteCard
                    className='transparent no-box-shadow'
                    maxHeight={600}
                    title={this.getTitle(events)}
                    round
                >
                    <GlobalCardList
                        smallTitle
                        data={data}
                        collapsible
                    />
                </WhiteCard>

                {/* <Dialog
                    title={ i18n.createEvent }
                    actions={ this.getActions() }
                    autoDetectWindowHeight
                    onRequestClose={() => this.setState({ open: !open })}
                    open={ open }
                    > */}
                <Dialog
                    onClose={() => this.setState({ open: false })}
                    fullWidth
                    maxWidth='lg'
                    open={open}
                >
                    <DialogTitle>{i18n.createEvent}</DialogTitle>
                    <DialogContent>
                        {this.getNewEventContent()}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.setState({ open: false })} variant='outlined' >
                            {i18n.close}
                        </Button>
                        <Button onClick={this.createEvent} variant='contained' color='primary'>
                            {i18n.register}
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>

        )
    }
}

EventsToolPanel.propTypes = {
    stationEvents: PropTypes.arrayOf(DtoEvent),
    minDate: PropTypes.number,
    maxDate: PropTypes.number,
    id: PropTypes.number,
    addEvent: PropTypes.func,
    fetchStationEvents: PropTypes.func,
    piezometer: instanceOf(DtoPiezometer),
}

const mapStateToProps = store => ({
    stationEvents: store.EventsReducer.stationEvents,
    piezometer: store.StationReducer.piezometer,
})

const mapDispatchToProps = {
    addEvent: EventsAction.addEvent,
    fetchStationEvents: EventsAction.fetchStationEvents,
}


export default connect(mapStateToProps, mapDispatchToProps)(EventsToolPanel)