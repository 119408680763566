import PropTypes from 'prop-types'
import React from 'react'
import ColorfulCard from '../../card/ColorfulCard'
import SubList from '../simpleList/SubList'

class SubCardList extends SubList {
    render() {
        const colorfulCards = this.props.cards.map((card, i) => {
            return (
                <ColorfulCard key={i} color={card.color}>
                    {card.content}
                </ColorfulCard>)
        })
        return this.getSuperComponent(colorfulCards)
    }
}

SubCardList.propTypes = {
    title: PropTypes.string.isRequired,
    cards: PropTypes.arrayOf(PropTypes.shape({
        color: PropTypes.string,
        onClick: PropTypes.func,
        content: PropTypes.element.isRequired,
    })).isRequired,
    smallTitle: PropTypes.bool,
}

export default SubCardList
