import React, { Component } from 'react'
import AppStore from '../../../../store/AppStore'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import AlertAction from '../../../../alerting/actions/AlertAction'
import InstallationAction from '../../../../installation/actions/InstallationAction'
import { arrayOf, getLabel, getObjectLabel, getSandreList, objectOf } from '../../../../utils/StoreUtils'
import DtoInstallationActivity from '../../../../installation/dto/industrialSite/DtoInstallationActivity'
import PollutionSourceActivityDto from '../../../../alerting/dto/pollution/PollutionSourceActivityDto'
import i18n from 'simple-react-i18n'
import Card from '../../../../components/card/Card'
import { flatten, groupBy, intersection, intersectionBy, maxBy, orderBy } from 'lodash'
import { hasValue } from '../../../../utils/NumberUtil'
import { POLLUTION_LEVEL_COLOR } from '../../../../alerting/constants/PollutionConstants'
import PanelList from '../../../../components/list/panelList/PanelList'
import Row from '../../../../components/react/Row'
import DtoPollutedSoil from '../../../../installation/components/pollutedSoil/dto/DtoPollutedSoil'
import DtoIndustrialSite from '../../../../installation/components/industrialSite/dto/DtoIndustrialSite'
import DtoInstallation from '../../../../installation/dto/installation/DtoInstallation'
import CartographyPanel from '../../../../components/map/CartographyPanel'
import CityAction from '../../city/actions/CityAction'
import Table from '../../../../components/datatable/Table'
import CityDto from '../../city/dto/CityDto'
import { push } from '@lagunovsky/redux-react-router'
import Checkbox from '../../../../components/forms/Checkbox'
import { getExport } from 'utils/linkUtils'
import ParameterDto from '../dto/ParameterDto'
import DtoSandreCode from 'referencial/dto/DtoSandreCode'
import { SANDRE } from 'referencial/constants/ReferencialConstants'
import SimpleDatePicker from 'components/forms/SimpleDatePicker'
import ParameterAction from '../actions/ParameterAction'
import { setActions } from 'components/ActionUtil'

class ParameterSourcePanel extends Component {
    constructor(props) {
        super(props)
        this.state = {
            industrialSiteMap: false,
            pollutedSoilMap: false,
            activeEstablishments: true,
            startDate: '',
            endDate: '',
        }
    }

    componentDidMount() {
        const { parameter, params, activities, industrialSites, pollutedSoils, installationsIndex } = this.props

        if (parameter.code) {
            this.props.fetchParameter(params.code).then(() => this.props.fetchPollutionActivities([this.props.parameter.code]))
        } else {
            this.props.fetchPollutionActivities([parameter.code])
        }

        if (!activities.length) {
            AppStore.dispatch(InstallationAction.fetchActivities())
        }

        if (!industrialSites.length) {
            AppStore.dispatch(InstallationAction.fetchIndustrialSites())
        }

        if (!pollutedSoils.length) {
            AppStore.dispatch(InstallationAction.fetchPollutedSoils())
        }

        if (!Object.keys(installationsIndex).length) {
            AppStore.dispatch(InstallationAction.fetchInstallationsLight())
        }

        setActions({})

        AppStore.dispatch(CityAction.fetchCities())
    }

    componentWillUnmount() {
        AppStore.dispatch(AlertAction.resetPollutionSourceActivities())
    }

    pushToInstallation = (id) => {
        AppStore.dispatch(push(`/station/installation/${id}`))
    }

    getIndustrialSitePanel = (rates, groups) => {
        const filteredIndustrialSites = this.state.activeEstablishments ? this.props.industrialSites.filter(i => !i.activityStatus || i.activityStatus != 2) : this.props.industrialSites
        if (this.state.industrialSiteMap) {
            const allActivities = this.props.pollutionSourceActivities.filter(p => hasValue(p.rate) && hasValue(p.activityCode))
            const coloredSites = filteredIndustrialSites.reduce((acc, i) => {
                const siteActivies = intersectionBy(allActivities, [i.activityCode, i.activityCode2, i.activityCode3, i.activityCode4].map(x => ({ activityCode: x })), 'activityCode')
                if (siteActivies.length) {
                    const worstActivity = maxBy(siteActivies, 'rate')
                    return [
                        ...acc, {
                            ...(this.props.installationsIndex[i.id] || {}),
                            markerIcon: `pictures/markers/map_installation_${((POLLUTION_LEVEL_COLOR.find(p => p.rate == worstActivity.rate) || {}).color || 'BLUE').toLowerCase()}.png`,
                        }]
                }
                return acc
            }, [])
            return (
                <CartographyPanel
                    layers={ ['STATIONS_POINTS'] }
                    componentType='installation'
                    stationsPoints={ coloredSites }
                    height={ 800 }
                />
            )
        }
        return rates.map(rate => {
            const { startDate, endDate } = this.state
            const activities = groups[rate].map(obj => obj.activityCode)
            const industrialSites = filteredIndustrialSites.filter(i => intersection(activities, [i.activityCode, i.activityCode2, i.activityCode3, i.activityCode4]).length)
                .filter(e => (!startDate || (!e.agreementStartDate || e.agreementStartDate <= startDate)) && (!endDate || (!e.agreementEndDate || e.agreementEndDate >= endDate)))
            const tableData = industrialSites.map(r => this.props.installationsIndex[r.id])
                .filter(i => !!i).map(i => ({
                    id: i.id,
                    name: `[${i.code}] ${i.name || ''}`,
                    city: getObjectLabel(this.props.citiesIndex[i.townCode], 'labelWithCode'),
                    icpe: <Checkbox label={i18n.icpe} checked={i.icpe} disabled />,
                    dischargeAgreement: <Checkbox label={i18n.dischargeAgreement} checked={i.dischargeAgreement} disabled />,
                }))
            return (
                <div>
                    <PanelList title={`${i18n.level} ${rate} (${industrialSites.length} ${i18n.establishments})`} titleColor={(POLLUTION_LEVEL_COLOR.find(p => p.rate == rate) || {}).html}>
                        { industrialSites.length ? <Table condensed data={ tableData } type={ { headers: ['name', 'city', 'icpe', 'dischargeAgreement'] } } showTitle={ false } onClick={ v => this.pushToInstallation(v.id) }/> : i18n.noEstablishment }
                    </PanelList>
                </div>
            )
        })
    }

    getPollutedSoilsPanel = (rates, groups) => {
        if (this.state.pollutedSoilMap) {
            const allActivities = this.props.pollutionSourceActivities.filter(p => hasValue(p.rate) && hasValue(p.activityCode))
            const coloredSites = this.props.pollutedSoils.reduce((acc, i) => {
                const siteActivies = intersectionBy(allActivities, [{ activityCode: i.activityCode }], 'activityCode')
                if (siteActivies.length) {
                    const worstActivity = maxBy(siteActivies, 'rate')
                    return [
                        ...acc, {
                            ...(this.props.installationsIndex[i.id] || {}),
                            markerIcon: `pictures/markers/map_installation_${((POLLUTION_LEVEL_COLOR.find(p => p.rate == worstActivity.rate) || {}).color || 'BLUE').toLowerCase()}.png`,
                        }]
                }
                return acc
            }, [])
            return (
                <CartographyPanel
                    layers={ ['STATIONS_POINTS'] }
                    componentType='referentials'
                    stationsPoints={ coloredSites }
                    height={ 800 }
                />
            )
        }
        return rates.map(rate => {
            const activities = groups[rate].map(obj => obj.activityCode)
            const pollutedSoils = this.props.pollutedSoils.filter(i => intersection(activities, [i.activityCode]).length)
            const tableData = pollutedSoils.map(r => this.props.installationsIndex[r.id])
                .filter(i => !!i).map(i => ({ name: `[${i.code}] ${i.name || ''}`, city: getObjectLabel(this.props.citiesIndex[i.townCode], 'labelWithCode') }))
            return (
                <PanelList title={`${i18n.level} ${rate} (${pollutedSoils.length} ${i18n.pollutedSoils})`} titleColor={(POLLUTION_LEVEL_COLOR.find(p => p.rate == rate) || {}).html}>
                    { pollutedSoils.length ? <Table condensed data={ tableData } type={ { headers: ['name', 'city'] } } showTitle={ false } onClick={ v => this.pushToInstallation(v.id) }/> : i18n.noPollutedSoil }
                </PanelList>
            )
        })
    }

    formatActivityCode = (r) => `[${r.activityCode}] ${getLabel(this.props.activities, r.activityCode)}`

    onResetFilters = () => this.setState({ startDate: '', endDate: '', activeEstablishments: false })

    render() {
        const { startDate, endDate } = this.state
        const { parameter, sandreCodes } = this.props
        const filteredPollutionSourceActivities = orderBy(this.props.pollutionSourceActivities.filter(p => hasValue(p.rate) && hasValue(p.activityCode)), ['desc'])
        const groups = groupBy(filteredPollutionSourceActivities, 'rate')
        const rates = orderBy(Object.keys(groups), v => parseInt(v), ['desc'])
        const activitiesLevels = rates.map(rate => {
            return (
                <PanelList title={`${i18n.level} ${rate}`} titleColor={(POLLUTION_LEVEL_COLOR.find(p => p.rate == rate) || {}).html}>
                    {
                        groups[rate].map(r => <h6 className='padding-left-1'>{ this.formatActivityCode(r) }</h6>)
                    }
                </PanelList>
            )
        })

        // export activities
        const exportData = filteredPollutionSourceActivities.map(fpsa => ({
            level: fpsa.rate,
            activityCode: fpsa.activityCode,
            activityLibelle: getLabel(this.props.activities, fpsa.activityCode).replace(';', ','),
        }))
        const activityAction = [getExport(exportData, i18n.activities, ['level', 'activityCode', 'activityLibelle'])]

        // export indusSite
        const filteredIndustrialSites = this.state.activeEstablishments ? this.props.industrialSites.filter(i => !i.activityStatus || i.activityStatus != 2) : this.props.industrialSites
        const industrialSiteExport = flatten(rates.map(rate => {
            const activities = groups[rate].map(obj => obj.activityCode)
            const industrialSites = filteredIndustrialSites.filter(i => intersection(activities, [i.activityCode, i.activityCode2, i.activityCode3, i.activityCode4]).length)
                .filter(e => (!startDate || (!e.agreementStartDate || e.agreementStartDate <= startDate)) && (!endDate || (!e.agreementEndDate || e.agreementEndDate >= endDate)))
            return industrialSites.map(r => ({ ...r, ...this.props.installationsIndex[r.id] }))
                .map(i => ({
                    ...i,
                    level: rate,
                    city: this.props.citiesIndex[i.townCode]?.name,
                    parameterCode: parameter.code,
                    parameterName: parameter.name,
                    dischargeAgreement: i.dischargeAgreement ? i18n.yes : i18n.no,
                    icpe: i.icpe ? i18n.yes : i18n.no,
                    close: i.agreementEndDate ? i18n.yes : i18n.no,
                    effectiveTranche: i.effectiveTranche ? getSandreList(sandreCodes, SANDRE.SITE_INDUS_EFFECTIF)[i.effectiveTranche]?.name : getSandreList(sandreCodes, SANDRE.SITE_INDUS_EFFECTIF)[-1]?.name,
                    activity: `${getLabel(this.props.activities, i.activityCode).replace(';', ',')} [${i.activityCode}]`,
                }))
        }))
        const indusSiteAction = getExport(
            industrialSiteExport,
            i18n.industrialSites,
            ['parameterCode', 'parameterName', 'level', 'code', 'name', 'townCode', 'city', 'x', 'y', 'projection', 'activity', 'effectiveTranche', 'icpe', 'dischargeAgreement', 'close']
        )

        return (
            <div className='row no-margin'>
                <div className='col s12'>
                    <div className='card padding-1'>
                        <Card maxWidth={ 1200 } title={ i18n.activities } actions={ activityAction }>
                            <Card maxHeight={ 800 } height={ 800 }>
                                <Row className='padding-left-1 padding-right-1'>
                                    { activitiesLevels }
                                </Row>
                            </Card>
                        </Card>
                        <div>
                            <Card
                                maxWidth={ 1200 }
                                className='margin-top-1'
                                title={ i18n.establishment }
                                actions={ [{ iconName: this.state.industrialSiteMap ? 'list': 'map', onClick: () => this.setState({ industrialSiteMap: !this.state.industrialSiteMap }) }, indusSiteAction] }
                            >
                                <Card style={{ maxHeight: 800 }} noMargin={ false }>
                                    <Row className='padding-left-1 padding-right-1'>
                                        <Card
                                            className='row margin-top-1'
                                            title={ i18n.criterias }
                                            contentClassName='margin-top-1' noMargin={ false }
                                        >
                                            <SimpleDatePicker
                                                col={3}
                                                label={i18n.startDate}
                                                onChange={ v => this.setState({ startDate: v })}
                                                id='startDate'
                                                value={startDate}
                                                max={endDate}
                                            />
                                            <SimpleDatePicker
                                                col={3}
                                                label={i18n.endDate}
                                                onChange={ v => this.setState({ endDate: v })}
                                                id='endDate'
                                                value={endDate}
                                                min={startDate}
                                            />
                                            <Checkbox
                                                col={ 3 }
                                                componentClassName='padding-top-10-px'
                                                checked={ this.state.activeEstablishments }
                                                onChange={ v => this.setState({ activeEstablishments: v }) }
                                                label={ i18n.activeEstablishments }
                                            />
                                            <a className='margin-1 waves-effect waves-teal center btn-flat secondary-button right' onClick={this.onResetFilters}>
                                                {i18n.reinit}
                                            </a>
                                        </Card>
                                        { this.getIndustrialSitePanel(rates, groups) }
                                    </Row>
                                </Card>
                            </Card>
                        </div>
                        <Card
                            className='margin-top-1'
                            maxWidth={ 1200 }
                            title={ i18n.pollutedSoils }
                            actions={ [{ iconName: this.state.pollutedSoilMap ? 'list': 'map', onClick: () => this.setState({ pollutedSoilMap: !this.state.pollutedSoilMap }) }] }
                        >
                            <Card maxHeight={ 800 } height={ 800 }>
                                <Row className='padding-left-1 padding-right-1'>
                                    { this.getPollutedSoilsPanel(rates, groups) }
                                </Row>
                            </Card>
                        </Card>
                    </div>
                </div>
            </div>
        )
    }
}

ParameterSourcePanel.propTypes = {
    params: PropTypes.shape({
        code: PropTypes.string,
    }),
    parameter: PropTypes.instanceOf(ParameterDto),
    sandreCodes: PropTypes.arrayOf(PropTypes.instanceOf(DtoSandreCode)),
    activities: arrayOf(DtoInstallationActivity),
    pollutionSourceActivities: arrayOf(PollutionSourceActivityDto),
    fetchPollutionActivities: PropTypes.func,
    pollutedSoils: arrayOf(DtoPollutedSoil),
    industrialSites: arrayOf(DtoIndustrialSite),
    installationsIndex: objectOf(DtoInstallation),
    citiesIndex: objectOf(CityDto),
    fetchParameter: PropTypes.func,
}

const mapStateToProps = store => ({
    parameter: store.ParameterReducer.parameter,
    pollutionSourceActivities: store.AlertReducer.pollutionSourceActivities,
    sandreCodes: store.ReferencialReducer.sandreCodes,
    activities: store.InstallationReducer.activities,
    industrialSites: store.InstallationReducer.industrialSites,
    pollutedSoils: store.InstallationReducer.pollutedSoils,
    installationsIndex: store.InstallationReducer.installationsIndex,
    citiesIndex: store.CityReducer.citiesIndex,
})

const dispatchToProps = {
    fetchPollutionActivities: AlertAction.fetchPollutionActivities,
    fetchParameter: ParameterAction.fetchParameter,
}

export default connect(mapStateToProps, dispatchToProps)(ParameterSourcePanel)