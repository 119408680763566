export default class DtoDataType {
    constructor(obj) {
        this.id = obj.id // Double,
        this.name = obj.name // Option[String],
        this.project = obj.project // Option[String],
        this.version = obj.version // Option[Double],
        this.showData = obj.showData // Option[Boolean],
        this.warningLimit = obj.warningLimit // Option[Long],
        this.alertLimit = obj.alertLimit // Option[Long],
        this.unit = obj.unit // Option[String],
        this.order = obj.order // Option[Int],
        this.calculMin = obj.calculMin // Option[Boolean],
        this.calculMoy = obj.calculMoy // Option[Boolean],
        this.calculMax = obj.calculMax // Option[Boolean],
        this.codeParameter = obj.codeParameter // Option[String],
        this.monitoringChronicle = obj.monitoringChronicle // Option[Boolean],
        this.codeUnit = obj.codeUnit // Option[String]
        this.color = obj.color
        this.isIncrement = obj.isIncrement
        this.razThreshold = obj.razThreshold
        this.lineType = obj.lineType
        this.lineWidth = obj.lineWidth
        this.lineOpacity = obj.lineOpacity
        this.headers = ['name', 'version', 'showData', 'warningLimit', 'alertLimit', 'unit', 'order', 'calculMin', 'calculMoy', 'calculMax', 'codeParameter', 'monitoringChronicle', 'codeUnit']
        this.comment = obj.comment
        this.numberDecimal = obj.numberDecimal
    }
}