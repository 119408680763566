import { push } from '@lagunovsky/redux-react-router'
import { groupBy, keys, mapValues, omit, uniq, uniqBy } from 'lodash'
import PropTypes from 'prop-types'
import React, { useEffect, useMemo, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import { H_PIEZO_DASHBOARD } from '../../../account/constants/AccessRulesConstants'
import AdministrationAction from '../../../administration/actions/AdministrationAction'
import CampaignPortletPanel from '../../../campaign/components/CampaignPortletPanel'
import { getCampaignIcon, getCampaignProgress, getProgressBar } from '../../../campaign/utils/CampaignUtils'
import ProgressCard from '../../../components/card/ProgressCard'
import CartographyPanel from '../../../components/map/CartographyPanel'
import HomeAction from '../../../home/actions/HomeAction'
import PiezometryAction from '../../../piezometry/actions/PiezometryAction'
import { nbPerPageLabel, SANDRE } from '../../../referencial/constants/ReferencialConstants'
import StationAction from '../../../station/actions/StationAction'
import StationStatisticPanel from '../../../station/components/dashboard/component/keyfigure/StationStatisticPanel'
import { STATION_TYPE_NAME } from '../../../station/constants/StationConstants'
import { getColorCampaign } from '../../../utils/CampaignUtil'
import { getDate } from '../../../utils/DateUtil'
import { componentHasHabilitations } from '../../../utils/HabilitationUtil'
import { hasValue } from '../../../utils/NumberUtil'
import { getBookmarks, hasLocalisationStation } from '../../../utils/StationUtils'
import {
    getLabel,
} from '../../../utils/StoreUtils'
import { getI18nTitleData, searchAllCharacters } from '../../../utils/StringUtil'
import { getUserBookmarksByStationType } from '../../../utils/UserUtil'
import DtoPiezometerListSpecific from '../../dto/DtoPiezometerListSpecific'
import AddPiezoStepper from './addPiezo/AddPiezoStepper'
import { WhiteCard } from 'components/styled/Card'
import { AccordionDetailsMUI, AccordionMUI, AccordionSummaryMUI } from 'components/styled/Accordions'
import { Grid2 } from '@mui/material'
import useProgressDispatch from 'utils/customHook/useProgressDispatch'
import useTitle from 'utils/customHook/useTitle'
import useBoolean from 'utils/customHook/useBoolean'
import Icon from 'components/icon/Icon'
import SimpleTabList from 'components/list/SimpleTabList'
import useSandreList from 'utils/customHook/useSandreList'
import ReferencialAction from 'referencial/action/ReferencialAction'
import UserAction from 'administration/components/user/actions/UserAction'
import CampaignAction from 'campaign/actions/CampaignAction'
import NetworkAction from 'referencial/components/network/actions/NetworkAction'
import CityAction from 'referencial/components/city/actions/CityAction'
import useActions from 'utils/customHook/useActions'
import StationFilterFields from 'station/components/search/StationFilterFields'
import { CardTable, sortRows } from 'components/datatable/NewTable'

const CARTO_STATION = 'cartoStation'
const LIST_STATION = 'listStation'
const REFERENT = 'referent'

const CampaignPanel = () => {
    const {
        piezometryCampaigns,
        piezometryCampaignsProgress,
        contacts,
    } = useSelector(store => ({
        piezometryCampaigns: store.PiezometryReducer.piezometryCampaigns,
        piezometryCampaignsProgress: store.PiezometryReducer.piezometryCampaignsProgress,
        contacts: store.ContactReducer.contacts,
    }), shallowEqual)

    const campaignsFiltered = piezometryCampaigns.filter(c => {
        const {
            nbStation = 0,
            nbStationValidated = 0,
        } = piezometryCampaignsProgress.find(cp => cp.id === c.id) || {}
        return nbStation !== 0 && nbStationValidated < nbStation && c.statut === 2
    }, [])

    const campaignsFormated = campaignsFiltered.map(campaign => {
        const {
            nbStation = 0,
            nbStationValidated = 0,
        } = piezometryCampaignsProgress.find(progress => progress.id === campaign.id) || {}
        const progress = getCampaignProgress(nbStationValidated, nbStation)
        return {
            id: campaign.id,
            qualificationTableColor: {
                color: getColorCampaign(false, progress, 2),
            },
            nullValue: getCampaignIcon(campaign.campaignType, 'small'),
            name: campaign.name,
            nbStations: nbStation,
            startDate: campaign.beginningApplication,
            progression: getProgressBar(nbStationValidated, nbStation, progress),
            referent: getLabel(contacts, campaign.contactCode, 'mnemonique'),
        }
    })
    return (
        <CampaignPortletPanel
            campaigns={campaignsFormated}
            stationType='piezometry'
            tableHeader={{ headers: ['qualificationTableColor', 'nullValue', 'name', 'nbStations', 'startDate', 'progression', 'referent'] }}
            noHeader
            customHeaders={{
                name: i18n.campaign,
            }}
            round
        />
    )
}

const LegendPanel = () => (
    <Grid2 container spacing={1} style={{ padding: '4px' }} alignItems='center'>
        <Grid2 size='auto'>
            <Icon icon='wifi' />
        </Grid2>
        <Grid2 size='auto'>
            {i18n.automaticUpdate}
        </Grid2>
    </Grid2>
)

const TablePanel = ({
    piezometers = [],

    initialSort,
    onTableSort,
    onSaveSearchValue = () => {},
}) => {
    const dispatch = useDispatch()

    const {
        filterResults,
    } = useSelector(store => ({
        filterResults: store.StationReducer.filterResults,
    }), shallowEqual)

    const filterHeaders = useMemo(() => filterResults?.length ? keys(filterResults[0]) : [], [filterResults])

    useEffect(() => {
        onSaveSearchValue(piezometers)
    }, [piezometers])

    return (
        <CardTable
            title={i18n.watchpoints}
            actions={[{
                icon: 'info_outlined',
                tooltip: <LegendPanel />,
            }]}

            rows={piezometers}
            headers={['nullValue', 'nullValue2', 'labelCode', 'internalReferences', 'department', 'cityCode', 'cityLabel', 'name', 'creationDate', 'closeDate', 'piezometerNature', 'administrator', 'nullValue3', ...filterHeaders]}

            rowsPerPageOptions={nbPerPageLabel}
            onClickRow={({ id }) => dispatch(push(`/station/piezometry/${id}`))}
            defaultSort={initialSort}
            onSort={(sort) => onTableSort?.(piezometers, sort)}
        />
    )
}

TablePanel.propTypes = {
    piezometers: PropTypes.arrayOf(PropTypes.instanceOf(DtoPiezometerListSpecific)),
    filter: PropTypes.object,
    initialSort: PropTypes.shape({
        column: PropTypes.string,
        sort: PropTypes.string,
    }),
    onTableSort: PropTypes.func,
    onSaveSearchValue: PropTypes.func,
}

const CartoPanel = ({
    piezometersFiltered = [],
}) => {
    const hasLocationPiezometers = useMemo(() => {
        return piezometersFiltered.filter(s => s && (hasLocalisationStation(s) || s.townCode))
    }, [piezometersFiltered])

    return (
        <WhiteCard
            title={(
                <>
                    <span>{i18n.watchpoints}</span>
                    <span style={{ fontWeight: 'normal' }}>{` (${hasLocationPiezometers.length} ${getI18nTitleData(i18n.element, i18n.elements, hasLocationPiezometers)})`}</span>
                </>
            )}
            round
        >
            <CartographyPanel
                layers={['STATIONS_POINTS']}
                componentType='piezometry'
                stationsPoints={piezometersFiltered}
                stationsPanelTitle={i18n.stations}
                heightToSubstract={450}
                noStationPanel={false}
            />
        </WhiteCard>
    )
}

CartoPanel.propTypes = {
    piezometersFiltered: PropTypes.array,
}

const ReferentPanel = ({
    piezometers = [],
}) => {
    const {
        piezometersReferents,
        contributorsIndex,
    } = useSelector(store => ({
        piezometersReferents: store.PiezometryReducer.piezometersReferents,
        contributorsIndex: store.ContributorReducer.contributorsIndex,
    }), shallowEqual)

    const {
        piezoWithoutReferent,
        ...piezoGroupByReferent
    } = groupBy(piezometers, p => {
        const referentLink = piezometersReferents.find(link => link.idStation === p.id)
        return hasValue(referentLink?.code) ? referentLink?.idContributor : 'piezoWithoutReferent'
    })

    return (
        <div style={{ padding: '10 10 0 10' }}>
            {Object.keys(piezoGroupByReferent).map((idContributor, i) => {
                const {
                    [idContributor]: piezoFiltered = [],
                } = piezoGroupByReferent
                return (
                    <AccordionMUI className='margin-bottom-1' defaultExpanded={i === 0} key={i}>
                        <AccordionSummaryMUI>
                            <span style={{ fontSize: '1.5rem' }}>{idContributor !== 'undefined' ? (contributorsIndex[idContributor] || {}).name : i18n.unknown}</span>
                            <span style={{ fontSize: '1.5rem', fontWeight: 'normal' }}>&nbsp;{`(${piezoFiltered.length} ${getI18nTitleData(i18n.element, i18n.elements, piezoFiltered)})`}</span>
                        </AccordionSummaryMUI>
                        <AccordionDetailsMUI nopadding>
                            <TablePanel piezometers={piezoFiltered} />
                        </AccordionDetailsMUI>
                    </AccordionMUI>
                )
            })}
            {piezoWithoutReferent && (
                <AccordionMUI className='margin-bottom-1' defaultExpanded={!Object.keys(piezoGroupByReferent).length}>
                    <AccordionSummaryMUI>
                        <span style={{ fontSize: '1.5rem' }}>{i18n.unknown}</span>
                        <span style={{ fontSize: '1.5rem', fontWeight: 'normal' }}>&nbsp;{`(${piezoWithoutReferent.length} ${getI18nTitleData(i18n.element, i18n.elements, piezoWithoutReferent)})`}</span>
                    </AccordionSummaryMUI>
                    <AccordionDetailsMUI nopadding>
                        <TablePanel piezometers={piezoWithoutReferent} />
                    </AccordionDetailsMUI>
                </AccordionMUI>
            )}
        </div>
    )
}

ReferentPanel.propTypes = {
    piezometers: PropTypes.arrayOf(PropTypes.shape({})),
}

const PiezometersDashboardApp = () => {
    const dispatch = useDispatch()

    const {
        piezosIndex,
        piezometerListSpecific,
        selectedSearchValues,
        accountUser,
        globalResearch,
        citiesIndex,
        departments,
        piezometersReferents,
        piezometersOperators,
        networkPiezoLink,
        userBookmarks,
        piezometryCampaigns,
        piezometryCampaignsProgress,
        piezometryCampaignStations,
        filterResults: filterResultsProps,
        networks,
        accountHabilitations,
    } = useSelector(store => ({
        piezosIndex: store.PiezometryReducer.piezosIndex,
        piezometerListSpecific: store.PiezometryReducer.piezometerListSpecific,
        selectedSearchValues: store.AdministrationReducer.selectedSearchValues,
        accountUser: store.AccountReducer.accountUser,
        globalResearch: store.HomeReducer.globalResearch,
        citiesIndex: store.CityReducer.citiesIndex,
        departments: store.HomeReducer.departments,
        piezometersReferents: store.PiezometryReducer.piezometersReferents,
        piezometersOperators: store.PiezometryReducer.piezometersOperators,
        networkPiezoLink: store.PiezometryReducer.networkPiezoLink,
        userBookmarks: store.UserReducer.userBookmarks,
        piezometryCampaigns: store.PiezometryReducer.piezometryCampaigns,
        piezometryCampaignsProgress: store.PiezometryReducer.piezometryCampaignsProgress,
        piezometryCampaignStations: store.PiezometryReducer.piezometryCampaignStations,
        filterResults: store.StationReducer.filterResults,
        networks: store.NetworkReducer.networks,
        accountHabilitations: store.AccountReducer.accountHabilitations,
    }), shallowEqual)

    useEffect(() => {
        if (!componentHasHabilitations(H_PIEZO_DASHBOARD)) { // A modifier quand react-router sera à jour
            dispatch(push('/unauthorized'))
        }
    }, [])

    const {
        isLoaded,
        progress,
    } = useProgressDispatch(() => [
        dispatch(PiezometryAction.fetchPiezometersListSpecific()),
        dispatch(StationAction.fetchFilters()),
        dispatch(ReferencialAction.fetchSandreCodes()),
        dispatch(UserAction.fetchBookmarks()),
        // dispatch(ContactAction.fetchContacts()), // fetch in fetchPiezometersReferents function
        // dispatch(ContributorAction.fetchContributors()), // fetch in fetchPiezometersReferents function
        dispatch(CampaignAction.fetchPiezometryCampaigns()),
        dispatch(CampaignAction.fetchPiezometryCampaignsProgress()),
        dispatch(CampaignAction.fetchPiezometryCampaignStations()),
        dispatch(HomeAction.fetchDepartments()),
        dispatch(PiezometryAction.fetchPiezometersReferents()),
        dispatch(PiezometryAction.fetchNetworkPiezoLink()),
        dispatch(NetworkAction.fetchNetworks()),
        dispatch(CityAction.fetchCities()),
    ])

    useEffect(() => {
        if (!Object.keys(piezosIndex).length) {
            dispatch(PiezometryAction.fetchPiezometersLight())
        }
    }, [])

    useTitle(() => [{
        title: i18n.piezometry,
        href: 'piezometry',
    }, {
        title: i18n.dashboard,
        href: 'piezometry',
    }], [])

    const {
        value: isAddStationPopupOpen,
        setTrue: openAddStationPopup,
        setValue: setAddStationPopupOpen,
    } = useBoolean(false)

    const [filter, setFilter] = useState(() => {
        const {
            filter: filterCode = -1,
            filterResults = [],
            searchValue = '',
        } = selectedSearchValues.piezometry || {}
        return {
            filter: filterCode,
            filterResults,
            searchValue: globalResearch || searchValue,
        }
    })

    const contributorLinks = [
        ...uniqBy(piezometersReferents, 'code'),
        ...uniqBy(piezometersOperators, 'code'),
    ]

    const networksHabilitation = accountHabilitations.filter(({ habilitation }) => habilitation.match('^PIEZO_NETWORK_*')).map(({ habilitation }) => parseInt(habilitation.substr(14)))
    const contributorsHabilitation = accountHabilitations.filter(({ habilitation }) => habilitation.match('^PIEZO_CONTRIB_*')).map(({ habilitation }) => parseInt(habilitation.substr(14)))
    const networksContributors = networks.filter(({ contributorCode }) => contributorsHabilitation.includes(contributorCode))
    const networksId = [...networkPiezoLink.map(({ idNetwork }) => idNetwork), ...networksHabilitation]
    const networklistFound = uniq(networksId).map(id => networks.find(n => n.id === id)).filter(n => !!n)
    const piezoNetworks = uniqBy([...networksContributors, ...networklistFound], 'id')

    const piezoNatures = useSandreList(SANDRE.PIEZOMETER_NATURE)

    useActions(() => {
        const exportAction = {
            export: () => {
                const headers = ['code', 'internalReferences', 'department', 'cityCode', 'cityLabel', 'name', 'creationDate', 'closeDate', 'piezometerNature', 'administrator']
                const piezometersFormated = piezometerListSpecific.map(piezo => {
                    const referentLink = piezometersReferents.find(link => link.idStation === piezo.id) || {}
                    const operatorLink = piezometersOperators.find(link => link.idStation === piezo.id) || {}
                    const city = citiesIndex[piezo.townCode]

                    const creationDate = getDate(piezo.creationDate)

                    const department = piezo.townCode && city && (!city.countryCode || city.countryCode === 'FR') ? `${getLabel(departments, piezo.townCode.substr(0, 2))} [${piezo.townCode.substr(0, 2)}]` : ''

                    return {
                        id: piezo.id,
                        code: piezo.code,
                        internalReferences: piezo.internalReferences,
                        department,
                        cityCode: piezo.townCode,
                        cityLabel: city?.name,
                        name: piezo.name || '',
                        creationDate,
                        closeDate: getDate(piezo.closeDate),
                        piezometerNature: getLabel(piezoNatures, piezo.nature),
                        administrator: referentLink.name,
                        operatorId: operatorLink.code,
                        referentId: referentLink.code,
                        searchValue: searchAllCharacters(`${piezo.code}${piezo.internalReferences}${piezo.name}${city?.name || ''}${piezo.townCode}${creationDate}${referentLink.name}${operatorLink.name}${department}`),
                    }
                })

                const {
                    searchValue,
                    filter: filterCode = -1,
                    filterResults = [],
                    network,
                    referentIds = [],
                    operatorIds = [],
                } = filter
                const ids = filterResults.map(p => p.id)
                const search = searchAllCharacters(searchValue)
                const filterSearch = search ? piezometersFormated.filter(p => p.searchValue.includes(search)) : piezometersFormated
                const filterStation = filterCode !== -1 ? filterSearch.filter(p => ids.includes(p.id)) : filterSearch
                const filterNetwork = hasValue(network) ? filterStation.filter(p => networkPiezoLink.filter(n => n.idPiezometer === p.id).some(n => n.idNetwork === network)) : filterStation
                const piezometersRefFiltered = referentIds.length ? filterNetwork.filter(p => referentIds.includes(p.referentId)) : filterNetwork
                const piezometersOpFiltered = operatorIds.length ? piezometersRefFiltered.filter(p => operatorIds.includes(p.referentId)) : piezometersRefFiltered
                return {
                    data: piezometersOpFiltered.length ? [
                        { ...piezometersOpFiltered[0], headers },
                        ...piezometersOpFiltered.slice(1),
                    ] : [],
                    exportType: 'xlsx',
                    titleFile: i18n.watchpoints,
                }
            },
        }
        if (accountUser.isAdmin === '1' || accountUser.metadata === '1') {
            return {
                ...exportAction,
                new: openAddStationPopup,
            }
        }
        return exportAction
    }, [piezometerListSpecific, filter, piezometersReferents, citiesIndex, departments, piezoNatures])

    const { piezometry: searchValues } = selectedSearchValues
    const initialSort = searchValues?.sort

    const onTableSort = (piezometers, sort) => {
        dispatch(AdministrationAction.setSelectedSearchValues('piezometry', { stations: sortRows(piezometers, sort), sort }))
    }

    const onSaveSearchValue = piezometers => {
        dispatch(AdministrationAction.setSelectedSearchValues('piezometry', { stations: sortRows(piezometers, initialSort), initialSort }))
    }

    const campaignsFiltered = useMemo(() => piezometryCampaigns.filter(c => {
        const {
            nbStation = 0,
            nbStationValidated = 0,
        } = piezometryCampaignsProgress.find(cp => cp.id === c.id) || {}
        return nbStation !== 0 && nbStationValidated < nbStation && c.statut === 2
    }), [piezometryCampaigns, piezometryCampaignsProgress])

    const piezometersFormated = useMemo(() => {
        return piezometerListSpecific.map(piezo => {
            const referentLink = piezometersReferents.find(link => link.idStation === piezo.id) || {}
            const operatorLink = piezometersOperators.find(link => link.idStation === piezo.id) || {}
            const city = citiesIndex[piezo.townCode]

            const campaignStationsFiltered = piezometryCampaignStations.filter(cs => campaignsFiltered.some(c => c.id === cs.campaignId))

            const campaignStations = campaignStationsFiltered.filter(s => s.stationId === piezo.id)
            const campaigns = campaignStations.map(s => campaignsFiltered.find(c => c.id === s.campaignId))

            const creationDate = getDate(piezo.creationDate)

            const department = piezo.townCode && city && (!city.countryCode || city.countryCode === 'FR') ? `${getLabel(departments, piezo.townCode.substr(0, 2))} [${piezo.townCode.substr(0, 2)}]` : ''

            const filterValuesFound = filterResultsProps.find(fd => fd.id === piezo.id) || {}
            const filterValues = mapValues(omit(filterValuesFound, ['id']), value => ({ value: value !== 'null' ? value : '' }))

            return {
                ...piezo,
                id: piezo.id,
                code: piezo.code,
                nullValue: { value: getBookmarks(piezo.code, getUserBookmarksByStationType(userBookmarks, 'piezometry')) },
                nullValue2: { value: hasValue(piezo.jobExecutionId) && <Icon icon='wifi' style={{ fontSize: '1.5rem' }} /> },
                labelCode: { value: piezo.code },
                internalReferences: { value: piezo.internalReferences },
                department: { value: department },
                cityCode: { value: piezo.townCode },
                cityLabel: { value: city?.name },
                name: { value: piezo.name || '' },
                creationDate: { value: creationDate },
                closeDate: { value: getDate(piezo.closeDate) },
                piezometerNature: { value: getLabel(piezoNatures, piezo.nature) },
                administrator: { value: referentLink.name },
                nullValue3: { value: !!campaigns.length && <Icon icon='nature_people' tooltip={campaigns.map(c => c.name).join('</br>')} size='tiny' /> },
                lineColor: piezo.closeDate ? 'grey' : 'white',
                referentId: referentLink.idContributor,
                operatorId: operatorLink.idContributor,
                searchValue: searchAllCharacters(`${piezo.code}${piezo.internalReferences}${piezo.name}${city?.name || ''}${piezo.townCode}${creationDate}${referentLink.name}${department}`.replace('undefined', '').replace('null', '')),
                ...filterValues,
            }
        })
    }, [piezometerListSpecific, piezometersReferents, piezometersOperators, citiesIndex, piezometryCampaignStations, departments, filterResultsProps, userBookmarks, piezoNatures, campaignsFiltered])

    const filterFunc = (data) => {
        const {
            searchValue,
            filter: filterCode = -1,
            filterResults = [],
            network,
            referentIds = [],
            operatorIds = [],
        } = filter
        const ids = filterResults.map(p => p.id)
        const search = searchAllCharacters(searchValue)
        const filterSearch = search ? data.filter(p => p.searchValue.includes(search)) : data
        const filterStation = filterCode !== -1 ? filterSearch.filter(p => ids.includes(p.id)) : filterSearch
        const filterNetwork = hasValue(network) ? filterStation.filter(p => networkPiezoLink.filter(n => n.idPiezometer === p.id).some(n => n.idNetwork === network)) : filterStation
        const filterReferent = referentIds.length ? filterNetwork.filter(p => referentIds.includes(p.referentId)) : filterNetwork
        const filterOperator = operatorIds.length ? filterReferent.filter(p => operatorIds.includes(p.operatorId)) : filterReferent

        return filterOperator
    }

    const piezometersFiltered = useMemo(() => filterFunc(piezometersFormated), [filter, networkPiezoLink, piezometersFormated])

    const piezoMap = useMemo(() => {
        const searchData = piezometerListSpecific.map(piezo => {
            const referentLink = piezometersReferents.find(link => link.idStation === piezo.id) || {}
            const city = citiesIndex[piezo.townCode]
            const creationDate = getDate(piezo.creationDate)

            const department = piezo.townCode && city && (!city.countryCode || city.countryCode === 'FR') ? `${getLabel(departments, piezo.townCode.substr(0, 2))} [${piezo.townCode.substr(0, 2)}]` : ''
            return {
                ...piezo,
                searchValue: searchAllCharacters(`${piezo.code}${piezo.internalReferences}${piezo.name}${city?.name || ''}${piezo.townCode}${creationDate}${referentLink.name}${department}`.replace('undefined', '').replace('null', '')),
            }
        })
        return filterFunc(searchData)
    }, [filter, networkPiezoLink, piezometerListSpecific])

    return (
        <div style={{ marginRight: 5, paddingBottom: 100 }}>
            {!isLoaded && <ProgressCard progress={progress} withMessage />}
            {
                isLoaded && (
                    <>
                        <Grid2 container spacing={2}>
                            <Grid2 size={9}>
                                <CampaignPanel />
                            </Grid2>
                            <Grid2 size={3}>
                                <StationStatisticPanel stationType='piezometry' round />
                            </Grid2>
                            <Grid2 size={12}>
                                <SimpleTabList
                                    defaultTab={LIST_STATION}
                                    tabs={[
                                        {
                                            constant: LIST_STATION,
                                            label: i18n.table,
                                            icon: 'list',
                                        },
                                        {
                                            constant: CARTO_STATION,
                                            label: i18n.map,
                                            icon: 'map',
                                        },
                                        {
                                            constant: REFERENT,
                                            label: i18n.administrator,
                                            icon: 'person',
                                        },
                                    ]}
                                    headerComponent={(
                                        <StationFilterFields
                                            defaultFilter={filter}
                                            onValidate={setFilter}
                                            stationType={STATION_TYPE_NAME.piezometry}
                                            contributorLinks={contributorLinks}
                                            stations={piezometersFormated}
                                            networklist={piezoNetworks}
                                        />
                                    )}
                                >
                                    {
                                        tab => (
                                            <>
                                                {tab === LIST_STATION && (
                                                    <TablePanel
                                                        piezometers={piezometersFiltered}
                                                        initialSort={initialSort}
                                                        onTableSort={onTableSort}
                                                        onSaveSearchValue={onSaveSearchValue}
                                                        filter={filter}
                                                    />
                                                )}
                                                {tab === CARTO_STATION && <CartoPanel piezometersFiltered={piezoMap} />}
                                                {tab === REFERENT && <ReferentPanel piezometers={piezometersFiltered} />}
                                            </>
                                        )
                                    }
                                </SimpleTabList>
                            </Grid2>
                        </Grid2>
                    </>
                )
            }
            <AddPiezoStepper
                isOpen={isAddStationPopupOpen}
                setIsOpen={setAddStationPopupOpen}
            />
        </div>
    )
}

export default PiezometersDashboardApp
