export default class DtoIAEauLearning {
    constructor(obj) {
        this.idStation = obj.idStation
        this.typeStation = obj.typeStation
        this.modele = obj.modele
        this.apprentissageId = obj.apprentissageId
        this.dateDebut = obj.dateDebut
        this.dateFin = obj.dateFin
        this.statut = obj.statut
        this.parametres = obj.parametres
    }
}