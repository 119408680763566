import User from 'account/dto/User'
import { linkContributorsDepartments } from 'carto/constants/CartoConstants'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import SieauParameterDto from '../../../administration/dto/SieauParameterDto'
import { arrestsPath } from '../../../conf/SieauConstants'
import { getArrestColor } from '../../../utils/ColorUtil'
import { hasValue } from '../../../utils/NumberUtil'
import { getSetting, getSettingJson } from '../../../utils/SettingUtils'
import HomeAction from '../../actions/HomeAction'
import DtoArrest from '../../dto/DtoArrest'

class PrefectoralArrestPanel extends Component {
    componentDidMount() {
        const departments = this.props.townCode ? this.props.townCode.slice(0, 2) : this.getDepartments()
        if (hasValue(departments) && departments.length >= 2) {
            this.props.fetchArrests(departments)
        }
    }

    getDepartments = () => {
        const { accountUser, applicationSettings } = this.props
        const contribSettings = hasValue(accountUser.contributorCode) ? (getSettingJson(applicationSettings, linkContributorsDepartments) || []).find((s) => s.contributor === accountUser.contributorCode) : null
        return contribSettings?.departments || getSetting(applicationSettings, 'arrestDepartments')
    }

    render() {
        const { arrests } = this.props
        return !!arrests.length && (
            <div className='card margin-top-1 margin-bottom-0'>
                <div className='collection'>
                    {arrests.map(arrest => (
                        <a href={ arrestsPath } target='_new'>
                            <div className='collection-item no-padding border-bottom-light' style={{ fontSize: '11px' }}>
                                <div className={ `row no-margin valign-wrapper ${getArrestColor(arrest.typeArrest)}` }>
                                    <div className='col s2 no-padding'>
                                        <p className='center-align no-margin bold'>
                                            {[
                                                arrest.typeArrest.split(' ')[0],
                                                <br/>,
                                                arrest.typeArrest.split(' ')[1] || '',
                                            ]}
                                        </p>
                                    </div>
                                    <div className='col s10 white padding-left-1'>
                                        <div className='row no-margin'>
                                            <b className='bold'>
                                                { arrest.title }
                                            </b>
                                        </div>
                                        <div className='row no-margin'>
                                            { arrest.comment }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </a>
                    ))}
                </div>
            </div>
        )
    }
}

PrefectoralArrestPanel.propTypes = {
    townCode: PropTypes.string,
    arrests: PropTypes.arrayOf(PropTypes.instanceOf(DtoArrest)),
    applicationSettings: PropTypes.arrayOf(PropTypes.instanceOf(SieauParameterDto)),
    accountUser: PropTypes.instanceOf(User),
    fetchArrests: PropTypes.func,
}

const mapStateToProps = store => ({
    arrests: store.HomeReducer.arrests.filter(o => o.typeArrest === i18n.reinforcedAlert || o.typeArrest === i18n.crisis),
    applicationSettings: store.AdministrationReducer.applicationSettings,
    accountUser: store.AccountReducer.accountUser,
})

const mapDispatchToProps = {
    fetchArrests: HomeAction.fetchArrests,
}

export default connect(mapStateToProps, mapDispatchToProps)(PrefectoralArrestPanel)
