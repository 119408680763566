export default class SimSubscriptionDto {
    constructor(obj) {
        this.idSim = obj.idSim // Int,
        this.idSubscription = obj.idSubscription // Int,
        this.startDate = obj.startDate // Option[DateTime] = None,
        this.endDate = obj.endDate // Option[DateTime] = None,
        this.comment = obj.comment // Option[String] = None,
        this.jobExecutionId = obj.jobExecutionId // Option[Int] = None,
        this.updateDate = obj.updateDate // Option[DateTime] = None,
        this.updateLogin = obj.updateLogin // Option[String] = None
    }
}