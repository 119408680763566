import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import { getExport, setModal } from '../../../../utils/linkUtils'
import DtoInstallation from '../../../dto/installation/DtoInstallation'
import SieauAction from '../../../../components/sieau/SieauAction'
import Table from '../../../../components/datatable/Table'
import StaffSafetyModal from './modal/StaffSafetyModal'
import { arrayOf, getSandreList } from '../../../../utils/StoreUtils'
import DtoSandreCode from '../../../../referencial/dto/DtoSandreCode'
import { hasBooleanValue } from '../../../../utils/NumberUtil'
import { SANDRE } from '../../../../referencial/constants/ReferencialConstants'
import { getSandreLabel } from '../../../../utils/StringUtil'

const InstallationStaffSafetyPanel = ({
    station = {},
    readMode = false,
    sandreCodes = [],
    onChange = () => {},
    setPopup = () => {},
}) => {
    const sandreList = getSandreList(sandreCodes, SANDRE.INSTALLATIONS_PROTECTIONPERSONNEL)
    const stationStaffSafety = sandreList.map(element => {
        const data = station.link_staffSafeties.find(ss => element.code === ss.code) || {}
        return {
            ...element,
            ...data,
            comment: data.comment,
            integrated: hasBooleanValue(data.isIntegrated) ? data.isIntegrated ? i18n.yes : i18n.no : i18n.unknown,
        }
    })
    const headers = ['name', 'integrated', 'comment']
    const exportAction = getExport(stationStaffSafety, i18n.staffSafety, headers)
    const actions = [exportAction]

    return (
        <Table
            title={ i18n.staffSafety }
            className='blue'
            condensed
            data={ stationStaffSafety }
            type={{ headers }}
            sortable={ !!stationStaffSafety.length }
            actions={ actions }
            alterable={ !readMode }
            onAlter={
                (element) => setModal(`${i18n.staffSafety} : ${getSandreLabel(sandreCodes, SANDRE.INSTALLATIONS_PROTECTIONPERSONNEL, element.code)}`,
                    <StaffSafetyModal
                        id={ station.id }
                        staffSafety={ stationStaffSafety.find(c => element.code === c.code) }
                        saveResult={ r => onChange({
                            link_staffSafeties: [
                                ...station.link_staffSafeties.filter(ss => ss.code !== element.code),
                                r,
                            ],
                        })}
                    />
                    , setPopup)
            }
        />
    )
}

InstallationStaffSafetyPanel.propTypes = {
    station: PropTypes.instanceOf(DtoInstallation),
    sandreCodes: arrayOf(DtoSandreCode),
    readMode: PropTypes.bool,
    onChange: PropTypes.func,
    setPopup: PropTypes.func,
}

const mapStateToProps = store => ({
    sandreCodes: store.ReferencialReducer.sandreCodes,
})

const mapDispatchToProps = {
    setPopup: SieauAction.setPopup,
}

export default connect(mapStateToProps, mapDispatchToProps)(InstallationStaffSafetyPanel)