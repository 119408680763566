/* eslint-disable camelcase */

import { hasValue, round } from 'utils/NumberUtil'

export default class DtoUsageDeclaration {
    constructor(object) {
        this.idProvisionalUsage = object.idProvisionalUsage // Long
        this.idUsage = object.idUsage // Long
        this.idSurvey = object.idSurvey // Option[Long]
        this.idExploitation = object.idExploitation // Option[Long]
        this.idInstallation = object.idInstallation // Long

        this.idFamille = hasValue(object.idFamille) ? object.idFamille : null //  not save, find from idCulture
        this.idCulture = hasValue(object.idCulture) ? object.idCulture : null // Option[Long]

        this.irrigationType = hasValue(object.irrigationType) ? object.irrigationType : null // not save, find from irrigationMode
        this.irrigationMode = hasValue(object.irrigationMode) ? object.irrigationMode : null // Option[Long]

        this.area = round(object.area, 4) // Option[Double]
        this.groundType = hasValue(object.groundType) ? object.groundType : null // Option[Long]
        this.groundDepth = hasValue(object.groundDepth) ? object.groundDepth : null // Option[Long]
        this.nbRotation = hasValue(object.nbRotation) ? object.nbRotation : 1 // Option[Int]

        this.requestPrelevement = hasValue(object.requestPrelevement) ? object.requestPrelevement : 1 // Option[Int]

        this.comment = object.comment // Option[String]

        this.requestedYear = object.requestedYear // Option[Int]
        this.currentYear = object.currentYear // Option[Boolean]
        this.requestedYearVolume = object.requestedYearVolume // Option[Double]
        this.adjustedVolume = object.adjustedVolume // Option[Boolean]
        this.updateDate = object.updateDate // Option[DateTime]
        this.updateLogin = object.updateLogin // Option[String]
        this.lowWaterVolume = object.lowWaterVolume // Option[Double] = None,
        this.volumesMonths = object.volumesMonths // Option[String] = None,

        this.link_periodes = object.link_periodes || [] // Seq[DtoUsagePeriode]
    }
}