import moment from 'moment'

export default class JobLogTable {
    constructor(obj = {}) {
        this.id = obj.id
        this.comment = obj.comment
        this.status = obj.status
        this.value = obj.status === 'link' ? obj.value.split(' ').map(s => s.startsWith('http') ? <a href={s} className='clickable  fileLabelWithPath'>{s}</a> : s + ' ') : obj.value
        this.date = obj.date && moment(obj.date).format('DD/MM/YYYY HH:mm:ss')
        this.timestamp = obj.date
        this.station = obj.station
        this.jobExecutionId = obj.jobExecutionId
        this.color = obj.status === 'success' ? 'green-200' : (obj.status === 'error' ? 'red-200' : (obj.status === 'warning' ? 'yellow-200' : null))
        this.fileName = obj.fileName
        this.fileLabel = obj.fileLabel || obj.fileName
        this.headers = [ 'station', 'date', 'value', 'status', 'fileLabel']
    }
}
