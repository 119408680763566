/* eslint-disable camelcase */
import DtoMaterielChronicle from '../../materiel/dto/DtoMaterielChronicle'
import DtoExploitationMatRep from './DtoExploitationMatRep'
import DtoExploitationService from './DtoExploitationService'

export default class DtoExploitation {
    constructor(object) {
        this.idExploitation = object.idExploitation // Long
        this.status = object.status // Option[String]
        this.startDate = object.startDate // Option[DateTime]
        this.exploitationUpdateDate = object.exploitationUpdateDate // Option[DateTime]
        this.author = object.author // Option[String]
        this.comment = object.comment // Option[String]
        this.operatorCode = object.operatorCode // Option[Long]
        this.updateDate = object.updateDate // Option[DateTime]
        this.updateLogin = object.updateLogin // Option[String]
        this.codification = object.codification // Option[Long]
        this.code = object.codification // Option[Long]
        this.procedureType = object.procedureType // Option[Int]
        this.organism = object.organism // Option[Long]
        this.nbPtsPrel = object.nbPtsPrel // Option[Int]
        this.lastDeclarationId = object.lastDeclarationId // Option[Long]
        this.lastDeclarationStatus = object.lastDeclarationStatus // Option[Int]
        this.lastDeclarationStep = object.lastDeclarationStep // Option[Int]
        this.lastDeclarationYear = object.lastDeclarationYear // Option[Int]
        this.lastDeclarationVolumePrevi = object.lastDeclarationVolumePrevi // Option[Double]
        this.headers = ['codification', 'name', 'sampler', 'structureType', 'status', 'author']

        this.link_samplings = object.link_samplings || []// Option[Seq[SamplingPointExploitation]]
        this.link_contributors = object.link_contributors || [] // Option[Seq[ExploitationContributor]]
        this.link_codifications = object.link_codifications || [] // Option[Seq[ExploitationCodification]]
        this.link_chronicles = object.link_chronicles ? object.link_chronicles.map((c) => new DtoMaterielChronicle(c)) : [] // Option[Seq[MaterielChronicle]] = None
        this.link_lands = object.link_lands || []
        this.link_materiel = object.link_materiel || []
        this.link_services = object.link_services ? object.link_services.map((c) => new DtoExploitationService(c)) : []
        this.link_repartition_materiels = object.link_repartition_materiels ? object.link_repartition_materiels.map((r) => new DtoExploitationMatRep(r)) : []
        this.link_conso_real = object.link_conso_real || [] // Option[Seq[ExploitationConsoRealLink]] = None // only the last 10 years
    }
}