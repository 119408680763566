import React, { Component } from 'react'
import { connect } from 'react-redux'
import AppStore from 'store/AppStore'
import KeyFigurePanel from '../../../components/keyFigures/KeyFigurePanel'
import SieauAction from '../../../components/sieau/SieauAction'
import DtoPiezometerLight from '../../../piezometry/dto/DtoPiezometerLight'
import { arrayOf, getMapStateToProps, getPropTypes } from '../../../utils/StoreUtils'
import i18n from 'simple-react-i18n'

const propsToFetch = {
    userKeyfigures: true,
    qualityKeyfigures: true,

    qualitometers: false,
    hydrometricStations: false,
}

class DashboardKeyFiguresPanel extends Component {
    componentDidMount() {
        AppStore.dispatch(SieauAction.fetch(propsToFetch))
    }

    getData = () => {
        if (this.props.userKeyfigures.length > 0 && this.props.qualityKeyfigures.length > 0) {
            return [
                this.props.userKeyfigures.find(k => k.title === 'users'),
                {
                    title: 'piezometers',
                    value: this.props.piezometers.length,
                },
                {
                    title: 'qualitometers',
                    value: this.props.qualitometers.length,
                },
                {
                    title: 'hydrometricStations',
                    value: this.props.hydrometricStations.length,
                },
                this.props.qualityKeyfigures.find(k => k.title === 'operations'),
                this.props.qualityKeyfigures.find(k => k.title === 'analysis'),
            ]
        }
        return []
    }

    render() {
        return (
            <KeyFigurePanel data={ this.getData() } title={ i18n.statistics } icon={ 'donut_large' } />
        )
    }
}

DashboardKeyFiguresPanel.propTypes = getPropTypes(propsToFetch, {
    piezometers: arrayOf(DtoPiezometerLight),
})

const mapStateToProps = store => getMapStateToProps(propsToFetch, {
    piezometers: store.PiezometryReducer.piezometersLight,
})

export default connect(mapStateToProps)(DashboardKeyFiguresPanel)