import DtoMaterielSituation from '../../../dto/DtoMaterielSituation'

export default class DtoSimSituation extends DtoMaterielSituation {
    constructor(obj) {
        super(obj)
        this.id = obj.idSimSituation
        this.idSim = obj.idSim
        this.materielId = obj.idSim
    }
}
