export default class DtoMaterielSituation {
    constructor(obj) {
        this.id = obj.id
        this.materielId = obj.materielId
        this.siteCode = obj.siteCode
        this.siteType = obj.siteType
        this.siteName = obj.siteName
        this.statusCode = obj.statusCode
        this.comment = obj.comment
        this.situationDate = obj.situationDate
        this.situationEndDate = obj.situationEndDate
        this.campaignCode = obj.campaignCode
        this.eventCode = obj.eventCode
        this.administratorCode = obj.administratorCode
        this.updateLogin = obj.updateLogin
        this.updateDate = obj.updateDate
        this.headers = ['siteType', 'siteCode', 'siteName', 'statusCode', 'comment', 'situationDate', 'situationEndDate']
    }
}
