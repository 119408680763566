import PropTypes from 'prop-types'
import React from 'react'
import { sieauTooltip } from '../../utils/FormUtils'

const ColorOval = ({
    className = '',
    onClick = () => {},
    label = '',
    color = 'red',
    tooltip = null,
}) => {
    const tip = tooltip ? sieauTooltip(tooltip) : {}
    return (
        <span onClick={onClick} className={className}>
            <span className={'arrests-level-panel'} {...tip} style={ { color, backgroundColor: color } }>__</span>
            {label}
        </span>
    )
}

ColorOval.propTypes = {
    className: PropTypes.string,
    onClick: PropTypes.func,
    label: PropTypes.string,
    color: PropTypes.string,
    tooltip: PropTypes.string,
}

ColorOval.defaultProps = {
    className: '',
    label: '',
    color: 'red',
    tooltip: null,
}

export default ColorOval
