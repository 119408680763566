import i18n from 'simple-react-i18n'
import Row from '../../../../components/react/Row'
import Input from '../../../../components/forms/Input'
import { getDate, getDateWithHour, getHour } from '../../../../utils/DateUtil'
import { onChangeDate, onChangeHour } from '../../../../utils/FormUtils'
import React, { useState } from 'react'
import { WhiteCard } from '../../../../components/styled/Card'
import Button from '../../../../components/forms/Button'
import HydrometryAction from '../../../actions/HydrometryAction'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import DtoHydroMeasure from '../../../dto/chronicMeasures/DtoHydroMeasure'
import ConfirmModal from '../../../../components/modal/ConfirmModal'
import NumberField from '../../../../components/forms/NumberField'
import ToastrAction from '../../../../toastr/actions/ToastrAction'
import { isNull } from 'lodash'

const CorrectionHydroToolPanel = ({
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    isChange,
    setIsChange,
    createTechnicalEvent,
    measures,
    serieLinePrevious,
    setSerieLinePrevious,
    isPreview,
    setIsPreview,
    valueCorrection,
    setValueCorrection,
    measuresData,
}) => {
    const [modalOpen, setModalOpen] = useState(false)

    const dispatch = useDispatch()

    const len = measures?.length
    const diff = valueCorrection - measures[len-1]?.value
    const lastDate = measures[len-1]?.date
    const timeDiff = lastDate - measures[0]?.date

    const onValidate = () => {
        if (isNull(startDate) || isNull(endDate)) {
            dispatch(ToastrAction.warning(i18n.datesNoSelected))
        }
        setIsPreview(true)
        const getDiff = (measure) => diff * (1 - ((lastDate - measure) / timeDiff))

        const dataHydro = measuresData[0].measures.map(d => ([ d.date, d.value ]))

        const findIndexStartDate = dataHydro.findIndex(m => m[0]===startDate)-1
        const measureBeforeStartDate = dataHydro[findIndexStartDate]

        const findIndexEndDate = dataHydro.findIndex(m => m[0]===endDate)+1
        const measureAfterStartDate = dataHydro[findIndexEndDate]

        const measuresPrevious = measures.map(m => ({
            date: m.date,
            value: m.value + getDiff(m.date),
        })
        )

        const allMeasuresPreview = [
            ...(measureBeforeStartDate ? [{
                date: measureBeforeStartDate[0],
                value: measureBeforeStartDate[1],
            }] : []),
            ...measuresPrevious,
            ...(measureAfterStartDate ? [{
                date: measureAfterStartDate[0],
                value: measureAfterStartDate[1],
            }] : []),
        ]
        setSerieLinePrevious(allMeasuresPreview)
    }

    const onCancel = () => {
        setIsPreview(false)
        setSerieLinePrevious(measures)
    }

    const onSave = () => {
        setIsPreview(false)
        setModalOpen(true)

        const getDiff = (measure) => diff * (1 - ((lastDate - measure) / timeDiff))

        const measuresChanges = measures.map(m => ({
            ...m,
            value: m.value + getDiff(m.date),
            updated: true,
        })
        )

        return (
            <ConfirmModal
                isOpen={modalOpen}
                title={i18n.driftingCorrection}
                onValidate={
                    dispatch(HydrometryAction.updateMeasures(measuresChanges))
                        .then(() => setIsChange(!isChange))
                        .then(() => createTechnicalEvent({ mode: 'correction' }))

                }
                onClose={() => setModalOpen(false)}
            />
        )
    }

    const resetDates = () => {
        setStartDate(null)
        setEndDate(null)
    }

    return (
        <div>
            <WhiteCard title={ i18n.driftingCorrection } round>
                <div className='padding-top-1'/>
                <Row className='padding-bottom-1 valign-wrapper'>
                    <Button
                        tooltip={ i18n.resetDates }
                        icon='loop'
                        className='margin-left-1 validToolBtn'
                        onClick={ () => resetDates() }
                        disabled={ isPreview }
                    />
                    <h6 className='padding-left-1'>
                        { i18n.resetDates }
                    </h6>
                </Row>
                <Row>
                    <Input
                        col={ 6 }
                        title={ i18n.startDate }
                        id='startDate'
                        value={ getDate(startDate)}
                        onChange={ v => {
                            onChangeDate(v, v2 => {
                                return setStartDate(getDateWithHour(v2, startDate).valueOf())
                            })
                        } }
                        disabled={ isPreview }
                    />
                    <Input
                        col={ 6 }
                        title={ i18n.startHour }
                        value={ getHour(startDate) }
                        onChange={ v => {
                            onChangeHour(v, v2 => {
                                return setStartDate(getDateWithHour(startDate, v2).valueOf())
                            })
                        } }
                        disabled={ isPreview }
                    />
                </Row>
                <Row>
                    <Input
                        id='endDate'
                        col={ 6 }
                        value={ getDate(endDate) }
                        title={ i18n.endDate }
                        onChange={ v => {
                            onChangeDate(v, v2 => {
                                return setEndDate(getDateWithHour(v2, endDate).valueOf())
                            })
                        } }
                        disabled={ isPreview }
                    />
                    <Input
                        id='endDateHour'
                        col={ 6 }
                        title={ i18n.endHour }
                        value={ getHour(endDate) }
                        onChange={ v => {
                            onChangeHour(v, v2 => {
                                return setEndDate(getDateWithHour(endDate, v2).valueOf())
                            })
                        } }
                        disabled={ isPreview }
                    />
                </Row>
                <Row>
                    <NumberField
                        col={ 12 }
                        title={ i18n.value }
                        value={ valueCorrection }
                        onChange={ v => setValueCorrection(v) }
                        disabled={ isPreview }
                        floatValue
                    />
                </Row>
                <Row className='padding-bottom-1 padding-top-1 center-align'>
                    <Button
                        tooltip={ i18n.apply }
                        onClick={ onValidate }
                        icon='border_color'
                        className='btn-floating btn-large'
                        disabled={ isPreview }
                    />
                    <Button
                        tooltip={ i18n.cancel }
                        onClick={ onCancel }
                        icon='cancel'
                        className='red btn-floating btn-large margin-left-2 margin-right-2'
                        disabled={ !isPreview }
                    />
                    <Button
                        tooltip={ i18n.register }
                        onClick={ onSave }
                        icon='save'
                        disabled={ !isPreview }
                        className={ `btn-floating btn-large ${isPreview ? 'pulse' : ''}` }
                    />
                </Row>
            </WhiteCard>
        </div>

    )
}


CorrectionHydroToolPanel.propTypes = {
    startDate: PropTypes.number,
    setStartDate: PropTypes.func,
    endDate: PropTypes.number,
    setEndDate: PropTypes.func,
    isChange: PropTypes.bool,
    setIsChange: PropTypes.func,
    isPreview: PropTypes.bool,
    setIsPreview: PropTypes.func,
    createTechnicalEvent: PropTypes.func,
    dataToReset: PropTypes.instanceOf(DtoHydroMeasure),
    measures: PropTypes.instanceOf(DtoHydroMeasure),
    setSerieLinePrevious: PropTypes.func,
    measuresData: PropTypes.instanceOf(DtoHydroMeasure),
    valueCorrection: PropTypes.number,
    setValueCorrection: PropTypes.func,
}

export default CorrectionHydroToolPanel

