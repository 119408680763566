export default class DtoSavedResearchCriterias {
    constructor(obj) {
        this.id = obj.listCode
        this.listCode = obj.listCode
        this.title = obj.title
        this.filterCode = obj.filterCode
        this.researchType = obj.researchType
        this.startDate = obj.startDate
        this.endDate = obj.endDate
        this.producer = obj.producer
        this.network = obj.network
        this.parameter = obj.parameter
        this.selection = obj.selection
        this.unit = obj.unit
        this.threshold = obj.threshold
        this.thresholdLevel = obj.thresholdLevel
        this.support = obj.support
        this.method = obj.method
        this.status = obj.status
        this.qualification = obj.qualification
        this.quantificationControl = obj.quantificationControl
        this.qualitometerId = obj.qualitometerId
        this.stationType = obj.stationType
    }
}