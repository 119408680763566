export default class DtoSurveyActualConsumption {
    constructor(obj) {
        this.survey = obj.comment // String,
        this.year = obj.year // Option[Int],
        this.codification = obj.codification // Option[String],
        this.point = obj.point // Option[String],
        this.pump = obj.pump // Option[String],
        this.counter = obj.meter // Option[String],
        this.indexorvolume = obj.indexorvolume // Option[String],
        this.measurementDate = obj.measurementDate // Option[DateTime],
        this.endDate = obj.endDate // Option[DateTime],
        this.measure = obj.round // Option[Double],
        this.readingCoefficient = obj.readingCoefficient // Option[Double],
        this.username = obj.username // Option[String],
        this.siret = obj.siret // Option[String],
        this.userType = obj.userType // Option[String],
        this.municipalityCode = obj.municipalityCode // Option[String],
        this.municipalityName = obj.municipalityName // Option[String],
        this.cadastralParcel = obj.cadastralParcel // Option[String],
        this.parcel = obj.parcel // Option[String],
        this.section = obj.section // Option[String],
        this.watershed = obj.watershed // Option[String],
        this.idUge = obj.idUge // Option[String],
        this.nameUge = obj.nameUge // Option[String],
        this.idUnderUge = obj.idUnderUge // Option[String],
        this.nameUnderUge = obj.nameUnderUge // Option[String],
        this.sampleType = obj.sampleType // Option[String],
        this.sampleNature = obj.sampleNature // Option[String],
        this.repPump = obj.repPump // Option[String],
        this.repCounter = obj.repCounter // Option[String]
        this.workDepth = obj.workDepth // Option[Double],
        this.PAM = obj.PAM // Option[Boolean],
        this.specificPrescriptions = obj.specificPrescriptions // Option[Boolean]
        this.x = obj.x // Option[Double]
        this.y = obj.y // Option[Double]
        this.projectionType = obj.projectionType // Option[String]
    }
}