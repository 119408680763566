import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import React, { useEffect } from 'react'
import { Grid } from '@mui/material'
import PropTypes from 'prop-types'
import { instanceOf } from '../../../../utils/StoreUtils'
import DtoIAEauModel from '../../../../iaeau/dto/DtoIAEauModel'


const ModelStepAccess = ({
    stationType,
    id, // station id
    selectedModel,
    changeModel,
}) => {
    const dispatch = useDispatch()

    const {

    } = useSelector(store => ({

    }), shallowEqual)

    // const [isOpen, setIsOpen] = useState(false)

    useEffect(() => {

    }, [])

    return (
        <div className='padding-top-2'>
            <Grid container justifyContent='center' alignItems='center' spacing={3}>
                <h5>Pour le moment, les résultats du modèles seront accessibles à tous. Vous pouvez continuer.</h5>
            </Grid>
        </div>
    )
}

ModelStepAccess.propTypes = {
    stationType: PropTypes.string,
    id: PropTypes.string,
    selectedModel: instanceOf(DtoIAEauModel),
    setSelectedModel: PropTypes.func,
}

export default ModelStepAccess