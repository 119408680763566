module.exports = {
    RECEIVE_ALL_EVENT_PROBLEMS: 'RECEIVE_ALL_EVENT_PROBLEMS',
    ADD_ALL_SELECTED_PROBLEM: 'ADD_ALL_SELECTED_PROBLEM',
    ADD_SELECTED_PROBLEM: 'ADD_SELECTED_PROBLEM',
    REMOVE_SELECTED_PROBLEM: 'REMOVE_SELECTED_PROBLEM',
    RESET_SELECTED_PROBLEM: 'RESET_SELECTED_PROBLEM',
    RECEIVE_ALL_EVENT_PROBLEM_SOLUTION_LINKS: 'RECEIVE_ALL_EVENT_PROBLEM_SOLUTION_LINKS',
    RECEIVE_ALL_EVENT_SOLUTIONS: 'RECEIVE_ALL_EVENT_SOLUTIONS',
    ADD_SELECTED_LINK: 'ADD_SELECTED_LINK',
    REMOVE_SELECTED_SOLUTION: 'REMOVE_SELECTED_SOLUTION',
    ADD_ALL_SELECTED_SOLUTION: 'ADD_ALL_SELECTED_SOLUTION',
    RESET_SELECTED_SOLUTION: 'RESET_SELECTED_SOLUTION',
}