import AdministrationAction from 'administration/actions/AdministrationAction'
import GlobalParametersDto from 'administration/dto/GlobalParameterDto'
import { setActions } from 'components/ActionUtil'
import Card from 'components/card/Card'
import Table from 'components/datatable/Table'
import Select from 'components/forms/Select'
import SieauAction from 'components/sieau/SieauAction'
import { PATH_ADMINISTRATION, PATH_ADMINISTRATION_PARAMETERS } from 'home/constants/RouteConstants'
import { sortBy, uniqBy } from 'lodash'
import moment from 'moment'
import React, { useEffect, useMemo, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { nbPerPageLabel } from 'referencial/constants/ReferencialConstants'
import i18n from 'simple-react-i18n'
import PopupAddParameter from './PopupAddParameter'
import Input from 'components/forms/Input'
import { Button, Grid } from '@mui/material'
import { searchAllCharacters } from 'utils/StringUtil'

const AdminParameterApp = () => {
    const {
        globalParameters,
        login,
    } = useSelector(store => ({
        globalParameters: store.AdministrationReducer.globalParameters,
        login: store.AccountReducer.accountUser.login,
    }), shallowEqual)

    const [editMode, setEditMode] = useState(false)
    const [openAddModale, setOpenAddModale] = useState(false)
    const [openEditModale, setOpenEditModale] = useState(false)
    const [parameter, setParameter] = useState({})
    const [parameters, setParameters] = useState([])
    const [tmpFilter, setTmpFilter] = useState({})
    const [filter, setFilter] = useState({})

    const dispatch = useDispatch()

    const headers = ['parameter', 'module', 'updateDate', 'value', 'updateLogin']

    const exportParameters = () => {
        const parametersToExport = parameters.map((param, i) => {
            return i === 0 ? { headers, ...param } : param
        })
        return {
            data: parametersToExport,
            exportType: 'xlsx',
            titleFile: i18n.parameters,
        }
    }

    useEffect(() => {
        dispatch(AdministrationAction.fetchGlobalParameters())
    }, [])

    useEffect(() => {
        setParameters(globalParameters.map(param => ({
            ...param,
            updateDate: param.updateDate ? moment(param.updateDate).format('DD/MM/YYYY') : '',
        })))
    }, [globalParameters])

    useEffect(() => {
        dispatch(SieauAction.forceFetch('title', [{
            title: i18n.administration,
            href: PATH_ADMINISTRATION,
        }, {
            title: i18n.parameters,
            href: PATH_ADMINISTRATION_PARAMETERS,
        }]))
        const actions = () => {
            if (editMode) {
                return {
                    new: () => setOpenAddModale(true),
                    cancel: () => setEditMode(false),
                    export: () => exportParameters(),
                }
            }
            return {
                edit: () => setEditMode(true),
                export: () => exportParameters(),
            }
        }
        setActions(actions())
    })

    const onDelete = (deleteParam) => {
        const newParams = parameters.filter(param => param !== deleteParam)
        setParameters(newParams)
        dispatch(AdministrationAction.deleteGlobalParameter(new GlobalParametersDto({
            parameter: deleteParam.parameter,
            module: deleteParam.module,
            value: deleteParam.value,
            updateDate: moment(deleteParam.value).valueOf(),
            updateLogin: deleteParam.updateLogin,
        })))
    }

    const onAdd = (addParam) => {
        dispatch(AdministrationAction.createGlobalParameter(new GlobalParametersDto({
            parameter: addParam.parameter,
            module: addParam.module,
            value: addParam.value,
        })))
        setParameters([...parameters, addParam])
        setOpenAddModale(false)
    }

    const onEdit = (editParameter) => {
        setParameter(editParameter)
        setOpenEditModale(true)
    }

    const onUpdate = (updateParam) => {
        const updateParameters = parameters.filter(param => param !== parameter)
        const param = new GlobalParametersDto({
            parameter: updateParam.parameter,
            module: updateParam.module,
            value: updateParam.value,
            updateDate: moment().valueOf(),
            updateLogin: login,
        })
        setParameters([...updateParameters, { ...param, updateDate: moment(param.updateDate).format('DD/MM/YYYY') }])
        dispatch(AdministrationAction.updateGlobalParameter(param))
        setOpenEditModale(false)
    }

    const optionsParameters = sortBy(uniqBy(parameters, 'parameter'), 'parameter')

    const parametersFiltered = useMemo(() => {
        const filterSearch = filter.searchValue ? parameters.filter(p => headers.some((k) => searchAllCharacters(p[k])?.includes(searchAllCharacters(filter.searchValue)))) : parameters
        return filter.param ? filterSearch.filter(p => p.parameter === filter.param) : filterSearch
    }, [parameters, filter, headers])

    return (
        <div className='row no-margin'>
            <div className='col s10 offset-s1 margin-top-1'>
                <Card round>
                    <Grid container className='padding-1' alignItems='center'>
                        <Grid item xs={4}>
                            <Input
                                col={12}
                                title={i18n.search}
                                value={tmpFilter.searchValue}
                                onChange={v => setTmpFilter({ ...tmpFilter, searchValue: v })}
                                onEnterKeyPress={(v) => setFilter({ ...tmpFilter, searchValue: v })}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <Select
                                col={12}
                                label={i18n.parameters}
                                value={tmpFilter.param}
                                options={optionsParameters}
                                onChange={v => setTmpFilter({ ...tmpFilter, param: v })}
                                returnNull={true}
                                keyValue='parameter'
                                keyLabel='parameter'
                            />
                        </Grid>
                        <Grid item xs={4} container justifyContent='flex-end'>
                            <Grid item>
                                <Button
                                    variant='outlined'
                                    onClick={() => {
                                        setTmpFilter({})
                                        setFilter({})
                                    }}
                                >
                                    {i18n.reinit}
                                </Button>
                            </Grid>
                            <Grid item className='padding-left-1'>
                                <Button variant='contained' onClick={() => setFilter(tmpFilter)}>
                                    {i18n.search}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Card>
            </div>
            <div className='col s10 offset-s1 padding-top-1 referencial-table'>
                <Table
                    title={i18n.parameters}
                    sortable paging condensed
                    nbPerPageLabel={nbPerPageLabel}
                    data={ parametersFiltered }
                    type={{ headers }}
                    deletable={ editMode }
                    onDelete={ (deleteParam) => onDelete(deleteParam) }
                    alterable={ editMode }
                    onAlter={ (editParameter) => onEdit(editParameter) }
                    round
                />
            </div>
            <div className='col s5 padding-top-1'>
                {openAddModale && (
                    <PopupAddParameter
                        openDialog = {openAddModale}
                        addParameter = {(addParam) => onAdd(addParam) }
                        closeDialog = {() => setOpenAddModale(false)}
                    />
                )}
                {openEditModale && (
                    <PopupAddParameter
                        parameter = { parameter }
                        openDialog = {openEditModale}
                        addParameter = {(addParam) => onUpdate(addParam) }
                        closeDialog = {() => setOpenEditModale(false)}
                    />
                )}
            </div>
        </div>

    )
}

export default AdminParameterApp