import DtoMaterielSituation from '../../../dto/DtoMaterielSituation'

export default class DtoPowerSupplySituation extends DtoMaterielSituation {
    constructor(obj) {
        super(obj)
        this.id = obj.idPowerSupplySituation
        this.idPowerSupply = obj.idPowerSupply
        this.materielId = obj.idPowerSupply
    }
}
