export default class ParameterGroupDto {
    constructor(obj) {
        const {
            code,
            name,
            status,
            author,
            creationDate,
            updateDate,
            description,
            parentCode,
        } = obj

        this.code = code
        this.name = name
        this.status = status
        this.author = author
        this.creationDate = creationDate
        this.updateDate = updateDate
        this.description = description
        this.parentCode = parentCode
    }
}