import { getOptions } from '../EChartUtils'

class _HeatMap {
    constructor(obj) {
        this.obj = obj
    }

    getDefaultOptions = () => {
        return {
            name: 'HeatMap chart',
            type: 'heatmap',
            data: [],
            label: {
                normal: {
                    show: true,
                },
            },
            itemStyle: {
                emphasis: {
                    shadowBlur: 10,
                    shadowColor: 'rgba(0, 0, 0, 0.5)',
                },
            },
        }
    }

    getJson = () => {
        return getOptions(this.obj, this.getDefaultOptions(), {})
    }
}

export default function HeatMap(args) {
    return new _HeatMap(args)
}


