module.exports = {
    SIEAU: 'SIEAU',
    QUALITY: 'QUALITY',
    PIEZOMETRY: 'PIEZOMETRY',
    HYDROMETRY: 'HYDROMETRY',
    PLUVIOMETRY: 'PLUVIOMETRY',
    INSTALLATION: 'INSTALLATION',
    DISTRIBUTION_UNIT: 'DISTRIBUTION_UNIT',
    PRODUCTION_UNIT: 'PRODUCTION_UNIT',
    MATERIEL: 'MATERIEL',
    IMPORT: 'IMPORT',
    EXPORT: 'EXPORT',
    WEB: 'WEB',
    MOBILE: 'MOBILE',
    IOS: 'IOS',
    ANDROID: 'ANDROID',

    STATISTIC_MODULES: {
        SIEAU: 'SIEAU',
        OBSERVATOIRE: 'OBSERVATOIRE',
        MEDEAU: 'MEDEAU',
        QAPTYS: 'QAPTYS',
        CCE: 'CCE',
        V6: 'V6',
    },

    STATISTIC_TYPE_NAME: {
        LOGIN: 'LOGIN',
        LOGOUT: 'LOGOUT',
        USAGE: 'USAGE',
        STATION: 'STATION',
        DOCUMENT: 'DOCUMENT',
        CMS: 'CMS',
        CARTO: 'CARTO',
        CGU: 'CGU',
    },

    STATISTIC_MODULE_USAGE: {
        HOME: 'HOME',
        FOLLOW: 'FOLLOW',
        CONTACT: 'CONTACT',
        SEARCH: 'SEARCH',
        ACCOUNT: 'ACCOUNT',
    },

    STATISTIC_MODULE_USAGE_STATION: {
        PIEZOMETRY: 'PIEZOMETRY',
        HYDROMETRY: 'HYDROMETRY',
        PLUVIOMETRY: 'PLUVIOMETRY',
        QUALITY: 'QUALITY',
        PRODUCTIONUNIT: 'PRODUCTIONUNIT',
        RESOURCE: 'RESOURCE',
    },

    STATISTIC_MODULE_DOCUMENT: {
        IMAGE: 'IMAGE',
        FILE: 'FILE',
        OTHER: 'OTHER',
    },

    STATISTIC_MODULE_CARTO: {
        SELECTED: 'SELECTED',
        DESELECTED: 'DESELECTED',
    },

    STATISTIC_USAGE_FONCTION: {
        EXPORT_XLSX: 'EXPORT_XLSX',
        EXPORT_CSV: 'EXPORT_CSV',
        ADD_EVENT: 'ADD_EVENT',
    },

}