export default class DtoUser {
    constructor(obj) {
        this.login = obj.login
        this.password = obj.password
        this.isAdmin = obj.isAdmin
        this.contributorCode = obj.contributorCode
        this.metadata = obj.metadata
        this.piezoFilter = obj.piezoFilter
        this.qualitoFilter = obj.qualitoFilter
        this.pluvioFilter = obj.pluvioFilter
        this.hydroFilter = obj.hydroFilter
        this.contactCode = obj.contactCode
        this.labo = obj.labo
        this.startDate = obj.startDate
        this.endDate = obj.endDate
        this.updateDate = obj.updateDate
        this.loginMaj = obj.loginMaj
        this.token = obj.token
        this.resetPassword = obj.resetPassword
        this.isTechnicien = obj.isTechnicien
        this.consultant = obj.consultant
        this.connexionAttempts = obj.connexionAttempts
        this.isApplicative = obj.isApplicative
    }
}
