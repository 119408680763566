import { difference, uniqBy } from 'lodash'
import PropTypes from 'prop-types'
import React, { Component, useState } from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import AppStore from 'store/AppStore'
import SieauParameterDto from '../../../../administration/dto/SieauParameterDto'
import ProgressCard from '../../../../components/card/ProgressCard'
import Table from '../../../../components/datatable/Table'
import Checkbox from '../../../../components/forms/Checkbox'
import Input from '../../../../components/forms/Input'
import NumberField from '../../../../components/forms/NumberField'
import RadioButtons from '../../../../components/forms/RadioButtons'
import Select from '../../../../components/forms/Select'
import Icon from '../../../../components/icon/Icon'
import Row from '../../../../components/react/Row'
import PiezometryAction from '../../../../piezometry/actions/PiezometryAction'
import DtoParametrageDataType from '../../../../piezometry/dto/DtoParametrageDataType'
import DtoPiezometerLight from '../../../../piezometry/dto/DtoPiezometerLight'
import QualityAction from '../../../../quality/actions/QualityAction'
import DtoQualification from '../../../../quality/dto/DtoQualification'
import DtoStatus from '../../../../quality/dto/DtoStatus'
import ReferencialAction from '../../../../referencial/action/ReferencialAction'
import ContributorAction from '../../../../referencial/components/contributor/actions/ContributorAction'
import { nbPerPageLabelShort, SANDRE } from '../../../../referencial/constants/ReferencialConstants'
import DtoSandreCode from '../../../../referencial/dto/DtoSandreCode'
import DtoContributor from '../../../../station/dto/DtoContributor'
import { setModal } from '../../../../utils/FormUtils'
import { getIntegrationModes, getMeasureCoteList } from '../../../../utils/JobUtils'
import { hasValue } from '../../../../utils/NumberUtil'
import { getHardPiezoDataTypes } from '../../../../utils/PiezometryUtils'
import { getSettingInt } from '../../../../utils/SettingUtils'
import { getQualificationSelectOptions, getStatusSelectOptions } from '../../../../utils/StatusUtil'
import { arrayOf, getSandreList } from '../../../../utils/StoreUtils'
import Job from '../../../dto/DtoJob'
import ImportFileModal from './ImportFileModal'
import { integrationModeHelpIcon } from '../../../utils/ImportUtils'
import { DialogActionsMUI, DialogContentMUI, DialogMUI, DialogTitleMUI } from 'components/styled/Dialog'
import { Button, Grid } from '@mui/material'
import DtoHydrometricStation from '../../../../hydrometry/dto/DtoHydrometricStation'
import HydrometryAction from '../../../../hydrometry/actions/HydrometryAction'
import { execByType, getStations } from '../../../../utils/StationUtils'
import { getHardHydroDataTypes } from '../../../../utils/HydroUtils'
import MultiContributorsAutocomplete from 'referencial/components/contributor/components/MultiContributorsAutocomplete'

const DialogSelectColumn = ({
    isOpen = false,
    onClose = () => {},
    onValidate = () => {},
    listColumn = [],
    customColumn = {},
    unwantedColumn = [],
}) => {
    const [selectedColumn, setSelectedColumn] = useState(() => listColumn.filter(c => !unwantedColumn.some(uc => uc === c)))
    const isSelected = column => selectedColumn.find(c => c === column)
    const onChange = column => {
        if (isSelected(column)) {
            setSelectedColumn(columns => columns.filter(c => c !== column))
        } else {
            setSelectedColumn(columns => [...columns, column])
        }
    }
    return (
        <DialogMUI
            fullWidth
            maxWidth='md'
            open={isOpen}
            PaperProps={{
                sx: {
                    minHeight: '45vh',
                    maxHeight: '45vh',
                },
            }}
        >
            <DialogTitleMUI>
                <Grid container justifyContent='space-between' alignItems='center'>
                    <Grid item >
                        { i18n.settingsToDisplay }
                    </Grid>
                    <Grid item>
                        <Icon style={{ color: 'white' }} size='small' icon={'close'} onClick={onClose} />
                    </Grid>
                </Grid>
            </DialogTitleMUI>
            <DialogContentMUI style={{ paddingTop: '10px' }}>
                <Grid container justifyContent='space-between'>
                    {
                        listColumn.map(column => (
                            <Grid xs={6}>
                                <Checkbox label={customColumn[column] ?? i18n[column]} checked={isSelected(column)} onChange={() => onChange(column)} />
                            </Grid>
                        ))
                    }
                </Grid>
            </DialogContentMUI>
            <DialogActionsMUI>
                <Button
                    onClick={() => onValidate(listColumn.filter(c => !selectedColumn.some(uc => uc === c)))}
                    variant='contained'
                    color='primary'
                >
                    {i18n.validate}
                </Button>
            </DialogActionsMUI>
        </DialogMUI>
    )
}

DialogSelectColumn.propTypes = {
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
    onValidate: PropTypes.func,
    listColumn: PropTypes.arrayOf(PropTypes.string),
    customColumn: PropTypes.objectOf(PropTypes.string),
    unwantedColumn: PropTypes.arrayOf(PropTypes.string),
}

class MISImportPanel extends Component {
    constructor(props) {
        super(props)
        this.state = {
            dataLoaded: true,
            progress: 0,
            isDialogOpen: false,
            filters: {},
        }
    }

    setAdvancedFtpModal =() => {
        setModal({
            title: i18n.importParam,
            content: <ImportFileModal url={this.props.job.parameters.path} />,
        })
    }

    componentDidMount() {
        AppStore.dispatch(PiezometryAction.fetchPiezometersLight())
        AppStore.dispatch(HydrometryAction.fetchHydrometricStations())
        AppStore.dispatch(PiezometryAction.fetchPiezometryDataTypes())
        AppStore.dispatch(HydrometryAction.fetchHydrometryDataTypes())
        if (!this.props.status.length) {
            AppStore.dispatch(QualityAction.fetchStatus())
        }
        if (!this.props.qualifications.length) {
            AppStore.dispatch(QualityAction.fetchQualifications())
        }
        if (!this.props.sandreCodes.length) {
            AppStore.dispatch(ReferencialAction.fetchSandreCodes())
        }
        if (!this.props.contributors.length) {
            AppStore.dispatch(ContributorAction.fetchContributors())
        }
        this.initFilter()
    }

    initFilter = () => {
        const jobFilters = this.props.job.parameters.filters.length ? JSON.parse(this.props.job.parameters.filters[0]) : {}
        const newFilters = {
            measureCote: 2,
            producer: getSettingInt(this.props.applicationSettings, 'validationDefaultProducer'),
            manager: getSettingInt(this.props.applicationSettings, 'validationDefaultManager'),
            validator: getSettingInt(this.props.applicationSettings, 'validationDefaultValidator'),
            ...jobFilters,
        }
        this.setState({ filters: newFilters })
    }

    onChangeJobParameter = (changes) => {
        const jobParameters = {
            ...this.props.job.parameters,
            ...changes,
        }
        this.props.onChangeJob({ parameters: jobParameters })
    }

    onChangeParametersNew = (currentParameter, index, changes) => {
        const jobParameters = {
            ...this.props.job.parameters,
            parameters: Object.assign([], this.props.job.parameters.parameters, {
                [index]: JSON.stringify({ ...currentParameter, ...changes }),
            }),
        }
        this.props.onChangeJob({ parameters: jobParameters })
    }

    onChangeFilters = (changes) => {
        const newFilters = { ...this.state.filters, ...changes }
        const parameters = {
            ...this.props.job.parameters,
            filters: [JSON.stringify(newFilters)],
        }
        this.setState({ filters: newFilters }, () => this.props.onChangeJob({ parameters }))
    }

    onChangeColumns = (parameter, index, dataType, channels) => {
        const actualData = parameter.data || []
        const newData = (() => {
            if (!hasValue(channels)) {
                return actualData.filter(d => d.dataType !== dataType)
            }
            return uniqBy([ { dataType, channels }, ...actualData], 'dataType')
        })()
        this.onChangeParametersNew(parameter, index, { data: newData })
    }

    findData = (parameter, dataType) => {
        const found = (parameter.data || []).find(d => d.dataType === dataType)
        return found ? found.channels : null
    }

    getParametersNew = () => this.props.job.parameters.parameters.length ? this.props.job.parameters.parameters.map(p => JSON.parse(p)) : []

    getDataTypes = (filters) => execByType(filters.stationType || 'piezometry', {
        piezometry: () => uniqBy([ ...getHardPiezoDataTypes(), ...this.props.piezometryDataTypes ], 'id'),
        hydrometry: () => uniqBy([ ...this.props.hydrometryDataTypes, ...getHardHydroDataTypes() ], 'id'),
        default: () => [],
    })

    onDeleteParameter = (index) => {
        if (this.props.isEditMode) {
            const jobParameters = {
                ...this.props.job.parameters,
                parameters: this.props.job.parameters.parameters.filter((_, i) => i !== index),
            }
            this.props.onChangeJob({ parameters: jobParameters })
        }
    }

    onDuplicateParameter = (index) => {
        if (this.props.isEditMode) {
            const jobParameters = {
                ...this.props.job.parameters,
                parameters: [ ...this.props.job.parameters.parameters, this.props.job.parameters.parameters[index] ],
            }
            this.props.onChangeJob({ parameters: jobParameters })
        }
    }

    getParametersData = (filters, parameters) => {
        const disabled = { disabled: !this.props.isEditMode }
        return parameters.map((p, index) => {
            const columnFields = this.getDataTypes(filters).reduce((acc, val) => ({
                ...acc,
                [`data${val.id}`]: <Input value={ this.findData(p, val.id) } onChange={ v => this.onChangeColumns(p, index, val.id, v) } {...disabled }/>,
            }), {})
            return {
                nullValue: <Icon icon='delete' tooltip={ i18n.delete } onClick={ () => this.onDeleteParameter(index) } />,
                nullValue2: <Icon icon='add_to_photos' tooltip={ i18n.duplicate } onClick={ () => this.onDuplicateParameter(index) } />,
                directory: <Input value={ p.directory } onChange={ v => this.onChangeParametersNew(p, index, { directory: v }) } {...disabled }/>,
                filter: <Input value={ p.filter } onChange={ v => this.onChangeParametersNew(p, index, { filter: v }) } {...disabled }/>,
                coeff: <NumberField value={ p.factor } onChange={ v => this.onChangeParametersNew(p, index, { factor: v }) } floatValue {...disabled }/>,
                offset: <NumberField value={ p.offset } onChange={ v => this.onChangeParametersNew(p, index, { offset: v }) } floatValue {...disabled }/>,
                station: (<Select options={ getStations(this.props, filters.stationType || 'piezometry').map(s => ({ ...s, name: s.code ? `[${s.code}] ${s.name}` : s.name })) }
                    onChange={ (_, v) => v && v.id ? this.onChangeParametersNew(p, index, { stationCode: v.code, stationId: v.id }) : null }
                    value={ p.stationId } keyValue='id' {...disabled }
                />),
                requiredValue: <Input value={ p.condition } onChange={ v => this.onChangeParametersNew(p, index, { condition: v }) } {...disabled }/>,
                ...columnFields,
            }
        })
    }

    addParameterNew = () => {
        if (this.props.isEditMode) {
            const jobParameters = {
                ...this.props.job.parameters,
                parameters: [ ...this.props.job.parameters.parameters, '{}'],
            }
            this.props.onChangeJob({ parameters: jobParameters })
        }
    }

    filtreDatatype = (data, value) => data.filter(d => d.dataType == value ? d.channels : '')
        .map(v => v.channels)

    exportData = (parameters, filters) => {
        const dataTypes = this.getDataTypes(filters)
        const headers = ['directory', 'filter', 'station', 'coeff', 'offset', ...dataTypes.map(h => h.label)]
        const data = parameters.map(p => {
            const param = JSON.parse(p)
            const columnFields = dataTypes.reduce((acc, val) => ({
                ...acc,
                [val.label]: param.data ? this.filtreDatatype(param.data, val.id) : '',
            }), {})
            const line = {
                directory: param.directory,
                filter: param.filter,
                station: param.stationCode,
                coeff: param.factor,
                offset: param.offset,
                headers,
                ...columnFields,
            }
            return line
        })
        return data
    }

    render() {
        const { dataLoaded, filters } = this.state
        if (dataLoaded) {
            const disabled = !this.props.isEditMode
            const parameters = this.getParametersData(filters, this.getParametersNew())
            const baseHeaders = ['nullValue', 'nullValue2', 'directory', 'filter', 'station', 'coeff', 'offset', 'requiredValue']
            const columnHeaders = this.getDataTypes(filters).map(type => `data${type.id}`)
            const customHeaders = this.getDataTypes(filters).reduce((acc, val) => ({ ...acc, [`data${val.id}`]: val.label }), {})
            const data = this.exportData(this.props.job.parameters.parameters, filters)
            const addDataAction = this.props.isEditMode ? [{
                onClick: () => this.addParameterNew(),
                iconName: 'add_box',
                tooltip: `${i18n.add}`,
            }, {
                onClick: () => this.setState({ isDialogOpen: true }),
                iconName: 'build',
                tooltip: `${i18n.advanced}`,
            }] : []
            const dataTypes = [
                { value: 'piezometry', label: i18n.piezometry },
                { value: 'hydrometry', label: i18n.hydrometry },
            ]
            return (
                <div>
                    <div className='row no-margin padding-top-1'>
                        <fieldset>
                            <legend>&nbsp;{i18n.defaultValue}&nbsp;</legend>
                            <div className='row no-margin'>
                                <Row className='padding-top-1'>
                                    <Select col={ 3 } label={ i18n.status } value={ filters.status } onChange={ v => this.onChangeFilters({ status: v }) } options={ getStatusSelectOptions() } nullLabel='&nbsp;' disabled={ disabled }/>
                                    <Select col={ 3 } label={ i18n.qualification } value={ filters.qualification } onChange={ v => this.onChangeFilters({ qualification: v }) } options={ getQualificationSelectOptions() } nullLabel='&nbsp;' disabled={ disabled }/>
                                    <MultiContributorsAutocomplete col={ 6 } label={ i18n.producer } values={ filters.producer } onChange={ v => this.onChangeFilters({ producer: v }) } options={ this.props.contributors } disabled={ disabled }/>
                                </Row>
                                <Row>
                                    <Select col={ 2 } label={ i18n.measureNature } value={ filters.nature } onChange={ v => this.onChangeFilters({ nature: v }) } options={ getSandreList(this.props.sandreCodes, SANDRE.PIEZOMETER_MEASURE_NATURE, true) } nullLabel='&nbsp;' disabled={ disabled }/>
                                    <Select col={ 2 } label={ i18n.obtainningMode } value={ filters.obtainingMode } onChange={ v => this.onChangeFilters({ obtainingMode: v }) } options={ getSandreList(this.props.sandreCodes, SANDRE.OBTAINING_MODE) } nullLabel='&nbsp;' disabled={ disabled }/>
                                    <MultiContributorsAutocomplete col={ 4 } label={ i18n.administrator } values={ filters.manager } onChange={ v => this.onChangeFilters({ manager: v }) } options={ this.props.contributors } disabled={ disabled }/>
                                    <MultiContributorsAutocomplete col={ 4 } label={ i18n.validator } values={ filters.validator } onChange={ v => this.onChangeFilters({ validator: v }) } options={ this.props.contributors } disabled={ disabled }/>
                                </Row>
                            </div>
                        </fieldset>
                    </div>
                    <div className='row no-margin valign-wrapper padding-top-1'>
                        <Select col={ 2 } label={ i18n.dataType } options={ dataTypes } obligatory={true} value={ filters.stationType || 'piezometry' } onChange={ v => this.onChangeFilters({ stationType: v }) } disabled={ disabled }/>
                        <Checkbox col={ 3 } label={ 'Renommer les fichiers en .DONE' } onChange={ v => this.onChangeFilters({ renameProcessedFiles: v }) } disabled={ disabled } checked={ filters.renameProcessedFiles } />
                        <Checkbox col={ 4 } label={ 'Ne pas traiter les fichiers présents dans le journal' } onChange={ v => this.onChangeFilters({ dontProcessFilesInLogs: v }) } disabled={ disabled } checked={ filters.dontProcessFilesInLogs } />
                        <RadioButtons col={ 3 } elements={ getMeasureCoteList() } selected={ filters.measureCote } onChange={ v => this.onChangeFilters({ measureCote: v }) } title={ i18n.ratingExpression } disabled={ disabled }/>
                    </div>
                    <div className='row no-margin valign-wrapper padding-top-1'>
                        <Select col={ 3 } options={ getIntegrationModes() } label={ i18n.dataIntegration } value={ filters.importMode } labelSpan={ integrationModeHelpIcon() }
                            onChange={ v => this.onChangeFilters({ importMode: v }) } disabled={ disabled }
                        />
                        <Input col={ 3 } title={ i18n.directory } value={ this.props.job.parameters.path } onChange={ v => this.onChangeJobParameter({ path: v }) } disabled={ disabled } />
                        <a className='btn btn-small secondary-color-back' onClick={ () => this.setAdvancedFtpModal()} ><span title={ i18n.importLabelFtp }> <i className='material-icons'>input </i> </span></a>
                        <Input col={ 3 } title={ i18n.formatDate } value={ filters.dateFormat } onChange={ v => this.onChangeFilters({ dateFormat: v }) } disabled={ disabled } placeholder='dd/MM/YYYY-HH:mm:ss'/>

                        <NumberField col={ 3 } title={ i18n.excludedValue } value={ filters.excludedValue } onChange={ v => this.onChangeFilters({ excludedValue: v }) } disabled={ disabled } floatValue />
                    </div>
                    <div className='job-parameter-table'>
                        <Table
                            data={ parameters }
                            nbPerPageLabel={ nbPerPageLabelShort }
                            type={{ headers: difference([...baseHeaders, ...columnHeaders], filters.unwantedColumn) } }
                            sortable
                            condensed
                            paging
                            customHeaders={ customHeaders }
                            activeHeader
                            exportButtonOnHeader
                            actions={ addDataAction }
                            exportData={ data }
                        />
                    </div>
                    <DialogSelectColumn
                        isOpen={this.state.isDialogOpen}
                        onClose={() => this.setState({ isDialogOpen: false })}
                        onValidate={unwantedColumn => {
                            this.onChangeFilters({ unwantedColumn })
                            this.setState({ isDialogOpen: false })
                        }}
                        listColumn={columnHeaders}
                        customColumn={customHeaders}
                        unwantedColumn={filters.unwantedColumn}
                    />
                </div>
            )
        }
        return <ProgressCard progress={ this.state.progress }/>
    }
}

MISImportPanel.propTypes = {
    job: PropTypes.instanceOf(Job).isRequired,
    onChangeJob: PropTypes.func.isRequired,
    piezometryDataTypes: arrayOf(DtoParametrageDataType),
    hydrometryDataTypes: arrayOf(DtoParametrageDataType),
    piezometers: arrayOf(DtoPiezometerLight),
    hydrometricStations: arrayOf(DtoHydrometricStation),
    status: arrayOf(DtoStatus),
    qualifications: arrayOf(DtoQualification),
    sandreCodes: arrayOf(DtoSandreCode),
    contributors: arrayOf(DtoContributor),
    isEditMode: PropTypes.bool,
    applicationSettings: arrayOf(SieauParameterDto),
}

const mapStateToProps = store => ({
    piezometryDataTypes: store.PiezometryReducer.piezometryDataTypes,
    hydrometryDataTypes: store.HydrometryReducer.hydrometryDataTypes,
    piezometers: store.PiezometryReducer.piezometersLight,
    hydrometricStations: store.HydrometryReducer.hydrometricStations,
    status: store.QualityReducer.status,
    qualifications: store.QualityReducer.qualifications,
    sandreCodes: store.ReferencialReducer.sandreCodes,
    contributors: store.ContributorReducer.contributors,
    applicationSettings: store.AdministrationReducer.applicationSettings,
})

export default connect(mapStateToProps)(MISImportPanel)