import { userActions } from 'administration/components/user/reducers/UserReducer'
import { push } from '@lagunovsky/redux-react-router'
import { InstallationActionConstant } from 'installation/reducers/InstallationReducer'
import { HydrometryActionConstant } from 'hydrometry/reducers/HydrometryReducer'
import fetch from 'isomorphic-fetch'
import { PiezometryActionConstant } from 'piezometry/reducers/PiezometryReducer'
import { ProductionUnitActionConstant } from 'productionUnit/reducers/ProductionUnitReducer'
import { QualityActionConstant } from 'quality/reducers/QualityReducer'
import i18n from 'simple-react-i18n'
import AppStore from 'store/AppStore'
import ToastrAction from 'toastr/actions/ToastrAction'
import WaitAction from 'wait/WaitAction'
import AccountAction from '../../account/actions/AccountAction'
import {
    H_DISTRIBUTION_MODULE,
    H_HYDRO_MODULE,
    H_INSTALLATION_MODULE,
    H_MAT_MODULE,
    H_PIEZO_MODULE,
    H_PRODUCTION_MODULE,
    H_QUALITO_MODULE,
    H_RESOURCE_MODULE,
} from '../../account/constants/AccessRulesConstants'
import UserAction from '../../administration/components/user/actions/UserAction'
import CampaignAction from '../../campaign/actions/CampaignAction'
import ApplicationConf from '../../conf/ApplicationConf'
import DistributionUnitAction from '../../distributionUnit/actions/DistributionUnitAction'
import CmsAction from '../../events/actions/CmsAction'
import HydrometryAction from '../../hydrometry/actions/HydrometryAction'
import InstallationAction from '../../installation/actions/InstallationAction'
import LogAction from '../../log/actions/LogAction'
import CentralAction from '../../materiel/components/central/actions/CentralAction'
import EquipmentAction from '../../materiel/components/equipment/actions/EquipmentAction'
import PowerSupplyAction from '../../materiel/components/powerSupply/actions/PowerSupplyAction'
import SensorAction from '../../materiel/components/sensor/actions/SensorAction'
import SimAction from '../../materiel/components/sim/actions/SimAction'
import TelecomAction from '../../materiel/components/telecom/actions/TelecomAction'
import VariousMaterielAction from '../../materiel/components/variousMateriel/actions/VariousMaterielAction'
import PiezometryAction from '../../piezometry/actions/PiezometryAction'
import ProductionUnitAction from '../../productionUnit/actions/ProductionUnitAction'
import QualityAction from '../../quality/actions/QualityAction'
import CityAction from '../../referencial/components/city/actions/CityAction'
import ContactAction from '../../referencial/components/contact/actions/ContactAction'
import ContributorAction from '../../referencial/components/contributor/actions/ContributorAction'
import FractionAction from '../../referencial/components/fraction/actions/FractionAction'
import HydrogeologicalEntityAction from '../../referencial/components/hydrogeologicalEntity/actions/HydrogeologicalEntityAction'
import MethodAction from '../../referencial/components/methods/actions/MethodAction'
import NetworkAction from '../../referencial/components/network/actions/NetworkAction'
import ParameterAction from '../../referencial/components/parameter/actions/ParameterAction'
import SupportAction from '../../referencial/components/support/actions/SupportAction'
import TaxonAction from '../../referencial/components/taxon/actions/TaxonAction'
import UnitAction from '../../referencial/components/unit/actions/UnitAction'
import WatermassAction from '../../referencial/components/watermass/actions/WatermassAction'
import WatershedAction from '../../referencial/components/watershed/actions/WatershedAction'
import { checkAuth, genericPromise, getAuthorization, getJson } from '../../utils/ActionUtils'
import { componentHasHabilitations } from '../../utils/HabilitationUtil'
import { getUser } from '../../utils/SettingUtils'
import { RECEIVE_ARRESTS } from '../constants/ArrestConstants'
import {
    ACTIONS,
    ADD_NOTIFICATION,
    BACK_PATH,
    DISCONNECTED,
    LOGIN_FAIL,
    NAVBAR_LINKS,
    READ_NOTIFICATION,
    RECEIVE_LAST_DOCUMENTS,
    RECEIVE_NOTIFICATIONS,
    RECEIVE_THEME_LAYERS,
    SET_FORMATION_PATH,
    SET_HELP_LINK,
    SHOW_WELCOME_POPUP,
    SIEAU_LOGIN,
    SIEAU_TOKEN,
    TITLE,
    UPDATE_GLOBAL_RESEARCH,
} from '../constants/HomeConstants'
import { RECEIVE_DEPARTMENTS_AQUADB } from '../constants/RefrencialConstants'
import { RECEIVE_VIGILANCES } from '../constants/VigilanceConstants'
import { DistributionUnitActionConstant } from 'distributionUnit/reducers/DistributionUnitReducer'
import { ContactActionConstant } from 'referencial/components/contact/reducers/ContactReducer'
import { ContributorActionConstant } from 'referencial/components/contributor/reducers/ContributorReducer'
import ResourceAction from 'resource/actions/ResourceAction'


const HomeAction = {
    fetchIfNeeded: (prop, fetchFunc, param1) => dispatch => {
        const elem = AppStore.getState().HomeReducer[prop]
        if (elem.length) {
            if (elem.length !== 0) {
                return { type: '' }
            }
        } else if (Object.keys(elem).length !== 0) {
            return { type: '' }
        }
        return dispatch(fetchFunc(param1))
    },
    setTitle(title) {
        const storeTitleHash = AppStore.getState().HomeReducer.title.map(obj => obj.title).join()
        const titleHash = title.map(obj => obj.title).join()
        if (storeTitleHash !== titleHash) {
            return { type: TITLE, title }
        }
        return { type: TITLE, title }
    },
    addToTitle(title) { // { title: String, href: String UNIQUEMENT de l'url à RAJOUTER }
        const currentTitle = AppStore.getState().HomeReducer.title
        const lastItem = currentTitle.slice(-1)[0]
        if (lastItem) {
            const newElement = {
                title: title.title,
                href: lastItem.href.endsWith('/') ? lastItem.href + title.href : `${lastItem.href}/${title.href}`,
            }
            return { type: TITLE, title: [...currentTitle, newElement] }
        }
        return { type: TITLE, title: currentTitle }
    },
    setBackPath: backPath => ({
        type: BACK_PATH,
        backPath,
    }),
    setLinks: links => ({
        type: NAVBAR_LINKS,
        links,
    }),
    loginFail: message => ({
        type: LOGIN_FAIL,
        message,
    }),
    disconnected: message => ({
        type: DISCONNECTED,
        message,
    }),
    login: (login, password, onLogin) => dispatch => {
        dispatch(WaitAction.waitStart())
        const basicAuth = btoa(`${login}:${password}`)
        return fetch(ApplicationConf.login(), {
            method: 'POST',
            headers: {
                Authorization: `Basic ${basicAuth}`,
            },
            body: JSON.stringify({
                module: 'WEB',
            }),
        })
            .then(checkAuth)
            .then(getJson)
            .then(json => {
                localStorage.setItem(SIEAU_LOGIN, login)
                localStorage.setItem(SIEAU_TOKEN, json)
                dispatch(WaitAction.waitStop())
                dispatch(AccountAction.fetchUser(login, onLogin))
            }).catch(err => {
                dispatch(LogAction.logError(`${i18n.unauthorizedLogin} : ${err}`))
                dispatch(ToastrAction.error(i18n.unauthorizedLogin))
                dispatch(WaitAction.waitStop())
            })
    },
    logout: () => dispatch => {
        return fetch(ApplicationConf.logout(), {
            method: 'POST',
            headers: getAuthorization(),
        }).then(response => {
            localStorage.removeItem(SIEAU_TOKEN)
            dispatch(push('/login'))
            switch (response.status) {
                case 200:
                    break
                default:
                    throw new Error(response.statusText)
            }
        })
    },
    receiveDepartments: departments => ({ type: RECEIVE_DEPARTMENTS_AQUADB, departments }),
    promiseDepartments: () => {
        return genericPromise(ApplicationConf.referencial.departmentsAquadb()).catch(() => [])
    },
    fetchDepartments: () => {
        return (dispatch) => {
            return HomeAction.promiseDepartments()
                .then(json => {
                    dispatch(HomeAction.receiveDepartments(json))
                    return json
                })
        }
    },
    receiveArrests: arrests => ({
        type: RECEIVE_ARRESTS,
        arrests,
    }),
    fetchArrests: (departments = '') => dispatch => {
        if (departments.length !== 0) {
            return fetch(ApplicationConf.arrest.get(departments), {
                method: 'GET',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then((json = []) => {
                    dispatch(HomeAction.receiveArrests(json))
                    const reinforcedAlert = json.filter(o => o.maxLevel > 1).length
                    if (reinforcedAlert) {
                        dispatch(HomeAction.addNotification({ arrests: reinforcedAlert }))
                    }
                })
        }
        return { type: '' }
    },
    resetArrests() {
        return dispatch => dispatch(HomeAction.receiveArrests([]))
    },
    receiveVigilances: vigilances => ({
        type: RECEIVE_VIGILANCES,
        vigilances,
    }),
    fetchVigilances: (departments = '') => dispatch => {
        if (departments.length !== 0) {
            return fetch(ApplicationConf.vigilance.get(departments), {
                method: 'GET',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then((json = []) => {
                    dispatch(HomeAction.receiveVigilances(json))
                    if (json.length) {
                        dispatch(HomeAction.addNotification({ vigilances: json.length }))
                    }
                })
        }
        return { type: '' }
    },
    resetVigilances() {
        return dispatch => dispatch(HomeAction.receiveVigilances([]))
    },
    setActions: actions => ({
        type: ACTIONS,
        actions,
    }),
    fetchImage(url, callback) {
        return () => {
            return fetch(url, {
                method: 'GET',
            })
                .then(response => {
                    if (response.status === 200) {
                        callback(true)
                    } else {
                        callback(false)
                    }
                    return { type: '' }
                })
                .catch(() => {
                    callback(false)
                    return { type: '' }
                })
        }
    },
    resetNotifications: () => dispatch => dispatch(HomeAction.receiveNotifications({})),
    readNotification: notification => ({
        type: READ_NOTIFICATION,
        notification,
    }),
    addNotification: notification => ({
        type: ADD_NOTIFICATION,
        notification,
    }),
    receiveNotifications: notifications => ({
        type: RECEIVE_NOTIFICATIONS,
        notifications,
    }),
    fbPublisher(message, token) {
        return dispatch => {
            const form = new FormData()
            form.append('message', message)
            fetch(`https://graph.facebook.com/v2.12/1665097736911103/feed?access_token=${token}`, {
                method: 'POST',
                body: form,
            })
                .then(res => {
                    if (res.status !== 200 && res.status !== 201) {
                        throw new Error(res.status)
                    }
                    return res.json()
                })
                .then(json => {
                    if (!json.id) {
                        throw new Error(i18n.fbPublishError)
                    }
                    dispatch(ToastrAction.success(i18n.fbPublisherSuccess))
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.fbPublishError} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fbPublishError))
                })
        }
    },
    receiveThemeLayers(layers) {
        return { type: RECEIVE_THEME_LAYERS, layers }
    },
    promiseThemeLayers() {
        return fetch(ApplicationConf.layers.getLayers(), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
    },
    fetchThemeLayers() {
        return function (dispatch) {
            HomeAction.promiseThemeLayers()
                .then((json = []) => {
                    if (json.error) {
                        throw new Error(JSON.stringify(json))
                    }
                    dispatch(HomeAction.receiveThemeLayers(json))
                })
                .catch(e => {
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.mapLayers} : ${e}`))
                    dispatch(ToastrAction.error(i18n.fetchError + i18n.mapLayers))
                })
        }
    },
    updateThemeLayer(layer, isNew) {
        return (dispatch) => {
            dispatch(WaitAction.waitStart())
            if (isNew) {
                dispatch(HomeAction.receiveThemeLayers(AppStore.getState().HomeReducer.themeLayers.concat([layer])))
                dispatch(WaitAction.waitStop())
            } else {
                const layers = AppStore.getState().HomeReducer.themeLayers.filter(t => t.id !== layer.id)
                dispatch(HomeAction.receiveThemeLayers(layers.concat([layer])))
                dispatch(WaitAction.waitStop())
            }
        }
    },
    receiveLastDocuments: documents => ({
        type: RECEIVE_LAST_DOCUMENTS,
        documents,
    }),
    fetchLastDocuments() {
        return function (dispatch) {
            return fetch(ApplicationConf.files.lastDocuments(), {
                method: 'GET',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then(json => {
                    dispatch(HomeAction.receiveLastDocuments(json))
                    dispatch(HomeAction.addNotification({ documents: json.length }))
                })
                .catch(e => {
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.lastDocuments} : ${e}`))
                    dispatch(ToastrAction.error(i18n.fetchError + i18n.lastDocuments))
                })
        }
    },
    resetPassword(login, redirectLogin = true, url, digitVersion = false) {
        return dispatch => {
            dispatch(WaitAction.waitStart())
            return fetch(ApplicationConf.resetPassword(), {
                method: 'POST',
                body: JSON.stringify({ login, url, digitVersion, module: digitVersion ? 'MOBILE' : 'WEB' }),
            })
                .then(response => checkAuth(response, {
                    404: () => {
                        dispatch(ToastrAction.error(i18n.noEmailWithLogin))
                        dispatch(WaitAction.waitStop())
                    },
                    409: () => {
                        dispatch(ToastrAction.error(i18n.emailAlreadySend))
                        dispatch(WaitAction.waitStop())
                    },
                }))
                .then(() => {
                    if (redirectLogin) {
                        dispatch(push('/login'))
                    }
                    dispatch(ToastrAction.success(i18n.sendEmail))
                    dispatch(WaitAction.waitStop())
                    dispatch(userActions.resetUserAttempts())
                })
                .catch(e => {
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.retrievePassword} : ${e}`))
                    dispatch(ToastrAction.error(i18n.fetchError + i18n.retrievePassword))
                    dispatch(WaitAction.waitStop())
                })
        }
    },
    updatePassword(password, token) {
        return dispatch => {
            dispatch(WaitAction.waitStart())
            return fetch(ApplicationConf.resetPassword(), {
                method: 'PUT',
                body: JSON.stringify({ password, token }),
            })
                .then(r => checkAuth(r, { 403: () => {
                    throw new Error('Not Authorized')
                } }))
                .then(() => {
                    dispatch(push('/login'))
                    dispatch(ToastrAction.success(i18n.passwordUpdate))
                    dispatch(WaitAction.waitStop())
                })
                .catch(e => {
                    dispatch(LogAction.logError(e))
                    dispatch(ToastrAction.error(i18n.unauthorized))
                    dispatch(WaitAction.waitStop())
                })
        }
    },
    loadGlobalResearchStations(callback = () => { }) {
        return function (dispatch) {
            const store = AppStore.getState()
            const promises = []
            const receives = []

            const addPromise = (tab, promise, receive) => {
                if (!tab.length) {
                    promises.push(promise.catch(() => []))
                    receives.push(receive)
                }
            }

            if (componentHasHabilitations(H_QUALITO_MODULE)) {
                addPromise(store.QualityReducer.qualitometersLight, QualityAction.promiseQualitometersLight(), QualityActionConstant.receiveQualitometersLight)
                // addPromise(store.QualityReducer.qualityCampaigns, QualityAction.promiseQualityCampaigns(), CampaignAction.receiveQualityCampaigns)
            }
            if (componentHasHabilitations(H_PIEZO_MODULE)) {
                addPromise(store.PiezometryReducer.piezometersLight, PiezometryAction.promisePiezometersLight(), PiezometryActionConstant.receiveAllPiezometersLight)
                addPromise(store.PiezometryReducer.piezometryCampaigns, CampaignAction.promiseCampaigns('piezometry'), PiezometryActionConstant.receivePiezometryCampaigns)
                addPromise(store.PiezometryReducer.piezometersContributors, PiezometryAction.promisePiezometersContributors(), PiezometryActionConstant.receiveAllPiezometersContributors)
            }
            if (componentHasHabilitations(H_HYDRO_MODULE)) {
                addPromise(store.HydrometryReducer.hydrometricStations, HydrometryAction.promiseHydrometricStations(), HydrometryActionConstant.receiveAllHydrometricStations)
            }
            if (componentHasHabilitations(H_PRODUCTION_MODULE)) {
                addPromise(store.ProductionUnitReducer.productionUnits, ProductionUnitAction.promiseProductionUnits(), ProductionUnitActionConstant.receiveAllProductionUnits)
            }
            if (componentHasHabilitations(H_DISTRIBUTION_MODULE)) {
                addPromise(store.DistributionUnitReducer.distributionUnits, DistributionUnitAction.promiseDistributionUnits(), DistributionUnitActionConstant.receiveAllDistributionUnits)
            }
            if (componentHasHabilitations(H_INSTALLATION_MODULE)) {
                addPromise(store.InstallationReducer.installationsLight, InstallationAction.promiseInstallationsLight(), InstallationActionConstant.ALL_INSTALLATIONS_LIGHT)
            }
            if (componentHasHabilitations(H_RESOURCE_MODULE)) {
                addPromise(store.ResourceReducer.resources, ResourceAction.promiseResources(), ResourceAction.receiveResources)
            }

            return Promise.all(promises).then(jsonTab => {
                receives.map((receive, index) => {
                    dispatch(receive(jsonTab[index]))
                })
                callback()
            })
        }
    },
    loadGlobalResearchReferentials(callback = () => { }) {
        return function (dispatch) {
            const store = AppStore.getState()
            const promises = []
            const receives = []

            const addPromise = (tab, promise, receive) => {
                if (!tab.length) {
                    promises.push(promise.catch(() => []))
                    receives.push(receive)
                }
            }
            if (getUser().metadata === '1') {
                addPromise(store.CityReducer.cities, CityAction.promiseCities(), CityAction.receiveCities)
                addPromise(store.ContactReducer.contacts, ContactAction.promiseContacts(), ContactActionConstant.receiveAllContacts)
                addPromise(store.NetworkReducer.networks, NetworkAction.promiseNetworks(), NetworkAction.receiveNetworks)
                addPromise(store.FractionReducer.fractions, FractionAction.promiseFractions(), FractionAction.receiveFractions)
                addPromise(store.ContributorReducer.contributors, ContributorAction.promiseContributors(), ContributorActionConstant.receiveAllContributors)
                addPromise(store.WatermassReducer.watermasses, WatermassAction.promiseWatermasses(), WatermassAction.receiveWatermasses)
                addPromise(store.MethodReducer.methods, MethodAction.promiseMethods(), MethodAction.receiveMethods)
                addPromise(store.ParameterReducer.parameters, ParameterAction.promiseParameters(), ParameterAction.receiveParameters)
                addPromise(store.SupportReducer.supports, SupportAction.promiseSupports(), SupportAction.receiveSupports)
                addPromise(store.UnitReducer.units, UnitAction.promiseUnits(), UnitAction.receiveUnits)
                addPromise(store.HydrogeologicalEntityReducer.hydrogeologicalEntities, HydrogeologicalEntityAction.promiseHydrogeologicalEntities(), HydrogeologicalEntityAction.receiveHydrogeologicalEntities)
                addPromise(store.WatershedReducer.watersheds, WatershedAction.promiseWatersheds(), WatershedAction.receiveWatersheds)
                addPromise(store.TaxonReducer.taxon, TaxonAction.promiseTaxons(), TaxonAction.receiveTaxons)
            }

            return Promise.all(promises).then(jsonTab => {
                receives.map((receive, index) => {
                    dispatch(receive(jsonTab[index]))
                })
                callback()
            })
        }
    },
    loadGlobalResearchOther(callback = () => { }) {
        return function (dispatch) {
            const store = AppStore.getState()
            const promises = []
            const receives = []

            const addPromise = (tab, promise, receive) => {
                if (!tab.length) {
                    promises.push(promise.catch(() => []))
                    receives.push(receive)
                }
            }
            addPromise(store.EventsReducer.cmsEvents, CmsAction.promiseCMSEvents(), CmsAction.receiveCMSEvents)
            addPromise(store.EventsReducer.cmsCategories, CmsAction.promiseCMSCategories(), CmsAction.receiveCMSCategories)
            if (getUser().isAdmin === '1') {
                addPromise(store.UserReducer.users, UserAction.promiseUsers(), userActions.receiveUsers)
            }
            if (componentHasHabilitations(H_MAT_MODULE)) {
                addPromise(store.CentralReducer.centrals, CentralAction.promiseCentrals(), CentralAction.receiveCentrals)
                addPromise(store.PowerSupplyReducer.powerSupplies, PowerSupplyAction.promisePowerSupplies(), PowerSupplyAction.receivePowerSupplies)
                addPromise(store.SensorReducer.sensors, SensorAction.promiseSensors(), SensorAction.receiveSensors)
                addPromise(store.SimReducer.sims, SimAction.promiseSims(), SimAction.receiveSims)
                addPromise(store.TelecomReducer.telecoms, TelecomAction.promiseTelecoms(), TelecomAction.receiveTelecoms)
                addPromise(store.VariousMaterielReducer.variousMateriels, VariousMaterielAction.promiseVariousMateriels(), VariousMaterielAction.receiveVariousMateriel)
                addPromise(store.EquipmentReducer.equipments, EquipmentAction.promiseEquipments(), EquipmentAction.receiveEquipments)
                addPromise(store.CentralReducer.centralTypes, CentralAction.promiseCentralTypes(), CentralAction.receiveCentralTypes)
                addPromise(store.PowerSupplyReducer.powerSupplyTypes, PowerSupplyAction.promisePowerSupplyTypes(), PowerSupplyAction.receivePowerSupplyTypes)
                addPromise(store.SensorReducer.sensorTypes, SensorAction.promiseSensorTypes(), SensorAction.receiveSensorTypes)
                addPromise(store.SimReducer.simTypes, SimAction.promiseSimTypes(), SimAction.receiveSimTypes)
                addPromise(store.TelecomReducer.telecomTypes, TelecomAction.promiseTelecomTypes(), TelecomAction.receiveTelecomTypes)
                addPromise(store.VariousMaterielReducer.variousMaterielTypes, VariousMaterielAction.promiseVariousMaterielTypes(), VariousMaterielAction.receiveVariousMaterielTypes)
                addPromise(store.EquipmentReducer.equipmentTypes, EquipmentAction.promiseEquipmentTypes(), EquipmentAction.receiveEquipmentTypes)
            }

            return Promise.all(promises).then(jsonTab => {
                receives.map((receive, index) => {
                    dispatch(receive(jsonTab[index]))
                })
                callback()
            })
        }
    },

    updateGlobalResearch: value => ({
        type: UPDATE_GLOBAL_RESEARCH,
        value,
    }),
    showWelcomePopup: value => ({
        type: SHOW_WELCOME_POPUP,
        value,
    }),
    sendMailToSupport: mail => dispatch => {
        return fetch(ApplicationConf.mailSupport(), {
            method: 'POST',
            headers: getAuthorization(),
            body: JSON.stringify(mail),
        })
            .then(checkAuth)
            .then(getJson)
            .then(json => {
                if (json.error) {
                    dispatch(ToastrAction.error(i18n.mailNotSent))
                } else {
                    dispatch(ToastrAction.success(i18n.mailSent))
                }
            })
            .catch(err => {
                dispatch(LogAction.logError(`${i18n.mailNotSent} : ${err}`))
                dispatch(ToastrAction.error(i18n.mailNotSent))
            })
    },
    changePassword(password) {
        return (dispatch) => {
            return fetch(ApplicationConf.user.changePassword(), {
                method: 'PUT',
                headers: getAuthorization(),
                body: JSON.stringify(password),
            })
                .then(() => {
                    dispatch(ToastrAction.success(i18n.passwordInitialized))
                })
                .catch((err) => {
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.newPassword} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError + i18n.newPassword))
                })
        }
    },
    changePasswordByAdmin(login, password) {
        return (dispatch) => {
            return fetch(ApplicationConf.user.changePasswordByAdmin(login), {
                method: 'PUT',
                headers: getAuthorization(),
                body: JSON.stringify(password),
            })
                .then(() => {
                    dispatch(ToastrAction.success(i18n.passwordInitialized))
                })
                .catch((err) => {
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.newPassword} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError + i18n.newPassword))
                })
        }
    },
    setHelpLink(module = '', number = '') {
        return (dispatch) => dispatch({ type: SET_HELP_LINK, module, number })
    },
    setFormationPath(formationPath = '') {
        return (dispatch) => dispatch({ type: SET_FORMATION_PATH, formationPath })
    },
}

export default HomeAction
