import React from 'react'
import PropTypes from 'prop-types'
import ReactECharts from 'echarts-for-react'
import echarts from 'echarts/lib/echarts'
import i18n from 'simple-react-i18n'
import { groupBy, keys } from 'lodash'
import { INDICATORS_COLORS } from 'utils/constants/ColorTheme'
import { LEVELS } from 'agriAdministration/constants/AgriConstants'

export const ManagementUnitsPARChart = ({
    datas = [],
}) => {
    const groupedUG = groupBy(datas, 'level')

    const getLabel = (key) => {
        if (key === LEVELS.incomplete) {
            return i18n.AUPtoComplete
        } else if (key === LEVELS.conform) {
            return i18n.compatibleAUP
        } else if (key === LEVELS.closeOverrun) {
            return i18n.closeOverrunAUP
        } else if (key === LEVELS.overrun) {
            return i18n.overrunAUP
        }
        return i18n.unknown
    }

    const getColor = (key) => {
        if (key === LEVELS.incomplete) {
            return 'grey'
        } else if (key === LEVELS.conform) {
            return 'green'
        } else if (key === LEVELS.closeOverrun) {
            return 'orange'
        } else if (key === LEVELS.overrun) {
            return 'red'
        }
        return INDICATORS_COLORS.BLACK
    }

    const formattedData = keys(groupedUG).map(key => {
        const data = groupedUG[key]
        return {
            value: data.length,
            name: `${data.length} ${getLabel(Number(key))}`,
            itemStyle: {
                color: getColor(Number(key)),
            },
        }
    })

    const formatTooltip = ({ marker, data: { name = '' } }) => `${marker} ${name}`

    const option = {
        tooltip: {
            trigger: 'item',
            formatter: formatTooltip,
        },
        legend: {
            orient: 'vertical',
            top: 'center',
            right: '10%',
            itemWidth: 28,
            itemHeight: 17,
            textStyle: {
                overflow: 'break',
                fontSize: 13,
                padding: [0, 0, 0, 10],
            },
        },
        series: [
            {
                type: 'pie',
                radius: ['35%', '85%'],
                avoidLabelOverlap: false,
                label: {
                    show: false,
                    position: 'center',
                },
                labelLine: {
                    show: false,
                },
                data: formattedData,
                right: '55%',
            },
        ],
    }

    return (
        <ReactECharts
            echarts={echarts}
            option={option}
            notMerge={true}
            lazyUpdate={true}
            className={'row no-margin'}
            style={{ height: 200, minWidth: 500 }}
        />
    )
}

ManagementUnitsPARChart.propTypes = {
    datas: PropTypes.array,
}