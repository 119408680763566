
module.exports = {
    RECEIVE_CAMPAIGN: 'RECEIVE_CAMPAIGN',
    RECEIVE_CAMPAIGN_PROGRESS: 'RECEIVE_CAMPAIGN_PROGRESS',
    RECEIVE_CAMPAIGN_STATIONS: 'RECEIVE_CAMPAIGN_STATIONS',
    RECEIVE_CAMPAIGN_EVENTS: 'RECEIVE_CAMPAIGN_EVENTS',
    RECEIVE_CAMPAIGN_OPERATIONS: 'RECEIVE_CAMPAIGN_OPERATIONS',
    RECEIVE_CAMPAIGN_PARAMETERS: 'RECEIVE_CAMPAIGN_PARAMETERS',
    RECEIVE_CAMPAIGN_PLANNINGS: 'RECEIVE_CAMPAIGN_PLANNINGS',
    RECEIVE_CAMPAIGN_MATERIELS: 'RECEIVE_CAMPAIGN_MATERIELS',
    RECEIVE_EDILABO_FILES: 'RECEIVE_EDILABO_FILES',
    RECEIVE_CAMPAIGN_FILES: 'RECEIVE_CAMPAIGN_FILES',
    RECEIVE_ALL_CAMPAIGNS_ACTIONS: 'RECEIVE_ALL_CAMPAIGNS_ACTIONS',
    RECEIVE_ALL_CAMPAIGNS_ACTIONS_WITH_STATS: 'RECEIVE_ALL_CAMPAIGNS_ACTIONS_WITH_STATS',
    RESET_CAMPAIGN: 'RESET_CAMPAIGN',
    MAP_PANEL: 'MAP_PANEL',
    LIST_PANEL: 'LIST_PANEL',
    STATION_PANEL: 'STATION_PANEL',
    PARAMETER_PANEL: 'PARAMETER_PANEL',
    PLANNING_PANEL: 'PLANNING_PANEL',
    RECEIVE_CAMPAIGN_VISITS: 'RECEIVE_CAMPAIGN_VISITS',
    DELETE_VISIT: 'DELETE_VISIT',
    NB_ELEM_PER_PAGE: 100,

    CAMPAIGN_TYPES: [
        { code: 1, label: 'preventive' },
        { code: 2, label: 'curative' },
        { code: 3, label: 'other' },
    ],
    CAMPAIGN_STATUT: [
        { code: 1, label: 'inAnticipation' },
        { code: 2, label: 'inProgress' },
    ],

    CAMPAIGN_STATUT_FOR_FILTER: [
        { label: 'inAnticipation', code: 1 },
        { label: 'inProgress', code: 2 },
        { label: 'finalize', code: 3 },
        { label: 'archived', code: 4 },
    ],

    PIEZOMETRY_CAMPAIGN_TYPES: [
        { code: 1, label: 'preventive' },
        { code: 2, label: 'curative' },
        { code: 3, label: 'other' },
    ],

    QUALITY_CAMPAIGN_TYPES: [
        { code: 2, label: 'hydrobio' },
        { code: 3, label: 'chemicalPhysics' },
    ],

    QUALITY_CAMPAIGN_TYPES_CONSTANTS: {
        HYDROBIO: 2,
        CHEMICAL_PHYSICS: 3,
    },

    QUALITY_CAMPAIGN_REQUEST: [
        { code: 1, label: 'sampleRequest' },
        { code: 2, label: 'analysisRequest' },
        { code: 3, label: 'mixedRequest' },
    ],

    PIEZOMETERY_SITUATION_TABLE_HEADERS: ['nullValue', 'code', 'name', 'city', 'type', 'visitsCount', 'state', 'nullValue2'],
    PIEZOMETERY_DASHBOARD_TABLE_HEADERS: ['code', 'city', 'name', 'contact', 'access', 'order', 'executed'],
}
