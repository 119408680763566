/* eslint-disable max-nested-callbacks */
import { countBy, groupBy, keyBy, uniqBy } from 'lodash'
import PropTypes from 'prop-types'
import React, { useEffect, useMemo, useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import Card from '../../../components/card/Card'
import Checkbox from '../../../components/forms/Checkbox'
import SelectionSelect from '../../../components/forms/specific/SelectionSelect'
import { STATION_QUALITOMETER_NAMES } from '../../../station/constants/StationConstants'
import { calculateAverage, searchMaxValue, searchMinValue, searchP90Value } from '../../../utils/AnalyseUtils'
import useActions from 'utils/customHook/useActions'
import { getLabel } from '../../../utils/StoreUtils'
import AEPAnalysisTable from './AEPAnalysisTable'
import { hasValue } from 'utils/NumberUtil'
import DtoQualityThreshold from 'quality/dto/QualityThreshold/DtoQualityThreshold'
import useApplicationSetting from 'utils/customHook/useApplicationSetting'
import useAccountSetting from 'utils/customHook/useAccountSetting'
import { SELECTION } from 'quality/constants/QualityConstants'

const DEFAULT_SELECTION = '-1'

const AEPwaterTypePanel = ({
    filter = {},
    onExport = () => { },
    thresholdList = [],
    analysis = [],
}) => {
    const {
        qualitometers,
        qualityThresholds,
        parametersIndex,
        unitsIndex,
        settings,
        producers,
    } = useSelector(store => ({
        qualitometers: store.QualityReducer.qualitometersLight,
        qualityThresholds: store.QualityReducer.qualityThresholds,
        parametersIndex: store.ParameterReducer.parametersIndex,
        unitsIndex: store.UnitReducer.unitsIndex,
        settings: store.AdministrationReducer.settings,
        producers: store.ContributorReducer.producers,
    }), shallowEqual)

    const settingSelection = useApplicationSetting(`SELECTION_FILTERS_${SELECTION.PC}`)
    const accountSettingSelection = useAccountSetting(`SELECTION_FILTERS_${SELECTION.PC}`)

    const [selection, setSelection] = useState(accountSettingSelection ?? settingSelection ?? DEFAULT_SELECTION)
    const [selectionResults, setSelectionResults] = useState([])
    const [displayAllParameters, setDisplayAllParameters] = useState(false)

    useEffect(() => {
        setSelection(accountSettingSelection ?? settingSelection ?? DEFAULT_SELECTION)
    }, [settingSelection, accountSettingSelection])

    const qualitometersObject = useMemo(() => {
        return keyBy(qualitometers, 'id')
    }, [qualitometers])

    const analysisFilteredByThreshold = useMemo(() => {
        return analysis.filter(a => thresholdList.some(t => !t.parameterCode || t.parameterCode === a.parameter))
    }, [analysis, thresholdList])

    const filteredAnalysis = useMemo(() => {
        return selection !== DEFAULT_SELECTION ? analysisFilteredByThreshold.filter(({ parameter }) => selectionResults.includes(parameter)) : analysisFilteredByThreshold
    }, [analysisFilteredByThreshold, selection, selectionResults])

    const analysisGroupStationType = useMemo(() => {
        return groupBy(filteredAnalysis, a => qualitometersObject[a.qualitometer]?.stationType)
    }, [filteredAnalysis, qualitometersObject])

    const stationTypes = useMemo(() => {
        return Object.keys(analysisGroupStationType)
    }, [analysisGroupStationType])

    useActions(() => ({
        exportList: [{
            onClick: () => {
                const formatedData = stationTypes.flatMap(stationType => {
                    const analysisList = analysisGroupStationType[stationType]
                    const producerIds = uniqBy(analysisList, 'producer').map(a => a.producer)
                    const groupedAnalysis = groupBy(analysisList, 'parameter')
                    return Object.keys(groupedAnalysis).map(param => {
                        const { [param]: listAnalysis = [] } = groupedAnalysis
                        const listAnalysisResult = listAnalysis.filter(a => a.color === 'red')
                        const analysisWithValues = listAnalysis.filter(a => hasValue(a.result) && hasValue(a.value) && (a.status == 2 || a.status == 3))

                        const countTotalProducer = countBy(listAnalysis, 'producer')
                        const countResultProducer = countBy(listAnalysisResult, 'producer')

                        const producersData = producerIds.reduce((acc, id) => {
                            const result = countResultProducer[id] || 0
                            const total = countTotalProducer[id] || 0

                            const producer = producers.find(p => p.id === id)
                            const label = producer?.mnemonique || producer?.name || i18n.unknownProducer

                            acc[`${label} ${i18n.nc}`] = { value: result, format: '0', cellType: 'number' }
                            acc[`${label} ${i18n.analysis}`] = { value: total, format: '0', cellType: 'number' }
                            return acc
                        }, {})

                        return {
                            stationType: getLabel(STATION_QUALITOMETER_NAMES, stationType),
                            parameterCode: { value: param, cellType: 'right' },
                            parameter: parametersIndex[param].shortLabel || parametersIndex[param].name,
                            codeUnit: { value: listAnalysis[0].unit, cellType: 'right' },
                            unit: unitsIndex[listAnalysis[0].unit]?.symbol,
                            min: { value: searchMinValue(analysisWithValues), cellType: 'right' },
                            average: { value: calculateAverage(analysisWithValues, settings), cellType: 'right' },
                            percentile90: { value: searchP90Value(analysisWithValues), cellType: 'right' },
                            max: { value: searchMaxValue(analysisWithValues), cellType: 'right' },
                            noncompliances: { value: listAnalysisResult.length, format: '0', cellType: 'number' },
                            nbAnalysis: { value: listAnalysis.length, format: '0', cellType: 'number' },
                            ...producersData,
                        }
                    })
                })

                const filteredData = displayAllParameters ? formatedData : formatedData.filter(obj => obj.noncompliances.value !== 0)
                const data = filteredData.length ? [{ ...filteredData[0], headers: Object.keys(filteredData[0]) }, ...filteredData.slice(1)] : []
                onExport({
                    data,
                    exportType: 'xlsx',
                    titleFile: `${i18n.AEPoverview} ${i18n.waterType}`,
                })
            },
            label: i18n.excel,
        }],
    }), [filter.referenceThreshold, filter.limitThreshold, qualityThresholds])

    return (
        <div>
            <Card>
                <div className='row no-margin padding-top-1 padding-bottom-1 valign-wrapper'>
                    <SelectionSelect
                        col={8}
                        value={selection}
                        onChange={(res, s) => {
                            setSelection(s)
                            setSelectionResults(res)
                        }}
                    />
                    <div className='col s4' >
                        <Checkbox
                            label={i18n.displayAllParameters}
                            checked={displayAllParameters}
                            onChange={setDisplayAllParameters}
                        />
                    </div>
                </div>
            </Card>
            {
                stationTypes.map(type => (
                    <AEPAnalysisTable
                        title={getLabel(STATION_QUALITOMETER_NAMES, type) || i18n.unknownType}
                        analysis={analysisGroupStationType[type]}
                        displayAllParameters={displayAllParameters}
                        key={`analysisTable:${type}`}
                        thresholdList={thresholdList}
                    />
                ))
            }
            {
                !filteredAnalysis.length && (
                    <div className='row no-margin padding-top-1 padding-left-3 padding-bottom-1' >
                        <h5>{i18n.noResults}</h5>
                    </div>
                )
            }
        </div>
    )
}

AEPwaterTypePanel.propTypes = {
    filter: PropTypes.shape({
        referenceThreshold: PropTypes.number,
        limitThreshold: PropTypes.number,
        year: PropTypes.number,
    }),
    onExport: PropTypes.func,
    thresholdList: PropTypes.arrayOf(PropTypes.instanceOf(DtoQualityThreshold)),
    analysis: PropTypes.arrayOf(PropTypes.shape({
        // DtoAnalysisLight
        // calculateThresholdResult
        producer: PropTypes.number,
    })),
}

export default AEPwaterTypePanel