import User from 'account/dto/User'
import SuperMultiAutocomplete from 'components/forms/SuperMultiAutocomplete'
import moment from 'moment'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import MultiContactsAutocomplete from 'referencial/components/contact/components/MultiContactsAutocomplete'
import MultiContributorsAutocomplete from 'referencial/components/contributor/components/MultiContributorsAutocomplete'
import i18n from 'simple-react-i18n'
import useSandreList from 'utils/customHook/useSandreList'
import { authorizeContributors, authorizeFilter } from 'utils/HabilitationUtil'
import Input from '../../../../components/forms/Input'
import RadioButtons from '../../../../components/forms/RadioButtons'
import SimpleDatePicker from '../../../../components/forms/SimpleDatePicker'
import Textarea from '../../../../components/forms/Textarea'
import ContactDto from '../../../../referencial/components/contact/dto/ContactDto'
import ContributorItem from '../../../../referencial/components/contributor/dto/ContributorItem'
import { getCampaignType } from '../../../../utils/CampaignUtil'
import { getDate } from '../../../../utils/DateUtil'
import { hasValue } from '../../../../utils/NumberUtil'
import { i18nize } from '../../../../utils/StringUtil'
import {
    QUALITY_CAMPAIGN_REQUEST,
    QUALITY_CAMPAIGN_TYPES,
} from '../../../constants/CampaignConstants'


const FormQualityCampaign = ({
    campaign = {},
    disabled = {},
    size = '',
    onChangeCampaign = () => { },

    contacts = [],
    contributors = [],
    accountUser = {},
}) => {
    const {
        beginningApplication,
        endingApplication,
        requestType,
        name,
        campaignType,
        statut,
        contactCode,
        contributorCode,
        recipientCode,
        payerCode,
        samplerCode,
        marketRef,
        comment,
    } = campaign
    const startDate = beginningApplication && moment(beginningApplication, 'DD/MM/YYYY').valueOf()
    const endDate = endingApplication && moment(endingApplication, 'DD/MM/YYYY').valueOf()

    const campaignStatus = useSandreList('CAMPAGNES.STATUT')

    return (
        <div className={size}>
            <div className='row no-margin padding-top-3-px'>
                <Input
                    col={6}
                    title={i18n.name}
                    value={name}
                    onChange={value => onChangeCampaign({ name: value })}
                    {...disabled}
                />
                <SimpleDatePicker
                    id='beginningApplication'
                    label={i18n.startDate}
                    value={startDate}
                    onChange={v => onChangeCampaign({ beginningApplication: getDate(v) !== '' ? getDate(v) : null })}
                    col={3}
                    max={endDate}
                    {...disabled}
                />
                <SimpleDatePicker
                    id='endingApplication'
                    label={i18n.endDate}
                    value={endDate}
                    onChange={v => onChangeCampaign({ endingApplication: getDate(v) !== '' ? getDate(v) : null })}
                    col={3}
                    min={startDate}
                    {...disabled}
                />
            </div>
            <div className='row no-margin padding-top-3-px'>
                <RadioButtons
                    elements={i18nize(QUALITY_CAMPAIGN_TYPES)}
                    col={6}
                    {...disabled}
                    title={i18n.campaignType}
                    selected={getCampaignType(campaignType)}
                    onChange={e => onChangeCampaign({ campaignType: e })}
                />
                <SuperMultiAutocomplete
                    col={3}
                    options={i18nize(QUALITY_CAMPAIGN_REQUEST)}
                    values={parseInt(requestType)}
                    label={i18n.requestType}
                    keyLabel='label'
                    keyValue='code'
                    onChange={value => onChangeCampaign({ requestType: value ? `${value}` : null })}
                    {...disabled}
                />
                <SuperMultiAutocomplete
                    col={3}
                    options={campaignStatus}
                    values={statut}
                    label={i18n.status}
                    keyValue='code'
                    onChange={value => onChangeCampaign({ statut: value })}
                    {...disabled}
                />
            </div>
            <div className='row no-margin padding-top-3-px'>
                <MultiContributorsAutocomplete
                    col={4}
                    label={i18n.laboratory}
                    options={authorizeContributors(contributors, accountUser, parseInt(recipientCode))}
                    onChange={id => onChangeCampaign({ recipientCode: hasValue(id) && `${id}` || undefined })}
                    values={parseInt(recipientCode)}
                    displayWithCode
                    keyLabel='labelDisplay'
                    {...disabled}
                />
                <MultiContributorsAutocomplete
                    col={4}
                    label={i18n.sampler}
                    options={authorizeContributors(contributors, accountUser, parseInt(samplerCode))}
                    onChange={id => onChangeCampaign({ samplerCode: hasValue(id) && `${id}` || undefined })}
                    values={parseInt(samplerCode)}
                    displayWithCode
                    keyLabel='labelDisplay'
                    {...disabled}
                />
                <MultiContributorsAutocomplete
                    col={4}
                    label={i18n.producer}
                    options={authorizeContributors(contributors, accountUser, parseInt(contributorCode))}
                    onChange={id => onChangeCampaign({ contributorCode: hasValue(id) && `${id}` || undefined })}
                    values={parseInt(contributorCode)}
                    displayWithCode
                    keyLabel='labelDisplay'
                    {...disabled}
                />
            </div>
            <div className='row no-margin padding-top-3-px'>
                <MultiContactsAutocomplete
                    col={4}
                    label={i18n.contact}
                    options={authorizeFilter(contacts, accountUser, contactCode, 'contributor')}
                    onChange={(id) => onChangeCampaign({ contactCode: id })}
                    values={contactCode}
                    displayWithCode
                    keyLabel='name'
                    {...disabled}
                />
                <MultiContributorsAutocomplete
                    col={4}
                    label={i18n.payer}
                    options={authorizeContributors(contributors, accountUser, parseInt(payerCode))}
                    onChange={id => onChangeCampaign({ payerCode: hasValue(id) && `${id}` || undefined })}
                    values={parseInt(payerCode)}
                    displayWithCode
                    keyLabel='labelDisplay'
                    {...disabled}
                />
                <Input
                    title={i18n.offerCall}
                    col={4}
                    value={marketRef}
                    onChange={value => onChangeCampaign({ marketRef: value })}
                    {...disabled}
                />
            </div>
            <div className='row no-margin padding-top-3-px'>
                <Textarea
                    col={12}
                    title={i18n.comment}
                    value={comment}
                    onChange={value => onChangeCampaign({ comment: value })}
                    {...disabled}
                />
            </div>
        </div>
    )
}

FormQualityCampaign.propTypes = {
    campaign: PropTypes.shape({
        beginningApplication: PropTypes.string,
        endingApplication: PropTypes.string,
        name: PropTypes.string,
        campaignType: PropTypes.number,
        statut: PropTypes.number,
        contactCode: PropTypes.number,
        contributorCode: PropTypes.string,
        recipientCode: PropTypes.string,
        samplerCode: PropTypes.string,
        payerCode: PropTypes.string,
        marketRef: PropTypes.string,
        comment: PropTypes.string,
    }),
    disabled: PropTypes.shape({
        active: PropTypes.bool,
        disabled: PropTypes.bool,
    }),
    onChangeCampaign: PropTypes.func,
    size: PropTypes.string,

    contacts: PropTypes.arrayOf(PropTypes.instanceOf(ContactDto)),
    contributors: PropTypes.arrayOf(PropTypes.instanceOf(ContributorItem)),
    analysisSupports: PropTypes.arrayOf(PropTypes.object),
    accountUser: PropTypes.instanceOf(User),
}

const mapStateToProps = store => ({
    contacts: store.ContactReducer.contacts,
    contributors: store.ContributorReducer.contributors,
    analysisSupports: store.SuivipcReducer.analysisSupports,
    accountUser: store.AccountReducer.accountUser,
})

export default connect(mapStateToProps)(FormQualityCampaign)