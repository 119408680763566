import laboIcon from 'assets/pictures/pictos/laboIcon.png'
import React from 'react'
import i18n from 'simple-react-i18n'
import AppStore from 'store/AppStore'
import { sieauTooltip } from './FormUtils'

const getExploitationAEP = (settingsProps) => {
    const found = settingsProps.find(s => s.parameter === 'ExploitationAEP')
    if (found) {
        return found.value === 'True' || found.value === 'OUI'
    }
    return false
}

const getLogin = () => {
    return localStorage.getItem('SIEAU_LOGIN')
}

const getUser = () => {
    return { ...(AppStore.getState().UserReducer.users.find(u => u.login === getLogin()) || {}), ...AppStore.getState().AccountReducer.accountUser }
}

const getSetting = (settingList, parameter) => {
    const found = settingList.find(s => s.parameter === parameter)
    return found ? found.value : null
}

const getSettingBool = (settingList, parameter) => {
    const found = settingList.find(s => s.parameter === parameter)
    return found && found.value ? found.value === 'true' : null
}

const getSettingInt = (settingList, parameter) => {
    const found = settingList.find(s => s.parameter === parameter)
    return found && parseInt(found.value) ? parseInt(found.value) : null
}

const getSettingFloat = (settingList, parameter) => {
    const found = settingList.find(s => s.parameter === parameter)
    return found && parseFloat(found.value) ? parseFloat(found.value) : null
}

const getSettingJson = (settingList, parameter) => {
    try {
        const found = settingList.find(s => s.parameter === parameter)
        return found ? JSON.parse(found.value) : null
    } catch (e) {
        return null
    }
}

const getIconsUser = (user, maximumNumberTry) => {
    const showedAdmin = user.isAdmin === '1' || user.admin === '1' ? (
        <i key={1} className='material-icons small center-align' style={{ color: '#161832' }}
            { ...sieauTooltip(i18n.admin, null, 'bottom') }
        >school</i>) : null
    const showedBlocked = user.consultant === '1' ? (
        <i key={2} className='material-icons small center-align' style={{ color: '#161832' }}
            { ...sieauTooltip(i18n.consultant, null, 'bottom') }
        >remove_red_eye</i>) : null
    const showedMetadata = user.metadata === '1' ? (
        <i key={3} className='material-icons small center-align' style={{ color: '#161832' }}
            { ...sieauTooltip(i18n.referencialManager, null, 'bottom') }
        >folder</i>) : null
    const blockedUser = user.connexionAttempts >= maximumNumberTry ? (
        <i key={4} className='material-icons small center-align' style={{ color: '#161832' }}
            { ...sieauTooltip(i18n.blockedUser, null, 'bottom') }
        >lock</i>) : null
    const showedLabo = user.labo === '1' ? (
        <img key={5} src={ laboIcon } className='responsive-img' { ...sieauTooltip(i18n.technicien, null, 'bottom') }
            style={ { width: '2rem' } }
        />) : null

    const showedConsulting = ((!user.isAdmin && !user.admin) || (user.isAdmin === '0' && user.admin === '0')) && (!user.labo || user.labo === '0') && (!user.metadata || user.metadata === '0') ? (
        <i key={6} className='material-icons small center-align' style={{ color: '#161832' }}
            { ...sieauTooltip(i18n.consultant, null, 'bottom') }
        >visibility</i>) : null

    return [showedAdmin, showedBlocked, showedMetadata, showedLabo, blockedUser, showedConsulting].filter(i => !!i)
}

const getProfileCard = (user, maximumNumberTry) => {
    const showedPicture = user.picture ? (
        <img src={ user.picture } style={{ height: '7rem', width: '7rem', objectFit: 'cover', marginTop: '1.5rem', marginBottom: '27px' }} className='circle responsive-img row'/>)
        : (<i className='material-icons profilePicture row'>account_circle</i>)

    const icons = getIconsUser(user, maximumNumberTry)
    return (
        <div>
            <div className='row no-margin '>
                <div className='col s12 center-align no-padding'>
                    { showedPicture }
                </div>
                <div className='col s12 center-align'>
                    { icons }
                </div>
            </div>
            <div className='row no-margin'>
                <div className='col s12 center-align'>
                    { user.login }
                </div>
            </div>
            <div className='row no-margin'>
                <div className='col s12 center-align large no-padding'>
                    { user.name }
                </div>
            </div>
            <div className='row no-margin'>
                <div className='col s12 center-align large no-padding'>
                    { user.mnemonique }
                </div>
            </div>
            <div className='row'>
                <div className='col s12 center-align'>
                    { user.lastConnexion }
                </div>
            </div>
        </div>
    )
}

const isDeveloppmentEnv = () => {
    return process.env.NODE_ENV !== 'production'
}

const hasHabilitation = (hab, checkAdmin=true) => {
    if (checkAdmin && getUser().isAdmin === '1') {
        return true
    }
    return !!AppStore.getState().AccountReducer.accountHabilitations.find(h => h.habilitation === hab)
}

// fromStationType: 'HYDRO', 'PLUVIO', ou 'PIEZO' ou 'INST' --> le type de station sur lequel vous êtes
// toStationType: 'HYDRO', 'PLUVIO', ou 'PIEZO' ou 'INST' --> le type de station associée que l'on doit afficher (ou pas)
// dataType: le type de donnée complémentaire à afficher ou pas ('all' pour spécifier tous les types de données complémentaires pour ce type de station)
const canShowStationTypeData = (fromStationType, toStationType, dataType = 'all', withRules) => {
    const rules = withRules ? withRules : getSettingJson(AppStore.getState().AdministrationReducer.applicationSettings, 'showDataTypesRules')
    const foundAll = rules?.[fromStationType]?.[toStationType]?.all
    const resultAll = foundAll === undefined ? true : foundAll
    const found = rules?.[fromStationType]?.[toStationType]?.[dataType]
    const resultData = found === undefined ? true : found
    return !!(resultData && resultAll)
}

export {
    getExploitationAEP, getLogin, getUser, getSetting, getSettingInt, getSettingFloat, getSettingBool,
    getSettingJson, getIconsUser, getProfileCard, isDeveloppmentEnv, hasHabilitation, canShowStationTypeData,
}
