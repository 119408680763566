import Row from '../../../../../components/react/Row'
import Button from '../../../../../components/forms/Button'
import Table from '../../../../../components/datatable/Table'
import { nbPerPageLabel } from '../../../../../referencial/constants/ReferencialConstants'
import React, { useEffect, useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { Dialog, DialogContent, DialogTitle } from '@mui/material'
import i18n from 'simple-react-i18n'
import SelectStationComponent from '../../../../../components/modal/SelectStationComponent'
import { genericPromise } from '../../../../../utils/ActionUtils'
import { path } from '../../../../../conf/basepath'
import AppStore from '../../../../../store/AppStore'
import PiezometryAction from '../../../../../piezometry/actions/PiezometryAction'
import NumberField from '../../../../../components/forms/NumberField'
import Line from '../../../../../components/echart/series/Line'
import Axis from '../../../../../components/echart/Axis'
import EChart from '../../../../../components/echart/EChart'
import StationLandmarkPanel from '../../../../../station/components/link/StationLandmarkPanel'
import { setConfirmationModal } from '../../../../../utils/FormUtils'
import { hasValue } from '../../../../../utils/NumberUtil'
import StationAltimetrySystemsPanel from '../../../../../station/components/link/StationAltimetrySystemsPanel'
import ToastrAction from '../../../../../toastr/actions/ToastrAction'
import { yAutomaticScaleTab } from '../../../../../components/echart/EChartUtils'

const getChart = (p) => {
    if (!p) {
        return null
    }
    const yScale = yAutomaticScaleTab(p.actualMeasures, p.correctedMeasures)
    const options = {
        series: [Line({
            data: p.actualMeasures,
            name: i18n.chronic,
            connectNulls: false,
            showSymbol: false,
            color: '#222',
            serieId: 'bruteMeasures',
            markLine: {
                symbol: 'diamond',
                label: { show: false },
                lineStyle: {
                    type: 'solid',
                    width: 2,
                    color: '#999',
                },
                data: p.datesReperes.map(d => ({ xAxis: d })),
            },
        }), Line({
            data: p.correctedMeasures,
            name: 'mesures corrigées',
            connectNulls: false,
            showSymbol: false,
            color: '#335d00',
            serieId: 'corrected',
        })],
        title: '[Profondeur] recalcul des profondeurs à partir du bon NGF et du repère de la mesure',
        grid: [{
            top: 25,
            right: '2%',
            height: 210,
            left: 100,
        }],
        xAxis: [Axis({
            type: 'time',
            position: 'bottom',
            showSplitLine: true,
        })],
        yAxis: [Axis({
            type: 'value',
            nameLocation: 'middle',
            name: `${i18n.chronic} (m)`,
            nameGap: 40,
            inverse: true,
            showSplitLine: true,
            ...yScale
        })],
        axisPointer: {
            link: { xAxisIndex: 'all' },
        },
        setDataZoom: true,
        height: 250,
    }
    return (
        <EChart options={options} id='v6Chart' initialZoomValueStart={0} initialZoomValueEnd={100} />
    )
}

const CorrectV6App = ({}) => {
    const {
        piezometers,
        piezosIndex,
        networkPiezoLink,
    } = useSelector(store => ({
        piezometers: store.PiezometryReducer.piezometersLight,
        piezosIndex: store.PiezometryReducer.piezosIndex,
        networkPiezoLink: store.PiezometryReducer.networkPiezoLink,
    }), shallowEqual)
    const [dataLoading, setDataLoading] = useState(false)
    const [maxIdsToCheck, setMaxIdsToCheck] = useState(100)
    const [filterStations, setFilterStations] = useState([])
    const [corrections, setCorrections] = useState({})
    const [popupOpen, setPopupOpen] = useState(false)
    const [pbV6, setPbV6] = useState({}) // IdentPbV6Output
    const [selectedPb, setSelectedPb] = useState(undefined)

    useEffect(() => {
        if (!piezometers.length) {
            AppStore.dispatch(PiezometryAction.fetchPiezometersLight())
        }
    }, [])

    const loadIdentPbV6 = () => {
        setDataLoading(true)
        genericPromise(`${path}piezometer/dataTypes/correctV6`, 'POST', { mode: 'IDENT_PB_V6', stationIds: filterStations, maxIdsToCheck }).then(json => {
            setDataLoading(false)
            setPbV6(json)
        })
    }
    const loadSingleStationPb = (id) => {
        setDataLoading(true)
        genericPromise(`${path}piezometer/dataTypes/correctV6`, 'POST', { mode: 'SHOW_PB_V6', stationIds: [id] }).then(json => {
            setDataLoading(false)
            setSelectedPb(json)
        })
    }
    const loadCorrectRepere = (ids) => {
        setDataLoading(true)
        genericPromise(`${path}piezometer/dataTypes/correctV6`, 'POST', { mode: 'CORRECT_PB_V6', stationIds: ids }).then(json => {
            setDataLoading(false)
            AppStore.dispatch(ToastrAction.success('Corrections effectuées avec succès !'))
            setCorrections(json.reduce((acc, obj) => {
                acc[obj[0]] = obj[1]
                return acc
            }, {}))
        })
    }

    const loadReinit = () => {
        genericPromise(`${path}piezometer/dataTypes/correctV6`, 'POST', { mode: 'REINIT_CHECK_V6', stationIds: [] }).then(() => {
            AppStore.dispatch(ToastrAction.success('Table piezometres_superpositions_parametres réinitialisée avec succès !'))
        })
    }

    const foundPiezos = (pbV6?.stations || []).map(([id, nbReperes]) => ({ ...piezosIndex[id], corrections: hasValue(corrections[id]) ? corrections[id] : '', nbReperes }))

    return (
        <div>
            <Row>
                <Button col={3} title='Filtrer les stations' onClick={() => setPopupOpen(true)}/>
                <div className='col s3'>{filterStations.length} stations filtrées</div>
                <NumberField title='Nb Max de stations à checker' col={3} value={maxIdsToCheck} onChange={setMaxIdsToCheck}/>
                <Button col={3} title='Lancer la recherche' onClick={loadIdentPbV6}/>
            </Row>
            {
                dataLoading && <h5>En cours de calcul ...</h5>
            }
            { pbV6 ? (
                <Row>
                    <div className='col s6'>
                        Nb de stations checkées : {pbV6?.nbStationsCheckees || 0}
                    </div>
                    <div className='col s6'>
                        Nb de stations avec au moins 2 repères : {pbV6?.nbStationsAvec2Reperes || 0}
                    </div>
                </Row>
            ) : null }
            <Row>
                <Table
                    title='Stations concernées'
                    condensed
                    data={foundPiezos}
                    type={{ headers: ['id', 'code', 'name', 'nbReperes', 'corrections'] }}
                    customHeaders={{
                        nbReperes: 'Nb repères',
                        corrections: 'Corrections',
                    }}
                    sortable
                    onClick={p => loadSingleStationPb(p.id)}
                    paging
                    nbPerPageLabel={nbPerPageLabel}
                />
            </Row>
            <Row>
                { getChart(selectedPb) }
            </Row>
            {
                selectedPb ? (
                    <Row className='padding-1 no-padding-bottom'>
                        <StationLandmarkPanel station={ { link_landmarks: selectedPb.reperes, link_altimetrySystems: selectedPb.sysAltis } } className='blue' readMode={true}/>
                    </Row>
                ) : null
            }
            {
                selectedPb ? (
                    <Row className='padding-1 no-padding-bottom'>
                        <StationAltimetrySystemsPanel station={ { link_altimetrySystems: selectedPb.sysAltis } } className='blue' readMode={true}/>
                    </Row>
                ) : null
            }
            <Row>
                <Button col={4} title='!!! Appliquer la correction !!!' onClick={() => setConfirmationModal(() => loadCorrectRepere([selectedPb.stationId]), 'Confirmer la correction ?')}/>
                <Button col={4} title='!!!!!!! Corriger tous les points affichés !!!!!!!' onClick={() => setConfirmationModal(() => loadCorrectRepere((pbV6?.stations || []).map(([id, rest]) => id)), 'Confirmer la correction ?')}/>
                <Button col={4} title='Réinitialiser les stations checkées' onClick={() => setConfirmationModal(() => loadReinit(), 'Confirmer la réinitialisation ?')}/>
            </Row>
            <Dialog
                onClose={() => setPopupOpen(false)}
                fullWidth
                maxWidth='lg'
                open={popupOpen}
                classes='no-padding'
                scroll='body'
            >
                <DialogTitle>{i18n.calculationParams}</DialogTitle>
                <DialogContent>
                    <SelectStationComponent
                        selectedStations={filterStations}
                        campaignEvents={[]}
                        stationType='piezometry'
                        onChangeSelectedStation={tmpStations => setFilterStations(tmpStations)}
                        networkLinks={networkPiezoLink}
                        // minHeight='30vh'
                    />
                </DialogContent>
            </Dialog>
        </div>
    )
}

CorrectV6App.propTypes = {
}

export default CorrectV6App
