module.exports = {
    links: {
        dashboard: {
            icons: 'dashboard',
            i18n: 'dashboard',
            id: 'station_dashboard',
        },
        description: {
            icons: 'compare_arrows',
            i18n: 'descriptive',
            id: 'station_descriptive',
        },
        event: {
            icons: 'event',
            i18n: 'events',
            id: 'station_event',
        },
        picture: {
            icons: 'perm_media',
            i18n: 'picturesAndDocuments',
            id: 'station_picture_and_document',
        },
        associated: {
            icons: 'receipt',
            i18n: 'associations',
        },
        operation: {
            icons: 'multiline_chart',
            i18n: 'operations',
            id: 'station_operation',
        },
        followUpPC: {
            icons: 'assignment_turned_in',
            i18n: 'followUpPC',
        },
        suiviPC: {
            icons: 'assignment_turned_in',
            i18n: 'followUpPC',
            id: 'station_follow_PC',
        },
        pcMonitoring: {
            icons: 'assignment_turned_in',
            i18n: 'followUpPC',
            id: 'pcMonitoring',
        },
        hydrobioOperation: {
            icons: 'bug_report',
            i18n: 'hydrobioOperations',
            id: 'hydrobioOperation',
        },
        hydrobioMonitoring: {
            icons: 'assignment',
            i18n: 'hydrobioMonitoring',
            id: 'hydrobioMonitoring',
        },
        conformityOverview: {
            icons: 'playlist_add_check',
            i18n: 'AEPoverview',
            id: 'conformity_overview',
        },
        pluviometricFollowUp: {
            icons: 'insert_chart',
            i18n: 'pluviometricSuivi',
        },
        pluviometricSuiviTable: {
            icons: 'reorder',
            i18n: 'followUpTable',
        },
        piezometricFollowUp: {
            icons: 'insert_chart',
            i18n: 'piezometrySuivi',
        },
        piezoSuiviTable: {
            icons: 'reorder',
            i18n: 'followUpTable',
        },
        hydrometricFollowUp: {
            icons: 'insert_chart',
            i18n: 'graphicTracking',
        },
        hydroSuiviTable: {
            icons: 'reorder',
            i18n: 'followUpTable',
        },
        observations: {
            icons: 'remove_red_eye',
            i18n: 'observations',
        },
        installationFollowUp: {
            icons: 'assignment_turned_in',
            i18n: 'tracking',
        },
        // installationAEPIndicators: {
        //     icons: 'table_chart',
        //     i18n: 'AEPIndicators',
        // },
        validationData: {
            icons: 'insert_drive_file',
            i18n: 'validationData',
        },
        graphic: {
            icons: 'insert_chart',
            i18n: 'graphics',
        },
        materiel: {
            icons: 'router',
            i18n: 'materiels',
            id: 'station_materiels',
        },
        validationHydro: {
            icons: 'border_color',
            i18n: 'validation',
        },
        validationPluvio: {
            icons: 'border_color',
            i18n: 'validation',
        },
        validation: {
            icons: 'border_color',
            i18n: 'validation',
        },
        qualityStates: {
            icons: 'class',
            i18n: 'states',
            id: 'quality_states',
        },
        STEPTerritory: {
            icons: 'insert_chart',
            i18n: 'emissions',
        },
        STEPPollutionSearch: {
            icons: 'bubble_chart',
            i18n: 'pollutionSearch',
        },
        export: {
            icons: 'file_download',
            i18n: 'exportName',
        },
        iaeauModels: {
            icons: 'extension',
            i18n: 'models',
        },
        map: {
            icons: 'map',
            i18n: 'cartography',
        },
        parameter: {
            icons: 'bubble_chart',
            i18n: 'parameter',
        },
        comment: {
            icons: 'comment',
            i18n: 'comment',
        },
        state: {
            icons: 'invert_colors',
            i18n: 'state',
        },
        source: {
            icons: 'water',
            i18n: 'source',
        },
        counters: {
            icons: 'heat_pump',
            i18n: 'counters',
        },
    },
}
