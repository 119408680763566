import DtoMaterielAssignment from './DtoMaterielAssignment'

export default class DtoStationVariousMaterielAssignment extends DtoMaterielAssignment {
    constructor(obj) {
        super(obj)
        this.id = obj.idVariousSituation
        this.idVariousSituation = obj.idVariousSituation
        this.idVarious = obj.idVarious
        this.materielId = obj.idVarious
    }
}