import { push } from '@lagunovsky/redux-react-router'
import { groupBy, maxBy, orderBy } from 'lodash'
import i18n from 'simple-react-i18n'
import moment from 'moment'
import React, { useEffect, useMemo, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { CAMPAIGN_STATUT_FOR_FILTER } from '../../../campaign/constants/CampaignConstants'
import Card from '../../../components/card/Card'
import { NUMBER } from '../../../components/keyFigures/KeyFigureConstants'
import KeyFigurePanel from '../../../components/keyFigures/KeyFigurePanel'
import ReferencialAction from '../../../referencial/action/ReferencialAction'
import { SANDRE } from '../../../referencial/constants/ReferencialConstants'
import { isInstallationType } from '../../../utils/CampaignUtil'
import { getDate, getHour } from '../../../utils/DateUtil'
import { hasValue } from '../../../utils/NumberUtil'
import { getUser } from '../../../utils/SettingUtils'
import { getLabel, getSandreList } from '../../../utils/StoreUtils'
import { getI18nTitleData, getSandreLabel, i18nize } from '../../../utils/StringUtil'
import InstallationAction from '../../actions/InstallationAction'
import FormInstallationFilterCampaigns from './components/FormInstallationFilterCampaigns'
import ProgressHorizontalChart from './components/ProgressHorizontalChart'
import useActions from 'utils/customHook/useActions'
import { Grid } from '@mui/material'
import { WhiteCard } from 'components/styled/Card'
import CampaignAndActionTabs from './components/CampaignAndActionTabs'
import { SORT_BY } from 'station/constants/CampaignConstants'

const campaignHeaders = ['name', 'startDate', 'endDate', 'contact', 'updateDate', 'nbStations', 'nbStationsValidated', 'status']
const campaignActionHeaders = ['code', 'installationName', 'installationType', 'city', 'cityCode', 'campaignName', 'campaignStartDate',
    'responsible', 'comment', 'deadline', 'localisation', 'category', 'risk', 'interventionType', 'equipmentType', 'correctionDate', 'commentCorrection']

const InstallationDashboardCampaigns = () => {
    const dispatch = useDispatch()

    const {
        contacts,
        sandreCodes,
        campaignsActions,
        campaignsInstallationWithStats,
        installationsTypes,
    } = useSelector(store => ({
        contacts: store.ContactReducer.contacts,
        sandreCodes: store.ReferencialReducer.sandreCodes,
        campaignsActions: store.CampaignReducer.campaignsActions,
        campaignsInstallationWithStats: store.CampaignReducer.campaignsInstallationWithStats,
        installationsTypes: store.InstallationReducer.installationsTypes,
    }), shallowEqual)

    const [sortBy, setSortBy] = useState(SORT_BY.STATUS)

    const getCampaignExport = () => {
        if (sortBy === SORT_BY.ACTIONS) {
            const data = orderBy(campaignsActions, 'deadline').map(ca => ({
                ...ca,
                responsible: getSandreLabel(sandreCodes, SANDRE.VISITES_ACTIONS_RESPONSABILITE, ca.responsible),
                deadline: getSandreLabel(sandreCodes, SANDRE.VISITES_ACTIONS_ECHEANCE, ca.deadline),
                localisation: getSandreLabel(sandreCodes, SANDRE.VISITES_ACTIONS_SECTEUR, ca.localisation),
                category: getSandreLabel(sandreCodes, SANDRE.VISITES_ACTIONS_CATEGORIE, ca.category),
                risk: getSandreLabel(sandreCodes, SANDRE.VISITES_ACTIONS_RISQUE, ca.risk),
                interventionType: getSandreLabel(sandreCodes, SANDRE.VISITES_ACTIONS_TYPE_INTERVENTION, ca.interventionType),
                equipmentType: getSandreLabel(sandreCodes, isInstallationType(ca.installationType) && SANDRE.EQUIPEMENTS_TYPE_AEP || SANDRE.EQUIPEMENTS_TYPE_ASST, ca.equipmentType),
                installationType: (installationsTypes.find(i => i.id === ca.installationType) || {}).name,
                comment: ca.comment && ca.comment.replace('\n', '').replace('\r', '') || '',
                status: getLabel(i18nize(CAMPAIGN_STATUT_FOR_FILTER), ca.campaignStatus),
                correctionDate: getDate(ca.correctionDate),
                commentCorrection: ca.commentCorrection,
            }))

            return data.length ? data.map((d, i) => i === 0 ? { ...d, headers: campaignActionHeaders } : d) : []
        }

        const data = campaignsInstallationWithStats.sort((a, b) => moment(b.startDate, 'DD/MM/YYYY').valueOf() - moment(a.startDate, 'DD/MM/YYYY').valueOf()).map(c => ({
            ...c,
            nbStations: c.nbStation,
            nbStationsValidated: c.nbStationValidated,
            status: getLabel(i18nize(CAMPAIGN_STATUT_FOR_FILTER), c.campaignStatus),
            updateDate: getDate(c.updateDate),
            contact: hasValue(c.contactCode) ? getLabel(contacts, c.contactCode) : i18n.unknownReferent,
        }))

        return data.length ? data.map((d, i) => i === 0 ? { ...d, headers: campaignHeaders } : d) : []
    }

    useEffect(() => {
        if (!sandreCodes.length) {
            dispatch(ReferencialAction.fetchSandreCodes())
        }

        if (!installationsTypes.length) {
            dispatch(InstallationAction.fetchInstallationTypes())
        }
    }, [])

    useActions(() => {
        return getUser().consultant === '1' ? {
            export: () => ({
                data: getCampaignExport(),
                exportType: 'xlsx',
                titleFile: `${i18n.campaigns}_${i18n.installation}`,
            }),
        } : {
            new: () => dispatch(push('/installation/campaign/new/dashboard')),
            export: () => ({
                data: getCampaignExport(),
                exportType: 'xlsx',
                titleFile: `${i18n.campaigns}_${i18n.installation}`,
            }),
        }
    }, [campaignsInstallationWithStats, campaignsActions, sandreCodes, sortBy])

    const getKeyFigures = ({ nbCampaignNotDraft, nbCampaignValidated, nbStation }) => [{
        title: i18n.inProgress,
        value: nbCampaignNotDraft - nbCampaignValidated,
        typeValue: NUMBER,
    }, {
        title: i18n.total,
        value: campaignsInstallationWithStats.length,
        typeValue: NUMBER,
    }, {
        title: i18n.stations,
        value: nbStation,
        typeValue: NUMBER,
    }]

    const getLastUpdates = campaigns => {
        const filterCampaignUpdate = campaigns.filter(o => o.updateDate && o.name)
        const lastCampaignUpdate = maxBy(filterCampaignUpdate, 'updateDate')
        if (lastCampaignUpdate) {
            const updateLogin = lastCampaignUpdate.updateLogin ? lastCampaignUpdate.updateLogin : i18n.unknown
            const updateDate = getDate(lastCampaignUpdate.updateDate, 'DD/MM/YYYY')
            const updateHour = getHour(lastCampaignUpdate.updateDate, 'HH:mm')
            return (
                <div className='row no-margin'>
                    <div className='col s12'>
                        <h6>{i18n.lastUpdate} : {lastCampaignUpdate.name}</h6>
                        <p>{`${i18n.by} ${updateLogin} ${i18n.atDate} ${updateDate} ${i18n.atHour} ${updateHour}`}</p>
                    </div>
                </div>
            )
        }
        return null
    }

    const getStats = (sandreCode, key) => {
        return getSandreList(sandreCodes, sandreCode).map(s => ({
            name: s.name, value: campaignsActions.filter(la => la[key] === s.code).length,
        }))
    }

    const keyFigures = campaignsInstallationWithStats.reduce((acc, cp) => {
        const camp = campaignsInstallationWithStats.find(c => c.id === cp.id)
        return {
            nbStation: acc.nbStation + cp.nbStation,
            nbStationValidated: acc.nbStationValidated + cp.nbStationValidated,
            nbCampaignValidated: camp && camp.campaignStatus !== 1 && cp.nbStation !== 0 && cp.nbStation === cp.nbStationValidated ? acc.nbCampaignValidated + 1 : acc.nbCampaignValidated,
        }
    }, { nbStation: 0, nbStationValidated: 0, nbCampaignValidated: 0 })

    const nbCampaignNotDraft = campaignsInstallationWithStats.filter(c => c.campaignStatus !== 1).length
    const actionStats = [{
        name: i18n.concernedActions,
        stats: getStats(SANDRE.VISITES_ACTIONS_CATEGORIE, 'category'),
    }, {
        name: i18n.unclosedActions,
        stats: getStats(SANDRE.VISITES_ACTIONS_RESPONSABILITE, 'responsible'),
    }, {
        name: i18n.interventionDeadline,
        stats: getStats(SANDRE.VISITES_ACTIONS_ECHEANCE, 'deadline'),
    }]

    const [campaignProgressData, visitsProgressData] = useMemo(() => {
        const grouped = groupBy(campaignsInstallationWithStats, 'campaignStatus')
        const campaigns = Object.keys(grouped).map(g => ({
            tooltip: getLabel(i18nize(CAMPAIGN_STATUT_FOR_FILTER), g) || i18n.notDefined,
            value: grouped[g].length,
            colorCode: parseInt(g),
        }))
        const data = campaignsInstallationWithStats.reduce(((acc, v) => ({
            nbValidated: acc.nbValidated + v.nbStationValidated,
            nbStations: acc.nbStations + v.nbStation,
        })), { nbValidated: 0, nbStations: 0 })
        const visits = [{
            tooltip: i18n.nbInstallationsValidated,
            value: data.nbValidated,
            colorCode: 4,
        }, {
            tooltip: i18n.nbStations,
            value: data.nbStations - data.nbValidated,
            colorCode: 0,
        }]
        return [campaigns, visits]
    }, [campaignsInstallationWithStats])

    return (
        <Grid className='padding-1'>
            <Grid container spacing={1}>
                <Grid item xs={12} md={9}>
                    <FormInstallationFilterCampaigns sortBy={sortBy} />
                    <CampaignAndActionTabs
                        sortBy={sortBy}
                        setSortBy={setSortBy}
                    />
                </Grid>
                <Grid item xs={12} md={3}>
                    <Grid className='margin-bottom-1'>
                        <KeyFigurePanel
                            data={getKeyFigures({ ...keyFigures, nbCampaignNotDraft })}
                            showAllValues
                            noMargin
                        />
                    </Grid>
                    <Card className='margin-bottom-1' noMargin={false}>
                        {getLastUpdates(campaignsInstallationWithStats)}
                    </Card>
                    <WhiteCard title={i18n.campaignsDistribution} className={'margin-bottom-1'} noMargin={false}>
                        <ProgressHorizontalChart data={campaignProgressData} />
                    </WhiteCard>
                    <WhiteCard title={i18n.visitsProgress} className={'margin-bottom-1'} noMargin={false}>
                        <ProgressHorizontalChart data={visitsProgressData} />
                    </WhiteCard>
                    <WhiteCard title={`${i18n.actions} (${campaignsActions.length} ${getI18nTitleData(i18n.element, i18n.elements, campaignsActions)})`}>
                        <Grid container style={{ padding: '0 10 5' }}>
                            {
                                actionStats.map((as) => {
                                    return (
                                        <Grid style={{ marginTop: '5px' }} key={as.name} item xs={12}>
                                            <div className='bold' style={{ fontSize: '14.3px' }}>{as.name}</div>
                                            {
                                                as.stats.map(stat => (
                                                    <Grid container item xs={12} key={stat.name}>
                                                        <Grid item xs={7}>{stat.name}</Grid>
                                                        <Grid item xs={5} className='text-align-center'>{stat.value}</Grid>
                                                    </Grid>
                                                ))
                                            }
                                        </Grid>
                                    )
                                })
                            }
                        </Grid>
                    </WhiteCard>
                </Grid>
            </Grid>

        </Grid>
    )
}

InstallationDashboardCampaigns.propTypes = {
}

export default InstallationDashboardCampaigns
