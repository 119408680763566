import DtoPollutionSourceParameter from 'alerting/dto/pollution/DtoPollutionSourceParameter'
import {
    ADD_FOLLOW_MEASURES,
    ADD_FOLLOW_SITE,
    RECEIVE_POLLUTION_SOURCE_ACTIVITIES,
    RECEIVE_POLLUTION_SOURCE_PARAMETERS,
    RECEIVE_POLLUTION_SOURCE_PARAMETERS_FULL_DATA,
    RECEIVE_TEMP_FORMATTED_SITES,
    RESET_ALERT_STORE,
    RESET_FOLLOW,
    RESET_POLLUTION_SOURCE_ACTIVITES,
    RESET_POLLUTION_SOURCE_PARAMETERS,
    SET_THRESHOLD_PREVI_DATA,
} from '../constants/AlertConstants'
import DtoMeasure from '../dto/alert/DtoMeasure'
import PollutionSourceActivityDto from '../dto/pollution/PollutionSourceActivityDto'
import PollutionSourceParameterDto from '../dto/pollution/PollutionSourceParameterDto'

export const store = {
    followSite: {},
    followMeasures: [],
    pollutionSourceActivities: [],
    pollutionSourceParameters: [],
    pollutionSourceParametersFullData: [],
    thresholdPreviListData: {},
    tempFormattedSites: [],
}

export function AlertReducer(state = {}, action) {
    switch (action.type) {
        case RESET_ALERT_STORE:
            return {
                ...state,
                store,
            }
        case ADD_FOLLOW_SITE:
            return {
                ...state,
                followSite: action.followSite,
            }
        case ADD_FOLLOW_MEASURES:
            return {
                ...state,
                followMeasures: action.followMeasures.map(m => new DtoMeasure(m)),
            }
        case RESET_FOLLOW:
            return {
                ...state,
                followSite: {},
            }
        case RESET_POLLUTION_SOURCE_ACTIVITES:
            return {
                ...state,
                pollutionSourceActivities: [],
            }
        case RECEIVE_POLLUTION_SOURCE_ACTIVITIES:
            return {
                ...state,
                pollutionSourceActivities: action.payload.map(d => new PollutionSourceActivityDto(d)),
            }
        case RESET_POLLUTION_SOURCE_PARAMETERS:
            return {
                ...state,
                pollutionSourceParameters: [],
            }
        case RECEIVE_POLLUTION_SOURCE_PARAMETERS:
            return {
                ...state,
                pollutionSourceParameters: action.data.map(d => new PollutionSourceParameterDto(d)),
            }
        case RECEIVE_POLLUTION_SOURCE_PARAMETERS_FULL_DATA:
            return {
                ...state,
                pollutionSourceParametersFullData: action.data.map(d => new DtoPollutionSourceParameter(d)),
            }
        case RECEIVE_TEMP_FORMATTED_SITES:
            return {
                ...state,
                tempFormattedSites: action.tempFormattedSites,
            }
        case SET_THRESHOLD_PREVI_DATA:
            return {
                ...state,
                thresholdPreviListData: action.data,
            }
        default:
            return state
    }
}
