import { Card, CardContent, Grid2, Icon, Tooltip } from '@mui/material'
import { NewTable } from 'components/datatable/NewTable'
import React, { useMemo, useRef } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { nbPerPageLabel } from 'referencial/constants/ReferencialConstants'
import { getAccreditationPicto, getResultFormat } from 'utils/AnalyseUtils'
import useListIndexed from 'utils/customHook/useListIndexed'
import ReactECharts from 'echarts-for-react'
import echarts from 'echarts/lib/echarts'
import i18n from 'simple-react-i18n'
import DtoIndice from 'quality/components/operation/dto/DtoIndice'
import PropTypes from 'prop-types'
import useActions from 'utils/customHook/useActions'
import { exportPictureIcon } from 'components/echart/EChartUtils'
import useBoolean from 'utils/customHook/useBoolean'
import ExportFileModal from 'components/modal/ExportFileModal'
import ExportAction from 'export/actions/ExportAction'
import { formatData } from 'utils/ExportDataUtil'
import { keys } from 'lodash'
import MessageCard from 'components/card/MessageCard'

const CHEMICAL_PRESSURES = [20001, 20002, 20003, 20004, 20005, 20006]
const ANTHROPOGENIC_PRESSURES = [20007, 20008, 20009, 20010, 20011, 20012]

const PressureRadar = ({
    title = '',
    pressures = [],
}) => {
    const {
        parameters,
    } = useSelector(store =>({
        parameters: store.ParameterReducer.parameters,
    }), shallowEqual)

    const indexedParameters = useListIndexed(parameters, 'code')

    let echartRef = useRef(undefined)

    const formattedIndicators = pressures.map(i => {
        return {
            name: indexedParameters[i.parameter]?.name ?? `${i.parameter}`,
            max: 1,
            min: 0,
        }
    })

    const getToolbox = show => ({
        show,
        right: 35,
        showTitle: false,
        itemSize: 18,
        tooltip: {
            show: true,
            position: 'bottom',
        },
        feature: {
            saveAsImage: {
                title: i18n.pictureExport,
                icon: exportPictureIcon,
                name: title,
            },
        },
    })

    const options = {
        title: {
            text: title,
            x: 'center',
        },
        tooltip: {},
        radar: {
            shape: 'circle',
            radius: '110',
            center: ['50%', '53%'],
            name: {
                textStyle: {
                    color: '#000',
                    padding: [3, 5],
                },
            },
            indicator: formattedIndicators,
        },
        series: [{
            name: title,
            type: 'radar',
            areaStyle: { normal: {} },
            data: [
                {
                    value: pressures.map(i => i.result),
                },
            ],
        }],
    }

    return (
        <div
            onMouseOver={() => {
                echartRef.current?.getEchartsInstance().setOption({
                    toolbox: getToolbox(true),
                })
            }}
            onMouseOut={() => {
                echartRef.current?.getEchartsInstance().setOption({
                    toolbox: getToolbox(false),
                })
            }}
        >
            <ReactECharts
                echarts={echarts}
                option={options}
                notMerge
                lazyUpdate
                ref={e => {
                    echartRef.current = e
                }}
                style={{ height: 350 }}
            />
        </div>
    )
}

PressureRadar.propTypes = {
    title: PropTypes.string,
    pressures: PropTypes.arrayOf(PropTypes.instanceOf(DtoIndice)),
}

const PressureTable = ({

}) => {
    const {
        pressures,
        parameters,
    } = useSelector(store => ({
        pressures: store.OperationReducer.operationPressures,
        parameters: store.ParameterReducer.parameters,
    }), shallowEqual)

    const indexedParameters = useListIndexed(parameters, 'code')

    const formattedPressures = useMemo(() => {
        return pressures.map(p => ({
            nullValue: { value: getAccreditationPicto(p.accreditation === 1) },
            nullValue2: { value: p.calculated ? <Tooltip title='Open Data'><Icon>wifi</Icon></Tooltip> : undefined },
            parameter: { value: indexedParameters[p.parameter]?.labelWithCode },
            result: { value: getResultFormat(p) },
            comment: { value: p.comment },
            colorLine: p.calculated ? '#b8d2ff' : '#fff',
        }))
    }, [pressures, indexedParameters])

    return (
        <NewTable
            rows={formattedPressures}
            headers={['nullValue', 'nullValue2', 'parameter', 'result', 'comment']}

            rowsPerPageOptions={nbPerPageLabel}
        />
    )
}

const OperationHydrobioPressuresPanel = ({

}) => {
    const dispatch = useDispatch()
    const {
        qualitometer,
        pressures,
        parameters,
        accountUser,
        hydrobioOperation,
    } = useSelector(store => ({
        qualitometer: store.QualityReducer.qualitometer,
        pressures: store.OperationReducer.operationPressures,
        parameters: store.ParameterReducer.parameters,
        accountUser: store.AccountReducer.accountUser,
        hydrobioOperation: store.OperationReducer.hydrobioOperation,
    }), shallowEqual)

    const { value: isOpen, setTrue: onOpen, setFalse: onClose } = useBoolean(false)

    useActions(() => {
        const exportList = pressures.length > 0 ? [{
            onClick: onOpen,
            label: i18n.excel,
        }] : undefined
        if (![4, 10, 11, 13, 27].includes(hydrobioOperation.support) || accountUser.consultant === '1') return { exportList }
        return {
            exportList,
            calculateIndexes: {
                qualitometer: hydrobioOperation.qualitometer,
                operations: [hydrobioOperation.id],
                support: hydrobioOperation.support,
            },
        }
    }, [pressures])

    const onExport = () => {
        const formattedPressures = pressures.map(p => {
            const paramString = `${p.parameter ?? ''}`
            const param = parameters.find(({ code }) => code === paramString)
            return {
                accreditation: { value: p.accreditation === 1 ? i18n.yes : i18n.no },
                calculatedParameter: { value: p.calculated ? i18n.yes : i18n.no },
                parameterCode: { value: paramString, cellType: 'right' },
                parameter: param?.name ?? '',
                result: getResultFormat(p),
                comment: p.comment,
            }
        })
        return formattedPressures.length > 0 ? [
            {
                ...formattedPressures[0],
                headers: keys(formattedPressures[0]),
            },
            ...formattedPressures.slice(1),
        ] : []
    }

    const formattedExport = [{
        name: i18n.resultsTable,
        formats: [{
            type: i18n.excelFile,
            callback: () => dispatch(ExportAction.export(formatData(onExport()), 'xlsx', `${qualitometer.code} - ${i18n.pressures}`)),
        }, {
            type: i18n.csvFile,
            callback: () => dispatch(ExportAction.export(formatData(onExport()), 'csv', `${qualitometer.code} - ${i18n.pressures}`)),
        }],
    }]

    const chemicalPressures = useMemo(() => pressures.filter(p => CHEMICAL_PRESSURES.includes(p.parameter)), [pressures])
    const anthropogenicPressures = useMemo(() => pressures.filter(p => ANTHROPOGENIC_PRESSURES.includes(p.parameter)), [pressures])

    return (
        <Card>
            <CardContent>
                {pressures.length > 0 && (
                    <Grid2 container spacing={1} justifyContent='space-around'>
                        <Grid2 size={12}>
                            <PressureTable />
                        </Grid2>
                        <Grid2 size={5}>
                            <PressureRadar title={i18n.chemicalPressures} pressures={chemicalPressures} />
                        </Grid2>
                        <Grid2 size={5}>
                            <PressureRadar title={i18n.anthropogenicPressures} pressures={anthropogenicPressures} />
                        </Grid2>
                    </Grid2>
                )}
                {pressures.length === 0 && (
                    <MessageCard>{i18n.noPressures}</MessageCard>
                )}
            </CardContent>
            <ExportFileModal
                open={isOpen}
                onClose={onClose}
                data={formattedExport}
            />
        </Card>
    )
}

export default OperationHydrobioPressuresPanel