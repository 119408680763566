/* eslint-disable camelcase */
import DtoAltimetrySystem from '../../piezometry/dto/DtoAltimetrySystem'
import DtoAquiferLink from '../../piezometry/dto/DtoAquiferLink'
import DtoLandmark from '../../piezometry/dto/DtoLandmark'
import DtoNetworkLink from '../../piezometry/dto/DtoNetworkLink'
import DtoPiezometerContributorLink from '../../piezometry/dto/DtoPiezometerContributorLink'
import DtoWatermassLink from '../../piezometry/dto/DtoWatermassLink'
import DtoAccessibility from '../../station/dto/DtoAccessibility'
import DtoArrangement from '../../station/dto/DtoArrangement'
import DtoCasing from '../../station/dto/DtoCasing'
import DtoFunction from '../../station/dto/DtoFunction'
import DtoGeoData from '../../station/dto/DtoGeoData'
import DtoHydrogeologicalEntityLink from '../../station/dto/DtoHydrogeologicalEntityLink'
import DtoLithology from '../../station/dto/DtoLithology'
import DtoLithologyType from '../../station/dto/DtoLithologyType'
import DtoLocation from '../../station/dto/DtoLocation'
import DtoLocationLink from '../../station/dto/DtoLocationLink'
import DtoMeasureMethod from '../../station/dto/DtoMeasureMethod'
import DtoSamplePoint from '../../station/dto/DtoSamplePoint'
import DtoStationContacts from '../../station/dto/DtoStationContacts'
import DtoUsage from '../../station/dto/DtoUsage'
import DtoWorkLink from '../../station/dto/DtoWorkLink'

export default class DtoQualitometer {
    constructor(obj) {
        this.id = obj.id
        this.name = obj.name
        this.code = obj.code
        this.comment = obj.comment
        this.creationDate = obj.creation
        this.creation = obj.creation
        this.close = obj.close
        this.type = obj.type
        this.stationType = obj.stationType || '0'
        this.typeName = 'quality'
        this.location = obj.location
        this.nature = obj.nature
        this.designation = obj.designation
        this.localisation = new DtoLocation(obj, obj.altitude)
        this.x = obj.x
        this.y = obj.y
        this.altitude = obj.altitude
        this.projection = obj.projection
        this.watershed = obj.watershed
        this.watershed2 = obj.watershed2
        this.watershed3 = obj.watershed3
        this.watershed4 = obj.watershed4
        this.contactCode = obj.contactCode
        this.qualitometerType = obj.qualitometerType
        this.waterSourceType = obj.waterSourceType
        this.townCode = obj.townCode
        this.declarationTownCode = obj.declarationTownCode
        this.fieldMode = obj.fieldMode
        this.stateCode = obj.stateCode
        this.finality = obj.finality
        this.qualityCode = obj.qualityCode
        this.address = obj.address
        this.representativenessCode = obj.representativenessCode
        this.jobExecutionId = obj.jobExecutionId
        this.historyStart = obj.historyStart
        this.historyEnd = obj.historyEnd
        this.operationCount = obj.operationCount
        this.countryCode = obj.countryCode
        this.updateLogin = obj.updateLogin
        this.updateDate = obj.updateDate
        this.her1 = obj.her1 // Option[Double] = None,
        this.her2 = obj.her2 // Option[Double] = None,
        this.river = obj.river
        this.strahlerRank = obj.strahlerRank // Option[Double] = None,
        this.strahlerValue = obj.strahlerValue // Option[Int] = None,
        this.frType = obj.frType // Option[Double] = None,

        this.link_accessibilities = obj.link_accessibilities ? obj.link_accessibilities.map(la => new DtoAccessibility(la)) : []
        this.link_altimetrySystems = obj.link_altimetrySystems && obj.link_altimetrySystems.length ? obj.link_altimetrySystems.map(l => new DtoAltimetrySystem(l)) : []
        this.link_aquifers = obj.link_aquifers ? obj.link_aquifers.map(l => new DtoAquiferLink(l)) : []
        this.link_arrangements = obj.link_arrangements ? obj.link_arrangements.map(la => new DtoArrangement(la)) : []
        this.link_casings = obj.link_casings ? obj.link_casings.map(lc => new DtoCasing(lc)): []
        this.link_contributors = obj.link_contributors ? obj.link_contributors.map(c => new DtoPiezometerContributorLink(c)): []
        this.link_functions = obj.link_functions ? obj.link_functions.map(lf => new DtoFunction(lf)) : []
        this.link_geo = obj.link_geo ? obj.link_geo.map(g => new DtoGeoData(g)) : []
        this.link_hydroEntity = obj.link_hydroEntity ? obj.link_hydroEntity.map(he => new DtoHydrogeologicalEntityLink(he)) : []
        this.link_landmarks = obj.link_landmarks ? obj.link_landmarks.map(l => new DtoLandmark(l)) : []
        this.link_lithologicType = obj.link_lithologicType ? obj.link_lithologicType.map(lt => new DtoLithologyType(lt)) : []
        this.link_lithology = obj.link_lithology ? obj.link_lithology.map(ll => new DtoLithology(ll)) : []
        this.link_locations = obj.link_locations ? obj.link_locations.map(l => new DtoLocationLink(l)) : []
        this.link_measureMethod = obj.link_measureMethod ? obj.link_measureMethod.map(lmm => new DtoMeasureMethod(lmm)) : []
        this.link_networks = obj.link_networks ? obj.link_networks.map(l => new DtoNetworkLink(l)) : []
        this.link_usages = obj.link_usages ? obj.link_usages.map(l => new DtoUsage(l)) : []
        this.link_watermasses = obj.link_watermasses ? obj.link_watermasses.map(l => new DtoWatermassLink(l)) : []
        this.link_work = obj.link_work ? obj.link_work.map(lw => new DtoWorkLink(lw)) : []
        this.link_samplePoints = obj.link_samplePoints ? obj.link_samplePoints.map(sp => new DtoSamplePoint(sp)) : []
        this.link_contacts = obj.link_contacts ? obj.link_contacts.map(lc => new DtoStationContacts(lc)) : []
    }
}
