import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import IAction from './IAction'

class Validate extends IAction {
    fn = () => {
        return this.props.onValidate
    }
    icon = () => {
        return 'check_circle'
    }
    label = () => {
        return i18n.validate
    }

    id = () =>{
        return 'validate_action_navbar'
    }
}

Validate.propTypes = {
    onValidate: PropTypes.func.isRequired,
}

export default Validate
