/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable consistent-return */
import { useEffect, useRef } from 'react'

/**
 * useEffect but net execute on mount
 * @param {function} callback - The callback
 * @param {Array} dependencies - The dependencies
 * @return {void}
 */
const useUpdateEffect = (callback, dependencies) => {
    const firstRenderRef = useRef(true)

    useEffect(() => {
        if (firstRenderRef.current) {
            firstRenderRef.current = false
            return
        }
        return callback()
    }, dependencies)
}

export default useUpdateEffect