import { createSlice } from '@reduxjs/toolkit'
import PluviometryThunk from 'pluviometry/actions/PluviometryThunk'
import DtoPluvioMeasureObj from 'pluviometry/dto/measures/DtoPluvioMeasureObj'
import DtoMeasure from '../../alerting/dto/alert/DtoMeasure'
import CampaignDto from '../../campaign/dto/CampaignDto'
import DtoCampaignProgress from '../../campaign/dto/DtoCampaignProgress'
import DtoStationCampaign from '../../campaign/dto/DtoStationCampaign'
import DtoParametrageDataType from '../../piezometry/dto/DtoParametrageDataType'
import DtoKeyFigure from '../../station/dto/DtoKeyFigure'
import { createIndex } from '../../utils/StoreUtils'
import DtoPluviometerContributorLink from '../dto/DtoPluviometerContributorLink'
import DtoPluviometerStats from '../dto/DtoPluviometerStats'
import DtoPluviometerWithCampaignsAndEvents from '../dto/DtoPluviometerWithCampaignsAndEvents'
import DtoPluviometrySituations from '../dto/DtoPluviometrySituations'
import DtoPluvioSituationDays from '../dto/DtoPluvioSituationDays'
import NetworkPluvioLinkDto from '../dto/NetworkPluvioLinkDto'
import PluviometerDto from '../dto/PluviometerDto'
import PluviometerNormalDto from '../dto/PluviometerNormalDto'
import PluviometerThresholdDto from '../dto/PluviometerThresholdDto'

export const initialState = {
    pluviometers: [],
    pluviosIndex: {},
    pluviosIndexCode: {},
    pluviometersWithCampaignsAndEvents: [],
    pluviometerAllThresholds: [],
    pluviometerLastMeasures: [],
    pluvioMeasures: [],
    pluviometer: {},
    pluviometerThresholds: [],
    pluviometerStatistics: [],
    normales: [],
    pluviometryCampaigns: [],
    pluviometryCampaignsProgress: [],
    pluviometryCampaignStations: [],
    pluviometrySituations: [],
    pluviometryDaysSituation: [],
    pluviometryKeyfigures: [],
    pluviometryDataTypes: [],
    networkPluvioLink: [],
    pluviometersContributors: [],
}

const store = createSlice({
    name: 'pluviometry',
    initialState,
    reducers: {
        createPluviometer: (state, action) => {
            state.pluviometers = [...state.pluviometers, new PluviometerDto(action.payload)]
        },
        receivePluviometers: (state, action) => {
            const pluvios = action.payload.map(p => new PluviometerDto(p))
            state.pluviometers = pluvios
            state.pluviosIndex = createIndex(pluvios)
            state.pluviosIndexCode = createIndex(pluvios, 'code')
        },
        receivePluviometersWithCampaignsAndEvents: (state, action) => {
            state.pluviometersWithCampaignsAndEvents = action.payload.map(p => new DtoPluviometerWithCampaignsAndEvents(p))
        },
        receivePluviometer: (state, action) => {
            state.pluviometer = new PluviometerDto(action.payload)
        },
        updatePluviometer: (state, action) => {
            const stations = state.pluviometers.filter(q => q.id !== action.payload.id)
            state.pluviometers = [...stations, new PluviometerDto(action.payload)]
        },
        receiveNormales: (state, action) => {
            state.normales = action.payload.map(p => new PluviometerNormalDto(p))
        },
        updateNormales: (state, action) => {
            const normales = state.normales.filter(n => !action.payload.find(e => e.month === n.month))
            state.normales = [...normales, ...action.payload.map(n => new PluviometerNormalDto(n))]
        },
        receivePluviometerKeyFigures: (state, action) => {
            state.pluviometryKeyfigures = action.payload.map(p => new DtoKeyFigure(p))
        },
        receivePluviometerSituations: (state, action) => {
            state.pluviometrySituations = action.payload.map(p => new DtoPluviometrySituations(p))
        },
        receivePluvioChronicMeasures: (state, action) => {
            state.pluvioMeasures = action.payload
        },
        receivePluviometerStatistics: (state, action) => {
            state.pluviometerStatistics = action.payload.map(p => new DtoPluviometerStats(p))
        },
        receivePluviometerThresholds: (state, action) => {
            state.pluviometerThresholds = action.payload.map(p => new PluviometerThresholdDto(p))
        },
        receiveAllPluviometerThresholds: (state, action) => {
            state.pluviometerAllThresholds = action.payload.map(p => new PluviometerThresholdDto(p))
        },
        receivePluviometryDataTypes: (state, action) => {
            state.pluviometryDataTypes = action.payload.map(p => new DtoParametrageDataType(p))
        },
        receivePluviometerLastMeasures: (state, action) => {
            state.pluviometerLastMeasures = action.payload.map(p => new DtoMeasure(p))
        },
        receivePluvioSituationLastMeasures: (state, action) => {
            state.pluvioSituationLastMeasures = action.payload.map(m => new DtoPluvioMeasureObj(m))
        },
        receivePluviometryCampaigns: (state, action) => {
            state.pluviometryCampaigns = action.payload.map(p => new CampaignDto(p))
        },
        receivePluviometryCampaignsProgress: (state, action) => {
            state.pluviometryCampaignsProgress = action.payload.map(p => new DtoCampaignProgress(p))
        },
        receivePluviometryCampaignStations: (state, action) => {
            state.pluviometryCampaignStations = action.payload.map(p => new DtoStationCampaign(p))
        },
        receivePluviometryDaysSituation: (state, action) => {
            state.pluviometryDaysSituation = action.payload.map(p => new DtoPluvioSituationDays(p))
        },
        receiveNetworkPluvioLink: (state, action) => {
            state.networkPluvioLink = action.payload.map(p => new NetworkPluvioLinkDto(p))
        },
        receiveAllPluviometersContributors: (state, action) => {
            state.pluviometersContributors = action.payload.map(p => new DtoPluviometerContributorLink(p))
        },
        resetPluviometerStation: (state) => {
            state.pluviometer = {}
            state.pluviometerThresholds = []
            state.pluviometerStatistics = []
        },
    },
    extraReducers: {
        [PluviometryThunk.fetchPluviometers.fulfilled]: (state, action) => {
            state.pluviometers = action.payload.map(p => new PluviometerDto(p))
        },
    },
})

export const PluviometryActionConstant = store.actions
export default store.reducer
