import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import IAction from './IAction'

class Email extends IAction {
    fn = () => {
        return this.props.onSend
    }
    icon = () => {
        return 'email'
    }
    label = () => {
        return i18n.send
    }

    id = () =>{
        return 'email_action_navbar'
    }
}

Email.propTypes = {
    onSend: PropTypes.func.isRequired,
}

export default Email
