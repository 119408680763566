import React, { useEffect, useState } from 'react'
import i18n from 'simple-react-i18n'
import PropTypes from 'prop-types'
import { Button, Dialog, Grid } from '@mui/material'
import Input from 'components/forms/Input'
import Icon from 'components/icon/Icon'
import { DialogActionsMUI, DialogContentMUI, DialogTitleMUI } from 'components/styled/Dialog'
import NumberField from 'components/forms/NumberField'
import Select from 'components/forms/Select'
import MultiAutocomplete from 'components/forms/MultiAutocomplete'
import SimpleCheckbox from 'components/forms/SimpleCheckbox'
import { orderBy } from 'lodash'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import ToastrAction from 'toastr/actions/ToastrAction'

const CreatePARDialog = ({
    onValidate = () => {},
    onClose = () => {},
    par = { onlyDeclarationsCompleted: true },
    open,
    title,
}) => {
    const [newPAR, setNewPAR] = useState(par)

    useEffect(() => {
        setNewPAR(par)
    }, [par])

    const dispatch = useDispatch()

    const {
        surveysWithStats,
        contributors,
    } = useSelector(store => ({
        surveysWithStats: store.AgriReducer.surveysWithStats,
        contributors: store.ContributorReducer.contributors,
    }), shallowEqual)

    const handleOnSave = () => {
        if (newPAR.name && newPAR.year && newPAR.periode && newPAR.linkSurveys.length) {
            onValidate({ ...newPAR, linkSurveys: newPAR.linkSurveys.map(v => ({ idPAR: -1, idSurvey: v })) })
        } else {
            dispatch(ToastrAction.error(i18n.pleaseCompleteAllRequiredField))
        }
    }

    return (
        <Dialog
            fullWidth
            maxWidth='md'
            open={open}
        >
            <DialogTitleMUI style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                {title || i18n.createNewPAR}
                <Icon style={{ color: 'white' }} size='small' icon='close' onClick={onClose} />
            </DialogTitleMUI>
            <DialogContentMUI className='padding-top-1' sx={{ height: 350 }}>
                <Grid container spacing={1}>
                    <Grid item xs={6}>
                        <Input
                            col={12}
                            title={i18n.name}
                            value={newPAR.name}
                            onChange={value => setNewPAR({ ...newPAR, name: value })}
                            obligatory
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <NumberField
                            col={12}
                            title={i18n.year}
                            value={newPAR.year}
                            onChange={value => setNewPAR({ ...newPAR, year: value })}
                            obligatory
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <Select
                            col={12}
                            label={i18n.periode}
                            options={[{
                                value: 1,
                                label: i18n.all,
                            }, {
                                value: 2,
                                label: i18n.lowWater,
                            }, {
                                value: 3,
                                label: i18n.notLowWater,
                            }]}
                            value={newPAR.periode}
                            onChange={value => setNewPAR({ ...newPAR, periode: value })}
                            integerValue
                            noNullValue
                            obligatory
                            noSort
                            clearFunction
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <MultiAutocomplete
                            col={12}
                            label={i18n.surveys}
                            values={newPAR.linkSurveys}
                            onChange={values => setNewPAR({ ...newPAR, linkSurveys: values })}
                            clearFunction
                            integerValue
                            noNullValue
                            elements={orderBy(surveysWithStats.filter((sc) => sc.year === newPAR.year), 'year', 'desc')}
                            keyName='nameWithYear'
                            keyId='idSurvey'
                            obligatory
                            tooltip={i18n.surveysFromYearSelected}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <SimpleCheckbox
                            checked={newPAR.onlyDeclarationsCompleted}
                            label={i18n.takeOnlyDeclarationsFull}
                            onToggle={(_, v) => setNewPAR({ ...newPAR, onlyDeclarationsCompleted: v })}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <SimpleCheckbox
                            checked={newPAR.tanks}
                            label={i18n.takeWaterTanksDescription}
                            onToggle={(_, v) => setNewPAR({ ...newPAR, tanks: v })}
                        />
                    </Grid>
                    <Grid item xs={6} className='margin-top-1'>
                        <Select
                            col={12}
                            label={i18n.organism}
                            options={contributors}
                            value={newPAR.organism}
                            onChange={value => setNewPAR({ ...newPAR, organism: value })}
                            keyValue='id'
                            clearFunction
                        />
                    </Grid>
                </Grid>
            </DialogContentMUI>
            <DialogActionsMUI>
                <Button onClick={handleOnSave} variant='contained' color='primary'>
                    {i18n.validate}
                </Button>
            </DialogActionsMUI>
        </Dialog>
    )
}

CreatePARDialog.propTypes = {
    onValidate: PropTypes.func,
    onClose: PropTypes.func,
    par: PropTypes.shape({}),
    open: PropTypes.bool,
    title: PropTypes.string,
}

export default CreatePARDialog