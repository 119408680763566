import { Card } from '@mui/material'
import DtoUserStatistic from 'administration/components/user/dto/DtoUserStatistic'
import ReactEchartsCore from 'echarts-for-react/lib/core'
import 'echarts/lib/chart/bar'
import echarts from 'echarts/lib/echarts'
import { keys } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import i18n from 'simple-react-i18n'
import { mainWhite } from 'utils/constants/ColorTheme'
import { exportExcelIcon, exportPictureIcon } from '../../../../../../components/echart/EChartUtils'
import { getFullDate } from '../../../../../../utils/DateUtil'
import { exportFile } from '../../../../../../utils/ExportDataUtil'
import { getModuleColor, getReduceStatistics } from '../../../../../../utils/StatisticUtil'

const AccessApplicationChartPanel = ({
    userStatistics,
}) => {
    const getExportData = () => {
        const data = userStatistics.map((d, i) => {
            const stat = {
                login: d.login,
                statisticDate: getFullDate(d.statisticDate),
                module: d.module,
                value: d.value,
            }
            if (i === 0) {
                return { ...stat, headers: ['login', 'statisticDate', 'module', 'value'] }
            }
            return stat
        })
        return data
    }

    const getOptions = () => {
        const reduceDatas = getReduceStatistics(userStatistics)
        const dataSeries = keys(reduceDatas).map((o) => {
            return {
                value: reduceDatas[o].length,
                name: o?.toUpperCase(),
                color: getModuleColor(o),
            }
        })
        return {
            color: dataSeries.map(o => o.color),
            title: {
                text: i18n.usedApplication,
            },
            tooltip: {
                trigger: 'item',
                formatter: '{b} : {c} ({d}%)',
            },
            legend: {
                top: 'bottom',
                left: 'center',
                type: 'scroll',
                width: '90%',
                itemGap: 20,
            },
            series: [
                {
                    type: 'pie',
                    data: dataSeries,
                    avoidLabelOverlap: false,
                    radius: ['50%', '70%'],
                    label: {
                        normal: {
                            show: false,
                            position: 'center',
                        },
                        emphasis: {
                            show: true,
                            textStyle: {
                                fontSize: '20',
                                fontWeight: 'bold',
                            },
                        },
                    },
                    labelLine: {
                        normal: {
                            show: false,
                        },
                    },
                },
            ],
            toolbox: {
                show: true,
                feature: {
                    restore: { title: i18n.restore },
                    saveAsImage: { title: i18n.export, icon: exportPictureIcon },
                    myToolExport: {
                        show: true,
                        title: i18n.excelExport,
                        icon: exportExcelIcon,
                        onclick: () => {
                            exportFile({
                                data: getExportData(),
                                exportType: 'xlsx',
                                titleFile: i18n.userConnexionStatistic,
                            })
                        },
                    },
                },
            },
        }
    }

    if (userStatistics.length) {
        return (
            <Card
                sx={{
                    borderRadius: '4px',
                    boxShadow: '0px 4px 7px rgba(0, 0, 0, 0.1)',
                    padding: '12px',
                    backgroundColor: mainWhite,
                    marginBottom: '12px',
                }}
            >
                <ReactEchartsCore
                    echarts={ echarts }
                    option={ getOptions(userStatistics) }
                    notMerge={ true }
                    lazyUpdate={ true }
                    className={ 'row no-margin' }
                    style={ { height: 200 } }
                />
            </Card>
        )
    }
    return null
}

AccessApplicationChartPanel.propTypes = {
    userStatistics: PropTypes.arrayOf(PropTypes.instanceOf(DtoUserStatistic)),
}

export default AccessApplicationChartPanel