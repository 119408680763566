export default class JobDashboard {
    constructor(obj = {}) {
        this.cron = obj.cron
        this.login = obj.login
        this.id = obj.id
        this.type = obj.jobType
        this.execution = obj.execution
        this.name = obj.name
        this.format = obj.format
        this.description = obj.description
        this.duration = obj.duration
        this.status = obj.status
        this.result = obj.result
        this.date = obj.date
        this.headers = ['type', 'name', 'execution', 'login']
    }

    visible() {
        return ['name', 'format', 'execution', 'duration', 'status']
    }
}
