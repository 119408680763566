import React, { useState } from 'react'
import i18n from 'simple-react-i18n'
import { uniqBy } from 'lodash'
import PropTypes from 'prop-types'
import { shallowEqual, useSelector } from 'react-redux'
import SimpleFilterSelect from 'components/forms/specific/SimpleFilterSelect'
import { Button, Card, CardContent, Grid2, Icon } from '@mui/material'
import SuperMultiAutocomplete from 'components/forms/SuperMultiAutocomplete'
import Select from 'components/forms/Select'
import { getTypeModule } from 'utils/StationUtils'
import Input from 'components/forms/Input'
import { hasValue } from 'utils/NumberUtil'
import FilterInsertModal from 'components/modal/FilterInsertModal'
import { FILTER_TYPE } from 'administration/components/user/constants/UserConstants'

const DistributionUnitFilterPanel = ({
    stationType,
    data = [],

    onChangeSearchValue = () => { },
    setFilterResults = () => { },
    setFilter = () => { },
    setSector = () => { },
    setCity = () => { },

    defaultSearchValue,
    defaultFilter,
    defaultFilterResults,
    defaultSector,
    defaultCity,
}) => {
    const {
        cities,
        distributionUnits,
        distributionUnitSectors,
    } = useSelector(store => ({
        cities: store.CityReducer.cities,
        distributionUnits: store.DistributionUnitReducer.distributionUnits,
        distributionUnitSectors: store.DistributionUnitReducer.distributionUnitSectors,
    }), shallowEqual)

    const [open, setOpen] = useState(false)
    const [search, setSearch] = useState(defaultSearchValue)
    const [tmpFilter, setTmpFilter] = useState(defaultFilter || FILTER_TYPE.NOFILTER)
    const [tmpFilterResults, setTmpFilterResults] = useState(defaultFilterResults || [])
    const [tmpSector, setTmpSector] = useState(defaultSector)
    const [tmpCity, setTmpCity] = useState(defaultCity)

    const typeModule = getTypeModule(stationType)

    const onValidate = (newSearch) => {
        onChangeSearchValue(newSearch || search)
        setFilterResults(tmpFilterResults)
        setFilter(tmpFilter)
        setSector(tmpSector)
        setCity(tmpCity)
    }

    const filteredTowns = distributionUnits?.flatMap(d => d.geographicSector?.split(','))

    return (
        <Card>
            <CardContent>
                <Grid2 container spacing={1.5}>
                    <Grid2 size={3}>
                        <Input
                            tooltip={i18n.searchThenEnter}
                            title={i18n.search}
                            value={search}
                            onChange={setSearch}
                            onEnterKeyPress={(value) => {
                                setSearch(value)
                                onValidate(value)
                            }}
                            clearFunction
                            data-cy='search'
                        />
                    </Grid2>
                    <Grid2 size={2.5}>
                        <SimpleFilterSelect
                            stationType={stationType}
                            onChange={(filterStations, newFilter) => {
                                setTmpFilterResults(filterStations)
                                setTmpFilter(newFilter)
                            }}
                            nullLabel={i18n.allStations}
                            stations={data}
                        />
                    </Grid2>
                    <Grid2 container size={0.5} alignItems='center'>
                        <Icon sx={{ cursor: 'pointer' }} onClick={() => setOpen(true)} data-cy='open-filter' fontSize='md'>
                            {hasValue(tmpFilter) && tmpFilter !== FILTER_TYPE.BOOKMARK && tmpFilter !== FILTER_TYPE.NOFILTER ? 'edit' : 'note_add'}
                        </Icon>
                    </Grid2>
                    <FilterInsertModal
                        setOpen={setOpen}
                        open={open}
                        selectedFilter={tmpFilter}
                        module={typeModule}
                        isNew={!hasValue(tmpFilter) || tmpFilter === FILTER_TYPE.BOOKMARK || tmpFilter === FILTER_TYPE.NOFILTER}
                        onChange={setFilter}
                    />
                    <Grid2 size={3}>
                        <SuperMultiAutocomplete
                            options={uniqBy(distributionUnitSectors, 'name')}
                            label={i18n.sector}
                            onChange={setTmpSector}
                            values={tmpSector}
                        />
                    </Grid2>
                    <Grid2 size={3}>
                        <Select
                            options={cities.filter(c => filteredTowns.includes(c.code))}
                            label={i18n.city}
                            onChange={setTmpCity}
                            value={tmpCity}
                            clearFunction
                            noNullValue
                        />
                    </Grid2>
                    <Grid2 container size={12} justifyContent='flex-end'>
                        <Grid2 size='auto'>
                            <Button
                                variant='contained'
                                onClick={() => onValidate()}
                                data-cy='searchBtn'
                            >
                                {i18n.search}
                            </Button>
                        </Grid2>
                    </Grid2>
                </Grid2>
            </CardContent>
        </Card>
    )
}

DistributionUnitFilterPanel.propTypes = {
    stationType: PropTypes.string,
    onChangeSearchValue: PropTypes.func,
    data: PropTypes.arrayOf(PropTypes.shape({})),
    setFilterResults: PropTypes.func,
    setFilter: PropTypes.func,
    filter: PropTypes.number,
    setSector: PropTypes.func,
    sector: PropTypes.number,
    setCity: PropTypes.func,
    city: PropTypes.number,
    defaultSearchValue: PropTypes.string,
    defaultFilter: PropTypes.number,
    defaultFilterResults: PropTypes.arrayOf(PropTypes.number),
    defaultSector: PropTypes.number,
    defaultCity: PropTypes.number,
}


export default DistributionUnitFilterPanel