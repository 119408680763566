import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import SettingDto from '../../../administration/dto/SettingDto'
import Icon from '../../../components/icon/Icon'
import DtoEvent from '../../../events/dto/DtoEvent'
import { getEventComment } from '../../../piezometry/components/validation/ValidationEventUtils'
import ContactItem from '../../../referencial/components/contact/dto/ContactDto'
import { getDate, getFullDate, getHour } from '../../../utils/DateUtil'

const $ = window.$

class TechniqueEventCard extends Component {
    constructor(props) {
        super(props)
    }

    componentDidMount() {
        $('.event-tooltip').tooltip()
    }

    componentDidUpdate() {
        $('.event-tooltip').tooltip()
    }

    getContact(code) {
        if (code) {
            return this.props.contacts.find(o => o.code == code)
        }
        return null
    }

    getUpdateInfo = (event) => {
        return event.updateDate && event.updateLogin ? (
            <div className='row no-margin valign-wrapper padding-left-2 font-size-10'>
                <div className='no-margin col s1'><Icon size='small' icon='build'/></div>
                <div className='no-margin col s11'>
                    { this.props.shortDate ?
                        ` ${i18n.atDate} ${getFullDate(event.updateDate)} ${i18n.by} ${event.updateLogin}` :
                        ` ${i18n.lastUpdateOn} ${getDate(event.updateDate)} ${i18n.atHour} ${getHour(event.updateDate)} ${i18n.by} ${event.updateLogin}` }
                </div>
            </div>
        ) : null
    }

    render() {
        const event = this.props.event

        return (
            <div className={ `event-card ${this.props.clickable ? 'clickable' : ''}` } onClick={ () => this.props.onClick(event) }>
                <div className='row no-margin event-content valign-wrapper'>
                    <div className='col s12 clickable' onClick={ () => {} } >
                        <div>{getEventComment(event.comment)}</div>
                    </div>
                </div>
                <div className='row no-margin event-footer'>
                    <div className='no-padding no-margin col s8'>
                        { this.getUpdateInfo(event) }
                    </div>
                    <div className='no-padding col s3 center-align'>
                        { event.chgtpiles && event.chgtpiles !== '0' && (<Icon size='small' icon='battery_std' tooltip={ i18n.changeBatteries } />) }
                        { event.razstation && event.razstation !== '0' && (<Icon size='small' icon='settings_backup_restore' tooltip={ i18n.rebootStation } />) }
                        { event.graph && event.graph !== '0' && (<Icon size='small' icon='insert_chart' tooltip={ i18n.displayOnCharts } />) }
                        { event.bankExport && event.bankExport !== '0' && (<Icon size='small' icon='launch' tooltip={ i18n.eventBankExport } />) }
                        { event.diagnostic && (<Icon size='small' icon='assignment_turned_in' tooltip={ i18n.diagnosticDone } />) }
                    </div>
                </div>
            </div>
        )
    }

    componentWillUnmount() {
        $('.event-tooltip').tooltip('remove')
    }
}

TechniqueEventCard.propTypes = {
    event: PropTypes.instanceOf(DtoEvent),
    title: PropTypes.string,
    small: PropTypes.boolean,
    shortDate: PropTypes.boolean,
    settings: PropTypes.arrayOf(PropTypes.instanceOf(SettingDto)),
    link: PropTypes.string,
    contacts: PropTypes.arrayOf(PropTypes.instanceOf(ContactItem)),
    showStationInfo: PropTypes.bool,
    onClick: PropTypes.func,
    clickable: PropTypes.bool,
}

TechniqueEventCard.defaultProps = {
    small: true,
    onClick: () => {},
}

const mapStateToProps = store => ({
    settings: store.AdministrationReducer.settings,
    contacts: store.ContactReducer.contacts,
})

export default connect(mapStateToProps)(TechniqueEventCard)
