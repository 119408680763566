import { CatchmentActionConstant } from 'catchment/reducers/CatchmentReducer'
import ApplicationConf from 'conf/ApplicationConf'
import LogAction from 'log/actions/LogAction'
import i18n from 'simple-react-i18n'
import ToastrAction from 'toastr/actions/ToastrAction'
import { genericPromise2 } from 'utils/ActionUtils'
import CatchmentThunk from './CatchmentThunk'
import { push } from '@lagunovsky/redux-react-router'

const builderError403 = (dispatch) => () => {
    dispatch(ToastrAction.warning(i18n.habilitationError))
    dispatch(push('/catchment'))
}

const CatchmentAction = {
    getCatchment: id => dispatch => genericPromise2(ApplicationConf.catchment.catchment(id), { method: 'GET' }, { 403: builderError403(dispatch) }).catch(err => {
        dispatch(LogAction.logError(`${i18n.fetchError} ${i18n.catchment} : ${err}`))
        dispatch(ToastrAction.error(`${i18n.fetchError} ${i18n.catchment}`))
    }),
    fetchCatchment: id => dispatch => genericPromise2(ApplicationConf.catchment.catchment(id), { method: 'GET' }, { 403: builderError403(dispatch) }).then(result => {
        dispatch(CatchmentActionConstant.catchment(result))
    }).catch(err => {
        dispatch(LogAction.logError(`${i18n.fetchError} ${i18n.catchment} : ${err}`))
        dispatch(ToastrAction.error(`${i18n.fetchError} ${i18n.catchment}`))
    }),
    insertCatchment: catchment => dispatch => genericPromise2(ApplicationConf.catchment.catchments(), { method: 'POST', body: catchment }, { 403: builderError403(dispatch) }).then(result => {
        if (result?.insert) {
            dispatch(ToastrAction.success(i18n.elementCreateSuccess))
            dispatch(CatchmentThunk.fetchCatchments({ forceFetch: true }))
            dispatch(CatchmentThunk.fetchCatchmentPoints({ forceFetch: true }))
        } else {
            dispatch(ToastrAction.error(`${i18n.createError} ${i18n.catchment}`))
        }
        return result
    }).catch(err => {
        dispatch(LogAction.logError(`${i18n.createError} ${i18n.catchment} : ${err}`))
        dispatch(ToastrAction.error(`${i18n.createError} ${i18n.catchment}`))
    }),
    updateCatchment: (id, catchment) => dispatch => genericPromise2(ApplicationConf.catchment.catchment(id), { method: 'PUT', body: catchment }, { 403: builderError403(dispatch) }).then(result => {
        if (result?.update) {
            dispatch(ToastrAction.success(i18n.elementUpdateSuccess))
            dispatch(CatchmentThunk.fetchCatchments({ forceFetch: true }))
            dispatch(CatchmentThunk.fetchCatchmentPoints({ forceFetch: true }))
        } else {
            dispatch(ToastrAction.error(`${i18n.updateError} ${i18n.catchment}`))
        }
        return result
    }).catch(err => {
        dispatch(LogAction.logError(`${i18n.createError} ${i18n.catchment} : ${err}`))
        dispatch(ToastrAction.error(`${i18n.createError} ${i18n.catchment}`))
    }),
    deleteCatchment: id => dispatch => genericPromise2(ApplicationConf.catchment.catchment(id), { method: 'DELETE' }, { 403: builderError403(dispatch) }).then(result => {
        if (result?.delete) {
            dispatch(ToastrAction.success(i18n.elementDeleteSuccess))
            dispatch(CatchmentThunk.fetchCatchments({ forceFetch: true }))
            dispatch(CatchmentThunk.fetchCatchmentPoints({ forceFetch: true }))
        } else {
            dispatch(ToastrAction.error(`${i18n.deleteError} ${i18n.catchment}`))
        }
        return result
    }).catch(err => {
        dispatch(LogAction.logError(`${i18n.deleteError} ${i18n.catchment} : ${err}`))
        dispatch(ToastrAction.error(`${i18n.deleteError} ${i18n.catchment}`))
    }),

    fetchFiles: code => dispatch => Promise.all([
        genericPromise2(ApplicationConf.files.stationFile(code, 'catchment')),
        genericPromise2(ApplicationConf.picture.stationPicture(code, 'catchment')),
    ]).then(([files, pictures]) => {
        dispatch(CatchmentActionConstant.files({ files, pictures }))
    }).catch(err => {
        dispatch(LogAction.logError(`${i18n.fetchError} ${i18n.files} : ${err}`))
        dispatch(ToastrAction.error(`${i18n.fetchError} ${i18n.files}`))
    }),

    getSumAnalysis: (filters, signal) => genericPromise2(ApplicationConf.catchment.sumAnalysis(), { method: 'POST', body: filters, signal }),
    countGroupParameters: (filters, signal) => genericPromise2(ApplicationConf.catchment.countGroupParameters(), { method: 'POST', body: filters, signal }),
}

export default CatchmentAction