import React from 'react'
import logoSandre from 'assets/pictures/logo_sandre.png'
import { push } from '@lagunovsky/redux-react-router'
import { find } from 'lodash'
import { useEffect, useMemo, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import useActions from 'utils/customHook/useActions'
import useTitle from 'utils/customHook/useTitle'
import { getDataWithHeaders } from 'utils/ExportDataUtil'
import { sandreFractionDefaultLink } from '../../../../conf/SieauConstants'
import HomeAction from '../../../../home/actions/HomeAction'
import {
    PATH_REFERENCIAL,
    PATH_REFERENCIAL_FRACTION,
    PATH_REFERENCIAL_FRACTION_NEW,
} from '../../../../home/constants/RouteConstants'
import ReferentialAction from '../../../action/ReferencialAction'
import FractionAction from '../actions/FractionAction'
import { nbPerPageLabel, REFERENCIAL_TYPE_NAME } from 'referencial/constants/ReferencialConstants'
import Table from 'components/datatable/Table'
import { getFullDate } from 'utils/DateUtil'
import Card from 'components/card/Card'
import Row from 'components/react/Row'
import Input from 'components/forms/Input'
import Select from 'components/forms/Select'
import useSessionStorage from 'utils/customHook/useSessionStorage'
import ReferencialAction from '../../../action/ReferencialAction'
import { hasValue } from 'utils/NumberUtil'
import { searchAllCharacters } from 'utils/StringUtil'
import { Button } from '@mui/material'
import { getStatusInformation } from 'referencial/util/StatusUtils'
import SupportAction from '../../support/actions/SupportAction'

const headers = ['code', 'name', 'support', 'status']

const FractionsApp = () => {
    const {
        fractions,
        supports,
        referencialSandreCodes,
        referencialStatus,
    } = useSelector(store => ({
        fractions: store.FractionReducer.fractions,
        supports: store.SupportReducer.supports,
        referencialSandreCodes: store.ReferencialReducer.referencialSandreCodes,
        referencialStatus: store.ReferencialReducer.referencialStatus,
    }), shallowEqual)

    const [filter, setFilter] = useSessionStorage(`referencial_${REFERENCIAL_TYPE_NAME.fraction}`, {})
    const [filterTmp, setFilterTmp] = useState(filter)

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(FractionAction.fetchFractions())
        dispatch(ReferentialAction.fetchReferencialSandreCodes())
        dispatch(HomeAction.setHelpLink('', ''))
        if (!referencialStatus.length) {
            dispatch(ReferencialAction.fetchReferencialStatus())
        }
        if (!supports.length) {
            dispatch(SupportAction.fetchSupports())
        }
    }, [])

    const lastUpdate = useMemo(() => find(referencialSandreCodes, o => o.field === 'FRACTIONS'), [referencialSandreCodes])

    const fractionByStatus = useMemo(() => hasValue(filter.status) ? fractions.filter(h => parseInt(h.status) === filter.status) : fractions, [filter.status, fractions])
    const data = useMemo(() => {
        return fractionByStatus
            .filter((obj) => filter.support ? obj.supportCode === filter.support : true)
            .filter((obj) => searchAllCharacters(headers.map((key) => obj[key]).join())
                .includes(searchAllCharacters(filter.searchValue || '')))
    }, [fractionByStatus, filter.searchValue, filter.support])

    const dataFormatted = useMemo(() => data.map(s => ({
        ...s,
        code: { value: s.code, sortValue: s.code },
        name: { value: s.name, sortValue: s.name },
        support: { value: s.supportCode ? `[${s.supportCode}] ${supports.find(su => su.code === s.supportCode)?.name}` : '', sortValue: s.supportCode },
        status: { value: s.status ? getStatusInformation(s.status.toString()) : '', sortValue: s.status },
    })), [data, supports])

    useTitle(() => [{
        title: i18n.referencials,
        href: PATH_REFERENCIAL,
    }, {
        title: i18n.fractions,
        href: PATH_REFERENCIAL_FRACTION,
    }], [])

    useActions(() => {
        return {
            new: () => dispatch(push(PATH_REFERENCIAL_FRACTION_NEW)),
            referencialActions: {
                actions: {
                    export: {
                        data: getDataWithHeaders(data, headers),
                        titleFile: i18n.fractionsList,
                    },
                    sync: {
                        updateAction: () => dispatch(FractionAction.fetchSynchroSandreFraction()),
                    },
                    purge: {
                        onPurge: () => dispatch(FractionAction.purgeFraction()),
                    },
                },
            },
            links: [{
                href: sandreFractionDefaultLink,
                img: logoSandre,
                label: i18n.sandre.toUpperCase(),
            }],
        }
    }, [data])

    return (
        <>
            <Card noMargin={false} className='margin-left-1 margin-right-1' title={i18n.criterias}>
                <Row className='padding-top-1 padding-bottom-5-px'>
                    <Input
                        col={3}
                        title={i18n.search}
                        value={filterTmp.searchValue}
                        onChange={v => setFilterTmp({ ...filterTmp, searchValue: v })}
                        onEnterKeyPress={() => setFilter({ ...filterTmp })}
                    />
                    <Select
                        options={referencialStatus}
                        label={i18n.status}
                        col={3}
                        onChange={v => setFilterTmp({ ...filterTmp, status: v })}
                        value={filterTmp.status}
                        nullLabel='&nbsp;'
                    />
                    <Select
                        options={supports}
                        label={i18n.support}
                        col={3}
                        onChange={v => setFilterTmp({ ...filterTmp, support: v })}
                        value={filterTmp.support}
                        nullLabel='&nbsp;'
                    />
                    <div className='col s3 padding-top-1'>
                        <Button className='right' onClick={() => setFilter({ ...filterTmp })} variant='contained' color='primary'>
                            {i18n.search}
                        </Button>
                    </div>
                </Row>
            </Card>
            {lastUpdate?.updateUse && lastUpdate?.updateDate && (
                <div className='row no-margin-bottom'>
                    <div className='col s4 center offset-s8 card'>
                        <div className='col s12'>
                            { i18n.lastUpdateOn } { getFullDate(lastUpdate.updateDate) } { i18n.by } { lastUpdate.updateUser }
                        </div>
                    </div>
                </div>
            )}
            <div className='margin-1 padding-top-5-px referencial-table'>
                <Table
                    title={i18n.fractionsList}
                    condensed paging
                    nbPerPageLabel={nbPerPageLabel}
                    data={dataFormatted}
                    color
                    type={{ headers }}
                    sortable={!!data.length}
                    onClick={fraction => dispatch(push(`/referencial/fraction/${fraction.id}/dashboard`))}
                />
            </div>
        </>
    )
}

export default FractionsApp
