import i18n from 'simple-react-i18n'
import { MAT_CENTRAL } from '../../../constants/MaterielConstants'
import DtoMaterielType from '../../../dto/DtoMaterielType'

export default class CentralTypeDto extends DtoMaterielType {
    constructor(obj) {
        super(obj)
        this.materielCategory = MAT_CENTRAL
        this.materielLabel = i18n.central
        this.powerSupplyType = obj.powerSupplyType
        this.pileNumber = obj.pileNumber
        this.callFrequency = obj.callFrequency
        this.callHour = obj.callHour
        this.transmissionVolume = obj.transmissionVolume
        this.telecomType = obj.telecomType
        this.thresholdOverrun = obj.thresholdOverrun
        this.desiccant = obj.desiccant
        this.supplyTime = obj.supplyTime
    }
}
