import { ceil, chunk, isEqual, range } from 'lodash'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import Checkbox from '../../../../../components/forms/Checkbox'
import { StyledFieldSet, StyledLegend } from '../../../../../components/StyledElements'
import Button from '../../../../../components/forms/Button'
import Row from '../../../../../components/react/Row'

class HydroSuiviPastYearsTab extends Component {
    constructor(props) {
        super(props)
        this.state = {
            horizon: 30,
            mean: 7,
            tmpSeries: this.props.series,
        }
    }

    onChangeSeries = (changes) => {
        this.setState({ tmpSeries: { ...this.state.tmpSeries, ...changes } })
    }

    onSave = () => {
        this.props.onChangeState({ series: { ...this.props.series, ...this.state.tmpSeries } })
    }

    render() {
        const yearRange = range(1, (moment.duration(moment().diff(moment(this.props.minDate))).years() || 1) + 1)

        const yearCheckboxes = yearRange.map(y => (
            <div className='row no-margin'>
                <Checkbox checked={ this.state.tmpSeries[`N-${y}`] } label={ moment().year() - y } onChange={ v => this.onChangeSeries({ [`N-${y}`]: v }) } />
            </div>
        ))
        const groups = chunk(yearCheckboxes, ceil(yearCheckboxes.length / 4) || 1)

        return (
            <div>
                <StyledFieldSet>
                    <StyledLegend>&nbsp;{ i18n.previousYears }&nbsp;</StyledLegend>
                    <div className='padding-left-2 row no-margin' >
                        <div className='col s3'>
                            { groups[0] || [] }
                        </div>
                        <div className='col s3'>
                            { groups[1] || [] }
                        </div>
                        <div className='col s3'>
                            { groups[2] || [] }
                        </div>
                        <div className='col s3'>
                            { groups[3] || [] }
                        </div>
                    </div>
                    <Row className='padding-bottom-1 padding-top-1 center-align'>
                        <Button tooltip={ i18n.apply } onClick={ this.onSave } icon='border_color' className={`btn-floating btn-large ${!isEqual(this.state.tmpSeries, this.props.series) ? 'pulse' : ''}`}/>
                    </Row>
                </StyledFieldSet>
            </div>
        )
    }
}

HydroSuiviPastYearsTab.propTypes = {
    series: PropTypes.object,
    onChangeState: PropTypes.func,
    minDate: PropTypes.number,
}

const mapStateToProps = () => ({
})

export default connect(mapStateToProps)(HydroSuiviPastYearsTab)
