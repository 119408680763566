import { find, reduce } from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import {
    ALL,
    DISTRIBUTION,
    HYDRO,
    INSTALLATION,
    PIEZO,
    PLUVIO,
    PRODUCTION,
    QUALITO,
} from '../../../administration/components/user/constants/ApplicationHabilitationConstants'
import DtoDistributionUnit from '../../../distributionUnit/dto/DtoDistributionUnit'
import DtoHydrometricStation from '../../../hydrometry/dto/DtoHydrometricStation'
import DtoInstallation from '../../../installation/dto/installation/DtoInstallation'
import DtoPiezometerLight from '../../../piezometry/dto/DtoPiezometerLight'
import PluviometerDto from '../../../pluviometry/dto/PluviometerDto'
import DtoProductionUnit from '../../../productionUnit/dto/DtoProductionUnit'
import DtoQualitometerLight from '../../../quality/dto/DtoQualitometerLight'
import { getStationTypeFromUserConstants } from '../../../utils/StationUtils'
import DtoAccountHabilitation from '../../dto/DtoAccountHabilitation'
import DtoAccountStation from '../../dto/DtoAccountStation'

class AccessAccountPanel extends Component {
    constructor(props) {
        super(props)
    }

    getHabilitation = (listOfHabilitations, stationType) => {
        return find(listOfHabilitations, (o) => {
            return o.module === stationType
        })
    }
    getAllValue = (listOfHabilitations, stationType) => {
        const findValues = this.getHabilitation(listOfHabilitations, stationType)
        if (findValues) {
            const allValues = find(findValues.values, (o) => {
                return o.habilitation === `${stationType}_${ALL}`
            })
            if (allValues) {
                return allValues.habilitation
            }
            return null
        }
        return undefined
    }

    getListOfHabilitations = () => {
        const result = reduce(this.props.accountHabilitations, (a, b) => {
            (a[b.habilitation.split('_')[0]] || (a[b.habilitation.split('_')[0]] = [])).push(b)
            return a
        }, {})
        return Object.keys(result).map((o) => {
            return {
                module: o,
                values: result[o],
            }
        })
    }

    getDatas = () => {
        const listOfHabilitations = this.getListOfHabilitations()
        const redureStationTypes = reduce(this.props.accountStations, (a, b) => {
            (a[b.stationType] || (a[b.stationType] = [])).push(b)
            return a
        }, {})
        const modules = [
            {
                stationType: QUALITO,
                stations: this.props.qualitometers,
                allValue: this.getAllValue(listOfHabilitations, QUALITO),
                userStationsSelected: redureStationTypes[QUALITO],
            },
            {
                stationType: PIEZO,
                stations: this.props.piezometers,
                allValue: this.getAllValue(listOfHabilitations, PIEZO),
                userStationsSelected: redureStationTypes[PIEZO],
            },
            {
                stationType: PLUVIO,
                stations: this.props.pluviometers,
                allValue: this.getAllValue(listOfHabilitations, PLUVIO),
                userStationsSelected: redureStationTypes[PLUVIO],
            },
            {
                stationType: HYDRO,
                stations: this.props.hydrometricStations,
                allValue: this.getAllValue(listOfHabilitations, HYDRO),
                userStationsSelected: redureStationTypes[HYDRO],
            },
            {
                stationType: INSTALLATION,
                stations: this.props.installations,
                allValue: this.getAllValue(listOfHabilitations, INSTALLATION),
                userStationsSelected: redureStationTypes[INSTALLATION],
            },
            {
                stationType: PRODUCTION,
                stations: this.props.productionUnits,
                allValue: this.getAllValue(listOfHabilitations, PRODUCTION),
                userStationsSelected: redureStationTypes[PRODUCTION],
            },
            {
                stationType: DISTRIBUTION,
                stations: this.props.distributionUnits,
                allValue: this.getAllValue(listOfHabilitations, DISTRIBUTION),
                userStationsSelected: redureStationTypes[DISTRIBUTION],
            },
        ]
        return modules.map((o) => {
            const value = (() => {
                if (o.allValue) {
                    return i18n.allAccessStation
                } else if (o.userStationsSelected) {
                    return `${o.userStationsSelected.length} / ${o.stations.length}`
                }
                return i18n.unauthorized
            })()
            return (
                <div className='col l4 m6 s12'>
                    <div className='row no-margin card'>
                        <div className='col s12 center-align card-title active'>
                            { i18n[getStationTypeFromUserConstants(o.stationType)] }
                        </div>
                        <div className='col s12 center-align'>
                            <span className='font-size-16'>{ value }</span>
                        </div>
                    </div>
                </div>
            )
        })
    }

    render() {
        return (
            <div className='row no-margin'>
                { this.getDatas() }
            </div>
        )
    }
}

AccessAccountPanel.propTypes = {
    accountStations: PropTypes.arrayOf(PropTypes.instanceOf(DtoAccountStation)),
    accountHabilitations: PropTypes.arrayOf(PropTypes.instanceOf(DtoAccountHabilitation)),
    qualitometers: PropTypes.arrayOf(PropTypes.instanceOf(DtoQualitometerLight)),
    piezometers: PropTypes.arrayOf(PropTypes.instanceOf(DtoPiezometerLight)),
    pluviometers: PropTypes.arrayOf(PropTypes.instanceOf(PluviometerDto)),
    hydrometricStations: PropTypes.arrayOf(PropTypes.instanceOf(DtoHydrometricStation)),
    installations: PropTypes.arrayOf(PropTypes.instanceOf(DtoInstallation)),
    distributionUnits: PropTypes.arrayOf(PropTypes.instanceOf(DtoDistributionUnit)),
    productionUnits: PropTypes.arrayOf(PropTypes.instanceOf(DtoProductionUnit)),
}
const mapStateToProps = (store) => {
    return {
        accountStations: store.AccountReducer.accountStations,
        accountHabilitations: store.AccountReducer.accountHabilitations,
        qualitometers: store.QualityReducer.qualitometersLight,
        piezometers: store.PiezometryReducer.piezometersLight,
        pluviometers: store.PluviometryReducer.pluviometers,
        hydrometricStations: store.HydrometryReducer.hydrometricStations,
        installations: store.InstallationReducer.installations,
        distributionUnits: store.DistributionUnitReducer.distributionUnits,
        productionUnits: store.ProductionUnitReducer.productionUnits,
    }
}
export default connect(mapStateToProps)(AccessAccountPanel)
