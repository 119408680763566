import moment from 'moment'
import word from 'assets/pictures/logos/word.svg'
import pdf from 'assets/pictures/logos/pdf.svg'
import excel from 'assets/pictures/logos/excel.svg'
import powerpoint from 'assets/pictures/logos/powerpoint.svg'
import text from 'assets/pictures/logos/txt.svg'
import defaultLogo from 'assets/pictures/logos/text.svg'
import { DOCUMENT, DOCUMENT_TYPES, EXCEL_EXTENSIONS, PDF_EXTENSIONS, PICTURE, PICTURE_TYPES, POWERPOINT_EXTENSIONS, TEXT_EXTENSIONS, WORD_EXTENSIONS } from 'components/file/constant/FileConstants'
import { SANDRE } from 'referencial/constants/ReferencialConstants'
import i18n from 'simple-react-i18n'

const formatedFileName = (fileName) => {
    return fileName ? fileName.normalize('NFD').toUpperCase().replace(/[\u0300-\u036f]/g, '').split('_').join('-').replaceAll(' ', '-') : i18n.unknown
}

const STATION_TYPE_NAME = {
    piezometry: 'PIEZOMETRY',
    hydrometry: 'HYDROMETRY',
    quality: 'QUALITY',
    pluviometry: 'PLUVIOMETRY',
    productionUnit: 'PRODUCTION',
    distributionUnit: 'DISTRIBUTION',
    installation: 'INSTALLATION',
    resource: 'RESOURCE',
    catchment: 'CATCHMENT',
}

const getFormatedFileName = (stationCode, active = 0, fileName, extension = 'jpg', fileType = 'INCONNU', date = moment(), stationType, shared = false) => {
    const dateFormatted = (() => {
        if (isNaN(date)) {
            return '0-0'
        }
        if (!new RegExp('^[0-9]+-[0-9]+$').test(date)) {
            return moment(date).format('YYYYMMDD-HHmmss')
        }
        return date
    })()
    const stationTypeShared = shared ? '' : `_${STATION_TYPE_NAME[stationType]}`
    return `${stationCode}_${fileType}_${active.toString()}_${dateFormatted}${stationTypeShared}_${formatedFileName(fileName)}.${extension}`
}

const getFileExtension = (fileName = '') => {
    return fileName.slice((Math.max(0, fileName.lastIndexOf('.')) || Infinity) + 1)
}

const getFileName = (fileName) => {
    return fileName.substr(0, fileName.lastIndexOf('.'))
}

const getFileNatureLabel = (fileName, sandreCodes) => {
    const [ , type] = fileName.substr(0, fileName.lastIndexOf('.')).split('_')
    const findByMnemo = sandreCodes.find(s => s.mnemonique === type)
    const fileNature = findByMnemo ? findByMnemo.name : (sandreCodes.some(s => formatedFileName(s.name) === formatedFileName(type)) ? type : 'INCONNU')
    return fileNature
}

const getDocumentTypeIcon = (fileExtension) => {
    if (WORD_EXTENSIONS.includes(fileExtension)) {
        return word
    } else if (PDF_EXTENSIONS.includes(fileExtension)) {
        return pdf
    } else if (EXCEL_EXTENSIONS.includes(fileExtension)) {
        return excel
    } else if (POWERPOINT_EXTENSIONS.includes(fileExtension)) {
        return powerpoint
    } else if (TEXT_EXTENSIONS.includes(fileExtension)) {
        return text
    } return defaultLogo
}

const getTypeSandreCode = (type) => {
    if (type === PICTURE) {
        return SANDRE.TYPE_PHOTO
    } else if (type === DOCUMENT) {
        return SANDRE.TYPE_DOCUMENT
    } return SANDRE.TYPE_PHOTO
}

const getFileType = (content) => {
    if (!content) {
        return {}
    }
    if (content.includes(PICTURE_TYPES.IMAGE)) {
        return { title: i18n.pictureType, sandreCode: SANDRE.TYPE_PHOTO }
    } else if (content.includes(DOCUMENT_TYPES.APPLICATION) || content.includes(DOCUMENT_TYPES.TEXT)) {
        return { title: i18n.documentType, sandreCode: SANDRE.TYPE_DOCUMENT }
    } return {}
}

export { getFormatedFileName, getFileExtension, getFileName, formatedFileName, getFileNatureLabel, getDocumentTypeIcon, getTypeSandreCode, getFileType }
