export default class DtoQualitometerListSpecific {
    constructor(obj = []) {
        this.id = obj[0]
        this.code = obj[1]
        this.name = obj[2] || ''
        this.x = obj[3]
        this.y = obj[4]
        this.projection = obj[5]
        this.townCode = obj[6]
        this.creationDate = obj[7]
        this.closeDate = obj[8]
        this.stationType = obj[9]
        this.address = obj[10]
        this.jobExecutionId = obj[11]
        this.historyStart = obj[12]
        this.historyEnd = obj[13]
        this.operationCount = obj[14]
        this.internalReferences = obj[15]
        this.typeName = 'quality'
    }
}
