import { TOASTR_ERROR, TOASTR_INFO, TOASTR_SUCCESS, TOASTR_WARNING } from '../constants/ToastrConstants'

export function ToastrReducer(state = {}, action) {
    switch (action.type) {
        case TOASTR_ERROR: {
            return {
                ...state,
                error: action.message,
                title: action.title,
                option: action.option,
            }
        }
        case TOASTR_SUCCESS: {
            return {
                ...state,
                success: action.message,
                title: action.title,
                option: action.option,
            }
        }
        case TOASTR_WARNING: {
            return {
                ...state,
                warning: action.message,
                title: action.title,
                option: action.option,
            }
        }
        case TOASTR_INFO: {
            return {
                ...state,
                info: action.message,
                title: action.title,
                option: action.option,
            }
        }
        default:
            return state
    }
}

export const store = {
    info: '',
    title: '',
    option: {},
}