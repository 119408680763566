
export default class DtoCampaignInstallationWithStats {
    constructor(obj) {
        this.idCampaign = obj.idCampaign // Long,
        this.name = obj.name // Option[String],
        this.startDate = obj.startDate // Option[String],
        this.endDate = obj.endDate // Option[String],
        this.contactCode = obj.contactCode // Option[Int]
        this.updateDate = obj.updateDate // Option[DateTime]
        this.campaignType = obj.campaignType // Option[Int]
        this.nbStation = obj.nbStation // Option[Int]
        this.nbStationValidated = obj.nbStationValidated // Option[Int]
        this.campaignStatus = obj.campaignStatus
    }
}
