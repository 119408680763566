import React from 'react'
import i18n from 'simple-react-i18n'
import Icon from '../../components/icon/Icon'
import { INSTALLATION_TYPES } from '../../installation/constants/InstallationConstants'
import { STATION_QUALITOMETER_NAMES } from '../../station/constants/StationConstants'
import AppStore from '../../store/AppStore'
import { getDate } from '../DateUtil'
import { getLabel } from '../StoreUtils'
import { i18nize } from '../StringUtil'
import { getSiteName } from './SiteTypes'


export default {

    popupSitePoint: {
        name: ((layer) => getSiteName(layer.type ? layer.type.name : layer.typeParams[0])),
        station: (layer, feature) => layer.getUuid().find((el) => el.uuid === feature.id).obj,
        content: ((layer, feature) => {
            const station = layer.getUuid().find((el) => el.uuid === feature.id).obj
            const icon = (() => {
                switch (station.typeName) {
                    case 'quality':
                        return 'assets/pictures/markers/map_qualite.png'
                    case 'piezometry':
                        return 'assets/pictures/markers/map_piezo.png'
                    case 'pluviometry':
                        return 'assets/pictures/markers/map_pluvio.png'
                    case 'hydrometry':
                        return 'assets/pictures/markers/map_hydro.png'
                    default:
                        return null
                }
            })()
            return (
                <div>
                    <div className='row margin-bottom-1'>
                        <div className='col s2 no-padding width-4 margin-right-1'>
                            <img src={icon} className='max-width-20 margin-right-1 responsive-img' style={{ transform: 'translate(3px, 19px)' }}/>
                        </div>
                        <div className='col s10 no-padding margin-left-2'>
                            <div className='margin-bottom-1'>
                                <div className='col s12 no-padding'>
                                    <span className='font-size-14'>
                                        <b>{station.stationType ? `${i18n.installation} : ${STATION_QUALITOMETER_NAMES[parseInt(station.stationType)].name}` : ''}</b>
                                        <b>{station.installationType ? `${i18n.installation} : ${getLabel(i18nize(INSTALLATION_TYPES), [station.installationType])}` : ''}</b>
                                        <br />
                                        {
                                            (station.code && station.code.value ? `${+'[ ' + station.code.value} ]` + ` - ${feature.name ? feature.name : ''}` : `[ ${station.code} ]` + ` - ${feature.name ? feature.name : ''}`)
                                || (station.id && station.id.value ? `${station.id.value} - ${feature.name ? feature.name : ''}` : `${station.id} - ${feature.name ? feature.name : ''}`)
                                        }
                                        {station.townCode ? getLabel(AppStore.getState().CityReducer.cities, station.townCode, 'labelWithCode') : ''}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='bold' >
                        {
                            station.measureDate ?
                                getDate(station.measureDate.valueOf())
                                : <span>{ i18n.noData }</span>
                        }
                    </div>
                    <div className='col s12 event-card no-padding'>
                        <div className='row margin-top-1 no-padding event-footer'>
                            <div className='left'>
                                { station.jobExecutionId && <Icon size='small' icon='wifi' tooltip={ 'Open Data' }/> }
                            </div>
                        </div>
                    </div>
                </div>
            )
        }),
    },
    popupKmlCity: {
        name: ((layer, feature) => feature.NAME),
        content: ((layer, feature) => {
            return (
                <div><b>{feature.NAME}</b><br />
                    Code postal : {feature.CODE_POSTAL}<br />
                    Population : {feature.POPULATION}<br />
                    Département : {feature.CODE_DEPARTMENT}<br />
                </div>)
        }),
    },
}
